<template>
    <div style="background:#FFF">
        <a-form
            id="components-form-demo-validate-other"
            :form="form"
            :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }"
            @submit="handleSubmit"
        >
        <a-row>
            <a-col :md="12" :sm="24" >
                <a-form-item label="项目名称" has-feedback>
                    <a-select @change="chooseProject" :disabled="view=='true'"  v-decorator="['projectId',{ rules: [{ required: true, message: '请选择用工的项目!' }] },]" placeholder="请选择用工的项目">
                        <a-select-option :key="index" :value="item.projId" v-for="(item, index) in projectList">{{item.fullName}}</a-select-option>
                    </a-select>
                </a-form-item>
            </a-col>
            <a-col :md="12" :sm="24" >
                <a-form-item label="入场日期">
                    <a-date-picker :disabled="view=='true'" v-decorator="['inDate', config]" />
                </a-form-item>
            </a-col>
        </a-row>
        <a-row>
            <a-col :md="12" :sm="24" >
                <a-form-item label="地址">
                    <a-input :disabled="view=='true'" v-decorator="['address',{rules: [{required: true,message: '请输入地址!'}]}]"/>
                </a-form-item>
            </a-col>
            <a-col :md="12" :sm="24" >
                <a-form-item label="计划工期">
                    <div><a-range-picker :disabled="view=='true'" v-decorator="['plan_date', rangeConfig]" />
                    <a-upload
                        v-if="!view"
                        name="file"
                        :multiple="false"
                        :beforeUpload="beforeUpload"
                        accept=".xls,.xlsx"
                        :customRequest="resourceUpload"
                    >
                        <a-button :disabled="tableLoading" style="margin-left:10px" type="primary" >导入用工计划</a-button>
                    </a-upload>
                        
                    </div>
                </a-form-item>
            </a-col>
        </a-row>
        </a-form>
        <div v-if="dataSource.persons&&dataSource.persons.length>0">
            <a-divider>用工人员信息</a-divider>
            <a-form layout="horizontal">
                <div :class="advanced ? null: 'fold'">
                    <a-row >
                        <a-col :md="6" :sm="18" >
                            <a-form-item
                            label="工人姓名"
                            :labelCol="{span: 5}"
                            :wrapperCol="{span: 18, offset: 1}"
                            >
                                <a-input placeholder="请输入" v-model="personsfiler.userName"/>
                            </a-form-item>
                        </a-col>
                        <a-col :md="6" :sm="18" >
                            <a-form-item
                            label="班组名称"
                            :labelCol="{span: 5}"
                            :wrapperCol="{span: 18, offset: 1}"
                            >
                                <a-input placeholder="请输入" v-model="personsfiler.teamName"/>
                            </a-form-item>
                        </a-col>
                        <a-col :md="6" :sm="18" >
                            <a-form-item
                            label="进场状态"
                            :labelCol="{span: 5}"
                            :wrapperCol="{span: 18, offset: 1}"
                            >
                                <a-select v-model="personsfiler.isInside">
                                    <a-select-option key="-1" :value="-1">全部</a-select-option>
                                    <a-select-option key="0" :value="0" >未进场</a-select-option>
                                    <a-select-option key="1" :value="1" >已进场</a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :md="6" :sm="18" >
                             <span style="float: left;margin-top: 3px;margin-left: 10px;">
                                <a-button type="primary" @click="doFilter()">查询</a-button>
                                <a-button style="margin-left: 8px" @click="doReset()">重置</a-button>
                            </span>
                        </a-col>
                    </a-row>
                </div>
            </a-form>

            <a-table
                size="default"
                bordered
                :columns="columns"
                :dataSource="showSource"
                :loading="tableLoading"
                :pagination="false"
            >
                <!-- <span slot="action" slot-scope="text, record">
                <a
                    @click="updateBlackList(record)"
                    :disabled="showButton"
                >{{record.isBlackList==0?'移出黑名单':'加入黑名单'}}</a>
                </span> -->
                <div slot="do_status" slot-scope="text">
                    <a-icon v-if="text==1" style="color:green" type="check" />
                    <a-icon v-else  style="color:red" type="close" />
                </div>
            </a-table>
            <a-pagination
                size="middle"
                style="margin:20px 50px;text-align:right"
                v-model="current"
                :pageSize="pageSize"
                @change="pageNumberChange"
                @showSizeChange="sizeChange"
                :total="total"
                :show-total="(total, range) => `第 ${range[0]}-${range[1]} 条，总计 ${total} 条`"
                showQuickJumper
            />
        </div>
        <div class="subBtn">
            <a-button type="primary" v-if="!view" html-type="submit" @click="handleSubmit"> 保存发布 </a-button>
            <a-button style="margin-left:10px;margin-right:50px" @click="doBack"> 返回 </a-button>                
        </div>
    </div>
</template>
<script>
const columns = [
  {
    title: '序号',
    dataIndex: 'key',
    align: 'center',
    scopedSlots: { customRender: 'key' }
  },
  {
    title: '姓名',
    dataIndex: 'userName',
    align: 'center',
    scopedSlots: { customRender: 'userName' }
  },
  {
    title: '身份证号',
    dataIndex: 'idCard',
    align: 'center',
    scopedSlots: { customRender: 'idCard' }
  },
  {
    title: '所属班组',
    dataIndex: 'teamName',
    align: 'center',
    scopedSlots: { customRender: 'teamName' }
  },{
    title: '进场状态',
    dataIndex: 'isInside',
    align: 'center',
    scopedSlots: { customRender: 'do_status' }
  },
  {
    title: '班组长姓名',
    dataIndex: 'leaderName',
    align: 'center',
    scopedSlots: { customRender: 'leaderName' }
  },
  {
    title: '班组长身份证号',
    dataIndex: 'leaderIdCard',
    align: 'center',
    scopedSlots: { customRender: 'leaderIdCard' }
  },
  ]

import {
  FIND_PROJECT_OFFICE, 
  LOOKJOB_DETAIL,
  LOOKJOB_INSERT,
  LOOKJOB_UPDATE,
  PERSONS_IMPORT_EXCEL,
  } from '@/services/api'
import {request} from '@/utils/request'
// import axios from '@/utils/axios'

export default {
  name: 'mobile_setting',
  components: {},
  data () {
    return {
      objId:null,
      columns,
      advanced: false,
      view:"false",
      isPublish:false,
      dataSource:{
        persons:[],
        projectName:null,
        personsList:[],
        status:1
      },
      personsfiler:{
        userName:null,
        idCard:null,
        teamName:null,
        isInside:-1
      },
      showSource:[],
      projectList:{},
      tableLoading:false,
      isUpload:false,
      spinning:false,
      loadingText:"数据加载中...",
      pageSize: 10, //当前实际一页显示多少条
      current: 1, //当前是哪一页
      total: 0, //总条数,在获取后台数据时将数组的length赋值给total
      config: {
        rules: [{ type: 'object', required: true, message: '请选择入场时间!' }],
      },
      rangeConfig: {
        rules: [{ type: 'array', required: true, message: '请选择计划工期!' }],
      },
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'time_related_controls' });
  },
  created() {
    this.objId = this.$route.query.id;
    this.view = this.$route.query.view;
    if(this.objId){
      this.doQuery();
    }
    this.doQueryProject();
  },
  methods: {
      /**
       * 获取公司的项目ID
       */
    doQueryProject(){
        request(FIND_PROJECT_OFFICE,"get").then(res => {
          if(res.data.code==0){
            this.projectList = res.data.data;
          }
        })
    },
    /** 
    * 上传之前
    */
    beforeUpload (file) {
      return new Promise((resolve, reject) => {
        const isFiveM= file.size / 1024 / 1024 < 5 //小于5M的图片上传
        //判断文件是否符合正则表达式的规则
        if (!isFiveM) {
          this.$message.error('上传资源不大于5M！');
          return reject(false)
        }
        const fileType = file.type;
        const isExcel = fileType === 'application/vnd.ms-excel' || fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        if (!isExcel) {
          this.$message.error('只能上传.xls和.xlsx文件');
          return reject(false)
        }
        if(this.dataSource.persons&&this.dataSource.persons.length>0){
          this.$confirm({
            title: '',
            content: h => <div style="color:red;">重新上传会将原有的记录覆盖，是否继续上传?</div>,
            onOk() {
              return resolve(true);
            },
            onCancel() {
              return reject(false);
            },
            class: 'test',
          });
        }else{
          return resolve(true);
        }
      });
    },
    /**
    * 资源上传
    */
    resourceUpload(value){
        this.tableLoading = true;
        const formData = new FormData();
        formData.append('file', value.file);
        request(PERSONS_IMPORT_EXCEL,"post",formData).then(result => {
            this.tableLoading = false;
            console.log(result)
            if (result.data.code==0) {
                this.dataSource.persons = result.data.data;
                this.dataSource.personsList =  result.data.data;
                this.pageNumberChange(1,10);
                this.total = this.dataSource.persons.length;
                value.onSuccess(result, value.file);
            }else{
                this.$message.warning(result.message);
            }
        })
    },

    chooseProject(value){
        let obj = this.projectList.filter(item => value == item.projId);
        if(obj.length>0){
            this.dataSource.projectName = obj[0].fullName;
            setTimeout(() => {
                this.form.setFieldsValue({
                    'address': obj[0].projAddress
                })
            }, 0)
        }
        
    },

    //分页页数的改变
    pageNumberChange(current, size) {
     this.current = current;
     this.pageSize = size;
     this.showSource = this.getShowSource();
   },
   //  分页显示条数的变化
    sizeChange(current, size) {
     this.current = current;
     this.pageSize = size;
     this.showSource = this.getShowSource();
   },

   doReset(){
       this.personsfiler.userName = null;
       this.personsfiler.teamName = null;
       this.doFilter();
   },

   doFilter(){
      this.pageNumberChange(1,10);
   },

   getShowSource() {
     var keyValue = 0;
     var data = this.dataSource.persons;
     console.log(data);
     if(this.personsfiler.userName){
        data = data.filter(item => item.userName.indexOf(this.personsfiler.userName)>-1);
     }
     if(this.personsfiler.teamName){
        data = data.filter(item => item.teamName.indexOf(this.personsfiler.teamName)>-1);
     }
     if(this.personsfiler.isInside!=-1){
        data = data.filter(item => item.isInside==this.personsfiler.isInside);
     }
     for (var i = 0; i < data.length; i++) {
       keyValue = keyValue + 1;
       let key = { key: keyValue };
       data[i] = Object.assign(data[i], key);
     }
     var start = this.pageSize * this.current - this.pageSize;
     var end = this.pageSize * this.current;
     this.total = data.length;
     return data.slice(start, end);
   },
    /**
     * 获取项目详情信息
     */
    doQuery(){
        if(this.objId){
          request(LOOKJOB_DETAIL+this.objId,"get").then(res => {
            if(res.data.code==0){
              this.dataSource = res.data.data;
              this.dataSource.persons=this.dataSource.personsList;
              this.pageNumberChange(1, 10);
              this.total = this.dataSource.personsList.length;
              setTimeout(() => {
                  this.form.setFieldsValue({
                      'address': this.dataSource.address,
                      'inDate': this.dataSource.inDate,
                      'projectId': this.dataSource.projectId,
                      'plan_date':[this.dataSource.beginDate,this.dataSource.endDate]
                  })
              }, 0)
            }
          })
        }
    },
    /**
     * 保存表单信息
     */
    handleSubmit(e){
        //判断当前是否已经发布
        e.preventDefault();
        var _this = this;
        let path = this.objId?LOOKJOB_UPDATE:LOOKJOB_INSERT;
        this.form.validateFields((err, values) => {
            if (!err) {
                const filedsValue = {
                    ...values,
                    'inDate':values['inDate'].format('YYYY-MM-DD'),
                    'beginDate':values['plan_date'][0].format('YYYY-MM-DD'),
                    'endDate':values['plan_date'][1].format('YYYY-MM-DD'),
                }
                let setting = {...this.dataSource,...filedsValue}
                // console.log(setting);
                
                if(setting.personsList.length<=0){
                    this.$message.error("请按照模板导入用工计划人员！");
                    return
                }
                var params = JSON.parse(JSON.stringify(setting))//深拷贝常用方法
                request(path,"post",params).then(res => {
                    if(res.data.code==0){
                        // console.log(res.data);
                        _this.view = "true";
                        _this.objId = res.data.id
                        _this.$message.success('派单成功!');
                        this.$router.go(-1);
                    }else{
                         _this.$message.error("保存失败！"+res.data.message);
                    }
                })
            }
        })
    },
    doBack(){
        this.$router.go(-1);
    },
    handleMenuClick (e) {
      if (e.key === 'delete') {
        this.remove()
      }
    },
  }
}
</script>

<style lang="less" scoped>
  .search{
    margin-bottom: 54px;
  }
  .fold{
    width: calc(100% - 216px);
    display: inline-block;
    margin-left:50px
  }
  .operator{
    margin-bottom: 18px;
  }
  .subBtn{
      width: 100%;
      text-align:right;
      background:#FFF;
      padding-bottom:20px
  }
  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
</style>