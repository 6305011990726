/* 详情页枚举 */

/**
 * @description 状态标识枚举
 */
export const DetailPageModeEnum = Object.freeze({
  VIEW: 'view',
  EDIT: 'edit',
  ADD: 'add',
});

/**
 * @description 状态文本映射
 */
export const DetailPageModeNameMap = {
  [DetailPageModeEnum.VIEW]: '查看',
  [DetailPageModeEnum.EDIT]: '编辑',
  [DetailPageModeEnum.ADD]: '新增',
}
