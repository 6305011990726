<template>
  <a-modal v-model="internalVisible" :width='600' :footer="null" @cancel="handleCancel" :maskClosable="false">
    <div style="padding: 30px;height: 550px" class="relative">
      <div class="flex a-center j-center flex-column">
        <h2 style="font-weight: bold">欢迎来到{{ systemFullname }}</h2>
        <p class="mt-1">请选择以下任意一种方式进入系统</p>
      </div>
      <div class="flex a-center j-center flex-column mt-2">
        <div class="flex">
          <div v-if="!isPtzy" class="choose-box choose-box-right" :class="selectType == 1 ? 'choose-box-selected' : 'choose-box-unselected'" @click="handleSelect(1)">
            <i class="iconfont gongsi" style="font-size: 30px"></i>
            <span>公司管理</span>
          </div>
          <div class="choose-box" :class="selectType == 2 ? 'choose-box-selected' : 'choose-box-unselected'" @click="handleSelect(2)">
            <i class="iconfont xiangmukongjian" style="font-size: 30px"></i>
            <span>项目管理</span>
          </div>
        </div>
      </div>
      <div class="radio-box mt-3">
        <img :src="radioBg" alt="">
        <a-radio-group name="radioGroup" style="width:85%; margin: 15px 35px 10px 35px" v-model="officeKey" @change="onChange" v-if="selectType == 1">
          <a-radio :style="radioStyle" :value="officeData.key">
            <span :title="officeData.name">{{officeData.name | ellipsis}}</span>
            <span :title="officeData.roles" style="float:right">{{officeData.roles | ellipsisRole}}</span>
          </a-radio>
        </a-radio-group>
        <a-radio-group name="radioGroup" style="width:85%; margin: 15px 35px 10px 35px" v-model="value" @change="onChange2" v-if="selectType == 2 && projectLists.length > 0">
            <a-radio v-for="(item, index) in projectLists" :key=index :style="radioStyle" :value="item.key">
              <span :title="item.name">{{item.name | ellipsisRole}}</span>
              <span :title="item.roles" style="float:right">{{item.roles | ellipsisRole}}</span>
            </a-radio>
          </a-radio-group>
      </div>
      <div class="btn-box flex j-center mt-3">
        <a-button type="primary" @click="doSureChoice()">
            确认选择
        </a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import {mapGetters,mapMutations} from 'vuex'
import {changeAccount} from '@/services/user'
import {afterLoginPhone} from '@/utils/routerUtil'
import {CHANGE_LOGIN_TYPE} from '@/services/api'
import {request} from '@/utils/request'
import { logout } from '@/services/user.js'
import { isLaowu } from '@/utils/system.js'


export default {
  name: 'choicetype',
  components: {},
  data () {
    return {
      radioBg: require('../../assets/img/radio-box-left-bg.png'),
      selectType: 2,
      officeKey: null,
      value:null,
      queryData:{},
      projectLists:[],
      officeData:{},
      isPtzy:false,
      radioStyle: {
        display: 'block',
        height: '45px',
        lineHeight: '30px'
      },
      internalVisible: this.visible,
      isLaowu: isLaowu(),
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  filters: {
    ellipsis (value) {
      if (!value) return ''
      if (value.length > 20) {
        return value.slice(0,20) + '...'
      }
      return value
    },
    ellipsisRole (value) {
      if (!value) return ''
      if (value.length > 10) {
        return value.slice(0,10) + '...'
      }
      return value
    }
  },
  computed: {
    ...mapGetters('account', ['user']),
    realmType() {
      return this.$store.state.setting.realmType;
    },
    systemFullname() {
      return this.$store.getters['setting/systemFullname'];
    },
  },
  watch:{
    visible(val){
      this.internalVisible = val
      if(val){
        this.queryData = this.user;
        this.projectLists = [];
        this.officeData = {};
        if(this.queryData.officeLoginData&&this.queryData.officeLoginData.officeId){
          let obj = {
            key:this.queryData.officeLoginData.officeId,
            name:this.queryData.officeLoginData.officeName,
            roles:this.queryData.officeLoginData.labourRoleName.replace(/普通职员/g, ""),
            type:1
          }
          this.officeData = obj;
          let officeRoles = (this.queryData.officeLoginData.labourRoleKeyList && this.queryData.officeLoginData.labourRoleKeyList.length > 0) ? this.queryData.officeLoginData.labourRoleKeyList.length : 0;
          this.isPtzy = officeRoles > 1 ? false : (this.queryData.officeLoginData.labourRoleKeyList[0] != 'OFFICE_ORDINARY_STAFF' ? false : true);
          if(this.queryData.loginType == 1) {
            this.officeKey = this.officeData.key;
          }
        }
        if(this.queryData.laoWuUserList && this.queryData.laoWuUserList.length > 0) {
          this.queryData.laoWuUserList.forEach(item => {
            let obj = {
              key:item.projectId,
              name:item.projectName,
              roles:item.labourRoleName,
              type:0
            }
            if(this.queryData.loginType==0&&this.queryData.projectId==item.projectId){
              this.value = this.queryData.projectId;
            }
            this.projectLists.push(obj);
          });
        }
      }
    },
    isPtzy: {
      handler(newVal) {
        if (newVal) {
          this.selectType = 2
          this.radioBg = require('../../assets/img/radio-box-mid-bg.png')
        }
      }
    }
  },
  created() {

  },
  methods: {
     ...mapMutations('account', ['setUser', 'setPermissions', 'setRoles']),
     handleSelect(type) {
      if (type == 1) {
        this.value = null;
        this.officeKey = this.officeData.key
      } else {
        this.officeKey = null;
        this.value = this.projectLists[0].key
      }
      this.selectType = type
      this.radioBg = type == 1 ? require('../../assets/img/radio-box-right-bg.png') : require('../../assets/img/radio-box-left-bg.png')
     },
     onChange(e){
       this.value = null;
       this.officeKey = e.target.value;
     },
     onChange2(e){
       this.officeKey = null;
       this.value = e.target.value;
     },
     doSureChoice(){
       if(!this.officeKey&&!this.value){
         this.$message.error("请选择一种方式进入系统");
         return ;
       }
       if(this.officeData&&this.officeData.key&&this.officeData.key==this.officeKey){
         this.changeOffice();

       }else{
         const branchArr = this.queryData.laoWuUserList.filter(o=>o.projectId==this.value);
         if(branchArr&&branchArr.length>0){
           this.changeAccount(branchArr[0].id,this.value,branchArr[0].isBindLaoWuUser);

         }else{
           this.$message.error("请重新选择...");
         }

       }
     },
    changeAccount(userId, projectId, isBindLaoWuUser){
      var that = this
      const system = this.isLaowu ? 0 : 1
      changeAccount({sysUserId: userId, projectId: projectId, isBindLaoWuUser: isBindLaoWuUser===undefined? false : true,system: system }).then(async (res) => {
        if (res.data.code === 0){
          await afterLoginPhone({ res, pageThis: that, loginDone: true})
          this.$emit('doSure', { type:0 })
        } else{
          this.error = res.data.msg
          this.$message.error(res.data.msg, 3)
          // 进入项目失败，调用logout清除登录信息
          logout()
          this.handleCancel()
        }
      })
    },
    changeOffice(){
      var that = this
      const system = this.isLaowu ? 0 : 1
      request(CHANGE_LOGIN_TYPE+`?loginType=1&system=${system}`,"get", {}).then(async (res) => {
        if (res.data.code === 0){
          await afterLoginPhone({ res, pageThis: that, loginDone: true})
          this.$emit('doSure',{type:1})
        } else {
          // 进入项目失败，调用logout清除登录信息
          logout()
        }
      })
    },
    handleCancel(e) {
      // this.$emit('doSure',{type:this.queryData.loginType})
      this.$emit('doCloseChoice')
    },
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-modal-content {
  border-radius: 20px !important;
  height: 620px;
}
.choose-box {
  width: 130px;
  height: 130px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &&.choose-box-right {
    margin-right: 60px;
  }
  span {
    margin-top: 10px;
    font-size: 16px;
  }
}
.choose-box-unselected {
  background: #F5F6FD;
  color: #CCCCCC;
}
.choose-box-selected {
  background: #0874F0;
  color: #fff;
}
.radio-box {
  position: relative;
  // background: rgb(255, 255, 255);
  // box-shadow: 0px 0px 18px 0px rgba(8, 116, 240, 0.3);
  // border-radius: 10px;
  // padding: 15px 0 10px 20px;
  // max-height: 170px;
  // overflow: hidden;
  // overflow-y: auto;
  img {
    position: absolute;
    width: 100%;
    top: -30px
  }
}
.ant-radio-group {
  max-height: 145px;
  overflow: hidden;
  overflow-y: auto;
}
.btn-box {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
</style>
