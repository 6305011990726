var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pb-2" },
    [
      _c(
        "a-row",
        { attrs: { gutter: 16 } },
        [
          _c(
            "a-col",
            { attrs: { span: 14 } },
            [
              _c("ProjectInfo"),
              _c("ProjectStaff", { staticStyle: { "margin-top": "16px" } }),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 10 } },
            [
              _c("ProjectSituation"),
              _c("WorkChart", { staticStyle: { "margin-top": "16px" } }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }