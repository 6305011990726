<template>
  <div>
    <a-card :title="title" class="a_Approval" :bordered="false">
      <form-index
        ref="formindex"
        :formData="formData"
        @handleSubmit="submit"
        :bottomSubmit="true"
        @handlSelect="handlSelect"
        @handleChange="handleChange"
        flexAction
      >
        <!-- 下部分按钮插槽 -->
        <template #bottomSubmit>
          <a-button type="primary" html-type="submit"> 保存 </a-button>
          <a-button @click="close"> 返回 </a-button>
        </template>
      </form-index>
    </a-card>
  </div>
</template>

<script>
const formData = [
  {
    label: '工程管理部',
    placeholder: '请选择工程管理部',
    decorator: ['gcglbId', { rules: [{ required: true, message: '请选择工程管理部' }] }],
    type: 'select',
    key: 'gcglbId',
    selectlist: [],
    col: 8,
    labelCol: 6,
    wrapperCol: 12,
    disabled: true
  },
  {
    label: "项目名称",
    placeholder: "请输入项目名称",
    decorator: [
      "projectId",
      { rules: [{ required: true, message: "请输入项目名称" }] },
    ],
    type: "inputGroup",
    key: "projectId",
    dataSource: [],
    col: 12,
    labelCol: 6,
    wrapperCol: 8,
    display: true,  
    disabled: true
  },
  {
    label: "检验日期",
    placeholder: "请选择",
    decorator: [
      "checkDate",
      { rules: [{ required: true, message: "请选择" }] },
    ],
    type: "datePicker",
    key: "checkDate",
    col: 8,
    labelCol: 6,
    wrapperCol: 12,
    display: true,
    format: "yyyy-MM-DD",
    styles: {width: '100%'},
    disabled: true
  },
  {
    label: '检查时间',
    placeholder: '请选择检查时间',
    decorator: ['checkDateTime', { rules: [{ required: true, message: '请选择检查时间' }] }],
    type: 'select',
    key: 'checkDateTime',
    selectlist: [{name: '上午', value: '上午'}, {name: '下午', value: '下午'}],
    col: 12,
    labelCol: 6,
    wrapperCol: 8,
    disabled: true
  },
  {
    label: '检查类别',
    placeholder: '请选择检查类别',
    decorator: ['checkType', { rules: [{ required: true, message: '请选择检查类别' }] }],
    type: 'select',
    key: 'checkType',
    selectlist: [],
    col: 8,
    labelCol: 6,
    wrapperCol: 12,
    disabled: false
  },
  {
    label: '视频巡检',
    placeholder: '请选择是否视频巡检',
    decorator: ['isVideo', { rules: [{ required: true, message: '请选择是否视频巡检' }] }],
    type: 'radio',
    key: 'isVideo',
    radiolist: [
      {
        name: "是",
        value: 1,
      },
      {
        name: "否",
        value: 0,
      },
    ],
    col: 12,
    labelCol: 6,
    wrapperCol: 8,
    disabled: true
  },
  {
    label: "检查内容",
    placeholder: "请输入检查内容",
    decorator: [
      "checkContent",
      { rules: [{ required: true, message: "请输入检查内容" }] },
    ],
    type: "textarea",
    key: "checkContent",
    col: 8,
    labelCol: 6,
    wrapperCol: 12,
    display: true,  
    disabled: false
  },
  {
    label: '危大工程',
    placeholder: '请选择危大工程',
    decorator: ['dangerProjectIds', { rules: [{ required: false, message: '请选择危大工程' }] }],
    type: 'select',
    key: 'dangerProjectIds',
    selectlist: [],
    col: 12,
    labelCol: 6,
    wrapperCol: 8,
    disabled: false,
    mode: 'multiple'
  },
  {
    label: '风险源类别',
    placeholder: '请选择风险源类别',
    decorator: ['riskIds', { rules: [{ required: false, message: '请选择风险源类别' }] }],
    type: 'select',
    key: 'riskIds',
    selectlist: [],
    col: 12,
    labelCol: 6,
    wrapperCol: 8,
    disabled: false,
    mode: 'multiple'
  },
];
import formIndex from "@/pages/components/form/index";
import { QUERY_OFFICE_BY_USER, AQXJ_ZXJC, PROJECT_OFFICE_CHILD } from "@/services/api";
import { request } from "@/utils/request";
import { mapGetters } from 'vuex'
import { getProjectByOffice } from "@/services/statistics";
import { saveOrUpdatePlan, inspectionPlanDetail, getRiskSourceType, getWDGC_GCLB } from "@/services/safeMonthlyReport";
import moment from 'moment';
export default {
  components: {
    formIndex,
  },
  inject: ["fatherMethod"],
  computed: {
    ...mapGetters('account', ['user']),
  },
  data() {
    return {
      title: '新增安全巡检计划',
      isEdit: 0,
      formData: formData,
      detailObj: null, //保存详情的对象
      params: {
        id: '',
        projectId: '',
        gcglbId: '',
        projectName: ''
      },
      ptype: 1, // 1:房间，2：市政
    };
  },
  activated() {
    // 非机施安全
    if (!this.$store.state.setting.realmType) {
      // 找到 title 为 工程管理部 的对象
      const formData = this.formData.find(column => column.label === '工程管理部')
      // 修改属性值
      if (formData) this.$set(formData, 'label', '分公司')
      if (formData) this.$set(formData, 'placeholder', '请选择分公司')
    }
    this.isEdit = Number(this.$route.query.isEdit);
    if (this.isEdit === 1) {
      this.title = '安全巡检计划详情'
      this.params.id = this.$route.query.id;
      this.getDetail()
      this.formData.forEach(el => {
        if (el.key == 'gcglbId' || el.key == 'projectId' || el.key == 'checkDate' || el.key == 'checkDateTime') {
           el.disabled = true
        }
      })
    } else {
      this.title = '新增安全巡检计划'
      this.formData.forEach(el => {
        el.disabled = false
      })
      this.$refs.formindex.resetFields()
    }
    this.getgcglbList(PROJECT_OFFICE_CHILD + "/OFFICE_CHILD");
    this.getProjectStatissKqS('')
    this.getAQXJ_ZXJC(AQXJ_ZXJC)
    this.getRiskSourceType()
    this.getWDGC_GCLB()
  },

  methods: {
    getProjectStatissKqS(projectName) {
      getProjectByOffice({gcglbId: this.params.gcglbId, projectName}).then((res) => {
        if (res.data.code === 0) {
          this.formData.forEach((item) => {
            if ('projectId' === item.key) {
              let arr = []
              res.data.data.forEach(el => {
                arr.push({
                  title: el.projectName,
                  id: el.projectId,
                  gcglbId: el.gcglbId,
                  ptype: el.ptype
                })
              })
              item.dataSource = arr
            }
          });
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    getDetail() {
      inspectionPlanDetail(this.params.id).then(res => {
        if (res.data.code === 0) {
          res.data.data.checkDate = moment(res.data.data.checkDate).format('YYYY-MM-DD')
          res.data.data.dangerProjectIds = (res.data.data.dangerProjectIds && res.data.data.dangerProjectIds.split(',')) || [];
          res.data.data.riskIds = (res.data.data.riskIds && res.data.data.riskIds.split(',')) || [];
          this.detailObj = res.data.data
          this.$refs.formindex.queryObj(res.data.data)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 获取工程管理部数据
    getAQXJ_ZXJC(path) {
      request(path, "get").then((res) => {
        this.formDataFn("checkType", res.data.data);
      });
    },
    // 获取工程管理部数据
    getRiskSourceType() {
      getRiskSourceType().then((res) => {
        this.formDataFn("riskIds", res.data.data);
      });
    },
    // 获取危大工程数据
    getWDGC_GCLB() {
      getWDGC_GCLB().then((res) => {
        this.formDataFn("dangerProjectIds", res.data.data);
      });
    },
    getgcglbList(path) {
      request(path, "get").then((res) => {
        if (res.data.code === 0) {
          const data = res.data.data.filter(
            (item) => item.id != "3da0f5533f87425c9a9e8a11a7923300"
          );
          const arr = [];
          data.forEach((item) => {
            arr.push({
              name: item.label,
              value: item.value,
            });
          });
          this.formDataFn("gcglbId", arr);
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      let arr = [];
      if (key == 'gcglbId') {
        data.forEach((item) => {
          arr.push({ name: item.name, value: item.value });
        });
      } else if (key == 'checkType') {
        data.forEach((item) => {
          if (item.value != '777') {
            arr.push({ name: item.label || item.itemValue, value: item.value });
          }
        })
      } else {
        data.forEach((item) => {
          // 兼容危大工程和风险源
          arr.push({ name: item.label || item.riskSourceDesc, value: item.value || item.id });
        });
      }
      this.formData.forEach((item) => {
        if (item.key == key) {
          item.selectlist = arr;
        }
      });
    },
    handlSelect(obj) {
      const { val, keys } = obj
      console.log(val, keys)
      if (keys === 'gcglbId') {
        this.params.gcglbId = val
        this.getProjectStatissKqS('')
      } else if (keys === 'projectId') {
        this.params.projectId = val
        this.formData.forEach(el => {
          if (el.key === 'projectId') {
            this.params.projectName = el.dataSource.find(i => val == i.id).title
            this.ptype = el.dataSource.find(i => val == i.id).ptype
          }
        })
      } else if (keys === 'checkType') {
        if (val == '12312') {
          if (this.ptype == 1) {
            const itemValue = '根据《建筑施工安全检查标准》（JGJ59）对施工现场的安全生产工作进行检查'
            this.$refs.formindex.setFieldsValue('checkContent', {'checkContent': itemValue}, '')
          } else if (this.ptype == 2) {
            const itemValue2 = '根据《市政工程施工安全检查标准》（CJJ/T275）对施工现场的安全生产工作进行检查' 
            this.$refs.formindex.setFieldsValue('checkContent', {'checkContent': itemValue2}, '')
          }
        } else if (val == '33333') {
          this.$refs.formindex.setFieldsValue('checkContent', {'checkContent': ''}, '')
        } else {
          let itemValue = ''
          this.formData.forEach(el => {
            if (el.key == 'checkType') {
              itemValue = el.selectlist.find(i => val == i.value).name
            }
          })
          this.$refs.formindex.setFieldsValue('checkContent', {'checkContent': itemValue}, '')
        }
      }
    },
    // 输入选择框触发
    handleChange(e) {
      console.log(e)
      this.getProjectStatissKqS(e.value)
    },
    submit(e) {
      if (this.isEdit === 0) {
        console.log(e)
        this.params = Object.assign(this.params, e)
        this.params.dangerProjectIds = this.params.dangerProjectIds && this.params.dangerProjectIds.join(',');
        this.params.riskIds = this.params.riskIds && this.params.riskIds.join(',');
        this.params.created = this.user.id
        this.params.checkDate = moment(this.params.checkDate).valueOf()
        saveOrUpdatePlan(this.params).then((res) => {
          let d = res.data;
          if (d.code === 0) {
            this.$message.success("新增成功");
            this.close();
          } else {
            this.$message.error(res.data.msg);
          }
        });
      } else {
        const projectName = this.detailObj.projectName//先把projectName存起来
        this.params = Object.assign(this.detailObj, Object.assign(this.params, e))//合并时projectName会丢失
        this.params.dangerProjectIds = this.params.dangerProjectIds && this.params.dangerProjectIds.join(',');
        this.params.riskIds = this.params.riskIds && this.params.riskIds.join(',');
        this.params.projectName = projectName
        this.params.updated = this.user.id
        this.params.checkDate = moment(this.params.checkDate).valueOf()
        saveOrUpdatePlan(this.params).then((res) => {
          let d = res.data;
          if (d.code === 0) {
            this.$message.success("修改成功");
            this.close();
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
    },
    close() {
      let path = this.$route.path;
      this.fatherMethod(`${path}`);
      this.$router.push({
        name: "安全巡检计划",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.a_Approval {
  background: #fff;
  border-radius: 10px;
}
</style>
