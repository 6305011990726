<template>
    <a-modal width="1400px" v-model="show" :title="title" :footer="null" @cancel="close" :dialog-style="{ top: '0px' }">
        <a-form-model ref="ruleForm" :model="params" :rules="rules">
          <a-row>
            <a-col :span="6">
              <a-form-model-item label="公司" :label-col="{ span:6 }" :wrapper-col="{ span: 14 }">
                <a-input v-model="prInfo.companyName" disabled placeholder=""/>
              </a-form-model-item>
            </a-col>
            
            <a-col :span="6">
              <a-form-model-item label="项目名称" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" >
                <a-input v-model="prInfo.projectName" disabled placeholder=""/>
                
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="日期" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" >
                <a-date-picker v-model="params.reportTime" placeholder="请选择日期" valueFormat="YYYY-MM-DD"/>
                
                
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="" :label-col="{ span: 6 }" :wrapper-col="{ span: 28 }" >
                <a-button type="primary" class="mr-4" :loading="sbloading" @click="handleSubmit">保存</a-button>
                <a-button class="mr-2" @click="close">返回</a-button>
                <!-- <a-button class="mr-2" @click="test">测试</a-button> -->
                
                
              </a-form-model-item>
            </a-col>

  
          </a-row>
        </a-form-model>
        <div >
            <tui-image-editor ref="tuiImageEditor" :include-ui="useDefaultUI" :options="options"></tui-image-editor>
        </div>
        <!-- <a-row>
          <a-col :span="12"></a-col>
          <a-col :span="12">
            <a-form-model-item :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }" prop="year">
              <div class="flex flex-row-reverse">
                <a-button type="primary" :loading="sbloading" @click="handleSubmit">保存</a-button>
                <a-button class="mr-2" @click="close">返回</a-button>
              </div>
            </a-form-model-item>
          </a-col>
        </a-row> -->
    </a-modal>
  </template>
  
  <script>
  
  import { request } from "@/utils/request";
  import { mapGetters } from 'vuex'
  import moment from "moment";
  import { riskmapdetailAdd,riskmapdetailUpdate, riskmapdetailByID } from "@/services/riskControll";
  import { ATTACHMENT_UPLOAD } from "@/services/api";
  import gcglib from './mixinGm'
  import 'tui-color-picker/dist/tui-color-picker.css';
  import 'tui-image-editor/dist/tui-image-editor.css';
  import { ImageEditor } from '@toast-ui/vue-image-editor';
  import theme from './theme'
  import locale from './locale'

  function base64ToFile(base64String, filename) {
    // 去掉 base64 URL 的前缀部分
    const arr = base64String.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
    }
  export default {
    name: 'etDetail',
    components: {
        'tui-image-editor': ImageEditor,
    },
    computed: {
      ...mapGetters('account', ['user']),
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        isEdit: {
            type: Number,
            default: 0
        },
        id: {
            default: ''
        },
        prInfo: {
            default() {
                return {}
            }
        }
        
  
    },
    mixins: [gcglib],
    data() {
      return {
        // isEdit: 0,
        rules: {
          // name: [{ required: true, message: '请输入 ', trigger: 'change' }],
          
        },
        showImgEditor: false,
  
        params: {
            "companyId": "gfjdkaglf3gfdgr2324",          //公司ID
            "companyName": "公司名称1",        //公司名称
            "branchOfficeId":"32432",      //分公司ID
            "branchOfficeName":"分公司名称1",   //分公司名称
            "projectId":"5gfdygfhgny78765", //项目ID
            "projectName": "项目名称1",        //项目名称
            "projectMap":"" //风险地图
        },
        sbloading: false,
        title: '新增',
        show: false,
        

        useDefaultUI: true,
        options: {
            includeUI: {
                loadImage: {
                    path: '',
                    name: ''
                },
                locale: locale,
                theme: theme, // or whiteTheme
                menu: ['draw', 'shape'],
                initMenu: 'filter',
                uiSize: {
                    width: '1350px',
                    height: '750px'
                },
                menuBarPosition: 'right'
            },
            cssMaxWidth: 1150,
            cssMaxHeight: 500,
            selectionStyle: {
                cornerSize: 20,
                rotatingPointOffset: 70
            }
        },
      };
    },
   
    watch: {
      visible(val) {
        this.showImgEditor = false
        if (this.isEdit == 0) {
            // dom 渲染后
          
          this.params = {
                "parentId": this.prInfo.id,   //风险管控ID 所属哪条风险管控的明细 
                "reportTime":"",  //填报时间
                "planarGraph":"" //项目风险图
            }
          // 新增 birthday 取 30年前
          // this.params.birthday = moment().subtract(30, 'years').format('YYYY-MM-DD');
          // this.params.age = 30;
          this.filePath1 = [];
          this.options.includeUI.loadImage.path = this.prInfo.projectMap
             //设置随机值name
          this.options.includeUI.loadImage.name = Math.random()
          this.$nextTick(() => {
                    this.$refs.tuiImageEditor.invoke('loadImageFromURL', this.prInfo.projectMap, 'testname').then(result => {
                        console.log('old : ' + result.oldWidth + ', ' + result.oldHeight);
                        console.log('new : ' + result.newWidth + ', ' + result.newHeight);
                    });

                });
          this.showImgEditor = true
          this.title = '新增 标注图'
          this.show = val;
        } else {
            this.title = '预览 标注图'
            this.params.id = this.id;
            this.getDetail()
  
        }
        this.show = val;
        
      }
    },
    methods: {
      getDetail() {
        riskmapdetailByID({id: this.params.id}).then(res => {
          if (res.data.code == 0) {
            this.params = res.data.data;
            if (this.params.planarGraph) {
                // this.$refs.tuiImageEditor.invoke('clearObjects');

              this.options.includeUI.loadImage.path = this.params.planarGraph
                //设置随机值name
                this.options.includeUI.loadImage.name = Math.random()
                this.$nextTick(() => {
                    this.$refs.tuiImageEditor.invoke('loadImageFromURL', this.params.planarGraph, 'testname').then(result => {
                        console.log('old : ' + result.oldWidth + ', ' + result.oldHeight);
                        console.log('new : ' + result.newWidth + ', ' + result.newHeight);
                    });

                });
            }
            this.showImgEditor = true
            // this.show = true;
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
  
      handleSubmit() {
        this.sbloading = true
        this.$refs.ruleForm.validate(async (valid) => {
          if (valid) {
            let d = this.params;  
            const img = this.$refs.tuiImageEditor.invoke('toDataURL');
            
            const formData = new FormData();
            let file = base64ToFile(img, this.prInfo.projectName +'风险地图'+ this.params.reportTime +'.png');
            formData.append("file", file);
            let r1 = await request(ATTACHMENT_UPLOAD, "post", formData)
            let imgUrl = ''
            if (r1.data.code === 0) {
                imgUrl = r1.data.data.path
            } else {
                this.$message.error(r1.data.msg)
                this.sbloading = false
                return false;
            }
            d.planarGraph = imgUrl;
            
            if (this.isEdit == 0) {
              riskmapdetailAdd(d).then(res => {
                if (res.data.code == 0) {
                  this.$message.success('提交成功！')
                  this.close();
                  this.$emit('refresh')
                } else {
                  this.$message.error(res.data.msg)
                }
                this.sbloading = false
              })
            } else {
              riskmapdetailUpdate(d).then(res => {
                if (res.data.code == 0) {
                  this.$message.success('修改成功！')
                  this.close();
                  this.$emit('refresh')
                } else {
                  this.$message.error(res.data.msg)
                }
                this.sbloading = false
              })
            }
          } else {
            console.log('error submit!!');
            this.sbloading = false
            return false;
          }
        });
      },
      close() {
        // this.resetForm()
        this.$emit('closeDialog')
      },
      resetForm() {
        this.$refs.ruleForm.resetFields();
        this.filePath1 = []
      },
      test() {
        this.$refs.tuiImageEditor.invoke('clearObjects');
        
      }
      
    },
  };
  </script>
  
  <style lang="less" scoped>
  .a_Approval {
    background: #fff;
    border-radius: 10px;
  }
  
  .btn_group {
    padding-right: 190px;
  }
  </style>
  <style>
    .tui-image-editor-header-logo {
        display: none;
    }
    .tui-image-editor-header {
        display: none;
    }
  </style>
  