import { transformMenuToRoute, filterRouteList } from '@/router/helper.js'
import store from '@/store/index.js'
import { storage } from './../../utils/storage';
import { mergeI18nFromRoutes } from '@/utils/i18n'

export default {
  namespaced: true,
  state: {
    // 是否已生成动态路由
    isGenerate: false,
    // 生成的动态路由
    routeList: []
  },
  getters: {
    isGenerate(state) {
      return state.isGenerate
    },
    routeList(state) {
      return state.routeList
    }
  },
  actions: {
    /**
    * @description 构建路由
    */
    async buildRoutesAction({ state }, { router, message, i18n }) {
      const hideLoading = message.loading(`正在加载菜单`, 0)

      const user = storage.get(process.env.VUE_APP_USER_KEY)
      // 获取权限相关数据
      const params = {
        userLoginType: String(user.loginType),
        projectId: user.loginType === 0 ? user.loginLaoWuUser.projectId : ''
      }
      const permissionInfo = await store.dispatch('permission/getPermissionInfoAction', params)
      // 缓存权限
      if (permissionInfo) {
        store.commit('permission/setPermCodeList', permissionInfo.permissions)
      }

      // 将 menus 菜单转换为路由列表
      let routeList = transformMenuToRoute(permissionInfo?.menus || [])
      
      // 过滤路由
      routeList = filterRouteList(routeList)
      // 缓存路由
      state.routeList = routeList
      // commit('setRouteList', routeList)

      console.log(
        `%c 🚀 %c 动态路由已生成 %c`,
        'background:#35495e ; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff',
        'background:#41b883 ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff',
        'background:transparent',
        JSON.parse(JSON.stringify(routeList))
      )

      routeList.forEach((route) => {
        router.addRoute(route)
      })

      // 设置顶部菜单数据，考虑多根目录的情况（比如建安易突然要加劳务的一些菜单，为避免重复新建菜单配置，所以会有一个系统，拥有多个根目录的情况）
      const menuRoutes = routeList.filter((item) => item.path === '/').reduce((arr, root) => {
        arr.push(...(root.children || []))
        return arr
      }, [])
      if (menuRoutes.length) {
        store.commit('setting/setMenuData', menuRoutes)
      }

      // 提取路由国际化数据
      mergeI18nFromRoutes(i18n, routeList)

      // 已生成动态路由的标识
      state.isGenerate = true
      hideLoading()
    }
  }
}
