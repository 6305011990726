var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "table", staticClass: "advanced-table", attrs: { id: _vm.id } },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.loading } },
        [
          _c("div", { class: ["header-bar", _vm.size] }, [
            _c(
              "div",
              { staticClass: "title" },
              [
                _vm.title
                  ? [_vm._v(_vm._s(_vm.title))]
                  : _vm.$slots.title
                  ? _vm._t("title")
                  : [_vm._v("高级表格")],
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "search" },
              [
                _c(
                  "search-area",
                  {
                    attrs: {
                      "format-conditions": _vm.formatConditions,
                      columns: _vm.columns,
                    },
                    on: { change: _vm.onSearchChange },
                  },
                  [
                    _vm._l(_vm.slots, function (slot) {
                      return _c("template", { slot: slot }, [_vm._t(slot)], 2)
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "actions" },
              [
                _c(
                  "a-tooltip",
                  { attrs: { title: "刷新" } },
                  [
                    _c("a-icon", {
                      staticClass: "action",
                      attrs: { type: _vm.loading ? "loading" : "reload" },
                      on: { click: _vm.refresh },
                    }),
                  ],
                  1
                ),
                _c("action-size", {
                  staticClass: "action",
                  model: {
                    value: _vm.sSize,
                    callback: function ($$v) {
                      _vm.sSize = $$v
                    },
                    expression: "sSize",
                  },
                }),
                _c(
                  "a-tooltip",
                  { attrs: { title: "列配置" } },
                  [
                    _c(
                      "action-columns",
                      {
                        staticClass: "action",
                        attrs: { columns: _vm.columns },
                        on: { reset: _vm.onColumnsReset },
                      },
                      [
                        _vm._l(_vm.slots, function (slot) {
                          return _c(
                            "template",
                            { slot: slot },
                            [_vm._t(slot)],
                            2
                          )
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "a-tooltip",
                  { attrs: { title: "全屏" } },
                  [
                    _c("a-icon", {
                      staticClass: "action",
                      attrs: {
                        type: _vm.fullScreen ? "fullscreen-exit" : "fullscreen",
                      },
                      on: { click: _vm.toggleScreen },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "a-table",
            _vm._b(
              {
                attrs: { size: _vm.sSize },
                on: {
                  expandedRowsChange: _vm.onExpandedRowsChange,
                  change: _vm.onChange,
                  expand: _vm.onExpand,
                },
                scopedSlots: _vm._u(
                  [
                    _vm._l(_vm.scopedSlots, function (slot) {
                      return {
                        key: slot,
                        fn: function (text, record, index) {
                          return [
                            _vm._t(slot, null, null, {
                              text: text,
                              record: record,
                              index: index,
                            }),
                          ]
                        },
                      }
                    }),
                    {
                      key: _vm.$scopedSlots.expandedRowRender
                        ? "expandedRowRender"
                        : "",
                      fn: function (record, index, indent, expanded) {
                        return [
                          _vm._t(
                            _vm.$scopedSlots.expandedRowRender
                              ? "expandedRowRender"
                              : "",
                            null,
                            null,
                            {
                              record: record,
                              index: index,
                              indent: indent,
                              expanded: expanded,
                            }
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              },
              "a-table",
              Object.assign({}, _vm.$props, {
                columns: _vm.visibleColumns,
                title: undefined,
                loading: false,
              }),
              false
            ),
            [
              _vm._l(_vm.slots, function (slot) {
                return _c("template", { slot: slot }, [_vm._t(slot)], 2)
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }