var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a_Approval" },
    [
      _c("div", { staticClass: "plug-Overview" }, [_vm._v("安全技术交底配置")]),
      _c("a-divider"),
      _c(
        "a-spin",
        { attrs: { spinning: _vm.spinning, tip: _vm.loadingText } },
        [
          _c(
            "a-form",
            {
              attrs: { form: _vm.form, layout: "horizontal" },
              on: { submit: _vm.doSave },
            },
            [
              _c(
                "a-card",
                { attrs: { title: "通用技术交底模板&课程设置：" } },
                [
                  _c(
                    "a-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: "技术交底手动发起",
                                labelCol: { span: 17 },
                                wrapperCol: { span: 3, offset: 1 },
                              },
                            },
                            [
                              _c("a-switch", {
                                model: {
                                  value: _vm.obj.params.jsjd,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.obj.params, "jsjd", $$v)
                                  },
                                  expression: "obj.params.jsjd",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 5 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: "启用技术交底视频学习",
                                labelCol: { span: 17 },
                                wrapperCol: { span: 3 },
                              },
                            },
                            [
                              _c("a-switch", {
                                model: {
                                  value: _vm.obj.params.isOpenVideo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.obj.params, "isOpenVideo", $$v)
                                  },
                                  expression: "obj.params.isOpenVideo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: "交底模板",
                                labelCol: { span: 6 },
                                wrapperCol: { span: 16, offset: 1 },
                              },
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    "show-search": "",
                                    placeholder: "请选择或输入模板",
                                    "option-filter-prop": "children",
                                    "filter-option": _vm.filterOption,
                                  },
                                  on: { change: _vm.handleChange },
                                  model: {
                                    value: _vm.obj.params.commonSet.templId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.obj.params.commonSet,
                                        "templId",
                                        $$v
                                      )
                                    },
                                    expression: "obj.params.commonSet.templId",
                                  },
                                },
                                _vm._l(_vm.jsjdTemplData, function (item) {
                                  return _c(
                                    "a-select-option",
                                    { key: item.id, attrs: { value: item.id } },
                                    [_vm._v(" " + _vm._s(item.title) + " ")]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: "教育课程",
                                labelCol: { span: 6 },
                                wrapperCol: { span: 16, offset: 1 },
                              },
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    "show-search": "",
                                    placeholder: "请输入或选择课程",
                                    "option-filter-prop": "children",
                                    "filter-option": _vm.filterOption,
                                  },
                                  on: { change: _vm.handleChange },
                                  model: {
                                    value: _vm.obj.params.commonSet.courseId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.obj.params.commonSet,
                                        "courseId",
                                        $$v
                                      )
                                    },
                                    expression: "obj.params.commonSet.courseId",
                                  },
                                },
                                _vm._l(_vm.courseData, function (item) {
                                  return _c(
                                    "a-select-option",
                                    { key: item.id, attrs: { value: item.id } },
                                    [_vm._v(" " + _vm._s(item.name) + " ")]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-card",
                { attrs: { title: "工种技术交底模板&视频课程设置：" } },
                _vm._l(_vm.obj.params.jobTitleSet, function (courseSet, index) {
                  return _c(
                    "a-row",
                    { key: index, attrs: { gutter: 24 } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 5 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: "工种",
                                labelCol: { span: 6 },
                                wrapperCol: { span: 16, offset: 1 },
                              },
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  attrs: {
                                    mode: "multiple",
                                    "show-search": "",
                                    placeholder: "请输入或选择工种",
                                    "option-filter-prop": "children",
                                    "filter-option": _vm.filterOption,
                                  },
                                  on: { change: _vm.handleChange },
                                  model: {
                                    value: courseSet.titleId,
                                    callback: function ($$v) {
                                      _vm.$set(courseSet, "titleId", $$v)
                                    },
                                    expression: "courseSet.titleId",
                                  },
                                },
                                _vm._l(_vm.jobTitles, function (item) {
                                  return _c(
                                    "a-select-option",
                                    { key: item.id, attrs: { value: item.id } },
                                    [_vm._v(" " + _vm._s(item.carft) + " ")]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 5 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: "交底模板",
                                labelCol: { span: 7 },
                                wrapperCol: { span: 16, offset: 1 },
                              },
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  attrs: {
                                    "show-search": "",
                                    placeholder: "请输入或选择模板",
                                    "option-filter-prop": "children",
                                    "filter-option": _vm.filterOption,
                                  },
                                  on: { change: _vm.handleChange },
                                  model: {
                                    value: courseSet.templId,
                                    callback: function ($$v) {
                                      _vm.$set(courseSet, "templId", $$v)
                                    },
                                    expression: "courseSet.templId",
                                  },
                                },
                                _vm._l(_vm.jsjdTemplData, function (item) {
                                  return _c(
                                    "a-select-option",
                                    { key: item.id, attrs: { value: item.id } },
                                    [_vm._v(" " + _vm._s(item.title) + " ")]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 5 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: "教育课程",
                                labelCol: { span: 7 },
                                wrapperCol: { span: 16, offset: 1 },
                              },
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  attrs: {
                                    "show-search": "",
                                    placeholder: "请输入或选择课程",
                                    "option-filter-prop": "children",
                                    "filter-option": _vm.filterOption,
                                  },
                                  on: { change: _vm.handleChange },
                                  model: {
                                    value: courseSet.courseId,
                                    callback: function ($$v) {
                                      _vm.$set(courseSet, "courseId", $$v)
                                    },
                                    expression: "courseSet.courseId",
                                  },
                                },
                                _vm._l(_vm.courseData, function (item) {
                                  return _c(
                                    "a-select-option",
                                    { key: item.id, attrs: { value: item.id } },
                                    [_vm._v(" " + _vm._s(item.name) + " ")]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "a-form-item",
                            [
                              _c("a-icon", {
                                staticClass: "imdexStyle",
                                attrs: { type: "plus-circle" },
                                on: { click: _vm.addTitleSet },
                              }),
                              _c("a-icon", {
                                staticClass: "imdexStyle",
                                attrs: { type: "minus-circle" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeTitleSet(index)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
              _c(
                "a-form-item",
                [
                  _c(
                    "a-button",
                    {
                      staticClass: "mt-2",
                      attrs: {
                        icon: "save",
                        type: "primary",
                        disabled: _vm.obj.isZongLaowu == 0 && !_vm.isYYZY,
                        "html-type": "submit",
                      },
                    },
                    [_vm._v(" 确认设置 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }