<template>
  <div>
    <a-card>
      <div class="flex a-center j-between">
        <div class="flex a-center">
          <img style="width: 21px; height: 21px" src="https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20241120/1732091350443.png?Expires=2047451350&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=59AEqlAw%2B%2BGyQ%2F%2B4QYdFWxJZ7rs%3D" alt="">
          <span class="font-bold ml-1">项目检查情况</span>
        </div>
        <div class="flex">
          <a-select
            :default-value="defaultUnit"
            v-model="defaultUnit"
            style="width: 200px"
            @change="onHandleUnitChange"
          >
            <a-select-option
              v-for="(items, id) in officeList"
              :key="id"
              :value="items.id"
            >
              <span :title="items.name">{{ items.name }}</span>
            </a-select-option>
          </a-select>
          <a-month-picker 
            class="ml-1" 
            style="width: 120px"  
            @change="mtChangeDate" 
            placeholder="请选择月份" 
            :default-value="moment(nowMonth, monthFormat)" 
            :format="monthFormat" 
            v-model="statTime"/>
        </div>
      </div>
      <a-divider />
      <div class="flex j-between" style="padding: 15px 30px;">
        <div class="flex flex-column">
          <span class="font-20 font-bold">{{projectCheckStatObj.leaderCheck || 0}}</span>
          <span class="black-three font-12" style="margin-top: 5px;">领导带班检查次数</span>
        </div>
        <a-divider type="vertical"  style="height: auto;"/>
        <div class="flex flex-column">
          <span class="font-20 font-bold">{{projectCheckStatObj.patrolCheck || 0}}</span>
          <span class="black-three font-12" style="margin-top: 5px;">巡查队检查次数</span>
        </div>
        <a-divider type="vertical"  style="height: auto;"/>
        <div class="flex flex-column">
          <span class="font-20 font-bold">{{projectCheckStatObj.monthCheckRatio || 0}}</span>
          <span class="black-three font-12" style="margin-top: 5px;">月度检查覆盖率</span>
        </div>
        <a-divider type="vertical"  style="height: auto;"/>
        <div class="flex flex-column">
          <span class="font-20 font-bold">99%</span>
          <span class="black-three font-12" style="margin-top: 5px;">危大及二级以上风</span>
          <span class="black-three font-12">险月度检查覆盖率</span>
        </div>
      </div>
      <div>
        <div class="font-bold ml-1">隐患总数：{{yhTotal}}</div>
        <div id="checkTimesCateGoryChart" style="width: 100%; height: 320px;"></div>
      </div>
    </a-card>
  </div>
</template>

<script>
import { getProjectCheckStat, getBarChartData } from "@/services/labourProjectExcellent";
import { mapGetters, mapMutations } from "vuex";
import moment from 'moment';
export default {
  props: {
    officeList: {
      type: Array,
      default: () =>  []
    }
  },
  data() {
    return {
      defaultUnit: "",
      deptId: '',
      statTime: '',
      nowMonth: null,
      monthFormat: 'YYYY-MM',
      projectCheckStatObj: {},
      barChartList: [],
      yhTotal: 0,
      cateGoryColor: [
        "#fc8251",
        "#5470c6",
        "#91cd77",
        "#75bedc",
        "#f9c956",
        "#ef6567",
        "#45C2E0",
        "#C1EBDD",
        "#FFC851",
      ],
    };
  },

  computed: {
    ...mapGetters("account", ["user"]),
  },

  watch: {
    officeList: {
      handler(val) {
        this.deptId = this.user.officeId;
        // 数据过滤成当前账号登录的信息
        let items = val.filter((item) => {
          return item.id === this.deptId;
        });
        this.defaultUnit = items[0].name;
        this.getData()
      },
      deep: true
    }
  },

  created() {
    this.nowMonth = moment(new Date()).format('YYYY-MM');
    this.statTime = moment(new Date()).format('YYYY-MM');
  },

  methods: {
    moment,

    getData() {
      getProjectCheckStat({deptId: this.deptId, statTime: this.statTime}).then(res => {
        if (res.data.code === 0) {
          this.projectCheckStatObj = res.data.data
        } else {
          this.$message.error(res.data.msg)
        }
      })
      const year = this.statTime.split('-')[0]
      const month = this.statTime.split('-')[1]
      getBarChartData({officeId: this.deptId, year, month}).then(res => {
        if (res.data.code === 0) {
          this.barChartList = res.data.data.map(el => {
            this.yhTotal += el.count
            return {
              name: el.name,
              value: el.count
            }
          })
          this.initCheckTimesCateGoryChart(this.barChartList)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    initCheckTimesCateGoryChart(data1) {
      // 提取标签（柱子下的名字）
      const categoryLabels = data1.map(item => item.name);

      // 遍历data数组，为每个对象添加itemStyle属性并赋予对应的颜色值
      data1.forEach((item, index) => {
        this.$set(item, "itemStyle", { color: this.cateGoryColor[index] });
      });

      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(
        document.getElementById("checkTimesCateGoryChart")
      );

      // 指定图表的配置项和数据
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          top: "20px",
          left: "20px",
          right: "20px", // 增加两侧留白
          bottom: "10px",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: categoryLabels, // 设置柱子下方的标签
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              interval: 0, // 显示所有标签
              rotate: 45, // 如果标签较长可设置旋转角度
              formatter: value => value, // 格式化标签显示
              color: "#615E83", // 标签颜色
              fontSize: 12, // 标签字体大小
            },
            axisLine: {
              lineStyle: {
                color: "#E6EFFF", // 修改 X 轴的颜色
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLine: {
              show: false, // 隐藏 Y 轴轴线
            },
            axisTick: {
              show: false, // 隐藏刻度
            },
            axisLabel: {
              show: true, // 显示数值
              color: "#615E83", // 数值颜色
              fontSize: 12, // 数值字体大小
            },
            splitLine: {
              show: true, // 显示横线
              lineStyle: {
                color: "#E6EFFF", // 横线颜色
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: "50%", // 调整柱子的宽度
            data: data1,
            label: {
              show: true,
              position: "outside",
            },
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    onHandleUnitChange(e) {
      this.deptId = e
      this.getData()
    },
    mtChangeDate(value) {
      this.statTime = moment(value).format('YYYY-MM');
      this.getData();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.ant-card {
  border-radius: 10px;
  border: none;
  .ant-card-body {
    padding: 15px;
  }
}
</style>