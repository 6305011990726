<!-- 入场审批 -->
<template>
    <div class="l_Approval">
      <div class="a_a_header">
        <!-- 表单封装 
            projectObj：下拉弹出框第一项对象
            Overview：项目名称
            baiduMapBoolen：控制地图显示
            topSubmit：控制上部分按钮显示
            bottomSubmit：控制下部分按钮显示
            handleSubmit：表单提交
            handleChange：输入出发
            handlSelect：输入下拉选择触发
        -->
        <form-index ref="formindex" :formData="formData" @handleSubmit="handleSubmit" :flexSubmit="true">
          <!-- 下部分按钮插槽 -->
          <template #flexSubmit>
            <a-button type="primary" html-type="submit"> 查询 </a-button>
          </template>
        </form-index>
      </div>
      <div class="a_a_footer">
        <div>
          <a-space class="operator">
            <a-button @click="doDowloadBatch(1)">批量通过</a-button>
          </a-space>
          <a-space class="operator">
            <a-button @click="doDowloadBatch(2)">批量驳回</a-button>
          </a-space>
          <a-space class="operator">
            <a-button @click="batchModify()">批量分配班组</a-button>
          </a-space>
        </div>
        <!-- 表格封装 -->
        <standard-table class="" :columns="columns" :dataSource="dataSource" :selectedRows.sync="selectedRows" rowKey="id"
          :scroll="{ x: 1300 }" :pagination="pagination" :loading="tableLoading" @change="onPageChange" :isAlert="false"
          :bordered="true" @update:selectedRows="updateSelectRows($event)">
          <template slot="index" slot-scope="{ index }">
            <span>
              {{
                (pagination.current - 1) * pagination.pageSize +
                parseInt(index) +
                1
              }}
            </span>
          </template>
          <div slot="action" slot-scope="{ record }">
            <div class="sTables">
              <div @click="doDowloadBatch(1,record)">通过</div>
              <div @click="doDowloadBatch(2,record)">驳回</div>
              <!-- <div @click="viewEditing(record)">更多></div> -->
              <a-popover placement="bottomRight" trigger="click">
                <template slot="content">
                  <div class="content_div">查看</div>
                  <div class="content_div" @click="batchModify(record)">分配班组</div>
                  <div class="content_div">分配宿舍</div>
                </template>
                <template slot="title">
                  <span>操作</span>
                </template>
                <div>更多></div>
              </a-popover>
            </div>
          </div>
        </standard-table>
      </div>
    </div>
  </template>
  
  <script>
  import formIndex from "@/pages/components/form/index";
  import StandardTable from "@/components/table/StandardTable";
  import { getApprovePersonList, teamPersonapprove,batchModifyPersonTeamId } from "@/services/projectManagement"; //测试数据
  import { dataTool } from "echarts/lib/echarts";
  import { useRender } from '@/hooks/useRender.js'

  const formData = [
    {
      label: "姓名",
      placeholder: "请填写",
      decorator: [
        "staffName",
        { rules: [{ required: false, message: "请填写" }] },
      ],
      type: "input",
      key: "staffName",
      col: 6,
      labelCol: 6,
      wrapperCol: 14,
      display: true,
    },
    {
      label: "身份证号",
      placeholder: "请填写",
      decorator: ["idCard", { rules: [{ required: false, message: "请填写" }] }],
      type: "input",
      key: "idCard",
      col: 6,
      labelCol: 6,
      wrapperCol: 14,
      display: true,
    },
    {
      label: "所属单位",
      placeholder: "请填写",
      decorator: [
        "officeName",
        { rules: [{ required: false, message: "请填写" }] },
      ],
      type: "input",
      key: "officeName",
      col: 6,
      labelCol: 6,
      wrapperCol: 14,
      display: true,
    },
    {
      label: "所属班组",
      placeholder: "请选择",
      decorator: ["teamName", { rules: [{ required: false, message: "请选择" }] }],
      type: "select",
      key: "teamName",
      selectlist: [],
      col: 6,
      labelCol: 6,
      wrapperCol: 14,
      display: true,
    },
    {
      label: "请假时间",
      placeholder: "请填写",
      decorator: [
        "enterDate",
        { rules: [{ required: false, message: "请填写" }] },
      ],
      type: "input",
      key: "enterDate",
      col: 6,
      labelCol: 6,
      wrapperCol: 14,
      display: true,
    },
  ];
  const columns = [
    {
      title: "序号",
      width: 65,
      scopedSlots: { customRender: "index" },
      // customRender: (text, record, index) => `${index + 1}`,
      align: "center",
    },
    {
      title: "姓名",
      dataIndex: "staffName",
      align: "center",
      scopedSlots: { customRender: "staffName" },
    },
    {
      title: "头像",
      dataIndex: "gender",
      scopedSlots: { customRender: "gender" },
    },
    {
      title: "年龄",
      dataIndex: "ptype",
      align: "center",
      scopedSlots: { customRender: "ptype" },
    },
    {
      title: "所属单位",
      align: "center",
      dataIndex: "officeName",
      scopedSlots: { customRender: "officeName" },
    },
    {
      title: "所属班组",
      dataIndex: "teamName",
      align: "center",
      ellipsis: true,
      scopedSlots: { customRender: "teamName" },
    },
    {
      title: "身份证号",
      dataIndex: "idCard",
      align: "center",
      scopedSlots: { customRender: "idCard" },
    },
    {
      title: "风险提示",
      dataIndex: "riskTips",
      align: "center",
      ellipsis: true,
      key: "riskTips",
    },
    {
      title: "扫码时间",
      dataIndex: "enterDate",
      align: "center",
      key: "enterDate",
      customRender: (text) => useRender.renderDate(text)
    },
    {
      title: "操作",
      align: "center",
      fixed: "right",
      width: 200,
      scopedSlots: { customRender: "action" },
    },
  ];
  export default {
    components: {
      formIndex,
      StandardTable,
    },
    data() {
      return {
        formData: formData, //form表单数据
        columns: columns, //table数据key对应
        dataSource: [], //table数组
        pagination: {
          //分页数据
          current: 1,
          pageSize: 10,
          total: 0,
        },
        tableLoading: false, //table加载中
        selectedRows: [], //选中的数据
        prams: { //测试数据
          pageNumber: 1,
          pageSize: 10,
        },
      };
    },
    created() {
      this.aqxjlist(this.prams);
    },
    methods: {
      updateSelectRows(e) {
        console.log(e)
      },
      // 测试数据
      doDowloadBatch(isPass,record) {
        if(!record){
          this.selectedRows.forEach(item => {
            item.isPass = isPass
          });
          const title = isPass == 1 ? '批量通过' : '批量驳回'
          const content = isPass == 1 ? `是否批量通过${this.selectedRows.length}条` : `是否批量驳回${this.selectedRows.length}条`
          this.teamPerson(this.selectedRows,title,content)
        }else{
          record.isPass = isPass
          const title = isPass == 1 ? '通过' : '驳回'
          const content = isPass == 1 ? `是否通过` : `是否驳回`
          this.teamPerson([record],title,content)
        }
      },
      // 测试数据
      ptypeState(type, status) {
        if (type == "ptype") {
          return status == 1 ? "房建" : "市政";
        } else {
          return status == 0 ? "在建" : status == 1 ? "完工" : "停工";
        }
      },
      teamPerson(prams,title,content) {
        this.$confirm({
          title: title,
          content: content,
          onOk() {
            teamPersonapprove(prams).then((res) => {
              console.log(res)
            })
          },
        })
      
      },
      // 分配班组
      batchModify(record){
        console.log(record)
        // batchModifyPersonTeamId(prams).then((res) => {
  
        // })
      },
      //测试数据
      aqxjlist(prams) {
        this.tableLoading = true;
        getApprovePersonList(prams).then((res) => {
          console.log(res)
          if (res.data.code === 0) {
            this.dataSource = res.data.data.list;
            this.pagination.total = res.data.data.total;
            // this.dataSource.forEach((item) => {
            //   item.ptype = this.ptypeState("ptype", item.ptype);
            //   item.status = this.ptypeState("status", item.status);
            // });
          }
          this.tableLoading = false;
        });
      },
      // 提交表单数据
      handleSubmit(e) {
        let obj = { ...e, ...this.prams }
        this.aqxjlist(obj);
      },
      //表格 - 分页查询
      onPageChange(e) {
        let obj = this.$refs["formindex"].getFieldValue();
        if (obj.status == "") {
          obj.status = 0;
        }
        this.prams.pageNumber = e.current;
        this.pagination.current = e.current;
        this.aqxjlist(Object.assign({}, this.prams, obj));
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  .l_Approval {
    background: #edf1fa;
  
    .a_a_header {
      background: #ffffff;
      border-radius: 10px;
      padding-top: 24px;
      padding-bottom: 12px;
  
      /deep/ .ant-form-item {
        margin-bottom: 15;
      }
  
      .plug-form[data-v-372326e8] .ant-form .ant-btn {
        margin-top: 5px;
        margin-left: 30px;
      }
    }
  
    .a_a_footer {
      margin-top: 10px;
      padding: 20px;
      background: #ffffff;
  
      .operator {
        margin-right: 8px;
  
        .ant-btn {
          color: #307dfa;
        }
      }
  
      .sTables {
        display: flex;
        justify-content: space-between;
        align-items: center;
  
        div {
          width: 100%;
          text-align: center;
          cursor: pointer;
        }
  
        div:nth-child(1) {
          color: #307dfa;
        }
  
        div:nth-child(2) {
          color: #ff5c77;
        }
  
        div:nth-child(3) {
          color: #615e83;
        }
      }
    }
  }
  
  .content_div {
    padding: 5px 0;
    cursor: pointer;
  }
  
  .content_div:hover {
    color: #307dfa;
  }
  </style>