<template>
  <div class="constructiontTable_index">
    <form-index ref="formindex" :formData="formData" :flexAction="true" :baiduMapBoolen="false">
      <!-- 下部分按钮插槽 -->
      <template #flexAction >
        <div class="flex a-center">
          <a-space v-if="teamList.length > 0">
            <a-button type="primary" @click="onChooseTeam" v-if="planDetail.status == 0 || planDetail.status == 2">选择班组</a-button>
            <a-button type="primary" @click="onGetAttendence" v-if="importAttendWay != 1" :loading="getAttendAgain">重新拉取考勤数据</a-button>
            <a-button type="primary" @click="exportAttend" v-if="planDetail.status != 0" :loading="downloadAttendLoading">导出考勤表</a-button>
            <a-button type="primary" @click="exportPayroll" v-if="planDetail.status != 0" :loading="downloadPayrollLoading">导出工资单</a-button>
            <a-button type="primary" @click="exportAll" v-if="planDetail.status != 0" :loading="downloadAllLoading">导出全套资料</a-button>
          </a-space>
          <span class="ml-2 font-bold">计划状态：{{getStatus(planDetail.status)}}</span>
        </div>
      </template>
    </form-index>
    <a-divider />
    <div>
      <div v-if="teamList.length > 0">
        <div class="mt-2 mb-2">
          <a-radio-group v-model="radioValue" @change="onRadioChange">
            <a-radio-button :value="1">
              考勤表
            </a-radio-button>
            <a-radio-button :value="2">
              工资单
            </a-radio-button>
          </a-radio-group>
          <!-- <a-button class="ml-2" type="primary" @click="uploadAttendSheet">下载考勤表</a-button> -->
        </div>
        <div>
          <a-collapse v-model="activeKey" expandIconPosition="right" :bordered="false">
            <a-collapse-panel v-for="(item, index) in teamList" :key="index + ''" :style="customStyle">
              <template slot="header">
                <div class="flex a-center j-center">
                  <span class="flex font-bold font-16 mr-3">{{ item.teamName }}</span>
                  <span
                    class="flex font-bold flex-4 font-16">工资月份：{{ moment(planDetail.doMonth).format('YYYY-MM') }}</span>
                  <div class="flex a-center" v-if="!activeKey.includes(index + '')">
                    <span class="flex font-bold font-16">共{{ item.wokerAttendRespVOList ? item.wokerAttendRespVOList.length : 0 }}项</span>
                    <span class="flex font-bold ml-3 font-16">应发总计：￥<span :style="isDiff(item)">{{item.payable}}</span></span>
                  </div>
                  <div v-else>
                    <div v-if="radioValue == 1" class="flex a-center custom-input-number">
                      <span class="font-16 w-100">不含税应发总额</span>
                      <a-input placeholder="" v-model="item.payable" type="number" @click.stop :disabled="item.type == 1" />
                      <a-button class="ml-2" type="primary" @click.stop="onGetPayroll(item)" v-if="item.type != 1">生成工资单</a-button>
                    </div>
                    <div v-else class="flex a-center custom-input-number font-bold font-16">
                      <span class="mr-2">共{{ item.wokerAttendRespVOList ? item.wokerAttendRespVOList.length : 0 }}项</span>
                      <span class="mr-2">不含税应发总计：￥{{item.payable}}</span>
                      <!-- <span class="mr-2">含税应发总计：￥1233</span> -->
                      <!-- <span>实发工资总计：￥1233</span> -->
                    </div>
                  </div>
                </div>
              </template>
              <!-- 考勤表 -->
              <div v-if="radioValue == 1">
                <standard-table class="" :columns="attColumns" :dataSource="item.wokerAttendRespVOList" rowKey="id" 
                  :scroll="{ x: 1800, y: 'calc(100vh - 450px)' }" :isAlert="false" :bordered="true" :pagination="pagination[item.id]" @change="onPageChange">
                  <div v-for="(columnItem, columnIndex) in attColumns" :key="columnIndex" :slot="columnItem.dataIndex"
                    slot-scope="{ record, index }">
                    <div>
                      <span v-if="columnItem.dataIndex === 'index'"> {{ (pagination[item.id].current - 1) * pagination[item.id].pageSize + parseInt(index) + 1 }} </span>
                      <span v-else-if="columnItem.dataIndex === 'staffName'">{{ record.staffName }}</span>
                      <span v-else-if="columnItem.dataIndex === 'idCard'">{{ record.idCard }}</span>
                      <span v-else-if="columnItem.dataIndex === 'days'">{{ record.days }}</span>
                      <div v-else-if="columnItem.dataIndex === 'payable'" class="custom-input-number">
                        <span v-if="!record.isEdit">{{ record.payable }}</span>
                        <a-input class="text-center" v-else placeholder="" v-model="record.payable" type="number"/>
                      </div>
                      <div v-else-if="columnItem.dataIndex === 'action'">
                        <div>
                          <a @click="edit(item, record)">{{ record.isEdit ? '确定' : '编辑' }}</a>
                          <a-divider type="vertical" v-if="item.type != 1"/>
                          <a style="color: #ff5c77;" @click="delWorker(record)" v-if="item.type != 1">删除</a>
                        </div>
                      </div>
                      <span v-else>
                        <div v-if="!record.isEdit">
                          <a-icon v-if="record[columnItem.dataIndex] == 1" type="check" />
                        </div>
                        <div v-else>
                          <a-checkbox @change="onCheckChange($event, item, record, columnItem.dataIndex)"
                            :checked="record[columnItem.dataIndex] == 1"></a-checkbox>
                        </div>
                      </span>
                    </div>
                  </div>
                </standard-table>
                <div class="mt-1">
                  <div class="flex a-center">
                    <div class="mr-1 font-bold">合计：
                      <span class="" :style="isDiff(item)">￥{{item.totalPayable || item.payable}}</span>
                    </div>
                    <!-- <a-tooltip>
                      <template slot="title">
                        此处合计指“不含税应发总额”拆分后应发金额的合计，因实际工人考勤天数不同，拆分后的应发金额合计可能与“不含税应发金额”不一致，请手动处理删减
                      </template>
                      <a-icon type="question-circle" />
                    </a-tooltip> -->

                    <!-- 上传附件暂时注释下面方法已经写好了 -->
                    <!-- <a-upload
                      :file-list="item.attachPath" :remove="upRemove" :data="item"
                      :customRequest="resourceUpload" list-type="picture-card" @preview="handlePreview"
                    >
                      <div v-if="!item.attachPath || item.attachPath.length < 1">
                        <a-icon type="plus" />
                        <div class="ant-upload-text">
                          上传
                        </div>
                      </div>
                    </a-upload> -->
                  </div>
                  <div class="flex flex-row-reverse">
                    <span class="ml-2 pointer" style="color: #ff5c77;" @click="delTeam(item)" v-if="planDetail.status == 0 || planDetail.status == 2"><a-icon type="delete" class="pr-1" />删除</span>
                    <span class="pointer" @click="resetAttendList(item)" v-if="planDetail.status == 0 || planDetail.status == 2"><a-icon type="redo" class="pr-1" />重置考勤表</span>
                  </div>
                </div>
              </div>
              <!-- 工资单 -->
              <div v-else>
                <standard-table class="" :columns="payrollColumns" :dataSource="item.wokerBaseRespVOList" rowKey="id" :scroll="{ x: 1800 }"
                  :isAlert="false" :bordered="true" :pagination="pagination[item.id]" @change="onPageChange">
                  <template slot="index" slot-scope="{ index }">
                    <span>{{ (pagination[item.id].current - 1) * pagination[item.id].pageSize + parseInt(index) + 1 }}</span>
                  </template>
                  <template slot="gender" slot-scope="{ record }">
                    <span>{{ record.idCard ? getGender(record.idCard) : '' }}</span>
                  </template>
                  <template slot="basic" slot-scope="{ record }">
                    <div class="custom-input-number">
                      <a-input class="text-center" placeholder="" v-model="record.basic" type="number" />
                    </div>
                  </template>
                  <template slot="performance" slot-scope="{ record }">
                    <div class="custom-input-number">
                      <a-input class="text-center" placeholder="" v-model="record.performance" type="number"
                        :disabled="true" />
                    </div>
                  </template>
                  <template slot="overMoney" slot-scope="{ record }">
                    <div class="custom-input-number">
                      <a-input class="text-center" placeholder="" v-model="record.overMoney" type="number" />
                    </div>
                  </template>
                  <template slot="hignTemp" slot-scope="{ record }">
                    <div class="custom-input-number">
                      <a-input class="text-center" placeholder="" v-model="record.hignTemp" type="number" />
                    </div>
                  </template>
                  <template slot="payable" slot-scope="{ record }">
                    <div class="custom-input-number">
                      <a-input class="text-center" placeholder="" v-model="record.payable" type="number" @change="onPayableChange($event, record)" />
                    </div>
                  </template>
                  <template slot="tax" slot-scope="{ record }">
                    <div class="custom-input-number">
                      <a-input class="text-center" placeholder="" v-model="record.tax" type="number" :disabled="true" />
                    </div>
                  </template>
                  <template slot="actual" slot-scope="{ record }">
                    <div class="custom-input-number">
                      <a-input class="text-center" placeholder="" v-model="record.actual" type="number" />
                    </div>
                  </template>
                  <template slot="ext1" slot-scope="{ record }">
                    <div class="custom-input-number">
                      <a-input class="text-center" placeholder="" v-model="record.ext1" type="number" />
                    </div>
                  </template>
                </standard-table>
              </div>
            </a-collapse-panel>
          </a-collapse>
        </div>
        <div class="btn-box">
          <a-space :size="20">
            <a-button @click="close">返回</a-button>
            <a-button type="primary" ghost style="background: #fd9e06 !important; color: #fff; border-color:#fd9e06"
              @click="onSave(0)" v-if="planDetail.status == 0 || planDetail.status == 2">暂存</a-button>
            <a-button type="primary" @click="onSave(1)" v-if="planDetail.status == 0 || planDetail.status == 2">提交</a-button>
          </a-space>
        </div>
      </div>
      <div v-else>
        <div class="flex a-center j-center flex-column empty-box">
          <span>暂无数据，请导入考勤表</span>
          <a-button class="mt-3" type="primary" @click="onChooseTeam">选择本月发放班组</a-button>
        </div>
      </div>
    </div>
    <create-payroll-dialog ref="payrollDialog" :visible="distributeVisible" :planDetail="planDetail" @cancel="cancelDialog"
      @confirm="confirmDialog" />
  </div>
</template>

<script>
import StandardTable from "@/components/table/StandardTable";
import formIndex from "@/pages/components/form/index";
import CreatePayrollDialog from '@/pages/payroll/dialogs/createPayrollDialog';
import { mapGetters } from "vuex";
import moment from 'moment';
import { getSalaryPlan, getEditPlans, saveSalaryPlanChange, delSalaryTeam, getSystemAttendData, getJwWorkAttend, exportAttend, exportBaisc, exportAll, updateSalaryPlan } from '@/services/payroll'
import { ATTACHMENT_UPLOAD } from '@/services/api'
import { request } from '@/utils/request'
import _ from "lodash";

const formData = [
  {
    label: "项目名称",
    placeholder: "请输入项目名称",
    decorator: [
      "projectName",
      { rules: [{ required: false, message: "请输入项目名称" }] },
    ],
    type: "inputGroup",
    key: "projectName",
    dataSource: [],
    col: 8,
    labelCol: 8,
    wrapperCol: 15,
    display: true,
    disabled: true
  },
  {
    label: "企业",
    placeholder: "请选择",
    decorator: ["officeName", { rules: [{ required: false, message: "请选择" }] }],
    type: "select",
    key: "officeName",
    selectlist: [],
    col: 8,
    labelCol: 8,
    wrapperCol: 15,
    display: true,
    showSearch: true,
    disabled: true
  },
  {
    label: "分包合同编号",
    placeholder: "请输入合同编号",
    decorator: [
      "contractNo",
      { rules: [{ required: false, message: "请输入合同编号" }] },
    ],
    type: "inputGroup",
    key: "contractNo",
    dataSource: [],
    col: 8,
    labelCol: 8,
    wrapperCol: 15,
    display: true,
    disabled: true
  },
  {
    label: "本次发放工资月份",
    placeholder: "请选择",
    decorator: ["doMonth", { initialValue: moment(moment()).format("YYYYMM"), rules: [{ required: false, message: "请选择" }] },],
    type: "monthPicker",
    key: "doMonth",
    col: 8,
    labelCol: 8,
    wrapperCol: 15,
    display: true,
    format: "YYYYMM",
    styles: 'width: 100%',
    disabled: true
  },
  {
    label: "计划不含税应发金额",
    placeholder: "请输入计划不含税应发金额",
    decorator: [
      "wages",
      { rules: [{ required: false, message: "请输入计划不含税应发金额" }] },
    ],
    type: "input",
    key: "wages",
    dataSource: [],
    col: 8,
    labelCol: 8,
    wrapperCol: 15,
    display: true,
  },
  {
    label: "账户类型",
    placeholder: "请选择账户类型",
    decorator: ["accountType", { rules: [{ required: false, message: "请选择账户类型" }] },],
    type: "radio",
    key: "accountType",
    radiolist: [
      {
        name: "专户",
        value: 2,
      },
      {
        name: "普户",
        value: 1,
      },
    ],
    col: 8,
    labelCol: 8,
    wrapperCol: 15,
    display: true,
    disabled: true
  },
  {
    type: "action",
    col: 24,
    display: true
  },
];
const attColumns = [
  {
    title: "序号",
    dataIndex: "index",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
    fixed: 'left'
  },
  {
    title: "姓名",
    dataIndex: "staffName",
    width: 85,
    align: "center",
    scopedSlots: { customRender: "staffName" },
    fixed: 'left'
  },
  {
    title: "身份证号",
    dataIndex: "idCard",
    align: "center",
    width: 160,
    scopedSlots: { customRender: "idCard" },
    fixed: 'left'
  },
  {
    title: "考勤天数",
    dataIndex: "days",
    align: "center",
    width: 65,
    scopedSlots: { customRender: "days" },
    fixed: 'left'
  },
  {
    title: "应发金额",
    dataIndex: "payable",
    align: "center",
    width: 120,
    scopedSlots: { customRender: "payable" },
    fixed: 'left'
  },
];
const payrollColumns = [
  {
    title: "序号",
    dataIndex: "index",
    width: 50,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
  },
  {
    title: "姓名",
    dataIndex: "staffName",
    width: 85,
    align: "center",
    scopedSlots: { customRender: "staffName" },
  },
  {
    title: "性别",
    dataIndex: "gender",
    width: 65,
    align: "center",
    scopedSlots: { customRender: "gender" },
  },
  {
    title: "身份证号",
    dataIndex: "idCard",
    align: "center",
    width: 160,
    scopedSlots: { customRender: "idCard" },
  },
  {
    title: "银行卡号",
    dataIndex: "bankNo",
    align: "center",
    width: 160,
    scopedSlots: { customRender: "bankNo" },
  },
  {
    title: "基本工资（元）",
    dataIndex: "basic",
    align: "center",
    width: 120,
    scopedSlots: { customRender: "basic" },
  },
  {
    title: "绩效工资（元）",
    dataIndex: "performance",
    align: "center",
    width: 120,
    scopedSlots: { customRender: "performance" },
  },
  {
    title: "加班工资（元）",
    dataIndex: "overMoney",
    align: "center",
    width: 120,
    scopedSlots: { customRender: "overMoney" },
  },
  {
    title: "高温津贴（元）",
    dataIndex: "hignTemp",
    align: "center",
    width: 120,
    scopedSlots: { customRender: "hignTemp" },
  },
  {
    title: "其他",
    dataIndex: "ext1",
    align: "center",
    width: 120,
    scopedSlots: { customRender: "ext1" },
  },
  {
    title: "应发工资（元）",
    dataIndex: "payable",
    align: "center",
    width: 120,
    scopedSlots: { customRender: "payable" },
  },
  {
    title: "代扣个人所得税（元）",
    dataIndex: "tax",
    align: "center",
    width: 120,
    scopedSlots: { customRender: "tax" },
  },
  {
    title: "实发工资（元）",
    dataIndex: "actual",
    align: "center",
    width: 120,
    scopedSlots: { customRender: "actual" },
  },
  {
    title: "手机号",
    dataIndex: "phone",
    align: "center",
    width: 130,
    scopedSlots: { customRender: "phone" },
  },
];
export default {
  inject: ["fatherMethod"],
  components: {
    StandardTable, //表格封装
    formIndex,
    CreatePayrollDialog
  },
  data() {
    return {
      id: '',
      importAttendWay: null,//获取考勤表的路径
      formData: formData,
      distributeVisible: false,
      radioValue: 1,
      activeKey: ['0'],
      attColumns: attColumns,
      payrollColumns: payrollColumns,
      customStyle:
        'background: #fff;margin-bottom: 16px;border: 0;overflow: hidden;box-shadow: 0px 0px 10px #ccc;',
      planDetail: {
        doMonth: ''
      },
      teamList: [],
      delWorkerList: [],
      delPayrollList: [],
      getAttendAgain: false,
      downloadAttendLoading: false,
      downloadPayrollLoading: false,
      downloadAllLoading: false,
      pagination: {},
    };
  },
  watch: {
    activeKey(key) {
      console.log(key);
    },
    "planDetail.doMonth": {
      handler(newVal, oldVal) {
        // if (oldVal) {
        //   const oldDays = moment(oldVal, "YYYY-MM").daysInMonth();
        //   for (let i = 0; i < oldDays; i++) {
        //     this.attColumns.pop();
        //   }
        // }
        this.attColumns = _.cloneDeep(attColumns)
        const newDays = moment(newVal, "YYYY-MM").daysInMonth();
        console.log(newDays, 'newDays')
        for (let i = 1; i <= newDays; i++) {
          // let day = i < 10 ? "0" + i : i + "";
          this.attColumns.push({
            title: i,
            dataIndex: `d${i}`,
            align: "center",
            width: 55,
            scopedSlots: { customRender: `d${i}` },
          });
        }
        if (this.planDetail.status == 0 || this.planDetail.status == 2) {
          this.attColumns.push({
            title: "操作",
            align: "center",
            width: 120,
            dataIndex: 'action',
            fixed: "right",
            scopedSlots: { customRender: "action" },
          },);
        }
        console.log(this.attColumns, 'this.attColumns')
      },
      immediate: true,
    },
    teamList: {
      handler(val) {
        val.forEach(el => {
          // this.getHignTemp(el.wokerAttendRespVOList, el.wokerBaseRespVOList)
          if (el.wokerBaseRespVOList) {
            el.wokerBaseRespVOList.forEach(item => {
              if (item.payable > 2300) {
                if (item.payable - item.hignTemp - item.ext1 > 2300) {
                  item.performance = (item.payable - item.ext1 - item.hignTemp - item.basic - item.overMoney).toFixed(2)
                } else {
                  item.performance = 0
                }
              } else {
                item.performance = 0
              }
            })
          }
        })
        console.log(val, 'teamList')
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters("account", ["user"]),
    // currentMonth() {
    //   return moment().format('M')
    // }
  },
  activated() {
    this.id = this.$route.query.id
    this.getSalaryPlan()
    this.getEditPlans()
  },
  methods: {
    moment,
    uploadAttendSheet() {

    },
    getGender(idCardNumber) {
      // 获取身份证号的第17位数字
      var genderCode = parseInt(idCardNumber.charAt(16));
      // 判断性别并返回结果
      return genderCode % 2 === 0 ? '女' : '男';
    },
    onRadioChange(e) {
      this.radioValue = e.target.value
    },
    getSalaryPlan() {
      getSalaryPlan({ id: this.id }).then(res => {
        if (res.data.code == 0) {
          this.planDetail = res.data.data
          this.$refs.formindex.queryObj(this.planDetail)
          if (this.planDetail.status != 0 && this.planDetail.status != 2) {
            this.formData.forEach(el => {
              if (el.key === 'wages') {
                el.disabled = true
              }
            })
          } else {
            this.formData.forEach(el => {
              if (el.key === 'wages') {
                el.disabled = false
              }
            })
          }
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    getEditPlans() {
      getEditPlans({ id: this.id }).then(res => {
        if (res.data.code == 0) {
          this.teamList = res.data.data.teamRespVOList
          this.importAttendWay = res.data.data.type
          this.teamList.forEach(team => {
            if (team.wokerAttendRespVOList) {
              this.pagination[team.id] = {
                //分页数据
                current: 1,
                pageSize: 10,
                total: team.wokerAttendRespVOList.length,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
                showTotal: (total, range) => `共 ${total} 条`
              }
              team.type = this.importAttendWay
              team.payable = 0
              team.wokerAttendRespVOList.forEach(staff => {
                team.payable += staff.payable
              })
              team.payable = team.payable.toFixed(2)
            }
            if (team.wokerAttendRespVOList && team.wokerBaseRespVOList) {
              this.getHignTemp(team.wokerAttendRespVOList, team.wokerBaseRespVOList, res.data.data.doMonth)
            }
          })
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    /**
     * 计算高温补贴
     * @param {*} attend 考勤表
     * @param {*} payroll 工资单
     */
    getHignTemp(attend, payroll, month) {
      if (Number(moment(month).format('M')) > 5 && Number(moment(month).format('M')) < 11) {
        if (attend && payroll) {
          for (let obj1 of attend) {
            for (let obj2 of payroll) {
              // 如果 planTeamId 和 staffName 都匹配
              if (obj1.planTeamId === obj2.planTeamId && obj1.staffName === obj2.staffName) {
                //获取考勤条数
                let days = obj1.days
                if (days > 15) {
                  obj2.hignTemp = 300
                } else if (days > 0 && days <= 15) {
                  obj2.hignTemp = (13.8 * days).toFixed(2)
                }
              }
            }
          }
        }
      } else {
        payroll.forEach(pay => {
          pay.hignTemp = 0
        })
      }
    },
    /**
     * item: 当前的班组
     * record：当前的工人
     */
    edit(item, record) {
      if (record.isEdit) {
        //确定
        this.teamList.forEach((team, index) => {
          this.getHignTemp(team.wokerAttendRespVOList, team.wokerBaseRespVOList)
          if (item.id == team.id && team.wokerAttendRespVOList && team.wokerBaseRespVOList) {
            let totalPayable = 0;
            team.wokerAttendRespVOList.forEach((attend, attendIndex) => {
              if (attend.id == record.id) {
                this.$set(this.teamList[index].wokerAttendRespVOList[attendIndex], 'isEdit', false)
              }
              totalPayable += Number(attend.payable);
              team.wokerBaseRespVOList.forEach(pay => {
                if (attend.planTeamId === pay.planTeamId && attend.staffName === pay.staffName) {
                  pay.payable = attend.payable
                  if (attend.days > 20) {
                    pay.ext1 = 1151
                  } else {
                    pay.ext1 = 58 * attend.days
                  }
                }
              })
            })
            this.$set(this.teamList[index], 'totalPayable', totalPayable.toFixed(2))
          }
        })
      } else {
        //编辑
        this.teamList.forEach((team, index) => {
          if (item.id == team.id && team.wokerAttendRespVOList) {
            team.wokerAttendRespVOList.forEach((attend, attendIndex) => {
              if (attend.id == record.id) {
                // attend.isEdit = true
                this.$set(this.teamList[index].wokerAttendRespVOList[attendIndex], 'isEdit', true)
              }
            })
          }
        })
      }
    },
    onGetPayroll(item) {
      let totalDays = 0;
      item.wokerAttendRespVOList.forEach(worker => {
        totalDays += worker.days;
      });
      const payablePerDay = item.payable / totalDays;
      //拆分工资
      item.wokerAttendRespVOList.forEach(worker => {
        worker.payable = (payablePerDay * worker.days).toFixed(2)
        item.wokerBaseRespVOList.forEach(pay => {
          if (worker.planTeamId === pay.planTeamId && worker.staffName === pay.staffName) {
            pay.payable = worker.payable
            if (worker.days > 20) {
              pay.ext1 = 1151
            } else {
              pay.ext1 = Number(58 * worker.days).toFixed(2)
            }
            if (pay.payable - pay.hignTemp - pay.ext1 > 2300) {
              pay.basic = 2300
            } else {
              pay.basic = Number(pay.payable - pay.hignTemp - pay.ext1).toFixed(2)
            }
          }
        })
      });
      this.$message.success('工资单已生成！')
    },
    onCheckChange(e, item, record, columnItem) {
      this.teamList.forEach((team) => {
        if (item.id == team.id && team.wokerAttendRespVOList) {
          team.wokerAttendRespVOList.forEach((staff) => {
            if (staff.id == record.id) {
              if (e.target.checked) {
                staff.days++
                staff[columnItem] = 1
              } else {
                staff.days--
                staff[columnItem] = 0
              }
            }
          })
        }
      })
    },
    onPayableChange(e, record) {
      this.teamList.forEach(team => {
        if (team.wokerAttendRespVOList) {
          team.wokerAttendRespVOList.forEach(staff => {
            if (team.id == record.planTeamId && staff.staffName == record.staffName) {
              staff.payable = record.payable
            }
          })
        }
      })
      // this.$forceUpdate()
    },
    onChooseTeam() {
      this.distributeVisible = true
      if (this.teamList.length > 0) {
        this.$nextTick(() => {
          this.$refs['payrollDialog'].setSelectedRows(this.teamList)
        })
      }
    },
    cancelDialog(e) {
      this.distributeVisible = e
    },
    confirmDialog(e) {
      this.distributeVisible = false
      this.getEditPlans()
    },
    //0暂存，1确定按钮
    onSave(e) {
      try {
        let teamPlansList = []
        this.teamList.forEach(el => {
          let staffVos = []
          let wagesVos = []
          el.wokerAttendRespVOList.forEach(item => {
            staffVos.push({
              ...item,
              attendId: item.id,
              doStatus: 0
            })
          })
          this.delWorkerList.forEach(item => {
            if (item.planTeamId === el.id) {
              staffVos.push({
                ...item,
                attendId: item.id,
                doStatus: 1
              })
            }
          })
          el.wokerBaseRespVOList.forEach(item => {
            if (Number(item.basic) < 0 || Number(item.performance) < 0 || Number(item.payable) < 0) {
              throw Error('工资单出现负数，请检查再提交')
            }
            wagesVos.push({
              ...item,
              basicId: item.id,
              doStatus: 0
            })
          })
          this.delPayrollList.forEach(item => {
            if (item.planTeamId === el.id) {
              wagesVos.push({
                ...item,
                attendId: item.id,
                doStatus: 1
              })
            }
          })
          let tempObj = {
            planTeamId: el.id,
            payable: el.totalPayable || el.payable,
            teamId: el.teamId,
            teamName: el.teamName,
            // type: el.type,
            type: 1,
            staffVos,
            wagesVos,
            doStatus: 0,
          }
          if (el.attachPath && el.attachPath.length > 0) tempObj.attachPath = el.attachPath[0].url
          teamPlansList.push(tempObj)
        })
        let params = {
          planId: this.planDetail.id,
          payable: this.planDetail.wages,
          status: e,
          teamPlansList
        }
        // console.log(this.planDetail, 'this.planDetail')
        // console.log(params, 'params')
        // if (!this.$refs.formindex.getFieldValue().wages) {
        //   this.$message.error('请填写计划不含税应发金额')
        //   return
        // }
        updateSalaryPlan(Object.assign(this.planDetail, this.$refs.formindex.getFieldValue())).then(updateRes => {
          if (updateRes.data.code == 0) {
            saveSalaryPlanChange(params).then(res => {
              if (res.data.code == 0) {
                this.$message.success(res.data.msg)
                this.close()
              } else {
                this.$message.error(res.data.msg)
              }
            })
          } else {
            this.$message.error(updateRes.data.msg)
          }
        })
      } catch (error) {
        console.log(error)
        this.$message.error('工资单出现负数，请检查再提交')
      }
    },
    // 删除文件
    upRemove(file) {
      this.teamList.forEach(el => {
        if (el.id === file.key) {
          el.attachPath = []
        }
      })
    },
    // 预览图片
    handlePreview(file) {
      window.open(file.url);
    },
    // 上传资源
    resourceUpload(value) {
      const formData = new FormData();
      formData.append('file', value.file);
      console.log(value, 'valuevaluevalue')
      const teamListCache = _.cloneDeep(this.teamList)
      // 图片上传
      request(ATTACHMENT_UPLOAD, "post", formData).then(res => {
        if (res.data.code === 0) {
          const fileObj = {
            uid: res.data.data.id, //图片id
            name: res.data.data.name, //图片名称
            url: res.data.data.path, //图片地址
            key: value.data.id, //获取对应的上传key名 ，后续移除图片用
            status: 'done',
          }
          teamListCache.forEach(el => {
            if (el.id === value.data.id) {
              el.attachPath = []
              el.attachPath.push(fileObj)
            }
          })
          this.teamList = teamListCache
          // this.$forceUpdate()
          // console.log(this.teamList, 'this.teamList')
        } else {
          this.$message.warning(res.message);
        }
      })
    },
    delTeam(record) {
      const _that = this
      this.$confirm({
        title: '确认删除？',
        onOk() {
          delSalaryTeam({ id: record.id }).then(res => {
            if (res.data.code == 0) {
              _that.teamList = _that.teamList.filter(el => el.id != record.id)
              _that.$message.success(res.data.msg)
            } else {
              _that.$message.error(res.data.msg)
            }
          })
        }
      })
    },
    delWorker(record) {
      const _that = this
      this.$confirm({
        title: '确认删除？',
        onOk() {
          const { planTeamId, staffName } = record
          // 删除 wokerAttendRespVOList 中符合条件的项，同时删除 wokerBaseRespVOList 中相同 planTeamId 和 staffName 的项
          _that.teamList.forEach(team => {
              team.wokerAttendRespVOList.forEach((item, index) => {
                  if (item.planTeamId === planTeamId && item.staffName === staffName) {
                    // 保存被删除的 wokerAttendRespVOList 中的项
                    const deletedAttendItem = team.wokerAttendRespVOList.splice(index, 1)[0];
                    deletedAttendItem.doStatus = 1
                    _that.delWorkerList.push(deletedAttendItem);

                    // 删除 wokerBaseRespVOList 中相同 planTeamId 和 staffName 的项
                    const deletedBaseItemIndex = team.wokerBaseRespVOList.findIndex(baseItem => baseItem.planTeamId === deletedAttendItem.planTeamId && baseItem.staffName === deletedAttendItem.staffName);
                    if (deletedBaseItemIndex !== -1) {
                      const deletedBaseItem = team.wokerBaseRespVOList.splice(deletedBaseItemIndex, 1)[0];
                      deletedBaseItem.doStatus = 1
                      _that.delPayrollList.push(deletedBaseItem);
                    }
                  }
              });
          });
        }
      })
    },
    resetAttendList(record) {
      const _that = this
      this.$confirm({
        title: '确认重置？',
        onOk() {
          getEditPlans({ id: _that.id }).then(res => {
            if (res.data.code == 0) {
              let tempTeamList = res.data.data.teamRespVOList
              let tempTeam = null
              tempTeamList.forEach(el => {
                if (el.id === record.id) {
                  tempTeam = el
                  tempTeam.type = _that.importAttendWay
                }
              })
              _that.teamList = _that.teamList.map(team => {
                if (team.id === tempTeam.id) {
                  return team = tempTeam
                } else {
                  return team
                }
              })
            } else {
              _that.$message.error(res.data.msg)
            }
          })
        }
      })
    },
    close() {
      let path = this.$route.path;
      this.fatherMethod(`${path}`);
      this.$router.push({
        name: "项目工资发放计划",
      });
    },
    isDiff(item) {
      if (item.totalPayable) {
        if (item.totalPayable == item.payable) {
          return ''
        } else {
          return 'color: #ff5c77'
        }
      } else {
        return ''
      }
    },
    onGetAttendence() {
      if (this.importAttendWay) {
        this.getAttendAgain = true
        let path = null
        this.importAttendWay === 2 ? path = getSystemAttendData : path = getJwWorkAttend
        path({planId: this.planDetail.id}).then(res => {
          this.getAttendAgain = false
          if (res.data.code == 0) {
            this.importAttendModal = false
            this.getEditPlans()
          } else {
            this.$message.error(res.data.msg)
          }
        })
      }
    },
    exportAttend() {
      this.downloadAttendLoading = true
      exportAttend({ planId: this.id }).then(res => {
        console.log(res)
        let fileName = "考勤表.xlsx";
        let downloadElement = document.createElement('a')//创建dom
        let href = window.URL.createObjectURL(res.data) //创建下载链接
        downloadElement.href = href
        downloadElement.download = fileName //下载后文件名
        document.body.appendChild(downloadElement)//添加创建的节点
        downloadElement.click() //点击下载
        document.body.removeChild(downloadElement) //下载完成移除元素
        window.URL.revokeObjectURL(href) //释放掉blob对象
      }).finally(() => {
          this.downloadAttendLoading = false;
      })
    },
    exportPayroll() {
      this.downloadPayrollLoading = true
      exportBaisc({ planId: this.id }).then(res => {
        console.log(res)
        let fileName = "工资单.xlsx";
        let downloadElement = document.createElement('a')//创建dom
        let href = window.URL.createObjectURL(res.data) //创建下载链接
        downloadElement.href = href
        downloadElement.download = fileName //下载后文件名
        document.body.appendChild(downloadElement)//添加创建的节点
        downloadElement.click() //点击下载
        document.body.removeChild(downloadElement) //下载完成移除元素
        window.URL.revokeObjectURL(href) //释放掉blob对象
      }).finally(() => {
          this.downloadPayrollLoading = false;
      })
    },
    exportAll() {
      this.downloadAllLoading = true
      exportAll({ planId: this.id }).then(res => {
        console.log(res)
        let fileName = "全套资料.zip";
        let downloadElement = document.createElement('a')//创建dom
        let href = window.URL.createObjectURL(res.data) //创建下载链接
        downloadElement.href = href
        downloadElement.download = fileName //下载后文件名
        document.body.appendChild(downloadElement)//添加创建的节点
        downloadElement.click() //点击下载
        document.body.removeChild(downloadElement) //下载完成移除元素
        window.URL.revokeObjectURL(href) //释放掉blob对象
      }).finally(() => {
          this.downloadAllLoading = false;
      })
    },
    getStatus(e) {
      switch (e) {
        case 0:
          return '草稿'
        case 1:
          return '待财务审核'
        case 2:
          return '财务驳回'
        case 3:
          return '已汇总工资单'
        case 4:
          return '已完成'
        default:
          break;
      }
    },
    //表格 - 分页查询
    onPageChange(pagination, filters, sorter, { currentDataSource }) {
      if (currentDataSource.length > 0) {
        let paginationCache = _.cloneDeep(this.pagination)
        paginationCache[currentDataSource[0].planTeamId].pageSize = pagination.pageSize;
        paginationCache[currentDataSource[0].planTeamId].current = pagination.current;
        this.pagination = paginationCache
      }
    },
  },
};
</script>

<style lang="less" scoped>
.constructiontTable_index {
  padding: 24px;
  background: #fff;
  border-radius: 6px;
  margin-bottom: 10px;
  min-height: 560px;

  /deep/ .light-select {
    border-color: #fd9e06;
    width: 155px;

    .ant-select-selection__placeholder {
      color: #fff
    }

    .ant-select-selection {
      background: #fd9e06;
      border-color: #fd9e06;
      color: #fff;

      .ant-select-arrow {
        color: #fff;
      }
    }

  }
}

/deep/ .ant-modal-footer {
  text-align: center;
  border-top: none;
  padding-bottom: 24px;
}

/deep/ .ant-modal-body {
  padding-bottom: unset;
}

.btn-box {
  margin-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row-reverse;
}

.empty-box {
  height: 500px;
}

// inputNumber 去掉右侧加减箭头
.custom-input-number /deep/ input::-webkit-outer-spin-button,
.custom-input-number /deep/ input::-webkit-inner-spin-button {
  appearance: none;
}
</style>