<template>
  <a-modal v-model="myVisible" centered :destroyOnClose="true" :width="1600" :footer="null" :maskClosable="false" :keyboard="false" @cancel="cancel">
    <template slot="title">
      <h3 class="flex a-center j-center font-bold">
        {{modalTitle}}
      </h3>
    </template>
    <a-spin :spinning="spinning">
      <!-- <h3 class="title">{{ title }}</h3> -->
      <form-index ref="zdbContent" :flexAction="true"
      :formData="zdbContentData"
      @handleChange="handleChange"
      @handlSelect="handlSelect"
      @handleSubmit="handleSubmit"
      >
        <!-- 下部分按钮插槽 -->
        <template #flexAction>
          <a-space :style="{ marginTop: '4px' }">
            <a-button icon="search" type="primary" html-type="submit">查询</a-button>
            <a-button @click="close"> 重置 </a-button>
          </a-space>
        </template>
      </form-index>
      <template>
        <div class="content">
          <standard-table
            class=""
            :columns="columns"
            :dataSource="dataSource"
            rowKey="summaryMonth"
            :scroll="{ x: 700 }"
            :pagination="pagination"
            @change="onPageChange"
            :loading="tableLoading"
            :isAlert="false"
            :bordered="true"
          >
            <template slot="index" slot-scope="{ index }">
              <span>
                {{
                  (pagination.current - 1) * pagination.pageSize +
                    parseInt(index) +
                    1
                }}
              </span>
            </template>
            <template slot="projectName" slot-scope="{ text }">
              <a-tooltip placement="topLeft">
                <template slot="title"
                  ><span>{{ text }}</span></template
                >
                <span class="ant-btn-text ellipsis-2">{{ text  }}</span>
              </a-tooltip>
            </template>
            <template slot="action" slot-scope="{ record }">
              <div>
                <a @click="viewEditing(record)">查看工人</a>
              </div>
            </template>
          </standard-table>
        </div>
      </template>
      <div class="btn-box">
        <a-space :size="10">
          <!-- <a-button :type="primary ? 'primary' : ''" @click="cancel('cancelBtn')" v-if="showCancelBtn">{{cancelBtnText}}</a-button>
          <a-button :type="primary ? '' : 'primary'" v-if="showConfirmBtn" @click="nextStepBtn" :disabled="HKTask">{{confirmBtnText}}</a-button>
          <a-button type="primary" v-if="showOverBtn" @click="cancel('cancelBtn')" :disabled="HKTask">完成</a-button> -->
          <a-button type="primary" @click="over('cancelBtn')">关闭</a-button>
        </a-space>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import StandardTable from "@/components/table/StandardTable";
import { getProStaffPayrollDetail } from '@/services/payroll'
import moment from 'moment';
import formIndex from "@/pages/components/form/index";
import { getOfficeProject } from "@/services/projectManagement";
import {getAllTeams} from "@/services/participationUnit"
import { mapGetters} from "vuex";
const columns = [
{
        title: "序号",
        width: 65,
        scopedSlots: { customRender: "index" },
        align: "center",
      },
      {
        title: "姓名",
        dataIndex: "name",
        align: "center",
        width: 80,
        scopedSlots: { customRender: "projectName" },
      },
      {
        title: "性别",
        dataIndex: "sex",
        align: "center",
        width: 80,
        ellipsis: true,
      },
      {
        title: "项目名称",
        dataIndex: "projectName",
        align: "center",
        ellipsis: true,
        width: 120,
      },
      {
        title: "班组名称",
        align: "center",
        dataIndex: "teamName",
        ellipsis: true,
        width: 120,
      },
      {
        title: "身份证号码",
        dataIndex: "idCard",
        align: "center",
        // ellipsis: true,
        // fixed: "left",
      },
      {
        title: "银行卡号",
        dataIndex: "bankCard",
        align: "center",
      },
      {
        title: "基本工资（元）",
        align: "center",
        dataIndex: "baseMoney",
        width: 80,
        scopedSlots: { customRender: "teamCount" },
      },
      {
        title: "加班工资（元）",
        dataIndex: "overtimeMoney",
        align: "center",
        width: 80,
        scopedSlots: { customRender: "peopleCount" },
      },
      {
        title: "高温津贴（元）",
        dataIndex: "highTempSubsidy",
        align: "center",
        width: 80,
        scopedSlots: { customRender: "peopleCount" },
      },
      {
        title: "代扣个人所得税（元）",
        dataIndex: "tax",
        align: "center",
        width: 120,
        scopedSlots: { customRender: "peopleCount" },
      },
      {
        title: "实发工资（元）",
        dataIndex: "actualMoney",
        align: "center",
        width: 80,
        scopedSlots: { customRender: "peopleCount" },
      },

      {
        title: "绩效工资（元）",
        dataIndex: "achievementsMoney",
        align: "center",
        width: 80,
        scopedSlots: { customRender: "peopleCount" },
      },
      {
        title: "手机号",
        dataIndex: "phone",
        align: "center",
        width: 130,
      },
      {
        title: '在场状态',
        dataIndex: 'workStatus',
        key: 'workStatus',
        align: "center",
        customRender: (text) => {
            return text == '' ?  text : text == 1 ? '在场' : (text == 2 ? '离场' : '未录入')
        }
      },
      // {
      //   title: "操作",
      //   align: "center",
      //   width: 100,
      //   // fixed: "right",
      //   scopedSlots: { customRender: "action" },
      // },
    ];
const zdbContentData = [
  {
    label: "制表月份",
    placeholder: "请选择制表月份",
    decorator: [
      "searchMonth",
      { rules: [{ required: false, message: "请选择制表月份" }] },
    ],
    type: "monthPicker",
    key: "searchMonth",
    col: 5,
    labelCol: 6,
    wrapperCol: 16,
    display: true,
    format: "YYYYMM",
  },
  {
    label: "项目名称",
    placeholder: "请输入项目名称",
    decorator: [
      "projectId",
      { rules: [{ required: false, message: "请输入项目名称" }] },
    ],
    type: "inputGroup",
    key: "projectId",
    dataSource: [],
    col: 5,
    labelCol: 6,
    wrapperCol: 16,
    display: true,
  },
  {
    label: "班组",
    placeholder: "请输入选择班组",
    decorator: ["teamId", { rules: [{ required: false, message: "请输入选择班组" }] }],
    type: "select",
    key: "teamId",
    selectlist: [],
    col: 5,
    labelCol: 6,
    wrapperCol: 16,
    display: true,
    showSearch: true
  },
  {
    type: "action",
    col: 6,
    display: true,
  },
];
export default {
  components: {
    StandardTable,
    formIndex
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
      require: true
    },
    modalTitle: {
      type: String,
      default: '',
      require: true
    },
    workNumList: {
      type: Object,
      default() {
        return {}
      },
      require: true
    },
  },
  data() {
    return {
      tabPosition: 1,
      title: '汇总明细',
      cancelBtnText: '返回修改',
      confirmBtnText: '下一步',
      showCancelBtn: true,
      primary: true,
      showConfirmBtn: true,
      showOverBtn: false,
      hkAll: 0,
      hkSuccess: 0,
      hkFail: 0,
      hkerrList: [],
      hkProgress: 0,
      yqAll: 0,
      yqSuccess: 0,
      yqFail: 0,
      yqProgress: 0,
      threadId: 0,
      spinning: false,
      columns: columns,
      dataSource: [],
      tableLoading: false, //table加载中
      pagination: {
        //分页数据
        current: 1,
        pageSize: 10,
        total: 0,
      },
      showFailBtn: false,
      yqerrList: [],
      HKTask: false, // 判断第三步海康完成
      zdbContentData,
      params: {
        pageNumber: 1,
        pageSize: 50,
      },
      projectArr: [],
      projectList: []
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
    myVisible: {
      get() {
        return this.visible
      },
      set() {}
    },
    totalFail: {
      get() {
        return this.hkFail === this.hkAll && this.yqFail === this.yqAll
      },
      set() {}
    }
  },
  watch: {
    visible: {
      handler(newVal) {
        if (newVal) {
          this.reset()
          this.params.searchMonth = this.workNumList.searchMonth
          this.firstStep(this.params)
          this.$nextTick(() => {
            this.$refs['zdbContent'].queryObj(this.workNumList)
          });
        }
      }
    },
    totalFail: {
      handler(newVal) {
        if (newVal) {
          this.cancelBtnText = '完成'
          this.showCancelBtn = true
          this.showConfirmBtn = false
        }
      }
    }
  },
  mounted() {

  },
  methods: {
    firstStep(params) {
      this.spinning = true
      const obj = {
        ...params,
        idCard : this.workNumList.idCard
      }
      // params.idCard = this.workNumList.idCard
      // params.searchMonth = this.workNumList.searchMonth
      getProStaffPayrollDetail(obj).then(res => {
        console.log(res,'查看项目汇总详情')
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list
          this.pagination.total = res.data.data.total;
        } else {
          this.dataSource = []
          this.$message.warning(res.data.msg)
        }
        this.spinning = false

      })
    },
    //表格 - 分页查询
    onPageChange(e) {
      let obj = this.$refs["zdbContent"].getFieldValue();
      this.params.pageNumber = e.current;
      this.params.pageSize = e.pageSize;
      this.pagination.pageSize = e.pageSize;
      this.pagination.current = e.current;
      // 处理查询默认格式
      if (obj.searchMonthArr == '') obj.searchMonthArr = []
      this.firstStep(Object.assign(obj, this.params))
    },
    reset() {
      this.dataSource = []
      this.tabPosition = 1
      this.cancelBtnText = '返回修改'
      this.confirmBtnText = '下一步'
      this.showCancelBtn = true
      this.showConfirmBtn = true
      this.showOverBtn = false
      this.primary = false
      this.showFailBtn = false
      this.HKTask = false
    },
    cancel() {
      var _that = this
      if (!this.totalFail) {
        if (this.cancelBtnText === '完成' && !this.HKTask) {
          this.$emit('cancel');
          this.reset()
        } else {
          this.$confirm({
            title: '请等待下发结果，关闭后可能导致下发失败。',
            onOk() {
              _that.$emit('cancel');
              _that.reset()
            }
          })
        }
      } else {
        this.$emit('cancel');
        this.reset()
      }
    },
    over() {
      this.$emit('cancel');
      this.reset()
    },
     // 查看编辑
     viewEditing(e) {
        this.$router.push({
          name: '工人工资汇总',
          query: {
            searchMonth: e.summaryMonth,
            projectId: e.projectId,
            fullName: e.projectName
          }
        })
        this.over()
    },
    // 重置
    close() {
      this.params.pageNumber= 1,
      this.params.pageSize= 50,
      this.pagination.current = 1;
      this.pagination.pageSize = 50;
      // this.$refs["zdbContent"].resetFields();
      this.$refs.zdbContent.setFieldsValue('', {'projectId': ''}, '')
      this.$refs.zdbContent.setFieldsValue('', {'teamId': ''}, '')
      this.params.searchMonth = this.workNumList.searchMonth
      this.firstStep(this.params)
    },
    handleSubmit(e) {
      let obj = Object.assign({},this.params,e);
      if (obj.searchMonth == '') obj.searchMonth = this.workNumList.searchMonth
      obj.pageNumber = 1
      this.pagination.current = 1;
      this.firstStep(obj)
    },
    // 特殊数据处理
    handlSelect(obj) {
      const { val, keys } = obj;
        this.projectList.forEach((item) => {
          if (keys == 'projectId' && item.id == val) {
            this.doQueryTeams({ projectId: val })
          }
        })
    },
    handleChange(e) {
     if (e.item.key == 'projectId') {
        this.projectArr = [];
        this.projectList = []
        this.getZdata(getOfficeProject, e, "projectName",'fullName');
      }
    },
    getZdata(msg, e, name,title) {
      let data = {
        [name]: e.value,
        officeId: this.user.officeId,
      };
      msg(data).then((res) => {
        if (res.status == 200) {
          const data = res.data.data.list ? res.data.data.list : res.data.data
          data.forEach((item) => {
            this.projectArr.push({
              title: item[title],
              id: item.id,
            });
          });
          this.projectList = data;
          // 获取当前匹配key，赋值相对于数组
          this.zdbContentData.forEach((item) => {
            if (e.item.key === item.key) {
              item.dataSource = this.projectArr;
            }
          });
        }
      });
    },
    //查询所有班组
    doQueryTeams(params){
      getAllTeams(params).then(res => {
        if(res.data.code === 0){
          this.formDataFn('teamId', res.data.data, 'teamName')
        }
      })
    },
    // 对下拉框做数据处理
    formDataFn(key, data, itemName) {
        let arrSelect = []
        let arrSource = []
        data.forEach(item => {
            // 班组模糊匹配dataSource
            arrSource.push({ 'title': item[itemName], 'id': item.id })
            // seletetList选项
            arrSelect.push({ 'name': item[itemName], 'value': item.id })
        })
        this.zdbContentData.forEach(item => {
             if (item.key == key) {
                item.dataSource = arrSource
                item.selectlist = arrSelect
            }
        })
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  padding: 0 10px 0 10px;
}
.content {
  padding: 0 10px;
  .sub-title {
    padding-top: 10px;
  }
  .progress {
    padding-top: 5px;
  }
}
.btn-box {
  margin-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
}
.yq-tips {
  margin-top: 10px;
}
.success {
  color: #4a86e8;
  font-weight: bold;
}
.fail {
  color: red;
  font-weight: bold;
}
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  z-index: 1;
  color: #4a86e8;
  background: #fff;
  border-color: #4a86e8;
}
.ant-radio-button-wrapper-disabled {
  background: #fff;
  color: rgba(0, 0, 0, 0.65);
  cursor: default ;
}
.err-box {
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;
  padding: 10px 20px;
}
</style>
