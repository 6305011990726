<template>
  <div class="a_Approval">
    <div class="plug-Overview">验收申请记录</div>
    <a-divider />
    <a-form class="clearfix">
      <a-col :span="2">
        <a-form-item>
          <a-radio-group v-model="queryParams.type">
            <a-radio-button :value="1">
              月份
            </a-radio-button>
            <a-radio-button :value="2">
              年份
            </a-radio-button>
          </a-radio-group>
        </a-form-item>
      </a-col>

      <a-col :span="5">
        <a-form-item label="月份/年份" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
          <a-month-picker placeholder="请选择月份" :default-value="moment(monthDay, monthFormat)" :format="monthFormat" v-if="queryParams.type==1" v-model="queryParams.plan_date">
            <a-icon slot="suffixIcon" type="smile" />
          </a-month-picker>
  
          <a-date-picker
            v-if="queryParams.type==2"
            mode="year"
            v-model="queryParams.plan_date"
            placeholder="请输入年份"
            :default-value="moment(nowYear, yearFormat)" :format="yearFormat"
            :open="yearShowOne"
            @openChange="openChangeOne"
            @panelChange="panelChangeOne"/>
        </a-form-item>
      </a-col>

      <a-col :span="4">
        <a-space class="btnBox">
          <a-button type="primary" @click="doQuery()">查询</a-button>
          <a-button @click="doReset()">重置</a-button>
        </a-space>
      </a-col>
    </a-form>

    <standard-table
        :columns="columns"
        :dataSource="dataSource"
        :selectedRows.sync="selectedRows"
        :pagination="pagination"
        :loading="tableLoading"
        :isAlert="false"
        @clear="onClear"
        @change="onPageChange"
        :bordered="true"
        :btnArr="tableBtnArr"
      >
        <div slot="do-status" slot-scope="{record}">
          <label v-if="record.overTime!=null">已完成</label>
          <label v-else-if="record.gcglbReportTime!=null">终验中</label>
          <label v-else-if="record.proReportTime!=null">复检中</label>
          <label v-else>项目审核</label>
        </div>
        <!-- <div slot="action" slot-scope="{record}">
          <a @click="dowLoadFile(record)">下载文件</a>
        </div> -->
        <div slot="action" slot-scope="{record}">
            <a :href="`${record.overFile}`">下载文件</a>
          </div>
    </standard-table>
    <Export-loading :cPercent="cPercent" :isExporting="isExporting" :loadingText="loadingText"/>
  </div>
</template>

<script>
import StandardTable from '@/components/table/StandardTable'
import {GET_CHECK_ACCEPT_LIST,GET_CHECK_ACCEPT_FILES,QUERY_CHECK_ACCEPT_FINDBYUSER, CHECK_ACCEPT_EXPORT} from '@/services/api'
import { exportProgress } from '@/pages/aqjyzl/export' // 导出方法
import ExportLoading from '@/components/ExportLoading/ExportLoading' // progress组件
import {request} from '@/utils/request'
import {mapGetters} from 'vuex'
import moment from 'moment';
import { useRender } from '@/hooks/useRender.js'

  const columns = [
  {
    title: '验收项目',
    dataIndex: 'projectName',
    width: 240,
    ellipsis: true,
    tooltip: true
  },{
    title: '所属工程管理部',
    dataIndex: 'chhildOfficeName',
    align: 'center',
    scopedSlots: { customRender: 'chhildOfficeName' }
  },
  {
    title: '验收类型',
    dataIndex: 'templName',
    align: 'center',
    scopedSlots: { customRender: 'templName' }
  },
  {
    title: '申请日期',
    dataIndex: 'createTime',
    align: 'center',
    customRender: (text) => useRender.renderDate(text, 'YYYY-MM-DD')
  },
  {
    title: '验收时间',
    dataIndex: 'chAccTime',
    ellipsis:false,
    align: 'center',
    customRender: (text) => useRender.renderDate(text, 'YYYY-MM-DD')
  },
    {
    title: '流程状态',
    dataIndex: 'applyStatus',
    align: 'center',
    scopedSlots: { customRender: 'do-status' }
  },{
    title: '操作',
    align: 'center',
    scopedSlots: { customRender: 'action' }
  }
  ];

export default {
   mixins: [exportProgress],
  name: 'QueryList',
  components: {StandardTable,ExportLoading},
  data () {
    return {
      firstDay:null,
      monthFormat: 'YYYY-MM',
      yearFormat: 'YYYY',
      nowDay:null,
      dataSource:[],
      loadingText:'正在下载文件...',
      loadKeys:[],
      advanced: false,
      columns: columns,
      tableLoading:false,
      selectedRows: [],
      dateFormat: 'YYYY-MM-DD',
      queryParams:{
        pageNumber:1,
        plan_date:null,
        date:null,
        type:1
      },
      pagination:{
        current:1,
        pageSize:10,
        total:0,
        showLessItems:true,
        showQuickJumper:true
      },
      cPercent:0,
      yearShowOne:false,
      isExporting:false,
      fileName:'检查记录文件',
      tableBtnArr: [
        {
            name: '导出',
            attrs: {
                type: 'primary'
            },
            click: this.doDowload
        },
        {
            name: '批量下载',
            attrs: {
                type: 'primary'
            },
            click: this.doDowloadBatch
        }
      ]
    }
  },
  computed: {
    ...mapGetters('account', ['user']),
  },
  created() {
    this.monthDay = moment(new Date()).format('YYYY-MM');
    this.nowYear = moment(new Date()).format('YYYY');
    this.doQuery();

  },
  watch: {
    cPercent:{
      handler(newName, oldName) {
        this.cPercent=newName;
      },
      immediate: true,
    },
    loadingText:{
      handler(newName, oldName) {
        this.loadingText=newName;
      },
      immediate: true,
    }
  },
  methods: {
    moment,
    deleteRecord(key) {
      this.dataSource = this.dataSource.filter(item => item.key !== key)
      this.selectedRows = this.selectedRows.filter(item => item.key !== key)
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    remove () {
      this.dataSource = this.dataSource.filter(item => this.selectedRows.findIndex(row => row.key === item.key) === -1)
      this.selectedRows = []
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },
     /**
     * 获取所有人员的签名记录信息
     */
    doQuery(){
      this.tableLoading = true;
       if(this.queryParams.type==1){
         if(this.queryParams.plan_date==null){
           this.queryParams.date = this.monthDay
         }else{
           this.queryParams.date = this.queryParams.plan_date.format('YYYY-MM')
         }
       }
       if(this.queryParams.type==2){
         if(this.queryParams.plan_date==null){
           this.queryParams.date = this.nowYear
         }else{
           this.queryParams.date = this.queryParams.plan_date.format('YYYY')
         }
       }
      request(QUERY_CHECK_ACCEPT_FINDBYUSER,"post",this.queryParams).then(res => {
        if(res.data.code==0){
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
          this.dataSource.forEach((obj,index)=>{
            obj.key = obj.id;
          })
        }
        this.tableLoading = false;
      })
    },
    doReset(){
      this.queryParams={queryType:1,pageNumber: 1};
      this.pagination.current=1
      this.doQuery();
    },
    //分页查询
    onPageChange(e){
      this.queryParams.pageNumber = e.current;
      this.pagination.current=e.current;
      this.doQuery();
    },
    onChange(dataStr){
      this.queryParams.plan_date=null;
    },
    openChangeOne(status) {
      if (status) {
        this.yearShowOne = true;
      }
    },
    // 得到年份选择器的值
    panelChangeOne(value) {
      this.yearShowOne = false;
      this.queryParams.plan_date = value;
    },
    /**
     * 导出excel
     */
     doDowload() {
      if(this.queryParams.type == 1){
         if(this.queryParams.plan_date == null) {
           this.queryParams.date = this.monthDay
         }else{
           this.queryParams.date = this.queryParams.plan_date.format('YYYY-MM')
         }
       }
       if(this.queryParams.type == 2){
         if(this.queryParams.plan_date == null) {
           this.queryParams.date = this.nowYear
         }else{
           this.queryParams.date = this.queryParams.plan_date.format('YYYY')
         }
       }
      this.loadingText = "正在导出EXCEL文件，请稍候 ····";
      this.isExporting = true;
      this.cPercent = 0;
      let fileName = "验收申请记录.xlsx";
      this.exportFunNomal2(
        CHECK_ACCEPT_EXPORT,
        this.queryParams,
        fileName,
        "application/vnd.ms-excel"
      );
    },
    doDowloadBatch(){
      this.loadingText = "正在下载文件，请稍候 ····";
      this.loadKeys = [];
      if(this.selectedRows&&this.selectedRows.length>0){
        this.selectedRows.map((item) => {
          this.loadKeys.push(item.key);
        })
        if(this.loadKeys.length<=0){
          this.$message.error("请您选择验收记录进行下载！");
          return false;
        }
        this.isExporting=true;
        this.cPercent=0;
        let fileName = ""
        fileName=moment(new Date()).format('YYYY-MM-DD')+"-项目验收文件资料.zip";
        this.exportFun(GET_CHECK_ACCEPT_FILES,this.loadKeys,fileName,this.selectedRows);
      }else{
        this.$message.info('请选中需要导出的项目验收文件资料进行下载');
        return;
      }

    }
  }
}
</script>

<style lang="less" scoped>
  .search{
    margin-bottom: 54px;
  }
  .fold{
    width: calc(100% - 216px);
    display: inline-block
  }
  .operator{
    margin-top: 18px;
  }
  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
</style>
