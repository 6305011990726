var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        width: "1400px",
        title: _vm.title,
        footer: null,
        "dialog-style": { top: "0px" },
      },
      on: { cancel: _vm.close },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "a-form-model",
        { ref: "ruleForm", attrs: { model: _vm.params, rules: _vm.rules } },
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "公司",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: { disabled: "", placeholder: "" },
                        model: {
                          value: _vm.prInfo.companyName,
                          callback: function ($$v) {
                            _vm.$set(_vm.prInfo, "companyName", $$v)
                          },
                          expression: "prInfo.companyName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "项目名称",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: { disabled: "", placeholder: "" },
                        model: {
                          value: _vm.prInfo.projectName,
                          callback: function ($$v) {
                            _vm.$set(_vm.prInfo, "projectName", $$v)
                          },
                          expression: "prInfo.projectName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "日期",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                      },
                    },
                    [
                      _c("a-date-picker", {
                        attrs: {
                          placeholder: "请选择日期",
                          valueFormat: "YYYY-MM-DD",
                        },
                        model: {
                          value: _vm.params.reportTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "reportTime", $$v)
                          },
                          expression: "params.reportTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 28 },
                      },
                    },
                    [
                      _c(
                        "a-button",
                        {
                          staticClass: "mr-4",
                          attrs: { type: "primary", loading: _vm.sbloading },
                          on: { click: _vm.handleSubmit },
                        },
                        [_vm._v("保存")]
                      ),
                      _c(
                        "a-button",
                        { staticClass: "mr-2", on: { click: _vm.close } },
                        [_vm._v("返回")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("tui-image-editor", {
            ref: "tuiImageEditor",
            attrs: { "include-ui": _vm.useDefaultUI, options: _vm.options },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }