var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a_Approval y-rc-main" },
    [
      _c(
        "a-form-model",
        {
          staticClass: "clearfix",
          attrs: { model: _vm.params },
          on: { submit: _vm.handleSubmit },
        },
        [
          _c(
            "a-row",
            { attrs: { type: "flex", justify: "space-between" } },
            [
              _c(
                "a-col",
                { attrs: { span: 16 } },
                [
                  _vm.isJiTuan
                    ? _c(
                        "a-row",
                        { attrs: { span: 16 } },
                        [
                          !_vm.isProject && !_vm.isBranchOffice
                            ? _c(
                                "a-col",
                                { attrs: { span: 4 } },
                                [
                                  _c(
                                    "a-form-model-item",
                                    {
                                      attrs: {
                                        label: "分公司",
                                        labelCol: { span: 8 },
                                        wrapperCol: { span: 14, offset: 1 },
                                      },
                                    },
                                    [
                                      _c(
                                        "a-select",
                                        {
                                          attrs: {
                                            allowClear: "",
                                            placeholder: "",
                                          },
                                          model: {
                                            value: _vm.params.branchOfficeId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.params,
                                                "branchOfficeId",
                                                $$v
                                              )
                                            },
                                            expression: "params.branchOfficeId",
                                          },
                                        },
                                        _vm._l(
                                          _vm.onlyGcglbList,
                                          function (item) {
                                            return _c(
                                              "a-select-option",
                                              {
                                                key: item.value,
                                                attrs: {
                                                  value: item.value,
                                                  title: item.label,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(item.label) + " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.isProject
                            ? _c(
                                "a-col",
                                { attrs: { span: 5 } },
                                [
                                  _c(
                                    "a-form-model-item",
                                    {
                                      attrs: {
                                        label: "项目",
                                        labelCol: { span: 8 },
                                        wrapperCol: { span: 14, offset: 1 },
                                      },
                                    },
                                    [
                                      _c("a-input", {
                                        attrs: { "allow-clear": "" },
                                        model: {
                                          value: _vm.params.projectName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.params,
                                              "projectName",
                                              $$v
                                            )
                                          },
                                          expression: "params.projectName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "a-col",
                            { attrs: { span: 4 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: "月份",
                                    labelCol: { span: 8 },
                                    wrapperCol: { span: 14, offset: 1 },
                                  },
                                },
                                [
                                  _c("a-month-picker", {
                                    attrs: {
                                      placeholder: "选择月份",
                                      valueFormat: "YYYY-MM",
                                    },
                                    model: {
                                      value: _vm.params.reportTime,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.params, "reportTime", $$v)
                                      },
                                      expression: "params.reportTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "a-button",
                                {
                                  staticClass: "ml-4",
                                  attrs: {
                                    type: "primary",
                                    "html-type": "submit",
                                  },
                                },
                                [_vm._v(" 查询 ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "a-space",
                    { staticClass: "btnBox" },
                    [
                      _c(
                        "a-button",
                        {
                          staticClass: "mr-10",
                          attrs: { type: "primary" },
                          on: { click: _vm.handleExport },
                        },
                        [_vm._v(" 导出 ")]
                      ),
                      !_vm.isJiTuan
                        ? [
                            _vm.currentStatus == 2
                              ? _c("a-tag", { attrs: { color: "red" } }, [
                                  _vm._v("已驳回"),
                                ])
                              : _vm._e(),
                            _vm.currentStatus == 1
                              ? _c("a-tag", { attrs: { color: "green" } }, [
                                  _vm._v("已提交"),
                                ])
                              : _vm._e(),
                            _vm.plan.reserved3 == _vm.role
                              ? _c(
                                  "a-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: { click: _vm.handleOpenReject },
                                  },
                                  [_vm._v(" 提交 ")]
                                )
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.isJiTuan
        ? _c(
            "a-row",
            { attrs: { type: "flex", justify: "center" } },
            [
              _c(
                "a-col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "a-steps",
                    { attrs: { current: _vm.currentStep, size: "small" } },
                    _vm._l(_vm.stepList, function (item, index) {
                      return _c(
                        "a-step",
                        { key: index, attrs: { title: item.name } },
                        [
                          _c(
                            "span",
                            {
                              attrs: { slot: "description" },
                              slot: "description",
                            },
                            [
                              item.status == 1
                                ? _c("a-tag", { attrs: { color: "green" } }, [
                                    _vm._v("已提交"),
                                  ])
                                : _vm._e(),
                              item.status == 2
                                ? _c("a-tag", { attrs: { color: "red" } }, [
                                    _vm._v("已驳回"),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "a-row",
        [
          _c("a-col", { attrs: { span: 3 } }, [
            _c("div", { staticClass: "rc-st-title" }),
          ]),
          _c("a-col", { attrs: { span: 12, offset: 3 } }, [
            _c("div", { staticClass: "rc-title" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.params.reportTime) +
                  "月安全生产风险排查管控清单 "
              ),
            ]),
          ]),
          _c(
            "a-col",
            { attrs: { span: 3, offset: 3 } },
            [
              _vm.isProject
                ? _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.addNewComplaint },
                    },
                    [_vm._v(" 新增风险 ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("standard-table", {
        attrs: {
          columns: _vm.columns,
          dataSource: _vm.tableData,
          rowKey: "id",
          pagination: _vm.pagination,
          loading: _vm.tableLoading,
          isAlert: false,
          bordered: true,
          btnArr: _vm.tableBtnArr,
          selectedRows: _vm.selectedRows,
          scroll: { x: 1400 },
        },
        on: {
          change: _vm.handleChangePage,
          "update:selectedRows": function ($event) {
            _vm.selectedRows = $event
          },
          "update:selected-rows": function ($event) {
            _vm.selectedRows = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function (ref) {
              var index = ref.index
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      (_vm.pagination.current - 1) * _vm.pagination.pageSize +
                        parseInt(index) +
                        1
                    )
                  ),
                ]),
              ]
            },
          },
          {
            key: "expertProve",
            fn: function (ref) {
              var record = ref.record
              return [
                record.expertProve == 1
                  ? _c("a-tag", { attrs: { color: "#108ee9" } }, [_vm._v("是")])
                  : _vm._e(),
                record.expertProve == 0
                  ? _c("a-tag", { attrs: { color: "#f50" } }, [_vm._v("否")])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "overRange",
            fn: function (ref) {
              var record = ref.record
              return [
                record.overRange == 1
                  ? _c("a-tag", { attrs: { color: "#108ee9" } }, [_vm._v("是")])
                  : _vm._e(),
                record.overRange == 0
                  ? _c("a-tag", { attrs: { color: "#f50" } }, [_vm._v("否")])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "riskCoverState",
            fn: function (ref) {
              var record = ref.record
              return [
                record.riskCoverState == 1
                  ? _c("a-tag", { attrs: { color: "#f50" } }, [
                      _vm._v("未覆盖"),
                    ])
                  : _vm._e(),
                record.riskCoverState == 2
                  ? _c("a-tag", { attrs: { color: "#108ee9" } }, [
                      _vm._v("已覆盖"),
                    ])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "projectAppointHead",
            fn: function (ref) {
              var record = ref.record
              return [
                _c("div", [_vm._v(_vm._s(record.projectAppointHead))]),
                _c("div", [_vm._v(_vm._s(record.projectAppointHeadPhone))]),
              ]
            },
          },
          {
            key: "projectConfirmHead",
            fn: function (ref) {
              var record = ref.record
              return [
                _c("div", [_vm._v(_vm._s(record.projectConfirmHead))]),
                _c("div", [_vm._v(_vm._s(record.projectConfirmHeadPhone))]),
              ]
            },
          },
          {
            key: "companyAppointHead",
            fn: function (ref) {
              var record = ref.record
              return [
                _c("div", [_vm._v(_vm._s(record.companyAppointHead))]),
                _c("div", [_vm._v(_vm._s(record.companyAppointHeadPhone))]),
              ]
            },
          },
          {
            key: "companyConfirmHead",
            fn: function (ref) {
              var record = ref.record
              return [
                _c("div", [_vm._v(_vm._s(record.companyConfirmHead))]),
                _c("div", [_vm._v(_vm._s(record.companyConfirmHeadPhone))]),
              ]
            },
          },
          {
            key: "action",
            fn: function (ref) {
              var record = ref.record
              return [
                _c("div", { staticClass: "sTables" }, [
                  _vm.isProjectLeader
                    ? _c(
                        "div",
                        {
                          staticClass: "y-edit",
                          on: {
                            click: function ($event) {
                              return _vm.handleOpenPhone(record, 1)
                            },
                          },
                        },
                        [_vm._v("填写项目指定责任人")]
                      )
                    : _vm._e(),
                  _vm.isSafe
                    ? _c(
                        "div",
                        {
                          staticClass: "y-edit",
                          on: {
                            click: function ($event) {
                              return _vm.handleOpenPhone(record, 2)
                            },
                          },
                        },
                        [_vm._v("填写公司指定责任人")]
                      )
                    : _vm._e(),
                  _vm.isProject
                    ? _c(
                        "div",
                        {
                          staticClass: "y-del",
                          on: {
                            click: function ($event) {
                              return _vm.handleDel(record)
                            },
                          },
                        },
                        [_vm._v("删除")]
                      )
                    : _vm._e(),
                ]),
              ]
            },
          },
        ]),
      }),
      _c("detail", {
        attrs: {
          visible: _vm.visible,
          isEdit: _vm.isEdit,
          projectInfo: _vm.projectInfo,
          id: _vm.id,
        },
        on: {
          "update:visible": function ($event) {
            _vm.visible = $event
          },
          closeDialog: _vm.closeDialog,
          refresh: _vm.handleQuery,
        },
      }),
      _c(
        "a-modal",
        {
          attrs: { title: "提交" },
          on: { ok: _vm.handleReject, cancel: _vm.handleCancel },
          model: {
            value: _vm.visibleReject,
            callback: function ($$v) {
              _vm.visibleReject = $$v
            },
            expression: "visibleReject",
          },
        },
        [
          _c(
            "a-form-model",
            {
              attrs: {
                model: _vm.rejectForm,
                "label-col": { span: 4 },
                "wrapper-col": { span: 20 },
              },
            },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "选择",
                    "label-col": { span: 4 },
                    "wrapper-col": { span: 20 },
                  },
                },
                [
                  _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.rejectForm.rejectType,
                        callback: function ($$v) {
                          _vm.$set(_vm.rejectForm, "rejectType", $$v)
                        },
                        expression: "rejectForm.rejectType",
                      },
                    },
                    [
                      _c("a-radio", { attrs: { value: "1" } }, [
                        _vm._v("提交"),
                      ]),
                      !_vm.isProjectSafer
                        ? _c("a-radio", { attrs: { value: "2" } }, [
                            _vm._v("驳回"),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_vm.isProjectSafer
                ? _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "驳回意见",
                        "label-col": { span: 4 },
                        "wrapper-col": { span: 20 },
                      },
                    },
                    [
                      _c("a-textarea", {
                        model: {
                          value: _vm.rejectForm.opinions,
                          callback: function ($$v) {
                            _vm.$set(_vm.rejectForm, "opinions", $$v)
                          },
                          expression: "rejectForm.opinions",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "填写责任人" },
          on: { ok: _vm.handlePhoneOk, cancel: _vm.handlePhoneCancel },
          model: {
            value: _vm.visiblePhone,
            callback: function ($$v) {
              _vm.visiblePhone = $$v
            },
            expression: "visiblePhone",
          },
        },
        [
          _c(
            "a-form-model",
            {
              ref: "phoneForm",
              attrs: {
                model: _vm.phoneForm,
                rules: _vm.rulePhone,
                "label-col": { span: 8 },
                "wrapper-col": { span: 16 },
              },
            },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label:
                      _vm.phoneType == 1 ? "项目指定责任人" : "公司指定责任人",
                    prop: "name",
                  },
                },
                [
                  _c("a-input", {
                    model: {
                      value: _vm.phoneForm.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.phoneForm, "name", $$v)
                      },
                      expression: "phoneForm.name",
                    },
                  }),
                ],
                1
              ),
              !_vm.isProjectSafer
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "电话", prop: "phone" } },
                    [
                      _c("a-input", {
                        model: {
                          value: _vm.phoneForm.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.phoneForm, "phone", $$v)
                          },
                          expression: "phoneForm.phone",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }