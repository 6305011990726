<template>
  <div class="company card w-100">
    <!-- 管理人员考勤 -->
    <div class="black font-16 font-bold overtext-1 flex mb-1">
      <img src="@/assets/img/payroll-card1.png" style="width: 25px; height: 25px;" alt="">
      <span class="ml-05">项目管理人员概览</span>
    </div>
    <a-divider />
    <div class="flex j-center" style="width: 350px;">
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          margin-right: 50px;
        "
      >
        <span class="black-three">已登记</span>
        <span class="font-26 font-bold">{{ mangerData.totalTeams || 0 }}</span>
      </div>
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          margin-right: 50px;
        "
      >
        <span class="black-three">今日出勤</span>
        <span class="font-26 font-bold">{{ mangerData.todayTeams || 0 }}</span>
      </div>
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          margin-right: 50px;
        "
      >
      <span class="black-three">出勤率</span>
        <span class="font-26 font-bold">
          {{ `${mangerData.teamCql || 0}%` }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      mangerData: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {

      }
    },
    watch: {
      officeData: {
        handler(val) {
          console.log(val, 'officeData')
        },
        immediate: true
      },
      mangerData: {
        handler(val) {
          console.log(val, 'mangerData')
        },
        immediate: true
      }
    },
    methods: {

    }
  }
</script>

<style lang="less" scoped>
.card {
  border-radius: 10px;
  padding: 17px;
  background-color: #fff;
}
.company {
  height: 130px;
  margin-bottom: 10px;
}
</style>
