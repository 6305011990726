<template>
  <div>
      <a-card>
        <a-spin :spinning="spinning" :tip="loadingText">
        <div :class="advanced ? 'search' : null">
          <a-form layout="horizontal">
            <div :class="advanced ? null: 'fold'">
              <a-row >
              <a-col :md="12" :sm="24" >
                <a-form-item
                  label="派工单编号"
                  :labelCol="{span: 5}"
                  :wrapperCol="{span: 18, offset: 1}"
                >
                  <a-input placeholder="请输入" v-model="queryParams.orderNo"/>
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24" >
                <a-form-item
                  label="派单项目"
                  :labelCol="{span: 5}"
                  :wrapperCol="{span: 18, offset: 1}"
                >
                  <a-input placeholder="请输入" v-model="queryParams.projectName"/>
                </a-form-item>
              </a-col>
            </a-row>
            </div>
            <span style="float: right; margin-top: 3px;">
              <a-button type="primary" @click="doQuery()">查询</a-button>
              <a-button style="margin-left: 8px" @click="doReset()">重置</a-button>
            </span>
          </a-form>
        </div>
        <div>
          <a-space class="operator">
            <a-button @click="doDowloadBatch()">批量导出名册</a-button>
          </a-space>
          <standard-table
            :columns="columns"
            :dataSource="dataSource"
            :pagination="pagination"
            :selectedRows.sync="selectedRows"
            :loading="tableLoading"
            rowKey="id"
            @clear="onClear"
            @change="onPageChange"
          >
            <div slot="do-hzcs" slot-scope="{text}">
              <div v-if="text!=null&&text!=''">{{text}}次</div>
              <div v-else style="color:red" type="close" >0次</div>
            </div>
            <div slot="do-status" slot-scope="{text}">
              <div v-if="text!=null&&text!=''&&text==1">在场</div>
              <div v-else style="color:red" type="close" >离场</div>
            </div>
            <div slot="action" slot-scope="{text, record}">
              <a @click="viewDetails(record)">查看人员</a>
              <a-divider type="vertical" />
              <a @click="doDowload(record)">导出名册</a>
            </div>
            <template slot="statusTitle">
              <a-icon @click.native="onStatusTitleClick" type="info-circle" />
            </template>
          </standard-table>
        </div>
        <Export-loading :cPercent="cPercent" :isExporting="isExporting" :loadingText="loadingText"></Export-loading>
        </a-spin>
      </a-card>
      <view-hmc
        :visible="hmcdetails.visible"
        :orderNo="hmcdetails.orderNo"
        :orderId="hmcdetails.orderId"
        @doColseDetails="doColseDetails"
      ></view-hmc>
  </div>
</template>

<script>
import ViewHmc from '@/pages/enterprisepool/viewhmc'
import StandardTable from '@/components/table/StandardTable'
import {
  OUTWORK_ORDER_QUERY,
  OUTWORK_ORDER_PERSONS_EXPORT
  } from '@/services/api'
  
import { exportProgress } from '@/pages/aqjyzl/export' // 导出方法
import ExportLoading from '@/components/ExportLoading/ExportLoading' // progress组件
import {request} from '@/utils/request'
import { useRender } from '@/hooks/useRender.js'

const columns = [
  {
    title: '派单编号',
    dataIndex: 'orderNo',
    width:150,
    align: 'center',
    scopedSlots: { customRender: 'orderNo' }
  },
  {
    title: '项目名称',
    dataIndex: 'projectName',
    align: 'center',
    scopedSlots: { customRender: 'projectName' },
  },
  {
    title: '班组名称',
    dataIndex: 'teamName',
    align: 'center',
    width:210,
    ellipsis: true,
   scopedSlots: { customRender: 'teamName' },
  },
  {
    title: '工种名称',
    dataIndex: 'jobtitleName',
    align: 'center',
    width:120,
    scopedSlots: {customRender: 'jobtitleName'}
  },
  {
    title: '负责人',
    dataIndex: 'teamLeader',
    align: 'center',
    ellipsis: true,
    width:120,
   scopedSlots: { customRender: 'teamLeader' },
  },
  {
    title: '负责人电话',
    dataIndex: 'teamPhone',
    align: 'center',
    width:125,
    scopedSlots: {customRender: 'teamPhone'}
  },
  {
    title: '派单人数',
    dataIndex: 'persons',
    align: 'center',
    width:120,
    scopedSlots: {customRender: 'persons'}
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    align: 'center',
    width:160,
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: '操作',
    align: 'center',
    width:110,
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  mixins: [exportProgress],
  name: 'QueryList',
  components: {StandardTable,ExportLoading,ViewHmc},
  data () {
    return {
      dataSource:[],
      columns: columns,
      selectedRows:[],
      tableLoading: false,
      spinning:false,
      advanced:false,
      loadingText:"数据加载中...",
      loadKeys:[],
      hmcdetails:{
        visible:false,
        orderNo:null,
        orderId:null,
      },
      queryParams:{
        orderNo:null,
        projectName:null,
        teamName:null,
        pageNumber:1,
      },
      pagination:{
        current:1,
        pageSize:10,
        total:0,
        showLessItems:true,
        showQuickJumper:true
      },
      cPercent:0,
      isExporting:false,
      fileName:'企业人员名单',
    }
  },
  watch: {
    cPercent:{
      handler(newName, oldName) {
        this.cPercent=newName;
      },
      immediate: true,
    },
    loadingText:{
      handler(newName, oldName) {
        this.loadingText=newName;
      },
      immediate: true,
    }
  },
  mounted(){
  },
  created() {
    this.doQuery();
  },
  methods: {
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    remove () {
      this.dataSource = this.dataSource.filter(item => this.selectedRows.findIndex(row => row.id === item.id) === -1)
      this.selectedRows = []
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },

    /**
     * 获取所有人员的签名记录信息
     */
    doQuery(){
      this.tableLoading = true;
      request(OUTWORK_ORDER_QUERY,"post",this.queryParams).then(res => {
        if(res.data.code==0){
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
        }
        this.tableLoading = false;
      })
    },
    
    doReset(){
      this.queryParams={};
      this.queryParams.teamId = this.teamId;
      this.doQuery();
    },
    //分页查询
    onPageChange(e){
      this.queryParams.pageNumber = e.current;
      this.pagination.current=e.current;
      this.doQuery();
    },
    
    /**
     * 导出
     */
    doDowload(record){
      this.loadingText = "正在导出名册，请稍候 ····";
      this.loadKeys = [];
      this.loadKeys.push(record.id);
      this.isExporting=true;
      this.cPercent=0;
      let fileName = ""
      fileName=record.orderNo+"名册下载.zip";
      this.exportFunNomal(OUTWORK_ORDER_PERSONS_EXPORT,this.loadKeys,fileName,this.selectedRows)
    },
    doDowloadBatch(){
      this.loadKeys = [];
      this.selectedRows.forEach(obj=>{
        this.loadKeys.push(obj.id);
      })
      this.isExporting=true;
      this.cPercent=0;
      let fileName = ""
      fileName="派工单名册下载.zip";
      this.exportFunNomal(OUTWORK_ORDER_PERSONS_EXPORT,this.loadKeys,fileName,this.selectedRows)
    },
    viewDetails(recode){
      this.hmcdetails.visible = true;
      this.hmcdetails.orderId = recode.id;
      this.hmcdetails.orderNo = recode.orderNo;
    },
    doColseDetails(){
      this.hmcdetails.visible = false;
      this.hmcdetails.orderId = null;
      this.hmcdetails.orderNo = null;
    }
  }
}
</script>

<style lang="less" scoped>
  .search{
    margin-bottom: 54px;
  }
  .fold{
    width: calc(100% - 216px);
    display: inline-block
  }
  .operator{
    margin-bottom: 18px;
  }
  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
</style>
