<template>
  <a-modal width="460px" v-model="show" :title="title" :footer="null" @cancel="close">
      <a-form-model ref="ruleForm" :model="params" :rules="rules">
        <a-row>
          <a-col :span="24">
            <a-form-model-item label="公司" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }">
              <a-input v-model="params.companyName" disabled placeholder=""/>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="项目名称" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" >
              <a-input v-model="params.projectName" disabled placeholder=""/>

            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="月份选择" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="reportTime">
              <a-month-picker v-model="params.reportTime"  placeholder="选择月份" valueFormat="YYYY-MM" />
              
            </a-form-model-item>
          </a-col>
         

        </a-row>
      </a-form-model>
      <a-row>
        <a-col :span="12"></a-col>
        <a-col :span="12">
          <a-form-model-item :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }" prop="year">
            <div class="flex flex-row-reverse">
              <a-button type="primary" :loading="sbloading" @click="handleSubmit">保存</a-button>
              <a-button class="mr-2" @click="close">返回</a-button>
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>
  </a-modal>
</template>

<script>

import { request } from "@/utils/request";
import { mapGetters } from 'vuex'
import moment from "moment";
import { riskcontrolAdd, riskcontrolByID } from "@/services/riskControll";
import { ATTACHMENT_UPLOAD } from "@/services/api";
import mixin from './mixin'

export default {
  name: 'etDetail',
  computed: {
    ...mapGetters('account', ['user']),
  },
  props: {
      visible: {
          type: Boolean,
          default: false
      },
      isEdit: {
          type: Number,
          default: 0
      },
      id: {
          default: ''
      },
      projectInfo: {
        default: () => {
          return {}
        }
      }
      

  },
  mixins: [mixin],
  data() {
    return {
      // isEdit: 0,
      rules: {
        reportTime: [{ required: true, message: '请选择 月份', trigger: 'change' }],
        
      },

      params: {
          "companyId": "gfjdkaglf3gfdgr2324",          //公司ID
          "companyName": "公司名称",        //公司名称
          "branchOfficeId":"32432",      //分公司ID
          "branchOfficeName":"分公司名称",   //分公司名称
          "projectId":"5gfdygfhgny78765", //项目ID
          "projectName": "项目名称",        //项目名称
          "safetyOfficerId":"543264",      //安全员ID
          "safetyOfficerName":"安全员名称",  //安全员名称
          "reportTime":"" //清单月份
      },
      sbloading: false,
      title: '新增',
      show: false,

    };
  },
 
  watch: {
    visible(val) {
      console.log(val)
      
      if (this.isEdit == 0) {
          // dom 渲染后
        
        this.params = {
            "companyId": this.projectInfo.parentOfficeId,          //公司ID
            "companyName": this.projectInfo.parentOffice,        //公司名称
            "branchOfficeId":this.projectInfo.gcglbId,      //分公司ID
            "branchOfficeName":this.projectInfo.gcglbName,   //分公司名称
            "projectId":this.projectInfo.projectId, //项目ID
            "projectName": this.projectInfo.projectName,        //项目名称
            "safetyOfficerId":this.user.id,      //安全员ID
            "safetyOfficerName":this.user.name,  //安全员名称
            "reportTime":"" //清单月份
        }
        // 新增 birthday 取 30年前
        // this.params.birthday = moment().subtract(30, 'years').format('YYYY-MM-DD');
        // this.params.age = 30;
        this.title = '新建清单'
      } else {
          this.title = '编辑 '
          this.params.id = this.id;
          this.getDetail()

      }
      this.show = val;
    }
  },
  methods: {
    getDetail() {
      riskcontrolByID({id: this.params.id}).then(res => {
        if (res.data.code == 0) {
          this.params = res.data.data;
          
         
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },

    handleSubmit() {
      this.sbloading = true
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let d = this.params;  
          
          if (this.isEdit == 0) {
            riskcontrolAdd(d).then(res => {
              if (res.data.code == 0) {
                this.$message.success('提交成功！')
                this.close();
                this.$emit('refresh')
              } else {
                this.$message.error(res.data.msg)
              }
              this.sbloading = false
            })
          }
        } else {
          console.log('error submit!!');
          this.sbloading = false
          return false;
        }
      });
    },
    close() {
      this.resetForm()
      this.$emit('closeDialog')
    },
    resetForm() {
      
    },
    
  },
};
</script>

<style lang="less" scoped>
.a_Approval {
  background: #fff;
  border-radius: 10px;
}

.btn_group {
  padding-right: 190px;
}
</style>
