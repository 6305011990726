import { getProjectIdConfig } from '@/services/projectConfig.js'
import { storage } from '@/utils/storage.js'

export function useSystem() {
  // 系统设置缓存
  let systemConfigCache = {}

  async function getProjectSystemConfig() {
    if (Object.keys(systemConfigCache).length !== 0) {
      return systemConfigCache
    }

    const userInfo = storage.get(process.env.VUE_APP_USER_KEY)
    const projectId = userInfo.projectId

    if (!projectId) {
      console.error('获取项目id失败')
      return false
    }

    const res = await getProjectIdConfig({ projectId })
    if (res.data.stat === 1) {
      systemConfigCache = res.data.data
    }

    return systemConfigCache
  }

  /**
  * @description 检查当前项目是否开通了【建委预警】功能
  * @return {Boolean} true-已开通 false-未开通
  */
  async function checkConstructionWarningOpened() {
    // const config = await getProjectSystemConfig()
    // jwWarning：是否开通了建委预警 0-未开通 1-已开通
    // return Boolean(config.jwWarning)
    return true
  }

  return {
    checkConstructionWarningOpened
  }
}