<template>
  <div>
    <a-card>
      <div class="flex a-center">
        <div class="flex a-center j-center">
          <img style="width: 21px; height: 21px" src="https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20241120/1732091350443.png?Expires=2047451350&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=59AEqlAw%2B%2BGyQ%2F%2B4QYdFWxJZ7rs%3D" alt="">
          <div class="font-bold ml-1" style="width: 100px;">预警处理情况</div>
        </div>
        <a-carousel v-if="warnList.length > 0" style="width: 80%;" class="flex-1" autoplay :dots="false" :autoplaySpeed="5000" :speed="2000" vertical>
          <div class="ml-1 flex a-center warning-tips" v-for="(item, index) in warnList" :key="index">
            <img style="width: 15px; height: 15px;" src="https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20241120/1732091634700.png?Expires=2047451634&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=GO6Aa%2BqnoqR2MWZGQ1vA2U%2BiDTY%3D" alt="">
            <span class="ml-1 font-12">{{item}}</span>
          </div>
        </a-carousel>
      </div>
      <a-divider />
      <div>
        <div class="flex j-between p-2">
          <div class="flex">
            <div class="warning-icon-1" style="background: #F1F8FF;">
              <img style="width: 25px;height: 27px;" src="https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20241120/1732092019676.png?Expires=2047452019&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=beAOQMxoL0nYQi%2F7QknGDWp0ZsQ%3D" alt="">
            </div>
            <div class="flex flex-column ml-1">
              <span class="font-18 font-bold">{{total}}</span>
              <span class="black-three">预警总数（个）</span>
            </div>
          </div>
          <div class="flex">
            <div class="warning-icon-1 relative" style="background: #FFF2F6;">
              <img style="width: 25px;height: 27px;" src="https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20241120/1732092456398.png?Expires=2047452456&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=5zbcHoarR4KSx1pagPnqYGn07GM%3D" alt="">
              <!-- <div class="red-dot">1</div> -->
            </div>
            <div class="flex flex-column ml-1">
              <span class="font-18 font-bold">{{redCount}}</span>
              <span class="black-three">红色预警数（个）</span>
            </div>
          </div>
          <div class="flex">
            <div class="warning-icon-1 relative" style="background: #FFF8EB;">
              <img style="width: 25px;height: 27px;" src="https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20241120/1732092483441.png?Expires=2047452483&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=IOky7cewCZHOWW85SqqERKGtAjw%3D" alt="">
              <!-- <div class="red-dot">1</div> -->
            </div>
            <div class="flex flex-column ml-1">
              <span class="font-18 font-bold">{{orangeCount}}</span>
              <span class="black-three">橙色预警数（个）</span>
            </div>
          </div>
          <div class="flex">
            <div class="warning-icon-1" style="background: #FFFBE7;">
              <img style="width: 25px;height: 27px;" src="https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20241120/1732092510821.png?Expires=2047452510&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=c5a0Dtozo35nNEpywABGQonQucI%3D" alt="">
            </div>
            <div class="flex flex-column ml-1">
              <span class="font-18 font-bold">{{yellowCount}}</span>
              <span class="black-three">黄色预警数（个）</span>
            </div>
          </div>
        </div>
        <div class="flex a-center font-12 ml-2">
          限期预警说明：<div class="dot-icon" style="background: #EF1A1A;"></div>逾期5天以上<div class="dot-icon ml-1" style="background: #F7A22C;"></div>逾期5天内<div class="dot-icon ml-1" style="background: #FFDE2E;"></div>限期3天内
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import { getEarlyWarnData } from "@/services/labourProjectExcellent";
export default {
  data() {
    return {
      total: 0,
      redCount: 0,
      orangeCount: 0,
      yellowCount: 0,
      warnList: []
    };
  },

  created() {
    this.getData()
  },

  methods: {
    getData() {
      getEarlyWarnData().then(res => {
        if (res.data.code === 0) {
          this.total = res.data.data.total
          this.redCount = res.data.data.redCount
          this.orangeCount = res.data.data.orangeCount
          this.yellowCount = res.data.data.yellowCount
          this.warnList = res.data.data.warnList.map(el => {
            return `${el.officeName} | ${el.projectName} | ${el.warnInfo}`
          })
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.ant-card {
  border-radius: 10px;
  border: none;
  .ant-card-body {
    padding: 15px;
  }
}
.warning-tips {
  background: #FFD2E04D;
  padding: 3px 8px;
  border-radius: 4px;
}
.warning-icon-1 {
  padding: 14px;
  border-radius: 12px;
}
.red-dot {
  position: absolute;
  width: 15px; /* 或者你想要的任何大小 */
  height: 15px; /* 同上 */
  background-color: #EF1A1A; /* 红色背景 */
  border-radius: 50%; /* 圆形 */
  text-align: center;
  line-height: 15px;
  font-size: 12px;
  color: #fff;
  top: -5px;
  right: -5px;
}

.dot-icon {
  width: 10px; /* 或者你想要的任何大小 */
  height: 10px; /* 同上 */
  border-radius: 50%; /* 圆形 */
  margin-right: 5px;
}
</style>