import {GET_OFFICE_AUTH_LIST, AUTH_OFFICE_STATUS, } from '@/services/api'
import {request, METHOD, removeAuthorization} from '@/utils/request'
import {
    exportAttendSheet,
    exportFile, findNoAttach, findNoAttachPersons,
    getAttendSheet,
    getOfficeListByProjectId,
    getPayrollList, getTeamListByOfficeId,
    submitPayrollData
} from "./payroll";

/**
 * 获取企业实名认证用户列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getOfficeAuthList(params) {
    return request(GET_OFFICE_AUTH_LIST, METHOD.GET, params)
}

/**
 * 审核企业实名认证
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function authOfficeStatus(params) {
    return request(AUTH_OFFICE_STATUS, METHOD.GET, params)
}


export default {
    getOfficeAuthList,
    authOfficeStatus
}