<template>
  <div class="a_Method">
    <a-row :gutter="16">
      <a-col :span="24">
        <a-card
          title="设置门禁通道"
          :bordered="false"
          style="height: 900px"
          :style="'overflow:auto'"
        >
          <a-tree
            v-model="checkedKeys"
            :tree-data="treeDataSource"
            checkable
            :selected-keys="selectedKeys"
            @select="onSelect"
            @check="onCheck"
            checkStrictly	
            :defaultExpandParent="true"
            :defaultExpandAll="true"
            v-if="!spinning"
          >
            <a-spin :spinning="spinning" />
            <template #title="{ key: treeKey, title }">
              <a-dropdown :trigger="['contextmenu']">
                <span>{{ title }}</span>
                <template #overlay>
                  <a-menu
                    @click="
                      ({ key: menuKey }) => onContextMenuClick(treeKey, menuKey)
                    "
                  >
                    <a-menu-item key="1">新增</a-menu-item>
                    <a-menu-item key="2">编辑</a-menu-item>
                    <a-menu-item key="3">删除</a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </template>
          </a-tree>
        </a-card>
      </a-col>
    </a-row>
    <!-- 新增区域框 -->
    <a-modal
      v-model="addNewDialog"
      title="新增区域"
      :width="600"
      :body-style="{ height: '200px', overflowY: 'auto' }"
      @ok="handleAddNewSubmit"
    >
      <form-index ref="addNewFormIndex" :formData="addNewFormData">
      </form-index>
    </a-modal>
  </div>
</template>

<script>
import { getGroupList, addGroup, delGroup } from "@/services/dmgr";
import { treeData } from "@/utils/treeutil";
import formIndex from "@/pages/components/form/index";
import {
  getGroupOfficeAccControl,
  addGroupOfficeAccControl,
  updateGroupOfficeAccControl,
} from "@/services/participationUnit";
const addNewFormData = [
  {
    label: "区域名称",
    placeholder: "请填写",
    decorator: [
      "groupName",
      { rules: [{ required: false, message: "请填写" }] },
    ],
    type: "input",
    key: "groupName",
    col: 24,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  // {
  //   label: "上级区域",
  //   placeholder: "请选择",
  //   decorator: ["fatherGroupId", { rules: [{ required: false, message: "请选择" }] }],
  //   type: "cascader",
  //   key: "fatherGroupId",
  //   col: 24,
  //   labelCol: 6,
  //   wrapperCol: 14,
  //   display: true,
  // },
];
export default {
  components: {
    formIndex,
  },
  data() {
    return {
      
      treeDataSource: [],
      selectedKeys: [],
      checkedKeys: [],
      originData: [],
      officeTree: [],
      queryData: {
        projectId: "",
      },
      addNewDialog: false,
      addNewFormData: addNewFormData,
      fatherGroupId: "",
      officeId: this.$route.query.officeId,
      spinning: true
    };
  },
  activated() {
    this.query =this.$route.query
    this.officeId = this.$route.query.officeId,
    this.queryData.projectId = this.query.projectId;
    this.$nextTick(() => {
      this.getGroupList();
    })
  },
  methods: {
    getGroupList() {
      getGroupList(this.queryData).then((res) => {
        this.originData = res.data.data;
        let list = treeData(
          res.data.data,
          "groupId",
          "fatherGroupId",
          "children",
          "0"
        );
        this.treeDataSource = list.map((el) => this.mapTree(el));
        this.spinning = false;
      });
      getGroupOfficeAccControl({officeId: this.officeId,projectId: this.queryData.projectId}).then((res) => {
        this.officeTree = res.data.data;
        let arr = [];
        this.originData.forEach((group) => {
          if (group.fatherGroupId == "0") {
            this.fatherGroupId = group.groupId;
          }
        });
        res.data.data.forEach((item) => {
          // if (!(item.groupId == this.fatherGroupId)) {
          //   arr.push(item.groupId);
          // }
          arr.push(item.groupId);
        });
        this.checkedKeys = arr
      });
    },
    buildtree(list, permissions, parentId) {
      list.forEach((item) => {
        if (item.parentId === parentId) {
          var child = {
            key: item.menuId,
            title: item.menuName,
            children: [],
          };
          this.buildtree(list, child.children, item.menuId);
          permissions.push(child); 
        }
      });
    },
    mapTree(org) {
      const haveChildren =
        Array.isArray(org.children) && org.children.length > 0;
      return {
        //分别将我们查询出来的值做出改变他的key
        // officeId:org.officeId,
        projectId: org.projectId,
        key: org.groupId,
        title: org.groupName,
        //判断它是否存在子集，若果存在就进行再次进行遍历操作，知道不存在子集便对其他的元素进行操作
        children: haveChildren ? org.children.map((i) => this.mapTree(i)) : [],
      };
    },
    onContextMenuClick(treeKey, menuKey) {
      // console.log(`treeKey: ${treeKey}, menuKey: ${menuKey}`);
      // let _that = this;
      // if (menuKey === "1") {
      //   this.addNewDialog = true;
      //   this.fatherGroupId = treeKey;
      // } else if (menuKey === "3") {
      //   const record = this.originData.find((el) => el.groupId);
      //   this.$confirm({
      //     title: "确定删除区域吗？",
      //     onOk() {
      //       delGroup(record).then((res) => {
      //         if (res.data.code === 0) {
      //           _that.$message.success("删除成功！");
      //           _that.getGroupList();
      //         } else {
      //           _that.$message.error(res.data.msg);
      //         }
      //       });
      //     },
      //   });
      // }
    },
    handleAddNewSubmit() {
      this.$refs.addNewFormIndex.combinationSubmit().then((res) => {
        // res.fatherGroupId = this.fatherGroupId;
        // res = {
        //   ...res,
        //   groupId: this.fatherGroupId,
        //   officeId: this.officeId,
        //   projectId: this.queryData.projectId,
        // };
        console.log(res, "res=>>>");
        // addGroupOfficeAccControl(res).then((el) => {
        //   if (el.data.code === 0) {
        //     this.$message.success("新增成功！");
        //     this.addNewDialog = false;
        //     this.groupId = "";
        //     this.fatherGroupId = "";
        //     this.getGroupList();
        //   } else {
        //     this.$message.error(el.data.msg);
        //   }
        // });
      });
    },
    onSelect(selectedKeys, info, selectedNodes, node, event) {
      console.log("selectedKeys", selectedKeys);
      console.log("onSelect", info);
      console.log("selectedNodes", selectedNodes);
      console.log("node", node);
      console.log("event", event);
      this.selectedKeys = selectedKeys;
    },
    onCheck(checkedKeys, e) {
      this.checkedKeys = checkedKeys.checked;
      let params = {
        groupIds: this.checkedKeys,
        officeId: this.officeId,
        projectId: this.queryData.projectId,
      };
        addGroupOfficeAccControl(params).then((el) => {
          if (el.data.code === 0) {
            this.$message.success("修改成功！");
            this.addNewDialog = false;
            this.groupId = "";
            this.fatherGroupId = "";
            this.getGroupList();
          } else {
            this.$message.error(el.data.msg);
            this.$message.error('至少设置一个门禁通道');
            this.getGroupList();
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.a_Method {
  // background: #fff;
  // padding: 20px;
  // border-radius: 10px;
}
</style>
