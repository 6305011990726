<template>
  <a-card>
    <div :class="advanced ? 'search' : null">
        <a-form layout="horizontal">
          <div :class="advanced ? null: 'fold'">
            <a-row >
            <a-col :md="8" :sm="24" >
              <a-form-item  :labelCol="{span: 5}"
                :wrapperCol="{span: 18, offset: 1}" label="所属公司">
                <a-tree-select
                  style="width: 80%"
                  v-model="queryParams.projectOfficeId"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  :tree-data="officeTreeData"
                  placeholder="请选择公司"
                  tree-default-expand-all
                  :replaceFields="{children:'children', title:'name', key:'id', value:'id' }"
                  :treeDefaultExpandAll="false"
                  @select="currentOfficeId"
                  :allowClear="true"
                >
                </a-tree-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24" >
              <a-form-item
                label="检查时间"
                :labelCol="{span: 5}"
                :wrapperCol="{span: 18, offset: 1}"
              >

                <a-range-picker  v-model="queryParams.plan_date" :default-value="[moment(firstDay, dateFormat), moment(nowDay, dateFormat)]"
      :format="dateFormat"  @change="changeDate"/>
              </a-form-item>
            </a-col>
          </a-row>
          </div>
          <span style="float: right; margin-top: 3px;">
            <a-button type="primary" @click="doQuery()">查询</a-button>
            <a-button style="margin-left: 8px" @click="doReset()">重置</a-button>
          </span>
        </a-form>
      </div>
      <standard-table
            :columns="columns"
            :dataSource="dataSource"
            :selectedRows.sync="selectedRows"
            :pagination="pagination"
            :loading="tableLoading"
            :isAlert="false"
            @clear="onClear"
            @change="onPageChange"
          >
            <div slot="safediscloseFileName" slot-scope="{text}">
              <div v-if="text!=null&&text!=''">{{text}}</div>
              <a-icon v-else style="color:red" type="close" />
            </div>
            <div slot="do-status" slot-scope="{text}">
              <a-icon v-if="text==1" style="color:green" type="check" />
              <a-icon v-else style="color:red" type="close" />
            </div>
            <div slot="action" slot-scope="{record}">
              <a @click="doDetail(`${record.key}`,`${record.officeId}`,`${record.officeName}`,`${record.projectName}`)">查看详情</a>
            </div>
            <template slot="statusTitle">
              <a-icon @click.native="onStatusTitleClick" type="info-circle" />
            </template>
          </standard-table>
          <detail
            :visible="detail.visible"
            :projectId="detail.projectId"
            :officeName="detail.officeName"
            :officeId="detail.officeId"
            :projectName="detail.projectName"
            :times="detail.times"
            @close="doClose"
          ></detail>
  </a-card>
</template>

<script>
import StandardTable from '@/components/table/StandardTable'
import Detail from '@/pages/safeview/detail'
import {QUERY_PROJECT_CHECK, GET_OFFICE_TREE_BY_EPIDEMIC} from '@/services/api'
import {request} from '@/utils/request'
import {mapGetters} from 'vuex'
import moment from 'moment';



  const columns = [
  //   {
  //   title: '序号',
  //   dataIndex: 'index',
  //   align: 'center',
  //   scopedSlots: { customRender: 'index' }
  // },
  {
    title: '项目名称',
    dataIndex: 'projectName',
    width:380,
    align: 'left',
    scopedSlots: { customRender: 'projectName' }
  },
  {
    title: '所属单位',
    dataIndex: 'officeName',
    align: 'left',
    scopedSlots: { customRender: 'officeName' }
  },
  {
    title: '检查次数',
    dataIndex: 'checkCnt',
    align: 'center',
    scopedSlots: { customRender: 'checkCnt' }
  },
  {
    title: '隐患总数',
    dataIndex: 'wtzs',
    align: 'center',
    scopedSlots: { customRender: 'wtzs' }
  },
  {
    title: '隐患整改率',
    dataIndex: 'yhzgl',
    align: 'center',
    scopedSlots: { customRender: 'yhzgl' }
  },
  {
    title: '超时未整改数',
    dataIndex: 'cswzgs',
    align: 'center',
    scopedSlots: { customRender: 'cswzgs' }
  },{
    title: '操作',
    align: 'center',
    scopedSlots: { customRender: 'action' }
  }
  ];

export default {
  name: 'QueryList',
  components: {StandardTable,Detail},
  data () {
    return {
      firstDay:null,
      nowDay:null,
      dataSource:[],
      officeTreeData:[],
      advanced: false,
      columns: columns,
      tableLoading:false,
      selectedRows: [],
      currentOfficeId:null,
      dateFormat: 'YYYY-MM-DD',
      queryParams:{
        officeName:null,
        projectOfficeId:null,
        teamName:null,
        pageNumber:1,
        plan_date:null,
      },
      pagination:{
        current:1,
        pageSize:10,
        total:0,
        showLessItems:true,
        showQuickJumper:true
      },
      detail:{
        visible:false,
      }
    }
  },
  computed: {
    ...mapGetters('account', ['user']),
  },
  created() {
    this.firstDay = moment(new Date()).format('YYYY-MM')+"-01";
    this.nowDay = moment(new Date()).format('YYYY-MM-DD');
    this.doQuery();
    if (this.user.enname == "admin"){
      this.getOfficeTree(undefined);
      this.currentOfficeId = undefined;
    }else{
      this.getOfficeTree(this.user.officeId);
      this.currentOfficeId = this.user.officeId;
    }

  },
  methods: {
    moment,
    deleteRecord(key) {
      this.dataSource = this.dataSource.filter(item => item.key !== key)
      this.selectedRows = this.selectedRows.filter(item => item.key !== key)
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    remove () {
      this.dataSource = this.dataSource.filter(item => this.selectedRows.findIndex(row => row.key === item.key) === -1)
      this.selectedRows = []
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },
    getOfficeTree(officeId){
      let params = {
        parentOfficeId: officeId
      }
      request(GET_OFFICE_TREE_BY_EPIDEMIC, "get", params).then(res => {
          this.officeTreeData = res.data.data
      });
    },
     /**
     * 获取所有人员的签名记录信息
     */
    doQuery(){
      this.tableLoading = true;
      if(this.queryParams.plan_date&&this.queryParams.plan_date.length>0){
        this.queryParams.startDate = this.queryParams.plan_date[0].format('YYYY-MM-DD')
        this.queryParams.endDate = this.queryParams.plan_date[1].format('YYYY-MM-DD')
      }
      request(QUERY_PROJECT_CHECK,"post",this.queryParams).then(res => {
        if(res.data.code==0){
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
          this.dataSource.forEach((obj,index)=>{
            obj.key = obj.projectId;
            obj.index = this.pagination.current*index+1
          })
        }
        this.tableLoading = false;
      })
    },
    doReset(){
      this.queryParams={};
      this.doQuery();
    },
    //分页查询
    onPageChange(e){
      this.queryParams.pageNumber = e.current;
      this.pagination.current=e.current;
      this.doQuery();
    },
    changeDate(e){
      if(e==null||e.length<=0){
        this.queryParams.startDate = null;
        this.queryParams.endDate = null
      }
    },
    //查看详情
    doDetail($projectId,$officeId,$officeName,$projectName){
      this.detail.times = "";
      if(this.queryParams.plan_date&&this.queryParams.plan_date.length>0){
        let start = this.queryParams.plan_date[0].format('YYYY-MM-DD')
        let end = this.queryParams.plan_date[1].format('YYYY-MM-DD')
        this.detail.times = start +"@" + end;
      }else{
        let start = this.firstDay
        let end = this.nowDay
        this.detail.times = start +"@" + end;
      }
      this.detail.projectId = $projectId;
      this.detail.officeId = $officeId;
      this.detail.officeName = $officeName;
      this.detail.projectName = $projectName;
      this.detail.visible = true;
    },
    doClose(){
      this.detail.visible = false;
    }
  }
}
</script>

<style lang="less" scoped>
  .search{
    margin-bottom: 54px;
  }
  .fold{
    width: calc(100% - 216px);
    display: inline-block
  }
  .operator{
    margin-bottom: 18px;
  }
  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
</style>
