<template>
  <div>
    <a-drawer
      :width="800"
      :visible="visible"
      :title="title"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose"
      :destroyOnClose="true"
    >
      <a-form
        :form="form"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 18 }"
        layout="horizontal"
      >
        <!-- :defaultValue="moment(checkTime, dateFormat)" -->
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="开展时间：">
              <a-date-picker
                @change="onChange"
                placeholder="请选择开展时间"
                :defaultValue="moment(ytlist.checkTime, 'YYYY-MM-DD')"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="约谈类型：">
              <a-select
                :allowClear="true"
                @change="handlTypeSelect"
                style="width: 100%"
                placeholder="请选择约谈类型："
                v-decorator="['talkType', { rules: [{ required: true, message: '请输入约谈类型' }] }]"
              >
              <a-select-option v-for="items in talkTypeList" :key="items.value" :value="items.value">{{ items.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="约谈单位/部门：">
              <a-input
                v-decorator="[
                  'ytUnit',
                  {
                    rules: [{ required: true, message: '请输入约谈单位/部门' }],
                  },
                ]"
                placeholder="请输入约谈单位/部门"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16" v-if="talkType == 3">
          <a-col :span="24">
            <a-form-item label="被约谈单位/部门：">
              <a-input
                :allowClear="true"
                style="width: 100%"
                placeholder="输入被约谈单位/部门名称："
                v-decorator="[
                  'bytUnitName',
                  {
                    rules: [
                      { required: true, message: '请选择被约谈单位/部门：' },
                    ],
                  },
                ]"
              >
              </a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16" v-else>
          <a-col :span="24">
            <a-form-item v-if="!isBranchOffice" label="被约谈单位/部门：">
              <a-select
                :allowClear="true"
                @change="handlSelect($event)"
                style="width: 100%"
                placeholder="请选择被约谈单位/部门："
                v-decorator="[
                  'bytUnit',
                  {
                    rules: [
                      { required: true, message: '请选择被约谈单位/部门：' },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="t in bytlist"
                  :key="t.id"
                  :value="t.id"
                  >{{ t.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item v-else label="被约谈单位/部门：">
              <a-input
                :allowClear="true"
                style="width: 100%"
                placeholder="输入被约谈单位/部门名称："
                v-decorator="[
                  'bytUnitName',
                  {
                    rules: [
                      { required: true, message: '请选择被约谈单位/部门：' },
                    ],
                  },
                ]"
              >
              </a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="项目名称：">
              <a-select
                :allowClear="true"
                @change="handleProjectSelect"
                show-search
                @search="handleSearch"
                style="width: 100%"
                :filter-option="filterOption"
                placeholder="请选择被约谈单位/部门："
                v-decorator="['projectId', { rules: [{ required: true, message: '请输入项目名称' }] }]"
              >
              <a-select-option v-for="items in projectList" :key="items.projectId + ''" :value="items.projectId">{{ items.projectName }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="约谈人：">
              <a-input
                v-decorator="[
                  'ytrName',
                  { rules: [{ required: true, message: '请输入约谈人' }] },
                ]"
                placeholder="请输入约谈人"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="被约谈人：">
              <a-input
                v-decorator="[
                  'bytrName',
                  { rules: [{ required: true, message: '请输入被约谈人' }] },
                ]"
                placeholder="请输入被约谈人"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="约谈内容">
              <!-- <keep-alive>
                <tinymce v-if="visible" ref="tinymceEditor" :value="obj.courseCont"  @input="contentInput"></tinymce>
              </keep-alive> -->
              <a-textarea
                v-decorator="[
                  'ytContent',
                  { rules: [{ required: true, message: '请输入约谈内容' }] },
                ]"
                placeholder="请输入约谈内容"
                :auto-size="{ minRows: 2, maxRows: 6 }"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="发现问题：">
              <a-textarea
                v-decorator="[
                  'question',
                  { rules: [{ required: true, message: '请输入发现问题' }] },
                ]"
                placeholder="请输入发现问题"
                :auto-size="{ minRows: 2, maxRows: 6 }"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="整改措施及情况：">
              <a-textarea
                v-decorator="[
                  'zgcs',
                  {
                    rules: [
                      { required: true, message: '请输入整改措施及情况' },
                    ],
                  },
                ]"
                placeholder="请输入整改措施及情况"
                :auto-size="{ minRows: 2, maxRows: 6 }"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="是否纳入考核：">
              <a-radio-group name="radioGroup"
                v-decorator="[
                  'isExamine',
                  {
                    rules: [ { required: true, message: '请选择是否纳入考核' }, ],
                  },
                ]"
              >
                <a-radio :value="1">
                  是
                </a-radio>
                <a-radio :value="0">
                  否
                </a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="上传附件：">
              <a-upload
                class="avatar-uploader"
                list-type="picture-card"
                :accept="accept"
                :file-list="filePath"
                @change="handleUploadChange"
                :customRequest="resourceUpload"
                @preview="handlePreview"
                :beforeUpload="beforeUpload"
              >
                <!-- <div v-for="(item, index) in filePath" :key="index">
                  <img
                    class="w-100"
                    v-if="item && item.indexOf('pdf') == -1"
                    :src="item"
                    alt="avatar"
                  />
                  <a-icon v-else-if="item && item.indexOf('pdf') > -1" type="file-pdf" :style="{ fontSize: '64px' }" />
                </div> -->

                <div v-if="filePath.length < 4">
                  <a-icon :type="uploading ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">上传附件</div>
                </div>
              </a-upload>
              <!-- <div
                style="color: #bfbfbf;font-size: 14px;margin-top: -28px;"
              >
                图片或pdf格式
                <div style="margin-top: -20px;">
                  <a-button
                    v-if="filePath"
                    size="small"
                    type="link"
                    @click="handlePreview(filePath)"
                  >
                    预览
                  </a-button>

                  <a-button
                    v-if="filePath"
                    type="link"
                    @click="handleClear"
                  >
                    清除
                  </a-button>
                </div>
              </div> -->
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button
          v-if="action == 'add'"
          :style="{ marginLeft: '8px' }"
          type="primary"
          @click="doSubmit"
        >
          确定
        </a-button>
        <a-button
          v-if="action == 'edit'"
          :style="{ marginLeft: '8px' }"
          type="primary"
          @click="doSubmit"
        >
          确定
        </a-button>
        <a-button :style="{ marginLeft: '8px' }" @click="onClose">
          关闭
        </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { request } from "@/utils/request";
// import Tinymce from "@/components/tinymce"
import { ADD_JDJC_INSERT, UPDATE_JDJC_EDIT, ATTACHMENT_UPLOAD } from "@/services/api";
import { aqxjprojectsgetlist } from '@/services/projectManagement';
import moment from "moment";
import { mapGetters } from "vuex";
import { de, fi } from 'date-fns/locale';
export default {
  name: "ytmodify",
  // components: { Tinymce },
  data() {
    return {
      form: this.$form.createForm(this),
      gcglb: [],
      obj: {},
      dateFormat: "YYYY-MM-DD",
      title: "新增约谈记录",
      isDisabled: false,
      projectList: [],
      talkTypeList: [
        {name: '工程管理部', value: 0},
        {name: '项目部', value: 1},
        {name: '专业分包单位', value: 2},
        {name: '其他', value: 3},
      ],
      uploading: false,
      accept:".png,.jpg,.jpeg,.pdf,.PDF",
      filePath: [],
      gcglbId: '',
      gcglbName: '',
      talkType: '',
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    ytlist: {
      type: Object,
      require: true,
    },
    bytlist: {
      type: Array,
      require: true,
    },
    action: {
      type: String,
      require: true,
    },
  },
  watch: {
    filePath(newV, oldV) {
      // watch监听props里status的变化，然后执行操作
      console.log(newV, 'filePath')
    },
    action(newV, oldV) {
      // watch监听props里status的变化，然后执行操作
      this.action = newV;
      this.setTitle();
    },
    visible(newV, oldV) {
      if (newV) {
        this.$nextTick(() => {
          this.setForm();
        });
      }
    },
  },
  computed: {
    ...mapGetters("account", ["user"]),
    isBranchOffice() { //分公司
        return this.user.nowRoleKey.includes('OFFICE_AQ_LEADER')
    },
  },
  created() {
    this.getProject()
  },
  methods: {
    moment,
    beforeUpload(file){
      const accept = '.' + file.name.substring(file.name.lastIndexOf(".") + 1);
      if (this.accept.split(',').indexOf(accept) == -1) {
          this.$message.error("只能上传图片、PDF文件");
          return false
      }
    },
    handleUploadChange(info) {
      console.log(info)
      if (info.file.status === "uploading") {
        this.uploading = true;
        return;
      }
      if (info.file.status === "done") {
        this.uploading = false;
      }
      if (info.file.status === "removed") {
        this.filePath = info.fileList
      }
    },
    resourceUpload(value) {
      console.log(value)
      const formData = new FormData();
      formData.append("file", value.file);
      request(ATTACHMENT_UPLOAD, "post", formData).then((result) => {
        if (result.data.code === 0) {
          this.$message.success("上传成功!");
          this.filePath.push({
            uid: value.file.uid,
            url: result.data.data.path,
            name: value.file.name,
            status: 'done',
          });
          value.onSuccess(result.data.data.path, value.file);
        } else {
          this.$message.warning(result.data.message);
        }
      });
    },
    // handleClear() {
    //   this.filePath = []
    // },
    handlePreview(file) {
      window.open(file.url);
    },
    getProject(gcglbId, projectName) {
      this.projectList = []
      let params = {"needPage": false, gcglbId, projectName}
      aqxjprojectsgetlist(params).then(res => {
        if (res.status == 200) {
          this.$nextTick(() => {
            this.projectList = res.data.data.list
          })
        }
      })
    },
    handleSearch(value) {
      this.getProject(this.gcglbId, value)
    },
    filterOption(input, option){
        return (
            option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
    },
    handleProjectSelect(e) {
      console.log(e)
      this.projectList.forEach(el => {
        if (el.projectId === e) {
          Object.assign(this.ytlist, {gcglbId: el.gcglbId});
        }
      });
    },
    onClose() {
      this.filePath = []
      this.$emit("ytClose");
    },
    handlTypeSelect(e) {
      this.talkType = e
      if (e == 3) {
        this.form.setFieldsValue({
          bytUnit: "",
        });
        this.getProject()
      } else {
        this.form.setFieldsValue({
          bytUnitName: "",
        });
      }
    },
    handlSelect(e) {
      let item = this.bytlist.filter((item) => item.id == e);
      console.log(item)
      if (item.length > 0) {
        this.gcglbId = item[0].id
        this.gcglbName = item[0].name
        this.getProject(item[0].id)
      } else {
        this.gcglbId = ''
        this.gcglbName = ''
        this.getProject()
      }
      // if (item.length == 0 || this.ytlist.bytUnit == '') {
      //   this.form.setFieldsValue({
      //     bytUnitName: "",
      //   });
      //   this.isDisabled = false
      // }
      // if (item && item.length > 0) {
      //   this.form.setFieldsValue({
      //     bytUnitName: item[0].name,
      //   });
      //   this.isDisabled = true
      // }
    },
    doSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          if (values.bytUnit) values.bytUnitName = this.gcglbName
          //保存结果数据
          Object.assign(this.ytlist, values);
          if (this.filePath.length > 0) {
            let fileArr = []
            this.filePath.forEach(el => {
              fileArr.push(el.url)
            })
            this.ytlist.filePath = fileArr.join(',')
          }
          if (this.action == "add") {
            request(ADD_JDJC_INSERT, "post", this.ytlist)
              .then((res) => {
                if (res.data.msg == "success") {
                  this.$message.success("创建成功");
                }
                this.onClose();
              })
              .catch(() => {
                this.onClose();
              });
          } else if (this.action == "edit") {
            let params = {};
            params = Object.assign(params, values);
            params.id = this.ytlist.id;
            if (this.filePath.length > 0) {
              let fileArr = []
              this.filePath.forEach(el => {
                fileArr.push(el.url)
              })
              params.filePath = fileArr.join(',')
            } else {
              params.filePath = ''
            }
            if (this.ytlist.gcglbId) {
              params.gcglbId = this.ytlist.gcglbId
            }
            request(UPDATE_JDJC_EDIT, "post", params)
              .then((res) => {
                if (res.data.code == 0) {
                  this.$message.success("修改成功");
                } else {
                  this.$message.error(res.data.msg);
                }
                this.onClose();
              })
              .catch(() => {
                this.onClose();
              });
          }
        }
      });
    },
    onChange(date, dateString) {
      this.ytlist.checkTime = dateString;
    },
    setTitle() {
      if (this.action == "edit") {
        this.title = "修改约谈记录";
      } else if (this.action == "look") {
        this.title = "查看约谈记录";
      } else {
        this.title = "新增约谈记录";
      }
    },
    setForm() {
      console.log(this.ytlist, 'ytlist')
      if (this.ytlist.filePath) {
        let fileArr = this.ytlist.filePath.split(',')
        fileArr.forEach((el, index) => {
          this.filePath.push({
            uid: index,
            url: el,
            name: 'image',
            status: 'done',
          })
        })
      } else {
        this.filePath = []
      }
      this.talkType = this.ytlist.talkType
      this.$nextTick(() => {
        this.form.setFieldsValue({
          talkType: this.ytlist.talkType,
          projectId: this.ytlist.projectId,
          ytUnit: this.ytlist.ytUnit,
          bytUnit: this.ytlist.bytUnit,
          ytrName: this.ytlist.ytrName,
          bytrName: this.ytlist.bytrName,
          ytContent: this.ytlist.ytContent,
          question: this.ytlist.question,
          zgcs: this.ytlist.zgcs,
          bytUnitName: this.ytlist.bytUnitName,
          isExamine: this.ytlist.isExamine,
        });
      })
    },
  },
};
</script>

<style scoped></style>
