import { ADMIN } from '@/services/api'
import {request, METHOD, removeAuthorization} from '@/utils/request'

// const BASE_URL = process.env.VUE_APP_API_BASE_URL + '/score'
const ADMIN_API = `${ADMIN}/score`


export async function rattingAdd(data) {
    return request(ADMIN_API + '/ratting/add', METHOD.POST, data)
}


export async function rattingList(params) {
    return request(ADMIN_API + `/ratting/list`, METHOD.GET, params)
}

export async function rattingitemAdd(data) {
    return request(ADMIN_API + '/rattingitem/add', METHOD.POST, data)
}
export async function rattingitemList(params) {
    return request(ADMIN_API + '/rattingitem/list', METHOD.GET, params)
}
export async function rattingDelete(params) {
    return request(ADMIN_API + '/ratting/delete', METHOD.GET, params)
}
export async function rattingitemDelete(params) {
    return request(ADMIN_API + '/rattingitem/delete', METHOD.GET, params)
}
export async function monthcheckAdd(params) {
    return request(ADMIN_API + '/monthcheck/add', METHOD.POST, params)
}
export async function monthcheckList(params) {
    return request(ADMIN_API + '/monthcheck/list', METHOD.POST, params)
}
export async function monthcheckDelete(params) {
    return request(ADMIN_API + '/monthcheck/delete', METHOD.GET, params)
}
export async function monthcheckUpdate(params) {
    return request(ADMIN_API + '/monthcheck/update', METHOD.POST, params)
}
export async function monthcheckGet(params) {
    return request(ADMIN_API + '/monthcheck/get', METHOD.GET, params)
}
export async function rattingitemUpdate(params) {
    return request(ADMIN_API + '/rattingitem/update', METHOD.POST, params)
}
export const monthcheckExport = ADMIN_API + '/monthcheck/exportExcel'
export const monthcheckListExport = ADMIN_API + '/monthcheck/download'

