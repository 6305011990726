<template>
    <a-modal v-model="visible" :footer="null" width="840px" @cancel="close">
        <template slot="title">
            <div style="padding: 0 64px;">

                <a-steps :current="step">

                    <a-step title="第一步" />
                    <a-step title="第二步" />
                </a-steps>
            </div>
        </template>

        <div v-if="step == 0" class="margin-auto">
            <a-radio-group v-model="radioValue">
                <a-radio class="radioStyle" :value="1" :disabled="safeList.length == 0">
                    更新现有应急预案 
                    
                    <a-tooltip>
                        <template slot="title" v-if="safeList.length == 0">
                            暂无应急预案，请新增预案后再更新
                        </template>
                        <a-icon type="question-circle" v-if="safeList.length == 0"/>
                    </a-tooltip>
                </a-radio>
                <a-radio class="radioStyle" :value="2">
                    创建新应急预案
                </a-radio>

            </a-radio-group>

            <div>
                <a-button type="primary" @click="handleStep(0)">确认</a-button>
            </div>
        </div>

        <div v-if="step == 1">

            <div v-if="radioValue == 1 && !params" class="margin-auto">
                <!-- 是编辑则选择编辑哪个 -->
                <div class="dis-flex mb-2">
                    <span class="mr-2">选择应急预案</span>

                    <a-select v-model="safeid" style="width: 260px" :options="safeList" allowClear>
                    </a-select>
                </div>
                <div>
                    <a-button type="primary" @click="handleStep(1)">确认</a-button>
                </div>
            </div>

            <div v-if="params">
                <a-form-model ref="ruleForm" :model="params" :rules="rules">
                    <a-row>
                        <a-col :span="12">
                        <a-form-model-item label="应急预案名称" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="emergencyname">
                            <a-input placeholder="请输入应急预案名称" v-model="params.emergencyname" />
                        </a-form-model-item>
                        </a-col>
                        <a-col :span="12">
                        <a-form-model-item label="版本号" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="version">
                            <a-input placeholder="请输入版本号" v-model="params.version" />
                        </a-form-model-item>
                        </a-col>
                        <a-col :span="12">
                        <a-form-model-item label="发文文号" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="pubno">
                            <a-input placeholder="请输入发文文号" v-model="params.pubno" />
                        </a-form-model-item>
                        </a-col>
                    
                        <a-col :span="12">
                        <a-form-model-item label="发布日期" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="reserved1">
                            <a-date-picker v-model="params.reserved1" style="width: 100%" allowClear mode="date"
                            :format="'YYYY-MM-DD'" :valueFormat="'YYYY-MM-DD'"/>
                        </a-form-model-item>
                        </a-col>
                    
            
                        <a-col :span="24">
                        <a-form-model-item label="上传附件" :label-col="{ span: 3 }" :wrapper-col="{ span: 14 }" prop="year">
                            <a-upload
                            class="avatar-uploader"
                            list-type="picture-card"
                            :accept="accept"
                            :file-list="filePath"
                            @change="handleUploadChange"
                            :customRequest="resourceUpload"
                            @preview="handlePreview"
                            :beforeUpload="beforeUpload"
                            multiple
                            >
                            <!-- <div v-for="(item, index) in filePath" :key="index">
                                <img
                                class="w-100"
                                v-if="item && item.name.indexOf('pdf') == -1"
                                :src="item"
                                alt="avatar"
                                />
                                <a-icon v-else-if="item && item.name.indexOf('pdf') > -1" type="file-pdf" :style="{ fontSize: '64px' }" />
                            </div> -->
            
                            <div v-if="filePath.length < 10">
                                <a-icon :type="uploading ? 'loading' : 'plus'" />
                                <div class="ant-upload-text">上传附件</div>
                            </div>
                            </a-upload>
                        </a-form-model-item>
                        </a-col>
            
            
                    </a-row>
                    </a-form-model>
                <a-row style="padding-top: 16px;" v-if="optionType == 'edit'">
                    <a-col :span="12"></a-col>
                    <a-col :span="12">
                        <a-form-model-item :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }" prop="year">
                            <div class="flex flex-row-reverse">
                                <a-button type="primary" @click="handleSubmit">保存</a-button>
                                <a-button class="mr-2" @click="close">返回</a-button>
                            </div>
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </div>

        </div>

    </a-modal>
</template>

<script>
import {
    emergencyplanUrellist,
    emergencyplanByNoticeId,
} from "@/services/specificatioNotification";
import { request } from "@/utils/request";
import { mapGetters } from 'vuex'
import moment from "moment";
import { emergencyplanAdd,emergencyplanUpdate, emergencyplanByID } from "@/services/emergencyManagement";
import { ATTACHMENT_UPLOAD } from "@/services/api";

export default {
    name: 'safeDialog',
    data() {
        return {
            visible: false,
            step: 0,
            radioValue: null,
            safeid: '',
            safeList: [],
            id: '',
            disabled1: false,
            optionType: null,
            params: null,

            // tt
            isEdit: 0,
            rules: {
            emergencyname: [{ required: true, message: '请输入名称', trigger: 'change' }],
            // fileno: [{ required: true, message: '请输入文件编号', trigger: 'change' }],
            // pubdate: [{ required: true, message: '请选择发布日期', trigger: 'change' }],
            // pubdeptname: [{ required: true, message: '请输入发布部门', trigger: 'change' }],
            // remark: [{ required: true, message: '请选择', trigger: 'change' }],
            // attach: [{ required: true, message: '请选择', trigger: 'change' }],
            },

            // accept:".png,.jpg,.jpeg,.pdf,.PDF",
            accept:"",
            filePath: [],
            uploading: false,
            title: '新增应急预案',
        }
    },
    mounted() {
        emergencyplanUrellist().then(res => {
            this.safeList = res.data.data.map(item => {
                return {
                    label: item.emergencyname,
                    value: item.emergencyid,
                    ...item
                }
            })
        })
    },
    methods: {
        open(id, op) {
            this.optionType = op
            if (op == 'edit') {
                this.id = id;
                this.visible = true

            } else if(op == 'view') {
                
                this.isEdit = 1
                this.step = 1
                emergencyplanByNoticeId({id: id}).then(res => {
                    if (res.data.code === 200) {
                        this.params = res.data.data
                        let attachList = [];
                        if (this.params.attach) {
                        attachList = this.params.attach.split(',');
                        }
                        let fileNames = [];
                        if (this.params.createuser) {
                        fileNames = this.params.createuser.split(',');
                        }
                        this.filePath = attachList.map((item, index) => {
                        return {
                            uid: index,
                            url: item,
                            name: fileNames[index],
                            status: 'done',
                        }
                        })

                        this.visible = true
                    } else {
                        this.$message.error(res.data.msg)
                    }
                })
            }
        },
        reset() {
            this.step = 0
            this.radioValue = null
            this.safeid = ''
            this.params = null
            this.isEdit = 0
            this.filePath = []
            this.uploading = false
        },
        handleStep(step) {
            if (step == 0) {
                if (this.radioValue == null) {
                    this.$message.error('请选择操作')
                    return
                }
                if (this.radioValue == 1) {
                    
                    this.step = 1
                } else {
                    this.step = 1
                    this.isEdit = 0;
                    this.params = {
                        "emergencyname": "", //安全工作索引名称
                        "version": "", //版本号
                        "pubno": "", //发文文号
                        "reserved1": "", //发布日期
                        "attach": ""
                        }
                    this.filePath = []
                }

            } else if (step == 1) {

                // 判断是否选择了制度
                let d = this.safeList.find(item => item.emergencyid == this.safeid)
                if (!d) {
                    this.$message.error('请选择应急预案')
                    return
                }
                this.isEdit = 1;
                this.params = d

                let attachList = [];
                        if (this.params.attach) {
                        attachList = this.params.attach.split(',');
                        }
                        let fileNames = [];
                        if (this.params.createuser) {
                        fileNames = this.params.createuser.split(',');
                        }
                        this.filePath = attachList.map((item, index) => {
                        return {
                            uid: index,
                            url: item,
                            name: fileNames[index],
                            status: 'done',
                        }
                        })
            }
        },

        // tt
        handleSubmit() {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
              let attach = this.filePath.map(item => item.url).join(',');
              let fileNames = this.filePath.map(item => item.name).join(',');

              let d = Object.assign({}, this.params, {attach: attach, createuser: fileNames});
              d.noticeid = this.id
            if (this.isEdit == 0) {
            //   emergencyplanAdd(d).then(res => {
            //     if (res.data.code === 200) {
            //       this.$message.success('操作成功！')
            //       this.close();
                  
            //     } else {
            //       this.$message.error(res.data.msg)
            //     }
            //   })
            } else {
              
            //   emergencyplanUpdate(this.params).then(res => {
            //     if (res.data.code === 200) {
            //       this.$message.success('操作成功！')
            //       this.close();
                  
            //     } else {
            //       this.$message.error(res.data.msg)
            //     }
            //   })
            }
            this.$emit('saveEmer', d)
            this.visible = false
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      close() {
        this.visible = false
            this.reset()
            // this.$emit('refresh')
      },
      beforeUpload(file){
        // const accept = '.' + file.name.substring(file.name.lastIndexOf(".") + 1);
        // if (this.accept.split(',').indexOf(accept) == -1) {
        //     this.$message.error("只能上传图片、PDF文件");
        //     return false
        // }
      },
      handleUploadChange(info) {
        console.log(info)
        if (info.file.status === "uploading") {
          this.uploading = true;
          return;
        }
        if (info.file.status === "done") {
          this.uploading = false;
        }
        if (info.file.status === "removed") {
          this.filePath = info.fileList
        }
      },
      resourceUpload(value) {
        const formData = new FormData();
        formData.append("file", value.file);
        request(ATTACHMENT_UPLOAD, "post", formData).then((result) => {
          if (result.data.code === 0) {
            this.$message.success("上传成功!");
            this.filePath.push({
              uid: value.file.uid,
              url: result.data.data.path,
              name: value.file.name,
              status: 'done',
            });
            value.onSuccess(result.data.data.path, value.file);
          } else {
            this.$message.warning(result.data.message);
          }
        });
      },
      handlePreview(file) {
        window.open(file.url);
      },
    },
}
</script>

<style lang="less" scoped>
.radioStyle {
    display: block;
    height: 30px;
    line-height: 30px;
    margin-bottom: 10px;
}

.margin-auto {
    width: 60%;
    margin: 0 auto;
}
.dis-flex {
    display: flex;
}
</style>