var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a_Method" },
    [
      _c(
        "div",
        { staticClass: "action" },
        [
          _vm.showAddNewBtn
            ? _c(
                "a-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.onContextMenuClick("0", "1")
                    },
                  },
                },
                [_vm._v("新增区域")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("a-tree", {
        attrs: {
          "tree-data": _vm.treeDataSource,
          expandedKeys: _vm.expandedKeys,
          checkable: "",
          "selected-keys": _vm.selectedKeys,
        },
        on: {
          "update:expandedKeys": function ($event) {
            _vm.expandedKeys = $event
          },
          "update:expanded-keys": function ($event) {
            _vm.expandedKeys = $event
          },
          select: _vm.onSelect,
          check: _vm.onCheck,
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function (ref) {
              var treeKey = ref.key
              var title = ref.title
              return [
                _c(
                  "a-dropdown",
                  {
                    attrs: { trigger: ["contextmenu"] },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "overlay",
                          fn: function () {
                            return [
                              _c(
                                "a-menu",
                                {
                                  on: {
                                    click: function (ref) {
                                      var menuKey = ref.key

                                      return _vm.onContextMenuClick(
                                        treeKey,
                                        menuKey
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("a-menu-item", { key: "1" }, [
                                    _vm._v("新增"),
                                  ]),
                                  _c("a-menu-item", { key: "2" }, [
                                    _vm._v("编辑"),
                                  ]),
                                  _c("a-menu-item", { key: "3" }, [
                                    _vm._v("删除"),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex" },
                      [
                        _c(
                          "a-tooltip",
                          { attrs: { placement: "topLeft" } },
                          [
                            _c("template", { slot: "title" }, [
                              _c("span", [_vm._v(_vm._s(title))]),
                            ]),
                            _c(
                              "span",
                              {
                                staticClass: "ellipsis-1",
                                staticStyle: { width: "200px" },
                              },
                              [_vm._v(_vm._s(title))]
                            ),
                          ],
                          2
                        ),
                        _c("div", { staticClass: "sTables ml-5" }, [
                          _c(
                            "div",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.onContextMenuClick(treeKey, "1")
                                },
                              },
                            },
                            [_vm._v("新增")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "ml-2",
                              on: {
                                click: function ($event) {
                                  return _vm.onContextMenuClick(treeKey, "2")
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "ml-2",
                              on: {
                                click: function ($event) {
                                  return _vm.onContextMenuClick(treeKey, "3")
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.modalTitle,
            width: 600,
            "body-style": { height: "200px", overflowY: "auto" },
          },
          on: { ok: _vm.handleAddNewSubmit, cancel: _vm.cancel },
          model: {
            value: _vm.addNewDialog,
            callback: function ($$v) {
              _vm.addNewDialog = $$v
            },
            expression: "addNewDialog",
          },
        },
        [
          _c("form-index", {
            ref: "addNewFormIndex",
            attrs: { formData: _vm.addNewFormData },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }