<template>
    <div class="container">
    <a-descriptions bordered :column="3">
      <a-descriptions-item label="班组编号">{{ hqTeamVo.id }}</a-descriptions-item>
      <a-descriptions-item label="姓名">{{ hqTeamVo.curator }}</a-descriptions-item>
      <a-descriptions-item label="联系电话">{{ hqTeamVo.curatorPhone }}</a-descriptions-item>
      <a-descriptions-item label="班组名称">{{ hqTeamVo.teamName }}</a-descriptions-item>
      <a-descriptions-item label="班组类别">{{ hqTeamVo.teamType == 1 ? '企业班组' : hqTeamVo.teamType == 2 ? '班组合伙人' : '专业班组' }}</a-descriptions-item>
      <a-descriptions-item label="承接内容">
        <span v-if="hqTeamWorkContentVoList.length > 0" class="check" @click="workContentCheck">查看</span>
      </a-descriptions-item>
      <a-descriptions-item label="规模（人数）">{{ hqTeamVo.scale }}</a-descriptions-item>
      <!-- <a-descriptions-item label="结算方式">{{ getSettlementType() }}</a-descriptions-item> -->
      <a-descriptions-item label="审核状态">{{ status }}</a-descriptions-item>
      <!-- <a-descriptions-item label="可承接规模">{{ hqTeamVo.minCarryScale }}平方 - {{ hqTeamVo.maxCarryScale }}平方</a-descriptions-item> -->
      <a-descriptions-item label="可承接区域">{{ hqTeamVo.scaleArea }}</a-descriptions-item>
      <a-descriptions-item label="持证人数">{{ hqTeamVo.certPersonNum }}</a-descriptions-item>
      <!-- <a-descriptions-item label="班组成员">
        <div v-for="(item, index) in hqTeamStaffVoList" :key="item.id" @click="onTeamStaffClick(index)">
            <span class="check mt-1">{{ item.name }}</span>
        </div>
      </a-descriptions-item> -->
      <!-- <a-descriptions-item v-if="hqTeamVo.teamType && hqTeamVo.teamType == 3" label="归属大班">
        <span class="check mt-1" @click="onTeamClick()">{{ bigTeamObj.teamName }}</span>
      </a-descriptions-item> -->
      <!-- <a-descriptions-item v-if="hqTeamVo.teamType && hqTeamVo.teamType != 3" label="下属小班">
        <div v-for="(item) in proTeamList" :key="item.id" @click="onTeamClick(item)">
            <span class="check mt-1">{{ item.teamName }}</span>
        </div>
      </a-descriptions-item> -->
      <!-- <a-descriptions-item label="班组简介">
        <span v-if="hqTeamVo.teamIntro" class="check" @click="teamIntroCheck">查看</span>
        <span v-else>暂无数据</span>
      </a-descriptions-item> -->
      <a-descriptions-item label="项目业绩">
        <span v-if="hqTeamPerformanceVoList.length > 0" class="check" @click="projModalCheck">查看</span>
        <span v-else>暂无数据</span>
      </a-descriptions-item>
      <a-descriptions-item v-if="hqTeamVo.teamType && hqTeamVo.teamType == 1" label="营业执照">
        <span v-if="officeAuth.businessLicense" class="check" @click="modalCheck('营业执照', '', '', officeAuth.businessLicense)">查看</span>
        <span v-else>暂无数据</span>
      </a-descriptions-item>
      <a-descriptions-item label="个人身份证">
        <span v-if="staffVo.facePath || staffVo.backPath" class="check" @click="modalCheck('个人身份证', '', '', [staffVo.facePath, staffVo.backPath])">查看</span>
        <span v-else>暂无数据</span>
      </a-descriptions-item>
      <a-descriptions-item v-if="hqTeamVo.teamType && hqTeamVo.teamType == 1" label="法人身份证">
        <span v-if="officeAuth.corporateIdCardFront || officeAuth.corporateIdCardBack" class="check" @click="modalCheck('法人身份证', '', '', [officeAuth.corporateIdCardFront, officeAuth.corporateIdCardBack])">查看</span>
        <span v-else>暂无数据</span>
      </a-descriptions-item>
    </a-descriptions>
      <a-modal v-model="staffModalVisible" title="班组成员" :destroyOnClose="true" :width="800" :footer="null" @cancel="staffModalCancel">
        <a-descriptions bordered :column="1">
          <a-descriptions-item label="联系人">
            {{ staffObj.name }}
          </a-descriptions-item>
          <a-descriptions-item label="联系方式">
            {{ staffObj.phone }}
          </a-descriptions-item>
          <a-descriptions-item label="成员角色">
            {{ staffObj.role == 1 ? '组长' : '组员' }}
          </a-descriptions-item>
        </a-descriptions>
      </a-modal>
      <a-modal v-model="workContentVisible" title="承接内容" :destroyOnClose="true" :width="600" :footer="null" @cancel="projModalCancel">
        <div>
          <a-collapse v-model="activeKey">
            <a-collapse-panel v-for="(item) in hqTeamWorkContentVoList" :key="item.id + ''" :header="item.workTypeSelected">
              <div>
                <a-descriptions bordered :column="1">
                  <a-descriptions-item label="工作内容">
                    {{ item.workTypeSelected }}
                  </a-descriptions-item>
                  <a-descriptions-item label="结算方式">
                    {{ item.settlementName }}
                  </a-descriptions-item>
                  <a-descriptions-item label="单价">
                    {{ item.minPrice }}元/{{ item.settleUnit }} - {{ item.maxPrice }}元/{{ item.settleUnit }}
                  </a-descriptions-item>
                </a-descriptions>
              </div>
            </a-collapse-panel>
          </a-collapse>
        </div>
      </a-modal>
      <a-modal v-model="projModalVisible" title="项目业绩" :destroyOnClose="true" :width="800" :footer="null" @cancel="projModalCancel">
        <div>
          <a-steps progress-dot :current="hqTeamPerformanceVoList.length" direction="vertical">
            <a-step v-for="item in hqTeamPerformanceVoList" :key="item.id">
              <div class="step-time" slot="title">{{ moment(item.startDate).format('YYYY-MM-DD') }} - {{ moment(item.endDate).format('YYYY-MM-DD') }}</div>
              <div slot="description">
                <div class="step-team-name flex a-center">
                  <a-tag :color="item.status == 0 ? '#108ee9' : '#CCCCCC'"> {{ item.status == 0 ? '在建' : '完工' }}</a-tag>
                  <span>项目名称：{{ item.projectName }}</span>
                </div>
                <div>班组人数：{{ item.workerNum }}</div>
                <div>工作内容：{{ item.workTypeSelected }}</div>
                <div>
                  <img class="mr-2 pointer" style="width: 100px;" v-for="(url, index) in item.photoList" :key="index" :src="url" alt="" @click="handlePreview(url)">
                </div>
                <!-- <div>劳务产值：{{ item.labourOutputValue }}万元</div>
                <div>内容描述：{{ item.description }}</div> -->
              </div>
            </a-step>
          </a-steps>
        </div>
      </a-modal>
      <a-modal v-model="modalVisible" :title="modalTitle" :destroyOnClose="true" :width="800" :footer="null" @cancel="modalCancel">
        <a-descriptions bordered :column="1">
          <a-descriptions-item v-if="modalSubTitle" :label="modalSubTitle">
            {{ modalContent }}
          </a-descriptions-item>
          <a-descriptions-item label="附件">
            <img class="attachment-img" v-if="attachmentUrl_2" :src="attachmentUrl_2" alt="">
            <span v-if="attachmentUrl">
              <img class="attachment-img" v-if="(attachmentUrl.indexOf('contractPath') == -1 && attachmentUrl.indexOf('CONTRACT') == -1) && attachmentUrl.indexOf('THREE_AQJY_TEPL_ALL') == -1" :src="attachmentUrl" alt="">
              <a v-else :href="attachmentUrl" target="_blank">查看附件</a>
            </span>
            <span v-else>暂未上传</span>
          </a-descriptions-item>
        </a-descriptions>
      </a-modal>
      <a-modal v-model="teamIntroVisible" title="班组简介" ok-text="确认" @ok="() => teamIntroVisible = false" :footer="null">
        <p>{{hqTeamVo.teamIntro}}</p>
      </a-modal>
      <a-modal :visible="previewVisible" :footer="null" :width="1000" @cancel="closeImgShow">
        <img alt="example" width="950" :src="imgPath" />
      </a-modal>
    </div>
  </template>
  
  <script>
  import { teamDetail, getBigTeamByProTeamId, getProTeamByBigTeamId, hgTeamWorkType } from "@/services/hgTeam"
  import moment from 'moment'
  export default {
    data() {
      return {
        id: '',
        hqTeamPerformanceVoList: [],
        hqTeamStaffVoList: [],
        hqTeamWorkContentVoList: [],
        hqTeamVo: {},
        proTeamList: [],
        staffObj: {},
        officeAuth: {},
        staffVo: {},
        bigTeamObj: '',
        teamIntroVisible: false,
        staffModalVisible: false,
        projModalVisible: false,
        workContentVisible: false,
        modalVisible: false,
        modalTitle: '',
        modalSubTitle: '',
        modalContent: '',
        attachmentUrl: '',
        attachmentUrl_2: '',
        workTypeList: [],
        activeKey: [],
        settlementList: [
          { name: "计时", value: "0", },
          { name: "计天", value: "1", }, 
          { name: "计量", value: "2", }, 
          { name: "包工", value: "3", }, 
          { name: "定额", value: "4", }, 
          { name: "其他", value: "5", }
        ],
        previewVisible: false,
        imgPath: ''
      };
    },
    activated() {
      this.id = this.$route.params.id
      this.getWorkType()
    },
    watch: {
    activeKey(key) {
      console.log(key);
    },
  },
    computed: {
        status() {
            switch(this.hqTeamVo.status) {
                case '0':
                    return '待提交申请'
                case '1':
                    return '待审批'
                case '2':
                    return '审批通过'
                case '3':
                    return '审批不通过'
                default:
                    return ''
            }
        }
    },
    methods: {
      moment,
      getWorkType() {
        hgTeamWorkType().then((res) => {
          if (res.data.code === 0) {
            this.workTypeList = res.data.data.map(item => {
              let transformedItem = {
                text: item.name,
                id: item.code
              }
              if (item.child) {
                transformedItem.children = item.child.map(childItem => {
                  return {
                    text: childItem.name,
                    id: childItem.code
                  };
                });
              }
              return transformedItem;
            })
            this.getTeamDetail()
          } else {
            this.$message.error(res.data.msg)
          }
        });
      },
      getTeamDetail() {
        teamDetail(this.id).then(res => {
          if (res.data.code === 0) {
            if (res.data.data.hqTeamPerformanceVoList) {
              res.data.data.hqTeamPerformanceVoList.forEach(el => {
                el.workTypeSelected = this.setWorkTypeStr(el)
                if (el.photoList && el.photoList.length > 0) el.photoList = JSON.parse(el.photoList)
              })
              this.hqTeamPerformanceVoList = res.data.data.hqTeamPerformanceVoList
            }
            if (res.data.data.hqTeamStaffVoList) this.hqTeamStaffVoList = res.data.data.hqTeamStaffVoList
            if (res.data.data.hqTeamVo) this.hqTeamVo = res.data.data.hqTeamVo
            if (res.data.data.officeAuth) this.officeAuth = res.data.data.officeAuth
            if (res.data.data.staffVo) this.staffVo = res.data.data.staffVo
            if (res.data.data.hqTeamWorkContentVoList) {
              res.data.data.hqTeamWorkContentVoList.forEach(el => {
                el.workTypeSelected = this.setWorkTypeStr(el)
                if (el.settleMethod && el.settleMethod.length > 0) {
                  el.settlementName = this.settlementList.filter(i => i.value == el.settleMethod)[0].name
                }
              })
              this.hqTeamWorkContentVoList = res.data.data.hqTeamWorkContentVoList
            }
            if (this.hqTeamVo.teamType != 3) {
                this.getProTeamData()
            } else {
                this.getBigTeamByProTeamIdData()
            }
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      //设置工作内容
      setWorkTypeStr(el) {
        let workTypeSelected = ''
        let result = JSON.parse(el.typeCode).reduce((acc, activeId) => {
          let parent = this.workTypeList.find(item =>  {
            return item.children.some(child => {
              return child.id === activeId;
            });
          });
          let child = parent.children.find(item => {
            return item.id === activeId;
          });
          let existingItem = acc.find(item => {
            return item.parentText === parent.text;
          });
          if (existingItem) {
            existingItem.childText += '，' + child.text;
          } else {
            acc.push({
              parentText: parent.text,
              childText: child.text
            });
          }
          return acc;
        }, []);
        result.forEach(el => {
          if (result.length > 1) {
            workTypeSelected += `${el.parentText}/${el.childText}；`
          } else {
            workTypeSelected += `${el.parentText}/${el.childText}`
          }
        })
        return workTypeSelected
      },
      teamIntroCheck() {
        this.teamIntroVisible = true
      },
      getBigTeamByProTeamIdData() {
        if (this.hqTeamVo.superTeamId) {
            getBigTeamByProTeamId(this.id).then(res => {
                if (res.data.code === 0) {
                    this.bigTeamObj = res.data.data
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        } else {
            return ''
        }
      },
      getProTeamData() {
        getProTeamByBigTeamId({hqTeamId: this.id}).then(res => {
            if (res.data.code === 0) {
                this.proTeamList = res.data.data
            } else {
                this.$message.error(res.data.msg)
            }
        })
      },
      onTeamClick(item) {
        let id = ''
        if (item) {
            id = item.id
        } else {
            id = this.bigTeamObj.id
        }
        this.$router.push({
          name:'班组详情',
          params: {
            id
          },
        })
      },
      getSettlementType () {
        let arr = []
        if (this.hqTeamVo.settleMethod) arr.push(this.hqTeamVo.settleMethod)
        if (arr && arr.length > 0) {
            const tempSettlementList = [
                { name: "计时", value: "0" },
                { name: "计天", value: "1" }, 
                { name: "计量", value: "2" }, 
                { name: "包月", value: "3" }, 
                { name: "定额", value: "4" }, 
                { name: "其他", value: "5" }
            ]
            let temp = []
            arr.forEach((item) => {
                let obj = tempSettlementList.filter(i => i.value == item)[0]
                temp.push(obj.name)
            })
            return temp.join('，')
        } else {
            return ''
        }
      },
      getStaffWorkType (arr) {
        if (arr && arr.length > 0) {
            const tempWorkList = [
                {carft: '焊工', code: '027'}, {carft: '杂工', code: 'Z10'}, {carft: '木工', code: '240'}, {carft: '钢筋工', code: '020'}, {carft: '砌筑工', code: '010'}, {carft: '混凝土工', code: '040'},
                {carft: '管道工', code: '110'}, {carft: '油漆工', code: '193'}, {carft: '门窗幕墙安装工', code: '210'}, {carft: '镶贴工', code: '192'}, {carft: '装饰装修工', code: '190'},
                {carft: '建筑起重机械司机', code: 'Z30'}, {carft: '建筑架子工', code: 'Z40'}, {carft: '着升降脚手架安装拆卸工', code: '031'}, {carft: '高处作业吊篮安装拆卸工', code: '033'},
                {carft: '起重工', code: '080'}, {carft: '电工', code: '130'}, {carft: '起重信号工', code: '170'}, {carft: '建筑起重机械安装拆卸工', code: '180'},{carft: '其他', code: 'Z20'}
            ]
            let temp = []
            arr.forEach((item) => {
                let obj = tempWorkList.filter(i => i.code == item)[0]
                temp.push(obj.carft)
            })
            return temp.join('，')
        } else {
            return ''
        }
      },
      onTeamStaffClick(index) {
        this.staffObj = this.hqTeamStaffVoList[index]
        this.staffModalVisible = true
      },
      staffModalCancel() {
        this.staffObj = {}
      },
      formatDate(str) {
        return moment(str).format('YYYY-MM-DD')
      },
      workContentCheck() {
        this.workContentVisible = true
      },
      projModalCheck() {
        this.projModalVisible = true
      },
      modalCheck(title, subTitle, content, url) {
        this.modalTitle = title
        this.modalSubTitle = subTitle
        this.modalContent = content
        if (Array.isArray(url) && url.length > 1) {
          this.attachmentUrl = url[0]
          this.attachmentUrl_2 = url[1]
        } else {
          this.attachmentUrl = url
        }
        this.modalVisible = true
      },
      projModalCancel() {
        
      },
      modalCancel() {
        setTimeout(() => {
          this.modalTitle = '',
          this.modalSubTitle = '',
          this.modalContent = '',
          this.attachmentUrl = '',
          this.attachmentUrl_2 = ''
        }, 200);
      },
      // 预览图片
      handlePreview(url) {
        this.previewVisible = true;
        this.imgPath = url;
      },
      closeImgShow() {
        this.previewVisible = false;
        this.imgPath = '';
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  .content {
    display: flex;
  }
  .check {
    color: #89bceb;
    cursor: pointer;
  }
  .attachment-img {
    width: 550px;
  }
  .step-time {
    
  }
  .step-team-name {
    font-weight: bold;
    font-size: 18px;
    color: #000;
  }
  ::v-deep .ant-descriptions-item-label {
    font-weight: bold;
  }
  ::v-deep .ant-steps-item-content {
    width: 600px !important;
  }
  </style>
  