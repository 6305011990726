var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.$route.meta.showFather,
              expression: "$route.meta.showFather",
            },
          ],
          staticClass: "a_Approval",
        },
        [
          _c("div", { staticClass: "plug-Overview" }, [_vm._v("安全巡检计划")]),
          _c("a-divider"),
          _c(
            "a-tabs",
            { attrs: { "default-active-key": _vm.defaultActiveKey } },
            [
              _c(
                "a-tab-pane",
                { key: "1", attrs: { tab: "安全巡检计划", forceRender: "" } },
                [_c("weekly-inspection-plan")],
                1
              ),
              _c(
                "a-tab-pane",
                {
                  key: "2",
                  attrs: { tab: "领导季度带班检查", forceRender: "" },
                },
                [_c("leader-inspection-plan")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }