<!-- 退场审批 -->
<template>
  <div class="container">
    <form-index ref="formindex" :formData="formData" @handleSubmit="handleSubmit" :flexSubmit="true" @handlSelect="handlSelect">
        <!-- 下部分按钮插槽 -->
        <template #flexSubmit>
          <a-button type="primary" html-type="submit"> 查询 </a-button>
        </template>
      </form-index>
    <div class="a_a_footer">
      <div>
        <a-space class="operator">
          <a-button @click="doDowloadBatch(1)">批量通过</a-button>
        </a-space>
        <a-space class="operator">
          <a-button @click="doDowloadBatch(2)">批量驳回</a-button>
        </a-space>
      </div>
      <!-- 表格封装 -->
      <standard-table class="" :columns="columns" :dataSource="dataSource" :selectedRows.sync="selectedRows" rowKey="id"
        :scroll="{ x: 1300 }" :pagination="pagination" :loading="tableLoading" @change="onPageChange" :isAlert="false"
        :bordered="true" @update:selectedRows="updateSelectRows($event)">
        <template slot="index" slot-scope="{ index }">
          <span>
            {{
              (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1
            }}
          </span>
        </template>
        <template slot="staffName" slot-scope="{ record }">
          <router-link :to="`/personal_info?projectPersonId=${record.projectPersonId}`">{{ record.staffName }}</router-link>
        </template>
        <template slot="filePath" slot-scope="{ text }">
          <a-avatar shape="square" :size="64" :src="text" icon="user"/>
        </template>
        <div slot="action" slot-scope="{ record }">
          <div class="sTables">
            <div @click="viewEditing(record)">查看></div>
            <div @click="doDowloadBatch(1,record)">通过</div>
            <div @click="doDowloadBatch(2,record)">驳回</div>
          </div>
        </div>
      </standard-table>
    </div>
    <!-- <a-modal v-model="teamDialogVisible" title="分配班组" @ok="modifyPersonTeam" :width="650" :destroyOnClose="true">
      <form-index ref="formindexModal" :formData="formDataModal" :flexSubmit="true" @handlSelect="handlSelect"></form-index>
    </a-modal> -->
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import { exitApprovalGetList, teamPersonapprove } from "@/services/projectManagement";
import { getAllTeams } from "@/services/participationUnit"
import { getOfficeListByProjectId } from '@/services/payroll'
import { dataTool } from "echarts/lib/echarts";
import { useRender } from '@/hooks/useRender.js'

const formData = [
  {
    label: "姓名",
    placeholder: "请填写",
    decorator: [
      "staffName",
      { rules: [{ required: false, message: "请填写" }] },
    ],
    type: "input",
    key: "staffName",
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "身份证号",
    placeholder: "请填写",
    decorator: ["idCard", { rules: [{ required: false, message: "请填写" }] }],
    type: "input",
    key: "idCard",
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "所属单位",
    placeholder: "请选择",
    decorator: [
      "officeId",
      { rules: [{ required: false, message: "请选择" }] },
    ],
    type: "select",
    key: "officeId",
    selectlist: [],
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "所属班组",
    placeholder: "请选择",
    decorator: ["teamId", { rules: [{ required: false, message: "请选择" }] }],
    type: "select",
    key: "teamId",
    selectlist: [],
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "申请时间",
    placeholder: "请选择",
    decorator: [
      "applyDate",
      { rules: [{ required: false, message: "请选择" }] },
    ],
    type: "rangePicker",
    key: "applyDate",
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
    format: "yyyy-MM-DD",
  },
  {
    label: "工资状态",
    placeholder: "请选择",
    decorator: [
      "riskTips",
      { rules: [{ required: false, message: "请选择" }] },
    ],
    type: "select",
    key: "riskTips",
    selectlist: [{
      value: 1,
      name: '诚信风险'
    },{
      value: 2,
      name: '超龄'
    },{
      value: 3,
      name: '多项目'
    },{
      value: 4,
      name: '重点民族、地区'
    }],
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
];
const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
  },
  {
    title: "姓名",
    dataIndex: "staffName",
    align: "center",
    scopedSlots: { customRender: "staffName" },
  },
  {
    title: "头像",
    dataIndex: "filePath",
    align: "center",
    scopedSlots: { customRender: "filePath" },
  },
  {
    title: "所属单位",
    align: "center",
    dataIndex: "officeName",
    scopedSlots: { customRender: "officeName" },
  },
  {
    title: "所属班组",
    dataIndex: "teamName",
    align: "center",
    ellipsis: true,
    scopedSlots: { customRender: "teamName" },
  },
  {
    title: "身份证号",
    dataIndex: "idCard",
    align: "center",
    scopedSlots: { customRender: "idCard" },
  },
  {
    title: "申请时间",
    dataIndex: "enterDate",
    align: "center",
    key: "enterDate",
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "退场理由",
    dataIndex: "enterDate",
    align: "center",
    key: "enterDate",
  },
  {
    title: "工资状态",
    dataIndex: "enterDate",
    align: "center",
    key: "enterDate",
  },
  {
    title: "操作",
    align: "center",
    fixed: "right",
    width: 200,
    scopedSlots: { customRender: "action" },
  },
];
// const formDataModal = [
// {
//     label: "所属单位",
//     placeholder: "请填写",
//     decorator: [
//       "officeId",
//       { rules: [{ required: false, message: "请填写" }] },
//     ],
//     type: "select",
//     key: "officeId",
//     selectlist: [],
//     col: 12,
//     labelCol: 6,
//     wrapperCol: 14,
//     display: true,
//   },
//   {
//     label: "所属班组",
//     placeholder: "请选择",
//     decorator: ["teamId", { rules: [{ required: false, message: "请选择" }] }],
//     type: "select",
//     key: "teamId",
//     selectlist: [],
//     col: 12,
//     labelCol: 6,
//     wrapperCol: 14,
//     display: true,
//   },
// ]
export default {
  components: {
    formIndex,
    StandardTable,
  },
  data() {
    return {
      formData: formData, //form表单数据
      columns: columns, //table数据key对应
      dataSource: [], //table数组
      pagination: {
        //分页数据
        current: 1,
        pageSize: 10,
        total: 0,
      },
      tableLoading: false, //table加载中
      selectedRows: [], //选中的数据
      prams: { //测试数据
        pageNumber: 1,
        pageSize: 10,
      },
      teamDialogVisible: false,
      // formDataModal: formDataModal,
      singleRecord: {}
    };
  },
  created() {
    this.aqxjlist(this.prams);
    this.getOfficeListByProjectId()
  },
  methods: {
    updateSelectRows(e) {
      console.log(e)
    },
    //查看
    visit(record) {
      this.$router.push({
          path:'/personal_info',
          query:{
            projectPersonId: record.projectPersonId
          }
      })
    },
    handlSelect(obj) {
      const { val, keys } = obj
      if (keys === 'officeId') {
        this.doQueryTeams({ officeId: val })
      }
    },
    // 通过、驳回
    doDowloadBatch(isPass,record) {
      if(!record){
        this.selectedRows.forEach(item => {
          item.isPass = isPass
        });
        const title = isPass == 1 ? '批量通过' : '批量驳回'
        const content = isPass == 1 ? `是否批量通过${this.selectedRows.length}条` : `是否批量驳回${this.selectedRows.length}条`
        this.teamPerson(this.selectedRows,title,content)
      }else{
        record.isPass = isPass
        const title = isPass == 1 ? '通过' : '驳回'
        const content = isPass == 1 ? `确定通过？` : `确定驳回？`
        this.teamPerson([record],title,content)
      }
    },
    // 测试数据
    // ptypeState(type, status) {
    //   if (type == "ptype") {
    //     return status == 1 ? "房建" : "市政";
    //   } else {
    //     return status == 0 ? "在建" : status == 1 ? "完工" : "停工";
    //   }
    // },
    //通过、驳回请求
    teamPerson(prams,title,content) {
      this.$confirm({
        title: title,
        content: content,
        onOk: () => {
          teamPersonapprove(prams).then((res) => {
            if (res.data.code === 0) {
              this.aqxjlist(this.prams);
            } else {
              this.$message.error(res.data.msg)
            }
          })
        },
      })
    
    },
    // 分配班组
    batchModify(record){
      if (record) {
        this.selectedRows.push(record)
        this.teamDialogVisible = true
      } else {
        if (this.selectedRows.length < 1) {
          this.$message.warning('请选择人员')
        } else {
          this.teamDialogVisible = true
        }
      }
    },
    //分配班组请求
    modifyPersonTeam() {
      this.$refs.formindexModal.combinationSubmit().then(res => {
        this.selectedRows.forEach(item => {
          item.teamId = res.teamId
          item.officeId = res.officeId
          item.isPass = 1
        })
        teamPersonapprove(this.selectedRows).then((res) => {
          if (res.data.code === 0) {
            this.selectedRows = []
            this.$message.success(res.data.msg)
            this.teamDialogVisible = false
            this.aqxjlist(this.prams);
          } else {
            this.$message.error(res.data.msg)
          }
        })
      })
    },
    //列表请求数据
    aqxjlist(prams) { 
      this.tableLoading = true;
      exitApprovalGetList(prams).then((res) => { 
        this.tableLoading = false;
        if (res.data.code === 0) {
          // this.dataSource = res.data.data;
          // this.pagination.total = res.data.data.total;
          // if (this.dataSource && this.dataSource.length > 0) {
          //   this.dataSource.forEach((item) => {
          //     item.riskTipsTextList = item.riskTipsTextList.length > 0 ? item.riskTipsTextList.join(',') : '/'
          //     item.officeName = item.officeName ? item.officeName : '/'
          //     item.teamName = item.teamName ? item.teamName : '待分配'
          //   });
          // }
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    //查询所有班组
    doQueryTeams(params){
      getAllTeams(params).then(res => {
        if(res.data.code === 0){
          this.formDataFn('teamId', res.data.data, 'teamName')
        }
      })
    },
    // 对下拉框做数据处理
    formDataFn(key, data, itemName) {
      let arr = []
      data.forEach(item => {
          arr.push({ 'name': item[itemName], 'value': item.id })
      })
      this.formData.forEach(item => {
          if (item.key == key) {
              item.selectlist = arr
          }
      })
      this.formDataModal.forEach(item => {
          if (item.key == key) {
              item.selectlist = arr
          }
      })
    },
    //查询所有单位
    getOfficeListByProjectId(){
      getOfficeListByProjectId().then((res) => {
        if(res.data.code === 0){
          this.formDataFn('officeId', res.data.data, 'name')
        }
      })
    },
    // 提交表单数据
    handleSubmit(e) {
      let obj = { ...e, ...this.prams }
      obj.applyDate = obj.applyDate === '' ? [] : obj.applyDate
      this.aqxjlist(obj);
    },
    //表格 - 分页查询
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      if (obj.status == "") {
        obj.status = 0;
      }
      this.prams.pageNumber = e.current;
      this.pagination.current = e.current;
      this.aqxjlist(Object.assign({}, this.prams, obj));
    },
  },
};
</script>

<style lang="less" scoped>
  .a_a_header {
    background: #ffffff;
    border-radius: 10px;

    /deep/ .ant-form-item {
      margin-bottom: 15;
    }

    .plug-form[data-v-372326e8] .ant-form .ant-btn {
      margin-top: 5px;
      margin-left: 30px;
    }
  }

  .a_a_footer {
    padding: 20px;
    background: #ffffff;

    .operator {
      margin-right: 8px;

      .ant-btn {
        color: #307dfa;
      }
    }

    .sTables {
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        width: 100%;
        text-align: center;
        cursor: pointer;
      }

      div:nth-child(1) {
        color: #307dfa;
      }

      div:nth-child(2) {
        color: #ff5c77;
      }

      div:nth-child(3) {
        color: #615e83;
      }
    }
  }

.content_div {
  padding: 5px 0;
  cursor: pointer;
}

.content_div:hover {
  color: #307dfa;
}
</style>