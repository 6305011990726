var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("form-index", {
        ref: "formindex",
        attrs: {
          formData: _vm.formData,
          projectList: _vm.projectList,
          flexSubmit: true,
        },
        on: {
          handleSubmit: _vm.handleSubmit,
          handlSelect: _vm.handlSelect,
          handleChange: _vm.handleChange,
        },
        scopedSlots: _vm._u([
          {
            key: "flexSubmit",
            fn: function () {
              return [
                _c(
                  "a-space",
                  {
                    staticStyle: { "margin-left": "55px", "margin-top": "5px" },
                  },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: {
                          icon: "search",
                          type: "primary",
                          "html-type": "submit",
                        },
                      },
                      [_vm._v(" 查询 ")]
                    ),
                    _c(
                      "a-space",
                      { staticClass: "operator" },
                      [
                        _c("a-button", { on: { click: _vm.close } }, [
                          _vm._v(" 重置 "),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "customAction",
            fn: function () {
              return [
                _c(
                  "a-input-group",
                  { attrs: { compact: "" } },
                  [
                    _c(
                      "a-input",
                      {
                        staticStyle: {
                          width: "43.3%",
                          "text-align": "center",
                          "border-right": "0",
                        },
                        attrs: { "max-length": 2, placeholder: "最小" },
                        on: {
                          onChange: function ($event) {
                            return _vm.onChange($event, "startAge")
                          },
                          blur: function ($event) {
                            return _vm.onBlur($event, "startAge")
                          },
                        },
                        model: {
                          value: _vm.params.startAge,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "startAge", $$v)
                          },
                          expression: "params.startAge",
                        },
                      },
                      [
                        _c(
                          "a-tooltip",
                          { attrs: { slot: "suffix" }, slot: "suffix" },
                          [
                            _c("span", { staticClass: "text-color" }, [
                              _vm._v("岁"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("a-input", {
                      staticStyle: {
                        width: "32px",
                        "border-left": "0",
                        "pointer-events": "none",
                        backgroundColor: "#fff",
                      },
                      attrs: { placeholder: "~", disabled: "" },
                    }),
                    _c(
                      "a-input",
                      {
                        staticClass: "input-sytle",
                        staticStyle: {
                          width: "43.3%",
                          "text-align": "center",
                          "border-right": "0",
                        },
                        attrs: { "max-length": 2, placeholder: "最大" },
                        on: {
                          onChange: function ($event) {
                            return _vm.onChange($event, "endAge")
                          },
                          blur: function ($event) {
                            return _vm.onBlur($event, "endAge")
                          },
                        },
                        model: {
                          value: _vm.params.endAge,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "endAge", $$v)
                          },
                          expression: "params.endAge",
                        },
                      },
                      [
                        _c(
                          "a-tooltip",
                          { attrs: { slot: "suffix" }, slot: "suffix" },
                          [
                            _c("span", { staticClass: "text-color" }, [
                              _vm._v("岁"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "a_a_footer" },
        [
          _c(
            "div",
            { staticClass: "mb-1 mt-2" },
            [
              _c(
                "a-space",
                { staticClass: "operator" },
                [
                  _c(
                    "a-button",
                    { on: { click: _vm.setUploadStatus } },
                    [
                      _c("a-icon", { attrs: { type: "issues-close" } }),
                      _vm._v("设置上报状态"),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-space",
                { staticClass: "operator" },
                [
                  _vm.canJwOperate
                    ? _c(
                        "a-button",
                        { on: { click: _vm.uploadJw } },
                        [
                          _c("a-icon", { attrs: { type: "file-add" } }),
                          _vm._v("申请导入建委"),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.canOperate
                ? _c(
                    "a-space",
                    { staticClass: "operator" },
                    [
                      _c(
                        "a-button",
                        { on: { click: _vm.distribute2 } },
                        [
                          _c("a-icon", { attrs: { type: "cloud-download" } }),
                          _vm._v("分步下发考勤设备"),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-space",
                { staticClass: "operator" },
                [
                  _c(
                    "a-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.exportJw()
                        },
                      },
                    },
                    [
                      _c("a-icon", { attrs: { type: "download" } }),
                      _vm._v("导出上报建委平台资料"),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-space",
                { staticClass: "operator" },
                [
                  _c(
                    "a-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.exportStaff()
                        },
                      },
                    },
                    [
                      _c("a-icon", { attrs: { type: "download" } }),
                      _vm._v("导出全部信息"),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-space",
                { staticClass: "operator" },
                [
                  _vm.isZb
                    ? _c(
                        "a-button",
                        { on: { click: _vm.batchApprove } },
                        [
                          _c("a-icon", { attrs: { type: "download" } }),
                          _vm._v("批量审批"),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "a-space",
                { staticClass: "operator" },
                [
                  _c(
                    "a-button",
                    {
                      staticStyle: {
                        background: "#fd9e06 !important",
                        color: "#fff",
                        "border-color": "#fd9e06",
                      },
                      attrs: { ghost: "" },
                      on: { click: _vm.batchExitAll },
                    },
                    [
                      _c("a-icon", { attrs: { type: "logout" } }),
                      _vm._v("批量退场入口"),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-space",
                { staticClass: "operator" },
                [
                  _c(
                    "a-button",
                    {
                      staticStyle: {
                        background: "#fd9e06 !important",
                        color: "#fff",
                        "border-color": "#fd9e06",
                      },
                      attrs: { ghost: "" },
                      on: { click: _vm.preExitAll },
                    },
                    [
                      _c("a-icon", { attrs: { type: "logout" } }),
                      _vm._v("通知工人离场"),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-space",
                { staticClass: "operator" },
                [
                  _c(
                    "a-button",
                    {
                      staticStyle: {
                        background: "#fd9e06 !important",
                        color: "#fff",
                        "border-color": "#fd9e06",
                      },
                      attrs: { icon: "history", ghost: "" },
                      on: { click: _vm.failRecord },
                    },
                    [_vm._v(" 下发失败记录 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("standard-table", {
            attrs: {
              columns: _vm.columns,
              dataSource: _vm.dataSource,
              selectedRows: _vm.selectedRows,
              rowKey: "id",
              scroll: { x: 1300 },
              pagination: _vm.pagination,
              loading: _vm.tableLoading,
              isAlert: false,
              bordered: true,
            },
            on: {
              "update:selectedRows": [
                function ($event) {
                  _vm.selectedRows = $event
                },
                function ($event) {
                  return _vm.updateSelectRows($event)
                },
              ],
              "update:selected-rows": function ($event) {
                _vm.selectedRows = $event
              },
              change: _vm.onPageChange,
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function (ref) {
                  var index = ref.index
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            (_vm.pagination.current - 1) *
                              _vm.pagination.pageSize +
                              parseInt(index) +
                              1
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "workName",
                fn: function (ref) {
                  var record = ref.record
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "电子履历",
                            params: { projWorkNum: record.projWorkNum },
                            query: {
                              teamId: record.teamId,
                              workNum: record.workNum,
                            },
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(record.workName) + " ")]
                    ),
                    record.isCaptain == 1
                      ? _c("div", { staticStyle: { color: "#4a86e8" } }, [
                          _vm._v("(班组长)"),
                        ])
                      : _vm._e(),
                    record.leaveStatus == 0
                      ? _c("div", { staticStyle: { color: "#FF5C77" } }, [
                          _vm._v("(退场中)"),
                        ])
                      : _vm._e(),
                    record.riskNames && record.riskNames.length > 0
                      ? _vm._l(record.riskNames, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticStyle: { color: "#ff5c77" } },
                            [_vm._v("(" + _vm._s(item) + ")")]
                          )
                        })
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "workerFace",
                fn: function (ref) {
                  var text = ref.text
                  var record = ref.record
                  return [
                    _c(
                      "div",
                      { staticClass: "flex flex-column a-center mt-1" },
                      [
                        _c("a-avatar", {
                          attrs: {
                            shape: "square",
                            size: 64,
                            src: text,
                            icon: "user",
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "flex a-center j-center",
                            staticStyle: { "margin-top": "5px" },
                          },
                          [
                            record.hkFace != "" && record.hkFace != null
                              ? _c("div", [
                                  _vm._v(" " + _vm._s(record.hkFace) + " "),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "isreport",
                fn: function (ref) {
                  var text = ref.text
                  return [
                    text == 1
                      ? _c("span", [
                          _c("i", {
                            staticClass: "iconfont duigouxiao",
                            staticStyle: { color: "#00CE86" },
                          }),
                        ])
                      : _c("span", [
                          _c("i", {
                            staticClass: "iconfont path",
                            staticStyle: { color: "#FF5C77" },
                          }),
                        ]),
                  ]
                },
              },
              {
                key: "conconfirmedStatus",
                fn: function (ref) {
                  var text = ref.text
                  return [
                    text == -1
                      ? _c("span", { staticStyle: { color: "#fd9e06" } }, [
                          _vm._v("待审批"),
                        ])
                      : text == 0
                      ? _c("span", { staticStyle: { color: "#FF5C77" } }, [
                          _vm._v("不通过"),
                        ])
                      : text == 1
                      ? _c("span", { staticStyle: { color: "#00CE86" } }, [
                          _vm._v("已通过"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "syncJwStatus",
                fn: function (ref) {
                  var text = ref.text
                  var record = ref.record
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "jwTag",
                        class: _vm.getJwStatusStyle(text),
                        on: {
                          click: function ($event) {
                            return _vm.errToast(record)
                          },
                        },
                      },
                      [_c("span", [_vm._v(_vm._s(_vm.getJwStatusText(text)))])]
                    ),
                  ]
                },
              },
              {
                key: "action",
                fn: function (ref) {
                  var record = ref.record
                  return _c("div", {}, [
                    _c(
                      "div",
                      { staticClass: "sTables" },
                      [
                        _c(
                          "div",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.visit(record)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                        _c(
                          "div",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.visitEdit(record)
                              },
                            },
                          },
                          [_vm._v("修改")]
                        ),
                        _c(
                          "a-popover",
                          {
                            attrs: {
                              placement: "bottomRight",
                              trigger: "click",
                            },
                          },
                          [
                            _c("template", { slot: "content" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "content_div",
                                  on: {
                                    click: function ($event) {
                                      return _vm.batchExit(record)
                                    },
                                  },
                                },
                                [_vm._v("退场")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "content_div",
                                  on: {
                                    click: function ($event) {
                                      return _vm.eventRecord(record)
                                    },
                                  },
                                },
                                [_vm._v("诚信行为登记")]
                              ),
                            ]),
                            _c("template", { slot: "title" }, [
                              _c("span", [_vm._v("操作")]),
                            ]),
                            _c("div", [_vm._v("更多>")]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ])
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.modalTitle,
            destroyOnClose: true,
            width: _vm.modalWidth,
            footer: null,
          },
          on: { cancel: _vm.modalCancel },
          model: {
            value: _vm.modalVisible,
            callback: function ($$v) {
              _vm.modalVisible = $$v
            },
            expression: "modalVisible",
          },
        },
        [
          _vm.modalTitle === "下发任务"
            ? _c("span", [
                _vm._v(
                  "该列表有效期为3小时,结束后请前去“下发失败记录”中查看是否下发失败"
                ),
              ])
            : _vm._e(),
          _vm.errMsg
            ? _c("span", { staticClass: "mt-1" }, [_vm._v(_vm._s(_vm.errMsg))])
            : _vm._e(),
          _c("standard-table", {
            staticClass: "mt-1",
            attrs: {
              columns: _vm.modalColumns,
              dataSource: _vm.modalDataSource,
              rowKey: "id",
              scroll: { x: _vm.modalWidth - 100, y: 500 },
              pagination: _vm.modalPagination,
              loading: _vm.modalTableLoading,
              isAlert: false,
              bordered: true,
            },
            on: { change: _vm.onModalPageChange },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function (ref) {
                  var index = ref.index
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            (_vm.modalPagination.current - 1) *
                              _vm.modalPagination.pageSize +
                              parseInt(index) +
                              1
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("distribute-dialog", {
        attrs: {
          visible: _vm.distributeVisible,
          isDel: false,
          modalTitle: "下发考勤设备",
          workNumList: _vm.workNumList,
        },
        on: {
          cancel: function ($event) {
            _vm.distributeVisible = false
          },
        },
      }),
      _c(
        "a-modal",
        {
          attrs: {
            title: "退场",
            destroyOnClose: true,
            footer: null,
            width: "680px",
          },
          model: {
            value: _vm.exitDialog,
            callback: function ($$v) {
              _vm.exitDialog = $$v
            },
            expression: "exitDialog",
          },
        },
        [
          _c(
            "a-form",
            _vm._b(
              {
                attrs: {
                  id: "components-form-demo-validate-other",
                  form: _vm.exitForm,
                },
                on: { submit: _vm.exitSubmit },
              },
              "a-form",
              _vm.formItemLayout,
              false
            ),
            [
              _c(
                "a-form-item",
                { attrs: { label: "退场凭证", extra: "最多可选1个" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex a-center" },
                    [
                      _vm.defaultFileList.length > 0
                        ? [
                            _c(
                              "div",
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.defaultFileList[0].name)),
                                ]),
                                _c("a-icon", {
                                  staticClass: "ml-2",
                                  staticStyle: {
                                    color: "red",
                                    cursor: "pointer",
                                  },
                                  attrs: { type: "close" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.upRemove(
                                        _vm.defaultFileList[0]
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : [
                            _c(
                              "a-upload",
                              {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "upload",
                                      {
                                        rules: [
                                          { required: true, message: "请上传" },
                                        ],
                                      },
                                    ],
                                    expression:
                                      "[\n                'upload',\n                { rules: [{ required: true, message: '请上传' }] },\n              ]",
                                  },
                                ],
                                attrs: {
                                  beforeUpload: _vm.beforeUpload,
                                  customRequest: _vm.resourceUpload,
                                  "file-list": _vm.defaultFileList,
                                },
                                on: { preview: _vm.handlePreview },
                              },
                              [
                                _c("a-button", { attrs: { type: "primary" } }, [
                                  _vm._v(" 点击选择文件 "),
                                ]),
                              ],
                              1
                            ),
                          ],
                      _c(
                        "a-button",
                        {
                          staticClass: "ml-3",
                          attrs: { type: "link" },
                          on: { click: _vm.doDowload },
                        },
                        [_vm._v(" 下载文件模板 ")]
                      ),
                    ],
                    2
                  ),
                ]
              ),
              _c(
                "a-form-item",
                { attrs: { label: "满意程度" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "grade",
                            { rules: [{ required: true, message: "请选择" }] },
                          ],
                          expression:
                            "['grade', { rules: [{ required: true, message: '请选择' }] },]",
                        },
                      ],
                    },
                    [
                      _c("a-radio", { attrs: { value: "1" } }, [
                        _vm._v(" 不满意 "),
                      ]),
                      _c("a-radio", { attrs: { value: "2" } }, [
                        _vm._v(" 基本满意 "),
                      ]),
                      _c("a-radio", { attrs: { value: "3" } }, [
                        _vm._v(" 满意 "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { "wrapper-col": { span: 12, offset: 16 } } },
                [
                  _c(
                    "a-button",
                    { staticClass: "mr-3", on: { click: _vm.closeExitDialog } },
                    [_vm._v(" 关闭 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "primary", "html-type": "submit" } },
                    [_vm._v(" 提交 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            destroyOnClose: true,
            footer: null,
            width: "680px",
            closable: false,
          },
          model: {
            value: _vm.approveModal,
            callback: function ($$v) {
              _vm.approveModal = $$v
            },
            expression: "approveModal",
          },
        },
        [
          _c("h2", [_vm._v("是否进行批量审批？")]),
          _c(
            "div",
            { staticClass: "flex mt-5 flex-row-reverse" },
            [
              _c(
                "a-button",
                { staticClass: "mr-3", on: { click: _vm.approveCancel } },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "a-button",
                { staticClass: "mr-3", on: { click: _vm.approveReject } },
                [_vm._v(" 驳回 ")]
              ),
              _c(
                "a-button",
                {
                  staticClass: "mr-3",
                  attrs: { type: "primary" },
                  on: { click: _vm.approvePass },
                },
                [_vm._v(" 通过 ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("persence-list-modal", {
        ref: "persenceModal",
        attrs: { visible: _vm.visibleExit, visibleTitle: _vm.visibleTitle },
        on: { handleCancel: _vm.handleCancel, handleOk: _vm.handleOk },
      }),
      _c("Export-loading", {
        attrs: {
          cPercent: _vm.cPercent,
          isExporting: _vm.isExporting,
          loadingText: _vm.loadingText,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }