var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-card", [
        _c("div", { attrs: { slot: "title" }, slot: "title" }, [
          _c("div", { staticClass: "flex j-between a-center" }, [
            _c(
              "div",
              { staticClass: "ellipsis-1" },
              [
                _c("span", { staticClass: "font-bold" }, [
                  _vm._v(
                    _vm._s(
                      _vm.projectObj.projectName ||
                        "国道G324线云浮市腰古至茶洞段改线工程第二合同段"
                    )
                  ),
                ]),
                _c(
                  "a-tag",
                  {
                    staticClass: "ml-2 font-14 white",
                    attrs: { color: "#0A63F1" },
                  },
                  [_vm._v(_vm._s(_vm.getStatusText(_vm.projectObj.status)))]
                ),
                _c(
                  "a-tag",
                  {
                    staticClass: "font-14",
                    staticStyle: { color: "#0a63f1" },
                    attrs: { color: "#E6EFFF" },
                  },
                  [_vm._v(_vm._s(_vm.getPtypeStatusText(_vm.projectObj.ptype)))]
                ),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "div",
          [
            _c("div", { staticClass: "flex a-center pb-3" }, [
              _c(
                "div",
                {
                  staticClass: "flex a-center j-center",
                  staticStyle: {
                    width: "22px",
                    height: "22px",
                    background: "#eff6fe",
                    "border-radius": "4px",
                  },
                },
                [
                  _c("img", {
                    staticStyle: { width: "17px", height: "17px" },
                    attrs: {
                      src: "https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20241125/1732496689728.png?Expires=2047856689&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=tXuND2DYnt8jwc6qTonNwOJJN0k%3D",
                      alt: "",
                    },
                  }),
                ]
              ),
              _c(
                "span",
                { staticClass: "ml-1 mr-4", staticStyle: { color: "#212121" } },
                [
                  _vm._v(
                    "所属公司 | " +
                      _vm._s(_vm.projectObj.companyName || "工程总承包分公司")
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "flex a-center j-center",
                  staticStyle: {
                    width: "22px",
                    height: "22px",
                    background: "#eff6fe",
                    "border-radius": "4px",
                  },
                },
                [
                  _c("img", {
                    staticStyle: { width: "17px", height: "17px" },
                    attrs: {
                      src: "https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20241125/1732496689728.png?Expires=2047856689&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=tXuND2DYnt8jwc6qTonNwOJJN0k%3D",
                      alt: "",
                    },
                  }),
                ]
              ),
              _c(
                "span",
                { staticClass: "ml-1", staticStyle: { color: "#212121" } },
                [
                  _vm._v(
                    "所属分公司 | " +
                      _vm._s(_vm.projectObj.gcglbName || "第二事业部")
                  ),
                ]
              ),
            ]),
            _c(
              "a-row",
              { attrs: { gutter: [16, 8] } },
              [
                _c(
                  "a-col",
                  { attrs: { span: 13 } },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "flex-wrap": "wrap",
                          "justify-content": "space-between",
                        },
                      },
                      _vm._l(_vm.constructionList, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "flex black font-16 mb-1 ellipsis-1",
                            class: item.class,
                            style: item.style,
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "black-three text-center mr-3" },
                              [_vm._v(_vm._s(item.text))]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "ellipsis-1",
                                staticStyle: { width: "60%" },
                              },
                              [
                                _vm._v(_vm._s(item.value)),
                                item.unit && item.value != "暂无"
                                  ? _c("span", [_vm._v(_vm._s(item.unit))])
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _c(
                      "div",
                      { staticClass: " mt-1 pl-2 pr-1 py-1 bg-gradient" },
                      [
                        _c("div", { staticClass: "flex a-center" }, [
                          _c("img", {
                            staticStyle: {
                              width: "46px",
                              height: "46px",
                              "border-radius": "25px",
                            },
                            attrs: {
                              src: "https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20241125/1732499127654.png?Expires=2047859127&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=0ZGPPXm7vFTyD5Yq6yijIO%2BQGOc%3D",
                              alt: "",
                            },
                          }),
                          _c("div", { staticClass: "ml-1 flex w-100" }, [
                            _c("div", { staticClass: "flex-1" }, [
                              _c("div", { staticClass: "flex a-center" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "font-16 ellipsis-1",
                                    staticStyle: {
                                      color: "#212121",
                                      "max-width": "50px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.projectObj.xmjl || "暂无")
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "ml-1 project-manager" },
                                  [_vm._v("项目经理")]
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    color: "#4D4D4D",
                                    "margin-top": "5px",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.projectObj.xmjlPhone || "暂无")
                                  ),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "flex-1 font-14" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "ellipsis-1",
                                  style: _vm.multiPage
                                    ? "max-width: 225px"
                                    : "max-width: 225px",
                                },
                                [
                                  _c("span", [_vm._v("技术负责人: ")]),
                                  _vm.jsfzrList
                                    ? [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.projectObj.jsfzrList.length >
                                                0
                                                ? _vm.projectObj.jsfzrList[0]
                                                    .userName
                                                : "暂无"
                                            )
                                          ),
                                        ]),
                                        _c(
                                          "a-tooltip",
                                          {
                                            attrs: {
                                              placement: "top",
                                              title:
                                                "技术负责人: " +
                                                (_vm.projectObj.jsfzrList
                                                  .length > 0
                                                  ? _vm.projectObj.jsfzrList[0]
                                                      .userName
                                                  : "") +
                                                " " +
                                                (_vm.projectObj.jsfzrList
                                                  .length > 0
                                                  ? _vm.projectObj.jsfzrList[0]
                                                      .phone
                                                  : ""),
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.projectObj.jsfzrList
                                                    .length > 0
                                                    ? _vm.projectObj
                                                        .jsfzrList[0].phone
                                                    : ""
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    : [_vm._v("暂无")],
                                ],
                                2
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "ellipsis-1",
                                  staticStyle: { "margin-top": "8px" },
                                  style: _vm.multiPage
                                    ? "max-width: 225px"
                                    : "max-width: 225px",
                                },
                                [
                                  _c("span", [_vm._v("专职安全员: ")]),
                                  _vm.aqyList
                                    ? [
                                        _c(
                                          "a-tooltip",
                                          {
                                            attrs: {
                                              placement: "top",
                                              title:
                                                "专职安全员: " +
                                                (_vm.projectObj.aqyList.length >
                                                0
                                                  ? _vm.projectObj.aqyList[0]
                                                      .userName
                                                  : "") +
                                                " " +
                                                (_vm.projectObj.aqyList.length >
                                                0
                                                  ? _vm.projectObj.aqyList[0]
                                                      .phone
                                                  : ""),
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.projectObj.aqyList[0]
                                                      .userName
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.projectObj.aqyList[0]
                                                      .phone
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                    _c("div", {
                      staticClass: "my-2",
                      staticStyle: { border: "1px solid rgb(230, 239, 255)" },
                    }),
                    _vm._l(_vm.unitList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "flex black font-16 mb-1",
                          class: item.class,
                          style: item.style,
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "black-three text-center mr-3" },
                            [_vm._v(_vm._s(item.text))]
                          ),
                          _c("span", [
                            _vm._v(_vm._s(item.value)),
                            item.unit
                              ? _c("span", [_vm._v(_vm._s(item.unit))])
                              : _vm._e(),
                          ]),
                        ]
                      )
                    }),
                  ],
                  2
                ),
                _c(
                  "a-col",
                  { attrs: { span: 11 } },
                  [
                    _c(
                      "baidu-map",
                      {
                        staticClass: "bmView",
                        attrs: {
                          "scroll-wheel-zoom": true,
                          center: _vm.location,
                          zoom: _vm.zoom,
                        },
                      },
                      [
                        _c("bm-view", { staticClass: "bmMap" }),
                        _vm._l(_vm.persons, function (items, index) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              _c("bm-marker", {
                                attrs: {
                                  position: { lng: items.lng, lat: items.lat },
                                  icon: {
                                    url: items.show
                                      ? _vm.localActive
                                      : items.icon,
                                    size: { width: 28, height: 28 },
                                  },
                                  dragging: false,
                                },
                              }),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }