var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    [
      _c(
        "div",
        { attrs: { id: "components-form-demo-advanced-search" } },
        [
          _c(
            "a-form",
            {
              staticClass: "ant-advanced-search-form",
              attrs: { form: _vm.queryData },
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    {
                      style: { textAlign: "right", marginTop: "3px" },
                      attrs: { span: 2 },
                    },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", icon: "plus" },
                          on: {
                            click: function ($event) {
                              return _vm.addModel("", "0")
                            },
                          },
                        },
                        [_vm._v(" 新增厂商分组 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("a-table", {
        attrs: { columns: _vm.columns, "data-source": _vm.data },
        scopedSlots: _vm._u([
          {
            key: "manufacturer",
            fn: function (text, record) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      record.manufacturerKey === "yunqi" ? "云启" : "其他"
                    ) +
                    " "
                ),
              ]
            },
          },
          {
            key: "action",
            fn: function (text, record) {
              return [
                _c(
                  "span",
                  [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addModel(
                              record.manufacturerKey,
                              record.groupId
                            )
                          },
                        },
                      },
                      [_vm._v("添加下级")]
                    ),
                    _c("a-divider", { attrs: { type: "vertical" } }),
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.delGroup(record)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "div",
        [
          _c(
            "a-modal",
            {
              attrs: {
                title: _vm.title,
                visible: _vm.visible,
                "confirm-loading": _vm.confirmLoading,
                width: "560px",
              },
              on: { ok: _vm.modelOk, cancel: _vm.modelCancel },
            },
            [
              _vm.visible
                ? _c(
                    "a-form-model",
                    {
                      attrs: {
                        model: _vm.form,
                        "label-col": _vm.labelCol,
                        "wrapper-col": _vm.wrapperCol,
                      },
                    },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          ref: "groupId",
                          attrs: { label: "分组ID", prop: "groupId" },
                        },
                        [
                          _c("a-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.groupId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "groupId", $$v)
                              },
                              expression: "form.groupId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          ref: "groupName",
                          attrs: {
                            label: "分组名称",
                            prop: "groupName",
                            required: "",
                          },
                        },
                        [
                          _c("a-input", {
                            attrs: { disabled: _vm.groupNameDisabled },
                            model: {
                              value: _vm.form.groupName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "groupName", $$v)
                              },
                              expression: "form.groupName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          ref: "manufacturerKey",
                          attrs: {
                            label: "所属厂商",
                            prop: "manufacturerKey",
                            required: "",
                          },
                        },
                        [
                          _c(
                            "a-select",
                            {
                              model: {
                                value: _vm.form.manufacturerKey,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "manufacturerKey", $$v)
                                },
                                expression: "form.manufacturerKey",
                              },
                            },
                            _vm._l(_vm.manufacturerList, function (item) {
                              return _c(
                                "a-select-option",
                                {
                                  key: item.manufacturerKey,
                                  attrs: { value: item.manufacturerKey },
                                },
                                [_vm._v(_vm._s(item.manufacturerName))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          ref: "fatherGroupId",
                          attrs: {
                            label: "上级分组ID",
                            prop: "fatherGroupId",
                            required: "",
                          },
                        },
                        [
                          _c("a-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.fatherGroupId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "fatherGroupId", $$v)
                              },
                              expression: "form.fatherGroupId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }