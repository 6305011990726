var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "relative" },
    [
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c("div", { staticClass: "text-center font-bold" }, [
            _vm._v("为提升您账号的安全程度，请更新您的绑定手机号"),
          ]),
          _c(
            "a-form",
            {
              staticClass: "mt-2",
              attrs: { form: _vm.form },
              on: { submit: _vm.submit },
            },
            [
              _c(
                "a-form-item",
                [
                  _c(
                    "a-input",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "mobile",
                            {
                              rules: [
                                { required: true, message: "请输入手机号" },
                                {
                                  pattern: /^1[3-9]\d{9}$/,
                                  message: "请输入有效的手机号码",
                                },
                              ],
                            },
                          ],
                          expression:
                            "[\n            'mobile',\n            {\n              rules: [\n                { required: true, message: '请输入手机号' },\n                { pattern: /^1[3-9]\\d{9}$/, message: '请输入有效的手机号码' }\n              ],\n            },\n          ]",
                        },
                      ],
                      attrs: {
                        maxLength: 11,
                        size: "large",
                        placeholder: "请输入手机号",
                        name: "mobile",
                      },
                    },
                    [
                      _c("a-icon", {
                        attrs: { slot: "prefix", type: "mobile" },
                        slot: "prefix",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-item",
                [
                  _c(
                    "a-input",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "smsCode",
                            {
                              rules: [
                                { required: true, message: "请输入验证码" },
                              ],
                            },
                          ],
                          expression:
                            "[\n            'smsCode',\n            {\n              rules: [{ required: true, message: '请输入验证码' }],\n            },\n          ]",
                        },
                      ],
                      attrs: {
                        size: "large",
                        placeholder: "请输入验证码",
                        name: "smsCode",
                      },
                    },
                    [
                      _c("a-icon", {
                        attrs: { slot: "prefix", type: "lock" },
                        slot: "prefix",
                      }),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            slot: "addonAfter",
                            type: "link",
                            disabled: _vm.countdown > 0,
                          },
                          on: { click: _vm.getCode },
                          slot: "addonAfter",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.countdown > 0
                                  ? _vm.countdown + "秒后重新获取"
                                  : "获取验证码"
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-item",
                [
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "100%", "margin-top": "10px" },
                      attrs: {
                        loading: _vm.btnLoading,
                        size: "large",
                        htmlType: "submit",
                        type: "primary",
                      },
                    },
                    [_vm._v(" 确定 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("Verify", {
        ref: "verify",
        attrs: {
          "captcha-type": "blockPuzzle",
          "img-size": { width: "330px", height: "180px" },
        },
        on: { success: _vm.handleSuccessVerify },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }