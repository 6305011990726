var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form",
        { staticStyle: { "max-width": "500px", margin: "40px auto 0" } },
        [
          _c(
            "a-form-item",
            {
              attrs: {
                label: _vm.$t("payment"),
                labelCol: { span: 7 },
                wrapperCol: { span: 17 },
              },
            },
            [
              _c(
                "a-select",
                { attrs: { value: "1", placeholder: "ant-design@alipay.com" } },
                [
                  _c("a-select-option", { attrs: { value: "1" } }, [
                    _vm._v("ant-design@alipay.com"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                label: _vm.$t("collection"),
                labelCol: { span: 7 },
                wrapperCol: { span: 17 },
              },
            },
            [
              _c(
                "a-input-group",
                {
                  staticStyle: {
                    display: "inline-block",
                    "vertical-align": "middle",
                  },
                  attrs: { compact: true },
                },
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "100px" },
                      attrs: { defaultValue: "alipay" },
                    },
                    [
                      _c("a-select-option", { attrs: { value: "alipay" } }, [
                        _vm._v(_vm._s(_vm.$t("alipay"))),
                      ]),
                      _c("a-select-option", { attrs: { value: "wexinpay" } }, [
                        _vm._v(_vm._s(_vm.$t("wechat"))),
                      ]),
                    ],
                    1
                  ),
                  _c("a-input", {
                    style: { width: "calc(100% - 100px)" },
                    attrs: { value: "test@example.com" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                label: _vm.$t("collectionName"),
                labelCol: { span: 7 },
                wrapperCol: { span: 17 },
              },
            },
            [_c("a-input", { attrs: { value: "Alex" } })],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                label: _vm.$t("transferAmount"),
                labelCol: { span: 7 },
                wrapperCol: { span: 17 },
              },
            },
            [_c("a-input", { attrs: { prefix: "￥", value: "5000" } })],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { wrapperCol: { span: 17, offset: 7 } } },
            [
              _c(
                "a-button",
                { attrs: { type: "primary" }, on: { click: _vm.nextStep } },
                [_vm._v(_vm._s(_vm.$t("nextStep")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }