<template>
  <div class="team-box">
    <form-index
        ref="formindex"
        :projectList="projectList"
        :formData="formData"
        :flexAction="true"
        :baiduMapBoolen="false"
        @handleSubmit="handleSubmit"
        @handleChange="handleChange"
        @handlSelect="handlSelect"
      >
        <!-- 下部分按钮插槽 -->
        <template #flexAction>
          <a-space :style="{ marginTop: '4px' }">
          <a-button icon="search" type="primary" html-type="submit"> 查询 </a-button>
          <a-space class="operator">
            <a-button @click="close"> 重置 </a-button>
          </a-space>
          <!-- <a-button icon="plus" type="primary" @click="createItem"> 新增 </a-button> -->
        </a-space>
        </template>
      </form-index>
    <standard-table
      :columns="columns"
      :dataSource="dataSource"
      row-key="id"
      :pagination="pagination"
      :loading="tableLoading"
      @change="onPageChange"
      :bordered="true"
      tableSize="middle"
    >
      <template slot="index" slot-scope="{ index }">
        <span>
          {{
            (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1
          }}
        </span>
      </template>
      <template slot="teamName" slot-scope="{ text, record }">
        <a-tooltip placement="topLeft">
            <template slot="title"
              ><span>{{ text }}</span></template
            >
            <span class="ant-btn-text ellipsis" @click="viewEditing(record)">{{
              text
            }}</span>
          </a-tooltip>
      </template>
      <div slot="phone" slot-scope="{ text }">
        <span>{{ text ? text : "/" }}</span>
      </div>
      <div slot="action" slot-scope="{ record }">
        <a-dropdown placement="bottomCenter" class="sTables">
          <div>
            <span>操作</span>
            <a-icon
              style="margin-left: 2px; font-size: 12px"
              type="caret-down"
            />
          </div>
          <a-menu :class="['avatar-menu']" slot="overlay">
            <a-menu-item @click="viewEditing(record, 'edit')">
              <a-icon type="edit" />
              <span>修改</span>
            </a-menu-item>
            <a-menu-item @click="recorDelete(record)">
              <a-icon type="delete" />
              <span>删除</span>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </standard-table>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    align: "center",
  },
  {
    title: "班组名称",
    dataIndex: "teamName",
    width: 120,
    // scopedSlots: { customRender: "teamName" },
  },
  {
    title: "所在项目",
    dataIndex: "projectName",
    width: 225,
    align: "center",
    scopedSlots: { customRender: "projectName" },
  },
  {
    title: "班组长",
    dataIndex: "teamLeader",
    align: "center",
    key: "teamLeader",
    width: 80,
  },
  {
    title: "联系电话",
    dataIndex: "phoneNumber",
    align: "center",
    width: 80,
  },
  // {
  //   title: "负责人",
  //   dataIndex: "leader",
  //   align: "center",
  //   width: 80,
  //   scopedSlots: { customRender: "phone" },
  // },
  // {
  //   title: "负责人联系电话",
  //   dataIndex: "leaderPhoneNumber",
  //   align: "center",
  //   width: 80,
  //   scopedSlots: { customRender: "phone" },
  // },
  {
    title: "工种",
    dataIndex: "jobtitle",
    align: "center",
    width: 80,
    scopedSlots: { customRender: "phone" },
  },
  {
    title: "人数",
    dataIndex: "numbers",
    align: "center",
    width: 80,
  },

  // {
  //   title: "操作",
  //   align: "center",
  //   width: 80,
  //   scopedSlots: { customRender: "action" },
  // },
];
const formData = [
  {
    label: "班组名称",
    placeholder: "请输入班组名称",
    decorator: [
      "teamName",
      { rules: [{ required: false, message: "请选择班组名称" }] },
    ],
    type: "input",
    key: "teamName",
    dataSource: [],
    col: 6,
    labelCol: 5,
    wrapperCol: 14,
  },
  {
    label: "所属项目",
    placeholder: "请输入所属项目",
    decorator: [
      "projectId",
      { rules: [{ required: false, message: "请输入所属项目" }] },
    ],
    type: "inputGroup",
    key: "projectId",
    dataSource: [],
    col: 6,
    labelCol: 5,
    wrapperCol: 14,
    display: true,
  },
  // {
  //   label: "工种",
  //   placeholder: "请选择工种",
  //   decorator: [
  //     "postId",
  //     { rules: [{ required: false, message: "请选择工种" }] },
  //   ],
  //   type: "select",
  //   key: "postId",
  //   selectlist: [],
  //   col: 6,
  //   labelCol: 4,
  //   wrapperCol: 14,
  // },
  // {
  //   label: "登记时间",
  //   placeholder: "请选择登记时间",
  //   decorator: [
  //     "startTime",
  //     { rules: [{ required: false, message: "请选择登记时间" }] },
  //   ],
  //   type: "rangePicker",
  //   key: "startTime",
  //   dataSource: [],
  //   col: 6,
  //   labelCol: 5,
  //   wrapperCol: 14,
  //   display: true,
  //   format: "yyyy-MM-DD",
  // },
  {
    type: "action",
    col: 5,
    display: true,
    key: 'action'
  },
];
import { getOfficeTeams ,getOfficeProject} from "@/services/projectManagement";
import StandardTable from "@/components/table/StandardTable";
import { getTeamDetail} from "@/services/participationUnit"
import formIndex from "@/pages/components/form/index";
export default {
  name: "SuijianClientCompanyTeams",
  components: {
    StandardTable, //表格封装
    formIndex
  },
  props: {
    officeId: {
      type: [String, Number],
      require: false,
    },
  },
  data() {
    return {
      columns, //表格数据
      dataSource: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total, range) => `共 ${total} 条`
      },
      tableLoading: false,
      prams: {
        pageNumber: 1,
        pageSize: 10,
      },
      formData: formData,
      projectList: []
    };
  },
  watch: {
    officeId(newV, oldV) {
      if (newV) {
        this.aqxjlist();
      }
    },
  },
  mounted() {
    this.aqxjlist();
  },
  // activated() {
  //   this.$nextTick(() => {
  //     this.aqxjlist();
  //   })
  // },

  methods: {
    aqxjlist(prams) {
      this.prams.officeId = this.officeId
      let obj = Object.assign({}, prams, this.prams);
      this.tableLoading = true;
      getOfficeTeams(obj).then((res) => {
        if (res.data.code === 0) {
          this.$nextTick(() => {
            console.log('????????????????',res.data.data)
            this.pagination.total = res.data.data.total;
            this.dataSource = res.data.data.list;
          })
          this.tableLoading = false;
        } else {
          this.tableLoading = false;
        }
      });
    },
    //表格 - 分页查询
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      this.prams.pageNumber = e.current;
      this.pagination.current = e.current;
      this.aqxjlist(Object.assign({}, this.prams, obj));
    },
     // 跳转到班组详情
     viewEditing(e) {
      console.log(e,'班组详情===')
      // const params = {
      //   officeId: this.officeId
      //   ? this.officeId
      //   : this.$route.query.id
      // }
      getTeamDetail(e.id).then((res) => {
        if (res.data.code === 0) {
          console.log(res.data.data,'班组详情')
          // this.$router.push({
          //   path: "../participation_Teams/details_index",
          //   query: {
          //     data: res.data.data,
          //     type: "table",
          //     pageNumber: this.prams.pageNumber,
          //   },
          // });
        }
      });
    },
     // 提交表单数据
     handleSubmit(e) {
      this.prams.pageNumber = 1
      this.pagination.current = 1;
      let obj = Object.assign({}, e, this.prams);
      // if (obj.startTime && obj.startTime.length > 0) {
      //   let arrDate = obj.startTime
      //   obj.startTime = arrDate[0]
      //   obj.endTime = arrDate[1]
      // }
      this.aqxjlist(obj);
    },
    // 重置
    close() {
      this.prams = {
        pageNumber: 1,
        pageSize: 10,
      };
      this.aqxjlist(this.prams);
      this.pagination.current = 1;
      // this.$refs["formindex"].setFieldsValue("", {}, "projectName");
      this.$refs["formindex"].resetFields();
    },
    handleChange(e) {
      this.projectArr = [];
      this.getZdata(getOfficeProject, e, "projectName",'fullName');
      // 获取当前匹配key，赋值相对于数组
      this.formData.forEach((item) => {
        if (e.item.key === item.key) {
          item.dataSource = this.projectArr;
        }
      });
    },
    // 特殊数据处理
    handlSelect({ val, keys }) {
      this.projectList.forEach((item) => {
        if (keys == "officeName" && item.id == val) {
          this.$refs["formindex"].setFieldsValue(
            item.name,
            {},
            "officeName"
          );
        }
      });
    },
    /**
     * msg 接收接口名称
     * e 数据
     * name 参数名
     * */
     getZdata(msg, e, name,title) {
      let data = {
        [name]: e.value,
        officeId:this.officeId
        ? this.officeId
        : this.$route.query.id,

      };

      msg(data).then((res) => {
        if (res.status == 200) {
          const data = res.data.data.list ? res.data.data.list : res.data.data
          data.forEach((item) => {
            this.projectArr.push({
              title: item[title],
              id: item.id,
            });
          });
          this.projectList = data;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.team-box {
  padding: 0 24px 24px 24px;
}
.ant-btn-text {
  color: #4a86e8;
  background-color: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ant-btn-text:hover {
  cursor: pointer;
  color: #76abf5;
  background-color: transparent;
  border-color: #76abf5;
}
</style>
