<template>
  <div>
    <a-card title="职工伤亡事故报表">
      <a-form-model layout="horizontal" :model="params">
        <a-row>
          <a-col :span="24">
            <a-form-model-item
              label="状态："
              :labelCol="{ span: 3 }"
              :wrapperCol="{ span: 5 }"
            >
              <span>{{statusName}}</span>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-model-item
              label="本企业职工小计（人）："
              :labelCol="{ span: 3 }"
              :wrapperCol="{ span: 5 }"
            >
              <a-input
                placeholder="请输入本企业职工小计（人）"
                v-model="params.employeeNums"
                allowClear
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item
              label="本企业临时工（人）："
              :labelCol="{ span: 3 }"
              :wrapperCol="{ span: 5 }"
            >
              <a-input
                placeholder="请输入本企业临时工（人）"
                v-model="params.partTimeNums"
                allowClear
              /> </a-form-model-item
          ></a-col>
        </a-row>
        <a-form-item :wrapper-col="{ offset: 12 }">
          <a-button @click="close">
            关闭
          </a-button>
          <a-button class="ml-3" type="primary" @click="submit">
            提交
          </a-button>
        </a-form-item>
      </a-form-model>
    </a-card>
  </div>
</template>

<script>
import {
  getCasualtyAccidentInfoDetail, updateCasualtyAccidentInfo
} from "@/services/safeMonthlyReport";
export default {
  inject: ["fatherMethod"],
  data() {
    return {
      params: {
        employeeNums: 0,
        partTimeNums: 0,
        id: '',
        status: null
      },
    };
  },
  
  activated() {
    this.params.id = this.$route.query.id
    this.handleQuery()
  },
  computed: {
    statusName() {
      switch (this.params.status) {
        case 0:
          return '待提交'
        case 1:
          return '已提交'
        case 2:
          return '退回'
        case 3:
          return '已上报'
        default: 
          return ''
      }
    }
  },
  methods: {
    handleQuery() {
      getCasualtyAccidentInfoDetail(this.params.id).then((res) => {
        let d = res.data;
        if (d.code === 0) {
          this.params = d.data
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    submit() {
      updateCasualtyAccidentInfo(this.params).then(res => {
        let d = res.data;
        if (d.code === 0) {
          this.$message.success('修改成功')
          this.handleQuery()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    close() {
      let path = this.$route.path
      this.fatherMethod(`${path}`)
    },
  },
};
</script>

<style lang="less" scoped></style>
