var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-card",
        [
          _c(
            "div",
            { staticClass: "flex a-center" },
            [
              _c("div", { staticClass: "flex a-center j-center" }, [
                _c("img", {
                  staticStyle: { width: "21px", height: "21px" },
                  attrs: {
                    src: "https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20241120/1732091350443.png?Expires=2047451350&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=59AEqlAw%2B%2BGyQ%2F%2B4QYdFWxJZ7rs%3D",
                    alt: "",
                  },
                }),
                _c(
                  "div",
                  {
                    staticClass: "font-bold ml-1",
                    staticStyle: { width: "100px" },
                  },
                  [_vm._v("预警处理情况")]
                ),
              ]),
              _vm.warnList.length > 0
                ? _c(
                    "a-carousel",
                    {
                      staticClass: "flex-1",
                      staticStyle: { width: "80%" },
                      attrs: {
                        autoplay: "",
                        dots: false,
                        autoplaySpeed: 5000,
                        speed: 2000,
                        vertical: "",
                      },
                    },
                    _vm._l(_vm.warnList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "ml-1 flex a-center warning-tips",
                        },
                        [
                          _c("img", {
                            staticStyle: { width: "15px", height: "15px" },
                            attrs: {
                              src: "https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20241120/1732091634700.png?Expires=2047451634&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=GO6Aa%2BqnoqR2MWZGQ1vA2U%2BiDTY%3D",
                              alt: "",
                            },
                          }),
                          _c("span", { staticClass: "ml-1 font-12" }, [
                            _vm._v(_vm._s(item)),
                          ]),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("a-divider"),
          _c("div", [
            _c("div", { staticClass: "flex j-between p-2" }, [
              _c("div", { staticClass: "flex" }, [
                _c(
                  "div",
                  {
                    staticClass: "warning-icon-1",
                    staticStyle: { background: "#F1F8FF" },
                  },
                  [
                    _c("img", {
                      staticStyle: { width: "25px", height: "27px" },
                      attrs: {
                        src: "https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20241120/1732092019676.png?Expires=2047452019&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=beAOQMxoL0nYQi%2F7QknGDWp0ZsQ%3D",
                        alt: "",
                      },
                    }),
                  ]
                ),
                _c("div", { staticClass: "flex flex-column ml-1" }, [
                  _c("span", { staticClass: "font-18 font-bold" }, [
                    _vm._v(_vm._s(_vm.total)),
                  ]),
                  _c("span", { staticClass: "black-three" }, [
                    _vm._v("预警总数（个）"),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "flex" }, [
                _c(
                  "div",
                  {
                    staticClass: "warning-icon-1 relative",
                    staticStyle: { background: "#FFF2F6" },
                  },
                  [
                    _c("img", {
                      staticStyle: { width: "25px", height: "27px" },
                      attrs: {
                        src: "https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20241120/1732092456398.png?Expires=2047452456&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=5zbcHoarR4KSx1pagPnqYGn07GM%3D",
                        alt: "",
                      },
                    }),
                  ]
                ),
                _c("div", { staticClass: "flex flex-column ml-1" }, [
                  _c("span", { staticClass: "font-18 font-bold" }, [
                    _vm._v(_vm._s(_vm.redCount)),
                  ]),
                  _c("span", { staticClass: "black-three" }, [
                    _vm._v("红色预警数（个）"),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "flex" }, [
                _c(
                  "div",
                  {
                    staticClass: "warning-icon-1 relative",
                    staticStyle: { background: "#FFF8EB" },
                  },
                  [
                    _c("img", {
                      staticStyle: { width: "25px", height: "27px" },
                      attrs: {
                        src: "https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20241120/1732092483441.png?Expires=2047452483&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=IOky7cewCZHOWW85SqqERKGtAjw%3D",
                        alt: "",
                      },
                    }),
                  ]
                ),
                _c("div", { staticClass: "flex flex-column ml-1" }, [
                  _c("span", { staticClass: "font-18 font-bold" }, [
                    _vm._v(_vm._s(_vm.orangeCount)),
                  ]),
                  _c("span", { staticClass: "black-three" }, [
                    _vm._v("橙色预警数（个）"),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "flex" }, [
                _c(
                  "div",
                  {
                    staticClass: "warning-icon-1",
                    staticStyle: { background: "#FFFBE7" },
                  },
                  [
                    _c("img", {
                      staticStyle: { width: "25px", height: "27px" },
                      attrs: {
                        src: "https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20241120/1732092510821.png?Expires=2047452510&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=c5a0Dtozo35nNEpywABGQonQucI%3D",
                        alt: "",
                      },
                    }),
                  ]
                ),
                _c("div", { staticClass: "flex flex-column ml-1" }, [
                  _c("span", { staticClass: "font-18 font-bold" }, [
                    _vm._v(_vm._s(_vm.yellowCount)),
                  ]),
                  _c("span", { staticClass: "black-three" }, [
                    _vm._v("黄色预警数（个）"),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "flex a-center font-12 ml-2" }, [
              _vm._v(" 限期预警说明："),
              _c("div", {
                staticClass: "dot-icon",
                staticStyle: { background: "#EF1A1A" },
              }),
              _vm._v("逾期5天以上"),
              _c("div", {
                staticClass: "dot-icon ml-1",
                staticStyle: { background: "#F7A22C" },
              }),
              _vm._v("逾期5天内"),
              _c("div", {
                staticClass: "dot-icon ml-1",
                staticStyle: { background: "#FFDE2E" },
              }),
              _vm._v("限期3天内 "),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }