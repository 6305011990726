<template>
  <div v-show="$route.meta.showFather" class="a_Approval">
    <div class="plug-Overview">待审核列表</div>
    <a-divider />
    <a-form-model :model="params" class="clearfix" @submit="handleSubmit">
      <!-- <a-form-model-item label="日期">
        <a-range-picker style="width: 220px" format="YYYY-MM-DD" @change="onChange" v-model="dateGroup" :disabled="tabKey == 1"/>
      </a-form-model-item> -->
      <a-col :span="5">
        <a-form-model-item label="项目名称" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
          <a-input placeholder="请输入项目名称" v-model="params.projectName" allowClear/>
        </a-form-model-item>
      </a-col>
      <a-col :span="4">
        <a-space class="btnBox">
          <a-button type="primary" html-type="submit"> 查询 </a-button>
          <a-button @click="resetQuery"> 重置 </a-button>
        </a-space>
      </a-col>
    </a-form-model>
    <div class="a_a_footer">
      <standard-table
        class=""
        :columns="columns"
        :dataSource="tableData"
        rowKey="id"
        :pagination="pagination"
        :loading="tableLoading"
        @change="handleChangePage"
        :isAlert="false"
        :scroll="{ x: 1300 }"
        :bordered="true"
      >
        <template slot="index" slot-scope="{ index }">
          <span>{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}</span>
        </template>
        <template slot="dateGroup" slot-scope="{ record }">
          <div>
            {{ useRender.renderDate(record.workStartDate) }} - {{ useRender.renderDate(record.workEndDate) }}
          </div>
        </template>
        <template slot="dangerLevel">
          <div class="circle"></div>
        </template>
        <template slot="approveStatus" slot-scope="{ text }">
          <div>
            {{ text == 0 ? '已提交' : text == 1 ? '已审核' : '已退回' }}
          </div>
        </template>
        <template slot="workContentCode" slot-scope="{ text }">
          <div>
            {{ getWorkContent(text) }}
          </div>
        </template>
        <template slot="action" slot-scope="{ record }">
          <div v-if="isBranchOffice && record.approveStatus == 0">
            <a @click="() => handleOperate(record, 1)" > 通过 </a>
            <a class="ml-2" @click="() => handleOperate(record, 2)" style="color: FA367A;"> 退回 </a>
          </div>
        </template>
      </standard-table>
    </div>
    <Export-loading
      :cPercent="cPercent"
      :isExporting="isExporting"
      :loadingText="loadingText"
    ></Export-loading>
  </div>
</template>

<script>
import {
  safeLevelList, safeLevelInsert, safeContentList, safeLevelDel, safeLevelUpdate, approveSafeLevel, submitNoDangerWeekly, safeLevelDepartPage, safeLevelStatic, cancelNoDangerWeekly, safeLevelsExport
} from "@/services/safeRisk";
import { getFacilityInfoList } from "@/services/equipmentLedger";
import { request } from "@/utils/request";
import { QUERY_OFFICE_BY_USER } from "@/services/api";
import { mapGetters } from "vuex";
import StandardTable from "@/components/table/StandardTable";
import moment from "moment";
import ExportLoading from "@/components/ExportLoading/ExportLoading"; // progress组件
import { exportProgress } from "@/pages/aqjyzl/export"; // 导出方法
import { useRender } from '@/hooks/useRender.js'

const columns = [
  {
    title: "序号",
    align: "center",
    scopedSlots: { customRender: "index" },
    width: 65,
  },
  {
    title: "工程管理部",
    align: "center",
    dataIndex: "gcglbName",
    width: 120,
  },
  {
    title: "项目名称",
    dataIndex: "projectName",
    ellipsis: true,
    width: 280,
    // align: "center",
  },
  {
    title: "作业时间",
    dataIndex: "dateGroup",
    align: "center",
    width: 120,
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "工作内容",
    dataIndex: "workContentCode",
    align: "center",
    width: 180,
    scopedSlots: { customRender: "workContentCode" },
  },
  {
    title: "设备编号",
    dataIndex: "selfEquityNum",
    align: "center",
    width: 180,
    scopedSlots: { customRender: "selfEquityNum" },
  },
  {
    title: "风险等级",
    dataIndex: "dangerLevel",
    align: "center",
    width: 80,
    scopedSlots: { customRender: "dangerLevel" },
  },
  {
    title: "提交时间",
    dataIndex: "createTime",
    align: "center",
    width: 100,
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "审核状态",
    dataIndex: "approveStatus",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "approveStatus" },
  },
  {
    title: "审核时间",
    dataIndex: "approveTime",
    align: "center",
    width: 100,
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "操作",
    align: "center",
    dataIndex: "action",
    // fixed: "right",
    width: 110,
    scopedSlots: { customRender: "action" },
  },
];

export default {
  mixins: [exportProgress], //导出方法注入
  components: {
    ExportLoading,
    StandardTable
  },
  data() {
    return {
      addNewVisible: false,
      confirmLoading: false,
      idEdit: false,
      dateGroup: [],
      uploadRecordDateGroup: [moment(new Date()).isoWeekday(1).format('YYYY-MM-DD'), moment(new Date()).isoWeekday(7).format('YYYY-MM-DD')],
      workDateGroup: [],
      params: {
        approveStatus: '0',
        gcglbId: '',
        projectName: '',
        pageNumber: 1,
        pageSize: 10,
        isNow: 0
      },
      workContentList: [],
      pagination: {
        //分页数据
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total, range) => `共 ${total} 条`,
      },
      gcglbList: [],
      total: 0,
      columns: columns,
      tableData: [],
      tableLoading: false, //table加载中
      staticObj: {},
      showSelfEquity: false,
      selfEquityList: [],
      loadingText: "数据加载中...",
      cPercent: 0,
      isExporting: false,
      useRender
    };
  },
  created() {
    this.getgcglbList(QUERY_OFFICE_BY_USER);
    this.getSafeContentList()
    
  },
  activated() {
    this.handleQuery();
  },
  computed: {
    ...mapGetters("account", ["user"]),
    isProject() { //项目
      return this.user.loginType == 0
    },
    isBranchOffice() { //分公司
      return this.user.isGcglb == 1 && this.user.loginType == 1
    },
    isJSOffice() { //机施
      return this.user.isGcglb == 0 && this.user.loginType == 1
    },
    showTips() {
      let isShow = false
      for (const key in this.staticObj) {
        if (Object.hasOwnProperty.call(this.staticObj, key)) {
          const element = this.staticObj[key];
          isShow = element.some(el => el.nums > 0)
          if (isShow) break
        }
      }
      return isShow
    }
  },
  methods: {
    moment,
    doDowload() {
      let obj = this.params;
      this.loadingText = "正在导出EXCEL文件，请稍候 ····";
      this.isExporting = true;
      this.cPercent = 0;
      let fileName = "项目安全风险管控台账.xlsx";
      this.exportFunNomal2(
        safeLevelsExport,
        obj,
        fileName,
        "application/vnd.ms-excel"
      );
    },
    handleCodeChange(e) {
      if (e == 'holting' || e == 'out_model') {
        this.showSelfEquity = false
        this.params.selfEquityNum = ''
      } else {
        this.showSelfEquity = true
        if (e == 'tower_install') {
          this.getFacilityInfoList(1, 3)
        } else if (e == 'tower_split' || e == 'tower_wall') {
          this.getFacilityInfoList(1, 0)
        } else if (e == 'updown_install') {
          this.getFacilityInfoList(2, 3)
        } else if (e == 'updown_split' || e == 'updown_wall') {
          this.getFacilityInfoList(2, 0)
        }
      }
    },
    showStatic(obj) {
      if (obj) {
        for (const key in obj) {
          if (Object.hasOwnProperty.call(obj, key)) {
            const el = obj[key];
            if (el.some(item => item.nums > 0)) {
              return true
            }
          }
        }
      } else {
        return false
      }
    },
    handleEdit(row) {
      this.addNewParams.workStartDate = row.workStartDate
      this.addNewParams.workEndDate = row.workEndDate
      this.addNewParams.workContentCode = row.workContentCode
      this.addNewParams.projectId = row.projectId
      this.addNewParams.selfEquityNum = row.selfEquityNum
      this.addNewParams.id = row.id
      this.workDateGroup = [row.workStartDate, row.workEndDate]
      this.addNewVisible = true
      this.idEdit = true
    },
    getSafeLevelStatic() {
      safeLevelStatic(this.params).then(res => {
        let d = res.data;
        if (d.code === 0) {
          this.staticObj = d.data
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    getSafeContentList() {
      safeContentList({pageSize: 9999, pageNumber: 1}).then(res => {
        if (res.data.code === 0) {
          const arr = [];
          res.data.data.list.forEach((item) => {
            arr.push({
              name: item.workContent,
              value: item.workContentCode,
            });
          });
          this.workContentList = arr;
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    getFacilityInfoList(facilityType, status) {
      getFacilityInfoList({pageSize: 9999, pageNumber: 1, isSplit: 0, status, facilityType}).then(res => {
        if (res.data.code === 0) {
          const arr = [];
          if (res.data.data.list.length > 0) {
            res.data.data.list.forEach((item) => {
              arr.push({
                name: `${item.selfNum}(${item.equityNum})`,
                value: `${item.selfNum}(${item.equityNum})`,
              });
            });
          } else {
            this.$confirm({
              title: '本项目暂无使用中大型设备',
              cancelButtonProps: { style: { display: 'none' } }, // 隐藏取消按钮
              onOk() {}
            })
          }
          this.selfEquityList = arr;
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    uploadRecord() {
      this.uploadRecordVisible = true
      this.uploadRecordParams.startDate =  this.uploadRecordDateGroup[0]
      this.uploadRecordParams.endDate =  this.uploadRecordDateGroup[1]
      this.getSafeLevelDepartPage()
    },
    getSafeLevelDepartPage() {
      safeLevelDepartPage(this.uploadRecordParams).then(res => {
        if (res.data.code === 0) {
          this.uploadRecordTableData = res.data.data.list
          this.uploadRecordPagination.total = res.data.data.total;
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    handleDel(record) {
      const _that = this
      this.$confirm({
        title: '是否确定删除？',
        cancelText: '否',
        okText: '是',
        onOk: () => {
          safeLevelDel(record.id).then(res => {
            if (res.data.code === 0) {
              _that.$message.success('删除成功')
              this.handleQuery();
            } else {
              _that.$message.error(res.data.msg)
            }
          })
        },
      })
    },
    handleSubmit(e) {
      e.preventDefault();
      this.params.pageNumber = 1
      this.pagination.current = 1
      this.tableData = []
      this.handleQuery();
    },
    handleQuery(e) {
      this.tableLoading = true
      safeLevelList(this.params).then((res) => {
        this.tableLoading = false
        let d = res.data;
        if (d.code === 0) {
          this.tableData = d.data.list
          this.pagination.total = d.data.total;
          this.total = d.data.total;
          if(this.isJSOffice) {
            this.getSafeLevelStatic()
          }
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    handleChangePage(e) {
      this.params.pageNumber = e.current;
      this.pagination.current = e.current;
      this.handleQuery();
    },
    // 获取工程管理部数据
    getgcglbList(path) {
      request(path, "get").then((res) => {
        let data = res.data.data.filter(item => item.id != "3da0f5533f87425c9a9e8a11a7923300")
        const arr = [];
        data.forEach((item) => {
          arr.push({
            name: item.master,
            value: item.id,
          });
        });
        this.gcglbList = arr;
      });
    },
    resetQuery() {
      this.pagination.pageNumber = 1
      this.params = {
        approveStatus: '',
        endDate: '',
        gcglbId: '',
        projectName: '',
        startDate: '',
        pageNumber: 1,
        pageSize: 10,
      };
      this.handleQuery();
    },
    getWorkContent(text) {
      return this.workContentList.find(el => el.value == text).name
    },
    handleOperate(record, type) {
      let title = ''
      if (type == 1) {
        title = '是否确定通过？'
      } else {
        title = '是否确定退回？'
      }
      const _that = this
      this.$confirm({
        title,
        cancelText: '否',
        okText: '是',
        onOk: () => {
          approveSafeLevel({id: record.id, approveStatus: type}).then(res => {
            if (res.data.code === 0) {
              _that.$message.success('操作成功')
              this.handleQuery();
            } else {
              _that.$message.error(res.data.msg)
            }
          })
        },
      })
    },
    handleOk(e) {
      this.confirmLoading = true;
      this.addNewParams.projectId = this.user.projectId
      this.addNewParams.approveStatus = 0
      if (!this.addNewParams.workStartDate || !this.addNewParams.workEndDate) {
        this.$message.error('请选择作业时间')
        this.confirmLoading = false;
        return
      }
      // const date1 = moment(this.addNewParams.workStartDate);
      // const date2 = moment(this.addNewParams.workEndDate);
      // const diff = date2.diff(date1, 'days');
      // if (diff > 7) {
      //   this.$message.error('请选择七天之内作业时间')
      //   this.confirmLoading = false;
      //   return
      // }
      if (!this.addNewParams.workContentCode) {
        this.$message.error('请选择工作内容')
        this.confirmLoading = false;
        return
      }
      if ((this.addNewParams.workContentCode != 'holting' && this.addNewParams.workContentCode != 'out_model') && !this.addNewParams.selfEquityNum) {
        this.$message.error('请选择设备编号')
        this.confirmLoading = false;
        return
      }
      if (this.idEdit) {
        safeLevelUpdate(this.addNewParams).then(res => {
          this.addNewVisible = false;
          this.confirmLoading = false;
          this.resetParams()
          if (res.data.code === 0) {
            this.$message.success('新增成功！')
            this.handleQuery()
          } else {
            this.$message.error(res.data.msg)
          }
        })
      } else {
        safeLevelInsert(this.addNewParams).then(res => {
          this.addNewVisible = false;
          this.confirmLoading = false;
          this.resetParams()
          if (res.data.code === 0) {
            this.$message.success('新增成功！')
            this.handleQuery()
          } else {
            this.$message.error(res.data.msg)
          }
        })
      }
    },
    resetParams() {
      this.addNewParams.workStartDate = ''
      this.addNewParams.workEndDate = ''
      this.addNewParams.workContentCode = ''
      this.addNewParams.selfEquityNum = ''
      this.workDateGroup = []
    },
  },
};
</script>

<style lang="less" scoped>
@import "@/theme/common.less";
.a_Approval {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
}
.plug-Overview {
  font-size: 16px;
  color: #101010;
  font-weight: bold;
}
.query-form {
  display: flex;
  // justify-content: space-between;
}
.a_a_footer {
  background: #ffffff;
  .ant-btn {
    border-color: #d9d9d9 !important;
  }
  .operator {
    margin-right: 8px;

  }
}
.circle {
  height:20px;/*与width设置一致*/
  width:20px;
  background:red;
  border-radius:50px;
  margin:0 auto;
}
</style>
