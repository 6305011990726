<template>
  <a-card :bordered="false">
    <div class="card-container">
      <a-tabs v-model="activeTab" @change="handeleTab">
        <a-tab-pane key="1">
          <template #tab>
            <div class="post-status">
              <a-icon
                type="check-circle"
                theme="twoTone"
                two-tone-color="#52c41a"
                v-if="false"
              />
              企业认证
              <div v-if="isActive" class="status" :class="{ active: isActive }">
                {{ isActive ? "已完成" : "处理中" }}
              </div>
            </div>
          </template>
          <a-form-model
            style="margin-top: 40px"
            ref="ruleForm"
            :model="form"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-model-item label="统一社会信用代码" prop="officeCode">
              <a-input v-model="form.officeCode" />
            </a-form-model-item>
            <a-form-model-item label="企业名称" prop="officeName">
              <a-input v-model="form.officeName" />
            </a-form-model-item>
          </a-form-model>
          <div class="flex-container" style="flex-direction: column">
            <a-button type="primary" @click="onSubmit"> 申请认证 </a-button>
            <div class="mt-20">点击下方链接完成认证</div>
            <!-- <a href="https://www.baidu.com/" target="_blank" class="mt-2"
              >https://www.baidu.com/</a
            > -->
          </div>
        </a-tab-pane>
        <a-tab-pane key="2"
          ><template #tab>
            <div class="post-status">
              <a-icon
                type="check-circle"
                theme="twoTone"
                two-tone-color="#52c41a"
              />
              公章签署协议
              <div class="status" v-if="!authStatus.isAuto">未完成</div>
            </div>
          </template>
          <div class="flex-container" style="flex-direction: column">
            <div>
              <a-upload
                class="ts-upload-box"
                :accept="accept"
                :customRequest="resourceUpload"
                :beforeUpload="beforeUpload"
                list-type="picture-card"
                :show-upload-list="false"
              >
                <template>
                  <div v-if="imageUrl">
                    <img :src="imageUrl" alt="avatar" class="ts-img" />
                    <a-button v-if="imageUrl" type="link" class="ts-button"
                      ><a-icon type="reload" />重新上传</a-button
                    >
                  </div>

                  <div v-else>
                    <a-icon type="plus" />
                  </div>
                </template>
              </a-upload>
            </div>
            <span v-if="!imageUrl">上传公章</span>
            <p v-html="text" style="color: #999999" class="mt-20"></p>
            <a-button type="primary" @click="onSubmit">
              申请公章签署协议
            </a-button>
          </div>
        </a-tab-pane>
        <a-tab-pane key="3"
          ><template #tab>
            <div class="post-status">
              <a-icon
                type="check-circle"
                theme="twoTone"
                :two-tone-color="'#52c41a'"
              />
              合同模板
              <div class="status" v-if="!authStatus.yyzzPath">未上传</div>
            </div>
          </template>
          <div class="flex-container" style="flex-direction: column">
            <div>
              <a-upload
                class="ts-upload-box"
                :accept="accept"
                :customRequest="resourceUpload"
                :beforeUpload="beforeUploadDocx"
                list-type="picture-card"
                :show-upload-list="false"
              >
                <template>
                  <div v-if="imageUrl">
                    <img :src="imageUrl" alt="avatar" class="ts-img" />
                    <a-button v-if="imageUrl" type="link" class="ts-button"
                      ><a-icon type="reload" />重新上传</a-button
                    >
                  </div>

                  <div v-else>
                    <a-icon type="plus" />
                  </div>
                </template>
              </a-upload>
            </div>
            <span v-if="!imageUrl">上传合同</span>
            <p style="color: #999999" class="mt-20">合同模板可以包含以下内容：工人劳动合同、工人入场须知等</p>
            <a-button type="primary" @click="onSubmit">
              上传合同模板
            </a-button>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </a-card>
</template>

<script>
import { request } from "@/utils/request";
import { ATTACHMENT_UPLOAD } from "@/services/api";
import {
  getTreeOffice,
} from "@/services/projectManagement";
import {
  applyEssOffice,
  getOfficeAuthStatus
} from "@/services/ess";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      activeTab: "1",
      isActive: false,
      labelCol: { span: 9 },
      wrapperCol: { span: 6 },
      accept: ".png,.PNG,.jpg,.JPG,.pdf", //图片上传限制
      acceptDocx:".docx", // 只能上传docx格式文件
      defaultFileList: [],
      imageUrl: "",
      form: {
        officeCode: "",
        officeName: "",
      },
      authStatus: {
        isSuccess: 0, // 是否实名成功0：否 1：是
        isAuto: false, // 是否授权成功
        yyzzPath: false //  合同模板是否已经上传
      },
      officeData: {},
      rules: {
        officeCode: [
          {
            required: true,
            message: "请填写统一社会信用代码",
            trigger: "blur",
          },
        ],
        officeName: [
          {
            required: true,
            message: "请填写企业名称",
            trigger: "blur",
          },
        ],
      },
      text: `上传公章要求：</br>
（1）大小不超过1M；</br>
（2）格式为IPG、JPEG或PNG，需设置为透明底；</br>
（3）印章紧密贴紧四周，尽量避免留白；</br>
（4）图片尺寸：166*166像素；</br>
（5）保证印章完整、清晰。`,
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },

  created() {
    this.getTreeOffice() // 获取公司详情
    // this.getOfficeAuthStatus() // 公司实名状态
  },

  methods: {
    // 点击tab页变化
    handeleTab(key) {
      console.log(key,'key')
      // this.getOfficeAuthStatus()
    },
    // 获取实名详情状态
    getOfficeAuthStatus() {
      getOfficeAuthStatus(this.user.officeId).then(res => {
        console.log(res,'公司实名详情状态')
      })
    },
    // 获取公司详情
    getTreeOffice() {
      getTreeOffice({ownerOfficeId: this.user.officeId}).then(res => {
        console.log(res.data.data[0],'获取公司详情')
        this.officeData = res.data.data[0]
        this.form = {
          officeCode: res.data.data[0].code,
          officeName:  res.data.data[0].name
        }
      })
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          console.log('企业认证信息',this.form)
          console.log(this.officeData)
          const params = {
            ...this.form,
            officeId: this.officeData.id,
            customerId: this.user.id
          }
          // applyEssOffice(params).then(res => {
          //   console.log(res,'申请开通法大大电子签')
          // })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 图片上传限制处理
    beforeUpload(file) {
      const accept = "." + file.name.substring(file.name.lastIndexOf(".") + 1);
      if (this.accept.split(",").indexOf(accept) == -1) {
        this.$message.error("只能上传图片或者PDF文件");
        return false
      }
    },
    // 图片上传限制处理
    beforeUploadDocx(file) {
      const isDocx = file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      if (!isDocx) {
        this.$message.error('只能上传 DOCX 格式的文件!');
        return false;
      }
      return true;
    },
    // 上传资源
    resourceUpload(value) {
      const formData = new FormData();
      formData.append("file", value.file);
      // 图片上传
      this.attachmentUpload(formData, value);
    },
    /*
     * 图片请求接口
     * type 上传组件的类型
     */
    attachmentUpload(formData, value, type) {
      // ATTACHMENT_UPLOAD图片上传接口
      request(ATTACHMENT_UPLOAD, "post", formData).then((res) => {
        if (res.data.code === 0) {
          const fileObj = {
            uid: res.data.data.id, //图片id
            name: res.data.data.name, //图片名称
            url: res.data.data.path, //图片地址
            key: value.data.key, //获取对应的上传key名 ，后续移除图片用
            formData, //源文件上传流
            type: value.data.type,
          };
          // this.defaultFileList.push(fileObj);
          this.imageUrl = fileObj.url;
          this.$message.success("上传成功");
        } else {
          this.$message.warning(res.message);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.card-container {
  // background: #EDF1FA;
  // text-align: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 24px;
  min-height: 555px;
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
  /deep/ .ant-tabs-ink-bar {
    //background-color: none;
  }
  .ant-tabs-nav .ant-tabs-tab .anticon {
    margin-right: 0;
  }
  /deep/ .ant-tabs-nav .ant-tabs-tab {
    background: #ebebeb;
    padding: 35px 130px;
    margin: 0;
    color: #999999;
    font-size: 16px;
    font-weight: 400;
  }
  /deep/ .ant-tabs-nav .ant-tabs-tab-active {
    background: #2c3953;
    color: #fff;
  }
  .flex-container {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    .ts-img {
      width: 120px;
      height: 120px;
    }
  }

  .post-status {
    position: relative;
    .status {
      position: absolute;
      top: -10px;
      right: -56px;
      width: 56px;
      height: 20px;
      background: #999999;
      border-radius: 10px 10px 10px 0px;
      color: #fff;
      font-size: 14px;
      text-align: center;
    }
    .active {
      background: rgb(0, 206, 134);
    }
  }
}
.mt-20 {
  margin-top: 20px;
}

.ts-upload-box {
  position: relative;
  .ts-button {
    position: absolute;
    bottom: -45px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
