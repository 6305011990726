var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-table",
    { attrs: { "data-source": _vm.apiSource, pagination: false } },
    [
      _vm.title
        ? _c(
            "h2",
            {
              staticStyle: { margin: "0 16px 0" },
              attrs: { slot: "title" },
              slot: "title",
            },
            [_vm._v(_vm._s(_vm.title))]
          )
        : _vm._e(),
      _c("a-table-column", {
        attrs: { width: "20%", "data-index": "param", title: "参数" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (text) {
              return _c("div", { domProps: { innerHTML: _vm._s(text) } })
            },
          },
        ]),
      }),
      _c("a-table-column", {
        attrs: { width: "50%", "data-index": "desc", title: "说明" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (text) {
              return _c("div", { domProps: { innerHTML: _vm._s(text) } })
            },
          },
        ]),
      }),
      _vm.isApi
        ? _c("a-table-column", {
            attrs: { width: "15%", "data-index": "type", title: "类型" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (text) {
                    return _c("div", { domProps: { innerHTML: _vm._s(text) } })
                  },
                },
              ],
              null,
              false,
              102549752
            ),
          })
        : _vm._e(),
      _vm.isApi
        ? _c("a-table-column", {
            attrs: { width: "15%", "data-index": "default", title: "默认值" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (text) {
                    return _c("div", { domProps: { innerHTML: _vm._s(text) } })
                  },
                },
              ],
              null,
              false,
              102549752
            ),
          })
        : _vm._e(),
      !_vm.isApi
        ? _c("a-table-column", {
            attrs: {
              width: "30%",
              "data-index": "callback",
              title: "回调函数",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (text) {
                    return _c("div", { domProps: { innerHTML: _vm._s(text) } })
                  },
                },
              ],
              null,
              false,
              102549752
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }