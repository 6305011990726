<template>
  <div>
    <a-form-model layout="inline" :model="params" class="mb-5 query-form" @submit="handleSubmit">
      <a-form-model-item label="分公司">
        <a-select
          v-model="params.gcglbId"
          allowClear
          placeholder="请选择"
          style="width: 150px"
        >
          <a-select-option
            v-for="item in gcglbList"
            :key="item.value"
            :value="item.value"
            :title="item.name"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="日期" >
        <a-range-picker style="width: 220px" format="YYYY-MM-DD" @change="onChange" v-model="dateGroup"/>
      </a-form-model-item>
      <a-form-model-item label="项目名称">
        <a-input style="width: 200px" placeholder="请输入项目名称" v-model="params.projectName" allowClear/>
      </a-form-model-item>
      <a-form-model-item label="巡检状态">
        <a-select placeholder="请选择巡检状态" allowClear style="width: 200px" v-model="params.status">
          <a-select-option v-for="items of selectlist" :key="items.name" :value="items.value" :title="items.name">
              {{ items.name }}
          </a-select-option>
        </a-select>
        <!-- <a-input style="width: 220px" placeholder="请选择巡检状态" v-model="params.status" allowClear/> -->
      </a-form-model-item>
      <a-form-model-item label="检查日期排序">
        <a-select placeholder="请选择排序" allowClear style="width: 110px" v-model="params.sortType">
          <a-select-option v-for="items of sortlist" :key="items.name" :value="items.value" :title="items.name">
              {{ items.name }}
          </a-select-option>
        </a-select>
        <!-- <a-input style="width: 220px" placeholder="请选择巡检状态" v-model="params.status" allowClear/> -->
      </a-form-model-item>
      <a-form-model-item>
        <a-space>
          <a-button type="primary" html-type="submit"> 查询 </a-button>
          <a-button @click="resetQuery"> 重置 </a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
    <div class="a_a_footer">
      <div>
        <a-space class="operator">
          <a-button type="primary" @click="addNewPlan">新增</a-button>
        </a-space>
        <a-space class="operator">
          <a-button class="mr-9"  @click="doDowload" v-if="user.isGcglb == 0 && user.loginType == 1"> 导出 </a-button>
        </a-space>
      </div>
      <standard-table
        class=""
        :columns="columns"
        :dataSource="tableData"
        rowKey="id"
        :pagination="pagination"
        :loading="tableLoading"
        @change="handleChangePage"
        :isAlert="false"
        :scroll="{ x: 1300 }"
        :bordered="true"
      >
        <template slot="index" slot-scope="{ index }">
          <span>{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}</span>
        </template>
        <template slot="proInfo" slot-scope="{ record }">
          <div>{{ `${record.xmjl}/${record.xmjlPhone}` }}</div>
          <a-tooltip placement='topLeft'>
            <template slot='title'>
              {{ record.projAddress }}
            </template>
            <div class='ellipsis-2'>{{ record.projAddress }}</div>
          </a-tooltip>
        </template>
        <template slot="inspectonDate" slot-scope="{ record }">
          <div>{{ record.checkDate }}</div>
          <div>{{ `${record.week}` }}</div>
        </template>
        <template slot="problems" slot-scope="{ index, record }">
          <div v-for="(problem, i) in record.problems" :key="i">{{ problem }}</div>
        </template>
        <div slot="action" slot-scope="{ text, record }">
          <a
            @click="() => handleEdit(record)"
          >
            查看
          </a>
          <a
            class="ml-2"
            @click="() => handleDel(record)"
            style="color: red;"
          >
            删除
          </a>
        </div>
      </standard-table>
    </div>
    <Export-loading
      :cPercent="cPercent"
      :isExporting="isExporting"
      :loadingText="loadingText"
    ></Export-loading>
  </div>
</template>

<script>
import {
  inspectionPlanList,
  delInspectionPlan,
  inspectionPlanExport,
} from "@/services/safeMonthlyReport";
import { request } from "@/utils/request";
import { QUERY_OFFICE_BY_USER, AQXJ_ZXJC } from "@/services/api";
import ExportLoading from "@/components/ExportLoading/ExportLoading"; // progress组件
import { exportProgress } from "@/pages/aqjyzl/export"; // 导出方法
import { mapGetters } from "vuex";
import StandardTable from "@/components/table/StandardTable";
import moment from "moment";
import { useRender } from '@/hooks/useRender.js'

const columns = [
  {
    title: "序号",
    align: "center",
    scopedSlots: { customRender: "index" },
    width: 65,
  },
  {
    title: "公司",
    align: "center",
    dataIndex: "parentOffice",
    width: 120,
  },
  {
    title: "分公司",
    align: "center",
    dataIndex: "gcglbName",
    width: 120,
  },
  {
    title: "项目名称",
    dataIndex: "projectName",
    width: 280,
    ellipsis: true,
    tooltip: true
  },
  {
    title: "联系人/电话/地址",
    dataIndex: "proInfo",
    // align: "center",
    width: 240,
    scopedSlots: { customRender: "proInfo" },
  },
  {
    title: "检查日期",
    dataIndex: "inspectonDate",
    align: "center",
    width: 120,
    scopedSlots: { customRender: "inspectonDate" }
  },
  {
    title: "检查时间",
    dataIndex: "checkDateTime",
    align: "center",
    width: 65,
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "检查类别",
    dataIndex: "checkType",
    align: "center",
    width: 120,
  },
  {
    title: "巡检状态",
    dataIndex: "status",
    align: "center",
    width: 120,
  },
  {
    title: "备注",
    dataIndex: "cancelReason",
    align: "center",
    width: 180,
  },
  {
    title: "操作",
    align: "center",
    // fixed: "right",
    width: 110,
    scopedSlots: { customRender: "action" },
  },
];

export default {
  mixins: [exportProgress], //导出方法注入
  components: {
    ExportLoading,
    StandardTable
  },
  data() {
    return {
      dateGroup: [moment().startOf('isoWeek').format('YYYY-MM-DD'), moment().endOf('isoWeek').format('YYYY-MM-DD')],
      sortlist: [{
        value: 0,
        name: '升序'
      }, {
        value: 1,
        name: '降序'
      }],
      selectlist: [{
        value: 0,
        name: '待巡检'
      }, {
        value: 1,
        name: '待复核'
      },{
        value: 2,
        name: '已完成'
      },{
        value: 3,
        name: '整改中'
      },{
        value: 4,
        name: '已变更'
      }],
      params: {
        sortType: 0,
        endDate: moment().endOf('isoWeek').format('YYYY-MM-DD'),
        gcglbId: '',
        projectName: '',
        startDate: moment().startOf('isoWeek').format('YYYY-MM-DD'),
        pageNumber: 1,
        pageSize: 10,
        status: ''
      },
      pagination: {
        //分页数据
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total, range) => `共 ${total} 条`,
      },
      gcglbList: [],
      checkTypeDic: [],
      total: 0,
      columns: columns,
      tableData: [],
      tableLoading: false, //table加载中

      loadingText: "数据加载中...",
      cPercent: 0,
      isExporting: false,
    };
  },
  created() {
    this.getgcglbList(QUERY_OFFICE_BY_USER);
    this.getAQXJ_ZXJC(AQXJ_ZXJC);
  },
  activated() {
    this.handleQuery();
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  methods: {
    handleEdit(row) {
      this.$router.push({
        name: '新增安全巡检计划',
        query: {
          isEdit: 1,
          id: row.id
        },
      });
    },
    handleDel(record) {
      const _that = this
      this.$confirm({
        title: '是否确定删除？',
        cancelText: '否',
        okText: '是',
        onOk: () => {
          delInspectionPlan(record.id).then(res => {
            if (res.data.code === 0) {
              _that.$message.success('删除成功')
              this.handleQuery();
            } else {
              _that.$message.error(res.data.msg)
            }
          })
        },
      })
    },
    addNewPlan() {
      this.$router.push({
        name: '新增安全巡检计划',
        query: {
          isEdit: 0,
        },
      });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.params.pageNumber = 1
      this.pagination.current = 1
      this.tableData = []
      this.handleQuery();
    },
    handleQuery(e) {
      this.tableLoading = true
      inspectionPlanList(this.params).then((res) => {
        this.tableLoading = false
        let d = res.data;
        if (d.code === 0) {
          this.tableData = d.data.list
          this.tableData.forEach(el => {
            el.checkDate = moment(el.checkDate).format('YYYY-MM-DD')
            if (el.status == 0) {
              el.status = '待巡检'
            } else if (el.status == 1) {
              el.status = '待复核'
            } else if (el.status == 2) {
              el.status = '已完成'
            } else if (el.status == 3) {
              el.status = '整改中'
            } else if (el.status == 6) {
              el.status = '已变更'
            }
            el.checkType = this.checkTypeDic.find(i => i.id == el.checkType).itemValue
          })
          this.pagination.total = d.data.total;
          this.total = d.data.total;
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    handleChangePage(e) {
      this.params.pageNumber = e.current;
      this.pagination.current = e.current;
      this.handleQuery();
    },
    // 获取工程管理部数据
    getgcglbList(path) {
      request(path, "get").then((res) => {
        let data = res.data.data.filter(item => item.id != "3da0f5533f87425c9a9e8a11a7923300")
        const arr = [];
        data.forEach((item) => {
          arr.push({
            name: item.master,
            value: item.id,
          });
        });
        this.gcglbList = arr;
      });
    },
    getAQXJ_ZXJC(path) {
      request(path, "get").then((res) => {
        res.data.data.forEach(el => {
          this.checkTypeDic.push({
            id: el.value,
            itemValue: el.label
          })
        })
      });
    },
    resetQuery() {
      this.dateGroup = [moment().startOf('isoWeek').format('YYYY-MM-DD'), moment().endOf('isoWeek').format('YYYY-MM-DD')]
      this.pagination.pageNumber = 1
      this.params = {
        endDate: moment().endOf('isoWeek').format('YYYY-MM-DD'),
        gcglbId: '',
        projectName: '',
        startDate: moment().startOf('isoWeek').format('YYYY-MM-DD'),
        pageNumber: 1,
        pageSize: 10,
        status: ''
      };
      this.handleQuery();
    },
    doDowload() {
      let obj = this.params;
      delete obj.pageNumber
      delete obj.pageSize
      this.loadingText = "正在导出EXCEL文件，请稍候 ····";
      this.isExporting = true;
      this.cPercent = 0;
      let fileName = "安全巡检计划.xlsx";
      this.exportFunNomal2(
        inspectionPlanExport,
        obj,
        fileName,
        "application/vnd.ms-excel"
      );
    },
    onChange(date, dateString) {
      // console.log(date, dateString);
      this.params.startDate = dateString[0]
      this.params.endDate = dateString[1]
    },
  },
};
</script>

<style lang="less" scoped>
@import "@/theme/common.less";
.query-form {
  display: flex;
  // justify-content: space-between;
}
.a_a_footer {
  background: #ffffff;

  .operator {
    margin-right: 8px;

  }
}
</style>
