<template>
    <div>
        <a-modal title="公司关联" style="top: 20px;" :width="1400" v-model="visible">
            <a-row>
<!--                <a-button type="primary" @click="add()">-->
<!--                    新增绑定-->
<!--                </a-button>-->
            </a-row>
            <a-table
                    :columns="columns"
                    :data-source="data"
            >
                <template slot="isBigPlatform" slot-scope="isBigPlatform,record">
                    {{record.accessno !=null && record.accessno != "" && record.accesskey !=null && record.accesskey != "" ? "是" : "否"}}
                </template>
<!--                <template slot="action" slot-scope="text, record">-->
<!--                                <span>-->
<!--                                  <a @click="showEditModel(record)">编辑</a>-->
<!--&lt;!&ndash;                                  <a-divider type="vertical" />&ndash;&gt;-->
<!--&lt;!&ndash;                                  <a >删除</a>&ndash;&gt;-->
<!--                                </span>-->
<!--                </template>-->
            </a-table>
        </a-modal>

        <a-modal
                title="编辑"
                :visible="visibleEdit"
                :confirm-loading="confirmLoading"
                @ok="projectBingOffice"
                @cancel="closeEdit"
                width="560px"
        >
            <a-form-model :model="form"
                          :label-col="labelCol"
                          :wrapper-col="wrapperCol"
                          ref="ruleForm"
                          :rules="rules"
                          v-if="visible"
            >
                <a-form-model-item label="所属公司">
                    <a-tree-select
                            style="width: 100%"
                            v-model="form.officeId"
                            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                            :tree-data="officeTreeData"
                            placeholder="请选择直属上级单位"
                            tree-default-expand-all
                            :replaceFields="{children:'children', title:'name', key:'id', value:'id' }"
                            :treeDefaultExpandAll="false"
                            @select="officeSelect"
                    >
                    </a-tree-select>
                </a-form-model-item>

                <a-form-model-item label="甲方联系人">
                    <a-input v-model="form.nailcontacts" />
                </a-form-model-item>

                <a-form-model-item label="负责人" ref="manager" prop="manager"  required>
                    <a-input v-model="form.manager" />
                </a-form-model-item>

                <a-form-model-item label="联系方式" ref="phoneNumber" prop="phoneNumber"  required>
                    <a-input v-model="form.phoneNumber" />
                </a-form-model-item>

                <a-form-model-item label="单位类型" ref="companyRole" prop="companyRole"  required>
                    <a-select
                            v-model="form.companyRole"
                            placeholder="请选择单位类型"
                    >
                        <a-select-option v-for="(item, index) in companyTypeList" :key="index" :value="item.value">
                            {{ item.label }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>

                <a-form-model-item label="开户名称(选填)" >
                    <a-select
                            v-model="form.projBankName"
                            placeholder="请选择开户行"
                    >

                        <a-select-option v-for="(item, index) in bankList" :key="index" :value="item.name">
                            {{ item.name }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>

                <a-form-model-item label="工资支付账户(选填)">
                    <a-input v-model="form.projBankAccount" />
                </a-form-model-item>

                <a-form-model-item label="大平台接入编号(选填)" ref="accessno" prop="accessno">
                    <a-input v-model="form.accessno" />
                </a-form-model-item>

                <a-form-model-item label="大平台接入密钥(选填)" ref="accesskey" prop="accesskey">
                    <a-input v-model="form.accesskey" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>


    </div>



</template>
<script>
    import { getProjectRelationOfficeList, projectRelationOffice, getProjectDictTypeList} from '@/services/sys'
    import {getOfficeTree} from '@/services/sys'
    import pick from 'lodash.pick'
    import {mapGetters} from 'vuex'

    const columns = [
        {
            title: '公司名称',
            dataIndex: 'officeName',
            key: 'officeName',
        },
        {
            title: '甲方联系人',
            dataIndex: 'nailcontacts',
            key: 'nailcontacts',
        },
        {
            title: '负责人',
            dataIndex: 'manager',
            key: 'manager',
        },
        {
            title: '联系方式',
            key: 'phoneNumber',
            dataIndex: 'phoneNumber',
        },
        {
            title: '单位类型',
            dataIndex: 'companyRoleName',
            scopedSlots: { customRender: 'companyRoleName' },
        },
        {
            title: '开户行名称',
            key: 'projBankName',
            dataIndex: 'projBankName',
        },
        {
            title: '工资支付账户',
            key: 'projBankAccount',
            dataIndex: 'projBankAccount',
        },
        {
            title: '是否连接大平台',
            // key: 'isBigPlatform',
            dataIndex: 'isBigPlatform',
            scopedSlots: { customRender: 'isBigPlatform' },
        },
        {
            title: '操作',
            key: 'action',
            scopedSlots: { customRender: 'action' },
        },
    ];

    export default {
        name: 'RelationOfficeModel',
        data () {
            return {
                projectId: "",

                // 数据
                columns,
                data: [],
                visible: false,

                // 表单
                title: "编辑",
                visibleEdit: false,
                confirmLoading: false,
                labelCol: { span: 8 },
                wrapperCol: { span: 12 },
                form:{
                    officeId: undefined
                },
                rules: {
                    officeId: [
                        { required: true, message: '请选择公司', trigger: 'blur' },
                    ],
                    manager: [
                        { required: true, message: '请输入负责人', trigger: 'blur' },
                    ],
                    phoneNumber: [
                        { required: true, message: '请输入联系方式', trigger: 'blur' },
                    ],
                    companyRole: [
                        { required: true, message: '请选择单位类型', trigger: 'blur' },
                    ],
                },
                officeTreeData:[],
                companyTypeList: [],
                bankList: [],

            }
        },
        computed: {
            ...mapGetters('account', ['user']),
        },
        created () {

        },
        methods: {
            getRelationOfficeList() {
                getProjectRelationOfficeList({projectId: this.projectId}).then(res => {
                    this.data = res.data.data
                })
            },
            relationOfficeOpen(projectId) {
                this.visible = true
                this.projectId = projectId
                this.getRelationOfficeList();

                let parentOfficeId = undefined
                if (this.user.nowRoleKey.includes("admin")){
                    parentOfficeId = undefined
                }else{
                    parentOfficeId = this.user.officeId
                }

                getOfficeTree({parentOfficeId: parentOfficeId}).then(res => {
                    this.officeTreeData = res.data.data
                })

                getProjectDictTypeList().then(res=>{
                    this.companyTypeList = res.data.data.companyTypeList
                    this.bankList = res.data.data.bankList
                })
            },
            officeSelect(value, node, extra){
                console.log(value)
                this.form.officeId = value
                console.log(this.form.officeId)
            },
            showModel(record){
              this.form = record;
              this.visibleEdit = true;
            },
            closeEdit(){
              this.visibleEdit = false;
            },
            showEditModel(record){
                this.showModel(record)
            },
            add(){
                this.showModel({projectId: this.projectId, officeId: undefined})
            },
            projectBingOffice(){
                console.log("projectRelationOffice")
                projectRelationOffice(this.form).then(res=>{
                    if (res.data.code === 0){
                        this.$message.info(res.data.msg);
                        this.closeEdit()
                        this.getRelationOfficeList()
                    }else{
                        this.$message.error(res.data.msg);
                    }
                    console.log("projectRelationOffice", res)
                })
            }


        },
        watch: {
            /*
              'selectedRows': function (selectedRows) {
                this.needTotalList = this.needTotalList.map(item => {
                  return {
                    ...item,
                    total: selectedRows.reduce( (sum, val) => {
                      return sum + val[item.dataIndex]
                    }, 0)
                  }
                })
              }
              */
        }
    }
</script>
