import { ADMIN, LOGIN, ROUTES, LOGIN_PHONE, CHANGE_ACCOUNT, CHANGE_PWD,LOGINOUT, PERMISSION_INFO, ISGCGLB, GET_CAPTCHA, 
  CHECK_CAPTCHA, SEND_SMS_CODE, CHECK_SMS_CODE, UPDATE_PHONE_PWD, CHECK_PHONE, FIRST_SAVE_BIND_PHONE, DEPTDEPTH} from '@/services/api'
import {request, METHOD, removeAuthorization} from '@/utils/request'
import store from '@/store/index.js'
import { resetRouter } from '@/router/index.js'
import { systemTypeStorageKey } from '@/constants/system.js'

// const BASE_URL = process.env.VUE_APP_API_BASE_URL
/**
 * 登录服务
 * @param name 账户名
 * @param password 账户密码
 * @param system 0.劳务，1.机施
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function login(name, password, system) {
  return request(LOGIN, METHOD.GET, {
    userName: name,
    password: password,
    ...(system !== null ? { system } : {})
  })
}

// 使用租户名，获得租户编号
export function getTenantIdByName(name) {
  return request(ADMIN + '/system/tenant/get-id-by-name?name=' + name, METHOD.GET)
}

/**
 * 滑动或者点选验证
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function checkCaptcha(params) {
  return request(CHECK_CAPTCHA, METHOD.POST, params)
}

/**
 * 获取验证图片  以及token
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getCaptcha(params) {
  return request(GET_CAPTCHA, METHOD.POST, params)
}

/**
 * 机施获取是否工程管理部
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getIsGcglb(params) {
  return request(ISGCGLB, METHOD.GET, params) 
}

/**
 * 获取公司层级
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getDeptDepth(params) {
  return request(DEPTDEPTH, METHOD.GET, params) 
}


/**
 * 手机号登录服务
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function loginPhone(params) {
  return request(LOGIN_PHONE, METHOD.GET, params)
}

export async function getRoutesConfig() {
  return request(ROUTES, METHOD.GET)
}

/**
 * 切换登录账号
 * @returns {Promise<AxiosResponse<T>>}
 */
export function changeAccount(params){

  return request(CHANGE_ACCOUNT, METHOD.GET, params)

}

/**
 * 修改密码
 * @returns {Promise<AxiosResponse<T>>}
 */
export function changePwd(params){

  return request(CHANGE_PWD, METHOD.GET, params)

}

/**
 * 验证原密码
 * @returns {Promise<AxiosResponse<T>>}
 */
export function checkUserPwd(params){
  return request(ADMIN + `/labour/wxUser/checkUserPwd`, METHOD.GET, params)
}

/**
 * 退出登录
 * @returns {Promise<AxiosResponse<T>>}
 */
export function loginOut(params){

  return request(LOGINOUT, METHOD.GET, params)

}


// 挂载到全局 方便在控制台退登
window.logout = () => {
  logout()
  location.reload()
}
/**
 * 退出登录
 */
export function logout() {
  // 重置路由，以免账号A的菜单带到账号B
  resetRouter()
  store.commit('permission/setPermissionInfo', null)
  
  // 缓存系统类型
  const systemType = sessionStorage.getItem(systemTypeStorageKey)

  // 清空 storage 缓存
  localStorage.clear()
  sessionStorage.clear()
  // 移除 cookies 认证信息
  removeAuthorization()

  // 重新设置系统类型
  sessionStorage.setItem(systemTypeStorageKey, systemType)
}

/**
 * 退出分配管理人员 清除单位公司ID
 */
export function delUnitOfficeId() {
  localStorage.removeItem('unitOfficeId')
}

/**
 * 删除项目详情数据
 */
export function delProjectDetail() {
  localStorage.removeItem('projectDetail')
}

/**
 * 获取权限信息
 */
export function getPermissionInfo(params = {}) {
  return request(PERMISSION_INFO, METHOD.GET, params)
}

/**
 * 获取验证码
 */
export function sendSmsCode(params = {}) {
  return request(SEND_SMS_CODE, METHOD.POST, params)
}

/**
 * 校验验证码
 */
export function checkSmsCode(params = {}) {
  return request(CHECK_SMS_CODE, METHOD.GET, params)
}

/**
 * 提交修改密码
 */
export function updatePhonePwd(params = {}) {
  return request(UPDATE_PHONE_PWD, METHOD.GET, params)
}

/**
 * 校验手机号是否绑定多账号
 */
export function checkPhone(params = {}) {
  return request(CHECK_PHONE, METHOD.GET, params)
}

/**
 * 绑定手机号
 */
export function firstSaveBindPhone(params = {}) {
  return request(FIRST_SAVE_BIND_PHONE, METHOD.GET, params)
}


export default {
  login,
  logout,
  getRoutesConfig,
  loginPhone,
  changeAccount,
  changePwd,
  delUnitOfficeId,
  delProjectDetail,
  loginOut,
  getPermissionInfo,
  sendSmsCode,
  checkSmsCode
}
