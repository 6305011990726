<template>
  <div class="card-container">
    <!-- <a-tabs type="card">
      <a-tab-pane key="1" tab="班组信息"> -->
        <!-- 表单封装
          projectObj：下拉弹出框第一项对象
          Overview：项目名称
          baiduMapBoolen：控制地图显示
          topSubmit：控制上部分按钮显示
          bottomSubmit：控制下部分按钮显示
          handleSubmit：表单提交
          handleChange：输入出发
          handlSelect：输入下拉选择触发
          defaultFileList：上传图片回显链接
          query：路由传过来的数据
      -->
        <form-index
          ref="formindex"
          :formData="formData"
          Overview="参建班组详情"
          :bottomSubmit="true"
          @handleSubmit="handleSubmit"
          @handlSelect="handlSelect"
        >
          <!-- 上部分按钮插槽 -->
          <template #bottomSubmit>
            <a-space>
              <a-button :disabled="Boolean(query.look)" type="primary" html-type="submit"> 保存 </a-button>
              <a-button @click="close"> 关闭 </a-button>
            </a-space>
          </template>
        </form-index>
      <!-- </a-tab-pane> -->
      <!-- <a-tab-pane key="2" tab="班组评分">
        <standard-table
          class=""
          :columns="columns"
          :dataSource="dataSource"
          rowKey="id"
          :scroll="{ x: 1300 }"
          :pagination="pagination"
          :loading="tableLoading"
          @change="onPageChange"
          :isAlert="false"
          :bordered="true"
        >
          <template slot="index" slot-scope="{ index }">
            <span>
              {{
                (pagination.current - 1) * pagination.pageSize +
                parseInt(index) +
                1
              }}
            </span>
          </template>
        </standard-table>
      </a-tab-pane> -->
    <!-- </a-tabs> -->
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import { getofficelist, getProjContacts } from "@/services/projectManagement";
import { request } from "@/utils/request";
import { PROJECT_OFFICE_CHILD } from "@/services/api";
import { debounce } from "lodash";
import {
  saveTeamInfo,
  getTeamPoint,
  getProAllOfficeList,
  getTeamDetail
} from "@/services/participationUnit";
import { QUERY_JOBTITLE_ALL } from "@/services/api";
import { mapGetters } from "vuex";
import { useRender } from '@/hooks/useRender.js'
const columns = [
  {
    title: "组长能力",
    dataIndex: "zuzhangPoint",
    align: "center",
    width: 110,
    scopedSlots: { customRender: "zuzhangPoint" },
    // fixed: "left",
  },
  {
    title: "效率",
    dataIndex: "xiaolvPoint",
    width: 110,
    align: "center",
    // ellipsis: true,
    scopedSlots: { customRender: "xiaolvPoint" },
    // fixed: "left",
  },
  {
    title: "协作",
    align: "center",
    width: 100,
    dataIndex: "xiezuoPoint",
    scopedSlots: { customRender: "xiezuoPoint" },
  },
  {
    title: "安全意识",
    dataIndex: "anquanPoint",
    width: 150,
    ellipsis: true,
    align: "center",
    scopedSlots: { customRender: "anquanPoint" },
  },
  {
    title: "综合",
    dataIndex: "zonghePoint",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "zonghePoint" },
  },
  {
    title: "评价人",
    dataIndex: "auto",
    align: "center",
    width: 140,
    ellipsis: true,
    key: "auto",
  },
  {
    title: "评价时间",
    dataIndex: "createDate",
    align: "center",
    width: 160,
    key: "createDate",
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "备注",
    dataIndex: "remarks",
    width: 160,
    align: "center",
    key: "remarks",
  },
  // {
  //   title: "操作",
  //   align: "center",
  //   width: 200,
  //   // fixed: "right",
  //   scopedSlots: { customRender: "action" },
  // },
];
const formData = [
  {
    label: "班组名称",
    placeholder: "请输入班组名称",
    decorator: [
      "teamName",
      { rules: [{ required: true, message: "请输入班组名称" }] },
    ],
    type: "input",
    key: "teamName",
    labelCol: 5,
    wrapperCol: 17,
    col: 12,
    display: true,
    disabled: true,
  },
  {
    label: "班组长姓名",
    placeholder: "请输入班组长姓名",
    decorator: [
      "teamLeader",
      { rules: [{ required: true, message: "请输入班组长姓名" },
      { required: true, message: "最长不超过12位汉字" ,pattern:/(^[\u4e00-\u9fa5]{1,12}$)/}] },
    ],
    type: "input",
    key: "teamLeader",
    labelCol: 5,
    wrapperCol: 17,
    col: 12,
    display: true,
    disabled: true,
    maxLength: 13
  },
  {
    label: "身份证号码",
    placeholder: "请输入身份证号码",
    decorator: [
      "remarks",
      {
        rules: [
          {
            required: true,
            message: "请输入身份证号码",
            pattern: new RegExp(
              /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/
            ),
          },
        ],
      },
    ],
    type: "input",
    key: "remarks",
    labelCol: 5,
    wrapperCol: 17,
    col: 12,
    display: true,
  },
  {
    label: "班组长联系电话",
    placeholder: "请输入班组长联系电话",
    decorator: [
      "phoneNumber",
      {
        rules: [
          {
            required: true,
            message: "请输入班组长联系电话",
            pattern: new RegExp(/^1\d{10}$/),
          },
        ],
      },
    ],
    type: "input",
    key: "phoneNumber",
    labelCol: 5,
    wrapperCol: 17,
    col: 12,
    display: true,
    maxLength: 11
  },
  {
    label: "所属单位",
    placeholder: "请输入所属单位",
    decorator: [
      "officeId",
      { rules: [{ required: true, message: "请输入所属单位" }] },
    ],
    type: "select",
    key: "officeId",
    showSearch: true,
    selectlist: [],
    labelCol: 5,
    wrapperCol: 17,
    col: 12,
    display: true,
    disabled: true
  },
  // {
  //   label: "业务负责人",
  //   placeholder: "请输入业务负责人",
  //   decorator: [
  //     "leader",
  //     { rules: [{ required: true, message: "请输入业务负责人" }] },
  //   ],
  //   type: "input",
  //   key: "leader",
  //   labelCol: 5,
  //   wrapperCol: 17,
  //   col: 12,
  //   display: true,
  // },
  // {
  //   label: "负责人联系电话",
  //   placeholder: "请输入业务负责人联系电话",
  //   decorator: [
  //     "leaderPhoneNumber",
  //     { rules: [{ required: true, message: "请输入业务负责人联系电话" }] },
  //   ],
  //   type: "input",
  //   key: "leaderPhoneNumber",
  //   labelCol: 5,
  //   wrapperCol: 17,
  //   col: 12,
  //   display: true,
  // },
  {
    label: "工种",
    placeholder: "请选择工种",
    decorator: [
      "postId",
      { rules: [{ required: true, message: "请选择工种" }] },
    ],
    type: "select",
    key: "postId",
    selectlist: [],
    labelCol: 5,
    wrapperCol: 17,
    col: 12,
    display: true,
    showSearch: true
  },
  {
    label: "班组工作内容",
    placeholder: "请输入班组工作内容",
    decorator: [
      "content",
      { rules: [{ required: true, message: "请输入班组工作内容" }] },
    ],
    type: "input",
    key: "content",
    labelCol: 5,
    wrapperCol: 17,
    col: 12,
    display: true,
    maxLength: 50
  },
  {
    label: "合同密钥",
    placeholder: "请选择合同密钥",
    decorator: [
      "contractId",
      { rules: [{ required: false, message: "请选择合同密钥" }] },
    ],
    type: "select",
    key: "contractId",
    selectlist: [],
    labelCol: 5,
    wrapperCol: 17,
    col: 12,
    display: true,
  },
  {
    label: "建委编码",
    placeholder: "请输入班组建委编码",
    decorator: [
      "jwTeamCode",
      { rules: [{ required: false, message: "请输入建委编码" }] },
    ],
    type: "input",
    key: "jwTeamCode",
    labelCol: 5,
    wrapperCol: 17,
    col: 12,
    display: true,
    maxLength: 50
  },
];
export default {
  data() {
    return {
      columns: columns,
      query: this.$route.query,
      formData: formData,
      // form: this.$form.createForm(this, { name: 'coordinated' }),
      jobTitles: [],
      dataSource: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        // showLessItems: true,
        // showQuickJumper: true,
      },
      tableLoading: false,
      prams: {
        pageNumber: 1,
        pageSize: 10,
        // queryType: 1,
      },
      data: {}
    };
  },
  inject: ["fatherMethod"],
  components: {
    formIndex,
    // StandardTable,
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  methods: {
    // 获取公司数组
    initData() {
      request(QUERY_JOBTITLE_ALL, "get", {}).then((res) => {
        // console.log(res.data.data, "查询所有工种");
        if (res.data.code === 0) {
          const data = res.data.data;
          const arr = [];
          data.forEach((item) => {
            // 剔除没有工种名字的选项
            if (item.carft) {
              arr.push({
                name: item.carft,
                value: item.id,
              });
            }
          });
          this.jobTitles = arr;
          this.formDataFn("postId", arr);
        }
      });
      this.getgcglbList();
      this.getProjContacts()
    },
    // 获取评分列表
    aqxjlist(prams) {
      this.tableLoading = true;
      getTeamPoint(prams).then((res) => {
        if (res.data.code === 0) {
          this.dataSource = res.data.data;
        }
        this.tableLoading = false;
      });
    },
    // 下拉特殊数据处理
    handleSearch(e) {
      if (e.key == "buildOfficeName" || e.key == "effcOfficeName") {
        this.getZdata(getofficelist, e, "name", e.key);
      }
    },
    // 关闭当前页面
    close() {
      let path = this.$route.path;
      this.fatherMethod(`${path}`);
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      this.formData.forEach((item) => {
        if (item.key == key) {
          // console.log(data);
          item.selectlist = data;
        }
      });
    },
    // 获取工程管理部数据
    getgcglbList() {
      getProAllOfficeList({ projectId: this.user.projectId }).then((res) => {
        if (res.data.code === 0) {
          const data = res.data.data;
          const arr = [];
          data.forEach((item) => {
            arr.push({
              name: item.officeName,
              value: item.officeId,
            });
          });
          this.formDataFn("officeId", arr);
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    // 合同密钥
    getProjContacts() {
      getProjContacts({}).then(res => {
        if (res.data.code === 0) {
          const data = res.data.data;
          const arr = [];
          data.forEach((item) => {
            arr.push({
              name: item.name,
              value: item.id,
            });
          });
          this.formDataFn("contractId", arr);
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 提交表单数据
    handleSubmit:debounce(function (e)  {
       // 绑定班组名称 = 班组长姓名 和 工种信息 并添加 班组
       this.jobTitles.forEach((item) => {
        if (item.value == e.postId) {
          e.teamName = e.teamLeader + item.name + '班组';
        }
      });
      e.id = this.data.id;
      e.projectId = this.data.projectId;
      e.officeId = this.data.officeId;
      this.update(e, "handleSubmit");
    },1000),
    update(e, type) {
      saveTeamInfo(e).then((res) => {
        if (res.data.code === 0) {
          type == "handleSubmit"
            ? this.$message.success("更新成功", 10)
            : this.$message.success("成功", 10);
          if (this.query.type == "table") {
            this.close();
            this.$router.push({
              name: "参建班组",
              query: {
                pageNumber: this.query.pageNumber,
              },
            });
          }
        } else {
          this.$message.error(res.data.msg, 10);
        }
      });
    },
    // 特殊数据处理
    handlSelect({ val, keys }) {
      this.jobTitles.forEach((item) => {
        if (keys == "postId" && item.value == val) {
          this.$refs["formindex"].setFieldsValue(item.name, {}, "jobtitle");
          let obj = this.$refs["formindex"].getFieldValue();
          this.$refs["formindex"].setFieldsValue(
            item.name,
            { teamName: obj["teamLeader"] + item.name },
            "teamName"
          );
        }
      });
    },
    //表格 - 分页查询
    onPageChange(e) {
      // let obj = this.$refs["formindex"].getFieldValue();
      this.prams.pageNumber = e.current;
      this.pagination.current = e.current;
      this.aqxjlist(Object.assign({}, this.prams));
    },
    getTeamDetail() {
      getTeamDetail(this.query.id).then((res) => {
        if (res.data.code === 0) {
          this.data = res.data.data
          this.$refs.formindex.queryObj(this.data)
        }
      });
    }
  },
  activated() {
    this.userObj = this.user;
    this.getTeamDetail()
    this.initData();
    // console.log(this.$route.query, "this.$route.query");
  },
};
</script>
<style lang="less" scoped>
.card-container {
  // background: #f5f5f5;
  overflow: hidden;
  padding: 24px;
  background: #fbfbfb;
  border-radius: 6px;
  margin-bottom: 10px;
  /deep/ .ant-tabs-bar {
    margin: 0;
  }
  /deep/ .ant-tabs {
    .ant-tabs-top-content {
      padding: 20px;
      /* border-top: none; */
      border: 1px solid #f0f0f0;
      border-top: hidden;
    }
  }
}
</style>
