import { ADMIN } from '@/services/api'
import {request, METHOD, removeAuthorization} from '@/utils/request'

// const BASE_URL = process.env.VUE_APP_API_BASE_URL + '/score'
const ADMIN_API = `${ADMIN}/score`

export async function safestafileAdd(data) {
    return request(ADMIN_API + '/safestafile/add', METHOD.POST, data)
}
export async function safestafileUpdate(data) {
    return request(ADMIN_API + `/safestafile/update`, METHOD.POST, data)
}
export async function safestafileList(data) {
    return request(ADMIN_API + `/safestafile/list`, METHOD.POST, data)
}
export async function safestafileByID(params) {
    return request(ADMIN_API + `/safestafile/view`, METHOD.GET, params)
}
export async function safestafileDelete(params) {
    return request(ADMIN_API + `/safestafile/delete`, METHOD.GET, params)
}

export const safestafileBatchDownload = ADMIN_API + '/safestafile/batchDownload'

export async function safestapicAdd(data) {
    return request(ADMIN_API + '/safestapic/add', METHOD.POST, data)
}
export async function safestapicUpdate(data) {
    return request(ADMIN_API + `/safestapic/update`, METHOD.POST, data)
}
export async function safestapicList(data) {
    return request(ADMIN_API + `/safestapic/list`, METHOD.POST, data)
}
export async function safestapicByID(params) {
    return request(ADMIN_API + `/safestapic/view`, METHOD.GET, params)
}
export async function safestapicDelete(params) {
    return request(ADMIN_API + `/safestapic/delete`, METHOD.GET, params)
}

export const safestapicBatchDownload = ADMIN_API + '/safestapic/batchDownload'