<template>
    <div>
      <a-card :title="title" class="a_Approval" :bordered="false">
        <a-form-model ref="ruleForm" :model="params" :rules="rules">
          <a-row :gutter="16">
            <a-col :span="12">
              <a-form-model-item label="制度类别" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="systemcatid">
                <a-select
                        ref="select"
                        v-model="params.systemcatid"
                        
                        :options="options1"
                        allowClear
                        >
                        </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="级次" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="levelid">
                <a-select
                        ref="select"
                        v-model="params.levelid"
                        
                        :options="options2"
                        allowClear
                        >
                        </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="管理职能类别" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="funccatid">
                <a-select
                        ref="select"
                        v-model="params.funccatid"
                        
                        :options="options3"
                        allowClear
                        >
                        </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="管理职能内容" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="funcontent">
                <a-input placeholder="请输入管理职能内容" v-model="params.funcontent" />

              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="制度名称" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="systemname">
                <a-input placeholder="请输入制度名称" v-model="params.systemname" />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="制度主管部门" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="systemdeptid">
                <a-select
                        ref="select"
                        v-model="params.systemdeptid"
                       
                        :options="options4"
                        allowClear
                        >
                        </a-select>
              </a-form-model-item>
            </a-col>
           
            <a-col :span="12">
              <a-form-model-item label="发文日期" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="pubdate">
                <a-date-picker v-model="params.pubdate" style="width: 100%" allowClear mode="date"
                  :format="'YYYY-MM-DD'" :valueFormat="'YYYY-MM-DD'"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="生效日期" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="effectdate">
                <a-date-picker v-model="params.effectdate" style="width: 100%" allowClear mode="date"
                  :format="'YYYY-MM-DD'" :valueFormat="'YYYY-MM-DD'"/>
              </a-form-model-item>
            </a-col>
           

  
            <a-col :span="12" v-if="isEdit == 2">
              <a-card>
              <a-form-model-item label="历史版本" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="hisno">
                <a-input placeholder="请输入历史发文文号" v-model="params.hisno" />
              </a-form-model-item>
              <a-form-model-item label="上传附件" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }">
                <a-upload
                  class="avatar-uploader"
                  list-type="picture-card"
                  :accept="accept"
                  :file-list="hisFilePath"
                  @change="hishandleUploadChange"
                  :customRequest="hisresourceUpload"
                  @preview="handlePreview"
                  :beforeUpload="beforeUpload"
                  multiple
                >
                  <!-- <div v-for="(item, index) in filePath" :key="index">
                    <img
                      class="w-100"
                      v-if="item && item.name.indexOf('pdf') == -1"
                      :src="item"
                      alt="avatar"
                    />
                    <a-icon v-else-if="item && item.name.indexOf('pdf') > -1" type="file-pdf" :style="{ fontSize: '64px' }" />
                  </div> -->
  
                  <div v-if="filePath.length < 10">
                    <a-icon :type="uploading ? 'loading' : 'plus'" />
                    <div class="ant-upload-text">上传附件</div>
                  </div>
                </a-upload>
              </a-form-model-item>
            </a-card>
            </a-col>
            <a-col :span="12">
              <a-card>
              <a-form-model-item label="最新发文文号" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" >
                <a-input placeholder="请输入制度名称" v-model="params.nowno" />
              </a-form-model-item>
              <a-form-model-item label="上传附件" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" >
                <a-upload
                  class="avatar-uploader"
                  list-type="picture-card"
                  :accept="accept"
                  :file-list="filePath"
                  @change="handleUploadChange"
                  :customRequest="resourceUpload"
                  @preview="handlePreview"
                  :beforeUpload="beforeUpload"
                  multiple
                >
                  <!-- <div v-for="(item, index) in filePath" :key="index">
                    <img
                      class="w-100"
                      v-if="item && item.name.indexOf('pdf') == -1"
                      :src="item"
                      alt="avatar"
                    />
                    <a-icon v-else-if="item && item.name.indexOf('pdf') > -1" type="file-pdf" :style="{ fontSize: '64px' }" />
                  </div> -->
  
                  <div v-if="filePath.length < 10">
                    <a-icon :type="uploading ? 'loading' : 'plus'" />
                    <div class="ant-upload-text">上传附件</div>
                  </div>
                </a-upload>
              </a-form-model-item>
            </a-card>
            </a-col>
  
  
          </a-row>
        </a-form-model>
        <a-row style="padding-top: 16px;">
          <a-col :span="12"></a-col>
          <a-col :span="12">
            <a-form-model-item :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }" prop="year">
              <div class="flex flex-row-reverse">
                <a-button type="primary" @click="handleSubmit">保存</a-button>
                <a-button class="mr-2" @click="close">返回</a-button>
              </div>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-card>
    </div>
  </template>
  
  <script>
  
  import { request } from "@/utils/request";
  import { mapGetters } from 'vuex'
  import moment from "moment";
  import { safesystemAdd,safesystemUpdate, safesystemByID } from "@/services/safeManagementWorkingGuidelines";
  import { ATTACHMENT_UPLOAD } from "@/services/api";
  const options = [
    {
        value: "1",
        label: "安全措施费管理",
    },
    {
        value: "2",
        label: "危大工程管理",
    },
    {
        value: "3",
        label: "企业级项目安全人员管理",
    },
    {
        value: "4",
        label: "特种作业人员管理",
    },
    {
        value: "5",
        label: "文明施工管理",
    },
  ]
  // 1业务工作制度 2 工作指引
  const options1 = [
    {
        value: "1",
        label: "业务工作制度",
    },
    {
        value: "2",
        label: "工作指引",
    },
  ]
  // 1.一级、2.二级、3.三级
  const options2 = [
    {
        value: "1",
        label: "一级",
    },
    {
        value: "2",
        label: "二级",
    },
    {
        value: "3",
        label: "三级",
    },
  ]

  //管理职能类别 默认 1.安全管理
  const options3 = [
    {
        value: "1",
        label: "安全管理",
    }
  ]
  // 制度主管部门id 默认OFFICE_AQMGLY 安全部
  const options4 = [
    {
        value: "OFFICE_AQMGLY",
        label: "安全部",
    }
  ]
  export default {
    inject: ["fatherMethod"],
    computed: {
      ...mapGetters('account', ['user']),
    },
    data() {
      return {
        isEdit: 0,
        rules: {
          systemname: [{ required: true, message: '请输入名称', trigger: 'change' }],
          pubdate: [{ required: true, message: '请选择发布日期', trigger: 'change' }],
          // remark: [{ required: true, message: '请选择', trigger: 'change' }],
          // attach: [{ required: true, message: '请选择', trigger: 'change' }],
        },
  
        params: {
          "systemcatid": "", //制度类别id 1业务工作制度 2 工作指引
          "systemdes": "", //制度类别描述
          "levelid": "", //级次id 1.一级、2.二级、3.三级
          "leveldes": "", //级次描述
          "funccatid": "", //管理职能类别 默认 1.安全管理
          "funccatdes": "", //管理职能类别描述
          "funcontent": "", //管理职能内容
          "systemname": "", //制度名称
          "systemdeptid": "", //制度主管部门id 默认OFFICE_AQMGLY 安全部
          "systemdeptname": "", //制度主管部门名称 默认OFFICE_AQMGLY 安全部
          "pubdate": "", //发布日期 yyyy-MM-dd
          "effectdate": "", //生效日期
          "nowno": "", //最新发文文号
          "nowattach": "", //最新附件
           hisno: "", //历史版本
        },
        // accept:".png,.jpg,.jpeg,.pdf,.PDF",
        accept:"",
        filePath: [],
        uploading: false,
        hisaccept:"",
        hisFilePath: [],
        hisuploading: false,
        title: '新增安全制度管理详情',
        options: options,
        options1: options1,
        options2: options2,
        options3: options3,
        options4: options4,

      };
    },
    activated() {
      this.isEdit = Number(this.$route.query.isEdit);
      if (this.isEdit === 0) {
        // this.params.creator = this.user.id
        this.title = '新增安全制度管理详情';
      } else {
        // this.params.updator = this.user.id
        if (this.isEdit == 2) {
          this.title = '更新安全制度管理详情';
        } else {
          this.title = '编辑安全制度管理详情';
        }
        this.params.safesystemid = this.$route.query.id;
        this.getDetail()
      }
    },
    methods: {
      moment,
      getDetail() {
        safesystemByID({id: this.params.safesystemid}).then(res => {
          if (res.data.code === 200) {
            this.params = res.data.data;
            let attachList = this.params.nowattach.split(',');
            if (this.params.attach == '') {
              attachList = []
            }
            let fileNames = [];
            if (this.params.createuser) {
              fileNames = this.params.createuser.split(',');
            }
            this.filePath = attachList.map((item, index) => {
              return {
                uid: index,
                url: item,
                name: fileNames[index],
                status: 'done',
              }
            })

            let hisAttachList = this.params.hisattach.split(',');
            if (this.params.hisattach == '') {
              hisAttachList = []
            }
            this.hisFilePath = hisAttachList.map((item, index) => {
              return {
                uid: index,
                url: item,
                name: item.substring(item.lastIndexOf('/') + 1),
                status: 'done',
              }
            })
           
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
  
      handleSubmit() {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            let attach = this.filePath.map(item => item.url).join(',');
            let d = {
              ...this.params,
            }
            d.nowattach = attach;
            let fileNames = this.filePath.map(item => item.name).join(',');
            d.createuser = fileNames;

            let hisattach = this.hisFilePath.map(item => item.url).join(',');
            d.hisattach = hisattach;

            d.systemdes = this.options1.find(item => item.value == d.systemcatid).label;
            d.leveldes = this.options2.find(item => item.value == d.levelid).label;
            d.funccatdes = this.options3.find(item => item.value == d.funccatid).label;
            d.systemdeptname = this.options4.find(item => item.value == d.systemdeptid).label;
            if (this.isEdit == 0) {
              safesystemAdd(d).then(res => {
                if (res.data.code === 200) {
                  this.$message.success('提交成功！')
                  this.close();
                  this.$router.push({
                    name: "安全管理制度",
                  });
                } else {
                  this.$message.error(res.data.msg)
                }
              })
            } else {

              safesystemUpdate(d).then(res => {
                if (res.data.code === 200) {
                  this.$message.success('修改成功！')
                  this.close();
                  this.$router.push({
                    name: "安全管理制度",
                  });
                } else {
                  this.$message.error(res.data.msg)
                }
              })
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      close() {
        let path = this.$route.path;
        this.fatherMethod(`${path}`);
        this.$router.push({
          name: "安全管理制度",
        });
      },
      beforeUpload(file){
        // const accept = '.' + file.name.substring(file.name.lastIndexOf(".") + 1);
        // if (this.accept.split(',').indexOf(accept) == -1) {
        //     this.$message.error("只能上传图片、PDF文件");
        //     return false
        // }
      },
      handleUploadChange(info) {
        if (info.file.status === "uploading") {
          this.uploading = true;
          return;
        }
        if (info.file.status === "done") {
          this.uploading = false;
        }
        if (info.file.status === "removed") {
          this.filePath = info.fileList
        }
      },
      resourceUpload(value) {
        const formData = new FormData();
        formData.append("file", value.file);
        request(ATTACHMENT_UPLOAD, "post", formData).then((result) => {
          if (result.data.code === 0) {
            this.$message.success("上传成功!");
            this.filePath.push({
              uid: value.file.uid,
              url: result.data.data.path,
              name: value.file.name,
              status: 'done',
            });
            value.onSuccess(result.data.data.path, value.file);
          } else {
            this.$message.warning(result.data.message);
          }
        });
      },
      handlePreview(file) {
        window.open(file.url);
      },
      hishandleUploadChange(info) {
        if (info.file.status === "uploading") {
          this.hisuploading = true;
          return;
        }
        if (info.file.status === "done") {
          this.hisuploading = false;
        }
        if (info.file.status === "removed") {
          this.hisFilePath = info.fileList
        }
      },
      hisresourceUpload(value) {
        const formData = new FormData();
        formData.append("file", value.file);
        request(ATTACHMENT_UPLOAD, "post", formData).then((result) => {
          if (result.data.code === 0) {
            this.$message.success("上传成功!");
            this.hisFilePath.push({
              uid: value.file.uid,
              url: result.data.data.path,
              name: value.file.name,
              status: 'done',
            });
            value.onSuccess(result.data.data.path, value.file);
          } else {
            this.$message.warning(result.data.message);
          }
        });
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  .a_Approval {
    background: #fff;
    border-radius: 10px;
  }
  
  .btn_group {
    padding-right: 190px;
  }
  </style>
  