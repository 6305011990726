<template>
  <div>
    <div class="a_Approval" v-show="$route.meta.showFather">
      <div class="plug-Overview">安全考核一览表</div>
      <a-divider />
      <a-form-model :model="params" class="clearfix" @submit="handleSubmit">
        <a-col :span="5">
          <a-form-model-item label="选择年份" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
            <a-date-picker
              mode="year"
              v-model="params.year"
              placeholder="请选择年份"
              :open="yearShowOne"
              format="YYYY"
              @openChange="openChangeOne"
              @panelChange="panelChangeOne"/>
          </a-form-model-item>
        </a-col>

        <a-col :span="4">
          <a-space class="btnBox">
            <a-button type="primary" html-type="submit"> 查询 </a-button>
            <a-button @click="resetQuery"> 重置 </a-button>
          </a-space>
        </a-col>
      </a-form-model>

      <standard-table
        :columns="columns"
        :dataSource="tableData"
        rowKey="projectId"
        :pagination="pagination"
        :loading="tableLoading"
        @change="handleChangePage"
        :isAlert="false"
        :bordered="true"
        :btnArr="tableBtnArr"
      >
        <template slot="title">
          <div class="table-title">
            <div>广州机施建设集团有限公司</div>
            <div>{{titleYear}}年工程管理部安全指标考核表</div>
          </div>
        </template>
        <template slot="index" slot-scope="{ index }">
          <span>{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}</span>
        </template>
        <template slot="action" slot-scope="{ record }">
          <a @click="() => handleCheck(record, 1)" v-if="isJSOffice"> 编辑 </a>
          <a @click="() => handleCheck(record, 0)" v-if="isBranchOffice"> 查看 </a>
        </template>
      </standard-table>
      <Export-loading
        :cPercent="cPercent"
        :isExporting="isExporting"
        :loadingText="loadingText"
      ></Export-loading>
    </div>
    <router-view></router-view>
  </div>
</template>
  
<script>
import {
  safeExamineYearList, safeExamineYearGenerate, monthlyCheckExport
} from "@/services/safeExamine";
import { mapGetters } from "vuex";
import StandardTable from "@/components/table/StandardTable";
import moment from "moment";
import { exportProgress } from "@/pages/aqjyzl/export"; // 导出方法
import ExportLoading from "@/components/ExportLoading/ExportLoading"; // progress组件

const columns = [
  {
    title: "序号",
    align: "center",
    scopedSlots: { customRender: "index" },
    width: 65,
  },
  {
    title: "工程管理部",
    align: "center",
    dataIndex: "gcglbName",
    width: 120,
  },
  {
    title: "安全生产文明施工创优（3分）",
    dataIndex: "excellent",
    width: 120,
    align: "center",
  },
  {
    title: "安全隐患排查治理",
    align: "center",
    children: [
      {
        title: "停工处罚（4分）",
        dataIndex: "stopWork",
        width: 100,
        align: "center",
      },
      {
        title: "整改回复（2分）",
        dataIndex: "repairAnswer",
        width: 100,
        align: "center",
      },
    ]
  },
  {
    title: "投诉约谈（5分）",
    dataIndex: "complainAndTalk",
    width: 100,
    scopedSlots: { customRender: "complainAndTalk" },
    align: "center",
  },
  {
    title: "安全管理信息化系统应用（2分）",
    dataIndex: "systemApplication",
    width: 130,
    scopedSlots: { customRender: "systemApplication" },
    align: "center",
  },
  {
    title: "安全投入（1分）",
    dataIndex: "focus",
    width: 100,
    scopedSlots: { customRender: "focus" },
    align: "center",
  },
  {
    title: "安全教育培训（1分）",
    dataIndex: "education",
    width: 100,
    scopedSlots: { customRender: "education" },
    align: "center",
  },
  {
    title: "应急管理（2分）",
    dataIndex: "emergency",
    width: 100,
    scopedSlots: { customRender: "emergency" },
    align: "center",
  },
  {
    title: "专项安全检评",
    align: "center",
    children: [
      {
        title: "集团标准化管理",
        dataIndex: "standard",
        ellipsis: true,
        width: 100,
        align: "center",
      },
      {
        title: "月度综合检评",
        dataIndex: "monthlyCheck",
        ellipsis: true,
        width: 100,
        align: "center",
      },
    ]
  },
  {
    title: "安全事故",
    dataIndex: "accident",
    width: 100,
    scopedSlots: { customRender: "accident" },
    align: "center",
  },
  {
    title: "合计得分",
    dataIndex: "totalScore",
    width: 100,
    scopedSlots: { customRender: "totalScore" },
    align: "center",
  },
  {
    title: "操作",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "action" },
  },
];

export default {
  mixins: [exportProgress], //导出方法注入
  components: {
    StandardTable,
    ExportLoading, //导出数据加载封装
  },
  data() {
    return {
      titleYear: '',
      params: {
        pageNumber: 1,
        pageSize: 10,
        year: moment(new Date()).format('YYYY'),
      },
      pagination: {
        //分页数据
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total, range) => `共 ${total} 条`,
      },
      yearShowOne:false,
      total: 0,
      columns: columns,
      tableData: [],
      tableLoading: false, //table加载中
      loadingText: "数据加载中...",
      cPercent: 0,
      isExporting: false,
    };
  },
  activated() {
    this.handleQuery();
  },
  computed: {
    ...mapGetters("account", ["user"]),
    isBranchOffice() { //分公司
      return this.user.isGcglb == 1
    },
    isJSOffice() { //机施
      return this.user.isGcglb == 0
    },
    tableBtnArr() {
      return [
        {
          name: '导出',
          attrs: {
            type: 'primary'
          },
          click: this.doDowload
        },
        {
          name: '生成',
          attrs: {
            type: 'primary'
          },
          hidden: !this.isJSOffice,
          click: this.handleGenerate
        }
      ]
    }
  },
  methods: {
    openChangeOne(status) {
      if (status) {
        this.yearShowOne = true;
      }
    },
    // 得到年份选择器的值
    panelChangeOne(value) {
      this.yearShowOne = false;
      this.params.year = value.format('YYYY');
    },
     /**
     * 导出excel
     */
     doDowload() {
      this.loadingText = "正在导出EXCEL文件，请稍候 ····";
      this.isExporting = true;
      this.cPercent = 0;
      let fileName = "安全考核一览表.xlsx";
      this.exportFunNomal2(
        monthlyCheckExport,
        this.params,
        fileName,
        "application/vnd.ms-excel"
      );
    },
    handleGenerate() {
      safeExamineYearGenerate(this.params).then(res => {
        if (res.data.code === 0) {
          this.handleQuery();
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    handleSubmit(e) {
      e.preventDefault();
      this.params.pageNumber = 1
      this.pagination.current = 1
      this.tableData = []
      this.handleQuery();
    },
    handleQuery(e) {
      this.titleYear = this.params.year
      this.tableLoading = true
      safeExamineYearList(this.params).then((res) => {
        this.tableLoading = false
        if (res.data.code === 0) {
          this.tableData = res.data.data.list;
          this.pagination.total = res.data.data.total;
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    handleChangePage(e) {
      this.params.pageNumber = e.current;
      this.pagination.current = e.current;
      this.handleQuery();
    },
    addNewProject() {
      this.$router.push({
        name: `检评项目`,
      });
    },
    handleCheck(e, isEdit) {
      this.$router.push({
        name: `安全考核编辑`,
        query: {
          id: e.id,
          isEdit
        },
      });
    },
    resetQuery() {
      this.pagination.pageNumber = 1
      this.params = {
        year: moment(new Date()).format('YYYY'),
        pageNumber: 1,
        pageSize: 10,
      };
      this.handleQuery();
    },
  },
};
</script>
  
<style lang="less" scoped>
@import "@/theme/common.less";
.query-form {
  display: flex;
  // justify-content: space-between;
}
.post-border {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 5px;
  padding-top: 5px;
  // padding-top: 10px;
  // height: 40px;
}

.post-border:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.table-title {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}
</style>
  