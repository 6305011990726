var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "detail-list",
        _vm.size === "small" ? "small" : "large",
        _vm.layout === "vertical" ? "vertical" : "horizontal",
      ],
    },
    [
      _vm.title
        ? _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))])
        : _vm._e(),
      _c("a-row", [_vm._t("default")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }