<template>
  <a-card>
    <a-spin :spinning="spinning" :tip="loadingText">
      <div :class="advanced ? 'search' : null">
        <a-form layout="horizontal">
          <div :class="advanced ? null : 'fold'">
            <a-row>
              <a-col :md="8" :sm="24">
                <a-form-item
                  label="证书名称"
                  :labelCol="{ span: 5 }"
                  :wrapperCol="{ span: 18, offset: 1 }"
                >
                  <a-input
                    placeholder="请输入"
                    v-model="queryParams.cerName"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item
                  label="负责人"
                  :labelCol="{ span: 5 }"
                  :wrapperCol="{ span: 18, offset: 1 }"
                >
                  <a-input
                    placeholder="请输入"
                    v-model="queryParams.userName"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item
                  label="培训地址"
                  :labelCol="{ span: 5 }"
                  :wrapperCol="{ span: 18, offset: 1 }"
                >
                  <a-input
                    placeholder="请输入"
                    v-model="queryParams.address"
                  />
                </a-form-item>
              </a-col>
            </a-row>
          </div>
          <span style="float: right; margin-top: 3px">
            <a-button type="primary" @click="doQuery()">查询</a-button>
            <a-button style="margin-left: 8px" @click="doReset()"
              >重置</a-button
            >
          </span>
        </a-form>
      </div>
      <div>
        <a-button type="primary" style="margin-bottom:5px" @click="addExamCer()">新增考证信息</a-button>
        <standard-table
          :columns="columns"
          :dataSource="dataSource"
          :selectedRows.sync="selectedRows"
          :pagination="pagination"
          :loading="tableLoading"
          @change="onPageChange"
          :isAlert="false"
          :bordered="true"
        >
          <div slot="do-status" slot-scope="{ text }">
            <a-icon v-if="text == 1" style="color: green" type="check" />
            <a-icon v-else style="color: red" type="close" />
          </div>
          <template slot="vaccinationImg" slot-scope="{text}">
                <img :src="text" width="80px;" height="70px">
            </template>
          <div slot="action" slot-scope="{ record }">
            <a href="#" @click="doEditor(record)">编辑</a>
            <a-divider v-if="record.pstatus==0" type="vertical" />
            <a href="#" v-if="record.pstatus==0" @click="doChangePst(record,1)">发布</a>
            <a-divider v-if="record.pstatus==1" type="vertical" />
            <a href="#" v-if="record.pstatus==1" @click="doChangePst(record,0)">关闭</a>
          </div>
        </standard-table>
      </div>
    </a-spin>
    <modify-examcer :visible="examcer.visible"
      :examcer="examcer.obj"
      @examcerPageClose="examcerClose"
      @examcerSubmit="examcerSubmit"
    >
    </modify-examcer>
  </a-card>
</template>

<script>
import StandardTable from "@/components/table/StandardTable";
import ModifyExamcer from '@/pages/onlineedu/modifyexamcer'
import { ONLINE_EXAM_CERTIFICATE_QUERY,
ONLINE_EXAM_CERTIFICATE_INSERT,
ONLINE_EXAM_CERTIFICATE_UPDATE,
ONLINE_EXAM_CERTIFICATE_PUBLISH } from "@/services/api";
import { request } from "@/utils/request";
import { useRender } from '@/hooks/useRender.js'

const columns = [
  {
    title: "证书名称",
    dataIndex: "cerName",
    align: "center",
    scopedSlots: { customRender: "cerName" },
    customRender: (text,record) => {
      if(record.level){
        let levelName = ""
        if(record.level==0){
          levelName = "（初级）";
        }else if(record.level==1){
          levelName = "（中级）";
        }else if(record.level==2){
          levelName = "（高级）";
        }
        return text+levelName
      }else{
        return text
      }
    }
  },
  {
    title: "证书简图",
    dataIndex: "cerImg",
    align: "center",
    scopedSlots: { customRender: "vaccinationImg" },
    // render:(record)=>{
    //   alert(record)
    //   return <img src={record.cerImg} alt="证书简略图" style={{width:'50px',height:'50px'}}/>
    // }
  },
  {
    title: "负责人",
    dataIndex: "userName",
    align: "center",
    scopedSlots: { customRender: "userName" },
    customRender: (text,record) => {
      if(record.phone){
        return text+"("+record.phone+")"
      }else{
        return text
      }
    }
  },
  {
    title: "培训地址",
    dataIndex: "address",
    align: "center",
    scopedSlots: { customRender: "address" },
  },
  {
    title: "发布状态",
    dataIndex: "pstatus",
    align: "center",
    scopedSlots: { customRender: "do-status" },
  },
  {
    title: "发布时间",
    dataIndex: "ptime",
    align: "center",
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "创建时间",
    dataIndex: "createdTime",
    align: "center",
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  name: "QueryList",
  components: { StandardTable,ModifyExamcer },
  data() {
    return {
      dataSource: [],
      advanced: false,
      columns: columns,
      selectedRows: [],
      tableLoading: false,
      spinning: false,
      loadingText: "数据加载中...",
      loadKeys: [],
      queryParams: {
        cerName: null,
        phone: null,
        address: null,
      },
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showLessItems: true,
        showQuickJumper: true,
      },
      examcer:{
        visible:false,
        obj:{},

      }
    };
  },
  created() {
    this.doQuery();
  },
  authorize: {
    deleteRecord: "delete",
  },
  methods: {
    deleteRecord(key) {
      this.dataSource = this.dataSource.filter((item) => item.key !== key);
      this.selectedRows = this.selectedRows.filter((item) => item.key !== key);
    },
    toggleAdvanced() {
      this.advanced = !this.advanced;
    },
    remove() {
      this.dataSource = this.dataSource.filter(
        (item) =>
          this.selectedRows.findIndex((row) => row.key === item.key) === -1
      );
      this.selectedRows = [];
    },

    /**
     * 获取所有人员的签名记录信息
     */
    doQuery() {
      this.tableLoading = true;
      request(ONLINE_EXAM_CERTIFICATE_QUERY, "post", this.queryParams).then((res) => {
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
          this.dataSource.forEach((obj) => {
            obj.key = obj.id;
          });
        }
        this.tableLoading = false;
      });
    },
    doReset() {
      this.queryParams = {};
      this.doQuery();
    },
    //分页查询
    onPageChange(e) {
      this.queryParams.pageNumber = e.current;
      this.pagination.current = e.current;
      this.doQuery();
    },
    examcerClose(){
      this.examcer.visible = false;
      this.examcer.obj={};
    },
    examcerSubmit($event){
      this.tableLoading = true;
      let url = ONLINE_EXAM_CERTIFICATE_INSERT
      if($event.examcer.id){
        url = ONLINE_EXAM_CERTIFICATE_UPDATE;
      }
      request(url, "post", $event.examcer).then((res) => {
        if (res.data.code === 0) {
          this.doQuery();
        }
        this.tableLoading = false;
      });
    },
    addExamCer(){
      this.examcer.visible = true;
    },
    doEditor(record){
      this.examcer.obj = record;
      this.examcer.visible = true;
    },
    doChangePst(recode,type){
      this.tableLoading = true;
      let obj={
        id:recode.id,
        pstatus:type
      }
      request(ONLINE_EXAM_CERTIFICATE_PUBLISH, "post", obj).then((res) => {
        if (res.data.code === 0) {
          this.doQuery();
        }
        this.tableLoading = false;
      });
    }
  },
};
</script>

<style lang="less" scoped>
.search {
  margin-bottom: 54px;
}
.fold {
  width: calc(100% - 216px);
  display: inline-block;
}
.operator {
  margin-bottom: 18px;
}

@media screen and (max-width: 900px) {
  .fold {
    width: 100%;
  }
}
</style>
