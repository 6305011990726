var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "common-layout" },
    [
      _c("div", { staticClass: "content" }, [_vm._t("default")], 2),
      !_vm.realmType
        ? _c("page-footer", {
            attrs: {
              newLogin: !_vm.realmType,
              "link-list": _vm.footerLinks,
              copyright: _vm.copyright,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }