<template>
	<div>
		<div class="a_Approval">
			<div class="plug-Overview">项目名称变更记录</div>
			<a-divider/>
			<div class="a_a_footer">
				<!-- 表格封装 -->
				<standard-table
					class=""
					:columns="columns"
					:dataSource="dataSource"
					rowKey="id"
					:pagination="pagination"
					:loading="tableLoading"
					@change="onPageChange"
					:isAlert="false"
					:bordered="true"
				>
					<template slot="index" slot-scope="{ index }">
						<span>
							{{
								(pagination.current - 1) * pagination.pageSize +
								parseInt(index) +
								1
							}}
						</span>
					</template>
					<!-- <template slot="action" slot-scope="{ record }" v-if="user.nowRoleKey.includes('OFFICE_AQMGLY')">
						<div class="sTables">
							<div @click="visit(record)">编辑</div>
							<div @click="del(record)">删除</div>
						</div>
					</template> -->
				</standard-table>
			</div>
		</div>
		<router-view></router-view>
	</div>
</template>

<script>
import StandardTable from "@/components/table/StandardTable";
import { mapGetters } from 'vuex'
import { getProjectNameHistoryInfo } from '@/services/projectManagement';
import { useRender } from '@/hooks/useRender.js'
const columns = [
	{
		title: "序号",
		width: 65,
		scopedSlots: { customRender: "index" },
		// customRender: (text, record, index) => `${index + 1}`,
		align: "center",
	},
	{
		title: "历史名称",
		dataIndex: "historyName",
		// width: 180,
		align: "center"
	},

	{
		title: "新名称",
		dataIndex: "newName",
		align: "center",
		// width: 180
	},
	{
		title: "信息部变更时间",
		dataIndex: "nameUpdateDate",
		align: "center",
		width: 250,
    customRender: (text) => useRender.renderDate(text)
	},
	{
		title: "同步时间",
		dataIndex: "createDate",
		align: "center",
		width: 250,
    customRender: (text) => useRender.renderDate(text)
	},
	// {
	// 	title: "操作",
	// 	align: "center",
	// 	width: 130,
	// 	scopedSlots: { customRender: "action" },
	// },
];
export default {
	components: {
		StandardTable,
	},
	data() {
		return {
			columns: columns,
			dataSource: [], //table数组
			pagination: {
				//分页数据
				current: 1,
				pageSize: 10,
				total: 0,
				showTotal: (total, range) => `共 ${total} 条`,
			},
			params: {
				pageNumber: 1,
				pageSize: 10,
			},
			tableLoading: false, //table加载中
		};
	},
	computed: {
		...mapGetters('account', ['user']),
	},
	activated() {
		this.init()
	},
	methods: {
		init() {
			this.aqxjlist(this.params);
		},
		aqxjlist(params) {
			this.tableLoading = true;
			getProjectNameHistoryInfo(params).then((res) => {
				if (res.data.code === 0) {
					this.dataSource = res.data.data.list;
					this.pagination.total = res.data.data.total;
				} else {
					this.$message.error(res.data.msg)
				}
				this.tableLoading = false;
			});
		},
		//表格 - 分页查询
		onPageChange(e) {
			this.params.pageNumber = e.current;
			this.pagination.current = e.current;
			this.aqxjlist(Object.assign({}, this.params));
		},
	},
};
</script>

<style lang="less" scoped>
.a_a_footer {
	padding: 20px;
	background: #ffffff;

	.operator {
		margin-right: 8px;
	}

	.sTables {
		display: flex;
		justify-content: space-between;
		align-items: center;

		div {
			width: 100%;
			text-align: center;
			cursor: pointer;
		}

		div:nth-child(1) {
			color: #307dfa;
		}

		div:nth-child(2) {
			color: #ff5c77;
		}

		div:nth-child(3) {
			color: #615e83;
		}
	}
}
.content_div {
	padding: 5px 0;
	cursor: pointer;
}
.content_div:hover {
	color: #307dfa;
}
.plug-Overview {
	font-size: 16px;
	color: #101010;
	font-weight: bold;
}
</style>
