<template>
  <div>
    <a-drawer
      :width="800"
      :visible="visible"
      title="编辑课程信息"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose"
    > 
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }" layout="horizontal">
       <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="课程分类：">
              <a-select
                :allowClear="true"
                style="width: 100%"
                placeholder="请选择课程分类："
                v-decorator="['typeId', { rules: [{ required: true, message: '请选择课程分类：' }] }]">
                <a-select-option v-for="t in typeCourse" :key="t.id" :value="t.id">{{t.name}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="课程名称：">
              <a-input
                v-decorator="['name', { rules: [{ required: true, message: '请输入课程名称' }] }]"
               placeholder="请输入课程名称"/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="课程编码：">
              <a-input
                v-decorator="['core', { rules: [{ required: true, message: '请输入课程编码' }] }]"
               placeholder="请输入课程编码"/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="12">
          <a-col :span="24">
            <a-form-item label="课程封面：">
              <a-upload
                  name="file"
                  :multiple="false"
                  :beforeUpload="beforeUpload"
                  accept=".png,.PNG,.jpg,.JPG"
                  :customRequest="resourceUpload"
                  :file-list="fileList"
                  @change="handleChange"
              >
                  <a-button :loading="iconLoading" style="margin-left:10px" type="primary" >上传封面</a-button>
                  <div style="margin-top:10px"><img style="max-width:200px;max-height:100px" :src="filePath" /></div>
              </a-upload>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="课程价格">
                <a-input-number
                v-decorator="['price', { rules: [{ required: true, message: '请输入课程价格' }],initialValue:'0' }]"
                :min="0"
                :formatter="value => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                @change="onChange"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="12">
          <a-col :span="24">
            <a-form-item label="课件资料：">
              <a-upload
                  name="file"
                  :multiple="false"
                  :beforeUpload="beforeUpload"
                  accept=".pdf,.PDF"
                  :customRequest="resourceUpload1"
                  :file-list="fileList1"
                  @change="handleChange"
              >
                  <a-button :loading="iconLoading1" style="margin-left:10px" type="primary" >上传课件</a-button>
                  <!-- <div style="margin-top:20px"><img style="max-width:200px;max-height:100px" :src="filePath" /></div> -->
              </a-upload>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="是否公开">
              <a-radio-group v-model="isPublic">
                <a-radio :value="0">
                  仅限公司内部
                </a-radio>
                <a-radio :value="1">
                  公开至平台
                </a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row>
        <!-- <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="教育类型">
              <a-select v-model="isThreeEdu">
                <a-select-option :value="0">
                  都不是
                </a-select-option>
                <a-select-option :value="1">
                  一级安全教育
                </a-select-option>
                <a-select-option :value="2">
                  二级安全教育
                </a-select-option>
                <a-select-option :value="3">
                  三级安全教育
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row> -->
        <!-- <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="课程标签">
              
            </a-form-item>
          </a-col>
        </a-row> -->
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="课程简介">
               <!-- <a-textarea
                   v-decorator="['courseCont', { rules: [{  message: '课程简介' }] }]"
                  placeholder="课程简介"
                  :auto-size="{ minRows: 3 }"
                /> -->
                <keep-alive>
                  <tinymce v-if="visible" ref="tinymceEditor" :value="obj.courseCont"  @input="contentInput"></tinymce>
                </keep-alive>
            </a-form-item>
          </a-col>
        </a-row>
    </a-form>
    <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginLeft: '8px' }" type="primary" @click="doSubmit">
          确定
        </a-button>
        <a-button :style="{ marginLeft: '8px' }" @click="onClose">
          关闭
        </a-button>
      </div>
    </a-drawer>
    
  </div>
</template>
<script>
import { request } from "@/utils/request";
import Tinymce from "@/components/tinymce"
import { ATTACHMENT_UPLOAD,ONLINE_QUERY_COURSE_CHECKTHREEEDU} from "@/services/api";
export default {
  name: "teamEditor",
  components: {Tinymce},
  data() {
    return {
      form: this.$form.createForm(this),
      isPublic:0,
      isThreeEdu:0,
      fileList:[],
      filePath:null,
      iconLoading:false,
      fileList1:[],
      filePath1:null,
      iconLoading1:false,
      obj:{},

    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    course:{
      type: Object,
      require:true
    },
    typeCourse:{
      type:Array,
      require:true
    }
  },
  created(){
    
  },
  mounted(){
  },
  watch:{
    visible:{
       handler(val, oldName) {
          if(val&&this.course.id){
            this.obj = this.course;
            setTimeout(()=>{
              this.form.setFieldsValue({
                'name': this.obj.name,
                'core': this.obj.core,
                'price': this.obj.price,
                'courseCont': this.obj.courseCont,
                'typeId': this.obj.typeId?(this.obj.typeId+""):"",
                
              })
              this.isPublic = this.obj.isPublic
              this.isThreeEdu=this.obj.isThreeEdu?this.obj.isThreeEdu:0
              this.filePath = this.obj.picPath;
              if(this.filePath){
                var fileObj = {
                  uid: 'kcfm-001',
                  name: "课程封面图",
                  url: this.filePath,
                }
                this.fileList.push(fileObj)
              }else{
                this.fileList=[];
              }
              if(this.obj.kjFilePath){
                var fileObj1 = {
                  uid: 'kjzl-001',
                  name: "课程课件资料",
                  url: this.obj.kjFilePath,
                }
                this.fileList1.push(fileObj1)
              }else{
                this.fileList1 = [];
              }
            })
          }else{
            this.form.resetFields();
            this.form.setFieldsValue({
              'typeId': this.course.typeId+"",
              'isThreeEdu':0
            })
            this.filePath=null;
            this.fileList = [];
            this.fileList1 = [];
          }
       }
      
    }
  },
  methods: {
    onChange(){

    },
    handleChange(info){
      if(info.file.uid=='kcfm-001'){
        let fileList = [...info.fileList];
        this.fileList = fileList;
        this.filePath = null;
      }
      if(info.file.uid=='kjzl-001'){
        let fileList = [...info.fileList];
        this.fileList1 = fileList;
      }
    },
    doDetail(){
      
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },
    onClose(){
      this.$emit('coursePageClose');
    },
    contentInput(e){
      this.obj.courseCont = e;
    },
    changeThreeEdu(e){
      let chooseValue = e.target.value;
      if(chooseValue&&chooseValue==1){
        request(ONLINE_QUERY_COURSE_CHECKTHREEEDU+chooseValue,"get",{}).then(res => {
            if(res.data.code==0){
              if(res.data.data){
                this.$message.warning("您公司已经设置了课程【"+res.data.msg+"】为三级安全教育课程,请您先将其取消再设置.")
                this.isThreeEdu=0;
                return false;
              }
            }else{
              this.$message.error("系统异常...");
            }
        })
      }
      
    },
    doSubmit(){
       //数据校验
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(this.obj);
          if(this.obj&&this.obj.id){
            values.id = this.obj.id
          }
          if(!values.typeId){
            values.typeId=this.course.typeId;
          }
          
          values.isPublic=this.isPublic;
          values.isThreeEdu=this.isThreeEdu;
          values.picPath=this.filePath
          this.$set(values,"courseCont",this.obj.courseCont);
          if(this.fileList1&&this.fileList1.length>0){
            this.$set(values,"kjFilePath",this.fileList1[0].url);
          }
          this.$emit('courseSubmit',{course:values})
        }
      })
    },
    /** 
    * 上传之前
    */
    beforeUpload (file) {
      return new Promise((resolve, reject) => {
        // const isFiveM= file.size / 1024 / 1024 < 10 //小于5M的图片上传
        // //判断文件是否符合正则表达式的规则
        // if (!isFiveM) {
        //   this.$message.error('上传资源不大于10M！');
        //   return reject(false)
        // }else{
          return resolve(true);
        // }
      });
    },
    /**
    * 资源上传
    */
    resourceUpload(value){
      this.iconLoading = true;
      const formData = new FormData();
      formData.append('file', value.file);
      request(ATTACHMENT_UPLOAD,"post",formData).then(result => {
          if (result.data.code==0) {
            var fileObj = {
              uid: '1',
              name: result.data.data.name,
              url: result.data.data.path,
            }
            this.filePath = result.data.data.path;
            this.fileList = [];
            this.fileList.push(fileObj);
            value.onSuccess(result, value.file);
          }else{
            this.$message.warning(result.message);
          }
          this.iconLoading = false;
      })
    },
    /**
    * 课件资源上传
    */
    resourceUpload1(value){
      this.iconLoading1 = true;
      const formData = new FormData();
      formData.append('file', value.file);
      request(ATTACHMENT_UPLOAD,"post",formData).then(result => {
          if (result.data.code==0) {
            var fileObj = {
              uid: 'kjzl-001',
              name: result.data.data.name,
              url: result.data.data.path,
            }
            this.fileList1 = [];
            this.fileList1.push(fileObj);
            value.onSuccess(result, value.file);
          }else{
            this.$message.warning(result.message);
          }
          this.iconLoading1 = false;
      })
    },
  }
};
</script>
<style>
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>