<template>
  <div class="a_Approval">
    <a-card title="预警设置" :bordered="false">
      <a-row>
        <a-col :span="24">
          <div
            class="flex a-center"
            @click="addUserNotify"
            style="width: 100px"
          >
            <a-icon class="iconStyle" type="plus-circle" />
            <span class="font-16">新增</span>
          </div>
        </a-col>
        <a-col :span="24" v-for="(item, index) in alertList" :key="index">
          <div class="flex a-center mt-2">
            <div class="setting-item flex a-center">
              <a-form-model layout="inline">
                <a-form-model-item :colon="false" label="通知人">
                  <!-- <a-select
                      v-model="item.user"
                      placeholder="请选择民族"
                      style="width: 200px"
                      @change="handlSelect($event)"
                      allowClear
                      >
                    <a-select-option
                      v-for="items of userList"
                      :key="items"
                      :value="items"
                    >
                      {{ items }}
                    </a-select-option>
                  </a-select> -->
                  <!-- @change="onChange" -->

                  <!-- <a-auto-complete
                    v-model="item.userId"
                    :disabled="item.edit"
                    :data-source="dataSource"
                    placeholder="请输入手机号"
                    @select="onSelect($event, index)"
                    @search="onSearch"
                  >
                    <template slot="dataSource">
                      <a-select-option
                        v-for="items in dataSource"
                        :key="items.id"
                        :title="items.name"
                      >
                        <span className="global-search-item-count"
                          >{{ items.name }}</span
                        >
                      </a-select-option>
                    </template>
                    <template slot="default">
                      <a-input>
                        <a-button
                          :disabled="item.edit" 
                          slot="suffix"
                          style="margin-right: -12px"
                          class="search-btn"
                          type="primary"
                        >
                          <a-icon type="search" />
                        </a-button>
                      </a-input>
                    </template>
                  </a-auto-complete> -->
                  <a-input-search
                    placeholder="请输入手机号"
                    :disabled="item.edit"
                    enter-button
                    @search="onSearch($event, index)"
                    v-model="item.phone"
                  >
                    <!-- <a-select style="width:100px" slot="addonBefore">
                      <a-select-option
                        v-for="items of dataSource"
                        :key="items.id"
                        :value="items.value"
                      >
                        {{ items.name }}
                      </a-select-option>
                    </a-select> -->
                  </a-input-search>
                </a-form-model-item>
                <a-form-model-item :colon="false" label="短信模板">
                  <a-radio-group
                    @change="onRadio($event, index)"
                    :disabled="item.edit"
                    v-model="item.smsCode"
                  >
                    <a-radio
                      v-for="items of dataNotify"
                      :key="items.smsCode"
                      :value="Number(items.smsCode)"
                    >
                      {{ items.smsName }}
                    </a-radio>
                  </a-radio-group>
                </a-form-model-item>
              </a-form-model>
            </div>
            <a-space>
              <a-button
                v-if="item.edit === true"
                type="primary"
                @click="onEdit(item, index)"
                >编辑</a-button
              >
              <a-button v-else type="primary" @click="onEdit(item, index)"
                >保存</a-button
              >
              <a-button type="danger" @click="onDelete(item, index)"
                >删除</a-button
              >
            </a-space>
            <a-space> </a-space>
          </div>
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import {
  getNotifyList,
  getList,
  getUserPhone,
  editNotify,
  delNotify
} from "@/services/alert";
import { isphone } from "@/utils/util";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      form: {},
      alertList: [
        {
          edit: true,
          userId: "",
          phone: "",
          projectId: "",
          officeId: "",
          userType: "",
          smsCode: "",
        },
      ], // 预警模板数组
      dataSource: [],
      dataNotify: [],
    };
  },

  mounted() {
    this.getNotifyList();
    this.getList();
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  methods: {
    getNotifyList() {
      getNotifyList().then((res) => {
        if (res.data.code === 0) {
          this.dataNotify = res.data.data;
        }
      });
    },
    getList() {
      let params = {
        projectId: this.user.projectId,
      };
      getList(params).then((res) => {
        if (res.data.code === 0) {
          console.log(res.data.data, "获取列表");
          if (res.data.data.length) {
            res.data.data.map((item, index) => {
              // 最后一项或者第一项都是需要保存的
              if (res.data.data.length == index + 1) {
                item.edit = false;
              } else {
                item.edit = true;
              }
            });
          }
          this.alertList = res.data.data;
          console.log(this.alertList, "this.alertList");
        }
      });
    },
    handlSelect(e) {
      console.log(e, "e===》》》");
    },
    // 新增通知模板
    addUserNotify() {
      let isAdd =
        this.alertList.every((item) => item.edit == true) ||
        this.alertList.length == 0;
      if (isAdd) {
        let obj = {
          edit: false,
          userId: "",
          phone: "",
          projectId: this.user.projectId,
          officeId: this.user.officeId,
          userType: "",
          smsCode: "",
        };
        editNotify(obj).then((res) => {
          console.log(res, "新增成功");
          this.getList(); // 重新获取列表
        });
        // this.alertList.push(obj);
        this.$message.success("新增成功", 10);
      } else {
        this.$message.error("新增失败,请先保存前一项", 10);
      }
    },
    onEdit(item, index) {
      console.log(item, index);
      if (this.alertList[index].edit == true) {
        // 编辑操作
        this.alertList[index].edit = false;
      } else {
        // 保存操作  +  METHOD.DELETE
        this.alertList[index].edit = true;
        let isSave = item.userId && item.smsCode && item.phone;
        if (isSave) {
          editNotify(item).then((res) => {
            console.log(res.data);
            if (res.data.code === 0) this.$message.success("保存成功");
          });
        } else {
          item.phone = ''
          item.smsCode = ''
          this.$message.error("保存失败，请正确填写通知人和短信模板");
          
        }
      }
    },
    onDelete(item, index) {
      console.log(item, index);
      if (this.alertList.length == 1) {
        this.$message.error("已经是最后一项了，不能再删除了");
        return false;
      }
      this.confirm("删除", "是否删除").then(() => {
        delNotify({id:item.id}).then(res => {
          if (res.data.code === 0) {
            this.$message.success("删除成功");
            this.getList();
          } 
        })
      });
    },
    onSearch(value, i) {
      console.log(value, "value");
      console.log(i, "e");
      console.log(isphone(value), "isphone(value)");

      if (isphone(value)) {
        this.dataSource = [];
        let params = {
          phone: value,
          // projectId: this.user.projectId
        };
        getUserPhone(params).then((res) => {
          console.log(res, "res");
          if (res.data.code === 0) {
            // let obj = {
            //   id: res.data.data.id,
            //   name: res.data.data.name,
            // };
            // this.dataSource.push(obj);
            // 修改绑定的用户Id和userType
            this.alertList[i].userId = res.data.data.id;
            this.alertList[i].userType = res.data.data.userType;
            this.$message.success("绑定通知人成功");
          } else if (res.data.code == -1) {
            this.$message.error(res.data.msg);
          }
        });
      } else {
        this.$message.error("请输入正确的手机号");
      }
    },
    onSelect(e, value) {
      console.log("e", e);
      console.log("onSelect", value);
    },
    onChange(value) {
      console.log("onChange", value);
    },
    // 封装弹窗确定按钮
    confirm(title, content) {
      return new Promise((resolve, reject) => {
        this.$confirm({
          title: title,
          content: content,
          onOk() {
            resolve();
          },
        });
      });
    },
    onRadio(e, index) {
      this.alertList[index].smsCode = e.target.value;
      console.log(this.alertList[index], "修改了模板");
      console.log(this.alertList[index].smsCode, "修改了模板code");
    },
  },
};
</script>

<style lang="less" scoped>
.a_Approval {
  border-radius: 0 10px 10px;
  padding: 0 20px 0 20px;
  min-height: 555px;
  background: #fff;
  /deep/ .ant-card-head-title {
    font-weight: bold;
    font-size: 20px;
  }
  /deep/ .ant-card-head {
    padding: 0 24px 0 12px;
    border-bottom: none;
  }
  /deep/ .ant-card-body {
    padding: 0 24px 24px 24px;
  }
  .setting-item {
    width: 800px;
    height: 70px;
    background: #f5f5f5;
    padding: 10px 10px 10px 50px;
    margin-right: 30px;

    /deep/ .ant-form-item-label > label {
      font-size: 16px;
      color: #a1a9bc;
      margin-right: 8px;
    }
    .ant-form-inline .ant-form-item:first-child {
      margin-right: 80px;
    }
  }
  .iconStyle {
    margin-right: 6px;
    font-size: 24px;
  }
}
</style>
