<template>
  <div>
    <a-drawer
      :width="800"
      :visible="visible"
      title="任务信息编辑"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose"
    > 
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }" layout="horizontal">
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="任务名称">
               <a-input
                v-decorator="['name',{rules: [{ required: true, message: '请输入任务名称' }]}]"
                placeholder="任务名称"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="任务类型">
              <a-radio-group v-decorator="['type']">
                <a-radio-button :value="1">
                  安全教育
                </a-radio-button>
                <a-radio-button :value="2">
                  技术交底
                </a-radio-button>
                <a-radio-button :value="3">
                  其他任务
                </a-radio-button>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="任务课程">
              <a-select
                :allowClear="true"
                style="width: 100%"
                placeholder="请选择课程"
                @change="changeCourse"
                v-decorator="['courseId', { rules: [{ required: true, message: '请选择课程' }] }]">
                <a-select-option v-for="t in courseDataSource" :key="t.id" :value="t.id">{{t.name}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="任务起止时间">
                <a-range-picker @change="onChangeTime" :default-value="[moment(nowMonthDay, monthFormat), moment(nowMonth7Day, monthFormat)]" v-model="inDate"/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="任务描述">
              <a-textarea
                v-decorator="['taskRemark', { rules: [{  message: '任务描述' }] }]"
               placeholder="任务描述"
               :auto-size="{ minRows: 3, maxRows: 5 }"
               />
            </a-form-item>
          </a-col>
        </a-row>
        
    </a-form>
    <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginLeft: '8px' }" type="primary" @click="doSubmit">
          确定
        </a-button>
        <a-button :style="{ marginLeft: '8px' }" @click="onClose">
          关闭
        </a-button>
      </div>
    </a-drawer>
    
  </div>
</template>
<script>
import { request } from "@/utils/request";
import {ONLINE_TASK_COURSE_LIST} from "@/services/api";
import moment from 'moment';
export default {
  name: "taskEditor",
  components: {},
  data() {
    return {
      form: this.$form.createForm(this),
      courseDataSource:[],
      obj:{},
      startTime:null,
      endTime:null,
      monthFormat: 'YYYY-MM-DD',
      nowMonthDay:null,
      nowMonth7Day:null,
      data:[],
      inDate:null
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    task:{
      type: Object
    },
  },
  created(){
    this.nowMonthDay = moment(new Date()).format('YYYY-MM-DD');
    this.nowMonth7Day = moment(new Date()).format('YYYY-MM-DD');
    this.doQueryCourse();
  },
  mounted(){
    
  },
  watch:{
    visible(val){
      if(val){
        this.obj = this.task;
        setTimeout(()=>{
          this.form.setFieldsValue({
            'name': this.obj.name?this.obj.name:'',
            'type': this.obj.type?this.obj.type:1,
            'taskRemark': this.obj.taskRemark,
            'courseId': this.obj.courseId?this.obj.courseId+"":null
          })
        })
      }
    }
  },
  methods: {
    doQueryCourse(){
      request(ONLINE_TASK_COURSE_LIST,"post",{}).then(res => {
          if(res.data.code==0){
           this.courseDataSource = res.data.data;
          }
       })
    },
    onChangeTime(){

    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },
    onClose(){
      this.$emit('taskPageClose');
    },
    doSubmit(){
       //数据校验
      this.form.validateFields((err, values) => {
        if (!err) {
          if(this.obj&&this.obj.id){
            values.id = this.obj.id
          }
          this.$set(values,"type",this.type);
          this.$emit('submit',{task:values})
        }
      })
    },

  }
};
</script>
<style>
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
/deep/ .ant-upload-list-picture-card .ant-upload-list-item{
  width: 127px;
  height: 127px;
}
</style>