var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isExporting
    ? _c("div", { staticClass: "export-loading" }, [
        _c("div", { staticClass: "export-con" }, [
          _c("div", { staticClass: "export-title" }, [
            _vm._v(_vm._s(_vm.loadingText)),
          ]),
          _c(
            "div",
            [
              _c("a-progress", {
                attrs: {
                  strokeWidth: 15,
                  "stroke-linecap": "square",
                  "show-info": false,
                  percent: _vm.percent,
                  status: "active",
                  "stroke-color": {
                    from: "#108ee9",
                    to: "#87d068",
                  },
                },
              }),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }