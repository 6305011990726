var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("form-index", {
        ref: "formindex",
        attrs: {
          formData: _vm.formData,
          projectList: _vm.projectList,
          flexSubmit: true,
        },
        on: {
          handleSubmit: _vm.handleSubmit,
          handlSelect: _vm.handlSelect,
          handleChange: _vm.handleChange,
        },
        scopedSlots: _vm._u([
          {
            key: "flexSubmit",
            fn: function () {
              return [
                _c(
                  "a-space",
                  {
                    staticStyle: { "margin-left": "90px", "margin-top": "5px" },
                  },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: {
                          icon: "search",
                          type: "primary",
                          "html-type": "submit",
                        },
                      },
                      [_vm._v(" 查询 ")]
                    ),
                    _c(
                      "a-space",
                      { staticClass: "operator" },
                      [
                        _c("a-button", { on: { click: _vm.close } }, [
                          _vm._v(" 重置 "),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "a-space",
                      { staticClass: "operator" },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: { icon: "download", type: "primary" },
                            on: { click: _vm.exportExcel },
                          },
                          [_vm._v(" 导出 ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "a_a_footer" },
        [
          _c("standard-table", {
            attrs: {
              columns: _vm.columns,
              dataSource: _vm.dataSource,
              selectedRows: _vm.selectedRows,
              rowKey: function (record) {
                return record.hqTeamVo.id
              },
              pagination: _vm.pagination,
              loading: _vm.tableLoading,
              scroll: { x: 1300 },
              isAlert: false,
              bordered: true,
            },
            on: {
              "update:selectedRows": [
                function ($event) {
                  _vm.selectedRows = $event
                },
                function ($event) {
                  return _vm.updateSelectRows($event)
                },
              ],
              "update:selected-rows": function ($event) {
                _vm.selectedRows = $event
              },
              change: _vm.onPageChange,
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function (ref) {
                  var index = ref.index
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            (_vm.pagination.current - 1) *
                              _vm.pagination.pageSize +
                              parseInt(index) +
                              1
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "id",
                fn: function (ref) {
                  var record = ref.record
                  return [_vm._v(" " + _vm._s(record.hqTeamVo.id) + " ")]
                },
              },
              {
                key: "teamName",
                fn: function (ref) {
                  var record = ref.record
                  return [_vm._v(" " + _vm._s(record.hqTeamVo.teamName) + " ")]
                },
              },
              {
                key: "curator",
                fn: function (ref) {
                  var record = ref.record
                  return [_vm._v(" " + _vm._s(record.hqTeamVo.curator) + " ")]
                },
              },
              {
                key: "curatorPhone",
                fn: function (ref) {
                  var record = ref.record
                  return [
                    _vm._v(" " + _vm._s(record.hqTeamVo.curatorPhone) + " "),
                  ]
                },
              },
              {
                key: "teamType",
                fn: function (ref) {
                  var record = ref.record
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          record.hqTeamVo.teamType == 1
                            ? "企业班组"
                            : record.hqTeamVo.teamType == 2
                            ? "班组合伙人"
                            : "专业班组"
                        )
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "workType",
                fn: function (ref) {
                  var record = ref.record
                  return _vm._l(
                    record.hqTeamWorkContentVoList,
                    function (item) {
                      return _c("div", { key: item.id }, [
                        _vm._v(" " + _vm._s(item.workTypeSelected)),
                      ])
                    }
                  )
                },
              },
              {
                key: "reference",
                fn: function (ref) {
                  var record = ref.record
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          record.hqTeamVo.reference
                            ? record.hqTeamVo.reference
                            : "/"
                        )
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "referenceType",
                fn: function (ref) {
                  var record = ref.record
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          record.hqTeamVo.referenceType == 1
                            ? "工人推广"
                            : record.hqTeamVo.referenceType == 2
                            ? "管理人员推荐"
                            : "自主申请"
                        )
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "applyTime",
                fn: function (ref) {
                  var record = ref.record
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm
                            .moment(record.hqTeamVo.applyTime)
                            .format("YYYY-MM-DD")
                        )
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "status",
                fn: function (ref) {
                  var record = ref.record
                  return [
                    record.hqTeamVo.status == 0
                      ? _c("span", [_vm._v("待提交申请")])
                      : record.hqTeamVo.status == 1
                      ? _c("span", [_vm._v("待审批")])
                      : record.hqTeamVo.status == 2
                      ? _c("span", [_vm._v("审批通过")])
                      : record.hqTeamVo.status == 3
                      ? _c("span", [_vm._v("审批不通过")])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "action",
                fn: function (ref) {
                  var record = ref.record
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "span",
                        {
                          staticStyle: { color: "#615e83", cursor: "pointer" },
                          on: {
                            click: function ($event) {
                              return _vm.visit(record.hqTeamVo)
                            },
                          },
                        },
                        [_vm._v("查看")]
                      ),
                      _c("a-divider", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: record.hqTeamVo.status == 1,
                            expression: "record.hqTeamVo.status == 1",
                          },
                        ],
                        attrs: { type: "vertical" },
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: record.hqTeamVo.status == 1,
                              expression: "record.hqTeamVo.status == 1",
                            },
                          ],
                          staticStyle: { color: "#307dfa", cursor: "pointer" },
                          on: {
                            click: function ($event) {
                              return _vm.passOrReject(record.hqTeamVo, 2)
                            },
                          },
                        },
                        [_vm._v("通过")]
                      ),
                      _c("a-divider", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: record.hqTeamVo.status == 1,
                            expression: "record.hqTeamVo.status == 1",
                          },
                        ],
                        attrs: { type: "vertical" },
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: record.hqTeamVo.status == 1,
                              expression: "record.hqTeamVo.status == 1",
                            },
                          ],
                          staticStyle: { color: "#ff5c77", cursor: "pointer" },
                          on: {
                            click: function ($event) {
                              return _vm.passOrReject(record.hqTeamVo, 3)
                            },
                          },
                        },
                        [_vm._v("驳回")]
                      ),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("Export-loading", {
        attrs: {
          cPercent: _vm.cPercent,
          isExporting: _vm.isExporting,
          loadingText: _vm.loadingText,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }