var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mini-progress" },
    [
      _c("a-tooltip", { attrs: { title: "目标值：" + _vm.target + "%" } }, [
        _c(
          "div",
          { staticClass: "target", style: { left: _vm.target + "%" } },
          [
            _c("span", { style: { backgroundColor: _vm.color } }),
            _c("span", { style: { backgroundColor: _vm.color } }),
          ]
        ),
      ]),
      _c("div", { staticClass: "wrap" }, [
        _c("div", {
          staticClass: "progress",
          style: {
            backgroundColor: _vm.color,
            width: _vm.percent + "%",
            height: _vm.height,
          },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }