<template>

    <div>
        <div>
            <a-row :gutter="16">
                <a-col :span="8">
                    <a-card title="公司结构" size="small" :bordered="false" style="height: 900px" :style="'overflow:auto'">
                        <a-tree
                                :expanded-keys="expandedKeys"
                                :tree-data="officeTreeData"
                                :auto-expand-parent="autoExpandParent"
                                :replaceFields="{children:'children', title:'name', key:'id' }"
                                @expand="onExpand"
                                @select="onSelect"
                        >
                            <template slot="title" slot-scope="{ title }">
                                <span>{{ title }}</span>
                            </template>
                        </a-tree>
                    </a-card>
                </a-col>
                <a-col :span="15" :offset="1">
                    <a-card title="子公司" :bordered="false">
<!--                        <a-button type="primary" @click="addOffice(parentOfficeId)">-->
<!--                            新增-->
<!--                        </a-button>-->
                        <a-table
                                :columns="columns"
                                :data-source="officeData"
                                @expand="expand">

                            <template slot="action" slot-scope="text, record">
                                <span>
                                  <a @click="showModal(record)">查看</a>
                                  <a-divider type="vertical" />
                                  <a @click="showModal(record)">修改</a>
                                  <a-divider type="vertical" />
                                  <a @click="delOffice(record)">删除</a>
                                  <a-divider type="vertical" />
                                  <a @click="addOffice(record.id)">添加下级</a>
                                </span>
                            </template>
                        </a-table>
                    </a-card>
                </a-col>
            </a-row>
        </div>

        <div>
            <a-modal
                    :title="title"
                    :visible="visible"
                    :confirm-loading="confirmLoading"
                    @ok="modelOk"
                    @cancel="modelCancel"
                    width="560px"
            >
                <a-form-model :model="form"
                              :label-col="labelCol"
                              :wrapper-col="wrapperCol"
                              ref="ruleForm"
                              :rules="rules"
                              v-if="visible"
                >
                    <a-form-model-item label="上级单位">
                        <a-tree-select
                                style="width: 100%"
                                v-model="form.parentId"
                                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                :tree-data="officeTreeData"
                                placeholder="请选择直属上级单位"
                                tree-default-expand-all
                                :replaceFields="{children:'children', title:'name', key:'id', value:'id' }"
                                :treeDefaultExpandAll="false"
                                @select="officeSelect"
                        >
                        </a-tree-select>
                    </a-form-model-item>
                    <a-form-model-item label="统一社会信用码" ref="code" prop="code"  required>
                        <a-input v-model="form.code" />
                    </a-form-model-item>
                    <a-form-model-item label="单位名称" ref="name" prop="name" required>
                        <a-input v-model="form.name" />
                    </a-form-model-item>
                    <a-form-model-item label="负责人" ref="master" prop="master" required>
                        <a-input v-model="form.master" />
                    </a-form-model-item>
                    <a-form-model-item label="联系电话">
                        <a-input v-model="form.phone" />
                    </a-form-model-item>
                    <a-form-model-item label="单位类型">
                        <a-select
                                  v-model="form.companyTypeName"
                                  placeholder="请选择单位类型"
                        >
                            <a-select-option v-for="(item, index) in typeMap.companyTypeList" :key="index" :value="item.value">
                                {{ item.label }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="单位地址">
                        <a-input v-model="form.address" />
                    </a-form-model-item>
                    <a-form-model-item label="专业分包类型">
                        <a-select
                                  v-model="form.companyMiniType"
                                  placeholder="请选择分包类型"
                        >
                            <a-select-option v-for="(item, index) in typeMap.companyMiniTypeList" :key="index" :value="item.value">
                                {{ item.label }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="单位归属">
                        <a-select
                                  v-model="form.undergroup"
                                  placeholder="请选择单位归属"
                        >
                            <a-select-option v-for="(item, index) in typeMap.underGroupList" :key="index" :value="item.value">
                                {{ item.label }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="备注">
                        <a-input v-model="form.remarks" />
                    </a-form-model-item>
                </a-form-model>

            </a-modal>
        </div>
    </div>
</template>

<script>
    import {getOfficeTree, getOfficeTypeList, submitOffice, delOffice} from '@/services/sys'
    const columns = [
        {
            title: '单位名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '负责人',
            dataIndex: 'master',
            key: 'master',
        },
        {
            title: '单位类型',
            dataIndex: 'companyTypeName',
            key: 'companyTypeName',
        },
        {
            title: '联系电话',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: '地址',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            scopedSlots: { customRender: 'action' }
        },
    ];


    export default {
        data() {
            return {

                // 树形结构
                expandedKeys: [],
                autoExpandParent: true,
                officeTreeData: [],

                // 数据表格
                columns,
                officeData: [],
                parentOfficeId: undefined,
                expandedRowKeys: [],

                // 详细数据弹窗
                title: "编辑",
                visible: false,
                confirmLoading: false,
                labelCol: { span: 6 },
                wrapperCol: { span: 12 },
                form:{
                    parentId: undefined,
                },
                typeMap: {
                    underGroupList: [],
                    companyMiniTypeList: [],
                    companyTypeList: []
                },
                rules: {
                    name: [
                        { required: true, message: '请输入单位名称', trigger: 'blur' },
                    ],
                    code: [{ required: true, message: '请输入单位名称统一社会信用代码', trigger: 'blur' }],
                    master: [{ required: true, message: '请输入负责人', trigger: 'blur' }],
                },
            };
        },

        created() {
            this.initData()
        },
        methods: {
            onExpand(expandedKeys) {
                this.expandedKeys = expandedKeys;
                this.autoExpandParent = false;
                console.log("sue", expandedKeys)
            },
            onSelect(selectedKeys, info) {
                console.log('selected', selectedKeys, info);
                this.parentOfficeId = selectedKeys[0];
                this.parentData();
            },
            initData() {
                getOfficeTree({parentOfficeId: ""}).then(res => {
                    this.officeTreeData = res.data.data
                })

                getOfficeTypeList().then( res =>{
                    this.typeMap.underGroupList = res.data.data.underGroupList
                    this.typeMap.companyMiniTypeList = res.data.data.companyMiniTypeList
                    this.typeMap.companyTypeList = res.data.data.companyTypeList

                })
            },
            parentData() {
                if (this.parentOfficeId == undefined || this.parentOfficeId == ""){
                    this.officeData = []
                    return
                }
                getOfficeTree({parentOfficeId: this.parentOfficeId}).then(res => {
                    this.officeData = res.data.data
                })
            },
            expand(expanded, record){
                console.log(expanded, record)
            },

            showModal(record) {
                this.visible = true;
                this.form = record;
            },
            modelOk(e) {
                this.confirmLoading = true;
                this.$refs.ruleForm.validate(valid => {
                    if (valid) {
                        this.submit();
                    } else {
                        this.$message.error("请检查必填信息");
                        this.confirmLoading = false;
                        return false;
                    }
                });


            },
            modelCancel(e) {
                this.visible = false;
                this.form = {parentId:undefined}
            },
            officeSelect(value, node, extra){
                console.log(value, node, extra)
                this.form.parentId = value
            },
            submit(){
                submitOffice(this.form).then(res => {
                    this.$message.info(res.data.msg);
                    this.parentData()
                    this.visible = false;
                    this.confirmLoading = false;
                })
            },
            addOffice(parentOfficeId){
                console.log(parentOfficeId)
                this.form = {
                    parentId: parentOfficeId
                }
                this.showModal(this.form)
            },
            delOffice(record){
                var _that = this
                this.$confirm({
                    title: '确认删除' + record.name + "?",
                    content: '要删除该机构及所有子机构项吗？',
                    okText: '删除',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                        delOffice({officeId: record.id}).then(res => {
                            _that.$message.info(res.data.msg);
                        })
                        _that.parentData()
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            }

        },
    };
</script>