var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.$route.meta.showFather,
              expression: "$route.meta.showFather",
            },
          ],
          staticClass: "a_Approval",
        },
        [
          _c(
            "a-tabs",
            { attrs: { "default-active-key": _vm.defaultActiveKey } },
            [
              _c(
                "a-tab-pane",
                { key: "1", attrs: { tab: "集团标准化检评情况" } },
                [_c("group-project")],
                1
              ),
              _c(
                "a-tab-pane",
                { key: "2", attrs: { tab: "投诉" } },
                [_c("group-project-complaint")],
                1
              ),
              _c(
                "a-tab-pane",
                { key: "3", attrs: { tab: "约谈" } },
                [_c("group-project-interview")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }