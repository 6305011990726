var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    [
      _c(
        "div",
        { class: _vm.advanced ? "search" : null },
        [
          _c("a-form", { attrs: { layout: "horizontal" } }, [
            _c(
              "div",
              { class: _vm.advanced ? null : "fold" },
              [
                _c(
                  "a-row",
                  [
                    _c(
                      "a-col",
                      { attrs: { md: 8, sm: 24 } },
                      [
                        _c(
                          "a-form-item",
                          {
                            attrs: {
                              labelCol: { span: 5 },
                              wrapperCol: { span: 18, offset: 1 },
                              label: "项目所属公司",
                            },
                          },
                          [
                            _c("a-tree-select", {
                              staticStyle: { width: "80%" },
                              attrs: {
                                "dropdown-style": {
                                  maxHeight: "400px",
                                  overflow: "auto",
                                },
                                "tree-data": _vm.officeTreeData,
                                placeholder: "请选择公司",
                                "tree-default-expand-all": "",
                                replaceFields: {
                                  children: "children",
                                  title: "name",
                                  key: "id",
                                  value: "id",
                                },
                                treeDefaultExpandAll: false,
                                allowClear: true,
                              },
                              on: { select: _vm.currentOfficeId },
                              model: {
                                value: _vm.queryParams.projectOfficeId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryParams,
                                    "projectOfficeId",
                                    $$v
                                  )
                                },
                                expression: "queryParams.projectOfficeId",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a-col",
                      { attrs: { md: 8, sm: 24 } },
                      [
                        _c(
                          "a-form-item",
                          {
                            attrs: {
                              label: "检查时间",
                              labelCol: { span: 5 },
                              wrapperCol: { span: 18, offset: 1 },
                            },
                          },
                          [
                            _c("a-range-picker", {
                              attrs: { format: _vm.dateFormat },
                              on: { change: _vm.changeDate },
                              model: {
                                value: _vm.queryParams.plan_date,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryParams, "plan_date", $$v)
                                },
                                expression: "queryParams.plan_date",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a-col",
                      { attrs: { md: 8, sm: 24 } },
                      [
                        _c(
                          "a-form-item",
                          {
                            attrs: {
                              label: "项目名称",
                              labelCol: { span: 5 },
                              wrapperCol: { span: 18, offset: 1 },
                            },
                          },
                          [
                            _c("a-input", {
                              model: {
                                value: _vm.queryParams.projectName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryParams, "projectName", $$v)
                                },
                                expression: "queryParams.projectName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-row",
                  [
                    _c(
                      "a-col",
                      { attrs: { md: 8, sm: 24 } },
                      [
                        _c(
                          "a-form-item",
                          {
                            attrs: {
                              label: "检查类型",
                              labelCol: { span: 5 },
                              wrapperCol: { span: 18, offset: 1 },
                            },
                          },
                          [
                            _c(
                              "a-select",
                              {
                                model: {
                                  value: _vm.queryParams.planType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryParams, "planType", $$v)
                                  },
                                  expression: "queryParams.planType",
                                },
                              },
                              [
                                _c(
                                  "a-select-option",
                                  { attrs: { value: "1" } },
                                  [_vm._v(" 公司常规检查 ")]
                                ),
                                _c(
                                  "a-select-option",
                                  { attrs: { value: "3" } },
                                  [_vm._v(" 带班检查 ")]
                                ),
                                _c(
                                  "a-select-option",
                                  { attrs: { value: "4" } },
                                  [_vm._v(" 停工处罚 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a-col",
                      { attrs: { md: 8, sm: 24 } },
                      [
                        _c(
                          "a-form-item",
                          {
                            attrs: {
                              label: "检查状态",
                              labelCol: { span: 5 },
                              wrapperCol: { span: 18, offset: 1 },
                            },
                          },
                          [
                            _c(
                              "a-select",
                              {
                                model: {
                                  value: _vm.queryParams.status,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryParams, "status", $$v)
                                  },
                                  expression: "queryParams.status",
                                },
                              },
                              [
                                _c(
                                  "a-select-option",
                                  { attrs: { value: "0" } },
                                  [_vm._v(" 待检查/待处理 ")]
                                ),
                                _c(
                                  "a-select-option",
                                  { attrs: { value: "1" } },
                                  [_vm._v(" 待复核 ")]
                                ),
                                _c(
                                  "a-select-option",
                                  { attrs: { value: "3" } },
                                  [_vm._v(" 整改中 ")]
                                ),
                                _c(
                                  "a-select-option",
                                  { attrs: { value: "2" } },
                                  [_vm._v(" 已完成 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "span",
              { staticStyle: { float: "right", "margin-top": "3px" } },
              [
                _c(
                  "a-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.doQuery()
                      },
                    },
                  },
                  [_vm._v("查询")]
                ),
                _c(
                  "a-button",
                  {
                    staticStyle: { "margin-left": "8px" },
                    on: {
                      click: function ($event) {
                        return _vm.doReset()
                      },
                    },
                  },
                  [_vm._v("重置")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "standard-table",
        {
          attrs: {
            columns: _vm.columns,
            dataSource: _vm.dataSource,
            selectedRows: _vm.selectedRows,
            pagination: _vm.pagination,
            loading: _vm.tableLoading,
            isAlert: false,
          },
          on: {
            "update:selectedRows": function ($event) {
              _vm.selectedRows = $event
            },
            "update:selected-rows": function ($event) {
              _vm.selectedRows = $event
            },
            clear: _vm.onClear,
            change: _vm.onPageChange,
          },
          scopedSlots: _vm._u([
            {
              key: "planType",
              fn: function (ref) {
                var text = ref.text
                return _c("div", {}, [
                  text == "4"
                    ? _c("div", [_vm._v("停工处罚")])
                    : text == "3"
                    ? _c("div", [_vm._v("带班检查")])
                    : _c("div", [_vm._v("公司常规检查")]),
                ])
              },
            },
            {
              key: "do-status",
              fn: function (ref) {
                var record = ref.record
                var text = ref.text
                return _c("div", {}, [
                  record.planType == 4 && text == 0
                    ? _c("label", [_vm._v("待处理")])
                    : _vm._e(),
                  (record.planType == 1 || record.planType == 3) && text == 0
                    ? _c("label", [_vm._v("待检查")])
                    : _vm._e(),
                  text == 1 ? _c("label", [_vm._v("待复核")]) : _vm._e(),
                  text == 2 ? _c("label", [_vm._v("已完成")]) : _vm._e(),
                  text == 3 ? _c("label", [_vm._v("整改中")]) : _vm._e(),
                ])
              },
            },
            {
              key: "action",
              fn: function (ref) {
                var record = ref.record
                return _c("div", {}, [
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.dowLoadFile(record)
                        },
                      },
                    },
                    [_vm._v("下载文件")]
                  ),
                ])
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "statusTitle" },
            [
              _c("a-icon", {
                attrs: { type: "info-circle" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onStatusTitleClick($event)
                  },
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _c("Export-loading", {
        attrs: {
          cPercent: _vm.cPercent,
          isExporting: _vm.isExporting,
          loadingText: _vm.loadingText,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }