var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home_container" },
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 8] } },
        [
          _c("a-col", { attrs: { span: 16 } }, [
            _c(
              "div",
              { staticClass: "public", staticStyle: { "padding-top": "24px" } },
              [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c("div", { staticClass: "relative" }, [
                      _c("img", {
                        staticClass: "wran-img",
                        attrs: {
                          src: require("@/assets/img/icon-jw-warning.png"),
                          alt: "",
                        },
                      }),
                      _c("div", { staticClass: "warning-content white" }, [
                        _c("p", { staticClass: "font-bold-700" }, [
                          _vm._v("建委预警"),
                        ]),
                        _c("p", { staticClass: "font-bold-700 font-30" }, [
                          _vm._v(
                            " " + _vm._s(_vm.noCloseWarnList.length) + " "
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "white pointer" },
                          [
                            _vm._v(" 查看详情"),
                            _c("a-icon", { attrs: { type: "right" } }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c(
                      "vue-scroll",
                      {
                        staticClass: "ml-2",
                        staticStyle: { height: "122px" },
                        attrs: { ops: _vm.ops },
                      },
                      [
                        _c("standard-table", {
                          staticClass: "close-table",
                          attrs: {
                            columns: _vm.columns,
                            dataSource: _vm.noCloseWarnList,
                            rowKey: "id",
                            pagination: false,
                            isAlert: false,
                            bordered: false,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "index",
                              fn: function (ref) {
                                var index = ref.index
                                return [
                                  _c("span", [
                                    _vm._v(
                                      " " + _vm._s(parseInt(index) + 1) + " "
                                    ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "bizType",
                              fn: function (ref) {
                                var text = ref.text
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        text == 1 ? "实名制" : "工资分账"
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "eventName",
                              fn: function (ref) {
                                var text = ref.text
                                return [_vm._v(" " + _vm._s(text) + " ")]
                              },
                            },
                            {
                              key: "warmCode",
                              fn: function (ref) {
                                var text = ref.text
                                var record = ref.record
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "statusDrop flex a-center overtext-1",
                                      class: _vm.textStyle(record),
                                    },
                                    [_vm._v(" " + _vm._s(text) + " ")]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "status",
                              fn: function (ref) {
                                var text = ref.text
                                var record = ref.record
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "statusDrop flex a-center overtext-1",
                                      class: _vm.textStyle(record),
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm._f("statusStr")(text)) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "overtext",
                              fn: function (ref) {
                                var text = ref.text
                                return [
                                  _c("span", { staticClass: "overtext-1" }, [
                                    _vm._v(_vm._s(text)),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c("a-col", { attrs: { span: 8 } }, [
            _c(
              "div",
              [
                _c(
                  "a-row",
                  { attrs: { gutter: [0, 16] } },
                  [
                    _c("a-col", [
                      _c("div", { staticClass: "public" }, [
                        _c("div", { staticClass: "pl-1" }, [
                          _c(
                            "div",
                            {
                              staticClass: "black font-16 font-bold overtext-1",
                            },
                            [_vm._v(" " + _vm._s(_vm.officeData.name) + " ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "flex",
                              staticStyle: {
                                "padding-top": "17px",
                                "padding-bottom": "6px",
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "home-project",
                                attrs: {
                                  src: require("@/assets/img/home-project.png"),
                                  alt: "",
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "flex-1 pl-2",
                                  staticStyle: {
                                    "line-height": "20px",
                                    "font-size": "14px",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex overtext-1" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "black mr-2" },
                                        [_vm._v("公司编码")]
                                      ),
                                      _c("span", { staticClass: "lightgray" }, [
                                        _vm._v(_vm._s(_vm.officeData.code)),
                                      ]),
                                    ]
                                  ),
                                  _c("div", { staticClass: "flex py-1" }, [
                                    _c("span", { staticClass: "black mr-2" }, [
                                      _vm._v("负责人"),
                                    ]),
                                    _c("span", { staticClass: "lightgray" }, [
                                      _vm._v(_vm._s(_vm.officeData.master)),
                                    ]),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "flex" },
                                    [
                                      _c("div", { staticClass: "flex-1" }, [
                                        _c(
                                          "span",
                                          { staticClass: "black mr-2" },
                                          [_vm._v("联系电话")]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "lightgray" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.officeData.userPhone)
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "pointer",
                                          staticStyle: { color: "#0874f0" },
                                          attrs: { to: { name: "企业概况" } },
                                        },
                                        [
                                          _vm._v("查看详情"),
                                          _c("a-icon", {
                                            attrs: { type: "right" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            [
              _c(
                "a-card",
                {
                  staticClass: "project-card",
                  attrs: { bordered: false },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("span", { staticClass: "font-16  black" }, [
                            _vm._v("项目数据"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "div",
                    { staticClass: "relative" },
                    [
                      _c(
                        "baidu-map",
                        {
                          staticClass: "baidu-map",
                          attrs: {
                            "scroll-wheel-zoom": false,
                            center: _vm.location,
                            zoom: _vm.zoom,
                          },
                        },
                        [
                          _c("bm-view", { staticClass: "bmMap" }),
                          _c("bm-navigation", {
                            attrs: { anchor: "BMAP_ANCHOR_BOTTOM_RIGHT" },
                          }),
                          _vm._l(_vm.persons, function (items, index) {
                            return _c(
                              "div",
                              { key: index },
                              [
                                _c(
                                  "bm-marker",
                                  {
                                    attrs: {
                                      position: {
                                        lng: items.lng,
                                        lat: items.lat,
                                      },
                                      icon: {
                                        url: items.show
                                          ? _vm.local2
                                          : items.icon,
                                        size: { width: 28, height: 28 },
                                      },
                                      dragging: false,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.infoWindowOpen(index, items)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "bm-info-window",
                                      {
                                        attrs: { show: items.show },
                                        on: {
                                          close: function ($event) {
                                            return _vm.infoWindowClose(index)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "info-window" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "ellipsis-2" },
                                              [
                                                _vm._v(
                                                  "项目名称： " +
                                                    _vm._s(items.name)
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "line-height": "45px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "工人总数：" +
                                                    _vm._s(
                                                      _vm.staffObj.persons
                                                        ? _vm.staffObj.persons
                                                        : 0
                                                    )
                                                ),
                                              ]
                                            ),
                                            _c("div", [
                                              _vm._v(
                                                "班组总数：" +
                                                  _vm._s(
                                                    _vm.staffObj.teams
                                                      ? _vm.staffObj.teams
                                                      : 0
                                                  )
                                              ),
                                            ]),
                                            _vm.canOperate
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "onInfo",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.onInfo(items)
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("查看详情")]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        { staticClass: "pro-box" },
                        [
                          _c(
                            "a-row",
                            {
                              attrs: { type: "flex", justify: "space-between" },
                            },
                            _vm._l(_vm.proList, function (item, index) {
                              return _c(
                                "a-col",
                                {
                                  key: item.key + index,
                                  staticClass: "tab-box",
                                  class:
                                    _vm.proActive == index ? "pro-active" : "",
                                  on: {
                                    click: function ($event) {
                                      return _vm.onProAction(index, item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { "line-height": "45px" } },
                                    [_vm._v(_vm._s(item.title))]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "font-24",
                                      staticStyle: { "line-height": "20px" },
                                    },
                                    [_vm._v(_vm._s(item.value))]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 16 } },
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 0] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "a-card",
                        {
                          attrs: { bordered: false },
                          scopedSlots: _vm._u([
                            {
                              key: "title",
                              fn: function () {
                                return [
                                  _c("div", { staticClass: "flex font-16" }, [
                                    _c(
                                      "span",
                                      { staticClass: "flex-1 black" },
                                      [_vm._v("管理人员数据")]
                                    ),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _c(
                            "a-row",
                            {
                              attrs: { type: "flex", justify: "space-between" },
                            },
                            [
                              _c("a-col", { staticClass: "ml-35" }, [
                                _c("h4", { staticClass: "black-three" }, [
                                  _vm._v("登记人员"),
                                ]),
                                _c("p", { staticClass: "font-24 black" }, [
                                  _vm._v(
                                    _vm._s(_vm.mangerData.totalTeams || 5)
                                  ),
                                ]),
                                _c("div", { staticClass: "py-1" }, [
                                  _c("h4", { staticClass: "black-three" }, [
                                    _vm._v("今日出勤人数"),
                                  ]),
                                  _c("div", { staticClass: "flex" }, [
                                    _c(
                                      "span",
                                      { staticClass: "font-24 black" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.mangerData.todayTeams)
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c("a-col", { staticClass: "mr-3" }, [
                                _c(
                                  "div",
                                  { staticClass: "flex flex-column a-center" },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/img/icon-line-chart.png"),
                                        alt: "",
                                      },
                                    }),
                                    _c("div", { staticClass: "pt-2" }, [
                                      _c("h4", { staticClass: "black-three" }, [
                                        _vm._v("出勤率"),
                                      ]),
                                      _c(
                                        "p",
                                        { staticClass: "font-24 black" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.mangerData.teamCql
                                                  ? _vm.mangerData.teamCql
                                                  : 100
                                              ) +
                                              "% "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "a-card",
                        {
                          attrs: { bordered: false },
                          scopedSlots: _vm._u([
                            {
                              key: "title",
                              fn: function () {
                                return [
                                  _c("div", { staticClass: "flex font-16" }, [
                                    _c(
                                      "span",
                                      { staticClass: "flex-1 black" },
                                      [_vm._v("工人数据")]
                                    ),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _c(
                            "a-row",
                            {
                              attrs: { type: "flex", justify: "space-between" },
                            },
                            [
                              _c("a-col", { staticClass: "ml-35" }, [
                                _c("h4", { staticClass: "black-three" }, [
                                  _vm._v("累计登记人数"),
                                ]),
                                _c("p", { staticClass: "font-24 black" }, [
                                  _vm._v(
                                    _vm._s(_vm.staffData.onlineTeams || 5972)
                                  ),
                                ]),
                                _c("div", { staticClass: "py-1" }, [
                                  _c("h4", { staticClass: "black-three" }, [
                                    _vm._v("今日出勤人数"),
                                  ]),
                                  _c("div", { staticClass: "flex" }, [
                                    _c(
                                      "span",
                                      { staticClass: "font-24 black" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.staffData.todayTeams || 423
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c("a-col", { staticClass: "mr-3" }, [
                                _c("h4", { staticClass: "black-three" }, [
                                  _vm._v("在场人数"),
                                ]),
                                _c("p", { staticClass: "font-24 black" }, [
                                  _vm._v(
                                    _vm._s(_vm.staffData.totalTeams || 879)
                                  ),
                                ]),
                                _c("div", { staticClass: "py-1" }, [
                                  _c("h4", { staticClass: "black-three" }, [
                                    _vm._v("出勤率"),
                                  ]),
                                  _c("p", { staticClass: "font-24 black" }, [
                                    _vm._v(
                                      _vm._s(_vm.staffData.teamCql || 48.0) +
                                        "%"
                                    ),
                                  ]),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "a-card",
                        {
                          attrs: { bordered: false },
                          scopedSlots: _vm._u([
                            {
                              key: "title",
                              fn: function () {
                                return [
                                  _c("div", { staticClass: "flex font-16" }, [
                                    _c(
                                      "span",
                                      { staticClass: "flex-1 black" },
                                      [_vm._v("班组数据")]
                                    ),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _c(
                            "a-row",
                            {
                              attrs: { type: "flex", justify: "space-between" },
                            },
                            [
                              _c("a-col", { staticClass: "ml-35" }, [
                                _c("h4", { staticClass: "black-three" }, [
                                  _vm._v("累计登记班组"),
                                ]),
                                _c("p", { staticClass: "font-24 black" }, [
                                  _vm._v(
                                    _vm._s(_vm.teamDataKq.totalTeams || 109)
                                  ),
                                ]),
                                _c("div", { staticClass: "py-1" }, [
                                  _c("h4", { staticClass: "black-three" }, [
                                    _vm._v("今日出勤班组"),
                                  ]),
                                  _c("div", { staticClass: "flex" }, [
                                    _c(
                                      "span",
                                      { staticClass: "font-24 black" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.teamDataKq.todayTeams || 29
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c("a-col", { staticClass: "mr-3" }, [
                                _c("h4", { staticClass: "black-three" }, [
                                  _vm._v("在场班组"),
                                ]),
                                _c("p", { staticClass: "font-24 black" }, [
                                  _vm._v(
                                    _vm._s(_vm.teamDataKq.onlineTeams || 37)
                                  ),
                                ]),
                                _c("div", { staticClass: "py-1" }, [
                                  _c("h4", { staticClass: "black-three" }, [
                                    _vm._v("出勤率"),
                                  ]),
                                  _c("p", { staticClass: "font-24 black" }, [
                                    _vm._v(
                                      _vm._s(_vm.teamDataKq.teamCql || "78%")
                                    ),
                                  ]),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 8 } },
            [
              _c(
                "a-card",
                {
                  attrs: { bordered: false },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "flex font-16" },
                            [
                              _c("span", { staticClass: "flex-1 black" }, [
                                _vm._v("考勤设备"),
                              ]),
                              _c(
                                "router-link",
                                {
                                  directives: [
                                    {
                                      name: "auth",
                                      rawName: "v-auth:role",
                                      value: "device",
                                      expression: "`device`",
                                      arg: "role",
                                    },
                                  ],
                                  staticClass: "pointer",
                                  attrs: { to: { name: "考勤设备设置" } },
                                },
                                [_vm._v("更多")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("div", { staticClass: "w-100" }, [
                    _c(
                      "div",
                      { staticClass: "flex j-around" },
                      [
                        _c("div", { staticClass: "ml-1 mr-5" }, [
                          _c("div", { staticClass: "flex flex-column" }, [
                            _c("h4", { staticClass: "black-three" }, [
                              _vm._v("考勤设备数"),
                            ]),
                            _c("p", { staticClass: "black font-24" }, [
                              _vm._v(_vm._s(_vm.deviceData.deviceNum || 0)),
                            ]),
                          ]),
                          _c("div", { staticClass: "flex flex-column pt-1" }, [
                            _c("h4", { staticClass: "black-three" }, [
                              _vm._v("离线设备"),
                            ]),
                            _c("p", { staticClass: "black font-24" }, [
                              _vm._v(
                                _vm._s(_vm.deviceData.offlineDeviceNum || 0)
                              ),
                            ]),
                          ]),
                        ]),
                        _c(
                          "vue-scroll",
                          {
                            staticClass: "flex-1",
                            staticStyle: { height: "152px" },
                            attrs: { ops: _vm.ops },
                          },
                          [
                            _c("standard-table", {
                              staticClass: "close-table",
                              attrs: {
                                columns: _vm.deviceColumns,
                                dataSource:
                                  _vm.deviceData.attendanceDeviceVoList,
                                rowKey: "id",
                                pagination: false,
                                isAlert: false,
                                bordered: false,
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "index",
                                  fn: function (ref) {
                                    var index = ref.index
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(parseInt(index) + 1) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                                {
                                  key: "online",
                                  fn: function (ref) {
                                    var text = ref.text
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(text ? text : "在线") +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [24, 16] } },
        [
          _c(
            "a-col",
            { staticStyle: { "padding-right": "30px" }, attrs: { span: 11 } },
            [
              _c(
                "a-card",
                {
                  attrs: { bordered: false },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("span", { staticClass: "flex-1 black font-16" }, [
                            _vm._v("班组考勤情况"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "vue-scroll",
                    {
                      staticStyle: { height: "264px" },
                      attrs: { ops: _vm.ops },
                    },
                    [
                      _c("standard-table", {
                        staticClass: "close-table2 scroll-tabox",
                        attrs: {
                          columns: _vm.teamColumns,
                          dataSource: _vm.teamRankList,
                          rowKey: "id",
                          pagination: false,
                          isAlert: false,
                          bordered: false,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "cql",
                            fn: function (ref) {
                              var text = ref.text
                              return [
                                _c("span", [_vm._v(" " + _vm._s(text) + "% ")]),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            {
              staticStyle: {
                "margin-left": "-13px",
                "padding-right": "0",
                "padding-left": "0",
              },
              attrs: { span: 13 },
            },
            [
              _c(
                "a-card",
                {
                  attrs: { bordered: false },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "flex font-16" },
                            [
                              _c("span", { staticClass: "flex-1 black" }, [
                                _vm._v("工资发放数据"),
                              ]),
                              _c(
                                "router-link",
                                {
                                  directives: [
                                    {
                                      name: "auth",
                                      rawName: "v-auth:role",
                                      value: "salary",
                                      expression: "`salary`",
                                      arg: "role",
                                    },
                                  ],
                                  staticClass: "pointer",
                                  attrs: { to: { name: "汇总工资申请单" } },
                                },
                                [_vm._v("明细查询")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "a-row",
                    {
                      staticClass: "mt-2 mb-2",
                      staticStyle: { "padding-bottom": "8px" },
                      attrs: { type: "flex", justify: "space-around" },
                    },
                    _vm._l(_vm.payrollData, function (item, index) {
                      return _c(
                        "a-col",
                        {
                          key: index,
                          staticClass: "payroll-card",
                          class: item.class ? item.class : "",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "flex j-center a-center h-100" },
                            [
                              _c(
                                "div",
                                { staticClass: "flex a-center" },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      width: "42px",
                                      height: "42px",
                                    },
                                    attrs: { src: item.img, alt: "" },
                                  }),
                                  _c("a-divider", {
                                    staticClass: "mx-3",
                                    staticStyle: {
                                      height: "61px",
                                      background: "#DAE6FF",
                                    },
                                    attrs: { type: "vertical" },
                                  }),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "mt-2" }, [
                                _c("h4", { staticClass: "black-three" }, [
                                  _vm._v(_vm._s(item.title)),
                                ]),
                                _c(
                                  "h4",
                                  { staticClass: "black-three font-24" },
                                  [_vm._v(_vm._s(item.value))]
                                ),
                              ]),
                            ]
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }