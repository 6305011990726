var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "a_Approval" }, [
    _c("div", { staticClass: "flex" }, [
      _c(
        "div",
        {
          staticClass:
            "equipment-title flex flex-column a-center j-center font-bold",
        },
        [
          _c("span", [
            _vm._v(_vm._s(_vm.params.facilityType == 1 ? "起重机" : "升降机")),
          ]),
          _c("span", [_vm._v("基本信息")]),
        ]
      ),
      _c("div", { staticClass: "ml-1" }, [
        _c("div", { staticClass: "font-12" }, [_vm._v("工程名称")]),
        _c("div", { staticClass: "title-box" }, [
          _vm._v(_vm._s(_vm.projectName)),
        ]),
      ]),
      _c("div", { staticClass: "ml-1" }, [
        _c("div", { staticClass: "font-12" }, [_vm._v("设备类型")]),
        _c("div", { staticClass: "title-box" }, [
          _c("div", [_vm._v(_vm._s(_vm.facilityTypeStr))]),
          _c("div", [_vm._v(_vm._s(_vm.facilityNum))]),
        ]),
      ]),
      _c("div", { staticClass: "ml-1" }, [
        _c("div", { staticClass: "font-12" }, [_vm._v("最近监测时间")]),
        _c("div", { staticClass: "title-box" }, [
          _vm._v(_vm._s(_vm.equipmentInfo.monitoringtime)),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "flex mt-2" }, [
      _c("div", [
        _c(
          "div",
          { staticClass: "alarm-record-box" },
          [
            _c("span", { staticClass: "font-bold" }, [_vm._v("作业报警记录")]),
            _c("standard-table", {
              attrs: {
                columns: _vm.columns,
                dataSource: _vm.dataSource,
                rowKey: "id",
                pagination: false,
                loading: _vm.tableLoading,
                scroll: { y: 450 },
                isAlert: false,
                bordered: true,
              },
              scopedSlots: _vm._u([
                {
                  key: "index",
                  fn: function (ref) {
                    var index = ref.index
                    return [_c("span", [_vm._v(" " + _vm._s(index + 1) + " ")])]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _vm.params.facilityType === 1
        ? _c("div", { staticClass: "flex flex-1 ml-2 bg-box relative" }, [
            _c("div", { staticClass: "flex-1" }, [
              _c("div", { staticClass: "relative" }, [
                _vm._m(0),
                _c("div", { staticClass: "left-box" }, [
                  _c("div", { staticClass: "relative white" }, [
                    _c("img", {
                      attrs: {
                        src: "https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20240717/1721201816187.png?Expires=2036561816&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=1agscqdH7bFXrY7cN3aw9FhE%2BKM%3D",
                        alt: "",
                      },
                    }),
                    _vm.spinning == true
                      ? _c(
                          "span",
                          { staticClass: "data-text" },
                          [
                            _vm._v("风速： "),
                            _c(
                              "a-spin",
                              [
                                _c("a-icon", {
                                  staticStyle: {
                                    "font-size": "16px",
                                    color: "#fff",
                                  },
                                  attrs: {
                                    slot: "indicator",
                                    type: "loading",
                                    spin: "",
                                  },
                                  slot: "indicator",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" m/s"),
                          ],
                          1
                        )
                      : _c("span", { staticClass: "data-text" }, [
                          _vm._v(
                            "风速：" +
                              _vm._s(_vm.equipmentInfo.windspeed || 0) +
                              "m/s"
                          ),
                        ]),
                  ]),
                  _c("div", { staticClass: "relative white mt-5" }, [
                    _c("img", {
                      attrs: {
                        src: "https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20240717/1721202941660.png?Expires=2036562941&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=gPrtgXY6dkoPjf%2FPgTC2l7Qx5NM%3D",
                        alt: "",
                      },
                    }),
                    _vm.spinning == true
                      ? _c(
                          "span",
                          { staticClass: "data-text" },
                          [
                            _vm._v("力矩： "),
                            _c(
                              "a-spin",
                              [
                                _c("a-icon", {
                                  staticStyle: {
                                    "font-size": "16px",
                                    color: "#fff",
                                  },
                                  attrs: {
                                    slot: "indicator",
                                    type: "loading",
                                    spin: "",
                                  },
                                  slot: "indicator",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" %"),
                          ],
                          1
                        )
                      : _c("span", { staticClass: "data-text" }, [
                          _vm._v(
                            "力矩：" +
                              _vm._s(_vm.equipmentInfo.moment || 0) +
                              "%"
                          ),
                        ]),
                  ]),
                  _c("div", { staticClass: "relative white mt-5" }, [
                    _c("img", {
                      attrs: {
                        src: "https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20240717/1721202964452.png?Expires=2036562964&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=UxhUdBa377%2FA6c9sTrinosuIjSo%3D",
                        alt: "",
                      },
                    }),
                    _vm.spinning == true
                      ? _c(
                          "span",
                          { staticClass: "data-text" },
                          [
                            _vm._v("塔身倾斜： "),
                            _c(
                              "a-spin",
                              [
                                _c("a-icon", {
                                  staticStyle: {
                                    "font-size": "16px",
                                    color: "#fff",
                                  },
                                  attrs: {
                                    slot: "indicator",
                                    type: "loading",
                                    spin: "",
                                  },
                                  slot: "indicator",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" °"),
                          ],
                          1
                        )
                      : _c("span", { staticClass: "data-text" }, [
                          _vm._v(
                            "塔身倾斜：" +
                              _vm._s(_vm.equipmentInfo.dip || 0) +
                              "°"
                          ),
                        ]),
                  ]),
                ]),
                _c("div", { staticClass: "right-top-box" }, [
                  _c("div", { staticClass: "relative white" }, [
                    _c("img", {
                      attrs: {
                        src: "https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20240717/1721204610729.png?Expires=2036564610&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=98K6NrDLDvQ%2FrE45lVegPOBBwr8%3D",
                        alt: "",
                      },
                    }),
                    _vm.spinning == true
                      ? _c(
                          "span",
                          { staticClass: "data-text" },
                          [
                            _vm._v("幅度： "),
                            _c(
                              "a-spin",
                              [
                                _c("a-icon", {
                                  staticStyle: {
                                    "font-size": "16px",
                                    color: "#fff",
                                  },
                                  attrs: {
                                    slot: "indicator",
                                    type: "loading",
                                    spin: "",
                                  },
                                  slot: "indicator",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" m"),
                          ],
                          1
                        )
                      : _c("span", { staticClass: "data-text" }, [
                          _vm._v(
                            "幅度：" +
                              _vm._s(_vm.equipmentInfo.ranges || 0) +
                              "m"
                          ),
                        ]),
                  ]),
                ]),
                _c("div", { staticClass: "right-mid-box" }, [
                  _c("div", { staticClass: "relative white" }, [
                    _c("img", {
                      attrs: {
                        src: "https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20240717/1721204791320.png?Expires=2036564791&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=JMjL%2FxYIys9NvkT5PvE4j2s5jJE%3D",
                        alt: "",
                      },
                    }),
                    _vm.spinning == true
                      ? _c(
                          "span",
                          { staticClass: "data-text" },
                          [
                            _vm._v("起升高度： "),
                            _c(
                              "a-spin",
                              [
                                _c("a-icon", {
                                  staticStyle: {
                                    "font-size": "16px",
                                    color: "#fff",
                                  },
                                  attrs: {
                                    slot: "indicator",
                                    type: "loading",
                                    spin: "",
                                  },
                                  slot: "indicator",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" m"),
                          ],
                          1
                        )
                      : _c("span", { staticClass: "data-text" }, [
                          _vm._v(
                            "起升高度：" +
                              _vm._s(_vm.equipmentInfo.height || 0) +
                              "m"
                          ),
                        ]),
                  ]),
                ]),
                _c("div", { staticClass: "right-bottom-box" }, [
                  _c("div", { staticClass: "relative white" }, [
                    _c("img", {
                      attrs: {
                        src: "https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20240717/1721205275764.png?Expires=2036565275&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=qc1PeWsivSUw8yB7deQ2IhHEf38%3D",
                        alt: "",
                      },
                    }),
                    _vm.spinning == true
                      ? _c(
                          "span",
                          { staticClass: "data-text" },
                          [
                            _vm._v("载重： "),
                            _c(
                              "a-spin",
                              [
                                _c("a-icon", {
                                  staticStyle: {
                                    "font-size": "16px",
                                    color: "#fff",
                                  },
                                  attrs: {
                                    slot: "indicator",
                                    type: "loading",
                                    spin: "",
                                  },
                                  slot: "indicator",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" t"),
                          ],
                          1
                        )
                      : _c("span", { staticClass: "data-text" }, [
                          _vm._v(
                            "载重：" +
                              _vm._s(_vm.equipmentInfo.weight || 0) +
                              "t"
                          ),
                        ]),
                  ]),
                ]),
              ]),
            ]),
            _c(
              "div",
              [
                _c("RotatingDialECharts", {
                  attrs: {
                    angle: Number(_vm.equipmentInfo.gyration) || 0,
                    spinning: _vm.spinning,
                  },
                }),
              ],
              1
            ),
            _vm.projectId === _vm.jlProjectId && _vm.videoVisible
              ? _c("div", { staticClass: "video-box" }, [
                  _c(
                    "div",
                    {
                      staticClass: "font-bold flex a-center j-center pl-1 pr-1",
                      staticStyle: {
                        "border-bottom": "1px solid #d4d4d4",
                        "padding-bottom": "5px",
                      },
                    },
                    [
                      _c("span", { staticClass: "flex-1" }, [
                        _vm._v("吊钩监控"),
                      ]),
                      _c("a-icon", {
                        staticClass: "pointer",
                        attrs: { type: "close-circle" },
                        on: {
                          click: function ($event) {
                            _vm.videoVisible = !_vm.videoVisible
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c("video", {
                    ref: "video",
                    staticStyle: { width: "100%", "margin-top": "5px" },
                    attrs: {
                      id: "video",
                      crossorigin: "anonymous",
                      autoplay: "",
                      controls: "",
                    },
                  }),
                ])
              : _vm._e(),
          ])
        : _c("div", { staticClass: "flex flex-1 ml-2 bg-box" }, [
            _c("div", { staticClass: "flex-1" }, [
              _c("div", { staticClass: "relative" }, [
                _vm._m(1),
                _c("div", { staticClass: "construction_hoist-left-box" }, [
                  _c("div", { staticClass: "relative white" }, [
                    _c("img", {
                      attrs: {
                        src: "https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20240724/1721802098514.png?Expires=2037162098&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=0sxu3PRn2q9ggbaSkUW%2Fv3AZTtk%3D",
                        alt: "",
                      },
                    }),
                    _vm.spinning == true
                      ? _c(
                          "span",
                          { staticClass: "data-text" },
                          [
                            _vm._v("速度： "),
                            _c(
                              "a-spin",
                              [
                                _c("a-icon", {
                                  staticStyle: {
                                    "font-size": "16px",
                                    color: "#fff",
                                  },
                                  attrs: {
                                    slot: "indicator",
                                    type: "loading",
                                    spin: "",
                                  },
                                  slot: "indicator",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" m/s"),
                          ],
                          1
                        )
                      : _c("span", { staticClass: "data-text" }, [
                          _vm._v(
                            "速度：" +
                              _vm._s(_vm.equipmentInfo.speed || 0) +
                              "m/s"
                          ),
                        ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "relative white",
                      staticStyle: { "margin-top": "90px" },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: "https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20240724/1721802149273.png?Expires=2037162149&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=IWVqLe04n%2FcF50pDvAXY83I0RmA%3D",
                          alt: "",
                        },
                      }),
                      _vm.spinning == true
                        ? _c(
                            "span",
                            { staticClass: "data-text" },
                            [
                              _vm._v("载重： "),
                              _c(
                                "a-spin",
                                [
                                  _c("a-icon", {
                                    staticStyle: {
                                      "font-size": "16px",
                                      color: "#fff",
                                    },
                                    attrs: {
                                      slot: "indicator",
                                      type: "loading",
                                      spin: "",
                                    },
                                    slot: "indicator",
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" t"),
                            ],
                            1
                          )
                        : _c("span", { staticClass: "data-text" }, [
                            _vm._v(
                              "载重：" +
                                _vm._s(_vm.equipmentInfo.weight || 0) +
                                "t"
                            ),
                          ]),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex a-center j-center" }, [
      _c("img", {
        attrs: {
          src: "https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20240723/1721703316589.png?Expires=2037063316&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=01hr34VUOpumRIr3usGLWpZ%2BP1s%3D",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex a-center j-center" }, [
      _c("img", {
        attrs: {
          src: "https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20240724/1721801681731.png?Expires=2037161682&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=%2Ff3pd1SvFftxNbcZlNGQ9krq%2BjE%3D",
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }