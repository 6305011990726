var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "a_Approval" },
        [
          _c("div", { staticClass: "plug-Overview" }, [
            _vm._v("项目名称变更记录"),
          ]),
          _c("a-divider"),
          _c(
            "div",
            { staticClass: "a_a_footer" },
            [
              _c("standard-table", {
                attrs: {
                  columns: _vm.columns,
                  dataSource: _vm.dataSource,
                  rowKey: "id",
                  pagination: _vm.pagination,
                  loading: _vm.tableLoading,
                  isAlert: false,
                  bordered: true,
                },
                on: { change: _vm.onPageChange },
                scopedSlots: _vm._u([
                  {
                    key: "index",
                    fn: function (ref) {
                      var index = ref.index
                      return [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                (_vm.pagination.current - 1) *
                                  _vm.pagination.pageSize +
                                  parseInt(index) +
                                  1
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }