/**
 * 封装操作localstorage本地存储的方法
 */
export const storage = {
  // 存储
  set(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  },
  // 取出
  get(key) {
    let value = localStorage.getItem(key)
    if (value && value !== 'undefined' && value !== 'null') {
      try {
        value = JSON.parse(value)
      } catch (e) {
        console.log('err local json转换失败', e)
      }
      return value
    }
    return null
  },
  // 删除
  remove(key) {
    localStorage.removeItem(key)
  }
}

/**
 * 封装操作sessionStorage本地存储的方法
 */
export const session = {
  // 存储
  set(key, value) {
    window.sessionStorage.setItem(key, JSON.stringify(value))
  },
  // 取出
  get(key) {
    let value = window.sessionStorage.getItem(key)
    if (value && value !== 'undefined' && value !== 'null') {
      try {
        value = JSON.parse(value)
      } catch (e) {
        console.log('err session json转换失败', e)
      }
      return value
    }
    return null
  },
  // 删除
  remove(key) {
    window.sessionStorage.removeItem(key)
  }
}
