<template>
  <div class="container">
    <div class="content">
      <div class="img-box">
        <img :src="staffLib.appPhotoPath" alt="">
        <p>{{ staffLib.name }}</p>
        <p>{{ staffLib.gender }}</p>
        <p>{{ staffLib.nation }}</p>
        <p>{{ workAge }}岁</p>
        <p v-if="staffLib.moreYears" style="color: red;">超龄</p>
        <p class="check" @click="modalCheck('实时头像', '', '', staffLib.actualPath)">实时头像</p>
        <p class="check" @click="modalCheck('身份证正反面', '', '', [staffLib.facePath, staffLib.backPath])">身份证正反面</p>
      </div>
      <a-descriptions bordered :column="3">
        <a-descriptions-item label="身份证号码：">{{ staffLib.idCard }}</a-descriptions-item>
        <a-descriptions-item label="出生：">{{ formatDate(staffLib.birthday) }}</a-descriptions-item>
        <a-descriptions-item label="住址：">{{ staffLib.location }}</a-descriptions-item>
        <a-descriptions-item label="现住地址：">{{ staffLib.residence }}</a-descriptions-item>
        <a-descriptions-item label="工人住宿：">{{ staffDormitory }}</a-descriptions-item>
        <a-descriptions-item label="籍贯：">{{ staffLib.nationality ? staffLib.nationality : staffLib.nativePlace + staffLib.nativeCity + staffLib.nativeArea }}</a-descriptions-item>
        <a-descriptions-item label="是否购买工伤保险：">{{ staffLib.hasBuyinjuryInsurance == 'true' ? '是' : '否' }}</a-descriptions-item>
        <a-descriptions-item label="是否加入工会：">{{ staffLib.isLabourUnion == 'true' ? '是' : '否' }}</a-descriptions-item>
        <a-descriptions-item label="是否退役军人：">{{ staffLib.soldier }}</a-descriptions-item>
        <a-descriptions-item label="签发机关：">{{ staffLib.signOrgan }}</a-descriptions-item>
        <a-descriptions-item label="有效期限：">{{ formatDate(staffLib.expiryStart) }} 至 {{ staffLib.expiryEnd ? formatDate(staffLib.expiryEnd) : '长期' }}</a-descriptions-item>
        <a-descriptions-item label="常住地址：">{{ staffLib.location }}</a-descriptions-item>
        <a-descriptions-item label="工号：">{{ teamStaffRelation.projWorkNum }}</a-descriptions-item>
        <a-descriptions-item label="政治面貌：">{{ political }}</a-descriptions-item>
        <a-descriptions-item label="文化程度：">{{ staffLib.education }}</a-descriptions-item>
        <a-descriptions-item label="手机号：">{{ staffLib.phoneNumber }}</a-descriptions-item>
        <a-descriptions-item label="应急手机号：">{{ staffLib.emergencyTel }}</a-descriptions-item>
        <a-descriptions-item label="技能水平：">{{ teamStaffRelation.techlevelText }}</a-descriptions-item>
        <a-descriptions-item label="所属单位：">{{ teamStaffRelation.officeName }}</a-descriptions-item>
        <a-descriptions-item label="类别：">作业工人</a-descriptions-item>
        <!-- <a-descriptions-item label="岗位："></a-descriptions-item> -->
        <a-descriptions-item label="班组：">{{ teamStaffRelation.teamName }}</a-descriptions-item>
        <a-descriptions-item label="工种：">{{ teamStaffRelation.workTypeName }}</a-descriptions-item>
        <a-descriptions-item label="用工形式：">{{ contractTypeStr }}</a-descriptions-item>
        <a-descriptions-item label="ID卡号：">{{ teamStaffRelation.icCardNumber }}</a-descriptions-item>
        <!-- 暂时隐藏 -->
        <!-- <a-descriptions-item label="执业资格证：">{{ staffCertication.certName }}</a-descriptions-item>
        <a-descriptions-item label="工资取证记录图："></a-descriptions-item>
        <a-descriptions-item label="资格证号：">{{ staffCertication.certNum }}</a-descriptions-item>
        <a-descriptions-item label="资格证附件：">
          <span v-if="staffCertication.attachment" class="check" @click="modalCheck('资格证附件', '', '', staffCertication.attachment)">查看</span>
          <span v-else>暂无数据</span>
        </a-descriptions-item>
        <a-descriptions-item label="其他证书："></a-descriptions-item> -->
        <a-descriptions-item label="入场安全教育情况：">
          <span v-if="teamStaffRelation.aqdjPath" class="check"
            @click="modalCheck('入场安全教育情况', '安全教育情况', '已接受入场安全教育培训', teamStaffRelation.aqdjPath)">查看</span>
          <span v-else>已完成</span>
        </a-descriptions-item>
        <a-descriptions-item label="劳动合同情况：">
          <span v-if="teamStaffRelation.contactNumber" class="check"
            @click="modalCheck('劳动合同情况', '劳动合同编号', teamStaffRelation.contactNumber, teamStaffRelation.contactAttachment)">查看</span>
          <span v-else>暂无数据</span>
        </a-descriptions-item>
        <a-descriptions-item label="离场凭证图：">
          <span v-if="teamStaffRelation.quitPhoto" class="check" @click="modalCheck('离场凭证图', '', '', teamStaffRelation.quitPhoto)">预览</span>
          <span v-else>暂无数据</span>
        </a-descriptions-item>
        <a-descriptions-item label="开户支行：">{{ teamStaffRelation.salaryName }}</a-descriptions-item>
        <a-descriptions-item label="工资卡账号：">{{ teamStaffRelation.salaryAccount }}</a-descriptions-item>
        <a-descriptions-item label="工资卡附件：">
          <span v-if="teamStaffRelation.salaryCardAttachment" class="check" @click="modalCheck('工资卡附件', '', '', teamStaffRelation.salaryCardAttachment)">查看</span>
          <span v-else>暂无数据</span>
        </a-descriptions-item>
      </a-descriptions>
    </div>
    <a-modal v-model="modalVisible" :title="modalTitle" :destroyOnClose="true" :width="800" :footer="null" @cancel="modalCancel">
      <a-descriptions bordered :column="1">
        <a-descriptions-item v-if="modalSubTitle" :label="modalSubTitle">
          {{ modalContent }}
        </a-descriptions-item>
        <a-descriptions-item label="附件">
          <img class="attachment-img" v-if="attachmentUrl_2" :src="attachmentUrl_2" alt="">
          <span v-if="attachmentUrl">
            <img class="attachment-img" v-if="checkFileType(attachmentUrl) == 'image' || (attachmentUrl.indexOf('contractPath') == -1 && attachmentUrl.indexOf('attachment') == -1 && attachmentUrl.indexOf('CONTRACT') == -1) && attachmentUrl.indexOf('THREE_AQJY_TEPL_ALL') == -1 && attachmentUrl.indexOf('LEAVE_PROJECT_DOCUMENT') == -1" :src="attachmentUrl" alt="">
            <a v-else :href="attachmentUrl" target="_blank">查看附件</a>
          </span>
          <span v-else>暂未上传</span>
        </a-descriptions-item>
      </a-descriptions>
    </a-modal>
  </div>
</template>

<script>
import { staffInfo, getPoliticalOutlook } from "@/services/participationUnit";
import moment from 'moment'
export default {
  data() {
    return {
      projWorkNum: '',
      teamId: '',
      workNum: '',
      staffCertication: {},
      staffLib: {},
      teamStaffRelation: {},
      modalVisible: false,
      modalTitle: '',
      modalSubTitle: '',
      modalContent: '',
      attachmentUrl: '',
      attachmentUrl_2: '',
      workAge: 24,
      political: ''
    };
  },
  activated() {
    this.projWorkNum = this.$route.params.projWorkNum
    this.teamId = this.$route.query.teamId
    this.workNum = this.$route.query.workNum
    this.getStaffInfo()
  },
  computed: {
    staffDormitory() {
      let dormitoryName = ''
      if (this.staffLib.builderStay &&  this.staffLib.builderStay == '0') {
        dormitoryName = '场外住宿'
      } else if (this.staffLib.builderStay &&  this.staffLib.builderStay == '1') {
        dormitoryName = '场内住宿'
      }
      return dormitoryName
    },
    contractTypeStr() {
      if (this.staffLib.contractType) {
        switch (this.staffLib.contractType) {
          case '0': 
            return '自聘'
          case '1': 
            return '外聘'
          case '2': 
            return '点工'
          case '3': 
            return '临时工'
          case '4': 
            return '包工'
          case '5': 
            return '合同工'
          default:
           return ''
        }
      } else {
        return ''
      }
    }
  },
  methods: {
    getPoliticalOutlook(val) {
      getPoliticalOutlook({type:'political'}).then(res => {
        if(res.data.code === 0) {
          res.data.data.forEach(el => {
            if (val == el.value) {
              this.political = el.label
            }
          });
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    getStaffInfo() {
      staffInfo({
        projWorkNum: this.projWorkNum,
        teamId: this.teamId,
        workNum: this.workNum
      }).then(res => {
        if (res.data.code === 0) {
          if (res.data.data.staffCertication) this.staffCertication = res.data.data.staffCertication
          if (res.data.data.staffLib) this.staffLib = res.data.data.staffLib
          if (res.data.data.teamStaffRelation) this.teamStaffRelation = res.data.data.teamStaffRelation
          if (res.data.data.staffLib.birthday) this.getAge(res.data.data.staffLib.birthday)
          this.getPoliticalOutlook(res.data.data.staffLib.political)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    formatDate(str) {
      return moment(str).format('YYYY-MM-DD')
    },
    modalCheck(title, subTitle, content, url) {
      this.modalTitle = title
      this.modalSubTitle = subTitle
      this.modalContent = content
      if (Array.isArray(url) && url.length > 1) {
        this.attachmentUrl = url[0]
        this.attachmentUrl_2 = url[1]
        console.log(this.attachmentUrl,'this.attachmentUrl=')
        console.log(this.attachmentUrl_2,'this.attachmentUrl_2=')
      } else {
        this.attachmentUrl = url
        console.log(this.attachmentUrl,'this.attachmentUrl=')
      }
      this.modalVisible = true
    },
    checkFileType(url) {
      const extension = url.split('.').pop().toLowerCase().substring(0,3)
      if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) {
        // 是图像文件
        return 'image'
      }
    },
    modalCancel() {
      setTimeout(() => {
        this.modalTitle = '',
        this.modalSubTitle = '',
        this.modalContent = '',
        this.attachmentUrl = '',
        this.attachmentUrl_2 = ''
      }, 200);
    },
    // 根据出生日期获取年龄
    getAge(vl) {
      if (vl) {
        this.workAge = this.calculateAge(vl)
      }
    },
    calculateAge(birthdayTimestamp) {
      const birthday = moment(birthdayTimestamp);
      const age = moment().diff(birthday, 'years');
      return age;
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  display: flex;
}
.img-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
  img {
    width: 150px;
    margin-bottom: 10px;
  }
}
.check {
  color: #89bceb;
  cursor: pointer;
}
.attachment-img {
  width: 550px;
}
::v-deep .ant-descriptions-item-label {
  font-weight: bold;
}
</style>
