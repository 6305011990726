var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("exception-page", {
    style: "min-height: " + _vm.minHeight,
    attrs: {
      "home-route": _vm.user.loginType == 1 ? "公司首页" : "项目首页",
      type: "404",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }