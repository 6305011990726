<template>
  <div class="wrap-cantainer ant-card ant-card-body">
    <a-row :gutter="16">
      <a-col :span="4">
        <div class="laowu-data-box blue">
          <h5 class="p-1">建委预警</h5>
          <div class="px-1">
            <div class="flex mb-1">
              <label class="flex-1 font-bold-700 font-30">{{ warningTotal }}</label>
            </div>
            <div class="flex mb-1">
              <label class="flex-1" @click="toWarning">去处理<a-icon type="right" /></label>
              <!-- <div class="flex-1 text-right">6</div> -->
            </div>
          </div>
        </div>
      </a-col>
      <a-col :span="10">
        <div class="laowu-data-box orange">
          <h5 class="p-1">工人</h5>
          <div class="px-1">
            <div class="flex mb-1">
              <label class="flex-1">今日</label>
              <div class="flex-1 text-right">{{ workData.today }}</div>
            </div>
            <div class="flex mb-1">
              <label class="flex-1">昨日</label>
              <div class="flex-1 text-right">{{ workData.yesterday }}</div>
            </div>
            <div class="flex">
              <label class="flex-1">已离场</label>
              <div class="flex-1 text-right">{{ workData.leave }}</div>
            </div>
          </div>
        </div>
      </a-col>
      <a-col :span="10">
        <div class="laowu-data-box blue">
          <h5 class="p-1">管理人员</h5>
          <div class="px-1">
            <div class="flex mb-1">
              <label class="flex-1">建设单位</label>
              <div class="flex-1 text-right">{{ ManagCWA["2"] }}</div>
            </div>
            <div class="flex mb-1">
              <label class="flex-1">施工单位</label>
              <div class="flex-1 text-right">{{ ManagCWA["1"] }}</div>
            </div>
            <div class="flex">
              <label class="flex-1">监理单位</label>
              <div class="flex-1 text-right">{{ ManagCWA["3"] }}</div>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
    <div class="mt-2">
      <a-row :gutter="15">
        <a-col :span="12">
          <div id="ageTypeChart" style="width: 100%; height: 300px"></div>
        </a-col>
        <a-col :span="12">
          <div id="workTypeChart" style="width: 100%; height: 300px"></div>
        </a-col>
      </a-row>
    </div>
    <div class="mt-2">
      <div id="sevenKqChart" style="width: 100%; height: 300px"></div>
    </div>
  </div>
</template>

<script>
import {
  sevenKqChart,
  safeChart,
  dangerTypeChart,
  ageTypeChart,
  workTypeChart,
} from "@/pages/homePage/echarts";
import {
  get7DaysCWA,
  getJobTitleFb,
  getManagCWA,
  getOfficeListByParentOffice,
  getProjectsByOffice,
  getQueryCurrentGk,
  getTypeCount,
  getWorkAgeFb,
  getWorkTotal,
  projectStatissKqS,
} from "@/services/statistics";
import { getNoCloseWarnList } from "@/services/projectManagement";
import { mapGetters } from "vuex";
export default {
  name: "homePage",
  data() {
    return {
      currentGk: {}, // 当月安全管理统计汇总
      workData: {}, // 劳务数据汇总
      projectList: [],
      defaultProject: "全部",
      gcObj: {}, // 工程类型守护
      ManagCWA: {}, // 管理人员数据
      sevenKq: [],
      warningTotal: 0,
      prams: {
        type: 2,
        status: 4,
        pageNo: 1,
        pageSize: 10,
      },
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  created() {
    // 获取账号下的单位
    const id = this.user.projectId;
    this.getWorkTotal(id);
    this.getManagCWA(id); // 默认第一个项目id
    this.getSevenKq(id); //  7天考勤
    this.getAgeType(id); //   年龄结构
    this.getJobType(id); //   工种结构
    this.aqxjlist(this.prams)
  },
  methods: {
    toWarning() {
      this.$router.push("/alert_list_index");
    },
    // 建委预警列表数据
    aqxjlist(prams) {
      getNoCloseWarnList(prams)
        .then((res) => {
          if (res.data.code === 0) {
            this.warningTotal = res.data.data.total;
          }
        })
    },
    /**
     * 劳务数据管理员统计
     * */
    getManagCWA(id) {
      getManagCWA({ projectId: id }).then((res) => {
        if (res.data.code === 0) {
          this.ManagCWA = res.data.data.list ? res.data.data.list : res.data.data;
        } else {
          this.error = res.data.msg;
          this.$message.error(res.data.msg, 3);
        }
      });
    },
    /**
     * 获取劳务工人考勤数
     * */
    getWorkTotal(id) {
      getWorkTotal({ projectId: id }).then((res) => {
        if (res.data.code === 0) {
          this.workData = res.data.data.list ? res.data.data.list : res.data.data;
        } else {
          this.error = res.data.msg;
          this.$message.error(res.data.msg, 3);
        }
      });
    },
    /**
     * 工人七天考勤
     * */
    getSevenKq(id) {
      get7DaysCWA({ projectId: id }).then((res) => {
        if (res.data.code === 0) {
          const week = [
            { dates: this.fun_date(-7), count: "0" },
            { dates: this.fun_date(-6), count: "0" },
            { dates: this.fun_date(-5), count: "0" },
            { dates: this.fun_date(-4), count: "0" },
            { dates: this.fun_date(-3), count: "0" },
            { dates: this.fun_date(-2), count: "0" },
            { dates: this.fun_date(-1), count: "0" },
          ];
          const data = res.data.data.list ? res.data.data.list : res.data.data
          this.sevenKq = data.length > 0 ? data : week;
          let nameArr = [];
          let cntArr = [];
          this.sevenKq.forEach((item, index) => {
            nameArr.push(item.dates);
            cntArr.push(Number(item.count));
          });
          sevenKqChart(nameArr, cntArr);
        } else {
          this.error = res.data.msg;
          this.$message.error(res.data.msg, 3);
        }
      });
    },
    fun_date(day) {
      let date1 = new Date();
      let date2 = new Date(date1);
      date2.setDate(date1.getDate() + day);
      let time2 =
        date2.getFullYear() +
        "-" +
        (date2.getMonth() + 1) +
        "-" +
        date2.getDate();
      return time2;
    },
    /**
     * 工人年龄分布
     * */
    getAgeType(id) {
      getWorkAgeFb(id).then((res) => {
        if (res.data.code === 0) {
          const data = res.data.data.list ? res.data.data.list : res.data.data
          let ageTypeFb = data[0] || { d4155: 0 };
          let arr = [];
          for (let item in ageTypeFb) {
            let obj = {};
            if (item == "d30") {
              obj.name = "低于40岁";
              obj.value = ageTypeFb[item];
            } else if (item == "d55") {
              obj.name = "55岁以上";
              obj.value = ageTypeFb[item];
            } else if (item == "d3140") {
              obj.name = "31岁到40岁";
              obj.value = ageTypeFb[item];
            } else {
              obj.name = "41岁到50岁";
              obj.value = ageTypeFb[item];
            }
            arr.push(obj);
          }
          console.log(arr);
          ageTypeChart(arr);
        } else {
          this.error = res.data.msg;
          this.$message.error(res.data.msg, 3);
        }
      });
    },
    /**
     * 工人工种分布
     * */
    getJobType(id) {
      getJobTitleFb(id).then((res) => {
        if (res.data.code === 0) {
          let arr = [];
          let workTypeFb = [];
          workTypeFb =
            res.data.data.length > 0
              ? res.data.data
              : [{ carft: "木工", num: 0 }];
          workTypeFb.forEach((item) => {
            arr.push({ name: item.carft, value: item.num });
          });
          workTypeChart(arr);
        } else {
          this.error = res.data.msg;
          this.$message.error(res.data.msg, 3);
        }
      });
    },
    /**
     * 监听统计换位中当前选择项目
     * */
    onHandleChange(e) {
      this.getWorkTotal(e);
      this.getManagCWA(e);
      this.getSevenKq(e);
      this.getAgeType(e);
      this.getJobType(e);
    },
  },
};
</script>
<style scoped>
.px-1 {
  padding: 0 10px;
}
.p-1 {
  padding: 10px;
}
.mt-2 {
  margin-top: 20px;
}
.mb-1 {
  margin-bottom: 10px;
}
.data-wrap {
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.flex {
  display: flex;
}
.flex-1 {
  flex: 1;
}
.a-center {
  align-items: center;
}
.j-center {
  justify-content: center;
}
.orange {
  background: rgb(242, 168, 45);
}
.blue {
  background: rgb(39, 130, 203);
}
.laowu-data-box {
  height: 150px;
  font-size: 14px;
  box-sizing: border-box;
  border-radius: 5px;
  color: #ffffff;
}
.laowu-data-box h5 {
  color: #ffffff;
  border-bottom: 1px solid #fff;
}
.relative {
  position: relative;
}
.unit-box {
  position: absolute;
  top: 10px;
  left: 10px;
}
.gc-box {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ffffff;
  padding: 0 5px;
}
.gc-box-item {
  min-width: 120px;
  color: deepskyblue;
  padding: 5px 0px;
}
.gc-box-img {
  width: 18px;
  height: 18px;
}
.border-right {
  border-right: 1px solid #e4e4e4;
}
</style>
