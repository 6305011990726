import { render, staticRenderFns } from "./modifycourse.vue?vue&type=template&id=fec55336&"
import script from "./modifycourse.vue?vue&type=script&lang=js&"
export * from "./modifycourse.vue?vue&type=script&lang=js&"
import style0 from "./modifycourse.vue?vue&type=style&index=0&id=fec55336&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\admin\\Desktop\\SJ\\【劳务机施】suijian_client\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fec55336')) {
      api.createRecord('fec55336', component.options)
    } else {
      api.reload('fec55336', component.options)
    }
    module.hot.accept("./modifycourse.vue?vue&type=template&id=fec55336&", function () {
      api.rerender('fec55336', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/onlineedu/modifycourse.vue"
export default component.exports