<template>
  <div class="container">
    <form-index
      ref="formindex"
      :formData="formData"
      @handleSubmit="handleSubmit"
      :projectList="projectList"
      :flexSubmit="true"
      @handlSelect="handlSelect"
      @handleChange="handleChange"
    >
      <!-- 下部分按钮插槽 -->
      <template #flexSubmit>
        <a-space  style="margin-left: 55px; margin-top: 5px">
          <a-button icon="search" type="primary" html-type="submit"> 查询 </a-button>
          <a-space class="operator">
            <a-button @click="close"> 重置 </a-button>
          </a-space>
        </a-space>
      </template>
      <!-- 自定义组件插槽 -->
      <template #customAction>
        <a-input-group compact>
            <a-input v-model="params.startAge" @onChange="onChange($event, 'startAge')" @blur="onBlur($event,'startAge')" :max-length="2" style="width: 43.3%; text-align: center;border-right: 0;" placeholder="最小">
              <a-tooltip slot="suffix">
                    <span class="text-color">岁</span>
              </a-tooltip>
            </a-input>
            <a-input
              style="width: 32px;border-left: 0;pointer-events: none; backgroundColor: #fff"
              placeholder="~"
              disabled
            />
            <a-input v-model="params.endAge" @onChange="onChange($event, 'endAge')" @blur="onBlur($event,'endAge')" :max-length="2" class="input-sytle" style="width: 43.3%; text-align: center;border-right: 0" placeholder="最大">
              <a-tooltip slot="suffix">
                    <span class="text-color">岁</span>
              </a-tooltip>
            </a-input>
        </a-input-group>
      </template>
    </form-index>
    <div class="a_a_footer">
      <div class="mb-1 mt-2">
        <a-space class="operator">
          <a-button @click="setUploadStatus"><a-icon type="issues-close" />设置上报状态</a-button>
        </a-space>
        <a-space class="operator">
          <a-button v-if="canJwOperate" @click="uploadJw"><a-icon type="file-add" />申请导入建委</a-button>
        </a-space>
        <!-- <a-space class="operator">
          <a-button @click="doDowloadBatch(3)">下载劳务资料</a-button>
        </a-space>
        <a-space class="operator">
          <a-button @click="doDowloadBatch(4)">导入</a-button>
        </a-space>
        <a-space class="operator">
          <a-button @click="doDowloadBatch(5)">导出</a-button>
        </a-space> -->
        <!-- 注销批量退场 -->
        <!-- <a-space class="operator">
          <a-button @click="batchExit()"><a-icon type="logout" />批量退场</a-button>
        </a-space> -->
        <!-- <a-space class="operator" v-if="canOperate && (user.projectId != '90cb52bef5dc41cdb4a3b3336d243fb6' && user.projectId != 'd1e394d934ff4db096c3cd681637e432')">
          <a-button @click="distribute"><a-icon type="cloud-download" />下发考勤设备</a-button>
        </a-space> -->
        <a-space class="operator" v-if="canOperate">
          <a-button @click="distribute2"><a-icon type="cloud-download" />分步下发考勤设备</a-button>
        </a-space>
        <a-space class="operator">
          <a-button @click="exportJw()"><a-icon type="download" />导出上报建委平台资料</a-button>
        </a-space>
        <a-space class="operator">
          <a-button @click="exportStaff()"><a-icon type="download" />导出全部信息</a-button>
        </a-space>
        <a-space class="operator">
          <a-button @click="batchApprove" v-if="isZb"><a-icon type="download"/>批量审批</a-button>
        </a-space>
        <a-space class="operator">
          <a-button @click="batchExitAll" ghost style="background: #fd9e06 !important; color: #fff; border-color:#fd9e06"><a-icon type="logout" />批量退场入口</a-button>
        </a-space>
        <a-space class="operator">
          <a-button @click="preExitAll" ghost style="background: #fd9e06 !important; color: #fff; border-color:#fd9e06"><a-icon type="logout" />通知工人离场</a-button>
        </a-space>
        <a-space class="operator">
          <a-button icon="history" ghost @click="failRecord" style="background: #fd9e06 !important; color: #fff; border-color:#fd9e06" > 下发失败记录 </a-button>
        </a-space>
        <!-- <span class="task-tips" v-if="taskObj.taskCount && taskObj.taskCount > 0" @click="handleTaskTips">共有{{taskObj.taskCount || 0}}个下发任务，已结束{{taskObj.endTaskCount || 0}}个下发任务</span> -->
      </div>
      <!-- 表格封装 -->
      <standard-table
        class=""
        :columns="columns"
        :dataSource="dataSource"
        :selectedRows.sync="selectedRows"
        rowKey="id"
        :scroll="{ x: 1300 }"
        :pagination="pagination"
        :loading="tableLoading"
        @change="onPageChange"
        :isAlert="false"
        :bordered="true"
        @update:selectedRows="updateSelectRows($event)"
      >
        <template slot="index" slot-scope="{ index }">
          <span>
            {{
              (pagination.current - 1) * pagination.pageSize +
                parseInt(index) +
                1
            }}
          </span>
        </template>
        <template slot="workName" slot-scope="{ record }">
          <!-- <router-link :to="'../advance_retreat/electronic_resume/' + record.projWorkNum + `?teamId=${record.teamId}&workNum=${record.workNum}`">
            {{ record.workName }}
          </router-link> -->
          <router-link :to="{ name: '电子履历', params: { projWorkNum: record.projWorkNum}, query: { teamId: record.teamId,workNum: record.workNum } }">
            {{ record.workName }}
          </router-link>
          <div v-if="record.isCaptain == 1" style="color: #4a86e8">(班组长)</div>
          <div v-if="record.leaveStatus == 0" style="color: #FF5C77">(退场中)</div>
          <template v-if="record.riskNames && record.riskNames.length > 0">
              <div style="color: #ff5c77" v-for="(item,index) in record.riskNames" :key="index">({{item}})</div>
          </template>
        </template>
        <template slot="workerFace" slot-scope="{ text, record }">
          <div class="flex flex-column a-center mt-1">
            <a-avatar shape="square" :size="64" :src="text" icon="user"/>
            <div class="flex a-center j-center" style="margin-top: 5px">
              <div v-if="record.hkFace != '' && record.hkFace != null">
                {{record.hkFace}}
              </div>
            </div>
          </div>
        </template>
        <template slot="isreport" slot-scope="{ text }">
          <span v-if="text == 1"><i class="iconfont duigouxiao" style="color:#00CE86"></i></span>
          <span v-else><i class="iconfont path" style="color:#FF5C77"></i></span>
        </template>
        <template slot="conconfirmedStatus" slot-scope="{ text }">
          <span v-if="text == -1" style="color: #fd9e06;">待审批</span>
          <span v-else-if="text == 0" style="color: #FF5C77;">不通过</span>
          <span v-else-if="text == 1" style="color: #00CE86;">已通过</span>
        </template>
        <template slot="syncJwStatus" slot-scope="{ text, record }">
          <div class="jwTag" :class="getJwStatusStyle(text)" @click="errToast(record)">
            <span>{{ getJwStatusText(text) }}</span>
          </div>
        </template>
        <div slot="action" slot-scope="{ record }">
          <div class="sTables">
            <div @click="visit(record)">查看</div>
            <div @click="visitEdit(record)">修改</div>
            <a-popover placement="bottomRight" trigger="click">
              <template slot="content">
                <!-- <div class="content_div">下载入场资料</div> -->
                <div class="content_div" @click="batchExit(record)">退场</div>
                <!-- <div class="content_div">评价</div> -->
                <div class="content_div" @click="eventRecord(record)">诚信行为登记</div>
              </template>
              <template slot="title">
                <span>操作</span>
              </template>
              <div>更多></div>
            </a-popover>
          </div>
        </div>
      </standard-table>
    </div>
    <a-modal v-model="modalVisible" :title="modalTitle" :destroyOnClose="true" :width="modalWidth" :footer="null" @cancel="modalCancel">
      <!-- <div>还有{{this.modalPagination.total }}人下发中</div> -->
      <span v-if="modalTitle === '下发任务'">该列表有效期为3小时,结束后请前去“下发失败记录”中查看是否下发失败</span>
      <!-- <div v-if="modalTitle === '下发失败记录'" class="mb-1">
        <a-button type="primary" @click="clearFailRecord">清理失败人员</a-button>
        <span class="ml-2">注：该清理将会清理掉人员所有考勤机的打卡权限，例如：1号设备下发失败，清理后，2号设备也将无法打卡。</span>
      </div> -->
      <span class="mt-1" v-if="errMsg">{{errMsg}}</span>
      <standard-table
        class="mt-1"
        :columns="modalColumns"
        :dataSource="modalDataSource"
        rowKey="id"
        :scroll="{ x: modalWidth - 100, y: 500 }"
        :pagination="modalPagination"
        :loading="modalTableLoading"
        @change="onModalPageChange"
        :isAlert="false"
        :bordered="true"
      >
        <template slot="index" slot-scope="{ index }">
          <span>
            {{
              (modalPagination.current - 1) * modalPagination.pageSize +
              parseInt(index) +
              1
            }}
          </span>
        </template>
        <!-- <template slot="name" slot-scope="{ record }">
          <span class="task-tips" @click="viewEditing(record, true)">
            {{ record.name }}
          </span>
        </template> -->
      </standard-table>
    </a-modal>
    <distribute-dialog
      :visible="distributeVisible"
      @cancel="distributeVisible = false"
      :isDel="false"
      :modalTitle="'下发考勤设备'"
      :workNumList="workNumList"/>
    <a-modal v-model="exitDialog" title="退场" :destroyOnClose="true" :footer="null" width="680px">
      <a-form
        id="components-form-demo-validate-other"
        :form="exitForm"
        v-bind="formItemLayout"
        @submit="exitSubmit"
      >
        <a-form-item label="退场凭证" extra="最多可选1个">
          <div class="flex a-center">
            <template v-if="defaultFileList.length > 0">
              <div>
                <span>{{defaultFileList[0].name}}</span>
                <a-icon type="close" class="ml-2" style="color: red;cursor: pointer;" @click="upRemove(defaultFileList[0])"/>
              </div>
            </template>
            <template v-else>
              <a-upload
                v-decorator="[
                  'upload',
                  { rules: [{ required: true, message: '请上传' }] },
                ]"
                :beforeUpload="beforeUpload"
                @preview="handlePreview"
                :customRequest="resourceUpload"
                :file-list="defaultFileList"
              >
                <a-button type="primary"> 点击选择文件 </a-button>
              </a-upload>
            </template>
            <a-button type="link" class="ml-3" @click="doDowload"> 下载文件模板 </a-button>
          </div>
        </a-form-item>
        <a-form-item label="满意程度">
          <a-radio-group v-decorator="['grade', { rules: [{ required: true, message: '请选择' }] },]">
            <a-radio value="1"> 不满意 </a-radio>
            <a-radio value="2"> 基本满意 </a-radio>
            <a-radio value="3">  满意 </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 12, offset: 16 }">
          <a-button @click="closeExitDialog" class="mr-3"> 关闭 </a-button>
          <a-button type="primary" html-type="submit"> 提交 </a-button>
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal v-model="approveModal" :destroyOnClose="true" :footer="null" width="680px" :closable="false">
      <h2>是否进行批量审批？</h2>
      <div class="flex mt-5 flex-row-reverse">
        <a-button @click="approveCancel" class="mr-3"> 取消 </a-button>
        <a-button @click="approveReject" class="mr-3"> 驳回 </a-button>
        <a-button class="mr-3" type="primary" @click="approvePass"> 通过 </a-button>
      </div>
    </a-modal>
    <persence-list-modal
      ref="persenceModal"
      :visible="visibleExit"
      :visibleTitle="visibleTitle"
      @handleCancel="handleCancel"
      @handleOk="handleOk"/>
    <Export-loading :cPercent="cPercent" :isExporting="isExporting" :loadingText="loadingText"></Export-loading>
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import { getProjectStaffList, getAllTeams, getJobByWorkerType, exitStaff, setJwReportStatus, reportJwStaff, batchSyncStaffToPlatform, getStaffTask, getDistributePersonErrList,
  getProAllOfficeList,getStaffHkFace, clearFailRecord, approveStaff} from "@/services/participationUnit"
import { request } from '@/utils/request'
import { ATTACHMENT_UPLOAD,EXPORT_JW_STAFFS,EXPORT_STAFF_ALL_MSG} from '@/services/api'
import DistributeDialog from './dialogs/distributeDialog.vue';
import moment from "moment";
import { mapGetters } from "vuex";
import { exportProgress } from "@/pages/aqjyzl/export"; // 导出方法
import ExportLoading from '@/components/ExportLoading/ExportLoading' // progress组件
import PersenceListModal from './dialogs/presenceListModal.vue';
import { useRender } from '@/hooks/useRender.js'
import { storage } from '@/utils/storage'

const formData = [
  {
    label: "姓名",
    placeholder: "请填写",
    decorator: [
      "workerName",
      { rules: [{ required: false, message: "请填写" }] },
    ],
    type: "input",
    key: "workerName",
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "单位",
    placeholder: "请输入所属单位",
    decorator: [
      "unitId",
      { rules: [{ required: false, message: "请选择所属单位" }] },
    ],
    type: "inputGroup",
    key: "unitId",
    dataSource: [],
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
  },
  // {
  //   label: "班组",
  //   placeholder: "请选择",
  //   decorator: ["teamId", { rules: [{ required: false, message: "请选择" }] }],
  //   type: "select",
  //   key: "teamId",
  //   selectlist: [],
  //   col: 6,
  //   labelCol: 6,
  //   wrapperCol: 14,
  //   display: true,
  // },
  {
    label: "班组",
    placeholder: "请输入选择班组",
    decorator: ["teamId", { rules: [{ required: false, message: "请输入选择班组" }] }],
    type: "inputGroup",
    key: "teamId",
    dataSource: [],
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "工种",
    placeholder: "请选择",
    decorator: [
      "title",
      { rules: [{ required: false, message: "请选择" }] },
    ],
    type: "select",
    key: "title",
    selectlist: [],
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
    showSearch: true
  },
  {
    label: "上报建委",
    placeholder: "请选择",
    decorator: [
      "isReportInfo",
      { rules: [{ required: false, message: "请选择" }] },
    ],
    type: "select",
    selectlist: [{
      value: 1,
      name: '是'
    },{
      value: 0,
      name: '否'
    }],
    key: "isReportInfo",
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "导入建委",
    placeholder: "请选择导入建委状态",
    decorator: [
      "syncJwStatus",
      { rules: [{ required: false, message: "请选择导入建委状态" }] },
    ],
    type: "select",
    selectlist: [{
      value: 0,
      name: '未导入'
    }, {
      value: 2,
      name: '处理中'
    }, {
      value: -1,
      name: '失败'
    }, {
      value: 1,
      name: '完成'
    }],
    key: "syncJwStatus",
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "进场日期",
    placeholder: "",
    decorator: ["enterDate", { rules: [{ required: false, message: "" }] }],
    type: "rangePicker",
    key: "enterDate",
    dataSource: [],
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
    format: "YYYY-MM-DD",
  },
  {
    label: "总包审批状态",
    placeholder: "请选择总包审批状态",
    decorator: [
      "conconfirmedStatus",
      { rules: [{ required: false, message: "请选择总包审批状态" }] },
    ],
    type: "select",
    selectlist: [{
      value: -1,
      name: '待审批'
    }, {
      value: 0,
      name: '不通过'
    }, {
      value: 1,
      name: '已审批'
    }],
    key: "conconfirmedStatus",
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "筛选年龄段",
    type: "customAction",
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  }
];

const failColumns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
    // fixed: "left",
  },
  {
    title: "人员ID",
    dataIndex: "personId",
    align: "center",
    width: 110,
    scopedSlots: { customRender: "personId" },
    // fixed: "left",
  },
  {
    title: "姓名",
    dataIndex: "name",
    align: "center",
    width: 110,
    scopedSlots: { customRender: "name" },
    // fixed: "left",
  },
  {
    title: "人员类型",
    dataIndex: "builderType",
    width: 110,
    align: "center",
    // ellipsis: true,
    scopedSlots: { customRender: "builderType" },
    // fixed: "left",
  },
  {
    title: "设备名称",
    align: "center",
    width: 100,
    dataIndex: "deviceName",
    scopedSlots: { customRender: "deviceName" },
  },
  {
    title: "下发时间",
    align: "center",
    width: 150,
    dataIndex: "createTime",
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "错误信息",
    align: "center",
    width: 180,
    dataIndex: "errInfo",
    scopedSlots: { customRender: "errInfo" },
  },
];
const taskColumns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
    // fixed: "left",
  },
  {
    title: "姓名",
    dataIndex: "name",
    align: "center",
    width: 110,
    scopedSlots: { customRender: "name" },
    // fixed: "left",
  },
  {
    title: "状态",
    dataIndex: "step",
    align: "center",
    // ellipsis: true,
    scopedSlots: { customRender: "step" },
    // fixed: "left",
  },
];
export default {
  mixins: [exportProgress], //导出方法注入
  components: {
    formIndex,
    StandardTable,
    DistributeDialog,
    ExportLoading,
    PersenceListModal
  },
  data() {
    const columns = [
      {
        title: "序号",
        width: 65,
        scopedSlots: { customRender: "index" },
        // customRender: (text, record, index) => `${index + 1}`,
        align: "center",
      },
      {
        title: "姓名",
        dataIndex: "workName",
        align: "center",
        width: 120,
        scopedSlots: { customRender: "workName" },
      },
      {
        title: <a-tooltip>
          <template slot="title">人脸评分越高对于下发设备成功的概率越高，建议评分大于80分</template>
          头像评分<a-icon style="margin-left:5px" type="question-circle" theme="twoTone" />
        </a-tooltip>, 
        dataIndex: "workerFace",
        align: "center",
        width: 120,
        scopedSlots: { customRender: "workerFace" },
      },
      {
        title: "年龄",
        dataIndex: "age",
        align: "center",
        width: 65,
      },
      {
        title: "所属单位",
        dataIndex: "officeName",
        align: "center",
        scopedSlots: { customRender: "officeName" },
      },
      {
        title: "所属班组",
        align: "center",
        dataIndex: "teamName",
        scopedSlots: { customRender: "teamName" },
      },
      {
        title: "工种",
        dataIndex: "workTypeName",
        align: "center",
        width: 120,
        ellipsis: true,
        scopedSlots: { customRender: "workTypeName" },
      },
      {
        title: "进场时间",
        dataIndex: "enterDate",
        align: "center",
        width: 120,
        customRender: (text) => useRender.renderDate(text)
      },
      {
        title: "总包审批",
        dataIndex: "conconfirmedStatus",
        align: "center",
        width: 120,
        scopedSlots: { customRender: "conconfirmedStatus" },
      },
      {
        title: "是否上报建委",
        dataIndex: "isreport",
        align: "center",
        width: 130,
        scopedSlots: { customRender: "isreport" },
      },
      {
        title: "导入建委状态",
        dataIndex: "syncJwStatus",
        align: "center",
        width: 130,
        scopedSlots: { customRender: "syncJwStatus" },
      },
      // {
      //   title: "入场资料完成度",
      //   dataIndex: "infoPercent",
      //   align: "center",
      //   scopedSlots: { customRender: "infoPercent" },
      // },
      {
        title: "操作",
        align: "center",
        fixed: "right",
        width: 160,
        scopedSlots: { customRender: "action" },
      },
    ];
    return {
      approveModal: false,
      projectArr: [],
      projectList: [], //获取当前项目数据
      formItemLayout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 },
      },
      defaultFileList: [],
      formData: formData,
      columns: columns,
      dataSource: [], //table数组
      selectedRows: [], //选中的数据
      pagination: {
        //分页数据
        showSizeChanger:true,
        pageSizeOptions: ['10', '50', '100', '150'],
        current:1,
        pageSize:50,
        total:0,
        showLessItems:true,
        showQuickJumper:true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      params: { //测试数据
        pageNumber: 1,
        pageSize: 50,
        enterStatus: '1',
        startAge: undefined,
        endAge: undefined,
      },
      tableLoading: false, //table加载中
      distributeVisible: false,
      workNumList: [], //选择的工号list
      exitDialog: false,
      selectedRecord: null,
      modalTitle: '',
      modalWidth: 1000,
      modalVisible: false,
      modalColumns: null,
      modalDataSource: [],
      modalPagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      modalTableLoading: false,
      modalParams: {
        pageNumber: 1,
        pageSize: 10,
      },
      taskObj: {
        taskCount: 0,
        endTaskCount: 0
      },
      exitForm: this.$form.createForm(this, { name: 'coordinated' }),
      accept:'.png,.PNG,.jpg,.JPG,.pdf,.doc,.docx',//图片上传限制
      loadingText:"请稍后.",
      isExporting: false,
      cPercent: 0,
      arrRoles:['zong_bao_lao_wu',], // 下发设备权限
      arrJwOperate: ['67f43348a8fd450f9cac17a9215ba609','d811216fac2c3714028f74408affff5f','89da81404e5697bda28ba24e78097eb3','936244f9c843cd55c2638bd68b3e159e'], // 有操作权限的项目ID
      staffHkFace: [],
      visibleExit: false,
      visibleTitle: '批量退场',
      errMsg: '',
    };
  },
  watch: {
    defaultFileList: {
      handler(newVal) {
        console.log(newVal)
      }
    }
  },
  computed: {
    ...mapGetters("account", ["user"]),
    canOperate() {
      return this.user.nowRoleKey.some(role => this.arrRoles.includes(role));
    },
    canJwOperate() {
      return this.arrJwOperate.some(role => role == this.user.projectId)
    },
    isZb() {
      return this.user.nowRoleKey.includes('zong_bao_lao_wu')
    }
  },
  mounted() {
    this.getData(this.params)
    this.getZdata(getProAllOfficeList, {value: '', item: {key: 'unitId'}}, "officeName");
  },
  activated() {
    const submited = storage.get('presenceList-submit')
    
    submited && this.getData(this.params)
    this.getWorkerType()
    // this.getStaffTask()
    // 没有导入建委状态权限的操作
    if (!this.canJwOperate) {
      let keyToDelete = 'syncJwStatus'
      this.columns = this.columns.filter(item => item.dataIndex !== keyToDelete)
      this.formData = this.formData.filter(item => item.key !== keyToDelete)
    }
  },
  methods: {
    onChange(e,setValue) {
      const { value } = e.target;
      const reg = /^-?[0-9]*(\.[0-9]*)?$/;
      if ((!isNaN(value) && reg.test(value)) && value != '' || value === '-') {
        this.$set(this.params,`${setValue}`,Number(value))
      } else {
        this.$set(this.params,`${setValue}`,undefined)
      }
    },
    onBlur(e, setValue) {
      const { value } = e.target;
      const reg = /^-?[0-9]*(\.[0-9]*)?$/;
      if ((!isNaN(value) && reg.test(value)) && value != '' || value === '-') {
        this.$set(this.params,`${setValue}`, Number(value))
      } else {
        this.$set(this.params,`${setValue}`,undefined)
      }
    },
    doDowload() {
      const link = document.createElement('a');
      link.style.display = 'none';
      link.href = 'https://adm.gz-suijian.com/工人离场手续.docx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
     // 图片上传限制处理
     beforeUpload(file) {
       const accept = '.' + file.name.substring(file.name.lastIndexOf(".") + 1);
      if (this.accept.split(',').indexOf(accept) == -1) {
          this.$message.error("只能上传图片、Word文档、PDF文件");
          return false
      }
    },
    // 预览图片
    handlePreview(file) {
      let isimg = this.icludesPdf(file.url, "pdf") == -1;
      if (file.url && isimg) {
          this.previewImage = file.url
          this.previewVisible = true
      }else{
          window.open(file.url);
      }
    },
    // 上传资源
    resourceUpload(value) {
      const formData = new FormData();
      formData.append('file', value.file);
      // 图片上传
      this.attachmentUpload(formData, value)
    },
    /*
    * 图片请求接口
    * type 上传组件的类型
    */
    attachmentUpload(formData, value) {
      // ATTACHMENT_UPLOAD图片上传接口
      request(ATTACHMENT_UPLOAD, "post", formData).then(res => {
        if (res.data.code === 0) {
          const fileObj = {
            uid: res.data.data.id, //图片id
            name: res.data.data.name, //图片名称
            url: res.data.data.path, //图片地址
            key: 'Upload', //获取对应的上传key名 ，后续移除图片用
            formData, //源文件上传流
            type: 'Upload'
          }
          this.defaultFileList.push(fileObj);
        } else {
          this.$message.warning(res.message);
        }
      })
    },
    // 删除文件
    upRemove(file) {
      if (file.type === "Upload") {
        // 获取文件下表
        const deIndex = this.defaultFileList.map(item => item.uid).indexOf(file.uid)
        // 删除对应下标中的数组对象
        this.defaultFileList.splice(deIndex, 1)
      }
    },
    getData(params) {
      storage.set('presenceList-submit', false)
      this.tableLoading = true;
      getProjectStaffList(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
          this.dataSource.forEach((item) => {
            item.riskTips = item.riskTips ? item.riskTips : '/'
            item.officeName = item.officeName ? item.officeName : '/'
            item.teamName = item.teamName ? item.teamName : '待分配'
            // if (item.isreport && item.isreport == '1') {
            //   item.isreport = '是'
            // } else if (item.isreport && item.isreport == '0') {
            //   item.isreport = '否'
            // } else {
            //   item.isreport = '/'
            // }
            // getStaffHkFace
            item.enterDate = moment(item.enterDate).format("YYYY-MM-DD");
          })
          let workNumList = this.dataSource.map(item => item.workNum)
          // 获取人脸评分
          getStaffHkFace(workNumList).then(result => {
            if (result.data.code === 0) {
              // 海康人脸评分
              this.staffHkFace = result.data.data
              this.dataSource.map(item => {
                this.staffHkFace.map(key => {
                  if (item.workNum == key.workNum) {
                    item.hkFace = key.scoreContent
                  }
                })
              })
              this.dataSource = [...this.dataSource]
            } else {
              // 处理海康接口报错
              this.dataSource.map(item => {
                    item.hkFace = '暂无'
              })
              this.dataSource = [...this.dataSource]
            }
          })
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    getJwStatusStyle(text) {
      switch(text) {
        case -1:
          return 'tagRed'
        case 0:
          return 'tagGray'
        case 1:
          return 'tagGreen'
        case 2:
        case 3:
        case 4:
        case 5:
          return 'tagBlue'
        default:
          return 'tagGray'
      }
    },
    getJwStatusText(text) {
      switch(text) {
        case -1:
          return '失败'
        case 0:
          return '未导入'
        case 1:
          return '完成'
        case 2:
        case 3:
        case 4:
        case 5:
          return '处理中'
        default:
          return '未导入'
      }
    },
    errToast(record) {
      if (record.syncJwErrInfo) {
        const h = this.$createElement;
        this.$info({
          content: h('div', {}, [
            h('p', record.syncJwErrInfo),
          ]),
          onOk() {},
        });
      }
    },
    handleSubmit(e) {
      let obj = { ...e, ...this.params }
      obj.pageNumber = 1
      if (!obj.endAge && !obj.startAge) {
        delete obj.endAge
        delete obj.startAge
      }
      this.pagination.current = 1;
      this.selectedRows = []
      obj.enterDate = obj.enterDate === '' ? [] : obj.enterDate
      this.getData(obj);
      // this.getStaffTask() // 查询时更新查询工人下发考勤机任务数及明细
    },
    clearFailRecord() {
      clearFailRecord().then(res => {
        if (res.data.code === 0) {
          this.errMsg = res.data.msg
          this.$message.warning(res.data.msg)
          this.getDistributePersonErrList(this.modalParams)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    //设置上报状态
    setUploadStatus() {
      if (this.selectedRows.length > 0) {
        const _that = this
        let recordIdList = []
        this.selectedRows.forEach(el => {
          recordIdList.push(el.recordId)
        })
        this.$confirm({
          title: '是否上报至建委？',
          cancelText: '否',
          okText: '是',
          onOk: () => {
            setJwReportStatus(recordIdList, 1).then(res => {
              if (res.data.code === 0) {
                _that.$message.success('设置成功')
                this.getData(this.params)
              } else {
                _that.$message.error(res.data.msg)
              }
            })
          },
          onCancel: () => {
            setJwReportStatus(recordIdList, 0).then(res => {
              if (res.data.code === 0) {
                _that.$message.success('设置成功')
                this.getData(this.params)
              } else {
                _that.$message.error(res.data.msg)
              }
            })
          }
        })
      } else {
        this.$message.error('请选择人员！')
      }
    },
    uploadJw() {
      if (this.selectedRows.length > 0) {
        const _that = this
        let recordIdList = []
        this.selectedRows.forEach(el => {
          recordIdList.push(el.recordId)
        })
        this.$confirm({
          title: '是否申请导入建委？',
          cancelText: '否',
          okText: '是',
          onOk: () => {
            reportJwStaff(recordIdList).then(res => {
              if (res.data.code === 0) {
                _that.$message.success('设置成功')
                this.getData(this.params)
              } else {
                _that.$message.error(res.data.msg)
              }
            })
          },
        })
      } else {
        this.$message.error('请选择人员！')
      }
    },
    //下发考勤设备
    distribute() {
      const _that = this
      if (this.selectedRows.length > 0) {
        this.workNumList = []//先初始化这个list
        this.selectedRows.forEach(el => {
          this.workNumList.push(el.workNum)
        })
        // this.distributeVisible = true
        this.$confirm({
        title: '确定下发人员到考勤设备？',
          onOk() {
            batchSyncStaffToPlatform({ threadId: new Date().getTime(), workNumList: _that.workNumList }).then(res => {
              if (res.data.code === 0) {
                _that.$message.success(res.data.msg)
                // _that.getStaffTask({pageNumber: 1,pageSize: 10,})
              } else {
                _that.$message.error(res.data.msg)
              }
            })
          }
        })
      } else {
        this.$message.error('请选择人员！')
      }
    },
    //下发考勤设备
    distribute2() {
      if (this.selectedRows.length > 0) {
        this.workNumList = []//先初始化这个list
        this.selectedRows.forEach(el => {
          this.workNumList.push(el.workNum)
        })
        this.distributeVisible = true
      } else {
        this.$message.error('请选择人员！')
      }
    },
    failRecord() {
      this.modalTitle = '下发失败记录'
      this.modalVisible = true
      this.modalTableLoading = true
      this.modalColumns = failColumns
      this.modalParams.pageNumber = 1
      this.modalWidth = 950
      this.getDistributePersonErrList(this.modalParams)
    },
    getDistributePersonErrList(params) {
      getDistributePersonErrList(params).then(res => {
        this.modalTableLoading = false
        if (res.data.code === 0) {
          this.modalDataSource = res.data.data;
          this.modalPagination.total = res.data.data.length;
          // this.modalDataSource.forEach(el => {
          //   if (el.createTime) {
          //     el.createTime = moment(el.createTime).format("YYYY-MM-DD");
          //   }
          // })
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    getStaffTask() {
      getStaffTask().then(res => {
        if (res.data.code === 0) {
          if (res.data.data) {
            this.taskObj = res.data.data
          }
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    modalCancel() {
      this.modalPagination = {
        current: 1,
        pageSize: 10,
        total: 0,
      }
    },
    onModalPageChange(e) {
      // let obj = this.$refs["formindex"].getFieldValue();
      this.modalParams.pageNumber = e.current;
      this.modalPagination.current = e.current;
      if (this.modalTitle === '下发失败记录') {
        this.getDistributePersonErrList(Object.assign({}, this.modalParams))
      } else if (this.modalTitle === '下发任务') {
        // this.getManagerTask(Object.assign({}, this.modalParams))
      }
    },
    handleTaskTips() {
      this.modalTitle = '下发任务'
      this.modalVisible = true
      this.modalColumns = taskColumns
      this.modalParams.pageNumber = 1
      this.modalDataSource = this.taskObj.taskDetailList
      this.modalPagination.total = this.taskObj.taskDetailList.length;
      this.modalWidth = 700
    },
    //查询所有班组
    doQueryTeams(params){
      getAllTeams(params).then(res => {
        if(res.data.code === 0){
          this.formDataFn('teamId', res.data.data, 'teamName')
        }
      })
    },
    // 对下拉框做数据处理
    formDataFn(key, data, itemName) {
        let arrSelect = []
        let arrSource = []
        data.forEach(item => {
            // 班组模糊匹配dataSource
            arrSource.push({ 'title': item[itemName], 'id': item.id })
            // seletetList选项
            arrSelect.push({ 'name': item[itemName], 'value': item.id })
        })
        this.formData.forEach(item => {
             if (item.key == key) {
                item.dataSource = arrSource
                item.selectlist = arrSelect
            }

        })
    },
    handleChange(e) {
      if (e.item.key == 'teamId') {
        const officeId = this.$refs["formindex"].getFieldValue().unitId
        this.doQueryTeams({ teamName: e.value, officeId })
      } else if (e.item.key == 'unitId') {
        this.projectArr = [];
        if (!e.value) {
          this.$refs["formindex"].setFieldsValue('', {}, "unitId");
        }
        this.getZdata(getProAllOfficeList, e, "officeName");
      }
    },
    // 接口封装
    /**
     * msg 接收接口名称
     * e 数据
     * name 参数名
     * */
     getZdata(msg, e, name) {
      let data = {
        [name]: e.value,
        projectId: this.user.projectId
      };

      msg(data).then((res) => {
        if (res.status == 200) {
          const data = res.data.data.list ? res.data.data.list : res.data.data
          data.forEach((item) => {
            this.projectArr.push({
              title: item[name],
              id: item.officeId,
            });
          });
          this.projectList = data;
          // 获取当前匹配key，赋值相对于数组
          this.formData.forEach((item) => {
            if (e.item.key === item.key) {
              item.dataSource = this.projectArr;
            }
          });
        }
      });
    },
    // 特殊数据处理
    handlSelect({ val, keys }) {
      this.projectList.forEach((item) => {
        if (keys == "unitId" && item.officeId == val) {
          this.$refs["formindex"].setFieldsValue(item.officeId, {}, "unitId");
          this.doQueryTeams({ officeId: val })
        }
      });
    },
    //获取工种
    getWorkerType() {
      getJobByWorkerType([]).then(res => {
        if(res.data.code === 0){
          this.formDataFn('title', res.data.data, 'carft')
        }
      })
    },
    //表格 - 分页查询
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      if (obj.status == "") {
        obj.status = 0;
      }
      if (obj.enterDate == "") {
        obj.enterDate = [];
      }
      this.params.pageNumber = e.current;
      this.pagination.current = e.current;
      this.params.pageSize = e.pageSize;
      this.pagination.pageSize = e.pageSize;
      this.getData(Object.assign({}, this.params, obj));
    },
    //批量退场
    batchExit(record) {
      if (record) {
        this.selectedRecord = record
        this.exitDialog = true
      } else {
        if (this.selectedRows.length > 0) {
          this.exitDialog = true
        } else {
          this.$message.error('请选择人员')
        }
      }
    },
    approveReject() {
      let approveStaffList = []
      this.selectedRows.forEach(el => {
        approveStaffList.push({projWorkNum: el.projWorkNum, workNum: el.workNum})
      })
      approveStaff({approveStaffList, status: 0}).then(res => {
        if (res.data.code === 0) {
          this.$message.success('操作成功！')
          this.getData(this.params)
          this.approveModal = false
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    approvePass() {
      let approveStaffList = []
      this.selectedRows.forEach(el => {
        approveStaffList.push({projWorkNum: el.projWorkNum, workNum: el.workNum})
      })
      approveStaff({approveStaffList, status: 1}).then(res => {
        if (res.data.code === 0) {
          this.$message.success('操作成功！')
          this.getData(this.params)
          this.approveModal = false
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    approveCancel() {
      this.approveModal = false
    },
    batchApprove() {
      if (this.selectedRows.length > 0) {
        this.approveModal = true
      } else {
        this.$message.error('请选择人员')
      }
    },
    //查看
    visit(record) {
      this.$router.push({
        name:'电子履历',
        params: {
          projWorkNum: record.projWorkNum
        },
        query:{
          teamId: record.teamId,
          workNum: record.workNum
        }
      })
    },
    //修改
    visitEdit(record) {
      this.$router.push({
        name:'进场登记',
        query:{
          projWorkNum: record.projWorkNum,
          teamId: record.teamId,
          workNum: record.workNum
        }
      })
    },
    //事件记录
    eventRecord(record) {
      this.$router.push({
        name:'诚信行为登记',
        query:{
          id: record.id,
          name: record.workName,
          unitId: record.unitId,
          teamId: record.teamId
        }
      })
    },
    closeExitDialog() {
      this.defaultFileList = []
      this.exitDialog = false
    },
    exitSubmit(e) {
      e.preventDefault();
      this.exitForm.validateFields((err, values) => {
        if (!err) {
          let idList = []
          let recordIdList = []
          if (this.selectedRecord) {
            //单个退场
            idList.push(this.selectedRecord.id)
            recordIdList.push(this.selectedRecord.recordId)
          } else {
            //批量退场
            this.selectedRows.forEach(el => {
              idList.push(el.id)
              recordIdList.push(el.recordId)
            })
          }
          if (this.defaultFileList.length > 0) {
            const _that = this
            let exitCertificate = this.defaultFileList[0].url
            exitStaff({idList, recordIdList, exitCertificate, grade: values.grade }).then(res => {
              this.selectedRecord = null
              if (res.data.code === 0) {
                this.closeExitDialog()
                this.$confirm({
                  title: '提交成功',
                  content: '是否需要前往退场人员页面申请住建平台退场',
                  onOk() {
                    _that.$router.push({name: '退场工人'})
                  }
                })
                if (this.params.pageNumber > 1) {
                  this.params.pageNumber = 1
                }
                this.getData(this.params)
                this.$emit('refreshExitList')
              } else {
                this.$message.error(res.data.msg)
              }
            })
          } else {
            this.$message.error('请上传退场凭证！')
          }
        }
      });

    },
    updateSelectRows(e) {
      console.log(e)
    },
    // 重置
    close() {
      this.params = {
        pageNumber: 1,
        pageSize: 50,
        enterStatus: '1',
        startAge: undefined,
        endAge: undefined,
      };
      this.pagination.current = 1;
      this.pagination.pageSize = 50;
      this.selectedRows = []
      this.getData(this.params);
      this.$refs["formindex"].resetFields();
    },
    // 导出建委平台资料
    exportJw() {
      let formObj = this.$refs["formindex"].getFieldValue()
      if (!formObj.teamId) return  this.$message.error('请选择班组，再导出建委平台资料')
      this.loadingText = "正在导出建委平台资料，请稍候 ····";
      let recordIdList = []
      if (this.selectedRows.length > 0) {
        this.selectedRows.forEach(el => {
          recordIdList.push(el.workNum)
        })
      }

      // if (recordIdList.length == 0) return this.$message.error('请选择班组人员或者选择进场日期，再导出建委平台资料')
      // if (formObj.enterDate.length == 0) return this.$message.error('请选择班组人员或者选择进场日期，再导出建委平台资料')
      let obj = {
        projectId: this.user.projectId,
        teamId: formObj.teamId ? formObj.teamId : undefined,
        workNums: recordIdList.length ? recordIdList : undefined,
        startEnter: formObj.enterDate[0] ? formObj.enterDate[0] : undefined,
        endEnter: formObj.enterDate[1] ? formObj.enterDate[1] : undefined
      }
      let fileName = "上报建委平台资料.zip"
      if (obj.workNums) {
        // 筛选班组人员-再导出
        this.isExporting=true;
        this.cPercent=0;
        this.exportFunNomal2(EXPORT_JW_STAFFS,obj,fileName)
      } else if (obj.startEnter && obj.endEnter) {
        // 筛选班组人员-再导出
          this.isExporting=true;
          this.cPercent=0;
          this.exportFunNomal2(EXPORT_JW_STAFFS,obj,fileName)
      } else {
        return this.$message.error('请选择筛选班组人员或者选择进场日期，再导出建委平台资料')
      }
      this.selectedRows = []
    },
    // 导出人员信息
    exportStaff() {
      let formObj = this.$refs["formindex"].getFieldValue()
      this.loadingText = "正在导出全部信息，请稍候 ····";
      let recordIdList = []
      if (this.selectedRows.length > 0) {
        this.selectedRows.forEach(el => {
          recordIdList.push(el.workNum)
        })
      }
      let fileName = '在场全部信息.xls'
      let obj = {
        officeId: formObj.unitId ? formObj.unitId : undefined,
        projectId: this.user.projectId,
        teamId: formObj.teamId ? formObj.teamId : undefined,
        workNums: recordIdList.length ? recordIdList : undefined,
        enterStatus: 1, // 在场人员
        enterDateStart: formObj.enterDate[0] ? formObj.enterDate[0] : undefined,
        enterDateEnd: formObj.enterDate[1] ? formObj.enterDate[1] : undefined
      }
      this.isExporting=true;
      this.cPercent=0;
      this.exportFunNomal2(EXPORT_STAFF_ALL_MSG,obj,fileName)
      this.selectedRows = []
    },
    batchExitAll() {
      this.visibleTitle = '批量退场'
      this.visibleExit = true
    },
    handleCancel() {
      this.visibleExit = false
    },
    handleOk() {
      this.visibleExit = false
      this.getData(this.params)
    },
    preExitAll() {
      this.visibleTitle = '批量通知工人离场'
      this.visibleExit = true
    }
  },
};
</script>

<style lang="less" scoped>
.a_a_footer {
  padding: -10px 0px;
  background: #ffffff;

  .operator {
    margin-right: 8px;

    .ant-btn {
      color: #307dfa;
    }
  }

  .sTables {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      width: 100%;
      text-align: center;
      cursor: pointer;
    }

    div:nth-child(1) {
      color: #307dfa;
    }

    div:nth-child(2) {
      color: #ff5c77;
    }

    div:nth-child(3) {
      color: #615e83;
    }
  }

}
.content_div {
  padding: 5px 0;
  cursor: pointer;
}
.content_div:hover {
  color: #307dfa;
}
.task-tips {
  color: #4377cc;
  text-decoration: underline;
  cursor: pointer;
}
.jwTag {
  border-radius: 2px;
  width: 65px;
  height: 30px;
  line-height: 28px;
  margin: auto;
  cursor: pointer;
  font-size: 14px;
}
.tagRed {
  background: #FFE8EC;
  color: #FF5D78;
}
.tagGray {
  background: #EAEBEB;
  color: #989999;
}
.tagGreen {
  background: #DEFCF1;
  color: #00CE86;
}
.tagBlue {
  background: #0874F01A;
  color: #0874F0;
}
.input-sytle {
        /deep/ .ant-input{
            border-left: none
        }
        // /deep/ .ant-input:hover {
        //     border-color: none;
        // }
    }
</style>
