<template>
  <div>
    <a-drawer
      :width="800"
      :visible="visible"
      title="企业库班组信息"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose"
    > 
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }" layout="horizontal">
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="班组名称：">
              <a-input
                v-decorator="['teamName', { rules: [{ required: true, message: '请输入班组名称' }] }]"
               placeholder="请输入班组名称"/>
            </a-form-item>
          </a-col>
        </a-row>
        
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="工种类型">
              <a-select
                :allowClear="true"
                style="width: 100%"
                placeholder="请选择工种类型"
                @change="changeTypeWork"
                v-decorator="['typeWorkId', { rules: [{ required: true, message: '请选择工种类型' }] }]">
                
                <a-select-option v-for="t in typeWorkDataSource" :key="t.id" :value="t.id">{{t.name}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="工  种">
              <a-select
                :allowClear="true"
                style="width: 100%"
                placeholder="请选择工种"
                v-decorator="['jobtitleId', { rules: [{ required: true, message: '请选择工种' }] }]">
                <a-select-option v-for="l in JobtitleDataSource" :key="l.id" :value="l.id">{{l.name}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="负责人：">
              <a-input
                v-decorator="['fzrUserName', { rules: [{ message: '请输入班组负责人' }] }]"
               placeholder="请输入班组负责人"/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="负责人手机号：">
              <a-input
                v-decorator="['fzrPhone', { rules: [{  message: '请输入负责人手机号' }] }]"
               placeholder="请输入负责人手机号"/>
            </a-form-item>
          </a-col>
        </a-row>
    </a-form>
    <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginLeft: '8px' }" type="primary" @click="doSubmit">
          确定
        </a-button>
        <a-button :style="{ marginLeft: '8px' }" @click="onClose">
          关闭
        </a-button>
      </div>
    </a-drawer>
    
  </div>
</template>
<script>
import { request } from "@/utils/request";
import {JOB_JOBTITLES_QUERY} from "@/services/api";
export default {
  name: "teamEditor",
  components: {},
  data() {
    return {
      form: this.$form.createForm(this),
      JobtitleDataSource:[],
      obj:{},
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    teamInfo:{
      type: Object,
      require:true
    },
    typeWorkDataSource:{
      type: Array,
      require:true
    }
  },
  created(){
  },
  mounted(){
  },
  watch:{
    teamInfo(val){
      this.obj = val;
      if(val.id){
        setTimeout(()=>{
          this.doQueryJobs(this.obj.typeWorkId);
          this.form.setFieldsValue({
            'teamName': this.obj.teamName,
            'fzrUserName': this.obj.fzrUserName,
            'fzrPhone': this.obj.fzrPhone,
            'typeWorkId': this.obj.typeWorkId,
            'jobtitleId': this.obj.jobtitleId
          })
        })
      }
      
    }
  },
  methods: {
    doQueryJobs($typeWorkId){
      request(JOB_JOBTITLES_QUERY,"post",{typeWorkId:$typeWorkId}).then(res => {
          if(res.data.code==0){
           this.JobtitleDataSource = res.data.data;
           console.log(this.JobtitleDataSource);
          }
       })
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },
    onClose(){
      this.$emit('teamPageClose');
    },
    changeTypeWork($Value){
       request(JOB_JOBTITLES_QUERY,"post",{typeWorkId:$Value}).then(res => {
          if(res.data.code==0){
           this.JobtitleDataSource = res.data.data;
           this.form.setFieldsValue({
            'jobtitleId': this.JobtitleDataSource[0].id
            })
          }
       })
    },
    doSubmit(){
       //数据校验
      this.form.validateFields((err, values) => {
        if (!err) {
          if(this.obj&&this.obj.id){
            values.id = this.obj.id
          }
          console.log(values);
          this.$emit('submit',{teamInfo:values})
        }
      })
    },
  }
};
</script>
<style>
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
/deep/ .ant-upload-list-picture-card .ant-upload-list-item{
  width: 127px;
  height: 127px;
}
</style>