var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a_Approval" },
    [
      _c(
        "a-tabs",
        {
          model: {
            value: _vm.defaultActiveKey,
            callback: function ($$v) {
              _vm.defaultActiveKey = $$v
            },
            expression: "defaultActiveKey",
          },
        },
        [
          _c("a-tab-pane", { key: "1", attrs: { tab: "分公司记录" } }),
          _c("a-tab-pane", { key: "2", attrs: { tab: "项目周检记录" } }),
        ],
        1
      ),
      _c(
        "a-form",
        { staticClass: "clearfix" },
        [
          _c(
            "a-col",
            { attrs: { span: 5 } },
            [
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "月度",
                    labelCol: { span: 6 },
                    wrapperCol: { span: 14, offset: 1 },
                  },
                },
                [
                  _c(
                    "a-month-picker",
                    {
                      attrs: {
                        allowClear: false,
                        placeholder: "请选择月份",
                        format: "YYYY-MM",
                      },
                      model: {
                        value: _vm.monthPickerVal,
                        callback: function ($$v) {
                          _vm.monthPickerVal = $$v
                        },
                        expression: "monthPickerVal",
                      },
                    },
                    [
                      _c("a-icon", {
                        attrs: { slot: "suffixIcon", type: "smile" },
                        slot: "suffixIcon",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.user.isGcglb != 1
            ? _c(
                "a-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: "分公司",
                        labelCol: { span: 8 },
                        wrapperCol: { span: 14, offset: 1 },
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          model: {
                            value: _vm.queryParams.gcglbId,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "gcglbId", $$v)
                            },
                            expression: "queryParams.gcglbId",
                          },
                        },
                        [
                          _c("a-select-option", { attrs: { value: "" } }, [
                            _vm._v("全部"),
                          ]),
                          _vm._l(_vm.options, function (item) {
                            return _c(
                              "a-select-option",
                              {
                                key: item.itemText || item.id,
                                attrs: { value: item.itemText || item.id },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(item.itemValue || item.master) +
                                    " "
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-col",
            { attrs: { span: 5 } },
            [
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "重点监控项目",
                    labelCol: { span: 9 },
                    wrapperCol: { span: 14, offset: 1 },
                  },
                },
                [
                  _c(
                    "a-select",
                    {
                      model: {
                        value: _vm.queryParams.monitorDisable,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "monitorDisable", $$v)
                        },
                        expression: "queryParams.monitorDisable",
                      },
                    },
                    [
                      _c("a-select-option", { attrs: { value: "" } }, [
                        _vm._v("全部"),
                      ]),
                      _vm._l(_vm.monitorOptions, function (item) {
                        return _c(
                          "a-select-option",
                          { key: item.value, attrs: { value: item.value } },
                          [_vm._v(" " + _vm._s(item.name) + " ")]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 3 } },
            [
              _c(
                "a-space",
                { staticClass: "btnBox" },
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.doRateQuery()
                        },
                      },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "a-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.close()
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "flex j-between" }, [
        _c(
          "div",
          [
            _c(
              "a-space",
              [
                _c(
                  "a-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.doDowload(1)
                      },
                    },
                  },
                  [_vm._v("导出")]
                ),
                _c(
                  "a-button",
                  { attrs: { type: "primary" }, on: { click: _vm.doDowload } },
                  [_vm._v("统计表")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", [
          _c(
            "div",
            { staticClass: "table-title flex" },
            [
              _c(
                "div",
                {
                  staticClass: "mr-3",
                  staticStyle: { "font-size": "14px", "line-height": "24px" },
                },
                [
                  _c(
                    "a-tooltip",
                    [
                      _c("template", { slot: "title" }, [
                        _vm._v(
                          _vm._s(
                            _vm.defaultActiveKey == 1
                              ? "公司整改下发后即为完成"
                              : "项目经理提交闭环后即为完成"
                          )
                        ),
                      ]),
                      _vm._v(" 统计规则"),
                      _c("a-icon", {
                        staticStyle: { "margin-left": "5px" },
                        attrs: {
                          type: "question-circle",
                          theme: "twoTone",
                          "two-tone-color": "#52c41a",
                        },
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm.defaultActiveKey == 1
                ? [
                    _c("div", { staticClass: "mr-2 " }, [
                      _vm._v(
                        "项目完成度: " +
                          _vm._s(_vm.completeCompany.completeNum) +
                          "/" +
                          _vm._s(_vm.completeCompany.projectSum)
                      ),
                    ]),
                    _c("div", [
                      _vm._v(
                        "项目完成率: " +
                          _vm._s(
                            _vm.sumToFixed(
                              _vm.completeCompany.completeNum,
                              _vm.completeCompany.projectSum
                            )
                          ) +
                          "%"
                      ),
                    ]),
                  ]
                : [
                    _c("div", { staticClass: "mr-2 " }, [
                      _vm._v(
                        "项目完成度: " +
                          _vm._s(_vm.completeProject.completeNum) +
                          "/" +
                          _vm._s(_vm.completeProject.projectSum)
                      ),
                    ]),
                    _c("div", [
                      _vm._v(
                        "项目完成率: " +
                          _vm._s(
                            _vm.sumToFixed(
                              _vm.completeProject.completeNum,
                              _vm.completeProject.projectSum
                            )
                          ) +
                          "%"
                      ),
                    ]),
                  ],
            ],
            2
          ),
        ]),
      ]),
      _c(
        "div",
        [
          _c(
            "a-spin",
            { attrs: { spinning: _vm.rateLoading } },
            [
              _c(
                "vue-scroll",
                {
                  staticClass: "mt-2",
                  staticStyle: { height: "calc(100vh - 380px)" },
                  attrs: { ops: _vm.ops },
                },
                [
                  _vm.rateSource.length > 0
                    ? _c(
                        "a-collapse",
                        {
                          staticClass: "w-100",
                          attrs: { bordered: false },
                          model: {
                            value: _vm.activeKey,
                            callback: function ($$v) {
                              _vm.activeKey = $$v
                            },
                            expression: "activeKey",
                          },
                        },
                        _vm._l(_vm.rateSource, function (item) {
                          return _c(
                            "a-collapse-panel",
                            {
                              key: item.gcglbId,
                              staticClass: "rate-item",
                              style: _vm.customStyle,
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function () {
                                      return [
                                        _c(
                                          "div",
                                          { staticClass: "font-bold" },
                                          [
                                            _vm._v(
                                              _vm._s(item.gcglbName) +
                                                " (" +
                                                _vm._s(
                                                  _vm.defaultActiveKey == 1
                                                    ? item.inspectCompanyNum
                                                    : item.inspectProjectNum
                                                ) +
                                                "/" +
                                                _vm._s(item.rateInfos.length) +
                                                ")"
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c(
                                "vue-scroll",
                                {
                                  staticStyle: {
                                    "max-width": "calc(100vw - 380px)",
                                    height: "150px",
                                  },
                                  attrs: { ops: _vm.ops2 },
                                },
                                [
                                  item.rateInfos.length
                                    ? _vm._l(item.rateInfos, function (items) {
                                        return _c(
                                          "div",
                                          {
                                            key: items.projectId,
                                            staticClass: "project-item",
                                          },
                                          [
                                            _vm.defaultActiveKey == 1
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "text-center",
                                                    style: {
                                                      color:
                                                        items.subCompanyCheck
                                                          ? "#0FC682"
                                                          : "#4a86e8",
                                                      cursor: "pointer",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.showModal(
                                                          items,
                                                          1,
                                                          item.gcglbId
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        items.subCompanyCheckNum ||
                                                          0
                                                      ) + "/1"
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticClass: "text-center",
                                                    style: {
                                                      color:
                                                        items.subProjectCheck
                                                          ? "#0FC682"
                                                          : "#4a86e8",
                                                      cursor: "pointer",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.showModal(
                                                          items,
                                                          3,
                                                          item.gcglbId
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        items.projectCheckNum ||
                                                          0
                                                      ) + "/4"
                                                    ),
                                                  ]
                                                ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "text-center mb-1",
                                                staticStyle: {
                                                  "margin-top": "8px",
                                                },
                                              },
                                              [
                                                _vm.defaultActiveKey == 1
                                                  ? [
                                                      items.subCompanyCheck
                                                        ? _c("a-icon", {
                                                            style: {
                                                              fontSize: "40px",
                                                            },
                                                            attrs: {
                                                              type: "check-circle",
                                                              theme: "twoTone",
                                                              "two-tone-color":
                                                                "#0FC682",
                                                            },
                                                          })
                                                        : _c("a-progress", {
                                                            attrs: {
                                                              type: "circle",
                                                              percent:
                                                                items.subCompanyPercent,
                                                              width: 40,
                                                              strokeWidth: 15,
                                                              showInfo: false,
                                                            },
                                                          }),
                                                    ]
                                                  : _vm._e(),
                                                _vm.defaultActiveKey == 2
                                                  ? [
                                                      items.subProjectCheck
                                                        ? _c("a-icon", {
                                                            style: {
                                                              fontSize: "40px",
                                                            },
                                                            attrs: {
                                                              type: "check-circle",
                                                              theme: "twoTone",
                                                              "two-tone-color":
                                                                "#0FC682",
                                                            },
                                                          })
                                                        : _c("a-progress", {
                                                            attrs: {
                                                              type: "circle",
                                                              percent:
                                                                items.subPorjectPercent,
                                                              width: 40,
                                                              strokeWidth: 15,
                                                              showInfo: false,
                                                            },
                                                          }),
                                                    ]
                                                  : _vm._e(),
                                              ],
                                              2
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "ellipsis-2 text-center tooltip-hover",
                                                staticStyle: {
                                                  "font-size": "14px",
                                                  cursor: "pointer",
                                                },
                                              },
                                              [
                                                _c(
                                                  "a-tooltip",
                                                  {
                                                    attrs: {
                                                      placement: "top",
                                                      title: items.projectName,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.showModal(
                                                              items,
                                                              _vm.defaultActiveKey ==
                                                                1
                                                                ? 1
                                                                : 3,
                                                              item.gcglbId
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              items.projectName
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            items.monitorDisable === 0
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "text-center bottom-tag",
                                                  },
                                                  [
                                                    _c(
                                                      "a-tooltip",
                                                      {
                                                        attrs: {
                                                          placement:
                                                            "bottomLeft",
                                                          title:
                                                            items.monitorReason,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "a-tag",
                                                          {
                                                            attrs: {
                                                              color: "#FC8251",
                                                            },
                                                          },
                                                          [_vm._v("非监控")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      })
                                    : [
                                        _c("a-empty", {
                                          staticClass:
                                            "flex j-center a-center w-100",
                                        }),
                                      ],
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _c("a-empty", {
                        staticClass: "flex j-center a-center w-100",
                      }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { width: "1400px", footer: null },
          model: {
            value: _vm.isShow,
            callback: function ($$v) {
              _vm.isShow = $$v
            },
            expression: "isShow",
          },
        },
        [
          _c(
            "standard-table",
            {
              attrs: {
                bordered: true,
                columns: _vm.columns,
                dataSource: _vm.dataSource,
                selectedRows: _vm.selectedRows,
                isAlert: false,
                pagination: _vm.pagination,
                loading: _vm.tableLoading,
              },
              on: {
                "update:selectedRows": function ($event) {
                  _vm.selectedRows = $event
                },
                "update:selected-rows": function ($event) {
                  _vm.selectedRows = $event
                },
                clear: _vm.onClear,
              },
              scopedSlots: _vm._u([
                {
                  key: "index",
                  fn: function (ref) {
                    var index = ref.index
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            (_vm.pagination.current - 1) *
                              _vm.pagination.pageSize +
                              parseInt(index) +
                              1
                          )
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "do-status",
                  fn: function (ref) {
                    var text = ref.text
                    return _c("div", {}, [
                      text == 2
                        ? _c(
                            "label",
                            [
                              _c("a-icon", {
                                staticStyle: {
                                  "font-size": "15px",
                                  color: "green",
                                },
                                attrs: { type: "check" },
                              }),
                            ],
                            1
                          )
                        : text == 99
                        ? _c("label")
                        : _c(
                            "label",
                            [
                              _c("a-icon", {
                                staticStyle: {
                                  "font-size": "15px",
                                  color: "red",
                                },
                                attrs: { type: "close" },
                              }),
                            ],
                            1
                          ),
                    ])
                  },
                },
                {
                  key: "outLine",
                  fn: function (ref) {
                    var text = ref.text
                    return _c("div", {}, [
                      _c("span", [
                        _vm._v(_vm._s(text && text == 1 ? "已线下回复" : "")),
                      ]),
                    ])
                  },
                },
                {
                  key: "action",
                  fn: function (ref) {
                    var record = ref.record
                    return _c("div", {}, [
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.onClick(record)
                            },
                          },
                        },
                        [_vm._v("查看详情")]
                      ),
                    ])
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "statusTitle" },
                [
                  _c("a-icon", {
                    attrs: { type: "info-circle" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.onStatusTitleClick($event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("Export-loading", {
        attrs: {
          cPercent: _vm.cPercent,
          isExporting: _vm.isExporting,
          loadingText: _vm.loadingText,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }