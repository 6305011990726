var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticStyle: { top: "10px" },
      attrs: {
        title: _vm.paperTitle,
        width: 850,
        zIndex: 2000,
        visible: _vm.visible,
        footer: null,
      },
      on: {
        cancel: function () {
          return _vm.doBack()
        },
      },
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "background-color": "#bfd5cb",
            "padding-top": "20px",
            "border-radius": "8px",
          },
        },
        [
          _c(
            "div",
            { staticStyle: { width: "100%", "text-align": "center" } },
            [
              _c("h2", [_vm._v(_vm._s(_vm.paperTitle))]),
              _c("h4", [
                _vm._v(
                  "总题目数：" +
                    _vm._s(
                      _vm.sigleQuests.length +
                        _vm.multipleQuests.length +
                        _vm.judgeQuests.length
                    ) +
                    "题 总分：" +
                    _vm._s(_vm.sigleCore + _vm.multipleCore + _vm.judgeCore) +
                    "分"
                ),
              ]),
            ]
          ),
          _c(
            "div",
            { staticStyle: { "padding-left": "50px", "margin-top": "30px" } },
            [
              _vm.sigleCore > 0
                ? _c(
                    "p",
                    {
                      staticStyle: {
                        "font-size": "14px",
                        "font-weight": "bolder",
                      },
                    },
                    [
                      _vm._v(
                        "一、单选题（" +
                          _vm._s(_vm.sigleQuests.length) +
                          "题/" +
                          _vm._s(_vm.sigleCore) +
                          "分）"
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.sigleCore > 0
                ? _c("a-list", {
                    staticStyle: { "padding-left": "30px" },
                    attrs: { split: false, "data-source": _vm.sigleQuests },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "renderItem",
                          fn: function (item, index) {
                            return _c(
                              "a-list-item",
                              {},
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(index + 1) +
                                    "、" +
                                    _vm._s(item.questCont) +
                                    " (" +
                                    _vm._s(item.newcore) +
                                    "分) "
                                ),
                                _c("a-list", {
                                  staticStyle: { "padding-left": "20px" },
                                  attrs: {
                                    split: false,
                                    size: "small",
                                    "data-source": item.optionsList,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "renderItem",
                                        fn: function (item2, index2) {
                                          return _c("a-list-item", {}, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("transNumber")(
                                                    index2 + 1
                                                  )
                                                ) +
                                                "、" +
                                                _vm._s(item2.answerCont) +
                                                " "
                                            ),
                                          ])
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            )
                          },
                        },
                      ],
                      null,
                      false,
                      2024260892
                    ),
                  })
                : _vm._e(),
              _vm.sigleCore > 0 && _vm.multipleCore > 0
                ? _c(
                    "p",
                    {
                      staticStyle: {
                        "font-size": "14px",
                        "font-weight": "bolder",
                      },
                    },
                    [
                      _vm._v(
                        "二、多选题（" +
                          _vm._s(_vm.multipleQuests.length) +
                          "题/" +
                          _vm._s(_vm.multipleCore) +
                          "分）"
                      ),
                    ]
                  )
                : _vm.multipleCore > 0
                ? _c(
                    "p",
                    {
                      staticStyle: {
                        "font-size": "14px",
                        "font-weight": "bolder",
                      },
                    },
                    [
                      _vm._v(
                        "一、多选题（" +
                          _vm._s(_vm.multipleQuests.length) +
                          "题/" +
                          _vm._s(_vm.multipleCore) +
                          "分）"
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.multipleCore > 0
                ? _c("a-list", {
                    staticStyle: { "padding-left": "30px" },
                    attrs: { split: false, "data-source": _vm.multipleQuests },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "renderItem",
                          fn: function (item, index) {
                            return _c(
                              "a-list-item",
                              {},
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(index + 1) +
                                    "、" +
                                    _vm._s(item.questCont) +
                                    "(" +
                                    _vm._s(item.newcore) +
                                    "分) "
                                ),
                                _c("a-list", {
                                  staticStyle: { "padding-left": "20px" },
                                  attrs: {
                                    split: false,
                                    size: "small",
                                    "data-source": item.optionsList,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "renderItem",
                                        fn: function (item2, index2) {
                                          return _c("a-list-item", {}, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("transNumber")(
                                                    index2 + 1
                                                  )
                                                ) +
                                                "、" +
                                                _vm._s(item2.answerCont) +
                                                " "
                                            ),
                                          ])
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            )
                          },
                        },
                      ],
                      null,
                      false,
                      782098428
                    ),
                  })
                : _vm._e(),
              _vm.sigleCore > 0 && _vm.multipleCore > 0 && _vm.judgeCore > 0
                ? _c(
                    "p",
                    {
                      staticStyle: {
                        "font-size": "14px",
                        "font-weight": "bolder",
                      },
                    },
                    [
                      _vm._v(
                        "三、判断题（" +
                          _vm._s(_vm.judgeQuests.length) +
                          "题/" +
                          _vm._s(_vm.judgeCore) +
                          "分）"
                      ),
                    ]
                  )
                : (_vm.sigleCore > 0 || _vm.multipleCore > 0) &&
                  _vm.judgeCore > 0
                ? _c(
                    "p",
                    {
                      staticStyle: {
                        "font-size": "14px",
                        "font-weight": "bolder",
                      },
                    },
                    [
                      _vm._v(
                        "二、判断题（" +
                          _vm._s(_vm.judgeQuests.length) +
                          "题/" +
                          _vm._s(_vm.judgeCore) +
                          "分）"
                      ),
                    ]
                  )
                : _vm.judgeCore > 0
                ? _c(
                    "p",
                    {
                      staticStyle: {
                        "font-size": "14px",
                        "font-weight": "bolder",
                      },
                    },
                    [
                      _vm._v(
                        "一、判断题（" +
                          _vm._s(_vm.judgeQuests.length) +
                          "题/" +
                          _vm._s(_vm.judgeCore) +
                          "分）"
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.judgeCore > 0
                ? _c("a-list", {
                    staticStyle: { "padding-left": "30px" },
                    attrs: { split: false, "data-source": _vm.judgeQuests },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "renderItem",
                          fn: function (item, index) {
                            return _c(
                              "a-list-item",
                              {},
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(index + 1) +
                                    "、" +
                                    _vm._s(item.questCont) +
                                    "(" +
                                    _vm._s(item.newcore) +
                                    "分) "
                                ),
                                _c("a-list", {
                                  staticStyle: { "padding-left": "20px" },
                                  attrs: {
                                    split: false,
                                    size: "small",
                                    "data-source": _vm.judgeQuestsOptions,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "renderItem",
                                        fn: function (item2, index2) {
                                          return _c("a-list-item", {}, [
                                            index2 == 0
                                              ? _c("span", [_vm._v("A、正确")])
                                              : _c("span", [_vm._v("B、错误")]),
                                          ])
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            )
                          },
                        },
                      ],
                      null,
                      false,
                      1909582997
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }