<template>
  <div class="container">
    <!-- <form-index
      ref="formindex"
      :formData="formData"
      @handleSubmit="handleSubmit"
      :flexAction="true"
      @handlSelect="handlSelect"
      @handleChange="handleChange"
    >
      <template #flexAction>
        <a-space :style="{ marginTop: '4px' }">
          <a-button icon="search" type="primary" html-type="submit"> 查询 </a-button>
          <a-space class="operator">
              <a-button @click="close"> 重置 </a-button>
            </a-space>
          <a-button icon="plus" type="primary" @click="addNewRecord" v-if="user.loginType == 0">新增</a-button>
        </a-space>
      </template>
    </form-index> -->
    <a-button icon="plus" type="primary" @click="addNewContract">新增</a-button>
    <div class="a_a_footer mt-1">
      <standard-table
        class=""
        :columns="columns"
        :dataSource="dataSource"
        :selectedRows.sync="selectedRows"
        rowKey="id"
        :pagination="pagination"
        :loading="tableLoading"
        @change="onPageChange"
        :isAlert="false"
        :bordered="true"
        @update:selectedRows="updateSelectRows($event)"
      >
        <template slot="index" slot-scope="{ index }">
          <span>
            {{
              (pagination.current - 1) * pagination.pageSize +
              parseInt(index) +
              1
            }}
          </span>
        </template>
        <div slot="action" slot-scope="{ record }">
            <a @click="edit(record)">修改</a>
            <a-divider type="vertical" />
            <a @click="delRecord(record)">删除</a>
        </div>
      </standard-table>
    </div>
    <a-modal
      :visible="showModal"
      @ok="handleOk"
      @cancel="cancelModal"
      :width="800"
      title="新增合同密钥"
    >
      <a-form-model
        :model="formModal"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 16 }"
        ref="ruleForm"
        :rules="rules"
      >
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="合同名称" prop="name">
              <a-input v-model="formModal.name" placeholder="请输入合同名称"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="乙方" prop="officeId">
              <a-select
                show-search
                placeholder="请选择乙方单位"
                option-filter-prop="children"
                :filter-option="filterOption"
                @change="handleChange"
                v-model="formModal.officeId"
                allowClear
              >
                <a-select-option
                  :key="item.name"
                  v-for="item in officeList"
                  :value="item.value"
                  :title="item.name"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="大平台编码" prop="accessno">
              <a-input v-model="formModal.accessno" placeholder="大平台编码"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="大平台密钥" prop="accesssecretkey">
              <a-input v-model="formModal.accesssecretkey" placeholder="大平台密钥"/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row >
          <a-col :span="24" v-for="(item, index) in formModal.jwAccountsVoList" :key="index">
            <a-card :title="item.roleKey == 1 ? '项目经理' : item.roleKey == 2 ? '总包劳务员' : '分包劳务员'" :bordered="false">
              <a-row>
                <a-col :span="12">
                  <a-form-model-item label="建委账号">
                    <a-input v-model.trim="formModal.jwAccountsVoList[index].account" placeholder="建委账号"/>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item label="建委密码">
                    <a-input v-model.trim="formModal.jwAccountsVoList[index].password" placeholder="建委密码"/>
                  </a-form-model-item>
                </a-col>
              </a-row>
            </a-card>
          </a-col>
        </a-row>
      </a-form-model>
      <template slot="footer">
        <a-space size="large">
          <a-button  @click="cancelModal">
            关闭
          </a-button>
          <a-button type="primary" @click="handleOk">
            确定
        </a-button>
        </a-space>
      </template>
    </a-modal>
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import { getOfficeProject } from "@/services/projectManagement";
import moment from "moment";
import {mapGetters} from 'vuex'
import eventType from '@/pages/advanceRetreat/eventType.json'
import { getContractList, getProAllOfficeList, saveContract, delContract } from "@/services/participationUnit";
import _ from 'lodash'
// const formData = [
//   {
//     label: "所属项目",
//     placeholder: "请输入所属项目",
//     decorator: [
//       "projectId",
//       { rules: [{ required: false, message: "请输入所属项目" }] },
//     ],
//     type: "inputGroup",
//     key: "projectId",
//     dataSource: [],
//     labelCol: 8,
//     wrapperCol: 14,
//     col: 4,
//     display: true,
//   },
//   {
//     label: "事件类型",
//     placeholder: "请选择",
//     decorator: [
//       "eventType",
//       { rules: [{ required: false, message: "请选择" }] },
//     ],
//     type: "select",
//     selectlist: eventType,
//     key: "eventType",
//     dataSource: [],
//     labelCol: 8,
//     wrapperCol: 14,
//     col: 4,
//     display: true,
//   },
//   {
//     label: "发生时间",
//     placeholder: "请选择",
//     decorator: [
//       "eventTime",
//       { rules: [{ required: false, message: "请选择" }] },
//     ],
//     type: "datePicker",
//     key: "eventTime",
//     col: 4,
//     labelCol: 8,
//     wrapperCol: 14,
//     display: true,
//   },
//   {
//     type: "action",
//     col: 5,
//     display: true,
//   },
// ];
const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
  },
  {
    title: "合同名称",
    dataIndex: "name",
    align: "center",
    scopedSlots: { customRender: "name" },
  },
  {
    title: '乙方',
    dataIndex: 'officeName',
    key: 'officeName',
    scopedSlots: { customRender: 'officeName' }
  },
  {
    title: "大平台编码",
    dataIndex: "accessno",
    key: 'accessno',
    align: "center",
    scopedSlots: { customRender: "accessno" },
  },
  {
    title: "大平台密钥",
    dataIndex: "accesssecretkey",
    align: "center",
    key: 'accesssecretkey',
    scopedSlots: { customRender: "accesssecretkey" },
  },
  {
    title: "操作",
    align: "center",
    width: 120,
    scopedSlots: { customRender: "action" },
  },
];
export default {
  components: {
    // formIndex,
    StandardTable,
  },
  data() {
    return {
      // formData: formData,
      columns: columns,
      dataSource: [], //table数组
      selectedRows: [], //选中的数据
      pagination: {
        //分页数据
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total, range) => `共 ${total} 条`
      },
      params: {
        //永远都是第一页
        pageNumber: 1,
        pageSize: 10,
      },
      tableLoading: false, //table加载中
      showModal: false,
      formModal: {
        name: '',
        officeId: undefined,
        accessno: '',
        accesssecretkey: '',
        // projectId: this.user.projectId,
        jwAccountsVoList: [
          {officeId: '', roleKey: '1', account: '', password: ''},
          {officeId: '', roleKey: '2', account: '', password: ''},
          {officeId: '', roleKey: '3', account: '', password: ''}
        ]
      },
      rules: {
        name: [{ required: true, message: "请填写合同名称", trigger: "change" },{ pattern: /(^\S)((.)*\S)?(\S*$)/, message: '前后不能有空格' }],
        officeId: [{ required: true, message: "请选择乙方", trigger: "change" }],
        accessno: [{ required: true, message: "请填写接入编码", trigger: "change" },{ pattern: /(^\S)((.)*\S)?(\S*$)/, message: '前后不能有空格' }],
        accesssecretkey : [{ required: true, message: "请填写接入密钥", trigger: "change" },{ pattern: /(^\S)((.)*\S)?(\S*$)/, message: '前后不能有空格' }],
      },
      officeList: [],
    };
  },
  activated() {
    this.getContractList()
    this.getgcglbList();
  },
  computed: {
    ...mapGetters('account', ['user']),
  },
  methods: {
    validateInput() {
      if (/\s/g.test(this.inputData)) {
        this.errorMessage = '输入内容不能包含空格';
      } else {
        this.errorMessage = '';
      }
    },
    // handleChange(e) {
    //   this.projectArr = [];
    //   this.getZdata(getOfficeProject, e, "projectName",'fullName');
    //   // 获取当前匹配key，赋值相对于数组
    //   this.formData.forEach((item) => {
    //     if (e.item.key === item.key) {
    //       item.dataSource = this.projectArr;
    //     }
    //   });
    // },
    /**
     * msg 接收接口名称
     * e 数据
     * name 参数名
     * */
    //  getZdata(msg, e, name,title) {
    //   let data = {
    //     [name]: e.value,
    //     officeId: this.user.officeId
    //   };
    //   msg(data).then((res) => {
    //     if (res.status == 200) {
    //       res.data.data.forEach((item) => {
    //         this.projectArr.push({
    //           title: item[title],
    //           id: item.id,
    //         });
    //       });
    //       this.projectList = res.data.data;
    //     }
    //   });
    // },
    // 获取项目侧参建单位
    getgcglbList() {
      getProAllOfficeList({ projectId: this.user.projectId }).then((res) => {
        if (res.data.code === 0) {
          const data = res.data.data;
          const arr = [];
          data.forEach((item) => {
            arr.push({
              name: item.officeName,
              value: item.officeId,
            });
          });
          this.officeList = arr
          // this.formDataFn("officeId", arr);
        }
      });
    },
    formDataFn(key, data, itemName) {
      let arr = [];
      data.forEach((item) => {
        arr.push({ name: item[itemName], value: item.id });
      });
      this.formData.forEach((item) => {
        if (item.key == key) {
          if (itemName) {
            item.selectlist = arr;
          } else {
            item.selectlist = data;
          }
        }
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange(e) {},
    getContractList() {
      this.tableLoading = true;
      getContractList(this.params).then((res) => {
        this.tableLoading = false;
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list
          this.pagination.total = res.data.data.total
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 特殊数据处理
    // handlSelect({ val, keys }) {
    //   this.projectList.forEach((item) => {
    //     if (keys == "officeName" && item.id == val) {
    //       this.$refs["formindex"].setFieldsValue(
    //         item.name,
    //         {},
    //         "officeName"
    //       );
    //     }
    //   });
    // },
    // 对下拉框做数据处理
    // formDataFn(key, data, itemName, form) {
    //   let arr = [];
    //   data.forEach((item) => {
    //     arr.push({ name: item[itemName], value: item.id });
    //   });
    //   form.forEach((item) => {
    //     if (item.key == key) {
    //       item.selectlist = arr;
    //     }
    //   });
    // },
    // handleSubmit(e) {
    //   let obj = { ...e, ...this.prams };
    //   obj.startEnterDate = obj.startEnterDate === "" ? [] : obj.startEnterDate;
    //   // this.getCertificateList(obj);
    //   this.getEventRecordList(obj)
    // },
    addNewContract() {
      this.showModal = true
    },
    // 关闭评价motal
    cancelModal(e) {
      this.showModal = false;
      // this.$refs.ruleForm.resetFields();
      this.formModal = {
        name: '',
        officeId: undefined,
        accessno: '',
        accesssecretkey: '',
        jwAccountsVoList: [
          {officeId: '', roleKey: '1', account: '', password: ''},
          {officeId: '', roleKey: '2', account: '', password: ''},
          {officeId: '', roleKey: '3', account: '', password: ''}
        ]
      } // 制空
    },
    // 添加评价
    handleOk(e) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.formModal.jwAccountsVoList = this.formModal.jwAccountsVoList.filter(el => {
            if (el.account && el.password) {
              el.officeId = this.formModal.officeId
              return el
            } 
          })
          console.log(this.formModal)
          this.formModal.projectId = this.user.projectId,
          saveContract(this.formModal).then(res => {
            if (res.data.code === 0) {
              this.$message.success('操作成功！');
              this.cancelModal();
              this.getContractList()
            } else {
              this.$message.error(res.data.msg);
            }
          })
        } 
        else {
          console.log(valid)
          return false;
        }
      });
    },
    edit(record) {
      let recordObj = _.cloneDeep(record)
      this.showModal = true
      if (recordObj.jwAccountsVoList) {
        this.formModal.jwAccountsVoList.forEach(el => {
          recordObj.jwAccountsVoList.forEach(i => {
            if (el.roleKey == i.roleKey) {
              el.account = i.account
              el.password = i.password
            }
          })
        })
        delete recordObj.jwAccountsVoList
      } else {
        recordObj.jwAccountsVoList = this.formModal.jwAccountsVoList
      }
      this.formModal = Object.assign(this.formModal, recordObj)
      // this.formModal = Object.assign(this.formModal, record)
    },
    delRecord(record) {
      const _that = this
      this.$confirm({
        title: '是否删除记录？',
        onOk() {
          delContract(record.id).then(res => {
            if (res.data.code === 0) {
              _that.$message.success('删除成功！');
              _that.params.pageNumber = 1
              _that.getContractList()
            } else {
              _that.$message.error(res.data.msg);
            }
          })
        }
      })
    },
    //表格 - 分页查询
    onPageChange(e) {
      this.params.pageNumber = e.current;
      this.pagination.current = e.current;
      this.getContractList()
    },
    // close() {
    //   this.params = {
    //     pageNumber: 1,
    //     pageSize: 10,
    //   };
    //   this.getEventRecordList(this.params);
    //   this.pagination.current = 1;
    //   this.$refs["formindex"].resetFields();
    // },
  },
};
</script>

<style lang="less" scoped>
::v-deep .ant-card-body {
  padding: 24px 12px 12px 0 !important;
}
::v-deep .ant-card-head-title {
  padding: 8px 0 !important;
  font-weight: bold;
}
.a_a_footer {
  // padding: 20px;
  background: #ffffff;

  .operator {
    margin-right: 8px;

    .ant-btn {
      color: #307dfa;
    }
  }

  .sTables {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      width: 100%;
      text-align: center;
      cursor: pointer;
    }

    div:nth-child(1) {
      color: #307dfa;
    }

    div:nth-child(2) {
      color: #ff5c77;
    }

    div:nth-child(3) {
      color: #615e83;
    }
  }
}
.content_div {
  padding: 5px 0;
  cursor: pointer;
}
.content_div:hover {
  color: #307dfa;
}

.plug-form .ant-form .ant-btn {
  // 处理表单组件强制margin-right问题
  margin-right: 0
}

.operator {
  .ant-btn {
  color: #307dfa;
  border-color: #307dfa;
  }
}
</style>
