var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("h3", [_vm._v("新增事件记录")]),
      _c("form-index", {
        ref: "formindex",
        attrs: { formData: _vm.formData, bottomSubmit: true },
        on: {
          handleSubmit: _vm.handleSubmit,
          handlSelect: _vm.handlSelect,
          resourceUpload: _vm.resourceUpload,
          upRemove: _vm.upRemove,
        },
        scopedSlots: _vm._u([
          {
            key: "bottomSubmit",
            fn: function () {
              return [
                _c(
                  "a-space",
                  [
                    _c(
                      "a-button",
                      { attrs: { type: "primary", "html-type": "submit" } },
                      [_vm._v(" 确定 ")]
                    ),
                    _c("a-button", { on: { click: _vm.close } }, [
                      _vm._v(" 关闭 "),
                    ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }