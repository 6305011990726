var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "admin-layout",
        [
          _c(
            "template",
            { slot: "tabBar" },
            [
              _vm.multiPage
                ? _c("tabs-head", {
                    ref: "child",
                    attrs: {
                      active: _vm.activePage,
                      "page-list": _vm.pageList,
                    },
                    on: {
                      change: _vm.changePage,
                      close: _vm.remove,
                      refresh: _vm.refresh,
                      contextmenu: _vm.onContextmenu,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("template", { slot: "content" }, [
            _c(
              "div",
              { class: ["tabs-view-content", _vm.layout, _vm.pageWidth] },
              [
                _vm.multiPage && _vm.cachePage
                  ? _c(
                      "a-keep-alive",
                      {
                        attrs: { "exclude-keys": _vm.excludeKeys },
                        model: {
                          value: _vm.clearCaches,
                          callback: function ($$v) {
                            _vm.clearCaches = $$v
                          },
                          expression: "clearCaches",
                        },
                      },
                      [
                        !_vm.refreshing
                          ? _c("router-view", {
                              key: _vm.$route.path,
                              ref: "tabContent",
                              staticStyle: { height: "100%" },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : !_vm.refreshing
                  ? _c("router-view", {
                      ref: "tabContent",
                      staticStyle: { height: "100%" },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("contextmenu", {
        attrs: { itemList: _vm.menuItemList, visible: _vm.menuVisible },
        on: {
          "update:visible": function ($event) {
            _vm.menuVisible = $event
          },
          select: _vm.onMenuSelect,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }