<template>
  <div v-if="visible" class="full_modal">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'FullModal',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {
    // 如果有需要，可以定义一些方法
  }
};
</script>

<style lang="less" scoped>
.full_modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f0f2f5;
  z-index: 100;
  overflow: auto;
}
</style>
