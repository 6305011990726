<template>
  <div class="constructiontTable_index">
    <a-row :gutter="24">
      <a-col :span="6">
        <a-card
            :bordered="true"
            class="calc_height"
            style="overflow: auto"
          >
            <!-- <div class="flex a-center j-center">
              <div class="mr-1">部门列表</div>
              <a-input-search class="flex-1 w-100" placeholder="搜索" @change="onChange" />
            </div> -->
            <a-tree
              :showSearch="true"
              :tree-data="officeTreeData"
              :auto-expand-parent="autoExpandParent"
              defaultExpandAll
              :replaceFields="{
                children: 'children',
                title: 'name',
                key: 'id',
              }"
              @select="onSelect"
            >
            </a-tree>
          </a-card>
      </a-col>
      <a-col :span="18">
        <form-index
        ref="formindex"
        :formData="formData"
        :baiduMapBoolen="false"
        @handleSubmit="handleSubmit"
        :flexAction="true"
      >
        <!-- 下部分按钮插槽 -->
        <template #flexAction>
          <a-space :style="{ marginTop: '4px' }">
            <a-button icon="search" type="primary" html-type="submit"> 查询 </a-button>
            <!-- <a-button @click="close"> 重置 </a-button> -->
            <a-space class="operator">
              <a-button @click="close"> 重置 </a-button>
            </a-space>
            <a-button icon="plus" type="primary" @click="createUser"> 新增用户 </a-button>
          </a-space>
        </template>
      </form-index>
      <!-- 表格封装 -->
      <standard-table
        class=""
        :columns="columns"
        :dataSource="dataSource"
        rowKey="id"
        :pagination="pagination"
        :loading="tableLoading"
        @change="onPageChange"
        :isAlert="false"
        :bordered="true"
      >
        <template slot="index" slot-scope="{ index }">
          <span>
            {{
              (pagination.current - 1) * pagination.pageSize +
                parseInt(index) +
                1
            }}
          </span>
        </template>
        <template slot="status" slot-scope="{ text }">
          <a-tag color="blue" v-if="text === 0">开启</a-tag>
          <a-tag v-else>关闭</a-tag>
        </template>
        <template slot="action" slot-scope="{ record }">
          <div class="sTables">
            <div @click="updateUser(record)">修改</div>
            <a-popover placement="bottomRight" trigger="click">
              <template slot="content">
                <div class="content_div" @click="setRole(record)">
                  <a-icon type="edit" style="margin-right: 5px;"/>分配角色
                </div>
                <div class="content_div" @click="resetPassUser(record)"><a-icon style="margin-right: 5px;" type="edit"/>重置密码</div>
                <div class="content_div" @click="deleteUser(record)"><a-icon style="margin-right: 5px;" type="delete" />删除</div>
              </template>
              <template slot="title">
                <span>操作</span>
              </template>
              <div>更多></div>
            </a-popover>
          </div>
        </template>
      </standard-table>
      </a-col>
    </a-row>
      <systemUserModal
      ref="systemUserModal"
      :visible="visibleExit"
      :visibleTitle="visibleTitle"
      :userId="userId"
      @handleCancel="handleCancel"/>
      <resetPasswordModal
      ref="resetPasswordModal"
      :visible="resetPasswordVisible"
      :userId="userId"
      @handleCancel="handleCancel"/>
      <setUserRoleModal
      ref="resetPasswordModal"
      :visible="setRoleVisible"
      :userId="userId"
      @handleCancel="handleCancel"/>
  </div>
</template>

<script>
const formData = [
  {
    label: "用户昵称",
    placeholder: "请输入用户昵称",
    decorator: [
      "nickName",
      { rules: [{ required: false, message: "请输入用户昵称" }] },
    ],
    type: "input",
    key: "nickName",
    col: 6,
    labelCol: 8,
    wrapperCol: 16,
  },
  {
    label: "手机号码",
    placeholder: "请输入手机号码",
    decorator: [
      "mobile",
      { rules: [{ required: false, message: "请输入手机号码" }] },
    ],
    type: "input",
    key: "mobile",
    col: 6,
    labelCol: 8,
    wrapperCol: 16,
  },
  {
    label: "状态",
    placeholder: "请选择状态",
    decorator: [
      "status",
      { rules: [{ required: false, message: "请选择状态" }] },
    ],
    type: "select",
    selectlist: [
      {
        name: '开启',
        value: 0
      },
      {
        name: '关闭',
        value: 1
      }
    ],
    key: "status",
    col: 6,
    labelCol: 8,
    wrapperCol: 16,
  },
  {
    label: "创建时间",
    placeholder: "",
    decorator: ["createTime", { rules: [{ required: false, message: "" }] }],
    type: "rangePicker",
    key: "createTime",
    col: 6,
    labelCol: 8,
    wrapperCol: 16,
    display: true,
    format: "YYYY-MM-DD HH:mm:ss",
  },
  // {
  //   label: "所属部门",
  //   placeholder: "请选择所属部门",
  //   decorator: [
  //     "deptId",
  //     { rules: [{ required: false, message: "请选择所属部门" }] },
  //   ],
  //   type: "treeSelect",
  //   key: "deptId",
  //   selectlist: [],
  //   col: 6,
  //   labelCol: 6,
  //   wrapperCol: 14,
  //   display: true,
  //   disabled: false,
  // },
  {
    type: "action",
    col: 6,
    display: true,
  }
];

import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import { mapGetters } from "vuex";
import {
  getOfficeSysUser,
  deleteUser,
  getOfficeTree
} from "@/services/sys";
import { useRender } from '@/hooks/useRender.js'
import systemUserModal from '@/pages/phasell/sys/systemUser/components/systemUserModal'
import resetPasswordModal from '@/pages/phasell/sys/systemUser/components/resetPasswordModal'
import setUserRoleModal from '@/pages/phasell/sys/systemUser/components/setUserRoleModal'
export default {
  name: 'SuijianClientSystemUserIndex',
  components: {
    formIndex,
    StandardTable,
    systemUserModal,
    resetPasswordModal,
    setUserRoleModal
  },
  data() {
    const columns = [
      {
        title: "序号",
        width: 65,
        scopedSlots: { customRender: "index" },
        // customRender: (text, record, index) => `${index + 1}`,
        align: "center",
      },
      {
        title: "用户账号",
        dataIndex: "username",
        align: "center",
        width: 120,
      },
      {
        title: "用户昵称",
        dataIndex: "nickname",
        align: "center",
        width: 120,
      },
      // {
      //   title: "手机号码",
      //   dataIndex: "mobile",
      //   align: "center",
      //   width: 120,
      // },
      {
        title: "状态",
        dataIndex: "status",
        align: "center",
        width: 60,
        scopedSlots: { customRender: "status" },
      },
      {
        title: "创建时间",
        dataIndex: "createTime",
        align: "center",
        width: 120,
        customRender: (text) => useRender.renderDate(text)
      },
      // {
      //   title: "所属公司",
      //   dataIndex: "deptName",
      //   align: "center",
      //   width: 140,
      // },
      {
        title: "备注",
        dataIndex: "remark",
        align: "center",
        width: 120,
      },
      {
        title: "操作",
        align: "center",
        width: 100,
        scopedSlots: { customRender: "action" },
      },
    ]
    return {
      formData:formData,
      columns:columns,
      tableLoading: false, //table加载中
      pagination: {
        //分页数据
        showSizeChanger:true,
        pageSizeOptions: ['10', '50', '100', '150'],
        current:1,
        pageSize:50,
        total:0,
        showLessItems:true,
        showQuickJumper:true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      params: {
        pageNo: 1,
        pageSize: 50,

      },
      dataSource: [],
      visibleExit:false,
      visibleTitle: '新增用户',
      userId: null,
      resetPasswordVisible:false,
      setRoleVisible: false,
      // 树形结构
      expandedKeys: [],
      autoExpandParent: true,
      officeTreeData: [],
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  mounted() {
    this.getData(this.params)
    this.getOfficeTree()
  },

  methods: {
    handleSubmit(e) {
      let obj = { ...e, ...this.params }
      obj.pageNo = 1
      this.pagination.current = 1;
      let query = ''
      if (obj.createTime) {
        for (const idx of Object.keys(obj.createTime)) {
          const paramss = `createTime[${idx}]`
          const subPart = `${encodeURIComponent(paramss)}=`
          query += `${subPart + encodeURIComponent(obj.createTime[idx])}&`
        }
      }
      delete obj.createTime
      this.getData(obj, query);
    },

    close() {
      this.params = {
        pageNo: 1,
        pageSize: 50,
      };
      this.pagination.current = 1;
      this.pagination.pageSize = 50;
      this.getData(this.params);
      this.$refs["formindex"].resetFields();
    },
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      for (const key in obj) {
        if (obj[key] == '') obj[key] = undefined
      }
      this.params.pageNo = e.current;
      this.pagination.current = e.current;
      this.params.pageSize = e.pageSize;
      this.pagination.pageSize = e.pageSize;
      this.getData(Object.assign({}, this.params, obj));
    },
    getData(params,query) {
      this.tableLoading = true;
      getOfficeSysUser(params,query).then(res => {
        this.tableLoading = false
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list
          this.pagination.total = res.data.data.total;
        }
      })
    },
    updateUser(record) {
      this.visibleTitle = '修改用户'
      this.userId = record.id
      this.visibleExit = true
    },
    resetPassUser(record) {
      this.userId = record.id
      this.resetPasswordVisible = true
    },
    setRole(record) {
      this.userId = record.id
      this.setRoleVisible = true
    },
    createUser() {
      this.visibleTitle = '新增用户'
      this.visibleExit = true
    },
    deleteUser(val) {
      let params = {
        id: val.id,
      };
      this.confirm("删除", `是否删除 ${val.nickname} 用户`).then(() => {
        deleteUser(params).then((res) => {
          if (res.data.code === 0) {
            this.$message.success("删除成功", 10);
            this.getData(this.params);
          } else {
            this.$message.error(res.data.msg, 10);
          }
        });
      });
    },
    // 封装弹窗确定按钮
    confirm(title, content) {
      return new Promise((resolve, reject) => {
        this.$confirm({
          title: title,
          content: content,
          onOk() {
            resolve();
          },
        });
      });
    },
    handleOk() {
      this.visibleExit = false
      this.getData(this.params)
    },
    handleCancel() {
      this.visibleExit = false
      this.resetPasswordVisible = false
      this.setRoleVisible = false
      this.userId = null
      this.getData(this.params)
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      this.formData.forEach((item) => {
        if (item.key == key) {
          item.selectlist = data;
        }
      });
    },
    getOfficeTree() {
      getOfficeTree({officeId: this.user.officeId}).then((res) => {
        this.formDataFn("deptId", res.data.data);
        this.officeTreeData = res.data.data
      });
    },
    onSelect(selectedKeys) {
      let obj = this.$refs["formindex"].getFieldValue();
      for (const key in obj) {
        if (obj[key] == '') obj[key] = undefined
      }
      this.params.deptId = selectedKeys[0];
      this.getData(Object.assign({}, this.params, obj));
    },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    }
  }
};
</script>

<style lang="less" scoped>
.constructiontTable_index {
  padding: 24px;
  background: #fff;
  border-radius: 6px;
  margin-bottom: 10px;
}

.sTables {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      width: 100%;
      text-align: center;
      cursor: pointer;
    }

    div:nth-child(1) {
      color: #307dfa;
    }

    div:nth-child(2) {
      color: #ff5c77;
    }

    div:nth-child(3) {
      color: #615e83;
    }
  }
.content_div {
  padding: 5px 0;
  cursor: pointer;
}
.content_div:hover {
  color: #307dfa;
}
.calc_height {
  height:calc(100vh - 35px)
}
</style>
