<template>
  <a-card>
    <a-spin :spinning="spinning" :tip="loadingText">
    <label v-if="dataSource.isZongLaowu==0">温馨提示：移动考勤设置暂只允许项目总包劳务员才能设置，如需更改设置，请联系总包劳务员更改设置，非常感谢！</label>
      <a-form :form="form" layout="horizontal" @submit="doSave">
            <a-form-item
              label="工人开启移动考勤"
              :labelCol="{span: 5}"
              :wrapperCol="{span: 18, offset: 1}"
            >
              <a-switch :disabled="dataSource.isZongLaowu==0"  v-model="dataSource.workIsOpen"  />
            </a-form-item>
            <a-form-item
              label="工人开启考勤范围"
              :labelCol="{span: 5}"
              :wrapperCol="{span: 18, offset: 1}"
            >
              <a-switch :disabled="dataSource.isZongLaowu==0"  v-model="dataSource.workSwitch"  />
            </a-form-item>
            <a-form-item
              label="管理人员开启移动考勤"
              :labelCol="{span: 5}"
              :wrapperCol="{span: 18, offset: 1}"
            >
              <a-switch :disabled="dataSource.isZongLaowu==0"  v-model="dataSource.manageIsOpen"  />
            </a-form-item>
            <a-form-item
              label="管理人员开启考勤范围"
              :labelCol="{span: 5}"
              :wrapperCol="{span: 18, offset: 1}"
            >
              <a-switch :disabled="dataSource.isZongLaowu==0"  v-model="dataSource.manageSwitch" />
            </a-form-item>
            <a-form-item
              label="允许考勤的范围（米）"
              :labelCol="{span: 5}"
              :wrapperCol="{span: 18, offset: 1}"
            >
              <a-input-number :disabled="dataSource.isZongLaowu==0" v-decorator="['dataSource.clockRange', { initialValue: dataSource.clockRange?dataSource.clockRange:500 }]"/>
            </a-form-item>
        <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
            <a-button icon="save" type="primary" html-type="submit">
                确认设置
            </a-button>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-card>
</template>

<script>
import {
  ATTENDANCE_QUERY_SETTING,
  ATTENDANCE_SAVE
  } from '@/services/api'
import {request} from '@/utils/request'
// import axios from '@/utils/axios'

export default {
  name: 'mobile_setting',
  components: {},
  data () {
    return {
      dataSource:{},
      spinning:false,
      loadingText:"数据加载中..."
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'validate_other' });
  },
  created() {
    this.doQuery();
  },
  methods: {
    /**
     * 获取项目设置的考勤信息
     */
    doQuery(){
        request(ATTENDANCE_QUERY_SETTING,"get").then(res => {
            console.log("init:",res)
          if(res.data.code==0){
            this.dataSource = res.data.data;
            this.dataSource.workSwitch = this.dataSource.workSwitch===1?true:false;
            this.dataSource.manageSwitch = this.dataSource.manageSwitch===1?true:false;
            this.dataSource.workIsOpen = this.dataSource.workIsOpen===1?true:false;
            this.dataSource.manageIsOpen = this.dataSource.manageIsOpen===1?true:false;
          }
        })
    },
    /**
     * 保存表单信息
     */
    doSave(e){
        e.preventDefault();
        var _this = this;
        this.form.validateFields((err, values) => {
            if (!err) {
                let {dataSource} = values;
                let setting = {...this.dataSource,...dataSource}
                var params = JSON.parse(JSON.stringify(setting))//深拷贝常用方法
                params.workSwitch = params.workSwitch?1:0;
                params.manageSwitch = params.manageSwitch?1:0;
                params.workIsOpen = params.workIsOpen?1:0;
                params.manageIsOpen = params.manageIsOpen?1:0;
                request(ATTENDANCE_SAVE,"post",params).then(res => {
                    if(res.data.code==0){
                        _this.dataSource = res.data.data;
                        _this.dataSource.workSwitch = _this.dataSource.workSwitch===1?true:false;
                        _this.dataSource.manageSwitch = _this.dataSource.manageSwitch===1?true:false;
                        _this.dataSource.workIsOpen = _this.dataSource.workIsOpen===1?true:false;
                        _this.dataSource.manageIsOpen = _this.dataSource.manageIsOpen===1?true:false;
                        _this.$message.success('设置成功');
                    } else {
                      _this.$message.error(res.data.msg);
                    }
                })
            }
        })
    },
    handleMenuClick (e) {
      if (e.key === 'delete') {
        this.remove()
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .search{
    margin-bottom: 54px;
  }
  .fold{
    width: calc(100% - 216px);
    display: inline-block
  }
  .operator{
    margin-bottom: 18px;
  }
  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
</style>
