<template>
  <div>
    <a-drawer
      title="审批直播"
      :width="520"
      :visible="visible"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose"
    > 
      <detail-list :col="1">
        <detail-list-item term="直播主题">{{livebroadcast.title}}</detail-list-item>
        <detail-list-item term="直播类型">{{livebroadcast.type | statusStr }}</detail-list-item>
        <detail-list-item term="直播时间">{{livebroadcast.startTime}} ~ {{livebroadcast.endTime}}</detail-list-item>
        <detail-list-item term="主播微信号">{{livebroadcast.anchorWechat}}</detail-list-item>
        <detail-list-item term="主播昵称">{{livebroadcast.anchorName}}</detail-list-item>
        <detail-list-item term="申请人">{{livebroadcast.createdUser}}</detail-list-item>
        <detail-list-item term="直播背景图">
          <img  style="width:200px;height:150px" :src="livebroadcast.coverPath"/>
        </detail-list-item>
        <detail-list-item term="购物封面图">
          <img style="width:200px;height:150px" :src="livebroadcast.feedsPath"/>
        </detail-list-item>
        <detail-list-item term="直播分享图">
          <img style="width:200px;height:150px" :src="livebroadcast.sharePath"/>
        </detail-list-item>
        <detail-list-item term="直播描述">{{livebroadcast.remark}}</detail-list-item>
      </detail-list>
      <a-form :form="form" layout="vertical" hide-required-mark>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="审批意见：">
              <a-textarea v-model="livebroadcast.approveRemark"
                :rows="4"
                placeholder="请输入审批的意见"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button type="primary" @click="handleSubmit(1)">
          同意
        </a-button>
        <a-button type="danger" @click="handleSubmit(2)" :style="{ marginLeft: '8px' }">
          不同意
        </a-button>
        <a-button :style="{ marginLeft: '8px' }" @click="onClose">
          取消
        </a-button>
      </div>
    </a-drawer>
  </div>
</template>
<script>
import DetailList from '@/components/tool/DetailList'
const DetailListItem = DetailList.Item
export default {
  name: "liveApprove",
  components: { DetailList,DetailListItem},
  filters: {
    statusStr(val) {
      switch (val) {
        case 0: return '会议直播'
        case 1: return '直播带货'
        case 2: return '直播秀'
        case 3: return '其他'
      }
    }
  },
  data() {
    return {
      form: this.$form.createForm(this)
    };
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    livebroadcast: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    }
  },
  methods: {
    /**
     * 取消审批
     */
    onClose() {
      this.$emit('close')
    },
    /**
     * 审批通过或驳回
     */
    handleSubmit(type){
      this.livebroadcast.approveStatus = type;
      this.$emit('submit',{approveStatus:type,approveRemark:this.livebroadcast.approveRemark})
    }
  },
};
</script>