var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "clickoutside",
          rawName: "v-clickoutside",
          value: _vm.handleDropDownOutSide,
          expression: "handleDropDownOutSide",
        },
      ],
      staticClass: "gb-ant-select-multiple-cascader",
      class: { "multiple-cascader-outside": !_vm.isClickOutSide },
    },
    [
      _c(
        "a-select",
        _vm._g(
          _vm._b(
            {
              staticStyle: { width: "100%" },
              attrs: {
                mode: "multiple",
                maxTagTextLength: _vm.maxTagTextLength,
                maxTagCount: _vm.maxTagCount,
                placeholder: _vm.placeholder,
                value: _vm.selectedValueArr,
                notFoundContent: null,
                open: _vm.isOpen,
                showArrow: "",
                allowClear: _vm.allowClear,
                optionLabelProp: "text",
                dropdownMatchSelectWidth: false,
                getPopupContainer: function (tirggerNode) {
                  return tirggerNode.parentNode
                },
              },
              on: { focus: _vm.handleFocus, deselect: _vm.handleDeselect },
              scopedSlots: _vm._u([
                {
                  key: "dropdownRender",
                  fn: function (menu) {
                    return [
                      _c("v-nodes", { attrs: { vnodes: menu } }),
                      _c("div", { class: _vm.dropdownClassName }, [
                        _vm.treeDataList.length <= 0
                          ? _c(
                              "div",
                              { staticClass: "cascader-not-content" },
                              [
                                _c("a-empty", {
                                  attrs: {
                                    image: _vm.simpleImage,
                                    description: _vm.noDataText,
                                  },
                                }),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              { staticClass: "cascader-content-wrap" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "cascader-content-container" },
                                  _vm._l(
                                    _vm.treeDataList,
                                    function (itemList, levelIndex) {
                                      return _c(
                                        "ul",
                                        {
                                          key: levelIndex,
                                          staticClass: "cascader-content-list",
                                        },
                                        [
                                          levelIndex === 0
                                            ? _c(
                                                "li",
                                                {
                                                  staticClass:
                                                    "cascader-content-item cascader-content-item-check-all",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleCascaderAllItemClick(
                                                        _vm.isCheckAll
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("a-checkbox", {
                                                    attrs: {
                                                      indeterminate:
                                                        _vm.isIndeterminateAll,
                                                    },
                                                    model: {
                                                      value: _vm.isCheckAll,
                                                      callback: function ($$v) {
                                                        _vm.isCheckAll = $$v
                                                      },
                                                      expression: "isCheckAll",
                                                    },
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "checkbox-text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_vm.allText)
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._l(
                                            itemList,
                                            function (subItem, subIndex) {
                                              return _c(
                                                "li",
                                                {
                                                  key: subItem[
                                                    _vm.selectOptionsConfig.key
                                                  ],
                                                  staticClass:
                                                    "cascader-content-item",
                                                  class: {
                                                    "cascader-content-item-active":
                                                      subItem.$active,
                                                  },
                                                  attrs: {
                                                    title:
                                                      subItem[
                                                        _vm.selectOptionsConfig
                                                          .text
                                                      ],
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleCascaderItemClick(
                                                        subItem,
                                                        subIndex,
                                                        levelIndex
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("a-checkbox", {
                                                    attrs: {
                                                      indeterminate:
                                                        !!subItem.$indeterminate,
                                                      checked:
                                                        !!subItem.$checked,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        if (
                                                          $event.target !==
                                                          $event.currentTarget
                                                        ) {
                                                          return null
                                                        }
                                                        return (function (e) {
                                                          return _vm.handleCheckClick(
                                                            e,
                                                            subItem,
                                                            subIndex,
                                                            levelIndex
                                                          )
                                                        })($event)
                                                      },
                                                    },
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "checkbox-text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          subItem[
                                                            _vm
                                                              .selectOptionsConfig
                                                              .text
                                                          ]
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  subItem[
                                                    _vm.selectOptionsConfig
                                                      .children
                                                  ] &&
                                                  subItem[
                                                    _vm.selectOptionsConfig
                                                      .children
                                                  ].length > 0
                                                    ? _c("a-icon", {
                                                        staticStyle: {
                                                          "font-size": "10px",
                                                        },
                                                        attrs: {
                                                          type: "right",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                      ]),
                    ]
                  },
                },
              ]),
            },
            "a-select",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        [
          _c("a-icon", {
            attrs: { slot: "clearIcon", type: "close-circle", theme: "filled" },
            on: { click: _vm.handleClearAll },
            slot: "clearIcon",
          }),
          _vm._l(_vm.treeToList, function (item) {
            return _c(
              "a-select-option",
              {
                key: item[_vm.selectOptionsConfig.key],
                attrs: {
                  value: item[_vm.selectOptionsConfig.value],
                  text: item[_vm.selectOptionsConfig.text],
                },
              },
              [_vm._v(" " + _vm._s(item[_vm.selectOptionsConfig.text]) + " ")]
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }