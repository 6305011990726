import {GET_STAFF_APPEAL_LIST, SET_APPEAL_STATUS, CHANGE_OPEN_APPEAL, GET_OPEN_APPEAL_CONFIG} from '@/services/api'
import {request, METHOD, removeAuthorization} from '@/utils/request'

/**
 * 获取工人申诉
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getStaffAppealList(params) {
    return request(GET_STAFF_APPEAL_LIST, METHOD.POST, params)
}


/**
 * 修改申诉状态
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function setAppealStatus(params) {
    return request(SET_APPEAL_STATUS, METHOD.POST, params)
}

/**
 * 修改申诉配置开关
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function changeOpenAppeal(params) {
    return request(CHANGE_OPEN_APPEAL, METHOD.POST, params)
}

/**
 * 修改申诉配置开关
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getOpenAppealConfig() {
    return request(GET_OPEN_APPEAL_CONFIG, METHOD.GET)
}




export default {
    getStaffAppealList,
    setAppealStatus,
    changeOpenAppeal,
    getOpenAppealConfig
}