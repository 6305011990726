var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a_Method" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.$route.meta.showFather,
              expression: "$route.meta.showFather",
            },
          ],
          staticClass: "a_m_header",
        },
        [
          _c(
            "a-tabs",
            {
              attrs: { "default-active-key": "1" },
              on: { change: _vm.callback },
            },
            [
              _c(
                "a-tab-pane",
                { key: "1", attrs: { tab: "实名制通道考勤" } },
                [_c("passageway-attendance")],
                1
              ),
              _vm.user.loginType == 0
                ? _c(
                    "a-tab-pane",
                    {
                      key: "2",
                      attrs: { tab: "小程序移动考勤", "force-render": "" },
                    },
                    [_c("attendanceSetting")],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }