<template>
  <div>
    <div v-show="$route.meta.showFather" class="a_Approval">
      <div class="plug-Overview">项目安全组织架构</div>
      <a-divider />
      <a-form-model :model="params" class="clearfix" @submit="handleSubmit">
        <a-col :span="5" v-if="user.isGcglb == 0" >
          <a-form-model-item label="工程管理部" :labelCol="{ span: 9 }" :wrapperCol="{ span: 14, offset: 1 }">
            <a-select
              v-model="params.officeId"
              allowClear
              placeholder="请选择"
            >
              <a-select-option
                v-for="item in gcglbList"
                :key="item.value"
                :value="item.value"
                :title="item.name"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>

        <a-col :span="5">
          <a-form-model-item label="项目名称" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
            <a-input placeholder="请输入项目名称" v-model="params.projectName" allowClear/>
          </a-form-model-item>
        </a-col>

        <a-col :span="5">
          <a-form-model-item label="查询人员" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
            <a-select
              v-model="params.userId"
              show-search
              allowClear
              placeholder="请输入查询人员"
            >
              <a-select-option
                :key="item.id"
                v-for="item in userSafeList"
                :value="item.id"
                :title="item.name"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>

        <a-col :span="4">
          <a-space class="btnBox">
            <a-button type="primary" html-type="submit"> 查询 </a-button>
            <a-button @click="resetQuery"> 重置 </a-button>
          </a-space>
        </a-col>
      </a-form-model>
      
      <standard-table
        :columns="columns"
        :dataSource="tableData"
        rowKey="projectId"
        :pagination="pagination"
        :loading="tableLoading"
        @change="handleChangePage"
        :isAlert="false"
        :bordered="true"
        :btnArr="tableBtnArr"
      >
        <template slot="index" slot-scope="{ index }">
          <span>{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}</span>
        </template>
        <template slot="manager" slot-scope="{ record }" v-if="record.managerList">
          <div class="post-border" v-for="item in record.managerList" :key="item.userId">
            <div> {{ item.userName }} </div>
            <div> {{ item.phone }} </div>
          </div>
        </template>
        <template slot="subManager" slot-scope="{ record }" v-if="record.subManagerList">
          <div class="post-border" v-for="item in record.subManagerList" :key="item.userId">
            <div> {{ item.userName }} </div>
            <div> {{ item.phone }} </div>
          </div>
        </template>
        <template slot="tecDuty" slot-scope="{ record }" v-if="record.tecDuty">
          <div class="post-border" v-for="item in record.tecDuty" :key="item.userId">
            <div> {{ item.userName }} </div>
            <div> {{ item.phone }} </div>
          </div>
        </template>
        <template slot="safe" slot-scope="{ record }" v-if="record.safeList">
          <div class="post-border" v-for="item in record.safeList" :key="item.userId">
            <div> {{ item.userName }} </div>
            <div> {{ item.phone }} </div>
          </div>
        </template>
        <template slot="safeNormalList" slot-scope="{ record }" v-if="record.safeNormalList">
          <div class="post-border" v-for="item in record.safeNormalList" :key="item.userId">
            <div> {{ item.userName }} </div>
            <div> {{ item.phone }} </div>
          </div>
        </template>
        <template slot="action" slot-scope="{ record }">
          <a @click="() => handleCheck(record)" > 查看 </a>
        </template>
      </standard-table>
      <Export-loading
        :cPercent="cPercent"
        :isExporting="isExporting"
        :loadingText="loadingText"
      ></Export-loading>
    </div>
    <router-view></router-view>
  </div>
</template>
  
<script>
import { GET_ALL_USER } from "@/services/api";
import {
  getCompanyStructureList,
  exportCompanyStructureList,
} from "@/services/projectDutyStructure";
import { request } from "@/utils/request";
import { QUERY_OFFICE_BY_USER } from "@/services/api";
import ExportLoading from "@/components/ExportLoading/ExportLoading"; // progress组件
import { exportProgress } from "@/pages/aqjyzl/export"; // 导出方法
import { mapGetters } from "vuex";
import StandardTable from "@/components/table/StandardTable";
import moment from "moment";

const columns = [
  {
    title: "序号",
    align: "center",
    scopedSlots: { customRender: "index" },
    width: 65,
  },
  {
    title: "工程管理部",
    align: "center",
    dataIndex: "gcglbName",
    width: 150,
  },
  {
    title: "项目名称",
    dataIndex: "projectName",
    width: 380,
    ellipsis: true,
    tooltip: true
  },
  {
    title: "项目经理",
    dataIndex: "manager",
    scopedSlots: { customRender: "manager" },
    align: "center",
  },
  {
    title: "项目副经理",
    dataIndex: "subManager",
    scopedSlots: { customRender: "subManager" },
    align: "center",
  },
  {
    title: "技术负责人",
    dataIndex: "tecDuty",
    scopedSlots: { customRender: "tecDuty" },
    align: "center",
  },
  {
    title: "专职安全员",
    dataIndex: "safe",
    scopedSlots: { customRender: "safe" },
    align: "center",
  },
  {
    title: "安全员",
    dataIndex: "safeNormalList",
    scopedSlots: { customRender: "safeNormalList" },
    align: "center",
  },
  {
    title: "操作",
    align: "center",
    fixed: "right",
    width: 100,
    scopedSlots: { customRender: "action" },
  },
];

export default {
  mixins: [exportProgress], //导出方法注入
  components: {
    ExportLoading,
    StandardTable
  },
  data() {
    return {
      params: {
        officeId: '',
        projectName: '',
        pageNumber: 1,
        pageSize: 10,
        userId: undefined
      },
      pagination: {
        //分页数据
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total, range) => `共 ${total} 条`,
      },
      gcglbList: [],
      userSafeList: [],
      total: 0,
      columns: columns,
      tableData: [],
      tableLoading: false, //table加载中
      loadingText: "数据加载中...",
      cPercent: 0,
      isExporting: false,
      tableBtnArr: [
        {
            name: '导出',
            attrs: {
                type: 'primary'
            },
            click: this.doDowload
        }
      ]
    };
  },
  mounted() {
    this.getgcglbList(QUERY_OFFICE_BY_USER);
    this.getStafFindUser();
  },
  activated() {
    this.handleQuery();
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.params.pageNumber = 1
      this.pagination.current = 1
      this.tableData = []
      this.handleQuery();
    },
    // 安全责任筛选责任人数组user
    getStafFindUser() {
      request(GET_ALL_USER, "post", {}).then((res) => {
        if (res.data.code === 0) {
          this.userSafeList = res.data.data;
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    handleQuery(e) {
      this.tableLoading = true
      getCompanyStructureList(this.params).then((res) => {
        this.tableLoading = false
        let d = res.data;
        if (d.code === 0) {
          this.tableData = d.data.list
          this.pagination.total = d.data.total;
          this.total = d.data.total;
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    handleChangePage(e) {
      this.params.pageNumber = e.current;
      this.pagination.current = e.current;
      this.handleQuery();
    },
    // 获取工程管理部数据
    getgcglbList(path) {
      request(path, "get").then((res) => {
        let data = res.data.data.filter(item => item.id != "3da0f5533f87425c9a9e8a11a7923300")
        const arr = [];
        data.forEach((item) => {
          arr.push({
            name: item.master,
            value: item.id,
          });
        });
        this.gcglbList = arr;
      });
    },
    handleCheck(e) {
      this.$router.push({
        name: `项目安全组织架构详情`,
        query: {
          projectId: e.projectId,
          projectName: e.projectName
        },
      });
    },
    resetQuery() {
      this.pagination.pageNumber = 1
      this.params = {
        officeId: '',
        projectName: '',
        pageNumber: 1,
        pageSize: 10,
        userId: undefined
      };
      this.handleQuery();
    },
    doDowload() {
      let obj = this.params;
      this.loadingText = "正在导出EXCEL文件，请稍候 ····";
      this.isExporting = true;
      this.cPercent = 0;
      let fileName = "项目安全组织架构.xlsx";
      this.exportFunNomal2(
        exportCompanyStructureList,
        obj,
        fileName,
        "application/vnd.ms-excel"
      );
    },
  },
};
</script>
  
<style lang="less" scoped>
@import "@/theme/common.less";
.query-form {
  display: flex;
  // justify-content: space-between;
}
.post-border {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 5px;
  padding-top: 5px;
  // padding-top: 10px;
  // height: 40px;
}

.post-border:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
</style>
  