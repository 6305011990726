var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        width: "1400px",
        title: _vm.title,
        footer: null,
        "dialog-style": { top: "0px" },
      },
      on: { cancel: _vm.close },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "a-form-model",
        { ref: "ruleForm", attrs: { model: _vm.params, rules: _vm.rules } },
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "作业活动/项目名称",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                        prop: "workName",
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: { disabled: _vm.isEdit != 0, placeholder: "" },
                        model: {
                          value: _vm.params.workName,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "workName", $$v)
                          },
                          expression: "params.workName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "填报时间",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                        prop: "reportTime",
                      },
                    },
                    [
                      _c("a-date-picker", {
                        attrs: {
                          disabled: _vm.isEdit != 0,
                          valueFormat: "YYYY-MM-DD",
                        },
                        model: {
                          value: _vm.params.reportTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "reportTime", $$v)
                          },
                          expression: "params.reportTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("a-divider", { attrs: { orientation: "left" } }, [
                _vm._v(" 危险源辨识 "),
              ]),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "危险源所在部位",
                        "label-col": { span: 9 },
                        "wrapper-col": { span: 14 },
                        prop: "riskResourseType",
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "240px" },
                          attrs: {
                            disabled: _vm.isEdit != 0,
                            allowClear: "",
                            placeholder: "请选择 ",
                            prop: "riskResourseType",
                          },
                          model: {
                            value: _vm.params.riskResourseType,
                            callback: function ($$v) {
                              _vm.$set(_vm.params, "riskResourseType", $$v)
                            },
                            expression: "params.riskResourseType",
                          },
                        },
                        _vm._l(_vm.options1, function (item) {
                          return _c(
                            "a-select-option",
                            {
                              key: item.value,
                              attrs: { value: item.value, title: item.label },
                            },
                            [_vm._v(" " + _vm._s(item.label) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "风险特征描述",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                        prop: "riskAttrType",
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "240px" },
                          attrs: {
                            disabled: _vm.isEdit != 0,
                            allowClear: "",
                            placeholder: "请选择 ",
                          },
                          model: {
                            value: _vm.params.riskAttrType,
                            callback: function ($$v) {
                              _vm.$set(_vm.params, "riskAttrType", $$v)
                            },
                            expression: "params.riskAttrType",
                          },
                        },
                        _vm._l(_vm.options2, function (item) {
                          return _c(
                            "a-select-option",
                            {
                              key: item.value,
                              attrs: { value: item.value, title: item.label },
                            },
                            [_vm._v(" " + _vm._s(item.label) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "可能导致的事故类型",
                        "label-col": { span: 8 },
                        "wrapper-col": { span: 14 },
                        prop: "accidentType",
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "240px" },
                          attrs: {
                            disabled: _vm.isEdit != 0,
                            allowClear: "",
                            placeholder: "请选择 ",
                          },
                          model: {
                            value: _vm.params.accidentType,
                            callback: function ($$v) {
                              _vm.$set(_vm.params, "accidentType", $$v)
                            },
                            expression: "params.accidentType",
                          },
                        },
                        _vm._l(_vm.options3, function (item) {
                          return _c(
                            "a-select-option",
                            {
                              key: item.value,
                              attrs: { value: item.value, title: item.label },
                            },
                            [_vm._v(" " + _vm._s(item.label) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("a-divider", { attrs: { orientation: "left" } }, [
                _vm._v(" 风险分析 "),
              ]),
              _c(
                "a-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: " L 值",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                        prop: "riskLType",
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "140px" },
                          attrs: { allowClear: "", placeholder: "请选择 " },
                          model: {
                            value: _vm.params.riskLType,
                            callback: function ($$v) {
                              _vm.$set(_vm.params, "riskLType", $$v)
                            },
                            expression: "params.riskLType",
                          },
                        },
                        _vm._l(_vm.options4, function (item) {
                          return _c(
                            "a-select-option",
                            {
                              key: item.value,
                              attrs: { value: item.value, title: item.label },
                            },
                            [_vm._v(" " + _vm._s(item.label) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: " E 值",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                        prop: "riskEType",
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "140px" },
                          attrs: { allowClear: "", placeholder: "请选择 " },
                          model: {
                            value: _vm.params.riskEType,
                            callback: function ($$v) {
                              _vm.$set(_vm.params, "riskEType", $$v)
                            },
                            expression: "params.riskEType",
                          },
                        },
                        _vm._l(_vm.options5, function (item) {
                          return _c(
                            "a-select-option",
                            {
                              key: item.value,
                              attrs: { value: item.value, title: item.label },
                            },
                            [_vm._v(" " + _vm._s(item.label) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: " C 值",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                        prop: "riskCType",
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "140px" },
                          attrs: { allowClear: "", placeholder: "请选择 " },
                          model: {
                            value: _vm.params.riskCType,
                            callback: function ($$v) {
                              _vm.$set(_vm.params, "riskCType", $$v)
                            },
                            expression: "params.riskCType",
                          },
                        },
                        _vm._l(_vm.options6, function (item) {
                          return _c(
                            "a-select-option",
                            {
                              key: item.value,
                              attrs: { value: item.value, title: item.label },
                            },
                            [_vm._v(" " + _vm._s(item.label) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: " D 值",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: { disabled: "", placeholder: "请输入 " },
                        model: {
                          value: _vm.riskD,
                          callback: function ($$v) {
                            _vm.riskD = $$v
                          },
                          expression: "riskD",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "风险等级",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "140px" },
                          attrs: {
                            disabled: "",
                            allowClear: "",
                            placeholder: "请选择 ",
                          },
                          model: {
                            value: _vm.riskRankType,
                            callback: function ($$v) {
                              _vm.riskRankType = $$v
                            },
                            expression: "riskRankType",
                          },
                        },
                        _vm._l(_vm.options7, function (item) {
                          return _c(
                            "a-select-option",
                            {
                              key: item.value,
                              attrs: { value: item.value, title: item.label },
                            },
                            [_vm._v(" " + _vm._s(item.label) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("a-divider", { attrs: { orientation: "left" } }, [
                _vm._v(" 风险控制措施 "),
              ]),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "工程技术",
                        "label-col": { span: 4 },
                        "wrapper-col": { span: 22 },
                      },
                    },
                    [
                      _c("a-textarea", {
                        attrs: { placeholder: "", rows: 4 },
                        model: {
                          value: _vm.params.engineeringTech,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "engineeringTech", $$v)
                          },
                          expression: "params.engineeringTech",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "安全管理",
                        "label-col": { span: 4 },
                        "wrapper-col": { span: 22 },
                      },
                    },
                    [
                      _c("a-textarea", {
                        attrs: { placeholder: "", rows: 4 },
                        model: {
                          value: _vm.params.safetyManage,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "safetyManage", $$v)
                          },
                          expression: "params.safetyManage",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "培训内容",
                        "label-col": { span: 4 },
                        "wrapper-col": { span: 22 },
                      },
                    },
                    [
                      _c("a-textarea", {
                        attrs: { placeholder: "", rows: 4 },
                        model: {
                          value: _vm.params.cultivateContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "cultivateContent", $$v)
                          },
                          expression: "params.cultivateContent",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "个体防护",
                        "label-col": { span: 4 },
                        "wrapper-col": { span: 22 },
                      },
                    },
                    [
                      _c("a-textarea", {
                        attrs: { placeholder: "", rows: 4 },
                        model: {
                          value: _vm.params.personProtect,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "personProtect", $$v)
                          },
                          expression: "params.personProtect",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "应急处置",
                        "label-col": { span: 4 },
                        "wrapper-col": { span: 22 },
                      },
                    },
                    [
                      _c("a-textarea", {
                        attrs: { placeholder: "", rows: 4 },
                        model: {
                          value: _vm.params.emergencyResponse,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "emergencyResponse", $$v)
                          },
                          expression: "params.emergencyResponse",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "风险控制措施落实情况",
                        "label-col": { span: 9 },
                        "wrapper-col": { span: 22 },
                      },
                    },
                    [
                      _c("a-textarea", {
                        attrs: { placeholder: "", rows: 4 },
                        model: {
                          value: _vm.params.riskControlState,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "riskControlState", $$v)
                          },
                          expression: "params.riskControlState",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isEdit != 2
        ? _c(
            "a-row",
            [
              _c("a-col", { attrs: { span: 12 } }),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        "label-col": { span: 4 },
                        "wrapper-col": { span: 14 },
                        prop: "year",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex flex-row-reverse" },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.sbloading,
                              },
                              on: { click: _vm.handleSubmit },
                            },
                            [_vm._v("保存")]
                          ),
                          _c(
                            "a-button",
                            { staticClass: "mr-2", on: { click: _vm.close } },
                            [_vm._v("返回")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }