<template>
  <div>
    <a-card title="查看远程视频检查" class="a_Approval" :bordered="false">
      <form-index
        ref="formindex"
        :formData="formData"
      >
      </form-index>
      <a-button type="primary" @click="addCheckContent" v-if="user.loginType != 0 && recordData.status == 0"> 添加检查内容 </a-button>
      <div class="check-content-box">
        <a-row>
          <a-col :span="8" v-for="(item, index) in checkContentList" :key="index">
            <div class="mb-2">
              <div class="video-item-box">
                <div>
                  <span style="font-size: 18px;">{{item.bigTypeName}}</span>
                  <a-icon class="ml-1 pointer" v-if="user.loginType != 0 && recordData.status == 0" type="edit" style="font-size: 22px;" @click="editCheckContent(item)"/>
                  <a-icon class="pointer mr-2" v-if="user.loginType != 0 && recordData.status == 0" type="delete" style="float: right;font-size: 22px;color: red" @click="delCheckContent(item)"/>
                </div>
                <div class="video-box">
                  <div class="flex" v-if="user.loginType != 0">
                    <div 
                      v-for="video in item.videoFilePathFull" 
                      :key="video.videoId" 
                      class="flex flex-column a-center j-center pointer m-1"
                      :style="{
                        'background': video.isAdd ? '#e8f2ff' : '#fff2f5',
                        'color': video.isAdd ? '#1a37e9' : '#e91a1a',
                      }"
                      style="width: 100px; height: 100px;border: 1px solid #F3F3F3;" 
                      @click="onClickVideo(item, video)">
                      <a-icon style="font-size: 30px;" type="play-square"/>
                      <span class="mt-1">{{video.isAdd ? '已' : '未'}}添加隐患</span>
                    </div>
                  </div>
                  <a-upload v-else :file-list="item.defaultFileList" :remove="upRemove" :data="item" :accept="accept"
                    :customRequest="resourceUpload" list-type="picture-card" :beforeUpload="beforeUpload"
                    @preview="handlePreview">
                    <div v-if="item.defaultFileList.length < 3">
                      <a-icon type="plus" />
                    </div>
                  </a-upload>
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
      <div class="mt-2" style="float: right;">
        <a-button class="mr-2" type="primary" @click="submit(0)" v-if="user.loginType == 1 && recordData.status == 0"> 提交 </a-button>
        <a-button class="mr-2" type="primary" @click="submit(1)" v-if="user.loginType == 1 && recordData.status == 2"> 创建安全整改单 </a-button>
        <a-button class="mr-2" type="primary" @click="submit(3)" v-if="user.loginType == 1 && recordData.status == 2"> 创建领导带班检查单 </a-button>
        <a-button class="mr-2" @click="close"> 返回 </a-button>
      </div>
    </a-card>
    <a-modal v-model="checkContentModal" title="添加视频巡检内容" :destroyOnClose="true" :footer="null" @cancel="cancelModal">
      <a-select style="width: 100%" @change="handleSelectChange">
        <a-select-option v-for="item in checkContentSelectlist" :key="item.id">
          {{item.name}}
        </a-select-option>
      </a-select>
      <div class="confirm-btn" @click="onAddConfirm"> <span>确定</span> </div>
    </a-modal>
    <video-content-modal
      :visible="videoContentVisible" 
      @cancel="cancelVideoModal"
      @save="saveVideo"
      :clickVideo="clickVideo"
      :clickContent="clickContent"
      :recordData="recordData"
      />
  </div>
</template>

<script>
const formData = [
  {
    label: '工程管理部',
    placeholder: '请选择工程管理部',
    decorator: ['gcglbId', { rules: [{ required: true, message: '请选择工程管理部' }] }],
    type: 'select',
    key: 'gcglbId',
    selectlist: [],
    col: 8,
    labelCol: 6,
    wrapperCol: 12,
    disabled: true
  },
  {
    label: "项目名称",
    placeholder: "请输入项目名称",
    decorator: [
      "projectId",
      { rules: [{ required: true, message: "请输入项目名称" }] },
    ],
    type: "inputGroup",
    key: "projectId",
    dataSource: [],
    col: 12,
    labelCol: 6,
    wrapperCol: 8,
    display: true,  
    disabled: true
  },
  {
    label: "检验日期",
    placeholder: "请选择",
    decorator: [
      "askCheckTime",
      { rules: [{ required: true, message: "请选择" }] },
    ],
    type: "datePicker",
    key: "askCheckTime",
    col: 8,
    labelCol: 6,
    wrapperCol: 12,
    display: true,
    format: "yyyy-MM-DD",
    styles: {width: '100%'},
    disabled: true
  },
  {
    label: '检查类别',
    placeholder: '请选择检查类别',
    decorator: ['zxjcKey', { rules: [{ required: true, message: '请选择检查类别' }] }],
    type: 'select',
    key: 'zxjcKey',
    selectlist: [],
    col: 12,
    labelCol: 6,
    wrapperCol: 8,
    disabled: true
  },
  {
    label: '检查状态',
    placeholder: '请选择检查状态',
    decorator: ['status', { rules: [{ required: true, message: '请选择检查状态' }] }],
    type: 'select',
    key: 'status',
    selectlist: [{name: '未采集视频', value: 0}, {name: '采集视频中', value: 1}, {name: '已上传视频', value: 2}, {name: '已开整改单', value: 3}],
    col: 8,
    labelCol: 6,
    wrapperCol: 12,
    disabled: true
  },
];
import formIndex from "@/pages/components/form/index";
import { QUERY_OFFICE_BY_USER, AQXJ_ZXJC, ATTACHMENT_UPLOAD } from "@/services/api";
import { request } from "@/utils/request";
import { mapGetters } from 'vuex'
import { getProjectByOffice } from "@/services/statistics";
import { saveOrUpdatePlan, getVideoCheckDetail, getTreeList, getDetailByPlanId, createVideoRecord, 
  updateVideoRecord, delVideoRecord, deleteAttachment, updateVideoPlan, createPlanCheck } from "@/services/safeMonthlyReport";
import moment from 'moment';
import VideoContentModal from './components/videoContentModal.vue';
export default {
  components: {
    formIndex,
    VideoContentModal,
  },
  inject: ["fatherMethod"],
  data() {
    return {
      // accept:'.png,.PNG,.jpg,.JPG,.pdf,.PDF,.doc,.docx',//
      accept: '.mp4',//视频上传限制
      formData: formData,
      checkContentModal: false,
      videoContentVisible: false,
      planId: '',
      selectCheckContentId: '',
      editId: '',
      recordData: {},
      clickVideo: {},//点击是视频
      clickContent: {},//点击的内容
      params: {
        id: '',
        projectId: '',
        gcglbId: '',
        projectName: ''
      },
      ptype: 1, // 1:房间，2：市政
      treeData: [
        { id: 1, value: '1', title: 'Expand to load', disabled: true, children: [] },
        { id: 2, value: '2', title: 'Expand to load', disabled: true, children: [] },
        { id: 3, value: '3', title: 'Tree Node', isLeaf: true, children: [] },
      ],
      checkContentSelectlist: [],
      checkContentList: []
    };
  },
  activated() {
    this.params.id = this.$route.query.id;
    this.getgcglbList(QUERY_OFFICE_BY_USER);
    this.getProjectStatissKqS('')
    this.getAQXJ_ZXJC(AQXJ_ZXJC);
    this.getTreeList()
    this.getDetail()
    
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  watch: {
    checkContentList(value) {
      console.log(value, 'checkContentList');
    },
  },
  methods: {
    // 删除文件
    upRemove(file) {
      const _that = this
      this.$confirm({
        title: '确定删除？',
        onOk() {
          deleteAttachment(file.videoId).then(res => {
            if (res.data.code === 0) {
              _that.$message.success('删除成功')
              _that.getVideoDetailList()
            } else {
              _that.$message.error(res.data.msg)
            }
          })
        }
      })
    },
    // 上传资源
    resourceUpload(value) {
      const formData = new FormData();
      formData.append('file', value.file);
      this.attachmentUpload(formData, value)
    },
     /*
      * 请求接口
      * type 上传组件的类型
      */
    attachmentUpload(formData, value) {
      console.log(value, 'attachmentUpload')
      // ATTACHMENT_UPLOAD上传接口
      request(ATTACHMENT_UPLOAD, "post", formData).then(res => {
        if (res.data.code === 0) {
          let videoFilePath = value.data.videoFilePath
          if (videoFilePath) {
            videoFilePath = value.data.videoFilePath.split(',')
          } else {
            videoFilePath = []
          }
          videoFilePath.push(res.data.data.id)
          videoFilePath = videoFilePath.join(',')
          let params = {
            id: value.data.id,
            planId: this.planId,
            bigType: value.data.bigType,
            bigTypePath: value.data.bigTypePath,
            videoFilePath
          }
          this.updateVideoRecord(params)
        } else {
          this.$message.warning(res.message);
        }
      })
    },
    // 上传限制处理
    beforeUpload(file){
      // const accept = '.' + file.name.substring(file.name.lastIndexOf(".") + 1);
      // if (this.accept.split(',').indexOf(accept) == -1) {
      //   this.$message.error("只能上传图片、Word文档、PDF文件");
      //   return false
      // }
    },
    // 预览图片
    handlePreview(file) {
      if (file.url) {
        window.open(file.url);
      }
    },
    onClickVideo(item, video) {
      console.log(item, video, 'onClickVideo')
      this.clickContent = item
      this.clickVideo = video
      this.videoContentVisible = true
    },
    saveVideo() {
      this.videoContentVisible = false
      this.getDetail()
    },
    cancelVideoModal(e) {
      this.videoContentVisible = e;
    },
    addCheckContent() {
      this.checkContentModal = true
    },
    cancelModal() {
      this.checkContentModal = false
      this.selectCheckContentId = ''
      this.editId = ''
    },
    editCheckContent(item) {
      this.checkContentModal = true
      this.editId = item.id
    },
    delCheckContent(item) {
      const _that = this
      this.$confirm({
        title: '确定删除？',
        onOk() {
          delVideoRecord(item.id).then(res => {
            if (res.data.code === 0) {
              _that.$message.success('删除成功')
              _that.getVideoDetailList()
            } else {
              _that.$message.error(res.data.msg)
            }
          })
        }
      })
    },
    onAddConfirm(e) {
      const item = this.checkContentSelectlist.find(el => this.selectCheckContentId == el.id)
      let params = {
        bigType: item.id,
        bigTypePath: item.pathIds,
        id: this.editId,
        planId: this.planId
      }
      if (this.editId) {
        this.updateVideoRecord(params)
      } else {
        createVideoRecord(params).then((res) => {
          if (res.data.code === 0) {
            this.$message.success('添加成功')
            this.getVideoDetailList()
          } else {
            this.$message.error(res.data.msg)
          }
        });
      }
      this.cancelModal()
    },
    updateVideoRecord(params) {
      updateVideoRecord(params).then((res) => {
        if (res.data.code === 0) {
          this.$message.success('操作成功')
          this.getVideoDetailList()
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    handleSelectChange(e) {
      this.selectCheckContentId = e
    },
    getTreeList() {
      getTreeList().then((res) => {
        if (res.data.code === 0) {
          this.checkContentSelectlist = res.data.data
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    getProjectStatissKqS(projectName) {
      getProjectByOffice({gcglbId: this.params.gcglbId, projectName}).then((res) => {
        if (res.data.code === 0) {
          this.formData.forEach((item) => {
            if ('projectId' === item.key) {
              let arr = []
              res.data.data.forEach(el => {
                arr.push({
                  title: el.projectName,
                  id: el.projectId,
                  gcglbId: el.gcglbId,
                  ptype: el.ptype
                })
              })
              item.dataSource = arr
            }
          });
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    getDetail() {
      getVideoCheckDetail(this.params.id).then(res => {
        if (res.data.code === 0) {
          this.planId = res.data.data.id
          this.recordData = res.data.data
          this.getVideoDetailList()
          res.data.data.askCheckTime = moment(res.data.data.askCheckTime).format('YYYY-MM-DD')
          this.$refs.formindex.queryObj(res.data.data)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    getVideoDetailList() {
      getDetailByPlanId(this.planId).then((res) => {
        if (res.data.code === 0) {
          res.data.data.forEach((el, recordIndex) => {
            let arr = []
            if (el.videoFilePathFull) {
              el.videoFilePathFull.forEach((video, index) => {
                arr.push({
                  recordIndex: recordIndex,
                  uid: index,
                  videoId: video.videoId,
                  name: `视频${index + 1}`,
                  url: video.filePath,
                  key: "videoPath",
                  status: 'done',
                })
              })
              el.defaultFileList = arr
            } else {
              el.defaultFileList = arr
            }
          })
          this.checkContentList = res.data.data
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    // 获取工程管理部数据
    getAQXJ_ZXJC(path) {
      request(path, "get").then((res) => {
        this.formDataFn("zxjcKey", res.data.data);
      });
    },
    getgcglbList(path) {
      request(path, "get").then((res) => {
        let data = res.data.data.filter(
          (item) => item.id != "3da0f5533f87425c9a9e8a11a7923300"
        );
        this.formDataFn("gcglbId", data);
      });
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      let arr = [];
      if (key == 'gcglbId') {
        data.forEach((item) => {
          arr.push({ name: item.master, value: item.id });
        });
      } else if (key == 'zxjcKey') {
        data.forEach((item) => {
          if (item.value != '777') {
            arr.push({ name: item.label || item.itemValue, value: item.value });
          }
        });
      }
      this.formData.forEach((item) => {
        if (item.key == key) {
          item.selectlist = arr;
        }
      });
    },
    handlSelect(obj) {
      const { val, keys } = obj
      console.log(val, keys)
      if (keys === 'gcglbId') {
        this.params.gcglbId = val
        this.getProjectStatissKqS('')
      } else if (keys === 'projectId') {
        this.params.projectId = val
        this.formData.forEach(el => {
          if (el.key === 'projectId') {
            this.params.projectName = el.dataSource.find(i => val == i.id).title
            this.ptype = el.dataSource.find(i => val == i.id).ptype
          }
        })
      }
    },
    submit(e) {
      if (e == 0) {
        this.recordData.status = 1
        this.recordData.askCheckTime = moment(this.recordData.askCheckTime).valueOf()
        updateVideoPlan(this.recordData).then((res) => {
          if (res.data.code === 0) {
            this.$message.success('操作成功')
            this.getDetail()
          } else {
            this.$message.error(res.data.msg)
          }
        });
      } else if (e == 1 || e == 3) {
        const _that = this
        this.$confirm({
        title: '创建后无法更改，请确认是否提交？',
          onOk() {
            createPlanCheck({planType: e, planId: _that.planId}).then((res) => {
              if (res.data.code === 0) {
                _that.$message.success('操作成功')
                _that.getDetail()
              } else {
                _that.$message.error(res.data.msg)
              }
            });
          }
        })
      }
    },
    close() {
      let path = this.$route.path;
      this.fatherMethod(`${path}`);
      this.$router.push({
        name: "远程视频检查台账",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.a_Approval {
  background: #fff;
  border-radius: 10px;
}
.confirm-btn {
  display: flex;
  background: #4a86e8;
  margin-top: 20px;
  padding: 8px;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  align-content: center;
  justify-content: center;
  cursor: pointer;
}
.check-content-box {
  border: 1px solid #F3F3F3;
  padding: 10px 20px;
  margin-top: 10px;
  width: 75%;
}
.video-item-box {
  width: 380px;
  margin-right: 30px;
}
.video-box {
  margin-top: 10px;
  border: 1px solid #F3F3F3;
  padding: 10px;
  min-height: 142px;
}
</style>
