var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a_Approval" },
    [
      _c("div", { staticClass: "plug-Overview" }, [
        _vm._v("安全技术交底模板管理"),
      ]),
      _c("a-divider"),
      _c(
        "a-collapse",
        {
          model: {
            value: _vm.activeKey,
            callback: function ($$v) {
              _vm.activeKey = $$v
            },
            expression: "activeKey",
          },
        },
        _vm._l(_vm.wordList, function (item, i) {
          return _c(
            "a-collapse-panel",
            { key: i + "" },
            [
              _c(
                "div",
                { attrs: { slot: "header" }, slot: "header" },
                [
                  _c("span", [_vm._v(_vm._s(item.name))]),
                  _vm.isYYZY || item.hierarchy == 10
                    ? _c(
                        "a-button",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.addModal(item.hierarchy)
                            },
                          },
                        },
                        [_vm._v("新增")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._l(item.wordConfigVoList, function (word, index) {
                return _c(
                  "p",
                  { key: index },
                  [
                    _vm._v(_vm._s(word.title) + " "),
                    word.isCustom == 1
                      ? _c(
                          "a-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.showModal(word)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    word.isCustom == 0
                      ? _c(
                          "a-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.showModal(word)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
            ],
            2
          )
        }),
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "文档详情",
            visible: _vm.visible,
            "confirm-loading": _vm.confirmLoading,
            "wrapper-col": _vm.wrapperCol,
            width: "980px",
            destroyOnClose: true,
            keyboard: false,
          },
          on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
        },
        [
          _vm.visible
            ? _c("word-content-edit", { ref: "wordContentEdit" })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }