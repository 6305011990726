<template>
  <div>
    <div class="constructiontTable_index">
      <!-- 表单封装 
              projectObj：下拉弹出框第一项对象
              Overview：项目名称
              baiduMapBoolen：控制地图显示
              topSubmit：控制上部分按钮显示
              bottomSubmit：控制下部分按钮显示
              handleSubmit：表单提交
              handleChange：输入出发
              handlSelect：输入下拉选择触发
          -->
      <form-index
        ref="formindex"
        :projectList="projectList"
        :formData="formData"
        :bottomSubmit="true"
        :topSubmit="true"
        :baiduMapBoolen="false"
        @handleSubmit="handleSubmit"
        @handleChange="handleChange"
        @handlSelect="handlSelect"
      >
        <!-- 下部分按钮插槽 -->
        <template #bottomSubmit>
          <a-button icon="search" type="primary" html-type="submit"> 查询 </a-button>
          <a-button @click="close"> 重置 </a-button>
          <!-- <a-button @click="doDowload()"> 导出花名册 </a-button> -->
          <a-button icon="plus" type="primary" @click="createItem"> 新增单位 </a-button>
        </template>
      </form-index>

      <!-- 表格封装 -->
      <standard-table
        class=""
        :columns="columns"
        :dataSource="dataSource"
        rowKey="id"
        :scroll="{ x: 1300 }"
        :pagination="pagination"
        :loading="tableLoading"
        @change="onPageChange"
        :isAlert="false"
        :bordered="true"
      >
        <template slot="index" slot-scope="{ index }">
          <span>
            {{
              (pagination.current - 1) * pagination.pageSize +
              parseInt(index) +
              1
            }}
          </span>
        </template>
        <template slot="projectName" slot-scope="{ record }">
          <!-- :style="record.pjsPath == null || record.pjsPath == ''? '' : 'color:red'" -->
          <div :style="textStyle(record)">
            {{ record.projectName }}
          </div>
        </template>
        <template slot="roleType" slot-scope="{ text }">
          <!-- :style="record.pjsPath == null || record.pjsPath == ''? '' : 'color:red'" -->
          <div v-for="item in roleTypeList" :key="item.value">
            <div v-if="text == item.value">
              {{ item.name }}
            </div>
          </div>
        </template>
        <template slot="onlineStaffCnt" slot-scope="{ text }">
          <!-- :style="record.pjsPath == null || record.pjsPath == ''? '' : 'color:red'" -->
          <div>{{ text ? text : 0 }}</div>
        </template>
        <div slot="action" slot-scope="{ record }">
          <div class="sTables">
            <div @click="viewEditing(record)">查看</div>
            <div @click="viewEditing(record)">修改</div>
            <a-popover placement="bottomLeft" trigger="focus">
              <template slot="content">
                <div class="content_div" @click="viewEditing(record)">查看</div>
                <div class="content_div" @click="viewEditing(record)">修改</div>
                <div class="content_div" @click="recorDelete(record)">删除</div>
                <div class="content_div" @click="recordView(record, 4)">
                  分配管理人员
                </div>
                <div class="content_div" @click="recordView(record, 5)">
                  设置门禁通道
                </div>
                <div class="content_div" @click="recordView(record, 6)">
                  评价
                </div>
              </template>
              <template slot="title">
                <span>操作</span>
              </template>
              <a-button type="link">更多></a-button>
            </a-popover>
          </div>
        </div>
      </standard-table>
      <Export-loading
        :cPercent="cPercent"
        :isExporting="isExporting"
        :loadingText="loadingText"
      ></Export-loading>
    </div>
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import {
  aqxjprojectsgetlist,
  aqxjprojectsdetail,
  aqxjProjectsupdate,
  aqxjprojectsdel,
} from "@/services/projectManagement";
import { request } from "@/utils/request";
import { exportProgress } from "@/pages/aqjyzl/export"; // 导出方法
import ExportLoading from "@/components/ExportLoading/ExportLoading"; // progress组件
import { AQXJ_PROJECTS_EXPORT_ZAIJIAN, STAFFLIB_EXPORT } from "@/services/api";
import { formatNumber } from "@/utils/whole";
import { projectMsgGetList } from "@/services/projectManagement";
import {
  getProAllOfficeList,
  getCheckOfficeList,
  getDetailOffice,
  delOffice
} from "@/services/participationUnit";
import { mapGetters } from "vuex";
import { getOfficeTypeList } from "@/services/sys";
import { useRender } from '@/hooks/useRender.js'
const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
    // fixed: "left",
  },
  {
    title: "单位名称",
    dataIndex: "officeName",
    align: "center",
    width: 110,
    scopedSlots: { customRender: "officeName" },
    // fixed: "left",
  },
  {
    title: "类型",
    dataIndex: "roleType",
    width: 110,
    align: "center",
    // ellipsis: true,
    scopedSlots: { customRender: "roleType" },
    // fixed: "left",
  },
  {
    title: "登记日期",
    dataIndex: "createDate",
    align: "center",
    width: 100,
   customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "合同造价(万元)",
    align: "center",
    width: 100,
    dataIndex: "htzj",
    scopedSlots: { customRender: "htzj" },
  },
  {
    title: "班组个数",
    dataIndex: "teamsCnt",
    width: 100,
    ellipsis: true,
    align: "center",
    scopedSlots: { customRender: "teamsCnt" },
  },
  {
    title: "在场人数",
    dataIndex: "onlineStaffCnt",
    align: "center",
    width: 100,
    key:"onlineStaffCnt",
    scopedSlots: { customRender: "onlineStaffCnt" },
  },
  {
    title: "退场人数",
    dataIndex: "offStaffCnt",
    align: "center",
    width: 100,
    ellipsis: true,
    key: "offStaffCnt",
  },
  {
    title: "新申请人数",
    dataIndex: "newStaffCnt",
    align: "center",
    width: 100,
    key: "newStaffCnt",
    scopedSlots: { customRender: "onlineStaffCnt" },
  },
  {
    title: "管理人员数量",
    dataIndex: "manageCnt",
    width: 100,
    align: "center",
    key: "manageCnt",
  },
  {
    title: "操作",
    align: "center",
    width: 160,
    // fixed: "right",
    scopedSlots: { customRender: "action" },
  },
];
const formData = [
  {
    label: "单位名称",
    placeholder: "请输入所属单位",
    decorator: [
      "officeName",
      { rules: [{ required: false, message: "请选择所属单位" }] },
    ],
    type: "inputGroup",
    key: "officeName",
    dataSource: [],
    col: 8,
    labelCol: 4,
    wrapperCol: 14,
  },
  {
    label: "登记日期",
    placeholder: "请输入项登记日期",
    decorator: [
      "createDateStr",
      { rules: [{ required: false, message: "请输入登记日期" }] },
    ],
    type: "datePicker",
    key: "createDateStr",
    dataSource: [],
    col: 8,
    labelCol: 6,
    wrapperCol: 14,
    format: "yyyy-MM-DD",
  },
  {
    label: "单位类型",
    placeholder: "请选择单位类型",
    decorator: [
      "roleType",
      { rules: [{ required: false, message: "请选择单位类型" }] },
    ],
    type: "select",
    key: "roleType",
    col: 8,
    labelCol: 6,
    wrapperCol: 14,
    selectlist: [],
    display: true,
  },
  //   ,
  //   {
  //     label: "现场联系人",
  //     placeholder: "请输入现场联系人",
  //     decorator: [
  //       "officeName",
  //       { rules: [{ required: false, message: "请选择现场联系人" }] },
  //     ],
  //     type: "inputGroup",
  //     key: "officeName",
  //     selectlist: [],
  //     qb: true,
  //     col: 8,
  //     labelCol: 6,
  //     wrapperCol: 14,
  //   },
  //   {
  //     label: "联系电话",
  //     placeholder: "请输入联系电话",
  //     decorator: [
  //       "officeName",
  //       { rules: [{ required: false, message: "联系电话" }] },
  //     ],
  //     type: "inputGroup",
  //     key: "officeName",
  //     selectlist: [],
  //     qb: true,
  //     col: 8,
  //     labelCol: 6,
  //     wrapperCol: 14,
  //   },
];
export default {
  mixins: [exportProgress], //导出方法注入
  components: {
    formIndex, //form表单封装
    StandardTable, //表格封装
    ExportLoading, //导出数据加载封装
  },
  data() {
    return {
      columns: columns, //表格数据
      dataSource: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showLessItems: true,
        showQuickJumper: true,
      },
      tableLoading: false,
      formData: formData,
      conTabList: [
        {
          id: 1,
          title: "项目总数",
          total: 80,
          class: "bg1",
        },
        {
          id: 2,
          title: "本月新增项目数",
          total: 3,
          class: "bg2",
        },
        {
          id: 3,
          title: "在建项目",
          total: 78,
          class: "bg1",
        },
        {
          id: 4,
          title: "停工项目",
          total: 2,
          class: "bg1",
        },
      ],
      prams: {
        pageNumber: 1,
        pageSize: 10,
      },
      projectList: [], //获取当前项目数据
      loadingText: "数据加载中...",
      cPercent: 0,
      isExporting: false,
      projectId: "",
      roleTypeList: [],
      path: "",
    };
  },
  computed: {
    textStyle() {
      return function (val) {
        if (val.statusw == 4 && (val.pjsPath != null || val.pjsPath != "")) {
          return "color:red";
        }
      };
    },
    ...mapGetters("account", ["user"]),
  },
  watch: {
    loadingText: {
      handler(newName, oldName) {
        this.loadingText = newName;
      },
      immediate: true,
    },
  },
  created() {
    this.projectId = this.user.projectId;
    this.prams.projectId = this.user.projectId
    //   if(this.$route.query.pageNumber){
    //       this.prams.pageNumber = this.$route.query.pageNumber
    //   }
    this.initData();
    this.aqxjlist(this.prams);
  },
  methods: {
    // 获取公司数组
    initData() {
      getOfficeTypeList().then((res) => {
        const data = res.data.data.companyTypeList;
        const arr = [];
        data.forEach((item) => {
          arr.push({
            name: item.label,
            value: item.value,
          });
        });
        this.roleTypeList = arr;
        this.formDataFn("roleType", arr);
      });
    },
    // 完工
    tobeFinished(val) {
      let obj = {
        id: val.id,
        projectId: val.projectId,
        status: 1,
      };
      this.confirm("完工", "是否完工").then(() => {
        this.update(obj);
      });
    },
    // 封装弹窗确定按钮
    confirm(title, content) {
      return new Promise((resolve, reject) => {
        this.$confirm({
          title: title,
          content: content,
          onOk() {
            resolve();
          },
        });
      });
    },
    // 删除接口
    recorDelete(val) {
      this.confirm("删除", "是否删除").then(() => {
        let params = {
          projectId: this.user.projectId,
          officeId: val.officeId
        }
        delOffice(params).then((res) => {
          if (res.data.code === 0) {
            this.$message.success("删除成功", 10);
            this.aqxjlist(this.prams);
          } else {
            this.$message.error(res.data.msg, 10);
          }
        });
      });
    },
    // 更新接口
    update(e) {
      aqxjProjectsupdate(e).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("成功", 10);
        } else {
          this.$message.error(res.data.msg, 10);
        }
      });
    },
    /**
     * 导出excel
     */
    doDowload() {
      let obj = {
        officeId: this.user.officeId,
        projectId: this.user.projectId,
      };
      this.loadingText = "正在导出ZIP文件，请稍候 ····";
      this.isExporting = true;
      this.cPercent = 0;
      let fileName = "参建单位花名册.zip";
      this.exportFunNomal(STAFFLIB_EXPORT, obj, fileName);
    },
    // 查看编辑
    viewEditing(e) {

      getDetailOffice(e.id).then((res) => {
        let data = {
          ...res.data.data,
          officeId:e.officeId
        }
        if (res.data.code === 0) {
          this.$router.push({
            name: "单位详情",
            query: {
              data: data,
              type: "table",
              pageNumber: this.prams.pageNumber,
            },
          });
        }
      });
    },
    handleChange(e) {
      this.projectArr = [];
      this.getZdata(getCheckOfficeList, e, "name");
      // 获取当前匹配key，赋值相对于数组
      this.formData.forEach((item) => {
        if (e.item.key === item.key) {
          item.dataSource = this.projectArr;
        }
      });
    },
    // 接口封装
    /**
     * msg 接收接口名称
     * e 数据
     * name 参数名
     * */
    getZdata(msg, e, name) {
      let data = {
        [name]: e.value,
      };

      msg(data).then((res) => {
        if (res.status == 200) {
          const data = res.data.data.list ? res.data.data.list : res.data.data
          data.forEach((item) => {
            this.projectArr.push({
              title: item[name],
              id: item.id,
            });
          });
          this.projectList = data;
        }
      });
    },
    // 新建项目
    createItem() {
      this.$router.push({name: '新增参建单位'});
    },
    // 特殊数据处理
    handlSelect({ val, keys }) {
      this.projectList.forEach((item) => {
        if (keys == "officeName" && item.id == val) {
          this.$refs["formindex"].setFieldsValue(
            item.name,
            {},
            "officeName"
          );
        }
      });
    },
    aqxjlist(prams) {
      this.tableLoading = true;
      getProAllOfficeList(prams).then((res) => {
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
        }
        this.tableLoading = false;
      });
    },
    // 根据条件查询公司
    // aqxCheckList()
    // 对下拉框做数据处理
    formDataFn(key, data) {
      this.formData.forEach((item) => {
        if (item.key == key) {
          item.selectlist = data;
        }
      });
    },
    // 提交表单数据
    handleSubmit(e) {
      let obj = Object.assign({}, e, this.prams);
      this.aqxjlist(obj);
    },
    // 重置
    close() {
      this.prams = {
        pageNumber: 1,
        pageSize: 10,
        projectId:this.user.projectId
      };
      this.aqxjlist(this.prams);
      this.$refs["formindex"].setFieldsValue("", {}, "projectName");
      this.$refs["formindex"].resetFields();
    },
    //表格 - 分页查询
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      this.prams.pageNumber = e.current;
      this.pagination.current = e.current;
      this.aqxjlist(Object.assign({}, this.prams, obj));
    },
    // 跳转--更多
    recordView(record, type) {
      switch (type) {
        case 4:
          this.path = "参建单位管理人员";
          break;
        case 5:
          this.path = "设置门禁通道";
          break;
        case 6:
          this.path = "参建单位评价";
          break;
        default:
          break;
      }
      this.$router.push({
        name: this.path,
        query: {
          data: record,
          type: type,
          projectId: record.projectId,
          officeId:record.officeId,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.bg1 {
  background: linear-gradient(
    180deg,
    rgba(242, 249, 254, 1) 0%,
    rgba(230, 244, 254, 1) 99%
  );
}

.bg2 {
  background: linear-gradient(
    180deg,
    rgba(245, 254, 242, 1) 0%,
    rgba(230, 254, 238, 1) 99%
  );
}

.constructiontTable_index {
  padding: 24px;
  background: #fff;
  border-radius: 6px;
  margin-bottom: 10px;

  .c_i_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 25px;

    .c_i_h_center {
      padding: 16px;
      border-radius: 4px;
      width: 180px;
      height: 130px;
      margin-right: 50px;

      div:nth-child(1) {
        font-size: 16px;
      }

      div:nth-child(2) {
        margin-top: 26px;
        font-size: 24px;
      }
    }
  }
}

.sTables {
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    // width: 100%;
    padding: 0 10px;
    text-align: center;
    cursor: pointer;
  }
  div:nth-child(1) {
    color: #307dfa;
  }
  div:nth-child(2) {
    color: #ff5c77;
  }
  div:nth-child(3) {
    color: #615e83;
  }
}
.content_div {
  padding: 5px 0;
  cursor: pointer;
}
.content_div:hover {
  color: #307dfa;
}
</style>
