<template>
  <div class="a_Approval">
    <div class="plug-Overview">安全隐患排查周报</div>
    <a-divider />
    <a-spin :spinning="spinning" :tip="loadingText">
      <div :class="advanced ? 'search' : null">
        <a-form class="clearfix">
          <a-col :span="6">
            <a-form-item label="选择日期" :labelCol="{ span: 7 }" :wrapperCol="{ span: 16, offset: 1 }">
              <a-range-picker @change="onChange" v-model="queryParams.plan_date"  format="yyyy-MM-DD"/>
            </a-form-item>
          </a-col>

          <a-col :span="4">
            <a-space class="btnBox">
              <a-button type="primary" @click="doQuery()">查询</a-button>
              <a-button @click="doReset()" >重置</a-button>
            </a-space>
          </a-col>
        </a-form>
      </div>
      <div>
        <standard-table
          :columns="columns"
          :dataSource="dataSource"
          :selectedRows.sync="selectedRows"
          :pagination="pagination"
          :loading="tableLoading"
          @change="onPageChange"
          :isAlert="false"
          :bordered="true"
          :btnArr="tableBtnArr"
        >
          <template slot="index" slot-scope="{ index }">
            <span>{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}</span>
          </template>
          <div slot="action" slot-scope="{ record }">
            <a :href="record.filePath" >下载</a>
          </div>
        </standard-table>
      </div>
    </a-spin>
  </div>
</template>

<script>
import StandardTable from "@/components/table/StandardTable";
import { AQXJ_WEEK_REPORT_QUERY,AQXJ_CREATE_WEEK_REPORT } from "@/services/api";
import { request } from "@/utils/request";
import moment from 'moment'
import { useRender } from '@/hooks/useRender.js'

const columns = [
  {
    title: "序号",
    align: "center",
    scopedSlots: { customRender: "index" },
    width: 65,
  },
  {
    title: "月份",
    dataIndex: "month",
    align: "center",
    customRender: (text) => useRender.renderDate(text, 'YYYY-MM')
  },
  // {
  //   title: "公司名称",
  //   dataIndex: "officeName",
  //   align: "center",
  //   scopedSlots: { customRender: "officeName" },
  // },
  {
    title: "开始日期",
    dataIndex: "startDate",
    align: "center",
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "结束日期",
    dataIndex: "endDate",
    align: "center",
    customRender: (text) => useRender.renderDate(text)
  },
  // {
  //   title: "培训地址",
  //   dataIndex: "address",
  //   align: "center",
  //   scopedSlots: { customRender: "address" },
  // },
  {
    title: "创建时间",
    dataIndex: "createTime",
    align: "center",
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  name: "QueryList",
  components: { StandardTable },
  data() {
    return {
      dataSource: [],
      advanced: false,
      columns: columns,
      dateFormat:'yyyy-MM-dd',
      selectedRows: [],
      tableLoading: false,
      spinning: false,
      loadingText: "数据加载中...",
      loadKeys: [],
      queryParams: {
        cerName: null,
        phone: null,
        address: null,
        startDateStr:null,
        endDateStr:null,
        plan_date:null,
      },
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showLessItems: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`,
      },
      tableBtnArr: [
        {
            name: '生成周报',
            attrs: {
                type: 'primary'
            },
            click: this.doCreateWeekReport
        }
      ]
    };
  },
  created() {
    this.doQuery();
  },
  authorize: {
    deleteRecord: "delete",
  },
  methods: {
    doCreateWeekReport(){
      if(this.queryParams.endDateStr==null||this.queryParams.startDateStr==null){
        this.$message.error("请选择开始日期和结束日期！");
        return;
      }
      request(AQXJ_CREATE_WEEK_REPORT, "post", this.queryParams).then((res) => {
        if (res.data.code === 0) {
          this.doReset();
        }else{
          this.$message.error(res.data.msg);
        }
      });
    },

    moment,
    onChange(dateString){
      this.queryParams.startDateStr = this.queryParams.plan_date[0].format('YYYY-MM-DD')
      this.queryParams.endDateStr = this.queryParams.plan_date[1].format('YYYY-MM-DD')
    },
    queryDataMonthChange(dateString){
      if (dateString === '' || dateString=== null || dateString === undefined) {
          this.queryParams.month = ''
          return
      }
      this.queryParams.month = moment(dateString).format('YYYY-MM')
    },
    deleteRecord(key) {
      this.dataSource = this.dataSource.filter((item) => item.key !== key);
      this.selectedRows = this.selectedRows.filter((item) => item.key !== key);
    },
    toggleAdvanced() {
      this.advanced = !this.advanced;
    },
    remove() {
      this.dataSource = this.dataSource.filter(
        (item) =>
          this.selectedRows.findIndex((row) => row.key === item.key) === -1
      );
      this.selectedRows = [];
    },

    /**
     * 获取所有人员的签名记录信息
     */
    doQuery() {
      this.tableLoading = true;
      request(AQXJ_WEEK_REPORT_QUERY, "post", this.queryParams).then((res) => {
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
          this.dataSource.forEach((obj) => {
            obj.key = obj.id;
          });
        }
        this.tableLoading = false;
      });
    },
    doReset() {
      this.queryParams = {};
      this.doQuery();
    },
    //分页查询
    onPageChange(e) {
      this.queryParams.pageNumber = e.current;
      this.pagination.current = e.current;
      this.doQuery();
    },
    
  },
};
</script>

<style lang="less" scoped>
.search {
  margin-bottom: 54px;
}
.fold {
  width: calc(100% - 216px);
  display: inline-block;
}
.operator {
  margin-bottom: 18px;
}

@media screen and (max-width: 900px) {
  .fold {
    width: 100%;
  }
}
</style>
