var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("form-index", {
        ref: "formindex",
        attrs: { formData: _vm.formData, flexAction: true },
        on: {
          handleChange: _vm.handleChange,
          handleSubmit: _vm.handleSubmit,
          handlSelect: _vm.handlSelect,
        },
        scopedSlots: _vm._u([
          {
            key: "flexAction",
            fn: function () {
              return [
                _c(
                  "a-space",
                  {
                    staticStyle: { "margin-left": "25px", "margin-top": "5px" },
                  },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: {
                          icon: "search",
                          type: "primary",
                          "html-type": "submit",
                        },
                      },
                      [_vm._v(" 查询 ")]
                    ),
                    _c(
                      "a-space",
                      { staticClass: "operator" },
                      [
                        _c("a-button", { on: { click: _vm.close } }, [
                          _vm._v(" 重置 "),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "a_a_footer" },
        [
          _c(
            "div",
            { staticClass: "mb-1" },
            [
              _vm.canOperate
                ? _c(
                    "a-space",
                    { staticClass: "operator" },
                    [
                      _c(
                        "a-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.doDowloadBatch(1)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "iconfont tongguo mr-05 " }),
                          _vm._v("批量通过"),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-space",
                { staticClass: "operator" },
                [
                  _c(
                    "a-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.doDowloadBatch(2)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "iconfont bohui mr-05 " }),
                      _vm._v("批量驳回"),
                    ]
                  ),
                ],
                1
              ),
              _vm.canOperate
                ? _c(
                    "a-space",
                    { staticClass: "operator" },
                    [
                      _c(
                        "a-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.batchModify()
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "iconfont fenpei mr-05 " }),
                          _vm._v("批量修改班组"),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-space",
                { staticClass: "operator" },
                [
                  _c(
                    "a-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.exportStaff()
                        },
                      },
                    },
                    [
                      _c("a-icon", { attrs: { type: "download" } }),
                      _vm._v("导出待审批人员信息"),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.canOperate
                ? _c(
                    "a-space",
                    { staticClass: "operator" },
                    [
                      _c(
                        "a-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.batchPlanExitDate(1)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "iconfont fenpei mr-05 " }),
                          _vm._v("批量添加拟退场时间"),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("standard-table", {
            attrs: {
              columns: _vm.columns,
              dataSource: _vm.dataSource,
              selectedRows: _vm.selectedRows,
              rowKey: "id",
              scroll: { x: 1300 },
              pagination: _vm.pagination,
              loading: _vm.tableLoading,
              isAlert: false,
              bordered: true,
            },
            on: {
              "update:selectedRows": [
                function ($event) {
                  _vm.selectedRows = $event
                },
                function ($event) {
                  return _vm.updateSelectRows($event)
                },
              ],
              "update:selected-rows": function ($event) {
                _vm.selectedRows = $event
              },
              change: _vm.onPageChange,
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function (ref) {
                  var index = ref.index
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            (_vm.pagination.current - 1) *
                              _vm.pagination.pageSize +
                              parseInt(index) +
                              1
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "staffName",
                fn: function (ref) {
                  var record = ref.record
                  return [
                    _c(
                      "router-link",
                      {
                        staticStyle: { display: "block" },
                        attrs: {
                          to:
                            "/personal_info?projectPersonId=" +
                            record.projectPersonId,
                        },
                      },
                      [_vm._v(_vm._s(record.staffName))]
                    ),
                    _vm._l(record.riskTipsText, function (item, index) {
                      return _c(
                        "a-tag",
                        { key: index, attrs: { color: "#ee3f4d" } },
                        [
                          record.riskTipsList && item == "多项目"
                            ? [
                                _vm.showTooltip(record.riskTipsList)
                                  ? _c(
                                      "a-popover",
                                      [
                                        _c(
                                          "template",
                                          { slot: "content" },
                                          _vm._l(
                                            _vm.showNames(record.projectNames),
                                            function (item, index) {
                                              return _c("div", { key: index }, [
                                                _vm._v(_vm._s(item)),
                                              ])
                                            }
                                          ),
                                          0
                                        ),
                                        _c("template", { slot: "title" }, [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: { color: "#FF5D78" },
                                            },
                                            [_vm._v("已在场项目")]
                                          ),
                                        ]),
                                        _c("span", [_vm._v(_vm._s(item))]),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                              ]
                            : [_vm._v(" " + _vm._s(item) + " ")],
                        ],
                        2
                      )
                    }),
                  ]
                },
              },
              {
                key: "filePath",
                fn: function (ref) {
                  var text = ref.text
                  return [
                    _c("a-avatar", {
                      attrs: {
                        shape: "square",
                        size: 64,
                        src: text,
                        icon: "user",
                      },
                    }),
                  ]
                },
              },
              {
                key: "planExitDate",
                fn: function (ref) {
                  var text = ref.text
                  return [_c("span", [_vm._v(_vm._s(text ? text : "/"))])]
                },
              },
              {
                key: "action",
                fn: function (ref) {
                  var record = ref.record
                  return _c("div", {}, [
                    _c(
                      "div",
                      { staticClass: "sTables" },
                      [
                        _vm.canOperate
                          ? _c(
                              "div",
                              {
                                staticStyle: { color: "#307dfa" },
                                on: {
                                  click: function ($event) {
                                    return _vm.doDowloadBatch(1, record)
                                  },
                                },
                              },
                              [_vm._v("通过")]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticStyle: { color: "#ff5c77" },
                            on: {
                              click: function ($event) {
                                return _vm.doDowloadBatch(2, record)
                              },
                            },
                          },
                          [_vm._v("驳回")]
                        ),
                        !_vm.canOperate
                          ? _c(
                              "div",
                              {
                                staticStyle: { color: "#307dfa" },
                                on: {
                                  click: function ($event) {
                                    return _vm.visit(record)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            )
                          : _vm._e(),
                        _vm.canOperate
                          ? _c(
                              "a-popover",
                              {
                                attrs: {
                                  placement: "bottomRight",
                                  trigger: "click",
                                },
                              },
                              [
                                _c("template", { slot: "content" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "content_div",
                                      on: {
                                        click: function ($event) {
                                          return _vm.visit(record)
                                        },
                                      },
                                    },
                                    [_vm._v("查看")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "content_div",
                                      on: {
                                        click: function ($event) {
                                          return _vm.setInfo(record)
                                        },
                                      },
                                    },
                                    [_vm._v("修改")]
                                  ),
                                  _vm.canOperate
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "content_div",
                                          on: {
                                            click: function ($event) {
                                              return _vm.batchModify(record)
                                            },
                                          },
                                        },
                                        [_vm._v("分配班组")]
                                      )
                                    : _vm._e(),
                                ]),
                                _c("template", { slot: "title" }, [
                                  _c("span", [_vm._v("操作")]),
                                ]),
                                _c("div", [_vm._v("更多>")]),
                              ],
                              2
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ])
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "分配班组", width: 750, destroyOnClose: true },
          on: { cancel: _vm.cancleModifyPersonTeam, ok: _vm.modifyPersonTeam },
          model: {
            value: _vm.teamDialogVisible,
            callback: function ($$v) {
              _vm.teamDialogVisible = $$v
            },
            expression: "teamDialogVisible",
          },
        },
        [
          _c("form-index", {
            ref: "formindexModal",
            attrs: { formData: _vm.formDataModal, flexSubmit: true },
            on: {
              onDateChange: _vm.onDateChange,
              handlSelect: _vm.batchHandlSelect,
            },
          }),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { width: 500, destroyOnClose: true },
          on: { cancel: _vm.cancleModifyExitDate },
          model: {
            value: _vm.planExitDateVisible,
            callback: function ($$v) {
              _vm.planExitDateVisible = $$v
            },
            expression: "planExitDateVisible",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "text-center mb-2",
              staticStyle: { "font-size": "17px", color: "black" },
            },
            [
              _c(
                "span",
                {
                  staticClass: "mr-3 font-bold",
                  staticStyle: { color: "black" },
                },
                [_vm._v("批量添加")]
              ),
              _c("span", [
                _vm._v("已选 " + _vm._s(_vm.selectedRows.length) + " 人"),
              ]),
            ]
          ),
          _c("form-index", {
            ref: "formExitDateModal",
            attrs: { formData: _vm.formDataExitDate, flexSubmit: true },
            on: { onDateChange: _vm.onDateChange },
          }),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "a-space",
                { attrs: { size: "large" } },
                [
                  _c("a-button", { on: { click: _vm.cancleModifyExitDate } }, [
                    _vm._v(" 取消 "),
                  ]),
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary", disabled: _vm.batchExitDate },
                      on: { click: _vm.modifyExitDate },
                    },
                    [_vm._v(" 确定 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("Export-loading", {
        attrs: {
          cPercent: _vm.cPercent,
          isExporting: _vm.isExporting,
          loadingText: _vm.loadingText,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }