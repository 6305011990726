<template>
  <a-card>
    <a-spin :spinning="spinning" :tip="loadingText">
      <div :class="advanced ? 'search' : null">
        <a-form layout="horizontal">
          <div :class="advanced ? null : 'fold'">
            <a-row>
              <a-col :md="8" :sm="24">
                <a-form-item
                  label="直播主题"
                  :labelCol="{ span: 5 }"
                  :wrapperCol="{ span: 18, offset: 1 }"
                >
                  <a-input
                    placeholder="请输入"
                    v-model="queryParams.title"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item
                  label="微信号"
                  :labelCol="{ span: 5 }"
                  :wrapperCol="{ span: 18, offset: 1 }"
                >
                  <a-input
                    placeholder="请输入"
                    v-model="queryParams.anchorWechat"
                  />
                </a-form-item>
              </a-col>
            </a-row>
          </div>
          <span style="float: right; margin-top: 3px">
            <a-button type="primary" @click="doQuery()">查询</a-button>
            <a-button style="margin-left: 8px" @click="doReset()"
              >重置</a-button
            >
          </span>
        </a-form>
      </div>
      <div>
        <standard-table
          :columns="columns"
          :dataSource="dataSource"
          :selectedRows.sync="selectedRows"
          :pagination="pagination"
          :loading="tableLoading"
          @clear="onClear"
          @change="onPageChange"
        >
          <div slot="do-status" slot-scope="{ text }">
            <a-icon v-if="text == 1" style="color: green" type="check" />
            <a-icon v-else-if="text == 0" type="check" />
            <a-icon v-else style="color: red" type="close" />
          </div>
          <div slot="action" slot-scope="{ text, record }">
            <a href="#" @click="doApprove(`${record.key}`)" v-if="text == 0">审批</a>
            <a-divider v-if="text == 0" type="vertical" />
            <router-link
              :to="{
                name: '直播信息详情',
                query: { id: `${record.key}`, view: true },
              }"
              >查看详情</router-link
            >
          </div>
        </standard-table>
      </div>
    </a-spin>
    <approver
      :visible="approve.visible"
      :livebroadcast="approve.livebroadcast"
      @close="doCloseApprove"
      @submit="doSubmit($event)"
    ></approver>
  </a-card>
</template>

<script>
import StandardTable from "@/components/table/StandardTable";
import approver from "@/pages/livebroadcast/approve";
import { LIVE_BROADCAST_QUERY,LIVE_BROADCAST_DETAIL,LIVE_BROADCAST_APPROVE } from "@/services/api";
import { request } from "@/utils/request";
import { te } from "date-fns/locale";
// import axios from '@/utils/axios'
import { useRender } from '@/hooks/useRender.js'

const columns = [
  {
    title: "直播主题",
    dataIndex: "title",
    align: "center",
    scopedSlots: { customRender: "title" },
  },
  {
    title: "主播呢称",
    dataIndex: "anchorName",
    align: "center",
    scopedSlots: { customRender: "anchorName" },
    // customRender: (text) => {
    //   return text.substring(0,6)+"************"+text.substring(text.length-4,text.length)
    // }
  },
  {
    title: "主播微信号",
    dataIndex: "anchorWechat",
    align: "center",
    scopedSlots: { customRender: "anchorWechat" },
  },
  {
    title: "直播开始时间",
    dataIndex: "startTime",
    align: "center",
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "直播结束时间",
    dataIndex: "endTime",
    align: "center",
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "申请人",
    dataIndex: "createdUser",
    align: "center",
    scopedSlots: { customRender: "createdUser" },
    // customRender: (text) => {
    //   return text+"（人）"
    // }
  },
  {
    title: "审批状态",
    dataIndex: "approveStatus",
    align: "center",
    scopedSlots: { customRender: "do-status" },
    // customRender: (text) => {
    //   return text+"（人）"
    // }
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  name: "QueryList",
  components: { StandardTable, approver },
  data() {
    return {
      dataSource: [],
      advanced: false,
      columns: columns,
      selectedRows: [],
      tableLoading: false,
      spinning: false,
      loadingText: "数据加载中...",
      loadKeys: [],
      queryParams: {
        title: null,
        anchorWechat: null,
        pageNumber: 1,
      },
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showLessItems: true,
        showQuickJumper: true,
      },
      approve: {
        visible: false,
        livebroadcast: {},
      },
    };
  },
  created() {
    this.doQuery();
  },
  authorize: {
    deleteRecord: "delete",
  },
  methods: {
    deleteRecord(key) {
      this.dataSource = this.dataSource.filter((item) => item.key !== key);
      this.selectedRows = this.selectedRows.filter((item) => item.key !== key);
    },
    toggleAdvanced() {
      this.advanced = !this.advanced;
    },
    remove() {
      this.dataSource = this.dataSource.filter(
        (item) =>
          this.selectedRows.findIndex((row) => row.key === item.key) === -1
      );
      this.selectedRows = [];
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },

    /**
     * 获取所有人员的签名记录信息
     */
    doQuery() {
      this.tableLoading = true;
      request(LIVE_BROADCAST_QUERY, "post", this.queryParams).then((res) => {
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
          this.dataSource.forEach((obj) => {
            obj.key = obj.id;
          });
        }
        this.tableLoading = false;
      });
    },
    doReset() {
      this.queryParams = {};
      this.doQuery();
    },
    //分页查询
    onPageChange(e) {
      this.queryParams.pageNumber = e.current;
      this.pagination.current = e.current;
      this.doQuery();
    },
    handleMenuClick(e) {
      if (e.key === "delete") {
        this.remove();
      }
    },
    doCloseApprove() {
      this.approve.visible = false;
    },
    doApprove($id){
      //查询该直播的最新数据
      this.doDetail($id);
    },
    doDetail(id){
      request(LIVE_BROADCAST_DETAIL+id, "get", {}).then((res) => {
        if (res.data.code === 0) {
          this.approve.livebroadcast = res.data.data;
          this.approve.visible = true;
        }else{
          this.$message.error("直播详情获取失败！")
        }
      });
    },
    doSubmit($event){
      Object.assign(this.approve.livebroadcast,$event);
      console.log(this.approve.livebroadcast.approveStatus);
      request(LIVE_BROADCAST_APPROVE, "post", this.approve.livebroadcast).then((res) => {
        if (res.data.code === 0) {
          this.approve.visible = false;
          this.$message.success("审批完成！");
          this.doQuery();
        }else{
          this.$message.error("审批异常！");
        }
      });
    }
  },
};
</script>

<style lang="less" scoped>
.search {
  margin-bottom: 54px;
}
.fold {
  width: calc(100% - 216px);
  display: inline-block;
}
.operator {
  margin-bottom: 18px;
}

@media screen and (max-width: 900px) {
  .fold {
    width: 100%;
  }
}
</style>
