<template>
    <a-card >
        <a-form-model :model="projectConfig" :label-col="labelCol" :wrapper-col="wrapperCol">

            <a-form-model-item label="入场模式">
                <a-radio-group v-model="projectConfig.rcMode">
                    <a-radio value="1">
                        详细模式
                    </a-radio>
                    <a-radio value="2">
                        简洁模式
                    </a-radio>
                </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="是否上报建委">
                <a-switch v-model="projectConfig.pushJw" checked-children="是" un-checked-children="否" @change="pushJwChange" />
            </a-form-model-item>

            <a-form-model-item label="是否上报重点办">
                <a-switch v-model="projectConfig.pushZdb" checked-children="是" un-checked-children="否" @change="pushZdbChange" />
            </a-form-model-item>
            <a-form-model :model="zdbContent" :label-col="labelCol" :wrapper-col="wrapperCol" v-if="projectConfig.pushZdb===1">
                <a-form-model-item label="项目ID">
                    <a-input v-model="zdbContent.projectId" />
                </a-form-model-item>
                <a-form-model-item label="appid">
                    <a-input v-model="zdbContent.appid" />
                </a-form-model-item>
                <a-form-model-item label="secret">
                    <a-input v-model="zdbContent.secret" />
                </a-form-model-item>
            </a-form-model>


            <a-form-model-item label="是否上报代建局">
                <a-switch v-model="projectConfig.pushDjj" checked-children="是" un-checked-children="否" @change="pushDjjChange" />
            </a-form-model-item>
            <a-form-model :model="djjContent" :label-col="labelCol" :wrapper-col="wrapperCol" v-if="projectConfig.pushDjj===1">
                <a-form-model-item label="项目ID">
                    <a-input v-model="djjContent.projectId" />
                </a-form-model-item>
                <a-form-model-item label="accessKey">
                    <a-input v-model="djjContent.accessKey" />
                </a-form-model-item>
                <a-form-model-item label="accessNo">
                    <a-input v-model="djjContent.accessNo" />
                </a-form-model-item>
            </a-form-model>

            <a-form-model-item label="是否开启备用考勤">
                <a-switch v-model="projectConfig.jwbykq" checked-children="是" un-checked-children="否" @change="jwbykqChange" />
            </a-form-model-item>
            <a-form-model :model="jwbykqContent" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-form-model-item label="工程密钥">
                    <a-input v-model="jwbykqContent.gcms" />
                </a-form-model-item>
                <a-form-model-item label="工程编码">
                    <a-input v-model="jwbykqContent.projectCode" />
                </a-form-model-item>
                <a-form-model-item label="接入编号">
                    <a-input v-model="jwbykqContent.accessNo" />
                </a-form-model-item>
                <a-form-model-item label="进场设备">
                    <a-input v-model="jwbykqContent.enterClientId" />
                </a-form-model-item>
                <a-form-model-item label="出场设备">
                    <a-input v-model="jwbykqContent.leaveClientId" />
                </a-form-model-item>
            </a-form-model>





            <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
                <a-button type="primary" @click="saveProjectConfig">
                    保存
                </a-button>
            </a-form-model-item>
        </a-form-model>
    </a-card>
</template>


<script>
    import { getProjectConfig, updateProjectConfig } from '@/services/projectConfig';
    import {IMPORT_STAFF_PAYROLL_TAX} from '@/services/api'
    import {mapGetters} from 'vuex'
    import moment from 'moment'
    import Cookie from 'js-cookie'


    export default {
        data() {
            return {
                labelCol: { span: 4 },
                wrapperCol: { span: 14 },
                projectConfig:{
                    rcMode: '',
                    pushZdb: 0,
                    pushJw: 0,
                    jwbykq: 0,


                },
                zdbContent:{
                    projectId:'',
                    appid:'',
                    secret:''
                },
                djjContent:{
                    projectId:'',
                    accessKey:'',
                    accessNo:''

                },
                jwbykqContent:{
                    gcms:'',
                    projectCode:'',
                    accessNo:'',
                    enterClientId: '',
                    leaveClientId: ''
                }
            };
        },
        computed: {
            ...mapGetters('account', ['user']),
        },
        created() {
            this.getProjectConfig()
        },
        methods: {

            getProjectConfig(){
                getProjectConfig().then(res => {
                    this.projectConfig = res.data.data;
                    this.projectConfig.rcMode = this.projectConfig.rcMode+""
                    this.zdbContent = this.projectConfig.zdbContent
                    this.djjContent = this.projectConfig.djjContent
                    this.jwbykqContent = this.projectConfig.jwbykqContent
                })
            },
            saveProjectConfig(){
                this.projectConfig.zdbContent = this.zdbContent
                this.projectConfig.djjContent = this.djjContent
                this.projectConfig.jwbykqContent = this.jwbykqContent
                updateProjectConfig(this.projectConfig).then(res => {
                    if(res.data.code==0){
                        this.$message.success('设置成功');
                    }
                })

            },
            pushZdbChange(checked){
                this.projectConfig.pushZdb = checked?1:0;
            },
            pushJwChange(checked){
                this.projectConfig.pushJw = checked?1:0;
            },
            pushDjjChange(checked){
                this.projectConfig.pushDjj = checked?1:0;
            },
            jwbykqChange(checked){
                this.projectConfig.jwbykq = checked?1:0;
            }
        },


    };

</script>

<style>

</style>