<template>
  <div class="a_Method">
    <a-row :gutter="16">
      <a-col :span="24">
        <a-card
          title="班组设置门禁通道"
          :bordered="false"
          style="height: 900px"
          :style="'overflow:auto'"
        >
          <a-tree
            v-model="checkedKeys"
            :tree-data="treeDataSource"
            checkable
            @check="onCheck"
            checkStrictly
            :defaultExpandParent="true"
            :defaultExpandAll="true"
            v-if="!spinning"
          >
          <a-spin :spinning="spinning" />
          </a-tree>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { getGroupList, addGroup, delGroup } from "@/services/dmgr";
import { treeData } from "@/utils/treeutil";
import {
  addGroupOfficeAccControl,
  getGroupTeamAccControl,
  getTeamDetail,
} from "@/services/participationUnit";

export default {
  data() {
    return {
      query: this.$route.query,
      treeDataSource: [],
      checkedKeys: [],
      originData: [],
      officeTree: [],
      queryData: {
        projectId: "",
      },
      addNewDialog: false,
      fatherGroupId: "",
      officeId: this.$route.query.officeId,
      teamId: this.$route.query.id,
      teamObj: {},
      spinning: true
    };
  },

  activated() {
    this.officeId = this.$route.query.officeId,
    this.teamId = this.$route.query.teamId,
    this.getGroupList();
  },

  methods: {
    init() {},
    getGroupList() {
      getTeamDetail(this.query.teamId).then((res) => {
        this.teamObj = res.data.data;
        this.queryData.projectId = res.data.data.projectId;
        getGroupList({ projectId: this.teamObj.projectId }).then((res) => {
          this.originData = res.data.data;
          let list = treeData(
            res.data.data,
            "groupId",
            "fatherGroupId",
            "children",
            "0"
          );
          this.treeDataSource = list.map((el) => this.mapTree(el));
          this.spinning = false;
        });
        getGroupTeamAccControl(this.teamId).then((res) => {
          this.officeTree = res.data.data;
          let arr = [];
          this.originData.forEach((group) => {
            if (group.fatherGroupId == "0") {
              this.fatherGroupId = group.groupId;
            }
          });
          res.data.data.forEach((item) => {
            // if (!(item.groupId == this.fatherGroupId)) {
            //   arr.push(item.groupId);
            // }
            arr.push(item.groupId);
          });
          this.checkedKeys = arr;
        });
      });
    },
    mapTree(org) {
      const haveChildren =
        Array.isArray(org.children) && org.children.length > 0;
      return {
        //分别将我们查询出来的值做出改变他的key
        // officeId:org.officeId,
        projectId: org.projectId,
        key: org.groupId,
        title: org.groupName,
        //判断它是否存在子集，若果存在就进行再次进行遍历操作，知道不存在子集便对其他的元素进行操作
        children: haveChildren ? org.children.map((i) => this.mapTree(i)) : [],
      };
    },
    onCheck(checkedKeys, e) {
      this.checkedKeys = checkedKeys.checked;
      let params = {
        groupIds: this.checkedKeys,
        officeId: this.officeId,
        teamId: this.teamId,
        projectId: this.queryData.projectId,
      };
      console.log(params,'params==')
      addGroupOfficeAccControl(params).then((el) => {
        if (el.data.code === 0) {
          this.$message.success("修改成功！");
          this.addNewDialog = false;
          this.groupId = "";
          this.fatherGroupId = "";
          this.getGroupList();
        } else {
          this.$message.error(el.data.msg);
          this.$message.error("至少设置一个门禁通道");
          this.getGroupList();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.a_Method {
  // background: #fff;
  // padding: 20px;
  // border-radius: 10px;
}
</style>
