import {request, METHOD} from '@/utils/request'
import { SUB_POINT_PAGE, SUB_POINT_TOTAL, INSERT_SUB_POINT, SUBMIT_SUB_POINT, DEL_SUB_POINT, SUB_POINT_DETAIL, SUB_POINT_ITEM } from '@/services/api'

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getSubPointPage(params) {
  return request(SUB_POINT_PAGE, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getSubPointTotal(params) {
  return request(SUB_POINT_TOTAL, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function insertSubPoint(params) {
  return request(INSERT_SUB_POINT, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function submitSubPoint(params) {
  return request(SUBMIT_SUB_POINT, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function delSubPoint(params) {
  return request(DEL_SUB_POINT + `/${params}`, METHOD.DELETE)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getSubPointDetail(params) {
  return request(SUB_POINT_DETAIL + `/${params}`, METHOD.GET)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function subPointItemList(params) {
  return request(SUB_POINT_ITEM, METHOD.POST, params)
}

