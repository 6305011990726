var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-drawer",
        {
          attrs: {
            title: "审批直播",
            width: 520,
            visible: _vm.visible,
            "body-style": { paddingBottom: "80px" },
          },
          on: { close: _vm.onClose },
        },
        [
          _c(
            "detail-list",
            { attrs: { col: 1 } },
            [
              _c("detail-list-item", { attrs: { term: "直播主题" } }, [
                _vm._v(_vm._s(_vm.livebroadcast.title)),
              ]),
              _c("detail-list-item", { attrs: { term: "直播类型" } }, [
                _vm._v(_vm._s(_vm._f("statusStr")(_vm.livebroadcast.type))),
              ]),
              _c("detail-list-item", { attrs: { term: "直播时间" } }, [
                _vm._v(
                  _vm._s(_vm.livebroadcast.startTime) +
                    " ~ " +
                    _vm._s(_vm.livebroadcast.endTime)
                ),
              ]),
              _c("detail-list-item", { attrs: { term: "主播微信号" } }, [
                _vm._v(_vm._s(_vm.livebroadcast.anchorWechat)),
              ]),
              _c("detail-list-item", { attrs: { term: "主播昵称" } }, [
                _vm._v(_vm._s(_vm.livebroadcast.anchorName)),
              ]),
              _c("detail-list-item", { attrs: { term: "申请人" } }, [
                _vm._v(_vm._s(_vm.livebroadcast.createdUser)),
              ]),
              _c("detail-list-item", { attrs: { term: "直播背景图" } }, [
                _c("img", {
                  staticStyle: { width: "200px", height: "150px" },
                  attrs: { src: _vm.livebroadcast.coverPath },
                }),
              ]),
              _c("detail-list-item", { attrs: { term: "购物封面图" } }, [
                _c("img", {
                  staticStyle: { width: "200px", height: "150px" },
                  attrs: { src: _vm.livebroadcast.feedsPath },
                }),
              ]),
              _c("detail-list-item", { attrs: { term: "直播分享图" } }, [
                _c("img", {
                  staticStyle: { width: "200px", height: "150px" },
                  attrs: { src: _vm.livebroadcast.sharePath },
                }),
              ]),
              _c("detail-list-item", { attrs: { term: "直播描述" } }, [
                _vm._v(_vm._s(_vm.livebroadcast.remark)),
              ]),
            ],
            1
          ),
          _c(
            "a-form",
            {
              attrs: {
                form: _vm.form,
                layout: "vertical",
                "hide-required-mark": "",
              },
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "审批意见：" } },
                        [
                          _c("a-textarea", {
                            attrs: { rows: 4, placeholder: "请输入审批的意见" },
                            model: {
                              value: _vm.livebroadcast.approveRemark,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.livebroadcast,
                                  "approveRemark",
                                  $$v
                                )
                              },
                              expression: "livebroadcast.approveRemark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              style: {
                position: "absolute",
                right: 0,
                bottom: 0,
                width: "100%",
                borderTop: "1px solid #e9e9e9",
                padding: "10px 16px",
                background: "#fff",
                textAlign: "right",
                zIndex: 1,
              },
            },
            [
              _c(
                "a-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleSubmit(1)
                    },
                  },
                },
                [_vm._v(" 同意 ")]
              ),
              _c(
                "a-button",
                {
                  style: { marginLeft: "8px" },
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      return _vm.handleSubmit(2)
                    },
                  },
                },
                [_vm._v(" 不同意 ")]
              ),
              _c(
                "a-button",
                { style: { marginLeft: "8px" }, on: { click: _vm.onClose } },
                [_vm._v(" 取消 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }