import { QUERY_OFFICE_BY_USER } from "@/services/api";
import { request } from "@/utils/request";
import { mapGetters } from "vuex";
import { ATTACHMENT_UPLOAD } from "@/services/api";

const columns = [
    {
        title: "序号",
        align: "center",
        scopedSlots: { customRender: "index" },
        width: 65,
    },
    {
        title: "公司",
        align: "center",
        dataIndex: "companyName",
        ellipsis: true,
        tooltip: true,
        width: 200,
    },
    {
        title: "分公司",
        align: "center",
        dataIndex: "branchOfficeName",
        ellipsis: true,
        tooltip: true,
        width: 200,
    },
    {
        title: "项目名称",
        dataIndex: "projectName",
        width: 200,
        align: "center",
    },
    {
        title: "月份",
        dataIndex: "ym",
        width: 140,
        align: "center",
    },

    {
        title: "提交时间",
        dataIndex: "tjtime",
        width: 100,
        align: "center",

    },
    {
        title: "提交人",
        dataIndex: "tjname",
        align: "center",
        width: 100,
    },
   

    {
        title: "操作",
        align: "center",
        width: 120,
        scopedSlots: { customRender: "action" },
    },
];

export default {
    data() {
        return {
            onlyGcglbList: [],
            columns,

            filePath1: [],
            uploading1: false,
            accept1: "",
        };
    },
    computed: {
        ...mapGetters("account", ["user"]),
        isBranchOffice() { //分公司
            return this.user.isGcglb == 1 && this.user.loginType == 1
        },
        isProject() { //项目部
            return this.user.loginType == 0
        },
        isSafe() { //安全部
            return this.user.isGcglb == 0 && this.user.loginType == 1
        },
        isProjectLeader() {
            // user.nowRoleKey 包含 'project_leader'
            return this.user.nowRoleKey.includes('project_leader')
        },
        isProjectSafer() {
            // user.nowRoleKey 包含 'PROJECT_AQY_ZB_FZR'
            return this.user.nowRoleKey.includes('PROJECT_AQY_ZB_FZR')
        }
    },
    created() {
        request(QUERY_OFFICE_BY_USER, "get").then((res) => {
            let data = res.data.data;
            const arr1 = []
            data.forEach((item) => {
             
              arr1.push({
                label: item.master,
                value: item.id,
              });
            });
      
            this.onlyGcglbList = arr1;
          });
    },
    methods: {
        calcCount(list,item,index, paramKey) {
    
            let reCount;
            if (index > 0 && item[paramKey] === list[index - 1][paramKey]) {
                reCount = 0;
            } else {
                let count = 1;
                for (let i = index + 1; i < list.length; i++) {
                    if (item[paramKey] === list[i][paramKey]) {
                        count++;
                    } else {
                        break;
                    }
                }
                reCount = count;
            }
            return reCount;
        
        },
    },
};
