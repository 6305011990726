var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-card", [
    _c(
      "div",
      {
        staticStyle: {
          "background-color": "#FFF",
          "padding-top": "10px",
          padding: "10px 10px",
        },
      },
      [
        _c(
          "div",
          { class: _vm.advanced ? "search" : null },
          [
            _c("a-form", { attrs: { layout: "horizontal" } }, [
              _c(
                "div",
                { class: _vm.advanced ? null : "fold" },
                [
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { attrs: { md: 8, sm: 24 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: "任务名称",
                                labelCol: { span: 5 },
                                wrapperCol: { span: 18, offset: 1 },
                              },
                            },
                            [
                              _c("a-input", {
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.queryParams.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryParams, "name", $$v)
                                  },
                                  expression: "queryParams.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { md: 8, sm: 24 } },
                        [
                          _c(
                            "a-button",
                            {
                              staticStyle: {
                                "margin-left": "10px",
                                "margin-top": "3px",
                              },
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.doQuery()
                                },
                              },
                            },
                            [_vm._v("查询")]
                          ),
                          _c(
                            "a-button",
                            {
                              staticStyle: { "margin-left": "8px" },
                              on: {
                                click: function ($event) {
                                  return _vm.doReset()
                                },
                              },
                            },
                            [_vm._v("重置")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    float: "right",
                    "margin-top": "-50px",
                    "margin-right": "10px",
                  },
                },
                [
                  _c(
                    "a-button",
                    { attrs: { type: "primary" }, on: { click: _vm.addTask } },
                    [_vm._v("新增任务")]
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c("standard-table", {
          attrs: {
            columns: _vm.columns,
            dataSource: _vm.dataSource,
            selectedRows: _vm.selectedRows,
            pagination: _vm.pagination,
            loading: _vm.tableLoading,
            rowKey: "id",
            isAlert: false,
          },
          on: {
            "update:selectedRows": function ($event) {
              _vm.selectedRows = $event
            },
            "update:selected-rows": function ($event) {
              _vm.selectedRows = $event
            },
            clear: _vm.onClear,
            change: _vm.onPageChange,
          },
          scopedSlots: _vm._u([
            {
              key: "name",
              fn: function (ref) {
                var text = ref.text
                var record = ref.record
                return _c("div", {}, [
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.viewTask(record)
                        },
                      },
                    },
                    [_vm._v(_vm._s(text))]
                  ),
                ])
              },
            },
            {
              key: "action",
              fn: function (ref) {
                var text = ref.text
                var record = ref.record
                return _c(
                  "div",
                  {},
                  [
                    _c("a-icon", {
                      staticStyle: { color: "#FC5531" },
                      attrs: { type: "setting", title: "查看任务" },
                      on: {
                        click: function ($event) {
                          return _vm.viewTask(record)
                        },
                      },
                    }),
                    _c("a-divider", { attrs: { type: "vertical" } }),
                    _c("a-icon", {
                      staticStyle: { color: "blue" },
                      attrs: { type: "edit", title: "编辑任务" },
                      on: {
                        click: function ($event) {
                          return _vm.editTask(record)
                        },
                      },
                    }),
                    _c("a-divider", { attrs: { type: "vertical" } }),
                    _c("a-icon", {
                      staticStyle: { color: "red" },
                      attrs: { type: "delete", title: "删除任务" },
                      on: {
                        click: function ($event) {
                          return _vm.TaskDelete(record)
                        },
                      },
                    }),
                  ],
                  1
                )
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }