<template>
    <a-card :bordered="false">
        <a-row>
            <a-col :span="24">
                <div style="text-align: center;font-size: 28px">
                    {{user.loginLaoWuUser.projectName}}
                </div>
            </a-col>

        </a-row>
        <a-row type="flex" justify="center" align="top" style="margin-top: 20px">
            <a-col >
                <a-radio-group :default-value="dateChoose" v-model="dateChoose" @change="dateChooseChange" v-if="projectConfig.yqsbFrequency == 1">
                    <a-radio-button value="0">
                        昨天
                    </a-radio-button>
                    <a-radio-button value="1">
                        今天
                    </a-radio-button>
                    <a-radio-button value="2">
                        明天
                    </a-radio-button>
                    <a-radio-button value="3" :disabled="dateChoose!=3">
                        其他
                    </a-radio-button>
                </a-radio-group>


                <a-radio-group :default-value="dateChoose" v-model="dateChoose" @change="dateChooseChange" v-if="projectConfig.yqsbFrequency == 2">
                    <a-radio-button value="0">
                        上周
                    </a-radio-button>
                    <a-radio-button value="1">
                        本周
                    </a-radio-button>
                    <a-radio-button value="2">
                        下周
                    </a-radio-button>
                    <a-radio-button value="3" :disabled="dateChoose!=3">
                        其他
                    </a-radio-button>
                </a-radio-group>


                <a-radio-group :default-value="dateChoose" v-model="dateChoose" @change="dateChooseChange" v-if="projectConfig.yqsbFrequency == 3">
                    <a-radio-button value="0">
                        上月
                    </a-radio-button>
                    <a-radio-button value="1">
                        本月
                    </a-radio-button>
                    <a-radio-button value="2">
                        下月
                    </a-radio-button>
                    <a-radio-button value="3" :disabled="dateChoose!=3">
                        其他
                    </a-radio-button>
                </a-radio-group>


            </a-col>
            <a-col>
                <div style="margin-left: 5px">
                    <a-date-picker v-model="day" :format="dayFormat" @change="dateChange" v-if="projectConfig.yqsbFrequency == 1" />
                    <a-week-picker v-model="week" :format="weekFormat" placeholder="选择周" @change="dateChange" v-if="projectConfig.yqsbFrequency == 2" />
                    <a-month-picker v-model="month" :format="monthFormat" placeholder="选择月" @change="dateChange" v-if="projectConfig.yqsbFrequency == 3" />
                </div>
            </a-col>
        </a-row>
        <a-row align="top" type="flex" style="margin-top: 30px">
            <a-card title="申报数据概况" style="width:30%;display: inline-block" >
                <a-row>
                    应申报人数：{{report.allNum}}人
                </a-row>
                <a-row>
                    已申报人数：{{report.reportNum}}人
                </a-row>
                <a-row>
                    未申报人数：{{report.noReportNum}}人
                </a-row>
            </a-card>
            <a-card title="未申报班组列表" style="margin-left: 5%;width:65%;display: inline-block" >

                <a-table :columns="columns" :data-source="report.epidemicReportTeamStatisticsVoList" >
                    <template slot="num" slot-scope="text, record">
                        <span >{{ record.noReportNum + '/' + record.allNum }}</span>
                    </template>
                </a-table>
            </a-card>
        </a-row>
    </a-card>

</template>

<script>
    import { getProjectConfig } from '@/services/projectConfig';
    import { getReportStatistics } from '@/services/epidemic';
    import {mapGetters} from 'vuex'
    import moment from 'moment';

    const columns = [
        {
            title: '班组名称',
            dataIndex: 'teamName',
            key: 'teamName',
        },
        {
            title: '未申报人数/总人数',
            dataIndex: 'num',
            key: 'num',
            slots: { title: 'num' },
            scopedSlots: { customRender: 'num' },
        },
        {
            title: '班组长手机号',
            dataIndex: 'foremanPhone',
            key: 'foremanPhone',
        },
    ];



    export default {
        data() {
            return {

                queryData:{
                    projectId: "",
                    startDateTime: '',
                    endDateTime:'',
                },
                report:{
                    teamName:'',
                    noReportNum:'',
                    allNum:'',
                    reportNum:'',
                    epidemicReportTeamStatisticsVoList:[]
                },
                data: [],
                columns,
                projectConfig:{},

                dayDefault: '',
                dayFormat: "YYYY-MM-DD",
                weekDefault: '',
                weekFormat: 'YYYY-w周',
                monthDefault: '',
                monthFormat: 'YYYY-MM',

                day: '',
                week: '',
                month: '',

                dateChoose: '1'

            };
        },

        computed: {
            ...mapGetters('account', ['user']),
        },
        filters: {
        },

        created() {
            const date = new Date;
            this.day = moment(date).format('YYYY-MM-DD')
            const year = moment(date).format('YYYY')
            this.week = year + "-" + moment(date).week()
            this.month =moment(date).format('YYYY-MM')
            this.queryData.projectId = this.user.projectId
            this.init();


        },
        methods: {
            init(){
                getProjectConfig().then(res => {
                    this.projectConfig = res.data.data
                }).finally(()=>{
                    const e = {
                        target:{
                            value:'1'
                        }
                    }
                    this.dateChooseChange(e)
                })
            },
            dateFormat(date, dateString){
                if (this.projectConfig.yqsbFrequency == 1){
                    this.queryData.startDateTime = dateString + " 00:00:00";
                    this.queryData.endDateTime = dateString + " 23:59:59";
                }
                if (this.projectConfig.yqsbFrequency == 2){
                    dateString = dateString.replace("周", "");
                    const selectMonthNum = dateString.split("-")[1];
                    const nowDate = new Date;
                    const nowMomentNum = moment(nowDate).week()
                    this.queryData.startDateTime = moment().week(nowMomentNum - (nowMomentNum-selectMonthNum)).startOf('week').format("YYYY-MM-DD 00:00:00")
                    this.queryData.endDateTime = moment().week(nowMomentNum - (nowMomentNum-selectMonthNum)).endOf('week').format("YYYY-MM-DD 23:59:59")
                }
                if (this.projectConfig.yqsbFrequency == 3){
                    this.queryData.startDateTime = moment(dateString).startOf('month').format('YYYY-MM-DD 00:00:00')
                    this.queryData.endDateTime = moment(dateString).endOf('month').format('YYYY-MM-DD 23:59:59')
                }
            },
            dateChange(date, dateString) {
                this.dateChoose = '3'
                this.dateFormat(date, dateString);

            },
            dateChooseChange(e){
                this.dateChoose = e.target.value
                if (this.projectConfig.yqsbFrequency == 1){
                    this.dateFormat(null, moment().add(this.dateChoose-1, 'days').format(this.dayFormat))
                    this.day = moment().add(this.dateChoose-1, 'days').format(this.dayFormat)
                }
                if (this.projectConfig.yqsbFrequency == 2){
                    this.dateFormat(null, moment().add(this.dateChoose-1, "weeks").format(this.weekFormat))
                    this.week = moment().add(this.dateChoose-1, 'weeks').format(this.dayFormat)

                }
                if (this.projectConfig.yqsbFrequency == 3){
                    this.dateFormat(null, moment().add(this.dateChoose-1, 'months').format(this.dayFormat))
                    this.month = moment().add(this.dateChoose-1, 'months').format(this.dayFormat)
                }
                console.log(this.queryData.startDateTime, this.queryData.endDateTime)
                this.getReportStatistics()
            },
            getReportStatistics(){
                getReportStatistics(this.queryData).then(res => {
                    this.report = res.data.data
                })
            },
            moment,
        }
    };
</script>