<template>
  <a-modal width="960px" v-model="show" :title="title" :footer="null" @cancel="close">
      <a-form-model ref="ruleForm" :model="params" :rules="rules">
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="公司" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }">
              <a-input v-model="params.companyName" disabled placeholder=""/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="分公司" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" >
              <a-input v-model="params.branchOfficeName" disabled placeholder=""/>

            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="项目名称" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" >
              <a-input v-model="params.projectName" disabled placeholder=""/>
              
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="危大工程类别" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="gclbId">
              <a-select v-model="params.gclbId" allowClear placeholder="请选择 " style="width: 240px">
                <a-select-option v-for="item in options1" :key="item.value" :value="item.value" :title="item.label">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="施工方案名称" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="sgfaName">
              <a-input v-model="params.sgfaName" placeholder="请输入 "/>
              
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="是否超一定规模" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="overRange">
              <a-select v-model="params.overRange" allowClear placeholder="请选择 " style="width: 240px">
                <a-select-option v-for="item in options" :key="item.value" :value="item.value" :title="item.label">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-row>
              <a-col :span="12">

                <a-form-model-item label="是否专家认证" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="expertProve">
                  <a-select v-model="params.expertProve" allowClear placeholder="请选择" style="width: 240px">
                    <a-select-option v-for="item in options" :key="item.value" :value="item.value" :title="item.label">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="12" v-if="params.expertProve==1">
                <a-form-model-item  label="论证时间" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="proveTime">
                  <a-date-picker v-model="params.proveTime" valueFormat="YYYY-MM-DD" />
                  
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-col>
       
          <a-col :span="12">
            <a-form-model-item label="最终审批时间" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="aproveTime">
              <a-date-picker v-model="params.aproveTime" valueFormat="YYYY-MM-DD" />
              
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="编制时间" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="makeTime">
              <a-date-picker v-model="params.makeTime" valueFormat="YYYY-MM-DD" />
              
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="附件" :label-col="{ span: 3 }" :wrapper-col="{ span: 14 }">
              <a-upload class="avatar-uploader" list-type="picture-card" :accept="accept1" :file-list="filePath1"
                @change="handleUploadChange1" :customRequest="resourceUpload1" @preview="handlePreview"
                :beforeUpload="beforeUpload1">

                <div v-if="filePath1.length < 3">
                  <a-icon :type="uploading1 ? 'loading' : 'plus'" />
                </div>
                <!-- <div class="tit-fi">支持pdf格式上传</div> -->
              </a-upload>
            </a-form-model-item>
          </a-col>
          

        </a-row>
      </a-form-model>
      <a-row v-if="isEdit != 2">
        <a-col :span="12"></a-col>
        <a-col :span="12">
          <a-form-model-item :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }" prop="year">
            <div class="flex flex-row-reverse">
              <a-button type="primary" :loading="sbloading" @click="handleSubmit">保存</a-button>
              <a-button class="mr-2" @click="close">返回</a-button>
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>
  </a-modal>
</template>

<script>

import { request } from "@/utils/request";
import { mapGetters } from 'vuex'
import moment from "moment";
import { constructplanAdd,constructplanUpdate, constructplanByID } from "@/services/dangerousProject";
import { ATTACHMENT_UPLOAD } from "@/services/api";
import gcglib from './mixin'

export default {
  name: 'etDetail',
  computed: {
    ...mapGetters('account', ['user']),
  },
  props: {
      visible: {
          type: Boolean,
          default: false
      },
      isEdit: {
          type: Number,
          default: 0
      },
      id: {
          default: ''
      },
      projectInfo: {
        default: () => {
          return {}
        }
      }
      

  },
  mixins: [gcglib],
  data() {
    return {
      // isEdit: 0,
      rules: {
        sgfaName: [{ required: true, message: '请输入 施工方案名称', trigger: 'change' }],
        
      },

      params: {
        "companyId": "29805", // 公司ID 
        "companyName": "广州恒大有限公司", // 公司名称 
        "branchOfficeId": "4829", // 分公司ID 
        "branchOfficeName": "湖南恒大分公司", // 分公司名称 
        "projectId": "test001", // 项目ID 
        "projectName": "测试项目1", // 项目名称 
        "gclbId": "1", // 工程类别id 1.深基坑工程2.模板工程及支撑体系3.起重吊装及安装拆卸工程4.脚手架工程5.拆除工程6.暗挖工程7.其他 
        "gclbName": "深基坑名称", // 工程类别名称 
        "sgfaName": "恒大许老板", // 施工方案名称 
        "overRange": "1", // 是否超过一定规模 0 否 1 是 
        "expertProve": "0", // 是否专家论证 0 否 1是 
        "proveTime": "2023-12-31", // 论证时间 yyyy-MM-dd 
        "aproveTime": "2024-12-31", // 最终审批时间 yyyy-MM-dd 
        "makeTime": "2025-12-31", // 编制时间 yyyy-MM-dd 
        "attachUrl": "", // 附件url 多个以逗号分割 
        "attachName": "测试.pdf" // 附件名称 多个以逗号分割 
      },
      sbloading: false,
      title: '新增',
      show: false,

    };
  },
 
  watch: {
    visible(val) {
      console.log(val)
      
      if (this.isEdit == 0) {
          // dom 渲染后
        
        this.params = {
          "companyId": this.projectInfo.parentOfficeId, // 公司ID 
          "companyName": this.projectInfo.parentOffice, // 公司名称 
          "branchOfficeId": this.projectInfo.gcglbId, // 分公司ID 
          "branchOfficeName": this.projectInfo.gcglbName, // 分公司名称 
          "projectId": this.projectInfo.projectId, // 项目ID 
          "projectName": this.projectInfo.projectName, // 项目名称 
          "gclbId": "", // 工程类别id 1.深基坑工程2.模板工程及支撑体系3.起重吊装及安装拆卸工程4.脚手架工程5.拆除工程6.暗挖工程7.其他 
          "gclbName": "", // 工程类别名称 
          "sgfaName": "", // 施工方案名称 
          "overRange": "", // 是否超过一定规模 0 否 1 是 
          "expertProve": "", // 是否专家论证 0 否 1是 
          "proveTime": "", // 论证时间 yyyy-MM-dd 
          "aproveTime": "", // 最终审批时间 yyyy-MM-dd 
          "makeTime": "", // 编制时间 yyyy-MM-dd 
          "attachUrl": "", // 附件url 多个以逗号分割 
          "attachName": "" // 附件名称 多个以逗号分割 
        }
        // 新增 birthday 取 30年前
        // this.params.birthday = moment().subtract(30, 'years').format('YYYY-MM-DD');
        // this.params.age = 30;
        this.filePath1 = [];
        this.title = '新增 危大工程专项施工方案'
      } else {
          this.title = '编辑 危大工程专项施工方案'
          this.params.id = this.id;
          this.getDetail()

      }
      this.show = val;
    }
  },
  methods: {
    getDetail() {
      constructplanByID({id: this.params.id}).then(res => {
        if (res.data.code == 0) {
          this.params = res.data.data;
          if (this.params.attachUrl) {
              let att = this.params.attachUrl
              let name = this.params.attachName.split(',')
            this.filePath1 = att.split(',').map((item,index) => {
              return {
                name: name[index],
                url: item,
                status: 'done',
                uid: index,
              }
            })
          } else {
            this.filePath1 = []
          }
         
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },

    handleSubmit() {
      this.sbloading = true
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let d = this.params;  
          let attach1 = this.filePath1.map(item => item.url).join(',');
          let attachname1 = this.filePath1.map(item => item.name).join(',');
          let gclbName = this.options1.find(item => item.value == d.gclbId).label
          d.attachUrl = attach1;
          d.attachName = attachname1;
          if (d.expertProve == 0) {
            d.proveTime = ''
          }
          d.gclbName = gclbName
          if (this.isEdit == 0) {
            constructplanAdd(d).then(res => {
              if (res.data.code == 0) {
                this.$message.success('提交成功！')
                this.close();
                this.$emit('refresh')
              } else {
                this.$message.error(res.data.msg)
              }
              this.sbloading = false
            })
          } else {
            constructplanUpdate(d).then(res => {
              if (res.data.code == 0) {
                this.$message.success('修改成功！')
                this.close();
                this.$emit('refresh')
              } else {
                this.$message.error(res.data.msg)
              }
              this.sbloading = false
            })
          }
        } else {
          console.log('error submit!!');
          this.sbloading = false
          return false;
        }
      });
    },
    close() {
      this.resetForm()
      this.$emit('closeDialog')
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
      this.filePath1 = []
    },
    
  },
};
</script>

<style lang="less" scoped>
.a_Approval {
  background: #fff;
  border-radius: 10px;
}

.btn_group {
  padding-right: 190px;
}
</style>
