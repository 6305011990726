<template>

    <div >
        <div id="components-form-demo-advanced-search">
            <a-form class="ant-advanced-search-form" :form="form" >
                <a-row :gutter="24">
                    <a-col :span="5" >
                        <a-form-item :label="`月份`">
                            <a-input v-model="option.month" placeholder="请输入月份,格式：202105 " />
                        </a-form-item>
                    </a-col>

                    <a-col :span="6">
                        <a-form-item :label="`所属单位`">
                            <a-select
                                    label-in-value
                                    @change="handleChangeOffice"
                                    placeholder="请选择所属单位"
                            >
                                <a-select-option v-for="(office, index) in officeList" :key="index" :value="office.id">
                                    {{office.name}}
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>

                    <a-col :span="6" >
                        <a-form-item :label="`班组`">
                            <a-select
                                    label-in-value
                                    @change="handleChangeTeam"
                                    placeholder="请选择班组"
                            >

                                <a-select-option v-for="(team, index) in teamList" :key="index" :value="team.id">
                                    {{team.teamName}}
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :span="4" >
                        <a-form-item :label="`状态`">
                            <a-select
                                    v-model = "option.type"
                                    placeholder="请选择状态"
                            >
                                <a-select-option value="1">
                                    出勤
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>

                <a-row>
                    <a-col :span="24" :style="{ textAlign: 'right' }">
                        <a-button type="primary" icon="search" :loading="loading" @click="handleSearch">
                            查询
                        </a-button>
                        <a-button :style="{ marginLeft: '8px' }" icon="download" :loading="loading" type="primary" @click="exportAttendSheet">
                            导出
                        </a-button>
                    </a-col>
                </a-row>

            </a-form>
        </div>
        <a-table
                :columns="columns"
                :data-source="data"
                :pagination="pagination"
                :loading="loading"
                rowKey="index"
        >
            <span slot="isChangeTeam">
                是否多个班组考勤
                <a-tooltip placement="top">
                    <template slot="title">
                      <span>该人员在该月是否切换过班组</span>
                    </template>
                    <a-icon type="question-circle" />
                </a-tooltip>

            </span>
            <template style="background: #ffffff;" slot="name" slot-scope="text"> {{ text }} </template>

            <template slot="isChangeTeam" slot-scope="text">
                {{text == 0? "否" : "是"}}
            </template>
        </a-table>
    </div>

</template>
<script>
    import axios from 'axios';
    import {getAttendSheet, exportAttendSheet, getOfficeListByProjectId, getTeamListByOfficeId} from '@/services/payroll'

    const queryData = params => {
        console.log(params)
        return getAttendSheet(params);
    };
    const columns = [
        {
            title: '序号',
            dataIndex: 'index',
        },
        {
            title: '姓名',
            dataIndex: 'name',
        },
        {
            title: '身份证号码',
            dataIndex: 'idCard',
        },
        {
            title: '性别',
            dataIndex: 'sex',
        },
        {
            title: '银行卡号',
            dataIndex: 'bankCard',
        },
        {
            title: '开户行',
            dataIndex: 'bankName',
        },
        {
            title: '出勤天数',
            dataIndex: 'dayNum',
        },
        {
            title: '应发金额',
            dataIndex: 'payableMoney',
        },
        {
            title: '实发金额',
            dataIndex: 'actualMoney',
        },
        {
            // title: '是否切换班组',
            dataIndex: 'isChangeTeam',
            key: 'name',
            slots: { title: 'isChangeTeam' },
            scopedSlots: { customRender: 'isChangeTeam' },
        },
    ];

    export default {
        data() {
            return {
                data: [],
                pagination: {},
                loading: false,
                columns,
                option: {
                    officeId: "",
                    teamId: "",
                    month: "",
                    officeName: "",
                    teamName: "",
                    type: '1',
                },
                officeList: [],
                teamList: [],


                expand: false,
                form: this.$form.createForm(this, { name: 'advanced_search' }),
            };
        },
        mounted() {
            // this.fetch(this.option);
            this.getOfficeListByProjectId();
        },
        methods: {
            handleTableChange(pagination, filters, sorter) {
                console.log(pagination);
                const pager = { ...this.pagination };
                pager.current = pagination.current;
                this.pagination = pager;
                this.fetch(this.option);
            },



            fetch(params = {}) {
                this.loading = true;
                queryData(params).then(({ data }) => {
                    console.log(data)
                    this.data = data.data;



                    this.loading = false;
                });
            },

            handleSearch(e) {
                this.fetch(this.option);
            },
            handleChangeTeam(val){
                this.option.teamId = val.key;
                this.option.teamName = val.label;
            },
            handleChangeOffice(val){
                this.option.officeId = val.key;
                this.option.officeName = val.label;
                this.getTeamListByOfficeId();
            },
            exportAttendSheet(){
                // console.log(this.startTime,this.endTime)
                exportAttendSheet(this.option).then((res) => {
                    console.log(res)
                    if(res.data.code == -1){
                        this.$message.warning(res.data.msg)
                    } else  {
                        console.log(res)
                        let fileName = this.option.teamName + '_' + this.option.month + '工资表.xls'


                        // let tableData = res.data
                        // let blob = new Blob([tableData], {type: 'application/vnd.ms-excel'})//创建Blob对象,Blob(blobParts[, options])blobParts： 数组类型， 数组中的每一项连接起来构成Blob对象的数据

                        let downloadElement = document.createElement('a')//创建dom

                        // let href = window.URL.createObjectURL(blob) //创建下载链接
                        let href = window.URL.createObjectURL(res.data) //创建下载链接

                        downloadElement.href = href
                        downloadElement.download = fileName //下载后文件名
                        document.body.appendChild(downloadElement)//添加创建的节点
                        downloadElement.click() //点击下载
                        document.body.removeChild(downloadElement) //下载完成移除元素
                        window.URL.revokeObjectURL(href) //释放掉blob对象
                        this.isLoading = false
                    }
                }).catch((err) => {
                    setTimeout(() => {
                        this.isLoading = false
                    }, 500);
                })
            },
            getOfficeListByProjectId(){
                getOfficeListByProjectId().then((res) => {
                    console.log(res)
                    this.officeList = res.data.data;
                })
            },
            getTeamListByOfficeId(){
                getTeamListByOfficeId({officeId: this.option.officeId}).then((res) => {
                    this.teamList = res.data.data;

                })
            }
        },
    };
</script>


<style scoped>
    .ant-advanced-search-form {
        padding: 24px;
        background: #fbfbfb;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        margin-bottom: 10px;
    }

    .ant-advanced-search-form .ant-form-item {
        display: flex;
    }

    .ant-advanced-search-form .ant-form-item-control-wrapper {
        flex: 1;
    }

    #components-form-demo-advanced-search .ant-form {
        max-width: none;
    }

    tr{
        background-color: #ffffff;
    }

</style>
