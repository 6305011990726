var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "v-chart",
        {
          attrs: {
            forceFit: true,
            height: _vm.height,
            data: _vm.data,
            scale: _vm.scale,
          },
        },
        [
          _c("v-tooltip", {
            attrs: { showTitle: false, dataKey: "item*percent" },
          }),
          _c("v-axis"),
          _c("v-legend", {
            attrs: { dataKey: "item", position: "right", offsetX: -140 },
          }),
          _c("v-pie", {
            attrs: {
              position: "percent",
              color: "item",
              vStyle: _vm.pieStyle,
              label: _vm.labelConfig,
            },
          }),
          _c("v-coord", {
            attrs: { type: "theta", radius: 0.75, innerRadius: 0.6 },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }