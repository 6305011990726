var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a_Approval" },
    [
      _c("div", { staticClass: "plug-Overview" }, [
        _vm._v("安全技术交底资料管理"),
      ]),
      _c("a-divider"),
      _c("a-form", { staticClass: "clearfix" }, [
        _c(
          "div",
          { class: _vm.advanced ? null : "fold" },
          [
            _c(
              "a-col",
              { attrs: { span: 5 } },
              [
                _c(
                  "a-form-item",
                  {
                    attrs: {
                      label: "日期",
                      labelCol: { span: 8 },
                      wrapperCol: { span: 14, offset: 1 },
                    },
                  },
                  [
                    _c("a-date-picker", {
                      staticStyle: { width: "100%" },
                      attrs: { format: _vm.monthFormat, allowClear: false },
                      model: {
                        value: _vm.queryParams.dueTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "dueTime", $$v)
                        },
                        expression: "queryParams.dueTime",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.user.loginType == 1
              ? _c(
                  "a-col",
                  { attrs: { span: 5 } },
                  [
                    _c(
                      "a-form-item",
                      {
                        attrs: {
                          labelCol: { span: 8 },
                          wrapperCol: { span: 14, offset: 1 },
                          label: "项目名称",
                        },
                      },
                      [
                        _c(
                          "a-auto-complete",
                          {
                            attrs: {
                              "data-source": _vm.inputDataSource,
                              placeholder: "请输入项目名称",
                              allowClear: "",
                            },
                            on: {
                              search: function ($event) {
                                return _vm.handleInputSearch($event)
                              },
                              select: function ($event) {
                                return _vm.handlInputSelect($event)
                              },
                            },
                            model: {
                              value: _vm.queryParams.projectId,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "projectId", $$v)
                              },
                              expression: "queryParams.projectId",
                            },
                          },
                          [
                            _c(
                              "template",
                              { slot: "dataSource" },
                              _vm._l(_vm.inputDataSource, function (items) {
                                return _c(
                                  "a-select-option",
                                  { key: items.id + "" },
                                  [_vm._v(_vm._s(items.title))]
                                )
                              }),
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "a-col",
              { attrs: { span: 5 } },
              [
                _c(
                  "a-form-item",
                  {
                    attrs: {
                      label: "班组名称",
                      labelCol: { span: 8 },
                      wrapperCol: { span: 14, offset: 1 },
                    },
                  },
                  [
                    _c(
                      "a-select",
                      {
                        attrs: {
                          "show-search": "",
                          placeholder: "请输入或选择班组",
                          "option-filter-prop": "children",
                          "filter-option": _vm.filterOption,
                        },
                        on: {
                          focus: _vm.handleFocus,
                          blur: _vm.handleBlur,
                          change: _vm.handleChange,
                        },
                        model: {
                          value: _vm.queryParams.teamId,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParams, "teamId", $$v)
                          },
                          expression: "queryParams.teamId",
                        },
                      },
                      _vm._l(_vm.teamDatas, function (item) {
                        return _c(
                          "a-select-option",
                          { key: item.id, attrs: { value: item.id } },
                          [_vm._v(" " + _vm._s(item.teamName) + " ")]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "a-col",
              { attrs: { span: 4 } },
              [
                _c(
                  "a-space",
                  { staticClass: "btnBox" },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: { icon: "search", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.doQuery()
                          },
                        },
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "a-space",
                      { staticClass: "operator" },
                      [
                        _c(
                          "a-button",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.doReset()
                              },
                            },
                          },
                          [_vm._v("重置")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        [
          _c("standard-table", {
            attrs: {
              columns: _vm.columns,
              dataSource: _vm.dataSource,
              selectedRows: _vm.selectedRows,
              pagination: _vm.pagination,
              loading: _vm.tableLoading,
              isAlert: false,
              bordered: true,
            },
            on: {
              "update:selectedRows": function ($event) {
                _vm.selectedRows = $event
              },
              "update:selected-rows": function ($event) {
                _vm.selectedRows = $event
              },
              clear: _vm.onClear,
              change: _vm.onPageChange,
            },
            scopedSlots: _vm._u([
              {
                key: "safediscloseFileName",
                fn: function (ref) {
                  var text = ref.text
                  return _c(
                    "div",
                    {},
                    [
                      text != null && text != ""
                        ? _c("div", [_vm._v(_vm._s(text))])
                        : _c("a-icon", {
                            staticStyle: { color: "red" },
                            attrs: { type: "close" },
                          }),
                    ],
                    1
                  )
                },
              },
              {
                key: "do-status",
                fn: function (ref) {
                  var text = ref.text
                  return _c(
                    "div",
                    {},
                    [
                      text == 1
                        ? _c("a-icon", {
                            staticStyle: { color: "green" },
                            attrs: { type: "check" },
                          })
                        : _c("a-icon", {
                            staticStyle: { color: "red" },
                            attrs: { type: "close" },
                          }),
                    ],
                    1
                  )
                },
              },
              {
                key: "do-userList",
                fn: function (ref) {
                  var text = ref.text
                  var record = ref.record
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "a-popover",
                        { attrs: { placement: "bottom" } },
                        [
                          _c(
                            "template",
                            { slot: "content" },
                            _vm._l(
                              record.userNames.split(","),
                              function (item, key) {
                                return _c("p", { key: key }, [
                                  _vm._v(_vm._s(item)),
                                ])
                              }
                            ),
                            0
                          ),
                          _c("span", [_vm._v(_vm._s(_vm._f("subText")(text)))]),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                },
              },
              {
                key: "action",
                fn: function (ref) {
                  var text = ref.text
                  var record = ref.record
                  return _c("div", {}, [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.download(record)
                          },
                        },
                      },
                      [_vm._v("下载")]
                    ),
                  ])
                },
              },
              {
                key: "statusTitle",
                fn: function (ref) {
                  var text = ref.text
                  var record = ref.record
                  return [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.showPersons(record)
                          },
                        },
                      },
                      [_vm._v(_vm._s(text))]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "人员完成情况" },
          on: { ok: _vm.handleOk },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c(
            "div",
            [
              _c("a-list", {
                attrs: { "data-source": _vm.personDataSource },
                scopedSlots: _vm._u([
                  {
                    key: "renderItem",
                    fn: function (item) {
                      return _c(
                        "a-list-item",
                        {},
                        [
                          _c(
                            "a-list-item-meta",
                            { attrs: { description: item.phoneNumber } },
                            [
                              _c(
                                "a",
                                { attrs: { slot: "title" }, slot: "title" },
                                [_vm._v(_vm._s(item.userName))]
                              ),
                              _c("a-avatar", {
                                attrs: { slot: "avatar", src: item.headImg },
                                slot: "avatar",
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                position: "absolute",
                                left: "50%",
                                "font-size": "20px",
                              },
                            },
                            [
                              item.isSign == 1
                                ? _c("span", [
                                    _c("i", {
                                      staticClass: "iconfont duigouxiao",
                                      staticStyle: { color: "#00CE86" },
                                    }),
                                  ])
                                : _c("span", [
                                    _c("i", {
                                      staticClass: "iconfont path",
                                      staticStyle: { color: "#FF5C77" },
                                    }),
                                  ]),
                            ]
                          ),
                        ],
                        1
                      )
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }