<!-- 入场审批 -->
<template>
  <div class="a_Approval">
    <a-tabs :default-active-key="defaultActiveKey" :activeKey="activeKey" @tabClick="tabClick">
      <a-tab-pane key="1" tab="基本信息">
        <base-info :gcglbList="gcglbList" ref="baseInfo"></base-info>
      </a-tab-pane>
      <a-tab-pane key="2" tab="使用登记记录(年/次)">
        <use-record :gcglbList="gcglbList" ref="useRecord"></use-record>
      </a-tab-pane>
      <a-tab-pane key="3" tab="检验记录(年/次)">
        <inspection-record :gcglbList="gcglbList" ref="inspectionRecord"></inspection-record>
      </a-tab-pane>
      <a-tab-pane key="4" tab="维保记录(月/次)">
        <maintenance-record :gcglbList="gcglbList" ref="maintenanceRecord"></maintenance-record>
      </a-tab-pane>
      <a-tab-pane key="5" tab="定期自检记录(月/次)">
        <self-inspection-record :gcglbList="gcglbList" ref="seflInspectionRecord"></self-inspection-record>
      </a-tab-pane>
      <a-tab-pane key="6" tab="防坠器检验记录(年/次)" v-if="facilityType == 2">
        <falling-check-record :gcglbList="gcglbList" ref="fallingCheckRecord"></falling-check-record>
      </a-tab-pane>
      <a-tab-pane key="7" tab="防坠器试验记录(三个月/次)" v-if="facilityType == 2">
        <falling-record :gcglbList="gcglbList" ref="fallingRecord"></falling-record>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import BaseInfo from './baseInfo.vue';
import UseRecord from './useRecord.vue';
import InspectionRecord from './inspectionRecord.vue';
import MaintenanceRecord from './maintenanceRecord.vue';
import SelfInspectionRecord from './selfInspectionRecord.vue';
import FallingCheckRecord from './fallingCheckRecord.vue';
import FallingRecord from './fallingRecord.vue';
import { QUERY_OFFICE_BY_USER } from "@/services/api";
import { request } from "@/utils/request";

export default {
  components: {
    BaseInfo,
    UseRecord,
    InspectionRecord,
    MaintenanceRecord,
    SelfInspectionRecord,
    FallingCheckRecord,
    FallingRecord
  },
  data() {
    return {
      defaultActiveKey: "1",
      activeKey: '1',
      gcglbList: [],
      facilityType: 1,
      id: ''
    };
  },
  activated() {
    if (Number(this.activeKey) > 1) {
      this.tabClick(this.activeKey)
    }
    this.id = this.$route.query.id;
    this.facilityType = Number(this.$route.query.facilityType);
    this.getgcglbList(QUERY_OFFICE_BY_USER);
  },
  watch: {
    id: {
      handler(val, oldVal) {
        if (val && oldVal && val != oldVal) {
          this.activeKey = '1'
          this.defaultActiveKey = '1'
          this.$refs.baseInfo.init()
        }
      }
    }
  },
  methods: {
    // 获取工程管理部数据
    getgcglbList(path) {
      request(path, "get").then((res) => {
        let data = res.data.data.filter(
          (item) => item.id != "3da0f5533f87425c9a9e8a11a7923300"
        );
        this.gcglbList = data
      });
    },
    tabClick(e) {
      console.log(e, 'eeeeeeeeeeee')
      this.activeKey = e
      if (e == 1) {
        this.$refs.baseInfo.init()
      } else if (e == 2) {
        if (this.$refs.useRecord) {
          this.$refs.useRecord.init()
        }
      } else if (e == 3) {
        if (this.$refs.inspectionRecord) {
          this.$refs.inspectionRecord.init()
        }
      } else if (e == 4) {
        if (this.$refs.maintenanceRecord) {
          this.$refs.maintenanceRecord.init()
        }
      } else if (e == 5) {
        if (this.$refs.seflInspectionRecord) {
          this.$refs.seflInspectionRecord.init()
        }
      } else if (e == 6) {
        if (this.$refs.fallingCheckRecord) {
          this.$refs.fallingCheckRecord.init()
        }
      } else if (e == 7) {
        if (this.$refs.fallingRecord) {
          this.$refs.fallingRecord.init()
        }
      }
    }
  },
};
</script>

<style lang="less" scoped>
.a_Approval {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
}
</style>