<template>
  <a-dropdown :trigger="['click']" @visibleChange="(visible) => fetchNotice(visible)">
    <div slot="overlay">
      <a-spin :spinning="loading">
        <a-tabs class="dropdown-tabs" :tabBarStyle="{textAlign: 'center', marginBottom: '0px'}" :style="{width: '297px'}">
          <a-tab-pane tab="通知" key="1">
            <a-list class="tab-pane" :data-source="versionData">
              <a-list-item slot="renderItem" slot-scope="item, index">
                <a-list-item-meta
                :key="index"
                @click="showVersion(item)">
                  <a slot="title" >版本更新通知 {{item.vcode}}</a>
                  <a-avatar style="background-color: white" slot="avatar" src="https://gw.alipayobjects.com/zos/rmsportal/ThXAXghbEsBCCSDihZxY.png"/>
                  <div slot="description">{{moment(item.vtime).format('YYYY-MM-DD HH:mm:ss')}}
                    <!-- <span v-html="item.vcontent"></span> -->
                  </div>
                </a-list-item-meta>
              </a-list-item>
            </a-list>
          </a-tab-pane>
          <a-tab-pane tab="消息" key="2">
            <div
                    @scroll="handleInfiniteOnLoad"
                    class="demo-infinite-container"
            >
              <a-list class="tab-pane" :data-source="data">
                <a-list-item slot="renderItem" slot-scope="item, index">
                  <a-list-item-meta :key="index"
                    :description="`${index + 1}.${item.templateContent}`"
                    @click="showMessage(item)"
                  >
                    <!-- <a slot="title" >{{ item.promoterName }}</a> -->
                  </a-list-item-meta>
                </a-list-item>
                <div v-if="loading && !busy" class="demo-loading-container">
                  <a-spin />
                </div>
              </a-list>
            </div>

          </a-tab-pane>
          <a-tab-pane tab="待办" key="3">
            <a-list class="tab-pane">
               <!-- <a-list-item>
                <a-list-item-meta description="今天">
                  <a slot="title" >版本更新通知1.X</a>
                  <a-avatar style="background-color: white" slot="avatar" src="https://gw.alipayobjects.com/zos/rmsportal/ThXAXghbEsBCCSDihZxY.png"/>
                </a-list-item-meta>
              </a-list-item>
              <a-list-item>
                <a-list-item-meta title="你推荐的 曲妮妮 已通过第三轮面试" description="一年前">
                  <a-avatar style="background-color: white" slot="avatar" src="https://gw.alipayobjects.com/zos/rmsportal/OKJXDXrmkNshAMvwtvhu.png"/>
                </a-list-item-meta>
              </a-list-item>
              <a-list-item>
                <a-list-item-meta title="这种模板可以区分多种通知类型" description="一年前">
                  <a-avatar style="background-color: white" slot="avatar" src="https://gw.alipayobjects.com/zos/rmsportal/kISTdvpyTAhtGxpovNWd.png"/>
                </a-list-item-meta>
              </a-list-item> -->
            </a-list>
          </a-tab-pane>
        </a-tabs>
      </a-spin>
      <a-modal :mask="true" centered v-model="versionMotal" :width="1050" :title="`#更新通知 ${version.vcode ? version.vcode : ''}`" @cancel="closeVersion" :footer="null">
        <p>{{ moment(version.vtime).format('YYYY-MM-DD HH:mm:ss') }}</p>
        <!-- <a-button @click="downloadToPDF">下载pdf</a-button> -->
        <div id="element-order-detail" >
          <span v-html="version.vcontent"></span>
          <!-- <a-avatar style="background-color: white" slot="avatar" src="https://gw.alipayobjects.com/zos/rmsportal/ThXAXghbEsBCCSDihZxY.png"/> -->
        </div>
    </a-modal>
    </div>
    <span class="header-notice" @click="e => e.preventDefault()">
      <a-badge class="notice-badge" :count="newVersion" dot>
        <a-icon :class="['header-notice-icon']" type="bell" />
      </a-badge>
    </span>
    <div @click="e => e.preventDefault()">
      <a-modal v-model="visible" title="Basic Modal" @ok="closeMessage">
          {{msgContent}}
      </a-modal>
    </div>
  </a-dropdown>
</template>

<script>
import infiniteScroll from 'vue-infinite-scroll';
import {messageNotifyGetList} from '@/services/message'
import html2pdf from "html2pdf.js";
import { getVersionHistroyDetail,getVersionHistroyLast } from '@/services/sys'
import moment from 'moment';
import { VERSION_TYPE_ENUMS, SYSTEM_TYPE_ENUM } from '@/enums/system'
import { getSystemType, isLaowu, isSafe, isJianAnYi } from '@/utils/system.js'
export default {
  name: 'HeaderNotice',
  directives: { infiniteScroll },
  data () {
    return {
      loading: false,
      busy: false,
      data: [],

      searchForm: {
        officeName: "",
        projectName: "",
      },
      pagination:{
        current:1,
        pageSize:5,
        total:0,
      },
      visible: false,
      msgContent: "",
      versionData: [],
      versionMotal: false,
      version: {},
      newVersion: 0,
      moment,
      isLaowu: isLaowu(),
      isSafe: isSafe(),
      isJianAnYi: isJianAnYi(),
    }
  },
  mounted(){
    this.getVersionHistroyList()
  },
  methods: {
    fetchNotice (visible) {
      if (!visible) return
      
      this.searchForm.pageNumber = 1
      this.pagination.pageNumber = 1

      if (this.loading) {
        return
      }
      this.data = []
      this.messageNotifyGetList();
      this.getVersionHistroyList()
      // this.loadding = true
      // setTimeout(() => {
      //   this.loadding = false
      // }, 1000)
    },

    messageNotifyGetList(){
      this.loadding = true
      messageNotifyGetList(this.searchForm).then(res=>{
        this.data = this.data.concat(res.data.data.list);
        // this.data = res.data.data
        this.pagination.total = res.data.data.total
      }).finally(()=>{
        this.loadding = false
      })
    },
    handleInfiniteOnLoad(e){
      if (this.loadding){
        return
      }
      let ele = e.srcElement ? e.srcElement : e.target;
      if (ele.scrollTop + ele.offsetHeight > ele.scrollHeight - 10) { //监听滚动到div底部
        this.searchForm.pageNumber = this.searchForm.pageNumber+1
        this.messageNotifyGetList();
      }
    },
    showMessage(item){
      this.$router.push('/messageinfo?id='+item.id)
    },
    closeMessage(){
      this.visible = false
    },
    getVersionHistroyList() {
      // 系统版本发布-查询[版本更新内容]数据
      getVersionHistroyLast({ type: VERSION_TYPE_ENUMS.RELEASE }).then(res => {
        // 过滤失败
        if (res.data.code !== 0) return
        
        const data = res.data.data.list ? res.data.data.list : res.data.data

        const systemArr = data.vsystem.split(',')
        // 过滤当前系统为劳务，更新内容不属于劳务的
        if (this.isLaowu && !systemArr.includes(SYSTEM_TYPE_ENUM.LAOWU)) return
        // 过滤当前系统为机施，更新内容不属于机施的
        if (this.isSafe && !systemArr.includes(SYSTEM_TYPE_ENUM.SAFE)) return
        // 过滤当前系统为建安易，更新内容不属于建安易的
        if (this.isJianAnYi && !systemArr.includes(SYSTEM_TYPE_ENUM.SAFE)) return

        // 版本更新内容
        // 校验是否在有效期内
        const currentTimeStamp = Date.now()
        const isValid = moment(currentTimeStamp).isBefore(moment(data.endTime))
        if (!isValid) return

        // 列表赋值
        this.versionData = [data]

        // 发布时间
        const targetTime = moment(data.vtime, 'YYYY-MM-DD HH:mm:ss')
        const diffInDays = moment(currentTimeStamp).diff(targetTime, 'days');
        // 三天内发布的，显示徽标数
        diffInDays > 3 ? this.newVersion = 0 : this.newVersion = 1
      })
    },
    showVersion(item) {
      getVersionHistroyDetail({id: item.id}).then(res => {
        if (res.data.code === 0 ) {
          this.versionMotal = true
          this.version = res.data.data.list ? res.data.data.list : res.data.data
          // this.setImageSrc(res.data.data.vcontent).then((res) => {
          //   console.log(res,'======');//res转换好的富文本内容
          // })
        }
      })
    },
    closeVersion(){
      this.versionMotal = false
      this.version = {}
    },
    downloadToPDF() {
      const element = document.getElementById("element-order-detail");
      const opt = {
          enableLinks: true,
          margin: 1,
          filename: "myfile.pdf",
          image: { type: "webp", quality: 1 },
          // html2canvas: { scale: 2,allowTaint: true,},
          html2canvas: { 
            scale: 2, 
            // allowTaint: true,
          },
          jsPDF: {
              unit: "in",
              format: "letter",
              orientation: "portrait",
          },
      };
      html2pdf().set(opt).from(element).save()
      // html2pdf(element, opt);
    },
    setImageSrc (content) {//重组IMG 图片转换为base64
            return new Promise((resolve, reject) => {
            let _this = this;
            let srcArr = content.split(/src="/g);//截取全部图片为数组
            let isEnd = 1;//初始化是否转换完毕

            function getBase64Image (img) {//用 canvas 生成base64图片
                var canvas = document.createElement("canvas");  
                canvas.width = img.width;  
                canvas.height = img.height;  
                var ctx = canvas.getContext("2d");  
                ctx.drawImage(img, 0, 0, img.width, img.height);
                var ext = img.src.substring(img.src.lastIndexOf(".")+1).toLowerCase();  
                var dataURL = canvas.toDataURL("image/" + ext);
                isEnd++
                return dataURL;  
            }

            function creatBase64(srcArr) {//循环img数组 转换为图片

                
                for (let i = 1; i < srcArr.length; i++) {
                        let lastIndex = srcArr[i].indexOf('"');//找到第一个 "双引号
                        let src = srcArr[i].slice(0, lastIndex); //用下标获取每个图片的src
                        let image = new Image();//动态创建一个图片
                        image.src = src;
                        image.onload = function () {//创建后 调用base64 图片转换方法 获取base64格式图片
                            let base64 = getBase64Image(image);
                            let newSrc = srcArr[i].slice(lastIndex);//获取 第一个双引号 之后的字符再把 生成的 base64格式的图片的字符拼接起来
                            srcArr[i] = base64+newSrc;
                            if (isEnd == srcArr.length) {//用全局变量 判断是否创建完所有6base4图片，返回数组
                                 let result = srcArr.join('src="');//拿到 创建2完的base64图片数组，拼接为一个字符串，抛出去
                                 resolve(result)
                            }
                        }
                }
                
            }
                creatBase64(srcArr)
            })
        }

  }
}
</script>

<style lang="less">
  .demo-infinite-container {
    border-radius: 4px;
    overflow: auto;
    padding: 8px 24px;
    height: 300px;
  }
  .demo-loading-container {
    position: absolute;
    bottom: 40px;
    width: 100%;
    text-align: center;
  }

  .dropdown-tabs .ant-list-item-meta{
    width: 184px;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
  }

  .dropdown-tabs .ant-list-item-meta-description{

    width: 184px;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
  }



  .header-notice{
    display: inline-block;
    transition: all 0.3s;
    span {
      vertical-align: initial;
    }
    .notice-badge{
      color: inherit;
      .header-notice-icon{
        font-size: 16px;
        padding: 4px;
      }
    }
  }

  .dropdown-tabs{
    background-color: @base-bg-color;
    box-shadow: 0 2px 8px @shadow-color;
    border-radius: 4px;
    .tab-pane{
      padding: 0 24px 12px;
      min-height: 250px;
    }
  }

  /deep/ .ant-tabs-bar {
    margin-bottom: 0px !important;
  }
</style>
