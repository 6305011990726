var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-layout-header", { class: [_vm.headerTheme, "admin-header"] }, [
    _c(
      "div",
      { class: ["admin-header-wide", _vm.layout, _vm.pageWidth] },
      [
        _vm.isMobile || _vm.layout === "head"
          ? _c(
              "router-link",
              {
                class: ["logo", _vm.isMobile ? null : "pc", _vm.headerTheme],
                attrs: { to: "/" },
              },
              [
                _vm.isLaowu
                  ? _c("img", {
                      attrs: {
                        width: "32",
                        src: require("@/assets/img/logo.png"),
                      },
                    })
                  : _vm._e(),
                _vm.isSafe
                  ? _c("img", {
                      attrs: {
                        width: "70",
                        src: require("@/assets/img/logo2.png"),
                      },
                    })
                  : _vm._e(),
                !_vm.isMobile
                  ? _c("h1", [_vm._v(_vm._s(_vm.systemFullName))])
                  : _vm._e(),
              ]
            )
          : _vm._e(),
        _vm.isMobile
          ? _c("a-divider", { attrs: { type: "vertical" } })
          : _vm._e(),
        _vm.layout !== "head"
          ? _c("a-icon", {
              staticClass: "trigger",
              attrs: { type: _vm.collapsed ? "menu-unfold" : "menu-fold" },
              on: { click: _vm.toggleCollapse },
            })
          : _vm._e(),
        _vm.layout !== "side" && !_vm.isMobile
          ? _c(
              "div",
              {
                staticClass: "admin-header-menu",
                style: "width: " + _vm.menuWidth + ";",
              },
              [
                _c("i-menu", {
                  staticClass: "head-menu",
                  attrs: {
                    theme: _vm.headerTheme,
                    mode: "horizontal",
                    options: _vm.menuData,
                  },
                  on: { select: _vm.onSelect },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { class: ["admin-header-right", _vm.headerTheme] },
          [
            false
              ? _c("header-search", {
                  staticClass: "header-item",
                  on: {
                    active: function (val) {
                      return (_vm.searchActive = val)
                    },
                  },
                })
              : _vm._e(),
            _vm.showGoview
              ? _c(
                  "a",
                  {
                    staticClass: "header-item",
                    on: { click: _vm.onShowGoview },
                  },
                  [_vm._v("打开大屏")]
                )
              : _vm._e(),
            _vm.manualPath && _vm.checkAuthorization()
              ? _c(
                  "a",
                  {
                    staticClass: "header-item",
                    attrs: { href: _vm.manualPath, target: "_blank" },
                  },
                  [_vm._v("操作手册")]
                )
              : _vm._e(),
            _c("header-notice", { staticClass: "header-item" }),
            _c("header-project", { staticClass: "header-item" }),
            _c("header-avatar", { staticClass: "header-item" }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }