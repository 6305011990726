var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.$route.meta.showFather,
              expression: "$route.meta.showFather",
            },
          ],
          staticClass: "a_Approval",
        },
        [
          _c("div", { staticClass: "plug-Overview" }, [
            _vm._v("项目安全组织架构"),
          ]),
          _c("a-divider"),
          _c(
            "a-button",
            { attrs: { type: "primary" }, on: { click: _vm.addNewPost } },
            [_vm._v(" 新增 ")]
          ),
          _c("standard-table", {
            attrs: {
              columns: _vm.columns,
              dataSource: _vm.tableData,
              rowKey: "index",
              pagination: _vm.pagination,
              loading: _vm.tableLoading,
              isAlert: false,
              bordered: true,
            },
            on: { change: _vm.handleChangePage },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function (ref) {
                  var index = ref.index
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          (_vm.pagination.current - 1) *
                            _vm.pagination.pageSize +
                            parseInt(index) +
                            1
                        )
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "status",
                fn: function (ref) {
                  var record = ref.record
                  return [
                    record.status == 0
                      ? _c("span", [_vm._v("待提交")])
                      : record.status == 1
                      ? _c("span", [_vm._v("已提交")])
                      : record.status == 2
                      ? _c("span", [_vm._v("通过")])
                      : record.status == 3
                      ? _c("span", [_vm._v("驳回")])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cerFilePath",
                fn: function (ref) {
                  var text = ref.text
                  var record = ref.record
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "flex a-center j-center",
                        staticStyle: { height: "40px" },
                      },
                      [
                        text != "" && text != null
                          ? _c("div", [
                              _c("img", {
                                staticStyle: {
                                  width: "40px",
                                  height: "40px",
                                  cursor: "pointer",
                                },
                                attrs: { src: text },
                                on: {
                                  click: function ($event) {
                                    return _vm.showPreview(text, record)
                                  },
                                },
                              }),
                            ])
                          : _vm._e(),
                        !text ? _c("div", [_vm._v("暂无图片")]) : _vm._e(),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "action",
                fn: function (ref) {
                  var record = ref.record
                  return [
                    record.id && record.status != 2
                      ? _c(
                          "div",
                          [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function () {
                                    return _vm.handleEdit(record)
                                  },
                                },
                              },
                              [_vm._v(" 编辑 ")]
                            ),
                            _c("a-divider", { attrs: { type: "vertical" } }),
                            _c(
                              "a",
                              {
                                staticStyle: { color: "#FF5C77" },
                                on: {
                                  click: function () {
                                    return _vm.delPeople(record)
                                  },
                                },
                              },
                              [_vm._v(" 删除 ")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("router-view"),
      _c(
        "a-modal",
        {
          attrs: {
            destroyOnClose: true,
            visible: _vm.modalVisible,
            "ok-text": "下载",
            "cancel-text": "关闭",
          },
          on: {
            cancel: _vm.cancelPreview,
            ok: function ($event) {
              return _vm.downloadFile(_vm.previewImage)
            },
          },
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { alt: "example", src: _vm.previewImage },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }