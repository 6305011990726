import echarts from "echarts";
export function peopleLineChart(arr, dom) {
  let peopleLineChart = echarts.init(dom);
  let option = {
    tooltip: {
      trigger: "item",
    },
    grid: {
      //   top: "15%", // 一下数值可为百分比也可为具体像素值
      right: "5%",
      //   bottom: "10%",
      left: "5%",
    },
    // legend: {
    //   bottom: 10,
    //   left: "center",
    // },
    // x轴线
    xAxis: {
      boundaryGap: false, // 不留白，从原点开始
      data: [
        "00:00",
        "02:00",
        "04:00",
        "06:00",
        "08:00",
        "10:00",
        "12:00",
        "14:00",
        "16:00",
        "18:00",
        "19:00",
        "20:00",
        "22:00",
        "24:00",
      ],
    },
    // y轴线
    yAxis: {},
    color: ["#2EC7C9", "#B6A2DE", "#5AB1EF", "#FFB980", "#D87A80", "#8D98B3"],
    series: [
      {
        name: "施工实施人数",
        type: "line",
        data: [8, 10, 4, 5, 9, 4, 8, 8, 10, 4, 5, 9, 4, 8],
        smooth: true,
        areaStyle: "#f70",
      },
      {
        name: "项目部人数",
        type: "line",
        data: [2, 15, 3, 4, 3, 5, 6, 2, 15, 3, 4, 3, 5, 6],
        smooth: true,
      },
    ],
  };
  peopleLineChart.setOption(option);
  return peopleLineChart;
}

export function environmentChart(arr, dom) {
  let environmentChart = echarts.init(dom);
  let option = {
    tooltip: {
      trigger: "item",
    },
    grid: {
      top: "15%", // 一下数值可为百分比也可为具体像素值
      right: "5%",
      bottom: "10%",
      left: "5%",
    },
    //   legend: {
    //     bottom: 10,
    //     left: "center",
    //   },
    // x轴线
    xAxis: {
      boundaryGap: false, // 不留白，从原点开始
      data: ["4-15", "4-16", "4-17", "4-18", "4-19", "4-20", "4-21"],
    },
    // y轴线
    yAxis: {},
    color: ["#2EC7C9", "#B6A2DE", "#5AB1EF", "#FFB980", "#D87A80", "#8D98B3"],
    series: [
      {
        name: "施工实施人数",
        type: "line",
        data: [8, 10, 4, 5, 9, 4, 8, 8, 10, 4, 5, 9, 4, 8],
        smooth: true,
        areaStyle: "#f70",
      },
      {
        name: "管理人员数",
        type: "line",
        data: [2, 15, 3, 4, 3, 5, 6, 2, 15, 3, 4, 3, 5, 6],
        smooth: true,
      },
    ],
  };
  environmentChart.setOption(option);
  return environmentChart;
}

export function safeChart (obj, dom) {
    let safeChart = echarts.init(dom)
    let option = {
        title: {
            text: '',
            left: 'left',
            textStyle:{
                color:'#333',
                fontStyle:'normal',
                fontSize:16
            }
        },
        tooltip: {
            trigger: 'item'
        },
        color:['#68BBC4', '#5087EC'],
        // legend: {
        //     orient: 'vertical',
        //     left: 'right'
        // },
        series: [
            {
                name: '本月安全管理',
                type: 'pie',
                // radius: '70%',
                // center: ["35%", "58%"],
                data:[
                    {name:"js",value:90},
                    {name:"html",value:85},
                    {name:"jq",value:90},
                    {name:"vue",value:50},
                ],
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    }
    safeChart.setOption(option)
    return safeChart
}


export function sevenDaysChart(arr, dom) {
  const sevenDaysChart = echarts.init(dom);
  let option = {
  tooltip: {
       trigger: "axis",  
  },
  legend: {
      itemWidth:15,  
      itemHeight:15,  
      data:['管理人员数','工人考勤人数'],
  },
  xAxis: {
      data: arr.days,
      splitLine:{
          show:false,
      },
  },
  yAxis: {
       splitLine:{
          show:false,
      },
  },
  series: [{
      name: '工人考勤人数',
      type: 'bar',
      stack:'使用情况',
      data: arr.grs,
      itemStyle:{
           normal:{color:"#68BBC4"},
      }
  },{
      name: '管理人员数',
      type: 'bar',
      stack:'使用情况',
      data: arr.glrys,
      itemStyle:{
           normal:{color:"#5087EC"},
      }
  }]
  }
  sevenDaysChart.setOption(option);
  return sevenDaysChart;
}
