<template>
  <div>
    <div class="constructiontTable_index">
      <a-tabs :default-active-key="defaultActiveKey" @change="callback" forceRender>
        <a-tab-pane key="1" tab="在场人员班组"> </a-tab-pane>
        <a-tab-pane key="2" tab="无在场人员班组"> </a-tab-pane>
      </a-tabs>
      <!-- 表单封装
              projectObj：下拉弹出框第一项对象
              Overview：项目名称
              baiduMapBoolen：控制地图显示
              topSubmit：控制上部分按钮显示
              bottomSubmit：控制下部分按钮显示
              handleSubmit：表单提交
              handleChange：输入出发
              handlSelect：输入下拉选择触发
          -->
      <form-index
        ref="formindex"
        :projectList="projectList"
        :formData="formData"
        :flexAction="true"
        :topSubmit="true"
        :baiduMapBoolen="false"
        @handleSubmit="handleSubmit"
        @handleChange="handleChange"
        @handlSelect="handlSelect"
      >
        <!-- 下部分按钮插槽 -->
        <!-- <template #topSubmit>
          <div></div>

        </template> -->
        <!-- 下部分按钮插槽 -->
        <template #flexAction>
          <!-- <a-button> 导入 </a-button>
          <a-button type="primary"> 导出 </a-button> -->
          <a-space :style="{ marginTop: '4px' }">
            <a-button icon="search" type="primary" html-type="submit">
              查询
            </a-button>
            <a-space class="operator">
              <a-button @click="close"> 重置 </a-button>
            </a-space>
            <!-- <div slot="bottomSubmitLeft" style="margin-right: auto"><a-button @click="doDowload()"> 导出花名册 </a-button></div> -->
            <a-button icon="plus" type="primary" @click="createItem">
              新增
            </a-button>
          </a-space>
        </template>
      </form-index>

      <!-- 表格封装 -->
      <standard-table
        class=""
        :columns="columns"
        :dataSource="dataSource"
        rowKey="id"
        :pagination="pagination"
        :loading="tableLoading"
        @change="onPageChange"
        :isAlert="false"
        :bordered="true"
        tableSize="middle"
      >
        <template slot="index" slot-scope="{ index }">
          <span>
            {{
              (pagination.current - 1) * pagination.pageSize +
              parseInt(index) +
              1
            }}
          </span>
        </template>
        <template slot="projectName" slot-scope="{ record }">
          <!-- :style="record.pjsPath == null || record.pjsPath == ''? '' : 'color:red'" -->
          <div :style="textStyle(record)">
            {{ record.projectName }}
          </div>
        </template>
        <template slot="teamName" slot-scope="{ record, text }">
          <span v-if="record.teamType == 1">
            <a-tag>甲指乙管</a-tag>
          </span>
          {{ text }}
        </template>
        <div slot="teamQr" slot-scope="{ text, record }">
          <div class="flex a-center j-center" style="height: 40px">
            <div v-if="text != '' && text != null">
              <img
                :src="text"
                style="width: 40px; height: 40px; cursor: pointer"
                @click="showImg(text, record)"
              />
            </div>
            <a-spin :spinning="!text"></a-spin>
          </div>
        </div>
        <div slot="action" slot-scope="{ record, text }">
          <div>
            <a @click="viewEditing(record, true)">查看</a>
            <a-divider type="vertical" />
            <a
              class="content_div"
              style="color: #ff5c77"
              @click="viewEditing(record)"
              >修改</a
            >
            <!-- <div @click="viewEditing(record)">更多></div> -->
            <a-popover placement="bottomRight" trigger="click">
              <template slot="content">
                <!-- <div class="content_div" @click="viewEditing(record)">查看</div>
                 -->

                <div
                  class="content_div"
                  style="color: #ff5c77"
                  @click="recorDelete(record, text)"
                  v-if="defaultActiveKey == 2"
                >
                  删除
                </div>
                <div class="content_div" @click="recordView(record, 5)">
                  设置门禁通道
                </div>
                <!-- <div class="content_div" @click="recordView(record, 6)">
                  设置考勤设备
                </div> -->
                <div
                  class="content_div"
                  @click="recordView(record, 'evaluate')"
                >
                  评价
                </div>
              </template>
              <template slot="title">
                <span>操作</span>
              </template>
              <a-button type="link" style="color: #615e83">更多></a-button>
            </a-popover>
          </div>
        </div>
      </standard-table>
      <Export-loading
        :cPercent="cPercent"
        :isExporting="isExporting"
        :loadingText="loadingText"
      ></Export-loading>
      <a-modal
        :destroyOnClose="true"
        :visible="modalVisible"
        @cancel="handleCancel"
        @ok="downQrCode"
        ok-text="下载"
        cancel-text="关闭"
      >
        <img alt="example" style="width: 100%" :src="previewImage" />
      </a-modal>
    </div>
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import { exportProgress } from "@/pages/aqjyzl/export"; // 导出方法
import ExportLoading from "@/components/ExportLoading/ExportLoading"; // progress组件
import { STAFFLIB_EXPORT } from "@/services/api";
import {
  getTeamDetail,
  getTeams,
  delTeams,
  getProAllOfficeList,
  getJobByWorkerType,
  getQrCodeList,
  getAllTeams,
} from "@/services/participationUnit";
import { mapGetters } from "vuex";
import { useRender } from '@/hooks/useRender.js'
const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
    // fixed: "left",
  },
  {
    title: "班组名称",
    dataIndex: "teamName",
    align: "center",
    width: 150,
    scopedSlots: { customRender: "teamName" },
    ellipsis: true,
    // fixed: "left",
  },
  {
    title: "所属单位",
    dataIndex: "officeName",
    width: 180,
    align: "center",
    // ellipsis: true,
    scopedSlots: { customRender: "officeName" },
    // fixed: "left",
  },
  {
    title: "工种",
    align: "center",
    width: 100,
    dataIndex: "jobtitle",
    scopedSlots: { customRender: "jobtitle" },
  },
  {
    title: "登记时间",
    dataIndex: "createDate",
    width: 100,
    ellipsis: true,
    align: "center",
    scopedSlots: { customRender: "createDate" },
    customRender: (text) => useRender.renderDate(text, 'YYYY-MM-DD')
  },
  {
    title: "在场人数",
    dataIndex: "personNum",
    align: "center",
    width: 80,
    scopedSlots: { customRender: "personNum" },
  },
  {
    title: "退场人数",
    dataIndex: "offStaffNum",
    align: "center",
    width: 80,
    ellipsis: true,
    key: "offStaffNum",
  },
  {
    title: "新申请人数",
    dataIndex: "newStaffNum",
    align: "center",
    width: 80,
    key: "newStaffNum",
  },
  {
    title: "工作内容",
    dataIndex: "content",
    width: 130,
    align: "center",
    key: "content",
    ellipsis: true,
  },
  {
    title: "班组二维码",
    dataIndex: "teamQr",
    width: 80,
    align: "center",
    scopedSlots: { customRender: "teamQr" },
  },
  {
    title: "操作",
    align: "center",
    width: 120,
    // fixed: "right",
    scopedSlots: { customRender: "action" },
  },
];
const formData = [
  {
    label: "班组名称",
    placeholder: "请输入班组名称",
    decorator: [
      "teamName",
      { rules: [{ required: false, message: "请选择班组名称" }] },
    ],
    type: "inputGroup",
    key: "teamName",
    dataSource: [],
    col: 6,
    labelCol: 5,
    wrapperCol: 14,
  },
  {
    label: "所属单位",
    placeholder: "请输入所属单位",
    decorator: [
      "officeId",
      { rules: [{ required: false, message: "请选择所属单位" }] },
    ],
    type: "inputGroup",
    key: "officeId",
    dataSource: [],
    col: 6,
    labelCol: 5,
    wrapperCol: 14,
    display: true,
  },
  // {
  //   label: "工种",
  //   placeholder: "请选择工种",
  //   decorator: [
  //     "postId",
  //     { rules: [{ required: false, message: "请选择工种" }] },
  //   ],
  //   type: "select",
  //   key: "postId",
  //   selectlist: [],
  //   col: 6,
  //   labelCol: 4,
  //   wrapperCol: 14,
  // },
  {
    label: "登记时间",
    placeholder: "请选择登记时间",
    decorator: [
      "startTime",
      { rules: [{ required: false, message: "请选择登记时间" }] },
    ],
    type: "rangePicker",
    key: "startTime",
    dataSource: [],
    col: 6,
    labelCol: 5,
    wrapperCol: 14,
    display: true,
    format: "yyyy-MM-DD",
  },
  {
    type: "action",
    col: 5,
    display: true,
    key: "action",
  },
  //   ,
  //   {
  //     label: "现场联系人",
  //     placeholder: "请输入现场联系人",
  //     decorator: [
  //       "officeName",
  //       { rules: [{ required: false, message: "请选择现场联系人" }] },
  //     ],
  //     type: "inputGroup",
  //     key: "officeName",
  //     selectlist: [],
  //     qb: true,
  //     col: 8,
  //     labelCol: 6,
  //     wrapperCol: 14,
  //   },
  //   {
  //     label: "联系电话",
  //     placeholder: "请输入联系电话",
  //     decorator: [
  //       "officeName",
  //       { rules: [{ required: false, message: "联系电话" }] },
  //     ],
  //     type: "inputGroup",
  //     key: "officeName",
  //     selectlist: [],
  //     qb: true,
  //     col: 8,
  //     labelCol: 6,
  //     wrapperCol: 14,
  //   },
];
export default {
  mixins: [exportProgress], //导出方法注入
  components: {
    formIndex, //form表单封装
    StandardTable, //表格封装
    ExportLoading, //导出数据加载封装
  },
  data() {
    return {
      columns: columns, //表格数据
      dataSource: [],
      pagination: {
        showSizeChanger:true,
        pageSizeOptions: ["10", "20", "30", "50"],
        current: 1,
        pageSize: 10,
        total: 0,
        showLessItems: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      tableLoading: false,
      formData: formData,
      params: {
        pageNumber: 1,
        pageSize: 10,
        isOnline: 1,
      },
      projectArr: [],
      projectList: [], //获取当前项目数据
      loadingText: "数据加载中...",
      cPercent: 0,
      isExporting: false,
      projectId: "",
      showEvaluate: false,
      confirmLoading: false,
      teamQrCodeList: [],
      modalVisible: false,
      previewImage: "",
      teamName: "",
      defaultActiveKey: this.$route.query.isOnline == 0 ? '2': '1',
    };
  },
  computed: {
    textStyle() {
      return function (val) {
        if (val.statusw == 4 && (val.pjsPath != null || val.pjsPath != "")) {
          return "color:red";
        }
      };
    },
    ...mapGetters("account", ["user"]),
  },
  watch: {
    loadingText: {
      handler(newName, oldName) {
        this.loadingText = newName;
      },
      immediate: true,
    },
  },
  mounted() {
    // this.projectId = this.user.projectId;
    // //   if(this.$route.query.pageNumber){
    // //       this.params.pageNumber = this.$route.query.pageNumber
    // //   }
    // this.getgcglbList();
    // this.aqxjlist();
  },
  activated() {
    this.$nextTick(() => {
      // 新增参建班组时。tabs显示无在场人员班组
      if (this.$route.query.isOnline == 0) {
        this.params.isOnline = 0
      }
      this.projectId = this.user.projectId;
      // this.getgcglbList();
      this.aqxjlist(this.params);
      this.projectArr = [];
      this.getZdata(getProAllOfficeList, {value: '', item: {key: 'officeId'}}, "officeName",'officeId');
    })
  },
  methods: {
    downQrCode() {
      if (this.previewImage) {
        //这里objimg 表示图片显示的路径  一般都是data:image/jpeg;base64,iVBORw0KGgoAAAANS
        const byteCharacters = atob(this.previewImage.split(",")[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        //区分是什么类型的 svg 或者是png 格式 切记不同类型后缀不同。使用错误可能会下载图片打不开
        let blobs = "image/png";
        let url = window.URL.createObjectURL(
          new Blob([byteArray], { type: blobs })
        );
        let link = document.createElement("a"); //创建a标签
        link.style.display = "none"; //将a标签隐藏
        link.href = url; //给a标签添加下载链接
        link.setAttribute("download", `${this.teamName}.jpg`);
        document.body.appendChild(link);
        link.click(); //执行a标签
      }
    },
    //获取工种
    getWorkerType() {
      getJobByWorkerType([]).then((res) => {
        if (res.data.code === 0) {
          this.formDataFn("postId", res.data.data, "carft");
        }
      });
    },
    // 封装弹窗确定按钮
    confirm(title, content) {
      return new Promise((resolve, reject) => {
        this.$confirm({
          title: title,
          content: content,
          onOk() {
            resolve();
          },
        });
      });
    },
    // 删除接口
    recorDelete(val, text) {
      let params = {
        teamId: val.id,
        projectId: this.projectId,
      };
      this.confirm("删除", `是否删除 ${val.teamName} 班组`).then(() => {
        delTeams(params).then((res) => {
          if (res.data.code === 0) {
            this.$message.success("删除成功", 10);
            this.aqxjlist(this.params);
          } else {
            this.$message.error(res.data.msg, 10);
          }
        });
      });
    },
    /**
     * 导出excel
     */
    doDowload() {
      let obj = {
        // officeId:this.user.officeId,
        projectId: this.user.projectId,
      };
      this.loadingText = "正在导出EXCEL文件，请稍候 ····";
      this.isExporting = true;
      this.cPercent = 0;
      let fileName = "参建班组花名册.xlsx";
      this.exportFunNomal2(
        STAFFLIB_EXPORT,
        obj,
        fileName,
        "application/vnd.ms-excel"
      );
    },
    // 查看编辑
    viewEditing(e, look) {
      this.$router.push({
        name: "参建班组详情",
        query: {
          look: look ? look : false,
          id: e.id,
          type: "table",
          pageNumber: this.params.pageNumber,
        },
      });
    },
    handleChange(e) {
      this.projectArr = [];
      if (e.item.key == 'officeId') {
        if (!e.value) {
          this.$refs["formindex"].setFieldsValue('', {}, "officeId");
        }
        this.getZdata(getProAllOfficeList, e, "officeName","officeId");
      } else if (e.item.key == 'teamName') {
        this.getZdata(getTeams, e, "teamName");
      }
    },
    // 接口封装
    /**
     * msg 接收接口名称
     * e 数据
     * name 参数名
     * */
    getZdata(msg, e, name, key) {
      let data = {
        [name]: e.value,
        projectId: this.user.projectId,
        isOnline:this.params.isOnline // 是否在场
      };

      msg(data).then((res) => {
        if (res.status == 200) {
          const data = res.data.data.list ? res.data.data.list : res.data.data
          data.forEach((item) => {
            this.projectArr.push({
              title: item[name],
              id: key ? item[e.item.key] : item[name],
            });
          });
          this.projectList = data;
          // 获取当前匹配key，赋值相对于数组
          this.formData.forEach((item) => {
            if (e.item.key === item.key) {
              item.dataSource = this.projectArr;
            }
          });
        }
      });
    },
    // 新建项目
    createItem() {
      this.$router.push({ name: "新增参建班组" });
    },
    // 特殊数据处理
    handlSelect({ val, keys }) {
      if (keys == "officeId") {
        this.projectList.forEach((item) => {
          if (item.officeId == val) {
            this.$refs["formindex"].setFieldsValue(
              item.officeId,
              {},
              "officeId"
            );
            this.doQueryTeams({ officeId: item.officeId });
          }
        });
      } else if (keys == "teamName") {
        this.formData.forEach((item) => {
          if (item.key == "teamName") {
            item.dataSource.forEach((el) => {
              if (el.id == val) {
                this.$refs["formindex"].setFieldsValue(
                  el.title,
                  {},
                  "teamName"
                );
              }
            });
          }
        });
      }
    },
    //查询所有班组
    doQueryTeams(params) {
      getAllTeams(params).then((res) => {
        if (res.data.code === 0) {
          let arrSource = [];
          res.data.data.forEach((item) => {
            arrSource.push({ title: item.teamName, id: item.id });
          });
          this.formData.forEach((item) => {
            if (item.key == "teamName") {
              item.dataSource = arrSource;
            }
          });
        }
      });
    },
    aqxjlist(param) {
      this.tableLoading = true;
      console.log(param,'param,======')
      let params = {
        ...param,
        projectId: this.projectId,
        // officeId: this.user.officeId
      };
      getTeams(params).then((res) => {
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
          let teamIds = this.dataSource.map((item) => item.id);
          let obj = {
            teamIds: teamIds,
            oldProjectId: this.projectId,
          };

          // 获取班组二维码
          getQrCodeList(obj).then((result) => {
            if (result.data.code === 0) {
              this.teamQrCodeList = result.data.data;
              this.dataSource.map((item) => {
                // 返回的map对象
                Object.keys(this.teamQrCodeList).map((key) => {
                  if (item.id == key) {
                    item.teamQr = this.teamQrCodeList[key];
                  }
                });
              });
              this.dataSource = [...this.dataSource];
            }
          });
          this.tableLoading = false;
        }
      });
    },
    // 根据条件查询公司
    // aqxCheckList()
    // 对下拉框做数据处理
    formDataFn(key, data, itemName) {
      let arr = [];
      data.forEach((item) => {
        arr.push({ name: item[itemName], value: item.id });
      });
      this.formData.forEach((item) => {
        if (item.key == key) {
          if (itemName) {
            item.selectlist = arr;
          } else {
            item.selectlist = data;
          }
        }
      });
    },
    // 提交表单数据
    handleSubmit(e) {
      this.params.pageNumber = 1;
      this.pagination.current = 1;
      let obj = Object.assign({}, e, this.params);
      console.log(e,'e===')
      console.log(this.formData,'this.formData*************')
      if (obj.startTime && obj.startTime.length > 0) {
        let arrDate = obj.startTime;
        obj.startTime = arrDate[0];
        obj.endTime = arrDate[1];
      }
      this.aqxjlist(obj);
    },
    // 重置
    close() {
      this.params.pageNumber = 1;
      this.params.pageSize = 10;
      this.aqxjlist(this.params);
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      // this.$refs["formindex"].setFieldsValue("", {}, "projectName");
      this.$refs["formindex"].resetFields();
      let obj = this.$refs["formindex"].getFieldValue();
      console.log(obj,'*************')
    },
    //表格 - 分页查询
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      this.params.pageNumber = e.current;
      this.pagination.current = e.current;
      this.params.pageSize = e.pageSize;
      this.pagination.pageSize = e.pageSize;
      this.aqxjlist(Object.assign({}, this.params, obj));
    },
    // 获取项目侧参建单位
    getgcglbList() {
      getProAllOfficeList({ projectId: this.user.projectId }).then((res) => {
        if (res.data.code === 0) {
          const data = res.data.data;
          const arr = [];
          data.forEach((item) => {
            arr.push({
              name: item.officeName,
              value: item.officeId,
            });
          });
          this.formDataFn("officeId", arr);
        }
      });
    },
    // 评价
    recordView(e, type) {
      if (type == "evaluate") {
        this.$router.push({
          name: "班组评价",
          // name: "班组评价",
          query: {
            data: e,
            id: e.id,
            type: type,
            pageNumber: this.params.pageNumber,
            projectId: e.projectId,
          },
        });
      } else if (type == 5) {
        this.$router.push({
          name: "班组门禁通道",
          query: {
            officeId: e.officeId,
            teamId: e.id,
            type: type,
          },
        });
      } else if (type == 6) {
        this.$router.push({
          name: "班组考勤设备",
          query: {
            officeId: e.officeId,
            teamId: e.id,
            type: type,
          },
        });
      }
    },
    // 图片预览
    showImg(imgPath, record) {
      this.modalVisible = true;
      this.previewImage = imgPath;
      this.teamName = record.teamName;
    },
    handleCancel() {
      this.modalVisible = false;
      this.previewImage = "";
      this.teamName = "";
    },
    callback(key) {
      if (key == 1) {
        this.params.isOnline = 1;
      } else {
        this.params.isOnline = 0;
      }
      this.defaultActiveKey = key
      this.close();
    },
  },
};
</script>

<style lang="less" scoped>
.ant-descriptions-item-label {
  background: red !important;
}

.e-descriptions /deep/ .ant-descriptions-item-label {
  width: 120px;
  padding: 6px 7px;
}
.e-descriptions /deep/ .ant-descriptions-item-content {
  padding: 6px 7px;
}

.constructiontTable_index {

  padding: 20px;
  background: #fff;
  border-radius: 6px;
  margin-bottom: 10px;

  .c_i_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 25px;

    .c_i_h_center {
      padding: 16px;
      border-radius: 4px;
      width: 180px;
      height: 130px;
      margin-right: 50px;

      div:nth-child(1) {
        font-size: 16px;
      }

      div:nth-child(2) {
        margin-top: 26px;
        font-size: 24px;
      }
    }
  }
}

.sTable {
  display: flex;
  justify-content: space-between;
  align-items: center;

  // div {
  //     width: 100%;
  //     color: blue;
  //     text-align: center;
  //     cursor: pointer;
  // }
}

.sTables {
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    // width: 100%;
    padding: 0 10px;
    text-align: center;
    cursor: pointer;
  }
  div:nth-child(1) {
    color: #307dfa;
  }
  div:nth-child(2) {
    color: #ff5c77;
  }
  div:nth-child(3) {
    color: #615e83;
  }
}
.content_div {
  padding: 5px 0;
  cursor: pointer;
}
.content_div:hover {
  color: #307dfa;
}
</style>
