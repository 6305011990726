var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: "960px", title: _vm.title, footer: null },
      on: { cancel: _vm.close },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "a-form-model",
        { ref: "ruleForm", attrs: { model: _vm.params, rules: _vm.rules } },
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "公司",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: { disabled: "", placeholder: "" },
                        model: {
                          value: _vm.params.companyName,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "companyName", $$v)
                          },
                          expression: "params.companyName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "分公司",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: { disabled: "", placeholder: "" },
                        model: {
                          value: _vm.params.branchOfficeName,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "branchOfficeName", $$v)
                          },
                          expression: "params.branchOfficeName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "项目名称",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: { disabled: "", placeholder: "" },
                        model: {
                          value: _vm.params.projectName,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "projectName", $$v)
                          },
                          expression: "params.projectName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "危大工程类别",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                        prop: "gclbId",
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "240px" },
                          attrs: { allowClear: "", placeholder: "请选择 " },
                          model: {
                            value: _vm.params.gclbId,
                            callback: function ($$v) {
                              _vm.$set(_vm.params, "gclbId", $$v)
                            },
                            expression: "params.gclbId",
                          },
                        },
                        _vm._l(_vm.options1, function (item) {
                          return _c(
                            "a-select-option",
                            {
                              key: item.value,
                              attrs: { value: item.value, title: item.label },
                            },
                            [_vm._v(" " + _vm._s(item.label) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "施工方案名称",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                        prop: "sgfaName",
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入 " },
                        model: {
                          value: _vm.params.sgfaName,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "sgfaName", $$v)
                          },
                          expression: "params.sgfaName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "是否超一定规模",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                        prop: "overRange",
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "240px" },
                          attrs: { allowClear: "", placeholder: "请选择 " },
                          model: {
                            value: _vm.params.overRange,
                            callback: function ($$v) {
                              _vm.$set(_vm.params, "overRange", $$v)
                            },
                            expression: "params.overRange",
                          },
                        },
                        _vm._l(_vm.options, function (item) {
                          return _c(
                            "a-select-option",
                            {
                              key: item.value,
                              attrs: { value: item.value, title: item.label },
                            },
                            [_vm._v(" " + _vm._s(item.label) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: "是否专家认证",
                                "label-col": { span: 6 },
                                "wrapper-col": { span: 14 },
                                prop: "expertProve",
                              },
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  staticStyle: { width: "240px" },
                                  attrs: {
                                    allowClear: "",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.params.expertProve,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.params, "expertProve", $$v)
                                    },
                                    expression: "params.expertProve",
                                  },
                                },
                                _vm._l(_vm.options, function (item) {
                                  return _c(
                                    "a-select-option",
                                    {
                                      key: item.value,
                                      attrs: {
                                        value: item.value,
                                        title: item.label,
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(item.label) + " ")]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.params.expertProve == 1
                        ? _c(
                            "a-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: "论证时间",
                                    "label-col": { span: 6 },
                                    "wrapper-col": { span: 14 },
                                    prop: "proveTime",
                                  },
                                },
                                [
                                  _c("a-date-picker", {
                                    attrs: { valueFormat: "YYYY-MM-DD" },
                                    model: {
                                      value: _vm.params.proveTime,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.params, "proveTime", $$v)
                                      },
                                      expression: "params.proveTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "最终审批时间",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                        prop: "aproveTime",
                      },
                    },
                    [
                      _c("a-date-picker", {
                        attrs: { valueFormat: "YYYY-MM-DD" },
                        model: {
                          value: _vm.params.aproveTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "aproveTime", $$v)
                          },
                          expression: "params.aproveTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "编制时间",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                        prop: "makeTime",
                      },
                    },
                    [
                      _c("a-date-picker", {
                        attrs: { valueFormat: "YYYY-MM-DD" },
                        model: {
                          value: _vm.params.makeTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "makeTime", $$v)
                          },
                          expression: "params.makeTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "附件",
                        "label-col": { span: 3 },
                        "wrapper-col": { span: 14 },
                      },
                    },
                    [
                      _c(
                        "a-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            "list-type": "picture-card",
                            accept: _vm.accept1,
                            "file-list": _vm.filePath1,
                            customRequest: _vm.resourceUpload1,
                            beforeUpload: _vm.beforeUpload1,
                          },
                          on: {
                            change: _vm.handleUploadChange1,
                            preview: _vm.handlePreview,
                          },
                        },
                        [
                          _vm.filePath1.length < 3
                            ? _c(
                                "div",
                                [
                                  _c("a-icon", {
                                    attrs: {
                                      type: _vm.uploading1 ? "loading" : "plus",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isEdit != 2
        ? _c(
            "a-row",
            [
              _c("a-col", { attrs: { span: 12 } }),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        "label-col": { span: 4 },
                        "wrapper-col": { span: 14 },
                        prop: "year",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex flex-row-reverse" },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.sbloading,
                              },
                              on: { click: _vm.handleSubmit },
                            },
                            [_vm._v("保存")]
                          ),
                          _c(
                            "a-button",
                            { staticClass: "mr-2", on: { click: _vm.close } },
                            [_vm._v("返回")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }