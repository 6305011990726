<template>
  <div class="basic-box">
    <a-descriptions bordered :column="2" class="e-descriptions">
      <a-descriptions-item
        v-for="(desc, index) in descList"
        :key="index"
        :label="desc.menuText"
        :span="desc.span"
      >
        <div class="risk-rules" :style="desc.span ? 'width: 100%' : ''">
          <template v-if="desc.image">
            <span
              v-if="desc.value"
              class="check"
              @click="modalCheck(desc.menuText, '', '', desc.value)"
              >查看</span
            >
            <span v-else>暂无数据</span>
          </template>
          <template v-else>
            {{ desc.value }}
          </template>
        </div>
      </a-descriptions-item>
    </a-descriptions>
    <!-- 查看图片模态框 -->
    <a-modal
      v-model="modalVisible"
      :title="modalTitle"
      :destroyOnClose="true"
      :width="800"
      :footer="null"
      @cancel="modalCancel"
    >
      <a-descriptions bordered :column="1">
        <a-descriptions-item v-if="modalSubTitle" :label="modalSubTitle">
          {{ modalContent }}
        </a-descriptions-item>
        <a-descriptions-item label="附件">
          <img
            class="attachment-img"
            v-if="attachmentUrl_2"
            :src="attachmentUrl_2"
            alt=""
          />
          <img
            class="attachment-img"
            v-if="attachmentUrl"
            :src="attachmentUrl"
            alt=""
          />
          <span v-else>暂未上传</span>
        </a-descriptions-item>
      </a-descriptions>
    </a-modal>
  </div>
</template>

<script>
const descList = [
  {
    menuKey: "code",
    menuText: "统一社会信用代码",
    value: "",
  },
  {
    menuKey: "name",
    menuText: "公司名称",
    value: "",
  },
  {
    menuKey: "address",
    menuText: "公司地址",
    value: "",
  },
  {
    menuKey: "capital",
    menuText: "注册资金",
    value: "",
  },
  {
    menuKey: "master",
    menuText: "法人代表",
    value: "",
  },
  {
    menuKey: "phone",
    menuText: "联系方式",
    value: "",
  },
  {
    menuKey: "startDate",
    menuText: "成立时间",
    value: "",
  },
  {
    menuKey: "parentId",
    menuText: "上级单位",
    value: "",
  },
  {
    menuKey: "licence",
    menuText: "营业执照",
    value: "",
    image: true,
  },
  {
    menuKey: "credential",
    menuText: "公司资质",
    value: "",
    image: true,
  },
  {
    menuKey: "mainBusiness",
    menuText: "主营业务",
    value: "",
    span: 2,
  },
  {
    menuKey: "honor",
    menuText: "获得荣誉",
    value: "",
    span: 2,
  },
  {
    menuKey: "achievement",
    menuText: "业绩",
    value: "",
    span: 2,
  },
  {
    menuKey: "scope",
    menuText: "经营范围",
    value: "",
    span: 2,
  },
];
import { getOfficeDetail } from "@/services/projectManagement";
export default {
  name: "SuijianClientCompanyDetailsBasic",
  props: {
    officeId: {
      type: [String, Number],
      require: false,
    },
  },
  data() {
    return {
      descList,
      item: {},
      modalVisible: false,
      modalTitle: "",
      modalSubTitle: "",
      modalContent: "",
      attachmentUrl: "",
      attachmentUrl_2: "",
    };
  },
  watch: {
    officeId(newV, oldV) {
      if (newV) {
        this.init();
      }
    },
  },

  mounted() {
    // this.init();
  },
  // activated() {
  //   this.init();
  // },
  
  methods: {
    init() {
      getOfficeDetail({
        id: this.officeId ? this.officeId : this.$route.query.id,
      }).then((res) => {
        if (res.data.code === 0) {
          this.item = res.data.data;
          this.item.capital = this.item.capital ? `${this.item.capital}万元` : '暂无数据' // 展示万元
          this.descList.forEach((item) => {
            Object.keys(this.item).forEach((key) => {
              if (item.menuKey == key) {
                item.value = this.item[key];
              }
            });

            if (item.menuKey == "mainBusiness" && item.value) {
              item.value = this.ptypeState("mainBusiness", item.value);
            }
          });
        }
      });
    },
    // 打开模态框
    modalCheck(title, subTitle, content, url) {
      this.modalTitle = title;
      this.modalSubTitle = subTitle;
      this.modalContent = content;
      if (Array.isArray(url) && url.length > 1) {
        this.attachmentUrl = url[0];
        this.attachmentUrl_2 = url[1];
      } else {
        this.attachmentUrl = url;
      }
      this.modalVisible = true;
    },
    // 关闭模态框，清除默认值
    modalCancel() {
      setTimeout(() => {
        (this.modalTitle = ""),
          (this.modalSubTitle = ""),
          (this.modalContent = ""),
          (this.attachmentUrl = ""),
          (this.attachmentUrl_2 = "");
      }, 200);
    },
    ptypeState(type, status) {
      if (type == "mainBusiness") {
        const obj = {
          1: "总包",
          2: "监理",
          3: "劳务分包",
          4: "专业分包",
          5: "老包",
        };
        let str = null;
        Object.keys(obj).forEach((key) => {
          if (key == status) {
            str = obj[key];
          }
        });
        return str;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.basic-box {
  padding: 0 24px 24px 24px;
}
.e-descriptions {
  width: 100%;

  .risk-rules {
    padding: 10px 15px;
    color: rgb(118, 131, 143);
    font-size: 15px;
    width: 100%;
    > div {
      display: flex;
      width: 100%;
      align-items: center;
      margin-bottom: 5px;
    }
  }
}
.e-descriptions /deep/ .ant-descriptions-item-label {
  width: 180px;
  // padding: 6px 7px;
  text-align: right;
  background: #f3f7f9;
  color: rgb(118, 131, 143);
  font-size: 15px;
}
.e-descriptions /deep/ .ant-descriptions-item-content {
  padding: 6px 7px 6px 0;
  // padding: 0;
}
.check {
  color: #89bceb;
  cursor: pointer;
}
</style>
