var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("form-index", {
        ref: "formindex",
        attrs: { formData: _vm.formData, flexAction: "" },
        on: { handleSubmit: _vm.handleSubmit, handlSelect: _vm.handlSelect },
        scopedSlots: _vm._u([
          {
            key: "flexAction",
            fn: function () {
              return [
                _c(
                  "a-space",
                  { staticClass: "btnBox" },
                  [
                    _c(
                      "a-button",
                      { attrs: { type: "primary", "html-type": "submit" } },
                      [_vm._v("查询")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "a_a_footer" },
        [
          _c(
            "div",
            [
              _c(
                "a-space",
                { staticClass: "operator" },
                [
                  _c(
                    "a-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.$route.meta.isBreak,
                          expression: "!$route.meta.isBreak",
                        },
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.addNewRecord },
                    },
                    [_vm._v("新增")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("standard-table", {
            attrs: {
              columns: _vm.columns,
              dataSource: _vm.dataSource,
              rowKey: "id",
              pagination: _vm.pagination,
              loading: _vm.tableLoading,
              isAlert: false,
              bordered: true,
            },
            on: { change: _vm.onPageChange },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function (ref) {
                  var index = ref.index
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            (_vm.pagination.current - 1) *
                              _vm.pagination.pageSize +
                              parseInt(index) +
                              1
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "firstPath",
                fn: function (ref) {
                  var text = ref.text
                  var record = ref.record
                  return [
                    text
                      ? _c("a-icon", {
                          style: { fontSize: "32px" },
                          attrs: { type: "file-pdf" },
                          on: {
                            click: function ($event) {
                              return _vm.handlePreview(1, record)
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "secondPath",
                fn: function (ref) {
                  var text = ref.text
                  var record = ref.record
                  return [
                    text
                      ? _c("a-icon", {
                          style: { fontSize: "32px" },
                          attrs: { type: "file-pdf" },
                          on: {
                            click: function ($event) {
                              return _vm.handlePreview(2, record)
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "thirdPath",
                fn: function (ref) {
                  var text = ref.text
                  var record = ref.record
                  return [
                    text
                      ? _c("a-icon", {
                          style: { fontSize: "32px" },
                          attrs: { type: "file-pdf" },
                          on: {
                            click: function ($event) {
                              return _vm.handlePreview(3, record)
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "fourthPath",
                fn: function (ref) {
                  var text = ref.text
                  var record = ref.record
                  return [
                    text
                      ? _c("a-icon", {
                          style: { fontSize: "32px" },
                          attrs: { type: "file-pdf" },
                          on: {
                            click: function ($event) {
                              return _vm.handlePreview(4, record)
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "action",
                fn: function (ref) {
                  var record = ref.record
                  return _c("div", {}, [
                    _c("div", { staticClass: "sTables" }, [
                      _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.visit(record)
                            },
                          },
                        },
                        [_vm._v("查看")]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.$route.meta.isBreak,
                              expression: "!$route.meta.isBreak",
                            },
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.del(record)
                            },
                          },
                        },
                        [_vm._v("删除")]
                      ),
                    ]),
                  ])
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }