var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form-model",
        {
          staticClass: "mb-5 query-form",
          attrs: { layout: "inline", model: _vm.params },
          on: { submit: _vm.handleSubmit },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "分公司" } },
            [
              _c(
                "a-select",
                {
                  staticStyle: { width: "220px" },
                  attrs: { allowClear: "", placeholder: "请选择" },
                  model: {
                    value: _vm.params.gcglbId,
                    callback: function ($$v) {
                      _vm.$set(_vm.params, "gcglbId", $$v)
                    },
                    expression: "params.gcglbId",
                  },
                },
                _vm._l(_vm.gcglbList, function (item) {
                  return _c(
                    "a-select-option",
                    {
                      key: item.value,
                      attrs: { value: item.value, title: item.name },
                    },
                    [_vm._v(" " + _vm._s(item.name) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "年度" } },
            [
              _c("a-date-picker", {
                staticStyle: { width: "220px" },
                attrs: {
                  allowClear: "",
                  mode: "year",
                  placeholder: "请选择年度",
                  format: "YYYY",
                },
                on: { panelChange: _vm.changeYear },
                model: {
                  value: _vm.params.year,
                  callback: function ($$v) {
                    _vm.$set(_vm.params, "year", $$v)
                  },
                  expression: "params.year",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "项目名称" } },
            [
              _c("a-input", {
                staticStyle: { width: "220px" },
                attrs: { placeholder: "请输入项目名称", allowClear: "" },
                model: {
                  value: _vm.params.projectName,
                  callback: function ($$v) {
                    _vm.$set(_vm.params, "projectName", $$v)
                  },
                  expression: "params.projectName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "带班领导" } },
            [
              _c("a-input", {
                staticStyle: { width: "220px" },
                attrs: { placeholder: "请输入带班领导", allowClear: "" },
                model: {
                  value: _vm.params.leader,
                  callback: function ($$v) {
                    _vm.$set(_vm.params, "leader", $$v)
                  },
                  expression: "params.leader",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    { attrs: { type: "primary", "html-type": "submit" } },
                    [_vm._v(" 查询 ")]
                  ),
                  _c("a-button", { on: { click: _vm.resetQuery } }, [
                    _vm._v(" 重置 "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "a_a_footer" },
        [
          _c(
            "div",
            [
              _c(
                "a-space",
                { staticClass: "operator" },
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.addNewPlan },
                    },
                    [_vm._v("新增")]
                  ),
                ],
                1
              ),
              _c(
                "a-space",
                { staticClass: "operator" },
                [
                  _vm.user.isGcglb == 0 && _vm.user.loginType == 1
                    ? _c(
                        "a-button",
                        { staticClass: "mr-9", on: { click: _vm.doDowload } },
                        [_vm._v(" 导出 ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("standard-table", {
            attrs: {
              columns: _vm.columns,
              dataSource: _vm.tableData,
              rowKey: "id",
              pagination: _vm.pagination,
              scroll: { x: 1300 },
              loading: _vm.tableLoading,
              isAlert: false,
              bordered: true,
            },
            on: { change: _vm.handleChangePage },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function (ref) {
                  var index = ref.index
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          (_vm.pagination.current - 1) *
                            _vm.pagination.pageSize +
                            parseInt(index) +
                            1
                        )
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "problems",
                fn: function (ref) {
                  var index = ref.index
                  var record = ref.record
                  return _vm._l(record.problems, function (problem, i) {
                    return _c("div", { key: i }, [_vm._v(_vm._s(problem))])
                  })
                },
              },
              {
                key: "action",
                fn: function (ref) {
                  var text = ref.text
                  var record = ref.record
                  return _c("div", {}, [
                    _c(
                      "a",
                      {
                        on: {
                          click: function () {
                            return _vm.handleEdit(record)
                          },
                        },
                      },
                      [_vm._v(" 编辑 ")]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "ml-2",
                        staticStyle: { color: "red" },
                        on: {
                          click: function () {
                            return _vm.handleDel(record)
                          },
                        },
                      },
                      [_vm._v(" 删除 ")]
                    ),
                  ])
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("Export-loading", {
        attrs: {
          cPercent: _vm.cPercent,
          isExporting: _vm.isExporting,
          loadingText: _vm.loadingText,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }