<template>
  <a-card>
    <div :class="advanced ? 'search' : null">
      <a-form layout="horizontal">
        <div :class="advanced ? null: 'fold'">
          <a-row :gutter="24">
            <a-col :span="6">
              <a-form-item
                label="日期"
                :labelCol="{span: 5}"
                :wrapperCol="{span: 16, offset: 1}"
              >
                <!-- <a-input placeholder="请输入" v-model="queryParams.userName"/> -->
                  <a-date-picker :format="monthFormat" v-model="queryParams.enterDateStr"  :allowClear="false" style="width:100%"/>
              </a-form-item>
            </a-col>
            <a-col v-if="user.loginType == 1" :span="6" >
              <a-form-item 
                :labelCol="{span: 7}" 
                :wrapperCol="{span: 16, offset: 1}" 
                label="项目名称">
                  <a-auto-complete :data-source="inputDataSource" v-model="queryParams.projectId"
                      placeholder="请输入项目名称" @search="handleInputSearch($event)" allowClear
                      @select="handlInputSelect($event)">
                      <template slot="dataSource">
                          <a-select-option v-for="items in inputDataSource" :key="items.id + ''">{{ items.title }}</a-select-option>
                      </template>
                  </a-auto-complete>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item
                label="班组名称"
                :labelCol="{span: 7}"
                :wrapperCol="{span: 16, offset: 1}"
              >
                <a-select
                  show-search
                  placeholder="请输入或选择班组"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  @focus="handleFocus"
                  @blur="handleBlur"
                  @change="handleChange"
                  v-model="queryParams.teamId"
                >
                  <a-select-option :key="item.id" v-for="item in teamDatas" :value="item.id">
                    {{item.teamName}}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6" :style="{marginTop: '4px'}">
              <a-space>
                <a-button icon="search" type="primary" @click="doQuery()">查询</a-button>
                <a-space class="operator">
                  <a-button style="margin-left: 8px" @click="doReset()">重置</a-button>
                </a-space>
              </a-space>
          </a-col>
        </a-row>
        </div>

      </a-form>
    </div>
    <div>
      <div style="margin-top: -10px;margin-bottom: 5px">
        <a-space class="operator">
          <a-button icon="download"  @click="doDowloadZhBatch()">批量下载整合版</a-button>
        </a-space>
        <a-space class="operator">
          <a-button icon="download"  @click="doDowloadFenkaiBatch()">批量下载个人版</a-button>
        </a-space>
      </div>
      <standard-table
        :columns="columns"
        :dataSource="dataSource"
        :selectedRows.sync="selectedRows"
        :pagination="pagination"
        :loading="tableLoading"
        @clear="onClear"
        @change="onPageChange"
      >
        <div slot="safediscloseFileName" slot-scope="{text}">
          <div v-if="text!=null&&text!=''">{{text}}</div>
          <a-icon v-else style="color:red" type="close" />
        </div>
        <div slot="do-status" slot-scope="{text}">
          <a-icon v-if="text==1" style="color:green" type="check" />
          <a-icon v-else style="color:red" type="close" />
        </div>
        <div slot="do-userList" slot-scope="{text,record}">
          <!-- {{record.userNames}} -->
          <a-popover placement="bottom">
            <template slot="content">
              <p v-for="(item, key) in record.userNames.split(',')" :key="key">{{item}}</p>
            </template>
            <span>{{text| subText}}</span>
          </a-popover>
        </div>
        <template slot="projectName" slot-scope="{ text }">
          <a-tooltip placement="top">
            <template slot="title"
              ><span>{{ text }}</span></template
            >
            <span class="ellipsis-2">{{
              text
            }}</span>
          </a-tooltip>
        </template>
        <div slot="action" slot-scope="{text, record}">
          <!-- <a  @click="doPersonList(record)">人员列表</a>
          <a-divider type="vertical" /> -->
          <a @click="doDowloadZh(record)">整合版下载</a>
          <a-divider type="vertical" />
          <a @click="doDowloadFenkai(record)">个人版下载</a>
        </div>
        <template slot="statusTitle">
          <a-icon @click.native="onStatusTitleClick" type="info-circle" />
        </template>
      </standard-table>
    </div>
    <Export-loading :cPercent="cPercent" :isExporting="isExporting" :loadingText="loadingText"></Export-loading>
    <person-list :visible="personList.visible" :personList="personList" @perosonListClose="doClosePersonList" ></person-list>
  </a-card>
</template>

<script>
import exportFile from '@/utils/exportFile'
import StandardTable from '@/components/table/StandardTable'
import {
  STAFF_WORK_MEANS_QUERYPAGE,
  SAFEMEANS_DOWNLOAD,
  RESTART_CCONTRACT,
  RESTART_RCXZ,
  RESTART_ESS_CONTRACT,
  FIND_TEAMS_BY_USER
  } from '@/services/api'

import { exportProgress } from '@/pages/aqjyzl/export' // 导出方法
import ExportLoading from '@/components/ExportLoading/ExportLoading' // progress组件
import PersonList from '@/pages/aqjyzl/personlist' // progress组件
import {request} from '@/utils/request'
import moment from 'moment';
import {mapGetters} from 'vuex'
import { getOfficeProject } from "@/services/projectManagement";
import { useRender } from '@/hooks/useRender.js'
const columns = [
  {
    title: '发起类型',
    dataIndex: 'isAuto',
    align: 'center',
    width: 150,
    scopedSlots: { customRender: 'isAuto' },
    customRender: (text) => {
      if(text==0){
        return "劳务员手动发起";
      }else{
        return "系统自动发起";
      }
    }
  },
  {
    title: '所属项目',
    dataIndex: 'projectName',
    align: 'center',
    scopedSlots: { customRender: 'projectName' }
  },
  {
    title: '班组名称',
    dataIndex: 'teamName',
    align: 'center',
    scopedSlots: { customRender: 'teamName' },
  },
  {
    title: '人员列表',
    dataIndex: 'userNames',
    align: 'center',
    width: 150,
    scopedSlots: {customRender: 'do-userList'},
  },
  {
    title: '批次日期',
    dataIndex: 'enterDate',
    align: 'center',
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: '操作',
    align: 'center',
    width: 250,
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  mixins: [exportProgress],
  name: 'QueryList',
  components: {StandardTable,ExportLoading,PersonList},
  data () {
    return {
      inputDataSource: [],
      dataSource:[],
      advanced: false,
      columns: columns,
      selectedRows: [],
      tableLoading: false,
      spinning:false,
      loadingText:"数据加载中...",
      monthFormat: 'YYYY-MM-DD',
      nowMonthDay:null,
      loadKeys:[],
      visible:false,
      overDownIndex:0,
      teamDatas:[],
      queryParams:{
        enterDateStr:undefined,
        idCard:null,
        teamId:undefined,
        pageNumber:1,
        projectId: ''
      },
      pagination:{
        current:1,
        pageSize:10,
        total:0,
        showLessItems:true,
        showQuickJumper:true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      personList:{
        visible:false,
        id:null,
        date:null,
        teamId:null,
        teamName:null,
        enterCode:null,
        isAuto:1
      },
      cPercent:0,
      isExporting:false,
      fileName:'入场资料文件',
    }
  },
  filters:{
    subText:function(value){
      if(value&&value.length>50){
        return value.substring(0,50)+"……";
      }else{
        return value;
      }
    }
  },
  watch: {
    cPercent:{
      handler(newName, oldName) {
        this.cPercent=newName;
      },
      immediate: true,
    },
    loadingText:{
      handler(newName, oldName) {
        this.loadingText=newName;
      },
      immediate: true,
    }
  },
  computed: {
      ...mapGetters('account', ['user']),
    },
  created() {
    // this.nowMonthDay = moment(new Date()).format('YYYY-MM-DD');
    // this.queryParams.enterDateStr = moment(new Date()).format('YYYY-MM-DD');
    this.personList.date = moment(new Date()).format('YYYY-MM-DD');
    this.doQuery();
    this.doQueryTeams();
    if (this.user.loginType == 0) {
      // 0 是项目人员 1是公司人员
      let keyToDelete = 'projectName'
      this.columns = this.columns.filter(item => item.dataIndex !== keyToDelete)
    }
  },
  authorize: {
    deleteRecord: 'delete'
  },
  methods: {
    moment,
    handlInputSelect(e) {
      this.doQueryTeams()
    },
    handleInputSearch(value) {
      this.inputDataSource = []
      if(!value) {
          return
      }
      let params = {
          officeId: this.user.officeId,
          projectName: value
      }
      getOfficeProject(params).then((res) => {
          if (res.data.code === 0) {
            res.data.data.list.forEach(el => {
                this.inputDataSource.push({
                    title: el.fullName,
                    id: el.id,
                })
            })

          }
      });
    },
    doPersonList(recode){
      this.personList.teamName=recode.teamName;
      this.personList.enterCode=recode.enterCode;
      this.personList.isAuto = recode.isAuto;
      this.personList.id = recode.key;
      this.personList.visible = true;

    },

    handleChange(value) {
      this.queryParams.teamId = value;
      this.doQuery();
    },
    handleBlur() {
      console.log('blur');
    },
    handleFocus() {
      console.log('focus');
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    doQueryTeams(){
      request(FIND_TEAMS_BY_USER,"post",{projectId: this.queryParams.projectId}).then(res => {
          if(res.data.code==0){
            this.teamDatas = res.data.data;
          }
        })
    },

    deleteRecord(key) {
      this.dataSource = this.dataSource.filter(item => item.key !== key)
      this.selectedRows = this.selectedRows.filter(item => item.key !== key)
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    remove () {
      this.dataSource = this.dataSource.filter(item => this.selectedRows.findIndex(row => row.key === item.key) === -1)
      this.selectedRows = []
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },

    /**
     * 获取所有人员的签名记录信息
     */
    doQuery(){
      this.tableLoading = true;
      if (this.queryParams.enterDateStr) {
       this.queryParams.enterDateStr = moment(this.queryParams.enterDateStr).format('YYYY-MM-DD');
      }
      this.personList.date = this.queryParams.enterDateStr;
      request(STAFF_WORK_MEANS_QUERYPAGE,"post",this.queryParams).then(res => {
        if(res.data.code==0){
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
          this.dataSource.forEach(obj=>{
            obj.key = obj.id;
          })
        }
        this.tableLoading = false;
      })
    },
    doReset(){
      this.queryParams={
        enterDateStr:undefined,
        idCard:null,
        teamId:undefined,
        pageNumber:1,
        projectId: ''
      };
      this.doQuery();
    },
    //分页查询
    onPageChange(e){
      this.queryParams.pageNumber = e.current;
      this.pagination.current=e.current;
      this.doQuery();
    },

    doDowloadZhBatch(){
      if(this.selectedRows&&this.selectedRows.length>0){
        this.selectedRows.forEach(o=>{
          this.doDowloadZh(o);
        })
      }else{
        this.$message.info('请选中批次记录下载');
        return;
      }
    },
    doDowloadFenkaiBatch(){
      if(this.selectedRows&&this.selectedRows.length>0){
        this.selectedRows.forEach(o=>{
          this.doDowloadFenkai(o);
        })
      }else{
        this.$message.info('请选中批次记录下载');
        return;
      }
    },
    handleMenuClick (e) {
      if (e.key === 'delete') {
        this.remove()
      }
    },
    doSureModal(){
      this.visible = true;
    },
    doClosePersonList(){
      this.personList.visible = false;
    },
    doDowloadZh(recode){
      // console.log(recode.enterCode);
      // exportFile.getFileURLs(recode.meansPath,recode.enterCode+".zip")
      // // if(recode.isAuto==0){
      // //   exportFile.getImgURLs(recode.meansPath, recode.enterCode+".zip");
      // // }else{
      // //   exportFile.getImgURLs(recode.meansPath, recode.teamName+this.queryParams.enterDateStr+".zip");
      // // }
      window.open(recode.meansPath);
    },
    doDowloadFenkai(recode){
      // if(recode.isAuto==0){
      //   exportFile.getImgURLs(recode.meansPathFen, recode.enterCode+".zip");
      // }else{
      //   exportFile.getImgURLs(recode.meansPathFen, recode.teamName+this.queryParams.enterDateStr+".zip");
      // }
      window.open(recode.meansPathFen);
    }
  }
}
</script>

<style lang="less" scoped>
  
  .fold{
    width: calc(100% - 216px);
    display: inline-block
  }
  .operator {
    margin-right: 8px;

    .ant-btn {
      color: #307dfa;
      border-color: #307dfa;
    }
  }
  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
</style>
