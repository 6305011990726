<template>
    <div class="wrap-cantainer ant-card ant-card-bordered ant-card-body">
        <a-row :gutter="16">
            <a-col :span="12">
                <div class="relative">
                    <baidu-map
                        class="bmView"
                        :scroll-wheel-zoom="true"
                        :center="location"
                        :zoom="zoom">
                        <bm-view style="width: 100%; height:500px; flex: 1"></bm-view>
                        <bm-local-search :keyword="addressKeyword" :auto-viewport="true" style="display: none"></bm-local-search>
                        <div v-for="(items,index) in persons" :key="index">
                            <bm-marker :position="{lng: items.lng, lat: items.lat}" :icon="{url: items.icon, size: {width: 28, height: 28}}" :dragging="false" @click="infoWindowOpen(index)">
                              <bm-info-window :show="items.show" @close="infoWindowClose(index)" @open="infoWindowOpen(index)">{{items.name}}</bm-info-window>
                            </bm-marker>
                        </div>
                    </baidu-map>
                    <div class="unit-box">
                        <label>单位：</label>
                        <a-select :default-value="defaultUnit" v-model="defaultUnit"  style="width: 160px" @change="onHandleUnitChange">
                            <a-select-option   v-for="(items,id) in unitList" :key="id" :value="items.id">
                              {{items.name}}
                            </a-select-option>
                        </a-select>
                    </div>
                    <div class="gc-box flex">
                        <div class="text-center flex-1 gc-box-item ">
                            <div class="flex a-center j-center border-right"><img class="gc-box-img" :src="local1"/>在建工程{{gcObj.zaijian || 0}}个</div>
                        </div>
                        <div class="text-center flex-1 gc-box-item">
                            <div class="flex a-center j-center border-right">
                                <img class="gc-box-img" :src="local2"/>
                                暂停工程{{gcObj.tinggong || 0}}个
                                </div>
                        </div>
                        <div class="text-center flex-1 gc-box-item">
                            <div class="flex a-center j-center">
                                <img class="gc-box-img" :src="local3"/>
                                完工工程{{gcObj.wangong || 0}}个
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-2">
                    <a-table :columns="projectColumns" :data-source="projectData" bordered>
                        <a slot="projectName" slot-scope="text,record">{{ record.projectName }}</a>
                        <div slot="xmjl" slot-scope="text,record">{{ record.xmjl }}{{record.phone}}</div>
                    </a-table>
                </div>
            </a-col>
            <a-col :span="12">
                <div class="data-wrap">
                    <a-row :gutter="16">
                        <a-col :span="14">
                            <div id="safeChart" style="width: 100%;height:200px;"></div>
                        </a-col>
                        <a-col :span="10">
                            <div style="padding-left: 40px">
                                <div class="mb-1">隐患总数：{{currentGk.yhs}}</div>
                                <div class="mb-1">整改率：{{currentGk.zgl||0}}</div>
                                <div>超时未整改：{{currentGk.cswzg || 0}}</div>
                            </div>
                        </a-col>
                    </a-row>
                    <div id="dangerTypeChart" style="width: 100%;height:300px;"></div>
                    <div class="flex a-center" style="line-height: 32px">
                        <div style="font-size:16px;font-weight:600;margin-right:20px;color: #333;font-style: normal;">劳务数据汇总</div>
                        <div class="flex-1">
                            <label>选择项目：</label>
                            <a-select :default-value="defaultProject" style="width: 220px" @change="onHandleChange" v-model="defaultProject">
                                <a-select-option   v-for="(items,projId) in projectList" :key="projId" :value="items.projId">
                                    {{items.shortName || items.fullName}}
                                </a-select-option>
                            </a-select>
                        </div>
                    </div>
                    <div class="mt-2">
                        <a-row :gutter="15">
                            <a-col :span="12">
                                <div class="laowu-data-box orange">
                                    <h5 class="p-1">工人</h5>
                                    <div class="px-1">
                                        <div class="flex mb-1">
                                            <label class="flex-1">今日</label>
                                            <div class="flex-1 text-right">{{workData.today}}</div>
                                        </div>
                                        <div class="flex mb-1">
                                            <label class="flex-1">昨日</label>
                                            <div class="flex-1 text-right">{{workData.yesterday}}</div>
                                        </div>
                                        <div class="flex">
                                            <label class="flex-1">已离场</label>
                                            <div class="flex-1 text-right">{{workData.leave}}</div>
                                        </div>
                                    </div>
                                </div>
                            </a-col>
                            <a-col :span="12">
                                <div class="laowu-data-box blue">
                                    <h5 class="p-1">管理人员</h5>
                                    <div class="px-1">
                                        <div class="flex mb-1">
                                            <label class="flex-1">建设单位</label>
                                            <div class="flex-1 text-right">{{ManagCWA['2']}}</div>
                                        </div>
                                        <div class="flex mb-1">
                                            <label class="flex-1">施工单位</label>
                                            <div class="flex-1 text-right">{{ManagCWA['1']}}</div>
                                        </div>
                                        <div class="flex">
                                            <label class="flex-1">监理单位</label>
                                            <div class="flex-1 text-right">{{ManagCWA['3']}}</div>
                                      </div>
                                    </div>
                                </div>
                            </a-col>
                        </a-row>
                    </div>
                    <div class="mt-2">
                        <a-row :gutter="15">
                            <a-col :span="12">
                                <div id="ageTypeChart" style="width: 100%;height:300px;"></div>
                            </a-col>
                            <a-col :span="12">
                                <div id="workTypeChart" style="width: 100%;height:300px;"></div>
                            </a-col>
                        </a-row>
                    </div>
                    <div class="mt-2">
                        <div id="sevenKqChart" style="width: 100%;height:300px;"></div>
                    </div>

                </div>
            </a-col>
        </a-row>
  </div>



</template>

<script>
import {sevenKqChart,safeChart,dangerTypeChart,ageTypeChart,workTypeChart} from './echarts'
import {
  get7DaysCWA, getJobTitleFb,
  getManagCWA,
  getOfficeListByParentOffice2,
  getProjectsByOffice,
  getQueryCurrentGk,
  getTypeCount, getWorkAgeFb, getWorkTotal, projectStatissKqS
} from "../../services/statistics";
import {mapGetters} from 'vuex'
export default {
    name: "homePage",
    data() {
        return {
            location: {
                lng: 113.274,
                lat: 23.123
            },
            zoom: 9.5,
            addressKeyword: "",
            unitList:[], //单位列表
            currentGk: {}, // 当月安全管理统计汇总
            workData: {}, // 劳务数据汇总
            projectList: [],
            defaultProject: '全部',
            defaultUnit: '全部',
            persons:[
            ], // 地图上的覆盖物
            gcObj: {}, // 工程类型守护
            ManagCWA: {}, // 管理人员数据
            sevenKq: [],
            local1: require("./icon/local-1.png"),
            local2: require("./icon/local-2.png"),
            local3: require("./icon/local-3.png"),
            projectData: [], // table表格数据
            projectColumns: [
                {
                    title: '项目',
                    dataIndex: 'projectName',
                    key: 'projectName',
                    width: '35%',
                    scopedSlots: { customRender: 'projectName' },
                },
                {
                  title: '项目经理',
                  dataIndex: 'xmjl',
                  width: '25%',
                  align: 'center',
                  key: 'xmjl',
                  scopedSlots: { customRender: 'xmjl' },
                },
                {
                  title: '在场人数',
                  dataIndex: 'zcrsCnt',
                  key: 'zcrsCnt',
                  align: 'center',
                  scopedSlots: { customRender: 'zcrsCnt' },
                },
                {
                  title: '今日出勤数',
                  dataIndex: 'kqrsCnt',
                  key: 'kqrsCnt',
                  align: 'center',
                  scopedSlots: { customRender: 'kqrsCnt' },
                }
            ]
        }
    },
    computed: {
        ...mapGetters('account', ['user'])
    },
    created() {
        // 获取账号下的单位
        const id = this.user.officeId
        getOfficeListByParentOffice2({parentOfficeId:id}).then((res)=>{
            if (res.data.code === 0) {
                this.unitList = res.data.data
                // 数据过滤成当前账号登录的信息
                let items = this.unitList.filter((item)=>{
                  return  item.id === id
                })
                this.defaultUnit = items[0].name
                this.getProjectsByOffice(items[0].id)
                this.getProjectStatissKqS(items[0].id)
            } else {
                this.error = res.data.msg
                this.$message.error(res.data.msg, 3)
            }
        })
        // 当月安全管理统计汇总
        getQueryCurrentGk({queryType:2}).then((res)=>{
            if (res.data.code === 0) {
                this.currentGk = res.data.data
                safeChart(this.currentGk)
            } else {
                this.error = res.data.msg
                this.$message.error(res.data.msg, 3)
            }
        })
        getTypeCount({}).then((res)=>{
            if (res.data.code === 0) {
                let arr = res.data.data
                let nameArr = []
                let cntArr = []
                arr.forEach((item,index)=>{
                    nameArr.push(item.name)
                    cntArr.push(Number(item.cnt))
                })
                dangerTypeChart(nameArr,cntArr)
            } else {
                this.error = res.data.msg
                this.$message.error(res.data.msg, 3)
            }
        })
    },
    mounted() {
    },
    methods: {
        /**
         * 获取所有项目，以及在建，停工，完工的数量
         * */
        getProjectsByOffice(id){
          // 获取所有项目，以及在建，停工，完工的数量
            getProjectsByOffice({parentOfficeId:id}).then((res)=>{
                if (res.data.code === 0) {
                    let obj = res.data.data
                    let arr = []
                    this.projectList = obj.projects
                    const id = obj.projects[0].projId
                    this.gcObj = {tinggong: obj.tinggong, wangong: obj.wangong, zaijian: obj.zaijian}
                    obj.projects.forEach((item) => {
                        // 组成百度地图适配的数据格式
                        console.log(item.status)
                        let icon = item.status === '0' ? this.local1 : (item.status === '1' ? this.local2 : this.local3)
                        // if(item.status === '0'){
                            arr.push({
                                name: item.fullName,
                                id: item.projId,
                                lat: item.latitude,
                                lng: item.longitude,
                                icon: icon,
                                show: false
                            })
                        // }

                    })
                    this.persons = arr
                }  else {
                    this.error = res.data.msg
                    this.$message.error(res.data.msg, 3)
                }
            })
        },
        infoWindowClose (index) {
          this.persons[index].show = false
        },
        infoWindowOpen (index) {
          this.persons[index].show = true
        },
        // 监听选择单位
        onHandleUnitChange(e) {
            // e 单位id
            this.getProjectsByOffice(e)
            this.getProjectStatissKqS(e)
        },
        getProjectStatissKqS(id) {
            projectStatissKqS({parentOfficeId:id}).then((res)=>{
                if (res.data.code === 0) {
                    this.defaultProject = res.data.data[0].projectName
                    this.getManagCWA(res.data.data[0].projectId) // 默认第一个项目id
                    this.getWorkTotal(res.data.data[0].projectId) // 默认第一个项目id
                    this.getSevenKq(res.data.data[0].projectId)  //  7天考勤
                    this.getAgeType(res.data.data[0].projectId) //   年龄结构
                    this.getJobType(res.data.data[0].projectId) //   工种结构
                    this.projectData = res.data.data
                } else {
                    this.error = res.data.msg
                    this.$message.error(res.data.msg, 3)
                }

            })
        },
      // 获取 项目考勤人数统计table 数据
        /**
         * 获取劳务工人考勤数
         * */
        getWorkTotal(id) {
            getWorkTotal({projectId: id}).then((res)=>{
                if (res.data.code === 0) {
                    this.workData = res.data.data
                } else {
                    this.error = res.data.msg
                    this.$message.error(res.data.msg, 3)

                }

            })
        },
        /**
         * 劳务数据管理员统计
         * */
        getManagCWA(id) {
            getManagCWA({projectId:id}).then((res)=>{
                if (res.data.code === 0) {
                    this.ManagCWA = res.data.data
                } else {
                    this.error = res.data.msg
                    this.$message.error(res.data.msg, 3)

                }
            })
        },
        /**
         * 工人七天考勤
         * */
        getSevenKq(id) {
            get7DaysCWA({projectId:id}).then((res)=>{
                if (res.data.code === 0) {
                    const week = [{dates:this.fun_date(-7),count:'0'},{dates:this.fun_date(-6),count:'0'},{dates:this.fun_date(-5),count:'0'},{dates:this.fun_date(-4),count:'0'},{dates:this.fun_date(-3),count:'0'},{dates:this.fun_date(-2),count:'0'},{dates:this.fun_date(-1),count:'0'},]
                    this.sevenKq = res.data.data.length>0?res.data.data: week
                    let nameArr = []
                    let cntArr = []
                    this.sevenKq.forEach((item, index) => {
                        nameArr.push(item.dates)
                        cntArr.push(Number(item.count))
                    })
                    sevenKqChart(nameArr, cntArr)
                } else {
                    this.error = res.data.msg
                    this.$message.error(res.data.msg, 3)
                }
            })
        },
        fun_date(day){
            let date1 = new Date()
            let date2 = new Date(date1);
            date2.setDate(date1.getDate()+day);
            let time2 = date2.getFullYear()+"-"+(date2.getMonth()+1)+"-"+date2.getDate()
            return time2
        },
        /**
         * 工人年龄分布
         * */
        getAgeType(id) {
            getWorkAgeFb(id).then((res)=>{
                if (res.data.code === 0) {
                    let ageTypeFb = res.data.data[0] || {d4155:0}
                    let arr = []
                    for (let item in ageTypeFb) {
                      let obj = {}
                        if (item == 'd30') {
                          obj.name = '低于40岁'
                          obj.value = ageTypeFb[item]
                        } else if (item == 'd55') {
                          obj.name = '55岁以上'
                          obj.value = ageTypeFb[item]
                        } else if (item == 'd3140') {
                          obj.name = '31岁到40岁'
                          obj.value = ageTypeFb[item]
                        } else {
                          obj.name = '41岁到50岁'
                          obj.value = ageTypeFb[item]
                        }
                        arr.push(obj)
                    }
                    console.log(arr)
                    ageTypeChart(arr)
                } else {
                    this.error = res.data.msg
                    this.$message.error(res.data.msg, 3)
                }
            })
        },
        /**
         * 工人工种分布
         * */
        getJobType (id) {
            getJobTitleFb(id).then((res)=>{
                if (res.data.code === 0) {
                    let arr = []
                    let workTypeFb = []
                    workTypeFb = res.data.data.length>0?res.data.data : [{carft:'木工',num: 0}]
                    workTypeFb.forEach((item)=>{
                      arr.push({name:item.carft,value:item.num})
                    })
                    workTypeChart(arr)
                } else {
                    this.error = res.data.msg
                    this.$message.error(res.data.msg, 3)
                }
            })
        },
        /**
         * 监听统计换位中当前选择项目
         * */
        onHandleChange(e) {
            this.getWorkTotal(e)
            this.getManagCWA(e)
            this.getSevenKq(e)
            this.getAgeType(e)
            this.getJobType(e)
        },
    }
}
</script>

<style>
  .anchorBL{
    display: none;
  }
</style>
<style scoped>
    .px-1{
      padding: 0 10px;
    }
    .p-1{
      padding: 10px;
    }
    .mt-2{
        margin-top: 20px;
    }
    .mb-1{
        margin-bottom: 10px;
    }
    .data-wrap{
    }
    .text-center{
        text-align: center;
    }
    .text-right{
        text-align: right;
    }
    .flex{
        display: flex;
    }
    .flex-1{
        flex: 1;
    }
    .a-center{
        align-items: center;
    }
    .j-center{
        justify-content: center;
    }
    .orange{
        background: rgb(242,168,45);
    }
    .blue{
        background: rgb(39,130,203);
    }
    .laowu-data-box{
        height: 150px;
        font-size: 14px;
        box-sizing: border-box;
        border-radius: 5px;
        color: #FFFFFF;
    }
    .laowu-data-box h5{
        color: #ffffff;
        border-bottom: 1px solid #fff;
    }
    .relative{
        position: relative;
    }
    .unit-box{
        position: absolute;
        top: 10px;
        left: 10px;
    }
    .gc-box{
        position: absolute;
        top: 10px;
        right: 10px;
        background: #ffffff;
        padding: 0 5px;
    }
    .gc-box-item{
        min-width: 120px;
        color: deepskyblue;
        padding: 5px 0px;
    }
    .gc-box-img{
        width: 18px;
        height: 18px;
    }
    .border-right{
        border-right: 1px solid #E4E4E4;
    }
</style>
