import {GET_PROJECT_DEVICE_LIST, INSERT_PROJECT_DEVICE, DEL_PROJECT_DEVICE, UPDATE_PROJECT_DEVICE} from '@/services/api'
import {request, METHOD, removeAuthorization} from '@/utils/request'

/**
 * 获取项目的设备列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getProjectDeviceList(params) {
    return request(GET_PROJECT_DEVICE_LIST, METHOD.POST, params)
}


/**
 * 新增设备
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function insertProjectDevice(params) {
    return request(INSERT_PROJECT_DEVICE, METHOD.POST, params)
}


/**
 * 更新设备
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function updateProjectDevice(params) {
    return request(UPDATE_PROJECT_DEVICE, METHOD.POST, params)
}


/**
 * 删除设备
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function delProjectDevice(params) {
    return request(DEL_PROJECT_DEVICE + "/" + params.id, METHOD.DELETE)
}

export default {
    getProjectDeviceList,
    insertProjectDevice,
    delProjectDevice,
    updateProjectDevice
}