var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-card",
        [
          _c("div", { staticClass: "flex a-center j-between" }, [
            _c("div", { staticClass: "flex a-center" }, [
              _c("img", {
                staticStyle: { width: "21px", height: "21px" },
                attrs: {
                  src: "https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20241120/1732091350443.png?Expires=2047451350&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=59AEqlAw%2B%2BGyQ%2F%2B4QYdFWxJZ7rs%3D",
                  alt: "",
                },
              }),
              _c("span", { staticClass: "font-bold ml-1" }, [
                _vm._v("危大工程情况"),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "flex" },
              [
                _c(
                  "a-select",
                  {
                    staticStyle: { width: "200px" },
                    attrs: { "default-value": _vm.defaultUnit },
                    on: { change: _vm.onHandleUnitChange },
                    model: {
                      value: _vm.defaultUnit,
                      callback: function ($$v) {
                        _vm.defaultUnit = $$v
                      },
                      expression: "defaultUnit",
                    },
                  },
                  _vm._l(_vm.officeList, function (items, id) {
                    return _c(
                      "a-select-option",
                      { key: id, attrs: { value: items.id } },
                      [
                        _c("span", { attrs: { title: items.name } }, [
                          _vm._v(_vm._s(items.name)),
                        ]),
                      ]
                    )
                  }),
                  1
                ),
                _c("a-month-picker", {
                  staticClass: "ml-1",
                  staticStyle: { width: "120px" },
                  attrs: {
                    placeholder: "请选择月份",
                    "default-value": _vm.moment(_vm.nowMonth, _vm.monthFormat),
                    format: _vm.monthFormat,
                  },
                  on: { change: _vm.mtChangeDate },
                  model: {
                    value: _vm.statTime,
                    callback: function ($$v) {
                      _vm.statTime = $$v
                    },
                    expression: "statTime",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("a-divider"),
          _c(
            "div",
            { staticClass: "mt-1" },
            [
              _c(
                "a-row",
                { attrs: { gutter: [24, 16] } },
                [
                  _c("a-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex flex-column j-center a-center content-box content-box-bg1",
                      },
                      [
                        _c("span", { staticClass: "font-20 font-bold" }, [
                          _vm._v(_vm._s(_vm.wdData.wdgcTotal || 0)),
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "black-three font-12",
                            staticStyle: { "margin-top": "5px" },
                          },
                          [_vm._v("危大工程项目数")]
                        ),
                      ]
                    ),
                  ]),
                  _c("a-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex flex-column j-center a-center content-box content-box-bg1",
                      },
                      [
                        _c("span", { staticClass: "font-20 font-bold" }, [
                          _vm._v(_vm._s(_vm.wdData.wdgcCount || 0)),
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "black-three font-12",
                            staticStyle: { "margin-top": "5px" },
                          },
                          [_vm._v("危大工程分部分项数")]
                        ),
                      ]
                    ),
                  ]),
                  _c("a-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex flex-column j-center a-center content-box content-box-bg1",
                      },
                      [
                        _c("span", { staticClass: "font-20 font-bold" }, [
                          _vm._v(_vm._s(_vm.wdData.overWdgcCount || 0)),
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "black-three font-12",
                            staticStyle: { "margin-top": "5px" },
                          },
                          [_vm._v("超危大工程分部分项数")]
                        ),
                      ]
                    ),
                  ]),
                  _c("a-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex flex-column j-center a-center content-box content-box-bg2",
                      },
                      [
                        _c("span", { staticClass: "font-20 font-bold" }, [
                          _vm._v(_vm._s(_vm.wdData.riskTotal || 0)),
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "black-three font-12",
                            staticStyle: { "margin-top": "5px" },
                          },
                          [_vm._v("风险总数")]
                        ),
                      ]
                    ),
                  ]),
                  _c("a-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex flex-column j-center a-center content-box content-box-bg2",
                      },
                      [
                        _c("span", { staticClass: "font-20 font-bold" }, [
                          _vm._v(_vm._s(_vm.wdData.riskRankType1Count || 0)),
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "black-three font-12",
                            staticStyle: { "margin-top": "5px" },
                          },
                          [_vm._v("一级风险个数")]
                        ),
                      ]
                    ),
                  ]),
                  _c("a-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex flex-column j-center a-center content-box content-box-bg2",
                      },
                      [
                        _c("span", { staticClass: "font-20 font-bold" }, [
                          _vm._v(_vm._s(_vm.wdData.riskRankType2Count || 0)),
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "black-three font-12",
                            staticStyle: { "margin-top": "5px" },
                          },
                          [_vm._v("二级风险个数")]
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }