<template>
  <div class="create_index">
    <div class="over_view">
      <div class="plug-Overview">
        {{ teams.teamName ? teams.teamName : "班组" }} 评价
      </div>
      <a-button @click="close">关闭</a-button>
    </div>

    <a-divider />
    <a-button @click="viewEditing">新增评价</a-button>
    <!-- 表格封装 -->
    <standard-table
      class=""
      :columns="columns"
      :dataSource="dataSource"
      rowKey="id"
      :pagination="pagination"
      :loading="tableLoading"
      @change="onPageChange"
      :isAlert="false"
      :bordered="true"
    >
      <template slot="index" slot-scope="{ index }">
        <span>
          {{
            (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1
          }}
        </span>
      </template>
      <template slot="projectName" slot-scope="{ record }">
        <!-- :style="record.pjsPath == null || record.pjsPath == ''? '' : 'color:red'" -->
        <div>
          {{ record.projectName }}
        </div>
      </template>
      <div slot="pjTime" slot-scope="{ text }">
        <div>{{ text | formatDate }}</div>
      </div>
      <div slot="action" slot-scope="{ record }">
        <div class="sTables">
          <div @click="viewEditing(record, true)">修改</div>
        </div>
      </div>
    </standard-table>
    <a-modal
      title="班组评分"
      :visible="showEvaluate"
      :confirm-loading="confirmLoading"
      @ok="handleEvaluate"
      @cancel="cancelEvaluate"
      :width="700"
    >
      <a-form-model
        :model="form"
        :label-col="{ span: 3 }"
        :wrapper-col="{ span: 12 }"
        ref="ruleForm"
        :rules="rules"
      >
        <a-row>
          <a-col :span="24">
            <a-form-model-item
              prop="pjLevel"
              label="综合评价"
              style="display: flex; align-items: center"
            >
              <a-rate v-model="form.pjLevel" />
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="评价内容" prop="plContent">
              <a-textarea
                style="height: 100px"
                placeholder="请输入评价内容"
                v-model="form.plContent"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item
              :wrapper-col="{ span: 24 }"
              style="margin-left: 80px"
            >
              <a-button style="margin-right: 10px" @click="_text('诚实守信')"
                >诚实守信</a-button
              >
              <a-button
                style="margin-right: 10px"
                @click="_text('团队协作度高')"
                >团队协作度高</a-button
              >
              <a-button @click="_text('做事效率高')">做事效率高</a-button>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="评价人">
              <a-input v-model="user.name" :disabled="true" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import { getofficelist } from "@/services/projectManagement";
import { request } from "@/utils/request";
import { PROJECT_OFFICE_CHILD } from "@/services/api";
import {
  getOfficePointList,
  getTeamDetail,
  addOfficePoint,
  editOfficePoint,
} from "@/services/participationUnit";
import { mapGetters } from "vuex";
import StandardTable from "@/components/table/StandardTable";
import { formatDate } from "@/filters/formatDate.js";
import { useRender } from '@/hooks/useRender.js'

const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
    fixed: "left",
  },
  {
    title: "单位名称",
    dataIndex: "officeName",
    align: "center",
    scopedSlots: { customRender: "officeName" },
  },
  {
    title: "评价时间",
    dataIndex: "pjTime",
    align: "center",
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "评价人",
    dataIndex: "pjrName",
    // ellipsis: true,
    align: "center",
  },
  {
    title: "评价内容",
    dataIndex: "plContent",
    align: "center",
    ellipsis: true,
    width: 600,
  },
  {
    title: "操作",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "action" },
  },
];
export default {
  data() {
    return {
      query: {},
      columns: columns, //表格数据
      tableLoading: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        // showLessItems: true,
        // showQuickJumper: true,
      },
      dataSource: [],
      showEvaluate: false,
      confirmLoading: false,
      form: {
        pjLevel: 0,
        plContent: "",
      },
      prams: {
        pageNumber: 1,
        pageSize: 10,
      },
      rules: {
        pjLevel: [{ required: true, message: "请选择评分", trigger: "change" }],
        plContent: [
          { required: true, message: "请填写评分内容", trigger: "change" },
        ],
      },
      teams: {},
      isEdit: false,
    };
  },
  filters: {
    formatDate(time) {
      let date = new Date(time);
      return formatDate(date, "yyyy-MM-dd");
    },
  },
  inject: ["fatherMethod"],
  components: {
    StandardTable,
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  methods: {
    // 获取班组详情
    initData() {
      getTeamDetail(this.$route.query.id).then((res) => {
        this.teams = res.data.data;
        this.aqxjlist(this.prams)
      });
    },
    onPageChange(e) {
      this.prams.pageNumber = e.current;
      this.pagination.current = e.current;
      this.aqxjlist(this.prams)
    },
    // 获取班组评分列表
    aqxjlist(prams) {
      this.tableLoading = true;
      let params = {
        ...prams,
        teamId: this.teams.id
      };
      getOfficePointList(params).then((res) => {
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
        }
        this.tableLoading = false;
      });
    },
    // 新增评价弹窗
    viewEditing(recode, edit) {
      this.showEvaluate = true;
      if (edit) {
        this.form = {
          pjLevel: Number(recode.pjLevel),
          plContent: recode.plContent,
          id: recode.id,
        };
        this.isEdit = true;
      }
    },
    // 添加评价
    handleEvaluate(e) {
      this.$refs.ruleForm.validate((valid) => {
        this.confirmLoading = true;
        if (valid) {
          if (this.isEdit) {
            let params = {
              ...this.form,
              pjrId: this.user.id,
              pjrName: this.user.name,
            };
            editOfficePoint(params).then((res) => {
              if (res.data.code == 0) {
                this.$message.success('修改成功');
                this.aqxjlist(this.prams);
              }
            });
          } else {
            let params = {
              ...this.form,
              pjrId: this.user.id,
              pjrName: this.user.name,
              teamId: this.teams.id,
              teamName: this.teams.teamName,
              pjrOfficeId: this.user.officeId,
              projectId: this.teams.projectId,
              officeId: this.teams.officeId,
              officeName: this.teams.officeName ? this.teams.officeName : "",
            };
            addOfficePoint(params).then((res) => {
              if (res.data.code == 0) {
                this.$message.success('新增成功');
                this.aqxjlist(this.prams);
              }
            });
          }
        } else {
          return false;
        }
      });
      this.confirmLoading = false;
      this.cancelEvaluate();
      this.aqxjlist(this.prams);
    },
    // 关闭评价motal
    cancelEvaluate(e) {
      this.showEvaluate = false;
      this.isEdit = false;
      this.$refs.ruleForm.resetFields();
    },
    _text(text) {
      this.$set(this.form, "plContent", `${this.form.plContent}${text}，`);
    },
    close() {
      let path = this.$route.path;
      this.fatherMethod(`${path}`);
    },
    // 删除评价
  },
  mounted() {
    this.query = this.$route.query.data;
    this.initData();
  },
};
</script>
<style lang="less" scoped>
.create_index {
  padding: 24px;
  background: #fbfbfb;
  // border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-bottom: 10px;
  .plug-Overview {
    font-size: 16px;
    color: #101010;
    font-weight: bold;
  }
  .over_view {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.sTables {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;

  div {
    // width: 100%;
    padding: 0 10px;
    text-align: center;
    cursor: pointer;
  }
  div:nth-child(1) {
    color: #307dfa;
  }
  div:nth-child(2) {
    color: #ff5c77;
  }
  div:nth-child(3) {
    color: #615e83;
  }
}
.content_div {
  padding: 5px 0;
  cursor: pointer;
}
.content_div:hover {
  color: #307dfa;
}
.e-descriptions /deep/ .ant-descriptions-item-label {
  width: 120px;
  padding: 6px 7px;
}
.e-descriptions /deep/ .ant-descriptions-item-content {
  padding: 6px 7px;
}
</style>
