<template>
  <div class="a_Approval">
    <div class="plug-Overview">安全验收台账</div>
    <a-divider />
    <a-spin :spinning="spinning" :tip="loadingText">
      <a-form class="clearfix">
        <a-col :span="5">
          <a-form-item label="统计方式" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
            <a-radio-group v-model="queryParams.type">
              <a-radio-button :value="1">
                月份
              </a-radio-button>
              <a-radio-button :value="2">
                年份
              </a-radio-button>
            </a-radio-group>
          </a-form-item>
        </a-col>
        
        <!-- <a-form-item
          label="统计日期"
          :labelCol="{span: 5}"
          :wrapperCol="{span: 18, offset: 1}"
        >
            <a-range-picker  v-model="queryParams.plan_date" :default-value="[moment(firstDay, dateFormat), moment(nowDay, dateFormat)]"
                :format="dateFormat"  @change="changeDate"/>
        </a-form-item> -->

        <a-col :span="5">
          <a-form-item label="月份/年份" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
            <a-month-picker placeholder="请选择月份" :default-value="moment(monthDay, monthFormat)" :format="monthFormat" v-if="queryParams.type==1" v-model="queryParams.plan_date">
              <a-icon slot="suffixIcon" type="smile" />
            </a-month-picker>

            <a-date-picker
              v-if="queryParams.type==2"
              mode="year"
              v-model="queryParams.plan_date"
              placeholder="请输入年份"
              :default-value="moment(nowYear, yearFormat)" :format="yearFormat"
              :open="yearShowOne"
              @openChange="openChangeOne"
              @panelChange="panelChangeOne"/>
          </a-form-item>
        </a-col>

        <a-col :span="4">
          <a-space class="btnBox">
            <a-button type="primary" @click="doQuery()">查询</a-button>
            <a-button @click="doReset()">重置</a-button>
          </a-space>
        </a-col>
      </a-form>
      <standard-table
        :bordered="true"
        :columns="columns"
        :dataSource="dataSource"
        :isAlert="false"
        :pagination="pagination"
        :loading="tableLoading"
        :scroll="{x: '100%'}"
        @clear="onClear"
        @change="onPageChange"
        :btnArr="tableBtnArr"
      >
      </standard-table>
    <Export-loading :cPercent="cPercent" :isExporting="isExporting" :loadingText="loadingText"></Export-loading>
    </a-spin>
  </div>
</template>

<script>
import StandardTable from '@/components/table/StandardTable'
import {
  ACCEP_STAND_BOOK_QUERY,
  ACCEP_STAND_BOOK_EXPORT,
  PROJECT_AQYS_GETLIST,
  PROJECT_AQYS_EXPORT
  } from '@/services/api'

import { exportProgress } from '@/pages/aqjyzl/export' // 导出方法
import ExportLoading from '@/components/ExportLoading/ExportLoading' // progress组件
import {request} from '@/utils/request'
import moment from 'moment';
const columns = [
  {
    title: '项目名称',
    dataIndex: 'projectName',
    width: 240,
    ellipsis: true,
    tooltip: true
  },
  {
    title: '所属单位',
    dataIndex: 'proOfficeName',
    align: 'center',
    width:140,
    scopedSlots: { customRender: 'proOfficeName' }
  },
  {
    title: '塔吊基础验收（验收时间/结果）',
    dataIndex: 'tdjc',
    align: 'center',
    width: 170,
  },
  {
    title: '塔吊验收（验收时间/结果）',
    dataIndex: 'td',
    align: 'center',
    width: 170,
  },
  {
    title: '施工升降机验收（验收时间/结果）',
    dataIndex: 'sjj',
    align: 'center',
    width: 170,
  },
  {
    title: '物料提升机验收（验收时间/结果）',
    dataIndex: 'wltsj',
    align: 'center',
    width: 170,
  },
  {
    title: '外脚手架验收（验收时间/结果）',
    dataIndex: 'wjsj',
    align: 'center',
    width: 170,
  },
  {
    title: '高支模验收（验收时间/结果）',
    dataIndex: 'gzm',
    width: 170,
    align: 'center',
  },
  {
    title: '临电验收（验收时间/结果）',
    dataIndex: 'ldys',
    align: 'center',
    width: 170,
  },
  {
    title: '消防设备验收（验收时间/结果）',
    dataIndex: 'xfsb',
    align: 'center',
    width: 170,
  },
  {
    title: '活动板房验收（验收时间/结果）',
    dataIndex: 'hdbf',
    align: 'center',
    width: 170,
  }
]

export default {
  mixins: [exportProgress],
  name: 'QueryList',
  components: {StandardTable,ExportLoading},
  data () {
    return {
      dataSource:[],
      advanced: false,
      columns: columns,
      selectedRows: [],
      tableLoading: false,
      spinning:false,
      loadingText:"数据加载中...",
      loadKeys:[],
      visible:false,
      dateFormat: 'YYYY-MM-DD',
      monthFormat: 'YYYY-MM',
      yearFormat: 'YYYY',
      overDownIndex:0,
      firstDay:null,
      monthDay:null,
      nowYear:null,
      queryParams:{
        type:1,
        date:null,
        plan_date:null,
        accpetStartTime:moment(new Date()).format('YYYY-MM')+"-01",
        accpetEndTime:moment(new Date()).format('YYYY-MM-DD'),
        pageNumber:1,
      },
      pagination:{
        current:1,
        pageSize:10,
        total:0,
        showLessItems:true,
        showQuickJumper:true
      },
      cPercent:0,
      isExporting:false,
      yearShowOne:false,
      tableBtnArr: [
        {
            name: '导出',
            attrs: {
                type: 'primary'
            },
            click: this.doDowload
        }
      ]
    }
  },
  watch: {
    cPercent:{
      handler(newName, oldName) {
        this.cPercent=newName;
      },
      immediate: true,
    },
    loadingText:{
      handler(newName, oldName) {
        this.loadingText=newName;
      },
      immediate: true,
    }
  },
  created() {
    // this.firstDay = moment(new Date()).format('YYYY-MM')+"-01";
    // this.nowDay = moment(new Date()).format('YYYY-MM-DD');
    this.monthDay = moment(new Date()).format('YYYY-MM');
    this.nowYear = moment(new Date()).format('YYYY');
    this.doQuery();
  },
  authorize: {
    deleteRecord: 'delete'
  },
  methods: {
    moment,
    deleteRecord(key) {
      this.dataSource = this.dataSource.filter(item => item.key !== key)
      this.selectedRows = this.selectedRows.filter(item => item.key !== key)
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    remove () {
      this.dataSource = this.dataSource.filter(item => this.selectedRows.findIndex(row => row.key === item.key) === -1)
      this.selectedRows = []
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },
    changeDate(e){
      if(e==null||e.length<=0){
        this.queryParams.accpetStartTime = moment(new Date()).format('YYYY-MM')+"-01";
        this.queryParams.accpetEndTime = moment(new Date()).format('YYYY-MM-DD');
      }
    },

    /**
     * 获取时间段的台账信息
     */
    doQuery(){
      this.tableLoading = true;
      // if(this.queryParams.plan_date&&this.queryParams.plan_date.length>0){
      //   this.queryParams.accpetStartTime = this.queryParams.plan_date[0].format('YYYY-MM-DD')
      //   this.queryParams.accpetEndTime = this.queryParams.plan_date[1].format('YYYY-MM-DD')
      // }
      if(this.queryParams.type==1){
        if(this.queryParams.plan_date==null){
          this.queryParams.date = this.monthDay
        }else{
          this.queryParams.date = this.queryParams.plan_date.format('YYYY-MM')
        }
      }
      if(this.queryParams.type==2){
        if(this.queryParams.plan_date==null){
          this.queryParams.date = this.nowYear
        }else{
          this.queryParams.date = this.queryParams.plan_date.format('YYYY')
        }
      }
      request(PROJECT_AQYS_GETLIST,"post",this.queryParams).then(res => {
        if(res.data.code==0){
          // 处理默认值
          this.dataSource = res.data.data.list || [];
          this.pagination.total = res.data.data.total || 0;
          this.dataSource.forEach(obj=>{
            obj.key = obj.id;
          })
        } else {
          this.$message.error(res.data.msg)
        }
        this.tableLoading = false;
      })
    },
    doReset(){
      this.queryParams={};
      this.queryParams.accpetStartTime=moment(new Date()).format('YYYY-MM')+"-01";
      this.queryParams.accpetEndTime=moment(new Date()).format('YYYY-MM-DD');
      this.doQuery();
    },
    //分页查询
    onPageChange(e){
      this.queryParams.pageNumber = e.current;
      this.pagination.current=e.current;
      this.doQuery();
    },
    /**
     * 导出excel
     */
    doDowload(){
      this.loadingText = "正在导出EXCEL文件，请稍候 ····";
      let name = ""
      if(this.queryParams.plan_date&&this.queryParams.plan_date.length>0){
        this.queryParams.accpetStartTime = this.queryParams.plan_date[0].format('YYYY-MM-DD')
        this.queryParams.accpetEndTime = this.queryParams.plan_date[1].format('YYYY-MM-DD')
        name = name+this.queryParams.accpetStartTime+"至"+this.queryParams.accpetEndTime
      }else{
        name = name+moment(new Date()).format('YYYY-MM')+"-01"+"至"+moment(new Date()).format('YYYY-MM-DD')
      }
      this.isExporting=true;
      this.cPercent=0;
      let fileName=name+"-验收记录汇总.xlsx";
      this.exportFunNomal2(PROJECT_AQYS_EXPORT,this.queryParams,fileName,'application/vnd.ms-excel')
    },
    handleMenuClick (e) {
      if (e.key === 'delete') {
        this.remove()
      }
    },
    doSureModal(){
      this.visible = true;
    },
    onChange(dataStr){
      this.queryParams.plan_date=null;
    },
    openChangeOne(status) {
      if (status) {
        this.yearShowOne = true;
      }
    },
    // 得到年份选择器的值
    panelChangeOne(value) {
      this.yearShowOne = false;
      this.queryParams.plan_date = value;
    },
  }
}
</script>

<style lang="less" scoped>
  .search{
    margin-bottom: 54px;
  }
  .fold{
    width: calc(100% - 216px);
    display: inline-block
  }
  .operator{
    margin-bottom: 18px;
  }
  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
</style>
