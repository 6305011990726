<!-- eslint-disable vue/no-template-key -->
<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <a-card title="班前教育记录详情">
    <!-- 手工登记-现场照片 -->
    <div v-if="recordType === TYPE_ENUM.MANUAL" class="text-center">
      <div class="title">现场照片</div>
      <form-index
        ref="formSitePhoto"
        :formData="formDataPhoto"
        @resourceUpload="resourceUpload"
        @upRemove="upRemove"
      ></form-index>
    </div>

    <!-- AI识别-音视频内容 -->
    <div v-if="recordType === TYPE_ENUM.AI">
      <div class="text-center">
        <div class="title">视频内容</div>
        <template v-if="aiVideo.length > 0">
          <video
            v-for="item in aiVideo"
            :key="item.id"
            style="width: 600px; height: 300px"
            controls
            :src="item.file"
          ></video>
        </template>
        <template v-else>
          <a-empty description="未能从第三方AI摄像头获取到内容" />
        </template>
      </div>

      <div class="text-center">
        <div class="title">音频内容</div>
        <template v-if="aiAudio.length > 0">
          <audio
            v-for="item in aiAudio"
            :key="item.id"
            style="width: 600px"
            controls
            :src="item.file"
          ></audio>
        </template>
        <template v-else>
          <a-empty description="未能从第三方AI摄像头获取到内容" />
        </template>
      </div>
    </div>

    <a-divider style="margin: 20px 0px" />

    <!-- 表单区域 -->
    <div class="title">教育记录信息</div>
    <form-index
      ref="formRef"
      :formData="formData"
      @handlSelect="handleSelect"
      @onDateChange="onDateChange"
    >
      <!-- 自定义组件插槽 -->
      <template #customAction>
        <div class="flex a-center">
          <div style="line-height: 32px">
            <div>
              <a-icon type="user" class="mr-1" :style="{ fontSize: '18px' }" />
              <a-tag
                v-for="item in workEducationPersons"
                :key="item.idCard"
                v-if="item.workerId"
                >{{ item.workerName || item.name }}</a-tag
              >
            </div>
            <div>
              <a-tooltip placement="bottom">
                <template slot="title">
                  <span
                    ><a-icon
                      type="user"
                      :style="{ fontSize: '18px', color: '#F69707' }"
                      class="mr-1"
                    />此类人员未在本系统录入实名制信息</span
                  >
                </template>
                <a-icon
                  type="user"
                  :style="{ fontSize: '18px', color: '#F69707' }"
                  class="mr-1"
                />
              </a-tooltip>
              <a-tag
                v-for="item in workEducationPersons"
                :key="item.idCard"
                v-if="
                  (item.workerId === null || item.workerId === undefined) &&
                  (item.workNum === null || item.workNum === undefined)
                "
                >{{ item.workerName || item.name }}</a-tag
              >
            </div>
          </div>
          <div class="flex-1 ml-1">
            <a-button
              type="primary"
              @click="onPersonsModal"
              :disabled="isReadonly"
            >
              请选择
            </a-button>
          </div>
        </div>
      </template>
    </form-index>

    <div style="display: flex; justify-content: flex-end">
      <a-space>
        <a-button @click="handleClose">关闭</a-button>
        <a-button type="primary" @click="handleSubmit" :disabled="isReadonly"
          >保存</a-button
        >
      </a-space>
    </div>
    <a-modal
      title="选择参与人员"
      v-model="personsModal"
      :width="600"
      @ok="onConfirm"
      @cancel="onReset"
    >
      <!-- 在场班组人员 -->
      <div>
        <div class="relative mb-1">
          <a-checkbox
            class="position-all"
            :indeterminate="indeterminate"
            :checked="checkAll"
            @change="onCheckAllChange"
          >
            全选
          </a-checkbox>
          <div class="text-center">
            <div class="font-bold black">
              {{ teamName.name || "未匹配班组" }}
            </div>
            <div class="black-three">
              (教育日期 {{ educateDateValue }} 班组在场人员)
            </div>
          </div>
        </div>
        <a-checkbox-group
          class="ml-2"
          v-model="checkedList1"
          :options="presenceList"
          @change="onChange"
        >
        </a-checkbox-group>
      </div>
      <!-- 非此班组识别人员 -->
      <div class="mt-3">
        <div class="text-center mb-1" v-if="noPresenceList.length > 0">
          <div class="font-bold black">非此班组识别人员</div>
          <div class="black-three">(橙色名称人员在系统内无实名信息)</div>
        </div>
        <a-checkbox-group
          class="ml-2"
          v-model="checkedList2"
          @change="onChange"
        >
          <a-checkbox
            v-for="item in noPresenceList"
            :key="item.value"
            :value="item.value"
          >
            <span :style="computedStyle(!item.workerId)">{{
              item.label || item.name || item.workerName
            }}</span>
          </a-checkbox>
        </a-checkbox-group>
      </div>
    </a-modal>
  </a-card>
</template>

<script>
import { omit, pick, uniqBy } from "lodash";
import moment from "moment";
import formIndex from "@/pages/components/form/index";
import { TYPE_ENUM } from "./enum";
import { DetailPageModeEnum } from "@/enums/pageEnum.js";
import { request } from "@/utils/request";
import { FIND_TEAMS_BY_USER, SYSUSER_PROID_OFFICE } from "@/services/api";
import {
  createEducation,
  getEducation,
  getfindStaffMoment,
  updateEducationInfo,
} from "@/services/aqjyzl.js";
import { getReportAiMedia } from "@/services/wordConfig";
import { mapGetters } from "vuex";
import { storage } from "@/utils/storage";
const formDataPhoto = [
  {
    label: "",
    title: "上传现场图片",
    placeholder: "请上传现场图片",
    decorator: [
      "sitePhoto",
      { rules: [{ required: true, message: "请上传现场图片" }] },
    ],
    type: "tsUpload",
    key: "sitePhoto",
    labelCol: 24,
    wrapperCol: 24,
    defaultImg:
      "https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20240830/1724982419975.png?Expires=2040342420&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=tw9lmUzhw5%2BeVAdasun3bGZEQK8%3D",
    col: 24,
    defaultFileList: [],
    display: true,
    defaultStyleImg: {
      width: "600px",
      height: "300px",
      objectFit: "contain",
    },
  },
];
export default {
  name: "preClassDetail",
  components: {
    formIndex,
  },
  inject: ["fatherMethod"],
  computed: {
    ...mapGetters("account", ["user"]),
  },
  data() {
    return {
      formDataPhoto: formDataPhoto,
      recordId: Number(this.$route.query.id),
      recordType: Number(this.$route.query.type),
      isReadonly: this.$route.query.mode === DetailPageModeEnum.VIEW,
      TYPE_ENUM,
      formData: [],
      personsModal: false,
      checkedList: [],
      indeterminate: false,
      checkAll: false,
      workEducationPersons: [],
      oldWorkEducationPersons: [],
      educateDateValue: null,
      educationPersons: [],
      presenceList: [],
      noPresenceList: [],
      checkedList1: [],
      checkedList2: [],
      aiVideo: [],
      aiAudio: [],
      teamName: {
        name: undefined,
      },
      handleEchoData: {},
    };
  },
  methods: {
    computedStyle(sign) {
      return sign ? { color: "#f69707" } : {};
    },
    createFormData() {
      return [
        {
          label: "教育日期",
          placeholder: "请选择日期",
          decorator: [
            "educateDate",
            {
              rules: [
                {
                  required: true,
                  message: "请选择教育日期",
                },
              ],
            },
          ],
          format: "yyyy-MM-DD HH:mm:ss",
          type: "datePicker",
          key: "educateDate",
          col: 12,
          labelCol: 8,
          wrapperCol: 12,
          display: true,
          styles: { width: "100%" },
          disabled: this.isReadonly,
          showTime: true,
        },
        {
          label: "班组",
          placeholder: "请选择班组",
          decorator: [
            "teamId",
            {
              rules: [
                {
                  required: true,
                  message: "请选择班组",
                },
              ],
            },
          ],
          type: "select",
          key: "teamId",
          selectlist: [],
          col: 12,
          labelCol: 8,
          wrapperCol: 12,
          display: true,
          allowClear: true,
          styles: { width: "100%" },
          disabled: this.isReadonly,
        },
        {
          label: "教育日期班组在场人数",
          placeholder: "选择教育日期和班组后自动带出",
          decorator: ["teamPersonNum"],
          type: "input",
          key: "teamPersonNum",
          col: 12,
          labelCol: 8,
          wrapperCol: 12,
          display: true,
          disabled: true,
        },
        {
          label: "教育地点",
          placeholder: "请填写",
          decorator: ["educatePlace"],
          type: "input",
          key: "educatePlace",
          col: 12,
          labelCol: 8,
          wrapperCol: 12,
          display: true,
          disabled: this.isReadonly,
        },
        {
          label: "参与人员",
          type: "customAction",
          col: 24,
          key: "workEducationPersons",
          labelCol: 4,
          wrapperCol: 12,
          display: true,
        },
        {
          label: "参与人数",
          placeholder: "选择参与人员自动带出",
          decorator: ["participatePersonNum"],
          type: "input",
          key: "participatePersonNum",
          col: 12,
          labelCol: 8,
          wrapperCol: 12,
          display: true,
          disabled: true,
        },
        {
          label: "当班作业部位",
          placeholder: "请输入",
          decorator: ["workPlace"],
          type: "input",
          key: "workPlace",
          col: 12,
          labelCol: 8,
          wrapperCol: 12,
          display: true,
          disabled: this.isReadonly,
        },
        {
          label: "当班作业内容",
          placeholder: "请输入",
          decorator: ["workContent"],
          type: "input",
          key: "workContent",
          col: 12,
          labelCol: 8,
          wrapperCol: 12,
          display: true,
          disabled: this.isReadonly,
        },
        {
          label: "记录人",
          placeholder: "请选择管理人员",
          decorator: ["recordUserId"],
          type: "select",
          selectlist: [],
          key: "recordUserId",
          col: 12,
          labelCol: 8,
          wrapperCol: 12,
          display: true,
          disabled: this.isReadonly,
        },
        {
          label: "复核安全员",
          placeholder: "请选择安全员",
          decorator: ["safeUserId"],
          type: "select",
          key: "safeUserId",
          selectlist: [],
          col: 12,
          labelCol: 8,
          wrapperCol: 12,
          display: true,
          disabled: this.isReadonly,
        },
        {
          label: "资料表编号",
          placeholder: "请填写",
          decorator: [
            "documentNum",
            {
              rules: [
                {
                  required: false,
                  message: "请填写",
                },
                {
                  pattern: /^[A-Za-z0-9]*$/,
                  message: "只能输入英文字母和数字",
                },
              ],
            },
          ],
          type: "input",
          key: "documentNum",
          maxLength: 5,
          col: 12,
          labelCol: 8,
          wrapperCol: 12,
          display: true,
          disabled: this.isReadonly,
        },
        {
          label: "防护设施及环境",
          placeholder: "请填写",
          type: "checkboxList",
          checkboxList: [
            {
              key: "workEnvironment",
              label: "作业环境",
              checkboxItemList: [{ name: "作业环境", value: 1 }],
              decorator: ["workEnvironment"],
              placeholder: "请填写",
              disabled: this.isReadonly,
            },
            {
              key: "protectEnviroment",
              label: "防护环境",
              checkboxItemList: [{ name: "防护环境", value: 1 }],
              decorator: ["protectEnviroment"],
              placeholder: "请填写",
              disabled: this.isReadonly,
            },
            {
              key: "workSurface",
              label: "作业面",
              checkboxItemList: [{ name: "作业面", value: 1 }],
              decorator: ["workSurface"],
              placeholder: "请填写",
              disabled: this.isReadonly,
            },
          ],
          col: 12,
          labelCol: 8,
          wrapperCol: 16,
          display: true,
          disabled: this.isReadonly,
          noClass: true,
        },
        {
          label: "个人防护数量",
          type: "inputList",
          inputList: [
            {
              key: "safeHat",
              label: "安全帽",
              inputType: "number",
              decorator: ["safeHat"],
              placeholder: "请填写",
              disabled: this.isReadonly,
            },
            {
              key: "safeBelt",
              label: "安全带",
              inputType: "number",
              decorator: ["safeBelt"],
              placeholder: "请填写",
              disabled: this.isReadonly,
            },
            {
              key: "otherProtect",
              label: "其他防护用品",
              inputType: "number",
              decorator: ["otherProtect"],
              placeholder: "请填写",
              disabled: this.isReadonly,
            },
          ],
          col: 24,
          labelCol: 4,
          wrapperCol: 16,
          display: true,
        },
        {
          label: "上一班作业中发现的隐患",
          placeholder: "请填写",
          decorator: ["preProblems"],
          type: "textarea",
          key: "preProblems",
          col: 24,
          labelCol: 4,
          wrapperCol: 20,
          display: true,
          style: { width: "100%" },
          disabled: this.isReadonly,
        },
        {
          label: "整改要求",
          placeholder: "请填写",
          decorator: ["checkAnswer"],
          type: "textarea",
          key: "checkAnswer",
          col: 24,
          labelCol: 4,
          wrapperCol: 20,
          display: true,
          disabled: this.isReadonly,
        },
        {
          label: "当班安全活动内容",
          placeholder: "请填写",
          decorator: ["educateContent"],
          type: "textarea",
          key: "educateContent",
          col: 24,
          labelCol: 4,
          wrapperCol: 20,
          display: true,
          disabled: this.isReadonly,
        },
      ];
    },
    handleSubmit() {
      const that = this;
      this.arr = [];
      let arr = ["formRef", "formSitePhoto"];
      if (this.recordType === TYPE_ENUM.AI) {
        arr = arr.filter((item) => item !== "formSitePhoto");
      }
      arr.forEach((item) => {
        this.arr.push(this.$refs[item].combinationSubmit());
      });
      Promise.all(this.arr).then((e) => {
        if (that.workEducationPersons.length === 0)
          return that.$message.error("请选择参与人员");
        this.$confirm({
          title: "是否确认提交？",
          async onOk() {
            let params = {
              projectId: that.user.projectId,
              id: that.recordId,
              educationPersons: that.workEducationPersons,
              teamName: that.teamName.name || that.handleEchoData.teamName,
              ...omit(Object.assign({}, ...e)),
              educateDate: moment(e.educateDate).valueOf(),
            };
            params = Object.assign({}, that.handleEchoData, params);
            // 如果有值为''空字符串，就置为空
            const keys = ["safeHat", "safeBelt", "otherProtect"];
            keys.forEach((key) => {
              if (!(key in params)) {
                // 如果 params 对象中没有这个 key，添加它并设置值为 0
                params[key] = 0;
              } else if (params[key] === "" || params[key] === null) {
                // 如果 params 对象中有这个 key，但值为空字符串或 null，设置值为 0
                params[key] = 0;
              }
            });
            const checks = [
              "workEnvironment",
              "protectEnviroment",
              "workSurface",
            ];
            checks.forEach((key) => {
              // 判断 checkValues 数组对应项是否符合条件
              if (
                Array.isArray(params[key]) &&
                params[key].length > 0 &&
                params[key][0] === 1
              ) {
                params[key] = 1;
              } else {
                params[key] = 0;
              }
            });
            try {
              // 新增或者修改
              const res =
                that.$route.query.mode !== DetailPageModeEnum.ADD
                  ? await updateEducationInfo(params)
                  : await createEducation(params);
              if (res.data.code === 0) {
                // 完成
                storage.set("presenceList-submit", true);
                that.fatherMethod(that.$route.path);
                that.$message.success("操作成功");
              }
            } catch (e) {
              console.error(e);
            }
          },
        });
      });
    },
    handleClose() {
      this.fatherMethod(this.$route.path);
    },
    async doQueryTeams() {
      const res = await request(FIND_TEAMS_BY_USER, "post", {});
      if (res.data.code === 0) {
        const teams = res.data.data.map((item) => ({
          name: item.teamName,
          value: item.id,
        }));
        teams.push({ name: "其他班组", value: "0" }); // 添加新对象-AI班组需要
        this.teamsList = teams;
        this.$set(this.formData[1], "selectlist", teams);
      }
    },
    async doQueryRecordPersons(roleCode = null) {
      // roleCode区分 记录人和复核安全员
      const params = { projectId: this.user.projectId };
      if (roleCode) params.roleCode = roleCode;

      const res = await request(SYSUSER_PROID_OFFICE, "post", params);
      if (res.data.code === 0) {
        const persons = res.data.data.map((item) => ({
          name: item.userName,
          value: item.id,
        }));
        const formIndex = roleCode ? 9 : 8;
        this.$set(this.formData[formIndex], "selectlist", persons);
      }
    },
    getPersonNum() {
      const educateDateValue =
        this.$refs["formRef"].getFieldValueByKey("educateDate");
      const teamIdValue = this.$refs["formRef"].getFieldValueByKey("teamId");

      if (educateDateValue && teamIdValue) {
        // TODO: 调用接口获取在场人数
        getfindStaffMoment({
          momentDate: educateDateValue,
          teamId: teamIdValue,
        }).then((res) => {
          if (res.data.code === 0) {
            const data = res.data.data;
            this.$refs["formRef"].setFieldsValue(
              "teamPersonNum",
              { teamPersonNum: data.length },
              "teamPersonNum"
            );

            // 合并在场人员和已参与人员
            this.setEduCationPersons(data);
          }
        });
      }
    },
    setEduCationPersons(data) {
      // uniqBy合并data，并根据 idCard 去重
      const mergedArray = uniqBy(
        [...data, ...this.workEducationPersons],
        "idCard"
      ).map((item) => ({
        ...item,
        workerId: item.workerId || item.workNum,
        workNum: item.workNum,
        label: item.name || item.workerName,
        workerName: item.name || item.workerName,
        value: item.idCard,
      }));
      // 1.合并在场班组人员和已参与人员-全选操作使用
      this.mergedPersons = mergedArray;
      // 2.在场班组
      this.presenceList = mergedArray.filter((i) => i.workNum);
      // 3.非在场班组人员
      this.noPresenceList = mergedArray.filter((i) => !i.workNum);
      // 遍历已选择的参与人员数组 this.workEducationPersons
      this.workEducationPersons.forEach((item) => {
        // 回显此班组参与人员 checkedList1
        if (
          this.presenceList.findIndex((p) => p.idCard === item.idCard) !== -1
        ) {
          this.checkedList1.push(item.idCard);
        } else {
          this.checkedList2.push(item.idCard);
          this.checkedList2 = uniqBy(this.checkedList2);
        }
      });
    },
    handleSelect({ val, keys }) {
      if (keys === "teamId" && val) {
        // 注释：切换班组需求，清空所有班组
        this.clearLists();
        this.resetForm();
        // this.workEducationPersons = this.oldWorkEducationPersons
        this.$nextTick(this.getPersonNum);
      }
    },
    clearLists() {
      this.presenceList = [];
      this.noPresenceList = [];
      this.checkedList1 = [];
      this.checkedList2 = [];
      this.workEducationPersons = [];
    },
    resetForm() {
      if (this.$refs["formRef"]) {
        this.$refs["formRef"].setFieldsValue(
          "participatePersonNum",
          { participatePersonNum: 0 },
          "participatePersonNum"
        );
      }
    },
    onDateChange(date) {
      date && this.$nextTick(this.getPersonNum);
    },
    // 回显
    async handleEcho() {
      // 当前不为新增状态，获取记录详情
      if (this.$route.query.mode !== DetailPageModeEnum.ADD) {
        const res = await getEducation({ id: this.recordId });
        if (res.data.code === 0) {
          this.handleEchoData = res.data.data;
          let formData = pick(res.data.data, [
            ...this.formData.map((i) => i.key),
            "safeHat",
            "safeBelt",
            "otherProtect",
            "workEnvironment",
            "protectEnviroment",
            "workSurface",
          ]);
          // 回显要组成数组
          const checks = [
            "workEnvironment",
            "protectEnviroment",
            "workSurface",
          ];
          checks.forEach((key) => {
            // 判断 checkValues 数组对应项是否符合条件
            if (formData[key] === 1) {
              formData[key] = [1];
            } else {
              formData[key] = [0];
            }
          });
          let formSite = pick(res.data.data, [
            ...this.formDataPhoto.map((i) => i.key),
          ]);
          // 过滤掉属性值为空的键值对
          formData = this.filterEmptyValues(formData);
          formSite = this.filterEmptyValues(formSite);
          if (
            formSite.sitePhoto &&
            formSite.sitePhoto != "" &&
            !this.isOSSUrl(formSite.sitePhoto)
          ) {
            formSite.sitePhoto = `data:image/png;base64,${formSite.sitePhoto}`;
          } else {
            this.$refs["formSitePhoto"].setFieldsValue("", formSite);
            // 处理回显图片path
            let path = ["sitePhoto"];
            this.formDataPhoto.forEach((item) => {
              path.forEach((key, index) => {
                if (item.key == key && formSite[key]) {
                  item.defaultFileList = [
                    {
                      url: formSite[key],
                      uid: index,
                      name: "image.png",
                      key: item.key,
                    },
                  ];
                }
              });
            });
          }
          if (
            formData.workEducationPersons &&
            formData.workEducationPersons.length > 0
          ) {
            this.setEduCationPersons(formData.workEducationPersons);
            this.workEducationPersons = formData.workEducationPersons;
            // 切换班组-需重置记录的参与人员
            // this.oldWorkEducationPersons = formData.workEducationPersons;
          }
          if (!formData.educateDate) {
            delete formData.educateDate;
          }
          (formData.educateDate = moment(formData.educateDate).format(
            "YYYY-MM-DD HH:mm:ss"
          )),
            this.$refs["formRef"].setFieldsValue("", formData);
          // AI识别音频视频
          if (this.recordType === TYPE_ENUM.AI) {
            this.getReportAiMedia({ eventId: res.data.data.aiId }).then(
              (res) => {
                const data = res.data.result;
                if (data.length > 0) {
                  this.aiVideo = data.filter((obj) => obj.type == 1); // 视频
                  this.aiAudio = data.filter((obj) => obj.type == 2); // 音频
                }
              }
            );
          }
        }
      }
    },
    isOSSUrl(url) {
      // 正则表达式检查是否是 OSS URL
      const ossUrlPattern = /^https:\/\/[a-z0-9.-]+\.aliyuncs\.com\/.+/i;
      return ossUrlPattern.test(url);
    },
    filterEmptyValues(data) {
      return Object.fromEntries(
        Object.entries(data).filter(
          ([_, value]) => value !== null && value !== undefined
        )
      );
    },
    //上传文件回调
    resourceUpload(obj) {
      const formDataArr = [this.formDataPhoto];
      formDataArr.forEach((i) => {
        i.forEach((item) => {
          if (item.key === obj.key) {
            item.defaultFileList = [obj];
          }
        });
      });
    },
    //删除上传附件
    upRemove(file) {
      const formDataArr = [this.formDataPhoto];
      formDataArr.forEach((i) => {
        i.forEach((item) => {
          if (item.key === file.key) {
            item.defaultFileList = [];
          }
        });
      });
    },
    async getReportAiMedia(eventId) {
      const res = await getReportAiMedia(eventId);
      return res;
    },
    // 参与人员弹窗
    onPersonsModal() {
      const educateDateValue =
        this.$refs["formRef"].getFieldValueByKey("educateDate");
      const teamIdValue = this.$refs["formRef"].getFieldValueByKey("teamId");
      this.teamName = this.teamsList.find(
        (person) => teamIdValue === person.value
      );
      if (educateDateValue && teamIdValue) {
        this.$nextTick(
          this.getPersonNum,
          (this.educateDateValue = educateDateValue),
          (this.personsModal = true)
        );
      } else {
        return this.$message.error("请先选择教育日期和班组");
      }
    },
    onChange() {
      const curTotal = this.checkedList1.length + this.checkedList2.length;
      this.checkAll = curTotal === this.mergedPersons.length;
      this.indeterminate = curTotal !== 0 && !this.checkAll;
    },
    onCheckAllChange(e) {
      const allValues1 = this.presenceList.map((option) => option.value);
      const allValues2 = this.noPresenceList.map((option) => option.value);
      Object.assign(this, {
        checkedList1: e.target.checked ? allValues1 : [],
        checkedList2: e.target.checked ? allValues2 : [],
        indeterminate: false,
        checkAll: e.target.checked,
      });
    },
    onConfirm() {
      const allCheckedList = uniqBy([
        ...this.checkedList1,
        ...this.checkedList2,
      ]);
      this.workEducationPersons = allCheckedList.map((item) => {
        return this.mergedPersons.find((person) => item === person.idCard);
      });
      this.$refs["formRef"].setFieldsValue(
        "participatePersonNum",
        { participatePersonNum: this.workEducationPersons.length },
        "participatePersonNum"
      );
      this.onReset();
    },
    onReset() {
      this.checkedList1 = [];
      this.checkedList2 = [];
      this.personsModal = false;
    },
  },
  mounted() {},
  activated() {
    this.recordId = Number(this.$route.query.id);
    this.recordType = Number(this.$route.query.type);
    this.isReadonly = this.$route.query.mode === DetailPageModeEnum.VIEW;
    this.formData = this.createFormData();
    this.handleEcho();
    this.doQueryTeams();
    this.doQueryRecordPersons();
    this.doQueryRecordPersons("PROJECT_AQY_ZB_FZR");
    const formDataArr = [this.formDataPhoto];
    formDataArr.forEach((i) => {
      i.forEach((item) => {
        if (item.type === "tsUpload" || item.type === "tsCropperUpload") {
          item.defaultFileList = [];
        }
      });
    });
  },
};
</script>

<style lang="less" scoped>
/deep/ .ant-card-body {
  padding: 24px 100px;
}

.title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: left;
}
.title:not(:first-child) {
  margin-top: 50px;
}

/deep/ .ant-modal-header .ant-modal-title {
  font-weight: bold;
}
/deep/ .ant-checkbox-group {
  line-height: 2.5;
}

.position-all {
  position: absolute;
  left: 0;
  top: 10px;
}
</style>
