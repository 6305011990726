var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-card",
        {
          staticClass: "a_Approval",
          attrs: { title: "查看远程视频检查", bordered: false },
        },
        [
          _c("form-index", {
            ref: "formindex",
            attrs: { formData: _vm.formData },
          }),
          _vm.user.loginType != 0 && _vm.recordData.status == 0
            ? _c(
                "a-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.addCheckContent },
                },
                [_vm._v(" 添加检查内容 ")]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "check-content-box" },
            [
              _c(
                "a-row",
                _vm._l(_vm.checkContentList, function (item, index) {
                  return _c("a-col", { key: index, attrs: { span: 8 } }, [
                    _c("div", { staticClass: "mb-2" }, [
                      _c("div", { staticClass: "video-item-box" }, [
                        _c(
                          "div",
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "18px" } },
                              [_vm._v(_vm._s(item.bigTypeName))]
                            ),
                            _vm.user.loginType != 0 &&
                            _vm.recordData.status == 0
                              ? _c("a-icon", {
                                  staticClass: "ml-1 pointer",
                                  staticStyle: { "font-size": "22px" },
                                  attrs: { type: "edit" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editCheckContent(item)
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm.user.loginType != 0 &&
                            _vm.recordData.status == 0
                              ? _c("a-icon", {
                                  staticClass: "pointer mr-2",
                                  staticStyle: {
                                    float: "right",
                                    "font-size": "22px",
                                    color: "red",
                                  },
                                  attrs: { type: "delete" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.delCheckContent(item)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "video-box" },
                          [
                            _vm.user.loginType != 0
                              ? _c(
                                  "div",
                                  { staticClass: "flex" },
                                  _vm._l(
                                    item.videoFilePathFull,
                                    function (video) {
                                      return _c(
                                        "div",
                                        {
                                          key: video.videoId,
                                          staticClass:
                                            "flex flex-column a-center j-center pointer m-1",
                                          staticStyle: {
                                            width: "100px",
                                            height: "100px",
                                            border: "1px solid #F3F3F3",
                                          },
                                          style: {
                                            background: video.isAdd
                                              ? "#e8f2ff"
                                              : "#fff2f5",
                                            color: video.isAdd
                                              ? "#1a37e9"
                                              : "#e91a1a",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onClickVideo(
                                                item,
                                                video
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("a-icon", {
                                            staticStyle: {
                                              "font-size": "30px",
                                            },
                                            attrs: { type: "play-square" },
                                          }),
                                          _c("span", { staticClass: "mt-1" }, [
                                            _vm._v(
                                              _vm._s(
                                                video.isAdd ? "已" : "未"
                                              ) + "添加隐患"
                                            ),
                                          ]),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _c(
                                  "a-upload",
                                  {
                                    attrs: {
                                      "file-list": item.defaultFileList,
                                      remove: _vm.upRemove,
                                      data: item,
                                      accept: _vm.accept,
                                      customRequest: _vm.resourceUpload,
                                      "list-type": "picture-card",
                                      beforeUpload: _vm.beforeUpload,
                                    },
                                    on: { preview: _vm.handlePreview },
                                  },
                                  [
                                    item.defaultFileList.length < 3
                                      ? _c(
                                          "div",
                                          [
                                            _c("a-icon", {
                                              attrs: { type: "plus" },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ])
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-2", staticStyle: { float: "right" } },
            [
              _vm.user.loginType == 1 && _vm.recordData.status == 0
                ? _c(
                    "a-button",
                    {
                      staticClass: "mr-2",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submit(0)
                        },
                      },
                    },
                    [_vm._v(" 提交 ")]
                  )
                : _vm._e(),
              _vm.user.loginType == 1 && _vm.recordData.status == 2
                ? _c(
                    "a-button",
                    {
                      staticClass: "mr-2",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submit(1)
                        },
                      },
                    },
                    [_vm._v(" 创建安全整改单 ")]
                  )
                : _vm._e(),
              _vm.user.loginType == 1 && _vm.recordData.status == 2
                ? _c(
                    "a-button",
                    {
                      staticClass: "mr-2",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submit(3)
                        },
                      },
                    },
                    [_vm._v(" 创建领导带班检查单 ")]
                  )
                : _vm._e(),
              _c(
                "a-button",
                { staticClass: "mr-2", on: { click: _vm.close } },
                [_vm._v(" 返回 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "添加视频巡检内容",
            destroyOnClose: true,
            footer: null,
          },
          on: { cancel: _vm.cancelModal },
          model: {
            value: _vm.checkContentModal,
            callback: function ($$v) {
              _vm.checkContentModal = $$v
            },
            expression: "checkContentModal",
          },
        },
        [
          _c(
            "a-select",
            {
              staticStyle: { width: "100%" },
              on: { change: _vm.handleSelectChange },
            },
            _vm._l(_vm.checkContentSelectlist, function (item) {
              return _c("a-select-option", { key: item.id }, [
                _vm._v(" " + _vm._s(item.name) + " "),
              ])
            }),
            1
          ),
          _c(
            "div",
            { staticClass: "confirm-btn", on: { click: _vm.onAddConfirm } },
            [_c("span", [_vm._v("确定")])]
          ),
        ],
        1
      ),
      _c("video-content-modal", {
        attrs: {
          visible: _vm.videoContentVisible,
          clickVideo: _vm.clickVideo,
          clickContent: _vm.clickContent,
          recordData: _vm.recordData,
        },
        on: { cancel: _vm.cancelVideoModal, save: _vm.saveVideo },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }