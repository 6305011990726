<template>
  <!-- 考勤未闭合记录 -->
  <div class="container">
    <form-index
      ref="formindex"
      :formData="formData"
      @handleSubmit="handleSubmit"
      :flexSubmit="true"
    >
      <!-- 下部分按钮插槽 -->
      <template #flexSubmit>
        <a-space  style="margin-left: 55px; margin-top: 5px">
          <a-button icon="search" type="primary" html-type="submit"> 查询 </a-button>
          <a-space class="operator">
            <a-button @click="close"> 重置 </a-button>
          </a-space>
        </a-space>
      </template>
    </form-index>
    <div class="a_a_footer">
      <!-- 表格封装 -->
      <standard-table
        class=""
        :columns="columns"
        :dataSource="dataSource"
        rowKey="id"
        :pagination="pagination"
        :loading="tableLoading"
        @change="onPageChange"
        :isAlert="false"
        :bordered="true"
      >
        <template slot="index" slot-scope="{ index }">
          <span>
            {{
              (pagination.current - 1) * pagination.pageSize +
                parseInt(index) +
                1
            }}
          </span>
        </template>
        <template slot="status" slot-scope="{ text }">
          <a-tag color="green" v-if="text == 1">补报成功</a-tag>
          <a-tag color="red" v-else>未补报</a-tag>
        </template>
      </standard-table>
    </div>
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import { getJwReportRecord } from "@/services/participationUnit"
import { useRender } from '@/hooks/useRender.js'

const formData = [
  {
    label: "所属公司",
    placeholder: "请输入所属公司",
    decorator: [
      "enterpriseName",
      { rules: [{ required: false, message: "请选择所属公司" }] },
    ],
    type: "input",
    key: "enterpriseName",
    dataSource: [],
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
  },
  {
    label: "班组",
    placeholder: "请输入选择班组",
    decorator: ["teamName", { rules: [{ required: false, message: "请输入选择班组" }] }],
    type: "input",
    key: "teamName",
    dataSource: [],
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "打卡日期",
    placeholder: "请输入打卡日期",
    decorator: [
      "workDate",
      { rules: [{ required: false, message: "请输入打卡日期" }] },
    ],
    type: "datePicker",
    key: "workDate",
    dataSource: [],
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    format: "yyyy-MM-DD",
    styles: "width: 100%",
  },
];

export default {
  components: {
    formIndex,
    StandardTable,
  },
  data() {
    // 创建一个用于提取汉字部分的正则表达式
    const chineseRegex = /^[\u4e00-\u9fa5]+/;
    // 使用 Intl.Collator 创建一个根据当前语言环境排序的对象
    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
    const columns = [
      {
        title: "序号",
        width: 65,
        scopedSlots: { customRender: "index" },
        // customRender: (text, record, index) => `${index + 1}`,
        align: "center",
      },
      {
        title: "姓名",
        dataIndex: "realName",
        align: "center",
      },
      {
        title: "身份证号",
        dataIndex: "cardNum",
        align: "center",
      },
      {
        title: "所属单位",
        dataIndex: "enterpriseName",
        align: "center",
      },
      {
        title: "所属班组",
        align: "center",
        dataIndex: "teamName",
        // sorter: (a,b) => collator.compare(a.teamName.match(chineseRegex)[0], b.teamName.match(chineseRegex)[0]),
      },
      {
        title: "打卡日期",
        dataIndex: "workDate",
        align: "center",
        // sorter: (a, b) => moment(a.workDate) - moment(b.workDate),
        customRender: (text) => useRender.renderDate(text)
      },
      {
        title: "开始打卡时间",
        dataIndex: "workStartStr",
        align: "center",
        customRender: (text) => useRender.renderDate(text)
      },
      {
        title: "结束打卡时间",
        dataIndex: "workEndStr",
        align: "center",
        customRender: (text) => useRender.renderDate(text)
      },
      {
        title: "预计补报时间",
        dataIndex: "atteTime",
        align: "center",
        customRender: (text) => useRender.renderDate(text)
      },
      {
        title: "状态",
        dataIndex: "status",
        align: "center",
        scopedSlots: { customRender: "status" },
      }
    ];
    return {
      formData: formData,
      columns: columns,
      dataSource: [], //table数组
      pagination: {
        //分页数据
        showSizeChanger:true,
        pageSizeOptions: ['10', '50', '100', '150'],
        current:1,
        pageSize:50,
        total:0,
        showLessItems:true,
        showQuickJumper:true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      params: { //测试数据
        pageNo: 1,
        pageSize: 50,
      },
      tableLoading: false, //table加载中
    };
  },
  mounted() {
    this.getData(this.params)
  },
  activated() {
  },
  methods: {
    getData(params) {
      this.tableLoading = true;
      getJwReportRecord(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    handleSubmit(e) {
      let obj = { ...e, ...this.params }
      obj.pageNo = 1
      this.pagination.current = 1;
      this.getData(obj);
    },
    //表格 - 分页查询
    onPageChange(e,filters,sorter) {
      let obj = this.$refs["formindex"].getFieldValue();
      this.params.pageNo = e.current;
      this.pagination.current = e.current;
      this.params.pageSize = e.pageSize;
      this.pagination.pageSize = e.pageSize;
      this.getData(Object.assign({}, this.params, obj));
    },
    // 重置
    close() {
      this.params = {
        pageNo: 1,
        pageSize: 50,
      };
      this.pagination.current = 1;
      this.pagination.pageSize = 50;
      this.getData(this.params);
      this.$refs["formindex"].resetFields();
    },
  },
};
</script>

<style lang="less" scoped>
.a_a_footer {
  padding: -10px 0px;
  background: #ffffff;

  .operator {
    margin-right: 8px;

    .ant-btn {
      color: #307dfa;
    }
  }

  .sTables {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      width: 100%;
      text-align: center;
      cursor: pointer;
    }

    div:nth-child(1) {
      color: #307dfa;
    }

    div:nth-child(2) {
      color: #ff5c77;
    }

    div:nth-child(3) {
      color: #615e83;
    }
  }

}
.content_div {
  padding: 5px 0;
  cursor: pointer;
}
.content_div:hover {
  color: #307dfa;
}
.task-tips {
  color: #4377cc;
  text-decoration: underline;
  cursor: pointer;
}
.jwTag {
  border-radius: 2px;
  width: 65px;
  height: 30px;
  line-height: 28px;
  margin: auto;
  cursor: pointer;
  font-size: 14px;
}
</style>