<template>
  <div class="container">
    <h2>人员信息</h2>
    <div class="content">
      <div class="img-box">
        <img :src="personInfo.photoPath" alt="">
        <p>{{ personInfo.staffName }}</p>
        <p>{{ personInfo.gender }}</p>
        <p>{{ personInfo.nation }}</p>
        <p>{{ personInfo.age }}岁</p>
        <p style="color: red;" v-if="personInfo.moreYears">超龄</p>
      </div>
      <a-descriptions bordered :column="2">
        <a-descriptions-item label="身份证号码：">{{ personInfo.idCard }}</a-descriptions-item>
        <a-descriptions-item label="手机号码：">{{ personInfo.phone }}</a-descriptions-item>
        <a-descriptions-item label="工种：">{{ personInfo.workTypeName }}</a-descriptions-item>
        <a-descriptions-item label="银行卡：">{{ personInfo.bankCard }}</a-descriptions-item>
        <a-descriptions-item label="民族：">{{ personInfo.nation }}族</a-descriptions-item>
        <a-descriptions-item label="地区：">{{ personInfo.homeAddress }}</a-descriptions-item>
        <a-descriptions-item label="疾病史：">无</a-descriptions-item>
        <a-descriptions-item label="已在场的项目：">
          <template v-if="personInfo.projectNames">
            <div v-for="(item,index) in showNames(personInfo.projectNames)" :key="index">{{item}}</div>
          </template>
        </a-descriptions-item>
        <a-descriptions-item label="身份证人像：">
          <img class="attachment-img" v-if="personInfo.idCardFace" :src="personInfo.idCardFace" alt="">
          <span v-else>暂未上传</span>
        </a-descriptions-item>
        <a-descriptions-item label="身份证国徽：">
          <img class="attachment-img" v-if="personInfo.idCardBack" :src="personInfo.idCardBack" alt="">
          <span v-else>暂未上传</span>
        </a-descriptions-item>
        <a-descriptions-item label="银行卡图片：">
          <img class="attachment-img" v-if="personInfo.bank" :src="personInfo.bank" alt="">
          <span v-else>暂未上传</span>
        </a-descriptions-item>
      </a-descriptions>
    </div>
  </div>
</template>

<script>
import { getApprovePersonInfo } from "@/services/participationUnit";
export default {
  data() {
    return {
      projectPersonId: '',
      personInfo: {}
    };
  },
  activated() {
    this.projectPersonId = this.$route.query.projectPersonId
    this.getApprovePersonInfo()
  },
  methods: {
    getApprovePersonInfo() {
      getApprovePersonInfo({projectPersonId: this.projectPersonId}).then(res => {
        if (res.data.code === 0) {
          this.personInfo = res.data.data
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    showNames(projects){
      return projects.split(',')
    }
  },
};
</script>

<style lang="less" scoped>
.content {
  display: flex;
}
.img-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
  img {
    width: 150px;
    margin-bottom: 10px;
  }
}
.attachment-img {
  width: 300px;
}
::v-deep .ant-descriptions-item-label {
  font-weight: bold;
}
</style>
