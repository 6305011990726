<template>
  <div>
    <div>
      <a-row :gutter="24">
        <!-- <a-col :span="8">
          <a-card
            title="公司结构"
            size="small"
            :bordered="false"
            style="height: 900px"
            :style="'overflow:auto'"
          >
            <a-tree
              :expanded-keys="expandedKeys"
              :tree-data="officeTreeData"
              :auto-expand-parent="autoExpandParent"
              :replaceFields="{
                children: 'children',
                title: 'name',
                key: 'id',
              }"
              @expand="onExpand"
              @select="onSelect"
            >
            </a-tree>
          </a-card>
        </a-col> -->
        <a-col>
          <a-card  :bordered="false">
            <a-form  :form="queryParam">
              <a-row :gutter="24">
                <a-col :span="4">
                  <a-form-item label="账号" :labelCol="{span: 6}"
              :wrapperCol="{span: 17, offset: 1}">
                    <a-input
                      placeholder="请输入手机号"
                      v-model="queryParam.phone"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="4">
                  <a-form-item label="姓名" :labelCol="{span: 6}"
              :wrapperCol="{span: 17, offset: 1}">
                    <a-input
                      placeholder="请输入姓名"
                      v-model="queryParam.name"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="4">
                  <a-form-item label="角色" :labelCol="{span: 4}"
              :wrapperCol="{span: 17, offset: 1}">
                    <a-select
                      placeholder="请选择"
                      v-model="queryParam.roleId"
                      :allowClear="true"
                    >
                      <a-select-option
                        v-for="(item, index) in newRole"
                        :key="item.id"
                        :index="index"
                        :value="item.roleId"
                        >{{ item.roleName }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="5">
                  <a-form-item label="所属公司" :labelCol="{span: 7}"
              :wrapperCol="{span: 14, offset: 1}">
                    <a-tree-select
                      searchPlaceholder="搜索公司"
                      style="width: 100%"
                      v-model="queryParam.officeId"
                      :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                      :tree-data="officeTreeData"
                      placeholder="请选择所属公司"
                      :replaceFields="{
                        children: 'children',
                        title: 'name',
                        key: 'id',
                        value: 'id',
                      }"
                      @select="queryOfficeId"
                      :allowClear="true"
                      :show-search="true"
                      tree-node-filter-prop="title"
                    >
                    </a-tree-select>
                  </a-form-item>
                </a-col>
                <a-col
                  :span="7"
                  :style="{ marginTop: '3px' }"
                >
                  <a-space>
                      <a-button
                      type="primary"
                      icon="search"
                      :loading="loading"
                      @click="handleSearch"
                    >
                      查询
                     </a-button>
                      <!-- <a-button
                        icon="reload"
                        type="primary"
                        @click="reset()"
                      >
                        重置
                      </a-button> -->
                      <a-space class="operator">
                        <a-button @click="reset()"> 重置 </a-button>
                      </a-space>
                      <a-button
                        icon="plus"
                        type="primary"
                        @click="addOffice(queryParam.officeId)"
                      >
                        新增
                      </a-button>
                  </a-space>
                </a-col>
              </a-row>
            </a-form>
            <!-- <a-table
              :columns="columns"
              :data-source="officeData"
              @expand="expand"
              @change="onPageChange"
            >
              <template slot="action" slot-scope="text, record">
                <span>
                  <a @click="showModal(record, true)">修改</a>
                  <a-divider type="vertical" />
                  <a @click="del(record)">删除</a>
                </span>
              </template>
            </a-table> -->
            <!-- 表格封装 -->
            <div style="margin-top: -25px;">
              <standard-table
                :columns="columns"
                :dataSource="officeData"
                rowKey="id"
                :pagination="queryParam"
                :loading="tableLoading"
                @change="onPageChange"
                :bordered="true"
              >
                <template slot="index" slot-scope="{ index }">
                  <span>
                    {{
                      (queryParam.current - 1) * queryParam.pageSize +
                      parseInt(index) +
                      1
                    }}
                  </span>
                </template>
                <template slot="action" slot-scope="{ text, record }">
                  <span>
                    <a @click="showModal(record, true)">修改</a>
                    <a-divider type="vertical" />
                    <a style="color:red" @click="del(record)">删除</a>
                  </span>
                </template>
              </standard-table>
          </div>
          </a-card>
        </a-col>
      </a-row>
    </div>

    <div>
      <a-modal
        :title="title"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="modelOk"
        @cancel="modelCancel"
        width="760px"
      >
        <a-form-model
          :model="form"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          ref="ruleForm"
          :rules="rules"
          v-if="visible"
          :selfUpdate="true"
        >
          <a-form-model-item label="所属公司">
            <a-tree-select
              style="width: 100%"
              v-model="form.officeId"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              :tree-data="officeTreeData"
              placeholder="请选择直属上级单位"
              :replaceFields="{
                children: 'children',
                title: 'name',
                key: 'id',
                value: 'id',
              }"
              :treeDefaultExpandAll="false"
              @change="officeSelect"
              show-search
              tree-node-filter-prop="title"
            >
            </a-tree-select>
          </a-form-model-item>
          <a-form-model-item label="姓名" ref="name" prop="name" required>
            <a-input v-model="form.name" />
          </a-form-model-item>
          <a-form-model-item label="账号" ref="phone" prop="phone" required>
            <a-input v-model="form.phone" />
          </a-form-model-item>
          <!--                    <a-form-model-item label="登录名" ref="phone" prop="phone" required>-->
          <!--                        <a-input v-model="form.loginName" />-->
          <!--                    </a-form-model-item>-->
          <a-form-model-item label="密码" ref="password" prop="password" :required="!isPwdShow" >
            <a-input-password v-model.trim="form.password" placeholder="密码必须包含数字、大小写字母、特殊字符，且长度大于等于8个字符"/>
          </a-form-model-item>
          <a-form-model-item
            label="确认密码"
            ref="confirmPassWord"
            prop="confirmPassWord"
          >
            <a-input-password v-model.trim="form.confirmPassWord" />
          </a-form-model-item>

          <a-form-model-item label="是否允许登录">
            <a-select v-model="form.loginFlag" placeholder="是否允许登录">
              <a-select-option key="1" value="1"> 是 </a-select-option>
              <a-select-option key="0" value="0"> 否 </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="新劳务用户角色">
            <a-select
              show-search
              mode="multiple"
              placeholder="请选择角色"
              option-filter-prop="children"
              :default-value="form.labourRoleIdList"
              style="width: 200px"
              :filter-option="filterOption"
              @focus="handleFocus"
              @blur="handleBlur"
              @change="changeLabourRole"
            >
              <a-select-option
                v-for="(item, index) in newRole"
                :key="index"
                :value="item.roleId"
              >
                {{ item.roleName }}
              </a-select-option>
            </a-select>

            <!--                        <a-radio-group :options="newRole" :default-value="form.labourRoleId" @change="changeLabourRole"/>-->
          </a-form-model-item>
          <a-form-model-item label="备注">
            <a-input v-model="form.remarks" />
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </div>
</template>

<script>
import {
  getOfficeTypeList,
  getOfficeTreeSysUser,
  getRoleListBySysUserList,
  getRoleByOldUserId,
  saveLabourUser,
  delSysUser,
  getProjectListByOfficeId,
  searchLoginName,
  getOfficeSysUser,
} from "@/services/sys";
import { getOfficeTree,getTreeOffice } from "@/services/projectManagement";
import { mapGetters } from "vuex";
import StandardTable from "@/components/table/StandardTable";
const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    align: "center",
  },
  {
    title: "账号",
    dataIndex: "phone",
    align: "center",
    key: "phone",
  },
  {
    title: "姓名",
    dataIndex: "name",
    align: "center",
    key: "name",
  },
  {
    title: "归属公司",
    dataIndex: "officeName",
    align: "center",
    key: "deptName",
  },
  {
    title: "角色",
    dataIndex: "roleName",
    align: "center",
    key: "roleName",
  },
  {
    title: "操作",
    dataIndex: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  components: {
    StandardTable,
  },
  data() {
    let validatePassword = (rule, value, callback) => {
      if (
        (value === "" || value == null) &&
        this.form.id != null &&
        this.form.id != undefined
      ) {
        callback();
      } else {
        var patrn = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[~!@#$%^&*.)(_+}{|:?><]).{8,16}$/;
        if (!patrn.exec(value)){
            callback(new Error('密码必须包含数字、小写字母、大写字母、特殊字符，且长度大于等于8个字符'))
        }
        if (this.form.confirmPassWord !== "") {
          this.$refs.ruleForm.validateField("validateConfirmPassWord");
        }
        callback();
      }
    };
    let validateConfirmPassWord = (rule, value, callback) => {
      if (this.form.password === "" || this.form.password == null) {
        callback();
        return;
      }
      if (value === "") {
        callback(new Error("请输入确认密码"));
      } else if (value !== this.form.password) {
        callback(new Error("两次密码不一致"));
      } else {
        callback();
      }
    };
    return {
      searchKeyword: "", //搜索的key
      // 筛选
      queryParam: {
        phone: "",
        name: "",
        officeId: "",
        roleId: undefined,
        projectId: undefined,
        pageNumber: 1,
        pageSize: 10,
        total: 0,
        current: 1,
        showTotal: (total, range) => `共 ${total} 条`
      },
      loading: false,
      projectList: [],

      // 树形结构
      expandedKeys: [],
      autoExpandParent: true,
      officeTreeData: [],

      // 数据表格
      columns,
      officeData: [],
      officeId: undefined,
      expandedRowKeys: [],
      tableLoading: false,
      // 详细数据弹窗
      title: "编辑",
      visible: false,
      confirmLoading: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 12 },
      bindLaoWuUserList: [],
      defaultForm: {
        officeId: undefined,
      },
      form: {
        officeId: undefined,
      },
      officeLoading: false,
      newRole: [],
      oldRole: [],
      typeMap: {
        underGroupList: [],
        companyMiniTypeList: [],
        companyTypeList: [],
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" },{required: true, message: '请输入姓名', trigger: 'change'}],
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" },{ required: true, message: "请输入手机号", trigger: "change" }],
        // loginName: [{ required: true, message: '请输入登录名', trigger: 'blur' }],
        password: [
          { validator: validatePassword, trigger: "change"},
          // { required: true, message: "密码必须包含数字、小写字母、大写字母、特殊字符，且长度大于等于8个字符",pattern: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=])\S{8,}$/, trigger: "blur" }
        ],
        confirmPassWord: [
          { validator: validateConfirmPassWord, trigger: "change" },
        ],
      },
      arr: [], // 存的第三方平台字段
      isPwdShow: false // 密码是否必填项
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  watch: {
    officeTreeData(newValue) {
      console.log(newValue, "监听officeTreeData数据变化");
    },
  },
  created() {
    // this.initData();
  },
  mounted() {
    this.initOffice()
    this.initData();
  },
  methods: {
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    onSelect(selectedKeys, info) {
      console.log("selectedKeys", selectedKeys);
      this.queryParam.pageNumber = 1;
      this.queryParam.current = 1;
      this.queryParam.officeId = selectedKeys[0];
      this.queryOfficeId(this.queryParam.officeId, null, null);
      this.parentData();
    },
    handleSearch() {
      this.queryParam.pageNumber = 1;
      this.queryParam.current = 1;
      // this.officeTreeData = []
      this.parentData();
    },
    initData() {
      if (this.user.nowRoleKey.includes("admin")) {
        this.queryParam.officeId = undefined;
      } else if (this.user.nowRoleKey.includes("OFFICE_JSFWZY")) {
        this.queryParam.officeId = this.user.officeId;
        this.handleSearch()
      } else if (this.user.nowRoleKey.includes("OFFICE_MGR")) {
        this.queryParam.officeId = this.user.officeId;
        this.handleSearch()
      } else {
        this.queryParam.officeId = this.user.officeId;
      }

      getOfficeTypeList().then((res) => {
        this.typeMap.underGroupList = res.data.data.underGroupList;
        this.typeMap.companyMiniTypeList = res.data.data.companyMiniTypeList;
        this.typeMap.companyTypeList = res.data.data.companyTypeList;
      });
      getRoleListBySysUserList({}).then((res) => {
        this.newRole = res.data.data.filter((obj) => obj.roleType == 2)
        this.oldRole = res.data.data.filter((obj) => obj.roleType != 2)
        // 如果不是Admin操作

        this.newRole.forEach(function (element, index, array) {
          array[index].label = element.name;
          array[index].value = element.id;
        });
        if (this.user.nowRoleKey.includes("admin")) {
          // this.newRole = res.data.data.newRoleList;
        } else {
        // 筛选数组=公司角色
          this.newRole = res.data.data.newRoleList.filter((obj) =>
            obj.type == null
          );
        }
        this.oldRole.forEach(function (element, index, array) {
          array[index].label = element.name;
          array[index].value = element.id;
        });
      });

      getProjectListByOfficeId({
        officeId: this.queryParam.officeId,
      }).then((res) => {
        this.projectList = res.data.data;
      });
    },
    parentData() {
      // this.queryParam.officeId= "000bed79a8d942aaa00c85c201eb81c2"
      if (
        this.queryParam.officeId == undefined ||
        this.queryParam.officeId == ""
      ) {
        this.officeData = [];
        return;
      }
      this.tableLoading = true;
      getOfficeSysUser(this.queryParam).then((res) => {
        if (res.data.code === 0) {
          this.officeData = res.data.data.list;
          this.queryParam.total = res.data.data.total;
        }
        this.tableLoading = false;
      });
    },
    expand(expanded, record) {},

    showModal(record, isShow) {
      this.form = {
        officeId: undefined,
      };
      this.isPwdShow = isShow // 密码是否必填项
      getRoleListBySysUserList({})
        .then((res) => {
          this.newRole = res.data.data.filter((obj) => obj.roleType == 2)
          this.oldRole = res.data.data.filter((obj) => obj.roleType != 2)
          this.newRole.forEach(function (element, index, array) {
            array[index].label = element.name;
            array[index].value = element.id;
          });
          if (this.user.nowRoleKey.includes("admin")) {
            // this.newRole = res.data.data.newRoleList;
          } else {
            let arrRoles = ["office"]; // 包含的权限角色
        // 筛选数组=公司角色
        this.newRole = res.data.data.newRoleList.filter((obj) =>
          arrRoles.some((role) => obj.roleKey.toLowerCase().includes(role))
        );
            // this.newRole = this.newRole.filter((item) => item.roleId != 1);
          }
          this.oldRole.forEach(function (element, index, array) {
            array[index].label = element.name;
            array[index].value = element.id;
          });
        })
        .finally(() => {
          if (record.id != undefined && record.id != "") {
            // getRoleByOldUserId({oldUserId: record.id}).then(res=>{
            //     const labourRole = res.data.data.labourRole;
            //     const laowuRole = res.data.data.laowuRole;
            //
            //     var noExist = true
            //     if (labourRole != undefined && labourRole != null){
            //         noExist = true
            //         this.newRole.forEach(function (element, index, array) {
            //             if (element.roleId == labourRole.roleId){
            //                 noExist = false;
            //                 return
            //             }
            //         });
            //         if (noExist){
            //             this.newRole[this.newRole.length] = labourRole;
            //         }
            //
            //         record.labourRoleId = labourRole.roleId;
            //     }
            //     if (laowuRole != undefined && laowuRole != null){
            //         noExist = true
            //         this.oldRole.forEach(function (element, index, array) {
            //             if (element.id == laowuRole.id){
            //                 noExist = false;
            //                 return
            //             }
            //         });
            //         if (noExist){
            //             laowuRole.label = laowuRole.name
            //             laowuRole.value = laowuRole.id
            //             this.oldRole[this.oldRole.length] = laowuRole;
            //         }
            //
            //         record.laowuRoleId = laowuRole.id;
            //     }
            //
            // })
            record.labourRoleIdList = record.roleIdList ? record.roleIdList : record.roleIds.split(',');
            record.labourRoleIdList = record.labourRoleIdList.map(item => {
              return Number(item);
            })
            this.visible = true;
          } else {
            this.visible = true;
          }
          if (isShow) {
            this.form = {
              id: parseInt(record.id),
              labourRoleId: parseInt(record.labourRoleId),
              labourRoleIdList: record.labourRoleIdList,
              name: record.name,
              officeId: record.officeId,
              officeName: record.officeName,
              phone: record.phone,
              remarks: record.remarks,
              roleId: parseInt(record.roleId),
              roleName: record.roleName,
            };
            // this.form = record;
          } else {
            this.form = record;
          }
        });
    },
    modelOk(e) {
      this.confirmLoading = true;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          this.$message.error("请检查必填信息");
          this.confirmLoading = false;
          return false;
        }
      });
    },
    modelCancel(e) {
      this.visible = false;
      this.bindLaoWuUserList = [];
    },
    officeSelect(value, node, extra) {
      this.form.officeId = value;
    },
    queryOfficeId(value, node, extra) {
      this.queryParam.officeId = value;
      getProjectListByOfficeId({
        officeId: this.queryParam.officeId,
      }).then((res) => {
        this.projectList = res.data.data;
      });
    },
    submit() {
      this.form.loginName = this.phone;
      saveLabourUser(this.form)
        .then((res) => {
          if (res.data.code === 0) {
            this.$message.success('操作成功');
            this.parentData();
            this.visible = false;
            this.modelCancel();
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    addOffice(officeId) {
      this.form = {
        officeId: officeId,
      };
      this.showModal(this.form, false);
    },
    del(record) {
      var _that = this;
      this.$confirm({
        title: "确认删除" + record.name + "?",
        content: "要删除该账号吗？",
        okText: "删除",
        cancelText: "取消",
        onOk() {
          delSysUser({ labourUserId: record.id }).then((res) => {
            _that.$message.info(res.data.msg);
          });
          _that.parentData();
        },
        onCancel() {},
      });
    },
    changeLaoWuRole(e) {
      var _that = this;
      this.form.laowuRoleId = e.target.value;
      this.oldRole.forEach(function (element, index, array) {
        if (element.id == e.target.value) {
          _that.form.code = element.code;
          return;
        }
      });
    },
    changeLabourRole(e) {
      // this.form.labourRoleId = e.target.value
      this.form.labourRoleIdList = e;
    },
    handleBlur() {
      console.log("blur");
    },
    handleFocus() {
      console.log("focus");
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.indexOf(input) >= 0;
    },
    onPageChange(e) {
      this.queryParam.pageNumber = e.current;
      this.queryParam.current = e.current;
      this.parentData();
    },
    formatData(data) {
      return data;
    },
    loadData(e) {
      this.searchKeyword = e;
        // if (e == '') return this.officeTreeData = []
        getOfficeTree({
          // ownerOfficeId: this.queryParam.officeId,
          name: e,
        }).then((res) => {
          const data = res.data.data;
          // 数据处理逻辑：根据你的需求对获取到的数据进行处理
          const formattedData = this.formatData(data);
          // 更新 tree-data 属性
          this.officeTreeData = formattedData;
        });
    },
    // 重置表单
    reset() {
      this.queryParam.pageNumber = 1;
      this.queryParam.current = 1;
      this.queryParam.phone = ''
      this.queryParam.name = ''
      this.queryParam.officeId = this.user.officeId
      this.queryParam.roleId = undefined
      this.handleSearch()
    },
    initOffice() {
      getTreeOffice({ownerOfficeId: this.user.officeId}).then(res => {
        this.officeTreeData = res.data.data;
      })
    }
  },

};
</script>

<style lang="less" scoped>
.ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-bottom: 10px;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

tr {
  background-color: #ffffff;
}
.operator {
    margin-right: 8px;

    .ant-btn {
      color: #307dfa;
      border-color: #307dfa;
    }
  }
</style>
