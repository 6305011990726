<template>
  <a-modal 
  forceRender
  centered 
  :maskClosable="false" 
  v-model="visibleExit" 
  title="重置密码" 
  @cancel="handleCancel" 
  @ok="modelOk"
  :width="700"
  >
    <form-index
      ref="formindex"
      :formData="formData"
    >
    </form-index>
  </a-modal>
</template>

<script>

import formIndex from "@/pages/components/form/index";
import { mapGetters } from "vuex";
import {
  updatePassword
} from "@/services/sys";
export default {
  name: 'SuijianClientresetPasswordModal',
  components: {
    formIndex
  },
  props: {
    visible:{
      type:Boolean,
      default:false
    },
    userId: {
      type: [String,Number],
    }
  },
  watch: {
    visible: {
      handler(val, oldName) {
        this.visibleExit = val
        this.$refs["formindex"].resetFields()
      }
    }
  },
  data() {
    const formData = [
      {
        label: "新密码",
        placeholder: "新密码必须包 含数字、大小写字母、特殊字符，且长度至少8位",
        decorator: [
          "password",
          { rules: [
            { 
              required: true, 
              message: '新码必须包含数字、小写字母、大写字母、特殊字符，且长度至少8位',
              pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[~!@#$%^&*.)(_+}{|:?><]).{8,16}$/
            },
            {validator: this.validatePassword}
            ]
          },
        ],
        type: "inputPassWord",
        key: "password",
        col: 24,
        labelCol: 6,
        wrapperCol: 14,
      },
      {
        label: "确认密码",
        placeholder: "请输入确认密码",
        decorator: [
          "confimPassword",
          { rules: [{ required: true,message: '请输入确认密码' },{validator: this.validateUserPassword}] },
        ],
        type: "inputPassWord",
        key: "confimPassword",
        col: 24,
        labelCol: 6,
        wrapperCol: 14,
      },
    ]
    return {
      formData:formData,
      visibleExit: this.visible,
    };
  },

  computed: {
    ...mapGetters("account", ["user"]),
  },

  methods: {
    handleCancel() {
      this.$emit("handleCancel")
    },
    // 确认弹窗
    modelOk(e) {
      this.$refs["formindex"].combinationSubmit().then((res) => {
        const params = {
          ...res,
        };
        params.id = this.userId
        if (this.userId) {
          updatePassword(params).then(result => {
            if (result.data.code === 0) {
              this.$message.success('操作成功', 10);
            }
          })
          this.handleCancel();
        }
      });
    },
    validatePassword(rule, value, callback) {
      if (value && /(\d)\1\1/.test(value)) {
          callback(new Error('密码不能包含三个连续的相同数字'));
      }
      else if (value && !this.isConsecutiveNumbers(value)) {
        callback(new Error('不能连续数字（如1234）连续4位或4位以上'));
      }
      else {
        // 密码符合要求
        callback();
      }
    },
    validateUserPassword(rule, value, callback) {
        // 自定义校验规则
        let obj = this.$refs["formindex"].getFieldValue();
        if (value && value != obj.password) {
            callback(new Error('确认密码不一致'));
        } 
        // else if (value && value == oldPwd) {
        //     callback(new Error('新密码不能和旧密码一致'));
        // } 
        else {
            callback();
        }
    },
    // 判断是否包含连续数字 (如1234)
    isConsecutiveNumbers(str) {
        let arr = str.split('')
        let flag = true
        for (let i = 1; i < arr.length - 2; i++) {
        let firstIndex = arr[i - 1].charCodeAt()
        let secondIndex = arr[i].charCodeAt()
        let thirdIndex = arr[i + 1].charCodeAt()
        let fourIndex = arr[i + 2].charCodeAt()
        // 是否都为数值
        const allNumber = [
        firstIndex,
        secondIndex,
        thirdIndex,
        fourIndex
        ].every((i) => this.isNumeric(i))

        if (allNumber && (fourIndex - thirdIndex == 1) && (thirdIndex - secondIndex == 1) && (secondIndex - firstIndex == 1)) {
            console.log('为弱口令密码')
            flag = false
            break; // 如果已经发现连续数字，提前结束循环
        }
        }
        return flag
    },
    // 根据Unicode 编码判断是否为数字
    isNumeric(charCode) {
        return charCode >= 48 && charCode <= 57
    }
  },
};
</script>