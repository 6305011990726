var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("form-index", {
        ref: "formindex",
        attrs: {
          formData: _vm.formData,
          flexSubmit: true,
          bottomSubmit: true,
          Overview: "访客记录",
        },
        on: { handleSubmit: _vm.handleSubmit, handlSelect: _vm.handlSelect },
        scopedSlots: _vm._u([
          {
            key: "flexSubmit",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "a_a_footer" },
        [
          _c(
            "div",
            [
              _c(
                "a-space",
                { staticClass: "operator" },
                [
                  _c(
                    "a-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.doDowloadBatch(1)
                        },
                      },
                    },
                    [_vm._v("导出")]
                  ),
                  _c(
                    "a-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.doDowloadBatch(3)
                        },
                      },
                    },
                    [_vm._v("刷新")]
                  ),
                ],
                1
              ),
              _c(
                "a-space",
                [
                  _c("a-button", { on: { click: _vm.close } }, [
                    _vm._v(" 重置 "),
                  ]),
                  _c(
                    "a-button",
                    { attrs: { type: "primary", "html-type": "submit" } },
                    [_vm._v(" 查询 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "standard-table",
            {
              attrs: {
                columns: _vm.columns,
                dataSource: _vm.dataSource,
                rowKey: "id",
                pagination: _vm.pagination,
                loading: _vm.tableLoading,
                isAlert: false,
                bordered: true,
              },
              on: { change: _vm.onPageChange },
              scopedSlots: _vm._u([
                {
                  key: "index",
                  fn: function (ref) {
                    var index = ref.index
                    return [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              (_vm.pagination.current - 1) *
                                _vm.pagination.pageSize +
                                parseInt(index) +
                                1
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            },
            [
              _c("template", { slot: "title" }, [
                _c("h3", { staticClass: "table-title" }, [_vm._v("test")]),
                _c("div", { staticClass: "table-desc" }, [
                  _c("span", [
                    _vm._v(
                      "项目名称（盖章）：湛江农垦第一机械有限公司 三旧 改造项目"
                    ),
                  ]),
                  _c("span", [_vm._v("单位：测试单位01 班组： 全部")]),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }