import { session } from '@/utils/storage'
import { logout } from '@/services/user.js'

const resp401 = {
  /**
   * 响应数据之前做点什么
   * @param response 响应对象
   * @param options 应用配置 包含: {router, i18n, store, message, warning}
   * @returns {*}
   */
  onFulfilled(response, options) {
    const { warning } = options
    if (response.data.code === 401) {
      warning({
        title: '登录已失效，请重新登录',
        onOk() {
          logout()
          location.href = '/#/login'
          location.reload(true)
        },
      });
    }
    return response
  },
  /**
   * 响应出错时执行
   * @param error 错误对象
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {Promise<never>}
   */
  onRejected(error, options) {
    const { warning } = options
    const {response} = error
    // 这里其实可以忽略，401接口并不会报错
    if (response.status === 401) {
      warning({
        title: '登录已失效，请重新登录',
        onOk() {
          logout()
          location.href = '/#/login'
          location.reload(true)
        },
      });
    }
    return Promise.reject(error)
  }
}

const resp406 = {
  /**
   * 响应数据之前做点什么
   * @param response 响应对象
   * @param options 应用配置 包含: {router, i18n, store, message, warning}
   * @returns {*}
   */
  onFulfilled(response, options) {
    const { warning } = options
    if (response.data.code === 406) {
      warning({
        title: response.data.msg,
        onOk() {
          logout()
          location.href = '/#/login'
          location.reload(true)
        },
      });
    }
    return response
  },
  /**
   * 响应出错时执行
   * @param error 错误对象
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {Promise<never>}
   */
  onRejected(error, options) {
    const { warning } = options
    const {response} = error
    // 这里其实可以忽略，401接口并不会报错
    if (response.status === 406) {
      warning({
        title: response.data.msg,
        onOk() {
          logout()
          location.href = '/#/login'
          location.reload(true)
        },
      });
    }
    return Promise.reject(error)
  }
}

const resp403 = {
  onFulfilled(response, options) {
    const {message} = options
    if (response.data.code === 403) {
      message.error(response.data.msg)
      // logout()
      // location.href = '/#/login'
      // location.reload(true)
    }
    return response
  },
  onRejected(error, options) {
    const {message} = options
    const {response} = error
    if (response.status === 403) {
      message.error('请求被拒绝')
      // location.href = '/#/login'
    }
    return Promise.reject(error)
  }
}

const reqCommon = {
  /**
   * 发送请求之前做些什么
   * @param config axios config
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {*}
   */
  onFulfilled(config, options) {
    /* 不做token校验，由接口返回状态码，在响应拦截器中处理 */
    // const {message} = options
    // const {url, xsrfCookieName} = config
    // const urlArr = [
    //   'login', 'captcha', 'send-sms-code', 'checkPhoneIsBindMultiple', 'updatePhonePwd', 'checkSmsCode', 'firstSaveBindPhone'
    // ]
    // if (urlArr.every(el => url.indexOf(el) === -1) && xsrfCookieName && !session.get(xsrfCookieName)) {
    //   message.warning('登录已过期，请重新登录');
    //   console.log("登录过期，返回的参数有",options)
    //   location.href = '/#/login'
    // }
    return config
  },
  /**
   * 请求出错时做点什么
   * @param error 错误对象
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {Promise<never>}
   */
  onRejected(error, options) {
    const {message} = options
    message.error(error.message)
    location.href = '/#/login'
    return Promise.reject(error)
  }
}

export default {
  request: [reqCommon], // 请求拦截
  response: [resp401, resp403, resp406] // 响应拦截
}
