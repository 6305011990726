<template>
    <div class="container">
        <div id="components-form-demo-advanced-search">
            <!-- <a-form-model class="ant-advanced-search-form"
                            :model="queryData"
                            layout="inline"
            >
                <a-form-model-item ref="applyType" label="类型" prop="applyType">
                    <a-select v-model="queryData.applyType" placeholder="请选择类型">
                        <a-select-option value="1">
                            专户工资
                        </a-select-option>
                        <a-select-option value="2">
                            普户工资
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item ref="month" label="发放月份" prop="month">
                    <a-month-picker v-model="queryData.month" placeholder="请选择发放月份"
                                    @change="queryDataMonthChange"  style="width: 100%;" format="yyyyMM"
                    />
                </a-form-model-item>
                <a-form-model-item ref="searchWorkerName" label="工人姓名" prop="searchWorkerName">
                    <a-input v-model="queryData.searchWorkerName" />
                </a-form-model-item>
                <a-form-model-item ref="teamName" label="班组名称" prop="teamName">
                    <a-input v-model="queryData.teamName" />
                </a-form-model-item>

                <a-form-model-item ref="summaryStatus" label="汇总状态" prop="summaryStatus">
                    <a-select v-model="queryData.summaryStatus " placeholder="请选择汇总状态" @change="summaryStatusChange">
                        <a-select-option value="0">
                            全部
                        </a-select-option>
                        <a-select-option value="1">
                            已汇总
                        </a-select-option>
                        <a-select-option value="2">
                            未汇总
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-button type="primary" icon="search" :loading="loading" @click="getData(true)">
                    查询
                </a-button>
                <br/>

            </a-form-model> -->
            <form-index
                ref="formindex"
                :formData="formData"
                @handleSubmit="handleSubmit"
                :flexSubmit="true"
                >
                <!-- 下部分按钮插槽 -->
                <template #flexSubmit>
                    <a-space  style="margin-left: 90px; margin-top: 5px">
                        <a-button type="primary" icon="search" :loading="loading" html-type="submit"> 查询 </a-button>
                    <a-space class="operator">

                        <a-button @click="close"> 重置 </a-button>
                    </a-space>
                    </a-space>
                </template>
            </form-index>
            <div class="a_a_footer mb-1">
                <a-space class="operator">
                    <a-button type="primary" style="margin-left: 10px" icon="download" :loading="loading" :disabled="selectedApplyIdList.length<=0"
                                @click="batchDownloadTeamPayroll(selectedApplyIdList)">
                        批量导出Excel报表
                    </a-button>
                </a-space>
                <a-space class="operator">
                    <a-button type="primary" style="margin-left: 10px" icon="enter" :loading="loading" :disabled="selectedApplyIdList.length<=0"
                                @click="batchCirculationFinance(selectedApplyIdList)">
                        批量提交财务审核
                    </a-button>
                </a-space>
                <a-space class="operator">
                    <a-button type="primary" style="margin-left: 10px" icon="plus" :loading="loading" @click="showApplyModel">
                        在线制作工资单
                    </a-button>
                </a-space>
                <a-space class="operator">
                    <a-button type="primary" style="margin-left: 10px" icon="plus" :loading="loading" @click="showManualModel">
                        上传工资单
                    </a-button>
                </a-space>
            </div>
            <a-table
                    :row-selection="{ selectedRowKeys: selectedApplyIdList, onChange: selectApplyChange }"
                    row-key="id"
                    :columns="columns"
                        :data-source="data"
                        :pagination="pagination"
                        :loading="loading"
                    @change="teamApplyTableChange"
                    :footer="handleFooter"
            >
                <template slot="action" slot-scope="text, record">
                    <a-popover title="操作" trigger="click" placement="bottom">
                        <template slot="content">
                            <a @click="showStaffPayrollModel(record)">查看详情</a>
                            <br/>
                            <a @click="downloadTeamPayroll(record)">导出Excel报表</a>
                            <br/>
                            <a v-if="record.status === 0 ||record.status === 2  " @click="upApplyStatus(record)">提交财务审核</a>
                            <br/>
                            <a v-if="record.status === 0 || record.status === 2  " @click="delApply(record)">删除</a>
                        </template>
                        <a type="primary">
                            操作<a-icon type="down" />
                        </a>
                    </a-popover>
    <!--                    <span>-->
    <!--                      <a @click="showStaffPayrollModel(record)">查看详情</a>-->
    <!--                      <a-divider type="vertical" />-->
    <!--                      <a @click="downloadTeamPayroll(record)">导出Excel报表</a>-->
    <!--                      <a-divider  v-if="record.status === 0 ||record.status === 2 " type="vertical" />-->
    <!--                      <a v-if="record.status === 0 ||record.status === 2  " @click="upApplyStatus(record)">提交财务审核</a>-->
    <!--                      <a-divider  v-if="record.status === 0 ||record.status === 2 " type="vertical" />-->
    <!--                        <a v-if="record.status === 0 || record.status === 2  " @click="delApply(record)">删除</a>-->
    <!--                    </span>-->
                </template>
                <template slot="workStatusText" slot-scope="text">
                    <div style="white-space: pre-wrap;">{{text}}</div>
                </template>
                <!-- <template slot="footer">  
                    <div style="text-align: center" :key="tabTotal.timeKey">
                        <a-row>
                            <a-col :span="11">
                                合计：
                            </a-col>
                            <a-col :span="2">
                                {{tabTotal.payableMoneySum}}
                            </a-col>
                            <a-col :span="2">
                                {{tabTotal.actualMoneySum}}
                            </a-col>
                            <a-col :span="2">
                                {{tabTotal.taxSum}}
                            </a-col>
                            <a-col :span="2">
                                {{tabTotal.highTempSubsidySum}}
                            </a-col>
                            <a-col :span="5">

                            </a-col>
                        </a-row>
                    </div>
                </template> -->

            </a-table>
        </div>

        <a-modal
                title="工资单申请"
                :visible="applyModelVisible"
                :confirm-loading="applyConfirmLoading"
                @ok="applyOk"
                @cancel="applyCancel"
                width="50%"
                :footer="null"
        >
            <a-steps :current="applyCurrent">
                <a-step v-for="item in steps" :key="item.title" :title="item.title" :description="item.description" />
            </a-steps>
            <div class="steps-content" v-if="applyCurrent==0">
                <a-form-model :model="applyForm" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules" ref="ruleForm">
                    <a-form-model-item label="项目" prop="projectId">
                        <a-auto-complete :data-source="inputDataSource" 
                            v-model="applyForm.projectId"
                            placeholder="请输入项目名称" @search="handleInputSearch($event)" allowClear
                            @select="handlInputSelect($event)"
                            >
                            <template slot="dataSource">
                                <a-select-option v-for="items in inputDataSource" :key="items.id + ''">{{ items.title }}</a-select-option>
                            </template>
                        </a-auto-complete>
                    </a-form-model-item>
                    <a-form-model-item label="类型" required  prop="applyType">
                        <a-select v-model="applyForm.applyType" placeholder="请选择类型" @change="applyFormApplyTypeChange">
                            <a-select-option value="1">
                                专户工资
                            </a-select-option>
                            <a-select-option value="2">
                                普户工资
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="发放月份" required prop="month">
                        <a-month-picker v-model="applyForm.month" placeholder="请选择发放月份"
                                      @change="monthChange"  style="width: 100%;" format="yyyyMM"
                        />
                    </a-form-model-item>
                </a-form-model>
            </div>
            <div class="steps-content" v-if="applyCurrent==1">
                <a-row :gutter="24">
                    <a-form-model :model="teamQueryData" >
                        <a-form-model-item label="班组名称" :labelCol="{span:3}" :wrapper-col="{span:6}">
                            <a-input v-model="teamQueryData.teamName" placeholder="请输入筛选的班组名称" />
                        </a-form-model-item>
                    </a-form-model>
                </a-row>
                <a-table
                        :row-selection="{ selectedRowKeys: selectedTeamIds, onChange: selectTeamChange, type: 'radio' }"
                        :columns="teamColumns"
                        :data-source="teamList"
                        row-key="teamId"
                />
            </div>
            <div class="steps-content" v-if="applyCurrent==2">
                <a-transfer
                        :data-source="transferData.staffList"
                        :target-keys="transferData.targetKeys"
                        :disabled="transferData.disabled"
                        :show-search="transferData.showSearch"
                        :filter-option="(inputValue, item) => item.title.indexOf(inputValue) !== -1"
                        :show-select-all="false"
                        @change="transferOnChange"
                >
                    <template
                            slot="children"
                            slot-scope="{
                              props: { direction, filteredItems, selectedKeys, disabled: listDisabled },
                              on: { itemSelectAll, itemSelect },
                            }"
                    >
                        <a-table
                                :row-selection="transferGetRowSelection({ disabled: listDisabled, selectedKeys, itemSelectAll, itemSelect })"
                                :columns="direction === 'left' ? transferData.leftColumns : transferData.rightColumns"
                                :data-source="filteredItems"
                                size="small"
                                :custom-row="
                                    ({ key, disabled: itemDisabled }) => ({
                                      on: {
                                        click: () => {
                                          if (itemDisabled || listDisabled) return;

                                          itemSelect(key, !selectedKeys.includes(key));
                                        },
                                      },
                                    })
                                  "
                        />
                    </template>
                </a-transfer>
            </div>
            <div style="text-align: center;margin-top: 20px">
                <a-button type="primary" :loading="loading" @click="applyBack" v-if="applyCurrent>0">
                    上一步
                </a-button>
                <a-button type="primary" style="margin-left: 15px" :loading="loading" @click="applyNext" v-if="applyCurrent==0 || applyCurrent==1 " >
                    下一步
                </a-button>
                <a-button type="primary" style="margin-left: 15px" :loading="loading" @click="applyNext" v-if="applyCurrent==2"
                          :disabled="applyForm.workNumList===undefined || applyForm.workNumList.length<=0 " >
                    制作工资单
                </a-button>
            </div>


        </a-modal>

        <a-modal
                title="工资单记录"
                :visible="staffPayrollModelVisible"
                width="65%"
                :footer="null"
                @cancel="staffPayrollModelCancel"
                :keyboard="true"
                :maskClosable="true"
        >
            <a-form-model
                    :model="staffPayrollQueryData"
                    layout="inline"
                    :label-col="{span: 6}"
                    :wrapper-col="{span: 16}"
            >
                <a-form-model-item ref="name" label="工人姓名" prop="name">
                    <a-input v-model="staffPayrollQueryData.name" />
                </a-form-model-item>
                <a-form-model-item ref="idCard" label="身份证号" prop="idCard">
                    <a-input v-model="staffPayrollQueryData.idCard" />
                </a-form-model-item>
                <a-button type="primary" icon="search" :loading="loading" @click="searchStaffPayroll">查询</a-button>
                <a-button type="primary" icon="download" :loading="loading" style="margin-left: 10px;" @click="downloadStaffPayroll">
                    导出工资单
                </a-button>
                <a-upload
                        name="file"
                        :multiple="true"
                        :action="uploadStaffPayrollUrl+ '?applyId=' + staffPayrollQueryData.applyId"
                        :headers="headers"
                        @change="staffPayrollFileChange"
                        :showUploadList="false"
                >
                    <a-button style="margin-left: 10px" :loading="loading"> <a-icon type="upload" />导入工资单</a-button>
                </a-upload>
            </a-form-model>

            <a-table :columns="staffPayrollColumns"
                     :data-source="staffPayrollData"
                     :pagination="staffPayrollPagination"
                     @change="staffPayrollTableChange"
                     :loading="staffPayrollLoading">
                <template slot="action" slot-scope="text, record">
                    <span>
                      <a v-if="(showTeamData.status===0 || showTeamData.status===2) && showTeamData.summaryId<=0 "  @click="editStaffPayrollModelShow(record)">修改</a>
                      <a-divider type="vertical" />
                      <a v-if="showTeamData.status===0 || showTeamData.status===2" @click="delStaffPayroll(record)">删除</a>
                    </span>
                </template>

            </a-table>
        </a-modal>

        <a-modal
                title="上传工资单"
                :visible="manualModelVisible"
                width="30%"
                :footer="null"
                @cancel="manualModelCancel"
                :keyboard="true"
                :maskClosable="true"
        >
            <a-card >
                <a-form-model class="mt-2" :model="uploadForm" :label-col="{span: 4}" :wrapper-col="{span: 18}" :rules="uploadingRules" ref="ruleForm">
                    <a-form-model-item label="项目" prop="uploadProjectId">
                        <a-auto-complete :data-source="inputDataSource"
                            v-model="uploadForm.uploadProjectId"
                            placeholder="请输入项目名称" @search="handleInputSearch($event)" allowClear
                            @select="handlInputSelect($event)"
                            > 
                            <template slot="dataSource">
                                <a-select-option v-for="items in inputDataSource" :key="items.id + ''">{{ items.title }}</a-select-option>
                            </template>
                        </a-auto-complete>
                    </a-form-model-item>
                </a-form-model>
                <a-card-grid style="width:50%;text-align:center;height: 300px">
                    <a-upload
                            name="file"
                            :multiple="true"
                            :action="uploadStaffPayrollByManual+ `?applyType=1&projectId=${uploadForm.uploadProjectId}`"
                            :headers="headers"
                            @change="manualStaffPayrollFileChange"
                            :showUploadList="false"
                            :data="{applyType:1}"
                            style=" line-height: 200px;"
                    >
                        <a-button type="primary" :loading="loading" :disabled="!isUpload" @click="applyFormApplyTypeChange('1')"> <a-icon type="upload" />上传专户工资表</a-button>
                    </a-upload>
                </a-card-grid>
                <a-card-grid style="width:50%;text-align:center;height: 300px" >
                    <a-upload
                            name="file"
                            :multiple="true"
                            :action="uploadStaffPayrollByManual+ `?applyType=2&projectId=${uploadForm.uploadProjectId}`"
                            :headers="headers"
                            @change="manualStaffPayrollFileChange"
                            :showUploadList="false"
                            :data="{applyType:2}"
                            style=" line-height: 200px;"
                    >
                        <a-button type="primary" :loading="loading" :disabled="!isUpload" @click="applyFormApplyTypeChange('2')"> <a-icon type="upload" />上传普户工资表</a-button>
                    </a-upload>
                </a-card-grid>
            </a-card>
            <br/>
            <a href="//adm.gz-suijian.com/templ/工资单模板.xlsx" style="font-size: 15px">下载工资单格式模板</a>
        </a-modal>

        <a-modal
                title="编辑工人记录"
                :visible="editStaffPayrollModelVisible"
                width="60%"
                okText="提交修改"
                @cancel="editStaffPayrollModelCancel"
                :keyboard="true"
                :maskClosable="false"
                @ok="submitStaffPayroll"
        >
            <a-form-model
                    ref="editStaffPayrollRuleForm"
                    :model="editStaffPayrollForm"
                    :layout="'inline'"
                    :rules="editStaffPayrollFormRules"
            >
                <a-form-model-item ref="name" label="姓名" prop="name" required>
                    <a-input v-model="editStaffPayrollForm.name"  />
                </a-form-model-item>
                <a-form-model-item ref="sex" label="性别" prop="sex" required>
                    <a-input v-model="editStaffPayrollForm.sex"  />
                </a-form-model-item>
                <a-form-model-item ref="idCard" label="身份证号" prop="idCard" required>
                    <a-input v-model="editStaffPayrollForm.idCard"  />
                </a-form-model-item>
                <a-form-model-item ref="bankCard" label="银行卡号" prop="bankCard" required>
                    <a-input v-model="editStaffPayrollForm.bankCard"  />
                </a-form-model-item>
                <a-form-model-item ref="bankName" label="开户银行" prop="bankName">
                    <a-input v-model="editStaffPayrollForm.bankName"  />
                </a-form-model-item>
                <a-form-model-item ref="bankBranch" label="开户银行支行" prop="bankBranch">
                    <a-input v-model="editStaffPayrollForm.bankBranch"  />
                </a-form-model-item>
                <a-form-model-item ref="idCard" label="手机号" prop="phone" required>
                    <a-input v-model="editStaffPayrollForm.phone"  />
                </a-form-model-item>
                <br/>
                <a-form-model-item ref="baseMoney" label="基本工资" prop="baseMoney" required>
                    <a-input style="width: 80px" v-model="editStaffPayrollForm.baseMoney"  />
                </a-form-model-item>
                <a-form-model-item ref="achievementsMoney" label="绩效工资" prop="achievementsMoney" required>
                    <a-input style="width: 80px"  v-model="editStaffPayrollForm.achievementsMoney"  />
                </a-form-model-item>
                <a-form-model-item ref="overtimeMoney" label="加班工资" prop="overtimeMoney" required>
                    <a-input style="width: 80px"  v-model="editStaffPayrollForm.overtimeMoney"  />
                </a-form-model-item>
                <a-form-model-item ref="highTempSubsidy" label="高温补贴" prop="highTempSubsidy" required>
                    <a-input style="width: 80px"  v-model="editStaffPayrollForm.highTempSubsidy"  />
                </a-form-model-item>
                <a-form-model-item ref="payableMoney" label="应发金额" prop="payableMoney" required>
                    <a-input style="width: 80px"  v-model="editStaffPayrollForm.payableMoney"  />
                </a-form-model-item>
                <a-form-model-item ref="tax" label="个税" prop="tax" required>
                    <a-input style="width: 80px"  v-model="editStaffPayrollForm.tax"  />
                </a-form-model-item>
                <a-form-model-item ref="actualMoney" label="实发金额" prop="actualMoney" required>
                    <a-input style="width: 80px"  v-model="editStaffPayrollForm.actualMoney"  />
                </a-form-model-item>
            </a-form-model>

        </a-modal>

    </div>
</template>
<script>
    import {getTeamPayrollApplyList, selectTeamAndTeamWorkerStaffByProjectId, downloadTeamPayroll, delStaffPayroll, updateStaffPayroll,
        submitPayrollApply, getStaffPayrollList, downloadStaffPayroll, getSysOffice, batchCirculationFinance, teamPayrollApplyDel} from '@/services/payroll'
    import {UPLOAD_STAFF_PAYROLL, UPLOAD_STAFF_PAYROLL_BY_MANUAL} from '@/services/api'
    import difference from 'lodash/difference';
    import {mapGetters} from 'vuex'
    import moment from 'moment'
    import { session } from '@/utils/storage'
    import { xsrfHeaderName } from '@/utils/request.js'
    import formIndex from "@/pages/components/form/index";
    import { getOfficeProject } from "@/services/projectManagement";
    const formData = [
        {
            label: "类型",
            placeholder: "请选择",
            decorator: [
                "applyType",
                { initialValue: "1", rules: [{ required: false, message: "请选择" }] },
            ],
            type: "select",
            key: "applyType",
            selectlist: [
                {
                    value: '1',
                    name: '专户工资'
                }, {
                    value: '2',
                    name: '普户工资'
                }
            ],
            col: 6,
            labelCol: 6,
            wrapperCol: 14,
            display: true,
        },
        {
            label: "发放月份",
            placeholder: "请选择",
            decorator: [
            "month",
            {
                rules: [{ required: false, message: "请选择" }],
            },
            ],
            type: "monthPicker",
            key: "month",
            col: 6,
            labelCol: 6,
            wrapperCol: 14,
            display: true,
            format: "YYYYMM",
            styles: {width: '100%'}
        },
        {
            label: "工人姓名",
            placeholder: "请填写",
            decorator: [
            "searchWorkerName",
                { rules: [{ required: false, message: "请填写" }] },
            ],
            type: "input",
            key: "searchWorkerName",
            col: 6,
            labelCol: 6,
            wrapperCol: 14,
            display: true,
        },
        {
            label: "项目名称",
            placeholder: "请填写",
            decorator: [
            "projectName",
                { rules: [{ required: false, message: "请填写" }] },
            ],
            type: "input",
            key: "projectName",
            col: 6,
            labelCol: 6,
            wrapperCol: 14,
            display: true,
        },
        {
            label: "班组名称",
            placeholder: "请填写",
            decorator: [
            "teamName",
                { rules: [{ required: false, message: "请填写" }] },
            ],
            type: "input",
            key: "teamName",
            col: 6,
            labelCol: 6,
            wrapperCol: 14,
            display: true,
        },
        {
            label: "汇总状态",
            placeholder: "请选择",
            decorator: [
            "summaryStatus",
            { rules: [{ required: false, message: "请选择" }] },
            ],
            type: "select",
            key: "summaryStatus",
            selectlist: [{
                value: '0',
                name: '全部'
            }, {
                value: '1',
                name: '已汇总'
            }, {
                value: '2',
                name: '未汇总'
            }],
            col: 6,
            labelCol: 6,
            wrapperCol: 14,
            display: true,
        },
    ];
    const columns = [
        
        {
            title: '申请id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '班组名称',
            dataIndex: 'teamName',
            key: 'teamName',
        },
        {
            title: '月份',
            dataIndex: 'month',
            key: 'month',
        },
        {
            title: '项目名称',
            dataIndex: 'projectName',
            key: 'projectName',
            ellipsis: true,
        },
        {
            title: '公司名称',
            dataIndex: 'officeName',
            key: 'officeName',
            ellipsis: true,
        },
        {
            title: '类型',
            dataIndex: 'applyType',
            key: 'applyType',
            customRender: (text) => {
                return text == 1? "专户工资" : text == 2? "普户工资" : ""
            }
        },
        {
            title: '应发工资',
            dataIndex: 'payableMoney',
            key: 'payableMoney',
        },

        {
            title: '实发工资',
            dataIndex: 'actualMoney',
            key: 'actualMoney',
        },
        {
            title: '个税',
            dataIndex: 'tax',
            key: 'tax',
        },
        {
            title: '高温补贴',
            dataIndex: 'highTempSubsidy',
            key: 'highTempSubsidy',
        },
        {
            title: '汇总状态',
            dataIndex: 'summaryId',
            key: 'summaryId',
            customRender: (text) => {
                return text >0 ? "已汇总" : "未汇总"
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            customRender: (text) => {
                return text  === 0 ? "已生成" : text  === 1? "已提交财务审核" : text  === 2? "审核未通过" : text  === 3? "已结束" :"未知"
            }
        },
        {
            title: '人员状态人数',
            dataIndex: 'workStatusText',
            key: 'workStatusText',
            width: 130,
            scopedSlots: { customRender: 'workStatusText' },
        },
        {
            title: '操作',
            dataIndex: 'action',
            scopedSlots: { customRender: 'action' },
        },

    ];

    const teamColumns = [
        {
            title: '班组名称',
            dataIndex: 'teamName',
            key: 'teamName',
        },
        {
            title: '班组长名称',
            dataIndex: 'teamLeader',
            key: 'teamLeader',
        },
        {
            title: '班组电话',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
    ]

    const staffPayrollColumns = [
        {
            title: '工人名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '身份证',
            dataIndex: 'idCard',
            key: 'idCard',
        },
        {
            title: '性别',
            dataIndex: 'sex',
            key: 'sex',
        },
        {
            title: '电话',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: '基本工资',
            dataIndex: 'baseMoney',
            key: 'baseMoney',
        },
        {
            title: '绩效奖金',
            dataIndex: 'achievementsMoney',
            key: 'achievementsMoney',
        },
        {
            title: '加班工资',
            dataIndex: 'overtimeMoney',
            key: 'overtimeMoney',
        },
        {
            title: '高温津贴',
            dataIndex: 'highTempSubsidy',
            key: 'highTempSubsidy',
        },
        {
            title: '应发金额',
            dataIndex: 'payableMoney',
            key: 'payableMoney',
        },
        {
            title: '个税',
            dataIndex: 'tax',
            key: 'tax',
        },
        {
            title: '实发金额',
            dataIndex: 'actualMoney',
            key: 'actualMoney',
        },
        {
        title: '在场状态',
        dataIndex: 'workStatus',
        key: 'workStatus',
        align: "center",
        customRender: (text) => {
            return text == '' ?  text : text == 1 ? '在场' : (text == 2 ? '离场' : '未录入')
        }
        },
        {
            title: '操作',
            dataIndex: 'action',
            scopedSlots: { customRender: 'action' },
        },
    ]

    const leftTableColumns = [
        {
            dataIndex: 'staffName',
            title: '工人姓名',
        },
        {
            dataIndex: 'idCard',
            title: '身份证号',
        },
    ];
    const rightTableColumns = [
        {
            dataIndex: 'staffName',
            title: '工人姓名',
        },
    ];

    export default {
        components: {
            formIndex,
        },
        data() {
            return {
                formData: formData,
                headers: {
                    Authorization: session.get(xsrfHeaderName),
                    'Tenant-Id': session.get('Tenant-Id') ? session.get('Tenant-Id') : ''
                },

                queryData: {
                    officeName: '',
                    projectName: '',
                    month: '',
                    teamName: '',
                    applyType: '1',
                    summaryStatus: '',
                    searchWorkerName: ''
                },
                data: [],
                tabTotal:{
                    timeKey: 0,
                    payableMoneySum:0,
                    actualMoneySum:0,
                    taxSum:0,
                    highTempSubsidySum:0,
                },
                pagination: {
                    current: 1,
                    pageSize: 20,
                    total: 0,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
                },
                loading: false,
                columns,
                selectedApplyIdList: [],

                inputDataSource: [],

                applyModelVisible: false,
                applyConfirmLoading: false,
                applyCurrent: 0,
                applyNextBtnName: '下一步',
                steps: [
                    {
                        title: '基础信息',
                        description: '填写申请单基础信息',
                    },
                    {
                        title: '选择班组',
                        description: '选择班组',
                    },
                    {
                        title: '选择工人',
                        description: '选择工人',
                    },
                ],
                teamColumns,
                teamQueryData: {
                    projectId: '',
                    officeId: '',
                    teamName: '',
                },
                teamList: [],
                selectedTeamIds: [],
                applyForm: {},
                uploadForm: {
                    uploadProjectId: undefined
                },
                isUpload: undefined, // 是否已选择项目
                uploadingRules: {
                    uploadProjectId : [{ required: true, message: '请选择项目', trigger: ['change'] }],
                },
                rules: {
                    
                    projectId: [{ required: true, message: '请选择项目', trigger: 'change' }],
                    month: [{ required: true, message: '请选择发放月份', trigger: 'change' }],
                    applyType: [{ required: true, message: '请选择类型', trigger: 'change' }],
                },
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 9 },
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 8 },
                },
                transferData:{
                    staffList : [],
                    targetKeys: [],
                    disabled: false,
                    showSearch: true,
                    leftColumns: leftTableColumns,
                    rightColumns: rightTableColumns,
                },

                showTeamData: {},
                staffPayrollColumns,
                staffPayrollData: [],
                staffPayrollPagination: {
                    current: 1,
                    pageSize: 20,
                    total: 0,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
                },
                staffPayrollModelVisible: false,
                staffPayrollLoading: false,
                staffPayrollQueryData: {
                    applyId: 0,
                },
                uploadStaffPayrollUrl: UPLOAD_STAFF_PAYROLL,
                uploadStaffPayrollByManual: UPLOAD_STAFF_PAYROLL_BY_MANUAL,

                sysOffice: {},

                manualModelVisible: false,
                manual: {
                    applyType: '',
                },


                editStaffPayrollModelVisible: false,
                editStaffPayrollForm:{

                },
                totalData:{},
                editStaffPayrollFormRules:{
                    name: [{message: '姓名为必填项',required:true}],
                    sex: [{message: '性别为必填项',required:true}],
                    idCard: [{message: '身份证号为必填项',required:true}],
                    bankCard: [{message: '银行卡号为必填项',required:true}],
                    phone: [{message: '手机号为必填项',required:true}],
                    baseMoney: [{message: '基本工资为必填项',required:true}],
                    achievementsMoney: [{message: '绩效工资为必填项',required:true}],
                    overtimeMoney: [{message: '加班工资为必填项',required:true}],
                    highTempSubsidy: [{message: '高温补贴为必填项',required:true}],
                    payableMoney: [{message: '应发金额为必填项',required:true}],
                    tax: [{message: '个税为必填项',required:true}],
                    actualMoney: [{message: '实发金额为必填项',required:true}],
                },
            };
        },
        computed: {
            hasTeamSelected() {
                return this.selectedTeamIds.length > 0;
            },
            ...mapGetters('account', ['user']),
            footerDataSource () {
                const summary = Object.assign({}, this.totalData)
                for (const attr in summary) {
                summary[attr] = '合计:'
                break
                }
                return [summary]
            },
            rowSelection() {
                return {
                    onChange: (selectedRowKeys, selectedRows) => {
                    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                    },
                    getCheckboxProps: record => ({
                        props: {
                            disabled: true, // Column configuration not to be checked
                            name: record.name,
                        },
                    }),
                };
            },
        },
        created() {

            this.queryData.projectId = this.user.projectId
            this.getSysOffice()
            this.getData(false);
        },
        methods: {
            handlInputSelect(e) {
                this.teamQueryData.projectId = e
                this.applyForm.projectId = e
                this.applyForm.projectName = this.inputDataSource.find(el => el.id == e).title
                this.isUpload = this.inputDataSource.find(el => el.id == e)
            },
            handleInputSearch(value) {
                this.inputDataSource = []
                if(!value || '') {
                    return this.isUpload = null
                }
                let params = {
                    officeId: this.user.officeId,
                    projectName: value
                }
                getOfficeProject(params).then((res) => {
                    if (res.data.code === 0) {
                        const data = res.data.data.list
                        data.forEach(el => {
                            this.inputDataSource.push({
                                title: el.fullName,
                                id: el.id,
                            })
                        })
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
            },
            handleSubmit(e) {
                this.queryData = Object.assign(this.queryData, e)
                this.getData(true);
            },
            // 重置
            close() {
                this.$refs["formindex"].resetFields();
                this.queryData= {
                    officeName: '',
                    projectName: '',
                    month: '',
                    teamName: '',
                    applyType: '1',
                    summaryStatus: '',
                    searchWorkerName: ''
                }
                this.getData(true);
            },
            getData(isSearch){
                this.loading = true;
                if (isSearch){
                    this.pagination.current = 1
                }
                this.queryData.pageNumber = this.pagination.current;
                this.queryData.pageSize = this.pagination.pageSize;
                getTeamPayrollApplyList(this.queryData).then(res => {
                    if (res.data.code === 0) {
                        this.data = res.data.data.list;
                        this.pagination.total = res.data.data.total;

                        let payableMoneySum = 0;
                        let actualMoneySum = 0;
                        let highTempSubsidySum = 0;
                        let taxSum = 0;
                        this.data.forEach(function (item){
                            payableMoneySum += item.payableMoney
                            taxSum += item.tax
                            highTempSubsidySum += item.highTempSubsidy
                            actualMoneySum += item.actualMoney
                        })
                        this.tabTotal.payableMoneySum = payableMoneySum.toFixed(2);
                        this.tabTotal.actualMoneySum = actualMoneySum.toFixed(2);
                        this.tabTotal.highTempSubsidySum = highTempSubsidySum.toFixed(2);
                        this.tabTotal.taxSum = taxSum.toFixed(2);
                        this.tabTotal.timeKey = new Date().getTime()
                        let obj = {}
                        obj.applyType = ''
                        obj.payableMoney = this.tabTotal.payableMoneySum
                        obj.actualMoney = this.tabTotal.actualMoneySum
                        obj.tax = this.tabTotal.taxSum
                        obj.highTempSubsidy = this.tabTotal.highTempSubsidySum
                        this.totalData = obj
                    } else {
                        this.$message.error(res.data.msg);
                    }
                }).finally(() => {
                    this.selectedApplyIdList = []
                    this.selectedTeamIds = []
                    this.loading = false;
                })
            },
            queryDataMonthChange(dateString){
                if (dateString === '' || dateString=== null || dateString === undefined) {
                    this.queryData.month = ''
                    return
                }
                this.queryData.month = moment(dateString).format('YYYYMM')
            },
            showApplyModel() {
                this.teamQueryData.officeId = this.user.officeId
                this.applyForm = {}
                this.applyForm.applyType = this.queryData.applyType;
                this.applyForm.officeId = this.user.officeId
                this.applyForm.officeName = this.sysOffice.name
                this.transferData = {
                    staffList : [],
                    targetKeys: [],
                    disabled: false,
                    showSearch: true,
                    leftColumns: leftTableColumns,
                    rightColumns: rightTableColumns,
                }
                this.applyCurrent = 0
                this.applyNextBtnName = '下一步'
                this.applyModelVisible = true
            },
            selectApplyChange(selectedRowKeys) {
                this.selectedApplyIdList = selectedRowKeys;
                console.log( this.selectedApplyIdList);
            },
            applyFormApplyTypeChange(value){
                this.queryData.applyType = value
                this.applyForm.applyType = value
            },
            applyOk(e){
                this.applyConfirmLoading = true;
                setTimeout(() => {
                    this.applyCancel();
                }, 3000);
            },
            applyCancel(e){
                this.applyModelVisible = false;
                this.applyConfirmLoading = false;
            },
            selectTeamChange(selectedRowKeys) {
                console.log('selectedRowKeys changed: ', selectedRowKeys);
                this.selectedTeamIds = selectedRowKeys;
                const team = this.teamList.find(team => team.teamId===this.selectedTeamIds[0])
                this.applyForm.teamId = this.selectedTeamIds[0]
                this.applyForm.teamName = team.teamName
                this.transferData.staffList = team.staffVos
                this.transferData.staffList.forEach(staff => {
                    staff.title  = staff.staffName
                    staff.key = staff.workNumStr
                    staff.officeName = this.sysOffice.name
                })
            },
            applyBack(){
                this.applyCurrent--;
                if(this.applyCurrent === 0){
                    this.applyNextBtnName = '下一步';
                }else if (this.applyCurrent === 1) {
                    this.applyNextBtnName = '下一步';
                }
            },
            applyNext(){
                if (this.applyCurrent === 0) {
                    let pass = false;
                    this.$refs.ruleForm.validate(valid => {
                        pass = valid;
                        if (!valid) {
                            this.$message.error('请填写完整信息');
                            return false;
                        }
                    });
                    if (!pass){
                        return;
                    }
                    this.getTeamList()
                    this.applyNextBtnName = '下一步';
                } else if (this.applyCurrent === 1) {
                    if (this.applyForm.teamId === undefined || this.applyForm.teamId === '') {
                        this.$message.error('请选择班组');
                        return;
                    }
                    this.applyNextBtnName = '提交申请单';
                } else if (this.applyCurrent === 2){
                    if (this.applyForm.workNumList === undefined || this.applyForm.workNumList.length <= 0) {
                        this.$message.error('请选择工人');
                        return;
                    }
                    this.submitPayrollApply();
                    return;
                }
                this.applyCurrent = this.applyCurrent + 1;
            },
            getTeamList(){
                selectTeamAndTeamWorkerStaffByProjectId(this.teamQueryData).then(res => {
                    this.teamList = res.data.data;
                })
            },
            monthChange( dateString){
                if (dateString === '' || dateString=== null || dateString === undefined) {
                    this.queryData.month = ''
                    return
                }
                this.applyForm.month = moment(dateString).format('YYYYMM')
            },
            transferOnChange(nextTargetKeys) {
                this.transferData.targetKeys = nextTargetKeys;
                this.applyForm.workNumList = this.transferData.targetKeys;
            },
            transferGetRowSelection({ disabled, selectedKeys, itemSelectAll, itemSelect }) {
                return {
                    getCheckboxProps: item => ({ props: { disabled: disabled || item.disabled } }),
                    onSelectAll(selected, selectedRows) {
                        const treeSelectedKeys = selectedRows
                            .filter(item => !item.disabled)
                            .map(({ key }) => key);
                        const diffKeys = selected
                            ? difference(treeSelectedKeys, selectedKeys)
                            : difference(selectedKeys, treeSelectedKeys);
                        itemSelectAll(diffKeys, selected);
                    },
                    onSelect({ key }, selected) {
                        itemSelect(key, selected);
                    },
                    selectedRowKeys: selectedKeys,
                };
            },
            submitPayrollApply(){
                this.applyModelVisible = true
                this.applyConfirmLoading = true;
                const teamId = this.applyForm.teamId
                const workNumList = this.applyForm.workNumList
                let payrollStaffList = [];
                workNumList.forEach(workNum => {
                    const staff = this.transferData.staffList.find(staff => staff.workNumStr === workNum)
                    payrollStaffList.push({
                        name: staff.staffName,
                        workNum: staff.workNum,
                        bankCard: staff.staffBankCard,
                        bankName: staff.staffBankName,
                        idCard: staff.idCard,
                        phone: staff.phone,
                        sex: staff.staffSex,
                        teamName: this.applyForm.teamName,
                        teamId: this.applyForm.teamId,
                        projectName: this.applyForm.projectName,
                        projectId: this.applyForm.projectId,
                        officeName: this.applyForm.officeName,
                        officeId: this.applyForm.officeId,
                        month: this.applyForm.month,
                    })
                })
                this.applyForm.payrollStaffList = payrollStaffList;


                this.loading = true;
                submitPayrollApply(this.applyForm).then(res => {
                    this.applyModelVisible = false;
                    this.applyConfirmLoading = false;
                    this.getData(false);
                }).finally(() => {
                    this.loading = false;
                })
            },
            showStaffPayrollModel(record){
                this.showTeamData = JSON.parse(JSON.stringify(record))
                this.staffPayrollModelVisible = true;
                this.staffPayrollQueryData = {
                    applyId:  record.id,
                    name: '',
                    idCard: ""
                }
                // this.staffPayrollQueryData.applyId = record.id
                this.getStaffPayrollList()
            },
            searchStaffPayroll(){
                this.staffPayrollPagination.current = 1
                this.getStaffPayrollList()
            },
            staffPayrollModelCancel(){
                this.getData(false)
                this.staffPayrollModelVisible = false;
            },
            getStaffPayrollList(){
                this.loading = true;
                this.staffPayrollQueryData.pageNumber = this.staffPayrollPagination.current;
                this.staffPayrollQueryData.pageSize = this.staffPayrollPagination.size;
                getStaffPayrollList(this.staffPayrollQueryData).then(res => {
                    this.staffPayrollData = res.data.data.list;
                    this.staffPayrollPagination.total = res.data.data.total;
                }).finally(() => {
                    this.loading = false;
                })

            },
            downloadStaffPayroll(){
                this.loading = true;
                downloadStaffPayroll(this.staffPayrollQueryData).then(res => {

                    let applyTypeText = this.queryData.applyType === '1' ? "专户" : "普户";
                    let teamName = this.showTeamData.teamName;
                    let month = this.showTeamData.month;
                    let fileName = teamName + month + applyTypeText + "员工工资表.xlsx";
                    let downloadElement = document.createElement('a')//创建dom
                    let href = window.URL.createObjectURL(res.data) //创建下载链接
                    downloadElement.href = href
                    downloadElement.download = fileName //下载后文件名
                    document.body.appendChild(downloadElement)//添加创建的节点
                    downloadElement.click() //点击下载
                    document.body.removeChild(downloadElement) //下载完成移除元素
                    window.URL.revokeObjectURL(href) //释放掉blob对象
                }).finally(() => {
                    this.loading = false;
                })
            },
            staffPayrollFileChange(info){
                this.loading = true;
                if (info.file.status === 'done') {
                    const stat = info.file.response.code
                    if (stat === 0){
                        this.$message.success('导入工资单成功')
                        // this.$message.success(info.file.response.msg)
                        this.getStaffPayrollList()
                    }else {
                        this.$message.error(info.file.response.msg)
                    }
                    this.loading = false;
                }
            },
            manualStaffPayrollFileChange(info){
                this.loading = true;
                if (info.file.status === 'done') {
                    const stat = info.file.response.code
                    if (stat === 0){
                        this.$message.success('导入工资单成功')
                        // this.$message.success(info.file.response.msg)
                        this.getData(false)
                    }else {
                        this.$message.error(info.file.response.msg)
                    }
                    this.loading = false;
                    this.manualModelCancel()
                }
            },
            batchDownloadTeamPayroll(idList){
                this.loading = true;
                // 循环idList，重新组装applyIdList
                let applyIds = [];
                idList.forEach(id => {
                    applyIds.push(parseInt(id))
                })
                let selectList = []
                applyIds.forEach(el => {
                    this.data.forEach(i => {
                        if (el == i.id) {
                            selectList.push(i)
                        }
                    })
                })
                for (let index = 0; index < selectList.length; index++) {
                    if (index > 0) {
                        if (selectList[index - 1].applyType != selectList[index].applyType) {
                            this.$message.warning('专户普户不能一起批量导出')
                            this.loading = false;
                            return
                        }
                    }
                }
                console.log(JSON.stringify(applyIds))
                let data = {
                    "applyIdListStr": encodeURIComponent(JSON.stringify(applyIds)),
                    "applyType": this.queryData.applyType
                }

                downloadTeamPayroll(data).then(res => {
                    let applyTypeText = this.queryData.applyType === '1' ? "专户" : "普户";
                    let fileName = applyTypeText + "班组工资表.xlsx";
                    // let fileName = res.headers['Content-Disposition'].split(';')[1].split('=')[1];
                    let downloadElement = document.createElement('a')//创建dom
                    let href = window.URL.createObjectURL(res.data) //创建下载链接
                    downloadElement.href = href
                    downloadElement.download = fileName //下载后文件名
                    document.body.appendChild(downloadElement)//添加创建的节点
                    downloadElement.click() //点击下载
                    document.body.removeChild(downloadElement) //下载完成移除元素
                    window.URL.revokeObjectURL(href) //释放掉blob对象
                }).finally(() => {
                   this.loading = false;
                })
            },
            downloadTeamPayroll(record){
                this.loading = true;
                let idList = [record.id];
                this.batchDownloadTeamPayroll(idList);
            },
            getSysOffice(){
                getSysOffice(this.user.officeId).then(res => {
                    if (res.data.code == 0) {
                        this.sysOffice = res.data.data;
                    }
                })
            },
            upApplyStatus(record){
                let idList = [record.id]
                this.batchCirculationFinance(idList)
            },
            delApply(record){
                let id = record.id;
                let that = this;
                this.$confirm({
                    title: '确定要删除?',
                    content: '删除后数据将无法恢复，请仔细核对要删除的记录！！！',
                    okText: '删除',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                        teamPayrollApplyDel({id: id}).then(res => {
                            if (res.data.code === 0){
                                that.$message.info(res.data.msg)
                            }else {
                                that.$message.error(res.data.msg)
                            }
                        }).finally(() => {
                            that.getData(false);
                            that.loading = false
                        })
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            },
            batchCirculationFinance(idList){
                let applyIds = [];
                idList.forEach(id => {
                    applyIds.push(parseInt(id))
                })
                this.loading = true
                batchCirculationFinance(applyIds).then(res => {
                    if (res.data.code === 0){
                        this.$message.success('批量提交财务审核成功！')
                    }else {
                        this.$message.error(res.data.msg)
                    }
                }).finally(() => {
                    this.getData(false);
                    this.loading = false
                })
            },
            delStaffPayroll(record){
                if ((this.showTeamData.status !=0 && this.showTeamData.status !=2) || this.showTeamData.summaryId >0){
                    this.$message.error("已提交财务审核的不能删除，请联系财务处理")
                    return
                }
                let that = this
                this.$confirm({
                    title: '确定要删除该条工人工资记录吗?',
                    content: h => <div style="color:red;">删除后不可恢复</div>,
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                        console.log("onOk....")
                        that.loading = true
                        delStaffPayroll({id: record.id}).then(res => {
                            if (res.data.code === 0){
                                that.$message.info(res.data.msg)
                            }else {
                                that.$message.error(res.data.msg)
                            }
                        }).finally(() => {
                            that.getStaffPayrollList()
                            that.loading = false
                        })
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            },
            showManualModel(){
              this.manualModelVisible = true;
              this.uploadForm.uploadProjectId = undefined
              this.isUpload = undefined
            },
            manualModelCancel(){
                this.manualModelVisible = false;
            },
            teamApplyTableChange(pagination){
                this.pagination.current = pagination.current
                this.pagination.pageSize = pagination.pageSize
                this.getData(false)
            },
            staffPayrollTableChange(pagination){
                this.staffPayrollPagination.current = pagination.current
                this.staffPayrollPagination.pageSize = pagination.pageSize
                this.getStaffPayrollList()
            },
            editStaffPayrollModelShow(record) {
                this.editStaffPayrollForm = JSON.parse(JSON.stringify(record))
                this.editStaffPayrollModelVisible = true
                this.staffPayrollModelVisible = false

            },
            editStaffPayrollModelCancel() {
                this.getStaffPayrollList()
                this.editStaffPayrollModelVisible = false
                this.staffPayrollModelVisible = true

            },
            submitStaffPayroll() {
                let that = this;
                updateStaffPayroll(this.editStaffPayrollForm).then(res => {
                    if (res.data.code === 0){
                        that.$message.info(res.data.msg)
                    }else {
                        that.$message.error(res.data.msg)
                    }
                }).finally(() => {
                    this.editStaffPayrollModelCancel()
                })

            },
            // summaryStatusChange(value){
            //     this.queryDate.summaryStatus = value
            // },
            handleFooter () {
                // 处理 width 成百分比, 默认 'auto' 会根据text 计算宽度，造成footer对不齐的情况
                const columns = JSON.parse(JSON.stringify(this.columns)) 
                columns.forEach(col => {
                    // if (!col.width) col.width = (100 / columns.length) + '%' 
                    // col.align = 'left'
                    if (col.customRender) {
                        delete col.customRender
                    }
                })
                return (
                    <a-table
                    rowSelection={this.rowSelection}
                    rowKey={Math.random}
                    bordered={false}
                    pagination={false}
                    columns={columns}
                    dataSource={this.footerDataSource  || []}
                    showHeader={false}
                    ></a-table>
                )
                }
            },


    }

</script>
<style lang="less" scoped>
.a_a_footer {
  padding: 0 0px;
  background: #ffffff;

  .operator {
    margin-right: 8px;

    .ant-btn {
      color: #307dfa;
      background: #fff;
    }
  }

  .sTables {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      width: 100%;
      text-align: center;
      cursor: pointer;
    }

    div:nth-child(1) {
      color: #307dfa;
    }

    // div:nth-child(2) {
    //   color: #ff5c77;
    // }

    div:nth-child(2) {
      color: #615e83;
    }
  }

}
    .ant-advanced-search-form {
        padding: 24px;
        background: #fff;
        border-radius: 10px;
        margin-bottom: 10px;
    }

    tr{
        background-color: #ffffff;
    }

    .steps-content {
        margin-top: 16px;
        border: 1px dashed #e9e9e9;
        border-radius: 6px;
        background-color: #fafafa;
        padding-top: 10px;
    }

    .steps-action {
        margin-top: 24px;
    }
   /deep/ .ant-table-footer {
        padding: 16px 0;
        .ant-table-tbody > tr > td {
            border-bottom:none;
            color: rgba(0, 0, 0, 0.85);
        }
    }
</style>
