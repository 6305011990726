var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticStyle: { top: "20px" },
      attrs: { title: "操作", width: 800, footer: null },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [_c("icon-selector", { on: { change: _vm.handleIconChange } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }