<template>
  <div>
    <a-drawer
      title="编辑模板信息"
      :width="820"
      :visible="visible"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose"
    >
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }" layout="horizontal">
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="公司：">
              <a-select
               show-search
                :allowClear="true"
                :filterOption="filterOption"
                style="width: 100%"
                @change="handleChange"
                @search="officeSearch"
                v-decorator="['officeIds',{rules: [{ required: true, message: '请选择公司' }]}]">
                <a-select-option v-for="t in officeDataSource" :key="t.id" :value="t.id">{{t.name}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="项目：">
              <a-select
                :allowClear="true"
                style="width: 100%"
                placeholder="请选择项目"
                @change="projectChange"
                v-decorator="['projectId']">
                <a-select-option v-for="t in projectDataSource" :key="t.projId" :value="t.projId">{{t.fullName}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="标题：">
              <a-input
                v-decorator="['title', { rules: [{ required: true, message: '请输入模板标题！' }] }]"
               placeholder="请输入模板标题"/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="模板类型：">
              <!-- <a-input
                v-decorator="['fileType', { rules: [{ required: true, message: '请输入模板编码！' }] }]"
               placeholder="模板编码"/> -->
               <a-select
                :allowClear="true"
                style="width: 100%"
                placeholder="请选择模板类型"
                v-decorator="['fileType']">
                <a-select-option v-for="t in fileTypes" :key="t.key" :value="t.key">{{t.text}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="业务类型：">
               <a-select
                :allowClear="true"
                style="width: 100%"
                placeholder="业务类型"
                v-decorator="['bussType']">
                 <a-select-option key="1" :value="1">
                    入场
                </a-select-option>
                <a-select-option key="2" :value="2">
                    三级安全教育
                </a-select-option>
                <a-select-option key="3" :value="3">
                    安全检查
                </a-select-option>
                <a-select-option key="4" :value="4">
                    其他
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16" style="margin-left:11%">
          <a-col :span="12">
            <a-form-item label="是否启用：">
              <a-switch checked-children="开" un-checked-children="关" v-decorator="['statusBoolean', { valuePropName: 'checked' }]"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item  label="子企业启用：">
              <a-switch v-bind:disabled="dis()" checked-children="开" un-checked-children="关" v-model="isChild"/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item   label="复合模板包含：">
              <a-select
                :allowClear="true"
                mode="multiple"
                style="width: 100%"
                placeholder="请选择复合模板包含的文档类型"
                v-decorator="['fileMoreTypes']">
                <a-select-option v-for="t in fileTypes" :key="t.key" :value="t.key">{{t.text}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
         <a-row v-if="isChild" v-bind="dis()" :gutter="16">
          <a-col :span="24">
            <a-form-item  label="启用子企业：">
              <a-select
                v-bind:disabled="dis()"
                mode="multiple"
                :allowClear="true"
                style="width: 100%"
                v-decorator="['childIds']">
                <a-select-option v-for="t in childOffice" :key="t.id" :value="t.id">{{t.name}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="12">
          <a-col :span="24">
            <a-form-item label="模板文件：">
              <a-upload
                  name="file"
                  :multiple="false"
                  :beforeUpload="beforeUploadTempl"
                  accept=".doc,.docx"
                  :customRequest="resourceUploadTempl"
                  :file-list="defaultFileList"
              >
                  <a-button style="margin-left:10px" type="primary" >上传模板文件</a-button>
              </a-upload>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="12">
          <a-col :span="24">
            <a-form-item label="编写sql：">
              <a-textarea
                :rows="7"
                v-decorator="['sqlStr', { rules: [{ required: true, message: '请输入模板需要执行的sql！' }] }]"
                placeholder="请输入模板需要执行的sql"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <!-- <a-row :gutter="12">
          <a-col :span="24">
            <a-form-item label="编写参数(JSON)：">
              <a-textarea v-model="templFile.approveRemark"
                :rows="4"
                placeholder="请输入审批的意见"
              />
            </a-form-item>
          </a-col>
        </a-row> -->
        <a-row :gutter="12">
          <a-col :span="24">
            <a-form-item label="列的特殊处理(JSON)：">
              <a-textarea
                v-decorator="['columnSet']"
                :rows="4"
                placeholder="列的特殊处理(JSON格式)"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <!-- <a-row :gutter="12">
          <a-col :span="24">
            <a-form-item label="内容文件：">
              <a-upload
                  name="file"
                  :multiple="false"
                  :beforeUpload="beforeUploadCont"
                  accept=".pdf,.jpg,.png,.gif,.JPG,.PNG,.GIF"
                  :customRequest="resourceUploadCont"
                  :remove="removeCont"
                  :file-list="contentFileList"
              >
                  <a-button style="margin-left:10px" type="primary" >上传内容文件</a-button>
              </a-upload>
            </a-form-item>
          </a-col>
        </a-row> -->
        <!-- <a-row :gutter="12">
          <a-col :span="24">
            <a-form-item label="视频文件：">
              <a-upload
                  name="file"
                  :multiple="false"
                  :beforeUpload="beforeUploadVideo"
                  accept=".mp4,.flv,.avi"
                  :customRequest="resourceUploadVideo"
                  :file-list="videoFileList"
                  :remove="removeVideo"
              >
                  <a-button style="margin-left:10px" type="primary" >上传视频文件</a-button>
                  <span style="margin-left:10px"><strong style="font-size:15px;color:blue">温馨提示：</strong><span style="font-size:13px">视频大小请控制在10M以内！</span></span>
              </a-upload>

            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="12">
          <a-col :span="24">
            <a-form-item label="内容文件说明：">
              <tinymce ref="tinymceEditor" :value="obj.fileContent" @tinymceClick="tinymceClick" @input="contentInput"></tinymce>
            </a-form-item>
          </a-col>
        </a-row> -->
        <a-row :gutter="12">
          <a-col :span="24">
            <a-form-item label="备注说明：">
              <a-textarea
                v-decorator="['remark']"
                :rows="4"
                placeholder="备注说明"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button type="primary" @click="handleSubmit()">
          保存
        </a-button>
        <a-button :style="{ marginLeft: '8px' }" @click="onClose">
          取消
        </a-button>
      </div>
    </a-drawer>
  </div>
</template>
<script>
import { ATTACHMENT_UPLOAD,FIND_ALL_OFFICE_LIST,FIND_PROJECT_BY_OFFICEID,QUERY_OFFICE_BY_USER } from "@/services/api";
import { request } from "@/utils/request";
// import Tinymce from "@/components/tinymce"


export default {
  name: "setting",
  components: {},
  // filters: {
  //   statusStr(val) {
  //     switch (val) {
  //       case 0: return '会议直播'
  //       case 1: return '直播带货'
  //       case 2: return '直播秀'
  //       case 3: return '其他'
  //     }
  //   }
  // },
  data() {
    return {
      form: this.$form.createForm(this),
      obj:{},
      defaultFileList:[],
      officeName:'',
      officeId:'',
      projectDataSource:[],
      contentFileList:[],
      videoFileList:[],
      fileTypes:[],
      officeDataSource:[],
      childOffice:[],
      isChild:false
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    templFile: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  created(){
    this.fileTypes = [];
    var contract = {
      key:'WORK_CONTRACT_TEPL',
      text:'合同模板'
    }
    this.fileTypes.push(contract)
    var EPIDEMIC_LETTER_COMMITMENT = {
      key:'EPIDEMIC_LETTER_COMMITMENT',
      text:'疫情防控承诺书'
    }
    this.fileTypes.push(EPIDEMIC_LETTER_COMMITMENT)

    var WORK_RCXZ_TEMPL = {
      key:'WORK_RCXZ_TEMPL',
      text:'入场须知'
    }
    this.fileTypes.push(WORK_RCXZ_TEMPL)
    var THREE_AQJY_TEPL_ALL = {
      key:'THREE_AQJY_TEPL_ALL',
      text:'总的三级安全教育'
    }
    this.fileTypes.push(THREE_AQJY_TEPL_ALL)
    var WORK_EPIDEMIC_DOCUMENT = {
      key:'WORK_EPIDEMIC_DOCUMENT',
      text:'疫情一人一档资料'
    }
    this.fileTypes.push(WORK_EPIDEMIC_DOCUMENT)
    var SALARY_LETTER_COMMITMENT = {
      key:'SALARY_LETTER_COMMITMENT',
      text:'工人承诺书'
    }
    this.fileTypes.push(SALARY_LETTER_COMMITMENT)
    var WORK_ONE_PERSON_DOCUMENT = {
      key:'WORK_ONE_PERSON_DOCUMENT',
      text:'工人入场一人一档资料'
    }
    this.fileTypes.push(WORK_ONE_PERSON_DOCUMENT)
    this.officeSearch();
  },
  mounted(){
  },
  watch:{
    templFile(val){
      this.$nextTick(()=>{
        this.obj = val;
        let officeIds = [];
        this.officeDataSource = [];
        if(this.obj.officeId!=null){
          this.findProjectByOffice(this.obj.officeId);
          this.queryOfficeByParentId(this.obj.officeId);
          officeIds.push(this.obj.officeId);
          let objx ={
            id:this.obj.officeId
          }
          request(FIND_ALL_OFFICE_LIST,"post",objx).then(result => {
              if (result.data.code==0) {
                result.data.data.forEach((o) => {
                  o.key = o.id;
                  this.officeDataSource.push(o)
                });
              }else{
                this.$message.warning(result.message);
              }
          })
        }
        this.form.setFieldsValue({
            'title': this.obj.title,
            'fileType': this.obj.fileType,
            'statusBoolean': this.obj.status==1?true:false,
            'sqlStr': this.obj.sqlStr,
            'columnSet': this.obj.columnSet,
            'remark': this.obj.remark,
            'officeIds':officeIds,
            'projectId':this.obj.projectId,
            'bussType':this.obj.bussType?this.obj.bussType:1,
            'isChild':this.obj.isChild==1?true:false,
            'childIds':this.obj.childIds?this.obj.childIds.split(','):null,
            'fileMoreTypes':this.obj.fileMoreTypes?this.obj.fileMoreTypes.split(','):null
        })
        if(this.obj.isChild){
          this.isChild = true
        }else{
          this.isChild = false
        }
        if(val.id){
          if(this.obj.filePath){
              var fileObj = {
              uid: '1',
              name: this.obj.title+".docx",
              url: this.obj.filePath,
            }
            this.defaultFileList = [];
            this.defaultFileList.push(fileObj);
          }
          // if(this.obj.fileContent){
          //   var contentObj = {
          //     uid: '1',
          //     name: this.obj.title+"-内容文件",
          //     url: this.obj.fileContent,
          //   }
          //   this.contentFileList = [];
          //   this.contentFileList.push(contentObj);
          // }
          if(this.obj.fileVideo){
            var videoObj = {
              uid: '1',
              name: this.obj.title+"-视频文件",
              url: this.obj.fileVideo,
            }
            this.videoFileList = [];
            this.videoFileList.push(videoObj);
          }
        }
      })
    }
  },
  methods: {
    dis(){
      // console.log('1111',this.form)
      let project = this.form.getFieldsValue(['projectId']);
      if(project&&project.projectId){
        return true;
      }else{
        return false;
      }
    },
    tinymceClick(e){
      console.log(e)
    },
    /**
     * 取消审批
     */
    onClose() {
      // this.$refs.tinymceEditor.destory();
      this.$emit('close');
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.indexOf(input) >= 0
      );
    },
    removeCont(file){
      this.handleRemove(file,2)
    },
    removeVideo(file){
      this.handleRemove(file,3)
    },
    handleRemove(file,type){
      let index = 0;
      // console.log(file)
      // if(type==2){
      //   index = this.contentFileList.findIndex(function(data) {
      //     return data.url == file.url;
      //   });
      //   const newFileList = this.contentFileList.slice();
      //   newFileList.splice(index, 1);
      //   this.contentFileList = newFileList;
      //   this.obj.fileContent = null;
      // }
      if(type==3){
        index = this.videoFileList.findIndex(function(data) {
          return data.url == file.url;
        });
        const newFileList = this.videoFileList.slice();
        newFileList.splice(index, 1);
        this.videoFileList = newFileList;
        this.obj.fileVideo = null;
      }
    },

    handleChange(value){
      this.projectDataSource=[];
      if(value){
        this.findProjectByOffice(value);
        this.queryOfficeByParentId(value)
      }

    },

    findProjectByOffice(officeId){
      request(FIND_PROJECT_BY_OFFICEID+officeId,"get",null).then(result => {
          if (result.data.code==0) {
            this.projectDataSource = result.data.data;
            this.projectDataSource.forEach((obj) => {
              obj.key = obj.id;
            });
          }else{
            this.$message.warning(result.message);
          }
      })
    },
    /**
     * 保存信息
     */
    handleSubmit(e){
      //数据校验
      this.form.validateFields((err, values) => {
        if (!err) {
          //保存结果数据
          if(values.officeIds!=null&&values.officeIds.length>0){
            values.officeId = values.officeIds[0]
          }
          // if(this.contentFileList!=null&&this.contentFileList.length>0){
          //   values.fileContent = this.contentFileList[0].url
          // }
          if(this.videoFileList!=null&&this.videoFileList.length>0){
            values.fileVideo = this.videoFileList[0].url
          }
          if(this.defaultFileList!=null&&this.defaultFileList.length>0){
            values.filePath = this.defaultFileList[0].url
          }else{
            this.$message.error("请上传模板文件！");
            return
          }
          this.obj.isChild = this.isChild?1:0;
          console.log(values);
          if(values.fileMoreTypes&&values.fileMoreTypes.length>0){
            values.fileMoreTypes = values.fileMoreTypes.join(',');
            this.$set(values,'templType',1);
          }else{
            this.$set(values,'templType',0);
          }
          Object.assign(this.obj,values);
          // console.log(this.obj);
          delete this.obj["createdTime"];
          // this.$refs.tinymceEditor.destory();
          this.$emit('submit',{templFile:this.obj})
        }
      });
    },

    beforeUploadTempl(file){
      this.beforeUpload(file,1);
    },
    beforeUploadCont(file){
      this.beforeUpload(file,2);
    },
    beforeUploadVideo(file){
      this.beforeUpload(file,3);
    },

    projectChange(value){
      console.log(value);
      // if(value){
      //   this.isChild = false;
      //   this.form.setFieldsValue({
      //       'childIds':''
      //   })
      // }
      // this.dis();
    },
    /**
    * 上传之前
    */
    beforeUpload (file,type) {
      return new Promise((resolve, reject) => {
        const isFiveM= file.size / 1024 / 1024 < 200 //小于5M的图片上传
        //判断文件是否符合正则表达式的规则
        if (!isFiveM) {
          this.$message.error('上传资源不大于200M！');
          return reject(false)
        }
        let cont = "模板";
        if(type==1){
          cont = "模板";
        }
        if(type==2){
          cont = "内容";
        }
        if(type==3){
          cont = "视频";
        }
        if(this.obj.id){
          this.$confirm({
            title: '',
            content: h => <div style="color:red;">重新上传会将原有的{cont}覆盖，是否继续上传?</div>,
            onOk() {
              return resolve(true);
            },
            onCancel() {
              return reject(false);
            },
            class: 'test',
          });
        }else{
          return resolve(true);
        }

      });
    },
    /**
    * 资源上传
    */
    resourceUpload(value,type){
      const formData = new FormData();
      formData.append('file', value.file);
      request(ATTACHMENT_UPLOAD,"post",formData).then(result => {
          if (result.data.code==0) {
            this.obj.filePath = result.data.data.path;
            var fileObj = {
              uid: '1',
              name: result.data.data.name,
              url: this.obj.filePath,
            }
            if(type==1){
              this.defaultFileList = [];
              this.defaultFileList.push(fileObj);
            }
            if(type==2){
              this.contentFileList = [];
              this.contentFileList.push(fileObj);
            }
            if(type==3){
              this.videoFileList = [];
              this.videoFileList.push(fileObj);
            }
            value.onSuccess(result, value.file);
          }else{
            this.$message.warning(result.message);
          }
      })
    },
    resourceUploadTempl(value){
      this.resourceUpload(value,1)
    },
    resourceUploadCont(value){
      this.resourceUpload(value,2)
    },
    resourceUploadVideo(value){
      this.resourceUpload(value,3)
    },
    officeSearch(value){
      let obj ={
        name:value,
        id:this.obj.officeId
      }
      request(FIND_ALL_OFFICE_LIST,"post",obj).then(result => {
          if (result.data.code==0) {
            this.officeDataSource = result.data.data;
            this.officeDataSource.forEach((obj) => {
              obj.key = obj.id;
            });
          }else{
            this.$message.warning(result.message);
          }
      })
    },
    contentInput(e){
      this.obj.fileContent = e;
    },

    //查询子企业
    queryOfficeByParentId(value){
      let obj ={
        parentOfficeId:value
      }
      request(QUERY_OFFICE_BY_USER,"get",obj).then(result => {
          if (result.data.code==0) {
            this.childOffice = result.data.data;
            this.childOffice.forEach((obj) => {
              obj.key = obj.id;
            });
          }else{
            this.$message.warning(result.message);
          }
      })
    },
  },
};
</script>
<style>
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
/deep/ .ant-upload-list-picture-card .ant-upload-list-item{
  width: 127px;
  height: 127px;
}
</style>
