import { ADMIN, PROJECT_MSG_GET_LIST,AQXJ_PROJECTS_INSERT,AQXJ_PROJECTS_GETLIST , GETOFFICELIST , AQXJ_PROJECTS_DETAIL ,AQXJPROJECTSUPDATE ,QXJ_PROJECTS_DEL,
    GETAPPROVEPERSONLIST, GETAPPROVEPERSONLISTBYPROJECTID,TEAMPERSONAPPROVE,BATCH_MODIFYPERSON_TEAMID,GET_LW_PROJECT_LIST,INSET_LW_PROJECT,GET_LW_PROJECT_DETAIL,
    GET_SAFE_PROJECT_DETAIL,GET_LW_PROJECT_ALL,ADD_LW_PROJECT,GET_LW_TREE_OFFICE,SAVE_LW_PROJECT,DELETE_LW_PROJECT,UPDATE_LW_PROJECT,GET_LW_DETAIL,ADD_OFFICE_MASTER,DELETE_OFFICE_MASTER,
    GET_OFFICE_MASTER,GET_FKSET_LIST,GET_FKSET_DETAIL,SAVE_FKSET,EDIT_FKSET,DELETE_CERTIFICATE_TYPE,UPDATE_CERTIFICATE_TYPE,GET_CERTIFICATE_TYPE,
    ADD_CERTIFICATE_TYPE, EXIT_APPROVAL_LIST, GET_NO_CLOSE_WARN,GET_OFFICES_LIST,ADD_OFFICES,EDIT_OFFICES,DEL_OFFICES,GET_OFFICES_DETAIL,GET_OFFICES_TREE,
    GET_OFFICES_TEAMS,GET_PROJECT_OFFICE, SYNC_PROJECT_TO_HK,EDIT_OFFICE_FKSET,GET_PRO_WARN_LIST,GET_PRO_TEAM_DATA,GET_PRO_TEAM_KQ,GET_PRO_MANGER_DATA,GET_PRO_STAFF_DATA,
    GET_PRO_PAYROLL_DATA,GET_PRO_DEVICE_DATA,GET_PRO_SEVEN_DAYS, PROJ_CONTACTS, PROJECT_NAME_HISTORY_INFO} from '@/services/api'
import {request, METHOD} from '@/utils/request'
// const BASE_URL = process.env.VUE_APP_API_BASE_URL
/**
 *  获取项目查询类表
 * @returns {Promise<AxiosResponse<T>>}
 */
 export async function projectMsgGetList(params) {
    return request(PROJECT_MSG_GET_LIST, METHOD.POST, params)
}

/**
 *  同步项目
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function syncProjectToHk(params) {
    return request(SYNC_PROJECT_TO_HK + `?projectId=${params.projectId}&projectNum=${params.projectNum}`, METHOD.GET)
}

/**
 *  退场审批列表查询
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function exitApprovalGetList(params) {
    return request(EXIT_APPROVAL_LIST, METHOD.POST, params)
}

/**
 *  项目名称记录
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getProjectNameHistoryInfo(params) {
    return request(PROJECT_NAME_HISTORY_INFO, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
 export async function getProjContacts(params) {
    return request(PROJ_CONTACTS, METHOD.POST, params)
}

/**
 *  获取建设单位
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getofficelist(params) {
    return request(GETOFFICELIST, METHOD.POST, params)
}
//
/**
 *  新增项目
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function aqxjprojectsinsert(params) {
    return request(AQXJ_PROJECTS_INSERT, METHOD.POST, params)
}

//
/**
 *  修改更新项目
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function aqxjProjectsupdate(params) {
    return request(AQXJPROJECTSUPDATE, METHOD.POST, params)
}
// AQXJ_PROJECTS_GETLIST
/**
 *  查询列表
 * @returns {Promise<AxiosResponse<T>>}
 */
 export async function aqxjprojectsgetlist(params) {
    return request(AQXJ_PROJECTS_GETLIST, METHOD.POST, params)
}
//
/**
 *  查询详情
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function aqxjprojectsdetail(params) {
    return request(AQXJ_PROJECTS_DETAIL + `/${params}`, METHOD.GET)
}
/**
 *  删除
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function aqxjprojectsdel(params) {
    return request(QXJ_PROJECTS_DEL + `/${params}`, METHOD.DELETE)
}
/**
 *  查询待审核人员列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getApprovePersonList(params) {
    return request(GETAPPROVEPERSONLIST, METHOD.POST, params)
}
/**
 *  查询待审核人员个人申请列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getApprovePersonListByProjectId(params) {
    return request(GETAPPROVEPERSONLISTBYPROJECTID, METHOD.POST, params)
}
/**
 *  查询列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function teamPersonapprove(params) {
    return request(TEAMPERSONAPPROVE, METHOD.POST, params)
}
/**
 *  劳务员批量修改劳工申请所属的班组
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function batchModifyPersonTeamId(params) {
    return request(BATCH_MODIFYPERSON_TEAMID, METHOD.POST, params)
}

/**
 *  劳务系统项目管理
 * @returns {Promise<AxiosResponse<T>>}
 */

/**
 *  获取项目查询类表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getLwProjectList(params) {
    return request(GET_LW_PROJECT_LIST, METHOD.POST, params)
}

/**
 *  获取项目查询类表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function addLwProject(params) {
    return request(INSET_LW_PROJECT, METHOD.POST, params)
}

/**
 *  获取项目详情
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getLwDetail(params) {
    return request(GET_LW_PROJECT_DETAIL + `/${params.projectId}`, METHOD.GET)
}

/**
 *  获取机施项目详情
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getSafeDetail(params) {
    return request(GET_SAFE_PROJECT_DETAIL + `/${params.projectId}`, METHOD.GET)
}

/**
 *  获取劳务系统所有项目列表msg
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getLwProjectAll(params) {
    return request(GET_LW_PROJECT_ALL, METHOD.POST,params)
}

/**
 *  新增劳务项目
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function addLwProjects(params) {
    return request(ADD_LW_PROJECT, METHOD.POST, params)
}

/**
 *  获取公司树形结构
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getTreeOffice(params) {
    return request(GET_LW_TREE_OFFICE, METHOD.POST, params)
}

/**
 *  保存或者需修改劳务项目接口
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function saveLwProject(params) {
    return request(SAVE_LW_PROJECT, METHOD.POST, params)
}

/**
 *  保存或者需修改劳务项目接口
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function updateLwProject(params) {
    return request(UPDATE_LW_PROJECT, METHOD.POST, params)
}

/**
 *  删除劳务项目
 * @Id 参数
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function deleteLwProject(params) {
    return request(DELETE_LW_PROJECT + `/${params.id}`, METHOD.DELETE)
}

/**
 *  获取劳务项目详情
 * @Id 参数
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getLwProjectDetail(params) {
    return request(GET_LW_DETAIL + `/${params.id}`, METHOD.GET)
}

/**
 *  创建公司机构
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function addOfficeMaster(params) {
    return request(ADD_OFFICE_MASTER, METHOD.POST, params)
}

/**
 *  删除公司机构
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function delOfficeMaster(params) {
    return request(DELETE_OFFICE_MASTER + `/${params.id}`, METHOD.DELETE)
}

/**
 *  公司机构详情
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getOfficeMaster(params) {
    return request(GET_OFFICE_MASTER + `/${params.officeId}`, METHOD.GET)
}

/**
 *  项目风控列表
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getFksetList(params) {
    return request(GET_FKSET_LIST, METHOD.POST,params)
}

/**
 *  获取项目风控详情
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getFksetDetail(params) {
    return request(GET_FKSET_DETAIL + `/${params.projectId}`, METHOD.GET)
}

/**
 *  新增项目风控
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function saveFkset(params) {
    return request(SAVE_FKSET, METHOD.POST,params)
}

/**
 *  修改项目风控
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function editFkset(params) {
    return request(EDIT_FKSET, METHOD.POST,params)
}

/**
 *  新增参建单位项目风控
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function saveOfficeFkset(params) {
    return request(EDIT_OFFICE_FKSET, METHOD.POST,params)
}

/**
 *  @id 参数id
 *  删除证书类型
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function deLCertificateType(params) {
    return request(DELETE_CERTIFICATE_TYPE +`/${params.id}`, METHOD.DELETE)
}

/**
 *  @id 参数id
 *  新增证书类型
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function addCertificateType(params) {
    return request(ADD_CERTIFICATE_TYPE, METHOD.POST,params)
}

/**
 *  修改证书类型
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function editCertificateType(params) {
    return request(UPDATE_CERTIFICATE_TYPE, METHOD.POST,params)
}

/**
 *  @id 证书ID
 *  获取证书详情
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getCertificateType(params) {
    return request(GET_CERTIFICATE_TYPE + `/${params.id}`, METHOD.GET)
}

/**
 *  获取建委预警数据
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getNoCloseWarnList(params) {
    return request(GET_NO_CLOSE_WARN, METHOD.POST,params)
}

/**
 *  获取企业库列表
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getOfficesList(params) {
    return request(GET_OFFICES_LIST, METHOD.POST,params)
}

/**
 *  新增企业库
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function addOffice(params) {
    return request(ADD_OFFICES, METHOD.POST,params)
}

/**
 *  修改企业库
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function editOffice(params) {
    return request(EDIT_OFFICES, METHOD.POST,params)
}

/**
 *  删除企业库
 * @id id
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function delOffice(params) {
    return request(DEL_OFFICES + `/${params.id}`, METHOD.DELETE)
}

/**
 *  获取企业详情
 * @id id
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getOfficeDetail(params) {
    return request(GET_OFFICES_DETAIL + `/${params.id}`, METHOD.GET)
}

/**
 *  企业获取树形结构
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getOfficeTree(params) {
    return request(GET_OFFICES_TREE , METHOD.POST,params)
}

/**
 *  获取公司的班组列表
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getOfficeTeams(params) {
    return request(GET_OFFICES_TEAMS , METHOD.POST,params)
}

/**
 *  获取公司的参建项目列表
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getOfficeProject(params) {
    return request(GET_PROJECT_OFFICE , METHOD.POST,params)
}

/**
 *  获取项目建委预警列表
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getProWarnList(params) {
    return request(GET_PRO_WARN_LIST , METHOD.POST,params)
}

/**
 *  首页班组出勤情况
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getProTeamData(params) {
    return request(GET_PRO_TEAM_DATA , METHOD.POST,params)
}

/**
 *  查询班组考勤排名
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getProTeamKq(params) {
    return request(GET_PRO_TEAM_KQ , METHOD.POST,params)
}

/**
 *  获取管理人员数据
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getProMangerData(params) {
    return request(GET_PRO_MANGER_DATA , METHOD.GET,params)
}

/**
 *  获取工人数据
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getProStaffData(params) {
    return request(GET_PRO_STAFF_DATA , METHOD.GET,params)
}

/**
 *  获取工资数据
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getProPayrollData(params) {
    return request(GET_PRO_PAYROLL_DATA , METHOD.GET,params)
}

/**
 *  获取设备数据
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getProDeviceData(params) {
    return request(GET_PRO_DEVICE_DATA , METHOD.GET,params)
}

/**
 *  获取近七日出勤数据
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getProSevenDays(params) {
    return request(GET_PRO_SEVEN_DAYS , METHOD.POST,params)
}

/**
 * 电子合同记录-电子签审批驳回
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function setEssOfficeReject(params) {
    return request(ADMIN + `/labour/ess/officeReject`, METHOD.POST, params)
}

/**
 * 电子合同记录-电子签确认信息
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function setEssProSure(params) {
    return request(ADMIN + `/labour/ess/projSure`, METHOD.POST, params)
}

/**
 * 电子合同记录-电子签操作记录
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getEsscContractDoLog(params) {
    return request(ADMIN + `/labour/essContractDoLog/list`, METHOD.POST, params)
}

/**
 * 电子合同记录-线下签署
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function setEssDoOffSign(params) {
    return request(ADMIN + `/labour/ess/doOffSign`, METHOD.POST, params)
}

/**
 * 电子合同记录-作废合同
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function setEssDoInvalid(params) {
    return request(ADMIN + `/labour/ess/doInvalidated`, METHOD.POST, params)
}

/**
 * 部门管理-删除部门
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function deleteDept(params) {
    return request(ADMIN + `/system/dept/delete`, METHOD.DELETE, params)
}