<template>
    <div class="a_Approval">
        <a-form-model :model="params" class="clearfix" @submit="handleSubmit">
            <a-col :span="4" v-if="!isProject && !isBranchOffice">
                <a-form-model-item label="分公司" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">

                    <a-select v-model="params.branchOfficeId" allowClear placeholder="">
                        <!-- <a-select-option value="">全部</a-select-option> -->
                        <a-select-option v-for="item in onlyGcglbList" :key="item.value" :value="item.value"
                            :title="item.label">
                            {{ item.label }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
            </a-col>
            <a-col :span="5" v-if="!isProject">
                <a-form-model-item label="项目" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
                    <a-input v-model="params.projectName" allow-clear />
                </a-form-model-item>
            </a-col>
            <a-col :span="4">
              <a-form-model-item label="预警来源" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 0 }">
                <a-select v-model="params.warnSourceId" allowClear placeholder="" style="width: 180px">
                  
                  <a-select-option v-for="item in options" :key="item.label" :value="item.value" :title="item.label">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="4">
                <a-form-model-item label="预警时间" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 0 }">

                    <a-date-picker v-model="params.warnTime" valueFormat="YYYY-MM-DD" style="width: 140px" placeholder="" />
                </a-form-model-item>
            </a-col>
            


            <a-col :span="4">
                <a-space class="btnBox">
                    <a-button type="primary" html-type="submit"> 查询 </a-button>
                    <a-button @click="resetQuery"> 重置 </a-button>
                </a-space>
            </a-col>
        </a-form-model>
        <standard-table :columns="columns" :dataSource="tableData" rowKey="id" :pagination="pagination"
            :loading="tableLoading" @change="handleChangePage" :isAlert="false" :bordered="true" :btnArr="tableBtnArr"
             :scroll="{ x: 1400 }">
            <!-- @selectedRowChange="handleSelectedRowsChange" :totalBoolean="true" :rowColor="false" -->
            <template slot="index" slot-scope="{ index }">
                <span>{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}</span>
            </template>
            
            <template slot="isFree" slot-scope="{ record }">
                <a-tag color="#108ee9" v-if="record.isFree == 0">有效</a-tag>
                <a-tag color="#f50" v-if="record.isFree == 1">无效</a-tag>
            </template>

            <template slot="action" slot-scope="{ record }">
                <div class="sTables">
                    <div class="y-edit" v-if="record.warnSourceId == 2||record.warnSourceId == 1"   @click="handleView(record)">查看</div>
                    <!-- <div class="y-del" @click="handleDel(record)">删除</div> -->
                </div>
            </template>
        </standard-table>
        <csdetail :visible.sync="visibleCs" :projectInfo="projectInfo" :isEdit="isEdit" :id="id" @closeDialog="closeDialogCs" @refresh="handleQuery" />
        <cldetail :visible.sync="visibleCl" :projectInfo="projectInfo" :isEdit="isEdit" :id="id" @closeDialog="closeDialogCl" @refresh="handleQuery" />
        <pmdetail :visible.sync="visiblePm" :projectInfo="projectInfo" :isEdit="isEdit" :id="id" @closeDialog="closeDialogPm" @refresh="handleQuery" />

    </div>
</template>

<script>

import {
    constructplanList, constructplanDelete, constructplanExport,earlywarnList
} from "@/services/dangerousProject";

import { mapGetters } from "vuex";
import StandardTable from "@/components/table/StandardTable";
import moment from "moment";
import csdetail from "./components/csDetail.vue";
import pmdetail from "./components/pmDetail.vue";
import { getProjectDetail } from "@/services/equipmentLedger";
import cldetail from "@/pages/riskControll/components/clDetail.vue";
import mixin from './components/mixinEw'

export default {
    name: "emerTeam",
    components: {
        StandardTable,
        csdetail,
        pmdetail,
        cldetail
    },
    mixins: [mixin],
    data() {
        return {
            params: {
                "companyName": "", //公司名称
                companyId: "", //公司ID
                projectId: "", //项目ID
                "projectName": "", //项目名称
                warnSourceId: "", //预警来源ID
                warnTime: "", //预警时间
                "pageNo": "1", //页码
                "pageSize": "10", //每页条数
                branchOfficeId: "", //分公司ID
            },
            pagination: {
                //分页数据
                current: 1,
                pageSize: 10,
                total: 0,
                showTotal: (total, range) => `共 ${total} 条`,
            },
            total: 0,
            tableData: [],
            tableLoading: false, //table加载中
            selectedRows: [],
            tmpSelectedRows: [],

            visible: false,
            isEdit: 0,
            id: "",
            projectInfo: {
                parentOffice: '',
                parentOfficeId: '',
                gcglbId: '',
                gcglbName: '',
                projectId: '',
                projectName: ''
            },

            visibleCs:false,
            visiblePm:false,
            visibleCl:false,
        };
    },
    created() {
        if (!this.isSafe) {
            // this.columns = this.columns.filter(el => el.title != '操作')
        }
        this.handleQuery();

        if(this.isProject) {
            let d = this.user.loginLaoWuUser.projectId
            getProjectDetail(d).then(res => {

                if (res.data.code === 0) {
                    
                    this.projectInfo.parentOfficeId = res.data.data.parentOfficeId;
                    this.projectInfo.parentOffice = res.data.data.parentOffice;
                    this.projectInfo.gcglbId = res.data.data.gcglbId;
                    this.projectInfo.gcglbName = res.data.data.gcglbName;
                    this.projectInfo.projectId = res.data.data.projectId;
                    this.projectInfo.projectName = res.data.data.projectName;
                    
                    this.params.projectId = this.projectInfo.projectId;
                    // this.params.companyId = this.projectInfo.parentOfficeId;
                    // this.params.branchOfficeId = this.projectInfo.gcglbId;
                } 

                this.handleQuery();
            })
        } else {
            this.params.branchOfficeId = this.user.officeId;
            this.handleQuery();
        }
    },
    computed: {
        tableBtnArr() {
            return [
                // {
                //     name: '新增',
                //     attrs: {
                //         type: 'primary'
                //     },
                //     // hidden: !this.isSafe,
                //     click: this.addNewComplaint
                // },
                // {
                //     name: '导出',
                //     attrs: {
                //         type: 'primary'
                //     },
                //     // hidden: !this.isJSOffice,
                //     click: this.bathDownload
                // }
            ]
        }
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault();
            this.params.pageNo = 1
            this.pagination.current = 1
            this.handleQuery();
        },
        handleQuery(e) {
            this.tableData = []
            this.tableLoading = true
            let d = this.params;
            d.warnTime = d.warnTime? moment(d.warnTime).format('YYYY-MM-DD') : ''

            earlywarnList(d).then((res) => {
                this.tableLoading = false
                if (res.data.code == 0) {
                    let list = res.data.data.list;

                    this.tableData = list.map((item, index) => {
                        
                        return {
                            ...item,
                        };
                    })
                    this.pagination.total = res.data.data.total;
                } else {
                    this.$message.error(res.data.msg)
                }
            });
        },
        handleChangePage(e) {
            this.params.pageNo = e.current;
            this.params.pageSize = e.pageSize;
            this.pagination.current = e.current;
            this.handleQuery();
        },
        addNewComplaint() {
            this.isEdit = 0
            this.id = ''
            this.visible = true
        },
        handleEdit(e) {

            this.isEdit = 1
            this.id = e.id
            // this.visible = true
        },
        handleView(e) {
            console.log(e)
            this.isEdit = 2
            this.id = e.parentId
            // if(e.warnSourceId == 3) {
            //     // 专项施工方案
            //     this.visibleCs = true
            // }
            if(e.warnSourceId == 2) {
                // 危大工程管理
                this.visiblePm = true
            }
            if(e.warnSourceId == 1) {
                // 施工方案
                this.visibleCl = true
            }
        },
        handleDel(record) {
            const _that = this
            this.$confirm({
                title: '是否确定删除？',
                cancelText: '否',
                okText: '是',
                onOk: () => {
                    constructplanDelete({ id: record.id }).then(res => {
                        if (res.data.code == 0) {
                            _that.$message.success('删除成功')
                            this.handleQuery(this.params);
                        } else {
                            _that.$message.error(res.data.msg)
                        }
                    })
                },
            })
        },
        resetQuery() {
            this.pagination.current = 1
            this.params = {
                "companyName": "", //公司名称
                companyId: "", //公司ID
                projectId: "", //项目ID
                "projectName": "", //项目名称
                warnSourceId: "", //预警来源ID
                warnTime: "", //预警时间
                "pageNo": "1", //页码
                "pageSize": "10", //每页条数
            };
            if(this.isProject) {
                this.params.projectId = this.projectInfo.projectId;
                // this.params.companyId = this.projectInfo.parentOfficeId;
                // this.params.branchOfficeId = this.projectInfo.gcglbId;
            } else {
                // 公司  分公司
                this.params.branchOfficeId = this.user.officeId;
            }
            this.handleQuery();
        },
        bathDownload() {
            // 取查询参数导出 companyId=&branchOfficeId=&projectId=&sgfaName=&overRange=&proveTime=&aproveTime=&makeTime=
            let url = constructplanExport + '?'+ `companyId=${this.params.companyId}&branchOfficeId=${this.params.branchOfficeId}&projectId=${this.params.projectId}&sgfaName=${this.params.sgfaName}&overRange=${this.params.overRange}&proveTime=${this.params.proveTime}&aproveTime=${this.params.aproveTime}&makeTime=${this.params.makeTime}`
            window.open(url)
        },
        closeDialog() {
            this.visible = false
        },
        closeDialogCs() {
            this.visibleCs = false
        },
        closeDialogPm() {
            this.visiblePm = false
        },
        closeDialogCl() {
            this.visibleCl = false
        },

        

    },
};
</script>

<style lang="less" scoped>
.query-form {
    display: flex;
    // justify-content: space-between;
}

.post-border {
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 5px;
    padding-top: 5px;
    // padding-top: 10px;
    // height: 40px;
}

.post-border:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

.sTables {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.y-edit {
    color: #307dfa;
    cursor: pointer;
}
.y-del {
    color: #ff5c77;
    cursor: pointer;
}
.y-other {
    color: #615e83;
    cursor: pointer;
}
</style>