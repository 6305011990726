export default {

    '3:2': '3:2',
    '4:3': '4:3',
    '5:4': '5:4',
    '7:5': '7:5',
    '16:9': '16:9',
    'Apply': '涂',
    'Arrow': '箭头',
    'Arrow-2': '箭头-2',
    'Arrow-3': '箭头-3',
    'Blend': '混合',
    'Blur': '模糊',
    'Bold': '加粗',
    'Brightness': '亮度',
    'Bubble': '气泡',
    'Cancel': '取消',
    'Center': '中心',
    'Circle': '圆',
    'Color': '颜色',
    'Color Filter': '颜色过滤',
    'Crop': '裁剪',
    'Custom': '自定义',
    'Custom icon': '自定义图标',
    'Delete': '删除',
    'Delete-all': '全部删除',
    'Distance': '距离',
    'Download': '下载',
    'Draw': '绘制',
    'Emboss': '浮雕',
    'Fill': '填充',
    'Filter': '滤镜',
    'Flip': '翻转',
    'Flip X': '水平翻转',
    'Flip Y': '垂直翻转',
    'Free': '自由',
    'Grayscale': '灰度',
    'Heart': '心',
    'Icon': '图标',
    'Invert': '反转',
    'Italic': '斜体',
    'Left': '左',
    'Load': '加载图片',
    'Load Mask Image': '加载遮罩图像',
    'Location': '位置',
    'Mask': '遮罩',
    'Multiply': '乘',
    'Noise': '噪音',
    'Pixelate': '像素化',
    'Polygon': '多边形',
    'Range': '范围',
    'Rectangle': '矩形',
    'Redo': '恢复',
    'Remove White': '去白',
    'Reset': '重置',
    'Right': '右',
    'Rotate': '旋转',
    'Sepia': '色调',
    'Sepia2': '色调2',
    'Shape': '形状',
    'Sharpen': '锐化',
    'Square': '正方形',
    'Star-1': '星-1',
    'Star-2': '星-2',
    'Straight': '直线',
    'Stroke': '边框',
    'Text': '文本',
    'Text size': '文本大小',
    'Threshold': '阈值',
    'Tint': '色调',
    'Triangle': '三角形',
    'Underline': '下划线',
    'Undo': '撤销',
    'Value': '值',
    'Select': '选择',
    
}