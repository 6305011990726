var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a_Approval" },
    [
      _c(
        "a-tabs",
        {
          attrs: {
            "default-active-key": _vm.defaultActiveKey,
            activeKey: _vm.activeKey,
          },
          on: { tabClick: _vm.tabClick },
        },
        [
          _c(
            "a-tab-pane",
            { key: "1", attrs: { tab: "基本信息" } },
            [
              _c("base-info", {
                ref: "baseInfo",
                attrs: { gcglbList: _vm.gcglbList },
              }),
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "2", attrs: { tab: "使用登记记录(年/次)" } },
            [
              _c("use-record", {
                ref: "useRecord",
                attrs: { gcglbList: _vm.gcglbList },
              }),
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "3", attrs: { tab: "检验记录(年/次)" } },
            [
              _c("inspection-record", {
                ref: "inspectionRecord",
                attrs: { gcglbList: _vm.gcglbList },
              }),
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "4", attrs: { tab: "维保记录(月/次)" } },
            [
              _c("maintenance-record", {
                ref: "maintenanceRecord",
                attrs: { gcglbList: _vm.gcglbList },
              }),
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "5", attrs: { tab: "定期自检记录(月/次)" } },
            [
              _c("self-inspection-record", {
                ref: "seflInspectionRecord",
                attrs: { gcglbList: _vm.gcglbList },
              }),
            ],
            1
          ),
          _vm.facilityType == 2
            ? _c(
                "a-tab-pane",
                { key: "6", attrs: { tab: "防坠器检验记录(年/次)" } },
                [
                  _c("falling-check-record", {
                    ref: "fallingCheckRecord",
                    attrs: { gcglbList: _vm.gcglbList },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.facilityType == 2
            ? _c(
                "a-tab-pane",
                { key: "7", attrs: { tab: "防坠器试验记录(三个月/次)" } },
                [
                  _c("falling-record", {
                    ref: "fallingRecord",
                    attrs: { gcglbList: _vm.gcglbList },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }