var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "hot-search" },
    [
      _c(
        "a-row",
        { staticStyle: { margin: "0 -34px" } },
        [
          _c(
            "a-col",
            {
              staticStyle: { padding: "0 34px", "margin-bottom": "24px" },
              attrs: { sm: 12, xs: 24 },
            },
            [
              _c("div", { staticClass: "num-info" }, [
                _c(
                  "span",
                  { staticClass: "title" },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("search")) + " "),
                    _c(
                      "a-tooltip",
                      { attrs: { title: _vm.$t("introduce") } },
                      [
                        _c("a-icon", {
                          staticStyle: {
                            "font-size": "14px",
                            "margin-left": "8px",
                          },
                          attrs: { type: "info-circle" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "value" }, [
                  _c("span", { staticClass: "total" }, [_vm._v("12321")]),
                  _c(
                    "span",
                    { staticClass: "subtotal" },
                    [
                      _vm._v("71.2"),
                      _c("a-icon", { attrs: { type: "caret-up" } }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("mini-area", { staticStyle: { height: "45px" } }),
            ],
            1
          ),
          _c(
            "a-col",
            {
              staticStyle: { padding: "0 34px", "margin-bottom": "24px" },
              attrs: { sm: 12, xs: 24 },
            },
            [
              _c("div", { staticClass: "num-info" }, [
                _c(
                  "span",
                  { staticClass: "title" },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("capita")) + " "),
                    _c(
                      "a-tooltip",
                      { attrs: { title: _vm.$t("introduce") } },
                      [
                        _c("a-icon", {
                          staticStyle: {
                            "font-size": "14px",
                            "margin-left": "8px",
                          },
                          attrs: { type: "info-circle" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "value" }, [
                  _c("span", { staticClass: "total" }, [_vm._v("2.7")]),
                  _c(
                    "span",
                    { staticClass: "subtotal" },
                    [
                      _vm._v("71.2"),
                      _c("a-icon", { attrs: { type: "caret-down" } }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("mini-area", { staticStyle: { height: "45px" } }),
            ],
            1
          ),
        ],
        1
      ),
      _c("a-table", {
        attrs: {
          dataSource: _vm.searchData,
          columns: _vm.tableColumns,
          pagination: { style: { marginBottom: 0 }, pageSize: 5 },
          size: "small",
          rowKey: "index",
        },
        scopedSlots: _vm._u([
          {
            key: "keyword",
            fn: function (text) {
              return _c("a", { attrs: { href: "#/" } }, [_vm._v(_vm._s(text))])
            },
          },
          {
            key: "rang",
            fn: function (text) {
              return _c(
                "span",
                {},
                [
                  _vm._v(_vm._s(text) + " %"),
                  _c("a-icon", { attrs: { type: "caret-up" } }),
                ],
                1
              )
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }