var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pay" },
    [
      _vm._m(0),
      _c("a-divider"),
      _c(
        "div",
        { staticStyle: { display: "flex" } },
        [
          _vm.loading
            ? _c("a-skeleton", {
                staticStyle: {
                  width: "68%",
                  height: "230px",
                  "margin-right": "30px",
                },
                attrs: { paragraph: { rows: 4 }, active: "" },
              })
            : _vm._e(),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.loading,
                expression: "!loading",
              },
            ],
            staticStyle: { width: "70%", height: "230px" },
            attrs: { id: "chart" },
          }),
          _c(
            "div",
            { staticClass: "flex", staticStyle: { "flex-wrap": "wrap" } },
            _vm._l(_vm.payrollData, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "item flex a-center",
                  style: {
                    width: "49%",
                    backgroundColor: item.background,
                    borderRadius: "6px",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex a-center" },
                    [
                      _c("img", {
                        staticStyle: {
                          width: "42px",
                          height: "42px",
                          "margin-left": "20px",
                        },
                        attrs: { src: item.img },
                      }),
                      _c("a-divider", {
                        staticClass: "mx-2",
                        staticStyle: { height: "61px", background: "#dae6ff" },
                        attrs: { type: "vertical" },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "mt-2" }, [
                    _c("h4", { staticClass: "black-three" }, [
                      _vm._v(_vm._s(item.title)),
                    ]),
                    _c(
                      "h4",
                      { staticClass: "black-three font-24" },
                      [
                        item.value && item.value != "0"
                          ? _c("span", [_vm._v(_vm._s(item.value))])
                          : _c("a-spin"),
                      ],
                      1
                    ),
                  ]),
                ]
              )
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "black font-16 font-bold overtext-1 flex" },
      [
        _c("img", {
          staticStyle: { width: "25px", height: "25px" },
          attrs: { src: require("@/assets/img/payroll-card1.png"), alt: "" },
        }),
        _c("span", { staticClass: "ml-05" }, [_vm._v("工资发放情况（万元）")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }