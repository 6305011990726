var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { staticClass: "result-error", attrs: { bordered: false } },
    [
      _c(
        "result",
        {
          staticStyle: { "margin-bottom": "16px", "margin-top": "48px" },
          attrs: {
            "is-success": false,
            title: _vm.title,
            description: _vm.description,
          },
        },
        [
          _c(
            "template",
            { slot: "action" },
            [
              _c("a-button", { attrs: { type: "primary" } }, [
                _vm._v("返回修改"),
              ]),
            ],
            1
          ),
          _c("div", [
            _c(
              "div",
              {
                staticStyle: {
                  fontSize: "16px",
                  fontWeight: "500",
                  marginBottom: "16px",
                },
              },
              [_vm._v(" 您提交的内容有如下错误： ")]
            ),
            _c(
              "div",
              { staticStyle: { "margin-bottom": "16px" } },
              [
                _c("a-icon", {
                  staticClass: "error-icon",
                  attrs: { type: "close-circle-o" },
                }),
                _vm._v(" 您的账户已被冻结 "),
                _c(
                  "a",
                  { staticStyle: { "margin-left": "16px" } },
                  [
                    _vm._v("立即解冻 "),
                    _c("a-icon", { attrs: { type: "right" } }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("a-icon", {
                  staticClass: "error-icon",
                  attrs: { type: "close-circle-o" },
                }),
                _vm._v(" 您的账户还不具备申请资格 "),
                _c(
                  "a",
                  { staticStyle: { "margin-left": "16px" } },
                  [
                    _vm._v("立即升级 "),
                    _c("a-icon", { attrs: { type: "right" } }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }