<template>
  <div>
    <a-card title="项目安全生产隐患排查治理情况">
      <a-form-model layout="horizontal" :model="params">
        <a-row>
          <a-col :span="24">
            <a-form-model-item
              label="状态："
              :labelCol="{ span: 2 }"
              :wrapperCol="{ span: 5 }"
            >
              <span>{{statusName}}</span>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-model-item
              label="项目："
              :labelCol="{ span: 2 }"
              :wrapperCol="{ span: 5 }"
            >
              <a-input
                placeholder="请输入项目"
                v-model="params.projectName"
                disabled
                allowClear
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item
              label="一般隐患数："
              :labelCol="{ span: 6 }"
              :wrapperCol="{ span: 15 }"
            >
              <a-input
                placeholder="暂无"
                disabled
                v-model="params.problemNumsNormal"
                allowClear
              /> </a-form-model-item
          ></a-col>
          <a-col :span="12"
            ><a-form-model-item
              label="一般隐患已整改数："
              :labelCol="{ span: 6 }"
              :wrapperCol="{ span: 11 }"
            >
              <a-input
                placeholder="暂无"
                v-model="params.problemNumsNormalDone"
                allowClear
                disabled
              /> </a-form-model-item
          ></a-col>
          <a-col :span="8"
            ><a-form-model-item
              label="重大隐患数："
              :labelCol="{ span: 6 }"
              :wrapperCol="{ span: 15 }"
            >
              <a-input
                placeholder="暂无"
                v-model="params.problemNumsSerious"
                disabled
                allowClear
              /> </a-form-model-item
          ></a-col>
          <a-col :span="12"
            ><a-form-model-item
              label="重大隐患已整改数："
              :labelCol="{ span: 6 }"
              :wrapperCol="{ span: 11 }"
            >
              <a-input
                placeholder="暂无"
                v-model="params.problemNumsSeriousDone"
                allowClear
                disabled
              /> </a-form-model-item
          ></a-col>
        </a-row>
        <a-form-item :wrapper-col="{ offset: 11 }">
          <a-button @click="close">
            关闭
          </a-button>
          <!-- <a-button class="ml-3" type="primary" @click="submit(0)" v-if="user.loginType == 1 && (params.status == 0  || params.status == 2)">
            暂存
          </a-button>
          <a-button class="ml-3" type="primary" @click="submit(1)" v-if="user.loginType == 1 && (params.status == 0  || params.status == 2)">
            提交
          </a-button> -->
        </a-form-item>
      </a-form-model>
    </a-card>
  </div>
</template>

<script>
import {
  getHiddenDangerDetail, updateHiddenDanger
} from "@/services/safeMonthlyReport";
import { mapGetters } from "vuex";
export default {
  inject: ["fatherMethod"],
  data() {
    return {
      params: {
        score: "",
        projectName: "",
        problemNumsNormalDone: "",
        problemNumsNormal: "",
        problemNumsSeriousDone: "",
        problemNumsSerious: "",
        id: '',
        status: null
      },
    };
  },
  
  activated() {
    this.params.id = this.$route.query.id
    this.handleQuery()
  },
  computed: {
    ...mapGetters("account", ["user"]),
    statusName() {
      switch (this.params.status) {
        case 0:
          return '待提交'
        case 1:
          return '已提交'
        case 2:
          return '退回'
        case 3:
          return '已上报'
        default: 
          return ''
      }
    }
  },
  methods: {
    handleQuery() {
      getHiddenDangerDetail(this.params.id).then((res) => {
        let d = res.data;
        if (d.code === 0) {
          this.params = d.data
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    submit(e) {
      const _that = this
      if (e == 1) {
        this.$confirm({
          title: '提交后信息不可修改，是否确认提交？',
          onOk() {
            _that.params.status = e
            updateHiddenDanger(_that.params).then(res => {
              let d = res.data;
              if (d.code === 0) {
                _that.$message.success('修改成功')
                _that.handleQuery()
              } else {
                _that.$message.error(res.data.msg)
              }
            })
          }
        })
      } else {
        this.params.status = e
        updateHiddenDanger(this.params).then(res => {
          let d = res.data;
          if (d.code === 0) {
            this.$message.success('暂存成功')
            this.handleQuery()
          } else {
            this.$message.error(res.data.msg)
          }
        })
      }
    },
    close() {
      let path = this.$route.path
      this.fatherMethod(`${path}`)
    },
  },
};
</script>

<style lang="less" scoped></style>
