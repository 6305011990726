var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "l_Approval" }, [
    _c(
      "div",
      { staticClass: "a_a_header" },
      [
        _c("form-index", {
          ref: "formindex",
          attrs: { formData: _vm.formData, flexSubmit: true },
          on: { handleSubmit: _vm.handleSubmit },
          scopedSlots: _vm._u([
            {
              key: "flexSubmit",
              fn: function () {
                return [
                  _c(
                    "a-button",
                    { attrs: { type: "primary", "html-type": "submit" } },
                    [_vm._v(" 查询 ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "a_a_footer" },
      [
        _c(
          "div",
          [
            _c(
              "a-space",
              { staticClass: "operator" },
              [
                _c(
                  "a-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.doDowloadBatch(1)
                      },
                    },
                  },
                  [_vm._v("批量通过")]
                ),
              ],
              1
            ),
            _c(
              "a-space",
              { staticClass: "operator" },
              [
                _c(
                  "a-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.doDowloadBatch(2)
                      },
                    },
                  },
                  [_vm._v("批量驳回")]
                ),
              ],
              1
            ),
            _c(
              "a-space",
              { staticClass: "operator" },
              [
                _c(
                  "a-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.batchModify()
                      },
                    },
                  },
                  [_vm._v("批量分配班组")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("standard-table", {
          attrs: {
            columns: _vm.columns,
            dataSource: _vm.dataSource,
            selectedRows: _vm.selectedRows,
            rowKey: "id",
            scroll: { x: 1300 },
            pagination: _vm.pagination,
            loading: _vm.tableLoading,
            isAlert: false,
            bordered: true,
          },
          on: {
            "update:selectedRows": [
              function ($event) {
                _vm.selectedRows = $event
              },
              function ($event) {
                return _vm.updateSelectRows($event)
              },
            ],
            "update:selected-rows": function ($event) {
              _vm.selectedRows = $event
            },
            change: _vm.onPageChange,
          },
          scopedSlots: _vm._u([
            {
              key: "index",
              fn: function (ref) {
                var index = ref.index
                return [
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          (_vm.pagination.current - 1) *
                            _vm.pagination.pageSize +
                            parseInt(index) +
                            1
                        ) +
                        " "
                    ),
                  ]),
                ]
              },
            },
            {
              key: "action",
              fn: function (ref) {
                var record = ref.record
                return _c("div", {}, [
                  _c(
                    "div",
                    { staticClass: "sTables" },
                    [
                      _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.doDowloadBatch(1, record)
                            },
                          },
                        },
                        [_vm._v("通过")]
                      ),
                      _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.doDowloadBatch(2, record)
                            },
                          },
                        },
                        [_vm._v("驳回")]
                      ),
                      _c(
                        "a-popover",
                        {
                          attrs: { placement: "bottomRight", trigger: "click" },
                        },
                        [
                          _c("template", { slot: "content" }, [
                            _c("div", { staticClass: "content_div" }, [
                              _vm._v("查看"),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "content_div",
                                on: {
                                  click: function ($event) {
                                    return _vm.batchModify(record)
                                  },
                                },
                              },
                              [_vm._v("分配班组")]
                            ),
                            _c("div", { staticClass: "content_div" }, [
                              _vm._v("分配宿舍"),
                            ]),
                          ]),
                          _c("template", { slot: "title" }, [
                            _c("span", [_vm._v("操作")]),
                          ]),
                          _c("div", [_vm._v("更多>")]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ])
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }