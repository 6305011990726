var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "create_index" },
    [
      _c("form-index", {
        ref: "formindex",
        attrs: {
          chageT: true,
          formData: _vm.formData,
          Overview: "项目概况",
          baiduMapBoolen: true,
          topSubmit: true,
          projectList: _vm.projectList,
          defaultFileList: _vm.defaultFileList,
        },
        on: {
          handleChange: _vm.handleChange,
          handleSubmit: _vm.handleSubmit,
          handlSelect: _vm.handlSelect,
          handleSearch: _vm.handleSearch,
        },
        scopedSlots: _vm._u([
          {
            key: "topSubmit",
            fn: function () {
              return [
                _c(
                  "a-button",
                  { attrs: { type: "primary", "html-type": "submit" } },
                  [_vm._v(" 提交 ")]
                ),
                _c("a-button", { on: { click: _vm.close } }, [
                  _vm._v(" 关闭 "),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }