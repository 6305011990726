var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a_Approval" },
    [
      _c("div", { staticClass: "plug-Overview" }, [
        _vm._v("建筑起重机械汇总表"),
      ]),
      _c("a-divider"),
      _c("div", [
        _c("span", { staticStyle: { color: "#ff718b" } }, [
          _vm._v(_vm._s(_vm.warningTotal) + "台设备即将到期/到期设备"),
        ]),
        _c(
          "span",
          {
            staticClass: "ml-2",
            staticStyle: { color: "#083af0", cursor: "pointer" },
            on: { click: _vm.goCheck },
          },
          [_vm._v("查看"), _c("a-icon", { attrs: { type: "arrow-right" } })],
          1
        ),
      ]),
      _c("form-index", {
        ref: "formindex",
        attrs: {
          formData: _vm.formData,
          flexAction: "",
          baiduMapBoolen: false,
        },
        on: { handleSubmit: _vm.handleSubmit },
        scopedSlots: _vm._u([
          {
            key: "flexAction",
            fn: function () {
              return [
                _c(
                  "a-space",
                  { staticClass: "btnBox" },
                  [
                    _c(
                      "a-button",
                      { attrs: { type: "primary", "html-type": "submit" } },
                      [_vm._v("查询")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "standard-table",
        {
          attrs: {
            columns: _vm.columns,
            dataSource: _vm.dataSource,
            rowKey: "projectId",
            pagination: _vm.pagination,
            loading: _vm.tableLoading,
            isAlert: false,
            bordered: true,
            btnArr: _vm.tableBtnArr,
            scroll: { x: 1800 },
          },
          on: { change: _vm.onPageChange },
          scopedSlots: _vm._u([
            {
              key: "index",
              fn: function (ref) {
                var index = ref.index
                return [
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          (_vm.pagination.current - 1) *
                            _vm.pagination.pageSize +
                            parseInt(index) +
                            1
                        ) +
                        " "
                    ),
                  ]),
                ]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "tableTitle" }, [
            _c("div", [
              _vm._v(
                " 总计：塔式起重机：" +
                  _vm._s(_vm.towerNums) +
                  "； 施工升降机：" +
                  _vm._s(_vm.upDownNums) +
                  "； 物料提升机：" +
                  _vm._s(_vm.goodsNums) +
                  "； 门式起重机：" +
                  _vm._s(_vm.doorNums) +
                  "； 桥式起重机：" +
                  _vm._s(_vm.bridgeNums) +
                  "； 总台数：" +
                  _vm._s(_vm.totalMachine) +
                  "； "
              ),
            ]),
          ]),
        ],
        2
      ),
      _c("Export-loading", {
        attrs: {
          cPercent: _vm.cPercent,
          isExporting: _vm.isExporting,
          loadingText: _vm.loadingText,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }