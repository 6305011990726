var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a_Approval" },
    [
      _c("formIndex", {
        ref: "formindex",
        attrs: { formData: _vm.formData, flexAction: true },
        on: {
          handleSubmit: _vm.handleSubmit,
          handlSelect: _vm.handlSelect,
          handleChange: _vm.handleChange,
        },
        scopedSlots: _vm._u([
          {
            key: "flexAction",
            fn: function () {
              return [
                _c(
                  "a-space",
                  {
                    staticStyle: { "margin-left": "25px", "margin-top": "5px" },
                  },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: {
                          icon: "search",
                          type: "primary",
                          "html-type": "submit",
                        },
                      },
                      [_vm._v(" 查询 ")]
                    ),
                    _c(
                      "a-space",
                      { staticClass: "operator" },
                      [
                        _c("a-button", { on: { click: _vm.close } }, [
                          _vm._v(" 重置 "),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("standard-table", {
        attrs: {
          columns: _vm.columns,
          dataSource: _vm.dataSource,
          pagination: _vm.pagination,
          loading: _vm.tableLoading,
          isAlert: false,
          bordered: true,
        },
        on: { change: _vm.onPageChange },
        scopedSlots: _vm._u([
          {
            key: "teamQr",
            fn: function (ref) {
              var text = ref.text
              var record = ref.record
              return _c("div", {}, [
                _c(
                  "div",
                  {
                    staticClass: "flex a-center j-center",
                    staticStyle: { height: "40px" },
                  },
                  [
                    text != "" && text != null
                      ? _c("div", [
                          _c("img", {
                            staticStyle: {
                              width: "40px",
                              height: "40px",
                              cursor: "pointer",
                            },
                            attrs: { src: text },
                            on: {
                              click: function ($event) {
                                return _vm.showImg(text, record)
                              },
                            },
                          }),
                        ])
                      : _vm._e(),
                    _c("a-spin", { attrs: { spinning: !text } }),
                  ],
                  1
                ),
              ])
            },
          },
          {
            key: "skillType",
            fn: function (ref) {
              var text = ref.text
              return _c(
                "div",
                {},
                [
                  _c("a-tag", { attrs: { color: "orange" } }, [
                    _vm._v(" " + _vm._s(text) + " "),
                  ]),
                ],
                1
              )
            },
          },
          {
            key: "action",
            fn: function (ref) {
              var text = ref.text
              var record = ref.record
              return _c("div", {}, [
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.showPersons(record)
                      },
                    },
                  },
                  [_vm._v("查看人员")]
                ),
              ])
            },
          },
          {
            key: "statusTitle",
            fn: function (ref) {
              var text = ref.text
              var record = ref.record
              return [
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.showPersons(record)
                      },
                    },
                  },
                  [_vm._v(_vm._s(text))]
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "a-modal",
        {
          attrs: { width: 800, title: "详情" },
          on: { ok: _vm.handleOk, cancel: _vm.handleOk },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c(
            "a-descriptions",
            { attrs: { bordered: "", column: 1, layout: "vertical" } },
            [
              _c("a-descriptions-item", { attrs: { label: "发生地点" } }, [
                _vm._v(
                  " " + _vm._s(_vm.personDataSource.address || "暂无") + " "
                ),
              ]),
              _c("a-descriptions-item", { attrs: { label: "事件发生时间" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.personDataSource.happenTime
                        ? _vm
                            .moment(_vm.personDataSource.happenTime)
                            .format("YYYY-MM-DD HH:mm:ss")
                        : "暂无"
                    ) +
                    " "
                ),
              ]),
              _c("a-descriptions-item", { attrs: { label: "人员名称" } }, [
                _vm._v(
                  " " + _vm._s(_vm.personDataSource.peopleNames || "暂无") + " "
                ),
              ]),
              _c("a-descriptions-item", { attrs: { label: "人数" } }, [
                _vm._v(" " + _vm._s(_vm.personDataSource.peopleNum) + " "),
              ]),
              _c("a-descriptions-item", { attrs: { label: "总结" } }, [
                _vm._v(
                  " " + _vm._s(_vm.personDataSource.summary || "暂无") + " "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("preview-modal", {
        attrs: {
          visible: _vm.isModalVisible,
          type: _vm.previewType,
          url: _vm.previewUrl,
          textContent: _vm.previewText,
        },
        on: {
          "update:visible": function ($event) {
            _vm.isModalVisible = $event
          },
        },
      }),
      _c(
        "a-modal",
        {
          attrs: {
            destroyOnClose: true,
            visible: _vm.modalVisible,
            "ok-text": "下载",
            "cancel-text": "关闭",
          },
          on: { cancel: _vm.handleCancel, ok: _vm.downQrCode },
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { alt: "example", src: _vm.previewImage },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }