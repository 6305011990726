<template>
  <div class="m_g">
    <div class="flex p-1">
      <div class="m_g_left public wapper">
        <form-index
          ref="formIndex"
          :formData="formData"
          Overview="基本信息"
          :options="options"
          @resourceUpload="resourceUpload"
          @upRemove="upRemove"
        ></form-index>
      </div>
      <div class="wapper">
        <div class="m_g_right_bottom public">
          <form-index
            ref="formIndex1"
            :formData="formDataTop"
            Overview="账号信息"
            :options="options"
            @handlSelect="handlSelect"
          ></form-index>
          <div style="color:red">特别注意：
            <p>1、更换岗位人员，请将原岗位人员先离场，然后新增管理人员，切勿直接在原有岗位上更换人员！</p>
            <p>2、请务必确保手机号和身份证号是真实属于管理人员的个人信息，否则会造成管理员数据混乱！</p>
            <p>3、需要上报住建局建设平台人员，务必所有信息填写正确！</p>
          </div>
        </div>

      </div>
    </div>
    <template>
      <div class="m_g_button">
        <a-button icon="save" type="primary" @click="handleSubmit">
          提交
        </a-button>
      </div>
    </template>
  </div>
</template>

<script>
let _that = null
const formData = [
  {
    type: "place",
    labelCol: 6,
    wrapperCol: 16,
    col: 2,
  },
  {
    label: "",
    title: "上传正面照",
    placeholder: "请上传正面照",
    decorator: [
      "facePath",
      { rules: [{ required: false, message: "请上传正面照" }] },
    ],
    type: "tsUpload",
    key: "facePath",
    labelCol: 6,
    wrapperCol: 16,
    defaultImg: require("@/assets/img/front.png"),
    col: 8,
    defaultFileList: [],
    display: true,
  },
  {
    label: "",
    title: "上传反面照",
    placeholder: "请上传反面照",
    defaultImg: require("@/assets/img/opposite.png"),
    defaultFileList: [],
    previewImage: "",
    decorator: [
      "backPath",
      { rules: [{ required: false, message: "请上传反面照" }] },
    ],
    type: "tsUpload",
    key: "backPath",
    labelCol: 8,
    wrapperCol: 14,
    col: 8,
    display: true,
  },
  {
    label: "",
    title: "上传头像",
    placeholder: "请上传头像",
    defaultImg: require("@/assets/img/headsculpture.png"),
    defaultFileList: [],
    previewImage: "",
    decorator: [
      "facePoto",
      { rules: [{ required: false, message: "请上传头像" }] },
    ],
    type: "tsCropperUpload",
    key: "facePoto",
    labelCol: 6,
    wrapperCol: 16,
    col: 6,
    display: true,
    compress: true, // 开启图片压缩
    cropperTitle: '编辑头像',
    showCroImg: true
  },
  {
    label: "身份证号",
    placeholder: "请输入身份证号",
    decorator: [
      "idCard",
      { rules: [{ required: true, message: "请输入身份证号"}] },
    ],
    type: "input",
    key: "idCard",
    dataSource: [],
    labelCol: 7,
    wrapperCol: 16,
    col: 12,
    display: true,
    inputChange: (e) => {
      _that.$nextTick(() => {
        const idCard = _that.$refs.formIndex.getFieldValue().idCard
        if (_that.isIdCardNo(idCard)) {
          const params = {IdCard: idCard,projectId: _that.query.projectId}
          getCheckIdCard(params).then(res => {
            if (res.data.code == -1) {
              // _that.$message.warning(res.data.msg)
            } else if (res.data.code === 0) {
              // 处理省市区回显数据
              if (res.data.data.nationality) {
                res.data.data.nationality = res.data.data.nationality.split(",");
              }
              if (res.data.data.roleIds) {
                // 处理后台返回roleIds 字段 ",[15]," == 转化为[15]
                let roleIds = res.data.data.roleIds
                  .substring(2, res.data.data.roleIds.length - 2)
                  .split(",");
                  res.data.data.roleIds = roleIds.map((item) => {
                  return Number(item);
                });
              }
              _that.$nextTick(() => {
                _that.$refs.formIndex.queryObj(res.data.data);
                _that.$refs.formIndex1.queryObj(res.data.data);
              });
              let path = ["facePath", "backPath", "facePoto"];
              _that.formData.forEach((item) => {
                path.forEach((key, index) => {
                  if (item.key == key && res.data.data[key]) {
                    item.defaultFileList = [
                      { url: res.data.data[key], uid: index, name: "image.png", key },
                    ];
                  }
                });
              });
            }
          })
        }
      })
    }
  },
  {
    label: "姓名",
    placeholder: "请输入姓名",
    decorator: [
      "userName",
      { rules: [{ required: true, message: "请输入姓名" },
      { required: true, message: "最长不超过12位汉字" ,pattern:/(^[\u4e00-\u9fa5]{1,12}$)/}] },

    ],
    type: "input",
    key: "userName",
    dataSource: [],
    labelCol: 7,
    wrapperCol: 16,
    col: 12,
    display: true,
    maxLength: 13
  },
  {
    label: "民族",
    placeholder: "请输入民族",
    decorator: [
      "nation",
      { rules: [{ required: false, message: "请输入民族" },{ required: false, message: "最长不超过8位汉字" ,pattern:/(^[\u4e00-\u9fa5]{1,8}$)/}] },
    ],
    type: "input",
    key: "nation",
    dataSource: [],
    labelCol: 7,
    wrapperCol: 16,
    col: 12,
    display: true,
    maxLength: 9,
  },
  {
    label: "籍贯",
    placeholder: "请选择省市区",
    decorator: [
      "nationality",
      { rules: [{ required: false, message: "请选择省市区" }] },
    ],
    type: "cascader",
    key: "nationality",
    labelCol: 7,
    wrapperCol: 16,
    col: 12,
    display: true,
    maxLength: 30,
  },
  {
    label: "住址",
    placeholder: "请输入住址",
    decorator: [
      "location",
      { rules: [{ required: true, message: "请输入住址" }] },
    ],
    type: "input",
    key: "location",
    labelCol: 7,
    wrapperCol: 16,
    col: 12,
    display: true,
    maxLength: 200,
  },
  {
    label: "签发机关",
    placeholder: "请输入签发机关",
    decorator: [
      "signOrgan",
      { rules: [{ required: true, message: "请输入签发机关" }, { required: true, message: "最长不超过20位" ,pattern:/^[\u4e00-\u9fa5|\d]{1,20}$/}] },
    ],
    type: "input",
    key: "signOrgan",
    dataSource: [],
    labelCol: 7,
    wrapperCol: 16,
    col: 12,
    display: true,
    maxLength: 21
  },
];
const formDataTop = [
  {
    label: "所属单位",
    placeholder: "请选择所属单位",
    decorator: [
      "officeId",
      { rules: [{ required: false, message: "请选择所属单位" }] },
    ],
    type: "select",
    key: "officeId",
    selectlist: [],
    labelCol: 7,
    wrapperCol: 16,
    col: 12,
    display: true,
  },
  {
    label: "上报建委",
    placeholder: "是否上报建委",
    decorator: [
      "isReportAtte",
      {
        initialValue: 0,
        rules: [{ required: true, message: "是否上报建委" }],
      },
    ],
    type: "radio",
    class: "radio",
    key: "isReportAtte",
    radiolist: [
      {
        name: "上报",
        value: 1,
      },
      {
        name: "不上报",
        value: 0,
      },
    ],
    labelCol: 7,
    wrapperCol: 13,
    col: 12,
    display: true,
  },

  {
    label: "手机号",
    placeholder: "请填写手机号",
    decorator: [
      "phone",
      { rules: [{ required: true, message: "请正确填写手机号",pattern: new RegExp(/^1\d{10}$/),}] },
    ],
    type: "input",
    key: "phone",
    dataSource: [],
    labelCol: 7,
    wrapperCol: 16,
    col: 12,
    display: true,
    maxLength: 11,
    inputChange:(e) => {
      _that.$nextTick(() => {
        const userPhone = _that.$refs.formIndex1.getFieldValue().phone
        if (_that.isPhone(userPhone)) {
          const params = {
            phone: userPhone,
            projectId: _that.projectId
          }
          getCheckPhone(params).then(res => {
            if (res.data.code === 0) {
              if (res.data.data === true) {
                _that.$message.warning('该管理人员用户已经在系统注册',5)
              }
            }
          })
        }
      })
    }
  },
  {
    label: "账号状态",
    placeholder: "是否启用账号",
    decorator: [
      "isLogin",
      {
        initialValue: 0,
        rules: [{ required: true, message: "是否启用账号" }],
      },
    ],
    type: "radio",
    class: "radio",
    key: "isLogin",
    radiolist: [
      {
        name: "启用",
        value: 0,
      },
      {
        name: "禁用",
        value: 1,
      },
    ],
    labelCol: 7,
    wrapperCol: 12,
    col: 12,
    display: true,
  },
  {
    label: "合同密钥",
    placeholder: "请选择合同密钥",
    decorator: [
      "contractId",
      { rules: [{ required: false, message: "请选择合同密钥" }] },
    ],
    type: "select",
    key: "contractId",
    selectlist: [],
    labelCol: 7,
    wrapperCol: 16,
    col: 12,
    display: true,
  },
  {
    label: "是否在场",
    placeholder: "是否在场",
    decorator: [
      "status",
      {
        initialValue: 1,
        rules: [{ required: true, message: "是否在场" }],
      },
    ],
    type: "radio",
    class: "radio",
    key: "status",
    radiolist: [
      {
        name: "在场",
        value: 1,
      },
      {
        name: "离场",
        value: 0,
      },
    ],
    labelCol: 7,
    wrapperCol: 12,
    col: 12,
    display: true,
  },
  {
    label: "角色",
    placeholder: "请选择角色",
    decorator: [
      "roleIds",
      { rules: [{ required: true, message: "请选择角色" }] },
    ],
    type: "select",
    key: "roleIds",
    selectlist: [],
    labelCol: 7,
    wrapperCol: 16,
    col: 12,
    display: true,
    mode: "multiple",
  }
];

import formIndex from "@/pages/components/form/index";
import address from "@/pages/components/form/address.json";
import {
  getIdCardInfo,
  saveOfficeMageUser,
  getProAllOfficeList,
  getMageUserDetail,
  getCheckPhone,
  getCheckIdCard
} from "@/services/participationUnit";
import { getRoleListBySysUserList } from "@/services/sys";
import { mapGetters } from "vuex";
import { getProjContacts } from "@/services/projectManagement";
export default {
  components: {
    formIndex,
  },
  data() {
    return {
      formData: formData,
      options: address,
      formDataTop: formDataTop,
      arr: [],
      allSubmitobj: {},
      newRole: [],
      roleName: [],
      query: {
        data: null,
      },
      initPhone: null
    };
  },
  inject: ["fatherMethod"],
  computed: {
    ...mapGetters("account", ["user"]),
  },
  created() {
    _that = this
  },
  activated() {
    // 每次进入都清空图片
    this.formData.forEach((item) => {
      if (item.type === "tsUpload") {
        item.defaultFileList = [];
      }
    });
    this.getRoleList();
    this.userId = this.$route.query.userId;
    this.projectId = this.$route.query.projectId;
    if (this.userId && this.projectId) {
      this.getManageInfo();
    }
    this.getProjContacts()
  },
  methods: {
    isPhone(num) {
      if ( !(/^1\d{10}$/.test(num)) ) {
        console.log('手机号错误')
        return false;
      } else {
        console.log('手机号正确')
        return true;
      }
    },
    isIdCardNo(num) {
      num = num.toUpperCase();
      //身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X。
      if ( !(/(^\d{15}$)|(^\d{17}([0-9]|X)$)/.test(num)) ) {
        console.log('身份证错误')
        return false;
      } else {
        console.log('身份证正确')
        return true;
      }
    },
    getManageInfo() {
      getMageUserDetail({
        userId: this.userId,
        projectId: this.projectId,
      }).then((res) => {
        this.query.data = res.data.data;
        this.query.data.projectId = this.projectId;
        this.getProAllOfficeList();
        this.$nextTick(() => {
          this.$refs.formIndex.queryObj(this.query.data);
          this.$refs.formIndex1.queryObj(this.query.data);
        });
        // 处理省市区回显数据
        if (this.query.data.nationality) {
          this.query.data.nationality = this.query.data.nationality.split(",");
        }
        if (this.query.data.roleIds) {
          // 处理后台返回roleIds 字段 ",[15]," == 转化为[15]
          let roleIds = this.query.data.roleIds
            .substring(2, this.query.data.roleIds.length - 2)
            .split(",");
          this.query.data.roleIds = roleIds.map((item) => {
            return Number(item);
          });
        }
        // 处理回显图片path
        let path = ["facePath", "backPath", "facePoto"];
        this.formData.forEach((item) => {
          path.forEach((key, index) => {
            if (item.key == key && this.query.data[key]) {
              item.defaultFileList = [
                {
                  url: this.query.data[key],
                  uid: index,
                  name: "image.png",
                  key: item.key,
                },
              ];
            }
          });
        });
      });
    },
    handleSubmit() {
      this.arr = [];
      let arr = ["formIndex", "formIndex1"];
      arr.forEach((item) => {
        this.arr.push(this.$refs[item].combinationSubmit());
      });
      Promise.all(this.arr).then((res) => {
        let data = Object.assign({}, ...res);
        data.roleNames = this.roleNames
          ? this.roleNames.join()
          : data.roleNames;
        if (data.nationality) data.nationality = data.nationality.join(",");
        data.roleIds = data.roleIds.join();
        // data.password = "sj123456.";
        data.id = this.query.data.id ? this.query.data.id : this.userId
        // data.officeId = this.query.data.officeId;
        data.projectId = this.query.data.projectId ? this.query.data.projectId : this.projectId
        delete data.officeName;
        console.log(data, "详情data");
        const params = {
          phone: data.phone,
          projectId: this.projectId
        }
        if (this.query.data.phone != data.phone) {
          getCheckPhone(params).then(res => {
          if (res.data.code === 0) {
            if (res.data.data === true) {
              this.$message.error('该手机号已在该项目创建',5)
              this.getManageInfo();
            } else {
                saveOfficeMageUser(data).then((res) => {
                  if (res.data.code === 0) {
                    this.$message.success("保存成功", 5);
                    this.close();
                  } else {
                    this.$message.error(res.data.msg,5);
                  }
                });
            }
          }
        })
        } else {
          saveOfficeMageUser(data).then((res) => {
            if (res.data.code === 0) {
              this.$message.success("保存成功", 5);
              this.close();
            } else {
              this.$message.error(res.data.msg,5);
            }
          });
        }
      });
    },
    // 查到单位名称 展示使用
    getProAllOfficeList() {
      let params = {
        officeId: this.query.data.officeId,
        projectId: this.query.data.projectId,
      };
      getProAllOfficeList(params).then((res) => {
        if (res.data.code === 0) {
          // if (res.data.data.length) {
          //   let office = res.data.data.filter(
          //     (obj) => obj.officeId == this.query.officeId || this.query.data.officeId
          //   );
          //   this.$nextTick(() => {
          //     this.$refs.formIndex1.setFieldsValue(
          //     office[0].officeName,
          //     { officeName: office[0].officeName },
          //     "officeName"
          //   );
          //   })
          // }
          const arr = [];
          res.data.data.forEach((item) => {
            arr.push({
              name: item.officeName,
              value: item.officeId,
            });
          });
          this.formDataFn("officeId", arr);
        }
      });
    },
    // 关闭当前页面
    close() {
      let path = this.$route.path;
      this.fatherMethod(`${path}`);
    },
    // 特殊数据处理
    handlSelect({ val, keys }) {
      // 处理新劳务角色 选择框名字数组
      if (keys == "roleIds") {
        let roleName = [];
        val.forEach((item) => {
          this.newRole.forEach((role) => {
            if (item == role.roleId) {
              roleName.push(role.roleName);
            }
          });
        });
        this.roleNames = roleName;
      }
    },
    resourceUpload(obj) {
      this.formData.forEach((item) => {
        if (item.key == obj.key) {
          item.defaultFileList = [obj];
          if (item.key === "facePath") {
            this.getIdCardInfo(obj.formData, "front");
          } else if (item.key === "backPath") {
            this.getIdCardInfo(obj.formData, "back");
          }
        }
      });
    },
    upRemove(file) {
      this.formData.forEach((item) => {
        if (item.key == file.key) {
          item.defaultFileList = [];
        }
      });
    },
    //获取身份证信息
    getIdCardInfo(formData, type) {
      getIdCardInfo(formData, type).then((res) => {
        if (res.data.code === 0) {
          const words_result = res.data.data.idCardJson.words_result;
          if (type === "front") {
            // 裁剪的身份证正面
            this.$refs.formIndex.setFieldsValue('facePath', {'facePath': res.data.data.idCardJson.filePath}, 'facePath')
            this.$refs.formIndex.setFieldsValue(
              words_result.name.words,
              { userName: words_result.name.words },
              "userName"
            );
            this.$refs.formIndex.setFieldsValue(
              words_result.idCard.words,
              { idCard: words_result.idCard.words },
              "idCard"
            );
            this.$refs.formIndex.setFieldsValue(
              words_result.nation.words,
              { nation: words_result.nation.words },
              "nation"
            );
            this.$refs.formIndex.setFieldsValue(
              words_result.address.words,
              { location: words_result.address.words },
              "location"
            );
          } else if (type === "back") {
            // 裁剪的身份证反面
            this.$refs.formIndex.setFieldsValue('backPath', {'backPath': res.data.data.idCardJson.filePath}, 'backPath')
            this.$refs.formIndex.setFieldsValue(
              words_result.signAddr.words,
              { signOrgan: words_result.signAddr.words },
              "signOrgan"
            );
          }
          // 处理身份证裁剪-回显图片path
          let path = [{key: "facePath",type: 'front'}, {key:"backPath",type:'back'}];
            this.formData.forEach((item) => {
              path.forEach((items, index) => {
                if (item.key == items.key && items.type == type) {
                  item.defaultFileList = [
                    { url: res.data.data.idCardJson.filePath, uid: index, name: "image.png", key: item.key },
                  ];
                }
              });
            });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 获取劳务角色数组
    getRoleList() {
      getRoleListBySysUserList({}).then((res) => {
        // 筛选数组=项目角色
        this.newRole = res.data.data.filter((obj) =>
          // SJKJ_CONTRACT_ESS项目电子专员-需要管理添加
          obj.roleType == 2 && obj.roleKey != 'SJKJ_CONTRACT_ESS'
        );
        const data = this.newRole;
        const arr = [];
        data.forEach((item) => {
          arr.push({
            name: item.name,
            value: item.id,
          });
        });
        this.formDataFn("roleIds", arr);
      });
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      this.formDataTop.forEach((item) => {
        if (item.key == key) {
          item.selectlist = data;
        }
      });
    },
    // 合同密钥
    getProjContacts() {
      getProjContacts({projectId: this.projectId}).then(res => {
        if (res.data.code === 0) {
          const data = res.data.data;
          const arr = [];
          data.forEach((item) => {
            arr.push({
              name: item.name,
              value: item.id,
            });
          });
          this.formDataFn("contractId", arr);
        }
      })
    },
  },
};
</script>

<style lang="less" scoped>
.wapper {
  // height: 500px;
  flex: 1;
}
.public {
  padding: 17px;
  background: #fff;
  border-radius: 10px;
}

.m_g {
  background: #ffffff;
  // border-radius: 10px;
  padding-bottom: 20px;
  .m_g_left {
    border: 1px solid rgb(218, 230, 255);
    margin-right: 10px;
    // margin: 10px;
  }

  .m_g_right_top {
    margin-bottom: 16px;
    border: 1px solid rgb(218, 230, 255);
  }

  .m_g_right_mid {
    margin-bottom: 16px;
    border: 1px solid rgb(218, 230, 255);
  }
  .m_g_right_bottom {
    border: 1px solid rgb(218, 230, 255);
  }

  .m_g_button {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 20px;
    margin-right: 100px;
  }
}
::v-deep .ant-form-item {
  margin-bottom: 20px !important;
}
</style>
