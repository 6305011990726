<template>
  <div>
    <form-index ref="formindex" :formData="formData" @handleSubmit="handleSubmit" :flexSubmit="true" @handlSelect="handlSelect">
        <!-- 下部分按钮插槽 -->
      <template #flexSubmit>
        <a-button type="primary" html-type="submit"> 查询 </a-button>
      </template>
    </form-index>
    <div class="a_a_footer">
      <div class="mb-1">
        <a-space class="operator">
          <a-button @click="doReports">一键上报</a-button>
        </a-space>
        <a-space class="operator">
          <!-- Refresh -->
          <a-button @click="Refresh">刷新</a-button>
          <!-- <a-button @click="doDowloadBatch(2)">刷新</a-button> -->
          <a-button v-if="canOperate" @click="handleUpload(1)"> <a-icon type="upload" /> 导入工人考勤 </a-button>
          <a-button v-if="canOperate" @click="handleUpload(2)"> <a-icon type="upload" /> 导入管理人员考勤 </a-button>
        </a-space>
      </div>
      <!-- 表格封装 -->
      <standard-table
        class=""
        :columns="columns"
        :dataSource="dataSource"
        rowKey="id"
        :pagination="pagination"
        :loading="tableLoading"
        @change="onPageChange"
        :isAlert="false"
        :bordered="true"
      >
        <template slot="index" slot-scope="{ index }">
          <span>
            {{
              (pagination.current - 1) * pagination.pageSize +
                parseInt(index) +
                1
            }}
          </span>
        </template>
      </standard-table>
    </div>
    <a-modal
        :visible="uploadWorkerModal"
        :footer="null"
        @cancel="cancelUploadWorkerModal"
        >
        <div class="flex flex-column a-center">
            <h4 class="mb-2">只能导入3天内的{{uploadType == 1 ? '工人' : '管理人员'}}考勤数据</h4>
            <a-upload
                :action="uploadType == 1 ? staffWorkBatchApplyUrl : staffManageBatchApplyUrl"
                :headers="headers"
                :file-list="fileList"
                @change="handleChange"
                accept=".xls,.xlsx"
                :beforeUpload="beforeUpload"
            >
                <div >
                    <a-button  type="primary"> 确认导入 </a-button>
                </div>
            </a-upload>
        </div>
    </a-modal>
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import { getAtteFailRecordList,atteReport } from '@/services/dmgr'
import { mapGetters } from 'vuex'
import {STAFF_APPLY_BATCH_WORK,STAFF_APPLY_BATCH_MANAGE} from '@/services/api'
import { session } from '@/utils/storage'
import { xsrfHeaderName } from '@/utils/request.js'
import { useRender } from '@/hooks/useRender.js'

const formData = [
  {
    label: "姓名",
    placeholder: "请填写",
    decorator: [
      "name",
      { rules: [{ required: false, message: "请填写" }] },
    ],
    type: "input",
    key: "name",
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  }
];
const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
  },
  {
    title: "考勤机名称",
    dataIndex: "checkChannel",
    align: "center",
    scopedSlots: { customRender: "checkChannel" },
  },
  {
    title: "工号",
    dataIndex: "workNum",
    align: "center",
    width: 180,
    scopedSlots: { customRender: "workNum" },
  },
  {
    title: "姓名",
    align: "center",
    dataIndex: "name",
    width: 140,
    scopedSlots: { customRender: "name" },
  },
  {
    title: "单位",
    dataIndex: "officeName",
    align: "center",
    ellipsis: true,
  },
  {
    title: "班组",
    dataIndex: "teamName",
    align: "center",
    ellipsis: true,
  },
  {
    title: "考勤时间",
    dataIndex: "atteTime",
    align: "center",
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "失败原因",
    dataIndex: "errMsg",
    align: "center",
    scopedSlots: { customRender: "errMsg" },
  },
];
export default {
  components: {
    formIndex,
    StandardTable,
  },
  props: {
    isDaily: {
      type: Boolean,
      default: true,
      require: true
    }
  },
  data() {
    return {
      formData: formData, //form表单数据
      columns: columns,
      dataSource: [], //table数组
      selectedRows: [], //选中的数据
      pagination: {
        showSizeChanger:true,
        showQuickJumper: true,
        //分页数据
        current: 1,
        pageSize: 10,
        total: 0,
        pageSizeOptions: ['10', '20', '30', '50'],
        showTotal: (total, range) => `共 ${total} 条`
      },
      tableLoading: false, //table加载中
      params: { //测试数据
        pageNumber: 1,
        pageSize: 10,
        isToday: this.isDaily
      },
      loadKeys: [],
      fileList: [],
      staffWorkBatchApplyUrl: STAFF_APPLY_BATCH_WORK,
      staffManageBatchApplyUrl: STAFF_APPLY_BATCH_MANAGE,
      uploadWorkerModal: false,
      headers: {
        Authorization: session.get(xsrfHeaderName),
        'Tenant-Id': session.get('Tenant-Id') ? session.get('Tenant-Id') : ''
      },
      uploadType: 1, // 1.工人考勤 2.管理人员考勤
      arrRoles:['project_leader','ATTENDANCE_CLERK',], // 按钮权限
    };
  },

  mounted() {
    this.getData(this.params)
  },
  computed: {
    ...mapGetters('account', ['user']),
    canOperate() {
      return this.user.nowRoleKey.some(role => this.arrRoles.includes(role));
    },
  },
  methods: {
    // 图片上传限制处理
    beforeUpload(file){
      const fileType = file.type;
      const isExcel = fileType === 'application/vnd.ms-excel' || fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      if (!isExcel) {
        this.$message.error('只能上传.xls和.xlsx文件');
        return false
      } 
    },
    doReports() {
      this.tableLoading = true
      let formObj = this.$refs["formindex"].getFieldValue()
      const params = {
        isToday: this.params.isToday,
        name: formObj.name ? formObj.name : ''
      }
      atteReport(params).then(res => {
        if (res.data.code === 0) {
          this.$message.success(res.data.msg,5)
        } else {
          this.$message.error(res.data.msg,5)
        }
        this.getData(this.params)
      })
    },
    // 提交表单数据
    handleSubmit(e) {
      this.params.pageNumber = 1
      this.pagination.current = 1
      let obj = { ...e, ...this.params }
      this.getData(obj)
    },
    getData(params) {
      this.tableLoading = true
      getAtteFailRecordList(params).then(res => {
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list
          this.pagination.total = res.data.data.total
          if (this.dataSource.length > 0) {
            this.dataSource.forEach(item => {
                if (item.atteTime) {
                  item.atteTime = this.formatTime(item.atteTime)
                }
              })
            this.dataSource = [...this.dataSource]
            
          }
        } else {
          this.$message.error(res.data.msg)
        }
        this.tableLoading = false
      })
    },
    //表格 - 分页查询
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      this.pagination.current = e.current;
      this.pagination.pageSize = e.pageSize;
      this.params.pageSize = e.pageSize
      this.params.pageNumber = e.current;
      this.getData(Object.assign({}, this.params, obj));
    },
    // 刷新
    Refresh() {
      this.params = {
        pageNumber: 1,
        pageSize: 10,
        isToday: this.isDaily
      };
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.$refs["formindex"].resetFields();
      this.getData(this.params)
    },
    updateSelectRows(e) {
      console.log(e)
    },
    // 时间格式化
    formatTime(time) {
      // 提取日期和时间组件
      const year = time.slice(0, 4);
      const month = time.slice(4, 6);
      const day = time.slice(6, 8);
      const hours = time.slice(8, 10);
      const minutes = time.slice(10, 12);
      const seconds = time.slice(12, 14);
      // 构建日期时间字符串
      const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      return formattedTime
    },
    handlSelect() {
      
    },
    handleUpload(type) {
      this.uploadType = type
      this.uploadWorkerModal = true
    },
    cancelUploadWorkerModal() {
      this.uploadWorkerModal = false
    },
    handleChange({ fileList }) {
      if (fileList.length <= 0){
          this.fileList = fileList;
          this.uploadWorkerModal = false
          return
      }
      let info = fileList[0]
      if (info.status === 'done') {
          if (info.response.code == 0){
              this.fileList = [];
              this.uploadWorkerModal = false
              this.$message.success(info.response.msg)
          }else {
              this.fileList = []
              this.uploadWorkerModal = false
              this.$message.error(info.response.msg)
          }
          this.getData(this.params);
      }else {
          this.uploadWorkerModal = false
          this.fileList = fileList;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.a_a_footer {
  margin-top: -20px;
  padding: 20px;
  background: #ffffff;

  .operator {
    margin-right: 8px;

    .ant-btn {
      color: #307dfa;
    }
  }

}
.sTables {
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    width: 100%;
    text-align: center;
    cursor: pointer;
  }

  div:nth-child(1) {
    color: #307dfa;
  }

  div:nth-child(2) {
    color: #ff5c77;
  }

  div:nth-child(3) {
    color: #615e83;
  }
}
</style>
