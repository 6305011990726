var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-card",
        {
          staticClass: "a_Approval",
          attrs: { title: _vm.title, bordered: false },
        },
        [
          _c(
            "a-form-model",
            { ref: "ruleForm", attrs: { model: _vm.params, rules: _vm.rules } },
            [
              _c(
                "a-row",
                { attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "制度类别",
                            "label-col": { span: 6 },
                            "wrapper-col": { span: 14 },
                            prop: "systemcatid",
                          },
                        },
                        [
                          _c("a-select", {
                            ref: "select",
                            attrs: { options: _vm.options1, allowClear: "" },
                            model: {
                              value: _vm.params.systemcatid,
                              callback: function ($$v) {
                                _vm.$set(_vm.params, "systemcatid", $$v)
                              },
                              expression: "params.systemcatid",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "级次",
                            "label-col": { span: 6 },
                            "wrapper-col": { span: 14 },
                            prop: "levelid",
                          },
                        },
                        [
                          _c("a-select", {
                            ref: "select",
                            attrs: { options: _vm.options2, allowClear: "" },
                            model: {
                              value: _vm.params.levelid,
                              callback: function ($$v) {
                                _vm.$set(_vm.params, "levelid", $$v)
                              },
                              expression: "params.levelid",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "管理职能类别",
                            "label-col": { span: 6 },
                            "wrapper-col": { span: 14 },
                            prop: "funccatid",
                          },
                        },
                        [
                          _c("a-select", {
                            ref: "select",
                            attrs: { options: _vm.options3, allowClear: "" },
                            model: {
                              value: _vm.params.funccatid,
                              callback: function ($$v) {
                                _vm.$set(_vm.params, "funccatid", $$v)
                              },
                              expression: "params.funccatid",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "管理职能内容",
                            "label-col": { span: 6 },
                            "wrapper-col": { span: 14 },
                            prop: "funcontent",
                          },
                        },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入管理职能内容" },
                            model: {
                              value: _vm.params.funcontent,
                              callback: function ($$v) {
                                _vm.$set(_vm.params, "funcontent", $$v)
                              },
                              expression: "params.funcontent",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "制度名称",
                            "label-col": { span: 6 },
                            "wrapper-col": { span: 14 },
                            prop: "systemname",
                          },
                        },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入制度名称" },
                            model: {
                              value: _vm.params.systemname,
                              callback: function ($$v) {
                                _vm.$set(_vm.params, "systemname", $$v)
                              },
                              expression: "params.systemname",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "制度主管部门",
                            "label-col": { span: 6 },
                            "wrapper-col": { span: 14 },
                            prop: "systemdeptid",
                          },
                        },
                        [
                          _c("a-select", {
                            ref: "select",
                            attrs: { options: _vm.options4, allowClear: "" },
                            model: {
                              value: _vm.params.systemdeptid,
                              callback: function ($$v) {
                                _vm.$set(_vm.params, "systemdeptid", $$v)
                              },
                              expression: "params.systemdeptid",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "发文日期",
                            "label-col": { span: 6 },
                            "wrapper-col": { span: 14 },
                            prop: "pubdate",
                          },
                        },
                        [
                          _c("a-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              allowClear: "",
                              mode: "date",
                              format: "YYYY-MM-DD",
                              valueFormat: "YYYY-MM-DD",
                            },
                            model: {
                              value: _vm.params.pubdate,
                              callback: function ($$v) {
                                _vm.$set(_vm.params, "pubdate", $$v)
                              },
                              expression: "params.pubdate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "生效日期",
                            "label-col": { span: 6 },
                            "wrapper-col": { span: 14 },
                            prop: "effectdate",
                          },
                        },
                        [
                          _c("a-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              allowClear: "",
                              mode: "date",
                              format: "YYYY-MM-DD",
                              valueFormat: "YYYY-MM-DD",
                            },
                            model: {
                              value: _vm.params.effectdate,
                              callback: function ($$v) {
                                _vm.$set(_vm.params, "effectdate", $$v)
                              },
                              expression: "params.effectdate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.isEdit == 2
                    ? _c(
                        "a-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "a-card",
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: "历史版本",
                                    "label-col": { span: 6 },
                                    "wrapper-col": { span: 14 },
                                    prop: "hisno",
                                  },
                                },
                                [
                                  _c("a-input", {
                                    attrs: {
                                      placeholder: "请输入历史发文文号",
                                    },
                                    model: {
                                      value: _vm.params.hisno,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.params, "hisno", $$v)
                                      },
                                      expression: "params.hisno",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: "上传附件",
                                    "label-col": { span: 6 },
                                    "wrapper-col": { span: 14 },
                                  },
                                },
                                [
                                  _c(
                                    "a-upload",
                                    {
                                      staticClass: "avatar-uploader",
                                      attrs: {
                                        "list-type": "picture-card",
                                        accept: _vm.accept,
                                        "file-list": _vm.hisFilePath,
                                        customRequest: _vm.hisresourceUpload,
                                        beforeUpload: _vm.beforeUpload,
                                        multiple: "",
                                      },
                                      on: {
                                        change: _vm.hishandleUploadChange,
                                        preview: _vm.handlePreview,
                                      },
                                    },
                                    [
                                      _vm.filePath.length < 10
                                        ? _c(
                                            "div",
                                            [
                                              _c("a-icon", {
                                                attrs: {
                                                  type: _vm.uploading
                                                    ? "loading"
                                                    : "plus",
                                                },
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "ant-upload-text",
                                                },
                                                [_vm._v("上传附件")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-card",
                        [
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: "最新发文文号",
                                "label-col": { span: 6 },
                                "wrapper-col": { span: 14 },
                              },
                            },
                            [
                              _c("a-input", {
                                attrs: { placeholder: "请输入制度名称" },
                                model: {
                                  value: _vm.params.nowno,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.params, "nowno", $$v)
                                  },
                                  expression: "params.nowno",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: "上传附件",
                                "label-col": { span: 6 },
                                "wrapper-col": { span: 14 },
                              },
                            },
                            [
                              _c(
                                "a-upload",
                                {
                                  staticClass: "avatar-uploader",
                                  attrs: {
                                    "list-type": "picture-card",
                                    accept: _vm.accept,
                                    "file-list": _vm.filePath,
                                    customRequest: _vm.resourceUpload,
                                    beforeUpload: _vm.beforeUpload,
                                    multiple: "",
                                  },
                                  on: {
                                    change: _vm.handleUploadChange,
                                    preview: _vm.handlePreview,
                                  },
                                },
                                [
                                  _vm.filePath.length < 10
                                    ? _c(
                                        "div",
                                        [
                                          _c("a-icon", {
                                            attrs: {
                                              type: _vm.uploading
                                                ? "loading"
                                                : "plus",
                                            },
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "ant-upload-text" },
                                            [_vm._v("上传附件")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-row",
            { staticStyle: { "padding-top": "16px" } },
            [
              _c("a-col", { attrs: { span: 12 } }),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        "label-col": { span: 4 },
                        "wrapper-col": { span: 14 },
                        prop: "year",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex flex-row-reverse" },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.handleSubmit },
                            },
                            [_vm._v("保存")]
                          ),
                          _c(
                            "a-button",
                            { staticClass: "mr-2", on: { click: _vm.close } },
                            [_vm._v("返回")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }