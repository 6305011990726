var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-drawer",
        {
          attrs: {
            title: "印章信息",
            width: 820,
            visible: _vm.visible,
            "body-style": { paddingBottom: "80px" },
          },
          on: { close: _vm.onClose },
        },
        [
          _c(
            "a-form",
            {
              attrs: {
                form: _vm.form,
                "label-col": { span: 5 },
                "wrapper-col": { span: 18 },
                layout: "horizontal",
              },
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "印章标题：" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "title",
                                  {
                                    rules: [
                                      { required: true, message: "请输入！" },
                                    ],
                                  },
                                ],
                                expression:
                                  "['title', { rules: [{ required: true, message: '请输入！' }] }]",
                              },
                            ],
                            attrs: { placeholder: "请输入印章标题" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "公司/项目：" } },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: ["bussType"],
                                  expression: "['bussType']",
                                },
                              ],
                              staticStyle: { width: "100%" },
                              attrs: {
                                allowClear: true,
                                placeholder: "请类型",
                              },
                            },
                            _vm._l(_vm.fileTypes, function (t) {
                              return _c(
                                "a-select-option",
                                { key: t.key, attrs: { value: t.key } },
                                [_vm._v(_vm._s(t.text))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "公司：" } },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: [
                                    "officeIds",
                                    {
                                      rules: [
                                        {
                                          required: true,
                                          message: "请选择公司",
                                        },
                                      ],
                                    },
                                  ],
                                  expression:
                                    "['officeIds',{rules: [{ required: true, message: '请选择公司' }]}]",
                                },
                              ],
                              staticStyle: { width: "100%" },
                              attrs: {
                                mode: "multiple",
                                allowClear: true,
                                filterOption: _vm.filterOption,
                              },
                              on: {
                                change: _vm.handleChange,
                                search: _vm.officeSearch,
                              },
                            },
                            _vm._l(_vm.officeDataSource, function (t) {
                              return _c(
                                "a-select-option",
                                { key: t.id, attrs: { value: t.id } },
                                [_vm._v(_vm._s(t.name))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "项目：" } },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: ["projectId"],
                                  expression: "['projectId']",
                                },
                              ],
                              staticStyle: { width: "100%" },
                              attrs: {
                                allowClear: true,
                                placeholder: "请选择项目",
                              },
                            },
                            _vm._l(_vm.projectDataSource, function (t) {
                              return _c(
                                "a-select-option",
                                { key: t.projId, attrs: { value: t.projId } },
                                [_vm._v(_vm._s(t.fullName))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "图章类型：" } },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: ["type"],
                                  expression: "['type']",
                                },
                              ],
                              staticStyle: { width: "100%" },
                              attrs: {
                                allowClear: true,
                                placeholder: "请选择图章类型",
                              },
                            },
                            _vm._l(_vm.types, function (t) {
                              return _c(
                                "a-select-option",
                                { key: t.key, attrs: { value: t.key } },
                                [_vm._v(_vm._s(t.text))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 12 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "印章文件：" } },
                        [
                          _c(
                            "a-upload",
                            {
                              attrs: {
                                name: "file",
                                multiple: false,
                                beforeUpload: _vm.beforeUpload,
                                accept: ".png,.PNG,.jpg,.JPG",
                                customRequest: _vm.resourceUpload,
                                "file-list": _vm.fileList,
                              },
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { type: "primary" },
                                },
                                [_vm._v("上传印章")]
                              ),
                              _c(
                                "span",
                                { staticStyle: { "margin-left": "10px" } },
                                [
                                  _c("strong", [_vm._v("温馨提示：")]),
                                  _c(
                                    "span",
                                    { staticStyle: { color: "blue" } },
                                    [
                                      _vm._v(
                                        "上传的章印必须是白色透明底，并且无白色边框的图章，否则展现出的效果不佳！"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("div", [
                                _c("img", {
                                  staticStyle: {
                                    "max-width": "200px",
                                    "max-height": "100px",
                                  },
                                  attrs: { src: _vm.filePath },
                                }),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 12 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "备注说明：" } },
                        [
                          _c("a-textarea", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: ["remark"],
                                expression: "['remark']",
                              },
                            ],
                            attrs: { rows: 4, placeholder: "备注说明" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              style: {
                position: "absolute",
                right: 0,
                bottom: 0,
                width: "100%",
                borderTop: "1px solid #e9e9e9",
                padding: "10px 16px",
                background: "#fff",
                textAlign: "right",
                zIndex: 1,
              },
            },
            [
              _c(
                "a-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleSubmit()
                    },
                  },
                },
                [_vm._v(" 保存 ")]
              ),
              _c(
                "a-button",
                { style: { marginLeft: "8px" }, on: { click: _vm.onClose } },
                [_vm._v(" 取消 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }