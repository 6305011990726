<template>
  <a-card class="card-radius card-padding-top" style="min-height: 655px">
    <div
      slot="title"
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <span v-if="fatherType === 'office'"
        >{{ gcglbtitle || "公司" }}检查隐患类型统计 （总计：{{
          yhzsTotal
        }}个）</span
      >
      <span v-else>项目检查隐患类型统计</span>
      <div>
        <div style="max-width: 60%; float: right">
          <a-range-picker
            v-if="queryParams.type == 3"
            v-model="queryParams.plan_date"
            :default-value="[
              moment(firstDay, 'YYYY-MM-DD'),
              moment(nowDay, 'YYYY-MM-DD'),
            ]"
            :format="dateFormat"
            @change="changeDate"
          />
          <a-month-picker
            style="width: 100px"
            v-if="queryParams.type == 2"
            @change="changeDate"
            placeholder="请选择月份"
            :default-value="moment(nowMonth, 'YYYY-MM')"
            format="YYYY-MM"
            v-model="queryParams.plan_date"
          >
            <a-icon slot="suffixIcon" type="smile" />
          </a-month-picker>
          <a-date-picker
            v-if="queryParams.type == 1"
            mode="year"
            v-model="queryParams.plan_date"
            placeholder="请输入年份"
            :default-value="moment(nowYear, 'YYYY')"
            format="YYYY"
            style="width: 100px"
            :open="yearShowOne"
            @openChange="openChangeOne"
            @panelChange="panelChangeOne"
          />
        </div>
        <a-select
          v-model="queryParams.type"
          style="width: 80px; float: right"
          @change="yhzsTypeChange"
        >
          <a-select-option :key="1"> 年 </a-select-option>
          <a-select-option :key="2"> 月 </a-select-option>
          <a-select-option :key="3">其他</a-select-option>
        </a-select>
      </div>
    </div>
    <div id="yhzsPieChart" style="width: 98%; height: 300px"></div>
    <div class="yh-total">
      <span style="color: #000">隐患总数: </span
      ><span style="color: #cd1030; font-size: 18px">{{ yhzsTotal }}</span>
    </div>
    <div id="checkTimesCateGoryChart" style="width: 98%; height: 240px"></div>
  </a-card>
</template>

<script>
import { mapGetters } from "vuex";
import { request } from "@/utils/request";
import { PROBLEMS_BY_TOTAL_QUERY, PROJECT_PROBLEMS_BY_TOTAL_QUERY } from "@/services/api";
import moment from "moment";

const columns = [
  {
    title: "隐患总数",
    dataIndex: "total",
    align: "center",
    scopedSlots: { customRender: "total" },
  },
  {
    title: "安全管理",
    dataIndex: "pb1",
    align: "center",
    scopedSlots: { customRender: "pb1" },
    className: "aqglclass",
  },
  {
    title: "文明施工",
    dataIndex: "pb2",
    align: "center",
    scopedSlots: { customRender: "pb2" },
    className: "wmsgclass",
  },
  {
    title: "脚手架",
    dataIndex: "pb3",
    align: "center",
    scopedSlots: { customRender: "pb3" },
    className: "jsjclass",
  },
  {
    title: "基坑与模板",
    dataIndex: "pb4",
    align: "center",
    scopedSlots: { customRender: "pb4" },
    className: "jkymbclass",
  },
  {
    title: "高处作业",
    dataIndex: "pb6",
    align: "center",
    scopedSlots: { customRender: "pb6" },
    className: "gczyclass",
  },
  {
    title: "施工用电",
    dataIndex: "pb7",
    align: "center",
    scopedSlots: { customRender: "pb7" },
    className: "sgydclass",
  },
  {
    title: "大型设备",
    dataIndex: "pb8",
    align: "center",
    scopedSlots: { customRender: "pb8" },
    className: "dxsbclass",
  },
  {
    title: "起重吊装",
    dataIndex: "pb54",
    align: "center",
    scopedSlots: { customRender: "pb54" },
    className: "qzdzclass",
  },
  {
    title: "施工机具",
    dataIndex: "pb11",
    align: "center",
    scopedSlots: { customRender: "pb11" },
    className: "sgjjclass",
  },
];
export default {
  data() {
    return {
      gcglbtitle: "",
      yhzsTotal: 0,
      queryParams: {
        type: 2,
        plan_date: null,
        xjTimeStr: moment(new Date()).format("YYYY-MM"),
      },
      firstDay: null,
      nowDay: null,
      nowMonth: null,
      nowYear: null,
      yearShowOne: false,
      columns,
      cateGoryColor: [
        "#fc8251",
        "#5470c6",
        "#91cd77",
        "#75bedc",
        "#f9c956",
        "#ef6567",
        "#45C2E0",
        "#C1EBDD",
        "#FFC851",
      ],
      moment,
    };
  },
  props: {
    // 父组件类型
    // office-公司首页 project-项目首页
    fatherType: {
      type: String,
      default: "office",
    },
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  created() {
    this.nowMonth = moment(new Date()).format("YYYY-MM");
    this.firstDay = moment(new Date()).format("YYYY-MM") + "-01";
    this.nowDay = moment(new Date()).format("YYYY-MM-DD");
    this.nowYear = moment(new Date()).format("YYYY");

    let type = this.$store.getters["account/user"].isGcglb;
    if (type === 1) {
      this.gcglbtitle = "工程管理部";
    }

    this.$nextTick(this.doQuery);
  },
  methods: {
    /**
     * 获取时间段的台账信息
     */
    doQuery() {
      this.tableLoading = true;
      if (this.queryParams.type == 1 && this.queryParams.plan_date != null) {
        this.queryParams.xjTimeStr = this.queryParams.plan_date.format("YYYY");
      }
      if (this.queryParams.type == 2 && this.queryParams.plan_date != null) {
        this.queryParams.xjTimeStr =
          this.queryParams.plan_date.format("YYYY-MM");
      }

      const url = this.fatherType === 'office' ? PROBLEMS_BY_TOTAL_QUERY : PROJECT_PROBLEMS_BY_TOTAL_QUERY
      if (this.fatherType === 'project') {
        this.queryParams.projectId = this.user.projectId
      }
      request(url, "post", this.queryParams).then((res) => {
        let obj = [];
        let pieData = [];
        this.columns.forEach((c) => {
          if (c.dataIndex != "total") {
            obj.push(c.title);
          }
        });
        let obj2 = [];
        if (res.data.code == 0) {
          this.dataSourceTypeCount = Array.isArray(res.data.data) ? res.data.data : [res.data.data];
          this.dataSourceTypeCount.forEach((obj) => {
            this.yhzsTotal = obj.total;
            this.$emit('updateYhzsTotal', this.yhzsTotal)
            obj.key = obj.id;
            this.columns.forEach((c) => {
              if (c.dataIndex != "total") {
                obj2.push(obj[c.dataIndex]);
                let pie = {
                  name: c.title,
                  value: obj[c.dataIndex] || 0,
                };
                pieData.push(pie);
              }
            });
          });
        }
        this.initYhzsPieChart(pieData);
        this.initCheckTimesCateGoryChart(pieData);
      });
    },
    initYhzsPieChart(data) {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("yhzsPieChart"));
      // 指定图表的配置项和数据
      let option = {
        title: {
          text: "",
          subtext: "",
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          show: false,
          orient: "vertical",
          left: "left",
        },
        color: [
          "#fc8251",
          "#5470c6",
          "#91cd77",
          "#75bedc",
          "#f9c956",
          "#ef6567",
          "#45C2E0",
          "#C1EBDD",
          "#FFC851",
        ],
        // color: ['#2F7CF9', '#2F51F9', '#6FA6FF', '#0E9BCA', '#00BBF4', '#6ED9FA', '#BECCFF', '#93AAFD', '#6184FF'],
        series: [
          {
            // name: 'Access From',
            type: "pie",
            radius: "55%",
            label: {
              normal: {
                show: true,
                // formatter: '{b} : {c} ({d}%)' //带当前图例名 + 百分比
                formatter: "{b} ({d}%)", //带当前图例名 + 百分比
              },
            },
            labelLine: { show: true },
            data: data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    initCheckTimesCateGoryChart(data1) {
      // 遍历data数组，为每个对象添加itemStyle属性并赋予对应的颜色值
      data1.forEach((item, index) => {
        this.$set(item, "itemStyle", { color: this.cateGoryColor[index] });
      });
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(
        document.getElementById("checkTimesCateGoryChart")
      );
      // 指定图表的配置项和数据
      let option = {
        // color: ['#9BCA63'],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: this.categoryTitle,
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: { interval: 0, rotate: 0, formatter: "{value}" },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            // name: ['检查次数','123'],
            type: "bar",
            barWidth: "60%",
            data: data1,
            label: {
              show: true,
              position: "outside",
            },
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    openChangeOne(status) {
      if (status) {
        this.yearShowOne = true;
      }
    },
    // 得到年份选择器的值
    panelChangeOne(value) {
      this.yearShowOne = false;
      this.queryParams.plan_date = value;
      this.$nextTick(function () {
        this.doQuery();
      });
    },
    changeDate(value) {
      this.queryParams.plan_date = value;
      this.$nextTick(function () {
        this.doQuery();
      });
    },
    yhzsTypeChange() {
      if (this.queryParams.type == 1 || this.queryParams.type == 2) {
        this.queryParams.plan_date = moment(new Date());
      } else {
        this.queryParams.plan_date = null;
      }
      this.$nextTick(function () {
        this.doQuery();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.card-radius {
  border-radius: 10px;
}
.card-padding-top {
  /deep/ .ant-card-body {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.yh-total {
  box-sizing: border-box;
  border: 1px solid rgb(229, 229, 239);
  border-radius: 6px;
  width: 144px;
  text-align: center;
  line-height: 37px;
  position: absolute;
  top: 53%;
  z-index: 1;
}
</style>
