import { TX_GET_PERSON_LIST, TX_CREATE_GROUP, TX_CREATE_PERSON, TX_DELETE_PERSON} from '@/services/api'
import {request, METHOD, removeAuthorization} from '@/utils/request'

/**
 * 获取人员列表
 */
export async function getPersonList(params) {
    return request(TX_GET_PERSON_LIST, METHOD.GET, params)
}

/**
 * 创建人员
 */
export async function createPerson(params) {
    return request(TX_CREATE_PERSON, METHOD.POST, params)
}

/**
 * 删除人员
 */
export async function deletePerson(params) {
    return request(TX_DELETE_PERSON, METHOD.GET, params)
}

/**
 * 创建人员组
 */
export async function createGroup(params) {
    return request(TX_CREATE_GROUP, METHOD.POST, params)
}

export default {
    getPersonList,
    createPerson,
    deletePerson,
    createGroup
}