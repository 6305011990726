<!-- 工人情况总览 组件 -->
<template>
  <div class="work-content">
    <div class="black font-16 font-bold overtext-1 flex mb-1">
      <img src="@/assets/img/payroll-card1.png" style="width: 25px; height: 25px;" alt="">
      <span class="ml-05">工人情况总览</span>
    </div>
    <a-divider />
    <div style="flex: 1; display: flex; flex-direction: column; align-items: center">
      <!-- <img src="@/assets/img/map.png" style="width: 80%; height: 150px;"> -->
      <div style="display: flex; height: 53px;">
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            margin-right: 20px;
          "
        >
          <span class="font-20 font-bold">{{
            staffData.onlineTeams || 5972
          }}</span>
          <span>累计登记</span>
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            margin-right: 20px;
          "
        >
          <span class="font-20 font-bold">{{
            staffData.totalTeams || 879
          }}</span>
          <span>当前在场</span>
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            margin-right: 20px;
          "
        >
          <span class="font-20 font-bold">{{
            staffData.todayTeams || 423
          }}</span>
          <span>今日出勤</span>
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            margin-right: 20px;
          "
        >
          <span class="font-20 font-bold"
            >{{ staffData.teamCql || 48.0 }}%</span
          >
          <span>今日出勤率</span>
        </div>
      </div>

      <a-divider />

      <!-- 工人情况饼图 -->
      <div style="flex: 1; width: 100%">
        <div
          id="jobAgeChart"
          style="margin: 0 auto; width: 60%; height: 50%; min-height: 180px;"
        ></div>
        <div
          id="jobStructureChart"
          style="margin: 0 auto; width: 60%; height: 50%; min-height: 180px;"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts';
import { debounce } from 'lodash';
import { mapGetters } from "vuex";
import {
  getWorkAgeFb,
  getJobTitleFb,
} from "@/services/statistics";
export default {
  props: {
    staffData: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  data() {
    return {
      jobAgeChart: null,
      jobAgeData: [],
      jobStructureChart: null,
      jobStructureData: []
    }
  },
  methods: {
    init() {
      const projectId = this.user.projectId || '3017a1fd47fa4647be7bf3feffe8e620'
      // 工人情况-年龄
      getWorkAgeFb(projectId).then((res) => {
        if (res.data.code === 0) {
          const map = {
            'd30': '30岁以下',
            'd55': '55岁以上',
            'd3140': '31-40岁',
            'd4155': '41-55岁'
          }
          this.jobAgeData = Object.entries(res.data.data[0]).map(([key, value]) => {
            return {
              name: map[key],
              value
            }
          });
          this.jobAgeChart.setOption({
            series: [
              {
                data: this.jobAgeData // 更新数据
              }
            ]
          });
        }
      });
      // 工人情况-工种结构
      getJobTitleFb(projectId).then((res) => {
        if (res.data.code === 0) {
          this.jobStructureData = res.data.data.map((item) => ({ name: item.carft, value: item.num }))
          this.jobStructureChart.setOption({
            series: [
              {
                data: this.jobStructureData // 更新数据
              }
            ]
          });
        }
      });
      this.initJobAgeChart()
      this.initJobStructureChart()
    },
    initJobAgeChart() {
      const chartDom = document.getElementById('jobAgeChart');
      this.jobAgeChart = echarts.init(chartDom);

      this.jobAgeChart.setOption({
        title: {
          text: '年龄分布', // 设置标题文本
          left: 'center', // 标题水平居中
          top: 45, // 标题距离顶部的距离
          textStyle: {
            fontWeight: 'bold', // 标题字体加粗
            fontSize: 16 // 标题字体大小
          }
        },
        tooltip: {
          trigger: 'item',
        },
        legend: {
          bottom: '20',
          left: 'center',
        },
        color: ['#fc8251', '#5470c6', '#91cd77', '#75bedc'],
        series: [
          {
            type: 'pie',
            radius: ['40%', '55%'],
            center: ['50%', '30%'],
            label: {
              show: false,
              position: 'center'
            },
            data: this.jobAgeData
          }
        ]
      });
    },
    initJobStructureChart() {
      const chartDom = document.getElementById('jobStructureChart');
      this.jobStructureChart = echarts.init(chartDom);

      this.jobStructureChart.setOption({
        title: {
          text: '工种分布', // 设置标题文本
          left: 'center', // 标题水平居中
          top: 45, // 标题距离顶部的距离
          textStyle: {
            fontWeight: 'bold', // 标题字体加粗
            fontSize: 16 // 标题字体大小
          }
        },
        tooltip: {
          trigger: 'item',
        },
        legend: {
          bottom: '0',
          left: 'center',
        },
        color: ['#fc8251', '#5470c6', '#91cd77', '#75bedc'],
        series: [
          {
            type: 'pie',
            radius: ['40%', '55%'],
            center: ['50%', '30%'],
            label: {
              show: false,
              position: 'center'
            },
            data: this.jobStructureData
          }
        ]
      });
    },
    // 处理窗口 resize 事件
    handleResize: debounce(function() {
      // 调用 ECharts 实例的 resize 方法
      this.jobAgeChart.resize()
      this.jobStructureChart.resize()
    }, 300)
  },
  mounted() {
    this.init()
    // 添加窗口 resize 事件监听
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
}
</script>

<style lang="less" scoped>
.work-content {
  display: flex;
  flex-direction: column;
  width: 400px;
  border-radius: 10px;
  padding: 10px 10px;
  background-color: #fff;
}
</style>
