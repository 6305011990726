<template>
  <div>
    <div v-show="$route.meta.showFather" class="a_Approval">
      <div class="plug-Overview">远程视频检查台账</div>
      <a-divider />
      <a-form-model layout="inline" :model="params" class="mb-5 query-form" @submit="handleSubmit">
        <a-form-model-item label="分公司">
          <a-select
            v-model="params.gcglbId"
            allowClear
            placeholder="请选择"
            style="width: 200px"
          >
            <a-select-option
              v-for="item in gcglbList"
              :key="item.value"
              :value="item.value"
              :title="item.name"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="项目名称">
          <a-input style="width: 200px" placeholder="请输入项目名称" v-model="params.projectName" allowClear/>
        </a-form-model-item>
        <a-form-model-item label="检查类别">
          <a-select placeholder="请选择检查类别" allowClear style="width: 200px" v-model="params.zxjcKey">
            <a-select-option v-for="items of checkTypeDic" :key="items.id" :value="items.id" :title="items.itemValue">
                {{ items.itemValue }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="检查状态">
          <a-select placeholder="请选择检查状态" allowClear style="width: 200px" v-model="params.status">
            <a-select-option v-for="items of selectlist" :key="items.name" :value="items.value" :title="items.name">
                {{ items.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-space>
            <a-button type="primary" html-type="submit"> 查询 </a-button>
            <a-button @click="resetQuery"> 重置 </a-button>
          </a-space>
        </a-form-model-item>
      </a-form-model>
      <div class="a_a_footer">
        <standard-table
          class=""
          :columns="columns"
          :dataSource="tableData"
          rowKey="id"
          :pagination="pagination"
          :loading="tableLoading"
          @change="handleChangePage"
          :isAlert="false"
          :scroll="{ x: 1300 }"
          :bordered="true"
        >
          <template slot="index" slot-scope="{ index }">
            <span>{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}</span>
          </template>
          <template slot="officeName" slot-scope="{ record }">
            <div v-for="(item, index) in record.officeName.split(',')" :key="index">
              <span>{{ item }}</span>
            </div>
          </template>
          <template slot="inspectonDate" slot-scope="{ record }">
            <div>{{ moment(record.askCheckTime).format('YYYY-MM-DD') }}</div>
            <div>{{ `${record.week}` }}</div>
          </template>
          <div slot="action" slot-scope="{ record }">
            <a
              v-if="user.loginType == 1"
              @click="() => handleEdit(record)"
            >
              查看
            </a>
            <a
              v-if="user.loginType == 1 && record.status == 0"
              class="ml-2"
              @click="() => handleDel(record)"
              style="color: red;"
            >
              删除
            </a>
          </div>
        </standard-table>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import {
  getVideoCheckList,
  delVideoCheckPlan,
} from "@/services/safeMonthlyReport";
import { request } from "@/utils/request";
import { QUERY_OFFICE_BY_USER, AQXJ_ZXJC } from "@/services/api";
import { mapGetters } from "vuex";
import StandardTable from "@/components/table/StandardTable";
import moment from "moment";
import { useRender } from '@/hooks/useRender.js'

const columns = [
  {
    title: "序号",
    align: "center",
    scopedSlots: { customRender: "index" },
    width: 65,
  },
  {
    title: "公司",
    align: "center",
    dataIndex: "parentOffice",
    width: 120,
  },
  {
    title: "分公司",
    align: "center",
    dataIndex: "gcglbName",
    width: 120,
  },
  {
    title: "项目名称",
    dataIndex: "projectName",
    width: 280,
    ellipsis: true,
    tooltip: true
  },
  {
    title: "检查日期",
    dataIndex: "inspectonDate",
    align: "center",
    width: 120,
    scopedSlots: { customRender: "inspectonDate" }
  },
  {
    title: "检查类别",
    dataIndex: "zxjcKey",
    align: "center",
    width: 120,
  },
  {
    title: "检查状态",
    dataIndex: "statusName",
    align: "center",
    width: 120,
  },
  {
    title: "操作",
    align: "center",
    // fixed: "right",
    width: 80,
    scopedSlots: { customRender: "action" },
  },
];

export default {
  components: {
    StandardTable
  },
  data() {
    return {
      selectlist: [{
        value: 0,
        name: '未采集视频'
      }, {
        value: 1,
        name: '采集视频中'
      },{
        value: 2,
        name: '已上传视频'
      },{
        value: 3,
        name: '已开整改单'
      }],
      params: {
        gcglbId: '',
        projectName: '',
        pageNo: 1,
        pageSize: 10,
        status: ''
      },
      pagination: {
        //分页数据
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total, range) => `共 ${total} 条`,
      },
      gcglbList: [],
      checkTypeDic: [],
      total: 0,
      columns: columns,
      tableData: [],
      tableLoading: false, //table加载中
    };
  },
  created() {
    this.getgcglbList(QUERY_OFFICE_BY_USER);
    this.getAQXJ_ZXJC(AQXJ_ZXJC);
  },
  activated() {
    this.handleQuery();
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  methods: {
    moment,
    handleEdit(row) {
      this.$router.push({
        name: '查看远程视频检查',
        query: {
          id: row.id
        },
      });
    },
    handleDel(record) {
      const _that = this
      this.$confirm({
        title: '是否确定删除？',
        cancelText: '否',
        okText: '是',
        onOk: () => {
          delVideoCheckPlan(record.id).then(res => {
            if (res.data.code === 0) {
              _that.$message.success('删除成功')
              this.handleQuery();
            } else {
              _that.$message.error(res.data.msg)
            }
          })
        },
      })
    },
    handleSubmit(e) {
      e.preventDefault();
      this.params.pageNo = 1
      this.pagination.current = 1
      this.tableData = []
      this.handleQuery();
    },
    handleQuery() {
      this.tableLoading = true
      getVideoCheckList(this.params).then((res) => {
        this.tableLoading = false
        let d = res.data;
        if (d.code === 0) {
          this.tableData = d.data.list
          this.tableData.forEach(el => {
            el.checkDate = moment(el.checkDate).format('YYYY-MM-DD')
            if (el.status == 0) {
              el.statusName = '未采集视频'
            } else if (el.status == 1) {
              el.statusName = '采集视频中'
            } else if (el.status == 2) {
              el.statusName = '已上传视频'
            } else if (el.status == 3) {
              el.statusName = '已开整改单'
            }
            el.zxjcKey = this.checkTypeDic.find(i => i.id == el.zxjcKey).itemValue
          })
          this.pagination.total = d.data.total;
          this.total = d.data.total;
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    handleChangePage(e) {
      this.params.pageNo = e.current;
      this.pagination.current = e.current;
      this.handleQuery();
    },
    // 获取工程管理部数据
    getgcglbList(path) {
      request(path, "get").then((res) => {
        let data = res.data.data.filter(item => item.id != "3da0f5533f87425c9a9e8a11a7923300")
        const arr = [];
        data.forEach((item) => {
          arr.push({
            name: item.master,
            value: item.id,
          });
        });
        this.gcglbList = arr;
      });
    },
    getAQXJ_ZXJC(path) {
      request(path, "get").then((res) => {
        res.data.data.forEach(el => {
          this.checkTypeDic.push({
            id: el.value,
            itemValue: el.label
          })
        })
      });
    },
    resetQuery() {
      this.pagination.pageNo = 1
      this.params = {
        gcglbId: '',
        projectName: '',
        pageNo: 1,
        pageSize: 10,
        status: ''
      };
      this.handleQuery();
    },
  },
};
</script>

<style lang="less" scoped>
@import "@/theme/common.less";
.query-form {
  display: flex;
  // justify-content: space-between;
}
.a_a_footer {
  background: #ffffff;

  .operator {
    margin-right: 8px;

  }
}
</style>
