<template>
  <div class="a_Approval" v-show="$route.meta.showFather">
    <div class="plug-Overview">检评列表</div>
    <a-divider />
    <a-form-model class="clearfix" :model="params" @submit="handleSubmit">
      <a-col :span="5">
        <a-form-model-item label="选择年份" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
          <a-date-picker
            mode="year"
            v-model="params.year"
            placeholder="请选择年份"
            :open="yearShowOne"
            format="YYYY"
            @openChange="openChangeOne"
            @panelChange="panelChangeOne"/>
        </a-form-model-item>
      </a-col>

      <a-col :span="4">
        <a-space class="btnBox">
          <a-button type="primary" html-type="submit"> 查询 </a-button>
          <a-button @click="resetQuery"> 重置 </a-button>
        </a-space>
      </a-col>
    </a-form-model>
    <standard-table
      class=""
      :columns="columns"
      :dataSource="tableData"
      rowKey="projectId"
      :pagination="pagination"
      :loading="tableLoading"
      @change="handleChangePage"
      :isAlert="false"
      :bordered="true"
    >
      <template slot="index" slot-scope="{ index }">
        <span>{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}</span>
      </template>
      <template slot="season" slot-scope="{ text }">
        <span v-if="text == 0">第一季度</span>
        <span v-else-if="text == 1">第二季度</span>
        <span v-else-if="text == 2">第三季度</span>
        <span v-else-if="text == 3">第四季度</span>
      </template>
      <template slot="examineScore" slot-scope="{ text, record }">
        <span v-if="text == 0">/</span>
        <span v-else>{{record.examineScore}}</span>
      </template>
    </standard-table>
  </div>
</template>
  
<script>
import {
  safeStandardList
} from "@/services/safeExamine";
import { mapGetters } from "vuex";
import StandardTable from "@/components/table/StandardTable";
import moment from "moment";

const columns = [
  {
    title: "序号",
    align: "center",
    scopedSlots: { customRender: "index" },
    width: 65,
  },
  {
    title: "工程管理部",
    align: "center",
    dataIndex: "gcglbName",
    width: 250,
  },
  {
    title: "项目名称",
    dataIndex: "projectName",
    ellipsis: true,
    tooltip: true
  },
  {
    title: "季度",
    dataIndex: "season",
    scopedSlots: { customRender: "season" },
    width: 150,
    align: "center",
  },
  {
    title: "集团项目标准化检评分数",
    dataIndex: "score",
    width: 200,
    scopedSlots: { customRender: "score" },
    align: "center",
  },
  {
    title: "集团项目标准化检评排名",
    dataIndex: "rankNum",
    width: 200,
    scopedSlots: { customRender: "rankNum" },
    align: "center",
  },
  {
    title: "年度考核得分",
    dataIndex: "examineScore",
    width: 200,
    scopedSlots: { customRender: "examineScore" },
    align: "center",
  },
  {
    title: "合计",
    dataIndex: "totalCount",
    width: 200,
    scopedSlots: { customRender: "totalCount" },
    align: "center",
  },
];

export default {
  components: {
    StandardTable
  },
  data() {
    return {
      params: {
        pageNumber: 1,
        pageSize: 999,
        sortIndex: 2,//排序规则，1.季度；2.工程管理部
        year: moment(new Date()).format('YYYY'),
      },
      pagination: {
        //分页数据
        current: 1,
        pageSize: 999,
        total: 0,
        showTotal: (total, range) => `共 ${total} 条`,
      },
      gcglbList: [],
      yearShowOne:false,
      total: 0,
      columns: columns,
      tableData: [],
      tableLoading: false, //table加载中
    };
  },
  activated() {
    this.handleQuery();
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  methods: {
    openChangeOne(status) {
      if (status) {
        this.yearShowOne = true;
      }
    },
    // 得到年份选择器的值
    panelChangeOne(value) {
      this.yearShowOne = false;
      this.params.year = value.format('YYYY');
    },
    handleSubmit(e) {
      e.preventDefault();
      this.params.pageNumber = 1
      this.pagination.current = 1
      this.handleQuery();
    },
    handleQuery(e) {
      this.tableLoading = true
      this.tableData = []
      safeStandardList(this.params).then((res) => {
        this.tableLoading = false
        let d = res.data;
        if (res.data.code === 0) {
          let totalCountArr = d.data.list.reduce(function(acc, obj) {
            let gcglbName = obj.gcglbName;
            let examineScore = obj.examineScore;

            if (acc[gcglbName]) {
              acc[gcglbName] += examineScore;
            } else {
              acc[gcglbName] = examineScore;
            }

            return acc;
          }, {});
          let totalCountArrKeys = Object.keys(totalCountArr)
          d.data.list.forEach(el => {
            totalCountArrKeys.forEach(key => {
              if (el.gcglbName == key) {
                el.totalCount = totalCountArr[key]
              }
            })
          });
          let j = 0//根据gcglbName保存有多少项相同
          let k = 0//根据gcglbName保存相同项的起始下标，因为其他项有可能不同gcglbName但还是相同的
          this.columns = this.columns.map(item => {
            if (item.dataIndex === "gcglbName") {
              let tempColIndex = 0
              item.customRender = (value, row, index) => {
                const obj = {
                  children: value,
                  attrs: {},
                };
                j = d.data.list.filter(obj => obj.gcglbName === value)
                k = d.data.list.findIndex(obj => obj.gcglbName === value)
                let l = k//临时保存该相同项的起始下标
                if (j.length > 1) {
                  //若相同项大于1则合并第一行
                  if (index === l) {
                    obj.attrs.rowSpan = j.length;
                    tempColIndex = l + 1//临时保存需要设为0的下标
                  }
                  //其他相同的要设为0
                  if (index === tempColIndex) {
                    obj.attrs.rowSpan = 0;
                    tempColIndex = tempColIndex + 1
                  }
                }
                return obj
              }
            }
            if (item.dataIndex === "totalCount") {
              let tempColIndex = 0
              item.customRender = (value, row, index) => {
                console.log(value, row, index)
                const obj = {
                  children: value,
                  attrs: {},
                };
                let l = k//临时保存该相同项的起始下标
                if (j.length > 1) {
                  //若相同项大于1则合并第一行
                  if (index === l) {
                    obj.attrs.rowSpan = j.length;
                    tempColIndex = l + 1//临时保存需要设为0的下标
                  }
                  //其他相同的要设为0
                  if (index === tempColIndex) {
                    obj.attrs.rowSpan = 0;
                    tempColIndex = tempColIndex + 1
                  }
                }
                return obj
              }
            }
            return item
          })
          this.tableData = res.data.data.list;
          this.pagination.total = res.data.data.total;
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    handleChangePage(e) {
      this.params.pageNumber = e.current;
      this.pagination.current = e.current;
      this.handleQuery();
    },
    resetQuery() {
      this.pagination.pageNumber = 1
      this.params = {
        pageNumber: 1,
        pageSize: 999,
        year: moment(new Date()).format('YYYY'),
      };
      this.handleQuery();
    },
  },
};
</script>
  
<style lang="less" scoped>
@import "@/theme/common.less";
.query-form {
  display: flex;
  // justify-content: space-between;
}
.post-border {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 5px;
  padding-top: 5px;
  // padding-top: 10px;
  // height: 40px;
}

.post-border:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
</style>
  