var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        centered: "",
        destroyOnClose: true,
        width: 1600,
        footer: null,
        maskClosable: false,
        keyboard: false,
      },
      on: { cancel: _vm.cancel },
      model: {
        value: _vm.myVisible,
        callback: function ($$v) {
          _vm.myVisible = $$v
        },
        expression: "myVisible",
      },
    },
    [
      _c("template", { slot: "title" }, [
        _c("h3", { staticClass: "flex a-center j-center font-bold" }, [
          _vm._v(" " + _vm._s(_vm.modalTitle) + " "),
        ]),
      ]),
      _c(
        "a-spin",
        { attrs: { spinning: _vm.spinning } },
        [
          _c("form-index", {
            ref: "zdbContent",
            attrs: { flexAction: true, formData: _vm.zdbContentData },
            on: {
              handleChange: _vm.handleChange,
              handlSelect: _vm.handlSelect,
              handleSubmit: _vm.handleSubmit,
            },
            scopedSlots: _vm._u([
              {
                key: "flexAction",
                fn: function () {
                  return [
                    _c(
                      "a-space",
                      { style: { marginTop: "4px" } },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: {
                              icon: "search",
                              type: "primary",
                              "html-type": "submit",
                            },
                          },
                          [_vm._v("查询")]
                        ),
                        _c("a-button", { on: { click: _vm.close } }, [
                          _vm._v(" 重置 "),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          [
            _c(
              "div",
              { staticClass: "content" },
              [
                _c("standard-table", {
                  attrs: {
                    columns: _vm.columns,
                    dataSource: _vm.dataSource,
                    rowKey: "summaryMonth",
                    scroll: { x: 700 },
                    pagination: _vm.pagination,
                    loading: _vm.tableLoading,
                    isAlert: false,
                    bordered: true,
                  },
                  on: { change: _vm.onPageChange },
                  scopedSlots: _vm._u([
                    {
                      key: "index",
                      fn: function (ref) {
                        var index = ref.index
                        return [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  (_vm.pagination.current - 1) *
                                    _vm.pagination.pageSize +
                                    parseInt(index) +
                                    1
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "projectName",
                      fn: function (ref) {
                        var text = ref.text
                        return [
                          _c(
                            "a-tooltip",
                            { attrs: { placement: "topLeft" } },
                            [
                              _c("template", { slot: "title" }, [
                                _c("span", [_vm._v(_vm._s(text))]),
                              ]),
                              _c(
                                "span",
                                { staticClass: "ant-btn-text ellipsis-2" },
                                [_vm._v(_vm._s(text))]
                              ),
                            ],
                            2
                          ),
                        ]
                      },
                    },
                    {
                      key: "action",
                      fn: function (ref) {
                        var record = ref.record
                        return [
                          _c("div", [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.viewEditing(record)
                                  },
                                },
                              },
                              [_vm._v("查看工人")]
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          _c(
            "div",
            { staticClass: "btn-box" },
            [
              _c(
                "a-space",
                { attrs: { size: 10 } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.over("cancelBtn")
                        },
                      },
                    },
                    [_vm._v("关闭")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }