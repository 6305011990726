import {request, METHOD} from '@/utils/request'
import { GET_FACILITY_STATIC_LIST, GET_FACILITY_STATIC_TOTAL, GET_WARNING_LIST, FACILITY_INFO_LIST, FACILITY_INSERT, FACILITY_DETAIL,
  FACILITY_UPDATE, FACILITY_USING_RECORD_LIST, FACILITY_DEL, FACILITY_USING_RECORD_INSERT, FACILITY_USING_RECORD_DEL, FACILITY_USING_RECORD_DETAIL,
  FACILITY_USING_RECORD_UPDATE, FACILITY_CHECK_RECORD_LIST, FACILITY_CHECK_RECORD_INSERT, FACILITY_CHECK_RECORD_DETAIL, FACILITY_CHECK_RECORD_UPDATE,
  FACILITY_CHECK_RECORD_DEL, FACILITY_MAINTENANCE_RECORD_LIST, FACILITY_MAINTENANCE_RECORD_INSERT, FACILITY_MAINTENANCE_RECORD_DETAIL,
  FACILITY_MAINTENANCE_RECORD_UPDATE, FACILITY_MAINTENANCE_RECORD_DEL, FACILITY_SELF_CHECK_RECORD_LIST, FACILITY_SELF_CHECK_RECORD_INSERT,
  FACILITY_SELF_CHECK_RECORD_DETAIL, FACILITY_SELF_CHECK_RECORD_UPDATE, FACILITY_SELF_CHECK_RECORD_DEL, FACILITY_FALLING_CHECK_RECORD_LIST,
  FACILITY_FALLING_CHECK_RECORD_INSERT, FACILITY_FALLING_CHECK_RECORD_DETAIL, FACILITY_FALLING_CHECK_RECORD_UPDATE, FACILITY_FALLING_CHECK_RECORD_DEL,
  FACILITY_FALLING_RECORD_LIST, FACILITY_FALLING_RECORD_INSERT, FACILITY_FALLING_RECORD_DETAIL, FACILITY_FALLING_RECORD_UPDATE, FACILITY_FALLING_RECORD_DEL,
  FACILITY_MAINTENANCE_EVALUATE_LIST, FACILITY_MAINTENANCE_EVALUATE_INSERT, FACILITY_MAINTENANCE_EVALUATE_DETAIL, FACILITY_MAINTENANCE_EVALUATE_UPDATE,
  FACILITY_MAINTENANCE_EVALUATE_DEL, FACILITY_MAINTENANCE_EVALUATE_SELF_DETAIL, FACILITY_INFO_BREAK, FACILITY_INFO_WARNING_TOTAL, DETAIL_PROJ_ID, GET_FACILITY_STATUS_STATIC, FALLING_WARN_LIST,
  FACILITY_MONITOR_INFO, FACILITY_EXCEPTION_INFO, SYNC_FACILITY_PROJECT_CONFIG_PAGE, SYNC_FACILITY_PROJECT_CONFIG_DELETE, SYNC_FACILITY_PROJECT_CONFIG_UPDATE, SYNC_FACILITY_PROJECT_CONFIG_CREATE,
  FACILITY_MONITOR_INFO2} from '@/services/api'


/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getFacilityProjectConfigPage(params) {
  return request(SYNC_FACILITY_PROJECT_CONFIG_PAGE, METHOD.GET, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getFacilityProjectConfigCreate(params) {
  return request(SYNC_FACILITY_PROJECT_CONFIG_CREATE, METHOD.POST, params)
}


/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getFacilityProjectConfigUpdate(params) {
  return request(SYNC_FACILITY_PROJECT_CONFIG_UPDATE, METHOD.PUT, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getFacilityProjectConfigDel(params) {
  return request(SYNC_FACILITY_PROJECT_CONFIG_DELETE, METHOD.DELETE, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getFacilityMonitorInfo(params) {
  return request(FACILITY_MONITOR_INFO, METHOD.GET, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getFacilityMonitorInfo2(params) {
  return request(FACILITY_MONITOR_INFO2, METHOD.GET, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getFacilityExceptionInfo(params) {
  return request(FACILITY_EXCEPTION_INFO, METHOD.GET, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getFacilityStatusStatic(params) {
  return request(GET_FACILITY_STATUS_STATIC, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getFallingWarnList(params) {
  return request(FALLING_WARN_LIST, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getProjectDetail(params) {
  return request(DETAIL_PROJ_ID + `/${params}`, METHOD.GET)
}


/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function breakFacilityInfo(params) {
  return request(FACILITY_INFO_BREAK, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function facilityInfoWarningTotal(params) {
  return request(FACILITY_INFO_WARNING_TOTAL, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getMaintenanceEvaluateList(params) {
  return request(FACILITY_MAINTENANCE_EVALUATE_LIST, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function insertMaintenanceEvaluatengRecord(params) {
  return request(FACILITY_MAINTENANCE_EVALUATE_INSERT, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getFacilityMaintenanceEvaluateSelfDetail(params) {
  return request(FACILITY_MAINTENANCE_EVALUATE_SELF_DETAIL + `/${params}`, METHOD.GET)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getFacilityMaintenanceEvaluateDetail(params) {
  return request(FACILITY_MAINTENANCE_EVALUATE_DETAIL + `/${params}`, METHOD.GET)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function updateFacilityMaintenanceEvaluate(params) {
  return request(FACILITY_MAINTENANCE_EVALUATE_UPDATE, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function delFacilityMaintenanceEvaluate(params) {
  return request(FACILITY_MAINTENANCE_EVALUATE_DEL + `/${params}`, METHOD.DELETE)
}




/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getFallingRecordList(params) {
  return request(FACILITY_FALLING_RECORD_LIST, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function insertFacilityFallingRecord(params) {
  return request(FACILITY_FALLING_RECORD_INSERT, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getFacilityFallingRecordDetail(params) {
  return request(FACILITY_FALLING_RECORD_DETAIL + `/${params}`, METHOD.GET)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function updateFacilityFallingRecord(params) {
  return request(FACILITY_FALLING_RECORD_UPDATE, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function delFacilityFallingRecord(params) {
  return request(FACILITY_FALLING_RECORD_DEL + `/${params}`, METHOD.DELETE)
}




/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getFallingCheckRecordList(params) {
  return request(FACILITY_FALLING_CHECK_RECORD_LIST, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function insertFacilityFallingCheckRecord(params) {
  return request(FACILITY_FALLING_CHECK_RECORD_INSERT, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getFacilityFallingCheckRecordDetail(params) {
  return request(FACILITY_FALLING_CHECK_RECORD_DETAIL + `/${params}`, METHOD.GET)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function updateFacilityFallingCheckRecord(params) {
  return request(FACILITY_FALLING_CHECK_RECORD_UPDATE, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function delFacilityFallingCheckRecord(params) {
  return request(FACILITY_FALLING_CHECK_RECORD_DEL + `/${params}`, METHOD.DELETE)
}




/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getSelfCheckRecordList(params) {
  return request(FACILITY_SELF_CHECK_RECORD_LIST, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function insertFacilitySelfCheckRecord(params) {
  return request(FACILITY_SELF_CHECK_RECORD_INSERT, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getFacilitySelfCheckRecordDetail(params) {
  return request(FACILITY_SELF_CHECK_RECORD_DETAIL + `/${params}`, METHOD.GET)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function updateFacilitySelfCheckRecord(params) {
  return request(FACILITY_SELF_CHECK_RECORD_UPDATE, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function delFacilitySelfCheckRecord(params) {
  return request(FACILITY_SELF_CHECK_RECORD_DEL + `/${params}`, METHOD.DELETE)
}


/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getMaintenanceRecordList(params) {
  return request(FACILITY_MAINTENANCE_RECORD_LIST, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function insertFacilityMaintenanceRecord(params) {
  return request(FACILITY_MAINTENANCE_RECORD_INSERT, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getFacilityMaintenanceRecordDetail(params) {
  return request(FACILITY_MAINTENANCE_RECORD_DETAIL + `/${params}`, METHOD.GET)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function updateFacilityMaintenanceRecord(params) {
  return request(FACILITY_MAINTENANCE_RECORD_UPDATE, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function delFacilityMaintenanceRecord(params) {
  return request(FACILITY_MAINTENANCE_RECORD_DEL + `/${params}`, METHOD.DELETE)
}


/**
 *  获取项目查询类表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getFacilityStaticList(params) {
  return request(GET_FACILITY_STATIC_LIST, METHOD.POST, params)
}

/**
 *  获取项目查询类表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getFacilityStaticTotal(params) {
  return request(GET_FACILITY_STATIC_TOTAL, METHOD.POST, params)
}

/**
 *  查询预警设备列表(分页)
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getWarningList(params) {
  return request(GET_WARNING_LIST, METHOD.POST, params)
}

/**
 *  查询设备列表(分页)
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getFacilityInfoList(params) {
  return request(FACILITY_INFO_LIST, METHOD.POST, params)
}

/**
 *  设备新增
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function insertFacility(params) {
  return request(FACILITY_INSERT, METHOD.POST, params)
}

/**
 *  查询详情
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getFacilityDetail(params) {
  return request(FACILITY_DETAIL + `/${params}`, METHOD.GET)
}

/**
 *  设备台账——删除
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function delFacility(params) {
  return request(FACILITY_DEL + `/${params}`, METHOD.DELETE)
}

/**
 *  修改
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function updateFacility(params) {
  return request(FACILITY_UPDATE, METHOD.POST, params)
}

/**
 *  使用登记记录表——查询列表(分页)
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getUsingRecordList(params) {
  return request(FACILITY_USING_RECORD_LIST, METHOD.POST, params)
}

/**
 *  使用记录新增
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function insertFacilityUsingRecord(params) {
  return request(FACILITY_USING_RECORD_INSERT, METHOD.POST, params)
}

/**
 *  使用记录——删除
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function delFacilityUsingRecord(params) {
  return request(FACILITY_USING_RECORD_DEL + `/${params}`, METHOD.DELETE)
}

/**
 *  使用记录详情
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getFacilityUsingRecordDetail(params) {
  return request(FACILITY_USING_RECORD_DETAIL + `/${params}`, METHOD.GET)
}

/**
 *  使用记录修改
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function updateFacilityUsingRecord(params) {
  return request(FACILITY_USING_RECORD_UPDATE, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getCheckRecordList(params) {
  return request(FACILITY_CHECK_RECORD_LIST, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function insertFacilityCheckRecord(params) {
  return request(FACILITY_CHECK_RECORD_INSERT, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getFacilityCheckRecordDetail(params) {
  return request(FACILITY_CHECK_RECORD_DETAIL + `/${params}`, METHOD.GET)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function updateFacilityCheckRecord(params) {
  return request(FACILITY_CHECK_RECORD_UPDATE, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function delFacilityCheckRecord(params) {
  return request(FACILITY_CHECK_RECORD_DEL + `/${params}`, METHOD.DELETE)
}

