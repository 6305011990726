import { render, staticRenderFns } from "./VerifyPoints.vue?vue&type=template&id=b6f706a0&scoped=true&"
import script from "./VerifyPoints.vue?vue&type=script&lang=js&"
export * from "./VerifyPoints.vue?vue&type=script&lang=js&"
import style0 from "./VerifyPoints.vue?vue&type=style&index=0&id=b6f706a0&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6f706a0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\admin\\Desktop\\SJ\\【劳务机施】suijian_client\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b6f706a0')) {
      api.createRecord('b6f706a0', component.options)
    } else {
      api.reload('b6f706a0', component.options)
    }
    module.hot.accept("./VerifyPoints.vue?vue&type=template&id=b6f706a0&scoped=true&", function () {
      api.rerender('b6f706a0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Verifition/Verify/VerifyPoints.vue"
export default component.exports