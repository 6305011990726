import { render, staticRenderFns } from "./project-js.vue?vue&type=template&id=3d61b8d8&scoped=true&"
import script from "./project-js.vue?vue&type=script&lang=js&"
export * from "./project-js.vue?vue&type=script&lang=js&"
import style0 from "./project-js.vue?vue&type=style&index=0&id=3d61b8d8&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d61b8d8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\admin\\Desktop\\SJ\\【劳务机施】suijian_client\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3d61b8d8')) {
      api.createRecord('3d61b8d8', component.options)
    } else {
      api.reload('3d61b8d8', component.options)
    }
    module.hot.accept("./project-js.vue?vue&type=template&id=3d61b8d8&scoped=true&", function () {
      api.rerender('3d61b8d8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/projectHome/project-js.vue"
export default component.exports