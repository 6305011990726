var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c("form-index", {
            ref: "formindex",
            attrs: {
              formData: _vm.formData,
              flexAction: true,
              baiduMapBoolen: false,
            },
            on: {
              handleSubmit: _vm.handleSubmit,
              handleChange: _vm.handleChange,
              handlSelect: _vm.handlSelect,
              changeMonth: _vm.changeMonth,
            },
            scopedSlots: _vm._u([
              {
                key: "flexAction",
                fn: function () {
                  return [
                    _c(
                      "a-space",
                      { style: { marginTop: "4px" } },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: {
                              icon: "search",
                              type: "primary",
                              "html-type": "submit",
                            },
                          },
                          [_vm._v("查询")]
                        ),
                        _c("a-button", { on: { click: _vm.reset } }, [
                          _vm._v(" 重置 "),
                        ]),
                        _c(
                          "a-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.handleAddNewPayroll },
                          },
                          [_vm._v("新增")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c("standard-table", {
        staticClass: "table",
        attrs: {
          columns: _vm.columns,
          dataSource: _vm.dataSource,
          rowKey: "id",
          pagination: _vm.pagination,
          loading: _vm.tableLoading,
          isAlert: false,
          bordered: true,
          tableSize: "middle",
        },
        on: { change: _vm.onPageChange },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function (ref) {
              var index = ref.index
              return [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        (_vm.pagination.current - 1) * _vm.pagination.pageSize +
                          parseInt(index) +
                          1
                      ) +
                      " "
                  ),
                ]),
              ]
            },
          },
          {
            key: "status",
            fn: function (ref) {
              var text = ref.text
              return [
                text == 0
                  ? _c(
                      "div",
                      {
                        staticStyle: { height: "40px", "line-height": "40px" },
                      },
                      [_vm._v("草稿")]
                    )
                  : _vm._e(),
                text == 1
                  ? _c(
                      "div",
                      {
                        staticStyle: { height: "40px", "line-height": "40px" },
                      },
                      [_vm._v("待财务审核")]
                    )
                  : _vm._e(),
                text == 2
                  ? _c(
                      "div",
                      {
                        staticStyle: { height: "40px", "line-height": "40px" },
                      },
                      [_vm._v("财务驳回")]
                    )
                  : _vm._e(),
                text == 3
                  ? _c(
                      "div",
                      {
                        staticStyle: { height: "40px", "line-height": "40px" },
                      },
                      [_vm._v("已汇总")]
                    )
                  : _vm._e(),
                text == 4
                  ? _c(
                      "div",
                      {
                        staticStyle: { height: "40px", "line-height": "40px" },
                      },
                      [_vm._v("已完成")]
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "action",
            fn: function (ref) {
              var record = ref.record
              return [
                _c(
                  "div",
                  [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.goDetail(record)
                          },
                        },
                      },
                      [_vm._v("明细")]
                    ),
                    record.status == 0 || record.status == 2
                      ? _c("a-divider", { attrs: { type: "vertical" } })
                      : _vm._e(),
                    record.status == 0 || record.status == 2
                      ? _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.edit(record)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    record.status == 0 || record.status == 2
                      ? _c("a-divider", { attrs: { type: "vertical" } })
                      : _vm._e(),
                    record.status == 0 || record.status == 2
                      ? _c(
                          "a",
                          {
                            staticStyle: { color: "#ff5c77" },
                            on: {
                              click: function ($event) {
                                return _vm.del(record)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "a-modal",
        {
          attrs: {
            title: "本次制表信息录入",
            destroyOnClose: true,
            footer: null,
            maskClosable: false,
          },
          on: { cancel: _vm.closeModal },
          model: {
            value: _vm.payrollVisible,
            callback: function ($$v) {
              _vm.payrollVisible = $$v
            },
            expression: "payrollVisible",
          },
        },
        [
          _c("form-index", {
            ref: "formindexModal",
            attrs: {
              formData: _vm.formDataModal,
              flexSubmit: true,
              flexAction: true,
            },
            on: {
              resourceUpload: _vm.resourceUpload,
              upRemove: _vm.upRemove,
              handleSubmit: _vm.handleMakeTable,
              handleChange: _vm.handleModalChange,
            },
            scopedSlots: _vm._u([
              {
                key: "flexAction",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "flex a-center j-center" },
                      [
                        _c(
                          "a-space",
                          { style: { marginBottom: "20px" } },
                          [
                            _c("a-button", { on: { click: _vm.closeModal } }, [
                              _vm._v(" 返回 "),
                            ]),
                            _c(
                              "a-button",
                              {
                                attrs: {
                                  type: "primary",
                                  "html-type": "submit",
                                },
                              },
                              [_vm._v("制作工资单")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }