var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    {
      attrs: {
        loading: _vm.loading,
        "body-style": { padding: "20px 24px 8px" },
        bordered: false,
      },
    },
    [
      _c("div", { staticClass: "chart-card-header" }, [
        _c("div", { staticClass: "meta" }, [
          _c("span", { staticClass: "chart-card-title" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _c(
            "span",
            { staticClass: "chart-card-action" },
            [_vm._t("action")],
            2
          ),
        ]),
        _c("div", { staticClass: "total" }, [
          _c("span", [_vm._v(_vm._s(_vm.total))]),
        ]),
      ]),
      _c("div", { staticClass: "chart-card-content" }, [
        _c("div", { staticClass: "content-fix" }, [_vm._t("default")], 2),
      ]),
      _c("div", { staticClass: "chart-card-footer" }, [_vm._t("footer")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }