var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { background: "#FFF" } },
    [
      _c(
        "a-form",
        {
          attrs: {
            id: "components-form-demo-validate-other",
            form: _vm.form,
            "label-col": { span: 5 },
            "wrapper-col": { span: 15 },
          },
          on: { submit: _vm.handleSubmit },
        },
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { md: 12, sm: 24 } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "项目名称", "has-feedback": "" } },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "projectId",
                                {
                                  rules: [
                                    {
                                      required: true,
                                      message: "请选择用工的项目!",
                                    },
                                  ],
                                },
                              ],
                              expression:
                                "['projectId',{ rules: [{ required: true, message: '请选择用工的项目!' }] },]",
                            },
                          ],
                          attrs: {
                            disabled: _vm.view == "true",
                            placeholder: "请选择用工的项目",
                          },
                          on: { change: _vm.chooseProject },
                        },
                        _vm._l(_vm.projectList, function (item, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: item.projId } },
                            [_vm._v(_vm._s(item.fullName))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { md: 12, sm: 24 } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "入场日期" } },
                    [
                      _c("a-date-picker", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: ["inDate", _vm.config],
                            expression: "['inDate', config]",
                          },
                        ],
                        attrs: { disabled: _vm.view == "true" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { md: 12, sm: 24 } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "地址" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "address",
                              {
                                rules: [
                                  { required: true, message: "请输入地址!" },
                                ],
                              },
                            ],
                            expression:
                              "['address',{rules: [{required: true,message: '请输入地址!'}]}]",
                          },
                        ],
                        attrs: { disabled: _vm.view == "true" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { md: 12, sm: 24 } },
                [
                  _c("a-form-item", { attrs: { label: "计划工期" } }, [
                    _c(
                      "div",
                      [
                        _c("a-range-picker", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: ["plan_date", _vm.rangeConfig],
                              expression: "['plan_date', rangeConfig]",
                            },
                          ],
                          attrs: { disabled: _vm.view == "true" },
                        }),
                        !_vm.view
                          ? _c(
                              "a-upload",
                              {
                                attrs: {
                                  name: "file",
                                  multiple: false,
                                  beforeUpload: _vm.beforeUpload,
                                  accept: ".xls,.xlsx",
                                  customRequest: _vm.resourceUpload,
                                },
                              },
                              [
                                _c(
                                  "a-button",
                                  {
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: {
                                      disabled: _vm.tableLoading,
                                      type: "primary",
                                    },
                                  },
                                  [_vm._v("导入用工计划")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.dataSource.persons && _vm.dataSource.persons.length > 0
        ? _c(
            "div",
            [
              _c("a-divider", [_vm._v("用工人员信息")]),
              _c("a-form", { attrs: { layout: "horizontal" } }, [
                _c(
                  "div",
                  { class: _vm.advanced ? null : "fold" },
                  [
                    _c(
                      "a-row",
                      [
                        _c(
                          "a-col",
                          { attrs: { md: 6, sm: 18 } },
                          [
                            _c(
                              "a-form-item",
                              {
                                attrs: {
                                  label: "工人姓名",
                                  labelCol: { span: 5 },
                                  wrapperCol: { span: 18, offset: 1 },
                                },
                              },
                              [
                                _c("a-input", {
                                  attrs: { placeholder: "请输入" },
                                  model: {
                                    value: _vm.personsfiler.userName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.personsfiler,
                                        "userName",
                                        $$v
                                      )
                                    },
                                    expression: "personsfiler.userName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { md: 6, sm: 18 } },
                          [
                            _c(
                              "a-form-item",
                              {
                                attrs: {
                                  label: "班组名称",
                                  labelCol: { span: 5 },
                                  wrapperCol: { span: 18, offset: 1 },
                                },
                              },
                              [
                                _c("a-input", {
                                  attrs: { placeholder: "请输入" },
                                  model: {
                                    value: _vm.personsfiler.teamName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.personsfiler,
                                        "teamName",
                                        $$v
                                      )
                                    },
                                    expression: "personsfiler.teamName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { md: 6, sm: 18 } },
                          [
                            _c(
                              "a-form-item",
                              {
                                attrs: {
                                  label: "进场状态",
                                  labelCol: { span: 5 },
                                  wrapperCol: { span: 18, offset: 1 },
                                },
                              },
                              [
                                _c(
                                  "a-select",
                                  {
                                    model: {
                                      value: _vm.personsfiler.isInside,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.personsfiler,
                                          "isInside",
                                          $$v
                                        )
                                      },
                                      expression: "personsfiler.isInside",
                                    },
                                  },
                                  [
                                    _c(
                                      "a-select-option",
                                      { key: "-1", attrs: { value: -1 } },
                                      [_vm._v("全部")]
                                    ),
                                    _c(
                                      "a-select-option",
                                      { key: "0", attrs: { value: 0 } },
                                      [_vm._v("未进场")]
                                    ),
                                    _c(
                                      "a-select-option",
                                      { key: "1", attrs: { value: 1 } },
                                      [_vm._v("已进场")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("a-col", { attrs: { md: 6, sm: 18 } }, [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                float: "left",
                                "margin-top": "3px",
                                "margin-left": "10px",
                              },
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.doFilter()
                                    },
                                  },
                                },
                                [_vm._v("查询")]
                              ),
                              _c(
                                "a-button",
                                {
                                  staticStyle: { "margin-left": "8px" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.doReset()
                                    },
                                  },
                                },
                                [_vm._v("重置")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("a-table", {
                attrs: {
                  size: "default",
                  bordered: "",
                  columns: _vm.columns,
                  dataSource: _vm.showSource,
                  loading: _vm.tableLoading,
                  pagination: false,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "do_status",
                      fn: function (text) {
                        return _c(
                          "div",
                          {},
                          [
                            text == 1
                              ? _c("a-icon", {
                                  staticStyle: { color: "green" },
                                  attrs: { type: "check" },
                                })
                              : _c("a-icon", {
                                  staticStyle: { color: "red" },
                                  attrs: { type: "close" },
                                }),
                          ],
                          1
                        )
                      },
                    },
                  ],
                  null,
                  false,
                  3879056393
                ),
              }),
              _c("a-pagination", {
                staticStyle: { margin: "20px 50px", "text-align": "right" },
                attrs: {
                  size: "middle",
                  pageSize: _vm.pageSize,
                  total: _vm.total,
                  "show-total": function (total, range) {
                    return (
                      "第 " +
                      range[0] +
                      "-" +
                      range[1] +
                      " 条，总计 " +
                      total +
                      " 条"
                    )
                  },
                  showQuickJumper: "",
                },
                on: {
                  change: _vm.pageNumberChange,
                  showSizeChange: _vm.sizeChange,
                },
                model: {
                  value: _vm.current,
                  callback: function ($$v) {
                    _vm.current = $$v
                  },
                  expression: "current",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "subBtn" },
        [
          !_vm.view
            ? _c(
                "a-button",
                {
                  attrs: { type: "primary", "html-type": "submit" },
                  on: { click: _vm.handleSubmit },
                },
                [_vm._v(" 保存发布 ")]
              )
            : _vm._e(),
          _c(
            "a-button",
            {
              staticStyle: { "margin-left": "10px", "margin-right": "50px" },
              on: { click: _vm.doBack },
            },
            [_vm._v(" 返回 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }