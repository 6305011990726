<template>
  <div class="container">
		<standard-table
			class=""
			:columns="columns"
			:dataSource="tableData"
			rowKey="index"
			:pagination="pagination"
			:loading="tableLoading"
			@change="handleChangePage"
			:isAlert="false"
			:bordered="true"
		>
			<template slot="index" slot-scope="{ index }">
				<span>{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}</span>
			</template>
			<template slot="cyyq" slot-scope="{ text }">
				<span v-if="text == 1">合同要求</span>
				<span v-else-if="text == 2">滚动计划</span>
			</template>
			<template slot="maxCylevel" slot-scope="{ text }">
				<span v-if="text == 1">市级</span>
				<span v-else-if="text == 2">省级</span>
				<span v-else-if="text == 3">国家级</span>
				<span v-else-if="text == 4">其他</span>
			</template>
			<template slot="filePath" slot-scope="{ text }">
				<div class="flex j-center">
					<a-icon type="file-pdf" :style="{ fontSize: '32px' }" v-if="text.indexOf('pdf') != -1" />
					<img
						alt=""
						style="width: 32px"
						v-else
						:src="text"
					/>
				</div>
			</template>
		</standard-table>
  </div>
</template>
  
<script>
import {
  examineExcellent
} from "@/services/safeExamine";
import { mapGetters } from "vuex";
import StandardTable from "@/components/table/StandardTable";
import moment from "moment";

export default {
  components: {
    StandardTable
  },
  data() {
    const columns = [
      {
        title: "序号",
        align: "center",
        scopedSlots: { customRender: "index" },
        width: 65,
      },
      {
        title: "工程管理部",
        align: "center",
        dataIndex: "gcglbName",
        width: 150,
      },
      {
        title: "项目名称",
        dataIndex: "projectName",
        ellipsis: true,
        width: 250,
        // align: "center",
      },
      {
        title: "创优级别",
        dataIndex: "maxCylevel",
        scopedSlots: { customRender: "maxCylevel" },
        width: 200,
        align: "center",
      },
      {
        title: "创优要求",
        dataIndex: "cyyq",
        width: 200,
        scopedSlots: { customRender: "cyyq" },
        align: "center",
      },
      {
        title: "基础分",
        dataIndex: "baseScore",
        width: 120,
        scopedSlots: { customRender: "baseScore" },
        align: "center",
      },
      {
        title: <a-tooltip>
          <template slot="title">
            <p>1.合同范围外获得市级安全文施创优证书的，每项目加2分。</p>
            <p>2.合同范围外获得省级安全文施创优证书的，每项目加5分。</p>
            <p>3.合同范围外获得国家级安全文施创优证书的，每项目加10分。</p>
            <p>上述安全文施创优加分项中，同一项目按安全文施创优最高级别计算，且加分上限为10分。</p>
          </template>
          分数<a-icon style="margin-left:5px" type="question-circle" theme="twoTone" />
        </a-tooltip>,
        dataIndex: "score",
        width: 120,
        scopedSlots: { customRender: "score" },
        align: "center",
      },
      {
        title: "合计",
        dataIndex: "totalCount",
        width: 120,
        scopedSlots: { customRender: "totalCount" },
        align: "center",
      },
    ];
    return {
      params: {
        pageNumber: 1,
        pageSize: 10,
        year: '',
        gcglbId: '',
        projectName: ''
      },
      pagination: {
        //分页数据
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total, range) => `共 ${total} 条`,
      },
      total: 0,
      columns: columns,
      tableData: [],
      tableLoading: false, //table加载中
    };
  },
	props: {
		gcglbId: {
			type: String,
      required: true,
		},
    year: {
      type: String,
      required: true,
    }
	},
  // created() {
  //   this.handleQuery();
  // },
	watch: {
    year: {
			handler(val) {
				this.params.year = val
			},
			immediate: true
		},
		gcglbId: {
			handler(val) {
				this.params.gcglbId = val
				this.handleQuery();
			},
			immediate: true
		}
	},
  computed: {
    ...mapGetters("account", ["user"]),
  },
  methods: {
    handleQuery(e) {
      this.tableLoading = true
      examineExcellent(this.params).then((res) => {
        this.tableLoading = false
        let d = res.data;
        if (d.code === 0) {
          let totalCountArr = d.data.reduce(function(acc, obj) {
            let gcglbName = obj.gcglbName;
            let score = obj.score;
            if (acc[gcglbName]) {
              acc[gcglbName] += score;
            } else {
              acc[gcglbName] = score;
            }
            return acc;
          }, {});
          let totalCountArrKeys = Object.keys(totalCountArr)
          d.data.forEach(el => {
            el.baseScore = 3
            totalCountArrKeys.forEach(key => {
              if (el.gcglbName == key) {
                el.totalCount = totalCountArr[key] + 3 > 14 ? 13 : totalCountArr[key] + 3
              }
            })
          });
          let j = 0//根据gcglbName保存有多少项相同
          let k = 0//根据gcglbName保存相同项的起始下标，因为其他项有可能不同gcglbName但还是相同的
          this.columns = this.columns.map(item => {
            if (item.dataIndex === "gcglbName") {
              let tempColIndex = 0
              item.customRender = (value, row, index) => {
                const obj = {
                  children: value,
                  attrs: {},
                };
                j = d.data.filter(obj => obj.gcglbName === value)
                k = d.data.findIndex(obj => obj.gcglbName === value)
                let l = k//临时保存该相同项的起始下标
                if (j.length > 1) {
                  //若相同项大于1则合并第一行
                  if (index === l) {
                    obj.attrs.rowSpan = j.length;
                    tempColIndex = l + 1//临时保存需要设为0的下标
                  }
                  //其他相同的要设为0
                  if (index === tempColIndex) {
                    obj.attrs.rowSpan = 0;
                    tempColIndex = tempColIndex + 1
                  }
                }
                return obj
              }
            }
            if (item.dataIndex === "baseScore") {
              let tempColIndex = 0
              item.customRender = (value, row, index) => {
                const obj = {
                  children: value,
                  attrs: {},
                };
                let l = k//临时保存该相同项的起始下标
                if (j.length > 1) {
                  //若相同项大于1则合并第一行
                  if (index === l) {
                    obj.attrs.rowSpan = j.length;
                    tempColIndex = l + 1//临时保存需要设为0的下标
                  }
                  //其他相同的要设为0
                  if (index === tempColIndex) {
                    obj.attrs.rowSpan = 0;
                    tempColIndex = tempColIndex + 1
                  }
                }
                return obj
              }
            }
            if (item.dataIndex === "totalCount") {
              let tempColIndex = 0
              item.customRender = (value, row, index) => {
                const obj = {
                  children: value,
                  attrs: {},
                };
                let l = k//临时保存该相同项的起始下标
                if (j.length > 1) {
                  //若相同项大于1则合并第一行
                  if (index === l) {
                    obj.attrs.rowSpan = j.length;
                    tempColIndex = l + 1//临时保存需要设为0的下标
                  }
                  //其他相同的要设为0
                  if (index === tempColIndex) {
                    obj.attrs.rowSpan = 0;
                    tempColIndex = tempColIndex + 1
                  }
                }
                return obj
              }
            }
            return item
          })
          this.tableData = d.data;
          this.pagination.total = d.total;
        } else {
          this.$message.error(d.msg)
        }
      });
    },
    handleChangePage(e) {
      this.params.pageNumber = e.current;
      this.pagination.current = e.current;
      this.handleQuery();
    },
  },
};
</script>
  
<style lang="less" scoped>
@import "@/theme/common.less";
.query-form {
  display: flex;
  // justify-content: space-between;
}
.post-border {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 5px;
  padding-top: 5px;
  // padding-top: 10px;
  // height: 40px;
}

.post-border:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.sTables {
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    width: 100%;
    text-align: center;
    cursor: pointer;
  }

  div:nth-child(1) {
    color: #307dfa;
  }

  div:nth-child(2) {
    color: #ff5c77;
  }

  div:nth-child(3) {
    color: #615e83;
  }
}
</style>
  
  