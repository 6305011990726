var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "flex" } },
    [
      _c(
        "task-group",
        { staticClass: "task-group", attrs: { title: "ToDo", group: "task" } },
        _vm._l(_vm.todoList, function (item, index) {
          return _c("task-item", { key: index, attrs: { content: item } })
        }),
        1
      ),
      _c(
        "task-group",
        {
          staticClass: "task-group",
          attrs: { title: "In Progress", group: "task" },
        },
        _vm._l(_vm.inproList, function (item, index) {
          return _c("task-item", { key: index, attrs: { content: item } })
        }),
        1
      ),
      _c(
        "task-group",
        { staticClass: "task-group", attrs: { title: "Done", group: "task" } },
        _vm._l(_vm.doneList, function (item, index) {
          return _c("task-item", { key: index, attrs: { content: item } })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }