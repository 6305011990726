var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-drawer",
        {
          attrs: {
            width: 800,
            visible: _vm.visible,
            title: "编辑宿舍楼",
            "body-style": { paddingBottom: "80px" },
          },
          on: { close: _vm.onClose },
        },
        [
          _c(
            "a-form",
            {
              attrs: {
                form: _vm.form,
                "label-col": { span: 5 },
                "wrapper-col": { span: 18 },
                layout: "horizontal",
              },
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "名称：" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "name",
                                  {
                                    rules: [
                                      { required: true, message: "宿舍楼名称" },
                                    ],
                                  },
                                ],
                                expression:
                                  "['name', { rules: [{ required: true, message: '宿舍楼名称' }] }]",
                              },
                            ],
                            attrs: { placeholder: "宿舍楼名称" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "编号:" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "code",
                                  { rules: [{ message: "请输入编号" }] },
                                ],
                                expression:
                                  "['code', { rules: [{ message: '请输入编号' }] }]",
                              },
                            ],
                            attrs: { placeholder: "请输入编号" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "地址：" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "address",
                                  { rules: [{ message: "请输入宿舍地址" }] },
                                ],
                                expression:
                                  "['address', { rules: [{  message: '请输入宿舍地址' }] }]",
                              },
                            ],
                            attrs: { placeholder: "请输入宿舍地址" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 12 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "宿舍楼照片：" } },
                        [
                          _c(
                            "a-upload",
                            {
                              attrs: {
                                name: "file",
                                multiple: false,
                                beforeUpload: _vm.beforeUpload,
                                accept: ".png,.PNG,.jpg,.JPG",
                                customRequest: _vm.resourceUpload,
                                "file-list": _vm.fileList,
                              },
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: {
                                    loading: _vm.iconLoading,
                                    type: "primary",
                                  },
                                },
                                [_vm._v("照片")]
                              ),
                              _c(
                                "div",
                                { staticStyle: { "margin-top": "20px" } },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      "max-width": "200px",
                                      "max-height": "100px",
                                    },
                                    attrs: { src: _vm.filePath },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "备注信息" } },
                        [
                          _c("a-textarea", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "remark",
                                  { rules: [{ message: "备注信息" }] },
                                ],
                                expression:
                                  "['remark', { rules: [{  message: '备注信息' }] }]",
                              },
                            ],
                            attrs: {
                              placeholder: "备注信息",
                              "auto-size": { minRows: 5 },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              style: {
                position: "absolute",
                right: 0,
                bottom: 0,
                width: "100%",
                borderTop: "1px solid #e9e9e9",
                padding: "10px 16px",
                background: "#fff",
                textAlign: "right",
                zIndex: 1,
              },
            },
            [
              _c(
                "a-button",
                {
                  style: { marginLeft: "8px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.doSubmit },
                },
                [_vm._v(" 确定 ")]
              ),
              _c(
                "a-button",
                { style: { marginLeft: "8px" }, on: { click: _vm.onClose } },
                [_vm._v(" 关闭 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }