<template>
  <div class="a_Approval">
    <a-card title="预警状态" :bordered="false">
      <a-steps :current="dataSolve.status" class="mb-1">
        <!-- <a-popover slot="progressDot" slot-scope="{ index, status, prefixCls }">
          <template slot="content">
            <span>step {{ index }} status: {{ status }}</span>
          </template>
          <span :class="`${prefixCls}-icon-dot`" />
        </a-popover> -->
        <a-step>
          <template slot="title"> 未处理 </template>
          <template slot="description" v-if="dataSolve.status == 0">
            <div class="flex">
              <span class="mr-2">总包劳务员</span>
              <a @click="onSolve">查看</a>
            </div>
          </template>
        </a-step>
        <a-step>
          <template slot="title"> 处理中 </template>
          <template slot="description" v-if="dataSolve.status == 1">
            <div class="flex">
              <span class="mr-2">总包劳务员</span>
              <a @click="onSolve">查看</a>
            </div>
          </template>
        </a-step>
        <a-step>
          <template slot="title"> 处理完成 </template>
          <template slot="description">
            <div class="flex">
              <span class="mr-2">总包劳务员</span>
              <a @click="onSolve">查看</a>
            </div>
          </template>
        </a-step>
      </a-steps>
      <a-list item-layout="horizontal" :data-source="descList" :split="false">
        <a-list-item slot="renderItem" slot-scope="item">
          <a-list-item-meta>
            <a
              slot="title"
              class="font-bold font-16"
              style="color: rgba(0, 0, 0, 0.85)"
              >{{ item.title }}</a
            >
            <span
              slot="description"
              style="white-space: pre-wrap; color: rgba(0, 0, 0, 0.75)"
              >{{ item.value }}</span
            >
          </a-list-item-meta>
        </a-list-item>
      </a-list>
    </a-card>

    <a-modal
      v-model="solveDialog"
      title="处理建委预警"
      :destroyOnClose="true"
      @ok="solveSubmit"
    >
      <form-index
        ref="formSolve"
        :formData="formSolveData"
        :defaultFileList="defaultFileList"
      >
      </form-index>
    </a-modal>
  </div>
</template>

<script>
const descList = [
  {
    menuKey: "describe",
    value: "",
    title: "预警描述",
  },
  {
    menuKey: "name",
    value: "",
    title: "发生时间点",
  },
  {
    menuKey: "frequency",
    value: "",
    title: "发生频率",
  },
  {
    menuKey: "recordRule",
    value: "",
    title: "记录管理行为信息准则",
  },
  {
    menuKey: "handle",
    value: "",
    title: "处理方法",
  },
  {
    menuKey: "closeCondition",
    value: "",
    title: "结束条件",
  },
  {
    menuKey: "avoid",
    value: "",
    title: "规避方法",
  },
];
const formSolveData = [
  {
    label: "处理说明",
    placeholder: "请填写处理说明",
    decorator: [
      "processDesc",
      { rules: [{ required: false, message: "请填写处理说明" }] },
    ],
    type: "input",
    key: "processDesc",
    col: 24,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "上传凭证",
    placeholder: "上传凭证",
    decorator: [
      "annex",
      { rules: [{ required: false, message: "请上传凭证" }] },
    ],
    type: "Upload",
    key: "annex",
    col: 24,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
    class: "Upload",
    uploadLength: 5,
  },
];
import {
  getJwWarnTip,
  getJwWarnSolve,
  updateJwWarnRecord,
} from "@/services/alert";
import { mapGetters } from "vuex";
import formIndex from "@/pages/components/form/index";
export default {
  components: {
    formIndex,
  },
  data() {
    return {
      query: this.$route.query,
      obj: {},
      descList,
      dataSolve: {},
      solveDialog: false,
      formSolveData,
      defaultFileList: [], //图片数组
    };
  },

  // mounted() {
  //   if (this.query.data) {
  //     this.getJwWarnTip();
  //     this.getJwWarnSolve();
  //   }
  // },
  activated() {
    if (this.query.data) {
      this.getJwWarnTip();
      this.getJwWarnSolve();
    }
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  methods: {
    getJwWarnTip() {
      // 预警提示
      const params = {
        typeNum: this.query.data.warmNum
          ? this.query.data.warmNum
          : this.query.data.typeNum,
      };
      getJwWarnTip(params).then((res) => {
        if (res.data.code === 0) {
          this.obj = res.data.data;
          this.descList.forEach((item) => {
            Object.keys(this.obj).forEach((key) => {
              if (item.menuKey == key) {
                item.value = this.obj[key];
              }
            });
          });
        }
      });
    },
    getJwWarnSolve() {
      // 预警处理状态
      const params = {
        jwWarnOddNum: this.query.data.warmCode
          ? this.query.data.warmCode
          : this.query.data.jwWarnOddNum,
      };
      getJwWarnSolve(params).then((res) => {
        if (res.data.code === 0) {
          res.data.data.status = Number(res.data.data.status);
          this.dataSolve = res.data.data;
          if (this.dataSolve.annex) {
            this.defaultFileList = [];
            // 回显图片
            const annexArr = JSON.parse(this.dataSolve.annex);
            annexArr.forEach((item, index) => {
              this.defaultFileList.push({
                url: item.url,
                name: "image.png",
                uid: index,
              });
            });
          }
        }
      });
    },
    onSolve() {
      // 去处理弹窗
      this.solveDialog = true;
    },
    solveSubmit() {
      //暂时不收集form信息
      if (this.query.data.status == (0 || 1)) {
        this.$refs.formSolve.combinationSubmit().then((res) => {
          let params = {
            ...res,
            jwWarnOddNum: this.query.data.jwWarnOddNum,
            typeNum: this.query.data.typeNum,
            name: this.query.data.name,
          };
          updateJwWarnRecord(params).then((result) => {
            if (result.code === 0) {
              this.$message.success("处理成功");
            }
          });
        });
      }
      this.solveDialog = false;
    },
    // 图片上传
    resourceUpload() {
      console.log(this.defaultFileList, "this.defaultFileList");
    },
  },
};
</script>

<style lang="less" scoped>
.a_Approval {
  border-radius: 0 10px 10px;
  padding: 0 20px 0 20px;
  min-height: 555px;
  background: #fff;
  /deep/ .ant-card-head-title {
    font-weight: bold;
    font-size: 20px;
  }
  /deep/ .ant-card-head {
    padding: 0 24px 0 12px;
    border-bottom: none;
  }
  /deep/ .ant-card-body {
    padding: 0 24px 24px 24px;
  }
  .setting-item {
    width: 800px;
    height: 70px;
    background: #f5f5f5;
    padding: 10px 10px 10px 50px;
    margin-right: 30px;

    /deep/ .ant-form-item-label > label {
      font-size: 16px;
      color: #a1a9bc;
      margin-right: 8px;
    }
    .ant-form-inline .ant-form-item:first-child {
      margin-right: 80px;
    }
  }
  .iconStyle {
    margin-right: 6px;
    font-size: 24px;
  }
}
</style>
