<template>
  <div class="a_Approval">
    <div class="plug-Overview">
      {{
        $route.path.includes("un_project_list")
          ? "未通过创优台账"
          : "在建项目安全创优情况表"
      }}
    </div>
    <a-divider />
    <a-form-model :model="params" class="clearfix">
      <a-col v-if="user.nowRoleKey.includes('OFFICE_AQMGLY')" :span="5">
        <a-form-model-item
          label="工程管理部"
          :labelCol="{ span: 8 }"
          :wrapperCol="{ span: 14, offset: 1 }"
        >
          <a-select
            v-model="params.gcglbId"
            allowClear
            placeholder=""
          >
            <a-select-option
              v-for="item in gcglbList"
              :key="item.value"
              :value="item.value"
              :title="item.name"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>

      <a-col v-if="user.nowRoleKey.includes('OFFICE_AQMGLY')" :span="5">
        <a-form-model-item
          label="月份检评"
          :labelCol="{ span: 8 }"
          :wrapperCol="{ span: 14, offset: 1 }"
        >
          <a-month-picker
            v-model="params.checkTime"
            valueFormat="YYYY-MM"
            placeholder=""
          />
        </a-form-model-item>
      </a-col>

      <a-col :span="5">
        <a-form-model-item
          label="创优要求"
          :labelCol="{ span: 8 }"
          :wrapperCol="{ span: 14, offset: 1 }"
        >
          <a-select v-model="params.cyyq" allowClear placeholder="">
            <a-select-option value="1"> 合同要求 </a-select-option>
            <a-select-option value="2"> 滚动计划 </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>

      <a-col :span="5">
        <a-form-model-item
          label="检评状态"
          :labelCol="{ span: 8 }"
          :wrapperCol="{ span: 14, offset: 1 }"
        >
          <a-select v-model="params.checkFinishType" allowClear placeholder="">
            <a-select-option value=""> 全部 </a-select-option>
            <a-select-option value="1"> 已完成 </a-select-option>
            <a-select-option value="2"> 未完成 </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>

      <a-col :span="5">
        <a-form-model-item
          label="工程类别"
          :labelCol="{ span: 8 }"
          :wrapperCol="{ span: 14, offset: 1 }"
        >
          <a-select v-model="params.ptype" allowClear placeholder="">
            <a-select-option value=""> 全部 </a-select-option>
            <a-select-option :value="1"> 房建 </a-select-option>
            <a-select-option :value="2"> 市政 </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="5">
        <a-form-model-item
          label="是否发给协会"
          :labelCol="{ span: 9 }"
          :wrapperCol="{ span: 14, offset: 1 }"
        >
          <a-select v-model="params.isSend" allowClear placeholder="">
            <a-select-option value=""> 全部 </a-select-option>
            <a-select-option value="1"> 是 </a-select-option>
            <a-select-option value="0"> 否 </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>

      <a-col
        v-if="this.params.cylevel == 2 || this.params.cylevel == 3"
        :span="5"
      >
        <a-form-model-item
          label="立项情况"
          :labelCol="{ span: 8 }"
          :wrapperCol="{ span: 14, offset: 1 }"
        >
          <a-select v-model="params.projectStatus" allowClear placeholder="">
            <a-select-option value=""> 全部 </a-select-option>
            <a-select-option value="已立项"> 已立项 </a-select-option>
            <a-select-option value="未立项"> 未立项 </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>

      <a-col :span="4">
        <a-space class="btnBox">
          <a-button type="primary" @click="handleQuery"> 查询 </a-button>
          <a-button @click="resetQuery"> 重置 </a-button>
        </a-space>
      </a-col>
    </a-form-model>
    
    <div style="display: flex; justify-content: space-between; align-items: center;">      
      <!-- 表格按钮组 -->
      <div>
        <a-space size="middle">
          <a-button type="primary" @click="doDowload"> 导出 </a-button>
          <div>
            <div>
              <a-space size="middle">
                <span><span class="font-bold">省市政协会：</span>2172345172@qq.com</span>
                <span><span class="font-bold">省安全协会：</span>cisagd@163.com</span>
                <span><span class="font-bold">市市政协会：</span>gzsszglxh@163.com</span>
              </a-space>
            </div>
            <div>
              <a-space size="middle">
                <span><a style="border-bottom: 1px solid #76abf5;" target="_blank" href="http://sfgd.cisagd.cn/login.aspx">房建项目省双优报名网址</a></span>
                <span><a style="border-bottom: 1px solid #76abf5;" target="_blank" href="http://www.gcia.org.cn:8080/User/index.aspx">房建项目市双优报名网址</a></span>
                <span><a style="border-bottom: 1px solid #76abf5;" target="_blank" href="http://119.29.187.237:12111/account/login">市政项目市双优报名网址</a></span>
              </a-space>
            </div>
          </div>
        </a-space>
      </div>
      <a-radio-group v-model="params.cylevel" @change="handleChangeType">
        <a-radio-button value="1"> 市双优 </a-radio-button>
        <a-radio-button value="2"> 省双优 </a-radio-button>
        <a-radio-button value="3"> 国家级创优 </a-radio-button>
        <a-radio-button value="4"> 其它 </a-radio-button>
      </a-radio-group>
    </div>

    <a-table
      class="table"
      :key="params.cylevel"
      :columns="columns"
      :data-source="tableData"
      bordered
      size="default"
      :pagination="{
        defaultCurrent: params.pageNumber,
        total: total,
        pageSize: params.pageSize,
        showTotal: (total, range) => `共 ${total} 条`,
      }"
      @change="handleChangePage"
    >
      <template slot="projectName" slot-scope="text">
        <a-tooltip placement="topLeft">
          <template slot="title">
            {{ text }}
          </template>
          <div class="overtext-1">{{ text }}</div>
        </a-tooltip>
      </template>
      <template slot="projectFile" slot-scope="text, row">
        <div class="flex j-center">
          <a-icon
            type="file-pdf"
            :style="{ fontSize: '32px' }"
            v-if="row.projectFile"
            @click="handleLXPreview(row.projectFile)"
          />
          <span v-else-if="row.projectStatus == '/'">/</span>
          <span v-else></span>
        </div>
      </template>
      <template slot="isSend" slot-scope="text">
        <label v-if="text == '1'"
          ><a-icon style="font-size: 15px; color: green" type="check"
        /></label>
        <!-- <label v-else ><a-icon style="font-size:15px;color:red" type="close" /></label> -->
      </template>
      <template slot="pjsPath" slot-scope="text, row">
        <div class="flex j-center">
          <template v-if="row.pjsPath && row.pjsPath.length > 0">
            <div v-for="(path, index) in row.pjsPath.split(',')" :key="index">
              <a-icon
                type="file-pdf"
                :style="{ fontSize: '32px' }"
                v-if="path && path.indexOf('.pdf') > -1"
                @click="handlePreview(path)"
              />
              <img
                v-else
                style="width: 32px"
                :src="path"
                @click="handlePreview(path)"
              />
            </div>
          </template>
        </div>
      </template>
      <template slot="action" slot-scope="text, record">
        <a @click="() => handleEdit(record)">
          <span
            v-if="
              (user.loginType == 0 && params.cylevel == 2) ||
              (user.loginType == 1 &&
                user.isGcglb == 1 &&
                (params.cylevel == 1 ||
                  params.cylevel == 3 ||
                  params.cylevel == 4)) ||
              (user.loginType == 1 && user.isGcglb == 0)
            "
            >编辑</span
          >
          <span
            v-if="
              (user.loginType == 0 &&
                (params.cylevel == 1 ||
                  params.cylevel == 3 ||
                  params.cylevel == 4)) ||
              (user.loginType == 1 && user.isGcglb == 1 && params.cylevel == 2)
            "
            >查看</span
          >
        </a>
      </template>
    </a-table>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="" style="width: 100%" :src="previewImage" />
    </a-modal>
    <Export-loading
      :cPercent="cPercent"
      :isExporting="isExporting"
      :loadingText="loadingText"
    ></Export-loading>
  </div>
</template>

<script>
import {
  getExcellentList,
  LabourProjectExcellentExportUrl,
} from "@/services/labourProjectExcellent";
import { request } from "@/utils/request";
import { QUERY_OFFICE_BY_USER } from "@/services/api";
import ExportLoading from "@/components/ExportLoading/ExportLoading"; // progress组件
import { exportProgress } from "@/pages/aqjyzl/export"; // 导出方法
import { mapGetters } from "vuex";
import { useRender } from '@/hooks/useRender.js'

let tmpColumns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    width: 65,
  },
  {
    title: "工程管理部",
    align: "center",
    dataIndex: "gcglbName",
    width: 120,
  },
  {
    title: "项目名称",
    dataIndex: "projectName",
    scopedSlots: { customRender: "projectName" },
    ellipsis: true,
    tooltip: true,
  },
  {
    title: "安全文明创优要求",
    dataIndex: "cyyqName",
    align: "center",
    width: 120,
  },
  {
    title: "申报情况",
    dataIndex: "applyStateName",
    align: "center",
    width: 90,
  },
  {
    title: "市双优检评时间",
    align: "center",
    children: [
      {
        title: "基础",
        dataIndex: "cityDateBase",
        align: "center",
        width: 130,
        customRender: (text) => useRender.renderDate(text)
      },
      {
        title: "主体",
        dataIndex: "cityDateMajor",
        align: "center",
        width: 130,
        customRender: (text) => useRender.renderDate(text)
      },
      {
        title: "装饰装修",
        dataIndex: "cityDateDecorate",
        align: "center",
        width: 130,
        customRender: (text) => useRender.renderDate(text)
      },
    ],
  },
  {
    title: "安全评价书",
    dataIndex: "pjsPath",
    width: 110,
    scopedSlots: { customRender: "pjsPath" },
    align: "center",
  },
  {
    title: "是否发给协会",
    dataIndex: "isSend",
    width: 100,
    align: "center",
    scopedSlots: { customRender: "isSend" },
  },
  {
    title: "备注",
    dataIndex: "comment",
    width: 180,
    align: "center",
    tooltip: true,
  },
  {
    title: "操作",
    dataIndex: "operation",
    align: "center",
    fixed: "right",
    width: 100,
    scopedSlots: { customRender: "action" },
  },
];

export default {
  name: "LabourProjectExcellent",
  mixins: [exportProgress], //导出方法注入
  components: {
    ExportLoading,
  },
  data() {
    return {
      params: {
        checkFinishType: "",
        cylevel: "1",
        gcglbId: "",
        cyyq: "",
        pageNumber: 1,
        pageSize: 10,
        officeId: "",
        ptype: "",
        projectStatus: "",
        isSend: "",
        isFailed: 0,
        checkTime: "",
      },
      gcglbList: [],
      cyyqOption: [
        { label: "合同要求", value: 1 },
        { label: "滚动计划", value: 2 },
      ],
      checkFinishTypeOption: [
        { label: "已完成", value: 1 },
        { label: "未完成", value: 2 },
      ],
      applyStateOption: [
        { label: "未申报", value: 1 },
        { label: "已申报", value: 2 },
      ],
      total: 0,
      type: "small",
      columns: [],
      tableData: [],

      loadingText: "数据加载中...",
      cPercent: 0,
      isExporting: false,

      previewVisible: false,
      previewImage: "",
    };
  },
  created() {
    this.handleChangeType();
    this.getgcglbList(QUERY_OFFICE_BY_USER);
  },
  activated() {
    this.handleQuery();
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  methods: {
    handleQuery(e) {
      /**
       * 创优级别 cylevel:  1.市级，2.省级，3.国家级，4.其他
       * 检评状态 checkFinishType 1.已完成，2.未完成
       * 安全文施创要求 cyyq 1.合同要求，2.滚动计划
       */
      this.params.officeId = this.user.officeId;
      // 过滤 params 中 为空的项
      let params = Object.keys(this.params).reduce((prev, cur) => {
        if (this.params[cur] != "") {
          prev[cur] = this.params[cur];
        }
        return prev;
      }, {});
      if (this.params.cylevel != 2 && this.params.cylevel != 3) {
        delete params.projectStatus;
      }
      if (this.$route.path.includes("un_project_list")) {
        this.params.isFailed = params.isFailed = 1; //未通过创优
        let i = this.columns.findIndex((item) => item.title == "安全评价书");
        if (i > 0) {
          this.columns.splice(i, 1);
        }
        let j = this.columns.findIndex((item) => item.title == "是否发给协会");
        if (j > 0) {
          this.columns.splice(j, 1);
        }
      } else {
        this.params.isFailed = params.isFailed = 0; //通过创优
      }
      getExcellentList(params).then((res) => {
        let d = res.data;
        if (d.code === 0) {
          this.tableData = d.data.records.map((item, index) => {
            let cyyq = this.cyyqOption.find((i) => i.value == item.cyyq);
            let checkFinishType = this.checkFinishTypeOption.find(
              (i) => i.value == item.checkFinishType
            );
            let applyState = this.applyStateOption.find(
              (i) => i.value == item.applyState
            );

            item.cyyqName = cyyq ? cyyq.label : "";
            item.checkFinishTypeName = checkFinishType
              ? checkFinishType.label
              : "";
            item.applyStateName = applyState ? applyState.label : "";
            item.index = index + 1;
            return item;
          });

          this.total = d.data.total;
        } else {
          this.$message.error(d.msg);
        }
      });
    },
    handleEdit(row) {
      this.$router.push({
        path: `/project_detail_${this.params.cylevel}`,
        query: {
          id: row.id,
          pageType: 1,
          cylevel: this.params.cylevel,
          isBook: 0,
        },
      });
    },
    handleLXPreview(item) {
      // 打开新页面
      window.open(item);
    },
    handlePreview(item) {
      if (item.indexOf(".pdf") > -1) {
        // 打开新页面
        window.open(item);
      } else {
        // 打开预览
        this.previewImage = item;
        this.previewVisible = true;
      }
    },
    handleCancel() {
      this.previewVisible = false;
    },
    handleChangePage(pagination) {
      this.params.pageNumber = pagination.current;
      this.handleQuery();
    },
    handleChangeType() {
      // 调整 columns
      // 市双优 其他
      let t1 = JSON.parse(JSON.stringify(tmpColumns));

      // 省双优 update 简评时间
      let t2 = JSON.parse(JSON.stringify(tmpColumns));
      t2.splice(5, 1);
      t2.splice(5, 1);
      t2[5].title = "省双优检评时间";
      t2[5].children = [
        {
          title: "初评",
          dataIndex: "provinceDateFirst",
          align: "center",
          width: 120,
          customRender: (text) => useRender.renderDate(text)
        },
        {
          title: "复评",
          dataIndex: "provinceDateSecond",
          align: "center",
          width: 120,
          customRender: (text) => useRender.renderDate(text)
        },
      ];
      t2.splice(5, 0, {
        title: "立项情况",
        // dataIndex: "projectStatus",
        align: "center",
        // width: 140,
        children: [
          {
            title: "立项状态",
            dataIndex: "projectStatus",
            align: "center",
            width: 100,
          },
          {
            title: "立项表",
            dataIndex: "projectFile",
            align: "center",
            width: 100,
            scopedSlots: { customRender: "projectFile" },
          },
        ],
      });

      // 国家级创优 del 创优要求 简评时间  add 简评时间 备注
      let t3 = JSON.parse(JSON.stringify(tmpColumns));
      t3.splice(3, 1);
      t3.splice(4, 1, {
        title: "国家级双优检评时间",
        dataIndex: "countryDateStandard",
        align: "center",
        customRender: (text) => useRender.renderDate(text)
      });
      t3.splice(4, 0, {
        title: "立项情况",
        // dataIndex: "projectStatus",
        align: "center",
        // width: 140,
        children: [
          {
            title: "立项状态",
            dataIndex: "projectStatus",
            align: "center",
            width: 100,
          },
          {
            title: "立项表",
            dataIndex: "projectFile",
            align: "center",
            width: 100,
            scopedSlots: { customRender: "projectFile" },
          },
        ],
      });
      t3.splice(6, 1);
      t3.splice(6, 1);
      // t3.push({
      //   title: "备注",
      //   dataIndex: "remark",
      //   align: "center",
      // });

      if (this.params.cylevel == 1) {
        this.columns = t1;
      } else if (this.params.cylevel == 2) {
        this.columns = t2;
      } else if (this.params.cylevel == 3) {
        this.columns = t3;
      } else if (this.params.cylevel == 4) {
        t1.splice(6, 1);
        t1.splice(6, 1);
        this.columns = t1;
      }
      this.$forceUpdate();
      this.handleQuery();
    },
    // 获取工程管理部数据
    getgcglbList(path) {
      request(path, "get").then((res) => {
        let data = res.data.data.filter(
          (item) => item.id != "3da0f5533f87425c9a9e8a11a7923300"
        );
        const arr = [];
        data.forEach((item) => {
          arr.push({
            name: item.master,
            value: item.id,
          });
        });
        this.gcglbList = arr;
      });
    },
    resetQuery() {
      this.params = {
        checkFinishType: "",
        cylevel: "1",
        gcglbId: "",
        cyyq: "",
        pageNumber: 1,
        pageSize: 10,
        checkTime: "",
      };
      this.handleQuery();
    },
    doDowload() {
      let obj = this.params;
      this.loadingText = "正在导出EXCEL文件，请稍候 ····";
      this.isExporting = true;
      this.cPercent = 0;
      let fileName = "在建项目安全创优情况表.xlsx";
      this.exportFunNomal2(
        LabourProjectExcellentExportUrl,
        obj,
        fileName,
        "application/vnd.ms-excel"
      );
    },
  },
};
</script>

<style lang="less" scoped>
@import "@/theme/common.less";
.query-form {
  display: flex;
  // justify-content: space-between;
}
/deep/.ant-table-thead > tr > th {
  text-align: center;
}

.table {
  margin-top: 12px;
}
</style>
