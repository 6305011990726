<template>
  <div class="exception-page">
    <div class="img">
      <img :src="config[type].img" />
    </div>
    <div class="content">
      <h1>{{config[type].title}}</h1>
      <div class="desc">{{config[type].desc}}</div>
      <!-- 404且无任何菜单，增加一段提示语 -->
      <div v-if="type === '404' && !hasMenu" class="tips">您的账号无任何菜单，请联系管理员开通</div>

      <a-space>
        <!-- 有菜单才显示该按钮 -->
        <a-button v-if="hasMenu" type="primary" @click="backHome">返回首页</a-button>
        <!-- 退登按钮始终显示 -->
        <a-button @click="logout">退出登录</a-button>
      </a-space>
    </div>
  </div>
</template>

<script>
import Config from './typeConfig'
import { mapGetters } from 'vuex'
import { logout } from '@/services/user.js'

export default {
  name: 'ExceptionPage',
  props: ['type', 'homeRoute'],
  computed: {
    ...mapGetters('route', ['routeList']),
    hasMenu() {
      return this.routeList && this.routeList.length
    }
  },
  data () {
    return {
      config: Config,
    }
  },
  mounted() {
    console.log(this.homeRoute,'this.homeRoute==')
    console.log(this.type,'this.type==')
  },
  methods: {
    backHome() {
      if (this.homeRoute) {
        this.$router.push({name: this.homeRoute })
      }
      this.$emit('backHome', this.type)
    },
    logout() {
      logout()
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="less" scoped>
  .exception-page{
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: @base-bg-color;
    .img{
      padding-right: 52px;
      zoom: 1;
      img{
        max-width: 430px;
      }
    }
    .content{
      h1{
        color: #434e59;
        font-size: 72px;
        font-weight: 600;
        line-height: 72px;
        margin-bottom: 24px;
      }
      .desc{
        color: @text-color-second;
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 8px;
      }
      .tips {
        color: @text-color-second;
        margin-bottom: 16px;
      }
    }
  }

</style>
