<template>
  <div>
    <div class="a_Approval" v-show="$route.meta.showFather">
      <a-tabs :default-active-key="defaultActiveKey">
        <a-tab-pane key="1" tab="集团标准化检评情况">
          <group-project></group-project>
        </a-tab-pane>
        <a-tab-pane key="2" tab="投诉">
          <group-project-complaint></group-project-complaint>
        </a-tab-pane>
        <a-tab-pane key="3" tab="约谈">
          <group-project-interview></group-project-interview>
        </a-tab-pane>
      </a-tabs>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import GroupProject from './groupProject'
import GroupProjectComplaint from './groupProjectComplaint.vue';
import GroupProjectInterview from './groupProjectInterview.vue';
export default {
  components: { 
    GroupProject,
    GroupProjectComplaint,
    GroupProjectInterview
  },
  data() {
    return {
      defaultActiveKey: "1",
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>