var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "a_Approval" },
      [
        _vm.tenantId == 1 || _vm.isOrg || _vm.isOffice
          ? _c("form-index", {
              ref: "formindex",
              attrs: {
                projectList: _vm.projectList,
                formData: _vm.formData,
                Overview: "完工工程一览表",
                baiduMapBoolen: false,
                flexAction: "",
              },
              on: {
                handleSubmit: _vm.handleSubmit,
                handleChange: _vm.handleChange,
                handlSelect: _vm.handlSelect,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "flexAction",
                    fn: function () {
                      return [
                        _c(
                          "a-space",
                          { staticClass: "btnBox j-start ml-4" },
                          [
                            _c(
                              "a-button",
                              {
                                attrs: {
                                  type: "primary",
                                  "html-type": "submit",
                                },
                              },
                              [_vm._v(" 查询 ")]
                            ),
                            _c("a-button", { on: { click: _vm.close } }, [
                              _vm._v(" 重置 "),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1478269157
              ),
            })
          : _vm._e(),
        _c("standard-table", {
          attrs: {
            columns: _vm.columns,
            dataSource: _vm.dataSource,
            rowKey: "id",
            pagination: _vm.pagination,
            loading: _vm.tableLoading,
            isAlert: false,
            bordered: true,
            btnArr: _vm.tableBtnArr,
          },
          on: { change: _vm.onPageChange },
          scopedSlots: _vm._u([
            {
              key: "index",
              fn: function (ref) {
                var index = ref.index
                return [
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          (_vm.pagination.current - 1) *
                            _vm.pagination.pageSize +
                            parseInt(index) +
                            1
                        ) +
                        " "
                    ),
                  ]),
                ]
              },
            },
            {
              key: "do-status",
              fn: function (ref) {
                var record = ref.record
                var text = ref.text
                return _c(
                  "div",
                  {},
                  [
                    record.isReport == 1 || record.pjsPath
                      ? [
                          _vm._l(text.split(","), function (item, index) {
                            return [
                              item != "" && item != null
                                ? _c(
                                    "div",
                                    { key: index },
                                    [
                                      _vm.icludesPdf(item, _vm.str) == -1
                                        ? [
                                            item != "" && item != null
                                              ? _c("img", {
                                                  staticStyle: {
                                                    width: "40px",
                                                    height: "40px",
                                                    cursor: "pointer",
                                                  },
                                                  attrs: { src: item },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.showImg(item)
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                            _c(
                                              "a-modal",
                                              {
                                                attrs: {
                                                  width: 1100,
                                                  visible: _vm.modalVisible,
                                                  footer: null,
                                                },
                                                on: {
                                                  cancel: _vm.handleCancel,
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    alt: "example",
                                                    src: _vm.previewImage,
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        : _vm.icludesPdf(item, _vm.str) != -1
                                        ? [
                                            _c("a-icon", {
                                              style: { fontSize: "35px" },
                                              attrs: { type: "file-pdf" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showPdf(item)
                                                },
                                              },
                                            }),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ]
                          }),
                        ]
                      : [_vm._v(" / ")],
                  ],
                  2
                )
              },
            },
            {
              key: "pjDate",
              fn: function (ref) {
                var record = ref.record
                var text = ref.text
                return _c("div", {}, [
                  record.isReport == 1 || record.pjsPath
                    ? _c("span", [
                        _vm._v(
                          " " + _vm._s(_vm.useRender.renderDate(text)) + " "
                        ),
                      ])
                    : _c("span", [_vm._v(" / ")]),
                ])
              },
            },
            {
              key: "action",
              fn: function (ref) {
                var record = ref.record
                return _c("div", {}, [
                  _c("div", { staticClass: "sTable" }, [
                    _c("div", [
                      !_vm.isOrg
                        ? _c(
                            "div",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.viewEditing(record)
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ])
              },
            },
          ]),
        }),
        _c("Export-loading", {
          attrs: {
            cPercent: _vm.cPercent,
            isExporting: _vm.isExporting,
            loadingText: _vm.loadingText,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }