var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "constructiontTable_index" },
    [
      _c(
        "a-row",
        { attrs: { gutter: 16 } },
        [
          _vm.isAdmin
            ? _c(
                "a-col",
                { attrs: { span: 7 } },
                [
                  _c(
                    "a-card",
                    {
                      staticClass: "card-left",
                      staticStyle: { height: "900px" },
                      style: "overflow:auto",
                      attrs: { title: "公司结构", bordered: false },
                    },
                    [
                      _c("a-tree", {
                        attrs: {
                          "show-line": false,
                          "expanded-keys": _vm.expandedKeys,
                          "auto-expand-parent": true,
                          "tree-data": _vm.officeTreeData,
                          replaceFields: {
                            children: "children",
                            title: "name",
                            key: "id",
                          },
                        },
                        on: { expand: _vm.onExpand, select: _vm.onSelect },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-col",
            { attrs: { span: _vm.isAdmin ? 17 : 24 } },
            [
              _c(
                "a-card",
                {
                  staticClass: "card-detail",
                  staticStyle: { "min-height": "900px" },
                  style: "overflow:auto",
                },
                [
                  _c(
                    "a-tabs",
                    {
                      attrs: { type: "card", forceRender: "" },
                      on: { change: _vm.callback },
                    },
                    [
                      _c(
                        "a-tab-pane",
                        { key: "1", attrs: { tab: "基本信息" } },
                        [
                          _c("companyBasic", {
                            attrs: { officeId: _vm.officeId },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }