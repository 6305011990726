var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.$route.meta.showFather,
              expression: "$route.meta.showFather",
            },
          ],
          staticClass: "a_Approval",
        },
        [
          _c("div", { staticClass: "plug-Overview" }, [_vm._v("已拆除设备表")]),
          _c("a-divider"),
          _c("form-index", {
            ref: "formindex",
            attrs: { formData: _vm.formData, flexAction: "" },
            on: { handleSubmit: _vm.handleSubmit },
            scopedSlots: _vm._u([
              {
                key: "flexAction",
                fn: function () {
                  return [
                    _c(
                      "a-space",
                      { staticClass: "btnBox" },
                      [
                        _c(
                          "a-button",
                          { attrs: { type: "primary", "html-type": "submit" } },
                          [_vm._v("查询")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c(
            "div",
            { staticClass: "a_a_footer" },
            [
              _c("standard-table", {
                attrs: {
                  columns: _vm.columns,
                  dataSource: _vm.dataSource,
                  selectedRows: _vm.selectedRows,
                  rowKey: "id",
                  pagination: _vm.pagination,
                  loading: _vm.tableLoading,
                  isAlert: false,
                  bordered: true,
                  btnArr: _vm.tableBtnArr,
                },
                on: {
                  "update:selectedRows": [
                    function ($event) {
                      _vm.selectedRows = $event
                    },
                    function ($event) {
                      return _vm.updateSelectRows($event)
                    },
                  ],
                  "update:selected-rows": function ($event) {
                    _vm.selectedRows = $event
                  },
                  change: _vm.onPageChange,
                },
                scopedSlots: _vm._u([
                  {
                    key: "index",
                    fn: function (ref) {
                      var index = ref.index
                      return [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                (_vm.pagination.current - 1) *
                                  _vm.pagination.pageSize +
                                  parseInt(index) +
                                  1
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "action",
                    fn: function (ref) {
                      var record = ref.record
                      return _c("div", {}, [
                        _c("div", { staticClass: "sTables" }, [
                          _c(
                            "div",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.visit(record)
                                },
                              },
                            },
                            [_vm._v("查看")]
                          ),
                        ]),
                      ])
                    },
                  },
                ]),
              }),
              _c("Export-loading", {
                attrs: {
                  cPercent: _vm.cPercent,
                  isExporting: _vm.isExporting,
                  loadingText: _vm.loadingText,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }