<template>
  <div class="container">
    <h3>新增事件记录</h3>
    <form-index
      ref="formindex"
      :formData="formData"
      @handleSubmit="handleSubmit"
      :bottomSubmit="true"
      @handlSelect="handlSelect"
      @resourceUpload="resourceUpload"
      @upRemove="upRemove"
    >
      <!-- 下部分按钮插槽 -->
      <template #bottomSubmit>
        <a-space>
          <a-button type="primary" html-type="submit"> 确定 </a-button>
          <a-button @click="close"> 关闭 </a-button>
        </a-space>
      </template>
    </form-index>
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import { addNewEventRecord, updataEventRecord, getAllTeams, eventRecordDetail } from "@/services/participationUnit";
import { getOfficeListByProjectId } from '@/services/payroll'
import eventType from '@/pages/advanceRetreat/eventType.json'
import moment from "moment";
const formData = [
  {
    label: "所属单位",
    placeholder: "请选择所属单位",
    decorator: [
      "officeId",
      { rules: [{ required: true, message: "请选择所属单位" }] },
    ],
    type: "select",
    key: "officeId",
    selectlist: [],
    dataSource: [],
    labelCol: 6,
    wrapperCol: 14,
    col: 12,
    display: true,
  },
  {
    label: "班组",
    placeholder: "请选择班组",
    decorator: [
      "teamId",
      { rules: [{ required: false, message: "请选择班组" }] },
    ],
    type: "select",
    key: "teamId",
    selectlist: [],
    dataSource: [],
    labelCol: 6,
    wrapperCol: 14,
    col: 12,
    display: true,
  },
  {
    label: "姓名",
    placeholder: "请输入",
    decorator: [
      "name",
      { initialValue: '', rules: [{ required: true, message: "请输入" }] },
    ],
    type: "input",
    key: "name",
    labelCol: 6,
    wrapperCol: 14,
    col: 12,
    display: true,
    disabled: false
  },
  {
    label: "事件类型",
    placeholder: "请选择",
    decorator: ["eventType", { rules: [{ required: true, message: "请选择" }] }],
    type: "select",
    key: "eventType",
    selectlist: eventType,
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "发生时间",
    placeholder: "请选择",
    decorator: [
      "eventTime",
      { rules: [{ required: true, message: "请选择" }] },
    ],
    type: "datePicker",
    key: "eventTime",
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
    format: "YYYY-MM-DD HH:mm:ss",
  },
  {
    label: "记录时效",
    placeholder: "请选择",
    decorator: ["termTime", { rules: [{ required: true, message: "请选择" }] }],
    type: "rangePicker",
    key: "termTime",
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
    format: "YYYY-MM-DD",
  },
  {
    label: "事件描述",
    placeholder: "请填写",
    decorator: ["eventDescribe", { rules: [{ required: false, message: "请填写" }, { max: 250, message: "请不要超过250个字符"}] }],
    type: "textarea",
    key: "eventDescribe",
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "处理措施",
    placeholder: "请填写",
    decorator: ["measures", { rules: [{ required: false, message: "请填写" }, { max: 250, message: "请不要超过250个字符"}] }],
    type: "textarea",
    key: "measures",
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "上传附件",
    placeholder: "请选择",
    decorator: ["annex", { rules: [{ required: false, message: "请选择" }] }],
    type: "tsUpload",
    listType: 'picture-card',
    key: "annex",
    defaultFileList: [],
    labelCol: 6,
    wrapperCol: 14,
    col: 12,
    display: true,
    class: "Upload",
    showUploadList: true
  },
];
export default {
  components: {
    formIndex,
  },
  inject: ["fatherMethod"],
  data() {
    return {
      formData: formData,
      staffId: '',
      workerName: '',
      officeId: '',
      teamId: '',
      isEdit: false,
      id: ''
    };
  },

  created() {
    // console.log(this.$route.query)
    this.getOfficeListByProjectId()
  },
  watch: {
    '$route.query': {
      handler(newVal) {
        console.log(newVal)
        if (newVal.name) {
          //从人员列表过来，会进来这里，锁定姓名
          this.staffId = newVal.staffId
          this.workerName = newVal.name
          this.officeId = newVal.unitId
          this.teamId = newVal.teamId
          let obj = {
            officeId: this.officeId,
            teamId: this.teamId,
            name: this.workerName
          }
          this.doQueryTeams({ officeId: this.officeId })
          this.$nextTick(() => {
            this.$refs.formindex.queryObj(obj)
            this.formData.forEach(el => {
              if (el.key === 'name' || el.key === 'teamId' || el.key === 'officeId') {
                el['disabled'] = true
              }
            })
          })
        } else {
          this.formData.forEach(el => {
            if (el.key === 'name') {
              el.decorator[1]['initialValue'] = ''
              el['disabled'] = false
            }
          })
        }
        if (newVal.isEdit) {
          //点击修改会进来这里
          this.$nextTick(() => {
            this.isEdit = newVal.isEdit
            this.staffId = newVal.staffId
            this.id = newVal.id
            this.getDetail()
            
          })
        } else {
          //重置所有表单
          this.$nextTick(() => {
            this.$refs.formindex.resetFields()
            this.formData.forEach((item) => {
            if (item.key == 'annex') {
                item.defaultFileList = []
              }
            })
          })
        }
      },
      immediate: true
    }
  },

  methods: {
    getDetail() {
      eventRecordDetail(this.id).then(res => {
        if(res.data.code === 0) {
          let obj = res.data.data
          obj.eventTime = moment(obj.eventTime).format('YYYY-MM-DD HH:mm:ss')
          obj['termTime'] = [moment(obj.startTermTime).format('YYYY-MM-DD'), moment(obj.endTermTime).format('YYYY-MM-DD')]
          this.$refs.formindex.queryObj(obj)
          this.formData.forEach((item) => {
            if (item.key == 'annex' && obj['annex']) {
              item.defaultFileList = [{url: obj['annex'], uid: 0, name:'image.png', key: item.key}]
            }
          })
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    handlSelect(obj) {
      const { val, keys } = obj
      // console.log(val, keys)
      if (keys === 'officeId') {
        this.doQueryTeams({ officeId: val })
      }
    },
    //查询所有单位
    getOfficeListByProjectId(){
      getOfficeListByProjectId().then((res) => {
        if(res.data.code === 0){
          this.formDataFn('officeId', res.data.data, 'name', this.formData)
        }
      })
    },
    //查询所有班组
    doQueryTeams(params){
      getAllTeams(params).then(res => {
        if(res.data.code === 0){
          this.formDataFn('teamId', res.data.data, 'teamName', this.formData)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 对下拉框做数据处理
    formDataFn(key, data, itemName, form) {
      let arr = []
      data.forEach(item => {
          arr.push({ 'name': item[itemName], 'value': item.id })
      })
      form.forEach(item => {
          if (item.key == key) {
              item.selectlist = arr
          }
      })
    },
    //上传文件回调
    resourceUpload(obj) {
      this.formData.forEach(item => {
        if (item.key === obj.key) {
          item.defaultFileList = [obj]
        }
      })
    },
    //删除上传附件
    upRemove(file) {
      this.formData.forEach(item => {
        if (item.key === file.key) {
          item.defaultFileList = []
        }
      })
    },
    handleSubmit(e) {
      // e.eventTime = moment(e.eventTime).format('YYYY-MM-DD HH:mm:ss')
      // e.startTermTime = moment(e.termTime[0]).format('YYYY-MM-DD HH:mm:ss')
      // e.endTermTime = moment(e.termTime[1]).format('YYYY-MM-DD HH:mm:ss')
      e.startTermTime = moment(e.termTime[0]).valueOf()
      e.endTermTime = moment(e.termTime[1]).valueOf()
      e.eventTime = moment().valueOf(e.eventTime)
      delete e.termTime
      e.staffId = this.staffId
      if (!this.isEdit) {
        addNewEventRecord(e).then(res => {
          if (res.data.code === 0) {
            this.$message.success('新增成功！');
            this.close()
            this.$router.push({
              name: "诚信行为登记",
              query: {
                staffId: this.staffId || '',
                name: this.workerName || '',
                unitId: this.officeId || '',
                teamId: this.teamId || '',
              }
            })
          } else {
            this.$message.error(res.data.msg);
          }
        })
      } else {
        e.id = Number(this.id)
        updataEventRecord(e).then(res => {
          if (res.data.code === 0) {
            this.$message.success('修改成功！');
            this.close()
            this.$router.push({
              name: "诚信行为登记",
            })
          } else {
            this.$message.error(res.data.msg);
          }
        })
      }
    },
    // 关闭当前页面
    close() {
      let path = this.$route.path
      this.fatherMethod(`${path}`)
      
    },
  },
};
</script>

<style lang="less" scoped>

</style>