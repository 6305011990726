<template>
  <div>
    <a-drawer
      title="印章信息"
      :width="820"
      :visible="visible"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose"
    > 
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }" layout="horizontal">
        <a-row :gutter="16">
        <a-col :span="24">
          <a-form-item label="印章标题：">
            <a-input
              v-decorator="['title', { rules: [{ required: true, message: '请输入！' }] }]"
              placeholder="请输入印章标题"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="公司/项目：">
               <a-select
                :allowClear="true"
                style="width: 100%"
                placeholder="请类型"
                v-decorator="['bussType']">
                <a-select-option v-for="t in fileTypes" :key="t.key" :value="t.key">{{t.text}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="公司：">
              <a-select
                mode="multiple"
                :allowClear="true"
                :filterOption="filterOption"
                style="width: 100%"
                @change="handleChange"
                @search="officeSearch"
                v-decorator="['officeIds',{rules: [{ required: true, message: '请选择公司' }]}]">
                <a-select-option v-for="t in officeDataSource" :key="t.id" :value="t.id">{{t.name}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="项目：">
              <a-select
                :allowClear="true"
                style="width: 100%"
                placeholder="请选择项目"
                v-decorator="['projectId']">
                <a-select-option v-for="t in projectDataSource" :key="t.projId" :value="t.projId">{{t.fullName}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="图章类型：">
               <a-select
                :allowClear="true"
                style="width: 100%"
                placeholder="请选择图章类型"
                v-decorator="['type']">
                <a-select-option v-for="t in types" :key="t.key" :value="t.key">{{t.text}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        
        <a-row :gutter="12">
          <a-col :span="24">
            <a-form-item label="印章文件：">
              <a-upload
                  name="file"
                  :multiple="false"
                  :beforeUpload="beforeUpload"
                  accept=".png,.PNG,.jpg,.JPG"
                  :customRequest="resourceUpload"
                  :file-list="fileList"
              >
                  <a-button style="margin-left:10px" type="primary" >上传印章</a-button>
                  <span style="margin-left:10px" ><strong>温馨提示：</strong><span style="color:blue">上传的章印必须是白色透明底，并且无白色边框的图章，否则展现出的效果不佳！</span></span>
                  <div><img style="max-width:200px;max-height:100px" :src="filePath" /></div>
              </a-upload>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="12">
          <a-col :span="24">
            <a-form-item label="备注说明：">
              <a-textarea 
                v-decorator="['remark']"
                :rows="4"
                placeholder="备注说明"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button type="primary" @click="handleSubmit()">
          保存
        </a-button>
        <a-button :style="{ marginLeft: '8px' }" @click="onClose">
          取消
        </a-button>
      </div>
    </a-drawer>
  </div>
</template>
<script>
import { ATTACHMENT_UPLOAD,FIND_ALL_OFFICE_LIST,FIND_PROJECT_BY_OFFICEID,FIND_PROJECT_BY_ID } from "@/services/api";
import { request } from "@/utils/request";


export default {
  name: "setting",
  components: {},
  data() {
    return {
      form: this.$form.createForm(this),
      obj:{},
      officeName:'',
      officeId:'',
      projectDataSource:[],
      fileList:[],
      fileTypes:[],
      types:[],
      officeDataSource:[],
      filePath:null,
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    seal: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  created(){
    this.fileTypes = [];
    var officeSeal = {
      key:1,
      text:'公司印章'
    }
    this.fileTypes.push(officeSeal)
    var projectSeal = {
      key:2,
      text:'项目印章'
    }
    this.fileTypes.push(projectSeal)
    this.types = [];
    var pt = {
      key:1,
      text:'普通印章'
    }
    this.types.push(pt)
    var other = {
      key:2,
      text:'其他'
    }
    this.types.push(other)
    this.officeSearch(null);
  },
  mounted(){
  },
  watch:{
    seal(val){
      console.log(111111,val);
      if(val.id){
        this.$nextTick(()=>{
          this.obj = val;
          if(this.obj.path){
            var fileObj = {
              uid: '1',
              name: this.obj.title+".png",
              url: this.obj.path,
            }
            this.filePath= this.obj.path;
            this.fileList = [];
            this.fileList.push(fileObj);
          }
          let officeIds = [];
          this.officeDataSource = [];
          if(this.obj.bussType==1){  //公司
            this.findProjectByOffice(this.obj.bussId);
            officeIds.push(this.obj.bussId);
            let objx ={
              id:this.obj.bussId
            }
            request(FIND_ALL_OFFICE_LIST,"post",objx).then(result => {
                if (result.data.code==0) {
                  result.data.data.forEach((o) => {
                    o.key = o.id;
                    this.officeDataSource.push(o)
                  });
                }else{
                  this.$message.warning(result.message);
                }
            })
            this.form.setFieldsValue({
              'title': this.obj.title,
              'bussType': this.obj.bussType,
              'remark': this.obj.remark,
              'officeIds':officeIds,
              'projectId':null,
            })
          }else if(this.obj.bussType==2){  //项目
            request(FIND_PROJECT_BY_ID+this.obj.bussId,"post",{}).then(result => {
                if (result.data.code==0) {
                  this.projectDataSource = [];
                  result.data.data.key = result.data.data.id;
                  this.projectDataSource.push(result.data.data);
                }else{
                  this.$message.warning(result.message);
                }
            })
            this.form.setFieldsValue({
              'title': this.obj.title,
              'bussType': this.obj.bussType,
              'remark': this.obj.remark,
              'projectId':this.obj.bussId,
              'officeIds':[]
            })
          }
        })
      }else{
        this.form.resetFields();
        this.filePath=null;
        this.fileList = [];
      }
      
    }
  },
  methods: {
    /**
     * 取消审批
     */
    onClose() {
      // this.$refs.tinymceEditor.destory();
      this.$emit('close');
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.indexOf(input) >= 0
      );
    },
    handleRemove(file){
      let index = 0;
      index = this.fileList.findIndex(function(data) {
        return data.url == file.url;
      });
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
      this.obj.path = null;
      this.filePath = null;
    },

    handleChange(value){
      this.projectDataSource=[];
      if(value){
        this.findProjectByOffice(value);
      }
    },

    findProjectByOffice(officeId){
      request(FIND_PROJECT_BY_OFFICEID+officeId,"get",null).then(result => {
          if (result.data.code==0) {
            this.projectDataSource = result.data.data;
            this.projectDataSource.forEach((obj) => {
              obj.key = obj.id;
            });
          }else{
            this.$message.warning(result.message);
          }
      })
    },
    /**
     * 保存信息
     */
    handleSubmit(e){
      //数据校验
      this.form.validateFields((err, values) => {
        if (!err) {
          //保存结果数据
          if(values.officeIds!=null&&values.officeIds.length>0){
            values.officeId = values.officeIds[0]
          }
          if(this.fileList!=null&&this.fileList.length>0){
            values.path = this.fileList[0].url
          }else{
            this.$message.error("请上传图章文件！");
            return
          }
          Object.assign(this.obj,values);
          console.log(this.obj)
          this.$emit('submit',{seal:this.obj})
        }
      });
    },
    /** 
    * 上传之前
    */
    beforeUpload (file) {
      return new Promise((resolve, reject) => {
        const isFiveM= file.size / 1024 / 1024 < 10 //小于5M的图片上传
        //判断文件是否符合正则表达式的规则
        if (!isFiveM) {
          this.$message.error('上传资源不大于10M！');
          return reject(false)
        }
        if(this.obj.id){
          this.$confirm({
            title: '',
            content: h => <div style="color:red;">重新上传会将原有的印章覆盖，是否继续上传?</div>,
            onOk() {
              return resolve(true);
            },
            onCancel() {
              return reject(false);
            },
            class: 'test',
          });
        }else{
          return resolve(true);
        }
        
      });
    },
    /**
    * 资源上传
    */
    resourceUpload(value){
      const formData = new FormData();
      formData.append('file', value.file);
      request(ATTACHMENT_UPLOAD,"post",formData).then(result => {
          if (result.data.code==0) {
            this.obj.path = result.data.data.path;
            var fileObj = {
              uid: '1',
              name: result.data.data.name,
              url: this.obj.path,
            }
            this.filePath = this.obj.path;
            this.fileList = [];
            this.fileList.push(fileObj);
            value.onSuccess(result, value.file);
          }else{
            this.$message.warning(result.message);
          }
      })
    },
    officeSearch(value){
      let obj ={
        name:value,
        id:this.obj.officeId
      }
      request(FIND_ALL_OFFICE_LIST,"post",obj).then(result => {
          if (result.data.code==0) {
            this.officeDataSource = result.data.data;
            this.officeDataSource.forEach((obj) => {
              obj.key = obj.id;
            });
          }else{
            this.$message.warning(result.message);
          }
      })
    },
    contentInput(e){
      this.obj.fileContent = e;
    }
  }
}
</script>
<style>
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
/deep/ .ant-upload-list-picture-card .ant-upload-list-item{
  width: 127px;
  height: 127px;
}
</style>