<template>
  <div>
    <a-card title="年度安全考核（分值权重：20）" class="a_Approval" :bordered="false">
      <a-form-model ref="ruleForm" :model="params" :rules="rules">
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="工程管理部" :label-col="{ span: 10 }" :wrapper-col="{ span: 12 }" prop="gcglbId">
              <a-select v-if="isEdit == 1" style="width: 50%" placeholder="请选择" @change="handlSelect" allowClear :filter-option="filterOption" v-model="params.gcglbId" :disabled="true">
                  <a-select-option v-for="items of selectlist" :key="items.name" :value="items.value" :title="items.name">
                      {{ items.name }}
                  </a-select-option>
              </a-select>
              <a-input v-else :disabled="true" style="width: 50%" v-model="params.gcglbName"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="年度" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" prop="year">
              <a-date-picker v-model="params.year" style="width: 50%" allowClear :disabled="true"
                mode="year" @panelChange="changeYear" :format="'YYYY'"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="安全生产文明施工创优（3分）" :label-col="{ span: 10 }" :wrapper-col="{ span: 12 }" prop="excellent">
              <a-input :disabled="true" style="width: 50%" v-model="params.excellent"/>
              <span class="ml-2 pointer" style="color: #2BA4FF;" @click="handleCheck(1)">查看</span>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="安全教育培训（1分）" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" prop="education">
              <a-input placeholder="请输入" style="width: 50%" v-model="params.education" :disabled="isEdit == 0"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="安全隐患排查治理-停工处罚（4分）" :label-col="{ span: 10 }" :wrapper-col="{ span: 12 }" prop="stopWork">
              <a-input placeholder="请输入" style="width: 50%" v-model="params.stopWork" :disabled="isEdit == 0"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="应急管理（2分）" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" prop="emergency">
              <a-input placeholder="请输入" style="width: 50%" v-model="params.emergency" :disabled="isEdit == 0"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="安全隐患排查治理-整改回复（2分）" :label-col="{ span: 10 }" :wrapper-col="{ span: 12 }" prop="repairAnswer">
              <a-input placeholder="请输入" style="width: 50%" v-model="params.repairAnswer" :disabled="isEdit == 0"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="专项安全检评-集团标准化管理" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" prop="standard">
              <a-input :disabled="true" style="width: 50%" v-model="params.standard"/>
              <span class="ml-2 pointer" style="color: #2BA4FF;" @click="handleCheck(2)">查看</span>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="投诉约谈（5分）" :label-col="{ span: 10 }" :wrapper-col="{ span: 12 }" prop="complainAndTalk">
              <a-input :disabled="true" style="width: 50%" v-model="params.complainAndTalk"/>
              <span class="ml-2 pointer" style="color: #2BA4FF;" @click="handleCheck(3)">查看</span>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="专项安全检评-月度综合评检" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" prop="monthlyCheck">
              <a-input placeholder="请输入" style="width: 50%" v-model="params.monthlyCheck" :disabled="isEdit == 0"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="安全管理信息化系统应用（2分）" :label-col="{ span: 10 }" :wrapper-col="{ span: 12 }" prop="systemApplication">
              <a-input placeholder="请输入" style="width: 50%" v-model="params.systemApplication" :disabled="isEdit == 0"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="安全事故" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" prop="accident">
              <a-input placeholder="请输入" style="width: 50%" v-model="params.accident" :disabled="isEdit == 0"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="安全投入（1分）" :label-col="{ span: 10 }" :wrapper-col="{ span: 12 }" prop="focus">
              <a-input placeholder="请输入" style="width: 50%" v-model="params.focus" :disabled="isEdit == 0"/>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <a-row>
        <a-col :span="12"></a-col>
        <a-col :span="12">
          <a-form-model-item :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
            <div class="flex flex-row-reverse">
              <div>
                <div>合计得分：{{totalCount}}</div>
                <a-button  class="mr-2" @click="close" v-if="isEdit == 1">返回</a-button>
                <a-button type="primary" @click="handleSubmit" v-if="isEdit == 1">保存</a-button>
              </div>
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-card>
    <a-modal
      :title="modalTitle"
      :visible="visible"
      :footer="null"
      @cancel="handleCancel"
      :width="1400"
    >
      <excellent-list v-if="modalType == 1" :gcglbId="params.gcglbId" :year="params.year"></excellent-list>
      <review-list-modal v-else-if="modalType == 2" :gcglbId="params.gcglbId" :year="params.year"></review-list-modal>
      <complaint-interview-list-modal v-else-if="modalType == 3" :gcglbId="params.gcglbId" :year="params.year"></complaint-interview-list-modal>
    </a-modal>
  </div>
</template>

<script>
import { QUERY_OFFICE_BY_USER } from "@/services/api";
import { request } from "@/utils/request";
import { mapGetters } from 'vuex'
import { getProjectByOffice } from "@/services/statistics";
import {
  safeExamineYearDetail, safeExamineYearkUpdate
} from "@/services/safeExamine";
import moment from "moment";
import excellentList from './components/excellentListModal.vue';
import ReviewListModal from './components/reviewListModal.vue';
import ComplaintInterviewListModal from './components/complaintInterviewListModal.vue';

export default {
  components: { excellentList, ReviewListModal, ComplaintInterviewListModal },
  inject: ["fatherMethod"],
  computed: {
    ...mapGetters('account', ['user']),
    totalCount() {
      return Number(this.params.education) + Number(this.params.stopWork) + Number(this.params.emergency) + Number(this.params.repairAnswer) + Number(this.params.standard) 
      + Number(this.params.complainAndTalk) + Number(this.params.monthlyCheck) + Number(this.params.focus) + Number(this.params.systemApplication) + Number(this.params.accident)
       + Number(this.params.excellent)
    }
  },
  data() {
    return {
      visible: false,
      modalType: null,
      modalTitle: '',
      isEdit: 0,
      rules: {
        gcglbId: [{ required: true, message: '请选择工程管理部', trigger: 'change' }],
        year: [{ required: true, message: '请选择年度', trigger: 'change' }],
        excellent: [{ required: false, message: '请输入安全生产文明施工创优', trigger: 'change' }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }],
        education: [{ required: false, message: '请输入安全教育培训', trigger: 'change' }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }],
        stopWork: [{ required: false, message: '请输入安全隐患排查治理-停工处罚', trigger: 'change' }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }],
        emergency: [{ required: false, message: '请输入应急管理', trigger: 'change' }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }],
        repairAnswer: [{ required: false, message: '请输入安全隐患排查治理-整改回复', trigger: 'change' }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }],
        standard: [{ required: false, message: '请输入专项安全检评-集团标准化管理', trigger: 'change' }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }],
        complainAndTalk: [{ required: false, message: '请输入投诉约谈', trigger: 'change' }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }],
        monthlyCheck: [{ required: false, message: '请输入专项安全检评-月度综合评检', trigger: 'change' }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }],
        systemApplication: [{ required: false, message: '请输入安全管理信息化系统应用', trigger: 'change' }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }],
        accident: [{ required: false, message: '请输入安全事故', trigger: 'change' }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }],
        focus: [{ required: false, message: '请输入安全投入', trigger: 'change' }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }],
      },
      selectItemList: [],
      selectlist: [],
      dataSource: [],
      params: {
        education: '',
        stopWork: '',
        emergency: '',
        repairAnswer: '',
        standard: '',
        complainAndTalk: '',
        monthlyCheck: '',
        focus: '',
        systemApplication: '',
        accident: '',
        gcglbId: '',
        excellent: '',
        year: moment(new Date()).format('YYYY'),
        creator: '',
        updator: ''
      }
    };
  },
  activated() {
    this.getgcglbList(QUERY_OFFICE_BY_USER);
    this.params.updator = this.user.id
    this.params.id = this.$route.query.id;
    this.isEdit = this.$route.query.isEdit;
    this.getDetail()
  },
  methods: {
    moment,
    getDetail() {
      safeExamineYearDetail(this.params.id).then(res => {
        if (res.data.code === 0) {
          for (const key in res.data.data) {
            if (Object.hasOwnProperty.call(res.data.data, key)) {
              const el = res.data.data[key];
              this.$set(this.params, key, el)
            }
          }
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 获取工程管理部数据
    getgcglbList(path) {
      request(path, "get").then((res) => {
        let data = res.data.data.filter(
          (item) => item.id != "3da0f5533f87425c9a9e8a11a7923300"
        );
        this.formDataFn("gcglbId", data);
      });
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      let arr = [];
      if (key == 'gcglbId') {
        data.forEach((item) => {
          arr.push({ name: item.master, value: item.id });
        });
      }
      this.selectlist = arr;
    },
    // 输入下拉框防止第一次数据没变渲染出来
    filterOption(input, option){
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    handlSelect(e) {
        this.params.gcglbId = e
        this.getProjectStatissKqS('')
    },
    handlProjectNameSelect(e) {
      this.params.projectId = this.dataSource.find(el => e == el.title).id
    },
    // 输入选择框触发
    handleChange(e) {
      // console.log(e)
      this.getProjectStatissKqS(e)
    },
    getProjectStatissKqS(projectName) {
      getProjectByOffice({gcglbId: this.params.gcglbId, projectName}).then((res) => {
        if (res.data.code === 0) {
              let arr = []
              res.data.data.forEach(el => {
                arr.push({
                  title: el.projectName,
                  id: el.projectId,
                  gcglbId: el.gcglbId
                })
              })
              this.dataSource = arr
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    handleSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          safeExamineYearkUpdate(this.params).then(res => {
            if (res.data.code === 0) {
              this.$message.success('保存成功！')
              this.close();
              this.$router.push({
                name: "安全考核一览表",
              });
            } else {
              this.$message.error(res.data.msg)
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleCheck(type) {
      this.visible = true;
      this.modalType = type
      switch (type) {
        case 1: 
          this.modalTitle = '安全创优评分'
        break
        case 2: 
          this.modalTitle = '检评列表'
        break
        case 3: 
          this.modalTitle = '被投诉约谈列表'
        break
      }
    },
    handleCancel(e) {
      console.log('Clicked cancel button');
      this.visible = false;
    },
    // 年度筛选
    changeYear(e) {
      this.params.year = e.format('YYYY')
    },
    close() {
      let path = this.$route.path;
      this.fatherMethod(`${path}`);
      this.$router.push({
        name: "安全考核一览表",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.a_Approval {
  background: #fff;
  border-radius: 10px;
}
.btn_group {
  padding-right: 190px;
}
</style>
