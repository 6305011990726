var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { staticClass: "a_Approval", attrs: { bordered: false } },
    [
      _c(
        "a-tabs",
        { attrs: { "default-active-key": _vm.defaultActiveKey } },
        [
          _c(
            "a-tab-pane",
            { key: "2", attrs: { tab: "建委预警" } },
            [_c("platform")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }