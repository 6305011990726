var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "plug-form" },
    [
      _c(
        "a-form",
        {
          staticClass: "ant-advanced-search-form",
          attrs: { form: _vm.form },
          on: { submit: _vm.handleSubmit },
        },
        [
          _c("div", { staticClass: "plug-Overview" }, [_vm._v("参建单位详情")]),
          _c("a-divider"),
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: "单位名称",
                        labelCol: { span: 5 },
                        wrapperCol: { span: 17, offset: 1 },
                      },
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "officeName",
                              {
                                rules: [
                                  { required: true, message: "请输入单位名称" },
                                ],
                              },
                            ],
                            expression:
                              "[\n              'officeName',\n              { rules: [{ required: true, message: '请输入单位名称' }] },\n            ]",
                          },
                        ],
                        attrs: {
                          placeholder: "请输入单位名称",
                          allowClear: "",
                          disabled: "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: "统一社会信用代码",
                        labelCol: { span: 5 },
                        wrapperCol: { span: 17, offset: 1 },
                      },
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "code",
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入统一社会信用代码",
                                  },
                                  {
                                    required: true,
                                    message: "请正确输入社会信用代码",
                                    pattern: /^[0-9A-Za-z]{18}$/,
                                  },
                                ],
                              },
                            ],
                            expression:
                              "[\n              'code',\n              {\n                rules: [\n                  { required: true, message: '请输入统一社会信用代码' },\n                  { required: true, message: '请正确输入社会信用代码' ,pattern:/^[0-9A-Za-z]{18}$/}\n                ],\n              },\n              \n            ]",
                          },
                        ],
                        attrs: {
                          placeholder: "请正确输入18位社会信用代码",
                          allowClear: "",
                          maxLength: 19,
                          disabled: _vm.query.readonly,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: "单位类型",
                        labelCol: { span: 5 },
                        wrapperCol: { span: 17, offset: 1 },
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "roleType",
                                {
                                  rules: [
                                    {
                                      required: true,
                                      message: "请选择单位类型",
                                    },
                                  ],
                                },
                              ],
                              expression:
                                "[\n              'roleType',\n              {\n                rules: [{ required: true, message: '请选择单位类型' }],\n              },\n            ]",
                            },
                          ],
                          attrs: {
                            placeholder: "请选择单位类型",
                            allowClear: "",
                            disabled: _vm.query.readonly,
                          },
                        },
                        _vm._l(_vm.selectlist, function (items) {
                          return _c(
                            "a-select-option",
                            {
                              key: items.name,
                              attrs: {
                                "filter-option": _vm.filterOption,
                                value: Number(items.value),
                              },
                            },
                            [_vm._v(" " + _vm._s(items.name) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: "法定代表人",
                        labelCol: { span: 5 },
                        wrapperCol: { span: 17, offset: 1 },
                      },
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "master",
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入法人代表人姓名",
                                  },
                                  {
                                    required: true,
                                    message: "最长不超过12位汉字",
                                    pattern: /(^[\u4e00-\u9fa5]{1,12}$)/,
                                  },
                                ],
                              },
                            ],
                            expression:
                              "[\n              'master',\n              {\n                rules: [{ required: true, message: '请输入法人代表人姓名' },\n                { required: true, message: '最长不超过12位汉字' ,pattern:/(^[\\u4e00-\\u9fa5]{1,12}$)/}\n              ],\n              },\n            ]",
                          },
                        ],
                        attrs: {
                          placeholder: "请输入法人代表人姓名",
                          allowClear: "",
                          maxLength: 13,
                          disabled: _vm.query.readonly,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: "现场联系人",
                        labelCol: { span: 5 },
                        wrapperCol: { span: 17, offset: 1 },
                      },
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "xclxr",
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入现场联系人",
                                  },
                                  {
                                    required: true,
                                    message: "最长不超过12位汉字",
                                    pattern: /(^[\u4e00-\u9fa5]{1,12}$)/,
                                  },
                                ],
                              },
                            ],
                            expression:
                              "[\n              'xclxr',\n              { rules: [{ required: true, message: '请输入现场联系人' },\n              { required: true, message: '最长不超过12位汉字' ,pattern:/(^[\\u4e00-\\u9fa5]{1,12}$)/}\n            ] },\n            ]",
                          },
                        ],
                        attrs: {
                          placeholder: "请输入现场联系人",
                          allowClear: "",
                          maxLength: 13,
                          disabled: _vm.query.readonly,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: "现场联系人电话",
                        labelCol: { span: 5 },
                        wrapperCol: { span: 17, offset: 1 },
                      },
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "xclxrPhone",
                              {
                                rules: [
                                  {
                                    required: false,
                                    message: "手机号格式错误",
                                    pattern: new RegExp(/^1\d{10}$/),
                                  },
                                ],
                              },
                            ],
                            expression:
                              "[\n              'xclxrPhone',\n              {\n                rules: [{ required: false, message: '手机号格式错误',\n          pattern: new RegExp(/^1\\d{10}$/),}],\n              },\n            ]",
                          },
                        ],
                        attrs: {
                          placeholder: "请输入现场联系人电话",
                          allowClear: "",
                          maxLength: 11,
                          disabled: _vm.query.readonly,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: "分包合同编号",
                        labelCol: { span: 5 },
                        wrapperCol: { span: 17, offset: 1 },
                      },
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "fbhtbh",
                              {
                                rules: [
                                  {
                                    required: false,
                                    message: "请输入分包合同编号",
                                  },
                                ],
                              },
                            ],
                            expression:
                              "[\n              'fbhtbh',\n              { rules: [{ required: false, message: '请输入分包合同编号' }\n            ] },\n            ]",
                          },
                        ],
                        attrs: {
                          placeholder: "请输入分包合同编号",
                          allowClear: "",
                          maxLength: 31,
                          disabled: _vm.query.readonly,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: "合同造价(万元)",
                        labelCol: { span: 5 },
                        wrapperCol: { span: 17, offset: 1 },
                      },
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "htzj",
                              {
                                rules: [
                                  {
                                    required: false,
                                    message: "请输入数字",
                                    pattern: new RegExp(
                                      /^-?[0-9]*(\.[0-9]*)?$/
                                    ),
                                  },
                                  {
                                    required: false,
                                    message: "数字最长为8位数",
                                    pattern: /^\d{1,8}$/,
                                  },
                                ],
                              },
                            ],
                            expression:
                              "[\n              'htzj',\n              { rules: [\n                { required: false, message: '请输入数字',pattern: new RegExp(/^-?[0-9]*(\\.[0-9]*)?$/),}\n                ,\n                {\n                  required: false,\n                  message: '数字最长为8位数',\n                  pattern: /^\\d{1,8}$/,\n                },\n              ] },\n            ]",
                          },
                        ],
                        attrs: {
                          placeholder: "请输入合同造价",
                          allowClear: "",
                          maxLength: 9,
                          disabled: _vm.query.readonly,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottomSubmit" },
            [
              !_vm.query.readonly
                ? _c(
                    "a-button",
                    { attrs: { type: "primary", "html-type": "submit" } },
                    [_vm._v(" 确定 ")]
                  )
                : _vm._e(),
              _c("a-button", { on: { click: _vm.close } }, [_vm._v(" 关闭 ")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }