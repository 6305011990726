<template>
  <div>
    <a-form-model layout="inline" :model="params" class="mb-5 query-form" @submit="handleSubmit">
      <a-form-model-item label="分公司">
        <a-select
          v-model="params.gcglbId"
          allowClear
          placeholder="请选择"
          style="width: 220px"
        >
          <a-select-option
            v-for="item in gcglbList"
            :key="item.value"
            :value="item.value"
            :title="item.name"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="年度">
        <a-date-picker style="width: 220px" v-model="params.year" allowClear mode="year" placeholder="请选择年度" @panelChange="changeYear" :format="'YYYY'"/>
      </a-form-model-item>
      <a-form-model-item label="项目名称">
        <a-input style="width: 220px" placeholder="请输入项目名称" v-model="params.projectName" allowClear/>
      </a-form-model-item>
      <a-form-model-item label="带班领导">
        <a-input style="width: 220px" placeholder="请输入带班领导" v-model="params.leader" allowClear/>
      </a-form-model-item>
      <a-form-model-item>
        <a-space>
          <a-button type="primary" html-type="submit"> 查询 </a-button>
          <a-button @click="resetQuery"> 重置 </a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
    <div class="a_a_footer">
      <div>
        <a-space class="operator">
          <a-button type="primary" @click="addNewPlan">新增</a-button>
        </a-space>
        <a-space class="operator">
          <a-button class="mr-9"  @click="doDowload" v-if="user.isGcglb == 0 && user.loginType == 1"> 导出 </a-button>
        </a-space>
      </div>
      <standard-table
        class=""
        :columns="columns"
        :dataSource="tableData"
        rowKey="id"
        :pagination="pagination"
        :scroll="{ x: 1300 }"
        :loading="tableLoading"
        @change="handleChangePage"
        :isAlert="false"
        :bordered="true"
      >
        <template slot="index" slot-scope="{ index }">
          <span>{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}</span>
        </template>
        <template slot="problems" slot-scope="{ index, record }">
          <div v-for="(problem, i) in record.problems" :key="i">{{ problem }}</div>
        </template>
        <div slot="action" slot-scope="{ text, record }">
          <a
            @click="() => handleEdit(record)"
          >
            编辑
          </a>
          <a
            class="ml-2"
            @click="() => handleDel(record)"
            style="color: red;"
          >
            删除
          </a>
        </div>
      </standard-table>
    </div>
    <Export-loading
      :cPercent="cPercent"
      :isExporting="isExporting"
      :loadingText="loadingText"
    ></Export-loading>
  </div>
</template>

<script>
import {
  leaderCheckPlanList,
  leaderCheckPlanDel,
  leaderCheckPlanExport,
} from "@/services/safeMonthlyReport";
import { request } from "@/utils/request";
import { QUERY_OFFICE_BY_USER } from "@/services/api";
import ExportLoading from "@/components/ExportLoading/ExportLoading"; // progress组件
import { exportProgress } from "@/pages/aqjyzl/export"; // 导出方法
import { mapGetters } from "vuex";
import StandardTable from "@/components/table/StandardTable";
import moment from "moment";

const columns = [
  {
    title: "序号",
    align: "center",
    scopedSlots: { customRender: "index" },
    width: 65,
  },
  {
    title: "公司",
    align: "center",
    dataIndex: "parentOffice",
    width: 120,
  },
  {
    title: "分公司",
    align: "center",
    dataIndex: "gcglbName",
    width: 150,
  },
  {
    title: "项目名称",
    dataIndex: "projectName",
    ellipsis: true,
    tooltip: true
  },
  {
    title: "季度",
    dataIndex: "yearAndSeason",
    align: "center",
    width: 240,
  },
  {
    title: "带班领导",
    dataIndex: "leader",
    align: "center",
    width: 150,
  },
  {
    title: "陪检人员",
    dataIndex: "accompany",
    align: "center",
    width: 220,
  },
  {
    title: "操作",
    align: "center",
    fixed: "right",
    width: 140,
    scopedSlots: { customRender: "action" },
  },
];

export default {
  mixins: [exportProgress], //导出方法注入
  components: {
    ExportLoading,
    StandardTable
  },
  data() {
    return {
      params: {
        gcglbId: '',
        projectName: '',
        year: moment(new Date()).format('YYYY'),
        leader: '',
        pageNumber: 1,
        pageSize: 10,
      },
      pagination: {
        //分页数据
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total, range) => `共 ${total} 条`,
      },
      gcglbList: [],
      total: 0,
      columns: columns,
      tableData: [],
      tableLoading: false, //table加载中

      loadingText: "数据加载中...",
      cPercent: 0,
      isExporting: false,
    };
  },
  created() {
    this.getgcglbList(QUERY_OFFICE_BY_USER);
  },
  activated() {
    this.handleQuery();
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  methods: {
    handleEdit(row) {
      this.$router.push({
        name: '新增领导季度带班检查',
        query: {
          isEdit: 1,
          id: row.id
        },
      });
    },
    addNewPlan() {
      this.$router.push({
        name: '新增领导季度带班检查',
        query: {
          isEdit: 0
        },
      });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.params.pageNumber = 1
      this.pagination.current = 1
      this.tableData = []
      this.handleQuery();
    },
    handleQuery(e) {
      this.tableLoading = true
      leaderCheckPlanList(this.params).then((res) => {
        this.tableLoading = false
        let d = res.data;
        if (d.code === 0) {
          this.tableData = d.data.list
          this.pagination.total = d.data.total;
          this.total = d.data.total;
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    handleChangePage(e) {
      this.params.pageNumber = e.current;
      this.pagination.current = e.current;
      this.handleQuery();
    },
    // 获取工程管理部数据
    getgcglbList(path) {
      request(path, "get").then((res) => {
        let data = res.data.data.filter(item => item.id != "3da0f5533f87425c9a9e8a11a7923300")
        const arr = [];
        data.forEach((item) => {
          arr.push({
            name: item.master,
            value: item.id,
          });
        });
        this.gcglbList = arr;
      });
    },
    resetQuery() {
      this.pagination.pageNumber = 1
      this.params = {
        gcglbId: '',
        projectName: '',
        year: moment(new Date()).format('YYYY'),
        leader: '',
        pageNumber: 1,
        pageSize: 10,
      };
      this.handleQuery();
    },
    doDowload() {
      let obj = this.params;
      delete obj.pageNumber
      delete obj.pageSize
      this.loadingText = "正在导出EXCEL文件，请稍候 ····";
      this.isExporting = true;
      this.cPercent = 0;
      let fileName = "领导季度带班检查.xlsx";
      this.exportFunNomal2(
        leaderCheckPlanExport,
        obj,
        fileName,
        "application/vnd.ms-excel"
      );
    },
    changeYear(e) {
      this.params.year = e.format('YYYY')
    },
    handleDel(record) {
      const _that = this
      this.$confirm({
        title: '是否确定删除？',
        cancelText: '否',
        okText: '是',
        onOk: () => {
          leaderCheckPlanDel(record.id).then(res => {
            if (res.data.code === 0) {
              _that.$message.success('删除成功')
              this.handleQuery();
            } else {
              _that.$message.error(res.data.msg)
            }
          })
        },
      })
    },
  },
};
</script>

<style lang="less" scoped>
@import "@/theme/common.less";
.query-form {
  display: flex;
  // justify-content: space-between;
}
.a_a_footer {
  background: #ffffff;

  .operator {
    margin-right: 8px;

  }
}
</style>
