<template>
  <div>
    <a-drawer
      :width="800"
      :visible="visible"
      title="编辑宿舍楼"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose"
    > 
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }" layout="horizontal">
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="名称：">
              <a-input
                v-decorator="['name', { rules: [{ required: true, message: '宿舍楼名称' }] }]"
               placeholder="宿舍楼名称"/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="编号:">
              <a-input
                v-decorator="['code', { rules: [{ message: '请输入编号' }] }]"
               placeholder="请输入编号"/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="地址：">
              <a-input
                v-decorator="['address', { rules: [{  message: '请输入宿舍地址' }] }]"
               placeholder="请输入宿舍地址"/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="12">
          <a-col :span="24">
            <a-form-item label="宿舍楼照片：">
              <a-upload
                  name="file"
                  :multiple="false"
                  :beforeUpload="beforeUpload"
                  accept=".png,.PNG,.jpg,.JPG"
                  :customRequest="resourceUpload"
                  :file-list="fileList"
              >
                  <a-button :loading="iconLoading" style="margin-left:10px" type="primary" >照片</a-button>
                  <div style="margin-top:20px"><img style="max-width:200px;max-height:100px" :src="filePath" /></div>
              </a-upload>
            </a-form-item>
          </a-col>
        </a-row>
        <!-- <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="是否为三级安全教育">
              <a-radio-group v-model="isThreeEdu" @change="changeThreeEdu">
                <a-radio :value="0">
                  否
                </a-radio>
                <a-radio :value="1">
                  是
                </a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row> -->
        <!-- <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="课程标签">
              
            </a-form-item>
          </a-col>
        </a-row> -->
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="备注信息">
               <a-textarea
                   v-decorator="['remark', { rules: [{  message: '备注信息' }] }]"
                  placeholder="备注信息"
                  :auto-size="{ minRows: 5 }"
                />
            </a-form-item>
          </a-col>
        </a-row>
    </a-form>
    <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginLeft: '8px' }" type="primary" @click="doSubmit">
          确定
        </a-button>
        <a-button :style="{ marginLeft: '8px' }" @click="onClose">
          关闭
        </a-button>
      </div>
    </a-drawer>
    
  </div>
</template>
<script>
import { request } from "@/utils/request";
import { ATTACHMENT_UPLOAD} from "@/services/api";
export default {
  name: "teamEditor",
  components: {},
  data() {
    return {
      form: this.$form.createForm(this),
      fileList:[],
      filePath:null,
      iconLoading:false
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    build:{
      type: Object,
      require:true
    }
  },
  created(){
    
  },
  mounted(){
  },
  watch:{
    visible:{
       handler(val, oldName) {
          if(val&&this.build&&this.build.id){
            this.obj = this.build;
            setTimeout(()=>{
              this.form.setFieldsValue({
                'name': this.obj.name,
                'code': this.obj.code,
                'address': this.obj.address
                
              })
              this.filePath = this.obj.imageIds;
              if(this.filePath){
                var fileObj = {
                  uid: '1',
                  name: "宿舍楼封面",
                  url: this.filePath,
                }
                this.fileList.push(fileObj)
              }else{
                this.fileList=[];
              }
            })
          }else{
            this.obj = this.build
            this.form.resetFields();
            this.filePath=null;
            this.fileList = [];
          }
       }
      
    }
  },
  methods: {
    onChange(){

    },
    doDetail(){
      
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },
    onClose(){
      this.$emit('buildPageClose');
    },
    // changeThreeEdu(e){
    //   let chooseValue = e.target.value;
    //   if(chooseValue&&chooseValue==1){
    //     request(ONLINE_QUERY_COURSE_CHECKTHREEEDU+chooseValue,"get",{}).then(res => {
    //         if(res.data.code==0){
    //           if(res.data.data){
    //             this.$message.warning("您公司已经设置了课程【"+res.data.msg+"】为三级安全教育课程,请您先将其取消再设置.")
    //             this.isThreeEdu=0;
    //             return false;
    //           }
    //         }else{
    //           this.$message.error("系统异常...");
    //         }
    //     })
    //   }
      
    // },
    doSubmit(){
       //数据校验
      this.form.validateFields((err, values) => {
        if (!err) {
          if(this.obj&&this.obj.id){
            values.id = this.obj.id
          }
          values.imageIds=this.filePath
          this.$emit('buildSubmit',{build:values})
        }
      })
    },
    /** 
    * 上传之前
    */
    beforeUpload (file) {
      return new Promise((resolve, reject) => {
        const isFiveM= file.size / 1024 / 1024 < 10 //小于5M的图片上传
        //判断文件是否符合正则表达式的规则
        if (!isFiveM) {
          this.$message.error('上传资源不大于10M！');
          return reject(false)
        }else{
          return resolve(true);
        }
      });
    },
    /**
    * 资源上传
    */
    resourceUpload(value){
      this.iconLoading = true;
      const formData = new FormData();
      formData.append('file', value.file);
      request(ATTACHMENT_UPLOAD,"post",formData).then(result => {
          if (result.data.code==0) {
            var fileObj = {
              uid: '1',
              name: result.data.data.name,
              url: result.data.data.path,
            }
            this.filePath = result.data.data.path;
            this.fileList = [];
            this.fileList.push(fileObj);
            value.onSuccess(result, value.file);
          }else{
            this.$message.warning(result.message);
          }
          this.iconLoading = false;
      })
    },
  }
};
</script>
<style>
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>