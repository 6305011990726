<template>
    <a-card>
        <div id="components-form-demo-advanced-search">
            <a-form class="ant-advanced-search-form" :form="queryData" >
                <a-row :gutter="24">
                    <a-col :span="2" :style="{ textAlign: 'right', marginTop: '3px'}">
                        <a-button type="primary" icon="plus" @click="addModel('', '0')">
                            新增厂商分组
                        </a-button>
                    </a-col>
                </a-row>

            </a-form>
        </div>
        <a-table :columns="columns" :data-source="data"  >
            <template slot="manufacturer" slot-scope="text, record">
                {{record.manufacturerKey === 'yunqi'? '云启': '其他'}}
            </template>
            <template slot="action" slot-scope="text, record">
                                <span>
                                  <a @click="addModel(record.manufacturerKey, record.groupId)">添加下级</a>
                                  <a-divider type="vertical" />
<!--                                  <a @click="addModel(record.manufacturerKey, record.groupId)">查看设备</a>-->
<!--                                  <a-divider type="vertical" />-->
                                  <a @click="delGroup(record)">删除</a>
                                </span>
            </template>
        </a-table>
        <div>
            <a-modal
                    :title="title"
                    :visible="visible"
                    :confirm-loading="confirmLoading"
                    @ok="modelOk"
                    @cancel="modelCancel"
                    width="560px"
            >
                <a-form-model :model="form"
                              :label-col="labelCol"
                              :wrapper-col="wrapperCol"
                              v-if="visible"
                >
                    <a-form-model-item label="分组ID" ref="groupId" prop="groupId"  >
                        <a-input v-model="form.groupId" disabled />
                    </a-form-model-item>

                    <a-form-model-item label="分组名称" ref="groupName" prop="groupName"  required>
                        <a-input v-model="form.groupName" :disabled="groupNameDisabled"/>
                    </a-form-model-item>

                    <a-form-model-item label="所属厂商" ref="manufacturerKey" prop="manufacturerKey"  required>
                        <a-select v-model="form.manufacturerKey" >
                            <a-select-option v-for="item in manufacturerList" :value="item.manufacturerKey" :key="item.manufacturerKey">{{item.manufacturerName}}</a-select-option>
                        </a-select>
                    </a-form-model-item>

                    <a-form-model-item label="上级分组ID" ref="fatherGroupId" prop="fatherGroupId"  required>
                        <a-input v-model="form.fatherGroupId" disabled/>
                    </a-form-model-item>


                </a-form-model>
            </a-modal>
        </div>
    </a-card>
</template>



<script>
    import { getGroupList, addGroup, delGroup} from '@/services/dmgr'
    import { treeData } from '@/utils/treeutil'
    import { getAllManufacturerList } from '@/utils/dmgrConstant'
    import {mapGetters} from 'vuex'
    import { Modal } from 'ant-design-vue';
    import { useRender } from '@/hooks/useRender.js'

    const columns = [
        {
            title: '分组编号',
            dataIndex: 'groupId',
            key: 'groupId',
        },
        {
            title: '分组名称',
            dataIndex: 'groupName',
            key: 'groupName',
        },
        {
            title: '所属厂商',
            dataIndex: 'manufacturer',
            key: 'manufacturer',
            scopedSlots: { customRender: 'manufacturer' },
        },
        {
            title: '创建时间',
            key: 'createTime',
            dataIndex: 'createTime',
            customRender: (text) => useRender.renderDate(text)
        },
        {
            title: '操作',
            key: 'action',
            scopedSlots: { customRender: 'action' },
        },
    ];


    export default {
        data() {
            return {

                manufacturerList:[
                    {
                        manufacturerKey: 'yunqi',
                        manufacturerName: '云启'
                    }
                ],
                queryData:{
                    projectId: "",
                },
                data: [],
                columns,

                title: "编辑",
                visible: false,
                confirmLoading: false,
                labelCol: { span: 6 },
                wrapperCol: { span: 12 },
                form:{
                },
                groupNameDisabled: false,
            };
        },
        computed: {
            ...mapGetters('account', ['user']),
        },
        filters: {
        },

        created() {
            console.log(this.user)
            this.queryData.projectId = this.user.projectId
            this.getGroupList()
        },
        methods: {
            getGroupList() {
                getGroupList(this.queryData).then(res => {
                    let list = res.data.data;
                    // 移除厂商列表中已有的厂商
                    let allManufacturerList = getAllManufacturerList()
                    this.manufacturerList = allManufacturerList.filter(item => {
                        return list.every(item2 => item2.manufacturerKey !== item.manufacturerKey)
                    })
                    this.data = treeData(res.data.data, 'groupId', 'fatherGroupId', 'children', "0");
                });
            },
            addModel(manufacturerKey, fatherGroupId) {
                if (manufacturerKey!==undefined && manufacturerKey!=="") {
                    // 查出manufacturerKey对应的厂商
                    let allManufacturerList = getAllManufacturerList()

                    let manufacturer = allManufacturerList.find(item => item.manufacturerKey === manufacturerKey);
                    this.manufacturerList = [manufacturer];
                }
                this.form = {
                    manufacturerKey: manufacturerKey,
                    fatherGroupId: fatherGroupId,
                };
                if (fatherGroupId == '0'){
                    this.form.groupName = this.user.loginLaoWuUser.projectName.length > 50 ?
                        this.user.loginLaoWuUser.projectName.substring(0,50) : this.user.loginLaoWuUser.projectName;
                    this.groupNameDisabled = true;
                }else {
                    this.groupNameDisabled = false;

                }
                this.visible = true;
            },
            modelOk(){
                this.confirmLoading = true;
                this.form.projectId = this.user.projectId
                addGroup(this.form).then(res => {
                    this.$message.success(res.data.msg);
                }).finally(() => {
                    this.modelCancel();
                })
            },
            modelCancel(){
                this.visible = false;
                this.confirmLoading = false;
                this.getGroupList();
            },
            delGroup(record){
                let _that = this
                Modal.confirm({
                    title: () => '确定删除当前分组吗?',
                    // icon: () => createVNode(ExclamationCircleOutlined),
                    content: () => '删除后将无法恢复',

                    onOk() {
                        delGroup(record).then(res => {
                            _that.$message.success(res.data.msg);
                        }).finally(() => {
                            _that.getGroupList();
                        })
                    },

                    onCancel() {},
                });

            },
        }
    };
</script>
<style scoped>

    .ant-advanced-search-form {
        padding: 24px;
        background: #fbfbfb;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        margin-bottom: 10px;
    }

    .ant-advanced-search-form .ant-form-item {
        display: flex;
    }

    .ant-advanced-search-form .ant-form-item-control-wrapper {
        flex: 1;
    }

    #components-form-demo-advanced-search .ant-form {
        max-width: none;
    }

    tr{
        background-color: #ffffff;
    }
</style>
