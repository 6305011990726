var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "a-tabs",
        {
          attrs: {
            "default-active-key": _vm.defaultActiveKey,
            forceRender: "",
          },
          on: { change: _vm.callback },
        },
        [
          _c("a-tab-pane", { key: "1", attrs: { tab: "在场工人" } }),
          _c("a-tab-pane", { key: "0", attrs: { tab: "离场工人" } }),
        ],
        1
      ),
      _c("form-index", {
        ref: "formindex",
        attrs: {
          formData: _vm.formData,
          projectList: _vm.projectList,
          flexSubmit: true,
        },
        on: {
          handleSubmit: _vm.handleSubmit,
          handlSelect: _vm.handlSelect,
          handleChange: _vm.handleChange,
        },
        scopedSlots: _vm._u([
          {
            key: "flexSubmit",
            fn: function () {
              return [
                _c(
                  "a-space",
                  {
                    staticStyle: { "margin-left": "55px", "margin-top": "5px" },
                  },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: {
                          icon: "search",
                          type: "primary",
                          "html-type": "submit",
                        },
                      },
                      [_vm._v(" 查询 ")]
                    ),
                    _c(
                      "a-space",
                      { staticClass: "operator" },
                      [
                        _c(
                          "a-button",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.close(true)
                              },
                            },
                          },
                          [_vm._v(" 重置 ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "a_a_footer", staticStyle: { "margin-top": "-20px" } },
        [
          _c(
            "div",
            { staticClass: "mb-1 mt-2" },
            [
              _c(
                "a-space",
                { staticClass: "operator" },
                [
                  _c(
                    "a-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.exportStaff()
                        },
                      },
                    },
                    [
                      _c("a-icon", { attrs: { type: "download" } }),
                      _vm._v("导出全部信息"),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-space",
                { staticClass: "operator" },
                [
                  _vm.defaultActiveKey != 1
                    ? _c(
                        "a-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.downloadBatch()
                            },
                          },
                        },
                        [
                          _c("a-icon", { attrs: { type: "download" } }),
                          _vm._v("批量下载凭证"),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("standard-table", {
            attrs: {
              columns: _vm.columns,
              dataSource: _vm.dataSource,
              selectedRows: _vm.selectedRows,
              rowKey: "id",
              scroll: { x: 1300 },
              pagination: _vm.pagination,
              loading: _vm.tableLoading,
              isAlert: false,
              bordered: true,
            },
            on: {
              "update:selectedRows": [
                function ($event) {
                  _vm.selectedRows = $event
                },
                function ($event) {
                  return _vm.updateSelectRows($event)
                },
              ],
              "update:selected-rows": function ($event) {
                _vm.selectedRows = $event
              },
              change: _vm.onPageChange,
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function (ref) {
                  var index = ref.index
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            (_vm.pagination.current - 1) *
                              _vm.pagination.pageSize +
                              parseInt(index) +
                              1
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "hkFace",
                fn: function (ref) {
                  var text = ref.text
                  return [
                    _c(
                      "div",
                      { staticClass: "flex a-center j-center" },
                      [
                        text != "" && text != null
                          ? _c("div", [_vm._v(" " + _vm._s(text) + " ")])
                          : _vm._e(),
                        _c("a-spin", { attrs: { spinning: !text } }),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "workName",
                fn: function (ref) {
                  var record = ref.record
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to:
                            "/electronic_resume_worker/" +
                            record.projWorkNum +
                            "?teamId=" +
                            record.teamId +
                            "&workNum=" +
                            record.workNum,
                        },
                      },
                      [_vm._v(" " + _vm._s(record.workName) + " ")]
                    ),
                    record.leaveStatus == 0
                      ? _c("div", { staticStyle: { color: "#FF5C77" } }, [
                          _vm._v("(退场中)"),
                        ])
                      : _vm._e(),
                    record.projectNames &&
                    _vm.showNames(record.projectNames).length > 1
                      ? _c(
                          "a-popover",
                          [
                            _c(
                              "template",
                              { slot: "content" },
                              _vm._l(
                                _vm.showNames(record.projectNames),
                                function (item, index) {
                                  return _c("div", { key: index }, [
                                    _vm._v(_vm._s(_vm.deleNames(item))),
                                  ])
                                }
                              ),
                              0
                            ),
                            _c("template", { slot: "title" }, [
                              _c(
                                "span",
                                { staticStyle: { color: "#FF5D78" } },
                                [_vm._v("已在场项目")]
                              ),
                            ]),
                            _c("div", { staticStyle: { color: "#FF5D78" } }, [
                              _vm._v("(多项目)"),
                            ]),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "workerFace",
                fn: function (ref) {
                  var text = ref.text
                  return [
                    _c("a-avatar", {
                      attrs: {
                        shape: "square",
                        size: 64,
                        src: text,
                        icon: "user",
                      },
                    }),
                  ]
                },
              },
              {
                key: "isreport",
                fn: function (ref) {
                  var text = ref.text
                  return [
                    text == 1
                      ? _c("span", [
                          _c("i", {
                            staticClass: "iconfont duigouxiao",
                            staticStyle: { color: "#00CE86" },
                          }),
                        ])
                      : _c("span", [
                          _c("i", {
                            staticClass: "iconfont path",
                            staticStyle: { color: "#FF5C77" },
                          }),
                        ]),
                  ]
                },
              },
              {
                key: "action",
                fn: function (ref) {
                  var record = ref.record
                  return _c("div", {}, [
                    _c("div", { staticClass: "sTables" }, [
                      _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.visit(record)
                            },
                          },
                        },
                        [_vm._v("查看")]
                      ),
                    ]),
                  ])
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("Export-loading", {
        attrs: {
          cPercent: _vm.cPercent,
          isExporting: _vm.isExporting,
          loadingText: _vm.loadingText,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }