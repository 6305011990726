<template>
  <a-card>
    <form-index
      :chageT="true"
      ref="formindex"
      :formData="formData"
      :Overview="query.id ? '报价单详情' : '新增报价单'"
      :topSubmit="true"
      @handleSubmit="handleSubmit"
      @handlSelect="handlSelect"
      :defaultFileList="defaultFileList"
    >
      <!-- 上部分按钮插槽 -->
      <template #topSubmit>
        <a-space :style="{ marginTop: '4px' }">
          <a-button type="primary" html-type="submit"> 提交 </a-button>
          <a-button @click="close"> 关闭 </a-button>
        </a-space>
      </template>
    </form-index>
    <!-- <a-space class="operator">
      <a-button type="primary" html-type="submit"> 提交 </a-button>
        <a-button @click="close"> 关闭 </a-button>
      </a-space> -->
      <a-space v-if="!query.id" class="operator mb-2">
      <a-button type="primary" html-type="submit"> 提交 </a-button>
        <a-button @click="close"> 关闭 </a-button>
      </a-space>
      <div class="table-container">
      <standard-table
      class="table-container"
      :columns="columns"
      :dataSource="dataSource"
      row-key="id"
      :loading="tableLoading"
      :bordered="true"
      :pagination="false"
    >
      <template slot="index" slot-scope="{ index }">
        <span>
          {{
            (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1
          }}
        </span>
      </template>
      <template slot="name" slot-scope="{ text, record }">
        <a-tooltip placement="topLeft">
          <template slot="title"
            ><span>{{ text }}</span></template
          >
          <span class="ant-btn-text ellipsis" @click="toUnits(record)">{{
            text
          }}</span>
        </a-tooltip>
      </template>
      <div slot="userName" slot-scope="{ record }">
        <div v-if="record.userName">
          <div>{{ record.userName }}</div>
          <span v-if="record.userPhone">({{ record.userPhone }})</span>
        </div>
      </div>
      <div slot="action" slot-scope="{ record }">
        <a-dropdown v-if="isAdmin" placement="bottomCenter" class="sTables">
          <div>
            <span>操作</span>
            <a-icon
              style="margin-left: 2px; font-size: 12px"
              type="caret-down"
            />
          </div>
          <a-menu :class="['avatar-menu']" slot="overlay">
            <a-menu-item @click="viewEditing(record, 'edit')">
              <a-icon type="edit" />
              <span>修改</span>
            </a-menu-item>
            <a-menu-item @click="recorDelete(record)">
              <a-icon type="delete" />
              <span>删除</span>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <span v-else>/</span>
      </div>
    </standard-table>
      </div>
    <a-row type="flex" justify="end" class="mt-2">
      <a-col style="font-size:16px">
        <div class="flex j-end">
          <span>共{{ dataSource.length }}项</span><span class="ml-1">总计: ￥{{ originData.totalMoney }}</span>
        </div>
        <div class="my-2 flex j-end a-center">
         折扣<a-input v-model="discount" prefix="￥" suffix="RMB" class="ml-1" style="width:150px" />
        </div>
        <div class="flex j-end">
          <span>实付</span> 
          <span class="ml-1">￥{{ setDiscount }}</span>
        </div>
      </a-col>
      
    </a-row>
    <a-modal :width="1300" :centered="true" v-model="productVisible" :title="query.id ? '查看产品' : '添加产品'">
      <standard-table
      :columns="productColumns"
      :dataSource="productSource"
      row-key="id"
      :loading="tableLoading"
      bordered
      :pagination="false"
      :selectedRows.sync="selectedRows"
    >
      <template slot="index" slot-scope="{ index }">
        <span>
          {{
            (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1
          }}
        </span>
      </template>
      <template slot="name" slot-scope="{ text }">
        <a-tooltip placement="topLeft">
          <template slot="title"
            ><span>{{ text }}</span></template
          >
          <span class="ant-btn-text ellipsis">{{
            text
          }}</span>
        </a-tooltip>
      </template>
    </standard-table>
    </a-modal>
  </a-card>
</template>

<script>
const formData = [
  {
    label: "标题",
    placeholder: "请输入标题",
    decorator: [
      "title",
      { rules: [{ required: true, message: "请输入标题" }] },
    ],
    type: "input",
    key: "title",
    labelCol: 4,
    wrapperCol: 12,
    col: 12,
    display: true,
  },
  // {
  //   label: "客户名称",
  //   placeholder: "请输入客户名称",
  //   decorator: [
  //     "saleMan",
  //     { rules: [{ required: true, message: "请输入客户名称" }] },
  //   ],
  //   type: "input",
  //   key: "saleMan",
  //   col: 12,
  //   labelCol: 4,
  //   wrapperCol: 12,
  //   display: true,
  // },
  {
    label: "项目名称",
    placeholder: "请选择产品类别",
    decorator: [
      "projectName",
      { rules: [{ required: true, message: "请选择产品类别" }] },
    ],
    type: "select",
    key: "projectName",
    selectlist: [],
    col: 12,
    labelCol: 4,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "项目地址",
    placeholder: "请输入项目地址",
    decorator: [
      "projAddress",
      { rules: [{ required: true, message: "请输入项目地址" }] },
    ],
    type: "input",
    key: "projAddress",
    col: 12,
    labelCol: 4,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "业务员",
    placeholder: "请选择业务员",
    decorator: [
      "saleMan",
      { rules: [{ required: false, message: "请选择业务员" }] },
    ],
    type: "input",
    key: "saleMan",
    col: 12,
    labelCol: 4,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "联系电话",
    placeholder: "请输入产品价格(不含税)",
    decorator: [
      "phone",
      { rules: [{ required: true, message: "请输入产品价格(不含税)" }] },
    ],
    type: "input",
    key: "phone",
    col: 12,
    labelCol: 4,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "运维时间",
    placeholder: "请填写运维时间",
    decorator: [
      "dueTime",
      { rules: [{ required: true, message: "请填写运维时间" }] },
    ],
    type: "input",
    key: "dueTime",
    col: 12,
    labelCol: 4,
    wrapperCol: 12,
    display: true,
  }
];
const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    align: "center",
  },
  {
    title: "产品",
    align: "center",
    dataIndex: "typeName",
    width: 80,
  },
  {
    title: "子产品",
    align: "center",
    dataIndex: "pdName",
    width: 100,
  },
  {
    title: "产品详情",
    dataIndex: "pdGgxh",
    width: 80,
    align: "center",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "产品(原价)",
    dataIndex: "oldPrice",
    width: 80,
    align: "center",
  },
  {
    title: "产品报价",
    dataIndex: "price",
    width: 80,
    align: "center",
  },
  {
    title: "税率",
    dataIndex: "oldTax",
    width: 80,
    align: "center",
  },
  {
    title: "执行税率",
    dataIndex: "doTax",
    width: 80,
    align: "center",
  },
  {
    title: "数量",
    dataIndex: "cnt",
    width: 60,
    align: "center",
  },
  // {
  //   title: "创建时间",
  //   dataIndex: "createTime",
  //   width: 80,
  //   align: "center",
  //   scopedSlots: { customRender: "createTime" },
  // },

  {
    title: "操作",
    align: "center",
    width: 80,
    scopedSlots: { customRender: "action" },
  },
];
const productColumns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    align: "center",
  },
  {
    title: "产品",
    align: "center",
    dataIndex: "typeName",
    width: 80,
  },
  {
    title: "子产品",
    align: "center",
    dataIndex: "pdName",
    width: 100,
  },
  {
    title: "产品详情",
    dataIndex: "ggxh",
    width: 80,
    align: "center",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "产品(原价)",
    dataIndex: "price",
    width: 80,
    align: "center",
  },
  {
    title: "产品报价",
    dataIndex: "lastPrice",
    width: 80,
    align: "center",
  },
  {
    title: "税率",
    dataIndex: "tax",
    width: 80,
    align: "center",
  },
  {
    title: "执行税率",
    dataIndex: "doTax",
    width: 80,
    align: "center",
  },
  {
    title: "数量",
    dataIndex: "cnt",
    width: 60,
    align: "center",
  },
];
import formIndex from "@/pages/components/form/index";
import { mapGetters } from "vuex";
import {
  getProductList,
  addProductDetail,
  editProductDetail,
  getQuotationDetail,
  getProductDetailList
} from "@/services/product";
import StandardTable from "@/components/table/StandardTable";
export default {
  data() {
    return {
      formData: formData,
      defaultFileList: [], //图片数组
      roleTypeList: [], // 所属产品数组
      query: this.$route.query,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      prams: {
        pageNumber: 1,
        pageSize: 10,
      },
      tableLoading: false,
      dataSource: [],
      columns,
      productColumns,
      originData: {},
      discount: 0, // 折扣价
      productVisible: false,
      productSource:[], // 子产品数组
      selectedRows: [],
    };
  },
  components: {
    formIndex,
    StandardTable
  },
  inject: ["fatherMethod"],
  computed: {
    ...mapGetters("account", ["user"]),
    isAdmin() {
      return this.user.nowRoleKey.includes("admin");
    },
    setDiscount() {
      if (!this.originData.totalMoney && !this.discount) return 0
      return this.originData.totalMoney - this.discount
    }
  },
  created() {},
  mounted() {
    this.getProductList();
    // 有id获取详情
    if (this.query.id) {
      this.getQuotationDetail();
    } else {
      this.getProductDetailList()
    }
  },
  activated() {
    this.discount = 0
  },
  methods: {
    // 编辑初始化数据
    getQuotationDetail() {
      this.tableLoading = true
      getQuotationDetail({ id: this.query.id }).then((res) => {
        if (res.data.code === 0) {
        this.originData = res.data.data
        this.$refs["formindex"].queryObj(res.data.data);
        this.dataSource = res.data.data.productDetailList
        this.tableLoading = false
        }
      });
    },
    getProductDetailList() {
      getProductDetailList({}).then((res) => {
        if (res.data.code === 0) {
          this.productSource = res.data.data;
        }
      });
    },
    // 关闭当前页面
    close() {
      let path = this.$route.path;
      this.fatherMethod(`${path}`);
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      this.formData.forEach((item) => {
        if (item.key == key) {
          item.selectlist = data;
        }
      });
    },
    getProductList() {
      getProductList({}).then((res) => {
        const data = res.data.data;
        const arr = [];
        data.forEach((item) => {
          arr.push({
            name: item.pdName,
            value: Number(item.id),
          });
        });
        this.roleTypeList = arr;
        this.formDataFn("typeId", arr);
      });
    },
    // 提交表单数据
    handleSubmit(e) {
      console.log(e, "ee");
      if (this.query.id) {
        e.id = this.query.id;
        editProductDetail(e).then((res) => {
          if (res.data.code === 0) {
            this.$message.success("修改成功", 10);
            // this.$refs["formindex"].resetFields();
          } else {
            this.$message.error(res.data.msg, 10);
          }
        });
      } else {
        addProductDetail(e).then((res) => {
          if (res.data.code === 0) {
            this.$message.success("新增成功", 10);
            // this.$refs["formindex"].resetFields();
          } else {
            this.$message.error(res.data.msg, 10);
          }
        });
      }
      this.close();
    },
    // 特殊数据处理
    handlSelect({ val, keys }) {
      console.log(val, keys, "val", "keys");
      if (keys == "typeId") {
        this.roleTypeList.forEach((item) => {
          if (item.value == val) {
            this.$refs["formindex"].setFieldsValue(
              item.name,
              { typeName: item.name },
              "typeName"
            );
          }
        });
      }
    },
    //表格 - 分页查询
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      this.prams.pageNumber = e.current;
      this.pagination.current = e.current;
      this.getQuotationDetail()
      // this.aqxjlist(Object.assign({}, this.prams, obj));
    },
  },
};
</script>
<style lang="less" scoped>
  .ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 显示行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-container {
  width: 100%;
  height: 200px; /* 设置容器高度 */
  overflow: auto; /* 显示滚动条 */
}

</style>
