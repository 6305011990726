var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a_Method" },
    [
      _c(
        "a-row",
        { attrs: { gutter: 16 } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-card",
                {
                  staticStyle: { height: "900px" },
                  style: "overflow:auto",
                  attrs: { title: "班组设置门禁通道", bordered: false },
                },
                [
                  !_vm.spinning
                    ? _c(
                        "a-tree",
                        {
                          attrs: {
                            "tree-data": _vm.treeDataSource,
                            checkable: "",
                            checkStrictly: "",
                            defaultExpandParent: true,
                            defaultExpandAll: true,
                          },
                          on: { check: _vm.onCheck },
                          model: {
                            value: _vm.checkedKeys,
                            callback: function ($$v) {
                              _vm.checkedKeys = $$v
                            },
                            expression: "checkedKeys",
                          },
                        },
                        [_c("a-spin", { attrs: { spinning: _vm.spinning } })],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }