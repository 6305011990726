<template>
  <div class="wrap-cantainer ant-card ant-card-bordered ant-card-body">
    <a-row :gutter="16">
      <a-col :span="12">
        <div class="relative">
          <baidu-map
              class="bmView"
              :scroll-wheel-zoom="true"
              :center="location"
              :zoom="zoom">
            <bm-view style="width: 100%; height:350px; flex: 1"></bm-view>
            <bm-local-search :keyword="addressKeyword" :auto-viewport="true" style="display: none"></bm-local-search>
            <div v-for="(items,index) in persons" :key="index">
              <bm-marker :position="{lng: items.lng, lat: items.lat}" :icon="{url: items.icon, size: {width: 28, height: 28}}" :dragging="false" @click="infoWindowOpen(index)">
                <bm-info-window :show="items.show" @close="infoWindowClose(index)" @open="infoWindowOpen(index)">{{items.name}}</bm-info-window>
              </bm-marker>
            </div>
          </baidu-map>
          <div class="unit-box">
            <label>单位：</label>
            <a-select :default-value="defaultUnit" v-model="defaultUnit"  style="width: 160px" @change="onHandleUnitChange">
              <a-select-option   v-for="(items,id) in unitList" :key="id" :value="items.id">
                {{items.name}}
              </a-select-option>
            </a-select>
          </div>
          <!-- <div class="gc-box flex">
            <div class="text-center flex-1 gc-box-item ">
              <div class="flex a-center j-center border-right"><img class="gc-box-img" :src="local1"/>在建工程{{gcObj.zaijian || 0}}个</div>
            </div>
            <div class="text-center flex-1 gc-box-item">
              <div class="flex a-center j-center border-right">
                <img class="gc-box-img" :src="local2"/>
                暂停工程{{gcObj.tinggong || 0}}个</div>
            </div>
            <div class="text-center flex-1 gc-box-item">
              <div class="flex a-center j-center">
                <img class="gc-box-img" :src="local3"/>
                完工工程{{gcObj.wangong || 0}}个</div>
            </div>
          </div> -->
        </div>
        <div class="mt-2 " style="border:1px solid #f0f0f0;height: 240px">
          <a-table size="middle" :scroll="pagination" :pagination="false" :columns="projectColumns" :data-source="projectData" bordered>
            <a slot="projectName"   slot-scope="text,record">{{ record.projectName }}</a>
            <div slot="xmjl" slot-scope="text,record">{{ record.xmjl }}</div>
            <div slot="phone" slot-scope="text,record">{{record.phone}}</div>
          </a-table>
        </div>
      </a-col>
      <a-col :span="12">
        <a-card
            style="width:100%;height:280px"
            :tab-list="tabListNoTitle"
            :active-tab-key="noTitleKey"
            @tabChange="key => onTabChange(key, 'noTitleKey')"
        >
          <p v-if="noTitleKey === 'office'">
            <a-row :gutter="16" style="padding-left:5%">
              <a-col :span="6">
                <a-statistic
                    title="检查项目总数"
                    :value="gkObj.projects"

                    suffix="个"
                    :value-style="{ color: 'blue' }"
                />
              </a-col>
              <a-col :span="6">
                <a-statistic
                    title="一般隐患数"
                    :value="`${yhzs-gkObj.zdyhs}`"
                    suffix="个"
                    :value-style="{ color: '#cf1322' }"
                />
              </a-col>
              <a-col :span="6">
                <a-statistic
                    title="重大隐患数"
                    suffix="个"
                    :value="gkObj.zdyhs"
                    :value-style="{ color: '#cf1322' }"
                />
              </a-col>
              <a-col :span="6">
                <a-statistic
                    title="隐患整改率"
                    :value="gkObj.zgl"
                />
              </a-col>

              <!--                <a-col :span="6">-->
              <!--                  <a-statistic-->
              <!--                    title="超时未整改数"-->
              <!--                    :value="`${gkObj.cswzgs|0}`"-->
              <!--                    suffix="个"-->
              <!--                    :value-style="{ color: '#cf1322' }"-->
              <!--                  />-->
              <!--                </a-col>-->

            </a-row>
            <a-row :gutter="16" style="padding-left:5%">
              <a-col :span="6">
                <a-statistic
                    title="检查总数"
                    :value="totalChecks"
                    suffix="次"
                    :value-style="{ color: 'green' }"
                />
              </a-col>
              <a-col :span="6">
                <a-statistic
                    title="日常检查总数"
                    :value="`${gkObj.checks - gkObj.zxjc - gkObj.tgdcnt}`"
                    suffix="次"
                    :value-style="{ color: 'green' }"
                />
              </a-col>
              <a-col :span="6">
                <a-statistic
                    title="专项检查总数"
                    :value="`${gkObj.zxjc|0}`"
                    suffix="次"
                    :value-style="{ color: 'purple' }"
                />
              </a-col>
              <a-col :span="6">
                <a-statistic
                    title="带班检查总数"
                    :value="`${gkObj.dbCnt|0}`"
                    suffix="次"
                    :value-style="{ color: '#000' }"
                />
              </a-col>
            </a-row>
            <a-row :gutter="16" style="padding-left:5%">
               <a-col :span="6">
                <a-statistic
                    title="安全验收总数"
                    :value="`${gkObj.aqyscnt|0}`"
                    suffix="份"
                    :value-style="{ color: 'green' }"
                />
              </a-col>
              <a-col :span="6">
                <a-statistic
                    title="停工通知书总数"
                    :value="`${gkObj.tgdcnt|0}`"
                    suffix="份"
                    :value-style="{ color: 'green' }"
                />
              </a-col>
              <a-col :span="6">
                <a-statistic
                    title="处罚通知书总数"
                    :value="`${gkObj.cfdcnt|0}`"
                    suffix="份"
                    :value-style="{ color: 'green' }"
                />
              </a-col>
              <a-col :span="6">
                <a-statistic
                    title="安全生产约谈总数"
                    :value="`${gkObj.ytcnt|0}`"
                    suffix="次"
                    :value-style="{ color: 'purple' }"
                />
              </a-col>

            </a-row>
          </p>
          <p v-else-if="noTitleKey === 'gcglb'">
            <a-row :gutter="16" style="padding-left:5%">
              <a-col :span="6">
                <a-statistic
                    title="检查项目总数"
                    :value="gcglbObj.projects"

                    suffix="个"
                    :value-style="{ color: 'blue' }"
                />
              </a-col>
              <!-- <a-col :span="6">
                <a-statistic
                  title="隐患总数"
                  :value="gcglbYhzs"
                  suffix="个"
                  :value-style="{ color: '#cf1322' }"
                />
              </a-col> -->
              <a-col :span="6">
                <a-statistic
                    title="一般隐患"
                    :value="`${gcglbYhzs-gcglbObj.zdyhs}`"
                    suffix="个"
                    :value-style="{ color: '#cf1322' }"
                />
              </a-col>
              <a-col :span="6">
                <a-statistic
                    title="重大隐患"
                    :value="`${gcglbObj.zdyh|0}`"
                    suffix="个"
                    :value-style="{ color: '#cf1322' }"
                />
              </a-col>
              <a-col :span="6">
                <a-statistic
                    title="隐患整改率"
                    :value="gcglbObj.zgl"
                />
              </a-col>

              <!-- <a-col :span="6">
                <a-statistic
                  title="超时未整改数"
                  :value="`${gcglbObj.cswzgs|0}`"
                  suffix="个"
                  :value-style="{ color: '#cf1322' }"
                />
              </a-col> -->

            </a-row>
            <a-row :gutter="16" style="padding-left:5%">
              <a-col :span="6">
                <a-statistic
                    title="安全验收总数"
                    :value="`${gkObj.aqyscnt|0}`"
                    suffix="份"
                    :value-style="{ color: 'green' }"
                />
              </a-col>
              <a-col :span="6">
                <a-statistic
                    title="检查总数"
                    :value="gcglbObj.checks"
                    suffix="次"
                    :value-style="{ color: 'green' }"
                />
              </a-col>
              <a-col :span="6">
                <a-statistic
                    title="日常检查总数"
                    :value="`${isNaN(gcglbObj.checks - gcglbObj.zxjc - gkObj.tgdcnt) ? 0 : gcglbObj.checks - gcglbObj.zxjc - gkObj.tgdcnt}`"
                    suffix="次"
                    :value-style="{ color: 'green' }"
                />
              </a-col>
              <a-col :span="6">
                <a-statistic
                    title="专项检查总数"
                    :value="`${gcglbObj.zxjc|0}`"
                    suffix="次"
                    :value-style="{ color: 'purple' }"
                />
              </a-col>
            </a-row>
          </p>
          <div slot="tabBarExtraContent" style="float:right;max-width:350px">
            <div style="max-width: 60%;float:right">
              <a-range-picker v-if="monthQueryParams.queryType==3"  v-model="monthQueryParams.plan_date" :default-value="[moment(firstDay, dateFormat), moment(nowDay, dateFormat)]"
                              :format="dateFormat"  @change="mtChangeDate"/>
              <a-month-picker style="width: 100px" v-if="monthQueryParams.queryType==2"  @change="mtChangeDate" placeholder="请选择月份" :default-value="moment(nowMonth, monthFormat)" :format="monthFormat"  v-model="monthQueryParams.plan_date">
                <a-icon slot="suffixIcon" type="smile"/>
              </a-month-picker>
              <a-date-picker
                  v-if="monthQueryParams.queryType==1"
                  mode="year"
                  v-model="monthQueryParams.plan_date"
                  placeholder="请输入年份"
                  :default-value="moment(nowYear, yearFormat)" :format="yearFormat"
                  style="width: 100px"
                  :open="monthQueryParams.yearShowOne"
                  @openChange="monthOpenChangeOne"
                  @panelChange="monthPanelChangeOne"/>
            </div>
            <a-select  v-model="monthQueryParams.queryType" style="width: 80px;float:right;top:12px" @change="mtChangeTypeDate">
              <a-select-option :key="1"> 年 </a-select-option>
              <a-select-option :key="2"> 月 </a-select-option>
              <a-select-option :key="3">其他</a-select-option>
            </a-select>
          </div>
        </a-card>
        <!-- <a-card title="公司巡检概况" style="height:250px">
          <div style="float:right" slot="extra">
            <div style="max-width: 60%;float:right">
              <a-range-picker v-if="monthQueryParams.queryType==3"  v-model="monthQueryParams.plan_date" :default-value="[moment(firstDay, dateFormat), moment(nowDay, dateFormat)]"
                    :format="dateFormat"  @change="mtChangeDate"/>
              <a-month-picker style="width: 100px" v-if="monthQueryParams.queryType==2"  @change="mtChangeDate" placeholder="请选择月份" :default-value="moment(nowMonth, monthFormat)" :format="monthFormat"  v-model="monthQueryParams.plan_date">
                <a-icon slot="suffixIcon" type="smile"/>
              </a-month-picker>
              <a-date-picker
                v-if="monthQueryParams.queryType==1"
                mode="year"
                v-model="monthQueryParams.plan_date"
                placeholder="请输入年份"
                :default-value="moment(nowYear, yearFormat)" :format="yearFormat"
                style="width: 100px"
                :open="monthQueryParams.yearShowOne"
                @openChange="monthOpenChangeOne"
                @panelChange="monthPanelChangeOne"/>
            </div>
            <a-select  v-model="monthQueryParams.queryType" style="width: 80px;float:right" @change="mtChangeTypeDate">
              <a-select-option :key="1"> 年 </a-select-option>
              <a-select-option :key="2"> 月 </a-select-option>
              <a-select-option :key="3">其他</a-select-option>
            </a-select>

          </div>

        </a-card> -->
      </a-col>
      <a-col :span="12" class="mt-2">
        <a-card style="height:310px" :tab-list="awardTabList" :active-tab-key="awardTabKey" @tabChange="onAwardTabChange">
          <div v-if="awardTabKey === 'hjqk'" style="float:right" slot="tabBarExtraContent">
            <span>年度：</span>
            <a-range-picker
              style="width: 200px;"
              :mode="['year', 'year']"
              :default-value="[moment(awardParams[0], 'YYYY'), moment(awardParams[1], 'YYYY')]"
              :format="'YYYY'"
              @change="awardYearChange"
              @openChange="awardOpenChange"              
              @panelChange="awardPanelChange"
              :open="awardYearOpen"
              v-model="awardParams"
            />
          </div>
          <div v-else-if="awardTabKey === 'jpqk'" style="float:right" slot="tabBarExtraContent">
            <span>月份：</span>
            <a-range-picker
              style="width: 200px;"
              :mode="['month', 'month']"
              :default-value="[moment(awardMonParams[0], 'YYYY-MM'), moment(awardMonParams[1], 'YYYY-MM')]"
              :format="'YYYY-MM'"
              @change="awardMonChange"
              @openChange="awardMonOpenChange"              
              @panelChange="awardMonPanelChange"
              :open="awardMonOpen"
              v-model="awardMonParams"
            />
          </div>
          <a-table
            size="middle"
            :bordered="true"
            :columns="awardColumns"
            :dataSource="awardDataSource"
            :pagination="false"
          >
          </a-table>  
        </a-card>
      </a-col>

    </a-row>
    <a-row :gutter="16" style="margin-top:20px">
      <a-col :span="12">
        <a-row :gutter="16">
          <a-col :span="24">
            <a-card>
              <span slot="title">{{gcglbtitle||'公司' }}检查次数统计（总计：{{checkTimeTotal}}次）</span>
              <div style="float:right" slot="extra">
                <div style="max-width: 60%;float:right">
                  <a-range-picker v-if="xjTimesQueryParams.queryType==3"  v-model="xjTimesQueryParams.plan_date" :default-value="[moment(firstDay, dateFormat), moment(nowDay, dateFormat)]"
                                  :format="dateFormat"  @change="xjTimesChangeDate"/>
                  <a-month-picker style="width: 100px" v-if="xjTimesQueryParams.queryType==2"  @change="xjTimesChangeDate" placeholder="请选择月份" :default-value="moment(nowMonth, monthFormat)" :format="monthFormat"  v-model="xjTimesQueryParams.plan_date">
                    <a-icon slot="suffixIcon" type="smile"/>
                  </a-month-picker>
                  <a-date-picker
                      v-if="xjTimesQueryParams.queryType==1"
                      mode="year"
                      v-model="xjTimesQueryParams.plan_date"
                      placeholder="请输入年份"
                      :default-value="moment(nowYear, yearFormat)" :format="yearFormat"
                      style="width: 100px"
                      :open="xjTimesQueryParams.yearShowOne"
                      @openChange="xjTimesOpenChangeOne"
                      @panelChange="xjTimesPanelChangeOne"/>
                </div>
                <a-select  v-model="xjTimesQueryParams.queryType" style="width: 80px;float:right" @change="checkTimeTypeChange">
                  <a-select-option :key="1"> 年 </a-select-option>
                  <a-select-option :key="2"> 月 </a-select-option>
                  <a-select-option :key="3">其他</a-select-option>
                </a-select>

              </div>
              <!-- <div style="float:right" slot="extra">
                <a-select v-model="checkTimeType" style="width: 100px" @change="checkTimeChange">
                  <a-select-option :key="1"> 当月每天 </a-select-option>
                  <a-select-option :key="2"> 当年每月 </a-select-option>
                </a-select>
              </div> -->
              <div id="checkTimesChart" style="width:98%;height:300px"></div>
            </a-card>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24" class="mt-2">
            <a-card>
              <div style="float:right" slot="extra">
                <a-date-picker
                    mode="year"
                    v-model="zglParams.plan_date"
                    placeholder="请输入年份"
                    :default-value="moment(nowYear, yearFormat)" :format="yearFormat"
                    style="width: 100px"
                    :open="zglParams.yearShowOne"
                    @openChange="zglOpenChangeOne"
                    @panelChange="zglPanelChangeOne"/>
              </div>
              <span slot="title">{{gcglbtitle||'公司' }}每月隐患整改率统计情况  （整改率：{{yhzglTotal||0}}）</span>
              <div id="yhzglChart" style="width:98%;height:300px"></div>
            </a-card>
          </a-col>
        </a-row>

      </a-col>
      <a-col :span="12">
        <a-row :gutter="16">
          <a-col :span="24">
            <a-card title='项目月度自检情况' style="height:258px">
              <div style="float:right" slot="extra">
                <!-- <div style="max-width: 60%;float:right"> -->
                <!-- <a-range-picker v-if="proQueryParams.queryType==3"  v-model="proQueryParams.plan_date" :default-value="[moment(firstDay, dateFormat), moment(nowDay, dateFormat)]"
                      :format="dateFormat"  @change="proChangeDate"/> -->
                <a-month-picker style="width: 100px"  @change="proChangeDate" placeholder="请选择月份" :default-value="moment(nowMonth, monthFormat)" :format="monthFormat"  v-model="proQueryParams.plan_date">
                  <a-icon slot="suffixIcon" type="smile"/>
                </a-month-picker>
                <!-- <a-date-picker
                  v-if="proQueryParams.queryType==1"
                  mode="year"
                  v-model="proQueryParams.plan_date"
                  placeholder="请输入年份"
                  :default-value="moment(nowYear, yearFormat)" :format="yearFormat"
                  style="width: 100px"
                  :open="proQueryParams.yearShowOne"
                  @openChange="proOpenChangeOne"
                  @panelChange="proPanelChangeOne"/> -->
                <!-- </div> -->
                <!-- <a-select  v-model="proQueryParams.queryType" style="width: 80px;float:right" @change="proTop10Change">
                  <a-select-option :key="1"> 年 </a-select-option>
                  <a-select-option :key="2"> 月 </a-select-option>
                  <a-select-option :key="3">其他</a-select-option>
                </a-select> -->

              </div>
              <vue-scroll :ops="ops" style="width:100%;height:220px">
                <a-list bordered :data-source="dataSource" item-layout="horizontal" >
                  <a-list-item slot="renderItem" slot-scope="item,index">
                    <a-list-item-meta>
                      <template slot="description">{{index+1}}.{{item.projectName}}</template>

                    </a-list-item-meta>
                    <a>{{item.cnt}}次</a>
                  </a-list-item>
                </a-list>
              </vue-scroll>
            </a-card>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-card>
              <span slot="title">{{gcglbtitle||'公司' }}检查隐患类型统计  （总计：{{yhzsTotal}}个）</span>
              <div style="float:right" slot="extra">
                <div style="max-width: 60%;float:right">
                  <a-range-picker v-if="queryParams.type==3"  v-model="queryParams.plan_date" :default-value="[moment(firstDay, dateFormat), moment(nowDay, dateFormat)]"
                                  :format="dateFormat"  @change="changeDate"/>
                  <a-month-picker style="width: 100px" v-if="queryParams.type==2"  @change="changeDate" placeholder="请选择月份" :default-value="moment(nowMonth, monthFormat)" :format="monthFormat"  v-model="queryParams.plan_date">
                    <a-icon slot="suffixIcon" type="smile"/>
                  </a-month-picker>
                  <a-date-picker
                      v-if="queryParams.type==1"
                      mode="year"
                      v-model="queryParams.plan_date"
                      placeholder="请输入年份"
                      :default-value="moment(nowYear, yearFormat)" :format="yearFormat"
                      style="width: 100px"
                      :open="yearShowOne"
                      @openChange="openChangeOne"
                      @panelChange="panelChangeOne"/>
                </div>
                <a-select  v-model="queryParams.type" style="width: 80px;float:right" @change="yhzsTypeChange">
                  <a-select-option :key="1"> 年 </a-select-option>
                  <a-select-option :key="2"> 月 </a-select-option>
                  <a-select-option :key="3">其他</a-select-option>
                </a-select>

              </div>
              <!-- <div id="yhzsChart" style="width:98%;height:398px"></div> -->
              <div id="yhzsPieChart" style="width:98%;height:300px"></div>
              <a-divider>隐患分布情况</a-divider>
              <div style="width:98%;height:120px">
                <a-table
                    :bordered="true"
                    :columns="columns"
                    :dataSource="dataSourceTypeCount"
                    :pagination="false"
                >
                </a-table>

              </div>
            </a-card>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
    <a-row :gutter="16">
      <a-col :span="24" class="mt-2">
        <a-card>
          <a-card :tab-list="tabNoTitle"
                  :active-tab-key="TitleKey"
                  @tabChange="key => TabChange(key, 'TitleKey')" >
            <div slot="tabBarExtraContent" style="float:right;max-width:350px">
              <div style="float:right;max-width: 60%;">
                <a-range-picker v-if="gcglbQueryParams.queryType==3"  v-model="gcglbQueryParams.plan_date" :default-value="[moment(firstDay, dateFormat), moment(nowDay, dateFormat)]"
                                :format="dateFormat"  @change="gcglbChangeDate"/>
                <a-month-picker style="width: 100px" v-if="gcglbQueryParams.queryType==2"  @change="gcglbChangeDate" placeholder="请选择月份" :default-value="moment(nowMonth, monthFormat)" :format="monthFormat"  v-model="gcglbQueryParams.plan_date">
                  <a-icon slot="suffixIcon" type="smile"/>
                </a-month-picker>
                <a-date-picker
                    v-if="gcglbQueryParams.queryType==1"
                    mode="year"
                    v-model="gcglbQueryParams.plan_date"
                    placeholder="请输入年份"
                    :default-value="moment(nowYear, yearFormat)" :format="yearFormat"
                    style="width: 100px"
                    :open="gcglbQueryParams.yearShowOne"
                    @openChange="gcglbOpenChangeOne"
                    @panelChange="gcglbPanelChangeOne"/>
              </div>
              <a-select  v-model="gcglbQueryParams.queryType" style="width: 100px;float:right;top:12px" @change="gcglbTimeChange">
                <a-select-option :key="1"> 年 </a-select-option>
                <a-select-option :key="2"> 月 </a-select-option>
                <a-select-option :key="3">其他</a-select-option>
              </a-select>
            </div>
          </a-card>
          <p v-if="TitleKey==='office'">
            <a-table
                :bordered="true"
                :columns="columns1"
                :dataSource="gcglbDatas"
                :pagination="false"
            >
            </a-table>
          </p>
          <p v-else-if="TitleKey==='gcglb'">
            <a-table
                :bordered="true"
                :columns="columns2"
                :dataSource="gcglbDatas2"
                :pagination="false"
            >
            </a-table>
          </p>
          <p v-else-if="TitleKey==='tgcfd'">
            <a-table
                :bordered="true"
                :columns="columns3"
                :dataSource="tgcfDatas"
                :pagination="false"
            >
            </a-table>
          </p>
        </a-card>
      </a-col>
    </a-row>
  </div>



</template>

<script>
const awardColumns = [
  {
    title: '安全创优奖项',
    dataIndex: 'cyLevelName',
    align: 'center',
    scopedSlots: { customRender: 'cyLevelName' }
  },
  {
    title: '获奖项目 (个)',
    dataIndex: 'projectNum',
    align: 'center',
    scopedSlots: { customRender: 'projectNum' },
  },
  {
    title: '奖项总数(项)',
    dataIndex: 'totalNum',
    align: 'center',
    scopedSlots: { customRender: 'totalNum' },
  },
  {
    title: '房建（项）',
    dataIndex: 'house',
    align: 'center',
    scopedSlots: {customRender: 'house'},
  },
  {
    title: '市政（项）',
    dataIndex: 'government',
    align: 'center',
    scopedSlots: {customRender: 'government'},
  },
]
const columns = [
  {
    title: '隐患总数',
    dataIndex: 'total',
    align: 'center',
    scopedSlots: { customRender: 'total' }
  },
  {
    title: '安全管理',
    dataIndex: 'pb1',
    align: 'center',
    scopedSlots: { customRender: 'pb1' },
    className:"aqglclass"
  },
  {
    title: '文明施工',
    dataIndex: 'pb2',
    align: 'center',
    scopedSlots: {customRender: 'pb2'},
    className:"wmsgclass"
  },
  {
    title: '脚手架',
    dataIndex: 'pb3',
    align: 'center',
    scopedSlots: {customRender: 'pb3'},
    className:"jsjclass"
  },
  {
    title: '基坑与模板',
    dataIndex: 'pb4',
    align: 'center',
    scopedSlots: {customRender: 'pb4'},
    className:"jkymbclass"
  },
  {
    title: '高处作业',
    dataIndex: 'pb6',
    align: 'center',
    scopedSlots: {customRender: 'pb6'},
    className:"gczyclass"
  },
  {
    title: '施工用电',
    dataIndex: 'pb7',
    align: 'center',
    scopedSlots: {customRender: 'pb7'},
    className:"sgydclass"
  },
  {
    title: '大型设备',
    dataIndex: 'pb8',
    align: 'center',
    scopedSlots: {customRender: 'pb8'}
    ,
    className:"dxsbclass"
  },
  {
    title: '起重吊装',
    dataIndex: 'pb54',
    align: 'center',
    scopedSlots: {customRender: 'pb54'},
    className:"qzdzclass"
  },
  {
    title: '施工机具',
    dataIndex: 'pb11',
    align: 'center',
    scopedSlots: { customRender: 'pb11' },
    className:"sgjjclass"
  }
]
const columns1=[
  {
    title: '工程管理部',
    dataIndex: 'officeName',
    align: 'center',
    scopedSlots: { customRender: 'officeName' }
  },
  {
    title: '在建项目',
    dataIndex: 'totalProjs',
    align: 'center',
    scopedSlots: { customRender: 'totalProjs' }
  },
  {
    title: '排查项目数量',
    dataIndex: 'checkProjs',
    align: 'center',
    scopedSlots: { customRender: 'checkProjs' }
  },
  {
    title: '安全隐患检查次数',
    dataIndex: 'checkTimes',
    align: 'center',
    scopedSlots: { customRender: 'checkTimes' }
  },
  {
    title: '检查覆盖率',
    dataIndex: 'checkProjFgl',
    align: 'center',
    scopedSlots: { customRender: 'checkProjFgl' }
  },
  {
    title: '隐患总数',
    dataIndex: 'yhzs',
    align: 'center',
    scopedSlots: { customRender: 'yhzs' }
  },
  {
    title: '隐患整改率',
    dataIndex: 'yhzgl',
    align: 'center',
    scopedSlots: { customRender: 'yhzgl' }
  },
]
const columns2=[
  {
    title: '项目名称',
    dataIndex: 'projectName',
    align: 'left',
    scopedSlots: { customRender: 'projectName' }
  },
  {
    title: '安全隐患检查次数',
    dataIndex: 'checkCnt',
    align: 'center',
    scopedSlots: { customRender: 'checkCnt' }
  },
  {
    title: '隐患总数',
    dataIndex: 'wtzs',
    align: 'center',
    scopedSlots: { customRender: 'wtzs' }
  },
  {
    title: '检查覆盖率',
    dataIndex: 'yhzgl',
    align: 'center',
    scopedSlots: { customRender: 'yhzgl' }
  },
]
const columns3=[
  {
    title: '工程管理部',
    dataIndex: 'officeName',
    align: 'center',
    scopedSlots: { customRender: 'officeName' }
  },
  {
    title: '停工通知书总数',
    dataIndex: 'stops',
    align: 'center',
    scopedSlots: { customRender: 'stops' }
  },
  {
    title: '处罚通知书总数',
    dataIndex: 'cfds',
    align: 'center',
    scopedSlots: { customRender: 'cfds' }
  },
  {
    title: '安全生产约谈总数',
    dataIndex: 'yts',
    align: 'center',
    scopedSlots: { customRender: 'yts' }
  },
]
import {sevenKqChart,safeChart,dangerTypeChart,ageTypeChart,workTypeChart} from './echarts'
import {
  get7DaysCWA, getJobTitleFb,
  getManagCWA,
  getOfficeListByParentOffice2,
  getProjectsByOffice,
  getQueryCurrentGk,
  getProjectByOffice,
  getProjectsByOfficeSafe,
  getTypeCount, getWorkAgeFb, getWorkTotal, projectStatissKqS
} from "../../services/statistics";
import {
  getAwardList, getAwardMonList
} from "@/services/labourProjectExcellent";
import {mapGetters} from 'vuex';
import { QUERY_PROJECT_GK,
  GCGLB_AQXJ_DETAILS,
  QUERY_AQXJ_YHZGLTOP10,
  CURRENT_CHECKTIMES_CHART,
  CURRENT_YHZSL_CHART,
  PROJECT_getStopCfdCnts,
  CURRENT_CSWZGS_CHART,
  OFFICE_TYPE_COUNT_QUERY,
  PROJECT_AQXJ_GCGLB_EXPORT,
  PROBLEMS_BY_TOTAL_QUERY,
  PROJECT_CHECK_TOP10,
  PROJECT_CHECK_NO8,
  CURRENT_YHZGL_CHART} from '@/services/api'
import {request} from '@/utils/request'
import moment from 'moment';
export default {
  name: "homePage",
  data() {
    return {
      location: {
        lng: 113.274,
        lat: 23.123
      },
      zoom: 9.5,
      addressKeyword: "",
      unitList:[], //单位列表
      currentGk: {}, // 当月安全管理统计汇总
      workData: {}, // 劳务数据汇总
      projectList: [],
      defaultProject: '全部',
      defaultUnit: '全部',
      persons:[
      ], // 地图上的覆盖物
      gcObj: {}, // 工程类型守护
      ManagCWA: {}, // 管理人员数据
      sevenKq: [],
      local1: require("./icon/local-1.png"),
      local2: require("./icon/local-2.png"),
      local3: require("./icon/local-3.png"),
      projectData: [], // table表格数据
      pagination: {
        y:'200px'
      },
      projectColumns: [
        {
          title: '项目',
          dataIndex: 'projectName',
          key: 'projectName',
          width: '35%',
          scopedSlots: { customRender: 'projectName' },
        },
        {
          title: '项目经理',
          dataIndex: 'xmjl',
          width: '25%',
          align: 'center',
          key: 'xmjl',
          scopedSlots: { customRender: 'xmjl' },
        },
        {
          title: '电话',
          dataIndex: 'xmjlPhone',
          width: '25%',
          align: 'center',
          key: 'xmjlPhone',
          scopedSlots: { customRender: 'xmjlPhone' },
        },

      ],
      awardColumns,
      awardDataSource: [{
          "cyLevelName": "市级",
          "cyLevel": "1",
          "totalNum": 0,
          "house": 0,
          "projectNum": 0,
          "government": 0
        },
        {
          "cyLevelName": "省级",
          "cyLevel": "2",
          "totalNum": 0,
          "house": 0,
          "projectNum": 0,
          "government": 0
        },
        {
          "cyLevelName": "国家级",
          "cyLevel": "3",
          "totalNum": 0,
          "house": 0,
          "projectNum": 0,
          "government": 0
        }],
      awardParams: [moment().format('YYYY'), moment().format('YYYY')],
      awardMonParams: [moment().format('YYYY-MM'), moment().add(1, "months").format('YYYY-MM')],
      awardYearOpen: false,
      awardMonOpen: false,
      columns,
      columns1,
      columns2,
      columns3,
      yhzs:0,
      gcglbYhzs:0,
      gkObj:{},
      gcglbObj:{},
      gcglbDatas:[],
      tgcfDatas:[],
      dataSourceTypeCount:[],
      dataSource:[],
      dataSourceYhs:[],
      dataSourceYhzgl:[],
      checkTimeType:1,
      checkTimeText:"当月",
      checkTimeTotal:0,
      yhzsType:1,
      yhzsText:"当月",
      yhzsTotal:0,
      yhzglType:2,
      yhzglText:"当年",
      yhzglTotal:0,
      cswzgType:1,
      cswzgText:"当月",
      cswzgsTotal:0,
      monthFormat: 'YYYY-MM',
      nowMonth:null,
      dateFormat: 'YYYY-MM-DD',
      nowYear:null,
      yearFormat:'YYYY',
      firstDay:null,
      nowDay:null,
      yearShowOne:false,
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: "#3ec13e",//滚动条颜色
          opacity: 0.8,//滚动条透明度
          "overflow-x": "hidden"
        }
      },
      queryParams:{
        type:2,
        plan_date:null,
        xjTimeStart:moment(new Date()).format('YYYY-MM')+"-01",
        xjTimeEnd:moment(new Date()).format('YYYY-MM-DD'),
        xjTimeStr:moment(new Date()).format('YYYY-MM'),
      },
      proQueryParams:{
        queryType:2,
        plan_date:null,
        yearShowOne:false,
        startDate:moment(new Date()).format('YYYY-MM')+"-01",
        endDate:moment(new Date()).format('YYYY-MM-DD'),
        month:moment(new Date()).format('YYYY-MM'),
      },
      xjTimesQueryParams:{
        queryType:1,
        plan_date:null,
        startDate:moment(new Date()).format('YYYY-MM')+"-01",
        endDate:moment(new Date()).format('YYYY-MM-DD'),
        month:moment(new Date()).format('YYYY'),
        yearShowOne:false,
      },
      zglParams:{
        yearShowOne:false,
        plan_date:null,
        month:moment(new Date()).format('YYYY'),
      },
      monthQueryParams:{
        isGcglb:0,
        queryType:2,
        plan_date:null,
        startDate:moment(new Date()).format('YYYY-MM')+"-01",
        endDate:moment(new Date()).format('YYYY-MM-DD'),
        month:moment(new Date()).format('YYYY-MM'),
        yearShowOne:false,
      },
      gcglbtype:false,
      gcglbQueryParams:{
        queryType:2,
        plan_date:null,
        startDate:moment(new Date()).format('YYYY-MM')+"-01",
        endDate:moment(new Date()).format('YYYY-MM-DD'),
        month:moment(new Date()).format('YYYY-MM'),
        yearShowOne:false,
      },
      tabListNoTitle: [
        {
          key: 'office',
          tab: '公司检查概况',
        },
        {
          key: 'gcglb',
          tab: '工程管理部检查概况',
        },
      ],
      awardTabList: [
        {
          key: 'hjqk',
          tab: '安全创优获奖情况',
        },
        {
          key: 'jpqk',
          tab: '安全创优检评情况',
        },
      ],
      awardTabKey: 'hjqk',
      tabNoTitle:[
        {
          key: 'office',
          tab: `工程管理部检查情况`,

        },
        {
          key: 'tgcfd',
          tab: '停工/处罚/约谈情况',
        }
      ],
      TitleKey:'office',
      key: 'office',
      noTitleKey: 'office',
      gcglbtitle:''
    }
  },
  computed: {
    ...mapGetters('account', ['user']),
    totalChecks() {
      let total = 0;
      if (this.gkObj.checks && this.gkObj.checks != null) {
        total = total + this.gkObj.checks;
      }
      if (this.gkObj.dbCnt && this.gkObj.dbCnt != null) {
        total = total + this.gkObj.dbCnt;
      }
      return total
    },
  },
    filters: {
      ellipsis(value) {
        if (!value) return ''
        if (value.length > 80) {
          return value.slice(0, 80) + '...'
        }
        return value
      }
    },
    created() {
      // 获取账号下的单位
      const id = this.user.officeId
      getOfficeListByParentOffice2({parentOfficeId: id}).then((res) => {
        if (res.data.code === 0) {
          this.unitList = res.data.data
          // 数据过滤成当前账号登录的信息
          let items = this.unitList.filter((item) => {
            return item.id === id
          })
          this.defaultUnit = items[0].name
          this.getProjectsByOffice(items[0].id)
          this.getProjectStatissKqS(items[0].id)
        } else {
          this.error = res.data.msg
          this.$message.error(res.data.msg, 3)
        }
      })
      this.nowMonth = moment(new Date()).format('YYYY-MM');
      this.firstDay = moment(new Date()).format('YYYY-MM') + "-01";
      this.nowDay = moment(new Date()).format('YYYY-MM-DD');
      this.nowYear = moment(new Date()).format('YYYY');
      let type = this.$store.getters["account/user"].isGcglb
      if (type === 1) {
        this.gcglbtype = true
        this.isleader=this.$store.getters["account/user"].nowRoleKey.includes('OFFCIE_DZBSJ')
        if(this.isleader){
          this.tabNoTitle=[{key: 'office', tab: `工程管理部检查情况`},{key: 'gcglb', tab: `项目部检查情况`},]
        }else{
          this.TitleKey='gcglb'
          this.tabNoTitle=[{key: 'gcglb', tab: `项目部检查情况`}]
        }
        this.tabListNoTitle = [{key: 'office', tab: '工程管理部检查概况'}];
        this.key = 'office';
        this.noTitleKey = 'office';
        this.gcglbtitle = '工程管理部'
      }
      this.queryXjGk();
      this.doProCheckQuery();
      // this.queryYhzsTop10();
      // this.queryYhzglTop10();
      this.gcglbAqxjQuery();
      this.$nextTick(function () {
        this.checkTimeChange();
        // this.yhzsChange();
        // this.cswzgChange(1);
        this.yhzglChange();
        this.doQuery();
      })
      this.getAwardList()
    },
    mounted() {
    },
    methods: {
      /**
       * 获取安全创优获奖情况
       * */
      getAwardList() {
        let params = new FormData();
        params.append("startYear", this.awardParams[0]);
        params.append("endYear", this.awardParams[1]);
        getAwardList(params).then(res => {
          if (res.data.code === 0) {
            res.data.data.forEach(item => {
              this.awardDataSource.forEach(el => {
                if (item.cyLevel == el.cyLevel) {
                  el.totalNum = item.totalNum
                  el.house = item.house
                  el.government = item.government
                  el.projectNum = item.projectNum
                }
              })
            })
          }
        })
      },
      awardYearChange(dates, dateStrings) {
        this.awardParams = dateStrings
        this.getAwardList()
      },
      awardOpenChange(status) { 
        this.awardYearOpen = status
      },
      awardPanelChange(value) {
        if (value && value.length > 0) {
          this.awardParams = value.map(el => {
            return moment(el).format('YYYY')
          })
          this.awardYearOpen = false
          this.getAwardList()
        }
      },
      awardMonChange(dates, dateStrings) {
        console.log(dates, dateStrings)
      },
      awardMonOpenChange(status) {
        this.awardMonOpen = status
      },
      awardMonPanelChange(value) {
        if (value && value.length > 0) {
          this.awardMonParams = value.map(el => {
            return moment(el).format('YYYY-MM')
          })
          this.awardMonOpen = false
          this.getAwardMonList()
        }
      },
      onAwardTabChange(key) {
        this.awardTabKey = key
        if (key === 'hjqk') {
          this.getAwardList()
          this.awardColumns.splice(1, 0, {
            title: '获奖项目 (个)',
            dataIndex: 'projectNum',
            align: 'center',
            scopedSlots: { customRender: 'projectNum' },
          })
          this.awardColumns[0].title = '安全创优奖项'
          this.awardColumns[2].title = '奖项总数(项)'
          this.awardColumns[3].title = '总数（项）'
          this.awardColumns[4].title = '房建（项）'
        } else if (key === 'jpqk') {
          this.getAwardMonList()
          this.awardColumns.splice(1, 1)
          this.awardColumns[0].title = '安全创优检评'
          this.awardColumns[1].title = '总数（次）'
          this.awardColumns[2].title = '房建（次）'
          this.awardColumns[3].title = '市政（次）'
        }
      },
      getAwardMonList() {
        let params = new FormData();
        params.append("startDate", this.awardMonParams[0]);
        params.append("endDate", this.awardMonParams[1]);
        getAwardMonList(params).then(res => {
          if (res.data.code === 0) {
            res.data.data.forEach(item => {
              this.awardDataSource.forEach(el => {
                if (item.cyLevel == el.cyLevel) {
                  el.totalNum = item.totalNum
                  el.house = item.house
                  el.government = item.government
                }
              })
            })
          }
        })
      },
      /**
       * 获取所有项目，以及在建，停工，完工的数量
       * */
      getProjectsByOffice(id) {
        // 获取所有项目，以及在建，停工，完工的数量
        getProjectsByOfficeSafe({parentOfficeId: id}).then((res) => {
          if (res.data.code === 0) {
            let obj = res.data.data
            let arr = []
            this.projectList = obj.projects
            // const id = obj.projects[0].projId
            this.gcObj = {tinggong: obj.tinggong, wangong: obj.wangong, zaijian: obj.zaijian}
            obj.projects.forEach((item) => {
              // 组成百度地图适配的数据格式
              let icon = item.status == '0' ? this.local1 : (item.status == '1' ? this.local2 : this.local3)
              // if(item.status == '0'){
                  
              // }
              arr.push({
                  name: item.fullName,
                  id: item.projId,
                  lat: item.latitude,
                  lng: item.longitude,
                  icon: icon,
                  show: false
              })
            })
            this.persons = arr
          } else {
            this.error = res.data.msg
            this.$message.error(res.data.msg, 3)
          }
        })
      },
      infoWindowClose(index) {
        this.persons[index].show = false
      },
      infoWindowOpen(index) {
        this.persons[index].show = true
      },
      // 监听选择单位
      onHandleUnitChange(e) {
        // e 单位id
        this.getProjectsByOffice(e)
        this.getProjectStatissKqS(e)
      },
      getProjectStatissKqS(id) {
        // projectStatissKqS({parentOfficeId: id}).then((res) => {
        //   if (res.data.code === 0) {
        //     this.defaultProject = res.data.data[0].projectName
        //     this.projectData = res.data.data
        //   } else {
        //     this.error = res.data.msg
        //     this.$message.error(res.data.msg, 3)
        //   }

        // })
        getProjectByOffice({parentOfficeId:id}).then((res) => {
          if (res.data.code === 0) {
            this.defaultProject = res.data.data[0].projectName
            this.projectData = res.data.data
          } else {
            this.error = res.data.msg
            this.$message.error(res.data.msg, 3)
          }
        });
      },
      onTabChange(key, type) {
        this[type] = key;
        if (key == 'gcglb') {
          this.monthQueryParams.isGcglb = 1;
        } else {
          this.monthQueryParams.isGcglb = 0;
        }
        this.queryXjGk();
      },
      TabChange(key,type){
        this[type] = key;
      },
      /**
       * 获取项目自查排名前十
       */
      doProCheckQuery() {
        if (this.proQueryParams.queryType == 1 && this.proQueryParams.plan_date != null) {
          this.proQueryParams.month = this.proQueryParams.plan_date.format('YYYY')
        }
        if (this.proQueryParams.queryType == 2 && this.proQueryParams.plan_date != null) {
          this.proQueryParams.month = this.proQueryParams.plan_date.format('YYYY-MM')
        }
        if (this.proQueryParams.queryType == 3 && this.proQueryParams.plan_date && this.proQueryParams.plan_date.length > 0) {
          this.proQueryParams.startDate = this.proQueryParams.plan_date[0].format('YYYY-MM-DD')
          this.proQueryParams.endDate = this.proQueryParams.plan_date[1].format('YYYY-MM-DD')
        }
        request(PROJECT_CHECK_NO8, "post", this.proQueryParams).then(res => {
          if (res.data.code === 0) {
            this.dataSource = res.data.data;
          }
        })
      },

      gcglbAqxjQuery() {
        if (this.gcglbQueryParams.queryType == 1 && this.gcglbQueryParams.plan_date != null) {
          this.gcglbQueryParams.month = this.gcglbQueryParams.plan_date.format('YYYY')
        }
        if (this.gcglbQueryParams.queryType == 2 && this.gcglbQueryParams.plan_date != null) {
          this.gcglbQueryParams.month = this.gcglbQueryParams.plan_date.format('YYYY-MM')
        }
        if (this.gcglbQueryParams.queryType == 3 && this.gcglbQueryParams.plan_date && this.gcglbQueryParams.plan_date.length > 0) {
          this.gcglbQueryParams.startDate = this.gcglbQueryParams.plan_date[0].format('YYYY-MM-DD')
          this.gcglbQueryParams.endDate = this.gcglbQueryParams.plan_date[1].format('YYYY-MM-DD')
        }
        if (this.gcglbtype) {
          request(PROJECT_AQXJ_GCGLB_EXPORT, "post", this.gcglbQueryParams).then(res => {
            if (res.data.code === 0) {
              res.data.data.forEach(e => {
                if (this.gcglbQueryParams.queryType == 1) {
                  e.yhzgl = Number(e.checkCnt / 48 * 100).toFixed(2) >= 100 ? '100%' : Number(e.checkCnt / 48 * 100).toFixed(2) + '%'
                } else if (this.gcglbQueryParams.queryType == 2) {
                  e.yhzgl = Number(e.checkCnt / 4 * 100).toFixed(2) >= 100 ? '100%' : Number(e.checkCnt / 4 * 100).toFixed(2) + '%'
                } else {
                  e.yhzgl = ' '
                }
              })
              this.gcglbDatas2 = res.data.data;
            }
          })
        } else {
          request(PROJECT_getStopCfdCnts,"post",this.gcglbQueryParams).then(res => {
            if(res.data.code==0){
              this.tgcfDatas = res.data.data;
            }
          })
        }
        if(this.isleader||!this.gcglbtype){
          request(GCGLB_AQXJ_DETAILS, "post", this.gcglbQueryParams).then(res => {
            if (res.data.code === 0) {
              this.gcglbDatas = res.data.data;
            }
          })
        }

      },


      /**
       * 获取时间段的台账信息
       */
      doQuery() {
        this.tableLoading = true;
        if (this.queryParams.type == 1 && this.queryParams.plan_date != null) {
          this.queryParams.xjTimeStr = this.queryParams.plan_date.format('YYYY')
        }
        if (this.queryParams.type == 2 && this.queryParams.plan_date != null) {
          this.queryParams.xjTimeStr = this.queryParams.plan_date.format('YYYY-MM')
        }
        if (this.queryParams.type == 3 && this.queryParams.plan_date && this.queryParams.plan_date.length > 0) {
          this.queryParams.xjTimeStart = this.queryParams.plan_date[0].format('YYYY-MM-DD')
          this.queryParams.xjTimeEnd = this.queryParams.plan_date[1].format('YYYY-MM-DD')
        }
        request(PROBLEMS_BY_TOTAL_QUERY, "post", this.queryParams).then(res => {
          let obj = [];
          let pieData = [];
          this.columns.forEach(c => {
            if (c.dataIndex != 'total') {
              obj.push(c.title);
            }
          })
          let obj2 = [];
          if (res.data.code === 0) {
            this.dataSourceTypeCount = res.data.data;
            this.dataSourceTypeCount.forEach(obj => {
              this.yhzsTotal = obj.total;
              obj.key = obj.id;
              this.columns.forEach(c => {
                if (c.dataIndex != 'total') {
                  obj2.push(obj[c.dataIndex]);
                  let pie = {
                    name: c.title,
                    value: obj[c.dataIndex],
                  }
                  pieData.push(pie);
                }
              })

            })
          }
          // this.initYhzsChart(obj,obj2);
          this.initYhzsPieChart(pieData);
        })
      },


      /**
       * //查询概况中的隐患总数
       */
      doGkYhzsQuery() {
        let obj = {
          type: this.monthQueryParams.queryType,
          xjTimeStr: this.monthQueryParams.month,
          xjTimeStart: this.monthQueryParams.startDate,
          xjTimeEnd: this.monthQueryParams.endDate
        }
        request(PROBLEMS_BY_TOTAL_QUERY, "post", obj).then(res => {
          if (res.data.code === 0) {
            res.data.data.forEach(obj => {
              this.yhzs = obj.total;
            })
          }
        })
      },
      getGcglbYhzs() {
        let obj = {
          queryType: this.monthQueryParams.queryType,
          type: this.monthQueryParams.queryType,
          month: this.monthQueryParams.month,
          startDate: this.monthQueryParams.startDate,
          endDate: this.monthQueryParams.endDate
        }
        request(GCGLB_AQXJ_DETAILS, "post", obj).then(res => {
          let total = 0;
          if (res.data.code === 0) {
            res.data.data.forEach(o => {
              total += o.yhzs
            });
          }
          this.gcglbYhzs = total;
        })
      },

      moment,
      initCheckTimesChart(data1, data2) {
        // 基于准备好的dom，初始化echarts实例
        let myChart = this.$echarts.init(document.getElementById("checkTimesChart"));
        // 指定图表的配置项和数据
        let option = {
          color: ['#9BCA63'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: data1,
              axisTick: {
                alignWithLabel: true
              },
              axisLabel: {interval: 0, rotate: 60, formatter: '{value}'}
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: '检查次数',
              type: 'bar',
              barWidth: '60%',
              data: data2,
              label: {
                show: true,
                position: 'outside'
              },
            }
          ]
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
      },
      initYhzsChart(data1, data2) {
        // 基于准备好的dom，初始化echarts实例
        let myChart = this.$echarts.init(document.getElementById("yhzsChart"));
        // 指定图表的配置项和数据
        let option = {
          color: ['#6087c7'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: data1,
              axisTick: {
                alignWithLabel: true
              },
              axisLabel: {interval: 0, rotate: 60, formatter: '{value}'}
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: '隐患数',
              type: 'bar',
              barWidth: '60%',
              data: data2,
              label: {
                show: true,
                position: 'outside'
              },
            }
          ]
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
      },
      initCswzgsChart(data1, data2, type) {
        let text = "";
        if (type == 2) {
          text = "月";
        }
        // 基于准备好的dom，初始化echarts实例
        let myChart = this.$echarts.init(document.getElementById("cswzgsChart"));
        // 指定图表的配置项和数据
        let option = {
          color: ['#8c493c'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: data1,
              axisTick: {
                alignWithLabel: true
              },
              axisLabel: {interval: 0, rotate: 60, formatter: '{value}' + text}
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: '超时未整改数',
              type: 'bar',
              barWidth: '60%',
              data: data2,
              label: {
                show: true,
                position: 'outside'
              },
            }
          ]
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
      },
      initYhzglChart(data1, data2, type) {
        let text = "";
        if (type == 2) {
          text = "月";
        }
        // 基于准备好的dom，初始化echarts实例
        let myChart = this.$echarts.init(document.getElementById("yhzglChart"));
        // 指定图表的配置项和数据
        let option = {
          color: ['#bcd749'],
          tooltip: {
            trigger: 'axis',
            formatter: "{a} <br/>{b}: {c}%",
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: data1,
              axisTick: {
                alignWithLabel: true
              },
              axisLabel: {interval: 0, rotate: 60, formatter: '{value}' + text}
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: '整改率',
              type: 'bar',
              barWidth: '60%',
              data: data2,
              label: {
                show: true,
                position: 'outside',
                formatter: '{c}%'
              },
            }
          ]
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
      },
      initYhzsPieChart(data) {
        // 基于准备好的dom，初始化echarts实例
        let myChart = this.$echarts.init(document.getElementById("yhzsPieChart"));
        // 指定图表的配置项和数据
        let option = {
          title: {
            text: '',
            subtext: '',
            left: 'center'
          },
          tooltip: {
            trigger: 'item'
          },
          legend: {
            show: false,
            orient: 'vertical',
            left: 'left'
          },
          color: ['#fc8251', '#5470c6', '#91cd77', '#75bedc', '#f9c956', '#ef6567', '#45C2E0', '#C1EBDD', '#FFC851'],
          series: [
            {
              name: 'Access From',
              type: 'pie',
              radius: '55%',
              label: {
                normal: {
                  show: true,
                  // formatter: '{b} : {c} ({d}%)' //带当前图例名 + 百分比
                  formatter: '{b} ({d}%)' //带当前图例名 + 百分比
                }
              },
              labelLine: {show: true},
              data: data,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                },
              }
            }
          ]
        }
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
      },
      checkTimeChange() {
        if (this.xjTimesQueryParams.queryType == 1 && this.xjTimesQueryParams.plan_date != null) {
          this.xjTimesQueryParams.month = this.xjTimesQueryParams.plan_date.format('YYYY')
        }
        if (this.xjTimesQueryParams.queryType == 2 && this.xjTimesQueryParams.plan_date != null) {
          this.xjTimesQueryParams.month = this.xjTimesQueryParams.plan_date.format('YYYY-MM')
        }
        if (this.xjTimesQueryParams.queryType == 3 && this.xjTimesQueryParams.plan_date && this.xjTimesQueryParams.plan_date.length > 0) {
          this.xjTimesQueryParams.startDate = this.xjTimesQueryParams.plan_date[0].format('YYYY-MM-DD')
          this.xjTimesQueryParams.endDate = this.xjTimesQueryParams.plan_date[1].format('YYYY-MM-DD')
        }
        this.checkTimeTotal = 0;
        request(CURRENT_CHECKTIMES_CHART, "post", this.xjTimesQueryParams).then(res => {
          if (res.data.code === 0) {
            let obj = [];
            let obj2 = [];
            res.data.data.forEach(o => {
              obj.push(o.timeDate);
              obj2.push(o.cnt)
              this.checkTimeTotal = this.checkTimeTotal + Number(o.cnt)
            })
            this.initCheckTimesChart(obj, obj2);
          }
        })
      },

      yhzglChange() {
        if (this.zglParams.plan_date != null) {
          this.zglParams.month = this.zglParams.plan_date.format('YYYY')
        }
        request(CURRENT_YHZGL_CHART, "post", this.zglParams).then(res => {
          if (res.data.code === 0) {
            this.yhzglTotal = res.data.msg + "%";
            let obj = [];
            let obj2 = [];
            res.data.data.forEach(o => {
              obj.push(o.timeDate);
              obj2.push(o.cnt);
            })
            this.initYhzglChart(obj, obj2);
          } else {
            this.yhzglTotal = "0%";
          }
        })
      },
      queryXjGk() {
        if (this.monthQueryParams.queryType == 1 && this.monthQueryParams.plan_date != null) {
          this.monthQueryParams.month = this.monthQueryParams.plan_date.format('YYYY')
        }
        if (this.monthQueryParams.queryType == 2 && this.monthQueryParams.plan_date != null) {
          this.monthQueryParams.month = this.monthQueryParams.plan_date.format('YYYY-MM')
        }
        if (this.monthQueryParams.queryType == 3 && this.monthQueryParams.plan_date && this.monthQueryParams.plan_date.length > 0) {
          this.monthQueryParams.startDate = this.monthQueryParams.plan_date[0].format('YYYY-MM-DD')
          this.monthQueryParams.endDate = this.monthQueryParams.plan_date[1].format('YYYY-MM-DD')
        }
        request(QUERY_PROJECT_GK, "post", this.monthQueryParams).then(res => {
          if (res.data.code === 0) {
            if (this.monthQueryParams.isGcglb == 0) {
              this.gkObj = res.data.data;
            } else {
              this.gcglbObj = res.data.data;
            }

          }
        })
        this.doGkYhzsQuery();
        this.getGcglbYhzs();

      },
      openChangeOne(status) {
        if (status) {
          this.yearShowOne = true;
        }
      },
      proOpenChangeOne(status) {
        if (status) {
          this.proQueryParams.yearShowOne = true;
        }
      },
      zglOpenChangeOne(status) {
        if (status) {
          this.zglParams.yearShowOne = true;
        }
      },
      xjTimesOpenChangeOne(status) {
        if (status) {
          this.xjTimesQueryParams.yearShowOne = true;
        }
      },
      monthOpenChangeOne(status) {
        if (status) {
          this.monthQueryParams.yearShowOne = true;
        }
      },
      gcglbOpenChangeOne(status) {
        if (status) {
          this.gcglbQueryParams.yearShowOne = true;
        }
      },
      gcglbPanelChangeOne(value) {
        this.gcglbQueryParams.yearShowOne = false;
        this.gcglbQueryParams.plan_date = value;
        this.$nextTick(function () {
          this.gcglbAqxjQuery();
        })
      },
      monthPanelChangeOne(value) {
        this.monthQueryParams.yearShowOne = false;
        this.monthQueryParams.plan_date = value;
        this.$nextTick(function () {
          this.queryXjGk();
        })
      },
      // 得到年份选择器的值
      proPanelChangeOne(value) {
        this.proQueryParams.yearShowOne = false;
        this.proQueryParams.plan_date = value;
        this.$nextTick(function () {
          this.doProCheckQuery();
        })
      },
      zglPanelChangeOne(value) {
        this.zglParams.yearShowOne = false;
        this.zglParams.plan_date = value;
        this.$nextTick(function () {
          this.yhzglChange();
        })
      },
      // 得到年份选择器的值
      xjTimesPanelChangeOne(value) {
        this.xjTimesQueryParams.yearShowOne = false;
        this.xjTimesQueryParams.plan_date = value;
        this.$nextTick(function () {
          this.checkTimeChange();
        })
      },
      // 得到年份选择器的值
      panelChangeOne(value) {
        this.yearShowOne = false;
        this.queryParams.plan_date = value;
        this.$nextTick(function () {
          this.doQuery();
        })
      },
      proChangeDate(value) {
        this.proQueryParams.plan_date = value;
        this.$nextTick(function () {
          this.doProCheckQuery();
        })

      },
      changeDate(value) {
        this.queryParams.plan_date = value;
        this.$nextTick(function () {
          this.doQuery();
        })
      },
      xjTimesChangeDate(value) {
        this.xjTimesQueryParams.plan_date = value;
        this.$nextTick(function () {
          this.checkTimeChange();
        })
      },
      yhzsTypeChange() {
        if (this.queryParams.type == 1 || this.queryParams.type == 2) {
          this.queryParams.plan_date = moment(new Date());
        } else {
          this.queryParams.plan_date = null;
        }
        this.$nextTick(function () {
          this.doQuery();
        })
      },
      proTop10Change() {
        if (this.proQueryParams.queryType == 1 || this.proQueryParams.queryType == 2) {
          this.proQueryParams.plan_date = moment(new Date());
        } else {
          this.proQueryParams.plan_date = null;
        }
        this.doProCheckQuery();
      },
      checkTimeTypeChange() {
        if (this.xjTimesQueryParams.queryType == 1 || this.xjTimesQueryParams.queryType == 2) {
          this.xjTimesQueryParams.plan_date = moment(new Date());
        } else {
          this.xjTimesQueryParams.plan_date = null;
        }
        if (this.xjTimesQueryParams.queryType == 1) {
          this.xjTimesQueryParams.month = moment(new Date()).format('YYYY')
        }
        if (this.xjTimesQueryParams.queryType == 2) {
          this.xjTimesQueryParams.month = moment(new Date()).format('YYYY-MM')
        }
        this.checkTimeChange();
      },
      gcglbTimeChange() {
        if (this.gcglbQueryParams.queryType == 1 || this.gcglbQueryParams.queryType == 2) {
          this.gcglbQueryParams.plan_date = moment(new Date());
        } else {
          this.gcglbQueryParams.plan_date = null;
        }
        if (this.gcglbQueryParams.queryType == 1) {
          this.gcglbQueryParams.month = moment(new Date()).format('YYYY')
        }
        if (this.gcglbQueryParams.queryType == 2) {
          this.gcglbQueryParams.month = moment(new Date()).format('YYYY-MM')
        }
        this.gcglbAqxjQuery();
      },
      mtChangeTypeDate() {
        if (this.monthQueryParams.queryType == 1 || this.monthQueryParams.queryType == 2) {
          this.monthQueryParams.plan_date = moment(new Date());
        } else {
          this.monthQueryParams.plan_date = null;
        }
        if (this.monthQueryParams.queryType == 1) {
          this.monthQueryParams.month = moment(new Date()).format('YYYY')
        }
        if (this.monthQueryParams.queryType == 2) {
          this.monthQueryParams.month = moment(new Date()).format('YYYY-MM')
        }
        this.queryXjGk();
      },
      mtChangeDate(value) {
        this.monthQueryParams.plan_date = value;
        this.queryXjGk();
      },
      gcglbChangeDate(value) {
        this.gcglbQueryParams.plan_date = value;
        this.gcglbAqxjQuery();
      }
    }
}
</script>

<style>
.anchorBL{
  display: none;
}
</style>
<style scoped>
.px-1{
  padding: 0 10px;
}
.p-1{
  padding: 10px;
}
.mt-2{
  margin-top: 20px;
}
.mb-1{
  margin-bottom: 10px;
}
.data-wrap{
}
.text-center{
  text-align: center;
}
.text-right{
  text-align: right;
}
.flex{
  display: flex;
}
.flex-1{
  flex: 1;
}
.a-center{
  align-items: center;
}
.j-center{
  justify-content: center;
}
.orange{
  background: rgb(242,168,45);
}
.blue{
  background: rgb(39,130,203);
}
.laowu-data-box{
  height: 150px;
  font-size: 14px;
  box-sizing: border-box;
  border-radius: 5px;
  color: #FFFFFF;
}
.laowu-data-box h5{
  color: #ffffff;
  border-bottom: 1px solid #fff;
}
.relative{
  position: relative;
}
.unit-box{
  position: absolute;
  top: 10px;
  left: 10px;
}
.gc-box{
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ffffff;
  padding: 0 5px;
}
.gc-box-item{
  min-width: 120px;
  color: deepskyblue;
  padding: 5px 0px;
}
.gc-box-img{
  width: 18px;
  height: 18px;
}
.border-right{
  border-right: 1px solid #E4E4E4;
}
</style>

<style lang="less" scoped>
.search{
  margin-bottom: 54px;
}
.fold{
  width: calc(100% - 216px);
  display: inline-block
}
.operator{
  margin-bottom: 18px;
}
@media screen and (max-width: 900px) {
  .fold {
    width: 100%;
  }
}
// 滚动条位置
/deep/.__bar-is-vertical {
  right: -1px !important;
}
// 隐藏横向滚动条
/deep/.__bar-is-horizontal {
  display: none !important;
}
/deep/ th.aqglclass{
  background-color: #fc8251 !important;
}
/deep/ th.wmsgclass{
  background-color: #5470c6 !important;
}
/deep/ th.jsjclass{
  background-color: #91cd77 !important;
}
/deep/ th.jkymbclass{
  background-color:  #75bedc !important;
}
/deep/ th.gczyclass{
  background-color: #f9c956 !important;
}
/deep/ th.sgydclass{
  background-color: #ef6567 !important;
}
/deep/ th.dxsbclass{
  background-color: #45C2E0 !important;
}
/deep/ th.qzdzclass{
  background-color: #C1EBDD !important;
}
/deep/ th.sgjjclass{
  background-color: #FFC851 !important;
}

</style>
