<template>
  <div>
    <a-drawer
      :width="1240"
      :visible="visible"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose"
    > 
    <template slot="title">
      <strong>派工单人员名册</strong>
    </template>
    <standard-table
            :columns="columns"
            :dataSource="dataSource"
            :pagination="pagination"
            :loading="tableLoading"
            rowKey="idCard"
            @change="onPageChange"
    >

    </standard-table>
    <Export-loading :cPercent="cPercent" :isExporting="isExporting" :loadingText="loadingText"></Export-loading>
    <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button  :style="{ marginRight: '28px' }" @click="doDowload"  type="primary">
          导出名册
        </a-button>
        <a-button :style="{ marginRight: '28px' }" @click="onClose">
          关闭
        </a-button>
      </div>
  </a-drawer>
  </div>
</template>

<script>
import {
  OUTWORK_ORDER_PERSONS_EXPORT,
  OUTWORK_ORDER_PERSONS_QUERY
  } from '@/services/api'
import StandardTable from '@/components/table/StandardTable'
import ExportLoading from '@/components/ExportLoading/ExportLoading' // progress组件
import { exportProgress } from '@/pages/aqjyzl/export' // 导出方法
import {request} from '@/utils/request'

const columns = [
  {
    title: '工人姓名',
    dataIndex: 'userName',
    width:120,
    align: 'center',
    scopedSlots: { customRender: 'userName' }
  },
  // {
  //   title: '身份证号',
  //   dataIndex: 'idCard',
  //   align: 'center',
  //   scopedSlots: { customRender: 'idCard' },
  // },
  {
    title: '联系电话',
    dataIndex: 'phone',
    align: 'center',
    width:140,
    ellipsis: true,
   scopedSlots: { customRender: 'phone' },
  },
  {
    title: '派去班组',
    dataIndex: 'teamName',
    align: 'center',
    width:120,
    scopedSlots: {customRender: 'teamName'}
  },
  {
    title: '工种名称',
    dataIndex: 'jobTitle',
    align: 'center',
    ellipsis: true,
    width:120,
   scopedSlots: { customRender: 'jobTitle' },
  },
  {
    title: '派去项目',
    dataIndex: 'projectName',
    align: 'center',
    width:200,
    scopedSlots: {customRender: 'projectName'}
  },
  {
    title: '班组负责人',
    dataIndex: 'teamMaster',
    align: 'center',
    width:120,
    scopedSlots: {customRender: 'teamMaster'}
  },
  {
    title: '负责人电话',
    dataIndex: 'teamPhone',
    align: 'center',
    width:140,
    scopedSlots: {customRender: 'teamPhone'}
  }
]

export default {
  mixins: [exportProgress],
  name: 'Querydetais',
  components: {StandardTable,ExportLoading},
  data () {
    return {
      columns,
      dataSource:[],
      tableLoading:false,
      loadingText:"下载中...",
      queryParams:{
        orderId:null,
      },
      pagination:{
        current:1,
        pageSize:10,
        total:0,
        showLessItems:true,
        showQuickJumper:true
      },
      cPercent:0,
      isExporting:false,
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    orderNo:{
      type: String,
      require:true
    },
    orderId:{
      type: String,
      require:true
    }
  },
  watch: {
    cPercent:{
      handler(newName, oldName) {
        this.cPercent=newName;
      },
      immediate: true,
    },
    orderId:{
      handler(newName, oldName) {
        this.queryParams.orderId = newName;
        this.doQuery();
      },
      immediate: true,
    }
  },
  mounted(){
  },
  created() {
    
  },
  methods:{
    /**
     * 获取所有人员的签名记录信息
     */
    doQuery(){
      this.tableLoading = true;
      request(OUTWORK_ORDER_PERSONS_QUERY,"post",this.queryParams).then(res => {
        if(res.data.code==0){
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
        }
        this.tableLoading = false;
      })
    },
    doReset(){
      this.queryParams={};
      this.queryParams.orderId = this.orderId;
      this.doQuery();
    },
    //分页查询
    onPageChange(e){
      this.queryParams.pageNumber = e.current;
      this.pagination.current=e.current;
      this.doQuery();
    },
    /**
     * 导出
     */
    doDowload(){
      let selectedRows = [];
      this.loadingText = "正在打包下载文件，请稍候 ····";
      this.loadKeys = [];
      this.loadKeys.push(this.orderId);
      this.isExporting=true;
      this.cPercent=0;
      let fileName=this.orderNo+"派工单名册下载.zip";
      this.exportFunNomal(OUTWORK_ORDER_PERSONS_EXPORT,this.loadKeys,fileName,selectedRows)
    },
    onClose(){
      this.$emit('doColseDetails');
    },
  }
}
</script>