var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    {
      staticClass: "risk_container",
      attrs: { title: "风控管理", bordered: false },
    },
    [
      _c(
        "a-descriptions",
        { staticClass: "e-descriptions", attrs: { bordered: "", column: 1 } },
        [
          _c(
            "a-descriptions-item",
            {
              scopedSlots: _vm._u([
                {
                  key: "label",
                  fn: function () {
                    return [_c("div", [_vm._v("年龄限制规则：")])]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("div", { staticClass: "risk-flex" }, [
                _c("div", { staticClass: "risk-rules" }, [
                  _c(
                    "div",
                    [
                      _c("a-checkbox", [
                        _c("span", { staticClass: "text-color" }, [
                          _vm._v("童工年龄限制，童工指未满 "),
                        ]),
                      ]),
                      _c(
                        "a-input",
                        {
                          staticStyle: { width: "30%" },
                          attrs: { "default-value": "18" },
                        },
                        [
                          _c(
                            "a-tooltip",
                            { attrs: { slot: "suffix" }, slot: "suffix" },
                            [
                              _c("span", { staticClass: "text-color" }, [
                                _vm._v("周岁"),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("a-checkbox", [
                        _c("span", { staticClass: "text-color" }, [
                          _vm._v("男性退休年龄限制，退休年龄为 "),
                        ]),
                      ]),
                      _c(
                        "a-input",
                        {
                          staticStyle: { width: "30%" },
                          attrs: { "default-value": "65" },
                        },
                        [
                          _c(
                            "a-tooltip",
                            { attrs: { slot: "suffix" }, slot: "suffix" },
                            [
                              _c("span", { staticClass: "text-color" }, [
                                _vm._v("周岁"),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("a-checkbox", [
                        _c("span", { staticClass: "text-color" }, [
                          _vm._v("女性退休年龄限制，退休年龄为 "),
                        ]),
                      ]),
                      _c(
                        "a-input",
                        {
                          staticStyle: { width: "30%" },
                          attrs: { "default-value": "60" },
                        },
                        [
                          _c(
                            "a-tooltip",
                            { attrs: { slot: "suffix" }, slot: "suffix" },
                            [
                              _c("span", { staticClass: "text-color" }, [
                                _vm._v("周岁"),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "ant-descriptions-item-label risk-switch" },
                  [
                    _c(
                      "div",
                      [
                        _c("a-switch", {
                          attrs: {
                            "checked-children": "开启",
                            "un-checked-children": "关闭",
                            "default-checked": "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]
          ),
          _c(
            "a-descriptions-item",
            {
              scopedSlots: _vm._u([
                {
                  key: "label",
                  fn: function () {
                    return [_c("div", [_vm._v("民族限制规则：")])]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("div", { staticClass: "risk-flex" }, [
                _c("div", { staticClass: "risk-rules" }, [
                  _c(
                    "div",
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { placeholder: "请选择民族", allowClear: "" },
                          on: {
                            change: function ($event) {
                              return _vm.handlSelect($event)
                            },
                          },
                        },
                        [
                          _c("a-select-option", { attrs: { value: "" } }, [
                            _vm._v("请选择民族"),
                          ]),
                          _vm._l(_vm.nation, function (items) {
                            return _c(
                              "a-select-option",
                              { key: items, attrs: { value: items } },
                              [_vm._v(" " + _vm._s(items) + " ")]
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "ant-descriptions-item-label risk-switch" },
                  [
                    _c(
                      "div",
                      [
                        _c("a-switch", {
                          attrs: {
                            "checked-children": "开启",
                            "un-checked-children": "关闭",
                            "default-checked": "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]
          ),
          _c(
            "a-descriptions-item",
            {
              scopedSlots: _vm._u([
                {
                  key: "label",
                  fn: function () {
                    return [_c("div", [_vm._v("地区限制规则：")])]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("div", { staticClass: "risk-flex" }, [
                _c("div", { staticClass: "risk-rules" }, [
                  _c(
                    "div",
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { placeholder: "请选择省份", allowClear: "" },
                          on: {
                            change: function ($event) {
                              return _vm.handlSelect($event)
                            },
                          },
                        },
                        [
                          _c("a-select-option", { attrs: { value: "" } }, [
                            _vm._v("请选择省份"),
                          ]),
                          _vm._l(_vm.selectlist, function (items) {
                            return _c(
                              "a-select-option",
                              {
                                key: items.name,
                                attrs: { value: items.value },
                              },
                              [_vm._v(" " + _vm._s(items.name) + " ")]
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "ant-descriptions-item-label risk-switch" },
                  [
                    _c(
                      "div",
                      [
                        _c("a-switch", {
                          attrs: {
                            "checked-children": "开启",
                            "un-checked-children": "关闭",
                            "default-checked": "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]
          ),
          _c(
            "a-descriptions-item",
            {
              scopedSlots: _vm._u([
                {
                  key: "label",
                  fn: function () {
                    return [_c("div", [_vm._v("长期未考勤提醒规则：")])]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("div", { staticClass: "risk-flex" }, [
                _c("div", { staticClass: "risk-rules" }, [
                  _c(
                    "div",
                    [
                      _c("a-checkbox", [
                        _c("span", { staticClass: "text-color" }, [
                          _vm._v("超过 "),
                        ]),
                      ]),
                      _c("a-input", {
                        staticStyle: { width: "100px" },
                        attrs: { "default-value": "" },
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "text-color",
                          staticStyle: { "margin-left": "5px" },
                        },
                        [_vm._v("天未考勤，系统进行提示")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "ant-descriptions-item-label risk-switch" },
                  [
                    _c(
                      "div",
                      [
                        _c("a-switch", {
                          attrs: {
                            "checked-children": "开启",
                            "un-checked-children": "关闭",
                            "default-checked": "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]
          ),
          _c(
            "a-descriptions-item",
            {
              scopedSlots: _vm._u([
                {
                  key: "label",
                  fn: function () {
                    return [_c("div", [_vm._v("教育提醒规则：")])]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("div", { staticClass: "risk-flex" }, [
                _c("div", { staticClass: "risk-rules" }, [
                  _c(
                    "div",
                    [
                      _c("a-checkbox", [
                        _c("span", { staticClass: "text-color" }, [
                          _vm._v("超过 "),
                        ]),
                      ]),
                      _c("a-input", {
                        staticStyle: { width: "100px" },
                        attrs: { "default-value": "" },
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "text-color",
                          staticStyle: { "margin-left": "5px" },
                        },
                        [_vm._v("天未接受三级安全教育培训，系统进行提示")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "ant-descriptions-item-label risk-switch" },
                  [
                    _c(
                      "div",
                      [
                        _c("a-switch", {
                          attrs: {
                            "checked-children": "开启",
                            "un-checked-children": "关闭",
                            "default-checked": "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }