var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-card",
        { attrs: { title: "项目危险源情况" } },
        [
          _c(
            "a-form-model",
            { attrs: { layout: "horizontal", model: _vm.params } },
            [
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "状态：",
                            labelCol: { span: 3 },
                            wrapperCol: { span: 15 },
                          },
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.statusName))])]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "项目：",
                            labelCol: { span: 3 },
                            wrapperCol: { span: 15 },
                          },
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              placeholder: "请输入项目",
                              disabled: "",
                              allowClear: "",
                            },
                            model: {
                              value: _vm.params.projectName,
                              callback: function ($$v) {
                                _vm.$set(_vm.params, "projectName", $$v)
                              },
                              expression: "params.projectName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "工程类别：",
                            labelCol: { span: 3 },
                            wrapperCol: { span: 11 },
                          },
                        },
                        [
                          _c("a-input", {
                            attrs: { allowClear: "", disabled: "" },
                            model: {
                              value: _vm.projectType,
                              callback: function ($$v) {
                                _vm.projectType = $$v
                              },
                              expression: "projectType",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "自评得分：",
                            labelCol: { span: 3 },
                            wrapperCol: { span: 15 },
                          },
                        },
                        [
                          _c("a-input", {
                            staticClass: "custom-input-number",
                            attrs: {
                              placeholder: "请输入自评得分",
                              allowClear: "",
                              type: "number",
                            },
                            on: { input: _vm.validateInteger },
                            model: {
                              value: _vm.params.score,
                              callback: function ($$v) {
                                _vm.$set(_vm.params, "score", $$v)
                              },
                              expression: "params.score",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "形象进度：",
                            labelCol: { span: 3 },
                            wrapperCol: { span: 11 },
                          },
                        },
                        [
                          _vm.params.ptype == 1
                            ? _c(
                                "a-select",
                                {
                                  attrs: { placeholder: "请输入形象进度" },
                                  model: {
                                    value: _vm.params.imageProcess,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.params, "imageProcess", $$v)
                                    },
                                    expression: "params.imageProcess",
                                  },
                                },
                                [
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "基础阶段" } },
                                    [_vm._v(" 基础阶段 ")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "主体结构阶段" } },
                                    [_vm._v(" 主体结构阶段 ")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "装饰装修阶段" } },
                                    [_vm._v(" 装饰装修阶段 ")]
                                  ),
                                ],
                                1
                              )
                            : _c("a-input", {
                                attrs: {
                                  placeholder: "请输入形象进度",
                                  allowClear: "",
                                },
                                model: {
                                  value: _vm.params.imageProcess,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.params, "imageProcess", $$v)
                                  },
                                  expression: "params.imageProcess",
                                },
                              }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "危大内容：",
                            labelCol: { span: 1 },
                            wrapperCol: { span: 14 },
                          },
                        },
                        [
                          _c(
                            "a-radio-group",
                            {
                              staticClass: "ml-3",
                              on: { change: _vm.radioChange },
                              model: {
                                value: _vm.radio,
                                callback: function ($$v) {
                                  _vm.radio = $$v
                                },
                                expression: "radio",
                              },
                            },
                            [
                              _c("a-radio", { attrs: { value: true } }, [
                                _vm._v(" 是 "),
                              ]),
                              _c("a-radio", { attrs: { value: false } }, [
                                _vm._v(" 否 "),
                              ]),
                            ],
                            1
                          ),
                          _c("a-textarea", {
                            attrs: {
                              placeholder: "请输入危大内容",
                              disabled: !_vm.radio,
                              autoSize: { minRows: 10, maxRows: 20 },
                            },
                            model: {
                              value: _vm.params.dangerContent,
                              callback: function ($$v) {
                                _vm.$set(_vm.params, "dangerContent", $$v)
                              },
                              expression: "params.dangerContent",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { "wrapper-col": { offset: 11 } } },
                [
                  _c("a-button", { on: { click: _vm.close } }, [
                    _vm._v(" 关闭 "),
                  ]),
                  _vm.params.status != 3 && _vm.canOperate
                    ? _c(
                        "a-button",
                        {
                          staticClass: "ml-3",
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submit(1)
                            },
                          },
                        },
                        [_vm._v(" 保存 ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }