var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "20px 50px", background: "#FFF" } },
    [
      _c(
        "detail-list",
        { attrs: { col: 2 } },
        [
          _c("detail-list-item", { attrs: { term: "直播主题" } }, [
            _vm._v(_vm._s(_vm.livebroadcast.title)),
          ]),
          _c("detail-list-item", { attrs: { term: "直播类型" } }, [
            _vm._v(_vm._s(_vm._f("typeStr")(_vm.livebroadcast.type))),
          ]),
          _c("detail-list-item", { attrs: { term: "直播时间" } }, [
            _vm._v(
              _vm._s(_vm.livebroadcast.startTime) +
                " ~ " +
                _vm._s(_vm.livebroadcast.endTime)
            ),
          ]),
          _c("detail-list-item", { attrs: { term: "主播微信号" } }, [
            _vm._v(_vm._s(_vm.livebroadcast.anchorWechat)),
          ]),
          _c("detail-list-item", { attrs: { term: "主播昵称" } }, [
            _vm._v(_vm._s(_vm.livebroadcast.anchorName)),
          ]),
          _c("detail-list-item", { attrs: { term: "申请人" } }, [
            _vm._v(_vm._s(_vm.livebroadcast.createdUser)),
          ]),
          _c("detail-list-item", { attrs: { term: "直播背景图" } }, [
            _c("img", {
              staticStyle: { width: "200px", height: "150px" },
              attrs: { src: _vm.livebroadcast.coverPath },
            }),
          ]),
          _c("detail-list-item", { attrs: { term: "购物封面图" } }, [
            _c("img", {
              staticStyle: { width: "200px", height: "150px" },
              attrs: { src: _vm.livebroadcast.feedsPath },
            }),
          ]),
        ],
        1
      ),
      _c(
        "detail-list",
        { attrs: { col: 1 } },
        [
          _c("detail-list-item", { attrs: { term: "直播分享图" } }, [
            _c("img", {
              staticStyle: { width: "200px", height: "150px" },
              attrs: { src: _vm.livebroadcast.sharePath },
            }),
          ]),
          _c("detail-list-item", { attrs: { term: "直播描述" } }, [
            _vm._v(_vm._s(_vm.livebroadcast.remark)),
          ]),
          _c("detail-list-item", { attrs: { term: "审批意见" } }, [
            _vm._v(_vm._s(_vm.livebroadcast.approveRemark)),
          ]),
          _c("detail-list-item", { attrs: { term: "审批状态" } }, [
            _vm._v(
              _vm._s(_vm._f("statusStr")(_vm.livebroadcast.approveStatus))
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "subBtn" },
        [
          _c(
            "a-button",
            {
              staticStyle: { "margin-left": "10px", "margin-right": "50px" },
              on: { click: _vm.doBack },
            },
            [_vm._v(" 返回 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }