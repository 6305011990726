<template>
    <a-card>
        <a-form-model layout="inline" class="mb-3">

            <a-form-model-item label="工程管理部" v-if="!isProject">
                <a-select v-model="params.departmentname" allowClear placeholder="" style="width: 240px">
                    <a-select-option v-for="item in gcglbList" :key="item.departmentname" :value="item.value" :title="item.name">
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>


            <a-form-model-item label="选择月度" class="ml-5">
                <a-date-picker v-model="params.inspectiondate" mode="month" @panelChange="changeYear" :format="`YYYY-MM`"
                    style="width: 140px" />
            </a-form-model-item>

            <a-form-model-item label="项目名称" class="ml-5">
                <a-select show-search :value="params.projectname" placeholder="请输入" style="width: 280px" allowClear
                    :default-active-first-option="false" :show-arrow="false" :filter-option="false"
                    :not-found-content="null" @search="handleChange" @change="handleChangeSearch">
                    <a-select-option v-for="d in projectArr" :key="d.fullName" :title="d.fullName">
                        {{ d.fullName }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>



            <a-form-model-item>
                <a-button class="mr-9 ml-4" type="primary" @click="handleQuery">
                    查询
                </a-button>
                <a-button class="mr-9" type="default" @click="handleReset">
                    重置
                </a-button>
            </a-form-model-item>
        </a-form-model>
        <div class="mb-4">

            <a-button v-if="canOperate" class="mr-4" type="primary" @click="handleAdd">
                新增
            </a-button>
            <a-button class="mr-4" @click="exportDoc"> 导出 </a-button>
        </div>

        <a-table :columns="columns" :data-source="tableData" bordered size="default" :pagination="{
            defaultCurrent: params.pageNumber,
            total: total,
            pageSize: params.pageSize,
            showTotal: (total, range) => `共 ${total} 条`,}" 
            @change="handleChangePage"
        >
            <template slot="action" slot-scope="text,row">

                <a class="mr-4" v-if="(row.status != 2 && row.status != 3) && canOperate"  @click="handleEdit(row)">
                    编辑
                </a>
                <a class="mr-4" v-if="(row.status != 3 && row.status != 2) && canOperate" @click="handleDelete(row)">
                    删除
                </a>
                <div>

                    <a class="mr-4"  v-if="row.status == 2" @click="handleDownload(row)">
                        下载月检资料
                    </a>
                </div>
                <div>

                    <a class="mr-4"  v-if="row.status == 2" @click="handleDownload2(row)">
                        下载整改回复单
                    </a>
                </div>
            </template>



        </a-table>


        <!-- <Export-loading
        :cPercent="cPercent"
        :isExporting="isExporting"
        :loadingText="loadingText"
      ></Export-loading> -->
    </a-card>
</template>
  
<script>
import {
    monthcheckAdd,
    monthcheckList,
    monthcheckDelete,
    monthcheckUpdate,
    monthcheckExport,
    monthcheckListExport
} from "@/services/monthlyInspectionPlan";
import moment from "moment";
import ExportLoading from "@/components/ExportLoading/ExportLoading"; // progress组件
import { exportProgress } from "@/pages/aqjyzl/export"; // 导出方法
import { mapGetters } from "vuex";
import { request } from "@/utils/request";
import { QUERY_OFFICE_BY_USER } from "@/services/api";
import { projectMsgGetList } from "@/services/projectManagement";
import { useRender } from '@/hooks/useRender.js'
import {
  SAFE_VIEW_DOWNLOAD,
} from "@/services/api";

export default {
    name: "LabourProjectExcellentBook",
    mixins: [exportProgress], //导出方法注入
    components: {
        //   ExportLoading,
    },
    data() {
        return {
            params: {
                projectname: undefined,
                departmentname: "",
                inspectiondate: "",
                
                pageNumber: 1,
                pageSize: 10,

            },
            yearFormat: "YYYY",
            gcglbList: [],
            projectArr: [],
            departmentIds: [],
            total: 0,
            columns: [
                {
                    title: "序号",
                    dataIndex: "id",
                    align: "center",
                    width: 65,
                    customRender: (text, record, index) => `${index + 1}`,
                },
                
                {
                    title: "工程管理部",
                    align: "center",
                    dataIndex: "departmentname",
                    width: 180
                },
                {
                    title: "项目名称",
                    dataIndex: "projectname",
                    // align: "center",
                    // ellipsis: true
                },
                {
                    title: "项目类型",
                    dataIndex: "ratingtypeName",
                    width: 150,
                    align: "center",
                    ellipsis: true
                },
                {
                    title: "检查日期",
                    dataIndex: "inspectiondate1",
                    width: 200,
                    align: "center",
                    ellipsis: true,
                    customRender: (text) => useRender.renderDate(text)
                },
                {
                    title: "检查时间",
                    align: "center",
                    dataIndex: "inspectiontime1",
                    width: 150,
                    customRender: (text) => useRender.renderDate(text)
                },
                {
                    title: "状态",
                    dataIndex: "statusName",
                    align: "center",
                    width: 120
                },
                {
                    title: "检评分数",
                    dataIndex: "score",
                    align: "center",
                    width: 95
                },

                {
                    title: "操作",
                    dataIndex: "operation",
                    align: "center",
                    fixed: "right",
                    width: 320,
                    scopedSlots: { customRender: "action" },
                },
            ],
            tableData: [{}],
            arrRoles:['OFFICE_AQMGLY'], // 审批权限
        };
    },
    activated() {
        this.getgcglbList(QUERY_OFFICE_BY_USER);
        if (this.isProject) {
            this.handleQuery();
        }
    },
    computed: {
        ...mapGetters("account", ["user"]),
        canOperate() {
            return this.user.nowRoleKey.some(role => this.arrRoles.includes(role));
        },
        isProject() {
            return this.user.loginType == 0
        }
    },
    methods: {
        handleQuery(e) {
            // moment(params.year).format("YYYY");
            let {projectname,departmentname,inspectiondate,pageNumber,pageSize} = this.params;
            let d = {
                projectname,
                departmentname,
                
                page: pageNumber,
                limit: pageSize,
            };
            if (inspectiondate) {
                d.inspectiondate = moment(inspectiondate).format("YYYY-MM");
            }
            if (this.isProject) {
                d.projectId = this.user.projectId
            } else {
                d.departmentIds = this.departmentIds
            }

            monthcheckList(d).then((res) => {
                if (res.data.code == 200) {
                    this.tableData = res.data.data.list.map(item => {
                        let inspectiondate1 = item.inspectiondate + " " + item.dayofweek;
                        item.inspectiondate1 = inspectiondate1;

                        if (item.inspectiontime == 1) {
                            item.inspectiontime1 = "上午";
                        }
                        if (item.inspectiontime == 2) {
                            item.inspectiontime1 = "下午";
                        }
                        
                        if (item.status == 2) {
                            item.statusName = "完成";
                        } else if (item.status == 3) {
                            item.statusName = "进行中";
                        
                        
                        } else {
                            item.statusName = "计划";

                        }
                        if (item.ratingtype == 1) {
                            item.ratingtypeName = "房建";
                        } else if (item.ratingtype == 2) {
                            item.ratingtypeName = "市政";
                        }
                        
                        return {
                            ...item,
                           
                            
                        }
                    });
                    this.total = res.data.data.totalCount;
                }
            });
            
        },
        handleEdit(row) {
            this.$router.push({
              path: `/planDetail`,
              query: {
                id: row.monthcheckid,
               
              },
            });
        },
        handleAdd() {
            this.$router.push({
              path: `/planDetail`,
              query: {
                id: null,
               
              },
            });
        },

        handleChange(e) {
            console.log(e)
            projectMsgGetList({ fullName: e }).then(res => {
                this.projectArr = [];
                
                if (res.data.code === 0) {
                    this.projectArr = res.data.data.list;
                }
            })
        },
        handleChangeSearch(value) {
            console.log(value);
            this.params.projectname = value;
        },
        handleChangePage(pagination) {
            this.params.pageNumber = pagination.current;
            this.handleQuery();
        },
        changeYear(e) {
            this.params.inspectiondate = e;
            console.log(e);
        },

        // 获取工程管理部数据
        getgcglbList(path) {
            request(path, "get").then((res) => {
                let data = res.data.data
                const arr = [];
                data.forEach((item) => {
                    this.departmentIds.push(item.id)
                    arr.push({
                        name: item.master,
                        value: item.id,
                    });
                });
                arr.unshift({
                    name: "全部",
                    value: "",
                });
                this.gcglbList = arr;
                if (this.departmentIds.length === 0) {
                    this.departmentIds.push(this.user.officeId)
                }
                this.handleQuery();
            });
        },
        async resetQuery() {
            // 重置参数

            this.params = {
                certificateCode: "",
                year: "",
                ptype: 1,
                gcglbId: "",
                pageNumber: 1,
                pageSize: 10,
            };

            this.handleQuery();
        },
        handleDelete(row) {
            this.$confirm({
                title: "提示",
                content: "是否删除？",
                okText: "确定",
                cancelText: "取消",
                onOk: () => {
                    monthcheckDelete({monthcheckid: row.monthcheckid}).then(res => {
                        if (res.data.code == 200) {
                            this.$message.success("删除成功");
                            this.handleQuery();
                        }
                    })

                   
                },
            });

        },
        handleDownload(row) {
            let url = monthcheckListExport + `?monthcheckid=${row.monthcheckid}`;
            window.open(url)
        },
        exportDoc() {
            window.open(monthcheckExport)
            
        },
        handleReset() {
            this.params = {
                projectname: undefined,
                departmentname: "",
                inspectiondate: "",
                
                pageNumber: 1,
                pageSize: 10,
            };
            this.handleQuery();
        
        },
        handleDownload2(row) {
            let arr = [];
            arr.push(row.safeid);
            console.log(row)
            let fileName =
                row.projectname +
                "-" +
                row.inspectiondate+
                "-" +
                '整改回复单'
                // ".zip";
            this.exportFunNomal(SAFE_VIEW_DOWNLOAD, arr, fileName)
        }




    },
};
</script>
  
<style lang="less" scoped>
@import "@/theme/common.less";

/deep/.ant-table-thead>tr>th {
    text-align: center;
}
</style>
  