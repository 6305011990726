<template>
  <div>
    <a-card title="发布版本" class="constructiontTable_index" :bordered="false">
      <a-form :form="docFrom" @submit="handleSubmit" :layout="formLayout">
        <a-form-item label="所属系统" :colon="false">
          <a-checkbox-group v-decorator="['vsystem', { rules: [{ required: true, message: '请选择所属系统' }] }]">
            <a-checkbox v-for="([value, text]) in Object.entries(SYSTEM_TYPE_NAME_MAP)" :value="value" :key="value">
              {{ text }}
            </a-checkbox>
          </a-checkbox-group>
        </a-form-item>
        <a-form-item label="通知类型" :colon="false">
          <a-radio-group v-decorator="['type']">
            <a-radio v-for="([value, text]) in Object.entries(VERSION_TYPE_ZHCN_MAP)" :value="Number(value)" :key="value">
              {{ text }}
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="有效截止时间" :labelCol="{span: 24}" :wrapper-col="{span: 5}">
          <a-date-picker
            class="w-100"
            placeholder="有效截止时间"
            v-decorator="['endTime', { rules: [{ required: true, message: '请选择有效截止时间' }] }]"
            show-time
            format="YYYY-MM-DD HH:mm:ss"
          />
        </a-form-item>
        <a-form-item label="版本号" :labelCol="{span: 24}" :wrapper-col="{span: 16}">
          <a-input placeholder="请填写版本号" v-decorator="['vcode',
          { rules: [{
              required: false,
              message: '请填写版本号',
          }] },
          ]" />
        </a-form-item>
        <a-form-item label="发布时间" :labelCol="{span: 24}" :wrapper-col="{span: 5}">
          <a-date-picker
            class="w-100"
            placeholder="不填则当前日期"
            v-decorator="['vtime', { rules: [{ required: false,message: '发布时间' }] }]"
            show-time
            format="YYYY-MM-DD HH:mm:ss"
          />
        </a-form-item>
        <a-form-item label="更新内容" :labelCol="{span: 24}" :wrapper-col="{span: 16}">
          <keep-alive>
            <tinymce ref="tinymceEditor" :value="remarks" :plugins="plugins" @input="contentInput"></tinymce>
          </keep-alive>
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 6 }">
            <a-button type="primary" html-type="submit"> {{ id ? '修改' :'提交'}} </a-button>
        </a-form-item>
      </a-form>
    </a-card>
  </div>
</template>

<script>
import Tinymce from "@/components/tinymce"
import moment from 'moment';
import { merge } from "lodash";
import { addVersionHistroy, getVersionHistroyDetail,setVersionHistroy } from '@/services/sys'
import { VERSION_TYPE_ENUMS, VERSION_TYPE_ZHCN_MAP, SYSTEM_TYPE_NAME_MAP } from '@/enums/system'

export default {
  name: 'SuijianClientDocConfig',
  components: {Tinymce},
  data() {
    return {
      formLayout: 'horizontal',
      docFrom: this.$form.createForm(this, { name: 'coordinated' }),
      plugins:"lists image table colorpicker textcolor contextmenu",
      remarks: '',
      id: null,
      VERSION_TYPE_ENUMS,
      VERSION_TYPE_ZHCN_MAP,
      SYSTEM_TYPE_NAME_MAP
    };
  },
  mounted() {
    // 初始化表单项的值
    this.initFormItemsValue()
  },
  activated() {
    this.id = this.$route.query.id;
    if (this.id) {
      this.getVersionHistroyDetail()
    }
  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.docFrom.validateFields((err, values) => {
          if (!err) {
              this.$set(values,"vcontent",this.remarks);
              if (values['vtime']) {
                this.$set(values,"vtime",moment(values['vtime']).valueOf());
              } else {
                this.$set(values,"vtime",moment(new Date()).valueOf());
              }
              // 有效截止时间格式化
              this.$set(values,"endTime",moment(values['endTime']).valueOf());
              // 所属系统格式化
              this.$set(values,"vsystem", values['vsystem'].join(','));
              if (!this.id) {
                 addVersionHistroy(values).then(res => {
                  console.log('res', res)
                  if (res.data.code === 0) {
                    this.$message.success('发布成功');
                  } else {
                    this.$message.error(res.data.msg);
                  }
                })
              } else {
                let params = {
                  ...values,
                  id: this.id
                }
                setVersionHistroy(params).then(res => {
                  if (res.data.code === 0) {
                    this.$message.success('修改成功');
                  } else {
                    this.$message.error('发布失败');
                  }
                })
            }
          } else {
              this.$message.error('请填写发版内容');
          }
      })
    },
    contentInput(e){
      this.remarks = e;
    },
    getVersionHistroyDetail() {
      getVersionHistroyDetail({id: this.id}).then(res => {
        if (res.data.code === 0) {
          const data = res.data.data
          const form = ['vsystem', 'type', 'endTime', 'vcode','vtime']
          form.forEach(item => {
            Object.keys(data).forEach(key => {
              if (item === key) {
                let val = data[key]
                if (key === 'vsystem') {
                  val = val.split(',')
                } else if (key === 'endTime' || key === 'vtime') {
                  val = moment(val).format('YYYY-MM-DD HH:mm:ss')
                }
                this.$nextTick(() =>{
                  this.docFrom.setFieldsValue({ [key]: val })
                })
              }
            })
          })
          this.remarks = data.vcontent
        }
      })
    },
    initFormItemsValue() {
      // 默认值
      const defaultValueMap = {
        // 版本更新通告
        type: VERSION_TYPE_ENUMS.PRE_RELEASE
      }
      // 合并
      const newValus = merge(defaultValueMap, this.docFrom.getFieldsValue(['vsystem', 'type']))
      // 更新
      this.docFrom.setFieldsValue(newValus)
    }
  },
};
</script>

<style lang="less" scoped>
  .constructiontTable_index {
    background: #fff;
    border-radius: 6px;
    margin-bottom: 10px;
    min-height: 560px;
  }
</style>