<template>
  <div class="a_Approval">
    <!-- AI交底资料-->
    <!-- <div class="plug-Overview">班前技术交底资料管理</div>
    <a-divider /> -->
    <formIndex ref="formindex" :formData="formData" @handleSubmit="handleSubmit" :flexAction="true"
      @handlSelect="handlSelect" @handleChange="handleChange">
      <template #flexAction>
        <a-space style="margin-left: 25px; margin-top: 5px">
          <a-button icon="search" type="primary" html-type="submit"> 查询 </a-button>
          <a-space class="operator">
            <a-button @click="close"> 重置 </a-button>
          </a-space>
        </a-space>
      </template>
    </formIndex>
    <standard-table :columns="columns" :dataSource="dataSource" :pagination="pagination" :loading="tableLoading"
      @change="onPageChange" :isAlert="false" :bordered="true">
      <div slot="teamQr" slot-scope="{ text, record }">
        <div class="flex a-center j-center" style="height: 40px">
          <div v-if="text != '' && text != null">
            <img :src="text" style="width: 40px; height: 40px; cursor: pointer" @click="showImg(text, record)" />
          </div>
          <a-spin :spinning="!text"></a-spin>
        </div>
      </div>
      <div slot="skillType" slot-scope="{text}">
        <a-tag color="orange">
          {{ text }}
        </a-tag>
      </div>
      <div slot="action" slot-scope="{text, record}">
        <a @click="showPersons(record)">查看人员</a>
        <a-divider type="vertical" />
        <a @click="showPreview('video', record.eventId, 1)">查看视频</a>
        <a-divider type="vertical" />
        <a @click="showPreview('audio', record.eventId, 2)">查看音频</a>
        <a-divider type="vertical" />
        <a @click="showPreview('text', record.eventId)">查看文字</a>
        <!-- <a-divider type="vertical" />
        <a @click="download(record)">下载资料</a> -->
      </div>
      <template slot="statusTitle" slot-scope="{text, record}">
        <a @click="showPersons(record)">{{ text }}</a>
      </template>
    </standard-table>
    <a-modal :width="800" v-model="visible" title="详情" @ok="handleOk" @cancel="handleOk">
      <a-descriptions bordered :column="1" layout="vertical">
        <a-descriptions-item label="发生地点">
          {{ personDataSource.address || '暂无' }}
        </a-descriptions-item>
        <a-descriptions-item label="事件发生时间">
          {{ personDataSource.happenTime ? moment(personDataSource.happenTime).format('YYYY-MM-DD HH:mm:ss') : '暂无' }}
        </a-descriptions-item>
        <a-descriptions-item label="人员名称">
          {{ personDataSource.peopleNames || '暂无' }}
        </a-descriptions-item>
        <a-descriptions-item label="人数">
          {{ personDataSource.peopleNum }}
        </a-descriptions-item>
        <a-descriptions-item label="总结">
          {{ personDataSource.summary || '暂无' }}
        </a-descriptions-item>
      </a-descriptions>
    </a-modal>
    <preview-modal :visible.sync="isModalVisible" :type="previewType" :url="previewUrl" :textContent="previewText" />
    <a-modal :destroyOnClose="true" :visible="modalVisible" @cancel="handleCancel" @ok="downQrCode" ok-text="下载"
      cancel-text="关闭">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import StandardTable from '@/components/table/StandardTable'
import formIndex from "@/pages/components/form/index";
import { useRender } from '@/hooks/useRender.js'
import moment from "moment";
import { mapGetters } from 'vuex'
import PreviewModal from '@/pages/components/PreviewModal.vue';
import { getReportAiList, getReportAiDetail, getReportAiMedia } from '@/services/wordConfig'
import { getAllTeams } from '@/services/participationUnit'
const columns = [
  {
    title: '事件发生时间',
    dataIndex: 'happenTime',
    align: 'center',
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: '事件描述',
    dataIndex: 'description',
    align: 'center',
    ellipsis: true,
    tooltip: true
  },
  {
    title: '班组名称',
    dataIndex: 'teamName',
    align: 'center',
  },
  {
    title: '事件类型',
    dataIndex: 'skillType',
    align: 'center',
    scopedSlots: { customRender: 'skillType' },
  },
  {
    title: '参与人数',
    dataIndex: 'teamName1',
    align: 'center',
  },
  {
    title: '班组在场人数',
    dataIndex: 'teamName2',
    align: 'center',
  },
  {
    title: '事件图片',
    dataIndex: 'renderImageBase64',
    align: 'center',
    scopedSlots: { customRender: 'teamQr' },
  },
  {
    title: '操作',
    align: 'center',
    width: 410,
    scopedSlots: { customRender: 'action' }
  }
]
const formData = [
  {
    label: "事件日期",
    placeholder: "请选择事件日期",
    decorator: [
      "happenTime",
      { rules: [{ required: false, message: "请选择事件日期" }] },
    ],
    type: "rangePicker",
    format: "YYYY-MM-DD HH:mm:ss",
    key: "happenTime",
    labelCol: 6,
    wrapperCol: 14,
    col: 6,
    display: true,
    styles: { width: '100%' },
  },
  {
    label: "事件描述",
    placeholder: "请填写事件描述",
    decorator: [
      "description",
      { rules: [{ required: false, message: "请填写事件描述" }] },
    ],
    type: "input",
    key: "description",
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "所属班组",
    placeholder: "请输入选择所属班组",
    decorator: ["teamId", { rules: [{ required: false, message: "请输入选择所属班组" }] }],
    type: "inputGroup",
    key: "teamId",
    dataSource: [],
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "事件类型",
    placeholder: "请选择",
    decorator: [
      "skillType",
      { rules: [{ required: false, message: "请选择" }] },
    ],
    type: "select",
    selectlist: [
      { value: 0, name: "抽烟行为检测" },
      { value: 1, name: "烟火检测" },
      { value: 2, name: "口罩佩戴检测" },
      { value: 3, name: "睡岗检测" },
      { value: 4, name: "车辆违规检测" },
      { value: 5, name: "塔吊吊装管理员履职识别" },
      { value: 6, name: "电子围栏" },
      { value: 7, name: "反光衣颜色识别" },
      { value: 8, name: "安全帽颜色识别" },
      { value: 9, name: "安全帽检测" },
      { value: 10, name: "聚众识别" },
      { value: 11, name: "室内抽烟" },
      { value: 12, name: "车辆违停" },
      { value: 13, name: "安全交底识别" }
    ],
    key: "skillType",
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "编辑状态",
    placeholder: "请选择",
    decorator: [
      "editStatus",
      { rules: [{ required: false, message: "请选择" }] },
    ],
    type: "select",
    selectlist: [
      { value: 0, name: "未编辑" },
      { value: 1, name: "已编辑" },
    ],
    key: "editStatus",
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "最近编辑日期",
    placeholder: "请选择",
    decorator: [
      "happenTime",
      { rules: [{ required: false, message: "请选择" }] },
    ],
    type: "rangePicker",
    format: "YYYY-MM-DD HH:mm:ss",
    key: "happenTime",
    labelCol: 6,
    wrapperCol: 14,
    col: 6,
    display: true,
    styles: { width: '100%' },
  },
  {
    type: 'action',
    col: 6,
  }
];
export default {
  name: 'SuijianClientAiVideoResource',
  components: {
    StandardTable,
    formIndex,
    PreviewModal
  },
  data() {
    return {
      personDataSource: {},
      dataSource: [],
      inputDataSource: [],
      advanced: false,
      columns: columns,
      tableLoading: false,
      spinning: false,
      loadingText: "数据加载中...",
      monthFormat: 'YYYY-MM-DD',
      nowMonthDay: null,
      loadKeys: [],
      visible: false,
      teamDatas: [],
      params: {
        description: undefined,
        teamId: undefined,
        pageNo: 1,
        pageSize: 10,
        createTime: undefined,
        skillType: undefined
      },
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showLessItems: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      formData,
      isModalVisible: false,
      previewType: '',
      previewUrl: '',
      previewText: '',
      moment,
      previewImage: '',
      modalVisible: false
    };
  },
  filters: {
    subText: function (value) {
      if (value && value.length > 50) {
        return value.substring(0, 50) + "……";
      } else {
        return value;
      }
    }
  },
  computed: {
    ...mapGetters('account', ['user']),
  },

  mounted() {
    this.getData(this.params)
  },

  methods: {
    downQrCode() {
      if (this.previewImage) {
        //这里objimg 表示图片显示的路径  一般都是data:image/jpeg;base64,iVBORw0KGgoAAAANS
        const byteCharacters = atob(this.previewImage.split(",")[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        //区分是什么类型的 svg 或者是png 格式 切记不同类型后缀不同。使用错误可能会下载图片打不开
        let blobs = "image/png";
        let url = window.URL.createObjectURL(
          new Blob([byteArray], { type: blobs })
        );
        let link = document.createElement("a"); //创建a标签
        link.style.display = "none"; //将a标签隐藏
        link.href = url; //给a标签添加下载链接
        link.setAttribute("download", `事件图片.jpg`);
        document.body.appendChild(link);
        link.click(); //执行a标签
      }
    },
    getData(params, query) {
      this.tableLoading = true
      getReportAiList(params, query).then(res => {
        this.tableLoading = false
        const data = res.data.result
        this.dataSource = data.list
        this.pagination.total = data.total
        this.dataSource.forEach(item => {
          item.skillType = this.ptypeState('skillType', item.skillType)
          item.renderImageBase64 = `data:image/png;base64,${item.renderImageBase64}`
        })
      })
    },
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      let query = '';
      if (obj.happenTime) {
        const happenTime = obj.happenTime.map(time => {
          return moment(time).startOf('day').format('YYYY-MM-DD HH:mm:ss');
        });
        // for (const idx of Object.keys(createTime)) {
        //   const paramss = `createTime[${idx}]`
        //   const subPart = `${encodeURIComponent(paramss)}=`
        //   query += `${subPart + encodeURIComponent(createTime[idx])}&`
        // }
        obj.happenTimeStart = happenTime[0]
        obj.happenTimeEnd = happenTime[1]
      }
      delete obj.happenTime
      this.params.pageNo = e.current;
      this.pagination.current = e.current;
      console.log(Object.assign(this.params,obj),'Object.assign(obj, this.params)===')
      this.getData(Object.assign(this.params,obj));
    },
    showPersons(recode) {
      this.visible = true;
      this.getReportAiDetail(recode.eventId)
    },
    async getReportAiDetail(eventId) {
      await getReportAiDetail(eventId).then(res => {
        if (res.data.code == 200) {
          this.personDataSource = res.data.result
        }
      })
    },
    async getReportAiMedia(eventId) {
      const res = await getReportAiMedia(eventId)
      return res
    },
    handleOk() {
      this.visible = false;
      this.personDataSource = {};
      this.aiMedia = []
    },
    async showPreview(type, eventId, media) {
      this.previewType = type;
      if (type == 'text') {
        await this.getReportAiDetail(eventId)
        this.previewText = this.personDataSource.summary;
        this.isModalVisible = true;
      } else {
        this.getReportAiMedia({ eventId: eventId, type: media }).then(res => {
          const data = res.data.result
          if (data.length > 0) {
            const aiMedia = data.filter(obj => obj.type == media)
            if (aiMedia.length == 0) return this.$message.warning(media == 2 ? '音频数据为空' : '视频数据为空', 5)
            this.previewUrl = aiMedia[0].file;
            this.isModalVisible = true;
          } else {
            return this.$message.warning(media == 2 ? '音频数据为空' : '视频数据为空', 5)
          }
        })
      }
    },
    ptypeState(type, status) {
      if (type == "skillType") {
        const obj = {
          0: '抽烟行为检测',
          1: '烟火检测',
          2: '口罩佩戴检测',
          3: '睡岗检测',
          4: '车辆违规检测',
          5: '塔吊吊装管理员履职识别',
          6: '电子围栏',
          7: '反光衣颜色识别',
          8: '安全帽颜色识别',
          9: '安全帽检测',
          10: '聚众识别',
          11: '室内抽烟',
          12: '车辆违停',
          13: '安全交底识别'
        };
        let str = null;
        Object.keys(obj).forEach((key) => {
          if (key == status) {
            str = obj[key];
          }
        });
        return str;
      }
    },
    showImg(imgPath, record) {
      this.modalVisible = true;
      this.previewImage = imgPath;
      this.teamName = record.teamName;
    },
    handleCancel() {
      this.modalVisible = false;
      this.previewImage = "";
      this.teamName = "";
    },
    // 提交表单数据
    handleSubmit(e) {
      let obj = { ...this.params, ...e }
      let query = '';
      if (obj.happenTime) {
        const happenTime = obj.happenTime.map(time => {
          return moment(time).startOf('day').format('YYYY-MM-DD HH:mm:ss');
        });
        // for (const idx of Object.keys(createTime)) {
        //   const paramss = `createTime[${idx}]`
        //   const subPart = `${encodeURIComponent(paramss)}=`
        //   query += `${subPart + encodeURIComponent(createTime[idx])}&`
        // }
        obj.happenTimeStart = happenTime[0]
        obj.happenTimeEnd = happenTime[1]
      }
      delete obj.happenTime
      obj.pageNo = 1
      this.pagination.current = 1;
      this.selectedRows = []
      this.getData(obj);
    },
    handleChange(e) {
      if (e.item.key == 'teamId') {
        this.doQueryTeams({ teamName: e.value })
      }
    },
    // 特殊数据处理
    handlSelect(obj) {
      const { val, keys } = obj;
    },
    close() {
      this.params = {
        description: undefined,
        teamId: undefined,
        pageNo: 1,
        pageSize: 10,
        createTime: undefined,
        skillType: undefined
      }
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getData(this.params);
      this.$refs["formindex"].resetFields();
    },
    //查询所有班组
    doQueryTeams(params) {
      getAllTeams(params).then(res => {
        if (res.data.code === 0) {
          this.formDataFn('teamId', res.data.data, 'teamName')
        }
      })
    },
    // 对下拉框做数据处理
    formDataFn(key, data, itemName) {
      let arrSelect = []
      let arrSource = []
      data.forEach(item => {
        // 班组模糊匹配dataSource
        arrSource.push({ 'title': item[itemName], 'id': item.id })
        // seletetList选项
        arrSelect.push({ 'name': item[itemName], 'value': item.id })
      })
      this.formData.forEach(item => {
        if (item.key == key) {
          item.dataSource = arrSource
          item.selectlist = arrSelect
        }

      })
    },
  },
};
</script>

<style lang="less" scoped></style>