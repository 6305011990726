<template>
    <a-card :bordered="false">

        <div id="components-form-demo-advanced-search">
            <a-form class="ant-advanced-search-form" :form="searchForm" >
                <a-row :gutter="24">
                    <a-col :span="10" >
                        <a-form-item :label="`公司名称`">
                            <a-input v-model="searchForm.officeName" placeholder="请输入公司名称" />
                        </a-form-item>
                    </a-col>
                    <a-col :span="8" >
                        <a-form-item :label="`考勤时间`">
                            <a-range-picker @change="onChange" />
                        </a-form-item>
                    </a-col>

                    <a-col :span="4" :style="{ textAlign: 'right', marginTop: '3px'}">
                        <a-button type="primary" icon="search" :loading="loading" @click="search">
                            查询
                        </a-button>
                    </a-col>
                </a-row>

                <a-row>
                    <a-button type="primary" @click="showDetail">
                        {{detailsShow ? "汇总": "详情"}}
                    </a-button>
                    <a-button style="margin-left:15px" type="primary" v-if="detailsShow" @click="exportExcel">
                        导出详情
                    </a-button>
                </a-row>

            </a-form>
        </div>
        <a-table
                :columns="columns"
                :data-source="data"
                v-if="!detailsShow"
                :pagination="pagination"
                @change="onPageChange"
                :loading="tableLoading"
        >

            <template slot="action" slot-scope="text, record">
                                <span>
                                  <a @click="showModal(record)">编辑</a>
                                  <a-divider type="vertical" />
                                  <a @click="del(record)">删除</a>
                                </span>
            </template>
        </a-table>
        <a-table
                :columns="detailsColumns"
                :data-source="detailsData"
                v-if="detailsShow"
                :loading="tableLoading"
        >

            <template slot="action" slot-scope="text, record">
                                <span>
                                  <a @click="showModal(record)">编辑</a>
                                  <a-divider type="vertical" />
                                  <a @click="del(record)">删除</a>
                                </span>
            </template>
        </a-table>
    </a-card>


</template>
<script>
    import {findNoAttach,findNoAttachPersons} from '@/services/payroll'
    import {EXPORT_NOATTACH_EXCEL} from '@/services/api'
    import {request} from '@/utils/request'

    const columns = [
        {
            title: '公司',
            dataIndex: 'officeName',
            key: 'officeName',
        },
        {
            title: '班组',
            dataIndex: 'teamName',
            key: 'teamName',
        },
        {
            title: '人数',
            dataIndex: 'total',
            key: 'total',
        },
        // {
        //     title: '操作',
        //     dataIndex: 'action',
        //     key: 'action',
        //     scopedSlots: { customRender: 'action' }
        // },
    ];

    const detailsColumns = [
        {
            title: '公司',
            dataIndex: 'officeName',
            key: 'officeName',
        },
        {
            title: '班组',
            dataIndex: 'teamName',
            key: 'teamName',
        },
        {
            title: '班组长电话',
            dataIndex: 'teamPhone',
            key: 'teamPhone',
        },
        {
            title: '工人名',
            dataIndex: 'userName',
            key: 'userName',
        },
        {
            title: '工人电话',
            dataIndex: 'phone',
            key: 'phone',
        },
        // {
        //     title: '操作',
        //     dataIndex: 'action',
        //     key: 'action',
        //     scopedSlots: { customRender: 'action' }
        // },
    ];

    export default {
        data() {
            return {

                // 筛选
                searchForm: {
                    officeName: "",
                    startTime: "",
                    endTime: "",
                    dateText: "",
                },
                loading: false,


                // 数据表格
                columns,
                data: [],
                pagination:{
                    current:1,
                    pageSize:10,
                    total:0,
                },
                tableLoading: false,

                detailsShow: false,
                detailsColumns,
                detailsData:[],

            };
        },
        created() {
            this.initData()
        },
        methods: {
            initData() {
                this.searchForm.pageNumber = 1
                this.pagination.pageNumber = 1
                this.findNoAttach()
            },
            onChange(date, dateString) {
                this.searchForm.startTime = dateString[0] + " 00:00:00"
                this.searchForm.endTime = dateString[1] + " 23:59:59"
                console.log(date, dateString);
            },
            search() {
                this.initData()
            },
            findNoAttach(){
                this.data = []
                this.detailsData = []
                this.loading = true
                this.tableLoading = true
                findNoAttach(this.searchForm).then(res=>{
                    console.log(res)
                    this.data = res.data.data.list
                    this.pagination.total = res.data.data.total
                }).finally(()=>{
                    this.loading = false
                    this.tableLoading = false
                })

                var searchForm = {
                        officeName: this.searchForm.officeName,
                        startTime: this.searchForm.startTime,
                        endTime: this.searchForm.endTime,
                        pageNumber: 1,
                }
                findNoAttachPersons(searchForm).then(res=>{
                    console.log(res)
                    this.detailsData = res.data.data
                }).finally(()=>{
                    this.loading = false
                    this.tableLoading = false
                })
            },
            showDetail() {
                this.detailsShow = !this.detailsShow
            },
            onPageChange(e){
                this.searchForm.pageNumber = e.current;
                this.pagination.current=e.current;
                this.findNoAttach();
            },
            exportExcel(){
                var searchForm = {
                    officeName: this.searchForm.officeName,
                    startTime: this.searchForm.startTime,
                    endTime: this.searchForm.endTime
                }
                request(EXPORT_NOATTACH_EXCEL,"post",searchForm,
                    {
                        responseType:"blob",
                        headers:{ 'Content-Type': 'application/json; application/octet-stream'}
                    }).then(res => {
                        const content = res.data;
                        const reader = new FileReader()
                        var blob = new Blob([content], {type: "application/vnd.ms-excel"})
                        reader.readAsDataURL(blob)
                        reader.onload = (e) => {
                        const a = document.createElement('a')
                        a.download = "未考勤人员统计.xlsx";
                        a.href = e.target.result
                        document.body.appendChild(a)
                        a.click()
                        document.body.removeChild(a)
                    }
                })
            }


        },
    };

</script>
<style scoped>
    .ant-advanced-search-form {
        padding: 24px;
        background: #fbfbfb;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        margin-bottom: 10px;
    }

    .ant-advanced-search-form .ant-form-item {
        display: flex;
    }

    .ant-advanced-search-form .ant-form-item-control-wrapper {
        flex: 1;
    }

    #components-form-demo-advanced-search .ant-form {
        max-width: none;
    }

    tr{
        background-color: #ffffff;
    }

</style>
