var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.$route.meta.showFather,
              expression: "$route.meta.showFather",
            },
          ],
          staticClass: "a_Approval",
        },
        [
          _c("div", { staticClass: "plug-Overview" }, [_vm._v("动态扣分台账")]),
          _c("a-divider"),
          _c("form-index", {
            ref: "formindex",
            attrs: { formData: _vm.formData, flexAction: "" },
            on: {
              handleSubmit: _vm.handleSubmit,
              handlSelect: _vm.handlSelect,
              changeYear: _vm.changeYear,
            },
            scopedSlots: _vm._u([
              {
                key: "flexAction",
                fn: function () {
                  return [
                    _c(
                      "a-space",
                      { staticClass: "btnBox" },
                      [
                        _c(
                          "a-button",
                          { attrs: { type: "primary", "html-type": "submit" } },
                          [_vm._v("查询")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c(
            "div",
            { staticClass: "a_a_footer" },
            [
              _c(
                "standard-table",
                {
                  attrs: {
                    columns: _vm.columns,
                    dataSource: _vm.dataSource,
                    rowKey: "id",
                    pagination: _vm.pagination,
                    loading: _vm.tableLoading,
                    isAlert: false,
                    bordered: true,
                    btnArr: _vm.tableBtnArr,
                  },
                  on: { change: _vm.onPageChange },
                  scopedSlots: _vm._u([
                    {
                      key: "index",
                      fn: function (ref) {
                        var index = ref.index
                        return [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  (_vm.pagination.current - 1) *
                                    _vm.pagination.pageSize +
                                    parseInt(index) +
                                    1
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "company",
                      fn: function (ref) {
                        var record = ref.record
                        return [
                          _c(
                            "span",
                            {
                              staticStyle: { "white-space": "pre-wrap" },
                              style: _vm.scoreColor(record.company),
                              attrs: {
                                title:
                                  "满60分或该单位在省内所有被记分项目平均记分值达到40分的，约谈单位负责人；\n满80分或该单位所有被记分项目平均记分值达到50分以上的，列为重点监管对象。",
                              },
                            },
                            [_vm._v(_vm._s(record.company))]
                          ),
                        ]
                      },
                    },
                    {
                      key: "majorPeople",
                      fn: function (ref) {
                        var record = ref.record
                        return [
                          _c(
                            "span",
                            {
                              staticStyle: { "white-space": "pre-wrap" },
                              attrs: {
                                title:
                                  "满40分或在所属项目的平均记分值达到30分以上的，被约谈；\n满50分的或在所属项目的平均记分值达到40分以上的，列为重点监管对象。",
                              },
                            },
                            [_vm._v(_vm._s(record.majorPeople))]
                          ),
                        ]
                      },
                    },
                    {
                      key: "projectPeople",
                      fn: function (ref) {
                        var record = ref.record
                        return [
                          _c(
                            "span",
                            {
                              staticStyle: { "white-space": "pre-wrap" },
                              attrs: {
                                title:
                                  "满40分，被约谈；\n满50分，列为重点监管对象。",
                              },
                            },
                            [_vm._v(_vm._s(record.projectPeople))]
                          ),
                        ]
                      },
                    },
                    {
                      key: "safePeople",
                      fn: function (ref) {
                        var record = ref.record
                        return [
                          _c(
                            "span",
                            {
                              staticStyle: { "white-space": "pre-wrap" },
                              attrs: {
                                title:
                                  "满40分，被约谈；\n满50分，列为重点监管对象。",
                              },
                            },
                            [_vm._v(_vm._s(record.safePeople))]
                          ),
                        ]
                      },
                    },
                    {
                      key: "comment",
                      fn: function (ref) {
                        var record = ref.record
                        return [
                          _c(
                            "a-tooltip",
                            { attrs: { placement: "topLeft" } },
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(" " + _vm._s(record.comment) + " "),
                              ]),
                              _c("div", { staticClass: "overtext-1" }, [
                                _c(
                                  "span",
                                  { style: _vm.dateColor(record.createTime) },
                                  [_vm._v(_vm._s(record.comment))]
                                ),
                              ]),
                            ],
                            2
                          ),
                        ]
                      },
                    },
                    {
                      key: "action",
                      fn: function (ref) {
                        var record = ref.record
                        return [
                          _c("div", { staticClass: "sTables" }, [
                            _c(
                              "div",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.visit(record)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.$route.meta.isBreak,
                                    expression: "!$route.meta.isBreak",
                                  },
                                ],
                                on: {
                                  click: function ($event) {
                                    return _vm.del(record)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("template", { slot: "tableTitle" }, [
                    _c("div", [
                      _c("span", [
                        _vm._v("累计扣分："),
                        _c("span", { staticClass: "font-bold font-20" }, [
                          _vm._v(_vm._s(_vm.totalScore)),
                        ]),
                      ]),
                      _c("span", { staticClass: "ml-3" }, [
                        _vm._v("所属项目累计平均分："),
                        _c("span", { staticClass: "font-bold font-20" }, [
                          _vm._v(_vm._s(_vm.averageScore)),
                        ]),
                      ]),
                    ]),
                  ]),
                ],
                2
              ),
              _c("Export-loading", {
                attrs: {
                  cPercent: _vm.cPercent,
                  isExporting: _vm.isExporting,
                  loadingText: _vm.loadingText,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }