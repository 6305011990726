var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "relative" },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c(
            "a-steps",
            { attrs: { current: _vm.current, labelPlacement: "vertical" } },
            _vm._l(_vm.steps, function (item) {
              return _c("a-step", {
                key: item.title,
                attrs: { title: item.title },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          [
            _c(
              "a-form",
              { attrs: { form: _vm.form }, on: { submit: _vm.submit } },
              [
                _vm.current == 0
                  ? _c(
                      "a-form-item",
                      [
                        _c(
                          "a-input",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "mobile",
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        message: "请输入手机号",
                                      },
                                      {
                                        pattern: /^1[3-9]\d{9}$/,
                                        message: "请输入有效的手机号码",
                                      },
                                    ],
                                  },
                                ],
                                expression:
                                  "[\n            'mobile',\n            {\n              rules: [\n                { required: true, message: '请输入手机号' },\n                { pattern: /^1[3-9]\\d{9}$/, message: '请输入有效的手机号码' }\n              ],\n            },\n          ]",
                              },
                            ],
                            attrs: {
                              maxLength: 11,
                              size: "large",
                              placeholder: "请输入手机号",
                              disabled: true,
                              name: "mobile",
                            },
                          },
                          [
                            _c("a-icon", {
                              attrs: { slot: "prefix", type: "mobile" },
                              slot: "prefix",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.current == 0 && !_vm.usePwd
                  ? _c(
                      "a-form-item",
                      [
                        _c(
                          "a-input",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "smsCode",
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        message: "请输入验证码",
                                      },
                                    ],
                                  },
                                ],
                                expression:
                                  "[\n            'smsCode',\n            {\n              rules: [{ required: true, message: '请输入验证码' }],\n            },\n          ]",
                              },
                            ],
                            attrs: {
                              size: "large",
                              placeholder: "请输入验证码",
                              name: "smsCode",
                            },
                          },
                          [
                            _c("a-icon", {
                              attrs: { slot: "prefix", type: "lock" },
                              slot: "prefix",
                            }),
                            _c(
                              "a-button",
                              {
                                attrs: {
                                  slot: "addonAfter",
                                  type: "link",
                                  disabled: _vm.countdown > 0,
                                },
                                on: { click: _vm.getCode },
                                slot: "addonAfter",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.countdown > 0
                                        ? _vm.countdown + "秒后重新获取"
                                        : "获取验证码"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.current == 0 && _vm.usePwd
                  ? _c(
                      "a-form-item",
                      [
                        _c(
                          "a-input-password",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "pwd",
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        message: "请输入密码",
                                        whitespace: true,
                                      },
                                    ],
                                  },
                                ],
                                expression:
                                  "[\n            'pwd',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请输入密码',\n                  whitespace: true,\n                },\n              ],\n            },\n          ]",
                              },
                            ],
                            attrs: {
                              maxLength: 16,
                              size: "large",
                              placeholder: "请输入密码",
                              autocomplete: "autocomplete",
                              type: "password",
                            },
                          },
                          [
                            _c("a-icon", {
                              attrs: { slot: "prefix", type: "lock" },
                              slot: "prefix",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.current == 1
                  ? _c(
                      "a-form-item",
                      [
                        _c(
                          "a-input-password",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "newPwd",
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        message: "请输入新密码",
                                        whitespace: true,
                                      },
                                      { validator: _vm.validatePasswordRules },
                                    ],
                                  },
                                ],
                                expression:
                                  "[\n            'newPwd',\n            {\n              rules: [\n                { required: true, message: '请输入新密码', whitespace: true, },\n                { validator: validatePasswordRules },\n              ],\n            },\n          ]",
                              },
                            ],
                            attrs: {
                              size: "large",
                              placeholder: "请输入新密码",
                              type: "password",
                              name: "newPwd",
                            },
                          },
                          [
                            _c("a-icon", {
                              attrs: { slot: "prefix", type: "lock" },
                              slot: "prefix",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.current == 1
                  ? _c(
                      "a-form-item",
                      [
                        _c(
                          "a-input-password",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "checkNewPwd",
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        message: "请确认新密码",
                                        whitespace: true,
                                      },
                                      { validator: _vm.validateToNextPassword },
                                    ],
                                  },
                                ],
                                expression:
                                  "[\n            'checkNewPwd',\n            {\n              rules: [{ required: true, message: '请确认新密码', whitespace: true, }, { validator: validateToNextPassword },],\n            },\n          ]",
                              },
                            ],
                            attrs: {
                              size: "large",
                              placeholder: "请确认新密码",
                              name: "checkNewPwd",
                              type: "password",
                            },
                          },
                          [
                            _c("a-icon", {
                              attrs: { slot: "prefix", type: "lock" },
                              slot: "prefix",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                this.current == 0
                  ? _c("div", [
                      _c(
                        "span",
                        {
                          staticClass: "pointer sys-blue",
                          on: { click: _vm.changeUsePwd },
                        },
                        [_vm._v(_vm._s(_vm.typeText))]
                      ),
                    ])
                  : _vm._e(),
                _c("a-form-item", [
                  _c(
                    "div",
                    { staticClass: "flex mt-1" },
                    [
                      _c(
                        "a-button",
                        {
                          staticClass: "mr-2",
                          staticStyle: { width: "100%", "margin-top": "10px" },
                          attrs: { size: "large" },
                          on: { click: _vm.cancel },
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "a-button",
                        {
                          staticStyle: { width: "100%", "margin-top": "10px" },
                          attrs: {
                            loading: _vm.btnLoading,
                            size: "large",
                            htmlType: "submit",
                            type: "primary",
                          },
                        },
                        [_vm._v(_vm._s(_vm.BtnText))]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
        ],
        2
      ),
      _c("Verify", {
        ref: "verify",
        attrs: {
          "captcha-type": "blockPuzzle",
          "img-size": { width: "330px", height: "180px" },
        },
        on: { success: _vm.handleSuccessVerify },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex a-center j-center mt-1" }, [
      _c("span", { staticClass: "title" }, [_vm._v("修改密码")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }