<template>
    <a-card>
      <a-spin :spinning="spinning" :tip="loadingText">
        <div :class="advanced ? 'search' : null">
          <a-form layout="horizontal">
            <div :class="advanced ? null : 'fold'">
              <a-row>
                <a-col :md="8" :sm="24">
                  <a-form-item
                    label="模块名称"
                    :labelCol="{ span: 5 }"
                    :wrapperCol="{ span: 18, offset: 1 }"
                  >
                    <a-input
                      placeholder="请输入"
                      v-model="queryParams.moduleName"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                  <a-form-item
                    label="模块编号"
                    :labelCol="{ span: 5 }"
                    :wrapperCol="{ span: 18, offset: 1 }"
                  >
                    <a-input
                      placeholder="请输入"
                      v-model="queryParams.moduleCode"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
            </div>
            <span style="float: right; margin-top: 3px">
              <a-button type="primary" @click="doQuery()">查询</a-button>
              <a-button style="margin-left: 8px" @click="doReset()">重置</a-button
              >
            </span>
          </a-form>
        </div>
        <div class="operator">
          <a-button type="primary" @click="doAddTempl()">新增模块设置</a-button>
        </div>
        <div>
          <standard-table
            :columns="columns"
            :dataSource="dataSource"
            :selectedRows.sync="selectedRows"
            :pagination="pagination"
            :loading="tableLoading"
            rowKey="id"
            @clear="onClear"
            @change="onPageChange"
          >
            <div slot="do-status" slot-scope="{ text }">
              <a-icon v-if="text == 1" style="color: green" type="check" />
              <a-icon v-else style="color: red" type="close" />
            </div>
            <div slot="types" slot-scope="{ text,record }">
              {{selectClassFlyDict(`${record.types}`) }}
            </div>
            <div slot="sysClient" slot-scope="{ text,record }">
              {{toDict(`${record.sysClient}`)}}
            </div>
            <div slot="action" slot-scope="{record }">
              <a href="#" @click="doTemplSet(`${record.id}`)">编辑</a>
            </div>
          </standard-table>
        </div>
      </a-spin>
      <temp-setting
        :visible="tempFile.visible"
        :setObject="tempFile.setObject"
        @close="doCloseSet"
        @submit="doSubmit($event)"
      ></temp-setting>
    </a-card>
  </template>
  
  <script>
  import StandardTable from "@/components/table/StandardTable";
  import tempSetting from "@/pages/message/setting";
  import { MESSAGE_MODULE_SET_INSERFT,MESSAGE_MODULE_SET_UPDATE,MESSAGE_MODULE_SET_GETLIST,MESSAGE_MODULE_SET_DETAIL } from "@/services/api";
  import { request } from "@/utils/request";
  import { te } from "date-fns/locale";
  // import axios from '@/utils/axios'
  import { useRender } from '@/hooks/useRender.js'

  const columns = [
    {
      title: "模块名称",
      dataIndex: "moduleName",
      align: "center",
    },
    {
      title: "模块编号",
      dataIndex: "moduleCode",
      align: "center",
    },
    {
      title: "发送平台",
      dataIndex: "sysClient",
      align: "center",
      scopedSlots: { customRender: "sysClient" },
    },
    {
      title: "发送方式",
      dataIndex: "types",
      align: "center",
      scopedSlots: { customRender: "types" },
    },
    {
      title: "是否有效",
      dataIndex: "status",
      align: "center",
      scopedSlots: { customRender: "do-status" },
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      align: "center",
      customRender: (text) => useRender.renderDate(text)
    },
    {
      title: "描述说明",
      dataIndex: "remark",
      align: "center",
    },
    {
      title: "操作",
      align: "center",
      scopedSlots: { customRender: "action" },
    },
  ];

  const arrays = [
    {
      key:'1',
      value:'小程序站内信'
    },
    {
      key:'2',
      value:'微信公众号'
    },
    {
      key:'3',
      value:'微信企业号'
    },
    {
      key:'4',
      value:'消息通知'
    },
  ]
  const sysClientArr = [
    {
      key:'1',
      value:'建工一号'
    },
    {
      key:'2',
      value:'建工管理'
    },
    {
      key:'3',
      value:'建工管理（电脑平台）'
    },
    {
      key:'4',
      value:'其他'
    },
  ]
  
  export default {
    name: "QueryList",
    components: { StandardTable, tempSetting },
    data() {
      return {
        dataSource: [],
        advanced: false,
        columns: columns,
        selectedRows: [],
        tableLoading: false,
        spinning: false,
        loadingText: "数据加载中...",
        loadKeys: [],
        queryParams: {
          moduleName: null,
          moduleCode: null,
          pageNumber: 1,
        },
        pagination: {
          current: 1,
          pageSize: 10,
          total: 0,
          showLessItems: true,
          showQuickJumper: true,
        },
        tempFile: {
          visible: false,
          setObject: {},
        },
      };
    },
    // filters: {
    //   toDict(value) {
    //     debugger
    //     let v  = this.selectClassFlyDict(value);
    //     console.log(v);
    //     return v;
    //   }
    // },
    created() {
      this.doQuery();
    },
    authorize: {
      deleteRecord: "delete",
    },
    methods: {
      toDict(value) {
        var actions = [];
          Object.keys(sysClientArr).some((key) => {
              if (sysClientArr[key].key == ('' + value)) {
                actions.push(sysClientArr[key].value);
                return true;
              }
          })
          return actions.join(',');
      },
      selectClassFlyDict(value) {
          var actions = [];
          Object.keys(arrays).some((key) => {
            value.split(",").forEach(t=>{
              if (arrays[key].key == ('' + t)) {
                actions.push(arrays[key].value);
                return true;
              }
            })
          })
          return actions.join(',');
      },

      deleteRecord(key) {
        this.dataSource = this.dataSource.filter((item) => item.key !== key);
        this.selectedRows = this.selectedRows.filter((item) => item.key !== key);
      },
      toggleAdvanced() {
        this.advanced = !this.advanced;
      },
      remove() {
        this.dataSource = this.dataSource.filter(
          (item) =>
            this.selectedRows.findIndex((row) => row.key === item.key) === -1
        );
        this.selectedRows = [];
      },
      onClear() {
        // this.$message.info('您清空了勾选的所有行')
      },
  
      /**
       * 获取所有人员的签名记录信息
       */
      doQuery() {
        this.tableLoading = true;
        request(MESSAGE_MODULE_SET_GETLIST, "post", this.queryParams).then((res) => {
          if (res.data.code === 0) {
            this.dataSource = res.data.data.list;
            this.pagination.total = res.data.data.total;
          }
          this.tableLoading = false;
        });
      },
  
      doReset() {
        this.queryParams = {};
        this.doQuery();
      },
      //分页查询
      onPageChange(e) {
        this.queryParams.pageNumber = e.current;
        this.pagination.current = e.current;
        this.doQuery();
      },
      handleMenuClick(e) {
        if (e.key === "delete") {
          this.remove();
        }
      },
      doCloseSet() {
        this.tempFile.visible = false;
      },
      doTemplSet($id){
        //查询该直播的最新数据
        this.doDetail($id);
      },
      doAddTempl(){
        this.tempFile.setObject = {};
        this.tempFile.setObject.statusBoolean = true;
        this.tempFile.visible = true;
      },
      doDetail(id){
        request(MESSAGE_MODULE_SET_DETAIL+id, "get", {}).then((res) => {
          if (res.data.code === 0) {
            this.tempFile.setObject = res.data.data;
            this.tempFile.visible = true;
          }else{
            this.$message.error("暂未查询到模板信息！")
          }
        });
      },
      doSubmit($event){
        $event.templFile.status = $event.templFile.statusBoolean?1:0;
        var url = $event.templFile.id?MESSAGE_MODULE_SET_UPDATE:MESSAGE_MODULE_SET_INSERFT
        request(url, "post", $event.templFile).then((res) => {
          if (res.data.code === 0) {
            this.tempFile.visible = false;
            this.$message.success("保存成功！");
            this.doQuery();
          }else{
            this.$message.error("保存异常！");
          }
        });
      },
    //   doStatus($id,status){
    //     let updateObj = {
    //       id:$id,
    //       status:status
    //     }
    //     request(FILE_TEMPL_UPDATE, "post", updateObj).then((res) => {
    //       if (res.data.code === 0) {
    //         this.$message.success("修改状态成功！");
    //         this.doQuery();
    //       }else{
    //         this.$message.error("修改状态异常！");
    //       }
    //     });
    //   }
    },
  };
  </script>
  
  <style lang="less" scoped>
  .search {
    margin-bottom: 54px;
  }
  .fold {
    width: calc(100% - 216px);
    display: inline-block;
  }
  .operator {
    margin-bottom: 18px;
  }
  
  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
  </style>
  