<template>
  <div>
    <div class="a_Approval">
      <!-- 暂时注释 -->
      <!-- <div class="c_i_header">
                <div v-for="item of conTabList" :key="item.id" :class="[item.class, 'c_i_h_center']">
                    <div>
                        {{ item.title }}
                    </div>
                    <div>
                        {{ item.total }}
                    </div>
                </div>
            </div> -->
      <!-- 表单封装
                projectObj：下拉弹出框第一项对象
                Overview：项目名称
                baiduMapBoolen：控制地图显示
                topSubmit：控制上部分按钮显示
                bottomSubmit：控制下部分按钮显示
                handleSubmit：表单提交
                handleChange：输入出发
                handlSelect：输入下拉选择触发
            -->
      <form-index
        v-if="tenantId == 1 || (isOrg || isOffice)"
        ref="formindex"
        :projectList="projectList"
        :formData="formData"
        Overview="完工工程一览表"
        :baiduMapBoolen="false"
        flexAction
        @handleSubmit="handleSubmit"
        @handleChange="handleChange"
        @handlSelect="handlSelect"
      >
        <!-- 按钮插槽 -->
        <template #flexAction>
          <a-space class="btnBox j-start ml-4">
            <a-button type="primary" html-type="submit"> 查询 </a-button>
            <a-button @click="close"> 重置 </a-button>
          </a-space>
        </template>
      </form-index>
      <!-- 表格封装 -->
      <standard-table
        :columns="columns"
        :dataSource="dataSource"
        rowKey="id"
        :pagination="pagination"
        :loading="tableLoading"
        @change="onPageChange"
        :isAlert="false"
        :bordered="true"
        :btnArr="tableBtnArr"
      >
        <template slot="index" slot-scope="{ index }">
          <span>
            {{
              (pagination.current - 1) * pagination.pageSize +
              parseInt(index) +
              1
            }}
          </span>
        </template>
        <div slot="do-status" slot-scope="{ record, text }">
          <template v-if="record.isReport == 1 || record.pjsPath">
            <template v-for="(item, index) in text.split(',')">
              <div v-if="item != '' && item != null"  :key="index">
                <template v-if="icludesPdf(item, str) == -1">
                  <img
                    v-if="item != '' && item != null"
                    :src="item"
                    style="width: 40px; height: 40px; cursor: pointer"
                    @click="showImg(item)"
                  />
                  <a-modal
                    :width="1100"
                    :visible="modalVisible"
                    :footer="null"
                    @cancel="handleCancel"
                  >
                    <img alt="example" style="width: 100%" :src="previewImage" />
                  </a-modal>
                </template>
                <template v-else-if="icludesPdf(item, str) != -1">
                  <!-- style="color:red" -->
                  <a-icon
                    type="file-pdf"
                    :style="{ fontSize: '35px' }"
                    @click="showPdf(item)"
                  />
                </template>
              </div>
            </template>
          </template>
          <template v-else> / </template>
        </div>
        <div slot="pjDate" slot-scope="{ record, text }">
          <span v-if="record.isReport == 1 || record.pjsPath">
            {{ useRender.renderDate(text) }}
          </span>
          <span v-else> / </span>
        </div>
        <div slot="action" slot-scope="{ record }">
          <!-- <a :href="record.filePath">下载</a> -->
          <div class="sTable">
            <div>
              <div v-if="!isOrg" @click="viewEditing(record)">编辑</div>
              <!-- <a-icon
                @click="viewEditing(record)"
                type="edit"
                theme="twoTone"
              /> -->
              <!-- <a-icon
                @click="recorDelete(record)"
                type="delete"
                theme="twoTone"
                two-tone-color="#eb2f96"
              /> -->
            </div>
          </div>
        </div>
      </standard-table>
      <Export-loading
        :cPercent="cPercent"
        :isExporting="isExporting"
        :loadingText="loadingText"
      ></Export-loading>
    </div>
  </div>
</template>

<script>
// AQXJPROJECTSGETLIST
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import {
  aqxjprojectsgetlist,
  aqxjprojectsdetail,
  aqxjprojectsdel,
} from "@/services/projectManagement";
import { request } from "@/utils/request";
import { exportProgress } from "@/pages/aqjyzl/export"; // 导出方法
import ExportLoading from "@/components/ExportLoading/ExportLoading"; // progress组件
import {
  PROJECT_OFFICE_CHILD,
  QUERY_OFFICE_BY_USER,
  AQXJ_PROJECTS_EXPORT_ZAIJIAN,
} from "@/services/api";
import { formatNumber } from "@/utils/whole";
import { projectMsgGetList } from "@/services/projectManagement";
import { useRender } from '@/hooks/useRender.js'
import { mapGetters } from "vuex";
import { session } from '@/utils/storage'

const formData = [
  {
    label: "所属单位",
    placeholder: "请选择所属单位",
    decorator: [
      "gcglbId",
      { rules: [{ required: false, message: "请选择所属单位" }] },
    ],
    type: "select",
    key: "gcglbId",
    qb: true,
    col: 5,
    labelCol: 8,
    wrapperCol: 14,
    selectlist: [],
  },
  {
    label: "项目名称",
    placeholder: "请输入项目名称",
    decorator: [
      "projectName",
      { rules: [{ required: false, message: "请输入项目名称" }] },
    ],
    type: "inputGroup",
    key: "projectName",
    dataSource: [],
    col: 5,
    labelCol: 8,
    wrapperCol: 14
  },
  {
    label: "项目类型",
    placeholder: "请选择施工状态",
    decorator: [
      "ptype",
      { rules: [{ required: false, message: "请选择施工状态" }] },
    ],
    type: "select",
    key: "ptype",
    col: 5,
    labelCol: 8,
    wrapperCol: 14,
    selectlist: [
      {
        value: 1,
        name: "房建",
      },
      {
        value: 2,
        name: "市政",
      },
      {
        value: 3,
        name: "装饰装修",
      },
      {
        value: 4,
        name: "水利",
      },
    ],
  },
  {
    label: "项目编号",
    placeholder: "请输入项目编号：",
    decorator: [
      "projectNum",
      { rules: [{ required: false, message: "请输入项目编号" }] },
    ],
    type: "input",
    key: "projectNum",
    selectlist: [],
    col: 5,
    labelCol: 8,
    wrapperCol: 14
  },
  {
    label: '施工状态',
    placeholder: '请选择施工状态',
    decorator: ['status', { rules: [{ required: false, message: '请选择施工状态' }] }],
    type: 'select',
    key: 'status',
    col: 5,
    labelCol: 8,
    wrapperCol: 14,
    selectlist: [{
        value: 1,
        name: '完工'
    },{
        value: 5,
        name: '竣工'
    }]
  },
  {
    type: 'action',
    col: 5
  }
];
const columns = [
  {
    title: "序号",
    width: 65,
    align: "center",
    scopedSlots: { customRender: "index" },
    fixed: "left",
  },
  {
    title: "项目名称",
    dataIndex: "projectName",
    tooltip: true
  },
  {
    title: "工程地址",
    dataIndex: "projAddress",
    width: 120,
    tooltip: true,
    key: "projAddress",
  },
  {
    title: "工程概况",
    dataIndex: "projDesc",
    width: 120,
    tooltip: true,
    key: "projDesc",
  },
  {
    title: "实际日期（年月日）",
    align: "center",
    children: [
      {
        title: "开工",
        dataIndex: "startDate",
        key: "startDate",
        align: "center",
        width: 120,
        ellipsis: true,
        customRender: (text) => useRender.renderDate(text)
      },
      {
        title: "完工",
        dataIndex: "endDate",
        key: "endDate",
        align: "center",
        width: 120,
        ellipsis: true,
        customRender: (text) => useRender.renderDate(text)
      },
    ],
  },
  {
    title: "合同造价",
    dataIndex: "projBudget",
    align: "center",
    width: 100,
    key: "projBudget",
    ellipsis: true,
  },
  {
    title: "项目经理",
    dataIndex: "xmjl",
    align: "center",
    width: 100,
    key: "xmjl",
    ellipsis: true,
  },
  {
    title: "建设单位",
    dataIndex: "buildOfficeName",
    width: 140,
    key: "buildOfficeName",
    tooltip: true,
  },
  {
    title: "监理单位",
    dataIndex: "effcOfficeName",
    width: 140,
    tooltip: true,
    key: "effcOfficeName",
  },
  {
    title: '当前施工状态',
    dataIndex: 'status',
    align: "center",
    width: 70,
    key: 'status',
  },
  {
    title: "安全评价书",
    align: "center",
    children: [
      {
        title: "评价书",
        dataIndex: "pjsPath",
        key: "pjsPath",
        align: "center",
        width: 100,
        scopedSlots: { customRender: "do-status" },
        ellipsis: true,
      },
      {
        title: "获得时间",
        dataIndex: "pjsDate",
        key: "pjsDate",
        align: "center",
        width: 100,
        ellipsis: true,
        customRender: (text) => useRender.renderDate(text)
      },
    ],
  },
  {
    title: "操作",
    align: "center",
    fixed: "right",
    width: 80,
    scopedSlots: { customRender: "action" },
  },
];
export default {
  mixins: [exportProgress], //导出方法注入
  components: {
    formIndex, //form表单封装
    StandardTable, //表格封装
    ExportLoading, //导出数据加载封装
  },
  data() {
    return {
      columns: columns, //表格数据
      dataSource: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showLessItems: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`,
      },
      tableLoading: false,
      formData: formData,
      conTabList: [
        {
          id: 1,
          title: "项目总数",
          total: 80,
          class: "bg1",
        },
        {
          id: 2,
          title: "本月新增项目数",
          total: 3,
          class: "bg2",
        },
        {
          id: 3,
          title: "在建项目",
          total: 78,
          class: "bg1",
        },
        {
          id: 4,
          title: "停工项目",
          total: 2,
          class: "bg1",
        },
      ],
      prams: {
        pageNumber: 1,
        pageSize: 10,
        queryType: 2,
      },
      projectList: [], //获取当前项目数据
      str: "pdf",
      modalVisible: false,
      previewImage: "",
      loadingText: "数据加载中...",
      cPercent: 0,
      isExporting: false,
      useRender
    };
  },
  created() {
    if (this.$route.query.pageNumber) {
      this.prams.pageNumber = this.$route.query.pageNumber;
    }
    // 获取工程管理部数据
    // this.getgcglbList(PROJECT_OFFICE_CHILD + "/OFFICE_CHILD")
    this.getgcglbList(QUERY_OFFICE_BY_USER);
    this.aqxjlist(this.prams);
  },
  computed:{
    ...mapGetters("account", ["user"]),
    ...mapGetters("setting", ["systemType"]),
    tenantId() {
      return session.get('Tenant-Id') || 1
    },
    isOffice() { //机施
      return this.user.isGcglb == 0 && this.user.loginType == 1;
    },
    isOrg() { //是否集团级
      return this.user.nowRoleKey.some(item => item == 'CLIGUE_AQMGLY')
    },
    tableBtnArr() {
      let arr = []
      if (this.tenantId == 1 || this.isOrg || this.isOffice) {
        return arr = [{
          name: '导出',
          attrs: {
              type: 'primary'
          },
          click: this.doDowload
        }]
      } else {
        return arr
      }
    },
  },
  methods: {
    // 封装弹窗确定按钮
    confirm(title, content) {
      return new Promise((resolve, reject) => {
        this.$confirm({
          title: title,
          content: content,
          onOk() {
            resolve();
          },
        });
      });
    },
    // 删除接口
    recorDelete(val) {
      this.confirm("删除", "是否删除").then(() => {
        aqxjprojectsdel(val.id).then((res) => {
          if (res.data.code === 0) {
            this.$message.success("删除成功", 10);
            this.aqxjlist(this.prams);
          } else {
            this.$message.error(res.data.msg, 10);
          }
        });
      });
    },
    /**
     * 导出excel
     */
    doDowload() {
      let obj = this.$refs["formindex"].getFieldValue();
      obj.queryType = this.prams.queryType;
      this.loadingText = "正在导出EXCEL文件，请稍候 ····";
      this.isExporting = true;
      this.cPercent = 0;
      let fileName = "完工工程一览表.xlsx";
      this.exportFunNomal2(
        AQXJ_PROJECTS_EXPORT_ZAIJIAN,
        obj,
        fileName,
        "application/vnd.ms-excel"
      );
    },
    // 判断是pdf还是图片
    icludesPdf(url, str) {
      if (url) {
        return url.toLowerCase().indexOf(str);
      }
    },
    // 打开pdf
    showPdf(text) {
      let url = text;
      let isimg = this.icludesPdf(url, "pdf") == -1;
      if (isimg) {
        // 打开图片
        this.showImg(url);
      } else {
        // 打开pdf
        window.open(url);
      }
    },
    // 图片预览
    showImg(imgPath) {
      this.modalVisible = true;
      this.previewImage = imgPath;
    },
    handleCancel() {
      this.modalVisible = false;
    },
    // 查看编辑
    viewEditing(e) {
      aqxjprojectsdetail(e.id).then((res) => {
        if (res.data.code === 0) {
          this.$router.push({
            path: "/project_details_index",
            query: {
              data: res.data.data,
              type: "list",
              pageNumber: this.prams.pageNumber,
              random: Math.random()
            },
          })
          // 刷新详情页面，form组件回显数据
          // this.$refreshPage('/project_management/details_index',false)
        }
      });
    },
    // 特殊数据处理
    handlSelect({ val, keys }) {
      this.projectList.forEach((item) => {
        if (keys == "projectName" && item.id == val) {
          this.$refs["formindex"].setFieldsValue(
            item.fullName,
            {},
            "projectName"
          );
        }
      });
    },
    handleChange(e) {
      this.projectArr = [];
      this.$refs["formindex"].setFieldsValue('', {}, "projectName");
      this.getZdata(projectMsgGetList, e, "fullName");
      // 获取当前匹配key，赋值相对于数组
      this.formData.forEach((item) => {
        if (e.item.key === item.key) {
          item.dataSource = this.projectArr;
        }
      });
    },
    // 接口封装
    getZdata(msg, e, name) {
      let data = {
        [name]: e.value,
      };
      msg(data).then((res) => {
        if (res.status == 200) {
          const data = res.data.data.list ? res.data.data.list : res.data.data
          data.forEach((item) => {
            this.projectArr.push({
              title: item[name],
              id: item.id,
            });
          });
          this.projectList = data;
        }
      });
    },
    // 新建项目
    createItem() {
      this.$router.push("./create_index");
    },
    // 项目数据
    ptypeState(type, status) {
      if (type == "ptype") {
        const obj = {
          0: "房建",
          1: "市政",
          2: "装饰装修",
          // 3:'装修',
        };
        let str = null;
        Object.keys(obj).forEach((key) => {
          if (key == status) {
            str = obj[key];
          }
        });
        return str;
      } else {
        const obj = {
            1:'完工',
            5: '竣工'
        }
        let str = null;
        Object.keys(obj).forEach(key =>{
            if(key == status){
                str =  obj[key]
            }
        })
        return str
      }
    },
    aqxjlist(prams) {
      this.tableLoading = true;
      aqxjprojectsgetlist(prams).then((res) => {
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
          this.dataSource.forEach((item) => {
            item.ptype = this.ptypeState("ptype", item.ptype);
            item.status = this.ptypeState("status", item.status);
          });
        }
        this.tableLoading = false;
      });
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      let arr = [];
      data.forEach((item) => {
        arr.push({ name: item.master, value: item.id });
      });
      this.formData.forEach((item) => {
        if (item.key == key) {
          item.selectlist = arr;
        }
      });
    },
    // 获取工程管理部数据
    getgcglbList(path) {
      request(path, "get").then((res) => {
        let data = res.data.data.filter(
          (item) => item.id != "3da0f5533f87425c9a9e8a11a7923300"
        );
        this.formDataFn("gcglbId", data);
      });
    },
    // 提交表单数据
    handleSubmit(e) {
      this.prams.pageNumber = 1 // 点击查询按钮 从第一页开始
      this.pagination.current = 1
      this.aqxjlist(Object.assign(e, this.prams));
    },
    // 重置
    close() {
      this.prams = {
        pageNumber: 1,
        pageSize: 10,
        queryType: 2,
      };
      this.aqxjlist(this.prams);
      this.$refs["formindex"].setFieldsValue("", {}, "projectName");
      this.$refs["formindex"].resetFields();
    },
    //表格 - 分页查询
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      this.prams.pageNumber = e.current;
      this.pagination.current = e.current;
      this.aqxjlist(Object.assign({}, this.prams, obj));
    },
  },
};
</script>

<style lang="less" scoped>
.bg1 {
  background: linear-gradient(
    180deg,
    rgba(242, 249, 254, 1) 0%,
    rgba(230, 244, 254, 1) 99%
  );
}

.bg2 {
  background: linear-gradient(
    180deg,
    rgba(245, 254, 242, 1) 0%,
    rgba(230, 254, 238, 1) 99%
  );
}

.c_i_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 25px;

  .c_i_h_center {
    padding: 16px;
    border-radius: 4px;
    width: 180px;
    height: 130px;
    margin-right: 50px;

    div:nth-child(1) {
      font-size: 16px;
    }

    div:nth-child(2) {
      margin-top: 26px;
      font-size: 24px;
    }
  }
}

.sTable {
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    width: 100%;
    color: #307dfa;
    text-align: center;
    cursor: pointer;
  }
}
</style>
