<template>
    <div>
      <a-card :title="title" class="a_Approval" :bordered="false">
        <a-form-model ref="ruleForm" :model="params" :rules="rules">
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="通知名称" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="noticename">
                <a-input placeholder="请输入通知名称" v-model="params.noticename" />
              </a-form-model-item>
            </a-col>
          
            <a-col :span="12">
              <a-form-model-item label="日期" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="pubdate">
                <a-date-picker v-model="params.pubdate" style="width: 100%" allowClear mode="date"
                  :format="'YYYY-MM-DD'" :valueFormat="'YYYY-MM-DD'"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="编号" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="no">
                <a-input placeholder="请输入编号" v-model="params.no" />
              </a-form-model-item>
            </a-col>
           
           
  
            <a-col :span="12">
              <a-form-model-item label="上传附件" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="year">
                <a-upload
                  class="avatar-uploader"
                  list-type="picture-card"
                  :accept="accept"
                  :file-list="filePath"
                  @change="handleUploadChange"
                  :customRequest="resourceUpload"
                  @preview="handlePreview"
                  :beforeUpload="beforeUpload"
                  multiple
                >
                  <!-- <div v-for="(item, index) in filePath" :key="index">
                    <img
                      class="w-100"
                      v-if="item && item.name.indexOf('pdf') == -1"
                      :src="item"
                      alt="avatar"
                    />
                    <a-icon v-else-if="item && item.name.indexOf('pdf') > -1" type="file-pdf" :style="{ fontSize: '64px' }" />
                  </div> -->
  
                  <div v-if="filePath.length < 10">
                    <a-icon :type="uploading ? 'loading' : 'plus'" />
                    <div class="ant-upload-text">上传附件</div>
                  </div>
                </a-upload>
              </a-form-model-item>
            </a-col>
            <a-col :span="12" v-if="level == 1">
              <a-form-model-item label="关联信息" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" >
              <div>
                <div>
                 
                  <a-button type="link" @click="handleSafe">安全管理制度</a-button>
                  <a-button v-if="safeObj" type="link">已关联</a-button>
                  <a-button v-if="safeObj" type="link" @click="handleSafeView">查看</a-button>
                </div>
                <div>
                  <a-button type="link" @click="handleGuid">工作指引</a-button>
                  <a-button v-if="workguide" type="link">已关联</a-button>
                  <a-button v-if="workguide" type="link" @click="handleGuidView">查看</a-button>
                </div>
                <div>
                  <a-button type="link" @click="handleEmer">应急预案</a-button>
                  <a-button v-if="emergency" type="link">已关联</a-button>
                  <a-button v-if="emergency" type="link" @click="handleEmerView">查看</a-button>
                </div>
              </div>
              </a-form-model-item>
            </a-col>
  
          </a-row>
        </a-form-model>
        <a-row>
          <a-col :span="12"></a-col>
          <a-col :span="12">
            <a-form-model-item :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }" prop="year">
              <div class="flex flex-row-reverse">
                <a-button type="primary" @click="handleSubmit">保存</a-button>
                <a-button class="mr-2" @click="close">返回</a-button>
              </div>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-card>

      <safeDialog ref="safe" @refresh="handleRefresh" @saveSafe="emitSafe"></safeDialog>
      <guidelinesDialog ref="guid" @refresh="handleRefresh" @saveGuide="emitGuid"></guidelinesDialog>
      <emergencyDialog ref="emer" @refresh="handleRefresh" @saveEmer="emitEmer"></emergencyDialog>
    </div>
  </template>
  
  <script>
  
  import { request } from "@/utils/request";
  import { mapGetters } from 'vuex'
  import moment from "moment";
  import { noticefileAdd,noticefileUpdate, noticefileByID,
   
    safesystemByNoticeId,
    safegworkguideByNoticeId,
    emergencyplanByNoticeId
   } from "@/services/specificatioNotification";
  import { ATTACHMENT_UPLOAD } from "@/services/api";
  import safeDialog from "./safeDialog.vue"
  import guidelinesDialog from "./guidelinesDialog.vue"
  import emergencyDialog from "./emergencyDialog.vue"

  export default {
    inject: ["fatherMethod"],
    computed: {
      ...mapGetters('account', ['user']),
    },
    components: {
      safeDialog,
      guidelinesDialog,
      emergencyDialog
    },
    data() {
      return {
        isEdit: 0,
        rules: {
          noticename: [{ required: true, message: '请输入名称', trigger: 'change' }],
          no: [{ required: true, message: '请输入编号', trigger: 'change' }],
          // pubdate: [{ required: true, message: '请选择发布日期', trigger: 'change' }],
          // pubdeptname: [{ required: true, message: '请输入发布部门', trigger: 'change' }],
          // remark: [{ required: true, message: '请选择', trigger: 'change' }],
          // attach: [{ required: true, message: '请选择', trigger: 'change' }],
        },
  
        params: {
          "noticename": "", //通知文件名称
          "no": "", //编号
          "pubdate": "", //日期 yyyy-MM-dd
          "attach": ""
        },
        level: null,
        // accept:".png,.jpg,.jpeg,.pdf,.PDF",
        accept:"",
        filePath: [],
        uploading: false,
        title: '新增通知发放内容',

        safeObj: null,
        workguide: null,
        emergency: null,
        safeObjD: null,
        workguideD: null,
        emergencyD: null,
      };
    },
    activated() {
      this.isEdit = Number(this.$route.query.isEdit);
      if (this.isEdit === 0) {
        // this.params.creator = this.user.id
        this.title = '新增通知发放内容'
      } else {
        // this.params.updator = this.user.id
        this.title = '编辑通知发放内容'
        this.params.noticeid = this.$route.query.id;
        this.getDetail()
      }
      this.level = this.$route.query.type;
      
    },
    methods: {
      moment,
      async getDetail() {
        noticefileByID({id: this.params.noticeid}).then(res => {
          if (res.data.code === 200) {
            this.params = res.data.data;
            let attachList = [];
            if (this.params.attach) {
              attachList = this.params.attach.split(',');
            }
            let fileNames = [];
            if (this.params.createuser) {
              fileNames = this.params.createuser.split(',');
            }
            this.filePath = attachList.map((item, index) => {
              return {
                uid: index,
                url: item,
                name: fileNames[index],
                status: 'done',
              }
            })
           
          } else {
            this.$message.error(res.data.msg)
          }
        })

        // 查询关联的 安全制度 工作指引 应急预案
        this.getBang()
      },
      async getBang() {
        // 查询关联的 安全制度 工作指引 应急预案
        let p = {id: this.params.noticeid}
        let r1 = await safesystemByNoticeId(p)
        let r2 = await safegworkguideByNoticeId(p)
        let r3 = await emergencyplanByNoticeId(p)

        this.safeObj = r1.data.data
        this.workguide = r2.data.data
        this.emergency = r3.data.data
        
        // this.safeObjD = r1.data.data
        // this.workguideD = r2.data.data
        // this.emergencyD = r3.data.data
      },
  
      handleSubmit() {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            if (this.isEdit == 0) {
              let attach = this.filePath.map(item => item.url).join(',');
              let fileNames = this.filePath.map(item => item.name).join(',');

              let d = {
                notice: {
                  ...this.params,
                  attach: attach,
                  createuser: fileNames
                },
                level: this.level,
                safeSystem: null,
                safeGWorkGuide: null,
                emergencyPlan: null
              }

              if(this.level == 1){
                d.safeSystem = this.safeObjD
                d.safeGWorkGuide = this.workguideD
                d.emergencyPlan = this.emergencyD
              }
              noticefileAdd(d).then(res => {
                if (res.data.code === 200) {
                  this.$message.success('提交成功！')
                  this.close();
                  this.$router.push({
                    name: "集团及公司通知文件",
                  });
                } else {
                  this.$message.error(res.data.msg)
                }
              })
            } else {
              let attach = this.filePath.map(item => item.url).join(',');
              let fileNames = this.filePath.map(item => item.name).join(',');

              this.params.attach = attach;
              this.params.createuser = fileNames;

              let d = {
                notice: this.params,
                level: this.level,
                safeSystem: null,
                safeGWorkGuide: null,
                emergencyPlan: null
              }
              if(this.level == 1){
                d.safeSystem = this.safeObjD
                d.safeGWorkGuide = this.workguideD
                d.emergencyPlan = this.emergencyD
              }

              noticefileUpdate(d).then(res => {
                if (res.data.code === 200) {
                  this.$message.success('修改成功！')
                  this.close();
                  this.$router.push({
                    name: "集团及公司通知文件",
                  });
                } else {
                  this.$message.error(res.data.msg)
                }
              })
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      close() {
        let path = this.$route.path;
        this.fatherMethod(`${path}`);
        this.$router.push({
          name: "集团及公司通知文件",
        });
      },
      beforeUpload(file){
        // const accept = '.' + file.name.substring(file.name.lastIndexOf(".") + 1);
        // if (this.accept.split(',').indexOf(accept) == -1) {
        //     this.$message.error("只能上传图片、PDF文件");
        //     return false
        // }
      },
      handleUploadChange(info) {
        console.log(info)
        if (info.file.status === "uploading") {
          this.uploading = true;
          return;
        }
        if (info.file.status === "done") {
          this.uploading = false;
        }
        if (info.file.status === "removed") {
          this.filePath = info.fileList
        }
      },
      resourceUpload(value) {
        const formData = new FormData();
        formData.append("file", value.file);
        request(ATTACHMENT_UPLOAD, "post", formData).then((result) => {
          if (result.data.code === 0) {
            this.$message.success("上传成功!");
            this.filePath.push({
              uid: value.file.uid,
              url: result.data.data.path,
              name: value.file.name,
              status: 'done',
            });
            value.onSuccess(result.data.data.path, value.file);
          } else {
            this.$message.warning(result.data.message);
          }
        });
      },
      handlePreview(file) {
        window.open(file.url);
      },
      handleSafe() {
        let id = this.params.noticeid
        this.$refs.safe.open(id, 'edit')
      },
      handleSafeView() {
        let id = this.params.noticeid
        this.$refs.safe.open(id, 'view')
      },
      emitSafe(data) {
        this.safeObjD = data
      },
      handleGuid() {
        let id = this.params.noticeid
        this.$refs.guid.open(id, 'edit')
      },
      handleGuidView() {
        let id = this.params.noticeid
        this.$refs.guid.open(id, 'view')
      },
      emitGuid(data) {
        this.workguideD = data
      },
      handleEmer() {
        let id = this.params.noticeid
        this.$refs.emer.open(id, 'edit')
      },
      handleEmerView() {
        let id = this.params.noticeid
        this.$refs.emer.open(id, 'view')
      },
      emitEmer(data) {
        this.emergencyD = data
      },
      handleRefresh() {
        this.getBang()
      }

    },
  };
  </script>
  
  <style lang="less" scoped>
  .a_Approval {
    background: #fff;
    border-radius: 10px;
  }
  
  .btn_group {
    padding-right: 190px;
  }
  </style>
  