var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("form-index", {
        ref: "formindex",
        attrs: { formData: _vm.formData, flexSubmit: true },
        on: { handleSubmit: _vm.handleSubmit, handlSelect: _vm.handlSelect },
        scopedSlots: _vm._u([
          {
            key: "flexSubmit",
            fn: function () {
              return [
                _c(
                  "a-button",
                  { attrs: { type: "primary", "html-type": "submit" } },
                  [_vm._v(" 查询 ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "a_a_footer" },
        [
          _c(
            "div",
            [
              _c(
                "a-space",
                { staticClass: "operator" },
                [
                  _c(
                    "a-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.doDowloadBatch(1)
                        },
                      },
                    },
                    [_vm._v("批量通过")]
                  ),
                ],
                1
              ),
              _c(
                "a-space",
                { staticClass: "operator" },
                [
                  _c(
                    "a-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.doDowloadBatch(2)
                        },
                      },
                    },
                    [_vm._v("批量驳回")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("standard-table", {
            attrs: {
              columns: _vm.columns,
              dataSource: _vm.dataSource,
              selectedRows: _vm.selectedRows,
              rowKey: "id",
              scroll: { x: 1300 },
              pagination: _vm.pagination,
              loading: _vm.tableLoading,
              isAlert: false,
              bordered: true,
            },
            on: {
              "update:selectedRows": [
                function ($event) {
                  _vm.selectedRows = $event
                },
                function ($event) {
                  return _vm.updateSelectRows($event)
                },
              ],
              "update:selected-rows": function ($event) {
                _vm.selectedRows = $event
              },
              change: _vm.onPageChange,
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function (ref) {
                  var index = ref.index
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            (_vm.pagination.current - 1) *
                              _vm.pagination.pageSize +
                              parseInt(index) +
                              1
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "staffName",
                fn: function (ref) {
                  var record = ref.record
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to:
                            "/personal_info?projectPersonId=" +
                            record.projectPersonId,
                        },
                      },
                      [_vm._v(_vm._s(record.staffName))]
                    ),
                  ]
                },
              },
              {
                key: "filePath",
                fn: function (ref) {
                  var text = ref.text
                  return [
                    _c("a-avatar", {
                      attrs: {
                        shape: "square",
                        size: 64,
                        src: text,
                        icon: "user",
                      },
                    }),
                  ]
                },
              },
              {
                key: "action",
                fn: function (ref) {
                  var record = ref.record
                  return _c("div", {}, [
                    _c("div", { staticClass: "sTables" }, [
                      _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.viewEditing(record)
                            },
                          },
                        },
                        [_vm._v("查看>")]
                      ),
                      _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.doDowloadBatch(1, record)
                            },
                          },
                        },
                        [_vm._v("通过")]
                      ),
                      _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.doDowloadBatch(2, record)
                            },
                          },
                        },
                        [_vm._v("驳回")]
                      ),
                    ]),
                  ])
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }