<template>
  <div class="pb-2">
    <a-row :gutter="16">
      <a-col :span="14">
        <ProjectInfo />
        <ProjectStaff style="margin-top: 16px"/>
      </a-col>
      <a-col :span="10">
        <ProjectSituation />
        <WorkChart style="margin-top: 16px"/>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import ProjectInfo from "./components/projectInfo.vue";
import ProjectSituation from "./components/projectSituation.vue";
import WorkChart from "./components/workChart.vue";
import ProjectStaff from "./components/projectStaff.vue";
export default {
  components: { ProjectInfo, ProjectSituation,WorkChart, ProjectStaff },
  data() {
    return {
      
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>

<style lang="less" scoped>

</style>