<!-- eslint-disable vue/no-template-key -->
<template>
  <div class="create_index">
    <!-- 表单封装 
            projectObj：下拉弹出框第一项对象
            Overview：项目名称
            baiduMapBoolen：控制地图显示
            topSubmit：控制上部分按钮显示
            bottomSubmit：控制下部分按钮显示
            handleSubmit：表单提交
            handleChange：输入出发
            handlSelect：输入下拉选择触发
            defaultFileList：上传图片回显链接
            query：路由传过来的数据
        -->
    <form-index :key="random" ref="formindex"  :formData="formData" Overview="项目概况" :options="address"
      @handleChange="handleChange" :baiduMapBoolen="true" :topSubmit="true" :projectList="projectList"
      @handleSubmit="handleSubmit" @handlSelect="handlSelect" :defaultFileList="defaultFileList"
      @handleSearch="handleSearch" @resourceUpload="resourceUpload">
      <!-- 上部分按钮插槽 -->
      <template #topSubmit>
        <a-button type="primary" html-type="submit"> 提交 </a-button>
        <a-button @click="close"> 关闭 </a-button>
      </template>
      <template :slot="item.officeType" v-for="(item, index) in officeList" >
        <a-input v-model="item.officeName" placeholder="请输入" :key="index"/>
      </template>
    </form-index>
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import {
  projectMsgGetList,
  aqxjprojectsinsert,
  getofficelist,
  aqxjProjectsupdate,
} from "@/services/projectManagement";
import { request } from "@/utils/request";
import { PROJECT_OFFICE_CHILD } from "@/services/api";
import { sysUserGetList } from "@/services/message";
import { storage } from '@/utils/storage';
import {
  saveOrUpdateExcellent,
  getExcellentDetail,
} from "@/services/labourProjectExcellent";
import { mapGetters } from "vuex";
import { jsFormData, commonFormData } from './formData'
import { session } from '@/utils/storage'
import { cloneDeep } from "lodash";
import address from "@/pages/components/form/address.json";

export default {
  data() {
    return {
      query: this.$route.query,
      formData: [],
      // form: this.$form.createForm(this, { name: 'coordinated' }),
      projectArr: [], //项目名称
      projectList: [], //获取当前项目数据
      defaultFileList: [], //图片数组
      isReport: null, // 记录是否报建变量
      status: null,
      random: null,
      monitorRoles: ['OFFICE_AQMGLY'], // 操作重点监控项目权限
      arrRoles: ['OFFICE_AQMGLY'], // 权限
      projectRoles: ['project_leader', 'PROJECT_AQY_ZB_FZR', 'OFFICE_AQ_LEADER'], // 项目经理，安全员，分公司的角色
      officeList: [
        {
          officeName:'',
          officeType: '1',
        },
        {
          officeName:'',
          officeType: '2',
        },
        {
          officeName:'',
          officeType: '3',
        }
      ],
      address
    };
  },
  components: {
    formIndex,
  },
  inject: ["fatherMethod"],
  computed: {
    ...mapGetters("account", ["user"]),
    isJSOffice() { //机施
      return this.user.isGcglb == 0 && this.user.loginType == 1
    },
    canSubmit() {
      return this.user.nowRoleKey.some(role => this.arrRoles.includes(role));
    },
    isMonitor() {
      return this.user.nowRoleKey.some(role => this.monitorRoles.includes(role));
    }
  },
  watch: {
    isJSOffice: {
      handler(val) {
        this.formData.forEach(item => {
          if (item.key == 'pjsDate' || item.key == 'pjsPath' || item.key == 'endDate') {
            item.disabled = !val
          }
        })
      },
      immediate: true,
    },
  },
  methods: {
    // 下拉特殊数据处理
    handleSearch(e) {
      if (e.key == "buildOfficeName" || e.key == "effcOfficeName") {
        this.getZdata(getofficelist, e, "name", e.key);
      }
    },
    // 图片上传
    resourceUpload() {
      console.log(this.$refs["formindex"].pathArr);
    },
    // 关闭当前页面
    close() {
      let path = this.$route.path;
      this.fatherMethod(`${path}`);
    },
    // 完工
    tobeFinished() {
      let obj = {
        id: this.query.data.id,
        projectId: this.query.data.projectId,
        status: 1,
      };
      this.update(obj, "tobeFinished");
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      this.formData.forEach((item) => {
        if (item.key == key) {
          item.selectlist = data;
        }
      });
    },
    // 获取工程管理部数据
    getgcglbList(path) {
      request(path, "get").then((res) => {
        if (res.data.code === 0) {
          const data = res.data.data.filter(
            (item) => item.id != "3da0f5533f87425c9a9e8a11a7923300"
          );
          const arr = [];
          data.forEach((item) => {
            arr.push({
              name: item.label,
              value: item.value,
            });
          });
          this.formDataFn("gcglbId", arr);
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    update(e, type) {
      if (this.query.type != "list") {
        this.saveExcellent(e)
      }
      if (e.monitorDisable == 1) {
        // 手动置空
        e.monitorReason = ''
      }
      const params = Object.assign({}, e, {
        officeList: this.officeList,
        province: e.projectAddress[0] ? e.projectAddress[0] : undefined,
        city: e.projectAddress[1] ? e.projectAddress[1] : undefined,
        county: e.projectAddress[2] ? e.projectAddress[2] : undefined,
      });
      aqxjProjectsupdate(params).then((res) => {
        if (res.data.code === 0) {
          type == "handleSubmit"
            ? this.$message.success("更新成功", 10)
            : this.$message.success("成功", 10);
          if (this.query.type == "table") {
            this.$router.push({
              name: "在建工程一览表",
              query: {
                pageNumber: this.query.pageNumber,
              },
            });
          } else {
            this.$router.push({
              name: "完工工程一览表",
              query: {
                pageNumber: this.query.pageNumber,
              },
            });
          }
        } else {
          this.$message.error(res.data.msg, 10);
        }
      });


    },
    saveExcellent(e) {
      // 新增在建工程创优
      let { projectName, cyyq, projectId, cylevel, ptype, gcglbId, gcglbName } =
        e;
      let params
      if (this.query.exData == 0) {
        params = {
          certificates: [],
          excellentInfo: {
            cyyq,
            cylevel,
            ptype,
            projectName,
            projectId,
            gcglbId,
            gcglbName,
          },
        };
      } else {
        console.log(this.query)
        this.query.exData.excellentInfo.cyyq = cyyq;
        this.query.exData.excellentInfo.cylevel = cylevel;
        this.query.exData.excellentInfo.ptype = ptype;
        params = this.query.exData
      }
      saveOrUpdateExcellent(params).then((res) => {
        if (res.data.code === 0) {
          // this.$message.success("添加创优", 10);
        } else {
          this.$message.error(res.data.msg, 10);

        }
      });
    },
    // 提交表单数据
    handleSubmit(e) {
      delete e.lanAndLat;
      e.id = this.query.data.id;
      e.projectId = this.query.data.projectId;
      storage.set('presenceList-submit', true)
      this.update(e, "handleSubmit");
      this.close();
    },
    // 下拉接口的处理
    getZdata(msg, e, name, key) {
      let data = {
        [name]: e.value,
      };
      msg(data).then((res) => {
        this.projectArr = [];
        if (res.data.code == 0) {
          const data = res.data.data.list ? res.data.data.list : res.data.data
          const arr = [];
          if (name == 'nickName') {
            name = 'nickname'
          }
          data.forEach((item) => {
            this.projectArr.push({
              title: item[name],
              id: item.id,
            });
            arr.push({
              name: item[name],
              value: item.id,
            });
          });
          // // 获取当前匹配key，赋值相对于数组
          this.formData.forEach((item) => {
            if (e.item.key === item.key) {
              item.dataSource = this.projectArr;
            }
          });
          this.projectList = data;
          if (key) {
            this.formDataFn(key, arr);
          }
        }
      });
    },
    // 输入选择框触发
    handleChange(e) {
      const name = {
        projectName: () => {
          this.getZdata(projectMsgGetList, e, "fullName");
        },
        xmjl: () => {
          this.getZdata(sysUserGetList, e, "nickName");
        },
        buildOfficeName: () => {
          this.getZdata(getofficelist, e, "name");
        },
        effcOfficeName: () => {
          this.getZdata(getofficelist, e, "name");
        },
      }[e.item.key](); //匹配对应调用对应接口
      const arr = ["projectId", "buildOffice", "effcOffice"];
      arr.forEach((item) => {
        this.$refs["formindex"].setFieldsValue("", {}, item);
      });
    },
    // 特殊数据处理
    handlSelect({ val, keys }) {
      if (keys == "status") {
        this.status = val;
      }
      // 处理是否是附属项目key
      if (keys == "ptype") {
        this.formData.forEach((item) => {
          if (val == 3 && item.key == "isAttached") {
            item.decorator.forEach((items) => {
              if (items instanceof Object) {
                items.rules[0].required = true;
              }
            });
          } else if (item.key == "isAttached") {
            item.decorator.forEach((items) => {
              if (items instanceof Object) {
                items.rules[0].required = false;
              }
            });
          }
        });
      }
      if (keys == "isReport") {
        // 处理选择框 val值
        this.isReport = val;
        this.formData.forEach((item) => {
          if (item.key == "projectNum") {
            if (val == 1) {
              item.label = "项目代码";
            } else {
              item.label = "工程编码";
            }
          }
          if (this.isReport == 1 && this.status == 4) {
            this.requireds(true);
          } else {
            this.requireds(false);
          }
        });
      }
      this.projectList.forEach((item) => {
        if (keys == "xmjl" && item.id == val) {
          this.$refs["formindex"].setFieldsValue(
            val,
            { xmjlPhone: item.mobile },
            "userId"
          );
          this.$refs["formindex"].setFieldsValue(
            item.nickname,
            { xmjl: item.nickname },
            "xmjl"
          );
        } else if (keys == "projectName") {
          this.$refs["formindex"].setFieldsValue(val, {}, "projectId");
          this.$refs["formindex"].setFieldsValue("", {
            lanAndLat: `${item.latitude},${item.longitude}`,
          });
        } else if (keys === "buildOfficeName") {
          this.$refs["formindex"].setFieldsValue(val, {}, "buildOffice");
        } else if (keys === "effcOfficeName") {
          this.$refs["formindex"].setFieldsValue(val, {}, "effcOffice");
        }
      });
      if (keys == "status" && val == 4 && this.isReport == 1) {
        this.requireds(true);
      } else if (keys == "status" && val != 4) {
        this.requireds(false);
      }
      if (keys == 'monitorDisable') {
        // 重点监控项目
        this.setMonitor('monitorReason', val)
      }
    },
    requireds(bool) {
      this.formData.forEach((item) => {
        if (item.key == "pjsDate" || item.key == "pjsPath") {
          item.decorator.forEach((items) => {
            if (items instanceof Object) {
              items.rules[0].required = bool;
            }
          });
        } else if (item.key == "status") {
          item.selectlist.forEach((items) => {
            if (items.name == "完工" && this.query.type == "list") {
              items.value = 1;
            }
          });
        }
      });
    },
    setMonitor(key, val) {
      this.formData.forEach((item) => {
        if (item.key === key) {
          if (val == 0) {
            item.decorator.forEach((items) => {
              if (items instanceof Object) {
                items.rules[0].required = true;
              }
            })
            item.display = true
          } else {
            item.decorator.forEach((items) => {
              if (items instanceof Object) {
                items.rules[0].required = false;
              }
            });
            item.display = false
          }
        }
      });
    },
    init() {
      const tenantId = session.get('Tenant-Id') ? session.get('Tenant-Id') : '1'
      if (tenantId == '1') {
        this.formData = cloneDeep(jsFormData)
      } else if (tenantId == '172') {
        this.formData = cloneDeep(commonFormData)
        //如果是项目经理或者安全员
        if (this.user.nowRoleKey.some(role => this.projectRoles.includes(role))) {
          this.formData.map(el => {
            // el.disabled = true
            if (el.key == 'gcglbId') {
              el.display = false
            }
            if (el.key == 'gcglbName') {
              el.disabled = true
            }
            // if (el.key == 'xxjd' || el.key == 'projDesc' || el.key == 'projAddress' || el.key == 'lanAndLat') {
            //   el.disabled = false
            // }
          })
        }
      } else {
        this.formData = cloneDeep(commonFormData)
        //如果是项目经理或者安全员
        if (this.user.nowRoleKey.some(role => this.projectRoles.includes(role))) {
          this.formData.map(el => {
            el.disabled = true
            if (el.key == 'gcglbId') {
              el.display = false
            }
            if (el.key == 'xxjd' || el.key == 'projDesc' || el.key == 'projAddress' || el.key == 'lanAndLat') {
              el.disabled = false
            }
          })
        }
      }

      // 回显officeList
      if (this.$route.query.data.officeList && this.$route.query.data.officeList.length > 0) {
        this.officeList = this.$route.query.data.officeList
      }
      // this.$refs["formindex"].queryObj({ projectAddress: [record.province, record.city, record.county] })
      this.query = this.$route.query;
      const record = this.$route.query.data
      // 回显工程地址省市区
      this.$route.query.data.projectAddress = [
        `${record.province}`,
        `${record.city}`,
        `${record.county}`,
      ];
      this.isReport = this.query.data.isReport;
      this.statsu = this.query.data.status;
      this.random = this.query.random;
      this.setMonitor('monitorReason', this.query.data.monitorDisable)
      if (this.query.data.latitude && this.query.data.longitude) {
        let lanAndLat = `${this.query.data.latitude},${this.query.data.longitude}`;
        let queryParams = {
          longitude: Number(this.query.data.longitude),
          latitude: Number(this.query.data.latitude)
        }
        this.query.data.lanAndLat = lanAndLat
        // 赋值地图坐标点
        setTimeout(() => {
          let point = new this.$refs["formindex"].BMap.Point(Number(this.query.data.longitude), Number(this.query.data.latitude))
          this.$refs["formindex"].map.centerAndZoom(point, 14)
          this.$refs["formindex"].queryParams = queryParams
        }, 2000)
      }
      console.log(this.random)
      this.formData.forEach((item) => {
        if (item.type == "inputGroup") {
          item.dataSource = [];
        }
        // 报建=项目代码 不保健=工程编码
        if (item.key == "projectNum") {
          if (this.query.data.isReport == 1) {
            item.label = "项目代码";
          } else {
            item.label = "工程编码";
          }
        }
      });
      if (this.query.data.status == 4) {
        this.requireds(true);
      } else {
        this.requireds(false);
      }
      if (this.query.data && this.query.data.pjsPath) {
        const paths = this.query.data.pjsPath.split(',')
        const defaultFileList = []
        paths.forEach((path, index) => {
          defaultFileList.push({
            uid: index,
            name: "安全评价书",
            url: path,
            key: "pjsPath",
            type: 'Upload'
          })
        })
        this.defaultFileList = defaultFileList
      }
      if (this.query.type == "list") {
        this.formData.forEach((item) => {
          item.display = true;
          // 处理完工在线一览表进入详情 type== list 隐藏创优选项
          if (item.key == 'cyyq' || item.key == 'cylevel' || item.key == 'safeDutyDisable') {
            item.display = false;
          }
        });
      } else {
        this.formData.forEach((item) => {
          if (this.isMonitor && item.key == 'monitorDisable') {
            item.disabled = false
          }
          if (item.disType) {
            item.display = false;
          }
          if (item.disType && item.disType == "list") {
            item.display = true;
          }
        });
      }

      this.getgcglbList(PROJECT_OFFICE_CHILD + "/OFFICE_CHILD");
    }
  },
  activated() {
    this.init()
  },
};
</script>
<style lang="less" scoped>
.create_index {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-bottom: 10px;
}
</style>
