<template>
  <div class="a_Approval">
    <a-card>
      <h3>{{ $route.query.projectName }}</h3>
      <standard-table
        class=""
        :columns="columns"
        :dataSource="tableData"
        rowKey="index"
        :loading="tableLoading"
        :isAlert="false"
        :bordered="true"
      >
        <template slot="status" slot-scope="{ record }">
          <span v-if="record.status == 0">待提交</span>
          <span v-else-if="record.status == 1">已提交</span>
          <span v-else-if="record.status == 2">通过</span>
          <span v-else-if="record.status == 3">驳回</span>
        </template>
        <!-- 证件 -->
        <template slot="cerFilePath" slot-scope="{ text }">
          <div class="flex a-center j-center" style="height: 40px">
            <div v-if="text != '' && text != null">
              <img
                :src="text"
                style="width: 40px; height: 40px; cursor: pointer"
                @click="showPreview(text)"
              />
            </div>
            <div v-if="!text">暂无图片</div>
          </div>
        </template>
        <!-- <template slot="action" slot-scope="{ record }">
          <a @click="() => handleCheck(record)" > 查看 </a>
        </template> -->
      </standard-table>
    </a-card>

    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>
  
  <script>
  import {
    listWithProject,
  } from "@/services/projectDutyStructure";
  import { mapGetters } from "vuex";
  import StandardTable from "@/components/table/StandardTable";
  import moment from "moment";
  const columns = [
    {
      title: "岗位",
      align: "center",
      dataIndex: "roleName",
      width: 300,
    },
    {
      title: "姓名",
      dataIndex: "userName",
      ellipsis: true,
      align: "center",
      width: 300,
    },
    {
      title: "联系方式",
      dataIndex: "phone",
      align: "center",
      width: 300,
    },
    {
      title: "证件",
      dataIndex: "cerFilePath",
      scopedSlots: { customRender: "cerFilePath" },
      align: "center",
    },
    {
      title: "备注",
      dataIndex: "remark",
      scopedSlots: { customRender: "remark" },
      align: "center",
    },
    // {
    //   title: "操作",
    //   align: "center",
    //   fixed: "right",
    //   width: 120,
    //   scopedSlots: { customRender: "action" },
    // },
  ];
  
  export default {
    components: {
      StandardTable
    },
    data() {
      return {
        params: {
          projectId: ''
        },
        //这个页面不用分页
        // pagination: {
        //   //分页数据
        //   current: 1,
        //   pageSize: 10,
        //   total: 0,
        //   showTotal: (total, range) => `共 ${total} 条`,
        // },
        // total: 0,
        columns: columns,
        tableData: [],
        tableLoading: false, //table加载中
        previewVisible: false,
        previewImage: "",
      };
    },
    activated() {
      this.params.projectId = this.$route.query.projectId;
      this.handleQuery();
    },
    computed: {
      ...mapGetters("account", ["user"]),
    },
    methods: {
      handleQuery() {
        this.tableLoading = true
        listWithProject(this.params).then((res) => {
          this.tableLoading = false
          let d = res.data;
          if (d.code === 0) {
            this.tableData = d.data
            // this.pagination.total = d.total;
            // this.total = d.total;
          } else {
            this.$message.error(res.data.msg)
          }
        });
      },
      showPreview(imgPath) {
        this.previewVisible = true;
        this.previewImage = imgPath;
      },
      // handleCheck(item) {
      //   this.$router.push({
      //     name: `岗位详情`,
      //     query: {
      //       id: item.id
      //     },
      //   });
      // },
    },
  };
  </script>
  
  <style lang="less" scoped>
  @import "@/theme/common.less";
  .query-form {
    display: flex;
    // justify-content: space-between;
  }
  </style>
  