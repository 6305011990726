var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-card", { attrs: { bordered: false } }, [
    _c(
      "div",
      { staticClass: "card-container" },
      [
        _c(
          "a-tabs",
          {
            on: { change: _vm.handeleTab },
            model: {
              value: _vm.activeTab,
              callback: function ($$v) {
                _vm.activeTab = $$v
              },
              expression: "activeTab",
            },
          },
          [
            _c(
              "a-tab-pane",
              {
                key: "1",
                scopedSlots: _vm._u([
                  {
                    key: "tab",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "post-status" },
                          [
                            false
                              ? _c("a-icon", {
                                  attrs: {
                                    type: "check-circle",
                                    theme: "twoTone",
                                    "two-tone-color": "#52c41a",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" 企业认证 "),
                            _vm.isActive
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "status",
                                    class: { active: _vm.isActive },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.isActive ? "已完成" : "处理中"
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c(
                  "a-form-model",
                  {
                    ref: "ruleForm",
                    staticStyle: { "margin-top": "40px" },
                    attrs: {
                      model: _vm.form,
                      rules: _vm.rules,
                      "label-col": _vm.labelCol,
                      "wrapper-col": _vm.wrapperCol,
                    },
                  },
                  [
                    _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: "统一社会信用代码",
                          prop: "officeCode",
                        },
                      },
                      [
                        _c("a-input", {
                          model: {
                            value: _vm.form.officeCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "officeCode", $$v)
                            },
                            expression: "form.officeCode",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "企业名称", prop: "officeName" } },
                      [
                        _c("a-input", {
                          model: {
                            value: _vm.form.officeName,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "officeName", $$v)
                            },
                            expression: "form.officeName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "flex-container",
                    staticStyle: { "flex-direction": "column" },
                  },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.onSubmit },
                      },
                      [_vm._v(" 申请认证 ")]
                    ),
                    _c("div", { staticClass: "mt-20" }, [
                      _vm._v("点击下方链接完成认证"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "a-tab-pane",
              {
                key: "2",
                scopedSlots: _vm._u([
                  {
                    key: "tab",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "post-status" },
                          [
                            _c("a-icon", {
                              attrs: {
                                type: "check-circle",
                                theme: "twoTone",
                                "two-tone-color": "#52c41a",
                              },
                            }),
                            _vm._v(" 公章签署协议 "),
                            !_vm.authStatus.isAuto
                              ? _c("div", { staticClass: "status" }, [
                                  _vm._v("未完成"),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "flex-container",
                    staticStyle: { "flex-direction": "column" },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "a-upload",
                          {
                            staticClass: "ts-upload-box",
                            attrs: {
                              accept: _vm.accept,
                              customRequest: _vm.resourceUpload,
                              beforeUpload: _vm.beforeUpload,
                              "list-type": "picture-card",
                              "show-upload-list": false,
                            },
                          },
                          [
                            [
                              _vm.imageUrl
                                ? _c(
                                    "div",
                                    [
                                      _c("img", {
                                        staticClass: "ts-img",
                                        attrs: {
                                          src: _vm.imageUrl,
                                          alt: "avatar",
                                        },
                                      }),
                                      _vm.imageUrl
                                        ? _c(
                                            "a-button",
                                            {
                                              staticClass: "ts-button",
                                              attrs: { type: "link" },
                                            },
                                            [
                                              _c("a-icon", {
                                                attrs: { type: "reload" },
                                              }),
                                              _vm._v("重新上传"),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    [_c("a-icon", { attrs: { type: "plus" } })],
                                    1
                                  ),
                            ],
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    !_vm.imageUrl ? _c("span", [_vm._v("上传公章")]) : _vm._e(),
                    _c("p", {
                      staticClass: "mt-20",
                      staticStyle: { color: "#999999" },
                      domProps: { innerHTML: _vm._s(_vm.text) },
                    }),
                    _c(
                      "a-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.onSubmit },
                      },
                      [_vm._v(" 申请公章签署协议 ")]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "a-tab-pane",
              {
                key: "3",
                scopedSlots: _vm._u([
                  {
                    key: "tab",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "post-status" },
                          [
                            _c("a-icon", {
                              attrs: {
                                type: "check-circle",
                                theme: "twoTone",
                                "two-tone-color": "#52c41a",
                              },
                            }),
                            _vm._v(" 合同模板 "),
                            !_vm.authStatus.yyzzPath
                              ? _c("div", { staticClass: "status" }, [
                                  _vm._v("未上传"),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "flex-container",
                    staticStyle: { "flex-direction": "column" },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "a-upload",
                          {
                            staticClass: "ts-upload-box",
                            attrs: {
                              accept: _vm.accept,
                              customRequest: _vm.resourceUpload,
                              beforeUpload: _vm.beforeUploadDocx,
                              "list-type": "picture-card",
                              "show-upload-list": false,
                            },
                          },
                          [
                            [
                              _vm.imageUrl
                                ? _c(
                                    "div",
                                    [
                                      _c("img", {
                                        staticClass: "ts-img",
                                        attrs: {
                                          src: _vm.imageUrl,
                                          alt: "avatar",
                                        },
                                      }),
                                      _vm.imageUrl
                                        ? _c(
                                            "a-button",
                                            {
                                              staticClass: "ts-button",
                                              attrs: { type: "link" },
                                            },
                                            [
                                              _c("a-icon", {
                                                attrs: { type: "reload" },
                                              }),
                                              _vm._v("重新上传"),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    [_c("a-icon", { attrs: { type: "plus" } })],
                                    1
                                  ),
                            ],
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    !_vm.imageUrl ? _c("span", [_vm._v("上传合同")]) : _vm._e(),
                    _c(
                      "p",
                      {
                        staticClass: "mt-20",
                        staticStyle: { color: "#999999" },
                      },
                      [
                        _vm._v(
                          "合同模板可以包含以下内容：工人劳动合同、工人入场须知等"
                        ),
                      ]
                    ),
                    _c(
                      "a-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.onSubmit },
                      },
                      [_vm._v(" 上传合同模板 ")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }