var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        "max-width": "500px",
        margin: "40px auto 0",
        "text-align": "center",
      },
    },
    [
      _c("result", { attrs: { title: _vm.$t("success"), "is-success": true } }),
      _c(
        "a-button",
        { attrs: { type: "primary" }, on: { click: _vm.doOnceAgin } },
        [_vm._v(_vm._s(_vm.$t("doAgain")))]
      ),
      _c("a-button", { staticStyle: { "margin-left": "8px" } }, [
        _vm._v(_vm._s(_vm.$t("bill"))),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }