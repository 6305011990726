var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a_Approval" },
    [
      _c("div", { staticClass: "plug-Overview" }, [_vm._v("月报汇总台账")]),
      _c("a-divider"),
      _c(
        "a-form-model",
        {
          staticClass: "mb-2 query-form",
          attrs: { layout: "inline", model: _vm.params },
          on: { submit: _vm.handleSubmit },
        },
        [
          _c(
            "a-form-model-item",
            { staticClass: "ml-12", attrs: { label: "月度" } },
            [
              _c(
                "a-month-picker",
                {
                  staticStyle: { width: "220px" },
                  attrs: { placeholder: "请选择月份", format: "YYYY-MM" },
                  model: {
                    value: _vm.monthPickerVal,
                    callback: function ($$v) {
                      _vm.monthPickerVal = $$v
                    },
                    expression: "monthPickerVal",
                  },
                },
                [
                  _c("a-icon", {
                    attrs: { slot: "suffixIcon", type: "smile" },
                    slot: "suffixIcon",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      staticClass: "mr-9",
                      attrs: { type: "primary", "html-type": "submit" },
                    },
                    [_vm._v(" 查询 ")]
                  ),
                  _c(
                    "a-button",
                    { staticClass: "mr-9", on: { click: _vm.resetQuery } },
                    [_vm._v(" 重置 ")]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "pointer ml-3",
                      staticStyle: {
                        color: "#2BA4FF",
                        "border-bottom": "1px solid #2BA4FF",
                      },
                      on: { click: _vm.uploadRecord },
                    },
                    [_vm._v("未确认记录台账")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("standard-table", {
        attrs: {
          columns: _vm.columns,
          dataSource: _vm.tableData,
          rowKey: "id",
          pagination: _vm.pagination,
          loading: _vm.tableLoading,
          isAlert: false,
          bordered: true,
        },
        on: { change: _vm.handleChangePage },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function (ref) {
              var index = ref.index
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      (_vm.pagination.current - 1) * _vm.pagination.pageSize +
                        parseInt(index) +
                        1
                    )
                  ),
                ]),
              ]
            },
          },
          {
            key: "yearMonth",
            fn: function (ref) {
              var record = ref.record
              return [
                _c("span", [
                  _vm._v(_vm._s(record.year) + "-" + _vm._s(record.month)),
                ]),
              ]
            },
          },
          {
            key: "action",
            fn: function (ref) {
              var text = ref.text
              var record = ref.record
              return _c("div", {}, [
                _c(
                  "a",
                  {
                    on: {
                      click: function () {
                        return _vm.doDowloadPre(record)
                      },
                    },
                  },
                  [_vm._v(" 下载资料 ")]
                ),
              ])
            },
          },
        ]),
      }),
      _c(
        "a-modal",
        {
          attrs: {
            title: "未确认记录台账",
            visible: _vm.uploadRecordVisible,
            width: 1400,
            footer: null,
          },
          on: { cancel: _vm.handleCancelUploadRecord },
        },
        [
          _c(
            "a-tabs",
            { attrs: { "default-active-key": _vm.defaultActiveKey } },
            [
              _c(
                "a-tab-pane",
                { key: "1", attrs: { tab: "危险源一览表" } },
                [_c("danger-source-report-modal")],
                1
              ),
              _c(
                "a-tab-pane",
                { key: "2", attrs: { tab: "危大工程情况表", forceRender: "" } },
                [_c("wd-hidden-danger-static-modal")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }