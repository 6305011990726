<template>
  <div class="platform">
    <div class="a_a_header mt-1">
      <form-index
        ref="formindex"
        :formData="formData"
        @handleSubmit="handleSubmit"
        :flexSubmit="true"
        @handlSelect="handlSelect"
      >
        <template #flexSubmit>
          <a-space :style="{ marginTop: '4px' }">
           <a-button class="flexSubmit" type="primary" html-type="submit"> 查询 </a-button>
           <a-button @click="close"> 重置 </a-button>
          </a-space>
        </template>
      </form-index>
    </div>
    <div class="flex a-center mb-1">
      <div>处理时限说明: </div>
      <div class="status-box flex">
        <div class="statusDrop">剩余1天</div>
        <div class="statusDrop before-second">剩余2-3天</div>
        <div class="statusDrop before-third">剩余>3天</div>
        <div class="statusDrop before-fourth">已超时</div>
      </div>
    </div>
    <!-- 表格封装 -->
    <standard-table
      class="close-table"
      :columns="columns"
      :dataSource="dataSource"
      rowKey="id"
      :pagination="pagination"
      :isAlert="false"
      :bordered="true"
      @change="onPageChange"
    >
      <template slot="index" slot-scope="{ index }">
        {{
            (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1
          }}
      </template>
      <template slot="bizType" slot-scope="{ text }">
        {{ text == 1 ? "实名制" : "工资分账" }}
      </template>
      <template slot="eventName" slot-scope="{ text }">
        {{ text }}
      </template>
      <template slot="warmCode" slot-scope="{ text, record }">
        <template v-if="text">
        <span
          class="statusDrop"
          :class="textStyle(record)"
        >
          {{ text }}
        </span>
      </template>

      </template>
      <template slot="status" slot-scope="{ text, record }">
        <span
          class="statusDrop"
          :class="textStyle(record)"
        >
          {{ text | statusStr }}
        </span>
      </template>
      <!-- ellipsis-1 -->
      <template slot="overtext" slot-scope="{ text }">
        <span class="overtext-1">{{ text }}</span>
      </template>
      <!-- 生成时间 -->
      <template slot="warmDate" slot-scope="{ text }">
        <span class="overtext-1">{{ useRender.renderDate(text) }}</span>
      </template>
      <div slot="action" slot-scope="{ record }">
        <a-button type="link" @click="toHandle(record)"
          >去处理</a-button
        >
      </div>
    </standard-table>
  </div>
</template>

<script>
import { useRender } from '@/hooks/useRender.js'
const formData = [
  // {
  //   label: "预警时间",
  //   placeholder: "请选择时间段",
  //   decorator: [
  //     "applyDate",
  //     { rules: [{ required: false, message: "请选择时间段" }] },
  //   ],
  //   type: "rangePicker",
  //   key: "applyDate",
  //   col: 8,
  //   labelCol: 3,
  //   wrapperCol: 24,
  //   display: true,
  //   format: "yyyy-MM-DD",
  // },
  {
    label: "处理状态",
    placeholder: "请选择",
    decorator: [
      "status",
      { rules: [{ required: false, message: "请选择" }] },
    ],
    type: "select",
    selectlist: [{
      value: 0,
      name: '未处理'
    },
    {
      value: 1,
      name: '处理中'
    },
    {
      value: 2,
      name: '已处理'
    },
    {
      value: 3,
      name: '已超时'
    },
  ],
    key: "isReportInfo",
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
];
const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    align: "center",
  },
  {
    title: "预警单号",
    dataIndex: "jwWarnOddNum",
    align: "center",
    scopedSlots: { customRender: "overtext" },
  },
  {
    title: "预警(编号)名称",
    dataIndex: "name",
    align: "center",
    scopedSlots: { customRender: "overtext" },
  },
  {
    title: "生成时间",
    dataIndex: "warmDate",
    align: "center",
    scopedSlots: { customRender: "warmDate" },
  },
  {
    title: "处理状态",
    dataIndex: "status",
    align: "center",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "处理时限",
    dataIndex: "timeoutTime",
    align: "center",
    scopedSlots: { customRender: "warmCode" },
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
import { mapGetters } from "vuex";
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import { getProWarnList } from "@/services/projectManagement";
export default {
  components: {
    formIndex,
    StandardTable,
  },
  data() {
    return {
      formData: formData, //form表单数据
      columns: columns, //table数据key对应
      dataSource: [], //table数组
      pagination: {
        showSizeChanger:true,
        pageSizeOptions: ['10', '20', '30', '50'],
        showLessItems:true,
        showQuickJumper:true,
        //分页数据
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total, range) => `共 ${total} 条`
      },
      prams:{
        pageNumber: 1,
        pageSize: 10,
      },
      tableLoading: false,
      useRender
    };
  },
  filters: {
    statusStr(val) {
      switch (val) {
        case 0:
          return "待处理";
        case 1:
          return "处理中";
        case 2:
          return "已处理";
        case 3:
          return "已超时";
      }
    },
  },
  computed: {
    ...mapGetters("account", ["user"]),
    textStyle() {
      return function (val) {
        if (val.status == 0) {
          return "";
        } else if (val.status == 1) {
          return "before-second";
        } else if (val.status == 2) {
          return "before-third";
        } else if (val.status == 3) {
          return "before-fourth";
        }
      };
    },
  },
  mounted() {
    this.prams.projectId = this.user.projectId
    this.aqxjlist(this.prams);
  },

  methods: {
    updateSelectRows(e) {
      console.log(e);
    },
    toHandle(e) {
      console.log(e);
      this.$router.push({
        name: "预警机器人",
        query: {
          data: e
        },
      });
    },
    aqxjlist(prams) {
      this.tableLoading = true;
      // 建委平台预警
      getProWarnList(prams).then(res => {
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
        }
        this.tableLoading = false;
      }).catch((e) => {
          this.tableLoading = false;
          this.$message.error("获取预警列表失败",2);
        });
    },
    close() {
      this.prams = {
        projectId: this.user.projectId,
        pageNumber: 1,
        pageSize: 10,
      },
      this.pagination.current = 1
      this.aqxjlist(this.prams);
      this.$refs["formindex"].resetFields();
    },
    handlSelect(obj) {
      // const { val, keys } = obj
      // if (keys === 'officeId') {
      //   this.doQueryTeams({ officeId: val })
      // }
    },
    // 提交表单数据 查询
    handleSubmit(e) {
      let obj = { ...e, ...this.prams }
      this.aqxjlist(obj);
    },
    //表格 - 分页查询
    onPageChange(e) {
      // let obj = this.$refs["formindex"].getFieldValue();
      // if (obj.status == "") {
      //   obj.status = 0;
      // }
      this.prams.pageNumber = e.current;
      this.pagination.current = e.current;
      this.prams.pageSize = e.pageSize;
      this.pagination.pageSize = e.pageSize;
      this.aqxjlist(this.prams);
      // this.aqxjlist(Object.assign({}, this.prams, obj));
    },
  },
};
</script>

<style lang="less" scoped>
.platform {
  color: #000000;
  font-family: Microsoft YaHei;
  font-size: 14px;
  .status-box {
    color: #4d4d4d;
  }
  // 预警状态
  .statusDrop::before {
    width: 8px;
    height: 8px;
    background: #ee6945;
    content: "";
    display: inline-block;
    border-radius: 50%;
    margin-left: 15px;
    margin-right: 3px;
  }
  .before-second::before {
    background: #e5a858;
  }
  .before-third::before {
    background: #6ccc51;
  }
  .before-fourth::before {
    background: #656262;
  }
}
</style>