var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    [
      _c(
        "a-form-model",
        {
          attrs: {
            model: _vm.projectConfig,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "入场模式" } },
            [
              _c(
                "a-radio-group",
                {
                  model: {
                    value: _vm.projectConfig.rcMode,
                    callback: function ($$v) {
                      _vm.$set(_vm.projectConfig, "rcMode", $$v)
                    },
                    expression: "projectConfig.rcMode",
                  },
                },
                [
                  _c("a-radio", { attrs: { value: "1" } }, [
                    _vm._v(" 详细模式 "),
                  ]),
                  _c("a-radio", { attrs: { value: "2" } }, [
                    _vm._v(" 简洁模式 "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "是否上报建委" } },
            [
              _c("a-switch", {
                attrs: {
                  "checked-children": "是",
                  "un-checked-children": "否",
                },
                on: { change: _vm.pushJwChange },
                model: {
                  value: _vm.projectConfig.pushJw,
                  callback: function ($$v) {
                    _vm.$set(_vm.projectConfig, "pushJw", $$v)
                  },
                  expression: "projectConfig.pushJw",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "是否上报重点办" } },
            [
              _c("a-switch", {
                attrs: {
                  "checked-children": "是",
                  "un-checked-children": "否",
                },
                on: { change: _vm.pushZdbChange },
                model: {
                  value: _vm.projectConfig.pushZdb,
                  callback: function ($$v) {
                    _vm.$set(_vm.projectConfig, "pushZdb", $$v)
                  },
                  expression: "projectConfig.pushZdb",
                },
              }),
            ],
            1
          ),
          _vm.projectConfig.pushZdb === 1
            ? _c(
                "a-form-model",
                {
                  attrs: {
                    model: _vm.zdbContent,
                    "label-col": _vm.labelCol,
                    "wrapper-col": _vm.wrapperCol,
                  },
                },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "项目ID" } },
                    [
                      _c("a-input", {
                        model: {
                          value: _vm.zdbContent.projectId,
                          callback: function ($$v) {
                            _vm.$set(_vm.zdbContent, "projectId", $$v)
                          },
                          expression: "zdbContent.projectId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "appid" } },
                    [
                      _c("a-input", {
                        model: {
                          value: _vm.zdbContent.appid,
                          callback: function ($$v) {
                            _vm.$set(_vm.zdbContent, "appid", $$v)
                          },
                          expression: "zdbContent.appid",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "secret" } },
                    [
                      _c("a-input", {
                        model: {
                          value: _vm.zdbContent.secret,
                          callback: function ($$v) {
                            _vm.$set(_vm.zdbContent, "secret", $$v)
                          },
                          expression: "zdbContent.secret",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            { attrs: { label: "是否上报代建局" } },
            [
              _c("a-switch", {
                attrs: {
                  "checked-children": "是",
                  "un-checked-children": "否",
                },
                on: { change: _vm.pushDjjChange },
                model: {
                  value: _vm.projectConfig.pushDjj,
                  callback: function ($$v) {
                    _vm.$set(_vm.projectConfig, "pushDjj", $$v)
                  },
                  expression: "projectConfig.pushDjj",
                },
              }),
            ],
            1
          ),
          _vm.projectConfig.pushDjj === 1
            ? _c(
                "a-form-model",
                {
                  attrs: {
                    model: _vm.djjContent,
                    "label-col": _vm.labelCol,
                    "wrapper-col": _vm.wrapperCol,
                  },
                },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "项目ID" } },
                    [
                      _c("a-input", {
                        model: {
                          value: _vm.djjContent.projectId,
                          callback: function ($$v) {
                            _vm.$set(_vm.djjContent, "projectId", $$v)
                          },
                          expression: "djjContent.projectId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "accessKey" } },
                    [
                      _c("a-input", {
                        model: {
                          value: _vm.djjContent.accessKey,
                          callback: function ($$v) {
                            _vm.$set(_vm.djjContent, "accessKey", $$v)
                          },
                          expression: "djjContent.accessKey",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "accessNo" } },
                    [
                      _c("a-input", {
                        model: {
                          value: _vm.djjContent.accessNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.djjContent, "accessNo", $$v)
                          },
                          expression: "djjContent.accessNo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            { attrs: { label: "是否开启备用考勤" } },
            [
              _c("a-switch", {
                attrs: {
                  "checked-children": "是",
                  "un-checked-children": "否",
                },
                on: { change: _vm.jwbykqChange },
                model: {
                  value: _vm.projectConfig.jwbykq,
                  callback: function ($$v) {
                    _vm.$set(_vm.projectConfig, "jwbykq", $$v)
                  },
                  expression: "projectConfig.jwbykq",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model",
            {
              attrs: {
                model: _vm.jwbykqContent,
                "label-col": _vm.labelCol,
                "wrapper-col": _vm.wrapperCol,
              },
            },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "工程密钥" } },
                [
                  _c("a-input", {
                    model: {
                      value: _vm.jwbykqContent.gcms,
                      callback: function ($$v) {
                        _vm.$set(_vm.jwbykqContent, "gcms", $$v)
                      },
                      expression: "jwbykqContent.gcms",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "工程编码" } },
                [
                  _c("a-input", {
                    model: {
                      value: _vm.jwbykqContent.projectCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.jwbykqContent, "projectCode", $$v)
                      },
                      expression: "jwbykqContent.projectCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "接入编号" } },
                [
                  _c("a-input", {
                    model: {
                      value: _vm.jwbykqContent.accessNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.jwbykqContent, "accessNo", $$v)
                      },
                      expression: "jwbykqContent.accessNo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "进场设备" } },
                [
                  _c("a-input", {
                    model: {
                      value: _vm.jwbykqContent.enterClientId,
                      callback: function ($$v) {
                        _vm.$set(_vm.jwbykqContent, "enterClientId", $$v)
                      },
                      expression: "jwbykqContent.enterClientId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "出场设备" } },
                [
                  _c("a-input", {
                    model: {
                      value: _vm.jwbykqContent.leaveClientId,
                      callback: function ($$v) {
                        _vm.$set(_vm.jwbykqContent, "leaveClientId", $$v)
                      },
                      expression: "jwbykqContent.leaveClientId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { "wrapper-col": { span: 14, offset: 4 } } },
            [
              _c(
                "a-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.saveProjectConfig },
                },
                [_vm._v(" 保存 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }