var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-descriptions",
    { staticClass: "content-descriptions", attrs: { column: 3, bordered: "" } },
    [
      _c("a-descriptions-item", { attrs: { label: "发送人" } }, [
        _vm._v(" " + _vm._s(_vm.messageInfo.receiverName) + " "),
      ]),
      _c("a-descriptions-item", { attrs: { label: "发送时间" } }, [
        _vm._v(" " + _vm._s(_vm.messageInfo.createTime) + " "),
      ]),
      _c("a-descriptions-item", { attrs: { label: "通知类型" } }, [
        _vm._v(" " + _vm._s(_vm.messageInfo.typeText) + " "),
      ]),
      _c("a-descriptions-item", { attrs: { label: "内容", span: 3 } }, [
        _vm._v(" " + _vm._s(_vm.messageInfo.content) + " "),
      ]),
      _c("a-descriptions-item", { attrs: { label: "备注信息", span: 3 } }, [
        _vm._v(" " + _vm._s(_vm.messageInfo.remarks) + " "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }