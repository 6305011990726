var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { bordered: false } },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.spinning, tip: _vm.loadingText } },
        [
          _c(
            "div",
            { class: _vm.advanced ? "search" : null },
            [
              _c("a-form", { attrs: { layout: "horizontal" } }, [
                _c(
                  "div",
                  { class: _vm.advanced ? null : "fold" },
                  [
                    _c(
                      "a-row",
                      [
                        _c(
                          "a-col",
                          { attrs: { md: 4, sm: 24 } },
                          [
                            _c(
                              "a-form-item",
                              {
                                attrs: {
                                  label: "姓名",
                                  labelCol: { span: 4 },
                                  wrapperCol: { span: 18, offset: 1 },
                                },
                              },
                              [
                                _c("a-input", {
                                  attrs: { placeholder: "请输入" },
                                  model: {
                                    value: _vm.queryParams.userName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryParams, "userName", $$v)
                                    },
                                    expression: "queryParams.userName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { md: 5, sm: 24 } },
                          [
                            _c(
                              "a-form-item",
                              {
                                attrs: {
                                  label: "身份证号",
                                  labelCol: { span: 6 },
                                  wrapperCol: { span: 16, offset: 1 },
                                },
                              },
                              [
                                _c("a-input", {
                                  attrs: { placeholder: "身份证号" },
                                  model: {
                                    value: _vm.queryParams.idCard,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryParams, "idCard", $$v)
                                    },
                                    expression: "queryParams.idCard",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          {
                            style: { textAlign: "left", marginTop: "4px" },
                            attrs: { md: 5, sm: 24 },
                          },
                          [
                            _c(
                              "a-space",
                              [
                                _c(
                                  "a-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: { click: _vm.handleSubmit },
                                  },
                                  [_vm._v("查询")]
                                ),
                                _c(
                                  "a-button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.doReset()
                                      },
                                    },
                                  },
                                  [_vm._v("重置")]
                                ),
                                _c(
                                  "a-button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.doReports()
                                      },
                                    },
                                  },
                                  [_vm._v("批量上报")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "standard-table",
                {
                  attrs: {
                    columns: _vm.columns,
                    dataSource: _vm.dataSource,
                    selectedRows: _vm.selectedRows,
                    pagination: _vm.pagination,
                    loading: _vm.tableLoading,
                  },
                  on: {
                    "update:selectedRows": function ($event) {
                      _vm.selectedRows = $event
                    },
                    "update:selected-rows": function ($event) {
                      _vm.selectedRows = $event
                    },
                    clear: _vm.onClear,
                    change: _vm.onPageChange,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "remarks",
                      fn: function (ref) {
                        var text = ref.text
                        return _c("div", {}, [
                          _c("div", { domProps: { innerHTML: _vm._s(text) } }),
                        ])
                      },
                    },
                    {
                      key: "do-status",
                      fn: function (ref) {
                        var text = ref.text
                        return _c(
                          "div",
                          {},
                          [
                            text == 1
                              ? _c("a-icon", {
                                  staticStyle: { color: "green" },
                                  attrs: { type: "check" },
                                })
                              : _c("a-icon", {
                                  staticStyle: { color: "red" },
                                  attrs: { type: "close" },
                                }),
                          ],
                          1
                        )
                      },
                    },
                    {
                      key: "action",
                      fn: function (ref) {
                        var text = ref.text
                        var record = ref.record
                        return _c("div", {}, [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.doReport("" + record.key)
                                },
                              },
                            },
                            [_vm._v("重新上报")]
                          ),
                        ])
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "template",
                    { slot: "statusTitle" },
                    [
                      _c("a-icon", {
                        attrs: { type: "info-circle" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.onStatusTitleClick($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }