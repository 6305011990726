var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-drawer",
        {
          attrs: {
            width: 1244,
            visible: _vm.visible,
            title: "企业库班组信息",
            "body-style": { paddingBottom: "80px" },
          },
          on: { close: _vm.onClose },
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: 16 } },
            [
              _c(
                "a-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "vue-scroll",
                    {
                      staticStyle: { width: "100%", height: "855px" },
                      attrs: { ops: _vm.ops },
                    },
                    [
                      _c(
                        "a-card",
                        {
                          attrs: {
                            title: "工种",
                            size: "small",
                            bordered: false,
                          },
                        },
                        [
                          _c("a-tree", {
                            attrs: {
                              "load-data": _vm.onLoadData,
                              "tree-data": _vm.treeData,
                            },
                            on: { select: _vm.onSelect },
                            scopedSlots: _vm._u([
                              {
                                key: "title",
                                fn: function (ref) {
                                  var title = ref.title
                                  return [_c("span", [_vm._v(_vm._s(title))])]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 17, offset: 0 } },
                [
                  _c(
                    "a-card",
                    { attrs: { bordered: false } },
                    [
                      _c("template", { slot: "title" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.node.title) + "班组清单"),
                        ]),
                      ]),
                      _c("a-form", { attrs: { layout: "horizontal" } }, [
                        _c(
                          "div",
                          { class: _vm.advanced ? null : "fold" },
                          [
                            _c(
                              "a-row",
                              [
                                _c(
                                  "a-col",
                                  { attrs: { md: 12, sm: 24 } },
                                  [
                                    _c(
                                      "a-form-item",
                                      {
                                        attrs: {
                                          label: "班组名称",
                                          labelCol: { span: 5 },
                                          wrapperCol: { span: 18, offset: 1 },
                                        },
                                      },
                                      [
                                        _c("a-input", {
                                          attrs: {
                                            placeholder: "请输入班组名称",
                                          },
                                          model: {
                                            value: _vm.queryParam.teamName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.queryParam,
                                                "teamName",
                                                $$v
                                              )
                                            },
                                            expression: "queryParam.teamName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "a-col",
                                  { attrs: { md: 12, sm: 24 } },
                                  [
                                    _c(
                                      "a-form-item",
                                      {
                                        attrs: {
                                          label: "负责人",
                                          labelCol: { span: 5 },
                                          wrapperCol: { span: 18, offset: 1 },
                                        },
                                      },
                                      [
                                        _c("a-input", {
                                          attrs: {
                                            placeholder: "请负责人姓名",
                                          },
                                          model: {
                                            value: _vm.queryParam.fzrUserName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.queryParam,
                                                "fzrUserName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "queryParam.fzrUserName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "span",
                          { staticStyle: { float: "right" } },
                          [
                            _c(
                              "a-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.doQuery()
                                  },
                                },
                              },
                              [_vm._v("查询")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "a-row",
                        { staticStyle: { "margin-right": "-60px" } },
                        [
                          _c(
                            "a-col",
                            { attrs: { md: 24, sm: 24 } },
                            [
                              _c(
                                "standard-table",
                                {
                                  attrs: {
                                    columns: _vm.columns,
                                    dataSource: _vm.teams_data,
                                    selectedRows: _vm.selectedRows,
                                    pagination: _vm.pagination,
                                    loading: _vm.tableLoading,
                                    isAlert: false,
                                  },
                                  on: {
                                    "update:selectedRows": function ($event) {
                                      _vm.selectedRows = $event
                                    },
                                    "update:selected-rows": function ($event) {
                                      _vm.selectedRows = $event
                                    },
                                    clear: _vm.onClear,
                                    change: _vm.onPageChange,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "action",
                                      fn: function (ref) {
                                        var text = ref.text
                                        var record = ref.record
                                        return _c(
                                          "div",
                                          {},
                                          [
                                            _c("a-icon", {
                                              staticStyle: { color: "#FC5531" },
                                              attrs: {
                                                type: "unordered-list",
                                                title: "查看人员列表",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.viewPersons(record)
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      },
                                    },
                                  ]),
                                },
                                [
                                  _c(
                                    "template",
                                    { slot: "statusTitle" },
                                    [
                                      _c("a-icon", {
                                        attrs: { type: "info-circle" },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.onStatusTitleClick(
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              style: {
                position: "absolute",
                right: 0,
                bottom: 0,
                width: "100%",
                borderTop: "1px solid #e9e9e9",
                padding: "10px 16px",
                background: "#fff",
                textAlign: "right",
                zIndex: 1,
              },
            },
            [
              _c(
                "a-button",
                {
                  style: { marginLeft: "8px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.doSubmit },
                },
                [_vm._v(" 确定 ")]
              ),
              _c(
                "a-button",
                { style: { marginLeft: "8px" }, on: { click: _vm.onClose } },
                [_vm._v(" 关闭 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }