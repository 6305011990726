var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-card",
        { attrs: { bordered: false } },
        [
          _c(
            "div",
            [
              _c(
                "a-form",
                { attrs: { form: _vm.searchForm } },
                [
                  _c(
                    "a-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 5 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: "申诉人",
                                labelCol: { span: 5 },
                                wrapperCol: { span: 18, offset: 1 },
                              },
                            },
                            [
                              _c("a-input", {
                                staticStyle: { width: "180px" },
                                attrs: { placeholder: "申诉人" },
                                model: {
                                  value: _vm.searchForm.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "name", $$v)
                                  },
                                  expression: "searchForm.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: "状态",
                                labelCol: { span: 5 },
                                wrapperCol: { span: 18, offset: 1 },
                              },
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  model: {
                                    value: _vm.searchForm.status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "status", $$v)
                                    },
                                    expression: "searchForm.status",
                                  },
                                },
                                [
                                  _c(
                                    "a-select-option",
                                    { key: "", attrs: { value: "" } },
                                    [_vm._v(" 全部 ")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { key: "1", attrs: { value: "1" } },
                                    [_vm._v(" 待处理 ")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { key: "2", attrs: { value: "2" } },
                                    [_vm._v(" 处理中 ")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { key: "3", attrs: { value: "3" } },
                                    [_vm._v(" 已完成 ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { style: { marginTop: "3px" }, attrs: { span: 4 } },
                        [
                          _c(
                            "a-space",
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    icon: "search",
                                    loading: _vm.loading,
                                  },
                                  on: { click: _vm.handleSearch },
                                },
                                [_vm._v(" 查询 ")]
                              ),
                              _c(
                                "a-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.doReset()
                                    },
                                  },
                                },
                                [_vm._v(" 重置 ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("a-table", {
            attrs: {
              columns: _vm.columns,
              "data-source": _vm.data,
              pagination: _vm.pagination,
              loading: _vm.tableLoading,
              bordered: true,
              size: "middle",
            },
            on: { change: _vm.onPageChange },
            scopedSlots: _vm._u([
              {
                key: "action",
                fn: function (text, record) {
                  return [
                    _c("span", [
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.showRecord(record)
                            },
                          },
                        },
                        [_vm._v("处理申诉")]
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.visible,
            "confirm-loading": _vm.confirmLoading,
            width: "560px",
          },
          on: { ok: _vm.modelOk, cancel: _vm.modelCancel },
        },
        [
          _vm.visible
            ? _c(
                "a-form-model",
                {
                  attrs: {
                    model: _vm.record,
                    "label-col": _vm.labelCol,
                    "wrapper-col": _vm.wrapperCol,
                  },
                },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "姓名", prop: "name", required: "" } },
                    [
                      _c("a-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.record.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.record, "name", $$v)
                          },
                          expression: "record.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "申诉状态" } },
                    [
                      _c(
                        "a-select",
                        {
                          model: {
                            value: _vm.record.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.record, "status", $$v)
                            },
                            expression: "record.status",
                          },
                        },
                        [
                          _c(
                            "a-select-option",
                            { key: "1", attrs: { value: "1" } },
                            [_vm._v(" 待处理 ")]
                          ),
                          _c(
                            "a-select-option",
                            { key: "2", attrs: { value: "2" } },
                            [_vm._v(" 处理中 ")]
                          ),
                          _c(
                            "a-select-option",
                            { key: "3", attrs: { value: "3" } },
                            [_vm._v(" 已完成 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      ref: "resultReason",
                      attrs: {
                        label: "处理结果",
                        prop: "resultReason",
                        required: "",
                      },
                    },
                    [
                      _c("a-textarea", {
                        attrs: { placeholder: "请输入处理结果", rows: 4 },
                        model: {
                          value: _vm.record.resultReason,
                          callback: function ($$v) {
                            _vm.$set(_vm.record, "resultReason", $$v)
                          },
                          expression: "record.resultReason",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }