<template>
  <div class="docTable">
    <form-index
        ref="formindex"
        :formData="formData"
        :flexAction="true"
        :baiduMapBoolen="false"
        @handleSubmit="handleSubmit"
      >
        <!-- 下部分按钮插槽 -->
        <template #flexAction>
          <a-space :style="{ marginTop: '4px' }">
            <a-button icon="search" type="primary" html-type="submit">查询</a-button>
            <a-button @click="close"> 重置 </a-button>
          </a-space>
        </template>
      </form-index>
      <a-button v-if="canOperate" style="margin-right: 8px;" icon="plus" type="primary" @click="addVersion">新 增</a-button>
    <standard-table
        class="mt-1"
        :columns="columns"
        :dataSource="dataSource"
        rowKey="id"
        :pagination="pagination"
        :loading="tableLoading"
        @change="onPageChange"
        :isAlert="false"
        :bordered="true"
      >
        <template slot="index" slot-scope="{ index }">
          <span>
            {{
              (pagination.current - 1) * pagination.pageSize +
                parseInt(index) +
                1
            }}
          </span>
        </template>
        <!-- 通知类型 -->
        <template slot="type" slot-scope="{ text }">{{ VERSION_TYPE_ZHCN_MAP[text] }}</template>
        <div slot="action" slot-scope="{ record }">
          <div>
            <a @click="showVersion(record)">查看</a>
            <a-divider v-if="canOperate" type="vertical" />
            <a @click="editVersion(record)" v-if="canOperate">修改</a>
            <a-divider v-if="canOperate" type="vertical" />
            <a @click="delVersion(record)" v-if="canOperate" style="color: red;">删除</a>
          </div>
        </div>
      </standard-table>
    <a-modal :mask="true" centered v-model="versionMotal" :width="1050" :title="`#更新通知 ${version.vcode ? version.vcode : ''}`" @cancel="closeVersion" :footer="null">
        <p>{{moment(version.vtime).format('YYYY-MM-DD hh:mm:ss')}}</p>  
        <span v-html="version.vcontent"></span>
      </a-modal>
  </div>
</template>

<script>
import { getVersionHistroyList, getVersionHistroyDetail,delVersionHistroy } from '@/services/sys'
import StandardTable from "@/components/table/StandardTable";
import { mapGetters} from "vuex";
import formIndex from "@/pages/components/form/index";
import { VERSION_TYPE_ZHCN_MAP } from '@/enums/system'
import { useRender } from '@/hooks/useRender.js'
import moment from "moment";
export default {
  name: 'SuijianClientDocList',
  components: {StandardTable,formIndex},
  data() {
    const columns = [
      {
        title: "序号",
        width: 65,
        scopedSlots: { customRender: "index" },
        align: "center",
      },
      {
        title: "通知类型",
        dataIndex: "type",
        align: "center",
        scopedSlots: { customRender: "type" },
      },
      {
        title: "版本号",
        dataIndex: "vcode",
        align: "center",
        scopedSlots: { customRender: "vcode" },
      },
      {
        title: "发布时间",
        dataIndex: "vtime",
        align: "center",
        customRender: (text) => useRender.renderDate(text)
      },
      {
        title: "操作",
        align: "center",
        fixed: "right",
        width: 160,
        scopedSlots: { customRender: "action" },
      },
    ];
    const formData = [
      {
        label: "版本号",
        placeholder: "请填写版本号",
        decorator: [
          "vcode",
          { rules: [{ required: false, message: "请填写版本号" }] },
        ],
        type: "input",
        key: "vcode",
        col: 5,
        labelCol: 5,
        wrapperCol: 16,
        display: true,
      },
      {
        label: "发布时间",
        placeholder: "请选择发布时间",
        decorator: [
          "vtime",
          { rules: [{ required: false, message: "请选择发布时间" }] },
        ],
        type: "rangePicker",
        key: "vtime",
        col: 5,
        labelCol: 6,
        wrapperCol: 16,
        display: true,
        format: "yyyy-MM-DD",
      },
      {
        type: "action",
        col: 6,
        display: true,
      },

    ];
    return {
      columns,
      formData,
      versionMotal: false,
      version: {},
      dataSource: [],
      tableLoading: false,
      pagination: {
        //分页数据
        showSizeChanger:true,
        pageSizeOptions: ['10', '50', '100', '150'],
        current:1,
        pageSize:50,
        total:0,
        showLessItems:true,
        showQuickJumper:true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      params: {
        pageNumber: 1,
        pageSize: 50,
      },
      arrRoles:['admin'], // 权限
      VERSION_TYPE_ZHCN_MAP
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
    canOperate() {
      return this.user.nowRoleKey.some(role => this.arrRoles.includes(role));
    },
  },
  mounted() {
    this.getData(this.params)
  },

  methods: {
    moment,
    getData(params) {
      this.tableLoading = true
      // 系统版本发布-查询列表
      getVersionHistroyList(params).then(res => {
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list
        }
        this.tableLoading = false
      })
    },
   
    //表格 - 分页查询
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      if (obj.vtime) {
        obj.timeStart = obj.vtime[0]
        obj.timeEnd = obj.vtime[1]
      }
      obj.vtime = ''
      this.params.pageNumber = e.current;
      this.pagination.current = e.current;
      this.params.pageSize = e.pageSize;
      this.pagination.pageSize = e.pageSize;
      this.getData(Object.assign(obj, this.params));
    },
    // 重置
    close() {
      this.params = {
        pageNumber: 1,
        pageSize: 50,
      };
      this.pagination.current = 1;
      this.pagination.pageSize = 50;
      this.getData(this.params);
      this.$refs["formindex"].resetFields();
    },
    // 封装弹窗确定按钮
    confirm(title, content) {
      return new Promise((resolve, reject) => {
        this.$confirm({
          title: title,
          content: content,
          onOk() {
            resolve();
          },
        });
      });
    },
     showVersion(item) {
      getVersionHistroyDetail({id: item.id}).then(res => {
        if (res.data.code === 0 ) {
          this.versionMotal = true
          this.version = res.data.data
        }
      })
    },
    closeVersion(){
      this.versionMotal = false
      this.version = {}
    },
    delVersion(e) {
      let params = {
        id: e.id
      }
      const typeText = VERSION_TYPE_ZHCN_MAP[e.type]
      const version = e.vcode ? e.vcode : ''
      this.confirm("删除", `是否删除该${typeText} ${version}`).then(() => {
        delVersionHistroy(params).then((res) => {
          if (res.data.code === 0) {
            this.$message.success("删除成功", 5);
            this.getData(this.params)
          } else {
            this.$message.error(res.data.msg, 5);
          }
        });
      });
    },
    editVersion(e) {
      this.$router.push({
        name: "发布版本",
        query: {
          id: e.id,
        },
      });
    },
    // 提交表单数据
    handleSubmit(e) {
      let obj = Object.assign({}, e, this.params);
      if (obj.vtime) {
        obj.timeStart = obj.vtime[0]
        obj.timeEnd = obj.vtime[1]
      }
      delete obj.vtime
      obj.pageNumber = 1
      this.pagination.current = 1;
      this.getData(obj);
    },
    addVersion(){
      this.$router.push({
        name: "发布版本",
      });
    }
  },
};
</script>

<style lang="less" scoped>
.docTable {
  padding: 24px;
  background: #fff;
  border-radius: 6px;
  margin-bottom: 10px;
}
</style>