<template>
   <div class="export-loading" v-if="isExporting">
      <div class="export-con">
        <div class="export-title">{{loadingText}}</div> 
        <div>
          <a-progress
            :strokeWidth="15"
            stroke-linecap="square"
            :show-info="false"
            :percent="percent"
            status="active"
            :stroke-color="{
              from: '#108ee9',
              to: '#87d068',
            }"
            />
        </div>
      </div>
   </div>
</template>
<script>
export default {
  props:{
    cPercent: {
      type: Number,
      required: true
    },
    isExporting: {
      type: Boolean,
      required: true
    },
    loadingText: {
      type: String,
      required: false
    }
  },
  watch: {
    cPercent:{
      handler(newName, oldName) {
        this.percent=newName;
      },
      immediate: true
    }
  },
  data () {
    return {
     percent:0
    }
  },
}
</script>
<style lang="less" scoped>
.export-loading{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  width: 100%;
}
.export-con{
  width:380px;
  height:80px;
  padding:10px 15px 0px 20px;
  position:absolute;
  top:50%;
  left:50%;
  margin-top:-25px;
  margin-left:-190px;
  background:#fff;
  border-radius:5px;
  text-align:center;
  // .export-title{
  //   position:absolute;
  //   left:15px;
  //   top:13px;
  // }
  &/deep/ .ant-progress-inner{
    background-color: #d7d7d7;
  }
  // &/deep/ .ant-progress-line{
  //   width: 70%;
  // }
}
</style>