<template>
  <div>
    <standard-table
      class=""
      :columns="columns"
      :dataSource="dataSource"
      rowKey="id"
      :pagination="pagination"
      :loading="tableLoading"
      :isAlert="false"
      :bordered="true"
      @change="onPageChange"
    >
      <template slot="index" slot-scope="{ index }">
        <span>
          {{
            (pagination.current - 1) * pagination.pageSize +
              parseInt(index) +
              1
          }}
        </span>
      </template>
      <div slot="exitCertificate" slot-scope="{ text,record }">
          <div class="sTables" v-if="text">
            <div @click="doDowloadBatch(record)">下载凭证</div>
          </div>
      </div>
    </standard-table>
  </div>
</template>

<script>
import StandardTable from "@/components/table/StandardTable";
import {
  getstaffProjList,
} from "@/services/participationUnit";
import { useRender } from '@/hooks/useRender.js'

const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
  },
  {
    title: "项目名称",
    dataIndex: "projectName",
    align: "center",
  },
  {
    title: "所属单位",
    dataIndex: "officeName",
    align: "center",
  },
  {
    title: "所属班组",
    dataIndex: "teamName",
    align: "center",
  },
  {
    title: "职务/工种",
    dataIndex: "workType",
    align: "center",
  },
  {
    title: "劳动合同编号",
    dataIndex: "contractNo",
    align: "center",
    ellipsis: true,
    key: "contractNo",
  },
  {
    title: "进场日期",
    dataIndex: "enterDate",
    align: "center",
    key: "enterDate",
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "离场日期",
    dataIndex: "quitDate",
    align: "center",
    key: "quitDate",
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "离场凭证",
    dataIndex: "exitCertificate",
    align: "center",
    key: "exitCertificate",
    scopedSlots: { customRender: "exitCertificate" },
  },
  // {
  //   title: "操作",
  //   align: "center",
  //   fixed: "right",
  //   width: 200,
  //   scopedSlots: { customRender: "action" },
  // },
];
export default {
  components: {
    StandardTable
  },
  data() {
    return {
      columns: columns, //table数据key对应
      dataSource: [], //table数组
      pagination: {
        //分页数据
        current: 1,
        pageSize: 50,
        total: 0,
      },
      params: {
        //永远都是第一页
        pageNumber: 1,
        pageSize: 50,
        workNum: '',
      },
      tableLoading: false, //table加载中
      selectedRows: [], //选中的数据
    };
  },

  mounted() {
    this.params.workNum = this.$route.query.workNum
    this.getData()
  },

  methods: {
    getData() {
      this.tableLoading = true;
      getstaffProjList(this.params).then((res) => {
        this.tableLoading = false;
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    doDowloadBatch(record) {
      window.open(record.exitCertificate)
    },
    //表格 - 分页查询
    onPageChange(e) {
      this.params.pageNumber = e.current;
      this.pagination.current = e.current;
      this.params.pageSize = e.pageSize;
      this.pagination.pageSize = e.pageSize;
      this.getData();
    },
  },
};
</script>

<style lang="less" scoped>
.sTables {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      width: 100%;
      text-align: center;
      cursor: pointer;
    }

    div:nth-child(1) {
      color: #307dfa;
    }

    div:nth-child(2) {
      color: #ff5c77;
    }

    div:nth-child(3) {
      color: #615e83;
    }
  }
</style>