<template>
  <div class="a_Approval">
    <div class="plug-Overview">标准化评分汇总表</div>
    <a-divider />
    <a-form-model class="clearfix" @submit="handleSubmit">
      <a-col :span="5">
        <a-form-model-item label="选择年份" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
          <a-date-picker
            mode="year"
            v-model="teamYear"
            placeholder="请选择年份"
            :open="yearShowOne"
            format="YYYY"
            :allowClear="false"
            @openChange="openChangeOne"
            @panelChange="panelChangeOne"/>
        </a-form-model-item>
      </a-col>

      <a-col :span="4">
        <a-space class="btnBox">
          <a-button type="primary" html-type="submit"> 查询 </a-button>
          <a-button @click="resetQuery"> 重置 </a-button>
        </a-space>
      </a-col>
    </a-form-model>

    <div class="flex j-between">
      <div>
        <a-space v-if="radioVal > 0">
          <a-upload
            name="file"
            :multiple="false"
            :action="examineSeasonImport"
            :headers="headers"
            :data="{year, season: radioVal}"
            @change="uploadChange"
            :showUploadList="false"
          >
            <a-button type="primary" :loading="loading"> 导入 </a-button>
          </a-upload>
          <a-button type="link" @click="doDowload"> 下载导入模板 </a-button>
        </a-space>
      </div>
      
      <a-radio-group v-model="radioVal">
        <a-radio-button :value="0">
          总分表
        </a-radio-button>
        <a-radio-button :value="1">
          第一季度
        </a-radio-button>
        <a-radio-button :value="2">
          第二季度
        </a-radio-button>
        <a-radio-button :value="3">
          第三季度
        </a-radio-button>
        <a-radio-button :value="4">
          第四季度
        </a-radio-button>
      </a-radio-group>
    </div>

    <summary-table ref="summaryTable" v-show="radioVal == 0" :year="year"></summary-table>
    <season-table ref="seasonTable" v-show="radioVal > 0" :year="year" :season="radioVal"></season-table>
  </div>
</template>
  
<script>
import {
	examineSeasonImport
} from "@/services/safeExamine";
import { mapGetters } from "vuex";
import moment from "moment";
import SummaryTable from './components/summaryTable.vue';
import SeasonTable from './components/seasonTable.vue';
import { session } from '@/utils/storage'
import { xsrfHeaderName } from '@/utils/request.js'

export default {
  components: {
    SummaryTable,
    SeasonTable
  },
  data() {
    return {
      examineSeasonImport: examineSeasonImport,
      loading: false,
      headers: {
        Authorization: session.get(xsrfHeaderName),
        'Tenant-Id': session.get('Tenant-Id') ? session.get('Tenant-Id') : ''
      },
      radioVal: 0,
      year: moment(new Date()).format('YYYY'),
      teamYear: moment(new Date()).format('YYYY'),
      yearShowOne:false,
      total: 0,
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  methods: {
    uploadChange(info){
      this.loading = true;
      if (info.file.status === 'done') {
        const stat = info.file.response.code
        if (stat === 0){
          this.$refs.seasonTable.handleQuery()
          this.$message.success(info.file.response.msg)
        } else {
          this.$message.error(info.file.response.msg)
        }
        this.loading = false;
      }
    },
    doDowload() {
      const link = document.createElement('a');
      link.style.display = 'none';
      // link.href = 'https://safe.gz-suijian.com/examine_import.xlsx';
      link.href = 'https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20240725/1721877361734.xlsx?Expires=2037237361&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=mciMk%2Fm1pVxgc8MyzmUbtsARNU4%3D';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    openChangeOne(status) {
      if (status) {
        this.yearShowOne = true;
      }
    },
    // 得到年份选择器的值
    panelChangeOne(value) {
      this.yearShowOne = false;
      this.teamYear = value.format('YYYY');
    },
    handleSubmit(e) {
      e.preventDefault();
      this.year = this.teamYear
      if (this.radioVal == 0) {
        this.$refs.summaryTable.handleQuery()
      } else {
        this.$refs.seasonTable.handleQuery()
      }
    },
    resetQuery() {
      this.pagination.pageNumber = 1
      this.year = moment(new Date()).format('YYYY')
    },
  },
};
</script>
  
<style lang="less" scoped>
@import "@/theme/common.less";
.query-form {
  display: flex;
  // justify-content: space-between;
}
.post-border {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 5px;
  padding-top: 5px;
  // padding-top: 10px;
  // height: 40px;
}

.post-border:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
</style>
  