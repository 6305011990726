<template>
  <a-card>
    <a-spin :spinning="spinning" :tip="loadingText">
    <div :class="advanced ? 'search' : null">
      <a-form layout="horizontal">
        <div :class="advanced ? null: 'fold'">
          <a-row >
          <!-- <a-col :md="8" :sm="24" >
            <a-form-item
              label="项目名称"
              :labelCol="{span: 5}"
              :wrapperCol="{span: 18, offset: 1}"
            >
              <a-input placeholder="请输入" v-model="queryParams.projectName"/>
            </a-form-item>
          </a-col> -->
          <a-col :md="8" :sm="24" >
              <a-form-item
                label="检查时间"
                :labelCol="{span: 5}"
                :wrapperCol="{span: 18, offset: 1}"
              >
                <a-range-picker  v-model="queryParams.plan_date"
      :format="dateFormat"  @change="changeDate"/>
              </a-form-item>
            </a-col>
        </a-row>
        </div>
        <span style="float: right; margin-top: 3px;">
          <a-button type="primary" @click="doQuery()">查询</a-button>
          <a-button style="margin-left: 8px" @click="doReset()">重置</a-button>
        </span>
      </a-form>
    </div>
    <div>
      <a-space class="operator">
         <a-button type="primary" @click="doDowload()">导出EXCEL</a-button>
      </a-space>
      <!-- <standard-table
        :columns="columns"
        :dataSource="dataSource"
        :selectedRows.sync="selectedRows"
        :pagination="pagination"
        :loading="tableLoading"
        @clear="onClear"
        @change="onPageChange"
      >
        <div slot="action" slot-scope="{record}">
          <a v-if="`${record.cnt>0}`" @click="viewProject(`${record.key}`)">涉及项目</a>
        </div>
        <template slot="statusTitle">
          <a-icon @click.native="onStatusTitleClick" type="info-circle" />
        </template>
      </standard-table> -->
      <a-table
        :columns="columns"
        :dataSource="dataSource"
        childrenColumnName="childAqxjProblemsBig"
        :pagination="pagination"
        :selectedRows.sync="selectedRows">
        <span slot="customTitle"><a-icon type="smile-o" />隐患类型</span>
        <!-- <span slot="action" slot-scope="text, record, index">
          <a @click="handleEdit(record.key)">编辑</a>
        </span> -->
    </a-table>
    </div>
    <Export-loading :cPercent="cPercent" :isExporting="isExporting" :loadingText="loadingText"></Export-loading>
    </a-spin>
  </a-card>
</template>

<script>
// import StandardTable from '@/components/table/StandardTable'
import {
  OFFICE_TYPE_COUNT_QUERY, 
  OFFICE_TYPE_COUNT_EXPORT
  } from '@/services/api'
  
import { exportProgress } from '@/pages/aqjyzl/export' // 导出方法
import ExportLoading from '@/components/ExportLoading/ExportLoading' // progress组件
import {request} from '@/utils/request'
import {mapGetters} from 'vuex'
import moment from 'moment';
const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    align: 'center',
    width:80,
    scopedSlots: { customRender: 'index' }
  },
  {
    title: '隐患类型',
    dataIndex: 'name',
    align: 'center',
    scopedSlots: { customRender: 'name' }
  },
  {
    title: '隐患数量',
    dataIndex: 'cnt',
    align: 'center',
    scopedSlots: { customRender: 'cnt' }
  }
  // ,
  // {
  //   title: '操作',
  //   align: 'center',
  //   scopedSlots: { customRender: 'action' }
  // }
]

export default {
  mixins: [exportProgress],
  name: 'QueryList',
  components: {ExportLoading},
  data () {
    return {
      dataSource:[],
      advanced: false,
      columns: columns,
      selectedRows: [],
      tableLoading: false,
      spinning:false,
      loadingText:"数据加载中...",
      loadKeys:[],
      visible:false,
      dateFormat: 'YYYY-MM-DD',
      overDownIndex:0,
      firstDay:null,
      noeDay:null,
      queryParams:{
        projectName:null,
        plan_date:null,
        pageNumber:1,
        startTime:null,
        endTime:null
      },
      pagination:{
        current:1,
        pageSize:50,
        total:0,
        showLessItems:true,
        showQuickJumper:true
      },
      cPercent:0,
      isExporting:false,
    }
  },
  watch: {
    cPercent:{
      handler(newName, oldName) {
        this.cPercent=newName;
      },
      immediate: true,
    },
    loadingText:{
      handler(newName, oldName) {
        this.loadingText=newName;
      },
      immediate: true,
    }
  },
  created() {
    // this.firstDay = moment(new Date()).format('YYYY-MM')+"-01";
    // this.nowDay = moment(new Date()).format('YYYY-MM-DD');
    this.doQuery();
  },
  authorize: {
    deleteRecord: 'delete'
  },
  methods: {
    moment,
    deleteRecord(key) {
      this.dataSource = this.dataSource.filter(item => item.key !== key)
      this.selectedRows = this.selectedRows.filter(item => item.key !== key)
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    remove () {
      this.dataSource = this.dataSource.filter(item => this.selectedRows.findIndex(row => row.key === item.key) === -1)
      this.selectedRows = []
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },
    changeDate(e){
      if(e==null||e.length<=0){
        this.queryParams.startTime = null;
        this.queryParams.endTime = null
      }
    },

    // viewProject($id){

    // },

    /**
     * 获取时间段的台账信息
     */
    doQuery(){
      this.tableLoading = true;
      if(this.queryParams.plan_date&&this.queryParams.plan_date.length>0){
        this.queryParams.startTime = this.queryParams.plan_date[0].format('YYYY-MM-DD')
        this.queryParams.endTime = this.queryParams.plan_date[1].format('YYYY-MM-DD')
      }
        request(OFFICE_TYPE_COUNT_QUERY,"post",this.queryParams).then(res => {
          if(res.data.code==0){
            this.dataSource = res.data.data.list;
            this.pagination.total = res.data.data.total;
            this.dataSource.forEach((obj,index)=>{
              obj.key = obj.id;
              obj.index = (index+1)
            })
          }
          this.tableLoading = false;
        })
    },
    doReset(){
      this.queryParams={};
      this.doQuery();
    },
    //分页查询
    onPageChange(e){
      this.queryParams.pageNumber = e.current;
      this.pagination.current=e.current;
      this.doQuery();
    },
    /**
     * 导出excel
     */
    doDowload(){
      this.loadingText = "正在导出EXCEL文件，请稍候 ····";
      let name = "隐患类型统计"
      if(this.queryParams.plan_date&&this.queryParams.plan_date.length>0){
        this.queryParams.startTime = this.queryParams.plan_date[0].format('YYYY-MM-DD')
        this.queryParams.endTime = this.queryParams.plan_date[1].format('YYYY-MM-DD')
        name = this.queryParams.startTime+"至"+this.queryParams.endTime
      }
      this.isExporting=true;
      this.cPercent=0;
      let fileName=name+"-安全隐患类型统计.xlsx";
      this.exportFunNomal2(OFFICE_TYPE_COUNT_EXPORT,this.queryParams,fileName,'application/vnd.ms-excel')
    },
    handleMenuClick (e) {
      if (e.key === 'delete') {
        this.remove()
      }
    },
    doSureModal(){
      this.visible = true;
    }
  }
}
</script>

<style lang="less" scoped>
  .search{
    margin-bottom: 54px;
  }
  .fold{
    width: calc(100% - 216px);
    display: inline-block
  }
  .operator{
    margin-bottom: 18px;
  }
  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
</style>
