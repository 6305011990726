var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "company card w-100" },
    [
      _vm._m(0),
      _c("a-divider"),
      _c(
        "div",
        { staticClass: "flex j-center", staticStyle: { width: "350px" } },
        [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "flex-direction": "column",
                "justify-content": "space-between",
                "align-items": "center",
                "margin-right": "50px",
              },
            },
            [
              _c("span", { staticClass: "black-three" }, [_vm._v("已登记")]),
              _c("span", { staticClass: "font-26 font-bold" }, [
                _vm._v(_vm._s(_vm.mangerData.totalTeams || 0)),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "flex-direction": "column",
                "justify-content": "space-between",
                "align-items": "center",
                "margin-right": "50px",
              },
            },
            [
              _c("span", { staticClass: "black-three" }, [_vm._v("今日出勤")]),
              _c("span", { staticClass: "font-26 font-bold" }, [
                _vm._v(_vm._s(_vm.mangerData.todayTeams || 0)),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "flex-direction": "column",
                "justify-content": "space-between",
                "align-items": "center",
                "margin-right": "50px",
              },
            },
            [
              _c("span", { staticClass: "black-three" }, [_vm._v("出勤率")]),
              _c("span", { staticClass: "font-26 font-bold" }, [
                _vm._v(" " + _vm._s((_vm.mangerData.teamCql || 0) + "%") + " "),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "black font-16 font-bold overtext-1 flex mb-1" },
      [
        _c("img", {
          staticStyle: { width: "25px", height: "25px" },
          attrs: { src: require("@/assets/img/payroll-card1.png"), alt: "" },
        }),
        _c("span", { staticClass: "ml-05" }, [_vm._v("项目管理人员概览")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }