var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "root", staticClass: "action-columns" },
    [
      _c(
        "a-popover",
        {
          attrs: {
            placement: "bottomRight",
            trigger: "click",
            "get-popup-container": function () {
              return _vm.$refs.root
            },
          },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("a-checkbox", {
                staticClass: "check-all",
                attrs: {
                  indeterminate: _vm.indeterminate,
                  checked: _vm.checkAll,
                },
                on: { change: _vm.onCheckAllChange },
              }),
              _vm._v("列展示 "),
              _c(
                "a-button",
                {
                  staticStyle: { float: "right" },
                  attrs: { type: "link", size: "small" },
                  on: { click: _vm.resetColumns },
                },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
          _vm._l(_vm.columns, function (col, i) {
            return _c(
              "a-list",
              {
                key: i,
                staticStyle: { width: "100%" },
                attrs: { slot: "content", size: "small" },
                slot: "content",
              },
              [
                _c(
                  "a-list-item",
                  [
                    _c("a-checkbox", {
                      on: {
                        change: function (e) {
                          return _vm.onCheckChange(e, col)
                        },
                      },
                      model: {
                        value: col.visible,
                        callback: function ($$v) {
                          _vm.$set(col, "visible", $$v)
                        },
                        expression: "col.visible",
                      },
                    }),
                    col.title
                      ? [_vm._v(" " + _vm._s(col.title) + ": ")]
                      : col.slots && col.slots.title
                      ? _vm._t(col.slots.title)
                      : _vm._e(),
                    _c(
                      "template",
                      { slot: "actions" },
                      [
                        _c(
                          "a-tooltip",
                          {
                            attrs: {
                              title: "固定在列头",
                              mouseEnterDelay: 0.5,
                              "get-popup-container": function () {
                                return _vm.$refs.root
                              },
                            },
                          },
                          [
                            _c("a-icon", {
                              class: ["left", { active: col.fixed === "left" }],
                              attrs: { type: "vertical-align-top" },
                              on: {
                                click: function ($event) {
                                  return _vm.fixColumn("left", col)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "a-tooltip",
                          {
                            attrs: {
                              title: "固定在列尾",
                              mouseEnterDelay: 0.5,
                              "get-popup-container": function () {
                                return _vm.$refs.root
                              },
                            },
                          },
                          [
                            _c("a-icon", {
                              class: [
                                "right",
                                { active: col.fixed === "right" },
                              ],
                              attrs: { type: "vertical-align-bottom" },
                              on: {
                                click: function ($event) {
                                  return _vm.fixColumn("right", col)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "a-tooltip",
                          {
                            attrs: {
                              title: "添加搜索",
                              mouseEnterDelay: 0.5,
                              "get-popup-container": function () {
                                return _vm.$refs.root
                              },
                            },
                          },
                          [
                            _c("a-icon", {
                              class: { active: col.searchAble },
                              attrs: { type: "search" },
                              on: {
                                click: function ($event) {
                                  return _vm.setSearch(col)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            )
          }),
          _c("a-icon", { staticClass: "action", attrs: { type: "setting" } }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }