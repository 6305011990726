var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table" },
    [
      _c(
        "advance-table",
        {
          attrs: {
            columns: _vm.columns,
            "data-source": _vm.dataSource,
            title: "高级表格-Beta",
            loading: _vm.loading,
            rowKey: "id",
            "format-conditions": true,
            pagination: {
              current: _vm.page,
              pageSize: _vm.pageSize,
              total: _vm.total,
              showSizeChanger: true,
              showLessItems: true,
              showQuickJumper: true,
              showTotal: function (total, range) {
                return (
                  "第 " +
                  range[0] +
                  "-" +
                  range[1] +
                  " 条，总计 " +
                  total +
                  " 条"
                )
              },
              onChange: _vm.onPageChange,
              onShowSizeChange: _vm.onSizeChange,
            },
          },
          on: {
            search: _vm.onSearch,
            refresh: _vm.onRefresh,
            reset: _vm.onReset,
          },
          scopedSlots: _vm._u([
            {
              key: "send",
              fn: function (ref) {
                var text = ref.text
                return [_vm._v(" " + _vm._s(text ? "是" : "否") + " ")]
              },
            },
            {
              key: "status",
              fn: function (ref) {
                var text = ref.text
                return [_vm._v(" " + _vm._s(_vm._f("statusStr")(text)) + " ")]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "statusTitle" },
            [
              _vm._v(" 状态"),
              _c("a-icon", {
                staticStyle: { margin: "0 4px" },
                attrs: { type: "info-circle" },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _c("api"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }