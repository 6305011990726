var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-layout",
    { attrs: { desc: _vm.desc, linkList: _vm.linkList } },
    [
      this.extraImage && !_vm.isMobile
        ? _c(
            "div",
            {
              staticClass: "extraImg",
              attrs: { slot: "extra" },
              slot: "extra",
            },
            [_c("img", { attrs: { src: _vm.extraImage } })]
          )
        : _vm._e(),
      _c(
        "page-toggle-transition",
        {
          attrs: {
            disabled: _vm.animate.disabled,
            animate: _vm.animate.name,
            direction: _vm.animate.direction,
          },
        },
        [_c("router-view", { ref: "page" })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }