<template>
  <div>
    <a-drawer
      :width="800"
      :visible="visible"
      title="编辑考证拿证信息"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose"
    > 
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }" layout="horizontal">
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="证书名称：">
              <a-input
                v-decorator="['cerName', { rules: [{ required: true, message: '请输入证书名称' }] }]"
               placeholder="请输入证书名称"/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="证书等级：">
              <!-- <a-input
                v-decorator="['level', { rules: [{ required: true, message: '请输入证书等级' }] }]"
               placeholder="请输入证书等级"/> -->
                <a-select v-decorator="['level', { rules: [{ required: true, message: '请选择证书等级' }] }]">
                  <a-select-option :value="0" key="0">初级</a-select-option>
                  <a-select-option :value="1" key="1">中级</a-select-option>
                  <a-select-option :value="2" key="2">高级</a-select-option>
                </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="12">
          <a-col :span="24">
            <a-form-item label="证书图样：">
              <a-upload
                  name="file"
                  :multiple="false"
                  :beforeUpload="beforeUpload"
                  accept=".png,.PNG,.jpg,.JPG"
                  :customRequest="resourceUpload"
                  :file-list="fileList"
                  @change="handleChange"
              >
                  <a-button :loading="iconLoading" style="margin-left:10px" type="primary" >上传证书图样</a-button>
                  <div style="margin-top:10px"><img style="max-width:200px;max-height:100px" :src="filePath" /></div>
              </a-upload>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="负责人：">
              <a-input
                v-decorator="['userName', { rules: [{ required: true, message: '请输入负责人' }] }]"
               placeholder="请输入证书名称"/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="咨询热线：">
              <a-input
                v-decorator="['phone', { rules: [{ required: true, message: '请输入手机号' }] }]"
               placeholder="请输入手机号"/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="培训地址：">
              <a-input
                v-decorator="['address', { rules: [{ required: true, message: '请输入培训地址' }] }]"
               placeholder="请输入培训地址"/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="报名简章">
                <keep-alive>
                  <tinymce ref="tinymceEditor" :value="obj.remarks"  @input="contentInput"></tinymce>
                </keep-alive>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="关联课程">
                <a-select
                  mode="multiple"
                  show-search
                  placeholder="请输入或选择课程"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  v-model="obj.courseIds"
                >
                  <a-select-option :key="item.id" v-for="item in courseData" :value="item.id">
                    {{item.name}}
                  </a-select-option>
                </a-select>
            </a-form-item>
          </a-col>
        </a-row>
    </a-form>
    <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginLeft: '8px' }" type="primary" @click="doSubmit">
          确定
        </a-button>
        <a-button :style="{ marginLeft: '8px' }" @click="onClose">
          关闭
        </a-button>
      </div>
    </a-drawer>
    
  </div>
</template>
<script>
import { request } from "@/utils/request";
import Tinymce from "@/components/tinymce"
import { ATTACHMENT_UPLOAD,ONLINE_TASK_COURSE_LIST} from "@/services/api";
export default {
  name: "teamEditor",
  components: {Tinymce},
  data() {
    return {
      form: this.$form.createForm(this),
      isPublic:0,
      isThreeEdu:0,
      fileList:[],
      filePath:null,
      iconLoading:false,
      iconLoading1:false,
      obj:{},
      activeKey: ['1'],
      expandIconPosition: 'left',
      courseData:[],
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    examcer:{
      type: Object,
      require:true
    }
  },
  created(){
    
  },
  mounted(){
  },
  watch:{
    visible:{
       handler(val, oldName) {
        this.doQueryCourse();
          if(val&&this.examcer.id){
            this.obj = this.examcer;
            setTimeout(()=>{
              this.form.setFieldsValue({
                'cerName': this.examcer.cerName,
                'level': this.examcer.level,
                'userName': this.examcer.userName,
                'phone': this.examcer.phone,
                'address': this.examcer.address,
                
              })
              this.filePath = this.obj.cerImg;
              if(this.filePath){
                var fileObj = {
                  uid: 'kcfm-001',
                  name: "证书简略图",
                  url: this.filePath,
                }
                this.fileList.push(fileObj)
              }else{
                this.fileList=[];
              }
            })
          }else{
            this.form.resetFields();
            this.filePath=null;
            this.fileList = [];
          }
       }
      
    }
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    doQueryCourse(){
      request(ONLINE_TASK_COURSE_LIST, "post", {}).then((res) => {
        if (res.data.code === 0) {
          this.courseData = res.data.data;
        }
      });
    },
    handleClick(event) {
      // If you don't want click extra trigger collapse, you can prevent this:
      event.stopPropagation();
    },
    onChange(){

    },
    handleChange(info){
      if(info.file.uid=='kcfm-001'){
        let fileList = [...info.fileList];
        this.fileList = fileList;
        this.filePath = null;
      }
    },
    doDetail(){
      
    },
    onClose(){
      this.$emit('examcerPageClose');
    },
    contentInput(e){
      this.obj.remarks = e;
    },
    doSubmit(){
       //数据校验
      this.form.validateFields((err, values) => {
        if (!err) {
          if(this.obj&&this.obj.id){
            values.id = this.obj.id
          }
          values.cerImg=this.filePath
          this.$set(values,"remarks",this.obj.remarks);
          this.$set(values,"courseIds",this.obj.courseIds);
          this.$emit('examcerSubmit',{examcer:values})
        }
      })
    },
    /** 
    * 上传之前
    */
    beforeUpload (file) {
      return new Promise((resolve, reject) => {
        const isFiveM= file.size / 1024 / 1024 < 5 //小于5M的图片上传
        //判断文件是否符合正则表达式的规则
        if (!isFiveM) {
          this.$message.error('上传资源不大于5M！');
          return reject(false)
        }else{
          return resolve(true);
        }
      });
    },
    /**
    * 资源上传
    */
    resourceUpload(value){
      this.iconLoading = true;
      const formData = new FormData();
      formData.append('file', value.file);
      request(ATTACHMENT_UPLOAD,"post",formData).then(result => {
          if (result.data.code==0) {
            var fileObj = {
              uid: '1',
              name: result.data.data.name,
              url: result.data.data.path,
            }
            this.filePath = result.data.data.path;
            this.fileList = [];
            this.fileList.push(fileObj);
            value.onSuccess(result, value.file);
          }else{
            this.$message.warning(result.message);
          }
          this.iconLoading = false;
      })
    },
  }
};
</script>
<style>
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>