<template>
  <div>
    <div class="constructiontTable_index">
      <!-- 表单封装
                projectObj：下拉弹出框第一项对象
                Overview：项目名称
                baiduMapBoolen：控制地图显示
                topSubmit：控制上部分按钮显示
                bottomSubmit：控制下部分按钮显示
                handleSubmit：表单提交
                handleChange：输入出发
                handlSelect：输入下拉选择触发
            -->
      <form-index
        ref="formindex"
        :projectList="projectList"
        :formData="formData"
        :flexAction="true"
        :baiduMapBoolen="false"
        @handleSubmit="handleSubmit"
        @handleChange="handleChange"
        @handlSelect="handlSelect"
      >
        <!-- 下部分按钮插槽 -->
        <template #flexAction>
          <a-space :style="{ marginTop: '4px' }">
            <a-button icon="search" type="primary" html-type="submit"> 查询 </a-button>
            <!-- <a-button @click="close"> 重置 </a-button> -->
            <!-- <div class="operator"> -->
            <a-button @click="close"> 重置 </a-button>
            <!-- </div> -->
            <!-- <a-button type="primary" @click="doDowload()"> 导出EXCEL </a-button> -->
          </a-space>
        </template>

      </form-index>
      <a-space class="operator">
        <a-button icon="plus" type="primary" @click="createItem"> 新建项目 </a-button>
      </a-space>
      <!-- 表格封装 -->
      <standard-table
        class="mt-1"
        :columns="columns"
        :dataSource="dataSource"
        rowKey="id"
        :pagination="pagination"
        :loading="tableLoading"
        @change="onPageChange"
        :isAlert="false"
        :bordered="true"
      >
        <template slot="index" slot-scope="{ index }">
          <span>
            {{
              (pagination.current - 1) * pagination.pageSize +
              parseInt(index) +
              1
            }}
          </span>
        </template>
        <template slot="projectName" slot-scope="{ text, record }">
          <a-tooltip placement="topLeft">
            <template slot="title"
              ><span>{{ record.projectMsg.fullName ? record.projectMsg.fullName : text  }}</span></template
            >
            <span class="ant-btn-text ellipsis-2" @click="toUnits(record)">{{ record.projectMsg.fullName ? record.projectMsg.fullName : text  }}</span>
          </a-tooltip>
        </template>
        <template slot="projectNum" slot-scope="{ record }">
          <a-tooltip placement="topLeft">
            <template slot="title"
              >
              <span>{{ record.projectMsg.projectNum ? record.projectMsg.projectNum : record.projectNum }}</span>
              </template
            >
            <span class="ellipsis-2">
              <span>{{ record.projectMsg.projectNum ? record.projectMsg.projectNum : record.projectNum}}</span>
            </span>
          </a-tooltip>
        </template>
        <template slot="ellipsis" slot-scope="{ text }">
          <a-tooltip placement="topLeft">
            <template slot="title"
              ><span>{{ text }}</span></template
            >
            <span class="ellipsis-2">{{
              text
            }}</span>
          </a-tooltip>
        </template>
        <div slot="userName" slot-scope="{ record }">
          <div v-if="record.userName">
            <div>{{ record.userName }}</div>
            <span v-if="record.userPhone">({{ record.userPhone }})</span>
          </div>
        </div>
        <div slot="action" slot-scope="{ record }">
          <div>
            <a style="color: #4678d6" @click="toUnits(record)"> 修改 </a>
            <!-- <div @click="syncProject(record)"> 同步 </div> -->
            <a-divider type="vertical" />
            <a style="color: #ff5c77" @click="recorDelete(record)"> 删除 </a>
          </div>
        </div>
      </standard-table>
      <Export-loading
        :cPercent="cPercent"
        :isExporting="isExporting"
        :loadingText="loadingText"
      ></Export-loading>
    </div>
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import {
  aqxjProjectsupdate,
  getLwProjectList,
  deleteLwProject,
  getLwProjectDetail,
} from "@/services/projectManagement";
import { request } from "@/utils/request";
import { exportProgress } from "@/pages/aqjyzl/export"; // 导出方法
import ExportLoading from "@/components/ExportLoading/ExportLoading"; // progress组件
import {
  QUERY_OFFICE_BY_USER,
  AQXJ_PROJECTS_EXPORT_ZAIJIAN,
} from "@/services/api";
import {
  projectMsgGetList,
  getLwDetail,
  getTreeOffice,
  syncProjectToHk
} from "@/services/projectManagement";
import { mapGetters } from "vuex";
const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
    // fixed: "left",
  },
  {
    title: "项目名称",
    dataIndex: "projectName",
    width: 200,

    scopedSlots: { customRender: "projectName" },
    // fixed: "left",
    // ellipsis: true,
  },
  {
    title: "项目编码",
    dataIndex: "projectNum",
    scopedSlots: { customRender: "projectNum" },
    width: 120,
  },
  {
    title: "主要负责人",
    dataIndex: "userName",
    align: "center",
    width: 100,
    key: "userName",
  },
  {
    title: "项目类型",
    dataIndex: "ptype",
    align: "center",
    width: 80,
    scopedSlots: { customRender: "ptype" },
  },
  {
    title: "工程造价(万元)",
    align: "center",
    width: 120,
    dataIndex: "projBudget",
    scopedSlots: { customRender: "projBudget" },
  },
  {
    title: "施工状态",
    dataIndex: "status",
    align: "center",
    width: 100,
    key: "status",
  },
  {
    title: "工程地址",
    dataIndex: "address",
    width: 160,
    scopedSlots: { customRender: "ellipsis" },
  },
  {
    title: "工程概况",
    dataIndex: "projDesc",
    align: "center",
    width: 160,
    scopedSlots: { customRender: "ellipsis" },
    key: "projDesc",
  },
  {
    title: "操作",
    align: "center",
    width: 120,
    // fixed: "right",
    scopedSlots: { customRender: "action" },
  },
];
const formData = [
  // {
  //   label: "所属单位",
  //   placeholder: "请选择所属单位",
  //   decorator: [
  //     "officeId",
  //     { rules: [{ required: false, message: "请选择所属单位" }] },
  //   ],
  //   type: "select",
  //   key: "officeId",
  //   selectlist: [],
  //   qb: true,
  //   col: 6,
  //   labelCol: 6,
  //   wrapperCol: 14,
  // },
  {
    label: "项目名称",
    placeholder: "请输入项目名称",
    decorator: [
      "projectName",
      { rules: [{ required: false, message: "请输入项目名称" }] },
    ],
    type: "input",
    key: "projectName",
    dataSource: [],
    col: 5,
    labelCol: 6,
    wrapperCol: 14,
  },
  {
    label: "施工状态",
    placeholder: "请选择施工状态",
    decorator: [
      "status",
      { rules: [{ required: false, message: "请选择施工状态" }] },
    ],
    type: "select",
    key: "status",
    col: 5,
    labelCol: 6,
    wrapperCol: 14,
    selectlist: [
      {
        value: 0,
        name: "在建",
      },
      {
        value: 1,
        name: "完工",
      },
      {
        value: 2,
        name: "停缓建",
      },
      {
        value: 3,
        name: "未开工",
      },
    ],
  },
  {
    label: "项目类型",
    placeholder: "请选择项目类型",
    decorator: [
      "ptype",
      { rules: [{ required: false, message: "请选择项目类型" }] },
    ],
    type: "select",
    key: "ptype",
    col: 5,
    labelCol: 6,
    wrapperCol: 14,
    selectlist: [
      {
        value: 1,
        name: "房建",
      },
      {
        value: 2,
        name: "市政",
      },
    ],
  },
  {
    type: "action",
    col: 4,
    display: true,
  },
];
export default {
  mixins: [exportProgress], //导出方法注入
  components: {
    formIndex, //form表单封装
    StandardTable, //表格封装
    ExportLoading, //导出数据加载封装
  },
  data() {
    return {
      columns: columns, //表格数据
      dataSource: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        pageSizeOptions: ['10', '20', '30', '50'],
        showSizeChanger:true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      tableLoading: false,
      formData: formData,
      prams: {
        pageNumber: 1,
        pageSize: 10,
      },
      projectList: [], //获取当前项目数据
      loadingText: "数据加载中...",
      cPercent: 0,
      isExporting: false,
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  activated() {
    // console.log(this.user, "this.user");
    if (this.$route.query.pageNumber) {
      this.prams.pageNumber = this.$route.query.pageNumber;
    }
    // this.getgcglbList(QUERY_OFFICE_BY_USER); // 暂时不用
    // this.initTree(); // 暂时不用
    this.aqxjlist(this.prams);
  },
  methods: {
    // 同步项目
    syncProject(val) {
      console.log(val)
      let params = {
        projectNum: val.projectMsg.projectNum,
        projectId: val.projectMsg.id,
      };
      this.confirm("同步项目", "是否此同步项目？").then(() => {
        syncProjectToHk(params).then(res => {
          if (res.data.code === 0) {
            this.$message.success('同步成功！')
          } else {
            this.$message.error(res.data.msg)
          }
        })
      });
    },
    // 封装弹窗确定按钮
    confirm(title, content) {
      return new Promise((resolve, reject) => {
        this.$confirm({
          title: title,
          content: content,
          onOk() {
            resolve();
          },
        });
      });
    },
    // 删除接口
    recorDelete(val) {
      let params = {
        id: val.id,
      };
      this.confirm("删除", "是否删除").then(() => {
        deleteLwProject(params).then((res) => {
          if (res.data.code === 0) {
            this.$message.success("删除成功", 10);
            this.aqxjlist(this.prams);
          } else {
            this.$message.error(res.data.msg, 10);
          }
        });
      });
    },
    // 更新接口
    update(e) {
      aqxjProjectsupdate(e).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("成功", 10);
        } else {
          this.$message.error(res.data.msg, 10);
        }
      });
    },
    /**
     * 导出excel
     */
    doDowload() {
      let obj = this.$refs["formindex"].getFieldValue();
      obj.queryType = this.prams.queryType;
      console.log(obj,'=>>>>obj导出')
      this.loadingText = "正在导出EXCEL文件，请稍候 ····";
      this.isExporting = true;
      this.cPercent = 0;
      let fileName = "工程一览表.xlsx";
      this.exportFunNomal2(
        AQXJ_PROJECTS_EXPORT_ZAIJIAN,
        obj,
        fileName,
        "application/vnd.ms-excel"
      );
    },
    // 查看编辑
    viewEditing(e) {
      getLwProjectDetail({ id: e.id }).then((res) => {
        // 项目字段存在projectMsg 处理字段
        if (res.data.code === 0) {
          const result = res.data.data;
          let lanAndLat = `${result.projectMsg.longitude},${result.projectMsg.latitude}`;
          let projectMsg = {
            projectNum: result.projectMsg.projectNum ? result.projectMsg.projectNum : undefined,
            projDesc: result.projectMsg.projDesc,
            projAddress: result.projectMsg.projAddress,
            fullName: result.projectMsg.fullName ? result.projectMsg.fullName : undefined,
            latitude: result.projectMsg.latitude,
            longitude: result.projectMsg.longitude,
            projBudget: result.projectMsg.projBudget,
            ptype: Number(result.ptype),
            lanAndLat,
          };
          const data = {
            ...result,
            ...projectMsg,
          };
          this.$router.push({
            name: "项目详情",
            query: {
              data,
              type: "table",
              pageNumber: this.prams.pageNumber,
            },
          });
        } else {
          this.$message.error(res.data.msg, 10);
        }
      });
    },
    handleChange(e) {
      this.projectArr = [];
      this.getZdata(projectMsgGetList, e, "fullName");
      // 获取当前匹配key，赋值相对于数组
      this.formData.forEach((item) => {
        if (e.item.key === item.key) {
          item.dataSource = this.projectArr;
        }
      });
    },
    // 接口封装
    /**
     * msg 接收接口名称
     * e 数据
     * name 参数名
     * */
    getZdata(msg, e, name) {
      let data = {
        [name]: e.value,
      };
      msg(data).then((res) => {
        if (res.status == 200) {
          const data = res.data.data.list ? res.data.data.list : res.data.data
          data.forEach((item) => {
            this.projectArr.push({
              title: item[name],
              id: item.id,
            });
          });
          this.projectList = data;
        }
      });
    },
    // 新建项目
    createItem() {
      this.$router.push({name: "新增项目"});
    },
    // 项目数据
    ptypeState(type, status) {
      if (type == "ptype") {
        const obj = {
          1: "房建",
          2: "市政",
          // 3: "装饰装修",
          // 3:'装修',
        };
        let str = null;
        Object.keys(obj).forEach((key) => {
          if (key == status) {
            str = obj[key];
          }
        });
        return str;
      } else {
        const obj = {
          0: "在建",
          1: "完工",
          2: "停缓建",
          3: "未开工",
          4: "完工",
        };
        let str = null;
        Object.keys(obj).forEach((key) => {
          if (key == status) {
            str = obj[key];
          }
        });
        return str;
      }
    },
    // 特殊数据处理
    handlSelect({ val, keys }) {
      this.projectList.forEach((item) => {
        if (keys == "projectName" && item.id == val) {
          this.$refs["formindex"].setFieldsValue(
            item.fullName,
            {},
            "projectName"
          );
        }
      });
    },
    aqxjlist(prams) {
      this.tableLoading = true;
      getLwProjectList(prams).then((res) => {
        if (res.data.code === 0) {
          // res.data.data.map((item, index) => {
          //   res.data.data[index] = { ...item, ...item.projectMsg };
          // });

          this.pagination.total = res.data.data.total;
          res.data.data.list.map((item) => {

            if (!item.projectNum) {
              item.projectNum == ''
            }
            if (!item.projectMsg) {
              item.projectMsg = ''
            }
            item.ptype = this.ptypeState("ptype", item.ptype);
            item.statusw = item.status;
            item.status = this.ptypeState("status", item.status);
            item.projBudget = item.projBudget
              ? item.projBudget
              : item?.projectMsg?.projBudget
              ? item?.projectMsg?.projBudget
              : "";
          });
          this.dataSource = res.data.data.list;
        }
        this.tableLoading = false;
      });
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      console.log(data);
      let arr = [];
      data.forEach((item) => {
        arr.push({ name: item.name || item.master, value: item.id });
      });
      this.formData.forEach((item) => {
        if (item.key == key) {
          item.selectlist = arr;
        }
      });
    },
    // 获取工程管理部数据
    getgcglbList(path) {
      request(path, "get").then((res) => {
        let data = res.data.data.filter(
          (item) => item.id != "3da0f5533f87425c9a9e8a11a7923300"
        );
        this.formDataFn("officeId", data);
      });
    },
    initTree() {
      getTreeOffice({}).then((res) => {
        this.formDataFn("officeId", res.data.data);
      });
    },
    // 提交表单数据
    handleSubmit(e) {
      this.prams.pageNumber = 1
      this.pagination.current = 1;
      let obj = Object.assign({}, e, this.prams);
      this.aqxjlist(obj);
    },
    // 重置
    close() {
      this.prams = {
        pageNumber: 1,
        pageSize: 10,
      };
      this.pagination.current = 1;
      this.pagination.pageSize = 10
      this.aqxjlist(this.prams);
      // this.$refs["formindex"].setFieldsValue("", {}, "projectName");
      this.$refs["formindex"].resetFields();
    },
    //表格 - 分页查询
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      this.prams.pageNumber = e.current;
      this.prams.pageSize = e.pageSize
      this.pagination.current = e.current;
      this.pagination.pageSize = e.pageSize;
      this.aqxjlist(Object.assign({}, this.prams, obj));
    },
    // 跳转到项目详情
    toUnits(e) {
      this.$router.push({
        // path: "../organization/projectLw_detail",
        name: "劳务项目详情",
        query: {
          id: e.projectId,
          officeId: e.officeId,
          // data: res.data.data,
          // pageNumber: this.prams.pageNumber,
          // random: Math.random(),
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.a_a_footer {
  padding: -10px 0px;
  background: #ffffff;
  .operator {
    // margin-right: 8px;

    .ant-btn {
      color: #307dfa;
      border-color: #307dfa;
    }
  }
}

.constructiontTable_index {
  padding: 24px;
  background: #fff;
  border-radius: 6px;
  margin-bottom: 10px;

  .c_i_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 25px;

    .c_i_h_center {
      padding: 16px;
      border-radius: 4px;
      width: 180px;
      height: 130px;
      margin-right: 50px;

      div:nth-child(1) {
        font-size: 16px;
      }

      div:nth-child(2) {
        margin-top: 26px;
        font-size: 24px;
      }
    }
  }
}

.sTable {
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
      width: 100%;
      text-align: center;
      cursor: pointer;
    }

    div:nth-child(1) {
      color: #307dfa;
    }

    div:nth-child(3) {
      color: #ff5c77;
    }

    div:nth-child(2) {
      color: #86D65F;
    }
}

.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 1; /* 显示行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-btn-text {
  color: #4a86e8;
  background-color: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ant-btn-text:hover {
  cursor: pointer;
  color: #76abf5;
  background-color: transparent;
  border-color: #76abf5;
}

/deep/ .ant-form-item{
  margin-bottom: 10px ;
}

</style>
