<template>
  <a-modal 
  forceRender
  centered 
  :maskClosable="false" 
  v-model="visibleExit" 
  title="用户角色权限" 
  @cancel="handleCancel" 
  @ok="modelOk"
  :width="700"
  >
    <form-index
      ref="formindex"
      :formData="formData"
    >
    </form-index>
  </a-modal>
</template>

<script>

import formIndex from "@/pages/components/form/index";
import { mapGetters } from "vuex";
import {
  getUser,
  getRoleListBySysUserList,
  assignUserRole,
  getUserRole
} from "@/services/sys";
export default {
  name: 'SuijianClientsetUserRoleModal',
  components: {
    formIndex
  },
  props: {
    visible:{
      type:Boolean,
      default:false
    },
    userId: {
      type: [String,Number],
    }
  },
  watch: {
    visible: {
      handler(val, oldName) {
        this.visibleExit = val
        this.$refs["formindex"].resetFields()
        if (this.userId) this.getUser(this.userId)
      }
    }
  },
  data() {
    const formData = [
      {
        label: "姓名",
        placeholder: "请输入姓名",
        decorator: [
          "nickname",
          { rules: [{ required: false, message: "请输入姓名" }] },
        ],
        type: "input",
        key: "nickname",
        col: 24,
        labelCol: 6,
        wrapperCol: 14,
        disabled:true
      },
      {
        label: "账号",
        placeholder: "请输入账号",
        decorator: [
          "username",
          { rules: [{ required: false, message: "请输入账号" }] },
        ],
        type: "input",
        key: "username",
        col: 24,
        labelCol: 6,
        wrapperCol: 14,
        disabled:true
      },
      {
        label: "角色",
        placeholder: "请选择角色",
        decorator: [
          "roleIds",
          { rules: [{ required: true, message: "请选择角色" }] },
        ],
        type: "select",
        key: "roleIds",
        selectlist: [],
        labelCol: 6,
        wrapperCol: 14,
        col: 24,
        display: true,
        mode: "multiple",
      },
    ]
    return {
      formData:formData,
      visibleExit: this.visible,
      // projectList:[]
    };
  },

  mounted() {
    this.getRoleList()
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },

  methods: {
    handleCancel() {
      this.$emit("handleCancel")
    },
    getUser(userId) {
      getUser({id: userId}).then(res => {
        if (res.data.code == 0) {
          getUserRole({userId:userId}).then(roles => {
            console.log(res)
            let data = res.data.data
            data.roleIds = roles.data.data
            this.$nextTick(() => {
              this.$refs.formindex.queryObj(data)
            })
          })
         
        }
      })
    },
    // 确认弹窗
    modelOk(e) {
      this.$refs["formindex"].combinationSubmit().then((res) => {
        const params = {
          ...res,
        };
        params.userId = this.userId
        assignUserRole(params).then(result => {
          if (result.data.code === 0) {
            this.$message.success('操作成功', 10);
          }
        })
        this.handleCancel();
      });
    },
    // 获取劳务角色数组
    getRoleList() {
      getRoleListBySysUserList({}).then((res) => {
        if (res.data.code == 0) {
          // 筛选数组=项目角色
          this.newRole = res.data.data.filter((obj) => obj.roleType == 1 || obj.code == "OFFICE_ORDINARY_STAFF" );
          const data = this.newRole;
          const arr = [];
          data.forEach((item) => {
            arr.push({
              name: item.name,
              value: item.id,
            });
          });
          this.formDataFn("roleIds", arr);
        }
      });
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      this.formData.forEach((item) => {
        if (item.key == key) {
          item.selectlist = data;
        }
      });
    }
  },
};
</script>