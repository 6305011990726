<template>
  <a-modal v-model="internalVisible" :width='600' :footer="null" @cancel="handleCancel" :maskClosable="false">
    <div style="padding: 30px;height: 550px" class="relative">
      <div class="flex a-center j-center flex-column">
        <h2 style="font-weight: bold">欢迎使用{{systemFullname}}</h2>
        <div style="font-size: 16px">
          <div style="font-weight: bold;color: red">您的密码过于简单，请重新设置密码，密码要求如下:</div>
          <div>1.包含大小写字母、数字和符号且长度至少8位</div>
          <div>2.数字不存在4位顺序连续以上</div>
          <div>3.不包含三个连续的相同数字</div>
        </div>
        <!-- <a-tooltip placement="bottomRight">
          <span class="mt-1">您的密码过于简单，请重新设置密码，密码规则如下：</span>
          <p>密码要求：大写，小写，数字，符号，必须满足三种，并且长度不少于8位，数字不允许连续三位以上！</p>
          <template slot="title">密码要求：大写，小写，数字，符号，必须满足三种，并且长度不少于8位，数字不允许连续三位以上！</template>
          <a-icon style="margin-left:5px" type="question-circle" theme="twoTone" />
      </a-tooltip> -->
      </div>
      <a-card :bordered="false">
        <a-form :form="changePwdForm" @submit="handleSubmit" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol" >
            <a-form-item label="新密码">
                <a-input-password placeholder="新密码必须包含数字、大小写字母、特殊字符，且长度至少8位" v-decorator="['newPwd',
                { rules:
                [
                  {
                    required: true,
                    message: '密码必须包含数字、小写字母、大写字母、特殊字符，且长度至少8位',
                    pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[~!@#$%^&*.)(_+}{|:?><]).{8,16}$/
                  },
                  {validator: validatePassword}
              ] },
                ]"
                />
            </a-form-item>
            <a-form-item label="确认新密码">
                <a-input-password placeholder="确认新密码" v-decorator="['confirmPwd',
                {
                  rules: [{ required: true,message: '请输入确认新密码' },{validator: validateUserPassword}] },

                ]"
                />
            </a-form-item>
            <a-form-item :wrapper-col="{ span: 6, offset: 10}">
                <a-button type="primary" html-type="submit"> 修改 </a-button>
            </a-form-item>
        </a-form>
      </a-card>
    </div>
  </a-modal>
</template>
<script>

    import { changePwd, logout, loginOut, checkUserPwd } from '@/services/user'
    import { mapGetters } from 'vuex'
    import { Decrypt, Encrypt } from "@/config/crypto-js";
    export default {
        data() {
            return {
                changePwdForm: this.$form.createForm(this, { name: 'coordinated' }),
                internalVisible: this.visible,
                formItemLayout: {
                  labelCol: { span: 6 },
                  wrapperCol: { span: 16 },
                }
            };
        },
        computed: {
          ...mapGetters('account', ['user']),
          systemFullname() {
            return this.$store.getters['setting/systemFullname']
          },
        },
        props: {
          visible: {
            type: Boolean,
            default: false
          }
        },
        watch: {
          visible(val) {
            this.internalVisible = val
          }
        },
        methods: {
            handleSubmit(e) {
                e.preventDefault();
                this.changePwdForm.validateFields((err, values) => {
                    if (!err) {
                        changePwd({newPwd: values.newPwd}).then(res => {
                            if (res.data.code === 0) {
                                this.$message.success(res.data.msg + ',请重新登录');
                                setTimeout(() => {
                                    loginOut({userId: this.user.id}).then(res => {
                                        logout()
                                        this.$router.push('/login')
                                    })
                                },2000)
                            } else {
                                this.$message.error(res.data.msg);
                            }
                        })
                    }
                })
            },
            validateUserPassword(rule, value, callback) {
                // 自定义校验规则
                let newPwd = this.changePwdForm.getFieldValue('newPwd')
                if (value && value != newPwd) {
                    callback(new Error('确认密码不一致'));
                } else {
                    callback();
                }
            },
            handleCancel(e) {
              this.$message.error('应网信办对系统要求，按照国家安全法律法规，将实行检测密码是否属于弱口令，如果是，将强制要求登录用户修改复杂密码才能进行操作',10);
              setTimeout(() => {
                  loginOut({userId: this.user.id}).then(res => {
                      logout()
                      this.$router.push('/login')
                  })
              },2000)
          },
          validatePassword(rule, value, callback) {
              if (value && /(\d)\1\1/.test(value)) {
                 callback(new Error('密码不能包含三个连续的相同数字'));
              }
              else if (value && !this.isConsecutiveNumbers(value)) {
                callback(new Error('不能连续数字（如1234）连续4位或4位以上'));
              }
              else {
                // 密码符合要求
                callback();
              }
          },
          // 判断密码是否为连续的数字或字母
          Lxstr (str) {
            let arr = str.split('')
            let flag = true
            for (let i = 1; i < arr.length - 1; i++) {
              let firstIndex = arr[i - 1].charCodeAt()
              let secondIndex = arr[i].charCodeAt()
              let thirdIndex = arr[i + 1].charCodeAt()
              thirdIndex - secondIndex == 1
              secondIndex - firstIndex == 1
              if ((thirdIndex - secondIndex == 1) && (secondIndex - firstIndex == 1)) {
                console.log('为弱口令密码')
                flag = false
              }
            }
            return flag
          },
          // 判断是否包含连续数字 (如1234)
          isConsecutiveNumbers(str) {
            let arr = str.split('')
            let flag = true
            for (let i = 1; i < arr.length - 2; i++) {
              let firstIndex = arr[i - 1].charCodeAt()
              let secondIndex = arr[i].charCodeAt()
              let thirdIndex = arr[i + 1].charCodeAt()
              let fourIndex = arr[i + 2].charCodeAt()
              // 是否都为数值
              const allNumber = [
              firstIndex,
              secondIndex,
              thirdIndex,
              fourIndex
              ].every((i) => this.isNumeric(i))

              if (allNumber && (fourIndex - thirdIndex == 1) && (thirdIndex - secondIndex == 1) && (secondIndex - firstIndex == 1)) {
                console.log('为弱口令密码')
                flag = false
                break; // 如果已经发现连续数字，提前结束循环
              }
            }
            return flag
          },
          // 根据Unicode 编码判断是否为数字
          isNumeric(charCode) {
            return charCode >= 48 && charCode <= 57
          }
        }
    };

</script>

<style lang="less" scoped>
/deep/ .ant-modal-content {
  border-radius: 20px !important;
  height: 480px;
}
.choose-box {
  width: 130px;
  height: 130px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &&.choose-box-right {
    margin-right: 60px;
  }
  span {
    margin-top: 10px;
    font-size: 16px;
  }
}
.choose-box-unselected {
  background: #F5F6FD;
  color: #CCCCCC;
}
.choose-box-selected {
  background: #0874F0;
  color: #fff;
}
.radio-box {
  position: relative;
  // background: rgb(255, 255, 255);
  // box-shadow: 0px 0px 18px 0px rgba(8, 116, 240, 0.3);
  // border-radius: 10px;
  // padding: 15px 0 10px 20px;
  // max-height: 170px;
  // overflow: hidden;
  // overflow-y: auto;
  img {
    position: absolute;
    width: 100%;
    top: -30px
  }
}
.ant-radio-group {
  max-height: 145px;
  overflow: hidden;
  overflow-y: auto;
}
.btn-box {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
</style>
