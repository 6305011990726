<template>
  <a-card>
    <a-drawer
      :width="1224"
      :visible="visible"
      title="签署人位置配置"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose"
    > 
      <a-form :form="form" layout="horizontal" @submit="doSubmit">
        <a-row v-for="(item,index) in dataSource" :key="index" :gutter="16">
          <a-col :span="3">
            <a-form-item>
              <!-- <a-input v-model="item.approverType" placeholder="签署类型" /> -->
              <a-select default-value="PERSON"  v-model="item.approverType" placeholder="签署类型">
                <a-select-option value="ORGANIZATION">
                  ORGANIZATION
                </a-select-option>
                <a-select-option value="WORKSEND">
                  WORKSEND
                </a-select-option>
                <a-select-option value="PERSON">
                  PERSON
                </a-select-option>
                <a-select-option value="TEAMLEADER">
                  TEAMLEADER
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
           <a-col :span="6">
            <a-form-item>
              <a-input v-model="item.recipientId" placeholder="recipientId" />
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item>
              <!-- <a-input v-bind:disabled="dis(item)" v-model="item.roleKey" placeholder="角色" /> -->
              <a-select v-bind:disabled="dis(item,1)" v-model="item.roleId" placeholder="签署角色" @change="changeRole">
                <a-select-option v-for="(role, index) in roleList" :key="index" :value="role.roleId" >
                    {{role.roleName}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="3">
            <a-form-item>
              <!-- <a-input v-bind:disabled="dis(item,2)" v-model="item.userName" placeholder="签署人姓名" /> -->
              <a-select v-bind:disabled="dis(item,1)" v-model="item.userId" placeholder="签署人" :allowClear="true"  @change="e => userChange(e, item)">
                <a-select-option v-for="(user, index) in userList" :key="index" :value="user.sysUserId" >
                    {{user.name}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="3">
            <a-form-item>
              <a-input v-bind:disabled="dis(item,1)" v-model="item.mobile" placeholder="手机号" />
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item>
              <a-input v-bind:disabled="dis(item,1)" v-model="item.idCard" placeholder="身份证号" />
            </a-form-item>
          </a-col>
        </a-row>
        
      </a-form>
      <div>
        <a-button :style="{ marginLeft: '8px' }" type="primary" @click="doMsgTempl">
          获取电子签模板信息
        </a-button>
        <span>(ORGANIZATION,WORKSEND,PERSON)</span>
        <span>{{templMsg}}</span>
      </div>
    <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginLeft: '8px' }" type="primary" @click="doSubmit">
          确定
        </a-button>
        <a-button :style="{ marginLeft: '8px' }" @click="onClose">
          关闭
        </a-button>
      </div>
    </a-drawer>
  </a-card>
</template>

<script>
import {
  ESS_TEMPL_RECIPIENT_QUERY, 
  ESS_TEMPL_RECIPIENT_MSG,
  GET_ROLE_LIST,
  QUERY_USERLIST_ROLE,
  } from '@/services/api'
import {request} from '@/utils/request'
// import axios from '@/utils/axios'

export default {
  name: 'mobile_setting',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    essTempl:{
      type:Object,
      require:true
    }
    // templId:{
    //   type: String,
    //   require:true
    // },
    // customTemplateId:{
    //   type: String,
    //   require:true
    // },
    // officeId:{
    //   type: String,
    //   require:true
    // }
  },
  watch:{
    visible(val){
      if(val){
        this.doQuery();
        this.queryRoleList();
      }else{
        this.dataSource=[
        {
          approverType:null,
          recipientId:null,
          roleId:null,
          userId:null,
         mobile:'',
          userName:'',
          idCard:''
        },
        {
          approverType:null,
          recipientId:null,
          roleId:null,
          userId:null,
          mobile:'',
          userName:'',
          idCard:''
        },
        {
          approverType:null,
          recipientId:null,
          roleId:null,
          userId:null,
          mobile:'',
          userName:'',
          idCard:''
        },
        {
          approverType:null,
          recipientId:null,
          roleId:null,
          userId:null,
          mobile:'',
          userName:'',
          idCard:''
        },
        {
          approverType:null,
          recipientId:null,
          roleId:null,
          userId:null,
          mobile:'',
          userName:'',
          idCard:''
        }
      ]
      }
    }
  },
  data () {
    return {
      dataSource:[
        {
          approverType:null,
          recipientId:null,
          roleId:null,
          userId:null,
          mobile:null,
          userName:null,
          idCard:null
        },
        {
          approverType:null,
          recipientId:null,
          roleId:null,
          userId:null,
          mobile:null,
          userName:null,
          idCard:null
        },
        {
          approverType:null,
          recipientId:null,
          roleId:null,
          userId:null,
          mobile:null,
          userName:null,
          idCard:null
        },
        {
          approverType:null,
          recipientId:null,
          roleId:null,
          userId:null,
          mobile:null,
          userName:null,
          idCard:null
        },
        {
          approverType:null,
          recipientId:null,
          roleId:null,
          userId:null,
          mobile:null,
          userName:null,
          idCard:null
        }
      ],
      roleList:[],
      userList:[],
      templMsg:'',
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'validate_other' });
  },
  // created() {
  //   this.doQuery();
  // },
  methods: {
    /**
     * 获取项目设置的考勤信息
     */
    doQuery(){
        request(ESS_TEMPL_RECIPIENT_QUERY,"post",{templId:this.essTempl.essTemplateId}).then(res => {
          if(res.data.code==0&&res.data.data.length>0){
            this.dataSource = res.data.data;

          }
        })
    },
    dis(item){
     
      let dis = item.approverType=='ORGANIZATION'||item.approverType=='WORKSEND'||item.approverType=='TEAMLEADER';
      if(dis){
        item.idCard= null;
        item.userName=null;
        item.mobile=null;
      }
      return dis;
    },
    /**
     * 保存表单信息
     */
    // doSave(e){
    //     e.preventDefault();
    //     var _this = this;
    //     this.form.validateFields((err, values) => {
    //       if (!err) {
    //         console.log(values);
    //       }
    //     })
    // },
    handleMenuClick (e) {
      if (e.key === 'delete') {
        this.remove()
      }
    },
    doMsgTempl(){
      request(ESS_TEMPL_RECIPIENT_MSG+this.essTempl.essTemplateId,"GET").then(res => {
        if(res.data.code==0){
          this.templMsg = res.data.msg;
        }
      })
    },
    onClose(){
      this.$emit("close");
    },
    doSubmit(){
      let obj = {
        setings:this.dataSource,
        templId:this.essTempl.essTemplateId,
        customTemplateId:this.essTempl.customTemplateId
      }
      this.$emit("submit",obj);
    },
    queryRoleList(){
      request(GET_ROLE_LIST,"GET").then(res => {
        if(res.data.code==0){
          this.roleList = res.data.data;
        }
      })
    },
    changeRole(roleId){
      let params = {
        officeId:this.essTempl.officeId,
        // projectId:this.essTempl.projectId,
        roleId:roleId
      }
      request(QUERY_USERLIST_ROLE,"post",params).then(res => {
        if(res.data.code==0){
          this.userList = res.data.data;
        }
      })
    },
    userChange(userId,item){
      if(userId!=null){
        let user = this.userList.filter(o=>o.sysUserId == userId);
        if(user!=null&&user.length>0){
          item.idCard=user[0].idCard;
          item.userName=user[0].name;
          item.mobile=user[0].phone;
        }
      }else{
        item.idCard=null;
        item.userName=null;
        item.mobile=null;
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .search{
    margin-bottom: 54px;
  }
  .fold{
    width: calc(100% - 216px);
    display: inline-block
  }
  .operator{
    margin-bottom: 18px;
  }
  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
</style>
