<template>
  <div>
    <div class="constructiontTable_index">
      <form-index
        ref="formindex"
        :formData="formData"
        :flexAction="true"
        :baiduMapBoolen="false"
        @handleSubmit="handleSubmit"
        @handleChange="handleChange"
        @handlSelect="handlSelect"
      >
        <!-- 下部分按钮插槽 -->
        <template #flexAction>
          <a-space :style="{ marginTop: '4px' }">
            <a-button icon="search" type="primary" html-type="submit">查询</a-button>
            <a-button @click="close"> 重置 </a-button>
          </a-space>
        </template>
      </form-index>
      <div class="mt-10">
        <standard-table
          class="table"
          :columns="columns"
          :dataSource="dataSource"
          :pagination="pagination"
          :loading="tableLoading"
          @change="onPageChange"
          :isAlert="false"
          :bordered="true"
          tableSize="middle"
        >
          <template slot="index" slot-scope="{ index }">
            <span>
              {{
                (pagination.current - 1) * pagination.pageSize +
                parseInt(index) +
                1
              }}
            </span>
          </template>
          <template slot="projectName" slot-scope="{ text,record  }">
              <a-tooltip placement="topLeft">
                <template slot="title"
                  ><span>{{ text }}</span></template
                >
                <span @click="viewEditing(record)" class="ant-btn-text ellipsis-2">{{ text  }}</span>
              </a-tooltip>
            </template>
          <div slot="action" slot-scope="{ record }">
            <div>
              <a @click="viewEditing(record)">查看</a>
              <!-- <a-divider type="vertical" />
              <a style="color: red;" @click="recorDelete(record)">删除</a> -->
            </div>
          </div>
        </standard-table>
      </div>
    </div>
    <proSummaryStaff-dialog
      :visible="distributeVisible"
      @cancel="distributeVisible = false"
      :modalTitle="'工人工资汇总'"
      :workNumList="workNumList"/>
    <Export-loading :cPercent="cPercent" :isExporting="isExporting" :loadingText="loadingText"></Export-loading>
  </div>
</template>

<script>
const formData = [
  {
    label: "制表月份",
    placeholder: "请选择制表月份",
    decorator: [
      "searchMonth",
      { rules: [{ required: false, message: "请选择制表月份" }] },
    ],
    type: "monthPicker",
    key: "searchMonth",
    col: 5,
    labelCol: 5,
    wrapperCol: 16,
    display: true,
    format: "YYYYMM",
  },
  {
    label: "项目名称",
    placeholder: "请输入项目名称",
    decorator: [
      "projectId",
      { rules: [{ required: false, message: "请输入项目名称" }] },
    ],
    type: "inputGroup",
    key: "projectId",
    dataSource: [],
    col: 5,
    labelCol: 6,
    wrapperCol: 16,
    display: true,
  },
  // {
  //   label: "班组",
  //   placeholder: "请输入选择班组",
  //   decorator: ["teamId", { rules: [{ required: false, message: "请输入选择班组" }] }],
  //   type: "select",
  //   key: "teamId",
  //   selectlist: [],
  //   col: 6,
  //   labelCol: 6,
  //   wrapperCol: 14,
  //   display: true,
  //   showSearch: true
  // },
  {
    type: "action",
    col: 6,
    display: true,
  },

];
import StandardTable from "@/components/table/StandardTable";
import formIndex from "@/pages/components/form/index";
import { mapGetters} from "vuex";
import { exportProgress } from "@/pages/aqjyzl/export"; // 导出方法
import ExportLoading from '@/components/ExportLoading/ExportLoading' // progress组件
import moment from 'moment';
import { getProStaffList, getProStaffPayrollDetail } from '@/services/payroll'
import { getOfficeProject } from "@/services/projectManagement";
import ProSummaryStaffDialog from '@/pages/payroll/dialogs/proSummaryStaffDialog';
import {getAllTeams} from "@/services/participationUnit"
import { valueFormatToTimestamp } from '@/utils/valueFormat.js'
export default {
  mixins: [exportProgress], //导出方法注入
  components: {
    StandardTable, //表格封装
    formIndex,
    ExportLoading,
    ProSummaryStaffDialog
  },
  name: 'SuijianClientOfficeSummaryStaffList',

  data() {
    const columns = [
      {
        title: "序号",
        width: 65,
        scopedSlots: { customRender: "index" },
        align: "center",
      },
      {
        title: "姓名",
        dataIndex: "name",
        align: "center",
        width: 80,
        scopedSlots: { customRender: "projectName" },
      },
      {
        title: "性别",
        dataIndex: "sex",
        align: "center",
        width: 80,
        ellipsis: true,
      },
      {
        title: "身份证号码",
        dataIndex: "idCard",
        align: "center",
        // ellipsis: true,
        // fixed: "left",
      },
      {
        title: "银行卡号",
        dataIndex: "bankCard",
        align: "center",
      },
      {
        title: '所属项目班组',
        dataIndex: 'remarks',
        align: "center",
        ellipsis: true,
        tooltip: true
      },
      {
        title: '工资月份',
        dataIndex: 'startMonth',
        width: 100,
        align: "center",
      },
      {
        title: "基本工资（元）",
        align: "center",
        width: 80,
        dataIndex: "baseMoney",
        scopedSlots: { customRender: "teamCount" },
      },
      {
        title: "绩效工资（元）",
        dataIndex: "achievementsMoney",
        width: 80,
        align: "center",
        scopedSlots: { customRender: "peopleCount" },
      },
      {
        title: "加班工资（元）",
        dataIndex: "overtimeMoney",
        width: 80,
        align: "center",
        scopedSlots: { customRender: "peopleCount" },
      },
      {
        title: "高温津贴（元）",
        dataIndex: "highTempSubsidy",
        width: 80,
        align: "center",
        scopedSlots: { customRender: "peopleCount" },
      },
      {
        title: "代扣个人所得税（元）",
        dataIndex: "tax",
        width: 120,
        align: "center",
        scopedSlots: { customRender: "peopleCount" },
      },
      {
        title: "实发工资（元）",
        dataIndex: "actualMoney",
        width: 80,
        align: "center",
        scopedSlots: { customRender: "peopleCount" },
      },
      {
        title: "手机号",
        dataIndex: "phone",
        width: 120,
        align: "center",
      },
    ];
    return {
      formData,
      columns, //表格数据
      dataSource: [],
      pagination: {
        showSizeChanger:true,
        pageSizeOptions: ["10", "50", "100", "150"],
        current: 1,
        pageSize: 50,
        total: 0,
        showLessItems: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      params: {
        pageNumber: 1,
        pageSize: 50,
        searchMonth: ''
      },
      loadingText: "数据加载中...",
      cPercent: 0,
      isExporting: false,
      query: this.$route.query,
      projectArr: [],
      tableLoading: false,
      workNumList: {},
      distributeVisible: false,
    };
  },
  watch: {
    loadingText: {
      handler(newName, oldName) {
        this.loadingText = newName;
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  activated() {
    this.query = this.$route.query,
    this.projectArr = [];
    this.projectList = []
    this.getZdata(getOfficeProject, {value:this.query.fullName,item: {key: 'projectId'}},"projectName",'fullName');
    if (this.query.searchMonth && this.query.projectId) {
      this.query.searchMonth ? this.params.searchMonth = this.query.searchMonth : this.params.searchMonth = ''
      this.query.projectId ? this.params.projectId = this.query.projectId : this.params.projectId = undefined
      this.$refs['formindex'].queryObj(this.query)
      this.aqxjlist(this.params);
    } else {
       this.$refs["formindex"].resetFields();
       this.dataSource = []
       this.pagination.total = 0;
       if (this.params.searchMonth == '') return this.$message.error('请选择制表月份')
    }
  },

  methods: {
    // 查看编辑
    viewEditing(e) {
        let obj = this.$refs["formindex"].getFieldValue();
        this.workNumList = {
          idCard: e.idCard,
          searchMonth: obj.searchMonth
        }
        this.distributeVisible = true

    },
    aqxjlist(params) {
      // 日期字符串格式化为时间戳
      // if (params.searchMonth) valueFormatToTimestamp(params, 'searchMonth')
      this.tableLoading = true;
      getProStaffList(params).then(res => {
        if (res.data.code === 0) {
        this.dataSource = res.data.data.list
        this.pagination.total = res.data.data.total;
        } else {
        this.dataSource = []
        this.pagination.total = 0
        this.$message.warning(res.data.msg)
        }
        this.tableLoading = false;
      })
    },
    // 提交表单数据
    handleSubmit(e) {
      let obj = { ...this.params,...e, }
      // 处理查询默认格式
      if (obj.searchMonth == '') return this.$message.error('请选择制表月份')
      obj.pageNumber = 1
      this.pagination.current = 1;
      this.selectedRows = []
      this.aqxjlist(obj);
    },
    handleChange(e) {
     if (e.item.key == 'projectId') {
        this.projectArr = [];
        this.projectList = []
        this.getZdata(getOfficeProject, e, "projectName",'fullName');
      }
    },
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      this.params.pageNumber = e.current;
      this.params.pageSize = e.pageSize;
      this.pagination.current = e.current;
      this.pagination.pageSize = e.pageSize;
      this.aqxjlist(Object.assign({},this.params,obj));
    },
    // 特殊数据处理
    handlSelect(obj) {
      const { val, keys } = obj;
        // this.projectList.forEach((item) => {
        //   if (keys == 'projectId' && item.id == val) {
        //     this.doQueryTeams({ projectId: val })
        //   }
        // })
    },
    getZdata(msg, e, name,title) {
      let data = {
        [name]: e.value,
        officeId: this.user.officeId,
      };
      msg(data).then((res) => {
        if (res.status == 200) {
          const data = res.data.data.list ? res.data.data.list : res.data.data
          data.forEach((item) => {
            this.projectArr.push({
              title: item[title],
              id: item.id,
            });
          });
          this.projectList = data;
          // 获取当前匹配key，赋值相对于数组
          this.formData.forEach((item) => {
            if (e.item.key === item.key) {
              item.dataSource = this.projectArr;
            }
          });
        }
      });
    },
    close() {
      this.params.pageNumber = 1;
      this.params.pageSize = 50;
      this.pagination.current = 1;
      this.pagination.pageSize = 50;
      if (this.query.searchMonth && this.query.projectId) {
        this.query.searchMonth ? this.params.searchMonth = this.query.searchMonth : this.params.searchMonth = undefined
        this.query.projectId ? this.params.projectId = this.query.projectId : this.params.projectId = undefined
        this.$refs['formindex'].queryObj(this.query)
        this.aqxjlist(this.params);
      } else {
        // this.$refs["formindex"].resetFields();
        this.$refs.formindex.setFieldsValue('', {'projectId': ''}, '')
        let obj = this.$refs["formindex"].getFieldValue();
        this.aqxjlist(Object.assign({},this.params,obj));
      }
    },
    //查询所有班组
    doQueryTeams(params){
      getAllTeams(params).then(res => {
        if(res.data.code === 0){
          this.formDataFn('teamId', res.data.data, 'teamName')
        }
      })
    },
    // 对下拉框做数据处理
    formDataFn(key, data, itemName) {
        let arrSelect = []
        let arrSource = []
        data.forEach(item => {
            // 班组模糊匹配dataSource
            arrSource.push({ 'title': item[itemName], 'id': item.id })
            // seletetList选项
            arrSelect.push({ 'name': item[itemName], 'value': item.id })
        })
        this.formData.forEach(item => {
             if (item.key == key) {
                item.dataSource = arrSource
                item.selectlist = arrSelect
            }
        })
    },
  },
};
</script>

<style lang="less" scoped>
.constructiontTable_index {
  padding: 24px;
  background: #fff;
  border-radius: 6px;
  margin-bottom: 10px;
  min-height: 560px;
}
/deep/ .ant-modal-footer {
    text-align: center;
    border-top: none;
    padding-bottom: 24px;
}
/deep/ .ant-modal-body {
  padding-bottom: unset;
}
.ant-btn-text {
  color: #4a86e8;
  background-color: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ant-btn-text:hover {
  cursor: pointer;
  color: #76abf5;
  background-color: transparent;
  border-color: #76abf5;
}
</style>
