<template>
  <a-modal width="1400px" v-model="show" :title="title" :dialog-style="{ top: '0px' }" :footer="null" @cancel="close">
      <a-form-model ref="ruleForm" :model="params" :rules="rules">
        <a-row>
          <a-col :span="8">
            <a-form-model-item label="公司" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }">
              <a-input v-model="params.companyName" disabled placeholder=""/>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="分公司" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" >
              <a-input v-model="params.branchOfficeName" disabled placeholder=""/>

            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="施工单位" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" >
              <a-input v-model="params.zbOfficeName" disabled placeholder=""/>

            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="项目名称" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" >
              <a-input v-model="params.projectName" disabled placeholder=""/>
              
            </a-form-model-item>
          </a-col>
          
       
          <a-col :span="4">
            <a-form-model-item label="月份" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" >
              <a-month-picker v-model="params.ym" disabled  placeholder="" valueFormat="YYYY-MM" />
              
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="提交时间" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" >
              <a-date-picker v-model="params.tjtime" disabled valueFormat="YYYY-MM-DD" />
              
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="提交人" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" >
              <a-input v-model="params.tjname" disabled placeholder=""/>
              
            </a-form-model-item>
          </a-col>


        </a-row>
      </a-form-model>

      <div>
        <a-row type="flex" justify="space-between">
          <a-col :span="12">
            <a-tabs v-model="tabIndex">
                <a-tab-pane key="1" tab="危险性较大的分部分项工程确认表">
                </a-tab-pane>
                <a-tab-pane key="2" tab="超过一定过规模的危险性较大的分部分项工程确认表">
              </a-tab-pane>
            </a-tabs>
          </a-col>
          <a-col :span="4">
            <a-button class="mr-4" type="primary" :loading="sbloading" @click="handleSubmit">保存</a-button>
            <a-button @click="close">返回</a-button>
          </a-col>
        </a-row>

        <a-table v-show="tabIndex == 1"  :columns="columns1" :pagination="false" :data-source="tableList1" bordered
          rowKey="itemId" :scroll="{ y: maxHeight }"
        >
          <template slot="index" slot-scope="text, record, index">
              <span>{{  index + 1 }}</span>
          </template>
          <!-- radio 选择是否包含 -->
          <template slot="isContained" slot-scope="text, record">
            <a-radio-group v-model="record.isContained">
              <a-radio value="1">是</a-radio>
              <a-radio value="0">否</a-radio>
            </a-radio-group>
          </template>
            

          
        </a-table>
        <a-table v-show="tabIndex == 2"  :columns="columns1" :pagination="false" :data-source="tableList2" bordered
          rowKey="itemId" :scroll="{ y: maxHeight }"
        >
          <template slot="index" slot-scope="text, record, index">
              <span>{{  index + 1 }}</span>
          </template>
          <!-- radio 选择是否包含 -->
          <template slot="isContained" slot-scope="text, record">
            <a-radio-group v-model="record.isContained">
              <a-radio value="1">是</a-radio>
              <a-radio value="0">否</a-radio>
            </a-radio-group>
          </template>
            

          
        </a-table>
      </div>

      <!-- <a-row>
        <a-col :span="12"></a-col>
        <a-col :span="12">
          <a-form-model-item :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }" prop="year">
            <div class="flex flex-row-reverse">
              <a-button type="primary" :loading="sbloading" @click="handleSubmit">保存</a-button>
              <a-button class="mr-2" @click="close">返回</a-button>
            </div>
          </a-form-model-item>
        </a-col>
      </a-row> -->
  </a-modal>
</template>

<script>

import { request } from "@/utils/request";
import { mapGetters } from 'vuex'
import moment from "moment";
import { wdgcsummaryAdd,wdgcsummaryUpdate, wdgcsummaryByID, wdgcsummaryGet } from "@/services/dangerousProject";
import { ATTACHMENT_UPLOAD } from "@/services/api";
import mixin from './mixinCf'

const columns1 = [
  {
      title: "序号",
      align: "center",
      scopedSlots: { customRender: "index" },
      width: 65,
  },
  {
    title: '分部分项',
    dataIndex: 'subItem',
    width: 200,
    customRender: (text, record, index) => {
            const obj = {
                children: text,
                attrs: {},
            };
            obj.attrs.rowSpan = record.count1;
            
            return obj;
        },
  },
  {
    title: '工程范围',
    dataIndex: 'scopeDescription',
  },
  {
    title: '是否含有',
    dataIndex: 'isContained',
    width: 165,
    scopedSlots: { customRender: 'isContained' },
  },
]
export default {
  name: 'etDetail',
  computed: {
    ...mapGetters('account', ['user']),
  },
  props: {
      visible: {
          type: Boolean,
          default: false
      },
      isEdit: {
          type: Number,
          default: 0
      },
      id: {
          default: ''
      },
      projectInfo: {
        default: () => {
          return {}
        }
      }
      

  },
  mixins: [mixin],
  data() {
    return {
      // isEdit: 0,
      tabIndex: '1',
      rules: {
        // name: [{ required: true, message: '请输入 ', trigger: 'change' }],
        
      },

      params: {
        "companyId": "24213", //公司id
        "companyName": "王五", //公司名称
        "branchOfficeId": "796", //分公司id
        "branchOfficeName": "张三", //分公司名称
        "zbOfficeId": "796", //总包公司id
        "zbOfficeName": "张三", //总包公司名称
        "projectId": "15547", //项目id
        "projectName": "项目2", //项目名称
        "ym": "2023-10", //月份
        "tjid": 16421, //提交人id
        "tjname": "张三", //提交人姓名
        "tjtime": "2023-10-01" //提交时间
      },
      sbloading: false,
      title: '新增',
      show: false,

      columns1: columns1,
      tableList1: [],
      tableList2: [],
      maxHeight: 400,

    };
  },
 
  watch: {
    visible(val) {
      console.log(val)
      
      if (this.isEdit == 0) {
          this.params = {
            "companyId": this.projectInfo.parentOfficeId, //公司id
            "companyName": this.projectInfo.parentOffice, //公司名称
            "branchOfficeId": this.projectInfo.gcglbId, //分公司id
            "branchOfficeName": this.projectInfo.gcglbName, //分公司名称
            "zbOfficeId": this.projectInfo.buildOffice, //总包公司id
            "zbOfficeName": this.projectInfo.buildOfficeName, //总包公司名称
            "projectId": this.projectInfo.projectId, //项目id
            "projectName": this.projectInfo.projectName, //项目名称
            "ym": "", //月份
            "tjid": this.user.id, //提交人id
            "tjname": this.user.name, //提交人姓名
            "tjtime": '' //提交时间
          }
          let nowDate = moment().format('YYYY-MM-DD')
          this.params.tjtime = nowDate
          this.params.ym = moment().format('YYYY-MM')
          wdgcsummaryGet().then(res => {
            if (res.data.code == 0) {
              let list1 = res.data.data.firstPage;
              let list2 = res.data.data.secondPage;

              this.tableList1 = res.data.data.firstPage.map((item, index) => {
                let count1 = this.calcCount(list1,item,index, "subItem");
                
                return {
                  ...item,
                  count1
                };
              })
              this.tableList2 = res.data.data.secondPage.map((item, index) => {
                let count1 = this.calcCount(list2,item,index, "subItem");
                
                return {
                  ...item,
                  count1
                };
              })
            } else {
              this.$message.error(res.data.msg)
            }
          })
        

        this.title = '新增 危大工程确认表'
      } else {
          this.title = '编辑 危大工程确认表'
          this.params.id = this.id;
          this.getDetail()

      }
      this.maxHeight = document.documentElement.clientHeight - 380
      this.show = val;
    }
  },
  methods: {
    getDetail() {
      wdgcsummaryByID({id: this.params.id}).then(res => {
        if (res.data.code == 0) {
          let {wdgcTempRespVO, wdgcsummarySaveReqVO} = res.data.data;
          this.params = wdgcsummarySaveReqVO;
          this.tableList1 = wdgcTempRespVO.firstPage.map((item, index) => {
            let count1 = this.calcCount(wdgcTempRespVO.firstPage,item,index, "subItem");
            
            return {
              ...item,
              count1
            };
          })
          this.tableList2 = wdgcTempRespVO.secondPage.map((item, index) => {
            let count1 = this.calcCount(wdgcTempRespVO.secondPage,item,index, "subItem");
            
            return {
              ...item,
              count1
            };
          })
          
         
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },

    handleSubmit() {
      this.sbloading = true
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let d = {
            wdgcsummarySaveReqVO: this.params,
            wdgcTempRespVO:{
              firstPage: this.tableList1,
              secondPage: this.tableList2
            }
          } 
          
          if (this.isEdit == 0) {
            wdgcsummaryAdd(d).then(res => {
              if (res.data.code == 0) {
                this.$message.success('提交成功！')
                this.close();
                this.$emit('refresh')
              } else {
                this.$message.error(res.data.msg)
              }
              this.sbloading = false
            })
          } else {
            wdgcsummaryUpdate(d).then(res => {
              if (res.data.code == 0) {
                this.$message.success('修改成功！')
                this.close();
                this.$emit('refresh')
              } else {
                this.$message.error(res.data.msg)
              }
              this.sbloading = false
            })
          }
        } else {
          console.log('error submit!!');
          this.sbloading = false
          return false;
        }
      });
    },
    close() {
      this.resetForm()
      this.$emit('closeDialog')
    },
    resetForm() {
      this.tableList1 = []
      this.tableList2 = []
    },
    
  },
};
</script>

<style lang="less" scoped>
.a_Approval {
  background: #fff;
  border-radius: 10px;
}

.btn_group {
  padding-right: 190px;
}
</style>
