<template>
  <div>
    <div class="a_Approval" v-show="$route.meta.showFather">
      <div class="plug-Overview">已拆除设备表</div>
      <a-divider />
      <form-index
        ref="formindex"
        :formData="formData"
        flexAction
        @handleSubmit="handleSubmit"
      >
        <!-- 按钮插槽 -->
        <template #flexAction>
          <a-space class="btnBox">
            <a-button type="primary" html-type="submit">查询</a-button>
          </a-space>
        </template>
      </form-index>
      <div class="a_a_footer">
        <!-- 表格封装 -->
        <standard-table
          class=""
          :columns="columns"
          :dataSource="dataSource"
          :selectedRows.sync="selectedRows"
          rowKey="id"
          :pagination="pagination"
          :loading="tableLoading"
          @change="onPageChange"
          :isAlert="false"
          :bordered="true"
          @update:selectedRows="updateSelectRows($event)"
          :btnArr="tableBtnArr"
        >
          <template slot="index" slot-scope="{ index }">
            <span>
              {{
                (pagination.current - 1) * pagination.pageSize +
                parseInt(index) +
                1
              }}
            </span>
          </template>
          <div slot="action" slot-scope="{ record }">
            <div class="sTables">
              <div @click="visit(record)">查看</div>
            </div>
          </div>
        </standard-table>
        <Export-loading
          :cPercent="cPercent"
          :isExporting="isExporting"
          :loadingText="loadingText"
        ></Export-loading>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import { QUERY_OFFICE_BY_USER, EXPORT_FACILITY_BREAK } from "@/services/api";
import { request } from "@/utils/request";
import { getFacilityInfoList, delFacility, breakFacilityInfo } from "@/services/equipmentLedger";
import { exportProgress } from "@/pages/aqjyzl/export"; // 导出方法
import ExportLoading from "@/components/ExportLoading/ExportLoading"; // progress组件
import { useRender } from '@/hooks/useRender.js'

const formData = [
  {
    label: "工程名称",
    placeholder: "请选择工程名称",
    decorator: [
      "projectName",
      { rules: [{ required: false, message: "请选择工程名称" }] },
    ],
    type: "input",
    key: "projectName",
    col: 5,
    labelCol: 8,
    wrapperCol: 14,
  },
  {
    label: '工程管理部',
    placeholder: '请选择工程管理部',
    decorator: ['gcglbId', { rules: [{ required: false, message: '请选择工程管理部' }] }],
    type: 'select',
    key: 'gcglbId',
    selectlist: [],
    qb:true,
    col:5,
    labelCol: 8,
    wrapperCol:14,
  },
  {
    label: "产权登记编号",
    placeholder: "请选择产权登记编号",
    decorator: [
      "ptype",
      { rules: [{ required: false, message: "请选择产权登记编号" }] },
    ],
    type: "input",
    key: "ptype",
    col: 6,
    labelCol: 10,
    wrapperCol: 14,
  },
  {
    label: "设备类型",
    placeholder: "请选择",
    decorator: [
      "facilityType",
      { rules: [{ required: false, message: "请选择" }] },
    ],
    type: "select",
    key: "facilityType",
    col: 5,
    labelCol: 8,
    wrapperCol: 14,
    selectlist: [
      {
        value: 1,
        name: "塔式起重机",
      },
      {
        value: 2,
        name: "施工升降机",
      },
      {
        value: 3,
        name: "物料提升机",
      },
    ],
  },
  {
    type: 'action',
    col: 2
  }
];
const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
  },
  {
    title: "项目名称",
    dataIndex: "projectName",
    width: 200,
    ellipsis: true,
    tooltip: true
  },
  {
    title: "设备类型",
    dataIndex: "facilityTypeName",
    align: "center",
    width: 130,
    scopedSlots: { customRender: "facilityTypeName" },
  },
  {
    title: "工程管理部",
    dataIndex: "gcglbName",
    align: "center",
    width: 110,
    scopedSlots: { customRender: "gcglbName" },
  },
  {
    title: "设备型号",
    align: "center",
    width: 100,
    dataIndex: "facilityNum",
    ellipsis: true,
    tooltip: true
  },
  {
    title: "自编号",
    dataIndex: "selfNum",
    width: 80,
    align: "center",
    ellipsis: true,
    scopedSlots: { customRender: "selfNum" },
  },
  {
    title: "产权登记编号",
    dataIndex: "equityNum",
    align: "center",
    width: 130,
    scopedSlots: { customRender: "equityNum" },
  },
  // {
  //   title: "检测单位名称",
  //   dataIndex: "checkUnit",
  //   align: "center",
  //   width: 120,
  //   ellipsis: true,
  //   key: "checkUnit",
  // },
  // {
  //   title: "设备检验报告编号",
  //   dataIndex: "checkReportNum",
  //   align: "center",
  //   width: 120,
  //   key: "checkReportNum",
  // },
  // {
  //   title: "最新检验日期",
  //   dataIndex: "checkDateString",
  //   align: "center",
  //   width: 130,
  //   key: "checkDateString",
  //   customRender: (text) => useRender.renderDate(text)
  // },
  // {
  //   title: "使用登记证编号",
  //   dataIndex: "usingNum",
  //   align: "center",
  //   width: 120,
  //   key: "usingNum",
  // },
  // {
  //   title: "登记证有效截止日期",
  //   dataIndex: "usingEndTime",
  //   align: "center",
  //   width: 120,
  //   key: "usingEndTime",
  //   customRender: (text) => useRender.renderDate(text)
  // },
  {
    title: "拆除日期",
    dataIndex: "splitDate",
    align: "center",
    width: 120,
    key: "splitDate",
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "操作",
    align: "center",
    width: 110,
    scopedSlots: { customRender: "action" },
  },
];
export default {
  mixins: [exportProgress], //导出方法注入
  components: {
    formIndex,
    StandardTable,
    ExportLoading, //导出数据加载封装
  },
  data() {
    return {
      formData: formData,
      columns: columns,
      dataSource: [], //table数组
      selectedRows: [], //选中的数据
      pagination: {
        //分页数据
        current: 1,
        pageSize: 10,
        total: 0,
      },
      params: {
        //测试数据
        pageNumber: 1,
        pageSize: 10,
        isSplit: 1,
        // facilityType: 1 //设备类别：1：塔式起重机，2：施工升降机，3：物料提升机
      },
      tableLoading: false, //table加载中
      loadingText: "数据加载中...",
      cPercent: 0,
      isExporting: false,
      tableBtnArr: [
        {
          name: '导出',
          attrs: {
            type: 'primary'
          },
          click: this.doDowload
        }
      ]
    };
  },
  watch: {
    loadingText: {
      handler(newName, oldName) {
        this.loadingText = newName;
      },
      immediate: true,
    },
  },
  activated() {
    this.getgcglbList(QUERY_OFFICE_BY_USER);
    // switch(this.$route.name) {
    //   case '塔式起重机': {
    //     this.params.facilityType = 1
    //     break
    //   }
    //   case '施工升降机': {
    //     this.params.facilityType = 2
    //     break
    //   }
    //   case '物料提升机': {
    //     this.params.facilityType = 3
    //     break
    //   }
    // }
    this.aqxjlist(this.params);
  },
  methods: {
    breakFacility() {
      if (this.selectedRows.length < 1) {
        this.$message.warning('请选择设备')
      } else {
        this.teamDialogVisible = true
      }
    },
    handleBreak() {
      console.log(this.selectedRows)
      
      // breakFacilityInfo().then(res => {
        
      // })
    },
    /**
     * 导出excel
     */
     doDowload() {
      let obj = this.$refs["formindex"].getFieldValue();
      // obj.facilityType = this.params.facilityType;
      obj.isSplit = this.params.isSplit;
      this.loadingText = "正在导出EXCEL文件，请稍候 ····";
      this.isExporting = true;
      this.cPercent = 0;
      let fileName = '已拆除设备表'
      // switch(this.params.facilityType) {
      //   case 1: {
      //     fileName = '塔式起重机'
      //     break
      //   }
      //   case 2: {
      //     fileName = '施工升降机'
      //     break
      //   }
      //   case 3: {
      //     fileName = '物料提升机'
      //     break
      //   }
      // }
      this.exportFunNomal2(
        EXPORT_FACILITY_BREAK,
        obj,
        fileName,
        "application/vnd.ms-excel"
      );
    },
    // 获取工程管理部数据
    getgcglbList(path) {
      request(path, "get").then((res) => {
        let data = res.data.data.filter(
          (item) => item.id != "3da0f5533f87425c9a9e8a11a7923300"
        );
        this.formDataFn("gcglbId", data);
      });
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      let arr = [];
      data.forEach((item) => {
        arr.push({ name: item.master, value: item.id });
      });
      this.formData.forEach((item) => {
        if (item.key == key) {
          item.selectlist = arr;
        }
      });
    },
    aqxjlist(params) {
      this.tableLoading = true;
      getFacilityInfoList(params).then((res) => {
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
        }
        this.tableLoading = false;
      });
    },
    handleSubmit(e) {
      this.params.pageNumber = 1
      this.pagination.current = 1;
      let obj = Object.assign({}, e, this.params);
      this.aqxjlist(obj);
    },
    //表格 - 分页查询
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      this.params.pageNumber = e.current;
      this.pagination.current = e.current;
      this.aqxjlist(Object.assign({}, this.params, obj));
    },
    //查看
    visit(record) {
      let name = ''
      switch(record.facilityType) {
        case 1: {
           name = "已拆除塔式起重机详情"
          break
        }
        case 2: {
          name = "已拆除施工升降机详情"
          break
        }
        case 3: {
          name = "已拆除物料提升机详情"
          break
        }
        case 4: {
          name = "已拆除门式起重机详情"
          break
        }
        case 5: {
          name = "已拆除桥式起重机详情"
          break
        }
      }
      this.$router.push({
        name,
        query: {
          id: record.id,
          facilityType: record.facilityType
        },
      });
    },
    del(record) {
      const _that = this
      this.$confirm({
          title: '是否确定删除？',
          cancelText: '否',
          okText: '是',
          onOk: () => {
            delFacility(record.id).then(res => {
              if (res.data.code === 0) {
                _that.$message.success('删除成功')
                this.aqxjlist(this.params);
              } else {
                _that.$message.error(res.data.msg)
              }
            })
          },
        })
    },
    updateSelectRows(e) {
      console.log(e);
    },
  },
};
</script>

<style lang="less" scoped>
.a_Approval {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
}
.a_a_footer {
  background: #ffffff;

  .operator {
    margin-right: 8px;

    .ant-btn {
      color: #307dfa;
    }
  }

  .sTables {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      width: 100%;
      text-align: center;
      cursor: pointer;
    }

    div:nth-child(1) {
      color: #307dfa;
    }

    div:nth-child(2) {
      color: #ff5c77;
    }

    div:nth-child(3) {
      color: #615e83;
    }
  }
}
.content_div {
  padding: 5px 0;
  cursor: pointer;
}
.content_div:hover {
  color: #307dfa;
}
</style>
