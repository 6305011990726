<template>
  <div>
    <a-card title="防坠器检验记录" class="a_Approval" :bordered="false">
      <form-index
        ref="formindex"
        :formData="formData"
        @handleSubmit="submit"
        :bottomSubmit="true"
        @resourceUpload="resourceUpload"
        @upRemove="upRemove"
      >
        <!-- 下部分按钮插槽 -->
        <template #bottomSubmit>
          <a-button type="primary" html-type="submit"> 确定 </a-button>
          <a-button @click="close"> 关闭 </a-button>
        </template>
      </form-index>
    </a-card>
  </div>
</template>

<script>
const formData = [
  {
    label: "项目名称",
    placeholder: "请输入项目名称",
    decorator: [
      "projectName",
      { rules: [{ required: true, message: "请输入项目名称" }] },
    ],
    type: "inputGroup",
    key: "projectName",
    dataSource: [],
    col: 12,
    labelCol: 6,
    wrapperCol: 8,
    display: true,
    disabled: true
  },
  {
    label: '工程管理部',
    placeholder: '请选择工程管理部',
    decorator: ['gcglbId', { rules: [{ required: true, message: '请选择工程管理部' }] }],
    type: 'select',
    key: 'gcglbId',
    selectlist: [],
    qb:true,
    col: 12,
    labelCol: 6,
    wrapperCol: 8,
    disabled: true
  },
  {
    label: "自编号",
    placeholder: "请选择",
    decorator: [
      "selfNum",
      { rules: [{ required: true, message: "请选择" }] },
    ],
    type: "input",
    key: "selfNum",
    col: 12,
    labelCol: 6,
    wrapperCol: 8,
    display: true,
    disabled: true
  },
  {
    label: "产权登记编号",
    placeholder: "请选择",
    decorator: [
      "equityNum",
      { rules: [{ required: true, message: "请选择" }] },
    ],
    type: "input",
    key: "equityNum",
    col: 12,
    labelCol: 6,
    wrapperCol: 8,
    display: true,
    disabled: true
  },
  {
    label: "出厂编号（左）",
    placeholder: "请选择",
    decorator: [
      "factoryNumLeft",
      { rules: [{ required: true, message: "请选择" }] },
    ],
    type: "input",
    key: "factoryNumLeft",
    col: 12,
    labelCol: 6,
    wrapperCol: 8,
    display: true,
  },
  {
    label: "出厂编号（右）",
    placeholder: "请选择",
    decorator: [
      "factoryNumRight",
      { rules: [{ required: true, message: "请选择" }] },
    ],
    type: "input",
    key: "factoryNumRight",
    col: 12,
    labelCol: 6,
    wrapperCol: 8,
    display: true,
  },
  {
    label: "出厂日期（左）",
    placeholder: "请选择",
    decorator: [
      "factoryDateLeft",
      { rules: [{ required: true, message: "请选择" }] },
    ],
    type: "datePicker",
    key: "factoryDateLeft",
    col: 12,
    labelCol: 6,
    wrapperCol: 8,
    display: true,
    format: "yyyy-MM-DD",
    styles: {width: '100%'}
  },
  {
    label: "出厂日期（右）",
    placeholder: "请选择",
    decorator: [
      "factoryDateRight",
      { rules: [{ required: true, message: "请选择" }] },
    ],
    type: "datePicker",
    key: "factoryDateRight",
    col: 12,
    labelCol: 6,
    wrapperCol: 8,
    display: true,
    format: "yyyy-MM-DD",
    styles: {width: '100%'}
  },
  {
    label: "标定有效期（左）",
    placeholder: "请选择",
    decorator: [
      "usefulDateLeft",
      { rules: [{ required: true, message: "请选择" }] },
    ],
    type: "datePicker",
    key: "usefulDateLeft",
    col: 12,
    labelCol: 6,
    wrapperCol: 8,
    display: true,
    format: "yyyy-MM-DD",
    styles: {width: '100%'}
  },
  {
    label: "标定有效期（右）",
    placeholder: "请选择",
    decorator: [
      "usefulDateRight",
      { rules: [{ required: true, message: "请选择" }] },
    ],
    type: "datePicker",
    key: "usefulDateRight",
    col: 12,
    labelCol: 6,
    wrapperCol: 8,
    display: true,
    format: "yyyy-MM-DD",
    styles: {width: '100%'}
  },
  {
    label: "上传检验报告",
    placeholder: "请上传",
    decorator: ["fallingCheckFile", { rules: [{ required: false, message: "请上传" }] }],
    type: "tsUpload",
    listType: 'picture-card',
    accept: ".pdf",
    key: "fallingCheckFile",
    defaultFileList: [],
    col: 12,
    labelCol: 6,
    wrapperCol: 8,
    display: true,
  },
];
import formIndex from "@/pages/components/form/index";
import { QUERY_OFFICE_BY_USER } from "@/services/api";
import { request } from "@/utils/request";
import { mapGetters } from 'vuex'
import { insertFacilityFallingCheckRecord, getFacilityFallingCheckRecordDetail, updateFacilityFallingCheckRecord } from "@/services/equipmentLedger";
export default {
  components: {
    formIndex,
  },
  inject: ["fatherMethod"],
  computed: {
    ...mapGetters('account', ['user']),
    ...mapGetters('equipment', ['towerCrane', 'constructionHoist', 'materialHois', 'portalCrane', 'bridgeCrane']),
  },
  data() {
    return {
      isEdit: 0,
      formData: formData,
      detailObj: null, //保存详情的对象
      params: {
        facilityId: "",
        projectId: '',
        facilityType: null
      },
    };
  },

  activated() {
    this.params.facilityId = this.$route.query.facilityId;
    this.isEdit = Number(this.$route.query.isEdit);
    this.params.facilityType = Number(this.$route.query.facilityType);
    if (this.isEdit === 0) {
      let obj = null
      switch(this.params.facilityType) {
        case 1: 
          obj = this.towerCrane
          break
        case 2: 
          obj = this.constructionHoist
          break
        case 3: 
          obj = this.materialHois
          break
      }
      this.$refs.formindex.queryObj({
        projectName: obj.projectName,
        gcglbId: obj.gcglbId,
        selfNum: obj.selfNum,
        equityNum: obj.equityNum,
      })
      this.params.projectId = obj.projectId
    } else {
      this.params.id = this.$route.query.id;
      this.getDetail()
    }
    this.getgcglbList(QUERY_OFFICE_BY_USER);
  },

  methods: {
    getDetail() {
      getFacilityFallingCheckRecordDetail(this.params.id).then(res => {
        if (res.data.code === 0) {
          this.detailObj = res.data.data
          this.$refs.formindex.queryObj(res.data.data)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 获取工程管理部数据
    getgcglbList(path) {
      request(path, "get").then((res) => {
        let data = res.data.data.filter(
          (item) => item.id != "3da0f5533f87425c9a9e8a11a7923300"
        );
        this.formDataFn("gcglbId", data);
      });
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      let arr = [];
      data.forEach((item) => {
        arr.push({ name: item.master, value: item.id });
      });
      this.formData.forEach((item) => {
        if (item.key == key) {
          item.selectlist = arr;
        }
      });
    },
    //上传文件回调
    resourceUpload(obj) {
      if (obj.name.includes('pdf')) {
        this.formData.forEach(item => {
          if (item.key === obj.key) {
            item.defaultFileList = [obj]
          }
        })
      } else {
        this.$message.warning(`只能上传PDF文件`)
      }
    },
    //删除上传附件
    upRemove(file) {
      this.formData.forEach(item => {
        if (item.key === file.key) {
          item.defaultFileList = []
        }
      })
    },
    submit(e) {
      if (this.isEdit === 0) {
        this.params = Object.assign(this.params, e)
        // console.log(this.formData)
        insertFacilityFallingCheckRecord(this.params).then((res) => {
          let d = res.data;
          if (d.code === 0) {
            this.$message.success("新增成功");
            this.close();
          } else {
            this.$message.error(res.data.msg);
          }
        });
      } else {
        this.params = Object.assign(this.detailObj, Object.assign(this.params, e))
        updateFacilityFallingCheckRecord(this.params).then((res) => {
          let d = res.data;
          if (d.code === 0) {
            this.$message.success("修改成功");
            this.close();
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
    },
    close() {
      let path = this.$route.path;
      this.fatherMethod(`${path}`);
    },
  },
};
</script>

<style lang="less" scoped>
.a_Approval {
  background: #fff;
  border-radius: 10px;
}
</style>
