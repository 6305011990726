<template>
  <div v-show="$route.meta.showFather" class="a_Approval">
    <template v-if="isProject">
      <div>
        <div>
          <span class="plug-Overview">项目安全风险管控一览表</span>
        </div>
        <a-divider />
        <a-form-model :model="params" class="clearfix" @submit="handleSubmit">
          <a-col :span="5">
            <a-form-model-item label="日期" :labelCol="{ span: 7 }" :wrapperCol="{ span: 14, offset: 1 }">
              <a-range-picker format="YYYY-MM-DD" @change="onChange" v-model="dateGroup" />
            </a-form-model-item>
          </a-col>
          <a-col :span="5">
            <a-form-model-item label="审核状态" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
              <a-select v-model="params.approveStatus" allowClear placeholder="请选择">
                <a-select-option v-for="item in approveList" :key="item.value" :value="item.value" :title="item.name">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="4">
            <a-form-model-item>
              <a-space class="btnBox">
                <a-button type="primary" html-type="submit"> 查询 </a-button>
                <a-button @click="resetQuery"> 重置 </a-button>
              </a-space>
            </a-form-model-item>
          </a-col>
        </a-form-model>
        <div class="a_a_footer">
          <standard-table :columns="columns" :dataSource="tableData" rowKey="id" :pagination="pagination"
            :loading="tableLoading" @change="handleChangePage" :isAlert="false" :scroll="{ x: 1300 }" :bordered="true"
            :btnArr="tableBtnArr">
            <template slot="index" slot-scope="{ index }">
              <span>{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}</span>
            </template>
            <template slot="dateGroup" slot-scope="{ record }">
              <div>
                {{ record.workStartDate }} - {{ record.workEndDate }}
              </div>
            </template>
            <template slot="dangerLevel">
              <div class="circle"></div>
            </template>
            <template slot="approveStatus" slot-scope="{ text }">
              <div>
                {{ text == 0 ? '已提交' : text == 1 ? '已审核' : '已退回' }}
              </div>
            </template>
            <template slot="workContentCode" slot-scope="{ text }">
              <div>
                {{ getWorkContent(text) }}
              </div>
            </template>
          </standard-table>
        </div>
      </div>
    </template>
    <template v-else>
      <div>
        <a-tabs :default-active-key="1" @change="tabCallback">
          <a-tab-pane key="1" tab="项目安全风险分级管控一览表">
          </a-tab-pane>
          <a-tab-pane key="2" tab="项目安全风险管控台账">
          </a-tab-pane>
        </a-tabs>
        <a-form-model :model="params" class="clearfix" @submit="handleSubmit">
          <a-col v-if="isBranchOffice || tabKey == 2" :span="5">
            <a-form-model-item label="日期" :labelCol="{ span: 7 }" :wrapperCol="{ span: 16, offset: 1 }">
              <a-range-picker format="YYYY-MM-DD" @change="onChange" v-model="dateGroup" :disabled="tabKey == 1" />
            </a-form-model-item>
          </a-col>

          <a-col v-if="isJSOffice" :span="5">
            <a-form-model-item label="工程管理部" :labelCol="{ span: 9 }" :wrapperCol="{ span: 14, offset: 1 }">
              <a-select v-model="params.gcglbId" allowClear placeholder="请选择">
                <a-select-option v-for="item in gcglbList" :key="item.value" :value="item.value" :title="item.name">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>

          <a-col v-if="!isProject" :span="5">
            <a-form-model-item label="项目名称" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
              <a-input placeholder="请输入项目名称" v-model="params.projectName" allowClear />
            </a-form-model-item>
          </a-col>

          <a-col v-if="!isJSOffice" :span="5">
            <a-form-model-item label="审核状态" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
              <a-select v-model="params.approveStatus" allowClear placeholder="请选择">
                <a-select-option v-for="item in approveList" :key="item.value" :value="item.value" :title="item.name">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>

          <a-col :span="4">
            <a-space class="btnBox">
              <a-button type="primary" html-type="submit"> 查询 </a-button>
              <a-button @click="resetQuery"> 重置 </a-button>
              <a-button type="primary" v-if="this.tabKey == '2'" @click="doDowload"> 导出 </a-button>
              <span v-if="isJSOffice && tabKey == 1" class="pointer ml-3" style="color: #2BA4FF;border-bottom: 1px solid #2BA4FF;" @click="uploadRecord">上报记录</span>
            </a-space>
          </a-col>
        </a-form-model>

        <div class="a_a_footer">
          <standard-table :columns="columns" :dataSource="tableData" rowKey="id" :pagination="pagination"
            :loading="tableLoading" @change="handleChangePage" :isAlert="false" :scroll="{ x: 1300 }" :bordered="true"
            :btnArr="tableBtnArr">
            <template slot="tableTitle">
              <div>
                <a-space class="operator mb-3">
                  <a-button type="primary" @click="handleUploadNoRisk" v-if="isBranchOffice && tabKey == 1"
                    :disabled="uploadBtnDisabled">{{ uploadRecordText }}</a-button>
                    <span v-if="isBranchOffice && tabKey == 1">下周（{{ moment(this.params.startDate).add(7, 'days').format('YYYY-MM-DD') }} ~ {{ moment(this.params.endDate).add(7, 'days').format('YYYY-MM-DD') }}）</span>
                    <span v-if="isBranchOffice && uploadRecordText == '取消上报'">无危大危险源上报</span>
                </a-space>
              </div>
              <div v-if="isJSOffice">
                <div><span v-if="tabKey == 1">本周（</span>{{ params.startDate }} ~ {{ params.endDate }}<span
                    v-if="tabKey == 1">）计划</span>公司项目安全风险管控情况：</div>
                <template v-if="showStatic(staticObj)">
                  <div v-for="(value, key) in staticObj" :key="key">
                    <span v-if="key == 1 && value.some(el => el.nums > 0)">起重吊装及起重机械安装拆卸工程：</span>
                    <span v-else-if="key == 2 && value.some(el => el.nums > 0)">塔吊顶升：</span>
                    <!-- <span v-else-if="key == 3 && value.some(el => el.nums > 0)">超一定规模板模板工程砼浇捣：</span> -->
                    <span v-for="(item, index) in value" :key="index">
                      <span v-if="item.nums">{{ `${item.workContent}（${item.nums}）；` }}</span>
                    </span>
                  </div>
                </template>
                <template v-else>
                  <div>
                    暂无
                  </div>
                </template>
              </div>
            </template>
            <template slot="index" slot-scope="{ index }">
              <span>{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}</span>
            </template>
            <template slot="dateGroup" slot-scope="{ record }">
              <div>
                {{ record.workStartDate }} - {{ record.workEndDate }}
              </div>
            </template>
            <template slot="dangerLevel">
              <div class="circle"></div>
            </template>
            <template slot="approveStatus" slot-scope="{ text }">
              <div>
                {{ text == 0 ? '已提交' : text == 1 ? '已审核' : '已退回' }}
              </div>
            </template>
            <template slot="workContentCode" slot-scope="{ text }">
              <div>
                {{ getWorkContent(text) }}
              </div>
            </template>
            <template slot="action" slot-scope="{ record }">
              <div v-if="isBranchOffice && record.approveStatus == 0">
                <a @click="() => handleOperate(record, 1)"> 通过 </a>
                <a class="ml-2" @click="() => handleOperate(record, 2)" style="color: FA367A;"> 退回 </a>
              </div>
            </template>
          </standard-table>
        </div>
      </div>
    </template>
    <a-modal title="新增项目安全分级管控" :visible="addNewVisible" :confirm-loading="confirmLoading" @ok="handleOk"
      @cancel="handleCancel" :width="1100">
      <a-form-model layout="inline" :model="addNewParams" class="mb-5 query-form">
        <a-form-model-item label="作业时间" required>
          <a-range-picker style="width: 220px" format="YYYY-MM-DD" @change="onWorkDateChange" v-model="workDateGroup" />
        </a-form-model-item>
        <a-form-model-item label="工作内容" required>
          <a-select v-model="addNewParams.workContentCode" allowClear placeholder="请选择" style="width: 250px"
            @change="handleCodeChange">
            <a-select-option v-for="item in workContentList" :key="item.value" :value="item.value" :title="item.name">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="设备编号" required v-if="showSelfEquity">
          <a-select v-model="addNewParams.selfEquityNum" allowClear placeholder="请选择" style="width: 220px">
            <a-select-option v-for="item in selfEquityList" :key="item.value" :value="item.value" :title="item.name">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal title="上报记录" :visible="uploadRecordVisible" :width="800" :footer="null" @cancel="handleCancelUploadRecord">
      <a-form-model layout="inline" :model="params" class="mb-5 query-form" @submit="handleSubmitUploadRecord">
        <a-form-model-item label="日期">
          <a-range-picker style="width: 220px" format="YYYY-MM-DD" @change="onChangeUploadRecord"
            v-model="uploadRecordDateGroup" />
        </a-form-model-item>
        <a-form-model-item>
          <div>
            <a-button class="mr-9" type="primary" html-type="submit"> 查询 </a-button>
          </div>
        </a-form-model-item>
      </a-form-model>
      <standard-table class="" :columns="uploadRecordColumns" :dataSource="uploadRecordTableData" rowKey="gcglbId"
        :pagination="uploadRecordPagination" :loading="tableLoading" @change="handleChangeUploadRecordPage"
        :isAlert="false" :bordered="true">
        <template slot="index" slot-scope="{ index }">
          <span>{{ (uploadRecordPagination.current - 1) * uploadRecordPagination.pageSize + parseInt(index) + 1 }}</span>
        </template>
        <template slot="submitStatus" slot-scope="{ text }">
          <span style="color: #FA367A;" v-if="text == 0">未上报</span>
          <span v-else>已上报</span>
        </template>
      </standard-table>
    </a-modal>
    <Export-loading :cPercent="cPercent" :isExporting="isExporting" :loadingText="loadingText"></Export-loading>
  </div>
</template>

<script>
import {
  safeLevelList, safeLevelInsert, safeContentList, safeLevelDel, safeLevelUpdate, approveSafeLevel, submitNoDangerWeekly, safeLevelDepartPage, safeLevelStatic, cancelNoDangerWeekly, safeLevelsExport
} from "@/services/safeRisk";
import { getFacilityInfoList } from "@/services/equipmentLedger";
import { request } from "@/utils/request";
import { QUERY_OFFICE_BY_USER } from "@/services/api";
import { mapGetters } from "vuex";
import StandardTable from "@/components/table/StandardTable";
import moment from "moment";
import ExportLoading from "@/components/ExportLoading/ExportLoading"; // progress组件
import { exportProgress } from "@/pages/aqjyzl/export"; // 导出方法
import { useRender } from '@/hooks/useRender.js'
import { valueFormatToTimestamp } from '@/utils/valueFormat.js'

const columns = [
  {
    title: "序号",
    align: "center",
    scopedSlots: { customRender: "index" },
    width: 65,
  },
  {
    title: "工程管理部",
    align: "center",
    dataIndex: "gcglbName",
    width: 120,
  },
  {
    title: "项目名称",
    dataIndex: "projectName",
    width: 280,
    ellipsis: true,
    tooltip: true
  },
  {
    title: "作业时间",
    dataIndex: "dateGroup",
    align: "center",
    width: 210,
    scopedSlots: { customRender: "dateGroup" },
  },
  {
    title: "工作内容",
    dataIndex: "workContentCode",
    align: "center",
    width: 180,
    scopedSlots: { customRender: "workContentCode" },
  },
  {
    title: "设备编号",
    dataIndex: "selfEquityNum",
    align: "center",
    width: 180,
    scopedSlots: { customRender: "selfEquityNum" },
  },
  {
    title: "风险等级",
    dataIndex: "dangerLevel",
    align: "center",
    width: 80,
    scopedSlots: { customRender: "dangerLevel" },
  },
  {
    title: "提交时间",
    dataIndex: "createTime",
    align: "center",
    width: 210,
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "审核状态",
    dataIndex: "approveStatus",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "approveStatus" },
  },
  {
    title: "审核时间",
    dataIndex: "approveTime",
    align: "center",
    width: 210,
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "操作",
    align: "center",
    dataIndex: "action",
    // fixed: "right",
    width: 110,
    scopedSlots: { customRender: "action" },
  },
];

const uploadRecordColumns = [
  {
    title: "序号",
    align: "center",
    scopedSlots: { customRender: "index" },
    width: 65,
  },
  {
    title: "工程管理部",
    align: "center",
    dataIndex: "gcglbName",
    width: 100,
  },
  {
    title: "上报状态",
    dataIndex: "submitStatus",
    ellipsis: true,
    width: 100,
    scopedSlots: { customRender: "submitStatus" },
    align: "center",
  },
  {
    title: "无危大危险源上报确认时间",
    dataIndex: "submitDate",
    align: "center",
    width: 130,
    customRender: (text) => useRender.renderDate(text)
  },
]

export default {
  mixins: [exportProgress], //导出方法注入
  components: {
    ExportLoading,
    StandardTable
  },
  data() {
    return {
      tabKey: 1,
      addNewVisible: false,
      confirmLoading: false,
      idEdit: false,
      dateGroup: [],
      uploadRecordDateGroup: [moment(new Date()).isoWeekday(1).format('YYYY-MM-DD'), moment(new Date()).isoWeekday(7).format('YYYY-MM-DD')],
      workDateGroup: [],
      params: {
        approveStatus: '',
        endDate: '',
        gcglbId: '',
        projectName: '',
        startDate: '',
        pageNumber: 1,
        pageSize: 10,
        isNow: 1
      },
      addNewParams: {
        workStartDate: '',
        workEndDate: '',
        workContentCode: '',
        projectId: '',
        selfEquityNum: ''
      },
      workContentList: [],
      pagination: {
        //分页数据
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total, range) => `共 ${total} 条`,
      },
      gcglbList: [],
      total: 0,
      columns: columns,
      tableData: [],
      tableLoading: false, //table加载中
      staticObj: {},
      approveList: [
        {
          value: 0,
          name: '已提交'
        },
        {
          value: 1,
          name: '已审核'
        },
        {
          value: 2,
          name: '已退回'
        },
      ],
      uploadRecordParams: {
        endDate: '',
        startDate: '',
        pageNumber: 1,
        pageSize: 5,
      },
      uploadRecordPagination: {
        //分页数据
        current: 1,
        pageSize: 5,
        total: 0,
        showTotal: (total, range) => `共 ${total} 条`,
      },
      uploadRecordVisible: false,
      uploadRecordColumns: uploadRecordColumns,
      uploadRecordTableData: [],
      uploadRecordText: '下周无危大危险源上报',
      isUploaded: false,
      uploadBtnDisabled: false,
      showSelfEquity: false,
      selfEquityList: [],
      loadingText: "数据加载中...",
      cPercent: 0,
      isExporting: false,
    };
  },
  created() {
    this.getgcglbList(QUERY_OFFICE_BY_USER);
    this.getSafeContentList()

  },
  activated() {
    if (!this.isProject) {
      this.dateGroup = [moment(new Date()).isoWeekday(1).format('YYYY-MM-DD'), moment(new Date()).isoWeekday(7).format('YYYY-MM-DD')]
    }
    this.handleQuery();
    if (this.isJSOffice) {
      this.columns = this.columns.filter(el => el.title != '操作')
      this.columns = this.columns.filter(el => el.title != '提交时间')
      this.columns = this.columns.filter(el => el.title != '审核状态')
      this.columns = this.columns.filter(el => el.title != '审核时间')
    }
    if (this.isBranchOffice) {
      this.getNoDangerWeeklyBtn()
    }
  },
  computed: {
    ...mapGetters("account", ["user"]),
    isProject() { //项目
      return this.user.loginType == 0
    },
    isBranchOffice() { //分公司
      return this.user.isGcglb == 1 && this.user.loginType == 1
    },
    isJSOffice() { //机施
      return this.user.isGcglb == 0 && this.user.loginType == 1
    },
    showTips() {
      let isShow = false
      for (const key in this.staticObj) {
        if (Object.hasOwnProperty.call(this.staticObj, key)) {
          const element = this.staticObj[key];
          isShow = element.some(el => el.nums > 0)
          if (isShow) break
        }
      }
      return isShow
    },
    tableBtnArr() {
      return [
        {
          name: '新增',
          attrs: {
            type: 'primary'
          },
          hidden: !this.isProject,
          click: this.addNewRisk
        },
        // {
        //   name: '导出',
        //   attrs: {
        //     type: 'primary'
        //   },
        //   hidden: this.tabKey !== '2',
        //   click: this.doDowload
        // }
      ]
    }
  },
  methods: {
    moment,
    doDowload() {
      let obj = this.params;
      this.loadingText = "正在导出EXCEL文件，请稍候 ····";
      this.isExporting = true;
      this.cPercent = 0;
      let fileName = "项目安全风险管控台账.xlsx";
      this.exportFunNomal2(
        safeLevelsExport,
        obj,
        fileName,
        "application/vnd.ms-excel"
      );
    },
    handleCodeChange(e) {
      if (e == 'holting' || e == 'out_model') {
        this.showSelfEquity = false
        this.params.selfEquityNum = ''
      } else {
        this.showSelfEquity = true
        if (e == 'tower_install') {
          this.getFacilityInfoList(1, 3)
        } else if (e == 'tower_split' || e == 'tower_wall') {
          this.getFacilityInfoList(1, 0)
        } else if (e == 'updown_install') {
          this.getFacilityInfoList(2, 3)
        } else if (e == 'updown_split' || e == 'updown_wall') {
          this.getFacilityInfoList(2, 0)
        }
      }
    },
    showStatic(obj) {
      if (obj) {
        for (const key in obj) {
          if (Object.hasOwnProperty.call(obj, key)) {
            const el = obj[key];
            if (el.some(item => item.nums > 0)) {
              return true
            }
          }
        }
      } else {
        return false
      }
    },
    tabCallback(key) {
      this.tabKey = key
      if (key == 1) {
        this.dateGroup = [moment(new Date()).isoWeekday(1).format('YYYY-MM-DD'), moment(new Date()).isoWeekday(7).format('YYYY-MM-DD')],
          this.params.isNow = 1
      } else {
        this.params.isNow = 0
      }
      this.handleQuery()
    },
    handleEdit(row) {
      this.addNewParams.workStartDate = row.workStartDate
      this.addNewParams.workEndDate = row.workEndDate
      this.addNewParams.workContentCode = row.workContentCode
      this.addNewParams.projectId = row.projectId
      this.addNewParams.selfEquityNum = row.selfEquityNum
      this.addNewParams.id = row.id
      this.workDateGroup = [row.workStartDate, row.workEndDate]
      this.addNewVisible = true
      this.idEdit = true
    },
    getSafeLevelStatic() {
      safeLevelStatic(this.params).then(res => {
        let d = res.data;
        if (d.code === 0) {
          this.staticObj = d.data
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    getSafeContentList() {
      safeContentList({ pageSize: 9999, pageNumber: 1 }).then(res => {
        if (res.data.code === 0) {
          const arr = [];
          res.data.data.list.forEach((item) => {
            arr.push({
              name: item.workContent,
              value: item.workContentCode,
            });
          });
          this.workContentList = arr;
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    getFacilityInfoList(facilityType, status) {
      getFacilityInfoList({ pageSize: 9999, pageNumber: 1, isSplit: 0, status, facilityType }).then(res => {
        if (res.data.code === 0) {
          const arr = [];
          if (res.data.data.list.length > 0) {
            res.data.data.list.forEach((item) => {
              arr.push({
                name: `${item.selfNum}(${item.equityNum})`,
                value: `${item.selfNum}(${item.equityNum})`,
              });
            });
          } else {
            this.$confirm({
              title: '本项目暂无使用中大型设备',
              cancelButtonProps: { style: { display: 'none' } }, // 隐藏取消按钮
              onOk() { }
            })
          }
          this.selfEquityList = arr;
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    handleUploadNoRisk() {
      let _that = this
      let obj = {
        startDate: moment(this.params.startDate).add(7, 'days').format('YYYY-MM-DD'),
        endDate: moment(this.params.endDate).add(7, 'days').format('YYYY-MM-DD'),
        gcglbId: this.user.officeLoginData.officeId,
        userId: this.user.id
      }
      let title = this.isUploaded ? '确认取消？' : '确认上报？'
      this.$confirm({
        title,
        onOk() {
          if (_that.isUploaded) {
            cancelNoDangerWeekly(obj).then(res => {
              if (res.data.code === 0) {
                _that.$message.success('操作成功')
                _that.uploadRecordText = '下周无危大危险源上报'
                _that.isUploaded = false
              } else {
                _that.$message.error(res.data.msg)
              }
            })
          } else {
            submitNoDangerWeekly(obj).then(res => {
              if (res.data.code === 0) {
                _that.$message.success('操作成功')
                _that.uploadRecordText = '取消上报'
                _that.isUploaded = true
              } else {
                _that.$message.error(res.data.msg)
              }
            })
          }
        }
      })
    },
    //分公司判断无危大危险源上报按钮
    getNoDangerWeeklyBtn() {
      let obj = {
        startDate: moment(this.params.startDate).add(7, 'days').format('YYYY-MM-DD'),
        endDate: moment(this.params.endDate).add(7, 'days').format('YYYY-MM-DD'),
        gcglbId: this.user.officeLoginData.officeId
      }
      safeLevelList(obj).then((res) => {
        let d = res.data;
        if (d.code === 0) {
          if (d.data.length > 0) {
            this.uploadRecordText = '下周已有危大危险源无需上报'
            this.uploadBtnDisabled = true
          } else {
            this.uploadBtnDisabled = false
            safeLevelDepartPage(obj).then(res => {
              if (res.data.code === 0) {
                if (res.data.data[0].submitStatus == 0) {
                  this.uploadRecordText = '下周无危大危险源上报'
                  this.isUploaded = false
                } else {
                  this.uploadRecordText = '取消上报'
                  this.isUploaded = true
                }
              } else {
                this.$message.error(res.data.msg)
              }
            })
          }
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    uploadRecord() {
      this.uploadRecordVisible = true
      this.uploadRecordParams.startDate = this.uploadRecordDateGroup[0]
      this.uploadRecordParams.endDate = this.uploadRecordDateGroup[1]
      this.getSafeLevelDepartPage()
    },
    getSafeLevelDepartPage() {
      safeLevelDepartPage(this.uploadRecordParams).then(res => {
        if (res.data.code === 0) {
          this.uploadRecordTableData = res.data.data.list
          this.uploadRecordPagination.total = res.data.data.total;
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    handleDel(record) {
      const _that = this
      this.$confirm({
        title: '是否确定删除？',
        cancelText: '否',
        okText: '是',
        onOk: () => {
          safeLevelDel(record.id).then(res => {
            if (res.data.code === 0) {
              _that.$message.success('删除成功')
              this.handleQuery();
            } else {
              _that.$message.error(res.data.msg)
            }
          })
        },
      })
    },
    addNewRisk() {
      this.addNewVisible = true
      this.idEdit = false
    },
    handleSubmit(e) {
      e.preventDefault();
      this.params.pageNumber = 1
      this.pagination.current = 1
      this.tableData = []
      this.handleQuery();
    },
    handleSubmitUploadRecord(e) {
      e.preventDefault();
      this.uploadRecordParams.pageNumber = 1
      this.uploadRecordPagination.current = 1
      this.uploadRecordTableData = []
      this.getSafeLevelDepartPage()
    },
    handleQuery(e) {
      this.tableLoading = true
      this.params.startDate = this.dateGroup[0]
      this.params.endDate = this.dateGroup[1]
      if (this.isJSOffice) this.params.approveStatus = 1
      safeLevelList(this.params).then((res) => {
        this.tableLoading = false
        let d = res.data;
        if (d.code === 0) {
          this.tableData = d.data.list
          this.pagination.total = d.data.total;
          this.total = d.data.total;
          if (this.isJSOffice) {
            this.getSafeLevelStatic()
          }
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    handleChangePage(e) {
      this.params.pageNumber = e.current;
      this.pagination.current = e.current;
      this.handleQuery();
    },
    handleChangeUploadRecordPage(e) {
      this.uploadRecordParams.pageNumber = e.current;
      this.uploadRecordPagination.current = e.current;
      this.uploadRecord();
    },
    // 获取工程管理部数据
    getgcglbList(path) {
      request(path, "get").then((res) => {
        let data = res.data.data.filter(item => item.id != "3da0f5533f87425c9a9e8a11a7923300")
        const arr = [];
        data.forEach((item) => {
          arr.push({
            name: item.master,
            value: item.id,
          });
        });
        this.gcglbList = arr;
      });
    },
    resetQuery() {
      this.pagination.pageNumber = 1
      this.params = {
        approveStatus: '',
        endDate: '',
        gcglbId: '',
        projectName: '',
        startDate: '',
        pageNumber: 1,
        pageSize: 10,
      };
      this.handleQuery();
    },
    onChange(date, dateString) {
      // console.log(date, dateString);
      this.dateGroup = dateString
      this.params.startDate = dateString[0]
      this.params.endDate = dateString[1]
    },
    onChangeUploadRecord(date, dateString) {
      this.uploadRecordDateGroup = dateString
      this.uploadRecordParams.startDate = dateString[0]
      this.uploadRecordParams.endDate = dateString[1]
    },
    onWorkDateChange(date, dateString) {
      console.log(dateString,'dateString===')
      this.workDateGroup = dateString
      this.addNewParams.workStartDate = dateString[0]
      this.addNewParams.workEndDate = dateString[1]
    },
    getWorkContent(text) {
      return this.workContentList.find(el => el.value == text).name
    },
    handleOperate(record, type) {
      let title = ''
      if (type == 1) {
        title = '是否确定通过？'
      } else {
        title = '是否确定退回？'
      }
      const _that = this
      this.$confirm({
        title,
        cancelText: '否',
        okText: '是',
        onOk: () => {
          approveSafeLevel({ id: record.id, approveStatus: type }).then(res => {
            if (res.data.code === 0) {
              _that.$message.success('操作成功')
              this.handleQuery();
            } else {
              _that.$message.error(res.data.msg)
            }
          })
        },
      })
    },
    handleOk(e) {
      this.confirmLoading = true;
      this.addNewParams.projectId = this.user.projectId
      this.addNewParams.approveStatus = 0
      if (!this.addNewParams.workStartDate || !this.addNewParams.workEndDate) {
        this.$message.error('请选择作业时间')
        this.confirmLoading = false;
        return
      }
      // const date1 = moment(this.addNewParams.workStartDate);
      // const date2 = moment(this.addNewParams.workEndDate);
      // const diff = date2.diff(date1, 'days');
      // if (diff > 7) {
      //   this.$message.error('请选择七天之内作业时间')
      //   this.confirmLoading = false;
      //   return
      // }
      if (!this.addNewParams.workContentCode) {
        this.$message.error('请选择工作内容')
        this.confirmLoading = false;
        return
      }
      if ((this.addNewParams.workContentCode != 'holting' && this.addNewParams.workContentCode != 'out_model') && !this.addNewParams.selfEquityNum) {
        this.$message.error('请选择设备编号')
        this.confirmLoading = false;
        return
      }

      // 日期字符串格式化为时间戳 - 不用转时间戳
      // valueFormatToTimestamp(this.addNewParams, ['workStartDate', 'workEndDate'])

      if (this.idEdit) {
        safeLevelUpdate(this.addNewParams).then(res => {
          this.addNewVisible = false;
          this.confirmLoading = false;
          this.resetParams()
          if (res.data.code === 0) {
            this.$message.success('新增成功！')
            this.handleQuery()
          } else {
            this.$message.error(res.data.msg)
          }
        })
      } else {
        safeLevelInsert(this.addNewParams).then(res => {
          this.addNewVisible = false;
          this.confirmLoading = false;
          this.resetParams()
          if (res.data.code === 0) {
            this.$message.success('新增成功！')
            this.handleQuery()
          } else {
            this.$message.error(res.data.msg)
          }
        })
      }
    },
    resetParams() {
      this.addNewParams.workStartDate = ''
      this.addNewParams.workEndDate = ''
      this.addNewParams.workContentCode = ''
      this.addNewParams.selfEquityNum = ''
      this.workDateGroup = []
    },
    handleCancel(e) {
      this.addNewVisible = false;
      this.resetParams()
    },
    handleCancelUploadRecord(e) {
      this.uploadRecordVisible = false;
    }
  },
};
</script>

<style lang="less" scoped>
@import "@/theme/common.less";

.a_Approval {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
}

.plug-Overview {
  font-size: 16px;
  color: #101010;
  font-weight: bold;
}

.query-form {
  display: flex;
  // justify-content: space-between;
}

.a_a_footer {
  background: #ffffff;

  .ant-btn {
    border-color: #d9d9d9 !important;
  }

  .operator {
    margin-right: 8px;

  }
}

.circle {
  height: 20px;
  /*与width设置一致*/
  width: 20px;
  background: red;
  border-radius: 50px;
  margin: 0 auto;
}
</style>
