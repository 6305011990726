var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c(
          "a-row",
          { attrs: { gutter: 16 } },
          [
            _c(
              "a-col",
              { attrs: { span: 8 } },
              [
                _c(
                  "a-card",
                  {
                    staticStyle: { height: "900px" },
                    style: "overflow:auto",
                    attrs: {
                      title: "公司结构",
                      size: "small",
                      bordered: false,
                    },
                  },
                  [
                    _c("a-tree", {
                      attrs: {
                        "expanded-keys": _vm.expandedKeys,
                        "tree-data": _vm.officeTreeData,
                        "auto-expand-parent": _vm.autoExpandParent,
                        replaceFields: {
                          children: "children",
                          title: "name",
                          key: "id",
                        },
                      },
                      on: { expand: _vm.onExpand, select: _vm.onSelect },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "a-col",
              { attrs: { span: 15, offset: 1 } },
              [
                _c(
                  "a-card",
                  { attrs: { title: "用户列表", bordered: false } },
                  [
                    _c(
                      "a-form",
                      {
                        staticClass: "ant-advanced-search-form",
                        attrs: { form: _vm.queryParam },
                      },
                      [
                        _c(
                          "a-row",
                          { attrs: { gutter: 24 } },
                          [
                            _c(
                              "a-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "a-form-item",
                                  { attrs: { label: "姓名" } },
                                  [
                                    _c("a-input", {
                                      attrs: { placeholder: "请输入姓名" },
                                      model: {
                                        value: _vm.queryParam.name,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.queryParam, "name", $$v)
                                        },
                                        expression: "queryParam.name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "a-form-model-item",
                                  { attrs: { label: "所属公司" } },
                                  [
                                    _c("a-tree-select", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        "dropdown-style": {
                                          maxHeight: "400px",
                                          overflow: "auto",
                                        },
                                        "tree-data": _vm.officeTreeData,
                                        placeholder: "请选择直属上级单位",
                                        "tree-default-expand-all": "",
                                        replaceFields: {
                                          children: "children",
                                          title: "name",
                                          key: "id",
                                          value: "id",
                                        },
                                        treeDefaultExpandAll: false,
                                        allowClear: true,
                                      },
                                      on: { select: _vm.queryOfficeId },
                                      model: {
                                        value: _vm.queryParam.parentOfficeId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParam,
                                            "parentOfficeId",
                                            $$v
                                          )
                                        },
                                        expression: "queryParam.parentOfficeId",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-row",
                          { attrs: { gutter: 24 } },
                          [
                            _c(
                              "a-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "a-form-item",
                                  { attrs: { label: "角色" } },
                                  [
                                    _c(
                                      "a-select",
                                      {
                                        attrs: {
                                          placeholder: "请选择",
                                          allowClear: true,
                                        },
                                        model: {
                                          value: _vm.queryParam.roleId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryParam,
                                              "roleId",
                                              $$v
                                            )
                                          },
                                          expression: "queryParam.roleId",
                                        },
                                      },
                                      _vm._l(
                                        _vm.newRole,
                                        function (item, index) {
                                          return _c(
                                            "a-select-option",
                                            {
                                              key: item.id,
                                              attrs: {
                                                index: index,
                                                value: item.roleId,
                                              },
                                            },
                                            [_vm._v(_vm._s(item.roleName))]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              {
                                style: { textAlign: "right", marginTop: "3px" },
                                attrs: { span: 2 },
                              },
                              [
                                _c(
                                  "a-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      icon: "search",
                                      loading: _vm.loading,
                                    },
                                    on: { click: _vm.handleSearch },
                                  },
                                  [_vm._v(" 查询 ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              {
                                style: {
                                  textAlign: "right",
                                  marginTop: "3px",
                                  marginLeft: "20px",
                                },
                                attrs: { span: 2 },
                              },
                              [
                                _c(
                                  "a-button",
                                  {
                                    attrs: { icon: "plus", type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addOffice(
                                          _vm.queryParam.parentOfficeId
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" 新增 ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              {
                                staticStyle: { "margin-top": "10px" },
                                attrs: { span: 6, offset: 1 },
                              },
                              [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("注：查询项目人员请去“项目管理”"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("a-table", {
                      attrs: {
                        columns: _vm.columns,
                        "data-source": _vm.officeData,
                      },
                      on: { expand: _vm.expand },
                      scopedSlots: _vm._u([
                        {
                          key: "action",
                          fn: function (text, record) {
                            return [
                              _c(
                                "span",
                                [
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.showModal(record, true)
                                        },
                                      },
                                    },
                                    [_vm._v("修改")]
                                  ),
                                  _c("a-divider", {
                                    attrs: { type: "vertical" },
                                  }),
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.del(record)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "a-modal",
          {
            attrs: {
              title: _vm.title,
              visible: _vm.visible,
              "confirm-loading": _vm.confirmLoading,
              maskClosable: false,
              width: "760px",
            },
            on: { ok: _vm.modelOk, cancel: _vm.modelCancel },
          },
          [
            _vm.visible
              ? _c(
                  "a-form-model",
                  {
                    ref: "ruleForm",
                    attrs: {
                      model: _vm.form,
                      "label-col": _vm.labelCol,
                      "wrapper-col": _vm.wrapperCol,
                      rules: _vm.rules,
                      selfUpdate: true,
                    },
                  },
                  [
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "所属公司" } },
                      [
                        _c("a-tree-select", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            "dropdown-style": {
                              maxHeight: "400px",
                              overflow: "auto",
                            },
                            "tree-data": _vm.officeTreeData,
                            placeholder: "请选择直属上级单位",
                            "tree-default-expand-all": "",
                            replaceFields: {
                              children: "children",
                              title: "name",
                              key: "id",
                              value: "id",
                            },
                            treeDefaultExpandAll: false,
                          },
                          on: { select: _vm.officeSelect },
                          model: {
                            value: _vm.form.officeId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "officeId", $$v)
                            },
                            expression: "form.officeId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        ref: "name",
                        attrs: { label: "姓名", prop: "name", required: "" },
                      },
                      [
                        _c("a-input", {
                          model: {
                            value: _vm.form.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "name", $$v)
                            },
                            expression: "form.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        ref: "phone",
                        attrs: { label: "手机号", prop: "phone", required: "" },
                      },
                      [
                        _c("a-input", {
                          model: {
                            value: _vm.form.phone,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "phone", $$v)
                            },
                            expression: "form.phone",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        ref: "password",
                        attrs: {
                          label: "密码",
                          prop: "password",
                          required: !_vm.isPwdShow,
                        },
                      },
                      [
                        _c("a-input", {
                          attrs: {
                            placeholder:
                              "密码必须包含数字、大小写字母、特殊字符，且长度大于等于8个字符",
                          },
                          model: {
                            value: _vm.form.password,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "password", $$v)
                            },
                            expression: "form.password",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        ref: "confirmPassWord",
                        attrs: { label: "确认密码", prop: "confirmPassWord" },
                      },
                      [
                        _c("a-input", {
                          model: {
                            value: _vm.form.confirmPassWord,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "confirmPassWord", $$v)
                            },
                            expression: "form.confirmPassWord",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "是否允许登录" } },
                      [
                        _c(
                          "a-select",
                          {
                            attrs: { placeholder: "是否允许登录" },
                            model: {
                              value: _vm.form.loginFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "loginFlag", $$v)
                              },
                              expression: "form.loginFlag",
                            },
                          },
                          [
                            _c(
                              "a-select-option",
                              { key: "1", attrs: { value: "1" } },
                              [_vm._v(" 是 ")]
                            ),
                            _c(
                              "a-select-option",
                              { key: "0", attrs: { value: "0" } },
                              [_vm._v(" 否 ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "新劳务用户角色" } },
                      [
                        _c(
                          "a-select",
                          {
                            staticStyle: { width: "200px" },
                            attrs: {
                              "show-search": "",
                              mode: "multiple",
                              placeholder: "请选择角色",
                              "option-filter-prop": "children",
                              "default-value": _vm.form.labourRoleIdList,
                              "filter-option": _vm.filterOption,
                            },
                            on: {
                              focus: _vm.handleFocus,
                              blur: _vm.handleBlur,
                              change: _vm.changeLabourRole,
                            },
                          },
                          _vm._l(_vm.newRole, function (item, index) {
                            return _c(
                              "a-select-option",
                              { key: index, attrs: { value: item.roleId } },
                              [_vm._v(" " + _vm._s(item.roleName) + " ")]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "备注" } },
                      [
                        _c("a-input", {
                          model: {
                            value: _vm.form.remarks,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "remarks", $$v)
                            },
                            expression: "form.remarks",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }