var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "task-group" }, [
    _c("div", { staticClass: "task-head" }, [
      _c("h3", { staticClass: "title" }, [
        _vm.count ? _c("span", [_vm._v(_vm._s(_vm.count))]) : _vm._e(),
        _vm._v(_vm._s(_vm.title)),
      ]),
      _c(
        "div",
        { staticClass: "actions", staticStyle: { float: "right" } },
        [
          _c("a-icon", {
            staticClass: "add",
            attrs: { type: "plus", draggable: "true" },
          }),
          _c("a-icon", {
            staticClass: "more",
            staticStyle: { "margin-left": "8px" },
            attrs: { type: "ellipsis" },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "task-content" },
      [
        _c(
          "draggable",
          { attrs: { options: _vm.dragOptions } },
          [_vm._t("default")],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }