import enquireJs from 'enquire.js'

export function isDef (v){
  return v !== undefined && v !== null
}

/**
 * Remove an item from an array.
 */
export function remove (arr, item) {
  if (arr.length) {
    const index = arr.indexOf(item)
    if (index > -1) {
      return arr.splice(index, 1)
    }
  }
}

export function isRegExp (v) {
  return _toString.call(v) === '[object RegExp]'
}

export function enquireScreen(call) {
  const handler = {
    match: function () {
      call && call(true)
    },
    unmatch: function () {
      call && call(false)
    }
  }
  enquireJs.register('only screen and (max-width: 767.99px)', handler)
}

/*
* 例如将 20110233 转为 2011-02-03
*/
export function changeDate (str) {
  return str.replace(/^(\d{4})(\d{2})(\d{2})$/, "$1-$2-$3")
}

//验证手机号
export function isphone (val) {
  const phoneReg = /^1(3|4|5|6|7|8|9)\d{9}$/
  if (phoneReg.test(val)) {
    return true
  } else {
    return false 
  }
}
const _toString = Object.prototype.toString


/**
 * 通过url下载文件并对下载的文件更名
 * @param {*} url 
 * @param {*} filename 
 */
export const downloadFile = (url, filename) => {
	function getBlob (url) {
		return new Promise(resolve => {
			const XML = new XMLHttpRequest();
			XML.open('GET', url, true);
			XML.responseType = 'blob';
      XML.crossOrigin = "*"; // 解决跨域问题
			XML.onload = () => {
				if (XML.status === 200) {
					resolve(XML.response);
				}
			};
			XML.send();
		});
	}
	//下载文件
	function saveAs (blob, filename) {
		const elelink = document.createElement("a");
		elelink.style.display = 'none';
		elelink.download = filename;
		elelink.href = window.URL.createObjectURL(blob);
		document.body.appendChild(elelink);
		elelink.click();
		document.body.removeChild(elelink);
	}
	// 调用以上方法进行下载
	getBlob(url).then(blob => {
		saveAs(blob, filename);
	});
}

// 互换键值对
export const swapKeyValuePairs = (obj) => {
  const swapped = {};
  for (const [key, value] of Object.entries(obj)) {
    swapped[value] = key;
  }
  return Object.freeze(swapped);
};
