var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a_Approval" },
    [
      _c(
        "a-form-model",
        {
          staticClass: "clearfix",
          attrs: { model: _vm.params },
          on: { submit: _vm.handleSubmit },
        },
        [
          _c(
            "a-col",
            { attrs: { span: 5 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "选择日期",
                    labelCol: { span: 6 },
                    wrapperCol: { span: 14, offset: 0 },
                  },
                },
                [
                  _c("a-range-picker", {
                    staticStyle: { width: "240px" },
                    model: {
                      value: _vm.params.time,
                      callback: function ($$v) {
                        _vm.$set(_vm.params, "time", $$v)
                      },
                      expression: "params.time",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 5 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "制度名称",
                    labelCol: { span: 8 },
                    wrapperCol: { span: 14, offset: 0 },
                  },
                },
                [
                  _c("a-input", {
                    attrs: { "allow-clear": "" },
                    model: {
                      value: _vm.params.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.params, "name", $$v)
                      },
                      expression: "params.name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 4 } },
            [
              _c(
                "a-space",
                { staticClass: "btnBox" },
                [
                  _c(
                    "a-button",
                    { attrs: { type: "primary", "html-type": "submit" } },
                    [_vm._v(" 查询 ")]
                  ),
                  _c("a-button", { on: { click: _vm.resetQuery } }, [
                    _vm._v(" 重置 "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("standard-table", {
        attrs: {
          columns: _vm.columns,
          dataSource: _vm.tableData,
          rowKey: "safesystemid",
          pagination: _vm.pagination,
          loading: _vm.tableLoading,
          isAlert: false,
          bordered: true,
          btnArr: _vm.tableBtnArr,
          selectedRows: _vm.selectedRows,
        },
        on: {
          change: _vm.handleChangePage,
          "update:selectedRows": function ($event) {
            _vm.selectedRows = $event
          },
          "update:selected-rows": function ($event) {
            _vm.selectedRows = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function (ref) {
              var index = ref.index
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      (_vm.pagination.current - 1) * _vm.pagination.pageSize +
                        parseInt(index) +
                        1
                    )
                  ),
                ]),
              ]
            },
          },
          {
            key: "attach",
            fn: function (ref) {
              var record = ref.record
              return [
                _c(
                  "div",
                  { staticClass: "flex j-center" },
                  _vm._l(record.attachList, function (item, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        item.type == "img"
                          ? _c("img", {
                              staticStyle: { width: "32px" },
                              attrs: { alt: "", src: item.url },
                              on: {
                                click: function ($event) {
                                  return _vm.handlePreview(item)
                                },
                              },
                            })
                          : item.type == "pdf"
                          ? _c("a-icon", {
                              style: { fontSize: "32px" },
                              attrs: { type: "file-pdf" },
                              on: {
                                click: function ($event) {
                                  return _vm.handlePreview(item)
                                },
                              },
                            })
                          : _c("a-icon", {
                              style: { fontSize: "32px" },
                              attrs: { type: "file-text" },
                              on: {
                                click: function ($event) {
                                  return _vm.handlePreview(item)
                                },
                              },
                            }),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]
            },
          },
          {
            key: "action",
            fn: function (ref) {
              var record = ref.record
              return [
                _c("div", { staticClass: "sTables" }, [
                  _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.handleEdit(record)
                        },
                      },
                    },
                    [_vm._v("编辑")]
                  ),
                  _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.handleUpdate(record)
                        },
                      },
                    },
                    [_vm._v("更新")]
                  ),
                  _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.handleDel(record)
                        },
                      },
                    },
                    [_vm._v("删除")]
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }