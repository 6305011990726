<template>
  <div>
    <a-row :gutter="16">
      <a-col :span="11">
        <a-card class="pitfall-card">
          <div slot="title">
            <div class="flex a-center">
              <img style="width: 21px; height: 21px; margin-right: 5px"
                src="https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20241120/1732091350443.png?Expires=2047451350&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=59AEqlAw%2B%2BGyQ%2F%2B4QYdFWxJZ7rs%3D"
                alt="" />
              <span class="ml-1 font-bold">隐患排查情况</span>
            </div>
          </div>
          <div style="display: flex; flex-wrap: wrap; justify-content: space-between; padding: 0 13px;">
            <a-col :span="12" v-for="(item, index) in content" :key="index">
              <div class="flex flex-column j-center a-center content-box black" :class="item.class" :style="item.style">
                <span class="font-20 font-bold">{{ item.value }}<span v-if="item.unit && item.value != '0'">%</span></span>
                <div class="black-three text-center font-14" style="margin-top: 5px;height: 41px;">{{ item.text }}</div>
              </div>
            </a-col>
          </div>
        </a-card>
      </a-col>
      <a-col :span="13">
        <a-card class="risk-card">
          <div slot="title">
            <div class="flex a-center">
              <img style="width: 21px; height: 21px; margin-right: 5px"
                src="https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20241120/1732091350443.png?Expires=2047451350&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=59AEqlAw%2B%2BGyQ%2F%2B4QYdFWxJZ7rs%3D"
                alt="" />
              <span class="ml-1 font-bold">风险分级情况</span>
            </div>
          </div>
          <div style="padding-left: 13px; padding-right: 13px">
            <a-row :gutter="[16, 8]">
              <a-col :span="8">
                <div class="flex flex-column j-center a-center content-box">
                  <span class="font-20 font-bold">{{riskRanking.total || 0}}</span>
                  <span class="black-three text-center  ellipsis-1" style="margin-top: 5px">风险总数</span>
                </div>
              </a-col>
              <a-col :span="8">
                <div class="flex flex-column j-center a-center content-box">
                  <span class="font-20 font-bold" style="color: red">{{
                   riskRanking.oneRisk
                  }}</span>
                  <span class="black-three text-center  ellipsis-1" style="margin-top: 5px">一级风险个数</span>
                </div>
              </a-col>
              <a-col :span="8">
                <div class="flex flex-column j-center a-center content-box">
                  <span class="font-20 font-bold" style="color: red">{{riskRanking.twoRisk}}</span>
                  <span class="black-three text-center ellipsis-1" style="margin-top: 5px">二级风险个数</span>
                </div>
              </a-col>
            </a-row>
          </div>
        </a-card>

        <a-card class="mt-1 risk-card">
          <div slot="title">
            <div class="flex a-center">
              <img style="width: 21px; height: 21px; margin-right: 5px"
                src="https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20241120/1732091350443.png?Expires=2047451350&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=59AEqlAw%2B%2BGyQ%2F%2B4QYdFWxJZ7rs%3D"
                alt="" />
              <span class="ml-1 font-bold">特种机械设备</span>
            </div>
          </div>
          <div style="padding: 0 13px">
            <a-row :gutter="[16, 8]">
              <a-col v-for="(item, index) in deviceContent" :key="index" :span="6">
                <div class="flex flex-column j-center a-center content-box black" :style="item.style">
                  <span class="font-20 font-bold">{{ item.value }}</span>
                  <span class="black-three text-center mt-5">{{ item.label }}</span>
                </div>
              </a-col>
            </a-row>
          </div>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import {getProblemStat,getRiskControlStat,getFacilityStatusStatic} from "@/services/labourProjectExcellent";
export default {
  data() {
    return {
      content: [
        { value: "0", text: "检查总数", class: "content-box-bg1", style: "",key: 'total' },
        { value: "0", text: "二级及以上风险覆盖率", class: "content-box-bg1", style: "color: red;", unit: true ,key: 'riskCoverage'},
        { value: "0", text: "月度检查次数", class: "content-box-bg2", style: "margin-top: 17px;" ,key: 'monthCount'},
        { value: "0", text: "整改完成率", class: "content-box-bg2", style: "color: red; margin-top: 17px;", unit: true,key: 'rectificationRate' },
      ],
      deviceContent:
        [
          { value: 0, label: '使用中', key:'usingNum'},
          { value: 0, label: '取证中', key:'giveNum' },
          { value: 0, label: '待安装', key:'waitNum' },
          { value: 0, label: '已停用', key:'stopNum' }
        ],
      riskRanking: {},
    };
  },
  mounted() {
    this.getProblemStat()
    this.getRiskControlStat()
    this.getFacilityStatusStatic()
  },
  methods: {
    getProblemStat() {
      getProblemStat().then(res => {
        if (res.data.code === 0) {
          this.content = this.content.map(item => {
            if (res.data.data[item.key]) item.value = res.data.data[item.key];
            return item;
          })
        }
      })
    },
    getRiskControlStat() {
      getRiskControlStat().then(res => {
        if (res.data.code === 0) {
          this.riskRanking = res.data.data
        }
      })
    },
    getFacilityStatusStatic() {
      getFacilityStatusStatic({}).then(res => {
        if (res.data.code === 0) {
          this.deviceContent = this.deviceContent.map(item => {
            if (res.data.data[item.key]) item.value = res.data.data[item.key];
            return item;
          })
        }
      })
    }
  }
};
</script>
<style lang="less" scoped>
/deep/.ant-card {
  border-radius: 10px;
  border: none;

  .ant-card-body {
    // padding: 20px 36px 32px 40px;
    // padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .ant-card-head {
    padding: 0 36px 0 40px;
    border-bottom: 1px solid #e6efff;
  }

  .ant-card-head-title {
    padding: 20px 0;
  }
}

/deep/ .pitfall-card>.ant-card-body {
  padding-top: 30px;
  padding-bottom: 20px;
}

/deep/ .risk-card {
  >.ant-card-body {
    padding-bottom: 0;
    padding-top: 10px;
  }

  >.ant-card-head {
    padding-left: 33px;
  }
}


.font-14 {
  font-size: 14px;
}

.mt-5 {
  margin-top: 5px;
}

.content-box {
  border-radius: 10px;
  padding: 15px 10px;
  flex: 1 0 48%;
}

.content-box-bg1 {
  background: linear-gradient(0deg,
      rgb(245, 248, 254),
      rgba(245, 248, 254, 0) 100%);
}

.content-box-bg2 {
  background: linear-gradient(180deg,
      rgb(245, 248, 254),
      rgba(245, 248, 254, 0) 100%);
}
</style>
