var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form",
    {
      staticClass: "form",
      attrs: { form: _vm.form },
      on: { submit: _vm.handleSubmit },
    },
    [
      _c(
        "a-row",
        { staticClass: "form-row" },
        [
          _c(
            "a-col",
            { attrs: { lg: 6, md: 12, sm: 24 } },
            [
              _c(
                "a-form-item",
                { attrs: { label: _vm.$t("name") } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "repository.name",
                          {
                            rules: [
                              {
                                required: true,
                                message: _vm.$ta("input|name"),
                                whitespace: true,
                              },
                            ],
                          },
                        ],
                        expression:
                          "['repository.name', {rules: [{ required: true, message: $ta('input|name'), whitespace: true}]}]",
                      },
                    ],
                    attrs: { placeholder: _vm.$ta("input|name") },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            {
              attrs: {
                xl: { span: 6, offset: 2 },
                lg: { span: 8 },
                md: { span: 12 },
                sm: 24,
              },
            },
            [
              _c(
                "a-form-item",
                { attrs: { label: _vm.$t("domain") } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "repository.domain",
                          {
                            rules: [
                              {
                                required: true,
                                message: _vm.$ta("input|domain"),
                                whitespace: true,
                              },
                              { validator: _vm.validate },
                            ],
                          },
                        ],
                        expression:
                          "['repository.domain', {rules: [{ required: true, message: $ta('input|domain'), whitespace: true}, {validator: validate}]}]",
                      },
                    ],
                    attrs: {
                      addonBefore: "http://",
                      addonAfter: ".github.io",
                      placeholder: _vm.$ta("input|domain"),
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            {
              attrs: {
                xl: { span: 8, offset: 2 },
                lg: { span: 10 },
                md: { span: 24 },
                sm: 24,
              },
            },
            [
              _c(
                "a-form-item",
                { attrs: { label: _vm.$t("manager") } },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "repository.manager",
                            {
                              rules: [
                                {
                                  required: true,
                                  message: _vm.$ta("select|manager"),
                                },
                              ],
                            },
                          ],
                          expression:
                            "['repository.manager', {rules: [{ required: true, message: $ta('select|manager')}]}]",
                        },
                      ],
                      attrs: { placeholder: _vm.$ta("select|manager") },
                    },
                    [
                      _c("a-select-option", { attrs: { value: "王同学" } }, [
                        _vm._v("王同学"),
                      ]),
                      _c("a-select-option", { attrs: { value: "李同学" } }, [
                        _vm._v("李同学"),
                      ]),
                      _c("a-select-option", { attrs: { value: "黄同学" } }, [
                        _vm._v("黄同学"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-row",
        { staticClass: "form-row" },
        [
          _c(
            "a-col",
            { attrs: { lg: 6, md: 12, sm: 24 } },
            [
              _c(
                "a-form-item",
                { attrs: { label: _vm.$t("approval") } },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "repository.auditor",
                            {
                              rules: [
                                {
                                  required: true,
                                  message: _vm.$ta("select|approval"),
                                },
                              ],
                            },
                          ],
                          expression:
                            "['repository.auditor', {rules: [{ required: true, message: $ta('select|approval')}]}]",
                        },
                      ],
                      attrs: { placeholder: _vm.$ta("select|approval") },
                    },
                    [
                      _c("a-select-option", { attrs: { value: "王晓丽" } }, [
                        _vm._v("王晓丽"),
                      ]),
                      _c("a-select-option", { attrs: { value: "李军" } }, [
                        _vm._v("李军"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            {
              attrs: {
                xl: { span: 6, offset: 2 },
                lg: { span: 8 },
                md: { span: 12 },
                sm: 24,
              },
            },
            [
              _c(
                "a-form-item",
                { attrs: { label: _vm.$t("date") } },
                [
                  _c("a-range-picker", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "repository.effectiveDate",
                          {
                            rules: [
                              {
                                required: true,
                                message: _vm.$ta("select|date"),
                              },
                            ],
                          },
                        ],
                        expression:
                          "['repository.effectiveDate', {rules: [{ required: true, message: $ta('select|date')}]}]",
                      },
                    ],
                    staticStyle: { width: "100%" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            {
              attrs: {
                xl: { span: 8, offset: 2 },
                lg: { span: 10 },
                md: { span: 24 },
                sm: 24,
              },
            },
            [
              _c(
                "a-form-item",
                { attrs: { label: _vm.$t("type") } },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "repository.type",
                            {
                              rules: [
                                {
                                  required: true,
                                  message: _vm.$ta("select|type"),
                                },
                              ],
                            },
                          ],
                          expression:
                            "['repository.type', {rules: [{ required: true, message: $ta('select|type')}]}]",
                        },
                      ],
                      attrs: { placeholder: _vm.$ta("select|type") },
                    },
                    [
                      _c("a-select-option", { attrs: { value: "公开" } }, [
                        _vm._v("公开"),
                      ]),
                      _c("a-select-option", { attrs: { value: "私密" } }, [
                        _vm._v("私密"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showSubmit
        ? _c(
            "a-form-item",
            [
              _c("a-button", { attrs: { htmlType: "submit" } }, [
                _vm._v("Submit"),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }