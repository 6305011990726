<template>
	<div>
		<standard-table
			class=""
			:columns="columns"
			:dataSource="tableData"
			rowKey="projectId"
			:pagination="pagination"
			:loading="tableLoading"
			@change="handleChangePage"
			:isAlert="false"
			:bordered="true"
		>
			<template slot="title">
				<div class="table-title">{{ year }}年企业项目标准化管理年度检查考核评分汇总表</div>
			</template>
			<template slot="index" slot-scope="{ index }">
				<span>{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}</span>
			</template>
			<template slot="companyName" slot-scope="{ text }">
				<span :style="{color: text == '机施集团' ? '#FF5D78' : ''}">{{ text }}</span>
			</template>
		</standard-table>
	</div>
</template>
	
<script>
import {
	examineSeasonTotalList
} from "@/services/safeExamine";
import { mapGetters } from "vuex";
import StandardTable from "@/components/table/StandardTable";
import moment from "moment";

const columns = [
	{
		title: "序号",
		align: "center",
		scopedSlots: { customRender: "index" },
		width: 65,
	},
	{
		title: "企业名称",
		align: "center",
		dataIndex: "companyName",
		scopedSlots: { customRender: "companyName" },
		width: 250,
	},
	{
		title: "排名",
		dataIndex: "rank",
		ellipsis: true,
		width: 200,
		align: "center",
	},
	{
		title: "总评分",
		dataIndex: "averageNonEmptyCount",
		scopedSlots: { customRender: "averageNonEmptyCount" },
		width: 200,
		align: "center",
	},
	{
		title: "第一季度评分",
		dataIndex: "firstExamineSum",
		width: 200,
		scopedSlots: { customRender: "firstExamineSum" },
		align: "center",
	},
	{
		title: "第二季度评分",
		dataIndex: "secondExamineSum",
		width: 200,
		scopedSlots: { customRender: "secondExamineSum" },
		align: "center",
	},
	{
		title: "第三季度评分",
		dataIndex: "thirdExamineSum",
		width: 200,
		scopedSlots: { customRender: "thirdExamineSum" },
		align: "center",
	},
	{
		title: "第四季度评分",
		dataIndex: "fourthExamineSum",
		width: 200,
		scopedSlots: { customRender: "fourthExamineSum" },
		align: "center",
	},
];

export default {
	components: {
		StandardTable
	},
	props: {
		year: {
			type: String,
			default: moment(new Date()).format('YYYY'),
			require: true
		}
	},
	data() {
		return {
			params: {
				pageNumber: 1,
				pageSize: 999,
				year: ''
			},
			pagination: {
				//分页数据
				current: 1,
				pageSize: 999,
				total: 0,
				showTotal: (total, range) => `共 ${total} 条`,
			},
			total: 0,
			columns: columns,
			tableData: [],
			tableLoading: false, //table加载中
		};
	},
	// created() {
	// 	this.handleQuery();
	// },
	watch: {
		year: {
			handler(val) {
				this.params.year = val
				this.handleQuery();
			},
			immediate: true
		}
	},
	computed: {
		...mapGetters("account", ["user"]),
	},
	methods: {
		handleQuery(e) {
			this.tableLoading = true
			this.tableData = []
			examineSeasonTotalList(this.params).then((res) => {
				this.tableLoading = false
				if (res.data.code === 0) {
					this.tableData = res.data.data.list;
					this.pagination.total = res.data.data.total;
				} else {
					this.$message.error(res.data.msg)
				}
			});
		},
		handleChangePage(e) {
      this.params.pageNumber = e.current;
      this.pagination.current = e.current;
			this.handleQuery();
    },
	},
};
</script>
	
<style lang="less" scoped>
@import "@/theme/common.less";
.query-form {
	display: flex;
	// justify-content: space-between;
}
.post-border {
	border-bottom: 1px solid #f0f0f0;
	padding-bottom: 5px;
	padding-top: 5px;
	// padding-top: 10px;
	// height: 40px;
}

.post-border:last-child {
	border-bottom: none;
	padding-bottom: 0;
}

.table-title {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}
</style>
	