<template>
  <a-modal v-model="myVisible" :title="modalTitle" :destroyOnClose="true" :width="900" :footer="null" :maskClosable="false" :keyboard="false" @cancel="cancel">
    <a-spin :spinning="spinning">
      <a-radio-group v-model="tabPosition" style="margin:8px" size="large" disabled>
        <a-radio-button :value="1">
          第一步
        </a-radio-button>
        <a-radio-button :value="2">
          第二步
        </a-radio-button>
        <a-radio-button :value="3" v-if="hkAll > 0">
          第三步
        </a-radio-button>
        <!-- <a-radio-button :value="4" v-if="hkAll > 0">
          第四步
        </a-radio-button> -->
      </a-radio-group>
      <h3 class="title">{{ title }}</h3>
      <h3 class="title" v-if="yqAll > 0 && tabPosition === 3 && hkAll == 0">云启设备无需等待该步骤，关闭即可</h3>
      <h3 class="title" v-if="tabPosition === 3">若失败人数过多，请前去“系统设置”--“考勤设备设置”进行勾选对应设备重新下发。</h3>
      <template v-if="tabPosition === 1 || tabPosition === 2">
        <div class="content" v-if="hkAll > 0">
          <h4 class="sub-title">海康：</h4>
          <a-space :size="100">
            <span>共{{ hkAll }}人</span>
            <span>{{ isDel ? '删除' : '下发' }}成功<span class="success">{{ hkSuccess }}</span>人</span>
            <span>{{ isDel ? '删除' : '下发' }}失败<span class="fail">{{ hkFail }}</span>人</span>
          </a-space>
          <a-progress :percent="hkProgress" :strokeWidth="15"/>
        </div>
        <div class="content" v-if="yqAll > 0">
          <h4 class="sub-title">云启：</h4>
          <a-space :size="100">
            <span>共{{ yqAll }}人</span>
            <span>{{ isDel ? '删除' : '下发' }}成功<span class="success">{{ yqSuccess }}</span>人</span>
            <span>{{ isDel ? '删除' : '下发' }}失败<span class="fail">{{ yqFail }}</span>人</span>
          </a-space>
          <a-progress class="progress" :percent="yqProgress" :strokeWidth="15"/>
        </div>
      </template>
      <template v-else-if="tabPosition === 3">
        <div class="content">
          <h4 class="sub-title">海康：</h4>
          <standard-table
            class=""
            :columns="columns"
            :dataSource="dataSource"
            rowKey="id"
            :scroll="{ x: 700 }"
            :pagination="pagination"
            @change="onPageChange"
            :loading="tableLoading"
            :isAlert="false"
            :bordered="true"
          >
            <template slot="index" slot-scope="{ index }">
              <span>
                {{
                  (pagination.current - 1) * pagination.pageSize +
                    parseInt(index) +
                    1
                }}
              </span>
            </template>
            <template slot="result" slot-scope="{ record }">
              <div>成功{{record.successedPersonCount}}人</div>
              <div>失败{{record.failedPersonCount}}人</div>
            </template>
            <template slot="downloadPercent" slot-scope="{ text }">
              <div class="flex a-center j-center">
                <a-progress class="progress" :percent="text" :strokeWidth="15"/>
                <a-spin v-if="text != 100" style="height: 20px"/>
              </div>
            </template>
          </standard-table>
          <p class="yq-tips" v-if="yqAll > 0">云启：已{{ isDel ? '删除' : '下发' }}{{ yqAll }}人，成功{{ yqSuccess }}人，失败{{ yqFail }}人。</p>
        </div>
      </template>
      <!-- 废弃第四步 -->
      <template v-else-if="tabPosition === 4">
        <div class="content">
          <div v-if="hkAll > 0">
            <h4 class="sub-title">海康：</h4>
            <p>本次共{{ isDel ? '删除' : '下发' }}{{hkAll}}人，{{ isDel ? '删除' : '下发' }}成功{{hkSuccess}}人，{{ isDel ? '删除' : '下发' }}失败{{hkFail}}人。</p>
          </div>
          <div v-if="yqAll > 0">
            <h4 class="sub-title" v-if="yqAll > 0">云启：</h4>
            <p>本次共{{ isDel ? '删除' : '下发' }}{{yqAll}}人，{{ isDel ? '删除' : '下发' }}成功{{yqSuccess}}人，{{ isDel ? '删除' : '下发' }}失败{{yqFail}}人。</p>
          </div>
        </div>
      </template>
      <template v-if="hkerrList.length > 0">
        <div class="err-box">
          <span class="font-18">失败原因：</span>
          <div class="mt-1" v-for="(item, index) in hkerrList" :key="index">
            {{item}}
          </div>
        </div>
      </template>
      <template v-if="yqerrList.length > 0">
        <div class="err-box">
          <span class="font-18">失败原因：</span>
          <div class="mt-1" v-for="(item, index) in yqerrList" :key="index">
            {{item}}
          </div>
        </div>
      </template>
      <div class="btn-box">
        <a-space :size="10">
          <a-button :type="primary ? 'primary' : ''" @click="cancel('cancelBtn')" v-if="showCancelBtn">{{cancelBtnText}}</a-button>
          <a-button :type="primary ? '' : 'primary'" v-if="showConfirmBtn" @click="nextStepBtn" :disabled="HKTask">{{confirmBtnText}}</a-button>
          <a-button type="primary" v-if="showOverBtn" @click="cancel('cancelBtn')" :disabled="HKTask">完成</a-button>
          <a-button type="primary" v-if="showFailBtn" @click="over('cancelBtn')">结束</a-button>
          <a-button type="primary" v-if="tabPosition == 3 && !HKTask" @click="batchSyncPersonAuthToDevice">重新执行该步骤</a-button>
        </a-space>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import { batchSyncPersonToAttendPlatform, getTaskProgress, batchDelDeviceAuth, batchDelPersonInfo,
  batchSyncPersonAuthToAttendPlatform, batchSyncPersonAuthToDevice, getHKTaskProgress,
  manageBatchSyncPersonToAttendPlatform,getMangeTaskProgress,manageBatchSyncPersonAuthToAttendPlatform,
  getMangeHKTaskProgress,mangeBatchSyncPersonAuthToDevice,mangeBatchDelDeviceAuth,mangeBatchDelPersonInfo } from "@/services/participationUnit";
import StandardTable from "@/components/table/StandardTable";
const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
  },
  {
    title: "设备IP",
    dataIndex: "deviceIp",
    width: 150,
    align: "center",
    scopedSlots: { customRender: "deviceIp" },
  },
  {
    title: "名称",
    dataIndex: "deviceName",
    width: 150,
    align: "center",
    scopedSlots: { customRender: "deviceName" },
  },
  {
    title: "结果",
    dataIndex: "result",
    width: 150,
    align: "center",
    scopedSlots: { customRender: "result" },
  },
  {
    title: "进度",
    align: "center",
    width: 150,
    dataIndex: "downloadPercent",
    scopedSlots: { customRender: "downloadPercent" },
  },
];
export default {
  components: {
    StandardTable
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
      require: true
    },
    modalTitle: {
      type: String,
      default: '',
      require: true
    },
    workNumList: {
      type: Array,
      default: () => [],
      require: true
    },
    isDel: { //true：下发，false：删除
      type: Boolean,
      default: false,
      require: true
    }
  },  
  data() {
    return {
      tabPosition: 1,
      title: this.isDel ? '删除设备权限' : '下发人员照片',
      cancelBtnText: '返回修改',
      confirmBtnText: '下一步',
      showCancelBtn: true,
      primary: true,
      showConfirmBtn: true,
      showOverBtn: false,
      hkAll: 0,
      hkSuccess: 0,
      hkFail: 0,
      hkerrList: [],
      yqerrList:[],
      hkProgress: 0,
      yqAll: 0,
      yqSuccess: 0,
      yqFail: 0,
      yqProgress: 0,
      threadId: 0,
      spinning: false,
      columns: columns,
      dataSource: [],
      tableLoading: false, //table加载中
      pagination: {
        //分页数据
        current: 1,
        pageSize: 10,
        total: 0,
      },
      showFailBtn: false,
      HKTask: false // 判断第三步海康完成
    };
  },
  computed: {
    myVisible: {
      get() {
        return this.visible
      },
      set() {}
    },
    totalFail: {
      get() {
        return this.hkFail === this.hkAll && this.yqFail === this.yqAll
      },
      set() {}
    }
  },
  watch: {
    visible: {
      handler(newVal) {
        if (newVal) {
          this.reset()
          this.firstStep()
        }
      }
    },
    totalFail: {
      handler(newVal) {
        if (newVal) {
          this.cancelBtnText = '完成'
          this.showCancelBtn = true
          this.showConfirmBtn = false
        }
      }
    }
  },
  mounted() {
  },
  methods: {
    firstStep() {
      this.spinning = true
      this.threadId = new Date().getTime()
      const params = {
        threadId: this.threadId,
        userIdList: this.workNumList
      }
      if (!this.isDel) {
        // manageBatchSyncPersonToAttendPlatform
        manageBatchSyncPersonToAttendPlatform(params).then(res => {
          if (res.data.code === 0) {
            this.getTaskProgress()
          } else {
            this.$message.error(res.data.msg)
          }
        })
      } else {
        mangeBatchDelDeviceAuth(params).then(res => {
          if (res.data.code === 0) {
            this.getTaskProgress()
            // const { hkSyncDataInfo } = res.data.data
            // if (hkSyncDataInfo.all === 0) {
            //   this.cancelBtnText = '完成'
            //   this.showCancelBtn = true
            //   this.showConfirmBtn = false
            // }
          } else {
            this.$message.error(res.data.msg)
          }
        })
      }
    },
    //查询任务进度接口
    getTaskProgress() {
      
      this.hkProgress = 0
      this.yqProgress = 0
      if (this.myVisible) {
        getMangeTaskProgress({threadId: this.threadId}).then(res => {
          if (res.data.code === 0) {
            const hkSyncDataInfo = res.data.data.hkSyncDataInfo
            this.hkAll = hkSyncDataInfo.all
            this.hkSuccess = hkSyncDataInfo.success
            this.hkFail = hkSyncDataInfo.fail
            this.hkerrList = hkSyncDataInfo.errList
            this.hkProgress = this.hkAll != 0 ? (this.hkSuccess + this.hkFail) / this.hkAll * 100 : 100
            const yunQiSyncDataInfo = res.data.data.yunQiSyncDataInfo
            this.yqAll = yunQiSyncDataInfo.all
            this.yqSuccess = yunQiSyncDataInfo.success
            this.yqerrList = yunQiSyncDataInfo.errList
            this.yqFail = yunQiSyncDataInfo.fail
            this.yqProgress = this.yqAll != 0 ? (this.yqSuccess + this.yqFail) / this.yqAll * 100 : 100
            if (this.hkProgress === 100 && this.yqProgress === 100) {
              this.spinning = false
            } else {
              setTimeout(() => {
                this.getTaskProgress()
              }, 3000);
            }
            if (this.hkFail > 0) {
              this.showOverBtn = false
              this.showCancelBtn = false
              this.showConfirmBtn = false
              this.showFailBtn = true
            }
            // 云启下发和删除，考勤不需要第三步
            if (this.hkAll == 0 && this.yqAll > 0 && this.tabPosition == 2) {
              this.cancelBtnText = '完成'
              this.showConfirmBtn = false
              this.showOverBtn = true
            } 
          } else {
            setTimeout(() => {
                this.getTaskProgress()
              }, 3000);
            // this.spinning = false
            this.$message.error(res.data.msg)
          }
        })
      }
    },
    //第三步查询海康设备的任务进度
    getHKTaskProgress() {
      if (this.myVisible) {
        getMangeHKTaskProgress({threadId: this.threadId}).then(res => {
          if (res.data.code === 0) {
            this.dataSource = res.data.data
            for (let i = 0; i < this.dataSource.length; i++) {
              const el = this.dataSource[i];
              if (el.downloadPercent !== 100) {
                setTimeout(() => {
                  this.getHKTaskProgress()
                }, 3000);
                this.HKTask = true // 海康第三步未完成
                break
              } else {
                this.HKTask = false // 完成
                this.spinning = false
              }
            }
          } else {
            setTimeout(() => {
              this.getHKTaskProgress()
            }, 3000);
            this.spinning = false
            this.$message.error(res.data.msg)
          }
        })
      }
    },
    secondStep() {
      this.spinning = true
      this.threadId = new Date().getTime()
      const params = {
        threadId: this.threadId,
        userIdList: this.workNumList
      }
      if (!this.isDel) {
        manageBatchSyncPersonAuthToAttendPlatform(params).then(res => {
          if (res.data.code === 0) {
            this.getTaskProgress()
          } else {
            this.spinning = false
            this.$message.error(res.data.msg)
          }
        })
      } else {
        mangeBatchDelPersonInfo(params).then(res => {
          if (res.data.code === 0) {
            this.getTaskProgress()
          } else {
            this.spinning = false
            this.$message.error(res.data.msg)
          }
        })
      }
    },
    batchSyncPersonAuthToDevice() {
      // this.spinning = true
      this.threadId = new Date().getTime()
      mangeBatchSyncPersonAuthToDevice({
        userIdList: this.workNumList
        }).then(res => {
          if (res.data.code === 0) {
            this.threadId = res.data.data
            this.getHKTaskProgress()
          } else {
            this.spinning = false
            this.$message.error(res.data.msg)
          }
      })
    },
    nextStepBtn() {
      switch (this.tabPosition) {
        case 1:
          this.title = this.isDel ? '删除人员信息' : '下发人员权限'
          this.showCancelBtn = false
          this.secondStep()
          this.tabPosition++
        break
        case 2:
          this.hkerrList = []
          this.yqerrList = []
          this.title = '同步考勤设备'
          this.batchSyncPersonAuthToDevice()
          this.cancelBtnText = '完成'
          this.showConfirmBtn = false
          this.primary = true
          this.showOverBtn = true
          this.tabPosition++
        break
        case 3:
          // this.showCancelBtn = true
          this.title = this.isDel ? '删除结果' : '下发结果'
          this.confirmBtnText = this.isDel ? '重新删除' : '重新下发'
          this.cancelBtnText = '完成'
          this.primary = true
          this.showOverBtn = true
          this.tabPosition++
        break
        case 4:
          this.tabPosition = 1
          this.showCancelBtn = false
          this.primary = false
          this.showOverBtn = false
          this.title = this.isDel ? '删除设备权限' : '下发人员照片',
          this.confirmBtnText = '下一步'
          this.firstStep()
        break
        default: 
        break
      }
    },
    //表格 - 分页查询
    onPageChange(e) {
      this.pagination.pageSize = e.pageSize;
      this.pagination.current = e.current;
    },
    reset() {
      this.tabPosition = 1
      this.title = this.isDel ? '删除设备权限' : '下发人员照片',
      this.cancelBtnText = '返回修改'
      this.confirmBtnText = '下一步'
      this.showCancelBtn = true
      this.showConfirmBtn = true
      this.showOverBtn = false
      this.primary = false
      this.showFailBtn = false
      this.HKTask = false
    },
    cancel() {
      var _that = this
      if (!this.totalFail) {
        if (this.cancelBtnText === '完成') {
          this.$emit('cancel');
          this.reset()
        } else {
          this.$confirm({
            title: this.isDel ? '关闭后系统将继续删除，删除失败的人员可查看【删除失败记录】，请重新删除或联系运维人员处理。' : '请等待下发结果，关闭后可能导致下发失败。',
            onOk() {
              _that.$emit('cancel');
              _that.reset()
            }
          })
        }
      } else {
        this.$emit('cancel');
        this.reset()
      }
    },
    over() {
      this.$emit('cancel');
      this.reset()
    }
  },
};
</script>

<style lang="less" scoped>
.title {
  padding: 0 10px 0 10px;
}
.content {
  padding: 0 10px;
  .sub-title {
    padding-top: 10px;
  }
  .progress {
    padding-top: 5px;
  }
}
.btn-box {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.yq-tips {
  margin-top: 10px;
}
.success {
  color: #4a86e8;
  font-weight: bold;
}
.fail {
  color: red;
  font-weight: bold;
}
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  z-index: 1;
  color: #4a86e8;
  background: #fff;
  border-color: #4a86e8;
}
.ant-radio-button-wrapper-disabled {
  background: #fff;
  color: rgba(0, 0, 0, 0.65);
  cursor: default ;
}
.err-box {
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;
  padding: 10px 20px;
}
</style>