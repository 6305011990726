<template>
    <div>
        <a-card :bordered="false">

            <div id="components-form-demo-advanced-search">
                <a-form class="ant-advanced-search-form" :form="queryParam" >
                    <a-row :gutter="24">
                        <a-col :span="6" >
                            <a-form-item label="唯一键">
                                <a-input placeholder="请输入" v-model="queryParam.menuKey"/>
                            </a-form-item>
                        </a-col>
                        <a-col :span="6" >
                            <a-form-item label="权限名称">
                                <a-input placeholder="请输入" v-model="queryParam.menuName"/>
                            </a-form-item>
                        </a-col>

                        <a-col :span="4" >
                            <a-form-item label="状态">
                                <a-select placeholder="请选择" v-model="queryParam.visible">
                                    <a-select-option value="">全部</a-select-option>
                                    <a-select-option value="0">显示</a-select-option>
                                    <a-select-option value="1">隐藏</a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>

                        <a-col :span="2" :style="{ textAlign: 'right', marginTop: '3px'}">
                            <a-button type="primary" icon="search" :loading="loading" @click="handleSearch">
                                查询
                            </a-button>
                        </a-col>
                    </a-row>

                    <a-row>
                        <a-button type="primary" @click="$refs.modal.add()">
                            新增
                        </a-button>
                    </a-row>

                </a-form>
            </div>
            <a-table
                    :columns="columns"
                    :data-source="data"
                    @expand="expand"
            >
                <span slot="menuType" slot-scope="text">
                    {{ text | menuTypeFilter }}
                </span>

                <span slot="visible" slot-scope="text">
                    {{ text | statusFilter }}
                </span>
                <template slot="action" slot-scope="text, record">
                                <span>
                                  <a @click="handleEdit(record)">编辑</a>
                                  <a-divider type="vertical" />
                                  <a @click="delById(record.menuId)">删除</a>
                                </span>
                </template>
            </a-table>
        </a-card>



        <permission-modal ref="modal" @ok="permissionOk" />
    </div>



</template>

<script>
    import {getMenuList, delMenuById} from '@/services/sys'
    import { treeData } from '@/utils/treeutil'
    import PermissionModal from './modules/PermissionModal.vue'
    const columns = [
        {
            dataIndex: 'menuName',
            key: 'menuName',
            title: '权限名称',
        },
        {
            title: '路由唯一键',
            dataIndex: 'menuKey',
            key: 'menuKey',
        },
        {
            title: '组件',
            dataIndex: 'component',
            key: 'component',
        },
        {
            title: '排序',
            key: 'orderNum',
            dataIndex: 'orderNum',
        },
        {
            title: '按钮类型',
            key: 'menuType',
            dataIndex: 'menuType',
            scopedSlots: { customRender: 'menuType' }
        },
        {
            title: '链接',
            key: 'path',
            dataIndex: 'path',
        },
        {
            title: '重定向',
            key: 'redirect',
            dataIndex: 'redirect',
        },
        {
            title: '权限标识',
            key: 'perms',
            dataIndex: 'perms',
        },
        {
            title: '状态',
            key: 'visible',
            dataIndex: 'visible',
            scopedSlots: { customRender: 'visible' }
        },
        {
            title: '操作',
            key: 'action',
            scopedSlots: { customRender: 'action' },
        },
    ];

    export default {
        components: {
            PermissionModal
        },
        data() {
            return {
                // 筛选
                queryParam: {
                    menuKey: "",
                    menuName: "",
                    status: ""
                },
                loading: false,

                // 数据表格
                data: [],
                columns,

                // 详细数据弹窗
                title: "编辑",
                visible: false,
                confirmLoading: false,
                labelCol: { span: 6 },
                wrapperCol: { span: 12 },
                form:{
                    status: "0",
                },
                rules: {
                    roleName: [
                        { required: true, message: '请输入角色名', trigger: 'blur' },
                    ],
                    roleKey: [{ required: true, message: '请输入权限字符', trigger: 'blur' }],
                    roleSort: [{ required: true, message: '请输入显示顺序', trigger: 'blur' }],
                },

            };
        },
        filters: {
            statusFilter (status) {
                const statusMap = {
                    '1': '隐藏',
                    '0': '显示'
                }
                return statusMap[status]
            },
            menuTypeFilter (type) {
                const menuMap = {
                    'M': '目录',
                    'F': '按钮',
                    'C': '菜单'
                }
                return menuMap[type]
            }
        },

        created() {
            this.getMenuList()
        },
        methods: {
            getMenuList() {
                getMenuList(this.queryParam).then(res => {
                    console.log(res)
                    this.data = treeData(res.data.data, 'menuId')
                })
            },
            handleSearch(){
                this.getMenuList();
            },
            handleEdit(record) {
                this.$refs.modal.edit(record)
            },
            modelCancel(e) {
                this.visible = false;
                this.form = {status:"0"}
            },
            modelOk(){
                console.log(this.form)
                this.modelCancel()
            },
            delById(id) {
                console.log(id)
                var _that = this
                this.$confirm({
                    title: "确认删除?",
                    content: '要删除该菜单吗？',
                    okText: '删除',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                        delMenuById({id: id}).then(res => {
                            console.log(res)
                            const  stat = res.data.code
                            if (stat === 0){
                                _that.$message.success(res.data.msg)
                            }else{
                                _that.$message.error(res.data.msg)
                            }
                            _that.getMenuList();
                        })
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });

            },
            expand(expanded, record){
                console.log(expanded, record)
            },
            permissionOk(){
                this.getMenuList()
            }
        }
    };
</script>


<style scoped>
    .ant-advanced-search-form {
        padding: 24px;
        background: #fbfbfb;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        margin-bottom: 10px;
    }

    .ant-advanced-search-form .ant-form-item {
        display: flex;
    }

    .ant-advanced-search-form .ant-form-item-control-wrapper {
        flex: 1;
    }

    #components-form-demo-advanced-search .ant-form {
        max-width: none;
    }

    tr{
        background-color: #ffffff;
    }

</style>
