<template>
  <div class="plug-form">
    <a-form
      class="ant-advanced-search-form"
      :form="form"
      @submit="handleSubmit"
    >
      <!-- 下划线 -->
      <div class="plug-Overview">参建单位详情</div>
      <a-divider />
      <a-row>
        <a-col :span="12">
          <a-form-item
            :label="`单位名称`"
            :labelCol="{ span: 5 }"
            :wrapperCol="{ span: 17, offset: 1 }"
          >
            <a-input
              v-decorator="[
                'officeName',
                { rules: [{ required: true, message: '请输入单位名称' }] },
              ]"
              placeholder="请输入单位名称"
              allowClear
              disabled
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            :label="`统一社会信用代码`"
            :labelCol="{ span: 5 }"
            :wrapperCol="{ span: 17, offset: 1 }"
          >
            <a-input
              v-decorator="[
                'code',
                {
                  rules: [
                    { required: true, message: '请输入统一社会信用代码' },
                    { required: true, message: '请正确输入社会信用代码' ,pattern:/^[0-9A-Za-z]{18}$/}
                  ],
                },
                
              ]"
              placeholder="请正确输入18位社会信用代码"
              allowClear
              :maxLength="19"
              :disabled="query.readonly"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            :label="`单位类型`"
            :labelCol="{ span: 5 }"
            :wrapperCol="{ span: 17, offset: 1 }"
          >
            <a-select
              v-decorator="[
                'roleType',
                {
                  rules: [{ required: true, message: '请选择单位类型' }],
                },
              ]"
              placeholder="请选择单位类型"
              allowClear
              :disabled="query.readonly"
            >
              <a-select-option
                :filter-option="filterOption"
                v-for="items of selectlist"
                :key="items.name"
                :value="Number(items.value)"
              >
                {{ items.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            :label="`法定代表人`"
            :labelCol="{ span: 5 }"
            :wrapperCol="{ span: 17, offset: 1 }"
          >
            <a-input
              v-decorator="[
                'master',
                {
                  rules: [{ required: true, message: '请输入法人代表人姓名' },
                  { required: true, message: '最长不超过12位汉字' ,pattern:/(^[\u4e00-\u9fa5]{1,12}$)/}
                ],
                },
              ]"
              placeholder="请输入法人代表人姓名"
              allowClear
              :maxLength="13"
              :disabled="query.readonly"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            :label="`现场联系人`"
            :labelCol="{ span: 5 }"
            :wrapperCol="{ span: 17, offset: 1 }"
          >
            <a-input
              v-decorator="[
                'xclxr',
                { rules: [{ required: true, message: '请输入现场联系人' },
                { required: true, message: '最长不超过12位汉字' ,pattern:/(^[\u4e00-\u9fa5]{1,12}$)/}
              ] },
              ]"
              placeholder="请输入现场联系人"
              allowClear
              :maxLength="13"
              :disabled="query.readonly"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            :label="`现场联系人电话`"
            :labelCol="{ span: 5 }"
            :wrapperCol="{ span: 17, offset: 1 }"
          >
            <a-input
              v-decorator="[
                'xclxrPhone',
                {
                  rules: [{ required: false, message: '手机号格式错误',
            pattern: new RegExp(/^1\d{10}$/),}],
                },
              ]"
              placeholder="请输入现场联系人电话"
              allowClear
              :maxLength="11"
              :disabled="query.readonly"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            :label="`分包合同编号`"
            :labelCol="{ span: 5 }"
            :wrapperCol="{ span: 17, offset: 1 }"
          >
            <a-input
              v-decorator="[
                'fbhtbh',
                { rules: [{ required: false, message: '请输入分包合同编号' }
              ] },
              ]"
              placeholder="请输入分包合同编号"
              allowClear
              :maxLength="31"
              :disabled="query.readonly"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item
            :label="`合同造价(万元)`"
            :labelCol="{ span: 5 }"
            :wrapperCol="{ span: 17, offset: 1 }"
          >
            <a-input
              v-decorator="[
                'htzj',
                { rules: [
                  { required: false, message: '请输入数字',pattern: new RegExp(/^-?[0-9]*(\.[0-9]*)?$/),}
                  ,
                  {
                    required: false,
                    message: '数字最长为8位数',
                    pattern: /^\d{1,8}$/,
                  },
                ] },
              ]"
              placeholder="请输入合同造价"
              allowClear
              :maxLength="9"
              :disabled="query.readonly"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <div class="bottomSubmit">
        <a-button v-if="!query.readonly" type="primary" html-type="submit"> 确定 </a-button>
        <a-button @click="close"> 关闭 </a-button>
      </div>
    </a-form>
  </div>
</template>

<script>
import { request } from "@/utils/request";
import { PROJECT_OFFICE_CHILD } from "@/services/api";
import { mdpSaveOffices,getDetailOffice} from "@/services/participationUnit";
import { getOfficeTypeList } from "@/services/sys";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      query: this.$route.query,
      // form: this.$form.createForm(this, { name: 'coordinated' }),
      projectArr: [], //项目名称
      projectList: [], //获取当前项目数据
      defaultFileList: [], //图片数组
      form: this.$form.createForm(this), //初始化form表单
      selectlist: [],
      params: {}, //提交表单参数
      roleTypeName: "",
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  inject: ["fatherMethod"],
  mounted() {
    this.initData();
    this.getData()
    // if (this.query.data && Object.keys(this.query.data).length > 0) {
    //   this.queryObj(this.$route.query.data);
    // }
  },
  methods: {
    getData() {
      getDetailOffice(this.query.id).then(res => {
        if (res.data.code === 0) {
          this.queryObj(res.data.data);
        }
      })
    },
    // 关闭当前页面
    close() {
      let path = this.$route.path;
      this.fatherMethod(`${path}`);
    },
    // 提交按钮
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        Object.keys(values).forEach((key) => {
          values[key] = values[key] == undefined ? "" : values[key];
        });
        if (!err) {
          if (values.roleType) {
            this.selectlist.forEach((item) => {
              if (values.roleType == item.value) {
                this.roleTypeName = item.name;
              }
            });
          }
          let params = {
            ...values,
            id: this.query.id,
            officeId: this.query.officeId,
            projectId: this.query.projectId ? this.query.projectId : this.user.projectId,
            roleTypeName: this.roleTypeName,
          };
          console.log(params,'保存的详情');
          mdpSaveOffices(params).then((res) => {
            if (res.data.code === 0) {
              this.$message.success("保存参建单位成功", 10);
              // this.$refs["form"].resetFields();
              this.close();
            } else {
              this.$message.error('参建单位保存失败，请检查单位信息', 10);
            }
          });
        }
      });
    },
    // 路由key和传入值key匹配
    queryObj(e) {
      console.log(e.code);
      this.$nextTick(() => {
        this.form.setFieldsValue({
          code: e.code,
          roleType: e.roleType,
          master: e.master,
          fbhtbh: e.fbhtbh,
          htzj: e.htzj,
          officeName: e.officeName,
          xclxr: e.xclxr,
          xclxrPhone: e.xclxrPhone,
        });
      });
    },
    // 输入下拉框防止第一次数据没变渲染出来
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 获取公司数组
    initData() {
      getOfficeTypeList().then((res) => {
        if (res.data.data.companyTypeList) {
          const data = res.data.data.companyTypeList;
          const arr = [];
          data.forEach((item) => {
            arr.push({
              name: item.label,
              value: item.value,
            });
          });
          this.selectlist = arr;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border-radius: 6px;
  margin-bottom: 10px;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

tr {
  background-color: #ffffff;
}

.plug-form {
  /deep/ .plug-Overview {
    font-size: 16px;
    color: #101010;
    font-weight: bold;
    .ant-col-offset-1 {
      margin-left: 10px;
    }
  }
  /deep/ .ant-form {
    .ant-btn {
      margin-right: 20px !important;
    }
  }
  .bottomSubmit {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    margin-right: 160px;
  }
}
</style>
