var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { forceRender: "", title: _vm.visibleTitle, width: 1500 },
      on: { cancel: _vm.handleCancel, ok: _vm.batchExit },
      model: {
        value: _vm.visibleExit,
        callback: function ($$v) {
          _vm.visibleExit = $$v
        },
        expression: "visibleExit",
      },
    },
    [
      _c(
        "div",
        { staticClass: "container", staticStyle: { height: "600px" } },
        [
          _c("form-index", {
            ref: "formindex",
            attrs: { formData: _vm.formData, flexSubmit: true },
            on: {
              handleSubmit: _vm.handleSubmit,
              handlSelect: _vm.handlSelect,
            },
            scopedSlots: _vm._u([
              {
                key: "flexSubmit",
                fn: function () {
                  return [
                    _c(
                      "a-space",
                      {
                        staticStyle: {
                          "margin-left": "55px",
                          "margin-top": "5px",
                        },
                      },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: {
                              icon: "search",
                              type: "primary",
                              "html-type": "submit",
                            },
                          },
                          [_vm._v(" 查询 ")]
                        ),
                        _c(
                          "a-space",
                          { staticClass: "operator" },
                          [
                            _c("a-button", { on: { click: _vm.close } }, [
                              _vm._v(" 重置 "),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "customAction",
                fn: function () {
                  return [
                    _c(
                      "a-input-group",
                      { attrs: { compact: "" } },
                      [
                        _c(
                          "a-input",
                          {
                            staticStyle: {
                              width: "42%",
                              "text-align": "center",
                              "border-right": "0",
                            },
                            attrs: { "max-length": 2, placeholder: "最小" },
                            on: {
                              onChange: function ($event) {
                                return _vm.onChange($event, "startAge")
                              },
                              blur: function ($event) {
                                return _vm.onBlur($event, "startAge")
                              },
                            },
                            model: {
                              value: _vm.params.startAge,
                              callback: function ($$v) {
                                _vm.$set(_vm.params, "startAge", $$v)
                              },
                              expression: "params.startAge",
                            },
                          },
                          [
                            _c(
                              "a-tooltip",
                              { attrs: { slot: "suffix" }, slot: "suffix" },
                              [
                                _c("span", { staticClass: "text-color" }, [
                                  _vm._v("岁"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("a-input", {
                          staticStyle: {
                            width: "32px",
                            "border-left": "0",
                            "pointer-events": "none",
                            backgroundColor: "#fff",
                          },
                          attrs: { placeholder: "~", disabled: "" },
                        }),
                        _c(
                          "a-input",
                          {
                            staticClass: "input-sytle",
                            staticStyle: {
                              width: "42%",
                              "text-align": "center",
                              "border-right": "0",
                            },
                            attrs: { "max-length": 2, placeholder: "最大" },
                            on: {
                              onChange: function ($event) {
                                return _vm.onChange($event, "endAge")
                              },
                              blur: function ($event) {
                                return _vm.onBlur($event, "endAge")
                              },
                            },
                            model: {
                              value: _vm.params.endAge,
                              callback: function ($$v) {
                                _vm.$set(_vm.params, "endAge", $$v)
                              },
                              expression: "params.endAge",
                            },
                          },
                          [
                            _c(
                              "a-tooltip",
                              { attrs: { slot: "suffix" }, slot: "suffix" },
                              [
                                _c("span", { staticClass: "text-color" }, [
                                  _vm._v("岁"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c(
            "div",
            {
              staticClass: "a_a_footer",
              staticStyle: { "margin-top": "-20px" },
            },
            [
              _c("div", { staticClass: "mb-1 mt-2" }),
              _c(
                "div",
                { staticStyle: { height: "450px", "overflow-y": "auto" } },
                [
                  _c("standard-table", {
                    attrs: {
                      columns: _vm.columns,
                      dataSource: _vm.dataSource,
                      selectedRows: _vm.selectedRows,
                      rowKey: "id",
                      scroll: { x: 1300 },
                      pagination: _vm.pagination,
                      loading: _vm.tableLoading,
                      isAlert: false,
                      bordered: true,
                      checkBoxDisable: _vm.checkBoxDisable,
                    },
                    on: {
                      "update:selectedRows": [
                        function ($event) {
                          _vm.selectedRows = $event
                        },
                        function ($event) {
                          return _vm.updateSelectRows($event)
                        },
                      ],
                      "update:selected-rows": function ($event) {
                        _vm.selectedRows = $event
                      },
                      change: _vm.onPageChange,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "index",
                        fn: function (ref) {
                          var index = ref.index
                          return [
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (_vm.pagination.current - 1) *
                                      _vm.pagination.pageSize +
                                      parseInt(index) +
                                      1
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "leaveStatus",
                        fn: function (ref) {
                          var text = ref.text
                          var record = ref.record
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "jwTag",
                                class: _vm.getLeaveStatusStyle(text),
                                on: {
                                  click: function ($event) {
                                    return _vm.visitPre(record)
                                  },
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.getLeaveStatusText(text))),
                                ]),
                              ]
                            ),
                          ]
                        },
                      },
                      {
                        key: "workName",
                        fn: function (ref) {
                          var record = ref.record
                          return [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to:
                                    "/electronic_resume/" +
                                    record.projWorkNum +
                                    "?teamId=" +
                                    record.teamId +
                                    "&workNum=" +
                                    record.workNum,
                                },
                              },
                              [_vm._v(" " + _vm._s(record.workName) + " ")]
                            ),
                          ]
                        },
                      },
                      {
                        key: "workerFace",
                        fn: function (ref) {
                          var text = ref.text
                          return [
                            _c("a-avatar", {
                              attrs: {
                                shape: "square",
                                size: 64,
                                src: text,
                                icon: "user",
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: "isreport",
                        fn: function (ref) {
                          var text = ref.text
                          return [
                            text == 1
                              ? _c("span", [
                                  _c("i", {
                                    staticClass: "iconfont duigouxiao",
                                    staticStyle: { color: "#00CE86" },
                                  }),
                                ])
                              : _c("span", [
                                  _c("i", {
                                    staticClass: "iconfont path",
                                    staticStyle: { color: "#FF5C77" },
                                  }),
                                ]),
                          ]
                        },
                      },
                      {
                        key: "syncJwStatus",
                        fn: function (ref) {
                          var text = ref.text
                          var record = ref.record
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "jwTag",
                                class: _vm.getJwStatusStyle(text),
                                on: {
                                  click: function ($event) {
                                    return _vm.errToast(record)
                                  },
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.getJwStatusText(text))),
                                ]),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "a-modal",
            {
              attrs: {
                title: _vm.modalTitle,
                destroyOnClose: true,
                width: _vm.modalWidth,
                footer: null,
              },
              on: { cancel: _vm.modalCancel },
              model: {
                value: _vm.modalVisible,
                callback: function ($$v) {
                  _vm.modalVisible = $$v
                },
                expression: "modalVisible",
              },
            },
            [
              _vm.modalTitle === "下发任务"
                ? _c("span", [
                    _vm._v(
                      "该列表有效期为3小时,结束后请前去“下发失败记录”中查看是否下发失败"
                    ),
                  ])
                : _vm._e(),
              _c("standard-table", {
                attrs: {
                  columns: _vm.modalColumns,
                  dataSource: _vm.modalDataSource,
                  rowKey: "id",
                  scroll: { x: _vm.modalWidth - 100, y: 500 },
                  pagination: _vm.modalPagination,
                  loading: _vm.modalTableLoading,
                  isAlert: false,
                  bordered: true,
                },
                on: { change: _vm.onModalPageChange },
                scopedSlots: _vm._u([
                  {
                    key: "index",
                    fn: function (ref) {
                      var index = ref.index
                      return [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                (_vm.modalPagination.current - 1) *
                                  _vm.modalPagination.pageSize +
                                  parseInt(index) +
                                  1
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "a-modal",
            {
              attrs: {
                title: "退场",
                destroyOnClose: true,
                footer: null,
                width: "680px",
              },
              model: {
                value: _vm.exitDialog,
                callback: function ($$v) {
                  _vm.exitDialog = $$v
                },
                expression: "exitDialog",
              },
            },
            [
              _c(
                "a-form",
                _vm._b(
                  {
                    attrs: {
                      id: "components-form-demo-validate-other",
                      form: _vm.exitForm,
                    },
                    on: { submit: _vm.exitSubmit },
                  },
                  "a-form",
                  _vm.formItemLayout,
                  false
                ),
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "退场凭证", extra: "最多可选1个" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex a-center" },
                        [
                          _vm.defaultFileList.length > 0
                            ? [
                                _c(
                                  "div",
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.defaultFileList[0].name)
                                      ),
                                    ]),
                                    _c("a-icon", {
                                      staticClass: "ml-2",
                                      staticStyle: {
                                        color: "red",
                                        cursor: "pointer",
                                      },
                                      attrs: { type: "close" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.upRemove(
                                            _vm.defaultFileList[0]
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            : [
                                _c(
                                  "a-upload",
                                  {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "upload",
                                          {
                                            rules: [
                                              {
                                                required: true,
                                                message: "请上传",
                                              },
                                            ],
                                          },
                                        ],
                                        expression:
                                          "[\n                  'upload',\n                  { rules: [{ required: true, message: '请上传' }] },\n                ]",
                                      },
                                    ],
                                    attrs: {
                                      beforeUpload: _vm.beforeUpload,
                                      customRequest: _vm.resourceUpload,
                                      "file-list": _vm.defaultFileList,
                                    },
                                    on: { preview: _vm.handlePreview },
                                  },
                                  [
                                    _c(
                                      "a-button",
                                      { attrs: { type: "primary" } },
                                      [_vm._v(" 点击选择文件 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                          _c(
                            "a-button",
                            {
                              staticClass: "ml-3",
                              attrs: { type: "link" },
                              on: { click: _vm.doDowload },
                            },
                            [_vm._v(" 下载文件模板 ")]
                          ),
                        ],
                        2
                      ),
                    ]
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "满意程度" } },
                    [
                      _c(
                        "a-radio-group",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "grade",
                                {
                                  rules: [
                                    { required: true, message: "请选择" },
                                  ],
                                },
                              ],
                              expression:
                                "['grade', { rules: [{ required: true, message: '请选择' }] },]",
                            },
                          ],
                        },
                        [
                          _c("a-radio", { attrs: { value: "1" } }, [
                            _vm._v(" 不满意 "),
                          ]),
                          _c("a-radio", { attrs: { value: "2" } }, [
                            _vm._v(" 基本满意 "),
                          ]),
                          _c("a-radio", { attrs: { value: "3" } }, [
                            _vm._v(" 满意 "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { "wrapper-col": { span: 12, offset: 16 } } },
                    [
                      _c(
                        "a-button",
                        {
                          staticClass: "mr-3",
                          on: { click: _vm.closeExitDialog },
                        },
                        [_vm._v(" 关闭 ")]
                      ),
                      _c(
                        "a-button",
                        { attrs: { type: "primary", "html-type": "submit" } },
                        [_vm._v(" 提交 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("Export-loading", {
            attrs: {
              cPercent: _vm.cPercent,
              isExporting: _vm.isExporting,
              loadingText: _vm.loadingText,
            },
          }),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            centered: "",
            title: "退场记录",
            destroyOnClose: true,
            width: "572px",
            footer: null,
          },
          on: { cancel: _vm.modalCancelPre },
          model: {
            value: _vm.modalVisiblePre,
            callback: function ($$v) {
              _vm.modalVisiblePre = $$v
            },
            expression: "modalVisiblePre",
          },
        },
        [
          _vm.preRecord
            ? _c(
                "a-timeline",
                { staticStyle: { "padding-left": "159px" } },
                [
                  _c(
                    "a-timeline-item",
                    [
                      _c("a-icon", {
                        staticStyle: { "font-size": "20px" },
                        attrs: {
                          slot: "dot",
                          type: "check-circle",
                          theme: "filled",
                        },
                        slot: "dot",
                      }),
                      _c("div", { staticClass: "time-item" }, [
                        _vm._v(_vm._s(_vm.preRecord.sendParams.doTime)),
                      ]),
                      _c("div", { staticClass: "black pb-1" }, [
                        _vm._v(_vm._s(_vm.preRecord.sendParams.title)),
                      ]),
                      _c("div", { staticClass: "black-three" }, [
                        _vm._v(
                          "发起人: " + _vm._s(_vm.preRecord.sendParams.doUser)
                        ),
                      ]),
                    ],
                    1
                  ),
                  !_vm.preRecord.quitDate
                    ? _c(
                        "a-timeline-item",
                        [
                          _c("a-icon", {
                            staticStyle: { "font-size": "20px" },
                            attrs: {
                              slot: "dot",
                              type: "check-circle",
                              theme: "twoTone",
                              "two-tone-color": "#999999",
                            },
                            slot: "dot",
                          }),
                          _c("div", { staticClass: "time-item" }, [
                            _vm._v(_vm._s(_vm.preRecord.autoTime)),
                          ]),
                          _c("div", { staticClass: "black pb-2" }, [
                            _vm._v("超时自动确认"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.preRecord.doParams && _vm.preRecord.leaveType == 2
                    ? _c(
                        "a-timeline-item",
                        {
                          attrs: {
                            color:
                              _vm.preRecord.doParams.doUser ==
                              "超时系统自动确认"
                                ? "red"
                                : "blue",
                          },
                        },
                        [
                          _c("a-icon", {
                            staticStyle: { "font-size": "20px" },
                            attrs: {
                              slot: "dot",
                              type: "check-circle",
                              theme: "filled",
                            },
                            slot: "dot",
                          }),
                          _c("div", { staticClass: "time-item" }, [
                            _vm._v(_vm._s(_vm.preRecord.doParams.doTime)),
                          ]),
                          _c("div", { staticClass: "black pb-1" }, [
                            _vm._v(_vm._s(_vm.preRecord.doParams.title)),
                          ]),
                          _c("span", { staticClass: "black-three" }, [
                            _vm._v(
                              "状态: " + _vm._s(_vm.preRecord.doParams.doUser)
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.preRecord.quitDate
                    ? _c(
                        "a-timeline-item",
                        { attrs: { color: "green" } },
                        [
                          _c("a-icon", {
                            staticStyle: { "font-size": "20px" },
                            attrs: {
                              slot: "dot",
                              type: "check-circle",
                              theme: "filled",
                            },
                            slot: "dot",
                          }),
                          _c("div", { staticClass: "time-item" }, [
                            _vm._v(_vm._s(_vm.preRecord.quitDate)),
                          ]),
                          _c("div", { staticClass: "black pb-1" }, [
                            _vm._v("完成"),
                          ]),
                          _c("a-avatar", {
                            attrs: {
                              theme: "twoTone",
                              "two-tone-color": "#eb2f96",
                              shape: "square",
                              size: 64,
                              src: _vm.testSrc,
                              icon: "file-word",
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "#0874F0",
                                cursor: "pointer",
                              },
                              on: { click: _vm.doDowloadPre },
                            },
                            [_vm._v("下载退场凭证")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }