var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-drawer",
        {
          attrs: {
            width: 1020,
            visible: _vm.visible,
            "body-style": { paddingBottom: "80px" },
          },
          on: { close: _vm.onClose },
        },
        [
          _c("template", { slot: "title" }, [
            _vm._v("检查项目名称：" + _vm._s(_vm.projectName)),
          ]),
          _c(
            "a-row",
            { staticStyle: { "margin-top": "5px" }, attrs: { gutter: 16 } },
            [
              _c("a-col", { attrs: { span: 12 } }, [
                _c("span", [
                  _vm._v("检查公司："),
                  _c("strong", [_vm._v(_vm._s(_vm.officeName))]),
                ]),
              ]),
              _c("a-col", { attrs: { span: 12 } }, [
                _c("span", [
                  _vm._v("检查时间："),
                  _c("strong", [
                    _vm._v(
                      _vm._s(_vm.startDate) + " 至 " + _vm._s(_vm.endDate)
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c("standard-table", {
            attrs: {
              columns: _vm.columns,
              dataSource: _vm.dataSource,
              loading: _vm.tableLoading,
              isAlert: false,
              pagination: false,
            },
            on: { clear: _vm.onClear },
            scopedSlots: _vm._u([
              {
                key: "safediscloseFileName",
                fn: function (ref) {
                  var text = ref.text
                  return _c(
                    "div",
                    {},
                    [
                      text != null && text != ""
                        ? _c("div", [_vm._v(_vm._s(text))])
                        : _c("a-icon", {
                            staticStyle: { color: "red" },
                            attrs: { type: "close" },
                          }),
                    ],
                    1
                  )
                },
              },
              {
                key: "do-status",
                fn: function (ref) {
                  var text = ref.text
                  return _c("div", {}, [
                    text == 0
                      ? _c("label", [_vm._v("待检查")])
                      : text == 1
                      ? _c("label", [_vm._v("待复核")])
                      : text == 2
                      ? _c("label", [_vm._v("已完成")])
                      : text == 3
                      ? _c("label", [_vm._v("整改中")])
                      : _vm._e(),
                  ])
                },
              },
              {
                key: "files",
                fn: function (record) {
                  return _c(
                    "div",
                    {},
                    [
                      record.record.fileStatus != null &&
                      record.record.fileStatus != 0
                        ? _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.download(1, "" + record.record.id)
                                },
                              },
                            },
                            [_vm._v("安全检查整改单")]
                          )
                        : _vm._e(),
                      record.record.filePath2 != null &&
                      record.record.filePath2 != ""
                        ? _c("a-divider", { attrs: { type: "vertical" } })
                        : _vm._e(),
                      record.record.filePath2 != null &&
                      record.record.filePath2 != ""
                        ? _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.download(2, "" + record.record.id)
                                },
                              },
                            },
                            [_vm._v("检查记录单")]
                          )
                        : _vm._e(),
                      record.record.zghfFilePath != null &&
                      record.record.zghfFilePath != ""
                        ? _c("a-divider", { attrs: { type: "vertical" } })
                        : _vm._e(),
                      record.record.zghfFilePath != null &&
                      record.record.zghfFilePath != ""
                        ? _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.download(3, "" + record.record.id)
                                },
                              },
                            },
                            [_vm._v("整改回复")]
                          )
                        : _vm._e(),
                      record.record.stopWorkFile != null &&
                      record.record.stopWorkFile != ""
                        ? _c("a-divider", { attrs: { type: "vertical" } })
                        : _vm._e(),
                      record.record.stopWorkFile != null &&
                      record.record.stopWorkFile != ""
                        ? _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.download(4, "" + record.record.id)
                                },
                              },
                            },
                            [_vm._v("停工通知单")]
                          )
                        : _vm._e(),
                      record.record.applyWorkFile != null &&
                      record.record.applyWorkFile != ""
                        ? _c("a-divider", { attrs: { type: "vertical" } })
                        : _vm._e(),
                      record.record.applyWorkFile != null &&
                      record.record.applyWorkFile != ""
                        ? _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.download(5, "" + record.record.id)
                                },
                              },
                            },
                            [_vm._v("复工申请单")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
          _c(
            "div",
            {
              style: {
                position: "absolute",
                right: 0,
                bottom: 0,
                width: "100%",
                borderTop: "1px solid #e9e9e9",
                padding: "10px 16px",
                background: "#fff",
                textAlign: "right",
                zIndex: 1,
              },
            },
            [
              _c(
                "a-button",
                { style: { marginLeft: "8px" }, on: { click: _vm.onClose } },
                [_vm._v(" 关闭 ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }