<template>
  <div class="container">
    <form-index
      ref="formindex"
      :formData="formData"
      :projectList="projectList"
      @handleSubmit="handleSubmit"
      :flexAction="true"
      @handlSelect="handlSelect"
      @handleChange="handleChange"
    >
      <!-- 下部分按钮插槽 -->
      <template #flexAction>
        <a-space :style="{ marginTop: '4px' }">
          <a-button type="primary" html-type="submit"> 查询 </a-button>
          <a-space class="operator">
            <a-button @click="close"> 重置 </a-button>
          </a-space>
          <a-button @click="exportExcelStaff">导出</a-button>
        </a-space>
      </template>
    </form-index>
    <div class="a_a_footer">
      <div>
        <!-- <a-space class="operator">
          <a-button @click="exportExcel">导出</a-button>
        </a-space> -->
        <!-- <a-space class="operator">
          <a-button @click="doDowloadBatch(3)">刷新</a-button>
        </a-space> -->
      </div>
      <!-- 表格封装 -->
      <standard-table
        class=""
        :columns="columns"
        :dataSource="dataSource"
        rowKey="index"
        :scroll="{ x: 1800, y: 'calc(100vh - 430px)' }"
        :pagination="pagination"
        :loading="tableLoading"
        @change="onPageChange"
        :isAlert="false"
        :bordered="true"
      >
        <template slot="title">
          <h3 class="table-title">{{ tableTitle }}</h3>
          <div class="table-desc">
            <span>项目名称（盖章）：{{user.loginType == 0 ? user.loginLaoWuUser.projectName : ''}}</span>
            <div>
              <span v-show="params.officeName">单位：{{params.officeName ? params.officeName : '全部'}}</span>
              <span style="margin-left: 50px;" v-show="params.teamName">班组：{{params.teamName ? params.teamName : '全部'}}</span>
            </div>
          </div>
        </template>
        <template slot="index" slot-scope="{ index }">
          <span>{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}</span>
        </template>
        <template v-for="(day, index1) in days" :slot="params['month'] + day" slot-scope="{ record }">
          <div :key="index1">
            <template v-if="record[params['month'] + day] && record[params['month'] + day].length > 2">
              <!-- <div class="flex a-center">
                <span>{{ record[params['month'] + day][0]['time'] }}</span>
                <span v-if="record[params['month'] + day][0]['checkType'] == 1" class="ml-05 font-12 in-span">进</span>
                <span v-else class="ml-05 font-12 out-span">出</span>
              </div> -->
              <!-- <div class="flex a-center">
                <span>{{ record[params['month'] + day][1]['time'] }}</span>
                <span v-if="record[params['month'] + day][1]['checkType'] == 1" class="ml-05 font-12 in-span">进</span>
                <span v-else class="ml-05 font-12 out-span">出</span>
              </div> -->
              <div style="cursor: pointer;">
                <a-popover placement="right">
                  <template slot="content">
                    <div class="flex a-center" :key="index2" v-for="(obj, index2) in record[params['month'] + day]">
                      <span>{{ obj['time'] }}</span>
                      <span v-if="obj['checkType'] == 1" class="ml-05 font-12 in-span">进</span>
                      <span v-else class="ml-05 font-12 out-span">出</span>
                    </div>
                  </template>
                  <div>
                    <div class="flex a-center">
                      <span>{{ getEarliestTime(record[params['month'] + day]) }}</span>
                      <span v-if="getEarliestTime(record[params['month'] + day])" class="ml-05 font-12 in-span">进</span>
                      <!-- <span v-else class="ml-05 font-12 out-span">出</span> -->
                    </div>
                    <div class="flex a-center">
                      <span>{{ getLatestTime(record[params['month'] + day]) }}</span>
                      <!-- <span v-if="record[params['month'] + day][record[params['month'] + day].length - 1]['checkType'] == 1" class="ml-05 font-12 in-span">进</span> -->
                      <span v-if="getLatestTime(record[params['month'] + day])" class="ml-05 font-12 out-span">出</span>
                    </div>
                  </div>
                  <!-- <div style="cursor: pointer;">
                    ······
                  </div> -->
                </a-popover>
              </div>
              <!-- <div class="flex a-center">
                <span>{{ record[params['month'] + day][record[params['month'] + day].length - 2]['time'] }}</span>
                <span v-if="record[params['month'] + day][record[params['month'] + day].length - 2]['checkType'] == 1" class="ml-05 font-12 in-span">进</span>
                <span v-else class="ml-05 font-12 out-span">出</span>
              </div> -->
              <!-- <div class="flex a-center">
                <span>{{ record[params['month'] + day][record[params['month'] + day].length - 1]['time'] }}</span>
                <span v-if="record[params['month'] + day][record[params['month'] + day].length - 1]['checkType'] == 1" class="ml-05 font-12 in-span">进</span>
                <span v-else class="ml-05 font-12 out-span">出</span>
              </div> -->
            </template>
            <template v-else-if="record[params['month'] + day] && record[params['month'] + day].length <= 2 && record[params['month'] + day].length > 0">
              <div>
                <div class="flex a-center" :key="index2" v-for="(obj, index2) in record[params['month'] + day]">
                  <span>{{ obj['time'] }}</span>
                  <span v-if="obj['checkType'] == 1" class="ml-05 font-12 in-span">进</span>
                  <span v-else class="ml-05 font-12 out-span">出</span>
                </div>
              </div>
            </template>
          </div>
        </template>
      </standard-table>
    </div>
    <Export-loading :cPercent="cPercent" :isExporting="isExporting" :loadingText="loadingText"></Export-loading>
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import { getStatistics } from "@/services/dmgr";
import { getAllTeams, getProAllOfficeList } from "@/services/participationUnit";
import { getOfficeListByProjectId } from "@/services/payroll";
import moment from "moment";
import {mapGetters} from 'vuex'
import {EXPORT_ATTE_STATISTICS} from '@/services/api'
import {request} from '@/utils/request'
import { exportProgress } from '@/pages/aqjyzl/export' // 导出方法
import ExportLoading from '@/components/ExportLoading/ExportLoading' // progress组件
const formData = [
  {
    label: "月份",
    placeholder: "请选择",
    decorator: [
      "month",
      {
        initialValue: moment(moment()).format("YYYYMM"),
        rules: [{ required: false, message: "请选择" }],
      },
    ],
    type: "monthPicker",
    key: "month",
    col: 4,
    labelCol: 5,
    wrapperCol: 16,
    display: true,
    format: "YYYYMM",
  },
  {
    label: "姓名",
    placeholder: "请输入姓名",
    decorator: ["name", { rules: [{ required: false, message: "请输入姓名" }] }],
    type: "input",
    key: "name",
    col: 4,
    labelCol: 6,
    wrapperCol: 16,
    display: true,
  },
  {
    label: "所属单位",
    placeholder: "请输入所属单位",
    decorator: [
      "officeId",
      { rules: [{ required: false, message: "请选择" }] },
    ],
    type: "inputGroup",
    key: "officeId",
    dataSource: [],
    col: 4,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "班组名称",
    placeholder: "请输入选择所属班组",
    decorator: ["teamId", { rules: [{ required: false, message: "请输入选择所属班组" }] }],
    type: "inputGroup",
    key: "teamId",
    selectlist: [],
    dataSource: [],
    col: 4,
    labelCol: 6,
    wrapperCol: 16,
    display: true,
  },
  {
    type: 'action',
    col: 6,
    display: true,
  }
];
const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
    fixed: 'left' 
  },
  {
    title: "姓名",
    dataIndex: "name",
    width: 85,
    align: "center",
    scopedSlots: { customRender: "name" },
    fixed: 'left' 
  },
  {
    title: "合计",
    dataIndex: "total",
    align: "center",
    width: 65,
    scopedSlots: { customRender: "total" },
    fixed: 'left' 
  },
];
export default {
  mixins: [exportProgress],
  components: {
    formIndex,
    StandardTable,
    ExportLoading
  },
  data() {
    return {
      projectArr: [],
      projectList: [], //获取当前项目数据
      tableTitle: '',
      formData: formData,
      columns: columns,
      dataSource: [], //table数组
      selectedRows: [], //选中的数据
      pagination: {
        //分页数据
        showSizeChanger:true,
        pageSizeOptions: ['10', '50', '100', '150'],
        current:1,
        pageSize:50,
        total:0,
        showLessItems:true,
        showQuickJumper:true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      days: [],
      params: {
        //测试数据
        pageNumber: 1,
        pageSize: 50,
        isPage: "true",
        month: moment(moment()).format("YYYYMM"),
        officeId: '',
        teamId: '',
        teamName: '',
        officeName: '',
        name: ''
      },
      tableLoading: false, //table加载中
      cPercent:0,
      isExporting:false,
      loadingText:"请稍后.",
    };
  },
  watch: {
    "params.month": {
      handler(newVal, oldVal) {
        // console.log(newVal,'newVal')
        // console.log(oldVal,'oldVal')
        if (oldVal) {
          const oldDays = moment(oldVal, "YYYY-MM").daysInMonth();
          for (let i = 0; i < oldDays; i++) {
            this.columns.pop();
          }
        }
        // 点击面包屑-工人考勤明细表有bug。暂时这么解决
          this.columns = [
          {
            title: "序号",
            width: 65,
            scopedSlots: { customRender: "index" },
            // customRender: (text, record, index) => `${index + 1}`,
            align: "center",
            fixed: 'left' 
          },
          {
            title: "姓名",
            dataIndex: "name",
            width: 85,
            align: "center",
            scopedSlots: { customRender: "name" },
            fixed: 'left' 
          },
          {
            title: "合计",
            dataIndex: "total",
            align: "center",
            width: 65,
            scopedSlots: { customRender: "total" },
            fixed: 'left' 
          },
        ]
        const newDays = moment(newVal, "YYYY-MM").daysInMonth();
        this.tableTitle = moment(newVal).format("YYYY年MM月") + '工人进出时间表'
        for (let i = 1; i <= newDays; i++) {
          let day = i < 10 ? "0" + i : i + "";
          this.columns.push({
            title: i,
            dataIndex: newVal + day,
            align: "center",
            width: 100,
            scopedSlots: { customRender: newVal + day },
          });
          this.days.push(day)
        }
        console.log(this.days)
      },
      immediate: true,
    },
  },
  mounted() {
    this.getData(this.params);
    this.getZdata(getProAllOfficeList, {value: '', item: {key: 'officeId'}}, "officeName");
  },
  computed: {
    ...mapGetters('account', ['user']),
  },
  methods: {
    getEarliestTime(arr) {
      const filteredData = arr.filter(item => item.checkType === '1');
      if (filteredData.length > 0) {
        const earliestTime = filteredData.reduce((earliest, current) => {
          return current.time < earliest ? current.time : earliest;
        }, filteredData[0].time);
        return earliestTime;
      }
    },
    getLatestTime(arr) {
      const filteredData = arr.filter(item => item.checkType === '2');
      if (filteredData.length > 0) {
        const latestTime = filteredData.reduce((latest, current) => {
          return current.time > latest ? current.time : latest;
        }, filteredData[0].time);
        return latestTime;
      }
    },
    getData(params) {
      this.tableLoading = true;
      getStatistics(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code === 0) {
          this.$nextTick(() => {
            this.pagination.total = res.data.data.total
            this.dataSource = res.data.data.list;
            this.dataSource.forEach((el) => {
              const dayAtte = el.dayAtte;
              dayAtte.forEach((i) => {
                // console.log(i)
                if (i["atteTime"] && i["atteTime"].length > 0) {
                  let atteTime = i["atteTime"].map((j) => {
                    let timeArr = j['atteTime'].substring(8, 12).split('')
                    return {time: timeArr[0] + timeArr[1] + ':' + timeArr[2] + timeArr[3], checkType: j.checkType}
                    // return moment(j).format('YYYYMMDDHHmmss')
                  });
                  el[i["day"]] = atteTime;
                }
              });
            });
            console.log(this.dataSource, 'this.dataSource')
            console.log(this.columns, 'this.columns')
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //查询所有单位
    getOfficeListByProjectId() {
      getOfficeListByProjectId().then((res) => {
        if (res.data.code === 0) {
          this.formDataFn("officeId", res.data.data, "name");
        }
      });
    },
    //查询所有班组
    doQueryTeams(params) {
      if (params) {
        getAllTeams(params).then((res) => {
          if (res.data.code === 0) {
            this.formDataFn("teamId", res.data.data, "teamName");
          }
        });
      } else {
        this.formDataFn("teamId", [], "teamName");
      }
    },
    handleChange(e) {
      if (e.item.key == 'teamId') {
        this.doQueryTeams({ teamName: e.value })
      } else if (e.item.key == 'officeId') {
        this.projectArr = [];
        if (!e.value) {
          this.$refs["formindex"].setFieldsValue('', {}, "officeId");
        }
        this.getZdata(getProAllOfficeList, e, "officeName");
      }
    },
    // 接口封装
    /**
     * msg 接收接口名称
     * e 数据
     * name 参数名
     * */
     getZdata(msg, e, name) {
      let data = {
        [name]: e.value,
        projectId: this.user.projectId
      };

      msg(data).then((res) => {
        if (res.status == 200) {
          const data = res.data.data.list ? res.data.data.list : res.data.data
          data.forEach((item) => {
            this.projectArr.push({
              title: item[name],
              id: item.officeId,
            });
          });
          this.projectList = data;
          // 获取当前匹配key，赋值相对于数组
          this.formData.forEach((item) => {
            if (e.item.key === item.key) {
              item.dataSource = this.projectArr;
            }
          });
        }
      });
    },
    // 对下拉框做数据处理
    formDataFn(key, data, itemName) {
      let arrSelect = []
      let arrSource = []
      data.forEach((item) => {
        // 班组模糊匹配dataSource
        arrSource.push({ 'title': item[itemName], 'id': item.id })
        // seletetList选项
        arrSelect.push({ 'name': item[itemName], 'value': item.id })
      });
      this.formData.forEach((item) => {
        if (item.key == key) {
          item.dataSource = arrSource
          item.selectlist = arrSelect
        }
      });
    },
    handleSubmit(e) {
      this.params.pageNumber = 1
      this.params = { ...this.params, ...e };
      this.pagination.current = 1;
      if (e.officeId) {
        this.formData.forEach(el => {
          if (el.key == 'officeId') {
            this.$set(this.params, 'officeName', this.projectList.find(i => e.officeId === i.officeId).officeName)
          }
        })
      } else {
        this.$set(this.params, 'officeName', '')
      }
      if (e.teamId) {
        this.formData.forEach(el => {
          if (el.key == 'teamId') {
            this.$set(this.params, 'teamName', el.selectlist.find(i => e.teamId === i.value).name)
          }
        })
      } else {
        this.$set(this.params, 'teamName', '')
      }
      this.getData(this.params);
    },
    //表格 - 分页查询
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      this.params.pageNumber = e.current;
      this.pagination.current = e.current;
      this.params.pageSize = e.pageSize;
      this.pagination.pageSize = e.pageSize;
      this.getData(Object.assign({}, this.params, obj));
    },
    handlSelect(obj) {
      const { val, keys } = obj;
      if (keys === "officeId") {
        this.doQueryTeams({ officeId: val });
        this.projectList.forEach((item) => {
          if (val == item.id) {
            this.$refs["formindex"].setFieldsValue(
              item.officeId,
              {},
              "officeId"
            );
          } 
        });
      }
    },
    exportExcel() {
      let obj = Object.assign(this.params, {
        exportType: 2 //"导出类型 1考勤统计 2考勤明细"
      })
      request(EXPORT_ATTE_STATISTICS, "post", obj, {
          responseType:"blob",
          headers:{ 'Content-Type': 'application/json; application/octet-stream'}
      }).then(res => {
          const content = res.data;
          const reader = new FileReader()
          var blob = new Blob([content], {type: "application/vnd.ms-excel"})
          reader.readAsDataURL(blob)
          reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = "工人进出时间表.xlsx";
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    exportExcelStaff() {
      let obj = Object.assign(this.params, {
        exportType: 2 //"导出类型 1考勤统计 2考勤明细"
      })
      this.loadingText = "正在导出，请稍候 ····";
      this.isExporting=true;
      this.cPercent=0;
      let fileName="工人进出时间表.xlsx";
      this.exportFunNomal(EXPORT_ATTE_STATISTICS,obj,fileName)
    },
    // 重置
    close() {
      this.params = {
        pageNumber: 1,
        pageSize: 50,
        isPage: "true",
        month: moment(moment()).format("YYYYMM"),
        officeId: '',
        teamId: '',
        teamName: '',
        officeName: '',
        name: ''
      };
      this.pagination.current = 1;
      this.pagination.pageSize = 50;
      this.selectedRows = []
      this.getData(this.params);
      this.$refs["formindex"].resetFields();
    },
  },
};
</script>

<style lang="less" scoped>
.a_a_footer {
  // margin-top: 10px;
  padding: 20px;
  background: #ffffff;

  .operator {
    margin-right: 8px;

    .ant-btn {
      color: #307dfa;
    }
  }

  .table-title {
    text-align: center;
    font-size: 25px;
  }

  .table-desc {
    display: flex;
    justify-content: space-between;
  }
}
/deep/ .ant-form-item {
  margin-bottom: 0;
}
.in-span {
  color: #fff;
  background: #00CE86;
  border-radius: 2px;
  padding: 0.5px 4px;
  text-align: center;
}
.out-span {
  color: #fff;
  background: #FF5D78;
  border-radius: 2px;
  padding: 0.5px 4px;
  text-align: center;
}
</style>
