<template>
    <div>
      <div class="a_Approval">
        <a-tabs :default-active-key="defaultActiveKey">
          <a-tab-pane key="1" tab="标准化文件">
            <documents></documents>
          </a-tab-pane>
          <a-tab-pane key="2" tab="标准化图册">
            <atlas></atlas>
          </a-tab-pane>
         
        </a-tabs>
      </div>
      <router-view></router-view>
    </div>
  </template>
  
  <script>
  import documents from './documents.vue'
  import atlas from './atlas.vue';
  export default {
    components: { 
        documents,
        atlas,
    },
    data() {
      return {
        defaultActiveKey: "1",
      };
    }
  };
  </script>