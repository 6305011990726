var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "a-button",
        {
          attrs: { icon: "plus", type: "primary" },
          on: { click: _vm.addNewContract },
        },
        [_vm._v("新增")]
      ),
      _c(
        "div",
        { staticClass: "a_a_footer mt-1" },
        [
          _c("standard-table", {
            attrs: {
              columns: _vm.columns,
              dataSource: _vm.dataSource,
              selectedRows: _vm.selectedRows,
              rowKey: "id",
              pagination: _vm.pagination,
              loading: _vm.tableLoading,
              isAlert: false,
              bordered: true,
            },
            on: {
              "update:selectedRows": [
                function ($event) {
                  _vm.selectedRows = $event
                },
                function ($event) {
                  return _vm.updateSelectRows($event)
                },
              ],
              "update:selected-rows": function ($event) {
                _vm.selectedRows = $event
              },
              change: _vm.onPageChange,
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function (ref) {
                  var index = ref.index
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            (_vm.pagination.current - 1) *
                              _vm.pagination.pageSize +
                              parseInt(index) +
                              1
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "action",
                fn: function (ref) {
                  var record = ref.record
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.edit(record)
                            },
                          },
                        },
                        [_vm._v("修改")]
                      ),
                      _c("a-divider", { attrs: { type: "vertical" } }),
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.delRecord(record)
                            },
                          },
                        },
                        [_vm._v("删除")]
                      ),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { visible: _vm.showModal, width: 800, title: "新增合同密钥" },
          on: { ok: _vm.handleOk, cancel: _vm.cancelModal },
        },
        [
          _c(
            "a-form-model",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.formModal,
                "label-col": { span: 7 },
                "wrapper-col": { span: 16 },
                rules: _vm.rules,
              },
            },
            [
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "合同名称", prop: "name" } },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入合同名称" },
                            model: {
                              value: _vm.formModal.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.formModal, "name", $$v)
                              },
                              expression: "formModal.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "乙方", prop: "officeId" } },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                "show-search": "",
                                placeholder: "请选择乙方单位",
                                "option-filter-prop": "children",
                                "filter-option": _vm.filterOption,
                                allowClear: "",
                              },
                              on: { change: _vm.handleChange },
                              model: {
                                value: _vm.formModal.officeId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formModal, "officeId", $$v)
                                },
                                expression: "formModal.officeId",
                              },
                            },
                            _vm._l(_vm.officeList, function (item) {
                              return _c(
                                "a-select-option",
                                {
                                  key: item.name,
                                  attrs: {
                                    value: item.value,
                                    title: item.name,
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "大平台编码", prop: "accessno" } },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "大平台编码" },
                            model: {
                              value: _vm.formModal.accessno,
                              callback: function ($$v) {
                                _vm.$set(_vm.formModal, "accessno", $$v)
                              },
                              expression: "formModal.accessno",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "大平台密钥",
                            prop: "accesssecretkey",
                          },
                        },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "大平台密钥" },
                            model: {
                              value: _vm.formModal.accesssecretkey,
                              callback: function ($$v) {
                                _vm.$set(_vm.formModal, "accesssecretkey", $$v)
                              },
                              expression: "formModal.accesssecretkey",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                _vm._l(_vm.formModal.jwAccountsVoList, function (item, index) {
                  return _c(
                    "a-col",
                    { key: index, attrs: { span: 24 } },
                    [
                      _c(
                        "a-card",
                        {
                          attrs: {
                            title:
                              item.roleKey == 1
                                ? "项目经理"
                                : item.roleKey == 2
                                ? "总包劳务员"
                                : "分包劳务员",
                            bordered: false,
                          },
                        },
                        [
                          _c(
                            "a-row",
                            [
                              _c(
                                "a-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "a-form-model-item",
                                    { attrs: { label: "建委账号" } },
                                    [
                                      _c("a-input", {
                                        attrs: { placeholder: "建委账号" },
                                        model: {
                                          value:
                                            _vm.formModal.jwAccountsVoList[
                                              index
                                            ].account,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formModal.jwAccountsVoList[
                                                index
                                              ],
                                              "account",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression:
                                            "formModal.jwAccountsVoList[index].account",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "a-form-model-item",
                                    { attrs: { label: "建委密码" } },
                                    [
                                      _c("a-input", {
                                        attrs: { placeholder: "建委密码" },
                                        model: {
                                          value:
                                            _vm.formModal.jwAccountsVoList[
                                              index
                                            ].password,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formModal.jwAccountsVoList[
                                                index
                                              ],
                                              "password",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression:
                                            "formModal.jwAccountsVoList[index].password",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "a-space",
                { attrs: { size: "large" } },
                [
                  _c("a-button", { on: { click: _vm.cancelModal } }, [
                    _vm._v(" 关闭 "),
                  ]),
                  _c(
                    "a-button",
                    { attrs: { type: "primary" }, on: { click: _vm.handleOk } },
                    [_vm._v(" 确定 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }