var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a_Approval" },
    [
      _c("div", { staticClass: "plug-Overview" }, [_vm._v("职工伤亡事故报表")]),
      _c("a-divider"),
      _c("standard-table", {
        attrs: {
          columns: _vm.columns,
          dataSource: _vm.tableData,
          rowKey: "id",
          pagination: false,
          loading: _vm.tableLoading,
          isAlert: false,
          bordered: true,
        },
        scopedSlots: _vm._u([
          {
            key: "action",
            fn: function (ref) {
              var index = ref.index
              var record = ref.record
              return _c(
                "a",
                {
                  on: {
                    click: function () {
                      return _vm.handleEdit(record)
                    },
                  },
                },
                [_vm._v(" 编辑 ")]
              )
            },
          },
        ]),
      }),
      _c("Export-loading", {
        attrs: {
          cPercent: _vm.cPercent,
          isExporting: _vm.isExporting,
          loadingText: _vm.loadingText,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }