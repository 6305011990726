var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: "660px", title: _vm.title, footer: null },
      on: { cancel: _vm.close },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "a-form-model",
        { ref: "ruleForm", attrs: { model: _vm.params, rules: _vm.rules } },
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "公司",
                        "label-col": { span: 3 },
                        "wrapper-col": { span: 14 },
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: { disabled: "", placeholder: "" },
                        model: {
                          value: _vm.params.companyName,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "companyName", $$v)
                          },
                          expression: "params.companyName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "项目名称",
                        "label-col": { span: 3 },
                        "wrapper-col": { span: 14 },
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: { disabled: "", placeholder: "" },
                        model: {
                          value: _vm.params.projectName,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "projectName", $$v)
                          },
                          expression: "params.projectName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "附件",
                        "label-col": { span: 3 },
                        "wrapper-col": { span: 14 },
                      },
                    },
                    [
                      _c(
                        "a-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            "list-type": "picture-card",
                            accept: _vm.accept1,
                            "file-list": _vm.filePath1,
                            customRequest: _vm.resourceUpload1,
                            beforeUpload: _vm.beforeUpload1,
                          },
                          on: {
                            change: _vm.handleUploadChange1,
                            preview: _vm.handlePreview,
                          },
                        },
                        [
                          _vm.filePath1.length < 1
                            ? _c(
                                "div",
                                [
                                  _c("a-icon", {
                                    attrs: {
                                      type: _vm.uploading1 ? "loading" : "plus",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-row",
        [
          _c("a-col", { attrs: { span: 12 } }),
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    "label-col": { span: 4 },
                    "wrapper-col": { span: 14 },
                    prop: "year",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex flex-row-reverse" },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", loading: _vm.sbloading },
                          on: { click: _vm.handleSubmit },
                        },
                        [_vm._v("保存")]
                      ),
                      _c(
                        "a-button",
                        { staticClass: "mr-2", on: { click: _vm.close } },
                        [_vm._v("返回")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }