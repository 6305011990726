var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-card",
        { attrs: { title: "危大工程情况表" } },
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "状态：",
                        labelCol: { span: 8 },
                        wrapperCol: { span: 12 },
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.statusName))])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("form-index", {
            ref: "formindex",
            attrs: { formData: _vm.formData, bottomSubmit: true },
            on: { handleSubmit: _vm.submit },
            scopedSlots: _vm._u([
              {
                key: "bottomSubmit",
                fn: function () {
                  return [
                    _vm.params.status != 3 && _vm.canOperate
                      ? _c(
                          "a-button",
                          {
                            staticClass: "ml-3",
                            attrs: { type: "primary", "html-type": "submit" },
                          },
                          [_vm._v(" 保存 ")]
                        )
                      : _vm._e(),
                    _c("a-button", { on: { click: _vm.close } }, [
                      _vm._v(" 关闭 "),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }