var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-card",
        {
          staticClass: "constructiontTable_index",
          attrs: { title: "发布版本", bordered: false },
        },
        [
          _c(
            "a-form",
            {
              attrs: { form: _vm.docFrom, layout: _vm.formLayout },
              on: { submit: _vm.handleSubmit },
            },
            [
              _c(
                "a-form-item",
                { attrs: { label: "所属系统", colon: false } },
                [
                  _c(
                    "a-checkbox-group",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "vsystem",
                            {
                              rules: [
                                { required: true, message: "请选择所属系统" },
                              ],
                            },
                          ],
                          expression:
                            "['vsystem', { rules: [{ required: true, message: '请选择所属系统' }] }]",
                        },
                      ],
                    },
                    _vm._l(
                      Object.entries(_vm.SYSTEM_TYPE_NAME_MAP),
                      function (ref) {
                        var value = ref[0]
                        var text = ref[1]
                        return _c(
                          "a-checkbox",
                          { key: value, attrs: { value: value } },
                          [_vm._v(" " + _vm._s(text) + " ")]
                        )
                      }
                    ),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "通知类型", colon: false } },
                [
                  _c(
                    "a-radio-group",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: ["type"],
                          expression: "['type']",
                        },
                      ],
                    },
                    _vm._l(
                      Object.entries(_vm.VERSION_TYPE_ZHCN_MAP),
                      function (ref) {
                        var value = ref[0]
                        var text = ref[1]
                        return _c(
                          "a-radio",
                          { key: value, attrs: { value: Number(value) } },
                          [_vm._v(" " + _vm._s(text) + " ")]
                        )
                      }
                    ),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "有效截止时间",
                    labelCol: { span: 24 },
                    "wrapper-col": { span: 5 },
                  },
                },
                [
                  _c("a-date-picker", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "endTime",
                          {
                            rules: [
                              { required: true, message: "请选择有效截止时间" },
                            ],
                          },
                        ],
                        expression:
                          "['endTime', { rules: [{ required: true, message: '请选择有效截止时间' }] }]",
                      },
                    ],
                    staticClass: "w-100",
                    attrs: {
                      placeholder: "有效截止时间",
                      "show-time": "",
                      format: "YYYY-MM-DD HH:mm:ss",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "版本号",
                    labelCol: { span: 24 },
                    "wrapper-col": { span: 16 },
                  },
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "vcode",
                          {
                            rules: [
                              {
                                required: false,
                                message: "请填写版本号",
                              },
                            ],
                          },
                        ],
                        expression:
                          "['vcode',\n        { rules: [{\n            required: false,\n            message: '请填写版本号',\n        }] },\n        ]",
                      },
                    ],
                    attrs: { placeholder: "请填写版本号" },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "发布时间",
                    labelCol: { span: 24 },
                    "wrapper-col": { span: 5 },
                  },
                },
                [
                  _c("a-date-picker", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "vtime",
                          { rules: [{ required: false, message: "发布时间" }] },
                        ],
                        expression:
                          "['vtime', { rules: [{ required: false,message: '发布时间' }] }]",
                      },
                    ],
                    staticClass: "w-100",
                    attrs: {
                      placeholder: "不填则当前日期",
                      "show-time": "",
                      format: "YYYY-MM-DD HH:mm:ss",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "更新内容",
                    labelCol: { span: 24 },
                    "wrapper-col": { span: 16 },
                  },
                },
                [
                  _c(
                    "keep-alive",
                    [
                      _c("tinymce", {
                        ref: "tinymceEditor",
                        attrs: { value: _vm.remarks, plugins: _vm.plugins },
                        on: { input: _vm.contentInput },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { "wrapper-col": { span: 6 } } },
                [
                  _c(
                    "a-button",
                    { attrs: { type: "primary", "html-type": "submit" } },
                    [_vm._v(" " + _vm._s(_vm.id ? "修改" : "提交") + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }