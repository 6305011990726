<template>
  <div class="a_Approval">
    <div class="plug-Overview">{{ `${bookType == 1 ? '市' : bookType == 2 ? '省' : '国家'}级安全创优获奖台账` }}</div>
    <a-divider/>
    <a-form-model class="clearfix">
      <a-col :span="5" v-if="user.nowRoleKey.includes('OFFICE_AQMGLY')">
        <a-form-model-item label="工程管理部" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
          <a-select
            v-model="params.gcglbId"
            allowClear
            @change="handlSelect($event)"
            placeholder=""
          >
            <a-select-option
              v-for="item in gcglbList"
              :key="item.value"
              :value="item.value"
              :title="item.name"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>

      <a-col :span="5">
        <a-form-item label="项目名称：" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
          <a-select
            :allowClear="true"
            show-search
            @search="handleSearch"
            :filter-option="filterOption"
            v-model="params.projectName"
          >
            <a-select-option v-for="items in projectList" :key="items.projectId + ''" :value="items.projectName" :title="items.projectName">{{ items.projectName }}</a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :span="5">
        <a-form-model-item label="工程类别" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
          <a-select
            v-model="params.ptype"
            allowClear
            placeholder=""
            @change="queryDicCode"
          >
            <a-select-option
              v-for="item in pTypeOption"
              :value="item.value"
              :key="item.value"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>

      <a-col :span="5">
        <a-form-model-item label="获奖年度" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
          <a-date-picker
            v-model="params.year"
            mode="year"
            @panelChange="changeYear"
            :format="`YYYY`"
          />
        </a-form-model-item>
      </a-col>

      <a-col :span="5" v-if="bookType != 3">
        <a-form-model-item label="奖项类型" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
          <a-select
            v-model="params.certificateCode"
            allowClear
            placeholder=""
          >
            <a-select-option
              v-for="item in codeOption"
              :value="item.certificateCode"
              :key="item.certificateCode"
              :title="item.certificateName"
            >
              {{ item.certificateName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>

      <a-col :span="4">
        <a-space class="btnBox">
          <a-button type="primary" @click="handleQuery">
            查询
          </a-button>
          <a-button @click="resetQuery"> 重置 </a-button>
        </a-space>
      </a-col>
    </a-form-model>

    <!-- 表格按钮组 -->
    <a-space class="flex j-start">
      <!-- <a-button type="primary" @click="handleUpload"> 导入 </a-button> -->
      <a-button type="primary" @click="doDowload">
        导出
      </a-button>
    </a-space>

    <a-table
      class="mt-3"
      :key="bookType"
      :columns="columns"
      :data-source="tableData"
      bordered
      size="default"
      :pagination="{
        defaultCurrent: params.pageNumber,
        total: total,
        pageSize: params.pageSize,
        showTotal: (total, range) => `共 ${total} 条`,
      }"
      @change="handleChangePage"
    >
      <!-- 奖项名称 -->
      <template slot='certificateName' slot-scope='text'>
        <a-tooltip placement='topLeft'>
          <template slot='title'>
            {{ text }}
          </template>
          <div class='overtext-1'>{{ text }}</div>
        </a-tooltip>
      </template>
      <a slot="action" slot-scope="text, row" @click="handleEdit(row)" v-if="user.loginType == 1 && !user.nowRoleKey.includes('OFFICE_ZGB_USER')">
        编辑
      </a>

      <!-- 授奖单位 -->
      <!-- <template slot='awardUnit' slot-scope='text'>
        <a-tooltip placement='topLeft'>
          <template slot='title'>
            <span v-if="text == 0">广州市建筑业联合会</span>
            <span v-else-if="text == 1">广州市市政公路协会</span>
            <span v-else-if="text == 2">广东省建筑安全协会</span>
          </template>
          <div class='overtext-1'>
            <span v-if="text == 0">广州市建筑业联合会</span>
            <span v-else-if="text == 1">广州市市政公路协会</span>
            <span v-else-if="text == 2">广东省建筑安全协会</span>
          </div>
        </a-tooltip>
      </template> -->

      <template slot="cert" slot-scope="text, row">
        <div class="flex j-center">
         <div
          v-for="(item, index) in row.certificatePathsList"
          :key="index"
         >

           <img
           alt=""
           style="width: 32px"
            v-if="item.type == 'img'"
           :src="item.url"
           @click="handlePreview(item)"
           />
           
           <a-icon type="file-pdf" :style="{ fontSize: '32px' }" v-if="item.type == 'pdf'" @click="handlePreview(item)" />
          </div>
        </div>
      </template>

      <template slot="projectFile" slot-scope="text, row">
        <div class="flex j-center">
          <a-icon type="file-pdf" :style="{ fontSize: '32px' }" v-if="row.projectFile" @click="handleLXPreview(row.projectFile)" />
           <span v-else-if="row.projectStatus == '/'">/</span>
           <span v-else></span>
        </div>
      </template>

      <template slot="certProject" slot-scope="text, row">
        <div class="flex j-center">
         <div
          v-for="(item, index) in row.certificatePathsList"
          :key="index"
         >

           <img
           alt=""
           style="width: 32px"
            v-if="item.type == 'img'"
           :src="item.url"
           @click="handlePreview(item)"
           />
           
           <a-icon type="file-pdf" :style="{ fontSize: '32px' }" v-if="item.type == 'pdf'" @click="handlePreview(item)" />
          </div>
        </div>
      </template>
      <template slot="certPrivate" slot-scope="text, row">
        <div class="flex j-center">
         <div
          v-for="(item, index) in row.certificatePathsListPrivate"
          :key="index"
         >

           <img
           alt=""
           style="width: 32px"
            v-if="item.type == 'img'"
           :src="item.url"
           @click="handlePreview(item)"
           />
           
           <a-icon type="file-pdf" :style="{ fontSize: '32px' }" v-if="item.type == 'pdf'" @click="handlePreview(item)" />
          </div>
        </div>
      </template>
      <template slot="projectDate" slot-scope="text, row">
        <div class="flex j-center">
         <span v-if="!row.cyyq || row.cyyq == 1">/</span>
         <span v-else>{{ renderDate(text) }}</span>
        </div>
      </template>
    </a-table>

    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="" style="width: 100%" :src="previewImage" />
    </a-modal>
    <a-modal :visible="uploadShow" :footer="null" @cancel="handleCancelUpload">
      <a-form-model
      layout="horizontal"
        :form="uploadForm"
        :label-col="labelCol" :wrapper-col="wrapperCol"
        ref="uploadForm"
        :model="uploadForm"
        :rules="rules"
      >
        <a-form-model-item label="奖项类别">
          <a-select
            v-model="uploadForm.certificateCode"
            allowClear
            placeholder=""
           
          >
            <a-select-option
              v-for="item in codeOptionTemp"
              :value="item.certificateCode"
              :key="item.certificateCode"
            >
              {{ item.certificateName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="上传文件" name="file">
          <a-upload
            :file-list="fileList"
            :before-upload="beforeUpload"
            @change="handleUploadChange"
          >
            <a-button>
              <a-icon type="upload" /> 选择文件
            </a-button>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button type="primary" @click="handleUploadSubmit">
            提交
          </a-button>
        </a-form-model-item>
      </a-form-model>

    </a-modal>

    <Export-loading
      :cPercent="cPercent"
      :isExporting="isExporting"
      :loadingText="loadingText"
    ></Export-loading>
  </div>
</template>

<script>
import {
  getExcellentList,
  getExcellentLedgerList,
  getExcellentCerDic,
  ExcellentExportLedgerUrl,
  excellentLedgerImport
} from "@/services/labourProjectExcellent";
import moment from "moment";
import ExportLoading from "@/components/ExportLoading/ExportLoading"; // progress组件
import { exportProgress } from "@/pages/aqjyzl/export"; // 导出方法
import { mapGetters } from "vuex";
import { request } from "@/utils/request";
import { QUERY_OFFICE_BY_USER } from "@/services/api";
import { aqxjprojectsgetlist } from '@/services/projectManagement';
import { useRender } from '@/hooks/useRender.js'

export default {
  name: "LabourProjectExcellentBook",
  mixins: [exportProgress], //导出方法注入
  components: {
    ExportLoading,
  },
  data() {
    return {
      params: {
        certificateCode: "",
        year: "",
        ptype: null,
        pageNumber: 1,
        pageSize: 10,
        officeId: '',
        gcglbId: ''
      },
      projectList: [],
      yearFormat: "YYYY",
      gcglbList: [],
      pTypeOption: [
        {
          value: 1,
          name: "房建",
        },
        {
          value: 2,
          name: "市政",
        },
      ],
      codeOption: [],
      total: 0,
      columns: [
        {
          title: "序号",
          align: "center",
          dataIndex: "index",
          width: 55,
        },
        // {
        //   title: "工程管理部",
        //   align: "center",
        //   dataIndex: "gcglbName",
        //   width: 80
        // },
        {
          title: "项目名称",
          dataIndex: "projectName",
          width: 220,
          ellipsis: true,
        },
        {
          title: "工程类别",
          align: "center",
          dataIndex: "ptypeName",
          width: 65
        },
        {
          title: "项目经理",
          dataIndex: "managerName",
          align: "center",
          width: 80
        },
        {
          title: "奖项名称",
          dataIndex: "certificateName",
          align: "center",
          scopedSlots: { customRender: "certificateName" },
          ellipsis: true,
          width: 260
        },
        {
          title: "证书编号",
          dataIndex: "certificateNum",
          align: "center",
          width: 180,
        },
        {
          title: "获奖时间",
          dataIndex: "certificateDate",
          align: "center",
          width: 80
        },
        {
          title: "授奖单位",
          dataIndex: "awardUnit",
          scopedSlots: { customRender: "awardUnit" },
          align: "center",
          ellipsis: true,
          width: 150
        },
        {
          title: "证书",
          dataIndex: "certificatePaths",
          width: 100,
          scopedSlots: { customRender: "cert" },
          align: "center",
        },
        {
          title: "操作",
          dataIndex: "operation",
          align: "center",
          // fixed: "right",
          width: 65,
          scopedSlots: { customRender: "action" },
        },
      ],
      tableData: [],
      bookType: "",

      previewVisible: false,
      previewImage: "",

      loadingText: "数据加载中...",
      cPercent: 0,
      isExporting: false,

      uploadShow: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      codeOptionTemp: [],
      uploadForm: {
        cyLevele: null,
        file: null,
        certificateCode: null,
      },
      rules: {},
      fileList: [],
    };
  },
  created() {
    this.getProject()
    this.getgcglbList(QUERY_OFFICE_BY_USER);
    let bookType = this.$route.path.slice(-1);
    this.bookType = bookType;
    // 市级 省级  国家级
    let t1 = JSON.parse(JSON.stringify(this.columns));
    let t2 = JSON.parse(JSON.stringify(this.columns));
    
    if (bookType == 1) {
      // 市级 证书分组
      console.log("市级", t1);
      let p = {
          title: "证书",
          // dataIndex: "certificatePaths",
          // width: 280,
          // scopedSlots: { customRender: "cert" },
          align: "center",
          children: [
            {
              title: "项目证书",
              dataIndex: "certificatePaths",
              align: "center",
              width: 75,
              scopedSlots: { customRender: "certProject" },
            },
            {
              title: "个人证书",
              dataIndex: "certificatePathsListPrivate",
              align: "center",
              width: 75,
              scopedSlots: { customRender: "certPrivate" },
            },
          ],
        }
      t1.splice(8, 1, p);
      this.columns = t1;
    } else if (bookType == 2) {
      // 省级  证书分组
      let p = {
        title: "证书",
        // dataIndex: "certificatePaths",
        // width: 280,
        // scopedSlots: { customRender: "cert" },
        align: "center",
        children: [
          {
            title: "项目证书",
            dataIndex: "certificatePaths",
            align: "center",
            width: 75,
            scopedSlots: { customRender: "certProject" },
          },
          {
            title: "个人证书",
            dataIndex: "certificatePathsListPrivate",
            align: "center",
            width: 75,
            scopedSlots: { customRender: "certPrivate" },
          },
        ],
      }
      // let i = t2.findIndex((item) => item.title == "证书编号");
      // 去掉证书编号
      // t2.splice(i, 1);
      t2.splice(8, 1, p);

      // let p2 = {
      //     title: "立项情况",
      //     // dataIndex: "projectStatus",
      //     // width: 100,
      //     align: "center",
      //     children: [
      //       {
      //         title: "立项状态",
      //         dataIndex: "projectStatus",
      //         align: "center",
      //         width: 100,
      //       },
      //       {
      //         title: "立项表",
      //         dataIndex: "projectFile",
      //         align: "center",
      //         width: 100,
      //         scopedSlots: { customRender: "projectFile" },
      //       },
      //     ],
      //   }
      // t2.splice(5, 0, p2);
      
      let p1 = {
          title: "立项完成时间",
          dataIndex: "projectDate",
          width: 100,
          align: "center",
          scopedSlots: { customRender: "projectDate" },
        }
      t2.splice(7, 0, p1);


      this.columns = t2;
    } else if (bookType == 3) {
      // let p2 = {
      //     title: "立项情况",
      //     // dataIndex: "projectStatus",
      //     // width: 100,
      //     align: "center",
      //     children: [
      //       {
      //         title: "立项状态",
      //         dataIndex: "projectStatus",
      //         align: "center",
      //         width: 100,
      //       },
      //       {
      //         title: "立项表",
      //         dataIndex: "projectFile",
      //         align: "center",
      //         width: 100,
      //         scopedSlots: { customRender: "projectFile" },
      //       },
      //     ],
      //   }
      // t2.splice(5, 0, p2);

      let p1 = {
          title: "立项完成时间",
          dataIndex: "projectDate",
          width: 120,
          align: "center",
          scopedSlots: { customRender: "projectDate" },
        }
      t2.splice(8, 0, p1);
      this.columns = t2;
    }
  },
  activated() {
    let params = new FormData();
    params.append("level", this.bookType);
    // if have ptype append ptype
    if (this.params.ptype) params.append("pType", this.params.ptype);
    getExcellentCerDic(params).then((res) => {
      if (res.data.code === 0) {
        if (res.data.data) {
          let t = res.data.data.filter((item) => item.isOther == 0);
          // 合并其他类型证书 isOther == 1
          if (this.bookType ==1) {
            t.push({
              certificateCode: "999",
              certificateName: "其他",
            });
            
          }
          this.codeOption = t;
          this.codeOptionTemp = JSON.parse(JSON.stringify(t));
          // this.params.certificateCode = res.data.data[0].certificateCode;
        }
        this.handleQuery();
      }
    });
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  methods: {
    ...useRender,
    handlSelect(e) {
      let item = this.gcglbList.filter((item) => item.value == e);
      console.log(item)
      if (item.length > 0) {
        this.params.gcglbId = item[0].value
        this.getProject(item[0].value)
      } else {
        this.params.gcglbId = ''
        this.getProject()
      }
    },
    getProject(gcglbId, projectName) {
      this.projectList = []
      let params = {"needPage": false, gcglbId, projectName}
      aqxjprojectsgetlist(params).then(res => {
        if (res.status == 200) {
          this.$nextTick(() => {
            this.projectList = res.data.data.list
          })
        }
      })
    },
    handleSearch(value) {
      this.getProject(this.params.gcglbId, value)
    },
    filterOption(input, option){
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    handleQuery(e) {
      let params = JSON.parse(JSON.stringify(this.params));
      params.year = params.year && moment(params.year).format("YYYY");
      params.cylevel = this.bookType;
      params.officeId = this.user.officeId;
      if (this.bookType == 3) {
        params.ptype = null;
      }

      getExcellentLedgerList(params).then((res) => {
        let d = res.data;
        if (d.code === 0) {
          let j = 0//根据projectName保存有多少项相同
          let k = 0//根据projectName保存相同项的起始下标，因为其他项有可能不同projectName但还是相同的
          this.columns = this.columns.map(item => {
            if (item.dataIndex === "projectName") {
              let tempColIndex = 0
              item.customRender = (value, row, index) => {
                const obj = {
                  children: (
                    <a-tooltip placement="topLeft" title={value}>
                      <div class="overtext-1">
                        {value}
                      </div>
                    </a-tooltip>
                  ),
                  attrs: {},
                };
                j = d.data.list.filter(obj => obj.projectName === value)
                k = d.data.list.findIndex(obj => obj.projectName === value)
                let l = k//临时保存该相同项的起始下标
                if (j.length > 1) {
                  //若相同项大于1则合并第一行
                  if (index === l) {
                    obj.attrs.rowSpan = j.length;
                    tempColIndex = l + 1//临时保存需要设为0的下标
                  }
                  //其他相同的要设为0
                  if (index === tempColIndex) {
                    obj.attrs.rowSpan = 0;
                    tempColIndex = tempColIndex + 1
                  }
                }
                return obj
              }
            }
            if (item.dataIndex === "ptypeName") {
              let tempColIndex = 0
              item.customRender = (value, row, index) => {
                console.log(value, row, index)
                const obj = {
                  children: value,
                  attrs: {},
                };
                let l = k//临时保存该相同项的起始下标
                if (j.length > 1) {
                  //若相同项大于1则合并第一行
                  if (index === l) {
                    obj.attrs.rowSpan = j.length;
                    tempColIndex = l + 1//临时保存需要设为0的下标
                  }
                  //其他相同的要设为0
                  if (index === tempColIndex) {
                    obj.attrs.rowSpan = 0;
                    tempColIndex = tempColIndex + 1
                  }
                }
                return obj
              }
            }
            if (item.dataIndex === "managerName") {
              let tempColIndex = 0
              item.customRender = (value, row, index) => {
                console.log(value, row, index)
                const obj = {
                  children: value,
                  attrs: {},
                };
                let l = k//临时保存该相同项的起始下标
                if (j.length > 1) {
                  //若相同项大于1则合并第一行
                  if (index === l) {
                    obj.attrs.rowSpan = j.length;
                    tempColIndex = l + 1//临时保存需要设为0的下标
                  }
                  //其他相同的要设为0
                  if (index === tempColIndex) {
                    obj.attrs.rowSpan = 0;
                    tempColIndex = tempColIndex + 1
                  }
                }
                return obj
              }
            }
            if (item.dataIndex === "projectStatus") {
              let tempColIndex = 0
              item.customRender = (value, row, index) => {
                console.log(value, row, index)
                const obj = {
                  children: value,
                  attrs: {},
                };
                let l = k//临时保存该相同项的起始下标
                if (j.length > 1) {
                  //若相同项大于1则合并第一行
                  if (index === l) {
                    obj.attrs.rowSpan = j.length;
                    tempColIndex = l + 1//临时保存需要设为0的下标
                  }
                  //其他相同的要设为0
                  if (index === tempColIndex) {
                    obj.attrs.rowSpan = 0;
                    tempColIndex = tempColIndex + 1
                  }
                }
                return obj
              }
            }
            return item
          })
          this.tableData = d.data.list.map((item, index) => {
            item.index = index + 1;
            // ptype 1 房建 2 市政 3 装饰装修
            let ptype = this.pTypeOption.find((i) => i.value == item.ptype);
            item.ptypeName = ptype ? ptype.name : "";

            // certPath
            item.certificatePathsList = item.certificatePaths
              ? item.certificatePaths.split(",")
              : [];

            // 证书是 pdf 时的处理
            item.certificatePathsList = item.certificatePathsList.map((i) => {
                let type = "img";
                if (i.indexOf(".pdf") > -1) {
                  type = "pdf";
                } else {
                  type = "img";
                }
                return {
                  url: i,
                  type: type,
                };
              }
            );

            // 个人证书
            item.certificatePathsListPrivate = item.certificatePathsPrivate
              ? item.certificatePathsPrivate.split(",")
              : [];

            // 证书是 pdf 时的处理
            item.certificatePathsListPrivate = item.certificatePathsListPrivate.map((i) => {
                let type = "img";
                if (i.indexOf(".pdf") > -1) {
                  type = "pdf";
                } else {
                  type = "img";
                }
                return {
                  url: i,
                  type: type,
                };
              }
            );

            return item;
          });

          this.total = d.data.total;
        } else {
          this.$message.error(d.msg)
        }
      });
    },
    handleEdit(row) {
      this.$router.push({
        path: `/project_detail_${this.bookType}`,
        query: {
          id: row.id,
          pageType: 2,
          cylevel: this.bookType,
          isBook: 1
        },
      });
    },
    handleChangePage(pagination) {
      this.params.pageNumber = pagination.current;
      this.handleQuery();
    },
    changeYear(e) {
      this.params.year = e;
    },
    queryDicCode() {
      // formdata
      let params = new FormData();
      params.append("level", this.bookType);
      if (this.bookType != 3 && this.params.ptype) {
        params.append("pType", this.params.ptype);
      }

      getExcellentCerDic(params).then((res) => {
        if (res.data.code === 0) {
          this.codeOption = res.data.data;
          if (res.data.data) {
            // this.params.certificateCode = res.data.data[0].certificateCode;
          }
        }
      });
    },
    handlePreview(item) {
      if (item.type == "pdf") {
        // 打开新页面
        window.open(item.url);
      } else {
        // 打开预览
        this.previewImage = item.url;
        this.previewVisible = true;

      }
    },
    handleLXPreview(item) {
      // 打开新页面
      window.open(item);
    },
    handleCancel() {
      this.previewVisible = false;
    },
    // 获取工程管理部数据
    getgcglbList(path) {
      request(path, "get").then((res) => {
        let data = res.data.data.filter(item => item.id != "3da0f5533f87425c9a9e8a11a7923300")
        const arr = [];
        data.forEach((item) => {
          arr.push({
            name: item.master,
            value: item.id,
          });
        });
        this.gcglbList = arr;
      });
    },
    async resetQuery() {
      // 重置参数

      this.params = {
        certificateCode: "",
        year: "",
        ptype: 1,
        gcglbId: "",
        pageNumber: 1,
        pageSize: 10,
      };
      await this.queryDicCode();
      this.handleQuery();
    },
    doDowload() {
      let params = JSON.parse(JSON.stringify(this.params));
      params.year = params.year && moment(params.year).format("YYYY");
      params.cylevel = this.bookType;

      if (this.bookType == 3) {
        params.ptype = null;
      }

      let obj = params;
      this.loadingText = "正在导出EXCEL文件，请稍候 ····";
      this.isExporting = true;
      this.cPercent = 0;
      let title;
      if (this.bookType == 1) title = "市级安全创优获奖台账";
      else if (this.bookType == 2) title = "省级安全创优获奖台账";
      else if (this.bookType == 3) title = "国家级安全创优获奖台账";
      let fileName = `${title}.xlsx`;
      this.exportFunNomal2(
        ExcellentExportLedgerUrl,
        obj,
        fileName,
        "application/vnd.ms-excel"
      );
    },
    handleCancelUpload() {
      this.uploadShow = false;
    
    },
    handleUpload() {
      this.uploadShow = true;
      this.uploadForm = {
        certificateCode: "",
        file: null,
      };
      this.fileList = [];
    },
    beforeUpload(file) {
      this.uploadForm.file = file
      return false
    },
    handleUploadChange(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      this.fileList = fileList;
    },
    handleUploadSubmit() {
      this.$refs.uploadForm.validate((valid) => {
        if (valid) {
          // formdata 
          let params = new FormData();
          params.append("file", this.uploadForm.file);
          params.append("cyLevel", this.bookType);
          params.append("certificateCode", this.uploadForm.certificateCode);

          excellentLedgerImport(params).then((res) => {
            if (res.data.code === 0) {
              this.$message.success("导入成功");
              this.uploadShow = false;
              this.handleQuery();
            } else {
              this.$message.error(res.data.msg);
            }
          });

        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "@/theme/common.less";
/deep/.ant-table-thead > tr > th{
  text-align: center;
}
</style>
