<template>
  <div class="container">
    <div class="a_m_header">
      <template v-if="isDirect">
        <a-tabs default-active-key="1" @change="callback">
          <a-tab-pane key="1" tab="当天补报">
            <daily-replenish :isDaily="true"></daily-replenish>
          </a-tab-pane>
          <a-tab-pane key="2" tab="批量补报（非当天）" force-render>
            <daily-replenish :isDaily="false"></daily-replenish>
          </a-tab-pane>
        </a-tabs>
      </template>
      <template v-else>
        <div>33333</div>
      </template>
    </div>
  </div>
</template>

<script>
import DailyReplenish from "./dailyReplenish.vue";
export default {
  data() {
    return {
      isDirect: true,
    };
  },
  components: {
    DailyReplenish,
  },
  methods: {
    callback(key) {
      console.log(key);
    },
  },
};
</script>

<style lang="less" scoped>
.a_Method {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
}
</style>
