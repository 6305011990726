<template>
  <a-modal
      :title="`${personList.teamName}${personList.date}【批次号：${personList.enterCode}】`"
      :visible="visible"
      @ok="modelOk"
      @cancel="modelCancel"
      width="860px"
      :footer="null"
    >
    <a-card>
      <a-spin :spinning="spinning" :tip="loadingText">
      <div :class="advanced ? 'search' : null">
        <a-form layout="horizontal">
          <div :class="advanced ? null: 'fold'">
            <a-row >
            <a-col :md="12" :sm="24" >
              <a-form-item
                label="姓名"
                :labelCol="{span: 5}"
                :wrapperCol="{span: 18, offset: 1}"
              >
                <a-input placeholder="请输入" v-model="queryParams.userName"/>
              </a-form-item>
            </a-col>
            <a-col :md="12" :sm="24" >
              <a-form-item
                label="身份证号"
                :labelCol="{span: 5}"
                :wrapperCol="{span: 18, offset: 1}"
              >
                <a-input placeholder="请输入" v-model="queryParams.idCard"/>
              </a-form-item>
            </a-col>
          </a-row>
          </div>
          <span style="float: right; margin-top: 3px;">
            <a-button type="primary" @click="doQuery()">查询</a-button>
            <a-button style="margin-left: 8px" @click="doReset()">重置</a-button>
          </span>
        </a-form>
      </div>
      <div style="margin-top:-30px">
        <standard-table
          :columns="columns"
          :dataSource="dataSource"
          :selectedRows.sync="selectedRows"
          :pagination="pagination"
          :loading="tableLoading"
          @clear="onClear"
          @change="onPageChange"
          :isAlert="false"
        >
          <div slot="do-status" slot-scope="{text}">
            <a-icon v-if="text==1" style="color:green" type="check" />
            <a-icon v-else style="color:red" type="close" />
          </div>
          <!-- <div slot="action" slot-scope="{text, record}">
            <a @click="doPersonList(`${record.key}`)">人员列表</a>
            <a-divider type="vertical" />
            <a @click="doDowload(`${record.key}`,`${record.userName}`)">打包下载</a>
          </div>
          <template slot="statusTitle">
            <a-icon @click.native="onStatusTitleClick" type="info-circle" />
          </template> -->
        </standard-table>
      </div>
      </a-spin>
    </a-card>
  </a-modal>
</template>

<script>
import StandardTable from '@/components/table/StandardTable'
import {
  STAFF_QUERYPAGE, 
  } from '@/services/api'
import {request} from '@/utils/request'
import { useRender } from '@/hooks/useRender.js'

const columns = [
  {
    title: '姓名',
    dataIndex: 'userName',
    align: 'center',
    scopedSlots: { customRender: 'userName' },
  },
  {
    title: '身份证号',
    dataIndex: 'idCard',
    align: 'center',
    scopedSlots: { customRender: 'idCard' }
  },
  {
    title: '资料生成日期',
    dataIndex: 'meansDate',
    align: 'center',
    scopedSlots: { customRender: 'meansDate' },
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: '在场状态',
    dataIndex: 'enterStatus',
    align: 'center',
    scopedSlots: { customRender: 'do-status' },
  },
  // {
  //   title: '操作',
  //   align: 'center',
  //   scopedSlots: { customRender: 'action' }
  // }
]

export default {
  name: 'personList',
  components: {StandardTable},
  data () {
    return {
      dataSource:[],
      columns,
      advanced: false,
      spinning:false,
      loadingText:"数据加载中...",
      selectedRows: [],
      tableLoading: false,
      queryParams:{
        idCard:null,
        userName:null,
        pageNumber:1,
      },
      pagination:{
        current:1,
        pageSize:10,
        total:0,
        showLessItems:true,
        showQuickJumper:true
      },
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    personList:{
      type: Object,
      require:true
    },
  },
  watch: {
    visible(val){
      if(val){
        this.doQuery();
      }
    }
  },
  created() {
  },
  methods: {
    modelOk(){
      const _this = this
    },
    modelCancel(){
      this.$emit('perosonListClose');
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },
    /**
     * 获取所有人员的签名记录信息
     */
    doQuery(){
      this.tableLoading = true;
      let mdl = Object.assign(this.queryParams, this.personList)
      request(STAFF_QUERYPAGE,"post",mdl).then(res => {
        if(res.data.code==0){
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
          this.dataSource.forEach(obj=>{
            obj.key = obj.idCard;
          })
        }
        this.tableLoading = false;
      })
    },
    doReset(){
      this.queryParams={};
      this.doQuery();
    },
    //分页查询
    onPageChange(e){
      this.queryParams.pageNumber = e.current;
      this.pagination.current=e.current;
      this.doQuery();
    },
  }
}
</script>

<style lang="less" scoped>
  .search{
    margin-bottom: 54px;
  }
  .fold{
    width: calc(100% - 216px);
    display: inline-block
  }
  .operator{
    margin-bottom: 18px;
  }
  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
</style>
