var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        width: "1400px",
        title: _vm.title,
        "dialog-style": { top: "0px" },
        footer: null,
      },
      on: { cancel: _vm.close },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "a-form-model",
        { ref: "ruleForm", attrs: { model: _vm.params, rules: _vm.rules } },
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "公司",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: { disabled: "", placeholder: "" },
                        model: {
                          value: _vm.params.companyName,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "companyName", $$v)
                          },
                          expression: "params.companyName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "分公司",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: { disabled: "", placeholder: "" },
                        model: {
                          value: _vm.params.branchOfficeName,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "branchOfficeName", $$v)
                          },
                          expression: "params.branchOfficeName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "施工单位",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: { disabled: "", placeholder: "" },
                        model: {
                          value: _vm.params.zbOfficeName,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "zbOfficeName", $$v)
                          },
                          expression: "params.zbOfficeName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "项目名称",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: { disabled: "", placeholder: "" },
                        model: {
                          value: _vm.params.projectName,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "projectName", $$v)
                          },
                          expression: "params.projectName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "月份",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                      },
                    },
                    [
                      _c("a-month-picker", {
                        attrs: {
                          disabled: "",
                          placeholder: "",
                          valueFormat: "YYYY-MM",
                        },
                        model: {
                          value: _vm.params.ym,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "ym", $$v)
                          },
                          expression: "params.ym",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "提交时间",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                      },
                    },
                    [
                      _c("a-date-picker", {
                        attrs: { disabled: "", valueFormat: "YYYY-MM-DD" },
                        model: {
                          value: _vm.params.tjtime,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "tjtime", $$v)
                          },
                          expression: "params.tjtime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "提交人",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: { disabled: "", placeholder: "" },
                        model: {
                          value: _vm.params.tjname,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "tjname", $$v)
                          },
                          expression: "params.tjname",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "a-row",
            { attrs: { type: "flex", justify: "space-between" } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-tabs",
                    {
                      model: {
                        value: _vm.tabIndex,
                        callback: function ($$v) {
                          _vm.tabIndex = $$v
                        },
                        expression: "tabIndex",
                      },
                    },
                    [
                      _c("a-tab-pane", {
                        key: "1",
                        attrs: { tab: "危险性较大的分部分项工程确认表" },
                      }),
                      _c("a-tab-pane", {
                        key: "2",
                        attrs: {
                          tab: "超过一定过规模的危险性较大的分部分项工程确认表",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "a-button",
                    {
                      staticClass: "mr-4",
                      attrs: { type: "primary", loading: _vm.sbloading },
                      on: { click: _vm.handleSubmit },
                    },
                    [_vm._v("保存")]
                  ),
                  _c("a-button", { on: { click: _vm.close } }, [
                    _vm._v("返回"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("a-table", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tabIndex == 1,
                expression: "tabIndex == 1",
              },
            ],
            attrs: {
              columns: _vm.columns1,
              pagination: false,
              "data-source": _vm.tableList1,
              bordered: "",
              rowKey: "itemId",
              scroll: { y: _vm.maxHeight },
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function (text, record, index) {
                  return [_c("span", [_vm._v(_vm._s(index + 1))])]
                },
              },
              {
                key: "isContained",
                fn: function (text, record) {
                  return [
                    _c(
                      "a-radio-group",
                      {
                        model: {
                          value: record.isContained,
                          callback: function ($$v) {
                            _vm.$set(record, "isContained", $$v)
                          },
                          expression: "record.isContained",
                        },
                      },
                      [
                        _c("a-radio", { attrs: { value: "1" } }, [
                          _vm._v("是"),
                        ]),
                        _c("a-radio", { attrs: { value: "0" } }, [
                          _vm._v("否"),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("a-table", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tabIndex == 2,
                expression: "tabIndex == 2",
              },
            ],
            attrs: {
              columns: _vm.columns1,
              pagination: false,
              "data-source": _vm.tableList2,
              bordered: "",
              rowKey: "itemId",
              scroll: { y: _vm.maxHeight },
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function (text, record, index) {
                  return [_c("span", [_vm._v(_vm._s(index + 1))])]
                },
              },
              {
                key: "isContained",
                fn: function (text, record) {
                  return [
                    _c(
                      "a-radio-group",
                      {
                        model: {
                          value: record.isContained,
                          callback: function ($$v) {
                            _vm.$set(record, "isContained", $$v)
                          },
                          expression: "record.isContained",
                        },
                      },
                      [
                        _c("a-radio", { attrs: { value: "1" } }, [
                          _vm._v("是"),
                        ]),
                        _c("a-radio", { attrs: { value: "0" } }, [
                          _vm._v("否"),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }