var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("p", [_vm._v("query: " + _vm._s(_vm.$route.query))]),
    _c("p", [_vm._v("params: " + _vm._s(_vm.$route.params))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }