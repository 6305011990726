var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { staticClass: "labour-y", attrs: { title: "项目详情" } },
    [
      _c(
        "div",
        { attrs: { slot: "extra" }, slot: "extra" },
        [
          _c(
            "a-button",
            {
              staticClass: "mr-4",
              attrs: { type: "primary" },
              on: { click: _vm.onSubmit },
            },
            [_vm._v(" 保存 ")]
          ),
          _c(
            "a-button",
            { attrs: { type: "text" }, on: { click: _vm.close } },
            [_vm._v(" 关闭 ")]
          ),
        ],
        1
      ),
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          staticStyle: { width: "84%", margin: "0 auto" },
          attrs: { model: _vm.form, rules: _vm.rules, layout: "inline" },
        },
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { staticClass: "mb-5", attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "项目名称", prop: "projectName" } },
                    [
                      _c("a-input", {
                        staticStyle: { width: "280px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.projectName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "projectName", $$v)
                          },
                          expression: "form.projectName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { staticClass: "mb-5", attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "申报情况" } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "280px" },
                          attrs: {
                            placeholder: "",
                            disabled: _vm.pageType == 2,
                          },
                          model: {
                            value: _vm.form.applyState,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "applyState", $$v)
                            },
                            expression: "form.applyState",
                          },
                        },
                        _vm._l(_vm.applyStateOption, function (item) {
                          return _c(
                            "a-select-option",
                            { key: item.value, attrs: { value: item.value } },
                            [_vm._v(" " + _vm._s(item.label) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { staticClass: "mb-5", attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "安全文明创优要求", prop: "cyyq" } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "280px" },
                          attrs: {
                            placeholder: "",
                            disabled: _vm.pageType == 2,
                          },
                          model: {
                            value: _vm.form.cyyq,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "cyyq", $$v)
                            },
                            expression: "form.cyyq",
                          },
                        },
                        [
                          _c("a-select-option", { attrs: { value: "1" } }, [
                            _vm._v(" 合同要求 "),
                          ]),
                          _c("a-select-option", { attrs: { value: "2" } }, [
                            _vm._v(" 滚动计划 "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.cylevel != 3
                ? _c(
                    "a-col",
                    { staticClass: "mb-5", attrs: { span: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "项目类别" } },
                        [
                          _c(
                            "a-select",
                            {
                              staticStyle: { width: "280px" },
                              attrs: { placeholder: "", disabled: "" },
                              model: {
                                value: _vm.form.ptype,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "ptype", $$v)
                                },
                                expression: "form.ptype",
                              },
                            },
                            [
                              _c("a-select-option", { attrs: { value: 1 } }, [
                                _vm._v(" 房建 "),
                              ]),
                              _c("a-select-option", { attrs: { value: 2 } }, [
                                _vm._v(" 市政 "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.cyyq == 2 && (_vm.cylevel == 2 || _vm.cylevel == 3)
                ? _c(
                    "a-col",
                    { staticClass: "mb-5", attrs: { span: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "立项情况" } },
                        [
                          _c(
                            "a-select",
                            {
                              staticStyle: { width: "280px" },
                              attrs: {
                                placeholder: "",
                                disabled: _vm.pageType == 2,
                              },
                              model: {
                                value: _vm.form.projectStatus,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "projectStatus", $$v)
                                },
                                expression: "form.projectStatus",
                              },
                            },
                            [
                              _c(
                                "a-select-option",
                                { attrs: { value: "已立项" } },
                                [_vm._v(" 已立项 ")]
                              ),
                              _c(
                                "a-select-option",
                                { attrs: { value: "未立项" } },
                                [_vm._v(" 未立项 ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$route.query.isBook == 1 &&
              _vm.form.cyyq == 2 &&
              (_vm.cylevel == 2 || _vm.cylevel == 3)
                ? _c(
                    "a-col",
                    { staticClass: "mb-5", attrs: { span: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "立项完成时间" } },
                        [
                          _c("a-date-picker", {
                            attrs: {
                              valueFormat: "YYYY-MM-DD",
                              placeholder: "",
                            },
                            model: {
                              value: _vm.form.projectDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "projectDate", $$v)
                              },
                              expression: "form.projectDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-col",
                { staticClass: "mb-5", attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "授奖单位" } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "280px" },
                          attrs: { placeholder: "" },
                          on: { change: _vm.handleAwardUnitChange },
                          model: {
                            value: _vm.awardUnitSelect,
                            callback: function ($$v) {
                              _vm.awardUnitSelect = $$v
                            },
                            expression: "awardUnitSelect",
                          },
                        },
                        _vm._l(_vm.awardUnitOption, function (item) {
                          return _c(
                            "a-select-option",
                            { key: item.value, attrs: { value: item.value } },
                            [_vm._v(" " + _vm._s(item.label) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.showOtherAwardUnit
                ? _c(
                    "a-col",
                    { staticClass: "mb-5", attrs: { span: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "" } },
                        [
                          _c("a-input", {
                            staticStyle: { width: "280px" },
                            attrs: { placeholder: "请输入授奖单位" },
                            model: {
                              value: _vm.form.awardUnit,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "awardUnit", $$v)
                              },
                              expression: "form.awardUnit",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-col",
                { staticClass: "mb-5", attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "备注" } },
                    [
                      _c("a-input", {
                        staticStyle: { width: "280px" },
                        model: {
                          value: _vm.form.comment,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "comment", $$v)
                          },
                          expression: "form.comment",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.cylevel == 1
                ? _c(
                    "a-col",
                    { staticClass: "mb-5", attrs: { span: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "是否发给协会" } },
                        [
                          _c(
                            "a-select",
                            {
                              staticStyle: { width: "280px" },
                              attrs: { placeholder: "" },
                              model: {
                                value: _vm.form.isSend,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "isSend", $$v)
                                },
                                expression: "form.isSend",
                              },
                            },
                            [
                              _c("a-select-option", { attrs: { value: 1 } }, [
                                _vm._v(" 是 "),
                              ]),
                              _c("a-select-option", { attrs: { value: 0 } }, [
                                _vm._v(" 否 "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.cyyq == 2 && (_vm.cylevel == 2 || _vm.cylevel == 3)
                ? _c(
                    "a-col",
                    { staticClass: "mb-5", attrs: { span: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "上传立项表" } },
                        [
                          _c(
                            "a-upload",
                            {
                              staticClass: "avatar-uploader",
                              attrs: {
                                "list-type": "picture-card",
                                accept: ".pdf",
                                "show-upload-list": false,
                                customRequest: _vm.resourceLXUpload,
                              },
                            },
                            [
                              _vm.form.projectFile &&
                              _vm.form.projectFile.indexOf("pdf") == -1
                                ? _c("img", {
                                    attrs: {
                                      src: _vm.form.projectFile,
                                      alt: "avatar",
                                    },
                                  })
                                : _vm.form.projectFile &&
                                  _vm.form.projectFile.indexOf("pdf") > -1
                                ? _c("a-icon", {
                                    style: { fontSize: "64px" },
                                    attrs: { type: "file-pdf" },
                                  })
                                : _c(
                                    "div",
                                    [
                                      _c("a-icon", { attrs: { type: "plus" } }),
                                      _c(
                                        "div",
                                        { staticClass: "ant-upload-text" },
                                        [_vm._v("上传立项表")]
                                      ),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                color: "#bfbfbf",
                                "font-size": "14px",
                                "margin-top": "-28px",
                              },
                            },
                            [
                              _vm._v(" pdf格式 "),
                              _c(
                                "div",
                                { staticStyle: { "margin-top": "-20px" } },
                                [
                                  _vm.form.projectFile
                                    ? _c(
                                        "a-button",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "link",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handlePreview(
                                                _vm.form.projectFile
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 预览 ")]
                                      )
                                    : _vm._e(),
                                  _vm.form.projectFile
                                    ? _c(
                                        "a-button",
                                        {
                                          attrs: { type: "link" },
                                          on: { click: _vm.handleLXClear },
                                        },
                                        [_vm._v(" 清除 ")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.cylevel != 3
                ? _c("a-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "p-2 mb-4",
                        staticStyle: {
                          "border-bottom": "1px solid #bbbbbb",
                          color: "#272727",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.timeTitle) + " ")]
                    ),
                  ])
                : _vm._e(),
              _vm.cylevel == 1 || _vm.cylevel == 4
                ? [
                    _c(
                      "a-col",
                      { staticClass: "mb-5", attrs: { span: 8 } },
                      [
                        _c(
                          "a-form-model-item",
                          { attrs: { label: "基础", prop: "cityDateBase" } },
                          [
                            _c(
                              "a-radio-group",
                              {
                                model: {
                                  value: _vm.form.cityDateBaseHave,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "cityDateBaseHave", $$v)
                                  },
                                  expression: "form.cityDateBaseHave",
                                },
                              },
                              [
                                _c("a-radio", { attrs: { value: true } }, [
                                  _vm._v(" 有 "),
                                ]),
                                _c("a-radio", { attrs: { value: false } }, [
                                  _vm._v(" 无 "),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _vm.form.cityDateBaseHave
                                  ? _c("a-date-picker", {
                                      attrs: {
                                        valueFormat: "YYYY-MM-DD",
                                        placeholder: "",
                                        disabled: _vm.pageType == 2,
                                      },
                                      on: { change: _vm.onDateBaseChange },
                                      model: {
                                        value: _vm.form.cityDateBase,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "cityDateBase",
                                            $$v
                                          )
                                        },
                                        expression: "form.cityDateBase",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a-col",
                      { staticClass: "mb-5", attrs: { span: 8 } },
                      [
                        _c(
                          "a-form-model-item",
                          { attrs: { label: "主体", prop: "cityDateMajor" } },
                          [
                            _c(
                              "a-radio-group",
                              {
                                model: {
                                  value: _vm.form.cityDateMajorHave,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "cityDateMajorHave", $$v)
                                  },
                                  expression: "form.cityDateMajorHave",
                                },
                              },
                              [
                                _c("a-radio", { attrs: { value: true } }, [
                                  _vm._v(" 有 "),
                                ]),
                                _c("a-radio", { attrs: { value: false } }, [
                                  _vm._v(" 无 "),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _vm.form.cityDateMajorHave
                                  ? _c("a-date-picker", {
                                      attrs: {
                                        valueFormat: "YYYY-MM-DD",
                                        placeholder: "",
                                        disabled: _vm.pageType == 2,
                                      },
                                      on: { change: _vm.onDateMajorChange },
                                      model: {
                                        value: _vm.form.cityDateMajor,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "cityDateMajor",
                                            $$v
                                          )
                                        },
                                        expression: "form.cityDateMajor",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a-col",
                      { staticClass: "mb-5", attrs: { span: 8 } },
                      [
                        _c(
                          "a-form-model-item",
                          {
                            attrs: {
                              label: "装饰装修",
                              prop: "cityDateDecorate",
                            },
                          },
                          [
                            _c(
                              "a-radio-group",
                              {
                                model: {
                                  value: _vm.form.cityDateDecorateHave,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "cityDateDecorateHave",
                                      $$v
                                    )
                                  },
                                  expression: "form.cityDateDecorateHave",
                                },
                              },
                              [
                                _c("a-radio", { attrs: { value: true } }, [
                                  _vm._v(" 有 "),
                                ]),
                                _c("a-radio", { attrs: { value: false } }, [
                                  _vm._v(" 无 "),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _vm.form.cityDateDecorateHave
                                  ? _c("a-date-picker", {
                                      attrs: {
                                        valueFormat: "YYYY-MM-DD",
                                        placeholder: "",
                                        disabled: _vm.pageType == 2,
                                      },
                                      on: { change: _vm.onDateDecorateChange },
                                      model: {
                                        value: _vm.form.cityDateDecorate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "cityDateDecorate",
                                            $$v
                                          )
                                        },
                                        expression: "form.cityDateDecorate",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.cylevel == 2
                ? [
                    _c(
                      "a-col",
                      { staticClass: "mb-5", attrs: { span: 8 } },
                      [
                        _c(
                          "a-form-model-item",
                          {
                            attrs: { label: "初评", prop: "provinceDateFirst" },
                          },
                          [
                            _c(
                              "a-radio-group",
                              {
                                model: {
                                  value: _vm.form.provinceDateFirstHave,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "provinceDateFirstHave",
                                      $$v
                                    )
                                  },
                                  expression: "form.provinceDateFirstHave",
                                },
                              },
                              [
                                _c("a-radio", { attrs: { value: true } }, [
                                  _vm._v(" 有 "),
                                ]),
                                _c("a-radio", { attrs: { value: false } }, [
                                  _vm._v(" 无 "),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _vm.form.provinceDateFirstHave
                                  ? _c("a-date-picker", {
                                      attrs: {
                                        valueFormat: "YYYY-MM-DD",
                                        placeholder: "",
                                        disabled: _vm.pageType == 2,
                                      },
                                      model: {
                                        value: _vm.form.provinceDateFirst,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "provinceDateFirst",
                                            $$v
                                          )
                                        },
                                        expression: "form.provinceDateFirst",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a-col",
                      { staticClass: "mb-5", attrs: { span: 8 } },
                      [
                        _c(
                          "a-form-model-item",
                          {
                            attrs: {
                              label: "复评",
                              prop: "provinceDateSecond",
                            },
                          },
                          [
                            _c(
                              "a-radio-group",
                              {
                                model: {
                                  value: _vm.form.provinceDateSecondHave,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "provinceDateSecondHave",
                                      $$v
                                    )
                                  },
                                  expression: "form.provinceDateSecondHave",
                                },
                              },
                              [
                                _c("a-radio", { attrs: { value: true } }, [
                                  _vm._v(" 有 "),
                                ]),
                                _c("a-radio", { attrs: { value: false } }, [
                                  _vm._v(" 无 "),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _vm.form.provinceDateSecondHave
                                  ? _c("a-date-picker", {
                                      attrs: {
                                        valueFormat: "YYYY-MM-DD",
                                        placeholder: "",
                                        disabled: _vm.pageType == 2,
                                      },
                                      model: {
                                        value: _vm.form.provinceDateSecond,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "provinceDateSecond",
                                            $$v
                                          )
                                        },
                                        expression: "form.provinceDateSecond",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm.cylevel == 3
                ? [
                    _c(
                      "a-col",
                      { staticClass: "mb-5", attrs: { span: 24 } },
                      [
                        _c(
                          "a-form-model-item",
                          {
                            attrs: {
                              label: "国家级安全生产标准化简评时间",
                              prop: "countryDateStandard",
                            },
                          },
                          [
                            _c(
                              "a-radio-group",
                              {
                                attrs: { disabled: _vm.pageType == 2 },
                                model: {
                                  value: _vm.form.countryDateStandardHave,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "countryDateStandardHave",
                                      $$v
                                    )
                                  },
                                  expression: "form.countryDateStandardHave",
                                },
                              },
                              [
                                _c("a-radio", { attrs: { value: true } }, [
                                  _vm._v(" 有 "),
                                ]),
                                _c("a-radio", { attrs: { value: false } }, [
                                  _vm._v(" 无 "),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _vm.form.countryDateStandardHave
                                  ? _c("a-date-picker", {
                                      attrs: {
                                        valueFormat: "YYYY-MM-DD",
                                        placeholder: "",
                                        disabled: _vm.pageType == 2,
                                      },
                                      model: {
                                        value: _vm.form.countryDateStandard,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "countryDateStandard",
                                            $$v
                                          )
                                        },
                                        expression: "form.countryDateStandard",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-row",
                    { attrs: { gutter: 48 } },
                    [
                      _vm._l(_vm.certificates, function (item, index) {
                        return _c(
                          "a-col",
                          {
                            key: index,
                            staticClass: "mb-3",
                            attrs: { span: 12 },
                          },
                          [
                            _c(
                              "a-card",
                              { attrs: { size: "small", hoverable: "" } },
                              [
                                _vm.cylevel != 4
                                  ? _c(
                                      "div",
                                      {
                                        attrs: { slot: "title" },
                                        slot: "title",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(item.certificateName) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.cylevel == 4
                                  ? _c(
                                      "div",
                                      { staticClass: "mb-3" },
                                      [
                                        _c(
                                          "a-form-model-item",
                                          { attrs: { label: "奖项名称" } },
                                          [
                                            _c(
                                              "a-select",
                                              {
                                                staticStyle: { width: "280px" },
                                                attrs: {
                                                  "show-search": "",
                                                  placeholder: "输入关键字搜索",
                                                  "default-active-first-option": true,
                                                  "show-arrow": false,
                                                  "filter-option": false,
                                                },
                                                on: {
                                                  search: function ($event) {
                                                    return _vm.remoteSearch(
                                                      $event,
                                                      index
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: item.certificateName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "certificateName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.certificateName",
                                                },
                                              },
                                              [
                                                _c(
                                                  "a-select-opt-group",
                                                  {
                                                    attrs: {
                                                      label: "现有证书",
                                                    },
                                                  },
                                                  _vm._l(
                                                    item.options,
                                                    function (d) {
                                                      return _c(
                                                        "a-select-option",
                                                        {
                                                          key: d.certificateName,
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                d.certificateName
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                                _c(
                                                  "a-select-opt-group",
                                                  {
                                                    attrs: {
                                                      label: "新增证书",
                                                    },
                                                  },
                                                  _vm._l(
                                                    item.optionsAdd,
                                                    function (d) {
                                                      return _c(
                                                        "a-select-option",
                                                        {
                                                          key: d.certificateName,
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                d.certificateName
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "mb-3" },
                                  [
                                    _c(
                                      "a-form-model-item",
                                      { attrs: { label: "获奖时间" } },
                                      [
                                        _c("a-month-picker", {
                                          attrs: {
                                            valueFormat: "YYYY-MM",
                                            placeholder: "",
                                          },
                                          model: {
                                            value: item.certificateDate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "certificateDate",
                                                $$v
                                              )
                                            },
                                            expression: "item.certificateDate",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "mb-3" },
                                  [
                                    _c(
                                      "a-form-model-item",
                                      { attrs: { label: "项目证书编号" } },
                                      [
                                        _c("a-input", {
                                          staticStyle: { width: "280px" },
                                          model: {
                                            value: item.certificateNum,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "certificateNum",
                                                $$v
                                              )
                                            },
                                            expression: "item.certificateNum",
                                          },
                                        }),
                                        _vm.cylevel == 1
                                          ? _c(
                                              "div",
                                              _vm._l(_vm.codeDes, function (t) {
                                                return _c(
                                                  "span",
                                                  {
                                                    key: t,
                                                    staticClass: "mr-2",
                                                    staticStyle: {
                                                      border:
                                                        "1px solid rgba(187, 187, 187, 1)",
                                                      "border-radius": "20px",
                                                      padding: "4px",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleCopyCode(
                                                          item,
                                                          t
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(t))]
                                                )
                                              }),
                                              0
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "mb-3" },
                                  [
                                    _c(
                                      "a-row",
                                      { attrs: { gutter: 48 } },
                                      [
                                        _c(
                                          "a-col",
                                          { attrs: { span: 12 } },
                                          [
                                            _c(
                                              "a-form-model-item",
                                              { attrs: { label: "项目证书" } },
                                              [
                                                _c(
                                                  "a-upload",
                                                  {
                                                    staticClass:
                                                      "avatar-uploader",
                                                    attrs: {
                                                      "list-type":
                                                        "picture-card",
                                                      accept:
                                                        ".png, .jpg, .jpeg,.pdf",
                                                      "show-upload-list": false,
                                                      customRequest:
                                                        item.customRequest,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.handleChange(
                                                          $event,
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    item.certificatePaths &&
                                                    item.certificatePaths.indexOf(
                                                      "pdf"
                                                    ) == -1
                                                      ? _c("img", {
                                                          attrs: {
                                                            src: item.certificatePaths,
                                                            alt: "avatar",
                                                          },
                                                        })
                                                      : item.certificatePaths &&
                                                        item.certificatePaths.indexOf(
                                                          "pdf"
                                                        ) > -1
                                                      ? _c("a-icon", {
                                                          style: {
                                                            fontSize: "64px",
                                                          },
                                                          attrs: {
                                                            type: "file-pdf",
                                                          },
                                                        })
                                                      : _c(
                                                          "div",
                                                          [
                                                            _c("a-icon", {
                                                              attrs: {
                                                                type: item.loading
                                                                  ? "loading"
                                                                  : "plus",
                                                              },
                                                            }),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "ant-upload-text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "上传证书"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      color: "#bfbfbf",
                                                      "font-size": "14px",
                                                      "margin-top": "-28px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(" 图片或pdf格式 "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "margin-top": "-20px",
                                                        },
                                                      },
                                                      [
                                                        item.certificatePaths
                                                          ? _c(
                                                              "a-button",
                                                              {
                                                                attrs: {
                                                                  size: "small",
                                                                  type: "link",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handlePreview(
                                                                        item.certificatePaths
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v(" 预览 ")]
                                                            )
                                                          : _vm._e(),
                                                        item.certificatePaths
                                                          ? _c(
                                                              "a-button",
                                                              {
                                                                attrs: {
                                                                  type: "link",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleClear(
                                                                        item,
                                                                        index,
                                                                        1
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v(" 清除 ")]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        (_vm.cylevel == 2 &&
                                          _vm.form.ptype == 1) ||
                                        _vm.cylevel == 1
                                          ? _c(
                                              "a-col",
                                              { attrs: { span: 12 } },
                                              [
                                                _c(
                                                  "a-form-model-item",
                                                  {
                                                    attrs: {
                                                      label: "个人证书",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "a-upload",
                                                      {
                                                        staticClass:
                                                          "avatar-uploader",
                                                        attrs: {
                                                          "list-type":
                                                            "picture-card",
                                                          accept:
                                                            ".png, .jpg, .jpeg,.pdf",
                                                          "show-upload-list": false,
                                                          customRequest:
                                                            item.privatecustomRequest,
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleChangePrivate(
                                                              $event,
                                                              index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        item.certificatePathsPrivate &&
                                                        item.certificatePathsPrivate.indexOf(
                                                          "pdf"
                                                        ) == -1
                                                          ? _c("img", {
                                                              attrs: {
                                                                src: item.certificatePathsPrivate,
                                                                alt: "avatar",
                                                              },
                                                            })
                                                          : item.certificatePathsPrivate &&
                                                            item.certificatePathsPrivate.indexOf(
                                                              "pdf"
                                                            ) > -1
                                                          ? _c("a-icon", {
                                                              style: {
                                                                fontSize:
                                                                  "64px",
                                                              },
                                                              attrs: {
                                                                type: "file-pdf",
                                                              },
                                                            })
                                                          : _c(
                                                              "div",
                                                              [
                                                                _c("a-icon", {
                                                                  attrs: {
                                                                    type: item.privateLoading
                                                                      ? "loading"
                                                                      : "plus",
                                                                  },
                                                                }),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "ant-upload-text",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "上传证书"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          color: "#bfbfbf",
                                                          "font-size": "14px",
                                                          "margin-top": "-28px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " 图片或pdf格式 "
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              "margin-top":
                                                                "-20px",
                                                            },
                                                          },
                                                          [
                                                            item.certificatePathsPrivate
                                                              ? _c(
                                                                  "a-button",
                                                                  {
                                                                    attrs: {
                                                                      size: "small",
                                                                      type: "link",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handlePreview(
                                                                            item.certificatePathsPrivate
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " 预览 "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            item.certificatePathsPrivate
                                                              ? _c(
                                                                  "a-button",
                                                                  {
                                                                    attrs: {
                                                                      type: "link",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handleClear(
                                                                            item,
                                                                            index,
                                                                            2
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " 清除 "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      _c("a-col", { attrs: { span: 24 } }, [
                        _vm.certificates[0].ledger != 1 &&
                        _vm.certificates[0].excellentId
                          ? _c(
                              "div",
                              {
                                staticClass: "p-4",
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "center",
                                },
                              },
                              [
                                _c(
                                  "a-button",
                                  {
                                    staticClass: "mr-3",
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeLedgerStatus(1)
                                      },
                                    },
                                  },
                                  [_vm._v(" 未通过创优 ")]
                                ),
                                _c(
                                  "a-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeLedgerStatus(0)
                                      },
                                    },
                                  },
                                  [_vm._v(" 流转到台账 ")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }