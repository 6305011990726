// 带班企业负责人(建安易)	OFFICE_OG_MANAGE	带班领导（集团）
// 集团安全部门管理员(建安易)	CLIGUE_AQMGLY	集团安全管理人员
// 总包项目经理(建安易)	project_leader	项目经理
// 总包安全员负责人(建安易)	PROJECT_AQY_ZB_FZR	安全员
// 项目现场管理人员(建安易)	OFFICE_ORDINARY_STAFF	
// 安全部门管理人员(建安易)	OFFICE_AQMGLY	子公司安全管理人员
// 安全部门负责人（建安易）	OFFICE_AQ_LEADER	分公司安全负责人
import { mapGetters } from "vuex";
export default {
    data() {
        return {

        };
    },
    computed: {
    
        ...mapGetters("account", ["user"]),
        isBranchOffice() { //分公司
            return this.user.nowRoleKey.includes('OFFICE_AQ_LEADER')
        },
        isProject() { //项目部
            return this.user.loginType == 0
        },
        isSafe() { //公司
            return this.user.nowRoleKey.includes('OFFICE_AQMGLY')
        },
        isProjectLeader(){
            // user.nowRoleKey 包含 'project_leader'
            return this.user.nowRoleKey.includes('project_leader')
        },
        isProjectSafer() {
            // user.nowRoleKey 包含 'PROJECT_AQY_ZB_FZR'
            return this.user.nowRoleKey.includes('PROJECT_AQY_ZB_FZR')
        },
        isJiTuan() {
        // CLIGUE_AQMGLY
            return this.user.nowRoleKey.includes('CLIGUE_AQMGLY') || this.user.nowRoleKey.includes('OFFICE_OG_MANAGE')
        }
    },
    methods: {
        calcRole() {
            let role = ''
            if (this.isBranchOffice) {
                role = '3'
            }
            if (this.isProject) {
                if(this.isProjectLeader) {
                    role = '2'
                }
                if(this.isProjectSafer) {
                    role = '1'
                }
            }
            if (this.isSafe) {
                role = '4'    
            }
            if (this.isJiTuan) {
                role = '5'
            }
    
    
            return role
        },
    },

};