<template>
  <div class="a_Approval">
    <div class="plug-Overview">安全检查记录</div>
    <a-divider />
    <div :class="advanced ? 'search' : null">
      <a-form class="clearfix">
        <a-col :span="5">
          <a-form-item
            label="检查部门"
            :labelCol="{ span: 8 }"
            :wrapperCol="{ span: 14, offset: 1 }"
          >
            <a-select v-model="queryParams.isGcglb">
              <a-select-option :value="2">公司</a-select-option>
              <a-select-option :value="1">工程管理部</a-select-option>
              <a-select-option :value="3">项目自检</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="5">
          <a-form-item
            label="检查时间"
            :labelCol="{ span: 8 }"
            :wrapperCol="{ span: 14, offset: 1 }"
          >
            <a-range-picker
              v-model="queryParams.plan_date"
              :format="dateFormat"
              @change="changeDate"
            />
          </a-form-item>
        </a-col>

        <a-col :span="4">
          <a-space class="btnBox">
            <a-button type="primary" @click="doQuery()">查询</a-button>
            <a-button style="margin-left: 8px" @click="doReset()">重置</a-button>
          </a-space>
        </a-col>
      </a-form>
    </div>

    <standard-table
      :columns="columns"
      :dataSource="dataSource"
      :selectedRows.sync="selectedRows"
      :pagination="pagination"
      :loading="tableLoading"
      :isAlert="false"
      @clear="onClear"
      @change="onPageChange"
      :btnArr="tableBtnArr"
    >
      <div slot="planType" slot-scope="{ text }">
        <div v-if="text == '4'">停工处罚</div>
        <div v-else-if="text == '3'">带班检查</div>
        <div v-else-if="xmzc">项目自检</div>
        <div v-else>公司常规检查</div>
      </div>
      <div slot="do-status" slot-scope="{ record, text }" v-if="!xmzc">
        <label v-if="record.planType == 4 && text == 0">待处理</label>
        <label
          v-if="(record.planType == 1 || record.planType == 3) && text == 0"
          >待检查</label
        >
        <label v-if="text == 1">待复核</label>
        <label v-if="text == 2">已完成</label>
        <label v-if="text == 3">整改中</label>
      </div>
      <div slot="do-status" slot-scope="{ text }" v-else>
        <label v-if="text == 0">检查中</label>
        <label v-if="text == 2">整改不合格</label>
        <label v-if="text == 1">整改达标</label>
      </div>
      <div slot="action" slot-scope="{ record }">
        <a @click="dowLoadFile(record)">下载文件</a>
      </div>
      <template slot="statusTitle">
        <a-icon @click.native="onStatusTitleClick" type="info-circle" />
      </template>
    </standard-table>
    <!-- <detail
            :visible="detail.visible"
            :projectId="detail.projectId"
            :officeName="detail.officeName"
            :officeId="detail.officeId"
            :projectName="detail.projectName"
            :times="detail.times"
            @close="doClose"
          ></detail> -->
    <Export-loading
      :cPercent="cPercent"
      :isExporting="isExporting"
      :loadingText="loadingText"
    ></Export-loading>
  </div>
</template>

<script>
import StandardTable from "@/components/table/StandardTable";
import {
  AQXJ_QUERY_GETLIST,
  GET_OFFICE_TREE_BY_EPIDEMIC,
  SAFE_VIEW_DOWNLOAD,
  PROJECT_CHECK_AQXJ_QUERY,
  PROJECT_CHECK_AQXJ_EXPORT,
} from "@/services/api";
import { exportProgress } from "@/pages/aqjyzl/export"; // 导出方法
import ExportLoading from "@/components/ExportLoading/ExportLoading"; // progress组件
import { request } from "@/utils/request";
import { mapGetters } from "vuex";
import moment from "moment";
import { useRender } from '@/hooks/useRender.js'

const columns = [
  {
    title: "检查类型",
    dataIndex: "planType",
    align: "center",
    scopedSlots: { customRender: "planType" },
  },
  {
    title: "检查单位",
    dataIndex: "officeName",
    align: "left",
    scopedSlots: { customRender: "officeName" },
  },
  {
    title: "项目名称",
    dataIndex: "projectName",
    align: "left",
    width: 249,
    ellipsis: true,
    scopedSlots: { customRender: "projectName" },
  },
  {
    title: "检查时间",
    dataIndex: "xjTime",
    align: "center",
    customRender: (text) => useRender.renderDate(text,'YYYY-MM-DD')
  },
  // {
  //   title: '巡检时间',
  //   dataIndex: 'status',
  //   align: 'center',
  //   scopedSlots: { customRender: 'status' }
  // },
  {
    title: "状态",
    dataIndex: "status",
    align: "center",
    scopedSlots: { customRender: "do-status" },
  },
  {
    title: "检查标题",
    dataIndex: "title",
    align: "left",
    scopedSlots: { customRender: "title" },
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
const columns2 = [
  {
    title: "检查类型",
    dataIndex: "planType",
    align: "center",
    scopedSlots: { customRender: "planType" },
  },
  {
    title: "项目名称",
    dataIndex: "projectName",
    align: "left",
    width: 249,
    ellipsis: true,
    scopedSlots: { customRender: "projectName" },
  },
  {
    title: "检查时间",
    dataIndex: "checkDate",
    align: "center",
    customRender: (text) => useRender.renderDate(text)
  },
  // {
  //   title: '巡检时间',
  //   dataIndex: 'status',
  //   align: 'center',
  //   scopedSlots: { customRender: 'status' }
  // },
  {
    title: "状态",
    dataIndex: "fhjg",
    align: "center",
    scopedSlots: { customRender: "do-status" },
  },
  {
    title: "检查标题",
    dataIndex: "title",
    align: "left",
    scopedSlots: { customRender: "title" },
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  mixins: [exportProgress],
  name: "QueryList",
  components: { StandardTable, ExportLoading },
  data() {
    return {
      firstDay: null,
      nowDay: null,
      dataSource: [],
      loadingText: "正在下载文件...",
      loadKeys: [],
      officeTreeData: [],
      advanced: false,
      columns: columns,
      xmzc: false,
      columns2,
      tableLoading: false,
      selectedRows: [],
      currentOfficeId: null,
      dateFormat: "YYYY-MM-DD",
      queryParams: {
        officeName: null,
        officeId: null,
        teamName: null,
        pageNumber: 1,
        plan_date: null,
        projectName: null,
        planType: null,
        status: null,
        queryType: 1,
        isGcglb: 2,
      },
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showLessItems: true,
        showQuickJumper: true,
      },
      detail: {
        visible: false,
      },
      cPercent: 0,
      isExporting: false,
      fileName: "检查记录文件",
      tableBtnArr: [
        {
            name: '批量下载',
            attrs: {
                type: 'primary'
            },
            click: this.doDowloadBatch
        }
      ]
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  created() {
    this.firstDay = moment(new Date()).format("YYYY-MM") + "-01";
    this.nowDay = moment(new Date()).format("YYYY-MM-DD");
    this.doQuery();
    if (this.user.enname == "admin") {
      // this.getOfficeTree(undefined);
      this.currentOfficeId = undefined;
    } else {
      // this.getOfficeTree(this.user.officeId);
      this.currentOfficeId = this.user.officeId;
    }
  },
  watch: {
    cPercent: {
      handler(newName, oldName) {
        this.cPercent = newName;
      },
      immediate: true,
    },
    loadingText: {
      handler(newName, oldName) {
        this.loadingText = newName;
      },
      immediate: true,
    },
  },
  methods: {
    moment,
    deleteRecord(key) {
      this.dataSource = this.dataSource.filter((item) => item.key !== key);
      this.selectedRows = this.selectedRows.filter((item) => item.key !== key);
    },
    toggleAdvanced() {
      this.advanced = !this.advanced;
    },
    remove() {
      this.dataSource = this.dataSource.filter(
        (item) =>
          this.selectedRows.findIndex((row) => row.key === item.key) === -1
      );
      this.selectedRows = [];
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },
    getOfficeTree(officeId) {
      let params = {
        parentOfficeId: officeId,
      };
      request(GET_OFFICE_TREE_BY_EPIDEMIC, "get", params).then((res) => {
        this.officeTreeData = res.data.data;
      });
    },
    /**
     * 获取所有人员的签名记录信息
     */
    doQuery() {
      this.tableLoading = true;
      if (this.queryParams.plan_date && this.queryParams.plan_date.length > 0) {
        this.queryParams.xjTimeStart =
          this.queryParams.plan_date[0].format("YYYY-MM-DD");
        this.queryParams.xjTimeEnd =
          this.queryParams.plan_date[1].format("YYYY-MM-DD");
      }
      if (this.queryParams.isGcglb === 3) {
        request(PROJECT_CHECK_AQXJ_QUERY, "post", this.queryParams).then(
          (res) => {
            if (res.data.code === 0) {
              this.xmzc = true;
              this.columns = columns2;
              this.dataSource = res.data.data.list;
              this.pagination.total = res.data.data.total;
              this.dataSource.forEach((obj, index) => {
                obj.key = obj.id;
              });
            }
            this.tableLoading = false;
          }
        );
      } else {
        request(AQXJ_QUERY_GETLIST, "post", this.queryParams).then((res) => {
          if (res.data.code === 0) {
            this.xmzc = false;
            this.columns = columns;
            this.dataSource = res.data.data.list;
            this.pagination.total = res.data.data.total;
            this.dataSource.forEach((obj, index) => {
              obj.key = obj.id;
              // obj.index = this.pagination.current*index+1
            });
          }
          this.tableLoading = false;
        });
      }
    },
    doReset() {
      this.queryParams = { queryType: 1 };
      this.doQuery();
    },
    //分页查询
    onPageChange(e) {
      this.queryParams.pageNumber = e.current;
      this.pagination.current = e.current;
      this.doQuery();
    },
    changeDate(e) {
      if (e == null || e.length <= 0) {
        this.queryParams.startDate = null;
        this.queryParams.endDate = null;
      }
    },
    //下载巡检资料文件
    dowLoadFile(recode) {
      console.log(recode);
      let selectedRows = this.dataSource.filter(
        (item) => item.key == recode.key
      );
      this.loadingText = "正在下载文件，请稍候 ····";
      this.loadKeys = [];
      this.loadKeys.push(recode.key);
      this.isExporting = true;
      this.cPercent = 0;
      let fileName = "";
      if (recode.title) {
        fileName = recode.title;
      }
      let typeName = "公司常规检查";
      if (recode.planType == 4) {
        typeName = "停工处罚";
      } else if (recode.planType == 3) {
        typeName = "带班检查";
      } else {
        typeName = "公司常规检查";
      }
      let projectName = recode.projectName;
      if (recode.projectName.length > 15) {
        projectName = recode.projectName.slice(0, 15) + "……";
      }
      if (this.queryParams.isGcglb === 3) {
        fileName = fileName || recode.projectName;
        fileName =
          fileName +
          "-" +
          moment(recode.checkDate).format("YYYY-MM-DD") +
          "-项目自检文件.zip";
        this.exportFun(
          PROJECT_CHECK_AQXJ_EXPORT,
          this.loadKeys,
          fileName,
          selectedRows
        );
      } else {
        fileName =
          projectName +
          "-" +
          moment(recode.xjTime).format("YYYY-MM-DD") +
          "-" +
          typeName +
          ".zip";
        this.exportFun(
          SAFE_VIEW_DOWNLOAD,
          this.loadKeys,
          fileName,
          selectedRows
        );
      }
    },
    doDowloadBatch() {
      if (this.selectedRows && this.selectedRows.length > 0) {
        this.isExporting = true;
        this.loadingText = "正在下载资料...";
        this.selectedRows.map((item) => {
          this.loadKeys = [];
          this.loadKeys.push(item.key);
          this.cPercent = 0;
          let fileName = "";
          if (item.title) {
            fileName = item.title;
          }
          let typeName = "公司常规检查";
          if (item.planType == 4) {
            typeName = "停工处罚";
          } else if (item.planType == 3) {
            typeName = "带班检查";
          } else {
            typeName = "公司常规检查";
          }
          let projectName = item.projectName;
          if (item.projectName.length > 15) {
            projectName = item.projectName.slice(0, 15) + "……";
          }
          if (this.queryParams.isGcglb === 3) {
            fileName = fileName || item.projectName;
            fileName =
              fileName +
              "-" +
              moment(item.checkDate).format("YYYY-MM-DD") +
              "-项目自检文件.zip";
            this.exportFun(
              PROJECT_CHECK_AQXJ_EXPORT,
              this.loadKeys,
              fileName,
              item
            );
          } else {
            fileName =
              projectName +
              "-" +
              moment(item.xjTime).format("YYYY-MM-DD") +
              "-" +
              typeName +
              ".zip";
            // this.exportFunAlone(SAFEMEANS_DOWNLOAD,itemsKeys,fileName,items,this.selectedRows)
            this.exportFun(SAFE_VIEW_DOWNLOAD, this.loadKeys, fileName, item);
          }
        });
      } else {
        this.$message.info("请选择记录下载");
        return;
      }
    },
    doClose() {
      this.detail.visible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.search {
  margin-bottom: 54px;
}
.fold {
  width: calc(100% - 216px);
  display: inline-block;
}
@media screen and (max-width: 900px) {
  .fold {
    width: 100%;
  }
}
</style>
