var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "constructiontTable_index" },
      [
        _c("form-index", {
          ref: "formindex",
          attrs: {
            projectList: _vm.projectList,
            formData: _vm.formData,
            flexAction: true,
            baiduMapBoolen: false,
          },
          on: {
            handleSubmit: _vm.handleSubmit,
            handleChange: _vm.handleChange,
            handlSelect: _vm.handlSelect,
          },
          scopedSlots: _vm._u([
            {
              key: "flexAction",
              fn: function () {
                return [
                  _c(
                    "a-space",
                    { style: { marginTop: "4px" } },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            icon: "search",
                            type: "primary",
                            "html-type": "submit",
                          },
                        },
                        [_vm._v(" 查询 ")]
                      ),
                      _c(
                        "a-space",
                        { staticClass: "operator" },
                        [
                          _c("a-button", { on: { click: _vm.close } }, [
                            _vm._v(" 重置 "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: { icon: "plus", type: "primary" },
                          on: { click: _vm.createItem },
                        },
                        [_vm._v(" 新增单位 ")]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        }),
        _c("standard-table", {
          attrs: {
            columns: _vm.columns,
            dataSource: _vm.dataSource,
            rowKey: "id",
            pagination: _vm.pagination,
            loading: _vm.tableLoading,
            isAlert: false,
            bordered: true,
            tableSize: "middle",
          },
          on: { change: _vm.onPageChange },
          scopedSlots: _vm._u([
            {
              key: "index",
              fn: function (ref) {
                var index = ref.index
                return [
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          (_vm.pagination.current - 1) *
                            _vm.pagination.pageSize +
                            parseInt(index) +
                            1
                        ) +
                        " "
                    ),
                  ]),
                ]
              },
            },
            {
              key: "projectName",
              fn: function (ref) {
                var record = ref.record
                return [
                  _c("div", { style: _vm.textStyle(record) }, [
                    _vm._v(" " + _vm._s(record.projectName) + " "),
                  ]),
                ]
              },
            },
            {
              key: "roleType",
              fn: function (ref) {
                var text = ref.text
                return _vm._l(_vm.roleTypeList, function (item) {
                  return _c("div", { key: item.value }, [
                    text == item.value
                      ? _c("div", [_vm._v(" " + _vm._s(item.name) + " ")])
                      : _vm._e(),
                  ])
                })
              },
            },
            {
              key: "onlineStaffCnt",
              fn: function (ref) {
                var text = ref.text
                return [_c("div", [_vm._v(_vm._s(text ? text : 0))])]
              },
            },
            {
              key: "action",
              fn: function (ref) {
                var record = ref.record
                return _c("div", {}, [
                  _c(
                    "div",
                    [
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.viewEditing(record)
                            },
                          },
                        },
                        [_vm._v("查看")]
                      ),
                      _c("a-divider", { attrs: { type: "vertical" } }),
                      _c(
                        "a",
                        {
                          staticStyle: { color: "#FF5C77" },
                          on: {
                            click: function ($event) {
                              return _vm.viewEditing(record)
                            },
                          },
                        },
                        [_vm._v("修改")]
                      ),
                      _c("a-divider", { attrs: { type: "vertical" } }),
                      _c(
                        "a-popover",
                        {
                          staticStyle: { "margin-left": "-15px" },
                          attrs: { placement: "bottomLeft", trigger: "focus" },
                        },
                        [
                          _c("template", { slot: "content" }, [
                            _c(
                              "div",
                              {
                                staticClass: "content_div",
                                staticStyle: { color: "#FF5C77" },
                                on: {
                                  click: function ($event) {
                                    return _vm.recorDelete(record)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "content_div",
                                on: {
                                  click: function ($event) {
                                    return _vm.recordView(record, 4)
                                  },
                                },
                              },
                              [_vm._v(" 分配管理人员 ")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "content_div",
                                on: {
                                  click: function ($event) {
                                    return _vm.recordView(record, 5)
                                  },
                                },
                              },
                              [_vm._v(" 设置门禁通道 ")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "content_div",
                                on: {
                                  click: function ($event) {
                                    return _vm.recordView(record, 6)
                                  },
                                },
                              },
                              [_vm._v(" 评价 ")]
                            ),
                          ]),
                          _c("template", { slot: "title" }, [
                            _c("span", [_vm._v("操作")]),
                          ]),
                          _c(
                            "a-button",
                            {
                              staticStyle: { color: "#615E83" },
                              attrs: { type: "link" },
                            },
                            [_vm._v("更多>")]
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ])
              },
            },
          ]),
        }),
        _c("Export-loading", {
          attrs: {
            cPercent: _vm.cPercent,
            isExporting: _vm.isExporting,
            loadingText: _vm.loadingText,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }