<template>
  <div class="a_Approval">
    <div class="plug-Overview">审核列表</div>
    <a-divider />
    <a-form-model :model="params" class="clearfix" @submit="handleSubmit">
      <a-col :span="5">
        <a-form-model-item label="项目名称" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
          <a-input placeholder="请输入项目名称" v-model="params.projectName" allowClear/>
        </a-form-model-item>
      </a-col>

      <a-col :span="5">
        <a-form-model-item label="岗位" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
          <a-select 
            v-model="params.postId"
            show-search
            allowClear
            placeholder="请输入或选择"
            option-filter-prop="children"
            :options="postList"
          >
          </a-select>
        </a-form-model-item>
      </a-col>

      <a-col :span="5">
        <a-form-model-item label="查询人员" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
          <a-select
            v-model="params.userId"
            allowClear
            show-search
            placeholder="请输入查询人员"
            option-filter-prop="children"
          >
            <a-select-option
              :key="item.id"
              v-for="item in userSafeList"
              :value="item.id"
              :title="item.name"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>

      <a-col :span="5">
        <a-form-model-item label="审核状态" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
          <a-select
            v-model="params.status"
            allowClear
            show-search
            placeholder="请输入审核状态"
            option-filter-prop="children"
          >
            <a-select-option
              :key="item.id"
              v-for="item in statusList"
              :value="item.id"
              :title="item.name"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>

      <a-col :span="4">
        <a-space class="btnBox" style="padding-left: 10px;">
          <a-button type="primary" html-type="submit"> 查询 </a-button>
          <a-button @click="resetQuery"> 重置 </a-button>
        </a-space>
      </a-col>
    </a-form-model>
    <standard-table
      class=""
      :columns="columns"
      :dataSource="tableData"
      rowKey="id"
      :pagination="pagination"
      :loading="tableLoading"
      @change="handleChangePage"
      :isAlert="false"
      :bordered="true"
    >
      <template slot="index" slot-scope="{ index }">
        <span>{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}</span>
      </template>
      <template slot="status" slot-scope="{ record }">
        <span v-if="record.status == 0">待提交</span>
        <span v-else-if="record.status == 1">已提交</span>
        <span v-else-if="record.status == 2">通过</span>
        <span v-else-if="record.status == 3">驳回</span>
        <span v-else>/</span>
      </template>
        <!-- 证件 -->
      <template slot="cerFilePath" slot-scope="{ text, record }">
        <div class="flex a-center j-center" style="height: 40px">
          <div v-if="text != '' && text != null">
            <img
              :src="text"
              style="width: 40px; height: 40px; cursor: pointer"
              @click="showPreview(text, record)"
            />
          </div>
          <div v-if="!text">暂无图片</div>
        </div>
      </template>
      <template slot="action" slot-scope="{ record }">
        <div v-if="record.status && (record.status != 2 && record.status != 3)">
          <a @click="() => handleApprove(record, 2)" > 通过 </a>
          <a-divider type="vertical" />
          <a style="color:#FF5C77" @click="() => handleApprove(record, 3)" > 驳回 </a>
        </div>
      </template>
    </standard-table>
  </div>
</template>
    
<script>
import { getApproveList, approveStruct, getPostList } from "@/services/projectDutyStructure";
import { request } from "@/utils/request";
import { GET_ALL_USER } from "@/services/api";
import { mapGetters } from "vuex";
import StandardTable from "@/components/table/StandardTable";
import { useRender } from '@/hooks/useRender.js'

const columns = [
  {
    title: "序号",
    align: "center",
    scopedSlots: { customRender: "index" },
    width: 65,
  },
  {
    title: "项目名称",
    dataIndex: "projectName",
    ellipsis: true,
    width: 380,
  },
  {
    title: "岗位",
    dataIndex: "roleName",
    scopedSlots: { customRender: "roleName" },
    align: "center",
    width: 130,
  },
  {
    title: "姓名",
    dataIndex: "userName",
    scopedSlots: { customRender: "userName" },
    align: "center",
    width: 130,
  },
  {
    title: "联系方式",
    dataIndex: "phone",
    scopedSlots: { customRender: "phone" },
    align: "center",
    width: 130,
  },
  {
    title: "提交时间",
    dataIndex: "subTime",
    align: "center",
    width: 130,
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "审核状态",
    dataIndex: "status",
    scopedSlots: { customRender: "status" },
    align: "center",
    width: 130,
  },
  {
    title: "审核时间",
    dataIndex: "approveTime",
    align: "center",
    width: 130,
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "证件",
    dataIndex: "cerFilePath",
    align: "center",
    width: 150,
    scopedSlots: { customRender: "cerFilePath" },
  },
  {
    title: "备注",
    dataIndex: "remark",
    scopedSlots: { customRender: "remark" },
    align: "center",
    ellipsis: true,
  },
  {
    title: "操作",
    align: "center",
    fixed: "right",
    width: 120,
    scopedSlots: { customRender: "action" },
  },
];

export default {
  components: {
    StandardTable
  },
  data() {
    return {
      params: {
        postId: undefined,
        userId: undefined,
        projectName: '',
        pageNumber: 1,
        pageSize: 10,
      },
      pagination: {
        //分页数据
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total, range) => `共 ${total} 条`,
      },
      statusList: [{
        name: '已提交',
        id: 1
      },{
        name: '通过',
        id: 2
      },{
        name: '驳回',
        id: 3
      }],
      gcglbList: [],
      userSafeList: [],
      postList: [],
      total: 0,
      columns: columns,
      tableData: [],
      tableLoading: false, //table加载中
    };
  },
  created() {
    this.getPostList()
    this.getStafFindUser();
  },
  activated() {
    this.handleQuery();
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.params.pageNumber = 1
      this.pagination.current = 1
      this.tableData = []
      this.handleQuery();
    },
    getPostList() {
      getPostList({pageSize: 999}).then(res => {
        if (res.data.code === 0) {
          this.postList = res.data.data.list.map((item) => ({
            label: item.postName,
            value: item.id
          }))
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 安全责任筛选责任人数组user
    getStafFindUser() {
      request(GET_ALL_USER, "post", {}).then((res) => {
        if (res.data.code === 0) {
          this.userSafeList = res.data.data;
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    handleQuery(e) {
      this.tableLoading = true
      getApproveList(this.params).then((res) => {
        this.tableLoading = false
        let d = res.data;
        if (d.code === 0) {
          this.tableData = d.data.list
          this.pagination.total = d.data.total;
          this.total = d.data.total;
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    handleChangePage(e) {
      this.params.pageNumber = e.current;
      this.pagination.current = e.current;
      this.handleQuery();
    },
    handleApprove(e, status) {
      const _that = this
      let title = status == 2 ? '是否确定通过？' : '是否确定驳回？'
      this.$confirm({
        title,
        onOk() {
          approveStruct({id: e.id, status}).then(res => {
            let d = res.data;
            if (d.code === 0) {
              _that.$message.success('操作成功！')
              _that.handleQuery();
            } else {
              _that.$message.error(res.data.msg)
            }
          })
        }
      })
      
    },
    resetQuery() {
      this.pagination.pageNumber = 1
      this.params = {
        postId: undefined,
        userId: undefined,
        projectName: '',
        pageNumber: 1,
        pageSize: 10,
      };
      this.handleQuery();
    },
  },
};
</script>

<style lang="less" scoped>
@import "@/theme/common.less";
.query-form {
  display: flex;
  // justify-content: space-between;
}
</style>
    