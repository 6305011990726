var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a_Approval" },
    [
      _c("div", { staticClass: "plug-Overview" }, [_vm._v("危大工程情况表")]),
      _c("a-divider"),
      _vm.user.loginType != 0
        ? _c(
            "a-form-model",
            {
              staticClass: "clearfix",
              attrs: { model: _vm.params },
              on: { submit: _vm.handleSubmit },
            },
            [
              _c(
                "a-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "工程管理部",
                        labelCol: { span: 9 },
                        wrapperCol: { span: 14, offset: 1 },
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: { allowClear: "", placeholder: "请选择" },
                          model: {
                            value: _vm.params.gcglbId,
                            callback: function ($$v) {
                              _vm.$set(_vm.params, "gcglbId", $$v)
                            },
                            expression: "params.gcglbId",
                          },
                        },
                        _vm._l(_vm.gcglbList, function (item) {
                          return _c(
                            "a-select-option",
                            {
                              key: item.value,
                              attrs: { value: item.value, title: item.name },
                            },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "项目名称",
                        labelCol: { span: 8 },
                        wrapperCol: { span: 14, offset: 1 },
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          placeholder: "请输入项目名称",
                          allowClear: "",
                        },
                        model: {
                          value: _vm.params.projectName,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "projectName", $$v)
                          },
                          expression: "params.projectName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "a-space",
                    { staticClass: "btnBox" },
                    [
                      _c(
                        "a-button",
                        { attrs: { type: "primary", "html-type": "submit" } },
                        [_vm._v(" 查询 ")]
                      ),
                      _c("a-button", { on: { click: _vm.resetQuery } }, [
                        _vm._v(" 重置 "),
                      ]),
                      _vm.user.isGcglb == 0 &&
                      _vm.user.loginType == 1 &&
                      _vm.user.nowRoleKey.includes("JS_LJJ")
                        ? _c(
                            "a-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.doDowload },
                            },
                            [_vm._v(" 导出 ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.user.isGcglb == 1
        ? _c(
            "a-button",
            { attrs: { type: "primary" }, on: { click: _vm.batchConfirm } },
            [_vm._v("批量确认")]
          )
        : _vm._e(),
      _c("standard-table", {
        attrs: {
          columns: _vm.columns,
          dataSource: _vm.tableData,
          rowKey: "id",
          scroll: { x: 1300 },
          pagination: _vm.pagination,
          loading: _vm.tableLoading,
          selectedRows: _vm.selectedRows,
          isAlert: false,
          bordered: true,
        },
        on: {
          "update:selectedRows": [
            function ($event) {
              _vm.selectedRows = $event
            },
            function ($event) {
              return _vm.updateSelectRows($event)
            },
          ],
          "update:selected-rows": function ($event) {
            _vm.selectedRows = $event
          },
          change: _vm.handleChangePage,
        },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function (ref) {
              var index = ref.index
              return [
                index < _vm.tableData.length - 1
                  ? _c("span", [_vm._v(_vm._s(_vm.itemNo(index)))])
                  : _c("span", [_vm._v("合计")]),
              ]
            },
          },
          {
            key: "status",
            fn: function (ref) {
              var text = ref.text
              return [_c("span", [_vm._v(_vm._s(_vm._f("statusName")(text)))])]
            },
          },
          {
            key: "problems",
            fn: function (ref) {
              var index = ref.index
              var record = ref.record
              return _vm._l(record.problems, function (problem, index) {
                return _c("div", { key: index }, [_vm._v(_vm._s(problem))])
              })
            },
          },
          {
            key: "action",
            fn: function (ref) {
              var index = ref.index
              var record = ref.record
              return _c("div", {}, [
                _c(
                  "a",
                  {
                    on: {
                      click: function () {
                        return _vm.handleEdit(record)
                      },
                    },
                  },
                  [
                    _vm.user.nowRoleKey.includes("PROJECT_AQY_ZB_FZR")
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              index !== _vm.tableData.length - 1 ? "编辑" : ""
                            )
                          ),
                        ])
                      : _c("span", [
                          _vm._v(
                            _vm._s(
                              index !== _vm.tableData.length - 1 ? "查看" : ""
                            )
                          ),
                        ]),
                  ]
                ),
                record.status == 0 &&
                _vm.user.isGcglb == 1 &&
                _vm.user.loginType == 1
                  ? _c(
                      "a",
                      {
                        staticStyle: { color: "red" },
                        on: {
                          click: function () {
                            return _vm.handleConfirm(record)
                          },
                        },
                      },
                      [_vm._v(" 确认 ")]
                    )
                  : _vm._e(),
              ])
            },
          },
        ]),
      }),
      _c("Export-loading", {
        attrs: {
          cPercent: _vm.cPercent,
          isExporting: _vm.isExporting,
          loadingText: _vm.loadingText,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }