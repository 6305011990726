import { render, staticRenderFns } from "./projectzyyh.vue?vue&type=template&id=18c3bbce&scoped=true&"
import script from "./projectzyyh.vue?vue&type=script&lang=js&"
export * from "./projectzyyh.vue?vue&type=script&lang=js&"
import style0 from "./projectzyyh.vue?vue&type=style&index=0&id=18c3bbce&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18c3bbce",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\admin\\Desktop\\SJ\\【劳务机施】suijian_client\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('18c3bbce')) {
      api.createRecord('18c3bbce', component.options)
    } else {
      api.reload('18c3bbce', component.options)
    }
    module.hot.accept("./projectzyyh.vue?vue&type=template&id=18c3bbce&scoped=true&", function () {
      api.rerender('18c3bbce', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/safeview/projectzyyh.vue"
export default component.exports