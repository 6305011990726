<template>
  <div class="a_Approval">
    <div class="plug-Overview">建筑起重机械汇总表</div>
    <a-divider />
    <div>
      <span style="color: #ff718b">{{warningTotal}}台设备即将到期/到期设备</span>
      <span class="ml-2" style="color: #083af0; cursor: pointer" @click="goCheck">查看<a-icon type="arrow-right"
      /></span>
    </div>
    <!-- 表单封装 
            projectObj：下拉弹出框第一项对象
            Overview：项目名称
            baiduMapBoolen：控制地图显示
            topSubmit：控制上部分按钮显示
            bottomSubmit：控制下部分按钮显示
            handleSubmit：表单提交
            handleChange：输入出发
            handlSelect：输入下拉选择触发
        -->
    <form-index
      ref="formindex"
      :formData="formData"
      flexAction
      :baiduMapBoolen="false"
      @handleSubmit="handleSubmit"
    >
      <!-- 按钮插槽 -->
      <template #flexAction>
        <a-space class="btnBox">
          <a-button type="primary" html-type="submit">查询</a-button>
        </a-space>
      </template>
    </form-index>

    <!-- 表格封装 -->
    <standard-table
      :columns="columns"
      :dataSource="dataSource"
      rowKey="projectId"
      :pagination="pagination"
      :loading="tableLoading"
      @change="onPageChange"
      :isAlert="false"
      :bordered="true"
      :btnArr="tableBtnArr"
      :scroll="{ x: 1800 }"
    >
      <template slot="tableTitle">
        <div>
          总计：塔式起重机：{{towerNums}}；    施工升降机：{{upDownNums}}；    物料提升机：{{goodsNums}}；   门式起重机：{{doorNums}}；   桥式起重机：{{bridgeNums}}；    总台数：{{totalMachine}}；
        </div>
      </template>
      <template slot="index" slot-scope="{ index }">
        <span>
          {{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}
        </span>
      </template>
    </standard-table>
    <Export-loading
      :cPercent="cPercent"
      :isExporting="isExporting"
      :loadingText="loadingText"
    ></Export-loading>
  </div>
</template>

<script>
// AQXJPROJECTSGETLIST
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import { getFacilityStaticList, getFacilityStaticTotal, facilityInfoWarningTotal } from "@/services/equipmentLedger";
import { request } from "@/utils/request";
import { exportProgress } from "@/pages/aqjyzl/export"; // 导出方法
import ExportLoading from "@/components/ExportLoading/ExportLoading"; // progress组件
import { QUERY_OFFICE_BY_USER, EXPORT_SPLIT_FACILITY } from "@/services/api";

const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
  },
  {
    title: "工程名称",
    dataIndex: "projectName",
    width: 250,
    ellipsis: true,
    tooltip: true
  },
  {
    title: "工程管理部",
    dataIndex: "gcglbName",
    width: 150,
    align: "center",
    // ellipsis: true,
    scopedSlots: { customRender: "gcglbName" },
  },
  {
    title: "塔式起重机",
    align: "center",
    children: [
      {
        title: "使用中",
        dataIndex: "towerUsingNum",
        align: "center",
        width: 95,
      },
      {
        title: "取证中",
        dataIndex: "towerGiveNum",
        align: "center",
        width: 95,
      },
      {
        title: "待安装",
        dataIndex: "towerWaitNum",
        align: "center",
        width: 95,
      },
      {
        title: "停用",
        dataIndex: "towerStopNum",
        align: "center",
        width: 95,
      },
    ],
  },
  {
    title: "施工升降机",
    align: "center",
    children: [
      {
        title: "使用中",
        dataIndex: "upDownUsingNum",
        align: "center",
        width: 95,
      },
      {
        title: "取证中",
        dataIndex: "upDownGiveNum",
        align: "center",
        width: 95,
      },
      {
        title: "待安装",
        dataIndex: "upDownWaitNum",
        align: "center",
        width: 95,
      },
      {
        title: "停用",
        dataIndex: "upDownStopNum",
        align: "center",
        width: 95,
      },
    ],
  },
  {
    title: "物料提升机",
    align: "center",
    children: [
      {
        title: "使用中",
        dataIndex: "goodsUsingNum",
        align: "center",
        width: 95,
      },
      {
        title: "取证中",
        dataIndex: "goodsGiveNum",
        align: "center",
        width: 95,
      },
      {
        title: "待安装",
        dataIndex: "goodsWaitNum",
        align: "center",
        width: 95,
      },
      {
        title: "停用",
        dataIndex: "goodsStopNum",
        align: "center",
        width: 95,
      },
    ],
  },
  {
    title: "门式起重机",
    align: "center",
    children: [
      {
        title: "使用中",
        dataIndex: "doorUsingNum",
        align: "center",
        width: 95,
      },
      {
        title: "取证中",
        dataIndex: "doorGiveNum",
        align: "center",
        width: 95,
      },
      {
        title: "待安装",
        dataIndex: "doorWaitNum",
        align: "center",
        width: 95,
      },
      {
        title: "停用",
        dataIndex: "doorStopNum",
        align: "center",
        width: 95,
      },
    ],
  },
  {
    title: "桥式起重机",
    align: "center",
    children: [
      {
        title: "使用中",
        dataIndex: "bridgeUsingNum",
        align: "center",
        width: 95,
      },
      {
        title: "取证中",
        dataIndex: "bridgeGiveNum",
        align: "center",
        width: 95,
      },
      {
        title: "待安装",
        dataIndex: "bridgeWaitNum",
        align: "center",
        width: 95,
      },
      {
        title: "停用",
        dataIndex: "bridgeStopNum",
        align: "center",
        width: 95,
      },
    ],
  },
];
const formData = [
  {
    label: "工程名称",
    placeholder: "请选择工程名称",
    decorator: [
      "projectName",
      { rules: [{ required: false, message: "请选择工程名称" }] },
    ],
    type: "input",
    key: "projectName",
    col: 5,
    labelCol: 8,
    wrapperCol: 14,
  },
  {
    label: '工程管理部',
    placeholder: '请选择工程管理部',
    decorator: ['gcglbId', { rules: [{ required: false, message: '请选择工程管理部' }] }],
    type: 'select',
    key: 'gcglbId',
    selectlist: [],
    qb:true,
    col:5,
    labelCol: 8,
    wrapperCol:14,
  },
  {
    type: 'action',
    col: 2
  }
];
export default {
  mixins: [exportProgress], //导出方法注入
  components: {
    formIndex, //form表单封装
    StandardTable, //表格封装
    ExportLoading, //导出数据加载封装
  },
  data() {
    return {
      columns: columns, //表格数据
      dataSource: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      tableLoading: false,
      formData: formData,
      prams: {
        pageNumber: 1,
        pageSize: 10,
      },
      loadingText: "数据加载中...",
      cPercent: 0,
      isExporting: false,
      towerNums: 0,
      goodsNums: 0,
      bridgeNums: 0,
      doorNums: 0,
      upDownNums: 0,
      warningTotal: 0,
      totalMachine: 0,
      tableBtnArr: [
        {
            name: '导出',
            attrs: {
                type: 'primary'
            },
            click: this.doDowload
        }
      ]
    };
  },
  watch: {
    loadingText: {
      handler(newName, oldName) {
        this.loadingText = newName;
      },
      immediate: true,
    },
  },
  activated() {
    this.getgcglbList(QUERY_OFFICE_BY_USER);
    this.aqxjlist(this.prams);
    this.getTotal(this.prams);
    this.getWarningTotal(this.prams);
  },
  methods: {
    /**
     * 导出excel
     */
    doDowload() {
      let obj = this.$refs["formindex"].getFieldValue();
      this.loadingText = "正在导出EXCEL文件，请稍候 ····";
      this.isExporting = true;
      this.cPercent = 0;
      let fileName = "建筑起重机械汇总表.xlsx";
      this.exportFunNomal2(
        EXPORT_SPLIT_FACILITY,
        obj,
        fileName,
        "application/vnd.ms-excel"
      );
    },
    aqxjlist(prams) {
      this.tableLoading = true;
      getFacilityStaticList(prams).then((res) => {
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
        }
        this.tableLoading = false;
      });
    },
    getTotal(prams) {
      getFacilityStaticTotal(prams).then((res) => {
        if (res.data.code === 0) {
          this.goodsNums = res.data.data.goodsNums
          this.towerNums = res.data.data.towerNums
          this.doorNums = res.data.data.doorNums
          this.bridgeNums = res.data.data.bridgeNums
          this.upDownNums = res.data.data.upDownNums
          this.totalMachine = res.data.data.total
        }
      });
    },
    getWarningTotal(prams) {
      facilityInfoWarningTotal(prams).then((res) => {
        if (res.data.code === 0) {
          this.warningTotal = res.data.data
        }
      });
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      let arr = [];
      data.forEach((item) => {
        arr.push({ name: item.master, value: item.id });
      });
      this.formData.forEach((item) => {
        if (item.key == key) {
          item.selectlist = arr;
        }
      });
    },
    // 获取工程管理部数据
    getgcglbList(path) {
      request(path, "get").then((res) => {
        let data = res.data.data.filter(
          (item) => item.id != "3da0f5533f87425c9a9e8a11a7923300"
        );
        this.formDataFn("gcglbId", data);
      });
    },
    // 提交表单数据
    handleSubmit(e) {
      this.prams.pageNumber = 1
      this.pagination.current = 1;
      let obj = Object.assign({}, e, this.prams);
      this.aqxjlist(obj);
      this.getTotal(obj)
    },
    //表格 - 分页查询
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      this.prams.pageNumber = e.current;
      this.pagination.current = e.current;
      this.aqxjlist(Object.assign({}, this.prams, obj));
      this.getTotal(Object.assign({}, this.prams, obj))
    },
    goCheck() {
      this.$router.push({
        path: "/warning_equipment_table",
      });
    }
  },
};
</script>

<style lang="less" scoped>

/deep/ .ant-table-thead > tr > th {
  font-weight: bold;
}

.c_i_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 25px;

  .c_i_h_center {
    padding: 16px;
    border-radius: 4px;
    width: 180px;
    height: 130px;
    margin-right: 50px;

    div:nth-child(1) {
      font-size: 16px;
    }

    div:nth-child(2) {
      margin-top: 26px;
      font-size: 24px;
    }
  }
}

.sTable {
  display: flex;
  justify-content: space-between;
  align-items: center;

  // div {
  //     width: 100%;
  //     color: blue;
  //     text-align: center;
  //     cursor: pointer;
  // }
}
</style>
