var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: ["step-item", _vm.link ? "linkable" : null],
      on: { click: _vm.go },
    },
    [
      _c("span", { style: _vm.titleStyle }, [_vm._v(_vm._s(_vm.title))]),
      _vm.icon
        ? _c("a-icon", { style: _vm.iconStyle, attrs: { type: _vm.icon } })
        : _vm._e(),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }