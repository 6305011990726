var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a_Approval" },
    [
      _c("div", { staticClass: "plug-Overview" }, [_vm._v("安全责任管理")]),
      _c("a-divider"),
      _c(
        "a-spin",
        { attrs: { spinning: _vm.spinning, tip: _vm.loadingText } },
        [
          _c(
            "div",
            { class: _vm.advanced ? "search" : null },
            [
              _vm.user.loginType == 1
                ? _c("a-form", { staticClass: "clearfix" }, [
                    _c(
                      "div",
                      { class: _vm.advanced ? null : "fold" },
                      [
                        _c(
                          "a-col",
                          { attrs: { span: 5 } },
                          [
                            _c(
                              "a-form-item",
                              {
                                attrs: {
                                  label: "所属单位",
                                  labelCol: { span: 8 },
                                  wrapperCol: { span: 14, offset: 1 },
                                },
                              },
                              [
                                _c(
                                  "a-select",
                                  {
                                    attrs: {
                                      allowClear: "",
                                      placeholder: "请选择单位",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.handlSelect($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.queryParams.officeId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryParams,
                                          "officeId",
                                          $$v
                                        )
                                      },
                                      expression: "queryParams.officeId",
                                    },
                                  },
                                  [
                                    _c(
                                      "a-select-option",
                                      { attrs: { value: "" } },
                                      [_vm._v("全部")]
                                    ),
                                    _vm._l(_vm.options, function (item) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: item.id,
                                          attrs: { value: item.id },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.master || item.name) +
                                              " "
                                          ),
                                        ]
                                      )
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { span: 5 } },
                          [
                            _c(
                              "a-form-item",
                              {
                                attrs: {
                                  label: "项目名称",
                                  labelCol: { span: 8 },
                                  wrapperCol: { span: 14, offset: 1 },
                                },
                              },
                              [
                                _c(
                                  "a-select",
                                  {
                                    attrs: {
                                      allowClear: "",
                                      "show-search": "",
                                      placeholder: "请输入或选择项目",
                                      "option-filter-prop": "children",
                                      "filter-option": _vm.filterOption,
                                    },
                                    on: {
                                      change: _vm.handleChange,
                                      search: _vm.handleSearch,
                                      focus: _vm.handleFocus,
                                      blur: _vm.handleBlur,
                                    },
                                    model: {
                                      value: _vm.queryParams.projectId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryParams,
                                          "projectId",
                                          $$v
                                        )
                                      },
                                      expression: "queryParams.projectId",
                                    },
                                  },
                                  _vm._l(_vm.projectDatas, function (item) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: item.projectId,
                                        attrs: {
                                          value: item.projectId,
                                          title: item.projectName,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.projectName) + " "
                                        ),
                                      ]
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { span: 5 } },
                          [
                            _c(
                              "a-form-item",
                              {
                                attrs: {
                                  label: "查询人员",
                                  labelCol: { span: 8 },
                                  wrapperCol: { span: 14, offset: 1 },
                                },
                              },
                              [
                                _c(
                                  "a-select",
                                  {
                                    attrs: {
                                      "show-search": "",
                                      allowClear: "",
                                      placeholder: "请输入或选择",
                                      "option-filter-prop": "children",
                                      "filter-option": _vm.filterOption,
                                    },
                                    on: {
                                      change: _vm.handleUserChange,
                                      focus: _vm.handleFocus,
                                      blur: _vm.handleBlur,
                                    },
                                    model: {
                                      value: _vm.queryParams.userId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.queryParams, "userId", $$v)
                                      },
                                      expression: "queryParams.userId",
                                    },
                                  },
                                  _vm._l(_vm.userSafeList, function (item) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: item.id,
                                        attrs: {
                                          value: item.id,
                                          title: item.name,
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(item.name) + " ")]
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { span: 5 } },
                          [
                            _c(
                              "a-space",
                              { staticClass: "btnBox" },
                              [
                                _c(
                                  "a-checkbox",
                                  {
                                    attrs: { checked: _vm.multiOpen },
                                    on: { change: _vm.onMulti },
                                  },
                                  [_vm._v(" 多项目履职人员 ")]
                                ),
                                _c(
                                  "a-checkbox",
                                  {
                                    attrs: { checked: _vm.exceedOpen },
                                    on: { change: _vm.onExceed },
                                  },
                                  [_vm._v(" 未上传项目 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { span: 4 } },
                          [
                            _c(
                              "a-space",
                              { staticClass: "btnBox" },
                              [
                                _c(
                                  "a-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Query()
                                      },
                                    },
                                  },
                                  [_vm._v("查询")]
                                ),
                                _c(
                                  "a-button",
                                  {
                                    staticStyle: { "margin-left": "8px" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.doReset()
                                      },
                                    },
                                  },
                                  [_vm._v("重置")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("standard-table", {
                staticClass: "headerTablestyle",
                attrs: {
                  bordered: true,
                  columns: _vm.columns,
                  dataSource: _vm.dataSource,
                  pagination: _vm.pagination,
                  loading: _vm.tableLoading,
                },
                on: {
                  change: function ($event) {
                    return _vm.onPageChange($event)
                  },
                  clear: _vm.onClear,
                },
                scopedSlots: _vm._u([
                  {
                    key: "index",
                    fn: function (ref) {
                      var index = ref.index
                      return [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                (_vm.pagination.current - 1) *
                                  _vm.pagination.pageSize +
                                  parseInt(index) +
                                  1
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "gcName",
                    fn: function (ref) {
                      var text = ref.text
                      return _c("div", {}, [
                        text != null && text != ""
                          ? _c("div", [
                              _vm._v(
                                " " + _vm._s(text || _vm.fruitLoader) + " "
                              ),
                            ])
                          : _c("span"),
                      ])
                    },
                  },
                  {
                    key: "Name",
                    fn: function (ref) {
                      var text = ref.text
                      var record = ref.record
                      return [
                        _c(
                          "a-tooltip",
                          { attrs: { placement: "topLeft" } },
                          [
                            _c("template", { slot: "title" }, [
                              text != null && text != ""
                                ? _c(
                                    "span",
                                    {
                                      class:
                                        record.status == 4 || record.status == 1
                                          ? "colorRed"
                                          : "",
                                      attrs: { title: text },
                                    },
                                    [_vm._v(" " + _vm._s(text) + " ")]
                                  )
                                : _vm._e(),
                            ]),
                            _c("div", { staticClass: "overtext-1" }, [
                              text != null && text != ""
                                ? _c(
                                    "span",
                                    {
                                      class:
                                        record.status == 4 || record.status == 1
                                          ? "colorRed"
                                          : "",
                                      attrs: { title: text },
                                    },
                                    [_vm._v(" " + _vm._s(text) + " ")]
                                  )
                                : _vm._e(),
                            ]),
                          ],
                          2
                        ),
                      ]
                    },
                  },
                  {
                    key: "do-status",
                    fn: function (ref) {
                      var text = ref.text
                      return _c("div", {}, [
                        text != "" && text != null
                          ? _c(
                              "div",
                              [
                                _vm.icludesPdf(text, _vm.str) == -1
                                  ? [
                                      text != "" && text != null
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "40px",
                                              height: "40px",
                                              cursor: "pointer",
                                            },
                                            attrs: { src: text },
                                            on: {
                                              click: function ($event) {
                                                return _vm.showImg(text)
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  : _vm.icludesPdf(text, _vm.str) != -1
                                  ? [
                                      _c("a-icon", {
                                        style: { fontSize: "35px" },
                                        attrs: { type: "file-pdf" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.showPdf(text)
                                          },
                                        },
                                      }),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm._e(),
                      ])
                    },
                  },
                  {
                    key: "do-userList",
                    fn: function (ref) {
                      var text = ref.text
                      var record = ref.record
                      return _c(
                        "div",
                        {},
                        [
                          _c(
                            "a-popover",
                            { attrs: { placement: "bottom" } },
                            [
                              _c(
                                "template",
                                { slot: "content" },
                                _vm._l(
                                  record.userNames.split(","),
                                  function (item, key) {
                                    return _c("p", { key: key }, [
                                      _vm._v(" " + _vm._s(item) + " "),
                                    ])
                                  }
                                ),
                                0
                              ),
                              _c("span", [
                                _vm._v(_vm._s(_vm._f("subText")(text))),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    },
                  },
                  {
                    key: "xmleader",
                    fn: function (ref) {
                      var record = ref.record
                      return _c(
                        "div",
                        {},
                        _vm._l(record.xmList, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "aqyBorder",
                              class: [_vm.active == item.cnt ? "" : "colorRes"],
                            },
                            [
                              _c("div", [_vm._v(_vm._s(item.userName))]),
                              item.phone
                                ? _c("span", [
                                    _vm._v("(" + _vm._s(item.phone) + ")"),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      )
                    },
                  },
                  {
                    key: "appointDate-aqy",
                    fn: function (ref) {
                      var record = ref.record
                      return _c(
                        "div",
                        {},
                        _vm._l(record.detailList, function (item, index) {
                          return _c(
                            "div",
                            { key: index, class: _vm.setClass(item) },
                            [
                              _c(
                                "div",
                                { staticStyle: { height: "42px" } },
                                [
                                  item.userPhone != 13924219200
                                    ? [
                                        item.appointDate
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  height: "42px",
                                                  "line-height": "42px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.useRender.renderDate(
                                                        item.appointDate
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          )
                        }),
                        0
                      )
                    },
                  },
                  {
                    key: "xmaqy",
                    fn: function (ref) {
                      var record = ref.record
                      return _c(
                        "div",
                        {},
                        _vm._l(record.detailList, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              class: _vm.setClass(item, record.zzaqList),
                            },
                            [
                              item.userPhone != 13924219200
                                ? _c("div", [
                                    _c("div", [_vm._v(_vm._s(item.userName))]),
                                    item.userPhone
                                      ? _c("span", [
                                          _vm._v(
                                            "(" + _vm._s(item.userPhone) + ")"
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      )
                    },
                  },
                  {
                    key: "do-aqyList",
                    fn: function (ref) {
                      var record = ref.record
                      return _c(
                        "div",
                        {},
                        _vm._l(record.detailList, function (item, index) {
                          return _c(
                            "div",
                            { key: index, class: _vm.setClass(item) },
                            [
                              _c("div", { staticStyle: { height: "42px" } }, [
                                item.zzrmsSafe != "" && item.zzrmsSafe != null
                                  ? _c(
                                      "div",
                                      [
                                        _vm.icludesPdf(
                                          item.zzrmsSafe,
                                          _vm.str
                                        ) == -1
                                          ? [
                                              item.zzrmsSafe != "" &&
                                              item.zzrmsSafe != null
                                                ? _c("img", {
                                                    staticStyle: {
                                                      width: "40px",
                                                      height: "40px",
                                                      cursor: "pointer",
                                                    },
                                                    attrs: {
                                                      src: item.zzrmsSafe,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.showImg(
                                                          item.zzrmsSafe
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          : _vm.icludesPdf(
                                              item.zzrmsSafe,
                                              _vm.str
                                            ) != -1
                                          ? [
                                              _c("a-icon", {
                                                style: { fontSize: "35px" },
                                                attrs: { type: "file-pdf" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.showPdf(
                                                      item.zzrmsSafe
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          )
                        }),
                        0
                      )
                    },
                  },
                  {
                    key: "do-aqyList-aqzrs",
                    fn: function (ref) {
                      var record = ref.record
                      return _c(
                        "div",
                        {},
                        _vm._l(record.detailList, function (item, index) {
                          return _c(
                            "div",
                            { key: index, class: _vm.setClass(item) },
                            [
                              _c("div", { staticStyle: { height: "42px" } }, [
                                item.aqzrsSafe != "" && item.aqzrsSafe != null
                                  ? _c(
                                      "div",
                                      [
                                        _vm.icludesPdf(
                                          item.aqzrsSafe,
                                          _vm.str
                                        ) == -1
                                          ? [
                                              item.aqzrsSafe != "" &&
                                              item.aqzrsSafe != null
                                                ? _c("img", {
                                                    staticStyle: {
                                                      width: "40px",
                                                      height: "40px",
                                                      cursor: "pointer",
                                                    },
                                                    attrs: {
                                                      src: item.aqzrsSafe,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.showImg(
                                                          item.aqzrsSafe
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          : _vm.icludesPdf(
                                              item.aqzrsSafe,
                                              _vm.str
                                            ) != -1
                                          ? [
                                              _c("a-icon", {
                                                style: { fontSize: "35px" },
                                                attrs: { type: "file-pdf" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.showPdf(
                                                      item.aqzrsSafe
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          )
                        }),
                        0
                      )
                    },
                  },
                  {
                    key: "action",
                    fn: function (ref) {
                      var record = ref.record
                      return [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.doEdit(record)
                              },
                            },
                          },
                          [_vm._v(" 编辑 ")]
                        ),
                      ]
                    },
                  },
                  {
                    key: "statusTitle",
                    fn: function (ref) {
                      var text = ref.text
                      var record = ref.record
                      return [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.showPersons(record)
                              },
                            },
                          },
                          [_vm._v(_vm._s(text))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]
      ),
      _c("modify-duty", {
        attrs: {
          visible: _vm.duty.visble,
          duty: _vm.duty.duty,
          userList: _vm.userList,
        },
        on: {
          "update:duty": function ($event) {
            return _vm.$set(_vm.duty, "duty", $event)
          },
          coursePageClose: _vm.dutyClose,
          dutySubmit: _vm.dutySubmit,
          doEdit: _vm.doEdit,
        },
      }),
      _c(
        "a-modal",
        {
          attrs: {
            destroyOnClose: true,
            visible: _vm.modalVisible,
            footer: null,
          },
          on: { cancel: _vm.handleCancel },
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { alt: "example", src: _vm.previewImage },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }