import { request, METHOD } from '@/utils/request'
import { ADMIN } from '@/services/api.js'

/**
 *  标准化考评-列表
 */
export async function assessmentPage(params) {
    return request(`${ADMIN}/safe/stand-assessment/page`, METHOD.POST, params)
}

/**
 *  标准化考评-导入
 */
export async function assessmentImport(params) {
    return request(`${ADMIN}/safe/stand-assessment/import-excel`, METHOD.POST, params)
}

/**
 *  标准化考评-删除
 */
export async function assessmentDelete(id) {
    return request(`${ADMIN}/safe/stand-assessment/delete`, METHOD.DELETE, { id })
}

/**
 *  标准化考评-新增
 */
export async function assessmentCreate(params) {
    return request(`${ADMIN}/safe/stand-assessment/create`, METHOD.POST, params)
}

/**
 *  标准化考评-编辑更新
 */
export async function assessmentUpdate(params) {
    return request(`${ADMIN}/safe/stand-assessment/update`, METHOD.PUT, params)
}

/**
 *  标准化考评-获取详情
 */
export async function assessmentDetail(id) {
    return request(`${ADMIN}/safe/stand-assessment/get`, METHOD.GET, { id })
}