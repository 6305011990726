<template>
  <div>
    <standard-table
      class=""
      :columns="columns"
      :dataSource="dataSource"
      rowKey="id"
      :pagination="pagination"
      :loading="tableLoading"
      :isAlert="false"
      :bordered="true"
    >
      <template slot="index" slot-scope="{ index }">
        <span>
          {{
            (pagination.current - 1) * pagination.pageSize +
              parseInt(index) +
              1
          }}
        </span>
      </template>
    </standard-table>
  </div>
</template>

<script>
import StandardTable from "@/components/table/StandardTable";
import { useRender } from '@/hooks/useRender.js'
const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
  },
  {
    title: "姓名",
    dataIndex: "staffName",
    align: "center",
    scopedSlots: { customRender: "staffName" },
  },
  {
    title: "身份证号",
    dataIndex: "filePath",
    align: "center",
    scopedSlots: { customRender: "filePath" },
  },
  {
    title: "所属单位",
    dataIndex: "age",
    align: "center",
    scopedSlots: { customRender: "age" },
  },
  {
    title: "所属班组",
    dataIndex: "idCard",
    align: "center",
    scopedSlots: { customRender: "idCard" },
  },
  {
    title: "伤病类型",
    dataIndex: "riskTips",
    align: "center",
    ellipsis: true,
    key: "riskTips",
  },
  {
    title: "工伤等级",
    dataIndex: "applyDate",
    align: "center",
    key: "applyDate",
  },
  {
    title: "最后一次体检时间",
    dataIndex: "applyDate",
    align: "center",
    key: "applyDate",
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "登记日期",
    dataIndex: "applyDate",
    align: "center",
    key: "applyDate",
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "附件",
    dataIndex: "applyDate",
    align: "center",
    key: "applyDate",
  },
  {
    title: "健康备注",
    dataIndex: "applyDate",
    align: "center",
    key: "applyDate",
  },
  {
    title: "操作",
    align: "center",
    fixed: "right",
    width: 200,
    scopedSlots: { customRender: "action" },
  },
];
export default {
  components: {
    StandardTable
  },
  data() {
    return {
      columns: columns, //table数据key对应
      dataSource: [], //table数组
      pagination: {
        //分页数据
        current: 1,
        pageSize: 10,
        total: 0,
      },
      tableLoading: false, //table加载中
      selectedRows: [], //选中的数据
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>

<style lang="less" scoped>

</style>