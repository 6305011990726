<template>
    <div>
      <a-drawer
        title="编辑模块消息设置"
        :width="820"
        :visible="visible"
        :body-style="{ paddingBottom: '80px' }"
        @close="onClose"
      >
        <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }" layout="horizontal">
          <a-row :gutter="16">
            <a-col :span="24">
              <a-form-item label="模块名称：">
                <a-input
                  v-decorator="['moduleName', { rules: [{ required: true, message: '请输入模块名称！' }] }]"
                 placeholder="请输入模块名称"/>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :span="24">
              <a-form-item label="模块编号：">
                <a-input
                  v-decorator="['moduleCode', { rules: [{ required: true, message: '请输入模块编号！' }] }]"
                 placeholder="请输入模块编号（请确保编号的保持唯一）"/>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item   label="发送方式：">
              <a-select
                :allowClear="true"
                mode="multiple"
                style="width: 100%"
                placeholder="请选择使用的发送方式（可多选）"
                v-decorator="['types']">
                <a-select-option v-for="t in sendTypes" :key="t.key" :value="t.key">{{t.text}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
          <a-row :gutter="16">
            <a-col :span="24">
              <a-form-item label="发送平台：">
                 <a-select
                  :allowClear="true"
                  style="width: 100%"
                  placeholder="发送平台"
                  v-decorator="['sysClient']">
                   <a-select-option key="1" :value="1">
                      建工一号
                  </a-select-option>
                  <a-select-option key="2" :value="2">
                      建工管理
                  </a-select-option>
                  <a-select-option key="3" :value="3">
                      建工管理（电脑平台）
                  </a-select-option>
                  <a-select-option key="4" :value="4">
                      其他
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="16" style="margin-left:11%">
            <a-col :span="12">
              <a-form-item label="有效状态：">
                <a-switch checked-children="开" un-checked-children="关" v-decorator="['statusBoolean', { valuePropName: 'checked' }]"/>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="12">
            <a-col :span="24">
              <a-form-item label="其他配置：">
                <a-textarea
                  v-decorator="['setDesc']"
                  :rows="4"
                  placeholder="请规范填写JSON格式"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="12">
            <a-col :span="24">
              <a-form-item label="备注说明：">
                <a-textarea
                  v-decorator="['remark']"
                  :rows="4"
                  placeholder="备注说明"
                />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
        <div
          :style="{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 1,
          }"
        >
          <a-button type="primary" @click="handleSubmit()">
            保存
          </a-button>
          <a-button :style="{ marginLeft: '8px' }" @click="onClose">
            取消
          </a-button>
        </div>
      </a-drawer>
    </div>
  </template>
  <script>
  import { ATTACHMENT_UPLOAD,FIND_ALL_OFFICE_LIST,FIND_PROJECT_BY_OFFICEID,GET_OFFICE_LIST } from "@/services/api";
  import { request } from "@/utils/request";
  // import Tinymce from "@/components/tinymce"
  
  
  export default {
    name: "setting",
    components: {},
    data() {
      return {
        form: this.$form.createForm(this),
        obj:{},
      };
    },
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      setObject: {
        type: Object,
        default() {
          return {}
        }
      }
    },
    created(){
        this.sendTypes = [];
        var contract = {
            key:'1',
            text:'小程序站内信'
        }
        this.sendTypes.push(contract)
        var EPIDEMIC_LETTER_COMMITMENT = {
            key:'2',
            text:'微信公众号'
        }
        this.sendTypes.push(EPIDEMIC_LETTER_COMMITMENT)

        var WORK_RCXZ_TEMPL = {
            key:'3',
            text:'微信企业号'
        }
        this.sendTypes.push(WORK_RCXZ_TEMPL)
        var THREE_AQJY_TEPL_ALL = {
            key:'4',
            text:'消息通知'
        }
        this.sendTypes.push(THREE_AQJY_TEPL_ALL)
        
    },
    mounted(){
    },
    watch:{
    visible(val){
        this.$nextTick(()=>{
          this.obj = this.setObject;
          if(!this.obj.id){
            this.form.resetFields("types");
          }
          this.form.setFieldsValue({
              'moduleName': this.obj.moduleName,
              'moduleCode': this.obj.moduleCode,
              'statusBoolean': this.obj.status==0?false:true,
              'setDesc': this.obj.setDesc,
              'remark': this.obj.remark,
              'sysClient':this.obj.sysClient?this.obj.sysClient:1,
          })
          if(this.obj.types){
            this.form.setFieldsValue({
              'types':this.obj.types?this.obj.types.split(','):"",
            })
          }
          
        })
      }
    },
    methods: {
      /**
       * 取消审批
       */
      onClose() {
        this.$emit('close');
      },
      filterOption(input, option) {
        return (
          option.componentOptions.children[0].text.indexOf(input) >= 0
        );
      },
      /**
       * 保存信息
       */
      handleSubmit(e){
        //数据校验
        this.form.validateFields((err, values) => {
          if (!err) {
            //保存结果数据
            
            if(values.types&&values.types.length>0){
                values.types = values.types.join(',');
            }
            Object.assign(this.obj,values);
            console.log(this.obj)
            this.$emit('submit',{templFile:this.obj})
          }
        });
      },
  
      
      
    },
  };
  </script>
  <style>
  .avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
  }
  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }
  
  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }
  /deep/ .ant-upload-list-picture-card .ant-upload-list-item{
    width: 127px;
    height: 127px;
  }
  </style>
  