var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("h2", [_vm._v("人员信息")]),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("div", { staticClass: "img-box" }, [
          _c("img", { attrs: { src: _vm.personInfo.photoPath, alt: "" } }),
          _c("p", [_vm._v(_vm._s(_vm.personInfo.staffName))]),
          _c("p", [_vm._v(_vm._s(_vm.personInfo.gender))]),
          _c("p", [_vm._v(_vm._s(_vm.personInfo.nation))]),
          _c("p", [_vm._v(_vm._s(_vm.personInfo.age) + "岁")]),
          _vm.personInfo.moreYears
            ? _c("p", { staticStyle: { color: "red" } }, [_vm._v("超龄")])
            : _vm._e(),
        ]),
        _c(
          "a-descriptions",
          { attrs: { bordered: "", column: 2 } },
          [
            _c("a-descriptions-item", { attrs: { label: "身份证号码：" } }, [
              _vm._v(_vm._s(_vm.personInfo.idCard)),
            ]),
            _c("a-descriptions-item", { attrs: { label: "手机号码：" } }, [
              _vm._v(_vm._s(_vm.personInfo.phone)),
            ]),
            _c("a-descriptions-item", { attrs: { label: "工种：" } }, [
              _vm._v(_vm._s(_vm.personInfo.workTypeName)),
            ]),
            _c("a-descriptions-item", { attrs: { label: "银行卡：" } }, [
              _vm._v(_vm._s(_vm.personInfo.bankCard)),
            ]),
            _c("a-descriptions-item", { attrs: { label: "民族：" } }, [
              _vm._v(_vm._s(_vm.personInfo.nation) + "族"),
            ]),
            _c("a-descriptions-item", { attrs: { label: "地区：" } }, [
              _vm._v(_vm._s(_vm.personInfo.homeAddress)),
            ]),
            _c("a-descriptions-item", { attrs: { label: "疾病史：" } }, [
              _vm._v("无"),
            ]),
            _c(
              "a-descriptions-item",
              { attrs: { label: "已在场的项目：" } },
              [
                _vm.personInfo.projectNames
                  ? _vm._l(
                      _vm.showNames(_vm.personInfo.projectNames),
                      function (item, index) {
                        return _c("div", { key: index }, [_vm._v(_vm._s(item))])
                      }
                    )
                  : _vm._e(),
              ],
              2
            ),
            _c("a-descriptions-item", { attrs: { label: "身份证人像：" } }, [
              _vm.personInfo.idCardFace
                ? _c("img", {
                    staticClass: "attachment-img",
                    attrs: { src: _vm.personInfo.idCardFace, alt: "" },
                  })
                : _c("span", [_vm._v("暂未上传")]),
            ]),
            _c("a-descriptions-item", { attrs: { label: "身份证国徽：" } }, [
              _vm.personInfo.idCardBack
                ? _c("img", {
                    staticClass: "attachment-img",
                    attrs: { src: _vm.personInfo.idCardBack, alt: "" },
                  })
                : _c("span", [_vm._v("暂未上传")]),
            ]),
            _c("a-descriptions-item", { attrs: { label: "银行卡图片：" } }, [
              _vm.personInfo.bank
                ? _c("img", {
                    staticClass: "attachment-img",
                    attrs: { src: _vm.personInfo.bank, alt: "" },
                  })
                : _c("span", [_vm._v("暂未上传")]),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }