var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a_Approval" },
    [
      _c("div", { staticClass: "plug-Overview" }, [
        _vm._v("安全隐患整改台账记录表"),
      ]),
      _c("a-divider"),
      _c(
        "a-spin",
        { attrs: { spinning: _vm.spinning, tip: _vm.loadingText } },
        [
          _c(
            "a-form",
            { staticClass: "clearfix" },
            [
              _c(
                "a-col",
                { attrs: { span: 2 } },
                [
                  _c(
                    "a-form-item",
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.queryParams.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "type", $$v)
                            },
                            expression: "queryParams.type",
                          },
                        },
                        [
                          _c("a-radio-button", { attrs: { value: 1 } }, [
                            _vm._v(" 月份 "),
                          ]),
                          _c("a-radio-button", { attrs: { value: 2 } }, [
                            _vm._v(" 年份 "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: "月份/年份",
                        labelCol: { span: 8 },
                        wrapperCol: { span: 14, offset: 1 },
                      },
                    },
                    [
                      _vm.queryParams.type == 1
                        ? _c(
                            "a-month-picker",
                            {
                              attrs: {
                                placeholder: "请选择月份",
                                "default-value": _vm.moment(
                                  _vm.monthDay,
                                  _vm.monthFormat
                                ),
                                format: _vm.monthFormat,
                              },
                              model: {
                                value: _vm.queryParams.plan_date,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryParams, "plan_date", $$v)
                                },
                                expression: "queryParams.plan_date",
                              },
                            },
                            [
                              _c("a-icon", {
                                attrs: { slot: "suffixIcon", type: "smile" },
                                slot: "suffixIcon",
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.queryParams.type == 2
                        ? _c("a-date-picker", {
                            attrs: {
                              mode: "year",
                              placeholder: "请输入年份",
                              "default-value": _vm.moment(
                                _vm.nowYear,
                                _vm.yearFormat
                              ),
                              format: _vm.yearFormat,
                              open: _vm.yearShowOne,
                            },
                            on: {
                              openChange: _vm.openChangeOne,
                              panelChange: _vm.panelChangeOne,
                            },
                            model: {
                              value: _vm.queryParams.plan_date,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "plan_date", $$v)
                              },
                              expression: "queryParams.plan_date",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: "检查部门",
                        labelCol: { span: 8 },
                        wrapperCol: { span: 14, offset: 1 },
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          model: {
                            value: _vm.queryParams.qtType,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "qtType", $$v)
                            },
                            expression: "queryParams.qtType",
                          },
                        },
                        [
                          _c("a-select-option", { attrs: { value: 0 } }, [
                            _vm._v("所有"),
                          ]),
                          _c("a-select-option", { attrs: { value: 1 } }, [
                            _vm._v("安全部"),
                          ]),
                          _c("a-select-option", { attrs: { value: 2 } }, [
                            _vm._v("工程管理部"),
                          ]),
                          _c("a-select-option", { attrs: { value: 3 } }, [
                            _vm._v("项目部"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 2 } },
                [
                  _c(
                    "a-space",
                    { staticClass: "btnBox" },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.doQueryBtn()
                            },
                          },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "standard-table",
                {
                  attrs: {
                    bordered: true,
                    columns: _vm.columns,
                    dataSource: _vm.dataSource,
                    isAlert: false,
                    pagination: _vm.pagination,
                    loading: _vm.tableLoading,
                    btnArr: _vm.tableBtnArr,
                  },
                  on: { clear: _vm.onClear, change: _vm.onPageChange },
                  scopedSlots: _vm._u([
                    {
                      key: "do-status",
                      fn: function (ref) {
                        var text = ref.text
                        return _c("div", {}, [
                          text == 2
                            ? _c(
                                "label",
                                [
                                  _c("a-icon", {
                                    staticStyle: {
                                      "font-size": "15px",
                                      color: "green",
                                    },
                                    attrs: { type: "check" },
                                  }),
                                ],
                                1
                              )
                            : text == 99
                            ? _c("label")
                            : _c(
                                "label",
                                [
                                  _c("a-icon", {
                                    staticStyle: {
                                      "font-size": "15px",
                                      color: "red",
                                    },
                                    attrs: { type: "close" },
                                  }),
                                ],
                                1
                              ),
                        ])
                      },
                    },
                  ]),
                },
                [
                  _c("div", { attrs: { slot: "zgxq" }, slot: "zgxq" }, [
                    _vm._v(" 已整改落实，详见整改回复单 "),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c("Export-loading", {
            attrs: {
              cPercent: _vm.cPercent,
              isExporting: _vm.isExporting,
              loadingText: _vm.loadingText,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }