var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c(
          "a-row",
          { attrs: { gutter: 24 } },
          [
            _c(
              "a-col",
              [
                _c(
                  "a-card",
                  { attrs: { bordered: false } },
                  [
                    _c(
                      "a-form",
                      { attrs: { form: _vm.queryParam } },
                      [
                        _c(
                          "a-row",
                          { attrs: { gutter: 24 } },
                          [
                            _c(
                              "a-col",
                              { attrs: { span: 4 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "账号",
                                      labelCol: { span: 6 },
                                      wrapperCol: { span: 17, offset: 1 },
                                    },
                                  },
                                  [
                                    _c("a-input", {
                                      attrs: { placeholder: "请输入手机号" },
                                      model: {
                                        value: _vm.queryParam.phone,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.queryParam, "phone", $$v)
                                        },
                                        expression: "queryParam.phone",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { span: 4 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "姓名",
                                      labelCol: { span: 6 },
                                      wrapperCol: { span: 17, offset: 1 },
                                    },
                                  },
                                  [
                                    _c("a-input", {
                                      attrs: { placeholder: "请输入姓名" },
                                      model: {
                                        value: _vm.queryParam.name,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.queryParam, "name", $$v)
                                        },
                                        expression: "queryParam.name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { span: 4 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "角色",
                                      labelCol: { span: 4 },
                                      wrapperCol: { span: 17, offset: 1 },
                                    },
                                  },
                                  [
                                    _c(
                                      "a-select",
                                      {
                                        attrs: {
                                          placeholder: "请选择",
                                          allowClear: true,
                                        },
                                        model: {
                                          value: _vm.queryParam.roleId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryParam,
                                              "roleId",
                                              $$v
                                            )
                                          },
                                          expression: "queryParam.roleId",
                                        },
                                      },
                                      _vm._l(
                                        _vm.newRole,
                                        function (item, index) {
                                          return _c(
                                            "a-select-option",
                                            {
                                              key: item.id,
                                              attrs: {
                                                index: index,
                                                value: item.roleId,
                                              },
                                            },
                                            [_vm._v(_vm._s(item.roleName))]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { span: 5 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "所属公司",
                                      labelCol: { span: 7 },
                                      wrapperCol: { span: 14, offset: 1 },
                                    },
                                  },
                                  [
                                    _c("a-tree-select", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        searchPlaceholder: "搜索公司",
                                        "dropdown-style": {
                                          maxHeight: "400px",
                                          overflow: "auto",
                                        },
                                        "tree-data": _vm.officeTreeData,
                                        placeholder: "请选择所属公司",
                                        replaceFields: {
                                          children: "children",
                                          title: "name",
                                          key: "id",
                                          value: "id",
                                        },
                                        allowClear: true,
                                        "show-search": true,
                                        "tree-node-filter-prop": "title",
                                      },
                                      on: { select: _vm.queryOfficeId },
                                      model: {
                                        value: _vm.queryParam.officeId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParam,
                                            "officeId",
                                            $$v
                                          )
                                        },
                                        expression: "queryParam.officeId",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              {
                                style: { marginTop: "3px" },
                                attrs: { span: 7 },
                              },
                              [
                                _c(
                                  "a-space",
                                  [
                                    _c(
                                      "a-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          icon: "search",
                                          loading: _vm.loading,
                                        },
                                        on: { click: _vm.handleSearch },
                                      },
                                      [_vm._v(" 查询 ")]
                                    ),
                                    _c(
                                      "a-space",
                                      { staticClass: "operator" },
                                      [
                                        _c(
                                          "a-button",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.reset()
                                              },
                                            },
                                          },
                                          [_vm._v(" 重置 ")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-button",
                                      {
                                        attrs: {
                                          icon: "plus",
                                          type: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addOffice(
                                              _vm.queryParam.officeId
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" 新增 ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "-25px" } },
                      [
                        _c("standard-table", {
                          attrs: {
                            columns: _vm.columns,
                            dataSource: _vm.officeData,
                            rowKey: "id",
                            pagination: _vm.queryParam,
                            loading: _vm.tableLoading,
                            bordered: true,
                          },
                          on: { change: _vm.onPageChange },
                          scopedSlots: _vm._u([
                            {
                              key: "index",
                              fn: function (ref) {
                                var index = ref.index
                                return [
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          (_vm.queryParam.current - 1) *
                                            _vm.queryParam.pageSize +
                                            parseInt(index) +
                                            1
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "action",
                              fn: function (ref) {
                                var text = ref.text
                                var record = ref.record
                                return [
                                  _c(
                                    "span",
                                    [
                                      _c(
                                        "a",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.showModal(record, true)
                                            },
                                          },
                                        },
                                        [_vm._v("修改")]
                                      ),
                                      _c("a-divider", {
                                        attrs: { type: "vertical" },
                                      }),
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { color: "red" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.del(record)
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "a-modal",
          {
            attrs: {
              title: _vm.title,
              visible: _vm.visible,
              "confirm-loading": _vm.confirmLoading,
              width: "760px",
            },
            on: { ok: _vm.modelOk, cancel: _vm.modelCancel },
          },
          [
            _vm.visible
              ? _c(
                  "a-form-model",
                  {
                    ref: "ruleForm",
                    attrs: {
                      model: _vm.form,
                      "label-col": _vm.labelCol,
                      "wrapper-col": _vm.wrapperCol,
                      rules: _vm.rules,
                      selfUpdate: true,
                    },
                  },
                  [
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "所属公司" } },
                      [
                        _c("a-tree-select", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            "dropdown-style": {
                              maxHeight: "400px",
                              overflow: "auto",
                            },
                            "tree-data": _vm.officeTreeData,
                            placeholder: "请选择直属上级单位",
                            replaceFields: {
                              children: "children",
                              title: "name",
                              key: "id",
                              value: "id",
                            },
                            treeDefaultExpandAll: false,
                            "show-search": "",
                            "tree-node-filter-prop": "title",
                          },
                          on: { change: _vm.officeSelect },
                          model: {
                            value: _vm.form.officeId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "officeId", $$v)
                            },
                            expression: "form.officeId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        ref: "name",
                        attrs: { label: "姓名", prop: "name", required: "" },
                      },
                      [
                        _c("a-input", {
                          model: {
                            value: _vm.form.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "name", $$v)
                            },
                            expression: "form.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        ref: "phone",
                        attrs: { label: "账号", prop: "phone", required: "" },
                      },
                      [
                        _c("a-input", {
                          model: {
                            value: _vm.form.phone,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "phone", $$v)
                            },
                            expression: "form.phone",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        ref: "password",
                        attrs: {
                          label: "密码",
                          prop: "password",
                          required: !_vm.isPwdShow,
                        },
                      },
                      [
                        _c("a-input-password", {
                          attrs: {
                            placeholder:
                              "密码必须包含数字、大小写字母、特殊字符，且长度大于等于8个字符",
                          },
                          model: {
                            value: _vm.form.password,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "password",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "form.password",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        ref: "confirmPassWord",
                        attrs: { label: "确认密码", prop: "confirmPassWord" },
                      },
                      [
                        _c("a-input-password", {
                          model: {
                            value: _vm.form.confirmPassWord,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "confirmPassWord",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "form.confirmPassWord",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "是否允许登录" } },
                      [
                        _c(
                          "a-select",
                          {
                            attrs: { placeholder: "是否允许登录" },
                            model: {
                              value: _vm.form.loginFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "loginFlag", $$v)
                              },
                              expression: "form.loginFlag",
                            },
                          },
                          [
                            _c(
                              "a-select-option",
                              { key: "1", attrs: { value: "1" } },
                              [_vm._v(" 是 ")]
                            ),
                            _c(
                              "a-select-option",
                              { key: "0", attrs: { value: "0" } },
                              [_vm._v(" 否 ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "新劳务用户角色" } },
                      [
                        _c(
                          "a-select",
                          {
                            staticStyle: { width: "200px" },
                            attrs: {
                              "show-search": "",
                              mode: "multiple",
                              placeholder: "请选择角色",
                              "option-filter-prop": "children",
                              "default-value": _vm.form.labourRoleIdList,
                              "filter-option": _vm.filterOption,
                            },
                            on: {
                              focus: _vm.handleFocus,
                              blur: _vm.handleBlur,
                              change: _vm.changeLabourRole,
                            },
                          },
                          _vm._l(_vm.newRole, function (item, index) {
                            return _c(
                              "a-select-option",
                              { key: index, attrs: { value: item.roleId } },
                              [_vm._v(" " + _vm._s(item.roleName) + " ")]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "备注" } },
                      [
                        _c("a-input", {
                          model: {
                            value: _vm.form.remarks,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "remarks", $$v)
                            },
                            expression: "form.remarks",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }