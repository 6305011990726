var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    [
      _c("form-index", {
        ref: "formindex",
        staticClass: "form",
        attrs: {
          formData: _vm.formData,
          Overview: "产品列表",
          baiduMapBoolen: false,
          flexSubmit: true,
        },
        scopedSlots: _vm._u([
          {
            key: "flexSubmit",
            fn: function () {
              return [
                _c(
                  "a-space",
                  { style: { marginTop: "4px" } },
                  [
                    _c(
                      "a-button",
                      { attrs: { type: "primary", "html-type": "submit" } },
                      [_vm._v(" 查询 ")]
                    ),
                    _c("a-button", { on: { click: _vm.close } }, [
                      _vm._v(" 重置 "),
                    ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("standard-table", {
        attrs: {
          columns: _vm.columns,
          dataSource: _vm.dataSource,
          "row-key": "id",
          scroll: { x: 1300 },
          pagination: _vm.pagination,
          loading: _vm.tableLoading,
          bordered: true,
        },
        on: { change: _vm.onPageChange },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function (ref) {
              var index = ref.index
              return [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        (_vm.pagination.current - 1) * _vm.pagination.pageSize +
                          parseInt(index) +
                          1
                      ) +
                      " "
                  ),
                ]),
              ]
            },
          },
          {
            key: "name",
            fn: function (ref) {
              var text = ref.text
              var record = ref.record
              return [
                _c(
                  "a-tooltip",
                  { attrs: { placement: "topLeft" } },
                  [
                    _c("template", { slot: "title" }, [
                      _c("span", [_vm._v(_vm._s(text))]),
                    ]),
                    _c(
                      "span",
                      {
                        staticClass: "ant-btn-text ellipsis",
                        on: {
                          click: function ($event) {
                            return _vm.viewEditing(record)
                          },
                        },
                      },
                      [_vm._v(_vm._s(text))]
                    ),
                  ],
                  2
                ),
              ]
            },
          },
          {
            key: "dictId",
            fn: function (ref) {
              var text = ref.text
              return _c("div", {}, [
                text
                  ? _c("span", [_vm._v(_vm._s(_vm._f("statusDictId")(text)))])
                  : _c("span", [_vm._v("/")]),
              ])
            },
          },
          {
            key: "startDate",
            fn: function (ref) {
              var text = ref.text
              var record = ref.record
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("formatDate")(text ? text : record.createTime)
                    ) +
                    " "
                ),
              ]
            },
          },
          {
            key: "mainBusiness",
            fn: function (ref) {
              var text = ref.text
              return [
                text
                  ? _c(
                      "span",
                      _vm._l(text, function (item, index) {
                        return _c(
                          "span",
                          { key: index },
                          [
                            _c("a-tag", { staticClass: "mt-1" }, [
                              _vm._v(_vm._s(item)),
                            ]),
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _c("span", [_vm._v("/")]),
              ]
            },
          },
          {
            key: "userName",
            fn: function (ref) {
              var record = ref.record
              return _c("div", {}, [
                record.userName
                  ? _c("div", [
                      _c("div", [_vm._v(_vm._s(record.userName))]),
                      record.userPhone
                        ? _c("span", [
                            _vm._v("(" + _vm._s(record.userPhone) + ")"),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ])
            },
          },
          {
            key: "action",
            fn: function (ref) {
              var record = ref.record
              return _c(
                "div",
                {},
                [
                  _vm.isAdmin
                    ? _c(
                        "a-dropdown",
                        {
                          staticClass: "sTables",
                          attrs: { placement: "bottomCenter" },
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("span", [_vm._v("操作")]),
                              _c("a-icon", {
                                staticStyle: {
                                  "margin-left": "2px",
                                  "font-size": "12px",
                                },
                                attrs: { type: "caret-down" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "a-menu",
                            {
                              class: ["avatar-menu"],
                              attrs: { slot: "overlay" },
                              slot: "overlay",
                            },
                            [
                              _c(
                                "a-menu-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.viewEditing(record, "edit")
                                    },
                                  },
                                },
                                [
                                  _c("a-icon", { attrs: { type: "edit" } }),
                                  _c("span", [_vm._v("编辑")]),
                                ],
                                1
                              ),
                              _c(
                                "a-menu-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.recorDelete(record)
                                    },
                                  },
                                },
                                [
                                  _c("a-icon", { attrs: { type: "delete" } }),
                                  _c("span", [_vm._v("删除")]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c("span", [_vm._v("/")]),
                ],
                1
              )
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }