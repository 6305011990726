<template>
  <div>
    <a-drawer
      :width="800"
      :visible="visible"
      title="题目信息"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose"
    > 
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }" layout="horizontal">
      <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="所属课程">
              <a-select
                :allowClear="true"
                style="width: 100%"
                placeholder="请选择课程"
                v-decorator="['courseId', { rules: [{ required: true, message: '请选择课程' }] }]">
                <a-select-option v-for="t in courseDataSource" :key="t.id" :value="t.id">{{t.name}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="试卷标题：">
              <a-input
                v-decorator="['title', { rules: [{  required: true, message: '请输入试卷标题' }] }]"
               placeholder="试卷标题"
               />
            </a-form-item>
          </a-col>
        </a-row>
        <!-- <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="试卷状态：">
              <a-radio-group v-decorator="['status']">
                <a-radio :value="1">
                  未发布
                </a-radio>
                <a-radio :value="2">
                  已发布
                </a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row> -->
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="试卷说明：">
              <a-textarea
                v-decorator="['pExplain', { rules: [{  message: '请输入试卷说明' }] }]"
               placeholder="试卷说明"
               :auto-size="{ minRows: 3, maxRows: 5 }"
               />
            </a-form-item>
          </a-col>
        </a-row>
        <a-divider>试卷题目<a @click="changeQuests" style="margin-left:10px;font-size:13px;color:blue">题目编辑</a></a-divider>
          <div style="width:98%;">
            <div style="width:95%;text-align: right;"><a @click="clearQuest()">清空</a></div>
            <a-tabs :activeKey="currentTab" @change="callback">
              <a-tab-pane :key="1">
                <template slot="tab">单选题（{{sigleQuests.length}}题/{{sigleCore}}分）</template>
                <vue-scroll :ops="ops" style="width:100%;height:415px">
                <a-list size="small" :data-source="sigleQuests">
                  <a-list-item slot="renderItem" slot-scope="item, index">
                    {{index+1}}、【{{item.level==1?'简单':item.level==2?"一般":"困难"}}】{{ item.questCont }}({{item.newcore}}分)
                    <a slot="actions" v-if="index !== 0" @click="upClick(1,index)"><a-icon type="arrow-up" title="上移" /></a>
                    <a slot="actions" v-if="index !== sigleQuests.length - 1" @click="downClick(1,index)"><a-icon type="arrow-down" title="下移"/></a>
                    <!-- <a slot="actions" v-if="index !== 0" @click="topClick(1,index)"><a-icon type="vertical-align-top" title="置顶" /></a>
                    <a slot="actions" v-if="index !== sigleQuests.length - 1"  @click="bottomClick(1,index)"><a-icon type="vertical-align-bottom" title="置底"/></a> -->
                  </a-list-item>
                </a-list>
                </vue-scroll>
              </a-tab-pane>
              <a-tab-pane :key="2" force-render>
                <template slot="tab">多选题（{{multipleQuests.length}}题/{{multipleCore}}分）</template>
                <vue-scroll :ops="ops" style="width:100%;height:415px">
                <a-list size="small" :data-source="multipleQuests">
                  <a-list-item slot="renderItem" slot-scope="item, index">
                    {{index+1}}、【{{item.level==1?'简单':item.level==2?"一般":"困难"}}】{{ item.questCont }}({{item.newcore}}分)
                    <a slot="actions" v-if="index !== 0" @click="upClick(2,index)"><a-icon type="arrow-up" title="上移" /></a>
                    <a slot="actions" v-if="index !== multipleQuests.length - 1" @click="downClick(2,index)"><a-icon type="arrow-down" title="下移"/></a>
                    <!-- <a slot="actions" v-if="index !== 0" @click="topClick(2,index)"><a-icon type="vertical-align-top" title="置顶" /></a>
                    <a slot="actions" v-if="index !== multipleQuests.length - 1"  @click="bottomClick(2,index)"><a-icon type="vertical-align-bottom" title="置底"/></a> -->
                  </a-list-item>
                </a-list>
                </vue-scroll>
              </a-tab-pane>
              <a-tab-pane :key="3">
                <template slot="tab">判断题（{{judgeQuests.length}}题/{{judgeCore}}分）</template>
                <vue-scroll :ops="ops" style="width:100%;height:415px">
                <a-list size="small" :data-source="judgeQuests">
                  <a-list-item slot="renderItem" slot-scope="item, index">
                    {{index+1}}、【{{item.level==1?'简单':item.level==2?"一般":"困难"}}】{{ item.questCont }}({{item.newcore}}分)
                    <a slot="actions" v-if="index !== 0" @click="upClick(3,index)"><a-icon type="arrow-up" title="上移" /></a>
                    <a slot="actions" v-if="index !== judgeQuests.length - 1" @click="downClick(3,index)"><a-icon type="arrow-down" title="下移"/></a>
                    <!-- <a slot="actions" v-if="index !== 0" @click="topClick(3,index)"><a-icon type="vertical-align-top" title="置顶" /></a>
                    <a slot="actions" v-if="index !== judgeQuests.length - 1"  @click="bottomClick(3,index)"><a-icon type="vertical-align-bottom" title="置底"/></a> -->
                  </a-list-item>
                </a-list>
                </vue-scroll>
              </a-tab-pane>
            </a-tabs>
        </div>
        
    </a-form>
    <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginLeft: '8px' }" type="primary" @click="doSubmit">
          保存
        </a-button>
        <a-button :style="{ marginLeft: '8px' }" @click="onClose">
          关闭
        </a-button>
      </div>
      <paper-quest
        :visible="paperquest.visible"
        :paperId="paperquest.paperId"
        :courseId="paperquest.courseId"
        :sigleQuestsOld="paperquest.sigleQuests"
        :multipleQuestsOld="paperquest.multipleQuests"
        :judgeQuestsOld="paperquest.judgeQuests"
        @doCloseQuestions="doCloseQuestions"
        @doSubmitPaperQuest="doSubmitPaperQuest"
      >
      </paper-quest>
    </a-drawer>
  </div>
</template>
<script>
const columns = [
  { title: '题号', dataIndex: 'qtIndex',key:'1',width:60, align: 'center'},
  { title: '题目', dataIndex: 'questCont',key:'2', align: 'left',ellipsis:true},
  { title: '题型', dataIndex: 'isMany',key:'3', align: 'center',width:60},
  { title: '分值', dataIndex: 'core',key:'4' , align: 'center',width:60},
  { title: '难度', dataIndex: 'level',key:'5' , align: 'center',width:60},
  { title: '操作', dataIndex: '',key:'6', scopedSlots: { customRender: 'action' }, align: 'center',width:180 },];
import { request } from "@/utils/request";
import PaperQuest from "@/pages/onlineedu/paperquest";
import {ONLINE_QUERY_COURSE_GETALL,ONLINE_PAPER_QUEST_QUERY} from "@/services/api";
export default {
  name: "teamEditor",
  components: {PaperQuest},
  data() {
    return {
      form: this.$form.createForm(this),
      courseDataSource:[],
      sigleQuests:[],
      multipleQuests:[],
      judgeQuests:[],
      obj:{},
      data:[],
      isEditor:false,
      currentTab:1,
      options:{
        answerCont:null,
        isRight:true
      },
      columns,
      dataSource:[],
      selectedRows:[],
      paperquest:{
        visible:false,
        courseId:null,
        paperId:null,
        sigleQuests:[],
        multipleQuests:[],
        judgeQuests:[],
      },
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: "#3ec13e",//滚动条颜色
          opacity: 0.8,//滚动条透明度
          "overflow-x": "hidden"
        }
      }
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    testPaper:{
      type: Object,
      require:true
    },
    type:{
      type: String,
      default: '1'
    },
  },
  computed:{
    sigleCore() {
      let totalCore = 0;
      this.sigleQuests.forEach(o=>{
        totalCore = totalCore+o.newcore;
      })
      return totalCore
    },
    multipleCore() {
      let totalCore = 0;
      this.multipleQuests.forEach(o=>{
        totalCore = totalCore+o.newcore;
      })
      return totalCore
    },
    judgeCore() {
      let totalCore = 0;
      this.judgeQuests.forEach(o=>{
        totalCore = totalCore+o.newcore;
      })
      return totalCore
    },
  },
  created(){
    this.doQueryCourse();
  },
  mounted(){
    
  },
  watch:{
    visible(val){
      if(val){
        this.obj = this.testPaper;
        setTimeout(()=>{
          this.form.setFieldsValue({
            'title': this.obj&&this.obj.title?this.obj.title:null,
            'status': this.obj&&this.obj.status?this.obj.status:1,
            'pExplain': this.obj&&this.obj.pExplain?this.obj.pExplain:null,
            'courseId': this.obj&&this.obj.courseId?this.obj.courseId+"":null
          })
        })
        //查询答题选项
        if(this.obj&&this.obj.id){
          this.queryPaperQuest();
        }
      }
    }
  },
  methods: {
    callback(key){
      this.currentTab = key;
    },
    doQueryCourse(){
      request(ONLINE_QUERY_COURSE_GETALL,"post",{}).then(res => {
          if(res.data.code==0){
           this.courseDataSource = res.data.data.list;
          }
       })
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },
    onClose(){
      this.$emit('questPageClose');
    },
    doSubmit(){

       //数据校验
      this.form.validateFields((err, values) => {
        if (!err) {
          if(this.obj&&this.obj.id){
            values.id = this.obj.id
          }
          if(this.sigleQuests.length<=0&&this.multipleQuests.length<=0&&this.judgeQuests.length<=0){
            this.$message.error("请点击题目编辑选择试卷相应的题目！")
            return ;
          }
          if(this.sigleQuests.length>0){
            this.sigleQuests.forEach((obj,index)=>{
              this.$set(obj,"qtIndex",(index+1))
              this.$set(obj,"qtType",1);
              this.$set(obj,"questId",obj.id);
              if(this.obj&&this.obj.id){
                this.$set(obj,"paperId",this.obj.id);
              }
              obj.core = obj.newcore?obj.newcore:obj.core
            })
            values.sigleQuests = this.sigleQuests;
          }
          if(this.multipleQuests.length>0){
            this.multipleQuests.forEach((obj,index)=>{
              this.$set(obj,"qtIndex",(index+1))
              this.$set(obj,"qtType",2)
              this.$set(obj,"questId",obj.id);
              if(this.obj&&this.obj.id){
                this.$set(obj,"paperId",this.obj.id);
              }
              obj.core = obj.newcore?obj.newcore:obj.core
            })
            values.multipleQuests = this.multipleQuests;
          }
          if(this.judgeQuests.length>0){
            this.judgeQuests.forEach((obj,index)=>{
              this.$set(obj,"qtIndex",(index+1))
              this.$set(obj,"qtType",3)
              this.$set(obj,"questId",obj.id);
              if(this.obj&&this.obj.id){
                this.$set(obj,"paperId",this.obj.id);
              }
              obj.core = obj.newcore?obj.newcore:obj.core
            })
            values.judgeQuests = this.judgeQuests;
          }
          //接口保存数据
          this.$set(values,'isAuto',this.type);
          this.$emit("submit",values);
          this.paperquest.visible=false;
        }
      })
    },
    changeQuests(){
       //数据校验
      this.form.validateFields((err, values) => {
        if (!err) {
          if(this.obj&&this.obj.id){
            this.paperquest.paperId=this.obj.id;
          }
          if(values&&values.courseId){
            this.paperquest.courseId=values.courseId;
            this.paperquest.sigleQuests = this.sigleQuests;
            this.paperquest.multipleQuests = this.multipleQuests;
            this.paperquest.judgeQuests = this.judgeQuests;
             this.paperquest.visible=true;
          }else{
            this.$message.error("请选择所属课程！")
          }
          
        }else{
          return false;
        }
      })
    },
    doCloseQuestions(){
      this.paperquest.paperId=null;
      this.paperquest.visible=false;
      this.paperquest.courseId=null;
    },
    doSubmitPaperQuest($event){
      this.judgeQuests = $event.judgeQuests;
      this.multipleQuests = $event.multipleQuests;
      this.sigleQuests = $event.sigleQuests;
      this.doCloseQuestions();

    },
    // 上移
    upClick(type,index) {
      if(type==1){
        let newArr = this.swapItems(this.sigleQuests, index, index - 1)
        this.sigleQuests = newArr
      }else if(type==2){
        let newArr = this.swapItems(this.multipleQuests, index, index - 1)
        this.multipleQuests = newArr
      }else if(type==3){
        let newArr = this.swapItems(this.judgeQuests, index, index - 1)
        this.judgeQuests = newArr
      }
    },
    // 下移
    downClick(type,index) {
     if(type==1){
        let newArr = this.swapItems(this.sigleQuests, index, index + 1)
        this.sigleQuests = newArr
      }else if(type==2){
        let newArr = this.swapItems(this.multipleQuests, index, index + 1)
        this.multipleQuests = newArr
      }else if(type==3){
        let newArr = this.swapItems(this.judgeQuests, index, index + 1)
        this.judgeQuests = newArr
      }
    },
    topClick(type,index){
      if(type==1){
        let newArr = this.swapItems(this.sigleQuests, index, 0)
        this.sigleQuests = newArr
      }else if(type==2){
        let newArr = this.swapItems(this.multipleQuests, index, 0)
        this.multipleQuests = newArr
      }else if(type==3){
        let newArr = this.swapItems(this.judgeQuests, index, 0)
        this.judgeQuests = newArr
      }
    },
    bottomClick(type,index){
      if(type==1){
        let newArr = this.swapItems(this.sigleQuests, index, this.sigleQuests.length-1)
        this.sigleQuests = newArr
      }else if(type==2){
        let newArr = this.swapItems(this.multipleQuests, index, this.multipleQuests.length-1)
        this.multipleQuests = newArr
      }else if(type==3){
        let newArr = this.swapItems(this.judgeQuests, index, this.judgeQuests.length-1)
        this.judgeQuests = newArr
      }
    },
    swapItems(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      return arr;
    },
    queryPaperQuest(){
      if(this.testPaper.id){
         request(ONLINE_PAPER_QUEST_QUERY+this.testPaper.id,"get",{}).then(res => {
          if(res.data.code==0){
            this.sigleQuests = res.data.data.sigleQuests;
            this.multipleQuests = res.data.data.multipleQuests;
            this.judgeQuests = res.data.data.judgeQuests;
          }
       })
      }
    },
    clearQuest(){
      if(this.currentTab==1){
        this.sigleQuests = [];
      }else if(this.currentTab==2){
        this.multipleQuests = [];
      }else if(this.currentTab==3){
        this.judgeQuests = [];
      }
    }
  }
};
</script>
<style>
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
/deep/ .ant-upload-list-picture-card .ant-upload-list-item{
  width: 127px;
  height: 127px;
}
</style>