var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a_Approval" },
    [
      _c(
        "a-card",
        { attrs: { title: "预警状态", bordered: false } },
        [
          _c(
            "a-steps",
            { staticClass: "mb-1", attrs: { current: _vm.dataSolve.status } },
            [
              _c(
                "a-step",
                [
                  _c("template", { slot: "title" }, [_vm._v(" 未处理 ")]),
                  _vm.dataSolve.status == 0
                    ? _c("template", { slot: "description" }, [
                        _c("div", { staticClass: "flex" }, [
                          _c("span", { staticClass: "mr-2" }, [
                            _vm._v("总包劳务员"),
                          ]),
                          _c("a", { on: { click: _vm.onSolve } }, [
                            _vm._v("查看"),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "a-step",
                [
                  _c("template", { slot: "title" }, [_vm._v(" 处理中 ")]),
                  _vm.dataSolve.status == 1
                    ? _c("template", { slot: "description" }, [
                        _c("div", { staticClass: "flex" }, [
                          _c("span", { staticClass: "mr-2" }, [
                            _vm._v("总包劳务员"),
                          ]),
                          _c("a", { on: { click: _vm.onSolve } }, [
                            _vm._v("查看"),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "a-step",
                [
                  _c("template", { slot: "title" }, [_vm._v(" 处理完成 ")]),
                  _c("template", { slot: "description" }, [
                    _c("div", { staticClass: "flex" }, [
                      _c("span", { staticClass: "mr-2" }, [
                        _vm._v("总包劳务员"),
                      ]),
                      _c("a", { on: { click: _vm.onSolve } }, [_vm._v("查看")]),
                    ]),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c("a-list", {
            attrs: {
              "item-layout": "horizontal",
              "data-source": _vm.descList,
              split: false,
            },
            scopedSlots: _vm._u([
              {
                key: "renderItem",
                fn: function (item) {
                  return _c(
                    "a-list-item",
                    {},
                    [
                      _c("a-list-item-meta", [
                        _c(
                          "a",
                          {
                            staticClass: "font-bold font-16",
                            staticStyle: { color: "rgba(0, 0, 0, 0.85)" },
                            attrs: { slot: "title" },
                            slot: "title",
                          },
                          [_vm._v(_vm._s(item.title))]
                        ),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "white-space": "pre-wrap",
                              color: "rgba(0, 0, 0, 0.75)",
                            },
                            attrs: { slot: "description" },
                            slot: "description",
                          },
                          [_vm._v(_vm._s(item.value))]
                        ),
                      ]),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "处理建委预警", destroyOnClose: true },
          on: { ok: _vm.solveSubmit },
          model: {
            value: _vm.solveDialog,
            callback: function ($$v) {
              _vm.solveDialog = $$v
            },
            expression: "solveDialog",
          },
        },
        [
          _c("form-index", {
            ref: "formSolve",
            attrs: {
              formData: _vm.formSolveData,
              defaultFileList: _vm.defaultFileList,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }