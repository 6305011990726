var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { bordered: false } },
    [
      _c(
        "a-form",
        { attrs: { form: _vm.form } },
        [
          _c(
            "a-form-item",
            {
              attrs: {
                label: "疫情上报开关",
                labelCol: { span: 5 },
                wrapperCol: { span: 18, offset: 1 },
              },
            },
            [
              _c("a-switch", {
                model: {
                  value: _vm.form.yqsbOnOff,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "yqsbOnOff", $$v)
                  },
                  expression: "form.yqsbOnOff",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                label: "疫情上报频率",
                labelCol: { span: 5 },
                wrapperCol: { span: 18, offset: 1 },
              },
            },
            [
              _c(
                "a-select",
                {
                  model: {
                    value: _vm.form.yqsbFrequency,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "yqsbFrequency", $$v)
                    },
                    expression: "form.yqsbFrequency",
                  },
                },
                [
                  _c("a-select-option", { attrs: { value: "1" } }, [
                    _vm._v("每天"),
                  ]),
                  _c("a-select-option", { attrs: { value: "2" } }, [
                    _vm._v("每周"),
                  ]),
                  _c("a-select-option", { attrs: { value: "3" } }, [
                    _vm._v("每月"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                label: "疫情上报提醒",
                labelCol: { span: 5 },
                wrapperCol: { span: 18, offset: 1 },
              },
            },
            [
              _c("a-switch", {
                model: {
                  value: _vm.form.yqsbRemind,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "yqsbRemind", $$v)
                  },
                  expression: "form.yqsbRemind",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                label: "提醒时间",
                labelCol: { span: 5 },
                wrapperCol: { span: 18, offset: 1 },
              },
            },
            [
              _c("a-time-picker", {
                attrs: {
                  format: "HH:mm",
                  "minute-step": 30,
                  "input-read-only": true,
                  valueFormat: "HH:mm",
                },
                model: {
                  value: _vm.form.yqsbTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "yqsbTime", $$v)
                  },
                  expression: "form.yqsbTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                label: "申报模式",
                labelCol: { span: 5 },
                wrapperCol: { span: 18, offset: 1 },
              },
            },
            [
              _c(
                "a-select",
                {
                  model: {
                    value: _vm.form.yqsbModel,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "yqsbModel", $$v)
                    },
                    expression: "form.yqsbModel",
                  },
                },
                [
                  _c("a-select-option", { attrs: { value: "1" } }, [
                    _vm._v("详细版"),
                  ]),
                  _c("a-select-option", { attrs: { value: "2" } }, [
                    _vm._v("简洁版"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("a-button", { attrs: { type: "primary" }, on: { click: _vm.save } }, [
        _vm._v(" 保存 "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }