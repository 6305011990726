<template>
  <div>
    <a-row :gutter="[16, 16]">
      <a-col :span="15">
        <div class="public">
          <div class="flex">
            <div class="relative" >
              <img src="../../assets/img/icon-jw-warning.png" alt="">
              <div class="warning-content white">
                <p class="font-bold-700">建委预警</p>
                <p class="font-bold-700 font-30">6</p>
                <p @click="toWarning">去处理<a-icon type="right" /></p>
              </div>
            </div>
            <div class="ml-2 w-100 tb-box">
              <table class="w-100">
                <thead align="center">
                  <tr>
                    <th>序号</th>
                    <th>预警内容</th>
                    <th>预警时间</th>
                    <th>处理时限</th>
                  </tr>
                </thead>
                <tbody align="center">
                  <tr v-for="(item, index) in 10" :key="index">
                    <td><span class="table-num">0{{index + 1}}</span></td>
                    <td><span class="overtext-1 table-content">(SY2)考勤不完整考勤不完整考勤不完整考勤不完整</span></td>
                    <td>2023.05.19</td>
                    <td><span class="overtext-1 table-suggest">剩余1天</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="flex mt-3">
            <div class="relative">
              <img src="../../assets/img/icon-plantform-warning.png" alt="">
              <div class="warning-content">
                <p class="font-bold-700">平台预警</p>
                <p class="font-bold-700 font-30">6</p>
                <p style="color: #083AF0;">去处理<a-icon type="right" /></p>
              </div>
            </div>
            <div class="ml-2 w-100 tb-box">
              <table class="w-100">
                <thead align="center">
                  <tr>
                    <th>序号</th>
                    <th>预警内容</th>
                    <th>预警时间</th>
                    <th>处理建议</th>
                  </tr>
                </thead>
                <tbody align="center">
                  <tr v-for="(item, index) in 3" :key="index">
                    <td><span class="table-num">0{{index + 1}}</span></td>
                    <td><span class="overtext-1 table-content">工人与建委在场状态不一致工人与建委在场状态不一致工人与建委在场状态不一致</span></td>
                    <td>2023.05.19</td>
                    <td><span class="overtext-1 table-suggest">请按照要求上传建委资料</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </a-col>
      <a-col :span="9">
        <div>
          <a-row :gutter="[16, 16]">
            <a-col :span="24">
              <div class="public">
                <div class="flex">
                  <div class="flex-1">
                    <span class="pointer" :class="activedKey === 1 ? 'black' : 'black-three'" @click="handleChangeTitle(1)">通知公告</span>
                    <span class="ml-2 pointer" :class="activedKey === 2 ? 'black' : 'black-three'" @click="handleChangeTitle(2)">我的消息</span>
                  </div>
                  <span span class="black pointer">更多<a-icon type="right" /></span>
                </div>
                <div class="mt-2">
                  <div class="mt-1 flex black" v-for="(item, index) in 3" :key="index">
                    <span class="overtext-1 flex-1 mr-5">广州市建筑业管理服务中心关于建广州市建筑业管理服务中心关于建</span>
                    <span class="black-three">2023.04.12 18:00</span>
                  </div>
                </div>
              </div>
            </a-col>
            <a-col :span="24">
              <div class="public">
                <div class="flex">
                  <div class="flex-1">
                    <span class="pointer font-16 black">工资发放数据</span>
                  </div>
                  <span span class="black pointer">更多<a-icon type="right" /></span>
                </div>
                <div class="mt-1 flex">
                  <div class="flex-1 text-center">
                    <p class="black-three">已发放金额(元)</p>
                    <p class="black salary-content">450,000</p>
                  </div>
                  <div class="flex-1 text-center">
                    <p class="black-three">建委考勤人数</p>
                    <p class="black salary-content">45</p>
                  </div>
                  <div class="flex-1 text-center">
                    <p class="black-three">专户已发放人数</p>
                    <p class="black salary-content">45</p>
                  </div>
                </div>
              </div>
            </a-col>
          </a-row>
        </div>
      </a-col>
    </a-row>
    <a-row :gutter="[16, 16]">
      <a-col :span="5">
        <div class="public">
          <h3 class="font-16 black">管理人员数据</h3>
          <a-row class="mt-1 p-1">
            <a-col :span="12">
              <h4 class="black-three">登记人员</h4>
              <p class="font-24 black">58</p>
            </a-col>
            <a-col :span="12">
              <img src="@/assets/img/icon-line-chart.png" alt="">
            </a-col>
          </a-row>
          <a-row class="mt-1 p-1">
            <a-col :span="12">
              <h4 class="black-three">今日出勤人数</h4>
              <p class="font-24 black">52</p>
              <span class="green">+21.01%</span>
              <img src="@/assets/img/arrow-up.png" alt="">
            </a-col>
            <a-col :span="12">
              <h4 class="black-three">出勤率</h4>
              <p class="font-24 black">96%</p>
            </a-col>
          </a-row>
        </div>
      </a-col>
      <a-col :span="5">
        <div class="public">
          <h3 class="font-16 black">工人数据</h3>
          <a-row class="mt-1 p-1">
            <a-col :span="12">
              <h4 class="black-three">累计登记人数</h4>
              <p class="font-24 black">230</p>
            </a-col>
            <a-col :span="12">
              <h4 class="black-three">在场人数</h4>
              <p class="font-24 black">158</p>
            </a-col>
          </a-row>
          <a-row class="mt-1 p-1">
            <a-col :span="12">
              <h4 class="black-three">今日出勤人数</h4>
              <p class="font-24 black">52</p>
              <span class="green">+21.01%</span>
              <img src="@/assets/img/arrow-up.png" alt="">
            </a-col>
            <a-col :span="12">
              <h4 class="black-three">出勤率</h4>
              <p class="font-24 black">96%</p>
            </a-col>
          </a-row>
        </div>
      </a-col>
      <a-col :span="5">
        <div class="public">
          <h3 class="font-16 black">班组数据</h3>
          <a-row class="mt-1 p-1">
            <a-col :span="12">
              <h4 class="black-three">累计登记班组</h4>
              <p class="font-24 black">230</p>
            </a-col>
            <a-col :span="12">
              <h4 class="black-three">在场班组数</h4>
              <p class="font-24 black">158</p>
            </a-col>
          </a-row>
          <a-row class="mt-1 p-1">
            <a-col :span="12">
              <h4 class="black-three">今日出勤班组数</h4>
              <p class="font-24 black">52</p>
              <span class="red">+21.01%</span>
              <img src="@/assets/img/arrow-down.png" alt="">
            </a-col>
            <a-col :span="12">
              <h4 class="black-three">出勤率</h4>
              <p class="font-24 black">96%</p>
            </a-col>
          </a-row>
        </div>
      </a-col>
      <a-col :span="9">
        <div class="public">
          <h3 class="font-16 black">考勤设备</h3>
          <div class="flex j-around">
            <div>
              <span class="black-three">考勤设备数</span>
              <span class="black font-24 ml-2">52</span>
            </div>
            <div>
              <span class="black-three">离线</span>
              <span class="black font-24 ml-2">2</span>
            </div>
          </div>
          <div class="w-100 tb-box-2 mt-1">
            <table class="w-100">
              <thead align="center">
                <tr>
                  <th>序号</th>
                  <th>区域</th>
                  <th>设备名称</th>
                  <th>状态</th>
                </tr>
              </thead>
              <tbody align="center">
                <tr v-for="(item, index) in 10" :key="index">
                  <td><span class="table-num">0{{index + 1}}</span></td>
                  <td><span class="overtext-1 table-content">工作区工作区工作区工作区</span></td>
                  <td>现场（出3）</td>
                  <td><span class="overtext-1 table-suggest">离线</span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </a-col>
    </a-row>
    <a-row :gutter="[16, 16]">
      <a-col :span="15">
        <div class="public">
          <div class="flex flex-column">
            <div class="flex-1">
              <span class="pointer font-16" :class="activedKey === 1 ? 'black' : 'black-three'" @click="handleChangeTitle(1)">实时在场人数</span>
              <span class="ml-2 pointer font-16" :class="activedKey === 2 ? 'black' : 'black-three'" @click="handleChangeTitle(2)">近7日出勤数据</span>
            </div>
            <div id="main" style="width: 100%;height:400px;" ref="peopleChart"></div>
          </div>
        </div>
      </a-col>
      <a-col :span="9">
        <div class="public">
          <h3 class="font-16 black">班组考勤情况</h3>
          <div style="height: 392px;" class="p-2">
            <div class="mt-2" v-for="(item, index) in 5" :key="index">
              <div class="flex j-between">
                <span>周子艺杂工班组(96/100)</span>
                <span>96%</span>
              </div>
              <a-progress :percent="96" :show-info="false" :strokeWidth="12"/>
            </div>
            <div class="mt-2 flex j-between">
              <span style="color: #615E83;">0</span>
              <span style="color: #615E83;">25%</span>
              <span style="color: #615E83;">50%</span>
              <span style="color: #615E83;">75%</span>
              <span style="color: #615E83;">100%</span>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
    <a-row :gutter="[16, 16]">
      <a-col :span="10">
        <div class="public flex">
          <div class="w-40">
            <h3 class="font-16 black">环境监测</h3>
            <a-row class="mt-1 p-1">
              <a-col :span="12">
                <h4 class="black-three">AQI</h4>
                <p class="font-24 black">55</p>
              </a-col>
              <a-col :span="12">
                <h4 class="black-three">噪声</h4>
                <p class="font-24 black">>0.0dB</p>
              </a-col>
            </a-row>
            <a-row class="mt-1 p-1">
              <a-col :span="12">
                <h4 class="black-three">气温</h4>
                <p class="font-24 black">158℃</p>
              </a-col>
              <a-col :span="12">
                <h4 class="black-three">湿度</h4>
                <p class="font-24 black">96%</p>
              </a-col>
            </a-row>
          </div>
          <div class="w-60">
            <div id="main" style="width: 100%;height:100%;" ref="environmentChart"></div>
          </div>
        </div>  
      </a-col>
      <a-col :span="5">
        <div class="public">
          <h3 class="font-16 black">安全监测</h3>
          <div class="flex j-around">
            <div>
              <p class="black-three">未带安全帽</p>
              <span class="black font-24 ml-2">52</span>
            </div>
            <div>
              <p class="black-three">未穿反光衣</p>
              <span class="black font-24 ml-2">2</span>
            </div>
          </div>
          <div class="w-100 mt-1">
            <table class="w-100">
              <thead align="center">
                <tr>
                  <th>名称</th>
                  <th>设备数量</th>
                  <th>预警数量</th>
                </tr>
              </thead>
              <tbody align="center">
                <tr v-for="(item, index) in 3" :key="index">
                  <td><span class="overtext-1">塔吊监测</span></td>
                  <td><span class="">15</span></td>
                  <td>16</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </a-col>
      <a-col :span="9">
        <div class="public">
          <h3 class="font-16 black">监控视频</h3>
          <div class="flex j-around">
            <div>
              <span class="black-three">摄像头个数</span>
              <span class="black font-24 ml-2">52</span>
            </div>
            <div>
              <span class="black-three">在线率</span>
              <span class="black font-24 ml-2">2%</span>
            </div>
          </div>
          <div class="w-100 tb-box-3 mt-1">
            <table class="w-100">
              <thead align="center">
                <tr>
                  <th>序号</th>
                  <th>名称</th>
                  <th>状态</th>
                  <th>操作</th>
                </tr>
              </thead>
              <tbody align="center">
                <tr v-for="(item, index) in 10" :key="index">
                  <td><span class="table-num">0{{index + 1}}</span></td>
                  <td><span class="overtext-1 table-content">现场实名制通道</span></td>
                  <td>在线</td>
                  <td><span class="overtext-1 table-suggest">查看视频</span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </a-col>
    </a-row>
    <a-row :gutter="[16, 16]">
      <a-col :span="5">
        <div class="public">
          <h3 class="font-16 black">教育安全培训</h3>
          <div class="purple b-radius1 p-2">
            <h4 class="text-center">三级安全教育</h4>
            <div class="flex j-between">
              <div>
                <h4 class="text-center black-three">参与人数</h4>
                <p class="black font-24 text-center">236</p>
              </div>
              <div>
                <h4 class="text-center black-three">参与人数</h4>
                <p class="black font-24 text-center">236</p>
              </div>
            </div>
          </div>
          <div class="light-blue b-radius1 p-2 mt-1">
            <h4 class="text-center">安全技术交底</h4>
            <div class="flex j-between">
              <div>
                <h4 class="text-center black-three">参与人数</h4>
                <p class="black font-24 text-center">236</p>
              </div>
              <div>
                <h4 class="text-center black-three">参与人数</h4>
                <p class="black font-24 text-center">236</p>
              </div>
            </div>
          </div>
        </div>
      </a-col>
      <a-col :span="5">
        <div class="public">
          <h3 class="font-16 black">安全检查统计</h3>
          <div>
            <span class="black-three">已整改/整改单总数</span>
            <span class="ml-2 black font-24">64/100</span>
          </div>
          <a-progress :percent="96" :show-info="false" :strokeWidth="12"/>
          <a-divider />
          <a-row class="p-1">
            <a-col :span="12">
              <h4 class="black-three">隐患个数</h4>
              <p class="font-24 black">230</p>
            </a-col>
            <a-col :span="12">
              <h4 class="black-three">超时未整改数</h4>
              <p class="font-24 black">158</p>
            </a-col>
          </a-row>
          <a-row class="p-1">
            <a-col :span="12">
              <h4 class="black-three">隐患整改率</h4>
              <p class="font-24 black">52</p>
            </a-col>
            <a-col :span="12">
              <h4 class="black-three">项目自检完成度</h4>
              <p class="font-24 black">96%</p>
            </a-col>
          </a-row>
        </div>
      </a-col>
      <a-col :span="14">
        <div class="public">
          <h3 class="font-16 black">安全隐患类型分析</h3>
          <div class="flex">
            <div class="w-40">
              <div id="main" style="width: 100%;height:275px;" ref="safeChart"></div>
            </div>
            <div class="w-60">
              <div>
                <span class="black-three">隐患总数</span>
                <span class="black font-24 ml-2">52</span>
              </div>
              <div class="w-100 tb-box-2 mt-1">
                <table class="w-100">
                  <thead align="center">
                    <tr>
                      <th>安全管理</th>
                      <th>文明施工</th>
                      <th>脚手架</th>
                      <th>基坑与模板</th>
                    </tr>
                  </thead>
                  <tbody align="center">
                    <tr>
                      <td>1</td>
                      <td>2</td>
                      <td>3</td>
                      <td>4</td>
                    </tr>
                  </tbody>
                  <thead align="center">
                    <tr>
                      <th>高处作业</th>
                      <th>施工用电</th>
                      <th>大型设备</th>
                      <th>器重吊装</th>
                    </tr>
                  </thead>
                  <tbody align="center">
                    <tr>
                      <td>1</td>
                      <td>2</td>
                      <td>3</td>
                      <td>4</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {
  sevenKqChart,
  dangerTypeChart,
  ageTypeChart,
  workTypeChart,
} from "../homePage/echarts";
import {
  get7DaysCWA,
  getJobTitleFb,
  getManagCWA,
  getOfficeListByParentOffice,
  getProjectsByOffice,
  getQueryCurrentGk,
  getTypeCount,
  getWorkAgeFb,
  getWorkTotal,
  projectStatissKqS,
} from "../../services/statistics";
import { mapGetters } from "vuex";
import { peopleLineChart, environmentChart, safeChart } from './echarts'
import { useRender } from '@/hooks/useRender.js'
const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
  },
  {
    title: "预警内容",
    dataIndex: "groupName",
    align: "center",
    scopedSlots: { customRender: "groupName" },
  },
  {
    title: "预警时间",
    dataIndex: "deviceName",
    align: "center",
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "处理时限",
    dataIndex: "online",
    align: "center",
    scopedSlots: { customRender: "online" },
  },
];
export default {
  name: "homePage",
  data() {
    return {
      activedKey: 1,
      peopleChart: null,
      environmentChart: null,
      safeChart: null
      // currentGk: {}, // 当月安全管理统计汇总
      // workData: {}, // 劳务数据汇总
      // projectList: [],
      // defaultProject: "全部",
      // gcObj: {}, // 工程类型守护
      // ManagCWA: {}, // 管理人员数据
      // sevenKq: [],
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  created() {
    // 获取账号下的单位
    const id = this.user.projectId;
    // this.getWorkTotal(id);
    // this.getManagCWA(id); // 默认第一个项目id
    // this.getSevenKq(id); //  7天考勤
    // this.getAgeType(id); //   年龄结构
    // this.getJobType(id); //   工种结构
  },
  mounted() {
    this.peopleChart = peopleLineChart([], this.$refs.peopleChart)
    this.environmentChart = environmentChart([], this.$refs.environmentChart)
    this.safeChart = safeChart([], this.$refs.safeChart)
    setTimeout(() => {
      this.peopleChart.resize();
      this.environmentChart.resize();
      this.safeChart.resize();
    }, 200);
    window.addEventListener("resize", () => {
        // 第六步，执行echarts自带的resize方法，即可做到让echarts图表自适应
      this.peopleChart.resize();
      this.environmentChart.resize();
      this.safeChart.resize();
      // 如果有多个echarts，就在这里执行多个echarts实例的resize方法,不过一般要做组件化开发，即一个.vue文件只会放置一个echarts实例
      /*
      this.myChart2.resize();
      this.myChart3.resize();
      ......
      */
    });
  },
  beforeDestroy() {
    /* 页面组件销毁的时候，别忘了移除绑定的监听resize事件，否则的话，多渲染几次
    容易导致内存泄漏和额外CPU或GPU占用哦*/
    window.removeEventListener("resize", () => {
      this.peopleChart.resize();
      this.environmentChart.resize();
      this.safeChart.resize();
    });
  },
  methods: {
    toWarning() {
      this.$router.push('/alert_list_index')
    },
    handleChangeTitle(e) {
      this.activedKey = e
    }
    /**
     * 劳务数据管理员统计
     * */
    // getManagCWA(id) {
    //   getManagCWA({ projectId: id }).then((res) => {
    //     if (res.data.code === 0) {
    //       this.ManagCWA = res.data.data;
    //     } else {
    //       this.error = res.data.msg;
    //       this.$message.error(res.data.msg, 3);
    //     }
    //   });
    // },
    // /**
    //  * 获取劳务工人考勤数
    //  * */
    // getWorkTotal(id) {
    //   getWorkTotal({ projectId: id }).then((res) => {
    //     if (res.data.code === 0) {
    //       this.workData = res.data.data;
    //     } else {
    //       this.error = res.data.msg;
    //       this.$message.error(res.data.msg, 3);
    //     }
    //   });
    // },
    // /**
    //  * 工人七天考勤
    //  * */
    // getSevenKq(id) {
    //   get7DaysCWA({ projectId: id }).then((res) => {
    //     if (res.data.code === 0) {
    //       const week = [
    //         { dates: this.fun_date(-7), count: "0" },
    //         { dates: this.fun_date(-6), count: "0" },
    //         { dates: this.fun_date(-5), count: "0" },
    //         { dates: this.fun_date(-4), count: "0" },
    //         { dates: this.fun_date(-3), count: "0" },
    //         { dates: this.fun_date(-2), count: "0" },
    //         { dates: this.fun_date(-1), count: "0" },
    //       ];
    //       this.sevenKq = res.data.data.length > 0 ? res.data.data : week;
    //       let nameArr = [];
    //       let cntArr = [];
    //       this.sevenKq.forEach((item, index) => {
    //         nameArr.push(item.dates);
    //         cntArr.push(Number(item.count));
    //       });
    //       sevenKqChart(nameArr, cntArr);
    //     } else {
    //       this.error = res.data.msg;
    //       this.$message.error(res.data.msg, 3);
    //     }
    //   });
    // },
    // fun_date(day) {
    //   let date1 = new Date();
    //   let date2 = new Date(date1);
    //   date2.setDate(date1.getDate() + day);
    //   let time2 =
    //     date2.getFullYear() +
    //     "-" +
    //     (date2.getMonth() + 1) +
    //     "-" +
    //     date2.getDate();
    //   return time2;
    // },
    // /**
    //  * 工人年龄分布
    //  * */
    // getAgeType(id) {
    //   getWorkAgeFb(id).then((res) => {
    //     if (res.data.code === 0) {
    //       let ageTypeFb = res.data.data[0] || { d4155: 0 };
    //       let arr = [];
    //       for (let item in ageTypeFb) {
    //         let obj = {};
    //         if (item == "d30") {
    //           obj.name = "低于40岁";
    //           obj.value = ageTypeFb[item];
    //         } else if (item == "d55") {
    //           obj.name = "55岁以上";
    //           obj.value = ageTypeFb[item];
    //         } else if (item == "d3140") {
    //           obj.name = "31岁到40岁";
    //           obj.value = ageTypeFb[item];
    //         } else {
    //           obj.name = "41岁到50岁";
    //           obj.value = ageTypeFb[item];
    //         }
    //         arr.push(obj);
    //       }
    //       console.log(arr);
    //       ageTypeChart(arr);
    //     } else {
    //       this.error = res.data.msg;
    //       this.$message.error(res.data.msg, 3);
    //     }
    //   });
    // },
    // /**
    //  * 工人工种分布
    //  * */
    // getJobType(id) {
    //   getJobTitleFb(id).then((res) => {
    //     if (res.data.code === 0) {
    //       let arr = [];
    //       let workTypeFb = [];
    //       workTypeFb =
    //         res.data.data.length > 0
    //           ? res.data.data
    //           : [{ carft: "木工", num: 0 }];
    //       workTypeFb.forEach((item) => {
    //         arr.push({ name: item.carft, value: item.num });
    //       });
    //       workTypeChart(arr);
    //     } else {
    //       this.error = res.data.msg;
    //       this.$message.error(res.data.msg, 3);
    //     }
    //   });
    // },
    // /**
    //  * 监听统计换位中当前选择项目
    //  * */
    // onHandleChange(e) {
    //   this.getWorkTotal(e);
    //   this.getManagCWA(e);
    //   this.getSevenKq(e);
    //   this.getAgeType(e);
    //   this.getJobType(e);
    // },
  },
};
</script>
<style lang="less" scoped>
p {
  margin: 0;
}
th {
  background: rgb(244, 246, 255);
  padding: 4px 16px;
}
td {
  padding: 4px 16px;
  text-align: center;
}
.public {
  padding: 17px;
  background: #fff;
  border-radius: 10px;
}
.warning-content {
  position: absolute;
  top: 10px;
  left: 18px;
}
.table-num {
  display: inline-block;
  width: 30px;
  vertical-align: middle;
}
.table-content {
  display: inline-block;
  width: 180px;
  vertical-align: middle;
}
.table-suggest {
  display: inline-block;
  width: 100px;
  vertical-align: middle;
}
.salary-content {
  margin-top: 10px;
  font-size: 24px;
}
.green {
  color: rgb(4, 206, 0);;
}
.red {
  color: #FF718B;
}
.tb-box {
  height: 122px;
  overflow: hidden;
  overflow-y: auto;
}
.tb-box-2 {
  height: 155px;
  overflow: hidden;
  overflow-y: auto;
}
.tb-box-3 {
  height: 138px;
  overflow: hidden;
  overflow-y: auto;
}
.purple {
  background: #F4F6FF;
}
.light-blue {
  background: #EBF6FF
}
</style>
