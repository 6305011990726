<template>
  <div>
    <a-drawer
      :width="800"
      :visible="visible"
      title="题目信息"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose"
    > 
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }" layout="horizontal">
      <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="所属课程">
              <a-select
                :allowClear="true"
                style="width: 100%"
                placeholder="请选择课程"
                @change="changeCourse"
                v-decorator="['courseId', { rules: [{ required: true, message: '请选择课程' }] }]">
                <a-select-option v-for="t in courseDataSource" :key="t.id" :value="t.id">{{t.name}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="题目：">
              <a-textarea
                v-decorator="['questCont', { rules: [{  message: '请输入题目内容' }] }]"
               placeholder="题目内容"
               :auto-size="{ minRows: 3, maxRows: 5 }"
               />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="单/多选：">
              <a-radio-group v-decorator="['isMany',{initialValue:'1'}]">
                <a-radio :value="1">
                  单选
                </a-radio>
                <a-radio :value="2">
                  多选
                </a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row>
        
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="难易程度">
              <a-radio-group v-decorator="['level',{initialValue:'1'}]">
                <a-radio-button :value="1">
                  简单
                </a-radio-button>
                <a-radio-button :value="2">
                  一般
                </a-radio-button>
                <a-radio-button :value="3">
                  困难
                </a-radio-button>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="分值：">
              <a-input-number
                v-decorator="['core',{rules: [{ required: true, message: '请输入题目分值' }]}]"
                placeholder="题目分值"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item label="课时标签">
              <a-select
                mode="tags"
                placeholder="请您选择课时标签"
                v-model="tagsArray"
                @change="handleChange"
              >
                <a-select-option v-for="item in hourDataSource" :key="item.id">
                  {{item.name}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="题目解析：">
              <a-textarea
                v-decorator="['analysis', { rules: [{  message: '请输入题目解析' }] }]"
               placeholder="请输入题目解析"
               :auto-size="{ minRows: 3, maxRows: 5 }"
               />
            </a-form-item>
          </a-col>
        </a-row>
        <a-divider>答题选项<span style="font-size:13px;color:blue">(无需输入A,B,C,D等选项编码)</span></a-divider>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-card  size="small" :bordered="true">
              <template slot="title">
                <span>题目选项<span style="font-size:12px;color:blue">(最多添加六个选项)</span></span>
              </template>
              <a slot="extra" href="#" @click="addOptions">新增</a>
              <a-list size="small" item-layout="horizontal" :data-source="data">
                <a-list-item slot="renderItem" slot-scope="item, index">
                  <span v-if="item.isRight==1">(<a-icon  type="check" />)</span>
                  <span v-else>(<a-icon type="close" />)</span>
                  <a style="width: 230px;" href="#" @click="editorOptions(item)">选项{{index+1}}、{{ item.answerCont}}</a>
                  <span style="float:right">
                    <a-icon style="color:blue" type="edit" @click="editorOptions(item)" title="编辑"></a-icon>
                    <a-divider type="vertical" />
                    <a-icon style="color:red" type="delete" @click="deleteOptions(item)" title="删除"></a-icon>
                  </span>
                </a-list-item>
              </a-list>
            </a-card>
          </a-col>
          <a-col :span="12">
            <a-card  size="small" title="编辑选项" :bordered="true">
              <a slot="extra" href="#" @click="saveOptions">保存选项</a>
              <a-textarea
               v-model="options.answerCont"
               placeholder="选项内容"
               :auto-size="{ minRows: 3, maxRows: 5 }"
               />
               <a-switch checked-children="正确" un-checked-children="错误" default-checked v-model="options.isRight"/>
               <div style="color:red">{{errorMsg}}</div>
            </a-card>
          </a-col>
        </a-row>
    </a-form>
    <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginLeft: '8px' }" type="primary" @click="doSubmit">
          保存
        </a-button>
        <a-button :style="{ marginLeft: '8px' }" @click="onClose">
          关闭
        </a-button>
      </div>
    </a-drawer>
    
  </div>
</template>
<script>
import { request } from "@/utils/request";
import {ONLINE_QUERY_COURSE_GETALL,ONLINE_QUESTION_OPTIONS_QUERY,ONLINE_CLASS_HOUR_GETALL} from "@/services/api";
export default {
  name: "teamEditor",
  components: {},
  data() {
    return {
      form: this.$form.createForm(this),
      courseDataSource:[],
      obj:{},
      data:[],
      hourDataSource:[],
      hourTags:'',
      isEditor:false,
      options:{
        answerCont:null,
        isRight:true
      },
      errorMsg:null,
      selectOption:{},
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    question:{
      type: Object,
      require:true
    },
    type:{
      type: String,
      default: '1'
    },
  },
  created(){
    this.doQueryCourse();
  },
  mounted(){
    
  },
  computed:{
    tagsArray:{
      get(){
        if(this.hourTags!=null&&this.hourTags!=''){
          return this.hourTags.split(',');
        }else{
          return [];
        }
      },
      set(v) {
        this.hourTags = v
      }
      
    }
  },
  watch:{
    visible(val){
      if(val){
        this.obj = this.question;
        if(this.obj.courseId){
          this.doQueryCourseHours(this.obj.courseId)
        }
        setTimeout(()=>{
          this.form.setFieldsValue({
            'isMany': this.obj.isMany?this.obj.isMany:1,
            'level': this.obj.level?this.obj.level:1,
            'questCont': this.obj.questCont,
            'core': this.obj.core?this.obj.level:1,
            'analysis': this.obj.analysis,
            'courseId': this.obj.courseId?this.obj.courseId+"":null
          })
        })
        this.hourTags = this.obj.hourTags;
        //查询答题选项
        if(this.obj&&this.obj.id){
          this.queryOptions(this.obj.id);
        }
      }
    }
  },
  methods: {
    handleChange(values){
      this.hourTags = values.join(",");
    },
    doQueryCourseHours(courseId){
      request(ONLINE_CLASS_HOUR_GETALL,"post",{courseId:courseId}).then(res => {
          if(res.data.code==0){
           this.hourDataSource = res.data.data;
          }
       })
    },
    changeCourse(value){
      this.hourTags = null;
      this.doQueryCourseHours(value);
    },
    queryOptions(questId){
      if(questId){
        let obj = {
          questId:questId
        }
        request(ONLINE_QUESTION_OPTIONS_QUERY,"post",obj).then(res => {
          if(res.data.code==0){
            this.data = res.data.data;
          }
        })
      }
    },
    doQueryCourse(){
      request(ONLINE_QUERY_COURSE_GETALL,"post",{}).then(res => {
          if(res.data.code==0){
           this.courseDataSource = res.data.data.list;
          }
       })
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },
    onClose(){
      this.$emit('questPageClose');
    },
    doSubmit(){
       //数据校验
      this.form.validateFields((err, values) => {
        if (!err) {
          if(this.obj&&this.obj.id){
            values.id = this.obj.id
          }
          if(this.data.length<=0){
            this.$message.error("请添加答题选项！")
          }
          let rightDatas = this.data.filter(item => item.isRight);
          if(rightDatas&&rightDatas.length>0){
            this.$set(values,"optionsList",this.data);
            this.$set(values,"type",this.type);
            this.$set(values,"hourTags",this.hourTags);
            this.$emit('submit',{quest:values})
            this.hourTags = '';
            this.form.resetFields(["questCont","analysis"]);
            this.options={answerCont:'',isRight:false};
            this.data = [];
          }else{
            this.$message.error("答题选项必须存在一个正确答案！")
          }
        }
      })
    },
    saveOptions(){
      let isPush = true
      if(!this.options.answerCont){
        this.errorMsg="请输入选项内容。"
        return;
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          if(values.isMany==1){
            this.data.forEach(d=>{
              if(d.isRight&&this.options.isRight){
                this.errorMsg="保存失败！该题为单选题，不能同时存在两个或两个以上的正确答案。"
                isPush = false;
              }
            })
          }
        }else{
          this.errorMsg="请将题目的必填项输入完整。"
          isPush = false;
        }
      })
      if(this.data.length>=6){
        this.errorMsg="最多增加六个选项。"
        isPush = false;
      }
      if(isPush){
        let obj1 ={
          questId:this.question&&this.question.id?this.question.id:null,
          answerCont:this.options.answerCont,
          isRight:this.options.isRight
        }
        if(this.isEditor){
          this.data.splice(this.data.findIndex(item => item.answerCont == this.selectOption.answerCont), 1)
        }
        this.data.push(obj1);
        this.selectOption = {};
        this.options={answerCont:'',isRight:false};
        this.isEditor=false;
        this.errorMsg="";
      }
      
    },
    addOptions(){
      this.isEditor=false;
      this.options={answerCont:'',isRight:false};
    },
    editorOptions(item){
      this.isEditor=true;
      this.selectOption=item;
      this.options.answerCont=item.answerCont;
      this.options.isRight = item.isRight;
    },
    deleteOptions(obj){
      this.data.splice(this.data.findIndex(item => item.answerCont === obj.answerCont), 1)
    }
  }
};
</script>
<style>
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
/deep/ .ant-upload-list-picture-card .ant-upload-list-item{
  width: 127px;
  height: 127px;
}
</style>