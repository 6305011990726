<template>

    <div>
        <div>
            <a-row :gutter="16">
                <a-col :span="8">
                    <a-card title="公司结构" size="small" :bordered="false" style="height: 900px" :style="'overflow:auto'">
                        <a-tree
                                :expanded-keys="expandedKeys"
                                :tree-data="officeTreeData"
                                :auto-expand-parent="autoExpandParent"
                                :replaceFields="{children:'children', title:'name', key:'id' }"
                                @expand="onExpand"
                                @select="onSelect"
                        >
                            <!-- <template slot="title" slot-scope="{ title }">
                                <span>{{ title }}</span>
                            </template> -->
                        </a-tree>
                    </a-card>
                </a-col>
                <a-col :span="15" :offset="1">
                    <a-card title="用户列表" :bordered="false">
                        <a-form class="ant-advanced-search-form" :form="queryParam" >
                            <a-row :gutter="24">
<!--                                <a-col :span="6" >-->
<!--                                    <a-form-item label="登录名">-->
<!--                                        <a-input placeholder="请输入登录名" v-model="queryParam.phone"/>-->
<!--                                    </a-form-item>-->
<!--                                </a-col>-->
                                <a-col :span="6" >
                                    <a-form-item label="姓名">
                                        <a-input placeholder="请输入姓名" v-model="queryParam.name"/>
                                    </a-form-item>
                                </a-col>

                                <a-col :span="12">
                                    <a-form-model-item label="所属公司">
                                        <a-tree-select
                                                style="width: 100%"
                                                v-model="queryParam.parentOfficeId"
                                                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                                :tree-data="officeTreeData"
                                                placeholder="请选择直属上级单位"
                                                tree-default-expand-all
                                                :replaceFields="{children:'children', title:'name', key:'id', value:'id' }"
                                                :treeDefaultExpandAll="false"
                                                @select="queryOfficeId"
                                                :allowClear="true"
                                        >
                                        </a-tree-select>
                                    </a-form-model-item>
                                </a-col>
                            </a-row>
                            <a-row :gutter="24">
                                <a-col :span="6" >
                                    <a-form-item label="角色">
                                        <a-select placeholder="请选择" v-model="queryParam.roleId"
                                                  :allowClear="true">
                                            <a-select-option v-for="(item, index) in newRole" :key="item.id" :index="index" :value="item.roleId">{{item.roleName}}</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                </a-col>
                                <a-col :span="2" :style="{ textAlign: 'right', marginTop: '3px'}">
                                    <a-button type="primary" icon="search" :loading="loading" @click="handleSearch">
                                        查询
                                    </a-button>
                                </a-col>
                                <a-col :span="2" :style="{ textAlign: 'right', marginTop: '3px', marginLeft: '20px'}" >
                                    <a-button icon="plus" type="primary" @click="addOffice(queryParam.parentOfficeId)">
                                        新增
                                    </a-button>
                                </a-col>
                                <a-col :span="6" :offset="1" style="margin-top: 10px">
                                    <span style="color:red">注：查询项目人员请去“项目管理”</span>
                                </a-col>
                            </a-row>

                        </a-form>
                        <a-table
                                :columns="columns"
                                :data-source="officeData"
                                @expand="expand">

                            <template slot="action" slot-scope="text, record">
                                <span>
                                  <a @click="showModal(record, true)">修改</a>
                                  <a-divider type="vertical" />
                                  <a @click="del(record)">删除</a>
                                </span>
                            </template>
                        </a-table>
                    </a-card>
                </a-col>
            </a-row>
        </div>

        <div>
            <a-modal
                    :title="title"
                    :visible="visible"
                    :confirm-loading="confirmLoading"
                    @ok="modelOk"
                    @cancel="modelCancel"
                    :maskClosable="false"
                    width="760px"
            >
                <a-form-model :model="form"
                              :label-col="labelCol"
                              :wrapper-col="wrapperCol"
                              ref="ruleForm"
                              :rules="rules"
                              v-if="visible"
                              :selfUpdate="true"

                >
                    <a-form-model-item label="所属公司">
                        <a-tree-select
                                style="width: 100%"
                                v-model="form.officeId"
                                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                :tree-data="officeTreeData"
                                placeholder="请选择直属上级单位"
                                tree-default-expand-all
                                :replaceFields="{children:'children', title:'name', key:'id', value:'id' }"
                                :treeDefaultExpandAll="false"
                                @select="officeSelect"
                        >
                        </a-tree-select>
                    </a-form-model-item>
                    <a-form-model-item label="姓名" ref="name" prop="name"  required>
                        <a-input v-model="form.name" />
                    </a-form-model-item>
                    <a-form-model-item label="手机号" ref="phone" prop="phone" required>
                        <a-input v-model="form.phone" />
                    </a-form-model-item>
<!--                    <a-form-model-item label="登录名" ref="phone" prop="phone" required>-->
<!--                        <a-input v-model="form.loginName" />-->
<!--                    </a-form-model-item>-->
                    <a-form-model-item label="密码" ref="password" prop="password" :required="!isPwdShow">
                        <a-input v-model="form.password" placeholder="密码必须包含数字、大小写字母、特殊字符，且长度大于等于8个字符"/>
                    </a-form-model-item>
                    <a-form-model-item label="确认密码" ref="confirmPassWord" prop="confirmPassWord">
                        <a-input v-model="form.confirmPassWord" />
                    </a-form-model-item>

                    <a-form-model-item label="是否允许登录">
                        <a-select
                                v-model="form.loginFlag"
                                placeholder="是否允许登录"
                        >
                            <a-select-option key="1" value="1">
                                是
                            </a-select-option>
                            <a-select-option key="0" value="0">
                                否
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="新劳务用户角色">

                        <a-select
                                show-search
                                mode="multiple"
                                placeholder="请选择角色"
                                option-filter-prop="children"
                                :default-value="form.labourRoleIdList"
                                style="width: 200px"
                                :filter-option="filterOption"
                                @focus="handleFocus"
                                @blur="handleBlur"
                                @change="changeLabourRole"
                        >
                            <a-select-option v-for="(item, index) in newRole" :key="index" :value="item.roleId">
                                {{item.roleName}}
                            </a-select-option>
                        </a-select>

<!--                        <a-radio-group :options="newRole" :default-value="form.labourRoleId" @change="changeLabourRole"/>-->
                    </a-form-model-item>
                    <a-form-model-item label="备注">
                        <a-input v-model="form.remarks" />
                    </a-form-model-item>
                </a-form-model>

            </a-modal>
        </div>
    </div>
</template>


<script>
    import {getOfficeTree, getOfficeTypeList, getOfficeTreeSysUser,
        getRoleListBySysUserList, getRoleByOldUserId, saveLabourUser, delSysUser,
        getProjectListByOfficeId, searchLoginName} from '@/services/sys'
    import {mapGetters} from 'vuex'
    const columns = [
        {
            title: '手机号',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '归属公司',
            dataIndex: 'officeName',
            key: 'companyName',
        },
        {
            title: '角色',
            dataIndex: 'roleName',
            key: 'roleName',
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            scopedSlots: { customRender: 'action' }
        },
    ];


    export default {
        data() {
            let validatePassword = (rule, value, callback) => {
                if ((value === '' || value == null) && (this.form.id != null && this.form.id!=undefined)) {
                    callback();
                } else {
                    var patrn = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[~!@#$%^&*.)(_+}{|:?><]).{8,16}$/;
                    if (!patrn.exec(value)){
                        callback(new Error('密码必须包含数字、小写字母、大写字母、特殊字符，且长度大于等于8个字符'))
                    }
                    if (this.form.confirmPassWord !== '') {
                        this.$refs.ruleForm.validateField('validateConfirmPassWord');
                    }
                    callback();
                }
            };
            let validateConfirmPassWord = (rule, value, callback) => {
                if (this.form.password === '' || this.form.password == null){
                    callback();
                    return
                }
                if (value === '') {
                    callback(new Error('请输入确认密码'));
                } else if (value !== this.form.password) {
                    callback(new Error("两次密码不一致"));
                } else {
                    callback();
                }
            };
            return {

                // 筛选
                queryParam: {
                    phone: "",
                    name: "",
                    parentOfficeId: "",
                    roleId: undefined,
                    projectId: undefined
                },
                loading: false,
                projectList: [],


                // 树形结构
                expandedKeys: [],
                autoExpandParent: true,
                officeTreeData: [],

                // 数据表格
                columns,
                officeData: [],
                parentOfficeId: undefined,
                expandedRowKeys: [],

                // 详细数据弹窗
                title: "编辑",
                visible: false,
                confirmLoading: false,
                labelCol: { span: 6 },
                wrapperCol: { span: 12 },
                bindLaoWuUserList: [],
                defaultForm:{
                    officeId: undefined,
                },
                form:{
                    officeId: undefined,
                },
                newRole: [],
                oldRole: [],
                typeMap: {
                    underGroupList: [],
                    companyMiniTypeList: [],
                    companyTypeList: []
                },
                rules: {
                    name: [
                        { required: true, message: '请输入姓名', trigger: 'blur' },
                    ],
                    phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
                    // loginName: [{ required: true, message: '请输入登录名', trigger: 'blur' }],
                    password: [{ validator: validatePassword, trigger: 'change' }],
                    confirmPassWord: [{ validator: validateConfirmPassWord, trigger: 'change' }],
                },
                isPwdShow: false // 密码是否必填项
            };
        },
        computed: {
            ...mapGetters('account', ['user']),
        },
        created() {
            this.initData()
        },
        methods: {
            onExpand(expandedKeys) {
                this.expandedKeys = expandedKeys;
                this.autoExpandParent = false;
            },
            onSelect(selectedKeys, info) {
                this.queryParam.parentOfficeId = selectedKeys[0];
                this.queryOfficeId(this.queryParam.parentOfficeId, null, null);
                this.parentData();
            },
            handleSearch(){
                this.parentData()
            },
            initData() {
                if (this.user.nowRoleKey.includes("admin")){
                    this.queryParam.parentOfficeId = undefined
                }else{
                    this.queryParam.parentOfficeId = this.user.officeId
                }

                getOfficeTree({parentOfficeId: this.queryParam.parentOfficeId}).then(res => {
                    this.officeTreeData = res.data.data
                })

                getOfficeTypeList().then( res =>{
                    this.typeMap.underGroupList = res.data.data.underGroupList
                    this.typeMap.companyMiniTypeList = res.data.data.companyMiniTypeList
                    this.typeMap.companyTypeList = res.data.data.companyTypeList

                })

                getRoleListBySysUserList({}).then(res =>{
                    this.newRole = res.data.data.newRoleList
                    this.oldRole = res.data.data.oldRoleList

                    this.newRole.forEach(function (element, index, array) {
                        array[index].label = element.roleName
                        array[index].value = element.roleId

                    });

                    this.oldRole.forEach(function (element, index, array) {
                        array[index].label = element.name
                        array[index].value = element.id
                    });

                })

                getProjectListByOfficeId({parentOfficeId: this.queryParam.parentOfficeId}).then(res =>{
                    this.projectList = res.data.data
                })

            },
            parentData() {
                if (this.queryParam.parentOfficeId == undefined || this.queryParam.parentOfficeId == ""){
                    this.officeData = []
                    return
                }
                getOfficeTreeSysUser(this.queryParam).then(res => {
                    this.officeData = res.data.data
                })
            },
            expand(expanded, record){
            },

            showModal(record, isShow) {
                this.form = {
                    officeId: undefined,
                }
                this.isPwdShow = isShow // 密码是否必填项
                getRoleListBySysUserList({}).then(res =>{
                    this.newRole = res.data.data.newRoleList
                    this.oldRole = res.data.data.oldRoleList

                    this.newRole.forEach(function (element, index, array) {
                        array[index].label = element.roleName
                        array[index].value = element.roleId

                    });

                    this.oldRole.forEach(function (element, index, array) {
                        array[index].label = element.name
                        array[index].value = element.id
                    });

                })
                .finally(() => {
                    if (record.id!=undefined && record.id!=""){
                        // getRoleByOldUserId({oldUserId: record.id}).then(res=>{
                        //     const labourRole = res.data.data.labourRole;
                        //     const laowuRole = res.data.data.laowuRole;
                        //
                        //     var noExist = true
                        //     if (labourRole != undefined && labourRole != null){
                        //         noExist = true
                        //         this.newRole.forEach(function (element, index, array) {
                        //             if (element.roleId == labourRole.roleId){
                        //                 noExist = false;
                        //                 return
                        //             }
                        //         });
                        //         if (noExist){
                        //             this.newRole[this.newRole.length] = labourRole;
                        //         }
                        //
                        //         record.labourRoleId = labourRole.roleId;
                        //     }
                        //     if (laowuRole != undefined && laowuRole != null){
                        //         noExist = true
                        //         this.oldRole.forEach(function (element, index, array) {
                        //             if (element.id == laowuRole.id){
                        //                 noExist = false;
                        //                 return
                        //             }
                        //         });
                        //         if (noExist){
                        //             laowuRole.label = laowuRole.name
                        //             laowuRole.value = laowuRole.id
                        //             this.oldRole[this.oldRole.length] = laowuRole;
                        //         }
                        //
                        //         record.laowuRoleId = laowuRole.id;
                        //     }
                        //
                        // })
                        record.labourRoleIdList = record.roleIdList
                        this.visible = true;

                        console.log(this.form)
                    }else{
                        this.visible = true;
                    }
                    if (isShow){
                        this.form = {
                            id: parseInt(record.id),
                            labourRoleId: parseInt(record.labourRoleId),
                            labourRoleIdList: (record.labourRoleIdList),
                            name: record.name,
                            officeId: record.officeId,
                            officeName: record.officeName,
                            phone: record.phone,
                            remarks: record.remarks,
                            roleId: parseInt(record.roleId),
                            roleName: record.roleName
                        }
                        // this.form = record;
                    }else{
                        this.form = record;
                    }

                })



            },
            modelOk(e) {
                this.confirmLoading = true;
                this.$refs.ruleForm.validate(valid => {
                    if (valid) {
                        this.submit();
                    } else {
                        this.$message.error("请检查必填信息");
                        this.confirmLoading = false;
                        return false;
                    }
                });
            },
            modelCancel(e) {
                this.visible = false;
                this.bindLaoWuUserList = []
            },
            officeSelect(value, node, extra){
                this.form.officeId = value
            },
            queryOfficeId(value, node, extra){
                this.queryParam.parentOfficeId = value
                getProjectListByOfficeId({parentOfficeId: this.queryParam.parentOfficeId}).then(res =>{
                    this.projectList = res.data.data
                })
            },
            submit(){
                this.form.loginName = this.phone;
                saveLabourUser(this.form).then(res => {
                    if (res.data.code === 0){
                        this.$message.info(res.data.msg);
                        this.parentData()
                        this.visible = false;
                        this.modelCancel();
                    }else{
                        this.$message.error(res.data.msg);
                    }
                }).finally(()=>{
                    this.confirmLoading = false;
                })
            },
            addOffice(parentOfficeId){

                this.form = {
                    officeId: parentOfficeId,
                }
                this.showModal(this.form, false)
            },
            del(record){
                var _that = this
                this.$confirm({
                    title: '确认删除' + record.name + "?",
                    content: '要删除该账号吗？',
                    okText: '删除',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                        delSysUser({labourUserId: record.id}).then(res => {
                            _that.$message.info(res.data.msg);
                        })
                        _that.parentData()
                    },
                    onCancel() {
                    },
                });
            },
            changeLaoWuRole(e){
                var _that = this
                this.form.laowuRoleId = e.target.value
                this.oldRole.forEach(function (element, index, array) {
                    if (element.id == e.target.value){
                        _that.form.code = element.code;
                        return
                    }
                });
            },
            changeLabourRole(e){
                // this.form.labourRoleId = e.target.value
                this.form.labourRoleIdList = e
            },
            handleBlur() {
                console.log('blur');
            },
            handleFocus() {
                console.log('focus');
            },
            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.indexOf(input) >= 0
                );
            },

        },
    };
</script>

<style scoped>
    .ant-advanced-search-form {
        padding: 24px;
        background: #fbfbfb;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        margin-bottom: 10px;
    }

    .ant-advanced-search-form .ant-form-item {
        display: flex;
    }

    .ant-advanced-search-form .ant-form-item-control-wrapper {
        flex: 1;
    }

    #components-form-demo-advanced-search .ant-form {
        max-width: none;
    }

    tr{
        background-color: #ffffff;
    }

</style>
