<template>
  <div class="constructiontTable_index">
    <a-row :gutter="16">
      <a-col :span="7" v-if="isAdmin">
        <a-card
          title="公司结构"
          :bordered="false"
          style="height: 900px"
          :style="'overflow:auto'"
          class="card-left"
        >
          <a-tree
            :show-line="false"
            :expanded-keys="expandedKeys"
            :auto-expand-parent="true"
            @expand="onExpand"
            @select="onSelect"
            :tree-data="officeTreeData"
            :replaceFields="{ children: 'children', title: 'name', key: 'id' }"
          >
          </a-tree>
        </a-card>
      </a-col>
      <a-col :span="isAdmin ? 17 : 24">
        <a-card
          style="min-height: 900px"
          :style="'overflow:auto'"
          class="card-detail"
        >
          <a-tabs type="card" @change="callback" forceRender>
            <a-tab-pane key="1" tab="基本信息">
              <companyBasic :officeId="officeId" />
            </a-tab-pane>
            <!-- <a-tab-pane key="2" tab="班组列表">
              <companyTeams :officeId="officeId" />
            </a-tab-pane>
            <a-tab-pane key="3" tab="参建项目">
              <companyProject :officeId="officeId" />
            </a-tab-pane> -->
          </a-tabs>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import companyBasic from "@/pages/phasell/company/library/components/companyBasic";
// import companyTeams from "@/pages/phasell/company/library/components/companyTeams";
// import companyProject from "@/pages/phasell/company/library/components/companyProject";
import { mapGetters } from "vuex";
import { getOfficeTree } from "@/services/projectManagement";
export default {
  name: "SuijianClientCompanyDetails",
  components: {
    companyBasic,
    // companyTeams,
    // companyProject,
  },
  data() {
    return {
      // 筛选
      queryParam: {
        parentOfficeId: undefined,
        roleId: undefined,
        projectId: undefined,
        parentId: undefined,

      },
      // query
      params: {},
      officeId: "",
      isAdmin: false,
      // 树形结构
      expandedKeys: [],
      autoExpandParent: true,
      officeTreeData: [],
    };
  },
  watch: {
    officeId(newV, oldV) {
      if (newV) {
        this.officeId = this.$route.query.id ? this.$route.query.id :this.user.officeId
      }
    },
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  mounted() {
    // this.params = this.$route.query;
    // this.initData(); // 不需要公司结构拉
  },
  activated() {
    this.officeId = this.$route.query.id ? this.$route.query.id :this.user.officeId
  },
  methods: {
    initData() {
      if (this.user.nowRoleKey.includes("admin")) {
        // 广州机施建设集团有限公司
        this.queryParam.parentId = this.params.parentId;
      } else {
        this.queryParam.parentOfficeId = this.user.officeId;
        this.isAdmin = false
        this.officeId = this.$route.query.id ? this.$route.query.id :this.user.officeId
      }
      // getOfficeTree(this.queryParam).then(
      //   (res) => {
      //     this.officeTreeData = res.data.data;
      //   }
      // );
    },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    onSelect(selectedKeys) {
      this.queryParam.parentOfficeId = selectedKeys[0];
      this.officeId = selectedKeys[0];
      // this.queryOfficeId(this.queryParam.parentOfficeId, null, null);
      // this.parentData();
    },
    callback(key) {
      console.log(key);
    },
  },
};
</script>

<style lang="less" scoped>
.constructiontTable_index {
  /deep/ .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
    height: 56px;
  }
  /deep/ .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    height: 56px;
    line-height: 56px;
  }

  /deep/ .card-left .ant-card-body {
    padding: 10px 24px;
  }
  /deep/ .card-detail .ant-card-body {
    padding: 0;
  }
  // padding: 24px;
  // background: #fff;
  // border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-bottom: 10px;

  .operator {
    margin-left: 30px;
  }
  .c_i_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 25px;

    .c_i_h_center {
      padding: 16px;
      border-radius: 4px;
      width: 180px;
      height: 130px;
      margin-right: 50px;

      div:nth-child(1) {
        font-size: 16px;
      }

      div:nth-child(2) {
        margin-top: 26px;
        font-size: 24px;
      }
    }
  }
}
</style>
