<template>
  <div>
    <a-card title="被投诉项目" class="a_Approval" :bordered="false">
      <a-form-model ref="ruleForm" :model="params" :rules="rules">
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="工程管理部" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="gcglbId">
              <a-select  placeholder="请选择" @change="handlSelect" allowClear :filter-option="filterOption" v-model="params.gcglbId">
                  <a-select-option v-for="items of selectlist" :key="items.name" :value="items.value" :title="items.name">
                      {{ items.name }}
                  </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="被投诉项目" :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }" prop="projectName">
              <a-auto-complete v-model="params.projectName"
                placeholder="请输入" @search="handleChange" allowClear option-label-prop="value"
                @select="handlProjectNameSelect">
                <template slot="dataSource">
                    <a-select-option v-for="items in dataSource" :key="items.title + ''">
                        <span :title="items.title">{{ items.title }}</span>
                    </a-select-option>
                </template>
              </a-auto-complete>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="年度" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="year">
              <a-date-picker v-model="params.year" style="width: 100%" allowClear
                mode="year" @panelChange="changeYear" :format="'YYYY'"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="季度" :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }" prop="season">
              <a-select style="width: 100%" v-model="params.season">
                <a-select-option v-for="item in seasonList" :value="item.id" :key="item.id">
                  {{item.name}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="投诉类型" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="complainType">
              <a-select style="width: 100%" @change="handleComplaintChange" v-model="params.complainType">
                <a-select-option v-for="item in complaintList" :value="item.id" :key="item.id">
                  {{item.name}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="被扣分数" :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }" prop="subScore">
              <a-input v-model="params.subScore" :disabled="true"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="上传附件" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="filePath">
              <a-upload
                class="avatar-uploader"
                list-type="picture-card"
                :accept="accept"
                :show-upload-list="false"
                @change="handleUploadChange"
                :customRequest="resourceUpload"
                :beforeUpload="beforeUpload"
              >
                <img
                  v-if="params.filePath && params.filePath.indexOf('pdf') == -1"
                  :src="params.filePath"
                  alt="avatar"
                />
                <a-icon v-else-if="params.filePath && params.filePath.indexOf('pdf') > -1" type="file-pdf" :style="{ fontSize: '64px' }" />

                <div v-else>
                  <a-icon :type="uploading ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">上传附件</div>
                </div>
              </a-upload>
              <div
                style="color: #bfbfbf;font-size: 14px;margin-top: -28px;"   
              >
                图片或pdf格式
                <div style="margin-top: -20px;">
                  <a-button
                    v-if="params.filePath"
                    size="small"
                    type="link"
                    @click="handlePreview(params.filePath)"
                  >
                    预览
                  </a-button>
                  
                  <a-button
                    v-if="params.filePath"
                    type="link"
                    @click="handleClear"
                  >
                    清除
                  </a-button>
                </div>
              </div>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <a-row>
        <a-col :span="12"></a-col>
        <a-col :span="12">
          <a-form-model-item :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }" prop="year">
            <div class="flex flex-row-reverse">
              <a-button type="primary" @click="handleSubmit">保存</a-button>
              <a-button  class="mr-2" @click="close">返回</a-button>
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { QUERY_OFFICE_BY_USER } from "@/services/api";
import { request } from "@/utils/request";
import { mapGetters } from 'vuex'
import { getProjectByOffice } from "@/services/statistics";
import { safeExamineComplainInsert, safeExamineComplainDetail, safeExamineComplainUpdate } from "@/services/safeExamine";
import { ATTACHMENT_UPLOAD } from "@/services/api";
import moment from "moment";
export default {
  inject: ["fatherMethod"],
  computed: {
    ...mapGetters('account', ['user']),
  },
  data() {
    return {
      isEdit: 0,
      rules: {
        gcglbId: [{ required: true, message: '请选择工程管理部', trigger: 'change' }],
        projectName: [{ required: true, message: '请输入工程名称', trigger: 'change' }],
        year: [{ required: true, message: '请选择年份', trigger: 'change' }],
        season: [{ required: true, message: '请选择季度', trigger: 'change' }],
        complainType: [{ required: true, message: '请选择投诉类型', trigger: 'change' }],
        subScore: [{ required: true, message: '请选择投诉类型', trigger: 'change' }],
      },
      selectItemList: [],
      selectlist: [],
      dataSource: [],
      complaintList: [
        {id: 0, name: '投诉到集团公司', subScore: -3},
        {id: 1, name: '投诉到政府相关部门', subScore: -4},
        {id: 2, name: '投诉到省、市领导', subScore: -6},
      ],
      seasonList: [
        {id: 0, name: '第一季度'},
        {id: 1, name: '第二季度'},
        {id: 2, name: '第三季度'},
        {id: 3, name: '第四季度'}
      ],
      params: {
        gcglbId: '',
        gcglbName: '',
        projectId: '',
        projectName: '',
        year: moment(new Date()).format('YYYY'),
        creator: '',
        updator: '',
        subScore: null,
        filePath: ''
      },
      uploading: false,
      accept:'.png,.jpg,.jpeg,.pdf',//上传限制
    };
  },
  activated() {
    this.getgcglbList(QUERY_OFFICE_BY_USER);
    this.isEdit = Number(this.$route.query.isEdit);
    if (this.isEdit === 0) {
      this.params.creator = this.user.id
    } else {
      this.params.updator = this.user.id
      this.params.id = this.$route.query.id;
      this.getDetail()
    }
  },
  methods: {
    moment,
    getDetail() {
      safeExamineComplainDetail(this.params.id).then(res => {
        if (res.data.code === 0) {
          for (const key in res.data.data) {
            if (Object.hasOwnProperty.call(res.data.data, key)) {
              const el = res.data.data[key];
              this.$set(this.params, key, el)
            }
          }
          console.log(this.params)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 图片上传限制处理
    beforeUpload(file){
      const accept = '.' + file.name.substring(file.name.lastIndexOf(".") + 1);
      if (this.accept.split(',').indexOf(accept) == -1) {
          this.$message.error("只能上传图片、PDF文件");
          return false
      }
    },
    handleUploadChange(info) {
      if (info.file.status === "uploading") {
        this.uploading = true;
        return;
      }
      if (info.file.status === "done") {
        this.uploading = false;
      }
    },
    resourceUpload(value) {
      const formData = new FormData();
      formData.append("file", value.file);
      request(ATTACHMENT_UPLOAD, "post", formData).then((result) => {
        if (result.data.code === 0) {
          this.$message.success("上传成功!");
          this.params.filePath = result.data.data.path;
          value.onSuccess(result.data.data.path, value.file);
        } else {
          this.$message.warning(result.data.message);
        }
      });
    },
    handlePreview(url) {
      window.open(url);
    },
    handleClear() {
      this.params.filePath = ''
    },
    // 获取工程管理部数据
    getgcglbList(path) {
      request(path, "get").then((res) => {
        let data = res.data.data.filter(
          (item) => item.id != "3da0f5533f87425c9a9e8a11a7923300"
        );
        this.formDataFn("gcglbId", data);
      });
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      let arr = [];
      if (key == 'gcglbId') {
        data.forEach((item) => {
          arr.push({ name: item.master, value: item.id });
        });
      }
      this.selectlist = arr;
    },
    // 输入下拉框防止第一次数据没变渲染出来
    filterOption(input, option){
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    handlSelect(e) {
        this.params.gcglbId = e
        this.getProjectStatissKqS('')
    },
    handlProjectNameSelect(e) {
      this.params.projectId = this.dataSource.find(el => e == el.title).id
    },
    // 输入选择框触发
    handleChange(e) {
      // console.log(e)
      this.getProjectStatissKqS(e)
    },
    handleComplaintChange(e) {
      const obj = this.complaintList.find(el => e == el.id)
      this.params.subScore = obj.subScore
    },
    getProjectStatissKqS(projectName) {
      getProjectByOffice({gcglbId: this.params.gcglbId, projectName}).then((res) => {
        if (res.data.code === 0) {
              let arr = []
              res.data.data.forEach(el => {
                arr.push({
                  title: el.projectName,
                  id: el.projectId,
                  gcglbId: el.gcglbId
                })
              })
              this.dataSource = arr
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    handleSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.isEdit == 0) {
            safeExamineComplainInsert(this.params).then(res => {
              if (res.data.code === 0) {
                this.$message.success('提交成功！')
                this.close();
                this.$router.push({
                  name: "项目标准化季度检评",
                });
              } else {
                this.$message.error(res.data.msg)
              }
            })
          } else {
            safeExamineComplainUpdate(this.params).then(res => {
              if (res.data.code === 0) {
                this.$message.success('修改成功！')
                this.close();
                this.$router.push({
                  name: "项目标准化季度检评",
                });
              } else {
                this.$message.error(res.data.msg)
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 年度筛选
    changeYear(e) {
      this.params.year = e.format('YYYY')
    },
    close() {
      let path = this.$route.path;
      this.fatherMethod(`${path}`);
      this.$router.push({
        name: "项目标准化季度检评",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.a_Approval {
  background: #fff;
  border-radius: 10px;
}
.btn_group {
  padding-right: 190px;
}
.avatar-uploader > .ant-upload {
  img {
    width: 86px;
    height: 86px;
  }
}
</style>
