import {request, METHOD} from '@/utils/request'
import {GET_JW_WARN_SOLVE,GET_JW_WARN_TIP,DEL_ALERT_NOTIFY,GET_ALERT_USER_PHONE,GET_ALERT_NOTIFY_LIST,EDIT_ALERT_NOTIFY_SAVE,GET_NOTIFY_LIST,UPDATE_JW_WARN_RECORD} from '@/services/api'

/**
 * 预警设置-根据手机号查询通知人
 * @returns {Promise<AxiosResponse<T>>}
 * @param projectId: 项目id 
 * @param phone: 手机号
 */
export async function getUserPhone(params) {
  return request(GET_ALERT_USER_PHONE, METHOD.GET, params)
}

/**
 * 预警设置-根据手机号查询短信模板列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getNotifyList(params) {
  return request(GET_ALERT_NOTIFY_LIST, METHOD.GET, params)
}

/**
 * 预警设置-新增或修改通知人编辑
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function editNotify(params) {
  return request(EDIT_ALERT_NOTIFY_SAVE, METHOD.POST, params)
}

/**
 * 预警设置-删除通知人
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function delNotify(params) {
  return request(DEL_ALERT_NOTIFY + `/${params.id}`, METHOD.DELETE)
}

/**
 * 预警设置-列表
 * @returns {Promise<AxiosResponse<T>>}
 * @param projectId: 项目ID
 */
export async function getList(params) {
  return request(GET_NOTIFY_LIST, METHOD.POST, params)
}

/**
 * 查询建委预警提示
 * @returns {Promise<AxiosResponse<T>>}
 * @param typeNum：建委预警编号，预警列表里的warmNum
 */
export async function getJwWarnTip(params) {
  return request(GET_JW_WARN_TIP, METHOD.GET, params)
}

/**
 * 查询建委处理状态
 * @returns {Promise<AxiosResponse<T>>}
 * @param jwWarnOddNum：建委预警单号，预警列表里的warmCode
 */
export async function getJwWarnSolve(params) {
  return request(GET_JW_WARN_SOLVE, METHOD.GET, params)
}

/**
 * 查询建委处理状态
 * @returns {Promise<AxiosResponse<T>>}
 * @param jwWarnOddNum：建委预警单号，预警列表里的warmCode
 */
export async function updateJwWarnRecord(params) {
  return request(UPDATE_JW_WARN_RECORD, METHOD.POST, params)
}