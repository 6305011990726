<template>
  <div >
    <a-card :title="title" class="a_Approval" :bordered="false">
      <form-index
        ref="formindex"
        :formData="formData"
        @handleSubmit="submit"
        :projectList="projectList"
        @handleChange="handleChange"
        @handlSelect="handlSelect"
        :bottomSubmit="true"
      >
        <!-- 下部分按钮插槽 -->
        <template #bottomSubmit>
          <a-button type="primary" html-type="submit"> 确定 </a-button>
          <a-button @click="close"> 关闭 </a-button>
        </template>
      </form-index>
    </a-card>
  </div>
</template>

<script>
const formData = [
  {
    label: "项目名称",
    placeholder: "请输入项目名称",
    decorator: [
      "projectName",
      { rules: [{ required: true, message: "请输入项目名称" }] },
    ],
    type: "inputGroup",
    key: "projectName",
    dataSource: [],
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
    disabled: false
  },
  {
    label: '工程管理部',
    placeholder: '请选择工程管理部',
    decorator: ['gcglbId', { rules: [{ required: true, message: '请选择工程管理部' }] }],
    type: 'select',
    key: 'gcglbId',
    selectlist: [],
    qb:false,
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    disabled: true,
  },
  {
    label: "自编号",
    placeholder: "请填写",
    decorator: [
      "selfNum",
      { rules: [{ required: true, message: "请填写" }] },
    ],
    type: "input",
    key: "selfNum",
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "设备型号",
    placeholder: "请填写",
    decorator: [
      "facilityNum",
      { rules: [{ required: true, message: "请填写" }] },
    ],
    type: "input",
    key: "facilityNum",
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "公司验收日期",
    placeholder: "请选择",
    decorator: [
      "acceptDate",
      { rules: [{ required: false, message: "请选择" }] },
    ],
    type: "datePicker",
    format: "yyyy-MM-DD",
    key: "acceptDate",
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
    styles: {width: '100%'}
  },
  {
    label: "状态",
    placeholder: "请选择状态",
    decorator: [
      "status",
      { rules: [{ required: true, message: "请选择状态" }] },
    ],
    type: "select",
    key: "status",
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    selectlist: [
      {
        value: 0,
        name: "使用中",
      },
      {
        value: 1,
        name: "取证中",
      },
      {
        value: 2,
        name: "停用",
      },
      {
        value: 3,
        name: "待安装",
      },
    ],
  },
  {
    label: "制造单位名称",
    placeholder: "请填写",
    decorator: [
      "makeUnitName",
      { rules: [{ required: true, message: "请填写" }] },
    ],
    type: "input",
    key: "makeUnitName",
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "设备出厂编号",
    placeholder: "请填写",
    decorator: [
      "factoryNum",
      { rules: [{ required: true, message: "请填写" }] },
    ],
    type: "input",
    key: "factoryNum",
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "设备出厂日期",
    placeholder: "请选择",
    decorator: [
      "factoryDate",
      { rules: [{ required: true, message: "请选择" }] },
    ],
    type: "datePicker",
    format: "yyyy-MM-DD",
    key: "factoryDate",
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
    styles: {width: '100%'}
  },
  {
    label: "设备产权单位名称",
    placeholder: "请填写",
    decorator: [
      "equityUnit",
      { rules: [{ required: true, message: "请填写" }] },
    ],
    type: "input",
    key: "equityUnit",
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "产权登记编号",
    placeholder: "请填写",
    decorator: [
      "equityNum",
      { rules: [{ required: true, message: "请填写" }] },
    ],
    type: "input",
    key: "equityNum",
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "租赁合同编号",
    placeholder: "请填写",
    decorator: [
      "rentContractNum",
      { rules: [{ required: true, message: "请填写" }] },
    ],
    type: "input",
    key: "rentContractNum",
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "安装单位名称",
    placeholder: "请填写",
    decorator: [
      "installUnit",
      { rules: [{ required: true, message: "请填写" }] },
    ],
    type: "input",
    key: "installUnit",
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "安装资质等级",
    placeholder: "请选择",
    decorator: [
      "installQualityLevel",
      { rules: [{ required: true, message: "请选择" }] },
    ],
    type: 'select',
    key: 'installQualityLevel',
    selectlist: [
      { name: '壹级', value: '壹级' },
      { name: '贰级', value: '贰级' },
    ],
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
  },
  {
    label: "安装合同编号",
    placeholder: "请填写",
    decorator: [
      "installContractNum",
      { rules: [{ required: false, message: "请填写" }] },
    ],
    type: "input",
    key: "installContractNum",
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  // {
  //   label: "拆卸合同编号",
  //   placeholder: "请填写",
  //   decorator: [
  //     "splitContractNum",
  //     { rules: [{ required: false, message: "请填写" }] },
  //   ],
  //   type: "input",
  //   key: "splitContractNum",
  //   col: 8,
  //   labelCol: 8,
  //   wrapperCol: 12,
  //   display: true,
  // },
  {
    label: "方案审批情况",
    placeholder: "请选择",
    decorator: [
      "approveStatus",
      { rules: [{ required: true, message: "请选择" }] },
    ],
    type: "select",
    key: "approveStatus",
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    selectlist: [
      {
        value: '0',
        name: "未审批",
      },
      {
        value: '1',
        name: "已审批",
      },
      {
        value: '2',
        name: "审批中",
      },
    ],
  },
  {
    label: "安装完成日期",
    placeholder: "请选择",
    decorator: [
      "finishDate",
      { rules: [{ required: false, message: "请选择" }] },
    ],
    type: "datePicker",
    format: "yyyy-MM-DD",
    key: "finishDate",
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
    styles: {width: '100%'}
  },
  {
    label: "安装告知编号",
    placeholder: "请填写",
    decorator: [
      "installNum",
      { rules: [{ required: false, message: "请填写" }] },
    ],
    type: "input",
    key: "installNum",
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
];
import formIndex from "@/pages/components/form/index";
import { QUERY_OFFICE_BY_USER } from "@/services/api";
import { request } from "@/utils/request";
import { insertFacility, getProjectDetail } from "@/services/equipmentLedger";
import { mapGetters } from 'vuex'
import { getProjectByOffice } from "@/services/statistics";
export default {
  components: {
    formIndex,
  },
  inject: ["fatherMethod"],
  data() {
    return {
      title: '',
      formData: formData,
      params: {
        facilityType: null,
        projectId: ''
      },
      projectArr: [], //项目名称
      projectList: [], //获取当前项目数据
    };
  },
  computed: {
    ...mapGetters('account', ['user']),
  },
  activated() {
    this.formData[0].dataSource = []//进来初始化一下
    this.title = this.$route.name
    this.getgcglbList(QUERY_OFFICE_BY_USER);
    if (this.user.loginType == 0) {  //项目账号
      this.params.projectId = this.user.loginLaoWuUser.projectId
      getProjectDetail(this.params.projectId).then(res => {
        if (res.data.code === 0) {
          this.$refs.formindex.queryObj({projectName: res.data.data.fullName, gcglbId: res.data.data.gcglbId})
        } else {
          this.$message.error(res.data.msg);
        }
      })
      this.formData[0].disabled = true
    } else {
      this.formData[0].disabled = false
    }
    this.params.facilityType = Number(this.$route.query.facilityType);
    switch(this.params.facilityType) {
      case 1: 
      case 4: 
      case 5: 
        if (this.formData.some(item => item.key === 'instaliSafeProtocal')) {
          this.formData.splice(this.formData.findIndex(item => item.key === 'instaliSafeProtocal'), 1)
        }
        // if (this.formData.some(item => item.key === 'splitSafeProtocal')) {
        //   this.formData.splice(this.formData.findIndex(item => item.key === 'splitSafeProtocal'), 1)
        // }
        if (this.formData.some(item => item.key === 'factoryPassNum')) {
          this.formData.splice(this.formData.findIndex(item => item.key === 'factoryPassNum'), 1)
        }
        break
      case 2: 
        if (this.formData.some(item => item.key === 'factoryPassNum')) {
          this.formData.splice(this.formData.findIndex(item => item.key === 'factoryPassNum'), 1)
        }
        if (!this.formData.some(item => item.key === 'instaliSafeProtocal')) {
          this.formData.splice(this.formData.length - 2, 0, {
            label: "安装安全管理协议",
            placeholder: "请填写",
            decorator: [
              "instaliSafeProtocal",
              { rules: [{ required: false, message: "请填写" }] },
            ],
            type: "input",
            key: "instaliSafeProtocal",
            col: 8,
            labelCol: 8,
            wrapperCol: 12,
            display: true,
          })
        }
        // if (!this.formData.some(item => item.key === 'splitSafeProtocal')) {
        //   this.formData.splice(this.formData.length - 2, 0, {
        //     label: "拆卸安全管理协议",
        //     placeholder: "请填写",
        //     decorator: [
        //       "splitSafeProtocal",
        //       { rules: [{ required: false, message: "请填写" }] },
        //     ],
        //     type: "input",
        //     key: "splitSafeProtocal",
        //     col: 8,
        //     labelCol: 8,
        //     wrapperCol: 12,
        //     display: true,
        //   })
        // }
        break
      case 3: 
        if (this.formData.some(item => item.key === 'instaliSafeProtocal')) {
          this.formData.splice(this.formData.findIndex(item => item.key === 'instaliSafeProtocal'), 1)
        }
        // if (this.formData.some(item => item.key === 'splitSafeProtocal')) {
        //   this.formData.splice(this.formData.findIndex(item => item.key === 'splitSafeProtocal'), 1)
        // }
        if (!this.formData.some(item => item.key === 'factoryPassNum')) {
          this.formData.splice(this.formData.length - 2, 0, {
            label: "设备出厂合格证编号",
            placeholder: "请填写",
            decorator: [
              "factoryPassNum",
              { rules: [{ required: true, message: "请填写" }] },
            ],
            type: "input",
            key: "factoryPassNum",
            col: 8,
            labelCol: 8,
            wrapperCol: 12,
            display: true,
          })
        }
        break
    }
  },
  methods: {
    // 输入选择框触发
    handleChange(e) {
      getProjectByOffice({projectName: e.value}).then(res => {
        this.projectArr = [];
        if (res.data.code === 0) {
          res.data.data.forEach((item) => {
            this.projectArr.push({
              title: item['projectName'],
              id: item.projectId,
            });
          });
          this.projectList = res.data.data;
          this.params.projectId = ''
          // // 获取当前匹配key，赋值相对于数组
          this.formData.forEach((item) => {
            if (e.item.key === item.key) {
              item.dataSource = this.projectArr;
            }
          });
        }
      })
    },
    handlSelect({ val, keys }) {
      console.log(val, keys)
      if (keys === 'projectName') {
        this.projectList.forEach((item) => {
          if (item.projectId == val) {
            this.params.projectId = val
            this.$refs["formindex"].setFieldsValue(item.projectName, {}, "projectName");
            getProjectDetail(this.params.projectId).then(res => {
              if (res.data.code === 0) {
                this.$refs.formindex.queryObj({projectName: res.data.data.fullName, gcglbId: res.data.data.gcglbId})
              } else {
                this.$refs.formindex.queryObj({gcglbId: ''})
                this.$message.error(res.data.msg);
              }
            })
          }
        });
      }
    },
    // 获取工程管理部数据
    getgcglbList(path) {
      request(path, "get").then((res) => {
        let data = res.data.data.filter(
          (item) => item.id != "3da0f5533f87425c9a9e8a11a7923300"
        );
        this.formDataFn("gcglbId", data);
      });
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      let arr = [];
      data.forEach((item) => {
        arr.push({ name: item.master, value: item.id });
      });
      this.formData.forEach((item) => {
        if (item.key == key) {
          item.selectlist = arr;
        }
      });
    },
    submit(e) {
      this.params = Object.assign(this.params, e)
      insertFacility(this.params).then((res) => {
        let d = res.data;
        if (d.code === 0) {
          this.$message.success("新增成功");
          this.close()
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    close() {
      let path = this.$route.path;
      this.fatherMethod(`${path}`);
    },
  },
};
</script>

<style lang="less" scoped>
.a_Approval {
  background: #fff;
  border-radius: 10px;
}</style>
