<template>
  <div>
    <a-card title="新增动态扣分" class="a_Approval" :bordered="false">
      <a-form-model ref="ruleForm" :model="params.projectSubPoint" :rules="rules">
        <a-row>
          <a-col :span="12">
            <a-form-model-item v-if="user.isGcglb == 0 && user.loginType == 1" label="工程管理部" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="gcglbId">
              <a-select  placeholder="请选择" @change="handlSelect" allowClear :filter-option="filterOption" v-model="params.projectSubPoint.gcglbId" :disabled="isEdit == 1">
                  <a-select-option v-for="items of selectlist" :key="items.name" :value="items.value" :title="items.name">
                      {{ items.name }}
                  </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item v-else label="工程管理部" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="gcglbName">
              <a-input  placeholder="请输入" v-model="params.projectSubPoint.gcglbName" :disabled="true"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item v-if="user.isGcglb == 0 && user.loginType == 0" label="工程名称" :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }" prop="projectName">
              <a-input :data-source="dataSource" placeholder="请输入" v-model="params.projectSubPoint.projectName" :disabled="true"/>
            </a-form-model-item>
            <a-form-model-item v-else label="工程名称" :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }" prop="projectName">
              <a-auto-complete v-model="params.projectSubPoint.projectName" :disabled="isEdit == 1"
                placeholder="请输入" @search="handleChange" allowClear option-label-prop="value"
                @select="handlProjectNameSelect">
                <template slot="dataSource">
                    <a-select-option v-for="items in dataSource" :key="items.title + ''">
                        <span :title="items.title">{{ items.title }}</span>
                    </a-select-option>
                </template>
              </a-auto-complete>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="扣分年份" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="year">
              <a-date-picker v-model="params.projectSubPoint.year" style="width: 100%" allowClear :disabled="isEdit == 1"
                mode="year" @panelChange="changeYear" :format="'YYYY'"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item class="ml-3" :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }" v-if="!params.projectSubPoint.id">
              <a-button type="primary" @click="getDeductPoint">填写扣分表</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-card>
    <template v-if="params.projectSubPoint.id">
      <div class="mt-2">
        <a-row :gutter="16">
          <a-col :span="12">
            <a-card class="a_Approval pl-3 pr-3" :bordered="false">
              <div class="deduct_point_box-1">
                <a-form-model :model="params" ref="companyForm">
                  <a-form-model-item :wrapper-col="{ span: 24 }" prop="projectSubPoint.company"
                    :rules="{
                      required: false,
                      message: '请输入分数',
                      trigger: 'blur',
                    }">
                    <div class="flex a-center">
                      <!-- <span style="color:#f5222d; margin-right: 4px">*</span> -->
                      <span class="flex-1">施工企业扣分情况</span>
                      <span class="ml-2">{{`共扣${params.projectSubPoint.company}分`}}</span>
                    </div>
                  </a-form-model-item>
                  <a-form-model-item
                    v-for="(domain, index) in params.details.filter(el => el.subType == 1)"
                    :key="index"
                    :rules="{
                      comment: [{required: false, message: '请输入扣分项', trigger: 'blur',}],
                      score: [{required: false, message: '请输入扣分数', trigger: 'blur',}]
                    }"
                  >
                    <!-- <span style="color:#f5222d; margin-right: 4px">*</span> -->
                    <a-row :gutter="16">
                      <a-col :span="16">
                        <a-form-model-item
                          :prop="'details.' + index + '.comment'"
                        >
                          <!-- <a-input
                            v-model="domain.comment"
                            placeholder="请输入扣分项"
                          /> -->
                          <a-select v-model="domain.comment" style="width: 100%" placeholder="请选择扣分项" @change="handleItemChange">
                            <a-select-option :value="item.comment" v-for="item in selectItemList" :key="item.id">
                              {{`${item.pointCode} ${item.comment}`}}
                            </a-select-option>
                          </a-select>
                        </a-form-model-item>
                      </a-col>
                      <a-col :span="8">
                        <a-form-model-item
                          :prop="'details.' + index + '.score'"
                        >
                          <a-input
                            class="text-center"
                            v-model="domain.score"
                            placeholder="扣分数"
                            addonAfter="分"
                            :disabled="true"
                            style="width: 70%;"
                          />
                          <a-icon
                            class="dynamic-delete-button ml-2"
                            type="minus-circle-o"
                            :disabled="params.details.length === 1"
                            @click="removeDomain(domain)"
                          />
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                  </a-form-model-item>
                  <a-form-model-item>
                    <a-button type="dashed" style="width: 95%" @click="addDomain(1)">
                      <a-icon type="plus" /> 添加扣分项
                    </a-button>
                  </a-form-model-item>
                </a-form-model>
              </div>
            </a-card>
          </a-col>
          <a-col :span="12">
            <a-card class="a_Approval pl-3 pr-3" :bordered="false">
              <div class="deduct_point_box-2">
                <a-form-model :model="params" ref="majorPeopleForm">
                  <a-form-model-item :wrapper-col="{ span: 24 }" prop="projectSubPoint.majorPeople"
                    :rules="{
                      required: false,
                      message: '请输入分数',
                      trigger: 'blur',
                    }">
                    <div class="flex a-center">
                      <!-- <span style="color:#f5222d; margin-right: 4px">*</span> -->
                      <span class="flex-1">施工企业主要负责人累计扣分情况</span>
                      <span class="ml-2">{{`共扣${params.projectSubPoint.majorPeople}分`}}</span>
                    </div>
                  </a-form-model-item>
                  <a-form-model-item
                    v-for="(domain, index) in params.details.filter(el => el.subType == 2)"
                    :key="index"
                    :rules="{
                      comment: [{required: false, message: '请输入扣分项', trigger: 'blur',}],
                      score: [{required: false, message: '请输入扣分数', trigger: 'blur',}]
                    }"
                  >
                    <!-- <span style="color:#f5222d; margin-right: 4px">*</span> -->
                    <a-row :gutter="16">
                      <a-col :span="16">
                        <a-form-model-item
                          :prop="'details.' + index + '.comment'"
                        >
                          <!-- <a-input
                            v-model="domain.comment"
                            placeholder="请输入扣分项"
                          /> -->
                          <a-select v-model="domain.comment" style="width: 100%" placeholder="请选择扣分项" @change="handleItemChange">
                            <a-select-option :value="item.comment" v-for="item in selectItemList" :key="item.id">
                              {{`${item.pointCode} ${item.comment}`}}
                            </a-select-option>
                          </a-select>
                        </a-form-model-item>
                      </a-col>
                      <a-col :span="8">
                        <a-form-model-item
                          :prop="'details.' + index + '.score'"
                        >
                          <a-input
                            class="text-center"
                            v-model="domain.score"
                            placeholder="扣分数"
                            addonAfter="分"
                            :disabled="true"
                            style="width: 70%;"
                          />
                          <a-icon
                            class="dynamic-delete-button ml-2"
                            type="minus-circle-o"
                            :disabled="params.details.length === 1"
                            @click="removeDomain(domain)"
                          />
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                  </a-form-model-item>
                  <a-form-model-item>
                    <a-button type="dashed" style="width: 95%" @click="addDomain(2)">
                      <a-icon type="plus" /> 添加扣分项
                    </a-button>
                  </a-form-model-item>
                </a-form-model>
              </div>
            </a-card>
          </a-col>
        </a-row>
        <!-- <a-row :gutter="16" class="mt-2">
          <a-col :span="12">
            <a-card class="a_Approval pl-3 pr-3" :bordered="false">
              <div class="deduct_point_box-3">
                <a-form-model :model="params" ref="projectPeopleForm">
                  <a-form-model-item :wrapper-col="{ span: 24 }" prop="projectSubPoint.projectPeople"
                    :rules="{
                      required: false,
                      message: '请输入分数',
                      trigger: 'blur',
                    }">
                    <div class="flex a-center">
                      <span class="flex-1">施工企业项目负责人累计扣分情况</span>
                      <span class="ml-2">{{`共扣${params.projectSubPoint.projectPeople}分`}}</span>
                    </div>
                  </a-form-model-item>
                  <a-form-model-item
                    v-for="(domain, index) in params.details.filter(el => el.subType == 3)"
                    :key="index"
                    :rules="{
                      comment: [{required: false, message: '请输入扣分项', trigger: 'blur',}],
                      score: [{required: false, message: '请输入扣分数', trigger: 'blur',}]
                    }"
                  >
                    <a-row :gutter="16">
                      <a-col :span="16">
                        <a-form-model-item
                          :prop="'details.' + index + '.comment'"
                        >
                          <a-select v-model="domain.comment" style="width: 100%" placeholder="请选择扣分项" @change="handleItemChange">
                            <a-select-option :value="item.comment" v-for="item in selectItemList" :key="item.id">
                              {{item.comment}}
                            </a-select-option>
                          </a-select>
                        </a-form-model-item>
                      </a-col>
                      <a-col :span="8">
                        <a-form-model-item
                          :prop="'details.' + index + '.score'"
                        >
                          <a-input
                            class="text-center"
                            v-model="domain.score"
                            placeholder="扣分数"
                            addonAfter="分"
                            :disabled="true"
                            style="width: 70%;"
                          />
                          <a-icon
                            class="dynamic-delete-button ml-2"
                            type="minus-circle-o"
                            :disabled="params.details.length === 1"
                            @click="removeDomain(domain)"
                          />
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                  </a-form-model-item>
                  <a-form-model-item>
                    <a-button type="dashed" style="width: 95%" @click="addDomain(3)">
                      <a-icon type="plus" /> 添加扣分项
                    </a-button>
                  </a-form-model-item>
                </a-form-model>
              </div>
            </a-card>
          </a-col>
          <a-col :span="12">
            <a-card class="a_Approval pl-3 pr-3" :bordered="false">
              <div class="deduct_point_box-4">
                <a-form-model :model="params" ref="safePeopleForm">
                  <a-form-model-item :wrapper-col="{ span: 24 }" prop="projectSubPoint.safePeople"
                    :rules="{
                      required: false,
                      message: '请输入分数',
                      trigger: 'blur',
                    }">
                    <div class="flex a-center">
                      <span class="flex-1">施工企业专职安全员累计扣分情况</span>
                      <span class="ml-2">{{`共扣${params.projectSubPoint.safePeople}分`}}</span>
                    </div>
                  </a-form-model-item>
                  <a-form-model-item
                    v-for="(domain, index) in params.details.filter(el => el.subType == 4)"
                    :key="index"
                    :rules="{
                      comment: [{required: false, message: '请输入扣分项', trigger: 'blur',}],
                      score: [{required: false, message: '请输入扣分数', trigger: 'blur',}]
                    }"
                  >
                    <a-row :gutter="16">
                      <a-col :span="16">
                        <a-form-model-item
                          :prop="'details.' + index + '.comment'"
                        >
                          <a-select v-model="domain.comment" style="width: 100%" placeholder="请选择扣分项" @change="handleItemChange">
                            <a-select-option :value="item.comment" v-for="item in selectItemList" :key="item.id">
                              {{item.comment}}
                            </a-select-option>
                          </a-select>
                        </a-form-model-item>
                      </a-col>
                      <a-col :span="8">
                        <a-form-model-item
                          :prop="'details.' + index + '.score'"
                        >
                          
                          <a-input
                            class="text-center"
                            v-model="domain.score"
                            placeholder="扣分数"
                            addonAfter="分"
                            :disabled="true"
                            style="width: 70%;"
                          />
                          <a-icon
                            class="dynamic-delete-button ml-2"
                            type="minus-circle-o"
                            :disabled="params.details.length === 1"
                            @click="removeDomain(domain)"
                          />
                        </a-form-model-item>
                      </a-col>
                    </a-row>
                  </a-form-model-item>
                  <a-form-model-item>
                    <a-button type="dashed" style="width: 95%" @click="addDomain(4)">
                      <a-icon type="plus" /> 添加扣分项
                    </a-button>
                  </a-form-model-item>
                </a-form-model>
              </div>
            </a-card>
          </a-col>
        </a-row> -->
        <div class="flex flex-row-reverse btn_group mt-2">
          <a-button type="primary" class="ml-3" @click="submit"> 确定 </a-button>
          <a-button @click="close"> 关闭 </a-button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { QUERY_OFFICE_BY_USER2 } from "@/services/api";
import { request } from "@/utils/request";
import { mapGetters } from 'vuex'
import { getProjectByOffice } from "@/services/statistics";
import { insertSubPoint, submitSubPoint, getSubPointDetail, subPointItemList } from "@/services/dynamicDeductPoint";
import moment from "moment";
export default {
  inject: ["fatherMethod"],
  computed: {
    ...mapGetters('account', ['user']),
  },
  data() {
    return {
      isEdit: 0,
      rules: {
        gcglbId: [{ required: true, message: '请选择工程管理部', trigger: 'change' }],
        projectName: [{ required: true, message: '请输入工程名称', trigger: 'change' }],
        year: [{ required: true, message: '请选择扣分年份', trigger: 'change' }],
      },
      selectItemList: [],
      selectlist: [],
      dataSource: [],
      params: {
        details: [],
        projectSubPoint: {
          id: '',
          company: 0,
          majorPeople: 0,
          // projectPeople: 0,
          // safePeople: 0,
          gcglbId: '',
          gcglbName: '',
          projectId: '',
          projectName: '',
          year: moment(new Date()).format('YYYY'),
          creator: '',
          updator: ''
        }
      }
    };
  },
  watch: {
    'params.details': {
      handler(val) {
        this.params.projectSubPoint.company = 0
        this.params.projectSubPoint.majorPeople = 0
        // this.params.projectSubPoint.projectPeople = 0
        // this.params.projectSubPoint.safePeople = 0
        val.forEach(el => {
          if (el.subType == 1) {
            this.params.projectSubPoint.company += Number(el.score)
          } else if (el.subType == 2) {
            this.params.projectSubPoint.majorPeople += Number(el.score)
          } 
          // else if (el.subType == 3) {
          //   this.params.projectSubPoint.projectPeople += Number(el.score)
          // } else if (el.subType == 4) {
          //   this.params.projectSubPoint.safePeople += Number(el.score)
          // }
        })
      },
      deep: true
    }
  },
  activated() {
    this.getSubPointItemList()
    this.getgcglbList(QUERY_OFFICE_BY_USER2);
    this.isEdit = Number(this.$route.query.isEdit);
    if (this.isEdit === 0) {
      this.params.projectSubPoint.creator = this.user.id
    } else {
      this.params.projectSubPoint.updator = this.user.id
      this.params.projectSubPoint.id = this.$route.query.id;
      this.getDetail()
    }
    if (this.user.isGcglb == 1 && this.user.loginType == 1) {
      this.params.projectSubPoint.gcglbId = this.user.officeLoginData.officeId
      this.params.projectSubPoint.gcglbName = this.user.officeLoginData.officeName
    }
    if (this.user.isGcglb == 0 && this.user.loginType == 0) {
      this.params.projectSubPoint.projectName = this.user.loginLaoWuUser.projectName
      this.params.projectSubPoint.projectId = this.user.loginLaoWuUser.projectId
      setTimeout(() => {
        getProjectByOffice({projectId: this.user.loginLaoWuUser.projectId}).then((res) => {
          if (res.data.code === 0) {
            this.params.projectSubPoint.gcglbId = res.data.data[0].gcglbId
            this.params.projectSubPoint.gcglbName = this.selectlist.find(el => el.value == res.data.data[0].gcglbId).name
          } else {
            this.$message.error(res.data.msg)
          }
        });
      }, 500);
    }
  },

  methods: {
    getSubPointItemList() {
      subPointItemList({pageSize: 999, pageNumber: 1}).then(res => {
        if (res.data.code === 0) {
          this.selectItemList = res.data.data.list
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    handleItemChange(e) {
      const itemObj = this.selectItemList.find(i => i.comment == e)
      this.params.details = this.params.details.map(el => {
        if (el.comment == e) {
          return {
            comment: itemObj.pointCode + el.comment,
            pointId: el.pointId,
            score: itemObj.score,
            subType: el.subType,
            pointCode: itemObj.pointCode
          }
        } else {
          return el
        }
      })
    },
    getDetail() {
      getSubPointDetail(this.params.projectSubPoint.id).then(res => {
        if (res.data.code === 0) {
          for (const key in res.data.data) {
            if (Object.hasOwnProperty.call(res.data.data, key)) {
              const el = res.data.data[key];
              this.$set(this.params, key, el)
            }
          }
          this.$set(this.params.projectSubPoint, 'gcglbId', res.data.data.projectSubPoint.gcblbId)
          console.log(this.params)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 获取工程管理部数据
    getgcglbList(path) {
      request(path, "get").then((res) => {
        let data = res.data.data.filter(
          (item) => item.id != "3da0f5533f87425c9a9e8a11a7923300"
        );
        this.formDataFn("gcglbId", data);
      });
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      let arr = [];
      if (key == 'gcglbId') {
        data.forEach((item) => {
          arr.push({ name: item.master, value: item.id });
        });
      }
      this.selectlist = arr;
    },
    // 输入下拉框防止第一次数据没变渲染出来
    filterOption(input, option){
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    handlSelect(e) {
        this.params.projectSubPoint.gcglbId = e
        this.getProjectStatissKqS('')
    },
    handlProjectNameSelect(e) {
      this.params.projectSubPoint.projectId = this.dataSource.find(el => e == el.title).id
    },
    // 输入选择框触发
    handleChange(e) {
      // console.log(e)
      this.getProjectStatissKqS(e)
    },
    getProjectStatissKqS(projectName) {
      getProjectByOffice({gcglbId: this.params.projectSubPoint.gcglbId, projectName}).then((res) => {
        if (res.data.code === 0) {
              let arr = []
              res.data.data.forEach(el => {
                arr.push({
                  title: el.projectName,
                  id: el.projectId,
                  gcglbId: el.gcglbId
                })
              })
              this.dataSource = arr
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    getDeductPoint() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let obj = {...this.params.projectSubPoint}
          insertSubPoint(obj).then(res => {
            if (res.data.code === 0) {
              this.$message.success('操作成功！')
              for (const key in res.data.data.projectSubPoint) {
                if (Object.hasOwnProperty.call(res.data.data.projectSubPoint, key)) {
                  const el = res.data.data.projectSubPoint[key];
                  this.$set(this.params.projectSubPoint, key, el)
                }
              }
            } else {
              this.$message.error(res.data.msg)
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    removeDomain(item) {
      const _that = this
      this.$confirm({
        title: '确认删除？',
        onOk() {
          let index = _that.params.details.indexOf(item);
          if (index !== -1) {
            _that.params.details.splice(index, 1);
          }
        }
      })
    },
    addDomain(e) {
      this.params.details.push({
        subType: e,
        pointId: this.params.projectSubPoint.id,
        comment: '',
        score: null
      });
    },
    submit() {
      const formArr = [
        this.$refs.companyForm.validate(), 
        this.$refs.majorPeopleForm.validate(), 
        // this.$refs.projectPeopleForm.validate(), 
        // this.$refs.safePeopleForm.validate()
      ]
      Promise.all(formArr).then(valid => {
        if (valid) {
          // console.log(this.params)
          submitSubPoint(this.params).then(res => {
            if (res.data.code === 0) {
              this.$message.success('提交成功！')
              this.close();
              this.$router.push({
                name: "动态扣分台账",
              });
            } else {
              this.$message.error(res.data.msg)
            }
          })
        }
      })
    },
    // 年度筛选
    changeYear(e) {
      this.params.projectSubPoint.year = e.format('YYYY')
    },
    close() {
      let path = this.$route.path;
      this.fatherMethod(`${path}`);
    },
  },
};
</script>

<style lang="less" scoped>
.a_Approval {
  background: #fff;
  border-radius: 10px;
}
.btn_group {
  padding-right: 190px;
}
.ant-form-item {
  margin-bottom: 14px;
}
</style>
