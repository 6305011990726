var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          staticStyle: { top: "20px" },
          attrs: { title: "公司关联", width: 1400 },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c("a-row"),
          _c("a-table", {
            attrs: { columns: _vm.columns, "data-source": _vm.data },
            scopedSlots: _vm._u([
              {
                key: "isBigPlatform",
                fn: function (isBigPlatform, record) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          record.accessno != null &&
                            record.accessno != "" &&
                            record.accesskey != null &&
                            record.accesskey != ""
                            ? "是"
                            : "否"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "编辑",
            visible: _vm.visibleEdit,
            "confirm-loading": _vm.confirmLoading,
            width: "560px",
          },
          on: { ok: _vm.projectBingOffice, cancel: _vm.closeEdit },
        },
        [
          _vm.visible
            ? _c(
                "a-form-model",
                {
                  ref: "ruleForm",
                  attrs: {
                    model: _vm.form,
                    "label-col": _vm.labelCol,
                    "wrapper-col": _vm.wrapperCol,
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "所属公司" } },
                    [
                      _c("a-tree-select", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          "dropdown-style": {
                            maxHeight: "400px",
                            overflow: "auto",
                          },
                          "tree-data": _vm.officeTreeData,
                          placeholder: "请选择直属上级单位",
                          "tree-default-expand-all": "",
                          replaceFields: {
                            children: "children",
                            title: "name",
                            key: "id",
                            value: "id",
                          },
                          treeDefaultExpandAll: false,
                        },
                        on: { select: _vm.officeSelect },
                        model: {
                          value: _vm.form.officeId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "officeId", $$v)
                          },
                          expression: "form.officeId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "甲方联系人" } },
                    [
                      _c("a-input", {
                        model: {
                          value: _vm.form.nailcontacts,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "nailcontacts", $$v)
                          },
                          expression: "form.nailcontacts",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      ref: "manager",
                      attrs: { label: "负责人", prop: "manager", required: "" },
                    },
                    [
                      _c("a-input", {
                        model: {
                          value: _vm.form.manager,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "manager", $$v)
                          },
                          expression: "form.manager",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      ref: "phoneNumber",
                      attrs: {
                        label: "联系方式",
                        prop: "phoneNumber",
                        required: "",
                      },
                    },
                    [
                      _c("a-input", {
                        model: {
                          value: _vm.form.phoneNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "phoneNumber", $$v)
                          },
                          expression: "form.phoneNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      ref: "companyRole",
                      attrs: {
                        label: "单位类型",
                        prop: "companyRole",
                        required: "",
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: { placeholder: "请选择单位类型" },
                          model: {
                            value: _vm.form.companyRole,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "companyRole", $$v)
                            },
                            expression: "form.companyRole",
                          },
                        },
                        _vm._l(_vm.companyTypeList, function (item, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: item.value } },
                            [_vm._v(" " + _vm._s(item.label) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "开户名称(选填)" } },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: { placeholder: "请选择开户行" },
                          model: {
                            value: _vm.form.projBankName,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "projBankName", $$v)
                            },
                            expression: "form.projBankName",
                          },
                        },
                        _vm._l(_vm.bankList, function (item, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: item.name } },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "工资支付账户(选填)" } },
                    [
                      _c("a-input", {
                        model: {
                          value: _vm.form.projBankAccount,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "projBankAccount", $$v)
                          },
                          expression: "form.projBankAccount",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      ref: "accessno",
                      attrs: {
                        label: "大平台接入编号(选填)",
                        prop: "accessno",
                      },
                    },
                    [
                      _c("a-input", {
                        model: {
                          value: _vm.form.accessno,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "accessno", $$v)
                          },
                          expression: "form.accessno",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      ref: "accesskey",
                      attrs: {
                        label: "大平台接入密钥(选填)",
                        prop: "accesskey",
                      },
                    },
                    [
                      _c("a-input", {
                        model: {
                          value: _vm.form.accesskey,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "accesskey", $$v)
                          },
                          expression: "form.accesskey",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }