var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-tabs",
        { attrs: { "default-active-key": _vm.defaultActiveKey } },
        [
          _c(
            "a-tab-pane",
            { key: "1", attrs: { tab: "投诉" } },
            [
              _c("complaint-list-modal", {
                attrs: { gcglbId: _vm.gcglbId, year: _vm.year },
              }),
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "2", attrs: { tab: "约谈" } },
            [
              _c("interview-list-modal", {
                attrs: { gcglbId: _vm.gcglbId, year: _vm.year },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }