import moment from 'moment'

export const useRender = {
  /**
   * 渲染文本，将text与val 拼接到一起
   * @param text 文本1
   * @param val 文本2
   * @returns 文本1 + 文本2
   */
  renderText: (text, val) => {
    if (text)
      return `${text} ${val}`
    else
      return ''
  },
  /**
   * 渲染日期
   * @param {number|string} text 时间戳或日期
   * @param {string} [format='YYYY-MM-DD HH:mm:ss'] 自定义所需格式
   * @param {string} defaultText 默认文本
   * @returns 格式化后日期
   */
  renderDate: (text, format = 'YYYY-MM-DD HH:mm:ss', defaultText = '/') => {
    if (!text) {
      return defaultText
    }

    // 兼容可能出现日期字符串的情况
    if (typeof text === 'string') {
      return text
    }

    return moment(text).format(format)
  },
}
