<template>
    <div>
        <a-spin tip="加载中..." :spinning="spinning">
            <a-form-model
                    ref="ruleForm"
                    :model="form"
                    :rules="rules"
                    :label-col="labelCol"
                    :wrapper-col="wrapperCol"
            >
                <a-form-model-item ref="title" label="标题" prop="title">
                    <a-input v-model="form.title" />
                </a-form-model-item>
                <a-form-model-item ref="wordCode" label="编号" prop="wordCode">
                    <a-input v-model="form.wordCode" />
                </a-form-model-item>

                <a-form-model-item ref="showDoc" label="预览" prop="showDoc">
                    <a @click="showDrawer">点击预览模板</a>
                </a-form-model-item>

                <a-form-model-item label="内容" prop="content">

    <!--                <ckeditor :editor="editor" v-model="form.content" :config="editorConfig"></ckeditor>-->
                    <!-- <div id="editor"></div> -->
                    <keep-alive>
                        <tinymce ref="tinymceEditor" :value="form.content" :plugins="plugins"  @input="contentInput"></tinymce>
                    </keep-alive>
                </a-form-model-item>
            </a-form-model>
        </a-spin>

            <a-drawer
                    title="文档模板预览"
                    :placement="'right'"
                    :closable="true"
                    :visible="drawerVisible"
                    @close="closeDrawer"
                    :keyboard="true"
                    :maskClosable="true"
                    :destroyOnClose="true"
                    width="780px"
                    :bodyStyle="bodyStyle"
            >

                <!-- <a-spin tip="加载中..." :spinning="spinningDrawer"> -->
                    <!-- <a-carousel arrows dots-class="slick-dots slick-thumb" :dot-position="'top'">
                        <a slot="customPaging" slot-scope="props">
                            <img :src="getImgUrl(props.i)" />
                        </a>
                        <div v-for="(item, index) in base64List" :key="index">
                            <img :src="item" />
                        </div>
                    </a-carousel> -->
                <!-- </a-spin> -->
                <!-- pdf预览 -->
                <iframe :src="base64List" class="fddPdf" style="width: 100%"></iframe>
            </a-drawer>

    </div>
</template>

<script>
    // import WangEditor from "wangeditor"
    import Tinymce from "@/components/tinymce"
    import {showDoc} from '@/services/wordConfig'

    export default {
        name: 'WordContentEdit',
        components: {Tinymce},
        data() {
            return {
                spinning: true,
                // 表单
                labelCol: { span: 2 },
                wrapperCol: { span: 22 },
                plugins:"lists image table colorpicker textcolor contextmenu",
                form: {
                    id: 0,
                    title: '',
                    wordCode: '',
                    content: '',
                    isCustom: 0,
                },
                rules: {
                    title: [
                        { required: true, message: '请输入标题', trigger: 'blur' },
                    ],
                    content: [{ required: true, message: '请输入内容', trigger: 'blur' }],
                },

                // 富文本--wangeditor
                editor: null,

                // 展示模板
                spinningDrawer: true,
                drawerVisible: false,
                base64List: null,
                bodyStyle:{
                    paddingBottom: "10px",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    paddingTop: "0px"
                }


            }
        },
        methods: {
            contentInput(e){
                this.form.content = e;
            },
            showModal(word) {
                this.form.title = word.title;
                this.form.content = word.content;
                this.form.isCustom = word.isCustom;
                this.form.wordCode = word.wordCode;
                this.form.hierarchy = word.hierarchy;
                this.form.id = word.id;
                this.spinning = false;
                // this.initWangEditor()
            },
            callBack(){
                console.log("富文本初始化完成！")
                this.spinning = false
            },
            handleOk() {
                // this.form.content = this.editor.txt.html()
                return this.form
            },
            // initWangEditor() {
            //     this.editor = new WangEditor('#editor')
            //     //这里各位小伙伴可以查询官网，根据自己的需求进行菜单栏调整
            //     // 配置菜单栏，删减菜单，调整顺序
            //     this.editor.config.menus = [
            //         'undo',
            //         'redo',
            //         'image',
            //     ]
            //     this.editor.config.placeholder = '请输入文档内容'
            //     this.editor.config.uploadImgShowBase64 = true // 允许上传本地图片
            //     this.editor.config.showLinkImg = false // 隐藏插入网络图片
            //     // this.editor.config.uploadImgMaxSize = 1 * 1024 * 1024 // 2M  没什么用
            //     console.log("开始创建富文本")
            //     this.editor.create()
            //     this.editor.txt.html(this.form.content)
            //     if (this.form.isCustom == 0){
            //         this.editor.disable()
            //     }else{
            //         this.editor.enable()
            //     }
            //     // 配置 onchange 回调函数
            //     let that = this;
            //     this.editor.config.onchange = function (newHtml) {
            //         var regExp=/alt=".*?"/g;
            //         var regExp2 = /data:image.*?base64,/g;
            //         // console.log('change 之后最新的 html1', newHtml.replace(regExp,"").replace(regExp2,"data:;base64,"))
            //         that.form.content = that.editor.txt.html().replace(regExp,"").replace(regExp2,"data:;base64,")
            //     }
            //     this.spinning = false
            // },
            // beforeDestroy() {
            //     console.log("开始销毁富文本")
            //     // 调用销毁 API 对当前编辑器实例进行销毁
            //     this.editor.destroy()
            //     this.editor = null
            // },
            showDrawer() {
                this.drawerVisible = true;
                // this.spinningDrawer = true;
                showDoc(this.form).then((res) => {
                    // console.log("res：", res)
                    this.base64List = res.data.data;
                    // this.spinningDrawer = false;
                })
            },
            closeDrawer() {
                this.drawerVisible = false;
                this.base64List = null
            },
            getImgUrl(i) {
                return this.base64List[i];
            },

        }
    }
</script>
<style scoped>
    /* For demo */
    .ant-carousel >>> .slick-dots {
        height: auto;
    }
    .ant-carousel >>> .slick-slide img {
        border: 5px solid #fff;
        display: block;
        margin: auto;
        /*max-width: 90%;*/
    }
    .ant-carousel >>> .slick-thumb {
        bottom: -45px;
    }
    .ant-carousel >>> .slick-thumb li {
        width: 60px;
        height: 45px;

    }
    .ant-carousel >>> .slick-thumb li img {
        width: 100%;
        height: 100%;
        filter: grayscale(100%);
        border: 1px solid #8c8c8c;
    }
    .ant-carousel >>> .slick-thumb li.slick-active img {
        filter: grayscale(0%);
        border: 1px solid #13C2C2;
    }

    /*.ant-drawer-body{*/
    /*    padding-bottom: 24px;*/
    /*    padding-left: 24px;*/
    /*    padding-right: 24px;*/
    /*}*/
    
</style>
<style  lang="less" scoped>
    .fddPdf {
        width:100%;
        height:90vh;
        border:none;
    }
</style>