var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header-search" },
    [
      _c("a-icon", {
        staticClass: "search-icon",
        attrs: { type: "search" },
        on: { click: _vm.enterSearchMode },
      }),
      _c("a-auto-complete", {
        ref: "input",
        class: ["search-input", _vm.searchMode ? "enter" : "leave"],
        attrs: {
          getPopupContainer: function (e) {
            return e.parentNode || _vm.document.body
          },
          dataSource: _vm.dataSource,
          placeholder: "站内搜索",
        },
        on: { blur: _vm.leaveSearchMode },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }