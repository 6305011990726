<template>
  <a-modal width="660px" v-model="show" :title="title" :footer="null" @cancel="close">
      <a-form-model ref="ruleForm" :model="params" :rules="rules">
        <a-row>
          <a-col :span="24">
            <a-form-model-item label="公司" :label-col="{ span:3 }" :wrapper-col="{ span: 14 }">
              <a-input v-model="params.companyName" disabled placeholder=""/>
            </a-form-model-item>
          </a-col>
          
          <a-col :span="24">
            <a-form-model-item label="项目名称" :label-col="{ span: 3 }" :wrapper-col="{ span: 14 }" >
              <a-input v-model="params.projectName" disabled placeholder=""/>
              
            </a-form-model-item>
          </a-col>
          
          <a-col :span="24">
            <a-form-model-item label="附件" :label-col="{ span: 3 }" :wrapper-col="{ span: 14 }">
              <a-upload class="avatar-uploader" list-type="picture-card" :accept="accept1" :file-list="filePath1"
                @change="handleUploadChange1" :customRequest="resourceUpload1" @preview="handlePreview"
                :beforeUpload="beforeUpload1">

                <div v-if="filePath1.length < 1">
                  <a-icon :type="uploading1 ? 'loading' : 'plus'" />
                </div>
                <!-- <div class="tit-fi">支持pdf格式上传</div> -->
              </a-upload>
            </a-form-model-item>
          </a-col>
          

        </a-row>
      </a-form-model>
      <a-row>
        <a-col :span="12"></a-col>
        <a-col :span="12">
          <a-form-model-item :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }" prop="year">
            <div class="flex flex-row-reverse">
              <a-button type="primary" :loading="sbloading" @click="handleSubmit">保存</a-button>
              <a-button class="mr-2" @click="close">返回</a-button>
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>
  </a-modal>
</template>

<script>

import { request } from "@/utils/request";
import { mapGetters } from 'vuex'
import moment from "moment";
import { riskmapAdd,riskmapUpdate, riskmapByID } from "@/services/riskControll";
import { ATTACHMENT_UPLOAD } from "@/services/api";
import gcglib from './mixinGm'

export default {
  name: 'etDetail',
  computed: {
    ...mapGetters('account', ['user']),
  },
  props: {
      visible: {
          type: Boolean,
          default: false
      },
      isEdit: {
          type: Number,
          default: 0
      },
      id: {
          default: ''
      },
      projectInfo: {
        default: () => {
          return {}
        }
      }
      

  },
  mixins: [gcglib],
  data() {
    return {
      // isEdit: 0,
      rules: {
        // name: [{ required: true, message: '请输入 ', trigger: 'change' }],
        
      },

      params: {
          "companyId": "gfjdkaglf3gfdgr2324",          //公司ID
          "companyName": "公司名称1",        //公司名称
          "branchOfficeId":"32432",      //分公司ID
          "branchOfficeName":"分公司名称1",   //分公司名称
          "projectId":"5gfdygfhgny78765", //项目ID
          "projectName": "项目名称1",        //项目名称
          "projectMap":"" //风险地图
      },
      sbloading: false,
      title: '新增',
      show: false,

    };
  },
 
  watch: {
    visible(val) {
      console.log(val)
      
      if (this.isEdit == 0) {
          // dom 渲染后
        
        this.params = {
            "companyId": this.projectInfo.parentOfficeId,          //公司ID
            "companyName": this.projectInfo.parentOffice,        //公司名称
            "branchOfficeId":this.projectInfo.gcglbId,      //分公司ID
            "branchOfficeName":this.projectInfo.gcglbName,   //分公司名称
            "projectId":this.projectInfo.projectId, //项目ID
            "projectName": this.projectInfo.projectName,        //项目名称
            "projectMap":"" //风险地图
        }
        // 新增 birthday 取 30年前
        // this.params.birthday = moment().subtract(30, 'years').format('YYYY-MM-DD');
        // this.params.age = 30;
        this.filePath1 = [];
        this.title = '新增 项目平面图'
      } else {
          this.title = '编辑 项目平面图'
          this.params.id = this.id;
          this.getDetail()

      }
      this.show = val;
    }
  },
  methods: {
    getDetail() {
      riskmapByID({id: this.params.id}).then(res => {
        if (res.data.code == 0) {
          this.params = res.data.data;
          if (this.params.projectMap) {
              let att = this.params.projectMap
              // let name = this.params.attachName.split(',')
            this.filePath1 = att.split(',').map((item,index) => {
              return {
                name: index,
                url: item,
                status: 'done',
                uid: index,
              }
            })
          } else {
            this.filePath1 = []
          }
         
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },

    handleSubmit() {
      this.sbloading = true
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let d = this.params;  
          let attach1 = this.filePath1.map(item => item.url).join(',');
          let attachname1 = this.filePath1.map(item => item.name).join(',');
          d.projectMap = attach1;
          // d.attachName = attachname1;

          if (this.isEdit == 0) {
            riskmapAdd(d).then(res => {
              if (res.data.code == 0) {
                this.$message.success('提交成功！')
                this.close();
                this.$emit('refresh')
              } else {
                this.$message.error(res.data.msg)
              }
              this.sbloading = false
            })
          } else {
            riskmapUpdate(d).then(res => {
              if (res.data.code == 0) {
                this.$message.success('修改成功！')
                this.close();
                this.$emit('refresh')
              } else {
                this.$message.error(res.data.msg)
              }
              this.sbloading = false
            })
          }
        } else {
          console.log('error submit!!');
          this.sbloading = false
          return false;
        }
      });
    },
    close() {
      this.resetForm()
      this.$emit('closeDialog')
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
      this.filePath1 = []
    },
    
  },
};
</script>

<style lang="less" scoped>
.a_Approval {
  background: #fff;
  border-radius: 10px;
}

.btn_group {
  padding-right: 190px;
}
</style>
