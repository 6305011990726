<template>
    <div>
        <a-card :bordered="false">

            <div id="components-form-demo-advanced-search">
                <a-form class="ant-advanced-search-form" :form="searchForm" >
                    <a-row :gutter="24">
                        <a-col :span="6" >
                            <a-form-item :label="`公司名称`">
                                <a-input v-model="searchForm.officeName" placeholder="请输入角色名称" />
                            </a-form-item>
                        </a-col>

                        <a-col :span="4" >
                            <a-form-item :label="`状态`">
                                <a-select
                                        v-model="searchForm.status"
                                >
                                    <a-select-option key="-1" value="-1">
                                        全部
                                    </a-select-option>
                                    <a-select-option key="1" value="1">
                                        待审核
                                    </a-select-option>
                                    <a-select-option key="2" value="2">
                                        已通过
                                    </a-select-option>
                                    <a-select-option key="3" value="3">
                                        已拒绝
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>

                        <a-col :span="4" :style="{ textAlign: 'right', marginTop: '3px'}">
                            <a-button type="primary" icon="search" :loading="loading" @click="handleSearch">
                                查询
                            </a-button>
                        </a-col>
                    </a-row>

                    <a-row>
                        <a-button type="primary" @click="add()">
                            新增
                        </a-button>
                    </a-row>

                </a-form>
            </div>
            <a-table
                    :columns="columns"
                    :data-source="data"
            >

                <template slot="businessLicense" slot-scope="text">
                    <img :src="text" width="120px;"  @click="showImg(text)">
                    <a-modal :visible="previewVisible" :footer="null" :mask="false" :bodyStyle="{padding: '40px'}"  @cancel="closeImgShow">
                        <img alt="example" style="width: 100%;" :src="previewImage" />
                    </a-modal>
                </template>

                <template slot="action" slot-scope="text, record">
                        <span v-if="record.status == 1">
                          <a @click="passAuth(record)">通过</a>
                          <a-divider type="vertical" />
                          <a @click="showBackReason(record)">打回</a>
                        </span>
                </template>
            </a-table>
        </a-card>


        <div>
            <a-modal
                    :title="title"
                    :visible="visible"
                    :confirm-loading="confirmLoading"
                    @ok="backAuth"
                    @cancel="closeBackReason"
                    width="560px"
            >
                <a-form-model :model="form"
                              :label-col="labelCol"
                              :wrapper-col="wrapperCol"
                              ref="ruleForm"
                              :rules="rules"
                              v-if="visible"
                >
                    <a-form-model-item label="打回原因" ref="callBackReason" prop="callBackReason"  required>
                        <a-textarea
                                v-model="form.callBackReason"
                                placeholder="请输入打回原因"
                                :auto-size="{ minRows: 5, maxRows: 16 }"
                        />
                    </a-form-model-item>

                </a-form-model>




            </a-modal>
        </div>

    </div>



</template>

<script>
    import {getOfficeAuthList, authOfficeStatus} from '@/services/realName'
    import pick from 'lodash.pick'
    import { useRender } from '@/hooks/useRender.js'

    const columns = [
        {
            dataIndex: 'id',
            key: 'id',
            title: '申请编号',
        },
        {
            title: '公司名',
            dataIndex: 'officeName',
            key: 'officeName',
        },
        {
            title: '统一社会信用代码',
            dataIndex: 'officeCode',
            key: 'officeCode',
        },
        {
            title: '营业执照照片',
            dataIndex: 'businessLicense',
            key: 'businessLicense',
            slots: { title: 'businessLicense' },
            scopedSlots: { customRender: 'businessLicense' },
        },
        {
            title: '联系人',
            key: 'contacts',
            dataIndex: 'contacts',
        },
        {
            title: '联系电话',
            key: 'phone',
            dataIndex: 'phone',
        },
        {
            title: '联系人',
            key: 'contacts',
            dataIndex: 'contacts',
        },
        {
            title: '实名状态',
            key: 'status',
            dataIndex: 'status',
            customRender: (text) => {
                return text === 1? "待审核" : text === 2? "审核通过" : "审核拒绝"
            }
        },
        {
            title: '提审时间',
            key: 'updateTime',
            dataIndex: 'updateTime',
            customRender: (text) => useRender.renderDate(text)
        },
        {
            title: '打回原因',
            key: 'callBackReason',
            dataIndex: 'callBackReason',
        },
        {
            title: '操作',
            key: 'action',
            scopedSlots: { customRender: 'action' },
        },
    ];

    export default {
        data() {
            return {
                // 筛选
                searchForm: {
                    name: "",
                    status: "-1"
                },
                loading: false,

                // 数据表格
                data: [],
                columns,

                // 详细数据弹窗
                title: "打回原因",
                visible: false,
                confirmLoading: false,
                labelCol: { span: 6 },
                wrapperCol: { span: 12 },
                form:{
                    status: "0",
                    callBackReason: ""
                },

                previewVisible: false,
                previewImage: ""

            };
        },

        created() {
            this.getOfficeAuthList()
        },
        methods: {
            getOfficeAuthList() {
                getOfficeAuthList(this.searchForm).then(res => {
                    console.log(res)
                    this.data = res.data.data
                })
            },
            handleSearch(){
                this.getOfficeAuthList();
            },
            authOfficeStatus(){
                authOfficeStatus(this.form).then(res=>{
                    if (res.data.code === 0){
                        this.$message.info(res.data.msg);
                        this.getOfficeAuthList()
                    }else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            passAuth(record){
                let _that = this
                this.$confirm({
                    title: '审核通过',
                    content: '确定要通过' + record.officeName + "?",
                    okText: '确认',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                        _that.form.id = record.id
                        _that.form.code = record.officeCode
                        _that.form.status = 2
                        _that.form.callBackReason = ""
                        _that.authOfficeStatus()
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });

            },
            showBackReason(record){
                this.form.id = record.id
                this.form.code = record.officeCode
                this.form.status = 3
                this.form.callBackReason = ""
                this.visible = true;
            },
            closeBackReason(){
                this.visible = false;
            },
            backAuth(record){
                this.authOfficeStatus()
                this.visible = false;
            },
            closeImgShow() {
                this.previewVisible = false;
            },
            showImg(imgPath){
                this.previewVisible = true;
                this.previewImage = imgPath;
            },

        }
    };
</script>


<style scoped>
    .ant-advanced-search-form {
        padding: 24px;
        background: #fbfbfb;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        margin-bottom: 10px;
    }

    .ant-advanced-search-form .ant-form-item {
        display: flex;
    }

    .ant-advanced-search-form .ant-form-item-control-wrapper {
        flex: 1;
    }

    #components-form-demo-advanced-search .ant-form {
        max-width: none;
    }

    tr{
        background-color: #ffffff;
    }

</style>
