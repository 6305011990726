<template>
  <div>
    <a-row :gutter="16">
      <a-col :span="6">
        <vue-scroll :ops="ops" style="width:100%;height:855px">
        <a-card size="small" :bordered="false" @click="clearMenu">
          <template slot="title">
              <strong>课程管理</strong>
          </template>
          <a-tree :load-data="onLoadData" :tree-data="treeData" show-icon default-expand-all @select="doNodeClick">
            <img src="@/assets/img/type.png" style="width:20px;height:20px"  slot="typeIcon" />
            <img src="@/assets/img/course.png" style="width:20px;height:20px"  slot="courseIcon" />
            <img src="@/assets/img/class.png" style="width:20px;height:20px"  slot="classIcon" />
            <template slot="title" slot-scope="item">
                <!-- <div  @mouseenter="mouseenter(item)" @mouseleave="mouseleave(item)"> -->
                  <span @click="treeNodeClick(item)">{{ item.name }}</span>
                  <!-- <div v-show="item.show" style="float:right" >
                     <div class="menu-item" @click="addTreeNode(item)">
                      <a-tooltip placement="bottom" >
                        <template slot="title">
                          <span v-if="item.nodeType==='1'">新增课程</span>
                          <span v-else-if="item.nodeType==='2'">新增课时</span>
                          <span v-else-if="item.nodeType==='3'">习题任务设置</span>
                        </template>
                        <a-icon v-if="item.nodeType!='3'" type="plus-circle-o" />
                        <a-icon v-if="item.nodeType==='3'" type="setting" />
                      </a-tooltip>
                    </div>
                    <div v-if="item.nodeType!='1'" class="menu-item" @click="editTreeNode(item)">
                      <a-tooltip placement="bottom" title="修改">
                        <a-icon type="edit" />
                      </a-tooltip>
                    </div>
                    <div  v-if="item.nodeType!='1'" class="menu-item" @click="deleteTreeNode(item)" >
                      <a-tooltip placement="bottom" title="删除">
                        <a-icon type="minus-circle-o" />
                      </a-tooltip>
                    </div>
                </div> -->
              <!-- </div> -->
            </template>
        </a-tree>

        </a-card>
        </vue-scroll>
      </a-col>
      <a-col :span="18">
        <div v-if="node.nodeType==='1'||!node.nodeType" style="background-color:#FFF;padding-top:10px;padding:10px 10px">
          <a-form class="clearfix">
            <a-col :span="5">
              <a-form-item
                label="课程名称"
                :labelCol="{span: 8}"
                :wrapperCol="{span: 14, offset: 1}"
              >
                <a-input placeholder="请输入" v-model="queryParams.name"/>
              </a-form-item>
            </a-col>
            <a-col :span="5">
              <a-form-item
                label="是否公开"
                :labelCol="{span: 8}"
                :wrapperCol="{span: 14, offset: 1}"
              >
                <a-select v-model="queryParams.isPublic" placeholder="请选择">
                  <a-select-option value=" ">全部</a-select-option>
                  <a-select-option value="1">公开课程</a-select-option>
                  <a-select-option value="0">内部课程</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="4">
              <a-space class="btnBox">
                <a-button type="primary" @click="doQuery()">查询</a-button>
                <a-button @click="doReset()">重置</a-button>
              </a-space>
            </a-col>
          </a-form>
          <standard-table
            :columns="courseColumns"
            :dataSource="dataSource"
            :selectedRows.sync="selectedRows"
            :pagination="pagination"
            :loading="tableLoading"
            rowKey="id"
            @clear="onClear"
            @change="onPageChange"
            :bordered="true"
            :btnArr="tableBtnArr"
          >
             <div slot="courseName" slot-scope="{text, record}">
               <span style="color:#4A86E8;cursor:pointer" @click="viewCourseHour(record)">{{text}}</span>
             </div>
             <div slot="action" slot-scope="{text, record}">
              <a-icon style="color:#FC5531" type="unordered-list" @click="viewCourseHour(record)" title="查看课时"></a-icon>
              <a-divider type="vertical" />
              <a-icon style="color:blue" type="edit" @click="editCourse(record)" title="编辑课程"></a-icon>
              <a-divider type="vertical" />
              <a-icon style="color:red" type="delete" @click="doDeleteCourse(`${record.id}`)" title="删除课程"></a-icon>
            </div>
          </standard-table>

        </div>
        <div v-if="node.nodeType==='2'||(!editor&&node.nodeType==='3')" style="background-color:#FFF;padding-top:10px;padding:10px 10px">
          <div :class="advanced ? 'search' : null">
            <a-form layout="horizontal">
              <div :class="advanced ? null: 'fold'">
                <a-row >
                  <a-col :md="12" :sm="24" >
                    <a-form-item
                      label="课时名称"
                      :labelCol="{span: 5}"
                      :wrapperCol="{span: 18, offset: 1}"
                    >
                      <a-input placeholder="请输入" v-model="queryParams.name"/>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24" >
                    <a-button style="margin-left:10px;margin-top:3px" type="primary" @click="doQuery()">查询</a-button>
                    <a-button style="margin-left: 8px" @click="doReset()">重置</a-button>
                  </a-col>
                </a-row>
              </div>
              <!-- <span style="float: right; margin-top: 3px;margin-right: 10px;">
                <a-button type="primary" @click="doQuery()">查询</a-button>
                <a-button style="margin-left: 8px" @click="doReset()">重置</a-button>
              </span> -->
              <span style="float: right; margin-top: 3px;margin-right: 10px;">
                <a-button type="primary" @click="addCourseHour()">新增课时</a-button>
              </span>
            </a-form>
          </div>
          <!-- <div style="margin-bottom:10px"><a-button type="primary" @click="addCourseHour()">新增课时</a-button></div> -->
          <!-- <standard-table
            :columns="courHourcolumns"
            :dataSource="dataSource"
            :selectedRows.sync="selectedRows"
            :pagination="pagination"
            :loading="tableLoading"
            rowKey="id"
            @clear="onClear"
            @change="onPageChange"
          >
            <div slot="courseHourName" slot-scope="{text, record}">
               <span style="color:#4A86E8;cursor:pointer" @click="setCourseHourTask(record)">{{text.name}}</span>
             </div>
            <div slot="action" slot-scope="{text, record}">
              <a-icon style="color:#FC5531" type="setting" @click="setCourseHourTask(record)" title="习题任务设置"></a-icon>
              <a-divider type="vertical" />
              <a-icon style="color:blue" type="edit" @click="editCourseHour(record)" title="编辑课时"></a-icon>
              <a-divider type="vertical" />
              <a-icon style="color:red" type="delete" @click="doDeleteCourseHour(`${record.id}`)" title="删除课时"></a-icon>
            </div>
          </standard-table> -->
          <a-table
            :columns="courHourcolumns"
            :dataSource="dataSource"
            :pagination="pagination"
            :loading="tableLoading"
            rowKey="id"
            @clear="onClear"
            @change="onPageChange"
            :customRow="customRow"
            :bordered="true"
          >
            <template slot="courseHourName" slot-scope="text">
               <span style="color:#4A86E8;cursor:pointer" @click="setCourseHourTask(text)">{{text.name}}</span>
            </template>
            <template slot="action" slot-scope="text">
              <a-icon style="color:#FC5531" type="setting" @click="setCourseHourTask(text)" title="习题任务设置"></a-icon>
              <a-divider type="vertical" />
              <a-icon style="color:blue" type="edit" @click="editCourseHour(text)" title="编辑课时"></a-icon>
              <a-divider type="vertical" />
              <a-icon style="color:red" type="delete" @click="doDeleteCourseHour(`${text.id}`)" title="删除课时"></a-icon>
            </template>
          </a-table>
        </div>

        <div v-if="editor&&node.nodeType==='3'" style="background-color:#FFF;padding-top:10px;padding:10px 10px">
          <a-card title="课时考试任务">
            <a-form :form="form" layout="horizontal">
               <a-row :gutter="16">
                  <a-col :span="24">
                    <a-form-item
                      label="试卷选择"
                      :labelCol="{span: 5}"
                      :wrapperCol="{span: 14, offset: 1}"
                    >
                      <a-select
                        :allowClear="true"
                        style="width: 100%"
                        placeholder="请选择试卷"
                        v-decorator="['task.paper.paperId', { rules: [{ required: true, message: '请选择试卷' }] }]">
                        <a-select-option v-for="t in paperList" :key="t.id" :value="t.id">{{t.title}}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row :gutter="16">
                  <a-col :span="24">
                    <a-form-item
                      label="达标分数"
                      :labelCol="{span: 5}"
                      :wrapperCol="{span: 14, offset: 1}"
                    >
                      <a-input-number  v-decorator="['task.paper.paperPip', { rules: [{ required: true, message: '达标分数' }] }]" placeholder="请输入" />
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row :gutter="16">
                  <a-col :span="24">
                    <a-form-item
                      label="考试时长"
                      :labelCol="{span: 5}"
                      :wrapperCol="{span: 14, offset: 1}"
                    >
                      <a-input-number  v-decorator="['task.paper.paperTime', { rules: [{ required: true, message: '答题时长' }] }]" placeholder="请输入"/>（秒）
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row :gutter="16">
                  <a-col :span="24">
                    <a-form-item
                      label="重考次数"
                      :labelCol="{span: 5}"
                      :wrapperCol="{span: 14, offset: 1}"
                    >
                      <a-input-number v-decorator="['task.paper.paperCt']" placeholder="请输入"/>（不填则可无限重考！）
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row :gutter="16">
                  <a-col :span="24">
                    <a-form-item
                      label="是否启用："
                      :labelCol="{span: 5}"
                      :wrapperCol="{span: 14, offset: 1}"
                    >
                      <a-radio-group v-decorator="['task.paper.status']">
                        <a-radio :value="0">
                          不启用
                        </a-radio>
                        <a-radio :value="1">
                          启用
                        </a-radio>
                      </a-radio-group>
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row :gutter="16">
                  <a-col :span="24">
                    <a-form-item
                      label="考试提示语："
                      :labelCol="{span: 5}"
                      :wrapperCol="{span: 14, offset: 1}"
                    >
                      <a-input  v-decorator="['task.paper.taskTip']" placeholder="考试提示语"/>
                    </a-form-item>
                  </a-col>
                </a-row>
            </a-form>
            <div style="width:98%;text-align:center">
              <a-button type="primary" @click="taskSubmit()">
                确认保存
              </a-button>
              <a-button style="margin-left:20px"  @click="taskBack()">
                返回
              </a-button>
              <!-- <a-button type="danger" style="margin-left:10px">
                删除任务
              </a-button> -->
            </div>
          </a-card>
        </div>
      </a-col>
    </a-row>
    <modify-course :visible="course.visble"
      :course="course.course"
      :typeCourse="course.typeCourse"
      @coursePageClose="courseClose"
      @courseSubmit="courseSubmit"
    >
    </modify-course>
    <modify-classhour :visible="courseHour.visible"
      :classHour="courseHour.courseHour"
      @classHourPageClose="courseHourClose"
      @classHourSubmit="classHourSubmit"
    >
    </modify-classhour>
  </div>
</template>
<script>
import StandardTable from '@/components/table/StandardTable'
import ModifyCourse from '@/pages/onlineedu/modifycourse'
import ModifyClasshour from '@/pages/onlineedu/modifycoursehour'
import {
      ONLINE_TYPE_QUERY,
      ONLINE_QUERY_COURSE_GETALL,
      ONLINE_CLASS_HOUR_GETALL,
      ONLINE_CALSS_TASK_GETALL,
      ONLINE_COURSE_INSERT,
      ONLINE_COURSE_UPDATE,
      ONLINE_COURSE_DELETE,
      ONLINE_CLASS_HOUR_INSERT,
      ONLINE_CLASS_HOUR_UPDATE,
      ONLINE_CLASS_HOUR_DELETE,
      ONLINE_TEST_PAPER_QUERY,
      ONLINE_CLASS_HOUR_TASK_QUERY,
      ONLINE_CLASS_HOUR_TASK_ADD,
      ONLINE_CLASS_HOUR_TASK_UPDATE,
      ONLINE_COURSE_HOUR_INDEX_UPDATE
    } from '@/services/api'
import {request} from '@/utils/request'
import {mapGetters} from 'vuex'
import { useRender } from '@/hooks/useRender.js'

const courseColumns=[
  // { title: '课程编码', dataIndex: 'teamName',key:'1', align: 'center'},
  { title: '课程名称', dataIndex: 'name',scopedSlots: { customRender: 'courseName' },align: 'center' },
  { title: '课程分类', dataIndex: 'typeName', align: 'center'},
  { title: '价格', dataIndex: 'price', align: 'center',customRender: (text) => {
    if(text==0){
      return "免费";
    }else{
      return "￥"+text;
    }
    }},
  { title: '是否公开', dataIndex: 'isPublic', align: 'center',customRender: (text) => {
    if(text==1){
      return "公开";
    }else{
      return "内部课程";
    }
    }},
  { title: '创建时间', dataIndex: 'createTime' , align: 'center', customRender: (text) => useRender.renderDate(text) },
  { title: '操作', dataIndex: '',scopedSlots: { customRender: 'action' }, align: 'center' },
];
const courHourcolumns=[
  { title: '课时名称',dataIndex: '',scopedSlots: { customRender: 'courseHourName' }, align: 'center' },
  { title: '所属课程', dataIndex: 'courseName', align: 'center'},
  // { title: '课时编码', dataIndex: 'code', align: 'center'},
  { title: '创建时间', dataIndex: 'createTime' , align: 'center', customRender: (text) => useRender.renderDate(text) },
  { title: '操作', dataIndex: '',scopedSlots: { customRender: 'action' }, align: 'center' },
];

export default {
  name: 'QueryList',
  components: {StandardTable,ModifyCourse,ModifyClasshour},
  data () {
    return {
      form: this.$form.createForm(this),
      NodeTreeItem: null, // 右键菜单
      tmpStyle: '',
      dataSource:[],
      paperList:[],
      sourceObj:{},
      targetObj:{},
      advanced: false,
      courseColumns,
      courHourcolumns,
      courseName:null,
      editor:false,
      tableLoading:false,
      selectedRows: [],
       // 树形结构
      expandedKeys: [],
      treeData: [],
      allNodes:{},
      node:{},
      queryParams:{
        pageNumber:1,
      },
      course:{
        visble:false,
        course:{},
        typeCourse:[],
      },
      courseHour:{
        visible:false,
        courseHour:{},
        courseList:[]
      },
      questManage:{
        visible:false,
        course:{},
      },
      task:{
        paper:{
          id:null,
          paperId:null,
          pip:100,
          paperTime:30,
          paperCt:null,
          classesId:null,
          status:1,
        }
      },
      pagination:{
        current:1,
        pageSize:10,
        total:0,
        showLessItems:true,
        showQuickJumper:true,
        showSizeChanger:true,
        pageSizeOptions: ['10', '20', '50', '100'],
      },
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: "#4A86EB",//滚动条颜色
          opacity: 0.8,//滚动条透明度
          "overflow-x": "hidden"
        }
      },
      tableBtnArr: [
        {
          name: '新增课程',
          attrs: {
            type: 'primary'
          },
          click: this.addCourse
        }
      ]
    }
  },
  computed: {
    ...mapGetters('account', ['user']),
  },
  created() {
    this.queryTypeWork();
    this.doQueryDatas(ONLINE_QUERY_COURSE_GETALL,{isPage:"false"},2);
  },
  methods: {
    doQuery(){
      if(this.node&&this.node.nodeType){
        if(this.node.nodeType==1){
          let obj = {
            typeId:this.node.id,
            isPage:"false"
          }
          this.doQueryDatas(ONLINE_QUERY_COURSE_GETALL,obj,2);
        }
        if(this.node.nodeType==2){
          let obj = {
            courseId:this.node.id
          }
          this.doQueryDatas(ONLINE_CLASS_HOUR_GETALL,obj,3);
        }
      }else{
        this.doQueryDatas(ONLINE_QUERY_COURSE_GETALL,{isPage:"false"},2);
      }
    },
    mouseenter(data) {
      this.$set(data.dataRef, 'show', true)
      this.$set(data,'show', true)
      this.$nextTick(()=>{
        this.treeData = [...this.treeData];
      })
    },
    mouseleave(data) {
      this.$set(data.dataRef, 'show', false)
      this.$set(data,'show', false)
      this.$nextTick(()=>{
        this.treeData = [...this.treeData];
      })
    },
    // 用于点击空白处隐藏增删改菜单
   clearMenu () {
      this.NodeTreeItem = null;
    },
    addCourse(){
      if(this.node&&this.node.id){
        this.course.course = {};
        this.$set(this.course.course,"typeId",this.node.id)
        // console.log("123213",this.course.course);
        // this.course.course.typeId = this.node.id;
      }else{
        // console.log("123213",this.course.course);
        this.course.course = {};
      }
      this.course.visble=true;
    },
    addCourseHour(){
      console.log("新增课时",this.node.nodeType);
       if(this.node.nodeType=="2"){
        this.courseHour.courseHour={};
        this.courseHour.courseHour.courseId = this.node.id;
      }else{
        this.courseHour.courseHour={};
        this.courseHour.courseHour.courseId = this.node.courseId;
        let key = `2-${this.node.courseId}`;
        this.node = this.allNodes[key];
      }
      console.log("新增课时传递的参数对象",this.courseHour);
      this.courseHour.visible=true;
    },
    addTreeNode ($item) {
      this.editor = true;
      if($item.dataRef.nodeType==1){
        this.course.visble=true;
        this.course.course.typeId = $item.dataRef.id;
        this.treeNodeClick($item);
      }
      if($item.dataRef.nodeType==2){
        this.courseHour.visible=true;
        this.courseHour.courseHour.courseId = $item.dataRef.id;
        this.treeNodeClick($item);
      }
      if($item.dataRef.nodeType==3){
        this.node = $item.dataRef;
        //查询当前安全教育的试卷列表
        let taskParams = {
          classesId:$item.dataRef.id,
          status:1
        }
        this.task.paper.classesId=$item.dataRef.id;
        request(ONLINE_TEST_PAPER_QUERY,"post",taskParams).then(res => {
          if(res.data.code==0){
            this.paperList = res.data.data.list;
          }
          //获取任务信息
          this.queryClassTask(taskParams);
        })
      }
    },
    editTreeNode ($item,e) {
      if($item.dataRef.nodeType==2){
        this.course.course = $item.dataRef;
        this.course.visble=true;
      }
      if($item.dataRef.nodeType==3){
        this.node = $item.dataRef;
        this.courseHour.visible=true;
        this.courseHour.courseHour = $item.dataRef;
        this.editor= false;
        let obj = {
          courseId:$item.dataRef.courseId,
        }
        this.doQueryDatas(ONLINE_CLASS_HOUR_GETALL,obj,2);
      }
    },
    deleteTreeNode ($item) {
      if($item.dataRef.nodeType==2){
        this.doDeleteCourse($item.dataRef.id);
      }
      if($item.dataRef.nodeType==3){
        this.doDeleteCourseHour($item.dataRef.id);
      }
    },
    queryClassTask(obj){
      request(ONLINE_CLASS_HOUR_TASK_QUERY,"post",obj).then(res => {
        if(res.data.code==0&&res.data.data.length>0){
          let taskPaper = res.data.data[0];
          setTimeout(()=>{
            this.form.setFieldsValue({
              'task.paper.paperId': taskPaper.paperId+"",
              'task.paper.status': taskPaper.status,
              'task.paper.taskTip': taskPaper.taskTip,
              'task.paper.paperPip': taskPaper.paperPip,
              'task.paper.paperCt': taskPaper.paperCt,
              'task.paper.paperTime': taskPaper.paperTime,
              'task.paper.intervalTime': taskPaper.intervalTime,
            })
          })
          this.task.paper.id = taskPaper.id;
        }else{
          this.task.paper.id=null;
          this.form.resetFields();
          this.form.setFieldsValue({
            'task.paper.status': 1,
          })
        }
      })
    },
    queryTypeWork(){
      request(ONLINE_TYPE_QUERY,"post",{}).then(res => {
        if(res.data.code==0){
          this.course.typeCourse = res.data.data.list;
          res.data.data.list.forEach(obj=>{
            obj.title = obj.name;
            obj.key = obj.id;
            obj.isLeaf = false;
            obj.nodeType = "1"
            obj.slots={icon: 'typeIcon'}
            obj.show=false;
            this.allNodes[obj.key] = obj;
          })
          this.treeData = res.data.data.list;
        }
      })
    },
    onLoadData(treeNode){
      return new Promise((resolve, reject) => {
        if(treeNode.dataRef.isLeaf){
          return reject(false);
        }
        setTimeout(() => {
          let path = ONLINE_QUERY_COURSE_GETALL;
          let params = {isPage:"false"};
          let isLeaf = false;
          let nodeType = '2';
          let icon = "courseIcon"
          if(treeNode.dataRef.nodeType=="1"){
            path = ONLINE_QUERY_COURSE_GETALL;
            params['typeId'] = treeNode.dataRef.id;
            isLeaf = false;
            nodeType = '2';
            icon = "courseIcon";
          }else if(treeNode.dataRef.nodeType=="2"){
            path = ONLINE_CLASS_HOUR_GETALL;
            params['courseId'] = treeNode.dataRef.id;
            isLeaf = true;
            nodeType = '3';
            icon = "classIcon";
          }
          // else if(treeNode.dataRef.nodeType=="3"){
          //   path = ONLINE_CALSS_TASK_GETALL;
          //   params['classesId'] = treeNode.dataRef.id;
          //   isLeaf = true;
          // }
          request(path,"post",params).then(res => {
            if(res.data.code==0){
              treeNode.dataRef.children = [];
              res.data.data.list.forEach(obj=>{
                obj.isLeaf = isLeaf;
                obj.title = obj.name;
                obj.key = `${nodeType}-${obj.id}`
                obj.nodeType = nodeType;
                obj.slots={icon: icon}
                obj.show=false;
                this.allNodes[obj.key] = obj;
                treeNode.dataRef.children.push(obj);
              })
              this.treeData = [...this.treeData];
            }

            resolve();
          })
        }, 500);
      });
    },
    doQueryDatas(path,obj,type){
      request(path,"post",Object.assign(obj, this.queryParams)).then(res => {
          if(res.data.code==0){
            // if(type==1){
            //   res.data.data.forEach(o=>{
            //     var arr = this.course.typeCourse.filter(item=>item.id==o.typeId);
            //     if(arr&&arr.length>0){
            //       o.typeName = arr[0].name
            //     }
            //   })
            // }
            this.dataSource = res.data.data.list;
            this.dataSource.forEach(obj=>{
              obj.title = obj.name;
              obj.key = `${type}-${obj.id}`;
              obj.isLeaf = false;
              obj.nodeType = type+""
              obj.slots={icon: 'courseIcon'}
              obj.show=false;
              this.allNodes[obj.key] =  obj;
              console.log(this.allNodes)
            })
            this.pagination.total = res.data.data.total;
          }
       })
      this.tableLoading = false;
    },
    remove () {
      this.dataSource = this.dataSource.filter(item => this.selectedRows.findIndex(row => row.key === item.key) === -1)
      this.selectedRows = []
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },
    doReset(){
      this.queryParams={};
      this.doQuery();
    },
    //分页查询
    onPageChange(e){
      this.queryParams.pageNumber = e.current;
      this.pagination.pageSize = e.pageSize;
      this.queryParams.pageSize = e.pageSize;
      this.pagination.current=e.current;
      this.doQuery();
    },
    courseSubmit($event){
      console.log("课程ID是：",$event.course.id)
      let methd = $event.course.id?ONLINE_COURSE_UPDATE:ONLINE_COURSE_INSERT;
      request(methd,"post",$event.course).then(res => {
        if(res.data.code==0){
          res.data.data.title = res.data.data.name;
          res.data.data.key = `2-${res.data.data.id}`;
          res.data.data.isLeaf = false;
          res.data.data.nodeType = "2"
          res.data.data.slots={icon: 'courseIcon'}
          res.data.data.show=false;
          if(this.node.children){
            this.node.children.push(res.data.data);
          }else{
            let children = [];
            children.push(res.data.data);
            this.$set(this.node,"children",children)
          }
          this.allNodes[res.data.data.key] =  res.data.data;
          this.treeData = [...this.treeData];
          this.course.visble=false;
          this.course.course={};
          this.doQuery();
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    classHourSubmit($event){
      let methd = $event.classHour.id?ONLINE_CLASS_HOUR_UPDATE:ONLINE_CLASS_HOUR_INSERT;
      request(methd,"post",$event.classHour).then(res => {
        if(res.data.code==0){
          res.data.data.title = res.data.data.name;
          res.data.data.key = `3-${res.data.data.id}`;
          res.data.data.isLeaf = true;
          res.data.data.nodeType = "3"
          res.data.data.slots={title: 'classIcon'}
          res.data.data.show=false;
          if(this.node.children){
            // let index = this.node.children.findIndex(row => row.key === res.data.data.key);
            // console.log(index);
            this.node.children.splice(this.node.children.findIndex(item=>item.key===res.data.data.key), 1);
            this.node.children.push(res.data.data);
          }else{
            let children = [];
            children.push(res.data.data);
            this.$set(this.node,"children",children)
          }
          this.allNodes[res.data.data.key] =  res.data.data;
          this.treeData = [...this.treeData];
          this.courseHour.visible=false;
          this.courseHour.courseHour={};
          this.doQuery();
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    courseClose(){
      this.course.visble=false;
      this.course.course={};
    },
    courseHourClose(){
      this.courseHour.courseHour={};
      this.courseHour.visible=false;
    },
    doDeleteCourse($id){
      var _that = this
      this.$confirm({
          title: '确认删除课程',
          content: '您好，删除课程会删除该课程下面的课时任务，请确定该课程下的所有课时任务都清除！',
          okText: '仍旧删除',
          cancelText: '再想想',
          onOk() {
            request(ONLINE_COURSE_DELETE+$id, "delete", {}).then((res) => {
              if (res.data.code === 0) {
                // console.log(_that.node);
                if(_that.node&&_that.node.children){
                  var index = _that.node.children.findIndex(item =>{
                    if(item.key==('2-'+$id)){
                      return true
                    }
                  })
                  _that.node.children.splice(index,1)
                  _that.treeData = [..._that.treeData];
                }
                _that.doQuery();
                _that.$message.success("删除成功！");
              }else{
                _that.$message.error("删除异常！");
              }
            });
          },
          onCancel() {
              console.log('Cancel');
          },
      });
    },
    doDeleteCourseHour($id){
      var _that = this
      this.$confirm({
          title: '确认删除课时',
          content: '您好，删除课时会删除该课时下面的任务，请您谨慎操作！',
          okText: '仍旧删除',
          cancelText: '再想想',
          onOk() {
              request(ONLINE_CLASS_HOUR_DELETE+$id, "delete", {}).then((res) => {
              if (res.data.code === 0) {
                // console.log(_that.node.children);
                // console.log('3-'+$id)
                if(_that.node&&_that.node.children){
                  var index = _that.node.children.findIndex(item =>{
                    if(item.key==('3-'+$id)){
                      return true
                    }
                  })
                  _that.node.children.splice(index,1)
                  _that.treeData = [..._that.treeData];
                }
                _that.doQuery();
                _that.$message.success("删除成功！");
              }else{
                _that.$message.error("删除异常！");
              }
            });
          },
          onCancel() {
              console.log('Cancel');
          },
      });
    },
    editCourse(recode){
      this.course.visble=true;
      this.course.course = recode;
    },
    editCourseHour(recode){
      this.courseHour.visible=true;
      this.courseHour.courseHour = recode;
    },
    viewCourseHour(recode){
      this.node = recode;
      this.node.nodeType='2'
      this.node.key='2-'+recode.id;
      this.queryParams.pageNumber = 1;
      this.pagination.pageSize = 10;
      this.pagination.current = 1
      this.doQuery();
    },
    setCourseHourTask(recode){
      this.editor = true;
      //查询当前安全教育的试卷列表
      let taskParams = {
        classesId:recode.id,
        status:1
      }
      recode.nodeType = '3';
      this.node = recode;
      this.task.paper.classesId=recode.id;
      request(ONLINE_TEST_PAPER_QUERY,"post",taskParams).then(res => {
        if(res.data.code==0){
          this.paperList = res.data.data.list;
        }
        //获取任务信息
        this.queryClassTask(taskParams);
      })
    },
    //课程题库
    doQuestList(recode){
      this.questManage.visible = true;
      this.questManage.course = recode;
    },
    questClose(){
      this.questManage.visible = false;
      this.questManage.course = {};
    },
    taskSubmit(){
      this.form.validateFields((err, values) => {
        if (!err) {
          let task = values.task.paper;

          if(!this.task.paper.classesId){
            this.$message.error("请未选中课时设置任务");
            return false;
          }else{
            this.$set(task,'classesId',this.task.paper.classesId);
            this.$set(task,'name','课时习题任务');
            this.$set(task,'type',3);
          }
          if(this.task.paper.id){
            this.$set(task,'id',this.task.paper.id);
          }
          let path = this.task.paper.id?ONLINE_CLASS_HOUR_TASK_UPDATE:ONLINE_CLASS_HOUR_TASK_ADD;
          request(path,"post",task).then(res => {
            if(res.data.code==0){
              this.task.paper.id = res.data.data.id;
              this.$message.success("系统任务设置成功！");
            }else{
              this.$message.error(res.data.msg);
            }
          })
        }else{
          return false;
        }
      })
    },
    treeNodeClick(item){
      this.tableLoading = true;
      if(item.dataRef){
        this.node = item.dataRef
      }
      let nodeType = this.node.nodeType;
      let typeId = null;
      let path = ONLINE_QUERY_COURSE_GETALL;
      if(nodeType==1){
        typeId = this.node.id
        path = ONLINE_QUERY_COURSE_GETALL;
      }
      let courseId = null;
      if(nodeType==2){
        courseId = this.node.id
        this.courseName = this.node.name;
        path = ONLINE_CLASS_HOUR_GETALL;
      }
      if(nodeType==3){
        this.editor = true;
        let taskParams = {
          classesId:this.node.id,
          status:1
        }
        this.task.paper.classesId=this.node.id;
        request(ONLINE_TEST_PAPER_QUERY,"post",taskParams).then(res => {
          if(res.data.code==0){
            this.paperList = res.data.data.list;
          }
          //获取任务信息
          this.queryClassTask(taskParams);
        })
      }else{
        let obj = {
          typeId:typeId,
          courseId:courseId,
          isGetAll:"1",
        }
        this.doQueryDatas(path,obj,nodeType+1);
      }
    },
    taskBack(){
      this.editor = false;
      let obj = {
        courseId:this.node.courseId
      }
      this.doQueryDatas(ONLINE_CLASS_HOUR_GETALL,obj,3);
    },
    customRow(record, index) {
      return {
        style: {
          cursor: "move",
        },
        on:{
          // 鼠标移入
          mouseenter: (event) => {
            // 兼容IE
            var ev = event || window.event;
            ev.target.draggable = true;
          },
          // click:event=>{
          //   console.log(event)
          // },
          // 开始拖拽
          dragstart: (event) => {
            // 兼容IE
            var ev = event || window.event;
            // 阻止冒泡
            ev.stopPropagation();
            // 得到源目标数据
            this.sourceObj = record;
            // console.log("选中的序号",this.sourceObj.indexNo);
            // console.log(this.sourceObj);
          },
          // 拖动元素经过的元素
          dragover: (event) => {
            // 兼容 IE
            var ev = event || window.event;
            // 阻止默认行为
            ev.preventDefault();
          },
          // 鼠标松开
          drop: (event) => {
            // 兼容IE
            var ev = event || window.event;
            // 阻止冒泡
            ev.stopPropagation();
            // 得到目标数据
            this.targetObj = record;
            const tempDta = this.dataSource;
            let min = this.sourceObj.indexNo;
            let max = this.targetObj.indexNo;
            // console.log(this.sourceObj.name,this.targetObj.name);

            if(min<max){  //往后移
              //将所有比目标小且比选中的大并且包含目标对象都减去1，并且把当前选中的对象改成目标的indexNo
              tempDta.forEach(obj=>{
                if(obj.indexNo==min){
                  obj.indexNo = max;
                }else{
                  if(obj.indexNo>min&&obj.indexNo<=max){
                    obj.indexNo = obj.indexNo - 1;
                  }
                }
              })
            }
            else{
              tempDta.forEach(obj=>{
                if(obj.indexNo==min){
                  obj.indexNo = max;
                }else{
                  if(obj.indexNo>=max&&obj.indexNo<=min){
                    obj.indexNo = obj.indexNo + 1;
                  }
                }

              })
            }
            //将选中的对象更新为目标对象
            let vehicleChangeData = JSON.parse(JSON.stringify(tempDta));
            const data = this.sortByKey(vehicleChangeData,"indexNo");
            let newData = [...data];
            this.dataSource = newData;
            let weightList = [];
            newData.forEach((item,index) => {
              weightList.push({
                id: item.id,
                indexNo: item.indexNo, //item.indexNo
              });
            });
            console.log(weightList)
            this.handleWeightModify(weightList);// 更改顺序接口
          },
        }
      };
    },
    handleWeightModify(weightList){
      request(ONLINE_COURSE_HOUR_INDEX_UPDATE,"post",weightList).then(res => {
        if(res.data.code==0){
          console.log(111)
        }
      })
    },
     // 数组对象方法排序：
    sortByKey(array,key){
      return array.sort(function(a,b){
          var x=a[key];
          var y=b[key];
          return ((x<y)?-1:((x<y)?1:0));
      });
    },
    doNodeClick(keys,e){
      let nodeDate = e.node.dataRef;
      this.node = nodeDate;
      const type = nodeDate.nodeType;
      if(type==1){  //点击的是类型
        let obj = {
            typeId:nodeDate.id
          }
        this.doQueryDatas(ONLINE_QUERY_COURSE_GETALL,obj,2);
      }else if(type==2){ //点击的是课程
        let obj = {
            courseId:nodeDate.id
          }
        this.doQueryDatas(ONLINE_CLASS_HOUR_GETALL,obj,3);
      }
    }
  }
}
</script>

<style scoped>
.menu-item{
    margin-left: 10px;
    float: left;
}
.iconplus{
  float: right;
}
.search{
    margin-top: 10px;
    margin-bottom: 0px;
  }
  .fold{
    width: calc(100% - 216px);
    display: inline-block
  }
  .operator{
    margin-bottom: 0px;
  }
  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
</style>
