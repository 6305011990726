var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-layout",
    {
      attrs: {
        title: "单号：234231029431",
        logo: "https://gw.alipayobjects.com/zos/rmsportal/nxkuOJlFJuAUhzlMTCEe.png",
      },
    },
    [
      _c(
        "detail-list",
        {
          attrs: { slot: "headerContent", size: "small", col: 2 },
          slot: "headerContent",
        },
        [
          _c("detail-list-item", { attrs: { term: "创建人" } }, [
            _vm._v("曲丽丽"),
          ]),
          _c("detail-list-item", { attrs: { term: "订购产品" } }, [
            _vm._v("XX服务"),
          ]),
          _c("detail-list-item", { attrs: { term: "创建时间" } }, [
            _vm._v("2018-08-07"),
          ]),
          _c("detail-list-item", { attrs: { term: "关联单据" } }, [
            _c("a", [_vm._v("12421")]),
          ]),
          _c("detail-list-item", { attrs: { term: "生效日期" } }, [
            _vm._v("2018-08-07 ~ 2018-12-11"),
          ]),
          _c("detail-list-item", { attrs: { term: "备注" } }, [
            _vm._v("请于两个工作日内确认"),
          ]),
        ],
        1
      ),
      _c(
        "template",
        { slot: "extra" },
        [
          _c("head-info", { attrs: { title: "状态", content: "待审批" } }),
          _c("head-info", {
            attrs: { title: "订单金额", content: "¥ 568.08" },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "action" },
        [
          _c(
            "a-button-group",
            { staticStyle: { "margin-right": "8px" } },
            [
              _c("a-button", [_vm._v("操作")]),
              _c("a-button", [_vm._v("操作")]),
              _c(
                "a-button",
                [_c("a-icon", { attrs: { type: "ellipsis" } })],
                1
              ),
            ],
            1
          ),
          _c("a-button", { attrs: { type: "primary" } }, [_vm._v("主操作")]),
        ],
        1
      ),
      _c(
        "a-card",
        { attrs: { bordered: false, title: "流程进度" } },
        [
          _c(
            "a-steps",
            {
              attrs: {
                current: 1,
                "progress-dot": "",
                direction: _vm.isMobile ? "vertical" : "horizontal",
              },
            },
            [
              _c(
                "a-step",
                { attrs: { title: "创建项目" } },
                [
                  _c(
                    "a-step-item-group",
                    {
                      attrs: {
                        slot: "description",
                        align: _vm.isMobile ? "left" : "center",
                      },
                      slot: "description",
                    },
                    [
                      _c("a-step-item", {
                        attrs: {
                          link: "/staff_lib_issues/staff_lib_issues_my",
                          title: "曲丽丽",
                          icon: "dingding-o",
                        },
                      }),
                      _c("a-step-item", {
                        attrs: { title: "2016-12-12 12:32" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-step",
                { attrs: { title: "部门初审" } },
                [
                  _c(
                    "a-step-item-group",
                    {
                      attrs: {
                        slot: "description",
                        align: _vm.isMobile ? "left" : "center",
                      },
                      slot: "description",
                    },
                    [
                      _c("a-step-item", {
                        attrs: {
                          link: "/form/step",
                          title: "周毛毛",
                          icon: "dingding-o",
                        },
                      }),
                      _c("a-step-item", {
                        attrs: {
                          link: "/result/success",
                          title: "催一下",
                          icon: "bell",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("a-step", { attrs: { title: "财务复核" } }),
              _c("a-step", { attrs: { title: "完成" } }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-card",
        {
          staticStyle: { "margin-top": "24px" },
          attrs: { bordered: false, title: "用户信息" },
        },
        [
          _c(
            "detail-list",
            [
              _c("detail-list-item", { attrs: { term: "用户姓名" } }, [
                _vm._v("付晓晓"),
              ]),
              _c("detail-list-item", { attrs: { term: "会员卡号" } }, [
                _vm._v("32943898021309809423"),
              ]),
              _c("detail-list-item", { attrs: { term: "身份证" } }, [
                _vm._v("3321944288191034921"),
              ]),
              _c("detail-list-item", { attrs: { term: "联系方式" } }, [
                _vm._v("18112345678"),
              ]),
              _c("detail-list-item", { attrs: { term: "联系地址" } }, [
                _vm._v("浙江省杭州市西湖区黄姑山路工专路交叉路口"),
              ]),
            ],
            1
          ),
          _c(
            "detail-list",
            { attrs: { title: "信息组" } },
            [
              _c("detail-list-item", { attrs: { term: "某某数据" } }, [
                _vm._v("725"),
              ]),
              _c("detail-list-item", { attrs: { term: "该数据更新时间" } }, [
                _vm._v("2018-08-08"),
              ]),
              _c("detail-list-item"),
              _c("detail-list-item", { attrs: { term: "某某数据" } }, [
                _vm._v("725"),
              ]),
              _c("detail-list-item", { attrs: { term: "该数据更新时间" } }, [
                _vm._v("2018-08-08"),
              ]),
              _c("detail-list-item"),
            ],
            1
          ),
          _c(
            "a-card",
            { attrs: { type: "inner", title: "多层信息组" } },
            [
              _c(
                "detail-list",
                { attrs: { title: "组名称", size: "small" } },
                [
                  _c("detail-list-item", { attrs: { term: "负责人" } }, [
                    _vm._v("林东东"),
                  ]),
                  _c("detail-list-item", { attrs: { term: "角色码" } }, [
                    _vm._v("1234567"),
                  ]),
                  _c("detail-list-item", { attrs: { term: "所属部门" } }, [
                    _vm._v("XX公司-YY部"),
                  ]),
                  _c("detail-list-item", { attrs: { term: "过期时间" } }, [
                    _vm._v("2018-08-08"),
                  ]),
                  _c("detail-list-item", { attrs: { term: "描述" } }, [
                    _vm._v(
                      "这段描述很长很长很长很长很长很长很长很长很长很长很长很长很长很长..."
                    ),
                  ]),
                ],
                1
              ),
              _c("a-divider", { staticStyle: { margin: "16px 0" } }),
              _c(
                "detail-list",
                { attrs: { title: "组名称", size: "small", col: 1 } },
                [
                  _c("detail-list-item", { attrs: { term: "学名" } }, [
                    _vm._v("林东东"),
                  ]),
                  _c("detail-list-item", { attrs: { term: "角色码" } }, [
                    _vm._v("1234567"),
                  ]),
                  _c("detail-list-item", { attrs: { term: "所属部门" } }, [
                    _vm._v("XX公司-YY部"),
                  ]),
                  _c("detail-list-item", { attrs: { term: "过期时间" } }, [
                    _vm._v("2018-08-08"),
                  ]),
                  _c("detail-list-item", { attrs: { term: "描述" } }, [
                    _vm._v(
                      "这段描述很长很长很长很长很长很长很长很长很长很长很长很长很长很长..."
                    ),
                  ]),
                ],
                1
              ),
              _c("a-divider", { staticStyle: { margin: "16px 0" } }),
              _c(
                "detail-list",
                { attrs: { title: "组名称", size: "small", col: 2 } },
                [
                  _c("detail-list-item", { attrs: { term: "学名" } }, [
                    _vm._v("林东东"),
                  ]),
                  _c("detail-list-item", { attrs: { term: "角色码" } }, [
                    _vm._v("1234567"),
                  ]),
                  _c("detail-list-item", { attrs: { term: "所属部门" } }, [
                    _vm._v("XX公司-YY部"),
                  ]),
                  _c("detail-list-item", { attrs: { term: "过期时间" } }, [
                    _vm._v("2018-08-08"),
                  ]),
                  _c("detail-list-item", { attrs: { term: "描述" } }, [
                    _vm._v(
                      "这段描述很长很长很长很长很长很长很长很长很长很长很长很长很长很长..."
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-card",
        {
          staticStyle: { "margin-top": "24px" },
          attrs: { bordered: false, title: "用户近半年来电记录" },
        },
        [_c("a-list")],
        1
      ),
      _c(
        "a-card",
        {
          staticStyle: { "margin-top": "24px" },
          attrs: {
            bordered: false,
            tabList: _vm.tabList,
            activeTabKey: _vm.activeTabKey,
          },
          on: {
            tabChange: function (key) {
              this$1.activeTabKey = key
            },
          },
        },
        [
          _vm.activeTabKey === "1"
            ? _c("a-table", {
                attrs: {
                  columns: _vm.operationColumns,
                  dataSource: _vm.operation1,
                  pagination: false,
                },
              })
            : _vm._e(),
          _vm.activeTabKey === "2"
            ? _c("a-table", {
                attrs: {
                  columns: _vm.operationColumns,
                  dataSource: _vm.operation2,
                  pagination: false,
                },
              })
            : _vm._e(),
          _vm.activeTabKey === "3"
            ? _c("a-table", {
                attrs: {
                  columns: _vm.operationColumns,
                  dataSource: _vm.operation3,
                  pagination: false,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }