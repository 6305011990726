var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "constructiontTable_index" },
      [
        _c("form-index", {
          ref: "formindex",
          attrs: {
            projectList: _vm.projectList,
            formData: _vm.formData,
            flexAction: true,
            baiduMapBoolen: false,
          },
          on: {
            handleSubmit: _vm.handleSubmit,
            handleChange: _vm.handleChange,
            handlSelect: _vm.handlSelect,
          },
          scopedSlots: _vm._u([
            {
              key: "flexAction",
              fn: function () {
                return [
                  _c(
                    "a-space",
                    { style: { marginTop: "4px" } },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            icon: "search",
                            type: "primary",
                            "html-type": "submit",
                          },
                        },
                        [_vm._v(" 查询 ")]
                      ),
                      _c("a-button", { on: { click: _vm.close } }, [
                        _vm._v(" 重置 "),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        }),
        _c(
          "a-space",
          { staticClass: "operator" },
          [
            _c(
              "a-button",
              {
                attrs: { icon: "plus", type: "primary" },
                on: { click: _vm.createItem },
              },
              [_vm._v(" 新建项目 ")]
            ),
          ],
          1
        ),
        _c("standard-table", {
          staticClass: "mt-1",
          attrs: {
            columns: _vm.columns,
            dataSource: _vm.dataSource,
            rowKey: "id",
            pagination: _vm.pagination,
            loading: _vm.tableLoading,
            isAlert: false,
            bordered: true,
          },
          on: { change: _vm.onPageChange },
          scopedSlots: _vm._u([
            {
              key: "index",
              fn: function (ref) {
                var index = ref.index
                return [
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          (_vm.pagination.current - 1) *
                            _vm.pagination.pageSize +
                            parseInt(index) +
                            1
                        ) +
                        " "
                    ),
                  ]),
                ]
              },
            },
            {
              key: "projectName",
              fn: function (ref) {
                var text = ref.text
                var record = ref.record
                return [
                  _c(
                    "a-tooltip",
                    { attrs: { placement: "topLeft" } },
                    [
                      _c("template", { slot: "title" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              record.projectMsg.fullName
                                ? record.projectMsg.fullName
                                : text
                            )
                          ),
                        ]),
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "ant-btn-text ellipsis-2",
                          on: {
                            click: function ($event) {
                              return _vm.toUnits(record)
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              record.projectMsg.fullName
                                ? record.projectMsg.fullName
                                : text
                            )
                          ),
                        ]
                      ),
                    ],
                    2
                  ),
                ]
              },
            },
            {
              key: "projectNum",
              fn: function (ref) {
                var record = ref.record
                return [
                  _c(
                    "a-tooltip",
                    { attrs: { placement: "topLeft" } },
                    [
                      _c("template", { slot: "title" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              record.projectMsg.projectNum
                                ? record.projectMsg.projectNum
                                : record.projectNum
                            )
                          ),
                        ]),
                      ]),
                      _c("span", { staticClass: "ellipsis-2" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              record.projectMsg.projectNum
                                ? record.projectMsg.projectNum
                                : record.projectNum
                            )
                          ),
                        ]),
                      ]),
                    ],
                    2
                  ),
                ]
              },
            },
            {
              key: "ellipsis",
              fn: function (ref) {
                var text = ref.text
                return [
                  _c(
                    "a-tooltip",
                    { attrs: { placement: "topLeft" } },
                    [
                      _c("template", { slot: "title" }, [
                        _c("span", [_vm._v(_vm._s(text))]),
                      ]),
                      _c("span", { staticClass: "ellipsis-2" }, [
                        _vm._v(_vm._s(text)),
                      ]),
                    ],
                    2
                  ),
                ]
              },
            },
            {
              key: "userName",
              fn: function (ref) {
                var record = ref.record
                return _c("div", {}, [
                  record.userName
                    ? _c("div", [
                        _c("div", [_vm._v(_vm._s(record.userName))]),
                        record.userPhone
                          ? _c("span", [
                              _vm._v("(" + _vm._s(record.userPhone) + ")"),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ])
              },
            },
            {
              key: "action",
              fn: function (ref) {
                var record = ref.record
                return _c("div", {}, [
                  _c(
                    "div",
                    [
                      _c(
                        "a",
                        {
                          staticStyle: { color: "#4678d6" },
                          on: {
                            click: function ($event) {
                              return _vm.toUnits(record)
                            },
                          },
                        },
                        [_vm._v(" 修改 ")]
                      ),
                      _c("a-divider", { attrs: { type: "vertical" } }),
                      _c(
                        "a",
                        {
                          staticStyle: { color: "#ff5c77" },
                          on: {
                            click: function ($event) {
                              return _vm.recorDelete(record)
                            },
                          },
                        },
                        [_vm._v(" 删除 ")]
                      ),
                    ],
                    1
                  ),
                ])
              },
            },
          ]),
        }),
        _c("Export-loading", {
          attrs: {
            cPercent: _vm.cPercent,
            isExporting: _vm.isExporting,
            loadingText: _vm.loadingText,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }