var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { destroyOnClose: true, width: 1800, footer: null },
      on: { cancel: _vm.cancel },
      model: {
        value: _vm.myVisible,
        callback: function ($$v) {
          _vm.myVisible = $$v
        },
        expression: "myVisible",
      },
    },
    [
      _c("h3", { staticClass: "font-bold" }, [
        _vm._v(_vm._s(_vm.clickContent.bigTypeName)),
      ]),
      _c(
        "div",
        { staticClass: "flex a-center" },
        [
          _vm.recordData.status == 2
            ? _c(
                "a-button",
                {
                  attrs: { type: "primary", loading: _vm.uploading },
                  on: { click: _vm.captureScreenshot },
                },
                [_vm._v(" 截图及隐患问题 ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex mt-2 w-100" },
        [
          _c(
            "a-row",
            [
              _c("a-col", { attrs: { span: 14 } }, [
                _c(
                  "div",
                  {
                    staticClass: "flex a-center j-center",
                    staticStyle: { "min-width": "1022px" },
                  },
                  [
                    _c("video", {
                      ref: "video",
                      staticStyle: { width: "auto", height: "550px" },
                      attrs: {
                        id: "video",
                        src: _vm.clickVideo.filePath,
                        controls: "",
                        crossorigin: "anonymous",
                        autoplay: "",
                      },
                    }),
                  ]
                ),
              ]),
              _c("a-col", { attrs: { span: 10 } }, [
                _c(
                  "div",
                  { staticStyle: { "min-width": "730px" } },
                  [
                    _c("a-row", [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "overflow-y": "auto",
                            height: "550px",
                          },
                        },
                        _vm._l(_vm.yhList, function (item, index) {
                          return _c(
                            "a-col",
                            { key: index, attrs: { span: 12 } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "p-1 m-1",
                                  staticStyle: { border: "1px solid #F3F3F3" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex" },
                                    [
                                      _c("span", { staticClass: "flex-1" }, [
                                        _vm._v("隐患描述"),
                                      ]),
                                      _vm.recordData.status == 2
                                        ? _c(
                                            "a-button",
                                            {
                                              staticStyle: { color: "red" },
                                              attrs: { type: "link" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.del(item, index)
                                                },
                                              },
                                            },
                                            [_vm._v("删除")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "yh-desc-box" }, [
                                    _vm._v(_vm._s(item.yhDesc)),
                                  ]),
                                  _c("div", { staticClass: "mt-1" }, [
                                    _vm._v("隐患部位"),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "yh-position-box" },
                                    [_vm._v(_vm._s(item.yhPosition))]
                                  ),
                                  _c("div", { staticClass: "mt-1" }, [
                                    _vm._v("图片"),
                                  ]),
                                  _c("img", {
                                    staticStyle: { width: "80px" },
                                    attrs: { src: item.yhPhoto, alt: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlePreview(item.yhPhoto)
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-1 flex flex-row-reverse" },
        [
          _vm.recordData.status == 2
            ? _c(
                "a-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSave } },
                [_vm._v(" 保存 ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            centered: "",
            destroyOnClose: true,
            width: 880,
            footer: null,
            maskClosable: false,
            title: "添加隐患",
            keyboard: false,
          },
          on: { cancel: _vm.handleCancel },
          model: {
            value: _vm.showScreenshotDialog,
            callback: function ($$v) {
              _vm.showScreenshotDialog = $$v
            },
            expression: "showScreenshotDialog",
          },
        },
        [
          _c("canvas", {
            ref: "canvas",
            on: {
              mousedown: _vm.startDrawing,
              mousemove: _vm.draw,
              mouseup: _vm.stopDrawing,
            },
          }),
          _c(
            "div",
            { staticClass: "mt-1 flex flex-row-reverse" },
            [
              _c("a-icon", {
                staticClass: "mr-1 pointer",
                staticStyle: { "font-size": "25px" },
                attrs: { type: "undo" },
                on: { click: _vm.undo },
              }),
              _c("a-icon", {
                staticClass: "mr-1 pointer",
                staticStyle: { "font-size": "25px" },
                attrs: { type: "arrows-alt" },
                on: { click: _vm.enableArrow },
              }),
              _c("a-icon", {
                staticClass: "mr-1 pointer",
                staticStyle: { "font-size": "25px" },
                attrs: { type: "up-square" },
                on: { click: _vm.enableRectangle },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-2" },
            [
              _c(
                "a-form",
                {
                  attrs: {
                    form: _vm.form,
                    "label-col": { span: 4 },
                    "wrapper-col": { span: 20 },
                  },
                },
                [
                  _c(
                    "a-row",
                    { attrs: { gutter: 16 } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "隐患描述" } },
                            [
                              _c("a-textarea", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "yhDesc",
                                      {
                                        rules: [
                                          { required: false, message: "" },
                                        ],
                                      },
                                    ],
                                    expression:
                                      "['yhDesc', { rules: [{ required: false, message: '' }] }]",
                                  },
                                ],
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "隐患部位" } },
                            [
                              _c("a-textarea", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "yhPosition",
                                      {
                                        rules: [
                                          { required: false, message: "" },
                                        ],
                                      },
                                    ],
                                    expression:
                                      "['yhPosition', { rules: [{ required: false, message: '' }] }]",
                                  },
                                ],
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { staticClass: "form-buttons", attrs: { span: 24 } },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "default" },
                              on: { click: _vm.handleCancel },
                            },
                            [_vm._v("返回")]
                          ),
                          _c(
                            "a-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.confirmCapture },
                            },
                            [_vm._v("确定")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: { visible: _vm.previewVisible, width: 880, footer: null },
          on: { cancel: _vm.handleCancelPreview },
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { alt: "example", src: _vm.previewImage },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }