var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a_Approval" },
    [
      _c("div", { staticClass: "plug-Overview" }, [_vm._v("安全检查记录")]),
      _c("a-divider"),
      _c(
        "div",
        { class: _vm.advanced ? "search" : null },
        [
          _c(
            "a-form",
            { staticClass: "clearfix" },
            [
              _c(
                "a-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: "检查部门",
                        labelCol: { span: 8 },
                        wrapperCol: { span: 14, offset: 1 },
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          model: {
                            value: _vm.queryParams.isGcglb,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "isGcglb", $$v)
                            },
                            expression: "queryParams.isGcglb",
                          },
                        },
                        [
                          _c("a-select-option", { attrs: { value: 2 } }, [
                            _vm._v("公司"),
                          ]),
                          _c("a-select-option", { attrs: { value: 1 } }, [
                            _vm._v("工程管理部"),
                          ]),
                          _c("a-select-option", { attrs: { value: 3 } }, [
                            _vm._v("项目自检"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: "检查时间",
                        labelCol: { span: 8 },
                        wrapperCol: { span: 14, offset: 1 },
                      },
                    },
                    [
                      _c("a-range-picker", {
                        attrs: { format: _vm.dateFormat },
                        on: { change: _vm.changeDate },
                        model: {
                          value: _vm.queryParams.plan_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParams, "plan_date", $$v)
                          },
                          expression: "queryParams.plan_date",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "a-space",
                    { staticClass: "btnBox" },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.doQuery()
                            },
                          },
                        },
                        [_vm._v("查询")]
                      ),
                      _c(
                        "a-button",
                        {
                          staticStyle: { "margin-left": "8px" },
                          on: {
                            click: function ($event) {
                              return _vm.doReset()
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "standard-table",
        {
          attrs: {
            columns: _vm.columns,
            dataSource: _vm.dataSource,
            selectedRows: _vm.selectedRows,
            pagination: _vm.pagination,
            loading: _vm.tableLoading,
            isAlert: false,
            btnArr: _vm.tableBtnArr,
          },
          on: {
            "update:selectedRows": function ($event) {
              _vm.selectedRows = $event
            },
            "update:selected-rows": function ($event) {
              _vm.selectedRows = $event
            },
            clear: _vm.onClear,
            change: _vm.onPageChange,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "planType",
                fn: function (ref) {
                  var text = ref.text
                  return _c("div", {}, [
                    text == "4"
                      ? _c("div", [_vm._v("停工处罚")])
                      : text == "3"
                      ? _c("div", [_vm._v("带班检查")])
                      : _vm.xmzc
                      ? _c("div", [_vm._v("项目自检")])
                      : _c("div", [_vm._v("公司常规检查")]),
                  ])
                },
              },
              {
                key: "do-status",
                fn: function (ref) {
                  var record = ref.record
                  var text = ref.text
                  return !_vm.xmzc
                    ? _c("div", {}, [
                        record.planType == 4 && text == 0
                          ? _c("label", [_vm._v("待处理")])
                          : _vm._e(),
                        (record.planType == 1 || record.planType == 3) &&
                        text == 0
                          ? _c("label", [_vm._v("待检查")])
                          : _vm._e(),
                        text == 1 ? _c("label", [_vm._v("待复核")]) : _vm._e(),
                        text == 2 ? _c("label", [_vm._v("已完成")]) : _vm._e(),
                        text == 3 ? _c("label", [_vm._v("整改中")]) : _vm._e(),
                      ])
                    : _c("div", {}, [
                        text == 0 ? _c("label", [_vm._v("检查中")]) : _vm._e(),
                        text == 2
                          ? _c("label", [_vm._v("整改不合格")])
                          : _vm._e(),
                        text == 1
                          ? _c("label", [_vm._v("整改达标")])
                          : _vm._e(),
                      ])
                },
              },
              {
                key: "action",
                fn: function (ref) {
                  var record = ref.record
                  return _c("div", {}, [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.dowLoadFile(record)
                          },
                        },
                      },
                      [_vm._v("下载文件")]
                    ),
                  ])
                },
              },
            ],
            null,
            true
          ),
        },
        [
          _c(
            "template",
            { slot: "statusTitle" },
            [
              _c("a-icon", {
                attrs: { type: "info-circle" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onStatusTitleClick($event)
                  },
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _c("Export-loading", {
        attrs: {
          cPercent: _vm.cPercent,
          isExporting: _vm.isExporting,
          loadingText: _vm.loadingText,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }