var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-card",
        { attrs: { bordered: false } },
        [
          _c(
            "div",
            { attrs: { id: "components-form-demo-advanced-search" } },
            [
              _c(
                "a-form",
                { attrs: { form: _vm.searchForm } },
                [
                  _c(
                    "a-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                labelCol: { span: 5 },
                                wrapperCol: { span: 16, offset: 1 },
                                label: "姓名",
                              },
                            },
                            [
                              _c("a-input", {
                                attrs: { placeholder: "请输入姓名" },
                                model: {
                                  value: _vm.searchForm.userName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "userName", $$v)
                                  },
                                  expression: "searchForm.userName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: "生成时间",
                                labelCol: { span: 5 },
                                wrapperCol: { span: 16, offset: 1 },
                              },
                            },
                            [
                              _c("a-range-picker", {
                                attrs: {
                                  format: _vm.monthFormat,
                                  allowClear: true,
                                },
                                on: { change: _vm.onTimeChange },
                                model: {
                                  value: _vm.searchForm.enterDateStr,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.searchForm,
                                      "enterDateStr",
                                      $$v
                                    )
                                  },
                                  expression: "searchForm.enterDateStr",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                labelCol: { span: 5 },
                                wrapperCol: { span: 16, offset: 1 },
                                label: "名册编号",
                              },
                            },
                            [
                              _c("a-input", {
                                attrs: { placeholder: "名册编号" },
                                model: {
                                  value: _vm.searchForm.codes,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "codes", $$v)
                                  },
                                  expression: "searchForm.codes",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: "状态",
                                labelCol: { span: 5 },
                                wrapperCol: { span: 16, offset: 1 },
                              },
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  model: {
                                    value: _vm.searchForm.status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "status", $$v)
                                    },
                                    expression: "searchForm.status",
                                  },
                                },
                                [
                                  _c(
                                    "a-select-option",
                                    { key: "", attrs: { value: "" } },
                                    [_vm._v(" 全部 ")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { key: "0", attrs: { value: "0" } },
                                    [_vm._v(" 待工人签署 ")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { key: "1", attrs: { value: "1" } },
                                    [_vm._v(" 已签署 ")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { key: "2", attrs: { value: "2" } },
                                    [_vm._v(" 已驳回 ")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { key: "3", attrs: { value: "3" } },
                                    [_vm._v(" 待公司签署 ")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { key: "4", attrs: { value: "4" } },
                                    [_vm._v(" 已线下签署 ")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { key: "99", attrs: { value: "99" } },
                                    [_vm._v(" 已作废 ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    { attrs: { gutter: 24 } },
                    [
                      _vm.user.loginType == 1
                        ? _c(
                            "a-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    labelCol: { span: 5 },
                                    wrapperCol: { span: 16, offset: 1 },
                                    label: "项目名称",
                                  },
                                },
                                [
                                  _c(
                                    "a-auto-complete",
                                    {
                                      attrs: {
                                        "data-source": _vm.inputDataSource,
                                        placeholder: "请输入项目名称",
                                        allowClear: "",
                                      },
                                      on: {
                                        search: function ($event) {
                                          return _vm.handleInputSearch($event)
                                        },
                                        select: function ($event) {
                                          return _vm.handlInputSelect($event)
                                        },
                                      },
                                      model: {
                                        value: _vm.searchForm.projectId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "projectId",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.projectId",
                                      },
                                    },
                                    [
                                      _c(
                                        "template",
                                        { slot: "dataSource" },
                                        _vm._l(
                                          _vm.inputDataSource,
                                          function (items) {
                                            return _c(
                                              "a-select-option",
                                              { key: items.id + "" },
                                              [_vm._v(_vm._s(items.title))]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "a-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: "所属班组",
                                labelCol: { span: 5 },
                                wrapperCol: { span: 16, offset: 1 },
                              },
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  attrs: {
                                    "show-search": "",
                                    placeholder: "请输入或选择班组",
                                    "option-filter-prop": "children",
                                    "filter-option": _vm.filterOption,
                                  },
                                  on: {
                                    focus: _vm.handleFocus,
                                    blur: _vm.handleBlur,
                                    change: _vm.handleChange,
                                  },
                                  model: {
                                    value: _vm.searchForm.teamId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "teamId", $$v)
                                    },
                                    expression: "searchForm.teamId",
                                  },
                                },
                                _vm._l(_vm.teamDatas, function (item) {
                                  return _c(
                                    "a-select-option",
                                    { key: item.id, attrs: { value: item.id } },
                                    [_vm._v(" " + _vm._s(item.teamName) + " ")]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        {
                          style: { textAlign: "left", marginTop: "4px" },
                          attrs: { span: 6 },
                        },
                        [
                          _c(
                            "a-space",
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    icon: "search",
                                    loading: _vm.loading,
                                  },
                                  on: { click: _vm.handleSearch },
                                },
                                [_vm._v(" 查询 ")]
                              ),
                              _vm.signOperate
                                ? _c(
                                    "a-button",
                                    {
                                      attrs: { type: "danger" },
                                      on: { click: _vm.doSealOffice },
                                    },
                                    [_vm._v(" 公司签章 ")]
                                  )
                                : _vm._e(),
                              _c(
                                "a-space",
                                { staticClass: "operator" },
                                [
                                  _c(
                                    "a-button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.doReset()
                                        },
                                      },
                                    },
                                    [_vm._v(" 重置 ")]
                                  ),
                                  _vm.rejectOperate
                                    ? _c(
                                        "a-button",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.doRejectContract(true)
                                            },
                                          },
                                        },
                                        [_vm._v("批量驳回")]
                                      )
                                    : _vm._e(),
                                  _vm.canOperate
                                    ? _c(
                                        "a-button",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.setEssProSure(true)
                                            },
                                          },
                                        },
                                        [_vm._v("批量信息已修正")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "a-button",
                                    {
                                      attrs: { icon: "download" },
                                      on: {
                                        click: function () {
                                          return _vm.downloadBatch(1)
                                        },
                                      },
                                    },
                                    [_vm._v("批量下载合同")]
                                  ),
                                  _vm.isSclw
                                    ? _c(
                                        "a-button",
                                        {
                                          attrs: { icon: "download" },
                                          on: {
                                            click: function () {
                                              return _vm.downloadBatch(2)
                                            },
                                          },
                                        },
                                        [_vm._v("批量下载三级安全教育")]
                                      )
                                    : _vm._e(),
                                  !_vm.isSclw && !_vm.signOperate
                                    ? _c(
                                        "a-button",
                                        {
                                          attrs: { icon: "download" },
                                          on: {
                                            click: function () {
                                              return _vm.exportStaffHmc()
                                            },
                                          },
                                        },
                                        [_vm._v("导出班组花名册")]
                                      )
                                    : _vm._e(),
                                  _vm.rejectOperate || _vm.signOperate
                                    ? _c(
                                        "a-button",
                                        {
                                          attrs: { icon: "download" },
                                          on: {
                                            click: function () {
                                              return _vm.exportStaffAllHmc()
                                            },
                                          },
                                        },
                                        [_vm._v("导出人员花名册")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("standard-table", {
            attrs: {
              columns: _vm.columns,
              dataSource: _vm.data,
              selectedRows: _vm.selectedRows,
              pagination: _vm.pagination,
              loading: _vm.tableLoading,
              bordered: "",
              rowKey: "id",
            },
            on: {
              "update:selectedRows": [
                function ($event) {
                  _vm.selectedRows = $event
                },
                function ($event) {
                  return _vm.updateSelectRows($event)
                },
              ],
              "update:selected-rows": function ($event) {
                _vm.selectedRows = $event
              },
              clear: _vm.onClear,
              change: _vm.onPageChange,
            },
            scopedSlots: _vm._u([
              {
                key: "projectName",
                fn: function (ref) {
                  var text = ref.text
                  return [
                    _c(
                      "a-tooltip",
                      { attrs: { placement: "topLeft" } },
                      [
                        _c("template", { slot: "title" }, [
                          _c("span", [_vm._v(_vm._s(text))]),
                        ]),
                        _c("span", { staticClass: "ellipsis-2" }, [
                          _vm._v(_vm._s(text)),
                        ]),
                      ],
                      2
                    ),
                  ]
                },
              },
              {
                key: "paperName",
                fn: function (ref) {
                  var record = ref.record
                  return [
                    _c(
                      "a",
                      {
                        on: {
                          click: function () {
                            return _vm.viewOpenUrl(record)
                          },
                        },
                      },
                      [_vm._v(_vm._s(record.contractName))]
                    ),
                  ]
                },
              },
              {
                key: "riskNames",
                fn: function (ref) {
                  var text = ref.text
                  var record = ref.record
                  return [
                    _c("div", [_vm._v(_vm._s(text))]),
                    record.riskNames && record.riskNames.length > 0
                      ? _vm._l(record.riskNames, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticStyle: { color: "#ff5c77" } },
                            [_vm._v("(" + _vm._s(item) + ")")]
                          )
                        })
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "action",
                fn: function (ref) {
                  var record = ref.record
                  return [
                    _c(
                      "span",
                      [
                        record.officeId != "1f6bd3af5c434991a44fc50fcb424f38" ||
                        _vm.user.loginType == 1
                          ? _c(
                              "a",
                              {
                                on: {
                                  click: function () {
                                    return _vm.viewOpenUrl(record)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            )
                          : _vm._e(),
                        _vm.canOperate && record.status == 2
                          ? _c("a-divider", { attrs: { type: "vertical" } })
                          : _vm._e(),
                        _vm.canOperate &&
                        record.status == 2 &&
                        !_vm.sureId.includes(record.id)
                          ? _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.setEssProSure(false, record)
                                  },
                                },
                              },
                              [_vm._v("信息已修正")]
                            )
                          : _vm.canOperate && record.status == 2
                          ? _c(
                              "a-spin",
                              {
                                attrs: {
                                  spinning: _vm.sureId.includes(record.id),
                                },
                              },
                              [_vm._v("正在重新发起电子签")]
                            )
                          : _vm._e(),
                        record.status == 0 && _vm.offSignOperate
                          ? _c("a-divider", { attrs: { type: "vertical" } })
                          : _vm._e(),
                        record.status == 0 && _vm.offSignOperate
                          ? _c(
                              "a",
                              {
                                staticStyle: { color: "red" },
                                on: {
                                  click: function ($event) {
                                    return _vm.showEssOffSign(record)
                                  },
                                },
                              },
                              [_vm._v("线下签署")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("Export-loading", {
        attrs: {
          cPercent: _vm.cPercent,
          isExporting: _vm.isExporting,
          loadingText: _vm.loadingText,
        },
      }),
      _c(
        "a-modal",
        {
          attrs: { visible: _vm.visible, width: 1350, footer: false },
          on: { cancel: _vm.closeView },
        },
        [
          _c("template", { slot: "title" }, [
            _c("div", { staticClass: "flex j-between a-center" }, [
              _c("span", { staticStyle: { flex: "0 0 auto" } }, [
                _vm._v("合同预览"),
              ]),
              _c(
                "div",
                { staticClass: "flex-1 text-center" },
                [
                  _c(
                    "a-space",
                    [
                      _vm.signOperate && _vm.essContract.status == 3
                        ? _c(
                            "a-button",
                            {
                              attrs: { type: "danger" },
                              on: { click: _vm.doSignOffice },
                            },
                            [_vm._v(" 签章 ")]
                          )
                        : _vm._e(),
                      _vm.rejectOperate &&
                      (_vm.essContract.status == 3 ||
                        _vm.essContract.status == 0)
                        ? _c(
                            "a-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.doRejectContract(false)
                                },
                              },
                            },
                            [_vm._v(" 驳回 ")]
                          )
                        : _vm._e(),
                      _vm.rejectOperate && _vm.essContract.status == 0
                        ? _c(
                            "a-button",
                            {
                              attrs: { type: "danger" },
                              on: {
                                click: function ($event) {
                                  _vm.offValiDialog = true
                                },
                              },
                            },
                            [_vm._v(" 作废 ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "flex" }, [
            _c(
              "div",
              {
                style: {
                  width: _vm.essDoLog.length > 0 ? "75%" : "100%",
                  position: "relative",
                },
                attrs: { id: "watermarkContainer" },
              },
              [
                _c("iframe", {
                  staticClass: "fddPdf",
                  staticStyle: { width: "100%" },
                  attrs: { src: _vm.essContract.fddViewUrl },
                }),
              ]
            ),
            _vm.essDoLog.length > 0
              ? _c(
                  "div",
                  { staticStyle: { width: "25%", "margin-top": "54px" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "ml-2 pb-2 font-18 font-bold text-center",
                      },
                      [_vm._v("操作记录")]
                    ),
                    _c(
                      "a-timeline",
                      { staticClass: "ml-2" },
                      _vm._l(_vm.essDoLog, function (item, index) {
                        return _c("a-timeline-item", { key: index }, [
                          _c("div", { staticClass: "black" }, [
                            _c("span", { staticClass: "black-three" }, [
                              _vm._v("发起人: "),
                            ]),
                            _vm._v(
                              _vm._s(
                                item.loginName ? item.loginName : item.loginAcc
                              )
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "black",
                              staticStyle: { "margin-top": "5px" },
                            },
                            [
                              _c("span", { staticClass: "black-three" }, [
                                _vm._v("时间: "),
                              ]),
                              _vm._v(" " + _vm._s(item.doTime)),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "black",
                              staticStyle: { "margin-top": "5px" },
                            },
                            [
                              _c("span", { staticClass: "black-three" }, [
                                _vm._v("内容: "),
                              ]),
                              _vm._v(" " + _vm._s(item.doContent) + " "),
                            ]
                          ),
                        ])
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        2
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "驳回信息", destroyOnClose: true, width: 950 },
          on: { ok: _vm.rejectSubmit },
          model: {
            value: _vm.rejectDialog,
            callback: function ($$v) {
              _vm.rejectDialog = $$v
            },
            expression: "rejectDialog",
          },
        },
        [
          _c("form-index", {
            ref: "formReject",
            attrs: { formData: _vm.formRejectData },
          }),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "线下签署凭证", destroyOnClose: true, width: 950 },
          on: { ok: _vm.setEssOffSign, cancel: _vm.closeEssOffSign },
          model: {
            value: _vm.offSignDialog,
            callback: function ($$v) {
              _vm.offSignDialog = $$v
            },
            expression: "offSignDialog",
          },
        },
        [
          _c("form-index", {
            ref: "formOffSign",
            attrs: { formData: _vm.formOffSignData },
          }),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "电子合同作废", destroyOnClose: true, width: 600 },
          on: { ok: _vm.setEssDoInvalidSign },
          model: {
            value: _vm.offValiDialog,
            callback: function ($$v) {
              _vm.offValiDialog = $$v
            },
            expression: "offValiDialog",
          },
        },
        [
          _c("a-alert", {
            attrs: {
              type: "error",
              message:
                "电子签作废之后，无法再次恢复电子签，请慎重使用，如需作废，请详细填写作废具体原因！",
              banner: "",
            },
          }),
          _c("form-index", {
            ref: "formVali",
            staticClass: "mt-2",
            attrs: { formData: _vm.formValiData },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }