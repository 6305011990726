<template>

    <dev>
        <a-card title="用户列表" :bordered="false">
            <div id="components-form-demo-advanced-search">
                <a-form class="ant-advanced-search-form" :form="searchForm" >
                    <a-row :gutter="24">
                        <a-col :span="8" >
                            <a-form-item :label="`上报时间`">
                                <a-range-picker @change="onChange" :default-value="[moment(searchForm.startTime, dateFormat), moment(searchForm.endTime, dateFormat)]" />
                            </a-form-item>
                        </a-col>



                        <a-col :span="4" v-if ="showDzb">
                            <a-form-item :label="`党支部`">
                                <a-select
                                        v-model = "searchForm.dzbIndex"
                                        placeholder="请选择所属党支部"

                                >
                                    <a-select-option value="">
                                        所有
                                    </a-select-option>
                                    <a-select-option value="1">
                                        工程第一支部
                                    </a-select-option>
                                    <a-select-option value="2">
                                        工程第二支部
                                    </a-select-option>
                                    <a-select-option value="3">
                                        工程第三支部
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>

                        <a-col :span="4" :style="{ textAlign: 'right', marginTop: '3px'}">
                            <a-button type="primary" icon="search" :loading="loading" @click="search">
                                查询
                            </a-button>
                            <a-button :style="{ marginLeft: '8px' }" icon="download" :loading="loading" type="primary" @click="exportExcel">
                                导出
                            </a-button>
                        </a-col>
                    </a-row>
                </a-form>
            </div>
            <a-table
                    :columns="columns"
                    :data-source="data"
                    bordered
                    :scroll="{ x: 'calc(700px + 50%)', y: 600 }"
            />
        </a-card>
    </dev>


</template>
<script>
    import {getOfficeProjectStatics, exportOfficeProjectStatics} from '@/services/epidemic'
    import moment from 'moment';
    import {mapGetters} from 'vuex'
    import table2excel from 'js-table2excel'

    const columns = [
        {
            title: '公司名',
            dataIndex: 'officeName',
            key: 'officeName',
            align: 'center',
            ellipsis: true,
            className:'sue'
        },
        {
            title: '项目名',
            dataIndex: 'projectName',
            key: 'projectName',
            align: 'center',
            ellipsis: true,
        },
        {
            title: '项目在场人数',
            dataIndex: 'zaiCeNum',
            key: 'zaiCeNum',
            align: 'center',
            ellipsis: true,
        },
        {
            title: '项目上报人数',
            dataIndex: 'allNum',
            key: 'allNum',
            align: 'center',
            ellipsis: true,
        },
        {
            title: "疫苗接种情况",
            align: 'center',
            children:[
                {
                    title: "广州市内农民工情况",
                    align: 'center',
                    children: [
                        {
                            title: '总人数',
                            dataIndex: 'guangZhouAllNum',
                            key: 'guangZhouAllNum',
                            align: 'center',
                        },
                        {
                            title: '已接种疫苗人数(第一针)',
                            dataIndex: 'guangZhouOneVaccinesNum',
                            key: 'guangZhouOneVaccinesNum',
                            align: 'center',
                        },
                        {
                            title: '已接种疫苗人数(第二针)',
                            dataIndex: 'guangZhouTwoVaccinesNum',
                            key: 'guangZhouTwoVaccinesNum',
                            align: 'center',
                        },
                        {
                            title: '已接种疫苗人数(第三针)',
                            dataIndex: 'guangZhouThreeVaccinesNum',
                            key: 'guangZhouThreeVaccinesNum',
                            align: 'center',
                        },
                        {
                            title: '未接种疫苗人数',
                            dataIndex: 'guangZhouNoVaccinesNum',
                            key: 'guangZhouNoVaccinesNum',
                            align: 'center',
                        },
                    ]
                },
                {
                    title: "广州市外（含境外）农民工情况",
                    align: 'center',
                    children: [
                        {
                            title: '总人数',
                            dataIndex: 'otherCityNum',
                            key: 'otherCityNum',
                            align: 'center',
                        },
                        {
                            title: '已接种疫苗人数(第一针)',
                            dataIndex: 'otherCityOneVaccinesNum',
                            key: 'otherCityOneVaccinesNum',
                            align: 'center',
                        },
                        {
                            title: '已接种疫苗人数(第二针)',
                            dataIndex: 'otherCityTwoVaccinesNum',
                            key: 'otherCityTwoVaccinesNum',
                            align: 'center',
                        },
                        {
                            title: '已接种疫苗人数(第三针)',
                            dataIndex: 'otherCityThreeVaccinesNum',
                            key: 'otherCityThreeVaccinesNum',
                            align: 'center',
                        },
                        {
                            title: '未接种疫苗人数',
                            dataIndex: 'otherCityNoVaccinesNum',
                            key: 'otherCityNoVaccinesNum',
                            align: 'center',
                        },
                    ]
                },
            ]
        },
        {
          title: "核酸检测情况" ,
            align: 'center',
          children: [
              {
                  title: '已完成核酸检测人数',
                  dataIndex: 'checkNum',
                  key: 'checkNum',
                  align: 'center',
              },
              {
                  title: '未完成核酸检测人数',
                  dataIndex: 'noCheckNum',
                  key: 'noCheckNum',
                  align: 'center',
              },
          ]
        },
    ];

    export default {
        data() {
            return {
                data: [],
                columns,
                pagination:{
                    current:1,
                    pageSize:10,
                    total:0,
                },
                tableLoading: false,
                dateFormat: 'YYYY-MM-DD',
                // 筛选
                searchForm: {
                    queryOfficeId: undefined,
                    dzbIndex: ""
                },
                showDzb: false,
                loading: false,

            };
        },
        computed: {
            ...mapGetters('account', ['user']),
        },
        created() {
            this.initData()
        },
        methods: {
            moment,
            initData() {
                this.searchForm.endTime = this.getDay(0) + " 23:59:59"
                this.searchForm.startTime = this.getDay(-7) + " 00:00:00"

                this.getOfficeProjectStatics()

                this.showDzb = this.user.officeId == "298e49d5b6d24b3e9bcf1e5e02090fcc"

            },
            onChange(date, dateString) {
                this.searchForm.startTime = dateString[0] + " 00:00:00"
                this.searchForm.endTime = dateString[1] + " 23:59:59"
            },
            search() {
                this.getOfficeProjectStatics()
            },
            getOfficeProjectStatics(){
                this.data = []
                this.detailsData = []
                this.loading = true
                this.tableLoading = true
                getOfficeProjectStatics(this.searchForm).then(res=>{
                    this.data = res.data.data.list
                    this.pagination.total = res.data.data.total
                }).finally(()=>{
                    this.loading = false
                    this.tableLoading = false
                })
            },
            exportExcel(){
                exportOfficeProjectStatics(this.searchForm).then((res) => {
                    if(res.data.code == -1){
                        this.$message.warning(res.data.msg)
                    } else  {
                        console.log(res)
                        let fileName = this.searchForm.startTime.split(" ")[0] + '~' + this.searchForm.endTime.split(" ")[0] + '统计.xls'
                        let downloadElement = document.createElement('a')//创建dom

                        // let href = window.URL.createObjectURL(blob) //创建下载链接
                        let href = window.URL.createObjectURL(res.data) //创建下载链接

                        downloadElement.href = href
                        downloadElement.download = fileName //下载后文件名
                        document.body.appendChild(downloadElement)//添加创建的节点
                        downloadElement.click() //点击下载
                        document.body.removeChild(downloadElement) //下载完成移除元素
                        window.URL.revokeObjectURL(href) //释放掉blob对象
                        this.isLoading = false
                    }
                }).catch((err) => {
                    setTimeout(() => {
                        this.isLoading = false
                    }, 500);
                })
            },
            getDay(day){
                var today = new Date();
                var targetday_milliseconds=today.getTime() + 1000*60*60*24*day;
                today.setTime(targetday_milliseconds); //注意，这行是关键代码
                var tYear = today.getFullYear();

                var tMonth = today.getMonth();

                var tDate = today.getDate();

                tMonth = this.doHandleMonth(tMonth + 1);

                tDate = this.doHandleMonth(tDate);

                return tYear+"-"+tMonth+"-"+tDate;

            },
            doHandleMonth(month){
                var m = month;
                if(month.toString().length == 1){
                    m = "0" + month;
                }
                return m;

            }


        },
    };
</script>


<style scoped>
    .ant-advanced-search-form {
        padding: 24px;
        background: #fbfbfb;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        margin-bottom: 10px;
    }

    .ant-advanced-search-form .ant-form-item {
        display: flex;
    }

    .ant-advanced-search-form .ant-form-item-control-wrapper {
        flex: 1;
    }

    #components-form-demo-advanced-search .ant-form {
        max-width: none;
    }

    tr{
        background-color: #ffffff;
    }

</style>
