var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.$route.meta.showFather,
          expression: "$route.meta.showFather",
        },
      ],
      staticClass: "a_Approval",
    },
    [
      _vm.isProject
        ? [
            _c(
              "div",
              [
                _vm._m(0),
                _c("a-divider"),
                _c(
                  "a-form-model",
                  {
                    staticClass: "clearfix",
                    attrs: { model: _vm.params },
                    on: { submit: _vm.handleSubmit },
                  },
                  [
                    _c(
                      "a-col",
                      { attrs: { span: 5 } },
                      [
                        _c(
                          "a-form-model-item",
                          {
                            attrs: {
                              label: "日期",
                              labelCol: { span: 7 },
                              wrapperCol: { span: 14, offset: 1 },
                            },
                          },
                          [
                            _c("a-range-picker", {
                              attrs: { format: "YYYY-MM-DD" },
                              on: { change: _vm.onChange },
                              model: {
                                value: _vm.dateGroup,
                                callback: function ($$v) {
                                  _vm.dateGroup = $$v
                                },
                                expression: "dateGroup",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a-col",
                      { attrs: { span: 5 } },
                      [
                        _c(
                          "a-form-model-item",
                          {
                            attrs: {
                              label: "审核状态",
                              labelCol: { span: 8 },
                              wrapperCol: { span: 14, offset: 1 },
                            },
                          },
                          [
                            _c(
                              "a-select",
                              {
                                attrs: {
                                  allowClear: "",
                                  placeholder: "请选择",
                                },
                                model: {
                                  value: _vm.params.approveStatus,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.params, "approveStatus", $$v)
                                  },
                                  expression: "params.approveStatus",
                                },
                              },
                              _vm._l(_vm.approveList, function (item) {
                                return _c(
                                  "a-select-option",
                                  {
                                    key: item.value,
                                    attrs: {
                                      value: item.value,
                                      title: item.name,
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(item.name) + " ")]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "a-form-model-item",
                          [
                            _c(
                              "a-space",
                              { staticClass: "btnBox" },
                              [
                                _c(
                                  "a-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      "html-type": "submit",
                                    },
                                  },
                                  [_vm._v(" 查询 ")]
                                ),
                                _c(
                                  "a-button",
                                  { on: { click: _vm.resetQuery } },
                                  [_vm._v(" 重置 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "a_a_footer" },
                  [
                    _c(
                      "standard-table",
                      {
                        attrs: {
                          columns: _vm.columns,
                          dataSource: _vm.tableData,
                          rowKey: "id",
                          pagination: _vm.pagination,
                          loading: _vm.tableLoading,
                          isAlert: false,
                          scroll: { x: 1300 },
                          bordered: true,
                          btnArr: _vm.tableBtnArr,
                        },
                        on: { change: _vm.handleChangePage },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "index",
                              fn: function (ref) {
                                var index = ref.index
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        (_vm.pagination.current - 1) *
                                          _vm.pagination.pageSize +
                                          parseInt(index) +
                                          1
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "dateGroup",
                              fn: function (ref) {
                                var record = ref.record
                                return [
                                  _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(record.workStartDate) +
                                        " - " +
                                        _vm._s(record.workEndDate) +
                                        " "
                                    ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "approveStatus",
                              fn: function (ref) {
                                var text = ref.text
                                return [
                                  _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          text == 0
                                            ? "已提交"
                                            : text == 1
                                            ? "已审核"
                                            : "已退回"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "workContentCode",
                              fn: function (ref) {
                                var text = ref.text
                                return [
                                  _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.getWorkContent(text)) +
                                        " "
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2179388747
                        ),
                      },
                      [
                        _c("template", { slot: "dangerLevel" }, [
                          _c("div", { staticClass: "circle" }),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : [
            _c(
              "div",
              [
                _c(
                  "a-tabs",
                  {
                    attrs: { "default-active-key": 1 },
                    on: { change: _vm.tabCallback },
                  },
                  [
                    _c("a-tab-pane", {
                      key: "1",
                      attrs: { tab: "项目安全风险分级管控一览表" },
                    }),
                    _c("a-tab-pane", {
                      key: "2",
                      attrs: { tab: "项目安全风险管控台账" },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-form-model",
                  {
                    staticClass: "clearfix",
                    attrs: { model: _vm.params },
                    on: { submit: _vm.handleSubmit },
                  },
                  [
                    _vm.isBranchOffice || _vm.tabKey == 2
                      ? _c(
                          "a-col",
                          { attrs: { span: 5 } },
                          [
                            _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  label: "日期",
                                  labelCol: { span: 7 },
                                  wrapperCol: { span: 16, offset: 1 },
                                },
                              },
                              [
                                _c("a-range-picker", {
                                  attrs: {
                                    format: "YYYY-MM-DD",
                                    disabled: _vm.tabKey == 1,
                                  },
                                  on: { change: _vm.onChange },
                                  model: {
                                    value: _vm.dateGroup,
                                    callback: function ($$v) {
                                      _vm.dateGroup = $$v
                                    },
                                    expression: "dateGroup",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isJSOffice
                      ? _c(
                          "a-col",
                          { attrs: { span: 5 } },
                          [
                            _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  label: "工程管理部",
                                  labelCol: { span: 9 },
                                  wrapperCol: { span: 14, offset: 1 },
                                },
                              },
                              [
                                _c(
                                  "a-select",
                                  {
                                    attrs: {
                                      allowClear: "",
                                      placeholder: "请选择",
                                    },
                                    model: {
                                      value: _vm.params.gcglbId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.params, "gcglbId", $$v)
                                      },
                                      expression: "params.gcglbId",
                                    },
                                  },
                                  _vm._l(_vm.gcglbList, function (item) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: item.value,
                                        attrs: {
                                          value: item.value,
                                          title: item.name,
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(item.name) + " ")]
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.isProject
                      ? _c(
                          "a-col",
                          { attrs: { span: 5 } },
                          [
                            _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  label: "项目名称",
                                  labelCol: { span: 8 },
                                  wrapperCol: { span: 14, offset: 1 },
                                },
                              },
                              [
                                _c("a-input", {
                                  attrs: {
                                    placeholder: "请输入项目名称",
                                    allowClear: "",
                                  },
                                  model: {
                                    value: _vm.params.projectName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.params, "projectName", $$v)
                                    },
                                    expression: "params.projectName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.isJSOffice
                      ? _c(
                          "a-col",
                          { attrs: { span: 5 } },
                          [
                            _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  label: "审核状态",
                                  labelCol: { span: 8 },
                                  wrapperCol: { span: 14, offset: 1 },
                                },
                              },
                              [
                                _c(
                                  "a-select",
                                  {
                                    attrs: {
                                      allowClear: "",
                                      placeholder: "请选择",
                                    },
                                    model: {
                                      value: _vm.params.approveStatus,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.params,
                                          "approveStatus",
                                          $$v
                                        )
                                      },
                                      expression: "params.approveStatus",
                                    },
                                  },
                                  _vm._l(_vm.approveList, function (item) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: item.value,
                                        attrs: {
                                          value: item.value,
                                          title: item.name,
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(item.name) + " ")]
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "a-col",
                      { attrs: { span: 4 } },
                      [
                        _c(
                          "a-space",
                          { staticClass: "btnBox" },
                          [
                            _c(
                              "a-button",
                              {
                                attrs: {
                                  type: "primary",
                                  "html-type": "submit",
                                },
                              },
                              [_vm._v(" 查询 ")]
                            ),
                            _c("a-button", { on: { click: _vm.resetQuery } }, [
                              _vm._v(" 重置 "),
                            ]),
                            this.tabKey == "2"
                              ? _c(
                                  "a-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: { click: _vm.doDowload },
                                  },
                                  [_vm._v(" 导出 ")]
                                )
                              : _vm._e(),
                            _vm.isJSOffice && _vm.tabKey == 1
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "pointer ml-3",
                                    staticStyle: {
                                      color: "#2BA4FF",
                                      "border-bottom": "1px solid #2BA4FF",
                                    },
                                    on: { click: _vm.uploadRecord },
                                  },
                                  [_vm._v("上报记录")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "a_a_footer" },
                  [
                    _c(
                      "standard-table",
                      {
                        attrs: {
                          columns: _vm.columns,
                          dataSource: _vm.tableData,
                          rowKey: "id",
                          pagination: _vm.pagination,
                          loading: _vm.tableLoading,
                          isAlert: false,
                          scroll: { x: 1300 },
                          bordered: true,
                          btnArr: _vm.tableBtnArr,
                        },
                        on: { change: _vm.handleChangePage },
                        scopedSlots: _vm._u([
                          {
                            key: "index",
                            fn: function (ref) {
                              var index = ref.index
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      (_vm.pagination.current - 1) *
                                        _vm.pagination.pageSize +
                                        parseInt(index) +
                                        1
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "dateGroup",
                            fn: function (ref) {
                              var record = ref.record
                              return [
                                _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(record.workStartDate) +
                                      " - " +
                                      _vm._s(record.workEndDate) +
                                      " "
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "approveStatus",
                            fn: function (ref) {
                              var text = ref.text
                              return [
                                _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        text == 0
                                          ? "已提交"
                                          : text == 1
                                          ? "已审核"
                                          : "已退回"
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "workContentCode",
                            fn: function (ref) {
                              var text = ref.text
                              return [
                                _c("div", [
                                  _vm._v(
                                    " " + _vm._s(_vm.getWorkContent(text)) + " "
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "action",
                            fn: function (ref) {
                              var record = ref.record
                              return [
                                _vm.isBranchOffice && record.approveStatus == 0
                                  ? _c("div", [
                                      _c(
                                        "a",
                                        {
                                          on: {
                                            click: function () {
                                              return _vm.handleOperate(
                                                record,
                                                1
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 通过 ")]
                                      ),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "ml-2",
                                          staticStyle: { color: "FA367A" },
                                          on: {
                                            click: function () {
                                              return _vm.handleOperate(
                                                record,
                                                2
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 退回 ")]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _c("template", { slot: "tableTitle" }, [
                          _c(
                            "div",
                            [
                              _c(
                                "a-space",
                                { staticClass: "operator mb-3" },
                                [
                                  _vm.isBranchOffice && _vm.tabKey == 1
                                    ? _c(
                                        "a-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            disabled: _vm.uploadBtnDisabled,
                                          },
                                          on: { click: _vm.handleUploadNoRisk },
                                        },
                                        [_vm._v(_vm._s(_vm.uploadRecordText))]
                                      )
                                    : _vm._e(),
                                  _vm.isBranchOffice && _vm.tabKey == 1
                                    ? _c("span", [
                                        _vm._v(
                                          "下周（" +
                                            _vm._s(
                                              _vm
                                                .moment(this.params.startDate)
                                                .add(7, "days")
                                                .format("YYYY-MM-DD")
                                            ) +
                                            " ~ " +
                                            _vm._s(
                                              _vm
                                                .moment(this.params.endDate)
                                                .add(7, "days")
                                                .format("YYYY-MM-DD")
                                            ) +
                                            "）"
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.isBranchOffice &&
                                  _vm.uploadRecordText == "取消上报"
                                    ? _c("span", [_vm._v("无危大危险源上报")])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.isJSOffice
                            ? _c(
                                "div",
                                [
                                  _c("div", [
                                    _vm.tabKey == 1
                                      ? _c("span", [_vm._v("本周（")])
                                      : _vm._e(),
                                    _vm._v(
                                      _vm._s(_vm.params.startDate) +
                                        " ~ " +
                                        _vm._s(_vm.params.endDate)
                                    ),
                                    _vm.tabKey == 1
                                      ? _c("span", [_vm._v("）计划")])
                                      : _vm._e(),
                                    _vm._v("公司项目安全风险管控情况："),
                                  ]),
                                  _vm.showStatic(_vm.staticObj)
                                    ? _vm._l(
                                        _vm.staticObj,
                                        function (value, key) {
                                          return _c(
                                            "div",
                                            { key: key },
                                            [
                                              key == 1 &&
                                              value.some(function (el) {
                                                return el.nums > 0
                                              })
                                                ? _c("span", [
                                                    _vm._v(
                                                      "起重吊装及起重机械安装拆卸工程："
                                                    ),
                                                  ])
                                                : key == 2 &&
                                                  value.some(function (el) {
                                                    return el.nums > 0
                                                  })
                                                ? _c("span", [
                                                    _vm._v("塔吊顶升："),
                                                  ])
                                                : _vm._e(),
                                              _vm._l(
                                                value,
                                                function (item, index) {
                                                  return _c(
                                                    "span",
                                                    { key: index },
                                                    [
                                                      item.nums
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.workContent +
                                                                  "（" +
                                                                  item.nums +
                                                                  "）；"
                                                              )
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        }
                                      )
                                    : [_c("div", [_vm._v(" 暂无 ")])],
                                ],
                                2
                              )
                            : _vm._e(),
                        ]),
                        _c("template", { slot: "dangerLevel" }, [
                          _c("div", { staticClass: "circle" }),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
      _c(
        "a-modal",
        {
          attrs: {
            title: "新增项目安全分级管控",
            visible: _vm.addNewVisible,
            "confirm-loading": _vm.confirmLoading,
            width: 1100,
          },
          on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
        },
        [
          _c(
            "a-form-model",
            {
              staticClass: "mb-5 query-form",
              attrs: { layout: "inline", model: _vm.addNewParams },
            },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "作业时间", required: "" } },
                [
                  _c("a-range-picker", {
                    staticStyle: { width: "220px" },
                    attrs: { format: "YYYY-MM-DD" },
                    on: { change: _vm.onWorkDateChange },
                    model: {
                      value: _vm.workDateGroup,
                      callback: function ($$v) {
                        _vm.workDateGroup = $$v
                      },
                      expression: "workDateGroup",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "工作内容", required: "" } },
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "250px" },
                      attrs: { allowClear: "", placeholder: "请选择" },
                      on: { change: _vm.handleCodeChange },
                      model: {
                        value: _vm.addNewParams.workContentCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.addNewParams, "workContentCode", $$v)
                        },
                        expression: "addNewParams.workContentCode",
                      },
                    },
                    _vm._l(_vm.workContentList, function (item) {
                      return _c(
                        "a-select-option",
                        {
                          key: item.value,
                          attrs: { value: item.value, title: item.name },
                        },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.showSelfEquity
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "设备编号", required: "" } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "220px" },
                          attrs: { allowClear: "", placeholder: "请选择" },
                          model: {
                            value: _vm.addNewParams.selfEquityNum,
                            callback: function ($$v) {
                              _vm.$set(_vm.addNewParams, "selfEquityNum", $$v)
                            },
                            expression: "addNewParams.selfEquityNum",
                          },
                        },
                        _vm._l(_vm.selfEquityList, function (item) {
                          return _c(
                            "a-select-option",
                            {
                              key: item.value,
                              attrs: { value: item.value, title: item.name },
                            },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "上报记录",
            visible: _vm.uploadRecordVisible,
            width: 800,
            footer: null,
          },
          on: { cancel: _vm.handleCancelUploadRecord },
        },
        [
          _c(
            "a-form-model",
            {
              staticClass: "mb-5 query-form",
              attrs: { layout: "inline", model: _vm.params },
              on: { submit: _vm.handleSubmitUploadRecord },
            },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "日期" } },
                [
                  _c("a-range-picker", {
                    staticStyle: { width: "220px" },
                    attrs: { format: "YYYY-MM-DD" },
                    on: { change: _vm.onChangeUploadRecord },
                    model: {
                      value: _vm.uploadRecordDateGroup,
                      callback: function ($$v) {
                        _vm.uploadRecordDateGroup = $$v
                      },
                      expression: "uploadRecordDateGroup",
                    },
                  }),
                ],
                1
              ),
              _c("a-form-model-item", [
                _c(
                  "div",
                  [
                    _c(
                      "a-button",
                      {
                        staticClass: "mr-9",
                        attrs: { type: "primary", "html-type": "submit" },
                      },
                      [_vm._v(" 查询 ")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c("standard-table", {
            attrs: {
              columns: _vm.uploadRecordColumns,
              dataSource: _vm.uploadRecordTableData,
              rowKey: "gcglbId",
              pagination: _vm.uploadRecordPagination,
              loading: _vm.tableLoading,
              isAlert: false,
              bordered: true,
            },
            on: { change: _vm.handleChangeUploadRecordPage },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function (ref) {
                  var index = ref.index
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          (_vm.uploadRecordPagination.current - 1) *
                            _vm.uploadRecordPagination.pageSize +
                            parseInt(index) +
                            1
                        )
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "submitStatus",
                fn: function (ref) {
                  var text = ref.text
                  return [
                    text == 0
                      ? _c("span", { staticStyle: { color: "#FA367A" } }, [
                          _vm._v("未上报"),
                        ])
                      : _c("span", [_vm._v("已上报")]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("Export-loading", {
        attrs: {
          cPercent: _vm.cPercent,
          isExporting: _vm.isExporting,
          loadingText: _vm.loadingText,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("span", { staticClass: "plug-Overview" }, [
        _vm._v("项目安全风险管控一览表"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }