var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "constructiontTable_index" },
        [
          _c(
            "a-tabs",
            {
              attrs: {
                "default-active-key": _vm.defaultActiveKey,
                forceRender: "",
              },
              on: { change: _vm.callback },
            },
            [
              _c("a-tab-pane", { key: "1", attrs: { tab: "在场管理人员" } }),
              _c("a-tab-pane", { key: "0", attrs: { tab: "离场管理人员" } }),
            ],
            1
          ),
          _c("form-index", {
            ref: "formindex",
            attrs: {
              formData: _vm.formData,
              flexAction: true,
              baiduMapBoolen: false,
            },
            on: {
              handleSubmit: _vm.handleSubmit,
              handleChange: _vm.handleChange,
              handlSelect: _vm.handlSelect,
            },
            scopedSlots: _vm._u([
              {
                key: "flexAction",
                fn: function () {
                  return [
                    _c(
                      "a-space",
                      { style: { marginTop: "4px" } },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: {
                              icon: "search",
                              type: "primary",
                              "html-type": "submit",
                            },
                          },
                          [_vm._v("查询")]
                        ),
                        _c("a-button", { on: { click: _vm.close } }, [
                          _vm._v(" 重置 "),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c(
            "a-button",
            {
              staticStyle: { "margin-right": "8px" },
              attrs: { icon: "plus", type: "primary" },
              on: { click: _vm.addPerson },
            },
            [_vm._v("新 增")]
          ),
          _c(
            "a-space",
            { staticClass: "operator" },
            [
              _vm.canOperate && _vm.defaultActiveKey == 1
                ? _c(
                    "a-button",
                    {
                      attrs: {
                        ghost: "",
                        type: "primary",
                        icon: "cloud-download",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.distribute2(false)
                        },
                      },
                    },
                    [_vm._v("分步下发考勤设备")]
                  )
                : _vm._e(),
              _vm.canOperate && _vm.defaultActiveKey == 0
                ? _c(
                    "a-button",
                    {
                      attrs: {
                        ghost: "",
                        type: "primary",
                        icon: "cloud-download",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.distribute2(true)
                        },
                      },
                    },
                    [_vm._v("批量删除考勤权限")]
                  )
                : _vm._e(),
              _c(
                "a-button",
                {
                  staticStyle: {
                    background: "#fd9e06 !important",
                    color: "#fff",
                    "border-color": "#fd9e06",
                  },
                  attrs: { icon: "history", ghost: "" },
                  on: { click: _vm.failRecord },
                },
                [_vm._v(" 下发失败记录 ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "10px" } },
            [
              _c("standard-table", {
                attrs: {
                  columns: _vm.columns,
                  dataSource: _vm.dataSource,
                  rowKey: "id",
                  pagination: _vm.pagination,
                  loading: _vm.tableLoading,
                  isAlert: false,
                  bordered: true,
                  selectedRows: _vm.selectedRows,
                  tableSize: "middle",
                },
                on: {
                  change: _vm.onPageChange,
                  "update:selectedRows": [
                    function ($event) {
                      _vm.selectedRows = $event
                    },
                    function ($event) {
                      return _vm.updateSelectRows($event)
                    },
                  ],
                  "update:selected-rows": function ($event) {
                    _vm.selectedRows = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "index",
                    fn: function (ref) {
                      var index = ref.index
                      return [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                (_vm.pagination.current - 1) *
                                  _vm.pagination.pageSize +
                                  parseInt(index) +
                                  1
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "roleName",
                    fn: function (ref) {
                      var text = ref.text
                      return [
                        text && text.length > 0
                          ? _vm._l(text, function (item, index) {
                              return _c("div", { key: index }, [
                                _vm._v(" " + _vm._s(item) + " "),
                              ])
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "manPhone",
                    fn: function (ref) {
                      var text = ref.text
                      var record = ref.record
                      return [
                        _c(
                          "div",
                          { staticClass: "flex flex-column a-center mt-1" },
                          [
                            _c("a-avatar", {
                              attrs: {
                                shape: "square",
                                size: 64,
                                src: text,
                                icon: "user",
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "flex a-center j-center",
                                staticStyle: { "margin-top": "5px" },
                              },
                              [
                                record.hkFace != "" && record.hkFace != null
                                  ? _c("div", [
                                      _vm._v(" " + _vm._s(record.hkFace) + " "),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "hkFace",
                    fn: function (ref) {
                      var text = ref.text
                      return [
                        _c(
                          "div",
                          { staticClass: "flex a-center j-center" },
                          [
                            text != "" && text != null
                              ? _c("div", [_vm._v(" " + _vm._s(text) + " ")])
                              : _vm._e(),
                            _c("a-spin", { attrs: { spinning: !text } }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "isLogin",
                    fn: function (ref) {
                      var text = ref.text
                      return [
                        text == 0
                          ? _c("span", [
                              _c("i", {
                                staticClass: "iconfont duigouxiao",
                                staticStyle: { color: "#00CE86" },
                              }),
                            ])
                          : _c("span", [
                              _c("i", {
                                staticClass: "iconfont path",
                                staticStyle: { color: "#FF5C77" },
                              }),
                            ]),
                      ]
                    },
                  },
                  {
                    key: "isReportAtte",
                    fn: function (ref) {
                      var text = ref.text
                      return [
                        text == 1
                          ? _c("span", [
                              _c("i", {
                                staticClass: "iconfont duigouxiao",
                                staticStyle: { color: "#00CE86" },
                              }),
                            ])
                          : _c("span", [
                              _c("i", {
                                staticClass: "iconfont path",
                                staticStyle: { color: "#FF5C77" },
                              }),
                            ]),
                      ]
                    },
                  },
                  {
                    key: "action",
                    fn: function (ref) {
                      var record = ref.record
                      return _c("div", {}, [
                        _c(
                          "div",
                          [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.viewEditing(record, false)
                                  },
                                },
                              },
                              [_vm._v("修改")]
                            ),
                            record.status == 1
                              ? _c("a-divider", { attrs: { type: "vertical" } })
                              : _vm._e(),
                            record.status == 1
                              ? _c(
                                  "a",
                                  {
                                    staticStyle: { color: "red" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.setLeave(record)
                                      },
                                    },
                                  },
                                  [_vm._v("离场")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ])
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.modalTitle,
            destroyOnClose: true,
            width: _vm.modalWidth,
            footer: null,
          },
          on: { cancel: _vm.modalCancel },
          model: {
            value: _vm.modalVisible,
            callback: function ($$v) {
              _vm.modalVisible = $$v
            },
            expression: "modalVisible",
          },
        },
        [
          _vm.modalTitle === "下发任务"
            ? _c("span", [
                _vm._v(
                  "该列表有效期为3小时,结束后请前去“下发失败记录”中查看是否下发失败"
                ),
              ])
            : _vm._e(),
          _vm.errMsg
            ? _c("span", { staticClass: "mt-1" }, [_vm._v(_vm._s(_vm.errMsg))])
            : _vm._e(),
          _c("standard-table", {
            staticClass: "mt-1",
            attrs: {
              columns: _vm.modalColumns,
              dataSource: _vm.modalDataSource,
              rowKey: "id",
              pagination: _vm.modalPagination,
              loading: _vm.modalTableLoading,
              isAlert: false,
              bordered: true,
              tableSize: "middle",
            },
            on: { change: _vm.onModalPageChange },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function (ref) {
                  var index = ref.index
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            (_vm.modalPagination.current - 1) *
                              _vm.modalPagination.pageSize +
                              parseInt(index) +
                              1
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("distribute-dialog", {
        attrs: {
          visible: _vm.distributeVisible,
          isDel: _vm.isDel,
          modalTitle: _vm.isDel ? "删除考勤权限" : "下发考勤设备",
          workNumList: _vm.userIdList,
        },
        on: {
          cancel: function ($event) {
            _vm.distributeVisible = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }