var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form",
    {
      staticClass: "form",
      attrs: { form: _vm.form },
      on: { submit: _vm.handleSubmit },
    },
    [
      _c(
        "a-row",
        { staticClass: "form-row" },
        [
          _c(
            "a-col",
            { attrs: { lg: 6, md: 12, sm: 24 } },
            [
              _c(
                "a-form-item",
                { attrs: { label: _vm.$t("name") } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "task.name",
                          {
                            rules: [
                              {
                                required: true,
                                message: _vm.$ta("input|name"),
                                whitespace: true,
                              },
                            ],
                          },
                        ],
                        expression:
                          "['task.name', {rules: [{ required: true, message: $ta('input|name'), whitespace: true}]}]",
                      },
                    ],
                    attrs: { placeholder: _vm.$ta("input|name") },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            {
              attrs: {
                xl: { span: 6, offset: 2 },
                lg: { span: 8 },
                md: { span: 12 },
                sm: 24,
              },
            },
            [
              _c(
                "a-form-item",
                { attrs: { label: _vm.$t("describe") } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "task.description",
                          {
                            rules: [
                              {
                                required: true,
                                message: _vm.$ta("input|describe"),
                                whitespace: true,
                              },
                            ],
                          },
                        ],
                        expression:
                          "['task.description', {rules: [{ required: true, message: $ta('input|describe'), whitespace: true}]}]",
                      },
                    ],
                    attrs: { placeholder: _vm.$ta("input|describe") },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            {
              attrs: {
                xl: { span: 8, offset: 2 },
                lg: { span: 10 },
                md: { span: 24 },
                sm: 24,
              },
            },
            [
              _c(
                "a-form-item",
                { attrs: { label: _vm.$t("executor") } },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "task.executor",
                            {
                              rules: [
                                {
                                  required: true,
                                  message: _vm.$ta("select|executor"),
                                },
                              ],
                            },
                          ],
                          expression:
                            "['task.executor', {rules: [{ required: true, message: $ta('select|executor')}]}]",
                        },
                      ],
                      attrs: { placeholder: _vm.$ta("select|executor") },
                    },
                    [
                      _c("a-select-option", { attrs: { value: "黄丽丽" } }, [
                        _vm._v("黄丽丽"),
                      ]),
                      _c("a-select-option", { attrs: { value: "李大刀" } }, [
                        _vm._v("李大刀"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-row",
        { staticClass: "form-row" },
        [
          _c(
            "a-col",
            { attrs: { lg: 6, md: 12, sm: 24 } },
            [
              _c(
                "a-form-item",
                { attrs: { label: _vm.$t("duty") } },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "task.manager",
                            {
                              rules: [
                                {
                                  required: true,
                                  message: _vm.$ta("select|duty"),
                                },
                              ],
                            },
                          ],
                          expression:
                            "['task.manager', {rules: [{ required: true, message: $ta('select|duty')}]}]",
                        },
                      ],
                      attrs: { placeholder: _vm.$ta("select|duty") },
                    },
                    [
                      _c("a-select-option", { attrs: { value: "王伟" } }, [
                        _vm._v("王伟"),
                      ]),
                      _c("a-select-option", { attrs: { value: "李红军" } }, [
                        _vm._v("李红军"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            {
              attrs: {
                xl: { span: 6, offset: 2 },
                lg: { span: 8 },
                md: { span: 12 },
                sm: 24,
              },
            },
            [
              _c(
                "a-form-item",
                { attrs: { label: _vm.$t("time") } },
                [
                  _c("a-time-picker", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "task.time",
                          {
                            rules: [
                              {
                                required: true,
                                message: _vm.$ta("select|time"),
                              },
                            ],
                          },
                        ],
                        expression:
                          "['task.time', {rules: [{ required: true, message: $ta('select|time')}]}]",
                      },
                    ],
                    staticStyle: { width: "100%" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            {
              attrs: {
                xl: { span: 8, offset: 2 },
                lg: { span: 10 },
                md: { span: 24 },
                sm: 24,
              },
            },
            [
              _c(
                "a-form-item",
                { attrs: { label: _vm.$t("type") } },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "task.type",
                            {
                              rules: [
                                {
                                  required: true,
                                  message: _vm.$ta("select|type"),
                                },
                              ],
                            },
                          ],
                          expression:
                            "['task.type', {rules: [{ required: true, message: $ta('select|type')}]}]",
                        },
                      ],
                      attrs: { placeholder: _vm.$ta("select|type") },
                    },
                    [
                      _c("a-select-option", { attrs: { value: "定时执行" } }, [
                        _vm._v("定时执行"),
                      ]),
                      _c("a-select-option", { attrs: { value: "周期执行" } }, [
                        _vm._v("周期执行"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showSubmit
        ? _c(
            "a-form-item",
            [
              _c("a-button", { attrs: { htmlType: "submit" } }, [
                _vm._v("Submit"),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }