var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("form-index", {
        ref: "formindex",
        attrs: { formData: _vm.formData, flexAction: true },
        on: { handleSubmit: _vm.handleSubmit },
        scopedSlots: _vm._u([
          {
            key: "flexAction",
            fn: function () {
              return [
                _c(
                  "a-space",
                  { style: { marginTop: "4px" } },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: {
                          icon: "search",
                          type: "primary",
                          "html-type": "submit",
                        },
                      },
                      [_vm._v(" 查询 ")]
                    ),
                    _c("a-button", { on: { click: _vm.close } }, [
                      _vm._v(" 重置 "),
                    ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticStyle: { "margin-bottom": "5px" } },
        [
          _vm.user.loginType == 0 && _vm.canOperate
            ? _c(
                "a-button",
                {
                  staticStyle: { "margin-right": "10px" },
                  attrs: { icon: "plus", type: "primary" },
                  on: { click: _vm.addNewDevice },
                },
                [_vm._v("新增")]
              )
            : _vm._e(),
          _c(
            "a-space",
            { staticClass: "operator" },
            [
              _vm.canOperate
                ? _c("a-button", { on: { click: _vm.regionManagement } }, [
                    _vm._v("区域管理"),
                  ])
                : _vm._e(),
              _vm.canOperate
                ? _c(
                    "a-button",
                    {
                      attrs: {
                        ghost: "",
                        type: "primary",
                        icon: "cloud-download",
                      },
                      on: { click: _vm.distribute2 },
                    },
                    [_vm._v("下发考勤设备")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("standard-table", {
        attrs: {
          columns: _vm.columns,
          dataSource: _vm.dataSource,
          selectedRows: _vm.selectedRows,
          rowKey: "id",
          pagination: _vm.pagination,
          loading: _vm.tableLoading,
          isAlert: false,
          bordered: true,
          tableSize: "middle",
        },
        on: {
          "update:selectedRows": [
            function ($event) {
              _vm.selectedRows = $event
            },
            function ($event) {
              return _vm.updateSelectRows($event)
            },
          ],
          "update:selected-rows": function ($event) {
            _vm.selectedRows = $event
          },
          change: _vm.onPageChange,
        },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function (ref) {
              var index = ref.index
              return [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        (_vm.pagination.current - 1) * _vm.pagination.pageSize +
                          parseInt(index) +
                          1
                      ) +
                      " "
                  ),
                ]),
              ]
            },
          },
          {
            key: "online",
            fn: function (ref) {
              var record = ref.record
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      Number(record.online)
                        ? "在线"
                        : record.manufacturerKey === "HK"
                        ? "/"
                        : "不在线"
                    )
                  ),
                ]),
              ]
            },
          },
          {
            key: "inOutFlag",
            fn: function (ref) {
              var record = ref.record
              return [
                _c("span", [
                  _vm._v(_vm._s(record.inOutFlag == 1 ? "进入" : "出去")),
                ]),
              ]
            },
          },
          {
            key: "manufacturerKey",
            fn: function (ref) {
              var record = ref.record
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      record.manufacturerKey == "yunqi"
                        ? "云启"
                        : record.manufacturerKey == "HK"
                        ? "海康"
                        : ""
                    )
                  ),
                ]),
              ]
            },
          },
          {
            key: "distinguishInOut",
            fn: function (ref) {
              var record = ref.record
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(record.distinguishInOut == "1" ? "区分" : "不区分")
                  ),
                ]),
              ]
            },
          },
          {
            key: "isAttend",
            fn: function (ref) {
              var record = ref.record
              return [
                _c("span", [
                  _vm._v(_vm._s(record.isAttend == "1" ? "是" : "否")),
                ]),
              ]
            },
          },
          {
            key: "action",
            fn: function (ref) {
              var record = ref.record
              return [
                _vm.canOperate
                  ? _c(
                      "div",
                      [
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: record.manufacturerKey === "HK",
                                expression: "record.manufacturerKey === 'HK'",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.operation(1, record)
                              },
                            },
                          },
                          [_vm._v("同步")]
                        ),
                        _c("a-divider", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: record.manufacturerKey === "HK",
                              expression: "record.manufacturerKey === 'HK'",
                            },
                          ],
                          attrs: { type: "vertical" },
                        }),
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.operation(2, record)
                              },
                            },
                          },
                          [_vm._v(" 修改 ")]
                        ),
                        _c("a-divider", { attrs: { type: "vertical" } }),
                        _c(
                          "a",
                          {
                            staticStyle: { color: "#ff5c77" },
                            on: {
                              click: function ($event) {
                                return _vm.operation(3, record)
                              },
                            },
                          },
                          [_vm._v(" 删除 ")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c(
        "a-modal",
        {
          attrs: {
            title: "新增设备",
            destroyOnClose: true,
            width: 1100,
            maskClosable: false,
            "body-style": { height: "370px", overflowY: "auto" },
          },
          on: { ok: _vm.handleAddNewDeviceSubmit, cancel: _vm.cancelModal },
          model: {
            value: _vm.addNewDeviceDialog,
            callback: function ($$v) {
              _vm.addNewDeviceDialog = $$v
            },
            expression: "addNewDeviceDialog",
          },
        },
        [
          _c("form-index", {
            ref: "addNewDeviceFormIndex",
            attrs: { formData: _vm.addNewDeviceFormData, options: _vm.options },
            on: { handlSelect: _vm.handlDeviceSelect },
          }),
        ],
        1
      ),
      _c("distribute-dialog", {
        attrs: {
          visible: _vm.distributeVisible,
          isDel: false,
          modalTitle: "下发考勤设备",
          workNumList: _vm.userIdList,
        },
        on: {
          cancel: function ($event) {
            _vm.distributeVisible = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }