var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "root", staticClass: "action-size" },
    [
      _c(
        "a-tooltip",
        { attrs: { title: "密度" } },
        [
          _c(
            "a-dropdown",
            {
              attrs: {
                placement: "bottomCenter",
                trigger: ["click"],
                "get-popup-container": function () {
                  return _vm.$refs.root
                },
              },
            },
            [
              _c("a-icon", {
                staticClass: "action",
                attrs: { type: "column-height" },
              }),
              _c(
                "a-menu",
                {
                  attrs: { slot: "overlay", "selected-keys": [_vm.value] },
                  on: { click: _vm.onClick },
                  slot: "overlay",
                },
                [
                  _c("a-menu-item", { key: "default" }, [_vm._v(" 默认 ")]),
                  _c("a-menu-item", { key: "middle" }, [_vm._v(" 中等 ")]),
                  _c("a-menu-item", { key: "small" }, [_vm._v(" 紧密 ")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }