<template>
  <div class="a_Method">
    <a-row :gutter="16">
      <a-col :span="24">
        <a-card
          title="设置门禁通道"
          :bordered="false"
          style="height: 900px"
          :style="'overflow:auto'"
        >
          <div class="action">
            <a-button
              icon="plus"
              type="primary"
              @click="onContextMenuClick('0', '1')"
              v-if="canOperate"
              >新增区域</a-button
            >
          </div>
          <a-tree
            v-model="checkedKeys"
            :tree-data="treeDataSource"
            checkable
            :selected-keys="selectedKeys"
            @select="onSelect"
            @check="onCheck"
            checkStrictly	
            :defaultExpandParent="true"
            :defaultExpandAll="true"
            v-if="!spinning"
          >
            <a-spin :spinning="spinning" />
            <template #title="{ key: treeKey, title }">
              <a-dropdown :trigger="['contextmenu']">
                <div class="flex">
                  <span style="width: 200px">{{ title }}</span>
                  <div class="sTables ml-5">
                    <div @click="onContextMenuClick(treeKey, '1')" v-if="canOperate">新增</div>
                    <div class="ml-2" @click="onContextMenuClick(treeKey, '2')">
                      编辑
                    </div>
                    <div class="ml-2" @click="onContextMenuClick(treeKey, '3')">
                      删除
                    </div>
                  </div>
                </div>
                <template #overlay>
                  <a-menu
                    @click="
                      ({ key: menuKey }) => onContextMenuClick(treeKey, menuKey)
                    "
                  >
                    <a-menu-item key="1" v-if="canOperate">新增</a-menu-item>
                    <a-menu-item key="2">编辑</a-menu-item>
                    <a-menu-item key="3">删除</a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </template>
          </a-tree>
        </a-card>
      </a-col>
    </a-row>
    <!-- 新增区域框 -->
    <a-modal
      v-model="addNewDialog"
      title="新增区域"
      :width="600"
      :body-style="{ height: '200px', overflowY: 'auto' }"
      @ok="handleAddNewSubmit"
    >
      <form-index ref="addNewFormIndex" :formData="addNewFormData">
      </form-index>
    </a-modal>
  </div>
</template>

<script>
import { getGroupList, addGroup, delGroup, updateGroup } from "@/services/dmgr";
import { treeData, mapTree } from "@/utils/treeutil";
import formIndex from "@/pages/components/form/index";
import {mapGetters} from 'vuex'
import {
  getGroupOfficeAccControl,
  addGroupOfficeAccControl,
  updateGroupOfficeAccControl,
} from "@/services/participationUnit";
const addNewFormData = [
  {
    label: "区域名称",
    placeholder: "请填写",
    decorator: [
      "groupName",
      { rules: [{ required: false, message: "请填写" }] },
    ],
    type: "input",
    key: "groupName",
    col: 24,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
];
export default {
  components: {
    formIndex,
  },
  props: {
    projectId: {
      type: [String, Number],
      require: false,
    },
    officeId: {
      type: [String, Number],
      require: false,
    },
  },
  data() {
    return {
      treeDataSource: [],
      selectedKeys: [],
      checkedKeys: [],
      expandedKeys: [],
      originData: [],
      officeTree: [],
      queryData: {
        projectId: undefined,
        officeId: undefined
      },
      addNewDialog: false,
      addNewFormData: addNewFormData,
      fatherGroupId: "",
      showAddNewBtn: false,
      isUpdate: false, //判断是编辑还是新增 false:新增 true:编辑
      tempId: "",
      arrRoles:['zong_bao_lao_wu', 'admin', 'fen_bao_lao_wu'], // 新增权限角色
      spinning: true
    };
  },
  computed: {
    ...mapGetters('account', ['user']),
    canOperate() {
      return this.user.nowRoleKey.some(role => this.arrRoles.includes(role));
    }
  },
  mounted() {
    // 如果是项目详情
    // this.projectId = this.projectId ? this.projectId : this.user.projectId;
    // this.officeId = this.officeId ? this.officeId : this.user.officeId;
    this.queryData.projectId = this.projectId ? this.projectId : this.user.projectId;
    this.queryData.officeId = this.officeId ? this.officeId : this.user.officeId;
    this.getGroupList();
  },

  methods: {
    getGroupList() {
      getGroupList(this.queryData).then((res) => {
        if (res.data.code === 0) {
          if (res.data.data.length == 0) {
            if (!this.canOperate) {
              this.$message.warning("没有场地分区，请项目总包或分包劳务员创建",6);
            }
          }
          this.originData = res.data.data;
          let list = treeData(
            res.data.data,
            "groupId",
            "fatherGroupId",
            "children",
            "0"
          );
          this.treeDataSource = list.map((el) => mapTree(el));
          this.spinning = false;
          // console.log(this.treeDataSource)
        } else {
          this.$message.error(res.data.msg);
        }
      });
      getGroupOfficeAccControl({officeId:this.queryData.officeId,projectId: this.queryData.projectId}).then((res) => {
        this.officeTree = res.data.data;
        let arr = [];
        this.originData.forEach((group) => {
          if (group.fatherGroupId == "0") {
            this.fatherGroupId = group.groupId;
          }
        });
        res.data.data.forEach((item) => {
          // if (!(item.groupId == this.fatherGroupId)) {
          //   arr.push(item.groupId);
          // }
          arr.push(item.groupId);
        });
        this.checkedKeys = arr;
      });
    },
    onContextMenuClick(treeKey, menuKey) {
      let _that = this;
      if (menuKey === "1") {
        this.modalTitle = "新增区域";
        this.addNewDialog = true;
        this.tempId = treeKey;
        this.isUpdate = false;
      } else if (menuKey === "2") {
        this.modalTitle = "编辑区域";
        this.addNewDialog = true;
        this.isUpdate = true;
        const obj = this.originData.find((el) => el.groupId === treeKey);
        this.tempId = obj.id;
        this.$nextTick(() => {
          this.$refs["addNewFormIndex"].queryObj(obj);
        });
      } else if (menuKey === "3") {
        const record = this.originData.find((el) => el.groupId === treeKey);
        this.$confirm({
          title: "确定删除区域吗？",
          onOk() {
            delGroup(record).then((res) => {
              if (res.data.code === 0) {
                _that.$message.success("删除成功！");
                _that.getGroupList();
              } else {
                _that.$message.error(res.data.msg);
              }
            });
          },
        });
      }
    },
    handleAddNewSubmit() {
      this.$refs.addNewFormIndex.combinationSubmit().then((res) => {
        if (this.isUpdate) {
          res.id = this.tempId;
          updateGroup(res).then((el) => {
            if (el.data.code === 0) {
              this.$message.success("编辑成功！");
              this.$refs.addNewFormIndex.resetFields();
              this.addNewDialog = false;
              this.tempId = "";
              this.getGroupList();
            } else {
              this.$message.error(el.data.msg);
            }
          });
        } else {
          res.fatherGroupId = this.tempId;
          // 如果是系统管理员操作
          // if (this.user.nowRoleKey.includes("admin")) {
            res.projectId = this.queryData.projectId
          // }
          addGroup(res).then((el) => {
            if (el.data.code === 0) {
              this.$message.success("新增成功！");
              this.addNewDialog = false;
              this.$refs.addNewFormIndex.resetFields();
              this.tempId = "";
              this.getGroupList();
            } else {
              this.$message.error(el.data.msg);
            }
          });
        }
      });
    },
    onSelect(selectedKeys, info) {
      this.selectedKeys = selectedKeys;
    },
    onCheck(checkedKeys, e) {
      this.checkedKeys = checkedKeys.checked;
      if (!this.projectId || !this.officeId) return false
      let params = {
        groupIds: this.checkedKeys,
        officeId: this.queryData.officeId,
        projectId: this.queryData.projectId,
      };
      addGroupOfficeAccControl(params).then((el) => {
        if (el.data.code === 0) {
          this.$message.success("修改成功！");
          this.addNewDialog = false;
          this.groupId = "";
          this.fatherGroupId = "";
          this.getGroupList();
        } else {
          this.$message.error(el.data.msg);
          this.$message.error("至少设置一个门禁通道");
          this.getGroupList();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.a_Method {
  // background: #fff;
  // padding: 20px;
  // border-radius: 10px;
}
.sTables {
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    width: 100%;
    text-align: center;
    cursor: pointer;
  }

  div:nth-child(1) {
    color: #307dfa;
  }

  div:nth-child(3) {
    color: #ff5c77;
  }

  div:nth-child(2) {
    color: #615e83;
  }
}
</style>
