<template>
    <dev>
        <a-card title="用户列表" :bordered="false">
            <div id="components-form-demo-advanced-search">
                <a-form class="ant-advanced-search-form" :form="searchForm" >
                    <a-row :gutter="24">
                        <!--                        <a-col :span="8" >-->
                        <!--                            <a-form-item :label="`上报时间`">-->
                        <!--                                <a-range-picker @change="onChange" />-->
                        <!--                            </a-form-item>-->
                        <!--                        </a-col>-->
                        <a-col :span="4" >
                            <a-form-item :label="`上报时间`">
                                <a-date-picker  @change="onChange" :default-value="moment(searchForm.queryDate, dateFormat)" />
                            </a-form-item>
                        </a-col>

                        <a-col :span="2" :style="{ textAlign: 'right', marginTop: '3px'}">
                            <a-button type="primary" icon="search" :loading="loading" @click="search">
                                查询
                            </a-button>
                        </a-col>
                    </a-row>
                </a-form>
            </div>
            <a-table
                    :columns="columns"
                    :data-source="data"
            >
            </a-table>
        </a-card>
    </dev>


</template>

<script>
    import {statisticsByOfficeProject} from '@/services/epidemic'
    import {request} from '@/utils/request'
    import {mapGetters} from 'vuex'
    import moment from 'moment'

    const columns = [
        {
            title: '公司',
            dataIndex: 'officeName',
            key: 'officeName',
        },
        {
            title: '项目',
            dataIndex: 'projectName',
            key: 'projectName',
        },
        {
            title: '上报人数',
            dataIndex: 'total',
            key: 'total',
        },
        {
            title: '异常人数',
            dataIndex: 'ycTotal',
            key: 'ycTotal',
        },
    ];

    export default {
        data() {
            return {

                // 筛选
                searchForm: {
                    queryDate: "",
                },
                loading: false,
                dateFormat: 'YYYY-MM-DD',

                // 树形结构
                expandedKeys: [],
                autoExpandParent: true,
                officeTreeData: [],


                // 数据表格
                columns,
                data: [],
                pagination:{
                    current:1,
                    pageSize:10,
                    total:0,
                },
                tableLoading: false,

            };
        },
        computed: {
            ...mapGetters('account', ['user']),
        },
        created() {
            this.initData()
        },
        methods: {
            moment,
            initData() {
                this.searchForm.queryDate = this.getDay(0)
                this.searchForm.pageNumber = 1
                this.pagination.pageNumber = 1
                this.statisticsByOfficeProject()
            },
            onChange(date, dateString) {
                console.log(date, dateString);
                this.searchForm.queryDate = dateString
            },
            search() {
                this.searchForm.pageNumber = 1
                this.pagination.pageNumber = 1
                this.statisticsByOfficeProject()
            },
            onPageChange(e){
                this.searchForm.pageNumber = e.current;
                this.pagination.current=e.current;
                this.statisticsByOfficeProject();
            },
            statisticsByOfficeProject(){
                this.data = []
                this.detailsData = []
                this.loading = true
                this.tableLoading = true
                statisticsByOfficeProject(this.searchForm).then(res=>{
                    this.data = res.data.data.list
                    this.pagination.total = res.data.data.total
                }).finally(()=>{
                    this.loading = false
                    this.tableLoading = false
                })

            },
            exportExcel(){
                // var searchForm = {
                //     officeName: this.searchForm.officeName,
                //     startTime: this.searchForm.startTime,
                //     endTime: this.searchForm.endTime
                // }
                // request(EXPORT_NOATTACH_EXCEL,"post",searchForm,
                //     {
                //         responseType:"blob",
                //         headers:{ 'Content-Type': 'application/json; application/octet-stream'}
                //     }).then(res => {
                //     const content = res.data;
                //     const reader = new FileReader()
                //     var blob = new Blob([content], {type: "application/vnd.ms-excel"})
                //     reader.readAsDataURL(blob)
                //     reader.onload = (e) => {
                //         const a = document.createElement('a')
                //         a.download = "未考勤人员统计.xlsx";
                //         a.href = e.target.result
                //         document.body.appendChild(a)
                //         a.click()
                //         document.body.removeChild(a)
                //     }
                // })
            },
            getDay(day){
                var today = new Date();
                var targetday_milliseconds=today.getTime() + 1000*60*60*24*day;
                today.setTime(targetday_milliseconds); //注意，这行是关键代码
                var tYear = today.getFullYear();
                var tMonth = today.getMonth();
                var tDate = today.getDate();
                tMonth = this.doHandleMonth(tMonth + 1);
                tDate = this.doHandleMonth(tDate);
                return tYear+"-"+tMonth+"-"+tDate;
            },
            doHandleMonth(month){
                var m = month;
                if(month.toString().length == 1){
                    m = "0" + month;
                }
                return m;

            }


        },
    };


</script>

<style scoped>
    .ant-advanced-search-form {
        padding: 24px;
        background: #fbfbfb;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        margin-bottom: 10px;
    }

    .ant-advanced-search-form .ant-form-item {
        display: flex;
    }

    .ant-advanced-search-form .ant-form-item-control-wrapper {
        flex: 1;
    }

    #components-form-demo-advanced-search .ant-form {
        max-width: none;
    }

    tr{
        background-color: #ffffff;
    }

</style>
