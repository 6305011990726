<template>
  <a-card>
    <companyTeams :officeId="user.officeId" />
  </a-card>
</template>

<script>
import companyTeams from "@/pages/phasell/company/library/components/companyTeams";
import { mapGetters } from "vuex";
export default {
  components: { companyTeams },
  computed: {
    ...mapGetters("account", ["user"]),
  },
}
</script>

<style>

</style>