<template>
    <a-card :bordered="false">
        <div id="components-form-demo-advanced-search">
            <a-form class="ant-advanced-search-form" :form="searchForm" >
                <a-row :gutter="24">
                    <a-col :span="8" >
                        <a-form-item :label="`上报时间`">
                            <a-range-picker @change="onChange" :default-value="[moment(searchForm.startTime, dateFormat), moment(searchForm.endTime, dateFormat)]" />
                        </a-form-item>
                    </a-col>

                    <a-col :span="12">
                        <a-form-model-item label="所属公司">
                            <a-tree-select
                                    style="width: 100%"
                                    v-model="searchForm.queryOfficeId"
                                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                    :tree-data="officeTreeData"
                                    placeholder="请选择直属上级单位"
                                    tree-default-expand-all
                                    :replaceFields="{children:'children', title:'name', key:'id', value:'id' }"
                                    :treeDefaultExpandAll="false"
                                    @select="queryOfficeId"
                                    :allowClear="true"
                            >
                            </a-tree-select>
                        </a-form-model-item>
                    </a-col>
                </a-row>

                <a-row :gutter="24">
                    <a-col :span="12" >
                        <a-form-item label="项目名称">
                            <a-select placeholder="请选择" v-model="searchForm.projectId" :allowClear="true">
                                <a-select-option v-for="(item, index) in projectList" :key="item.projId" :index="index" :value="item.projId">{{item.fullName}}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>


<!--                    <a-col :span="4">-->
<!--                        <a-form-item :label="`数据范围`">-->
<!--                            <a-select-->
<!--                                    v-model = "searchForm.dataRange"-->
<!--                                    placeholder="请选择数据范围"-->

<!--                            >-->
<!--                                <a-select-option value="0">-->
<!--                                    所有-->
<!--                                </a-select-option>-->
<!--                                <a-select-option value="1">-->
<!--                                    去重-->
<!--                                </a-select-option>-->
<!--                            </a-select>-->
<!--                        </a-form-item>-->
<!--                    </a-col>-->

                    <a-col :span="4" :style="{ textAlign: 'right', marginTop: '3px'}">
                        <a-button type="primary" icon="search" :loading="loading" @click="search">
                            查询
                        </a-button>
                    </a-col>
                </a-row>

                <a-row>
                    <a-button :style="{ marginLeft: '8px' }" icon="download" :loading="exportLoading" type="primary" @click="exportExcelByVue">
                        导出
                    </a-button>
                </a-row>

            </a-form>
        </div>
        <a-table
                :columns="columns"
                :data-source="data"
                :pagination="pagination"
                @change="onPageChange"
                :loading="tableLoading"
                :scroll="{ x: 3000}"
        >
            <template slot="dayTemp" slot-scope="text, record">
                <span :style="{color: (record.dayTemp<='37.3' ? '' : 'red')}">{{ text }}</span>
            </template>
            <template slot="userName" slot-scope="text, record">
                <span :style="{color: (record.dayTemp<='37.3' && record.color==1 && record.checkLastStatus==1 && record.highRisk==0
                && record.familyStatus==1  ? '' : 'red')}">{{ text }}</span>
            </template>

            <template slot="color" slot-scope="text, record">
                <span :style="{color: (record.color==1 ? '' : 'red')}">{{ text===null||text==undefined? "" : text === 1? "绿码" : (text === 2? "黄码" : "红码") }}</span>
            </template>
            <template slot="checkLastStatus" slot-scope="text, record">
                <span :style="{color: (record.checkLastStatus==1 ? '' : 'red')}">{{ text===null||text==undefined? "" : text === 1? "阴性" : "阳性" }}</span>
            </template>
            <template slot="highRisk" slot-scope="text, record">
                <span :style="{color: (record.highRisk==0 ? '' : 'red')}">{{ text===null||text==undefined? "" : text === 0? "否" : "是," }} {{record.remark}}</span>
            </template>
            <template slot="familyStatus" slot-scope="text, record">
                <span :style="{color: (record.familyStatus==1 ? '' : 'red')}">{{ text===null||text==undefined? "" : text === 1? "无异常" : "其他," }}{{record.familyRemark}}</span>
            </template>

        </a-table>


    </a-card>


</template>

<script>
    import {getOfficeReportRecode, abnormalReportStatistics, getOfficeTree, getProjectListByParentOfficeId, exportOfficeRecode} from '@/services/epidemic'
    import {request} from '@/utils/request'
    import {mapGetters} from 'vuex'
    import moment from 'moment';
    import table2excel from 'js-table2excel'
    import { useRender } from '@/hooks/useRender.js'

    const columns = [
        {
            title: '公司',
            dataIndex: 'officeName',
            key: 'officeName',
            ellipsis: true,
        },
        {
            title: '项目',
            dataIndex: 'projectName',
            key: 'projectName',
            ellipsis: true,
        },
        {
            title: '姓名',
            dataIndex: 'userName',
            key: 'userName',
            slots: { title: 'userName' },
            scopedSlots: { customRender: 'userName' },
        },
        {
            title: '身份证号',
            dataIndex: 'idCard',
            key: 'idCard',
        },
        {
            title: '手机号',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: '体温|℃',
            dataIndex: 'dayTemp',
            key: 'dayTemp',
            slots: { title: 'dayTemp' },
            scopedSlots: { customRender: 'dayTemp' },
        },
        {
            title: '所属区域/社区',
            dataIndex: 'address',
            key: 'address',
            ellipsis: true,
        },
        {
            title: '健康码颜码',
            dataIndex: 'color',
            key: 'color',
            slots: { title: 'color' },
            scopedSlots: { customRender: 'color' },
        },
        {
            title: '是否接种疫苗',
            dataIndex: 'isVaccines',
            key: 'isVaccines',
            customRender: (text) => {
                return text===null||text===undefined? "" : text === 0? "否" : "是"
            }
        },
        // {
        //     title: '第一针接种时间',
        //     dataIndex: 'oneTimeVaccines',
        //     key: 'oneTimeVaccines',
        // },
        // {
        //     title: '第二针接种时间',
        //     dataIndex: 'twoTimeVaccines',
        //     key: 'twoTimeVaccines',
        // },
        // {
        //     title: '第三针接种时间',
        //     dataIndex: 'threeTimeVaccines',
        //     key: 'threeTimeVaccines',
        // },
        // {
        //     title: '最近核酸检测时间',
        //     dataIndex: 'checkLastDate',
        //     key: 'checkLastDate',
        // },
        {
            title: '最近核酸检测结果',
            dataIndex: 'checkLastStatus',
            key: 'checkLastStatus',
            slots: { title: 'checkLastStatus' },
            scopedSlots: { customRender: 'checkLastStatus' },
        },
        {
            title: '有无前往高风险地(情况说明)',
            dataIndex: 'highRisk',
            key: 'highRisk',
            slots: { title: 'highRisk' },
            scopedSlots: { customRender: 'highRisk' },
        },
        // {
        //     title: '情况说明',
        //     dataIndex: 'remark',
        //     key: 'remark',
        // },
        {
            title: '家人或周围人群健康状况(情况说明)',
            dataIndex: 'familyStatus',
            key: 'familyStatus',
            slots: { title: 'familyStatus' },
            scopedSlots: { customRender: 'familyStatus' },
        },
        // {
        //     title: '家庭情况说明',
        //     dataIndex: 'familyRemark',
        //     key: 'familyRemark',
        // },
        // {
        //     title: '是否途径确诊病例轨迹',
        //     dataIndex: 'isChannel',
        //     key: 'isChannel',
        //     customRender: (text) => {
        //         return text === 0? "否" : "是"
        //     }
        // },
        // {
        //     title: '是否接触过中高风险地区旅居人员',
        //     dataIndex: 'isHighPlace',
        //     key: 'isHighPlace',
        //     customRender: (text) => {
        //         return text === 0? "否" : "是"
        //     }
        // },
        // {
        //     title: '月内是否与确诊病例直接接触',
        //     dataIndex: 'isTouch',
        //     key: 'isTouch',
        //     customRender: (text) => {
        //         return text === 0? "否" : "是"
        //     }
        // },
        {
            title: '上报日期',
            dataIndex: 'reportDate',
            key: 'reportDate',
            customRender: (text) => useRender.renderDate(text)
        },
        // {
        //     title: '健康码',
        //     dataIndex: 'image',
        //     key: 'image',
        //     slots: { title: 'userName' },
        //     scopedSlots: { customRender: 'userName' },
        // },
    ];

    export default {
        data() {
            return {

                // 筛选
                searchForm: {
                    queryOfficeId: undefined
                },
                loading: false,

                officeTreeData:[],

                // 数据表格
                columns,
                data: [],
                pagination:{
                    current:1,
                    pageSize:10,
                    total:0,
                },
                tableLoading: false,
                dateFormat: 'YYYY-MM-DD'

            };
        },
        computed: {
            ...mapGetters('account', ['user']),
        },
        created() {
            this.initData()
        },
        methods: {
            moment,
            initData() {

                this.searchForm.endTime = this.getDay(0)
                this.searchForm.startTime = this.getDay(-7)
                if (this.user.enname == "admin"){
                    this.searchForm.queryOfficeId = undefined
                }else{
                    this.searchForm.queryOfficeId = this.user.officeId
                }

                this.searchForm.pageNumber = 1
                this.pagination.pageNumber = 1
                this.getOfficeReportRecode()
                getOfficeTree({parentOfficeId: this.searchForm.queryOfficeId}).then(res => {
                    this.officeTreeData = res.data.data
                })
            },
            onChange(date, dateString) {
                this.searchForm.startTime = dateString[0]
                this.searchForm.endTime = dateString[1]
            },
            search() {
                this.searchForm.pageNumber = 1
                this.pagination.pageNumber = 1
                this.getOfficeReportRecode()
            },
            onPageChange(e){
                this.searchForm.pageNumber = e.current;
                this.pagination.current=e.current;
                this.getOfficeReportRecode();
            },
            getOfficeReportRecode(){
                this.data = []
                this.detailsData = []
                this.loading = true
                this.tableLoading = true
                getOfficeReportRecode(this.searchForm).then(res=>{
                    this.data = res.data.data.list
                    this.pagination.total = res.data.data.total
                }).finally(()=>{
                    this.loading = false
                    this.tableLoading = false
                })

                getProjectListByParentOfficeId({parentOfficeId: this.searchForm.queryOfficeId}).then(res =>{
                    this.projectList = res.data.data
                })
            },
            queryOfficeId(value, node, extra){
                this.searchForm.queryOfficeId = value
                this.getOfficeReportRecode()
            },
            exportExcel(){
                // var searchForm = {
                //     officeName: this.searchForm.officeName,
                //     startTime: this.searchForm.startTime,
                //     endTime: this.searchForm.endTime
                // }
                // request(EXPORT_NOATTACH_EXCEL,"post",searchForm,
                //     {
                //         responseType:"blob",
                //         headers:{ 'Content-Type': 'application/json; application/octet-stream'}
                //     }).then(res => {
                //     const content = res.data;
                //     const reader = new FileReader()
                //     var blob = new Blob([content], {type: "application/vnd.ms-excel"})
                //     reader.readAsDataURL(blob)
                //     reader.onload = (e) => {
                //         const a = document.createElement('a')
                //         a.download = "未考勤人员统计.xlsx";
                //         a.href = e.target.result
                //         document.body.appendChild(a)
                //         a.click()
                //         document.body.removeChild(a)
                //     }
                // })
            },
            getDay(day){
                var today = new Date();
                var targetday_milliseconds=today.getTime() + 1000*60*60*24*day;
                today.setTime(targetday_milliseconds); //注意，这行是关键代码
                var tYear = today.getFullYear();

                var tMonth = today.getMonth();

                var tDate = today.getDate();

                tMonth = this.doHandleMonth(tMonth + 1);

                tDate = this.doHandleMonth(tDate);

                return tYear+"-"+tMonth+"-"+tDate;

            },
            doHandleMonth(month){
                var m = month;
                if(month.toString().length == 1){
                    m = "0" + month;
                }
                return m;

            },
            exportExcelByVue(){
                const column = [
                    {
                        title: '姓名',
                        key: 'userName',
                        type: 'text'
                    },
                    {
                        title: '公司',
                        key: 'officeName',
                        type: 'text'
                    },
                    {
                        title: '身份证号',
                        key: 'idCard',
                        type: 'text',
                    },
                    {
                        title: '手机号',
                        key: 'phone',
                        type: 'text'
                    },
                    {
                        title: '体温|℃',
                        key: 'dayTemp',
                        type: 'text'
                    },
                    {
                        title: '所属区域/社区',
                        key: 'address',
                        type: 'text'
                    },
                    {
                        title: '健康码颜色',
                        key: 'color',
                        type: 'text'
                    },
                    {
                        title: '是否接种疫苗',
                        key: 'isVaccines',
                        type: 'text'
                    },
                    {
                        title: '第一针接种时间',
                        key: 'oneTimeVaccines',
                        type: 'text',
                        customRender: (text) => useRender.renderDate(text)
                    },
                    {
                        title: '第二针接种时间',
                        key: 'twoTimeVaccines',
                        type: 'text',
                        customRender: (text) => useRender.renderDate(text)
                    },
                    {
                        title: '第三针接种时间',
                        key: 'threeTimeVaccines',
                        type: 'text',
                        customRender: (text) => useRender.renderDate(text)
                    },
                    {
                        title: '最近核酸检测时间',
                        key: 'checkLastDate',
                        type: 'text',
                        customRender: (text) => useRender.renderDate(text)
                    },
                    {
                        title: '最近核酸检测结果',
                        key: 'checkLastStatus',
                        type: 'text'
                    },
                    {
                        title: '有无前往高风险地',
                        key: 'highRisk',
                        type: 'text'
                    },
                    {
                        title: '情况说明',
                        key: 'remark',
                        type: 'text'
                    },
                    {
                        title: '家人或周围人群健康状况',
                        key: 'familyStatus',
                        type: 'text'
                    },
                    {
                        title: '家庭情况说明',
                        key: 'familyRemark',
                        type: 'text'
                    },
                    {
                        title: '上报日期',
                        key: 'reportDate',
                        type: 'text',
                        customRender: (text) => useRender.renderDate(text)
                    },
                    {
                        title: '健康码',
                        key: 'image',
                        type: 'image',
                        width: 110,
                        height: 260
                    },
                    {
                        title: '行程码',
                        key: 'travelCode',
                        type: 'image',
                        width: 110,
                        height: 260
                    },
                    {
                        title: '行程卡颜色',
                        key: 'travelCodeColor',
                        type: 'text'
                    },
                    {
                        title: '疫苗接种记录截图',
                        key: 'vaccinationImg',
                        type: 'image',
                        width: 110,
                        height: 260
                    },
                    {
                        title: '核酸检测记录截图',
                        key: 'nucleicAcidImg',
                        type: 'image',
                        width: 110,
                        height: 260
                    },
                    {
                        title: '返岗前居住地',
                        key: 'residenceBeforeReturn',
                        type: 'text'
                    },
                    {
                        title: '返程详细行程',
                        key: 'travelBeforeReturn',
                        type: 'text'
                    },
                    {
                        title: '血型',
                        key: 'bloodType',
                        type: 'text'
                    },
                    {
                        title: '是否隔离',
                        key: 'isolate',
                        type: 'text'
                    },
                    {
                        title: '隔离体温',
                        key: 'isolateTemperature',
                        type: 'text'
                    },
                ]
                this.exportLoading = true;
                exportOfficeRecode(this.searchForm).then((res) => {

                    table2excel(column, res.data.data, "健康上报.xls")
                }).finally(()=>{
                    this.exportLoading = false;
                })
            }



        },
    };


</script>

<style scoped>
    .ant-advanced-search-form {
        padding: 24px;
        background: #fbfbfb;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        margin-bottom: 10px;
    }

    .ant-advanced-search-form .ant-form-item {
        display: flex;
    }

    .ant-advanced-search-form .ant-form-item-control-wrapper {
        flex: 1;
    }

    #components-form-demo-advanced-search .ant-form {
        max-width: none;
    }

    tr{
        background-color: #ffffff;
    }

    .ant-table td { white-space: nowrap; }

</style>
