<template>
    <a-card>
        <a-form-model ref="form" :model="params" :rules="rules" layout="inline" labelAlign="right" 
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            style="width: 60%; margin: 0 auto">
            <a-row>
                <a-col :span="12" class="mb-5 height-5">
                    <a-form-model-item label="工程管理部" prop="departmentid">
                        <a-select v-model="params.departmentid" allowClear placeholder="" style="width: 240px" @change="handleChangeDep">
                            <a-select-option v-for="item in gcglbList" :key="item.value" :value="item.value" :title="item.name">
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="12" class="mb-5 height-5">
                    <a-form-model-item label="项目名称" prop="projectid">
                    <a-auto-complete v-model="params.projectid" style="width: 240px" placeholder="" @search="handleChange" @change="handleChangeSearch">
                        <template slot="dataSource">
                            <a-select-option v-for="d in projectArr" :key="d.projectId" :title="d.projectName">
                                {{ d.projectName }}
                            </a-select-option>
                        </template>
                    </a-auto-complete>
                    </a-form-model-item>
                    <!-- <a-form-model-item label="项目名称" prop="projectid">
                        <a-select show-search :value="params.projectid" placeholder="input search text" style="width: 240px"
                            :default-active-first-option="false" :show-arrow="false" :filter-option="false"
                            :not-found-content="null" @search="handleChange" @change="handleChangeSearch">
                            <a-select-option v-for="d in projectArr" :key="d.projectId">
                                {{ d.projectName }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item> -->
                </a-col>
                <a-col :span="12" class="mb-5 height-5">
                    <a-form-model-item label="检查日期" prop="inspectiondate">
                        <a-date-picker
                        style="width: 240px"
                            v-model="params.inspectiondate"
                            valueFormat="YYYY-MM-DD"
                            placeholder=""
                           
                            />
                    </a-form-model-item>
                </a-col>
                <a-col :span="12" class="mb-5 height-5">
                    <a-form-model-item label="检查时间" prop="inspectiontime">
                        <!-- 选择上午下午 -->
                        <a-select v-model="params.inspectiontime" style="width: 240px">
                            <a-select-option value="1">上午</a-select-option>
                            <a-select-option value="2">下午</a-select-option>
                        </a-select>

                    </a-form-model-item>
                </a-col>
                <a-col :span="12" class="mb-5 height-5">
                    <a-form-model-item label="巡检部门" prop="inspectiondepartmentid">
                        <!-- 选择巡检部门 -->
                        <a-select v-model="params.inspectiondepartmentid" style="width: 240px" mode="multiple">
                            <a-select-option v-for="item in depL" :value="item.value" :key="item.value">{{item.name}}</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="12" class="mb-5 height-5">
                    <a-form-model-item label="是否评分" prop="isscored">
                        <!-- 是否评分 radio组件 -->
                        <a-radio-group v-model="params.isscored" style="width: 240px">
                            <a-radio :value="1">是</a-radio>
                            <a-radio :value="0">否</a-radio>
                        </a-radio-group>

                    </a-form-model-item>
                </a-col>
                <!-- 按钮 返回 保存 居中-->
                <a-col :span="24" class="mb-5" style="text-align: center">
                    <a-button class="mr-4" @click="close">
                        返回
                    </a-button>
                    <a-button :loading ="disabledBtn" class="mr-4" type="primary" @click="handleSubmit">
                        保存
                    </a-button>
                </a-col>
                
                

                
            </a-row>
        </a-form-model>
    </a-card>
</template>

<script>
import { request } from "@/utils/request";
import { QUERY_OFFICE_BY_USER } from "@/services/api";
import { projectMsgGetList } from "@/services/projectManagement";
import {
    monthcheckAdd,
    monthcheckGet,
    monthcheckUpdate,
} from "@/services/monthlyInspectionPlan";
import { mapGetters } from "vuex";
import { getProjectByOffice } from "@/services/statistics";

export default {
    name: "planDetail",
    inject: ["fatherMethod"],
    data() {
        return {
            form: {

            },
            rules: {
                departmentid: [{ required: true, message: "请填写" }], 
                 projectid: [{ required: true, message: "请填写" }], 
                 inspectiondepartmentid: [{ required: true, message: "请填写" }], 
                 inspectiondate: [{ required: true, message: "请填写" }], 
                 inspectiontime: [{ required: true, message: "请填写" }], 
                 isscored: [{ required: true, message: "请填写" }], 
            },
            params: {
                monthcheckid: null,
                "departmentid": "",
                "departmentname": "",
                "projectid":"",
                "projectname":"",
                "inspectiondate":"",
                "inspectiontime":"",
                "inspdeptname":"",
                "inspectiondepartmentid":[],
                "isscored":1
            },
            gcglbList: [],
            projectArr: [],
            labelCol: {
                span: 10,
            },
            wrapperCol: {
                span: 14,
            },
            id: null,
            title: '',
            depL: [
                {name: '安全部',value: 'OFFICE_AQMGLY'},
                {name: '总工办',value: 'OFFICE_ZGB_USER'},
                {name: '工程部',value: 'OFFICE_GC_STAFF'},
                {name: '成本部',value: 'COST_DEPARTMENT'},
                {name: '工会',value: 'LABOR_UNION'},
                {name: '办公室党群部',value: 'DANG_QUN_BU'},
            ],
            placeholderVal: '请输入项目名称',
            disabledBtn: false
        }
    },
    computed: {
        ...mapGetters("account", ["user"]),
    },
    mounted () {
        this.getGcglbList(QUERY_OFFICE_BY_USER);
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
        // if query 发生改变的情况下，需要重新获取数据
        vm.init();
        });
    },
    methods: {
        init() {
            this.id = this.$route.query.id;
            this.params.monthcheckid = this.id;
            if (this.id) {
                this.title = "编辑";
                this.queryDetail();
            } else {
                this.title = "新增";
            }

        },
        queryDetail() {
            monthcheckGet({monthcheckid: this.id}).then(res => {
                if (res.data.code == 200) {
                    let d = res.data.data;
                    this.params = {
                        ...d,
                        inspectiondepartmentid: d.inspectiondepartmentid.split(',')

                    }
                    this.handleChange(d.projectname)
                    

                }
            })
        },
        getGcglbList(path) {
            request(path, "get").then((res) => {
                let data = res.data.data
                const arr = [];
                data.forEach((item) => {
                    arr.push({
                        name: item.master,
                        value: item.id,
                    });
                });
                this.gcglbList = arr;
            });
        },
        handleChange(e) {
            console.log(e)
            getProjectByOffice({gcglbId: this.params.departmentid, projectName: e, "needPmSignPath":true }).then(res => {
                this.projectArr = [];
                
                if (res.data.code === 0) {
                    this.projectArr = res.data.data;
                }
            })
        },
        handleChangeSearch(value) {
            console.log(value);
            this.params.projectid = value;
        },
        handleChangeDep(){
            this.params.projectid = '';
            this.handleChange('')
        },
        close() {
            let path = this.$route.path;
            this.fatherMethod(`${path}`);
        },
        handleSubmit() {
            this.$refs.form.validate(async (valid) => {
                if (valid) {
                    let d = {
                        ...this.params,
                    }
                    d.departmentname = d.departmentid && this.gcglbList.find(item => item.value == d.departmentid).name;
                    d.projectname = this.projectArr.find(item => item.projectId == d.projectid).projectName;
                    d.ratingtype = this.projectArr.find(item => item.projectId == d.projectid).ptype;
                    d.leadername = this.projectArr.find(item => item.projectId == d.projectid).xmjl;
                    d.leaderSign = this.projectArr.find(item => item.projectId == d.projectid).needPmSignPath;
                    d.inspdeptname = d.inspectiondepartmentid.map(item => {
                        return this.depL.find(i => i.value == item).name;
                    }).join(',');
                    d.inspectiondepartmentid = d.inspectiondepartmentid.join(',');
                    this.disabledBtn = true;
                    if (this.id) {
                        // 编辑
                        monthcheckUpdate(d).then(res => {
                            if (res.data.code === 200) {
                                this.$message.success('编辑成功');
                                this.close()
                            } else {
                                this.$message.error(res.data.msg);
                            }
                            this.disabledBtn = false;
                        })
                    } else {
                        // 新增
                        d.reserved1 = this.user.id
                        monthcheckAdd(d).then(res => {
                            if (res.data.code === 200) {
                                this.$message.success('新增成功');
                                this.close()
                            } else {
                                this.$message.error(res.data.msg);
                            }
                            this.disabledBtn = false;
                        })
                    }

                }
            })
        }
        
    },
    
}
</script>

<style lang="less" scoped>
.height-5 {
    height: 60px;
}
</style>