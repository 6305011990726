import {GET_PROJECT_ACTIVATION_QR_CODE} from '@/services/api'
import {request, METHOD} from '@/utils/request'
import {
    applyEss,
    createConsoleLoginUrl, essTemplateDel, essTemplateInsert,
    essTemplateUpdate,
    getEssTemplateList,
    getOfficeEssList,
    officeEssUpdate
} from "./ess";


/**
 * 获取特殊激活码
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getProjectActivationQrCode(param) {
    return request(GET_PROJECT_ACTIVATION_QR_CODE, METHOD.GET)
}



export default {
    getProjectActivationQrCode,
}
