export default {
  namespaced: true,
  state: {
    towerCrane: {}, //塔式起重机
    constructionHoist: {}, //施工升降机
    materialHois: {}, //物料提升机
    portalCrane: {}, //门式起重机
    bridgeCrane: {}, //桥式起重机
  },
  getters: {
    towerCrane: state => {
      return state.towerCrane
    },
    constructionHoist: state => {
      return state.constructionHoist
    },
    materialHois: state => {
      return state.materialHois
    },
    portalCrane: state => {
      return state.portalCrane
    },
    bridgeCrane: state => {
      return state.bridgeCrane
    },
  },
  mutations: {
    setTowerCrane(state, towerCrane) {
      state.towerCrane = towerCrane
    },
    setConstructionHoist(state, constructionHoist) {
      state.constructionHoist = constructionHoist
    },
    setMaterialHois(state, materialHois) {
      state.materialHois = materialHois
    },
    setPortalCrane(state, portalCrane) {
      state.portalCrane = portalCrane
    },
    setBridgeCrane(state, bridgeCrane) {
      state.bridgeCrane = bridgeCrane
    }
  }
}
