<!-- 入场审批 -->
<template>
  <a-card class="a_Approval" :bordered="false ">
    <a-tabs :default-active-key="defaultActiveKey">
      <!-- <a-tab-pane key="1" tab="建委预警">
        <committee />
      </a-tab-pane> -->
      <a-tab-pane key="2" tab="建委预警">
        <platform />
      </a-tab-pane>
    </a-tabs>
  </a-card>
</template>

<script>
import committee from "@/pages/phasell/alertList/components/committee.vue";
import platform from "@/pages/phasell/alertList/components/platform.vue";
export default {
  components: {
    // committee,
    platform
  },
  data() {
    return {
      defaultActiveKey: "2",
    };
  },
  methods: {

  },
};
</script>

<style lang="less" scoped>
.a_Approval {
  border-radius: 10px;
  min-height: 555px;
}
</style>
