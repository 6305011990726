<template>

    <div class="tab-dev">
        <a-tabs default-active-key="1" @change="changeTab">
            <a-tab-pane key="1" tab="发送消息">

                <div id="components-form-demo-advanced-search">
                    <a-form class="ant-advanced-search-form" :form="searchForm" >
                        <a-row :gutter="24">
                            <a-col :span="1" :style="{ textAlign: 'right', marginTop: '3px'}">
                                <a-button type="primary" icon="search" :loading="loading" @click="showAddModel()">
                                    新增
                                </a-button>
                            </a-col>
                        </a-row>


                    </a-form>
                </div>
                <a-table
                        :columns="columns"
                        :data-source="data"
                        :pagination="pagination"
                        @change="onPageChange"
                        :loading="tableLoading"
                >

                    <template slot="action" slot-scope="text, record">
                <span>
                  <a @click="multiplexing(record)">复用发送</a>
                  <a-divider type="vertical" />
                  <a @click="showDetailedTable(record)">明细</a>
                </span>
                    </template>
                </a-table>

                <div>
                    <a-modal :visible="detailedVisible"
                             title="明细"
                             @ok="detailedCancel"
                             @cancel="detailedCancel"
                             width="80%"
                    >
                        <a-table
                                :columns="detailedColumns"
                                :data-source="detailedData"
                                :pagination="detailedPagination"
                                @change="onDetailedPageChange"
                                :loading="detailedTableLoading"
                        >

                        </a-table>
                    </a-modal>
                </div>

                <div>
                    <a-modal
                            :title="title"
                            :visible="visible"
                            :confirm-loading="confirmLoading"
                            @ok="modelOk"
                            @cancel="modelCancel"
                            width="560px"
                    >
                        <a-form-model :model="form"
                                      :label-col="labelCol"
                                      :wrapper-col="wrapperCol"
                                      v-if="visible"
                        >
                            <a-form-model-item label="发起人id" ref="promoterId" prop="promoterId"  required>
                                <a-input v-model="form.promoterId" disabled />
                            </a-form-model-item>

                            <a-form-model-item label="通知方式" ref="mode" prop="mode"  required>
                                <a-radio-group v-model="form.mode">
                                    <a-radio value="1">
                                        小程序内消息
                                    </a-radio>
                                </a-radio-group>
                            </a-form-model-item>


                            <a-form-model-item label="通知类型" ref="type" prop="type"  required>
                                <a-radio-group v-model="form.type">
                                    <a-radio value="999">
                                        其他
                                    </a-radio>
                                </a-radio-group>
                            </a-form-model-item>

                            <a-form-model-item label="接收对象" ref="faceTo" prop="faceTo"  required>
                                <a-radio-group v-model="form.faceTo" @change="faceToChange()">
                                    <a-radio value="1">
                                        工人
                                    </a-radio>
                                    <a-radio value="2">
                                        管理人员
                                    </a-radio>
                                </a-radio-group>
                            </a-form-model-item>

                            <a-form-model-item label="面向业务" ref="serviceType" prop="serviceType"  required>
                                <a-radio-group v-model="form.serviceType" @change="serviceTypeChange">
                                    <template v-for="(item, index) in serviceTypeObj" >
                                        <a-radio :key="index" v-if="(item.faceTo == form.faceTo)"  :value="item.type">
                                            {{item.title}}
                                        </a-radio>
                                    </template>
                                </a-radio-group>
                            </a-form-model-item>


                            <a-form-model-item v-if="showProjectId" label="选择项目" ref="selectProjectId" prop="selectProjectId" required>
                                <a-select
                                        show-search
                                        mode="default"
                                        :size="size"
                                        placeholder="请选择项目"
                                        style="width: 200px"
                                        @search="projectSearch"
                                        :not-found-content="projectGetListParam.fetching ? undefined : null"
                                        :filter-option="false"
                                        @focus="projectFocus"
                                        :value="selectProjectId"
                                        v-model="form.selectProjectId"
                                        @change="selectProjectIdChange"
                                >
                                    <a-spin v-if="projectGetListParam.fetching" slot="notFoundContent" :size="size" />
                                    <a-select-option v-for="(item, index) in projectList" :key="index" :value="item.projId" >
                                        {{ item.fullName }}
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>


                            <a-form-model-item v-if="showTeam"  label="接收对象维度" ref="sendDimension" prop="sendDimension"  required>
                                <a-radio-group v-model="form.sendDimension" :value="form.sendDimension"    @change="sendDimensionChange()">
                                    <a-radio value="1">
                                        以班组发送
                                    </a-radio>
                                    <a-radio value="2">
                                        以个人发送
                                    </a-radio>
                                </a-radio-group>
                            </a-form-model-item>

                            <a-form-model-item v-if="showTeam" label="班组" ref="teamReceiverIdList" prop="teamReceiverIdList" required>
                                <a-select
                                        mode="multiple"
                                        :size="size"
                                        placeholder="请选择消息接收的班组"
                                        style="width: 200px"
                                        @popupScroll="popupScroll"
                                        @search="teamSearch"
                                        @focus="teamSearch"
                                        :not-found-content="teamGetListParam.fetching ? undefined : null"
                                        :filter-option="false"
                                        v-model="form.teamReceiverIdList"
                                        :disabled="form.sendTeamAll || form.sendDimension != 1"
                                >
                                    <a-spin v-if="teamGetListParam.fetching" slot="notFoundContent" :size="size" />
                                    <a-select-option v-for="(item, index) in teamList" :key="index" :value="item.id" >
                                        {{ item.teamName }}
                                    </a-select-option>
                                </a-select>
                                <span style="margin-left: 5px">
                                    <a-checkbox :checked="form.sendTeamAll" @change="sendTeamAllChange" :disabled="form.sendDimension != 1">所有班组</a-checkbox>
                                </span>

                            </a-form-model-item>


                            <a-form-model-item label="接收人" ref="receiverIdList" prop="receiverIdList" required>
                                <a-select
                                        mode="multiple"
                                        :size="size"
                                        placeholder="请选择消息接收人"
                                        style="width: 200px"
                                        @popupScroll="popupScroll"
                                        @search="userSearch"
                                        :not-found-content="userGetListParam.fetching ? undefined : null"
                                        :filter-option="false"
                                        @focus="userFocus"
                                        v-model="form.receiverIdList"
                                        :disabled="sendAll || form.sendDimension != 2"
                                >
                                    <a-spin v-if="userGetListParam.fetching" slot="notFoundContent" :size="size" />
                                    <a-select-option v-for="(item, index) in userList" :key="index" :value="item.id" >
                                        {{ item.name }}
                                    </a-select-option>
                                </a-select>
                                <span style="margin-left: 5px">
                                    <a-checkbox :checked="form.sendAll" @change="sendAllChange" :disabled="form.sendDimension != 2">所有人</a-checkbox>
                                </span>

                            </a-form-model-item>

                            <a-form-model-item label="通知内容" ref="content" prop="content"  required>
                                <a-textarea
                                        v-model="form.content"
                                        placeholder="请输入通知内容"
                                        :auto-size="{ minRows: 3, maxRows: 6 }"
                                />
                            </a-form-model-item>

                        </a-form-model>




                    </a-modal>
                </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="接收消息" force-render>

                <div id="components-form-demo-advanced-search-2">
                    <a-form class="ant-advanced-search-form" :form="searchForm" >


                        <a-col :span="10" >
                            <a-form-item :label="`内容`">
                                <a-input v-model="detailedSearchForm.content" placeholder="请输入消息内容" />
                            </a-form-item>
                        </a-col>

                        <a-row :gutter="24">
                            <a-col :span="1" :style="{ textAlign: 'right', marginTop: '3px'}">
                                <a-button type="primary" icon="search" :loading="loading" @click="showAddModel()">
                                    查询
                                </a-button>
                            </a-col>
                        </a-row>


                    </a-form>
                </div>
                <a-table
                        :columns="detailedColumns"
                        :data-source="detailedData"
                        :pagination="detailedPagination"
                        @change="onDetailedPageChange"
                        :loading="detailedTableLoading"
                >

                    <template slot="action" slot-scope="text, record">
                <span>
                  <a @click="multiplexing(record)">查看</a>
                </span>
                    </template>
                </a-table>
            </a-tab-pane>
        </a-tabs>
    </div>








</template>
<script>
    import {getMySendMessageListBySys, messageNotifyGetList, sysUserGetList, getSysUserListBySysMsg,
        getListBySysMessage, messageNotifyInsert, getProjectListByParentOfficeIdAndProjectName, getTeamListBySysMsg} from '@/services/message'
    import {mapGetters} from 'vuex'
    import debounce from 'lodash/debounce';

    const detailedColumns = [
        {
            title: '发送人',
            dataIndex: 'promoterName',
            key: 'promoterName',
            ellipsis: true,
        },
        {
            title: '接收人',
            dataIndex: 'receiverName',
            key: 'receiverName',
            ellipsis: true,
        },
        // {
        //     title: '接收人微信昵称',
        //     dataIndex: 'nickName',
        //     key: 'nickName',
        // },
        {
            title: '通知内容',
            dataIndex: 'content',
            key: 'content',
            width: 300,
            ellipsis: true,
        },
        {
            title: '通知类型',
            dataIndex: 'typeText',
            key: 'typeText',
        },
        {
            title: '通知方式',
            dataIndex: 'mode',
            key: 'mode',
        },
        {
            title: '面向用户类型',
            dataIndex: 'faceTo',
            key: 'faceTo',
        },
        {
            title: '发送状态',
            dataIndex: 'status',
            key: 'status',
            customRender: (text) => {
                return text === 0? "未发送" : text === 1? "已发送" : text === 2? "已阅读" : "发送失败"
            }
        },
        {
            title: '关联性数据',
            dataIndex: 'paramJson',
            key: 'paramJson',
            width: 200,
            ellipsis: true,
        },
        {
            title: '备注',
            dataIndex: 'remarks',
            key: 'remarks',
            ellipsis: true,

        }];

    const columns = [
        {
            title: '发送人',
            dataIndex: 'promoterName',
            key: 'promoterName',
        },
        // {
        //     title: '接收人微信昵称',
        //     dataIndex: 'nickName',
        //     key: 'nickName',
        // },
        {
            title: '通知内容',
            dataIndex: 'content',
            key: 'content',
            width: 300,
            ellipsis: true,
        },
        {
            title: '通知类型',
            dataIndex: 'typeText',
            key: 'typeText',
        },
        {
            title: '通知方式',
            dataIndex: 'mode',
            key: 'mode',
        },
        {
            title: '总数',
            dataIndex: 'allNum',
            key: 'allNum',
        },
        {
            title: '已送达数',
            dataIndex: 'sendNum',
            key: 'sendNum'
        },
        {
            title: '已阅读数',
            dataIndex: 'readNum',
            key: 'readNum'
        },
        {
            title: '关联性数据',
            dataIndex: 'paramJson',
            key: 'paramJson',
            width: 200,
            ellipsis: true,
        },
        {
            title: '备注',
            dataIndex: 'remarks',
            key: 'remarks',
        },
        {
            title: '操作',
            key: 'action',
            scopedSlots: { customRender: 'action' },
        },
    ];


    export default {
        data() {
            this.lastFetchId = 0;
            this.projectLastFetchId = 0;
            this.userSearch = debounce(this.userSearch, 800);
            this.projectSearch = debounce(this.projectSearch, 800);
            this.teamSearch = debounce(this.teamSearch, 800);
            return {

                // 筛选
                searchForm: {
                    officeName: "",
                    projectName: "",
                },
                loading: false,


                // 数据表格
                columns,
                data: [],
                pagination:{
                    current:1,
                    pageSize:10,
                    total:0,
                },
                tableLoading: false,

                // 数据详情
                labelCol: { span: 6 },
                wrapperCol: { span: 16 },
                size: 'default',
                title: '编辑',
                visible: false,
                confirmLoading: false,
                form: {
                    id: 0,
                    content: "",
                    promoterId: "",
                    receiverId: 0,
                    type: "999",
                    mode: "1",
                    faceTo: "0",
                    status: "1",
                    serviceType: "",
                    paramJson: "",
                    remarks: "",
                    receiverIdList: [],
                    officeId :"",
                    projectId :"",
                    selectProjectId: "",
                    sendAll: false,
                    sendDimension: 2,
                    sendTeamAll: false,
                    teamReceiverIdList:[],
                },
                sendAll: false,
                serviceTypeObj: [
                    {
                        type: "0",
                        title: "劳务工人",
                        faceTo: "1",
                        showTeam: true,
                    },
                    {
                        type: "1",
                        title: "劳务管理",
                        faceTo: "2",
                        showTeam: false,
                    },
                    {
                        type: "2",
                        title: "找工用工工人",
                        faceTo: "1",
                        showTeam: false,
                    },
                    {
                        type: "3",
                        title: "找工用工管理",
                        faceTo: "2",
                        showTeam: false,
                    },
                ],
                userGetListParam:{
                    loading: false,
                    pageNumber: 1,
                    pageSize: 30,
                    fetching: false,
                },
                userList: [],

                sendTeam: false,
                teamGetListParam:{
                    loading: false,
                    fetching: false,
                    teamName: "",
                },
                teamList: [],
                showTeam: false,

                projectGetListParam: {

                },
                projectList: [],
                selectProjectId: "",
                showProjectId: false,


                // 数据表格

                // 筛选
                detailedSearchForm: {
                    officeName: "",
                    projectName: "",
                },
                detailedLoading: false,
                detailedVisible: false,
                detailedColumns,
                detailedData: [],
                detailedPagination:{
                    current:1,
                    pageSize:10,
                    total:0,
                },
                detailedTableLoading: false,

            };
        },
        computed: {
            ...mapGetters('account', ['user']),
        },
        created() {

            this.initData()
        },
        methods: {
            initData() {
                this.showProjectId = !(this.user.projectId !=undefined && this.user.projectId != "" && this.user.projectId != null)
                this.searchForm.pageNumber = 1
                this.pagination.pageNumber = 1
                this.getMySendMessageListBySys()
            },
            search() {
                this.initData()
            },
            userSearch(value){
                if (this.form.faceTo == "1"){
                    this.staffSearch(value)
                }else if (this.form.faceTo == "2"){
                    this.sysUserSearch(value)
                }
            },
            userFocus(){
                this.userGetListParam.name = ""
                this.userGetListParam.pageNumber = 1
                this.userList = []
                this.getUserList();
            },
            getUserList(){
                if (this.form.faceTo == "1"){
                    this.getListBySysMessage();
                }else if (this.form.faceTo == "2"){
                    this.sysUserGetList();
                }
            },

            sysUserSearch(value){
                this.userGetListParam.name = value
                this.userGetListParam.pageNumber = 1
                if (this.userGetListParam.loading){
                    return
                }
                this.lastFetchId += 1;
                const fetchId = this.lastFetchId;
                this.userGetListParam.fetching = true;
                this.userGetListParam.loading = true
                getSysUserListBySysMsg(this.userGetListParam).then(res=>{
                    if (fetchId !== this.lastFetchId) {
                        // for fetch callback order
                        return;
                    }
                    this.userList = res.data.data
                    this.userList = [...new Set(this.userList)]
                }).finally(()=>{
                    this.userGetListParam.loading = false
                    this.userGetListParam.fetching = false
                })

            },

            sysUserGetList(){
                if (this.userGetListParam.loading){
                    return
                }
                this.userGetListParam.name = ""
                this.userGetListParam.loading = true
                // this.userGetListParam.officeId = this.user.officeId
                getSysUserListBySysMsg(this.userGetListParam).then(res=>{
                    this.userList = this.userList.concat(res.data.data)
                    this.userList = [...new Set(this.userList)]
                }).finally(()=>{
                    this.userGetListParam.loading = false
                })
            },

            staffSearch(value){
                this.userGetListParam.name = value
                this.userGetListParam.pageNumber = 1
                if (this.userGetListParam.loading){
                    return
                }
                if (this.showProjectId && (this.selectProjectId == "" || this.selectProjectId == undefined)){
                    return;
                }
                this.userGetListParam.projectId = this.selectProjectId
                this.lastFetchId += 1;
                const fetchId = this.lastFetchId;
                this.userGetListParam.fetching = true;
                this.userGetListParam.loading = true
                getListBySysMessage(this.userGetListParam).then(res=>{
                    if (fetchId !== this.lastFetchId) {
                        // for fetch callback order
                        return;
                    }
                    this.userList = res.data.data
                    this.userList = [...new Set(this.userList)]
                }).finally(()=>{
                    this.userGetListParam.loading = false
                    this.userGetListParam.fetching = false
                })

            },
            getListBySysMessage(){
                if (this.userGetListParam.loading){
                    return
                }
                if (this.showProjectId && (this.selectProjectId == "" || this.selectProjectId == undefined)){
                    return;
                }
                this.userGetListParam.projectId = this.selectProjectId
                this.userGetListParam.name = ""
                this.userGetListParam.loading = true
                getListBySysMessage(this.userGetListParam).then(res=>{
                    this.userList = this.userList.concat(res.data.data)
                    this.userList = [...new Set(this.userList)]
                }).finally(()=>{
                    this.userGetListParam.loading = false
                })
            },
            getMySendMessageListBySys(){
                this.data = []
                this.loading = true
                this.tableLoading = true
                getMySendMessageListBySys(this.searchForm).then(res=>{
                    this.data = res.data.data.list
                    this.pagination.total = res.data.data.total
                }).finally(()=>{
                    this.loading = false
                    this.tableLoading = false
                })
            },
            messageNotifyGetList(){
                this.detailedData = []
                this.detailedLoading = true
                this.detailedTableLoading = true
                console.log("bbbb::", this.detailedSearchForm)
                messageNotifyGetList(this.detailedSearchForm).then(res=>{
                    this.detailedData = res.data.data.list
                    this.detailedPagination.total = res.data.data.total
                }).finally(()=>{
                    this.detailedLoading = false
                    this.detailedTableLoading = false
                })
            },
            onPageChange(e){
                this.searchForm.pageNumber = e.current;
                this.pagination.current=e.current;
                this.getMySendMessageListBySys();
            },
            onDetailedPageChange(e){
                this.detailedSearchForm.pageNumber = e.current;
                this.detailedSearchForm.pageSize = this.detailedPagination.pageSize;
                this.detailedPagination.current=e.current;
                this.messageNotifyGetList();
            },
            showDetailedTable(item){
                this.detailedVisible = true
                this.detailedSearchForm={
                    pageNumber: 1,
                    pageSize: 10,
                    current: 1,
                    batchId: item.batchId
                }

                this.detailedPagination.current = 1;
                this.messageNotifyGetList();
            },
            messageNotifyInsert(){
                this.confirmLoading = true
                messageNotifyInsert(this.form).then(res=>{
                    this.$message.info(res.data.msg);
                    this.initData();
                }).finally(()=>{
                    this.confirmLoading = false
                })
            },
            showAddModel(form){
                this.visible = true
                let promoterId = this.user.id
                let officeId = this.user.officeId
                let projectId = this.user.projectId
                this.form = form? form : {
                    id: 0,
                    content: "",
                    promoterId: promoterId,
                    receiverId: 0,
                    type: "999",
                    mode: "1",
                    faceTo: "0",
                    status: "1",
                    serviceType: "",
                    paramJson: "",
                    remarks: "",
                    receiverIdList: [],
                    projectId: projectId,
                    officeId: officeId,
                    sendAll: false,
                    sendDimension: 2,
                    sendTeamAll: false,
                    teamReceiverIdList: []
                }
                console.log(this.visible)
            },
            closeAddModel(){
                this.visible = false
            },
            multiplexing(record){
                let form = JSON.parse(JSON.stringify(record))
                form.id = 0;
                form.type = record.type+"";
                form.mode = record.mode+"";
                form.faceTo = record.faceTo+"";
                form.status = record.status+"";
                form.serviceType = record.serviceType+"";
                form.receiverIdList = []
                form.promoterId = this.user.id
                form.paramJson = ""
                this.showAddModel(record);
            },
            detailedCancel(){
                this.detailedVisible = false;
            },
            modelOk(){
                this.messageNotifyInsert()
                this.closeAddModel()
            },
            modelCancel(){
                this.closeAddModel()
            },
            faceToChange(){
                if (this.form.faceTo == 1){
                    this.showProjectId = !(this.user.projectId !=undefined && this.user.projectId != "" && this.user.projectId != null)
                }else {
                    this.showProjectId = false
                }
                this.form.serviceType =""
                this.userList = [];
                this.userGetListParam.pageNumber = 1
                console.log(this.form.faceTo)
                this.getUserList();
                this.form.receiverIdList = [];



                if(this.form.faceTo ==2){
                    // 切换为管理员的时候，清空班组相关的东西
                    let e = {
                        target: {
                            checked: false
                        }
                    }
                    this.sendTeamAllChange(e)
                    this.showTeam = false
                }else {
                    this.form.sendDimension=2
                    this.showTeam = true
                }

            },
            popupScroll(e){
                const { target } = e;
                if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
                    this.userGetListParam.pageNumber = this.userGetListParam.pageNumber + 1
                    this.getUserList();
                }
            },
            selectProjectIdChange(e){
                this.selectProjectId = e
            },
            projectSearch(value){
                this.projectGetListParam.projectName = value
                this.projectGetListParam.officeId = this.user.officeId
                if (this.projectGetListParam.loading){
                    return
                }
                this.projectLastFetchId += 1;
                const fetchId = this.projectLastFetchId;
                this.projectGetListParam.fetching = true;
                this.projectGetListParam.loading = true
                getProjectListByParentOfficeIdAndProjectName(this.projectGetListParam).then(res=>{
                    if (fetchId !== this.projectLastFetchId) {
                        // for fetch callback order
                        return;
                    }
                    this.projectList = res.data.data
                    this.projectList = [...new Set(this.projectList)]
                }).finally(()=>{
                    this.projectGetListParam.loading = false
                    this.projectGetListParam.fetching = false
                })
            },
            projectFocus(){
                this.projectGetListParam.projectName = ""
                this.projectGetListParam.officeId = this.user.officeId
                this.projectList = []
                this.getProjectList();
            },
            getProjectList(){
                getProjectListByParentOfficeIdAndProjectName(this.projectGetListParam).then(res=>{
                    this.projectList = res.data.data
                }).finally(()=>{

                })
            },
            changeTab(e){
                if (e == 2){
                    this.detailedSearchForm={
                        pageNumber: 1,
                        pageSize: 10,
                        current: 1,
                        faceTo: 2,
                        receiverId: this.user.id
                    }
                    this.messageNotifyGetList();
                }
            },
            sendAllChange(e){
                this.form.sendAll = e.target.checked
                this.sendAll = this.form.sendAll
                this.form.receiverIdList = []
                console.log(this.sendAll)
            },
            sendTeamAllChange(e){
                this.form.sendTeamAll = e.target.checked
                this.sendTeamAll = this.form.sendTeamAll
                this.form.teamReceiverIdList = []
            },
            teamSearch(value){
                if (this.teamGetListParam.loading){
                    return
                }
                if (this.showProjectId && (this.selectProjectId == "" || this.selectProjectId == undefined)){
                    return;
                }
                this.lastFetchId += 1;
                const fetchId = this.lastFetchId;
                this.teamGetListParam.projectId = this.selectProjectId
                this.teamGetListParam.loading = true
                this.teamGetListParam.teamName = value? value : ""
                getTeamListBySysMsg(this.teamGetListParam).then(res=>{
                    if (fetchId !== this.lastFetchId) {
                        // for fetch callback order
                        return;
                    }
                    this.teamList = res.data.data
                }).finally(()=>{
                    this.teamGetListParam.loading = false

                })
            },
            sendDimensionChange(){
                console.log(this.form.sendDimension)
                let e = {
                    target: {
                        checked: false
                    }
                }
                if (this.form.sendDimension == 1){
                    this.sendAllChange(e)
                    this.teamSearch("")
                }
                if (this.form.sendDimension == 2){
                    this.sendTeamAllChange(e)
                }

            },
            serviceTypeChange(e){
                this.form.serviceType = e.target.value
                const serviceType = this.serviceTypeObj.find((iteam)=>iteam.type == e.target.value)
                if (serviceType.showTeam){
                    let e = {
                        target: {
                            checked: false
                        }
                    }
                    this.sendTeamAllChange(e)
                    this.form.sendDimension=2
                    this.showTeam = true
                }else{
                    let e = {
                        target: {
                            checked: false
                        }
                    }
                    this.sendTeamAllChange(e)
                    this.showTeam = false
                }
            }




        },
    };

</script>
<style scoped>
    .ant-advanced-search-form {
        padding: 24px;
        background: #fbfbfb;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        margin-bottom: 10px;
    }

    .ant-advanced-search-form .ant-form-item {
        display: flex;
    }

    .ant-advanced-search-form .ant-form-item-control-wrapper {
        flex: 1;
    }

    #components-form-demo-advanced-search .ant-form {
        max-width: none;
    }

    tr{
        background-color: #ffffff;
    }

    .tab-dev{
        background-color: #fff;
    }

</style>
