import userService from './user'
import dataSource from './dataSource'
const ADMIN = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_PROXY_PREFIX : process.env.VUE_APP_API_BASE_URL
// const ADMIN = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_ADMIN : process.env.VUE_APP_API_PROXY_PREFIX

export {
  userService,
  dataSource,
  ADMIN
}
