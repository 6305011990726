var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c("a-col", { attrs: { span: 15 } }, [
            _c("div", { staticClass: "public" }, [
              _c("div", { staticClass: "flex" }, [
                _c("div", { staticClass: "relative" }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/img/icon-jw-warning.png"),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "warning-content white" }, [
                    _c("p", { staticClass: "font-bold-700" }, [
                      _vm._v("建委预警"),
                    ]),
                    _c("p", { staticClass: "font-bold-700 font-30" }, [
                      _vm._v("6"),
                    ]),
                    _c(
                      "p",
                      { on: { click: _vm.toWarning } },
                      [
                        _vm._v("去处理"),
                        _c("a-icon", { attrs: { type: "right" } }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "ml-2 w-100 tb-box" }, [
                  _c("table", { staticClass: "w-100" }, [
                    _c("thead", { attrs: { align: "center" } }, [
                      _c("tr", [
                        _c("th", [_vm._v("序号")]),
                        _c("th", [_vm._v("预警内容")]),
                        _c("th", [_vm._v("预警时间")]),
                        _c("th", [_vm._v("处理时限")]),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      { attrs: { align: "center" } },
                      _vm._l(10, function (item, index) {
                        return _c("tr", { key: index }, [
                          _c("td", [
                            _c("span", { staticClass: "table-num" }, [
                              _vm._v("0" + _vm._s(index + 1)),
                            ]),
                          ]),
                          _c("td", [
                            _c(
                              "span",
                              { staticClass: "overtext-1 table-content" },
                              [
                                _vm._v(
                                  "(SY2)考勤不完整考勤不完整考勤不完整考勤不完整"
                                ),
                              ]
                            ),
                          ]),
                          _c("td", [_vm._v("2023.05.19")]),
                          _c("td", [
                            _c(
                              "span",
                              { staticClass: "overtext-1 table-suggest" },
                              [_vm._v("剩余1天")]
                            ),
                          ]),
                        ])
                      }),
                      0
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "flex mt-3" }, [
                _c("div", { staticClass: "relative" }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/img/icon-plantform-warning.png"),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "warning-content" }, [
                    _c("p", { staticClass: "font-bold-700" }, [
                      _vm._v("平台预警"),
                    ]),
                    _c("p", { staticClass: "font-bold-700 font-30" }, [
                      _vm._v("6"),
                    ]),
                    _c(
                      "p",
                      { staticStyle: { color: "#083AF0" } },
                      [
                        _vm._v("去处理"),
                        _c("a-icon", { attrs: { type: "right" } }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "ml-2 w-100 tb-box" }, [
                  _c("table", { staticClass: "w-100" }, [
                    _c("thead", { attrs: { align: "center" } }, [
                      _c("tr", [
                        _c("th", [_vm._v("序号")]),
                        _c("th", [_vm._v("预警内容")]),
                        _c("th", [_vm._v("预警时间")]),
                        _c("th", [_vm._v("处理建议")]),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      { attrs: { align: "center" } },
                      _vm._l(3, function (item, index) {
                        return _c("tr", { key: index }, [
                          _c("td", [
                            _c("span", { staticClass: "table-num" }, [
                              _vm._v("0" + _vm._s(index + 1)),
                            ]),
                          ]),
                          _c("td", [
                            _c(
                              "span",
                              { staticClass: "overtext-1 table-content" },
                              [
                                _vm._v(
                                  "工人与建委在场状态不一致工人与建委在场状态不一致工人与建委在场状态不一致"
                                ),
                              ]
                            ),
                          ]),
                          _c("td", [_vm._v("2023.05.19")]),
                          _c("td", [
                            _c(
                              "span",
                              { staticClass: "overtext-1 table-suggest" },
                              [_vm._v("请按照要求上传建委资料")]
                            ),
                          ]),
                        ])
                      }),
                      0
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("a-col", { attrs: { span: 9 } }, [
            _c(
              "div",
              [
                _c(
                  "a-row",
                  { attrs: { gutter: [16, 16] } },
                  [
                    _c("a-col", { attrs: { span: 24 } }, [
                      _c("div", { staticClass: "public" }, [
                        _c("div", { staticClass: "flex" }, [
                          _c("div", { staticClass: "flex-1" }, [
                            _c(
                              "span",
                              {
                                staticClass: "pointer",
                                class:
                                  _vm.activedKey === 1
                                    ? "black"
                                    : "black-three",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleChangeTitle(1)
                                  },
                                },
                              },
                              [_vm._v("通知公告")]
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "ml-2 pointer",
                                class:
                                  _vm.activedKey === 2
                                    ? "black"
                                    : "black-three",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleChangeTitle(2)
                                  },
                                },
                              },
                              [_vm._v("我的消息")]
                            ),
                          ]),
                          _c(
                            "span",
                            {
                              staticClass: "black pointer",
                              attrs: { span: "" },
                            },
                            [
                              _vm._v("更多"),
                              _c("a-icon", { attrs: { type: "right" } }),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "mt-2" },
                          _vm._l(3, function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "mt-1 flex black" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "overtext-1 flex-1 mr-5" },
                                  [
                                    _vm._v(
                                      "广州市建筑业管理服务中心关于建广州市建筑业管理服务中心关于建"
                                    ),
                                  ]
                                ),
                                _c("span", { staticClass: "black-three" }, [
                                  _vm._v("2023.04.12 18:00"),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]),
                    _c("a-col", { attrs: { span: 24 } }, [
                      _c("div", { staticClass: "public" }, [
                        _c("div", { staticClass: "flex" }, [
                          _c("div", { staticClass: "flex-1" }, [
                            _c(
                              "span",
                              { staticClass: "pointer font-16 black" },
                              [_vm._v("工资发放数据")]
                            ),
                          ]),
                          _c(
                            "span",
                            {
                              staticClass: "black pointer",
                              attrs: { span: "" },
                            },
                            [
                              _vm._v("更多"),
                              _c("a-icon", { attrs: { type: "right" } }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "mt-1 flex" }, [
                          _c("div", { staticClass: "flex-1 text-center" }, [
                            _c("p", { staticClass: "black-three" }, [
                              _vm._v("已发放金额(元)"),
                            ]),
                            _c("p", { staticClass: "black salary-content" }, [
                              _vm._v("450,000"),
                            ]),
                          ]),
                          _c("div", { staticClass: "flex-1 text-center" }, [
                            _c("p", { staticClass: "black-three" }, [
                              _vm._v("建委考勤人数"),
                            ]),
                            _c("p", { staticClass: "black salary-content" }, [
                              _vm._v("45"),
                            ]),
                          ]),
                          _c("div", { staticClass: "flex-1 text-center" }, [
                            _c("p", { staticClass: "black-three" }, [
                              _vm._v("专户已发放人数"),
                            ]),
                            _c("p", { staticClass: "black salary-content" }, [
                              _vm._v("45"),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c("a-col", { attrs: { span: 5 } }, [
            _c(
              "div",
              { staticClass: "public" },
              [
                _c("h3", { staticClass: "font-16 black" }, [
                  _vm._v("管理人员数据"),
                ]),
                _c(
                  "a-row",
                  { staticClass: "mt-1 p-1" },
                  [
                    _c("a-col", { attrs: { span: 12 } }, [
                      _c("h4", { staticClass: "black-three" }, [
                        _vm._v("登记人员"),
                      ]),
                      _c("p", { staticClass: "font-24 black" }, [_vm._v("58")]),
                    ]),
                    _c("a-col", { attrs: { span: 12 } }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/img/icon-line-chart.png"),
                          alt: "",
                        },
                      }),
                    ]),
                  ],
                  1
                ),
                _c(
                  "a-row",
                  { staticClass: "mt-1 p-1" },
                  [
                    _c("a-col", { attrs: { span: 12 } }, [
                      _c("h4", { staticClass: "black-three" }, [
                        _vm._v("今日出勤人数"),
                      ]),
                      _c("p", { staticClass: "font-24 black" }, [_vm._v("52")]),
                      _c("span", { staticClass: "green" }, [_vm._v("+21.01%")]),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/img/arrow-up.png"),
                          alt: "",
                        },
                      }),
                    ]),
                    _c("a-col", { attrs: { span: 12 } }, [
                      _c("h4", { staticClass: "black-three" }, [
                        _vm._v("出勤率"),
                      ]),
                      _c("p", { staticClass: "font-24 black" }, [
                        _vm._v("96%"),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("a-col", { attrs: { span: 5 } }, [
            _c(
              "div",
              { staticClass: "public" },
              [
                _c("h3", { staticClass: "font-16 black" }, [
                  _vm._v("工人数据"),
                ]),
                _c(
                  "a-row",
                  { staticClass: "mt-1 p-1" },
                  [
                    _c("a-col", { attrs: { span: 12 } }, [
                      _c("h4", { staticClass: "black-three" }, [
                        _vm._v("累计登记人数"),
                      ]),
                      _c("p", { staticClass: "font-24 black" }, [
                        _vm._v("230"),
                      ]),
                    ]),
                    _c("a-col", { attrs: { span: 12 } }, [
                      _c("h4", { staticClass: "black-three" }, [
                        _vm._v("在场人数"),
                      ]),
                      _c("p", { staticClass: "font-24 black" }, [
                        _vm._v("158"),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "a-row",
                  { staticClass: "mt-1 p-1" },
                  [
                    _c("a-col", { attrs: { span: 12 } }, [
                      _c("h4", { staticClass: "black-three" }, [
                        _vm._v("今日出勤人数"),
                      ]),
                      _c("p", { staticClass: "font-24 black" }, [_vm._v("52")]),
                      _c("span", { staticClass: "green" }, [_vm._v("+21.01%")]),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/img/arrow-up.png"),
                          alt: "",
                        },
                      }),
                    ]),
                    _c("a-col", { attrs: { span: 12 } }, [
                      _c("h4", { staticClass: "black-three" }, [
                        _vm._v("出勤率"),
                      ]),
                      _c("p", { staticClass: "font-24 black" }, [
                        _vm._v("96%"),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("a-col", { attrs: { span: 5 } }, [
            _c(
              "div",
              { staticClass: "public" },
              [
                _c("h3", { staticClass: "font-16 black" }, [
                  _vm._v("班组数据"),
                ]),
                _c(
                  "a-row",
                  { staticClass: "mt-1 p-1" },
                  [
                    _c("a-col", { attrs: { span: 12 } }, [
                      _c("h4", { staticClass: "black-three" }, [
                        _vm._v("累计登记班组"),
                      ]),
                      _c("p", { staticClass: "font-24 black" }, [
                        _vm._v("230"),
                      ]),
                    ]),
                    _c("a-col", { attrs: { span: 12 } }, [
                      _c("h4", { staticClass: "black-three" }, [
                        _vm._v("在场班组数"),
                      ]),
                      _c("p", { staticClass: "font-24 black" }, [
                        _vm._v("158"),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "a-row",
                  { staticClass: "mt-1 p-1" },
                  [
                    _c("a-col", { attrs: { span: 12 } }, [
                      _c("h4", { staticClass: "black-three" }, [
                        _vm._v("今日出勤班组数"),
                      ]),
                      _c("p", { staticClass: "font-24 black" }, [_vm._v("52")]),
                      _c("span", { staticClass: "red" }, [_vm._v("+21.01%")]),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/img/arrow-down.png"),
                          alt: "",
                        },
                      }),
                    ]),
                    _c("a-col", { attrs: { span: 12 } }, [
                      _c("h4", { staticClass: "black-three" }, [
                        _vm._v("出勤率"),
                      ]),
                      _c("p", { staticClass: "font-24 black" }, [
                        _vm._v("96%"),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("a-col", { attrs: { span: 9 } }, [
            _c("div", { staticClass: "public" }, [
              _c("h3", { staticClass: "font-16 black" }, [_vm._v("考勤设备")]),
              _c("div", { staticClass: "flex j-around" }, [
                _c("div", [
                  _c("span", { staticClass: "black-three" }, [
                    _vm._v("考勤设备数"),
                  ]),
                  _c("span", { staticClass: "black font-24 ml-2" }, [
                    _vm._v("52"),
                  ]),
                ]),
                _c("div", [
                  _c("span", { staticClass: "black-three" }, [_vm._v("离线")]),
                  _c("span", { staticClass: "black font-24 ml-2" }, [
                    _vm._v("2"),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "w-100 tb-box-2 mt-1" }, [
                _c("table", { staticClass: "w-100" }, [
                  _c("thead", { attrs: { align: "center" } }, [
                    _c("tr", [
                      _c("th", [_vm._v("序号")]),
                      _c("th", [_vm._v("区域")]),
                      _c("th", [_vm._v("设备名称")]),
                      _c("th", [_vm._v("状态")]),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    { attrs: { align: "center" } },
                    _vm._l(10, function (item, index) {
                      return _c("tr", { key: index }, [
                        _c("td", [
                          _c("span", { staticClass: "table-num" }, [
                            _vm._v("0" + _vm._s(index + 1)),
                          ]),
                        ]),
                        _c("td", [
                          _c(
                            "span",
                            { staticClass: "overtext-1 table-content" },
                            [_vm._v("工作区工作区工作区工作区")]
                          ),
                        ]),
                        _c("td", [_vm._v("现场（出3）")]),
                        _c("td", [
                          _c(
                            "span",
                            { staticClass: "overtext-1 table-suggest" },
                            [_vm._v("离线")]
                          ),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c("a-col", { attrs: { span: 15 } }, [
            _c("div", { staticClass: "public" }, [
              _c("div", { staticClass: "flex flex-column" }, [
                _c("div", { staticClass: "flex-1" }, [
                  _c(
                    "span",
                    {
                      staticClass: "pointer font-16",
                      class: _vm.activedKey === 1 ? "black" : "black-three",
                      on: {
                        click: function ($event) {
                          return _vm.handleChangeTitle(1)
                        },
                      },
                    },
                    [_vm._v("实时在场人数")]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "ml-2 pointer font-16",
                      class: _vm.activedKey === 2 ? "black" : "black-three",
                      on: {
                        click: function ($event) {
                          return _vm.handleChangeTitle(2)
                        },
                      },
                    },
                    [_vm._v("近7日出勤数据")]
                  ),
                ]),
                _c("div", {
                  ref: "peopleChart",
                  staticStyle: { width: "100%", height: "400px" },
                  attrs: { id: "main" },
                }),
              ]),
            ]),
          ]),
          _c("a-col", { attrs: { span: 9 } }, [
            _c("div", { staticClass: "public" }, [
              _c("h3", { staticClass: "font-16 black" }, [
                _vm._v("班组考勤情况"),
              ]),
              _c(
                "div",
                { staticClass: "p-2", staticStyle: { height: "392px" } },
                [
                  _vm._l(5, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "mt-2" },
                      [
                        _c("div", { staticClass: "flex j-between" }, [
                          _c("span", [_vm._v("周子艺杂工班组(96/100)")]),
                          _c("span", [_vm._v("96%")]),
                        ]),
                        _c("a-progress", {
                          attrs: {
                            percent: 96,
                            "show-info": false,
                            strokeWidth: 12,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  _c("div", { staticClass: "mt-2 flex j-between" }, [
                    _c("span", { staticStyle: { color: "#615E83" } }, [
                      _vm._v("0"),
                    ]),
                    _c("span", { staticStyle: { color: "#615E83" } }, [
                      _vm._v("25%"),
                    ]),
                    _c("span", { staticStyle: { color: "#615E83" } }, [
                      _vm._v("50%"),
                    ]),
                    _c("span", { staticStyle: { color: "#615E83" } }, [
                      _vm._v("75%"),
                    ]),
                    _c("span", { staticStyle: { color: "#615E83" } }, [
                      _vm._v("100%"),
                    ]),
                  ]),
                ],
                2
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c("a-col", { attrs: { span: 10 } }, [
            _c("div", { staticClass: "public flex" }, [
              _c(
                "div",
                { staticClass: "w-40" },
                [
                  _c("h3", { staticClass: "font-16 black" }, [
                    _vm._v("环境监测"),
                  ]),
                  _c(
                    "a-row",
                    { staticClass: "mt-1 p-1" },
                    [
                      _c("a-col", { attrs: { span: 12 } }, [
                        _c("h4", { staticClass: "black-three" }, [
                          _vm._v("AQI"),
                        ]),
                        _c("p", { staticClass: "font-24 black" }, [
                          _vm._v("55"),
                        ]),
                      ]),
                      _c("a-col", { attrs: { span: 12 } }, [
                        _c("h4", { staticClass: "black-three" }, [
                          _vm._v("噪声"),
                        ]),
                        _c("p", { staticClass: "font-24 black" }, [
                          _vm._v(">0.0dB"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    { staticClass: "mt-1 p-1" },
                    [
                      _c("a-col", { attrs: { span: 12 } }, [
                        _c("h4", { staticClass: "black-three" }, [
                          _vm._v("气温"),
                        ]),
                        _c("p", { staticClass: "font-24 black" }, [
                          _vm._v("158℃"),
                        ]),
                      ]),
                      _c("a-col", { attrs: { span: 12 } }, [
                        _c("h4", { staticClass: "black-three" }, [
                          _vm._v("湿度"),
                        ]),
                        _c("p", { staticClass: "font-24 black" }, [
                          _vm._v("96%"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "w-60" }, [
                _c("div", {
                  ref: "environmentChart",
                  staticStyle: { width: "100%", height: "100%" },
                  attrs: { id: "main" },
                }),
              ]),
            ]),
          ]),
          _c("a-col", { attrs: { span: 5 } }, [
            _c("div", { staticClass: "public" }, [
              _c("h3", { staticClass: "font-16 black" }, [_vm._v("安全监测")]),
              _c("div", { staticClass: "flex j-around" }, [
                _c("div", [
                  _c("p", { staticClass: "black-three" }, [
                    _vm._v("未带安全帽"),
                  ]),
                  _c("span", { staticClass: "black font-24 ml-2" }, [
                    _vm._v("52"),
                  ]),
                ]),
                _c("div", [
                  _c("p", { staticClass: "black-three" }, [
                    _vm._v("未穿反光衣"),
                  ]),
                  _c("span", { staticClass: "black font-24 ml-2" }, [
                    _vm._v("2"),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "w-100 mt-1" }, [
                _c("table", { staticClass: "w-100" }, [
                  _c("thead", { attrs: { align: "center" } }, [
                    _c("tr", [
                      _c("th", [_vm._v("名称")]),
                      _c("th", [_vm._v("设备数量")]),
                      _c("th", [_vm._v("预警数量")]),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    { attrs: { align: "center" } },
                    _vm._l(3, function (item, index) {
                      return _c("tr", { key: index }, [
                        _c("td", [
                          _c("span", { staticClass: "overtext-1" }, [
                            _vm._v("塔吊监测"),
                          ]),
                        ]),
                        _c("td", [_c("span", {}, [_vm._v("15")])]),
                        _c("td", [_vm._v("16")]),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("a-col", { attrs: { span: 9 } }, [
            _c("div", { staticClass: "public" }, [
              _c("h3", { staticClass: "font-16 black" }, [_vm._v("监控视频")]),
              _c("div", { staticClass: "flex j-around" }, [
                _c("div", [
                  _c("span", { staticClass: "black-three" }, [
                    _vm._v("摄像头个数"),
                  ]),
                  _c("span", { staticClass: "black font-24 ml-2" }, [
                    _vm._v("52"),
                  ]),
                ]),
                _c("div", [
                  _c("span", { staticClass: "black-three" }, [
                    _vm._v("在线率"),
                  ]),
                  _c("span", { staticClass: "black font-24 ml-2" }, [
                    _vm._v("2%"),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "w-100 tb-box-3 mt-1" }, [
                _c("table", { staticClass: "w-100" }, [
                  _c("thead", { attrs: { align: "center" } }, [
                    _c("tr", [
                      _c("th", [_vm._v("序号")]),
                      _c("th", [_vm._v("名称")]),
                      _c("th", [_vm._v("状态")]),
                      _c("th", [_vm._v("操作")]),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    { attrs: { align: "center" } },
                    _vm._l(10, function (item, index) {
                      return _c("tr", { key: index }, [
                        _c("td", [
                          _c("span", { staticClass: "table-num" }, [
                            _vm._v("0" + _vm._s(index + 1)),
                          ]),
                        ]),
                        _c("td", [
                          _c(
                            "span",
                            { staticClass: "overtext-1 table-content" },
                            [_vm._v("现场实名制通道")]
                          ),
                        ]),
                        _c("td", [_vm._v("在线")]),
                        _c("td", [
                          _c(
                            "span",
                            { staticClass: "overtext-1 table-suggest" },
                            [_vm._v("查看视频")]
                          ),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c("a-col", { attrs: { span: 5 } }, [
            _c("div", { staticClass: "public" }, [
              _c("h3", { staticClass: "font-16 black" }, [
                _vm._v("教育安全培训"),
              ]),
              _c("div", { staticClass: "purple b-radius1 p-2" }, [
                _c("h4", { staticClass: "text-center" }, [
                  _vm._v("三级安全教育"),
                ]),
                _c("div", { staticClass: "flex j-between" }, [
                  _c("div", [
                    _c("h4", { staticClass: "text-center black-three" }, [
                      _vm._v("参与人数"),
                    ]),
                    _c("p", { staticClass: "black font-24 text-center" }, [
                      _vm._v("236"),
                    ]),
                  ]),
                  _c("div", [
                    _c("h4", { staticClass: "text-center black-three" }, [
                      _vm._v("参与人数"),
                    ]),
                    _c("p", { staticClass: "black font-24 text-center" }, [
                      _vm._v("236"),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "light-blue b-radius1 p-2 mt-1" }, [
                _c("h4", { staticClass: "text-center" }, [
                  _vm._v("安全技术交底"),
                ]),
                _c("div", { staticClass: "flex j-between" }, [
                  _c("div", [
                    _c("h4", { staticClass: "text-center black-three" }, [
                      _vm._v("参与人数"),
                    ]),
                    _c("p", { staticClass: "black font-24 text-center" }, [
                      _vm._v("236"),
                    ]),
                  ]),
                  _c("div", [
                    _c("h4", { staticClass: "text-center black-three" }, [
                      _vm._v("参与人数"),
                    ]),
                    _c("p", { staticClass: "black font-24 text-center" }, [
                      _vm._v("236"),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("a-col", { attrs: { span: 5 } }, [
            _c(
              "div",
              { staticClass: "public" },
              [
                _c("h3", { staticClass: "font-16 black" }, [
                  _vm._v("安全检查统计"),
                ]),
                _c("div", [
                  _c("span", { staticClass: "black-three" }, [
                    _vm._v("已整改/整改单总数"),
                  ]),
                  _c("span", { staticClass: "ml-2 black font-24" }, [
                    _vm._v("64/100"),
                  ]),
                ]),
                _c("a-progress", {
                  attrs: { percent: 96, "show-info": false, strokeWidth: 12 },
                }),
                _c("a-divider"),
                _c(
                  "a-row",
                  { staticClass: "p-1" },
                  [
                    _c("a-col", { attrs: { span: 12 } }, [
                      _c("h4", { staticClass: "black-three" }, [
                        _vm._v("隐患个数"),
                      ]),
                      _c("p", { staticClass: "font-24 black" }, [
                        _vm._v("230"),
                      ]),
                    ]),
                    _c("a-col", { attrs: { span: 12 } }, [
                      _c("h4", { staticClass: "black-three" }, [
                        _vm._v("超时未整改数"),
                      ]),
                      _c("p", { staticClass: "font-24 black" }, [
                        _vm._v("158"),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "a-row",
                  { staticClass: "p-1" },
                  [
                    _c("a-col", { attrs: { span: 12 } }, [
                      _c("h4", { staticClass: "black-three" }, [
                        _vm._v("隐患整改率"),
                      ]),
                      _c("p", { staticClass: "font-24 black" }, [_vm._v("52")]),
                    ]),
                    _c("a-col", { attrs: { span: 12 } }, [
                      _c("h4", { staticClass: "black-three" }, [
                        _vm._v("项目自检完成度"),
                      ]),
                      _c("p", { staticClass: "font-24 black" }, [
                        _vm._v("96%"),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("a-col", { attrs: { span: 14 } }, [
            _c("div", { staticClass: "public" }, [
              _c("h3", { staticClass: "font-16 black" }, [
                _vm._v("安全隐患类型分析"),
              ]),
              _c("div", { staticClass: "flex" }, [
                _c("div", { staticClass: "w-40" }, [
                  _c("div", {
                    ref: "safeChart",
                    staticStyle: { width: "100%", height: "275px" },
                    attrs: { id: "main" },
                  }),
                ]),
                _c("div", { staticClass: "w-60" }, [
                  _c("div", [
                    _c("span", { staticClass: "black-three" }, [
                      _vm._v("隐患总数"),
                    ]),
                    _c("span", { staticClass: "black font-24 ml-2" }, [
                      _vm._v("52"),
                    ]),
                  ]),
                  _c("div", { staticClass: "w-100 tb-box-2 mt-1" }, [
                    _c("table", { staticClass: "w-100" }, [
                      _c("thead", { attrs: { align: "center" } }, [
                        _c("tr", [
                          _c("th", [_vm._v("安全管理")]),
                          _c("th", [_vm._v("文明施工")]),
                          _c("th", [_vm._v("脚手架")]),
                          _c("th", [_vm._v("基坑与模板")]),
                        ]),
                      ]),
                      _c("tbody", { attrs: { align: "center" } }, [
                        _c("tr", [
                          _c("td", [_vm._v("1")]),
                          _c("td", [_vm._v("2")]),
                          _c("td", [_vm._v("3")]),
                          _c("td", [_vm._v("4")]),
                        ]),
                      ]),
                      _c("thead", { attrs: { align: "center" } }, [
                        _c("tr", [
                          _c("th", [_vm._v("高处作业")]),
                          _c("th", [_vm._v("施工用电")]),
                          _c("th", [_vm._v("大型设备")]),
                          _c("th", [_vm._v("器重吊装")]),
                        ]),
                      ]),
                      _c("tbody", { attrs: { align: "center" } }, [
                        _c("tr", [
                          _c("td", [_vm._v("1")]),
                          _c("td", [_vm._v("2")]),
                          _c("td", [_vm._v("3")]),
                          _c("td", [_vm._v("4")]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }