var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bar" }, [
    _c("h4", [_vm._v(_vm._s(_vm.title))]),
    _c(
      "div",
      { staticClass: "chart" },
      [
        _c(
          "v-chart",
          {
            attrs: {
              "force-fit": true,
              height: "312",
              data: _vm.data,
              padding: [24, 0, 0, 0],
            },
          },
          [
            _c("v-tooltip"),
            _c("v-axis"),
            _c("v-bar", { attrs: { position: "x*y" } }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }