<template>
  <a-modal
    v-model="visible"
    :title="'预览'"
    :width="800"
    @cancel="handleCancel"
    :footer="null"
  >
    <div v-if="type === 'pdf'">
      <iframe src="url" frameborder="0" style="width: 100%; height: 600px;"></iframe>
    </div>
    <div v-else-if="type === 'video'">
      <video
        autoplay
        controls
        :src="url"
        style="width: 100%; height: 100%;"
      ></video>
    </div>
    <div v-else-if="type === 'audio'">
      <audio autoplay controls :src="url" style="width: 100%;"></audio>
    </div>
    <div v-else-if="type === 'text'">
      <pre style="white-space: pre-wrap;">{{ textContent }}</pre>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'PreviewModal',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    url: {
      type: String,
      default: ''
    },
    textContent: {
      type: String,
      default: ''
    }
  },
  computed: {
    videoOptions() {
      return {
        autoplay: false,
        controls: true,
        sources: [{
          type: "video/mp4",
          src: this.url
        }]
      };
    }
  },
  methods: {
    handleCancel() {
      // this.visible = false
      this.$emit('update:visible', false);
    }
  }
};
</script>

<style scoped>
.video-player {
  width: 100%;
  height: 600px;
}
</style>