<template>
  <div>
    <div class="constructiontTable_index">
      <form-index
        ref="formindex"
        :projectList="projectList"
        :formData="formData"
        Overview="职务管理"
        :baiduMapBoolen="false"
        @handleSubmit="handleSubmit"
        @handleChange="handleChange"
        @handlSelect="handlSelect"
        :flexSubmit="true"
      >
        <!-- 下部分按钮插槽 -->
        <template #flexSubmit>
            <a-button style="margin-top: 5px" type="primary" html-type="submit"> 查询 </a-button>
            <a-button style="margin-top: 5px" @click="close"> 重置 </a-button>
        </template>
      </form-index>
      <div>
        <a-space class="operator">
          <a-button @click="addJob">新增</a-button>
        </a-space>
      </div>
      <!-- 表格封装 -->
      <standard-table
        class=""
        :columns="columns"
        :dataSource="dataSource"
        rowKey="id"
        :scroll="{ x: 1300 }"
        :pagination="pagination"
        :loading="tableLoading"
        @change="onPageChange"
        :isAlert="false"
        :bordered="false"
      >
        <template slot="index" slot-scope="{ index }">
          <span>
            {{
              (pagination.current - 1) * pagination.pageSize +
              parseInt(index) +
              1
            }}
          </span>
        </template>
        <template slot="projectName" slot-scope="{ record }">
          <!-- :style="record.pjsPath == null || record.pjsPath == ''? '' : 'color:red'" -->
          <div :style="textStyle(record)">
            {{ record.projectName }}
          </div>
        </template>

        <div slot="action" slot-scope="{ record }">
          <div class="sTable">
            <a-icon @click="viewEditing(record)" type="edit" theme="twoTone" />
            <a-icon
              @click="recorDelete(record)"
              type="delete"
              theme="twoTone"
              two-tone-color="#eb2f96"
            />
          </div>
        </div>
      </standard-table>
    </div>
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import {
  aqxjProjectsupdate,
  aqxjprojectsdel,
} from "@/services/projectManagement";
import {getJobByWorkerType}  from "@/services/participationUnit";
import { request } from "@/utils/request";
import { mapGetters } from "vuex";
const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
    // fixed: "left",
  },
  {
    title: "工种",
    dataIndex: "carft",
    key: "carft",
    align: "center",
    // ellipsis: true,
    // scopedSlots: { customRender: "projectName" },
    // fixed: "left",
  },
  {
    title: "工作内容",
    dataIndex: "station",
    align: "center",
    key: "station",
  },
  {
    title: "操作",
    align: "center",
    width: 100,
    // fixed: "right",
    scopedSlots: { customRender: "action" },
  },
];

const formData = [
  {
    label: "工种",
    placeholder: "请输入工种",
    decorator: [
      "projectName",
      { rules: [{ required: false, message: "请输入工种" }] },
    ],
    type: "inputGroup",
    key: "projectName",
    dataSource: [],
    col: 6,
    labelCol: 3,
    wrapperCol: 12,
  },
];
export default {
  components: {
    formIndex, //form表单封装
    StandardTable, //表格封装
  },
  data() {
    return {
      columns: columns, //表格数据
      dataSource: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      tableLoading: false,
      formData: formData,
      prams: {
        pageNumber: 1,
        pageSize: 10,
      },
      projectList: [], //获取当前项目数据
      loadingText: "数据加载中...",
      cPercent: 0,
      isExporting: false,
    };
  },
  computed: {
    textStyle() {
      return function (val) {
        if (val.statusw == 4 && (val.pjsPath != null || val.pjsPath != "")) {
          return "color:red";
        }
      };
    },
    ...mapGetters("account", ["user"]),
  },
  watch: {
    loadingText: {
      handler(newName, oldName) {
        this.loadingText = newName;
      },
      immediate: true,
    },
  },
  created() {
    console.log(this.user, "this.user");
    if (this.$route.query.pageNumber) {
      this.prams.pageNumber = this.$route.query.pageNumber;
    }
    this.aqxjlist(this.prams);
  },
  methods: {
    // 封装弹窗确定按钮
    confirm(title, content) {
      return new Promise((resolve, reject) => {
        this.$confirm({
          title: title,
          content: content,
          onOk() {
            resolve();
          },
        });
      });
    },
    // 删除接口
    recorDelete(val) {
      this.confirm("删除", "是否删除").then(() => {
        aqxjprojectsdel(val.id).then((res) => {
          if (res.data.code === 0) {
            this.$message.success("删除成功", 10);
            this.aqxjlist(this.prams);
          } else {
            this.$message.error(res.data.msg, 10);
          }
        });
      });
    },
    // 更新接口
    update(e) {
      aqxjProjectsupdate(e).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("成功", 10);
        } else {
          this.$message.error(res.data.msg, 10);
        }
      });
    },
    // 查看编辑
    viewEditing(e) {
      let params = {
        projectId: e.projectId,
      };
      console.log(e, "查看编辑数据====================");
      // getLwDetail(params).then((res) => {
      //     this.$router.push({
      //       path: "../project_construction_management/details_lw_index",
      //       query: {
      //         data: res.data.data,
      //         type: "table",
      //         pageNumber: this.prams.pageNumber,
      //       },
      //     });
      // });
    },
    handleChange(e) {
      this.projectArr = [];
      // this.getZdata(projectMsgGetList, e, "fullName");
      // 获取当前匹配key，赋值相对于数组
      this.formData.forEach((item) => {
        if (e.item.key === item.key) {
          item.dataSource = this.projectArr;
        }
      });
    },
    // 接口封装
    /**
     * msg 接收接口名称
     * e 数据
     * name 参数名
     * */
    getZdata(msg, e, name) {
      let data = {
        [name]: e.value,
      };
      msg(data).then((res) => {
        if (res.status == 200) {
          const data = res.data.data.list ? res.data.data.list : res.data.data
          res.data.data.forEach((item) => {
            this.projectArr.push({
              title: item[name],
              id: item.id,
            });
          });
          this.projectList = data;
        }
      });
    },
    // 新建项目
    createItem() {
      this.$router.push({name: "新增项目"});
    },
    // 特殊数据处理
    handlSelect({ val, keys }) {
      this.projectList.forEach((item) => {
        if (keys == "projectName" && item.id == val) {
          this.$refs["formindex"].setFieldsValue(
            item.fullName,
            {},
            "projectName"
          );
        }
      });
    },
    aqxjlist(prams) {
      this.tableLoading = true;
      getJobByWorkerType(prams).then((res) => {
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
        }
        this.tableLoading = false;
      });
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      console.log(data);
      let arr = [];
      data.forEach((item) => {
        arr.push({ name: item.master || item.name, value: item.id });
      });
      this.formData.forEach((item) => {
        if (item.key == key) {
          item.selectlist = arr;
        }
      });
    },

    // 提交表单数据
    handleSubmit(e) {
      let obj = Object.assign({}, e, this.prams);
      this.aqxjlist(obj);
    },
    // 重置
    close() {
      this.prams = {
        pageNumber: 1,
        pageSize: 10,
      };
      this.aqxjlist(this.prams);
      this.$refs["formindex"].setFieldsValue("", {}, "projectName");
      this.$refs["formindex"].resetFields();
    },
    //表格 - 分页查询
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      this.prams.pageNumber = e.current;
      this.pagination.current = e.current;
      this.aqxjlist(Object.assign({}, this.prams, obj));
    },
    addJob() {
      // 新增职务弹窗
    },
  },
};
</script>

<style lang="less" scoped>
.constructiontTable_index {
  padding: 24px;
  background: #fff;
  border-radius: 6px;
  margin-bottom: 10px;
  .operator {
    margin-right: 8px;

    .ant-btn {
      color: #307dfa;
    }
  }
  .c_i_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 25px;

    .c_i_h_center {
      padding: 16px;
      border-radius: 4px;
      width: 180px;
      height: 130px;
      margin-right: 50px;

      div:nth-child(1) {
        font-size: 16px;
      }

      div:nth-child(2) {
        margin-top: 26px;
        font-size: 24px;
      }
    }
  }
}

.sTable {
  display: flex;
  justify-content: space-between;
  align-items: center;

  // div {
  //     width: 100%;
  //     color: blue;
  //     text-align: center;
  //     cursor: pointer;
  // }
}
</style>
