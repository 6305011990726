var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: "960px", title: _vm.title, footer: null },
      on: { cancel: _vm.close },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "a-form-model",
        { ref: "ruleForm", attrs: { model: _vm.params, rules: _vm.rules } },
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "姓名",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                        prop: "name",
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入姓名" },
                        model: {
                          value: _vm.params.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "name", $$v)
                          },
                          expression: "params.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "性别",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                        prop: "sexdesc",
                      },
                    },
                    [
                      _c("a-select", {
                        staticStyle: { width: "266px" },
                        attrs: {
                          placeholder: "请选择 性别",
                          options: _vm.options2,
                          allowClear: "",
                        },
                        model: {
                          value: _vm.params.sexdesc,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "sexdesc", $$v)
                          },
                          expression: "params.sexdesc",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "职称",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                        prop: "titledesc",
                      },
                    },
                    [
                      _c(
                        "a-auto-complete",
                        {
                          staticStyle: { width: "260px" },
                          model: {
                            value: _vm.params.titledesc,
                            callback: function ($$v) {
                              _vm.$set(_vm.params, "titledesc", $$v)
                            },
                            expression: "params.titledesc",
                          },
                        },
                        [
                          _c(
                            "template",
                            { slot: "dataSource" },
                            _vm._l(_vm.options3, function (item) {
                              return _c(
                                "a-select-option",
                                { key: item.value },
                                [_vm._v(" " + _vm._s(item.label) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "专业特长",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                        prop: "specdesc",
                      },
                    },
                    [
                      _c("a-select", {
                        staticStyle: { width: "266px" },
                        attrs: {
                          placeholder: "请选择 专业特长",
                          options: _vm.options4,
                          allowClear: "",
                        },
                        model: {
                          value: _vm.params.specdesc,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "specdesc", $$v)
                          },
                          expression: "params.specdesc",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "联系电话",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                        prop: "phone",
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          type: "number",
                          placeholder: "请输入 联系电话",
                        },
                        model: {
                          value: _vm.params.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "phone", $$v)
                          },
                          expression: "params.phone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "应急领域",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                        prop: "domaindesc",
                      },
                    },
                    [
                      _c("a-select", {
                        staticStyle: { width: "266px" },
                        attrs: {
                          placeholder: "请选择 应急领域",
                          options: _vm.options6,
                          allowClear: "",
                        },
                        model: {
                          value: _vm.params.domaindesc,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "domaindesc", $$v)
                          },
                          expression: "params.domaindesc",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "备注",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                        prop: "beizhu",
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入 " },
                        model: {
                          value: _vm.params.beizhu,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "beizhu", $$v)
                          },
                          expression: "params.beizhu",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-row",
        [
          _c("a-col", { attrs: { span: 12 } }),
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    "label-col": { span: 4 },
                    "wrapper-col": { span: 14 },
                    prop: "year",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex flex-row-reverse" },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", loading: _vm.sbloading },
                          on: { click: _vm.handleSubmit },
                        },
                        [_vm._v("保存")]
                      ),
                      _c(
                        "a-button",
                        { staticClass: "mr-2", on: { click: _vm.close } },
                        [_vm._v("返回")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }