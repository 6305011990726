<template>

    <div>
        <a-card :body-style="{padding: '0px'}" :bordered="false">
            <div id="components-form-demo-advanced-search">
                <a-form-model
                        :model="queryData"
                        layout="inline"
                        class="ant-advanced-search-form"
                >
                    <a-form-model-item ref="day" label="超出时长(天)" prop="day">
                        <a-input-number id="inputNumber" v-model="queryData.day" :min="1" :max="1000" />
                    </a-form-model-item>
                    <a-form-model-item ref="name" label="工人姓名" prop="name">
                        <a-input v-model="queryData.name" />
                    </a-form-model-item>
                    <a-button type="primary" icon="search" :loading="loading" @click="getData">
                        查询
                    </a-button>
                    <a-upload
                            name="file"
                            :multiple="true"
                            :action="uploadEpidemic"
                            :headers="headers"
                            @change="uploadEpidemicFileChange"
                            :showUploadList="false"
                            :style="{ marginLeft: '8px' }"
                            :beforeUpload="beforeUpload"
                            :accept='accept'
                    >
                        <a-button type="primary"> <a-icon type="upload" />导入核酸结果</a-button>
                    </a-upload>
                </a-form-model>

            </div>
            <a-table
                    :columns="columns"
                    :data-source="data"
                    :loading="loading">

            </a-table>
        </a-card>
    </div>
</template>

<script>
    import {getNotNucleinStatistics} from '@/services/epidemic'
    import {UPLOAD_EPIDEMIC} from '@/services/api'
    import {mapGetters} from 'vuex'
    import moment from 'moment'
    import { session } from '@/utils/storage'
    import { xsrfHeaderName } from '@/utils/request.js'
    import { useRender } from '@/hooks/useRender.js'

    const columns = [
        {
            title: '工人姓名',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '身份证号',
            dataIndex: 'idCard',
            key: 'idCard',
        },
        {
            title: '手机号',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: '所属班组',
            dataIndex: 'teamName',
            key: 'teamName',
        },
        {
            title: '最近核酸时间',
            dataIndex: 'checkLastDate',
            key: 'checkLastDate',
            customRender: (text) => useRender.renderDate(text)
        },
        {
            title: '超出时长（天）',
            dataIndex: 'day',
            key: 'day',
        },

    ];



    export default {
        data() {
            return {
                headers: {
                    Authorization: session.get(xsrfHeaderName),
                    'Tenant-Id': session.get('Tenant-Id') ? session.get('Tenant-Id') : ''
                },
                queryData: {
                    day: 7,
                    name: '',
                },
                data: [],
                // pagination: {
                //     current: 0,
                //     size: '20',
                //     total: 0,
                // },
                loading: false,
                columns,
                selectedSummaryIdList: [],

                uploadEpidemic: UPLOAD_EPIDEMIC,
                accept:'.png,.PNG,.jpg,.JPG,.pdf,.doc,.docx',//图片上传限制

            };
        },
        computed: {
            hasTeamSelected() {
                return this.selectedTeamIds.length > 0;
            },
            ...mapGetters('account', ['user']),
        },
        created() {
            // this.queryData.projectId = this.user.projectId
            this.getData();
        },
        methods: {
            beforeUpload(file){
                const accept = '.' + file.name.substring(file.name.lastIndexOf(".") + 1);
                if (this.accept.split(',').indexOf(accept) == -1) {
                    this.$message.error("只能上传图片、Word文档、PDF文件");
                    return false
                }
            },
            getData(){
                this.loading = true
                getNotNucleinStatistics(this.queryData).then(res => {
                    this.data = res.data.data;
                }).finally(()=>{
                    this.loading = false
                })
            },
            uploadEpidemicFileChange(info){
                if (info.file.status === 'done') {
                    const stat = info.file.response.code
                    if (stat === 0){
                        this.$message.success(info.file.response.msg)
                        this.getData()
                    }else {
                        this.$message.error(info.file.response.msg)
                    }
                }
            }

        },


    };

</script>
<style scoped>

    .ant-advanced-search-form {
        padding: 24px;
        background: #fbfbfb;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        margin-bottom: 10px;
    }

    tr{
        background-color: #ffffff;
    }

    .steps-content {
        margin-top: 16px;
        border: 1px dashed #e9e9e9;
        border-radius: 6px;
        background-color: #fafafa;
        padding-top: 10px;
    }

    .steps-action {
        margin-top: 24px;
    }
</style>