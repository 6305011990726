<template>
  <a-card>
    <a-spin :spinning="spinning" :tip="loadingText">
      <div :class="advanced ? 'search' : null">
        <a-form layout="horizontal">
          <div :class="advanced ? null : 'fold'">
            <a-row>
              <a-col :md="8" :sm="24">
                <a-form-item
                  label="日期"
                  :labelCol="{ span: 5 }"
                  :wrapperCol="{ span: 18, offset: 1 }"
                >
                    <a-range-picker
                      v-model="queryParams.doDate"
                      show-time
                      format="YYYY-MM-DD HH:mm:ss"
                    />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item
                  label="用户名"
                  :labelCol="{ span: 5 }"
                  :wrapperCol="{ span: 18, offset: 1 }"
                >
                    <a-input
                      v-model="queryParams.operName"
                    />
                </a-form-item>
              </a-col>
            </a-row>
          </div>
          <span style="float: right; margin-top: 3px">
            <a-button type="primary" @click="doQuery()">查询</a-button>
            <a-button style="margin-left: 8px" @click="doReset()">重置</a-button
            >
          </span>
        </a-form>
      </div>
      <div>
        <standard-table
          :columns="columns"
          :dataSource="dataSource"
          :selectedRows.sync="selectedRows"
          :pagination="pagination"
          :loading="tableLoading"
          @clear="onClear"
          @change="onPageChange"
        >
          <div slot="do-status" slot-scope="{ text }">
            <a-icon v-if="text == 1" style="color: red" type="close"/>
            <a-icon v-else  style="color: green" type="check" />
          </div>
          <div slot="action"  slot-scope="{record}">
            <a @click="doDetail(record)" >查看详情</a>
          </div>
        </standard-table>
      </div>
    </a-spin>
    <a-modal v-model="visible" title="日志详情" :width="960" :footer="null" @cancel="handleCancel">
      <p>姓名：{{recode.operName}}</p>
      <p>状态：
          <a-icon v-if="recode.status == 1" style="color: red" type="close"/>
          <a-icon v-else  style="color: green" type="check" />
      </p>
      <p>异常信息：{{recode.jsonResult}}</p>
      <p>参数信息：{{recode.operParam}}</p>
    </a-modal>
  </a-card>
</template>

<script>
import StandardTable from "@/components/table/StandardTable";
import { SYS_OPER_LOG_QUERY } from "@/services/api";
import { request } from "@/utils/request";
import { te } from "date-fns/locale";
// import axios from '@/utils/axios'
import { useRender } from '@/hooks/useRender.js'

const columns = [
  {
    title: "操作标题",
    dataIndex: "title",
    align: "center",
    width:150,
    scopedSlots: { customRender: "title" },
  },
  {
    title: "操作用户",
    dataIndex: "operName",
    align: "center",
    width:130,
    scopedSlots: { customRender: "operName" },
  },
  {
    title: "公司ID",
    dataIndex: "deptName",
    align: "center",
    width:150,
    scopedSlots: { customRender: "deptName" },
  },
  {
    title: "请求路径",
    dataIndex: "operUrl",
    align: "center",
    width:200,
    scopedSlots: { customRender: "operUrl" },
  },
  // {
  //   title: "请求参数",
  //   dataIndex: "operParam",
  //   align: "left",
  //   width:350,
  //   scopedSlots: { customRender: "operParam" },
  // },
  {
    title: "是否成功",
    dataIndex: "status",
    align: "center",
    width:120,
    scopedSlots: { customRender: "do-status" },
  },
  {
    title: "错误信息",
    dataIndex: "errorMsg",
    align: "center",
    width:350,
    scopedSlots: { customRender: "errorMsg" },
  },
  {
    title: "操作时间",
    dataIndex: "operTime",
    align: "center",
    width:150,
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "操作",
    dataIndex: "action",
    align: "center",
    width:150,
    scopedSlots: { customRender: "action" },
  },
];

export default {
  name: "QueryList",
  components: { StandardTable },
  data() {
    return {
      dataSource: [],
      advanced: false,
      columns: columns,
      selectedRows: [],
      tableLoading: false,
      visible:false,
      recode:{},
      spinning: false,
      loadingText: "数据加载中...",
      loadKeys: [],
      queryParams: {
        doDate:null,
        operName:null,
        businessType:1,
        pageNumber: 1,
      },
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showLessItems: true,
        showQuickJumper: true,
      },
    };
  },
  created() {
    this.doQuery();
  },
  authorize: {
    deleteRecord: "delete",
  },
  methods: {
    deleteRecord(key) {
      this.dataSource = this.dataSource.filter((item) => item.key !== key);
      this.selectedRows = this.selectedRows.filter((item) => item.key !== key);
    },
    toggleAdvanced() {
      this.advanced = !this.advanced;
    },
    remove() {
      this.dataSource = this.dataSource.filter(
        (item) =>
          this.selectedRows.findIndex((row) => row.key === item.key) === -1
      );
      this.selectedRows = [];
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },

    /**
     * 获取所有人员的签名记录信息
     */
    doQuery() {
      this.tableLoading = true;
      console.log(this.queryParams.doDate);
      if(this.queryParams.doDate&&this.queryParams.doDate.length>0){
        this.queryParams.startOperTime = this.queryParams.doDate[0].format('YYYY-MM-DD HH:mm:ss')
        this.queryParams.endOperTime = this.queryParams.doDate[1].format('YYYY-MM-DD HH:mm:ss')
      }else{
        this.queryParams.startOperTime = null;
        this.queryParams.endOperTime = null;
      }
      request(SYS_OPER_LOG_QUERY, "post", this.queryParams).then((res) => {
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list;
          this.pagination.total = Number(res.data.data.total);
          this.dataSource.forEach((obj) => {
            obj.key = obj.operId;
          });
        }
        this.tableLoading = false;
      });
    },
    doReset() {
      this.queryParams = {};
      this.doQuery();
    },
    //分页查询
    onPageChange(e) {
      this.queryParams.pageNumber = e.current;
      this.pagination.current = e.current;
      this.doQuery();
    },
    handleMenuClick(e) {
      if (e.key === "delete") {
        this.remove();
      }
    },
    handleCancel() {
      this.visible = false;
      this.recode = {};
    },
    doDetail(recode){
      this.visible = true;
      this.recode = recode;
    }
  },
};
</script>

<style lang="less" scoped>
.search {
  margin-bottom: 54px;
}
.fold {
  width: calc(100% - 216px);
  display: inline-block;
}
.operator {
  margin-bottom: 18px;
}

@media screen and (max-width: 900px) {
  .fold {
    width: 100%;
  }
}
</style>
