import { ADMIN, GET_PAYROLL_LIST, GET_ATTEND_SHEET, EXPORT_ATTEND_SHEET,
    SUBMIT_PAYROLL_DATA, EXPORT_FILE, GET_OFFICE_LIST_BY_PROJECT_ID,
    GET_TEAM_LIST_BY_OFFICE_ID, FIND_NO_ATTACH, FIND_NO_ATTACH_PERSONS,
    SELECT_TEAM_AND_TEAM_WORKER_STAFF_BY_PROJECT_ID, GET_TEAM_PAYROLL_APPLY_LIST,
    SUBMIT_PAYROLL_APPLY, GET_STAFF_PAYROLL_LIST, DOWNLOAD_STAFF_PAYROLL, SUMMARY_EXPORT,
    UPLOAD_STAFF_PAYROLL, DOWNLOAD_TEAM_PAYROLL, PAYROLL_APPLY_SUMMARY, GET_SUMMARY_LIST,
    IMPORT_STAFF_PAYROLL_TAX, GET_SYS_OFFICE, BATCH_UP_STATUS, DEL_STAFF_PAYROLL, TEAM_PAYROLL_APPLY_DEL,
    UPLOAD_STAFF_PAYROLL_BY_MANUAL, BATCH_FINANCE_REPULSE, GET_PROJECT_SUMMARY_APPLY_BY_SUMMARY_ID,
    SUMMARY_PROJECT_EXPORT, UPDATE_STAFF_PAYROLL, TAX_RECORD_LIST, TAX_RECORD_EXPORT} from '@/services/api'
import {request, METHOD, removeAuthorization} from '@/utils/request'
// const BASE_URL = process.env.VUE_APP_API_BASE_URL

/**
 * 获取考勤统计
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getAttendSheet(params) {
    return request(GET_ATTEND_SHEET, METHOD.GET, params)
}

/**
 * 导出考勤统计
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function exportAttendSheet(params) {
    return request(EXPORT_ATTEND_SHEET, METHOD.GET, params, {responseType: "blob"})
}


/**
 *  获取工资计划
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getPayrollList(params) {
    return request(GET_PAYROLL_LIST, METHOD.GET, params)
}

/**
 *  导出班组的工资计划
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function exportFile(params) {
    return request(EXPORT_FILE, METHOD.GET, params, {responseType: "blob"})
}

/**
 *  提交工资计划
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function submitPayrollData(params) {
    return request(SUBMIT_PAYROLL_DATA, METHOD.POST, params)
}


/**
 *  获取公司列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getOfficeListByProjectId() {
    return request(GET_OFFICE_LIST_BY_PROJECT_ID, METHOD.GET)
}

/**
 *  获取班组列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getTeamListByOfficeId(param) {
    return request(GET_TEAM_LIST_BY_OFFICE_ID, METHOD.GET, param)
}

/**
 *  获取未考勤统计
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function findNoAttach(param) {
    return request(FIND_NO_ATTACH, METHOD.POST, param)
}
/**
 *  获取未考勤详细
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function findNoAttachPersons(param) {
    return request(FIND_NO_ATTACH_PERSONS, METHOD.POST, param)
}

/**
 * 获取班组的工资计划申请列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getTeamPayrollApplyList(params) {
    return request(GET_TEAM_PAYROLL_APPLY_LIST, METHOD.POST, params)
}

/**
 * 获取项目-公司下的班组及班组工人列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function selectTeamAndTeamWorkerStaffByProjectId(param) {
    return request(SELECT_TEAM_AND_TEAM_WORKER_STAFF_BY_PROJECT_ID, METHOD.GET, param)
}

/**
 * 提交工资计划申请
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function submitPayrollApply(param) {
    return request(SUBMIT_PAYROLL_APPLY, METHOD.POST, param)
}

/**
 * 获取工人工资计划申请列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getStaffPayrollList(params) {
    return request(GET_STAFF_PAYROLL_LIST, METHOD.POST, params)
}

/**
 * 下载工人工资计划申请列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function downloadStaffPayroll(params) {
    return request(DOWNLOAD_STAFF_PAYROLL, METHOD.GET, params, {responseType: "blob"})
}

/**
 * 上传工人工资计划申请列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function uploadStaffPayroll(params) {
    return request(UPLOAD_STAFF_PAYROLL, METHOD.POST, params)
}

/**
 * 下载班组工资计划申请列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function downloadTeamPayroll(params) {
    return request(DOWNLOAD_TEAM_PAYROLL, METHOD.GET, params, {responseType: "blob"})
}

/**
 * 工资计划申请汇总
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function payrollApplySummary(params) {
    let url = PAYROLL_APPLY_SUMMARY + "?month=" + params.month
    return request(url, METHOD.POST, params.applyIdList)
}

/**
 * 导出汇总工资单
 */
export async function summaryExport(params) {
    return request(SUMMARY_EXPORT, METHOD.GET, params, {responseType: "blob"})
}

/**
 * 
 */
export async function taxRecordExport(params) {
    return request(TAX_RECORD_EXPORT, METHOD.POST, params, {responseType: "blob"})
}

/**
 * 导出项目维度汇总工资单
 */
export async function summaryProjectExport(params) {
    return request(SUMMARY_PROJECT_EXPORT, METHOD.GET, params, {responseType: "blob"})
}

/**
 * 获取汇总工资单列表
 */
export async function getSummaryList(params) {
    return request(GET_SUMMARY_LIST, METHOD.POST, params)
}

/**
 * 导入汇总工资税务
 */
export async function importStaffPayrollTax(params) {
    return request(IMPORT_STAFF_PAYROLL_TAX, METHOD.POST, params)
}

/**
 * 获取公司信息
 */
export async function getSysOffice(params) {
    return request(GET_SYS_OFFICE + `/${params}`, METHOD.GET)
}

/**
 * 批量流转申请单状态
 * @param params
 * @returns {Promise<*>}
 */
export async function batchUpStatus(params) {
    return request(BATCH_UP_STATUS, METHOD.POST, params)
}

/**
 * 流转财务
 */
export async function batchCirculationFinance(params) {
    let url = BATCH_UP_STATUS + "?status=1" ;
    return request(url, METHOD.POST, params)
}

/**
 * 财务打回申请单
 */
export async function batchFinanceRepulse(params) {
    return request(BATCH_FINANCE_REPULSE, METHOD.POST, params)
}

/**
 * 删除工人工资计划
 * @param params
 * @returns {Promise<*>}
 */
export async function delStaffPayroll(params) {
    return request(DEL_STAFF_PAYROLL, METHOD.GET, params)
}

/**
 * 手动录入工人工资计划
 * @param params
 * @returns {Promise<*>}
 */
export async function uploadStaffPayrollByManual(params) {
    return request(UPLOAD_STAFF_PAYROLL_BY_MANUAL, METHOD.POST, params)
}

/**
 * 获取汇总单中每个项目的每个月份汇总单
 */
export async function getProjectSummaryApplyBySummaryId(params) {
    return request(GET_PROJECT_SUMMARY_APPLY_BY_SUMMARY_ID, METHOD.GET, params)
}

/**
 * 
 */
export async function getTaxRecordList(params) {
    return request(TAX_RECORD_LIST, METHOD.POST, params)
}

/**
 * 删除班组申请单
 */
export async function teamPayrollApplyDel(params) {
    return request(TEAM_PAYROLL_APPLY_DEL, METHOD.GET, params)
}

/**
 *
 */
export async function updateStaffPayroll(params){
    return request(UPDATE_STAFF_PAYROLL, METHOD.POST, params)
}

/**
 * 获取项目汇总列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getProSumList(params) {
    return request(ADMIN + `/labour/payrollApplySummary/getProjectSummaryList`, METHOD.POST, params)
}

/**
 * 获取项目汇总详情列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getProSumListDetail(params) {
    return request(ADMIN + `/labour/payrollApplySummary/getProjectDetailList`, METHOD.POST, params)
}

/**
 * 获取某制表月汇总工人工资列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getProStaffList(params) {
    return request(ADMIN + `/labour/payrollApplySummary/getSummaryStaffPayrollListByMonth`, METHOD.POST, params)
}

/**
 * 获取某制表月工人工资明细列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getProStaffPayrollDetail(params) {
    return request(ADMIN + `/labour/payrollApplySummary/getSummaryStaffPayrollDetailListByMonth`, METHOD.POST, params)
}

/**
 * 获得工资发放计划分页
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getSalaryPlanList(params) {
    return request(ADMIN + `/labour/wages-plan/page`, METHOD.POST, params)
}

/**
 * 创建工资发放计划
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function createSalaryPlan(params) {
    return request(ADMIN + `/labour/wages-plan/create`, METHOD.POST, params)
}

/**
 * 删除工资发放计划
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function delSalaryPlan(params) {
    return request(ADMIN + `/labour/wages-plan/delete`, METHOD.DELETE, params)
}

/**
 * 获得工资发放计划
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getSalaryPlan(params) {
    return request(ADMIN + `/labour/wages-plan/get`, METHOD.GET, params)
}

/**
 * 更新工资发放计划
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function updateSalaryPlan(params) {
    return request(ADMIN + `/labour/wages-plan/update`, METHOD.PUT, params)
}

/**
 * 创建工资发放班组
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function saveBatch(params) {
    return request(ADMIN + `/labour/wages-team/saveBatch`, METHOD.POST, params)
}

/**
 * 获得工资发放班组
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getSalaryTeam(params) {
    return request(ADMIN + `/labour/wages-team/page`, METHOD.POST, params)
}

/**
 * 删除班组
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function delSalaryTeam(params) {
    return request(ADMIN + `/labour/wages-team/delete`, METHOD.DELETE, params)
}

/**
 * 获取待修改的数据信息
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getEditPlans(params) {
    return request(ADMIN + `/labour/wages-plan/getEditPlans/${params.id}`, METHOD.GET)
}

/**
 * 保存修改之后de数据
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function saveSalaryPlanChange(params) {
    return request(ADMIN + `/labour/wages-plan/doSaveDataChange`, METHOD.POST, params)
}

/**
 * 从系统获取平台的考勤人员数据
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getSystemAttendData(params) {
    return request(ADMIN + `/labour/wages-team/doGetSystemAttendData/${params.planId}`, METHOD.GET)
}

/**
 * 从系统获取平台的考勤人员数据
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getJwWorkAttend(params) {
    return request(ADMIN + `/labour/wages-team/getJwWorkAttend/${params.planId}`, METHOD.GET)
}

/**
 * 选择考勤数据来源方式
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function chooseType(params) {
    return request(ADMIN + `/labour/wages-plan/chooseType`, METHOD.POST, params)
}

/**
 * 导出考勤表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function exportAttend(params) {
    return request(ADMIN + `/labour/wages-team/exportAttend/${params.planId}`, METHOD.GET, {}, {responseType: "blob"})
}

/**
 * 导出工资表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function exportBaisc(params) {
    return request(ADMIN + `/labour/wages-team/exportBaisc/${params.planId}`, METHOD.GET, {}, {responseType: "blob"})
}

/**
 * 导出全套资料
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function exportAll(params) {
    return request(ADMIN + `/labour/wages-team/exportAll/${params.planId}`, METHOD.GET, {}, {responseType: "blob"})
}

/**
 * 一次性获取所有状态的数量
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getAllStatusCnt(params) {
    return request(ADMIN + `/labour/wages-team/getAllStatusCnt`, METHOD.GET)
}

/**
 * 获取各个状态的数量
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getStatusCnt(params) {
    return request(ADMIN + `/labour/wages-team/getStatusCnt/${params} `, METHOD.GET)
}
// 


export default {
    getPayrollList,
    getAttendSheet,
    exportAttendSheet,
    submitPayrollData,
    exportFile,
    getOfficeListByProjectId,
    getTeamListByOfficeId,
    findNoAttach,
    findNoAttachPersons,
    getTeamPayrollApplyList,
    selectTeamAndTeamWorkerStaffByProjectId,
    submitPayrollApply,
    getStaffPayrollList,
    downloadStaffPayroll,
    downloadTeamPayroll,
    payrollApplySummary,
    summaryExport,
    getSummaryList,
    importStaffPayrollTax,
    getSysOffice,
    batchUpStatus,
    delStaffPayroll,
    uploadStaffPayrollByManual,
    batchCirculationFinance,
    batchFinanceRepulse,
    getProjectSummaryApplyBySummaryId,
    teamPayrollApplyDel,
    summaryProjectExport,
    updateStaffPayroll
}