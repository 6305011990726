var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a_Approval" },
    [
      _c("div", { staticClass: "plug-Overview" }, [_vm._v("建委配置")]),
      _c("a-divider"),
      _c("form-index", {
        ref: "formindex",
        attrs: {
          formData: _vm.formData,
          flexAction: "",
          baiduMapBoolen: false,
        },
        on: { handleSubmit: _vm.handleSubmit },
        scopedSlots: _vm._u([
          {
            key: "flexAction",
            fn: function () {
              return [
                _c(
                  "a-space",
                  { staticClass: "btnBox" },
                  [
                    _c(
                      "a-button",
                      { attrs: { type: "primary", "html-type": "submit" } },
                      [_vm._v("查询")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("standard-table", {
        attrs: {
          columns: _vm.columns,
          dataSource: _vm.dataSource,
          rowKey: "projectId",
          pagination: _vm.pagination,
          loading: _vm.tableLoading,
          isAlert: false,
          bordered: true,
          btnArr: _vm.tableBtnArr,
        },
        on: { change: _vm.onPageChange },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function (ref) {
              var index = ref.index
              return [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        (_vm.pagination.current - 1) * _vm.pagination.pageSize +
                          parseInt(index) +
                          1
                      ) +
                      " "
                  ),
                ]),
              ]
            },
          },
          {
            key: "status",
            fn: function (ref) {
              var record = ref.record
              return [
                _c("a-switch", {
                  attrs: {
                    checked: record.status == 0,
                    "checked-children": "已开启",
                    "un-checked-children": "已关闭",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.onSwitchClick($event, record)
                    },
                  },
                }),
              ]
            },
          },
          {
            key: "action",
            fn: function (ref) {
              var record = ref.record
              return [
                _c("a-space", [
                  _c(
                    "div",
                    {
                      staticClass: "pointer",
                      staticStyle: { color: "#ff5c77" },
                      on: {
                        click: function ($event) {
                          return _vm.del(record)
                        },
                      },
                    },
                    [_vm._v("删除")]
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
      _c(
        "a-modal",
        {
          attrs: {
            title: "新增工程配置",
            destroyOnClose: true,
            footer: null,
            maskClosable: false,
          },
          on: { cancel: _vm.closeModal },
          model: {
            value: _vm.modalVisible,
            callback: function ($$v) {
              _vm.modalVisible = $$v
            },
            expression: "modalVisible",
          },
        },
        [
          _c("form-index", {
            ref: "formindexModal",
            attrs: {
              formData: _vm.formDataModal,
              flexSubmit: true,
              flexAction: true,
            },
            on: {
              handleSubmit: _vm.handleModalSubmit,
              handleChange: _vm.handleModalChange,
              handlSelect: _vm.handlModalSelect,
            },
            scopedSlots: _vm._u([
              {
                key: "flexAction",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "flex a-center j-center" },
                      [
                        _c(
                          "a-space",
                          { style: { marginBottom: "20px" } },
                          [
                            _c("a-button", { on: { click: _vm.closeModal } }, [
                              _vm._v(" 返回 "),
                            ]),
                            _c(
                              "a-button",
                              {
                                attrs: {
                                  type: "primary",
                                  "html-type": "submit",
                                },
                              },
                              [_vm._v("提交")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }