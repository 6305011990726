<template>
  <div class="team card">
    <!-- 班组情况概览 -->
    <div style="margin-bottom: 15px" class="black font-16 font-bold overtext-1 flex">
      <img src="@/assets/img/payroll-card1.png" style="width: 25px; height: 25px;" alt="">
      <span class="ml-05">班组情况概览</span>
    </div>
    <a-divider />
    <div style="display: flex;">
      <div
        style="
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          margin-right: 20px;
          background: #EBF2FF;
          padding: 10px 0;
          border-radius: 10px;

        "
      >
        <span class="font-28 font-bold">{{
          teamDataKq.totalTeams || 109
        }}</span>
        <span>累计登记</span>
      </div>
      <div
        style="
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          background: #E9F7FF;
          padding: 10px 0;
          border-radius: 10px;
        "
      >
        <span class="font-28 font-bold">{{
          teamDataKq.onlineTeams || 37
        }}</span>
        <span>在场班组</span>
      </div>
    </div>
    <div style="display: flex" class="mt-1">
      <div
        style="
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          margin-right: 20px;
          background: #EBF2FF;
          padding: 10px 0;
          border-radius: 10px;
        "
      >
        <span class="font-28 font-bold">{{ teamDataKq.todayTeams || 29 }}</span>
        <span>今日出勤</span>
      </div>
      <div
        style="
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          background: #E9F7FF;
          padding: 10px 0;
          border-radius: 10px;
        "
      >
        <span class="font-28 font-bold">{{ teamDataKq.teamCql || "78%" }}</span>
        <span>出勤率</span>
      </div>
    </div>

    <!-- 班组考勤情况 -->
    <vue-scroll
      :ops="ops"
      style="height: 264px"
      class="flex-1"
    >
      <standard-table
        class="close-table2 scroll-tabox"
        :columns="teamColumns"
        :dataSource="teamRankList"
        rowKey="id"
        :pagination="false"
        :isAlert="false"
        :bordered="false"
      >
        <template slot="cql" slot-scope="{ text }">
          <span> {{ text }}% </span>
        </template>
      </standard-table>
    </vue-scroll>
  </div>
</template>

<script>
import StandardTable from "@/components/table/StandardTable";
export default {
  props: {
    teamDataKq: {
      type: Object,
      default: () => ({}),
    },
    teamRankList: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    StandardTable
  },
  data() {
    return {
      teamColumns: [
        {
          title: "班组名称",
          dataIndex: "teamName",
          align: "center",
          ellipsis: true,
          width: 150,
        },
        {
          title: "在场人数",
          dataIndex: "numbers",
          align: "center",
          width: 90,
        },
        {
          title: "出勤率",
          dataIndex: "cql",
          align: "center",
          scopedSlots: { customRender: "cql" },
          width: 75,
        },
      ],
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true,
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: "#3ec13e", //滚动条颜色
          opacity: 0.8, //滚动条透明度
          "overflow-x": "hidden",
        },
      },
    };
  },
};
</script>

<style lang="less" scoped>
.card {
  border-radius: 10px;
  padding: 17px;
  background-color: #fff;
}
</style>
