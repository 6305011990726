<template>
  <div class="dial-container">
    <div ref="chart" class="chart"></div>
    <div class="relative white">
      <img src="https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20240718/1721273563378.png?Expires=2036633563&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=tF907QGifu6zS%2FOebvQhI5%2BQ3os%3D" alt="">
      <span class="data-text" v-if="spinning == true">回转角度：
        <a-spin>
          <a-icon slot="indicator" type="loading" style="font-size: 16px;color: #fff" spin />
        </a-spin>
        °</span>
      <span class="data-text" v-else>回转角度：{{this.angle}}°</span>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  props: {
    spinning: {
      type: Boolean,
      default: false
    },
    angle: {
      type: Number,
      default: 0
    }
  },
  mounted() {
    this.initChart();
  },
  watch: {
    angle() {
      this.updateChart();
    }
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$refs.chart);
      this.updateChart();
    },
    updateChart() {
      const option = {
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -269.9999,
            min: 0,
            max: 360,
            pointer: {
              show: true,
              length: '60%',
              width: 6
            },
            axisLine: {
              lineStyle: {
                width: 10,
                color: [[1, '#dfe9f3']]
              }
            },
            splitLine: {
              length: 10,
              lineStyle: {
                color: '#aaa',
                width: 2,
                type: 'dashed'
              }
            },
            axisTick: {
              show: true,
              splitNumber: 5,
              length: 5,
              lineStyle: {
                color: '#aaa',
                width: 1
              }
            },
            axisLabel: {
              show: false
            },
            detail: {
              show: false
            },
            data: [
              {
                value: this.angle,
                name: ''
              }
            ],
            itemStyle: {
              color: '#0044cc'
            }
          }
        ]
      };
      this.chart.setOption(option, true);
    }
  }
};
</script>

<style scoped>
.dial-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chart {
  width: 250px;
  height: 250px;
}
.data-text {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 75%;
  transform: translate(-40%, -50%);
  font-size: 12px;
  font-weight: bold;
}
</style>
