import { ADMIN, GET_CROSS_PROJECT_LIST,GET_OFFICEID_PROJECT} from '@/services/api'
import {request, METHOD} from '@/utils/request'
// const BASE_URL = process.env.VUE_APP_API_BASE_URL

/**
 * 获取跨项目人员名单
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getCrossProjectList(param) {
    return request(GET_CROSS_PROJECT_LIST, METHOD.POST, param)
}

/**
 * @desc 查询公司下面的所有项目 
 */
 export async function getOfficeIdProject(id) {
    let url = `${ADMIN}/laowu/project/getProjectsByParentOfficeId?parentOfficeId=${id}`
    return request(url)
}

//   /getSafeDutyByProjId/{projectId}
/**
 * @desc 查询公司下面的所有项目 
 */
 export async function getSafeDutyByProjId(id) {
    let url = `${ADMIN}/safe/projectSafeDuty/getSafeDutyByProjId/${id}`
    return request(url)
}

/**
 * @desc 获得班前教育资料分页
 */
export async function geteducationInfoPage(params) {
    return request(ADMIN + `/edu/work-education-info/page`, METHOD.POST, params)
}


/**
 * @description 新增-班前教育记录
 */
export async function createEducation(params) {
    let url = `${ADMIN}/edu/work-education-info/create`
    return request(url, METHOD.POST, params)
}

/**
 * @description 获取-班前教育记录
 */
export async function getEducation(params) {
    let url = `${ADMIN}/edu/work-education-info/get`
    return request(url, METHOD.GET, params)
}

/**
 * @description 更新-班前教育记录
 */
export async function updateEducation(params) {
    let url = `${ADMIN}/edu/work-education-info/update`
    return request(url, METHOD.PUT, params)
}

/**
 * @description 删除-班前教育记录
 */
export async function deleteEducation(params) {
    let url = `${ADMIN}/edu/work-education-info/delete`
    return request(url, METHOD.DELETE, params)
}

/**
 * @desc 删除班前教育资料
 */
export async function deleteEducationInfo(params) {
    return request(ADMIN + `/edu/work-education-info/delete`, METHOD.DELETE, params)
}

/**
 * @desc 上周无教育记录班组
 */
export async function getNotEducatePreWeek(params) {
    return request(ADMIN + `/edu/work-education-info/getNotEducatePreWeek`, METHOD.POST, params)
}

/**
 * @desc 查询班组劳工
 */
export async function getfindStaffMoment(params) {
    return request(ADMIN + `/laowu/team/findStaff/moment`, METHOD.POST, params)
}

/**
 * @desc 更新班前教育资料
 */
export async function updateEducationInfo(params) {
    return request(ADMIN + `/edu/work-education-info/update`, METHOD.PUT, params)
}

export default {
    getCrossProjectList,
    getOfficeIdProject
    
}
