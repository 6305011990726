var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "m_g" },
    [
      _c("div", { staticClass: "flex p-1" }, [
        _c(
          "div",
          { staticClass: "m_g_left public wapper" },
          [
            _c("form-index", {
              ref: "formIndex",
              attrs: {
                formData: _vm.formData,
                Overview: "基本信息",
                options: _vm.options,
              },
              on: {
                resourceUpload: _vm.resourceUpload,
                upRemove: _vm.upRemove,
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "wapper" }, [
          _c(
            "div",
            { staticClass: "m_g_right_bottom public" },
            [
              _c("form-index", {
                ref: "formIndex1",
                attrs: {
                  formData: _vm.formDataTop,
                  Overview: "账号信息",
                  options: _vm.options,
                },
                on: { handlSelect: _vm.handlSelect },
              }),
              _vm._m(0),
            ],
            1
          ),
        ]),
      ]),
      [
        _c(
          "div",
          { staticClass: "m_g_button" },
          [
            _c(
              "a-button",
              {
                attrs: { icon: "save", type: "primary" },
                on: { click: _vm.handleSubmit },
              },
              [_vm._v(" 提交 ")]
            ),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { color: "red" } }, [
      _vm._v(" 特别注意： "),
      _c("p", [
        _vm._v(
          " 1、更换岗位人员，请将原岗位人员先离场，然后新增管理人员，切勿直接在原有岗位上更换人员！ "
        ),
      ]),
      _c("p", [
        _vm._v(
          " 2、请务必确保手机号和身份证号是真实属于管理人员的个人信息，否则会造成管理员数据混乱！ "
        ),
      ]),
      _c("p", [
        _vm._v("3、需要上报住建局建设平台人员，务必所有信息填写正确！"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }