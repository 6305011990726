import { QUERY_OFFICE_BY_USER } from "@/services/api";
import { request } from "@/utils/request";
import { mapGetters } from "vuex";
import { ATTACHMENT_UPLOAD } from "@/services/api";

let options = [
  {label: "是",value: "1",},
  {label: "否",value: "0",},
];
// 工程类别id 1.深基坑工程2.模板工程及支撑体系3.起重吊装及安装拆卸工程4.脚手架工程5.拆除工程6.暗挖工程7.其他
const options1 = [
  { label: "深基坑工程",value: "1"},
  { label: "高边坡工程",value: "2"},
  { label: "模板工程及支撑体系",value: "3"},
  { label: "脚手架工程",value: "4"},
  { label: "暗挖工程",value: "5"},
  { label: "拆除工程",value: "6"},
  { label: "有限空间",value: "7"},
  { label: "人防工程",value: "8"},
  { label: "天面工程",value: "9"},
  { label: "幕墙工程",value: "10"},
  { label: "钢结构工程",value: "11"},
  { label: "水下作业",value: "12"},
  { label: "水上平台",value: "13"},
  { label: "钢便桥",value: "14"},
  { label: "临时码头",value: "15"},
  { label: "装配式安装工程",value: "16"},
  { label: "人工挖孔桩",value: "17"},
  { label: "其他",value: "18"},


];

const calcAttach = (item,key) => {
  let attachList = [];
  if (item[key]) {
      attachList = item[key].split(',');
  } 
  attachList = attachList.map((i) => {
      let type = "img";
      // if i 为图片
      if (i.indexOf(".jpg") > -1 || i.indexOf(".png") > -1 || i.indexOf(".jpeg") > -1 || i.indexOf(".gif") > -1 ) {
          type = "img";
      } else if (i.indexOf(".pdf") > -1) {
          type = "pdf";
      } else {
          type = "file";
      }


      return {
          url: i,
          type: type,
      };
  });
  return attachList;
}

export default {
  data() {
    let customRender = (text, record, index) => {
      const obj = {
          children: text,
          attrs: {},
      };
      obj.attrs.rowSpan = record.count1;
      
      return obj;
  }
  let customRender1 = (text, record, index) => {
      let str
      if (text == 1) {
        str = <a-tag color="#108ee9">是</a-tag>
      } else if (text == 0) {
        str = <a-tag color="#f50">否</a-tag>
      }

      const obj = {
          children: str,
          attrs: {},
      };
      obj.attrs.rowSpan = record.count1;
      return obj;
    }
    

  const columns = [
    {
        title: "序号",
        align: "center",
        scopedSlots: { customRender: "index" },
        width: 65,
    },
    {
        title: "公司",
        align: "center",
        dataIndex: "companyName",
        ellipsis: true,
        tooltip: true,
        width: 200,
        customRender
    },
    {
        title: "分公司",
        align: "center",
        dataIndex: "branchOfficeName",
        ellipsis: true,
        tooltip: true,
        width: 200,
        customRender
    },
    {
      title: "项目名称",
      dataIndex: "projectName",
      width: 120,
      align: "center",
      customRender
    },
    {
        title: "危大工程类别",
        dataIndex: "gclbName",
        width: 140,
        align: "center",
        customRender
  
    },
    {
        title: "填报时间",
        dataIndex: "ztbTime",
        width: 140,
        align: "center",
        customRender
  
    },
  
    {
        title: "工程名称",
        dataIndex: "sgfaName",
        ellipsis: true,
        tooltip: true,
        width: 200,
        customRender
  
    },
    {
        title: "是否超一定规模",
        dataIndex: "overRange",
        // scopedSlots: { customRender: "overRange" },
  
        align: "center",
        width: 100,
        customRender: customRender1
  
    },
    {
        title: "危大工程进度",
        dataIndex: "gcproessCode",
        // scopedSlots: { customRender: "gcproessCode" },
        align: "center",
        width: 100,
        customRender: (text, record, index) => {
          let str
          if (text == 1) {
            str = <a-tag color="#108ee9">未开工</a-tag>
          } else if (text == 2) {
            str = <a-tag color="#108ee9">持续中</a-tag>
          } else if (text == 3) {
            str = <a-tag color="#108ee9">已完工</a-tag>
          }
    
          const obj = {
              children: str,
              attrs: {},
          };
          obj.attrs.rowSpan = record.count1;
          return obj;
      }
    },
    {
        title: "计划实施时间",
        dataIndex: "planTime",
        align: "center",
        width: 120,
        customRender
    },
    {
        title: "专项施工方案",
        dataIndex: "zxsgfa",
        // scopedSlots: { customRender: "zxsgfa" },
  
        align: "center",
        width: 120,
        customRender: customRender1
        
    },
    {
        title: "危大工程内容",
        dataIndex: "conetent",
        align: "center",
        width: 220,
        ellipsis: true,
        tooltip: true,
    },
    {
      title: "填报时间",
      dataIndex: "dtbTime",
      width: 140,
      align: "center",
      // customRender
  
  },
    {
        title: "实施状态",
        dataIndex: "ipmlCode",
        scopedSlots: { customRender: "ipmlCode" },
  
        align: "center",
        width: 120,
    },
    {
        title: "验收情况",
        dataIndex: "ysqk",
        align: "center",
        width: 120,
        ellipsis: true,
        tooltip: true,
    },
    {
        title: "验收表",
        dataIndex: "attach1",
        scopedSlots: { customRender: "attach1" },
  
        align: "center",
        width: 120,
    },
    {
        title: "现场监督负责人",
        dataIndex: "managename",
        scopedSlots: { customRender: "managename" },
        align: "center",
        width: 140,
    },
    {
        title: "操作",
        align: "center",
        dataIndex: "action",
        width: 180,
        // scopedSlots: { customRender: "action" },
        fixed: "right",
        customRender: (text, record, index) => {
          return {
            children: (
              <div class="sTables">
                <div class="y-edit" onClick={()=>this.handleEdit(record)}>编辑</div>
                <div class="y-del" onClick={()=>this.handleDel(record)}>删除</div>
              </div>
            ),
            attrs: {
              rowSpan: record.count1,
            },
          };
        }
    },
  
   
  ];
  
    return {
        gcglbList: [],
        onlyGcglbList: [],
        options,
        options1,
        columns,

        filePath1: [],
        uploading1: false,
        accept1: "",
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
    isBranchOffice() { //分公司
        return this.user.isGcglb == 1 && this.user.loginType == 1
    },
    isProject() { //项目部
        return this.user.loginType == 0
    },
    isSafe() { //安全部
        return this.user.isGcglb == 0 && this.user.loginType == 1
    },
    isProjectLeader(){
        // user.nowRoleKey 包含 'project_leader'
        return this.user.nowRoleKey.includes('project_leader')
    },
    isProjectSafer() {
        // user.nowRoleKey 包含 'PROJECT_AQY_ZB_FZR'
        return this.user.nowRoleKey.includes('PROJECT_AQY_ZB_FZR')
    }
  },
  created() {
    request(QUERY_OFFICE_BY_USER, "get").then((res) => {
      let data = res.data.data;
      const arr1 = []
      data.forEach((item) => {
       
        arr1.push({
          label: item.master,
          value: item.id,
        });
      });

      this.onlyGcglbList = arr1;
    });
  },
  methods: {
    calcCount(list,item,index, paramKey) {
      
      let reCount;
      if (index > 0 && item[paramKey] === list[index - 1][paramKey]) {
          reCount = 0;
      } else {
          let count = 1;
          for (let i = index + 1; i < list.length; i++) {
              if (item[paramKey] === list[i][paramKey]) {
                  count++;
              } else {
                  break;
              }
          }
          reCount = count;
      }
      return reCount;
    
    },
    calcAttach,
    handlePreview(file) {
        window.open(file.url);
    },
    beforeUpload1(file) {
        // const accept = '.' + file.name.substring(file.name.lastIndexOf(".") + 1);
        // if (this.accept1.split(',').indexOf(accept) == -1) {
        //   this.$message.error("只能上传PDF文件");
        //   return false
        // }
      },
      handleUploadChange1(info) {
        if (info.file.status === "uploading") {
          this.uploading1 = true;
          return;
        }
        if (info.file.status === "done") {
          this.uploading1 = false;
        }
        if (info.file.status === "removed") {
          this.filePath1 = info.fileList
        }
      },
      resourceUpload1(pane) {
        return (value) =>{

          const formData = new FormData();
          formData.append("file", value.file);
          request(ATTACHMENT_UPLOAD, "post", formData).then((result) => {
            if (result.data.code === 0) {
              this.$message.success("上传成功!");
              pane.fileList.push({
                uid: value.file.uid,
                url: result.data.data.path,
                name: value.file.name,
                status: 'done',
              });
              value.onSuccess(result.data.data.path, value.file);
            } else {
              this.$message.warning(result.data.message);
            }
          });
        }
      },
      handleRemove1(pane) {
        return () => {
          pane.fileList = [];
        }
      }
  },
};
