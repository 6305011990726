<template>
    <div class="a_Approval">
        <a-form-model :model="params" class="clearfix" @submit="handleSubmit">
            <a-col :span="5">
                <a-form-model-item label="统计方式" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
                    <a-radio-group v-model="dateType">
                        <a-radio-button value="a">
                        月份
                        </a-radio-button>
                        <a-radio-button value="b">
                        年份
                        </a-radio-button>
                        
                    </a-radio-group>
                </a-form-model-item>
            </a-col>
            <a-col :span="4">
                <a-form-model-item :label="dateType== 'a'? '月份': '年份'" :labelCol="{ span: 4 }" :wrapperCol="{ span: 10, offset: 0 }">
                    <a-month-picker v-if="dateType == 'a'" style="width: 140px"  v-model="params.startDate">
                        
                    </a-month-picker>
                    <a-date-picker
                        v-else
                        mode="year"
                        v-model="params.startDate"
                        placeholder="请输入年份"
                        :format="'YYYY'"
                        style="width: 140px"
                        :open="isOpen"
                        @openChange="monthOpenChangeOne"
                        @panelChange="monthPanelChangeOne"/>
                </a-form-model-item>
            </a-col>



            <a-col :span="4">
                <a-space class="btnBox">
                    <a-button type="primary" html-type="submit"> 查询 </a-button>
                    <a-button @click="resetQuery"> 重置 </a-button>
                </a-space>
            </a-col>
        </a-form-model>
        <div class="mb-2" style="display:flex">
            <a-button v-if="isSafe" type="primary" class="mr-2" @click="addNewComplaint"> 新增 </a-button>
            <a-button type="primary" @click="handleDownload" class="mr-2"> 导出 </a-button>
            <a-button type="primary" class="mr-2" @click="bathDownload"> 批量下载 </a-button>
        </div>

        <a-tabs default-active-key="1" v-model="params.type" @change="changeTab">
            <a-tab-pane key="1" tab="公司">

            </a-tab-pane>
            <a-tab-pane key="2" tab="集团">

            </a-tab-pane>

        </a-tabs>
        <standard-table :columns="columns" :dataSource="tableData" rowKey="noticeid" :pagination="pagination"
            :loading="tableLoading" @change="handleChangePage" :isAlert="false" :bordered="true"
            :selectedRows.sync="selectedRows">
            <!-- @selectedRowChange="handleSelectedRowsChange" :totalBoolean="true" :rowColor="false" -->
            <template slot="index" slot-scope="{ index }">
                <span>{{
                    (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1
                }}</span>
            </template>
            <template slot="attach" slot-scope="{ record }">
                <!-- {{ record }} -->
                <div class="flex j-center">
                    <div v-for="(item, index) in record.attachList" :key="index">
                        <img alt="" style="width: 32px" v-if="item.type == 'img'" :src="item.url"
                            @click="handlePreview(item)" />

                        <a-icon type="file-pdf" :style="{ fontSize: '32px' }" v-else-if="item.type == 'pdf'"
                            @click="handlePreview(item)" />
                        <a-icon type="file-text" :style="{ fontSize: '32px' }" v-else @click="handlePreview(item)" />
                    </div>
                </div>
            </template>
            <template slot="action" slot-scope="{ record }">
                <div class="sTables">
                    <div @click="handleEdit(record)">编辑</div>
                    <div @click="handleDel(record)" v-show="!$route.meta.isBreak">
                        删除
                    </div>
                </div>
            </template>
        </standard-table>
    </div>
</template>

<script>
import {
    noticefileList,
    noticefileDelete,
    noticefileBatchDownload,
    noticefileExport,
} from "@/services/specificatioNotification";
import { mapGetters } from "vuex";
import StandardTable from "@/components/table/StandardTable";
import moment from "moment";
import { request } from "@/utils/request";
import { ATTACHMENT_UPLOAD } from "@/services/api";
const columns = [
    {
        title: "序号",
        align: "center",
        scopedSlots: { customRender: "index" },
        width: 65,
    },
    {
        title: "通知名称",
        // align: "center",
        dataIndex: "noticename",
        // ellipsis: true,
        // tooltip: true,
        // width: 250,
    },

    
    {
        title: "日期",
        dataIndex: "pubdate",
        width: 220,
        align: "center",
    },
    {
        title: "编号",
        dataIndex: "no",
        width: 350,
        align: "center",
    },

    {
        title: "附件",
        dataIndex: "attach",
        scopedSlots: { customRender: "attach" },
        align: "center",
        width: 220,
    },

    {
        title: "操作",
        align: "center",
        width: 120,
        scopedSlots: { customRender: "action" },
    },
];

export default {
    components: {
        StandardTable,
    },
    data() {
        return {
            dateType: "a",
            params: {
                "name": "", //通知文件名称模糊查询
                "type": "1", //1.公司 2.集团
                "startDate": "",
                page: 1,
                limit: 10,

            },
            isOpen: false,
            pagination: {
                //分页数据
                current: 1,
                pageSize: 10,
                total: 0,
                showTotal: (total, range) => `共 ${total} 条`,
            },
            yearShowOne: false,
            total: 0,
            columns: columns,
            tableData: [],
            tableLoading: false, //table加载中
            selectedRows: [],
            tmpSelectedRows: [],

            accept: "",
            filePath: [],
            uploading: false,
        };
    },
    created() {
        if (!this.isSafe) {
            this.columns = this.columns.filter((el) => el.title != "操作");
        }
        this.handleQuery();
    },
    activated() {
        this.handleQuery();
    },
    computed: {
        ...mapGetters("account", ["user"]),
        isBranchOffice() {
            //分公司
            return this.user.isGcglb == 1 && this.user.loginType == 1;
        },
        isProject() {
            //项目部
            return this.user.loginType == 0;
        },
        isSafe() {
            //安全部
            return this.user.isGcglb == 0 && this.user.loginType == 1;
        },
        tableBtnArr() {
            return [
                {
                    name: "新增",
                    attrs: {
                        type: "primary",
                    },
                    hidden: !this.isSafe,
                    click: this.addNewComplaint,
                },
                {
                    name: "批量下载",
                    attrs: {
                        type: "primary",
                    },
                    // hidden: !this.isJSOffice,
                    click: this.bathDownload,
                },
            ];
        },
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault();
            this.params.page = 1;
            this.pagination.current = 1;
            this.handleQuery();
        },
        changeTab() {
            this.params.page = 1;
            this.pagination.current = 1;
            this.handleQuery();
        },
        handleQuery(e) {
            this.tableData = [];
            this.tableLoading = true;
            let d = {
                ...this.params,

            };
            if(d.startDate) {

                if (this.dateType == "a") {
                    d.startDate = moment(d.startDate).format("YYYY-MM");
                } else {
                    d.startDate = moment(d.startDate).format("YYYY");
                }
            }
            noticefileList(d).then((res) => {
                this.tableLoading = false;
                if (res.data.code === 200) {
                    let list = res.data.data.list;

                    this.tableData = list.map((item) => {
                        let attachList = [];
                        if (item.attach) {
                            attachList = item.attach.split(",");
                        }
                        attachList = attachList.map((i) => {
                            let type = "img";
                            // if i 为图片
                            if (
                                i.indexOf(".jpg") > -1 ||
                                i.indexOf(".png") > -1 ||
                                i.indexOf(".jpeg") > -1 ||
                                i.indexOf(".gif") > -1
                            ) {
                                type = "img";
                            } else if (i.indexOf(".pdf") > -1) {
                                type = "pdf";
                            } else {
                                type = "file";
                            }

                            return {
                                url: i,
                                type: type,
                            };
                        });
                        return {
                            ...item,
                            key: item.noticeid,
                            attachList: attachList,
                        };
                    });
                    this.pagination.total = res.data.data.totalCount;
                } else {
                    this.$message.error(res.data.msg);
                }
            });
        },
        handleChangePage(e) {
            this.params.page = e.current;
            this.pagination.current = e.current;
            this.handleQuery();
        },
        addNewComplaint() {
            this.$router.push({
                name: `通知文件管理详情`,
                query: {
                    isEdit: 0,
                    type: this.params.type,
                },
            });
        },
        handleEdit(e) {
            this.$router.push({
                name: `通知文件管理详情`,
                query: {
                    id: e.noticeid,
                    isEdit: 1,
                    type: this.params.type,

                },
            });
        },
        handleDel(record) {
            const _that = this;
            this.$confirm({
                title: "是否确定删除？",
                cancelText: "否",
                okText: "是",
                onOk: () => {
                    noticefileDelete({ id: record.noticeid }).then((res) => {
                        if (res.data.code === 200) {
                            _that.$message.success("删除成功");
                            this.handleQuery(this.params);
                        } else {
                            _that.$message.error(res.data.msg);
                        }
                    });
                },
            });
        },
        resetQuery() {
            this.pagination.pageNumber = 1
            this.params = {
                "name": "", //通知文件名称模糊查询
                "type": "1", //1.公司 2.集团
                "startDate": "",
                page: 1,
                limit: 10,

            }
            this.handleQuery();
        },
        bathDownload() {
            if (this.selectedRows.length == 0) {
                this.$message.warning("请选择文件！");
            } else {
                let arr = [];
                this.selectedRows.forEach((el) => {
                    arr.push(el.noticeid);
                });
                let url = noticefileBatchDownload + `?ids=${arr.join(",")}`;
                window.open(url);
            }
        },
        handleDownload() {
            
            window.open(noticefileExport+"?type="+this.params.type);
        },
        handlePreview(file) {
            window.open(file.url);
        },
        monthOpenChangeOne(status) {
            if (status) {
            this.isOpen = true;
            }
        },
        monthPanelChangeOne(value) {
            this.isOpen = false;
            this.params.startDate = value;
            
        }


    },
};
</script>

<style lang="less" scoped>
.query-form {
    display: flex;
    // justify-content: space-between;
}

.post-border {
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 5px;
    padding-top: 5px;
    // padding-top: 10px;
    // height: 40px;
}

.post-border:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

.sTables {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
        width: 100%;
        text-align: center;
        cursor: pointer;
    }

    div:nth-child(1) {
        color: #307dfa;
    }

    div:nth-child(2) {
        color: #ff5c77;
    }

    div:nth-child(3) {
        color: #615e83;
    }
}
</style>
