var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-config-provider",
    {
      staticStyle: { height: "100%" },
      attrs: { locale: _vm.locale, "get-popup-container": _vm.popContainer },
    },
    [
      _c(
        "div",
        { on: { mouseover: _vm.cmouseovered } },
        [_c("router-view", { staticStyle: { height: "100%" } })],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }