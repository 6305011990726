<template>
  <a-card>
    <a-spin :spinning="spinning" :tip="loadingText">
    <div :class="advanced ? 'search' : null">
      <a-form layout="horizontal">
        <div :class="advanced ? null: 'fold'">
          <a-row >
          <a-col :md="8" :sm="24" >
            <a-form-item
              label="公司名称"
              :labelCol="{span: 6}"
              :wrapperCol="{span: 16, offset: 1}"
            >
              <a-input placeholder="请输入" v-model="queryParams.officeName"/>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24" >
            <a-form-item
              label="班组名称"
              :labelCol="{span: 6}"
              :wrapperCol="{span: 16, offset: 1}"
            >
              <a-input placeholder="请输入" v-model="queryParams.teamName"/>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24" >
            <a-form-item
              label="退场时间"
              :labelCol="{span: 6}"
              :wrapperCol="{span: 16, offset: 1}"
            >
              <a-range-picker v-model="queryParams.inDate" />
            </a-form-item>
          </a-col>
        </a-row>
        </div>
        <span style="float: right; margin-top: 3px;">
          <a-button icon="search" type="primary" @click="handleSubmit">查询</a-button>
          <a-space class="operator">
            <a-button style="margin-left: 8px" @click="doReset()">重置</a-button>
          </a-space>

        </span>
      </a-form>
    </div>
    <div>
      <a-space class="operator">
        <a-button icon="download" @click="doDowloadBatch()">批量下载</a-button>
        <span><strong>温馨提示</strong>：<span style="color:blue;font-size:13px">批量下载只会下载已经确认了的退场确认单，请您选择已确认的退场单进行下载，非常感谢您的支持！</span></span>
      </a-space>
      <standard-table
        :columns="columns"
        :dataSource="dataSource"
        :selectedRows.sync="selectedRows"
        :pagination="pagination"
        :loading="tableLoading"
        :checkBoxDisable="checkBoxDisable"
        @clear="onClear"
        @change="onPageChange"
        :bordered="true"
      >
        <div slot="safediscloseFileName" slot-scope="{text}">
          <div v-if="text!=null&&text!=''">{{text}}</div>
          <a-icon v-else style="color:red" type="close" />
        </div>
        <div slot="do-status" slot-scope="{text}">
          <!-- <a-icon v-if="text==1" style="color:green" type="check" />
          <a-icon v-else style="color:red" type="close" /> -->
          <span v-if="text == 1"><i class="iconfont duigouxiao" style="color:#00CE86"></i></span>
          <span v-else><i class="iconfont path" style="color:#FF5C77"></i></span>
        </div>
        <div slot="action" slot-scope="{text, record}">
          <!-- <router-link :to="`/list/query/detail/${record.key}`" >打包下载资料</router-link> -->
          <a @click="doDowload(`${record.key}`,`${record.userName}`)">打包下载</a>
          <!-- <a-divider type="vertical" />
          <a @click="preview">预览</a> -->
        </div>
        <template slot="statusTitle">
          <a-icon @click.native="onStatusTitleClick" type="info-circle" />
        </template>
      </standard-table>
    </div>
    <Export-loading :cPercent="cPercent" :isExporting="isExporting" :loadingText="loadingText"></Export-loading>
    </a-spin>
    <a-modal
        :destroyOnClose="true"
        :visible="modalVisible"
        @cancel="handleCancel"
      >
      <div ref="previewContainer"></div>
      </a-modal>
  </a-card>
</template>

<script>
// import {renderAsync} from 'docx-preview';
import StandardTable from '@/components/table/StandardTable'
import {
  STAFF_INOUT_QUERY,
  STAFF_INOUT_EXPORT
  } from '@/services/api'

import { exportProgress } from '@/pages/aqjyzl/export' // 导出方法
import ExportLoading from '@/components/ExportLoading/ExportLoading' // progress组件
import {request} from '@/utils/request'
import { te } from 'date-fns/locale'
// import axios from '@/utils/axios'
import { useRender } from '@/hooks/useRender.js'

const columns = [
  {
    title: '班组名称',
    dataIndex: 'teamName',
    align: 'center',
    scopedSlots: { customRender: 'teamName' }
  },
  {
    title: '公司名称',
    dataIndex: 'officeName',
    align: 'center',
    scopedSlots: { customRender: 'officeName' }
  },
  {
    title: '入场人数',
    dataIndex: 'persons',
    align: 'center',
    width:95,
   scopedSlots: { customRender: 'persons' }
  },
  {
    title: '人员姓名',
    dataIndex: 'userNames',
    align: 'center',
    scopedSlots: {customRender: 'userNames'},
    ellipsis:true
  },
  {
    title: '退场时间',
    dataIndex: 'inOutTimes',
    align: 'center',
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: '确认状态',
    dataIndex: 'isSure',
    width:95,
    align: 'center',
    scopedSlots: {customRender: 'do-status'}
  },
  {
    title: '操作',
    align: 'center',
    width:140,
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  mixins: [exportProgress],
  name: 'QueryList',
  components: {StandardTable,ExportLoading},
  data () {
    return {
      dataSource:[],
      checkBoxDisable:{key:'isSure',value:0},
      advanced: false,
      columns: columns,
      selectedRows: [],
      tableLoading: false,
      spinning:false,
      loadingText:"数据加载中...",
      loadKeys:[],
      queryParams:{
        teamName:null,
        officeName:null,
        startTime:null,
        endTime:null,
        inDate:null,
        pageNumber:1,
        type:2
      },
      pagination:{
        current:1,
        pageSize:10,
        total:0,
        showLessItems:true,
        showQuickJumper:true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      cPercent:0,
      isExporting:false,
      fileName:'退场确认单',
      modalVisible: false
    }
  },
  watch: {
    cPercent:{
      handler(newName, oldName) {
        this.cPercent=newName;
      },
      immediate: true,
    },
    loadingText:{
      handler(newName, oldName) {
        this.loadingText=newName;
      },
      immediate: true,
    }
  },
  created() {
    this.doQuery();
  },
  authorize: {
    deleteRecord: 'delete'
  },
  methods: {
    handleSubmit() {
      this.queryParams.pageNumber = 1
      this.pagination.current = 1
      this.doQuery();
    },
    deleteRecord(key) {
      this.dataSource = this.dataSource.filter(item => item.key !== key)
      this.selectedRows = this.selectedRows.filter(item => item.key !== key)
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    remove () {
      this.dataSource = this.dataSource.filter(item => this.selectedRows.findIndex(row => row.key === item.key) === -1)
      this.selectedRows = []
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },

    /**
     * 获取所有人员的签名记录信息
     */
    doQuery(){
      this.tableLoading = true;
      if(this.queryParams.inDate){
        this.queryParams.startTime = this.queryParams.inDate[0].format('YYYY-MM-DD')
        this.queryParams.endTime = this.queryParams.inDate[1].format('YYYY-MM-DD')
      }
      request(STAFF_INOUT_QUERY,"post",this.queryParams).then(res => {
        if(res.data.code==0){
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
          this.dataSource.forEach(obj=>{
            obj.persons = obj.userNames.split(',').length;
            obj.key = obj.id;
          })
        }
        this.tableLoading = false;
      })
    },
    doReset(){
      this.queryParams={
        teamName:null,
        officeName:null,
        startTime:null,
        endTime:null,
        inDate:null,
        pageNumber:1,
        type:2
      };
      this.pagination.current = 1
      this.doQuery();
    },
    //分页查询
    onPageChange(e){
      this.queryParams.pageNumber = e.current;
      this.pagination.current=e.current;
      this.doQuery();
    },
    /**
     * 批量下载
     */
    doDowloadBatch(){
      this.loadingText = "正在打包退场确认单文件，请稍候 ····";
      this.loadKeys = [];
      if(this.selectedRows&&this.selectedRows.length>0){
        this.selectedRows.map((item) => {
          if(item.isSure==1){
            this.loadKeys.push(item.key);
          }
        })
        if(this.loadKeys.length<=0){
          this.$message.error("您选择的确认单，都没有确认，请重新选择！");
          return false;
        }
        this.isExporting=true;
        this.cPercent=0;
        let fileName = ""
        fileName="退场确认单下载.zip";
        this.exportFunNomal(STAFF_INOUT_EXPORT,this.loadKeys,fileName)
      }else{
        this.$message.info('请选中需要导出的确认单进行下载');
        return;
      }
    },
    /**
     * 单个单个的下载
     */
    doDowload(key){
      let rows = this.dataSource.filter(item => item.key == key&&item.isSure == 1);
      if(!rows||rows.length<=0){
        this.$message.error("该进场确认单没有确认，无法下载！");
          return false;
      }
      this.loadingText = "正在打包文件，请稍候 ····";
      this.loadKeys = [];
      this.loadKeys.push(key);
      this.isExporting=true;
      this.cPercent=0;
      let fileName = ""
      fileName="退场确认单下载.zip";
      
      this.exportFunNomal(STAFF_INOUT_EXPORT,this.loadKeys,fileName)
    },
    handleMenuClick (e) {
      if (e.key === 'delete') {
        this.remove()
      }
    },
    preview() {
      this.modalVisible = true;
      // 调用后端接口获取文件流
      request(STAFF_INOUT_EXPORT,'post',[123530]).then(res => {
        console.log(res,'res====')
        const fileData = res.data;
        const fileurl = 'http://static.shanhuxueyuan.com/test6.docx'
        const byteCharacters = atob(fileurl.split(',')[1])
        const byteNumbers = new Array(byteCharacters.length)
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)
        // 将文件流转换为 Blob 对象
        const blob = new Blob([fileData]);
        console.log(blob)
        // 创建 DocxPreview 实例并指定预览容器
        // const preview = new DocxPreview(blob,this.$refs.previewContainer);
        // 加载并预览文件
      //   renderAsync(byteArray, this.$refs.previewContainer, null, {
      //   className: "docx", //默认和文档样式类的类名/前缀
      //   inWrapper: true, //启用围绕文档内容呈现包装器
      //   ignoreWidth: false, //禁用页面的渲染宽度
      //   ignoreHeight: false, //禁用页面的渲染高度
      //   ignoreFonts: false, //禁用字体渲染
      //   breakPages: true, //在分页符上启用分页
      //   ignoreLastRenderedPageBreak: true, //在lastRenderedPageBreak元素上禁用分页
      //   experimental: false, //启用实验功能（制表符停止计算）
      //   trimXmlDeclaration: true, //如果为true，则在解析之前将从xml文档中删除xml声明
      //   useBase64URL: false, //如果为true，图像、字体等将转换为base 64 URL，否则使用URL.createObjectURL
      //   useMathMLPolyfill: false, //包括用于铬、边等的MathML多填充。
      //   showChanges: false, //启用文档更改的实验渲染（插入/删除）
      //   debug: false, //启用额外的日志记录
      // }).then(result => {
      //   console.log(result,'result')
      // })
        // console.log(preview,'blob')
        // preview.load(blob);
      })
    },
    handleCancel() {
      this.modalVisible = false;
      // this.previewImage = "";
      // this.teamName = ''
    },
  }
}
</script>

<style lang="less" scoped>
  .search{
    margin-bottom: 54px;
  }
  .fold{
    width: calc(100% - 216px);
    display: inline-block
  }
  .operator{
    margin-bottom: 18px;
  }
  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
</style>
