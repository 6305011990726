<template>
  <div>
    <div class="a_Approval" v-show="$route.meta.showFather">
      <a-tabs :default-active-key="defaultActiveKey">
        <a-tab-pane key="1" tab="被投诉">
          <complaint-list></complaint-list>
        </a-tab-pane>
        <a-tab-pane key="2" tab="被约谈">
          <interview-List></interview-List>
        </a-tab-pane>
      </a-tabs>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import ComplaintList from './complaintList.vue';
import InterviewList from './interViewList.vue';
export default {
  components: {
    ComplaintList,
    InterviewList
  },
  data() {
    return {
      defaultActiveKey: "1",
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>

<style lang="less" scoped>

</style>