<template>
  <div class="a_Approval">
    <div class="plug-Overview">预警设备汇总表</div>
    <a-divider />
    <!-- 表单封装 
            projectObj：下拉弹出框第一项对象
            Overview：项目名称
            baiduMapBoolen：控制地图显示
            topSubmit：控制上部分按钮显示
            bottomSubmit：控制下部分按钮显示
            handleSubmit：表单提交
            handleChange：输入出发
            handlSelect：输入下拉选择触发
        -->
    <form-index
      ref="formindex"
      :formData="formData"
      flexAction
      :baiduMapBoolen="false"
      @handleSubmit="handleSubmit"
    >
      <!-- 按钮插槽 -->
      <template #flexAction>
        <a-space class="btnBox">
          <a-button type="primary" html-type="submit">查询</a-button>
        </a-space>
      </template>
    </form-index>

    <!-- 表格封装 -->
    <standard-table
      :columns="columns"
      :dataSource="dataSource"
      rowKey="id"
      :pagination="pagination"
      :loading="tableLoading"
      @change="onPageChange"
      :isAlert="false"
      :bordered="true"
      :scroll="{ x: '100%' }"
      :btnArr="tableBtnArr"
    >
      <template slot="index" slot-scope="{ index }">
        <span>
          {{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}
        </span>
      </template>
      <template slot="usingEndTime" slot-scope="{ record }">
        <span :style="dateColor(record.usingEndTime)">{{ useRender.renderDate(record.usingEndTime) }}</span>
      </template>
      <div slot="action" slot-scope="{ record }">
        <div class="sTables">
          <div @click="visit(record)">查看</div>
        </div>
      </div>
    </standard-table>
    <Export-loading
      :cPercent="cPercent"
      :isExporting="isExporting"
      :loadingText="loadingText"
    ></Export-loading>
  </div>
</template>

<script>
// AQXJPROJECTSGETLIST
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import { getWarningList } from "@/services/equipmentLedger";
import { request } from "@/utils/request";
import { exportProgress } from "@/pages/aqjyzl/export"; // 导出方法
import ExportLoading from "@/components/ExportLoading/ExportLoading"; // progress组件
import { QUERY_OFFICE_BY_USER, FACILITY_INFO_WARNING_EXPORT } from "@/services/api";
import moment from "moment";
import { useRender } from '@/hooks/useRender.js'

const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
  },
  {
    title: "项目名称",
    dataIndex: "projectName",
    width: 220,
    ellipsis: true,
    tooltip: true
  },

  {
    title: "工程管理部",
    dataIndex: "gcglbName",
    align: "center",
    width: 105,
    scopedSlots: { customRender: "gcglbName" },
  },
  {
    title: "设备类型",
    dataIndex: "facilityTypeName",
    align: "center",
    width: 110,
    scopedSlots: { customRender: "facilityTypeName" },
  },
  {
    title: "设备型号",
    align: "center",
    width: 110,
    dataIndex: "facilityNum",
    ellipsis: true,
    tooltip: true
  },
  {
    title: "自编号",
    dataIndex: "selfNum",
    width: 80,
    ellipsis: true,
    align: "center",
    scopedSlots: { customRender: "selfNum" },
  },
  {
    title: "产权登记编号",
    dataIndex: "equityNum",
    align: "center",
    width: 160,
    scopedSlots: { customRender: "equityNum" },
  },
  {
    title: "检测单位名称",
    dataIndex: "checkUnit",
    align: "center",
    width: 120,
    key: "checkUnit",
    ellipsis: true,
    tooltip: true
  },
  {
    title: "设备检验报告编号",
    dataIndex: "checkReportNum",
    align: "center",
    width: 130,
    key: "checkReportNum",
    ellipsis: true,
    tooltip: true
  },
  {
    title: "最新检验日期",
    dataIndex: "checkDate",
    width: 130,
    align: "center",
    key: "checkDate",
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "使用登记证编号",
    dataIndex: "usingNum",
    width: 110,
    align: "center",
    key: "usingNum",
    ellipsis: true,
    tooltip: true
  },
  {
    title: "登记证有效截止日期",
    dataIndex: "usingEndTime",
    align: "center",
    width: 130,
    key: "usingEndTime",
    scopedSlots: { customRender: "usingEndTime" },
  },
  {
    title: "状态",
    dataIndex: "status",
    align: "center",
    width: 90,
    key: "status",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "操作",
    align: "center",
    width: 65,
    flex: 'right',
    scopedSlots: { customRender: "action" },
  },
];
const formData = [
  {
    label: "工程名称",
    placeholder: "请选择工程名称",
    decorator: [
      "projectName",
      { rules: [{ required: false, message: "请选择工程名称" }] },
    ],
    type: "input",
    key: "projectName",
    col: 5,
    labelCol: 8,
    wrapperCol: 14,
  },
  {
    label: '工程管理部',
    placeholder: '请选择工程管理部',
    decorator: ['gcglbId', { rules: [{ required: false, message: '请选择工程管理部' }] }],
    type: 'select',
    key: 'gcglbId',
    selectlist: [],
    qb:true,
    col: 5,
    labelCol: 8,
    wrapperCol:14,
  },
  {
    label: "设备类型",
    placeholder: "请选择设备类型",
    decorator: [
      "facilityType",
      { rules: [{ required: false, message: "请选择设备类型" }] },
    ],
    type: "select",
    key: "facilityType",
    col: 5,
    labelCol: 8,
    wrapperCol: 14,
    selectlist: [
      {
        value: 1,
        name: "塔式起重机",
      },
      {
        value: 2,
        name: "施工升降机",
      },
      {
        value: 3,
        name: "物料提升机",
      },
      {
        value: 4,
        name: "门式起重机",
      },
      {
        value: 5,
        name: "桥式起重机",
      },
    ],
  },
  {
    label: "状态",
    placeholder: "请选择状态",
    decorator: [
      "status",
      { rules: [{ required: false, message: "请选择状态" }] },
    ],
    type: "select",
    key: "status",
    col: 4,
    labelCol: 7,
    wrapperCol: 14,
    selectlist: [
      {
        value: 0,
        name: "使用中",
      },
      {
        value: 1,
        name: "取证中",
      },
      {
        value: 2,
        name: "停用",
      },
      {
        value: 3,
        name: "待安装",
      },
    ],
  },
  {
    type: 'action',
    col: 2
  }
];
export default {
  mixins: [exportProgress], //导出方法注入
  components: {
    formIndex, //form表单封装
    StandardTable, //表格封装
    ExportLoading, //导出数据加载封装
  },
  data() {
    return {
      columns: columns, //表格数据
      dataSource: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      tableLoading: false,
      formData: formData,
      prams: {
        pageNumber: 1,
        pageSize: 10,
      },
      loadingText: "数据加载中...",
      cPercent: 0,
      isExporting: false,
      tableBtnArr: [
        {
            name: '导出',
            attrs: {
                type: 'primary'
            },
            click: this.doDowload
        }
      ],
      useRender
    };
  },
  watch: {
    loadingText: {
      handler(newName, oldName) {
        this.loadingText = newName;
      },
      immediate: true,
    },
  },
  activated() {
    this.getgcglbList(QUERY_OFFICE_BY_USER);
    this.aqxjlist(this.prams);
  },
  methods: {
    /**
     * 导出excel
     */
    doDowload() {
      let obj = this.$refs["formindex"].getFieldValue();
      obj.queryType = this.prams.queryType;
      this.loadingText = "正在导出EXCEL文件，请稍候 ····";
      this.isExporting = true;
      this.cPercent = 0;
      let fileName = "预警设备汇总表.xlsx";
      this.exportFunNomal2(
        FACILITY_INFO_WARNING_EXPORT,
        obj,
        fileName,
        "application/vnd.ms-excel"
      );
    },
    dateColor(date) {
      if (moment(new Date()).diff(moment(date),'days') > -30) {
        return 'color: red'
      }
    },
    aqxjlist(prams) {
      this.tableLoading = true;
      getWarningList(prams).then((res) => {
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list;
          this.dataSource.forEach(el => {
            if (el.status == 0) {
              el.status = '使用中'
            } else if (el.status == 1) {
              el.status = '取证中'
            } else if (el.status == 2) {
              el.status = '停用'
            } else if (el.status == 3) {
              el.status = '待安装'
            }
          })
          this.pagination.total = res.data.data.total;
        }
        this.tableLoading = false;
      });
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      let arr = [];
      data.forEach((item) => {
        arr.push({ name: item.master, value: item.id });
      });
      this.formData.forEach((item) => {
        if (item.key == key) {
          item.selectlist = arr;
        }
      });
    },
    // 获取工程管理部数据
    getgcglbList(path) {
      request(path, "get").then((res) => {
        let data = res.data.data.filter(
          (item) => item.id != "3da0f5533f87425c9a9e8a11a7923300"
        );
        this.formDataFn("gcglbId", data);
      });
    },
    // 提交表单数据
    handleSubmit(e) {
      this.prams.pageNumber = 1
      this.pagination.current = 1;
      let obj = Object.assign({}, e, this.prams);
      this.aqxjlist(obj);
    },
    //表格 - 分页查询
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      this.prams.pageNumber = e.current;
      this.pagination.current = e.current;
      this.aqxjlist(Object.assign({}, this.prams, obj));
    },
     //查看
     visit(record) {
      let name = ''
      switch(record.facilityType) {
        case 1: {
           name = "塔式起重机详情"
          break
        }
        case 2: {
          name = "施工升降机详情"
          break
        }
        case 3: {
          name = "物料提升机详情"
          break
        }
        case 4: {
          name = "门式起重机详情"
          break
        }
        case 5: {
          name = "桥式起重机详情"
          break
        }
      }
      this.$router.push({
        name,
        query: {
          id: record.id,
          facilityType: record.facilityType
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>

/deep/ .ant-table-thead > tr > th {
  font-weight: bold;
}

.sTables {
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    width: 100%;
    text-align: center;
    cursor: pointer;
  }

  div:nth-child(1) {
    color: #307dfa;
  }

  div:nth-child(2) {
    color: #ff5c77;
  }

  div:nth-child(3) {
    color: #615e83;
  }
}

.sTable {
  display: flex;
  justify-content: space-between;
  align-items: center;

  // div {
  //     width: 100%;
  //     color: blue;
  //     text-align: center;
  //     cursor: pointer;
  // }
}
</style>
