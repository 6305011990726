var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a_Approval" },
    [
      _c("div", { staticClass: "plug-Overview" }, [_vm._v("安全隐患排查周报")]),
      _c("a-divider"),
      _c(
        "a-spin",
        { attrs: { spinning: _vm.spinning, tip: _vm.loadingText } },
        [
          _c(
            "div",
            { class: _vm.advanced ? "search" : null },
            [
              _c(
                "a-form",
                { staticClass: "clearfix" },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: "选择日期",
                            labelCol: { span: 7 },
                            wrapperCol: { span: 16, offset: 1 },
                          },
                        },
                        [
                          _c("a-range-picker", {
                            attrs: { format: "yyyy-MM-DD" },
                            on: { change: _vm.onChange },
                            model: {
                              value: _vm.queryParams.plan_date,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "plan_date", $$v)
                              },
                              expression: "queryParams.plan_date",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "a-space",
                        { staticClass: "btnBox" },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.doQuery()
                                },
                              },
                            },
                            [_vm._v("查询")]
                          ),
                          _c(
                            "a-button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.doReset()
                                },
                              },
                            },
                            [_vm._v("重置")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("standard-table", {
                attrs: {
                  columns: _vm.columns,
                  dataSource: _vm.dataSource,
                  selectedRows: _vm.selectedRows,
                  pagination: _vm.pagination,
                  loading: _vm.tableLoading,
                  isAlert: false,
                  bordered: true,
                  btnArr: _vm.tableBtnArr,
                },
                on: {
                  "update:selectedRows": function ($event) {
                    _vm.selectedRows = $event
                  },
                  "update:selected-rows": function ($event) {
                    _vm.selectedRows = $event
                  },
                  change: _vm.onPageChange,
                },
                scopedSlots: _vm._u([
                  {
                    key: "index",
                    fn: function (ref) {
                      var index = ref.index
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              (_vm.pagination.current - 1) *
                                _vm.pagination.pageSize +
                                parseInt(index) +
                                1
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "action",
                    fn: function (ref) {
                      var record = ref.record
                      return _c("div", {}, [
                        _c("a", { attrs: { href: record.filePath } }, [
                          _vm._v("下载"),
                        ]),
                      ])
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }