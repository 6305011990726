<template>
  <a-card>
    <div :class="advanced ? 'search' : null">
        <a-form layout="horizontal">
          <div :class="advanced ? null: 'fold'">
            <a-row >
            <a-col :md="8" :sm="24" >
              <a-form-item  :labelCol="{span: 5}"
                :wrapperCol="{span: 18, offset: 1}" label="项目所属公司">
                <a-tree-select
                  style="width: 80%"
                  v-model="queryParams.projectOfficeId"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  :tree-data="officeTreeData"
                  placeholder="请选择公司"
                  tree-default-expand-all
                  :replaceFields="{children:'children', title:'name', key:'id', value:'id' }"
                  :treeDefaultExpandAll="false"
                  @select="currentOfficeId"
                  :allowClear="true"
                >
                </a-tree-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24" >
              <a-form-item
                label="检查时间"
                :labelCol="{span: 5}"
                :wrapperCol="{span: 18, offset: 1}"
              >
                <a-range-picker  v-model="queryParams.plan_date"  :format="dateFormat"  @change="changeDate"/>
              </a-form-item>
            </a-col>

            <a-col :md="8" :sm="24" >
              <a-form-item
                label="项目名称"
                :labelCol="{span: 5}"
                :wrapperCol="{span: 18, offset: 1}"
              >

                <a-input v-model="queryParams.projectName"/>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row >
            <a-col :md="8" :sm="24" >
              <a-form-item
                label="检查类型"
                :labelCol="{span: 5}"
                :wrapperCol="{span: 18, offset: 1}"
              >
                <a-select v-model="queryParams.planType">
                  <a-select-option value="1">
                    公司常规检查
                  </a-select-option>
                  <a-select-option value="3">
                    带班检查
                  </a-select-option>
                  <a-select-option value="4">
                    停工处罚
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24" >
              <a-form-item
                label="检查状态"
                :labelCol="{span: 5}"
                :wrapperCol="{span: 18, offset: 1}"
              >

                <a-select v-model="queryParams.status">
                  <a-select-option value="0">
                    待检查/待处理
                  </a-select-option>
                  <a-select-option value="1">
                    待复核
                  </a-select-option>
                  <a-select-option value="3">
                    整改中
                  </a-select-option>
                  <a-select-option value="2">
                    已完成
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          </div>
          <span style="float: right; margin-top: 3px;">
            <a-button type="primary" @click="doQuery()">查询</a-button>
            <a-button style="margin-left: 8px" @click="doReset()">重置</a-button>
          </span>
        </a-form>
      </div>
      <standard-table
            :columns="columns"
            :dataSource="dataSource"
            :selectedRows.sync="selectedRows"
            :pagination="pagination"
            :loading="tableLoading"
            :isAlert="false"
            @clear="onClear"
            @change="onPageChange"
          >
            <div slot="planType" slot-scope="{text}">
              <div v-if="text=='4'">停工处罚</div>
              <div v-else-if="text=='3'">带班检查</div>
              <div v-else>公司常规检查</div>
            </div>
            <div slot="do-status" slot-scope="{record,text}">
              <label v-if="record.planType==4&&text==0">待处理</label>
              <label v-if="(record.planType==1||record.planType==3)&&text==0">待检查</label>
              <label v-if="text==1">待复核</label>
              <label v-if="text==2">已完成</label>
              <label v-if="text==3">整改中</label>
            </div>
            <div slot="action" slot-scope="{record}">
              <a @click="dowLoadFile(record)">下载文件</a>
            </div>
            <template slot="statusTitle">
              <a-icon @click.native="onStatusTitleClick" type="info-circle" />
            </template>
          </standard-table>
          <!-- <detail
            :visible="detail.visible"
            :projectId="detail.projectId"
            :officeName="detail.officeName"
            :officeId="detail.officeId"
            :projectName="detail.projectName"
            :times="detail.times"
            @close="doClose"
          ></detail> -->
           <Export-loading :cPercent="cPercent" :isExporting="isExporting" :loadingText="loadingText"></Export-loading>
  </a-card>
</template>

<script>
import StandardTable from '@/components/table/StandardTable'
import {AQXJ_QUERY_GETLIST, GET_OFFICE_TREE_BY_EPIDEMIC,SAFE_VIEW_DOWNLOAD} from '@/services/api'
import { exportProgress } from '@/pages/aqjyzl/export' // 导出方法
import ExportLoading from '@/components/ExportLoading/ExportLoading' // progress组件
import {request} from '@/utils/request'
import {mapGetters} from 'vuex'
import moment from 'moment';
import { useRender } from '@/hooks/useRender.js'


  const columns = [

  {
    title: '检查时间',
    dataIndex: 'xjTime',
    align: 'center',
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: '检查类型',
    dataIndex: 'planType',
    align: 'center',
    scopedSlots: { customRender: 'planType' }
  },
  {
    title: '检查单位',
    dataIndex: 'officeName',
    align: 'left',
    scopedSlots: { customRender: 'officeName' }
  },
  {
    title: '项目名称',
    dataIndex: 'projectName',
    align: 'left',
    scopedSlots: { customRender: 'projectName' }
  },
  // {
  //   title: '巡检时间',
  //   dataIndex: 'status',
  //   align: 'center',
  //   scopedSlots: { customRender: 'status' }
  // },
  {
    title: '状态',
    dataIndex: 'status',
    align: 'center',
    scopedSlots: { customRender: 'do-status' }
  },
    {
    title: '检查标题',
    dataIndex: 'title',
    align: 'left',
    scopedSlots: { customRender: 'title' }
  },{
    title: '操作',
    align: 'center',
    scopedSlots: { customRender: 'action' }
  }
  ];

export default {
   mixins: [exportProgress],
  name: 'QueryList',
  components: {StandardTable,ExportLoading},
  data () {
    return {
      firstDay:null,
      nowDay:null,
      dataSource:[],
      loadingText:'正在下载文件...',
      loadKeys:[],
      officeTreeData:[],
      advanced: false,
      columns: columns,
      tableLoading:false,
      selectedRows: [],
      currentOfficeId:null,
      dateFormat: 'YYYY-MM-DD',
      queryParams:{
        officeName:null,
        projectOfficeId:null,
        teamName:null,
        pageNumber:1,
        plan_date:null,
        projectName:null,
        planType:null,
        queryType:2,
        status:null
      },
      pagination:{
        current:1,
        pageSize:10,
        total:0,
        showLessItems:true,
        showQuickJumper:true
      },
      detail:{
        visible:false,
      },
      cPercent:0,
      isExporting:false,
      fileName:'检查记录文件',
    }
  },
  computed: {
    ...mapGetters('account', ['user']),
  },
  created() {
    this.firstDay = moment(new Date()).format('YYYY-MM')+"-01";
    this.nowDay = moment(new Date()).format('YYYY-MM-DD');
    this.doQuery();
    if (this.user.enname == "admin"){
      this.getOfficeTree(undefined);
      this.currentOfficeId = undefined;
    }else{
      this.getOfficeTree(this.user.officeId);
      this.currentOfficeId = this.user.officeId;
    }

  },
  watch: {
    cPercent:{
      handler(newName, oldName) {
        this.cPercent=newName;
      },
      immediate: true,
    },
    loadingText:{
      handler(newName, oldName) {
        this.loadingText=newName;
      },
      immediate: true,
    }
  },
  methods: {
    moment,
    deleteRecord(key) {
      this.dataSource = this.dataSource.filter(item => item.key !== key)
      this.selectedRows = this.selectedRows.filter(item => item.key !== key)
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    remove () {
      this.dataSource = this.dataSource.filter(item => this.selectedRows.findIndex(row => row.key === item.key) === -1)
      this.selectedRows = []
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },
    getOfficeTree(officeId){
      let params = {
        parentOfficeId: officeId
      }
      request(GET_OFFICE_TREE_BY_EPIDEMIC, "get", params).then(res => {
          this.officeTreeData = res.data.data
      });
    },
     /**
     * 获取所有人员的签名记录信息
     */
    doQuery(){
      this.tableLoading = true;
      if(this.queryParams.plan_date&&this.queryParams.plan_date.length>0){
        this.queryParams.xjTimeStart = this.queryParams.plan_date[0].format('YYYY-MM-DD')
        this.queryParams.xjTimeEnd = this.queryParams.plan_date[1].format('YYYY-MM-DD')
      }
      request(AQXJ_QUERY_GETLIST,"post",this.queryParams).then(res => {
        if(res.data.code==0){
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
          this.dataSource.forEach((obj,index)=>{
            obj.key = obj.id;
            // obj.index = this.pagination.current*index+1
          })
        }
        this.tableLoading = false;
      })
    },
    doReset(){
      this.queryParams={queryType:2};
      this.doQuery();
    },
    //分页查询
    onPageChange(e){
      this.queryParams.pageNumber = e.current;
      this.pagination.current=e.current;
      this.doQuery();
    },
    changeDate(e){
      if(e==null||e.length<=0){
        this.queryParams.startDate = null;
        this.queryParams.endDate = null
      }
    },
    //下载巡检资料文件
    dowLoadFile(recode){
      let selectedRows = this.dataSource.filter(item => item.key == recode.key);
      this.loadingText = "正在下载文件，请稍候 ····";
      this.loadKeys = [];
      this.loadKeys.push(recode.key);
      this.isExporting=true;
      this.cPercent=0;
      let fileName = ""
      if(recode.title){
        fileName=recode.title;
      }
      fileName=fileName+"("+moment(recode.xjTime, this.dateFormat)+")检查资料文件.zip";
      this.exportFun(SAFE_VIEW_DOWNLOAD,this.loadKeys,fileName,selectedRows)
    },
    doClose(){
      this.detail.visible = false;
    }
  }
}
</script>

<style lang="less" scoped>
  .search{
    margin-bottom: 54px;
  }
  .fold{
    width: calc(100% - 216px);
    display: inline-block
  }
  .operator{
    margin-bottom: 18px;
  }
  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
</style>
