var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-dropdown",
    {
      attrs: { placement: "bottomCenter", trigger: ["click"] },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "div",
        { staticClass: "header-project", staticStyle: { cursor: "pointer" } },
        [
          _c(
            "span",
            {
              staticClass: "name",
              staticStyle: {
                width: "150px",
                overflow: "hidden",
                "text-overflow": "ellipsis",
                "white-space": "nowrap",
              },
              on: { click: _vm.showProjectList },
            },
            [
              _vm._v(
                _vm._s(
                  _vm.user.loginType == 0
                    ? _vm.user.loginLaoWuUser.projectName
                    : _vm.user.officeLoginData.officeName
                )
              ),
            ]
          ),
        ]
      ),
      _c("div", { attrs: { slot: "overlay" }, slot: "overlay" }, [
        _c(
          "div",
          { staticClass: "demo-infinite-container" },
          [
            _vm.user.loginType == 0
              ? _c("a-input-search", {
                  attrs: {
                    placeholder: "搜索项目",
                    "enter-button": "搜索",
                    size: "default",
                  },
                  on: { search: _vm.onSearch },
                  model: {
                    value: _vm.searchProject,
                    callback: function ($$v) {
                      _vm.searchProject = $$v
                    },
                    expression: "searchProject",
                  },
                })
              : _vm._e(),
            _vm.user.loginType == 0
              ? _c(
                  "a-menu",
                  { class: ["project-menu"] },
                  _vm._l(_vm.useProjectList, function (item, index) {
                    return _c(
                      "a-menu-item",
                      {
                        key: index,
                        on: {
                          click: function ($event) {
                            return _vm.changeAccount(
                              item.id,
                              item.projectId,
                              item.isBindLaoWuUser
                            )
                          },
                        },
                      },
                      [_c("div", [_vm._v(_vm._s(item.projectName))])]
                    )
                  }),
                  1
                )
              : _vm._e(),
            _vm.useProjectList == null ||
            _vm.useProjectList == undefined ||
            _vm.useProjectList.length <= 0
              ? _c("a-empty")
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }