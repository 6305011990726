<template>
  <div class="company card w-100">
    <!-- 公司欢迎语 -->
    <div style="margin-bottom: 10px" class="black font-16 font-bold overtext-1">
      {{ isProject ? projectData.projectName : officeData.name }}
    </div>
    <div class="flex a-center">
      <img
        src="@/assets/img/home-project.png"
        style="width: 100px; height: 82px"
      />
      <div class="ml-1">
        <div v-if="isProject" class="overtext-1" style="width: 200px;">
          <span class="font-bold" style="width: 60px;">参建单位</span>
          <span class="ml-1" :title="projectData.officeName">{{ projectData.officeName }}</span>
        </div>
        <div class="overtext-1 mt-1 flex">
          <span class="font-bold text-right" style="width: 70px;">负责人:</span>
          <span class="ml-1" :title="isProject ? projectData.userName : officeData.master">{{ isProject ? projectData.userName : officeData.master || '/' }}</span>
        </div>
        <div class="overtext-1 mt-1 flex">
          <span class="font-bold text-right" style="width: 70px;">联系电话:</span>
          <span class="ml-1" :title="isProject ? projectData.userPhone : officeData.phone">{{ isProject ? projectData.userPhone : officeData.phone || '/' }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      officeData: {
        type: Object,
        default: () => ({})
      },
      projectData: {
        type: Object,
        default: () => ({})
      },
      isProject: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {

      }
    },
    watch: {
      projectData: {
        handler(val) {
          console.log(val, 'projectData')
        },
        immediate: true
      },
      mangerData: {
        handler(val) {
          console.log(val, 'mangerData')
        },
        immediate: true
      }
    },
    methods: {

    }
  }
</script>

<style lang="less" scoped>
.card {
  border-radius: 10px;
  padding: 17px;
  background-color: #fff;
}
.company {
  height: 150px;
  margin-bottom: 10px;
}
</style>
