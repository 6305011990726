<template>
  <div class="constructiontTable_index">
    <a-row :gutter="16">
      <a-col>
        <a-card
          :bordered="false"
          style="min-height: 900px;;"
          :style="'overflow:auto'"
        >
          <a-tabs v-model="activeKey" @change="callback" forceRender>
            <a-tab-pane key="1" tab="基本信息">
              <projectBasic :projectId="projectId" />
            </a-tab-pane>
            <a-tab-pane key="2" tab="参建单位">
              <unitTabel :projectId="projectId" />
            </a-tab-pane>
            <a-tab-pane key="3" tab="管理人员">
              <managementList :projectId="projectId" :officeId="officeId" />
            </a-tab-pane>
            <a-tab-pane key="4" tab="场地分区">
              <accessControl :projectId="projectId" :officeId="officeId" />
            </a-tab-pane>
            <!-- <a-tab-pane key="7" tab="考勤设备">
              <unitOfficeBindDevice/>
            </a-tab-pane> -->
            <a-tab-pane key="5" tab="风控规则">
              <riskControlRules :projectId="projectId" />
            </a-tab-pane>
            <a-tab-pane key="6" tab="项目参数设置">
              <projectConfig v-if="activeKey === '6'" :projectId="projectId" />
            </a-tab-pane>
            <a-tab-pane key="8" tab="考勤数据上报" v-if="user.nowRoleKey.includes('OFFICE_JSFWZY')">
              <attendnceSettings v-if="activeKey === '8'" :projectId="projectId" />
            </a-tab-pane>
          </a-tabs>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import riskControlRules from "@/pages/phasell/project/components/riskControlRules/index";
import unitTabel from "@/pages/phasell/project/components/unitTable";
import managementList from "@/pages/phasell/project/components/managementList";
import projectBasic from "@/pages/phasell/project/components/basic";
import accessControl from "@/pages/phasell/project/components/accessControl";
import projectConfig from "@/pages/phasell/project/components/projectConfig";
import unitOfficeBindDevice from '@/pages/participationUnit/accessControl/teamBindDevice/index'
import attendnceSettings from "@/pages/phasell/project/components/attendnceSettings";

export default {
  name: "SuijianClientCompanyProjectDetails",
  components: {
    projectBasic,
    accessControl,
    unitTabel,
    managementList,
    riskControlRules,
    projectConfig,
    attendnceSettings
    // unitOfficeBindDevice
  },
  data() {
    return {
      // 筛选
      queryParam: {
        phone: "",
        name: "123",
        parentOfficeId: "",
        roleId: undefined,
        projectId: undefined,
      },
      // query
      params: {},
      projectId: "",
      officeId: "",
      // 树形结构
      projectData: {},
      activeKey: '1'
    };
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler(newQuery, oldQuery) {
        // 对新的查询参数进行处理
        if (newQuery.id) {
          this.projectId = this.$route.query.id
            ? this.$route.query.id
            : this.user.projectId;
          this.officeId = this.$route.query.officeId
            ? this.$route.query.officeId
            : this.user.officeId;
        }
      },
    },
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  activated() {
    if (this.$route.query.id) {
      this.projectId = this.$route.query.id ? this.$route.query.id : this.user.projectId
      this.officeId = this.$route.query.officeId ? this.$route.query.officeId : this.user.officeId
    } else if (this.user.projectId && this.user.officeId) {
      this.projectId = this.user.projectId
      this.officeId = this.user.officeId
    }
  },
  methods: {
    callback(key) {
      console.log(key);
    },
  },
};
</script>

<style lang="less" scoped>
.constructiontTable_index {
  /deep/ .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
    height: 56px;
  }
  /deep/ .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    height: 56px;
    line-height: 56px;
  }

  /deep/ .card-left .ant-card-body {
    padding: 10px 24px;
  }
  /deep/ .card-detail .ant-card-body {
    padding: 0;
  }
  // padding: 24px;
  // background: #fff;
  // border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-bottom: 10px;

  .operator {
    margin-left: 30px;
  }
  .c_i_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 25px;

    .c_i_h_center {
      padding: 16px;
      border-radius: 4px;
      width: 180px;
      height: 130px;
      margin-right: 50px;

      div:nth-child(1) {
        font-size: 16px;
      }

      div:nth-child(2) {
        margin-top: 26px;
        font-size: 24px;
      }
    }
  }
}
</style>
