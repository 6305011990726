<!-- 入场审批 -->
<template>
  <div>
    <div v-show="$route.meta.showFather" class="a_Approval">
      <!-- <a-row :gutter="16">
        <a-col :span="4">
          <a-card :bordered="false" style="max-height: 800px;" :style="'overflow-y:hidden'">
            <a-tree
              :expanded-keys="expandedKeys"
              :tree-data="officeTreeData"
              :auto-expand-parent="autoExpandParent"
              :replaceFields="{children:'children', title:'name', key:'id' }"
              @expand="onExpand"
              @select="onSelect"
            >
            </a-tree>
          </a-card>
        </a-col>
        <a-col :span="20"> -->
          <div>
            <a-tabs :default-active-key="defaultActiveKey">
              <a-tab-pane key="1" :tab="tabName" forceRender>
                <equipment-list></equipment-list>
              </a-tab-pane>
              <a-tab-pane key="2" tab="维保记录" forceRender>
                <maintenance-record></maintenance-record>
              </a-tab-pane>
              <a-tab-pane key="3" tab="防坠器有效期预警汇总表" forceRender v-if="tabName == '施工升降机'">
                <falling-check-record-sum></falling-check-record-sum>
              </a-tab-pane>
            </a-tabs>
          </div>
        <!-- </a-col>
      </a-row> -->
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import EquipmentList from './components/equipmentList.vue';
import MaintenanceRecord from './components/maintenanceRecordTop.vue';
import {getOfficeTree, getOfficeTypeList, getOfficeTreeSysUser,
        getRoleListBySysUserList, getRoleByOldUserId, saveLabourUser, delSysUser,
        getProjectListByOfficeId, searchLoginName} from '@/services/sys'
import FallingCheckRecordSum from './components/fallingCheckRecordSum.vue';
export default {
  components: {
    EquipmentList,
    MaintenanceRecord,
    FallingCheckRecordSum,
  },
  data() {
    return {
      defaultActiveKey: "1",
      tabName: '',
      // 树形结构
      expandedKeys: [],
      autoExpandParent: true,
      officeTreeData: [],
      // 筛选
      queryParam: {
          phone: "",
          name: "",
          parentOfficeId: "",
          roleId: undefined,
          projectId: undefined
      },
    };
  },
  activated() {
    this.defaultActiveKey = '1'
    this.tabName = this.$route.name
    getOfficeTree({parentOfficeId: this.queryParam.parentOfficeId}).then(res => {
        this.officeTreeData = res.data.data
    })
},
  methods: {
    onExpand(expandedKeys) {
        this.expandedKeys = expandedKeys;
        this.autoExpandParent = false;
    },
    onSelect(selectedKeys, info) {
        this.queryParam.parentOfficeId = selectedKeys[0];
        // this.queryOfficeId(this.queryParam.parentOfficeId, null, null);
        // this.parentData();
    },
  },
};
</script>