<template>
  <a-card :bordered="false">
    <a-spin :spinning="spinning" :tip="loadingText">
    <div :class="advanced ? 'search' : null">
      <a-form layout="horizontal">
        <div :class="advanced ? null: 'fold'">
          <a-row >
          <a-col :md="4" :sm="24" >
            <a-form-item
              label="姓名"
              :labelCol="{span: 4}"
              :wrapperCol="{span: 18, offset: 1}"
            >
              <a-input placeholder="请输入" v-model="queryParams.userName"/>
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="24" >
            <a-form-item
              label="身份证号"
              :labelCol="{span: 6}"
              :wrapperCol="{span: 16, offset: 1}"
            >
              <!-- <a-select placeholder="请选择">
                <a-select-option value="1">关闭</a-select-option>
                <a-select-option value="2">运行中</a-select-option>
              </a-select> -->
              <a-input placeholder="身份证号" v-model="queryParams.idCard"/>
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="24" :style="{ textAlign: 'left', marginTop: '4px'}">
            <a-space >
              <a-button type="primary" @click="handleSubmit">查询</a-button>
              <a-button @click="doReset()">重置</a-button>
              <a-button @click="doReports()">批量上报</a-button>
            </a-space>
          </a-col>
        </a-row>
        </div>
      </a-form>
    </div>
    <div>
      <!-- <a-space class="operator">
        <a-button @click="doReports()">批量上报</a-button>
      </a-space> -->
      <standard-table
        :columns="columns"
        :dataSource="dataSource"
        :selectedRows.sync="selectedRows"
        :pagination="pagination"
        :loading="tableLoading"
        @clear="onClear"
        @change="onPageChange"
      >
        <div slot="remarks" slot-scope="{text}">
          <div v-html="text"></div>
        </div>
        <div slot="do-status" slot-scope="{text}">
          <a-icon v-if="text==1" style="color:green" type="check" />
          <a-icon v-else style="color:red" type="close" />
        </div>
        <div slot="action" slot-scope="{text, record}">
          <a @click="doReport(`${record.key}`)">重新上报</a>
        </div>
        <template slot="statusTitle">
          <a-icon @click.native="onStatusTitleClick" type="info-circle" />
        </template>
      </standard-table>
    </div>
    </a-spin>
  </a-card>
</template>

<script>
import StandardTable from '@/components/table/StandardTable'
import {
  ATTENDANCE_QUERYPAGE_ERROR,
  ATTENDANCE_DOREPORTCWA
  } from '@/services/api'
import {request} from '@/utils/request'
// import { te } from 'date-fns/locale'
// import axios from '@/utils/axios'
import { useRender } from '@/hooks/useRender.js'

const columns = [
  {
    title: '姓名',
    dataIndex: 'userName',
    align: 'center',
    scopedSlots: { customRender: 'userName' }
  },
  {
    title: '身份证号',
    dataIndex: 'idCard',
    align: 'center',
    scopedSlots: { customRender: 'description' },
    customRender: (text) => {
      return text.substring(0,6)+"************"+text.substring(text.length-4,text.length)
    }
  },
  {
    title: '考勤时间',
    dataIndex: 'clockTime',
    align: 'center',
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: '补报次数',
    dataIndex: 'cnt',
    align: 'center',
    scopedSlots: { customRender: 'cnt' },
    customRender: (text) => {
      return text+'次'
    }
  },
  {
    title: '是否上报成功',
    dataIndex: 'isSuccess',
    align: 'center',
    scopedSlots: {customRender: 'do-status'}
  },
  {
    title: '上报异常信息',
    dataIndex: 'remarks',
    align: 'left',
    scopedSlots: {customRender: 'remarks'}
  },
  {
    title: '操作',
    align: 'center',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'errorQueryList',
  components: {StandardTable},
  data () {
    return {
      dataSource:[],
      advanced: false,
      columns: columns,
      selectedRows: [],
      tableLoading: false,
      spinning:false,
      loadingText:"数据加载中...",
      loadKeys:[],
      showDrawer:false,
      edtorDatas:[],
      queryParams:{
        userName:null,
        idCard:null,
        pageNumber:1,
      },
      pagination:{
        current:1,
        pageSize:10,
        total:0,
        showLessItems:true,
        showQuickJumper:true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    }
  },
  created() {
    this.doQuery();
  },
  authorize: {
    deleteRecord: 'delete'
  },
  methods: {
    handleSubmit() {
      this.queryParams.pageNumber = 1
      this.pagination.current = 1
      this.doQuery();
    },
    deleteRecord(key) {
      this.dataSource = this.dataSource.filter(item => item.key !== key)
      this.selectedRows = this.selectedRows.filter(item => item.key !== key)
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    remove () {
      this.dataSource = this.dataSource.filter(item => this.selectedRows.findIndex(row => row.key === item.key) === -1)
      this.selectedRows = []
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },


    /**
     * 获取所有异常数据
     */
    doQuery(){
      this.tableLoading = true;
        request(ATTENDANCE_QUERYPAGE_ERROR,"post",this.queryParams).then(res => {
          if(res.data.code==0){
            this.dataSource = res.data.data.list;
            this.pagination.total = res.data.data.total;
            this.dataSource.forEach(obj=>{
              obj.key = obj.id;
            })
          }
          this.tableLoading = false;
        })
    },
    doReset(){
      this.queryParams={};
      this.pagination.current = 1
      this.doQuery();
    },
    //分页查询
    onPageChange(e){
      this.queryParams.pageNumber = e.current;
      this.pagination.current=e.current;
      this.doQuery();
    },

    edit(key){
        this.showDrawer=true;
        this.edtorDatas = this.dataSource.filter(item => item.id = key);
    },

    /**
     * 异常记录信息上报
     */
    doReport(key){
      this.spinning = true;
      this.loadingText = "正在上报建委平台，请稍后 ····";
      this.loadKeys = [];
      this.loadKeys.push(key);
      let obj = this.dataSource.filter(item => item.id = key);
      console.log(obj);
      if(obj.cnt>=5||obj.isSuccess===1){
          this.spinning = false;
          this.$message.warning('该记录已经上报大于5次或者已经上报成功了');
          return
      }
      request(ATTENDANCE_DOREPORTCWA,"post",this.loadKeys).then(res => {
        this.spinning = false;
        if(res.data.code==0){
            this.doReset();
        }else{
            console.log(res.data)
        }

      })
    },
    /**
     * 异常记录信息上报
     */
    doReports(){
      this.spinning = true;
      this.loadingText = "正在上报建委平台，请稍后 ····";
      this.loadKeys = [];
      if(!this.selectedRows||this.selectedRows.length<=0){
          this.$message.error('请选择异常考勤记录上报...');
      }
      this.selectedRows.map((item) => {
        this.loadKeys.push(item.key);
      })
      request(ATTENDANCE_DOREPORTCWA,"post",this.loadKeys).then(res => {
        this.spinning = false;
        if(res.data.code==0){
            this.doReset();
        }else{
            console.log(res.data)
        }

      })
    },
    handleMenuClick (e) {
      if (e.key === 'delete') {
        this.remove()
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .search{
    margin-bottom: 54px;
  }
  // .fold{
  //   width: calc(100% - 250px);
  //   display: inline-block
  // }
  // .operator{
  //   margin-bottom: 18px;
  // }
  // @media screen and (max-width: 900px) {
  //   .fold {
  //     width: 100%;
  //   }
  // }
</style>
