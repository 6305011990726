var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-card",
        {
          staticClass: "risk_container",
          attrs: { title: "工人年龄限制", bordered: false },
        },
        [
          _c("div", { staticClass: "risk-rules" }, [
            _c(
              "div",
              [
                _c(
                  "a-checkbox",
                  {
                    attrs: { disabled: !_vm.form.age.tg.value },
                    model: {
                      value: _vm.form.age.tg.check,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.age.tg, "check", $$v)
                      },
                      expression: "form.age.tg.check",
                    },
                  },
                  [
                    _c("span", { staticClass: "text-color" }, [
                      _vm._v("童工年龄限制，童工指未满 "),
                    ]),
                  ]
                ),
                _c(
                  "a-input",
                  {
                    staticStyle: { width: "120px" },
                    attrs: { placeholder: "18" },
                    model: {
                      value: _vm.form.age.tg.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.age.tg, "value", $$v)
                      },
                      expression: "form.age.tg.value",
                    },
                  },
                  [
                    _c(
                      "a-tooltip",
                      { attrs: { slot: "suffix" }, slot: "suffix" },
                      [
                        _c("span", { staticClass: "text-color" }, [
                          _vm._v("周岁"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "a-checkbox",
                  {
                    attrs: { disabled: !_vm.form.age.man.value },
                    model: {
                      value: _vm.form.age.man.check,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.age.man, "check", $$v)
                      },
                      expression: "form.age.man.check",
                    },
                  },
                  [
                    _c("span", { staticClass: "text-color" }, [
                      _vm._v("男性退休年龄限制，退休年龄为 "),
                    ]),
                  ]
                ),
                _c(
                  "a-input",
                  {
                    staticStyle: { width: "120px" },
                    attrs: { placeholder: "60" },
                    model: {
                      value: _vm.form.age.man.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.age.man, "value", $$v)
                      },
                      expression: "form.age.man.value",
                    },
                  },
                  [
                    _c(
                      "a-tooltip",
                      { attrs: { slot: "suffix" }, slot: "suffix" },
                      [
                        _c("span", { staticClass: "text-color" }, [
                          _vm._v("周岁"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "a-checkbox",
                  {
                    attrs: { disabled: !_vm.form.age.women.value },
                    model: {
                      value: _vm.form.age.women.check,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.age.women, "check", $$v)
                      },
                      expression: "form.age.women.check",
                    },
                  },
                  [
                    _c("span", { staticClass: "text-color" }, [
                      _vm._v("女性退休年龄限制，退休年龄为 "),
                    ]),
                  ]
                ),
                _c(
                  "a-input",
                  {
                    staticStyle: { width: "120px" },
                    attrs: { placeholder: "50" },
                    model: {
                      value: _vm.form.age.women.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.age.women, "value", $$v)
                      },
                      expression: "form.age.women.value",
                    },
                  },
                  [
                    _c(
                      "a-tooltip",
                      { attrs: { slot: "suffix" }, slot: "suffix" },
                      [
                        _c("span", { staticClass: "text-color" }, [
                          _vm._v("周岁"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "a-card",
        {
          staticClass: "risk_container",
          attrs: { title: "民族/地区限制", bordered: false },
        },
        [
          _c("div", { staticClass: "risk-rules" }, [
            _c(
              "div",
              [
                _c(
                  "a-checkbox",
                  {
                    attrs: { disabled: !_vm.form.areation.nation.value.length },
                    model: {
                      value: _vm.form.areation.nation.check,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.areation.nation, "check", $$v)
                      },
                      expression: "form.areation.nation.check",
                    },
                  },
                  [
                    _c("span", { staticClass: "text-color" }, [
                      _vm._v("民族限制 "),
                    ]),
                  ]
                ),
                _c(
                  "a-select",
                  {
                    staticStyle: { width: "200px" },
                    attrs: {
                      placeholder: "请选择民族",
                      allowClear: "",
                      mode: "multiple",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.handlSelect($event)
                      },
                    },
                    model: {
                      value: _vm.form.areation.nation.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.areation.nation, "value", $$v)
                      },
                      expression: "form.areation.nation.value",
                    },
                  },
                  _vm._l(_vm.nation, function (items) {
                    return _c(
                      "a-select-option",
                      { key: items, attrs: { value: items } },
                      [_vm._v(" " + _vm._s(items) + " ")]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "a-checkbox",
                  {
                    attrs: { disabled: !_vm.form.areation.area.value.length },
                    model: {
                      value: _vm.form.areation.area.check,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.areation.area, "check", $$v)
                      },
                      expression: "form.areation.area.check",
                    },
                  },
                  [
                    _c("span", { staticClass: "text-color" }, [
                      _vm._v("地区限制 "),
                    ]),
                  ]
                ),
                _c(
                  "a-select",
                  {
                    staticStyle: { width: "200px" },
                    attrs: {
                      placeholder: "请选择省份",
                      allowClear: "",
                      mode: "multiple",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.handlSelect($event)
                      },
                    },
                    model: {
                      value: _vm.form.areation.area.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.areation.area, "value", $$v)
                      },
                      expression: "form.areation.area.value",
                    },
                  },
                  _vm._l(_vm.area, function (items) {
                    return _c(
                      "a-select-option",
                      { key: items.name, attrs: { value: items.name } },
                      [_vm._v(" " + _vm._s(items.name) + " ")]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "a-card",
        {
          staticClass: "risk_container",
          attrs: { title: "长期未考勤提醒规则", bordered: false },
        },
        [
          _c("div", { staticClass: "risk-rules" }, [
            _c(
              "div",
              [
                _c(
                  "a-checkbox",
                  {
                    attrs: { disabled: !_vm.form.kq.value },
                    model: {
                      value: _vm.form.kq.check,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.kq, "check", $$v)
                      },
                      expression: "form.kq.check",
                    },
                  },
                  [_c("span", { staticClass: "text-color" }, [_vm._v("超过 ")])]
                ),
                _c("a-input", {
                  staticStyle: { width: "100px" },
                  attrs: { placeholder: "7" },
                  model: {
                    value: _vm.form.kq.value,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.kq, "value", $$v)
                    },
                    expression: "form.kq.value",
                  },
                }),
                _c(
                  "span",
                  {
                    staticClass: "text-color",
                    staticStyle: { "margin-left": "5px" },
                  },
                  [_vm._v("天未考勤，系统进行提示")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "a-card",
        {
          staticClass: "risk_container",
          attrs: { title: "教育提醒规则", bordered: false },
        },
        [
          _c("div", { staticClass: "risk-rules" }, [
            _c(
              "div",
              [
                _c(
                  "a-checkbox",
                  {
                    attrs: { disabled: !_vm.form.edu.value },
                    model: {
                      value: _vm.form.edu.check,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.edu, "check", $$v)
                      },
                      expression: "form.edu.check",
                    },
                  },
                  [_c("span", { staticClass: "text-color" }, [_vm._v("超过 ")])]
                ),
                _c("a-input", {
                  staticStyle: { width: "100px" },
                  attrs: { placeholder: "1" },
                  model: {
                    value: _vm.form.edu.value,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.edu, "value", $$v)
                    },
                    expression: "form.edu.value",
                  },
                }),
                _c(
                  "span",
                  {
                    staticClass: "text-color",
                    staticStyle: { "margin-left": "5px" },
                  },
                  [_vm._v("天未接受三级安全教育培训，系统进行提示")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "a-button",
        {
          attrs: { icon: "save", type: "primary", size: "large" },
          on: { click: _vm.saveRules },
        },
        [_vm._v("保存")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }