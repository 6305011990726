var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.$route.meta.showFather,
          expression: "$route.meta.showFather",
        },
      ],
      staticClass: "a_Approval",
    },
    [
      _c("div", { staticClass: "plug-Overview" }, [_vm._v("待审核列表")]),
      _c("a-divider"),
      _c(
        "a-form-model",
        {
          staticClass: "clearfix",
          attrs: { model: _vm.params },
          on: { submit: _vm.handleSubmit },
        },
        [
          _c(
            "a-col",
            { attrs: { span: 5 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "项目名称",
                    labelCol: { span: 8 },
                    wrapperCol: { span: 14, offset: 1 },
                  },
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入项目名称", allowClear: "" },
                    model: {
                      value: _vm.params.projectName,
                      callback: function ($$v) {
                        _vm.$set(_vm.params, "projectName", $$v)
                      },
                      expression: "params.projectName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 4 } },
            [
              _c(
                "a-space",
                { staticClass: "btnBox" },
                [
                  _c(
                    "a-button",
                    { attrs: { type: "primary", "html-type": "submit" } },
                    [_vm._v(" 查询 ")]
                  ),
                  _c("a-button", { on: { click: _vm.resetQuery } }, [
                    _vm._v(" 重置 "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "a_a_footer" },
        [
          _c(
            "standard-table",
            {
              attrs: {
                columns: _vm.columns,
                dataSource: _vm.tableData,
                rowKey: "id",
                pagination: _vm.pagination,
                loading: _vm.tableLoading,
                isAlert: false,
                scroll: { x: 1300 },
                bordered: true,
              },
              on: { change: _vm.handleChangePage },
              scopedSlots: _vm._u([
                {
                  key: "index",
                  fn: function (ref) {
                    var index = ref.index
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            (_vm.pagination.current - 1) *
                              _vm.pagination.pageSize +
                              parseInt(index) +
                              1
                          )
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "dateGroup",
                  fn: function (ref) {
                    var record = ref.record
                    return [
                      _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.useRender.renderDate(record.workStartDate)
                            ) +
                            " - " +
                            _vm._s(
                              _vm.useRender.renderDate(record.workEndDate)
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "approveStatus",
                  fn: function (ref) {
                    var text = ref.text
                    return [
                      _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              text == 0
                                ? "已提交"
                                : text == 1
                                ? "已审核"
                                : "已退回"
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "workContentCode",
                  fn: function (ref) {
                    var text = ref.text
                    return [
                      _c("div", [
                        _vm._v(" " + _vm._s(_vm.getWorkContent(text)) + " "),
                      ]),
                    ]
                  },
                },
                {
                  key: "action",
                  fn: function (ref) {
                    var record = ref.record
                    return [
                      _vm.isBranchOffice && record.approveStatus == 0
                        ? _c("div", [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function () {
                                    return _vm.handleOperate(record, 1)
                                  },
                                },
                              },
                              [_vm._v(" 通过 ")]
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "ml-2",
                                staticStyle: { color: "FA367A" },
                                on: {
                                  click: function () {
                                    return _vm.handleOperate(record, 2)
                                  },
                                },
                              },
                              [_vm._v(" 退回 ")]
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [
              _c("template", { slot: "dangerLevel" }, [
                _c("div", { staticClass: "circle" }),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("Export-loading", {
        attrs: {
          cPercent: _vm.cPercent,
          isExporting: _vm.isExporting,
          loadingText: _vm.loadingText,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }