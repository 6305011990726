var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      attrs: {
        autoFormCreate: function (form) {
          return (this$1.form = form)
        },
      },
    },
    [
      _c("a-table", {
        attrs: {
          columns: _vm.dataColumns,
          dataSource: _vm.dataSource,
          pagination: false,
        },
        scopedSlots: _vm._u(
          [
            _vm._l(["name", "number", "department"], function (col, i) {
              return {
                key: col,
                fn: function (text, record) {
                  return [
                    record.editable
                      ? _c("a-input", {
                          key: col,
                          staticStyle: { margin: "-5px 0" },
                          attrs: {
                            value: text,
                            placeholder: _vm.columns[i].title,
                          },
                          on: {
                            change: function (e) {
                              return _vm.handleChange(
                                e.target.value,
                                record.key,
                                col
                              )
                            },
                          },
                        })
                      : [_vm._v(_vm._s(text))],
                  ]
                },
              }
            }),
            {
              key: "operation",
              fn: function (text, record) {
                return [
                  record.editable
                    ? [
                        record.isNew
                          ? _c(
                              "span",
                              [
                                _c(
                                  "a",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.saveRow(record.key)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("add")))]
                                ),
                                _c("a-divider", {
                                  attrs: { type: "vertical" },
                                }),
                                _c(
                                  "a-popconfirm",
                                  {
                                    attrs: { title: _vm.$t("deleteConfirm") },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.remove(record.key)
                                      },
                                    },
                                  },
                                  [_c("a", [_vm._v(_vm._s(_vm.$t("delete")))])]
                                ),
                              ],
                              1
                            )
                          : _c(
                              "span",
                              [
                                _c(
                                  "a",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.saveRow(record.key)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("save")))]
                                ),
                                _c("a-divider", {
                                  attrs: { type: "vertical" },
                                }),
                                _c(
                                  "a",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.cancle(record.key)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("cancel")))]
                                ),
                              ],
                              1
                            ),
                      ]
                    : _c(
                        "span",
                        [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.toggle(record.key)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("edit")))]
                          ),
                          _c("a-divider", { attrs: { type: "vertical" } }),
                          _c(
                            "a-popconfirm",
                            {
                              attrs: { title: _vm.$t("deleteConfirm") },
                              on: {
                                confirm: function ($event) {
                                  return _vm.remove(record.key)
                                },
                              },
                            },
                            [_c("a", [_vm._v(_vm._s(_vm.$t("delete")))])]
                          ),
                        ],
                        1
                      ),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
      _c(
        "a-button",
        {
          staticStyle: {
            width: "100%",
            "margin-top": "16px",
            "margin-bottom": "8px",
          },
          attrs: { type: "dashed", icon: "plus" },
          on: { click: _vm.newMember },
        },
        [_vm._v(_vm._s(_vm.$t("newMember")))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }