<!-- 入场审批 -->
<template>
  <div class="a_Approval">
    <a-tabs :default-active-key="defaultActiveKey">
      <a-tab-pane key="1" tab="班组申请">
        <team-approval></team-approval>
      </a-tab-pane>
      <a-tab-pane key="2" tab="个人申请">
        <personal-approval></personal-approval>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import PersonalApproval from '@/pages/advanceRetreat/personalApproval.vue';
import TeamApproval from '@/pages/advanceRetreat/teamApproval.vue';

export default {
  components: {
    PersonalApproval,
    TeamApproval,
  },
  data() {
    return {
      defaultActiveKey: "1",
    };
  },
  methods: {
    
  },
};
</script>

<style lang="less" scoped>
.a_Approval {
  background: #fff;
  padding: 20px;
  // border-radius: 10px;
}
</style>