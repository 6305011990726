<template>
    <!-- form表单插件入口 -->
    <div class="plug-form">
        <!-- form表单 -->
        <a-form :form="form" @submit="handleSubmit">
            <!-- header -->
            <div class="flex j-between a-center">
                <!-- 标题 -->
                <div v-if="Overview" class="plug-Overview">{{ Overview }}</div>
                <!-- 提交/关闭按钮 -->
                <div v-if="topSubmit" class="topSubmit">
                    <slot name="topSubmit"></slot>
                </div>
            </div>
            <!-- 分割线 -->
            <a-divider v-if="Overview"/>

            <div class="formdiv">
                <div v-for="item of formData" :key="item.key"  :class="['formindex',item.class]">
                    <a-col :span="item.col" v-if="item.display || item.disType == query.type">
                        <!-- input输入框 -->
                        <template v-if="item.type == 'place'">
                            <!-- <div>
                                占位
                            </div> -->
                        </template>
                        <!-- input输入框 -->
                        <template v-if="item.type == 'input'">
                            <a-form-item :label="item.label" :labelCol="{ span: item.labelCol}" :wrapperCol="{ span: item.wrapperCol, offset: 1 }">
                                <div class="aIput" :class="{ 'custom-input-number' : item.inputType === 'number' }">
                                    <a-input :type="item.inputType || 'text'" :disabled="item.disabled" v-decorator="item.unsetRules ? item.decorator : setRules(item.decorator)" :style="item.style"
                                    :maxLength="item.maxLength" :placeholder="item.placeholder" allowClear @change="item.inputChange" :addon-after="item.addonAfter"/>
                                    <div v-if="item.key == 'lanAndLat'" class="aMap">
                                        地图上选择
                                    </div>
                                </div>
                            </a-form-item>
                        </template>
                        <!-- input输入框 -->
                        <template v-if="item.type == 'inputNumber'">
                            <a-form-item :label="item.label" :labelCol="{ span: item.labelCol}" :wrapperCol="{ span: item.wrapperCol, offset: 1 }">
                                <div class="aIput" :class="{ 'custom-input-number' : item.inputType === 'number' }">
                                    <a-input-number :disabled="item.disabled" v-decorator="item.unsetRules ? item.decorator : setRules(item.decorator)" :style="item.style"
                                    :maxLength="item.maxLength" :placeholder="item.placeholder" allowClear @change="item.inputChange" :addon-after="item.addonAfter"/>
                                </div>
                            </a-form-item>
                        </template>
                        <!-- input输入框 -->
                        <template v-if="item.type == 'inputPassWord'">
                            <a-form-item :label="item.label" :labelCol="{ span: item.labelCol}" :wrapperCol="{ span: item.wrapperCol, offset: 1 }">
                                <a-input-password :disabled="item.disabled" v-decorator="item.unsetRules ? item.decorator : setRules(item.decorator)" :style="item.style"
                                :maxLength="item.maxLength" :placeholder="item.placeholder" allowClear @change="item.inputChange"/>
                            </a-form-item>
                        </template>
                        <!-- input输入框组合 -->
                        <template v-if="item.type == 'inputList'">
                            <a-form-item :label="item.label" :labelCol="{ span: item.labelCol}" :wrapperCol="{ span: item.wrapperCol, offset: 1 }">
                                <div style="display: flex; justify-content: space-between;" class="aIput custom-input-number">
                                    <a-form-item v-for="(i) in item.inputList" :key="i.key" :label="i.label">
                                        <a-input :type="i.inputType || 'text'" :disabled="i.disabled" v-decorator="i.unsetRules ? i.decorator : setRules(i.decorator)" :style="i.style"
                                        :maxLength="i.maxLength" :placeholder="i.placeholder" allowClear @change="i.inputChange" :addon-after="i.addonAfter"/>
                                    </a-form-item>
                                </div>
                            </a-form-item>
                        </template>
                        <!-- input输入select选择框 -->
                        <template v-if="item.type == 'inputGroup'">
                            <a-form-item :label="item.label" :labelCol="{ span: item.labelCol}" :wrapperCol="{ span: item.wrapperCol, offset: 1 }">
                                    <a-auto-complete v-decorator="item.decorator" :data-source="item.dataSource" :style="item.style"
                                        :placeholder="item.placeholder" @search="handleSearch($event, item)" allowClear :disabled="item.disabled"
                                        @select="handlSelect($event, item.key)" @change="(val, opt) => handleChange(val, item.key, opt)">
                                        <template slot="dataSource">
                                            <a-select-option v-for="items in item.dataSource" :key="items.id + ''" :title="items.title">{{items.title}}</a-select-option>
                                        </template>
                                    </a-auto-complete>
                            </a-form-item>
                        </template>
                        <!-- select下拉选择 -->
                        <template v-if="item.type == 'select'">
                            <div class="select">
                                <a-form-item :label="item.label" :labelCol="{ span: item.labelCol}" :wrapperCol="{ span: item.wrapperCol, offset: 1 }">
                                    <a-select :mode="item.mode ? item.mode : 'default'" :disabled="item.disabled" :show-search="item.showSearch" v-decorator="item.decorator" :placeholder="item.placeholder" @change="handlSelect($event,item.key)"
                                    @search="handleSearch($event,item.key)"  :allowClear="!item.allowClear" :filter-option="filterOption">
                                        <a-select-option value="" v-if="item.qb">全部</a-select-option>
                                        <a-select-option v-for="items of item.selectlist" :key="items.name" :value="items.value" :title="items.name">{{items.name}}</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </div>
                        </template>
                        <!-- Upload上传文件 - (图片) -->
                        <template v-if="item.type == 'Upload'">
                            <a-form-item :label="item.label" :labelCol="{ span: item.labelCol}" :wrapperCol="{ span: item.wrapperCol, offset: 1 }">
                                <template>
                                    <a-upload v-decorator="item.decorator" :file-list="defaultFileList" :remove="upRemove" :data="item" :accept="accept" :disabled="item.disabled"
                                        :customRequest="resourceUpload" list-type="picture-card" :multiple="true" :beforeUpload="beforeUpload"
                                        @preview="handlePreview">
                                        <div v-if="defaultFileList.length < (item.uploadLength || 1)">
                                            <a-icon type="plus" />
                                            <!-- <div class="ant-upload-text">
                                                上传
                                            </div> -->
                                        </div>
                                    </a-upload>
                                </template>
                                <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                                    <img alt="example" style="width: 100%" :src="previewImage" />
                                </a-modal>
                            </a-form-item>
                        </template>
                        <!-- Upload自定义多个上传组件 -->
                        <template v-if="item.type == 'tsUpload'">
                            <a-form-item :label="item.label" :labelCol="{ span: item.labelCol}" :wrapperCol="{ span: item.wrapperCol, offset: 1 }">
                                <template v-if="item.defaultFileList.length > 0 && checkFileType(item.defaultFileList[0].url, item.defaultFileList[0].name) != 'image' && (item.listType && item.listType === 'picture-card')">
                                    <div v-decorator="item.decorator" class="ant-upload-list-item-info" v-for="items of item.defaultFileList" :key="items.uid">
                                        <a-icon v-if="checkFileType(item.defaultFileList[0].url, item.defaultFileList[0].name) == 'pdf'"
                                            type="file-pdf"
                                            :style="{ fontSize: '60px' }"
                                        />
                                        <a-icon v-if="checkFileType(item.defaultFileList[0].url, item.defaultFileList[0].name) == 'doc'" type="file" :style="{ fontSize: '60px' }"/>
                                        <div class="ant-upload-list-item-actions">
                                            <a-icon
                                                @click="handleTsPreview(items)"
                                                class="anticon-eye-o"
                                                type="eye"
                                            />
                                            <a-icon
                                                @click="upRemove(items)"
                                                class="anticon-delete"
                                                type="delete"
                                            />
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <a-upload v-decorator="item.decorator" :file-list="item.defaultFileList" :remove="upRemove" :data="item" :accept="item.accept ? item.accept : accept"
                                        :customRequest="resourceTsUpload" :list-type="item.listType ? item.listType : 'text'" :multiple="false" :beforeUpload="beforeUpload"
                                        @preview="handleTsPreview" :showUploadList="item.showUploadList ? item.showUploadList : false">
                                        <template v-if="item.listType && item.listType === 'picture-card'">
                                            <div v-if="item.defaultFileList.length < 1">
                                                <a-icon type="plus" />
                                                <!-- <div class="ant-upload-text">
                                                    上传
                                                </div> -->
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="ts-upload-box">
                                                <div v-if="item.defaultFileList.length > 0">
                                                    <img class="ts-img" :src="item.defaultFileList[0].url" :style="item.defaultStyleImg" @click.stop/>
                                                </div>
                                                <div v-if="item.defaultFileList.length < 1 && item.defaultImg">
                                                    <img class="ts-img" :src="item.defaultImg" :style="item.defaultStyleImg"/>
                                                </div>
                                                <a-button type="primary" :class="item.defaultImg ? 'ts-upload-button' : ''" >
                                                    {{item.title}}
                                                </a-button>
                                            </div>
                                        </template>
                                    </a-upload>
                                </template>
                                <a-modal :visible="previewTsVisible" :footer="null" @cancel="handleCancel">
                                    <img alt="example" style="width: 100%" :src="previewImage" />
                                </a-modal>
                            </a-form-item>
                        </template>
                         <!-- Upload自定义多个上传组件 -->
                         <template v-if="item.type == 'tsCropperUpload'">
                            <a-form-item :label="item.label" :labelCol="{ span: item.labelCol}" :wrapperCol="{ span: item.wrapperCol, offset: 1 }">
                                <template v-if="item.defaultFileList.length > 0 && checkFileType(item.defaultFileList[0].url, item.defaultFileList[0].name) != 'image' && (item.listType && item.listType === 'picture-card')">
                                    <div v-decorator="item.decorator" class="ant-upload-list-item-info" v-for="items of item.defaultFileList" :key="items.uid">
                                        <a-icon v-if="checkFileType(item.defaultFileList[0].url, item.defaultFileList[0].name) == 'pdf'"
                                            type="file-pdf"
                                            :style="{ fontSize: '60px' }"
                                        />
                                        <a-icon v-if="checkFileType(item.defaultFileList[0].url, item.defaultFileList[0].name) == 'doc'" type="file" :style="{ fontSize: '60px' }"/>
                                        <div class="ant-upload-list-item-actions">
                                            <a-icon
                                                @click="handleTsPreview(items)"
                                                class="anticon-eye-o"
                                                type="eye"
                                            />
                                            <a-icon
                                                @click="upRemove(items)"
                                                class="anticon-delete"
                                                type="delete"
                                            />
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <a-upload v-decorator="item.decorator" :file-list="item.defaultFileList" :remove="upRemove" :data="item" :accept="item.accept ? item.accept : accept"
                                        :customRequest="resourceCropperTsUpload" :list-type="item.listType ? item.listType : 'text'" :multiple="false" :beforeUpload="beforeUpload"
                                        @preview="handleTsPreview" :showUploadList="item.showUploadList ? item.showUploadList : false">
                                        <template v-if="item.listType && item.listType === 'picture-card'">
                                            <div v-if="item.defaultFileList.length < 1">
                                                <a-icon type="plus" />
                                                <!-- <div class="ant-upload-text">
                                                    上传
                                                </div> -->
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="ts-upload-box">
                                                <div v-if="item.defaultFileList.length > 0">
                                                    <img class="ts-img" :src="item.defaultFileList[0].url" />
                                                </div>
                                                <div v-if="item.defaultFileList.length < 1 && item.defaultImg">
                                                    <img class="ts-img" :src="item.defaultImg" />
                                                </div>
                                                <a-button type="primary" :class="item.defaultImg ? 'ts-upload-button' : ''" >
                                                    {{item.title}}
                                                </a-button>
                                            </div>
                                        </template>
                                    </a-upload>
                                </template>
                                <a-modal :title="item.cropperTitle" :width="item.showCroImg ? 1400 : 700" :visible="cropperVisible" @cancel="cropperVisible = false">
                                    <div style="display: flex" class="avatar">
                                        <div class="avatar-left">
                                            <div v-show="options1.img" class="avatar-left-crop">
                                            <vueCropper
                                                class="crop-box"
                                                ref="cropper"
                                                :img="options1.img"
                                                :autoCrop="options1.autoCrop"
                                                :centerBox="options1.centerBox"
                                                :full="options1.full"
                                                :canScale="true"
                                                @realTime="realTime"
                                                >
                                            </vueCropper>
                                            <p class="avatar-left-p">
                                                鼠标滚轮缩放控制图片显示大小，鼠标拖拽调整显示位置</p>
                                            </div>
                                        </div>
                                            <div class="w-100 flex">
                                                <div class="avatar-right mr-2" :style="{width: (item.showCroImg ? 150 + 'px' : 250 + 'px')}">
                                                    <div class="avatar-right-div"  v-for="(item,index) in previewsDiv" :key="index">
                                                        <div v-show="options1.img" :style="[previews.div,item.zoomStyle,item.style]" class="avatar-right-previews">
                                                            <img :src="previews.url" :style="previews.img">
                                                        </div>
                                                    </div>
                                                    <div class="avatar-right-text">
                                                        <span @click="rotateLeft" class="mr-2 pointer" style="color: #62A6F8">左转</span>
                                                        <span @click="rotateRight" class="pointer" style="color: #62A6F8">右转</span>
                                                    </div>
                                                </div>
                                                <div v-if="item.showCroImg" class="croImg flex-1">
                                                    <img alt="croImg" :src="croImg" />
                                                </div>
                                            </div>
                                    </div>
                                        <span slot="footer" class="dialog-footer">
                                            <a-button @click="cropperVisible = false">取 消</a-button>
                                            <a-button type="primary" @click="getCrop">确 定</a-button>
                                        </span>
                                </a-modal>
                            </a-form-item>
                        </template>
                        <!-- textarea文本框 -->
                        <template v-if="item.type == 'textarea'">
                            <a-form-item  :style="item.style" :label="item.label" :labelCol="{ span: item.labelCol}" :wrapperCol="{ span: item.wrapperCol, offset: 1 }">
                                <a-textarea :disabled="item.disabled"  :auto-size="{ minRows: 4, maxRows: 5 }" :style="item.styles" v-decorator="item.decorator" :placeholder="item.placeholder" />
                            </a-form-item>
                        </template>
                        <!-- picker日期 -->
                        <template v-if="item.type == 'datePicker'">
                            <a-form-item :label="item.label" :labelCol="{ span: item.labelCol}" :wrapperCol="{ span: item.wrapperCol, offset: 1 }">
                                <a-date-picker :defaultPickerValue="item.defaultPickerValue" @change="onDateChange" :disabledDate="item.disabledDate" :disabled="item.disabled" :style="item.styles" allowClear :format="item.format" :valueFormat="item.format" v-decorator="item.decorator"
                                :show-time="item.showTime" />
                            </a-form-item>
                        </template>
                        <!-- picker年度 -->
                        <template v-if="item.type == 'yearPicker'">
                            <a-form-item :label="item.label" :labelCol="{ span: item.labelCol}" :wrapperCol="{ span: item.wrapperCol, offset: 1 }">
                                <a-date-picker :disabled="item.disabled" :style="item.styles" allowClear v-decorator="item.decorator"
                                mode="year" @panelChange="changeYear" :format="'YYYY'"/>
                            </a-form-item>
                        </template>
                        <!-- picker月份 -->
                        <template v-if="item.type == 'monthPicker'">
                            <a-form-item :label="item.label" :labelCol="{ span: item.labelCol}" :wrapperCol="{ span: item.wrapperCol, offset: 1 }">
                                <a-month-picker :style="item.styles" :disabled="item.disabled" allowClear :valueFormat="item.format" v-decorator="item.decorator" />
                            </a-form-item>
                        </template>
                       <!-- month开始时间结束时间 -->
                        <template v-if="item.type == 'rangePicker'">
                            <a-form-item :label="item.label" :labelCol="{ span: item.labelCol}" :wrapperCol="{ span: item.wrapperCol, offset: 1 }">
                                <a-range-picker :style="item.styles" :show-time="item.showTime" :valueFormat="item.format" allowClear v-decorator="item.decorator" />
                            </a-form-item>
                        </template>
                        <!-- month月度选择 -->
                        <template v-if="item.type == 'rangeMonthPicker'">
                            <a-form-item :label="item.label" :labelCol="{ span: item.labelCol}" :wrapperCol="{ span: item.wrapperCol, offset: 1 }">
                                <a-range-picker :open="isRangeOpen" @openChange="openRangeChange" @panelChange="handlePanelChange($event,item.key)" :mode="item.mode" :format="item.format" allowClear v-decorator="item.decorator" />
                            </a-form-item>
                        </template>
                        <!-- 省市区联动 -->
                        <template v-if="item.type == 'cascader'">
                            <a-form-item :label="item.label" :labelCol="{ span: item.labelCol}" :wrapperCol="{ span: item.wrapperCol, offset: 1 }">
                                <a-cascader :show-search="{ filter }" v-decorator="item.decorator" :options="options" :placeholder="item.placeholder" @change="onChange"
                                :changeOnSelect="item.changeOnSelect ? item.changeOnSelect : false"/>
                            </a-form-item>
                        </template>
                        <!-- 单选框 -->
                        <template v-if="item.type == 'radio'">
                            <a-form-item :class="item.class" :label="item.label" :labelCol="{ span: item.labelCol}" :wrapperCol="{ span: item.wrapperCol, offset: 1 }">
                                <a-radio-group v-decorator="item.decorator" :disabled="item.disabled" @change="handlSelect($event, item.key)">
                                    <a-radio v-for="items of item.radiolist" :key="items.name" :value="items.value">{{items.name}}</a-radio>
                                </a-radio-group>
                            </a-form-item>
                        </template>
                        <!-- 复选框 -->
                        <template v-if="item.type == 'checkbox'">
                            <a-form-item :class="item.noClass ? '': 'checkbox'" :label="item.label" :labelCol="{ span: item.labelCol}" :wrapperCol="{ span: item.wrapperCol, offset: 1 }">
                                <a-checkbox-group  v-decorator="item.decorator" >
                                    <a-checkbox :disabled="item.disabled" v-for="items of item.checkboxlist" :key="items.name" :value="items.value" :style="item.styles">{{items.name}}</a-checkbox>
                                </a-checkbox-group>
                            </a-form-item>
                        </template>
                        <!-- 复选框 -->
                        <template v-if="item.type == 'checkboxList'">
                            <a-form-item :class="item.noClass ? '': 'checkbox'" :label="item.label" :labelCol="{ span: item.labelCol}" :wrapperCol="{ span: item.wrapperCol, offset: 1 }">
                                <div class="flex">
                                    <a-form-item v-for="(i) in item.checkboxList" :key="i.key">
                                        <a-checkbox-group v-decorator="i.decorator">
                                            <a-checkbox :disabled="items.disabled" v-for="items of i.checkboxItemList" :key="items.name" :value="items.value" :style="items.styles">{{items.name}}</a-checkbox>
                                        </a-checkbox-group>
                                    </a-form-item>
                                </div>
                            </a-form-item>
                        </template>
                        <!-- 选择输入框 -->
                        <template v-if="item.type == 'addonBefore'">
                            <a-form-item  :label="item.label" :labelCol="{ span: item.labelCol}" :wrapperCol="{ span: item.wrapperCol, offset: 1 }">
                                <a-input v-decorator="item.decorator">
                                    <a-select style="width: 100px" slot="addonBefore" v-decorator="item.decorators" >
                                    <a-select-option v-for="items of item.selectList" :key="items.name" :value="items.value">{{items.name}}</a-select-option>
                                    </a-select>
                                </a-input>
                            </a-form-item>
                        </template>
                        <!-- 树型结构选择 -->
                        <template v-if="item.type == 'treeSelect'">
                            <a-form-item   :label="item.label" :labelCol="{ span: item.labelCol}" :wrapperCol="{ span: item.wrapperCol, offset: 1 }">
                                <a-tree-select
                                    :disabled="item.disabled"
                                     @change="handlSelect($event,item.key)"
                                    v-decorator="item.decorator"
                                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                    :tree-data="item.selectlist"
                                    placeholder="请选择"
                                    tree-default-expand-all
                                    :replaceFields="{children:'children', title:'name', key:'id', value:'id' }"
                                    :treeDefaultExpandAll="false"
                                    :allowClear="true"
                                    :show-search="item.showSearch"
                                    tree-node-filter-prop="title"
                            >
                            </a-tree-select>
                            </a-form-item>
                        </template>
                        <!-- action操作按钮 -->
                        <template v-if="item.type == 'action'">
                            <div v-if="flexAction">
                                <slot name="flexAction"></slot>
                            </div>
                        </template>
                        <!-- 自定义表单组件 -->
                        <template v-if="item.type == 'customAction'">
                            <a-form-item  :label="item.label" :labelCol="{ span: item.labelCol}" :wrapperCol="{ span: item.wrapperCol, offset: 1 }">
                                <slot name="customAction"></slot>
                                <slot :name="item.slot"></slot>
                            </a-form-item>
                        </template>
                    </a-col>
                </div>
                <div v-if="flexSubmit">
                    <slot name="flexSubmit"></slot>
                </div>
            </div>
            <div v-if="bottomSubmit" class="bottomSubmit">
                <slot name="bottomSubmit"></slot>
                <slot name="bottomSubmitLeft"></slot>
            </div>
        </a-form>
        <!-- 地图组件 -->
        <div v-if="baiduMapBoolen">
            <baidu-map class="bm-view" :scroll-wheel-zoom="true" @click="getPoint" @ready="mapReady">
                <bm-view style="width: 100%; flex: 1; height: 454px"></bm-view>
                <bm-local-search :keyword="addressKeyword" :auto-viewport="true" style="display: none"></bm-local-search>
                <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT" />
                <div class="bm-complate">
                    <bm-auto-complete v-model="searchJingwei" :sugStyle="{ zIndex: 999999 }">
                        <a-input v-model="searchJingwei" style="width: 300px; margin-right: 15px"
                            placeholder="输入地址"></a-input>
                    </bm-auto-complete>
                    <a-button type="primary" @click="getBaiduMapPoint">搜索</a-button>
                </div>
                <bm-marker :show="show" @dragend="getPoint" :position="{
                    lng: queryParams.longitude,
                    lat: queryParams.latitude
                }" :dragging="true">
                    <bm-info-window :show="show" @close="infoWindowClose" @open="infoWindowOpen">
                        <p>纬度:{{ queryParams.latitude }}</p>
                        <p>经度:{{ queryParams.longitude }}</p>
                    </bm-info-window>
                </bm-marker>
            </baidu-map>
        </div>
    </div>
</template>

<script>
import { request } from '@/utils/request'
import { ATTACHMENT_UPLOAD } from '@/services/api'
import * as imageConversion from 'image-conversion'
import { VueCropper }  from 'vue-cropper'

export default {
    props: {
        // 上 - 提交按钮的显示隐藏
        topFlexSubmit: {
            type: Boolean,
            default: false
        },
        // 上 - 提交按钮的显示隐藏
        topSubmit: {
            type: Boolean,
            default: false
        },
        // 下 - 提交按钮的显示隐藏
        bottomSubmit: {
            type: Boolean,
            default: false
        },
        //  flex - 提交按钮的显示隐藏
        flexSubmit:{
            type: Boolean,
            default: false
        },
        // flexAction - col里的操作action按钮
        flexAction:{
            type: Boolean,
            default: false
        },
        // 地图的显示隐藏
        baiduMapBoolen: {
            type: Boolean,
            default: false
        },
        // 项目名称的显示隐藏
        Overview: {
            type: String,
            default: ''
        },
        // 数据数组
        formData: {
            type: Array,
            default: () => []
        },
        // 数据对象
        projectList: {
            type: Array,
            default: () => []
        },
        //
        chageT: {
            type: Boolean,
            default: false
        },
        // 上传图片数组回显
        defaultFileList: {
            type: Array,
            default: () => []
        },
        // 传入的级联选择数据
        options:{
            type: Array,
            default: () => []
        },
    },
    components: {
        VueCropper
    },
    data() {
        return {
            accept:'.png,.PNG,.jpg,.JPG,.pdf,.PDF,.doc,.docx',//图片上传限制
            previewVisible: false,//判断图片预览弹窗 - 显示/隐藏
            previewTsVisible: false,//判断图片预览弹窗 - 显示/隐藏
            previewImage: '', // 预览图片地址
            queryParams: {
                latitude: '23.123', // 项目纬度
                longitude: '113.274' // 项目经度
            },
            form: this.$form.createForm(this, { name: 'coordinated' }), //初始化form表单
            show: false, // 控制经纬度显示隐藏
            point: '', //地图的参数
            addressKeyword: "",//地图的参数
            searchJingwei: '',//地图的参数
            pathArr: [], //图片上传存储
            params: {}, //提交表单参数
            query:this.$route.query, //路由参数
            isRangeOpen:false,
            //vueCropper组件 裁剪配置信息
            options1: {
                img: '',  //原图文件
                autoCrop: true,  //默认生成截图框
                centerBox: true,    //截图框被限制在图片里面
                full: true,
                autoCropWidth: 200,  //截图框宽度
                autoCropHeight: 200, //截图框高度
                },
            cropperVisible:false,
            //实时预览图数据
            previews: {},
            //实时预览图回显数据
            cropperData: {},
            //实时预览图样式
            previewsDiv: [
                //108px 预览样式
                {
                    style: {
                    // width: '108px',
                    // height: '108px',
                    margin: '0 auto'
                    },
                    zoomStyle: {
                    zoom: 0.54
                    }
                },
                //68px 预览样式
                {
                    style: {
                    // width: '68px',
                    // height: '68px',
                    margin: '80px auto'
                    },
                    zoomStyle: {
                    zoom: 0.34
                    }
                },
                //48px 预览样式
                // {
                //     style: {
                //     // width: '48px',
                //     // height: '48px',
                //     margin: '0 auto'
                //     },
                //     zoomStyle: {
                //     zoom: 0.24
                //     }
                // }
                ],
            croImg:'https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachment/20231215/1702609401363.png?Expires=2017969401&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=9WvNUwpgfTLow0w5HJem34ho2lc%3D'
        }
    },
    watch: {

    },
    created() {
        // 判断路由参数是否存在
        if(this.query.data && Object.keys(this.query.data).length > 0){
            this.queryObj(this.$route.query.data)
        }
    },
    methods: {
        setRules(item) {
            // 添加新的对象到数组中
            let newObject = {pattern: /(^\S)([\s\S]*\S)?(\S*$)/, message: '前后不能有空格' };
            const rules = item[1] ? item[1].rules : [{ required: false }]
            item.rules = this.addObjectToArray(newObject, rules)
            return item
        },
        addObjectToArray(newObj, arr) {
            // 检查数组中是否已经存在相同的对象
            let exists = arr.some(item => item.message === newObj.message);
            // 如果不存在相同的对象，则添加到数组中
            if (!exists) {
                arr.push(newObj);
            }
        },
        openRangeChange(status) {
         if(status) {
            this.isRangeOpen = true;
         } else {
            this.isRangeOpen = false;
         }
        },
        handlePanelChange(val,key) {
            this.isRangeOpen = !this.isRangeOpen;
            this.$emit('changeMonth', {val,key})
        },
        // 年度筛选
        changeYear(e) {
            this.$emit('changeYear', e)
        },
        // 输入下拉框防止第一次数据没变渲染出来
        filterOption(input, option){
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        },
        // 路由key和传入值key匹配
        queryObj(e){
            this.formData.forEach(item => {
                Object.keys(e).forEach(key => {
                    if (item.key === key) {
                        // 匹配上就异步更新数据 nextTick（等数据加载完后，拿到最后的虚拟dom更新最新数据）
                        this.$nextTick(() =>{
                            this.form.setFieldsValue({ [key]: e[key] })
                        })
                    }
                })
            })
        },
        async validateFields() {
            try {
                await this.form.validateFields();
                return true
            } catch (_) {
                return false
            }
        },
        getFieldsValue() {
            return this.form.getFieldsValue()
        },
        // 清空数据
        resetFields() {
            this.form.resetFields()
            this.params = {}
        },
        // 关闭弹窗图片
        handleCancel() {
            this.previewVisible = false;
            this.previewTsVisible = false;
        },
        checkFileType(url, fileName = '') {
            // let url1 = 'https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachment/20230423/1682233399481.png?Expires=1997593399&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=T6aDeMUcvHzoWnJq5yB6QxyawbY%3D'
            const extension = url.split('.').pop().toLowerCase().substring(0,3)
            if (extension === 'pdf' || extension === 'PDF') {
                // 是PDF文档
                return 'pdf'
            } else if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) {
                // 是图像文件
                return 'image'
            } else if (['doc', 'docx'].includes(extension)) {
                // 是Word文档
                return 'doc'
            } else {
                // 尝试判断文件名
                if (fileName) {
                    if (fileName.indexOf('pdf') != -1) {
                        return 'pdf'
                    } else if (fileName.indexOf('jpg') != -1 || fileName.indexOf('jpeg') != -1 || fileName.indexOf('png') != -1 || fileName.indexOf('gif') != -1) {
                        return 'image'
                    } else if (fileName.indexOf('doc') != -1 || fileName.indexOf('docx') != -1) {
                        return 'doc'
                    } else {
                        // 其他类型文件
                        console.log('这是一个其他类型的文件');
                    }
                }
            }
        },
        // 判断是pdf还是图片
        icludesPdf(url, str) {
            if (url) {
                return url.toLowerCase().indexOf(str);
            }
        },
        // 图片上传限制处理
        beforeUpload(file){
            const accept = '.' + file.name.substring(file.name.lastIndexOf(".") + 1);
            if (this.accept.split(',').indexOf(accept) == -1) {
                this.$message.error("只能上传图片、Word文档、PDF文件");
                return false
            }
        },
        // 预览图片
        handlePreview(file) {
            let isimg = this.icludesPdf(file.url, "pdf") == -1;
            if (file.url && isimg) {
                this.previewImage = file.url
                this.previewVisible = true
            }else{
                window.open(file.url);
            }
        },
        // 预览图片
        handleTsPreview(file) {
            let isimg = this.checkFileType(file.url) == 'image'
            if (file.url && isimg) {
                this.previewImage = file.url
                this.previewTsVisible = true
            }else{
                window.open(file.url);
            }
        },
        // 删除文件
        upRemove(file) {
            if (file.type === "Upload") {
                // 获取文件下表
                const deIndex = this.defaultFileList.map(item => item.uid).indexOf(file.uid)
                // 删除对应下标中的数组对象
                this.defaultFileList.splice(deIndex, 1)
            }
            // 赋值图片预览弹窗为空
            this.previewImage = ''
            // 异步处理数据更新
            this.$nextTick(() => {
                this.form.setFieldsValue({ [file.key]: ''})
            })
            this.$emit('upRemove', file)
        },
        /*
        * 图片请求接口
        * type 上传组件的类型
        */
        attachmentUpload(formData, value, type) {
            // ATTACHMENT_UPLOAD图片上传接口
            request(ATTACHMENT_UPLOAD, "post", formData).then(res => {
                if (res.data.code === 0) {
                    const fileObj = {
                        uid: res.data.data.id, //图片id
                        name: res.data.data.name, //图片名称
                        url: res.data.data.path, //图片地址
                        key: value.data.key, //获取对应的上传key名 ，后续移除图片用
                        formData, //源文件上传流
                        type: value.data.type,
                    }
                    if (type === 1) {
                        this.defaultFileList.push(fileObj);
                        this.pathArr = this.defaultFileList.map((file) => file.url)
                        this.previewImage = res.data.data.path;
                        // 异步处理数据更新
                       this.$nextTick(() => {
                           this.setFieldsValue(this.pathArr.toString(), {[value.data.key]: this.pathArr.toString()}, [value.data.key])
                       })
                    } else {
                        this.formData.forEach(item => {
                            if (item.key === value.data.key) {
                                let pathArr = []
                                pathArr.push(res.data.data.path)
                                // 异步处理数据更新
                                this.$nextTick(() => {
                                    this.setFieldsValue(pathArr.toString(), {[value.data.key]: pathArr.toString()}, [value.data.key])
                                })
                            }
                        })
                    }
                    value.onSuccess(res, value.file);
                    this.$emit('resourceUpload', fileObj)
                } else {
                    this.$message.warning(res.message);
                }
            })
        },
        // 上传资源
        resourceUpload(value) {
            const formData = new FormData();
            formData.append('file', value.file);
            // 图片上传
            this.attachmentUpload(formData, value, 1)
        },
        // 自定义上传资源
        async resourceTsUpload(value) {
            // 修复传入空文档报错问题
            if (value.file.size == 0) return (this.$message.error('请勿上传空文件'),this.$nextTick(() => {this.setFieldsValue(undefined, {[value.data.key]: undefined}, [value.data.key])}))
            // 如果需要压缩图片 且图片大于200kb 200kb = 19wSize
            if (value.data.compress && value.file.size > 180000) {
                // 设定上传图片大小 默认190kb
                const compressSize = value.data.compressSize ? value.data.compressSize : 180
                const blob = await imageConversion.compressAccurately(value.file, compressSize);
                // blod转为file 后端只接收file
                value.file  = new File([blob], value.file.name, {type: value.file.type, lastModified: Date.now()})
            }
            const formData = new FormData();
            formData.append('file', value.file);
            // 图片上传
            this.attachmentUpload(formData, value, 2)
        },
        resourceCropperTsUpload(value) {
            this.cropperVisible = true
            this.cropperData = value
            let reader = new FileReader();
            reader.readAsDataURL(value.file);
            reader.onload = e => {
                this.options1.img = e.target.result //base64
            }
        },
        //实时预览数据
        realTime(data) {
            this.previews = data
        },
        //获取截图信息
        getCrop() {
            // 获取截图的 blob 数据
            this.$refs.cropper[0].getCropBlob(async(data) => {
                let fileItem  = new File([data], this.cropperData.file.name, {type: data.type, lastModified: Date.now()})
                if (data.size >180000) {
                    data = await imageConversion.compressAccurately(fileItem, 180);
                }
                fileItem  = new File([data], this.cropperData.file.name, {type: data.type, lastModified: Date.now()})
                const formData = new FormData();
                formData.append('file', fileItem);
                this.attachmentUpload(formData, this.cropperData, 2)
                // 重置
                this.cropperVisible = false
                this.cropperData = {}
            })
        },
        rotateLeft() {
          this.$refs.cropper[0].rotateLeft()
        },
        rotateRight() {
          this.$refs.cropper[0].rotateRight()
        },
        // 地图搜索
        getBaiduMapPoint() {
            let that = this
            let myGeo = new this.BMap.Geocoder()
            myGeo.getPoint(this.searchJingwei, function (point) {
                if (point) {
                    that.show = true
                    that.map.centerAndZoom(point, 15)
                    that.formDataFn('lanAndLat', point)
                }
            })
        },
        // 处理当前数据
        formDataFn(key, point) {
            this.formData.forEach(item => {
                if (item.key == key) {
                    this.form.setFieldsValue({ [key]: `${point.lat} , ${point.lng}` })
                    // 输入地址有值的时候才替换工程地址
                    if (this.searchJingwei) {
                        this.form.setFieldsValue({ 'projAddress': this.searchJingwei })
                    }
                }
            })
        },
        // 地图
        infoWindowClose() {
            this.show = false
        },
        // 地图
        infoWindowOpen() {
            this.show = true
        },
        // 地图数据处理
        getPoint(e) {
            this.queryParams.longitude = e.point.lng
            this.queryParams.latitude = e.point.lat
            this.formDataFn('lanAndLat', e.point)
            this.show = true
        },
        // 地图初始化
        mapReady({ BMap, map }) {
            // 选择一个经纬度作为中心点
            this.point = new BMap.Point(113.274, 23.123)
            map.centerAndZoom(this.point, 12)
            this.BMap = BMap
            this.map = map
        },
        combinationSubmit(){
            const { form: { validateFields } } = this
            return new Promise((resolve, reject) => {
                validateFields().then((values) => {
                    resolve(values);
                })
                .catch((error) => {
                    // reject('失败');
                });
            });
        },
        // 提交表单
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                // 查看是否存在经纬度，有就拆分
                Object.keys(values).forEach(key => {
                    if (key === 'lanAndLat' && values[key] != undefined) {
                        let lanAndLat = values.lanAndLat.split(',')
                        this.queryParams.latitude = lanAndLat[0]
                        this.queryParams.longitude = lanAndLat[1]
                        Object.assign(this.params, this.queryParams)
                    } else if (key === 'lanAndLat' && values[key] == undefined){
                        // 不填-默认广州经纬度
                        this.queryParams.latitude = '23.123'
                        this.queryParams.longitude = '113.274'
                        Object.assign(this.params, this.queryParams)
                    }
                    values[key] = values[key] === '' ? undefined : values[key]
                })
                if (!err) {
                    Object.assign(values, this.params)
                    this.$emit('handleSubmit', values)
                }
            });
        },
        // 关闭
        close() {
            this.$router.go(-1)
        },
        // 文本框值变化时回调
        // handleSearch(value,key){
        //     if(value == ''){
        //         return
        //     }
        //     this.$emit('handleSearch', { value, key })
        // },
        handleChange(value, key) {
            this.params[key] = value
        },
        // 输入选择框触发
        handleSearch(value, item) {
            // 如果为空，清空赋值列表数据
            if(value == ''){
                item.dataSource = []
            }
            this.setFieldsValue('',{ [item.key]: value },'')
            this.$emit('handleChange', { value, item })
        },
        // 获取数据
        getFieldValue(){
            let obj = {}
            this.formData.forEach(item =>{
                // 有key才循环
                if (item.key) {
                    obj[item.key] = this.form.getFieldValue(item.key) === undefined ? '' : this.form.getFieldValue(item.key)
                }
            })
            return obj
        },
        getFieldValueByKey(key) {
            return this.form.getFieldValue(key)
        },
        // 更新特殊数据
        setFieldsValue(e, value, qieryID) {
            // e 自定义新字段的值
            // value 传进来的对象
            // qieryID 自定义新字段名(key)
            if (qieryID) {
                this.params[qieryID] = e
            }
            Object.keys(value).length > 0 && this.form.setFieldsValue(value)
            // Object.entries(value).forEach(([key, val]) => {
            //     this.params[key] = val
            // })
        },
        // 选择触发
        handlSelect(val, keys) {
            if (this.chageT) {
                // 获取当前对象
                const index = this.projectList.findIndex(item =>item.id == val)
                if(index != -1){
                    const e = this.projectList[index]
                    this.setFieldsValue(e.name, {}, keys)
                    this.formData.forEach(item => {
                        // 循环相同key
                        Object.keys(e).forEach(key => {
                            if (item.key == key) {
                                // 赋值到对应key
                                this.form.setFieldsValue({ [key]: e[key] })
                            }
                        })
                    })
                }
            }

            val = val === undefined ? '' : val
            this.$emit('handlSelect', { val, keys })
        },
        // 级别联动触发
        onChange(value) {
            console.log(value);
        },
        filter(inputValue, path) {
            return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
        },
        onDateChange(date, dateString) {
            this.$emit('onDateChange', date)
        }
    }
}
</script>

<style lang="less" scoped>
// .ant-form-item {
//     margin-bottom: 16px;
// }
.plug-form {
    .aIput {
        position: relative;

        .aMap {
            position: absolute;
            right: 0;
            top: 3.5px;
            background: #f5f5f5;
            border: 1px solid #d9d9d9;
            color: rgba(0, 0, 0, 0.25);
            height: 32px;
            line-height: 32px;
            border-radius: 4px;
            padding: 0 5px;
        }
    }

    // inputNumber 去掉右侧加减箭头
    .custom-input-number /deep/ input::-webkit-outer-spin-button,
    .custom-input-number /deep/ input::-webkit-inner-spin-button {
        appearance: none;
    }


    /deep/ .ant-form {
        .radio{
            .ant-form-item-control{
                display: flex;
                flex-direction: row-reverse;
            }
            .ant-radio-wrapper{
                margin-right: 0;
                margin-left: 20px;
                span{
                    padding-right: 0;
                    padding-left: 4px;
                }
            }
        }
        .checkbox{
            .ant-form-item-control{
                display: flex;
                flex-direction: row-reverse;
            }
            .ant-checkbox-wrapper{
                margin-right: 0;
                margin-left: 20px;
                span{
                    padding-right: 0;
                    // padding-left: 4px; // 影响active时样式
                }
            }
        }
        // display: flex;
        // flex-wrap: wrap;
        // align-items: center;
        // justify-content: space-between;
        .ant-upload.ant-upload-select-picture-card{
            width: 60px;
            height: 60px;
            margin-bottom: 0;
        }
        .ant-upload-list-picture-card-container{
            width: 60px;
            height: 60px;
            margin: 0;
        }
        .ant-upload-list-picture-card .ant-upload-list-item{
            width: 60px;
            height: 60px;
            margin: 0 ;
        }
        .ant-col-offset-1{
            margin-left: 10px;
        }
        // .ant-form-item-label{
        //     // width: 140px;
        // }
        // .ant-btn {
        //     // margin-right: 20px !important;
        //     margin-right: 20px // 不要强制 !important
        // }
        .formdiv{
            &::after{
                content: "";
                display: block;
                height: 0;
                clear:both;
                visibility: hidden;
            }

        }
        .Upload{
            /deep/.plug-form[data-v-372326e8] .ant-form .ant-row{
                margin-bottom: 0 !important;
            }
            .ant-col{
                height: 64px;
            }
        }
        .topSubmit{
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
        }
        .bottomSubmit{
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            margin-right: 160px;
        }

        .ant-row {
            display: flex;
        }
    }

    .bm-view {
        position: relative;

        .bm-complate {
            position: absolute;
            bottom: 405px;
            left: 15px;
        }
    }

    /deep/v-deep .BaiduMap .el-card .el-card__body {
        padding: 0;
    }
}

.ant-upload-list-item-info {
  position: relative;
  display: inline-block;
  height: 100%;
  overflow: hidden;
  padding: 0;
  transition: background-color 0.3s;
    .ant-upload-list-item-actions {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 10;
        white-space: nowrap;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: all 0.3s;
        .anticon-delete,
        .anticon-eye-o {
            z-index: 10;
            width: 16px;
            margin: 0 4px;
            color: hsla(0, 0%, 100%, 0.85);
            font-size: 16px;
            cursor: pointer;
            transition: all 0.3s;
        }
        .anticon-delete:hover,
        .anticon-eye-o:hover {
            color: #fff;
        }

    }
    .ant-upload-list-item-actions:hover {
        opacity: 1;
    }
}
.ant-upload-list-item-info:hover{
    opacity: 1;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.5);
}
.ant-upload-list-item-info:before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.3s;
  content: " ";
}

.ts-upload-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .ts-img {
        width: 100%;
        height: 88px;
    }
    .ts-upload-button {
        margin-top: 10px;
    }
}

.avatar {
    display: flex;

    .avatar-left {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 400px;
      height: 400px;
      background-color: #F0F2F5;
      margin-right: 10px;
      border-radius: 4px;

      .avatar-left-crop {
        width: 400px;
        height: 400px;
        position: relative;

        .crop-box {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          overflow: hidden
        }

      }

      .avatar-left-p {
        text-align: center;
        width: 100%;
        position: absolute;
        bottom: 0px;
        color: #ffffff;
        font-size: 14px;
      }
    }

    .avatar-right {
      width: 150px;
      height: 400px;
      background-color: #F0F2F5;
      border-radius: 4px;
      padding: 16px 0;
      box-sizing: border-box;
      overflow: hidden;
      .avatar-right-div {
        // border: 3px solid #ffffff;
        border-radius: 50%;

      }

      .avatar-right-previews {
        // width: 200px;
        // height: 200px;
        overflow: hidden;
        // border-radius: 50%;
      }

      .avatar-right-text {
        text-align: center;
        margin-top: 10px;
        font-size: 14px;

        /deep/ .el-button {
          padding: 0;
        }

        span {
          color: #666666;
        }
      }
    }
  }

  .croImg {
    width: 100%; /* 设置容器宽度 */
    height: auto; /* 高度自适应 */
    overflow: hidden; /* 超出部分隐藏 */
    img {
        width: 100%; /* 图像宽度占满容器 */
        height: auto; /* 高度自适应 */
        display: block; /* 去除底部间距 */
    }
  }
</style>
