import { render, staticRenderFns } from "./addNewFallingRecord.vue?vue&type=template&id=c5b29f12&scoped=true&"
import script from "./addNewFallingRecord.vue?vue&type=script&lang=js&"
export * from "./addNewFallingRecord.vue?vue&type=script&lang=js&"
import style0 from "./addNewFallingRecord.vue?vue&type=style&index=0&id=c5b29f12&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5b29f12",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\admin\\Desktop\\SJ\\【劳务机施】suijian_client\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c5b29f12')) {
      api.createRecord('c5b29f12', component.options)
    } else {
      api.reload('c5b29f12', component.options)
    }
    module.hot.accept("./addNewFallingRecord.vue?vue&type=template&id=c5b29f12&scoped=true&", function () {
      api.rerender('c5b29f12', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/equipmentLedger/detail/addNewFallingRecord.vue"
export default component.exports