<template>
  <div>
    <div class="a_Approval" v-show="$route.meta.showFather">
      <div class="plug-Overview">动态扣分台账</div>
      <a-divider />
      <form-index
        ref="formindex"
        :formData="formData"
        flexAction
        @handleSubmit="handleSubmit"
        @handlSelect="handlSelect"
        @changeYear="changeYear"
      >
        <!-- 按钮插槽 -->
        <template #flexAction>
          <a-space class="btnBox">
            <a-button type="primary" html-type="submit">查询</a-button>
          </a-space>
        </template>
      </form-index>
      <div class="a_a_footer">
        <!-- 表格封装 -->
        <standard-table
          class=""
          :columns="columns"
          :dataSource="dataSource"
          rowKey="id"
          :pagination="pagination"
          :loading="tableLoading"
          @change="onPageChange"
          :isAlert="false"
          :bordered="true"
          :btnArr="tableBtnArr"
        >
          <template slot="tableTitle">
            <div>
              <span>累计扣分：<span class="font-bold font-20">{{totalScore}}</span></span>
              <span class="ml-3">所属项目累计平均分：<span class="font-bold font-20">{{averageScore}}</span></span>
            </div>
          </template>
          <template slot="index" slot-scope="{ index }">
            <span>
              {{
                (pagination.current - 1) * pagination.pageSize +
                parseInt(index) +
                1
              }}
            </span>
          </template>
          <template slot="company" slot-scope="{ record }">
            <span title="满60分或该单位在省内所有被记分项目平均记分值达到40分的，约谈单位负责人；
满80分或该单位所有被记分项目平均记分值达到50分以上的，列为重点监管对象。" style="white-space: pre-wrap;" :style="scoreColor(record.company)">{{ record.company }}</span>
          </template>
          <template slot="majorPeople" slot-scope="{ record }">
            <span title="满40分或在所属项目的平均记分值达到30分以上的，被约谈；
满50分的或在所属项目的平均记分值达到40分以上的，列为重点监管对象。" style="white-space: pre-wrap;">{{ record.majorPeople }}</span>
          </template>
          <template slot="projectPeople" slot-scope="{ record }">
            <span title="满40分，被约谈；
满50分，列为重点监管对象。" style="white-space: pre-wrap;">{{ record.projectPeople }}</span>
          </template>
          <template slot="safePeople" slot-scope="{ record }">
            <span title="满40分，被约谈；
满50分，列为重点监管对象。" style="white-space: pre-wrap;">{{ record.safePeople }}</span>
          </template>
          <template slot='comment' slot-scope='{ record }'>
            <a-tooltip placement='topLeft'>
              <template slot='title'>
                {{ record.comment }}
              </template>
              <div class='overtext-1'>
                <span :style="dateColor(record.createTime)">{{ record.comment }}</span>
              </div>
            </a-tooltip>
          </template>
          <template slot="action" slot-scope="{ record }">
            <div class="sTables">
              <div @click="visit(record)">查看</div>
              <div @click="del(record)" v-show="!$route.meta.isBreak">删除</div>
            </div>
          </template>
        </standard-table>
        <Export-loading
          :cPercent="cPercent"
          :isExporting="isExporting"
          :loadingText="loadingText"
        ></Export-loading>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import { QUERY_OFFICE_BY_USER } from "@/services/api";
import StandardTable from "@/components/table/StandardTable";
import { request } from "@/utils/request";
import { mapGetters } from 'vuex'
import { getSubPointPage, getSubPointTotal, delSubPoint } from "@/services/dynamicDeductPoint";
import { exportProgress } from "@/pages/aqjyzl/export"; // 导出方法
import ExportLoading from "@/components/ExportLoading/ExportLoading"; // progress组件
import moment from "moment";
import { SUB_POINT_EXPORT } from "@/services/api";
const formData = [
  {
    label: "选择年份",
    placeholder: "请选择年份",
    decorator: [
      "year",
      {
        initialValue: moment(new Date()).format('YYYY'),
        rules: [{ required: false, message: "请选择" }],
      },
    ],
    type: "yearPicker",
    key: "year",
    col: 5,
    labelCol: 8,
    wrapperCol: 14,
    display: true,
    styles: {width: '100%'}
  },
  {
    label: "工程名称",
    placeholder: "请选择工程名称",
    decorator: [
      "projectName",
      { rules: [{ required: false, message: "请选择工程名称" }] },
    ],
    type: "input",
    key: "projectName",
    col: 5,
    labelCol: 8,
    wrapperCol: 14,
  },
  {
    label: '工程管理部',
    placeholder: '请选择工程管理部',
    decorator: ['gcglbId', { rules: [{ required: false, message: '请选择工程管理部' }] }],
    type: 'select',
    key: 'gcglbId',
    selectlist: [],
    qb:true,
    col: 5,
    labelCol: 8,
    wrapperCol:14,
  },
  {
    type: 'action',
    col: 2
  }
];
const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
  },
  {
    title: "工程名称",
    dataIndex: "projectName",
    width: 205,
    ellipsis: true,
    tooltip: true
  },

  {
    title: "工程管理部",
    dataIndex: "gcglbName",
    align: "center",
    width: 110,
    scopedSlots: { customRender: "gcglbName" },
  },
  {
    title: "施工企业扣分情况",
    dataIndex: "company",
    width: 200,
    align: "center",
    // ellipsis: true,
    scopedSlots: { customRender: "company" },
  },
  {
    title: "施工企业主要负责人累计扣分情况",
    dataIndex: "majorPeople",
    align: "center",
    width: 260,
    scopedSlots: { customRender: "majorPeople" },
  },
  // {
  //   title: "施工企业项目负责人累计扣分情况",
  //   dataIndex: "projectPeople",
  //   width: 150,
  //   align: "center",
  //   key: "projectPeople",
  //   scopedSlots: { customRender: "projectPeople" },
  // },
  // {
  //   title: "施工企业专职安全员累计扣分情况",
  //   dataIndex: "safePeople",
  //   width: 150,
  //   align: "center",
  //   key: "safePeople",
  //   scopedSlots: { customRender: "safePeople" },
  // },
  {
    title: "备注",
    dataIndex: "comment",
    scopedSlots: { customRender: "comment" },
    ellipsis: true,
  },
  {
    title: "操作",
    align: "center",
    width: 130,
    scopedSlots: { customRender: "action" },
  },
];
export default {
  mixins: [exportProgress], //导出方法注入
  components: {
    formIndex,
    StandardTable,
    ExportLoading, //导出数据加载封装
  },
  data() {
    return {
      formData: formData,
      columns: columns,
      dataSource: [], //table数组
      averageScore: '',
      totalScore: '',
      pagination: {
        //分页数据
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total, range) => `共 ${total} 条`,
      },
      params: {
        //测试数据
        pageNumber: 1,
        pageSize: 10,
        year: moment(new Date()).format('YYYY')
      },
      tableLoading: false, //table加载中
      loadingText: "数据加载中...",
      cPercent: 0,
      isExporting: false,
      tableBtnArr: [
        {
          name: '新增',
          attrs: {
            type: 'primary'
          },
          click: this.addNewRecord,
          hidden: this.$route.meta.isBreak
        },
        {
          name: '导出',
          attrs: {
            type: 'primary'
          },
          click: this.doDowload
        }
      ]
    };
  },
  props: {
    gcglbList: {
      type: Array,
      request: true,
      default: () => []
    }
  },
  watch: {
    gcglbList: {
      handler(newVal) {
        this.formDataFn("gcglbId", newVal);
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters('account', ['user']),
  },
  activated() {
    if(this.user.isGcglb != 0 || this.user.loginType != 1) {
      this.formData = this.formData.filter(item => item.key !== 'gcglbId')
      this.tableBtnArr = this.tableBtnArr.filter(item => item.name != '新增')
    }
    if(this.user.isGcglb == 0 && this.user.loginType == 0) {
      this.formData = this.formData.filter(item => item.key !== 'gcglbId').filter(item => item.key !== 'projectName')
    }
    this.init()
  },
  methods: {
    init() {
      this.getgcglbList(QUERY_OFFICE_BY_USER);
      this.aqxjlist(this.params);
      this.getSubPointTotal(this.params);
    },
     /**
     * 导出excel
     */
     doDowload() {
      let obj = this.$refs["formindex"].getFieldValue();
      this.loadingText = "正在导出EXCEL文件，请稍候 ····";
      this.isExporting = true;
      this.cPercent = 0;
      let fileName = "动态扣分台账.xlsx";
      this.exportFunNomal2(
        SUB_POINT_EXPORT,
        obj,
        fileName,
        "application/vnd.ms-excel"
      );
    },
    getSubPointTotal(params) {
      delete params.pageNumber
      delete params.pageSize
      getSubPointTotal(params).then(res => {
        if (res.data.code === 0) {
          this.totalScore = res.data.data.total
          this.averageScore = res.data.data.average
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    aqxjlist(params) {
      this.tableLoading = true;
      getSubPointPage(params).then((res) => {
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
        } else {
          this.$message.error(res.data.msg)
        }
        this.tableLoading = false;
      });
    },
    // 获取工程管理部数据
    getgcglbList(path) {
      request(path, "get").then((res) => {
        let data = res.data.data.filter(
          (item) => item.id != "3da0f5533f87425c9a9e8a11a7923300"
        );
        this.formDataFn("gcglbId", data);
      });
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      let arr = [];
      data.forEach((item) => {
        arr.push({ name: item.master, value: item.id });
      });
      this.formData.forEach((item) => {
        if (item.key == key) {
          item.selectlist = arr;
        }
      });
    },
    addNewRecord() {
      this.$router.push({
        name: '新增动态扣分',
        query: {
          // facilityId: this.params.facilityId,
          // facilityType: this.params.facilityType,
          isEdit: 0,
        },
      });
    },
    handleSubmit(e) {
      let obj = {...this.params, ...e };
      this.aqxjlist(obj);
      this.getSubPointTotal(obj);
    },
    handlSelect(obj) {
      const { val, keys } = obj;
    },
    //表格 - 分页查询
    onPageChange(e) {
      this.params.pageNumber = e.current;
      this.pagination.current = e.current;
      this.aqxjlist(Object.assign({}, this.params));
    },
    //查看
    visit(record) {
      this.$router.push({
        name: '动态扣分详情',
        query: {
          id: record.id,
          isEdit: 1
        },
      });
    },
    del(record) {
      const _that = this
      this.$confirm({
          title: '是否确定删除？',
          cancelText: '否',
          okText: '是',
          onOk: () => {
            delSubPoint(record.id).then(res => {
              if (res.data.code === 0) {
                _that.$message.success('删除成功')
                this.aqxjlist(this.params);
              } else {
                _that.$message.error(res.data.msg)
              }
            })
          },
        })
    },
    handlePreview(item) {
      // if (item.type == "pdf") {
      //   // 打开新页面
        window.open(item.usingFile);
      // } else {
      //   // 打开预览
      //   this.previewImage = item.url;
      //   this.previewVisible = true;
      // }
    },
    dateColor(date) {
      if (moment(new Date()).diff(moment(date),'days') < 30) {
        return 'color: #FF5D78'
      }
    },
    scoreColor(score) {
      if (score > 40) {
        return 'color: #FF5D78'
      }
    },
    changeYear(e) {
      this.$refs.formindex.queryObj({
        year: e.format('YYYY'),
      })
    },
  },
};
</script>

<style lang="less" scoped>
.a_Approval {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
}
.a_a_footer {
  padding: 20px;
  background: #ffffff;

  .operator {
    margin-right: 8px;
  }

  .sTables {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      width: 100%;
      text-align: center;
      cursor: pointer;
    }

    div:nth-child(1) {
      color: #307dfa;
    }

    div:nth-child(2) {
      color: #ff5c77;
    }

    div:nth-child(3) {
      color: #615e83;
    }
  }
}
.content_div {
  padding: 5px 0;
  cursor: pointer;
}
.content_div:hover {
  color: #307dfa;
}
</style>
