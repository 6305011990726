<template>
  <a-card>
    <div :class="advanced ? 'search' : null">
        <a-form layout="horizontal">
          <div :class="advanced ? null: 'fold'">
            <a-row >
              <a-col :md="8" :sm="24" >
                <a-form-item
                  label="检查时间"
                  :labelCol="{span: 5}"
                  :wrapperCol="{span: 18, offset: 1}"
                >
                  <a-range-picker  v-model="queryParams.plan_date"  :format="dateFormat"  @change="changeDate"/>
                </a-form-item>
              </a-col>
              <a-col :md="5" :sm="24">
                <a-form-item
                    label="检查部门"
                    :labelCol="{span: 10}"
                    :wrapperCol="{span: 13, offset: 1}"
                >
                  <a-select style="width:115px" v-model="queryParams.qtType">
                    <a-select-option :value="1">安全部</a-select-option>
                    <a-select-option :value="2">工程管理部</a-select-option>
                    <a-select-option :value="3">项目部</a-select-option>
                    <!-- <a-select-option :value="3">带班检查</a-select-option>
                    <a-select-option :value="4">停工单</a-select-option> -->
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
          </div>
          <span style="float: right; margin-top: -64px;">
            <a-button type="primary" @click="doQuery()">查询</a-button>
            <a-button style="margin-left: 8px" @click="doReset()">重置</a-button>
             <a-button style="margin-left: 8px" @click="doExport()">导出台账</a-button>
          </span>
        </a-form>
      </div>
      <standard-table
          :columns="columns"
          :dataSource="dataSource"
          :selectedRows.sync="selectedRows"
          :pagination="pagination"
          :loading="tableLoading"
          :isAlert="false"
          @clear="onClear"
          @change="onPageChange"
        >
          <div slot="do-status" slot-scope="{text}">
            <label v-if="text==4">停工处罚单</label>
            <label v-if="text==3">领导带班检查</label>
            <label v-if="text==1">公司常规检查</label>
            <label v-if="text==99">项目自检</label>
          </div>
          <div slot="do-problems" slot-scope="{text}">
            <label v-html="text"></label>
          </div>
          <div slot="do-answers" slot-scope="{text}">
            <label v-html="text"></label>
          </div>
      </standard-table>
       <Export-loading :cPercent="cPercent" :isExporting="isExporting" :loadingText="loadingText"></Export-loading>
  </a-card>
</template>

<script>
import { exportProgress } from '@/pages/aqjyzl/export' // 导出方法
import ExportLoading from '@/components/ExportLoading/ExportLoading' // progress组件
import StandardTable from '@/components/table/StandardTable'
import {PROJECT_AQXJ_GZTZ,PROJECT_AQXJ_GZTZ_EXPORT} from '@/services/api'
import {request} from '@/utils/request'
import moment from 'moment';
import { useRender } from '@/hooks/useRender.js'

  const columns = [
  {
    title: '检查时间',
    dataIndex: 'xjTime',
    align: 'center',
    customRender: (text) => useRender.renderDate(text)
  },{
    title: '检查类别',
    dataIndex: 'planType',
    ellipsis:false,
    scopedSlots: { customRender: 'do-status' }
  },
  {
    title: '隐患部位',
    dataIndex: 'stopPoint',
    align: 'center',
    scopedSlots: { customRender: 'stopPoint' }
  },
  // ,
  {
    title: '隐患问题',
    dataIndex: 'problems',
    align: 'left',
    scopedSlots: { customRender: 'do-problems' }
  },
  {
    title: '整改情况',
    dataIndex: 'answers',
    align: 'left',
    scopedSlots: { customRender: 'do-answers' }
  },
    {
    title: '整改责任人',
    dataIndex: 'zgzrr',
    align: 'center',
    scopedSlots: { customRender: 'zgzrr' }
  }
  ];

export default {
  mixins: [exportProgress],
  name: 'QueryList',
  components: {StandardTable,ExportLoading},
  data () {
    return {
      firstDay:null,
      nowDay:null,
      dataSource:[],
      loadingText:'正在下载文件...',
      loadKeys:[],
      advanced: false,
      columns: columns,
      tableLoading:false,
      selectedRows: [],
      dateFormat: 'YYYY-MM-DD',
      queryParams:{
        pageNumber:1,
        plan_date:null,
        endDate:null,
        startDate:null,
        qtType:1
      },
      pagination:{
        current:1,
        pageSize:10,
        total:0,
        showLessItems:true,
        showQuickJumper:true
      },
      cPercent:0,
      isExporting:false,
    }
  },
  created() {
    this.firstDay = moment(new Date()).format('YYYY-MM')+"-01";
    this.nowDay = moment(new Date()).format('YYYY-MM-DD');
    this.doQuery();

  },
  watch: {
    cPercent:{
      handler(newName, oldName) {
        this.cPercent=newName;
      },
      immediate: true,
    },
    loadingText:{
      handler(newName, oldName) {
        this.loadingText=newName;
      },
      immediate: true,
    }
  },
  methods: {
    moment,
    deleteRecord(key) {
      this.dataSource = this.dataSource.filter(item => item.key !== key)
      this.selectedRows = this.selectedRows.filter(item => item.key !== key)
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    remove () {
      this.dataSource = this.dataSource.filter(item => this.selectedRows.findIndex(row => row.key === item.key) === -1)
      this.selectedRows = []
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },
     /**
     * 获取所有人员的签名记录信息
     */
    doQuery(){
      this.tableLoading = true;
      if(this.queryParams.plan_date&&this.queryParams.plan_date.length>0){
        this.queryParams.startDate = this.queryParams.plan_date[0].format('YYYY-MM-DD')
        this.queryParams.endDate = this.queryParams.plan_date[1].format('YYYY-MM-DD')
      }
      request(PROJECT_AQXJ_GZTZ,"post",this.queryParams).then(res => {
        if(res.data.code==0){
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
          this.dataSource.forEach((obj,index)=>{
            obj.key = obj.id;
          })
        }
        this.tableLoading = false;
      })
    },
    doReset(){
      this.queryParams={queryType:1};
      this.doQuery();
    },
    //分页查询
    onPageChange(e){
      this.queryParams.pageNumber = e.current;
      this.pagination.current=e.current;
      this.doQuery();
    },
    changeDate(e){
      if(e==null||e.length<=0){
        this.queryParams.startDate = null;
        this.queryParams.endDate = null
      }
    },
    doExport(){
      this.loadingText = "正在导出EXCEL文件，请稍候 ····";
      let name = "项目隐患"
      if(this.queryParams.plan_date&&this.queryParams.plan_date.length>0){
        this.queryParams.startDate = this.queryParams.plan_date[0].format('YYYY-MM-DD')
        this.queryParams.endDate = this.queryParams.plan_date[1].format('YYYY-MM-DD')
        name = this.queryParams.startDate+"至"+this.queryParams.endDate
      }
      this.isExporting=true;
      this.cPercent=0;
      let fileName=name+"-安全隐患台账.xlsx";
      this.exportFunNomal2(PROJECT_AQXJ_GZTZ_EXPORT,this.queryParams,fileName,'application/vnd.ms-excel')
    }
  }
}
</script>

<style lang="less" scoped>
  .search{
    margin-bottom: 54px;
  }
  .fold{
    width: calc(100% - 216px);
    display: inline-block
  }
  .operator{
    margin-bottom: 18px;
  }
  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
</style>
