var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("form-index", {
        ref: "formindex",
        attrs: {
          formData: _vm.formData,
          projectList: _vm.projectList,
          flexAction: true,
        },
        on: {
          handleSubmit: _vm.handleSubmit,
          handlSelect: _vm.handlSelect,
          handleChange: _vm.handleChange,
        },
        scopedSlots: _vm._u([
          {
            key: "flexAction",
            fn: function () {
              return [
                _c(
                  "a-space",
                  { style: { marginTop: "4px" } },
                  [
                    _c(
                      "a-button",
                      { attrs: { type: "primary", "html-type": "submit" } },
                      [_vm._v(" 查询 ")]
                    ),
                    _c(
                      "a-space",
                      { staticClass: "operator" },
                      [
                        _c("a-button", { on: { click: _vm.close } }, [
                          _vm._v(" 重置 "),
                        ]),
                      ],
                      1
                    ),
                    _c("a-button", { on: { click: _vm.exportExcelStaff } }, [
                      _vm._v("导出"),
                    ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "a_a_footer" },
        [
          _c("div"),
          _c(
            "standard-table",
            {
              attrs: {
                columns: _vm.columns,
                dataSource: _vm.dataSource,
                rowKey: "index",
                scroll: { x: 1800, y: "calc(100vh - 430px)" },
                pagination: _vm.pagination,
                loading: _vm.tableLoading,
                isAlert: false,
                bordered: true,
              },
              on: { change: _vm.onPageChange },
              scopedSlots: _vm._u(
                [
                  {
                    key: "index",
                    fn: function (ref) {
                      var index = ref.index
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              (_vm.pagination.current - 1) *
                                _vm.pagination.pageSize +
                                parseInt(index) +
                                1
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                  _vm._l(_vm.days, function (day, index1) {
                    return {
                      key: _vm.params["month"] + day,
                      fn: function (ref) {
                        var record = ref.record
                        return [
                          _c(
                            "div",
                            { key: index1 },
                            [
                              record[_vm.params["month"] + day] &&
                              record[_vm.params["month"] + day].length > 2
                                ? [
                                    _c(
                                      "div",
                                      { staticStyle: { cursor: "pointer" } },
                                      [
                                        _c(
                                          "a-popover",
                                          { attrs: { placement: "right" } },
                                          [
                                            _c(
                                              "template",
                                              { slot: "content" },
                                              _vm._l(
                                                record[
                                                  _vm.params["month"] + day
                                                ],
                                                function (obj, index2) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: index2,
                                                      staticClass:
                                                        "flex a-center",
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(obj["time"])
                                                        ),
                                                      ]),
                                                      obj["checkType"] == 1
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "ml-05 font-12 in-span",
                                                            },
                                                            [_vm._v("进")]
                                                          )
                                                        : _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "ml-05 font-12 out-span",
                                                            },
                                                            [_vm._v("出")]
                                                          ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                            _c("div", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "flex a-center",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getEarliestTime(
                                                          record[
                                                            _vm.params[
                                                              "month"
                                                            ] + day
                                                          ]
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                  _vm.getEarliestTime(
                                                    record[
                                                      _vm.params["month"] + day
                                                    ]
                                                  )
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "ml-05 font-12 in-span",
                                                        },
                                                        [_vm._v("进")]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "flex a-center",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getLatestTime(
                                                          record[
                                                            _vm.params[
                                                              "month"
                                                            ] + day
                                                          ]
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                  _vm.getLatestTime(
                                                    record[
                                                      _vm.params["month"] + day
                                                    ]
                                                  )
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "ml-05 font-12 out-span",
                                                        },
                                                        [_vm._v("出")]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : record[_vm.params["month"] + day] &&
                                  record[_vm.params["month"] + day].length <=
                                    2 &&
                                  record[_vm.params["month"] + day].length > 0
                                ? [
                                    _c(
                                      "div",
                                      _vm._l(
                                        record[_vm.params["month"] + day],
                                        function (obj, index2) {
                                          return _c(
                                            "div",
                                            {
                                              key: index2,
                                              staticClass: "flex a-center",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(obj["time"])),
                                              ]),
                                              obj["checkType"] == 1
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "ml-05 font-12 in-span",
                                                    },
                                                    [_vm._v("进")]
                                                  )
                                                : _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "ml-05 font-12 out-span",
                                                    },
                                                    [_vm._v("出")]
                                                  ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      },
                    }
                  }),
                ],
                null,
                true
              ),
            },
            [
              _c("template", { slot: "title" }, [
                _c("h3", { staticClass: "table-title" }, [
                  _vm._v(_vm._s(_vm.tableTitle)),
                ]),
                _c("div", { staticClass: "table-desc" }, [
                  _c("span", [
                    _vm._v(
                      "项目名称（盖章）：" +
                        _vm._s(
                          _vm.user.loginType == 0
                            ? _vm.user.loginLaoWuUser.projectName
                            : ""
                        )
                    ),
                  ]),
                  _c("div", [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.params.officeName,
                            expression: "params.officeName",
                          },
                        ],
                      },
                      [
                        _vm._v(
                          "单位：" +
                            _vm._s(
                              _vm.params.officeName
                                ? _vm.params.officeName
                                : "全部"
                            )
                        ),
                      ]
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.params.teamName,
                            expression: "params.teamName",
                          },
                        ],
                        staticStyle: { "margin-left": "50px" },
                      },
                      [
                        _vm._v(
                          "班组：" +
                            _vm._s(
                              _vm.params.teamName ? _vm.params.teamName : "全部"
                            )
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("Export-loading", {
        attrs: {
          cPercent: _vm.cPercent,
          isExporting: _vm.isExporting,
          loadingText: _vm.loadingText,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }