/**
 * @description 记录来源-值枚举
 */
export const TYPE_ENUM = Object.freeze({
  // 手工登记
  MANUAL: 0,
  // AI识别
  AI: 1,
})
/**
 * @description 系统类型-文本映射
 */
export const TYPE_NAME_MAP = Object.freeze({
  [TYPE_ENUM.MANUAL]: "手工登记",
  [TYPE_ENUM.AI]: "AI识别",
})