<template>
  <div style="padding:20px 50px; background:#FFF">
    <detail-list :col="2">
      <detail-list-item term="直播主题">{{livebroadcast.title}}</detail-list-item>
      <detail-list-item term="直播类型">{{livebroadcast.type | typeStr }}</detail-list-item>
      <detail-list-item term="直播时间">{{livebroadcast.startTime}} ~ {{livebroadcast.endTime}}</detail-list-item>
      <detail-list-item term="主播微信号">{{livebroadcast.anchorWechat}}</detail-list-item>
      <detail-list-item term="主播昵称">{{livebroadcast.anchorName}}</detail-list-item>
      <detail-list-item term="申请人">{{livebroadcast.createdUser}}</detail-list-item>
      <detail-list-item term="直播背景图">
        <img style="width:200px;height:150px" :src="livebroadcast.coverPath"/>
      </detail-list-item>
      <detail-list-item term="购物封面图">
        <img style="width:200px;height:150px" :src="livebroadcast.feedsPath"/>
      </detail-list-item>
    </detail-list>
    <detail-list :col="1">
      <detail-list-item term="直播分享图">
        <img style="width:200px;height:150px" :src="livebroadcast.sharePath"/>
      </detail-list-item>
      <detail-list-item term="直播描述">{{livebroadcast.remark}}</detail-list-item>
      <detail-list-item term="审批意见">{{livebroadcast.approveRemark}}</detail-list-item>
      <detail-list-item term="审批状态">{{livebroadcast.approveStatus | statusStr}}</detail-list-item>
    </detail-list>
    <div class="subBtn">
      <a-button style="margin-left:10px;margin-right:50px" @click="doBack"> 返回 </a-button>                
    </div>
  </div>
</template>

<script>
import DetailList from '@/components/tool/DetailList'
import {LIVE_BROADCAST_DETAIL } from "@/services/api";
import { request } from "@/utils/request";
const DetailListItem = DetailList.Item
export default {
  name: "liveApprove",
  components: { DetailList,DetailListItem},
  filters: {
    statusStr(val) {
      switch (val) {
        case 0: return '待审核'
        case 1: return '允许创建'
        case 2: return '已拒绝'
      }
    },
    typeStr(val) {
      switch (val) {
        case 0: return '会议直播'
        case 1: return '直播带货'
        case 2: return '直播秀'
        case 3: return '其他'
      }
    }
  },
  data() {
    return {
      livebroadcast:{}
    };
  },
  // props: {
  //   visible: {
  //     type: Boolean,
  //     required: true,
  //     default: false
  //   },
  //   livebroadcast: {
  //     type: Object,
  //     required: true,
  //     default() {
  //       return {}
  //     }
  //   }
  // },
  created() {
    this.objId = this.$route.query.id;
    if(this.objId){
      this.doDetail(this.objId);
    }
  },
  methods: {
    /**
     * 返回
     */
    doBack() {
      this.$router.go(-1);
    },
    doDetail(id){
      request(LIVE_BROADCAST_DETAIL+id, "get", {}).then((res) => {
        if (res.data.code === 0) {
          this.livebroadcast = res.data.data;
        }else{
          this.$message.error("直播详情获取失败！")
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
  .subBtn{
    width: 100%;
    text-align:right;
    background:#FFF;
    padding-bottom:20px
  }
</style>