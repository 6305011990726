module.exports = {
  messages: {
    CN: {
      introduce: '指标说明',
      totalSales: '总销售额',
      visits: '访问量',
      payments: '支付笔数',
      operating: '运营活动效果',
      wow: '同周比',
      dod: '日环比',
      sales: '销售额',
      daily: '日均',
      day: '今日',
      week: '本周',
      month: '本月',
      year: '本年',
      search: '热门搜索',
      proportion: '销售额占比',
      conversion: '转化率',
      trend: '趋势',
      ranking: '排行榜',
      all: '全渠道',
      online: '线上',
      stores: '门店',
    },
    HK: {
      introduce: '指標說明',
      totalSales: '總銷售額',
      visits: '訪問量',
      payments: '支付筆數',
      operating: '運營活動效果',
      wow: '通周比',
      dod: '日環比',
      sales: '銷售額',
      daily: '日均',
      day: '今日',
      week: '本週',
      month: '本月',
      year: '本年',
      search: '熱門搜索',
      proportion: '銷售額佔比',
      conversion: '轉化率',
      trend: '趨勢',
      ranking: '排行榜',
      all: '全渠道',
      online: '線上',
      stores: '門店',
    },
    US: {
      introduce: 'Introduce',
      totalSales: 'Total Sales',
      visits: 'Visits',
      payments: 'Payments',
      operating: 'Operational Effect',
      wow: 'WoW Change',
      dod: 'DoD Change',
      sales: 'Sales',
      daily: 'Daily',
      day: 'All Day',
      week: 'All Week',
      month: 'All Month',
      year: 'All Year',
      search: 'Hot Search',
      proportion: 'The Proportion Of Sales',
      conversion: 'Conversion Rate',
      trend: 'Trend',
      ranking: 'Ranking',
      all: 'All',
      online: 'Online',
      stores: 'Stores',
    }
  }
}
