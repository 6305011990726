<template>
    <a-descriptions class="content-descriptions" :column="3" bordered>
        <a-descriptions-item label="发送人">
            {{ messageInfo.receiverName }}
        </a-descriptions-item>
        <a-descriptions-item label="发送时间">
            {{ messageInfo.createTime }}
        </a-descriptions-item>
        <a-descriptions-item label="通知类型">
            {{ messageInfo.typeText }}
        </a-descriptions-item>
        <a-descriptions-item label="内容"  :span="3">
            {{ messageInfo.content }}
        </a-descriptions-item>
        <a-descriptions-item label="备注信息" :span="3">
            {{ messageInfo.remarks }}
        </a-descriptions-item>
    </a-descriptions>
</template>
<script>
    import {getMessageNotifyById, updateMessageNotifyById} from '@/services/message'
    export default {
        data () {
            return {
                messageInfo:{}
            }
        },
        computed: {
        },
        mounted(){
        },
        created() {
            this.getMessageNotifyById()
        },
        watch: {
            $route(){
                this.getMessageNotifyById();//换成你的方法
            }
        },
        methods: {
            getMessageNotifyById(){
                var query=this.$route.query;
                let id = query.id;
                getMessageNotifyById({id: id}).then(res=>{
                    this.messageInfo = res.data.data
                    if (res.data.code ==0 ){
                        this.updateMessageNotifyById(id)
                    }
                })
            },
            updateMessageNotifyById(id){
                updateMessageNotifyById({id: id, status: 2}).then(res=>{
                    this.messageInfo = res.data.data
                })
            },
        }
    }
</script>
<style>
    .content-descriptions{
        background-color: #fff;
    }
</style>