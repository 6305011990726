var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { title: "预览", width: 800, footer: null },
      on: { cancel: _vm.handleCancel },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _vm.type === "pdf"
        ? _c("div", [
            _c("iframe", {
              staticStyle: { width: "100%", height: "600px" },
              attrs: { src: "url", frameborder: "0" },
            }),
          ])
        : _vm.type === "video"
        ? _c("div", [
            _c("video", {
              staticStyle: { width: "100%", height: "100%" },
              attrs: { autoplay: "", controls: "", src: _vm.url },
            }),
          ])
        : _vm.type === "audio"
        ? _c("div", [
            _c("audio", {
              staticStyle: { width: "100%" },
              attrs: { autoplay: "", controls: "", src: _vm.url },
            }),
          ])
        : _vm.type === "text"
        ? _c("div", [
            _c("pre", { staticStyle: { "white-space": "pre-wrap" } }, [
              _vm._v(_vm._s(_vm.textContent)),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }