<template>
    <a-modal width="960px" v-model="show" :title="title" :footer="null" @cancel="close">
        <a-form-model ref="ruleForm" :model="params" :rules="rules">
          <a-row>
            <!-- <a-col :span="12">
              <a-form-model-item label="所属部门" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="departmentname">
                <a-select v-model="params.departmentname" allowClear placeholder="" style="width: 240px">
                    <a-select-option v-for="(item,index) in gcglbList" :key="index" :value="item.value" :title="item.name">
                        {{ item.name }}
                    </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col> -->
            <a-col :span="12">
              <a-form-model-item label="姓名" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="name">
                <a-input placeholder="请输入姓名" v-model="params.name" />
                
              </a-form-model-item>
            </a-col>
            <!-- <a-col :span="12">
              <a-form-model-item label="出生年月" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="birthday">
                <a-date-picker v-model="params.birthday" valueFormat="YYYY-MM-DD" @change="onChange" />
                
              </a-form-model-item>
            </a-col> -->
            <!-- <a-col :span="12">
              <a-form-model-item label="年龄" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" readonly prop="age">
                <a-input placeholder="" disabled v-model="params.age" />
              </a-form-model-item>
            </a-col> -->
            <a-col :span="12">
              <a-form-model-item label="性别" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="sexdesc">
                <a-select
                  placeholder="请选择 性别"
                  v-model="params.sexdesc"
                  style="width: 266px"
                  :options="options2"
                  allowClear
                  >
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="职称" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="titledesc">
                
                <a-auto-complete style="width: 260px" v-model="params.titledesc">
                  <template slot="dataSource">
                    <a-select-option v-for="item in options3" :key="item.value">
                      {{ item.label }}
                    </a-select-option>
                  </template>
                </a-auto-complete>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="专业特长" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="specdesc">
                <a-select
                  placeholder="请选择 专业特长"
                  v-model="params.specdesc"
                  style="width: 266px"
                  :options="options4"
                  allowClear
                  >
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="联系电话" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="phone">
                <a-input type="number" placeholder="请输入 联系电话" v-model="params.phone" />
              </a-form-model-item>
            </a-col>
            <!-- <a-col :span="12">
              <a-form-model-item label="专职/兼职" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="worktypedesc">
                <a-select
                  placeholder="请选择 "
                  v-model="params.worktypedesc"
                  style="width: 266px"
                  :options="options5"
                  allowClear
                  >
                </a-select>
              </a-form-model-item>
            </a-col> -->
            <a-col :span="12">
              <a-form-model-item label="应急领域" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="domaindesc">
                <a-select
                  placeholder="请选择 应急领域"
                  v-model="params.domaindesc"
                  style="width: 266px"
                  :options="options6"
                  allowClear
                  >
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="备注" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="beizhu">
                <a-input placeholder="请输入 " v-model="params.beizhu" />
              </a-form-model-item>
            </a-col>
  
          </a-row>
        </a-form-model>
        <a-row>
          <a-col :span="12"></a-col>
          <a-col :span="12">
            <a-form-model-item :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }" prop="year">
              <div class="flex flex-row-reverse">
                <a-button type="primary" :loading="sbloading" @click="handleSubmit">保存</a-button>
                <a-button class="mr-2" @click="close">返回</a-button>
              </div>
            </a-form-model-item>
          </a-col>
        </a-row>
    </a-modal>
  </template>
  
  <script>
  
  import { request } from "@/utils/request";
  import { mapGetters } from 'vuex'
  import moment from "moment";
  import { emergencyTeamAdd,emergencyTeamUpdate, emergencyTeamByID } from "@/services/dangerousProject";
  import { ATTACHMENT_UPLOAD } from "@/services/api";
  import gcglib from './mixin'
  
  const options1 = [
    { label: '是', value: 1 },
    { label: '否', value: 0 }
  ]
  const options2 = [
    { label: '男', value: '男' },
    { label: '女', value: '女' }
  ]
  // 工程师、一级建造师、助理工程师、经济师、高级工程师、助理审计师、审计师
  const options3 = [
    { label: '工程师', value: '工程师' },
    { label: '一级建造师', value: '一级建造师' },
    { label: '助理工程师', value: '助理工程师' },
    { label: '经济师', value: '经济师' },
    { label: '高级工程师', value: '高级工程师' },
    { label: '助理审计师', value: '助理审计师' },
    { label: '审计师', value: '审计师' }
  ]
  // 机电管理、设备管理、技术管理、电器管理、安全管理、材料管理、劳务管理、项目管理
  const options4 = [
    { label: '机电管理', value: '机电管理' },
    { label: '设备管理', value: '设备管理' },
    { label: '技术管理', value: '技术管理' },
    { label: '电器管理', value: '电器管理' },
    { label: '安全管理', value: '安全管理' },
    { label: '材料管理', value: '材料管理' },
    { label: '劳务管理', value: '劳务管理' },
    { label: '项目管理', value: '项目管理' }
  ]
  // 兼职、专职
  const options5 = [
    { label: '兼职', value: '兼职' },
    { label: '专职', value: '专职' }
  ]
  // 交通巡线、伪装防护、工程机械、综合救援、技术救援
  const options6 = [
    { label: '交通巡线', value: '交通巡线' },
    { label: '伪装防护', value: '伪装防护' },
    { label: '工程机械', value: '工程机械' },
    { label: '综合救援', value: '综合救援' },
    { label: '技术救援', value: '技术救援' }
  ]
  export default {
    name: 'etDetail',
    computed: {
      ...mapGetters('account', ['user']),
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        isEdit: {
            type: Number,
            default: 0
        },
        id: {
            default: ''
        }
        

    },
    mixins: [gcglib],
    data() {
      return {
        // isEdit: 0,
        rules: {
          name: [{ required: true, message: '请输入 姓名', trigger: 'change' }],
          phone: [
            { required: true, message: '请输入', trigger: 'blur' },
            { pattern: /^\d+$/, message: '只能输入数字', trigger: 'blur' },
            { len: 11, message: '长度必须为 11 位', trigger: 'blur' }
          ],
          // departmentname: [{ required: true, message: '请选择部门', trigger: 'change' }],
          // pubdate: [{ required: true, message: '请选择发布日期', trigger: 'change' }],
          // pubdeptname: [{ required: true, message: '请输入发布部门', trigger: 'change' }],
          // remark: [{ required: true, message: '请选择', trigger: 'change' }],
          // attach: [{ required: true, message: '请选择', trigger: 'change' }],
        },
  
        params: {
          "departmentid": "dept-001", //所属部门的ID
          "departmentname": "应急响应部门", //所属部门的名称
          "name": "张三", //姓名
          "birthday": "1995-01-01", //出生日期，格式通常为 "yyyy-MM-dd"
          "age": "34", //年龄，根据出生日期计算得出
          "sexcode": "M", //性别代码，例如 "M" 代表男性
          "sexdesc": "男", //性别描述
          "titlecode": "T01", //职称代码
          "titledesc": "高级工程师", //职称描述
          "speccode": "S01", //专业特长代码
          "specdesc": "信息技术", //专业特长描述
          "phone": "13800138000", //联系电话
          "worktypecode": "FT", //工作类型代码，例如 "FT" 代表全职
          "worktypedesc": "全职", //工作类型描述
          "domaincode": "D01", //应急领域代码
          "domaindesc": "网络安全", //应急领域描述
          "beizhu": "无" //备注信息
        },
        sbloading: false,
        // accept:".png,.jpg,.jpeg,.pdf,.PDF",
        accept:"",
        filePath: [],
        uploading: false,
        title: '新增标准规范',
        show: false,

        options1: options1,
        options2: options2,
        options3: options3,
        options4: options4,
        options5: options5,
        options6: options6
      };
    },
   
    watch: {
      visible(val) {
        console.log(val)
        
        if (this.isEdit == 0) {
            // dom 渲染后
          
          this.params = {
            "departmentid": "", //所属部门的ID
            "departmentname": "", //所属部门的名称
            "name": "", //姓名
            "birthday": "", //出生日期，格式通常为 "yyyy-MM-dd"
            "age": "", //年龄，根据出生日期计算得出
            "sexcode": "", //性别代码，例如 "M" 代表男性
            "sexdesc": "", //性别描述
            "titlecode": "", //职称代码
            "titledesc": "", //职称描述
            "speccode": "", //专业特长代码
            "specdesc": "", //专业特长描述
            "phone": "", //联系电话
            "worktypecode": "", //工作类型代码，例如 "FT" 代表全职
            "worktypedesc": "", //工作类型描述
            "domaincode": "", //应急领域代码
            "domaindesc": "", //应急领域描述
            "beizhu": "" //备注信息
          };
          // 新增 birthday 取 30年前
          // this.params.birthday = moment().subtract(30, 'years').format('YYYY-MM-DD');
          // this.params.age = 30;
          this.filePath = [];
          this.title = '新增 应急队伍'
        } else {
            this.title = '编辑 应急队伍'
            this.params.id = this.id;
            this.getDetail()

        }
        this.show = val;
      }
    },
    methods: {
      getDetail() {
        emergencyTeamByID({id: this.params.id}).then(res => {
          if (res.data.code === 200) {
            this.params = res.data.data;
            
           
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
  
      handleSubmit() {
        this.sbloading = true
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            if (this.isEdit == 0) {
              let d = this.params;  
              emergencyTeamAdd(d).then(res => {
                if (res.data.code === 200) {
                  this.$message.success('提交成功！')
                  this.close();
                  this.$emit('refresh')
                } else {
                  this.$message.error(res.data.msg)
                }
                this.sbloading = false
              })
            } else {
              let d = this.params;
              emergencyTeamUpdate(d).then(res => {
                if (res.data.code === 200) {
                  this.$message.success('修改成功！')
                  this.close();
                  this.$emit('refresh')
                } else {
                  this.$message.error(res.data.msg)
                }
                this.sbloading = false
              })
            }
          } else {
            console.log('error submit!!');
            this.sbloading = false
            return false;
          }
        });
      },
      close() {
        this.$emit('closeDialog')
      },
      onChange(date, dateString) {
        console.log(date, dateString);
        // 用当前时间减去出生日期，得到年龄
        let age = moment().diff(dateString, 'years');
        this.params.age = age;
      },
      
    },
  };
  </script>
  
  <style lang="less" scoped>
  .a_Approval {
    background: #fff;
    border-radius: 10px;
  }
  
  .btn_group {
    padding-right: 190px;
  }
  </style>
  