var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-card", { attrs: { bordered: false } }, [
        _c(
          "div",
          { staticStyle: { display: "flex", "flex-wrap": "wrap" } },
          [
            _c("head-info", {
              attrs: { title: "我的待办", content: "8个任务", bordered: true },
            }),
            _c("head-info", {
              attrs: {
                title: "本周任务平均处理时间",
                content: "32分钟",
                bordered: true,
              },
            }),
            _c("head-info", {
              attrs: { title: "本周完成任务数", content: "24个" },
            }),
          ],
          1
        ),
      ]),
      _c(
        "a-card",
        {
          staticStyle: { "margin-top": "24px" },
          attrs: { bordered: false, title: "标准列表" },
        },
        [
          _c(
            "div",
            { attrs: { slot: "extra" }, slot: "extra" },
            [
              _c(
                "a-radio-group",
                [
                  _c("a-radio-button", [_vm._v("全部")]),
                  _c("a-radio-button", [_vm._v("进行中")]),
                  _c("a-radio-button", [_vm._v("等待中")]),
                ],
                1
              ),
              _c("a-input-search", {
                staticStyle: { "margin-left": "16px", width: "272px" },
              }),
            ],
            1
          ),
          _c(
            "a-button",
            {
              staticStyle: { width: "100%" },
              attrs: { type: "dashed", icon: "plus" },
            },
            [_vm._v("添加")]
          ),
          _c(
            "a-list",
            {
              attrs: {
                size: "large",
                pagination: {
                  showSizeChanger: true,
                  showQuickJumper: true,
                  pageSize: 5,
                  total: 50,
                },
              },
            },
            _vm._l(5, function (i) {
              return _c(
                "a-list-item",
                { key: i },
                [
                  _c(
                    "a-list-item-meta",
                    {
                      attrs: {
                        description:
                          "那是一种内在的东西， 他们到达不了，也无法触及的",
                      },
                    },
                    [
                      _c("a-avatar", {
                        attrs: {
                          slot: "avatar",
                          size: "large",
                          shape: "square",
                          src: "https://gw.alipayobjects.com/zos/rmsportal/WdGqmHpayyMjiEhcKoVE.png",
                        },
                        slot: "avatar",
                      }),
                      _c("a", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v("AliPay"),
                      ]),
                    ],
                    1
                  ),
                  _c("div", { attrs: { slot: "actions" }, slot: "actions" }, [
                    _c("a", [_vm._v("编辑")]),
                  ]),
                  _c(
                    "div",
                    { attrs: { slot: "actions" }, slot: "actions" },
                    [
                      _c(
                        "a-dropdown",
                        [
                          _c(
                            "a-menu",
                            { attrs: { slot: "overlay" }, slot: "overlay" },
                            [
                              _c("a-menu-item", [_c("a", [_vm._v("编辑")])]),
                              _c("a-menu-item", [_c("a", [_vm._v("删除")])]),
                            ],
                            1
                          ),
                          _c(
                            "a",
                            [
                              _vm._v("更多"),
                              _c("a-icon", { attrs: { type: "down" } }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "list-content" }, [
                    _c("div", { staticClass: "list-content-item" }, [
                      _c("span", [_vm._v("Owner")]),
                      _c("p", [_vm._v("付晓晓")]),
                    ]),
                    _c("div", { staticClass: "list-content-item" }, [
                      _c("span", [_vm._v("开始时间")]),
                      _c("p", [_vm._v("2018-07-26 22:44")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "list-content-item" },
                      [
                        _c("a-progress", {
                          staticStyle: { width: "180px" },
                          attrs: { percent: 80 },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }