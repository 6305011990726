var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "exception-page" }, [
    _c("div", { staticClass: "img" }, [
      _c("img", { attrs: { src: _vm.config[_vm.type].img } }),
    ]),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("h1", [_vm._v(_vm._s(_vm.config[_vm.type].title))]),
        _c("div", { staticClass: "desc" }, [
          _vm._v(_vm._s(_vm.config[_vm.type].desc)),
        ]),
        _vm.type === "404" && !_vm.hasMenu
          ? _c("div", { staticClass: "tips" }, [
              _vm._v("您的账号无任何菜单，请联系管理员开通"),
            ])
          : _vm._e(),
        _c(
          "a-space",
          [
            _vm.hasMenu
              ? _c(
                  "a-button",
                  { attrs: { type: "primary" }, on: { click: _vm.backHome } },
                  [_vm._v("返回首页")]
                )
              : _vm._e(),
            _c("a-button", { on: { click: _vm.logout } }, [_vm._v("退出登录")]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }