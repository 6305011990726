var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { attrs: { id: "components-form-demo-advanced-search" } },
        [
          _c("form-index", {
            ref: "formindex",
            attrs: { formData: _vm.formData, flexSubmit: true },
            on: { handleSubmit: _vm.handleSubmit },
            scopedSlots: _vm._u([
              {
                key: "flexSubmit",
                fn: function () {
                  return [
                    _c(
                      "a-space",
                      {
                        staticStyle: {
                          "margin-left": "90px",
                          "margin-top": "5px",
                        },
                      },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: {
                              type: "primary",
                              icon: "search",
                              loading: _vm.loading,
                              "html-type": "submit",
                            },
                          },
                          [_vm._v(" 查询 ")]
                        ),
                        _c(
                          "a-space",
                          { staticClass: "operator" },
                          [
                            _c("a-button", { on: { click: _vm.close } }, [
                              _vm._v(" 重置 "),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c(
            "div",
            { staticClass: "a_a_footer mb-1" },
            [
              _c(
                "a-space",
                { staticClass: "operator" },
                [
                  _vm.queryData.applyType > 0
                    ? _c(
                        "a-button",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: {
                            type: "primary",
                            icon: "download",
                            loading: _vm.loading,
                            disabled: _vm.selectedApplyIdList.length <= 0,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.batchDownloadTeamPayroll(
                                _vm.selectedApplyIdList
                              )
                            },
                          },
                        },
                        [_vm._v(" 批量导出Excel报表 ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "a-space",
                { staticClass: "operator" },
                [
                  _c(
                    "a-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: {
                        type: "primary",
                        icon: "download",
                        loading: _vm.loading,
                        disabled: _vm.selectedApplyIdList.length <= 0,
                      },
                      on: { click: _vm.showTabulationMonthModel },
                    },
                    [_vm._v(" 汇总制表 ")]
                  ),
                ],
                1
              ),
              _c(
                "a-space",
                { staticClass: "operator" },
                [
                  _c(
                    "a-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: {
                        type: "primary",
                        icon: "enter",
                        loading: _vm.loading,
                        disabled: _vm.selectedApplyIdList.length <= 0,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.batchFinanceRepulse(
                            _vm.selectedApplyIdList
                          )
                        },
                      },
                    },
                    [_vm._v(" 批量退回 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-table",
            {
              attrs: {
                "row-selection": {
                  selectedRowKeys: _vm.selectedApplyIdList,
                  onChange: _vm.selectApplyChange,
                },
                "row-key": "id",
                columns: _vm.columns,
                "data-source": _vm.data,
                pagination: _vm.pagination,
                loading: _vm.loading,
              },
              on: { change: _vm.teamApplyTableChange },
              scopedSlots: _vm._u([
                {
                  key: "action",
                  fn: function (text, record) {
                    return [
                      _c(
                        "a-popover",
                        {
                          attrs: {
                            title: "操作",
                            trigger: "click",
                            placement: "bottom",
                          },
                        },
                        [
                          _c("template", { slot: "content" }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.showStaffPayrollModel(record)
                                  },
                                },
                              },
                              [_vm._v("查看详情")]
                            ),
                            _c("br"),
                            _c(
                              "a",
                              {
                                staticStyle: { "margin-top": "10px" },
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadTeamPayroll(record)
                                  },
                                },
                              },
                              [_vm._v("导出Excel报表")]
                            ),
                            _c("br"),
                            _c(
                              "a",
                              {
                                staticStyle: { "margin-top": "10px" },
                                on: {
                                  click: function ($event) {
                                    return _vm.repulseApplyStatus(record)
                                  },
                                },
                              },
                              [_vm._v("有误，退回劳务员处")]
                            ),
                          ]),
                          _c(
                            "a",
                            { attrs: { type: "primary" } },
                            [
                              _vm._v(" 操作"),
                              _c("a-icon", { attrs: { type: "down" } }),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ]
                  },
                },
                {
                  key: "workStatusText",
                  fn: function (text) {
                    return [
                      _c(
                        "div",
                        { staticStyle: { "white-space": "pre-wrap" } },
                        [_vm._v(_vm._s(text))]
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("template", { slot: "footer" }, [
                _c(
                  "div",
                  {
                    key: _vm.tabTotal.timeKey,
                    staticStyle: { "text-align": "center" },
                  },
                  [
                    _c(
                      "a-row",
                      [
                        _c("a-col", { attrs: { span: 10 } }, [
                          _vm._v(" 合计： "),
                        ]),
                        _c("a-col", { attrs: { span: 2 } }, [
                          _vm._v(
                            " " + _vm._s(_vm.tabTotal.payableMoneySum) + " "
                          ),
                        ]),
                        _c("a-col", { attrs: { span: 2 } }, [
                          _vm._v(
                            " " + _vm._s(_vm.tabTotal.actualMoneySum) + " "
                          ),
                        ]),
                        _c("a-col", { attrs: { span: 2 } }, [
                          _vm._v(" " + _vm._s(_vm.tabTotal.taxSum) + " "),
                        ]),
                        _c("a-col", { attrs: { span: 2 } }, [
                          _vm._v(
                            " " + _vm._s(_vm.tabTotal.highTempSubsidySum) + " "
                          ),
                        ]),
                        _c("a-col", { attrs: { span: 5 } }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "工资单记录",
            visible: _vm.staffPayrollModelVisible,
            width: "50%",
            footer: null,
            keyboard: true,
            maskClosable: true,
          },
          on: { cancel: _vm.staffPayrollModelCancel },
        },
        [
          _c(
            "a-form-model",
            {
              attrs: {
                model: _vm.staffPayrollQueryData,
                layout: "inline",
                "label-col": { span: 6 },
                "wrapper-col": { span: 16 },
              },
            },
            [
              _c(
                "a-form-model-item",
                { ref: "name", attrs: { label: "工人姓名", prop: "name" } },
                [
                  _c("a-input", {
                    model: {
                      value: _vm.staffPayrollQueryData.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.staffPayrollQueryData, "name", $$v)
                      },
                      expression: "staffPayrollQueryData.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { ref: "idCard", attrs: { label: "身份证号", prop: "idCard" } },
                [
                  _c("a-input", {
                    model: {
                      value: _vm.staffPayrollQueryData.idCard,
                      callback: function ($$v) {
                        _vm.$set(_vm.staffPayrollQueryData, "idCard", $$v)
                      },
                      expression: "staffPayrollQueryData.idCard",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "search",
                    loading: _vm.loading,
                  },
                  on: { click: _vm.searchStaffPayroll },
                },
                [_vm._v("查询")]
              ),
              _c(
                "a-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: {
                    type: "primary",
                    icon: "download",
                    loading: _vm.loading,
                  },
                  on: { click: _vm.downloadStaffPayroll },
                },
                [_vm._v(" 导出工资单 ")]
              ),
            ],
            1
          ),
          _c("a-table", {
            attrs: {
              columns: _vm.staffPayrollColumns,
              "data-source": _vm.staffPayrollData,
              pagination: _vm.staffPayrollPagination,
              loading: _vm.staffPayrollLoading,
            },
            on: { change: _vm.staffPayrollTableChange },
          }),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "制表月份",
            visible: _vm.tabulationMonthModelVisible,
            width: "30%",
            keyboard: true,
            maskClosable: true,
            okText: "制表并下载",
          },
          on: {
            cancel: _vm.tabulationMonthModelCancel,
            ok: function ($event) {
              return _vm.payrollApplySummary(_vm.selectedApplyIdList)
            },
          },
        },
        [
          _c(
            "a-form-model",
            { attrs: { model: _vm.tabulationData, layout: "inline" } },
            [
              _c(
                "a-form-model-item",
                { ref: "month", attrs: { label: "月份", prop: "month" } },
                [
                  _c("a-month-picker", {
                    staticStyle: { width: "100%" },
                    attrs: { placeholder: "请选择制表月份", format: "yyyyMM" },
                    on: { change: _vm.tabulationMonthChange },
                    model: {
                      value: _vm.tabulationData.month,
                      callback: function ($$v) {
                        _vm.$set(_vm.tabulationData, "month", $$v)
                      },
                      expression: "tabulationData.month",
                    },
                  }),
                ],
                1
              ),
              _c("br"),
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "a-button",
                { key: "back", on: { click: _vm.tabulationMonthModelCancel } },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "a-button",
                {
                  key: "submit",
                  attrs: { type: "primary", loading: _vm.monthModelLoading },
                  on: {
                    click: function ($event) {
                      return _vm.payrollApplySummary(_vm.selectedApplyIdList)
                    },
                  },
                },
                [_vm._v(" 制表并下载 ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }