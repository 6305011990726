var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "constructiontTable_index" },
        [
          _c("form-index", {
            ref: "formindex",
            attrs: {
              formData: _vm.formData,
              flexAction: true,
              baiduMapBoolen: false,
            },
            on: {
              handleSubmit: _vm.handleSubmit,
              handleChange: _vm.handleChange,
              handlSelect: _vm.handlSelect,
            },
            scopedSlots: _vm._u([
              {
                key: "flexAction",
                fn: function () {
                  return [
                    _c(
                      "a-space",
                      { style: { marginTop: "4px" } },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: {
                              icon: "search",
                              type: "primary",
                              "html-type": "submit",
                            },
                          },
                          [_vm._v("查询")]
                        ),
                        _c("a-button", { on: { click: _vm.close } }, [
                          _vm._v(" 重置 "),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c(
            "div",
            { staticClass: "mt-10" },
            [
              _c("standard-table", {
                staticClass: "table",
                attrs: {
                  columns: _vm.columns,
                  dataSource: _vm.dataSource,
                  pagination: _vm.pagination,
                  loading: _vm.tableLoading,
                  isAlert: false,
                  bordered: true,
                  tableSize: "middle",
                },
                on: { change: _vm.onPageChange },
                scopedSlots: _vm._u([
                  {
                    key: "index",
                    fn: function (ref) {
                      var index = ref.index
                      return [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                (_vm.pagination.current - 1) *
                                  _vm.pagination.pageSize +
                                  parseInt(index) +
                                  1
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "projectName",
                    fn: function (ref) {
                      var text = ref.text
                      var record = ref.record
                      return [
                        _c(
                          "a-tooltip",
                          { attrs: { placement: "topLeft" } },
                          [
                            _c("template", { slot: "title" }, [
                              _c("span", [_vm._v(_vm._s(text))]),
                            ]),
                            _c(
                              "span",
                              {
                                staticClass: "ant-btn-text ellipsis-2",
                                on: {
                                  click: function ($event) {
                                    return _vm.viewEditing(record)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(text))]
                            ),
                          ],
                          2
                        ),
                      ]
                    },
                  },
                  {
                    key: "action",
                    fn: function (ref) {
                      var record = ref.record
                      return _c("div", {}, [
                        _c("div", [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.viewEditing(record)
                                },
                              },
                            },
                            [_vm._v("查看")]
                          ),
                        ]),
                      ])
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("proSummaryStaff-dialog", {
        attrs: {
          visible: _vm.distributeVisible,
          modalTitle: "工人工资汇总",
          workNumList: _vm.workNumList,
        },
        on: {
          cancel: function ($event) {
            _vm.distributeVisible = false
          },
        },
      }),
      _c("Export-loading", {
        attrs: {
          cPercent: _vm.cPercent,
          isExporting: _vm.isExporting,
          loadingText: _vm.loadingText,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }