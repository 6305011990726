<script>
    import {getOfficeEssList, officeEssUpdate, applyEss, createConsoleLoginUrl} from '@/services/ess'
    import {UPLOAD_AUTHORIZATION_FILE} from '@/services/api'
    import {mapGetters} from 'vuex'
    import moment from 'moment';
    import Cookie from 'js-cookie'


    export default {
        data() {
            return {

                // 筛选
                searchForm: {
                    dataRange: "0"
                },
                loading: false,

            };
        },
        computed: {
            ...mapGetters('account', ['user']),
        },
        created() {
            if (!this.user.nowRoleKey.includes("admin")){
                this.searchForm.officeId = this.user.officeId
            }
            this.getOfficeEssList()
        },
        methods: {
            getOfficeEssList(){
                const that = this
                this.data = []
                this.detailsData = []
                getOfficeEssList(this.searchForm).then(res=>{
                    this.data = res.data.data
                    this.cacheData = this.data
                    const record = this.data[0]
                    if (record.status === 2){
                        this.createConsoleLoginUrl(record);
                    }else {
                        this.$message.info('电子签暂未开通，即将跳转开通电子签...');
                        setTimeout(function (){
                            that.$router.push('/ess/openessbasic')
                        }, 3000)

                    }
                })
            },
            createConsoleLoginUrl(record){
                createConsoleLoginUrl({officeEssInfoId: record.id}).then(res=>{
                    window.open(res.data.msg);
                })
            },

        },
    };
</script>