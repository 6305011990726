<template>
  <div class="container">
    <form-index
      ref="formindex"
      :formData="formData"
      @handleSubmit="handleSubmit"
      :flexSubmit="true"
    >
      <!-- 下部分按钮插槽 -->
      <template #flexSubmit>
        <a-space  style="margin-left: 55px; margin-top: 5px">
          <a-button icon="search" type="primary" html-type="submit"> 查询 </a-button>
          <a-space class="operator">
            <a-button @click="close"> 重置 </a-button>
          </a-space>
        </a-space>
      </template>
    </form-index>
    <div style="margin-top: -20px;" class="a_a_footer">
      <div class="mb-1 mt-2">
        <a-space class="operator">
          <a-button type="primary" @click="reportAgain">重新上报</a-button>
        </a-space>
      </div>
      <!-- 表格封装 -->
      <standard-table
        class=""
        :columns="columns"
        :dataSource="dataSource"
        :selectedRows.sync="selectedRows"
        rowKey="id"
        :scroll="{ x: 1300 }"
        :pagination="pagination"
        :loading="tableLoading"
        @change="onPageChange"
        :isAlert="false"
        :bordered="true"
        @update:selectedRows="updateSelectRows($event)"
      >
        <template slot="index" slot-scope="{ index }">
          <span>
            {{
              (pagination.current - 1) * pagination.pageSize +
                parseInt(index) +
                1
            }}
          </span>
        </template>
        <template slot="checkType" slot-scope="{text}">
            {{text == 1 ? '进场' : '出场'}}
        </template>
        <template slot="personType" slot-scope="{text}">
            {{text == 1 ? '工人' : '管理人员'}}
        </template>
        <template slot="atteImg" slot-scope="{ text }">
          <a-avatar shape="square" :size="64" :src="text" icon="user"/>
        </template>
        <template slot="pullStatus" slot-scope="{ text }">
          <span v-if="text == 1"><i class="iconfont duigouxiao" style="color:#00CE86"></i></span>
          <span v-else><i class="iconfont path" style="color:#FF5C77"></i></span>
        </template>
      </standard-table>
    </div>
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import { getProjectStaffList } from "@/services/participationUnit";
import { getBackAtteRecord, getBackAtteReRecord } from "@/services/participationUnit"
import moment from "moment";
import { mapGetters } from "vuex";
import { useRender } from '@/hooks/useRender.js'

const formData = [
  {
    label: "姓名",
    placeholder: "请填写",
    decorator: [
      "name",
      { rules: [{ required: false, message: "请填写" }] },
    ],
    type: "input",
    key: "name",
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "日期",
    placeholder: "",
    decorator: ["searchAtteTime", { rules: [{ required: false, message: "" }] }],
    type: "rangePicker",
    key: "searchAtteTime",
    dataSource: [],
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
    format: "YYYY-MM-DD hh:mm:ss",
  },
];
const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
  },
  {
    title: "姓名",
    dataIndex: "name",
    align: "center",
    width: 120,
    scopedSlots: { customRender: "name" },
  },
  {
    title: "头像",
    dataIndex: "atteImg",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "atteImg" },
  },
  {
      title: "进出方向",
      dataIndex: "checkType",
      align: "center",
      scopedSlots: { customRender: "checkType" },
  },
  {
      title: "人员类型",
      dataIndex: "personType",
      align: "center",
      scopedSlots: { customRender: "personType" },
  },
  {
    title: "考勤时间",
    dataIndex: "atteTime",
    align: "center",
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "上报状态",
    dataIndex: "pullStatus",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "pullStatus" },
  },
  {
    title: "上报时间",
    dataIndex: "pullTime",
    align: "center",
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "上传设备序列号",
    align: "center",
    dataIndex: "pullDeviceCode",
    scopedSlots: { customRender: "pullDeviceCode" },
  },
];
export default {
  components: {
    formIndex,
    StandardTable,
  },
  data() {
    return {
      formData: formData,
      columns: columns,
      dataSource: [], //table数组
      selectedRows: [], //选中的数据
      pagination: {
        //分页数据
        showSizeChanger:true,
        pageSizeOptions: ['10', '50', '100', '150'],
        current:1,
        pageSize:50,
        total:0,
        showLessItems:true,
        showQuickJumper:true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      params: { //测试数据
        pageNumber: 1,
        pageSize: 50,
        type: 1
      },
      tableLoading: false, //table加载中
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  activated() {
    this.getData(this.params)
  },
  methods: {
    getData(params) {
      this.tableLoading = true;
      if (params.searchAtteTime == '') {
        params.searchAtteTime = []
      }
      getBackAtteRecord(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
          // this.dataSource.forEach((item) => {
          //   item.enterDate = moment(item.enterDate).format("YYYY-MM-DD");
          // });
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    reportAgain() {
      if (this.selectedRows.length == 0) {
        this.$message.warning('请选择人员！')
      } else {
        let arr = []
        this.selectedRows.forEach(el => {
          arr.push(el.id)
        })
        console.log(arr)
        getBackAtteReRecord(arr).then(res => {
          if (res.data.code === 0) {
            this.$message.success(res.data.msg)
            this.getData(this.params)
        } else {
          this.$message.error(res.data.msg)
        }
        })
      }
    },
    handleSubmit(e) {
      this.params.pageNumber = 1
      let obj = { ...e, ...this.params }
      this.pagination.current = 1;
      this.selectedRows = []
      obj.enterDate = obj.enterDate === '' ? [] : obj.enterDate
      this.getData(obj);
    },
    //表格 - 分页查询
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      if (obj.status == "") {
        obj.status = 0;
      }
      if (obj.enterDate == "") {
        obj.enterDate = [];
      }
      this.params.pageNumber = e.current;
      this.pagination.current = e.current;
      this.params.pageSize = e.pageSize;
      this.pagination.pageSize = e.pageSize;
      this.getData(Object.assign({}, this.params, obj));
    },
    updateSelectRows(e) {
      console.log(e)
    },
    // 重置
    close() {
      this.params = {
        pageNumber: 1,
        pageSize: 50,
      };
      this.pagination.current = 1;
      this.pagination.pageSize = 50;
      this.selectedRows = []
      this.getData(this.params);
      this.$refs["formindex"].resetFields();
    },
  },
};
</script>

<style lang="less" scoped>
.a_a_footer {
  padding: -10px 0px;
  background: #ffffff;

  .operator {
    margin-right: 8px;

    // .ant-btn {
    //   color: #307dfa;
    // }
  }

  .sTables {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      width: 100%;
      text-align: center;
      cursor: pointer;
    }

    div:nth-child(1) {
      color: #307dfa;
    }

    div:nth-child(2) {
      color: #ff5c77;
    }

    div:nth-child(3) {
      color: #615e83;
    }
  }

}
.content_div {
  padding: 5px 0;
  cursor: pointer;
}
.content_div:hover {
  color: #307dfa;
}
.task-tips {
  color: #4377cc;
  text-decoration: underline;
  cursor: pointer;
}
.jwTag {
  border-radius: 2px;
  width: 65px;
  height: 30px;
  line-height: 28px;
  margin: auto;
  cursor: pointer;
  font-size: 14px;
}
.tagRed {
  background: #FFE8EC;
  color: #FF5D78;
}
.tagGray {
  background: #EAEBEB;
  color: #989999;
}
.tagGreen {
  background: #DEFCF1;
  color: #00CE86;
}
.tagBlue {
  background: #0874F01A;
  color: #0874F0;
}
</style>
