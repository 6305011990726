var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-card",
        { attrs: { "body-style": { padding: "0px" }, bordered: false } },
        [
          _c(
            "div",
            { attrs: { id: "components-form-demo-advanced-search" } },
            [
              _c(
                "a-form-model",
                {
                  staticClass: "ant-advanced-search-form",
                  attrs: { model: _vm.queryData, layout: "inline" },
                },
                [
                  _c(
                    "a-form-model-item",
                    {
                      ref: "day",
                      attrs: { label: "超出时长(天)", prop: "day" },
                    },
                    [
                      _c("a-input-number", {
                        attrs: { id: "inputNumber", min: 1, max: 1000 },
                        model: {
                          value: _vm.queryData.day,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryData, "day", $$v)
                          },
                          expression: "queryData.day",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { ref: "name", attrs: { label: "工人姓名", prop: "name" } },
                    [
                      _c("a-input", {
                        model: {
                          value: _vm.queryData.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryData, "name", $$v)
                          },
                          expression: "queryData.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "search",
                        loading: _vm.loading,
                      },
                      on: { click: _vm.getData },
                    },
                    [_vm._v(" 查询 ")]
                  ),
                  _c(
                    "a-upload",
                    {
                      style: { marginLeft: "8px" },
                      attrs: {
                        name: "file",
                        multiple: true,
                        action: _vm.uploadEpidemic,
                        headers: _vm.headers,
                        showUploadList: false,
                        beforeUpload: _vm.beforeUpload,
                        accept: _vm.accept,
                      },
                      on: { change: _vm.uploadEpidemicFileChange },
                    },
                    [
                      _c(
                        "a-button",
                        { attrs: { type: "primary" } },
                        [
                          _c("a-icon", { attrs: { type: "upload" } }),
                          _vm._v("导入核酸结果"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("a-table", {
            attrs: {
              columns: _vm.columns,
              "data-source": _vm.data,
              loading: _vm.loading,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }