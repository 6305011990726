<template>
  <a-modal
    :title="paperTitle"
    style="top: 10px;"
    :width="850"
    :zIndex="2000"
    :visible="visible"
    @cancel="() => doBack()"
    :footer="null"
  > 
  <div style="background-color:#bfd5cb;padding-top:20px;border-radius:8px">
    <div style="width:100%;text-align:center;"><h2>{{paperTitle}}</h2><h4>总题目数：{{sigleQuests.length+multipleQuests.length+judgeQuests.length}}题  总分：{{sigleCore+multipleCore+judgeCore}}分</h4></div>
    <div style="padding-left:50px;margin-top:30px">
      <p style="font-size: 14px;font-weight: bolder;" v-if="sigleCore>0">一、单选题（{{sigleQuests.length}}题/{{sigleCore}}分）</p>
      <a-list style="padding-left:30px" :split="false" v-if="sigleCore>0" :data-source="sigleQuests">
        <a-list-item slot="renderItem" slot-scope="item, index">
          {{index+1}}、{{ item.questCont }}   ({{item.newcore}}分)
          <a-list :split="false" size="small" style="padding-left:20px" :data-source="item.optionsList">
            <a-list-item slot="renderItem" slot-scope="item2, index2">
              {{index2+1 | transNumber}}、{{item2.answerCont}}
            </a-list-item>
          </a-list>
        </a-list-item>
      </a-list>
      <p style="font-size: 14px;font-weight: bolder;" v-if="sigleCore>0&&multipleCore>0">二、多选题（{{multipleQuests.length}}题/{{multipleCore}}分）</p>
      <p style="font-size: 14px;font-weight: bolder;" v-else-if="multipleCore>0">一、多选题（{{multipleQuests.length}}题/{{multipleCore}}分）</p>
      <a-list style="padding-left:30px" :split="false" v-if="multipleCore>0" :data-source="multipleQuests">
        <a-list-item slot="renderItem" slot-scope="item, index">
          {{index+1}}、{{ item.questCont }}({{item.newcore}}分)
          <a-list :split="false" size="small" style="padding-left:20px" :data-source="item.optionsList">
            <a-list-item slot="renderItem" slot-scope="item2, index2">
              {{index2+1 | transNumber}}、{{item2.answerCont}}
            </a-list-item>
          </a-list>
        </a-list-item>
      </a-list>
      <p style="font-size: 14px;font-weight: bolder;" v-if="sigleCore>0&&multipleCore>0&&judgeCore>0">三、判断题（{{judgeQuests.length}}题/{{judgeCore}}分）</p>
      <p style="font-size: 14px;font-weight: bolder;" v-else-if="(sigleCore>0||multipleCore>0)&&judgeCore>0">二、判断题（{{judgeQuests.length}}题/{{judgeCore}}分）</p>
      <p style="font-size: 14px;font-weight: bolder;" v-else-if="judgeCore>0">一、判断题（{{judgeQuests.length}}题/{{judgeCore}}分）</p>
      <a-list style="padding-left:30px" :split="false" v-if="judgeCore>0" :data-source="judgeQuests">
        <a-list-item slot="renderItem" slot-scope="item, index">
          {{index+1}}、{{ item.questCont }}({{item.newcore}}分)
          <a-list :split="false" size="small" style="padding-left:20px" :data-source="judgeQuestsOptions">
            <a-list-item slot="renderItem"  slot-scope="item2, index2">
              <span v-if="index2==0">A、正确</span>
              <span v-else>B、错误</span>
            </a-list-item>
          </a-list>
        </a-list-item>
      </a-list>
    </div>
  </div>
  </a-modal>
</template>

<script>
import {request} from '@/utils/request'

import {
  ONLINE_QUESTION_OPTIONS,
  } from '@/services/api'


export default {
  name: 'IconSelectorView',
  components: {
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    paperId:{
      type: String,
      require:true
    },
    paperTitle:{
      type: String,
      require:true
    },
    sigleQuests:{
      type:Array
    },
    multipleQuests:{
      type:Array
    },
    judgeQuests:{
      type:Array
    }
  },
  // watch:{
  //   visible(val){
  //     if(val){
  //       //查询题库信息
  //       if(this.paperId){
  //         this.doQuery();
  //       }
  //     }
  //   }
  // },
  filters: {
    transNumber (i) {
      const dist = {
        1: 'A',
        2: 'B',
        3: 'C',
        4: 'D',
        5: 'E'
      }
      return dist[i] || ''
    }
  },
  data () {
    return {
      // sigleQuests:[],
      // multipleQuests:[],
      // judgeQuests:[],
      chooseIdsArr:[],
      judgeIdArr:[],
      judgeQuestsOptions:[{},{}],
      tableLoading: false,
      queryParam:{
        isMany:1,
        level:null,
        courseId:null,
        hourTags:null,
        pageNumber:0,
      },
      pagination:{
        current:1,
        pageSize:10,
        total:0,
        showLessItems:true,
        showQuickJumper:true
      },
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: "#3ec13e",//滚动条颜色
          opacity: 0.8,//滚动条透明度
          "overflow-x": "hidden"
        }
      }
    }
  },
  computed:{
    sigleCore() {
      let totalCore = 0;
      this.sigleQuests.forEach(o=>{
        totalCore = totalCore+o.newcore;
      })
      return totalCore
    },
    multipleCore() {
      let totalCore = 0;
      this.multipleQuests.forEach(o=>{
        totalCore = totalCore+o.newcore;
      })
      return totalCore
    },
    judgeCore() {
      let totalCore = 0;
      this.judgeQuests.forEach(o=>{
        totalCore = totalCore+o.newcore;
      })
      return totalCore
    },
  },
  methods: {
    doBack(){
      this.$emit("doCloseViewPaper");
    },
    doQuery(){
      this.tableLoading = true;
      // setTimeout(() => {
      //   request(ONLINE_QUESTION_OPTIONS,"post",this.chooseIdsArr).then(res => {
      //     if(res.data.code==0){
      //       this.sigleQuests.forEach(obj=>{
      //         obj.optionsList = res.data.data[obj.id];
      //       })
      //       this.multipleQuests.forEach(obj=>{
      //         obj.optionsList = res.data.data[obj.id];
      //       })
      //     }
      //   })
      // }, 20);
      this.tableLoading = false;
    },
  }
}
</script>

<style lang="less" scoped>
  .search{
    margin-bottom: 54px;
  }
  .fold{
    width: calc(100% - 216px);
    display: inline-block
  }
  .operator{
    margin-bottom: 18px;
  }
  .ant-modal-header{
    background-color:#bdd3c9 !important;
  }
  .ant-modal-body{
    background-color:#bdd3c9 !important;
  }
  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
</style>
