<template>
  <div>
    <div class="constructiontTable_index">
      <form-index
        class="form"
        ref="formindex"
        :projectList="projectList"
        :formData="formData"
        Overview="证书类型管理"
        :baiduMapBoolen="false"
        @handleSubmit="handleSubmit"
        @handleChange="handleChange"
        @handlSelect="handlSelect"
        :flexSubmit="true"
      >
        <!-- 下部分按钮插槽 -->
        <template #flexSubmit>
          <a-button icon="search" type="primary" html-type="submit"> 查询 </a-button>
          <a-button @click="close"> 重置 </a-button>
        </template>
      </form-index>
      <a-space class="operator">
        <a-button type="primary" icon="plus" ghost @click="createItem">
          新增
        </a-button>
      </a-space>
      <standard-table
        :columns="columns"
        :dataSource="dataSource"
        row-key="id"
        :scroll="{ x: 1300 }"
        :pagination="pagination"
        :loading="tableLoading"
        @change="onPageChange"
        :bordered="false"
      >
        <template slot="type" slot-scope="{ text }">
          <div v-for="item in typeList" :key="item.value">
            <span v-if="text == item.value">{{ item.name }}</span>
          </div>
        </template>
        <template slot="other" slot-scope="{ text }">
          <span>{{ text ? `${text}天` : "" }}</span>
        </template>
        <div slot="action" slot-scope="{ record }">
          <div class="sTables">
            <a-popover placement="bottomLeft" overlayClassName="overlay-class">
              <template slot="content">
                <div style="display: flex; flex-direction: column">
                  <a-button
                    type="link"
                    icon="edit"
                    @click="viewEditing(record, 'edit')"
                    >修改</a-button
                  >
                  <a-button
                    type="link"
                    icon="delete"
                    @click="recorDelete(record)"
                    >删除</a-button
                  >
                </div>
              </template>
              <a-button type="link"
                >操作<a-icon type="double-right"
              /></a-button>
            </a-popover>
          </div>
        </div>
      </standard-table>
      <a-modal
        :title="title"
        :visible.sync="visible"
        @ok="modelOk"
        @cancel="modelCancel"
        width="800px"
      >
        <form-index
          ref="formModal"
          :projectList="projectList"
          :formData="formModal"
          :baiduMapBoolen="false"
          @handleChange="handleChange"
          @handlSelect="handlSelect"
        >
        </form-index>
      </a-modal>
    </div>
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import {
  deLCertificateType,
  editCertificateType,
  getCertificateType,
  addCertificateType,
} from "@/services/projectManagement";
import { getCertificateTypeList } from "@/services/participationUnit";
import { request } from "@/utils/request";
import ExportLoading from "@/components/ExportLoading/ExportLoading"; // progress组件
import { PROJECT_OFFICE_CHILD, QUERY_OFFICE_BY_USER } from "@/services/api";
import { mapGetters } from "vuex";
import { sysUserGetList } from "@/services/message";
const columns = [
  {
    title: "证书类型",
    dataIndex: "type",
    width: 245,
    // ellipsis: true,
    align: "center",
    key: "type",
    scopedSlots: { customRender: "type" },
    // fixed: "left",
  },
  {
    title: "证书名称",
    dataIndex: "typeName",
    align: "center",
    width: 200,
    key: "typeName",
    scopedSlots: { customRender: "typeName" },
  },
  {
    title: "有效期限提醒(天)",
    dataIndex: "other",
    align: "center",
    key: "other",
    width: 200,
    scopedSlots: { customRender: "other" },
  },
  {
    title: "操作",
    align: "center",
    width: 85,
    // fixed: "right",
    scopedSlots: { customRender: "action" },
  },
];
const formData = [
  {
    label: "证书类型",
    placeholder: "请输入证书类型",
    decorator: [
      "type",
      { rules: [{ required: false, message: "请选择证书类型" }] },
    ],
    type: "select",
    key: "type",
    selectlist: [
      {
        value: 1,
        name: "执业资格证",
      },
      {
        value: 2,
        name: "从业资格证",
      },
      {
        value: 3,
        name: "安全生产<三类人员>考核合格证",
      },
    ],
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
  },
  {
    label: "证书名称",
    placeholder: "请选择证书名称",
    decorator: [
      "typeName",
      { rules: [{ required: false, message: "请选择证书名称" }] },
    ],
    type: "input",
    key: "typeName",
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
  },
];
const formModal = [
  {
    label: "证书类型",
    placeholder: "请选择证书类型",
    decorator: [
      "type",
      { rules: [{ required: true, message: "请选择证书类型" }] },
    ],
    type: "select",
    selectlist: [
      {
        value: 1,
        name: "执业资格证",
      },
      {
        value: 2,
        name: "从业资格证",
      },
      {
        value: 3,
        name: "安全生产<三类人员>考核合格证",
      },
    ],
    key: "type",
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
  },
  {
    label: "证书名称",
    placeholder: "请输入证书名称",
    decorator: [
      "typeName",
      { rules: [{ required: true, message: "请输入证书名称" }] },
    ],
    type: "input",
    key: "typeName",
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
  },
  {
    label: "有效限期(天)",
    placeholder: "请输入有效限期提醒(天)",
    decorator: [
      "other",
      { rules: [{ required: false, message: "请输入有效限期提醒(天)" }] },
    ],
    type: "input",
    key: "other",
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
];
export default {
  components: {
    formIndex, //form表单封装
    StandardTable, //表格封装
  },
  data() {
    return {
      expandedRowKeys: [],
      selectedRows: [],
      columns: columns, //表格数据
      dataSource: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      tableLoading: false,
      formData: formData,
      formModal: formModal,
      prams: {
        pageNumber: 1,
        pageSize: 10,
      },
      projectList: [], //获取当前项目数据
      // 弹出层标题
      title: "修改公司机构",
      // 是否显示弹出层
      visible: false,
      // 新增或者修改
      type: "edit",
      // 单位详情
      detail: {},
      typeList: [
        {
          value: 1,
          name: "执业资格证",
        },
        {
          value: 2,
          name: "从业资格证",
        },
        {
          value: 3,
          name: "安全生产<三类人员>考核合格证",
        },
      ],
    };
  },
  created() {
    if (this.$route.query.pageNumber) {
      this.prams.pageNumber = this.$route.query.pageNumber;
    }
    this.aqxjlist(this.prams);
  },
  methods: {
    // 封装弹窗确定按钮
    confirm(title, content) {
      return new Promise((resolve, reject) => {
        this.$confirm({
          title: title,
          content: content,
          onOk() {
            resolve();
          },
        });
      });
    },
    // 删除接口
    recorDelete(val) {
      let params = {
        id: val.id,
      };
      this.confirm("删除", `是否确认删除 ${val.typeName} 证书`).then(() => {
        deLCertificateType(params).then((res) => {
          if (res.data.code === 0) {
            this.$message.success("删除成功", 10);
            this.aqxjlist(this.prams);
          } else {
            this.$message.error(res.data.msg, 10);
          }
        });
      });
    },
    // 更新接口
    update(e) {
      editCertificateType(e).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("成功", 10);
        } else {
          this.$message.error(res.data.msg, 10);
        }
      });
    },
    handleChange(e) {
      this.projectArr = [];
      const name = {
        master: () => {
          this.getZdata(sysUserGetList, e, "nickName");
        },
      }[e.item.key]();
      const arr = ["userId"];
      arr.forEach((item) => {
        this.$refs["formindex"].setFieldsValue("", {}, item);
      });
      // 获取当前匹配key，赋值相对于数组
      this.formModal.forEach((item) => {
        if (e.item.key === item.key) {
          item.dataSource = this.projectArr;
        }
      });
    },
    // 接口封装
    /**
     * msg 接收接口名称
     * e 数据
     * name 参数名
     * */
    getZdata(msg, e, name) {
      let data = {
        [name]: e.value,
      };
      msg(data).then((res) => {
        if (res.status == 200) {
          const data = res.data.data.list ? res.data.data.list : res.data.data
          if (name == 'nickName') {
            name = 'nickname'
          }
          data.forEach((item) => {
            this.projectArr.push({
              title: item[name],
              id: item.id,
            });
          });
          this.projectList = data;
        }
      });
    },

    // 特殊数据处理
    handlSelect({ val, keys }) {
      console.log(val, keys, "val, keys");
    },
    aqxjlist(prams) {
      this.tableLoading = true;
      getCertificateTypeList(prams).then((res) => {
        this.dataSource = res.data.data.list;
        // this.officeTreeData = res.data.data;
        this.formDataFn("id", res.data.data.list);
        this.tableLoading = false;
      });
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      console.log(data, "formDataFn");
      this.formModal.forEach((item) => {
        if (item.key == key) {
          item.selectlist = data;
        }
      });
    },
    // 提交表单数据
    handleSubmit(e) {
      console.log(e, "提交表单数据");
      let obj = Object.assign({}, e, this.prams);
      this.aqxjlist(obj);
    },
    // 重置
    close() {
      this.prams = {
        pageNumber: 1,
        pageSize: 10,
      };
      this.aqxjlist(this.prams);
      this.$refs["formindex"].resetFields();
    },
    //表格 - 分页查询
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      this.prams.pageNumber = e.current;
      this.pagination.current = e.current;
      this.aqxjlist(Object.assign({}, this.prams, obj));
    },
    // 查看编辑
    viewEditing(e, type) {
      console.log(e, "e======");
      if (type == "edit") {
        getCertificateType({ id: e.id }).then((res) => {
          this.detail = res.data.data;
          this.$nextTick(() => {
            this.$refs["formModal"].queryObj(res.data.data);
          });
        });
        this.type = "edit";
        this.title = "修改证书";
      } else if (type == "add") {
        this.type = "add";
        this.title = "新增证书";
        e = {
          id: e.id,
        };
        this.$nextTick(() => {
          this.$refs["formModal"].queryObj(e);
        });
      }
      this.visible = true;
    },
    // 新建项目
    createItem(e) {
      this.title = "新增证书类型";
      this.visible = true;
      this.type = "add";
    },
    // 确认弹窗
    modelOk(e) {
      this.$refs["formModal"].combinationSubmit().then((res) => {
        if (this.type == "add") {
          // 新增删除掉id
          const params = {
            ...res,
          };
          addCertificateType(params).then((result) => {
            if (result.data.code === 0) {
              this.$message.success("新增证书类型成功", 10);
            } else {
              this.$message.success(result.data.msg, 10);
            }
          });
        } else if (this.type == "edit") {
          const params = {
            ...res,
            id: this.detail.id,
          };
          editCertificateType(params).then((result) => {
            if (result.data.code === 0) {
              this.$message.success("修改证书类型成功", 10);
            } else {
              this.$message.success(result.data.msg, 10);
            }
          });
        }
        this.modelCancel();
        setTimeout(() => {
         this.aqxjlist(this.prams);
        },500)
      });
    },
    // 关闭弹窗
    modelCancel(e) {
      this.visible = false;
      this.$refs["formModal"].resetFields();
      this.detail = {};
    },
  },
};
</script>

<style lang="less" scoped>
.constructiontTable_index {
  padding: 24px;
  background: #fff;
  border-radius: 6px;
  margin-bottom: 10px;

  .operator {
    margin-left: 30px;
  }
  .c_i_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 25px;

    .c_i_h_center {
      padding: 16px;
      border-radius: 4px;
      width: 180px;
      height: 130px;
      margin-right: 50px;

      div:nth-child(1) {
        font-size: 16px;
      }

      div:nth-child(2) {
        margin-top: 26px;
        font-size: 24px;
      }
    }
  }
}

.sTable {
  display: flex;
  justify-content: space-between;
  align-items: center;

  // div {
  //     width: 100%;
  //     color: blue;
  //     text-align: center;
  //     cursor: pointer;
  // }
}

.sTables {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;

  div {
    // width: 100%;
    padding: 0 10px;
    text-align: center;
    cursor: pointer;
  }
}

.ant-btn-link {
  color: rgb(118, 131, 143);
  text-align: left;
  padding: 0;
}
.ant-btn-link:hover {
  color: #4a86e8;
}
</style>
