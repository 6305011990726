<template>
  <div class="a_Approval">
    <!-- <div class="plug-Overview">安全隐患排查覆盖率</div>
    <a-divider /> -->
    <a-tabs v-model="defaultActiveKey">
      <a-tab-pane key="1" tab="分公司记录">
      </a-tab-pane>
      <a-tab-pane key="2" tab="项目周检记录">
      </a-tab-pane>
    </a-tabs>
    <a-form class="clearfix">

      <a-col :span="5">
        <a-form-item label="月度" :labelCol="{ span: 6 }" :wrapperCol="{ span: 14, offset: 1 }">
          <a-month-picker :allowClear="false" placeholder="请选择月份" format="YYYY-MM" v-model="monthPickerVal">
            <a-icon slot="suffixIcon" type="smile" />
          </a-month-picker>
        </a-form-item>
      </a-col>

      <a-col :span="5" v-if="user.isGcglb != 1">
        <a-form-item label="分公司" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
          <a-select v-model="queryParams.gcglbId">
            <a-select-option value="">全部</a-select-option>
            <a-select-option v-for="item in options" :key="item.itemText || item.id" :value="item.itemText || item.id">
              {{ item.itemValue || item.master }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :span="5">
        <a-form-item label="重点监控项目" :labelCol="{ span: 9 }" :wrapperCol="{ span: 14, offset: 1 }">
          <a-select v-model="queryParams.monitorDisable">
            <a-select-option value="">全部</a-select-option>
            <a-select-option v-for="item in monitorOptions" :key="item.value" :value="item.value">
              {{ item.name}}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      

      <a-col :span="3">
        <a-space class="btnBox">
          <a-button type="primary" @click="doRateQuery()">查询</a-button>
          <a-button @click="close()">重置</a-button>
        </a-space>
      </a-col>
    </a-form>
    <div class="flex j-between">
      <div>
        <a-space>
          <a-button type="primary" @click="doDowload(1)">导出</a-button>
          <a-button type="primary" @click="doDowload">统计表</a-button>
        </a-space>
      </div>
      <div>
        <div class="table-title flex">
          <div class="mr-3" style="font-size:14px; line-height: 24px;">
            <a-tooltip>
              <template slot="title">{{defaultActiveKey == 1 ? '公司整改下发后即为完成' : '项目经理提交闭环后即为完成'}}</template>
              统计规则<a-icon style="margin-left:5px" type="question-circle" theme="twoTone" two-tone-color="#52c41a"/>
            </a-tooltip>
          </div>
          <template v-if="defaultActiveKey == 1">
            <div class="mr-2 ">项目完成度: {{ completeCompany.completeNum }}/{{ completeCompany.projectSum }}</div>
            <div>项目完成率: {{sumToFixed(completeCompany.completeNum,completeCompany.projectSum)}}%</div>
          </template>
          <template v-else>
            <div class="mr-2 ">项目完成度: {{completeProject.completeNum}}/{{ completeProject.projectSum }}</div>
            <div>项目完成率: {{sumToFixed(completeProject.completeNum,completeProject.projectSum)}}%</div>
          </template>
        </div>
      </div>
    </div>
    <div>
      <!-- dataSource 列表 -->
      <a-spin :spinning="rateLoading">
        <vue-scroll :ops="ops" style="height:calc(100vh - 380px)" class="mt-2">
          <a-collapse v-model="activeKey" :bordered="false" class="w-100" v-if="rateSource.length > 0">
            <a-collapse-panel :key="item.gcglbId" class="rate-item" :style="customStyle" v-for="item in rateSource">
              <template #header>
                <div class="font-bold">{{ item.gcglbName }} ({{defaultActiveKey == 1 ? item.inspectCompanyNum :item.inspectProjectNum}}/{{ item.rateInfos.length }})</div>
              </template>
              <!-- 1560px -->
              <vue-scroll :ops="ops2" style="max-width: calc(100vw - 380px); height: 150px;">
                <template v-if="item.rateInfos.length">
                <div class="project-item" v-for="items in item.rateInfos" :key="items.projectId">
                  <!-- subCompanyCheckNum  分公司 -->
                  <div @click="showModal(items,1,item.gcglbId)" v-if="defaultActiveKey == 1" class="text-center" :style="{color: items.subCompanyCheck ? '#0FC682' : '#4a86e8', cursor: 'pointer'}">{{ items.subCompanyCheckNum
                    || 0}}/1</div>
                  <!-- projectCheckNum 项目月检 --> 
                  <div v-else @click="showModal(items,3,item.gcglbId)" class="text-center" :style="{color: items.subProjectCheck ? '#0FC682' : '#4a86e8',cursor: 'pointer'}">{{ items.projectCheckNum || 0 }}/4</div>
                  <div class="text-center mb-1" style="margin-top: 8px">
                    <template v-if="defaultActiveKey == 1">
                      <a-icon v-if="items.subCompanyCheck" type="check-circle" theme="twoTone" two-tone-color="#0FC682" :style="{ fontSize: '40px' }"/>
                      <a-progress v-else type="circle" :percent="items.subCompanyPercent" :width="40" :strokeWidth="15"
                        :showInfo="false" />
                    </template>
                    <template v-if="defaultActiveKey == 2">
                      <a-icon v-if="items.subProjectCheck" type="check-circle" theme="twoTone" two-tone-color="#0FC682" :style="{ fontSize: '40px' }"/>
                      <a-progress v-else type="circle" :percent="items.subPorjectPercent" :width="40" :strokeWidth="15"
                        :showInfo="false" />
                    </template>
                  </div>
                  <div class="ellipsis-2 text-center tooltip-hover" style="font-size: 14px;cursor: pointer">
                    <a-tooltip placement="top" :title='items.projectName'>
                      <div @click="showModal(items,defaultActiveKey == 1 ? 1 : 3,item.gcglbId)">
                        {{ items.projectName }}
                      </div>
                    </a-tooltip>
                  </div>
                  <div class="text-center bottom-tag" v-if="items.monitorDisable === 0">
                    <a-tooltip placement="bottomLeft" :title='items.monitorReason'>
                      <a-tag color="#FC8251">非监控</a-tag>
                    </a-tooltip>
                  </div>
                </div>
              </template>
              <template v-else>
                  <a-empty class="flex j-center a-center w-100" />
                </template>
              </vue-scroll>
            </a-collapse-panel>
          </a-collapse>
          <a-empty v-else class="flex j-center a-center w-100" />
        </vue-scroll>
      </a-spin>
    </div>
    <a-modal v-model="isShow" width="1400px" :footer="null">
      <standard-table
        :bordered="true" 
        :columns="columns"
        :dataSource="dataSource"
        :selectedRows.sync="selectedRows"
        :isAlert="false"
        :pagination="pagination"
        :loading="tableLoading"
        @clear="onClear"
      >
        <template slot="index" slot-scope="{ index }">
          <span>{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}</span>
        </template>
        <div slot="do-status" slot-scope="{text}">
          <label v-if="text==2"><a-icon style="font-size:15px;color:green" type="check" /></label>
          <label v-else-if="text==99"></label>
          <label v-else ><a-icon style="font-size:15px;color:red" type="close" /></label>
        </div>
        <div slot="outLine" slot-scope="{text}">
          <span>{{ text && text == 1 ? '已线下回复' : ''}}</span>
        </div>
        <div slot="action" slot-scope="{record}">
          <!-- <a @click="doFileDowload(record)">下载资料</a> -->
          <a @click="onClick(record)">查看详情</a>
        </div>
        <template slot="statusTitle">
          <a-icon @click.native="onStatusTitleClick" type="info-circle" />
        </template>
      </standard-table>
    </a-modal>
    <Export-loading :cPercent="cPercent" :isExporting="isExporting" :loadingText="loadingText"></Export-loading>
  </div>
</template>

<script>
import StandardTable from '@/components/table/StandardTable'
import {
  OFFICE_STAND_BOOK,
  OFFICE_STAND_BOOK_EXPORT,
  SAFE_VIEW_DOWNLOAD,
  PROJECT_AQXJ_XMBZJ_EXPORT,
  PROJECT_OFFICE_CHILD,
  QUERY_OFFICE_BY_USER,
  CHECK_RATE_INFO_EXCEL,
  CHECK_RATE_INFO_EXPORT_EXCEL
} from '@/services/api'
import { checkRateInfoPage } from '@/services/safeRisk'
import { mapGetters } from "vuex";
import { exportProgress } from '@/pages/aqjyzl/export' // 导出方法
import ExportLoading from '@/components/ExportLoading/ExportLoading' // progress组件
import { request } from '@/utils/request'
import moment from 'moment';
import { useRender } from '@/hooks/useRender.js'
const columns1 = [
  {
    title: "序号",
    align: "center",
    scopedSlots: { customRender: "index" },
    width: 65,
  },
  {
    title: '巡检单位',
    dataIndex: 'officeName',
    align: 'center',
    scopedSlots: { customRender: 'officeName' }
  },
  {
    title: '项目名称',
    dataIndex: 'projectName',
    width: 280,
    ellipsis: true,
    tooltip: true
  },
  {
    title: '下发时间',
    dataIndex: 'downTimeStr',
    align: 'center',
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: '检查时间',
    dataIndex: 'checkDateStr',
    align: 'center',
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: '限期整改时间',
    dataIndex: 'zgqxStr',
    align: 'center',
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: '整改回复时间',
    dataIndex: 'pmTimeStr',
    align: 'center',
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: '复检时间',
    dataIndex: 'fhsjStr',
    align: 'center',
    customRender: (text) => useRender.renderDate(text)
  },
  // {
  //   title: '整改到期时间',
  //   dataIndex: 'zgqxStr',
  //   align: 'center',
  //   scopedSlots: {customRender: 'zgqxStr'}
  // },
  {
    title: '是否闭环',
    dataIndex: 'status',
    align: 'center',
    scopedSlots: { customRender: 'do-status' }
  },
  {
    title: '检查类别',
    dataIndex: 'remark',
    align: 'center',
    width: 155,
    ellipsis: true,
    tooltip: true
  },
  {
    title: '操作',
    align: 'center',
    scopedSlots: { customRender: 'action' }
  }
];
const columns2 = [
  {
    title: "序号",
    align: "center",
    scopedSlots: { customRender: "index" },
    width: 65,
  },
  {
    title: '公司',
    dataIndex: 'parentOffice',
    align: 'center',
    scopedSlots: { customRender: 'parentOffice' }
  },
  {
    title: '分公司',
    dataIndex: 'childOffice',
    align: 'center',
    scopedSlots: { customRender: 'childOffice' }
  },
  {
    title: '项目名称',
    dataIndex: 'projectName',
    width: 280,
    ellipsis: true,
    tooltip: true
  },
  {
    title: '下发时间',
    dataIndex: 'downTimeStr',
    align: 'center',
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: '检查时间',
    dataIndex: 'checkDateStr',
    align: 'center',
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: '限期整改时间',
    dataIndex: 'zgqxStr',
    align: 'center',
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: '整改回复时间',
    dataIndex: 'pmTimeStr',
    align: 'center',
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: '复检时间',
    dataIndex: 'fhsjStr',
    align: 'center',
    customRender: (text) => useRender.renderDate(text)
  },
  // {
  //   title: '整改到期时间',
  //   dataIndex: 'zgqxStr',
  //   align: 'center',
  //   scopedSlots: {customRender: 'zgqxStr'}
  // },
  {
    title: '是否闭环',
    dataIndex: 'status',
    align: 'center',
    scopedSlots: { customRender: 'do-status' }
  },
  {
    title: '检查类别',
    dataIndex: 'remark',
    align: 'center',
    width: 155,
    ellipsis: true,
    tooltip: true
  },
  {
    title: '操作',
    align: 'center',
    scopedSlots: { customRender: 'action' }
  }
];

export default {
  mixins: [exportProgress],
  name: 'safeCoverageRate',
  components: { StandardTable, ExportLoading },
  data() {
    return {
      rateSource: [],
      dataSource: [],
      advanced: false,
      columns: columns1,
      yearShowOne: false,
      tableLoading: false,
      rateLoading: false,
      spinning: false,
      loadingText: "数据加载中...",
      loadKeys: [],
      monthFormat: 'YYYY-MM',
      yearFormat: 'YYYY',
      overDownIndex: 0,
      dowloadtype: true,
      queryParams: {
        year: undefined,
        month: undefined,
        gcglbId: "",
        monitorDisable: ""
      },
      params: {
        isRate:true,
        date: undefined,
        status:0,
        type: 1,
        zjType: 0,
        projectId: undefined,
      },
      pagination: {
        current: 1,
        pageSize: 50,
        total: 0,
        showLessItems: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`,
      },
      options: [],
      cPercent: 0,
      isExporting: false,
      isShow: false,
      customStyle:
        'background: #F6F9FE;border-radius: 4px;margin-bottom: 24px;border: 0;overflow: hidden',
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: "#4a86e8",//滚动条颜色
          opacity: 0.8,//滚动条透明度
          "overflow-x": "unset"
        }
      },
      ops2: {
        horizontal: false,
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: "#4a86e8",//滚动条颜色
          opacity: 0.8,//滚动条透明度
          "overflow-y": "hidden"
        }
      },
      activeKey: ['0', '1', '2'],
      selectedRows: [],
      monthPickerVal: moment(new Date()).format('YYYY-MM'),
      defaultActiveKey: "1",
      completeCompany:{
        completeNum: 0,
        projectSum: 0
      },
      completeProject:{
        completeNum: 0,
        projectSum: 0
      },
      monitorOptions: [
        {
            value: 1,
            name: "是",
        },
        {
            value: 0,
            name: "否",
        },
      ]
    }
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  watch: {
    cPercent: {
      handler(newName, oldName) {
        this.cPercent = newName;
      },
      immediate: true,
    },
    loadingText: {
      handler(newName, oldName) {
        this.loadingText = newName;
      },
      immediate: true,
    }
  },
  created() {
    if (this.user.isGcglb === 1) {
      this.getgcglbList(QUERY_OFFICE_BY_USER)
    } else {
      this.getgcglbList(PROJECT_OFFICE_CHILD + "/OFFICE_CHILD")
    }
  },
  activated() {
    this.doRateQuery();
  },
  methods: {
    moment,
    getgcglbList(path) {
      request(path, 'get').then(res => {
        if (res.data.code === 0) {
          res.data.data.forEach(el => {
            this.options.push({
              itemText: el.value,
              id: el.id,
              itemValue: el.label || el.master || el.name
            })
          })
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 调整至安全隐患排查工作台账
    onClick(record) {
      this.$router.push({
        path: 'yhpc_office_stand_book',
        query:{
          isRate: true,
          qtType: this.qtType,
          gcglbId: this.gcglbId,
          date: moment(this.monthPickerVal).format('YYYY-MM'),
          recordId: record.id,
          recordProjectId: this.recordProjectId
        }
      })
      this.isShow = false
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },
    sumToFixed(num,sum) {
      if (!num && !sum) {
        return 0
      }
     return (num/sum * 100).toFixed(2)
    },
    close() {
      this.monthPickerVal = moment(new Date()).format('YYYY-MM')
      this.queryParams = {
        year: undefined,
        month: undefined,
        gcglbId: "",
        monitorDisable: ""
      }
      this.doRateQuery()
    },
    /**
     * 获取时间段的台账信息
     */
    doRateQuery() {
      this.rateLoading = true;
      if (this.user.isGcglb === 1 && this.user.loginType === 1) {
        this.queryParams.gcglbId = this.user.officeId
      }
      this.queryParams.year = this.monthPickerVal ? moment(this.monthPickerVal).format('YYYY') : ''
      this.queryParams.month = this.monthPickerVal ? moment(this.monthPickerVal).format('MM') : ''
      checkRateInfoPage(this.queryParams).then(res => {
        this.rateLoading = false;
        if (res.data.code === 0) {
          // 统计项目完成度
          this.completeCompany = {
            completeNum: 0,
            projectSum: 0
          }
          this.completeProject = {
            completeNum: 0,
            projectSum: 0
          }
          if(res.data.data.length === 0) {
            return this.rateSource = []
          }
          // 使用箭头函数和数组方法简化处理
          this.activeKey = res.data.data.slice(0, 3).map(({ gcglbId }) => gcglbId); // 取前3个对象的 gcglbId 属性

          this.rateSource = res.data.data.map(item => {
            item.inspectCompanyNum = 0 // 工程管理部检查完成次数
            item.inspectProjectNum = 0 // 项目周检记录检查完成次数
            // 需要移动的项
            let itemsToMove = [];
            item.rateInfos.forEach(items => {
              // 工程管理部记录
              if ((items.subCompanyCheckNum * 100).toFixed(0) >= 100) {
                items.subCompanyPercent = 100
                items.subCompanyCheck = true
                item.inspectCompanyNum++
              } else {
                items.subCompanyPercent = items.subCompanyCheckNum > 0 ? (Number(items.subCompanyCheckNum * 100).toFixed(0)) : items.subCompanyCheckNum
                items.subCompanyCheck = false
              }
              // 项目周检记录
              if ((items.projectCheckNum * 25).toFixed(0) >= 100) {
                items.subPorjectPercent = 100
                items.subProjectCheck = true
                item.inspectProjectNum++
              } else {
                items.subPorjectPercent = items.projectCheckNum > 0 ? Number((items.projectCheckNum * 25).toFixed(0)) : items.projectCheckNum
                items.subProjectCheck = false
              }
              // 如果 monitorDisable 等于 0，则收集需要移动的项
              if (items.monitorDisable === 0) {
                itemsToMove.push(items);
              }
            })
            // 将需要移动的项放到最后面
            item.rateInfos = item.rateInfos.filter(items => items.monitorDisable !== 0).concat(itemsToMove);
            
            this.completeCompany.completeNum = this.completeCompany.completeNum + item.inspectCompanyNum
            this.completeProject.completeNum = this.completeProject.completeNum + item.inspectProjectNum
            this.completeCompany.projectSum = this.completeCompany.projectSum + item.rateInfos.length
            this.completeProject.projectSum = this.completeProject.projectSum + item.rateInfos.length
            return item
          })
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    /**
     * 获取时间段的台账信息
     */
     doQuery(projectId,qtType, gcglbId){
      this.tableLoading = true;
      this.params.qtType = qtType
      this.params.projectId = projectId
      this.params.officeId = gcglbId || this.gcglbId
      this.params.date = moment(this.monthPickerVal).format('YYYY-MM')
      request(OFFICE_STAND_BOOK,"post",this.params).then(res => {
        if(res.data.code==0){
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
          this.dataSource.forEach(obj=>{
            obj.key = obj.id;
          })
        }
        this.tableLoading = false;
      })
    },
    showModal(record, qtType, gcglbId) {
      this.columns = qtType == 1 ? columns1 : columns2
      this.isShow = true
      this.qtType = qtType
      this.gcglbId = gcglbId
      this.recordProjectId = record.projectId
      this.doQuery(record.projectId, qtType,gcglbId)
    },
    /**
     * 导出excel
     */
    doDowload(type) {
      // type=excel表 否则 导出统计表
      this.loadingText = "正在导出EXCEL文件，请稍候 ····";
      let name = ""
      this.isExporting = true;
      this.cPercent = 0;
      let fileName = name + `${type == 1 ? '-项目隐患排查覆盖率统计表.xlsx' : '-工程管理部及项目部安全检查情况表.xlsx'}`;
      this.exportFunNomal2(type == 1 ? CHECK_RATE_INFO_EXPORT_EXCEL : CHECK_RATE_INFO_EXCEL, this.queryParams, fileName, 'application/vnd.ms-excel')
    },
    openChangeOne(status) {
      if (status) {
        this.yearShowOne = true;
      }
    },
    doDowloadBatch() {
      if (this.selectedRows && this.selectedRows.length > 0) {
        this.isExporting = true
        this.loadingText = "正在下载资料...";
        this.selectedRows.map((item) => {
          this.loadKeys = [];
          this.loadKeys.push(item.key);
          this.cPercent = 0;
          let fileName = ""
          if (item.title) {
            fileName = item.title;
          }
          let projectName = item.projectName;
          if (item.projectName.length > 15) {
            projectName = item.projectName.slice(0, 15) + "……";
          }
          fileName = projectName + "-" + item.checkDateStr + "-安全检查.zip";
          // this.exportFunAlone(SAFEMEANS_DOWNLOAD,itemsKeys,fileName,itemList,this.selectedRows)

          if (this.dowloadtype) {
            this.exportFun(PROJECT_AQXJ_XMBZJ_EXPORT, this.loadKeys, fileName, item)
          } else {
            this.exportFun(SAFE_VIEW_DOWNLOAD, this.loadKeys, fileName, item)
          }

        })
      } else {
        this.$message.info('请选择记录下载');
        return;
      }
    },
    //下载巡检资料文件
    doFileDowload(recode){
      const date = new Date()
      const hour = date.getHours(); // 时
      const minutes = date.getMinutes(); // 分
      const seconds = date.getSeconds() //秒
      const dateTime = `${hour}${minutes}${seconds}`
      let selectedRows = this.dataSource.filter(item => item.key == recode.key);
      this.loadingText = "正在下载文件，请稍候 ····";
      this.loadKeys = [];
      this.loadKeys.push(recode.key);
      this.isExporting=true;
      this.cPercent=0;
      let fileName = ""
      if(recode.title){
        fileName=recode.title;
      }
      let typeName = "公司常规检查"
      if(recode.planType==4){
        typeName = "停工处罚";
      }else if(recode.planType==3){
        typeName = "带班检查";
      }else{
        typeName="公司常规检查"
      }
      let projectName = recode.projectName;
      if(recode.projectName.length>15){
        projectName = recode.projectName.slice(0,15)+"……";
      }
      fileName=projectName+"-"+recode.checkDateStr+"-"+typeName+".zip";

      if(this.dowloadtype){
        fileName=projectName+"-"+recode.checkDateStr+"-"+`项目自检整改单${dateTime}.zip`;
        this.exportFun(PROJECT_AQXJ_XMBZJ_EXPORT,this.loadKeys,fileName,selectedRows)
      }else{
        fileName=projectName+"-"+recode.checkDateStr+"-"+typeName+".zip";
        this.exportFun(SAFE_VIEW_DOWNLOAD,this.loadKeys,fileName,selectedRows)
      }

    },
  }
}
</script>

<style lang="less" scoped>
.a_Approval {

  /deep/ .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    // left: 130px
  }

  /deep/ .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    // padding-left: 16px;
  }

  /deep/ .ant-collapse-borderless {
    background-color: #fff;
  }

  /deep/ .ant-collapse-content>.ant-collapse-content-box {
    padding: 0 16px 0 16px;
  }

  /deep/ .__view {
    display: flex;
  }

  /deep/ .ant-progress-circle path:first-child {
    stroke: #E5E5EF !important
  } 
}

.tooltip-hover:hover {
  color: #4a86e8;
  text-decoration: underline;
}

.project-item {
  transform: scale(0.9);
  width: 120px;
  height: 100%;
  border-right: 1px solid #E5E5EF;
  padding: 0 8px;
  margin-top: -5px;
  position: relative;

  .bottom-tag {
    position: absolute;
    bottom: -28px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.search {
  margin-bottom: 54px;
}

.fold {
  width: calc(100% - 216px);
  display: inline-block
}

.operator {
  margin-bottom: 18px;
}

@media screen and (max-width: 900px) {
  .fold {
    width: 100%;
  }
}

.table-title {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}
</style>
