<template>
  <div>
    <a-drawer
      :width="1020"
      :visible="visible"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose"
    >
    <template slot="title">检查项目名称：{{projectName}}</template>
    <a-row :gutter="16" style="margin-top:5px">
        <a-col :span="12">
          <span>检查公司：<strong>{{officeName}}</strong></span>
        </a-col>
        <a-col :span="12">
          <span>检查时间：<strong>{{startDate}} 至 {{endDate}}</strong></span>
        </a-col>
    </a-row>

    <standard-table
      :columns="columns"
      :dataSource="dataSource"
      :loading="tableLoading"
      :isAlert="false"
      :pagination="false"
      @clear="onClear"
    >
      <div slot="safediscloseFileName" slot-scope="{text}">
        <div v-if="text!=null&&text!=''">{{text}}</div>
        <a-icon v-else style="color:red" type="close" />
      </div>
      <div slot="do-status" slot-scope="{text}">
        <label v-if="text==0">待检查</label>
        <label v-else-if="text==1">待复核</label>
        <label v-else-if="text==2">已完成</label>
        <label v-else-if="text==3">整改中</label>
      </div>
      <div slot="files" slot-scope="record">
        <a @click="download(1,`${record.record.id}`)" v-if="record.record.fileStatus!=null&&record.record.fileStatus!=0">安全检查整改单</a>
        <a-divider v-if="record.record.filePath2!=null&&record.record.filePath2!=''" type="vertical" />
        <a @click="download(2,`${record.record.id}`)" v-if="record.record.filePath2!=null&&record.record.filePath2!=''">检查记录单</a>
        <a-divider v-if="record.record.zghfFilePath!=null&&record.record.zghfFilePath!=''" type="vertical" />
        <a @click="download(3,`${record.record.id}`)" v-if="record.record.zghfFilePath!=null&&record.record.zghfFilePath!=''">整改回复</a>
        <a-divider v-if="record.record.stopWorkFile!=null&&record.record.stopWorkFile!=''" type="vertical" />
        <a @click="download(4,`${record.record.id}`)" v-if="record.record.stopWorkFile!=null&&record.record.stopWorkFile!=''">停工通知单</a>
        <a-divider v-if="record.record.applyWorkFile!=null&&record.record.applyWorkFile!=''" type="vertical" />
        <a @click="download(5,`${record.record.id}`)" v-if="record.record.applyWorkFile!=null&&record.record.applyWorkFile!=''">复工申请单</a>
      </div>
    </standard-table>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginLeft: '8px' }" @click="onClose">
          关闭
        </a-button>
      </div>
    </a-drawer>
  </div>
</template>
<script>
import { QUERY_DETAIL_DATAS,DOWN_LOAD_FILE } from "@/services/api";
import { request } from "@/utils/request";
import StandardTable from '@/components/table/StandardTable'
import { useRender } from '@/hooks/useRender.js'

const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    align: 'center',
    scopedSlots: { customRender: 'index' }
  },{
    title: '检查时间',
    dataIndex: 'xjTimeStr',
    align: 'center',
    customRender: (text) => useRender.renderDate(text)
  },{
    title: '检查人',
    dataIndex: 'userNames',
    align: 'left',
    scopedSlots: { customRender: 'userNames' }
  },{
    title: '检查状态',
    dataIndex: 'status',
    align: 'left',
    scopedSlots: { customRender: 'do-status' }
  },{
    title: '相关资料文档',
    dataIndex: 'filePath',
    align: 'left',
    scopedSlots: { customRender: 'files' }
  }]

export default {
  name: "detailList",
  components: {StandardTable},
  data() {
    return {
      tableLoading:false,
      dataSource:[],
      columns:columns,
      startDate:null,
      endDate:null,
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    projectId: {
      type: String,
      require:true
    },
    projectName:{
      type: String,
      require:true
    },
    officeName:{
      type: String,
      require:true
    },
    officeId:{
      type: String,
      require:true
    },
    times:{
      type: String,
      require:true
    }
  },
  created(){
  },
  mounted(){
  },
  watch:{
    projectId(val){
      this.$nextTick(()=>{
        this.queryDetails(val,this.officeId);
      })
    }
  },
  methods: {
    tinymceClick(e){
      console.log(e)
    },
    download(type,$id){
      request(DOWN_LOAD_FILE+$id+"/"+type,"get",null).then(result => {
          if (result.data.code==0) {
            window.open(result.data.msg)
          }else{
            this.$message.warning(result.message);
          }
      })
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },
    onClose(){
      this.$emit('close');
    },
    queryDetails($projectId,$officeId){
      let startDate = null;
      let endDate = null;
      if(this.times&&this.times.indexOf("@") != -1){
        startDate = this.times.split("@")[0];
        endDate = this.times.split("@")[1];
        this.startDate = startDate;
        this.endDate = endDate;
      }
      let params = {
        projectId:$projectId,
        officeId:$officeId,
        xjTimeStart:startDate,
        xjTimeEnd:endDate
      }
      request(QUERY_DETAIL_DATAS,"post",params).then(result => {
          if (result.data.code==0) {
            this.dataSource = result.data.data;
            this.dataSource.forEach((obj,index) => {
              obj.index = index+1;
              obj.key = obj.id;
            });
          }else{
            this.$message.warning(result.message);
          }
      })
    }
  }
};
</script>
<style>
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
/deep/ .ant-upload-list-picture-card .ant-upload-list-item{
  width: 127px;
  height: 127px;
}
</style>
