<template>
  <div class="create_index">
    <!-- 表单封装
            projectObj：下拉弹出框第一项对象
            Overview：项目名称
            baiduMapBoolen：控制地图显示
            topSubmit：控制上部分按钮显示
            bottomSubmit：控制下部分按钮显示
            handleSubmit：表单提交
            handleChange：输入出发
            handlSelect：输入下拉选择触发
            defaultFileList：上传图片回显链接
            query：路由传过来的数据
        -->
    <form-index
      ref="formindex"
      :options="options"
      :formData="formData"
      Overview="项目概况"
      @handleChange="handleChange"
      :baiduMapBoolen="true"
      :topSubmit="true"
      :topFlexSubmit="true"
      :projectList="projectList"
      @handleSubmit="handleSubmit"
      @handlSelect="handlSelect"
      :defaultFileList="defaultFileList"
      @handleSearch="handleSearch"
    >
      <!-- 上部分按钮插槽 -->
      <template #topSubmit>
        <a-space>
           <a-button type="primary" icon="save" html-type="submit" v-if="canOperate"> 保存 </a-button>
          <a-button type="danger" icon="sync" @click="syncProject" v-if="canOperate"> 同步海康平台 </a-button>
          <!-- <a-button @click="close"> 关闭 </a-button> -->
        </a-space>
      </template>
    </form-index>
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import {
  projectMsgGetList,
  getTreeOffice,
  addLwProjects,
  getLwProjectDetail,
  getLwDetail,
  syncProjectToHk,
} from "@/services/projectManagement";
import { request } from "@/utils/request";
import { PROJECT_OFFICE_CHILD } from "@/services/api";
import { sysUserGetList } from "@/services/message";
import address from "@/pages/components/form/address.json";
import { mapGetters } from "vuex";
import { getOfficeTypeList } from "@/services/sys";
import { debounce } from "lodash";
const formData = [
  {
    label: "项目名称",
    placeholder: "请输入项目名称",
    decorator: [
      "fullName",
      { rules: [{ required: true, message: "请输入项目名称" }] },
    ],
    type: "inputGroup",
    key: "fullName",
    dataSource: [],
    labelCol: 4,
    wrapperCol: 19,
    col: 12,
    display: true,
  },
  {
    label: "项目代码",
    placeholder: "请输入项目代码",
    decorator: [
      "projectNum",
      { rules: [{ required: true, message: "请输入项目代码" }] },
    ],
    type: "input",
    key: "projectNum",
    col: 12,
    labelCol: 4,
    wrapperCol: 19,
    display: true,
  },
  {
    label: "参建单位",
    placeholder: "请选择参建单位",
    decorator: [
      "officeId",
      { rules: [{ required: true, message: "请选择参建单位" }] },
    ],
    type: "treeSelect",
    key: "officeId",
    selectlist: [],
    col: 6,
    labelCol: 8,
    wrapperCol: 14,
    display: true,
    showSearch: true
  },
  // 施工总承包
  {
    label: "参建类型",
    placeholder: "请选择参建类型",
    decorator: [
      "roleType",
      { rules: [{ required: false, message: "请选择参建类型" }] },
    ],
    type: "select",
    key: "roleType",
    selectlist: [],
    col: 6,
    labelCol: 8,
    wrapperCol: 14,
    display: true,
  },
  // {
  //   label: "工程管理部",
  //   placeholder: "请选择工程管理部",
  //   decorator: [
  //     "gcglbId",
  //     { rules: [{ required: false, message: "请选择工程管理部" }] },
  //   ],
  //   type: "select",
  //   key: "gcglbId",
  //   selectlist: [],
  //   col: 6,
  //   labelCol: 6,
  //   wrapperCol: 14,
  //   display: true,
  //   disabled: false,
  // },
  {
    label: "工程状态",
    placeholder: "请选择工程状态",
    decorator: [
      "status",
      { initialValue: 0, rules: [{ required: true, message: "请选择工程状态" }] },
    ],
    type: "select",
    key: "status",
    col: 6,
    labelCol: 8,
    wrapperCol: 14,
    display: true,
    selectlist: [

      {
        value: 0,
        name: "在建",
      },
      {
        value: 1,
        name: "完工",
      },
      {
        value: 2,
        name: "停缓建",
      },
      {
        value: 3,
        name: "未开工",
      },
    ],
  },
  {
    label: "工程类别",
    placeholder: "请选择工程类别",
    decorator: [
      "ptype",
      { rules: [{ required: true, message: "请选择工程类别" }] },
    ],
    type: "select",
    key: "ptype",
    col: 6,
    labelCol: 8,
    wrapperCol: 14,
    display: true,
    selectlist: [
      {
        value: 1,
        name: "房建"
      },
      {
        value: 2,
        name: "市政"
      },
      {
        value: 3,
        name: "装修工程"
      },
      {
        value: 91,
        name: "土建"
      },
      {
        value: 92,
        name: "房屋建筑工程"
      },
      {
        value: 93,
        name: "冶炼工程"
      },
      {
        value: 4,
        name: "矿山工程"
      },
      {
        value: 5,
        name: "化工石油工程"
      },
      {
        value: 6,
        name: "水利水电工程"
      },
      {
        value: 7,
        name: "电力工程"
      },
      {
        value: 8,
        name: "农林工程"
      },
      {
        value: 9,
        name: "铁路工程"
      },
      {
        value: 10,
        name: "公路工程"
      },
      {
        value: 11,
        name: "港口与航道工程"
      },
      {
        value: 12,
        name: "航天航空工程"
      },
      {
        value: 13,
        name: "通信工程"
      },
    ]
  },

  {
    label: "参建负责人",
    placeholder: "请选择或输入参建负责人",
    decorator: [
      "userName",
      { rules: [{ required: true, message: "请选择或输入参建负责人" }] },
    ],
    type: "inputGroup",
    key: "userName",
    dataSource: [],
    col: 6,
    labelCol: 8,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "负责人电话",
    placeholder: "请输入参建负责人电话",
    decorator: [
      "userPhone",
      { rules: [{ required: true, message: "请输入参建负责人电话" },{message: "请输入正确手机号",pattern: new RegExp(/^1\d{10}$/),}] },
    ],
    type: "input",
    key: "userPhone",
    col: 6,
    labelCol: 8,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "运维到期时间",
    placeholder: "请选择运维到期时间",
    decorator: [
      "dueTime",
      { rules: [{ required: true, message: "请选择运维到期时间" }] },
    ],
    type: "datePicker",
    key: "dueTime",
    col: 6,
    labelCol: 8,
    wrapperCol: 14,
    format: "yyyy-MM-DD",
    styles: "width: 100%",
    display: true,
  },
  {
    label: "是否报建",
    placeholder: "请选择是否报建",
    decorator: [
      "isReport",
      { initialValue: 1, rules: [{ required: false, message: "请选择是否报建" }] },
    ],
    type: "select",
    key: "isReport",
    col: 6,
    labelCol: 8,
    wrapperCol: 14,
    selectlist: [
      {
        value: 0,
        name: "否",
      },
      {
        value: 1,
        name: "是",
      },
    ],
    display: true,
  },
  {
    label: "工程概况",
    placeholder: "请输入工程概况",
    decorator: [
      "projDesc",
      { rules: [{ required: false, message: "请输入工程概况" }] },
    ],
    type: "textarea",
    key: "projDesc",
    labelCol: 4,
    wrapperCol: 19,
    col: 12,
    display: true,
  },

  {
    label: "造价(万元)",
    placeholder: "请输入工程造价",
    decorator: [
      "projBudget",
      { rules: [{ required: false, message: "请输入工程造价" }] },
    ],
    type: "input",
    key: "projBudget",
    col: 12,
    labelCol: 4,
    wrapperCol: 19,
    display: true,
  },
  {
    label: "项目地区",
    placeholder: "请选择省市区",
    decorator: [
      "nationality",
      { rules: [{ required: false, message: "请选择省市区" }] },
    ],
    type: "cascader",
    key: "nationality",
    labelCol: 8,
    wrapperCol: 14,
    col: 6,
    display: true,
  },
  {
    label: "工程地址",
    placeholder: "请輸入工程地址",
    decorator: [
      "projAddress",
      { rules: [{ required: false, message: "请輸入工程地址" }] },
    ],
    type: "input",
    key: "projAddress",
    col: 6,
    labelCol: 8,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "工程经纬度",
    placeholder: "113.274 , 23.123",
    decorator: [
      "lanAndLat",
      { rules: [{ required: false, message: "请输入经纬度或从地图选择" }] },
    ],
    type: "input",
    key: "lanAndLat",
    disabled: true,
    col: 12,
    labelCol: 4,
    wrapperCol: 19,
    display: true,
  },
];
export default {
  data() {
    return {
      options: address,
      query: this.$route.query,
      formData: formData,
      // form: this.$form.createForm(this, { name: 'coordinated' }),
      projectArr: [], //项目名称
      projectList: [], //获取当前项目数据
      defaultFileList: [], //图片数组
      isReport: null, // 记录是否报建变量
      status: null,
      parentOfficeId: "",
      userId: undefined,
      userName: undefined,
      projType: undefined,
      arrRoles:['zong_bao_lao_wu','admin','OFFICE_JSFWZY','OFFICE_MGR'], // 按钮权限
    };
  },
  props: {
    projectId: {
      type: [String, Number],
      require: true,
    },
  },
  components: {
    formIndex,
  },
  computed: {
    ...mapGetters("account", ["user"]),
    canOperate() {
      return this.user.nowRoleKey.some(role => this.arrRoles.includes(role));
    },
  },
  inject: ["fatherMethod"],
  methods: {
    // 下拉特殊数据处理
    handleSearch(e) {
      // if (e.key == "buildOfficeName" || e.key == "effcOfficeName") {
      //   this.getZdata(getofficelist, e, "name", e.key);
      // }
    },
    // 关闭当前页面
    close() {
      let path = this.$route.path;
      this.fatherMethod(`${path}`);
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      this.formData.forEach((item) => {
        if (item.key == key) {
          item.selectlist = data;
        }
      });
    },
    // 获取工程管理部数据
    getgcglbList(path) {
      request(path, "get").then((res) => {
        if (res.data.code === 0) {
          const data = res.data.data.filter(
            (item) => item.id != "3da0f5533f87425c9a9e8a11a7923300"
          );
          const arr = [];
          data.forEach((item) => {
            arr.push({
              name: item.itemValue,
              value: item.itemText,
            });
          });
          this.formDataFn("gcglbId", arr);
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    getOfficeTypeList() {
      getOfficeTypeList().then((res) => {
        if (res.data.code == 0) {
          const data = res.data.data.companyTypeList;
          const arr = [];
          data.forEach((item) => {
            arr.push({
              name: item.label,
              value: item.value,
            });
          });
          this.roleTypeList = arr;
          this.formDataFn("roleType", arr);
        }
      });
    },
    update(e, type) {
      addLwProjects(e).then((resdata) => {
        if (resdata.data.code === 0) {
          type == "handleSubmit"
            ? this.$message.success("更新成功", 5)
            : this.$message.success("成功", 5);
          this.$refreshPage(this.$route.path); // 刷新当前页面
        }
      });
    },
    // 提交表单数据
    handleSubmit:debounce(function (e)  {
      e.id = this.projectData.id;
      e.projectId = this.projectData.projectId;
      e.userId = this.userId ? this.userId : this.projectData.userId;
      e.projectName = e.fullName
      // 处理更新人员-userId问题
      if (this.projectData.userPhone != e.userPhone) {
          if ((e.userId == this.projectData.userId) || (e.userId == 'null')) {
            delete e.userId
          }
      }
      e.projType = e.ptype; // projType项目类型
      // 省市区联动
      if (e.nationality) {
        e.province = e.nationality[0] ? e.nationality[0] : undefined;
        e.city = e.nationality[1] ? e.nationality[1] : undefined;
        e.county = e.nationality[2] ? e.nationality[2] : undefined;
      }
      delete e.nationality;
      delete e.lanAndLat;
      // e.projType = this.projType ? this.projType : this.query.data.statusw;
      this.update(e, "handleSubmit");
      // this.close();
    },1000),
    // 下拉接口的处理
    getZdata(msg, e, name, key) {
      let data = {
        [name]: e.value,
        parentOfficeId: name == "name" ? this.parentOfficeId : undefined,
      };
      msg(data).then((res) => {
        if (res.status == 200) {
          const data = res.data.data.list ? res.data.data.list : res.data.data
          const arr = [];
          if (name == 'nickName') {
            name = 'nickname'
          }
          data.forEach((item) => {
            this.projectArr.push({
              title: item[name],
              id: item.id,
            });
            arr.push({
              name: item[name],
              value: item.id,
            });
          });
          this.projectList = data;
          if (key) {
            this.formDataFn(key, arr);
          }
        }
      });
    },
    // 输入选择框触发
    handleChange(e) {
      this.projectArr = [];
      const name = {
        fullName: () => {
          this.getZdata(projectMsgGetList, e, "fullName");
        },
        userName: () => {
          this.getZdata(sysUserGetList, e, "nickName");
        },
      }[e.item.key](); //匹配对应调用对应接口
      const arr = ["projectId", "userId"];
      arr.forEach((item) => {
        this.$refs["formindex"].setFieldsValue("", {}, item);
      });

      // // 获取当前匹配key，赋值相对于数组
      this.formData.forEach((item) => {
        if (e.item.key === item.key) {
          item.dataSource = this.projectArr;
        }
      });
    },
    // 特殊数据处理
    handlSelect({ val, keys }) {
      if (keys == "officeId") {
        this.parentOfficeId = val;
      }
      if (keys == "ptype") {
        this.ptype = val;
      }
      this.projectList.forEach((item) => {
        if (keys == "userName" && item.id == val) {
          this.$refs["formindex"].setFieldsValue(val, {
            userPhone: item.mobile,
          });
          this.$refs["formindex"].setFieldsValue(val, {
            userName: item.nickname,
          });
          this.userId = item.id;
        } else if (keys == "fullName" && item.id == val) {
          this.$refs["formindex"].setFieldsValue("", {
            lanAndLat: `${item.latitude},${item.longitude}`,
          });
          this.$refs["formindex"].setFieldsValue("", {
            projectNum: item.projectNum || item?.projectMsg?.projectNum,
          });
          this.$refs["formindex"].setFieldsValue("", {
            userName: item.userName || item?.projectMsg?.userName,
          });
          this.$refs["formindex"].setFieldsValue(
            item.userId,
            {
              userId: item.userId || item?.projectMsg?.userId,
            },
            "userId"
          );
        }
      });
    },
    initTree() {
      getTreeOffice({pageSize: 2000}).then((res) => {
        if (res.data.code == 0) {
         this.formDataFn("officeId", res.data.data.list);
        }
      });
    },
    getProjectData(e) {
      getLwDetail({ projectId: this.projectId })
        .then((res) => {
          // 获取详情，先清空key值
          this.$refs["formindex"].resetFields()
          // 项目字段存在projectMsg 处理字段
          if (res.data) {
            const result = res.data;
            let nationality = [];
            let projectMsg = {}
            let lanAndLat = "";
            if (res.data.projectMsg) {
            if (result.projectMsg.longitude && result.projectMsg.latitude) {
              lanAndLat = `${result.projectMsg.latitude},${result.projectMsg.longitude}`;
              let queryParams = {
                longitude: Number(result.projectMsg.longitude),
                latitude: Number(result.projectMsg.latitude)
              }
              // 赋值地图坐标点
              let point = new this.$refs["formindex"].BMap.Point(Number(result.projectMsg.longitude),Number(result.projectMsg.latitude))
              this.$refs["formindex"].map.centerAndZoom(point, 14)
              this.$refs["formindex"].queryParams = queryParams
            }
            if (
              result.projectMsg.province &&
              result.projectMsg.city &&
              result.projectMsg.county
            ) {
              nationality = [
                `${result.projectMsg.province}`,
                `${result.projectMsg.city}`,
                `${result.projectMsg.county}`,
              ];
            }
            }
            if (res.data.projectMsg) {
              projectMsg = {
              projectNum: result.projectMsg.projectNum ? result.projectMsg.projectNum : result.projectNum,
              projDesc: result.projectMsg.projDesc,
              projAddress: result.projectMsg.projAddress,
              fullName: result.projectMsg.fullName ? result.projectMsg.fullName : result.projectName,
              latitude: result.projectMsg.latitude,
              longitude: result.projectMsg.longitude,
              projBudget: result.projectMsg.projBudget,
              ptype: result.ptype ? Number(result.ptype) : undefined,
              isReport: result.isReport ? result.isReport : result.projectMsg.isReport,
              lanAndLat,
              nationality,
              roleType: String(result.roleType),
            };
            }

            const data = {
              ...result,
              ...projectMsg,
            };
            this.projectData = data;
            if (data && Object.keys(data).length > 0) {
              this.$refs["formindex"].queryObj(data);
            }
          } else {
            this.$message.error("查询项目详情错误", 10);
          }
        })

    },
    // 同步项目
    syncProject:debounce(function () {
      let params = {
        projectNum: this.projectData.projectNum,
        projectId: this.projectData.projectId,
      };
      this.confirm("同步项目", "是否此同步项目？").then(() => {
        syncProjectToHk(params).then((res) => {
          if (res.data.code === 0) {
            this.$message.success("同步成功！");
          } else {
            this.$message.error(res.data.msg);
          }
        });
      });
    },1000),
    // 封装弹窗确定按钮
    confirm(title, content) {
      return new Promise((resolve, reject) => {
        this.$confirm({
          title: title,
          content: content,
          onOk() {
            resolve();
          },
        });
      });
    },
    // 如果不是工程管理部。那就不能选择工程管理部们
    initGcglb() {
      if (!this.user.isGcglb) {
        this.formData.forEach((item) => {
          if (item.key == "gcglbId") {
            item.disabled = true;
          }
        });
      } else {
        this.getgcglbList(PROJECT_OFFICE_CHILD + "/OFFICE_CHILD");
        this.formData.forEach((item) => {
          if (item.key == "gcglbId") {
            item.disabled = false;
          }
        });
      }
    },
  },
  mounted() {
    // this.initGcglb()
    this.getOfficeTypeList();
  },
  created() {},
  activated() {
    this.getProjectData();
    this.initTree();
    // this.getgcglbList(PROJECT_OFFICE_CHILD + "/OFFICE_CHILD");
  },
};
</script>
<style lang="less" scoped>
.create_index {
  padding: 24px;
  // background: #fbfbfb;
  // border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-bottom: 10px;
}
</style>
