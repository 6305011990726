var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    {
      staticClass: "a_Approval",
      attrs: { title: "新增人员", bordered: false },
    },
    [
      _c(
        "a-form-model",
        { ref: "ruleForm", attrs: { model: _vm.params, rules: _vm.rules } },
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "岗位",
                        "label-col": { span: 2 },
                        "wrapper-col": { span: 6 },
                        prop: "postId",
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            "show-search": "",
                            placeholder: "请输入或选择",
                            "option-filter-prop": "children",
                            "option-label-prop": "label",
                          },
                          on: {
                            change: _vm.handlePostChange,
                            search: function ($event) {
                              return _vm.remotePostSearch($event)
                            },
                            focus: _vm.handlePostFocus,
                          },
                          model: {
                            value: _vm.params.postId,
                            callback: function ($$v) {
                              _vm.$set(_vm.params, "postId", $$v)
                            },
                            expression: "params.postId",
                          },
                        },
                        [
                          _c(
                            "a-select-opt-group",
                            { attrs: { label: "现有岗位" } },
                            _vm._l(_vm.postList, function (item) {
                              return _c(
                                "a-select-option",
                                {
                                  key: item.id,
                                  attrs: {
                                    value: item.id,
                                    label: item.postName,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex a-center j-center" },
                                    [
                                      _c("span", { staticClass: "flex-1" }, [
                                        _vm._v(_vm._s(item.postName)),
                                      ]),
                                      _vm.user.id == item.creatUser
                                        ? _c("a-icon", {
                                            attrs: { type: "close" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.delPost(item)
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                          _c(
                            "a-select-opt-group",
                            { attrs: { label: "新增岗位" } },
                            _vm._l(_vm.postListAdd, function (d, index) {
                              return _c(
                                "a-select-option",
                                { key: index, attrs: { value: d.postName } },
                                [_vm._v(" " + _vm._s(d.postName) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "姓名",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 18 },
                        prop: "userName",
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            "show-search": "",
                            placeholder: "请输入或选择",
                            "option-filter-prop": "children",
                          },
                          on: {
                            change: _vm.handleUserChange,
                            search: function ($event) {
                              return _vm.remoteSearch($event)
                            },
                            focus: _vm.handleUserFocus,
                          },
                          model: {
                            value: _vm.params.userName,
                            callback: function ($$v) {
                              _vm.$set(_vm.params, "userName", $$v)
                            },
                            expression: "params.userName",
                          },
                        },
                        [
                          _c(
                            "a-select-opt-group",
                            { attrs: { label: "现有姓名" } },
                            _vm._l(_vm.userSafeList, function (item) {
                              return _c(
                                "a-select-option",
                                {
                                  key: item.id,
                                  attrs: { value: item.name, title: item.name },
                                },
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              )
                            }),
                            1
                          ),
                          _c(
                            "a-select-opt-group",
                            { attrs: { label: "新增姓名" } },
                            _vm._l(_vm.userSafeListAdd, function (d, index) {
                              return _c(
                                "a-select-option",
                                { key: index, attrs: { value: d.name } },
                                [_vm._v(" " + _vm._s(d.name) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "联系方式",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 18 },
                        prop: "phone",
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          placeholder: "请输入联系方式",
                          allowClear: "",
                          disabled: _vm.phoneDisabled,
                          maxLength: 11,
                        },
                        model: {
                          value: _vm.params.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "phone", $$v)
                          },
                          expression: "params.phone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "备注",
                        "label-col": { span: 2 },
                        "wrapper-col": { span: 14 },
                        prop: "remark",
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入", allowClear: "" },
                        model: {
                          value: _vm.params.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "remark", $$v)
                          },
                          expression: "params.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "上传证件",
                        "label-col": { span: 2 },
                        "wrapper-col": { span: 14 },
                        prop: "cerFilePath",
                      },
                    },
                    [
                      _c(
                        "a-upload",
                        {
                          attrs: {
                            "list-type": "picture-card",
                            accept: "image/png,image/jpg,image/jpeg",
                            customRequest: _vm.resourceUpload,
                            "file-list": _vm.fileList,
                          },
                          on: {
                            preview: _vm.handlePreview,
                            change: _vm.handleChange,
                          },
                        },
                        [
                          _vm.fileList.length < 1
                            ? _c(
                                "div",
                                [_c("a-icon", { attrs: { type: "plus" } })],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      staticClass: "ml-3",
                      attrs: { "wrapper-col": { span: 16 } },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex flex-row-reverse" },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.submit },
                            },
                            [_vm._v(" 提交 ")]
                          ),
                          _c(
                            "a-button",
                            { staticClass: "mr-2", on: { click: _vm.close } },
                            [_vm._v(" 关闭 ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { visible: _vm.previewVisible, footer: null },
          on: {
            cancel: function ($event) {
              _vm.previewVisible = false
            },
          },
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { alt: "example", src: _vm.previewImage },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }