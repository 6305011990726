import FileSaver from "file-saver";
import axios from 'axios'
export default class fileSave {
    /**
     * 导出Excel文件
     * @param {*} res   文件流
     * @param {*} name  文件名
     */
    static getExcel(res, name) {
        let blob = new Blob([res], {
            type: "application/vnd.ms-excel"
        });
        FileSaver.saveAs(blob, name + ".xlsx");
    }
 
    /**
     * 导出CSV文件
     * @param {*} res   文件流
     * @param {*} name  文件名
     */
    static getCsv(res, name) {
        let blob = new Blob([res], {
            type: "application/vnd.ms-excel"
        });
        FileSaver.saveAs(blob, name + ".csv");
    }
 
    /**
     * 导出图片1
     * @param {*} url 图片地址
     * @param {*} name  文件名
     */
    static getImgURLs(url, name) {
        let last = url.substring(url.lastIndexOf("."), url.length);
        FileSaver.saveAs(url, `${name}${last}`);
    }

    /**
     * 导出图片1
     * @param {*} url 图片地址
     * @param {*} name  文件名
     */
    static getFileURLs(url, name) {
      FileSaver.saveAs(url, `${name}`);
    }
     /**
     * 导出图片2
     * @param {*} res 文件流
     * @param {*} name  文件名
     */
    static downLoadImg(res, filename) {
        let blob = new Blob([res], {
            type: "image/jpeg"
        });
        FileSaver.saveAs(blob, `${filename}.jpg`);
    }

    
    static downloadFile(url, filename,type) {
      if(!type){
        type = 'application/vnd.ms-excel';
      }
      axios .get(url, { 
          responseType: 'blob',
        })
        .then((res) => {
          const blob = new Blob([res.data], { type: type }) // 构造一个blob对象来处理数据，并设置文件类型
          if (window.navigator.msSaveOrOpenBlob) {
            // 兼容IE10
            navigator.msSaveBlob(blob, filename)
          } else {
            const href = URL.createObjectURL(blob) // 创建新的URL表示指定的blob对象
            const a = document.createElement('a')
            a.style.display = 'none'
            a.href = href // 指定下载链接
            a.download = filename // 指定下载文件名
            a.click()
            URL.revokeObjectURL(a.href) // 释放URL对象
          }
        })
    }
}