import {EDIT_PRODUCT_DETAIL,GET_PRODUCT_LIST,GET_PRODUCT_DETAIL_LIST,ADD_PRODUCT,GET_PRODUCT_DETAIL,DEL_PRODUCT_DETAIL, GET_QUOTATION_LIST,DEL_QUOTATION,GET_QUOTATION_DETAIL,TECHNICAL_DISCLOSURE_DOWNLOAD} from '@/services/api'
import {request, METHOD} from '@/utils/request'

/**
 *  获取产品库列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getProductList(params) {
  return request(GET_PRODUCT_LIST, METHOD.POST, params)
}

/**
 *  获取子产品库列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getProductDetailList(params) {
  return request(GET_PRODUCT_DETAIL_LIST, METHOD.POST, params)
}

/**
 *  新增子产品
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function addProductDetail(params) {
  return request(ADD_PRODUCT, METHOD.POST, params)
}

/**
 *  查看子产品详情
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getProductDetail(params) {
  return request(GET_PRODUCT_DETAIL + `/${params.id}`, METHOD.GET)
}

/**
 *  修改子产品详情
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function editProductDetail(params) {
  return request(EDIT_PRODUCT_DETAIL, METHOD.POST,params)
}

/**
 *  删除子产品详情
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function delProductDetail(params) {
  return request(DEL_PRODUCT_DETAIL + `/${params.id}`, METHOD.DELETE)
}

/**
 *  查询报价单列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getQuotationList(params) {
  return request(GET_QUOTATION_LIST, METHOD.POST,params)
}

/**
 *  删除（逻辑）报价单
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function delQuotation(params) {
  return request(DEL_QUOTATION + `/${params.id}`, METHOD.DELETE)
}

/**
 *  查询报价单详情
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getQuotationDetail(params) {
  return request(GET_QUOTATION_DETAIL + `/${params.id}`, METHOD.GET)
}

/** 
 *  技术交底下载资料
 * @params ID
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getDisclosureDownload(params) {
  return request(TECHNICAL_DISCLOSURE_DOWNLOAD + `/${params.id}`, METHOD.GET,{},{responseType: "blob"})
}
