<template>
  <div>
    <div class="mt-2">
      <form-index ref="formindex" :formData="formData" :flexAction="true" :baiduMapBoolen="false"
        @handleSubmit="handleSubmit" @handleChange="handleChange" @handlSelect="handlSelect" @changeMonth="changeMonth">
        <template #flexAction>
          <a-space :style="{ marginTop: '4px' }">
            <a-button icon="search" type="primary" html-type="submit">查询</a-button>
            <a-button @click="reset"> 重置 </a-button>
            <a-button type="primary" @click="handleAddNewPayroll">新增</a-button>
          </a-space>
        </template>
      </form-index>
    </div>
    <standard-table class="table" :columns="columns" :dataSource="dataSource" rowKey="id"
      :pagination="pagination" :loading="tableLoading" @change="onPageChange" :isAlert="false" :bordered="true"
      tableSize="middle">
      <template slot="index" slot-scope="{ index }">
        <span>
          {{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}
        </span>
      </template>
      <template slot="status" slot-scope="{ text }">
        <div style="height: 40px;line-height: 40px;" v-if="text == 0">草稿</div>
        <div style="height: 40px;line-height: 40px;" v-if="text == 1">待财务审核</div>
        <div style="height: 40px;line-height: 40px;" v-if="text == 2">财务驳回</div>
        <div style="height: 40px;line-height: 40px;" v-if="text == 3">已汇总</div>
        <div style="height: 40px;line-height: 40px;" v-if="text == 4">已完成</div>
      </template>
      <template slot="action" slot-scope="{ record }">
        <div>
          <a @click="goDetail(record)">明细</a>
          <a-divider type="vertical" v-if="record.status == 0 || record.status == 2"/>
          <a @click="edit(record)" v-if="record.status == 0 || record.status == 2">编辑</a>
          <a-divider type="vertical"  v-if="record.status == 0 || record.status == 2"/>
          <a style="color: #ff5c77;" @click="del(record)" v-if="record.status == 0 || record.status == 2">删除</a>
        </div>
      </template>
    </standard-table>
    <a-modal v-model="payrollVisible" title="本次制表信息录入" :destroyOnClose="true" :footer="null" @cancel="closeModal"
      :maskClosable="false">
      <form-index ref="formindexModal" :formData="formDataModal" :flexSubmit="true" @resourceUpload="resourceUpload"
        @upRemove="upRemove" :flexAction="true" @handleSubmit="handleMakeTable" @handleChange="handleModalChange">
        <template #flexAction>
          <div class="flex a-center j-center">
            <a-space :style="{ marginBottom: '20px' }">
              <a-button @click="closeModal"> 返回 </a-button>
              <a-button type="primary" html-type="submit">制作工资单</a-button>
            </a-space>
          </div>
        </template>
      </form-index>
    </a-modal>
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import { mapGetters } from "vuex";
import { getOfficeProject } from "@/services/projectManagement";
import { getProAllOfficeList } from "@/services/participationUnit";
import { getSalaryPlanList, createSalaryPlan, delSalaryPlan, updateSalaryPlan } from '@/services/payroll'
import moment from 'moment';
import { useRender } from '@/hooks/useRender.js'
export default {
  name: 'SalaryList',
  components: {
    formIndex,
    StandardTable, //表格封装
  },
  data() {
    const formData = [
      {
        label: "制表月份",
        placeholder: "请选择制表月份",
        decorator: [
          "searchMonthArr",
          { rules: [{ required: false, message: "请选择制表月份" }] },
        ],
        type: "rangeMonthPicker",
        key: "searchMonthArr",
        col: 5,
        labelCol: 5,
        wrapperCol: 16,
        display: true,
        format: "YYYY-MM",
        mode: ['month', 'month'],
      },
      {
        label: "工资月份",
        placeholder: "请选择",
        decorator: [
          "doMonth",
          {
            initialValue: moment(moment()).format("YYYYMM"),
            rules: [{ required: false, message: "请选择" }],
          },
        ],
        type: "monthPicker",
        key: "doMonth",
        col: 5,
        labelCol: 5,
        wrapperCol: 16,
        display: true,
        format: "YYYYMM",
        styles: 'width: 100%'
      },
      {
        label: "项目名称",
        placeholder: "请输入项目名称",
        decorator: [
          "projectId",
          { rules: [{ required: false, message: "请输入项目名称" }] },
        ],
        type: "inputGroup",
        key: "projectId",
        dataSource: [],
        col: 5,
        labelCol: 5,
        wrapperCol: 16,
        display: true,
      },
      {
        type: "action",
        col: 6,
        display: true,
      },
    ];
    const columns = [
      {
        title: "序号",
        width: 65,
        scopedSlots: { customRender: "index" },
        align: "center",
      },
      {
        title: "计划编码",
        dataIndex: "planCode",
        align: "center",
        scopedSlots: { customRender: "planCode" },
      },
      {
        title: "项目名称",
        dataIndex: "projectName",
        align: "center",
        width: 350,
        ellipsis: true,
        scopedSlots: { customRender: "projectName" },
      },
      // {
      //   title: "企业",
      //   dataIndex: "officeName",
      //   align: "center",
      //   width: 350,
      //   scopedSlots: { customRender: "officeName" },
      // },
      {
        title: "合同编号",
        dataIndex: "contractNo",
        align: "center",
        ellipsis: true,
      },
      {
        title: "发放工资月份",
        dataIndex: "doMonth",
        align: "center",
        // ellipsis: true,
        scopedSlots: { customRender: "doMonth" },
        // fixed: "left",
      },
      {
        title: "不含税应发总额",
        dataIndex: "wages",
        align: "center",
        scopedSlots: { customRender: "wages" },
      },
      {
        title: "含税应发总额",
        align: "center",
        dataIndex: "taxWages",
        customRender: (text) => useRender.renderText(text ? text : '/', '')
      },
      {
        title: "状态",
        dataIndex: "status",
        width: 100,
        align: "center",
        scopedSlots: { customRender: "status" },
      },
      {
        title: "操作",
        align: "center",
        width: 160,
        // fixed: "right",
        scopedSlots: { customRender: "action" },
      },
    ];
    const formDataModal = [
      {
        label: "项目",
        placeholder: "请输入项目名称",
        decorator: [
          "projectId",
          { rules: [{ required: true, message: "请输入项目名称" }] },
        ],
        type: "inputGroup",
        key: "projectId",
        dataSource: [],
        col: 24,
        labelCol: 8,
        wrapperCol: 15,
        display: true,
        disType: true
      },
      {
        label: "项目",
        placeholder: "请输入项目名称",
        decorator: [
          "projectName",
          { rules: [{ required: true, message: "请输入项目名称" }] },
        ],
        type: "inputGroup",
        key: "projectName",
        dataSource: [],
        col: 24,
        labelCol: 8,
        wrapperCol: 15,
        display: false,
        disabled: true,
        disType: true
      },
      // {
      //   label: "企业",
      //   placeholder: "请选择",
      //   decorator: ["officeId", { rules: [{ required: false, message: "请选择" }] }],
      //   type: "inputGroup",
      //   key: "officeId",
      //   dataSource: [],
      //   col: 24,
      //   labelCol: 8,
      //   wrapperCol: 15,
      //   display: true,
      //   disType: true
      // },
      {
        label: "企业",
        placeholder: "请选择",
        decorator: ["officeName", { rules: [{ required: true, message: "请选择" }] }],
        type: "input",
        key: "officeName",
        dataSource: [],
        col: 24,
        labelCol: 8,
        wrapperCol: 15,
        display: true,
        disabled: true,
        disType: true
      },
      {
        label: "分包合同编号",
        placeholder: "请填写",
        decorator: ["contractNo", { rules: [{ required: true, message: "请填写" }] }],
        type: "input",
        key: "contractNo",
        col: 24,
        labelCol: 8,
        wrapperCol: 15,
        display: true,
      },
      {
        label: "本次发放工资月份",
        placeholder: "请选择",
        decorator: ["doMonth", { initialValue: moment(moment()).format("YYYYMM"), rules: [{ required: true, message: "请选择" }] },],
        type: "monthPicker",
        key: "doMonth	",
        col: 24,
        labelCol: 8,
        wrapperCol: 15,
        display: true,
        format: "YYYYMM",
        styles: 'width: 100%'
      },
      {
        label: "计划不含税应发总额",
        placeholder: "请填写",
        decorator: ["wages", { rules: [{ required: false, message: "请填写" }] }],
        type: "input",
        key: "wages",
        col: 24,
        addonAfter: '元',
        labelCol: 8,
        wrapperCol: 15,
        display: true,
      },
      {
        label: "账户类型",
        placeholder: "请选择账户类型",
        decorator: ["accountType", { initialValue: 1, rules: [{ required: true, message: "请选择账户类型" }] },],
        type: "radio",
        key: "accountType",
        radiolist: [
          {
            name: "专户",
            value: 2,
          },
          {
            name: "普户",
            value: 1,
          },
        ],
        labelCol: 8,
        wrapperCol: 15,
        col: 24,
        display: true,
      },
      // {
      //   label: "附件",
      //   placeholder: "请上传",
      //   decorator: ["filePath", { rules: [{ required: false, message: "请上传" }] }],
      //   type: "tsUpload",
      //   listType: 'picture-card',
      //   accept: ".pdf",
      //   key: "filePath",
      //   defaultFileList: [],
      //   labelCol: 8,
      //   wrapperCol: 15,
      //   col: 24,
      //   display: true,
      // },
      {
        type: "action",
        col: 24,
        display: true,
      },
    ]
    return {
      id: '',//有值是修改，没值是新增
      officeId: '',
      projectId: '',
      formData: formData,
      columns: columns, //表格数据
      dataSource: [],
      pagination: {
        showSizeChanger: true,
        pageSizeOptions: ["10", "50", "100", "150"],
        current: 1,
        pageSize: 50,
        total: 0,
        showLessItems: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      tableLoading: false,
      params: {
        pageNo: 1,
        pageSize: 50,
        status: '',
        doMonth: '',
      },
      loadingText: "数据加载中...",
      payrollVisible: false,
      formDataModal: formDataModal,
    };
  },
  props: {
    salaryStatus: {
      type: String,
      required: true,
      default: ''
    }
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  watch: {
    salaryStatus: {
      handler(val) {
        this.params.status = val;
        let obj = this.$refs.formindex.getFieldValue()
        this.getData(Object.assign(this.params, obj))
      },
    },
    loadingText: {
      handler(newName, oldName) {
        this.loadingText = newName;
      },
      immediate: true,
    },
    id: {
      handler(val) {
        if (val) {
          this.formDataModal.forEach(el => {
            if (el.key == 'projectName') {
              el.display = true
            } else if (el.key == 'projectId') {
              el.display = false
            }
          })
        } else {
          this.formDataModal.forEach(el => {
            if (el.key == 'projectName') {
              el.display = false
            } else if (el.key == 'projectId') {
              el.display = true
            }
          })
        }
      },
      immediate: true
    }
  },
  created() {
    this.params.doMonth = moment(moment()).format("YYYYMM")
  },
  activated() {
    this.officeId = this.user.officeId
    // this.params = {
    //   pageNo: 1,
    //   pageSize: 10,
    // },
    this.getData(this.params);
  },
  methods: {
    // 提交表单数据
    handleSubmit(e) {
      if (e.searchMonthArr && e.searchMonthArr.length > 0) {
        e.startDate = moment(e.searchMonthArr[0]).format('YYYY-MM')
        e.endDate = moment(e.searchMonthArr[1]).format('YYYY-MM')
      }
      this.params.pageNo = 1
      this.params = Object.assign({}, this.params, e);
      this.getData(this.params)
    },
    // 特殊数据处理
    handlSelect(obj) {
      const { val, keys } = obj;
      if (keys === "officeId") {
        this.projectList.forEach((item) => {
          if (item.officeName == val) {
            this.$refs["formindex"].setFieldsValue(
              item.officeId,
              {},
              "officeId"
            );
          }
        });
      }
    },
    changeMonth(e) {
      const { val, key } = e;
      if (key === "searchMonthArr") {
        this.$refs["formindex"].setFieldsValue('', { [key]: [moment(val[0]).format('YYYYMM'), moment(val[1]).format('YYYYMM')] }, '');
      }
    },
    handleChange(e) {
      this.projectArr = [];
      this.projectList = []
      this.getZdata(getOfficeProject, e, "projectName", 'fullName', this.formData);
    },
    reset() {
      this.$refs.formindex.resetFields()
      this.params.pageNo = 1
      this.params.status = this.salaryStatus
      let obj = this.$refs.formindex.getFieldValue()
      this.getData(Object.assign(this.params, obj))
    },
    handleModalChange(e) {
      if (e.item.key == 'projectId') {
        this.getZdata(getOfficeProject, e, "projectName", 'fullName', this.formDataModal);
      } 
      // else if (e.item.key == 'officeId') {
      //   this.getZdata(getProAllOfficeList, e, "officeName", 'officeName');
      // }
    },
    // 接口封装
    /**
     * msg 接收接口名称
     * e 数据
     * name 参数名
     * */
    getZdata(msg, e, name, title, formData) {
      let params = {
        [name]: e.value,
      }
      if (e.item.key == 'projectId') {
        params.officeId = this.user.officeId
      } else if (e.item.key == 'officeId') {
        params.projectId = this.$refs["formindexModal"].getFieldValue().projectId
      }

      msg(params).then((res) => {
        if (res.status == 200) {
          const data = res.data.data.list ? res.data.data.list : res.data.data
          let arr = []
          data.forEach((item) => {
            let obj = {
              title: item[title],
            }
            if (e.item.key == 'projectId') {
              obj.id = item.id
            } else if (e.item.key == 'officeId') {
              obj.id = item.officeId
            }
            arr.push(obj);
          });
          // 获取当前匹配key，赋值相对于数组
          formData.forEach((item) => {
            if (e.item.key === item.key) {
              item.dataSource = arr;
            }
          });
        }
      });
    },
    edit(record) {
      this.payrollVisible = true
      this.id = record.id
      this.projectId = record.projectId
      this.$nextTick(() => {
        this.$refs["formindexModal"].setFieldsValue('', record)
      })
    },
    getData(params) {
      this.tableLoading = true;
      getSalaryPlanList(params).then(res => {
        if (res.data.code == 0) {
          this.dataSource = res.data.data.list
          this.pagination.total = res.data.data.total;
        } else {
          this.$message.error(res.data.msg)
        }
        this.tableLoading = false;
      })
    },
    goDetail(e) {
      this.$router.push({
        name: '发放计划详情',
        query: {
          id: e.id
        }
      });
    },
    //表格 - 分页查询
    onPageChange(e) {
      this.params.pageNo = e.current;
      this.params.pageSize = e.pageSize;
      this.pagination.current = e.current;
      this.pagination.pageSize = e.pageSize;
      this.getData(this.params);
    },
    //上传文件回调
    resourceUpload(obj) {
      if (obj.name.includes('pdf')) {
        this.formDataModal.forEach(item => {
          if (item.key === obj.key) {
            item.defaultFileList = [obj]
          }
        })
      } else {
        this.$message.warning(`只能上传PDF文件`)
      }
    },
    //删除上传附件
    upRemove(file) {
      this.formDataModal.forEach(item => {
        if (item.key === file.key) {
          item.defaultFileList = []
        }
      })
    },
    handleAddNewPayroll() {
      this.id = ''
      this.projectId = ''
      this.payrollVisible = true
      this.$nextTick(() => {
        this.$refs["formindexModal"].setFieldsValue('', { officeName: this.user.officeLoginData.officeName})
      })
    },
    handleMakeTable(e) {
      let method = null
      if (this.id) {
        e.id = this.id
        e.projectId = this.projectId
        method = updateSalaryPlan
      } else {
        method = createSalaryPlan
      }
      e.officeId = this.officeId
      method(e).then(res => {
        if (res.data.code == 0) {
          this.params = {
            pageNo: 1,
            pageSize: 50,
          },
          this.getData(this.params)
          this.closeModal()
          this.$router.push({
            name: '发放计划详情',
            query: {
              id: this.id ? this.id : res.data.data
            }
          });
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    del(e) {
      const _that = this
      this.$confirm({
        title: '确定删除？',
        onOk() {
          delSalaryPlan({ id: e.id }).then(res => {
            if (res.data.code == 0) {
              _that.params = {
                pageNo: 1,
                pageSize: 50,
              },
                _that.getData(_that.params);
            } else {
              _that.$message.error(res.data.msg)
            }
          })
        }
      })

    },
    closeModal() {
      this.payrollVisible = false
      this.formDataModal.forEach((item) => {
        if (item.defaultFileList && item.defaultFileList.length > 0) {
          item.defaultFileList = [];
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.constructiontTable_index {
  padding: 24px;
  background: #fff;
  border-radius: 6px;
  margin-bottom: 10px;
  min-height: 560px;

  /deep/ .light-select {
    border-color: #fd9e06;
    width: 155px;

    .ant-select-selection__placeholder {
      color: #fff
    }

    .ant-select-selection {
      background: #fd9e06;
      border-color: #fd9e06;
      color: #fff;

      .ant-select-arrow {
        color: #fff;
      }
    }

  }
}

.table {
  margin-top: -40px;
}

/deep/ .ant-modal-footer {
  text-align: center;
  border-top: none;
  padding-bottom: 24px;
}

/deep/ .ant-modal-body {
  padding-bottom: unset;
}
</style>