<template>
  <div>
    <a-tabs :default-active-key="defaultActiveKey">
      <a-tab-pane key="1" tab="投诉">
        <complaint-list-modal :gcglbId="gcglbId" :year="year"></complaint-list-modal>
      </a-tab-pane>
      <a-tab-pane key="2" tab="约谈">
        <interview-list-modal :gcglbId="gcglbId" :year="year"></interview-list-modal>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import ComplaintListModal from './complaintListModal.vue';
import InterviewListModal from './interViewListModal.vue';
export default {
  components: {
    ComplaintListModal,
    InterviewListModal
  },
  props: {
    year: {
      type: String,
      required: true,
    },
		gcglbId: {
			type: String,
      required: true,
		}
	},
  data() {
    return {
      defaultActiveKey: "1",
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>

<style lang="less" scoped>

</style>