var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "work-content" },
    [
      _vm._m(0),
      _c("a-divider"),
      _c(
        "div",
        {
          staticStyle: {
            flex: "1",
            display: "flex",
            "flex-direction": "column",
            "align-items": "center",
          },
        },
        [
          _c("div", { staticStyle: { display: "flex", height: "53px" } }, [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "flex-direction": "column",
                  "justify-content": "space-between",
                  "align-items": "center",
                  "margin-right": "20px",
                },
              },
              [
                _c("span", { staticClass: "font-20 font-bold" }, [
                  _vm._v(_vm._s(_vm.staffData.onlineTeams || 5972)),
                ]),
                _c("span", [_vm._v("累计登记")]),
              ]
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "flex-direction": "column",
                  "justify-content": "space-between",
                  "align-items": "center",
                  "margin-right": "20px",
                },
              },
              [
                _c("span", { staticClass: "font-20 font-bold" }, [
                  _vm._v(_vm._s(_vm.staffData.totalTeams || 879)),
                ]),
                _c("span", [_vm._v("当前在场")]),
              ]
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "flex-direction": "column",
                  "justify-content": "space-between",
                  "align-items": "center",
                  "margin-right": "20px",
                },
              },
              [
                _c("span", { staticClass: "font-20 font-bold" }, [
                  _vm._v(_vm._s(_vm.staffData.todayTeams || 423)),
                ]),
                _c("span", [_vm._v("今日出勤")]),
              ]
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "flex-direction": "column",
                  "justify-content": "space-between",
                  "align-items": "center",
                  "margin-right": "20px",
                },
              },
              [
                _c("span", { staticClass: "font-20 font-bold" }, [
                  _vm._v(_vm._s(_vm.staffData.teamCql || 48.0) + "%"),
                ]),
                _c("span", [_vm._v("今日出勤率")]),
              ]
            ),
          ]),
          _c("a-divider"),
          _vm._m(1),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "black font-16 font-bold overtext-1 flex mb-1" },
      [
        _c("img", {
          staticStyle: { width: "25px", height: "25px" },
          attrs: { src: require("@/assets/img/payroll-card1.png"), alt: "" },
        }),
        _c("span", { staticClass: "ml-05" }, [_vm._v("工人情况总览")]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { flex: "1", width: "100%" } }, [
      _c("div", {
        staticStyle: {
          margin: "0 auto",
          width: "60%",
          height: "50%",
          "min-height": "180px",
        },
        attrs: { id: "jobAgeChart" },
      }),
      _c("div", {
        staticStyle: {
          margin: "0 auto",
          width: "60%",
          height: "50%",
          "min-height": "180px",
        },
        attrs: { id: "jobStructureChart" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }