<template>
  <div>
    <a-dropdown>
      <div class="header-avatar overtext-1" style="cursor: pointer">
        <a-avatar class="avatar" size="small" shape="circle" :src="user.avatar"/>
        <span class="name overtext-1" :title="user.name" style="max-width: 150px">{{user.name}}</span>
      </div>
      <a-menu :class="['avatar-menu']" slot="overlay">
        <!-- <a-menu-item>
          <a-icon type="user" />
          <span>个人中心</span>
        </a-menu-item>
        <a-menu-item>
          <a-icon type="setting" />
          <span>设置</span>
        </a-menu-item> -->
        <a-menu-item @click="changePassword">
          <a-icon type="setting" />
          <span>修改密码</span>
        </a-menu-item>
        <a-menu-divider />
        <a-menu-item @click="logout">
          <a-icon style="margin-right: 8px;" type="poweroff" />
          <span>退出登录</span>
        </a-menu-item>
      </a-menu>
      </a-dropdown>
    <a-modal
      :visible="updatePwdModal"
      :footer="null"
      @cancel="handleCancel"
      :destroyOnClose="true"
    >
      <update-password @cancel="handleCancel"></update-password>
    </a-modal>
  </div>

</template>

<script>
import {mapGetters} from 'vuex'
import {logout,loginOut} from '@/services/user'
import UpdatePassword from './UpdatePassword.vue';

export default {
  components: { UpdatePassword },
  name: 'HeaderAvatar',
  computed: {
    ...mapGetters('account', ['user']),
  },
  data() {
    return {
      confirmLoading: false,
      updatePwdModal: false
    };
  },
  methods: {
    handleCancel(e) {
      this.updatePwdModal = false
    },
    logout() {
      loginOut({userId: this.user.id}).then(res => {
        logout()
        this.$router.push('/login')
      })
    },
    changePassword() {
      this.updatePwdModal = true
      // this.$router.push('/my/changePwd')
    }
  }
}
</script>

<style lang="less" scoped>
  .header-avatar{
    display: inline-flex;
    .avatar, .name{
      align-self: center;
    }
    .avatar{
      margin-right: 8px;
    }
    .name{
      font-weight: 500;
    }
  }
  .avatar-menu{
    width: 150px;
  }

</style>
