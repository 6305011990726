var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { background: "#ECECEC", padding: "30px" } },
    [
      _c(
        "a-row",
        { attrs: { gutter: 16 } },
        [
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c(
                "a-card",
                {
                  staticStyle: { width: "100%", height: "280px" },
                  attrs: {
                    "tab-list": _vm.tabListNoTitle,
                    "active-tab-key": _vm.noTitleKey,
                  },
                  on: {
                    tabChange: function (key) {
                      return _vm.onTabChange(key, "noTitleKey")
                    },
                  },
                },
                [
                  _vm.noTitleKey === "office"
                    ? _c(
                        "p",
                        [
                          _c(
                            "a-row",
                            {
                              staticStyle: { "padding-left": "5%" },
                              attrs: { gutter: 16 },
                            },
                            [
                              _c(
                                "a-col",
                                { attrs: { span: 6 } },
                                [
                                  _c("a-statistic", {
                                    attrs: {
                                      title: "检查项目总数",
                                      value: _vm.gkObj.checks,
                                      suffix: "个",
                                      "value-style": { color: "blue" },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 6 } },
                                [
                                  _c("a-statistic", {
                                    attrs: {
                                      title: "一般隐患数",
                                      value: "" + (_vm.yhzs - _vm.gkObj.zdyhs),
                                      suffix: "个",
                                      "value-style": { color: "#cf1322" },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 6 } },
                                [
                                  _c("a-statistic", {
                                    attrs: {
                                      title: "重大隐患数",
                                      suffix: "个",
                                      value: _vm.gkObj.zdyhs,
                                      "value-style": { color: "#cf1322" },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 6 } },
                                [
                                  _c("a-statistic", {
                                    attrs: {
                                      title: "隐患整改率",
                                      value: _vm.gkObj.zgl,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-row",
                            {
                              staticStyle: { "padding-left": "5%" },
                              attrs: { gutter: 16 },
                            },
                            [
                              _c(
                                "a-col",
                                { attrs: { span: 6 } },
                                [
                                  _c("a-statistic", {
                                    attrs: {
                                      title: "检查总数",
                                      value: _vm.totalChecks,
                                      suffix: "次",
                                      "value-style": { color: "green" },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 6 } },
                                [
                                  _c("a-statistic", {
                                    attrs: {
                                      title: "日常检查总数",
                                      value:
                                        "" +
                                        (_vm.gkObj.checks - _vm.gkObj.zxjc),
                                      suffix: "次",
                                      "value-style": { color: "green" },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 6 } },
                                [
                                  _c("a-statistic", {
                                    attrs: {
                                      title: "专项检查总数",
                                      value: "" + (_vm.gkObj.zxjc | 0),
                                      suffix: "次",
                                      "value-style": { color: "purple" },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 6 } },
                                [
                                  _c("a-statistic", {
                                    attrs: {
                                      title: "带班检查总数",
                                      value: "" + (_vm.gkObj.dbCnt | 0),
                                      suffix: "次",
                                      "value-style": { color: "#000" },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-row",
                            {
                              staticStyle: { "padding-left": "5%" },
                              attrs: { gutter: 16 },
                            },
                            [
                              _c(
                                "a-col",
                                { attrs: { span: 6 } },
                                [
                                  _c("a-statistic", {
                                    attrs: {
                                      title: "安全验收总数",
                                      value: "" + (_vm.gkObj.aqyscnt | 0),
                                      suffix: "份",
                                      "value-style": { color: "green" },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 6 } },
                                [
                                  _c("a-statistic", {
                                    attrs: {
                                      title: "停工通知书总数",
                                      value: "" + (_vm.gkObj.tgdcnt | 0),
                                      suffix: "份",
                                      "value-style": { color: "green" },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 6 } },
                                [
                                  _c("a-statistic", {
                                    attrs: {
                                      title: "处罚通知书总数",
                                      value: "" + (_vm.gkObj.cfdcnt | 0),
                                      suffix: "份",
                                      "value-style": { color: "green" },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 6 } },
                                [
                                  _c("a-statistic", {
                                    attrs: {
                                      title: "安全生产约谈总数",
                                      value: "" + (_vm.gkObj.ytcnt | 0),
                                      suffix: "次",
                                      "value-style": { color: "purple" },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm.noTitleKey === "gcglb"
                    ? _c(
                        "p",
                        [
                          _c(
                            "a-row",
                            {
                              staticStyle: { "padding-left": "5%" },
                              attrs: { gutter: 16 },
                            },
                            [
                              _c(
                                "a-col",
                                { attrs: { span: 6 } },
                                [
                                  _c("a-statistic", {
                                    attrs: {
                                      title: "检查项目总数",
                                      value: _vm.gcglbObj.projects,
                                      suffix: "个",
                                      "value-style": { color: "blue" },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 6 } },
                                [
                                  _c("a-statistic", {
                                    attrs: {
                                      title: "一般隐患",
                                      value:
                                        "" +
                                        (_vm.gcglbYhzs - _vm.gcglbObj.zdyhs),
                                      suffix: "个",
                                      "value-style": { color: "#cf1322" },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 6 } },
                                [
                                  _c("a-statistic", {
                                    attrs: {
                                      title: "重大隐患",
                                      value: "" + (_vm.gcglbObj.zdyh | 0),
                                      suffix: "个",
                                      "value-style": { color: "#cf1322" },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 6 } },
                                [
                                  _c("a-statistic", {
                                    attrs: {
                                      title: "隐患整改率",
                                      value: _vm.gcglbObj.zgl,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-row",
                            {
                              staticStyle: { "padding-left": "5%" },
                              attrs: { gutter: 16 },
                            },
                            [
                              _c(
                                "a-col",
                                { attrs: { span: 6 } },
                                [
                                  _c("a-statistic", {
                                    attrs: {
                                      title: "安全验收总数",
                                      value: "" + (_vm.gkObj.aqyscnt | 0),
                                      suffix: "份",
                                      "value-style": { color: "green" },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 6 } },
                                [
                                  _c("a-statistic", {
                                    attrs: {
                                      title: "检查总数",
                                      value: _vm.gcglbObj.checks,
                                      suffix: "次",
                                      "value-style": { color: "green" },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 6 } },
                                [
                                  _c("a-statistic", {
                                    attrs: {
                                      title: "日常检查总数",
                                      value:
                                        "" +
                                        (isNaN(
                                          _vm.gcglbObj.checks -
                                            _vm.gcglbObj.zxjc
                                        )
                                          ? 0
                                          : _vm.gcglbObj.checks -
                                            _vm.gcglbObj.zxjc),
                                      suffix: "次",
                                      "value-style": { color: "green" },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 6 } },
                                [
                                  _c("a-statistic", {
                                    attrs: {
                                      title: "专项检查总数",
                                      value: "" + (_vm.gcglbObj.zxjc | 0),
                                      suffix: "次",
                                      "value-style": { color: "purple" },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticStyle: { float: "right", "max-width": "350px" },
                      attrs: { slot: "tabBarExtraContent" },
                      slot: "tabBarExtraContent",
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { "max-width": "60%", float: "right" } },
                        [
                          _vm.monthQueryParams.queryType == 3
                            ? _c("a-range-picker", {
                                attrs: {
                                  "default-value": [
                                    _vm.moment(_vm.firstDay, _vm.dateFormat),
                                    _vm.moment(_vm.nowDay, _vm.dateFormat),
                                  ],
                                  format: _vm.dateFormat,
                                },
                                on: { change: _vm.mtChangeDate },
                                model: {
                                  value: _vm.monthQueryParams.plan_date,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.monthQueryParams,
                                      "plan_date",
                                      $$v
                                    )
                                  },
                                  expression: "monthQueryParams.plan_date",
                                },
                              })
                            : _vm._e(),
                          _vm.monthQueryParams.queryType == 2
                            ? _c(
                                "a-month-picker",
                                {
                                  staticStyle: { width: "100px" },
                                  attrs: {
                                    placeholder: "请选择月份",
                                    "default-value": _vm.moment(
                                      _vm.nowMonth,
                                      _vm.monthFormat
                                    ),
                                    format: _vm.monthFormat,
                                  },
                                  on: { change: _vm.mtChangeDate },
                                  model: {
                                    value: _vm.monthQueryParams.plan_date,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.monthQueryParams,
                                        "plan_date",
                                        $$v
                                      )
                                    },
                                    expression: "monthQueryParams.plan_date",
                                  },
                                },
                                [
                                  _c("a-icon", {
                                    attrs: {
                                      slot: "suffixIcon",
                                      type: "smile",
                                    },
                                    slot: "suffixIcon",
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.monthQueryParams.queryType == 1
                            ? _c("a-date-picker", {
                                staticStyle: { width: "100px" },
                                attrs: {
                                  mode: "year",
                                  placeholder: "请输入年份",
                                  "default-value": _vm.moment(
                                    _vm.nowYear,
                                    _vm.yearFormat
                                  ),
                                  format: _vm.yearFormat,
                                  open: _vm.monthQueryParams.yearShowOne,
                                },
                                on: {
                                  openChange: _vm.monthOpenChangeOne,
                                  panelChange: _vm.monthPanelChangeOne,
                                },
                                model: {
                                  value: _vm.monthQueryParams.plan_date,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.monthQueryParams,
                                      "plan_date",
                                      $$v
                                    )
                                  },
                                  expression: "monthQueryParams.plan_date",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "a-select",
                        {
                          staticStyle: {
                            width: "80px",
                            float: "right",
                            top: "12px",
                          },
                          on: { change: _vm.mtChangeTypeDate },
                          model: {
                            value: _vm.monthQueryParams.queryType,
                            callback: function ($$v) {
                              _vm.$set(_vm.monthQueryParams, "queryType", $$v)
                            },
                            expression: "monthQueryParams.queryType",
                          },
                        },
                        [
                          _c("a-select-option", { key: 1 }, [_vm._v(" 年 ")]),
                          _c("a-select-option", { key: 2 }, [_vm._v(" 月 ")]),
                          _c("a-select-option", { key: 3 }, [_vm._v("其他")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c(
                "a-card",
                {
                  staticStyle: { height: "280px" },
                  attrs: { title: "项目月度自检情况" },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { float: "right" },
                      attrs: { slot: "extra" },
                      slot: "extra",
                    },
                    [
                      _c(
                        "a-month-picker",
                        {
                          staticStyle: { width: "100px" },
                          attrs: {
                            placeholder: "请选择月份",
                            "default-value": _vm.moment(
                              _vm.nowMonth,
                              _vm.monthFormat
                            ),
                            format: _vm.monthFormat,
                          },
                          on: { change: _vm.proChangeDate },
                          model: {
                            value: _vm.proQueryParams.plan_date,
                            callback: function ($$v) {
                              _vm.$set(_vm.proQueryParams, "plan_date", $$v)
                            },
                            expression: "proQueryParams.plan_date",
                          },
                        },
                        [
                          _c("a-icon", {
                            attrs: { slot: "suffixIcon", type: "smile" },
                            slot: "suffixIcon",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "vue-scroll",
                    {
                      staticStyle: { width: "100%", height: "160px" },
                      attrs: { ops: _vm.ops },
                    },
                    [
                      _c("a-list", {
                        attrs: {
                          bordered: "",
                          "data-source": _vm.dataSource,
                          "item-layout": "horizontal",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "renderItem",
                            fn: function (item, index) {
                              return _c(
                                "a-list-item",
                                {},
                                [
                                  _c(
                                    "a-list-item-meta",
                                    [
                                      _c("template", { slot: "description" }, [
                                        _vm._v(
                                          _vm._s(index + 1) +
                                            "." +
                                            _vm._s(item.projectName)
                                        ),
                                      ]),
                                    ],
                                    2
                                  ),
                                  _c("a", [_vm._v(_vm._s(item.cnt) + "次")]),
                                ],
                                1
                              )
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-row",
        { staticStyle: { "margin-top": "5px" }, attrs: { gutter: 16 } },
        [
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c(
                "a-row",
                { attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c("a-card", [
                        _c(
                          "span",
                          { attrs: { slot: "title" }, slot: "title" },
                          [
                            _vm._v(
                              _vm._s(_vm.gcglbtitle || "公司") +
                                "检查次数统计（总计：" +
                                _vm._s(_vm.checkTimeTotal) +
                                "次）"
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: { float: "right" },
                            attrs: { slot: "extra" },
                            slot: "extra",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "max-width": "60%",
                                  float: "right",
                                },
                              },
                              [
                                _vm.xjTimesQueryParams.queryType == 3
                                  ? _c("a-range-picker", {
                                      attrs: {
                                        "default-value": [
                                          _vm.moment(
                                            _vm.firstDay,
                                            _vm.dateFormat
                                          ),
                                          _vm.moment(
                                            _vm.nowDay,
                                            _vm.dateFormat
                                          ),
                                        ],
                                        format: _vm.dateFormat,
                                      },
                                      on: { change: _vm.xjTimesChangeDate },
                                      model: {
                                        value: _vm.xjTimesQueryParams.plan_date,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.xjTimesQueryParams,
                                            "plan_date",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "xjTimesQueryParams.plan_date",
                                      },
                                    })
                                  : _vm._e(),
                                _vm.xjTimesQueryParams.queryType == 2
                                  ? _c(
                                      "a-month-picker",
                                      {
                                        staticStyle: { width: "100px" },
                                        attrs: {
                                          placeholder: "请选择月份",
                                          "default-value": _vm.moment(
                                            _vm.nowMonth,
                                            _vm.monthFormat
                                          ),
                                          format: _vm.monthFormat,
                                        },
                                        on: { change: _vm.xjTimesChangeDate },
                                        model: {
                                          value:
                                            _vm.xjTimesQueryParams.plan_date,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.xjTimesQueryParams,
                                              "plan_date",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "xjTimesQueryParams.plan_date",
                                        },
                                      },
                                      [
                                        _c("a-icon", {
                                          attrs: {
                                            slot: "suffixIcon",
                                            type: "smile",
                                          },
                                          slot: "suffixIcon",
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.xjTimesQueryParams.queryType == 1
                                  ? _c("a-date-picker", {
                                      staticStyle: { width: "100px" },
                                      attrs: {
                                        mode: "year",
                                        placeholder: "请输入年份",
                                        "default-value": _vm.moment(
                                          _vm.nowYear,
                                          _vm.yearFormat
                                        ),
                                        format: _vm.yearFormat,
                                        open: _vm.xjTimesQueryParams
                                          .yearShowOne,
                                      },
                                      on: {
                                        openChange: _vm.xjTimesOpenChangeOne,
                                        panelChange: _vm.xjTimesPanelChangeOne,
                                      },
                                      model: {
                                        value: _vm.xjTimesQueryParams.plan_date,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.xjTimesQueryParams,
                                            "plan_date",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "xjTimesQueryParams.plan_date",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "a-select",
                              {
                                staticStyle: { width: "80px", float: "right" },
                                on: { change: _vm.checkTimeTypeChange },
                                model: {
                                  value: _vm.xjTimesQueryParams.queryType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.xjTimesQueryParams,
                                      "queryType",
                                      $$v
                                    )
                                  },
                                  expression: "xjTimesQueryParams.queryType",
                                },
                              },
                              [
                                _c("a-select-option", { key: 1 }, [
                                  _vm._v(" 年 "),
                                ]),
                                _c("a-select-option", { key: 2 }, [
                                  _vm._v(" 月 "),
                                ]),
                                _c("a-select-option", { key: 3 }, [
                                  _vm._v("其他"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("div", {
                          staticStyle: { width: "98%", height: "300px" },
                          attrs: { id: "checkTimesChart" },
                        }),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c("a-card", [
                        _c(
                          "div",
                          {
                            staticStyle: { float: "right" },
                            attrs: { slot: "extra" },
                            slot: "extra",
                          },
                          [
                            _c("a-date-picker", {
                              staticStyle: { width: "100px" },
                              attrs: {
                                mode: "year",
                                placeholder: "请输入年份",
                                "default-value": _vm.moment(
                                  _vm.nowYear,
                                  _vm.yearFormat
                                ),
                                format: _vm.yearFormat,
                                open: _vm.zglParams.yearShowOne,
                              },
                              on: {
                                openChange: _vm.zglOpenChangeOne,
                                panelChange: _vm.zglPanelChangeOne,
                              },
                              model: {
                                value: _vm.zglParams.plan_date,
                                callback: function ($$v) {
                                  _vm.$set(_vm.zglParams, "plan_date", $$v)
                                },
                                expression: "zglParams.plan_date",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "span",
                          { attrs: { slot: "title" }, slot: "title" },
                          [
                            _vm._v(
                              _vm._s(_vm.gcglbtitle || "公司") +
                                "每月隐患整改率统计情况 （整改率：" +
                                _vm._s(_vm.yhzglTotal || 0) +
                                "）"
                            ),
                          ]
                        ),
                        _c("div", {
                          staticStyle: { width: "98%", height: "300px" },
                          attrs: { id: "yhzglChart" },
                        }),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c(
                "a-card",
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(
                      _vm._s(_vm.gcglbtitle || "公司") +
                        "检查隐患类型统计 （总计：" +
                        _vm._s(_vm.yhzsTotal) +
                        "个）"
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: { float: "right" },
                      attrs: { slot: "extra" },
                      slot: "extra",
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { "max-width": "60%", float: "right" } },
                        [
                          _vm.queryParams.type == 3
                            ? _c("a-range-picker", {
                                attrs: {
                                  "default-value": [
                                    _vm.moment(_vm.firstDay, _vm.dateFormat),
                                    _vm.moment(_vm.nowDay, _vm.dateFormat),
                                  ],
                                  format: _vm.dateFormat,
                                },
                                on: { change: _vm.changeDate },
                                model: {
                                  value: _vm.queryParams.plan_date,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryParams, "plan_date", $$v)
                                  },
                                  expression: "queryParams.plan_date",
                                },
                              })
                            : _vm._e(),
                          _vm.queryParams.type == 2
                            ? _c(
                                "a-month-picker",
                                {
                                  staticStyle: { width: "100px" },
                                  attrs: {
                                    placeholder: "请选择月份",
                                    "default-value": _vm.moment(
                                      _vm.nowMonth,
                                      _vm.monthFormat
                                    ),
                                    format: _vm.monthFormat,
                                  },
                                  on: { change: _vm.changeDate },
                                  model: {
                                    value: _vm.queryParams.plan_date,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryParams,
                                        "plan_date",
                                        $$v
                                      )
                                    },
                                    expression: "queryParams.plan_date",
                                  },
                                },
                                [
                                  _c("a-icon", {
                                    attrs: {
                                      slot: "suffixIcon",
                                      type: "smile",
                                    },
                                    slot: "suffixIcon",
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.queryParams.type == 1
                            ? _c("a-date-picker", {
                                staticStyle: { width: "100px" },
                                attrs: {
                                  mode: "year",
                                  placeholder: "请输入年份",
                                  "default-value": _vm.moment(
                                    _vm.nowYear,
                                    _vm.yearFormat
                                  ),
                                  format: _vm.yearFormat,
                                  open: _vm.yearShowOne,
                                },
                                on: {
                                  openChange: _vm.openChangeOne,
                                  panelChange: _vm.panelChangeOne,
                                },
                                model: {
                                  value: _vm.queryParams.plan_date,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryParams, "plan_date", $$v)
                                  },
                                  expression: "queryParams.plan_date",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "80px", float: "right" },
                          on: { change: _vm.yhzsTypeChange },
                          model: {
                            value: _vm.queryParams.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "type", $$v)
                            },
                            expression: "queryParams.type",
                          },
                        },
                        [
                          _c("a-select-option", { key: 1 }, [_vm._v(" 年 ")]),
                          _c("a-select-option", { key: 2 }, [_vm._v(" 月 ")]),
                          _c("a-select-option", { key: 3 }, [_vm._v("其他")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", {
                    staticStyle: { width: "98%", height: "398px" },
                    attrs: { id: "yhzsPieChart" },
                  }),
                  _c("a-divider", [_vm._v("隐患分布情况")]),
                  _c(
                    "div",
                    { staticStyle: { width: "98%", height: "260px" } },
                    [
                      _c("a-table", {
                        attrs: {
                          bordered: true,
                          columns: _vm.columns,
                          dataSource: _vm.dataSourceTypeCount,
                          pagination: false,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: 16 } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-card",
                [
                  _c(
                    "a-card",
                    {
                      attrs: {
                        "tab-list": _vm.tabNoTitle,
                        "active-tab-key": _vm.TitleKey,
                      },
                      on: {
                        tabChange: function (key) {
                          return _vm.TabChange(key, "TitleKey")
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: { float: "right", "max-width": "350px" },
                          attrs: { slot: "tabBarExtraContent" },
                          slot: "tabBarExtraContent",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                float: "right",
                                "max-width": "60%",
                              },
                            },
                            [
                              _vm.gcglbQueryParams.queryType == 3
                                ? _c("a-range-picker", {
                                    attrs: {
                                      "default-value": [
                                        _vm.moment(
                                          _vm.firstDay,
                                          _vm.dateFormat
                                        ),
                                        _vm.moment(_vm.nowDay, _vm.dateFormat),
                                      ],
                                      format: _vm.dateFormat,
                                    },
                                    on: { change: _vm.gcglbChangeDate },
                                    model: {
                                      value: _vm.gcglbQueryParams.plan_date,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.gcglbQueryParams,
                                          "plan_date",
                                          $$v
                                        )
                                      },
                                      expression: "gcglbQueryParams.plan_date",
                                    },
                                  })
                                : _vm._e(),
                              _vm.gcglbQueryParams.queryType == 2
                                ? _c(
                                    "a-month-picker",
                                    {
                                      staticStyle: { width: "100px" },
                                      attrs: {
                                        placeholder: "请选择月份",
                                        "default-value": _vm.moment(
                                          _vm.nowMonth,
                                          _vm.monthFormat
                                        ),
                                        format: _vm.monthFormat,
                                      },
                                      on: { change: _vm.gcglbChangeDate },
                                      model: {
                                        value: _vm.gcglbQueryParams.plan_date,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.gcglbQueryParams,
                                            "plan_date",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "gcglbQueryParams.plan_date",
                                      },
                                    },
                                    [
                                      _c("a-icon", {
                                        attrs: {
                                          slot: "suffixIcon",
                                          type: "smile",
                                        },
                                        slot: "suffixIcon",
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.gcglbQueryParams.queryType == 1
                                ? _c("a-date-picker", {
                                    staticStyle: { width: "100px" },
                                    attrs: {
                                      mode: "year",
                                      placeholder: "请输入年份",
                                      "default-value": _vm.moment(
                                        _vm.nowYear,
                                        _vm.yearFormat
                                      ),
                                      format: _vm.yearFormat,
                                      open: _vm.gcglbQueryParams.yearShowOne,
                                    },
                                    on: {
                                      openChange: _vm.gcglbOpenChangeOne,
                                      panelChange: _vm.gcglbPanelChangeOne,
                                    },
                                    model: {
                                      value: _vm.gcglbQueryParams.plan_date,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.gcglbQueryParams,
                                          "plan_date",
                                          $$v
                                        )
                                      },
                                      expression: "gcglbQueryParams.plan_date",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "a-select",
                            {
                              staticStyle: {
                                width: "100px",
                                float: "right",
                                top: "12px",
                              },
                              on: { change: _vm.gcglbTimeChange },
                              model: {
                                value: _vm.gcglbQueryParams.queryType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.gcglbQueryParams,
                                    "queryType",
                                    $$v
                                  )
                                },
                                expression: "gcglbQueryParams.queryType",
                              },
                            },
                            [
                              _c("a-select-option", { key: 1 }, [
                                _vm._v(" 年 "),
                              ]),
                              _c("a-select-option", { key: 2 }, [
                                _vm._v(" 月 "),
                              ]),
                              _c("a-select-option", { key: 3 }, [
                                _vm._v("其他"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm.TitleKey === "office"
                    ? _c(
                        "p",
                        [
                          _c("a-table", {
                            attrs: {
                              bordered: true,
                              columns: _vm.columns1,
                              dataSource: _vm.gcglbDatas,
                              pagination: false,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm.TitleKey === "gcglb"
                    ? _c(
                        "p",
                        [
                          _c("a-table", {
                            attrs: {
                              bordered: true,
                              columns: _vm.columns2,
                              dataSource: _vm.gcglbDatas2,
                              pagination: false,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm.TitleKey === "tgcfd"
                    ? _c(
                        "p",
                        [
                          _c("a-table", {
                            attrs: {
                              bordered: true,
                              columns: _vm.columns3,
                              dataSource: _vm.tgcfDatas,
                              pagination: false,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }