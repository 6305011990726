<template>
  <a-dropdown placement="bottomCenter" :trigger="['click']" v-model="show">
    <div class="header-project" style="cursor: pointer">
<!--      <a-project class="project" size="small" shape="circle" :src="user.project"/>-->
      <span style="width: 150px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;" class="name" @click="showProjectList">{{user.loginType == 0 ? user.loginLaoWuUser.projectName : user.officeLoginData.officeName}}</span>
    </div>
      <div slot="overlay">
          <div class="demo-infinite-container" >
              <a-input-search  v-if="user.loginType==0" placeholder="搜索项目" enter-button="搜索" v-model="searchProject"
                               size="default" @search="onSearch" />
              <a-menu :class="['project-menu']" v-if="user.loginType==0" >
                  <a-menu-item v-for="(item, index) in useProjectList" :key="index" @click="changeAccount(item.id, item.projectId, item.isBindLaoWuUser)" >
                      <div >{{item.projectName}}</div>
                  </a-menu-item>
              </a-menu>
              <a-empty v-if="useProjectList==null || useProjectList ==undefined || useProjectList.length<=0" />
          </div>

      </div>

<!--    <a-menu :class="['project-menu']" slot="overlay" v-if="user.loginType==0" >-->
<!--        <a-input-search placeholder="input search text" style="width: 200px" @search="onSearch" />-->
<!--        <a-menu-item v-for="(item, index) in user.laoWuUserList" :key="index" @click="changeAccount(item.id, item.projectId, item.isBindLaoWuUser)" >-->
<!--            <div >{{item.projectName}}</div>-->
<!--        </a-menu-item>-->
<!--    </a-menu>-->
  </a-dropdown>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import {logout, changeAccount} from '@/services/user'
import {loadRoutes, afterLoginPhone} from '@/utils/routerUtil'
import infiniteScroll from 'vue-infinite-scroll';

export default {
  name: 'HeaderProject',
  directives: { infiniteScroll },
  computed: {
    ...mapGetters('account', ['user']),
    realmType() {
      return this.$store.state.setting.realmType;
    },
  },
    data(){
      return {
        show: false,
          dbProjectList: [],
          useProjectList: [],
          searchProject: '',
      }

    },
    
  methods: {
      ...mapMutations('account', ['setUser', 'setPermissions', 'setRoles']),
    logout() {
      logout()
      this.$router.push('/login')
    },
      showProjectList(){
          this.searchProject= ''
          this.dbProjectList = this.user.laoWuUserList
          this.useProjectList = this.user.laoWuUserList
      },
      changeAccount(userId, projectId, isBindLaoWuUser){
        var that = this
        const system = this.realmType ? 1 : 0
        console.log("changeAccount", userId)
        changeAccount({sysUserId: userId, projectId: projectId, isBindLaoWuUser: isBindLaoWuUser===undefined? false : true, system:system }).then(async (res) => {
          if (res.data.code === 0){
            await afterLoginPhone({ res, pageThis: that})
            location.reload()
          } else{
            this.error = res.data.msg
            this.$message.error(res.data.msg, 3)
          }
        })
      },
      onSearch(value){
          this.searchProject = value
          let projectList = JSON.parse(JSON.stringify(this.dbProjectList))
            this.useProjectList = projectList.filter(item=>{
              return item.projectName.indexOf(value)>-1
          })
      },

  }
}
</script>

<style lang="less">
    .demo-infinite-container {
        border-radius: 4px;
        overflow: auto;
        padding: 8px 24px;
        /*height: 300px;*/
        text-align: center;
        background-color: #fff;
    }
  .header-project{
    display: inline-flex;
    .project, .name{
      align-self: center;
    }
    .project{
      margin-right: 8px;
    }
    .name{
      font-weight: 500;
    }
  }


    .tab-pane{
        padding: 0 24px 12px;
        /*min-height: 250px;*/
    }
  /*.project-menu{*/
  /*  width: 150px;*/
  /*}*/

</style>
