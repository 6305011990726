<template>
  <div>
    <form-index
      ref="formindex"
      :formData="formData"
      flexAction
      @handleSubmit="handleSubmit"
    >
      <!-- 按钮插槽 -->
      <template #flexAction>
        <a-space class="btnBox">
          <a-button type="primary" html-type="submit">查询</a-button>
        </a-space>
      </template>
    </form-index>
    <div class="a_Approval" style="padding: 0px;">
      <!-- 表格封装 -->
      <standard-table
        class=""
        :columns="columns"
        :dataSource="dataSource"
        :selectedRows.sync="selectedRows"
        rowKey="id"
        :scroll="{ x: 1300 }"
        :pagination="pagination"
        :loading="tableLoading"
        @change="onPageChange"
        :isAlert="false"
        :bordered="true"
        @update:selectedRows="updateSelectRows($event)"
        :btnArr="tableBtnArr"
      >
        <template slot="tableTitle">
          <div>
            <span>使用中：{{ facilityStatusStatic.usingNum ? facilityStatusStatic.usingNum : 0 }}</span>
            <span class="ml-2">取证中：{{ facilityStatusStatic.giveNum ? facilityStatusStatic.giveNum : 0 }}</span>
            <span class="ml-2">待安装：{{ facilityStatusStatic.waitNum ? facilityStatusStatic.waitNum : 0 }}</span>
            <span class="ml-2">停用：{{ facilityStatusStatic.stopNum ? facilityStatusStatic.stopNum : 0 }}</span>
          </div>
        </template>
        <template slot="index" slot-scope="{ index }">
          <span>
            {{
              (pagination.current - 1) * pagination.pageSize +
              parseInt(index) +
              1
            }}
          </span>
        </template>
        <template slot="usingEndTime" slot-scope="{ record }">
          <span :style="dateColor(record.usingEndTime)">{{ useRender.renderDate(record.usingEndTime) }}</span>
        </template>
        <template slot="action" slot-scope="{ record }">
          <a-space>
            <div v-if="params.facilityType == 1 || params.facilityType == 2" class="pointer" style="color: #307dfa;" @click="onMonitoring(record)">监测</div>
            <div class="pointer" style="color: #307dfa;" @click="visit(record)">查看</div>
            <div class="pointer" style="color: #ff5c77;" @click="del(record)">删除</div>
          </a-space>
        </template>
      </standard-table>
      <Export-loading
        :cPercent="cPercent"
        :isExporting="isExporting"
        :loadingText="loadingText"
      ></Export-loading>
    </div>
    <a-modal v-model="teamDialogVisible" title="" @ok="handleBreak" :destroyOnClose="true">
      <div class="flex a-center w-100 j-center">
        <form-index
          ref="breakFormindex"
          :formData="breakFormData"
        >
        </form-index>
        <!-- <a-date-picker @change="onChange" placeholder="请选择拆除时间"/> -->
      </div>
    </a-modal>
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import { QUERY_OFFICE_BY_USER, EXPORT_FACILITY } from "@/services/api";
import { request } from "@/utils/request";
import { getFacilityInfoList, delFacility, breakFacilityInfo, getFacilityStatusStatic } from "@/services/equipmentLedger";
import { exportProgress } from "@/pages/aqjyzl/export"; // 导出方法
import ExportLoading from "@/components/ExportLoading/ExportLoading"; // progress组件
import moment from "moment";
import { useRender } from '@/hooks/useRender.js'

const breakFormData = [
  {
    label: "拆卸合同编号",
    placeholder: "请输入",
    decorator: [
      "splitContractNum",
      { rules: [{ required: false, message: "请输入" }] },
    ],
    type: "input",
    key: "splitContractNum",
    col: 20,
    labelCol: 10,
    wrapperCol: 16,
  },
  // {
  //   label: "拆卸安全管理协议",
  //   placeholder: "请输入",
  //   decorator: [
  //     "splitSafeProtocal",
  //     { rules: [{ required: false, message: "请输入" }] },
  //   ],
  //   type: "input",
  //   key: "splitSafeProtocal",
  //   col: 20,
  //   labelCol: 10,
  //   wrapperCol: 16,
  // },
  {
    label: "拆除时间",
    placeholder: "请选择",
    decorator: [
      "breakTime",
      { rules: [{ required: true, message: "请选择" }] },
    ],
    type: "datePicker",
    format: "yyyy-MM-DD",
    key: "breakTime",
    dataSource: [],
    labelCol: 10,
    wrapperCol: 16,
    col: 20,
    display: true,
    styles: {width: '100%'}
  },
];
const formData = [
  {
    label: "工程名称",
    placeholder: "请选择工程名称",
    decorator: [
      "projectName",
      { rules: [{ required: false, message: "请选择工程名称" }] },
    ],
    type: "input",
    key: "projectName",
    col: 5,
    labelCol: 8,
    wrapperCol: 14,
  },
  {
    label: '工程管理部',
    placeholder: '请选择工程管理部',
    decorator: ['gcglbId', { rules: [{ required: false, message: '请选择工程管理部' }] }],
    type: 'select',
    key: 'gcglbId',
    selectlist: [],
    qb:true,
    col:5,
    labelCol: 8,
    wrapperCol:14,
  },
  {
    label: "产权登记编号",
    placeholder: "请选择产权登记编号",
    decorator: [
      "equityNum",
      { rules: [{ required: false, message: "请选择产权登记编号" }] },
    ],
    type: "input",
    key: "equityNum",
    col: 6,
    labelCol: 10,
    wrapperCol: 14,
  },
  {
    label: "状态",
    placeholder: "请选择状态",
    decorator: [
      "status",
      { rules: [{ required: false, message: "请选择状态" }] },
    ],
    type: "select",
    key: "status",
    col: 5,
    labelCol: 7,
    wrapperCol: 14,
    selectlist: [
      {
        value: 0,
        name: "使用中",
      },
      {
        value: 1,
        name: "取证中",
      },
      {
        value: 2,
        name: "停用",
      },
      {
        value: 3,
        name: "待安装",
      },
    ],
  },
  {
    type: 'action',
    col: 2
  }
];
const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
  },
  {
    title: "项目名称",
    dataIndex: "projectName",
    width: 160,
    ellipsis: true,
    tooltip: true
  },

  {
    title: "所属单位",
    dataIndex: "gcglbName",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "gcglbName" },
  },
  {
    title: "设备型号",
    align: "center",
    width: 100,
    dataIndex: "facilityNum",
    ellipsis: true,
    tooltip: true
  },
  {
    title: "自编号",
    dataIndex: "selfNum",
    width: 80,
    align: "center",
    ellipsis: true,
    scopedSlots: { customRender: "selfNum" },
  },
  {
    title: "产权登记编号",
    dataIndex: "equityNum",
    align: "center",
    width: 130,
    scopedSlots: { customRender: "equityNum" },
  },
  {
    title: "检测单位名称",
    dataIndex: "checkUnit",
    align: "center",
    width: 130,
    ellipsis: true,
    tooltip: true,
    key: "checkUnit",
  },
  {
    title: "设备检验报告编号",
    dataIndex: "checkReportNum",
    align: "center",
    width: 160,
    ellipsis: true,
    tooltip: true,
    key: "checkReportNum",
  },
  {
    title: "最新检验日期",
    dataIndex: "checkDate",
    align: "center",
    width: 130,
    key: "checkDate",
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "使用登记证编号",
    dataIndex: "usingNum",
    align: "center",
    width: 140,
    ellipsis: true,
    tooltip: true,
    key: "usingNum",
  },
  {
    title: "状态",
    dataIndex: "statusString",
    align: "center",
    width: 85,
    key: "statusString",
  },
  {
    title: "登记证有效截止日期",
    dataIndex: "usingEndTime",
    align: "center",
    width: 120,
    key: "usingEndTime",
    scopedSlots: { customRender: "usingEndTime" },
  },
  {
    title: "数据来源",
    dataIndex: "sourceType",
    align: "center",
    width: 100,
    key: "sourceType",
    customRender: (text) => {
      if (text == 0) {
        return '自维护'
      } else if (text == 1) {
        return '政企平台'
      } else {
        return '/'
      }
    }
  },
  {
    title: "操作",
    align: "center",
    width: 140,
    fixed: "right",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  mixins: [exportProgress], //导出方法注入
  components: {
    formIndex,
    StandardTable,
    ExportLoading, //导出数据加载封装
  },
  data() {
    return {
      formData: formData,
      columns: columns,
      dataSource: [], //table数组
      selectedRows: [], //选中的数据
      pagination: {
        //分页数据
        current: 1,
        pageSize: 10,
        total: 0,
      },
      params: {
        //测试数据
        pageNumber: 1,
        pageSize: 10,
        isSplit: 0,
        facilityType: 1 //设备类别：1：塔式起重机，2：施工升降机，3：物料提升机
      },
      tableLoading: false, //table加载中
      loadingText: "数据加载中...",
      cPercent: 0,
      isExporting: false,
      teamDialogVisible: false,
      breakFormData: breakFormData,
      facilityStatusStatic: {},
      tableBtnArr: [
        {
            name: '新增',
            attrs: {
                type: 'primary'
            },
            click: this.addNewEquipment
        },
        {
            name: '拆除设备',
            click: this.breakFacility
        },
        {
            name: '导出',
            click: this.doDowload
        }
      ],
      useRender
    };
  },
  watch: {
    loadingText: {
      handler(newName, oldName) {
        this.loadingText = newName;
      },
      immediate: true,
    },
  },
  activated() {
    this.getgcglbList(QUERY_OFFICE_BY_USER);
    switch(this.$route.name) {
      case '塔式起重机': {
        if (this.breakFormData.some(item => item.key === 'splitSafeProtocal')) {
          this.breakFormData.splice(this.breakFormData.findIndex(item => item.key === 'splitSafeProtocal'), 1)
        }
        this.params.facilityType = 1
        break
      }
      case '施工升降机': {
        if (!this.breakFormData.some(item => item.key === 'instaliSafeProtocal')) {
          this.breakFormData.splice(this.breakFormData.length - 1, 0, {
            label: "拆卸安全管理协议",
            placeholder: "请输入",
            decorator: [
              "splitSafeProtocal",
              { rules: [{ required: false, message: "请输入" }] },
            ],
            type: "input",
            key: "splitSafeProtocal",
            col: 20,
            labelCol: 10,
            wrapperCol: 16,
          },)
        }
        this.params.facilityType = 2
        break
      }
      case '物料提升机': {
        if (this.breakFormData.some(item => item.key === 'splitSafeProtocal')) {
          this.breakFormData.splice(this.breakFormData.findIndex(item => item.key === 'splitSafeProtocal'), 1)
        }
        this.params.facilityType = 3
        break
      }
      case '门式起重机': {
        if (this.breakFormData.some(item => item.key === 'splitSafeProtocal')) {
          this.breakFormData.splice(this.breakFormData.findIndex(item => item.key === 'splitSafeProtocal'), 1)
        }
        this.params.facilityType = 4
        break
      }
      case '桥式起重机': {
        if (this.breakFormData.some(item => item.key === 'splitSafeProtocal')) {
          this.breakFormData.splice(this.breakFormData.findIndex(item => item.key === 'splitSafeProtocal'), 1)
        }
        this.params.facilityType = 5
        break
      }
    }
    this.aqxjlist(this.params);
    this.getFacilityStatusStatic(this.params)
  },
  methods: {
    onMonitoring(record) {
      let name = ''
      switch(this.params.facilityType) {
        case 1: {
          name = "塔式起重机实时监测"
          break
        }
        case 2: {
          name = "施工升降机实时监测"
          break
        }
      }
      this.$router.push({
        name,
        query: {
          facilityType: this.params.facilityType,
          factoryNum: record.factoryNum,
          projectName: record.projectName,
          facilityNum: record.facilityNum,
          projectId: record.projectId
        },
      });
    },
    dateColor(date) {
      if (moment(new Date()).diff(moment(date),'days') > -30) {
        return 'color: red'
      }
    },
    breakFacility() {
      if (this.selectedRows.length < 1) {
        this.$message.warning('请选择设备')
      } else {
        this.teamDialogVisible = true
      }
    },
    handleBreak() {
      let arr = []
      this.selectedRows.forEach(el => {
        arr.push(el.id)
      })
      let obj = {
        ids: arr
      }
      breakFacilityInfo(Object.assign(obj, this.$refs.breakFormindex.getFieldValue())).then(res => {
        if (res.data.code === 0) {
          this.$message.success('拆除成功！')
          this.teamDialogVisible = false
          this.aqxjlist(this.params);
        } else {
          this.$message.error('拆除失败！')
          this.teamDialogVisible = false
        }
      })
    },
    /**
     * 导出excel
     */
     doDowload() {
      let obj = this.$refs["formindex"].getFieldValue();
      obj.facilityType = this.params.facilityType;
      obj.isSplit = this.params.isSplit;
      this.loadingText = "正在导出EXCEL文件，请稍候 ····";
      this.isExporting = true;
      this.cPercent = 0;
      let fileName = null
      switch(this.params.facilityType) {
        case 1: {
          fileName = '塔式起重机.xlsx'
          break
        }
        case 2: {
          fileName = '施工升降机.xlsx'
          break
        }
        case 3: {
          fileName = '物料提升机.xlsx'
          break
        }
        case 4: {
          fileName = '门式起重机.xlsx'
          break
        }
        case 5: {
          fileName = '桥式起重机.xlsx'
          break
        }
      }
      this.exportFunNomal2(
        EXPORT_FACILITY,
        obj,
        fileName,
        "application/vnd.ms-excel"
      );
    },
    // 获取工程管理部数据
    getgcglbList(path) {
      request(path, "get").then((res) => {
        let data = res.data.data.filter(
          (item) => item.id != "3da0f5533f87425c9a9e8a11a7923300"
        );
        this.formDataFn("gcglbId", data);
      });
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      let arr = [];
      data.forEach((item) => {
        arr.push({ name: item.master, value: item.id });
      });
      this.formData.forEach((item) => {
        if (item.key == key) {
          item.selectlist = arr;
        }
      });
    },
    getFacilityStatusStatic(params) {
      getFacilityStatusStatic(params).then((res) => {
        if (res.data.code === 0) {
          this.facilityStatusStatic = res.data.data || {}
        } else {
          this.$message.error(res.data.msg)
        }
        this.tableLoading = false;
      });
    },
    aqxjlist(params) {
      this.tableLoading = true;
      getFacilityInfoList(params).then((res) => {
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
        } else {
          this.$message.error(res.data.msg)
        }
        this.tableLoading = false;
      });
    },
    addNewEquipment() {
      let name = ''
      switch(this.params.facilityType) {
        case 1: {
           name = "新增塔式起重机"
          break
        }
        case 2: {
          name = "新增施工升降机"
          break
        }
        case 3: {
          name = "新增物料提升机"
          break
        }
        case 4: {
          name = "新增门式起重机"
          break
        }
        case 5: {
          name = "新增桥式起重机"
          break
        }
      }
      this.$router.push({
        name,
        query: {
          facilityType: this.params.facilityType
        },
      });
    },
    handleSubmit(e) {
      this.params.pageNumber = 1
      this.pagination.current = 1;
      let obj = Object.assign({}, e, this.params);
      this.aqxjlist(obj);
      this.getFacilityStatusStatic(obj)
    },
    //表格 - 分页查询
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      this.params.pageNumber = e.current;
      this.pagination.current = e.current;
      this.aqxjlist(Object.assign({}, this.params, obj));
    },
    //查看
    visit(record) {
      let name = ''
      switch(this.params.facilityType) {
        case 1: {
           name = "塔式起重机详情"
          break
        }
        case 2: {
          name = "施工升降机详情"
          break
        }
        case 3: {
          name = "物料提升机详情"
          break
        }
        case 4: {
          name = "门式起重机详情"
          break
        }
        case 5: {
          name = "桥式起重机详情"
          break
        }
      }
      this.$router.push({
        name,
        query: {
          id: record.id,
          facilityType: this.params.facilityType
        },
      });
    },
    del(record) {
      const _that = this
      this.$confirm({
          title: '是否确定删除？',
          cancelText: '否',
          okText: '是',
          onOk: () => {
            delFacility(record.id).then(res => {
              if (res.data.code === 0) {
                _that.$message.success('删除成功')
                this.aqxjlist(this.params);
              } else {
                _that.$message.error(res.data.msg)
              }
            })
          },
        })
    },
    updateSelectRows(e) {
      console.log(e);
    },
  },
};
</script>

<style lang="less" scoped>
.a_a_footer {
  margin-top: 16px;
  background: #ffffff;

  .sTables {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      width: 100%;
      text-align: center;
      cursor: pointer;
    }

    div:nth-child(1) {
      color: #307dfa;
    }

    div:nth-child(2) {
      color: #ff5c77;
    }

    div:nth-child(3) {
      color: #615e83;
    }
  }
}
.content_div {
  padding: 5px 0;
  cursor: pointer;
}
.content_div:hover {
  color: #307dfa;
}
</style>
