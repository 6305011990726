var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap-cantainer ant-card ant-card-bordered ant-card-body" },
    [
      _c(
        "a-row",
        { attrs: { gutter: 16 } },
        [
          _c("a-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "relative" },
              [
                _c(
                  "baidu-map",
                  {
                    staticClass: "bmView",
                    attrs: {
                      "scroll-wheel-zoom": true,
                      center: _vm.location,
                      zoom: _vm.zoom,
                    },
                  },
                  [
                    _c("bm-view", {
                      staticStyle: {
                        width: "100%",
                        height: "500px",
                        flex: "1",
                      },
                    }),
                    _c("bm-local-search", {
                      staticStyle: { display: "none" },
                      attrs: {
                        keyword: _vm.addressKeyword,
                        "auto-viewport": true,
                      },
                    }),
                    _vm._l(_vm.persons, function (items, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          _c(
                            "bm-marker",
                            {
                              attrs: {
                                position: { lng: items.lng, lat: items.lat },
                                icon: {
                                  url: items.icon,
                                  size: { width: 28, height: 28 },
                                },
                                dragging: false,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.infoWindowOpen(index)
                                },
                              },
                            },
                            [
                              _c(
                                "bm-info-window",
                                {
                                  attrs: { show: items.show },
                                  on: {
                                    close: function ($event) {
                                      return _vm.infoWindowClose(index)
                                    },
                                    open: function ($event) {
                                      return _vm.infoWindowOpen(index)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(items.name))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "unit-box" },
                  [
                    _c("label", [_vm._v("单位：")]),
                    _c(
                      "a-select",
                      {
                        staticStyle: { width: "160px" },
                        attrs: { "default-value": _vm.defaultUnit },
                        on: { change: _vm.onHandleUnitChange },
                        model: {
                          value: _vm.defaultUnit,
                          callback: function ($$v) {
                            _vm.defaultUnit = $$v
                          },
                          expression: "defaultUnit",
                        },
                      },
                      _vm._l(_vm.unitList, function (items, id) {
                        return _c(
                          "a-select-option",
                          { key: id, attrs: { value: items.id } },
                          [_vm._v(" " + _vm._s(items.name) + " ")]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "gc-box flex" }, [
                  _c(
                    "div",
                    { staticClass: "text-center flex-1 gc-box-item " },
                    [
                      _c(
                        "div",
                        { staticClass: "flex a-center j-center border-right" },
                        [
                          _c("img", {
                            staticClass: "gc-box-img",
                            attrs: { src: _vm.local1 },
                          }),
                          _vm._v(
                            "在建工程" + _vm._s(_vm.gcObj.zaijian || 0) + "个"
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "text-center flex-1 gc-box-item" }, [
                    _c(
                      "div",
                      { staticClass: "flex a-center j-center border-right" },
                      [
                        _c("img", {
                          staticClass: "gc-box-img",
                          attrs: { src: _vm.local2 },
                        }),
                        _vm._v(
                          " 暂停工程" + _vm._s(_vm.gcObj.tinggong || 0) + "个 "
                        ),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "text-center flex-1 gc-box-item" }, [
                    _c("div", { staticClass: "flex a-center j-center" }, [
                      _c("img", {
                        staticClass: "gc-box-img",
                        attrs: { src: _vm.local3 },
                      }),
                      _vm._v(
                        " 完工工程" + _vm._s(_vm.gcObj.wangong || 0) + "个 "
                      ),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mt-2" },
              [
                _c("a-table", {
                  attrs: {
                    columns: _vm.projectColumns,
                    "data-source": _vm.projectData,
                    bordered: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "projectName",
                      fn: function (text, record) {
                        return _c("a", {}, [_vm._v(_vm._s(record.projectName))])
                      },
                    },
                    {
                      key: "xmjl",
                      fn: function (text, record) {
                        return _c("div", {}, [
                          _vm._v(_vm._s(record.xmjl) + _vm._s(record.phone)),
                        ])
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
          _c("a-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "data-wrap" },
              [
                _c(
                  "a-row",
                  { attrs: { gutter: 16 } },
                  [
                    _c("a-col", { attrs: { span: 14 } }, [
                      _c("div", {
                        staticStyle: { width: "100%", height: "200px" },
                        attrs: { id: "safeChart" },
                      }),
                    ]),
                    _c("a-col", { attrs: { span: 10 } }, [
                      _c("div", { staticStyle: { "padding-left": "40px" } }, [
                        _c("div", { staticClass: "mb-1" }, [
                          _vm._v("隐患总数：" + _vm._s(_vm.currentGk.yhs)),
                        ]),
                        _c("div", { staticClass: "mb-1" }, [
                          _vm._v("整改率：" + _vm._s(_vm.currentGk.zgl || 0)),
                        ]),
                        _c("div", [
                          _vm._v(
                            "超时未整改：" + _vm._s(_vm.currentGk.cswzg || 0)
                          ),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c("div", {
                  staticStyle: { width: "100%", height: "300px" },
                  attrs: { id: "dangerTypeChart" },
                }),
                _c(
                  "div",
                  {
                    staticClass: "flex a-center",
                    staticStyle: { "line-height": "32px" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "16px",
                          "font-weight": "600",
                          "margin-right": "20px",
                          color: "#333",
                          "font-style": "normal",
                        },
                      },
                      [_vm._v("劳务数据汇总")]
                    ),
                    _c(
                      "div",
                      { staticClass: "flex-1" },
                      [
                        _c("label", [_vm._v("选择项目：")]),
                        _c(
                          "a-select",
                          {
                            staticStyle: { width: "220px" },
                            attrs: { "default-value": _vm.defaultProject },
                            on: { change: _vm.onHandleChange },
                            model: {
                              value: _vm.defaultProject,
                              callback: function ($$v) {
                                _vm.defaultProject = $$v
                              },
                              expression: "defaultProject",
                            },
                          },
                          _vm._l(_vm.projectList, function (items, projId) {
                            return _c(
                              "a-select-option",
                              { key: projId, attrs: { value: items.projId } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(items.shortName || items.fullName) +
                                    " "
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "mt-2" },
                  [
                    _c(
                      "a-row",
                      { attrs: { gutter: 15 } },
                      [
                        _c("a-col", { attrs: { span: 12 } }, [
                          _c("div", { staticClass: "laowu-data-box orange" }, [
                            _c("h5", { staticClass: "p-1" }, [_vm._v("工人")]),
                            _c("div", { staticClass: "px-1" }, [
                              _c("div", { staticClass: "flex mb-1" }, [
                                _c("label", { staticClass: "flex-1" }, [
                                  _vm._v("今日"),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "flex-1 text-right" },
                                  [_vm._v(_vm._s(_vm.workData.today))]
                                ),
                              ]),
                              _c("div", { staticClass: "flex mb-1" }, [
                                _c("label", { staticClass: "flex-1" }, [
                                  _vm._v("昨日"),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "flex-1 text-right" },
                                  [_vm._v(_vm._s(_vm.workData.yesterday))]
                                ),
                              ]),
                              _c("div", { staticClass: "flex" }, [
                                _c("label", { staticClass: "flex-1" }, [
                                  _vm._v("已离场"),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "flex-1 text-right" },
                                  [_vm._v(_vm._s(_vm.workData.leave))]
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                        _c("a-col", { attrs: { span: 12 } }, [
                          _c("div", { staticClass: "laowu-data-box blue" }, [
                            _c("h5", { staticClass: "p-1" }, [
                              _vm._v("管理人员"),
                            ]),
                            _c("div", { staticClass: "px-1" }, [
                              _c("div", { staticClass: "flex mb-1" }, [
                                _c("label", { staticClass: "flex-1" }, [
                                  _vm._v("建设单位"),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "flex-1 text-right" },
                                  [_vm._v(_vm._s(_vm.ManagCWA["2"]))]
                                ),
                              ]),
                              _c("div", { staticClass: "flex mb-1" }, [
                                _c("label", { staticClass: "flex-1" }, [
                                  _vm._v("施工单位"),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "flex-1 text-right" },
                                  [_vm._v(_vm._s(_vm.ManagCWA["1"]))]
                                ),
                              ]),
                              _c("div", { staticClass: "flex" }, [
                                _c("label", { staticClass: "flex-1" }, [
                                  _vm._v("监理单位"),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "flex-1 text-right" },
                                  [_vm._v(_vm._s(_vm.ManagCWA["3"]))]
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mt-2" },
                  [
                    _c(
                      "a-row",
                      { attrs: { gutter: 15 } },
                      [
                        _c("a-col", { attrs: { span: 12 } }, [
                          _c("div", {
                            staticStyle: { width: "100%", height: "300px" },
                            attrs: { id: "ageTypeChart" },
                          }),
                        ]),
                        _c("a-col", { attrs: { span: 12 } }, [
                          _c("div", {
                            staticStyle: { width: "100%", height: "300px" },
                            attrs: { id: "workTypeChart" },
                          }),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "mt-2" }, [
                  _c("div", {
                    staticStyle: { width: "100%", height: "300px" },
                    attrs: { id: "sevenKqChart" },
                  }),
                ]),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }