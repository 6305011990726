var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    [
      _c(
        "a-drawer",
        {
          attrs: {
            width: 1224,
            visible: _vm.visible,
            title: "签署人位置配置",
            "body-style": { paddingBottom: "80px" },
          },
          on: { close: _vm.onClose },
        },
        [
          _c(
            "a-form",
            {
              attrs: { form: _vm.form, layout: "horizontal" },
              on: { submit: _vm.doSubmit },
            },
            _vm._l(_vm.dataSource, function (item, index) {
              return _c(
                "a-row",
                { key: index, attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 3 } },
                    [
                      _c(
                        "a-form-item",
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                "default-value": "PERSON",
                                placeholder: "签署类型",
                              },
                              model: {
                                value: item.approverType,
                                callback: function ($$v) {
                                  _vm.$set(item, "approverType", $$v)
                                },
                                expression: "item.approverType",
                              },
                            },
                            [
                              _c(
                                "a-select-option",
                                { attrs: { value: "ORGANIZATION" } },
                                [_vm._v(" ORGANIZATION ")]
                              ),
                              _c(
                                "a-select-option",
                                { attrs: { value: "WORKSEND" } },
                                [_vm._v(" WORKSEND ")]
                              ),
                              _c(
                                "a-select-option",
                                { attrs: { value: "PERSON" } },
                                [_vm._v(" PERSON ")]
                              ),
                              _c(
                                "a-select-option",
                                { attrs: { value: "TEAMLEADER" } },
                                [_vm._v(" TEAMLEADER ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "a-form-item",
                        [
                          _c("a-input", {
                            attrs: { placeholder: "recipientId" },
                            model: {
                              value: item.recipientId,
                              callback: function ($$v) {
                                _vm.$set(item, "recipientId", $$v)
                              },
                              expression: "item.recipientId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "a-form-item",
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                disabled: _vm.dis(item, 1),
                                placeholder: "签署角色",
                              },
                              on: { change: _vm.changeRole },
                              model: {
                                value: item.roleId,
                                callback: function ($$v) {
                                  _vm.$set(item, "roleId", $$v)
                                },
                                expression: "item.roleId",
                              },
                            },
                            _vm._l(_vm.roleList, function (role, index) {
                              return _c(
                                "a-select-option",
                                { key: index, attrs: { value: role.roleId } },
                                [_vm._v(" " + _vm._s(role.roleName) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 3 } },
                    [
                      _c(
                        "a-form-item",
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                disabled: _vm.dis(item, 1),
                                placeholder: "签署人",
                                allowClear: true,
                              },
                              on: {
                                change: function (e) {
                                  return _vm.userChange(e, item)
                                },
                              },
                              model: {
                                value: item.userId,
                                callback: function ($$v) {
                                  _vm.$set(item, "userId", $$v)
                                },
                                expression: "item.userId",
                              },
                            },
                            _vm._l(_vm.userList, function (user, index) {
                              return _c(
                                "a-select-option",
                                {
                                  key: index,
                                  attrs: { value: user.sysUserId },
                                },
                                [_vm._v(" " + _vm._s(user.name) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 3 } },
                    [
                      _c(
                        "a-form-item",
                        [
                          _c("a-input", {
                            attrs: {
                              disabled: _vm.dis(item, 1),
                              placeholder: "手机号",
                            },
                            model: {
                              value: item.mobile,
                              callback: function ($$v) {
                                _vm.$set(item, "mobile", $$v)
                              },
                              expression: "item.mobile",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "a-form-item",
                        [
                          _c("a-input", {
                            attrs: {
                              disabled: _vm.dis(item, 1),
                              placeholder: "身份证号",
                            },
                            model: {
                              value: item.idCard,
                              callback: function ($$v) {
                                _vm.$set(item, "idCard", $$v)
                              },
                              expression: "item.idCard",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
          _c(
            "div",
            [
              _c(
                "a-button",
                {
                  style: { marginLeft: "8px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.doMsgTempl },
                },
                [_vm._v(" 获取电子签模板信息 ")]
              ),
              _c("span", [_vm._v("(ORGANIZATION,WORKSEND,PERSON)")]),
              _c("span", [_vm._v(_vm._s(_vm.templMsg))]),
            ],
            1
          ),
          _c(
            "div",
            {
              style: {
                position: "absolute",
                right: 0,
                bottom: 0,
                width: "100%",
                borderTop: "1px solid #e9e9e9",
                padding: "10px 16px",
                background: "#fff",
                textAlign: "right",
                zIndex: 1,
              },
            },
            [
              _c(
                "a-button",
                {
                  style: { marginLeft: "8px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.doSubmit },
                },
                [_vm._v(" 确定 ")]
              ),
              _c(
                "a-button",
                { style: { marginLeft: "8px" }, on: { click: _vm.onClose } },
                [_vm._v(" 关闭 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }