var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "committee" },
    [
      _c(
        "div",
        { staticClass: "a_a_header mt-1" },
        [
          _c("form-index", {
            ref: "formindex",
            attrs: { formData: _vm.formData, flexAction: true },
            on: {
              handleSubmit: _vm.handleSubmit,
              handlSelect: _vm.handlSelect,
            },
            scopedSlots: _vm._u([
              {
                key: "flexAction",
                fn: function () {
                  return [
                    _c(
                      "a-space",
                      { style: { marginTop: "4px" } },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: {
                              type: "primary",
                              icon: "search",
                              "html-type": "submit",
                            },
                          },
                          [_vm._v(" 查询 ")]
                        ),
                        _c(
                          "a-space",
                          { staticClass: "operator" },
                          [
                            _c("a-button", { on: { click: _vm.close } }, [
                              _vm._v(" 重置 "),
                            ]),
                          ],
                          1
                        ),
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("注：处理预警请去“建工管理小程序”"),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _vm._m(0),
      _c("standard-table", {
        attrs: {
          columns: _vm.columns,
          dataSource: _vm.dataSource,
          rowKey: "id",
          pagination: _vm.pagination,
          loading: _vm.tableLoading,
          isAlert: false,
          bordered: false,
          tableSize: "middle",
        },
        on: { change: _vm.onPageChange },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function (ref) {
              var index = ref.index
              return [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        (_vm.pagination.current - 1) * _vm.pagination.pageSize +
                          parseInt(index) +
                          1
                      ) +
                      " "
                  ),
                ]),
              ]
            },
          },
          {
            key: "bizType",
            fn: function (ref) {
              var text = ref.text
              return [
                _vm._v(" " + _vm._s(text == 1 ? "实名制" : "工资分账") + " "),
              ]
            },
          },
          {
            key: "eventName",
            fn: function (ref) {
              var text = ref.text
              return [_vm._v(" " + _vm._s(text) + " ")]
            },
          },
          {
            key: "warmCode",
            fn: function (ref) {
              var text = ref.text
              var record = ref.record
              return [
                _c(
                  "div",
                  {
                    staticClass: "statusDrop flex a-center",
                    class: _vm.textStyle(record),
                  },
                  [_vm._v(" " + _vm._s(text) + " ")]
                ),
              ]
            },
          },
          {
            key: "status",
            fn: function (ref) {
              var text = ref.text
              return [_vm._v(" " + _vm._s(_vm._f("statusStr")(text)) + " ")]
            },
          },
          {
            key: "action",
            fn: function (ref) {
              var record = ref.record
              return _c(
                "div",
                {},
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "link" },
                      on: {
                        click: function ($event) {
                          return _vm.toHandle(record)
                        },
                      },
                    },
                    [_vm._v("去处理")]
                  ),
                ],
                1
              )
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex a-center" }, [
      _c("div", [_vm._v("处理时限说明:")]),
      _c("div", { staticClass: "status-box flex" }, [
        _c("div", { staticClass: "statusDrop" }, [_vm._v("剩余1天")]),
        _c("div", { staticClass: "statusDrop before-second" }, [
          _vm._v("剩余2-3天"),
        ]),
        _c("div", { staticClass: "statusDrop before-third" }, [
          _vm._v("剩余>3天"),
        ]),
        _c("div", { staticClass: "statusDrop before-fourth" }, [
          _vm._v("已超时"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }