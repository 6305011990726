<template>
    <div>
      <a-card :title="title" class="a_Approval" :bordered="false">
        <a-form-model ref="ruleForm" :model="params" :rules="rules">
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="图册名称" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="picname">
                <a-input placeholder="请输入图册名称" v-model="params.picname" />
              </a-form-model-item>
            </a-col>
           
            <a-col :span="12">
              <a-form-model-item label="更新日期" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="pubdate">
                <a-date-picker v-model="params.pubdate" style="width: 100%" allowClear mode="date"
                  :format="'YYYY-MM-DD'" :valueFormat="'YYYY-MM-DD'"/>
              </a-form-model-item>
            </a-col>
           
            <a-col :span="12">
              <a-form-model-item label="备注" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="remark">
                <a-input placeholder="请输入备注" v-model="params.remark" />
              </a-form-model-item>
            </a-col>
  
            <a-col :span="12">
              <a-form-model-item label="上传附件" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="year">
                <a-upload
                  class="avatar-uploader"
                  list-type="picture-card"
                  :accept="accept"
                  :file-list="filePath"
                  @change="handleUploadChange"
                  :customRequest="resourceUpload"
                  @preview="handlePreview"
                  :beforeUpload="beforeUpload"
                  multiple
                >
                  <!-- <div v-for="(item, index) in filePath" :key="index">
                    <img
                      class="w-100"
                      v-if="item && item.name.indexOf('pdf') == -1"
                      :src="item"
                      alt="avatar"
                    />
                    <a-icon v-else-if="item && item.name.indexOf('pdf') > -1" type="file-pdf" :style="{ fontSize: '64px' }" />
                  </div> -->
  
                  <div v-if="filePath.length < 10">
                    <a-icon :type="uploading ? 'loading' : 'plus'" />
                    <div class="ant-upload-text">上传附件</div>
                  </div>
                </a-upload>
              </a-form-model-item>
            </a-col>
  
  
          </a-row>
        </a-form-model>
        <a-row>
          <a-col :span="12"></a-col>
          <a-col :span="12">
            <a-form-model-item :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }" prop="year">
              <div class="flex flex-row-reverse">
                <a-button type="primary" @click="handleSubmit">保存</a-button>
                <a-button class="mr-2" @click="close">返回</a-button>
              </div>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-card>
    </div>
  </template>
  
  <script>
  
  import { request } from "@/utils/request";
  import { mapGetters } from 'vuex'
  import moment from "moment";
  import { safestapicAdd,safestapicUpdate, safestapicByID } from "@/services/standardizedAtlas";
  import { ATTACHMENT_UPLOAD } from "@/services/api";
  
  export default {
    inject: ["fatherMethod"],
    computed: {
      ...mapGetters('account', ['user']),
    },
    data() {
      return {
        isEdit: 0,
        rules: {
          picname: [{ required: true, message: '请输入名称', trigger: 'change' }],
          pubdate: [{ required: true, message: '请选择发布日期', trigger: 'change' }],
          // remark: [{ required: true, message: '请选择', trigger: 'change' }],
          // attach: [{ required: true, message: '请选择', trigger: 'change' }],
        },
  
        params: {
          "picname": "",
          "pubdate": "",
          "remark": "",
          "attach": ""
        },
        // accept:".png,.jpg,.jpeg,.pdf,.PDF",
        accept:"",
        filePath: [],
        uploading: false,
        title: '新增标准化图册',
      };
    },
    activated() {
      this.isEdit = Number(this.$route.query.isEdit);
      if (this.isEdit === 0) {
        // this.params.creator = this.user.id
        this.title = '新增标准化图册';
      } else {
        // this.params.updator = this.user.id
        this.title = '编辑标准化图册';
        this.params.safestapicid = this.$route.query.id;
        this.getDetail()
      }
    },
    methods: {
      moment,
      getDetail() {
        safestapicByID({id: this.params.safestapicid}).then(res => {
          if (res.data.code === 200) {
            this.params = res.data.data;
            let attachList = this.params.attach.split(',');
            if (this.params.attach == '') {
              attachList = []
            }
            let fileNames = [];
            if (this.params.createuser) {
              fileNames = this.params.createuser.split(',');
            }
            this.filePath = attachList.map((item, index) => {
              return {
                uid: index,
                url: item,
                name: fileNames[index],
                status: 'done',
              }
            })
           
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
  
      handleSubmit() {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            if (this.isEdit == 0) {
              let attach = this.filePath.map(item => item.url).join(',');
              let fileNames = this.filePath.map(item => item.name).join(',');

              let d = {
                ...this.params,
                createuser: fileNames
              }
              d.attach = attach;
              safestapicAdd(d).then(res => {
                if (res.data.code === 200) {
                  this.$message.success('提交成功！')
                  this.close();
                  this.$router.push({
                    name: "标准化图册",
                  });
                } else {
                  this.$message.error(res.data.msg)
                }
              })
            } else {
              let attach = this.filePath.map(item => item.url).join(',');
              let fileNames = this.filePath.map(item => item.name).join(',');

              this.params.attach = attach;
              this.params.createuser = fileNames;
              safestapicUpdate(this.params).then(res => {
                if (res.data.code === 200) {
                  this.$message.success('修改成功！')
                  this.close();
                  this.$router.push({
                    name: "标准化图册",
                  });
                } else {
                  this.$message.error(res.data.msg)
                }
              })
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      close() {
        let path = this.$route.path;
        this.fatherMethod(`${path}`);
        this.$router.push({
          name: "标准化图册",
        });
      },
      beforeUpload(file){
        // const accept = '.' + file.name.substring(file.name.lastIndexOf(".") + 1);
        // if (this.accept.split(',').indexOf(accept) == -1) {
        //     this.$message.error("只能上传图片、PDF文件");
        //     return false
        // }
      },
      handleUploadChange(info) {
        if (info.file.status === "uploading") {
          this.uploading = true;
          return;
        }
        if (info.file.status === "done") {
          this.uploading = false;
        }
        if (info.file.status === "removed") {
          this.filePath = info.fileList
        }
      },
      resourceUpload(value) {
        const formData = new FormData();
        formData.append("file", value.file);
        request(ATTACHMENT_UPLOAD, "post", formData).then((result) => {
          if (result.data.code === 0) {
            this.$message.success("上传成功!");
            this.filePath.push({
              uid: value.file.uid,
              url: result.data.data.path,
              name: value.file.name,
              status: 'done',
            });
            value.onSuccess(result.data.data.path, value.file);
          } else {
            this.$message.warning(result.data.message);
          }
        });
      },
      handlePreview(file) {
        window.open(file.url);
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  .a_Approval {
    background: #fff;
    border-radius: 10px;
  }
  
  .btn_group {
    padding-right: 190px;
  }
  </style>
  