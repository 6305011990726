<template>
  <a-card>
    <a-spin :spinning="spinning" :tip="loadingText">
    <div :class="advanced ? 'search' : null">
      <a-form layout="horizontal">
        <div :class="advanced ? null: 'fold'">
          <a-row >
          <a-col :md="8" :sm="24" >
            <a-form-item
              label="姓名"
              :labelCol="{span: 5}"
              :wrapperCol="{span: 18, offset: 1}"
            >
              <a-input placeholder="请输入" v-model="queryParams.userName"/>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24" >
            <a-form-item
              label="身份证号"
              :labelCol="{span: 5}"
              :wrapperCol="{span: 18, offset: 1}"
            >
              <!-- <a-select placeholder="请选择">
                <a-select-option value="1">关闭</a-select-option>
                <a-select-option value="2">运行中</a-select-option>
              </a-select> -->
              <a-input placeholder="身份证号" v-model="queryParams.idCard"/>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24" >
            <a-form-item
              label="上报是否成功"
              :labelCol="{span: 5}"
              :wrapperCol="{span: 18, offset: 1}"
            >
              <a-select placeholder="请选择">
                <a-select-option value="0">上报失败</a-select-option>
                <a-select-option value="1">上报成功</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        </div>
        <span style="float: right; margin-top: 3px;">
          <a-button type="primary" @click="doQuery()">查询</a-button>
          <a-button style="margin-left: 8px" @click="doReset()">重置</a-button>
          <!-- <a @click="toggleAdvanced" style="margin-left: 8px">
            {{advanced ? '收起' : '展开'}}
            <a-icon :type="advanced ? 'up' : 'down'" />
          </a> -->
        </span>
      </a-form>
    </div>
    <div>
      <a-space class="operator">
        <a-button @click="doDowloadBatch()">批量下载</a-button>
      </a-space>
      <standard-table
        :columns="columns"
        :dataSource="dataSource"
        :selectedRows.sync="selectedRows"
        :pagination="pagination"
        :loading="tableLoading"
        @clear="onClear"
        @change="onPageChange"
      >
        <div slot="safediscloseFileName" slot-scope="{text}">
          <div v-if="text!=null&&text!=''">{{text}}</div>
          <a-icon v-else style="color:red" type="close" />
        </div>
        <div slot="do-status" slot-scope="{text}">
          <a-icon v-if="text==1" style="color:green" type="check" />
          <a-icon v-else style="color:red" type="close" />
        </div>
        <div slot="action" slot-scope="{text, record}">
          <!-- <router-link :to="`/list/query/detail/${record.key}`" >打包下载资料</router-link> -->
          <a @click="doDowload(`${record.key}`)">打包下载资料</a>
        </div>
        <template slot="statusTitle">
          <a-icon @click.native="onStatusTitleClick" type="info-circle" />
        </template>
      </standard-table>
    </div>
    </a-spin>
  </a-card>
</template>

<script>
import StandardTable from '@/components/table/StandardTable'
import {
  SAFEMEANS_QUERYPAGE, 
  SAFEMEANS_DOWNLOAD
  } from '@/services/api'
import {request} from '@/utils/request'
import { te } from 'date-fns/locale'
// import axios from '@/utils/axios'
const columns = [
  {
    title: '姓名',
    dataIndex: 'userName',
    align: 'center',
    scopedSlots: { customRender: 'userName' }
  },
  {
    title: '身份证号',
    dataIndex: 'idCard',
    align: 'center',
    scopedSlots: { customRender: 'description' },
    customRender: (text) => {
      return text.substring(0,6)+"************"+text.substring(text.length-4,text.length)
    }
  },
  {
    title: '所属班组',
    dataIndex: 'teamName',
    align: 'center',
   scopedSlots: { customRender: 'teamName' }
  },
  {
    title: '合同',
    dataIndex: 'contractStatus',
    align: 'center',
    scopedSlots: {customRender: 'do-status'}
  },
  {
    title: '入场须知',
    dataIndex: 'rcxzStatus',
    align: 'center',
    scopedSlots: {customRender: 'do-status'}
  },
  {
    title: '三级安全教育',
    dataIndex: 'aqdjStatus',
    align: 'center',
    scopedSlots: {customRender: 'do-status'}
  },
  {
    title: '疫情安全教育',
    dataIndex: 'yqsafeStatus',
    align: 'center',
    scopedSlots: {customRender: 'do-status'}
  },
  {
    title: '疫情技术教育',
    dataIndex: 'yqtechStatus',
    align: 'center',
    scopedSlots: {customRender: 'do-status'}
  },
  {
    title: '安全技术交底',
    dataIndex: 'safediscloseFileName',
    align: 'center',
    scopedSlots: {customRender: 'safediscloseFileName'}
  },
  {
    title: '操作',
    align: 'center',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'QueryList',
  components: {StandardTable},
  data () {
    return {
      dataSource:[],
      advanced: false,
      columns: columns,
      selectedRows: [],
      tableLoading: false,
      spinning:false,
      loadingText:"数据加载中...",
      loadKeys:[],
      queryParams:{
        userName:null,
        idCard:null,
        teamName:null,
        pageNumber:1,
      },
      pagination:{
        current:1,
        pageSize:10,
        total:0,
        showLessItems:true,
        showQuickJumper:true
      }
    }
  },
  created() {
    this.doQuery();
  },
  authorize: {
    deleteRecord: 'delete'
  },
  methods: {
    deleteRecord(key) {
      this.dataSource = this.dataSource.filter(item => item.key !== key)
      this.selectedRows = this.selectedRows.filter(item => item.key !== key)
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    remove () {
      this.dataSource = this.dataSource.filter(item => this.selectedRows.findIndex(row => row.key === item.key) === -1)
      this.selectedRows = []
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },

    /**
     * 获取所有人员的签名记录信息
     */
    doQuery(){
      this.tableLoading = true;
        request(SAFEMEANS_QUERYPAGE,"post",this.queryParams).then(res => {
          if(res.data.code==0){
            this.dataSource = res.data.data.list;
            this.pagination.total = res.data.data.total;
            this.dataSource.forEach(obj=>{
              obj.key = obj.id;
            })
          }
          this.tableLoading = false;
        })
    },
    doReset(){
      this.queryParams={};
      this.doQuery();
    },
    //分页查询
    onPageChange(e){
      this.queryParams.pageNumber = e.current;
      this.pagination.current=e.current;
      this.doQuery();
    },
    /**
     * 批量下载
     */
    doDowloadBatch(){
      this.spinning = true;
      this.loadingText = "正在批量下载中，请稍后 ····";
      this.loadKeys = [];
      if(this.selectedRows&&this.selectedRows.length>0){
        this.selectedRows.map((item) => {
          this.loadKeys.push(item.key);
        })
        request(SAFEMEANS_DOWNLOAD,"post",this.loadKeys,
          {
            responseType:"blob",
            headers:{ 'Content-Type': 'application/json; application/octet-stream'}
          }).then(res => {
          this.spinning = false; 
          const content = res.data;
          const reader = new FileReader()
          var blob = new Blob([content], {type: "application/zip"})
          reader.readAsDataURL(blob)
          reader.onload = (e) => {
          const a = document.createElement('a')
            a.download = "个人安全资料文件下载.zip";
            a.href = e.target.result
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
          }
      })
      }else{
        this.spinning = false; 
        this.$message.info('请选中劳工人员进行下载');
        return;
      }
    },
    /**
     * 单个单个的下载
     */
    doDowload(key){
      this.spinning = true;
      this.loadingText = "正在下载中，请稍后 ····";
      this.loadKeys = [];
      this.loadKeys.push(key);
      request(SAFEMEANS_DOWNLOAD,"post",this.loadKeys,
        {
          responseType:"blob",
          headers:{ 'Content-Type': 'application/json; application/octet-stream'}
        }).then(res => {
        this.spinning = false; 
        const content = res.data;
        const reader = new FileReader()
        var blob = new Blob([content], {type: "application/zip"})
        reader.readAsDataURL(blob)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = "个人安全资料文件下载.zip";
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    handleMenuClick (e) {
      if (e.key === 'delete') {
        this.remove()
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .search{
    margin-bottom: 54px;
  }
  .fold{
    width: calc(100% - 216px);
    display: inline-block
  }
  .operator{
    margin-bottom: 18px;
  }
  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
</style>
