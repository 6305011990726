import { ADMIN } from '@/services/api'
import {request, METHOD, removeAuthorization} from '@/utils/request'

// const BASE_URL = process.env.VUE_APP_API_BASE_URL + '/score'
const ADMIN_API = `${ADMIN}/score`

export async function emergencyplanAdd(data) {
    return request(ADMIN_API + '/emergencyplan/add', METHOD.POST, data)
}
export async function emergencyplanUpdate(data) {
    return request(ADMIN_API + `/emergencyplan/update`, METHOD.POST, data)
}
export async function emergencyplanList(data) {
    return request(ADMIN_API + `/emergencyplan/list`, METHOD.POST, data)
}
export async function emergencyplanByID(params) {
    return request(ADMIN_API + `/emergencyplan/view`, METHOD.GET, params)
}
export async function emergencyplanDelete(params) {
    return request(ADMIN_API + `/emergencyplan/delete`, METHOD.GET, params)
}
export async function emergencyplanUpload(data) {
    return request(ADMIN_API + `/emergencyplan/upload`, METHOD.POST, data)
}

export const emergencyplanBatchDownload = ADMIN_API + '/emergencyplan/batchDownload'
export const emergencyplanExport = ADMIN_API + '/emergencyplan/download'
