<template>
  <div class="a_Approval">
    <div class="plug-Overview">安全技术交底模板管理</div>
    <a-divider />
    <a-collapse v-model="activeKey">
      <a-collapse-panel v-for="(item, i) in wordList" :key = "i+''" > 
          <div slot="header">
            <span>{{item.name}}</span>
            <a-button type="primary" style="margin-left:10px" v-if="isYYZY||item.hierarchy == 10"  @click="addModal(item.hierarchy)">新增</a-button>
          </div>
        <p v-for="(word, index) in item.wordConfigVoList" :key="index">{{ word.title }}  
          <a-button v-if="word.isCustom == 1" type="primary" @click="showModal(word)">编辑</a-button> 
          <a-button  v-if="word.isCustom == 0" type="primary" @click="showModal(word)">查看</a-button>
        </p> 
      </a-collapse-panel>
    </a-collapse>

    <a-modal
      title="文档详情"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      :wrapper-col="wrapperCol"
      width="980px"
      :destroyOnClose="true"
      :keyboard="false"
    >
      <word-content-edit v-if="visible" ref="wordContentEdit"></word-content-edit>
    </a-modal>
  </div>
  
</template>
<script>
import {getJsjdWordList, submitJsjd} from '@/services/wordConfig'
import {mapGetters} from 'vuex'
import WordContentEdit from './WordContentEdit'


export default {
  components: {WordContentEdit},
  data() {
    return {
      activeKey: ["0"],
      wordList: [],
      isYYZY:false,

      visible: false,
      confirmLoading: false,

      // 表单
      wrapperCol: { span: 22 },
      emptyWordInfo: {
        id: 0,
        title: '',
        content: '',
        wordCode: '',
        isCustom: 1,
      },

    };
    
  },
  watch: {
    activeKey(key) {
      console.log(key);
    },
  },
  created() {
    this.initData()
    let nowRoleKey = this.user.nowRoleKey;
    if(nowRoleKey&&nowRoleKey.length>0&&nowRoleKey.indexOf('PLATFORM_YYZY')>-1){
      this.isYYZY = true
    }else{
      this.isYYZY = false
    }
  },
  computed: {
    ...mapGetters('account', ['user']),
  },
  methods: {
    addModal(hierarchy){
      let obj ={
        id: 0,
        title: '',
        content: '',
        wordCode: '',
        isCustom: 0,
        hierarchy:hierarchy
      }
      if(hierarchy!=10){
        obj.isCustom = 0;
      }else{
        obj.isCustom = 1;
      }
      this.visible = true;
      setTimeout(() => {
        this.$refs.wordContentEdit.showModal(obj);
      }, 500);
    },
    showModal(word) {
      this.visible = true;
      setTimeout(() => {
        this.$refs.wordContentEdit.showModal(word);
      }, 500);
    },
    handleOk(e) {
      this.form = this.$refs.wordContentEdit.handleOk()
      // console.log(this.form.content)
      if (this.form.content.indexOf("<table>") !=-1){
        // this.$message.error("内容中包含表格内容，请去除表格或将复杂表格进行截图上传到内容中")
        this.$error({
          title: '文档内容错误',
          content: '请去除内容中包含的表格或将复杂表格使用图片上传到内容中',
        });
        return;
      }
      if(this.form.isCustom == 0&&!this.isYYZY){
        this.$message.warning("您不能编辑非自定义的技术交底！")
        return
      }
      if (this.form.content === "" || this.form.title === ""){
        // this.visible = false;
        this.$message.warning("信息不完整，保存失败！")
        return
      }
      this.confirmLoading = true;
      submitJsjd(this.form).then(res => {
          this.visible = false;
          this.confirmLoading = false;
          this.initData()
      })


    },
    handleCancel(e) {
      // this.$refs.wordContentEdit.beforeDestroy()
      this.visible = false;
      this.initData()
    },
    initData(){
      getJsjdWordList().then(res => {
        this.wordList = res.data.data
      })
    }
  }
};
</script>
