var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-drawer",
        {
          attrs: {
            title: "编辑模块消息设置",
            width: 820,
            visible: _vm.visible,
            "body-style": { paddingBottom: "80px" },
          },
          on: { close: _vm.onClose },
        },
        [
          _c(
            "a-form",
            {
              attrs: {
                form: _vm.form,
                "label-col": { span: 5 },
                "wrapper-col": { span: 18 },
                layout: "horizontal",
              },
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "模块名称：" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "moduleName",
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        message: "请输入模块名称！",
                                      },
                                    ],
                                  },
                                ],
                                expression:
                                  "['moduleName', { rules: [{ required: true, message: '请输入模块名称！' }] }]",
                              },
                            ],
                            attrs: { placeholder: "请输入模块名称" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "模块编号：" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "moduleCode",
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        message: "请输入模块编号！",
                                      },
                                    ],
                                  },
                                ],
                                expression:
                                  "['moduleCode', { rules: [{ required: true, message: '请输入模块编号！' }] }]",
                              },
                            ],
                            attrs: {
                              placeholder:
                                "请输入模块编号（请确保编号的保持唯一）",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "发送方式：" } },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: ["types"],
                                  expression: "['types']",
                                },
                              ],
                              staticStyle: { width: "100%" },
                              attrs: {
                                allowClear: true,
                                mode: "multiple",
                                placeholder: "请选择使用的发送方式（可多选）",
                              },
                            },
                            _vm._l(_vm.sendTypes, function (t) {
                              return _c(
                                "a-select-option",
                                { key: t.key, attrs: { value: t.key } },
                                [_vm._v(_vm._s(t.text))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "发送平台：" } },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: ["sysClient"],
                                  expression: "['sysClient']",
                                },
                              ],
                              staticStyle: { width: "100%" },
                              attrs: {
                                allowClear: true,
                                placeholder: "发送平台",
                              },
                            },
                            [
                              _c(
                                "a-select-option",
                                { key: "1", attrs: { value: 1 } },
                                [_vm._v(" 建工一号 ")]
                              ),
                              _c(
                                "a-select-option",
                                { key: "2", attrs: { value: 2 } },
                                [_vm._v(" 建工管理 ")]
                              ),
                              _c(
                                "a-select-option",
                                { key: "3", attrs: { value: 3 } },
                                [_vm._v(" 建工管理（电脑平台） ")]
                              ),
                              _c(
                                "a-select-option",
                                { key: "4", attrs: { value: 4 } },
                                [_vm._v(" 其他 ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                {
                  staticStyle: { "margin-left": "11%" },
                  attrs: { gutter: 16 },
                },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "有效状态：" } },
                        [
                          _c("a-switch", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "statusBoolean",
                                  { valuePropName: "checked" },
                                ],
                                expression:
                                  "['statusBoolean', { valuePropName: 'checked' }]",
                              },
                            ],
                            attrs: {
                              "checked-children": "开",
                              "un-checked-children": "关",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 12 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "其他配置：" } },
                        [
                          _c("a-textarea", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: ["setDesc"],
                                expression: "['setDesc']",
                              },
                            ],
                            attrs: {
                              rows: 4,
                              placeholder: "请规范填写JSON格式",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 12 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "备注说明：" } },
                        [
                          _c("a-textarea", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: ["remark"],
                                expression: "['remark']",
                              },
                            ],
                            attrs: { rows: 4, placeholder: "备注说明" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              style: {
                position: "absolute",
                right: 0,
                bottom: 0,
                width: "100%",
                borderTop: "1px solid #e9e9e9",
                padding: "10px 16px",
                background: "#fff",
                textAlign: "right",
                zIndex: 1,
              },
            },
            [
              _c(
                "a-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleSubmit()
                    },
                  },
                },
                [_vm._v(" 保存 ")]
              ),
              _c(
                "a-button",
                { style: { marginLeft: "8px" }, on: { click: _vm.onClose } },
                [_vm._v(" 取消 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }