<template>
    <div class="a_Approval">
        <div class="mb-2" style="display:flex">
            <a-button v-if="isSafe" type="primary" class="mr-2" @click="addNewComplaint"> 新增 </a-button>
            <a-button type="primary" class="mr-2" @click="bathDownload"> 批量下载 </a-button>

            <a-upload name="file" :accept="accept" @change="handleUploadChange" :showUploadList="false"
                :customRequest="resourceUpload" :beforeUpload="beforeUpload" multiple>


                <a-button type="primary" class="mr-2"> 上传应急预案资料 </a-button>


            </a-upload>

            <a-button type="text" @click="handleDownload"> 应急预案资料下载 </a-button>
        </div>

        <a-tabs default-active-key="1" v-model="params.type" @change="changeTab">
            <a-tab-pane key="1" tab="最新应急预案">

            </a-tab-pane>
            <a-tab-pane v-if="isSafe" key="2" tab="历史应急预案">

            </a-tab-pane>

        </a-tabs>
        <standard-table :columns="columns" :dataSource="tableData" rowKey="emergencyid" :pagination="pagination"
            :loading="tableLoading" @change="handleChangePage" :isAlert="false" :bordered="true"
            :selectedRows.sync="selectedRows">
            <!-- @selectedRowChange="handleSelectedRowsChange" :totalBoolean="true" :rowColor="false" -->
            <template slot="index" slot-scope="{ index }">
                <span>{{
                    (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1
                }}</span>
            </template>
            <template slot="attach" slot-scope="{ record }">
                <!-- {{ record }} -->
                <div class="flex j-center">
                    <div v-for="(item, index) in record.attachList" :key="index">
                        <img alt="" style="width: 32px" v-if="item.type == 'img'" :src="item.url"
                            @click="handlePreview(item)" />

                        <a-icon type="file-pdf" :style="{ fontSize: '32px' }" v-else-if="item.type == 'pdf'"
                            @click="handlePreview(item)" />
                        <a-icon type="file-text" :style="{ fontSize: '32px' }" v-else @click="handlePreview(item)" />
                    </div>
                </div>
            </template>
            <template slot="action" slot-scope="{ record }">
                <div class="sTables">
                    <div @click="handleEdit(record)">编辑</div>
                    <div @click="handleDel(record)" v-show="!$route.meta.isBreak">
                        删除
                    </div>
                </div>
            </template>
        </standard-table>
    </div>
</template>

<script>
import {
    emergencyplanList,
    emergencyplanDelete,
    emergencyplanBatchDownload,
    emergencyplanExport,
    emergencyplanUpload
} from "@/services/emergencyManagement";
import { mapGetters } from "vuex";
import StandardTable from "@/components/table/StandardTable";
import moment from "moment";
import { request } from "@/utils/request";
import { ATTACHMENT_UPLOAD } from "@/services/api";
const columns = [
    {
        title: "序号",
        align: "center",
        scopedSlots: { customRender: "index" },
        width: 65,
    },
    {
        title: "应急预案名称",
        align: "center",
        dataIndex: "emergencyname",
        ellipsis: true,
        tooltip: true,
        width: 250,
    },

    {
        title: "版本号",
        dataIndex: "version",
        width: 150,
        align: "center",
    },
    {
        title: "发文文号",
        dataIndex: "pubno",
        width: 150,
        align: "center",
    },
    {
        title: "发布日期",
        dataIndex: "reserved1",
        width: 150,
        align: "center",
    },

    {
        title: "附件",
        dataIndex: "attach",
        scopedSlots: { customRender: "attach" },
        align: "center",
    },

    {
        title: "操作",
        align: "center",
        width: 120,
        scopedSlots: { customRender: "action" },
    },
];

export default {
    components: {
        StandardTable,
    },
    data() {
        return {
            params: {

                type: "1",
                page: 1,
                limit: 10,

            },
            pagination: {
                //分页数据
                current: 1,
                pageSize: 10,
                total: 0,
                showTotal: (total, range) => `共 ${total} 条`,
            },
            yearShowOne: false,
            total: 0,
            columns: columns,
            tableData: [],
            tableLoading: false, //table加载中
            selectedRows: [],
            tmpSelectedRows: [],

            accept: "",
            filePath: [],
            uploading: false,
        };
    },
    created() {
        if (!this.isSafe) {
            this.columns = this.columns.filter((el) => el.title != "操作");
        }
        this.handleQuery();
    },
    activated() {
        this.handleQuery();
    },
    computed: {
        ...mapGetters("account", ["user"]),
        isBranchOffice() {
            //分公司
            return this.user.isGcglb == 1 && this.user.loginType == 1;
        },
        isProject() {
            //项目部
            return this.user.loginType == 0;
        },
        isSafe() {
            //安全部
            return this.user.isGcglb == 0 && this.user.loginType == 1;
        },
        tableBtnArr() {
            return [
                {
                    name: "新增",
                    attrs: {
                        type: "primary",
                    },
                    hidden: !this.isSafe,
                    click: this.addNewComplaint,
                },
                {
                    name: "批量下载",
                    attrs: {
                        type: "primary",
                    },
                    // hidden: !this.isJSOffice,
                    click: this.bathDownload,
                },
            ];
        },
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault();
            this.params.page = 1;
            this.pagination.current = 1;
            this.handleQuery();
        },
        changeTab() {
            this.params.page = 1;
            this.pagination.current = 1;
            this.handleQuery();
        },
        handleQuery(e) {
            this.tableData = [];
            this.tableLoading = true;
            let d = {
                ...this.params,

            };
            emergencyplanList(d).then((res) => {
                this.tableLoading = false;
                if (res.data.code === 200) {
                    let list = res.data.data.list;

                    this.tableData = list.map((item) => {
                        let attachList = [];
                        if (item.attach) {
                            attachList = item.attach.split(",");
                        }
                        attachList = attachList.map((i) => {
                            let type = "img";
                            // if i 为图片
                            if (
                                i.indexOf(".jpg") > -1 ||
                                i.indexOf(".png") > -1 ||
                                i.indexOf(".jpeg") > -1 ||
                                i.indexOf(".gif") > -1
                            ) {
                                type = "img";
                            } else if (i.indexOf(".pdf") > -1) {
                                type = "pdf";
                            } else {
                                type = "file";
                            }

                            return {
                                url: i,
                                type: type,
                            };
                        });
                        return {
                            ...item,
                            key: item.emergencyid,
                            attachList: attachList,
                        };
                    });
                    this.pagination.total = res.data.data.totalCount;
                } else {
                    this.$message.error(res.data.msg);
                }
            });
        },
        handleChangePage(e) {
            this.params.page = e.current;
            this.pagination.current = e.current;
            this.handleQuery();
        },
        addNewComplaint() {
            this.$router.push({
                name: `应急预案详情`,
                query: {
                    isEdit: 0,
                },
            });
        },
        handleEdit(e) {
            this.$router.push({
                name: `应急预案详情`,
                query: {
                    id: e.emergencyid,
                    isEdit: 1,
                },
            });
        },
        handleDel(record) {
            const _that = this;
            this.$confirm({
                title: "是否确定删除？",
                cancelText: "否",
                okText: "是",
                onOk: () => {
                    emergencyplanDelete({ id: record.emergencyid }).then((res) => {
                        if (res.data.code === 200) {
                            _that.$message.success("删除成功");
                            this.handleQuery(this.params);
                        } else {
                            _that.$message.error(res.data.msg);
                        }
                    });
                },
            });
        },
        bathDownload() {
            if (this.selectedRows.length == 0) {
                this.$message.warning("请选择文件！");
            } else {
                let arr = [];
                this.selectedRows.forEach((el) => {
                    arr.push(el.emergencyid);
                });
                let url = emergencyplanBatchDownload + `?ids=${arr.join(",")}`;
                window.open(url);
            }
        },
        handleDownload() {
            window.open(emergencyplanExport);
        },
        handlePreview(file) {
            window.open(file.url);
        },
        beforeUpload(file) {
            // const accept = '.' + file.name.substring(file.name.lastIndexOf(".") + 1);
            // if (this.accept.split(',').indexOf(accept) == -1) {
            //     this.$message.error("只能上传图片、PDF文件");
            //     return false
            // }
        },
        handleUploadChange(info) {
            if (info.file.status === "uploading") {
                this.uploading = true;
                return;
            }
            if (info.file.status === "done") {
                this.uploading = false;
            }
            if (info.file.status === "removed") {
                this.filePath = info.fileList
            }
        },
        resourceUpload(value) {
            const formData = new FormData();
            formData.append("file", value.file);
            request(ATTACHMENT_UPLOAD, "post", formData).then((result) => {
                if (result.data.code === 0) {
                    // this.$message.success("上传成功!");
                    this.filePath.push({
                        uid: value.file.uid,
                        url: result.data.data.path,
                        name: value.file.name,
                        status: 'done',
                    });
                    value.onSuccess(result.data.data.path, value.file);
                    this.uploadUrl(result.data.data.path)
                } else {
                    this.$message.warning(result.data.message);
                }
            });
        },
        uploadUrl(url) {
            emergencyplanUpload({ url: url }).then((res) => {
                if (res.data.code === 200) {
                    this.$message.success("上传成功");
                    // this.handleQuery();
                } else {
                    this.$message.error(res.data.msg);
                }
            });
        }


    },
};
</script>

<style lang="less" scoped>
.query-form {
    display: flex;
    // justify-content: space-between;
}

.post-border {
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 5px;
    padding-top: 5px;
    // padding-top: 10px;
    // height: 40px;
}

.post-border:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

.sTables {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
        width: 100%;
        text-align: center;
        cursor: pointer;
    }

    div:nth-child(1) {
        color: #307dfa;
    }

    div:nth-child(2) {
        color: #ff5c77;
    }

    div:nth-child(3) {
        color: #615e83;
    }
}
</style>
