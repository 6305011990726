var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "img-box" }, [
        _c("img", { attrs: { src: _vm.staffLib.appPhotoPath, alt: "" } }),
        _c("p", [_vm._v(_vm._s(_vm.staffLib.name))]),
        _c("p", [_vm._v(_vm._s(_vm.staffLib.gender))]),
        _c("p", [_vm._v(_vm._s(_vm.staffLib.nation))]),
        _c("p", [_vm._v(_vm._s(_vm.workAge) + "岁")]),
      ]),
      _c(
        "div",
        { staticClass: "calender-box" },
        [
          _c("a-calendar", {
            attrs: { "header-render": _vm.headerRender },
            scopedSlots: _vm._u([
              {
                key: "dateCellRender",
                fn: function (value) {
                  return _vm._l(_vm.getListData(value), function (item, index) {
                    return _c(
                      "div",
                      { key: index },
                      _vm._l(item.atteTime, function (obj, index2) {
                        return _c(
                          "div",
                          { key: index2, staticClass: "flex a-center" },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.formatDate(obj.atteTime))),
                            ]),
                            obj["checkType"] == 1
                              ? _c(
                                  "span",
                                  { staticClass: "ml-05 font-12 in-span" },
                                  [_vm._v("进")]
                                )
                              : _c(
                                  "span",
                                  { staticClass: "ml-05 font-12 out-span" },
                                  [_vm._v("出")]
                                ),
                          ]
                        )
                      }),
                      0
                    )
                  })
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }