var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "text-align": "center", "margin-top": "48px" } },
    [
      _c(
        "color-checkbox-group",
        {
          staticStyle: { display: "inline-block" },
          attrs: { defaultValues: ["1", "3", "4"], multiple: true },
          on: { change: _vm.changeColor },
        },
        [
          _c("color-checkbox", {
            attrs: { color: "rgb(245, 34, 45)", value: "1" },
          }),
          _c("color-checkbox", {
            attrs: { color: "rgb(250, 84, 28)", value: "2" },
          }),
          _c("color-checkbox", {
            attrs: { color: "rgb(250, 173, 20)", value: "3" },
          }),
          _c("color-checkbox", {
            attrs: { color: "rgb(19, 194, 194)", value: "4" },
          }),
          _c("color-checkbox", {
            attrs: { color: "rgb(82, 196, 26)", value: "5" },
          }),
          _c("color-checkbox", {
            attrs: { color: "rgb(24, 144, 255)", value: "6" },
          }),
          _c("color-checkbox", {
            attrs: { color: "rgb(47, 84, 235)", value: "7" },
          }),
          _c("color-checkbox", {
            attrs: { color: "rgb(114, 46, 209)", value: "8" },
          }),
          _c("color-checkbox", {
            attrs: { color: "rgb(256, 0, 0)", value: "9" },
          }),
          _c("color-checkbox", {
            attrs: { color: "rgb(0, 256, 0)", value: "10" },
          }),
          _c("color-checkbox", {
            attrs: { color: "rgb(0, 0, 256)", value: "11" },
          }),
          _c("color-checkbox", {
            attrs: { color: "rgb(256, 256, 0)", value: "12" },
          }),
        ],
        1
      ),
      _c("div"),
      _c("div", {
        staticClass: "view-color",
        style: { backgroundColor: _vm.color },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }