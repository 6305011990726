<template>
  <div class="a_Approval">
    <div class="plug-Overview">建委配置</div>
    <a-divider />
    <!-- 表单封装 
            projectObj：下拉弹出框第一项对象
            Overview：项目名称
            baiduMapBoolen：控制地图显示
            topSubmit：控制上部分按钮显示
            bottomSubmit：控制下部分按钮显示
            handleSubmit：表单提交
            handleChange：输入出发
            handlSelect：输入下拉选择触发
        -->
    <form-index
      ref="formindex"
      :formData="formData"
      flexAction
      :baiduMapBoolen="false"
      @handleSubmit="handleSubmit"
    >
      <!-- 按钮插槽 -->
      <template #flexAction>
        <a-space class="btnBox">
          <a-button type="primary" html-type="submit">查询</a-button>
        </a-space>
      </template>
    </form-index>

    <!-- 表格封装 -->
    <standard-table
      :columns="columns"
      :dataSource="dataSource"
      rowKey="projectId"
      :pagination="pagination"
      :loading="tableLoading"
      @change="onPageChange"
      :isAlert="false"
      :bordered="true"
      :btnArr="tableBtnArr"
    >
      <template slot="index" slot-scope="{ index }">
        <span>
          {{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}
        </span>
      </template>
      <template slot="status" slot-scope="{ record }">
        <a-switch :checked="record.status == 0" checked-children="已开启" un-checked-children="已关闭" @click="onSwitchClick($event, record)"/>
      </template>
       <template slot="action" slot-scope="{ record }">
          <a-space>
            <!-- <div class="pointer" style="color: #307dfa;" @click="visit(record)">查看</div> -->
            <div class="pointer" style="color: #ff5c77;" @click="del(record)">删除</div>
          </a-space>
        </template>
    </standard-table>
    <a-modal v-model="modalVisible" title="新增工程配置" :destroyOnClose="true" :footer="null" @cancel="closeModal"
      :maskClosable="false">
      <form-index ref="formindexModal" :formData="formDataModal" :flexSubmit="true"
        :flexAction="true" @handleSubmit="handleModalSubmit" @handleChange="handleModalChange" @handlSelect="handlModalSelect">
        <template #flexAction>
          <div class="flex a-center j-center">
            <a-space :style="{ marginBottom: '20px' }">
              <a-button @click="closeModal"> 返回 </a-button>
              <a-button type="primary" html-type="submit">提交</a-button>
            </a-space>
          </div>
        </template>
      </form-index>
    </a-modal>
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import { getFacilityProjectConfigPage, getFacilityProjectConfigDel, getFacilityProjectConfigUpdate, getFacilityProjectConfigCreate } from "@/services/equipmentLedger";
import { QUERY_OFFICE_BY_USER } from "@/services/api";
import { request } from "@/utils/request";
import { getProjectByOffice } from "@/services/statistics";
import { mapGetters } from "vuex";

const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
  },
  {
    title: "工程名称",
    dataIndex: "gcmc",
    ellipsis: true,
    tooltip: true
  },
  {
    title: "工程编码",
    dataIndex: "gcbm",
    width: 350,
    align: "center",
    // ellipsis: true,
    scopedSlots: { customRender: "gcbm" },
  },
  {
    title: "同步类型",
    dataIndex: "syncType",
    width: 150,
    align: "center",
    customRender: (text) => {
      if (text == 0) {
        return '建委'
      } else if (text == 1) {
        return '设备双发'
      } else {
        return '/'
      }
    }
  },
  {
    title: "同步开关",
    dataIndex: "status",
    width: 150,
    align: "center",
    // ellipsis: true,
    scopedSlots: { customRender: "status" },
  },
  {
    title: "操作",
    align: "center",
    width: 140,
    fixed: "right",
    scopedSlots: { customRender: "action" },
  },
];
const formData = [
  {
    label: "工程名称",
    placeholder: "请选择工程名称",
    decorator: [
      "gcmc",
      { rules: [{ required: false, message: "请选择工程名称" }] },
    ],
    type: "input",
    key: "gcmc",
    col: 5,
    labelCol: 8,
    wrapperCol: 14,
  },
  // {
  //   label: '工程管理部',
  //   placeholder: '请选择工程管理部',
  //   decorator: ['gcglbId', { rules: [{ required: false, message: '请选择工程管理部' }] }],
  //   type: 'select',
  //   key: 'gcglbId',
  //   selectlist: [],
  //   qb:true,
  //   col:5,
  //   labelCol: 8,
  //   wrapperCol:14,
  // },
  {
    type: 'action',
    col: 2
  }
];
const formDataModal = [
  {
    label: "工程名称",
    placeholder: "请输入项目名称",
    decorator: [
      "projectId",
      { rules: [{ required: true, message: "请输入项目名称" }] },
    ],
    type: "inputGroup",
    key: "projectId",
    dataSource: [],
    col: 24,
    labelCol: 8,
    wrapperCol: 15,
    display: true,
    disType: true
  },
  {
    label: "工程编码",
    placeholder: "请填写",
    decorator: ["gcbm", { rules: [{ required: true, message: "请填写" }] }],
    type: "input",
    key: "gcbm",
    col: 24,
    labelCol: 8,
    wrapperCol: 15,
    display: true,
  },
  {
    label: "同步类型",
    placeholder: "请选择同步类型",
    decorator: ["syncType", { initialValue: 0, rules: [{ required: true, message: "请选择同步类型" }] },],
    type: "radio",
    key: "syncType",
    radiolist: [
      {
        name: "建委",
        value: 0,
      },
      {
        name: "设备双发",
        value: 1,
      },
    ],
    labelCol: 8,
    wrapperCol: 15,
    col: 24,
    display: true,
  },
  {
    label: "同步开关",
    placeholder: "请选择同步开关",
    decorator: ["status", { initialValue: 0, rules: [{ required: true, message: "请选择同步开关" }] },],
    type: "radio",
    key: "status",
    radiolist: [
      {
        name: "开启",
        value: 0,
      },
      {
        name: "关闭",
        value: 1,
      },
    ],
    labelCol: 8,
    wrapperCol: 15,
    col: 24,
    display: true,
  },
  {
    type: "action",
    col: 24,
    display: true,
  },
]
export default {
  components: {
    formIndex, //form表单封装
    StandardTable, //表格封装
  },
  data() {
    return {
      modalVisible: false,
      columns: columns, //表格数据
      dataSource: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      tableLoading: false,
      formData: formData,
      formDataModal: formDataModal,
      params: {
        pageNo: 1,
        pageSize: 10,
      },
      tableBtnArr: [
        {
          name: '新增',
          attrs: {
            type: 'primary'
          },
          click: this.addNewProject
        },
      ],
      projectList: [],
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  watch: {
    loadingText: {
      handler(newName, oldName) {
        this.loadingText = newName;
      },
      immediate: true,
    },
  },
  activated() {
    this.getgcglbList(QUERY_OFFICE_BY_USER);
    this.aqxjlist(this.params);
  },
  methods: {
     closeModal() {
      this.modalVisible = false
    },
    handleModalSubmit(e) {
      const project = this.projectList.find(el => el.projectId == e.projectId)
      if (project) {
        e.gcmc = project.projectName
        getFacilityProjectConfigCreate(e).then(res => {
          if (res.data.code === 0) {
            this.$message.success('操作成功')
            this.modalVisible = false
            this.projectList = []
            this.aqxjlist(this.params)
          } else {
            this.$message.error(res.data.msg)
          }
        })
      } else {
        this.$message.error('请选择正确工程名称')
      }
    },
    handlModalSelect(e) {
      if (e.keys == 'projectId') {
        this.formDataModal.forEach(item => {
          if (e.keys === item.key) {
            const project = this.projectList.find(el => el.projectId == e.val)
            this.$refs['formindexModal'].setFieldsValue('', {'gcbm': project.projectNum}, '')
          }
        })
      }
    },
    handleModalChange(e) {
      if (e.item.key == 'projectId') {
        this.getZdata(getProjectByOffice, e, "projectName", 'projectName', this.formDataModal);
      }
    },
    // 接口封装
    /**
     * msg 接收接口名称
     * e 数据
     * name 参数名
     * */
    getZdata(msg, e, name, title, formData) {
      let params = {
        [name]: e.value,
      }

      msg(params).then((res) => {
        if (res.status == 200) {
          this.projectList = res.data.data
          let arr = []
          res.data.data.forEach((item) => {
            let obj = {
              title: item[title],
            }
            if (e.item.key == 'projectId') {
              obj.id = item.projectId
            }
            arr.push(obj);
          });
          // 获取当前匹配key，赋值相对于数组
          formData.forEach((item) => {
            if (e.item.key === item.key) {
              item.dataSource = arr;
            }
          });
        }
      });
    },
    aqxjlist(params) {
      this.tableLoading = true;
      getFacilityProjectConfigPage(params).then((res) => {
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
        } else {
          this.$message.error(res.data.msg)
        }
        this.tableLoading = false;
      });
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      let arr = [];
      data.forEach((item) => {
        arr.push({ name: item.master, value: item.id });
      });
      this.formData.forEach((item) => {
        if (item.key == key) {
          item.selectlist = arr;
        }
      });
    },
    // 获取工程管理部数据
    getgcglbList(path) {
      request(path, "get").then((res) => {
        let data = res.data.data.filter(
          (item) => item.id != "3da0f5533f87425c9a9e8a11a7923300"
        );
        this.formDataFn("gcglbId", data);
      });
    },
    // 提交表单数据
    handleSubmit(e) {
      this.params.pageNo = 1
      this.pagination.current = 1;
      let obj = Object.assign({}, e, this.params);
      this.aqxjlist(obj);
    },
    addNewProject() {
      this.modalVisible = true
    },
    onSwitchClick(event, record) {
      console.log(event, record, 'onSwitchClick')
      if (event) {
        record.status = 0
      } else {
        record.status = 1
      }
      getFacilityProjectConfigUpdate(record).then(res => {
        if (res.data.code === 0) {
          this.$message.success('操作成功')
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    del(record) {
      const _that = this
      this.$confirm({
          title: '是否确定删除？',
          cancelText: '否',
          okText: '是',
          onOk: () => {
            getFacilityProjectConfigDel({id: record.id}).then(res => {
              if (res.data.code === 0) {
                _that.$message.success('删除成功')
                this.aqxjlist(this.params);
              } else {
                _that.$message.error(res.data.msg)
              }
            })
          },
        })
    },
    //表格 - 分页查询
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      this.params.pageNo = e.current;
      this.pagination.current = e.current;
      this.aqxjlist(Object.assign({}, this.params, obj));
    },
  },
};
</script>

<style lang="less" scoped>

/deep/ .ant-table-thead > tr > th {
  font-weight: bold;
}

.c_i_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 25px;

  .c_i_h_center {
    padding: 16px;
    border-radius: 4px;
    width: 180px;
    height: 130px;
    margin-right: 50px;

    div:nth-child(1) {
      font-size: 16px;
    }

    div:nth-child(2) {
      margin-top: 26px;
      font-size: 24px;
    }
  }
}

.sTable {
  display: flex;
  justify-content: space-between;
  align-items: center;

  // div {
  //     width: 100%;
  //     color: blue;
  //     text-align: center;
  //     cursor: pointer;
  // }
}
</style>
