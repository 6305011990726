<template>
  <div>
    <a-form-model :model="params" class="clearfix" @submit="handleSubmit" v-if="user.loginType != 0">
      <a-col :span="5">
        <a-form-model-item label="工程管理部" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
          <a-select
            v-model="params.gcglbId"
            allowClear
            placeholder="请选择"
          >
            <a-select-option
              v-for="item in gcglbList"
              :key="item.value"
              :value="item.value"
              :title="item.name"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <!-- <a-form-model-item label="月度">
        <a-month-picker style="width: 220px" placeholder="请选择月份" format="YYYY-MM" v-model="monthPickerVal">
          <a-icon slot="suffixIcon" type="smile" />
        </a-month-picker>
      </a-form-model-item> -->

      <a-col :span="5">
        <a-form-model-item label="项目名称" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
          <a-input placeholder="请输入项目名称" v-model="params.projectName" allowClear/>
        </a-form-model-item>
      </a-col>

      <a-col :span="5">
        <a-form-model-item label="形象进度" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
          <a-input placeholder="请输入形象进度" v-model="params.imageProcess" allowClear/>
        </a-form-model-item>
      </a-col>

      <!-- <a-col :span="5">
        <a-form-model-item label="状态" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
          <a-select
            v-model="params.status"
            allowClear
            placeholder="请选择"
          >
            <a-select-option
              v-for="item in statusList"
              :key="item.value"
              :value="item.value"
              :title="item.name"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col> -->

      <a-col :span="4">
        <a-space class="btnBox">
          <a-button type="primary" html-type="submit"> 查询 </a-button>
          <a-button @click="resetQuery"> 重置 </a-button>
          <a-button type="primary" @click="doDowload" v-if="user.isGcglb == 0 && user.loginType == 1 && user.nowRoleKey.includes('JS_LJJ')"> 导出 </a-button>
        </a-space>
      </a-col>
    </a-form-model>

    <a-button @click="batchConfirm" type="primary" :disabled="!canConfirm" v-if="user.isGcglb == 1">批量确认</a-button>

    <standard-table
      :columns="columns"
      :dataSource="tableData"
      rowKey="index"
      :pagination="pagination"
      :loading="tableLoading"
      @change="handleChangePage"
      :isAlert="false"
      :bordered="true"
    >
      <template slot="index" slot-scope="{ index }">
        <span>{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}</span>
      </template>
      <template slot="dangerAndProcess" slot-scope="{ index, record }">
        <a-tooltip v-if="record.dangerContent" placement='topLeft'>
          <template slot='title'>
            <div style="white-space: pre-wrap;" class="ellipsis-2">危大内容：{{ record.dangerContent }}</div>
          </template>
          <div style="white-space: pre-wrap;" class="ellipsis-2">危大内容：{{ record.dangerContent }}</div>
        </a-tooltip>
        <a-tooltip v-if="record.imageProcess" placement='topLeft'>
          <template slot='title'>
            <div class="mt-1 ellipsis-2">形象进度：{{ record.imageProcess }}</div>
          </template>
          <div class="mt-1 ellipsis-2">形象进度：{{ record.imageProcess }}</div>
        </a-tooltip>
      </template>
      <template slot="status" slot-scope="{ text }">
        <span >{{ text | statusName }}</span>
      </template>
      <div slot="action" slot-scope="{ text, record }">
        <a
          @click="() => handleEdit(record)"
        >
          <span v-if="user.nowRoleKey.includes('OFFICE_AQ_LEADER')">查看</span>
          <span v-else>编辑</span>
        </a>
        <a
          v-if="record.status == 0 && user.isGcglb == 1 && user.loginType == 1 && canConfirm"
          @click="() => handleConfirm(record)"
          style="color: red;"
        >
           确认
        </a>
        <div 
          v-if="record.status == 0 && user.isGcglb == 1 && user.loginType == 1 && !canConfirm" 
          style="color: #999;"><span title="开放时间：本月22至24日">确认</span>
        </div>
      </div>
    </standard-table>

    <Export-loading
      :cPercent="cPercent"
      :isExporting="isExporting"
      :loadingText="loadingText"
    ></Export-loading>
  </div>
</template>

<script>
import {
  getDangerSourceReportList,
  dangerSourceExportUrl,
  projectSourceDangerBack,
  updateSourceConfirm, updateSourceBatchConfirm
} from "@/services/safeMonthlyReport";
import { request } from "@/utils/request";
import { QUERY_OFFICE_BY_USER } from "@/services/api";
import ExportLoading from "@/components/ExportLoading/ExportLoading"; // progress组件
import { exportProgress } from "@/pages/aqjyzl/export"; // 导出方法
import { mapGetters } from "vuex";
import StandardTable from "@/components/table/StandardTable";
import moment from "moment";

const columns = [
  {
    title: "序号",
    align: "center",
    scopedSlots: { customRender: "index" },
    width: 65,
  },
  {
    title: "工程管理部",
    align: "center",
    dataIndex: "gcglbName",
    width: 150,
  },
  {
    title: "在建项目名称（危险源名称）",
    dataIndex: "projectName",
    ellipsis: true,
    tooltip: true
  },
  {
    title: "工程造价（亿元）",
    dataIndex: "projBudget",
    align: "center",
    width: 120,
  },
  {
    title: "月巡检次数/自评得分",
    dataIndex: "checkNumScore",
    align: "center",
    width: 120,
  },
  {
    title: "项目危险性较大分部分项分布（超）部位/（形象进度）",
    dataIndex: "dangerAndProcess",
    scopedSlots: { customRender: "dangerAndProcess" },
  },
  {
    title: "项目经理联系电话/项目地址",
    dataIndex: "projectExportInfo",
    // align: "center",
    width: 200,
    ellipsis: true,
    tooltip: true
  },
  {
    title: "状态",
    align: "center",
    dataIndex: "status",
    width: 80,
    scopedSlots: { customRender: "status" },
  },
  // {
  //   title: "操作",
  //   align: "center",
  //   fixed: "right",
  //   width: 80,
  //   scopedSlots: { customRender: "action" },
  // },
];

export default {
  mixins: [exportProgress], //导出方法注入
  components: {
    ExportLoading,
    StandardTable
  },
  data() {
    return {
      params: {
        month: '',
        gcglbId: undefined,
        projectName: '',
        year: '',
        imageProcess: '',
        pageNumber: 1,
        pageSize: 10,
        status: 0
      },
      selectedRows: [], //选中的数据
      pagination: {
        //分页数据
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total, range) => `共 ${total} 条`,
      },
      gcglbList: [],
      total: 0,
      columns: columns,
      tableData: [],
      tableLoading: false, //table加载中

      loadingText: "数据加载中...",
      cPercent: 0,
      isExporting: false,
      statusList: [
        {
          name: '待确认',
          value: 0
        },
        {
          name: '已确认',
          value: 1
        }
      ]
    };
  },
  created() {
    this.getgcglbList(QUERY_OFFICE_BY_USER);
    this.handleQuery();
  },
  activated() {
    
  },
  filters: {
    statusName(status) {
      switch (status) {
        case 0:
          return '待确认'
        case 1:
          return '已确认'
        case 2:
          return '退回'
        case 3:
          return '已上报'
        default:
          return ''
      }
    },
  },
  computed: {
    ...mapGetters("account", ["user"]),
    canConfirm() {
      const today = moment(); // 获取当前日期
      const dayOfMonth = today.date(); // 获取当前月份的日期
      if (dayOfMonth >= 22 && dayOfMonth <= 24) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.params.pageNumber = 1
      this.pagination.current = 1
      this.tableData = []
      this.handleQuery();
    },
    batchConfirm() {
      if (this.selectedRows.length == 0) {
        this.$message.warning('请选择项目！')
      } else {
        let arr = []
        this.selectedRows.forEach(el => {
          arr.push(el.id)
        })
        const _that = this
        this.$confirm({
          title: '是否确认？',
          onOk() {
            updateSourceBatchConfirm({ids: arr}).then(res => {
              if (res.data.code === 0) {
                _that.$message.success('操作成功！')
                _that.handleQuery()
              } else {
                _that.$message.error(res.data.msg)
              }
            })
          }
        })
      }
    },
    handleQuery(e) {
      this.tableLoading = true
      const now = moment() // 当前时间
      const targetDate = moment().date(25).hour(3).minute(0).second(0) // 设置目标日期为当月的25号03:00
      if (now.isBefore(targetDate)) {
        // console.log('当前时间早于当月25号03:00');
        this.params.year = moment(now).format('YYYY-MM').split('-')[0]
        this.params.month = moment(now).format('YYYY-MM').split('-')[1]
      } else if (now.isAfter(targetDate)) {
        // console.log('当前时间晚于当月25号03:00');
        this.params.year = moment(now).add(1,'months').format('YYYY-MM').split('-')[0]
        this.params.month = moment(now).add(1,'months').format('YYYY-MM').split('-')[1]
      }
      // if (this.monthPickerVal) {
      //   // console.log(moment(this.monthPickerVal).format('YYYY-MM'))
      //   this.params.year = moment(this.monthPickerVal).format('YYYY-MM').split('-')[0]
      //   this.params.month = moment(this.monthPickerVal).format('YYYY-MM').split('-')[1]
      // }
      getDangerSourceReportList(this.params).then((res) => {
        this.tableLoading = false
        let d = res.data;
        if (d.code === 0) {
          this.tableData = d.data.list
          this.pagination.total = d.data.total;
          this.total = d.data.total;
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    updateSelectRows(e) {
      console.log(e)
    },
    handleConfirm(e) {
      // 分公司-确认状态
      const _that = this
      this.$confirm({
        title: '是否确认？',
        onOk() {
          updateSourceConfirm({ids: [e.id]}).then(res => {
            let d = res.data;
            if (d.code === 0) {
              _that.$message.success('确认成功')
              _that.handleQuery()
            } else {
              _that.$message.error(res.data.msg)
            }
          })
        }
      })
    },
    handleBack(e) {
      const _that = this
      this.$confirm({
          title: '确认退回？',
          onOk() {
            projectSourceDangerBack(e.id).then(res => {
              let d = res.data;
              if (d.code === 0) {
                _that.$message.success('退回成功')
                _that.handleQuery()
              } else {
                _that.$message.error(res.data.msg)
              }
            })
          }
        })
    },
    handleEdit(row) {
      console.log(row)
      this.$router.push({
        path: `/danger_source_edit`,
        query: {
          id: row.id,
        },
      });
    },
    handleChangePage(e) {
      this.params.pageNumber = e.current;
      this.pagination.current = e.current;
      this.handleQuery();
    },
    // 获取工程管理部数据
    getgcglbList(path) {
      request(path, "get").then((res) => {
        let data = res.data.data.filter(item => item.id != "3da0f5533f87425c9a9e8a11a7923300")
        const arr = [];
        data.forEach((item) => {
          arr.push({
            name: item.master,
            value: item.id,
          });
        });
        this.gcglbList = arr;
      });
    },
    resetQuery() {
      this.monthPickerVal = null
      this.pagination.pageNumber = 1
      this.params = {
        month: '',
        gcglbId: undefined,
        projectName: '',
        year: '',
        pageNumber: 1,
        pageSize: 10,
      };
      this.handleQuery();
    },
    doDowload() {
      const _that = this
      this.$confirm({
        title: '导出上报后内容不可编辑，确认导出？',
        onOk() {
          let obj = _that.params;
          delete obj.pageNumber
          delete obj.pageSize
          _that.loadingText = "正在导出EXCEL文件，请稍候 ····";
          _that.isExporting = true;
          _that.cPercent = 0;
          let fileName = "危险源一览表.xlsx";
          _that.exportFunNomal2(
            dangerSourceExportUrl,
            obj,
            fileName,
            "application/vnd.ms-excel"
          );
        }
      })
    },
  },
};
</script>

<style lang="less" scoped>
@import "@/theme/common.less";
.query-form {
  display: flex;
  // justify-content: space-between;
}
</style>
