<template>
  <a-config-provider :locale="locale" :get-popup-container="popContainer" style="height: 100%;" >
    <div @mouseover="cmouseovered">
      <router-view style="height: 100%" />
    </div>
  </a-config-provider>
</template>

<script>
import {enquireScreen} from './utils/util'
import {mapState, mapMutations, mapGetters} from 'vuex'
import themeUtil from '@/utils/themeUtil';
import {getI18nKey} from '@/utils/routerUtil'
import {logout,loginOut} from '@/services/user'

export default {
  name: 'App',
  data() {
    return {
      locale: {},
      timeOut: 3 * 60 * 60 * 1000, // 3小时未操作自动退出登录
      timer: null
    }
  },
  async created () {
    this.setHtmlTitle()
    this.setLanguage(this.lang)
    enquireScreen(isMobile => this.setDevice(isMobile))
  },
  mounted() {
   this.setWeekModeTheme(this.weekMode)
   this.countTime()
  },
  watch: {
    weekMode(val) {
      this.setWeekModeTheme(val)
    },
    lang(val) {
      this.setLanguage(val)
      this.setHtmlTitle()
    },
    $route() {
      this.setHtmlTitle()
    },
    'theme.mode': function(val) {
      let closeMessage = this.$message.loading(`您选择了主题模式 ${val}, 正在切换...`)
      themeUtil.changeThemeColor(this.theme.color, val).then(closeMessage)
    },
    'theme.color': function(val) {
      let closeMessage = this.$message.loading(`您选择了主题色 ${val}, 正在切换...`)
      themeUtil.changeThemeColor(val, this.theme.mode).then(closeMessage)
    },
    'layout': function() {
      window.dispatchEvent(new Event('resize'))
    }
  },
  computed: {
    ...mapState('setting', ['layout', 'theme', 'weekMode', 'lang']),
    ...mapGetters('account', ['user']),
    systemName() {
      return this.$store.state.setting.systemName;
    },
  },
  methods: {
    ...mapMutations('setting', ['setDevice']),
    setWeekModeTheme(weekMode) {
      if (weekMode) {
        document.body.classList.add('week-mode')
      } else {
        document.body.classList.remove('week-mode')
      }
    },
    setLanguage(lang) {
      this.$i18n.locale = lang
      switch (lang) {
        case 'CN':
          this.locale = require('ant-design-vue/es/locale-provider/zh_CN').default
          break
        case 'HK':
          this.locale = require('ant-design-vue/es/locale-provider/zh_TW').default
          break
        case 'US':
        default:
          this.locale = require('ant-design-vue/es/locale-provider/en_US').default
          break
      }
    },
    setHtmlTitle() {
      const url = window.location.href// 当前页面的完整url路径
      const realmName = 'safe.' // 默认域名
      const realmType= url.indexOf(realmName)!==-1
      const route = this.$route
      const key = route.path === '/' ? 'home.name' : getI18nKey(route.matched[route.matched.length - 1].path)
      if(realmType){
        document.querySelector('link[rel="icon"]').setAttribute('href','https://res.gz-suijian.com/sjNews/img/titlelogo.png');
      }else{
        document.querySelector('link[rel="icon"]').setAttribute('href','https://res.gz-suijian.com/sjNews/img/laowu-login.png');
      }
      document.title = this.systemName + ' | ' + this.$t(key)
    },
    popContainer() {
      return document.getElementById("popContainer")
    },
    countTime() {
      const vm = this
      this.timer = setTimeout(function() {
        vm.logout()
      }, this.timeOut)
    },
    cmouseovered() { // 一段时间不操作自动退出系统
      if (this.timer) clearTimeout(this.timer)
      this.countTime()
    },
    logout() {
      const vm = this
      if (this.$route.name == '登录页') return 
      loginOut({userId: vm.user.id}).then(res => {
        logout()
      })
      this.$success({
        title: '您当前的会话已超时，请重新登录。',
        centered: true,
        okText:'确定',
        onOk() {
          vm.$router.push('/login')
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  #id{
  }
</style>
