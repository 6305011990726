<template>
  <div>
    <div class="constructiontTable_index">
      <!-- 表单封装 
                projectObj：下拉弹出框第一项对象
                Overview：项目名称
                baiduMapBoolen：控制地图显示
                topSubmit：控制上部分按钮显示
                bottomSubmit：控制下部分按钮显示
                handleSubmit：表单提交
                handleChange：输入出发
                handlSelect：输入下拉选择触发
            -->
      <form-index
        class="form"
        ref="formindex"
        :projectList="projectList"
        :formData="formData"
        Overview="单位管理/单位一览表"
        :baiduMapBoolen="false"
        @handleSubmit="handleSubmit"
        @handleChange="handleChange"
        @handlSelect="handlSelect"
        :flexSubmit="true"
      >
        <!-- 下部分按钮插槽 -->
        <template #flexSubmit>
          <a-button icon="search" type="primary" html-type="submit"> 查询 </a-button>
          <!-- <a-button @click="close"> 重置 </a-button> -->
          <a-space class="operator">
              <a-button @click="close"> 重置 </a-button>
            </a-space>
        </template>
      </form-index>
      <a-space class="operator">
        <a-button icon="plus" type="primary" @click="createItem"> 新建单位 </a-button>
        </a-space>
      <standard-table
        :columns="columns"
        :dataSource="dataSource"
        row-key="id"
        :scroll="{ x: 1300 }"
        :pagination="pagination"
        :loading="tableLoading"
        @change="onPageChange"
        :bordered="true"
      >
        <div slot="phone" slot-scope="{ text }">
          <span>{{ text ? text : "/" }}</span>
        </div>
        <div slot="userName" slot-scope="{ record }">
          <div v-if="record.userName">
            <div>{{ record.userName }}</div>
            <span v-if="record.userPhone">({{ record.userPhone }})</span>
          </div>
        </div>
        <div slot="action" slot-scope="{ record }">
          <div class="sTables">
            <a-popover placement="bottomLeft" overlayClassName="overlay-class">
              <template slot="content">
                <div style="display: flex; flex-direction: column">
                  <a-button
                    type="link"
                    icon="edit"
                    @click="viewEditing(record, 'edit')"
                    >修改</a-button
                  >
                  <a-button
                    type="link"
                    icon="delete"
                    @click="recorDelete(record)"
                    >删除</a-button
                  >
                  <a-button
                    type="link"
                    icon="plus"
                    @click="viewEditing(record, 'add')"
                    >添加下级机构</a-button
                  >
                </div>
              </template>
              <a-button type="link"
                >操作<a-icon type="double-right"
              /></a-button>
            </a-popover>
          </div>
        </div>
      </standard-table>
      <Export-loading
        :cPercent="cPercent"
        :isExporting="isExporting"
        :loadingText="loadingText"
      ></Export-loading>

      <a-modal
        :title="title"
        :visible.sync="visible"
        @ok="modelOk"
        @cancel="modelCancel"
        width="800px"
      >
        <form-index
          ref="formModal"
          :projectList="projectList"
          :formData="formModal"
          :baiduMapBoolen="false"
          @handleChange="handleChange"
          @handlSelect="handlSelect"
        >
        </form-index>
      </a-modal>
    </div>
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import {
  aqxjprojectsgetlist,
  aqxjprojectsdetail,
  aqxjProjectsupdate,
  aqxjprojectsdel,
  getLwProjectList,
  deleteLwProject,
  getLwProjectDetail,
  delOfficeMaster,
  getOfficeMaster,
} from "@/services/projectManagement";
import { request } from "@/utils/request";
import { exportProgress } from "@/pages/aqjyzl/export"; // 导出方法
import ExportLoading from "@/components/ExportLoading/ExportLoading"; // progress组件
import { PROJECT_OFFICE_CHILD, QUERY_OFFICE_BY_USER } from "@/services/api";
import { formatNumber } from "@/utils/whole";
import {
  projectMsgGetList,
  getLwDetail,
  getTreeOffice,
  addOfficeMaster,
} from "@/services/projectManagement";
import { mapGetters } from "vuex";
import { treeData } from "@/utils/treeutil";
import { sysUserGetList } from "@/services/message";
const columns = [
  {
    title: "单位名称",
    dataIndex: "name",
    width: 245,
    // ellipsis: true,
    scopedSlots: { customRender: "name" },
    // fixed: "left",
  },
  {
    title: "负责人",
    dataIndex: "master",
    align: "center",
    width: 65,
    key: "master",
  },
  {
    title: "联系电话",
    dataIndex: "phone",
    align: "center",
    width: 80,
    scopedSlots: { customRender: "phone" },
  },
  {
    title: "地址",
    align: "center",
    width: 100,
    dataIndex: "address",
    scopedSlots: { customRender: "address" },
  },
  {
    title: "操作",
    align: "center",
    width: 75,
    // fixed: "right",
    scopedSlots: { customRender: "action" },
  },
];
const formData = [
  {
    label: "公司名称",
    placeholder: "请输入公司名称",
    decorator: [
      "name",
      { rules: [{ required: false, message: "请输入公司名称" }] },
    ],
    type: "inputGroup",
    key: "name",
    selectlist: [],
    qb: true,
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
  },
  {
    label: "公司社会信用代码",
    placeholder: "请输入公司社会信用代码",
    decorator: [
      "code",
      { rules: [{ required: false, message: "请输入公司社会信用代码" }] },
    ],
    type: "inputGroup",
    key: "code",
    dataSource: [],
    col: 6,
    labelCol: 8,
    wrapperCol: 14,
  },
];
const formModal = [
  {
    label: "上级单位",
    placeholder: "请选择上级单位",
    decorator: [
      "id",
      { rules: [{ required: false, message: "请选择上级单位" }] },
    ],
    type: "treeSelect",
    key: "id",
    selectlist: [],
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "社会信用代码",
    placeholder: "请输入统一社会信用代码",
    decorator: [
      "code",
      { rules: [{ required: true, message: "请输入统一社会信用代码" }] },
    ],
    type: "input",
    key: "code",
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
  },
  {
    label: "公司名称",
    placeholder: "请输入公司名称",
    decorator: [
      "name",
      { rules: [{ required: true, message: "请输入公司名称" }] },
    ],
    type: "input",
    key: "name",
    col: 24,
    labelCol: 3,
    wrapperCol: 19,
  },
  // {
  //   label: "管理员",
  //   placeholder: "请输入公司管理员姓名",
  //   decorator: [
  //     "master",
  //     { rules: [{ required: true, message: "请输入公司管理员姓名" }] },
  //   ],
  //   type: "input",
  //   key: "master",
  //   col: 12,
  //   labelCol: 6,
  //   wrapperCol: 14,
  // },
  {
    label: "管理员",
    placeholder: "请输入公司管理员姓名",
    decorator: [
      "master",
      { rules: [{ required: true, message: "请输入公司管理员姓名" }] },
    ],
    type: "inputGroup",
    dataSource: [],
    key: "master",
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
  },
  {
    label: "联系电话",
    placeholder: "请输入联系电话",
    decorator: [
      "phone",
      { rules: [{ required: true, message: "请输入联系电话" }] },
    ],
    type: "input",
    key: "phone",
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
  },
  {
    label: "单位地址",
    placeholder: "请输入单位地址",
    decorator: [
      "address",
      { rules: [{ required: false, message: "请输入单位地址" }] },
    ],
    type: "input",
    key: "address",
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
  },
  {
    label: "运维到期时间",
    placeholder: "请选择运维到期时间",
    decorator: [
      "dutTime",
      { rules: [{ required: false, message: "请选择运维到期时间" }] },
    ],
    type: "datePicker",
    key: "dutTime",
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
    format: "yyyy-MM-DD",
    styles: "width: 220px",
    display: true,
  },
  {
    label: "管理员密码",
    placeholder: "修改管理员密码",
    decorator: [
      "userPwd",
      {
        rules: [
          {
            required: false,
            message: "字母、数字及特殊字符两种以上6-22位",
            pattern: new RegExp(
              /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)^\S{6,22}$/
            ),
          },
        ],
      },
    ],
    type: "input",
    key: "userPwd",
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "企业项目管理员用户姓名",
    placeholder: "请输入企业项目管理员用户姓名",
    decorator: [
      "userName",
      { rules: [{ required: false, message: "请输入企业项目管理员用户姓名" }] },
    ],
    type: "input",
    key: "userName",
    col: 12,
    labelCol: 0,
    wrapperCol: 0,
    display: false,
  },
  {
    label: "企业项目管理员用户姓名",
    placeholder: "请输入企业项目管理员用户姓名",
    decorator: [
      "userId",
      { rules: [{ required: false, message: "请输入企业项目管理员用户姓名" }] },
    ],
    type: "input",
    key: "userId",
    col: 12,
    labelCol: 0,
    wrapperCol: 0,
    display: false,
  },

  //
];
export default {
  mixins: [exportProgress], //导出方法注入
  components: {
    formIndex, //form表单封装
    StandardTable, //表格封装
    ExportLoading, //导出数据加载封装
  },
  data() {
    return {
      expandedRowKeys: [],
      selectedRows: [],
      columns: columns, //表格数据
      dataSource: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      tableLoading: false,
      formData: formData,
      formModal: formModal,
      prams: {
        pageNumber: 1,
        pageSize: 10,
        // id: this.user.officeId
      },
      projectList: [], //获取当前项目数据
      loadingText: "数据加载中...",
      cPercent: 0,
      isExporting: false,
      expandedKeys: [],
      officeTreeData: [],
      // 弹出层标题
      title: "修改公司机构",
      // 是否显示弹出层
      visible: false,
      // 新增或者修改
      type: "edit",
      // 单位详情
      detail: {},
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  created() {
    console.log("user", this.user);
    if (this.$route.query.pageNumber) {
      this.prams.pageNumber = this.$route.query.pageNumber;
    }
    console.log(this.isAdmin(), "是否是admin");
    if (this.isAdmin()) {
      this.prams.ownerOfficeId = this.user.officeId;
    }
    this.aqxjlist(this.prams);
  },
  methods: {
    // 判断是不是admin 账户
    isAdmin() {
      return this.user.officeLoginData.labourRoleKeyList.some((item) => {
        return item != "admin";
      });
      // console.log(isAdmin,'isAdmin')
    },
    // 封装弹窗确定按钮
    confirm(title, content) {
      return new Promise((resolve, reject) => {
        this.$confirm({
          title: title,
          content: content,
          onOk() {
            resolve();
          },
        });
      });
    },
    // 删除接口
    recorDelete(val) {
      let params = {
        id: val.id,
      };
      this.confirm("删除", `是否确认删除 ${val.name} 公司`).then(() => {
        delOfficeMaster(params).then((res) => {
          if (res.data.code === 0) {
            this.$message.success("删除成功", 10);
            this.aqxjlist(this.prams);
          } else {
            this.$message.error(res.data.msg, 10);
          }
        });
      });
    },
    // 更新接口
    update(e) {
      aqxjProjectsupdate(e).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("成功", 10);
        } else {
          this.$message.error(res.data.msg, 10);
        }
      });
    },
    handleChange(e) {
      this.projectArr = [];
      const name = {
        master: () => {
          this.getZdata(sysUserGetList, e, "nickName");
        },
      }[e.item.key]();
      const arr = ["userId"];
      arr.forEach((item) => {
        this.$refs["formindex"].setFieldsValue("", {}, item);
      });
      // 获取当前匹配key，赋值相对于数组
      this.formModal.forEach((item) => {
        if (e.item.key === item.key) {
          item.dataSource = this.projectArr;
        }
      });
    },
    // 接口封装
    /**
     * msg 接收接口名称
     * e 数据
     * name 参数名
     * */
    getZdata(msg, e, name) {
      let data = {
        [name]: e.value,
      };
      msg(data).then((res) => {
        if (res.status == 200) {
          const data = res.data.data.list ? res.data.data.list : res.data.data
          if (name == 'nickName') {
            name = 'nickname'
          }
          data.forEach((item) => {
            this.projectArr.push({
              title: item[name],
              id: item.id,
            });
          });
          this.projectList = data;
        }
      });
    },

    // 特殊数据处理
    handlSelect({ val, keys }) {
      const arr = ["parentId"];
      arr.forEach((item) => {
        this.$refs["formModal"].setFieldsValue("", {}, item);
      });
      console.log(val, keys, "val, keys");
      if (keys == "id") {
        this.officeTreeData.map((item) => {
          if (item.id == val) {
            this.$refs["formModal"].setFieldsValue("", { parentId: item.id });
          }
        });
      }
      this.projectList.forEach((item) => {
        // 选择企业项目管理员
        if (keys == "master" && item.id == val) {
          console.log(item, "item");
          this.$refs["formModal"].setFieldsValue("", { userId: item.id });
          this.$refs["formModal"].setFieldsValue("", { master: item.nickname });
        }
      });
    },
    aqxjlist(prams) {
      this.tableLoading = true;
      getTreeOffice(prams).then((res) => {
        this.dataSource = res.data.data;
        this.officeTreeData = res.data.data;
        this.formDataFn("id", res.data.data);
        this.tableLoading = false;
      });
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      console.log(data, "formDataFn");
      this.formModal.forEach((item) => {
        if (item.key == key) {
          item.selectlist = data;
        }
      });
    },
    // 提交表单数据
    handleSubmit(e) {
      console.log(e, "提交表单数据");
      let obj = Object.assign({}, e, this.prams);
      this.aqxjlist(obj);
    },
    // 重置
    close() {
      this.prams = {
        pageNumber: 1,
        pageSize: 10,
      };
      if (this.isAdmin()) {
        this.prams.ownerOfficeId = this.user.officeId;
      }
      this.aqxjlist(this.prams);
      // this.$refs["formindex"].setFieldsValue("", {}, "projectName");
      this.$refs["formindex"].resetFields();
    },
    //表格 - 分页查询
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      this.prams.pageNumber = e.current;
      this.pagination.current = e.current;
      this.aqxjlist(Object.assign({}, this.prams, obj));
    },
    updateSelectRows(e) {
      console.log(e);
    },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    onSelect(selectedKeys, info) {
      console.log(selectedKeys, "selectedKeys", info, "info");
      // this.queryParam.parentOfficeId = selectedKeys[0];
      // this.queryOfficeId(this.queryParam.parentOfficeId, null, null);
      // this.parentData();
    },
    // 查看编辑
    viewEditing(e, type) {
      if (type == "edit") {
        getOfficeMaster({ officeId: e.id }).then((res) => {
          this.detail = res.data.data;
          this.$nextTick(() => {
            this.$refs["formModal"].queryObj(res.data.data);
          });
        });
        this.type = "edit";
        this.title = "修改机构";
      } else if (type == "add") {
        this.type = "add";
        this.title = "新增机构";
        e = {
          id: e.id,
          parentId: e.id,
        };
        this.$nextTick(() => {
            this.$refs["formModal"].queryObj(e);
          });
      }
      this.visible = true;
    },
    // 新建项目
    createItem(e) {
      this.title = "新增公司机构";
      this.visible = true;
      this.type = "add";
    },
    // 确认弹窗
    modelOk(e) {
      this.$refs["formModal"].combinationSubmit().then((res) => {
        if (res.userPwd == null) {
          // 不修改密码删除字段
          delete res.userPwd;
        }
        if (this.type == "add") {
          // 新增删除掉id
          res.id = undefined;
          res.userName = res.master;
          res.userPhone = res.phone;
        }
        const params = {
          ...res,
        };
        console.log(params, "修改单位的数据");
        addOfficeMaster(params).then((result) => {
          console.log(result, "新增公司机构result===>");
          if (result.data.code === 0) {
            if (this.type == 'add') {
             this.$message.success('新增单位成功', 10);
            } else {
              this.$message.success('修改单位成功', 10);
            }
          } else {
            this.$message.success(result.data.msg, 10);
          }
          this.modelCancel();
        });
      });
    },
    // 关闭弹窗
    modelCancel(e) {
      console.log(e, "关闭弹窗");
      this.visible = false;
      this.aqxjlist(this.prams);
      this.$refs["formModal"].resetFields();
    },
  },
};
</script>

<style lang="less" scoped>
.constructiontTable_index {
  padding: 24px;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-bottom: 10px;

  .operator  {
    margin-left: 30px;
  }
  .c_i_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 25px;

    .c_i_h_center {
      padding: 16px;
      border-radius: 4px;
      width: 180px;
      height: 130px;
      margin-right: 50px;

      div:nth-child(1) {
        font-size: 16px;
      }

      div:nth-child(2) {
        margin-top: 26px;
        font-size: 24px;
      }
    }
  }
}

.sTable {
  display: flex;
  justify-content: space-between;
  align-items: center;

  // div {
  //     width: 100%;
  //     color: blue;
  //     text-align: center;
  //     cursor: pointer;
  // }
}

.sTables {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;

  div {
    // width: 100%;
    padding: 0 10px;
    text-align: center;
    cursor: pointer;
  }
}

.ant-btn-link {
  color: rgb(118, 131, 143);
  text-align: left;
  padding: 0;
}
.ant-btn-link:hover {
  color: red;
}


.operator {
    margin-right: 8px;

    .ant-btn {
      color: #307dfa;
      border-color: #307dfa;
    }
  }
</style>
