<template>
  <div>
    <a-drawer
      :width="1244"
      :visible="visible"
      title="企业库班组信息"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose"
    > 
    <a-row :gutter="16">
      <a-col :span="6">
        <vue-scroll :ops="ops" style="width:100%;height:855px">
        <a-card title="工种" size="small" :bordered="false" >
          <a-tree :load-data="onLoadData" :tree-data="treeData" @select="onSelect" >
            <template slot="title" slot-scope="{ title }">
                <span>{{ title }}</span>
            </template>
          </a-tree>
        </a-card>
        </vue-scroll>
      </a-col>
        <a-col :span="17" :offset="0">
            <a-card  :bordered="false">
              <template slot="title" >
                <span>{{node.title}}班组清单</span>
                </template>
                <a-form layout="horizontal">
                  <div :class="advanced ? null: 'fold'">
                    <a-row >
                      <a-col :md="12" :sm="24" >
                        <a-form-item
                          label="班组名称"
                          :labelCol="{span: 5}"
                          :wrapperCol="{span: 18, offset: 1}"
                        >
                          <a-input placeholder="请输入班组名称" v-model="queryParam.teamName"/>
                        </a-form-item>
                      </a-col>
                      <a-col :md="12" :sm="24" >
                        <a-form-item
                          label="负责人"
                          :labelCol="{span: 5}"
                          :wrapperCol="{span: 18, offset: 1}"
                        >
                          <a-input placeholder="请负责人姓名" v-model="queryParam.fzrUserName"/>
                        </a-form-item>
                      </a-col>
                    </a-row >
                  </div>
                  <span style="float:right">
                    <a-button type="primary" @click="doQuery()">查询</a-button>
                  </span>
                </a-form>
              <a-row style="margin-right:-60px">
                <a-col :md="24" :sm="24" >
                  <standard-table
                    :columns="columns"
                    :dataSource="teams_data"
                    :selectedRows.sync="selectedRows"
                    :pagination="pagination"
                    :loading="tableLoading"
                    :isAlert="false"
                    @clear="onClear"
                    @change="onPageChange"
                  >
                    <div slot="action" slot-scope="{text, record}">
                      <a-icon style="color:#FC5531" type="unordered-list" @click="viewPersons(record)" title="查看人员列表"></a-icon>
                    </div>
                    <template slot="statusTitle">
                      <a-icon @click.native="onStatusTitleClick" type="info-circle" />
                    </template>
                  </standard-table>
                 </a-col>
              </a-row>
            </a-card>
        </a-col>
    </a-row>
    <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginLeft: '8px' }" type="primary" @click="doSubmit">
          确定
        </a-button>
        <a-button :style="{ marginLeft: '8px' }" @click="onClose">
          关闭
        </a-button>
      </div>
    </a-drawer>
  </div>
</template>


<script>
import {
  JOB_TYPE_WORK_QUERY, 
  JOB_JOBTITLES_QUERY,
  TEAM_JOBTITLES_QUERY,
  } from '@/services/api'
  
import {request} from '@/utils/request'
import StandardTable from '@/components/table/StandardTable'
const columns = [
  { title: '班组名称', dataIndex: 'teamName',key:'1', align: 'center'},
  { title: '负责人', dataIndex: 'fzrUserName',key:'2', align: 'center' },
  { title: '负责人电话', dataIndex: 'fzrPhone',key:'3' , align: 'center'},
  { title: '在场/总人数', dataIndex: 'inTotal',key:'4' , align: 'center'},
  { id:4,title: '操作', dataIndex: '',key:'5', scopedSlots: { customRender: 'action' }, align: 'center' },
];

export default {
  name: 'QueryList',
  components: {StandardTable},
  // components: {StandardTable},
  data() {
    return {
      teams_data:[],
      columns,
      selectedRows: [],
      tableLoading: false,
      advanced:false,
      queryParam:{
        phone:null,
        userName:null,
        pageNumber:0,
      },
      autoExpandParent: true,
      // 树形结构
      expandedKeys: [],
      treeData: [],
      node:{},
      pagination:{
        current:1,
        pageSize:10,
        total:0,
        showLessItems:true,
        showQuickJumper:true
      },
      teamEditor:{
        visible:false,
        teamInfo:{},
        typeWorkDataSource:[]
      },
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: "#4A86EB",//滚动条颜色
          opacity: 0.8,//滚动条透明度
          "overflow-x": "hidden"
        }
      }
    };
  },
   props: {
    visible: {
      type: Boolean,
      default: false
    },
    idCard:{
      type: String,
      require:true
    },
    userName:{
      type: String,
      require:true
    }
  },
  created() {
    this.queryTypeWork();
    this.doQuery();
  },
  methods: {
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    doReset(){
      this.queryParam={};
      this.doQuery();
    },
    doImport(){

    },
    //分页查询
    onPageChange(e){
      this.queryParam.pageNumber = e.current;
      this.pagination.current=e.current;
      this.doQuery();
    },
    onLoadData(treeNode) {
      return new Promise(resolve => {
        setTimeout(() => {
          request(JOB_JOBTITLES_QUERY,"post",{typeWorkId:treeNode.dataRef.id}).then(res => {
            if(res.data.code==0){
              treeNode.dataRef.children = [];
              res.data.data.forEach(obj=>{
                obj.isLeaf = true;
                obj.title = obj.name;
                obj.key = `${treeNode.eventKey}-${obj.id}`
                obj.nodeType = "2";
                treeNode.dataRef.children.push(obj);
              })
            }
            this.treeData = [...this.treeData];
            resolve();
          })
        }, 500);
      });
    },
    onSelect(selectedKeys,e){
      this.tableLoading = true;
      if(e.node&&e.node.dataRef){
        this.node = e.node.dataRef
      }
      let typeWorkId = null;
      if(this.node&&this.node.nodeType==1){
        typeWorkId = this.node.id
      }
      let totitleId = null;
      if( this.node&&this.node.nodeType==2){
        totitleId = this.node.id
      }
      let obj = {
        typeWorkId:typeWorkId,
        jobtitleId:totitleId
      }
      request(TEAM_JOBTITLES_QUERY,"post",Object.assign(obj, this.queryParam)).then(res => {
          if(res.data.code==0){
            res.data.data.forEach(obj=>{
              obj.key = obj.id
            })
            this.pagination.total = res.data.data.total;
            this.teams_data = res.data.data.list;
          }
       })
      this.tableLoading = false;
    },
    doQuery(){
      this.tableLoading = true;
      let typeWorkId = null;
      if( this.node&&this.node.nodeType==1){
        typeWorkId = this.node.id
      }
      let totitleId = null;
      if( this.node&&this.node.nodeType==2){
        totitleId = this.node.id
      }
      let obj = {
        typeWorkId:typeWorkId,
        jobtitleId:totitleId
      }
      request(TEAM_JOBTITLES_QUERY,"post",Object.assign(obj, this.queryParam)).then(res => {
            if(res.data.code==0){
              res.data.data.forEach(obj=>{
                obj.key = obj.id
              })
              this.teams_data = res.data.data.list;
              this.pagination.total = res.data.data.total;
            }
       })
       this.tableLoading = false;
    },
    onExpand(){

    },
    queryTypeWork(){
      request(JOB_TYPE_WORK_QUERY,"post",{}).then(res => {
        if(res.data.code==0){
          this.treeData = res.data.data;
          this.teamEditor.typeWorkDataSource=res.data.data;
          this.treeData.forEach(obj=>{
            obj.title = obj.name;
            obj.key = obj.id;
            obj.isLeaf = false;
            obj.nodeType = "1"
          })
        }
      })
    },
    /**班组操作start */
    
    doOpenTeam(team){
      this.teamEditor.teamInfo = team;
      this.teamEditor.visible=true
    },
    viewPersons($team){
      this.$emit('viewTeamPersons',{team:$team})
    },
    /**班组操作end */

    onClose(){
      this.$emit('teamChangeClose');
    },
    doSubmit(){
      if(!this.selectedRows&&this.selectedRows.length<=0){
        this.$message.error("请选择需要调换的班组！")
      }
      let teamName = "";
      let obj = [];
      this.selectedRows.forEach(function(val,index){
          teamName += `【${val.teamName}】`
          obj.push(val.key);
      })
      var _that = this
      console.log(typeof teamName)
      this.$confirm({
          title: '确认调换班组',
          content: h => <div>您即将把{_that.userName}调班为：<br></br><div style="color:red;">{teamName}</div></div>,
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk() {
            _that.$emit('submit',{teamIds:obj,idCard:_that.idCard});
          },
          onCancel() {
              console.log('Cancel');
          },
      })
    },
  },
}
</script>

<style lang="less" scoped>
  .search{
    margin-bottom: 54px;
  }
  .fold{
    width: calc(100% - 216px);
    display: inline-block
  }
  .operator{
    margin-bottom: 18px;
  }
  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
</style>
