var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-drawer",
        {
          attrs: {
            width: 1024,
            visible: _vm.visible,
            title: "自动生成试卷的相关配置",
            footer: null,
            "body-style": { paddingBottom: "80px" },
          },
          on: { close: _vm.onClose },
        },
        [
          _c(
            "a-steps",
            {
              attrs: { type: "navigation", size: "small" },
              model: {
                value: _vm.current,
                callback: function ($$v) {
                  _vm.current = $$v
                },
                expression: "current",
              },
            },
            [
              _c("a-step", {
                attrs: { title: "第一步", "sub-title": "配置生成规则" },
              }),
              _c("a-step", {
                attrs: { title: "第二步", "sub-title": "试题编辑" },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.current == 0,
                  expression: "current==0",
                },
              ],
            },
            [
              _c(
                "a-form",
                {
                  attrs: {
                    form: _vm.form,
                    "label-col": { span: 4 },
                    "wrapper-col": { span: 19 },
                    layout: "horizontal",
                  },
                },
                [
                  _c(
                    "a-row",
                    { attrs: { gutter: 16 } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "所属课程" } },
                            [
                              _c(
                                "a-select",
                                {
                                  directives: [
                                    {
                                      name: "decorator",
                                      rawName: "v-decorator",
                                      value: [
                                        "courseId",
                                        {
                                          rules: [
                                            {
                                              required: true,
                                              message: "请选择课程",
                                            },
                                          ],
                                        },
                                      ],
                                      expression:
                                        "['courseId', { rules: [{ required: true, message: '请选择课程' }] }]",
                                    },
                                  ],
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    allowClear: true,
                                    placeholder: "请选择课程",
                                  },
                                  on: { change: _vm.changeCourse },
                                },
                                _vm._l(_vm.courseDataSource, function (t) {
                                  return _c(
                                    "a-select-option",
                                    { key: t.id, attrs: { value: t.id } },
                                    [_vm._v(_vm._s(t.name))]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    { attrs: { gutter: 16 } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "试卷标题：" } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "title",
                                      {
                                        rules: [
                                          {
                                            required: true,
                                            message: "请输入试卷标题",
                                          },
                                        ],
                                      },
                                    ],
                                    expression:
                                      "['title', { rules: [{  required: true, message: '请输入试卷标题' }] }]",
                                  },
                                ],
                                attrs: { placeholder: "试卷标题" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    { attrs: { gutter: 16 } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "课时标签" } },
                            [
                              _c(
                                "a-select",
                                {
                                  attrs: {
                                    mode: "tags",
                                    placeholder: "请您选择课时标签",
                                  },
                                  on: { change: _vm.handleChange },
                                  model: {
                                    value: _vm.tagsArray,
                                    callback: function ($$v) {
                                      _vm.tagsArray = $$v
                                    },
                                    expression: "tagsArray",
                                  },
                                },
                                _vm._l(_vm.hourDataSource, function (item) {
                                  return _c(
                                    "a-select-option",
                                    { key: item.id },
                                    [_vm._v(" " + _vm._s(item.name) + " ")]
                                  )
                                }),
                                1
                              ),
                              _c("span", { staticStyle: { color: "#999" } }, [
                                _vm._v(
                                  "备注：选择了课时标签，只会在选中的标签中抓取题目，不填则从整个课程中抓取题目"
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    { attrs: { gutter: 16 } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "试卷说明：" } },
                            [
                              _c("a-textarea", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "pExplain",
                                      {
                                        rules: [{ message: "请输入试卷说明" }],
                                      },
                                    ],
                                    expression:
                                      "['pExplain', { rules: [{  message: '请输入试卷说明' }] }]",
                                  },
                                ],
                                attrs: {
                                  placeholder: "试卷说明",
                                  "auto-size": { minRows: 3, maxRows: 5 },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    { attrs: { gutter: 16 } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "生成方式：" } },
                            [
                              _c(
                                "a-radio-group",
                                {
                                  directives: [
                                    {
                                      name: "decorator",
                                      rawName: "v-decorator",
                                      value: ["doType"],
                                      expression: "['doType']",
                                    },
                                  ],
                                  on: { change: _vm.changeDoType },
                                },
                                [
                                  _c(
                                    "a-radio-button",
                                    { attrs: { value: 2 } },
                                    [_vm._v(" 配置生成 ")]
                                  ),
                                  _c(
                                    "a-radio-button",
                                    { attrs: { value: 1 } },
                                    [_vm._v(" 随机生成 ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.doType == 1
                    ? _c(
                        "a-row",
                        { attrs: { gutter: 16 } },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    label: "单选题(" + _vm.sigleCntMax + ")：",
                                  },
                                },
                                [
                                  _vm._v(" 题目数量："),
                                  _c("a-input-number", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "singleCnt",
                                          { initialValue: 0 },
                                        ],
                                        expression:
                                          "['singleCnt',{ initialValue: 0 }]",
                                      },
                                    ],
                                    attrs: { min: 0, max: _vm.sigleCntMax },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeQuests(
                                          $event,
                                          "singleCnt"
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" 每题分值："),
                                  _c("a-input-number", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "singleCores1",
                                          { initialValue: 2 },
                                        ],
                                        expression:
                                          "['singleCores1',{ initialValue: 2 }]",
                                      },
                                    ],
                                    attrs: { min: 1, max: 20 },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeQuests(
                                          $event,
                                          "singleCores1"
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(
                                    " 题型占比：" + _vm._s(_vm.sigleP1) + "% "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.doType == 1
                    ? _c(
                        "a-row",
                        { attrs: { gutter: 16 } },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    label:
                                      "多选题(" + _vm.multipleCntMax + ")：",
                                  },
                                },
                                [
                                  _vm._v(" 题目数量："),
                                  _c("a-input-number", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "multipleCnt",
                                          { initialValue: 0 },
                                        ],
                                        expression:
                                          "['multipleCnt',{ initialValue: 0 }]",
                                      },
                                    ],
                                    attrs: { min: 0, max: _vm.multipleCntMax },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeQuests(
                                          $event,
                                          "multipleCnt"
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" 每题分值："),
                                  _c("a-input-number", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "multipleCores1",
                                          { initialValue: 2 },
                                        ],
                                        expression:
                                          "['multipleCores1',{ initialValue: 2 }]",
                                      },
                                    ],
                                    attrs: { min: 1, max: 20 },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeQuests(
                                          $event,
                                          "multipleCores1"
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(
                                    " 题型占比：" +
                                      _vm._s(_vm.multipleP1) +
                                      "% "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.doType == 1
                    ? _c(
                        "a-row",
                        { attrs: { gutter: 16 } },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    label: "判断题(" + _vm.judgeCntMax + ")：",
                                  },
                                },
                                [
                                  _vm._v(" 题目数量："),
                                  _c("a-input-number", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "judgeCnt",
                                          { initialValue: 0 },
                                        ],
                                        expression:
                                          "['judgeCnt',{ initialValue: 0 }]",
                                      },
                                    ],
                                    attrs: { min: 0, max: _vm.judgeCntMax },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeQuests(
                                          $event,
                                          "judgeCnt"
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" 每题分值："),
                                  _c("a-input-number", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "judgeCores1",
                                          { initialValue: 2 },
                                        ],
                                        expression:
                                          "['judgeCores1',{ initialValue: 2 }]",
                                      },
                                    ],
                                    attrs: { min: 1, max: 20 },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeQuests(
                                          $event,
                                          "judgeCores1"
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(
                                    " 题型占比：" + _vm._s(_vm.judgeP1) + "% "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.doType == 2
                    ? _c(
                        "a-row",
                        { attrs: { gutter: 16 } },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "a-form-item",
                                { attrs: { label: "单选题：" } },
                                [
                                  _vm._v(
                                    " 容易(" +
                                      _vm._s(_vm.sigleQ.easyMax) +
                                      ")："
                                  ),
                                  _c("a-input-number", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: ["sigleSet.easy"],
                                        expression: "['sigleSet.easy']",
                                      },
                                    ],
                                    attrs: { min: 0, max: _vm.sigleQ.easyMax },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeQuests(
                                          $event,
                                          "sigleSetEasy"
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(
                                    " 一般(" +
                                      _vm._s(_vm.sigleQ.commonMax) +
                                      ")："
                                  ),
                                  _c("a-input-number", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: ["sigleSet.common"],
                                        expression: "['sigleSet.common']",
                                      },
                                    ],
                                    attrs: {
                                      min: 0,
                                      max: _vm.sigleQ.commonMax,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeQuests(
                                          $event,
                                          "sigleSetCommon"
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(
                                    " 困难(" +
                                      _vm._s(_vm.sigleQ.difficultyMax) +
                                      ")："
                                  ),
                                  _c("a-input-number", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: ["sigleSet.difficulty"],
                                        expression: "['sigleSet.difficulty']",
                                      },
                                    ],
                                    attrs: {
                                      min: 0,
                                      max: _vm.sigleQ.difficultyMax,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeQuests(
                                          $event,
                                          "sigleSetDifficulty"
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" 每题分值："),
                                  _c("a-input-number", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: ["singleCores"],
                                        expression: "['singleCores']",
                                      },
                                    ],
                                    attrs: { min: 1, max: 20 },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeQuests(
                                          $event,
                                          "singleCores"
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" 占比：" + _vm._s(_vm.sigleP) + "% "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.doType == 2
                    ? _c(
                        "a-row",
                        { attrs: { gutter: 16 } },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "a-form-item",
                                { attrs: { label: "多选题：" } },
                                [
                                  _vm._v(
                                    " 容易(" +
                                      _vm._s(_vm.multipleQ.easyMax) +
                                      ")："
                                  ),
                                  _c("a-input-number", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: ["multipleSet.easy"],
                                        expression: "['multipleSet.easy']",
                                      },
                                    ],
                                    attrs: {
                                      min: 0,
                                      max: _vm.multipleQ.easyMax,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeQuests(
                                          $event,
                                          "multipleSetEasy"
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(
                                    " 一般(" +
                                      _vm._s(_vm.multipleQ.commonMax) +
                                      ")："
                                  ),
                                  _c("a-input-number", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: ["multipleSet.common"],
                                        expression: "['multipleSet.common']",
                                      },
                                    ],
                                    attrs: {
                                      min: 0,
                                      max: _vm.multipleQ.commonMax,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeQuests(
                                          $event,
                                          "multipleSetCommon"
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(
                                    " 困难(" +
                                      _vm._s(_vm.multipleQ.difficultyMax) +
                                      ")："
                                  ),
                                  _c("a-input-number", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: ["multipleSet.difficulty"],
                                        expression:
                                          "['multipleSet.difficulty']",
                                      },
                                    ],
                                    attrs: {
                                      min: 0,
                                      max: _vm.multipleQ.difficultyMax,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeQuests(
                                          $event,
                                          "multipleSetDifficulty"
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" 每题分值："),
                                  _c("a-input-number", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: ["multipleCores"],
                                        expression: "['multipleCores']",
                                      },
                                    ],
                                    attrs: { min: 1, max: 20 },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeQuests(
                                          $event,
                                          "multipleCores"
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(
                                    " 占比：" + _vm._s(_vm.multipleP) + "% "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.doType == 2
                    ? _c(
                        "a-row",
                        { attrs: { gutter: 16 } },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "a-form-item",
                                { attrs: { label: "判断题：" } },
                                [
                                  _vm._v(
                                    " 容易(" +
                                      _vm._s(_vm.judgeQ.easyMax) +
                                      ")："
                                  ),
                                  _c("a-input-number", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: ["judgeSet.easy"],
                                        expression: "['judgeSet.easy']",
                                      },
                                    ],
                                    attrs: { min: 0, max: _vm.judgeQ.easyMax },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeQuests(
                                          $event,
                                          "judgeSetEasy"
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(
                                    " 一般(" +
                                      _vm._s(_vm.judgeQ.commonMax) +
                                      ")："
                                  ),
                                  _c("a-input-number", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: ["judgeSet.common"],
                                        expression: "['judgeSet.common']",
                                      },
                                    ],
                                    attrs: {
                                      min: 0,
                                      max: _vm.judgeQ.commonMax,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeQuests(
                                          $event,
                                          "judgeSetCommon"
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(
                                    " 困难(" +
                                      _vm._s(_vm.judgeQ.difficultyMax) +
                                      ")："
                                  ),
                                  _c("a-input-number", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: ["judgeSet.difficulty"],
                                        expression: "['judgeSet.difficulty']",
                                      },
                                    ],
                                    attrs: {
                                      min: 0,
                                      max: _vm.judgeQ.difficultyMax,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeQuests(
                                          $event,
                                          "judgeSetDifficulty"
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" 每题分值："),
                                  _c("a-input-number", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: ["judgeCores"],
                                        expression: "['judgeCores']",
                                      },
                                    ],
                                    attrs: { min: 1, max: 20 },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeQuests(
                                          $event,
                                          "judgeCores"
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" 占比：" + _vm._s(_vm.judgeP) + "% "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.doType == 2
                    ? _c(
                        "div",
                        { staticStyle: { "margin-left": "20%", width: "90%" } },
                        [
                          _c("span", [
                            _vm._v(
                              "总题目数：" + _vm._s(_vm.totalQuest2) + "题"
                            ),
                          ]),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "200px" } },
                            [
                              _vm._v(
                                "总分数：" + _vm._s(_vm.totalCores2) + "分"
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.doType == 1
                    ? _c(
                        "div",
                        { staticStyle: { "margin-left": "20%", width: "90%" } },
                        [
                          _c("span", [
                            _vm._v(
                              "总题目数：" + _vm._s(_vm.totalQuest1) + "题"
                            ),
                          ]),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "200px" } },
                            [
                              _vm._v(
                                "总分数：" + _vm._s(_vm.totalCores1) + "分"
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    "text-align": "center",
                    "margin-top": "35px",
                  },
                },
                [
                  _c(
                    "a-button",
                    {
                      style: { marginLeft: "8px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.nextStep },
                    },
                    [_vm._v(" 保存，下一步 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.current == 1,
                  expression: "current==1",
                },
              ],
            },
            [
              _c(
                "div",
                [
                  _c("a-divider", [_vm._v("试卷题目")]),
                  _c(
                    "div",
                    { staticStyle: { width: "98%" } },
                    [
                      _c(
                        "a-tabs",
                        {
                          attrs: { activeKey: _vm.currentTab },
                          on: { change: _vm.callback },
                        },
                        [
                          _c(
                            "a-tab-pane",
                            { key: 1 },
                            [
                              _c("template", { slot: "tab" }, [
                                _vm._v(
                                  "单选题（" +
                                    _vm._s(_vm.sigleQuests.length) +
                                    "题/" +
                                    _vm._s(_vm.sigleCore) +
                                    "分）"
                                ),
                              ]),
                              _c(
                                "vue-scroll",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    height: "520px",
                                  },
                                  attrs: { ops: _vm.ops },
                                },
                                [
                                  _c("a-list", {
                                    attrs: {
                                      size: "small",
                                      "data-source": _vm.sigleQuests,
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "renderItem",
                                        fn: function (item, index) {
                                          return _c("a-list-item", {}, [
                                            _vm._v(
                                              " " +
                                                _vm._s(index + 1) +
                                                "、【" +
                                                _vm._s(
                                                  item.level == 1
                                                    ? "简单"
                                                    : item.level == 2
                                                    ? "一般"
                                                    : "困难"
                                                ) +
                                                "】" +
                                                _vm._s(item.questCont) +
                                                "(" +
                                                _vm._s(item.newcore) +
                                                "分) "
                                            ),
                                            index !== 0
                                              ? _c(
                                                  "a",
                                                  {
                                                    attrs: { slot: "actions" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.upClick(
                                                          1,
                                                          index
                                                        )
                                                      },
                                                    },
                                                    slot: "actions",
                                                  },
                                                  [
                                                    _c("a-icon", {
                                                      attrs: {
                                                        type: "arrow-up",
                                                        title: "上移",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            index !== _vm.sigleQuests.length - 1
                                              ? _c(
                                                  "a",
                                                  {
                                                    attrs: { slot: "actions" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.downClick(
                                                          1,
                                                          index
                                                        )
                                                      },
                                                    },
                                                    slot: "actions",
                                                  },
                                                  [
                                                    _c("a-icon", {
                                                      attrs: {
                                                        type: "arrow-down",
                                                        title: "下移",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ])
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                          _c(
                            "a-tab-pane",
                            { key: 2, attrs: { "force-render": "" } },
                            [
                              _c("template", { slot: "tab" }, [
                                _vm._v(
                                  "多选题（" +
                                    _vm._s(_vm.multipleQuests.length) +
                                    "题/" +
                                    _vm._s(_vm.multipleCore) +
                                    "分）"
                                ),
                              ]),
                              _c(
                                "vue-scroll",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    height: "520px",
                                  },
                                  attrs: { ops: _vm.ops },
                                },
                                [
                                  _c("a-list", {
                                    attrs: {
                                      size: "small",
                                      "data-source": _vm.multipleQuests,
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "renderItem",
                                        fn: function (item, index) {
                                          return _c("a-list-item", {}, [
                                            _vm._v(
                                              " " +
                                                _vm._s(index + 1) +
                                                "、【" +
                                                _vm._s(
                                                  item.level == 1
                                                    ? "简单"
                                                    : item.level == 2
                                                    ? "一般"
                                                    : "困难"
                                                ) +
                                                "】" +
                                                _vm._s(item.questCont) +
                                                "(" +
                                                _vm._s(item.newcore) +
                                                "分) "
                                            ),
                                            index !== 0
                                              ? _c(
                                                  "a",
                                                  {
                                                    attrs: { slot: "actions" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.upClick(
                                                          2,
                                                          index
                                                        )
                                                      },
                                                    },
                                                    slot: "actions",
                                                  },
                                                  [
                                                    _c("a-icon", {
                                                      attrs: {
                                                        type: "arrow-up",
                                                        title: "上移",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            index !==
                                            _vm.multipleQuests.length - 1
                                              ? _c(
                                                  "a",
                                                  {
                                                    attrs: { slot: "actions" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.downClick(
                                                          2,
                                                          index
                                                        )
                                                      },
                                                    },
                                                    slot: "actions",
                                                  },
                                                  [
                                                    _c("a-icon", {
                                                      attrs: {
                                                        type: "arrow-down",
                                                        title: "下移",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ])
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                          _c(
                            "a-tab-pane",
                            { key: 3 },
                            [
                              _c("template", { slot: "tab" }, [
                                _vm._v(
                                  "判断题（" +
                                    _vm._s(_vm.judgeQuests.length) +
                                    "题/" +
                                    _vm._s(_vm.judgeCore) +
                                    "分）"
                                ),
                              ]),
                              _c(
                                "vue-scroll",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    height: "520px",
                                  },
                                  attrs: { ops: _vm.ops },
                                },
                                [
                                  _c("a-list", {
                                    attrs: {
                                      size: "small",
                                      "data-source": _vm.judgeQuests,
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "renderItem",
                                        fn: function (item, index) {
                                          return _c("a-list-item", {}, [
                                            _vm._v(
                                              " " +
                                                _vm._s(index + 1) +
                                                "、【" +
                                                _vm._s(
                                                  item.level == 1
                                                    ? "简单"
                                                    : item.level == 2
                                                    ? "一般"
                                                    : "困难"
                                                ) +
                                                "】" +
                                                _vm._s(item.questCont) +
                                                "(" +
                                                _vm._s(item.newcore) +
                                                "分) "
                                            ),
                                            index !== 0
                                              ? _c(
                                                  "a",
                                                  {
                                                    attrs: { slot: "actions" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.upClick(
                                                          3,
                                                          index
                                                        )
                                                      },
                                                    },
                                                    slot: "actions",
                                                  },
                                                  [
                                                    _c("a-icon", {
                                                      attrs: {
                                                        type: "arrow-up",
                                                        title: "上移",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            index !== _vm.judgeQuests.length - 1
                                              ? _c(
                                                  "a",
                                                  {
                                                    attrs: { slot: "actions" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.downClick(
                                                          3,
                                                          index
                                                        )
                                                      },
                                                    },
                                                    slot: "actions",
                                                  },
                                                  [
                                                    _c("a-icon", {
                                                      attrs: {
                                                        type: "arrow-down",
                                                        title: "下移",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ])
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    "text-align": "center",
                    "margin-top": "35px",
                  },
                },
                [
                  _c(
                    "a-button",
                    {
                      style: { marginLeft: "8px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.doSubmit },
                    },
                    [_vm._v(" 好的，生成试卷 ")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }