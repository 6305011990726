var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-drawer",
        {
          attrs: {
            width: 800,
            visible: _vm.visible,
            title: "编辑房间",
            "body-style": { paddingBottom: "80px" },
          },
          on: { close: _vm.onClose },
        },
        [
          _c(
            "a-form",
            {
              attrs: {
                form: _vm.form,
                "label-col": { span: 5 },
                "wrapper-col": { span: 18 },
                layout: "horizontal",
              },
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "房间号：" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "roomNo",
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        message: "请输入房间号",
                                      },
                                    ],
                                  },
                                ],
                                expression:
                                  "['roomNo', { rules: [{ required: true, message: '请输入房间号' }] }]",
                              },
                            ],
                            attrs: { placeholder: "房间号" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "房间类型" } },
                        [
                          _c(
                            "a-radio-group",
                            {
                              on: { change: _vm.changeRoomType },
                              model: {
                                value: _vm.type,
                                callback: function ($$v) {
                                  _vm.type = $$v
                                },
                                expression: "type",
                              },
                            },
                            [
                              _c("a-radio", { attrs: { value: 0 } }, [
                                _vm._v(" 普通房间 "),
                              ]),
                              _c("a-radio", { attrs: { value: 1 } }, [
                                _vm._v(" 夫妻房 "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "可容纳人数:" } },
                        [
                          _c("a-input-number", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: ["personsNo"],
                                expression: "['personsNo']",
                              },
                            ],
                            attrs: { placeholder: "请输可容纳人数" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "所在楼层：" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "floor",
                                  { rules: [{ message: "请输入所在楼层" }] },
                                ],
                                expression:
                                  "['floor', { rules: [{  message: '请输入所在楼层' }] }]",
                              },
                            ],
                            attrs: { placeholder: "请输入所在楼层" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "是否投入使用" } },
                        [
                          _c(
                            "a-radio-group",
                            {
                              model: {
                                value: _vm.status,
                                callback: function ($$v) {
                                  _vm.status = $$v
                                },
                                expression: "status",
                              },
                            },
                            [
                              _c("a-radio", { attrs: { value: 0 } }, [
                                _vm._v(" 暂不可用 "),
                              ]),
                              _c("a-radio", { attrs: { value: 1 } }, [
                                _vm._v(" 可使用 "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "备注信息" } },
                        [
                          _c("a-textarea", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "remark",
                                  { rules: [{ message: "备注信息" }] },
                                ],
                                expression:
                                  "['remark', { rules: [{  message: '备注信息' }] }]",
                              },
                            ],
                            attrs: {
                              placeholder: "备注信息",
                              "auto-size": { minRows: 5 },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              style: {
                position: "absolute",
                right: 0,
                bottom: 0,
                width: "100%",
                borderTop: "1px solid #e9e9e9",
                padding: "10px 16px",
                background: "#fff",
                textAlign: "right",
                zIndex: 1,
              },
            },
            [
              _c(
                "a-button",
                {
                  style: { marginLeft: "8px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.doSubmit },
                },
                [_vm._v(" 确定 ")]
              ),
              _c(
                "a-button",
                { style: { marginLeft: "8px" }, on: { click: _vm.onClose } },
                [_vm._v(" 关闭 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }