var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          staticStyle: { top: "20px" },
          attrs: { title: "账号关联列表", width: 1400 },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c(
            "a-row",
            [
              _c(
                "a-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.add()
                    },
                  },
                },
                [_vm._v(" 新增绑定 ")]
              ),
            ],
            1
          ),
          _c("a-table", {
            attrs: { columns: _vm.columns, "data-source": _vm.data },
            scopedSlots: _vm._u([
              {
                key: "relation",
                fn: function (relation, record) {
                  return _vm._l(record.laoWuUserList, function (item, index) {
                    return _c("a-tag", { key: index }, [
                      _vm._v(
                        " " +
                          _vm._s(item.loginName) +
                          "(" +
                          _vm._s(item.roleName) +
                          ") "
                      ),
                    ])
                  })
                },
              },
              {
                key: "action",
                fn: function (text, record) {
                  return [
                    _c(
                      "span",
                      [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.edit(record)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c("a-divider", { attrs: { type: "vertical" } }),
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.del(record)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "编辑",
            visible: _vm.visibleEdit,
            "confirm-loading": _vm.confirmLoading,
            width: "560px",
          },
          on: { ok: _vm.projectBingUser, cancel: _vm.closeEdit },
        },
        [
          _vm.visible
            ? _c(
                "a-form-model",
                {
                  ref: "ruleForm",
                  attrs: {
                    model: _vm.form,
                    "label-col": _vm.labelCol,
                    "wrapper-col": _vm.wrapperCol,
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "a-form-model-item",
                    {
                      ref: "phone",
                      attrs: { label: "搜索绑定新账号", prop: "phone" },
                    },
                    [
                      _c("a-input-search", {
                        attrs: { "enter-button": "点击绑定" },
                        on: { search: _vm.searchPhone },
                        model: {
                          value: _vm.editSearch.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.editSearch, "phone", $$v)
                          },
                          expression: "editSearch.phone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      ref: "labourUser",
                      attrs: { label: "绑定新账号", prop: "labourUser" },
                    },
                    [
                      _vm._l(_vm.bindLabourUserList, function (labour) {
                        return [
                          _c(
                            "a-tag",
                            {
                              key: labour.phone,
                              attrs: { closable: true },
                              on: {
                                close: function () {
                                  return _vm.removeBindLabour(labour)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(labour.phone) + " ")]
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "新劳务用户角色", prop: "roleIdList" } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: {
                            "show-search": "",
                            mode: "multiple",
                            placeholder: "请选择角色",
                            "option-filter-prop": "children",
                            "default-value": _vm.form.roleIdList,
                            "filter-option": _vm.filterOption,
                          },
                          on: {
                            focus: _vm.handleFocus,
                            blur: _vm.handleBlur,
                            change: _vm.changeLabourRole,
                          },
                          model: {
                            value: _vm.form.roleIdList,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "roleIdList", $$v)
                            },
                            expression: "form.roleIdList",
                          },
                        },
                        _vm._l(_vm.newRole, function (item, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: item.id } },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      ref: "oldLoginName",
                      attrs: { label: "搜索绑定旧账号", prop: "oldLoginName" },
                    },
                    [
                      _c("a-input-search", {
                        attrs: { "enter-button": "点击绑定" },
                        on: { search: _vm.searchLoginName },
                        model: {
                          value: _vm.editSearch.oldLoginName,
                          callback: function ($$v) {
                            _vm.$set(_vm.editSearch, "oldLoginName", $$v)
                          },
                          expression: "editSearch.oldLoginName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      ref: "laoWuUser",
                      attrs: { label: "绑定以下旧账号", prop: "laoWuUser" },
                    },
                    [
                      _vm._l(_vm.bindLaoWuUserList, function (laoWuUser) {
                        return [
                          _c(
                            "a-tag",
                            {
                              key: laoWuUser.loginName,
                              attrs: { closable: true },
                              on: {
                                close: function () {
                                  return _vm.removeBindLoginName(laoWuUser)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(laoWuUser.loginName) + " ")]
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }