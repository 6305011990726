<template>
    <a-col :span="4" v-if="qrImg!=''">
        特殊激活小程序码（项目慎用）：
        <img :src="qrImg" style="width: 160px;" @click="showImg(qrImg)">
        <a-modal :visible="previewVisible" :footer="null" @cancel="closeImgShow">
            <img alt="example" style="width: 100%;" :src="qrImg" />
        </a-modal>
    </a-col>


</template>

<script>
    import {getProjectActivationQrCode} from '@/services/activationWork'


    export default {
        data() {
            return {
                previewVisible: false,
                qrImg: "",}
        },
        created(){
            getProjectActivationQrCode().then(res=>{
                if (res.data.code === 0){
                    this.qrImg = res.data.data.qrBase64
                }
            })
        },
        methods: {
            closeImgShow() {
                this.previewVisible = false;
            },
            showImg(imgPath){
                this.previewVisible = true;
                this.previewImage = imgPath;
            },
        }
    }
</script>
