<template>
  <div class="container">
    <div class="a_m_header">
      <form-index
        ref="formindex"
        :formData="formData"
        @handleSubmit="handleSubmit"
        :flexAction="true"
      >
        <!-- 下部分按钮插槽 -->
        <template #flexAction>
          <a-space :style="{ marginTop: '4px' }">
            <a-button icon="search" type="primary" html-type="submit"> 查询 </a-button>
            <a-button @click="close(true)"> 重置 </a-button>
          </a-space>
        </template>
      </form-index>
      <div class="a_a_footer">
        <standard-table
          class=""
          :columns="columns"
          :dataSource="dataSource"
          :selectedRows.sync="selectedRows"
          rowKey="id"
          :scroll="{ x: 1300 }"
          :pagination="pagination"
          :loading="tableLoading"
          @change="onPageChange"
          :isAlert="false"
          :bordered="true"
          @update:selectedRows="updateSelectRows($event)"
        >
          <template slot="index" slot-scope="{ index }">
            <span>
              {{
                (pagination.current - 1) * pagination.pageSize +
                parseInt(index) +
                1
              }}
            </span>
          </template>
          <template slot="attachment" slot-scope="{ text }">
            <a-avatar shape="square" :size="64" :src="text" icon="user" />
          </template>
          <div slot="action" slot-scope="{ record }">
            <div class="sTables">
              <div @click="doDowloadBatch(record)">下载证书</div>
            </div>
          </div>
        </standard-table>
      </div>
    </div>
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import moment from "moment";
import { mapGetters} from "vuex";
import {
  getCertificateTypeList,
  getCertificateList,
} from "@/services/participationUnit";
import { useRender } from '@/hooks/useRender.js'

const formData = [
  {
    label: "持证人",
    placeholder: "请输入",
    decorator: [
      "workName",
      { rules: [{ required: false, message: "请输入" }] },
    ],
    type: "input",
    key: "workName",
    col: 5,
    labelCol: 6,
    wrapperCol: 16,
    display: true,
  },
  {
    label: "持证人电话",
    placeholder: "请输入",
    decorator: [
      "phone",
      { rules: [{ required: false, message: "请输入" }] },
    ],
    type: "input",
    key: "phone",
    col: 5,
    labelCol: 6,
    wrapperCol: 16,
    display: true,
  },
  {
    type: "action",
    col: 6,
    display: true,
  },
];
const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
  },
  // {
  //   title: "证书类型",
  //   dataIndex: "certType",
  //   align: "center",
  //   scopedSlots: { customRender: "certType" },
  // },
  {
    title: "证书名称",
    dataIndex: "certName",
    align: "center",
    scopedSlots: { customRender: "certName" },
  },
  {
    title: "证书编号",
    dataIndex: "certNum",
    align: "center",
    scopedSlots: { customRender: "certNum" },
  },
  {
    title: "附件",
    align: "center",
    dataIndex: "attachment",
    scopedSlots: { customRender: "attachment" },
  },
  {
    title: "持证人",
    dataIndex: "workName",
    align: "center",
    ellipsis: true,
    scopedSlots: { customRender: "workName" },
  },
  {
    title: "联系电话",
    dataIndex: "phone",
    align: "center",
    scopedSlots: { customRender: "phone" },
  },
  {
    title: "初领时间",
    dataIndex: "effectiveStart",
    align: "center",
    customRender: (text) => useRender.renderDate(text, 'YYYY-MM-DD')
  },
  {
    title: "有效期限",
    dataIndex: "effectiveEnd",
    align: "center",
    customRender: (text) => useRender.renderDate(text, 'YYYY-MM-DD')
  },
  {
    title: "是否有效",
    dataIndex: "isValid",
    align: "center",
    // scopedSlots: { customRender: "isValid" },
  },
  {
    title: "操作",
    align: "center",
    fixed: "right",
    width: 100,
    scopedSlots: { customRender: "action" },
  },
];
export default {
  components: {
    formIndex,
    StandardTable,
  },
  data() {
    return {
      formData: formData,
      columns: columns,
      dataSource: [], //table数组
      selectedRows: [], //选中的数据
      pagination: {
        //分页数据
        showSizeChanger:true,
        pageSizeOptions: ['10', '50', '100', '150'],
        current:1,
        pageSize:50,
        total:0,
        showLessItems:true,
        showQuickJumper:true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      params: {
        //永远都是第一页
        pageNumber: 1,
        pageSize: 50,
      },
      tableLoading: false, //table加载中
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  mounted() {
    this.getCertificateTypeList();
    this.getCertificateList(this.params);
  },
  methods: {
    getCertificateTypeList() {
      getCertificateTypeList({ isPage: "false" }).then((res) => {
        if (res.data.code === 0) {
          this.formDataFn("certType", res.data.data.list, "typeName", this.formData);
        }
      });
    },
    getCertificateList(params) {
      this.tableLoading = true
      params.projectId = this.user.projectId
      getCertificateList(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
          this.dataSource.forEach((el) => {
            // el.certType = this.formData
            //   .find((j) => j.key === "certType")
            //   .selectlist.find((i) => i.value === el.certType).name;
            el.effectiveStart = el.effectiveStart ? moment(el.effectiveStart).format("YYYY-MM-DD"): null;
            el.effectiveEnd = el.effectiveEnd ? moment(el.effectiveEnd).format("YYYY-MM-DD"): null;
            el.isValid = el.isValid ? "有效" : (el.isValid === null ? '--' : "无效");
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 对下拉框做数据处理
    formDataFn(key, data, itemName, form) {
      let arr = [];
      data.forEach((item) => {
        arr.push({ name: item[itemName], value: item.id });
      });
      form.forEach((item) => {
        if (item.key == key) {
          item.selectlist = arr;
        }
      });
    },
    handleSubmit(e) {
      let obj = { ...e, ...this.params }
      this.getCertificateList(obj);
    },
    //表格 - 分页查询
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      if (obj.status == "") {
        obj.status = 0;
      }
      this.params.pageNumber = e.current;
      this.pagination.current = e.current;
      this.params.pageSize = e.pageSize;
      this.pagination.pageSize = e.pageSize;
      this.getCertificateList(Object.assign({}, this.params, obj));
    },
    doDowloadBatch(record) {
      window.open(record.attachment)
    },
    // 重置
    close() {
      this.params.pageNumber = 1
      this.params.pageSize = 50
      this.pagination.current = 1;
      this.pagination.pageSize = 50;
      this.selectedRows = []
      this.$refs["formindex"].resetFields();
      this.getCertificateList(this.params)
    },
  },
};
</script>

<style lang="less" scoped>
.a_a_footer {
  // margin-top: 10px;
  // padding: 20px;
  background: #ffffff;

  .operator {
    margin-right: 8px;

    .ant-btn {
      color: #307dfa;
    }
  }

  .sTables {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      width: 100%;
      text-align: center;
      cursor: pointer;
    }

    div:nth-child(1) {
      color: #307dfa;
    }

    div:nth-child(2) {
      color: #ff5c77;
    }

    div:nth-child(3) {
      color: #615e83;
    }
  }
}
.content_div {
  padding: 5px 0;
  cursor: pointer;
}
.content_div:hover {
  color: #307dfa;
}
</style>
