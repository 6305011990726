import { ADMIN } from '@/services/api'
import {request, METHOD, removeAuthorization} from '@/utils/request'

// const BASE_URL = process.env.VUE_APP_API_BASE_URL + '/score'
const ADMIN_API = `${ADMIN}/score`

export async function standardAdd(data) {
    return request(ADMIN_API + '/standard/add', METHOD.POST, data)
}
export async function standardUpdate(data) {
    return request(ADMIN_API + `/standard/update`, METHOD.POST, data)
}
export async function standardList(data) {
    return request(ADMIN_API + `/standard/list`, METHOD.POST, data)
}
export async function standardByID(params) {
    return request(ADMIN_API + `/standard/view`, METHOD.GET, params)
}
export async function standardDelete(params) {
    return request(ADMIN_API + `/standard/delete`, METHOD.GET, params)
}

export const standardBatchDownload = ADMIN_API + '/standard/batchDownload'

export async function govdeptfileAdd(data) {
    return request(ADMIN_API + '/govdeptfile/add', METHOD.POST, data)
}
export async function govdeptfileUpdate(data) {
    return request(ADMIN_API + `/govdeptfile/update`, METHOD.POST, data)
}
export async function govdeptfileList(data) {
    return request(ADMIN_API + `/govdeptfile/list`, METHOD.POST, data)
}
export async function govdeptfileByID(params) {
    return request(ADMIN_API + `/govdeptfile/view`, METHOD.GET, params)
}
export async function govdeptfileDelete(params) {
    return request(ADMIN_API + `/govdeptfile/delete`, METHOD.GET, params)
}

export const govdeptfileBatchDownload = ADMIN_API + '/govdeptfile/batchDownload'


export async function noticefileAdd(data) {
    return request(ADMIN_API + '/noticefile/add', METHOD.POST, data)
}
export async function noticefileUpdate(data) {
    return request(ADMIN_API + `/noticefile/update`, METHOD.POST, data)
}
export async function noticefileList(data) {
    return request(ADMIN_API + `/noticefile/list`, METHOD.POST, data)
}
export async function noticefileByID(params) {
    return request(ADMIN_API + `/noticefile/view`, METHOD.GET, params)
}
export async function noticefileDelete(params) {
    return request(ADMIN_API + `/noticefile/delete`, METHOD.GET, params)
}
export async function safesystemUrellist(params) {
    return request(ADMIN_API + `/safesystem/urellist`, METHOD.GET, params)
}
export async function safesystemByNoticeId(params) {
    return request(ADMIN_API + `/safesystem/getByNoticeId`, METHOD.GET, params)
}
export async function safegworkguideUrellist(params) {
    return request(ADMIN_API + `/safegworkguide/urellist`, METHOD.GET, params)
}
export async function safegworkguideByNoticeId(params) {
    return request(ADMIN_API + `/safegworkguide/getByNoticeId`, METHOD.GET, params)
}
export async function emergencyplanUrellist(params) {
    return request(ADMIN_API + `/emergencyplan/urellist`, METHOD.GET, params)
}
export async function emergencyplanByNoticeId(params) {
    return request(ADMIN_API + `/emergencyplan/getByNoticeId`, METHOD.GET, params)
}


export const noticefileBatchDownload = ADMIN_API + '/noticefile/batchDownload'
export const noticefileExport = ADMIN_API + '/noticefile/exportExcel'