<template>
  <div class="a_Approval">
    <div class="plug-Overview">安全技术交底资料管理</div>
    <a-divider />
    <a-form class="clearfix">
      <div :class="advanced ? null: 'fold'">
        <a-col :span="5">
          <a-form-item
            label="日期"
            :labelCol="{span: 8}"
            :wrapperCol="{span: 14, offset: 1}"
          >
            <!-- <a-input placeholder="请输入" v-model="queryParams.userName"/> -->
              <a-date-picker :format="monthFormat" v-model="queryParams.dueTime"  :allowClear="false" style="width:100%"/>
          </a-form-item>
        </a-col>
        <a-col v-if="user.loginType == 1" :span="5" >
          <a-form-item
            :labelCol="{span: 8}"
            :wrapperCol="{span: 14, offset: 1}"
            label="项目名称">
              <a-auto-complete :data-source="inputDataSource" v-model="queryParams.projectId"
                  placeholder="请输入项目名称" @search="handleInputSearch($event)" allowClear
                  @select="handlInputSelect($event)">
                  <template slot="dataSource">
                      <a-select-option v-for="items in inputDataSource" :key="items.id + ''">{{ items.title }}</a-select-option>
                  </template>
              </a-auto-complete>
          </a-form-item>
        </a-col>
        <a-col :span="5">
          <a-form-item
            label="班组名称"
            :labelCol="{span: 8}"
            :wrapperCol="{span: 14, offset: 1}"
          >
            <a-select
              v-model="queryParams.teamId"
              show-search
              placeholder="请输入或选择班组"
              option-filter-prop="children"
              :filter-option="filterOption"
              @focus="handleFocus"
              @blur="handleBlur"
              @change="handleChange"
            >
              <a-select-option :key="item.id" v-for="item in teamDatas" :value="item.id">
                {{item.teamName}}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-space class="btnBox">
            <a-button icon="search" type="primary" @click="doQuery()">查询</a-button>
            <a-space class="operator">
              <a-button @click="doReset()">重置</a-button>
              <!-- <a-button icon="download" @click="exportBatchStaff()">导出花名册</a-button>/ -->
            </a-space>
          </a-space>
        </a-col>
      </div>
    </a-form>
    <div>
      <standard-table
        :columns="columns"
        :dataSource="dataSource"
        :selectedRows.sync="selectedRows"
        :pagination="pagination"
        :loading="tableLoading"
        @clear="onClear"
        @change="onPageChange"
        :isAlert="false"
        :bordered="true"
      >
        <div slot="safediscloseFileName" slot-scope="{text}">
          <div v-if="text!=null&&text!=''">{{text}}</div>
          <a-icon v-else style="color:red" type="close" />
        </div>
        <div slot="do-status" slot-scope="{text}">
          <a-icon v-if="text==1" style="color:green" type="check" />
          <a-icon v-else style="color:red" type="close" />
        </div>
        <div slot="do-userList" slot-scope="{text,record}">
          <a-popover placement="bottom">
            <template slot="content">
              <p v-for="(item, key) in record.userNames.split(',')" :key="key">{{item}}</p>
            </template>
            <span>{{text| subText}}</span>
          </a-popover>
        </div>
        <div slot="action" slot-scope="{text, record}">
          <a @click="download(record)">下载</a>
          <!-- <a-divider type="vertical"/>
          <a @click="doDowloadZh(record)">下载</a> -->
        </div>
        <template slot="statusTitle" slot-scope="{text, record}">
          <a @click="showPersons(record)">{{text}}</a>
        </template>
      </standard-table>
    </div>
    <a-modal v-model="visible" title="人员完成情况" @ok="handleOk">
      <div>
        <a-list :data-source="personDataSource">
          <a-list-item slot="renderItem" slot-scope="item">
            <a-list-item-meta :description="item.phoneNumber">
              <a slot="title">{{ item.userName}}</a>
              <a-avatar
                slot="avatar"
                :src="item.headImg"
              />
            </a-list-item-meta>
            <div style="position: absolute;left: 50%;font-size:20px">
              <!-- <a-icon v-if="item.isSign==1" style="color:green" type="check" />
              <a-icon v-else style="color:red" type="close" /> -->
              <span v-if="item.isSign==1"><i class="iconfont duigouxiao" style="color:#00CE86"></i></span>
              <span v-else><i class="iconfont path" style="color:#FF5C77"></i></span>
            </div>
          </a-list-item>
        </a-list>
      </div>
    </a-modal>
  </div>
</template>

<script>
import StandardTable from '@/components/table/StandardTable'
import {
  TECHNICAL_DISCLOSURE_QUERYPAGE,
  TECHNICAL_DISCLOSURE_PERSONS,
  FIND_TEAMS_BY_USER
  } from '@/services/api'
  import {
    getDisclosureDownload,
  } from '@/services/product'
import {request} from '@/utils/request'
import moment from 'moment';
import {mapGetters} from 'vuex'
import { getOfficeProject } from "@/services/projectManagement";
import { useRender } from '@/hooks/useRender.js'

const columns = [
  {
    title: '发布时间',
    dataIndex: 'eduTime',
    align: 'center',
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: '项目名称',
    dataIndex: 'projectName',
    align: 'center',
    ellipsis: true,
    tooltip: true
  },
  {
    title: '班组名称',
    dataIndex: 'teamName',
    align: 'center',
    scopedSlots: { customRender: 'teamName' },
  },
  {
    title: '完成情况',
    dataIndex: 'overdec',
    align: 'center',
    scopedSlots: {customRender: 'statusTitle'},
  },
  {
    title: '截止日期',
    dataIndex: 'endTime',
    align: 'center',
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: '操作',
    align: 'center',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'QueryList',
  components: {StandardTable},
  data () {
    return {
      inputDataSource: [],
      personDataSource:[],
      dataSource:[],
      advanced: false,
      columns: columns,
      selectedRows: [],
      tableLoading: false,
      spinning:false,
      loadingText:"数据加载中...",
      monthFormat: 'YYYY-MM-DD',
      nowMonthDay:null,
      loadKeys:[],
      visible:false,
      teamDatas:[],
      queryParams:{
        dueTime:null,
        idCard:null,
        teamId:undefined,
        pageNumber:1,
        projectId: '',
        oldProjectId: undefined
      },
      pagination:{
        current:1,
        pageSize:10,
        total:0,
        showLessItems:true,
        showQuickJumper:true,
        showTotal: (total, range) => `共 ${total} 条`
      },
    }
  },
  filters:{
    subText:function(value){
      if(value&&value.length>50){
        return value.substring(0,50)+"……";
      }else{
        return value;
      }
    }
  },
  watch: {
    cPercent:{
      handler(newName, oldName) {
        this.cPercent=newName;
      },
      immediate: true,
    },
    loadingText:{
      handler(newName, oldName) {
        this.loadingText=newName;
      },
      immediate: true,
    }
  },
  computed: {
    ...mapGetters('account', ['user']),
  },
  created() {
    // this.nowMonthDay = moment(new Date()).format('YYYY-MM-DD');
    // this.queryParams.dueTime = moment(new Date()).format('YYYY-MM-DD');
    this.doQuery();
    this.doQueryTeams();
    if (this.user.loginType == 0) {
      // 0 是项目人员 1是公司人员
      let keyToDelete = 'projectName'
      this.columns = this.columns.filter(item => item.dataIndex !== keyToDelete)
    }
  },
  authorize: {
    deleteRecord: 'delete'
  },
  methods: {
    moment,
    handlInputSelect(e) {
      if (this.queryParams.projectId == e) {
        this.queryParams.oldProjectId = e
      }
      console.log(e,'e==')
      this.doQueryTeams()
    },
    handleInputSearch(value) {
      this.inputDataSource = []
      if(!value) {
          return
      }
      let params = {
          officeId: this.user.officeId,
          projectName: value
      }
      getOfficeProject(params).then((res) => {
          if (res.data.code === 0) {
            res.data.data.list.forEach(el => {
                this.inputDataSource.push({
                    title: el.fullName,
                    id: el.id,
                })
            })

          }
      });
    },
    handleChange(value) {
      this.queryParams.teamId = value;
      this.doQuery();
    },
    handleBlur() {
      console.log('blur');
    },
    handleFocus() {
      console.log('focus');
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    doQueryTeams(){
      request(FIND_TEAMS_BY_USER,"post",{projectId: this.queryParams.projectId}).then(res => {
          if(res.data.code==0){
            this.teamDatas = res.data.data;
          }
        })
    },

    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    remove () {
      this.dataSource = this.dataSource.filter(item => this.selectedRows.findIndex(row => row.key === item.key) === -1)
      this.selectedRows = []
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },

    /**
     * 获取所有人员的签名记录信息
     */
    doQuery(){
      this.tableLoading = true;
      if(this.queryParams.dueTime!=null){
        this.queryParams.dueTime = moment(this.queryParams.dueTime).format('YYYY-MM-DD');
      }
      request(TECHNICAL_DISCLOSURE_QUERYPAGE,"post",this.queryParams).then(res => {
        if(res.data.code==0){
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
          this.dataSource.forEach(obj=>{
            obj.key = obj.id;
          })
        }
        this.tableLoading = false;
      })
    },
    doReset(){
      this.queryParams={
        dueTime:null,
        idCard:null,
        teamId:undefined,
        pageNumber:1,
        oldProjectId: undefined,
        projectId: ''
      };
      this.doQuery();
    },
    //分页查询
    onPageChange(e){
      this.queryParams.pageNumber = e.current;
      this.pagination.current=e.current;
      this.doQuery();
    },
    showPersons(recode){
      this.visible = true;
      request(TECHNICAL_DISCLOSURE_PERSONS+recode.code,"get").then(res => {
        if(res.data.code==0){
          this.personDataSource = res.data.data;
        }
      })
    },
    handleOk(){
      this.visible = false;
      this.personDataSource = [];
    },
    doDowloadZh(recode){
      // downloadFile(recode.filePath,recode.teamName)
      window.open(recode.filePath);
      // exportFile.getImgURLs(recode.meansPathFen, recode.enterCode+".docx");

    },
    download(recode) {
      getDisclosureDownload({id: recode.id}).then(res => {
        if (res.data) {
            const blob = new Blob([res.data])
            let a = document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = `${recode.teamName}.docx`;
            a.click(); // 模拟点击a标签
            window.URL.revokeObjectURL(a.href);
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .fold{
    width: calc(100% - 216px);
    display: inline-block
  }
  .operator {
    margin-right: 8px;

    .ant-btn {
      color: #307dfa;
      border-color: #307dfa;
    }
  }
  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
</style>
