var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a_Method" },
    [
      _c(
        "a-row",
        { attrs: { gutter: 16 } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-card",
                {
                  staticStyle: { height: "900px" },
                  style: "overflow:auto",
                  attrs: { title: "设置门禁通道", bordered: false },
                },
                [
                  _c(
                    "div",
                    { staticClass: "action" },
                    [
                      _vm.canOperate
                        ? _c(
                            "a-button",
                            {
                              attrs: { icon: "plus", type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.onContextMenuClick("0", "1")
                                },
                              },
                            },
                            [_vm._v("新增区域")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  !_vm.spinning
                    ? _c(
                        "a-tree",
                        {
                          attrs: {
                            "tree-data": _vm.treeDataSource,
                            checkable: "",
                            "selected-keys": _vm.selectedKeys,
                            checkStrictly: "",
                            defaultExpandParent: true,
                            defaultExpandAll: true,
                          },
                          on: { select: _vm.onSelect, check: _vm.onCheck },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "title",
                                fn: function (ref) {
                                  var treeKey = ref.key
                                  var title = ref.title
                                  return [
                                    _c(
                                      "a-dropdown",
                                      {
                                        attrs: { trigger: ["contextmenu"] },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "overlay",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "a-menu",
                                                    {
                                                      on: {
                                                        click: function (ref) {
                                                          var menuKey = ref.key

                                                          return _vm.onContextMenuClick(
                                                            treeKey,
                                                            menuKey
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm.canOperate
                                                        ? _c(
                                                            "a-menu-item",
                                                            { key: "1" },
                                                            [_vm._v("新增")]
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "a-menu-item",
                                                        { key: "2" },
                                                        [_vm._v("编辑")]
                                                      ),
                                                      _c(
                                                        "a-menu-item",
                                                        { key: "3" },
                                                        [_vm._v("删除")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c("div", { staticClass: "flex" }, [
                                          _c(
                                            "span",
                                            { staticStyle: { width: "200px" } },
                                            [_vm._v(_vm._s(title))]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "sTables ml-5" },
                                            [
                                              _vm.canOperate
                                                ? _c(
                                                    "div",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.onContextMenuClick(
                                                            treeKey,
                                                            "1"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("新增")]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "ml-2",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onContextMenuClick(
                                                        treeKey,
                                                        "2"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" 编辑 ")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "ml-2",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onContextMenuClick(
                                                        treeKey,
                                                        "3"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" 删除 ")]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2036574313
                          ),
                          model: {
                            value: _vm.checkedKeys,
                            callback: function ($$v) {
                              _vm.checkedKeys = $$v
                            },
                            expression: "checkedKeys",
                          },
                        },
                        [_c("a-spin", { attrs: { spinning: _vm.spinning } })],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "新增区域",
            width: 600,
            "body-style": { height: "200px", overflowY: "auto" },
          },
          on: { ok: _vm.handleAddNewSubmit },
          model: {
            value: _vm.addNewDialog,
            callback: function ($$v) {
              _vm.addNewDialog = $$v
            },
            expression: "addNewDialog",
          },
        },
        [
          _c("form-index", {
            ref: "addNewFormIndex",
            attrs: { formData: _vm.addNewFormData },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }