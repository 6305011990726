<template>
    <a-card :bordered="false">
        <div id="components-form-demo-advanced-search">
            <a-form class="ant-advanced-search-form" :form="searchForm" >
                <a-row :gutter="24">
                    <a-col :span="8" >
                        <a-form-item :label="`上报时间`">
                            <a-range-picker @change="onChange" :default-value="[moment(searchForm.startTime, dateFormat), moment(searchForm.endTime, dateFormat)]" />
                        </a-form-item>
                    </a-col>

                    <a-col :span="5" :style="{  marginTop: '3px'}">
                        <a-button type="primary" icon="search" :loading="loading" @click="search">
                            查询
                        </a-button>
                        <a-button :style="{ marginLeft: '8px' }" icon="download" :loading="loading" type="primary" @click="exportExcel">
                            导出
                        </a-button>
                    </a-col>
                </a-row>

            </a-form>
        </div>
        <a-table
                :columns="columns"
                :data-source="data"
                :pagination="pagination"
                @change="onPageChange"
                :loading="tableLoading"
        >
        </a-table>


    </a-card>


</template>

<script>
    import {dailyReportStatistics, exportDailyReportStatistics} from '@/services/epidemic'
    import moment from 'moment';
    import { useRender } from '@/hooks/useRender.js'

    const columns = [
        {
            title: '日期',
            dataIndex: 'date',
            key: 'date',
            customRender: (text) => useRender.renderDate(text)
        },
        {
            title: '上报人数',
            dataIndex: 'reportNum',
            key: 'reportNum',
        },
        {
            title: '异常人数',
            dataIndex: 'abnormalNum',
            key: 'abnormalNum',
        },
    ];

    export default {
        data() {
            return {

                // 筛选
                searchForm: {

                },
                loading: false,


                // 数据表格
                columns,
                data: [],
                pagination:{
                    current:1,
                    pageSize:10,
                    total:0,
                },
                tableLoading: false,
                dateFormat: 'YYYY-MM-DD'

            };
        },
        created() {
            this.initData()
        },
        methods: {
            moment,
            initData() {
                this.searchForm.endTime = this.getDay(0) + " 23:59:59"
                this.searchForm.startTime = this.getDay(-7) + " 00:00:00"
                this.searchForm.pageNumber = 1
                this.pagination.pageNumber = 1
                this.dailyReportStatistics()
            },
            onChange(date, dateString) {
                this.searchForm.startTime = dateString[0] + " 00:00:00"
                this.searchForm.endTime = dateString[1] + " 23:59:59"
            },
            search() {
                this.searchForm.pageNumber = 1
                this.pagination.pageNumber = 1
                this.dailyReportStatistics()
            },
            onPageChange(e){
                this.searchForm.pageNumber = e.current;
                this.pagination.current=e.current;
                this.dailyReportStatistics();
            },
            dailyReportStatistics(){
                this.data = []
                this.detailsData = []
                this.loading = true
                this.tableLoading = true
                dailyReportStatistics(this.searchForm).then(res=>{
                    this.data = res.data.data.list
                    this.pagination.total = res.data.data.total
                }).finally(()=>{
                    this.loading = false
                    this.tableLoading = false
                })
            },
            exportExcel(){
                exportDailyReportStatistics(this.searchForm).then((res) => {
                    if(res.data.code == -1){
                        this.$message.warning(res.data.msg)
                    } else  {
                        console.log(res)
                        let fileName = this.searchForm.startTime.split(" ")[0] + '~' + this.searchForm.endTime.split(" ")[0] + '每日统计.xls'
                        let downloadElement = document.createElement('a')//创建dom

                        // let href = window.URL.createObjectURL(blob) //创建下载链接
                        let href = window.URL.createObjectURL(res.data) //创建下载链接

                        downloadElement.href = href
                        downloadElement.download = fileName //下载后文件名
                        document.body.appendChild(downloadElement)//添加创建的节点
                        downloadElement.click() //点击下载
                        document.body.removeChild(downloadElement) //下载完成移除元素
                        window.URL.revokeObjectURL(href) //释放掉blob对象
                        this.isLoading = false
                    }
                }).catch((err) => {
                    setTimeout(() => {
                        this.isLoading = false
                    }, 500);
                })
            },

            getDay(day){
                var today = new Date();
                var targetday_milliseconds=today.getTime() + 1000*60*60*24*day;
                today.setTime(targetday_milliseconds); //注意，这行是关键代码
                var tYear = today.getFullYear();

                var tMonth = today.getMonth();

                var tDate = today.getDate();

                tMonth = this.doHandleMonth(tMonth + 1);

                tDate = this.doHandleMonth(tDate);

                return tYear+"-"+tMonth+"-"+tDate;

            },
            doHandleMonth(month){
                var m = month;
                if(month.toString().length == 1){
                    m = "0" + month;
                }
                return m;

            }


        },
    };


</script>

<style scoped>
    .ant-advanced-search-form {
        padding: 24px;
        background: #fbfbfb;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        margin-bottom: 10px;
    }

    .ant-advanced-search-form .ant-form-item {
        display: flex;
    }

    .ant-advanced-search-form .ant-form-item-control-wrapper {
        flex: 1;
    }

    #components-form-demo-advanced-search .ant-form {
        max-width: none;
    }

    tr{
        background-color: #ffffff;
    }

</style>
