<template>
  <a-card>
    <div>
      <a-collapse v-model="activeKey" style="margin-bottom: 20px;">
        <a-collapse-panel key="1" header="进场资料完成情况">
          <div class="flex w-100 mb-1">
            <div style="width:50%;height:240px">
              <!-- <h2>进场资料完成情况</h2> -->
              <div id="chart" style="width:100%;height:240px;"></div>
            </div>
            <div style="margin-top: 60px;">
              <h2>情况分析</h2>
              <h3>当前进场工人资料完成率{{finishRate}}%</h3>
              <h3>资料待完善的工人最久的是{{farTime}}；进场至今已{{farDays}}天，请及时处理!</h3>
              <h3>超七天未完善进场资料的工人已达{{unFinishNums}}人</h3>
            </div>
          </div>
        </a-collapse-panel>
      </a-collapse>
      <a-form layout="horizontal">
        <div>
          <a-row :gutter="24">
            <a-col :span="6">
              <a-form-item
                label="姓名"
                :labelCol="{span: 5}"
                :wrapperCol="{span: 16, offset: 1}"
              >
                <a-input placeholder="请输入" v-model="queryParams.userName"/>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item
                label="身份证号"
                :labelCol="{span: 5}"
                :wrapperCol="{span: 16, offset: 1}"
              >
                <!-- <a-select placeholder="请选择">
                  <a-select-option value="1">关闭</a-select-option>
                  <a-select-option value="2">运行中</a-select-option>
                </a-select> -->
                <a-input placeholder="身份证号" v-model="queryParams.idCard"/>
              </a-form-item>
            </a-col>
            <a-col v-if="user.loginType == 1" :span="6" >
                <a-form-item 
                  :labelCol="{span: 5}" 
                  :wrapperCol="{span: 16, offset: 1}" 
                  label="项目名称">
                    <a-auto-complete :data-source="inputDataSource" v-model="queryParams.projectId"
                        placeholder="请输入项目名称" @search="handleInputSearch($event)" allowClear
                        @select="handlInputSelect($event)">
                        <template slot="dataSource">
                            <a-select-option v-for="items in inputDataSource" :key="items.id + ''">{{ items.title }}</a-select-option>
                        </template>
                    </a-auto-complete>
                </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item
                label="班组名称"
                :labelCol="{span: 5}"
                :wrapperCol="{span: 16, offset: 1}"
              >
                <!-- <a-input style="width: 100%" placeholder="请输入" v-model="queryParams.teamName"/> -->
                <a-select
                  show-search
                  placeholder="请输入或选择班组"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  @focus="handleFocus"
                  @blur="handleBlur"
                  @change="handleChange"
                  v-model="queryParams.teamId"
                >
                  <a-select-option :title="item.teamName" :key="item.id" v-for="item in teamDatas" :value="item.id">
                    {{item.teamName}}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-space class="ml-1 mb-3" style="margin-top: 4px">
              <a-button icon="search" type="primary" @click="handleSubmit">查询</a-button>
              <a-space class="operator">
                <a-button @click="doReset()">重置</a-button>
                <a-button icon="download" @click="doDowloadBatch()">批量下载</a-button>
              </a-space>
            </a-space>
          </a-row>
        </div>
      </a-form>
    </div>
      <!-- <a-space class="operator">
        <a-button @click="doDowloadBatch()">批量下载</a-button>
      </a-space> -->
    <standard-table
      :columns="columns"
      :dataSource="dataSource"
      :selectedRows.sync="selectedRows"
      :pagination="pagination"
      :loading="tableLoading"
      @clear="onClear"
      @change="onPageChange"
      :bordered="true"
    >
      <div slot="safediscloseFileName" slot-scope="{text}">
        <div v-if="text!=null&&text!=''">{{text}}</div>
        <a-icon v-else style="color:red" type="close" />
      </div>
      <div slot="do-status" slot-scope="{text}">
        <!-- <a-icon v-if="text==1" style="color:green" type="check" />
        <a-icon v-else style="color:red" type="close" /> -->
        <span v-if="text == 1"><i class="iconfont duigouxiao" style="color:#00CE86"></i></span>
        <span v-else><i class="iconfont path" style="color:#FF5C77"></i></span>
      </div>
      <div slot="action" slot-scope="{record}">
        <!--<router-link :to="`/list/query/detail/${record.key}`" >打包下载资料</router-link> -->
        <a @click="doDowload(`${record.key}`,`${record.userName}`)">下载资料</a>
        <!--<a-divider type="vertical" />
        <a @click="doRestartContract(`${record.key}`)">重新生成合同</a>
          <a-divider type="vertical" />
        <a @click="doRestartRcxz(`${record.key}`)">重新生成入场须知</a>  
        <a-divider type="vertical" />
        <a @click="doRestartEssContract(`${record.key}`)">重新生成电子签</a>  -->
      </div>
      <template slot="projectName" slot-scope="{ text }">
        <a-tooltip placement="topLeft">
          <template slot="title"
            ><span>{{ text }}</span></template
          >
          <span class="ellipsis-2">{{
            text
          }}</span>
        </a-tooltip>
      </template>
      <template slot="statusTitle">
        <a-icon @click.native="onStatusTitleClick" type="info-circle" />
      </template>
    </standard-table>
    <Export-loading :cPercent="cPercent" :isExporting="isExporting" :loadingText="loadingText"></Export-loading>
  </a-card>
</template>

<script>
import StandardTable from '@/components/table/StandardTable'
import {
  SAFEMEANS_QUERYPAGE, 
  STATISS_ONE,
  SAFEMEANS_DOWNLOAD,
  RESTART_CCONTRACT,
  RESTART_RCXZ,
  RESTART_ESS_CONTRACT,
  FIND_TEAMS_BY_USER
  } from '@/services/api'
  
import { exportProgress } from '@/pages/aqjyzl/export' // 导出方法
import ExportLoading from '@/components/ExportLoading/ExportLoading' // progress组件
import {request} from '@/utils/request'
import {mapGetters} from 'vuex'
import { getOfficeProject } from "@/services/projectManagement";
import { useRender } from '@/hooks/useRender.js'
import moment from 'moment'
// import axios from '@/utils/axios'

const columns = [
  {
    title: '姓名',
    dataIndex: 'userName',
    align: 'center',
    width:95,
  },
  {
    title: '身份证号',
    dataIndex: 'idCard',
    align: 'center',
    scopedSlots: { customRender: 'idCard' },
    // customRender: (text) => {
    //   return text.substring(0,6)+"************"+text.substring(text.length-4,text.length)
    // }
  },
  {
    title: '所属项目',
    dataIndex: 'projectName',
    align: 'center',
    width:200,
    scopedSlots: { customRender: 'projectName' }
  },
  {
    title: '所属班组',
    dataIndex: 'teamName',
    align: 'center',
    scopedSlots: { customRender: 'teamName' }
  },
  {
    title: '劳动合同',
    dataIndex: 'contractStatus',
    align: 'center',
    scopedSlots: {customRender: 'do-status'}
  },
  {
    title: '入场须知',
    dataIndex: 'rcxzStatus',
    align: 'center',
    scopedSlots: {customRender: 'do-status'}
  },
  {
    title: '三级安全教育',
    dataIndex: 'aqdjStatus',
    align: 'center',
    width:130,
    scopedSlots: {customRender: 'do-status'}
  }
  // ,
  // {
  //   title: '疫情防控承诺书',
  //   dataIndex: 'yqtechStatus',
  //   align: 'center',
  //   scopedSlots: {customRender: 'do-status'}
  // }
  // ,{
  //   title: '疫情一人一档',
  //   dataIndex: 'yqsafeStatus',
  //   align: 'center',
  //   scopedSlots: {customRender: 'do-status'}
  // }
  ,
  {
    title: '入场技术交底',
    dataIndex: 'safediscloseStatus',
    align: 'center',
    scopedSlots: {customRender: 'do-status'}
  },
  // {
  //   title: '其他交底',
  //   dataIndex: 'safediscloseFileName',
  //   align: 'center',
  //   scopedSlots: {customRender: 'safediscloseFileName'}
  // },
  {
    title: '进场日期',
    dataIndex: 'enterDate',
    align: 'center',
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: '更新日期',
    dataIndex: 'created',
    align: 'center',
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: '操作',
    align: 'center',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  mixins: [exportProgress],
  name: 'QueryList',
  components: {StandardTable,ExportLoading},
  data () {
    return {
      finishRate: '',
      farTime: '',
      farDays: '',
      unFinishNums: '',
      option: {
        title: {
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b}'  // 使用 {c} 表示数值，{d}% 表示百分/比
        },
        color: ['#fc8251', '#5470c6', '#91cd77', '#75bedc'],
        series: [
          {
            // name: 'Access From',
            type: 'pie',
            radius: '80%',
            center: ['50%', '60%'], // 调整饼图的位置，向下偏移一点点
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
          }
        ]
      },
      inputDataSource: [],
      dataSource:[],
      advanced: false,
      columns: columns,
      selectedRows: [],
      teamDatas:[],
      tableLoading: false,
      spinning:false,
      loadingText:"数据加载中...",
      loadKeys:[],
      visible:false,
      overDownIndex:0,
      queryParams:{
        userName:null,
        idCard:null,
        teamId:null,
        pageNumber:1,
        projectId: ''
      },
      pagination:{
        showSizeChanger:true,
        pageSizeOptions: ['10', '20', '30', '50'],
        current:1,
        pageSize:10,
        total:0,
        showLessItems:true,
        showQuickJumper:true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      cPercent:0,
      isExporting:false,
      fileName:'入场资料文件',
      activeKey: ['1']
    }
  },
  watch: {
    cPercent:{
      handler(newName, oldName) {
        this.cPercent=newName;
      },
      immediate: true,
    },
    loadingText:{
      handler(newName, oldName) {
        this.loadingText=newName;
      },
      immediate: true,
    }
  },
  computed: {
      ...mapGetters('account', ['user']),
    },
  created() {
    this.doQuery();
    this.doQueryTeams();
    if (this.user.loginType == 0) {
      // 0 是项目人员 1是公司人员
      let keyToDelete = 'projectName'
      this.columns = this.columns.filter(item => item.dataIndex !== keyToDelete)
    }
  },
  mounted() {
    request(STATISS_ONE,"post",{}).then(res => {
      if(res.data.code == 0){
        let chartData = [
          { value: res.data.data.type4, name: '全部完成' +  res.data.data.type4 + '人'},
          { value: res.data.data.type3, name: '缺失一项' +  res.data.data.type3 + '人'},
          { value: res.data.data.type2, name: '缺失两项' +  res.data.data.type2 + '人'},
          { value: res.data.data.type1, name: '缺失三项' +  res.data.data.type1 + '人'},
          { value: res.data.data.type0, name: '缺失四项' +  res.data.data.type0 + '人'},
        ]
        this.finishRate = Number(res.data.data.rate * 100).toFixed(2)
        this.farTime = res.data.data.farTime ? moment(res.data.data.farTime).format('YYYY-MM-DD') : '-'
        this.farDays = res.data.data.farDays ? res.data.data.farDays : '-'
        this.unFinishNums = res.data.data.unFinishNums ? res.data.data.unFinishNums : 0
        this.option.series[0].data = chartData
        let myChart = this.$echarts.init(document.getElementById("chart"));
        myChart.setOption(this.option)
      }
    })
  },
  authorize: {
    deleteRecord: 'delete'
  },
  methods: {
    handlInputSelect(e) {
      this.doQueryTeams()
    },
    handleInputSearch(value) {
      this.inputDataSource = []
      if(!value) {
          return
      }
      let params = {
          officeId: this.user.officeId,
          projectName: value
      }
      getOfficeProject(params).then((res) => {
          if (res.data.code === 0) {
            res.data.data.list.forEach(el => {
                this.inputDataSource.push({
                    title: el.fullName,
                    id: el.id,
                })
            })

          }
      });
    },
    handleChange(value) {
      this.queryParams.teamId = value;
      this.queryParams.pageNumber = 1
      this.pagination.current = 1
      this.doQuery();
    },
    handleSubmit() {
      this.queryParams.pageNumber = 1
      this.pagination.current = 1
      this.doQuery();
    },
    handleBlur() {
      console.log('blur');
    },
    handleFocus() {
      console.log('focus');
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    doQueryTeams(){
      request(FIND_TEAMS_BY_USER,"post", {projectId: this.queryParams.projectId}).then(res => {
          if(res.data.code==0){
            this.teamDatas = res.data.data;
          }
        })
    },

    deleteRecord(key) {
      this.dataSource = this.dataSource.filter(item => item.key !== key)
      this.selectedRows = this.selectedRows.filter(item => item.key !== key)
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    remove () {
      this.dataSource = this.dataSource.filter(item => this.selectedRows.findIndex(row => row.key === item.key) === -1)
      this.selectedRows = []
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },

    /**
     * 获取所有人员的签名记录信息
     */
    doQuery(){
      this.tableLoading = true;
        request(SAFEMEANS_QUERYPAGE,"post",this.queryParams).then(res => {
          if(res.data.code==0){
            this.dataSource = res.data.data.list;
            this.pagination.total = res.data.data.total;
            this.dataSource.forEach(obj=>{
              obj.key = obj.id;
            })
          }
          this.tableLoading = false;
        })
    },
    doReset(){
      this.queryParams={
        userName:null,
        idCard:null,
        teamId:null,
        pageNumber:1,
        projectId: ''
      };
      this.pagination.current = 1
      this.doQuery();
    },
    //分页查询
    onPageChange(e){
      this.queryParams.pageNumber = e.current;
      this.queryParams.pageSize = e.pageSize;
      this.pagination.pageSize = e.pageSize;
      this.pagination.current=e.current;
      this.doQuery();
    },
    /**
     * 批量下载
     */
    doDowloadBatch(){
      this.loadKeys = [];
      let userName = '';
      this.cPercent = 0;
      if(this.selectedRows&&this.selectedRows.length>0){
        // this.selectedRows.map((item) => {
        //   this.loadKeys.push(item.key);
        // })
        this.isExporting=true
        this.loadingText = "正在下载"+this.selectedRows[0].userName+"的资料...";
        this.selectedRows.map((item) => {
          let items = [];
          let itemsKeys = [];
          items.push(item);
          itemsKeys.push(item.key);
          userName = item.userName;
          let fileName = ""
          fileName=userName+"入场资料下载.zip";
          this.exportFunAlone(SAFEMEANS_DOWNLOAD,itemsKeys,fileName,items,this.selectedRows)
        })
      }else{ 
        this.$message.info('请选中劳工人员进行下载');
        return;
      }

        // if(this.selectedRows.length==1){
        //   userName = this.selectedRows[0].userName
        // }
        // this.isExporting=true;
        // this.cPercent=0;
        // let fileName = ""
        // fileName=userName+"入场资料下载.zip";
        // this.exportFun(SAFEMEANS_DOWNLOAD,this.loadKeys,fileName,this.selectedRows)
      //   request(SAFEMEANS_DOWNLOAD,"post",this.loadKeys,
      //     {
      //       responseType:"blob",
      //       headers:{ 'Content-Type': 'application/json; application/octet-stream'}
      //     }
      //     ).then(res => {
      //     this.spinning = false; 
      //     const content = res.data;
      //     console.log(typeof content);
      //     const reader = new FileReader()
      //     var blob = new Blob([content], {type: "application/zip"})
      //     reader.readAsDataURL(blob)
      //     reader.onload = (e) => {
      //     const a = document.createElement('a')
      //       a.download = userName+"入场资料下载.zip";
      //       a.href = e.target.result
      //       document.body.appendChild(a)
      //       a.click()
      //       document.body.removeChild(a)
      //     }
      // })
      
    },
    /**
     * 单个单个的下载
     */
    doDowload(key,userName){
      let selectedRows = this.dataSource.filter(item => item.key == key);
      this.loadingText = "正在打包资料文件，请稍候 ····";
      this.loadKeys = [];
      this.loadKeys.push(key);
      this.isExporting=true;
      this.cPercent=0;
      let fileName = ""
      fileName=userName+"入场资料下载.zip";
      this.exportFun(SAFEMEANS_DOWNLOAD,this.loadKeys,fileName,selectedRows)
      // request(SAFEMEANS_DOWNLOAD,"post",this.loadKeys,
      //   {
      //     responseType:"blob",
      //     headers:{ 'Content-Type': 'application/json; application/octet-stream'}
      //   }).then(res => {
      //   this.spinning = false; 
      //   const content = res.data;
      //   const reader = new FileReader()
      //   var blob = new Blob([content], {type: "application/zip"})
      //   reader.readAsDataURL(blob)
      //   reader.onload = (e) => {
      //     const a = document.createElement('a')
      //     a.download = userName+"入场资料下载.zip";
      //     a.href = e.target.result
      //     document.body.appendChild(a)
      //     a.click()
      //     document.body.removeChild(a)
      //   }
      // })
    },
    handleMenuClick (e) {
      if (e.key === 'delete') {
        this.remove()
      }
    },
    doRestartContract(key){
      let selectedRows = this.dataSource.filter(item => item.key == key);
      const obj = {
        id:selectedRows[0].id,
        projectId:selectedRows[0].projectId,
        officeId:selectedRows[0].officeId,
        teamId:selectedRows[0].teamId,
        idCard:selectedRows[0].idCard,
      }
      let _this = this;
      this.$confirm({
        title: '是否重新生成合同?',
        content: '确认是否重新生成合同，合同重新生成，原有合同将被覆盖作废，请慎重操作！',
        okText: '仍要执行',
        onOk() {
          return new Promise((resolve, reject) => {
            _this.visible = true;
            request(RESTART_CCONTRACT,"post",obj).then(res => {
              if(res.data.code==0){
                _this.doQuery();
                return resolve(true);
              }else{
                return reject(false);
              }
            })
          }).catch(() => _this.$message.error('合同生成异常！'));
        },
        onCancel() {},
      });
    },
    doRestartEssContract(key){
      let selectedRows = this.dataSource.filter(item => item.key == key);
      const obj = {
        id:selectedRows[0].id,
        projectId:selectedRows[0].projectId,
        officeId:selectedRows[0].officeId,
        teamId:selectedRows[0].teamId,
        idCard:selectedRows[0].idCard,
      }
      let _this = this;
      this.$confirm({
        title: '是否重新生成电子签合同?',
        content: '确认是否重新生成电子签合同，合同重新生成，原有合同将被覆盖作废，请慎重操作！',
        okText: '仍要执行',
        onOk() {
          return new Promise((resolve, reject) => {
            _this.visible = true;
            request(RESTART_ESS_CONTRACT,"post",obj).then(res => {
              if(res.data.code==0){
                _this.doQuery();
                return resolve(true);
              }else{
                return reject(false);
              }
            })
          }).catch(() => _this.$message.error('合同生成电子签异常！'));
        },
        onCancel() {},
      });
    },

    doRestartRcxz(key){
      let selectedRows = this.dataSource.filter(item => item.key == key);
      const obj = selectedRows[0];
      let _this = this;
      this.$confirm({
        title: '是否重新生成入场须知?',
        content: '确认是否重新生成入场须知，入场须知重新生成，原有入场须知将被覆盖作废，请慎重操作！',
        okText: '仍要执行',
        onOk() {
          return new Promise((resolve, reject) => {
            _this.visible = true;
            request(RESTART_RCXZ,"post",obj).then(res => {
              console.log(res)
              if(res.data.imgPath!=null&&res.data.imgPath!=''){
                _this.doQuery();
                return resolve(true);
              }else{
                return reject(false);
              }
            })
          }).catch(() => _this.$message.error('入场须知生成异常！'));
        },
        onCancel() {},
      });
    },
    doSureModal(){
      this.visible = true;
    }
  }
}
</script>

<style lang="less" scoped>
  // .search{
  //   margin-bottom: 54px;
  // }
  .fold{
    width: calc(100% - 216px);
    display: inline-block
  }
  // .operator{
  //   margin-bottom: 18px;
  // }
  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
  .operator {
  margin-right: 8px;

  .ant-btn {
    color: #307dfa;
    border-color: #307dfa;
  }
}
</style>
