<template>
  <div>
    <a-card title="新增领导季度带班检查" class="a_Approval" :bordered="false">
      <form-index
        ref="formindex"
        :formData="formData"
        @handleSubmit="submit"
        :bottomSubmit="true"
        @handlSelect="handlSelect"
        @handleChange="handleChange"
        @changeYear="changeYear"
      >
        <!-- 下部分按钮插槽 -->
        <template #bottomSubmit>
          <a-button type="primary" html-type="submit"> 保存 </a-button>
          <a-button @click="close"> 返回 </a-button>
        </template>
      </form-index>
    </a-card>
  </div>
</template>

<script>
const formData = [
  {
    label: '分公司',
    placeholder: '请选择分公司',
    decorator: ['gcglbId', { rules: [{ required: true, message: '请选择分公司' }] }],
    type: 'select',
    key: 'gcglbId',
    selectlist: [],
    col: 8,
    labelCol: 6,
    wrapperCol: 12,
  },
  {
    label: "项目名称",
    placeholder: "请输入项目名称",
    decorator: [
      "projectId",
      { rules: [{ required: true, message: "请输入项目名称" }] },
    ],
    type: "inputGroup",
    key: "projectId",
    dataSource: [],
    col: 12,
    labelCol: 6,
    wrapperCol: 8,
    display: true,  
  },
  {
    label: "年度",
    placeholder: "请选择",
    decorator: [
      "year",
      { rules: [{ required: true, message: "请选择" }] },
    ],
    type: "yearPicker",
    key: "year",
    col: 8,
    labelCol: 6,
    wrapperCol: 12,
    display: true,
    styles: {width: '100%'}
  },
  {
    label: "季度",
    placeholder: "请选择",
    decorator: [
      "season",
      { rules: [{ required: true, message: "请选择" }] },
    ],
    type: "select",
    selectlist: [{
      value: 1,
      name: '第一季度'
    },{
      value: 2,
      name: '第二季度'
    },{
      value: 3,
      name: '第三季度'
    },{
      value: 4,
      name: '第四季度'
    }],
    key: "season",
    col: 12,
    labelCol: 6,
    wrapperCol: 8,
    display: true,
  },
  {
    label: '带班领导',
    placeholder: '请输入',
    decorator: ['leader', { rules: [{ required: true, message: '请输入' }] }],
    type: 'input',
    key: 'leader',
    col: 8,
    labelCol: 6,
    wrapperCol: 12,
  },
  {
    label: '陪检人员',
    placeholder: '请输入',
    decorator: ['accompany', { rules: [{ required: true, message: '请输入' }] }],
    type: 'input',
    key: 'accompany',
    col: 12,
    labelCol: 6,
    wrapperCol: 8,
  },
  
];
import formIndex from "@/pages/components/form/index";
import { QUERY_OFFICE_BY_USER, AQXJ_ZXJC, PROJECT_OFFICE_CHILD } from "@/services/api";
import { request } from "@/utils/request";
import { mapGetters } from 'vuex'
import { getProjectByOffice } from "@/services/statistics";
import { leaderCheckPlanInsert, leaderCheckPlanUpdate, leaderCheckPlanDetail } from "@/services/safeMonthlyReport";
export default {
  components: {
    formIndex,
  },
  inject: ["fatherMethod"],
  computed: {
    ...mapGetters('account', ['user']),
  },
  data() {
    return {
      isEdit: 0,
      formData: formData,
      detailObj: null, //保存详情的对象
      params: {
        id: '',
        projectId: '',
        gcglbId: '',
        projectName: ''
      },
    };
  },
  activated() {
    this.isEdit = Number(this.$route.query.isEdit);
    if (this.isEdit === 1) {
      this.params.id = this.$route.query.id;
      this.getDetail()
    }
    this.getgcglbList(PROJECT_OFFICE_CHILD + "/OFFICE_CHILD");
    this.getProjectStatissKqS('')
    this.getAQXJ_ZXJC(AQXJ_ZXJC);
  },

  methods: {
    changeYear(e) {
      this.$refs.formindex.queryObj({
        year: e.format('YYYY'),
      })
    },
    getProjectStatissKqS(projectName) {
      getProjectByOffice({gcglbId: this.params.gcglbId, projectName}).then((res) => {
        if (res.data.code === 0) {
          this.formData.forEach((item) => {
            if ('projectId' === item.key) {
              let arr = []
              res.data.data.forEach(el => {
                arr.push({
                  title: el.projectName,
                  id: el.projectId,
                  gcglbId: el.gcglbId
                })
              })
              item.dataSource = arr
            }
          });
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    getDetail() {
      leaderCheckPlanDetail(this.params.id).then(res => {
        if (res.data.code === 0) {
          this.detailObj = res.data.data
          this.$refs.formindex.queryObj(res.data.data)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 获取工程管理部数据
    getAQXJ_ZXJC(path) {
      request(path, "get").then((res) => {
        this.formDataFn("checkType", res.data.data);
      });
    },
    getgcglbList(path) {
      request(path, "get").then((res) => {
        if (res.data.code === 0) {
          const data = res.data.data.filter(
            (item) => item.id != "3da0f5533f87425c9a9e8a11a7923300"
          );
          const arr = [];
          data.forEach((item) => {
            arr.push({
              name: item.label,
              value: item.value,
            });
          });
          this.formDataFn("gcglbId", arr);
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      let arr = [];
      if (key == 'gcglbId') {
        data.forEach((item) => {
          arr.push({ name: item.name, value: item.value });
        });
      } else if (key == 'checkType') {
        data.forEach((item) => {
          arr.push({ name: item.itemValue, value: item.id });
        });
      }
      this.formData.forEach((item) => {
        if (item.key == key) {
          item.selectlist = arr;
        }
      });
    },
    handlSelect(obj) {
      const { val, keys } = obj
      console.log(val, keys)
      if (keys === 'gcglbId') {
        this.params.gcglbId = val
        this.getProjectStatissKqS('')
      } else if (keys === 'projectId') {
        this.params.projectId = val
        this.formData.forEach(el => {
          if (el.key === 'projectId') {
            this.params.projectName = el.dataSource.find(i => val == i.id).title
          }
        })
      }
    },
    // 输入选择框触发
    handleChange(e) {
      console.log(e)
      this.getProjectStatissKqS(e.value)
    },
    submit(e) {
      if (this.isEdit === 0) {
        this.params = Object.assign(this.params, e)
        this.params.created = this.user.id
        console.log(this.params)
        leaderCheckPlanInsert(this.params).then((res) => {
          let d = res.data;
          if (d.code === 0) {
            this.$message.success("新增成功");
            this.close();
            this.$router.push({
              name: "安全巡检计划",
            });
          } else {
            this.$message.error(res.data.msg);
          }
        });
      } else {
        this.params = Object.assign(this.detailObj, Object.assign(this.params, e))
        this.params.updated = this.user.id
        leaderCheckPlanUpdate(this.params).then((res) => {
          let d = res.data;
          if (d.code === 0) {
            this.$message.success("修改成功");
            this.close();
            this.$router.push({
              name: "安全巡检计划",
            });
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
    },
    close() {
      let path = this.$route.path;
      this.fatherMethod(`${path}`);
      this.$router.push({
        name: "安全巡检计划",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.a_Approval {
  background: #fff;
  border-radius: 10px;
}
</style>
