<template>
  <div>
    <div class="constructiontTable_index">
      <a-form>
        <div class="plug-Overview">管理人员</div>
        <a-divider />
        <a-button class="mb-2" @click="addPerson">新 增</a-button>
      </a-form>
      <!-- 表格封装 -->
      <standard-table
        class=""
        :columns="columns"
        :dataSource="dataSource"
        rowKey="id"
        :pagination="pagination"
        :loading="tableLoading"
        @change="onPageChange"
        :isAlert="false"
        :bordered="true"
      >
        <template slot="index" slot-scope="{ index }">
          <span>
            {{
              (pagination.current - 1) * pagination.pageSize +
              parseInt(index) +
              1
            }}
          </span>
        </template>
        <template slot="isLogin" slot-scope="{ text }">
          <span v-if="text == 0"><i class="iconfont duigouxiao" style="color:#00CE86"></i></span>
          <span v-else><i class="iconfont path" style="color:#FF5C77"></i></span>
        </template>
        <template slot="isReportAtte" slot-scope="{ text }">
            <span v-if="text == 1"><i class="iconfont duigouxiao" style="color:#00CE86"></i></span>
            <span v-else><i class="iconfont path" style="color:#FF5C77"></i></span>
          </template>
        <div slot="action" slot-scope="{ record }">
          <div class="sTables">
            <div @click="viewEditing(record)">修改</div>
            <!-- <div @click="recorDelete(record)">删除</div> -->
          </div>
        </div>
      </standard-table>
    </div>
  </div>
</template>

<script>
import StandardTable from "@/components/table/StandardTable";

import { request } from "@/utils/request";
import { AQXJ_PROJECTS_EXPORT_ZAIJIAN,SYSUSER_PROID_OFFICE } from "@/services/api";
import {
  getProAllOfficeList,
  getCheckOfficeList,
  getDetailOffice,
  getMageUserDetail,
  getSysProOffice,
  delProOffUser
} from "@/services/participationUnit";
import moment from "moment";
import { mapGetters} from "vuex";
import { getOfficeTypeList } from "@/services/sys";
const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
    // fixed: "left",
  },
  {
    title: "姓名",
    dataIndex: "userName",
    align: "center",
    width: 110,
    scopedSlots: { customRender: "userName" },
    // fixed: "left",
  },
  {
    title: "身份证号",
    dataIndex: "idCard",
    width: 110,
    align: "center",
    // ellipsis: true,
    scopedSlots: { customRender: "idCard" },
    // fixed: "left",
  },
  {
    title: "联系电话",
    dataIndex: "phone",
    align: "center",
    width: 150,
    scopedSlots: { customRender: "phone" },
  },
  {
    title: "职务",
    align: "center",
    width: 100,
    dataIndex: "roleNames",
    scopedSlots: { customRender: "roleNames" },
  },
  {
    title: "是否上报建委",
    dataIndex: "isReportAtte",
    width: 80,
    align: "center",
    scopedSlots: { customRender: "isReportAtte" },
  },
  {
    title: "是否启用账号",
    dataIndex: "isLogin",
    width: 80,
    align: "center",
    scopedSlots: { customRender: "isLogin" },
  },
  {
    title: "操作",
    align: "center",
    width: 60,
    // fixed: "right",
    scopedSlots: { customRender: "action" },
  },
];
const formData = [
  {
    label: "姓名",
    placeholder: "请输入姓名",
    decorator: [
      "userName",
      { rules: [{ required: false, message: "请输入姓名" }] },
    ],
    type: "inputGroup",
    key: "userName",
    selectlist: [],
    col: 8,
    labelCol: 4,
    wrapperCol: 14,
  },
  // {
  //   label: "单位类型",
  //   placeholder: "请选择单位类型",
  //   decorator: [
  //     "roleType",
  //     { rules: [{ required: false, message: "请选择单位类型" }] },
  //   ],
  //   type: "select",
  //   key: "roleType",
  //   col: 8,
  //   labelCol: 6,
  //   wrapperCol: 14,
  //   selectlist: [],
  //   display: true,
  // },
];
export default {
  components: {
    StandardTable, //表格封装
  },
  data() {
    return {
      columns: columns, //表格数据
      dataSource: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      tableLoading: false,
      formData: formData,
      prams: {
        pageNumber: 1,
        pageSize: 10,
      },
      projectList: [], //获取当前项目数据
      loadingText: "数据加载中...",
      cPercent: 0,
      isExporting: false,
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  watch: {
    loadingText: {
      handler(newName, oldName) {
        this.loadingText = newName;
      },
      immediate: true,
    }
  },
  activated() {
    this.query =this.$route.query
    this.projectId = this.query.projectId ? this.query.projectId : this.user.projectId,
    this.$nextTick(() => {
      this.prams= {
        pageNumber: 1,
        pageSize: 10,
        projectId:this.query.projectId ? this.query.projectId : this.user.projectId,
        officeId: this.query.officeId
      },
     this.aqxjlist(this.prams);
    })
  },
  methods: {
    // 封装弹窗确定按钮
    confirm(title, content) {
      return new Promise((resolve, reject) => {
        this.$confirm({
          title: title,
          content: content,
          onOk() {
            resolve();
          },
        });
      });
    },
    // 删除接口
    recorDelete(val, text) {
      console.log(val, text);
      let params = {
        projectId:this.projectId,
        userId: val.id
      }
      this.confirm("删除", `是否删除该用户`).then(() => {
        delProOffUser(params).then((res) => {
          if (res.data.code === 0) {
            this.$message.success("删除成功", 10);
            this.aqxjlist(this.prams);
          } else {
            this.$message.error(res.data.msg, 10);
          }
        });
      });
    },
    // 查看编辑
    viewEditing(e) {
      this.$router.push({
        name: this.user.loginType == 1 ? "管理人员详情" : '管理员详情',
        query: {
          userId: e.id,
          projectId: this.projectId
        },
      });
    },
    aqxjlist(prams) {
      this.tableLoading = true;
      getSysProOffice(prams).then((res) => {
        if (res.data.code === 0) {
          this.dataSource = res.data.data
          this.pagination.total = res.data.data.total
        }
        this.tableLoading = false;
      });
    },
    // 根据条件查询公司
    // aqxCheckList()
    // 对下拉框做数据处理
    formDataFn(key, data) {
      this.formData.forEach((item) => {
        if (item.key == key) {
          item.selectlist = data;
        }
      });
    },
    // 提交表单数据
    handleSubmit(e) {
      let obj = Object.assign({}, e, this.prams);
      this.aqxjlist(obj);
    },
    //表格 - 分页查询
    onPageChange(e) {
      // let obj = this.$refs["formindex"].getFieldValue();
      this.prams.pageNumber = e.current;
      this.pagination.current = e.current;
      this.aqxjlist(Object.assign({}, this.prams));
    },
    // 新增人员
    addPerson() {
      let pathName = this.user.loginType == 1 ? '新增管理人员' : '分配管理人员'
      this.$router.push({
            name: pathName,
            query: {
              data: this.query.data,
              officeId: this.query.officeId,
              projectId: this.query.projectId
            },
          });
    }
  },
};
</script>

<style lang="less" scoped>
.bg1 {
  background: linear-gradient(
    180deg,
    rgba(242, 249, 254, 1) 0%,
    rgba(230, 244, 254, 1) 99%
  );
}

.bg2 {
  background: linear-gradient(
    180deg,
    rgba(245, 254, 242, 1) 0%,
    rgba(230, 254, 238, 1) 99%
  );
}

.constructiontTable_index {
  .plug-Overview {
    font-size: 16px;
    color: #101010;
    font-weight: bold;
  }

  padding: 24px;
  background: #fff;
  border-radius: 6px;
  margin-bottom: 10px;

  .c_i_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 25px;

    .c_i_h_center {
      padding: 16px;
      border-radius: 4px;
      width: 180px;
      height: 130px;
      margin-right: 50px;

      div:nth-child(1) {
        font-size: 16px;
      }

      div:nth-child(2) {
        margin-top: 26px;
        font-size: 24px;
      }
    }
  }
}

.sTables {
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    // width: 100%;
    padding: 0 10px;
    text-align: center;
    cursor: pointer;
  }
  div:nth-child(1) {
    color: #307dfa;
  }
  div:nth-child(2) {
    color: #ff5c77;
  }
  div:nth-child(3) {
    color: #615e83;
  }
}
.content_div {
  padding: 5px 0;
  cursor: pointer;
}
.content_div:hover {
  color: #307dfa;
}
</style>
