<template>
  <div>
    <a-card>
      <a-row :gutter="16">
        <a-col :span="8">
          <div class="left-box">
            <div class="flex a-center j-between">
              <div class="flex a-center">
                <img style="width: 21px; height: 21px" src="https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20241120/1732091350443.png?Expires=2047451350&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=59AEqlAw%2B%2BGyQ%2F%2B4QYdFWxJZ7rs%3D" alt="">
                <span class="font-bold ml-1">项目数总计</span>
              </div>
              <div class="flex">
                <a-select
                  :default-value="defaultUnit"
                  v-model="defaultUnit"
                  style="width: 150px"
                  @change="onHandleUnitChange"
                >
                  <a-select-option
                    v-for="(items, id) in officeList"
                    :key="id"
                    :value="items.id"
                  >
                    <span :title="items.name">{{ items.name }}</span>
                  </a-select-option>
                </a-select>
              </div>
            </div>
            <a-divider />
            <div>
              <a-row :gutter="[8,8]">
                <a-col :span="12" v-for="(item, index) in tabList" :key="index">
                  <div 
                    class="flex flex-column a-center j-center statistics-item-box" 
                    :class="actived == index ? 'actived' : ''" 
                    :style="{'background': item.background}" 
                    @click="onTabClick(index, item)">
                      <div class="font-20 font-bold">{{projectCatalogStatObj[item.key] || 0}}</div>
                      <div class="flex a-center">
                        <div class="dot-icon mr-1" :style="{'background': item.iconBackground}"></div>
                        <span class="font-12 black-three">{{item.title}}</span>
                      </div>
                  </div>
                </a-col>
              </a-row>
            </div>
          </div>
          <div class="left-box mt-1">
            <div class="flex a-center">
              <img style="width: 21px; height: 21px" src="https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20241120/1732091350443.png?Expires=2047451350&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=59AEqlAw%2B%2BGyQ%2F%2B4QYdFWxJZ7rs%3D" alt="">
              <span class="font-bold ml-1">项目分布情况</span>
            </div>
            <a-divider />
            <div id="yhzsPieChart" style="width: 100%; height: 230px"></div>
          </div>
        </a-col>
        <a-col :span="16">
          <div>
            <baidu-map
              class="bmView"
              :scroll-wheel-zoom="true"
              :center="location"
              :zoom="zoom"
            >
              <bm-view class="bmMap"></bm-view>
              <bm-local-search
                :keyword="addressKeyword"
                :auto-viewport="true"
                style="display: none"
              ></bm-local-search>
              <!-- <bml-marker-clusterer :averageCenter="true">
              </bml-marker-clusterer> -->
              <div v-for="(items, index) in persons" :key="index">
                <bm-marker
                  :position="{ lng: items.lng, lat: items.lat }"
                  :icon="{
                    url: items.show ? localActive : items.icon,
                    size: { width: 28, height: 28 },
                  }"
                  :dragging="false"
                  @click="infoWindowOpen(index, items)"
                >
                  <bm-info-window :show="items.show" @close="infoWindowClose(index)">
                    <div class="info-window">
                      <a-popover>
                        <div slot="content">{{ items.name }}</div>
                        <div class="ellipsis-2">项目名称： {{ items.name }}</div>
                      </a-popover>
                      <div style="line-height: 30px">
                        项目经理：{{ staffObj.xmjl ? staffObj.xmjl : 0 }}
                      </div> 
                      <div>
                        联系电话：{{ staffObj.xmjlPhone ? staffObj.xmjlPhone : 0 }}
                      </div>
                      <!-- <div
                        v-if="canOperate"
                        class="onInfo pointer"
                        @click="onInfo(items, staffObj)"
                      >
                        查看详情
                      </div> -->
                    </div>
                  </bm-info-window>
                </bm-marker>
              </div>
            </baidu-map>
          </div>
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { getProjectCatalogStat, getProjectAreaStat, getProjectCatalogMapStat } from "@/services/labourProjectExcellent";
import { aqxjprojectsdetail } from "@/services/projectManagement";
import {
  getExcellentDetail,
} from "@/services/labourProjectExcellent";
import {
  getProjectByOffice,
} from "@/services/statistics";
import { mapGetters } from "vuex";
// import {BmlMarkerClusterer} from 'vue-baidu-map'
export default {
  // components: {
  //   BmlMarkerClusterer
  // },
  props: {
    officeList: {
      type: Array,
      default: () =>  []
    }
  },
  data() {
    return {
      actived: 0,
      defaultUnit: "",
      deptId: '',
      projectCatalogStatObj: {},
      projectAreaStat: [],
      projectList: [],
      tabList: [
        {
          title: "在建项目",
          key: "BEGIN",
          value: 0,
          status: null,
          background: '#EAF3FF',
          iconBackground: '#0A63F1'
        },
        {
          title: "未开工项目",
          key: "UN_BEGIN",
          value: 0,
          status: null,
          background: '#EFFBFE',
          iconBackground: '#33D89E'
        },
        {
          title: "停缓建项目",
          key: "STOP",
          value: 0,
          status: null,
          background: '#FFF7ED',
          iconBackground: '#FFA020'
        },
        {
          title: "完工项目",
          key: "FINISH",
          value: 0,
          status: null,
          background: '#F0F1FF',
          iconBackground: '#93AAFD'
        },
      ],
      location: {
        lng: 113.274,
        lat: 23.123,
      },
      zoom: 11.5,
      addressKeyword: "",
      persons: [], // 地图上的覆盖物
      staffObj: {},
      arrRoles: [
        "admin",
        "OFFICE_LEADER",
        "OFFICE_AQMGLY",
        "OFFICE_AQ_LEADER",
        "OFFICE_GC_LEADER",
        "test_role"
      ], // 查看详情权限
      local0: require("@/assets/img/home/local-1.png"),
      localActive: require("@/assets/img/home/activepath.png"),
    };
  },

  computed: {
    ...mapGetters("account", ["user"]),
    canOperate() {
      return this.user.nowRoleKey.some((role) => this.arrRoles.includes(role));
    },
  },

  watch: {
    officeList: {
      handler(val) {
        this.deptId = this.user.officeId;
        // 数据过滤成当前账号登录的信息
        let items = val.filter((item) => {
          return item.id === this.deptId;
        });
        this.defaultUnit = items[0].name;
        this.getData()
      },
      deep: true
    }
  },

  created() {
  },

  methods: {
    getData() {
      getProjectCatalogStat({deptId: this.deptId}).then(res => {
        if (res.data.code === 0) {
          this.projectCatalogStatObj = res.data.data
        } else {
          this.$message.error(res.data.msg)
        }
      })
      getProjectAreaStat({deptId: this.deptId}).then(res => {
        if (res.data.code === 0) {
          this.projectAreaStat = res.data.data.source.map(el => {
            return {
              name: el.xAxis,
              value: el.yAxis || 0
            }
          });
          this.initYhzsPieChart(this.projectAreaStat)
        } else {
          this.$message.error(res.data.msg)
        }
      })
      getProjectCatalogMapStat({deptId: this.deptId, projectStatus: this.tabList[this.actived].key}).then(res => {
        if (res.data.code === 0) {
          this.projectList = res.data.data
          this.filterPersons(this.projectList);
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    onInfo(item, data) {
      // 点击详情
      aqxjprojectsdetail(data.id).then((res) => {
        if (res.data.code == 0) {
          if (item.status == 1 || item.status == 5) {
            this.$router.push({
              name: "项目详情",
              query: {
                data: res.data.data,
                type: "list",
                random: Math.random(),
              },
            });
          } else {
            getExcellentDetail(res.data.data.projectId).then((resEx) => {
              if (resEx.data.stat == 1) {
                if (resEx.data.data) {
                  // 赋值  cylevel cyyq
                  let p = res.data.data;
                  p.cylevel = resEx.data.data.excellentInfo.cylevel;
                  p.cyyq = resEx.data.data.excellentInfo.cyyq;
                  this.$router.push({
                    name: "项目详情",
                    query: {
                      data: res.data.data,
                      type: "table",
                      exData: resEx.data.data,
                      random: Math.random(),
                    },
                  });
                } else {
                  this.$router.push({
                    name: "项目详情",
                    query: {
                      data: res.data.data,
                      type: "table",
                      exData: 0,
                      random: Math.random(),
                    },
                  });
                }
              }
            });
          }
        }
      });
    },
    filterPersons(data) {
      // 遍历地图覆盖物状态
      let arr = [];
      let newData = data;

      newData.forEach((item) => {
        // 组成百度地图适配的数据格式
        arr.push({
          id: item.projectId,
          lat: item.lat,
          lng: item.lng,
          icon: this.local0,
          show: false,
          name: item.projectName
          // status: item.status,
        });
      });
      this.persons = arr;
    },
    infoWindowClose(index) {
      this.persons[index].show = false;
    },
    async infoWindowOpen(index, item) {
      getProjectByOffice({ projectId: item.id }).then((res) => {
        if (res.data.code == 0) {
          this.persons[index].show = true;
          this.staffObj = res.data.data[0];
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    initYhzsPieChart(data) {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("yhzsPieChart"));
      // 指定图表的配置项和数据
      let option = {
        title: {
          text: "项目区域",
          left: "center",
          top: "45%", // 让标题居中显示
          textStyle: {
            fontSize: 12,
            fontWeight: "bold",
          },
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          show: false,
          orient: "vertical",
          left: "left",
        },
        color: ["#0A63F1", "#FFA020", "#33D89E"], // 修改饼图颜色
        series: [
          {
            type: "pie",
            radius: ["50%", "75%"], // 增加圆环宽度（变粗）
            label: {
              normal: {
                show: true,
                formatter: "{b} ({c})", // 显示图例名和具体数值（去掉百分号）
              },
            },
            labelLine: { 
              show: true,
              length: 8, // 缩短标签指示线
              length2: 10,
            },
            data: data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    onHandleUnitChange(e) {
      this.deptId = e
      this.getData()
    },
    onTabClick(index, item) {
      this.actived = index
      this.getData()
    }
  },
};
</script>

<style lang="less" scoped>
/deep/.ant-card {
  border-radius: 10px;
  border: none;
  .ant-card-body {
    padding: 15px;
  }
}
.dot-icon {
  width: 10px; /* 或者你想要的任何大小 */
  height: 10px; /* 同上 */
  border-radius: 50%; /* 圆形 */
  margin-right: 5px;
}
.left-box {
  border-radius: 12px;
  box-shadow: 0px 0px 8px 0px rgba(10, 99, 241, 0.1);
  background: rgb(255, 255, 255);
  padding: 10px;
  .statistics-item-box {
    padding: 18px;
    border-radius: 6px;
    border: 1px solid #fff;
    cursor: pointer;
  }
}
.actived {
  border: 1px solid #639FFF !important;
}
// 百度地图自定义样式
/deep/ .bmView {
  .BMap_bottom {
    display: none;
  }
  .BMap_shadow {
    display: none;
  }
  .BMap_pop {
    > div,
    > img:nth-child(10) {
      display: none;
      overflow: unset;
    }
    > div:nth-child(9) {
      display: block;
      overflow: unset;
      width: 309px !important;
    }
    > div:nth-child(8) {
      /*display: block;*/
    }
    .BMap_top {
      display: none;
    }
    .BMap_center {
      background: transparent;
      border: none;
      position: sticky !important;
    }
  }
  .BMap_bubble_content {
    background: rgba(33, 42, 63, 0.8);
    border-radius: 6px;
    padding: 14px 17px 0 19px;
    max-height: 152px !important;
    // height: 136px !important;
    .info-window {
      font-size: 14px;
      font-family: Inter;
      width: 100%;
      // height: 100%;
      color: #e5e5ef;
      height: 108px !important;
      // height: 109px !important;
      position: relative;
      .onInfo {
        position: absolute;
        right: 5px;
        bottom: 14px;
        // margin: -10px 5px 0 auto;
        text-align: center;
        background: rgb(10, 99, 241);
        border-radius: 15px;
        width: 74px;
        height: 26px;
        line-height: 26px;
        font-size: 12px;
      }
    }
  }
}
.bmMap {
  width: 100%;
  height: 560px;
  flex: 1;
  border-radius: 10px;
}
</style>