var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main" }, [
    _c(
      "div",
      { staticClass: "sider" },
      [
        _c("companyCard", {
          attrs: { officeData: _vm.officeData, isProject: false },
        }),
        _c("managerCard", { attrs: { mangerData: _vm.mangerData } }),
        _c("teamsOverview", {
          staticStyle: { flex: "1" },
          attrs: { teamRankList: _vm.teamRankList },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c(
          "div",
          { staticClass: "content-top" },
          [
            _c(
              "div",
              { staticClass: "content-top-left" },
              [
                _c("mapOverview", {
                  staticStyle: { flex: "1" },
                  attrs: { type: "office" },
                }),
              ],
              1
            ),
            _c("workOverview", { attrs: { staffData: _vm.staffData } }),
          ],
          1
        ),
        _c("payOverview", { attrs: { payrollData: _vm.payrollData } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }