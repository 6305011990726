<template>
  <div class="a_Approval">
    <div class="flex">
      <div class="equipment-title flex flex-column a-center j-center font-bold">
        <span>{{params.facilityType == 1 ? '起重机' : '升降机'}}</span>
        <span>基本信息</span>
      </div>
      <div class="ml-1">
        <div class="font-12">工程名称</div>
        <div class="title-box">{{projectName}}</div>
      </div>
      <div class="ml-1">
        <div class="font-12">设备类型</div>
        <div class="title-box">
          <div>{{facilityTypeStr}}</div>
          <div>{{facilityNum}}</div>
        </div>
      </div>
      <div class="ml-1">
        <div class="font-12">最近监测时间</div>
        <div class="title-box">{{equipmentInfo.monitoringtime}}</div>
      </div>
    </div>
    <div class="flex mt-2">
      <div>
        <div class="alarm-record-box">
          <span class="font-bold">作业报警记录</span>
          <standard-table
            class=""
            :columns="columns"
            :dataSource="dataSource"
            rowKey="id"
            :pagination="false"
            :loading="tableLoading"
            :scroll="{ y: 450 }"
            :isAlert="false"
            :bordered="true"
          >
            <template slot="index" slot-scope="{ index }">
              <span> {{ index + 1 }} </span>
            </template>
          </standard-table>
        </div>
      </div>
      <div class="flex flex-1 ml-2 bg-box relative" v-if="params.facilityType === 1">
        <div class="flex-1">
          <div class="relative">
            <div class="flex a-center j-center">
              <img src="https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20240723/1721703316589.png?Expires=2037063316&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=01hr34VUOpumRIr3usGLWpZ%2BP1s%3D" alt="">
            </div>
            <div class="left-box">
              <div class="relative white">
                <img src="https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20240717/1721201816187.png?Expires=2036561816&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=1agscqdH7bFXrY7cN3aw9FhE%2BKM%3D" alt="">
                <span class="data-text" v-if="spinning == true">风速：
                  <a-spin>
                    <a-icon slot="indicator" type="loading" style="font-size: 16px;color: #fff" spin />
                  </a-spin>
                  m/s</span>
                <span class="data-text" v-else>风速：{{equipmentInfo.windspeed || 0}}m/s</span>
              </div>
              <div class="relative white mt-5">
                <img src="https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20240717/1721202941660.png?Expires=2036562941&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=gPrtgXY6dkoPjf%2FPgTC2l7Qx5NM%3D" alt="">
                <span class="data-text" v-if="spinning == true">力矩：
                  <a-spin>
                    <a-icon slot="indicator" type="loading" style="font-size: 16px;color: #fff" spin />
                  </a-spin>
                  %</span>
                <span class="data-text" v-else>力矩：{{equipmentInfo.moment || 0}}%</span>
              </div>
              <div class="relative white mt-5">
                <img src="https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20240717/1721202964452.png?Expires=2036562964&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=UxhUdBa377%2FA6c9sTrinosuIjSo%3D" alt="">
                <span class="data-text" v-if="spinning == true">塔身倾斜：
                  <a-spin>
                    <a-icon slot="indicator" type="loading" style="font-size: 16px;color: #fff" spin />
                  </a-spin>
                  °</span>
                <span class="data-text" v-else>塔身倾斜：{{equipmentInfo.dip || 0}}°</span>
              </div>
            </div>
            <div class="right-top-box">
              <div class="relative white">
                <img src="https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20240717/1721204610729.png?Expires=2036564610&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=98K6NrDLDvQ%2FrE45lVegPOBBwr8%3D" alt="">
                <span class="data-text" v-if="spinning == true">幅度：
                  <a-spin>
                    <a-icon slot="indicator" type="loading" style="font-size: 16px;color: #fff" spin />
                  </a-spin>
                  m</span>
                <span class="data-text" v-else>幅度：{{equipmentInfo.ranges || 0}}m</span>
              </div>
            </div>
            <div class="right-mid-box">
              <div class="relative white">
                <img src="https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20240717/1721204791320.png?Expires=2036564791&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=JMjL%2FxYIys9NvkT5PvE4j2s5jJE%3D" alt="">
                <span class="data-text" v-if="spinning == true">起升高度：
                  <a-spin>
                    <a-icon slot="indicator" type="loading" style="font-size: 16px;color: #fff" spin />
                  </a-spin>
                  m</span>
                <span class="data-text" v-else>起升高度：{{equipmentInfo.height || 0}}m</span>
              </div>
            </div>
            <div class="right-bottom-box">
              <div class="relative white">
                <img src="https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20240717/1721205275764.png?Expires=2036565275&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=qc1PeWsivSUw8yB7deQ2IhHEf38%3D" alt="">
                <span class="data-text" v-if="spinning == true">载重：
                  <a-spin>
                    <a-icon slot="indicator" type="loading" style="font-size: 16px;color: #fff" spin />
                  </a-spin>
                  t</span>
                <span class="data-text" v-else>载重：{{equipmentInfo.weight || 0}}t</span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <RotatingDialECharts :angle="Number(equipmentInfo.gyration) || 0" :spinning="spinning"/>
        </div>
        <div class="video-box" v-if="projectId === jlProjectId && videoVisible">
          <div class="font-bold flex a-center j-center pl-1 pr-1" style="border-bottom: 1px solid #d4d4d4;padding-bottom: 5px">
            <span class="flex-1">吊钩监控</span>
            <a-icon class="pointer" type="close-circle" @click="videoVisible  = !videoVisible"/>
          </div>
          <video 
            style="width: 100%;margin-top: 5px"
            id="video"
            ref="video"
            crossorigin="anonymous"
            autoplay
            controls
            ></video>
        </div>
      </div>
      <div class="flex flex-1 ml-2 bg-box" v-else>
        <div class="flex-1">
          <div class="relative">
            <div class="flex a-center j-center">
              <img src="https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20240724/1721801681731.png?Expires=2037161682&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=%2Ff3pd1SvFftxNbcZlNGQ9krq%2BjE%3D" alt="">
            </div>
            <div class="construction_hoist-left-box">
              <div class="relative white">
                <img src="https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20240724/1721802098514.png?Expires=2037162098&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=0sxu3PRn2q9ggbaSkUW%2Fv3AZTtk%3D" alt="">
                <span class="data-text" v-if="spinning == true">速度：
                  <a-spin>
                    <a-icon slot="indicator" type="loading" style="font-size: 16px;color: #fff" spin />
                  </a-spin>
                  m/s</span>
                <span class="data-text" v-else>速度：{{equipmentInfo.speed || 0}}m/s</span>
              </div>
              <div class="relative white" style="margin-top: 90px">
                <img src="https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20240724/1721802149273.png?Expires=2037162149&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=IWVqLe04n%2FcF50pDvAXY83I0RmA%3D" alt="">
                <span class="data-text" v-if="spinning == true">载重：
                  <a-spin>
                    <a-icon slot="indicator" type="loading" style="font-size: 16px;color: #fff" spin />
                  </a-spin>
                  t</span>
                <span class="data-text" v-else>载重：{{equipmentInfo.weight || 0}}t</span>
              </div>
              <!-- <div class="relative white" style="margin-top: 90px">
                <img src="https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20240724/1721802194412.png?Expires=2037162194&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=frC9ig02B2WWZrmqSSwxIcjEq5E%3D" alt="">
                <span class="data-text">前门：关闭</span>
              </div> -->
            </div>
            <!-- <div class="construction_hoist-right-mid-box">
              <div class="relative white">
                <img src="https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20240717/1721204791320.png?Expires=2036564791&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=JMjL%2FxYIys9NvkT5PvE4j2s5jJE%3D" alt="">
                <span class="data-text">运行速度：{{equipmentInfo.height || 0}}m</span>
              </div>
            </div> -->
            <!-- <div class="construction_hoist-right-bottom-box">
              <div class="relative white">
                <img src="https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20240717/1721205275764.png?Expires=2036565275&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=qc1PeWsivSUw8yB7deQ2IhHEf38%3D" alt="">
                <span class="data-text">后门：关闭</span>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Hls from 'hls.js';
import StandardTable from "@/components/table/StandardTable";
import { mapGetters } from 'vuex'
import { useRender } from '@/hooks/useRender.js'
import RotatingDialECharts from './components/RotatingDialECharts.vue';
import { getFacilityMonitorInfo2, getFacilityExceptionInfo } from "@/services/equipmentLedger";
import moment from 'moment';
const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
  },
  {
    title: "报警内容",
    dataIndex: "warnContent",
    width: 160,
    ellipsis: true,
    tooltip: true
  },
  {
    title: "报警时间",
    dataIndex: "monitoringtime",
    align: "center",
    width: 120,
    key: "monitoringtime",
    customRender: (text) => useRender.renderDate(text)
  },
];
export default {
  components: {
    StandardTable,
    RotatingDialECharts,
  },
  inject: ["fatherMethod"],
  data() {
    return {
      title: '',
      columns: columns,
      factoryNum: '', //设备出厂编号
      projectId: '',
      jlProjectId: '360a2b25f4e5626033cea7203b618ebf', // 写死梅州蕉岭项目的projectId，只有这个项目才能看到视频监控
      projectName: '', //项目名称
      facilityNum: '', //设备型号
      facilityTypeStr: '',
      dataSource: [],
      equipmentInfo: {},
      params: {
        facilityType: null, //设备类型
      },
      tableParams: {
        pageNo: 1,
        pageSize: 20,
      },
      tableLoading: true,
      timer: null,
      spinning: false,
      hls: null,
      videoVisible: true,
      videoUrl: 'https://open.ys7.com/v3/openlive/FC6281109_8_1.m3u8?expire=1755139658&id=745594824806690816&t=f9e9089051ed1166db112da5774ef764cd96752bbdb1011278f584a54b1818c9&ev=100'
    };
  },
  computed: {
    ...mapGetters('account', ['user']),
  },
  activated() {
    this.title = this.$route.name
    this.params.facilityType = Number(this.$route.query.facilityType);
    this.factoryNum = this.$route.query.factoryNum
    this.projectId = this.$route.query.projectId
    this.projectName = this.$route.query.projectName
    this.facilityNum = this.$route.query.facilityNum
    this.tableParams.factoryNum = this.factoryNum
    this.tableParams.projectId = this.projectId
    this.init()
    switch(this.params.facilityType) {
      case 1: 
        this.facilityTypeStr = '塔式起重机'
        break
      case 2: 
        this.facilityTypeStr = '施工升降机'
        break
    }
    if (this.timer) {
      clearInterval(this.timer)
    }
    this.timer = setInterval(() => {
      this.init()
    }, 5000);
    if (this.projectId === this.jlProjectId) {
      this.$nextTick(() => {
        const videoElement = this.$refs.video
        if (Hls.isSupported()) {
          this.hls = new Hls();
          this.hls.loadSource(this.videoUrl);
          this.hls.attachMedia(videoElement);
          this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
            videoElement.play();
          });
        } else if (videoElement.canPlayType('application/vnd.apple.mpegURL')) {
          videoElement.src = this.videoUrl;
          videoElement.addEventListener('loadedmetadata', () => {
            videoElement.play();
          });
        }
      })
    }
  },
  deactivated() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer)
    }
    if (this.hls) {
      this.hls.destroy()
      this.hls = null
    }
  },
  methods: {
    moment,
    init() {
      this.spinning = true
      this.tableLoading = true
      getFacilityMonitorInfo2({factoryNum: this.factoryNum, projectId: this.projectId}).then(res => {
        if (res.data.code === 0) {
          this.spinning = false
          this.equipmentInfo = res.data.data
          this.equipmentInfo.monitoringtime = res.data.data.monitoringtime ? moment(res.data.data.monitoringtime).format('YYYY-MM-DD hh:mm:ss') : null
        } else {
          this.$message.error(res.data.msg)
        }
      })
      getFacilityExceptionInfo(this.tableParams).then(res => {
        if (res.data.code === 0) {
          this.tableLoading = false
          this.dataSource = res.data.data ? res.data.data.list : [];
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    close() {
      let path = this.$route.path;
      this.fatherMethod(`${path}`);
    },
  },
};
</script>

<style lang="less" scoped>
.a_Approval {
  background: #fff;
}
.equipment-title {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  background: #307DFA;
  color: white;
}
.title-box {
  border: 1px solid #E4E7E9;
  background: #EAF8FF;
  padding: 3px 6px;
  border-radius: 5px;
  min-height: 55px;
  max-width: 400px;
  min-width: 200px;
  margin-top: 5px;
}
.alarm-record-box {
  width: 400px;
  box-shadow: #E4E7E9 0px 0px 10px;
  border-radius: 5px;
  background: #F9FAFA;
  padding: 10px;
}
.left-box {
  position: absolute;
  left: 118px;
  top: 172px;
}
.construction_hoist-left-box {
  position: absolute;
  left: 240px;
  top: 172px;
}
.right-top-box {
  position: absolute;
  right: 0;
  top: 45px;
}
.right-mid-box {
  position: absolute;
  right: 65px;
  top: 130px;
}
.construction_hoist-right-mid-box {
  position: absolute;
  right: 320px;
  top: 230px;
}
.right-bottom-box {
  position: absolute;
  right: 210px;
  top: 230px;
}
.construction_hoist-right-bottom-box {
  position: absolute;
  right: 227px;
  bottom: 128px;
}
.data-text {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 75%;
  transform: translate(-40%, -50%);
  font-size: 12px;
  font-weight: bold;
}
.bg-box {
  background-image: url('https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20240723/1721700352780.png?Expires=2037060352&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=HKOVI%2FBAC4QXC34k2UZpSj%2B22Zg%3D');
  background-size: contain;
  background-position: -50px bottom;
  background-repeat: no-repeat;
}
.video-box {
  position: absolute;
  bottom: 50px;
  right: 30px;
  width: 400px;
  border: 1px solid #d4d4d4;
  padding: 5px 5px 0px 5px;
}
</style>
