<template>
  <a-card>
    <a-spin :spinning="spinning" :tip="loadingText">
    <div :class="advanced ? 'search' : null">
      <a-form layout="horizontal">
        <div :class="advanced ? null: 'fold'">
          <a-row >
          <a-col :md="8" :sm="24" >
            <a-form-item
              label="项目名称"
              :labelCol="{span: 5}"
              :wrapperCol="{span: 18, offset: 1}"
            >
              <a-input placeholder="请输入" v-model="queryParams.projectName"/>
            </a-form-item>
          </a-col>
        </a-row>
        </div>
        <span style="float: right; margin-top: 3px;">
          <a-button type="primary" @click="doQuery()">查询</a-button>
          <a-button style="margin-left: 8px" @click="doReset()">重置</a-button>
          <!-- <a @click="toggleAdvanced" style="margin-left: 8px">
            {{advanced ? '收起' : '展开'}}
            <a-icon :type="advanced ? 'up' : 'down'" />
          </a> -->
        </span>
      </a-form>
    </div>
    <div class="operator">
        <router-link :to="{path: '/look_job/add', query: {}}">
            <a-button type="primary" @click="doAddPlan()">新增用工计划</a-button>
        </router-link>
      </div>
    <div>
      <standard-table
        :columns="columns"
        :dataSource="dataSource"
        :selectedRows.sync="selectedRows"
        :pagination="pagination"
        :loading="tableLoading"
        @clear="onClear"
        @change="onPageChange"
      >
        <!-- <div slot="safediscloseFileName" slot-scope="{text}">
          <div v-if="text!=null&&text!=''">{{text}}</div>
          <a-icon v-else style="color:red" type="close" />
        </div> -->
        <div slot="do-status" slot-scope="{text}">
          <a-icon v-if="text==1" style="color:green" type="check" />
          <a-icon v-else  type="check" />
        </div>
        <div slot="action" slot-scope="{text, record}">
          <!-- <router-link v-if="`${record.status===0}`" :to="{path: '/look_job/add', query: {id:`${record.key}`}}">编辑</router-link> -->
          <!-- <a-divider v-if="`${record.status===0}`" type="vertical" /> -->
          <router-link :to="{path: '/look_job/add', query: {id:`${record.key}`,view:true}}" >查看详情</router-link>
        </div>
      </standard-table>
    </div>
    </a-spin>
  </a-card>
</template>

<script>
import StandardTable from '@/components/table/StandardTable'
import {
  LOOKJOB_QUERY
  } from '@/services/api'
import {request} from '@/utils/request'
import { te } from 'date-fns/locale'
// import axios from '@/utils/axios'
import { useRender } from '@/hooks/useRender.js'

const columns = [
  {
    title: '编号',
    dataIndex: 'orderNo',
    align: 'center',
    scopedSlots: { customRender: 'orderNo' }
  },
  {
    title: '项目名称',
    dataIndex: 'projectName',
    align: 'left',
    scopedSlots: { customRender: 'projectName' },
    // customRender: (text) => {
    //   return text.substring(0,6)+"************"+text.substring(text.length-4,text.length)
    // }
  },
  {
    title: '入场日期',
    dataIndex: 'inDate',
    align: 'center',
   customRender: (text) => useRender.renderDate(text)
  },
  {
    title: '地址',
    dataIndex: 'address',
    align: 'left',
    scopedSlots: {customRender: 'address'}
  },
  {
    title: '发布状态',
    dataIndex: 'status',
    align: 'center',
    scopedSlots: {customRender: 'do-status'}
  },
  {
    title: '计划总人数',
    dataIndex: 'peoples',
    align: 'center',
    scopedSlots: {customRender: 'peoples'},
    customRender: (text) => {
      return text+"（人）"
    }
  },
  {
    title: '操作',
    align: 'center',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'QueryList',
  components: {StandardTable},
  data () {
    return {
      dataSource:[],
      advanced: false,
      columns: columns,
      selectedRows: [],
      tableLoading: false,
      spinning:false,
      loadingText:"数据加载中...",
      loadKeys:[],
      queryParams:{
        projectName:null,
        pageNumber:1,
      },
      pagination:{
        current:1,
        pageSize:10,
        total:0,
        showLessItems:true,
        showQuickJumper:true
      }
    }
  },
  created() {
    this.doQuery();
  },
  authorize: {
    deleteRecord: 'delete'
  },
  methods: {
    deleteRecord(key) {
      this.dataSource = this.dataSource.filter(item => item.key !== key)
      this.selectedRows = this.selectedRows.filter(item => item.key !== key)
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    remove () {
      this.dataSource = this.dataSource.filter(item => this.selectedRows.findIndex(row => row.key === item.key) === -1)
      this.selectedRows = []
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },

    doAddPlan(){
        // this.
    },

    /**
     * 获取所有人员的签名记录信息
     */
    doQuery(){
      this.tableLoading = true;
        request(LOOKJOB_QUERY,"post",this.queryParams).then(res => {
          if(res.data.code==0){
            this.dataSource = res.data.data.list;
            this.pagination.total = res.data.data.total;
            this.dataSource.forEach(obj=>{
              obj.key = obj.id;
            })
          }
          this.tableLoading = false;
        })
    },
    doReset(){
      this.queryParams={};
      this.doQuery();
    },
    //分页查询
    onPageChange(e){
      this.queryParams.pageNumber = e.current;
      this.pagination.current=e.current;
      this.doQuery();
    },
    handleMenuClick (e) {
      if (e.key === 'delete') {
        this.remove()
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .search{
    margin-bottom: 54px;
  }
  .fold{
    width: calc(100% - 216px);
    display: inline-block
  }
  .operator{
    margin-bottom: 18px;
  }

  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
</style>
