<template>
  <div class="a_Approval">
    <div class="plug-Overview">安全隐患排查记录汇总</div>
    <a-divider />
    <a-spin :spinning="spinning" :tip="loadingText">
    <div :class="advanced ? 'search' : null">
      <a-form class="clearfix">
        <a-col :span="3">
          <a-form-item>
            <a-radio-group v-model="queryParams.queryType">
              <a-radio-button :value="2">
                月份
              </a-radio-button>
              <a-radio-button :value="1">
                年份
              </a-radio-button>
            </a-radio-group>
          </a-form-item>
        </a-col>

        <a-col :span="5">
          <a-form-item label="月份/年份" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
            <a-month-picker placeholder="请选择月份" :default-value="moment(monthDay, monthFormat)" :format="monthFormat" v-if="queryParams.queryType==2" v-model="queryParams.plan_date">
              <a-icon slot="suffixIcon" type="smile" />
            </a-month-picker>
  
            <a-date-picker
                v-if="queryParams.queryType==1"
                mode="year"
                v-model="queryParams.plan_date"
                placeholder="请输入年份"
                :default-value="moment(nowYear, yearFormat)" :format="yearFormat"
                :open="yearShowOne"
                @openChange="openChangeOne"
                @panelChange="panelChangeOne"/>
          </a-form-item>
        </a-col>

        <a-col :span="4">
          <a-space class="btnBox">
            <a-button type="primary" @click="doQuery()">查询</a-button>
            <a-button @click="doReset()">重置</a-button>
          </a-space>
        </a-col>
      </a-form>
    </div>
    <div>
      <standard-table
        :bordered="true"
        :columns="columns"
        :dataSource="dataSource"
        :isAlert="false"
        :rowKey="
          (_, index) => {
            return index
          }
        "
        :pagination="pagination"
        :loading="tableLoading"
        @clear="onClear"
        @change="onPageChange"
        :btnArr="tableBtnArr"
      >
        <template slot="index" slot-scope="{ index }">
          <span>{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}</span>
        </template>
      </standard-table>
    </div>
    <Export-loading :cPercent="cPercent" :isExporting="isExporting" :loadingText="loadingText"></Export-loading>
    </a-spin>
  </div>
</template>

<script>
import StandardTable from '@/components/table/StandardTable'
import {
  PROBLEMS_BY_PRO_QUERY,
  PROBLEMS_BY_PRO_REPORT
  } from '@/services/api'

import { exportProgress } from '@/pages/aqjyzl/export' // 导出方法
import ExportLoading from '@/components/ExportLoading/ExportLoading' // progress组件
import {request} from '@/utils/request'
import moment from 'moment';
const columns = [
  {
    title: "序号",
    align: "center",
    scopedSlots: { customRender: "index" },
    width: 65,
  },
  {
    title: '被查单位',
    dataIndex: 'officeName',
    align: 'center',
    scopedSlots: { customRender: 'officeName' }
  },
  {
    title: '排查项目数量',
    dataIndex: 'projectCnts',
    align: 'center',
    scopedSlots: { customRender: 'projectCnts' },
    customRender: (text) => {
      if (text == null) {
        return 0
      } else {
        return text
      }
    }
  },
  {
    title: '隐患总数',
    dataIndex: 'total',
    align: 'center',
   scopedSlots: { customRender: 'total' },
    customRender: (text) => {
      if (text == null) {
        return 0
      } else {
        return text
      }
    }
  },
  {
    title: '安全管理',
    dataIndex: 'pb1',
    align: 'center',
   scopedSlots: { customRender: 'pb1' },
    customRender: (text) => {
      if (text == null) {
        return 0
      } else {
        return text
      }
    }
  },
  {
    title: '文明施工',
    dataIndex: 'pb2',
    align: 'center',
    scopedSlots: {customRender: 'pb2'},
    customRender: (text) => {
      if (text == null) {
        return 0
      } else {
        return text
      }
    }
  },
  {
    title: '脚手架',
    dataIndex: 'pb3',
    align: 'center',
    scopedSlots: {customRender: 'pb3'},
    customRender: (text) => {
      if (text == null) {
        return 0
      } else {
        return text
      }
    }
  },
  {
    title: '基坑与模板',
    dataIndex: 'pb4',
    align: 'center',
    scopedSlots: {customRender: 'pb4'},
    customRender: (text) => {
      if (text == null) {
        return 0
      } else {
        return text
      }
    }
  },
  {
    title: '高处作业',
    dataIndex: 'pb6',
    align: 'center',
    scopedSlots: {customRender: 'pb6'},
    customRender: (text) => {
      if (text == null) {
        return 0
      } else {
        return text
      }
    }
  },
  {
    title: '施工用电',
    dataIndex: 'pb7',
    align: 'center',
    scopedSlots: {customRender: 'pb7'},
    customRender: (text) => {
      if (text == null) {
        return 0
      } else {
        return text
      }
    }
  },
  {
    title: '大型设备',
    dataIndex: 'pb8',
    align: 'center',
    scopedSlots: {customRender: 'pb8'},
    customRender: (text) => {
      if (text == null) {
        return 0
      } else {
        return text
      }
    }
  },
  {
    title: '起重吊装',
    dataIndex: 'pb54',
    align: 'center',
    scopedSlots: {customRender: 'pb54'},
    customRender: (text) => {
      if (text == null) {
        return 0
      } else {
        return text
      }
    }
  },
  {
    title: '施工机具',
    dataIndex: 'pb11',
    align: 'center',
    scopedSlots: { customRender: 'pb11' },
    customRender: (text) => {
      if (text == null) {
        return 0
      } else {
        return text
      }
    }
  }
]

export default {
  mixins: [exportProgress],
  name: 'QueryList',
  components: {StandardTable,ExportLoading},
  data () {
    return {
      dataSource:[],
      advanced: false,
      columns: columns,
      selectedRows: [],
      tableLoading: false,
      spinning:false,
      loadingText:"数据加载中...",
      loadKeys:[],
      visible:false,
      monthFormat: 'YYYY-MM',
      yearFormat: 'YYYY',
      monthDay:null,
      nowYear:null,
      yearShowOne:false,
      dateFormat: 'YYYY-MM-DD',
      firstDay:null,
      nowDay:null,
      overDownIndex:0,
      queryParams:{
        plan_date:null,
        queryType:1,
        xjTimeStr:null,
        date:null,
        pageNumber:1,
      },
      pagination:{
        current:1,
        pageSize:10,
        total:0,
        showLessItems:true,
        showQuickJumper:true,
        showTotal: (total, range) => `共 ${total} 条`,
      },
      cPercent:0,
      isExporting:false,
      tableBtnArr: [
        {
            name: '导出',
            attrs: {
                type: 'primary'
            },
            click: this.doDowload
        }
      ]
    }
  },
  watch: {
    cPercent:{
      handler(newName, oldName) {
        this.cPercent=newName;
      },
      immediate: true,
    },
    loadingText:{
      handler(newName, oldName) {
        this.loadingText=newName;
      },
      immediate: true,
    }
  },
  created() {
    this.monthDay = moment(new Date()).format('YYYY-MM');
    this.nowYear = moment(new Date()).format('YYYY');
    this.doQuery();
  },
  authorize: {
    deleteRecord: 'delete'
  },
  methods: {
    moment,
    deleteRecord(key) {
      this.dataSource = this.dataSource.filter(item => item.key !== key)
      this.selectedRows = this.selectedRows.filter(item => item.key !== key)
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    remove () {
      this.dataSource = this.dataSource.filter(item => this.selectedRows.findIndex(row => row.key === item.key) === -1)
      this.selectedRows = []
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },
    changeDate(e){
      if(e==null||e.length<=0){
        this.queryParams.xjTimeStart = moment(new Date()).format('YYYY-MM')+"-01";
        this.queryParams.xjTimeEnd = moment(new Date()).format('YYYY-MM-DD');
      }
    },

    /**
     * 获取时间段的台账信息
     */
    doQuery(){
      this.tableLoading = true;
      if(this.queryParams.queryType==2){
        if(this.queryParams.plan_date==null){
          this.queryParams.xjTimeStr = this.monthDay
        }else{
          this.queryParams.xjTimeStr = this.queryParams.plan_date.format('YYYY-MM')
        }
      }
      if(this.queryParams.queryType==1){
        if(this.queryParams.plan_date==null){
          this.queryParams.xjTimeStr = this.nowYear
        }else{
          this.queryParams.xjTimeStr = this.queryParams.plan_date.format('YYYY')
        }
      }
      request(PROBLEMS_BY_PRO_QUERY,"post",this.queryParams).then(res => {
        if(res.data.code==0){
          this.dataSource = res.data.data;
          this.pagination.total = res.data.data.length;
          this.dataSource.forEach(obj=>{
            obj.key = obj.id;
          })
        }
        this.tableLoading = false;
      })
    },
    doReset(){
      this.queryParams={};
      this.doQuery();
    },
    //分页查询
    onPageChange(e){
      this.queryParams.pageNumber = e.current;
      this.pagination.current=e.current;
      this.doQuery();
    },
    /**
     * 导出excel
     */
    doDowload(){
      this.loadingText = "正在导出EXCEL文件，请稍候 ····";
      let name = ""
      let typeName = "月"
      if(this.queryParams.queryType==2){
        if(this.queryParams.plan_date==null){
          name = this.monthDay
        }else{
          name = this.queryParams.plan_date.format('YYYY-MM')
        }
        typeName = "月";
      }
      if(this.queryParams.queryType==1){
        if(this.queryParams.plan_date==null){
          name = this.nowYear
        }else{
          name = this.queryParams.plan_date.format('YYYY')
        }
        typeName = "年";
      }
      this.isExporting=true;
      this.cPercent=0;
      let fileName=name+"-"+typeName+"检查记录汇总.xlsx";
      this.exportFunNomal2(PROBLEMS_BY_PRO_REPORT,this.queryParams,fileName,'application/vnd.ms-excel')
    },
    handleMenuClick (e) {
      if (e.key === 'delete') {
        this.remove()
      }
    },
    panelChangeOne(value) {
      this.yearShowOne = false;
      this.queryParams.plan_date = value;
    },
    openChangeOne(status) {
      if (status) {
        this.yearShowOne = true;
      }
    },
    doSureModal(){
      this.visible = true;
    },
    onChange(dataStr){
      this.queryParams.plan_date=null;
    },
  }
}
</script>

<style lang="less" scoped>
  .search{
    margin-bottom: 54px;
  }
  .fold{
    width: calc(100% - 216px);
    display: inline-block
  }
  .operator{
    margin-bottom: 18px;
  }
  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
</style>
