<template>
    <a-card :bordered="false">
        <div id="components-form-demo-advanced-search">
            <a-form class="ant-advanced-search-form" :form="searchForm" >
                <a-row :gutter="24">
                    <a-col :span="8" >
                        <a-form-item :label="`上报时间`">
                            <a-range-picker @change="onChange" :default-value="[moment(searchForm.startTime, dateFormat), moment(searchForm.endTime, dateFormat)]" />
                        </a-form-item>
                    </a-col>

                    <a-col :span="5" :style="{ marginTop: '3px'}">
                        <a-button type="primary" icon="search" :loading="loading" @click="search">
                            查询
                        </a-button>
                        <a-button :style="{ marginLeft: '8px' }" icon="download" :loading="exportLoading" type="primary" @click="exportExcel">
                            导出
                        </a-button>
                    </a-col>
                </a-row>

            </a-form>
        </div>
        <a-table
                :columns="columns"
                :data-source="data"
                :pagination="pagination"
                @change="onPageChange"
                :loading="tableLoading"
                :scroll="{ x: 2000}"
        >
            <template slot="dayTemp" slot-scope="text, record">
                <span :style="{color: (record.dayTemp<='37.3' ? '' : 'red')}">{{ text }}</span>
            </template>
            <template slot="color" slot-scope="text, record">
                <span :style="{color: (record.color==1 ? '' : 'red')}">{{ text === 1? "绿码" : (text === 2? "黄码" : "红码") }}</span>
            </template>
            <template slot="checkLastStatus" slot-scope="text, record">
                <span :style="{color: (record.checkLastStatus==1 ? '' : 'red')}">{{ text === 1? "阴性" : "阳性" }}</span>
            </template>
            <template slot="highRisk" slot-scope="text, record">
                <span :style="{color: (record.highRisk==0 ? '' : 'red')}">{{ text === 0? "否" : "是," }} {{record.remark}}</span>
            </template>
            <template slot="familyStatus" slot-scope="text, record">
                <span :style="{color: (record.familyStatus==1 ? '' : 'red')}">{{ text === 1? "无异常" : "其他," }}{{record.familyRemark}}</span>
            </template>
            <template slot="image" slot-scope="text">
                <img :src="text" width="80px;">
            </template>
            <template slot="travelCode" slot-scope="text">
                <img :src="text" width="80px;">
            </template>

            <template slot="travelCodeColor" slot-scope="text, record">
                <span :style="{color: (record.travelCodeColor===0 || record.travelCodeColor===1 ? '' : 'red')}">{{ text===0? "" : text === 1? "绿码" : (text === 2? "黄码" : "红码") }}</span>
            </template>
            <template slot="vaccinationImg" slot-scope="text">
                <img :src="text" width="80px;">
            </template>
            <template slot="nucleicAcidImg" slot-scope="text">
                <img :src="text" width="80px;">
            </template>
            <template slot="isolate" slot-scope="text, record">
                <span :style="{color: (record.isolate===0 ? '' : 'red')}">{{ text === 1? "已隔离" : "未隔离" }}</span>
            </template>
        </a-table>


    </a-card>


</template>

<script>
    import { exportAbnormalReportStatistics, abnormalReportStatistics} from '@/services/epidemic'
    import moment from 'moment';
    import { useRender } from '@/hooks/useRender.js'

    const columns = [
        {
            title: '姓名',
            dataIndex: 'userName',
            key: 'userName',
        },
        {
            title: '身份证号',
            dataIndex: 'idCard',
            key: 'idCard',
        },
        {
            title: '电话',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: '体温',
            dataIndex: 'dayTemp',
            key: 'dayTemp',
            slots: { title: 'dayTemp' },
            scopedSlots: { customRender: 'dayTemp' },
        },
        {
            title: '健康码颜码',
            dataIndex: 'color',
            key: 'color',
            slots: { title: 'color' },
            scopedSlots: { customRender: 'color' },
        },
        {
            title: '最近核酸检测结果',
            dataIndex: 'checkLastStatus',
            key: 'checkLastStatus',
            slots: { title: 'checkLastStatus' },
            scopedSlots: { customRender: 'checkLastStatus' },
        },
        {
            title: '有无前往高风险地(情况说明)',
            dataIndex: 'highRisk',
            key: 'highRisk',
            slots: { title: 'highRisk' },
            scopedSlots: { customRender: 'highRisk' },
        },
        {
            title: '家人或周围人群健康状况(情况说明)',
            dataIndex: 'familyStatus',
            key: 'familyStatus',
            slots: { title: 'familyStatus' },
            scopedSlots: { customRender: 'familyStatus' },
        },
        {
            title: '所属区域/社区',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: '是否接种疫苗',
            dataIndex: 'isVaccines',
            key: 'isVaccines',
            customRender: (text) => {
                return text === 0? "否" : "是"
            }
        },
        {
            title: '第一针接种时间',
            dataIndex: 'oneTimeVaccines',
            key: 'oneTimeVaccines',
            customRender: (text) => useRender.renderDate(text)
        },
        {
            title: '第二针接种时间',
            dataIndex: 'twoTimeVaccines',
            key: 'twoTimeVaccines',
            customRender: (text) => useRender.renderDate(text)
        },
        {
            title: '第三针接种时间',
            dataIndex: 'threeTimeVaccines',
            key: 'threeTimeVaccines',
            customRender: (text) => useRender.renderDate(text)
        },
        {
            title: '最近核酸检测时间',
            dataIndex: 'checkLastDate',
            key: 'checkLastDate',
            customRender: (text) => useRender.renderDate(text)
        },
        {
            title: '上报日期',
            dataIndex: 'reportDate',
            key: 'reportDate',
            customRender: (text) => useRender.renderDate(text)
        },
        {
            title: '健康码',
            dataIndex: 'image',
            key: 'image',
            slots: { title: 'image' },
            scopedSlots: { customRender: 'image' },
            customRender: (text) => useRender.renderDate(text)
        },
        {
            title: '行程码',
            dataIndex: 'travelCode',
            key: 'travelCode',
            slots: { title: 'travelCode' },
            scopedSlots: { customRender: 'travelCode' },
        },

        {
            title: '行程卡颜色',
            dataIndex: 'travelCodeColor',
            key: 'travelCodeColor',
            slots: { title: 'travelCodeColor' },
            scopedSlots: { customRender: 'travelCodeColor' },
        },
        {
            title: '疫苗接种记录截图',
            dataIndex: 'vaccinationImg',
            key: 'vaccinationImg',
            slots: { title: 'vaccinationImg' },
            scopedSlots: { customRender: 'vaccinationImg' },
        },
        {
            title: '核酸检测记录截图',
            dataIndex: 'nucleicAcidImg',
            key: 'nucleicAcidImg',
            slots: { title: 'nucleicAcidImg' },
            scopedSlots: { customRender: 'nucleicAcidImg' },
        },
        {
            title: '返岗前居住地',
            dataIndex: 'residenceBeforeReturn',
            key: 'residenceBeforeReturn',
            slots: { title: 'residenceBeforeReturn' },
            scopedSlots: { customRender: 'residenceBeforeReturn' },
        },
        {
            title: '返程详细行程',
            dataIndex: 'travelBeforeReturn',
            key: 'travelBeforeReturn',
            slots: { title: 'travelBeforeReturn' },
            scopedSlots: { customRender: 'travelBeforeReturn' },
        },
        {
            title: '血型',
            dataIndex: 'bloodType',
            key: 'bloodType',
            slots: { title: 'bloodType' },
            scopedSlots: { customRender: 'bloodType' },
        },
        {
            title: '是否隔离',
            dataIndex: 'isolate',
            key: 'isolate',
            slots: { title: 'isolate' },
            scopedSlots: { customRender: 'isolate' },
        },
        {
            title: '隔离体温',
            dataIndex: 'isolateTemperature',
            key: 'isolateTemperature',
            slots: { title: 'isolateTemperature' },
            scopedSlots: { customRender: 'isolateTemperature' },
        },
    ];

    export default {
        data() {
            return {

                // 筛选
                searchForm: {

                },
                loading: false,


                // 数据表格
                columns,
                data: [],
                pagination:{
                    current:1,
                    pageSize:10,
                    total:0,
                },
                tableLoading: false,
                exportLoading: false,
                dateFormat: 'YYYY-MM-DD'

            };
        },
        created() {
            this.initData()
        },
        methods: {
            moment,
            initData() {
                this.searchForm.endTime = this.getDay(0) + " 23:59:59"
                this.searchForm.startTime = this.getDay(-7) + " 00:00:00"
                this.searchForm.pageNumber = 1
                this.pagination.pageNumber = 1
                this.abnormalReportStatistics()
            },
            onChange(date, dateString) {
                this.searchForm.startTime = dateString[0] + " 00:00:00"
                this.searchForm.endTime = dateString[1] + " 23:59:59"
                console.log(date, dateString);
            },
            search() {
                this.searchForm.pageNumber = 1
                this.pagination.pageNumber = 1
                this.abnormalReportStatistics()
            },
            onPageChange(e){
                this.searchForm.pageNumber = e.current;
                this.pagination.current=e.current;
                this.abnormalReportStatistics();
            },
            abnormalReportStatistics(){
                this.data = []
                this.detailsData = []
                this.loading = true
                this.tableLoading = true
                abnormalReportStatistics(this.searchForm).then(res=>{
                    this.data = res.data.data.list
                    this.pagination.total = res.data.data.total
                }).finally(()=>{
                    this.loading = false
                    this.tableLoading = false
                })
            },
            exportExcel(){
                this.exportLoading = true
                exportAbnormalReportStatistics(this.searchForm).then((res) => {
                    if(res.data.code == -1){
                        this.$message.warning(res.data.msg)
                    } else  {
                        console.log(res)
                        let fileName = this.searchForm.startTime.split(" ")[0] + '~' + this.searchForm.endTime.split(" ")[0] + '异常人数名单.xls'
                        let downloadElement = document.createElement('a')//创建dom

                        // let href = window.URL.createObjectURL(blob) //创建下载链接
                        let href = window.URL.createObjectURL(res.data) //创建下载链接

                        downloadElement.href = href
                        downloadElement.download = fileName //下载后文件名
                        document.body.appendChild(downloadElement)//添加创建的节点
                        downloadElement.click() //点击下载
                        document.body.removeChild(downloadElement) //下载完成移除元素
                        window.URL.revokeObjectURL(href) //释放掉blob对象
                        this.isLoading = false
                    }
                }).catch((err) => {
                    setTimeout(() => {
                        this.isLoading = false
                    }, 500);
                }).finally(()=>{
                    this.loading = false
                    this.tableLoading = false
                    this.exportLoading = false
                })
            },
            getDay(day){
                var today = new Date();
                var targetday_milliseconds=today.getTime() + 1000*60*60*24*day;
                today.setTime(targetday_milliseconds); //注意，这行是关键代码
                var tYear = today.getFullYear();

                var tMonth = today.getMonth();

                var tDate = today.getDate();

                tMonth = this.doHandleMonth(tMonth + 1);

                tDate = this.doHandleMonth(tDate);

                return tYear+"-"+tMonth+"-"+tDate;

            },
            doHandleMonth(month){
                var m = month;
                if(month.toString().length == 1){
                    m = "0" + month;
                }
                return m;

            }


        },
    };


</script>

<style scoped>
    .ant-advanced-search-form {
        padding: 24px;
        background: #fbfbfb;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        margin-bottom: 10px;
    }

    .ant-advanced-search-form .ant-form-item {
        display: flex;
    }

    .ant-advanced-search-form .ant-form-item-control-wrapper {
        flex: 1;
    }

    #components-form-demo-advanced-search .ant-form {
        max-width: none;
    }

    tr{
        background-color: #ffffff;
    }

</style>
