var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticStyle: { top: "20px" },
      attrs: { title: "操作", width: 800 },
      on: { ok: _vm.handleSubmit },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c(
        "a-form",
        { attrs: { form: _vm.form } },
        [
          _c(
            "a-form-item",
            { staticStyle: { display: "none" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: ["menuId"],
                    expression: "['menuId']",
                  },
                ],
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                labelCol: _vm.labelCol,
                wrapperCol: _vm.wrapperCol,
                label: "上级权限",
              },
            },
            [
              _c("a-tree-select", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "parentId",
                      {
                        rules: [{ required: true, message: "请选择上级权限" }],
                      },
                    ],
                    expression:
                      "['parentId', {rules: [{ required: true, message: '请选择上级权限' }]}]",
                  },
                ],
                attrs: {
                  dropdownStyle: { maxHeight: "400px", overflow: "auto" },
                  treeData: _vm.permissions,
                  placeholder: "上级权限",
                  treeDefaultExpandAll: "",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                labelCol: _vm.labelCol,
                wrapperCol: _vm.wrapperCol,
                label: "菜单类型",
              },
            },
            [
              _c(
                "a-select",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: [
                        "menuType",
                        {
                          initialValue: "M",
                          rules: [{ required: true, message: "请选择类型" }],
                        },
                      ],
                      expression:
                        "['menuType', {initialValue:'M',rules: [{ required: true, message: '请选择类型' }]}]",
                    },
                  ],
                  on: { select: _vm.menuTypeChange },
                },
                [
                  _c("a-select-option", { attrs: { value: "M" } }, [
                    _vm._v("目录"),
                  ]),
                  _c("a-select-option", { attrs: { value: "C" } }, [
                    _vm._v("菜单"),
                  ]),
                  _c("a-select-option", { attrs: { value: "F" } }, [
                    _vm._v("按钮"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                labelCol: _vm.labelCol,
                wrapperCol: _vm.wrapperCol,
                label: "权限名称",
              },
            },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "menuName",
                      {
                        rules: [{ required: true, message: "请输入权限名称" }],
                      },
                    ],
                    expression:
                      "['menuName',{rules: [{ required: true, message: '请输入权限名称' }]}]",
                  },
                ],
                attrs: { placeholder: "起一个名字" },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                labelCol: _vm.labelCol,
                wrapperCol: _vm.wrapperCol,
                label: "路由唯一键",
              },
            },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "menuKey",
                      {
                        initialValue: "",
                        rules: [
                          { required: true, message: "请输入动态菜单唯一键" },
                        ],
                      },
                    ],
                    expression:
                      "['menuKey',{initialValue:'',rules: [{ required: true, message: '请输入动态菜单唯一键' }]}]",
                  },
                ],
                attrs: { placeholder: "路由唯一键：如'user'" },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.menuType != "M",
                  expression: "menuType!='M'",
                },
              ],
              attrs: {
                labelCol: _vm.labelCol,
                wrapperCol: _vm.wrapperCol,
                label: "权限标识",
              },
            },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "perms",
                      {
                        rules: [{ required: false, message: "请输入权限标识" }],
                      },
                    ],
                    expression:
                      "['perms',{rules: [{ required: false, message: '请输入权限标识' }]}]",
                  },
                ],
                attrs: { placeholder: "权限标识" },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.menuType !== "F",
                  expression: "menuType!=='F'",
                },
              ],
              attrs: { labelCol: _vm.labelCol, wrapperCol: _vm.wrapperCol },
            },
            [
              _c(
                "span",
                { attrs: { slot: "label" }, slot: "label" },
                [
                  _vm._v(" 组件 "),
                  _c(
                    "a-tooltip",
                    {
                      attrs: {
                        title: "routerUtil中定义的组件或views文件下的路径",
                      },
                    },
                    [_c("a-icon", { attrs: { type: "question-circle-o" } })],
                    1
                  ),
                ],
                1
              ),
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "component",
                      { rules: [{ required: false, message: "请输入组件" }] },
                    ],
                    expression:
                      "['component',{rules: [{ required: false, message: '请输入组件' }]}]",
                  },
                ],
                attrs: { placeholder: "组件" },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.menuType !== "F",
                  expression: "menuType!=='F'",
                },
              ],
              attrs: {
                labelCol: _vm.labelCol,
                wrapperCol: _vm.wrapperCol,
                label: "图标",
              },
            },
            [
              _c(
                "a-input",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: [
                        "icon",
                        { rules: [{ required: false, message: "请选择图标" }] },
                      ],
                      expression:
                        "['icon',{rules: [{ required: false, message: '请选择图标' }]}]",
                    },
                  ],
                  ref: "iconInput",
                  attrs: { enterButton: "选择图标", placeholder: "选择图标" },
                  on: {
                    click: function ($event) {
                      return _vm.iconselect()
                    },
                  },
                },
                [
                  _c("a-icon", {
                    attrs: { slot: "prefix", type: _vm.icon },
                    slot: "prefix",
                  }),
                  _c("a-icon", {
                    attrs: { slot: "suffix", type: "close-circle" },
                    on: { click: _vm.emitEmpty },
                    slot: "suffix",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.menuType === "C",
                  expression: "menuType==='C'",
                },
              ],
              attrs: {
                labelCol: _vm.labelCol,
                wrapperCol: _vm.wrapperCol,
                label: "打开方式",
              },
            },
            [
              _c(
                "a-select",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: [
                        "target",
                        {
                          initialValue: "",
                          rules: [
                            { required: false, message: "请选择打开方式" },
                            { validator: _vm.validatePathTarget },
                          ],
                        },
                      ],
                      expression:
                        "['target', {initialValue:'',rules: [{ required: false, message: '请选择打开方式' },{validator: validatePathTarget}]}]",
                    },
                  ],
                },
                [
                  _c("a-select-option", { attrs: { value: "" } }, [
                    _vm._v("当前窗口"),
                  ]),
                  _c("a-select-option", { attrs: { value: "_blank" } }, [
                    _vm._v("新窗口"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.menuType === "C",
                  expression: "menuType==='C'",
                },
              ],
              attrs: { labelCol: _vm.labelCol, wrapperCol: _vm.wrapperCol },
            },
            [
              _c(
                "span",
                { attrs: { slot: "label" }, slot: "label" },
                [
                  _vm._v(" 链接地址 "),
                  _c(
                    "a-tooltip",
                    {
                      attrs: {
                        title:
                          "链接地址为外链时，打开方式必须为新窗口（antd限制）",
                      },
                    },
                    [_c("a-icon", { attrs: { type: "question-circle-o" } })],
                    1
                  ),
                ],
                1
              ),
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "path",
                      {
                        rules: [
                          {
                            required: false,
                            type: "string",
                            message: "请输入正确的路径",
                          },
                        ],
                      },
                    ],
                    expression:
                      "['path',{\n            rules: [\n              { required: false,type:'string', message: '请输入正确的路径' }\n            ]\n          }]",
                  },
                ],
                attrs: { placeholder: "路径" },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                labelCol: _vm.labelCol,
                wrapperCol: _vm.wrapperCol,
                label: "显示顺序",
              },
            },
            [
              _c("a-input-number", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "orderNum",
                      {
                        initialValue: "1",
                        rules: [{ required: true, message: "请输入顺序数字" }],
                      },
                    ],
                    expression:
                      "['orderNum',{initialValue:'1',rules: [{ required: true, message: '请输入顺序数字' }]}]",
                  },
                ],
                attrs: { placeholder: "显示顺序" },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                labelCol: _vm.labelCol,
                wrapperCol: _vm.wrapperCol,
                label: "状态",
              },
            },
            [
              _c(
                "a-select",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: [
                        "visible",
                        {
                          initialValue: "0",
                          rules: [{ required: true, message: "请选择状态" }],
                        },
                      ],
                      expression:
                        "['visible', {initialValue:'0',rules: [{ required: true, message: '请选择状态' }]}]",
                    },
                  ],
                },
                [
                  _c("a-select-option", { attrs: { value: "0" } }, [
                    _vm._v("显示"),
                  ]),
                  _c("a-select-option", { attrs: { value: "1" } }, [
                    _vm._v("隐藏"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("iconSelector-modal", {
        ref: "modal",
        attrs: { icon: _vm.icon },
        on: { ok: _vm.setIcon },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }