import { isArray } from 'lodash'
import { storage } from '@/utils/storage.js'
import { USER_INFO_KEY, ROLES_KEY } from '@/enums/cacheEnums.js'
import { getPermissionInfo } from '@/services/user.js'

export default {
  namespaced: true,
  state: {
    // 权限完整信息 包括 roles, permissions, menus
    permissionInfo: null,
    // 角色列表
    roleList: [],
    // 权限代码列表
    permCodeList: [],
  },
  getters: {
    permissionInfo(state) {
      return state.permissionInfo || storage.get(USER_INFO_KEY)
    },
    getRoleList(state) {
      return state.roleList.length > 0 ? state.roleList : storage.get(ROLES_KEY)
    },
    getPermCodeList(state) {
      return state.permCodeList
    },
  },
  mutations: {
    setPermissionInfo(state, payload) {
      state.permissionInfo = payload
      storage.set(USER_INFO_KEY, payload)
    },
    setRoleList(state, payload) {
      state.roleList = payload
      storage.set(ROLES_KEY, payload)
    },
    setPermCodeList(state, payload) {
      state.permCodeList = payload
    }
  },
  actions: {
    /**
    * @description 获取权限信息
    */
    async getPermissionInfoAction({ commit }, params) {
      try {
        const res = await getPermissionInfo(params)
        const permissionInfo = res.data.data
        const { roles = [] } = permissionInfo

        if (isArray(roles)) {
          const roleList = roles.map(item => item)
          commit('setRoleList', roleList)
        }
        else {
          permissionInfo.roles = []
          commit('setRoleList', [])
        }

        commit('setPermissionInfo', permissionInfo)
        return permissionInfo
      } catch (e) {
        console.log(e)
      }
    },
  }
}
