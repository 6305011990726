var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-card",
        {
          staticClass: "a_Approval",
          attrs: { title: "被约谈项目", bordered: false },
        },
        [
          _c(
            "a-form-model",
            { ref: "ruleForm", attrs: { model: _vm.params, rules: _vm.rules } },
            [
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "工程管理部",
                            "label-col": { span: 6 },
                            "wrapper-col": { span: 14 },
                            prop: "gcglbId",
                          },
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                placeholder: "请选择",
                                allowClear: "",
                                "filter-option": _vm.filterOption,
                              },
                              on: { change: _vm.handlSelect },
                              model: {
                                value: _vm.params.gcglbId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.params, "gcglbId", $$v)
                                },
                                expression: "params.gcglbId",
                              },
                            },
                            _vm._l(_vm.selectlist, function (items) {
                              return _c(
                                "a-select-option",
                                {
                                  key: items.name,
                                  attrs: {
                                    value: items.value,
                                    title: items.name,
                                  },
                                },
                                [_vm._v(" " + _vm._s(items.name) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "被约谈项目",
                            "label-col": { span: 4 },
                            "wrapper-col": { span: 14 },
                            prop: "projectName",
                          },
                        },
                        [
                          _c(
                            "a-auto-complete",
                            {
                              attrs: {
                                placeholder: "请输入",
                                allowClear: "",
                                "option-label-prop": "value",
                              },
                              on: {
                                search: _vm.handleChange,
                                select: _vm.handlProjectNameSelect,
                              },
                              model: {
                                value: _vm.params.projectName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.params, "projectName", $$v)
                                },
                                expression: "params.projectName",
                              },
                            },
                            [
                              _c(
                                "template",
                                { slot: "dataSource" },
                                _vm._l(_vm.dataSource, function (items) {
                                  return _c(
                                    "a-select-option",
                                    { key: items.title + "" },
                                    [
                                      _c(
                                        "span",
                                        { attrs: { title: items.title } },
                                        [_vm._v(_vm._s(items.title))]
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "年度",
                            "label-col": { span: 6 },
                            "wrapper-col": { span: 14 },
                            prop: "year",
                          },
                        },
                        [
                          _c("a-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              allowClear: "",
                              mode: "year",
                              format: "YYYY",
                            },
                            on: { panelChange: _vm.changeYear },
                            model: {
                              value: _vm.params.year,
                              callback: function ($$v) {
                                _vm.$set(_vm.params, "year", $$v)
                              },
                              expression: "params.year",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "季度",
                            "label-col": { span: 4 },
                            "wrapper-col": { span: 14 },
                            prop: "season",
                          },
                        },
                        [
                          _c(
                            "a-select",
                            {
                              staticStyle: { width: "100%" },
                              model: {
                                value: _vm.params.season,
                                callback: function ($$v) {
                                  _vm.$set(_vm.params, "season", $$v)
                                },
                                expression: "params.season",
                              },
                            },
                            _vm._l(_vm.seasonList, function (item) {
                              return _c(
                                "a-select-option",
                                { key: item.id, attrs: { value: item.id } },
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "约谈类型",
                            "label-col": { span: 6 },
                            "wrapper-col": { span: 14 },
                            prop: "year",
                          },
                        },
                        [
                          _c(
                            "a-select",
                            {
                              staticStyle: { width: "100%" },
                              on: { change: _vm.handleTalkChange },
                              model: {
                                value: _vm.params.talkType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.params, "talkType", $$v)
                                },
                                expression: "params.talkType",
                              },
                            },
                            _vm._l(_vm.interviewList, function (item) {
                              return _c(
                                "a-select-option",
                                { key: item.id, attrs: { value: item.id } },
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "被扣分数",
                            "label-col": { span: 4 },
                            "wrapper-col": { span: 14 },
                            prop: "subScore",
                          },
                        },
                        [
                          _c("a-input", {
                            attrs: { disabled: true },
                            model: {
                              value: _vm.params.subScore,
                              callback: function ($$v) {
                                _vm.$set(_vm.params, "subScore", $$v)
                              },
                              expression: "params.subScore",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "上传附件",
                            "label-col": { span: 6 },
                            "wrapper-col": { span: 14 },
                            prop: "filePath",
                          },
                        },
                        [
                          _c(
                            "a-upload",
                            {
                              staticClass: "avatar-uploader",
                              attrs: {
                                "list-type": "picture-card",
                                accept: _vm.accept,
                                "show-upload-list": false,
                                customRequest: _vm.resourceUpload,
                                beforeUpload: _vm.beforeUpload,
                              },
                              on: { change: _vm.handleUploadChange },
                            },
                            [
                              _vm.params.filePath &&
                              _vm.params.filePath.indexOf("pdf") == -1
                                ? _c("img", {
                                    attrs: {
                                      src: _vm.params.filePath,
                                      alt: "avatar",
                                    },
                                  })
                                : _vm.params.filePath &&
                                  _vm.params.filePath.indexOf("pdf") > -1
                                ? _c("a-icon", {
                                    style: { fontSize: "64px" },
                                    attrs: { type: "file-pdf" },
                                  })
                                : _c(
                                    "div",
                                    [
                                      _c("a-icon", {
                                        attrs: {
                                          type: _vm.uploading
                                            ? "loading"
                                            : "plus",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "ant-upload-text" },
                                        [_vm._v("上传附件")]
                                      ),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                color: "#bfbfbf",
                                "font-size": "14px",
                                "margin-top": "-28px",
                              },
                            },
                            [
                              _vm._v(" 图片或pdf格式 "),
                              _c(
                                "div",
                                { staticStyle: { "margin-top": "-20px" } },
                                [
                                  _vm.params.filePath
                                    ? _c(
                                        "a-button",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "link",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handlePreview(
                                                _vm.params.filePath
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 预览 ")]
                                      )
                                    : _vm._e(),
                                  _vm.params.filePath
                                    ? _c(
                                        "a-button",
                                        {
                                          attrs: { type: "link" },
                                          on: { click: _vm.handleClear },
                                        },
                                        [_vm._v(" 清除 ")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-row",
            [
              _c("a-col", { attrs: { span: 12 } }),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        "label-col": { span: 4 },
                        "wrapper-col": { span: 14 },
                        prop: "year",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex flex-row-reverse" },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.handleSubmit },
                            },
                            [_vm._v("保存")]
                          ),
                          _c(
                            "a-button",
                            { staticClass: "mr-2", on: { click: _vm.close } },
                            [_vm._v("返回")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }