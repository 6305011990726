var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { footer: null, width: "840px" },
      on: { cancel: _vm.close },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c("template", { slot: "title" }, [
        _c(
          "div",
          { staticStyle: { padding: "0 64px" } },
          [
            _c(
              "a-steps",
              { attrs: { current: _vm.step } },
              [
                _c("a-step", { attrs: { title: "第一步" } }),
                _c("a-step", { attrs: { title: "第二步" } }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm.step == 0
        ? _c(
            "div",
            { staticClass: "margin-auto" },
            [
              _c(
                "a-radio-group",
                {
                  model: {
                    value: _vm.radioValue,
                    callback: function ($$v) {
                      _vm.radioValue = $$v
                    },
                    expression: "radioValue",
                  },
                },
                [
                  _c(
                    "a-radio",
                    {
                      staticClass: "radioStyle",
                      attrs: { value: 1, disabled: _vm.safeList.length == 0 },
                    },
                    [
                      _vm._v(" 更新现有安全管理制度 "),
                      _c(
                        "a-tooltip",
                        [
                          _vm.safeList.length == 0
                            ? _c("template", { slot: "title" }, [
                                _vm._v(
                                  " 暂无安全管理制度，请新增制度后再更新 "
                                ),
                              ])
                            : _vm._e(),
                          _vm.safeList.length == 0
                            ? _c("a-icon", {
                                attrs: { type: "question-circle" },
                              })
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-radio",
                    { staticClass: "radioStyle", attrs: { value: 2 } },
                    [_vm._v(" 创建新安全管理制度 ")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleStep(0)
                        },
                      },
                    },
                    [_vm._v("确认")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.step == 1
        ? _c("div", [
            _vm.radioValue == 1 && !_vm.params
              ? _c("div", { staticClass: "margin-auto" }, [
                  _c(
                    "div",
                    { staticClass: "dis-flex mb-2" },
                    [
                      _c("span", { staticClass: "mr-2" }, [_vm._v("选择制度")]),
                      _c("a-select", {
                        staticStyle: { width: "260px" },
                        attrs: { options: _vm.safeList, allowClear: "" },
                        model: {
                          value: _vm.safeid,
                          callback: function ($$v) {
                            _vm.safeid = $$v
                          },
                          expression: "safeid",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.handleStep(1)
                            },
                          },
                        },
                        [_vm._v("确认")]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm.params
              ? _c(
                  "div",
                  [
                    _c(
                      "a-form-model",
                      {
                        ref: "ruleForm",
                        attrs: { model: _vm.params, rules: _vm.rules },
                      },
                      [
                        _c(
                          "a-row",
                          { attrs: { gutter: 16 } },
                          [
                            _c(
                              "a-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "a-form-model-item",
                                  {
                                    attrs: {
                                      label: "制度类别",
                                      "label-col": { span: 6 },
                                      "wrapper-col": { span: 14 },
                                      prop: "systemcatid",
                                    },
                                  },
                                  [
                                    _c("a-select", {
                                      ref: "select",
                                      attrs: {
                                        options: _vm.options1,
                                        allowClear: "",
                                      },
                                      model: {
                                        value: _vm.params.systemcatid,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.params,
                                            "systemcatid",
                                            $$v
                                          )
                                        },
                                        expression: "params.systemcatid",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "a-form-model-item",
                                  {
                                    attrs: {
                                      label: "级次",
                                      "label-col": { span: 6 },
                                      "wrapper-col": { span: 14 },
                                      prop: "levelid",
                                    },
                                  },
                                  [
                                    _c("a-select", {
                                      attrs: {
                                        options: _vm.options2,
                                        allowClear: "",
                                      },
                                      model: {
                                        value: _vm.params.levelid,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.params, "levelid", $$v)
                                        },
                                        expression: "params.levelid",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "a-form-model-item",
                                  {
                                    attrs: {
                                      label: "管理职能类别",
                                      "label-col": { span: 6 },
                                      "wrapper-col": { span: 14 },
                                      prop: "funccatid",
                                    },
                                  },
                                  [
                                    _c("a-select", {
                                      attrs: {
                                        options: _vm.options3,
                                        allowClear: "",
                                      },
                                      model: {
                                        value: _vm.params.funccatid,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.params, "funccatid", $$v)
                                        },
                                        expression: "params.funccatid",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "a-form-model-item",
                                  {
                                    attrs: {
                                      label: "管理职能内容",
                                      "label-col": { span: 6 },
                                      "wrapper-col": { span: 14 },
                                      prop: "funcontent",
                                    },
                                  },
                                  [
                                    _c("a-input", {
                                      attrs: {
                                        placeholder: "请输入管理职能内容",
                                      },
                                      model: {
                                        value: _vm.params.funcontent,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.params,
                                            "funcontent",
                                            $$v
                                          )
                                        },
                                        expression: "params.funcontent",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "a-form-model-item",
                                  {
                                    attrs: {
                                      label: "制度名称",
                                      "label-col": { span: 6 },
                                      "wrapper-col": { span: 14 },
                                      prop: "systemname",
                                    },
                                  },
                                  [
                                    _c("a-input", {
                                      attrs: { placeholder: "请输入制度名称" },
                                      model: {
                                        value: _vm.params.systemname,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.params,
                                            "systemname",
                                            $$v
                                          )
                                        },
                                        expression: "params.systemname",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "a-form-model-item",
                                  {
                                    attrs: {
                                      label: "制度主管部门",
                                      "label-col": { span: 6 },
                                      "wrapper-col": { span: 14 },
                                      prop: "systemdeptid",
                                    },
                                  },
                                  [
                                    _c("a-select", {
                                      attrs: {
                                        options: _vm.options4,
                                        allowClear: "",
                                      },
                                      model: {
                                        value: _vm.params.systemdeptid,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.params,
                                            "systemdeptid",
                                            $$v
                                          )
                                        },
                                        expression: "params.systemdeptid",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "a-form-model-item",
                                  {
                                    attrs: {
                                      label: "发文日期",
                                      "label-col": { span: 6 },
                                      "wrapper-col": { span: 14 },
                                      prop: "pubdate",
                                    },
                                  },
                                  [
                                    _c("a-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        allowClear: "",
                                        mode: "date",
                                        format: "YYYY-MM-DD",
                                        valueFormat: "YYYY-MM-DD",
                                      },
                                      model: {
                                        value: _vm.params.pubdate,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.params, "pubdate", $$v)
                                        },
                                        expression: "params.pubdate",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "a-form-model-item",
                                  {
                                    attrs: {
                                      label: "生效日期",
                                      "label-col": { span: 6 },
                                      "wrapper-col": { span: 14 },
                                      prop: "effectdate",
                                    },
                                  },
                                  [
                                    _c("a-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        allowClear: "",
                                        mode: "date",
                                        format: "YYYY-MM-DD",
                                        valueFormat: "YYYY-MM-DD",
                                      },
                                      model: {
                                        value: _vm.params.effectdate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.params,
                                            "effectdate",
                                            $$v
                                          )
                                        },
                                        expression: "params.effectdate",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "a-card",
                                  [
                                    _c(
                                      "a-form-model-item",
                                      {
                                        attrs: {
                                          label: "最新发文文号",
                                          "label-col": { span: 6 },
                                          "wrapper-col": { span: 14 },
                                        },
                                      },
                                      [
                                        _c("a-input", {
                                          attrs: {
                                            placeholder: "请输入制度名称",
                                          },
                                          model: {
                                            value: _vm.params.nowno,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.params, "nowno", $$v)
                                            },
                                            expression: "params.nowno",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-form-model-item",
                                      {
                                        attrs: {
                                          label: "上传附件",
                                          "label-col": { span: 6 },
                                          "wrapper-col": { span: 14 },
                                        },
                                      },
                                      [
                                        _c(
                                          "a-upload",
                                          {
                                            key: "safe",
                                            staticClass: "avatar-uploader",
                                            attrs: {
                                              "list-type": "picture-card",
                                              accept: _vm.accept,
                                              "file-list": _vm.filePath,
                                              customRequest: _vm.resourceUpload,
                                              beforeUpload: _vm.beforeUpload,
                                              multiple: "",
                                            },
                                            on: {
                                              change: _vm.handleUploadChange,
                                              preview: _vm.handlePreview,
                                            },
                                          },
                                          [
                                            _vm.filePath.length < 10
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c("a-icon", {
                                                      attrs: {
                                                        type: _vm.uploading
                                                          ? "loading"
                                                          : "plus",
                                                      },
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ant-upload-text",
                                                      },
                                                      [_vm._v("上传附件")]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.optionType == "edit"
                      ? _c(
                          "a-row",
                          { staticStyle: { "padding-top": "16px" } },
                          [
                            _c("a-col", { attrs: { span: 12 } }),
                            _c(
                              "a-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "a-form-model-item",
                                  {
                                    attrs: {
                                      "label-col": { span: 4 },
                                      "wrapper-col": { span: 14 },
                                      prop: "year",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "flex flex-row-reverse" },
                                      [
                                        _c(
                                          "a-button",
                                          {
                                            attrs: { type: "primary" },
                                            on: { click: _vm.handleSubmit },
                                          },
                                          [_vm._v("保存")]
                                        ),
                                        _c(
                                          "a-button",
                                          {
                                            staticClass: "mr-2",
                                            on: { click: _vm.close },
                                          },
                                          [_vm._v("返回")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }