var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { bordered: false } },
    [
      _c(
        "a-row",
        [
          _c("a-col", { attrs: { span: 24 } }, [
            _c(
              "div",
              { staticStyle: { "text-align": "center", "font-size": "28px" } },
              [_vm._v(" " + _vm._s(_vm.user.loginLaoWuUser.projectName) + " ")]
            ),
          ]),
        ],
        1
      ),
      _c(
        "a-row",
        {
          staticStyle: { "margin-top": "20px" },
          attrs: { type: "flex", justify: "center", align: "top" },
        },
        [
          _c(
            "a-col",
            [
              _vm.projectConfig.yqsbFrequency == 1
                ? _c(
                    "a-radio-group",
                    {
                      attrs: { "default-value": _vm.dateChoose },
                      on: { change: _vm.dateChooseChange },
                      model: {
                        value: _vm.dateChoose,
                        callback: function ($$v) {
                          _vm.dateChoose = $$v
                        },
                        expression: "dateChoose",
                      },
                    },
                    [
                      _c("a-radio-button", { attrs: { value: "0" } }, [
                        _vm._v(" 昨天 "),
                      ]),
                      _c("a-radio-button", { attrs: { value: "1" } }, [
                        _vm._v(" 今天 "),
                      ]),
                      _c("a-radio-button", { attrs: { value: "2" } }, [
                        _vm._v(" 明天 "),
                      ]),
                      _c(
                        "a-radio-button",
                        {
                          attrs: { value: "3", disabled: _vm.dateChoose != 3 },
                        },
                        [_vm._v(" 其他 ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.projectConfig.yqsbFrequency == 2
                ? _c(
                    "a-radio-group",
                    {
                      attrs: { "default-value": _vm.dateChoose },
                      on: { change: _vm.dateChooseChange },
                      model: {
                        value: _vm.dateChoose,
                        callback: function ($$v) {
                          _vm.dateChoose = $$v
                        },
                        expression: "dateChoose",
                      },
                    },
                    [
                      _c("a-radio-button", { attrs: { value: "0" } }, [
                        _vm._v(" 上周 "),
                      ]),
                      _c("a-radio-button", { attrs: { value: "1" } }, [
                        _vm._v(" 本周 "),
                      ]),
                      _c("a-radio-button", { attrs: { value: "2" } }, [
                        _vm._v(" 下周 "),
                      ]),
                      _c(
                        "a-radio-button",
                        {
                          attrs: { value: "3", disabled: _vm.dateChoose != 3 },
                        },
                        [_vm._v(" 其他 ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.projectConfig.yqsbFrequency == 3
                ? _c(
                    "a-radio-group",
                    {
                      attrs: { "default-value": _vm.dateChoose },
                      on: { change: _vm.dateChooseChange },
                      model: {
                        value: _vm.dateChoose,
                        callback: function ($$v) {
                          _vm.dateChoose = $$v
                        },
                        expression: "dateChoose",
                      },
                    },
                    [
                      _c("a-radio-button", { attrs: { value: "0" } }, [
                        _vm._v(" 上月 "),
                      ]),
                      _c("a-radio-button", { attrs: { value: "1" } }, [
                        _vm._v(" 本月 "),
                      ]),
                      _c("a-radio-button", { attrs: { value: "2" } }, [
                        _vm._v(" 下月 "),
                      ]),
                      _c(
                        "a-radio-button",
                        {
                          attrs: { value: "3", disabled: _vm.dateChoose != 3 },
                        },
                        [_vm._v(" 其他 ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("a-col", [
            _c(
              "div",
              { staticStyle: { "margin-left": "5px" } },
              [
                _vm.projectConfig.yqsbFrequency == 1
                  ? _c("a-date-picker", {
                      attrs: { format: _vm.dayFormat },
                      on: { change: _vm.dateChange },
                      model: {
                        value: _vm.day,
                        callback: function ($$v) {
                          _vm.day = $$v
                        },
                        expression: "day",
                      },
                    })
                  : _vm._e(),
                _vm.projectConfig.yqsbFrequency == 2
                  ? _c("a-week-picker", {
                      attrs: { format: _vm.weekFormat, placeholder: "选择周" },
                      on: { change: _vm.dateChange },
                      model: {
                        value: _vm.week,
                        callback: function ($$v) {
                          _vm.week = $$v
                        },
                        expression: "week",
                      },
                    })
                  : _vm._e(),
                _vm.projectConfig.yqsbFrequency == 3
                  ? _c("a-month-picker", {
                      attrs: { format: _vm.monthFormat, placeholder: "选择月" },
                      on: { change: _vm.dateChange },
                      model: {
                        value: _vm.month,
                        callback: function ($$v) {
                          _vm.month = $$v
                        },
                        expression: "month",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "a-row",
        {
          staticStyle: { "margin-top": "30px" },
          attrs: { align: "top", type: "flex" },
        },
        [
          _c(
            "a-card",
            {
              staticStyle: { width: "30%", display: "inline-block" },
              attrs: { title: "申报数据概况" },
            },
            [
              _c("a-row", [
                _vm._v(" 应申报人数：" + _vm._s(_vm.report.allNum) + "人 "),
              ]),
              _c("a-row", [
                _vm._v(" 已申报人数：" + _vm._s(_vm.report.reportNum) + "人 "),
              ]),
              _c("a-row", [
                _vm._v(
                  " 未申报人数：" + _vm._s(_vm.report.noReportNum) + "人 "
                ),
              ]),
            ],
            1
          ),
          _c(
            "a-card",
            {
              staticStyle: {
                "margin-left": "5%",
                width: "65%",
                display: "inline-block",
              },
              attrs: { title: "未申报班组列表" },
            },
            [
              _c("a-table", {
                attrs: {
                  columns: _vm.columns,
                  "data-source": _vm.report.epidemicReportTeamStatisticsVoList,
                },
                scopedSlots: _vm._u([
                  {
                    key: "num",
                    fn: function (text, record) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(record.noReportNum + "/" + record.allNum)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }