<template>
  <div>
    <a-card title="新增省双优检评时间安排" class="a_Approval" :bordered="false">
      <form-index
        ref="formindex"
        :formData="formData"
        @handleSubmit="submit"
        :bottomSubmit="true"
        @handlSelect="handlSelect"
        @handleChange="handleChange"
      >
        <!-- 下部分按钮插槽 -->
        <template #bottomSubmit>
          <a-button class="ml-2" type="primary" html-type="submit"> 保存 </a-button>
          <a-button @click="close"> 返回 </a-button>
        </template>
      </form-index>
    </a-card>
  </div>
</template>

<script>
const formData = [
  {
    label: '工程管理部',
    placeholder: '请选择工程管理部',
    decorator: ['gcglbId', { rules: [{ required: true, message: '请选择工程管理部' }] }],
    type: 'select',
    key: 'gcglbId',
    selectlist: [],
    col: 8,
    labelCol: 6,
    wrapperCol: 12,
    disabled: false
  },
  {
    label: "项目名称",
    placeholder: "请输入项目名称",
    decorator: [
      "projectId",
      { rules: [{ required: true, message: "请输入项目名称" }] },
    ],
    type: "inputGroup",
    key: "projectId",
    dataSource: [],
    col: 12,
    labelCol: 6,
    wrapperCol: 8,
    display: true,  
    disabled: false
  },
  {
    label: "检查日期",
    placeholder: "请选择",
    decorator: [
      "checkDate",
      { rules: [{ required: true, message: "请选择" }] },
    ],
    type: "datePicker",
    key: "checkDate",
    col: 8,
    labelCol: 6,
    wrapperCol: 12,
    display: true,
    format: "YYYY-MM-DD",
    styles: {width: '100%'},
    disabled: false
  },
  {
    label: '检查时间',
    placeholder: '请选择检查时间',
    decorator: ['checkDateTime', { rules: [{ required: false, message: '请选择检查时间' }] }],
    type: 'select',
    key: 'checkDateTime',
    selectlist: [
      {name: '上午第一站', value: 0},
      {name: '上午第二站', value: 1},
      {name: '下午第一站', value: 2},
      {name: '下午第二站', value: 3},
      {name: '下午第三站', value: 4},
    ],
    col: 12,
    labelCol: 6,
    wrapperCol: 8,
    disabled: false
  },
  {
    label: '检评性质',
    placeholder: '请选择检评性质',
    decorator: ['checkType', { rules: [{ required: true, message: '请选择检评性质' }] }],
    type: 'select',
    key: 'checkType',
    selectlist: [
      {name: '初评', value: 0},
      {name: '复评', value: 1},
    ],
    col: 8,
    labelCol: 6,
    wrapperCol: 12,
    disabled: false
  },
  {
    label: "参评序号",
    placeholder: "请输入参评序号",
    decorator: [
      "checkNum",
      { rules: [{ required: false, message: "请输入参评序号" }] },
    ],
    type: "input",
    key: "checkNum",
    col: 12,
    labelCol: 6,
    wrapperCol: 8,
    display: true,  
    disabled: false
  },
  {
    label: "带队人员",
    placeholder: "请输入带队人员",
    decorator: [
      "leaders",
      { rules: [{ required: true, message: "请输入带队人员" }] },
    ],
    type: "input",
    key: "leaders",
    col: 8,
    labelCol: 6,
    wrapperCol: 12,
    display: true,  
    disabled: false
  },
  {
    label: '注意事项',
    placeholder: '请选择注意事项',
    decorator: ['remarks', { rules: [{ required: false, message: '请选择注意事项' }] }],
    type: 'select',
    key: 'remarks',
    selectlist: [
      {name: '备午饭', value: '备午饭'},
      {name: '备晚饭', value: '备晚饭'},
    ],
    col: 12,
    labelCol: 6,
    wrapperCol: 8,
    disabled: false
  },
  {
    label: '组长',
    placeholder: '请选择组长',
    decorator: ['captainId', { rules: [{ required: false, message: '请选择组长' }] }],
    type: "inputGroup",
    key: "captainId",
    dataSource: [],
    col: 8,
    labelCol: 6,
    wrapperCol: 12,
    disabled: false
  },
  {
    label: '组员',
    placeholder: '请选择组员',
    decorator: ['teamsId', { rules: [{ required: false, message: '请选择组员' }] }],
    type: "select",
    key: "teamsId",
    selectlist: [],
    mode: 'tags',
    col: 12,
    labelCol: 6,
    wrapperCol: 8,
    disabled: false
  },
];
import formIndex from "@/pages/components/form/index";
import { QUERY_OFFICE_BY_USER } from "@/services/api";
import { request } from "@/utils/request";
import { mapGetters } from 'vuex'
import { getExcellentProject } from "@/services/statistics";
import { getExcellentMasterList, insertCivilizedExcellent, getCivilizedExcellentDetail, updateCivilizedExcellent } from "@/services/civilizedExcellent";
import moment from 'moment';
export default {
  components: {
    formIndex,
  },
  inject: ["fatherMethod"],
  computed: {
    ...mapGetters('account', ['user']),
  },
  data() {
    return {
      isEdit: 0,
      detailObj: null, //保存详情的对象
      formData: formData,
      params: {
        id: '',
        projectId: '',
        gcglbId: '',
        projectName: '',
      },
      ptype: 1, // 1:房间，2：市政
      allProjectArr: [],
      allCaptainArr: [],
      allCrewArr: []
    };
  },
  activated() {
    this.isEdit = Number(this.$route.query.isEdit);
    this.getgcglbList(QUERY_OFFICE_BY_USER);
    //获取项目名称
    this.getProjectStatissKqS('')
    //获取组长
    this.getExcellentMasterListData('captainId')
    //获取组员
    this.getExcellentMasterListData('teamsId')
    if (this.isEdit === 1) {
      this.params.id = this.$route.query.id;
      this.getDetail()
    }
  },

  methods: {
    getProjectStatissKqS(projectName) {
      getExcellentProject({gcglbId: this.params.gcglbId, projectName}).then((res) => {
        if (res.data.code === 0) {
          this.formData.forEach((item) => {
            if ('projectId' === item.key) {
              let arr = []
              res.data.data.forEach(el => {
                arr.push({
                  title: el.projectName,
                  id: el.projectId,
                  gcglbId: el.gcglbId,
                  ptype: el.ptype
                })
              })
              item.dataSource = arr
              if (!projectName) {
                this.allProjectArr = arr
              }
            }
          });
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    getgcglbList(path) {
      request(path, "get").then((res) => {
        let data = res.data.data.filter(
          (item) => item.id != "3da0f5533f87425c9a9e8a11a7923300"
        );
        this.formDataFn("gcglbId", data);
      });
    },
    getDetail() {
      getCivilizedExcellentDetail(this.params.id).then(res => {
        if (res.data.code === 0) {
          this.detailObj = res.data.data
          if (res.data.data.teamsId) {
            res.data.data.teamsId = res.data.data.teamsId.split(',')
          } else {
            res.data.data.teamsId = []
          }
          if (res.data.data.captainId) {
            res.data.data.captainId = res.data.data.captainId + '' //转字符串
          }
          res.data.data.checkDate = moment(res.data.data.checkDate).format('YYYY-MM-DD')
          this.$refs.formindex.queryObj(res.data.data)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    getExcellentMasterListData(key = '', name = '') {
      getExcellentMasterList({ name, pageSize: 999, pageNumber: 1}).then((res) => {
        if (res.data.code === 0) {
          this.formData.forEach((item) => {
            let arr = []
            if (key === item.key) {
              if (key == 'captainId') {
                res.data.data.list.forEach(el => {
                  arr.push({
                    title: el.name,
                    id: Number(el.id),
                  })
                })
                item.dataSource = arr
                if (!name) {
                  this.allCaptainArr = arr
                }
              } else {
                res.data.data.list.forEach(el => {
                  arr.push({
                    name: el.name,
                    value: el.id,
                  })
                })
                item.selectlist = arr
                if (!name) {
                  this.allCrewArr = arr
                }
              }
            }
          });
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      let arr = [];
      if (key == 'gcglbId') {
        data.forEach((item) => {
          arr.push({ name: item.master, value: item.id });
        });
      }
      this.formData.forEach((item) => {
        if (item.key == key) {
          item.selectlist = arr;
        }
      });
    },
    handlSelect(obj) {
      const { val, keys } = obj
      console.log(val, keys)
      if (keys === 'gcglbId') {
        this.params.gcglbId = val
        this.getProjectStatissKqS('')
      } else if (keys === 'projectId') {
        this.params.projectId = val
      }
    },
    // 输入选择框触发
    handleChange(e) {
      console.log(e)
      if (e.item.key == 'projectId') {
        this.getProjectStatissKqS(e.value)
      } else {
        this.getExcellentMasterListData(e.item.key, e.value)
      }
    },
    submit(e) {
      e.checkDate = moment(e.checkDate).valueOf()
      if (e.teamsId) {
        e.teamsId = e.teamsId.join(',')
      }
      if (this.isEdit === 0) {
        this.params = Object.assign(this.params, e)
        this.params.created = this.user.id
        if (!this.allProjectArr.some(el => el.id == this.params.projectId)) {
          this.$message.error("请选择正确项目！");
          return
        }
        console.log(this.allCaptainArr, 'this.allCaptainArr')
        if (this.params.captainId && !this.allCaptainArr.some(el => el.id == this.params.captainId)) {
          this.$message.error("请选择正确组长！");
          return
        }
        let allCrewIdArr = this.allCrewArr.map(el => el.value)
        if (this.params.teamsId && !this.params.teamsId.split(',').every(el => allCrewIdArr.includes(el))) {
          this.$message.error("请选择正确组员！");
          return
        }
        insertCivilizedExcellent(this.params).then((res) => {
          let d = res.data;
          if (d.code === 0) {
            this.$message.success("新增成功");
            this.close();
          } else {
            this.$message.error(res.data.msg);
          }
        });
      } else {
        this.params = Object.assign(this.detailObj, Object.assign(this.params, e))
        this.params.updated = this.user.id
        if (!this.allProjectArr.some(el => el.id == this.params.projectId)) {
          this.$message.error("请选择正确项目！");
          return
        }
        if (this.params.captainId && !this.allCaptainArr.some(el => el.id == this.params.captainId)) {
          this.$message.error("请选择正确组长！");
          return
        }
        let allCrewIdArr = this.allCrewArr.map(el => el.value)
        if (this.params.teamsId && !this.params.teamsId.split(',').every(el => allCrewIdArr.includes(el))) {
          this.$message.error("请选择正确组员！");
          return
        }
        updateCivilizedExcellent(this.params).then((res) => {
          let d = res.data;
          if (d.code === 0) {
            this.$message.success("修改成功");
            this.close();
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
    },
    close() {
      let path = this.$route.path;
      this.fatherMethod(`${path}`);
      this.$router.push({
        name: "省双优检评时间安排",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.a_Approval {
  background: #fff;
  border-radius: 10px;
}
</style>
