<template>
	<div>
		<div class="a_Approval" v-show="$route.meta.showFather">
			<div class="plug-Overview">专家信息列表</div>
			<a-divider/>
			<div class="a_a_footer">
				<div class="mb-1">
					<a-space class="operator">
						<a-button @click="addNewRecord" type="primary" v-if="user.nowRoleKey.includes('OFFICE_AQMGLY')">新增</a-button>
					</a-space>
				</div>
				<!-- 表格封装 -->
				<standard-table
					class=""
					:columns="columns"
					:dataSource="dataSource"
					rowKey="id"
					:pagination="pagination"
					:loading="tableLoading"
					@change="onPageChange"
					:isAlert="false"
					:bordered="true"
				>
					<template slot="index" slot-scope="{ index }">
						<span>
							{{
								(pagination.current - 1) * pagination.pageSize +
								parseInt(index) +
								1
							}}
						</span>
					</template>
					<template slot="checkDirection" slot-scope="{ record }">
						<span>{{getCheckDir(record.checkDirection)}}</span>
					</template>
					<template slot="action" slot-scope="{ record }" v-if="user.nowRoleKey.includes('OFFICE_AQMGLY')">
						<div class="sTables">
							<div @click="visit(record)">编辑</div>
							<div @click="del(record)">删除</div>
						</div>
					</template>
				</standard-table>
			</div>
		</div>
		<router-view></router-view>
	</div>
</template>

<script>
import StandardTable from "@/components/table/StandardTable";
import { mapGetters } from 'vuex'
import { getExcellentMasterList, delExcellentMaster } from "@/services/civilizedExcellent";
const columns = [
	{
		title: "序号",
		width: 65,
		scopedSlots: { customRender: "index" },
		// customRender: (text, record, index) => `${index + 1}`,
		align: "center",
	},
	{
		title: "姓名",
		dataIndex: "name",
		width: 180,
		align: "center"
	},

	{
		title: "联系方式",
		dataIndex: "phone",
		align: "center",
		width: 180
	},
	{
		title: "工作单位",
		dataIndex: "workUnit",
		width: 380,
		ellipsis: true,
		tooltip: true
	},
	{
		title: "职务",
		dataIndex: "post",
		align: "center",
		width: 150
	},
	{
		title: "检查方向",
		dataIndex: "checkDirection",
		key: "checkDirection",
		align: "center",
		scopedSlots: { customRender: "checkDirection" },
	},
	{
		title: "信息收集",
		dataIndex: "information",
		key: "information",
		ellipsis: true,
		tooltip: true
	},
	{
		title: "操作",
		align: "center",
		width: 130,
		scopedSlots: { customRender: "action" },
	},
];
export default {
	components: {
		StandardTable,
	},
	data() {
		return {
			columns: columns,
			dataSource: [], //table数组
			pagination: {
				//分页数据
				current: 1,
				pageSize: 10,
				total: 0,
				showTotal: (total, range) => `共 ${total} 条`,
			},
			params: {
				pageNumber: 1,
				pageSize: 10,
				startTime: '',
				endTime: '',
				checkType: ''
				// year: moment(new Date()).format('YYYY')
			},
			tableLoading: false, //table加载中
			checkDirectionList: [
				{name: '文施', value: '0'},
				{name: '土建', value: '1'},
				{name: '机电', value: '2'},
			],
		};
	},
	computed: {
		...mapGetters('account', ['user']),
	},
	activated() {
		this.init()
	},
	methods: {
		init() {
			this.aqxjlist(this.params);
		},
		getCheckDir(item) {
			if (item) {
				const values = item.split(',');
				const result = values.map(value => {
					const item = this.checkDirectionList.find(obj => obj.value === value);
					return item ? item.name : null;
				});
				return result.join('，')
			} else {
				return ''
			}
		},
		aqxjlist(params) {
			this.tableLoading = true;
			getExcellentMasterList(params).then((res) => {
				if (res.data.code === 0) {
					this.dataSource = res.data.data.list;
					this.pagination.total = res.data.data.total;
				} else {
					this.$message.error(res.data.msg)
				}
				this.tableLoading = false;
			});
		},
		addNewRecord() {
			this.$router.push({
				name: '新增专家信息',
				query: {
					isEdit: 0,
				},
			});
		},
		//表格 - 分页查询
		onPageChange(e) {
			this.params.pageNumber = e.current;
			this.pagination.current = e.current;
			this.aqxjlist(Object.assign({}, this.params));
		},
		//查看
		visit(record) {
			this.$router.push({
				name: '专家信息详情',
				query: {
					id: record.id,
					isEdit: 1
				},
			});
		},
		del(record) {
			const _that = this
			this.$confirm({
				title: '是否确定删除？',
				cancelText: '否',
				okText: '是',
				onOk: () => {
					delExcellentMaster(record.id).then(res => {
						if (res.data.code === 0) {
							_that.$message.success('删除成功')
							_that.aqxjlist(this.params);
						} else {
							_that.$message.error(res.data.msg)
						}
					})
				}
			})
		},
	},
};
</script>

<style lang="less" scoped>
.a_a_footer {
	padding: 20px;
	background: #ffffff;

	.operator {
		margin-right: 8px;
	}

	.sTables {
		display: flex;
		justify-content: space-between;
		align-items: center;

		div {
			width: 100%;
			text-align: center;
			cursor: pointer;
		}

		div:nth-child(1) {
			color: #307dfa;
		}

		div:nth-child(2) {
			color: #ff5c77;
		}

		div:nth-child(3) {
			color: #615e83;
		}
	}
}
.content_div {
	padding: 5px 0;
	cursor: pointer;
}
.content_div:hover {
	color: #307dfa;
}
.plug-Overview {
	font-size: 16px;
	color: #101010;
	font-weight: bold;
}
</style>
