<template>
  <div class="a_Approval">
    <div class="plug-Overview">职工伤亡事故报表</div>
    <a-divider />
    <!-- <a-form-model layout="inline" :model="params" class="mb-12 query-form">
      <a-form-model-item label="月度" class="ml-12">
        <a-month-picker style="width: 220px" placeholder="请选择月份" format="YYYY-MM" v-model="monthPickerVal">
          <a-icon slot="suffixIcon" type="smile" />
        </a-month-picker>
      </a-form-model-item>
    </a-form-model> -->
    <!-- <a-space>
      <a-button type="primary" @click="handleQuery"> 查询 </a-button>
      <a-button @click="resetQuery"> 重置 </a-button>
      <a-button type="primary" @click="doDowload" v-if="user.isGcglb == 0 && user.loginType == 1 && user.nowRoleKey.includes('JS_LJJ')"> 导出 </a-button>
    </a-space> -->

    <standard-table
      :columns="columns"
      :dataSource="tableData"
      rowKey="id"
      :pagination="false"
      :loading="tableLoading"
      :isAlert="false"
      :bordered="true"
    >
      <a
        slot="action"
        slot-scope="{ index, record }"
        @click="() => handleEdit(record)"
      >
        编辑
      </a>
    </standard-table>

    <Export-loading
      :cPercent="cPercent"
      :isExporting="isExporting"
      :loadingText="loadingText"
    ></Export-loading>
  </div>
</template>

<script>
import {
  getCasualtyAccidentInfo,
  casualtyAccidentExportUrl,
} from "@/services/safeMonthlyReport";
import { request } from "@/utils/request";
import { PROJECT_OFFICE_CHILD } from "@/services/api";
import ExportLoading from "@/components/ExportLoading/ExportLoading"; // progress组件
import { exportProgress } from "@/pages/aqjyzl/export"; // 导出方法
import { mapGetters } from "vuex";
import StandardTable from "@/components/table/StandardTable";
import moment from "moment";

const columns = [
  {
    title: "职工平均人数（人）",
    align: "center",
    children: [
      {
        title: "总计",
        dataIndex: "totalPerson",
        align: "center",
        // width: 80,
      },
      {
        title: "本企业职工",
        align: "center",
        children: [
          {
            title: "小计",
            dataIndex: "employeeNums",
            align: "center",
            // width: 80,
          },
          {
            title: "其中：临时工",
            dataIndex: "partTimeNums",
            align: "center",
            // width: 80,
          },
        ]
      },
      {
        title: "分包人员",
        dataIndex: "subpackageNums",
        align: "center",
        width: 80,
      },
    ],
  },
  {
    title: "操作",
    align: "center",
    fixed: "right",
    width: 65,
    scopedSlots: { customRender: "action" },
  },
];

export default {
  mixins: [exportProgress], //导出方法注入
  components: {
    ExportLoading,
    StandardTable
  },
  data() {
    return {
      monthPickerVal: moment(new Date(), 'YYYY-MM'),
      params: {
        month: '',
        year: '',
        pageNumber: 1,
        pageSize: 10,
      },
      total: 0,
      columns: columns,
      tableData: [],
      tableLoading: false, //table加载中

      loadingText: "数据加载中...",
      cPercent: 0,
      isExporting: false,
    };
  },
  activated() {
    this.handleQuery();
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  methods: {
    handleQuery(e) {
      this.tableLoading = true
      const now = moment() // 当前时间
      const targetDate = moment().date(25).hour(3).minute(0).second(0) // 设置目标日期为当月的25号03:00
      if (now.isBefore(targetDate)) {
        // console.log('当前时间早于当月25号03:00');
        this.params.year = moment(now).format('YYYY-MM').split('-')[0]
        this.params.month = moment(now).format('YYYY-MM').split('-')[1]
      } else if (now.isAfter(targetDate)) {
        // console.log('当前时间晚于当月25号03:00');
        this.params.year = moment(now).add(1,'months').format('YYYY-MM').split('-')[0]
        this.params.month = moment(now).add(1,'months').format('YYYY-MM').split('-')[1]
      }
      getCasualtyAccidentInfo(this.params).then((res) => {
        this.tableLoading = false
        let d = res.data;
        if (d.code === 0) {
          if (d.data) {
            this.tableData = [d.data]
          } else {
            this.$message.error('本月没有数据！')
          }
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    resetQuery() {
      this.monthPickerVal = null
      this.params = {
        month: '',
        gcglbId: '',
        projectName: '',
        year: '',
        pageNumber: 1,
        pageSize: 10,
      };
      this.handleQuery();
    },
    handleEdit(row) {
      console.log(row)
      this.$router.push({
        path: `/casualty_accident_info_edit`,
        query: {
          id: row.id,
        },
      });
    },
    doDowload() {
      const _that = this
      this.$confirm({
        title: '导出上报后内容不可编辑，确认导出？',
        onOk() {
          let obj = _that.params;
          delete obj.pageNumber
          delete obj.pageSize
          _that.loadingText = "正在导出WORD文件，请稍候 ····";
          _that.isExporting = true;
          _that.cPercent = 0;
          let fileName = "职工伤亡事故报表.docx";
          _that.exportFunNomal2(
            casualtyAccidentExportUrl,
            obj,
            fileName,
            "application/vnd.msword"
          );
        }
      })
    },
  },
};
</script>

<style lang="less" scoped>
@import "@/theme/common.less";
.query-form {
  display: flex;
  // justify-content: space-between;
}
.ant-table td { white-space: nowrap; }
</style>
