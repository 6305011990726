function getAllManufacturerList() {
    const allManufacturerList=[
        {
            manufacturerKey: 'yunqi',
            manufacturerName: '云启'
        }
    ]
    return allManufacturerList;
}

module.exports = {getAllManufacturerList}