var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        { attrs: { gutter: 16 } },
        [
          _c(
            "a-col",
            { attrs: { span: 6 } },
            [
              _c(
                "vue-scroll",
                {
                  staticStyle: { width: "100%", height: "855px" },
                  attrs: { ops: _vm.ops },
                },
                [
                  _c(
                    "a-card",
                    {
                      attrs: {
                        title: "课程管理",
                        size: "small",
                        bordered: false,
                      },
                    },
                    [
                      _c(
                        "a-tree",
                        {
                          attrs: {
                            "load-data": _vm.onLoadData,
                            "tree-data": _vm.treeData,
                            "show-icon": "",
                            "default-expand-all": "",
                          },
                          on: { select: _vm.onSelect },
                          scopedSlots: _vm._u([
                            {
                              key: "title",
                              fn: function (ref) {
                                var title = ref.title
                                return [_c("span", [_vm._v(_vm._s(title))])]
                              },
                            },
                          ]),
                        },
                        [
                          _c("img", {
                            staticStyle: { width: "20px", height: "20px" },
                            attrs: {
                              slot: "typeIcon",
                              src: require("@/assets/img/type.png"),
                            },
                            slot: "typeIcon",
                          }),
                          _c("img", {
                            staticStyle: { width: "20px", height: "20px" },
                            attrs: {
                              slot: "courseIcon",
                              src: require("@/assets/img/course.png"),
                            },
                            slot: "courseIcon",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 17, offset: 0 } },
            [
              _c(
                "a-card",
                {
                  staticStyle: { "margin-right": "-75px" },
                  attrs: { bordered: false },
                },
                [
                  _c("template", { slot: "title" }, [
                    _c("span", [_vm._v(_vm._s(_vm.node.title) + "试卷清单")]),
                  ]),
                  _c(
                    "a-form",
                    { staticClass: "clearfix" },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 5 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: "试卷标题",
                                labelCol: { span: 8 },
                                wrapperCol: { span: 14, offset: 1 },
                              },
                            },
                            [
                              _c("a-input", {
                                attrs: { placeholder: "试卷标题" },
                                model: {
                                  value: _vm.queryParam.title,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryParam, "title", $$v)
                                  },
                                  expression: "queryParam.title",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 5 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: "发布状态",
                                labelCol: { span: 8 },
                                wrapperCol: { span: 14, offset: 1 },
                              },
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.queryParam.status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryParam, "status", $$v)
                                    },
                                    expression: "queryParam.status",
                                  },
                                },
                                [
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "" } },
                                    [_vm._v("全部")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: 0 } },
                                    [_vm._v("未发布")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: 1 } },
                                    [_vm._v("已发布")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: 2 } },
                                    [_vm._v("已关闭")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 2 } },
                        [
                          _c(
                            "a-space",
                            { staticClass: "btnBox" },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.doQuery()
                                    },
                                  },
                                },
                                [_vm._v("查询")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { attrs: { md: 24, sm: 24 } },
                        [
                          _c(
                            "standard-table",
                            {
                              attrs: {
                                columns: _vm.columns,
                                dataSource: _vm.dataSource,
                                selectedRows: _vm.selectedRows,
                                pagination: _vm.pagination,
                                loading: _vm.tableLoading,
                                isAlert: false,
                                bordered: true,
                                btnArr: _vm.tableBtnArr,
                              },
                              on: {
                                "update:selectedRows": function ($event) {
                                  _vm.selectedRows = $event
                                },
                                "update:selected-rows": function ($event) {
                                  _vm.selectedRows = $event
                                },
                                clear: _vm.onClear,
                                change: _vm.onPageChange,
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "action",
                                  fn: function (ref) {
                                    var record = ref.record
                                    return _c(
                                      "div",
                                      {},
                                      [
                                        _c("a-icon", {
                                          staticStyle: { color: "blue" },
                                          attrs: {
                                            type: "edit",
                                            title: "编辑",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.editorPaper(record)
                                            },
                                          },
                                        }),
                                        _c("a-divider", {
                                          attrs: { type: "vertical" },
                                        }),
                                        _c("a-icon", {
                                          staticStyle: { color: "green" },
                                          attrs: {
                                            type: "eye",
                                            title: "试卷预览",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.viewPaperFn(record)
                                            },
                                          },
                                        }),
                                        _c("a-divider", {
                                          attrs: { type: "vertical" },
                                        }),
                                        record.status == 0
                                          ? _c("img", {
                                              attrs: {
                                                src: require("@/assets/img/publish.png"),
                                                title: "发布",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.modifyPaper(
                                                    record,
                                                    1
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        record.status == 0
                                          ? _c("a-divider", {
                                              attrs: { type: "vertical" },
                                            })
                                          : _vm._e(),
                                        record.status == 1
                                          ? _c("img", {
                                              attrs: {
                                                src: require("@/assets/img/cancel.png"),
                                                title: "取消发布",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.modifyPaper(
                                                    record,
                                                    0
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        record.status == 1
                                          ? _c("a-divider", {
                                              attrs: { type: "vertical" },
                                            })
                                          : _vm._e(),
                                        _c("a-icon", {
                                          staticStyle: { color: "red" },
                                          attrs: {
                                            type: "delete",
                                            title: "删除",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deletePaper(record)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  },
                                },
                              ]),
                            },
                            [
                              _c(
                                "template",
                                { slot: "statusTitle" },
                                [
                                  _c("a-icon", {
                                    attrs: { type: "info-circle" },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.onStatusTitleClick($event)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("editor-paper", {
        attrs: {
          testPaper: _vm.testPaper.obj,
          visible: _vm.testPaper.visible,
          type: _vm.testPaper.type,
        },
        on: { questPageClose: _vm.editorPageColse, submit: _vm.doSubmitPaper },
      }),
      _c("auto-paper", {
        attrs: { visible: _vm.auto.visible, oldCourseId: _vm.auto.oldCourseId },
        on: { closeAutoSetting: _vm.closeAutoSetting, submit: _vm.autoSubmit },
      }),
      _c("view-paper", {
        attrs: {
          visible: _vm.viewPaper.visible,
          sigleQuests: _vm.viewPaper.sigleQuests,
          multipleQuests: _vm.viewPaper.multipleQuests,
          judgeQuests: _vm.viewPaper.judgeQuests,
          paperTitle: _vm.viewPaper.paperTitle,
          paperId: _vm.viewPaper.paperId,
        },
        on: { doCloseViewPaper: _vm.doCloseViewPaper, submit: _vm.autoSubmit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }