var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        { attrs: { gutter: 16 } },
        [
          _c(
            "a-col",
            { attrs: { span: 11 } },
            [
              _c("a-card", { staticClass: "pitfall-card" }, [
                _c("div", { attrs: { slot: "title" }, slot: "title" }, [
                  _c("div", { staticClass: "flex a-center" }, [
                    _c("img", {
                      staticStyle: {
                        width: "21px",
                        height: "21px",
                        "margin-right": "5px",
                      },
                      attrs: {
                        src: "https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20241120/1732091350443.png?Expires=2047451350&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=59AEqlAw%2B%2BGyQ%2F%2B4QYdFWxJZ7rs%3D",
                        alt: "",
                      },
                    }),
                    _c("span", { staticClass: "ml-1 font-bold" }, [
                      _vm._v("隐患排查情况"),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "flex-wrap": "wrap",
                      "justify-content": "space-between",
                      padding: "0 13px",
                    },
                  },
                  _vm._l(_vm.content, function (item, index) {
                    return _c("a-col", { key: index, attrs: { span: 12 } }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex flex-column j-center a-center content-box black",
                          class: item.class,
                          style: item.style,
                        },
                        [
                          _c("span", { staticClass: "font-20 font-bold" }, [
                            _vm._v(_vm._s(item.value)),
                            item.unit && item.value != "0"
                              ? _c("span", [_vm._v("%")])
                              : _vm._e(),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "black-three text-center font-14",
                              staticStyle: {
                                "margin-top": "5px",
                                height: "41px",
                              },
                            },
                            [_vm._v(_vm._s(item.text))]
                          ),
                        ]
                      ),
                    ])
                  }),
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 13 } },
            [
              _c("a-card", { staticClass: "risk-card" }, [
                _c("div", { attrs: { slot: "title" }, slot: "title" }, [
                  _c("div", { staticClass: "flex a-center" }, [
                    _c("img", {
                      staticStyle: {
                        width: "21px",
                        height: "21px",
                        "margin-right": "5px",
                      },
                      attrs: {
                        src: "https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20241120/1732091350443.png?Expires=2047451350&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=59AEqlAw%2B%2BGyQ%2F%2B4QYdFWxJZ7rs%3D",
                        alt: "",
                      },
                    }),
                    _c("span", { staticClass: "ml-1 font-bold" }, [
                      _vm._v("风险分级情况"),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "padding-left": "13px",
                      "padding-right": "13px",
                    },
                  },
                  [
                    _c(
                      "a-row",
                      { attrs: { gutter: [16, 8] } },
                      [
                        _c("a-col", { attrs: { span: 8 } }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex flex-column j-center a-center content-box",
                            },
                            [
                              _c("span", { staticClass: "font-20 font-bold" }, [
                                _vm._v(_vm._s(_vm.riskRanking.total || 0)),
                              ]),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "black-three text-center  ellipsis-1",
                                  staticStyle: { "margin-top": "5px" },
                                },
                                [_vm._v("风险总数")]
                              ),
                            ]
                          ),
                        ]),
                        _c("a-col", { attrs: { span: 8 } }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex flex-column j-center a-center content-box",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "font-20 font-bold",
                                  staticStyle: { color: "red" },
                                },
                                [_vm._v(_vm._s(_vm.riskRanking.oneRisk))]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "black-three text-center  ellipsis-1",
                                  staticStyle: { "margin-top": "5px" },
                                },
                                [_vm._v("一级风险个数")]
                              ),
                            ]
                          ),
                        ]),
                        _c("a-col", { attrs: { span: 8 } }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex flex-column j-center a-center content-box",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "font-20 font-bold",
                                  staticStyle: { color: "red" },
                                },
                                [_vm._v(_vm._s(_vm.riskRanking.twoRisk))]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "black-three text-center ellipsis-1",
                                  staticStyle: { "margin-top": "5px" },
                                },
                                [_vm._v("二级风险个数")]
                              ),
                            ]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("a-card", { staticClass: "mt-1 risk-card" }, [
                _c("div", { attrs: { slot: "title" }, slot: "title" }, [
                  _c("div", { staticClass: "flex a-center" }, [
                    _c("img", {
                      staticStyle: {
                        width: "21px",
                        height: "21px",
                        "margin-right": "5px",
                      },
                      attrs: {
                        src: "https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20241120/1732091350443.png?Expires=2047451350&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=59AEqlAw%2B%2BGyQ%2F%2B4QYdFWxJZ7rs%3D",
                        alt: "",
                      },
                    }),
                    _c("span", { staticClass: "ml-1 font-bold" }, [
                      _vm._v("特种机械设备"),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticStyle: { padding: "0 13px" } },
                  [
                    _c(
                      "a-row",
                      { attrs: { gutter: [16, 8] } },
                      _vm._l(_vm.deviceContent, function (item, index) {
                        return _c("a-col", { key: index, attrs: { span: 6 } }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex flex-column j-center a-center content-box black",
                              style: item.style,
                            },
                            [
                              _c("span", { staticClass: "font-20 font-bold" }, [
                                _vm._v(_vm._s(item.value)),
                              ]),
                              _c(
                                "span",
                                { staticClass: "black-three text-center mt-5" },
                                [_vm._v(_vm._s(item.label))]
                              ),
                            ]
                          ),
                        ])
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }