<template>
  <div class="a_Approval">
    <div class="plug-Overview">月报汇总台账</div>
    <a-divider />
    <a-form-model layout="inline" :model="params" class="mb-2 query-form" @submit="handleSubmit">
      <a-form-model-item label="月度" class="ml-12">
        <a-month-picker style="width: 220px" placeholder="请选择月份" format="YYYY-MM" v-model="monthPickerVal">
          <a-icon slot="suffixIcon" type="smile" />
        </a-month-picker>
      </a-form-model-item>
      <div>
        <a-space>
          <a-button class="mr-9" type="primary" html-type="submit"> 查询 </a-button>
          <a-button class="mr-9" @click="resetQuery"> 重置 </a-button>
          <span class="pointer ml-3" style="color: #2BA4FF;border-bottom: 1px solid #2BA4FF;" @click="uploadRecord">未确认记录台账</span>
          <!-- <span class="pointer" style="color: #2BA4FF;" @click="uploadRecord">查看</span> -->
        </a-space>
      </div>
    </a-form-model>
    <standard-table
      :columns="columns"
      :dataSource="tableData"
      rowKey="id"
      :pagination="pagination"
      :loading="tableLoading"
      @change="handleChangePage"
      :isAlert="false"
      :bordered="true"
    >
      <template slot="index" slot-scope="{ index }">
        <span>{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}</span>
      </template>
      <template slot="yearMonth" slot-scope="{ record }">
        <span>{{record.year}}-{{record.month}}</span>
      </template>
      <div slot="action" slot-scope="{ text, record }">
        <a
          @click="() => doDowloadPre(record)"
        >
          下载资料
        </a>
      </div>
    </standard-table>
    <a-modal title="未确认记录台账" :visible="uploadRecordVisible" :width="1400" :footer="null" @cancel="handleCancelUploadRecord">
      <a-tabs :default-active-key="defaultActiveKey">
        <a-tab-pane key="1" tab="危险源一览表">
          <danger-source-report-modal></danger-source-report-modal>
        </a-tab-pane>
        <a-tab-pane key="2" tab="危大工程情况表" forceRender>
          <wd-hidden-danger-static-modal></wd-hidden-danger-static-modal>
          <!-- <exit-list ref="exitList" @refreshPresenceList="refreshPresenceList"></exit-list> -->
        </a-tab-pane>
      </a-tabs>
    </a-modal>
  </div>
</template>

<script>
import {
  getMonthlyReportPage
} from "@/services/safeMonthlyReport";
import { mapGetters } from "vuex";
import StandardTable from "@/components/table/StandardTable";
import moment from "moment";
import DangerSourceReportModal from './dangerSourceReportModal.vue';
import WdHiddenDangerStaticModal from './wdHiddenDangerStaticModal.vue';
import { useRender } from '@/hooks/useRender.js'

const columns = [
  {
    title: "序号",
    align: "center",
    scopedSlots: { customRender: "index" },
    width: 65,
  },
  {
    title: "月份",
    align: "center",
    dataIndex: "yearMonth",
    scopedSlots: { customRender: "yearMonth" },
    width: 150,
  },
  {
    title: "开始时间",
    dataIndex: "startTime",
    align: "center",
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "结束时间",
    dataIndex: "endTime",
    align: "center",
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    align: "center",
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "操作",
    align: "center",
    fixed: "right",
    width: 200,
    scopedSlots: { customRender: "action" },
  },
];

export default {
  components: {
    StandardTable,
    DangerSourceReportModal,
    WdHiddenDangerStaticModal
  },
  data() {
    return {
      defaultActiveKey: "1",
      monthPickerVal: '',
      params: {
        month: '',
        year: '',
        pageNumber: 1,
        pageSize: 100,
      },
      pagination: {
        //分页数据
        current: 1,
        pageSize: 100,
        total: 0,
        showTotal: (total, range) => `共 ${total} 条`,
      },
      total: 0,
      columns: columns,
      tableData: [],
      tableLoading: false, //table加载中
      uploadRecordVisible: false,
    };
  },
  mounted() {
    this.handleQuery();
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.params.pageNumber = 1
      this.pagination.current = 1
      this.tableData = []
      this.params.year = this.monthPickerVal ? this.monthPickerVal.format('YYYY') : ''
      this.params.month = this.monthPickerVal ? this.monthPickerVal.format('MM') : ''
      this.handleQuery();
    },
    handleQuery(e) {
      this.tableLoading = true
      getMonthlyReportPage(this.params).then((res) => {
        this.tableLoading = false
        let d = res.data;
        if (d.code === 0) {
          this.tableData = d.data.list
          this.pagination.total = d.data.total;
          this.total = d.data.total;
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    handleChangePage(e) {
      this.params.pageNumber = e.current;
      this.pagination.current = e.current;
      this.handleQuery();
    },
    resetQuery() {
      this.pagination.pageNumber = 1
      this.monthPickerVal = '',
      this.params = {
        pageNumber: 1,
        pageSize: 100,
      };
      this.handleQuery();
    },
    doDowloadPre(record) {
      if (record.filePath) {
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = record.filePath
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        this.$message.warning('暂无资料！')
      }
    },
    uploadRecord() {
      this.uploadRecordVisible = true
    },
    handleCancelUploadRecord(e) {
      this.uploadRecordVisible = false;
    }
  },
};
</script>

<style lang="less" scoped>
.query-form {
  display: flex;
  // justify-content: space-between;
}
</style>
