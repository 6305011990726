import { HG_TEAM_LIST, APPROVE_HG_TEAM, HG_TEAM_DETAIL, GET_PRO_TEAM_BY_TEAM_ID, GET_PRO_TEAM_BY_BIG_TEAM_ID, HG_TEAM_WORK_TYPE } from '@/services/api'
import {request, METHOD, removeAuthorization} from '@/utils/request'

/**
 *
 */
export async function hgTeamList(params){
    return request(HG_TEAM_LIST, METHOD.POST, params)
}

/**
 *
 */
export async function hgTeamWorkType(params){
    return request(HG_TEAM_WORK_TYPE, METHOD.GET, params)
}

/**
 *
 */
export async function approveTeamList(params){
    return request(APPROVE_HG_TEAM, METHOD.POST, params)
}

/**
 *
 */
export async function teamDetail(params){
    return request(HG_TEAM_DETAIL + '/' + params, METHOD.GET)
}

/**
 *
 */
export async function getProTeamByBigTeamId(params){
    return request(GET_PRO_TEAM_BY_BIG_TEAM_ID, METHOD.GET, params)
}

/**
 *
 */
export async function getBigTeamByProTeamId(params){
    return request(GET_PRO_TEAM_BY_TEAM_ID + '?hqTeamId=' + params, METHOD.POST)
}