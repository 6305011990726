var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { class: ["search-head", _vm.layout, _vm.pageWidth] }, [
      _c(
        "div",
        { staticClass: "search-input" },
        [
          _c("a-input-search", {
            staticClass: "search-ipt",
            staticStyle: { width: "522px" },
            attrs: {
              placeholder: "请输入...",
              size: "large",
              enterButton: "搜索",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { padding: "0 24px" } },
        [
          _c(
            "a-tabs",
            {
              attrs: { tabBarStyle: { margin: 0 }, activeKey: _vm.activeKey },
              on: { change: _vm.navigate },
            },
            [
              _c("a-tab-pane", { key: "1", attrs: { tab: "文章" } }),
              _c("a-tab-pane", { key: "2", attrs: { tab: "应用" } }),
              _c("a-tab-pane", { key: "3", attrs: { tab: "项目" } }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "search-content" }, [_c("router-view")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }