<template>
    <a-modal v-model="visible" :footer="null" width="840px" @cancel="close">
        <template slot="title">
            <div style="padding: 0 64px;">

                <a-steps :current="step">

                    <a-step title="第一步" />
                    <a-step title="第二步" />
                </a-steps>
            </div>
        </template>

        <div v-if="step == 0" class="margin-auto">
            <a-radio-group v-model="radioValue">
                <a-radio class="radioStyle" :value="1" :disabled="safeList.length == 0">
                    更新现有安全管理制度 
                    
                    <a-tooltip>
                        <template slot="title" v-if="safeList.length == 0">
                            暂无安全管理制度，请新增制度后再更新
                        </template>
                        <a-icon type="question-circle" v-if="safeList.length == 0"/>
                    </a-tooltip>
                </a-radio>
                <a-radio class="radioStyle" :value="2">
                    创建新安全管理制度
                </a-radio>

            </a-radio-group>

            <div>
                <a-button type="primary" @click="handleStep(0)">确认</a-button>
            </div>
        </div>

        <div v-if="step == 1">

            <div v-if="radioValue == 1 && !params" class="margin-auto">
                <!-- 是编辑则选择编辑哪个 -->
                <div class="dis-flex mb-2">
                    <span class="mr-2">选择制度</span>

                    <a-select v-model="safeid" style="width: 260px" :options="safeList" allowClear>
                    </a-select>
                </div>
                <div>
                    <a-button type="primary" @click="handleStep(1)">确认</a-button>
                </div>
            </div>

            <div v-if="params">
                <a-form-model ref="ruleForm" :model="params" :rules="rules">
                    <a-row :gutter="16">
                        <a-col :span="12">
                            <a-form-model-item label="制度类别" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }"
                                prop="systemcatid">
                                <a-select ref="select" v-model="params.systemcatid" :options="options1" allowClear>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-model-item label="级次" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }"
                                prop="levelid">
                                <a-select v-model="params.levelid" :options="options2" allowClear>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-model-item label="管理职能类别" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }"
                                prop="funccatid">
                                <a-select  v-model="params.funccatid" :options="options3" allowClear>
                                </a-select>
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-model-item label="管理职能内容" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }"
                                prop="funcontent">
                                <a-input placeholder="请输入管理职能内容" v-model="params.funcontent" />

                            </a-form-model-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-model-item label="制度名称" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }"
                                prop="systemname">
                                <a-input placeholder="请输入制度名称" v-model="params.systemname" />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-model-item label="制度主管部门" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }"
                                prop="systemdeptid">
                                <a-select  v-model="params.systemdeptid" :options="options4" allowClear>
                                </a-select>
                            </a-form-model-item>
                        </a-col>

                        <a-col :span="12">
                            <a-form-model-item label="发文日期" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }"
                                prop="pubdate">
                                <a-date-picker v-model="params.pubdate" style="width: 100%" allowClear mode="date"
                                    :format="'YYYY-MM-DD'" :valueFormat="'YYYY-MM-DD'" />
                            </a-form-model-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-model-item label="生效日期" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }"
                                prop="effectdate">
                                <a-date-picker v-model="params.effectdate" style="width: 100%" allowClear mode="date"
                                    :format="'YYYY-MM-DD'" :valueFormat="'YYYY-MM-DD'" />
                            </a-form-model-item>
                        </a-col>

                        <a-col :span="24">
                            <a-card>
                                <a-form-model-item label="最新发文文号" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }">
                                    <a-input placeholder="请输入制度名称" v-model="params.nowno" />
                                </a-form-model-item>
                                <a-form-model-item label="上传附件" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }">
                                    <a-upload class="avatar-uploader" key="safe" list-type="picture-card" :accept="accept"
                                        :file-list="filePath" @change="handleUploadChange"
                                        :customRequest="resourceUpload" @preview="handlePreview"
                                        :beforeUpload="beforeUpload" multiple>


                                        <div v-if="filePath.length < 10">
                                            <a-icon :type="uploading ? 'loading' : 'plus'" />
                                            <div class="ant-upload-text">上传附件</div>
                                        </div>
                                    </a-upload>
                                </a-form-model-item>
                            </a-card>
                        </a-col>


                    </a-row>
                </a-form-model>
                <a-row style="padding-top: 16px;" v-if="optionType == 'edit'">
                    <a-col :span="12"></a-col>
                    <a-col :span="12">
                        <a-form-model-item :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }" prop="year">
                            <div class="flex flex-row-reverse">
                                <a-button type="primary" @click="handleSubmit">保存</a-button>
                                <a-button class="mr-2" @click="close">返回</a-button>
                            </div>
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </div>

        </div>

    </a-modal>
</template>

<script>
import {
    safesystemUrellist,
    safesystemByNoticeId,
} from "@/services/specificatioNotification";
import { request } from "@/utils/request";
import { mapGetters } from 'vuex'
import moment from "moment";
import { safesystemAdd, safesystemUpdate, safesystemByID } from "@/services/safeManagementWorkingGuidelines";
import { ATTACHMENT_UPLOAD } from "@/services/api";

const options = [
    {
        value: "1",
        label: "安全措施费管理",
    },
    {
        value: "2",
        label: "危大工程管理",
    },
    {
        value: "3",
        label: "企业级项目安全人员管理",
    },
    {
        value: "4",
        label: "特种作业人员管理",
    },
    {
        value: "5",
        label: "文明施工管理",
    },
]
// 1业务工作制度 2 工作指引
const options1 = [
    {
        value: "1",
        label: "业务工作制度",
    },
    {
        value: "2",
        label: "工作指引",
    },
]
// 1.一级、2.二级、3.三级
const options2 = [
    {
        value: "1",
        label: "一级",
    },
    {
        value: "2",
        label: "二级",
    },
    {
        value: "3",
        label: "三级",
    },
]

//管理职能类别 默认 1.安全管理
const options3 = [
    {
        value: "1",
        label: "安全管理",
    }
]
// 制度主管部门id 默认OFFICE_AQMGLY 安全部
const options4 = [
    {
        value: "OFFICE_AQMGLY",
        label: "安全部",
    }
]
export default {
    name: 'safeDialog',
    data() {
        return {
            visible: false,
            step: 0,
            radioValue: null,
            safeid: '',
            safeList: [],
            id: '',
            disabled1: false,
            optionType: null,
            params: null,

            // tt
            isEdit: 0,
            rules: {
                systemname: [{ required: true, message: '请输入名称', trigger: 'change' }],
                pubdate: [{ required: true, message: '请选择发布日期', trigger: 'change' }],
                // remark: [{ required: true, message: '请选择', trigger: 'change' }],
                // attach: [{ required: true, message: '请选择', trigger: 'change' }],
            },

            cept: ".png,.jpg,.jpeg,.pdf,.PDF",
            accept: "",
            filePath: [],
            uploading: false,
            hisaccept: "",
            hisFilePath: [],
            hisuploading: false,
            title: '新增安全制度管理详情',
            options: options,
            options1: options1,
            options2: options2,
            options3: options3,
            options4: options4,
        }
    },
    mounted() {
        safesystemUrellist().then(res => {
            this.safeList = res.data.data.map(item => {
                return {
                    label: item.systemname,
                    value: item.safesystemid,
                    ...item
                }
            })
        })
    },
    methods: {
        open(id, op) {
            this.optionType = op
            if (op == 'edit') {
                this.id = id;
                this.visible = true

            } else if(op == 'view') {
                
                this.isEdit = 1
                this.step = 1
                safesystemByNoticeId({id: id}).then(res => {
                    if (res.data.code === 200) {
                        this.params = res.data.data
                        let attachList = this.params.nowattach.split(',');
                        if (this.params.attach == '') {
                            attachList = []
                        }
                        let fileNames = [];
                        if (this.params.createuser) {
                            fileNames = this.params.createuser.split(',');
                        }
                        this.filePath = attachList.map((item, index) => {
                            return {
                                uid: index,
                                url: item,
                                name: fileNames[index],
                                status: 'done',
                            }
                        })

                        let hisAttachList = this.params.hisattach.split(',');
                        if (this.params.hisattach == '') {
                            hisAttachList = []
                        }
                        this.hisFilePath = hisAttachList.map((item, index) => {
                            return {
                                uid: index,
                                url: item,
                                name: item.substring(item.lastIndexOf('/') + 1),
                                status: 'done',
                            }
                        })

                        this.visible = true
                    } else {
                        this.$message.error(res.data.msg)
                    }
                })
            }
        },
        reset() {
            this.step = 0
            this.radioValue = null
            this.safeid = ''
            this.params = null
            this.isEdit = 0
            this.filePath = []
            this.uploading = false
        },
        handleStep(step) {
            if (step == 0) {
                if (this.radioValue == null) {
                    this.$message.error('请选择操作')
                    return
                }
                if (this.radioValue == 1) {
                    
                    this.step = 1
                } else {
                    this.step = 1
                    this.isEdit = 0;
                    this.params = {
                        "systemcatid": "", //制度类别id 1业务工作制度 2 工作指引
                        "systemdes": "", //制度类别描述
                        "levelid": "", //级次id 1.一级、2.二级、3.三级
                        "leveldes": "", //级次描述
                        "funccatid": "", //管理职能类别 默认 1.安全管理
                        "funccatdes": "", //管理职能类别描述
                        "funcontent": "", //管理职能内容
                        "systemname": "", //制度名称
                        "systemdeptid": "", //制度主管部门id 默认OFFICE_AQMGLY 安全部
                        "systemdeptname": "", //制度主管部门名称 默认OFFICE_AQMGLY 安全部
                        "pubdate": "", //发布日期 yyyy-MM-dd
                        "effectdate": "", //生效日期
                        "nowno": "", //最新发文文号
                        "nowattach": "", //最新附件
                        hisno: "", //历史版本
                    }
                    this.filePath = []
                    this.hisFilePath = []
                }

            } else if (step == 1) {

                // 判断是否选择了制度
                let d = this.safeList.find(item => item.safesystemid == this.safeid)
                if (!d) {
                    this.$message.error('请选择制度')
                    return
                }
                this.isEdit = 1;
                this.params = d
                let attachList = this.params.nowattach.split(',');
                if (this.params.attach == '') {
                    attachList = []
                }
                let fileNames = [];
                if (this.params.createuser) {
                    fileNames = this.params.createuser.split(',');
                }
                this.filePath = attachList.map((item, index) => {
                    return {
                        uid: index,
                        url: item,
                        name: fileNames[index],
                        status: 'done',
                    }
                })

                let hisAttachList = this.params.hisattach.split(',');
                if (this.params.hisattach == '') {
                    hisAttachList = []
                }
                this.hisFilePath = hisAttachList.map((item, index) => {
                    return {
                        uid: index,
                        url: item,
                        name: item.substring(item.lastIndexOf('/') + 1),
                        status: 'done',
                    }
                })
            }
        },

        // tt
        handleSubmit() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    let attach = this.filePath.map(item => item.url).join(',');
                    let d = {
                        ...this.params,
                    }
                    d.nowattach = attach;
                    let fileNames = this.filePath.map(item => item.name).join(',');
                    d.createuser = fileNames;

                    let hisattach = this.hisFilePath.map(item => item.url).join(',');
                    d.hisattach = hisattach;

                    d.systemdes = this.options1.find(item => item.value == d.systemcatid).label;
                    d.leveldes = this.options2.find(item => item.value == d.levelid).label;
                    d.funccatdes = this.options3.find(item => item.value == d.funccatid).label;
                    d.systemdeptname = this.options4.find(item => item.value == d.systemdeptid).label;
                    // 加入通知id
                    d.noticeid = this.id;
                    if (this.isEdit == 0) {
                        // safesystemAdd(d).then(res => {
                        //     if (res.data.code === 200) {
                        //         this.$message.success('操作成功！')
                        //         this.close();
                                
                        //     } else {
                        //         this.$message.error(res.data.msg)
                        //     }
                        // })
                    } else {
                        // safesystemUpdate(d).then(res => {
                        //     if (res.data.code === 200) {
                        //         this.$message.success('操作成功！')
                        //         this.close();
                               
                        //     } else {
                        //         this.$message.error(res.data.msg)
                        //     }
                        // })
                    }
                    this.$emit('saveSafe', d)
                    this.visible = false
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        close() {
            this.visible = false
            this.reset()
            // this.$emit('refresh')
        },
        beforeUpload(file) {
            // const accept = '.' + file.name.substring(file.name.lastIndexOf(".") + 1);
            // if (this.accept.split(',').indexOf(accept) == -1) {
            //     this.$message.error("只能上传图片、PDF文件");
            //     return false
            // }
        },
        handleUploadChange(info) {
            if (info.file.status === "uploading") {
                this.uploading = true;
                return;
            }
            if (info.file.status === "done") {
                this.uploading = false;
            }
            if (info.file.status === "removed") {
                this.filePath = info.fileList
            }
        },
        resourceUpload(value) {
            const formData = new FormData();
            formData.append("file", value.file);
            request(ATTACHMENT_UPLOAD, "post", formData).then((result) => {
                if (result.data.code === 0) {
                    this.$message.success("上传成功!");
                    this.filePath.push({
                        uid: value.file.uid,
                        url: result.data.data.path,
                        name: value.file.name,
                        status: 'done',
                    });
                    value.onSuccess(result.data.data.path, value.file);
                } else {
                    this.$message.warning(result.data.message);
                }
            });
        },
        handlePreview(file) {
            window.open(file.url);
        },
    },
}
</script>

<style lang="less" scoped>
.radioStyle {
    display: block;
    height: 30px;
    line-height: 30px;
    margin-bottom: 10px;
}

.margin-auto {
    width: 60%;
    margin: 0 auto;
}
.dis-flex {
    display: flex;
}
</style>