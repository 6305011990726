<template>
    <div>
    </div>


</template>
<script>
    import { getLoginJeeplus } from '@/services/jeeplus'
    export default {
        data () {
            return {
            }
        },
        computed: {
        },
        mounted(){
        },
        created() {
            this.getLoginJeeplus();
        },
        methods: {
            getLoginJeeplus(){
                const that = this
                getLoginJeeplus().then(res=>{
                    this.data = res.data.data
                    if (res.data.code === 0){
                        this.goJeeplus(this.data);
                    }else {
                        this.$message.info('获取登录账号/密码错误，请稍后重试');
                        setTimeout(function (){
                            that.$router.back()
                        }, 3000)
                    }
                    // if (record.status === 2){

                })
            },
            goJeeplus(record){

                this.$message.info('登录成功，正在跳转...');
                window.open("http://gr.gz-suijian.com/gr/newlogin?username=" + record.loginName + "&password=" + record.password + "&redirectUrl=/");
                // window.open("http://localhost:8086/gr/newlogin?username=" + record.loginName + "&password=" + record.password + "&redirectUrl=/");
                this.$router.back()
            },
        }
    }

</script>