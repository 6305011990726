var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { staticClass: "result-success", attrs: { bordered: false } },
    [
      _c(
        "result",
        {
          attrs: {
            "is-success": true,
            description: _vm.description,
            title: _vm.title,
          },
        },
        [
          _c(
            "template",
            { slot: "action" },
            [
              _c(
                "a-button",
                { staticClass: "action", attrs: { type: "primary" } },
                [_vm._v("返回列表")]
              ),
              _c("a-button", { staticClass: "action" }, [_vm._v("查看项目")]),
              _c(
                "a-button",
                { staticClass: "action", on: { click: _vm.print } },
                [_vm._v("打印")]
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("div", { staticClass: "project-name" }, [_vm._v("项目名称")]),
              _c(
                "detail-list",
                {
                  staticStyle: { "max-width": "800px", "margin-bottom": "8px" },
                  attrs: { size: "small" },
                },
                [
                  _c("detail-list-item", { attrs: { term: "项目ID" } }, [
                    _vm._v("20180724089"),
                  ]),
                  _c("detail-list-item", { attrs: { term: "负责人" } }, [
                    _vm._v("曲丽丽"),
                  ]),
                  _c("detail-list-item", { attrs: { term: "生效时间" } }, [
                    _vm._v("016-12-12 ~ 2017-12-12"),
                  ]),
                ],
                1
              ),
              _c(
                "a-steps",
                { attrs: { current: 1, progressDot: "" } },
                [
                  _c(
                    "a-step",
                    { attrs: { title: "创建项目" } },
                    [
                      _c(
                        "a-step-item-group",
                        { attrs: { slot: "description" }, slot: "description" },
                        [
                          _c("a-step-item", {
                            attrs: { title: "曲丽丽", icon: "dingding-o" },
                          }),
                          _c("a-step-item", {
                            attrs: { title: "2016-12-12 12:32" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-step",
                    { attrs: { title: "部门初审" } },
                    [
                      _c(
                        "a-step-item-group",
                        { attrs: { slot: "description" }, slot: "description" },
                        [
                          _c("a-step-item", {
                            attrs: {
                              title: "周毛毛",
                              icon: "dingding-o",
                              iconStyle: { color: "#00A0E9" },
                            },
                          }),
                          _c("a-step-item", {
                            attrs: {
                              title: "催一下",
                              titleStyle: { color: "#00A0E9" },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("a-step", { attrs: { title: "财务复核" } }),
                  _c("a-step", { attrs: { title: "完成" } }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }