var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["tabBar", _vm.layout, _vm.pageWidth] },
    [
      _c(
        "a-tabs",
        {
          class: [
            "tabs-container",
            _vm.layout,
            _vm.pageWidth,
            {
              affixed: _vm.affixed,
              "fixed-header": _vm.fixedHeader,
              collapsed: _vm.adminLayout.collapsed,
            },
          ],
          attrs: {
            type: "editable-card",
            "active-key": _vm.active,
            "hide-add": true,
          },
        },
        [
          _c(
            "a-tooltip",
            {
              attrs: {
                slot: "tabBarExtraContent",
                placement: "left",
                title: _vm.lockTitle,
              },
              slot: "tabBarExtraContent",
            },
            [
              _c("a-icon", {
                staticClass: "header-lock",
                attrs: {
                  theme: "filled",
                  type: _vm.fixedTabs ? "lock" : "unlock",
                },
                on: { click: _vm.onLockClick },
              }),
            ],
            1
          ),
          _vm._l(_vm.pageList, function (page) {
            return _c("a-tab-pane", { key: page.path }, [
              _c(
                "div",
                {
                  staticClass: "tab",
                  attrs: { slot: "tab" },
                  on: {
                    contextmenu: function (e) {
                      return _vm.onContextmenu(page.path, e)
                    },
                  },
                  slot: "tab",
                },
                [
                  _c("a-icon", {
                    class: [
                      "icon-sync",
                      { hide: page.path !== _vm.active && !page.loading },
                    ],
                    attrs: { type: page.loading ? "loading" : "sync" },
                    on: {
                      click: function ($event) {
                        return _vm.onRefresh(page)
                      },
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "title",
                      on: {
                        click: function ($event) {
                          return _vm.onTabClick(page.path)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.pageName(page)))]
                  ),
                  !page.unclose
                    ? _c("a-icon", {
                        staticClass: "icon-close",
                        attrs: { type: "close" },
                        on: {
                          click: function ($event) {
                            return _vm.onClose(page.path)
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ])
          }),
        ],
        2
      ),
      _vm.affixed ? _c("div", { staticClass: "virtual-tabs" }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }