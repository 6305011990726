<template>
  <div>
    <form-index
      ref="formindex"
      :formData="formData"
      flexAction
      @handleSubmit="handleSubmit"
      @handlSelect="handlSelect"
    >
      <!-- 按钮插槽 -->
      <template #flexAction>
        <a-space class="btnBox">
          <a-button type="primary" html-type="submit">查询</a-button>
        </a-space>
      </template>
    </form-index>
    <div class="a_a_footer">
      <!-- 表格封装 -->
      <standard-table
        class=""
        :columns="columns"
        :dataSource="dataSource"
        rowKey="id"
        :pagination="pagination"
        :loading="tableLoading"
        @change="onPageChange"
        :isAlert="false"
        :bordered="true"
        :btnArr="tableBtnArr"
      >
        <template slot="index" slot-scope="{ index }">
          <span>
            {{
              (pagination.current - 1) * pagination.pageSize +
              parseInt(index) +
              1
            }}
          </span>
        </template>
        <template slot="maintenancePath" slot-scope="{ text, record }">
          <a-icon v-if="text" type="file-pdf" :style="{ fontSize: '32px' }" @click="handlePreview(record)"/>
        </template>
        <div slot="action" slot-scope="{ record }">
          <div class="sTables">
            <div @click="visit(record)">查看</div>
            <div @click="del(record)">删除</div>
            <div @click="evaluate(record)">评价</div>
          </div>
        </div>
      </standard-table>
      <Export-loading
        :cPercent="cPercent"
        :isExporting="isExporting"
        :loadingText="loadingText"
      ></Export-loading>
    </div>
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import { getMaintenanceRecordList, delFacilityMaintenanceRecord } from "@/services/equipmentLedger";
import { QUERY_OFFICE_BY_USER, FACILITY_MAINTENANCE_RECORD_EXPORT } from "@/services/api";
import { exportProgress } from "@/pages/aqjyzl/export"; // 导出方法
import ExportLoading from "@/components/ExportLoading/ExportLoading"; // progress组件
import moment from "moment";
import { request } from "@/utils/request";
import { useRender } from '@/hooks/useRender.js'

const formData = [
  {
    label: "选择年月",
    placeholder: "请选择选择年月",
    decorator: [
      "date",
      {
        rules: [{ required: false, message: "请选择" }],
      },
    ],
    type: "monthPicker",
    key: "date",
    col: 5,
    labelCol: 8,
    wrapperCol: 14,
    display: true,
    format: "YYYY-MM",
    styles: {width: '100%'}
  },
  {
    label: "工程名称",
    placeholder: "请选择工程名称",
    decorator: [
      "projectName",
      { rules: [{ required: false, message: "请选择工程名称" }] },
    ],
    type: "input",
    key: "projectName",
    col: 5,
    labelCol: 8,
    wrapperCol: 14,
  },
  {
    label: '工程管理部',
    placeholder: '请选择工程管理部',
    decorator: ['gcglbId', { rules: [{ required: false, message: '请选择工程管理部' }] }],
    type: 'select',
    key: 'gcglbId',
    selectlist: [],
    qb:true,
    col:5,
    labelCol: 8,
    wrapperCol:14,
  },
  {
    label: "产权登记编号",
    placeholder: "请选择产权登记编号",
    decorator: [
      "equityNum",
      { rules: [{ required: false, message: "请选择产权登记编号" }] },
    ],
    type: "input",
    key: "equityNum",
    col: 6,
    labelCol: 10,
    wrapperCol: 14,
  },
  {
    type: 'action',
    col: 3
  }
];
const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
  },
  {
    title: "工程名称",
    dataIndex: "projectName",
    width: 205,
    ellipsis: true,
    tooltip: true
  },

  {
    title: "工程管理部",
    dataIndex: "gcglbName",
    align: "center",
    width: 110,
    scopedSlots: { customRender: "gcglbName" },
  },
  {
    title: "自编号",
    dataIndex: "selfNum",
    width: 80,
    align: "center",
    // ellipsis: true,
    scopedSlots: { customRender: "selfNum" },
  },
  {
    title: "产权登记编号",
    dataIndex: "equityNum",
    align: "center",
    width: 130,
    scopedSlots: { customRender: "equityNum" },
  },
  {
    title: "维保单位",
    dataIndex: "maintenanceUnit",
    width: 170,
    align: "center",
    key: "maintenanceUnit",
    ellipsis: true,
    tooltip: true
  },
  {
    title: "维保日期",
    dataIndex: "maintenanceDate",
    width: 85,
    align: "center",
    key: "maintenanceDate",
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "维保记录表",
    dataIndex: "maintenancePath",
    align: "center",
    width: 130,
    scopedSlots: { customRender: "maintenancePath" },
  },
  {
    title: "操作",
    align: "center",
    width: 130,
    scopedSlots: { customRender: "action" },
  },
];
export default {
  mixins: [exportProgress], //导出方法注入
  components: {
    formIndex,
    StandardTable,
    ExportLoading, //导出数据加载封装
  },
  data() {
    return {
      formData: formData,
      columns: columns,
      dataSource: [], //table数组
      pagination: {
        //分页数据
        current: 1,
        pageSize: 10,
        total: 0,
      },
      params: {
        //测试数据
        pageNumber: 1,
        pageSize: 10,
        facilityId: '',
        facilityType: 1 //设备类别：1：塔式起重机，2：施工升降机，3：物料提升机
      },
      tableLoading: false, //table加载中
      loadingText: "数据加载中...",
      cPercent: 0,
      isExporting: false,
      tableBtnArr: [
        {
          name: '导出',
          attrs: {
            type: 'primary'
          },
          click: this.doDowload
        }
      ]
    };
  },
  activated() {
    this.getgcglbList(QUERY_OFFICE_BY_USER);
    this.params.facilityId = this.$route.query.id;
    console.log(this.$route.name)
    switch(this.$route.name) {
      case '塔式起重机': {
        this.params.facilityType = 1
        break
      }
      case '施工升降机': {
        this.params.facilityType = 2
        break
      }
      case '物料提升机': {
        this.params.facilityType = 3
        break
      }
      case '门式起重机': {
        this.params.facilityType = 4
        break
      }
      case '桥式起重机': {
        this.params.facilityType = 5
        break
      }
    }
    this.aqxjlist(this.params);
  },
  methods: {
    /**
     * 导出excel
     */
     doDowload() {
      let obj = this.$refs["formindex"].getFieldValue();
      obj.facilityType = this.params.facilityType;
      this.loadingText = "正在导出EXCEL文件，请稍候 ····";
      this.isExporting = true;
      this.cPercent = 0;
      let fileName = null
      switch(this.params.facilityType) {
        case 1: {
          fileName = '塔式起重机维保记录.xlsx'
          break
        }
        case 2: {
          fileName = '施工升降机维保记录.xlsx'
          break
        }
        case 3: {
          fileName = '物料提升机维保记录.xlsx'
          break
        }
        case 4: {
          fileName = '门式起重机维保记录.xlsx'
          break
        }
        case 5: {
          fileName = '桥式起重机维保记录.xlsx'
          break
        }
      }
      this.exportFunNomal2(
        FACILITY_MAINTENANCE_RECORD_EXPORT,
        obj,
        fileName,
        "application/vnd.ms-excel"
      );
    },
    // 获取工程管理部数据
    getgcglbList(path) {
      request(path, "get").then((res) => {
        let data = res.data.data.filter(
          (item) => item.id != "3da0f5533f87425c9a9e8a11a7923300"
        );
        this.formDataFn("gcglbId", data);
      });
    },
    aqxjlist(params) {
      this.tableLoading = true;
      getMaintenanceRecordList(params).then((res) => {
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
        }
        this.tableLoading = false;
      });
    },
    handleSubmit(e) {
      let obj = { ...e, ...this.params };
      const dateArr = obj.date?.split('-')
      obj.year = dateArr ? dateArr[0] : ''
      obj.month = dateArr ? dateArr[1] : ''
      delete obj.date
      this.aqxjlist(obj);
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      let arr = [];
      data.forEach((item) => {
        arr.push({ name: item.master, value: item.id });
      });
      this.formData.forEach((item) => {
        if (item.key == key) {
          item.selectlist = arr;
        }
      });
    },
    handlSelect(obj) {
      const { val, keys } = obj;
    },
    //表格 - 分页查询
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      if (obj.status == "") {
        obj.status = 0;
      }
      this.params.pageNumber = e.current;
      this.pagination.current = e.current;
      this.getData(Object.assign({}, this.params, obj));
    },
    //查看
    visit(record) {
      let name = ''
      switch(this.params.facilityType) {
        case 1: {
           name = "塔式起重机维保记录"
          break
        }
        case 2: {
          name = "施工升降机维保记录"
          break
        }
        case 3: {
          name = "物料提升机维保记录"
          break
        }
        case 4: {
          name = "门式起重机维保记录"
          break
        }
        case 5: {
          name = "桥式起重机维保记录"
          break
        }
      }
      this.$router.push({
        name,
        query: {
          id: record.id,
          facilityId: this.params.facilityId,
          facilityType: this.params.facilityType,
          isEdit: 1
        },
      });
    },
    del(record) {
      const _that = this
      this.$confirm({
          title: '是否确定删除？',
          cancelText: '否',
          okText: '是',
          onOk: () => {
            delFacilityMaintenanceRecord(record.id).then(res => {
              if (res.data.code === 0) {
                _that.$message.success('删除成功')
                this.aqxjlist(this.params);
              } else {
                _that.$message.error(res.data.msg)
              }
            })
          },
        })
    },
    evaluate(record) {
      let name = ''
      switch(this.params.facilityType) {
        case 1: {
           name = "塔式起重机维保记录评价"
          break
        }
        case 2: {
          name = "施工升降机维保记录评价"
          break
        }
        case 3: {
          name = "物料提升机维保记录评价"
          break
        }
        case 4: {
          name = "门式起重机维保记录评价"
          break
        }
        case 5: {
          name = "桥式起重机维保记录评价"
          break
        }
      }
      this.$router.push({
        name,
        query: {
          recordId: record.id,
          facilityId: this.params.facilityId,
          facilityType: this.params.facilityType,
        },
      });
    },
    handlePreview(item) {
      // if (item.type == "pdf") {
      //   // 打开新页面
        window.open(item.maintenancePath);
      // } else {
      //   // 打开预览
      //   this.previewImage = item.url;
      //   this.previewVisible = true;
      // }
    },
  },
};
</script>

<style lang="less" scoped>
.a_a_footer {
  margin-top: 16px;
  background: #ffffff;

  .operator {
    display: flex;
    justify-content: flex-end;
  }

  .sTables {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      width: 100%;
      text-align: center;
      cursor: pointer;
    }

    div:nth-child(1) {
      color: #307dfa;
    }

    div:nth-child(2) {
      color: #ff5c77;
    }

    div:nth-child(3) {
      color: #615e83;
    }
  }
}
.content_div {
  padding: 5px 0;
  cursor: pointer;
}
.content_div:hover {
  color: #307dfa;
}
</style>
