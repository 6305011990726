<template>
  <div style="width: 80%;">
    <a-row>
      <a-col :span="12" style="background-color: #ececec;padding-right: 5px">
        <a-card title="自动补报设置" :bordered="false" >
          <a-form-model :model="jwAutoAtteContent" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-model-item label="是否启用">
              <a-switch
                  v-model="jwAutoAtte"
                  checked-children="启用"
                  un-checked-children="停用"
                />
            </a-form-model-item>
            <a-form-model-item label="自动补报时间">
              <a-time-picker :minute-step="15" format="HH:mm" valueFormat="HH:mm" placeholder="开启后设置" :disabled="!jwAutoAtte" v-model="jwAutoAtteContent.atteReportTime" />
            </a-form-model-item>
            <a-form-model-item label="企微机器人链接">
              <a-textarea v-model="jwAutoAtteContent.wxRootHook" :disabled="!jwAutoAtte" placeholder="请开启后输入链接" :rows="3"/>
            </a-form-model-item>
          </a-form-model>
          <a-button class="mt-2 ml-5" icon="save" type="primary" @click="saveProjectConfig">保存</a-button>
        </a-card>
      </a-col>
      <a-col :span="12">
        <a-card title="指定日期补报" :bordered="false">
          <!-- <span slot="extra" style="color: red">当前正在执行 2020年4月21日、22日、23日 补报任务</span> -->
          <a-form-model :model="jwAutoAtteContent" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-model-item label="请选择日期">
                <a-date-picker valueFormat="YYYY-MM-DD" v-model="reportAtteDate" :disabledDate="disabledDate"/>
            </a-form-model-item>
          </a-form-model>
          <a-button :loading="setAutoLoding" class="ml-5" icon="save" type="primary" @click="setAutoReportAtte">执行</a-button>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {
  getProjectIdConfig,
  saveProjectIdConfig,
  setAutoReportAtte
} from "@/services/projectConfig";
import { debounce } from "lodash";
export default {
  name: 'SuijianClientAttendnceSettingIndex',
  props: {
    projectId: {
      type: [String, Number],
      require: true,
    },
  },
  data() {
    return {
      jwAutoAtte: false, // 是否启用
      jwAutoAtteContent: {
        atteReportTime: null, // 自动补报时间
        wxRootHook: '' // 企业微信机器人
      },
      reportAtteDate: null, // 指定日期补报
      labelCol: { span: 6 },
      wrapperCol: { span: 12 },
      pushConfig: {},
      setAutoLoding: false
    };
  },

  mounted() {
    this.getProjectConfig();
  },
  activated() {
    this.getProjectConfig();
    this.reportAtteDate = null
  },

  methods: {
    getProjectConfig() {
      getProjectIdConfig({
        projectId: this.projectId ? this.projectId : this.user.projectId,
      }).then((res) => {
        if (res.data.stat == -1) {
           this.jwAutoAtte = false
        } else {
           const data = res.data.data
           this.pushConfig = data
           this.jwAutoAtte = Boolean(data.jwAutoAtte)
           this.jwAutoAtteContent = data.jwAutoAtteContent
        }
      });
    },
    saveProjectConfig:debounce(function(){
      // if (!this.jwAutoAtte) return this.$message.error("请先启用自动补报设置");
      let params = {
        rcMode: this.pushConfig.rcMode, // 入场方式
        id: this.pushConfig.id, // id存在即修改，否则是新增
        pushJw: this.pushConfig.pushJw, // 是否上报建委
        isDirect: this.pushConfig.isDirect, // 1是设备直连 0是非设备直连
        jwbykq: this.pushConfig.jwbykq, // 是否启用备用考勤
        jwbykqContent: this.pushConfig.jwbykqContent, // 建委备用考勤参数
        jwZlSecritVoList: this.pushConfig.jwZlSecritVoList, // 设备直连
        jwFzlKeySecritList: this.pushConfig.jwFzlKeySecritList, // 非设备直连
        projectId: this.pushConfig.projectId
          ? this.pushConfig.projectId
          : (this.projectId ? this.projectId : this.user.projectId),
        pushDjj: this.pushConfig.pushDjj, // 推送代建局
        djjContent: this.pushConfig.djjContent, // 代建局
        pushZhgd: this.pushConfig.pushZhgd, // 推送智慧工地
        zhgdContent: this.pushConfig.zhgdContent, // 智慧工地
        pushZdb: this.pushConfig.pushZdb, // 推送重点办
        zdbContent: this.pushConfig.zdbContent, // 重点办
      };
      params.jwAutoAtte = Number(this.jwAutoAtte)
      params.jwAutoAtteContent = this.jwAutoAtteContent
      console.log(params,'************')
      saveProjectIdConfig(params).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("设置成功");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },1000),
    setAutoReportAtte:debounce(function(){
      if (this.reportAtteDate == null) return this.$message.error("请选择指定日期补报");
      const params = {
        reportAtteDate: this.reportAtteDate,
        projectId: this.pushConfig.projectId
          ? this.pushConfig.projectId
          : (this.projectId ? this.projectId : this.user.projectId),
      }
      this.setAutoLoding = true
      setAutoReportAtte(params).then(res => {
        if (res.data.code == 0) {
          this.$message.success("执行成功");
          this.setAutoLoding = false
        } else {
          this.$message.error(res.data.msg);
          this.setAutoLoding = false
        }
      })
    },1000),
    disabledDate(date) {
      // 获取当前日期
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      // 获取选择的日期
      const selectedDate = new Date(date);
      selectedDate.setHours(0, 0, 0, 0);

      // 获取昨天的日期
      const yesterday = new Date(currentDate);
      yesterday.setDate(yesterday.getDate() - 1);

      // 获取前天的日期
      const dayBeforeYesterday = new Date(currentDate);
      dayBeforeYesterday.setDate(dayBeforeYesterday.getDate() - 2);

      // 禁用选择的日期如果不是当天、昨天或前天
      return !(selectedDate.getTime() === currentDate.getTime() ||
               selectedDate.getTime() === yesterday.getTime() ||
               selectedDate.getTime() === dayBeforeYesterday.getTime());
      }
  },
};
</script>
