<template>
  <div class="main">
    <!-- 左侧 -->
    <div class="sider">
      <!-- 公司卡片 -->
      <companyCard :officeData="officeData" :isProject="false"></companyCard>
      <!-- 管理人员卡片 -->
      <managerCard :mangerData="mangerData"></managerCard>
      <!-- 班组情况概览 -->
      <teamsOverview style="flex: 1;" :teamRankList="teamRankList"></teamsOverview>
    </div>
    <!-- 右侧 -->
    <div class="content">
      <!-- 上部分 -->
      <div class="content-top">
        <div class="content-top-left">
          <!-- 地图组件 -->
          <mapOverview style="flex: 1;" type="office" />
        </div>
        <!-- 工人情况总览 -->
        <workOverview :staffData="staffData"></workOverview>
      </div>
      <!-- 工资发放情况 -->
      <payOverview :payrollData="payrollData"></payOverview>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getProWarnList,
  getProPayrollData,
  getProDeviceData,
} from "@/services/projectManagement";
import { getOfficeDetail } from "@/services/projectManagement";
import {
  getHomeOfficeManage,
  getHomeOfficeStaff,
  getHomeOfficeTeamKq,
  getHomeOfficeTeamKqData
} from "@/services/statistics";
import echarts from "echarts"
import { debounce } from 'lodash'
import companyCard from '@/components/home/companyCard/index.vue'
import managerCard from '@/components/home/managerCard/index.vue'
import teamsOverview from '@/components/home/teamsOverview/index.vue'
import mapOverview from '@/components/home/mapOverview/index.vue'
import payOverview from '@/components/home/payOverview/index.vue'
import workOverview from '@/components/home/workOverview/index.vue'
const columns = [
  // {
  //   title: "预警单号",
  //   dataIndex: "jwWarnOddNum",
  //   align: "center",
  //   scopedSlots: { customRender: "overtext" },
  // },
  {
    title: "预警内容",
    dataIndex: "name",
    align: "center",
    scopedSlots: { customRender: "overtext" },
  },
  {
    title: "预警时间",
    dataIndex: "warmDate",
    align: "center",
    scopedSlots: { customRender: "warmDate" },
  },
  // {
  //   title: "处理状态",
  //   dataIndex: "status",
  //   align: "center",
  //   scopedSlots: { customRender: "status" },
  // },
  {
    title: "剩余处理时间",
    dataIndex: "timeoutTime",
    align: "center",
    scopedSlots: { customRender: "warmCode" },
  },
];
const deviceColumns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    align: "center",
  },
  {
    title: "品牌",
    dataIndex: "manufacturerKey",
    align: "center",
  },
  {
    title: "设备名称",
    dataIndex: "deviceName",
    align: "center",
  },
  {
    title: "状态",
    dataIndex: "online",
    align: "center",
    scopedSlots: { customRender: "online" },
  },
];
const payrollData = [
  {
    title: "累计发放工人工资金额总数",
    key: "accumulatedTotal",
    value: "0",
    background: '#E9F7FF',
    img: require("@/assets/img/payroll-card1.png"),
  },
  {
    title: "本月发放工人工资金额总数",
    key: "currMonthAccumulatedTotal",
    value: "0",
    background: '#F4F6FF',
    img: require("@/assets/img/payroll-card2.png"),
  },
  {
    title: "累计班组发放总数",
    key: "teamNum",
    value: "0",
    background: '#F4F6FF',
    img: require("@/assets/img/payroll-card3.png"),
  },
  {
    title: "本月发放工人总数",
    key: "staffNum",
    value: "0",
    background: '#E9F7FF',
    img: require("@/assets/img/payroll-card-4.png"),
  },
];

export default {
  name: "officeNewhomePage",
  components: {
    // StandardTable,
    companyCard,
    managerCard,
    mapOverview,
    teamsOverview,
    payOverview,
    workOverview
  },
  data() {
    return {
      activedKey: 2,
      peopleChart: null,
      environmentChart: null,
      safeChart: null,
      environmentChartCopy: null,
      sevenDaysChart: null,
      // currentGk: {}, // 当月安全管理统计汇总
      // workData: {}, // 劳务数据汇总
      // projectList: [],
      // defaultProject: "全部",
      // gcObj: {}, // 工程类型守护
      // ManagCWA: {}, // 管理人员数据
      // sevenKq: [],
      noCloseWarnList: [], // 建委预警列表
      columns,
      deviceColumns,
      pagination: {
        //分页数据
        current: 1,
        pageSize: 10,
        total: 0,
      },
      teamRankList: [], // 班组排名情况
      teamDataKq: {}, // 班组数据
      deviceData: {}, // 设备数组
      staffData: {}, // 工人数据
      payrollData, // 工资数据
      newPayrollData: {}, // 新
      mangerData: {}, // 管理人员数据
      projectData: {}, // 项目数据
      sevenKq: {}, // 项目七日考勤情况
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true,
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: "#3ec13e", //滚动条颜色
          opacity: 0.8, //滚动条透明度
          "overflow-x": "hidden",
        },
      },
      deviceNum: 0,
      officeData: {}, // 公司概况详情
      projectList: [], //公司项目总数
      gcObj: {}, // 工程类型
      payChart: null,
      jobAgeChart: null,
      jobStructureChart: null,
      // 工人情况-年龄分布
      jobAge: [],
      // 工人情况-工种结构
      jobStructure: [],
    };
  },
  filters: {
    statusStr(val) {
      switch (val) {
        case 0:
          return "待处理";
        case 1:
          return "处理中";
        case 2:
          return "已处理";
        case 3:
          return "已超时";
      }
    },
  },
  computed: {
    ...mapGetters("account", ["user"]),
    getProgressColor() {
      return function (successPercent) {
        let color = "";
        if (successPercent < 25) {
          color = "#615e83";
        } else if (successPercent <= 50) {
          color = "#e5eafc";
        } else if (successPercent <= 75) {
          color = "#a9e9fe";
        } else if (successPercent <= 90) {
          color = "#3aa5ff";
        } else {
          color = "#4a3aff";
        }
        return color;
      };
    },
    textStyle() {
      return function (val) {
        if (val.status == 0) {
          return "";
        } else if (val.status == 1) {
          return "before-second";
        } else if (val.status == 2) {
          return "before-third";
        } else if (val.status == 3) {
          return "before-fourth";
        }
      };
    },

  },
  mounted() {
    this.init(); // 初始化数据
    this.getProWarnList(); // 建委预警列表
    this.initPayChart()
    this.initJobAgeChart()
    this.initJobStructureChart()

    // 添加窗口 resize 事件监听
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleChangeTitle(e) {
      this.activedKey = e;
    },
    // 建委预警列表
    getProWarnList() {
      const params = {
        officeId: this.user.officeId,
        pageNumber: 1,
        pageSize: 50,
      };
      getProWarnList(params)
        .then((res) => {
          if (res.data.code === 0) {
            // this.noCloseWarnList = res.data.data
            this.noCloseWarnList = res.data.data.list.filter(
              (item) => item.status != 2
            );
          }
        })
        .catch((e) => {
          this.$message.error("获取预警列表失败",2);
        });
    },
    init() {
      getOfficeDetail({
        id: this.user.officeId,
      }).then((res) => {
        if (res.data.code === 0) {
          this.officeData = res.data.data;
        }
      });

      // 首页获取管理人员数据
      getHomeOfficeManage().then((res) => {
        if (res.data.code === 0) {
          this.mangerData = res.data.data;
        }
      });
      // 工人公司出勤数据统计
      getHomeOfficeStaff().then((res) => {
        if (res.data.code === 0) {
          this.staffData = res.data.data;
        }
      });

      // 查询班组考勤情况
      getHomeOfficeTeamKq({}).then(res => {
          if (res.data.code === 0) {
            this.teamRankList = res.data.data
           }
      })
      // 班组考勤数组
      getHomeOfficeTeamKqData({}).then(res => {
        if (res.data.code === 0) {
            this.teamDataKq = res.data.data;
          }
      })
      // 获取工资数据
      getProPayrollData().then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data;
          this.newPayrollData = data
          this.payrollData.forEach((item) => {
            Object.keys(data).forEach((key) => {
              if (item.key == key) {
                if (key == 'accumulatedTotal' || key == 'currMonthAccumulatedTotal') {
                  item.value = (Number(data[key]) / 10000).toLocaleString();
                } else {
                  item.value = data[key];
                }
              }
            });
          });
        }
      });
      // 获取设备数据
      getProDeviceData().then((res) => {
        if (res.data.code === 0) {
          this.deviceData = res.data.data;
          if (this.deviceData.attendanceDeviceVoList) {
            this.deviceData.attendanceDeviceVoList.forEach((el) => {
              el.online = Number(el.online)
                ? "在线"
                : el.manufacturerKey === "HK"
                ? "/"
                : "不在线";
              if (el.manufacturerKey === "yunqi") {
                el.manufacturerKey = "云启";
              } else if (el.manufacturerKey === "HK") {
                el.manufacturerKey = "海康";
              }
            });
          }
        }
      });
    },
    toDetail() {
      this.$router.push({ name: "劳务项目详情" });
    },
    handleChange(value) {
      console.log(`selected ${value}`);
    },
    initPayChart() {
      const chartDom = document.getElementById('payChart');
      this.payChart = echarts.init(chartDom);

      // 定义月份数组
      const months = ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"];

      // 获取当前日期的月份
      const currentMonthIndex = new Date().getMonth();

      // 生成包含当前月份和前11个月份的数组
      const previousMonths = [];
      for (let i = currentMonthIndex; i > currentMonthIndex - 12; i--) {
        // 处理负数索引，实现循环
        const monthIndex = i < 0 ? months.length + i : i;
        previousMonths.unshift(months[monthIndex]);
      }

      this.payChart.setOption({
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: previousMonths,
          // name: '月份', // 在 X 轴底部添加单位
          axisLine: {
            lineStyle: {
              color: '#999' // X 轴颜色
            }
          },
          axisLabel: {
            color: '#333' // X 轴标签颜色
          },
          axisTick: {
            show: false // 隐藏刻度线
          }
        },
        yAxis: [
          {
            type: 'value',
            name: '万元', // 在 Y 轴底部添加单位
            axisLine: {
              lineStyle: {
                  color: '#999' // Y 轴颜色
              }
            },
            axisLabel: {
                color: '#333' // Y 轴标签颜色
            },
            splitLine: {
              lineStyle: {
                  color: '#eee' // 分隔线颜色
              }
            }
          },
          {
            type: 'value',
            name: '发放人次', // 在右侧 Y 轴底部添加单位
            axisLine: {
              lineStyle: {
                  color: '#999' // 右侧 Y 轴颜色
              }
            },
            axisLabel: {
              color: '#333' // 右侧 Y 轴标签颜色
            },
            splitLine: {
              show: false // 右侧 Y 轴分隔线隐藏
            },
          }
        ],
        tooltip: {
          trigger: 'axis',
          // formatter: function(params) {
          //   var tooltipContent = params[0].axisValueLabel + '<br/>'; // X 轴数值
          //   params.forEach(function(item) {
          //       tooltipContent += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' + item.color + '"></span>'; // 颜色块
          //       if (item.seriesName === '发放人次') {
          //           tooltipContent += `${item.value}发放人次<br/>`; // 发放人次数据名称及数值
          //       } else {
          //           tooltipContent += `${item.value}万元<br/>`; // 其他数据名称及数值
          //       }
          //   });
          //   return tooltipContent;
          // }
        },
        series: [
          {
            data: [820, 932, 901, 934, 1290, 1330, 1320, 820, 932, 901, 934, 1290],
            type: 'line',
            yAxisIndex: 0, // 指定使用左侧的 Y 轴
            lineStyle: {
              color: '#7ba7e9', // 设置折线颜色为淡蓝色
              width: 2 // 设置折线宽度
            },
            itemStyle: {
              color: '#7ba7e9' // 设置标记点颜色为淡蓝色
            },
            symbol: 'circle', // 设置标记点形状为圆形
            symbolSize: 5, // 设置标记点大小
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  { offset: 0, color: '#9c8fe5' },     // 起始颜色
                  { offset: 1, color: '#e6f7ff' }      // 结束颜色
                ],
                global: false   // 缺省为 false
              }
            }
          },
          {
            data: [1320, 820, 932, 901, 934, 1290, 820, 932, 901, 934, 1290, 1330], // 添加发放人次的数据
            type: 'line',
            yAxisIndex: 1, // 指定使用右侧的 Y 轴
            lineStyle: {
                color: '#6b49ad', // 设置折线颜色为深紫色
                width: 2 // 设置折线宽度
            },
            itemStyle: {
                color: '#6b49ad' // 设置标记点颜色为深紫色
            },
            symbol: 'circle', // 设置标记点形状为圆形
            symbolSize: 5, // 设置标记点大小
          }
        ]
      });
    },
    initJobAgeChart() {
      const chartDom = document.getElementById('jobAgeChart');
      this.jobAgeChart = echarts.init(chartDom);

      this.jobAgeChart.setOption({
        title: {
          text: '年龄分布', // 设置标题文本
          left: 'center', // 标题水平居中
          top: 7, // 标题距离顶部的距离
          textStyle: {
            fontWeight: 'bold', // 标题字体加粗
            fontSize: 16 // 标题字体大小
          }
        },
        tooltip: {
          trigger: 'item',
        },
        legend: {
          bottom: '0',
          left: 'center',
        },
        color: ['#fc8251', '#5470c6', '#91cd77', '#75bedc'],
        series: [
          {
            type: 'pie',
            radius: ['40%', '55%'],
            center: ['50%', '40%'],
            label: {
              show: false,
              position: 'center'
            },
            data: []
          }
        ]
      });
    },
    initJobStructureChart() {
      const chartDom = document.getElementById('jobStructureChart');
      this.jobStructureChart = echarts.init(chartDom);

      this.jobStructureChart.setOption({
        title: {
          text: '工种分布', // 设置标题文本
          left: 'center', // 标题水平居中
          top: 7, // 标题距离顶部的距离
          textStyle: {
            fontWeight: 'bold', // 标题字体加粗
            fontSize: 16 // 标题字体大小
          }
        },
        tooltip: {
          trigger: 'item',
        },
        legend: {
          bottom: '0',
          left: 'center',
        },
        color: ['#fc8251', '#5470c6', '#91cd77', '#75bedc'],
        series: [
          {
            type: 'pie',
            radius: ['40%', '55%'],
            center: ['50%', '40%'],
            label: {
              show: false,
              position: 'center'
            },
            data: []
          }
        ]
      });
    },
    // 处理窗口 resize 事件
    handleResize: debounce(function() {
      // 调用 ECharts 实例的 resize 方法
      this.payChart.resize()
      this.jobAgeChart.resize()
      this.jobStructureChart.resize()
    }, 300)
  },
};
</script>

<style lang="less" scoped>
.card {
  border-radius: 10px;
  padding: 17px;
  background-color: #fff;
}
.main {
  display: flex;
  margin: 0px 10px;
  padding-bottom: 10px;

  .sider {
    display: flex;
    flex-direction: column;
    width: 350px;
    margin-right: 15px;
  }
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .content-top {
      flex: 1;
      display: flex;
      justify-content: space-between;
      height: 65%;
      .content-top-left {
        flex: 1;
        margin-right: 15px;
        display: flex;
        flex-direction: column;
      }
      .content-top-right {
        padding-left: 10px;
        padding-right: 10px;
        width: 400px;
      }
    }
  }

  .early-content {
    height: 201px;
  }

  .content-top-left {
    /deep/ .ant-card {
      border-radius: 10px;
    }
    /deep/ .ant-card-head-title {
      padding: 0;
    }
    /deep/ .ant-card-head {
      // min-height: 45px;
      line-height: 48px;
      border-bottom: 1px solid rgb(218, 230, 255);
    }
    /deep/ .ant-card-body {
      padding: 17px;
    }
    .project-card /deep/ .ant-card-body {
      padding: 24px;
    }
    .card-match {
      line-height: 27px;
      margin-top: auto;
    }
    .home-project {
      width: 126px;
      height: 82px;
    }

    .payroll-card {
      width: 350px;
      height: 93px;
      background: #f4f6ff;
      border-radius: 10px;
    }
    .wran-img {
      width: 210px;
      height: 150px;
    }

  }
}
</style>

<style lang="less" scoped>
p {
  margin: 0;
}
.public {
  padding: 17px;
  background: #fff;
  border-radius: 10px;
}
.warning-content {
  position: absolute;
  top: 10px;
  left: 18px;
}
.green {
  color: rgb(4, 206, 0);
}
.red {
  color: #ff718b;
}
.lightgray {
  color: #4c4c4c;
}
.tb-box-2 {
  height: 155px;
  overflow: hidden;
  overflow-y: auto;
}
.scroll-tabox {
  position: relative;
  /deep/ .ant-table-thead {
    position: sticky;
    top: 0px;
    z-index: 9999;
    background: #fff;
  }
}
/deep/ .close-table2 {
  .ant-table-thead > tr > th {
    background: #f4f6ff;
    padding: 4px 16px;
    font-weight: 400;
    font-size: 14px;
    font-family: 微软雅黑;
    color: rgb(0, 0, 0);
  }
  .ant-table-tbody > tr > td {
    color: #4d4d4d;
    // color: #666;
    font-family: 微软雅黑;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0px;
    padding: 8px 8px;
    border-bottom: unset;
  }
  .ant-table-placeholder {
    // padding: 8px 0;
  }
  .ant-empty-normal {
    // margin: 0;
  }
}
/deep/ .close-table {
  .ant-table-thead > tr > th {
    background: #f4f6ff;
    padding: 4px 16px;
    font-weight: 400;
    font-size: 14px;
    font-family: 微软雅黑;
    color: rgb(0, 0, 0);
  }
  .ant-table-tbody > tr > td {
    color: #4d4d4d;
    // color: #666;
    font-family: 微软雅黑;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0px;
    padding: 8px 8px;
    border-bottom: unset;
  }
  .ant-table-placeholder {
    padding: 8px 0;
  }
  .ant-empty-normal {
    margin: 0;
  }
  // 预警状态
  .statusDrop::before {
    width: 8px;
    height: 8px;
    background: #ee6945;
    content: "";
    display: inline-block;
    border-radius: 50%;
    margin-left: 15px;
    margin-right: 3px;
  }
  .before-second::before {
    background: #e5a858;
  }
  .before-third::before {
    background: #6ccc51;
  }
  .before-fourth::before {
    background: #656262;
  }
}

</style>
