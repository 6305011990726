/* 系统相关-常量 */

/**
* @description 允许手动设置系统类型的环境
*/
export const allowSetSystemTypeEnvs = ['development', 'test']

/**
* @description 系统类型缓存key
*/
export const systemTypeStorageKey = 'temporary_systemType'
