/**
* @description 系统相关工具类
*/
import { session, storage } from '@/utils/storage.js'
import { swapKeyValuePairs } from '@/utils/util.js'
import { SYSTEM_TYPE_ENUM, SYSTEM_TYPE_NAME_MAP, SYSTEM_TYPE_TEST_HOST_MAP, SYSTEM_TYPE_PROD_HOST_MAP } from '@/enums/system.js'
import { allowSetSystemTypeEnvs, systemTypeStorageKey } from '@/constants/system.js'
import { message } from 'ant-design-vue'

/**
* @description 设置系统类型，仅在开发/测试环境生效
*/
export function setSystemType(type) {
	// 为空判断
	if (!type) return console.warn('系统类型不能为空!')
	// 重复判断
	if (type === session.get(systemTypeStorageKey))  return console.info(`当前系统类型已经是"${SYSTEM_TYPE_NAME_MAP[type]}"`)
	// 无效判断
	if (Object.values(SYSTEM_TYPE_ENUM).includes(type) === false) return console.warn(`输入的系统类型无效，仅支持以下类型`, SYSTEM_TYPE_NAME_MAP)
	
		message.success(`系统类型已切换为"${SYSTEM_TYPE_NAME_MAP[type]}"，即将自动刷新`)
		setTimeout(() => {
		// seesionStorage 缓存系统类型
		session.set(systemTypeStorageKey, type)
		location.reload()
	}, 1500)
}
window.setSystemType = allowSetSystemTypeEnvs.includes(process.env.NODE_ENV) ? setSystemType : () => { console.warn('仅限开发/测试环境开放此功能！') }

/**
* @description 获取当前系统类型，开发环境默认返回劳务系统
*/
export function getSystemType() {
	// 根据环境加载对应 hostMap，接着进行键值互换
	const hostMap = swapKeyValuePairs(process.env.NODE_ENV === 'production' ? SYSTEM_TYPE_PROD_HOST_MAP : SYSTEM_TYPE_TEST_HOST_MAP)
	// 按当前域名匹配
	const systemType = hostMap[location.host] || SYSTEM_TYPE_ENUM.LAOWU

  // 当前为允许手动设置系统类型的环境
	if (allowSetSystemTypeEnvs.includes(process.env.NODE_ENV)) {
    // 未手动设置过，返回默认值
		if (!session.get(systemTypeStorageKey)) {
			return shizhengORjiananyi(systemType)
		}
		
    // 否则返回手动设置的类型
		return shizhengORjiananyi(session.get(systemTypeStorageKey))
	}
  // 不允许手动设置系统类型的环境，返回根据域名匹配到的默认值
	return shizhengORjiananyi(systemType)
}
window.getSystemType = getSystemType

/**
* @description TODO: 【市政】临时加一个市政集团的判断
*/
export function shizhengORjiananyi(systemType) {
	// 当前是建安易域名
	if (systemType === SYSTEM_TYPE_ENUM.JIANANYI) {
		// 判断租户是否是市政集团，是则返回市政集团系统类型
		const tenantId = storage.get('admin.user')?.tenantId
		// 164-测试环境，172-生产环境
		if (tenantId === 164 || tenantId === 172) {
			return SYSTEM_TYPE_ENUM.SHIZHENG
		} else {
			return systemType
		}
	} else {
		return systemType
	}
}

/**
* @description 返回当前系统是否为建安易
*/
export function isJianAnYi() {
	return getSystemType() === SYSTEM_TYPE_ENUM.JIANANYI
}

/**
* @description 返回当前系统是否为劳务
*/
export function isLaowu() {
	return getSystemType() === SYSTEM_TYPE_ENUM.LAOWU
}

/**
* @description 返回当前系统是否为机施安全
*/
export function isSafe() {
	return getSystemType() === SYSTEM_TYPE_ENUM.SAFE
}

/**
* @description 返回当前系统是否为市政集团
*/
export function isShiZheng() {
	return getSystemType() === SYSTEM_TYPE_ENUM.SHIZHENG
}