var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a_Approval" },
    [
      _c("standard-table", {
        attrs: {
          columns: _vm.columns,
          dataSource: _vm.tableData,
          rowKey: "projectId",
          pagination: _vm.pagination,
          loading: _vm.tableLoading,
          isAlert: false,
          bordered: true,
        },
        on: { change: _vm.handleChangePage },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function (ref) {
              var index = ref.index
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      (_vm.pagination.current - 1) * _vm.pagination.pageSize +
                        parseInt(index) +
                        1
                    )
                  ),
                ]),
              ]
            },
          },
          {
            key: "filePath",
            fn: function (ref) {
              var text = ref.text
              return [
                _c(
                  "div",
                  { staticClass: "flex j-center pointer" },
                  [
                    text && text.indexOf(".pdf") > -1
                      ? _c("a-icon", {
                          style: { fontSize: "32px" },
                          attrs: { type: "file-pdf" },
                          on: {
                            click: function ($event) {
                              return _vm.handlePreview(text)
                            },
                          },
                        })
                      : _c("img", {
                          staticStyle: { width: "32px" },
                          attrs: { alt: "", src: text },
                          on: {
                            click: function ($event) {
                              return _vm.handlePreview(text)
                            },
                          },
                        }),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "a-modal",
        {
          attrs: { visible: _vm.previewVisible, footer: null },
          on: { cancel: _vm.handleCancel },
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { alt: "", src: _vm.previewImage },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }