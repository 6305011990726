<template>
  <div class="a_Approval">
    <div class="plug-Overview">安全隐患整改台账记录表</div>
    <a-divider />
    <a-spin :spinning="spinning" :tip="loadingText">
    <a-form class="clearfix">
      <a-col :span="2">
        <a-form-item>
          <a-radio-group v-model="queryParams.type">
            <a-radio-button :value="1">
              月份
            </a-radio-button>
            <a-radio-button :value="2">
              年份
            </a-radio-button>
          </a-radio-group>
        </a-form-item>
      </a-col>

      <a-col :span="5">
        <a-form-item label="月份/年份" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
          <a-month-picker placeholder="请选择月份" :default-value="moment(monthDay, monthFormat)" :format="monthFormat" v-if="queryParams.type==1" v-model="queryParams.plan_date">
            <a-icon slot="suffixIcon" type="smile" />
          </a-month-picker>

          <a-date-picker
            v-if="queryParams.type==2"
            mode="year"
            v-model="queryParams.plan_date"
            placeholder="请输入年份"
            :default-value="moment(nowYear, yearFormat)" :format="yearFormat"
            :open="yearShowOne"
            @openChange="openChangeOne"
            @panelChange="panelChangeOne"/>
        </a-form-item>
      </a-col>
    <!-- <a-col :md="5" :sm="24" >
      <a-form-item
        label="是否闭环"
        :labelCol="{span: 10}"
        :wrapperCol="{span: 13, offset: 1}"
      >
      <a-select style="width:90px" v-model="queryParams.status">
        <a-select-option :value="0">全部</a-select-option>
        <a-select-option :value="1">未闭环</a-select-option>
        <a-select-option :value="2">已闭环</a-select-option>
      </a-select>
      </a-form-item>
    </a-col>
    <a-col :md="5" :sm="24" >
      <a-form-item
        label="检查类别"
        :labelCol="{span: 10}"
        :wrapperCol="{span: 13, offset: 1}"
      >
      <a-select style="width:115px" v-model="queryParams.zjType">
        <a-select-option :value="0">全部</a-select-option>
        <a-select-option :value="1">常规检查</a-select-option>
        <a-select-option :value="2">专项检查</a-select-option>
        <a-select-option :value="3">带班检查</a-select-option>
        <a-select-option :value="4">停工单</a-select-option>
      </a-select>
      </a-form-item>
    </a-col> -->

      <a-col :span="5">
        <a-form-item label="检查部门" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
          <a-select v-model="queryParams.qtType">
            <a-select-option :value="0">所有</a-select-option>
            <a-select-option :value="1">安全部</a-select-option>
            <a-select-option :value="2">工程管理部</a-select-option>
            <a-select-option :value="3">项目部</a-select-option>
            <!-- <a-select-option :value="3">带班检查</a-select-option>
            <a-select-option :value="4">停工单</a-select-option> -->
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :span="2">
        <a-space class="btnBox">
          <a-button type="primary" @click="doQueryBtn()">查询</a-button>
          <!-- <a-button style="margin-left: 8px;margin-right: 8px" @click="doReset()">重置</a-button> -->
        </a-space>
      </a-col>
    </a-form>
    <div>
      <standard-table
        :bordered="true"
        :columns="columns"
        :dataSource="dataSource"
        :isAlert="false"
        :pagination="pagination"
        :loading="tableLoading"
        @clear="onClear"
        @change="onPageChange"
        :btnArr="tableBtnArr"
      >
        <div slot="zgxq">
          已整改落实，详见整改回复单
        </div>
        <div slot="do-status" slot-scope="{text}">
          <label v-if="text==2"><a-icon style="font-size:15px;color:green" type="check" /></label>
          <label v-else-if="text==99"></label>
          <label v-else ><a-icon style="font-size:15px;color:red" type="close" /></label>
        </div>
      </standard-table>
    </div>
    <Export-loading :cPercent="cPercent" :isExporting="isExporting" :loadingText="loadingText"></Export-loading>
    </a-spin>
  </div>
</template>

<script>
import StandardTable from '@/components/table/StandardTable'
import {
  GET_YHBW_STAND_BOOK,
  EXPORT_YHBW_STAND_BOOK
  } from '@/services/api'

import { exportProgress } from '@/pages/aqjyzl/export' // 导出方法
import ExportLoading from '@/components/ExportLoading/ExportLoading' // progress组件
import {request} from '@/utils/request'
import moment from 'moment';
import { useRender } from '@/hooks/useRender.js'
const columns = [
  {
    title: '检查时间',
    dataIndex: 'xjTime',
    width:200,
    align: 'center',
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: '检查类别（项目自检/公司/外部）',
    dataIndex: 'typeName',
    align: 'center',
    scopedSlots: { customRender: 'typeName' }
  },
  {
    title: '隐患部位',
    dataIndex: 'yhbw',
    align: 'center',
   scopedSlots: { customRender: 'yhbw' }
  },
  {
    title: '主要隐患',
    dataIndex: 'problems',
    align: 'left',
   scopedSlots: { customRender: 'problems' }
  },
  {
    title: '整改责任人',
    dataIndex: 'zgzrr',
    align: 'center',
    scopedSlots: {customRender: 'zgzrr'}
  },
  {
    title: '整改情况',
    align: 'left',
    scopedSlots: {customRender: 'zgxq'}
  },
  {
    title: '备注',
    dataIndex: 'remark',
    align: 'center',
    scopedSlots: {customRender: 'remark'}
  },
]

export default {
  mixins: [exportProgress],
  name: 'QueryList',
  components: {StandardTable,ExportLoading},
  data () {
    return {
      dataSource:[],
      advanced: false,
      columns: columns,
      yearShowOne:false,
      selectedRows: [],
      tableLoading: false,
      spinning:false,
      loadingText:"数据加载中...",
      loadKeys:[],
      visible:false,
      monthFormat: 'YYYY-MM',
      yearFormat: 'YYYY',
      overDownIndex:0,
      monthDay:null,
      nowYear:null,
      queryParams:{
        date:null,
        type:1,
        projectName:null,
        plan_date:null,
        zjType:0,
        status:0,
        pageNumber:1,
        qtType:0
      },
      pagination:{
        current:1,
        pageSize:10,
        total:0,
        showLessItems:true,
        showQuickJumper:true
      },
      cPercent:0,
      isExporting:false,
      tableBtnArr: [
        {
            name: '导出',
            attrs: {
                type: 'primary'
            },
            click: this.doDowload
        }
      ]
    }
  },
  watch: {
    cPercent:{
      handler(newName, oldName) {
        this.cPercent=newName;
      },
      immediate: true,
    },
    loadingText:{
      handler(newName, oldName) {
        this.loadingText=newName;
      },
      immediate: true,
    }
  },
  created() {
    this.monthDay = moment(new Date()).format('YYYY-MM');
    this.nowYear = moment(new Date()).format('YYYY');
    this.doQuery();
  },
  authorize: {
    deleteRecord: 'delete'
  },
  methods: {
    moment,
    deleteRecord(key) {
      this.dataSource = this.dataSource.filter(item => item.key !== key)
      this.selectedRows = this.selectedRows.filter(item => item.key !== key)
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    remove () {
      this.dataSource = this.dataSource.filter(item => this.selectedRows.findIndex(row => row.key === item.key) === -1)
      this.selectedRows = []
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },
    changeDate(e){
      if(e==null||e.length<=0){
        this.queryParams.startDate = null;
        this.queryParams.endDate = null
      }
    },

    doQueryBtn(){
      this.queryParams.pageNumber = 1;
      this.pagination.pageNumber = this.queryParams.pageNumber;
      this.doQuery();
    },

    /**
     * 获取时间段的台账信息
     */
    doQuery(){
      this.tableLoading = true;
      if(this.queryParams.type==1){
        if(this.queryParams.plan_date==null){
          this.queryParams.date = this.monthDay
        }else{
          this.queryParams.date = this.queryParams.plan_date.format('YYYY-MM')
        }
      }
      if(this.queryParams.type==2){
        if(this.queryParams.plan_date==null){
          this.queryParams.date = this.nowYear
        }else{
          this.queryParams.date = this.queryParams.plan_date.format('YYYY')
        }
      }
      request(GET_YHBW_STAND_BOOK,"post",this.queryParams).then(res => {
        if(res.data.code==0){
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
          this.dataSource.forEach(obj=>{
            obj.key = obj.id;
          })
        }
        this.tableLoading = false;
      })
    },
    // doReset(){
    //   this.queryParams={};
    //   this.queryParams.type==1
    //   this.doQuery();
    // },
    //分页查询
    onPageChange(e){
      this.queryParams.pageNumber = e.current;
      this.pagination.current=e.current;
      this.doQuery();
    },
    /**
     * 导出excel
     */
    doDowload(){
      this.loadingText = "正在导出EXCEL文件，请稍候 ····";
      let name = ""
      if(this.queryParams.type==1){
        if(this.queryParams.plan_date==null){
          this.queryParams.date = this.monthDay
        }else{
          this.queryParams.date = this.queryParams.plan_date.format('YYYY-MM')
        }
        name = name+this.queryParams.date+"月"
      }
      if(this.queryParams.type==2){
        if(this.queryParams.plan_date==null){
          this.queryParams.date = this.nowYear
        }else{
          this.queryParams.date = this.queryParams.plan_date.format('YYYY')
        }
        name = name+this.queryParams.date+"年"
      }
      this.isExporting=true;
      this.cPercent=0;
      let fileName=name+"项目部安全隐患整改台账.xlsx";
      this.exportFunNomal2(EXPORT_YHBW_STAND_BOOK,this.queryParams,fileName,'application/vnd.ms-excel')
    },
    handleMenuClick (e) {
      if (e.key === 'delete') {
        this.remove()
      }
    },
    doSureModal(){
      this.visible = true;
    },
    onChange(dataStr){
      this.queryParams.plan_date=null;
    },
    openChangeOne(status) {
      if (status) {
        this.yearShowOne = true;
      }
    },
    // 得到年份选择器的值
    panelChangeOne(value) {
      this.yearShowOne = false;
      this.queryParams.plan_date = value;
    },

  }
}
</script>

<style lang="less" scoped>
  .search{
    margin-bottom: 54px;
  }
  .fold{
    width: calc(100% - 216px);
    display: inline-block
  }
  .operator{
    margin-bottom: 18px;
  }
  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
</style>
