var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tag-select" },
    [
      _c("tag-select-option", { on: { click: _vm.toggleCheck } }, [
        _vm._v("全部"),
      ]),
      _vm._t("default"),
      _c(
        "a",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showTrigger,
              expression: "showTrigger",
            },
          ],
          ref: "trigger",
          staticClass: "trigger",
          on: { click: _vm.toggle },
        },
        [
          _vm._v("展开"),
          _c("a-icon", {
            staticStyle: { "margin-left": "5px" },
            attrs: { type: _vm.collapsed ? "down" : "up" },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }