var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { bordered: false } },
    [
      _c(
        "div",
        { attrs: { id: "components-form-demo-advanced-search" } },
        [
          _c(
            "a-form",
            {
              staticClass: "ant-advanced-search-form",
              attrs: { form: _vm.searchForm },
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "上报时间" } },
                        [
                          _c("a-range-picker", {
                            attrs: {
                              "default-value": [
                                _vm.moment(
                                  _vm.searchForm.startTime,
                                  _vm.dateFormat
                                ),
                                _vm.moment(
                                  _vm.searchForm.endTime,
                                  _vm.dateFormat
                                ),
                              ],
                            },
                            on: { change: _vm.onChange },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { style: { marginTop: "3px" }, attrs: { span: 5 } },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            icon: "search",
                            loading: _vm.loading,
                          },
                          on: { click: _vm.search },
                        },
                        [_vm._v(" 查询 ")]
                      ),
                      _c(
                        "a-button",
                        {
                          style: { marginLeft: "8px" },
                          attrs: {
                            icon: "download",
                            loading: _vm.exportLoading,
                            type: "primary",
                          },
                          on: { click: _vm.exportExcel },
                        },
                        [_vm._v(" 导出 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("a-table", {
        attrs: {
          columns: _vm.columns,
          "data-source": _vm.data,
          pagination: _vm.pagination,
          loading: _vm.tableLoading,
          scroll: { x: 2000 },
        },
        on: { change: _vm.onPageChange },
        scopedSlots: _vm._u([
          {
            key: "dayTemp",
            fn: function (text, record) {
              return [
                _c(
                  "span",
                  { style: { color: record.dayTemp <= "37.3" ? "" : "red" } },
                  [_vm._v(_vm._s(text))]
                ),
              ]
            },
          },
          {
            key: "color",
            fn: function (text, record) {
              return [
                _c(
                  "span",
                  { style: { color: record.color == 1 ? "" : "red" } },
                  [
                    _vm._v(
                      _vm._s(text === 1 ? "绿码" : text === 2 ? "黄码" : "红码")
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "checkLastStatus",
            fn: function (text, record) {
              return [
                _c(
                  "span",
                  {
                    style: { color: record.checkLastStatus == 1 ? "" : "red" },
                  },
                  [_vm._v(_vm._s(text === 1 ? "阴性" : "阳性"))]
                ),
              ]
            },
          },
          {
            key: "highRisk",
            fn: function (text, record) {
              return [
                _c(
                  "span",
                  { style: { color: record.highRisk == 0 ? "" : "red" } },
                  [
                    _vm._v(
                      _vm._s(text === 0 ? "否" : "是,") +
                        " " +
                        _vm._s(record.remark)
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "familyStatus",
            fn: function (text, record) {
              return [
                _c(
                  "span",
                  { style: { color: record.familyStatus == 1 ? "" : "red" } },
                  [
                    _vm._v(
                      _vm._s(text === 1 ? "无异常" : "其他,") +
                        _vm._s(record.familyRemark)
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "image",
            fn: function (text) {
              return [_c("img", { attrs: { src: text, width: "80px;" } })]
            },
          },
          {
            key: "travelCode",
            fn: function (text) {
              return [_c("img", { attrs: { src: text, width: "80px;" } })]
            },
          },
          {
            key: "travelCodeColor",
            fn: function (text, record) {
              return [
                _c(
                  "span",
                  {
                    style: {
                      color:
                        record.travelCodeColor === 0 ||
                        record.travelCodeColor === 1
                          ? ""
                          : "red",
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        text === 0
                          ? ""
                          : text === 1
                          ? "绿码"
                          : text === 2
                          ? "黄码"
                          : "红码"
                      )
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "vaccinationImg",
            fn: function (text) {
              return [_c("img", { attrs: { src: text, width: "80px;" } })]
            },
          },
          {
            key: "nucleicAcidImg",
            fn: function (text) {
              return [_c("img", { attrs: { src: text, width: "80px;" } })]
            },
          },
          {
            key: "isolate",
            fn: function (text, record) {
              return [
                _c(
                  "span",
                  { style: { color: record.isolate === 0 ? "" : "red" } },
                  [_vm._v(_vm._s(text === 1 ? "已隔离" : "未隔离"))]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }