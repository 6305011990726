<template>
  <div>
    <a-card class="pitfall-card">
          <div slot="title">
            <div class="flex a-center">
              <img style="width: 21px; height: 21px; margin-right: 5px"
                src="https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20241120/1732091350443.png?Expires=2047451350&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=59AEqlAw%2B%2BGyQ%2F%2B4QYdFWxJZ7rs%3D"
                alt="" />
              <span class="ml-1 font-bold">危大工程情况</span>
            </div>
          </div>
          <div style="padding-left: 13px; padding-right: 13px">
            <a-row :gutter="[16, 20]">
              <a-col v-for="(item, index) in projectContent" :key="index" :span="8">
                <div class="flex j-center a-center content-box black" :style="item.style">
                  <span class="black-three text-center font-14">{{item.label}}</span>
                  <span class="ml-1 font-20 font-bold black">{{item.value || 0}}</span>
                </div>
              </a-col>
            </a-row>
            <div class="mt-2" id="checkTimesCateGoryChart" style="width: 98%; height: 331px;margin-bottom: 15px;
    margin-top: 21px;"></div>
          </div>
        </a-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {getWdgcThdjStat} from "@/services/labourProjectExcellent";

const categoryTitle = [{name: '深基坑工程', gclbId: '1'},{name: '模板工程及支撑体系', gclbId: '2'},{name: '起重吊装及安装拆卸工程', gclbId: '3'},{name: '脚手架工程', gclbId: '4'},{name: '拆除工程', gclbId: '5'},{name: '暗挖工程', gclbId: '6'},{name: '其他', gclbId: '7'}]
export default {
  data() {
    return {
      categoryTitle:categoryTitle,
      cateGoryColor: [
        "#fc8251",
        "#5470c6",
        "#91cd77",
        "#75bedc",
        "#f9c956",
        "#ef6567",
        "#45C2E0",
        "#C1EBDD",
        "#FFC851",
      ],
      projectContent:
        [
          { value: '', label: '危大工程总数',key:'wdgcTotal' },
          { value: '', label: '危大工程数' ,key:'wdgcCount'},
          { value: '', label: '超危工程数' ,key:'overWdgcCount'},
        ],
        wdgcObj: {}
    }
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  mounted() {
    // this.$nextTick(this.initCheckTimesCateGoryChart([]));
    this.getWdgcStat()
  },
  methods: {
    initCheckTimesCateGoryChart(data) {
      // 提取标签（柱子下的名字）
      const categoryLabels = data.map(item => item.name);
      // 遍历data数组，为每个对象添加itemStyle属性并赋予对应的颜色值
      data.forEach((item, index) => {
        this.$set(item, "itemStyle", { color: this.cateGoryColor[index] });
      });
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(
        document.getElementById("checkTimesCateGoryChart")
      );
      // 指定图表的配置项和数据
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          top: "20px",
          left: "20px",
          right: "20px", // 增加两侧留白
          bottom: "10px",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: categoryLabels, // 设置柱子下方的标签
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              interval: 0, // 显示所有标签
              rotate: 45, // 如果标签较长可设置旋转角度
              formatter: value => value, // 格式化标签显示
              color: "#615E83", // 标签颜色
              fontSize: 12, // 标签字体大小
            },
            axisLine: {
              lineStyle: {
                color: "#E6EFFF", // 修改 X 轴的颜色
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLine: {
              show: false, // 隐藏 Y 轴轴线
            },
            axisTick: {
              show: false, // 隐藏刻度
            },
            axisLabel: {
              show: true, // 显示数值
              color: "#615E83", // 数值颜色
              fontSize: 12, // 数值字体大小
            },
            splitLine: {
              show: true, // 显示横线
              lineStyle: {
                color: "#E6EFFF", // 横线颜色
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: "25%", // 调整柱子的宽度
            data: data,
            label: {
              show: true,
              position: "outside",
            },
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    getWdgcStat() {
      getWdgcThdjStat({projectId: this.user.projectId}).then(res => {
        if (res.data.code === 0) {
          console.log(res,'getWdgcStat===========')
          this.wdgcObj = res.data.data
          const wdgcChartList = res.data.data.wdgcTypeCountList.map(el => {
            return {
              name: el.gclbName,
              value: el.count,
              gclbId: el.gclbId
            }
          })
          const combinedData = this.categoryTitle.map(item => {
            const category = wdgcChartList.find(category => category.gclbId == item.gclbId);
            return {
              name: item.name,
              value: category ? category.value : 0
            };
          });
          this.initCheckTimesCateGoryChart(combinedData)
          this.projectContent = this.projectContent.map(item => {
            if (this.wdgcObj[item.key]) item.value = this.wdgcObj[item.key];
            return item;
          })
        }

      })
    }
  }
};
</script>
<style lang="less" scoped>
/deep/.ant-card {
  border-radius: 10px;
  border: none;

  .ant-card-body {
    padding-left: 0;
    padding-right: 0;
  }

  .ant-card-head {
    padding: 0 36px 0 40px;
    border-bottom: 1px solid #e6efff;
  }

  .ant-card-head-title {
    padding: 20px 0;
  }
}

.font-14 {
  font-size: 14px;
}
</style>
