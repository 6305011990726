<template>
    <div class="a_Approval">
        <a-form-model :model="params" class="clearfix" @submit="handleSubmit">
            <a-col :span="4">
                <a-form-model-item label="年份" :labelCol="{ span: 6 }" :wrapperCol="{ span: 14, offset: 1 }">
                    <!-- <a-month-picker v-model="params.reportTime"  placeholder="选择月份" valueFormat="YYYY-MM" /> -->
                    <a-date-picker
                       
                        mode="year"
                        v-model="params.reportTime"
                        placeholder="请输入年份"
                        :format="'YYYY'"
                        style="width: 140px"
                        :open="isOpen"
                        @openChange="monthOpenChangeOne"
                        @panelChange="monthPanelChangeOne"/>
                </a-form-model-item>
            </a-col>

            <a-col :span="4">
                <a-space class="btnBox">
                    <a-button type="primary" html-type="submit"> 查询 </a-button>
                    <a-button type="primary" @click="addNewComplaint" v-if="isProject"> 新增每月风险图 </a-button>
                </a-space>
            </a-col>

        </a-form-model>
        <standard-table :columns="columns" :dataSource="tableData" rowKey="id" :pagination="pagination"
            :loading="tableLoading" @change="handleChangePage" :isAlert="false" :bordered="true" :btnArr="tableBtnArr"
            :selectedRows.sync="selectedRows" :scroll="{ x: 1400 }">
            <!-- @selectedRowChange="handleSelectedRowsChange" :totalBoolean="true" :rowColor="false" -->
            <template slot="index" slot-scope="{ index }">
                <span>{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}</span>
            </template>
            <template slot="attach1" slot-scope="{ record }">
                <!-- {{ record }} -->
                <div class="flex j-center" style="flex-wrap: wrap;">
                    <div v-for="(item, index) in record.attachList1" :key="index">

                        <img alt="" style="width: 32px" v-if="item.type == 'img'" :src="item.url"
                            @click="handlePreview(item)" />

                        <a-icon type="file-pdf" :style="{ fontSize: '32px' }" v-else-if="item.type == 'pdf'"
                            @click="handlePreview(item)" />
                        <a-icon type="file-text" :style="{ fontSize: '32px' }" v-else
                            @click="handlePreview(item)" />
                    </div>
                </div>
            </template>
            <template slot="action" slot-scope="{ record }">
                <div class="sTables">
                    <div class="y-edit" @click="handlePreview(record.attachList1[0])">平面图预览</div>
                    <div class="y-other" @click="handleDownload(record)">下载</div>
                    <div class="y-del" @click="handleDel(record)" v-if="isProject">删除</div>
                    <!-- <div class="y-del" @click="handleView(record)">t12</div> -->
                </div>
            </template>
        </standard-table>
        <detail :visible.sync="visible" :prInfo="prInfo" :isEdit="isEdit" :id="id" @closeDialog="closeDialog" @refresh="handleQuery" />
        <a-modal :visible="previewVisible" :footer="false" title="预览" @cancel="previewVisible= false">
            <img :src="previewImage" style="width: 100%" />
        </a-modal>
    </div>
</template>

<script>

import {
    riskmapdetailList, riskmapdetailDelete, riskmapByID
} from "@/services/riskControll";

import { mapGetters } from "vuex";
import StandardTable from "@/components/table/StandardTable";
import moment from "moment";
import detail from "./components/gmiDetail.vue";
import { getProjectDetail } from "@/services/equipmentLedger";

import mixin from './components/mixinGmi'
import mixinRole from './components/mixinRole'

export default {
    name: "emerTeam",
    components: {
        StandardTable,
        detail
    },
    mixins: [mixin, mixinRole],
    data() {
        return {
            params: {
                "companyName": "", //公司名称
                "parentId": "", //风险地图ID
                "projectName": "", //项目名称
                "branchOfficeId": "", 
                "branchOfficeName": "", 
                "pageNo": "1", //页码
                "pageSize": "10", //每页条数
                companyId: "",
                projectId: "",
                reportTime: moment().format('YYYY')
            },
            pagination: {
                //分页数据
                current: 1,
                pageSize: 10,
                total: 0,
                showTotal: (total, range) => `共 ${total} 条`,
            },
            total: 0,
            tableData: [],
            tableLoading: false, //table加载中
            selectedRows: [],
            tmpSelectedRows: [],

            visible: false,
            isEdit: 0,
            id: "",

            prInfo:{
                "id": null, //风险地图ID
                "companyId": "", //公司ID
                "companyName": "", //公司名称
                "branchOfficeId": "", //分公司ID
                "branchOfficeName": "", //分公司名称
                "projectId": "", //项目ID
                "projectName": "", //项目名称
                "projectMap": "", //项目地图
            },
            projectInfo: {
                parentOffice: '',
                parentOfficeId: '',
                gcglbId: '',
                gcglbName: '',
                projectId: '',
                projectName: '',
                reportTime: ''
            },
        };
    },
    activated() {
        console.log('test')
        this.init()
    },
    created() {
        if (!this.isSafe) {
            // this.columns = this.columns.filter(el => el.title != '操作')
        }
        // this.handleQuery();
    },
    computed: {
        tableBtnArr() {
            return [
              
            ]
        }
    },
    methods: {
        async init() {
            const res = await riskmapByID({ id: this.$route.query.id })
            if(this.isProject) {
                let d = this.user.loginLaoWuUser.projectId
                const res2 = await getProjectDetail(d)
                
                if (res2.data.code === 0) {
                        
                        this.projectInfo.parentOfficeId = res2.data.data.parentOfficeId;
                        this.projectInfo.parentOffice = res2.data.data.parentOffice;
                        this.projectInfo.gcglbId = res2.data.data.gcglbId;
                        this.projectInfo.gcglbName = res2.data.data.gcglbName;
                        this.projectInfo.projectId = res2.data.data.projectId;
                        this.projectInfo.projectName = res2.data.data.projectName;
                        
                        this.params.companyName = this.projectInfo.parentOffice;
                        this.params.projectName = this.projectInfo.projectName;
                        this.params.branchOfficeName = this.projectInfo.gcglbName;
                        this.params.companyId = this.projectInfo.parentOfficeId;
                        this.params.branchOfficeId = this.projectInfo.gcglbId;
                        this.params.projectId = this.projectInfo.projectId;
                } 
            }
            if (res.data.code == 0) {
                this.prInfo = res.data.data
                this.params.parentId = this.prInfo.id
                // this.params.companyName = this.prInfo.companyName
                // this.params.projectName = this.prInfo.projectName
                this.handleQuery();
            }

        },
        handleSubmit(e) {
            e.preventDefault();
            this.params.pageNo = 1
            this.pagination.current = 1
            this.handleQuery();
        },
        handleQuery(e) {
            this.tableData = []
            this.tableLoading = true
            let d = this.params;
            d.reportTime = d.reportTime ? moment(d.reportTime).format('YYYY'): ''
            riskmapdetailList(d).then((res) => {
                this.tableLoading = false
                if (res.data.code == 0) {
                    let list = res.data.data.list;
                    
                    this.tableData = list.map((item, index) => {
                        let attachList1 = this.calcAttach(item,'planarGraph')
                        
                        return {
                            ...item,
                            attachList1
                        };
                    })
                    this.pagination.total = res.data.data.total;
                } else {
                    this.$message.error(res.data.msg)
                }
            });
        },
        handleChangePage(e) {
            this.params.pageNo = e.current;
            this.params.pageSize = e.pageSize;
            this.pagination.current = e.current;
            this.handleQuery();
        },
        addNewComplaint() {
            this.isEdit = 0
            this.id = ''
            this.visible = true
        },
        handleEdit(e) {

            this.isEdit = 1
            this.id = e.id
            this.visible = true
        },
        handleView(e) {
            this.isEdit = 2
            this.id = e.id
            this.visible = true
        },
        handleDel(record) {
            const _that = this
            this.$confirm({
                title: '是否确定删除？',
                cancelText: '否',
                okText: '是',
                onOk: () => {
                    riskmapdetailDelete({ id: record.id }).then(res => {
                        if (res.data.code == 0) {
                            _that.$message.success('删除成功')
                            this.handleQuery(this.params);
                        } else {
                            _that.$message.error(res.data.msg)
                        }
                    })
                },
            })
        },
        resetQuery() {
            this.pagination.current = 1
            this.params = {
                "companyName": "", //公司名称
                "parentId": "", //风险地图ID
                "projectName": "", //项目名称
                "branchOfficeId": "", 
                "branchOfficeName": "", 
                "pageNo": "1", //页码
                "pageSize": "10", //每页条数
                companyId: "",
                projectId: "",
                reportTime: moment().format('YYYY-MM')
            };
            this.params.parentId = this.prInfo.id
            if(this.isProject) {
                this.params.companyName = this.projectInfo.parentOffice;
                this.params.projectName = this.projectInfo.projectName;
                this.params.branchOfficeName = this.projectInfo.gcglbName;
                this.params.companyId = this.projectInfo.parentOfficeId;
                this.params.branchOfficeId = this.projectInfo.gcglbId;
                this.params.projectId = this.projectInfo.projectId;
            }
            this.handleQuery();
        },
        closeDialog() {
            this.visible = false
        },
        handleDownload(record) {
            if(record.planarGraph){
                window.open(record.planarGraph)
            }else{
                this.$message.error('暂无文件')
            }
        },
        handleGo(record) {
            this.$router.push({name: '风险分级地图绘制情况', query: {id: record.id}})
        }

        

    },
};
</script>

<style lang="less" scoped>
.query-form {
    display: flex;
    // justify-content: space-between;
}

.post-border {
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 5px;
    padding-top: 5px;
    // padding-top: 10px;
    // height: 40px;
}

.post-border:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

.sTables {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.y-edit {
    color: #307dfa;
    cursor: pointer;
}
.y-del {
    color: #ff5c77;
    cursor: pointer;
}
.y-other {
    color: #615e83;
    cursor: pointer;
}
</style>