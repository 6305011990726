var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.$route.meta.showFather,
              expression: "$route.meta.showFather",
            },
          ],
          staticClass: "a_Approval",
        },
        [
          _c("div", { staticClass: "plug-Overview" }, [
            _vm._v("远程视频检查台账"),
          ]),
          _c("a-divider"),
          _c(
            "a-form-model",
            {
              staticClass: "mb-5 query-form",
              attrs: { layout: "inline", model: _vm.params },
              on: { submit: _vm.handleSubmit },
            },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "分公司" } },
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { allowClear: "", placeholder: "请选择" },
                      model: {
                        value: _vm.params.gcglbId,
                        callback: function ($$v) {
                          _vm.$set(_vm.params, "gcglbId", $$v)
                        },
                        expression: "params.gcglbId",
                      },
                    },
                    _vm._l(_vm.gcglbList, function (item) {
                      return _c(
                        "a-select-option",
                        {
                          key: item.value,
                          attrs: { value: item.value, title: item.name },
                        },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "项目名称" } },
                [
                  _c("a-input", {
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "请输入项目名称", allowClear: "" },
                    model: {
                      value: _vm.params.projectName,
                      callback: function ($$v) {
                        _vm.$set(_vm.params, "projectName", $$v)
                      },
                      expression: "params.projectName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "检查类别" } },
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { placeholder: "请选择检查类别", allowClear: "" },
                      model: {
                        value: _vm.params.zxjcKey,
                        callback: function ($$v) {
                          _vm.$set(_vm.params, "zxjcKey", $$v)
                        },
                        expression: "params.zxjcKey",
                      },
                    },
                    _vm._l(_vm.checkTypeDic, function (items) {
                      return _c(
                        "a-select-option",
                        {
                          key: items.id,
                          attrs: { value: items.id, title: items.itemValue },
                        },
                        [_vm._v(" " + _vm._s(items.itemValue) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "检查状态" } },
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { placeholder: "请选择检查状态", allowClear: "" },
                      model: {
                        value: _vm.params.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.params, "status", $$v)
                        },
                        expression: "params.status",
                      },
                    },
                    _vm._l(_vm.selectlist, function (items) {
                      return _c(
                        "a-select-option",
                        {
                          key: items.name,
                          attrs: { value: items.value, title: items.name },
                        },
                        [_vm._v(" " + _vm._s(items.name) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        { attrs: { type: "primary", "html-type": "submit" } },
                        [_vm._v(" 查询 ")]
                      ),
                      _c("a-button", { on: { click: _vm.resetQuery } }, [
                        _vm._v(" 重置 "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "a_a_footer" },
            [
              _c("standard-table", {
                attrs: {
                  columns: _vm.columns,
                  dataSource: _vm.tableData,
                  rowKey: "id",
                  pagination: _vm.pagination,
                  loading: _vm.tableLoading,
                  isAlert: false,
                  scroll: { x: 1300 },
                  bordered: true,
                },
                on: { change: _vm.handleChangePage },
                scopedSlots: _vm._u([
                  {
                    key: "index",
                    fn: function (ref) {
                      var index = ref.index
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              (_vm.pagination.current - 1) *
                                _vm.pagination.pageSize +
                                parseInt(index) +
                                1
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "officeName",
                    fn: function (ref) {
                      var record = ref.record
                      return _vm._l(
                        record.officeName.split(","),
                        function (item, index) {
                          return _c("div", { key: index }, [
                            _c("span", [_vm._v(_vm._s(item))]),
                          ])
                        }
                      )
                    },
                  },
                  {
                    key: "inspectonDate",
                    fn: function (ref) {
                      var record = ref.record
                      return [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm
                                .moment(record.askCheckTime)
                                .format("YYYY-MM-DD")
                            )
                          ),
                        ]),
                        _c("div", [_vm._v(_vm._s("" + record.week))]),
                      ]
                    },
                  },
                  {
                    key: "action",
                    fn: function (ref) {
                      var record = ref.record
                      return _c("div", {}, [
                        _vm.user.loginType == 1
                          ? _c(
                              "a",
                              {
                                on: {
                                  click: function () {
                                    return _vm.handleEdit(record)
                                  },
                                },
                              },
                              [_vm._v(" 查看 ")]
                            )
                          : _vm._e(),
                        _vm.user.loginType == 1 && record.status == 0
                          ? _c(
                              "a",
                              {
                                staticClass: "ml-2",
                                staticStyle: { color: "red" },
                                on: {
                                  click: function () {
                                    return _vm.handleDel(record)
                                  },
                                },
                              },
                              [_vm._v(" 删除 ")]
                            )
                          : _vm._e(),
                      ])
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }