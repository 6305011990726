var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "flex", "flex-direction": "column" } },
    [
      !_vm.openWaring
        ? _c(
            "div",
            { staticClass: "pro-box" },
            [
              _c(
                "a-row",
                { attrs: { type: "flex", justify: "space-between" } },
                _vm._l(_vm.proList, function (item, index) {
                  return _c(
                    "a-col",
                    {
                      key: item.key + index,
                      staticClass: "tab-box",
                      class: _vm.proActive == index ? "pro-active" : "",
                      style: item.color,
                      on: {
                        click: function ($event) {
                          return _vm.onProAction(index, item)
                        },
                      },
                    },
                    [
                      _c("div", { staticStyle: { "line-height": "45px" } }, [
                        _vm._v(_vm._s(item.title)),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "font-24",
                          staticStyle: { "line-height": "20px" },
                        },
                        [_vm._v(" " + _vm._s(item.value) + " ")]
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "a-row",
        {
          staticClass: "card mt-1",
          staticStyle: { "padding-top": "20px", flex: "1" },
        },
        [
          _c(
            "a-col",
            { staticStyle: { height: "100%" }, attrs: { span: 16 } },
            [
              _c(
                "div",
                { staticClass: "relative", staticStyle: { height: "100%" } },
                [
                  _c(
                    "baidu-map",
                    {
                      staticClass: "baidu-map",
                      staticStyle: { height: "100%" },
                      attrs: {
                        "scroll-wheel-zoom": true,
                        center: _vm.location,
                        zoom: _vm.zoom,
                      },
                    },
                    [
                      _c("bm-view", { staticClass: "bmMap" }),
                      _vm._l(_vm.persons, function (items, index) {
                        return _c(
                          "div",
                          { key: index },
                          [
                            _c("bm-marker", {
                              attrs: {
                                position: { lng: items.lng, lat: items.lat },
                                icon: {
                                  url: items.show
                                    ? _vm.localActive
                                    : items.icon,
                                  size: { width: 28, height: 28 },
                                },
                                dragging: false,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleMarkerClick(items)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]
          ),
          _c("a-col", { staticStyle: { height: "100%" }, attrs: { span: 8 } }, [
            _c(
              "div",
              { staticClass: "per-project", staticStyle: { height: "100%" } },
              [
                _vm.type === "office"
                  ? [
                      _c("div", {
                        staticStyle: { width: "98%", height: "400px" },
                        style: { display: _vm.isOver ? "block" : "none" },
                        attrs: { id: "yhzsPieChart" },
                      }),
                    ]
                  : _vm._e(),
                !_vm.isOver
                  ? [
                      _c(
                        "div",
                        { staticClass: "info-window relative" },
                        [
                          _vm.type === "office"
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    color: "#615e83",
                                    "font-size": "14px",
                                    position: "absolute",
                                    right: "0",
                                    top: "-10px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.isOver = true
                                    },
                                  },
                                },
                                [_vm._v("返回总览>")]
                              )
                            : _vm._e(),
                          _c("div", { staticClass: "pb-2 " }),
                          _c(
                            "div",
                            {
                              staticClass: "ellipsis-2 black font-16 font-bold",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.staffObj.projectMsg.fullName ||
                                    _vm.staffObj.projectName
                                )
                              ),
                            ]
                          ),
                          _c(
                            "a-tag",
                            {
                              staticClass: "mt-1",
                              attrs: {
                                color: _vm.getTagStatus(
                                  _vm.staffObj.status,
                                  "color"
                                ),
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.getTagStatus(_vm.staffObj.status))
                              ),
                            ]
                          ),
                          _vm.getTagName(_vm.staffObj.ptype)
                            ? _c("a-tag", { attrs: { color: "#F7A22C" } }, [
                                _vm._v(
                                  _vm._s(_vm.getTagName(_vm.staffObj.ptype))
                                ),
                              ])
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass: "ellipsis-1 font-bold mt-2 mb-2",
                              staticStyle: {
                                "line-height": "30px",
                                color: "#212121",
                                "font-size": "14px",
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  "工程造价：" +
                                    _vm._s(
                                      _vm.staffObj.projectMsg.projBudget ||
                                        "暂无"
                                    ) +
                                    " "
                                ),
                                _vm.staffObj.projectMsg.projBudget
                                  ? _c("span", [_vm._v("万元")])
                                  : _vm._e(),
                              ]),
                              _c("div", [
                                _vm._v(
                                  "参建单位：" +
                                    _vm._s(_vm.staffObj.officeName || "暂无")
                                ),
                              ]),
                              _c("div", [
                                _vm._v(
                                  "参建负责人：" +
                                    _vm._s(_vm.staffObj.userName || "暂无")
                                ),
                              ]),
                              _c("div", [
                                _vm._v(
                                  "联系电话：" +
                                    _vm._s(_vm.staffObj.userPhone || "暂无")
                                ),
                              ]),
                            ]
                          ),
                          _c("a-divider"),
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.staffObj.projectMsg.projDesc || "暂无"
                                    ) +
                                    " "
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "black font-bold ellipsis-2",
                                  staticStyle: {
                                    "font-size": "14px",
                                    "-webkit-line-clamp": "5",
                                  },
                                },
                                [
                                  _vm._v(
                                    "项目概况：" +
                                      _vm._s(
                                        _vm.staffObj.projectMsg.projDesc ||
                                          "暂无"
                                      )
                                  ),
                                ]
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }