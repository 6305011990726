var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "constructionWarning" }, [
    _vm._m(0),
    _c("div", [
      _c(
        "div",
        { staticStyle: { "font-size": "14px", color: "#212121" } },
        [
          _c("a-icon", {
            staticStyle: { "margin-right": "4px" },
            attrs: { type: "warning" },
          }),
          _vm._v("预警处理方法："),
        ],
        1
      ),
      _c("div", { staticStyle: { "font-size": "12px", color: "#4D4D4D" } }, [
        _vm._v(
          "未上传工资确认表：在工资分账功能模块上传对应的工资表附件。满足以上条件后，平台第二天自动关闭预警。"
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list" }, [
      _c("div", { staticClass: "item" }, [
        _c("img", {
          staticClass: "warning",
          attrs: {
            src: require("@/assets/img/home/constructionWarning-1.png"),
          },
        }),
        _c("span", { staticClass: "text" }, [_vm._v("0")]),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/img/home/constructionWarning-2.png"),
          },
        }),
        _c("span", { staticClass: "text" }, [_vm._v("0")]),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/img/home/constructionWarning-3.png"),
          },
        }),
        _c("span", { staticClass: "text" }, [_vm._v("0")]),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/img/home/constructionWarning-4.png"),
          },
        }),
        _c("span", { staticClass: "text" }, [_vm._v("0")]),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/img/home/constructionWarning-5.png"),
          },
        }),
        _c("span", { staticClass: "text" }, [_vm._v("0")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }