var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form-model",
        {
          staticClass: "clearfix",
          attrs: { model: _vm.params },
          on: { submit: _vm.handleSubmit },
        },
        [
          _c(
            "a-col",
            { attrs: { span: 5 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "选择年份",
                    labelCol: { span: 8 },
                    wrapperCol: { span: 14, offset: 1 },
                  },
                },
                [
                  _c("a-date-picker", {
                    attrs: {
                      mode: "year",
                      placeholder: "请选择年份",
                      open: _vm.yearShowOne,
                      format: "YYYY",
                    },
                    on: {
                      openChange: _vm.openChangeOne,
                      panelChange: _vm.panelChangeOne,
                    },
                    model: {
                      value: _vm.params.year,
                      callback: function ($$v) {
                        _vm.$set(_vm.params, "year", $$v)
                      },
                      expression: "params.year",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isJSOffice
            ? _c(
                "a-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "工程管理部",
                        labelCol: { span: 8 },
                        wrapperCol: { span: 14, offset: 1 },
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: { allowClear: "", placeholder: "请选择" },
                          model: {
                            value: _vm.params.gcglbId,
                            callback: function ($$v) {
                              _vm.$set(_vm.params, "gcglbId", $$v)
                            },
                            expression: "params.gcglbId",
                          },
                        },
                        _vm._l(_vm.gcglbList, function (item) {
                          return _c(
                            "a-select-option",
                            {
                              key: item.value,
                              attrs: { value: item.value, title: item.name },
                            },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-col",
            { attrs: { span: 5 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "项目名称",
                    labelCol: { span: 8 },
                    wrapperCol: { span: 14, offset: 1 },
                  },
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入项目名称", allowClear: "" },
                    model: {
                      value: _vm.params.projectName,
                      callback: function ($$v) {
                        _vm.$set(_vm.params, "projectName", $$v)
                      },
                      expression: "params.projectName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 4 } },
            [
              _c(
                "a-space",
                { staticClass: "btnBox" },
                [
                  _c(
                    "a-button",
                    { attrs: { type: "primary", "html-type": "submit" } },
                    [_vm._v(" 查询 ")]
                  ),
                  _c("a-button", { on: { click: _vm.resetQuery } }, [
                    _vm._v(" 重置 "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("standard-table", {
        attrs: {
          columns: _vm.columns,
          dataSource: _vm.tableData,
          rowKey: "projectId",
          pagination: _vm.pagination,
          loading: _vm.tableLoading,
          isAlert: false,
          bordered: true,
        },
        on: { change: _vm.handleChangePage },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function (ref) {
              var index = ref.index
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      (_vm.pagination.current - 1) * _vm.pagination.pageSize +
                        parseInt(index) +
                        1
                    )
                  ),
                ]),
              ]
            },
          },
          {
            key: "filePath",
            fn: function (ref) {
              var text = ref.text
              return [
                _c(
                  "div",
                  { staticClass: "flex j-center pointer" },
                  [
                    text && text.indexOf(".pdf") > -1
                      ? _c("a-icon", {
                          style: { fontSize: "32px" },
                          attrs: { type: "file-pdf" },
                          on: {
                            click: function ($event) {
                              return _vm.handlePreview(text)
                            },
                          },
                        })
                      : _c("img", {
                          staticStyle: { width: "32px" },
                          attrs: { alt: "", src: text },
                          on: {
                            click: function ($event) {
                              return _vm.handlePreview(text)
                            },
                          },
                        }),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "a-modal",
        {
          attrs: { visible: _vm.previewVisible, footer: null },
          on: { cancel: _vm.handleCancel },
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { alt: "", src: _vm.previewImage },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }