<template>
    <a-modal width="1400px" v-model="show" :title="title" :footer="null" @cancel="close" :dialog-style="{ top: '0px' }">
        <a-form-model ref="ruleForm" :model="params" :rules="rules">
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="作业活动/项目名称" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="workName">
                <a-input v-model="params.workName" :disabled="isEdit!=0"  placeholder=""/>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="填报时间" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="reportTime">
                <a-date-picker v-model="params.reportTime" :disabled="isEdit!=0" valueFormat="YYYY-MM-DD" />
                
              </a-form-model-item>
            </a-col>

            <a-divider orientation="left">
                危险源辨识
            </a-divider>
            <a-col :span="8">
              <a-form-model-item label="危险源所在部位" :label-col="{ span: 9 }" :wrapper-col="{ span: 14 }" prop="riskResourseType">
                <a-select v-model="params.riskResourseType" :disabled="isEdit!=0" allowClear placeholder="请选择 " style="width: 240px" prop="riskResourseType">
                  <a-select-option v-for="item in options1" :key="item.value" :value="item.value" :title="item.label">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="风险特征描述" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="riskAttrType">
                <a-select v-model="params.riskAttrType" :disabled="isEdit!=0" allowClear placeholder="请选择 " style="width: 240px">
                  <a-select-option v-for="item in options2" :key="item.value" :value="item.value" :title="item.label">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="可能导致的事故类型"  :label-col="{ span: 8 }" :wrapper-col="{ span: 14 }" prop="accidentType">
                <a-select v-model="params.accidentType" :disabled="isEdit!=0" allowClear placeholder="请选择 " style="width: 240px">
                  <a-select-option v-for="item in options3" :key="item.value" :value="item.value" :title="item.label">
                    {{ item.label }}
                  </a-select-option>
                </a-select>                
              </a-form-model-item>
            </a-col>

            <a-divider orientation="left">
                风险分析
            </a-divider>
            <a-col :span="5">
              <a-form-model-item label=" L 值" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="riskLType">
                <a-select v-model="params.riskLType" allowClear placeholder="请选择 " style="width: 140px">
                  <a-select-option v-for="item in options4" :key="item.value" :value="item.value" :title="item.label">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="5">
              <a-form-model-item label=" E 值" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="riskEType">
                <a-select v-model="params.riskEType" allowClear placeholder="请选择 " style="width: 140px">
                  <a-select-option v-for="item in options5" :key="item.value" :value="item.value" :title="item.label">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="5">
              <a-form-model-item label=" C 值" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="riskCType">
                <a-select v-model="params.riskCType" allowClear placeholder="请选择 " style="width: 140px">
                  <a-select-option v-for="item in options6" :key="item.value" :value="item.value" :title="item.label">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="4">
              <a-form-model-item label=" D 值" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" >
                <a-input v-model="riskD" disabled placeholder="请输入 "/>
                
              </a-form-model-item>
            </a-col>
            <a-col :span="5">
              <a-form-model-item label="风险等级" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" >
                <a-select v-model="riskRankType" disabled allowClear placeholder="请选择 " style="width: 140px">
                  <a-select-option v-for="item in options7" :key="item.value" :value="item.value" :title="item.label">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            
            <a-divider orientation="left">
                风险控制措施
            </a-divider>

            <a-col :span="8">
              <a-form-model-item label="工程技术" :label-col="{ span: 4 }" :wrapper-col="{ span: 22 }" >
                <a-textarea v-model="params.engineeringTech" placeholder="" :rows="4" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="安全管理" :label-col="{ span: 4 }" :wrapper-col="{ span: 22 }" >
                <a-textarea v-model="params.safetyManage" placeholder="" :rows="4" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="培训内容" :label-col="{ span: 4 }" :wrapper-col="{ span: 22 }" >
                <a-textarea v-model="params.cultivateContent" placeholder="" :rows="4" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="个体防护" :label-col="{ span: 4 }" :wrapper-col="{ span: 22 }" >
                <a-textarea v-model="params.personProtect" placeholder="" :rows="4" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="应急处置" :label-col="{ span: 4 }" :wrapper-col="{ span: 22 }" >
                <a-textarea v-model="params.emergencyResponse" placeholder="" :rows="4" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="风险控制措施落实情况" :label-col="{ span: 9 }" :wrapper-col="{ span: 22 }" >
                <a-textarea v-model="params.riskControlState" placeholder="" :rows="4" />
              </a-form-model-item>
            </a-col>
 
            <!-- <div>
            <a-divider orientation="left">
                风险控制责任人
            </a-divider>
                <a-col :span="6">
                    <a-form-model-item label="项目级指定负责人" :label-col="{ span: 10 }" :wrapper-col="{ span: 14 }" >
                        <a-input v-model="params.projectAppointHead" placeholder=""  style="width: 140px"/>
                    </a-form-model-item>
                    <a-form-model-item label="电话" :label-col="{ span: 10 }" :wrapper-col="{ span: 14 }" prop="projectAppointHeadPhone">
                        <a-input v-model="params.projectAppointHeadPhone" placeholder=""  style="width: 140px"/>
                    </a-form-model-item>
                </a-col>
                <a-col :span="6">
                    <a-form-model-item label="项目级确认负责人" :label-col="{ span: 10 }" :wrapper-col="{ span: 14 }" >
                        <a-input v-model="params.projectConfirmHead" placeholder=""  style="width: 140px"/>
                    </a-form-model-item>
                    <a-form-model-item label="电话" :label-col="{ span: 10 }" :wrapper-col="{ span: 14 }" prop="projectConfirmHeadPhone">
                        <a-input v-model="params.projectConfirmHeadPhone" placeholder=""  style="width: 140px"/>
                    </a-form-model-item>
                </a-col>
                <a-col :span="6">
                    <a-form-model-item label="公司级指定负责人" :label-col="{ span: 10 }" :wrapper-col="{ span: 14 }" >
                        <a-input v-model="params.companyAppointHead" placeholder=""  style="width: 140px"/>
                    </a-form-model-item>
                    <a-form-model-item label="电话" :label-col="{ span: 10 }" :wrapper-col="{ span: 14 }" prop="companyAppointHeadPhone">
                        <a-input v-model="params.companyAppointHeadPhone" placeholder=""  style="width: 140px"/>
                    </a-form-model-item>
                </a-col>
                <a-col :span="6">
                    <a-form-model-item label="公司级确认负责人" :label-col="{ span: 10 }" :wrapper-col="{ span: 14 }" >
                        <a-input v-model="params.companyConfirmHead" placeholder=""  style="width: 140px"/>
                    </a-form-model-item>
                    <a-form-model-item label="电话" :label-col="{ span: 10 }" :wrapper-col="{ span: 14 }" prop="companyConfirmHeadPhone">
                        <a-input v-model="params.companyConfirmHeadPhone" placeholder=""  style="width: 140px"/>
                    </a-form-model-item>
                </a-col>
                
            </div> -->
  
          </a-row>
        </a-form-model>
        <a-row v-if="isEdit != 2">
          <a-col :span="12"></a-col>
          <a-col :span="12">
            <a-form-model-item :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }" prop="year">
              <div class="flex flex-row-reverse">
                <a-button type="primary" :loading="sbloading" @click="handleSubmit">保存</a-button>
                <a-button class="mr-2" @click="close">返回</a-button>
              </div>
            </a-form-model-item>
          </a-col>
        </a-row>
    </a-modal>
  </template>
  
  <script>
  
  import { request } from "@/utils/request";
  import { mapGetters } from 'vuex'
  import moment from "moment";
  import { riskcontroldetailAdd,riskcontroldetailUpdate, riskcontroldetailByID } from "@/services/riskControll";
  import { ATTACHMENT_UPLOAD } from "@/services/api";
  import gcglib from './mixinCl'
  
  export default {
    name: 'etDetail',
    computed: {
      ...mapGetters('account', ['user']),
      riskD() {
        return (this.params.riskLType * this.params.riskEType * this.params.riskCType).toFixed(2)
      },
      riskRankType() {
        // 一级	二级	三级	四级
        // [320，+∞）	[160，320）	[70,160)	[0,70)
        if (this.riskD >= 320) {
          return '1'
        } else if (this.riskD >= 160) {
          return '2'
        } else if (this.riskD >= 70) {
          return '3'
        } else {
          return '4'
        }
        // return this.params.riskRankType
      }
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        isEdit: {
            type: Number,
            default: 0
        },
        id: {
            default: ''
        },
        projectInfo: {
          default: () => {
            return {}
          }
        }
        
  
    },
    mixins: [gcglib],
    data() {
      return {
        // isEdit: 0,
        rules: {
          workName: [{ required: true, message: '请输入 ', trigger: 'change' }],
          reportTime: [{ required: true, message: '请选择 ', trigger: 'change' }],
          riskResourseType: [{ required: true, message: '请选择 ', trigger: 'change' }],
          riskAttrType: [{ required: true, message: '请选择 ', trigger: 'change' }],
          accidentType: [{ required: true, message: '请选择 ', trigger: 'change' }],
          riskLType: [{ required: true, message: '请选择 ', trigger: 'change' }],
          riskEType: [{ required: true, message: '请选择 ', trigger: 'change' }],
          riskCType: [{ required: true, message: '请选择 ', trigger: 'change' }],
          // 电话校验
          // projectAppointHeadPhone:[
          //   { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的电话', trigger: 'change' }
          // ],
          // projectConfirmHeadPhone:[
          //   { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的电话', trigger: 'change' }
          // ],
          // companyAppointHeadPhone:[
          //   { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的电话', trigger: 'change' }
          // ],
          // companyConfirmHeadPhone:[
          //   { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的电话', trigger: 'change' }
          // ],
        },
  
        params: {
            "parentId": 1,   //风险管控ID 所属哪条风险管控的明细 
            "workName":"作业活动名称",   //作业活动名称
            "reportTime":"2024-11-15",  //填报时间
            "riskResourseType":"1", //风险源所在部位ID
            "riskResourse":"风险源所在部位",  //风险源所在部位
            "riskAttrType":"5",  //风险特征描述ID
            "riskAttr":"风险特征描述",  //风险特征描述
            "accidentType":"1", //可能导致的事故类型ID
            "accident":"可能导致的事故类型",  //可能导致的事故类型
            "riskLType":"3",  //风险分析L值ID
            "riskL":0.9,  //风险分析L值
            "riskEType":"2",  //风险分析E值ID
            "riskE":0.5,  //风险分析E值
            "riskCType":"1",  //风险分析C值ID
            "riskC":1.2,  //风险分析C值
            "riskD":2.04,  //风险D值
            "riskRankType":"3",  //风险等级ID(1 一级 2 二级 3 三级 4 四级)
            "riskRank":"风险等级",  //风险等级
            "engineeringTech":"工程技术", //工程技术
            "safetyManage":"安全管理",  //安全管理
            "cultivateContent":"培训内容",  //培训内容
            "personProtect":"个人防护",  //个人防护
            "emergencyResponse":"应急处理",  //应急处理
            "riskControlState":"风险控制措施落实情况",  //风险控制措施落实情况
            "projectAppointHead":"项目级指定负责人",  //项目级指定负责人
            "projectAppointHeadPhone":"15945621235", //项目级指定负责人电话
            "projectConfirmHead":"项目级确认负责人",  //项目级确认负责人
            "projectConfirmHeadPhone":"15945621235",  //项目级确认负责人电话
            "companyAppointHead":"公司级指定负责人",  //公司级指定负责人
            "companyAppointHeadPhone":"15945621235",  //公司级指定负责人电话
            "companyConfirmHead":"公司级确认负责人",  //公司级确认负责人
            "companyConfirmHeadPhone":"15945621235", //公司级确认负责人电话
            "riskCoverState":1,                  //风险覆盖状态
            "reserved1":"321",
            "reserved2":"",
            "reserved3":null

        },
        sbloading: false,
        title: '新增',
        show: false,
  
      };
    },
    watch: {
      visible(val) {
        console.log(val)
        
        if (this.isEdit == 0) {
            // dom 渲染后
          
          this.params = {
                "parentId": null,   //风险管控ID 所属哪条风险管控的明细 
                "workName":this.projectInfo.projectName,   //作业活动名称
                "reportTime":this.projectInfo.reportTime,  //填报时间
                "riskResourseType":"", //风险源所在部位ID
                "riskResourse":"",  //风险源所在部位
                "riskAttrType":"",  //风险特征描述ID
                "riskAttr":"",  //风险特征描述
                "accidentType":"", //可能导致的事故类型ID
                "accident":"",  //可能导致的事故类型
                "riskLType":"",  //风险分析L值ID
                "riskL":null,  //风险分析L值
                "riskEType":"",  //风险分析E值ID
                "riskE":null,  //风险分析E值
                "riskCType":"",  //风险分析C值ID
                "riskC":null,  //风险分析C值
                "riskD":null,  //风险D值
                "riskRankType":"",  //风险等级ID(1 一级 2 二级 3 三级 4 四级)
                "riskRank":"",  //风险等级
                "engineeringTech":"", //工程技术
                "safetyManage":"",  //安全管理
                "cultivateContent":"",  //培训内容
                "personProtect":"",  //个人防护
                "emergencyResponse":"",  //应急处理
                "riskControlState":"",  //风险控制措施落实情况
                // "projectAppointHead":"",  //项目级指定负责人
                // "projectAppointHeadPhone":"", //项目级指定负责人电话
                // "projectConfirmHead":"",  //项目级确认负责人
                // "projectConfirmHeadPhone":"",  //项目级确认负责人电话
                // "companyAppointHead":"",  //公司级指定负责人
                // "companyAppointHeadPhone":"",  //公司级指定负责人电话
                // "companyConfirmHead":"",  //公司级确认负责人
                // "companyConfirmHeadPhone":"", //公司级确认负责人电话
                "riskCoverState":null,                  //风险覆盖状态
                "reserved1":"",
                "reserved2":"",
                "reserved3":null

            }
            this.params.parentId = this.$route.query.id
          // 新增 birthday 取 30年前
          // this.params.birthday = moment().subtract(30, 'years').format('YYYY-MM-DD');
          // this.params.age = 30;
          this.filePath1 = [];
          this.title = '新增 安全生产风险排查管控清单'
        } else {
            this.title = '编辑 安全生产风险排查管控清单'
            this.params.id = this.id;
            this.getDetail()
  
        }
        this.show = val;
      }
    },
    methods: {
      getDetail() {
        riskcontroldetailByID({id: this.params.id}).then(res => {
          if (res.data.code == 0) {
            this.params = res.data.data;
            
           
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
  
      handleSubmit() {
        this.sbloading = true
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            let d = JSON.parse(JSON.stringify(this.params));  
            d.riskResourse = this.getName(d.riskResourseType,this.options1)
            d.riskAttr = this.getName(d.riskAttrType,this.options2)
            d.accident = this.getName(d.accidentType,this.options3)
            d.riskRank = this.getName(d.riskRankType,this.options7)

            d.riskD = this.riskD
            d.riskRankType = this.riskRankType
            d.riskRank = this.getName(d.riskRankType,this.options7)
            d.riskC = d.riskCType
            d.riskE = d.riskEType
            d.riskL = d.riskLType

            
            if (this.isEdit == 0) {
              riskcontroldetailAdd(d).then(res => {
                if (res.data.code == 0) {
                  this.$message.success('提交成功！')
                  this.close();
                  this.$emit('refresh')
                } else {
                  this.$message.error(res.data.msg)
                }
                this.sbloading = false
              })
            } else {
              riskcontroldetailUpdate(d).then(res => {
                if (res.data.code == 0) {
                  this.$message.success('修改成功！')
                  this.close();
                  this.$emit('refresh')
                } else {
                  this.$message.error(res.data.msg)
                }
                this.sbloading = false
              })
            }
          } else {
            console.log('error submit!!');
            this.sbloading = false
            return false;
          }
        });
      },
      close() {
        this.resetForm()
        this.$emit('closeDialog')
      },
      resetForm() {
        this.$refs.ruleForm.resetFields();
        this.filePath1 = []
      },
      getName(key,list) {
        let item = list.find(item => item.value == key)
        return item ? item.label : ''
      }
      
    },
  };
  </script>
  
  <style lang="less" scoped>
  .a_Approval {
    background: #fff;
    border-radius: 10px;
  }
  
  .btn_group {
    padding-right: 190px;
  }
  </style>
  