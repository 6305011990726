var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.spinning, tip: _vm.loadingText } },
        [
          _vm.obj.isZongLaowu == 0
            ? _c("label", [
                _vm._v(
                  "温馨提示：项目参数设置暂只允许项目总包劳务员才能设置，如需更改设置，请联系总包劳务员更改设置，非常感谢！"
                ),
              ])
            : _vm._e(),
          _c(
            "a-form",
            {
              attrs: { form: _vm.form, layout: "vertical" },
              on: { submit: _vm.doSave },
            },
            [
              !_vm.isYYZY
                ? _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: "三级安全教育手动发起",
                        labelCol: { span: 3 },
                        wrapperCol: { span: 3, offset: 1 },
                      },
                    },
                    [
                      _c("a-switch", {
                        attrs: { disabled: _vm.obj.isZongLaowu == 0 },
                        model: {
                          value: _vm.obj.params.sjaqjy,
                          callback: function ($$v) {
                            _vm.$set(_vm.obj.params, "sjaqjy", $$v)
                          },
                          expression: "obj.params.sjaqjy",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isYYZY
                ? _c(
                    "a-form-item",
                    {
                      staticStyle: { "margin-top": "-20px" },
                      attrs: {
                        label: "是否启用三级安全教育视频学习",
                        labelCol: { span: 4 },
                        wrapperCol: { span: 4, offset: 1 },
                      },
                    },
                    [
                      _c("a-switch", {
                        attrs: { disabled: _vm.obj.isZongLaowu == 0 },
                        model: {
                          value: _vm.obj.params.isOpenVideo,
                          callback: function ($$v) {
                            _vm.$set(_vm.obj.params, "isOpenVideo", $$v)
                          },
                          expression: "obj.params.isOpenVideo",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-card",
                { attrs: { title: "通用三级安全教育课程设置：" } },
                [
                  _c(
                    "a-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 7 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: "一级教育课程",
                                labelCol: { span: 7 },
                                wrapperCol: { span: 12, offset: 1 },
                              },
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  attrs: {
                                    "show-search": "",
                                    placeholder: "请输入或选择课程",
                                    "option-filter-prop": "children",
                                    "filter-option": _vm.filterOption,
                                  },
                                  on: { change: _vm.handleChange },
                                  model: {
                                    value: _vm.obj.params.eduSet.oneEduCourseId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.obj.params.eduSet,
                                        "oneEduCourseId",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "obj.params.eduSet.oneEduCourseId",
                                  },
                                },
                                _vm._l(_vm.courseData, function (item) {
                                  return _c(
                                    "a-select-option",
                                    { key: item.id, attrs: { value: item.id } },
                                    [_vm._v(" " + _vm._s(item.name) + " ")]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 7 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: "二级教育课程",
                                labelCol: { span: 7 },
                                wrapperCol: { span: 12, offset: 1 },
                              },
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  attrs: {
                                    "show-search": "",
                                    placeholder: "请输入或选择课程",
                                    "option-filter-prop": "children",
                                    "filter-option": _vm.filterOption,
                                  },
                                  on: { change: _vm.handleChange },
                                  model: {
                                    value: _vm.obj.params.eduSet.twoEduCourseId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.obj.params.eduSet,
                                        "twoEduCourseId",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "obj.params.eduSet.twoEduCourseId",
                                  },
                                },
                                _vm._l(_vm.courseData, function (item) {
                                  return _c(
                                    "a-select-option",
                                    { key: item.id, attrs: { value: item.id } },
                                    [_vm._v(" " + _vm._s(item.name) + " ")]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 7 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: "三级教育课程",
                                labelCol: { span: 7 },
                                wrapperCol: { span: 12, offset: 1 },
                              },
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  attrs: {
                                    "show-search": "",
                                    placeholder: "请输入或选择课程",
                                    "option-filter-prop": "children",
                                    "filter-option": _vm.filterOption,
                                  },
                                  on: { change: _vm.handleChange },
                                  model: {
                                    value:
                                      _vm.obj.params.eduSet.threeEduCourseId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.obj.params.eduSet,
                                        "threeEduCourseId",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "obj.params.eduSet.threeEduCourseId",
                                  },
                                },
                                _vm._l(_vm.courseData, function (item) {
                                  return _c(
                                    "a-select-option",
                                    { key: item.id, attrs: { value: item.id } },
                                    [_vm._v(" " + _vm._s(item.name) + " ")]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-card",
                { attrs: { title: "工种三级安全教育课程设置：" } },
                _vm._l(_vm.obj.params.jobTitleSet, function (courseSet, index) {
                  return _c(
                    "a-row",
                    { key: index, attrs: { gutter: 24 } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 5 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: "工种",
                                labelCol: { span: 4 },
                                wrapperCol: { span: 14, offset: 1 },
                              },
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  attrs: {
                                    mode: "multiple",
                                    "show-search": "",
                                    placeholder: "请输入或选择工种",
                                    "option-filter-prop": "children",
                                    "filter-option": _vm.filterOption,
                                  },
                                  on: { change: _vm.handleChange },
                                  model: {
                                    value: courseSet.titleId,
                                    callback: function ($$v) {
                                      _vm.$set(courseSet, "titleId", $$v)
                                    },
                                    expression: "courseSet.titleId",
                                  },
                                },
                                _vm._l(_vm.jobTitles, function (item) {
                                  return _c(
                                    "a-select-option",
                                    {
                                      key: item.id,
                                      attrs: {
                                        value: item.id,
                                        title: item.carft,
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(item.carft) + " ")]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 5 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: "一级教育课程",
                                labelCol: { span: 8 },
                                wrapperCol: { span: 15, offset: 1 },
                              },
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  attrs: {
                                    "show-search": "",
                                    placeholder: "请输入或选择课程",
                                    "option-filter-prop": "children",
                                    "filter-option": _vm.filterOption,
                                  },
                                  on: { change: _vm.handleChange },
                                  model: {
                                    value: courseSet.oneEduCourseId,
                                    callback: function ($$v) {
                                      _vm.$set(courseSet, "oneEduCourseId", $$v)
                                    },
                                    expression: "courseSet.oneEduCourseId",
                                  },
                                },
                                _vm._l(_vm.courseData, function (item) {
                                  return _c(
                                    "a-select-option",
                                    {
                                      key: item.id,
                                      attrs: {
                                        value: item.id,
                                        title: item.name,
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(item.name) + " ")]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 5 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: "二级教育课程",
                                labelCol: { span: 8 },
                                wrapperCol: { span: 15, offset: 1 },
                              },
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  attrs: {
                                    "show-search": "",
                                    placeholder: "请输入或选择课程",
                                    "option-filter-prop": "children",
                                    "filter-option": _vm.filterOption,
                                  },
                                  on: { change: _vm.handleChange },
                                  model: {
                                    value: courseSet.twoEduCourseId,
                                    callback: function ($$v) {
                                      _vm.$set(courseSet, "twoEduCourseId", $$v)
                                    },
                                    expression: "courseSet.twoEduCourseId",
                                  },
                                },
                                _vm._l(_vm.courseData, function (item) {
                                  return _c(
                                    "a-select-option",
                                    {
                                      key: item.id,
                                      attrs: {
                                        value: item.id,
                                        title: item.name,
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(item.name) + " ")]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 5 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: "三级教育课程",
                                labelCol: { span: 8 },
                                wrapperCol: { span: 15, offset: 1 },
                              },
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  attrs: {
                                    "show-search": "",
                                    placeholder: "请输入或选择课程",
                                    "option-filter-prop": "children",
                                    "filter-option": _vm.filterOption,
                                  },
                                  on: { change: _vm.handleChange },
                                  model: {
                                    value: courseSet.threeEduCourseId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        courseSet,
                                        "threeEduCourseId",
                                        $$v
                                      )
                                    },
                                    expression: "courseSet.threeEduCourseId",
                                  },
                                },
                                _vm._l(_vm.courseData, function (item) {
                                  return _c(
                                    "a-select-option",
                                    {
                                      key: item.id,
                                      attrs: {
                                        value: item.id,
                                        title: item.name,
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(item.name) + " ")]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "a-form-item",
                            [
                              _c("a-icon", {
                                staticClass: "imdexStyle",
                                attrs: { type: "plus-circle" },
                                on: { click: _vm.addTitleSet },
                              }),
                              _c("a-icon", {
                                staticClass: "imdexStyle",
                                attrs: { type: "minus-circle" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeTitleSet(index)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
              _c(
                "a-form-item",
                { attrs: { "wrapper-col": { span: 12, offset: 5 } } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        icon: "save",
                        type: "primary",
                        disabled: _vm.obj.isZongLaowu == 0 && !_vm.isYYZY,
                        "html-type": "submit",
                      },
                    },
                    [_vm._v(" 确认设置 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }