<template>
  <div class="a_Approval">
    <div class="plug-Overview" style="display: flex; justify-content: space-between;">
      {{  DetailPageModeNameMap[pageMode] }}标准化考评
      <a-space>
        <a-button @click="onClose">关闭</a-button>
        <a-button v-if="isEdit || isAdd" type="primary" @click="onSave">保存</a-button>
      </a-space>
    </div>
    <a-divider />
    <!-- 表单 -->
    <form-index
      ref="formindexRef"
      :formData="formConfig"
      @changeYear="changeYear"
      @handleChange="handleChange"
      @handlSelect="handlSelect"
    >
    </form-index>
  </div>
    
</template>

<script>
import moment from 'moment'
import { debounce, pick } from 'lodash'
import formIndex from "@/pages/components/form/index";
import { request } from "@/utils/request";
import { QUERY_OFFICE_BY_USER } from "@/services/api";
import { assessmentCreate, assessmentUpdate, assessmentDetail } from '@/services/safeManage.js'
import { getProjectByOffice } from "@/services/statistics";
import { DetailPageModeNameMap, DetailPageModeEnum } from '@/enums/pageEnum.js'

const formConfig = [
  {
    label: "所属分公司",
    placeholder: "请选择所属分公司",
    decorator: [
      'childOfficeId',
      {
        rules: [{ required: true, message: "请选择所属分公司" }],
      }
    ],
    type: "select",
    key: "childOfficeId",
    selectlist: [],
    col: 12,
    labelCol: 3,
    wrapperCol: 14,
  },
  {
    label: "项目名称",
    placeholder: "请选择项目",
    decorator: [
      'projectId',
      {
        rules: [{ required: true, message: "请选择项目" }],
      }
    ],
    type: "inputGroup",
    dataSource: [],
    key: "projectId",
    col: 12,
    labelCol: 3,
    wrapperCol: 14,
  },
  {
    label: "年份",
    placeholder: "请选择年份",
    decorator: [
      "standYear",
      {
        initialValue: moment(new Date()).format("YYYY"),
        rules: [{ required: true, message: "请选择年份" }],
      },
    ],
    type: "yearPicker",
    key: "standYear",
    col: 12,
    labelCol: 3,
    wrapperCol: 14,
    styles: { width: "100%" },
  },
  {
    label: "季度",
    placeholder: "请选择季度",
    decorator: [
      'standQuarter',
      {
        rules: [{ required: true, message: "请选择季度" }],
      }
    ],
    type: "select",
    key: "standQuarter",
    selectlist: [
      { name: '第一季度', value: 1 },
      { name: '第二季度', value: 2 },
      { name: '第三季度', value: 3 },
      { name: '第四季度', value: 4 },
    ],
    col: 12,
    labelCol: 3,
    wrapperCol: 14,
  },
  {
    label: "名次",
    placeholder: "请输入名次",
    decorator: ['standRank'],
    type: "inputNumber",
    key: "standRank",
    col: 12,
    labelCol: 3,
    wrapperCol: 14,
    style: { width: '100%' }
  },
];

export default {
  props: ["pageMode", "id"],
  components: { formIndex },
  data() {
    return {
      formConfig,
      DetailPageModeNameMap,
      isAdd: this.pageMode === DetailPageModeEnum.ADD,
      isEdit: this.pageMode === DetailPageModeEnum.EDIT,
      isView: this.pageMode === DetailPageModeEnum.VIEW
    };
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    async onSave() {
      const validate = await this.$refs.formindexRef.validateFields();
      if (!validate) return

      const formModel = this.$refs.formindexRef.getFieldsValue();
      const projectIndex = this.findIndex(this.formConfig, 'projectId')
      const projectName = this.formConfig[projectIndex].dataSource.find(item => item.id == formModel.projectId).title

      const childOfficeIndex = this.findIndex(this.formConfig, 'childOfficeId')
      const params = {
        ...formModel,
        childOfficeName: this.formConfig[childOfficeIndex].selectlist.find(i => formModel.childOfficeId === i.value).name,
        projectName,
        ...(this.isEdit && { id: this.id })
      }
      const api = this.isEdit ? assessmentUpdate : assessmentCreate;
      try {
        const res = await api(params);
        if (res.data.code === 0) {
          this.$emit("save");
        }
      } catch (error) {
        console.log(error);
      }
      
    },
    changeYear(date) {
      this.$refs.formindexRef.queryObj({
        standYear: date.format('YYYY'),
      })
    },
    getChildOfficeId() {
      return this.$refs.formindexRef.getFieldValueByKey('childOfficeId')
    },
    // 获取项目列表
    async getProjectList(projectName, childOfficeId) {
      const res = await getProjectByOffice({ gcglbId: childOfficeId || this.getChildOfficeId(), ...(projectName && { projectName }) })
      const projectIndex = this.findIndex(this.formConfig, 'projectId')
      this.formConfig[projectIndex].dataSource = res.data.data.map((item) => ({ title: item.projectName, id: item.projectId }))
    },
    // 输入项目名称，进行项目数据筛选(实际是表单组件的search事件触发的)
    handleChange: debounce(async function (e) {
      const childOfficeId = this.getChildOfficeId()
      // 不是输入项目名称，直接返回
      if (e.item.key !== 'projectId') return
      // 未选择分公司，清空输入框，直接返回
      if (!childOfficeId) {
        this.$refs.formindexRef.setFieldsValue('', { projectId: '' }, 'projectId')
      }

      this.getProjectList(e.value)
    }, 300),
    async handlSelect({ val, keys }) {
      // 不是选择分公司，直接返回
      if (keys !== 'childOfficeId') return

      const projectIndex = this.findIndex(this.formConfig, 'projectId')
      // 分公司值清空，也把项目清空
      if (!val) {
        this.formConfig[projectIndex].dataSource = []
        this.$refs.formindexRef.setFieldsValue('', { projectId: '' }, 'projectId')
      } else {
        // 根据分公司，获取项目列表
        this.getProjectList('', val)
      }
    },
    findIndex(arr, key) {
      return arr.findIndex(item => item.key === key)
    },
    async echoData() {
      if (!this.id) return
      const res = await assessmentDetail(this.id)
      const pickKeys = this.formConfig.map(item => item.key)
      this.$refs.formindexRef.setFieldsValue('', pick(res.data.data, pickKeys))
      // 根据分公司，获取项目列表
      this.getProjectList()
    }
  },
  mounted() {
    // 分公司下拉数据等
    request(QUERY_OFFICE_BY_USER, "get").then((res) => {
      const childOfficeIndex = this.findIndex(this.formConfig, 'childOfficeId')
      this.formConfig[childOfficeIndex].selectlist = res.data.data.map((item) => ({
        name: item.name,
        value: item.id,
      }));
    });

    // 当前是查看详情，禁用所有表单组件
    if (this.isView) {
      this.formConfig.forEach(item => {
        item.disabled = true
      })
    }

    // 回显
    this.echoData()
  },
};
</script>