import { ADMIN, SYSUSER_PROID_OFFICE,MDPOFFICE_SAVE_OFFICES, MDPOFFICE_PROJECT_ALLOFFICES,MDPOFFICE_CHECK_OFFICECODE,MDPOFFICE_DETAIL_OFFICES,
  FIND_TEAMS_BY_USER,TEAMINFO_SAVE,TEAMINFO_FIND_BYID,TEAMPOINT_LIST, QUERY_PROJECT_STAFF_LIST, QUERY_JOB_BY_WORKER_TYPE,
  SAVE_STAFF_INFO, QUERY_ID_CARD_DATA, UPLOAD_IMAGE,TEAM_OFFICE_POINT_LIST,TEAM_OFFICE_POINT_INSERT,TEAM_OFFICE_POINT_UPDATE,
  SYS_USER_SAVE_MAGE_USERS,GET_SYS_USER_DETAILE,GET_GROUP_OFFICE, BATCH_SYNC_PERSON_TO_ATTEND_PLATFORM,
  GET_TASK_PROGRESS,ADD_GROUP_OFFICE,UPDATE_GROUP_OFFICE,GET_GROUP_TEAM, BATCH_SYNC_PERSON_AUTH_TO_ATTEND_PLATFORM, BATCH_SYNC_PERSON_AUTH_TO_DEVICE,
  SYNC_MACHINE_TO_HK, GET_HK_TASK_PROGRESS, MDP_OFFICE_DEL, EXIT_STAFF, GET_TEAMS_BY_USER,DELETE_TEAMS_BY_USER,MDP_OFFICE_USER_DEL, RE_ENTER_STAFF,
  SET_JW_REPORT_STATUS, REPORT_JW_STAFF, REPORT_JW_EXIT_STAFF, GET_APPROVE_PERSON_INFO, STAFF_INFO, GET_CERTIFICATE_TYPE_LIST, GET_CERTIFICATE_MANAGEMENT_LIST,
  ADD_NEW_EVENT_RECORD, GET_EVENT_RECORD_LIST, DEL_EVENT_RECORD, UPDATE_EVENT_RECORD, BATCH_DEL_DEVICE_AUTH, BATCH_DEL_PERSON_INFO, BATCH_SYNC_PERSON_TO_PLATFORM,
  GET_DISTRIBUTE_PERSON_ERR_LIST, GET_MANAGER_TASH, BATCH_SYNC_STAFF_TO_PLATFORM, GET_STAFF_TASH,GET_STAFF_FK_LIST, GET_BANK, POLITICAL_OUTLOOK,
   SELECT_STAFF_INFO_BY_ID_CARD,GET_BANK_CARD,GET_QR_CODE_LIST, EVENT_RECORD_DETAIL, GET_BACK_ATTE_RECORD, CONTRACT_LIST, CONTRACT_SAVE, CONTRACT_DEL,
   BACK_ATTE_RE_RECORD,SYSUSER_PROID_USER, BATCH_DEL_PERSON_TO_PLATFORM, STAFF_LEAVE_DOSEND,QUERY_STAFF_HK_FACE,QUERY_JOB_FIND_WORKER_TYPE,QUERY_MANGE_HK_FACE,QUERY_USER_CHECK_PHONE,
   QUERY_USER_CHECK_ID_CARD,SET_MANGE_USER_LEAVE,MANAGE_BATCH_SYNC_PERSON_TO_ATTEND_PLATFORM,GET_MANGE_TASK_PROGRESS,MANAGE_BATCH_SYNC_PERSON_AUTH_TO_ATTEND_PLATFORM,GET_MANAGE_HK_TASK_PROGRESS,
   MANAGE_BATCH_SYNC_PERSON_AUTH_TO_DEVICE,DEVICE_BATCH_SYNC_PERSON_AUTH_TO_CODE,GET_DEVICE_HK_TASK_PROGRESS,DEL_MANAGE_BATCH_DEVICE,DEL_MANAGE_BATCH_INFO, CLEAR_FAIL_PERSON, APPROVE_STAFF} from '@/services/api'
import {request, METHOD} from '@/utils/request'
// const BASE_URL = process.env.VUE_APP_API_BASE_URL

/**
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function delContract(params) {
  return request(CONTRACT_DEL + `/${params}`, METHOD.DELETE)
}

/**
 *  总包审批工人
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function approveStaff(params) {
  return request(APPROVE_STAFF, METHOD.POST, params)
}

/**
 *  清理失败人员
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function clearFailRecord(params) {
  return request(CLEAR_FAIL_PERSON, METHOD.POST, params)
}


/**
 *  合同密钥的新增，修改保存接口
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function saveContract(params) {
  return request(CONTRACT_SAVE, METHOD.POST, params)
}

/**
 *  系统设置中的分页查询列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getContractList(params) {
  return request(CONTRACT_LIST, METHOD.POST, params)
}

/**
 *  获取备用考勤记录列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getBackAtteRecord(params) {
  return request(GET_BACK_ATTE_RECORD, METHOD.POST, params)
}

/**
 *  获取备用考勤记录列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getBackAtteReRecord(params) {
  return request(BACK_ATTE_RE_RECORD, METHOD.POST, params)
}

/**
 *  查询银行
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getBank(params) {
  return request(GET_BANK, METHOD.POST, params)
}

/**
 *  银行卡识别
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getBankCrad(params) {
  return request(GET_BANK_CARD, METHOD.POST, params)
}

/**
 *  政治面貌
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getPoliticalOutlook(params) {
  return request(POLITICAL_OUTLOOK, METHOD.GET, params)
}

/**
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function selectStaffInfoByIdCard(params) {
  return request(SELECT_STAFF_INFO_BY_ID_CARD, METHOD.GET, params)
}

/**
 *  同步闸机到hk平台
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function syncMachineToHk(params) {
  return request(SYNC_MACHINE_TO_HK + `?deviceCode=${params.deviceCode}`, METHOD.POST)
}

/**
 *  批量下发管理人员到考勤厂商平台
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function batchSyncPersonToPlatform(params) {
  return request(BATCH_SYNC_PERSON_TO_PLATFORM + `?threadId=${params.threadId}`, METHOD.POST, params.userIdList)
}

/**
 *  批量删除管理人员权限
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function batchDelPersonToPlatform(params) {
  return request(BATCH_DEL_PERSON_TO_PLATFORM + `?threadId=${params.threadId}`, METHOD.POST, params.userIdList)
}

/**
 *  批量下发工人人员到考勤厂商平台
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function batchSyncStaffToPlatform(params) {
  return request(BATCH_SYNC_STAFF_TO_PLATFORM + `?threadId=${params.threadId}`, METHOD.POST, params.workNumList)
}

/**
 *  查询下发考勤机任务数及明细
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getManagerTask(params) {
  return request(GET_MANAGER_TASH, METHOD.POST, params)
}

/**
 *  查询工人下发考勤机任务数及明细
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getStaffTask(params) {
  return request(GET_STAFF_TASH, METHOD.POST, params)
}

/**
 *  管理人员下发失败记录
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getDistributePersonErrList(params) {
  return request(GET_DISTRIBUTE_PERSON_ERR_LIST, METHOD.POST, params)
}

/**
 *  修改事件记录
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function updataEventRecord(params) {
  return request(UPDATE_EVENT_RECORD, METHOD.POST, params)
}

/**
 *  事件记录详情
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function eventRecordDetail(id) {
  return request(EVENT_RECORD_DETAIL + `/${id}`, METHOD.GET)
}

/**
 *  新增事件记录
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function addNewEventRecord(params) {
  return request(ADD_NEW_EVENT_RECORD, METHOD.POST, params)
}

/**
 *  删除事件记录
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function delEventRecord(params) {
  return request(DEL_EVENT_RECORD + params, METHOD.DELETE)
}

/**
 *  查询事件记录列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getEventRecordList(params) {
  return request(GET_EVENT_RECORD_LIST, METHOD.POST, params)
}

/**
 *  查询编辑个人信息
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function staffInfo(params) {
  return request(STAFF_INFO, METHOD.POST, params)
}

/**
 *  查询证书管理列表(分页)
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getCertificateList(params) {
  return request(GET_CERTIFICATE_MANAGEMENT_LIST, METHOD.POST, params)
}

/**
 *  获取证书类型列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getCertificateTypeList(params) {
  return request(GET_CERTIFICATE_TYPE_LIST, METHOD.POST, params)
}

/**
 *  获取待审核人员信息
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getApprovePersonInfo(params) {
  return request(GET_APPROVE_PERSON_INFO, METHOD.GET, params)
}

/**
 *  设置是否上报建委状态
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function setJwReportStatus(params, query) {
  return request(SET_JW_REPORT_STATUS + `?jwReportStatus=${query}`, METHOD.POST, params)
}

/**
 *  上报建委
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function reportJwStaff(params) {
  return request(REPORT_JW_STAFF, METHOD.POST, params)
}

/**
 *  上报建委离场
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function reportJwExitStaff(params) {
  return request(REPORT_JW_EXIT_STAFF, METHOD.POST, params)
}

/**
 *  重新进场
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function enterStaff(params) {
  return request(RE_ENTER_STAFF, METHOD.POST, params)
}

/**
 *  批量退场
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function exitStaff(params) {
  return request(EXIT_STAFF , METHOD.POST, params)
}

/**
 *  查询HK任务执行进度
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getHKTaskProgress(params) {
  console.log("params", params)
  return request(GET_HK_TASK_PROGRESS, METHOD.GET, params)
}

/**
 *  保存参见单位
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function mdpSaveOffices(params) {
  return request(MDPOFFICE_SAVE_OFFICES, METHOD.POST, params)
}

/**
 *  根据项目ID 查询项目所有的参见单位
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getProAllOfficeList(params) {
  return request(MDPOFFICE_PROJECT_ALLOFFICES, METHOD.POST,params)
}

/**
 *  根据根据条件查询公司
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getCheckOfficeList(params) {
  return request(MDPOFFICE_CHECK_OFFICECODE, METHOD.POST,params)
}

/**
 *  在场-退场人员列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getProjectStaffList(params) {
  return request(QUERY_PROJECT_STAFF_LIST, METHOD.POST, params)
}

/**
 *  在场-退场人员列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getJobByWorkerType(params) {
  return request(QUERY_JOB_BY_WORKER_TYPE, METHOD.POST, params)
}

/**
 *  批量下发人员到考勤厂商平台
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function batchSyncPersonToAttendPlatform(params) {
  console.log("params", params)
  return request(BATCH_SYNC_PERSON_TO_ATTEND_PLATFORM + `?threadId=${params.threadId}`, METHOD.POST, params.workNumList)
}

/**
 *  批量下发人员权限到考勤厂商平台
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function batchSyncPersonAuthToAttendPlatform(params) {
  console.log("params", params)
  return request(BATCH_SYNC_PERSON_AUTH_TO_ATTEND_PLATFORM + `?threadId=${params.threadId}`, METHOD.POST, params.workNumList)
}

/**
 *  批量删除设备权限
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function batchDelDeviceAuth(params) {
  console.log("params", params)
  return request(BATCH_DEL_DEVICE_AUTH + `?threadId=${params.threadId}`, METHOD.POST, params.workNumList)
}

/**
 *  批量删除人员信息
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function batchDelPersonInfo(params) {
  console.log("params", params)
  return request(BATCH_DEL_PERSON_INFO + `?threadId=${params.threadId}`, METHOD.POST, params.workNumList)
}

/**
 *  批量同步人员到考勤机（目前海康需要这一步）
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function batchSyncPersonAuthToDevice(params) {
  console.log("params", params)
  return request(BATCH_SYNC_PERSON_AUTH_TO_DEVICE, METHOD.POST, params.workNumList)
}

/**
 *  查询任务执行进度
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getTaskProgress(params) {
  console.log("params", params)
  return request(GET_TASK_PROGRESS, METHOD.GET, params)
}


/**
 *  保存编辑个人信息
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function saveStaffInfo(params) {
  return request(SAVE_STAFF_INFO, METHOD.POST, params)
}

/**
 *  获取身份证信息
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getIdCardInfo(params, type) {
  return request(QUERY_ID_CARD_DATA + `?type=${type}`, METHOD.POST, params)
}

/**
 *  上传图片
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function uploadImage(params) {
  return request(UPLOAD_IMAGE, METHOD.POST, params)
}


/**
 *  根据ID 查询查询参建单位详情详情
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getDetailOffice(params) {
  return request(MDPOFFICE_DETAIL_OFFICES + `/${params}`, METHOD.GET)
}

/**
 *  查询项目的班组列表 根据查询所有班组
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getAllTeams(params) {
  return request(FIND_TEAMS_BY_USER, METHOD.POST,params)
}

/**
 *  查询项目的班组列表 根据查询所有班组
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function saveTeamInfo(params) {
  return request(TEAMINFO_SAVE, METHOD.POST,params)
}

/**
 *  根据ID查询班组信息
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getTeamDetail(params) {
  return request(TEAMINFO_FIND_BYID + `/${params}`, METHOD.GET)
}

/**
 *  根据ID查询班组信息
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getTeamPoint(params) {
  return request(TEAMPOINT_LIST, METHOD.POST, params)
}

/**
 *  根据项目和公司查询用户列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getSysProOffice(params) {
  return request(SYSUSER_PROID_OFFICE, METHOD.POST, params)
}

/**
 *  查询参建班组评分列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getOfficePointList(params) {
  return request(TEAM_OFFICE_POINT_LIST, METHOD.POST, params)
}

/**
 *  查询参建班组评分列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function addOfficePoint(params) {
  return request(TEAM_OFFICE_POINT_INSERT, METHOD.POST, params)
}

/**
 *  查询参建班组评分列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function editOfficePoint(params) {
  return request(TEAM_OFFICE_POINT_UPDATE, METHOD.POST, params)
}

/**
 *  保存管理人员
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function saveOfficeMageUser(params) {
  return request(SYS_USER_SAVE_MAGE_USERS, METHOD.POST, params)
}

/**
 *  查询项目和用户ID查看用户详情
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getMageUserDetail(params) {
  return request(GET_SYS_USER_DETAILE + `/${params.userId}/${params.projectId}`, METHOD.GET)
}

/**
 *  根据公司ID查询设置的门禁通道
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getGroupOfficeAccControl(params) {
  return request(GET_GROUP_OFFICE + `/${params.officeId}/${params.projectId}`, METHOD.GET)
}

/**
 *  勾选分组新增
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function addGroupOfficeAccControl(params) {
  return request(ADD_GROUP_OFFICE, METHOD.POST,params)
}

/**
 *  删除分组
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function updateGroupOfficeAccControl(params) {
  return request(UPDATE_GROUP_OFFICE, METHOD.POST,params)
}

/**
 *  根据班组ID查询设置的门禁通道
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getGroupTeamAccControl(params) {
  return request(GET_GROUP_TEAM + `/${params}`, METHOD.GET)
}

/**
 *  删除参建单位
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function delOffice(params) {
  return request(MDP_OFFICE_DEL + `/${params.projectId}/${params.officeId}`, METHOD.POST)
}

/**
 *  查询项目的班组列表 根据查询所有班组
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getTeams(params) {
  return request(GET_TEAMS_BY_USER, METHOD.POST,params)
}

/**
 *  删除参建班组
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function delTeams(params) {
  return request(DELETE_TEAMS_BY_USER + `/${params.teamId}/${params.projectId}`, METHOD.DELETE)
}

/**
 *  删除项目用户
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function delProOffUser(params) {
  return request(MDP_OFFICE_USER_DEL + `/${params.projectId}/${params.userId}`, METHOD.DELETE)
}

/**
 *  风控人员管理
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getStaffFkList(params) {
  return request(GET_STAFF_FK_LIST, METHOD.POST, params)
}

/**
 *  批量获取列表班组二维码
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getQrCodeList(params) {
  return request(GET_QR_CODE_LIST, METHOD.POST, params)
}

/**
 *  管理人员列表查询
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getSysProManegeList(params) {
  return request(SYSUSER_PROID_USER, METHOD.POST, params)
}


/**
 *  发起预退场功能
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function setStaffSendLeave(params) {
  return request(STAFF_LEAVE_DOSEND, METHOD.POST, params)
}

/**
 *  人员列表-海康评分
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getStaffHkFace(params) {
  return request(QUERY_STAFF_HK_FACE, METHOD.POST, params)
}

/**
 *  根据条件获取工人的工种列表
 * @params category 类别3
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getJobFindWorkerType(params) {
  return request(QUERY_JOB_FIND_WORKER_TYPE, METHOD.POST, params)
}

/**
 *  管理人员-海康评分
 *  @params workNumList
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getMangeHkFace(params) {
  return request(QUERY_MANGE_HK_FACE, METHOD.POST, params)
}

/**
 *  管理人员详情-校验手机号
 *  @params Phone
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getCheckPhone(params) {
  return request(QUERY_USER_CHECK_PHONE + `/${params.phone}/${params.projectId}`, METHOD.GET)
}

/**
 *  管理人员详情-校验身份证号
 *  @params IdCard
 *  @params ProjectId
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getCheckIdCard(params) {
  return request(QUERY_USER_CHECK_ID_CARD + `/${params.projectId}/${params.IdCard}`, METHOD.GET)
}

/**
 *  管理人员列表-退场功能
 *  @params userId
 *  @params projectId
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function setMangeUserLeave(params) {
  return request(SET_MANGE_USER_LEAVE + `/${params.userId}/${params.projectId}`, METHOD.POST)
}

/**
 *  管理人员批量下发人员到考勤厂商平台
 *  @params threadId
 *  @params userIdList
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function manageBatchSyncPersonToAttendPlatform(params) {
  return request(MANAGE_BATCH_SYNC_PERSON_TO_ATTEND_PLATFORM + `?threadId=${params.threadId}`, METHOD.POST,params.userIdList)
}

/**
 *  查询管理人员-任务执行进度
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getMangeTaskProgress(params) {
  return request(GET_MANGE_TASK_PROGRESS, METHOD.GET, params)
}

/**
 *  批量下发人员权限到考勤厂商平台
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function manageBatchSyncPersonAuthToAttendPlatform(params) {
  return request(MANAGE_BATCH_SYNC_PERSON_AUTH_TO_ATTEND_PLATFORM + `?threadId=${params.threadId}`, METHOD.POST, params.userIdList)
}

/**
 *  查询管理人员-HK任务执行进度
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getMangeHKTaskProgress(params) {
  return request(GET_MANAGE_HK_TASK_PROGRESS, METHOD.GET, params)
}

/**
 *  批量同步人员到考勤机（目前海康需要这一步）
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function mangeBatchSyncPersonAuthToDevice(params) {
  return request(MANAGE_BATCH_SYNC_PERSON_AUTH_TO_DEVICE, METHOD.POST, params.userIdList)
}

/**
 *  批量同步人员到考勤机（目前海康需要这一步）
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function codeBatchSyncPersonAuthToDevice(params) {
  return request(DEVICE_BATCH_SYNC_PERSON_AUTH_TO_CODE, METHOD.POST, params.deviceCodeList)
}

/**
 *  查询考勤设备设置-HK任务执行进度
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getDeviceHKTaskProgress(params) {
  return request(GET_DEVICE_HK_TASK_PROGRESS, METHOD.GET, params)
}

/**
 *  批量删除设备权限
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function mangeBatchDelDeviceAuth(params) {
  return request(DEL_MANAGE_BATCH_DEVICE + `?threadId=${params.threadId}`, METHOD.POST, params.userIdList)
}

/**
 *  批量删除人员信息
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function mangeBatchDelPersonInfo(params) {
  return request(DEL_MANAGE_BATCH_INFO + `?threadId=${params.threadId}`, METHOD.POST, params.userIdList)
}

/**
 * 获取公司的工人库
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getOfficeStaffs(params) {
  return request(ADMIN + `/laowu/team/getOfficeStaffs`, METHOD.POST, params)
}

/**
 * 查询工人证书列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getstaffCertMyList(params) {
  return request(ADMIN + `/laowu/staffCertication/getMyList/${params.workNum}`, METHOD.POST, params)
}

/**
 * 查询工人项目履历
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getstaffProjList(params) {
  return request(ADMIN + `/labour/teamStaffRecord/getWorkProjList`, METHOD.POST, params)
}

/**
 * 获取班组/单位绑定的设备列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getBindDeviceList(params) {
  return request(ADMIN + `/dmgr/bindDevice/getList`, METHOD.POST, params)
}

/**
 * 单位、班组绑定/取消绑定设备接口：
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getBindDeviceInsert(params) {
  return request(ADMIN + `/dmgr/bindDevice/insert`, METHOD.POST, params)
}

/**
 * 修改审批人员信息
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function setApprovePersonInfo(params) {
  return request(ADMIN + `/labour/teamPerson/updateApprovePersonInfo`, METHOD.POST, params)
}

/**
 * 查询待审批人员信息
 * @returns {Promise<AxiosResponse<T>>}
 * @params applyType 1班组申请 2项目申请
 */
export async function getApprovePersonInfoSys(params) {
  return request(ADMIN + `/labour/teamPerson/getApprovePersonInfoBySys`, METHOD.GET, params)
}

/**
 *
 * @returns {Promise<AxiosResponse<T>>}
 * @params projectId officeId
 */
export async function getTeamsByOfficeAndProject(params) {
  return request(ADMIN + `/laowu/team/findTeams/${params.projectId}/${params.officeId}`, METHOD.GET,)
}

/**
 * 校验是否开启拟退场
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function enablePlanExit(params) {
  return request(ADMIN + `/labour/projectFkSet/enablePlanExit`, METHOD.POST, params)
}

/**
 * 批量设置拟退场时间
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function batchSetPlanExit(params) {
  return request(ADMIN + `/labour/teamPerson/batchSetPlanExit`, METHOD.POST, params)
}

/**
 * 待审批-校验拟退场时间-提示
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function checkPlanExitDate(params) {
  return request(ADMIN + `/labour/teamPerson/checkPlanExitDate`, METHOD.POST, params)
}

/**
 * 在场人员-校验拟退场时间-提示
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function checkPresencePlanExitDate(params) {
  return request(ADMIN + `/laowu/team/checkPlanExitDate`, METHOD.POST, params)
}

/**
 *  更新备用考勤记录列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function updateBackAtteRecord(params) {
  return request(ADMIN + `/dmgr/backAtteRecord/update`, METHOD.POST, params)
}

/**
 *  获取工人进场设置
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getSetParamsByCond(params) {
  return request(ADMIN + `/labour/projectParamsSetting/getSetParamsBySelf`, METHOD.POST, params)
}

/**
 *  保存工人进场设置
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function setParamsByCond(params) {
  return request(ADMIN + `/labour/projectParamsSetting/save`, METHOD.POST, params)
}

/**
 *  工人入场登记查询入场设置
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getSetParamsByPidOid(params) {
  return request(ADMIN + `/labour/projectParamsSetting/getSetParamsByPidOid`, METHOD.POST, params)
}

/**
 *  建委考勤补报记录
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getJwReportRecord(params) {
  return request(ADMIN + `/labour/jwReportRecord/pages`, METHOD.POST, params)
}
