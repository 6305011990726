var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dial-container" }, [
    _c("div", { ref: "chart", staticClass: "chart" }),
    _c("div", { staticClass: "relative white" }, [
      _c("img", {
        attrs: {
          src: "https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20240718/1721273563378.png?Expires=2036633563&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=tF907QGifu6zS%2FOebvQhI5%2BQ3os%3D",
          alt: "",
        },
      }),
      _vm.spinning == true
        ? _c(
            "span",
            { staticClass: "data-text" },
            [
              _vm._v("回转角度： "),
              _c(
                "a-spin",
                [
                  _c("a-icon", {
                    staticStyle: { "font-size": "16px", color: "#fff" },
                    attrs: { slot: "indicator", type: "loading", spin: "" },
                    slot: "indicator",
                  }),
                ],
                1
              ),
              _vm._v(" °"),
            ],
            1
          )
        : _c("span", { staticClass: "data-text" }, [
            _vm._v("回转角度：" + _vm._s(this.angle) + "°"),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }