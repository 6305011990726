<template>
  <div>
    <a-card title="危大工程情况表">
      <a-row>
          <a-col :span="12">
            <a-form-model-item
              label="状态："
              :labelCol="{ span: 8 }"
              :wrapperCol="{ span: 12 }"
            >
              <span>{{statusName}}</span>
            </a-form-model-item>
          </a-col>
        </a-row>
      <form-index
        ref="formindex"
        :formData="formData"
        @handleSubmit="submit"
        :bottomSubmit="true"
      >
        <!-- 下部分按钮插槽 -->
        <template #bottomSubmit>
          <!-- <a-button type="primary" html-type="submit" v-if="params.status == 0 || params.status == 2"> 提交 </a-button>
          <a-button type="primary" @click="tempSubmit" v-if="params.status == 0 || params.status == 2"> 暂存 </a-button> -->
          <a-button class="ml-3" type="primary" html-type="submit" v-if="params.status != 3 && canOperate">
            保存
          </a-button>
          <a-button @click="close"> 关闭 </a-button>
        </template>
      </form-index>
    </a-card>
  </div>
</template>

<script>
const formData = [
  {
    label: "项目：",
    placeholder: "请填写",
    decorator: [
      "projectName",
      { rules: [{ required: false, message: "请填写" }] },
    ],
    type: "input",
    key: "projectName",
    col: 24,
    labelCol: 4,
    wrapperCol: 6,
    disabled: true,
    display: true,
    unsetRules: true
  },
  {
    label: "基坑工程：",
    placeholder: "请填写",
    decorator: [
      "baseHole",
      { rules: [{ required: false, message: "请填写" }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }] },
    ],
    type: "input",
    key: "baseHole",
    col: 12,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "基坑工程（超）：",
    placeholder: "请填写",
    decorator: [
      "baseHoleOut",
      { rules: [{ required: false, message: "请填写" }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }] },
    ],
    type: "input",
    key: "baseHoleOut",
    col: 12,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "模板及支撑体系：",
    placeholder: "请填写",
    decorator: [
      "modelSupport",
      { rules: [{ required: false, message: "请填写" }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }] },
    ],
    type: "input",
    key: "modelSupport",
    col: 12,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "模板及支撑体系（超）：",
    placeholder: "请填写",
    decorator: [
      "modelSupportOut",
      { rules: [{ required: false, message: "请填写" }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }] },
    ],
    type: "input",
    key: "modelSupportOut",
    col: 12,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "起重吊装及起重机械数量（塔）：",
    placeholder: "此项是设备台账数据",
    decorator: [
      "hoistingTower",
      { rules: [{ required: false, message: "此项是设备台账数据" }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }] },
    ],
    type: "input",
    key: "hoistingTower",
    col: 12,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
    disabled: true
  },
  {
    label: "起重吊装及起重机械数量（梯）：",
    placeholder: "此项是设备台账数据",
    decorator: [
      "hoistingTowerEle",
      { rules: [{ required: false, message: "此项是设备台账数据" }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }] },
    ],
    type: "input",
    key: "hoistingTowerEle",
    col: 12,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
    disabled: true
  },
  {
    label: "起重吊装及起重机械数量（吊）：",
    placeholder: "请填写",
    decorator: [
      "hoistingHang",
      { rules: [{ required: false, message: "请填写" }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }] },
    ],
    type: "input",
    key: "hoistingHang",
    col: 12,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "起重吊装及起重机械安装拆卸（超）：",
    placeholder: "请填写",
    decorator: [
      "hoistingOut",
      { rules: [{ required: false, message: "请填写" }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }] },
    ],
    type: "input",
    key: "hoistingOut",
    col: 12,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "脚手架：",
    placeholder: "请填写",
    decorator: [
      "scaffold",
      { rules: [{ required: false, message: "请填写" }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }] },
    ],
    type: "input",
    key: "scaffold",
    col: 12,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "脚手架（超）：",
    placeholder: "请填写",
    decorator: [
      "scaffoldOut",
      { rules: [{ required: false, message: "请填写" }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }] },
    ],
    type: "input",
    key: "scaffoldOut",
    col: 12,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "拆除工程：",
    placeholder: "请填写",
    decorator: [
      "splitWork",
      { rules: [{ required: false, message: "请填写" }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }] },
    ],
    type: "input",
    key: "splitWork",
    col: 12,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "拆除工程（超）：",
    placeholder: "请填写",
    decorator: [
      "splitWorkOut",
      { rules: [{ required: false, message: "请填写" }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }] },
    ],
    type: "input",
    key: "splitWorkOut",
    col: 12,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "幕墙：",
    placeholder: "请填写",
    decorator: [
      "curtainWall",
      { rules: [{ required: false, message: "请填写" }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }] },
    ],
    type: "input",
    key: "curtainWall",
    col: 12,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "幕墙（超）：",
    placeholder: "请填写",
    decorator: [
      "curtainWallOut",
      { rules: [{ required: false, message: "请填写" }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }] },
    ],
    type: "input",
    key: "curtainWallOut",
    col: 12,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "钢架结构：",
    placeholder: "请填写",
    decorator: [
      "steelFrame",
      { rules: [{ required: false, message: "请填写" }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }] },
    ],
    type: "input",
    key: "steelFrame",
    col: 12,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "钢架结构（超）：",
    placeholder: "请填写",
    decorator: [
      "steelFrameOut",
      { rules: [{ required: false, message: "请填写" }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }] },
    ],
    type: "input",
    key: "steelFrameOut",
    col: 12,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "人工挖孔：",
    placeholder: "请填写",
    decorator: [
      "handDigging",
      { rules: [{ required: false, message: "请填写" }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }] },
    ],
    type: "input",
    key: "handDigging",
    col: 12,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "人工挖孔（超）：",
    placeholder: "请填写",
    decorator: [
      "handDiggingOut",
      { rules: [{ required: false, message: "请填写" }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }] },
    ],
    type: "input",
    key: "handDiggingOut",
    col: 12,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "暗挖工程：",
    placeholder: "请填写",
    decorator: [
      "darkWork",
      { rules: [{ required: false, message: "请填写" }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }] },
    ],
    type: "input",
    key: "darkWork",
    col: 12,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "暗挖工程（超）：",
    placeholder: "请填写",
    decorator: [
      "darkWorkOut",
      { rules: [{ required: false, message: "请填写" }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }] },
    ],
    type: "input",
    key: "darkWorkOut",
    col: 12,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "四新无标：",
    placeholder: "请填写",
    decorator: ["fourNew", { rules: [{ required: false, message: "请填写" }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }] }],
    type: "input",
    key: "fourNew",
    col: 12,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "四新无标（超）：",
    placeholder: "请填写",
    decorator: [
      "fourNewOut",
      { rules: [{ required: false, message: "请填写" }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }] },
    ],
    type: "input",
    key: "fourNewOut",
    col: 12,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "装配式安装：",
    placeholder: "请填写",
    decorator: ["fitment", { rules: [{ required: false, message: "请填写" }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }] }],
    type: "input",
    key: "fitment",
    col: 12,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "装配式安装（超）：",
    placeholder: "请填写",
    decorator: [
      "fitmentOut",
      { rules: [{ required: false, message: "请填写" }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }] },
    ],
    type: "input",
    key: "fitmentOut",
    col: 12,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "水下作业：",
    placeholder: "请填写",
    decorator: [
      "waterMan",
      { rules: [{ required: false, message: "请填写" }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }] },
    ],
    type: "input",
    key: "waterMan",
    col: 12,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "宿舍人数：",
    placeholder: "请填写",
    decorator: ["roomMan", { rules: [{ required: false, message: "请填写" }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }] }],
    type: "input",
    key: "roomMan",
    col: 12,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "人：",
    placeholder: "请填写",
    decorator: [
      "otherMan",
      { rules: [{ required: false, message: "请填写" }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }] },
    ],
    type: "input",
    key: "otherMan",
    col: 12,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "其他：",
    placeholder: "请填写",
    decorator: ["other", { rules: [{ required: false, message: "请填写" }, { pattern: /^(?!0+$)(\d+(\.\d+)?)$/, message: '不能输入0' }] }],
    type: "input",
    key: "other",
    col: 12,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
];
import formIndex from "@/pages/components/form/index";
import {
  getWdHiddenDangerDetail,
  updateWdHiddenDanger,
} from "@/services/safeMonthlyReport";
import { mapGetters } from "vuex";
export default {
  components: {
    formIndex,
  },
  inject: ["fatherMethod"],
  data() {
    return {
      formData: formData,
      params: {
        status: null,
        id: "",
        projectName: "",
        baseHole: 0,
        baseHoleOut: 0,
        curtainWall: 0,
        curtainWallOut: 0,
        darkWork: 0,
        darkWorkOut: 0,
        fitment: 0,
        fitmentOut: 0,
        fourNew: 0,
        fourNewOut: 0,
        handDigging: 0,
        handDiggingOut: 0,
        hoistingHang: 0,
        hoistingOut: 0,
        hoistingTower: 0,
        hoistingTowerEle: 0,
        modelSupport: 0,
        modelSupportOut: 0,
        other: 0,
        otherMan: 0,
        roomMan: 0,
        scaffold: 0,
        scaffoldOut: 0,
        splitWork: 0,
        splitWorkOut: 0,
        steelFrame: 0,
        steelFrameOut: 0,
        waterMan: 0,
      },
      arrRoles:['PROJECT_JSFZR','PROJECT_AQY_ZB_FZR', 'project_leader', 'PROJECT_DEPUTY_LEADER'], // 保存权限按钮
    };
  },

  activated() {
    this.params.id = this.$route.query.id;
    this.handleQuery();
  },
  computed: {
    statusName() {
      switch (this.params.status) {
        case 0:
          return '待确认'
        case 1:
          return '已确认'
        case 2:
          return '退回'
        case 3:
          return '已上报'
        default:
          return ''
      }
    },
    ...mapGetters("account", ["user"]),
    canOperate() {
      return this.user.nowRoleKey.some(role => this.arrRoles.includes(role));
    },
  },
  methods: {
    handleQuery() {
      getWdHiddenDangerDetail(this.params.id).then((res) => {
        let d = res.data;
        if (d.code === 0) {
          console.log(d)
          this.params = d.data;
          this.$refs.formindex.queryObj(this.params)
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    tempSubmit() {
      this.$refs.formindex.combinationSubmit().then(res => {
        this.params = Object.assign(this.params, res)
        this.params.status = 0
        updateWdHiddenDanger(this.params).then(res => {
          let d = res.data;
          if (d.code === 0) {
            this.$message.success('修改成功')
            this.handleQuery()
          } else {
            this.$message.error(res.data.msg)
          }
        })
      })
    },
    submit(e) {
      const _that = this
      this.$confirm({
          title: '是否确认提交？',
          onOk() {
            _that.params = Object.assign(_that.params, e)
            updateWdHiddenDanger(_that.params).then(res => {
              let d = res.data;
              if (d.code === 0) {
                _that.$message.success('保存成功')
                _that.handleQuery()
              } else {
                _that.$message.error(res.data.msg)
              }
            })
          }
        })
    },
    close() {
      let path = this.$route.path;
      this.fatherMethod(`${path}`);
    },
  },
};
</script>

<style lang="less" scoped></style>
