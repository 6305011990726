import {request, METHOD} from '@/utils/request'
import { CIVILIZED_EXCELLENT_LIST, EXCELLENT_MASTER_LIST, INSERT_CIVILIZED_EXCELLENT, DEL_CIVILIZED_EXCELLENT, CIVILIZED_EXCELLENT_DETAIL, CIVILIZED_EXCELLENT_UPDATE,
  INSERT_EXCELLENT_MASTER, EXCELLENT_MASTER_UPDATE, DEL_EXCELLENT_MASTER, EXCELLENT_MASTER_DETAIL } from '@/services/api'


  /**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function updateExcellentMaster(params) {
  return request(EXCELLENT_MASTER_UPDATE, METHOD.POST, params)
}

  /**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getExcellentMasterDetail(params) {
  return request(EXCELLENT_MASTER_DETAIL + `/${params}`, METHOD.GET)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function delExcellentMaster(params) {
  return request(DEL_EXCELLENT_MASTER + `/${params}`, METHOD.DELETE)
}


  /**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function insertExcellentMaster(params) {
  return request(INSERT_EXCELLENT_MASTER, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getCivilizedExcellentList(params) {
  return request(CIVILIZED_EXCELLENT_LIST, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function insertCivilizedExcellent(params) {
  return request(INSERT_CIVILIZED_EXCELLENT, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getExcellentMasterList(params) {
  return request(EXCELLENT_MASTER_LIST, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function delCivilizedExcellent(params) {
  return request(DEL_CIVILIZED_EXCELLENT + `/${params}`, METHOD.DELETE)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getCivilizedExcellentDetail(params) {
  return request(CIVILIZED_EXCELLENT_DETAIL + `/${params}`, METHOD.GET)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function updateCivilizedExcellent(params) {
  return request(CIVILIZED_EXCELLENT_UPDATE, METHOD.POST, params)
}

