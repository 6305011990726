import {request, METHOD} from '@/utils/request'
import { ADMIN } from '@/services/api.js'

// const BASE_URL = process.env.VUE_APP_API_BASE_URL + '/safe'
const ADMIN_API = `${ADMIN}/safe`

/**
 *  查询列表(分页)
 */
export async function safeLevelList(params) {
    return request(ADMIN_API + `/safeLevelManager/getSafeLevelPage`, METHOD.POST, params)
}

/**
 *  新增
 */
export async function safeLevelInsert(params) {
    return request(ADMIN_API + `/safeLevelManager/insert`, METHOD.POST, params)
}

/**
 *  工作内容
 */
export async function safeContentList(params) {
    return request(ADMIN_API + `/safeLevelContentDic/getList`, METHOD.POST, params)
}

/**
 *  删除
 */
export async function safeLevelDel(id) {
    return request(ADMIN_API + `/safeLevelManager/del/${id}`, METHOD.DELETE)
}

/**
 *  修改
 */
export async function safeLevelUpdate(params) {
    return request(ADMIN_API + `/safeLevelManager/update`, METHOD.POST, params)
}

/**
 *  审批分级管控-1.通过,2.退回
 */
export async function approveSafeLevel(params) {
    return request(ADMIN_API + `/safeLevelManager/approveSafeLevel?id=${params.id}&approveStatus=${params.approveStatus}`, METHOD.POST)
}

/**
 *  下周无伟大危险源上报
 */
export async function submitNoDangerWeekly(params) {
    return request(ADMIN_API + `/safeLevelManager/submitNoDangerWeekly`, METHOD.POST, params)
}

/**
 *  下周无伟大危险源上报取消
 */
export async function cancelNoDangerWeekly(params) {
    return request(ADMIN_API + `/safeLevelManager/cancelNoDangerWeekly`, METHOD.POST, params)
}

/**
 *  获取上报记录分页列表
 */
export async function safeLevelDepartPage(params) {
    return request(ADMIN_API + `/safeLevelProjectDepartment/getSafeLevelDepartPage`, METHOD.POST, params)
}

/**
 *  情况描述
 */
export async function safeLevelStatic(params) {
    return request(ADMIN_API + `/safeLevelManager/getSafeLevelStatic`, METHOD.POST, params)
}

/**
 *  导出
 */
export const safeLevelsExport = ADMIN_API + '/safeLevelManager/safeLevelsExport'

/**
 *  获得隐患排查覆盖率固化数据分页
 */
export async function checkRateInfoPage(params) {
    return request(ADMIN_API + `/check-rate-info/getCheckRateList`, METHOD.GET, params)
}