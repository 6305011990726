var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a_Approval" },
    [
      _c(
        "a-card",
        [
          _c("h3", [_vm._v(_vm._s(_vm.$route.query.projectName))]),
          _c("standard-table", {
            attrs: {
              columns: _vm.columns,
              dataSource: _vm.tableData,
              rowKey: "index",
              loading: _vm.tableLoading,
              isAlert: false,
              bordered: true,
            },
            scopedSlots: _vm._u([
              {
                key: "status",
                fn: function (ref) {
                  var record = ref.record
                  return [
                    record.status == 0
                      ? _c("span", [_vm._v("待提交")])
                      : record.status == 1
                      ? _c("span", [_vm._v("已提交")])
                      : record.status == 2
                      ? _c("span", [_vm._v("通过")])
                      : record.status == 3
                      ? _c("span", [_vm._v("驳回")])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cerFilePath",
                fn: function (ref) {
                  var text = ref.text
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "flex a-center j-center",
                        staticStyle: { height: "40px" },
                      },
                      [
                        text != "" && text != null
                          ? _c("div", [
                              _c("img", {
                                staticStyle: {
                                  width: "40px",
                                  height: "40px",
                                  cursor: "pointer",
                                },
                                attrs: { src: text },
                                on: {
                                  click: function ($event) {
                                    return _vm.showPreview(text)
                                  },
                                },
                              }),
                            ])
                          : _vm._e(),
                        !text ? _c("div", [_vm._v("暂无图片")]) : _vm._e(),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { visible: _vm.previewVisible, footer: null },
          on: {
            cancel: function ($event) {
              _vm.previewVisible = false
            },
          },
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { alt: "example", src: _vm.previewImage },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }