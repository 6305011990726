<template>
  <div>
    <div class="constructiontTable_index">
      <div class="flex">
        <div class="header">
          <!-- <form-index ref="formindex" :formData="formData" :flexAction="true" :baiduMapBoolen="false"
            @handleSubmit="handleSubmit" @handleChange="handleChange" @handlSelect="handlSelect" @changeMonth="changeMonth">
            <template #flexAction>
              <a-space :style="{ marginTop: '4px' }">
                <a-button icon="search" type="primary" html-type="submit">查询</a-button>
                <a-button @click="reset"> 重置 </a-button>
                <a-button type="primary" @click="handleAddNewPayroll" v-show="comName == 'SalaryList'">新增</a-button>
              </a-space>
            </template>
          </form-index> -->
          <template>
            <div class="flex a-center">
              <div class="flex a-center j-center" v-for="(item, index) in headerSelectList" :key="index">
                <div class="flex a-center j-center pointer item-box" :class="item.activated ? 'activated' : ''" @click="changeStatus(index)">
                  <!-- <div class="flex a-center j-center" >
                    <img :src="item.img" alt="">
                  </div> -->
                  <span>{{ item.name }}</span>
                  <span>（{{ item.num }}）</span>
                </div>
                <div class="divider" v-if="shouldShowDivider(index)"></div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div>
        <salary-list :salaryStatus="salaryStatus"></salary-list>
        <!-- <keep-alive>
          <component :is="comName" ref="myComponent" :salaryStatus="salaryStatus" :key="comId"></component>
        </keep-alive> -->
      </div>
    </div>
  </div>
</template>

<script>
// import formIndex from "@/pages/components/form/index";
// import TaxTeamPayrollApply from '@/pages/payroll/TaxTeamPayrollApply'
// import TaxSummaryApply from '@/pages/payroll/TaxSummaryApply'
import SalaryList from '@/pages/payroll/SalaryList'
import { getAllStatusCnt, getStatusCnt } from '@/services/payroll'
// import NotTabulated from '@/pages/payroll/NotTabulated'
// import moment from 'moment';
import { mapGetters } from "vuex";
import { ca, de } from 'date-fns/locale';
// import { getOfficeProject } from "@/services/projectManagement";
// const formData = [
//   {
//     label: "制表月份",
//     placeholder: "请选择制表月份",
//     decorator: [
//       "searchMonthArr",
//       { rules: [{ required: false, message: "请选择制表月份" }] },
//     ],
//     type: "rangeMonthPicker",
//     key: "searchMonthArr",
//     col: 5,
//     labelCol: 5,
//     wrapperCol: 16,
//     display: true,
//     format: "YYYY-MM",
//     mode: ['month', 'month'],
//   },
//   {
//     label: "工资月份",
//     placeholder: "请选择",
//     decorator: [
//       "doMonth",
//       {
//         initialValue: moment(moment()).format("YYYYMM"),
//         rules: [{ required: false, message: "请选择" }],
//       },
//     ],
//     type: "monthPicker",
//     key: "doMonth",
//     col: 5,
//     labelCol: 5,
//     wrapperCol: 16,
//     display: true,
//     format: "YYYYMM",
//     styles: 'width: 100%'
//   },
//   {
//     label: "项目名称",
//     placeholder: "请输入项目名称",
//     decorator: [
//       "projectId",
//       { rules: [{ required: false, message: "请输入项目名称" }] },
//     ],
//     type: "inputGroup",
//     key: "projectId",
//     dataSource: [],
//     col: 5,
//     labelCol: 5,
//     wrapperCol: 16,
//     display: true,
//   },
//   {
//     type: "action",
//     col: 6,
//     display: true,
//   },
// ];
export default {
  components: {
    // formIndex,
    // TaxTeamPayrollApply,
    // TaxSummaryApply,
    SalaryList,
    // NotTabulated
  },
  data() {
    return {
      // formValuesCache: {},
      // formData: formData,
      comName: 'SalaryList',
      comId: null,
      currentIndex: 0,
      headerSelectList: [
        { id: 0, name: '全部', num: 0, activated: true, comName: 'SalaryList', status: '', img: 'https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20240509/1715249445904.png?Expires=2030609445&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=7hFRbv2i4RlB1goWroflbnRIcnI%3D' },
        { id: 1, name: '草稿', num: 0, activated: false, comName: 'SalaryList', status: '0', img: 'https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20240509/1715249814770.png?Expires=2030609814&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=r6LwRwkLNeSwBkyKsptddSZizig%3D'},
        { id: 2, name: '待财务审核', num: 0, activated: false, comName: 'SalaryList', status: '1', img: 'https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20240510/1715307157409.png?Expires=2030667157&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=wPRB9IWmqnJrYU8axccZEC2Xzfk%3D' },
        { id: 3, name: '已汇总工资单', num: 0, activated: false, comName: 'SalaryList', status: '3', img: 'https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20240510/1715307211964.png?Expires=2030667212&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=N70UxRstiglfBROk2nllJWdMD0o%3D' },
        { id: 4, name: '已完成制表', num: 0, activated: false, comName: 'SalaryList', status: '4', img: 'https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20240510/1715307298606.png?Expires=2030667298&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=DPnDMAh%2FYJEjTnQ5nvqVLFmPZ9M%3D' },
        // { name: '本次未制表项目数', num: 32, activated: false, comName: 'NotTabulated', img:'https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20240510/1715307395188.png?Expires=2030667395&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=dVTgRyfKjmVjqhFUhoSnBvtxsek%3D' },
      ],
      salaryStatus: '',
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  activated() {
    this.getAllStatusCnt()
  },
  // watch: {
  //   formValuesCache: {
  //     handler(val) {
  //       console.log(val, 'formValuesCache')
  //     },
  //     deep: true,
  //     immediate: true
  //   }
  // },
  methods: {
    shouldShowDivider(index) {
      // 最后一项的 divider 永远消失
      if (index === this.headerSelectList.length - 1) {
        return false;
      }
      // 当前项被激活时
      if (this.headerSelectList[index].activated) {
        return false
      } else if (this.headerSelectList[index + 1].activated) {
        return false
      }
      return true
    },
    getAllStatusCnt() {
      getAllStatusCnt().then(res => {
        if (res.data.code == 0) {
          let total = 0
          this.headerSelectList.forEach(el => {
            if (el.status && res.data.data[el.status] > 0) {
              el.num = res.data.data[el.status]
            } else {
              el.num = 0
            }
            total += el.num
          })
          this.headerSelectList[0].num = total
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    getStatusCnt(status) {
      getStatusCnt(status).then(res => {
        if (res.data.code == 0) {
          this.headerSelectList.forEach(el => {
            if (el.status == status) {
              el.num = res.data.data
            }
          })
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    changeStatus(index) {
      this.headerSelectList.forEach(el => {
        el.activated = false
      })
      this.headerSelectList[index].activated = true
      this.salaryStatus = this.headerSelectList[index].status
      this.comName = this.headerSelectList[index].comName
      this.comId = this.headerSelectList[index].id
      this.currentIndex = index
      if (index == 0) {
        this.getAllStatusCnt()
      } else {
        this.getStatusCnt(this.headerSelectList[index].status)
      }
      // this.$refs.formindex.resetFields()
      // if (this.formValuesCache[this.currentIndex]) {
      //   this.$refs.formindex.setFieldsValue('', this.formValuesCache[this.currentIndex])
      // }
    },
    // changeMonth(e) {
    //   const { val, key } = e;
    //   if (key === "searchMonthArr") {
    //     this.$refs["formindex"].setFieldsValue('', { [key]: [moment(val[0]).format('YYYYMM'), moment(val[1]).format('YYYYMM')] }, '');
    //   }
    // },
    // handleChange(e) {
    //   this.projectArr = [];
    //   this.projectList = []
    //   this.getZdata(getOfficeProject, e, "projectName", 'fullName');
    // },
    // 接口封装
    /**
     * msg 接收接口名称
     * e 数据
     * name 参数名
     * */
    // getZdata(msg, e, name, title) {
    //   let data = {
    //     [name]: e.value,
    //     officeId: this.user.officeId,
    //   };
    //   msg(data).then((res) => {
    //     if (res.status == 200) {
    //       res.data.data.list.forEach((item) => {
    //         this.projectArr.push({
    //           title: item[title],
    //           id: item.id,
    //         });
    //       });
    //       this.projectList = res.data.data;
    //       // 获取当前匹配key，赋值相对于数组
    //       this.formData.forEach((item) => {
    //         if (e.item.key === item.key) {
    //           item.dataSource = this.projectArr;
    //         }
    //       });
    //     }
    //   });
    // },
    // 提交表单数据
    // handleSubmit(e) {
    //   this.formValuesCache[this.currentIndex] = e
    //   if (e.searchMonthArr && e.searchMonthArr.length > 0) {
    //     e.startDate = moment(e.searchMonthArr[0]).format('YYYY-MM')
    //     e.endDate = moment(e.searchMonthArr[1]).format('YYYY-MM')
    //   }
    //   let obj = Object.assign({}, e, {
    //     pageNumber: 1,
    //     pageSize: 50,
    //   });
    //   this.$refs.myComponent.getData(obj)
    // },
    // 特殊数据处理
    // handlSelect(obj) {
    //   const { val, keys } = obj;
    //   if (keys === "officeId") {
    //     this.projectList.forEach((item) => {
    //       if (item.officeName == val) {
    //         this.$refs["formindex"].setFieldsValue(
    //           item.officeId,
    //           {},
    //           "officeId"
    //         );
    //       }
    //     });
    //   }
    // },
    //新增
    // handleAddNewPayroll() {
    //   if (this.$refs.myComponent.$options.name == 'SalaryList') {
    //     this.$refs.myComponent.handleAddNewPayroll()
    //   }
    // },
    // reset() {
    //   this.$refs.formindex.resetFields()
    //   let obj = {
    //     pageNumber: 1,
    //     pageSize: 50,
    //   }
    //   this.$refs.myComponent.getData(obj)
    //   if (this.formValuesCache[this.currentIndex]) {
    //     this.formValuesCache[this.currentIndex] = {}
    //   }
    // }
  },
};
</script>

<style lang="less" scoped>
.constructiontTable_index {
  padding: 24px;
  background: #fff;
  border-radius: 6px;
  margin-bottom: 10px;
  min-height: 560px;
}

/deep/ .ant-modal-footer {
  text-align: center;
  border-top: none;
  padding-bottom: 24px;
}

/deep/ .ant-modal-body {
  padding-bottom: unset;
}

.ant-btn-text {
  color: #4a86e8;
  background-color: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ant-btn-text:hover {
  cursor: pointer;
  color: #76abf5;
  background-color: transparent;
  border-color: #76abf5;
}

.header {
  background: #EFF8FF;
  border-radius: 8px;
  font-size: 16px;
}

.activated {
  background: #08B0F0;
  color: #fff;
  border-radius: 8px;
}

.item-box {
  width: 250px;
  height: 45px;
}

.operator {
  margin-right: 8px;

  .ant-btn {
    color: #307dfa;
  }
}
.divider {
  height: 20px;
  width: 2px;
  background: #E0E0E0;
}
</style>
