<template>
    <a-card>
        <div class="mb-3">
            <span>
                评分表名称： {{ name }}
            </span>
            <span class="ml-8">
                所属角色： {{ roleName }}
            </span>

            <a-button class="ml-8" type="primary" @click="hanldeAdd">
                新增
            </a-button>
        </div>
        <a-table defaultExpandAllRows :columns="columns" :data-source="tableData" :row-selection="rowSelection"
            :expanded-row-keys.sync="expandedRowKeys">
            <span slot="action" slot-scope="text,row">
                <a-button type="link" @click="hanldeAdd(row)">
                    新增
                </a-button>
                <a-button type="link" @click="hanldeUpdate(row)">编辑</a-button>
                <a-button type="link" @click="handleDelete(row)">删除</a-button>
            </span>
        </a-table>

        <a-modal v-model="visible" :title="title" @ok="handleOk" width="50%">
            <a-form-model ref="form" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-form-model-item label="评分项标题">
                    <a-textarea v-model="form.ratingitemname" :rows="2" />
                </a-form-model-item>
                <a-form-model-item label="评分项说明">
                    <a-textarea v-model="form.reserved1" :rows="8" />
                </a-form-model-item>
                
                <a-form-model-item label="加分说明">
                    <a-textarea v-model="form.reserved2" :rows="8" />
                </a-form-model-item>

                <a-form-model-item label="分数">
                    <a-input type="number" v-model="form.ratingitemscore" />
                </a-form-model-item>
                <a-form-model-item label="是否为输入项">
                    <a-radio-group v-model="form.reserved3"   style="width: 240px">
                        <a-radio :value="1">是</a-radio>
                        <a-radio :value="0">否</a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item label="缺陷类型">
                    <a-radio-group v-model="form.lacktype"   style="width: 240px">
                        <a-radio :value="1">一般</a-radio>
                        <a-radio :value="2">严重</a-radio>
                    </a-radio-group>
                </a-form-model-item>

            </a-form-model>
        </a-modal>
    </a-card>
</template>

<script>
import {
    rattingitemAdd,
    rattingitemList,
    rattingitemDelete,
    rattingitemUpdate

} from "@/services/monthlyInspectionPlan";
const columns = [
    {
        title: '检查项目名称',
        dataIndex: 'ratingitemname',
        width: 240,
        // key: 'ratingitemname',
    },
    {
        title: '评分标准',
        dataIndex: 'des',
        width: 300,
        // key: 'des',
    },
    {
        title: '评分项说明',
        dataIndex: 'reserved1',
        width: 300,
        // key: 'des',
    },
    {
        title: '加分说明',
        dataIndex: 'reserved2',
        
        // key: 'des',
    },
    {
        title: '应得分数',
        dataIndex: 'ratingitemscore',
        // key: 'ratingitemscore',
        width: 100,
    },
    {
        title: '输入项',
        dataIndex: 'reserved3Name',
        // key: 'ratingitemscore',
        width: 80,
    },
    {
        title: 'Action',
        // key: 'operation',
        fixed: 'right',
        width: 220,
        scopedSlots: { customRender: 'action' },
    },
];


const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
        console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
        console.log(selected, selectedRows, changeRows);
    },
};

// list 转 tree
function listToTree(list) {
    var map = {};
    list.forEach(function (item) {
        map[item.id] = item;
    });
    var val = [];
    list.forEach(function (item) {
        var parent = map[item.parentId];
        if (parent) {
            (parent.children || (parent.children = [])).push(item);
        } else {
            val.push(item);
        }
    });
    return val;
}
function setDes(tree) {
    tree.forEach((item, index) => {
        if (item.children && item.children.length > 0) {
            setDes(item.children);
        } else {

            item.des = item.ratingitemname;
            item.ratingitemname = '';
        }
    })
}


export default {
    name: "planDetail",
    data() {
        return {

            columns,
            rowSelection,
            expandedRowKeys: [],
            tableData: [],
            id: '',
            name: '',
            roleName: '',
            visible: false,
            form: {
                "ratingitemname": "",
                "ratingid": null,
                "parentratingitemid": 1,
                ratingitemscore: null,
                reserved3: 0,
                reserved1: '',
                reserved2: '',
                lacktype: null
            },
            labelCol: { span: 5 },
            wrapperCol: { span: 14 },
            title: '新增',

        }
    },
    mounted() {

    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            // if query 发生改变的情况下，需要重新获取数据
            vm.init();
        });
    },
    methods: {
        init() {
            this.id = this.$route.query.id;
            this.name = this.$route.query.name;
            this.roleName = this.$route.query.roleName;
            this.queryDetail();
        },
        queryDetail() {
            let d = {
                ratingid: this.id,
            }
            rattingitemList(d).then(res => {
                if (res.data.code == 200) {
                    console.log(res.data)
                    let d = res.data.data.map((item, index) => {
                        let reserved3Name
                        if (item.reserved3 == 0) {
                            reserved3Name = '否'
                        }
                        if (item.reserved3 == 1) {
                            reserved3Name = '是'
                        }

                        return {
                            ...item,
                            id: item.ratingitemid,
                            parentId: item.parentratingitemid === 0 ? null : item.parentratingitemid,
                            key: item.ratingitemid,
                            reserved3Name
                        }
                    })

                    let d1 = listToTree(d);

                    // setDes(d1);

                    this.tableData = d1;
                    console.log(this.tableData)
                }
            })
        },
        hanldeAdd(row) {
            if (row) {
                // 添加子项
                this.form = {
                    "ratingitemname": "",
                    "ratingid": this.id,
                    "parentratingitemid": row.ratingitemid,
                    ratingitemscore: null,
                    reserved3: 0,
                    reserved1: '',
                    reserved2: '',
                    lacktype: null
                }
            } else {
                // 添加父项
                this.form = {
                    "ratingitemname": "",
                    "ratingid": this.id,
                    "parentratingitemid": null,
                    ratingitemscore: null,
                    reserved3: 0,
                    reserved1: '',
                    reserved2: '',
                    lacktype: null
                }
            }
            this.visible = true;
        },
        hanldeUpdate(row) {
            this.form = row
            console.log(this.form)
            this.visible = true;
        },
        handleOk() {
            
            this.$refs.form.validate(async (valid) => {
                if (valid) {
                    if (this.form.ratingitemid) {
                        let d = {
                            ...this.form,
                            ratingitemscore: Number(this.form.ratingitemscore),
                        }
                        rattingitemUpdate(d).then(res => {
                            if (res.data.code == 200) {
                                this.visible = false;
                                this.$message.success("编辑成功成功");
                                this.queryDetail();
                            }
                        })
                    } else {
                        let d = {
                            ...this.form,
                            ratingitemscore: Number(this.form.ratingitemscore),
                        }
                        rattingitemAdd(d).then(res => {
                            if (res.data.code == 200) {
                                this.visible = false;
                                this.$message.success("新增成功");
                                this.queryDetail();
                            }
                        })

                    }
                }
            });
        },
        handleDelete(row) {
            this.$confirm({
                title: "提示",
                content: "是否删除？",
                okText: "确定",
                cancelText: "取消",
                onOk: () => {
                    rattingitemDelete({ratingitemid: row.ratingitemid}).then(res => {
                        if (res.data.code == 200) {
                            this.$message.success("删除成功");
                            this.queryDetail();
                        }
                    })

                   
                },
            });
        },

    },

}
</script>

<style lang="less" scoped>
@import "@/theme/common.less";
</style>