<template>
  <a-modal
    title="题库列表"
    style="top: 10px"
    :width="1024"
    :zIndex="2000"
    :visible="visible"
    @ok="() => doSubmit()"
    @cancel="() => doBack()"
  > 
    <div :class="advanced ? 'search' : null">
      <a-form layout="horizontal">
        <div :class="advanced ? null: 'fold'">
          <a-row >
            <a-col :md="6" :sm="24" >
              <a-form-item
                label="题目"
                :labelCol="{span: 5}"
                :wrapperCol="{span: 15, offset: 1}"
              >
                <a-input-search size="small" placeholder="题目名称" v-model="queryParam.questCont" @search="doQuery()"/>
              </a-form-item>
            </a-col>
            <a-col  :md="6" :sm="24" >
              <a-form-item
                label="题型"
                :labelCol="{span: 5}"
                :wrapperCol="{span: 15, offset: 1}"
              >
                <a-select size="small" :dropdownStyle="{zIndex:2001}" v-model="queryParam.isMany" @change="changeMany" placeholder="请选择">
                  <a-select-option :value="1">单选</a-select-option>
                  <a-select-option :value="2">多选</a-select-option>
                  <a-select-option :value="3">判断</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24" >
              <a-form-item
                label="难度"
                :labelCol="{span: 5}"
                :wrapperCol="{span: 15, offset: 1}"
              >
                <a-select size="small" :dropdownStyle="{zIndex:2001}" v-model="queryParam.level"  @change="changelevel" placeholder="请选择">
                  <a-select-option :value="1">简单</a-select-option>
                  <a-select-option :value="2">一般</a-select-option>
                  <a-select-option :value="3">困难</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24" >
              <a-form-item
                label="课时标签"
                :labelCol="{span: 8}"
                :wrapperCol="{span: 15, offset: 1}"
              >
                <!-- <a-select size="small" :dropdownStyle="{zIndex:2001}" v-model="queryParam.hourTags"  @change="changelevel" placeholder="请选择">
                  <a-select-option :value="1">简单</a-select-option>
                  <a-select-option :value="2">一般</a-select-option>
                  <a-select-option :value="3">困难</a-select-option>
                </a-select> -->
                <a-select
                mode="tags"
                size="small"
                placeholder="课时标签"
                :dropdownStyle="{zIndex:2001}"
                @change="changeHoursTags"
              >
                <a-select-option v-for="item in hourDataSource" :key="item.id">
                  {{item.name}}
                </a-select-option>
              </a-select>
              </a-form-item>
            </a-col>
          </a-row >
        </div>
      </a-form>
    </div>
    <div style="margin-top:-30px">
      <standard-table
          :columns="columns"
          :dataSource="dataSource"
          :selectedRows.sync="selectedRows"
          :pagination="pagination"
          :expandedRowKeys.sync="expandedRowKeys"
          :loading="tableLoading"
          :isAlert="false"
          tableSize='small'
          rowKey="code"
          @update:selectedRows="updateSelectRows($event)"
          @clear="onClear"
          @change="onPageChange"
          @rowSelect = "rowSelectRecode"
        >
        </standard-table>
    </div>
      <div>
        <a-tabs :activeKey="currentTab" @change="callback">
          <a-tab-pane :key="1">
            <template slot="tab">单选题（{{sigleQuests.length}}题/{{sigleCore}}分）</template>
            <vue-scroll :ops="ops" style="width:100%;height:250px">
              <a-list size="small" bordered :data-source="sigleQuests">
                <a-list-item slot="renderItem" slot-scope="item, index">
                  {{index+1}}、【{{item.level==1?'简单':item.level==2?"一般":"困难"}}】{{ item.questCont }}
                  <template slot="actions"><div> <a-input-number size="small" v-model="item.newcore" :min="1" :max="15" :step="0.5"/>(分)</div></template>
                </a-list-item>
              </a-list>
            </vue-scroll>
          </a-tab-pane>
          <a-tab-pane :key="2" force-render>
            <template slot="tab">多选题（{{multipleQuests.length}}题/{{multipleCore}}分）</template>
            <vue-scroll :ops="ops" style="width:100%;height:250px">
            <a-list size="small" bordered :data-source="multipleQuests">
              <a-list-item slot="renderItem" slot-scope="item, index">
                {{index+1}}、【{{item.level==1?'简单':item.level==2?"一般":"困难"}}】{{ item.questCont }}
                <template slot="actions"><div> <a-input-number size="small" v-model="item.newcore" :min="1" :max="15" :step="0.5"/>(分)</div></template>
              </a-list-item>
            </a-list>
            </vue-scroll>
          </a-tab-pane>
          <a-tab-pane :key="3">
            <template slot="tab">判断题（{{judgeQuests.length}}题/{{judgeCore}}分）</template>
            <vue-scroll :ops="ops" style="width:100%;height:250px">
            <a-list size="small" bordered :data-source="judgeQuests">
              <a-list-item slot="renderItem" slot-scope="item, index">
                {{index+1}}、【{{item.level==1?'简单':item.level==2?"一般":"困难"}}】{{ item.questCont }}
                <template slot="actions"><div><a-input-number size="small" v-model="item.newcore" :min="1" :max="15" :step="0.5" />(分)</div></template>
              </a-list-item>
            </a-list>
            </vue-scroll>
          </a-tab-pane>
        </a-tabs>
      </div>
  </a-modal>
</template>

<script>
import {request} from '@/utils/request'
import StandardTable from '@/components/table/StandardTable'
import cloneDeep from 'lodash.clonedeep'
import {
  ONLINE_QUESTION_CHOOSE_QUERY,
  ONLINE_QUESTION_JUDGE_QUERY,
  ONLINE_CLASS_HOUR_GETALL,
  } from '@/services/api'
const columns = [
  { title: '题目', dataIndex: 'questCont',key:'1', align: 'left',ellipsis:true},
  { title: '题目类型', dataIndex: 'isMany',key:'2', align: 'left',width:120,customRender: (text) => {
    if(text==1){
      return "单选";
    }else if(text==2){
      return "多选";
    }else{
      return "判断";
    }}},
  { title: '分值', dataIndex: 'core',key:'3' , align: 'center',width:100},
  { title: '难度', dataIndex: 'level',key:'4' , align: 'center',width:100,customRender: (text) => {
    if(text==1){
      return "简单";
    }else if(text==2){
      return "一般";
    }else{
      return "困难";
    }}}];

export default {
  name: 'IconSelectorView',
  components: {
    StandardTable
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    paperId:{
      type: String,
      
    },
    courseId:{
      type: String,
      require:true
    },
    sigleQuestsOld:{
      type:Array
    },
    multipleQuestsOld:{
      type:Array
    },
    judgeQuestsOld:{
      type:Array
    }
  },
  watch:{
    visible(val){
      if(val){
        this.sigleQuests = cloneDeep(this.sigleQuestsOld);
        this.multipleQuests = cloneDeep(this.multipleQuestsOld);
        this.judgeQuests = cloneDeep(this.judgeQuestsOld);
        this.sigleQuests.forEach(o=>{
          var isHas = this.gloabKeys.indexOf(o.code);
          if(isHas<0){
            this.gloabKeys.push(o.code);
          }
        })
        this.judgeQuests.forEach(o=>{
          var isHas = this.gloabKeys.indexOf(o.code);
          if(isHas<0){
            this.gloabKeys.push(o.code);
          }
        })
        this.multipleQuests.forEach(o=>{
          var isHas = this.gloabKeys.indexOf(o.code);
          if(isHas<0){
            this.gloabKeys.push(o.code);
          }
        })
        //查询题库信息
        if(this.courseId){
          this.doQuery(1);
          this.doQueryCourseHours(this.courseId)
        }
        if(this.paperId){
          this.doQueryQuest();
        }
      }
    }
  },
  data () {
    return {
      columns,
      selectedRows: [],
      hourDataSource:[],
      dataSource:[],
      sigleQuests:[],
      multipleQuests:[],
      judgeQuests:[],
      currentTab:1,
      tableLoading: false,
      advanced:false,
      importLoading:false,
      fileListFile:[],
      gloabKeys:[],
      expandedRowKeys:[],
      queryParam:{
        isMany:1,
        level:null,
        courseId:null,
        hourTags:null,
        pageNumber:0,
      },
      pagination:{
        current:1,
        pageSize:10,
        total:0,
        showLessItems:true,
        showQuickJumper:true
      },
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: "#3ec13e",//滚动条颜色
          opacity: 0.8,//滚动条透明度
          "overflow-x": "hidden"
        }
      }
    }
  },
  computed:{
    sigleCore() {
      let totalCore = 0;
      this.sigleQuests.forEach(o=>{
        totalCore = totalCore+o.newcore;
      })
      return totalCore
    },
    multipleCore() {
      let totalCore = 0;
      this.multipleQuests.forEach(o=>{
        totalCore = totalCore+o.newcore;
      })
      return totalCore
    },
    judgeCore() {
      let totalCore = 0;
      this.judgeQuests.forEach(o=>{
        totalCore = totalCore+o.newcore;
      })
      return totalCore
    },
  },
  methods: {
    doBack(){
      this.$emit("doCloseQuestions");
    },
    changeHoursTags(values){
      this.queryParam.hourTags = values.join(',');
      this.doQuery();
    },
    doQueryCourseHours(courseId){
      request(ONLINE_CLASS_HOUR_GETALL,"post",{courseId:courseId}).then(res => {
          if(res.data.code==0){
           this.hourDataSource = res.data.data;
          }
       })
    },
    doSubmit(){
      let obj = {
        paperId:this.paperId,
        courseId:this.courseId,
        sigleQuests:this.sigleQuests,
        multipleQuests:this.multipleQuests,
        judgeQuests:this.judgeQuests
      }
      this.$emit("doSubmitPaperQuest",obj);
    },
    onClear(){

    },
    //分页查询
    onPageChange(e){
      this.queryParam.pageNumber = e.current;
      this.pagination.current=e.current;
      this.doQuery();
    },
    doQuery(){
      this.tableLoading = true;
      let path = ONLINE_QUESTION_CHOOSE_QUERY;
      if(this.queryParam.isMany==3){
        path = ONLINE_QUESTION_JUDGE_QUERY;
      }else{
        path = ONLINE_QUESTION_CHOOSE_QUERY;
      }
      this.queryParam.courseId = this.courseId;
      request(path,"post",this.queryParam).then(res => {
        if(res.data.code==0){
          this.dataSource = res.data.data.list;
          this.dataSource.forEach(obj=>{
            if(this.gloabKeys.includes(obj.code)){
              this.selectedRows.push(obj);
            }
          })
          this.pagination.total = res.data.data.total;
        }
      })
      this.tableLoading = false;
    },
    doQueryQuest(){

    },
    callback(key){
      this.currentTab = key;
      this.queryParam.isMany=key;
      this.doQuery();
    },
    rowSelectRecode(record,selected,selectedRows){
      if(selected){
        const isHas = this.gloabKeys.indexOf(record.code);
        if(isHas<0){
          this.gloabKeys.push(record.code);
          this.$set(record,"newcore",record.core);
          if(this.currentTab==1){
            this.sigleQuests.push(record);
          }else if(this.currentTab==2){
            this.multipleQuests.push(record)
          }else{
            this.judgeQuests.push(record)
          }
        }
      }else{
        const isHas = this.gloabKeys.includes(record.code);
        if(isHas){
          const index = this.gloabKeys.indexOf(record.code)
          if (index > -1) {
            if(this.currentTab==1){
                this.sigleQuests.splice(this.sigleQuests.findIndex(item => item.code == record.code), 1)
              }else if(this.currentTab==2){
                this.multipleQuests.splice(this.multipleQuests.findIndex(item => item.code == record.code), 1)
              }else{
                this.judgeQuests.splice(this.judgeQuests.findIndex(item => item.code == record.code), 1)
              }
            return this.gloabKeys.splice(index, 1)
          }
        }

      }

    },
    updateSelectRows($event){
      if($event.length>0){
        $event.forEach(obj=>{
          const isHas = this.gloabKeys.indexOf(obj.code);
          if(isHas<0){
            this.$set(obj,"newcore",obj.core);
            this.gloabKeys.push(obj.code);
            if(this.currentTab==1){
              this.sigleQuests.push(obj);
            }else if(this.currentTab==2){
              this.multipleQuests.push(obj)
            }else{
              this.judgeQuests.push(obj)
            }
          }
        })
      }else{
        this.dataSource.forEach(obj=>{
          const isHas = this.gloabKeys.includes(obj.code);
          if(isHas){
            const index = this.gloabKeys.indexOf(obj.code)
            if (index > -1) {
              if(this.currentTab==1){
                this.sigleQuests.splice(this.sigleQuests.findIndex(item => item.code == obj.code), 1)
              }else if(this.currentTab==2){
                this.multipleQuests.splice(this.multipleQuests.findIndex(item => item.code == obj.code), 1)
              }else{
                this.judgeQuests.splice(this.judgeQuests.findIndex(item => item.code == obj.code), 1)
              }
              return this.gloabKeys.splice(index, 1)
            }
          }
        })
      }
    },
    changeMany(value){
      this.currentTab=value;
      this.doQuery();
    },
    changelevel(){
      this.doQuery();
    }
    // doDatas(){
    //   if(this.currentTab==1){
    //     this.sigleQuests.push();
    //   }else if(this.currentTab==2){

    //   }else{
        
    //   }
    // }
  }
}
</script>

<style lang="less" scoped>
  .search{
    margin-bottom: 54px;
  }
  .fold{
    width: calc(100% - 216px);
    display: inline-block
  }
  .operator{
    margin-bottom: 18px;
  }
  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
</style>
