import {GET_EPIDEMIC_LIST_BY_PROJECT, DAILY_REPORT_STATISTICS,
    ABNORMAL_REPORT_STATISTICS, GET_EPIDEMIC_QR_CODE, GET_OFFICE_TREE_BY_EPIDEMIC,
    GET_PROJECT_LIST_BY_PARENT_OFFICE_ID_BY_EPIDEMIC, EXPORT_EPIDEMIC_LIST_BY_PROJECT,
    STATISTICS_BY_OFFICE, STATISTICS_BY_OFFICE_PROJECT, EXPORT_ABNORMAL_REPORT_STATISTICS,
    EXPORT_DAILY_REPORT_STATISTICS, GET_OFFICE_REPORT_RECODE, GET_OFFICE_PROJECT_STATICS,
    EXPORT_OFFICE_PROJECT_STATICS, EXPORT_OFFICE_RECODE, GET_REPORT_STATISTICS, GET_NOT_NUCLEIN_STATISTICS} from '@/services/api'
import {request, METHOD, removeAuthorization} from '@/utils/request'
import {changeAccount, getRoutesConfig, login, loginPhone, logout} from "./user";

/**
 * 项目查询所有上报记录
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getEpidemicListByProject(params) {
    return request(GET_EPIDEMIC_LIST_BY_PROJECT, METHOD.POST, params)
}

/**
 * 项目查询每日上报人数统计
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function dailyReportStatistics(params) {
    return request(DAILY_REPORT_STATISTICS, METHOD.POST, params)
}

/**
 * 项目查询异常上报统计
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function abnormalReportStatistics(params) {
    return request(ABNORMAL_REPORT_STATISTICS, METHOD.POST, params)
}

/**
 * 项目查询二维码
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getEpidemicQrCode(params) {
    return request(GET_EPIDEMIC_QR_CODE, METHOD.GET, params)
}

/**
 * 查询公司树状结构
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getOfficeTree(params) {
    return request(GET_OFFICE_TREE_BY_EPIDEMIC, METHOD.GET, params)
}

/**
 * 查询公司树状结构
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getProjectListByParentOfficeId(params) {
    return request(GET_PROJECT_LIST_BY_PARENT_OFFICE_ID_BY_EPIDEMIC, METHOD.GET, params)
}

// /**
//  * 项目导出所有上报记录
//  * @returns {Promise<AxiosResponse<T>>}
//  */
// export async function exportEpidemicListByProject(params) {
//     return request(EXPORT_EPIDEMIC_LIST_BY_PROJECT, METHOD.POST, params, {responseType: "blob"})
// }


/**
 * 项目导出所有上报记录
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function exportEpidemicListByProject(params) {
    return request(EXPORT_EPIDEMIC_LIST_BY_PROJECT, METHOD.POST, params)
}

/**
 * 项目导出每日上报统计
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function exportDailyReportStatistics(params) {
    return request(EXPORT_DAILY_REPORT_STATISTICS, METHOD.POST, params, {responseType: "blob"})
}

/**
 * 项目导出异常记录
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function exportAbnormalReportStatistics(params) {
    return request(EXPORT_ABNORMAL_REPORT_STATISTICS, METHOD.POST, params, {responseType: "blob"})
}


/**
 * 公司级每日上报
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function statisticsByOffice(params) {
    return request(STATISTICS_BY_OFFICE, METHOD.POST, params)
}

/**
 * 公司级项目每日上报
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function statisticsByOfficeProject(params) {
    return request(STATISTICS_BY_OFFICE_PROJECT, METHOD.POST, params)
}

/**
 * 公司级所有上报记录
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getOfficeReportRecode(params) {
    return request(GET_OFFICE_REPORT_RECODE, METHOD.POST, params)
}

/**
 * 公司-项目 详细统计
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getOfficeProjectStatics(params) {
    return request(GET_OFFICE_PROJECT_STATICS, METHOD.POST, params)
}

/**
 * 导出公司-项目 详细统计
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function exportOfficeProjectStatics(params) {
    return request(EXPORT_OFFICE_PROJECT_STATICS, METHOD.POST, params, {responseType: "blob"})
}



/**
 * 公司导出所有上报记录
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function exportOfficeRecode(params) {
    return request(exportOfficeRecode, METHOD.POST, params)
}


/**
 * 公司导出所有上报记录
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getReportStatistics(params) {
    return request(GET_REPORT_STATISTICS, METHOD.GET, params)
}


/**
 * 获取核酸超时的列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getNotNucleinStatistics(params) {
    return request(GET_NOT_NUCLEIN_STATISTICS, METHOD.POST, params)
}






export default {
    getEpidemicListByProject,
    dailyReportStatistics,
    abnormalReportStatistics,
    getEpidemicQrCode,
    getOfficeTree,
    getProjectListByParentOfficeId,
    exportEpidemicListByProject,
    statisticsByOffice,
    statisticsByOfficeProject,
    exportDailyReportStatistics,
    exportAbnormalReportStatistics,
    getOfficeReportRecode,
    getOfficeProjectStatics,
    exportOfficeProjectStatics,
    exportOfficeRecode,
    getReportStatistics,
    getNotNucleinStatistics
}

