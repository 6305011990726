var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a_Approval" },
    [
      _c("div", { staticClass: "plug-Overview" }, [_vm._v("审核列表")]),
      _c("a-divider"),
      _c(
        "a-form-model",
        {
          staticClass: "clearfix",
          attrs: { model: _vm.params },
          on: { submit: _vm.handleSubmit },
        },
        [
          _c(
            "a-col",
            { attrs: { span: 5 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "项目名称",
                    labelCol: { span: 8 },
                    wrapperCol: { span: 14, offset: 1 },
                  },
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入项目名称", allowClear: "" },
                    model: {
                      value: _vm.params.projectName,
                      callback: function ($$v) {
                        _vm.$set(_vm.params, "projectName", $$v)
                      },
                      expression: "params.projectName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 5 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "岗位",
                    labelCol: { span: 8 },
                    wrapperCol: { span: 14, offset: 1 },
                  },
                },
                [
                  _c("a-select", {
                    attrs: {
                      "show-search": "",
                      allowClear: "",
                      placeholder: "请输入或选择",
                      "option-filter-prop": "children",
                      options: _vm.postList,
                    },
                    model: {
                      value: _vm.params.postId,
                      callback: function ($$v) {
                        _vm.$set(_vm.params, "postId", $$v)
                      },
                      expression: "params.postId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 5 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "查询人员",
                    labelCol: { span: 8 },
                    wrapperCol: { span: 14, offset: 1 },
                  },
                },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        allowClear: "",
                        "show-search": "",
                        placeholder: "请输入查询人员",
                        "option-filter-prop": "children",
                      },
                      model: {
                        value: _vm.params.userId,
                        callback: function ($$v) {
                          _vm.$set(_vm.params, "userId", $$v)
                        },
                        expression: "params.userId",
                      },
                    },
                    _vm._l(_vm.userSafeList, function (item) {
                      return _c(
                        "a-select-option",
                        {
                          key: item.id,
                          attrs: { value: item.id, title: item.name },
                        },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 5 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "审核状态",
                    labelCol: { span: 8 },
                    wrapperCol: { span: 14, offset: 1 },
                  },
                },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        allowClear: "",
                        "show-search": "",
                        placeholder: "请输入审核状态",
                        "option-filter-prop": "children",
                      },
                      model: {
                        value: _vm.params.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.params, "status", $$v)
                        },
                        expression: "params.status",
                      },
                    },
                    _vm._l(_vm.statusList, function (item) {
                      return _c(
                        "a-select-option",
                        {
                          key: item.id,
                          attrs: { value: item.id, title: item.name },
                        },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 4 } },
            [
              _c(
                "a-space",
                {
                  staticClass: "btnBox",
                  staticStyle: { "padding-left": "10px" },
                },
                [
                  _c(
                    "a-button",
                    { attrs: { type: "primary", "html-type": "submit" } },
                    [_vm._v(" 查询 ")]
                  ),
                  _c("a-button", { on: { click: _vm.resetQuery } }, [
                    _vm._v(" 重置 "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("standard-table", {
        attrs: {
          columns: _vm.columns,
          dataSource: _vm.tableData,
          rowKey: "id",
          pagination: _vm.pagination,
          loading: _vm.tableLoading,
          isAlert: false,
          bordered: true,
        },
        on: { change: _vm.handleChangePage },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function (ref) {
              var index = ref.index
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      (_vm.pagination.current - 1) * _vm.pagination.pageSize +
                        parseInt(index) +
                        1
                    )
                  ),
                ]),
              ]
            },
          },
          {
            key: "status",
            fn: function (ref) {
              var record = ref.record
              return [
                record.status == 0
                  ? _c("span", [_vm._v("待提交")])
                  : record.status == 1
                  ? _c("span", [_vm._v("已提交")])
                  : record.status == 2
                  ? _c("span", [_vm._v("通过")])
                  : record.status == 3
                  ? _c("span", [_vm._v("驳回")])
                  : _c("span", [_vm._v("/")]),
              ]
            },
          },
          {
            key: "cerFilePath",
            fn: function (ref) {
              var text = ref.text
              var record = ref.record
              return [
                _c(
                  "div",
                  {
                    staticClass: "flex a-center j-center",
                    staticStyle: { height: "40px" },
                  },
                  [
                    text != "" && text != null
                      ? _c("div", [
                          _c("img", {
                            staticStyle: {
                              width: "40px",
                              height: "40px",
                              cursor: "pointer",
                            },
                            attrs: { src: text },
                            on: {
                              click: function ($event) {
                                return _vm.showPreview(text, record)
                              },
                            },
                          }),
                        ])
                      : _vm._e(),
                    !text ? _c("div", [_vm._v("暂无图片")]) : _vm._e(),
                  ]
                ),
              ]
            },
          },
          {
            key: "action",
            fn: function (ref) {
              var record = ref.record
              return [
                record.status && record.status != 2 && record.status != 3
                  ? _c(
                      "div",
                      [
                        _c(
                          "a",
                          {
                            on: {
                              click: function () {
                                return _vm.handleApprove(record, 2)
                              },
                            },
                          },
                          [_vm._v(" 通过 ")]
                        ),
                        _c("a-divider", { attrs: { type: "vertical" } }),
                        _c(
                          "a",
                          {
                            staticStyle: { color: "#FF5C77" },
                            on: {
                              click: function () {
                                return _vm.handleApprove(record, 3)
                              },
                            },
                          },
                          [_vm._v(" 驳回 ")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }