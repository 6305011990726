<template>
    <div class="container">
        <div class="a_m_header">
            <a-tabs :default-active-key="defaultActiveKey">
                <a-tab-pane key="1" tab="在场人员">
                    <presence-list ref="presenceList" @refreshExitList="refreshExitList"></presence-list>
                </a-tab-pane>
                <a-tab-pane key="2" tab="退场人员" forceRender>
                    <exit-list ref="exitList" @refreshPresenceList="refreshPresenceList"></exit-list>
                </a-tab-pane>
            </a-tabs>
        </div>
    </div>
</template>

<script>
import PresenceList from "./presenceList.vue";
import ExitList from "./exitList.vue";
export default {
    components: {
        PresenceList,
        ExitList
    },
    data() {
        return {
            defaultActiveKey: "1",
        };
    },
    created() {
    },
    methods: {
        refreshPresenceList() {
            this.$refs.presenceList.getData({ //永远都是第一页
                pageNumber: 1,
                pageSize: 10,
                enterStatus: '1' //在场
            })
        },
        refreshExitList() {
            this.$refs.exitList.getData({ //永远都是第一页
                pageNumber: 1,
                pageSize: 10,
                enterStatus: '0' //退场
            })
        }
    },
}
</script>

<style lang="less" scoped>
</style>