var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("search-form"),
      _c(
        "a-list",
        {
          staticStyle: { margin: "0 -8px" },
          attrs: { grid: { gutter: 16, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 } },
        },
        _vm._l(12, function (n) {
          return _c(
            "a-list-item",
            { key: n, staticStyle: { padding: "0 8px" } },
            [
              _c(
                "a-card",
                [
                  _c(
                    "a-card-meta",
                    { attrs: { title: "Angular" } },
                    [
                      _c("a-avatar", {
                        attrs: {
                          slot: "avatar",
                          src: "https://gw.alipayobjects.com/zos/rmsportal/zOsKZmFRdUtvpqCImOVY.png",
                          size: "small",
                        },
                        slot: "avatar",
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-tooltip",
                    {
                      staticClass: "tool",
                      attrs: { slot: "actions", title: "下载" },
                      slot: "actions",
                    },
                    [_c("a-icon", { attrs: { type: "download" } })],
                    1
                  ),
                  _c(
                    "a-tooltip",
                    {
                      staticClass: "tool",
                      attrs: { slot: "actions", title: "编辑" },
                      slot: "actions",
                    },
                    [_c("a-icon", { attrs: { type: "edit" } })],
                    1
                  ),
                  _c(
                    "a-tooltip",
                    {
                      staticClass: "tool",
                      attrs: { slot: "actions", title: "分享" },
                      slot: "actions",
                    },
                    [_c("a-icon", { attrs: { type: "share-alt" } })],
                    1
                  ),
                  _c(
                    "a-dropdown",
                    {
                      staticClass: "tool",
                      attrs: { slot: "actions" },
                      slot: "actions",
                    },
                    [
                      _c("a-icon", { attrs: { type: "ellipsis" } }),
                      _c(
                        "a-menu",
                        { attrs: { slot: "overlay" }, slot: "overlay" },
                        [
                          _c("a-menu-item", [_vm._v("1 item")]),
                          _c("a-menu-item", [_vm._v("2 item")]),
                          _c("a-menu-item", [_vm._v("3 item")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "content" }, [
                    _c("div", [
                      _c("p", [_vm._v("活跃用户")]),
                      _c("p", [_vm._v("18万")]),
                    ]),
                    _c("div", [
                      _c("p", [_vm._v("新增用户")]),
                      _c("p", [_vm._v("1,338")]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }