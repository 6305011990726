<template>
    <div>
        <a-card :bordered="false">
            <div>
                <a-form :form="searchForm" >
                    <a-row :gutter="24">
                        <a-col :span="5" >
                            <a-form-item :label="`申诉人`" :labelCol="{span: 5}" :wrapperCol="{span: 18, offset: 1}">
                                <a-input style="width:180px" v-model="searchForm.name" placeholder="申诉人" />
                            </a-form-item>
                        </a-col>

                        <a-col :span="4" >
                            <a-form-item :label="`状态`" :labelCol="{span: 5}" :wrapperCol="{span: 18, offset: 1}">
                                <a-select
                                        v-model="searchForm.status"
                                >
                                    <a-select-option key="" value="">
                                        全部
                                    </a-select-option>
                                    <a-select-option key="1" value="1">
                                        待处理
                                    </a-select-option>
                                    <a-select-option key="2" value="2">
                                        处理中
                                    </a-select-option>
                                    <a-select-option key="3" value="3">
                                        已完成
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>

                        <a-col :span="4" :style="{ marginTop: '3px'}">
                            <a-space>
                                <a-button type="primary" icon="search" :loading="loading" @click="handleSearch">
                                 查询
                                </a-button>
                                <a-button @click="doReset()">
                                    重置
                                </a-button>
                            </a-space>
                        </a-col>
                    </a-row>
                    <!-- <a-row>
                        <a-switch v-if="showConfig" v-model="openAppealConfig.openAppealBoolean" checked-children="开" un-checked-children="关" @change="changeOpenAppeal" />
                        说明：开启则代表小程序端可对任何问题进行反馈申诉，关闭则反之。
                    </a-row> -->
                </a-form>
            </div>
            <a-table
                    :columns="columns"
                    :data-source="data"
                    :pagination="pagination"
                    @change="onPageChange"
                    :loading="tableLoading"
                    :bordered="true"
                    size="middle"
            >
                <template slot="action" slot-scope="text, record">
                    <span >
                      <a @click="showRecord(record)">处理申诉</a>
                    </span>
                </template>

            </a-table>
        </a-card>


        <a-modal
                :title="title"
                :visible="visible"
                :confirm-loading="confirmLoading"
                @ok="modelOk"
                @cancel="modelCancel"
                width="560px"


        >
            <a-form-model :model="record"
                          :label-col="labelCol"
                          :wrapper-col="wrapperCol"
                          v-if="visible"
            >
                <!-- <a-form-model-item label="申诉id" ref="id" prop="id"  required>
                    <a-input v-model="record.id" disabled />
                </a-form-model-item> -->
                 <a-form-model-item label="姓名" prop="name"  required>
                    <a-input v-model="record.name" disabled />
                </a-form-model-item>
                <a-form-model-item label="申诉状态">
                    <a-select v-model="record.status"  >
                        <a-select-option key="1" value="1">
                            待处理
                        </a-select-option>
                        <a-select-option key="2" value="2">
                            处理中
                        </a-select-option>
                        <a-select-option key="3" value="3">
                            已完成
                        </a-select-option>
                    </a-select>
                </a-form-model-item>

                <a-form-model-item label="处理结果" ref="resultReason" prop="resultReason"  required>
                    <a-textarea placeholder="请输入处理结果" v-model="record.resultReason" :rows="4" />
                </a-form-model-item>
            </a-form-model>

        </a-modal>



    </div>



</template>

<script>
    import {  getStaffAppealList, setAppealStatus, changeOpenAppeal, getOpenAppealConfig} from '@/services/staffAppeal'
    import pick from 'lodash.pick'
    import {mapGetters} from 'vuex'
    import { useRender } from '@/hooks/useRender.js'

    const columns = [
        {
            title: '项目名称',
            dataIndex: 'projectName',
            key: 'projectName',
            ellipsis: true
        },
        {
            title: '公司',
            align:'center',
            dataIndex: 'officeName',
            key: 'officeName',
            ellipsis: true
        },
        {
            title: '班组',
            align:'center',
            dataIndex: 'teamName',
            key: 'teamName',
        },
        {
            title: '姓名',
            align:'center',
            width:120,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '手机号',
            align:'center',
            width:120,
            key: 'phone',
            dataIndex: 'phone',
        },
        {
            title: '申诉原因',
            align:'center',
            key: 'appealStatement',
            dataIndex: 'appealStatement',
        },
        {
            title: '处理状态',
            align:'center',
            width:120,
            key: 'status',
            dataIndex: 'status',
            customRender: (text) => {
                return text == 3? "已完成" : text == 2? "处理中" : "待处理"
            }
        },
        {
            title: '处理结果',
            align:'center',
            width:120,
            key: 'resultReason',
            dataIndex: 'resultReason',
        },
        {
            title: '创建时间',
            align:'center',
            key: 'createTime',
            dataIndex: 'createTime',
            customRender: (text) => useRender.renderDate(text)
        },
        {
            title: '操作',
            key: 'action',
            align:'center',
            width:120,
            scopedSlots: { customRender: 'action' },
        },
    ];

    export default {
        data() {
            return {
                // 筛选
                searchForm: {
                    name: "",
                    status: "",
                    templateType: ""
                },
                loading: false,

                showConfig: false,
                openAppealConfig:{
                    openAppealBoolean: false,
                },



                // 数据表格
                data: [],
                cacheData: [],
                columns,
                editingKey: '',
                pagination:{
                    current:1,
                    pageSize:10,
                    total:0,
                    showTotal: (total, range) => `共 ${total} 条`
                },
                tableLoading: false,


                labelCol: { span: 6 },
                wrapperCol: { span: 12 },
                title: "编辑",
                visible: false,
                confirmLoading: false,
                record:{
                    status: 0,
                }

            };
        },
        computed: {
            ...mapGetters('account', ['user']),
        },
        created() {
            this.getStaffAppealList()
            this.showConfig = this.user.nowRoleKey.includes("fen_bao_lao_wu") || this.user.nowRoleKey.includes("zong_bao_lao_wu") || this.user.nowRoleKey.includes("project_leader")
            this.getOpenAppealConfig()
        },
        methods: {
            doReset() {
                this.pagination.current = 1
                this.searchForm = {
                    name: "",
                    status: "",
                    templateType: ""
                }
                this.getStaffAppealList();
            },
            init() {
                this.searchForm.pageNumber = this.pagination.pageNumber = 1
            },
            getStaffAppealList() {
                this.loading = true
                this.tableLoading = true
                getStaffAppealList(this.searchForm).then(res => {
                    this.data = res.data.data.list
                    this.pagination.total = res.data.data.total
                }).finally(()=>{
                    this.loading = false
                    this.tableLoading = false
                })
            },
            getOpenAppealConfig(){
                getOpenAppealConfig().then(res => {
                    this.openAppealConfig = res.data.data
                    this.openAppealConfig.openAppealBoolean= this.openAppealConfig.openAppeal===1
                }).finally(()=>{

                })
            },
            handleSearch(){
                this.searchForm.pageNumber = this.pagination.pageNumber = 1
                this.getStaffAppealList();
            },
            showRecord(record){
                console.log(record,'record===')
                this.record = record
                this.record.status = this.record.status + ""
                this.visible = true
            },
            onPageChange(e){
                this.searchForm.pageNumber = e.current;
                this.pagination.current=e.current;
                this.getStaffAppealList();
            },
            modelOk() {
                setAppealStatus(this.record).then(res => {
                    this.getStaffAppealList();
                }).finally(()=>{
                    this.visible = false
                })
            },
            modelCancel(){
                this.visible = false
            },
            changeOpenAppeal(checked){
                this.openAppealConfig.openAppeal = checked? 1 : 0;
                changeOpenAppeal(this.openAppealConfig).then(res => {
                    this.$message.info(res.data.msg);
                }).finally(()=>{

                })
            }

        }
    };
</script>


<style scoped>
    .ant-advanced-search-form {
        padding: 24px;
        background: #fbfbfb;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        margin-bottom: 10px;
    }

    .ant-advanced-search-form .ant-form-item {
        display: flex;
    }

    .ant-advanced-search-form .ant-form-item-control-wrapper {
        flex: 1;
    }

    #components-form-demo-advanced-search .ant-form {
        max-width: none;
    }

    tr{
        background-color: #ffffff;
    }

</style>
