<template>
  <div class="a_Method">
    <a-row :gutter="16">
      <a-col :span="24">
        <a-card
          title="参建单位绑定考勤设备"
          :bordered="false"
          style="height: 900px"
          :style="'overflow:auto'"
        >
         <!-- <a-checkbox-group
            v-model="selectedDevices"
            name="checkboxgroup"
            :options="deviceList"
            @change="onChange"
          /> -->
          <a-checkbox-group v-model="selectedDevices" @change="onChange">
             <a-row>
              <a-col class="mt-1" :span="24" v-for="device in deviceList" :key="device.value">
                <a-checkbox :value="device.value" >
                  {{ device.label }}
                </a-checkbox>
              </a-col>
             </a-row>
          </a-checkbox-group>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { getDeviceList } from "@/services/dmgr";
import {
  getBindDeviceList,
  getBindDeviceInsert
} from "@/services/participationUnit";
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      query: this.$route.query,
      officeId: this.$route.query.officeId,
      deviceList: [],
      selectedDevices: [],
      selectedList:[]
    };
  },

  activated() {
    this.officeId = this.$route.query.officeId,
    this.getGroupList();
  },
  computed: {
    ...mapGetters('account', ['user']),
  },
  methods: {
    onChange(checkedValues) {
      const newSelectedDevices = checkedValues;
      const removedDevices = this.selectedList.filter(deviceId => !newSelectedDevices.includes(deviceId));
      const addedDevices = newSelectedDevices.filter(deviceId => !this.selectedList.includes(deviceId));
       // 判断哪些设备是新绑定的
       if (addedDevices.length > 0) {
        const params = {
          atteDeviceId: addedDevices[0],
          officeId: this.$route.query.officeId,
          projectId: this.$route.query.projectId ? this.$route.query.projectId : this.$route.query.id,
          type: 2,
          operation: 1
        }
        getBindDeviceInsert(params).then(res => {
          if (res.data.code === 0) {
            this.$message.success(res.data.msg, 5)
            this.getBindDeviceList()
          }
        })
      }
       // 判断哪些设备是取消绑定的
       if (removedDevices.length > 0) {
        const params = {
          atteDeviceId: removedDevices[0],
          officeId: this.$route.query.officeId,
          projectId: this.$route.query.projectId ? this.$route.query.projectId : this.$route.query.id,
          type: 2,
          operation: 0
        }
        getBindDeviceInsert(params).then(res => {
          if (res.data.code === 0) {
            this.$message.success(res.data.msg, 5)
            this.getBindDeviceList()
          }
        })
      }

    },
    getBindDeviceList() {
      const params = {
        officeId: this.$route.query.officeId,
        projectId: this.$route.query.projectId ? this.$route.query.projectId : this.$route.query.id,
        type: 2
      }
      getBindDeviceList(params).then((result) => {
        if (result.data.code === 0) {
          this.selectedDevices = []
          this.selectedList = []
          result.data.data.forEach(item => {
            this.selectedDevices.push( item.atteDeviceId ),
            this.selectedList.push( item.atteDeviceId )
          })
        }
      })
    },
    // 获取班组/单位绑定的设备列表
    getGroupList() {
      // 获取设备列表
      const params = {
        pageNumber: 1,
        pageSize: 100,
        officeId: this.$route.query.officeId,
        projectId: this.$route.query.projectId ? this.$route.query.projectId : this.$route.query.id,
      }
      getDeviceList(params).then(res => {
        if (res.data.code === 0) {
          this.deviceList = [];
          res.data.data.forEach(item => {
            this.deviceList.push({ label: item.deviceName, value: item.id })
          })
        }
      })
      setTimeout(() => {
          this.getBindDeviceList()
      }, 500);
    }
  }
}
</script>

<style lang="less" scoped>
.a_Method {
  // background: #fff;
  // padding: 20px;
  // border-radius: 10px;
}
</style>
