var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "a-card",
        [
          _c(
            "div",
            {
              staticStyle: {
                "background-color": "#FFF",
                "padding-top": "10px",
                padding: "10px 10px",
              },
            },
            [
              _c(
                "div",
                { class: _vm.advanced ? "search" : null },
                [
                  _c("a-form", { attrs: { layout: "horizontal" } }, [
                    _c(
                      "div",
                      { class: _vm.advanced ? null : "fold" },
                      [
                        _c(
                          "a-row",
                          [
                            _c(
                              "a-col",
                              { attrs: { md: 6, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "班组",
                                      labelCol: { span: 3 },
                                      wrapperCol: { span: 18, offset: 1 },
                                    },
                                  },
                                  [
                                    _c(
                                      "a-select",
                                      {
                                        attrs: {
                                          "show-search": "",
                                          placeholder: "请输入或选择班组",
                                          "option-filter-prop": "children",
                                          dropdownStyle:
                                            _vm.dropdownStyleSelect,
                                          "filter-option": _vm.filterOption,
                                        },
                                        on: { change: _vm.handleChange },
                                        model: {
                                          value: _vm.queryParams.teamId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryParams,
                                              "teamId",
                                              $$v
                                            )
                                          },
                                          expression: "queryParams.teamId",
                                        },
                                      },
                                      _vm._l(_vm.teamDatas, function (te) {
                                        return _c(
                                          "a-select-option",
                                          {
                                            key: te.id,
                                            attrs: {
                                              value: te.id,
                                              title: te.teamName,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(te.teamName) + " "
                                            ),
                                          ]
                                        )
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 6, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "姓名",
                                      labelCol: { span: 3 },
                                      wrapperCol: { span: 18, offset: 1 },
                                    },
                                  },
                                  [
                                    _c("a-input", {
                                      attrs: { placeholder: "请输入" },
                                      model: {
                                        value: _vm.queryParams.userName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParams,
                                            "userName",
                                            $$v
                                          )
                                        },
                                        expression: "queryParams.userName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 6, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "入场日期",
                                      labelCol: { span: 6 },
                                      wrapperCol: { span: 16, offset: 1 },
                                    },
                                  },
                                  [
                                    _c("a-range-picker", {
                                      on: { change: _vm.onTimeChange },
                                      model: {
                                        value: _vm.queryParams.inDate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParams,
                                            "inDate",
                                            $$v
                                          )
                                        },
                                        expression: "queryParams.inDate",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 6, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "分配状况",
                                      labelCol: { span: 6 },
                                      wrapperCol: { span: 16, offset: 1 },
                                    },
                                  },
                                  [
                                    _c(
                                      "a-select",
                                      {
                                        model: {
                                          value: _vm.queryParams.inStatus,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryParams,
                                              "inStatus",
                                              $$v
                                            )
                                          },
                                          expression: "queryParams.inStatus",
                                        },
                                      },
                                      [
                                        _c(
                                          "a-select-option",
                                          { attrs: { value: "99" } },
                                          [_vm._v(" 全部 ")]
                                        ),
                                        _c(
                                          "a-select-option",
                                          { attrs: { value: "0" } },
                                          [_vm._v(" 未分配 ")]
                                        ),
                                        _c(
                                          "a-select-option",
                                          { attrs: { value: "1" } },
                                          [_vm._v(" 已分配 ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 6, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  [
                                    _c(
                                      "a-space",
                                      {
                                        style: {
                                          marginLeft: "5px",
                                          marginTop: "4px",
                                        },
                                      },
                                      [
                                        _c(
                                          "a-button",
                                          {
                                            attrs: { type: "primary" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.doQuery()
                                              },
                                            },
                                          },
                                          [_vm._v("查询")]
                                        ),
                                        _c(
                                          "a-button",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.doReset()
                                              },
                                            },
                                          },
                                          [_vm._v("重置")]
                                        ),
                                        _c(
                                          "a-button",
                                          {
                                            attrs: { type: "primary" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.doAllotRoom()
                                              },
                                            },
                                          },
                                          [_vm._v("批量分配宿舍")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c("standard-table", {
                attrs: {
                  columns: _vm.columns,
                  dataSource: _vm.dataSource,
                  pagination: _vm.pagination,
                  loading: _vm.tableLoading,
                  rowKey: "idCard",
                  isAlert: false,
                },
                on: { clear: _vm.onClear, change: _vm.onPageChange },
                scopedSlots: _vm._u([
                  {
                    key: "action",
                    fn: function (ref) {
                      var text = ref.text
                      var record = ref.record
                      return _c(
                        "div",
                        {},
                        [
                          record.isInRoom <= 0
                            ? _c(
                                "a",
                                {
                                  attrs: { type: "setting" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.roomChange(record)
                                    },
                                  },
                                },
                                [_vm._v("房间分配")]
                              )
                            : _vm._e(),
                          record.isInRoom <= 0
                            ? _c("a-divider", { attrs: { type: "vertical" } })
                            : _vm._e(),
                          record.isInRoom > 0
                            ? _c(
                                "a",
                                {
                                  staticStyle: { color: "blue" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.bakRoom(record)
                                    },
                                  },
                                },
                                [_vm._v("退房")]
                              )
                            : _vm._e(),
                          record.isInRoom > 0
                            ? _c("a-divider", { attrs: { type: "vertical" } })
                            : _vm._e(),
                          record.isInRoom > 0
                            ? _c(
                                "a",
                                {
                                  staticStyle: { color: "red" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.roomChange(record)
                                    },
                                  },
                                },
                                [_vm._v("换房")]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("person-allot", {
            attrs: { visible: _vm.allotVisible },
            on: {
              modelCancel: _vm.modelCancel,
              doAllotRoom: _vm.doAllotRoomSubmit,
            },
          }),
          _c(
            "a-modal",
            {
              attrs: { title: _vm.Title, visible: _vm.visible, footer: null },
              on: { cancel: _vm.roomChangeColse },
            },
            [
              _c(
                "p",
                [
                  _c(
                    "a-form",
                    { attrs: { layout: "horizontal" } },
                    [
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            { attrs: { md: 24, sm: 24 } },
                            [
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    label: "宿舍楼",
                                    labelCol: { span: 5 },
                                    wrapperCol: { span: 18, offset: 1 },
                                  },
                                },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      staticStyle: { width: "200px" },
                                      attrs: {
                                        "show-search": "",
                                        placeholder: "请输入或选择宿舍楼",
                                        "option-filter-prop": "children",
                                        defaultValue: this.queryParams2.buildId,
                                        defaultActiveFirstOption: true,
                                        dropdownStyle: _vm.dropdownStyleSelect,
                                        "filter-option": _vm.filterOption,
                                      },
                                      on: { change: _vm.handleChange2 },
                                    },
                                    _vm._l(_vm.buildData, function (te) {
                                      return _c(
                                        "a-select-option",
                                        { key: te.id, attrs: { value: te.id } },
                                        [_vm._v(" " + _vm._s(te.name) + " ")]
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("standard-table", {
                    attrs: {
                      columns: _vm.columnsRoom,
                      dataSource: _vm.dataSource2,
                      pagination: _vm.pagination2,
                      loading: _vm.tableLoading2,
                      rowKey: "id",
                      isAlert: false,
                      tableSize: "small",
                    },
                    on: { change: _vm.onPageChange2 },
                    scopedSlots: _vm._u([
                      {
                        key: "action",
                        fn: function (ref) {
                          var text = ref.text
                          var record = ref.record
                          return _c(
                            "div",
                            {},
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    disabled: _vm.dis(record),
                                    type: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.modelOk(record)
                                    },
                                  },
                                },
                                [_vm._v(" 分配 ")]
                              ),
                            ],
                            1
                          )
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }