<template>
  <div class="create_index">
    <!-- 表单封装
            projectObj：下拉弹出框第一项对象
            Overview：项目名称
            baiduMapBoolen：控制地图显示
            topSubmit：控制上部分按钮显示
            bottomSubmit：控制下部分按钮显示
            handleSubmit：表单提交
            handleChange：输入出发
            handlSelect：输入下拉选择触发
            defaultFileList：上传图片回显链接
            query：路由传过来的数据
        -->
    <form-index
      ref="formindex"
      :formData="formData"
      :projectList="projectList"
      Overview="新增参建班组"
      :bottomSubmit="true"
      @handleSubmit="handleSubmit"
      @handlSelect="handlSelect"
      @handleChange="handleChange"
    >
      <!-- 上部分按钮插槽 -->
      <template #bottomSubmit>
        <a-space>
          <a-button @click="close"> 关闭 </a-button>
          <a-button type="primary" html-type="submit"> 确定 </a-button>
        </a-space>
      </template>
    </form-index>
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import {
  saveTeamInfo,
  getProAllOfficeList,
} from "@/services/participationUnit";
import { request } from "@/utils/request";
import { mapGetters } from "vuex";
import { getOfficeTypeList } from "@/services/sys";
import { QUERY_JOBTITLE_ALL } from "@/services/api";
import { debounce } from "lodash";
import { getProjContacts } from "@/services/projectManagement";

const formData = [
  {
    label: "班组名称",
    placeholder: "请选择工种和填写班组长姓名",
    decorator: [
      "teamName",
      { rules: [{ required: true, message: "请选择工种和填写班组长名称" }] },
    ],
    type: "input",
    key: "teamName",
    labelCol: 5,
    wrapperCol: 17,
    col: 12,
    display: true,
    disabled: true,
  },
  {
    label: "工种",
    placeholder: "请选择工种",
    decorator: [
      "postId",
      { rules: [{ required: true, message: "请选择工种" }] },
    ],
    type: "select",
    key: "postId",
    selectlist: [],
    labelCol: 5,
    wrapperCol: 17,
    col: 12,
    display: true,
    showSearch: true
  },

  {
    label: "班组长姓名",
    placeholder: "请输入班组长姓名",
    decorator: [
      "teamLeader",
      { rules: [{ required: true, message: "请输入班组长姓名" },
      { required: true, message: "最长不超过12位汉字" ,pattern:/(^[\u4e00-\u9fa5]{1,12}$)/}] },
    ],
    type: "input",
    key: "teamLeader",
    labelCol: 5,
    wrapperCol: 17,
    col: 12,
    display: true,
    maxLength: 13
  },
  {
    label: "班组长身份证",
    placeholder: "请输入班组长身份证号码",
    decorator: [
      "remarks",
      {
        rules: [
          {
            required: true,
            message: "请输入正确的班组长身份证号码",
            pattern: new RegExp(
              /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/
            ),
          },
        ],
      },
    ],
    type: "input",
    key: "remarks",
    labelCol: 5,
    wrapperCol: 17,
    col: 12,
    display: true,
  },
  {
    label: "班组长联系电话",
    placeholder: "请输入班组长联系电话",
    decorator: [
      "phoneNumber",
      { rules: [{ required: true, message: "请输入班组长联系电话" }] },
    ],
    type: "input",
    key: "phoneNumber",
    labelCol: 5,
    wrapperCol: 17,
    col: 12,
    display: true,
    maxLength: 11
  },
  // {
  //   label: "所属单位",
  //   placeholder: "请输入所属单位",
  //   decorator: [
  //     "officeId",
  //     { rules: [{ required: true, message: "请输入所属单位" }] },
  //   ],
  //   type: "inputGroup",
  //   key: "officeId",
  //   dataSource: [],
  //   labelCol: 5,
  //   wrapperCol: 17,
  //   col: 12,
  //   display: true,
  // },
  {
    label: "所属单位",
    placeholder: "请输入所属单位",
    decorator: [
      "officeId",
      { rules: [{ required: true, message: "请输入所属单位" }] },
    ],
    type: "select",
    key: "officeId",
    dataSource: [],
    selectlist:[],
    labelCol: 5,
    wrapperCol: 17,
    col: 12,
    display: true,
  },
  // {
  //   label: "业务负责人",
  //   placeholder: "请输入业务负责人",
  //   decorator: [
  //     "leader",
  //     { rules: [{ required: true, message: "请输入业务负责人" }] },
  //   ],
  //   type: "input",
  //   key: "leader",
  //   labelCol: 5,
  //   wrapperCol: 17,
  //   col: 12,
  //   display: true,
  // },
  // {
  //   label: "负责人联系电话",
  //   placeholder: "请输入业务负责人联系电话",
  //   decorator: [
  //     "leaderPhoneNumber",
  //     { rules: [{ required: true, message: "请输入业务负责人联系电话" }] },
  //   ],
  //   type: "input",
  //   key: "leaderPhoneNumber",
  //   labelCol: 5,
  //   wrapperCol: 17,
  //   col: 12,
  //   display: true,
  // },
  {
    label: "班组工作内容",
    placeholder: "请输入班组工作内容",
    decorator: [
      "content",
      { rules: [{ required: false, message: "请输入班组工作内容" }] },
    ],
    type: "input",
    key: "content",
    labelCol: 5,
    wrapperCol: 17,
    col: 12,
    display: true,
    maxLength: 50
  },
  {
    label: "合同密钥",
    placeholder: "请选择合同密钥",
    decorator: [
      "contractId",
      { rules: [{ required: false, message: "请选择合同密钥" }] },
    ],
    type: "select",
    key: "contractId",
    selectlist: [],
    labelCol: 5,
    wrapperCol: 17,
    col: 12,
    display: true,
  },
  {
    label: "建委编码",
    placeholder: "请输入班组建委编码",
    decorator: [
      "jwTeamCode",
      { rules: [{ required: false, message: "请输入建委编码" }] },
    ],
    type: "input",
    key: "jwTeamCode",
    labelCol: 5,
    wrapperCol: 17,
    col: 12,
    display: true,
    maxLength: 50
  },
];
export default {
  data() {
    return {
      projectArr: [],
      projectList: [], //获取当前项目数据
      formData: formData,
      jobTitles: [],
    };
  },
  inject: ["fatherMethod"],
  components: {
    formIndex,
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  created() {
    console.log(this.user, "user");
    this.userObj = this.user;
    this.initData();
  },
  methods: {
    // 获取公司数组
    initData() {
      request(QUERY_JOBTITLE_ALL, "get", {}).then((res) => {
        console.log(res.data.data, "查询所有工种");
        if (res.data.code === 0) {
          const data = res.data.data;
          const arr = [];
          data.forEach((item) => {
            // 剔除没有工种名字的选项
            if (item.carft) {
              arr.push({
                name: item.carft,
                value: item.id,
              });
            }
          });
          this.jobTitles = arr;
          this.formDataFn("postId", arr);
        }
      });
      this.getgcglbList();
      // this.getZdata(getProAllOfficeList, {value: '', item: {key: 'officeId'}}, "officeName");
      this.getProjContacts()
    },
    // 关闭当前页面
    close() {
      let path = this.$route.path;
      this.fatherMethod(`${path}`);
    },
    handleChange(e) {
      if (e.item.key == 'officeId') {
        if (!e.value) {
          this.$refs["formindex"].setFieldsValue('', {}, "officeId");
        }
        this.getZdata(getProAllOfficeList, e, "officeName");
      }
    },
    // 接口封装
    /**
     * msg 接收接口名称
     * e 数据
     * name 参数名
     * */
     getZdata(msg, e, name) {
      let data = {
        [name]: e.value,
        projectId: this.user.projectId
      };

      msg(data).then((res) => {
        if (res.status == 200) {
          this.projectArr = [];
          const data = res.data.data.list ? res.data.data.list : res.data.data
          data.forEach((item) => {
            this.projectArr.push({
              title: item[name],
              id: item.id,
            });
          });
          this.projectList = data;
          console.log(this.projectList, 'this.projectList')
          // 获取当前匹配key，赋值相对于数组
          this.formData.forEach((item) => {
            if (e.item.key === item.key) {
              item.dataSource = this.projectArr;
            }
          });
        }
      });
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      this.formData.forEach((item) => {
        if (item.key == key) {
          item.selectlist = data;
        }
      });
    },
    // 特殊数据处理
    handlSelect({ val, keys }) {
      console.log(val, keys, "val====keys ====");
      this.jobTitles.forEach((item) => {
        if (keys == "postId" && item.value == val) {
          console.log(item, "postId ====");
          console.log(this.formData, "this.formData==");
          let obj = this.$refs["formindex"].getFieldValue();
          this.$refs["formindex"].setFieldsValue(
            item.name,
            { teamName: obj["teamLeader"] + item.name },
            "teamName"
          );
        } else if (keys === "officeId") {
          this.projectList.forEach((item) => {
            if (val == item.id) {
              this.$refs["formindex"].setFieldsValue(
                item.officeId,
                {},
                "officeId"
              );
            } 
          });
        }
      });
    },
    // 合同密钥
    getProjContacts() {
      getProjContacts({}).then(res => {
        if (res.data.code === 0) {
          const data = res.data.data;
          const arr = [];
          data.forEach((item) => {
            arr.push({
              name: item.name,
              value: item.id,
            });
          });
          this.formDataFn("contractId", arr);
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 获取工程管理部数据
    getgcglbList() {
      getProAllOfficeList({ projectId: this.user.projectId }).then((res) => {
        if (res.data.code === 0) {
          const data = res.data.data;
          const arr = [];
          data.forEach((item) => {
            arr.push({
              name: item.officeName,
              value: item.officeId,
            });
          });
          this.formDataFn("officeId", arr);
        }
      });
    },
    // 提交表单数据
    handleSubmit:debounce(function (e) {
      // 绑定班组名称 = 班组长姓名 和 工种信息
      this.jobTitles.forEach((item) => {
        if (item.value == e.postId) {
          e.teamName = e.teamLeader + item.name;
        }
      });
      let params = {
        ...e,
        // officeId: this.userObj.officeId,
        projectId: this.userObj.projectId,
        // idCard: e.teamIdCard
      };
      saveTeamInfo(params).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("新增成功", 10);
          this.$refs["formindex"].resetFields();
          this.close();
          this.$router.push({
            name: "参建班组",
            query: {
              isOnline: 0
            }
          })
        } else {
          this.$message.error(res.data.msg, 10);
        }
      });
    },1000),
  },
};
</script>
<style lang="less" scoped>
.create_index {
  padding: 24px;
  background: #fff;
  border-radius: 6px;
  margin-bottom: 10px;
}
</style>
