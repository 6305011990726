import { render, staticRenderFns } from "./staticss.vue?vue&type=template&id=0db603b7&scoped=true&"
import script from "./staticss.vue?vue&type=script&lang=js&"
export * from "./staticss.vue?vue&type=script&lang=js&"
import style0 from "./staticss.vue?vue&type=style&index=0&id=0db603b7&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0db603b7",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\admin\\Desktop\\SJ\\【劳务机施】suijian_client\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0db603b7')) {
      api.createRecord('0db603b7', component.options)
    } else {
      api.reload('0db603b7', component.options)
    }
    module.hot.accept("./staticss.vue?vue&type=template&id=0db603b7&scoped=true&", function () {
      api.rerender('0db603b7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/safeview/staticss.vue"
export default component.exports