<template>
  <div class="create_index">
    <!-- 表单封装
            projectObj：下拉弹出框第一项对象
            Overview：项目名称
            baiduMapBoolen：控制地图显示
            topSubmit：控制上部分按钮显示
            bottomSubmit：控制下部分按钮显示
            handleSubmit：表单提交
            handleChange：输入出发
            handlSelect：输入下拉选择触发
            defaultFileList：上传图片回显链接
            query：路由传过来的数据
        -->
    <form-index
      :chageT="true"
      ref="formindex"
      :formData="formData"
      :projectList="projectList"
      Overview="新增参建单位"
      :bottomSubmit="true"
      @handleSubmit="handleSubmit"
      @handlSelect="handlSelect"
      @handleChange="handleChange"
    >
      <!-- 上部分按钮插槽 -->
      <template #bottomSubmit>
        <a-button icon="save" type="primary" html-type="submit"> 确定 </a-button>
        <a-space class="operator">
          <a-button icon="close" @click="close"> 关闭 </a-button>
        </a-space>
      </template>
    </form-index>
    <!-- <a-modal
        :title="title"
        :visible.sync="visible"
        @ok="modelOk"
        @cancel="modelCancel"
        width="800px"
      >
        <form-index
        key="formModal"
          ref="formModal"
          :projectList="projectList"
          :formData="formModal"
          :baiduMapBoolen="false"
          @handleChange="handleChange"
          @handlSelect="handlSelect"
          @resourceUpload="resourceUpload"
          @upRemove="upRemove"
        >
        </form-index>
      </a-modal> -->
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import { mdpSaveOffices } from "@/services/participationUnit";
import { request } from "@/utils/request";
import { mapGetters } from "vuex";
import { getOfficeTypeList } from "@/services/sys";
import {
  getOfficesList,
} from "@/services/projectManagement";

const formData = [
  {
    label: "单位名称",
    placeholder: "请选择或者输入单位名称",
    decorator: [
      "officeName",
      { rules: [{ required: true, message: "请输入单位名称" }] },
    ],
    type: "inputGroup",
    dataSource: [],
    key: "officeName",
    labelCol: 5,
    wrapperCol: 17,
    col: 12,
    display: true,
  },
  {
    label: "社会信用代码",
    placeholder: "请输入统一社会信用代码",
    decorator: [
      "code",
      { rules: [
        { required: true, message: "请输入统一社会信用代码" },
        { required: true, message: "请正确输入18位社会信用代码" ,pattern:/^[0-9A-Za-z]{18}$/}
      ] },
    ],
    type: "input",
    key: "code",
    labelCol: 5,
    wrapperCol: 17,
    col: 12,
    display: true,
    maxLength: 19
  },
  {
    label: "参建类型",
    placeholder: "请选择参建类型",
    decorator: [
      "roleType",
      { rules: [{ required: true, message: "请选择参建类型" }] },
    ],
    type: "select",
    selectlist: [],
    key: "roleType",
    labelCol: 5,
    wrapperCol: 17,
    col: 12,
    display: true,
  },
  {
    label: "法定代表人",
    placeholder: "请输入法定代表人姓名",
    decorator: [
      "master",
      { rules: [{ required: true, message: "请输入法定代表人姓名" },{ required: true, message: "最长不超过12位汉字" ,pattern:/(^[\u4e00-\u9fa5]{1,12}$)/}] },
    ],
    type: "input",
    key: "master",
    labelCol: 5,
    wrapperCol: 17,
    col: 12,
    display: true,
    maxLength:13
  },
  {
    label: "现场联系人",
    placeholder: "请输入现场联系人",
    decorator: [
      "xclxr",
      { rules: [{ required: true, message: "请输入现场联系人" },{ required: true, message: "最长不超过12位汉字" ,pattern:/(^[\u4e00-\u9fa5]{1,12}$)/}] },
    ],
    type: "input",
    key: "xclxr",
    labelCol: 5,
    wrapperCol: 17,
    col: 12,
    display: true,
    maxLength:13
  },

  {
    label: "现场联系人电话",
    placeholder: "请输入现场联系人电话",
    decorator: [
      "xclxrPhone",
      {
        rules: [
          {
            required: false,
            message: "手机号格式错误",
            pattern: new RegExp(/^1\d{10}$/),
          },
        ],
      },
    ],
    type: "input",
    key: "xclxrPhone",
    labelCol: 5,
    wrapperCol: 17,
    col: 12,
    display: true,
    maxLength:11
  },
  {
    label: "分包合同编号",
    placeholder: "请输入分包合同编号",
    decorator: [
      "fbhtbh",
      { rules: [{ required: false, message: "请输入分包合同编号" }] },
    ],
    type: "input",
    key: "fbhtbh",
    labelCol: 5,
    wrapperCol: 17,
    col: 12,
    display: true,
    maxLength:31
  },
  {
    label: "合同造价(万元)",
    placeholder: "请输入合同造价",
    decorator: [
      "htzj",
      {
        rules: [
          {
            required: false,
            message: "请输入数字",
            pattern: new RegExp(/^-?[0-9]*(\.[0-9]*)?$/),
          },
          {
            required: false,
            message: "最长不超过8位数",
            pattern: /^\d{1,8}$/,
          },
        ],
      },
    ],
    type: "input",
    key: "htzj",
    labelCol: 5,
    wrapperCol: 17,
    col: 12,
    display: true,
    maxLength: 9
  },
  {
    label: "parentId",
    placeholder: "请输入id",
    decorator: [
      "parentId",
      {
        rules: [
        { required: false, message: "请输入id" }
        ],
      },
    ],
    type: "input",
    key: "parentId",
    labelCol: 0,
    wrapperCol: 0,
    col: 0,
    display: false,
  },
];
export default {
  data() {
    return {
      formData: formData,
      roleTypeName: "",
      roleTypeList: [],
      userObj: {},
      checkModal:false,
      projectList: []
    };
  },
  inject: ["fatherMethod"],
  components: {
    formIndex,
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  created() {
    // 如果是组织机构=>项目库=>项目详情-tabs参建单位-点击跳转新增单位页面传过来projectId
    if (this.$route.query.projectId) {
      this.userObj.projectId = this.$route.query.projectId
    } else {
     this.userObj = this.user;
    }
    this.initData();
  },
  methods: {
    // 关闭当前页面
    close() {
      let path = this.$route.path;
      this.fatherMethod(`${path}`);
    },
    // 获取公司数组
    initData() {
      getOfficeTypeList().then((res) => {
        const data = res.data.data.companyTypeList;
        const arr = [];
        data.forEach((item) => {
          arr.push({
            name: item.label,
            value: item.value,
          });
        });
        this.roleTypeList = arr;
        this.formDataFn("roleType", arr);
      });
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      this.formData.forEach((item) => {
        if (item.key == key) {
          item.selectlist = data;
        }
      });
    },
    // 提交表单数据
    handleSubmit(e) {
      let params = {
        ...e,
        // officeId: e.id, // 项目中添加参建单位, 如果是筛选的单位，直接获取公司ID
        projectId: this.userObj.projectId,
        roleTypeName: this.roleTypeName,
      };
      // 创建时不需要传ID
      delete params.id
      mdpSaveOffices(params).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("新增成功", 10);
          this.$refs["formindex"].resetFields();
          this.close()
        } else {
          this.$message.error(res.data.msg, 10);
        }
      });
    },
    handleChange(e) {
      this.projectArr = [];
      const name = {
        officeName: () => {
          this.getZdata(getOfficesList, e, "name");
        },
      }[e.item.key]();
      this.formData.forEach((item) => {
        if (e.item.key === item.key) {
          item.dataSource = this.projectArr;
        }
      });
    },
    // 下拉框处理
    handlSelect({ val, keys }) {
      if (keys == "roleType") {
        // 提交表单时增加单位类型名称
        this.roleTypeList.forEach((item) => {
          if (val == item.value) {
            this.roleTypeName = item.name;
          }
        });
      }
      if (keys == "officeName") {
        // 提交表单时增加单位类型名称
        console.log(val,keys,'点击单位名称的操作')
        this.projectList.forEach(item => {
               if (item.id == val) {
                console.log(item,'item')
                }
            })
      }

    },
     // 接口封装
    /**
     * msg 接收接口名称
     * e 数据
     * name 参数名
     * */
     getZdata(msg, e, name) {
      let data = {
        [name]: e.value,
      };
      msg(data).then((res) => {
        if (res.status == 200) {
          res.data.data.list.forEach((item) => {
            this.projectArr.push({
              title: item[name],
              id: item.id,
            });
          });
          this.projectList = res.data.data.list;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.create_index {
  padding: 24px;
  background: #FFF;
  border-radius: 6px;
  margin-bottom: 10px;
}
.operator {
    margin-right: 8px;
  }
</style>
