var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-layout-sider",
    {
      class: ["side-menu", "beauty-scroll", _vm.isMobile ? null : "shadow"],
      attrs: {
        theme: _vm.sideTheme,
        width: "256px",
        collapsible: _vm.collapsible,
        trigger: null,
      },
      model: {
        value: _vm.collapsed,
        callback: function ($$v) {
          _vm.collapsed = $$v
        },
        expression: "collapsed",
      },
    },
    [
      _c(
        "div",
        { class: ["logo", _vm.theme] },
        [
          _c(
            "router-link",
            {
              attrs: {
                to:
                  _vm.user.loginType == 1
                    ? "/staff_lib_home/homePage"
                    : "/staff_lib_home/homePage1",
              },
            },
            [
              _vm.isSafe
                ? _c("img", {
                    attrs: { src: require("@/assets/img/logo3.png") },
                  })
                : _vm._e(),
              _vm.isLaowu
                ? _c("img", {
                    attrs: { src: require("@/assets/img/logo.png") },
                  })
                : _vm._e(),
              _vm.isShiZheng
                ? _c("img", {
                    style: { width: _vm.collapsed ? "35px" : "90px" },
                    attrs: {
                      src: _vm.collapsed
                        ? _vm.shizhengLogo
                        : _vm.shizhengLogoFull,
                    },
                  })
                : _vm._e(),
              !_vm.collapsed
                ? _c("h1", [_vm._v(_vm._s(_vm.systemSideName))])
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _c("i-menu", {
        staticClass: "menu",
        attrs: {
          theme: _vm.theme,
          collapsed: _vm.collapsed,
          options: _vm.menuData,
        },
        on: { select: _vm.onSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }