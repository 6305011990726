<template>

    <div>

        <a-modal title="账号关联列表" style="top: 20px;" :width="1400" v-model="visible">
            <a-row>
                <a-button type="primary" @click="add()">
                    新增绑定
                </a-button>
            </a-row>
            <a-table
                    :columns="columns"
                    :data-source="data"
            >
                <template slot="relation" slot-scope="relation,record">
                    <a-tag v-for="(item, index) in record.laoWuUserList" :key="index" >
                        {{ item.loginName }}({{item.roleName}})
                    </a-tag>
                </template>
                <template slot="action" slot-scope="text, record">
                                <span>
                                  <a @click="edit(record)">编辑</a>
                                  <a-divider type="vertical" />
                                  <a @click="del(record)">删除</a>
                                </span>
                </template>
            </a-table>
        </a-modal>

        <a-modal
                title="编辑"
                :visible="visibleEdit"
                :confirm-loading="confirmLoading"
                @ok="projectBingUser"
                @cancel="closeEdit"
                width="560px"
        >
            <a-form-model :model="form"
                          :label-col="labelCol"
                          :wrapper-col="wrapperCol"
                          ref="ruleForm"
                          :rules="rules"
                          v-if="visible"
            >
                <a-form-model-item label="搜索绑定新账号" ref="phone" prop="phone">
                    <a-input-search v-model="editSearch.phone" enter-button="点击绑定" @search="searchPhone" />
                </a-form-model-item>

                <a-form-model-item label="绑定新账号" ref="labourUser" prop="labourUser">
                    <template v-for="(labour) in bindLabourUserList">
                        <a-tag :key="labour.phone" :closable="true" @close="() => removeBindLabour(labour)">
                            {{ labour.phone }}
                        </a-tag>
                    </template>
                </a-form-model-item>

                <a-form-model-item label="新劳务用户角色" prop="roleIdList">
<!--                    <a-radio-group :options="newRole" :default-value="form.roleId" @change="changeLabourRole"/>-->
                    <a-select
                            show-search
                            mode="multiple"
                            placeholder="请选择角色"
                            option-filter-prop="children"
                            v-model = "form.roleIdList"
                            :default-value="form.roleIdList"
                            style="width: 200px"
                            :filter-option="filterOption"
                            @focus="handleFocus"
                            @blur="handleBlur"
                            @change="changeLabourRole"
                    >
                        <a-select-option v-for="(item, index) in newRole" :key="index" :value="item.id">
                            {{item.name}}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>

                <a-form-model-item label="搜索绑定旧账号" ref="oldLoginName" prop="oldLoginName">
                    <a-input-search v-model="editSearch.oldLoginName" enter-button="点击绑定" @search="searchLoginName" />
                </a-form-model-item>

                <a-form-model-item label="绑定以下旧账号" ref="laoWuUser" prop="laoWuUser">
                    <template v-for="(laoWuUser) in bindLaoWuUserList">
                        <a-tag :key="laoWuUser.loginName" :closable="true" @close="() => removeBindLoginName(laoWuUser)">
                            {{ laoWuUser.loginName }}
                        </a-tag>
                    </template>
                </a-form-model-item>
            </a-form-model>

        </a-modal>

    </div>
</template>
<script>
    import { getProjectRelationOfficeList, searchLoginNameByProject, projectBingUser, searchPhoneUser, getRoleListBySysUserList,
        getProjectUserList, userDelBindLaoWuUser, delProjectBindUser} from '@/services/sys'
    import pick from 'lodash.pick'
    import { useRender } from '@/hooks/useRender.js'

    const columns = [
        {
            title: '手机号',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: '账号名称',
            dataIndex: 'userName',
            key: 'userName',
        },
        {
            title: '角色',
            dataIndex: 'roleName',
            key: 'roleName',
        },
        {
            title: '关联劳务账号（角色）',
            dataIndex: 'relation',
            scopedSlots: { customRender: 'relation' },
        },
        {
            title: '创建时间',
            key: 'createTime',
            dataIndex: 'createTime',
            customRender: (text) => useRender.renderDate(text)
        },
        {
            title: '操作',
            key: 'action',
            scopedSlots: { customRender: 'action' },
        },
    ];

    export default {
        name: 'RelationAcctModel',
        data () {
            return {
                projectId: "",

                // 数据
                columns,
                data: [],


                visible: false,

                // 详细数据弹窗
                title: "编辑",
                visibleEdit: false,
                confirmLoading: false,
                labelCol: { span: 6 },
                wrapperCol: { span: 12 },
                form:{
                    phone: "",
                    laoWuUserIdList: [],
                    roleId: undefined,
                    roleIdList: []
                },
                bindLaoWuUserList: [],
                bindLabourUserList: [],
                newRole: [],
                oldRole: [],
                rules: {
                    roleIdList: [
                        { required: true, message: '请选择角色', trigger: 'change' },
                    ],
                },
                editSearch:{
                    phone: "",
                    oldLoginName: ""
                },

                // needPayment: [1, ],
                // needBigPlatform:[1,]
            }
        },
        created () {

        },
        methods: {
            relationAcctOpen(projectId) {
                this.visible = true
                this.projectId = projectId
                this.getRelationAcctList()
            },
            showEdit() {
                this.visibleEdit = true

                getRoleListBySysUserList({}).then(res =>{
                    this.newRole = res.data.data.filter((obj) => obj.roleType == 2)
                    this.oldRole = res.data.data.filter((obj) => obj.roleType != 2)

                    this.newRole.forEach(function (element, index, array) {
                        array[index].label = element.name
                        array[index].value = element.id

                    });

                    this.oldRole.forEach(function (element, index, array) {
                        array[index].label = element.name
                        array[index].value = element.id
                    });

                })
            },
            closeEdit(){
                this.visibleEdit = false
            },
            add() {
                this.form = {
                    phone: "",
                    laoWuUserIdList: [],
                    roleId: undefined,
                    roleIdList: []
                }
                this.bindLaoWuUserList = []
                this.bindLabourUserList = []
                this.showEdit()
            },
            edit(record) {
                this.form = record

                for (var index=0; index<record.laoWuUserList.length; index++ ){
                    record.laoWuUserList[index].id = record.laoWuUserList[index].userId
                }
                this.bindLaoWuUserList = record.laoWuUserList
                this.bindLabourUserList = [{
                    phone: record.phone,
                    id: record.userId
                }]

                this.showEdit()
            },
            searchLoginName(){
                searchLoginNameByProject({loginName: this.editSearch.oldLoginName, projectId: this.projectId}).then(res=>{

                    if (res.data.code != 0 ){
                        this.$message.error(res.data.msg);
                        return
                    }
                    const userInfo = res.data.data;
                    const loginName = userInfo.loginName;
                    const userId = userInfo.id;

                    let bindLaoWuUserList = this.bindLaoWuUserList
                    let bindLaoWuUser = this.bindLaoWuUserList.filter(laoWuUser => laoWuUser.id === userId);
                    if (!bindLaoWuUser || Object.keys(bindLaoWuUser).length <= 0 ){
                        bindLaoWuUser = {
                            loginName: loginName,
                            id: userId
                        }
                        bindLaoWuUserList = [...bindLaoWuUserList, bindLaoWuUser];
                    }

                    Object.assign(this, {
                        bindLaoWuUserList,
                    });
                    console.log(this.bindLaoWuUserList)
                })
            },
            searchPhone(){
                searchPhoneUser({phone: this.editSearch.phone, projectId: this.projectId}).then(res => {
                    if (res.data.code !=0 ){
                        this.$message.error(res.data.msg);
                        return
                    }
                    const userInfo = res.data.data;
                    const phone = userInfo.phone;
                    const userId = userInfo.userId;
                    const laoWuUserList = userInfo.laoWuUserList == undefined? [] : userInfo.laoWuUserList;


                    let bindLabourUserList = [{
                        phone: phone,
                        id: userId
                    }]

                    laoWuUserList.forEach(function (element, index, array) {
                        laoWuUserList[index].id = element.userId
                    });

                    Object.assign(this, {
                        bindLabourUserList,
                        bindLaoWuUserList: laoWuUserList,
                    });
                })

            },
            removeBindLoginName(bindLaoWuUser) {
                console.log("bindLaoWuUser", bindLaoWuUser)
                userDelBindLaoWuUser({userId: this.bindLabourUserList[0].id, laoWuUserId: bindLaoWuUser.id }).then(res=>{
                    console.log(res)
                    if (res.data.code === 0){
                        this.$message.info(res.data.msg);
                        const bindLaoWuUserList = this.bindLaoWuUserList.filter(laoWuUser => laoWuUser.id !== bindLaoWuUser.id);
                        Object.assign(this, {
                            bindLaoWuUserList,
                        });
                    }else{
                        this.$message.error(res.data.msg);
                    }
                })


            },
            removeBindLabour(bindLabour){
                const bindLabourUserList = this.bindLabourUserList.filter(LabourUser => LabourUser.id !== bindLabour.id);
                Object.assign(this, {
                    bindLabourUserList,
                    bindLaoWuUserList: []
                });
            },
            projectBingUser() {
                if (this.bindLabourUserList == undefined || this.bindLabourUserList.length <=0 ){
                    this.$message.error("请先选择绑定新账号");
                    return
                }

                if (this.bindLaoWuUserList != undefined && this.bindLaoWuUserList.length >0 ){
                    console.log(this.bindLabourUserList[0])
                    this.form.laoWuUserIdList = this.bindLaoWuUserList.map(function (laoWuUser){
                        return laoWuUser.id
                    })
                }
                this.form.projectId = this.projectId
                this.form.userId = this.bindLabourUserList[0].id

                this.$refs.ruleForm.validate(valid => {
                    if (valid) {
                        projectBingUser(this.form).then(res=>{
                            console.log(res)
                            if (res.data.code === 0){
                                this.$message.info(res.data.msg);
                            }else{
                                this.$message.error(res.data.msg);
                            }
                            this.closeEdit()
                            this.getRelationAcctList()
                        })
                    } else {
                        this.getRelationAcctList()
                        console.log('error submit!!');
                        return false;
                    }
                });

            },
            getRelationAcctList(){
                getProjectUserList({projectId: this.projectId}).then(res => {
                    console.log(res)
                    this.data = res.data.data
                })
            },
            del(record){
                let laoWuUserId = record.laoWuUserIdList == null || record.laoWuUserIdList.length <=0? "" : record.laoWuUserIdList[0];
                delProjectBindUser({id: record.id, laoWuUserId: laoWuUserId}).then(res => {
                    this.getRelationAcctList()
                })
            },
            changeLabourRole(e){
                // this.form.labourRoleId = e.target.value
                this.form.roleIdList = e
            },
            handleBlur() {
                console.log('blur');
            },
            handleFocus() {
                console.log('focus');
            },
            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.indexOf(input) >= 0
                );
            },


        },
        watch: {
            /*
              'selectedRows': function (selectedRows) {
                this.needTotalList = this.needTotalList.map(item => {
                  return {
                    ...item,
                    total: selectedRows.reduce( (sum, val) => {
                      return sum + val[item.dataIndex]
                    }, 0)
                  }
                })
              }
              */
        }
    }
</script>
