var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    {
      staticClass: "card",
      staticStyle: { "margin-top": "3px", "padding-top": "20px", flex: "1" },
    },
    [
      _c(
        "a-col",
        {
          staticStyle: { height: "100%" },
          attrs: { span: _vm.fatherType === "office" ? 24 : 16 },
        },
        [
          _c(
            "div",
            { staticClass: "relative", staticStyle: { height: "100%" } },
            [
              _c(
                "baidu-map",
                {
                  staticClass: "bmView",
                  attrs: {
                    "scroll-wheel-zoom": true,
                    center: _vm.location,
                    zoom: _vm.zoom,
                  },
                },
                [
                  _c("bm-view", { staticClass: "bmMap" }),
                  _c("bm-local-search", {
                    staticStyle: { display: "none" },
                    attrs: {
                      keyword: _vm.addressKeyword,
                      "auto-viewport": true,
                    },
                  }),
                  _vm._l(_vm.persons, function (items, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        _c(
                          "bm-marker",
                          {
                            attrs: {
                              position: { lng: items.lng, lat: items.lat },
                              icon: {
                                url: items.show ? _vm.localActive : items.icon,
                                size: { width: 28, height: 28 },
                              },
                              dragging: false,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.infoWindowOpen(index, items)
                              },
                            },
                          },
                          [
                            _vm.fatherType === "office"
                              ? _c(
                                  "bm-info-window",
                                  {
                                    attrs: { show: items.show },
                                    on: {
                                      close: function ($event) {
                                        return _vm.infoWindowClose(index)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "info-window" },
                                      [
                                        _c("a-popover", [
                                          _c(
                                            "div",
                                            {
                                              attrs: { slot: "content" },
                                              slot: "content",
                                            },
                                            [_vm._v(_vm._s(items.name))]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "ellipsis-2" },
                                            [
                                              _vm._v(
                                                "项目名称： " +
                                                  _vm._s(items.name)
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "line-height": "30px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " 项目经理：" +
                                                _vm._s(
                                                  _vm.staffObj.xmjl
                                                    ? _vm.staffObj.xmjl
                                                    : 0
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c("div", [
                                          _vm._v(
                                            " 联系电话：" +
                                              _vm._s(
                                                _vm.staffObj.xmjlPhone
                                                  ? _vm.staffObj.xmjlPhone
                                                  : 0
                                              ) +
                                              " "
                                          ),
                                        ]),
                                        _vm.canOperate
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "onInfo",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onInfo(
                                                      items,
                                                      _vm.staffObj
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" 查看详情 ")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
              _vm.fatherType === "office"
                ? _c(
                    "div",
                    { staticClass: "pro-box" },
                    [
                      _c(
                        "a-row",
                        { attrs: { type: "flex", justify: "space-around" } },
                        _vm._l(_vm.proList, function (item, index) {
                          return _c(
                            "a-col",
                            {
                              key: item.key + index,
                              staticClass: "tab-box",
                              class: _vm.proActive == index ? "pro-active" : "",
                              on: {
                                click: function ($event) {
                                  return _vm.onProAction(index, item)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "font-24",
                                  staticStyle: { "line-height": "35px" },
                                },
                                [_vm._v(_vm._s(item.value))]
                              ),
                              _c(
                                "div",
                                { staticStyle: { "line-height": "20px" } },
                                [_vm._v(_vm._s(item.title))]
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.fatherType === "office"
                ? _c(
                    "div",
                    { staticClass: "unit-box" },
                    [
                      _c("label", { staticClass: "unit-label" }, [
                        _vm._v("单位："),
                      ]),
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "260px" },
                          attrs: { "default-value": _vm.defaultUnit },
                          on: { change: _vm.onHandleUnitChange },
                          model: {
                            value: _vm.defaultUnit,
                            callback: function ($$v) {
                              _vm.defaultUnit = $$v
                            },
                            expression: "defaultUnit",
                          },
                        },
                        _vm._l(_vm.unitList, function (items, id) {
                          return _c(
                            "a-select-option",
                            { key: id, attrs: { value: items.id } },
                            [_vm._v(" " + _vm._s(items.name) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm.fatherType === "project"
        ? _c("a-col", { staticStyle: { height: "100%" }, attrs: { span: 8 } }, [
            _c(
              "div",
              { staticStyle: { height: "100%", padding: "0 5px 0 20px" } },
              [
                _c(
                  "div",
                  { staticClass: "info-window relative" },
                  [
                    _c("div", { staticClass: "pb-2 " }),
                    _c(
                      "div",
                      { staticClass: "ellipsis-2 black font-16 font-bold" },
                      [_vm._v(_vm._s(_vm.staffObj.projectName))]
                    ),
                    _c(
                      "a-tag",
                      {
                        staticClass: "mt-1",
                        attrs: {
                          color: _vm.getTagStatus(_vm.staffObj.status, "color"),
                        },
                      },
                      [_vm._v(_vm._s(_vm.getTagStatus(_vm.staffObj.status)))]
                    ),
                    _vm.getTagName(_vm.staffObj.ptype)
                      ? _c("a-tag", { attrs: { color: "#F7A22C" } }, [
                          _vm._v(_vm._s(_vm.getTagName(_vm.staffObj.ptype))),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "ellipsis-1 font-bold mt-2 mb-2",
                        staticStyle: {
                          "line-height": "30px",
                          color: "#212121",
                          "font-size": "14px",
                        },
                      },
                      [
                        _c("div", [
                          _vm._v(
                            "工程造价：" +
                              _vm._s(_vm.staffObj.projBudget || "暂无") +
                              " "
                          ),
                          _vm.staffObj.projBudget
                            ? _c("span", [_vm._v("万元")])
                            : _vm._e(),
                        ]),
                        _c("div", [
                          _vm._v(
                            "是否报建：" +
                              _vm._s(_vm.staffObj.isReport ? "是" : "否")
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            "项目经理：" + _vm._s(_vm.staffObj.xmjl || "暂无")
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            "联系电话：" +
                              _vm._s(_vm.staffObj.xmjlPhone || "暂无")
                          ),
                        ]),
                      ]
                    ),
                    _c("a-divider"),
                    _c("a-tooltip", [
                      _c(
                        "div",
                        {
                          staticClass: "black font-bold ellipsis-2",
                          staticStyle: {
                            "font-size": "14px",
                            "-webkit-line-clamp": "5",
                          },
                        },
                        [
                          _vm._v(
                            "项目概况：" +
                              _vm._s(_vm.staffObj.projDesc || "暂无")
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
              ]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }