var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        { attrs: { gutter: 16 } },
        [
          _c(
            "a-col",
            { attrs: { span: 15 } },
            [
              _c("warning-status"),
              _c("project-map", {
                staticStyle: { "margin-top": "15px" },
                attrs: { officeList: _vm.officeList },
              }),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 9 } },
            [
              _c("dangerous-project", {
                attrs: { officeList: _vm.officeList },
              }),
              _c("project-inspection", {
                staticStyle: { "margin-top": "15px" },
                attrs: { officeList: _vm.officeList },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }