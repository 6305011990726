<template>
  <div class="container">
    <form-index
      ref="formindex"
      :formData="formData"
      @handleSubmit="handleSubmit"
      :flexSubmit="true"
      @handlSelect="handlSelect"
      :bottomSubmit="true"
      Overview="访客记录"
    >
      <!-- 下部分按钮插槽 -->
      <template #flexSubmit>
       
      </template>
    </form-index>
    <div class="a_a_footer">
      <div>
        <a-space class="operator">
          <a-button @click="doDowloadBatch(1)">导出</a-button>
          <a-button @click="doDowloadBatch(3)">刷新</a-button>
        </a-space>
        <a-space>
          <a-button @click="close"> 重置 </a-button>
          <a-button type="primary" html-type="submit"> 查询 </a-button>
        </a-space>

      </div>
      <!-- 表格封装 -->
      <standard-table
        class=""
        :columns="columns"
        :dataSource="dataSource"
        rowKey="id"
        :pagination="pagination"
        :loading="tableLoading"
        @change="onPageChange"
        :isAlert="false"
        :bordered="true"
      >
        <template slot="title">
          <h3 class="table-title">test</h3>
          <div class="table-desc">
            <span>项目名称（盖章）：湛江农垦第一机械有限公司 三旧 改造项目</span>
            <span>单位：测试单位01 班组： 全部</span>
          </div>
        </template>
        <template slot="index" slot-scope="{ index }">
          <span>
            {{
              (pagination.current - 1) * pagination.pageSize +
                parseInt(index) +
                1
            }}
          </span>
        </template>
      </standard-table>
    </div>
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
const formData = [
  {
    label: "月份",
    placeholder: "请选择",
    decorator: [
      "workerName",
      { rules: [{ required: false, message: "请选择" }] },
    ],
    type: "monthPicker",
    key: "workerName",
    col: 6,
    labelCol: 3,
    wrapperCol: 12,
    display: true,
    format: "yyyy-MM",
  },
  {
    label: "所属单位",
    placeholder: "请填写",
    decorator: [
      "unitId",
      { rules: [{ required: false, message: "请填写" }] },
    ],
    type: "select",
    key: "unitId",
    selectlist: [],
    col: 6,
    labelCol: 4,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "班组名称",
    placeholder: "请选择",
    decorator: ["teamId", { rules: [{ required: false, message: "请选择" }] }],
    type: "select",
    key: "teamId",
    selectlist: [],
    col: 6,
    labelCol: 4,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "闸机名称",
    placeholder: "请填写",
    decorator: [
      "uploadStatus",
      { rules: [{ required: false, message: "请填写" }] },
    ],
    type: "select",
    key: "uploadStatus",
    col: 6,
    labelCol: 4,
    wrapperCol: 12,
    display: true,
  },
];
const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
  },
  {
    title: "姓名",
    dataIndex: "workName",
    width: 65,
    align: "center",
    scopedSlots: { customRender: "workName" },
  },
  {
    title: "合计",
    dataIndex: "workerFace",
    align: "center",
    width: 65,
    scopedSlots: { customRender: "workerFace" },
  }
];
export default {
  components: {
    formIndex,
    StandardTable,
  },
  data() {
    return {
      formData: formData,
      columns: columns,
      dataSource: [], //table数组
      selectedRows: [], //选中的数据
      pagination: {
        //分页数据
        current: 1,
        pageSize: 10,
        total: 0,
      },
      params: { //测试数据
        pageNumber: 1,
        pageSize: 10,
        enterStatus: '1'
      },
      tableLoading: false, //table加载中
    };
  },

  mounted() {
    for (let i = 1; i < 31; i++) {
      columns.push({
        title: i,
        dataIndex: i,
        align: "center",
        scopedSlots: { customRender: i },
      })
    }
  },

  methods: {
    
  },
};
</script>

<style lang="less" scoped>
.a_a_footer {
  background: #ffffff;

  .operator {
    margin-right: 8px;

    .ant-btn {
      color: #307dfa;
    }
  }

  .table-title {
    text-align: center;
    font-size: 25px;
  }

  .table-desc {
    display: flex;
    justify-content: space-between
  }
  
}
</style>