<template>
    <div>
        <a-card :bordered="false">

            <div >
                <a-form :form="searchForm" >
                    <a-row :gutter="24">
                        <a-col :span="5" >
                            <a-form-item :label="`设备编码`"  :labelCol="{span: 7}" :wrapperCol="{span: 16, offset: 1}">
                                <a-input v-model="searchForm.deviceImei" placeholder="请输入设备编码" />
                            </a-form-item>
                        </a-col>
                        <a-col :span="5" >
                            <a-form-item :label="`设备类型`"  :labelCol="{span: 7}" :wrapperCol="{span: 16, offset: 1}">
                                <a-select
                                        v-model="searchForm.deviceType"
                                >
                                    <a-select-option key="" value="">
                                        全部
                                    </a-select-option>
                                    <a-select-option key="1" value="1">
                                        塔吊
                                    </a-select-option>
                                    <a-select-option key="2" value="2">
                                        升降机
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>

                        <a-col :span="4" :style="{marginTop: '3px'}">
                            <a-space>
                                <a-button type="primary" icon="search" :loading="loading" @click="handleSearch">
                                查询
                                </a-button>
                                <a-button icon="plus" type="primary" @click="add()">
                                新增
                                </a-button>
                            </a-space>
                        </a-col>
                    </a-row>

                    <a-row>

                    </a-row>

                </a-form>
            </div>
            <a-table
                    :columns="columns"
                    :data-source="data"
                    :bordered="true"
            >
            <!-- deviceType -->
                <!-- <template slot="action" slot-scope="text, record">
                    <div>

                    </div>
                </template> -->
                <template slot="action" slot-scope="text, record"><span v-if="record.editable">
                      <a @click="() => editSave(record.id)">保存</a>
                      <a-divider type="vertical" />
                      <a-popconfirm title="是否取消保存?" @confirm="() => editCancel(record.id)">
                        <a>取消</a>
                      </a-popconfirm>
                    </span>
                    <span v-else>
                      <a @click="() => edit(record)">编辑</a>
                      <a-divider type="vertical" />
                      <a @click="del(record)">删除</a>
                    </span>
                </template>
            </a-table>
        </a-card>

        <div>
            <a-modal
                    :title="title"
                    :visible="visible"
                    :confirm-loading="confirmLoading"
                    @ok="modelOk"
                    @cancel="modelCancel"
                    width="560px"
            >
                <a-form-model :model="form"
                              :label-col="labelCol"
                              :wrapper-col="wrapperCol"
                              ref="ruleForm"
                              :rules="rules"
                              v-if="visible"
                >
                    <a-form-model-item label="设备编码" ref="deviceImei" prop="deviceImei"  required>
                        <a-input v-model="form.deviceImei" />
                    </a-form-model-item>
                    <a-form-model-item label="设备类型">
                        <a-select
                                v-model="form.deviceType"
                        >
                            <a-select-option key="1" :value="1">
                                塔吊
                            </a-select-option>
                            <a-select-option key="2" :value="2">
                                升降机
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>


                </a-form-model>
            </a-modal>
        </div>
    </div>



</template>

<script>
    import { getProjectDeviceList, insertProjectDevice, delProjectDevice, updateProjectDevice } from '@/services/wisdomsite'
    import {ESS_TEMPL_RECIPIENT_SAVE,} from '@/services/api'
    import {request} from '@/utils/request'
    import pick from 'lodash.pick'
    import {mapGetters} from 'vuex'
    import { useRender } from '@/hooks/useRender.js'
    const columns = [
        {
            dataIndex: 'deviceImei',
            key: 'deviceImei',
            align:'center',
            title: '设备编码',
            slots: { title: 'deviceImei' },
            scopedSlots: { customRender: 'deviceImei' },
        },
        {
            title: '设备类型',
            dataIndex: 'deviceType',
            key: 'deviceType',
            align:'center',
            slots: { title: 'deviceType' },
            scopedSlots: { customRender: 'deviceType' },
        },
        {
            title: '所属项目',
            dataIndex: 'projectId',
            key: 'projectId',
            align:'center',
            slots: { title: 'projectId' },
            scopedSlots: { customRender: 'projectId' },
        },
        {
            title: '所属公司',
            dataIndex: 'officeId',
            key: 'officeId',
            align:'center',
            slots: { title: 'officeId' },
            scopedSlots: { customRender: 'officeId' },
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            align:'center',
            key: 'createTime',
            customRender: (text) => useRender.renderDate(text)
        },
        {
            title: '更新时间',
            key: 'updateTime',
            align:'center',
            dataIndex: 'updateTime',
            customRender: (text) => useRender.renderDate(text)
        },
        {
            title: '操作',
            align:'center',
            key: 'action',
            scopedSlots: { customRender: 'action' },
        },
    ];

    export default {
        data() {
            return {
                // 筛选
                searchForm: {
                    deviceImei: "",
                    status: "",
                    deviceType: ""
                },
                loading: false,

                // 数据表格
                data: [],
                cacheData: [],
                columns,
                editingKey: '',

                // 详细数据弹窗
                title: "编辑",
                visible: false,
                confirmLoading: false,
                labelCol: { span: 6 },
                wrapperCol: { span: 12 },
                form:{
                    status: "0",
                },
                rules: {
                    deviceImei: [
                        { required: true, message: '请输入设备编码', trigger: 'blur' },
                    ],
                    deviceType: [{ required: true, message: '请选择设备类型', trigger: 'blur' }],
                },
                customTemplateList: []


            };
        },
        computed: {
            ...mapGetters('account', ['user']),
        },
        created() {

            console.log(this.user)
            this.getProjectDeviceList()
        },
        methods: {
            getProjectDeviceList() {
                getProjectDeviceList(this.searchForm).then(res => {
                    this.data = res.data.data
                })
            },
            handleSearch(){
                this.getProjectDeviceList();
            },
            showModal(record) {
                this.visible = true
                this.form = record;
            },
            add(){
                this.showModal({status:"0", deviceType: 1})
            },
            modelOk(){
                const _this = this
                // 触发表单验证
                this.$refs.ruleForm.validate(valid => {
                    if (valid) {
                        this.form.projectId = this.user.projectId
                        this.form.officeId = this.user.officeId
                        this.confirmLoading = true
                        insertProjectDevice(this.form).then(res => {
                            this.getProjectDeviceList()
                            this.visible = false;
                            this.confirmLoading = false;
                            return true;
                        })
                    } else {
                        this.$message.error("请检查必填信息");
                        this.confirmLoading = false;
                    }
                })
            },
            modelCancel(e) {
                this.visible = false;
            },
            edit(record) {
                this.showModal(record)
            },
            editSave(key) {
                this.loading = true
                this.tableLoading = true
                updateProjectDevice(this.form).then(res=>{

                }).finally(()=>{
                    this.loading = false
                    this.tableLoading = false
                    this.getProjectDeviceList()
                })
            },
            del(record){
                var _that = this
                this.$confirm({
                    title: '确认删除' + record.deviceImei + "?",
                    content: '要删除该设备吗？',
                    okText: '删除',
                    cancelText: '取消',
                    onOk() {
                        delProjectDevice({id: record.id}).then(res => {
                            if (res.data.code === 0) {
                            _that.$message.info('操作成功');
                            _that.getProjectDeviceList()
                            } else {
                                _that.$message.info('操作失败');
                            }
                        })
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            },

        }
    };
</script>


<style scoped>
    .ant-advanced-search-form {
        padding: 24px;
        background: #fbfbfb;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        margin-bottom: 10px;
    }

    .ant-advanced-search-form .ant-form-item {
        display: flex;
    }

    .ant-advanced-search-form .ant-form-item-control-wrapper {
        flex: 1;
    }

    #components-form-demo-advanced-search .ant-form {
        max-width: none;
    }

    tr{
        background-color: #ffffff;
    }

</style>
