var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("div", { staticClass: "img-box" }, [
            _c("img", { attrs: { src: _vm.staffLib.appPhotoPath, alt: "" } }),
            _c("p", [_vm._v(_vm._s(_vm.staffLib.name))]),
            _c("p", [_vm._v(_vm._s(_vm.staffLib.gender))]),
            _c("p", [_vm._v(_vm._s(_vm.staffLib.nation))]),
            _c("p", [_vm._v(_vm._s(_vm.workAge) + "岁")]),
            _vm.staffLib.moreYears
              ? _c("p", { staticStyle: { color: "red" } }, [_vm._v("超龄")])
              : _vm._e(),
            _c(
              "p",
              {
                staticClass: "check",
                on: {
                  click: function ($event) {
                    return _vm.modalCheck(
                      "实时头像",
                      "",
                      "",
                      _vm.staffLib.actualPath
                    )
                  },
                },
              },
              [_vm._v("实时头像")]
            ),
            _c(
              "p",
              {
                staticClass: "check",
                on: {
                  click: function ($event) {
                    return _vm.modalCheck("身份证正反面", "", "", [
                      _vm.staffLib.facePath,
                      _vm.staffLib.backPath,
                    ])
                  },
                },
              },
              [_vm._v("身份证正反面")]
            ),
          ]),
          _c(
            "a-descriptions",
            { attrs: { bordered: "", column: 3 } },
            [
              _c("a-descriptions-item", { attrs: { label: "身份证号码：" } }, [
                _vm._v(_vm._s(_vm.staffLib.idCard)),
              ]),
              _c("a-descriptions-item", { attrs: { label: "出生：" } }, [
                _vm._v(_vm._s(_vm.formatDate(_vm.staffLib.birthday))),
              ]),
              _c("a-descriptions-item", { attrs: { label: "住址：" } }, [
                _vm._v(_vm._s(_vm.staffLib.location)),
              ]),
              _c("a-descriptions-item", { attrs: { label: "现住地址：" } }, [
                _vm._v(_vm._s(_vm.staffLib.residence)),
              ]),
              _c("a-descriptions-item", { attrs: { label: "工人住宿：" } }, [
                _vm._v(_vm._s(_vm.staffDormitory)),
              ]),
              _c("a-descriptions-item", { attrs: { label: "籍贯：" } }, [
                _vm._v(
                  _vm._s(
                    _vm.staffLib.nationality
                      ? _vm.staffLib.nationality
                      : _vm.staffLib.nativePlace +
                          _vm.staffLib.nativeCity +
                          _vm.staffLib.nativeArea
                  )
                ),
              ]),
              _c(
                "a-descriptions-item",
                { attrs: { label: "是否购买工伤保险：" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.staffLib.hasBuyinjuryInsurance == "true" ? "是" : "否"
                    )
                  ),
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: "是否加入工会：" } },
                [
                  _vm._v(
                    _vm._s(_vm.staffLib.isLabourUnion == "true" ? "是" : "否")
                  ),
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: "是否退役军人：" } },
                [_vm._v(_vm._s(_vm.staffLib.soldier))]
              ),
              _c("a-descriptions-item", { attrs: { label: "签发机关：" } }, [
                _vm._v(_vm._s(_vm.staffLib.signOrgan)),
              ]),
              _c("a-descriptions-item", { attrs: { label: "有效期限：" } }, [
                _vm._v(
                  _vm._s(_vm.formatDate(_vm.staffLib.expiryStart)) +
                    " 至 " +
                    _vm._s(
                      _vm.staffLib.expiryEnd
                        ? _vm.formatDate(_vm.staffLib.expiryEnd)
                        : "长期"
                    )
                ),
              ]),
              _c("a-descriptions-item", { attrs: { label: "常住地址：" } }, [
                _vm._v(_vm._s(_vm.staffLib.location)),
              ]),
              _c("a-descriptions-item", { attrs: { label: "工号：" } }, [
                _vm._v(_vm._s(_vm.teamStaffRelation.projWorkNum)),
              ]),
              _c("a-descriptions-item", { attrs: { label: "政治面貌：" } }, [
                _vm._v(_vm._s(_vm.political)),
              ]),
              _c("a-descriptions-item", { attrs: { label: "文化程度：" } }, [
                _vm._v(_vm._s(_vm.staffLib.education)),
              ]),
              _c("a-descriptions-item", { attrs: { label: "手机号：" } }, [
                _vm._v(_vm._s(_vm.staffLib.phoneNumber)),
              ]),
              _c("a-descriptions-item", { attrs: { label: "应急手机号：" } }, [
                _vm._v(_vm._s(_vm.staffLib.emergencyTel)),
              ]),
              _c("a-descriptions-item", { attrs: { label: "技能水平：" } }, [
                _vm._v(_vm._s(_vm.teamStaffRelation.techlevelText)),
              ]),
              _c("a-descriptions-item", { attrs: { label: "所属单位：" } }, [
                _vm._v(_vm._s(_vm.teamStaffRelation.officeName)),
              ]),
              _c("a-descriptions-item", { attrs: { label: "类别：" } }, [
                _vm._v("作业工人"),
              ]),
              _c("a-descriptions-item", { attrs: { label: "班组：" } }, [
                _vm._v(_vm._s(_vm.teamStaffRelation.teamName)),
              ]),
              _c("a-descriptions-item", { attrs: { label: "工种：" } }, [
                _vm._v(_vm._s(_vm.teamStaffRelation.workTypeName)),
              ]),
              _c("a-descriptions-item", { attrs: { label: "用工形式：" } }, [
                _vm._v(_vm._s(_vm.contractTypeStr)),
              ]),
              _c("a-descriptions-item", { attrs: { label: "ID卡号：" } }, [
                _vm._v(_vm._s(_vm.teamStaffRelation.icCardNumber)),
              ]),
              _c(
                "a-descriptions-item",
                { attrs: { label: "入场安全教育情况：" } },
                [
                  _vm.teamStaffRelation.aqdjPath
                    ? _c(
                        "span",
                        {
                          staticClass: "check",
                          on: {
                            click: function ($event) {
                              return _vm.modalCheck(
                                "入场安全教育情况",
                                "安全教育情况",
                                "已接受入场安全教育培训",
                                _vm.teamStaffRelation.aqdjPath
                              )
                            },
                          },
                        },
                        [_vm._v("查看")]
                      )
                    : _c("span", [_vm._v("已完成")]),
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: "劳动合同情况：" } },
                [
                  _vm.teamStaffRelation.contactNumber
                    ? _c(
                        "span",
                        {
                          staticClass: "check",
                          on: {
                            click: function ($event) {
                              return _vm.modalCheck(
                                "劳动合同情况",
                                "劳动合同编号",
                                _vm.teamStaffRelation.contactNumber,
                                _vm.teamStaffRelation.contactAttachment
                              )
                            },
                          },
                        },
                        [_vm._v("查看")]
                      )
                    : _c("span", [_vm._v("暂无数据")]),
                ]
              ),
              _c("a-descriptions-item", { attrs: { label: "离场凭证图：" } }, [
                _vm.teamStaffRelation.quitPhoto
                  ? _c(
                      "span",
                      {
                        staticClass: "check",
                        on: {
                          click: function ($event) {
                            return _vm.modalCheck(
                              "离场凭证图",
                              "",
                              "",
                              _vm.teamStaffRelation.quitPhoto
                            )
                          },
                        },
                      },
                      [_vm._v("预览")]
                    )
                  : _c("span", [_vm._v("暂无数据")]),
              ]),
              _c("a-descriptions-item", { attrs: { label: "开户支行：" } }, [
                _vm._v(_vm._s(_vm.teamStaffRelation.salaryName)),
              ]),
              _c("a-descriptions-item", { attrs: { label: "工资卡账号：" } }, [
                _vm._v(_vm._s(_vm.teamStaffRelation.salaryAccount)),
              ]),
              _c("a-descriptions-item", { attrs: { label: "工资卡附件：" } }, [
                _vm.teamStaffRelation.salaryCardAttachment
                  ? _c(
                      "span",
                      {
                        staticClass: "check",
                        on: {
                          click: function ($event) {
                            return _vm.modalCheck(
                              "工资卡附件",
                              "",
                              "",
                              _vm.teamStaffRelation.salaryCardAttachment
                            )
                          },
                        },
                      },
                      [_vm._v("查看")]
                    )
                  : _c("span", [_vm._v("暂无数据")]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.modalTitle,
            destroyOnClose: true,
            width: 800,
            footer: null,
          },
          on: { cancel: _vm.modalCancel },
          model: {
            value: _vm.modalVisible,
            callback: function ($$v) {
              _vm.modalVisible = $$v
            },
            expression: "modalVisible",
          },
        },
        [
          _c(
            "a-descriptions",
            { attrs: { bordered: "", column: 1 } },
            [
              _vm.modalSubTitle
                ? _c(
                    "a-descriptions-item",
                    { attrs: { label: _vm.modalSubTitle } },
                    [_vm._v(" " + _vm._s(_vm.modalContent) + " ")]
                  )
                : _vm._e(),
              _c("a-descriptions-item", { attrs: { label: "附件" } }, [
                _vm.attachmentUrl_2
                  ? _c("img", {
                      staticClass: "attachment-img",
                      attrs: { src: _vm.attachmentUrl_2, alt: "" },
                    })
                  : _vm._e(),
                _vm.attachmentUrl
                  ? _c("span", [
                      _vm.checkFileType(_vm.attachmentUrl) == "image" ||
                      (_vm.attachmentUrl.indexOf("contractPath") == -1 &&
                        _vm.attachmentUrl.indexOf("attachment") == -1 &&
                        _vm.attachmentUrl.indexOf("CONTRACT") == -1 &&
                        _vm.attachmentUrl.indexOf("THREE_AQJY_TEPL_ALL") ==
                          -1 &&
                        _vm.attachmentUrl.indexOf("LEAVE_PROJECT_DOCUMENT") ==
                          -1)
                        ? _c("img", {
                            staticClass: "attachment-img",
                            attrs: { src: _vm.attachmentUrl, alt: "" },
                          })
                        : _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.attachmentUrl,
                                target: "_blank",
                              },
                            },
                            [_vm._v("查看附件")]
                          ),
                    ])
                  : _c("span", [_vm._v("暂未上传")]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }