var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", {
      class: ["mask", _vm.visible ? "open" : "close"],
      on: { click: _vm.close },
    }),
    _c(
      "div",
      { class: ["drawer", _vm.placement, _vm.visible ? "open" : "close"] },
      [
        _c(
          "div",
          { ref: "drawer", staticClass: "content beauty-scroll" },
          [_vm._t("default")],
          2
        ),
        _vm.showHandler
          ? _c(
              "div",
              {
                ref: "handler",
                class: [
                  "handler-container",
                  _vm.placement,
                  _vm.visible ? "open" : "close",
                ],
                on: { click: _vm.toggle },
              },
              [
                _vm.$slots.handler
                  ? _vm._t("handler")
                  : _c(
                      "div",
                      { staticClass: "handler" },
                      [
                        _c("a-icon", {
                          attrs: { type: _vm.visible ? "close" : "bars" },
                        }),
                      ],
                      1
                    ),
              ],
              2
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }