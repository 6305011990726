<template>
  <div>
    <!-- 表单封装
      projectObj：下拉弹出框第一项对象
      Overview：项目名称
      baiduMapBoolen：控制地图显示
      topSubmit：控制上部分按钮显示
      bottomSubmit：控制下部分按钮显示
      handleSubmit：表单提交
      handleChange：输入出发
      handlSelect：输入下拉选择触发
    -->
    <form-index
      ref="formindex"
      :formData="formData"
      @handleSubmit="handleSubmit"
      :flexAction="true"
    >
      <!-- 下部分按钮插槽 -->
      <template #flexAction>
          <a-space :style="{marginTop: '4px'}">
            <a-button icon="search" type="primary" html-type="submit"> 查询 </a-button>
            <a-button @click="close"> 重置 </a-button>
          </a-space>
      </template>
    </form-index>
      <div style="margin-bottom: 5px;">
        <a-button v-if="user.loginType == 0 && canOperate" icon="plus" style="margin-right: 10px;" type="primary" @click="addNewDevice">新增</a-button>
        <a-space class="operator">
          <!-- <a-button @click="doDowloadBatch(2)">批量下发权限</a-button> -->
          <a-button v-if="canOperate" @click="regionManagement">区域管理</a-button>
          <a-button 
        v-if="canOperate"
        @click="distribute2"
        ghost
        type="primary"
        icon="cloud-download"
        >下发考勤设备</a-button>
        </a-space>
      </div>
      <!-- 表格封装 -->
      <standard-table
        class=""
        :columns="columns"
        :dataSource="dataSource"
        :selectedRows.sync="selectedRows"
        rowKey="id"
        :pagination="pagination"
        :loading="tableLoading"
        @change="onPageChange"
        :isAlert="false"
        :bordered="true"
        @update:selectedRows="updateSelectRows($event)"
        tableSize="middle"
      >
        <template slot="index" slot-scope="{ index }">
          <span>
            {{
              (pagination.current - 1) * pagination.pageSize +
                parseInt(index) +
                1
            }}
          </span>
        </template>
        <template slot="online" slot-scope="{ record }">
          <span>{{ Number(record.online) ? '在线' : record.manufacturerKey === 'HK' ? '/' : '不在线' }}</span>
        </template>
        <template slot="inOutFlag" slot-scope="{ record }">
          <span>{{ record.inOutFlag == 1 ? '进入' : '出去' }}</span>
        </template>
        <template slot="manufacturerKey" slot-scope="{ record }">
          <span>{{ record.manufacturerKey == 'yunqi' ? '云启' : record.manufacturerKey == 'HK' ? '海康' : '' }}</span>
        </template>
        <template slot="distinguishInOut" slot-scope="{ record }">
          <span>{{ record.distinguishInOut == '1' ? '区分' : '不区分' }}</span>
        </template>
        <template slot="isAttend" slot-scope="{ record }">
          <span>{{ record.isAttend == '1' ? '是' : '否' }}</span>
        </template>
        <template slot="action" slot-scope="{ record }">
          <div v-if="canOperate">
            <a @click="operation(1, record)" v-show="record.manufacturerKey === 'HK'">同步</a>
            <a-divider type="vertical" v-show="record.manufacturerKey === 'HK'"/>
            <a @click="operation(2, record)"> 修改 </a>
            <a-divider type="vertical"/>
            <a style="color: #ff5c77" @click="operation(3, record)"> 删除 </a>
          </div>
        </template>
      </standard-table>
    <a-modal v-model="addNewDeviceDialog" title="新增设备"
      :destroyOnClose="true"
      :width="1100" :maskClosable="false" :body-style="{height:'370px', overflowY: 'auto'}" @ok="handleAddNewDeviceSubmit" @cancel="cancelModal">
      <form-index
        ref="addNewDeviceFormIndex"
        :formData="addNewDeviceFormData"
        :options="options"
        @handlSelect="handlDeviceSelect"
      >
      </form-index>
    </a-modal>
    <distribute-dialog
      :visible="distributeVisible"
      @cancel="distributeVisible = false"
      :isDel="false"
      :modalTitle="'下发考勤设备'"
      :workNumList="userIdList"/>
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import { getGroupList, addGroup, delGroup, addDevice, getDeviceList, delDevice, updateDevice } from '@/services/dmgr'
import { syncMachineToHk } from "@/services/participationUnit";
import { treeData } from '@/utils/treeutil'
import { mapGetters } from 'vuex'
import DistributeDialog from '@/pages/attendanceManagement/dialogs/distributeDialog';
const inOutFlag = {
  label: "进出方向",
  placeholder: "请选择",
  decorator: ["inOutFlag", { rules: [{ required: true, message: "请选择" }] }],
  type: "radio",
  radiolist: [
    {
      name: "进入",
      value: 1,
    },
    {
      name: "出去",
      value: 2,
    },
  ],
  key: "inOutFlag",
  col: 12,
  labelCol: 6,
  wrapperCol: 14,
  display: true,
}
const formData = [
  {
    label: "区域",
    placeholder: "请选择",
    decorator: [
      "groupName",
      { rules: [{ required: false, message: "请选择" }] },
    ],
    type: "input",
    key: "groupName",
    col: 5,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "设备名称",
    placeholder: "请填写",
    decorator: ["deviceName", { rules: [{ required: false, message: "请填写" }] }],
    type: "input",
    key: "deviceName",
    col: 5,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "设备品牌",
    placeholder: "请选择",
    decorator: [
      "manufacturerKey",
      { rules: [{ required: false, message: "请选择" }] },
    ],
    type: "select",
    selectlist: [
      {
        name: "海康",
        value: "HK",
      },
      {
        name: "云启",
        value: "YUNQI",
      },
      {
        name: "PDA",
        value: "PDA",
      },
      {
        name: "中控",
        value: "ZK",
      },
    ],
    key: "manufacturerKey",
    col: 5,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  // {
  //   label: "在线状态",
  //   placeholder: "请选择",
  //   decorator: [
  //     "online",
  //     { rules: [{ required: false, message: "请选择" }] },
  //   ],
  //   type: "select",
  //   key: "online",
  //   selectlist: [{
  //     name: "/",
  //     value: "",
  //   },
  //   {
  //     name: "在线",
  //     value: "1",
  //   },
  //   {
  //     name: "离线",
  //     value: "0",
  //   },],
  //   col: 5,
  //   labelCol: 6,
  //   wrapperCol: 14,
  //   display: true,
  // },
  {
    type: "action",
    col: 2,
    display: true,
  },
];
const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
  },
  {
    title: "区域",
    dataIndex: "groupName",
    align: "center",
    scopedSlots: { customRender: "groupName" },
  },
  {
    title: "考勤设备名称",
    dataIndex: "deviceName",
    align: "center",
    scopedSlots: { customRender: "deviceName" },
  },
  {
    title: "设备在线状态",
    dataIndex: "online",
    align: "center",
    scopedSlots: { customRender: "online" },
  },
  {
    title: "设备品牌",
    align: "center",
    dataIndex: "manufacturerKey",
    scopedSlots: { customRender: "manufacturerKey" },
  },
  {
    title: "设备编号",
    dataIndex: "deviceCode",
    align: "center",
    ellipsis: true,
    scopedSlots: { customRender: "deviceCode" },
  },
  {
    title: "设备IP",
    dataIndex: "deviceIp",
    align: "center",
    ellipsis: true,
    scopedSlots: { customRender: "deviceIp" },
  },
  {
    title: "进出方向",
    dataIndex: "inOutFlag",
    align: "center",
    scopedSlots: { customRender: "inOutFlag" },
  },
  {
    title: "是否区分进出方向",
    dataIndex: "distinguishInOut",
    align: "center",
    scopedSlots: { customRender: "distinguishInOut" },
  },
  {
    title: "是否为考勤机",
    dataIndex: "isAttend",
    align: "center",
    scopedSlots: { customRender: "isAttend" },
  },
  {
    title: "操作",
    align: "center",
    fixed: "right",
    width: 160,
    scopedSlots: { customRender: "action" },
  },
];
const addNewDeviceFormData = [
  {
    label: "区域名称",
    placeholder: "请选择",
    decorator: [
      "groupId",
      { rules: [{ required: true, message: "请选择" }] },
    ],
    type: "cascader",
    key: "groupId",
    changeOnSelect: true,
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "设备名称",
    placeholder: "请输入",
    decorator: ["deviceName", { rules: [{ required: true, message: "请输入" }] }],
    type: "input",
    key: "deviceName",
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "设备品牌",
    placeholder: "请选择",
    decorator: ["manufacturerKey", { rules: [{ required: true, message: "请选择" }] }],
    type: "select",
    selectlist: [
      {
        name: "海康",
        value: "HK",
      },
      {
        name: "云启",
        value: "YUNQI",
      },
      {
        name: "PDA",
        value: "PDA",
      },
      {
        name: "中控",
        value: "ZK",
      },
    ],
    key: "manufacturerKey",
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "设备编号",
    placeholder: "请输入",
    decorator: ["deviceCode", { rules: [{ required: true, message: "请输入" }] }],
    type: "input",
    key: "deviceCode",
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
    disabled: false
  },
  {
    label: "设备ip",
    placeholder: "请输入",
    decorator: ["deviceIp", { rules: [{ required: true, message: "请输入" }] }],
    type: "input",
    key: "deviceIp",
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "是否区分进出方向",
    placeholder: "请选择",
    decorator: ["distinguishInOut", { initialValue: 0, rules: [{ required: true, message: "请选择" }] }],
    type: "radio",
    key: "distinguishInOut",
    radiolist: [
      {
        name: "区分",
        value: 1,
      },
      {
        name: "不区分",
        value: 0,
      },
    ],
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "是否设备直连",
    placeholder: "请选择",
    decorator: ["isDirect", { rules: [{ required: true, message: "请选择" }] }],
    type: "radio",
    key: "isDirect",
    radiolist: [
      {
        name: "是",
        value: 1,
      },
      {
        name: "否",
        value: 0,
      },
    ],
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "是否为考勤机",
    placeholder: "请选择",
    decorator: ["isAttend", { rules: [{ required: true, message: "请选择" }] }],
    type: "radio",
    key: "isAttend",
    radiolist: [
      {
        name: "是",
        value: 1,
      },
      {
        name: "否",
        value: 0,
      },
    ],
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "项目ID",
    placeholder: "项目ID",
    decorator: ["projectId", { rules: [{ required: false, message: "项目ID" }] }],
    type: "input",
    key: "projectId",
    col: 0,
    labelCol: 0,
    wrapperCol: 0,
    display: false,
  },
];
export default {
  components: {
    formIndex,
    StandardTable,
    DistributeDialog
  },
  data() {
    return {
      formData: formData,
      columns: columns,
      dataSource: [], //table数组
      selectedRows: [], //选中的数据
      params: {
        pageNumber: 1,
        pageSize: 10,
      },
      pagination: {
        showSizeChanger:true,
        showQuickJumper: true,
        //分页数据
        current: 1,
        pageSize: 10,
        total: 0,
        pageSizeOptions: ['10', '20', '30', '50'],
        showTotal: (total, range) => `共 ${total} 条`
      },
      tableLoading: false, //table加载中
      options: [], //新增设备配置项
      queryData:{ //新增设备区域名称请求参数
        projectId: "",
      },
      fatherGroupId: "",//选中的fatherGroupId
      addNewDeviceDialog: false,
      addNewDeviceFormData: addNewDeviceFormData,
      isModalEdit: false,
      changeRecordId: '',
      arrRoles:['zong_bao_lao_wu',], // 操作权限
      userIdList:[],
      distributeVisible: false,
    };
  },

  created() {
    this.getGroupList()
    this.getDeviceList(this.params)
  },
  computed: {
    ...mapGetters('account', ['user']),
    canOperate() {
      return this.user.nowRoleKey.some(role => this.arrRoles.includes(role));
    },
  },
  methods: {
    updateSelectRows(e) {
      console.log(e)
    },
    //区域管理
    regionManagement() {
      this.$router.push({
        name: "考勤区域管理",
      });
    },
    addNewDevice() {
      this.addNewDeviceFormData.forEach(el => {
        if (el.key == 'deviceCode') {
          el.disabled = false
        }
      })
      this.isModalEdit = false
      this.addNewDeviceDialog = true
    },
    getDeviceList(params) {
      this.tableLoading = true
      getDeviceList(params).then(res => {
        this.tableLoading = false
        this.selectedRows = []
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list
          this.pagination.total = res.data.data.total
          } else {
            this.$message.error(res.data.msg)
          }
      })
    },
    getGroupList() {
      this.queryData.projectId = this.user.projectId
      getGroupList(this.queryData).then(res => {
          let list = treeData(res.data.data, 'groupId', 'fatherGroupId', 'children', "0");
          // this.regionDataSource = list
          this.options = list.map(el => this.mapTree(el))
      });
    },
    handlDeviceSelect(e) {
      const { keys, val } = e
      if (keys === 'distinguishInOut') {
        const value = val.target.value
        if (value == 0 && this.addNewDeviceFormData.some(el => el.key == 'inOutFlag')) {
          this.addNewDeviceFormData.pop()
        } else {
          this.addNewDeviceFormData.push(inOutFlag)
        }
      }
    },
    cancelModal() {
      // 修复关闭新增弹窗删除表单问题
      if (this.addNewDeviceFormData.length > 9) {
        this.addNewDeviceFormData.pop()
      }
    },
    handleAddNewDeviceSubmit() {
      this.$refs.addNewDeviceFormIndex.combinationSubmit().then(res => {
        res.groupId = res.groupId[res.groupId.length - 1]
        if (this.isModalEdit) {
          res.id = this.changeRecordId
          updateDevice(res).then(el => {
            if (el.data.code === 0) {
              this.$message.success('修改设备成功！')
              this.changeRecordId = ''
              this.addNewDeviceDialog = false
              this.getDeviceList(this.params)
            } else {
              this.$message.error(el.data.msg)
            }
          })
        } else {
          addDevice(res).then(el => {
            if (el.data.code === 0) {
              this.$message.success('新增设备成功！')
              this.addNewDeviceDialog = false
              this.getDeviceList(this.params)
            } else {
              this.$message.error(el.data.msg)
            }
          })
        }
      })
    },
    //更多操作
    operation(key, record) {
      if (key === 1) {
        syncMachineToHk({ deviceCode: record.deviceCode }).then(res => {
          if (res.data.code === 0) {
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
        })
      } else if (key === 2) {
        console.log(record,'修改参数')
        this.changeRecordId = record.id
        this.isModalEdit = true
        this.addNewDeviceDialog = true
        // console.log(this.options)
        record.groupId = record.fatherGroupIdList
        if (record.distinguishInOut == 1) {
          this.addNewDeviceFormData.push(inOutFlag)
        }
        this.addNewDeviceFormData.forEach(el => {
          if (el.key == 'deviceCode') {
            el.disabled = true
          }
        })
        this.$nextTick(() => {
          this.$refs.addNewDeviceFormIndex.queryObj(record)
        })
      } else if (key === 3) {
        const _that = this
        this.$confirm({
          title: '确定删除设备吗？',
          onOk() {
            delDevice([record.id]).then(res => {
              if (res.data.code === 0) {
                _that.$message.success('删除成功！')
                _that.getDeviceList(_that.params)
              } else {
                _that.$message.error(res.data.msg)
              }
            })
          }
        })
      }
    },
    mapTree (org) {
      const haveChildren = Array.isArray(org.children) && org.children.length > 0;
      return {
        //分别将我们查询出来的值做出改变他的key
        value: org.groupId,
        label: org.groupName,
        //判断它是否存在子集，若果存在就进行再次进行遍历操作，知道不存在子集便对其他的元素进行操作
        children: haveChildren ? org.children.map(i => this.mapTree(i)) : []
      };
    },
    handleSubmit(e) {
      this.params = Object.assign(this.params, e)
      this.getDeviceList(this.params);
    },
    //表格 - 分页查询
    onPageChange(e) {
      console.log(e,'e=======')
      this.pagination.current = e.current;
      this.pagination.pageSize = e.pageSize;
      this.params.pageSize = e.pageSize
      this.params.pageNumber = e.current;
      this.getDeviceList(this.params);
    },
    close() {
      console.log('重置')
      this.prams = {
        pageNumber: 1,
        pageSize: 10,
      };
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.getDeviceList(this.prams);
      this.$refs["formindex"].resetFields();
    },
    //下发考勤设备
    distribute2() {
      if (this.selectedRows.length > 0) {
        this.userIdList = []//先初始化这个list
        this.selectedRows.forEach(el => {
          this.userIdList.push(el.deviceCode)
        })
        this.distributeVisible = true
      } else {
        this.$message.error('请选择设备')
      }
    },
  },
};
</script>

<style lang="less" scoped>
.a_a_footer {
  margin-top: 10px;
  padding: 20px;
  background: #ffffff;

  .operator {
    margin-right: 8px;

    .ant-btn {
      color: #307dfa;
    }
  }

}
.sTables {
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    width: 100%;
    text-align: center;
    cursor: pointer;
  }

  div:nth-child(1) {
    color: #307dfa;
  }

  div:nth-child(2) {
    color: #ff5c77;
  }

  div:nth-child(3) {
    color: #615e83;
  }
}
</style>
