<template>
  <div>
    <div class="constructiontTable_index">
      <form-index
        class="form"
        ref="formindex"
        :projectList="projectList"
        :formData="formData"
        Overview="企业库管理"
        :baiduMapBoolen="false"
        @handleSubmit="handleSubmit"
        @handleChange="handleChange"
        @handlSelect="handlSelect"
        :flexSubmit="true"
      >
        <!-- 下部分按钮插槽 -->
        <template #flexSubmit>
          <a-space :style="{ marginTop: '4px' }">
            <a-button type="primary" html-type="submit"> 查询 </a-button>
            <a-button @click="close"> 重置 </a-button>
          </a-space>
        </template>
      </form-index>
      <a-space class="operator">
        <a-button type="primary" @click="createItem"> 新建企业 </a-button>
      </a-space>
      <standard-table
        :columns="columns"
        :dataSource="dataSource"
        row-key="id"
        :scroll="{ x: 1300 }"
        :pagination="pagination"
        :loading="tableLoading"
        @change="onPageChange"
        :bordered="true"
      >
        <template slot="index" slot-scope="{ index }">
          <span>
            {{
              (pagination.current - 1) * pagination.pageSize +
              parseInt(index) +
              1
            }}
          </span>
        </template>
        <template slot="name" slot-scope="{ text, record }">
          <a-tooltip placement="topLeft">
            <template slot="title"
              ><span>{{ text }}</span></template
            >
            <span class="ant-btn-text ellipsis" @click="toUnits(record)">{{
              text
            }}</span>
          </a-tooltip>
        </template>
        <div slot="phone" slot-scope="{ text }">
          <span>{{ text ? text : "/" }}</span>
        </div>
        <template slot="mainBusiness" slot-scope="{ text }">
          <span v-if="text">
            <span v-for="(item, index) in text" :key="index">
              <a-tag class="mt-1">{{ item }}</a-tag>
            </span>
          </span>
          <span v-else>/</span>
        </template>
        <div slot="userName" slot-scope="{ record }">
          <div v-if="record.userName">
            <div>{{ record.userName }}</div>
            <span v-if="record.userPhone">({{ record.userPhone }})</span>
          </div>
        </div>
        <div slot="action" slot-scope="{ record }">
          <a-dropdown placement="bottomCenter" class="sTables">
            <div>
              <span>操作</span>
              <a-icon
                style="margin-left: 2px; font-size: 12px"
                type="caret-down"
              />
            </div>
            <a-menu :class="['avatar-menu']" slot="overlay">
              <a-menu-item @click="viewEditing(record, 'edit')">
                <a-icon type="edit" />
                <span>修改</span>
              </a-menu-item>
              <a-menu-item @click="recorDelete(record)">
                <a-icon type="delete" />
                <span>删除</span>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </standard-table>
      <Export-loading
        :cPercent="cPercent"
        :isExporting="isExporting"
        :loadingText="loadingText"
      ></Export-loading>

      <a-modal
        :title="title"
        :visible.sync="visible"
        @ok="modelOk"
        @cancel="modelCancel"
        width="800px"
      >
        <form-index
          ref="formModal"
          :projectList="projectList"
          :formData="formModal"
          :baiduMapBoolen="false"
          @handleChange="handleChange"
          @handlSelect="handlSelect"
          @resourceUpload="resourceUpload"
          @upRemove="upRemove"
        >
        </form-index>
      </a-modal>
    </div>
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import {
  getOfficesList,
  getOfficeDetail,
  delOffice,
  addOffice,
  editOffice,
  getOfficeTree,
  addOfficeMaster,
} from "@/services/projectManagement";
import { exportProgress } from "@/pages/aqjyzl/export"; // 导出方法
import ExportLoading from "@/components/ExportLoading/ExportLoading"; // progress组件
import { mapGetters } from "vuex";
import { getOfficeTypeList } from "@/services/sys";
import { debounce } from "lodash";
import { useRender } from '@/hooks/useRender.js'

const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
  },
  {
    title: "公司名称",
    dataIndex: "name",
    width: 245,
    // ellipsis: true,
    scopedSlots: { customRender: "name" },
    // fixed: "left",
  },
  {
    title: "负责人",
    dataIndex: "master",
    align: "center",
    width: 65,
    key: "master",
  },
  {
    title: "联系电话",
    dataIndex: "phone",
    align: "center",
    width: 80,
    scopedSlots: { customRender: "phone" },
  },
  {
    title: "成立日期",
    dataIndex: "startDate",
    align: "center",
    width: 80,
    customRender: (text) => useRender.renderDate(text, 'YYYY-MM-DD')
  },
  {
    title: "注册资金(万)",
    dataIndex: "capital",
    align: "center",
    width: 80,
    scopedSlots: { customRender: "phone" },
  },
  // {
  //   title: "录入人",
  //   dataIndex: "creatorName",
  //   align: "center",
  //   width: 80,
  //   scopedSlots: { customRender: "mainBusiness" },
  // },
  {
    title: "主营业务",
    dataIndex: "mainBusiness",
    align: "center",
    width: 120,
    scopedSlots: { customRender: "mainBusiness" },
  },
  {
    title: "参建项目数量",
    dataIndex: "count",
    align: "center",
    width: 80,
  },
  // 测试新增单位002-2
  // {
  //   title: "单位地址",
  //   align: "center",
  //   width: 100,
  //   dataIndex: "address",
  //   scopedSlots: { customRender: "address" },
  // },
  {
    title: "操作",
    align: "center",
    width: 80,
    // fixed: "right",
    scopedSlots: { customRender: "action" },
  },
];
const formData = [
  {
    label: "公司名称",
    placeholder: "请输入公司名称",
    decorator: [
      "name",
      { rules: [{ required: false, message: "请输入公司名称" }] },
    ],
    type: "inputGroup",
    key: "name",
    dataSource: [],
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
  },
  {
    label: "社会信用代码",
    placeholder: "请输入公司社会信用代码",
    decorator: [
      "code",
      { rules: [{ required: false, message: "请输入公司社会信用代码" }] },
    ],
    type: "input",
    key: "code",
    dataSource: [],
    col: 6,
    labelCol: 8,
    wrapperCol: 14,
  },
  {
    label: "法人代表",
    placeholder: "请输入法人代表",
    decorator: [
      "master",
      { rules: [{ required: false, message: "请输入法人代表" }] },
    ],
    type: "inputGroup",
    key: "master",
    dataSource: [],
    col: 6,
    labelCol: 8,
    wrapperCol: 14,
  },
];
const formModal = [
  {
    label: "公司名称",
    placeholder: "请输入公司名称",
    decorator: [
      "name",
      { rules: [{ required: true, message: "请输入公司名称" }] },
    ],
    type: "input",
    dataSource: [],
    key: "name",
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
  },
  {
    label: "社会信用代码",
    placeholder: "请输入统一社会信用代码",
    decorator: [
      "code",
      { rules: [{ required: true, message: "请输入统一社会信用代码" }] },
    ],
    type: "input",
    key: "code",
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
  },
  {
    label: "单位类型",
    placeholder: "请选择单位类型",
    decorator: [
      "type",
      { rules: [{ required: false, message: "请选择单位类型" }] },
    ],
    type: "select",
    key: "type",
    selectlist: [],
    col: 0,
    labelCol: 0,
    wrapperCol: 0,
    display: false,
  },
  {
    label: "主营业务",
    placeholder: "请选择主营业务(可多选)",
    decorator: [
      "mainBusiness",
      { rules: [{ required: false, message: "请输入主营业务" }] },
    ],
    type: "select",
    key: "mainBusiness",
    mode: "multiple",
    selectlist: [
      {
        value: "1",
        name: "总包",
      },
      {
        value: "2",
        name: "监理",
      },
      {
        value: "3",
        name: "劳务分包",
      },
      {
        value: "4",
        name: "专业分包",
      },
      {
        value: "5",
        name: "老包",
      },
    ],
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },

  // {
  //   label: "上级单位",
  //   placeholder: "请输入上级单位",
  //   decorator: [
  //     "parentId",
  //     { rules: [{ required: false, message: "请选择上级单位" }] },
  //   ],
  //   key: "parentId",
  //   type: "treeSelect",
  //   selectlist: [],
  //   col: 12,
  //   labelCol: 6,
  //   wrapperCol: 14,
  //   display: true,
  // },
  // {
  //   label: "上级单位",
  //   placeholder: "请输入上级单位",
  //   decorator: [
  //     "id",
  //     { rules: [{ required: false, message: "请选择上级单位" }] },
  //   ],
  //   key: "id",
  //   type: "treeSelect",
  //   selectlist: [],
  //   col: 12,
  //   labelCol: 6,
  //   wrapperCol: 14,
  //   display: true,
  // },
  {
    label: "法人代表",
    placeholder: "请输入法人代表",
    decorator: [
      "master",
      { rules: [{ required: true, message: "请输入法人代表" }] },
    ],
    type: "input",
    key: "master",
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "联系电话",
    placeholder: "请输入联系电话",
    decorator: [
      "phone",
      { rules: [{ required: false, message: "请输入联系电话" }] },
    ],
    type: "input",
    key: "phone",
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
  },

  {
    label: "运维到期时间",
    placeholder: "请选择运维到期时间",
    decorator: [
      "dutTime",
      { rules: [{ required: false, message: "请选择运维到期时间" }] },
    ],
    type: "datePicker",
    key: "dutTime",
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
    format: "yyyy-MM-DD",
    styles: "width: 220px",
    display: true,
  },
  {
    label: "id",
    placeholder: "id",
    decorator: ["id", { rules: [{ required: false, message: "id" }] }],
    type: "input",
    key: "id",
    col: 0,
    labelCol: 0,
    wrapperCol: 0,
    display: false,
  },
  {
    label: "注册资金(万)",
    placeholder: "请输入注册资金(万)元",
    decorator: [
      "capital",
      { rules: [{ required: false, message: "请输入注册资金(万)元" }] },
    ],
    type: "input",
    key: "capital",
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "成立时间",
    placeholder: "请选择成立时间",
    decorator: [
      "startDate",
      { rules: [{ required: false, message: "请选择成立时间" }] },
    ],
    type: "datePicker",
    key: "startDate",
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
    format: "yyyy-MM-DD",
    styles: "width: 220px",
  },

  {
    label: "单位地址",
    placeholder: "请输入单位地址",
    decorator: [
      "address",
      { rules: [{ required: false, message: "请输入单位地址" }] },
    ],
    type: "input",
    key: "address",
    col: 24,
    labelCol: 3,
    wrapperCol: 19,
  },
  {
    label: "营业执照",
    title: "上传营业执照",
    placeholder: "请上传营业执照",
    decorator: [
      "licence",
      { rules: [{ required: false, message: "请上传营业执照" }] },
    ],
    type: "tsUpload",
    key: "licence",
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
    defaultFileList: [],
  },
  {
    label: "公司资质附件",
    title: "上传公司资质",
    placeholder: "请上传公司资质",
    decorator: [
      "credential",
      { rules: [{ required: false, message: "请上传公司资质" }] },
    ],
    type: "tsUpload",
    key: "credential",
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
    defaultFileList: [],
  },
  {
    label: "业绩",
    placeholder: "请输入业绩",
    decorator: [
      "achievement",
      { rules: [{ required: false, message: "请输入业绩" }] },
    ],
    type: "textarea",
    key: "achievement",
    col: 24,
    labelCol: 3,
    wrapperCol: 19,
    display: true,
  },
  {
    label: "获得荣誉",
    placeholder: "请输入获得荣誉",
    decorator: [
      "honor",
      { rules: [{ required: false, message: "请输入获得荣誉" }] },
    ],
    type: "textarea",
    key: "honor",
    col: 24,
    labelCol: 3,
    wrapperCol: 19,
    display: true,
  },
  {
    label: "经营范围",
    placeholder: "请输入经营范围",
    decorator: [
      "scope",
      { rules: [{ required: false, message: "请输入经营范围" }] },
    ],
    type: "textarea",
    key: "scope",
    col: 24,
    labelCol: 3,
    wrapperCol: 19,
    display: true,
  },
];
export default {
  mixins: [exportProgress], //导出方法注入
  components: {
    formIndex, //form表单封装
    StandardTable, //表格封装
    ExportLoading, //导出数据加载封装
  },
  data() {
    return {
      expandedRowKeys: [],
      selectedRows: [],
      columns: columns, //表格数据
      dataSource: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      tableLoading: false,
      formData: formData,
      formModal: formModal,
      prams: {
        pageNo: 1,
        pageSize: 10,
        // id: this.user.officeId
      },
      projectList: [], //获取当前项目数据
      loadingText: "数据加载中...",
      cPercent: 0,
      isExporting: false,
      expandedKeys: [],
      officeTreeData: [],
      // 弹出层标题
      title: "修改企业",
      // 是否显示弹出层
      visible: false,
      // 新增或者修改
      type: "edit",
      // 单位详情
      detail: {},
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  created() {
    if (this.$route.query.pageNumber) {
      this.prams.pageNo = this.$route.query.pageNumber;
    }
    this.aqxjlist(this.prams);
    this.getOfficeTree();
  },
  methods: {
    resourceUpload(obj) {
      this.formModal.forEach((item) => {
        if (item.key == obj.key) {
          item.defaultFileList = [obj];
          // if (item.key === "facePath") {
          //   this.getIdCardInfo(obj.formData, "front");
          // } else if (item.key === "backPath") {
          //   this.getIdCardInfo(obj.formData, "back");
          // }
        }
      });
    },
    upRemove(file) {
      this.formModal.forEach((item) => {
        if (item.key == file.key) {
          item.defaultFileList = [];
        }
      });
    },
    // 封装弹窗确定按钮
    confirm(title, content) {
      return new Promise((resolve, reject) => {
        this.$confirm({
          title: title,
          content: content,
          onOk() {
            resolve();
          },
        });
      });
    },
    // 删除接口
    recorDelete(val) {
      let params = {
        id: val.id,
      };
      this.confirm("删除", `是否确认删除 ${val.name} 公司`).then(() => {
        delOffice(params).then((res) => {
          if (res.data.code === 0) {
            this.$message.success("删除成功", 10);
            this.aqxjlist(this.prams);
          } else {
            this.$message.error(res.data.msg, 10);
          }
        });
      });
    },
    handleChange(e) {
      this.projectArr = [];
      // const name = {
      //   name: () => {
      //     this.getZdata(getOfficesList, e, "name");
      //   },
      // }[e.item.key]();
      this.fetchData(e);
      this.formData.forEach((item) => {
        if (e.item.key === item.key) {
          item.dataSource = this.projectArr;
        }
      });
      // 获取当前匹配key，赋值相对于数组
      this.formModal.forEach((item) => {
        if (e.item.key === item.key) {
          item.dataSource = this.projectArr;
        }
      });
      const arr = ["userId"];
      arr.forEach((item) => {
        this.$refs["formindex"].setFieldsValue("", {}, item);
      });
    },
    // 接口封装
    /**
     * msg 接收接口名称
     * e 数据
     * name 参数名
     * */
    getZdata(msg, e, name, key) {
      let data = {
        [name]: e.value,
      };
      msg(data).then((res) => {
        if (res.status == 200) {
          res.data.data.forEach((item) => {
            this.projectArr.push({
              title: item[name],
              id: item.id,
            });
          });
          this.projectList = res.data.data;
        }
      });
    },
    fetchData: debounce(function (e) {
      // 执行需要节流的逻辑
      this.getZdata(getOfficesList, e, "name")
    }, 1000), // 设置节流的时间间隔（毫秒）
    // 特殊数据处理
    handlSelect({ val, keys }) {
      // const arr = ["parentId"];
      // arr.forEach((item) => {
      //   this.$refs["formModal"].setFieldsValue("", {}, item);
      // });
      if (keys == "id") {
        this.officeTreeData.map((item) => {
          if (item.id == val) {
            this.$refs["formModal"].setFieldsValue("", { parentId: item.id });
          }
        });
      }
      this.projectList.forEach((item) => {
        // 选择企业项目管理员
        // if (keys == "master" && item.id == val) {
        //   console.log(item, "item");
        //   this.$refs["formModal"].setFieldsValue("", { userId: item.id });
        //   this.$refs["formModal"].setFieldsValue("", { master: item.name });
        // }
        // 选择公司名称查询项目
        if (keys == "name" && item.id == val) {
          this.$refs["formindex"].setFieldsValue(item.name, {}, "name");
        }
      });
    },
    aqxjlist(prams) {
      this.tableLoading = true;
      getOfficesList(prams).then((res) => {
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list;
          this.dataSource.forEach((item) => {
            if (item.mainBusiness) {
              item.mainBusiness = item.mainBusiness.split(",");
              item.mainBusiness = this.ptypeState(
                "mainBusiness",
                item.mainBusiness
              );
            }
          });

          this.pagination.total = res.data.data.total;
        }
        this.tableLoading = false;
      });
    },
    ptypeState(type, status) {
      if (type == "mainBusiness") {
        const obj = {
          1: "总包",
          2: "监理",
          3: "劳务分包",
          4: "专业分包",
          5: "老包",
        };
        let str = [];
        Object.keys(obj).forEach((key) => {
          status.forEach((item) => {
            if (key == item) {
              str.push(obj[key]);
            }
          });
        });
        return str;
      }
    },
    getOfficeTree() {
      getOfficeTree({}).then((res) => {
        this.officeTreeData = res.data.data;
        this.formDataFn("parentId", res.data.data);
        this.formDataFn("id", res.data.data);
      });
      getOfficeTypeList().then((res) => {
        const data = res.data.data.companyTypeList;
        const arr = [];
        data.forEach((item) => {
          arr.push({
            name: item.label,
            value: item.value,
          });
        });
        this.roleTypeList = arr;
        this.formDataFn("type", arr);
      });
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      this.formModal.forEach((item) => {
        if (item.key == key) {
          item.selectlist = data;
        }
      });
    },
    // 提交表单数据
    handleSubmit(e) {
      this.prams.pageNo = 1; // 点击查询按钮 从第一页开始
      this.pagination.current = 1;

      let obj = Object.assign({}, e, this.prams);
      this.aqxjlist(obj);
    },
    // 重置
    close() {
      this.prams = {
        pageNo: 1,
        pageSize: 10,
      };
      this.aqxjlist(this.prams);
      // this.$refs["formindex"].setFieldsValue("", {}, "name");
      this.$refs["formindex"].resetFields();
    },
    //表格 - 分页查询
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      this.prams.pageNo = e.current;
      this.pagination.current = e.current;
      this.aqxjlist(Object.assign({}, this.prams, obj));
    },
    updateSelectRows(e) {
      console.log(e);
    },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    onSelect(selectedKeys, info) {
      console.log(selectedKeys, "selectedKeys", info, "info");
      // this.queryParam.parentOfficeId = selectedKeys[0];
      // this.queryOfficeId(this.queryParam.parentOfficeId, null, null);
      // this.parentData();
    },
    // 跳转到参建单位
    toUnits(e) {
      this.$router.push({
        name: "企业概况",
        query: {
          id: e.id,
          parentId: e.parentId,
          // data: res.data.data,
          type: "table",
          pageNo: this.prams.pageNo,
          exData: 0,
          random: Math.random(),
        },
      });
    },
    // 查看编辑
    viewEditing(e, type) {
      let that = this;
      getOfficeDetail({ id: e.id }).then((res) => {
        if (res.data.code === 0) {
          this.detail = res.data.data;
          // 如果是顶级部门，标记为 undefined ，避免展示最上级的parentId
          if (this.detail.parentId == "00ad5fc2531546428aa8fe87312045ba") {
            this.detail.parentId = undefined;
          }

          // 处理回显图片path
          let path = ["licence", "credential"];
          this.formModal.forEach((item) => {
            path.forEach((key, index) => {
              if (item.key == key && res.data.data[key]) {
                item.defaultFileList = [
                  { url: res.data.data[key], uid: index, name: "image.png" },
                ];
              }
            });
          });
          // 单位类型
          res.data.data.type = res.data.data.type
            ? String(res.data.data.type)
            : res.data.data.type;
          // 主营业务
          res.data.data.mainBusiness = res.data.data.mainBusiness
            ? res.data.data.mainBusiness.split(",")
            : undefined;
          this.type = "edit";
          this.title = "修改企业";
          this.visible = true;
          this.$nextTick(() => {
            that.$refs["formModal"].queryObj(res.data.data);
          });
        }
      });
    },
    // 新建项目
    createItem(e) {
      this.title = "新增企业";
      this.visible = true;
      this.type = "add";
    },
    // 确认弹窗
    modelOk(e) {
      this.$refs["formModal"].combinationSubmit().then((res) => {
        if (res.userPwd == null) {
          // 不修改密码删除字段
          delete res.userPwd;
        }
        if (this.type == "add") {
          // 新增删除掉id
          res.id = undefined;
          res.userName = res.master;
          res.userPhone = res.phone;
          const params = {
            ...res,
            mainBusiness: res.mainBusiness
              ? res.mainBusiness.join()
              : res.mainBusiness,
          };
          addOffice(params)
            .then((result) => {
              if (result.data.code === 0) {
                this.$message.success("新增企业成功", 10);
              }
              this.modelCancel();
            })
            .catch((error) => {
              this.$message.success(error, 10);
              this.modelCancel();
            });
        } else if (this.type == "edit") {
          const params = {
            ...res,
            mainBusiness: res.mainBusiness
              ? res.mainBusiness.join()
              : res.mainBusiness,
          };

          editOffice(params)
            .then((result) => {
              if (result.data.code === 0) {
                this.$message.success("修改企业成功", 10);
              }
              this.modelCancel();
            })
            .catch((error) => {
              this.$message.success(error, 10);
              this.modelCancel();
            });
        }
      });
    },
    // 关闭弹窗
    modelCancel(e) {
      this.visible = false;
      this.aqxjlist(this.prams);
      this.$refs["formModal"].resetFields();
      //  关闭弹窗defaultFileList 清除默认图片数据
      let path = ["licence", "credential"];
      this.formModal.forEach((item) => {
        path.forEach((key) => {
          if (item.key == key) {
            item.defaultFileList = [];
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.constructiontTable_index {
  padding: 24px;
  background: #fff;
  border-radius: 6px;
  margin-bottom: 10px;

  .operator {
    margin-left: 30px;
  }
  .c_i_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 25px;

    .c_i_h_center {
      padding: 16px;
      border-radius: 4px;
      width: 180px;
      height: 130px;
      margin-right: 50px;

      div:nth-child(1) {
        font-size: 16px;
      }

      div:nth-child(2) {
        margin-top: 26px;
        font-size: 24px;
      }
    }
  }
}

.sTables {
  cursor: pointer;
  div {
    padding: 0 10px;
    text-align: center;
  }
}

.ant-btn-link {
  // color: rgb(118, 131, 143);
  // text-align: left;
  padding: 0;
}
.btn-default {
  color: #76838f;
}

.btn-default:hover {
  color: red;
}
.avatar-menu {
  width: 120px;
}

.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 1; /* 显示行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-btn-text {
  color: #4a86e8;
  background-color: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ant-btn-text:hover {
  cursor: pointer;
  color: #76abf5;
  background-color: transparent;
  border-color: #76abf5;
}
</style>
