import { RoleKeysEnum } from '@/enums/roleEnum.js'

export default {
  namespaced: true,
  state: {
    user: undefined,
    permissions: null,
    roles: null,
    routesConfig: null,
    unitOfficeId: undefined, // 参建单位公司Id 刷新时不丢失
    projectDeail: undefined, // 项目详情数据
    isGroupAccount: false, // 是否为集团账号
    isCompanyAccount: false, // 是否为公司账号
    isProjectAccount: false // 是否为项目账号
  },
  getters: {
    projectDeail: state => {
      if (!state.user) {
        try {
          const projectDeail = localStorage.getItem(process.env.VUE_APP_USER_KEY)
          state.projectDeail = JSON.parse(projectDeail)
        } catch (e) {
          console.error(e)
        }
      }
      return state.projectDeail
    },
    unitOfficeId: state => {
      if (!state.unitOfficeId) {
        try {
          const unitOfficeId = localStorage.getItem('unitOfficeId')
          state.unitOfficeId = unitOfficeId
        } catch (e) {
          console.error(e)
        }
      }
      return state.unitOfficeId
    },
    user: state => {
      if (!state.user) {
        try {
          const user = localStorage.getItem(process.env.VUE_APP_USER_KEY)
          state.user = JSON.parse(user)
        } catch (e) {
          console.error(e)
        }
      }
      return state.user
    },
    permissions: state => {
      if (!state.permissions) {
        try {
          const permissions = localStorage.getItem(process.env.VUE_APP_PERMISSIONS_KEY)
          state.permissions = JSON.parse(permissions)
          state.permissions = state.permissions ? state.permissions : []
        } catch (e) {
          console.error(e.message)
        }
      }
      return state.permissions
    },
    roles: state => {
      if (!state.roles) {
        try {
          const roles = localStorage.getItem(process.env.VUE_APP_ROLES_KEY)
          state.roles = JSON.parse(roles)
          state.roles = state.roles ? state.roles : []
        } catch (e) {
          console.error(e.message)
        }
      }
      return state.roles
    },
    // 返回当前用户是否为集团账号
    isGroupAccount: (state, getters) => {
      if (state.isGroupAccount) {
        return state.isGroupAccount
      } else {
        state.isGroupAccount = getters.roles.some(({ id }) => RoleKeysEnum.GROUP.includes(id))
        return state.isGroupAccount
      }
    },
    // 返回当前用户是否为公司/分公司账号
    isCompanyAccount: (state, getters) => {
      if (state.isCompanyAccount) {
        return state.isCompanyAccount
      } else {
        state.isCompanyAccount = getters.roles.some(({ id }) => RoleKeysEnum.COMPANY.includes(id))
        return state.isCompanyAccount
      }
    },
    // 返回当前用户是否为项目账号
    isProjectAccount: (state, getters) => {
      if (state.isProjectAccount) {
        return state.isProjectAccount
      } else {
        state.isProjectAccount = getters.roles.some(({ id }) => RoleKeysEnum.PROJECT.includes(id))
        return state.isProjectAccount
      }
    },
    routesConfig: state => {
      if (!state.routesConfig) {
        try {
          const routesConfig = localStorage.getItem(process.env.VUE_APP_ROUTES_KEY)
          state.routesConfig = JSON.parse(routesConfig)
          state.routesConfig = state.routesConfig ? state.routesConfig : []
        } catch (e) {
          console.error(e.message)
        }
      }
      return state.routesConfig
    }
  },
  mutations: {
    setProjectDeail (state, projectDeail) {
      state.projectDeail = projectDeail
      localStorage.setItem("projectDeail",JSON.stringify(projectDeail))
    },
    setUnitOfficeId (state, unitOfficeId) {
      state.unitOfficeId = unitOfficeId
      localStorage.setItem("unitOfficeId", unitOfficeId)
    },
    setState(state, newState){
      state = newState
    },
    setUser (state, user) {
      state.user = user
      localStorage.setItem(process.env.VUE_APP_USER_KEY, JSON.stringify(user))
    },
    setPermissions(state, permissions) {
      state.permissions = permissions
      localStorage.setItem(process.env.VUE_APP_PERMISSIONS_KEY, JSON.stringify(permissions))
    },
    setRoles(state, roles) {
      state.roles = roles
      localStorage.setItem(process.env.VUE_APP_ROLES_KEY, JSON.stringify(roles))
    },
    setRoutesConfig(state, routesConfig) {
      state.routesConfig = routesConfig
      localStorage.setItem(process.env.VUE_APP_ROUTES_KEY, JSON.stringify(routesConfig))
    },
  }
}
