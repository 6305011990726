import {GET_OFFICE_LIST,GET_PROJECT_BY_LIST,DETAIL_OBJ,GET_QUERY_CURRENTGK,GET_TYPE_COUNT,GET_WORK_TOTAL,GET_MANAG_CWA, GET_SEVEN_CWA,GET_WORK_AGE_FB,
    GET_JOB_TITLE_FB,GET_PROJECT_STATLS_KQ,GET_HOME_OFFICE_PROJS,GET_HOME_OFFICE_PROJS_STATUS,GET_HOME_OFFICE_PROJS_TOTALS,GET_HOME_OFFICE_MANAGE,
    GET_HOME_OFFICE_STAFF,GET_HOME_OFFICE_TEAM_KQ,GET_HOME_OFFICE_TEAM_DATA, EXCELLENT_PROJECT, QUERY_OFFICE_BY_USER} from '@/services/api'
import {request, METHOD} from '@/utils/request'
import { GET_PROJECT_BY_LIST_SAFE,OFFICE_PROJECT_BYOFFICEID, SummaryMonthOffice } from './api'
 
/**
 *
 *   获取近几个月工资发放情况
 */
export async function getSummaryMonthOffice(params) {
    return request(SummaryMonthOffice, METHOD.POST, params)
}
/**
 *
 *   获取所有公司列表
 */
export async function getOfficeListByParentOffice(params) {
    return request(GET_OFFICE_LIST, METHOD.GET, params)
}

/**
 *
 *   获取所有公司列表
 */
export async function getOfficeListByParentOffice2(params) {
    return request(QUERY_OFFICE_BY_USER, METHOD.GET, params)
}

/**
 *    根据公司查询机施管理的项目
 */
export async function getProjectByOffice(params) {
    return request(OFFICE_PROJECT_BYOFFICEID, METHOD.POST, params)
}
/**
 *
 *   获取所有项目，以及在建，停工，完工的数量
 */
export async function getProjectsByOffice(params) {
    return request(GET_PROJECT_BY_LIST, METHOD.GET, params)
}

/**
 *  项目首页地图mark详情
 */
export async function detailObj(id) {
    return request(`${DETAIL_OBJ}/${id}`, METHOD.GET)
}

/**
 *
 *   获取所有项目，以及在建，停工，完工的数量()safe
 */
export async function getProjectsByOfficeSafe(params) {
    return request(GET_PROJECT_BY_LIST_SAFE, METHOD.GET, params)
}
/**
 *    安全巡检统计本月概况
 */
export async function getQueryCurrentGk(params) {
    return request(GET_QUERY_CURRENTGK, METHOD.POST, params)
}
/**
 *    安全巡检统计本月概况
 */
export async function getTypeCount(params) {
    return request(GET_TYPE_COUNT, METHOD.POST, params)
}
/**
 *    劳务数据工人统计
 */
export async function getWorkTotal(params) {
    return request(GET_WORK_TOTAL, METHOD.POST, params)
}

/**
 *    劳务数据管理员统计
 */
export async function getManagCWA(params) {
    return request(GET_MANAG_CWA, METHOD.POST, params)
}

/**
 *    查询工人考勤人数7天
 */
export async function get7DaysCWA(params) {
    return request(GET_SEVEN_CWA, METHOD.POST, params)
}

/**
 *    工人年龄结构
 */
export async function getWorkAgeFb(id) {
    return request(`${GET_WORK_AGE_FB}/${id}`, METHOD.GET)
}

/**
 *    工种结构分布
 */
export async function getJobTitleFb(id) {
    return request(`${GET_JOB_TITLE_FB}/${id}`, METHOD.GET)
}

/**
 *
 */
export async function projectStatissKqS  (params) {
    return request(GET_PROJECT_STATLS_KQ, METHOD.GET, params)
}

/**
 *  查詢各項目狀態的總數量
 */
export async function getHomeOfficeProject (params) {
    return request(GET_HOME_OFFICE_PROJS, METHOD.GET, params)
}

/**
 *  查詢各項目狀態的项目清单
 *  @params status 项目状态
 */
export async function getHomeOfficeProjeStatus (params) {
    return request(GET_HOME_OFFICE_PROJS_STATUS + `${params.status}`, METHOD.GET)
}

/**
 *  查詢項目的工人和班組總數
 * @params projectId 项目ID
 */
export async function getHomeOfficeProjeTotals (params) {
    return request(GET_HOME_OFFICE_PROJS_TOTALS + `${params.projectId}`, METHOD.GET)
}

/**
 *  管理人员公司出勤数据统计
 */
export async function getHomeOfficeManage (params) {
    return request(GET_HOME_OFFICE_MANAGE, METHOD.GET, params)
}

/**
 *  工人公司出勤数据统计
 */
export async function getHomeOfficeStaff (params) {
    return request(GET_HOME_OFFICE_STAFF, METHOD.GET, params)
}

/**
 *  查询班组考勤情况
 */
export async function getHomeOfficeTeamKq (params) {
    return request(GET_HOME_OFFICE_TEAM_KQ, METHOD.POST, params)
}

/**
 *  班组考勤情况
 */
export async function getHomeOfficeTeamKqData (params) {
    return request(GET_HOME_OFFICE_TEAM_DATA, METHOD.POST, params)
}


/**
 *  获取创优项目
 */
export async function getExcellentProject (params) {
    return request(EXCELLENT_PROJECT, METHOD.POST, params)
}
