<template>
  <div class="a_Approval">
    <div class="plug-Overview">月度项目检查情况一览表</div>
    <a-divider />
    <a-form-model layout="inline" :model="params" class="mb-8 query-form" @submit="handleSubmit">
      <a-form-model-item v-if="user.loginType != 0" label="工程管理部">
        <a-select
          v-model="params.gcglbId"
          allowClear
          placeholder="请选择"
          style="width: 220px"
        >
          <a-select-option
            v-for="item in gcglbList"
            :key="item.value"
            :value="item.value"
            :title="item.name"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="月度" >
        <a-month-picker style="width: 220px" placeholder="请选择月份" format="YYYY-MM" v-model="monthPickerVal">
          <a-icon slot="suffixIcon" type="smile" />
        </a-month-picker>
      </a-form-model-item>
      <a-form-model-item v-if="user.loginType != 0" label="项目名称">
        <a-input style="width: 220px" placeholder="请输入项目名称" v-model="params.projectName" allowClear/>
      </a-form-model-item>
    </a-form-model>
    
    <a-space>
      <a-button type="primary" @click="handleSubmit"> 查询 </a-button>
      <a-button @click="resetQuery"> 重置 </a-button>
      <a-button type="primary" @click="doDowload" v-if="user.isGcglb == 0 && user.loginType == 1 && user.nowRoleKey.includes('JS_LJJ')"> 导出 </a-button>
    </a-space>

    <standard-table
      :columns="columns"
      :dataSource="tableData"
      rowKey="index"
      :pagination="pagination"
      :loading="tableLoading"
      @change="handleChangePage"
      :isAlert="false"
      :bordered="true"
    >
      <template slot="index" slot-scope="{ index }">
        <span>{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}</span>
      </template>
      <!-- 检查发现问题 -->
      <template slot='problems' slot-scope='{ index, record }'>
        <a-tooltip placement='topLeft'>
          <template slot='title'>
            <template v-for="(problem) in record.problems">{{ problem }}</template>
          </template>
          <div class="overtext-1">
            <template v-for="(problem) in record.problems">{{ problem }}</template>
          </div>
        </a-tooltip>
      </template>
    </standard-table>

    <Export-loading
      :cPercent="cPercent"
      :isExporting="isExporting"
      :loadingText="loadingText"
    ></Export-loading>
  </div>
</template>

<script>
import {
  getMonthlyCheckList,
  monthlyCheckExportUrl,
} from "@/services/safeMonthlyReport";
import { request } from "@/utils/request";
import { QUERY_OFFICE_BY_USER } from "@/services/api";
import ExportLoading from "@/components/ExportLoading/ExportLoading"; // progress组件
import { exportProgress } from "@/pages/aqjyzl/export"; // 导出方法
import { mapGetters } from "vuex";
import StandardTable from "@/components/table/StandardTable";
import moment from "moment";
import { cloneDeep } from "lodash";

const columns = [
  {
    title: "序号",
    align: "center",
    scopedSlots: { customRender: "index" },
    width: 65,
  },
  {
    title: "工程管理部",
    align: "center",
    dataIndex: "gcglbName",
    width: 150,
  },
  {
    title: "在建项目名称（危险源名称）",
    dataIndex: "projectName",
    ellipsis: true,
    tooltip: true
  },
  {
    title: "工程造价（亿元）",
    dataIndex: "projBudget",
    align: "center",
    width: 120,
  },
  {
    title: "月巡检次数/自评得分",
    dataIndex: "checkNumScore",
    align: "center",
    width: 120,
  },
  {
    title: "检查人员",
    dataIndex: "checkPerson",
    align: "center",
    width: 140,
    ellipsis: true,
    tooltip: true
  },
  {
    title: "检查发现问题",
    dataIndex: "problems",
    scopedSlots: { customRender: "problems" },
    ellipsis: true,
    // align: "center",
  },
  {
    title: "整改落实情况",
    dataIndex: "checkResult",
    align: "center",
    width: 180,
  },
];

export default {
  mixins: [exportProgress], //导出方法注入
  components: {
    ExportLoading,
    StandardTable
  },
  data() {
    return {
      monthPickerVal: moment(new Date(), 'YYYY-MM'),
      params: {
        month: '',
        gcglbId: '',
        projectName: '',
        year: '',
        pageNumber: 1,
        pageSize: 10,
      },
      pagination: {
        //分页数据
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total, range) => `共 ${total} 条`,
      },
      gcglbList: [],
      total: 0,
      columns: cloneDeep(columns),
      tableData: [],
      tableLoading: false, //table加载中

      loadingText: "数据加载中...",
      cPercent: 0,
      isExporting: false,
    };
  },
  created() {
    this.getgcglbList(QUERY_OFFICE_BY_USER);
  },
  activated() {
    if (this.user.loginType == 0) {
      this.columns.splice(this.columns.findIndex(el => el.dataIndex == 'gcglbName'), 1)
    }
    this.handleQuery();
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.params.pageNumber = 1
      this.pagination.current = 1
      this.tableData = []
      this.handleQuery();
    },
    handleQuery(e) {
      this.tableLoading = true
      if (this.monthPickerVal) {
        console.log(moment(this.monthPickerVal).format('YYYY-MM'))
        this.params.year = moment(this.monthPickerVal).format('YYYY-MM').split('-')[0]
        this.params.month = moment(this.monthPickerVal).format('YYYY-MM').split('-')[1]
      } else {
        this.monthPickerVal = moment(new Date(), 'YYYY-MM')
      }
      getMonthlyCheckList(this.params).then((res) => {
        this.tableLoading = false
        let d = res.data;
        if (d.code === 0) {
          this.tableData = d.data.list
          this.pagination.total = d.data.total;
          this.total = d.data.total;
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    handleChangePage(e) {
      this.params.pageNumber = e.current;
      this.pagination.current = e.current;
      this.handleQuery();
    },
    // 获取工程管理部数据
    getgcglbList(path) {
      request(path, "get").then((res) => {
        let data = res.data.data.filter(item => item.id != "3da0f5533f87425c9a9e8a11a7923300")
        const arr = [];
        data.forEach((item) => {
          arr.push({
            name: item.master,
            value: item.id,
          });
        });
        this.gcglbList = arr;
      });
    },
    resetQuery() {
      this.monthPickerVal = moment(new Date(), 'YYYY-MM')
      this.pagination.pageNumber = 1
      this.params = {
        month: moment(new Date()).format('MM'),
        gcglbId: '',
        projectName: '',
        year: moment(new Date()).format('YYYY'),
        pageNumber: 1,
        pageSize: 10,
      };
      this.handleQuery();
    },
    doDowload() {
      let obj = this.params;
      this.loadingText = "正在导出EXCEL文件，请稍候 ····";
      this.isExporting = true;
      this.cPercent = 0;
      let fileName = "月度项目检查情况一览表.xlsx";
      this.exportFunNomal2(
        monthlyCheckExportUrl,
        obj,
        fileName,
        "application/vnd.ms-excel"
      );
    },
  },
};
</script>

<style lang="less" scoped>
@import "@/theme/common.less";
.query-form {
  display: flex;
  // justify-content: space-between;
}
</style>
