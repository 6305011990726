<template>
  <a-modal width="1400px" v-model="show" :title="title" :footer="null" @cancel="close">
      <a-form-model ref="ruleForm" :model="params" :rules="rules">
        <a-row>
          <a-col :span="8">
            <a-form-model-item label="公司" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }">
              <a-input v-model="params.companyName" disabled placeholder=""/>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="分公司" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" >
              <a-input v-model="params.branchOfficeName" disabled placeholder=""/>

            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="项目名称" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" >
              <a-input v-model="params.projectName" disabled placeholder=""/>
              
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="危大工程类别" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="gclbId">
              <a-select v-model="params.gclbId" allowClear placeholder="请选择 " style="width: 240px">
                <a-select-option v-for="item in options1" :key="item.value" :value="item.value" :title="item.label">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="危大工程名称" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" >
              <a-input v-model="params.sgfaName" placeholder="请输入 "/>
              
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="是否超一定规模" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="overRange">
              <a-select v-model="params.overRange" allowClear placeholder="请选择 " style="width: 240px">
                <a-select-option v-for="item in options" :key="item.value" :value="item.value" :title="item.label">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="危大工程进度" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" >
              <a-radio-group v-model="params.gcproessCode">
                <a-radio :value="1">未开始</a-radio>
                <a-radio :value="2">持续中</a-radio>
                <a-radio :value="3">已完成</a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="计划实施时间" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" >
              <a-date-picker v-model="params.planTime" valueFormat="YYYY-MM-DD" />
              
            </a-form-model-item>
          </a-col>

          <a-col :span="8">
            <a-form-model-item label="专项施工方案" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" >
              <a-radio-group v-model="params.zxsgfa">
                <a-radio value="1">是</a-radio>
                <a-radio value="0">否</a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="填报时间" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" >
              <a-date-picker v-model="params.tbTime" valueFormat="YYYY-MM-DD" />
              
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>

      <div style="position: relative;">
        <a-button type="primary" class="y-ayh" @click="add" v-if="isEdit != 2">
          添加施工过程
        </a-button>
        <a-tabs v-model="activeKey" type="editable-card" hide-add @edit="onEdit">
          <a-tab-pane v-for="(pane,index) in panes" :key="index" :tab="`危大工程`+(index+1)" :closable="pane.closable">
            <a-form-model :model="pane" :rules="rulesPane">
              <a-row>
                <a-col :span="8">
                  <a-form-model-item label="危大工程内容" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }">
                    <a-input v-model="pane.conetent"  placeholder=""/>
                  </a-form-model-item>
                </a-col>
                <a-col :span="8">
                  <a-form-model-item label="实施状态" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" >
                    <a-radio-group v-model="pane.ipmlCode">
                      <a-radio :value="1">未开始</a-radio>
                      <a-radio :value="2">持续中</a-radio>
                      <a-radio :value="3">已完成</a-radio>
                    </a-radio-group>
                  </a-form-model-item>
                </a-col>
                <a-col :span="8">
                  <a-form-model-item label="填报时间" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" >
                    <a-date-picker v-model="pane.tbTime" valueFormat="YYYY-MM-DD" />
                    
                  </a-form-model-item>
                </a-col>
                <a-col :span="8">
                  <a-form-model-item label="验收情况" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" >
                    <!-- <a-input v-model="pane.ysqk"  placeholder=""/> -->
                    <!-- radio ysstatus  ysqk-->
                    <a-radio-group v-model="pane.ysstatus">
                      <a-radio value="1">已验收</a-radio>
                      <a-radio value="2">
                        未验收
                      </a-radio>
                    </a-radio-group>
                  </a-form-model-item>
                </a-col>
                
                <a-col :span="8">
                  <a-form-model-item label="负责人" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" >
                    <a-input v-model="pane.managename" placeholder="请输入 "/>
                    
                  </a-form-model-item>
                </a-col>
                <a-col :span="8">
                  <a-form-model-item label="负责人电话" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" prop="managephone">
                    <a-input type="number" v-model="pane.managephone" placeholder="请输入 "/>
                    
                  </a-form-model-item>
                </a-col>
                
                
                
                <a-col :span="20">
                  <a-form-model-item label="验收表" :label-col="{ span: 2 }" :wrapper-col="{ span: 14 }" prop="gclbId">
                    <a-upload class="avatar-uploader" list-type="picture-card" :accept="accept1" :file-list="pane.fileList"
                        @change="handleUploadChange1" :customRequest="resourceUpload1(pane)" @preview="handlePreview"
                        :beforeUpload="beforeUpload1" :remove="handleRemove1(pane)">

                        <div v-if="pane.fileList.length < 1">
                          <a-icon :type="uploading1 ? 'loading' : 'plus'" />
                        </div>
                        <!-- <div class="tit-fi">支持pdf格式上传</div> -->
                    </a-upload>
                  </a-form-model-item>
                </a-col>
                

              </a-row>
            </a-form-model>
          </a-tab-pane>
        </a-tabs>

      </div>

      <a-row v-if="isEdit != 2">
        <a-col :span="12"></a-col>
        <a-col :span="12">
          <a-form-model-item :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }" prop="year">
            <div class="flex flex-row-reverse">
              <a-button type="primary" :loading="sbloading" @click="handleSubmit">保存</a-button>
              <a-button class="mr-2" @click="close">返回</a-button>
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>
  </a-modal>
</template>

<script>

import { request } from "@/utils/request";
import { mapGetters } from 'vuex'
import moment from "moment";
import { wdgcplanAdd,wdgcplanUpdate, wdgcplanByID } from "@/services/dangerousProject";
import { ATTACHMENT_UPLOAD } from "@/services/api";
import gcglib from './mixinDm'

export default {
  name: 'etDetail',
  computed: {
    ...mapGetters('account', ['user']),
  },
  props: {
      visible: {
          type: Boolean,
          default: false
      },
      isEdit: {
          type: Number,
          default: 0
      },
      id: {
          default: ''
      },
      projectInfo: {
        default: () => {
          return {}
        }
      }
      

  },
  mixins: [gcglib],
  data() {
    return {
      // isEdit: 0,
      rules: {
        name: [{ required: true, message: '请输入 ', trigger: 'change' }],
        
      },
      rulesPane: {
        managephone: [
          { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号', trigger: 'change' }
        ],
        
      },

      params: {
        "companyId": "27794", //公司ID
        "companyName": "张三", //公司名称
        "branchOfficeId": "8664", //分公司ID
        "branchOfficeName": "张三", //分公司名称
        "zbOfficeId": "29073", //总包公司ID
        "zbOfficeName": "芋艿", //总包公司名称
        "projectId": "31881", //项目ID
        "projectName": "赵六", //项目名称
        "gclbId": "4425", //工程类别id
        "gclbName": "张三", //工程类别名称
        "sgfaName": "王五", //施工方案名称
        "overRange": "0", //是否超过一定规模
        "gcproessCode": 1, //危大工程进度code 1.未开工2.持续中3.已完成
        "gcproessDesc": "未开工", //危大工程进度描述
        "planTime": "2024-01-01", //计划实施时间
        "zxsgfa": "1", //专项施工方案 1是 其他否
        "tbTime": "2024-11-19" //填报时间
      },
      sbloading: false,
      title: '新增',
      show: false,

      activeKey: 0,
      panes: [
        {
          "conetent": "", //危大工程内容
          "ipmlCode": 1, //实施状态code 1.未开始2.持续中3.已完成
          "ipmlDesc": "", //实施状态描述
          "ysqk": "", //验收情况文字
          "ysstatus": "1", //验收状态 1已验收 其他未验收
          "ysurl": "",
          "ysname": "", //验收表原始文件名称
          "managename": "", //现场负责人姓名
          "managephone": "", //现场负责人电话
          "tbTime": "" //填报时间
        },
        
      ],
      newTabIndex: 0,

      rules1: {}
    };
  },
 
  watch: {
    visible(val) {
      console.log(val)
      
      if (this.isEdit == 0) {
          // dom 渲染后
        
        this.params = {
          "companyId": this.projectInfo.parentOfficeId, //公司ID
          "companyName": this.projectInfo.parentOffice, //公司名称
          "branchOfficeId": this.projectInfo.gcglbId, //分公司ID
          "branchOfficeName": this.projectInfo.gcglbName, //分公司名称
          // "zbOfficeId": "", //总包公司ID
          // "zbOfficeName": "", //总包公司名称
          "projectId": this.projectInfo.projectId, //项目ID
          "projectName": this.projectInfo.projectName, //项目名称
          "gclbId": "", //工程类别id
          "gclbName": "", //工程类别名称
          "sgfaName": "", //施工方案名称
          "overRange": "", //是否超过一定规模
          "gcproessCode": 1, //危大工程进度code 1.未开工2.持续中3.已完成
          "gcproessDesc": "", //危大工程进度描述
          "planTime": "", //计划实施时间
          "zxsgfa": "", //专项施工方案 1是 其他否
          "tbTime": "" //填报时间
        }
        this.panes = [
          {
            "conetent": "", //危大工程内容
            "ipmlCode": 1, //实施状态code 1.未开始2.持续中3.已完成
            "ipmlDesc": "", //实施状态描述
            "ysqk": "", //验收情况文字
            "ysstatus": "", //验收状态 1已验收 其他未验收
            "ysurl": "",
            "ysname": "", //验收表原始文件名称
            "managename": "", //现场负责人姓名
            "managephone": "", //现场负责人电话
            "tbTime": "", //填报时间
            fileList: [],
          },
          
        ]
        this.activeKey = 0
        this.filePath1 = [];
        this.title = '新增 危大工程管理'
      } else {
          this.title = '编辑 危大工程管理'
          this.params.parentId = this.id;
          this.getDetail()

      }
      this.show = val;
    }
  },
  methods: {
    getDetail() {
      wdgcplanByID({id: this.params.parentId}).then(res => {
        if (res.data.code == 0) {
          let d = res.data.data;

          this.params =  d.plan
          this.panes = d.list.map(item => {
            if (item.ysurl) {
                let att = item.ysurl
                let name = item.ysname.split(',')
                item.fileList = att.split(',').map((item,index) => {
                return {
                  name: name[index],
                  url: item,
                  status: 'done',
                  uid: index,
                }
              })
            } else {
              item.fileList = []
            }
            return {
              ...item,
            }
          })
          
         
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },

    handleSubmit() {
      // this.panes.length == 0
      if (this.panes.length == 0) {
        this.$message.error('请添加危大工程')
        return
      }
      this.sbloading = true
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let d = {
            plan: this.params,
            list: this.panes.map(item => {
              let attach1 = item.fileList.map(item => item.url).join(',');
              let attachname1 = item.fileList.map(item => item.name).join(',');
              item.ysurl = attach1;
              item.ysname = attachname1;
              // "ipmlCode": 1, //实施状态code 1.未开始2.持续中3.已完成
              item.ipmlDesc = ['未开始','持续中','已完成'][item.ipmlCode - 1]
              // ysstatus
              item.ysqk = item.ysstatus == 1 ? '已验收' : '未验收'
              return {
                ...item,
              }
            })
          }
          let gclbName
          if(this.params.gclbId) {

            gclbName = this.options1.find(item => item.value == this.params.gclbId).label
          }
          d.plan.gclbName = gclbName
          // "gcproessCode": 1, //危大工程进度code 1.未开工2.持续中3.已完成
          d.plan.gcproessDesc = ['未开工','持续中','已完成'][d.plan.gcproessCode - 1]

          // 校验手机号
          let flag = d.list.every(item => {
            if (item.managephone) {
              return /^1[3456789]\d{9}$/.test(item.managephone)
            } else {
              return true
            }
          })
          if (!flag) {
            this.$message.error('请输入正确的手机号')
            this.sbloading = false
            return
          }
          if (this.isEdit == 0) {
            wdgcplanAdd(d).then(res => {
              if (res.data.code == 0) {
                this.$message.success('提交成功！')
                this.close();
                this.$emit('refresh')
              } else {
                this.$message.error(res.data.msg)
              }
              this.sbloading = false
            })
          } else {
            wdgcplanUpdate(d).then(res => {
              if (res.data.code == 0) {
                this.$message.success('修改成功！')
                this.close();
                this.$emit('refresh')
              } else {
                this.$message.error(res.data.msg)
              }
              this.sbloading = false
            })
          }
        } else {
          console.log('error submit!!');
          this.sbloading = false
          return false;
        }
      });
    },
    close() {
      this.resetForm()
      this.$emit('closeDialog')
    },
    resetForm() {
      // this.$refs.ruleForm.resetFields();
      // this.filePath1 = []
    },
    callback(key) {
      console.log(key);
    },
    onEdit(targetKey, action) {
      this[action](targetKey);
    },
    add() {
      const panes = this.panes;
      const activeKey = this.newTabIndex++;
      panes.push({
        "conetent": "", //危大工程内容
        "ipmlCode": 1, //实施状态code 1.未开始2.持续中3.已完成
        "ipmlDesc": "", //实施状态描述
        "ysqk": "", //验收情况文字
        "ysstatus": "1", //验收状态 1已验收 其他未验收
        "ysurl": "",
        "ysname": "", //验收表原始文件名称
        "managename": "", //现场负责人姓名
        "managephone": "", //现场负责人电话
        "tbTime": "", //填报时间
        fileList: [],
      });
      this.panes = panes;
      this.activeKey = panes.length - 1;
    },
    remove(targetKey) {
      let activeKey = this.activeKey;
      let lastIndex;
      this.panes.forEach((pane, i) => {
        if (i === targetKey) {
          lastIndex = i - 1;
        }
      });
      const panes = this.panes.filter((pane,index) => index !== targetKey);
      
      this.panes = panes;
      this.activeKey = lastIndex;
    },
    
  },
};
</script>

<style lang="less" scoped>
.a_Approval {
  background: #fff;
  border-radius: 10px;
}

.btn_group {
  padding-right: 190px;
}
.y-ayh {
  position: absolute;
  right: 0;
  z-index: 22;
}
</style>
