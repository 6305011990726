<template>
  <div class="home_container">
    <a-row :gutter="[16, 16]">
      <a-col :span="16">
        <div class="public" style="padding-top: 24px">
          <div class="flex">
            <div class="relative">
              <img
                class="wran-img"
                src="../../assets/img/icon-jw-warning.png"
                alt=""
              />
              <div class="warning-content white">
                <p class="font-bold-700">建委预警</p>
                <p class="font-bold-700 font-30">
                  {{ noCloseWarnList.length }}
                </p>
                <router-link class="white pointer"
                :to="{ name: '预警列表' }"
                >
                  查看详情<a-icon type="right" />
                </router-link>
              </div>
            </div>
            <vue-scroll :ops="ops" style="height:122px" class="ml-2">
              <standard-table
                class="close-table"
                :columns="columns"
                :dataSource="noCloseWarnList"
                rowKey="id"
                :pagination="false"
                :isAlert="false"
                :bordered="false"
              >
                <template slot="index" slot-scope="{ index }">
                  <span>
                    {{ parseInt(index) + 1 }}
                  </span>
                </template>
                <template slot="bizType" slot-scope="{ text }">
                  {{ text == 1 ? "实名制" : "工资分账" }}
                </template>
                <template slot="eventName" slot-scope="{ text }">
                  {{ text }}
                </template>
                <template slot="warmCode" slot-scope="{ text, record }">
                  <div
                    class="statusDrop flex a-center overtext-1"
                    :class="textStyle(record)"
                  >
                    {{ text }}
                  </div>
                </template>
                <template slot="status" slot-scope="{ text, record }">
                  <div
                    class="statusDrop flex a-center overtext-1"
                    :class="textStyle(record)"
                  >
                    {{ text | statusStr }}
                  </div>
                </template>
                <!-- ellipsis-1 -->
                <template slot="overtext" slot-scope="{ text }">
                  <span class="overtext-1">{{ text }}</span>
                </template>
                <!-- <div slot="action" slot-scope="{ record }">
                  <a-button type="link" @click="toHandle(record)"
                    >去处理</a-button
                  >
                </div> -->
              </standard-table>
            </vue-scroll>
          </div>
        </div>
      </a-col>
      <a-col :span="8">
        <div>
          <a-row :gutter="[0, 16]">
            <a-col>
              <div class="public">
                <div class="pl-1">
                  <div class="black font-16 font-bold overtext-1">
                    {{
                      projectData.fullName
                        ? projectData.fullName
                        : projectData.projectName
                    }}
                  </div>
                  <div
                    class="flex"
                    style="padding-top: 17px; padding-bottom: 6px"
                  >
                    <img
                      class="home-project"
                      src="@/assets/img/home-project.png"
                      alt=""
                    />
                    <div
                      class="flex-1 pl-2"
                      style="line-height: 20px; font-size: 14px"
                    >
                      <div class="flex overtext-1">
                        <span class="black mr-2">项目编码</span>
                        <span class="lightgray">{{
                          projectData.projectNum
                        }}</span>
                      </div>
                      <div class="flex py-1">
                        <span class="black mr-2">负责人</span>
                        <span class="lightgray">{{
                          projectData.userName
                        }}</span>
                      </div>
                      <div class="flex">
                        <div class="flex-1">
                          <span class="black mr-2">联系电话</span>
                          <span class="lightgray">{{
                            projectData.userPhone
                          }}</span>
                        </div>
                        <router-link
                          class="pointer"
                          style="color: #0874f0"
                          :to="{ name: '项目概况' }"
                          >查看详情<a-icon type="right"
                        /></router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a-col>
          </a-row>
        </div>
      </a-col>
    </a-row>
    <a-row :gutter="[16, 16]">
      <a-col :span="16">
        <a-row :gutter="[16, 0]">
          <a-col :span="8">
            <a-card :bordered="false">
              <template #title>
                <div class="flex font-16">
                  <span class="flex-1 black">管理人员数据</span>
                  <router-link
                    :to="{ name: '管理人员列表' }"
                    class="pointer"
                    style="color: #0874f0"
                    >更多</router-link
                  >
                </div>
              </template>
              <a-row type="flex" justify="space-between">
                <a-col class="ml-35">
                  <h4 class="black-three">登记人员</h4>
                  <p class="font-24 black">{{ mangerData.registerNum || 0 }}</p>
                  <div class="py-1">
                    <h4 class="black-three">今日出勤人数</h4>
                  <div class="flex">
                    <span class="font-24 black">{{
                      mangerData.todayAtteNum || 0
                    }}</span>
                    <!-- <div class="card-match ml-05">
                      <span class="green">+1.502%</span>
                      <img src="@/assets/img/arrow-up.png" alt="" />
                    </div> -->
                  </div>
                  </div>
                </a-col>
                <a-col class="mr-3">
                  <div class="flex flex-column a-center">
                  <img src="@/assets/img/icon-line-chart.png" alt="" />
                  <div class="pt-2">
                    <h4 class="black-three">出勤率</h4>
                    <p class="font-24 black">
                      {{ mangerData.atteRate ? (mangerData.atteRate > 100 ? 100 :mangerData.atteRate) : 0 }}%
                    </p>
                  </div>
                </div>
                </a-col>
              </a-row>
            </a-card>
          </a-col>
          <a-col :span="8">
            <a-card :bordered="false">
              <template #title>
                <div class="flex font-16">
                  <span class="flex-1 black">工人数据</span>
                  <router-link
                    :to="{ name: '在场工人列表' }"
                    class="pointer"
                    style="color: #0874f0"
                    >更多</router-link
                  >
                </div>
              </template>
              <a-row type="flex" justify="space-between">
                <a-col class="ml-35">
                  <h4 class="black-three">累计登记人数</h4>
                  <p class="font-24 black">{{ staffData.registerNum || 0}}</p>
                  <div class="py-1">
                    <h4 class="black-three">今日出勤人数</h4>
                  <div class="flex">
                    <span class="font-24 black">{{
                      staffData.todayAtteNum || 0
                    }}</span>
                    <!-- <div class="card-match ml-05">
                      <span class="green"
                        >{{ staffData.compareYesterdayRate }}%</span
                      >
                      <img src="@/assets/img/arrow-up.png" alt="" />
                    </div> -->
                  </div>
                  </div>
                </a-col>
                <a-col class="mr-3">
                  <h4 class="black-three">在场人数</h4>
                  <p class="font-24 black">{{ staffData.enterNum || 0}}</p>
                  <div class="py-1">
                    <h4 class="black-three">出勤率</h4>
                    <p class="font-24 black">{{ staffData.atteRate || 0}}%</p>
                  </div>
                </a-col>
              </a-row>
            </a-card>
          </a-col>
          <a-col :span="8">
            <a-card :bordered="false">
              <template #title>
                <div class="flex font-16">
                  <span class="flex-1 black">班组数据</span>
                  <router-link
                    :to="{ name: '参建班组' }"
                    class="pointer"
                    style="color: #0874f0"
                    >更多</router-link
                  >
                </div>
              </template>
              <a-row type="flex" justify="space-between">
                <a-col class="ml-35">
                  <h4 class="black-three">累计登记班组</h4>
                  <p class="font-24 black">{{ teamDataKq.totalTeams || 0}}</p>
                  <div class="py-1">
                  <h4 class="black-three">今日出勤班组</h4>
                  <div class="flex">
                    <span class="font-24 black">{{
                      teamDataKq.todayTeams || 0
                    }}</span>
                    <!-- <div class="card-match ml-05">
                      <span class="red">-21.01%</span>
                      <img src="@/assets/img/arrow-down.png" alt="" />
                    </div> -->
                  </div>
                </div>
                </a-col>
                <a-col class="mr-3">
                  <h4 class="black-three">在场班组</h4>
                  <p class="font-24 black">{{ teamDataKq.onlineTeams || 0}}</p>
                  <div class="py-1">
                    <h4 class="black-three">出勤率</h4>
                    <p class="font-24 black">{{ teamDataKq.teamCql || 0}}</p>
                  </div>
                </a-col>
              </a-row>
            </a-card>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="8">
        <a-card :bordered="false">
          <template #title>
            <div class="flex font-16">
              <span class="flex-1 black">考勤设备</span>
              <router-link
                :to="{ name: '考勤设备设置' }"
                class="pointer"
                style="color: #0874f0"
                >更多</router-link
              >
            </div>
          </template>
          <div class="w-100">
            <div class="flex j-around">
              <div class="ml-1 mr-5">
                <div class="flex flex-column">
                  <h4 class="black-three">考勤设备数</h4>
                  <p class="black font-24">{{ deviceData.deviceNum || 0}}</p>
                </div>
                <div class="flex flex-column pt-1">
                  <h4 class="black-three">离线设备</h4>
                  <p class="black font-24">{{ deviceData.offlineDeviceNum || 0}}</p>
                </div>
              </div>
              <vue-scroll
                :ops="ops"
                style="height: 152px"
                class="flex-1"
              >
                <standard-table
                  class="close-table"
                  :columns="deviceColumns"
                  :dataSource="deviceData.attendanceDeviceVoList"
                  rowKey="id"
                  :pagination="false"
                  :isAlert="false"
                  :bordered="false"
                >
                  <template slot="index" slot-scope="{ index }">
                    <span>
                      {{ parseInt(index) + 1 }}
                    </span>
                  </template>
                  <template slot="online" slot-scope="{ text }">
                    <span>
                      {{ text ? text : "在线" }}
                    </span>
                  </template>
                </standard-table>
              </vue-scroll>
            </div>
          </div>
        </a-card>
      </a-col>
    </a-row>
    <a-row :gutter="[16, 16]">
      <a-col :span="16">
        <a-card :bordered="false">
          <template #title>
            <!-- <span
                class="pointer font-16"
                :class="activedKey === 1 ? 'black' : 'black-three'"
                @click="handleChangeTitle(1)"
                >实时在场人数</span
              > -->
            <span
              class="pointer font-16"
              :class="activedKey === 2 ? 'black' : 'black-three'"
              @click="handleChangeTitle(2)"
              >近7日出勤数据</span
            >
          </template>
          <div
            id="main"
            style="width: 100%; height: 263px"
            ref="sevenDaysChart"
          ></div>
        </a-card>
      </a-col>
      <a-col :span="8">
        <a-card :bordered="false">
          <template #title>
            <span class="flex-1 black font-16">班组考勤情况</span>
          </template>
          <vue-scroll v-if="teamRankList && teamRankList.length > 0" :ops="ops" style="height: 220px" class="tb-box-2">
            <div
              class="mt-2 px-1"
              v-for="(item, index) in teamRankList"
              :key="index"
            >
              <div class="flex j-between">
                <span>{{ item.teamName }}</span>
                <span>{{ item.cql }}%</span>
              </div>
              <a-progress
                :percent="item.cql"
                :show-info="false"
                :strokeWidth="12"
                :strokeColor="getProgressColor(item.cql)"
              />
            </div>
          </vue-scroll>
          <template v-else>
            <a-empty style="height: 220px" class="flex j-center flex-column"/>
          </template>
          <div class="mt-2 flex j-between px-2">
            <span style="color: #615e83">0</span>
            <span style="color: #e5eafc">25%</span>
            <span style="color: #a9e9fe">50%</span>
            <span style="color: #3aa5ff">75%</span>
            <span style="color: #4a3aff">100%</span>
          </div>
        </a-card>
      </a-col>
    </a-row>
    <a-row :gutter="[16, 16]">
      <a-col>
        <a-card :bordered="false">
          <template #title>
            <div class="flex font-16">
              <span class="flex-1 black">工资发放数据</span>
              <router-link
                :to="{ name: '项目工资申请' }"
                class="pointer"
                style="color: #0874f0"
                >明细查询</router-link
              >
            </div>
          </template>
          <a-row class="mt-2 mb-1" type="flex" justify="space-around">
            <a-col
              class="payroll-card"
              v-for="(item, index) in payrollData"
              :key="index"
            >
              <div class="flex j-around a-center h-100">
                <img style="width: 42px; height: 42px" :src="item.img" alt="" />
                <div class="mt-2">
                  <h4 class="black-three">{{ item.title }}</h4>
                  <h4 class="black-three font-24">{{ item.value }}</h4>
                </div>
              </div>
            </a-col>
          </a-row>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  sevenDaysChart,
} from "./echarts";
import {
  getProWarnList,
  getProTeamData,
  getProTeamKq,
  getProMangerData,
  getProStaffData,
  getProPayrollData,
  getProDeviceData,
  getLwDetail,
  getProSevenDays,
} from "@/services/projectManagement";
import StandardTable from "@/components/table/StandardTable";
const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    align: "center",
  },
  {
    title: "预警单号",
    dataIndex: "jwWarnOddNum",
    align: "center",
    scopedSlots: { customRender: "overtext" },
  },
  {
    title: "预警(编号)名称",
    dataIndex: "name",
    align: "center",
    scopedSlots: { customRender: "overtext" },
  },
  {
    title: "生成时间",
    dataIndex: "warmDate",
    align: "center",
    scopedSlots: { customRender: "overtext" },
  },
  {
    title: "处理状态",
    dataIndex: "status",
    align: "center",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "处理时限",
    dataIndex: "timeoutTime",
    align: "center",
    scopedSlots: { customRender: "warmCode" },
  },
];
const deviceColumns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    align: "center",
  },
  {
    title: "品牌",
    dataIndex: "manufacturerKey",
    align: "center",
  },
  {
    title: "设备名称",
    dataIndex: "deviceName",
    align: "center",
  },
  {
    title: "状态",
    dataIndex: "online",
    align: "center",
    scopedSlots: { customRender: "online" },
  },
];
const payrollData = [
  {
    title: "累计发放工人工资金额总数(元)",
    key: "accumulatedTotal",
    value: "",
    img: require("@/assets/img/payroll-card1.png"),
  },
  {
    title: "本月发放工人工资金额总数(元)",
    key: "currMonthAccumulatedTotal",
    value: "",
    img: require("@/assets/img/payroll-card2.png"),
  },
  {
    title: "累计班组发放总数(人)",
    key: "teamNum",
    value: "",
    img: require("@/assets/img/payroll-card3.png"),
  },
  {
    title: "本月发放工人总数(人)",
    key: "staffNum",
    value: "",
    img: require("@/assets/img/payroll-card-4.png"),
  },
];

export default {
  name: "homePage",
  components: {
    StandardTable,
  },
  data() {
    return {
      activedKey: 2,
      peopleChart: null,
      environmentChart: null,
      safeChart: null,
      environmentChartCopy: null,
      sevenDaysChart: null,
      // currentGk: {}, // 当月安全管理统计汇总
      // workData: {}, // 劳务数据汇总
      // projectList: [],
      // defaultProject: "全部",
      // gcObj: {}, // 工程类型守护
      // ManagCWA: {}, // 管理人员数据
      // sevenKq: [],
      noCloseWarnList: [], // 建委预警列表
      columns,
      deviceColumns,
      pagination: {
        //分页数据
        current: 1,
        pageSize: 10,
        total: 0,
      },
      teamRankList: [], // 班组排名情况
      teamDataKq: {}, // 班组数据
      deviceData: {}, // 设备数组
      staffData: {}, // 工人数据
      payrollData, // 工资数据
      mangerData: {}, // 管理人员数据
      projectData: {}, // 项目数据
      sevenKq: {}, // 项目七日考勤情况
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true,
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: "#3ec13e", //滚动条颜色
          opacity: 0.8, //滚动条透明度
          "overflow-x": "hidden",
        },
      },
      deviceNum: 0,
    };
  },
  filters: {
    statusStr(val) {
      switch (val) {
        case 0:
          return "待处理";
        case 1:
          return "处理中";
        case 2:
          return "已处理";
        case 3:
          return "已超时";
      }
    },
  },
  computed: {
    ...mapGetters("account", ["user"]),
    getProgressColor() {
      return function (successPercent) {
        let color = "";
        if (successPercent < 25) {
          color = "#615e83";
        } else if (successPercent <= 50) {
          color = "#e5eafc";
        } else if (successPercent <= 75) {
          color = "#a9e9fe";
        } else if (successPercent <= 90) {
          color = "#3aa5ff";
        } else {
          color = "#4a3aff";
        }
        return color;
      };
    },
    textStyle() {
      return function (val) {
        if (val.status == 0) {
          return "";
        } else if (val.status == 1) {
          return "before-second";
        } else if (val.status == 2) {
          return "before-third";
        } else if (val.status == 3) {
          return "before-fourth";
        }
      };
    },
  },
  mounted() {
    this.init(); // 初始化数据
    this.getProWarnList(); // 建委预警列表
    this.getProTeamData(); // 班组出勤情况
  },
  beforeDestroy() {
    /* 页面组件销毁的时候，别忘了移除绑定的监听resize事件，否则的话，多渲染几次
    容易导致内存泄漏和额外CPU或GPU占用哦*/
    window.removeEventListener("resize", () => {
      this.sevenDaysChart.resize();
    });
  },
  methods: {
    handleChangeTitle(e) {
      this.activedKey = e;
    },
    // 建委预警列表
    getProWarnList() {
      const params = {
        projectId: this.user.projectId,
        pageNumber: 1,
        pageSize: 50,
      };
      getProWarnList(params)
        .then((res) => {
          if (res.data.code === 0) {
            // this.noCloseWarnList = res.data.data
            this.noCloseWarnList = res.data.data.filter(
              (item) => item.status != 2
            );
          }
        })
        .catch((e) => {
          this.$message.error("获取预警列表失败", 10);
        });
    },
    // 首页获取班组情况
    getProTeamData() {
      getProTeamData({ projectId: this.user.projectId }).then((res) => {
        this.teamDataKq = res.data.data;
      });
      const params = {
        projectId: this.user.projectId,
        pageNumber: 1,
        pageSize: 10,
      };
      getProTeamKq(params).then((res) => {
        this.teamRankList = res.data.data;
      });
    },
    init() {
      const params = {
        projectId: this.user.projectId,
      };
      // 获取项目详情数据
      getLwDetail(params).then((res) => {
        if (res.data) {
          this.projectData = { ...res.data, ...res.data.projectMsg };
          this.projectData.userName = res.data.userName;
        }
      });
      // 首页获取管理人员数据
      getProMangerData().then((res) => {
        if (res.data.code === 0) {
          this.mangerData = res.data.data;
        }
      });
      // 获取工人数据
      getProStaffData().then((res) => {
        if (res.data.code === 0) {
          this.staffData = res.data.data;
        }
      });
      // 获取工资数据
      getProPayrollData().then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data;
          this.payrollData.forEach((item) => {
            Object.keys(data).forEach((key) => {
              if (item.key == key) {
                item.value = data[key];
              }
            });
          });
        }
      });
      // 获取设备数据
      getProDeviceData().then((res) => {
        if (res.data.code === 0) {
          this.deviceData = res.data.data;
          if (this.deviceData.attendanceDeviceVoList) {
            this.deviceData.attendanceDeviceVoList.forEach((el) => {
              el.online = Number(el.online)
                ? "在线"
                : el.manufacturerKey === "HK"
                ? "/"
                : "不在线";
              if (el.manufacturerKey === "yunqi") {
                el.manufacturerKey = "云启";
              } else if (el.manufacturerKey === "HK") {
                el.manufacturerKey = "海康";
              }
            });
          }

        }
      });
      // 获取近七日出勤数据
      getProSevenDays({}).then((res) => {
        if (res.data.code === 0) {
          const week = [
            { days: this.fun_date(-7), glrys: 0, grs: 0 },
            { days: this.fun_date(-6), glrys: 0, grs: 0 },
            { days: this.fun_date(-5), glrys: 0, grs: 0 },
            { days: this.fun_date(-4), glrys: 0, grs: 0 },
            { days: this.fun_date(-3), glrys: 0, grs: 0 },
            { days: this.fun_date(-2), glrys: 0, grs: 0 },
            { days: this.fun_date(-1), glrys: 0, grs: 0 },
          ];
          let days = [];
          let glrys = [];
          let grs = [];
          let oldArr = [];
          oldArr = res.data.data.length > 0 ? res.data.data : week;
          oldArr.forEach((item) => {
            days.push(item.days);
            glrys.push(item.glrys);
            grs.push(item.grs);
          });
          this.sevenKq = {
            days,
            glrys,
            grs,
          };

          this.sevenDaysChart = sevenDaysChart(
            this.sevenKq,
            this.$refs.sevenDaysChart
          );

          window.addEventListener("resize", () => {
            // 第六步，执行echarts自带的resize方法，即可做到让echarts图表自适应
            this.sevenDaysChart.resize();
          });
        }
      });
    },
    fun_date(day) {
      let date1 = new Date();
      let date2 = new Date(date1);
      date2.setDate(date1.getDate() + day);
      let time2 =
        date2.getFullYear() +
        "-" +
        (date2.getMonth() + 1) +
        "-" +
        date2.getDate();
      return time2;
    },
    toDetail() {
      this.$router.push({ name: "项目详情" });
    },
  },
};
</script>
<style lang="less" scoped>
p {
  margin: 0;
}
.public {
  padding: 17px;
  background: #fff;
  border-radius: 10px;
}
.warning-content {
  position: absolute;
  top: 10px;
  left: 18px;
}
.green {
  color: rgb(4, 206, 0);
}
.red {
  color: #ff718b;
}
.lightgray {
  color: #4c4c4c;
}
.tb-box-2 {
  height: 155px;
  overflow: hidden;
  overflow-y: auto;
}
.home_container {
  /deep/ .ant-card {
    border-radius: 10px;
  }
  /deep/ .ant-card-head-title {
    padding: 0;
  }
  /deep/ .ant-card-head {
    // min-height: 45px;
    line-height: 48px;
    border-bottom: 1px solid rgb(218, 230, 255);
  }
  /deep/ .ant-card-body {
    padding: 17px;
  }
  .card-match {
    line-height: 27px;
    margin-top: auto;
  }
  .home-project {
    width: 126px;
    height: 82px;
  }

  .payroll-card {
    width: 350px;
    height: 93px;
    background: #f4f6ff;
    border-radius: 10px;
  }
  .wran-img {
    width: 210px;
    height: 120px;
  }
}
/deep/ .close-table {
  .ant-table-thead > tr > th {
    background: #f4f6ff;
    padding: 4px 16px;
    font-weight: 400;
    font-size: 14px;
    font-family: 微软雅黑;
    color: rgb(0, 0, 0);
  }
  .ant-table-tbody > tr > td {
    color: #4d4d4d;
    // color: #666;
    font-family: 微软雅黑;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0px;
    padding: 8px 8px;
    border-bottom: unset;
  }
  .ant-table-placeholder {
    padding: 8px 0;
  }
  .ant-empty-normal {
    margin: 0;
  }
  // 预警状态
  .statusDrop::before {
    width: 8px;
    height: 8px;
    background: #ee6945;
    content: "";
    display: inline-block;
    border-radius: 50%;
    margin-left: 15px;
    margin-right: 3px;
  }
  .before-second::before {
    background: #e5a858;
  }
  .before-third::before {
    background: #6ccc51;
  }
  .before-fourth::before {
    background: #656262;
  }
}
</style>
