<template>
    <div class="a_Approval">
        <a-form-model :model="params" class="clearfix" @submit="handleSubmit">
            <a-col :span="4" v-if="!isProject && !isBranchOffice">
                <a-form-model-item label="分公司" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">

                    <a-select v-model="params.branchOfficeId" allowClear placeholder="">
                        <!-- <a-select-option value="">全部</a-select-option> -->
                        <a-select-option v-for="item in onlyGcglbList" :key="item.value" :value="item.value"
                            :title="item.label">
                            {{ item.label }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
            </a-col>
            <a-col :span="5" v-if="!isProject">
                <a-form-model-item label="项目" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
                    <a-input v-model="params.projectName" allow-clear />
                </a-form-model-item>
            </a-col>
            <a-col :span="5">
                <a-form-model-item label="危大工程类别" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
                    <a-select v-model="params.gclbId" allowClear placeholder="" style="width: 180px">
                    
                        <a-select-option v-for="item in options1" :key="item.label" :value="item.value" :title="item.label">
                            {{ item.label }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
            </a-col>
            <a-col :span="4">
              <a-form-model-item label="工程名称" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 0 }">
                <a-input v-model="params.sgfaName" allow-clear />
                
                
              </a-form-model-item>
            </a-col>
            <a-col :span="4">
                <a-form-model-item label="是否超规模" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 0 }">

                    <a-select v-model="params.overRange" allowClear placeholder="" style="width: 120px">
                    
                        <a-select-option v-for="item in options" :key="item.label" :value="item.value" :title="item.label">
                            {{ item.label }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
            </a-col>
            <a-col :span="4">
                <a-form-model-item label="计划实施时间" :labelCol="{ span: 9 }" :wrapperCol="{ span: 14, offset: 0 }">

                    <a-date-picker v-model="params.planTime" valueFormat="YYYY-MM-DD" style="width: 140px" placeholder="" />
                </a-form-model-item>
            </a-col>


            <a-col :span="4">
                <a-space class="btnBox">
                    <a-button type="primary" html-type="submit"> 查询 </a-button>
                    <a-button @click="resetQuery"> 重置 </a-button>
                </a-space>
            </a-col>
        </a-form-model>
        <standard-table :columns="columns" :dataSource="tableData" rowKey="id" :pagination="false"
            :loading="tableLoading" @change="handleChangePage" :isAlert="false" :bordered="true" :btnArr="tableBtnArr"
             :scroll="{ x: 1400 }">
            <!-- @selectedRowChange="handleSelectedRowsChange" :totalBoolean="true" :rowColor="false" -->
            <template slot="index" slot-scope="{ index }">
                <span>{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}</span>
            </template>
            
            <template slot="overRange" slot-scope="{ record }">
                <a-tag color="#108ee9" v-if="record.overRange == 1">是</a-tag>
                <a-tag color="#f50" v-if="record.overRange == 0">否</a-tag>
            </template>
            <template slot="zxsgfa" slot-scope="{ record }">
                <a-tag color="#108ee9" v-if="record.overRange == 1">是</a-tag>
                <a-tag color="#f50" v-if="record.overRange == 0">否</a-tag>
            </template>
            <template slot="ipmlCode" slot-scope="{ record }">
                <a-tag color="#108ee9" v-if="record.ipmlCode == 1">未开始</a-tag>
                <a-tag color="#108ee9" v-if="record.ipmlCode == 2">持续中</a-tag>
                <a-tag color="#108ee9" v-if="record.ipmlCode == 3">已完成</a-tag>
            </template>

            <template slot="gcproessCode" slot-scope="{ record }">
                <div class="sTables">
                    <!-- 1.未开工2.持续中3.已完成 -->
                    <a-tag color="#108ee9" v-if="record.gcproessCode == 1">未开工</a-tag>
                    <a-tag color="#108ee9" v-if="record.gcproessCode == 2">持续中</a-tag>
                    <a-tag color="#108ee9" v-if="record.gcproessCode == 3">已完成</a-tag>
                </div>
            </template>
            <template slot="ysurl" slot-scope="{ record }">
                <div class="sTables">
                    {{record.gcproessCode}}
                </div>
            </template>

            <template slot="attach1" slot-scope="{ record }">
                <!-- {{ record }} -->
                <div class="flex j-center" style="flex-wrap: wrap;">
                    <div v-for="(item, index) in record.attachList1" :key="index">

                        <img alt="" style="width: 32px;height: 22.5px;" v-if="item.type == 'img'" :src="item.url"
                            @click="handlePreview(item)" />

                        <a-icon type="file-pdf" :style="{ fontSize: '32px' }" v-else-if="item.type == 'pdf'"
                            @click="handlePreview(item)" />
                        <a-icon type="file-text" :style="{ fontSize: '32px' }" v-else
                            @click="handlePreview(item)" />
                    </div>
                </div>
            </template>
            <template slot="managename" slot-scope="{ record }">
                <div>
                    <div>
                        {{record.managename}}
                    </div>
                    <div>
                        {{record.managephone}}
                    </div>
                </div>
            </template>
            
            <template slot="action" slot-scope="{ record }">
                <div class="sTables">
                    <div class="y-edit" @click="handleEdit(record)">编辑</div>
                    <div class="y-del" @click="handleDel(record)">删除</div>
                    <!-- <div v-if="record.attachList1.length> 0" class="y-other" @click="handleDownload(record)">下载</div> -->
                </div>
            </template>
        </standard-table>
        <detail :visible.sync="visible" :isEdit="isEdit" :projectInfo="projectInfo" :id="id" @closeDialog="closeDialog" @refresh="handleQuery" />

    </div>
</template>

<script>

import {
    wdgcplanList, wdgcplanDelete, wdgcplanExport, wdgcplanDownload
} from "@/services/dangerousProject";

import { mapGetters } from "vuex";
import StandardTable from "@/components/table/StandardTable";
import moment from "moment";
import detail from "./components/pmDetail.vue";
import { getProjectDetail } from "@/services/equipmentLedger";

import mixin from './components/mixinDm'

export default {
    name: "list",
    components: {
        StandardTable,
        detail
    },
    mixins: [mixin],
    data() {
        return {
            params: {
                "companyId": "", //公司id
                "branchOfficeId": "",  //分公司id
                "projectId": "",//项目id 
                "projectName": "",//项目名称
                "gclbId": "", //危大工程类别
                "sgfaName": "",//专项施工方案名称
                "overRange": "",//是否超一定规模
                "planTime": ""//计划实施时间
            },
            pagination: {
                //分页数据
                current: 1,
                pageSize: 10,
                total: 0,
                showTotal: (total, range) => `共 ${total} 条`,
            },
            total: 0,
            tableData: [],
            tableLoading: false, //table加载中
            selectedRows: [],
            tmpSelectedRows: [],

            visible: false,
            isEdit: 0,
            id: "",

            projectInfo: {
                parentOffice: '',
                parentOfficeId: '',
                gcglbId: '',
                gcglbName: '',
                projectId: '',
                projectName: ''
            }

        };
    },
    created() {
        
        if(this.isProject) {
            let d = this.user.loginLaoWuUser.projectId
            getProjectDetail(d).then(res => {

                if (res.data.code === 0) {
                    
                    this.projectInfo.parentOfficeId = res.data.data.parentOfficeId;
                    this.projectInfo.parentOffice = res.data.data.parentOffice;
                    this.projectInfo.gcglbId = res.data.data.gcglbId;
                    this.projectInfo.gcglbName = res.data.data.gcglbName;
                    this.projectInfo.projectId = res.data.data.projectId;
                    this.projectInfo.projectName = res.data.data.projectName;
                    
                    this.params.projectId = this.projectInfo.projectId;
                    this.params.companyId = this.projectInfo.parentOfficeId;
                    this.params.branchOfficeId = this.projectInfo.gcglbId;
                } 

                this.handleQuery();
            })
        } else {
            this.columns = this.columns.filter(el => el.title != '操作')
            this.handleQuery();
        }
    },
    computed: {
        tableBtnArr() {
            return [
                {
                    name: '新增',
                    attrs: {
                        type: 'primary'
                    },
                    hidden: !this.isProject,
                    click: this.addNewComplaint
                },
                {
                    name: '导出',
                    attrs: {
                        type: 'primary'
                    },
                    // hidden: !this.isJSOffice,
                    click: this.handleExport
                }
            ]
        }
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault();
            // this.params.pageNo = 1
            // this.pagination.current = 1
            this.handleQuery();
        },
        handleQuery(e) {
            this.tableData = []
            this.tableLoading = true
            let d = this.params;

            wdgcplanList(d).then((res) => {
                this.tableLoading = false
                if (res.data.code == 0) {
                    let list = res.data.data;

                    this.tableData = list.map((item, index) => {
                        let count1 = this.calcCount(list,item,index, 'parentId')

                        let attachList1 = this.calcAttach(item,'ysurl')

                        return {
                            ...item,
                            count1,
                            attachList1
                        };
                    })
                    this.pagination.total = res.data.data.total;
                } else {
                    this.$message.error(res.data.msg)
                }
            });
        },
        handleChangePage(e) {
            this.params.pageNo = e.current;
            this.params.page = e.pageSize;
            this.pagination.current = e.current;
            this.handleQuery();
        },
        addNewComplaint() {
            this.isEdit = 0
            this.id = ''
            this.visible = true
        },
        handleEdit(e) {

            this.isEdit = 1
            this.id = e.parentId
            this.visible = true
        },
        handleDel(record) {
            const _that = this
            this.$confirm({
                title: '是否确定删除？',
                cancelText: '否',
                okText: '是',
                onOk: () => {
                    wdgcplanDelete({ id: record.parentId }).then(res => {
                        if (res.data.code == 0) {
                            _that.$message.success('删除成功')
                            this.handleQuery(this.params);
                        } else {
                            _that.$message.error(res.data.msg)
                        }
                    })
                },
            })
        },
        resetQuery() {
            this.pagination.current = 1
            this.params = {
                "companyId": "", //公司id
                "branchOfficeId": "",  //分公司id
                "projectId": "",//项目id 
                "projectName": "",//项目名称
                "gclbId": "", //危大工程类别
                "sgfaName": "",//专项施工方案名称
                "overRange": "",//是否超一定规模
                "planTime": ""//计划实施时间
            };
            if(this.isProject) {
                this.params.projectId = this.projectInfo.projectId;
                this.params.companyId = this.projectInfo.parentOfficeId;
                this.params.branchOfficeId = this.projectInfo.gcglbId;
            } else {
                // 公司  分公司
            }
            this.handleQuery();
        },
        bathDownload() {
            // 取查询参数导出 companyId=&branchOfficeId=&projectId=&sgfaName=&overRange=&proveTime=&aproveTime=&makeTime=
            let url = wdgcplanExport + '?'+ `companyId=${this.params.companyId}&branchOfficeId=${this.params.branchOfficeId}&projectId=${this.params.projectId}&sgfaName=${this.params.sgfaName}&overRange=${this.params.overRange}&proveTime=${this.params.proveTime}&aproveTime=${this.params.aproveTime}&makeTime=${this.params.makeTime}`
            window.open(url)
        },
        handleExport() {
            // /wdgcplan/export-excel?companyId=&branchOfficeId=&projectId=&projectName=&gclbId=&sgfaName=&overRange=&planTime=
            let url = wdgcplanExport + '?'+ `companyId=${this.params.companyId}&branchOfficeId=${this.params.branchOfficeId || ''}&projectId=${this.params.projectId}&projectName=${this.params.projectName}&gclbId=${this.params.gclbId || ''}&sgfaName=${this.params.sgfaName}&overRange=${this.params.overRange || ''}&planTime=${this.params.planTime}`
            window.open(url)
        },
        handleDownload(val){
            // /wdgcplan/download?id=2
            let url = wdgcplanDownload + '?id='+val.id
            window.open(url)

        },
        closeDialog() {
            this.visible = false
        },

        

    },
};
</script>

<style lang="less" scoped>
.query-form {
    display: flex;
    // justify-content: space-between;
}

.post-border {
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 5px;
    padding-top: 5px;
    // padding-top: 10px;
    // height: 40px;
}

.post-border:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

.sTables {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.y-edit {
    color: #307dfa;
    cursor: pointer;
}
.y-del {
    color: #ff5c77;
    cursor: pointer;
}
.y-other {
    color: #615e83;
    cursor: pointer;
}
</style>