var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form-model",
        {
          staticClass: "mb-5 query-form",
          attrs: { layout: "inline", model: _vm.params },
          on: { submit: _vm.handleSubmit },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "分公司" } },
            [
              _c(
                "a-select",
                {
                  staticStyle: { width: "150px" },
                  attrs: { allowClear: "", placeholder: "请选择" },
                  model: {
                    value: _vm.params.gcglbId,
                    callback: function ($$v) {
                      _vm.$set(_vm.params, "gcglbId", $$v)
                    },
                    expression: "params.gcglbId",
                  },
                },
                _vm._l(_vm.gcglbList, function (item) {
                  return _c(
                    "a-select-option",
                    {
                      key: item.value,
                      attrs: { value: item.value, title: item.name },
                    },
                    [_vm._v(" " + _vm._s(item.name) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "日期" } },
            [
              _c("a-range-picker", {
                staticStyle: { width: "220px" },
                attrs: { format: "YYYY-MM-DD" },
                on: { change: _vm.onChange },
                model: {
                  value: _vm.dateGroup,
                  callback: function ($$v) {
                    _vm.dateGroup = $$v
                  },
                  expression: "dateGroup",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "项目名称" } },
            [
              _c("a-input", {
                staticStyle: { width: "200px" },
                attrs: { placeholder: "请输入项目名称", allowClear: "" },
                model: {
                  value: _vm.params.projectName,
                  callback: function ($$v) {
                    _vm.$set(_vm.params, "projectName", $$v)
                  },
                  expression: "params.projectName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "巡检状态" } },
            [
              _c(
                "a-select",
                {
                  staticStyle: { width: "200px" },
                  attrs: { placeholder: "请选择巡检状态", allowClear: "" },
                  model: {
                    value: _vm.params.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.params, "status", $$v)
                    },
                    expression: "params.status",
                  },
                },
                _vm._l(_vm.selectlist, function (items) {
                  return _c(
                    "a-select-option",
                    {
                      key: items.name,
                      attrs: { value: items.value, title: items.name },
                    },
                    [_vm._v(" " + _vm._s(items.name) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "检查日期排序" } },
            [
              _c(
                "a-select",
                {
                  staticStyle: { width: "110px" },
                  attrs: { placeholder: "请选择排序", allowClear: "" },
                  model: {
                    value: _vm.params.sortType,
                    callback: function ($$v) {
                      _vm.$set(_vm.params, "sortType", $$v)
                    },
                    expression: "params.sortType",
                  },
                },
                _vm._l(_vm.sortlist, function (items) {
                  return _c(
                    "a-select-option",
                    {
                      key: items.name,
                      attrs: { value: items.value, title: items.name },
                    },
                    [_vm._v(" " + _vm._s(items.name) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    { attrs: { type: "primary", "html-type": "submit" } },
                    [_vm._v(" 查询 ")]
                  ),
                  _c("a-button", { on: { click: _vm.resetQuery } }, [
                    _vm._v(" 重置 "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "a_a_footer" },
        [
          _c(
            "div",
            [
              _c(
                "a-space",
                { staticClass: "operator" },
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.addNewPlan },
                    },
                    [_vm._v("新增")]
                  ),
                ],
                1
              ),
              _c(
                "a-space",
                { staticClass: "operator" },
                [
                  _vm.user.isGcglb == 0 && _vm.user.loginType == 1
                    ? _c(
                        "a-button",
                        { staticClass: "mr-9", on: { click: _vm.doDowload } },
                        [_vm._v(" 导出 ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("standard-table", {
            attrs: {
              columns: _vm.columns,
              dataSource: _vm.tableData,
              rowKey: "id",
              pagination: _vm.pagination,
              loading: _vm.tableLoading,
              isAlert: false,
              scroll: { x: 1300 },
              bordered: true,
            },
            on: { change: _vm.handleChangePage },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function (ref) {
                  var index = ref.index
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          (_vm.pagination.current - 1) *
                            _vm.pagination.pageSize +
                            parseInt(index) +
                            1
                        )
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "proInfo",
                fn: function (ref) {
                  var record = ref.record
                  return [
                    _c("div", [
                      _vm._v(_vm._s(record.xmjl + "/" + record.xmjlPhone)),
                    ]),
                    _c(
                      "a-tooltip",
                      { attrs: { placement: "topLeft" } },
                      [
                        _c("template", { slot: "title" }, [
                          _vm._v(" " + _vm._s(record.projAddress) + " "),
                        ]),
                        _c("div", { staticClass: "ellipsis-2" }, [
                          _vm._v(_vm._s(record.projAddress)),
                        ]),
                      ],
                      2
                    ),
                  ]
                },
              },
              {
                key: "inspectonDate",
                fn: function (ref) {
                  var record = ref.record
                  return [
                    _c("div", [_vm._v(_vm._s(record.checkDate))]),
                    _c("div", [_vm._v(_vm._s("" + record.week))]),
                  ]
                },
              },
              {
                key: "problems",
                fn: function (ref) {
                  var index = ref.index
                  var record = ref.record
                  return _vm._l(record.problems, function (problem, i) {
                    return _c("div", { key: i }, [_vm._v(_vm._s(problem))])
                  })
                },
              },
              {
                key: "action",
                fn: function (ref) {
                  var text = ref.text
                  var record = ref.record
                  return _c("div", {}, [
                    _c(
                      "a",
                      {
                        on: {
                          click: function () {
                            return _vm.handleEdit(record)
                          },
                        },
                      },
                      [_vm._v(" 查看 ")]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "ml-2",
                        staticStyle: { color: "red" },
                        on: {
                          click: function () {
                            return _vm.handleDel(record)
                          },
                        },
                      },
                      [_vm._v(" 删除 ")]
                    ),
                  ])
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("Export-loading", {
        attrs: {
          cPercent: _vm.cPercent,
          isExporting: _vm.isExporting,
          loadingText: _vm.loadingText,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }