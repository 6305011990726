<!-- 入场审批 - 个人申请 -->
<template>
  <div>
    <div class="a_a_header">
      <!-- 表单封装
          projectObj：下拉弹出框第一项对象
          Overview：项目名称
          baiduMapBoolen：控制地图显示
          topSubmit：控制上部分按钮显示
          bottomSubmit：控制下部分按钮显示
          handleSubmit：表单提交
          handleChange：输入出发
          handlSelect：输入下拉选择触发
      -->
      <form-index ref="formindex" :formData="formData" @handleSubmit="handleSubmit" :flexAction="true" @handlSelect="handlSelect">
        <!-- 下部分按钮插槽 -->
        <template #flexAction>
          <a-space :style="{ marginTop: '4px' }">
          <a-button icon="search" type="primary" html-type="submit"> 查询 </a-button>
          <a-space class="operator">
            <a-button @click="close"> 重置 </a-button>
          </a-space>
          </a-space>
        </template>
      </form-index>
    </div>

    <div class="a_a_footer">
      <div class="mb-1">
        <a-space class="operator">
          <a-button @click="doDowloadBatch(2)"><i class="iconfont bohui mr-05 "></i>批量驳回</a-button>
        </a-space>
        <a-space class="operator" v-if="canOperate">
          <a-button @click="batchModify()"><i class="iconfont fenpei mr-05 "></i>批量分配班组</a-button>
        </a-space>
        <a-space class="operator">
          <a-button @click="exportStaff()"><a-icon type="download" />导出待审批人员信息</a-button>
        </a-space>
      </div>
      <!-- 表格封装 -->
      <standard-table class="" :columns="columns" :dataSource="dataSource" :selectedRows.sync="selectedRows" rowKey="id"
        :scroll="{ x: 1300 }" :pagination="pagination" :loading="tableLoading" @change="onPageChange" :isAlert="false"
        :bordered="true" @update:selectedRows="updateSelectRows($event)">
        <template slot="index" slot-scope="{ index }">
          <span>
            {{
              (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1
            }}
          </span>
        </template>
        <template slot="staffName" slot-scope="{ record }">
          <router-link style="display: block;" :to="`/personal_info?projectPersonId=${record.projectPersonId}`">{{ record.staffName }}</router-link>
            <a-tag color="#ee3f4d" v-for="(item,index) in record.riskTipsText" :key="index">
              <template v-if="record.riskTipsList && item == '多项目'">
                <a-popover v-if="showTooltip(record.riskTipsList)">
                  <template slot="content">
                    <div v-for="(item,index) in showNames(record.projectNames)" :key="index">{{item}}</div>
                  </template>
                  <template slot="title">
                    <span style="color: #FF5D78">已在场项目</span>
                  </template>
                  <span>{{item}}</span>
                </a-popover>
              </template>
              <template v-else>
                {{item}}
              </template>
            </a-tag>
        </template>
        <template slot="filePath" slot-scope="{ text }">
          <a-avatar shape="square" :size="64" :src="text" icon="user"/>
        </template>
        <template slot="planExitDate" slot-scope="{ text }">
          <span>{{text ? text : '/'}}</span>
        </template>
        <div slot="action" slot-scope="{ record }">
          <div class="sTables">
            <div v-if="canOperate" style="color: #307dfa" @click="batchModify(record)">分配班组</div>
            <div  style="color: #ff5c77" @click="doDowloadBatch(2,record)">驳回</div>
            <a-popover placement="bottomRight" trigger="click">
              <template slot="content">
                <div class="content_div" @click="visit(record)">查看</div>
                <div class="content_div" @click="setInfo(record)">修改</div>
              </template>
              <template slot="title">
                <span>操作</span>
              </template>
              <div>更多></div>
            </a-popover>
          </div>
        </div>
      </standard-table>
    </div>
    <a-modal forceRender v-model="teamDialogVisible" title="分配班组" @cancel="cancleModifyPersonTeam" @ok="modifyPersonTeam" :width="650" :destroyOnClose="true">
      <form-index ref="formindexModal" @onDateChange="onDateChange" :formData="formDataModal" :flexSubmit="true" @handlSelect="handlSelect"></form-index>
    </a-modal>
    <Export-loading :cPercent="cPercent" :isExporting="isExporting" :loadingText="loadingText"></Export-loading>
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import { getApprovePersonListByProjectId, teamPersonapprove } from "@/services/projectManagement";
import { getAllTeams,enablePlanExit, checkPlanExitDate } from "@/services/participationUnit"
import { getOfficeListByProjectId } from '@/services/payroll'
import moment from 'moment'
import { mapGetters } from "vuex";
import { debounce } from "lodash";
import { exportProgress } from "@/pages/aqjyzl/export"; // 导出方法
import ExportLoading from '@/components/ExportLoading/ExportLoading' // progress组件
import { EXPORT_PRO_STAFF_APPLY } from '@/services/api'
import { useRender } from '@/hooks/useRender.js'

const formData = [
  {
    label: "姓名",
    placeholder: "请填写",
    decorator: [
      "staffName",
      { rules: [{ required: false, message: "请填写" }] },
    ],
    type: "input",
    key: "staffName",
    col: 4,
    labelCol: 6,
    wrapperCol: 15,
    display: true,
  },
  {
    label: "身份证号",
    placeholder: "请填写",
    decorator: ["idCard", { rules: [{ required: false, message: "请填写" }] }],
    type: "input",
    key: "idCard",
    col: 5,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "申请时间",
    placeholder: "请填写",
    decorator: [
      "applyDate",
      { rules: [{ required: false, message: "请填写" }] },
    ],
    type: "rangePicker",
    key: "applyDate",
    col: 5,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
    format: "yyyy-MM-DD",
  },
  {
    label: "风险提示",
    placeholder: "请选择",
    decorator: [
      "riskTips",
      { rules: [{ required: false, message: "请选择" }] },
    ],
    type: "select",
    key: "riskTips",
    selectlist: [{
      value: 1,
      name: '诚信风险'
    },{
      value: 2,
      name: '超龄'
    },{
      value: 3,
      name: '多项目'
    },{
      value: 4,
      name: '重点民族、地区'
    }],
    col: 5,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    type: 'action',
    col: 3,
    display: true,
  }
];

const formDataModal = [
{
    label: "所属单位",
    placeholder: "请填写",
    decorator: [
      "officeId",
      { rules: [{ required: true, message: "请填写" }] },
    ],
    type: "select",
    key: "officeId",
    selectlist: [],
    col: 12,
    labelCol: 7,
    wrapperCol: 14,
    display: true,
    showSearch: true
  },
  {
    label: "所属班组",
    placeholder: "请选择",
    decorator: ["teamId", { rules: [{ required: true, message: "请选择" }] }],
    type: "select",
    key: "teamId",
    selectlist: [],
    col: 12,
    labelCol: 7,
    wrapperCol: 14,
    display: true,
    showSearch: true
  },
  {
    label: "拟退场时间",
    placeholder: "请选择拟退场时间",
    decorator: [
      "planExitDate",
      { rules: [{ required: false, message: "请选择拟退场时间" }] },
    ],
    type: "datePicker",
    format: "yyyy-MM-DD",
    key: "planExitDate",
    dataSource: [],
     labelCol: 7,
    wrapperCol: 14,
    col: 12,
    display: true,
    styles: {width: '100%'},
    defaultPickerValue: moment().add(30, 'days'),
    disabledDate(date) {
      // 1. 拟退场时间必须晚于入场时间，入场时间之前（含入场当日）不可选
      let entranceTime = new Date();
      // 2. 拟退场时间-当前时间往后延 30天 2024.7.5 垃圾需求
      entranceTime.setDate(entranceTime.getDate() + 29);
      return date <= entranceTime;
    },
  },
]
export default {
  mixins: [exportProgress], //导出方法注入
  components: {
    formIndex,
    StandardTable,
    ExportLoading
  },
  data() {
    const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
  },
  {
    title: "姓名",
    dataIndex: "staffName",
    align: "center",
    width: 150,
    scopedSlots: { customRender: "staffName" },
  },
  {
    title: "头像",
    dataIndex: "filePath",
    align: "center",
    scopedSlots: { customRender: "filePath" },
  },
  {
    title: "年龄",
    dataIndex: "age",
    align: "center",
    scopedSlots: { customRender: "age" },
  },
  {
    title: "身份证号",
    dataIndex: "idCard",
    align: "center",
    scopedSlots: { customRender: "idCard" },
  },
  // {
  //   title: "风险提示",
  //   dataIndex: "riskTipsTextList",
  //   align: "center",
  //   key: "riskTipsTextList",
  //   scopedSlots: { customRender: "riskTipsTextList" },
  // },
  {
    title: "申请时间",
    dataIndex: "enterDate",
    align: "center",
    key: "enterDate",
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "拟退场时间",
    dataIndex: "planExitDate",
    align: "center",
    key: "planExitDate",
    scopedSlots: { customRender: "planExitDate" },
  },
  {
    title: "操作",
    align: "center",
    fixed: "right",
    width: 150,
    key: 'action',
    scopedSlots: { customRender: "action" },
  },
];
    return {
      formData: formData, //form表单数据
      columns: columns, //table数据key对应
      dataSource: [], //table数组
      pagination: {
        //分页数据
        showSizeChanger:true,
        pageSizeOptions: ['10', '50', '100', '150'],
        current: 1,
        pageSize: 10,
        total: 0,
        showLessItems:true,
        showQuickJumper:true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      tableLoading: false, //table加载中
      selectedRows: [], //选中的数据
      prams: { //测试数据
        pageNumber: 1,
        pageSize: 10,
      },
      teamDialogVisible: false,
      formDataModal: formDataModal,
      singleRecord: {},
      riskTips:'riskTips',
      arrRoles:['zong_bao_lao_wu','fen_bao_lao_wu'], // 审批权限
      cPercent: 0,
      isExporting:false,
      loadingText:"请稍后.",
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
    canOperate() {
      return this.user.nowRoleKey.some(role => this.arrRoles.includes(role));
    },
  },
  created() {
    this.aqxjlist(this.prams);
    this.getOfficeListByProjectId()
  },
  mounted() {
    if (this.canOperate) {
      this.columns.map(item => {
          if (item.key == 'action') {
               item.width = 230
          }
      })
    }
  },
  methods: {
    updateSelectRows(e) {
      console.log(e)
    },
    async handlSelect(obj) {
      const { val, keys } = obj
      if (keys === 'officeId') {
        this.doQueryTeams({ officeId: val })
        const isPlanExit = await this.enablePlanExit(val)
        // 校验是否必填拟退场
        this.checkExitDate(isPlanExit)
      }
    },
    // 通过、驳回
    doDowloadBatch(isPass,record) {
      if (!record) {
        this.selectedRows.forEach(item => {
          item.isPass = isPass
        });
        const title = isPass == 1 ? '批量通过' : '批量驳回'
        const content = isPass == 1 ? `是否批量通过${this.selectedRows.length}条` : `是否批量驳回${this.selectedRows.length}条`
        this.teamPerson(this.selectedRows,title,content)
      } else {
        record.isPass = isPass
        const title = isPass == 1 ? '通过' : '驳回'
        const content = isPass == 1 ? `是否通过` : `是否驳回`
        this.teamPerson([record],title,content)
      }
    },
    // 测试数据
    // ptypeState(type, status) {
    //   if (type == "ptype") {
    //     return status == 1 ? "房建" : "市政";
    //   } else {
    //     return status == 0 ? "在建" : status == 1 ? "完工" : "停工";
    //   }
    // },
    //通过、驳回请求
    teamPerson(prams,title,content) {
      this.$confirm({
        title: title,
        content: content,
        onOk: () => {
          this.tableLoading = true
          teamPersonapprove(prams).then((res) => {
            if (res.data.code === 0) {
              this.aqxjlist(this.prams);
              this.$message.success('操作成功')
            } else {
              this.tableLoading = false
              const str = res.data.msg
              // 去除;分号
              const msg = str.replace(/;/g, '');
              this.$message.error(msg)
            }
          })
        },
      })

    },
    //查看
    visit(record) {
      this.$router.push({
          path:'/personal_info',
          query:{
            projectPersonId: record.projectPersonId
          }
      })
    },
    setInfo(record) {
      this.$router.push({
          name:'修改人员信息',
          query:{
            projectPersonId: record.projectPersonId,
            applyType: 2
          }
      })
    },
    // 分配班组
    async batchModify(record){
      if (record) {
        // 先置空
        this.selectedRows = []
        this.selectedRows.push(record)
        this.teamDialogVisible = true
      } else {
        if (this.selectedRows.length < 1) {
          this.$message.warning('请选择人员')
        } else {
          this.teamDialogVisible = true
        }
      }
    },
    //分配班组请求
    modifyPersonTeam:debounce(function (e) {
      this.$refs.formindexModal.combinationSubmit().then(res => {
        this.selectedRows.forEach(item => {
          item.teamId = res.teamId
          item.officeId = res.officeId
          item.isPass = 1
          item.isTeamBatch = 1 // 是否批量分配修改班组
          if (res.planExitDate) {item.planExitDate = res.planExitDate}
        })
        teamPersonapprove(this.selectedRows).then((res) => {
          if (res.data.code === 0) {
            this.selectedRows = []
            this.$message.success('分配成功')

            this.aqxjlist(this.prams);
          } else {
            this.$message.error(res.data.msg,10)
          }
          this.teamDialogVisible = false
        })
      })
    },1000),
    //列表请求数据
    aqxjlist(prams) {
      this.tableLoading = true;
      getApprovePersonListByProjectId(prams).then((res) => {
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
          this.selectedRows = []
          if (this.dataSource && this.dataSource.length > 0) {
            this.dataSource.forEach((item) => {
              item.riskTipsText = item.riskTipsTextList.length > 0 ? item.riskTipsTextList : []
              item.riskTipsTextList = item.riskTipsTextList.length > 0 ? item.riskTipsTextList.join(',') : '/'
              item.enterDate = item.enterDate ? moment(item.enterDate).format('YYYY-MM-DD') : undefined
            });
          }
        } else {
          this.$message.error(res.data.msg)
        }
        this.tableLoading = false;
      });
    },
    //查询所有班组
    doQueryTeams(params){
      getAllTeams(params).then(res => {
        if(res.data.code === 0){
          this.formDataFn('teamId', res.data.data, 'teamName')
        }
      })
    },
    // 对下拉框做数据处理
    formDataFn(key, data, itemName) {
        let arr = []
        data.forEach(item => {
            arr.push({ 'name': item[itemName], 'value': item.id })
        })
        this.formData.forEach(item => {
            if (item.key == key) {
                item.selectlist = arr
            }
        })
        this.formDataModal.forEach(item => {
            if (item.key == key) {
                item.selectlist = arr
            }
        })
    },
    //查询所有单位
    getOfficeListByProjectId(){
        getOfficeListByProjectId().then((res) => {
          if(res.data.code === 0){
            this.formDataFn('officeId', res.data.data, 'name')
          }
        })
    },
    // 提交表单数据
    handleSubmit(e) {
      let obj = { ...e, ...this.prams }
      obj.pageNumber = 1
      obj.applyDate = obj.applyDate === '' ? [] : obj.applyDate
      this.aqxjlist(obj);
    },
    //表格 - 分页查询
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      if (obj.status == "") {
        obj.status = 0;
      }
      this.prams.pageNumber = e.current;
      this.pagination.current = e.current;
      this.prams.pageSize = e.pageSize;
      this.pagination.pageSize = e.pageSize;
      obj.applyDate = obj.applyDate === '' ? [] : obj.applyDate
      this.aqxjlist(Object.assign({}, this.prams, obj));
    },
    // 重置
    close() {
      this.prams = {
        pageNumber: 1,
        pageSize: 10,
      };
      this.prams.pageNumber = 1;
      this.pagination.current = 1;
      this.aqxjlist(this.prams);
      this.$refs["formindex"].resetFields();
    },
    // 导出人员信息
    exportStaff() {
      let formObj = this.$refs["formindex"].getFieldValue()
      this.loadingText = "正在导出个人申请人员信息，请稍候 ····";
      let recordIdList = []
      if (this.selectedRows.length > 0) {
        this.selectedRows.forEach(el => {
          recordIdList.push(el.idCard)
        })
      }
      let fileName = '个人申请人员信息.xls'
      let obj = {
        officeId: formObj.officeId ? formObj.officeId : undefined,
        projectId: this.user.projectId,
        teamId: formObj.teamId ? formObj.teamId : undefined,
        idCards: recordIdList.length ? recordIdList : undefined,
        applyStart: formObj.applyDate[0] ? formObj.applyDate[0] : undefined,
        applyEnd: formObj.applyDate[1] ? formObj.applyDate[1] : undefined
      }
      this.isExporting=true;
      this.cPercent=0;
      this.exportFunNomal2(EXPORT_PRO_STAFF_APPLY,obj,fileName)
      this.selectedRows = []
    },
    showTooltip(riskTips) {
      if (Array.isArray(riskTips) && riskTips.length > 0) {
        return riskTips.some(item => item == 3)
      } else {
        return false
      }
    },
    showNames(projects){
      return projects.split(',')
    },
    // 校验是否开启拟退场
    async enablePlanExit(officeId) {
      const params = {
        officeId: officeId,
        projectId: this.user.projectId
      }
      const res = await enablePlanExit(params)
      return res.data.data
    },
    // 校验是否必填拟退场
    checkExitDate(check) {
      this.formDataModal.forEach(el => {
        if (el.key === 'planExitDate') {
          if (check === true) {
            el.decorator.forEach((items) => {
              if (items instanceof Object) {
                items.rules[0].required = true;
              }
            })
          } else {
            el.decorator.forEach((items) => {
              if (items instanceof Object) {
                items.rules[0].required = false;
              }
            })
          }
        }
      })
    },
    cancleModifyPersonTeam() {
      this.teamDialogVisible = false
      this.$refs["formindexModal"].resetFields()
      this.selectedRows = []
    },
    onDateChange(date) {
      if (this.selectedRows.length > 0) {
        const idCards = []
        this.selectedRows.forEach(record => {
          idCards.push(record["idCard"])
        })
        const planExitParam = {
          idCards,
          projectId: this.user.projectId,
          planExitDate: date
        }
        checkPlanExitDate(planExitParam).then(res => {
          if (res.data.code == 0) {
            this.batchExitDate = false
          } else {
            this.$refs.formindexModal.setFieldsValue('planExitDate', {'planExitDate': null}, 'planExitDate')
            this.batchExitDate = true
            this.$message.error(res.data.msg,10)
          }
        })
      }
    }
  },
};
</script>

<style lang="less" scoped>
  .a_a_header {
    background: #ffffff;
    border-radius: 10px;

    /deep/ .ant-form-item {
      margin-bottom: 15px;
    }

    // .plug-form[data-v-372326e8] .ant-form .ant-btn {
    //   margin-top: 5px;
    //   margin-left: 30px;
    // }
  }

  .a_a_footer {
    padding: 0 20px;
    background: #ffffff;

    .operator {
      margin-right: 8px;

      .ant-btn {
        color: #307dfa;
      }
    }

    .sTables {
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        width: 100%;
        text-align: center;
        cursor: pointer;
      }

      div:nth-child(1) {
        color: #307dfa;
      }

      div:nth-child(2) {
        color: #ff5c77;
      }

      div:nth-child(3) {
        color: #615e83;
      }
    }
  }

.content_div {
  padding: 5px 0;
  cursor: pointer;
}

.content_div:hover {
  color: #307dfa;
}
.riskTips {
  background: rgb(255, 232, 236);
  border-radius: 2px;
  color: #FF5D78;
  font-size: 14px;
  padding: 5px 9px;
}
/deep/ .ant-modal-footer {
    text-align: center;
    border-top: none;
    padding-bottom: 24px;
  }
  /deep/ .ant-modal-body {
    padding-bottom: unset;
  }
</style>
