<template>
  <div>
    <standard-table
      class=""
      :columns="columns"
      :dataSource="dataSource"
      rowKey="id"
      :pagination="pagination"
      :loading="tableLoading"
      :isAlert="false"
      :bordered="true"
    >
    </standard-table>
  </div>
</template>

<script>
import StandardTable from "@/components/table/StandardTable";
import { useRender } from '@/hooks/useRender.js'

const columns = [
  {
    title: "姓名",
    dataIndex: "staffName",
    align: "center",
    scopedSlots: { customRender: "staffName" },
  },
  {
    title: "项目名称",
    dataIndex: "filePath",
    align: "center",
    scopedSlots: { customRender: "filePath" },
  },
  {
    title: "课程名称",
    dataIndex: "age",
    align: "center",
    scopedSlots: { customRender: "age" },
  },
  {
    title: "培训形式",
    dataIndex: "idCard",
    align: "center",
    scopedSlots: { customRender: "idCard" },
  },
  {
    title: "培训时间",
    dataIndex: "riskTips",
    align: "center",
    ellipsis: true,
    key: "riskTips",
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "上传附件",
    align: "center",
    fixed: "right",
    width: 200,
    scopedSlots: { customRender: "action" },
  },
];
export default {
  components: {
    StandardTable
  },
  data() {
    return {
      columns: columns, //table数据key对应
      dataSource: [], //table数组
      pagination: {
        //分页数据
        current: 1,
        pageSize: 10,
        total: 0,
      },
      tableLoading: false, //table加载中
      selectedRows: [], //选中的数据
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>

<style lang="less" scoped>

</style>