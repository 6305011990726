<!-- 这个布局组件被 TabsView 使用 -->
<template>
  <a-layout :class="['admin-layout', 'beauty-scroll']">
    <drawer v-if="isMobile" v-model="drawerOpen">
      <side-menu :theme="theme.mode" :menuData="menuData" :collapsed="false" :collapsible="false" @menuSelect="onMenuSelect"/>
    </drawer>
    <side-menu :class="[fixedSideBar ? 'fixed-side' : '']" :theme="theme.mode" v-else-if="layout === 'side' || layout === 'mix'" :menuData="sideMenuData" :collapsed="collapsed" :collapsible="true" />
    <div v-if="fixedSideBar && !isMobile" :style="`width: ${sideMenuWidth}; min-width: ${sideMenuWidth};max-width: ${sideMenuWidth};`" class="virtual-side"></div>

    <changePwd :visible.sync="user.pwdStrong == 0 ? true : false"/>
    <a-layout class="admin-layout-main beauty-scroll">
      <!-- 头部 -->
      <admin-header :class="[{'fixed-tabs': fixedTabs, 'fixed-header': fixedHeader, 'multi-page': multiPage}]" :style="headerStyle" :menuData="headMenuData" :collapsed="collapsed" @toggleCollapse="toggleCollapse"/>
      <a-layout-header :class="['virtual-header', {'fixed-tabs' : fixedTabs, 'fixed-header': fixedHeader, 'multi-page': multiPage}]" v-show="fixedHeader"></a-layout-header>
      <!-- 内容区，自适应撑满高度 -->
      <a-layout-content :class="multiPage ? '' : 'content-multiPage'" class="admin-layout-content beauty-scroll">
        <!-- 由 TabsView 传入内容 -->
        <slot name="tabBar"></slot>
        <slot name="content"></slot>
        <!-- 底部 -->
        <page-footer v-if="!realmType" :link-list="footerLinks" :copyright="copyright" />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import AdminHeader from './header/AdminHeader'
import PageFooter from './footer/PageFooter'
import Drawer from '../components/tool/Drawer'
import SideMenu from '../components/menu/SideMenu'
// import Setting from '../components/setting/Setting'
import {mapState, mapMutations, mapGetters} from 'vuex'

// const minHeight = window.innerHeight - 64 - 122
import changePwd from "@/pages/login/changePwd"

export default {
  name: 'AdminLayout',
  components: { SideMenu, Drawer, PageFooter, AdminHeader,changePwd},
  data () {
    return {
      minHeight: window.innerHeight - 64 - 122,
      disableControlCollapsed: false,
      collapsed: false,
      showSetting: false,
      drawerOpen: false,
      visiblePwd:false 
    }
  },
  provide() {
    return {
      adminLayout: this
    }
  },
  watch: {
    $route(val) {
      this.setActivated(val)
    },
    layout() {
      this.setActivated(this.$route)
    },
    isMobile(val) {
      if(!val) {
        this.drawerOpen = false
      }
    },
    pwdStrong(val) {
      if (val) {
        this.visiblePwd = true
      }
    },
    // 监听左侧菜单栏数据变更，若只有一项菜单，自动隐藏菜单栏
    sideMenuData(menuData) {
      // 如果用户主动操作了显隐状态，则不进行干预了
      if (this.disableControlCollapsed) return 
      // 过滤设置了隐藏的菜单，还要兼容下改造前的invisible
      const effectMenu = menuData.filter((menu) => menu.visible || !menu.meta.invisible)
      // 有效菜单数为1，收起菜单
      this.collapsed = effectMenu.length === 1
    }
  },
  computed: {
    ...mapState('setting', ['isMobile', 'theme', 'layout', 'footerLinks', 'copyright', 'fixedHeader', 'fixedSideBar',
      'fixedTabs', 'hideSetting', 'multiPage']),
    ...mapGetters('setting', ['firstMenu', 'subMenu', 'menuData']),
    ...mapGetters('account', ['user']),
    sideMenuWidth() {
      return this.collapsed ? '80px' : '256px'
    },
    headerStyle() {
      let width = (this.fixedHeader && this.layout !== 'head' && !this.isMobile) ? `calc(100% - ${this.sideMenuWidth})` : '100%'
      let position = this.fixedHeader ? 'fixed' : 'static'
      return `width: ${width}; position: ${position};`
    },
    realmType () {
      return this.$store.state.setting.realmType
    },
    headMenuData() {
      const {layout, menuData, firstMenu} = this
      return layout === 'mix' ? firstMenu : menuData
    },
    sideMenuData() {
      const {layout, menuData, subMenu} = this
      let isGcglb= this.$store.getters["account/user"].isGcglb
      let isleader=this.$store.getters["account/user"].nowRoleKey.includes('OFFICE_LEADER')
      let isDzbsj=this.$store.getters["account/user"].nowRoleKey.includes('OFFCIE_DZBSJ')
      //console.log(isDzbsj)
      if(isGcglb===1){
        menuData.forEach((item,index)=>{
          if(item.name=="安全隐患排查"){
            // debugger
            // if(isleader){
            //   let arr=item.children.filter(e=>{
            //     return e.name != "安全隐患排查周报"&&e.name!="安全隐患排查记录汇总"
            //   })
            //   menuData[index].children=arr

            // }else{
            //   let arr=item.children.filter(e=>{
            //     return e.name != "安全隐患排查周报"&&e.name!="安全隐患排查记录汇总"
            //   })
            //   menuData[index].children=arr

            // }
            if(isDzbsj){
              let arr=item.children.filter(e=>{
                return e.name != "安全隐患排查周报"
              })
              menuData[index].children=arr
            }else{
              let arr=item.children.filter(e=>{
                return e.name != "安全隐患排查周报"&&e.name!="安全隐患排查记录汇总"
              })
              menuData[index].children=arr
            }

          }
          if(item.name=="安全验收"){
            const arr=item.children.filter(e=>{
              return e.name != "安全验收台账"
            })
            menuData[index].children=arr
          }
        })
      }
      return layout === 'mix' ? subMenu : menuData
    }
  },
  methods: {
    ...mapMutations('setting', ['correctPageMinHeight', 'setActivatedFirst']),
    toggleCollapse () {
      this.disableControlCollapsed = true
      this.collapsed = !this.collapsed
      // 触发resize事件
      window.dispatchEvent(new Event('resize'));
    },
    onMenuSelect () {
      this.toggleCollapse()
    },
    setActivated(route) {
      if (this.layout === 'mix') {
        let matched = route.matched
        matched = matched.slice(0, matched.length - 1)
        const {firstMenu} = this
        for (let menu of firstMenu) {
          if (matched.findIndex(item => item.path === menu.fullPath) !== -1) {
            this.setActivatedFirst(menu.fullPath)
            break
          }
        }
      }
    }
  },
  created() {
    console.log(this.multiPage,'this.multiPage')
    this.correctPageMinHeight(this.minHeight - 24)
    this.setActivated(this.$route)
  },
  beforeDestroy() {
    this.correctPageMinHeight(-this.minHeight + 24)
  }
}
</script>

<style lang="less" scoped>
  .admin-layout{
    height: 100%;
    .side-menu{
      // background: #222A3F; // dark 深色版本主题注释
      &.fixed-side{
        position: fixed;
        height: 100vh;
        left: 0;
        top: 0;
      }
    }
    .virtual-side{
      transition: all 0.2s;
    }
    .virtual-header{
      transition: all 0.2s;
      opacity: 0;
      &.fixed-tabs.multi-page:not(.fixed-header){
        height: 0;
      }
    }
    .admin-layout-main{
      display: flex;
      flex-direction: column;

      .admin-header{
        top: 0;
        right: 0;
        overflow: hidden;
        transition: all 0.2s;
        &.fixed-tabs.multi-page:not(.fixed-header){
          height: 0;
        }
      }
    }
    .admin-layout-content{
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: column;
      background: #EDF1FA;
      border-radius: 25px 0 0 0;
      padding: 10px 8px 0px 8px;
      overflow-y: auto;
      overflow-x: hidden;
    }
    .content-multiPage {
      // 首页去除了页签。padding-top：12px
      padding-top: 12px;
    }
    .setting{
      background-color: @primary-color;
      color: @base-bg-color;
      border-radius: 5px 0 0 5px;
      line-height: 40px;
      font-size: 22px;
      width: 40px;
      height: 40px;
      box-shadow: -2px 0 8px @shadow-color;
    }
  }
</style>
