var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "theme-color",
      style: { backgroundColor: _vm.color },
      on: { click: _vm.toggle },
    },
    [_vm.sChecked ? _c("a-icon", { attrs: { type: "check" } }) : _vm._e()],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }