// fullModal.vue 组件搭配使用
export function useFullModal(backAction) {
  const originState = history.state.current;
  
  // 用于推送历史记录状态，防止用户通过浏览器返回按钮直接关闭 modal
  const pushHistoryState = () => {
    window.history.pushState({ target: originState, random: Math.random() }, '', location.href);
  };

  // popstate 事件触发时的回调，执行传入的自定义逻辑
  const onPopstate = (e) => {
    if (e.state && e.state.current === originState) {
      backAction?.();
    }
  };

  // 监听 popstate 事件
  window.addEventListener('popstate', onPopstate, false);

  // 在组件销毁时移除监听
  const removeListener = () => {
    window.removeEventListener('popstate', onPopstate, false);
  };

  return {
    pushHistoryState,
    removeListener,
  };
}
