var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.spinning, tip: _vm.loadingText } },
        [
          _vm.dataSource.isZongLaowu == 0
            ? _c("label", [
                _vm._v(
                  "温馨提示：移动考勤设置暂只允许项目总包劳务员才能设置，如需更改设置，请联系总包劳务员更改设置，非常感谢！"
                ),
              ])
            : _vm._e(),
          _c(
            "a-form",
            {
              attrs: { form: _vm.form, layout: "horizontal" },
              on: { submit: _vm.doSave },
            },
            [
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "工人开启移动考勤",
                    labelCol: { span: 5 },
                    wrapperCol: { span: 18, offset: 1 },
                  },
                },
                [
                  _c("a-switch", {
                    attrs: { disabled: _vm.dataSource.isZongLaowu == 0 },
                    model: {
                      value: _vm.dataSource.workIsOpen,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataSource, "workIsOpen", $$v)
                      },
                      expression: "dataSource.workIsOpen",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "工人开启考勤范围",
                    labelCol: { span: 5 },
                    wrapperCol: { span: 18, offset: 1 },
                  },
                },
                [
                  _c("a-switch", {
                    attrs: { disabled: _vm.dataSource.isZongLaowu == 0 },
                    model: {
                      value: _vm.dataSource.workSwitch,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataSource, "workSwitch", $$v)
                      },
                      expression: "dataSource.workSwitch",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "管理人员开启移动考勤",
                    labelCol: { span: 5 },
                    wrapperCol: { span: 18, offset: 1 },
                  },
                },
                [
                  _c("a-switch", {
                    attrs: { disabled: _vm.dataSource.isZongLaowu == 0 },
                    model: {
                      value: _vm.dataSource.manageIsOpen,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataSource, "manageIsOpen", $$v)
                      },
                      expression: "dataSource.manageIsOpen",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "管理人员开启考勤范围",
                    labelCol: { span: 5 },
                    wrapperCol: { span: 18, offset: 1 },
                  },
                },
                [
                  _c("a-switch", {
                    attrs: { disabled: _vm.dataSource.isZongLaowu == 0 },
                    model: {
                      value: _vm.dataSource.manageSwitch,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataSource, "manageSwitch", $$v)
                      },
                      expression: "dataSource.manageSwitch",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "允许考勤的范围（米）",
                    labelCol: { span: 5 },
                    wrapperCol: { span: 18, offset: 1 },
                  },
                },
                [
                  _c("a-input-number", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "dataSource.clockRange",
                          {
                            initialValue: _vm.dataSource.clockRange
                              ? _vm.dataSource.clockRange
                              : 500,
                          },
                        ],
                        expression:
                          "['dataSource.clockRange', { initialValue: dataSource.clockRange?dataSource.clockRange:500 }]",
                      },
                    ],
                    attrs: { disabled: _vm.dataSource.isZongLaowu == 0 },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { "wrapper-col": { span: 12, offset: 5 } } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        icon: "save",
                        type: "primary",
                        "html-type": "submit",
                      },
                    },
                    [_vm._v(" 确认设置 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }