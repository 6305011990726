var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "a_Approval" },
        [
          _c(
            "a-tabs",
            { attrs: { "default-active-key": _vm.defaultActiveKey } },
            [
              _c(
                "a-tab-pane",
                { key: "1", attrs: { tab: "标准化文件" } },
                [_c("documents")],
                1
              ),
              _c(
                "a-tab-pane",
                { key: "2", attrs: { tab: "标准化图册" } },
                [_c("atlas")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }