var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { bordered: false } },
    [
      _c(
        "a-steps",
        { staticClass: "steps", attrs: { current: _vm.current } },
        [
          _c("a-step", { attrs: { title: _vm.$t("input") } }),
          _c("a-step", { attrs: { title: _vm.$t("confirm") } }),
          _c("a-step", { attrs: { title: _vm.$t("complete") } }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _vm.current === 0
            ? _c("step1", { on: { nextStep: _vm.nextStep } })
            : _vm._e(),
          _vm.current === 1
            ? _c("step2", {
                on: { nextStep: _vm.nextStep, prevStep: _vm.prevStep },
              })
            : _vm._e(),
          _vm.current === 2
            ? _c("step3", {
                on: { prevStep: _vm.prevStep, finish: _vm.finish },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }