<template>
  <div class="a_Approval">
    <!-- AI违规记录页面-->
    <!-- <div class="plug-Overview">违规记录页面</div>
    <a-divider /> -->
    <formIndex ref="formindex" :formData="formData" @handleSubmit="handleSubmit" :flexAction="true"
      @handlSelect="handlSelect" @handleChange="handleChange">
      <template #flexAction>
        <a-space style="margin-left: 25px; margin-top: 5px">
          <a-button icon="search" type="primary" html-type="submit"> 查询 </a-button>
          <a-space class="operator">
            <a-button @click="close"> 重置 </a-button>
          </a-space>
        </a-space>
      </template>
    </formIndex>
    <standard-table :columns="columns" :dataSource="dataSource" :pagination="pagination" :loading="tableLoading"
      @change="onPageChange" :isAlert="false" :bordered="true">
      <div slot="teamQr" slot-scope="{ text, record }">
        <div class="flex a-center j-center" style="height: 40px">
          <div v-if="text != '' && text != null">
            <img :src="text" style="width: 40px; height: 40px; cursor: pointer" @click="showImg(text, record)" />
          </div>
          <a-spin :spinning="!text"></a-spin>
        </div>
      </div>
      <div slot="skillType" slot-scope="{text}">
        <a-tag color="orange">
          {{ text }}
        </a-tag>
      </div>
      <div slot="action" slot-scope="{text, record}">
        <a @click="showPersons(record)">查看人员</a>
        <!-- <a-divider type="vertical" />
        <a @click="showPreview('video', record.eventId, 1)">查看视频</a>
        <a-divider type="vertical" />
        <a @click="showPreview('audio', record.eventId, 2)">查看音频</a>
        <a-divider type="vertical" />
        <a @click="showPreview('text', record.eventId)">查看文字</a> -->
        <!-- <a-divider type="vertical" />
        <a @click="download(record)">下载资料</a> -->
      </div>
      <template slot="statusTitle" slot-scope="{text, record}">
        <a @click="showPersons(record)">{{ text }}</a>
      </template>
    </standard-table>
    <a-modal :width="800" v-model="visible" title="详情" @ok="handleOk" @cancel="handleOk">
      <a-descriptions bordered :column="1" layout="vertical">
        <a-descriptions-item label="发生地点">
          {{ personDataSource.address || '暂无' }}
        </a-descriptions-item>
        <a-descriptions-item label="事件发生时间">
          {{ personDataSource.happenTime ? moment(personDataSource.happenTime).format('YYYY-MM-DD HH:mm:ss') : '暂无' }}
        </a-descriptions-item>
        <a-descriptions-item label="人员名称">
          {{ personDataSource.peopleNames || '暂无' }}
        </a-descriptions-item>
        <a-descriptions-item label="人数">
          {{ personDataSource.peopleNum }}
        </a-descriptions-item>
        <a-descriptions-item label="总结">
          {{ personDataSource.summary || '暂无' }}
        </a-descriptions-item>
      </a-descriptions>
    </a-modal>
    <preview-modal :visible.sync="isModalVisible" :type="previewType" :url="previewUrl" :textContent="previewText" />
    <a-modal :destroyOnClose="true" :visible="modalVisible" @cancel="handleCancel" @ok="downQrCode" ok-text="下载"
      cancel-text="关闭">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import StandardTable from '@/components/table/StandardTable'
import formIndex from "@/pages/components/form/index";
import { useRender } from '@/hooks/useRender.js'
import moment from "moment";
import { mapGetters } from 'vuex'
import PreviewModal from '@/pages/components/PreviewModal.vue';
import { getReportAiList, getReportAiDetail, getReportAiMedia } from '@/services/wordConfig'
import { getAllTeams } from '@/services/participationUnit'
import { projectMsgGetList } from '@/services/projectManagement';
import {
    QUERY_OFFICE_BY_USER,
} from '@/services/api'
import { request } from '@/utils/request'
import {cameraWaringCheckEvel,cameraWaringPage} from '@/services/wordConfig'
import { getProjectByOffice } from "@/services/statistics";

export default {
  name: 'SuijianClientAiViolationRrecords',
  components: {
    StandardTable,
    formIndex,
    PreviewModal
  },
  data() {
        const formData = [
      {
          label: '所属公司',
          placeholder: '请选择所属公司',
          decorator: ['officeId', { rules: [{ required: false, message: '请选择所属公司' }] }],
          type: 'select',
          key: 'officeId',
          selectlist: [],
          qb:true,
          col: 5,
          labelCol: 8,
          wrapperCol: 14,
          display: this.level !== 'D' && this.level !== 'M',
          disType: 'false'
      },
      {
          label: '项目名称',
          placeholder: '请输入项目名称',
          decorator: ['projectId', { rules: [{ required: false, message: '请输入项目名称' }] }],
          type: 'inputGroup',
          key: 'projectId',
          dataSource: [],
          col: 5,
          labelCol: 8,
          wrapperCol: 14,
          display: this.level !== 'D',
          disType: 'false'
      },
      {
        label: "事件类型",
        placeholder: "请选择",
        decorator: [
          "eventType",
          { rules: [{ required: false, message: "请选择" }] },
        ],
        type: "select",
        selectlist: [
          { value: 0, name: "安全帽监测" },
          { value: 1, name: "反光衣监测" },
          { value: 6, name: "吸烟检测" },
          { value: 7, name: "人员聚集" },
          { value: 9, name: "车辆违停" },
          { value: 14, name: "人脸(抓拍/识别)" },
          { value: 15, name: "安全帽+人脸识别" },
          { value: 16, name: "反光衣+人脸识别" },
          { value: 17, name: "电子围栏" },
          { value: 18, name: "积水" },
          { value: 19, name: "明火名烟" },
          { value: 20, name: "吸烟+人脸识别" },
          { value: 21, name: "安全带事件" },
        ],
        key: "eventType",
        col: 6,
        labelCol: 6,
        wrapperCol: 14,
        display: true,
      },
      {
        label: "事件日期",
        placeholder: "请选择事件日期",
        decorator: [
          "alarmTime",
          { rules: [{ required: false, message: "请选择事件日期" }] },
        ],
        type: "rangePicker",
        format: "YYYY-MM-DD",
        key: "alarmTime",
        labelCol: 6,
        wrapperCol: 14,
        col: 6,
        display: true,
        styles: { width: '100%' },
      },
      {
        type: 'action',
        col: 6,
      }
    ];
    const columns = [
    {
        title: '总包企业名称',
        dataIndex: 'officeName',
        align: 'center',
        ellipsis: true,
        tooltip: true
      },
      {
        title: '项目名称',
        dataIndex: 'projectName',
        align: 'center',
        ellipsis: true,
        tooltip: true
      },
      {
        title: '项目经理',
        dataIndex: 'pmName',
        align: 'center',
        ellipsis: true,
        tooltip: true
      },
      {
        title: '安全员',
        dataIndex: 'aqyName',
        align: 'center',
      },
      {
        title: '事件发生时间',
        dataIndex: 'alarmTime',
        align: 'center',
        customRender: (text) => useRender.renderDate(text)
      },
      {
        title: '事件类型',
        dataIndex: 'eventType',
        align: 'center',
        scopedSlots: { customRender: 'skillType' },
      },
      {
        title: '事件名称',
        dataIndex: 'cameraName',
        align: 'center',
      },
      {
        title: '事件图像',
        dataIndex: 'alarmPictureTag',
        align: 'center',
        scopedSlots: { customRender: 'teamQr' },
      },
      // {
      //   title: '操作',
      //   align: 'center',
      //   width: 410,
      //   scopedSlots: { customRender: 'action' }
      // }
    ]
    return {
      personDataSource: {},
      dataSource: [],
      inputDataSource: [],
      advanced: false,
      columns: columns,
      tableLoading: false,
      spinning: false,
      loadingText: "数据加载中...",
      monthFormat: 'YYYY-MM-DD',
      nowMonthDay: null,
      loadKeys: [],
      visible: false,
      teamDatas: [],
      params: {
        description: undefined,
        teamId: undefined,
        pageNo: 1,
        pageSize: 10,
        alarmTime: undefined,
        skillType: undefined,
        level: 'T'
      },
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showLessItems: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      formData:formData,
      isModalVisible: false,
      previewType: '',
      previewUrl: '',
      previewText: '',
      moment,
      previewImage: '',
      modalVisible: false,
      level: 'T' // 层级
    };
  },
  filters: {
    subText: function (value) {
      if (value && value.length > 50) {
        return value.substring(0, 50) + "……";
      } else {
        return value;
      }
    }
  },
  computed: {
    ...mapGetters('account', ['user']),
  },

  watch: {
    level: {
      handler(newName,oldName) {
          if (newName === 'D') {
            const keyToDelete = ['officeName','projectName','pmName','aqyName']
            this.columns = this.columns.filter(item => !keyToDelete.includes(item.dataIndex));
            const keyToForm = ['officeId','projectId']
            this.formData = this.formData.filter(item => !keyToForm.includes(item.key));
          } else if (newName === 'M') {
            const keyToDelete= ['officeName']
            this.columns = this.columns.filter(item => !keyToDelete.includes(item.dataIndex));
            const keyToForm = ['officeId']
            this.formData = this.formData.filter(item => !keyToForm.includes(item.key));
          }
      }
    }
  },

  mounted() {
    this.getCheckEvel()
    this.getgcglbList(QUERY_OFFICE_BY_USER)
  },

  methods: {
    async getCheckEvel(){
      const res = await cameraWaringCheckEvel()
      if (res.data.code === 0) {
        // T：第一级  M是二三级 D：第四级
        this.level = res.data.data
        this.params.level = res.data.data
        this.getData(this.params)
      }
    },
    downQrCode() {
      if (this.previewImage) {
        let link = document.createElement("a"); //创建a标签
        link.style.display = "none"; //将a标签隐藏
        link.href = this.previewImage; //给a标签添加下载链接
        link.setAttribute("download", `事件图片.jpg`);
        document.body.appendChild(link);
        link.click(); //执行a标签
      }
    },
    getData(params, query) {
      this.tableLoading = true
      cameraWaringPage(params, query).then(res => {
        if (res.data.code === 0) {
          this.tableLoading = false
          const data = res.data.data
          this.dataSource = data.list
          this.pagination.total = data.total
          this.dataSource.forEach(item => {
            item.eventType = this.ptypeState('skillType', item.eventType)
            // item.renderImageBase64 = `data:image/png;base64,${item.alarmPictureTag}`
          })
        } else {
          this.dataSource = []
        }
      })
    },
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      if (obj.alarmTime) {
          obj.alarmTime = obj.alarmTime.map(time => moment(time).valueOf());
      }
      this.params.pageNo = e.current;
      this.pagination.current = e.current;
      this.getData(Object.assign(obj,this.params));
    },
    showPersons(recode) {
      this.visible = true;
      this.getReportAiDetail(recode.eventId)
    },
    async getReportAiDetail(eventId) {
      await getReportAiDetail(eventId).then(res => {
        if (res.data.code == 200) {
          this.personDataSource = res.data.result
        }
      })
    },
    async getReportAiMedia(eventId) {
      const res = await getReportAiMedia(eventId)
      return res
    },
    handleOk() {
      this.visible = false;
      this.personDataSource = {};
      this.aiMedia = []
    },
    async showPreview(type, eventId, media) {
      this.previewType = type;
      if (type == 'text') {
        await this.getReportAiDetail(eventId)
        this.previewText = this.personDataSource.summary;
        this.isModalVisible = true;
      } else {
        this.getReportAiMedia({ eventId: eventId, type: media }).then(res => {
          const data = res.data.result
          if (data.length > 0) {
            const aiMedia = data.filter(obj => obj.type == media)
            if (aiMedia.length == 0) return this.$message.warning(media == 2 ? '音频数据为空' : '视频数据为空', 5)
            this.previewUrl = aiMedia[0].file;
            this.isModalVisible = true;
          } else {
            return this.$message.warning(media == 2 ? '音频数据为空' : '视频数据为空', 5)
          }
        })
      }
    },
    ptypeState(type, status) {
      if (type == "skillType") {
        const obj = {
          0: '安全帽监测',
          1: '反光衣监测',
          6: '吸烟检测',
          7: '人员聚集',
          9: '车辆违停',
          14: '人脸(抓拍/识别)',
          15: '安全帽+人脸识别',
          16: '反光衣+人脸识别',
          17: '电子围栏',
          18: '积水',
          19: '明火名烟',
          20: '吸烟+人脸识别',
          21: '安全带事件'
        };
        let str = null;
        Object.keys(obj).forEach((key) => {
          if (key == status) {
            str = obj[key];
          }
        });
        return str;
      }
    },
    showImg(imgPath, record) {
      this.modalVisible = true;
      this.previewImage = imgPath;
      this.teamName = record.teamName;
    },
    handleCancel() {
      this.modalVisible = false;
      this.previewImage = "";
      this.teamName = "";
    },
    // 提交表单数据
    handleSubmit(e) {
      let obj = { ...this.params, ...e }
      let query = '';
      if (obj.alarmTime) {
        if (obj.alarmTime == '') {
          delete obj.alarmTime
        } else {
          obj.alarmTime = obj.alarmTime.map(time => moment(time).valueOf());
        }
      }
      obj.pageNo = 1
      this.pagination.current = 1;
      this.selectedRows = []
      this.getData(obj);
    },
    // 获取工程管理部数据
    getgcglbList(path) {
        request(path, 'get').then(res => {
            let data = res.data.data.filter(item => item.id != "3da0f5533f87425c9a9e8a11a7923300")
            this.formDataFn('officeId', data, 'name')
        })
    },
    handleChange(e) {
      if (e.item.key == 'teamId') {
        this.doQueryTeams({ teamName: e.value })
      }
      if (e.item.key == 'projectId' && e.value) {
          this.projectArr = []
          // this.getZdata(projectMsgGetList, e, 'fullName')
          this.getZdata(getProjectByOffice, e, 'projectName')
          // 获取当前匹配key，赋值相对于数组
          this.formData.forEach(item => {
              if (e.item.key === item.key) {
                  item.dataSource = this.projectArr
              }
          })
      } else {
          this.$refs['formindex'].setFieldsValue('', {}, 'projectId')
      }
    },
    close() {
      this.params = {
        description: undefined,
        teamId: undefined,
        pageNo: 1,
        pageSize: 10,
        alarmTime: undefined,
        skillType: undefined,
        level: this.level
      }
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getData(this.params);
      this.$refs["formindex"].resetFields();
    },
    //查询所有班组
    doQueryTeams(params) {
      getAllTeams(params).then(res => {
        if (res.data.code === 0) {
          this.formDataFn('teamId', res.data.data, 'teamName')
        }
      })
    },
    // 对下拉框做数据处理
    formDataFn(key, data, itemName) {
      let arrSelect = []
      let arrSource = []
      data.forEach(item => {
        // 班组模糊匹配dataSource
        arrSource.push({ 'title': item[itemName], 'id': item.id })
        // seletetList选项
        arrSelect.push({ 'name': item[itemName], 'value': item.id })
      })
      this.formData.forEach(item => {
        if (item.key == key) {
          item.dataSource = arrSource
          item.selectlist = arrSelect
        }
      })
    },
    // 特殊数据处理
    handlSelect({ val, keys }) {
      this.projectList.forEach(item => {
          if (keys == 'projectId' && item.id == val) {
              this.$refs['formindex'].setFieldsValue(item.projectId, {}, 'projectId')
          }
      })
    },
    getZdata(msg, e, name) {
      let data = {
          [name]: e.value
      }
      msg(data).then(res => {
          if (res.status == 200) {
              const data = res.data.data.list ? res.data.data.list : res.data.data
              data.forEach(item => {
                  this.projectArr.push({
                      title: item[name],
                      id: item.projectId
                  })
              })
              this.projectList = data
          }
      })
    },
  },
};
</script>

<style lang="less" scoped></style>
