var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-card",
        { attrs: { "body-style": { padding: "0px" }, bordered: false } },
        [
          _c(
            "div",
            {
              staticStyle: {
                "margin-left": "10px",
                "margin-top": "10px",
                "margin-bottom": "10px",
              },
            },
            [
              _c(
                "a-form",
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "plus",
                        loading: _vm.loading,
                      },
                      on: { click: _vm.showPersonModel },
                    },
                    [_vm._v(" 添加考勤人员 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("a-table", {
            attrs: {
              columns: _vm.columns,
              "data-source": _vm.data,
              pagination: _vm.pagination,
              loading: _vm.loading,
            },
            scopedSlots: _vm._u([
              {
                key: "action",
                fn: function (text, record) {
                  return [
                    _c("span", [
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.del(record)
                            },
                          },
                        },
                        [_vm._v("删除")]
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "新增人员",
            visible: _vm.personModelVisible,
            width: "560px",
            keyboard: true,
            maskClosable: true,
          },
          on: { cancel: _vm.personModelCancel, ok: _vm.modelOk },
        },
        [
          _c(
            "a-form-model",
            {
              ref: "personForm",
              attrs: {
                model: _vm.personForm,
                "label-col": _vm.labelCol,
                "wrapper-col": _vm.wrapperCol,
              },
            },
            [
              _c(
                "a-form-model-item",
                {
                  ref: "personName",
                  attrs: { label: "姓名", prop: "personName", required: "" },
                },
                [
                  _c("a-input", {
                    model: {
                      value: _vm.personForm.personName,
                      callback: function ($$v) {
                        _vm.$set(_vm.personForm, "personName", $$v)
                      },
                      expression: "personForm.personName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  ref: "personId",
                  attrs: { label: "身份证号", prop: "personId", required: "" },
                },
                [
                  _c("a-input", {
                    model: {
                      value: _vm.personForm.personId,
                      callback: function ($$v) {
                        _vm.$set(_vm.personForm, "personId", $$v)
                      },
                      expression: "personForm.personId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { ref: "url", attrs: { label: "人脸照片", prop: "url" } },
                [
                  _c(
                    "a-upload",
                    {
                      attrs: {
                        action:
                          _vm.uploadFaceUrl +
                          "?personId=" +
                          _vm.personForm.personId,
                        headers: _vm.headers,
                        "list-type": "picture-card",
                        "file-list": _vm.fileList,
                        accept: _vm.accept,
                        beforeUpload: _vm.beforeUpload,
                      },
                      on: {
                        preview: _vm.handlePreview,
                        change: _vm.handleChange,
                      },
                    },
                    [
                      _vm.fileList.length < 1
                        ? _c(
                            "div",
                            [
                              _c("a-icon", { attrs: { type: "plus" } }),
                              _c("div", { staticClass: "ant-upload-text" }, [
                                _vm._v(" 上传人脸照片 "),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "a-modal",
                    {
                      attrs: { visible: _vm.previewVisible, footer: null },
                      on: { cancel: _vm.handleCancel },
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "100%" },
                        attrs: { alt: "example", src: _vm.previewImage },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }