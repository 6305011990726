var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.$route.meta.showFather,
              expression: "$route.meta.showFather",
            },
          ],
          staticClass: "a_Approval",
        },
        [
          _c(
            "div",
            [
              _c(
                "a-tabs",
                { attrs: { "default-active-key": _vm.defaultActiveKey } },
                [
                  _c(
                    "a-tab-pane",
                    { key: "1", attrs: { tab: _vm.tabName, forceRender: "" } },
                    [_c("equipment-list")],
                    1
                  ),
                  _c(
                    "a-tab-pane",
                    { key: "2", attrs: { tab: "维保记录", forceRender: "" } },
                    [_c("maintenance-record")],
                    1
                  ),
                  _vm.tabName == "施工升降机"
                    ? _c(
                        "a-tab-pane",
                        {
                          key: "3",
                          attrs: {
                            tab: "防坠器有效期预警汇总表",
                            forceRender: "",
                          },
                        },
                        [_c("falling-check-record-sum")],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }