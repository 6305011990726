var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        forceRender: "",
        centered: "",
        maskClosable: false,
        title: "重置密码",
        width: 700,
      },
      on: { cancel: _vm.handleCancel, ok: _vm.modelOk },
      model: {
        value: _vm.visibleExit,
        callback: function ($$v) {
          _vm.visibleExit = $$v
        },
        expression: "visibleExit",
      },
    },
    [_c("form-index", { ref: "formindex", attrs: { formData: _vm.formData } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }