<template>
  <div>
    <a-row :gutter="16">
      <a-col :span="13">
        <a-card>
          <div slot="title">
            <div class="flex a-center">
              <img style="width: 21px; height: 21px; margin-right: 5px"
                src="https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20241120/1732091350443.png?Expires=2047451350&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=59AEqlAw%2B%2BGyQ%2F%2B4QYdFWxJZ7rs%3D"
                alt="" />
              <span class="ml-1 font-bold">人员及培训</span>
            </div>
          </div>
          <div class="flex j-around a-center text-center">
            <div class="flex-1" v-for="(item, index) in staffArr" :key="index">
              <div class="flex a-center flex-column">
                <div class="black" style="font-size: 26px">{{ item.value }}</div>
                <div class="font-14 black-three mt-1">{{ item.text }}</div>
              </div>
            </div>
          </div>
          <vue-scroll :ops="ops" style="height: 143px" class="mt-2">
            <standard-table class="close-table" :columns="staffColumns" :dataSource="staffList" rowKey="id"
              :pagination="false" :isAlert="false" :bordered="false">
              <template slot="do-status" slot-scope="{ text }">
                <span v-if="text == 1"><i class="iconfont duigouxiao" style="color:#00CE86"></i></span>
                <span v-else><i class="iconfont path" style="color:#FF5C77"></i></span>
              </template>
            </standard-table>
          </vue-scroll>
        </a-card>
      </a-col>
      <a-col :span="11">
        <a-card class="pitfall-card">
          <div class="font-bold black" style="font-size: 17px">应急管理情况</div>
          <div class="mt-1 py-2" style="border-radius: 10px;background: rgb(255, 251, 245);">
            <div class="flex j-around a-center text-center">
              <div class="flex-1">
                <div class="flex a-center flex-column">
                  <div class="black" style="font-size: 26px">5</div>
                  <div class="font-14 black-three">年度应急计划次数</div>
                </div>
              </div>
              <div style="border: 1px solid rgb(255, 223, 180);transform:rotate(180.00deg);height: 58px"></div>
              <div class="flex-1">
                <div class="flex a-center flex-column">
                  <div class="black" style="font-size: 26px">5</div>
                  <div class="font-14 black-three">年度应急演练次数</div>
                </div>
              </div>
            </div>
          </div>
          <div class="font-bold mt-2" style="font-size: 17px;color: #000;">费用使用情况</div>
          <div class="mt-2 py-2" style="border-radius: 10px;background: rgb(247, 253, 255)">
            <div class="flex j-around a-center text-center">
              <div class="flex-1">
                <div class="flex a-center flex-column">
                  <div class="black" style="font-size: 22px">5548.20 <span class="font-14 black-three">万元</span></div>
                  <div class="font-14 black-three">十项费用内容计划资金</div>
                </div>
              </div>
              <div style="border: 1px solid rgb(217, 231, 255);transform:rotate(180.00deg);height: 58px"></div>
              <div class="flex-1">
                <div class="flex a-center flex-column">
                  <div class="black" style="font-size: 22px">470.30 <span class="font-14  black-three">万元</span></div>
                  <div class="font-14 black-three">累计实际使用资金</div>
                </div>
              </div>
            </div>
          </div>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import StandardTable from "@/components/table/StandardTable";

export default {
  components: {
    StandardTable,
  },
  data() {
    return {
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true,
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: "#3ec13e", //滚动条颜色
          opacity: 0.8, //滚动条透明度
          "overflow-x": "hidden",
          size: "5px"
        },
      },
      staffArr:
        [
          {
            value: '32,345',
            text: '在场人数'
          },
          {
            value: '32,345',
            text: '在项目现场人数'
          },
          {
            value: '32,345',
            text: '三级安全教育人数'
          }
        ],
      staffColumns: [
        {
          title: "班组名称",
          dataIndex: "teamName",
          align: "center",
        },
        {
          title: "在场人数",
          dataIndex: "deviceName",
          align: "center",
        },
        {
          title: "今日班前教育",
          dataIndex: "education",
          align: "center",
          scopedSlots: { customRender: 'do-status' }
        },
      ],
      staffList: [{ id: 1, teamName: '一号班组号班组', deviceName: '2510', education: 1 }, { id: 2, teamName: '二号班组号班组', deviceName: '2565', education: 1 }, { id: 3, teamName: '三号班组号班组', deviceName: '3560', education: 1 }]
    }
  },

  mounted() {

  },

  methods: {

  },
};
</script>

<style lang="less" scoped>
/deep/.ant-card {
  border-radius: 10px;
  border: none;

  .ant-card-head {
    padding: 0 36px 0 26px;
    border-bottom: 1px solid #E6EFFF
  }

  .ant-card-head-title {
    padding: 20px 0
  }
}

.font-14 {
  font-size: 14px;
}

/deep/ .close-table {
  .ant-table-thead>tr>th {
    background: #f4f6ff;
    padding: 7px 16px;
    font-weight: 400;
    font-size: 14px;
    font-family: 微软雅黑;
    color: rgb(0, 0, 0);
  }

  .ant-table-tbody>tr>td {
    color: #4d4d4d;
    // color: #666;
    font-family: 微软雅黑;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0px;
    padding: 8px 8px;
    border-bottom: unset;
  }

  .ant-table-placeholder {
    padding: 8px 0;
  }

  .ant-empty-normal {
    margin: 0;
  }
}
</style>