var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c(
          "a-row",
          { attrs: { gutter: 16 } },
          [
            _c(
              "a-col",
              { attrs: { span: 8 } },
              [
                _c(
                  "a-card",
                  {
                    staticStyle: { height: "900px" },
                    style: "overflow:auto",
                    attrs: {
                      title: "公司结构",
                      size: "small",
                      bordered: false,
                    },
                  },
                  [
                    _c("a-tree", {
                      attrs: {
                        "expanded-keys": _vm.expandedKeys,
                        "tree-data": _vm.officeTreeData,
                        "auto-expand-parent": _vm.autoExpandParent,
                        replaceFields: {
                          children: "children",
                          title: "name",
                          key: "id",
                        },
                      },
                      on: { expand: _vm.onExpand, select: _vm.onSelect },
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function (ref) {
                            var title = ref.title
                            return [_c("span", [_vm._v(_vm._s(title))])]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "a-col",
              { attrs: { span: 15, offset: 1 } },
              [
                _c(
                  "a-card",
                  { attrs: { title: "子公司", bordered: false } },
                  [
                    _c("a-table", {
                      attrs: {
                        columns: _vm.columns,
                        "data-source": _vm.officeData,
                      },
                      on: { expand: _vm.expand },
                      scopedSlots: _vm._u([
                        {
                          key: "action",
                          fn: function (text, record) {
                            return [
                              _c(
                                "span",
                                [
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.showModal(record)
                                        },
                                      },
                                    },
                                    [_vm._v("查看")]
                                  ),
                                  _c("a-divider", {
                                    attrs: { type: "vertical" },
                                  }),
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.showModal(record)
                                        },
                                      },
                                    },
                                    [_vm._v("修改")]
                                  ),
                                  _c("a-divider", {
                                    attrs: { type: "vertical" },
                                  }),
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.delOffice(record)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                  _c("a-divider", {
                                    attrs: { type: "vertical" },
                                  }),
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.addOffice(record.id)
                                        },
                                      },
                                    },
                                    [_vm._v("添加下级")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "a-modal",
          {
            attrs: {
              title: _vm.title,
              visible: _vm.visible,
              "confirm-loading": _vm.confirmLoading,
              width: "560px",
            },
            on: { ok: _vm.modelOk, cancel: _vm.modelCancel },
          },
          [
            _vm.visible
              ? _c(
                  "a-form-model",
                  {
                    ref: "ruleForm",
                    attrs: {
                      model: _vm.form,
                      "label-col": _vm.labelCol,
                      "wrapper-col": _vm.wrapperCol,
                      rules: _vm.rules,
                    },
                  },
                  [
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "上级单位" } },
                      [
                        _c("a-tree-select", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            "dropdown-style": {
                              maxHeight: "400px",
                              overflow: "auto",
                            },
                            "tree-data": _vm.officeTreeData,
                            placeholder: "请选择直属上级单位",
                            "tree-default-expand-all": "",
                            replaceFields: {
                              children: "children",
                              title: "name",
                              key: "id",
                              value: "id",
                            },
                            treeDefaultExpandAll: false,
                          },
                          on: { select: _vm.officeSelect },
                          model: {
                            value: _vm.form.parentId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "parentId", $$v)
                            },
                            expression: "form.parentId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        ref: "code",
                        attrs: {
                          label: "统一社会信用码",
                          prop: "code",
                          required: "",
                        },
                      },
                      [
                        _c("a-input", {
                          model: {
                            value: _vm.form.code,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "code", $$v)
                            },
                            expression: "form.code",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        ref: "name",
                        attrs: {
                          label: "单位名称",
                          prop: "name",
                          required: "",
                        },
                      },
                      [
                        _c("a-input", {
                          model: {
                            value: _vm.form.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "name", $$v)
                            },
                            expression: "form.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        ref: "master",
                        attrs: {
                          label: "负责人",
                          prop: "master",
                          required: "",
                        },
                      },
                      [
                        _c("a-input", {
                          model: {
                            value: _vm.form.master,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "master", $$v)
                            },
                            expression: "form.master",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "联系电话" } },
                      [
                        _c("a-input", {
                          model: {
                            value: _vm.form.phone,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "phone", $$v)
                            },
                            expression: "form.phone",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "单位类型" } },
                      [
                        _c(
                          "a-select",
                          {
                            attrs: { placeholder: "请选择单位类型" },
                            model: {
                              value: _vm.form.companyTypeName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "companyTypeName", $$v)
                              },
                              expression: "form.companyTypeName",
                            },
                          },
                          _vm._l(
                            _vm.typeMap.companyTypeList,
                            function (item, index) {
                              return _c(
                                "a-select-option",
                                { key: index, attrs: { value: item.value } },
                                [_vm._v(" " + _vm._s(item.label) + " ")]
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "单位地址" } },
                      [
                        _c("a-input", {
                          model: {
                            value: _vm.form.address,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "address", $$v)
                            },
                            expression: "form.address",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "专业分包类型" } },
                      [
                        _c(
                          "a-select",
                          {
                            attrs: { placeholder: "请选择分包类型" },
                            model: {
                              value: _vm.form.companyMiniType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "companyMiniType", $$v)
                              },
                              expression: "form.companyMiniType",
                            },
                          },
                          _vm._l(
                            _vm.typeMap.companyMiniTypeList,
                            function (item, index) {
                              return _c(
                                "a-select-option",
                                { key: index, attrs: { value: item.value } },
                                [_vm._v(" " + _vm._s(item.label) + " ")]
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "单位归属" } },
                      [
                        _c(
                          "a-select",
                          {
                            attrs: { placeholder: "请选择单位归属" },
                            model: {
                              value: _vm.form.undergroup,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "undergroup", $$v)
                              },
                              expression: "form.undergroup",
                            },
                          },
                          _vm._l(
                            _vm.typeMap.underGroupList,
                            function (item, index) {
                              return _c(
                                "a-select-option",
                                { key: index, attrs: { value: item.value } },
                                [_vm._v(" " + _vm._s(item.label) + " ")]
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "备注" } },
                      [
                        _c("a-input", {
                          model: {
                            value: _vm.form.remarks,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "remarks", $$v)
                            },
                            expression: "form.remarks",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }