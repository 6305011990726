<template>
    <a-card>
        <a-form-model layout="inline" class="mb-3">

            <a-form-model-item label="所属角色">
                <a-select v-model="params.role" allowClear placeholder="" style="width: 240px">
                    <a-select-option v-for="item in roleList" :key="item.value" :value="item.value" :title="item.name">
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>

            <a-form-model-item label="表类型">
                <a-select v-model="params.ratingtype" allowClear placeholder="" style="width: 240px">
                    <a-select-option v-for="item in tbType" :key="item.value" :value="item.value" :title="item.name">
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>

            <a-form-model-item label="评分表名称">
                <a-input v-model="params.ratingchname" allowClear placeholder="" style="width: 240px" />
            </a-form-model-item>





            <a-form-model-item>
                <a-button class="mr-9 ml-4" type="primary" @click="handleBtn">
                    查询
                </a-button>
            </a-form-model-item>
        </a-form-model>
        <div class="mb-4">

            <a-button class="mr-4" type="primary" @click="hanldeAdd">
                新增
            </a-button>

        </div>

        <a-table :columns="columns" :data-source="tableData" bordered size="default" :pagination="{
            defaultCurrent: params.pageNumber,
            total: total,
            pageSize: params.pageSize,
            showTotal: (total, range) => `共 ${total} 条`,
        }" @change="handleChangePage">
            <template slot="action" slot-scope="text,row">

                <a class="mr-4"   slot="action" @click="handleEdit(row)">
                    编辑表规则
                </a>
                <a class="mr-4"  slot="action" @click="handleDelete(row)">
                    删除
                </a>
                <!-- <a class="mr-4" slot="action" @click="handleDownload(row)">
                    下载
                </a> -->
            </template>



        </a-table>

        <a-modal v-model="visible" :title="title" @ok="handleOk">
            <a-form-model ref="form" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-form-model-item label="评分表名称">
                    <a-input v-model="form.ratingchname" />
                </a-form-model-item>
                <a-form-model-item label="评分表分类">
                   
                    <a-radio-group v-model="form.ratingtype" style="width: 240px">
                        <a-radio :value="1">房建</a-radio>
                        <a-radio :value="2">市政</a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item label="所属角色">
                    <a-select v-model="form.role" placeholder="">
                        <a-select-option v-for="item in roleList" :key="item.value" :value="item.value" :title="item.name">
                            {{ item.name }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="评分表类型">
                    <a-radio-group v-model="form.type" style="width: 240px">
                            <a-radio :value="1">评分表</a-radio>
                            <a-radio :value="2">汇总表</a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item label="计算方式">
                    <a-radio-group v-model="form.reserved1" style="width: 240px">
                            <a-radio :value="1">默认扣分</a-radio>
                            <a-radio :value="2">加分</a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item label="描述">
                    <a-textarea v-model="form.ratingchdes" />
                </a-form-model-item>

            </a-form-model>
        </a-modal>
        <!-- <Export-loading
        :cPercent="cPercent"
        :isExporting="isExporting"
        :loadingText="loadingText"
      ></Export-loading> -->
    </a-card>
</template>
  
<script>
import {
    rattingAdd,
    rattingList,
    rattingDelete
} from "@/services/monthlyInspectionPlan";
import moment from "moment";
import ExportLoading from "@/components/ExportLoading/ExportLoading"; // progress组件
import { exportProgress } from "@/pages/aqjyzl/export"; // 导出方法
import { mapGetters } from "vuex";
import { request } from "@/utils/request";
import { QUERY_OFFICE_BY_USER } from "@/services/api";
import { projectMsgGetList } from "@/services/projectManagement";

export default {
    name: "LabourProjectExcellentBook",
    mixins: [exportProgress], //导出方法注入
    components: {
        //   ExportLoading,
    },
    data() {
        return {
            params: {
                role: "",
                pageNumber: 1,
                pageSize: 10,
                ratingchname: '',
                ratingtype: '',
            },
            labelCol: { span: 5 },
            wrapperCol: { span: 14 },
            form: {
                "ratingchname": "", //表中文名称
                "ratingchdes": "", //表描述
                "role": "", //机施	OFFICE_AQMGLY
                type: 1,
                reserved1: 1,
                ratingtype: 1
            },
            title: "新增",
            yearFormat: "YYYY",
            gcglbList: [],
            roleList: [

                {
                    name: "安全部",
                    value: "OFFICE_AQMGLY",
                },
                {
                    name: "总工办",
                    value: 'OFFICE_ZGB_USER',
                },
                {
                    name: "工程部",
                    value: "OFFICE_GC_STAFF",
                },
                {
                    name: "成本部",
                    value: "COST_DEPARTMENT",
                },
                {
                    name: "工会",
                    value: "LABOR_UNION",
                },
                {
                    name: "办公室党群部",
                    value: "DANG_QUN_BU",
                },

            ],
            projectArr: [],

            total: 0,
            columns: [
                
                {
                    title: "评分表名称",
                    align: "center",
                    dataIndex: "ratingchname",
                    width: 380
                },
                // {
                //     title: "表类型",
                //     dataIndex: "ratingtype",
                //     width: 180,
                //     ellipsis: true
                // },
                {
                    title: "所属角色",
                    dataIndex: "roleName",
                    width: 180,
                    ellipsis: true
                },
                {
                    title: "描述",
                    dataIndex: "ratingchdes",
                    
                    ellipsis: true
                },



                {
                    title: "操作",
                    dataIndex: "operation",
                    align: "center",
                    fixed: "right",
                    width: 180,
                    scopedSlots: { customRender: "action" },
                },
            ],
            tableData: [],
            visible: false,
            tbType: [
                {
                    name: "全部",
                    value: '',
                },
                {
                    name: "房建",
                    value: 1,
                },
                {
                    name: "市政",
                    value: 2,
                },
            ]

        };
    },
    created() {
        this.handleQuery();

    },
    activated() {

    },
    computed: {
        ...mapGetters("account", ["user"]),
    },
    methods: {
        handleBtn(){
            this.params.pageNumber = 1;
            this.handleQuery();
        },
        handleQuery(e) {
            // moment(params.year).format("YYYY");
            let d = {
                page: this.params.pageNumber,
                limit: this.params.pageSize,
                ratingchname: this.params.ratingchname,
                role: this.params.role,
                ratingtype: this.params.ratingtype,
            }
            rattingList(d).then(res => {
                if (res.data.code == 200) {
                    this.tableData = res.data.data.list.map((item, index) => {
                        let roleName = '';
                        this.roleList.forEach((item1, index1) => {
                            if (item.role == item1.value) {
                                roleName = item1.name;
                            }
                        })
                        return {
                            ...item,
                            roleName
                        };
                    });
                    this.total = res.data.data.totalCount;
                }
            })
        },
        handleEdit(row) {
            this.$router.push({
                path: `/markDetail`,
                query: {
                    id: row.ratingid,
                    name: row.ratingchname,
                    roleName: row.roleName,

                },
            });
        },

        handleChangePage(pagination) {
            this.params.pageNumber = pagination.current;
            this.handleQuery();
        },

        handleDelete(row) {
            this.$confirm({
                title: "提示",
                content: "是否删除？",
                okText: "确定",
                cancelText: "取消",
                onOk: () => {
                    rattingDelete({ratingid: row.ratingid}).then(res => {
                        if (res.data.code == 200) {
                            this.$message.success("删除成功");
                            this.handleQuery();
                        }
                    })

                   
                },
            });
        },
        hanldeAdd() {
            this.form = {
                "ratingchname": "", //表中文名称
                "ratingchdes": "", //表描述
                "role": "", //机施	OFFICE_AQMGLY
                type: 1,
                reserved1: 1,
                ratingtype: 1
            }
            this.visible = true;
            this.title = "新增";
        },
        handleOk() {
            this.visible = false;
            this.$refs.form.validate(async (valid) => {
                if (valid) {

                    rattingAdd(this.form).then(res => {
                        if (res.data.code == 200) {
                            this.$message.success("新增成功");
                            this.handleQuery();
                        }
                    })
                }
            });
        },




    },
};
</script>
  
<style lang="less" scoped>
@import "@/theme/common.less";

/deep/.ant-table-thead>tr>th {
    text-align: center;
}
</style>
  