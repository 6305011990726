<template>
    <div class="a_Approval">
        <a-form-model :model="params" class="clearfix" @submit="handleSubmit">
            <a-col :span="5">
                <a-form-model-item label="选择日期" :labelCol="{ span: 6 }" :wrapperCol="{ span: 14, offset: 0 }">

                    <a-range-picker v-model="params.time" style="width: 240px;"/>
                </a-form-model-item>
            </a-col>
            <a-col :span="5">
                <a-form-model-item label="制度名称" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 0 }">

                    <a-input v-model="params.name" allow-clear/>
                </a-form-model-item>
            </a-col>


            <a-col :span="4">
                <a-space class="btnBox">
                    <a-button type="primary" html-type="submit"> 查询 </a-button>
                    <a-button @click="resetQuery"> 重置 </a-button>
                </a-space>
            </a-col>
        </a-form-model>
        <standard-table :columns="columns" :dataSource="tableData" rowKey="safesystemid" :pagination="pagination"
            :loading="tableLoading" @change="handleChangePage" :isAlert="false" :bordered="true" :btnArr="tableBtnArr"
            :selectedRows.sync="selectedRows"
            >
            <template slot="index" slot-scope="{ index }">
                <span>{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}</span>
            </template>
            <template slot="attach" slot-scope="{ record }">
                <!-- {{ record }} -->
                <div class="flex j-center">
                    <div v-for="(item, index) in record.attachList" :key="index">

                        <img alt="" style="width: 32px" v-if="item.type == 'img'" :src="item.url"
                            @click="handlePreview(item)" />

                        <a-icon type="file-pdf" :style="{ fontSize: '32px' }" v-else-if="item.type == 'pdf'"
                            @click="handlePreview(item)" />
                        <a-icon type="file-text" :style="{ fontSize: '32px' }" v-else
                            @click="handlePreview(item)" />
                    </div>
                </div>
            </template>
            <template slot="action" slot-scope="{ record }">
                <div class="sTables">
                    <div @click="handleEdit(record)">编辑</div>
                    <div @click="handleUpdate(record)">更新</div>
                    <div @click="handleDel(record)">删除</div>
                </div>
            </template>
        </standard-table>
    </div>
</template>

<script>
import {
    safeStandardList, safeStandardkDel
} from "@/services/safeExamine";
import {
    safesystemList, safesystemDelete, safesystemBatchDownload, safesystemExport
} from "@/services/safeManagementWorkingGuidelines";
import { mapGetters } from "vuex";
import StandardTable from "@/components/table/StandardTable";
import moment from "moment";

const columns = [
    {
        title: "序号",
        align: "center",
        scopedSlots: { customRender: "index" },
        width: 65,
    },
    {
        title: "制度名称",
        // align: "center",
        dataIndex: "systemname",
        ellipsis: true,
        tooltip: true,
        width: 350,
    },

    {
        title: "最新发文文号",
        dataIndex: "nowno",
        width: 230,
        align: "center",
    },
    {
        title: "发布日期",
        dataIndex: "pubdate",
        width: 120,
        align: "center",
    },
    {
        title: "生效日期",
        dataIndex: "effectdate",
        width: 120,
        align: "center",
    },
    {
        title: "历史版本",
        dataIndex: "hisno",
        width: 150,
        align: "center",
    },
    {
        title: "制度主管部门",
        dataIndex: "systemdeptname",
        width: 150,
        align: "center",
    },
    // {
    //     title: "附件",
    //     dataIndex: "attach",
    //     scopedSlots: { customRender: "attach" },
    //     align: "center",
    // },
    {
        title: "操作",
        align: "center",
        width: 140,
        scopedSlots: { customRender: "action" },
    },
];

export default {
    components: {
        StandardTable
    },
    data() {
        return {
            params: {
                "name": "", //上级文件名称
                "startDate": "", //开始日期 yyyy-MM-dd
                "endDate": "", //结束日期yyyy-MM-dd
                "page": 1,
                "limit": 10,
                 time: [],
                },
            pagination: {
                //分页数据
                current: 1,
                pageSize: 10,
                total: 0,
                showTotal: (total, range) => `共 ${total} 条`,
            },
            total: 0,
            columns: columns,
            tableData: [],
            tableLoading: false, //table加载中
            selectedRows: [],
        };
    },
    created() {
        if (!this.isSafe) {
            this.columns = this.columns.filter(el => el.title != '操作')
        }
        if (this.isBranchOffice) {
            this.columns = this.columns.filter(el => el.title != '历史版本')
        }
        this.handleQuery();
    },
    activated() {
        this.handleQuery();
    },
    computed: {
        ...mapGetters("account", ["user"]),
        isBranchOffice() { //分公司
            return this.user.isGcglb == 1 && this.user.loginType == 1
        },
        isProject() { //项目部
            return this.user.loginType == 0
        },
        isSafe() { //安全部
            return this.user.isGcglb == 0 && this.user.loginType == 1
        },
        tableBtnArr() {
            return [
                {
                    name: '新增',
                    attrs: {
                        type: 'primary'
                    },
                    hidden: !this.isSafe,
                    click: this.addNewComplaint
                },
                {
                    name: '导出',
                    attrs: {
                        type: 'primary'
                    },
                    // hidden: !this.isJSOffice,
                    click: this.handleExport
                },
                {
                    name: '批量下载',
                    attrs: {
                        type: 'primary'
                    },
                    // hidden: !this.isJSOffice,
                    click: this.bathDownload
                }
            ]
        }
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault();
            this.params.pageNumber = 1
            this.pagination.current = 1
            this.handleQuery();
        },
        handleQuery(e) {
            this.tableData = []
            this.tableLoading = true
            let d = {
                name: this.params.name,
                page: this.params.page,
                limit: this.params.limit,
                startDate: this.params.time[0] ? moment(this.params.time[0]).format('YYYY-MM-DD') : '',
                endDate: this.params.time[1] ? moment(this.params.time[1]).format('YYYY-MM-DD') : '',
            }
            safesystemList(d).then((res) => {
                this.tableLoading = false
                if (res.data.code === 200) {
                    let list = res.data.data.list;

                    this.tableData = list
                    this.pagination.total = res.data.data.totalCount;
                } else {
                    this.$message.error(res.data.msg)
                }
            });
        },
        handleChangePage(e) {
            this.params.page = e.current;
            this.pagination.current = e.current;
            this.handleQuery();
        },
        addNewComplaint() {
            this.$router.push({
                name: `安全管理制度详情`,
                query: {
                    isEdit: 0
                },
            });
        },
        handleEdit(e) {
            this.$router.push({
                name: `安全管理制度详情`,
                query: {
                    id: e.safesystemid,
                    isEdit: 1
                },
            });
        },
        handleUpdate(e) {
            this.$router.push({
                name: `安全管理制度详情`,
                query: {
                    id: e.safesystemid,
                    isEdit: 2
                },
            });
        },
        handleDel(record) {
            const _that = this
            this.$confirm({
                title: '是否确定删除？',
                cancelText: '否',
                okText: '是',
                onOk: () => {
                    safesystemDelete({ id: record.safesystemid }).then(res => {
                        if (res.data.code === 200) {
                            _that.$message.success('删除成功')
                            this.handleQuery(this.params);
                        } else {
                            _that.$message.error(res.data.msg)
                        }
                    })
                },
            })
        },
        resetQuery() {
            this.pagination.pageNumber = 1
            this.params =  {
                "name": "", //上级文件名称
                "startDate": "", //开始日期 yyyy-MM-dd
                "endDate": "", //结束日期yyyy-MM-dd
                "page": 1,
                "limit": 10,
                 time: [],
                };
            this.handleQuery();
        },
        bathDownload() {
            if (this.selectedRows.length == 0) {
                this.$message.warning('请选择文件！')
            } else {
                let arr = []
                this.selectedRows.forEach(el => {
                    arr.push(el.safesystemid)
                })
                let url = safesystemBatchDownload + `?ids=${arr.join(',')}`
                window.open(url)
            }
        },
        handleExport() {
            window.open(safesystemExport)
        },
        handlePreview(file) {
            window.open(file.url);
        },
    },
};
</script>

<style lang="less" scoped>
.query-form {
    display: flex;
    // justify-content: space-between;
}
.sTables {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
        width: 100%;
        text-align: center;
        cursor: pointer;
    }

    div:nth-child(1) {
        color: #307dfa;
    }

    div:nth-child(2) {
        color: #307dfa;
    }

    div:nth-child(3) {
        color: #ff5c77;
    }
}

</style>