var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a_a_top" },
    [
      _c("form-index", {
        ref: "formindex",
        attrs: {
          formData: _vm.formData,
          flexAction: true,
          projectList: _vm.projectList,
        },
        on: {
          handleSubmit: _vm.handleSubmit,
          handlSelect: _vm.handlSelect,
          handleChange: _vm.handleChange,
        },
        scopedSlots: _vm._u([
          {
            key: "flexAction",
            fn: function () {
              return [
                _c(
                  "a-space",
                  { style: { marginTop: "4px" } },
                  [
                    _c(
                      "a-button",
                      { attrs: { type: "primary", "html-type": "submit" } },
                      [_vm._v(" 查询 ")]
                    ),
                    _c(
                      "a-space",
                      { staticClass: "operator" },
                      [
                        _c("a-button", { on: { click: _vm.close } }, [
                          _vm._v(" 重置 "),
                        ]),
                      ],
                      1
                    ),
                    _c("a-button", { on: { click: _vm.exportExcelStaff } }, [
                      _vm._v("导出"),
                    ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "a_a_footer" },
        [
          _c("div"),
          _c(
            "standard-table",
            {
              attrs: {
                columns: _vm.columns,
                dataSource: _vm.dataSource,
                rowKey: "index",
                scroll: { x: 1800, y: "calc(100vh - 410px)" },
                pagination: _vm.pagination,
                loading: _vm.tableLoading,
                isAlert: false,
                bordered: true,
              },
              on: { change: _vm.onPageChange },
              scopedSlots: _vm._u(
                [
                  _vm._l(_vm.columns, function (item, index) {
                    return {
                      key: item.dataIndex,
                      fn: function (ref) {
                        var record = ref.record
                        return [
                          _c("div", { key: index }, [
                            item.dataIndex === "index"
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      (_vm.pagination.current - 1) *
                                        _vm.pagination.pageSize +
                                        parseInt(index) +
                                        1
                                    )
                                  ),
                                ])
                              : item.dataIndex === "name"
                              ? _c("span", [_vm._v(_vm._s(record.name))])
                              : item.dataIndex === "total"
                              ? _c("span", [_vm._v(_vm._s(record.total))])
                              : _c(
                                  "span",
                                  {
                                    staticClass: "table-content",
                                    class: _vm.getStatusClass(
                                      record[item.dataIndex]
                                    ),
                                  },
                                  [
                                    record[item.dataIndex] == 0
                                      ? _c("a-icon", {
                                          attrs: { type: "close-circle" },
                                        })
                                      : record[item.dataIndex] == 1
                                      ? _c("a-icon", {
                                          attrs: { type: "check-circle" },
                                        })
                                      : record[item.dataIndex] == 2
                                      ? _c("a-icon", {
                                          attrs: { type: "exclamation-circle" },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                          ]),
                        ]
                      },
                    }
                  }),
                ],
                null,
                true
              ),
            },
            [
              _c("template", { slot: "title" }, [
                _c(
                  "h3",
                  {
                    staticClass: "table-title",
                    staticStyle: { "margin-bottom": "0" },
                  },
                  [_vm._v(_vm._s(_vm.tableTitle))]
                ),
                _c(
                  "div",
                  [
                    _c("a-icon", {
                      staticClass: "red mr-1",
                      attrs: { type: "close-circle" },
                    }),
                    _vm._v("全天无考勤 "),
                    _c("a-icon", {
                      staticClass: "yellow ml-2 mr-1",
                      attrs: { type: "exclamation-circle" },
                    }),
                    _vm._v("缺少考勤 "),
                    _c("a-icon", {
                      staticClass: "green ml-2 mr-1",
                      attrs: { type: "check-circle" },
                    }),
                    _vm._v("考勤完整 "),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("Export-loading", {
        attrs: {
          cPercent: _vm.cPercent,
          isExporting: _vm.isExporting,
          loadingText: _vm.loadingText,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }