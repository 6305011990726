var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-dropdown",
    {
      attrs: { trigger: ["click"] },
      on: {
        visibleChange: function (visible) {
          return _vm.fetchNotice(visible)
        },
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "overlay" }, slot: "overlay" },
        [
          _c(
            "a-spin",
            { attrs: { spinning: _vm.loading } },
            [
              _c(
                "a-tabs",
                {
                  staticClass: "dropdown-tabs",
                  style: { width: "297px" },
                  attrs: {
                    tabBarStyle: { textAlign: "center", marginBottom: "0px" },
                  },
                },
                [
                  _c(
                    "a-tab-pane",
                    { key: "1", attrs: { tab: "通知" } },
                    [
                      _c("a-list", {
                        staticClass: "tab-pane",
                        attrs: { "data-source": _vm.versionData },
                        scopedSlots: _vm._u([
                          {
                            key: "renderItem",
                            fn: function (item, index) {
                              return _c(
                                "a-list-item",
                                {},
                                [
                                  _c(
                                    "a-list-item-meta",
                                    {
                                      key: index,
                                      on: {
                                        click: function ($event) {
                                          return _vm.showVersion(item)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: { slot: "title" },
                                          slot: "title",
                                        },
                                        [
                                          _vm._v(
                                            "版本更新通知 " + _vm._s(item.vcode)
                                          ),
                                        ]
                                      ),
                                      _c("a-avatar", {
                                        staticStyle: {
                                          "background-color": "white",
                                        },
                                        attrs: {
                                          slot: "avatar",
                                          src: "https://gw.alipayobjects.com/zos/rmsportal/ThXAXghbEsBCCSDihZxY.png",
                                        },
                                        slot: "avatar",
                                      }),
                                      _c(
                                        "div",
                                        {
                                          attrs: { slot: "description" },
                                          slot: "description",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm
                                                .moment(item.vtime)
                                                .format("YYYY-MM-DD HH:mm:ss")
                                            ) + " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c("a-tab-pane", { key: "2", attrs: { tab: "消息" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "demo-infinite-container",
                        on: { scroll: _vm.handleInfiniteOnLoad },
                      },
                      [
                        _c(
                          "a-list",
                          {
                            staticClass: "tab-pane",
                            attrs: { "data-source": _vm.data },
                            scopedSlots: _vm._u([
                              {
                                key: "renderItem",
                                fn: function (item, index) {
                                  return _c(
                                    "a-list-item",
                                    {},
                                    [
                                      _c("a-list-item-meta", {
                                        key: index,
                                        attrs: {
                                          description:
                                            index +
                                            1 +
                                            "." +
                                            item.templateContent,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.showMessage(item)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                },
                              },
                            ]),
                          },
                          [
                            _vm.loading && !_vm.busy
                              ? _c(
                                  "div",
                                  { staticClass: "demo-loading-container" },
                                  [_c("a-spin")],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "a-tab-pane",
                    { key: "3", attrs: { tab: "待办" } },
                    [_c("a-list", { staticClass: "tab-pane" })],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-modal",
            {
              attrs: {
                mask: true,
                centered: "",
                width: 1050,
                title:
                  "#更新通知 " + (_vm.version.vcode ? _vm.version.vcode : ""),
                footer: null,
              },
              on: { cancel: _vm.closeVersion },
              model: {
                value: _vm.versionMotal,
                callback: function ($$v) {
                  _vm.versionMotal = $$v
                },
                expression: "versionMotal",
              },
            },
            [
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.moment(_vm.version.vtime).format("YYYY-MM-DD HH:mm:ss")
                  )
                ),
              ]),
              _c("div", { attrs: { id: "element-order-detail" } }, [
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.version.vcontent) },
                }),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "header-notice",
          on: {
            click: function (e) {
              return e.preventDefault()
            },
          },
        },
        [
          _c(
            "a-badge",
            {
              staticClass: "notice-badge",
              attrs: { count: _vm.newVersion, dot: "" },
            },
            [
              _c("a-icon", {
                class: ["header-notice-icon"],
                attrs: { type: "bell" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          on: {
            click: function (e) {
              return e.preventDefault()
            },
          },
        },
        [
          _c(
            "a-modal",
            {
              attrs: { title: "Basic Modal" },
              on: { ok: _vm.closeMessage },
              model: {
                value: _vm.visible,
                callback: function ($$v) {
                  _vm.visible = $$v
                },
                expression: "visible",
              },
            },
            [_vm._v(" " + _vm._s(_vm.msgContent) + " ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }