<template>
  <div class="main">
    <!-- 左侧 -->
    <div class="sider">
      <!-- 公司卡片 -->
      <companyCard :projectData="projectData" :isProject="true"></companyCard>
      <!-- 管理人员卡片 -->
      <managerCard :mangerData="mangerData"></managerCard>
      <!-- 班组情况概览 -->
      <teamsOverview style="flex: 1" :teamRankList="teamRankList"></teamsOverview>
    </div>
    <!-- 右侧 -->
    <div class="content">
      <!-- 上部分 -->
      <div class="content-top">
        <div class="content-top-left">
          <!-- 建委预警 -->
          <constructionWarning v-if="openWaring"></constructionWarning>
          <!-- 旧建委预警 -->
          <!-- <div class="card early-content">
            <div class="flex">
              <div class="relative">
                <img
                  class="wran-img"
                  src="@/assets/img/icon-jw-warning.png"
                  alt=""
                />
                <div class="warning-content white">
                  <p class="font-bold-700">建委预警</p>
                  <p class="font-bold-700 font-30">
                    {{ noCloseWarnList.length }}
                  </p>
                  <div class="white pointer">
                    查看详情<a-icon type="right" />
                  </div>
                </div>
              </div>
              <vue-scroll :ops="ops" style="height: 210px" class="ml-2">
                <standard-table
                  class="close-table"
                  :columns="columns"
                  :dataSource="noCloseWarnList"
                  rowKey="id"
                  :pagination="false"
                  :isAlert="false"
                  :bordered="false"
                >
                  <template slot="bizType" slot-scope="{ text }">
                    {{ text == 1 ? "实名制" : "工资分账" }}
                  </template>
                  <template slot="eventName" slot-scope="{ text }">
                    {{ text }}
                  </template>
                  <template slot="warmCode" slot-scope="{ text, record }">
                    <div
                      class="statusDrop flex a-center overtext-1"
                      :class="textStyle(record)"
                    >
                      {{ text }}
                    </div>
                  </template>
                  <template slot="status" slot-scope="{ text, record }">
                    <div
                      class="statusDrop flex a-center overtext-1"
                      :class="textStyle(record)"
                    >
                      {{ text | statusStr }}
                    </div>
                  </template>
                  <template slot="overtext" slot-scope="{ text }">
                    <span class="overtext-1">{{ text }}</span>
                  </template>
                </standard-table>
              </vue-scroll>
            </div>
          </div> -->
          <!-- 地图，建委预警显示的话，隐藏项目概览模块 -->
          <mapOverview style="flex: 1;" type="project" :openWaring="openWaring"></mapOverview>
          <!-- 近7日出勤数据 -->
          <!-- <div class="card mt-2">
            <div style="margin-bottom: 15px;" class="black font-16 font-bold overtext-1">近7日出勤数据</div>
            <div
              id="main"
              style="width: 100%; height: 240px"
              ref="sevenDaysChart"
            ></div>
          </div> -->
        </div>
        <!-- 工人情况总览 -->
        <workOverview :staffData="staffData"></workOverview>
      </div>
      <!-- 工资发放情况 -->
      <payOverview :payrollData="payrollData"></payOverview>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  sevenDaysChart,
} from "./echarts";
import {
  getProWarnList,
  getProTeamData,
  getProTeamKq,
  getProMangerData,
  getProStaffData,
  getProPayrollData,
  getProDeviceData,
  getLwDetail,
  getProSevenDays,
} from "@/services/projectManagement";
import { useSystem } from '@/hooks/useSystem.js'
import companyCard from '@/components/home/companyCard/index.vue'
import managerCard from '@/components/home/managerCard/index.vue'
import teamsOverview from '@/components/home/teamsOverview/index.vue'
import payOverview from '@/components/home/payOverview/index.vue'
import workOverview from '@/components/home/workOverview/index.vue'
import mapOverview from '@/components/home/mapOverview/index.vue'
import constructionWarning from '@/components/home/constructionWarning/index.vue'
const columns = [
  // {
  //   title: "预警单号",
  //   dataIndex: "jwWarnOddNum",
  //   align: "center",
  //   scopedSlots: { customRender: "overtext" },
  // },
  {
    title: "预警内容",
    dataIndex: "name",
    align: "center",
    scopedSlots: { customRender: "overtext" },
  },
  {
    title: "预警时间",
    dataIndex: "warmDate",
    align: "center",
    scopedSlots: { customRender: "warmDate" },
  },
  // {
  //   title: "处理状态",
  //   dataIndex: "status",
  //   align: "center",
  //   scopedSlots: { customRender: "status" },
  // },
  {
    title: "剩余处理时间",
    dataIndex: "timeoutTime",
    align: "center",
    scopedSlots: { customRender: "warmCode" },
  },
];
const deviceColumns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    align: "center",
  },
  {
    title: "品牌",
    dataIndex: "manufacturerKey",
    align: "center",
  },
  {
    title: "设备名称",
    dataIndex: "deviceName",
    align: "center",
  },
  {
    title: "状态",
    dataIndex: "online",
    align: "center",
    scopedSlots: { customRender: "online" },
  },
];
const payrollData = [
  {
    title: "累计发放工人工资金额总数",
    key: "accumulatedTotal",
    value: "",
    background: '#E9F7FF',
    img: require("@/assets/img/payroll-card1.png"),
  },
  {
    title: "本月发放工人工资金额总数",
    key: "currMonthAccumulatedTotal",
    value: "",
    background: '#F4F6FF',
    img: require("@/assets/img/payroll-card2.png"),
  },
  {
    title: "累计班组发放总数",
    key: "teamNum",
    value: "",
    background: '#F4F6FF',
    img: require("@/assets/img/payroll-card3.png"),
  },
  {
    title: "本月发放工人总数",
    key: "staffNum",
    value: "",
    background: '#E9F7FF',
    img: require("@/assets/img/payroll-card-4.png"),
  },
];
const { checkConstructionWarningOpened } = useSystem()

export default {
  name: "homePage",
  components: {
    companyCard,
    managerCard,
    teamsOverview,
    payOverview,
    workOverview,
    mapOverview,
    constructionWarning
  },
  data() {
    return {
      activedKey: 2,
      peopleChart: null,
      environmentChart: null,
      safeChart: null,
      environmentChartCopy: null,
      sevenDaysChart: null,
      // currentGk: {}, // 当月安全管理统计汇总
      // workData: {}, // 劳务数据汇总
      // projectList: [],
      // defaultProject: "全部",
      // gcObj: {}, // 工程类型守护
      // ManagCWA: {}, // 管理人员数据
      // sevenKq: [],
      noCloseWarnList: [], // 建委预警列表
      columns,
      deviceColumns,
      pagination: {
        //分页数据
        current: 1,
        pageSize: 10,
        total: 0,
      },
      teamRankList: [], // 班组排名情况
      teamDataKq: {}, // 班组数据
      deviceData: {}, // 设备数组
      staffData: {}, // 工人数据
      payrollData, // 工资数据
      newPayrollData: {},
      mangerData: {}, // 管理人员数据
      projectData: {}, // 项目数据
      sevenKq: {}, // 项目七日考勤情况
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true,
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: "#3ec13e", //滚动条颜色
          opacity: 0.8, //滚动条透明度
          "overflow-x": "hidden",
        },
      },
      deviceNum: 0,
      openWaring: false, // 是否开通了建委预警
    };
  },
  filters: {
    statusStr(val) {
      switch (val) {
        case 0:
          return "待处理";
        case 1:
          return "处理中";
        case 2:
          return "已处理";
        case 3:
          return "已超时";
      }
    },
  },
  computed: {
    ...mapGetters("account", ["user"]),
    getProgressColor() {
      return function (successPercent) {
        let color = "";
        if (successPercent < 25) {
          color = "#615e83";
        } else if (successPercent <= 50) {
          color = "#e5eafc";
        } else if (successPercent <= 75) {
          color = "#a9e9fe";
        } else if (successPercent <= 90) {
          color = "#3aa5ff";
        } else {
          color = "#4a3aff";
        }
        return color;
      };
    },
    textStyle() {
      return function (val) {
        if (val.status == 0) {
          return "";
        } else if (val.status == 1) {
          return "before-second";
        } else if (val.status == 2) {
          return "before-third";
        } else if (val.status == 3) {
          return "before-fourth";
        }
      };
    },
  },
  async created() {
    // 检查当前项目有无开通建委预警
    this.openWaring = await checkConstructionWarningOpened()
  },
  mounted() {
    this.init(); // 初始化数据
    this.getProWarnList(); // 建委预警列表
    this.getProTeamData(); // 班组出勤情况
  },
  beforeDestroy() {
    /* 页面组件销毁的时候，别忘了移除绑定的监听resize事件，否则的话，多渲染几次
    容易导致内存泄漏和额外CPU或GPU占用哦*/
    window.removeEventListener("resize", () => {
      this.sevenDaysChart.resize();
    });
  },
  methods: {
    handleChangeTitle(e) {
      this.activedKey = e;
    },
    // 建委预警列表
    getProWarnList() {
      const params = {
        projectId: this.user.projectId,
        pageNumber: 1,
        pageSize: 50,
      };
      getProWarnList(params)
        .then((res) => {
          if (res.data.code === 0) {
            // this.noCloseWarnList = res.data.data
            this.noCloseWarnList = res.data.data.list.filter(
              (item) => item.status != 2
            );
          }
        })
        .catch((e) => {
          this.$message.error("获取预警列表失败",2);
        });
    },
    // 首页获取班组情况
    getProTeamData() {
      getProTeamData({ projectId: this.user.projectId }).then((res) => {
        this.teamDataKq = res.data.data;
      });
      const params = {
        projectId: this.user.projectId,
        pageNumber: 1,
        pageSize: 10,
      };
      getProTeamKq(params).then((res) => {
        this.teamRankList = res.data.data;
      });
    },
    init() {
      const params = {
        projectId: this.user.projectId,
      };
      // 获取项目详情数据
      getLwDetail(params).then((res) => {
        if (res.data) {
          this.projectData = { ...res.data, ...res.data.projectMsg };
          this.projectData.userName = res.data.userName;
        }
      });
      // 首页获取管理人员数据
      getProMangerData().then((res) => {
        if (res.data.code === 0) {
          this.mangerData = res.data.data;
        }
      });
      // 获取工人数据
      getProStaffData().then((res) => {
        if (res.data.code === 0) {
          this.staffData = res.data.data;
        }
      });
      // 获取工资数据
      getProPayrollData().then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data;
          this.newPayrollData = data
          this.payrollData.forEach((item) => {
            Object.keys(data).forEach((key) => {
              if (item.key == key) {
                if (key == 'accumulatedTotal' || key == 'currMonthAccumulatedTotal') {
                  item.value = (Number(data[key]) / 10000).toLocaleString();
                } else {
                  item.value = data[key];
                }
              }
            });
          });
        }
      });
      // 获取设备数据
      getProDeviceData().then((res) => {
        if (res.data.code === 0) {
          this.deviceData = res.data.data;
          if (this.deviceData.attendanceDeviceVoList) {
            this.deviceData.attendanceDeviceVoList.forEach((el) => {
              el.online = Number(el.online)
                ? "在线"
                : el.manufacturerKey === "HK"
                ? "/"
                : "不在线";
              if (el.manufacturerKey === "yunqi") {
                el.manufacturerKey = "云启";
              } else if (el.manufacturerKey === "HK") {
                el.manufacturerKey = "海康";
              }
            });
          }

        }
      });
      // 获取近七日出勤数据
      getProSevenDays({}).then((res) => {
        if (res.data.code === 0) {
          const week = [
            { days: this.fun_date(-7), glrys: 0, grs: 0 },
            { days: this.fun_date(-6), glrys: 0, grs: 0 },
            { days: this.fun_date(-5), glrys: 0, grs: 0 },
            { days: this.fun_date(-4), glrys: 0, grs: 0 },
            { days: this.fun_date(-3), glrys: 0, grs: 0 },
            { days: this.fun_date(-2), glrys: 0, grs: 0 },
            { days: this.fun_date(-1), glrys: 0, grs: 0 },
          ];
          let days = [];
          let glrys = [];
          let grs = [];
          let oldArr = [];
          oldArr = res.data.data.length > 0 ? res.data.data : week;
          oldArr.forEach((item) => {
            days.push(item.days);
            glrys.push(item.glrys);
            grs.push(item.grs);
          });
          this.sevenKq = {
            days,
            glrys,
            grs,
          };

          this.sevenDaysChart = sevenDaysChart(
            this.sevenKq,
            this.$refs.sevenDaysChart
          );

          window.addEventListener("resize", () => {
            // 第六步，执行echarts自带的resize方法，即可做到让echarts图表自适应
            this.sevenDaysChart.resize();
          });
        }
      });
    },
    fun_date(day) {
      let date1 = new Date();
      let date2 = new Date(date1);
      date2.setDate(date1.getDate() + day);
      let time2 =
        date2.getFullYear() +
        "-" +
        (date2.getMonth() + 1) +
        "-" +
        date2.getDate();
      return time2;
    }
  },
};
</script>

<style lang="less" scoped>
.card {
  border-radius: 10px;
  padding: 17px;
  background-color: #fff;
}
.main {
  display: flex;
  margin: 0px 10px;
  padding-bottom: 10px;

  .sider {
    display: flex;
    flex-direction: column;
    width: 350px;
    margin-right: 15px;
  }
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .content-top {
      flex: 1;
      display: flex;
      justify-content: space-between;
      height: 65%;
      .content-top-left {
        flex: 1;
        margin-right: 15px;
        display: flex;
        flex-direction: column;
      }
    }
  }

  .early-content {
    height: 201px;
  }

  .content-top-left {
    .bmMap {
      width: 100%;
      height: 278px;
      flex: 1;
      border-radius: 10px;
    }
    .pro-box {
      position: absolute;
      top: 25px;
      left: 40px;
      right: 38px;
      .tab-box {
        width: 18%;
        height: 80px;
        background: rgba(34, 42, 63, 0.8);
        border-radius: 6px;
        color: #fff;
        text-align: center;
      }
      .pro-active {
        background: #2d5aff;
      }
    }
    /deep/ .ant-card {
      border-radius: 10px;
    }
    /deep/ .ant-card-head-title {
      padding: 0;
    }
    /deep/ .ant-card-head {
      // min-height: 45px;
      line-height: 48px;
      border-bottom: 1px solid rgb(218, 230, 255);
    }
    /deep/ .ant-card-body {
      padding: 17px;
    }
    .project-card /deep/ .ant-card-body {
      padding: 24px;
    }
    .card-match {
      line-height: 27px;
      margin-top: auto;
    }
    .home-project {
      width: 126px;
      height: 82px;
    }

    .payroll-card {
      width: 350px;
      height: 93px;
      background: #f4f6ff;
      border-radius: 10px;
    }
    .wran-img {
      width: 210px;
      height: 150px;
    }
    .per-project {
      // background: pink;
      height: 278px;
    }
  }
}
.aa:nth-child(odd) {
  margin-right: 10px;
}
.aa {
  margin-bottom: 10px;
}
</style>

<style lang="less" scoped>
p {
  margin: 0;
}
.public {
  padding: 17px;
  background: #fff;
  border-radius: 10px;
}
.warning-content {
  position: absolute;
  top: 10px;
  left: 18px;
}
.green {
  color: rgb(4, 206, 0);
}
.red {
  color: #ff718b;
}
.lightgray {
  color: #4c4c4c;
}
.tb-box-2 {
  height: 155px;
  overflow: hidden;
  overflow-y: auto;
}
.home_container {
  /deep/ .ant-card {
    border-radius: 10px;
  }
  /deep/ .ant-card-head-title {
    padding: 0;
  }
  /deep/ .ant-card-head {
    // min-height: 45px;
    line-height: 48px;
    border-bottom: 1px solid rgb(218, 230, 255);
  }
  /deep/ .ant-card-body {
    padding: 17px;
  }
  .card-match {
    line-height: 27px;
    margin-top: auto;
  }
  .home-project {
    width: 126px;
    height: 82px;
  }

  .payroll-card {
    width: 350px;
    height: 93px;
    background: #f4f6ff;
    border-radius: 10px;
  }
  .wran-img {
    width: 210px;
    height: 120px;
  }
}
/deep/ .close-table {
  .ant-table-thead > tr > th {
    background: #f4f6ff;
    padding: 4px 16px;
    font-weight: 400;
    font-size: 14px;
    font-family: 微软雅黑;
    color: rgb(0, 0, 0);
  }
  .ant-table-tbody > tr > td {
    color: #4d4d4d;
    // color: #666;
    font-family: 微软雅黑;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0px;
    padding: 8px 8px;
    border-bottom: unset;
  }
  .ant-table-placeholder {
    padding: 8px 0;
  }
  .ant-empty-normal {
    margin: 0;
  }
  // 预警状态
  .statusDrop::before {
    width: 8px;
    height: 8px;
    background: #ee6945;
    content: "";
    display: inline-block;
    border-radius: 50%;
    margin-left: 15px;
    margin-right: 3px;
  }
  .before-second::before {
    background: #e5a858;
  }
  .before-third::before {
    background: #6ccc51;
  }
  .before-fourth::before {
    background: #656262;
  }
}

.scroll-tabox {
  position: relative;
  /deep/ .ant-table-thead {
    position: sticky;
    top: 0px;
    z-index: 9999;
    background: #fff;
  }
}
/deep/ .close-table2 {
  .ant-table-thead > tr > th {
    background: #f4f6ff;
    padding: 4px 16px;
    font-weight: 400;
    font-size: 14px;
    font-family: 微软雅黑;
    color: rgb(0, 0, 0);
  }
  .ant-table-tbody > tr > td {
    color: #4d4d4d;
    // color: #666;
    font-family: 微软雅黑;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0px;
    padding: 8px 8px;
    border-bottom: unset;
  }
  .ant-table-placeholder {
    // padding: 8px 0;
  }
  .ant-empty-normal {
    // margin: 0;
  }
}
/deep/ .close-table {
  .ant-table-thead > tr > th {
    background: #f4f6ff;
    padding: 4px 16px;
    font-weight: 400;
    font-size: 14px;
    font-family: 微软雅黑;
    color: rgb(0, 0, 0);
  }
  .ant-table-tbody > tr > td {
    color: #4d4d4d;
    // color: #666;
    font-family: 微软雅黑;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0px;
    padding: 8px 8px;
    border-bottom: unset;
  }
  .ant-table-placeholder {
    padding: 8px 0;
  }
  .ant-empty-normal {
    margin: 0;
  }
  // 预警状态
  .statusDrop::before {
    width: 8px;
    height: 8px;
    background: #ee6945;
    content: "";
    display: inline-block;
    border-radius: 50%;
    margin-left: 15px;
    margin-right: 3px;
  }
  .before-second::before {
    background: #e5a858;
  }
  .before-third::before {
    background: #6ccc51;
  }
  .before-fourth::before {
    background: #656262;
  }
}
</style>
