<template>
  <a-modal v-model="myVisible" :destroyOnClose="true" :width="1800" :footer="null" @cancel="cancel">
    <h3 class="font-bold">{{clickContent.bigTypeName}}</h3>
    <div class="flex a-center">
      <!-- <span class="flex-1">落地式脚手架-视频1</span> -->
      <a-button type="primary" @click="captureScreenshot" :loading="uploading" v-if="recordData.status == 2"> 截图及隐患问题 </a-button>
    </div>
    <div class="flex mt-2 w-100">
      <a-row>
        <a-col :span="14">
          <div class="flex a-center j-center" style="min-width: 1022px;">
            <video 
              style="width: auto;height: 550px;"
              id="video"
              ref="video"
              :src="clickVideo.filePath" 
              controls
              crossorigin="anonymous"
              autoplay></video>
          </div>
        </a-col>
        <a-col :span="10">
          <div style="min-width: 730px;">
            <a-row>
              <div style="overflow-y: auto;height: 550px;">
                <a-col :span="12" v-for="(item, index) in yhList" :key="index">
                  <div class="p-1 m-1" style="border: 1px solid #F3F3F3;">
                    <div class="flex">
                      <span class="flex-1">隐患描述</span>
                      <!-- <a-button type="link" @click="onEdit(item, index)">编辑</a-button> -->
                      <a-button type="link" style="color: red;" @click="del(item, index)" v-if="recordData.status == 2">删除</a-button>
                    </div>
                    <div class="yh-desc-box">{{item.yhDesc}}</div>
                    <div class="mt-1">隐患部位</div>
                    <div class="yh-position-box">{{item.yhPosition}}</div>
                    <div class="mt-1">图片</div>
                    <img :src="item.yhPhoto" style="width: 80px;" alt="" @click="handlePreview(item.yhPhoto)">
                  </div>
                </a-col>
              </div>
            </a-row>
          </div>
        </a-col>
      </a-row>
      </div>
      <div class="mt-1 flex flex-row-reverse">
        <a-button type="primary" @click="onSave" v-if="recordData.status == 2"> 保存 </a-button>
      </div>
    <a-modal
      v-model="showScreenshotDialog"
      centered
      :destroyOnClose="true"
      :width="880"
      :footer="null"
      :maskClosable="false"
      title="添加隐患"
      :keyboard="false"
      @cancel="handleCancel"
    >
      <canvas ref="canvas" @mousedown="startDrawing" @mousemove="draw" @mouseup="stopDrawing"></canvas>
      <div class="mt-1 flex flex-row-reverse">
        <a-icon type="undo" class="mr-1 pointer" style="font-size: 25px;" @click="undo"/>
        <a-icon type="arrows-alt" class="mr-1 pointer" style="font-size: 25px;" @click="enableArrow"/>
        <a-icon type="up-square" class="mr-1 pointer" style="font-size: 25px;" @click="enableRectangle"/>
      </div>
      <div class="mt-2">
        <a-form
          :form="form"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 20 }"
        >
          <a-row :gutter="16">
            <a-col :span="24">
              <a-form-item label="隐患描述">
                <a-textarea
                  v-decorator="['yhDesc', { rules: [{ required: false, message: '' }] }]"
                />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="隐患部位">
                <a-textarea
                  v-decorator="['yhPosition', { rules: [{ required: false, message: '' }] }]"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24" class="form-buttons">
              <a-button type="default" @click="handleCancel">返回</a-button>
              <a-button type="primary" @click="confirmCapture">确定</a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
    </a-modal>
    <a-modal :visible="previewVisible" :width="880" :footer="null" @cancel="handleCancelPreview">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </a-modal>
</template>

<script>
import { createVideoProblem, getVideoAqxjProblemByVideoId, delVideoProblem } from "@/services/safeMonthlyReport";
import { request } from "@/utils/request";
import { ATTACHMENT_UPLOAD_WATER } from "@/services/api";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
      require: true
    },
    clickVideo: {
      type: Object,
      default: () => {},
      require: true
    },
    clickContent: {
      type: Object,
      default: () => {},
      require: true
    },
    recordData: {
      type: Object,
      default: () => {},
      require: true
    },
  },
  data() {
    return {
      previewVisible: false,
      previewImage: '',
      myVisible: false,
      showScreenshotDialog: false,
      uploading: false,
      form: this.$form.createForm(this),
      drawingMode: '',
      shapes: [], // 保存标记
      startX: 0,
      startY: 0,
      isDrawing: false,
      yhList: [],
      canvasWidth: 830,
      canvasHeight: 830,
      isEdit: false,
      editIndex: null, // 需要编辑的隐患列表的下标
      captureTime: null, // 视频当前的播放时间
    };
  },
  mounted() {
    
   },
  watch: {
    visible: {
      handler(val) {
        this.myVisible = val
        if (val) {
          this.getProblems()
          // const video = this.$refs.video;
          // video.play();
        }
      }
    },
    yhList: {
      handler(val) {
        console.log(val, 'yhList')
      }
    },
    clickContent: {
      handler(val) {
        console.log(val, 'clickContent')
      }
    },
  },
  methods: {
    getProblems() {
      getVideoAqxjProblemByVideoId(this.clickVideo.videoId).then(res => {
        if (res.data.code === 0) {
          this.yhList = []
          res.data.data.forEach(el => {
            this.yhList.push({
              yhDesc: el.problems,
              yhPosition: el.yhbw,
              yhPhoto: el.attachBeforeList[0].path,
              id: el.id
            })
          })
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    handlePreview(e) {
      this.previewImage = e;
      this.previewVisible = true;
    },
    handleCancelPreview() {
      this.previewImage = ''
      this.previewVisible = false;
    },
    enableRectangle() {
      this.drawingMode = 'rectangle';
    },
    enableArrow() {
      this.drawingMode = 'arrow';
    },
    undo() {
      this.shapes.pop();
      this.redrawCanvas();
    },
    // 截图
    captureScreenshot() {
      this.showScreenshotDialog = true
      this.$nextTick(() => {

        const video = this.$refs.video;
        video.pause()
        const canvas = this.$refs.canvas;
        const context = canvas.getContext('2d');
  
        const aspectRatio = video.videoWidth / video.videoHeight;
        canvas.width = this.canvasWidth; 
        canvas.height = this.canvasHeight / aspectRatio; 
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        // 获取视频播放时间
        this.captureTime = video.currentTime;
        console.log(`视频当前播放时间: ${this.captureTime} 秒`);
      })
    },
    handleCancel() {
      this.screenshot = ''
      const video = this.$refs.video;
      video.play()
      this.shapes = []
      this.form.resetFields()
      this.showScreenshotDialog = false
    },
    cancel() {
      this.yhList = []
      this.$emit('cancel', false)
    },
    //获取鼠标位置
    getMousePos(event) {
      const canvas = this.$refs.canvas;
      const rect = canvas.getBoundingClientRect();
      return {
        x: event.clientX - rect.left,
        y: event.clientY - rect.top
      };
    },
    startDrawing(event) {
      const pos = this.getMousePos(event);
      this.startX = pos.x;
      this.startY = pos.y;
      this.isDrawing = true;
    },
    draw(event) {
      if (!this.isDrawing) return;

      const pos = this.getMousePos(event);
      const canvas = this.$refs.canvas;
      const context = canvas.getContext('2d');

      this.redrawCanvas();

      context.strokeStyle = 'red';
      context.lineWidth = 2;

      if (this.drawingMode === 'rectangle') {
        const width = pos.x - this.startX;
        const height = pos.y - this.startY;
        context.strokeRect(this.startX, this.startY, width, height);
      } else if (this.drawingMode === 'arrow') {
        context.beginPath();
        context.moveTo(this.startX, this.startY);
        context.lineTo(pos.x, pos.y);
        context.stroke();
        context.closePath();

        const angle = Math.atan2(pos.y - this.startY, pos.x - this.startX);
        context.fillStyle = 'red';
        context.beginPath();
        context.moveTo(pos.x, pos.y);
        context.lineTo(pos.x - 10 * Math.cos(angle - Math.PI / 6), pos.y - 10 * Math.sin(angle - Math.PI / 6));
        context.lineTo(pos.x - 10 * Math.cos(angle + Math.PI / 6), pos.y - 10 * Math.sin(angle + Math.PI / 6));
        context.lineTo(pos.x, pos.y);
        context.fill();
        context.closePath();
      }
    },
    stopDrawing(event) {
      if (!this.isDrawing) return;

      const pos = this.getMousePos(event);

      if (this.drawingMode === 'rectangle') {
        this.shapes.push({
          type: 'rectangle',
          startX: this.startX,
          startY: this.startY,
          width: pos.x - this.startX,
          height: pos.y - this.startY,
        });
      } else if (this.drawingMode === 'arrow') {
        this.shapes.push({
          type: 'arrow',
          startX: this.startX,
          startY: this.startY,
          endX: pos.x,
          endY: pos.y,
        });
      }

      this.isDrawing = false;
      // this.drawingMode = '';
    },
    redrawCanvas() {
      const canvas = this.$refs.canvas;
      const context = canvas.getContext('2d');

      const video = this.$refs.video;
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      this.shapes.forEach((shape) => {
        context.strokeStyle = 'red';
        context.lineWidth = 2;

        if (shape.type === 'rectangle') {
          context.strokeRect(shape.startX, shape.startY, shape.width, shape.height);
        } else if (shape.type === 'arrow') {
          context.beginPath();
          context.moveTo(shape.startX, shape.startY);
          context.lineTo(shape.endX, shape.endY);
          context.stroke();
          context.closePath();

          const angle = Math.atan2(shape.endY - shape.startY, shape.endX - shape.startX);
          context.fillStyle = 'red';
          context.beginPath();
          context.moveTo(shape.endX, shape.endY);
          context.lineTo(shape.endX - 10 * Math.cos(angle - Math.PI / 6), shape.endY - 10 * Math.sin(angle - Math.PI / 6));
          context.lineTo(shape.endX - 10 * Math.cos(angle + Math.PI / 6), shape.endY - 10 * Math.sin(angle + Math.PI / 6));
          context.lineTo(shape.endX, shape.endY);
          context.fill();
          context.closePath();
        }
      });
    },
    confirmCapture() {
      const _that = this
      const canvas = this.$refs.canvas
      this.uploading = true
      this.screenshot = canvas.toDataURL('image/png');
      console.log(this.screenshot, 'this.screenshot')
      this.form.validateFields((err, values) => {
        if (!err) {
          values.yhPhoto = this.screenshot //保存base64截图
          values.shapes = this.shapes //保存视频的标记
          values.captureTime = this.captureTime //保存视频当前播放时间
          if (this.isEdit) {
            this.yhList.splice(this.editIndex, 1, values)
            this.isEdit = false
            this.editIndex = null
          } else {
            // 将Canvas内容转换为Blob
            canvas.toBlob(function(blob) {
                // 使用FormData构造函数创建一个新的FormData对象
                let formData = new FormData();
                // 将Blob添加到FormData对象中
                formData.append('file', blob, 'image.png');
                formData.append('projectId', _that.recordData.projectId);
                
                // 发送FormData
                _that.attachmentUpload(formData, values)
            }, 'image/png');
          }
          this.isDrawing = false
          this.drawingMode = ''
          this.screenshot = ''
          this.captureTime = null
          console.log('Received values of form: ', values);
        }
      });
      this.handleCancel()
    },
    /*
      * 请求接口
      * type 上传组件的类型
      */
    attachmentUpload(formData, value) {
      // ATTACHMENT_UPLOAD上传接口
      request(ATTACHMENT_UPLOAD_WATER, "post", formData).then(res => {
        if (res.data.code === 0) {
          let params = {
            recordId: this.clickContent.id,
            problems: value.yhDesc,
            yhbw: value.yhPosition,
            attachBefore: res.data.data.id,
            videoId: this.clickVideo.videoId
          }
          value.yhPhoto = res.data.data.path
          createVideoProblem(params).then((res2) => {
            if (res2.data.code === 0) {
              this.$message.success('添加成功')
              value.id = res2.data.data
              this.uploading = false
              this.yhList.unshift(value)
            } else {
              this.$message.error(res2.data.msg)
            }
          });
        } else {
          this.$message.warning(res.message);
        }
      })
    },
    onEdit(item, index) {
      console.log(item, index)
      this.editIndex = index
      this.screenshot = item.yhPhoto
      this.isEdit = true
      this.shapes = item.shapes
      const _that = this
      const video = this.$refs.video;
      video.pause()
      video.currentTime = item.captureTime
      this.captureTime = item.captureTime
      this.showScreenshotDialog = true
      this.$nextTick(() => {
        this.form.setFieldsValue(item)
        let img = new Image()
        img.src = item.yhPhoto
        img.onload = function() {
          const canvas = _that.$refs.canvas
          const context = canvas.getContext('2d')
          const aspectRatio = video.videoWidth / video.videoHeight;
          canvas.width = _that.canvasWidth; 
          canvas.height = _that.canvasHeight / aspectRatio; 
          context.clearRect(0, 0, canvas.width, canvas.height);
          context.drawImage(img, 0, 0, canvas.width, canvas.height)
        }
      })
    },
    del(item, index) {
      console.log(item, 'del')
      const _that = this
      this.$confirm({
        title: '确定删除？',
        onOk() {
          delVideoProblem(item.id).then((res2) => {
            if (res2.data.code === 0) {
              _that.$message.success('删除成功')
              _that.yhList.splice(index, 1)
            } else {
              _that.$message.error(res2.data.msg)
            }
          });
        }
      })
    },
    onSave() {
      this.yhList = []
      this.$emit('save', this.yhList)
    },
  },
};
</script>

<style lang="less" scoped>
.form-buttons {
  text-align: center;
  margin-top: 10px;

  .ant-btn {
    margin: 0 8px;
  }
}
.yh-desc-box, .yh-position-box {
  padding: 10px;
  background: #F3F3F3;
}
</style>