<template>
  <div class="m_g">
      <div class="flex p-1">
        <div class="m_g_left public wapper">
          <form-index ref="formIndex" @onDateChange="onDateChange" :formData="formData" Overview="基本信息" :options="options"
            @resourceUpload="resourceUpload" @upRemove="upRemove" @handlSelect="handlSelect"></form-index>
        </div>
        <div class="wapper">
          <div class="m_g_right_top public">
            <form-index ref="formIndex1" :options="options" @upRemove="upRemove" :formData="formDataTop" Overview="工卡/工资卡信息" @resourceUpload="resourceUpload" @handleChange="handleChange" @handlSelect="handlSelect"></form-index>
          </div>
          <!-- <div class="m_g_right_mid public">
            <form-index ref="formIndex2" :formData="formDataMid" Overview="工种/职务信息" @upRemove="upRemove" @handlSelect="handlSelect" @resourceUpload="resourceUpload"></form-index>
          </div> -->
          <!-- <div class="m_g_right_bottom public">
            <form-index ref="formIndex3" :formData="formDataBottom" Overview="证件信息" @upRemove="upRemove" @resourceUpload="resourceUpload"></form-index>
          </div> -->
          <div class="mt-1 m_g_right_mid public">
            <form-index ref="formIndexInfo" :formData="formDataInfo" Overview="紧急联系人信息"/>
          </div>
        </div>
      </div>
    <template>
      <div class="m_g_button">
        <a-button type="primary" @click="handleSubmit">
          提交
        </a-button>
      </div>
    </template>
  </div>
</template>

<script>
// let obj =  this.$refs['formindex'].getFieldValue()
let _that = null
const formDataInfo = [
  {
    label: "姓名",
    placeholder: "请输入姓名",
    decorator: [
      "emergencyUserName",
      { rules: [{ required: false, message: "请输入姓名" },{ required: false, message: "最长不超过12位汉字" ,pattern:/(^[\u4e00-\u9fa5]{1,12}$)/}] },
    ],
    type: "input",
    key: "emergencyUserName",
    labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
    maxLength: 13
  },
  {
    label: "电话",
    placeholder: "请输入紧急联系人电话",
    decorator: [
      "emergencyUserPhone",
      { rules: [{ required: false, message: "请正确输入紧急联系人电话",pattern: new RegExp(/^1\d{10}$/),}] },
    ],
    type: "input",
    key: "emergencyUserPhone",
     labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
    maxLength: 11,
  },
  {
    label: "地址",
    placeholder: "请输入地址",
    decorator: [
      "emergencyAddress",
      { rules: [{ required: false, message: "请输入地址" }] },
    ],
    type: "input",
    key: "emergencyAddress",
    labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
    maxLength: 13
  },
  {
    label: "与劳动者关系",
    placeholder: "请选择",
    decorator: [
      "emergencyRelation",
      { rules: [{ required: false, message: "请选择" }] },
    ],
    showSearch: true,//暂时注释
    type: "select",
    selectlist: [
      {
        name: "父子",
        value: "父子",
      },
      {
        name: "母子",
        value: "母子",
      },
      {
        name: '父女',
        value: "父女",
      },
      {
        name: '母女',
        value: "母女", 
      },
      {
        name: '夫妻',
        value: "夫妻",
      },
      {
        name: '兄弟姐妹',
        value: "兄弟姐妹",
      },
      {
        name: '其他',
        value: "其他",
      }
    ],
    key: "emergencyRelation",
    labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
  },
  ]
const formData = [
  {
    type: "place",
    labelCol: 6,
    wrapperCol: 16,
    col: 2,
  },
  {
    label: "",
    title: "上传正面照",
    placeholder: "请上传正面照",
    decorator: [
      "facePath",
      { rules: [{ required: true, message: "请上传正面照" }] },
    ],
    type: "tsUpload",
    key: "facePath",
    labelCol: 6,
    wrapperCol: 16,
    defaultImg: require('@/assets/img/front.png'),
    col: 8,
    defaultFileList: [],
    display: true,
  },
   {
    label: "",
    title: "上传反面照",
    placeholder: "请上传反面照",
    defaultImg: require('@/assets/img/opposite.png'),
    defaultFileList: [],
    previewImage: '',
    decorator: [
      "backPath",
      { rules: [{ required: true, message: "请上传反面照" }] },
    ],
    type: "tsUpload",
    key: "backPath",
    labelCol: 8,
    wrapperCol: 14,
    col: 8,
    display: true,
  },
  {
    label: "",
    title: "上传头像",
    placeholder: "请上传头像",
    defaultImg: require('@/assets/img/headsculpture.png'),
    defaultFileList: [],
    previewImage: '',
    decorator: [
      "portraitPath",
      { rules: [{ required: true, message: "请上传头像" }] },
    ],
    type: "tsCropperUpload",
    key: "portraitPath",
    labelCol: 6,
    wrapperCol: 16,
    col: 6,
    display: true,
    compress: true,
    cropperTitle: '编辑头像',
    showCroImg: true
  },
  {
    label: "姓名",
    placeholder: "请输入姓名",
    decorator: [
      "name",
      { rules: [{ required: true, message: "请输入姓名" },{ required: true, message: "最长不超过12位汉字" ,pattern:/(^[\u4e00-\u9fa5]{1,12}$)/}] },
    ],
    type: "input",
    key: "name",
    dataSource: [],
    labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
    maxLength: 13
  },
  {
    label: "性别",
    placeholder: "请选择性别",
    decorator: [
      "gender",
      {
        rules: [{ required: true, message: "请选择性别" }],
      },
    ],
    type: "radio",
    class: "radio",
    key: "gender",
    radiolist: [
      {
        name: "男",
        value: "男",
      },
      {
        name: "女",
        value: "女",
      },
    ],
     labelCol: 8,
    wrapperCol: 12,
    col: 12,
    display: true,
  },
  {
    label: "身份证号",
    placeholder: "请输入身份证号",
    decorator: [
      "idCard",
      { rules: [{ required: true, message: "请正确输入身份证号", pattern:/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,}] },
    ],
    type: "input",
    key: "idCard",
    dataSource: [],
     labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
    inputChange: (e) => {
      _that.$nextTick(() => {
        const idCard = _that.$refs.formIndex.getFieldValue().idCard
        if (_that.isIdCardNo(idCard)) {
          selectStaffInfoByIdCard({idCard}).then(res => {
            if (res.data.code == -1) {
              _that.$message.warning(res.data.msg)
            } else if (res.data.code == 0) {
              res.data.data.expiryDate = [res.data.data.expiryStart, res.data.data.expiryEnd]
              res.data.data.nationality = [res.data.data.nativePlace, res.data.data.nativeCity, res.data.data.nativeArea]
              _that.$refs.formIndex.queryObj(res.data.data)
              let path = ["facePath", "backPath", "appPhotoPath"];
              _that.formData.forEach((item) => {
                path.forEach((key, index) => {
                  if (item.key == key && res.data.data[key]) {
                    item.defaultFileList = [
                      { url: res.data.data[key], uid: index, name: "image.png", key },
                    ];
                  }
                });
              });
            }
          })
        }
      })
    }
  },
  {
    label: "民族",
    placeholder: "请输入民族",
    decorator: [
      "nation",
      { rules: [{ required: true, message: "请输入民族" },{ required: false, message: "最长不超过8位汉字" ,pattern:/(^[\u4e00-\u9fa5]{1,8}$)/}] },
    ],
    type: "input",
    key: "nation",
    dataSource: [],
     labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
    maxLength: 9,
  },
  {
    label: "出生日期",
    placeholder: "请输入出生日期",
    decorator: [
      "birthday",
      { rules: [{ required: true, message: "请输入出生日期" }] },
    ],
    type: "datePicker",
    format: "yyyy-MM-DD",
    key: "birthday",
    dataSource: [],
     labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
    styles: {width: '100%'}
  },
  {
    label: "签发机关",
    placeholder: "请输入签发机关",
    decorator: [
      "signOrgan",
      { rules: [{ required: true, message: "请输入签发机关" }, { required: true, message: "最长不超过20位" ,pattern:/^[\u4e00-\u9fa5|\d]{1,20}$/}] },
    ],
    type: "input",
    key: "signOrgan",
    dataSource: [],
     labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
    maxLength: 21
  },
  {
    label: "有效期限",
    placeholder: "请选择有效期限",
    decorator: [
      "expiryDate",
      { rules: [{ required: true, message: "请选择有效期限" }] },
    ],
    type: "rangePicker",
    key: "expiryDate",
    dataSource: [],
     labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
    format: "YYYY-MM-DD HH:mm:ss",
  },
  {
    label: "手机号码",
    placeholder: "请输入手机号码",
    decorator: [
      "phone",
      { rules: [{ required: true, message: "请正确输入手机号码",pattern: new RegExp(/^1\d{10}$/),}] },
    ],
    type: "input",
    key: "phone",
    dataSource: [],
     labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
    maxLength: 11,
  },
  {
    label: "籍贯",
    placeholder: "请选择省市区",
    decorator: [
      "nationality",
      { rules: [{ required: true, message: "请选择省市区" }] },
    ],
    type: "cascader",
    key: "nationality",
    labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
  },
  {
    label: "住址",
    placeholder: "请输入住址",
    decorator: [
      "address",
      { rules: [{ required: true, message: "请输入住址" }] },
    ],
    type: "input",
    key: "address",
    dataSource: [],
    labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
    maxLength: 201,
  },
  {
    label: "婚姻状况",
    placeholder: "请输入婚姻状况",
    decorator: [
      "maritalStatus",
      {
        initialValue: "1",
        rules: [{ required: true, message: "请选择婚姻状况" }]
      },
    ],
    type: "select",
    selectlist: [
      {
        name: "未婚",
        value: "0",
      },
      {
        name: "已婚",
        value: "1",
      },
      {
        name: "离异",
        value: "2",
      },
      {
        name: "丧偶",
        value: "3",
      },
    ],
    key: "maritalStatus",
    dataSource: [],
     labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
  },
  {
    label: "文化程度",
    placeholder: "请选择文化程度",
    decorator: [
      "education",
      {
        initialValue: "初中及以下",
        rules: [{ required: true, message: "请选择文化程度" }]
      },
    ],
    selectlist: [{
      value: '初中及以下',
      name: '初中及以下'
    }, {
      value: '高中',
      name: '高中'
    }, {
      value: '中专',
      name: '中专'
    }, {
      value: '大专',
      name: '大专'
    }, {
      value: '本科',
      name: '本科'
    }, {
      value: '本科及以上',
      name: '本科及以上'
    }],
    type: "select",
    key: "education",
    dataSource: [],
     labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
  },
  {
    label: "拟退场时间",
    placeholder: "请选择拟退场时间",
    decorator: [
      "planExitDate",
      { rules: [{ required: false, message: "请选择拟退场时间" }] },
    ],
    type: "datePicker",
    format: "yyyy-MM-DD",
    key: "planExitDate",
    dataSource: [],
     labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
    styles: {width: '100%'},
    defaultPickerValue: moment().add(30, 'days'),
    disabledDate(date) {
      // 1. 拟退场时间必须晚于入场时间，入场时间之前（含入场当日）不可选
      let entranceTime = new Date();
      // 2. 拟退场时间-当前时间往后延 30天 2024.7.5
      entranceTime.setDate(entranceTime.getDate() + 29);
      return date <= entranceTime
    },
  },
  {
    label: "是否购买新农合社保",
    placeholder: "请选择新农合社保",
    decorator: [
      "isBuyXnhsb",
      {
        initialValue: 0,
        rules: [{ required: false, message: "请选择新农合社保" }],
      },
    ],
    type: "radio",
    class: "radio",
    key: "isBuyXnhsb",
    radiolist: [
      {
        name: "是",
        value: 1,
      },
      {
        name: "否",
        value: 0,
      },
    ],
    labelCol: 10,
    wrapperCol: 12,
    col: 12,
    display: true,
  },
  {
    label: "应急号码",
    placeholder: "请输入应急号码",
    decorator: [
      "emergencyTel",
      { rules: [{ required: false, message: "请输入应急号码" }] },
    ],
    type: "input",
    key: "emergencyTel",
    dataSource: [],
     labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
  },
  {
    label: "特种证书",
    placeholder: "上传特种证书",
    decorator: [
      "certificateImg",
      { rules: [{ required: false, message: "上传特种证书" }] },
    ],
    type: "tsUpload",
    key: "certificateImg",
    listType: 'picture-card',
    defaultFileList: [],
    labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
    class: "Upload",
    showUploadList: true
  },
];

const formDataMid = [
 {
  label: "岗位/工种",
  placeholder: "请选择岗位/工种",
  decorator: [
    "workTypeCode",
    { rules: [{ required: true, message: "请选择岗位/工种" }] },
  ],
  type: "select",
  selectlist: [],
  key: "workTypeCode",
  dataSource: [],
  labelCol: 8,
  wrapperCol: 14,
  col: 12,
  display: true,
  showSearch: true,
  disabled: true,
}]
const formDataBottom = [{
  label: "证书类型",
  placeholder: "请选择证书类型",
  decorator: ["certType",
    { rules: [{ required: false, message: "请选择证书类型" }] },
  ],
  type: "select",
  selectlist: [],
  key: "certType",
  dataSource: [],
  labelCol: 6,
  wrapperCol: 14,
  col: 12,
  display: true,
}, {
  label: "证书名称",
  placeholder: "请输入证书名称",
  decorator: ["certName",
    { rules: [{ required: false, message: "请输入证书名称" }] },
  ],
  type: "input",
  key: "certName",
  dataSource: [],
  labelCol: 6,
  wrapperCol: 14,
  col: 12,
  display: true,
}, {
  label: "证书编号",
  placeholder: "请输入证书编号",
  decorator: ["certNum",
    { rules: [{ required: false, message: "请输入证书编号" }] },
  ],
  type: "input",
  key: "certNum",
  dataSource: [],
  labelCol: 6,
  wrapperCol: 14,
  col: 12,
  display: true,
  maxLength:30
}, {
  label: "初领时间",
  placeholder: "请选择初领时间",
  decorator: ["effectiveStart",
    { rules: [{ required: false, message: "请选择初领时间" }] },
  ],
  type: "datePicker",
  key: "effectiveStart",
  dataSource: [],
  labelCol: 6,
  wrapperCol: 14,
  col: 12,
  display: true,
  styles: {width: '100%'},
  format: "yyyy-MM-DD",
}, {
    label: "到期时间",
    placeholder: "请选择到期时间",
    decorator: [
      "effectiveEnd",
      { rules: [{ required: false, message: "请选择到期时间" }] },
    ],
    type: "datePicker",
    key: "effectiveEnd",
    dataSource: [],
    labelCol: 6,
    wrapperCol: 14,
    col: 12,
    display: true,
    format: "yyyy-MM-DD",
    styles: {width: '100%'}
  }, {
    label: "证书",
    placeholder: "上传证书",
    decorator: [
      "attachment",
      { rules: [{ required: false, message: "上传证书" }] },
    ],
    type: "tsUpload",
    key: "attachment",
    listType: 'picture-card',
    defaultFileList: [],
    labelCol: 6,
    wrapperCol: 14,
    col: 12,
    display: true,
    class: "Upload",
    showUploadList: true
  }]
import formIndex from "@/pages/components/form/index";
import address from "@/pages/components/form/address.json";
import { saveStaffInfo, getIdCardInfo, getJobByWorkerType, getCertificateTypeList, staffInfo, getBank, getPoliticalOutlook,
  selectStaffInfoByIdCard,getBankCrad,getJobFindWorkerType,getAllTeams,getApprovePersonInfoSys,setApprovePersonInfo,enablePlanExit, checkPlanExitDate,getSetParamsByPidOid} from "@/services/participationUnit"
import { changeDate } from '@/utils/util.js'
import { getOfficeListByProjectId } from '@/services/payroll'
import { mapGetters } from 'vuex'
import moment from "moment";
import { replace, debounce } from "lodash";
import { valueFormatToTimestamp } from '@/utils/valueFormat.js'
export default {
  components: {
    formIndex,
  },
  data() {
    const formDataTop = [
  {
    type: "place",
    labelCol: 6,
    wrapperCol: 16,
    col: 3,
  },
  {
  label: "",
  title: "上传工资卡",
  placeholder: "请上传工资卡",
  decorator: [
    "bankCardPath",
    { rules: [{ required: false, message: "请上传工资卡" }] },
  ],
  type: "tsCropperUpload",
  key: "bankCardPath",
  labelCol: 6,
  wrapperCol: 16,
  col: 7,
  defaultImg: require('@/assets/img/wageCard.png'),
  defaultFileList: [],
  display: true,
  cropperTitle: '编辑工资卡',
}, {
  label: "开户银行",
  placeholder: "请输入开户银行",
  decorator: [
    // "salaryBranch",
    "bankName",
    { rules: [{ required: true, message: "请输入开户银行" }] },
  ],
  type: "inputGroup",
  // key: "salaryBranch",
  key: "bankName",
  dataSource: [],
  labelCol: 8,
  wrapperCol: 14,
  col: 12,
  display: true,
}, {
  label: "开户账号",
  placeholder: "请输入开户账号",
  decorator: [
    // "salaryAccount",
    "bankCardNum",
    { rules: [{ required: true, message: "请输入" }] },
  ],
  type: "input",
  // key: "salaryAccount",
  key: "bankCardNum",
  dataSource: [],
  labelCol: 8,
  wrapperCol: 14,
  col: 12,
  display: true,
  maxLength:30
}
// ,
// {
//   label: "开户所属地",
//   placeholder: "请输入开户所属地",
//   decorator: [
//     "branchAddress",
//     { rules: [{ required: false, message: "请输入开户所属地" }] },
//   ],
//   type: "cascader",
//   key: "branchAddress",
//   labelCol: 8,
//   wrapperCol: 14,
//   col: 12,
//   display: true,
// }
,
{
  label: "银行名称",
  placeholder: "请输入银行名称",
  decorator: [
    "salaryName",
    { rules: [{ required: false, message: "请输入银行名称" }] },
  ],
  type: "input",
  key: "salaryName",
  labelCol: 0,
  wrapperCol: 0,
  col: 0,
  display: false,
},
]
    return {
      formData: formData,
      options: address,
      formDataTop: formDataTop,
      formDataMid: formDataMid,
      formDataBottom: formDataBottom,
      formDataInfo:formDataInfo,
      formDataArr: [],
      allSubmitobj: {},
      projWorkNum: '',
      teamId: '',
      workNum: '',
      operateType: 1,
      params: {
        operateType: this.operateType,
        staffLib: {},
        teamStaffRelation: {},
        staffCertication: {}
      },
      paramsObj: {
        projectPersonId: this.projectPersonId,
        applyType:this.applyType,
        staffVo: {}
      },
      selectBranch: [
        {
          name: '工商',
          code: '0102'
        },
        {
          name: '农业',
          code: '0103'
        },
        {
          name: '建设',
          code: '0105'
        },
        {
          name: '广西农村信用社联合社',
          code: '1443'
        },
        {
          name: '浦东发展银行',
          code: '0310'
        },
      ], // 上传银行卡-匹配不到的name值自定义code
      teamData: [],
      personInfo: {},
      // workTypeData: []
    };
  },
  inject: ["fatherMethod"],
  created() {
    _that = this
    this.getBankList({key: 'bankName', value: ''})
  },
  activated() {
    this.getWorkerType()
    this.applyType = this.$route.query.applyType
    this.projectPersonId = this.$route.query.projectPersonId
    const formDataArr = [this.formData, this.formDataTop, this.formDataMid, this.formDataBottom]
    formDataArr.forEach(i => {
      i.forEach(item => {
        if (item.type === 'tsUpload' || item.type === 'tsCropperUpload') {
          item.defaultFileList = []
        }
      })
    })
    this.getOfficeListByProjectId()

    this.getCertificateTypeList()
    this.getPoliticalOutlook()
    this.enablePlanExit()
    this.projWorkNum = this.$route.query.projWorkNum
    this.teamId = this.$route.query.teamId
    this.workNum = this.$route.query.workNum
    if (this.$route.query.operateType) {
      this.operateType = this.$route.query.operateType
    }
    this.getApprovePersonInfo()
    // 校验是否必填紧急联系人
    this.getSetParamsByPidOid(this.$route.query.officeId)
  },
  computed: {
    ...mapGetters('account', ['user']),
  },
  methods: {
    // 获取工人进场设置
    getSetParamsByPidOid(officeId) {
      getSetParamsByPidOid({officeId: officeId}).then(res => {
        const enterSet = JSON.parse(res.data.data.enterSet)
        if (enterSet.emergency.isOpen) {
          if (enterSet.emergency.fields && enterSet.emergency.fields.length > 0) {
            this.formDataInfo.forEach(el => {
              if (enterSet.emergency.fields.includes(el.key)) {
                el.decorator.forEach((items) => {
                  if (items instanceof Object) {
                    items.rules[0].required = true;
                  }
                })
              } else {
                el.decorator.forEach((items) => {
                  if (items instanceof Object) {
                    items.rules[0].required = false;
                  }
                })
              }
          })
          }
        } else {
          this.formDataInfo.forEach(el => {
            el.decorator.forEach((items) => {
              if (items instanceof Object) {
                items.rules[0].required = false;
              }
            })
          })
        }
      })
    },
    setWorkType(val) {
      if (val) {
        // 使用find方法查找匹配项
      const matchedItem = this.workTypeData.find(item => item.id === val);
      // 返回匹配项
      return matchedItem
      }
    },
    getApprovePersonInfo() {
      getApprovePersonInfoSys({projectPersonId: this.projectPersonId,applyType: this.applyType}).then(res => {
        if (res.data.code=== 0) {
          let {staffVo} = res.data.data
          this.personInfo = res.data.data
          this.paramsObj.staffVo = staffVo
          if (staffVo) {
            // 回显拟退场时间
            staffVo.planExitDate = this.personInfo.planExitDate
            staffVo.isBuyXnhsb = this.personInfo.isBuyXnhsb
            staffVo.expiryDate = [staffVo.expiryStart, staffVo.expiryEnd]
            staffVo.nationality = [this.personInfo.province, this.personInfo.city, this.personInfo.counties]
          }
          this.$refs.formIndexInfo.queryObj(staffVo)
          this.$refs.formIndex.queryObj(staffVo)
          this.$refs.formIndex1.queryObj(staffVo)
          // 岗位/工种
          // this.$refs.formIndex2.queryObj({workTypeCode:this.personInfo.workTypeCode})

          // console.log(this.setWorkType(this.personInfo.workTypeCode),'this.setWorkType(this.personInfo.workTypeCode)')
          // 处理回显图片path
          let path = ["facePath", "backPath", "portraitPath", "certificateImg"];
            this.formData.forEach((item) => {
              path.forEach((key, index) => {
                if (item.key == key && this.personInfo.staffVo[key]) {
                  item.defaultFileList = [
                    { url: this.personInfo.staffVo[key], uid: index, name: "image.png", key: item.key },
                  ];
                } else if (item.key == key && this.personInfo[key]) {
                  // 特种证书回显
                  item.defaultFileList = [
                    { url: this.personInfo[key], uid: index, name: "image.png", key: item.key },
                  ];
                }
              });
            });

          // 处理回显银行卡
          this.formDataTop.forEach((item) => {
              if (item.key == 'bankCardPath' && this.personInfo.staffVo['bankCardPath']) {
                item.defaultFileList = [
                  { url: this.personInfo.staffVo['bankCardPath'], uid: 0, name: "image.png", key: item.key },
                ];
              }
            });
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 关闭当前页面
    close() {
      let path = this.$route.path;
      this.fatherMethod(`${path}`);
      this.$router.push({name: "在场工人列表"});
    },
    // 银行卡号校验函数
    // checkCardNum(rule, value, callback) {
    //   var reg = /^([1-9]{1})(\d{14}|\d{18})$/;
    //   if (reg.test(value)) {
    //     callback();
    //   } else {
    //     callback(new Error('银行卡号格式不正确'));
    //   }
    // },
    isIdCardNo(num) {
      num = num.toUpperCase();
      //身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X。
      if ( !(/(^\d{15}$)|(^\d{17}([0-9]|X)$)/.test(num)) ) {
        console.log('身份证错误')
        return false;
      } else {
        console.log('身份证正确')
        return true;
      }
    },
    // 输入选择框触发
    handleChange(e) {
      // // 获取当前匹配key，赋值相对于数组
      this.getBankList({key: e.item.key, value: e.value})
    },
    getBankList(e) {
      getBank({name: e.value}).then(res => {
        if (res.data.code=== 0) {
          this.formDataTop.forEach((item) => {
            if (e.key === item.key) {
              let arr = []
              res.data.data.forEach(el => {
                arr.push({
                  title: el.name,
                  id: el.code
                })
              })
              item.dataSource = arr
            }
          });
        }
      })
    },
    getCertificateTypeList() {
      getCertificateTypeList({isPage: "false"}).then(res => {
        if(res.data.code == 0){
          this.formDataFn('certType', res.data.data.list, 'typeName', this.formDataBottom)
        }
      })
    },
    getPoliticalOutlook() {
      getPoliticalOutlook({type:'political'}).then(res => {
        if(res.data.code == 0){
          this.formDataFn('political', res.data.data, 'label', this.formData)
        }
      })
    },
    //上传文件回调
    resourceUpload(obj) {
      const formDataArr = [this.formData, this.formDataTop, this.formDataMid, this.formDataBottom]
      formDataArr.forEach(i => {
        i.forEach(item => {
          if (item.key === obj.key) {
            item.defaultFileList = [obj]
            if (item.key === 'facePath') {
              this.getIdCardInfo(obj.formData, 'front')
            } else if (item.key === 'backPath') {
              this.getIdCardInfo(obj.formData, 'back')
            } else if (item.key === 'bankCardPath') {
              // 上传工资卡-并回显银行卡号、银行名称
              this.getBankInfo(obj.formData)
            }
          }
        })
      })
    },
    //删除上传附件
    upRemove(file) {
      const formDataArr = [this.formData, this.formDataTop, this.formDataMid, this.formDataBottom]
      formDataArr.forEach(i => {
        i.forEach(item => {
          if (item.key === file.key) {
            item.defaultFileList = []
          }
        })
      })
    },
    //获取身份证信息
    getIdCardInfo(formData, type) {
      getIdCardInfo(formData, type).then(res => {
        if (res.data.code=== 0) {
          console.log(res.data.data)
          const words_result = res.data.data.idCardJson.words_result
          if (type === 'front') {
            // 裁剪的身份证正面
            this.$refs.formIndex.setFieldsValue('facePath', {'facePath': res.data.data.idCardJson.filePath}, 'facePath')
            this.$refs.formIndex.setFieldsValue(words_result.name.words, {'name': words_result.name.words}, 'name')
            this.$refs.formIndex.setFieldsValue(words_result.sex.words, {'gender': words_result.sex.words}, 'gender')
            this.$refs.formIndex.setFieldsValue(words_result.idCard.words, {'idCard': words_result.idCard.words}, 'idCard')
            this.$refs.formIndex.setFieldsValue(words_result.nation.words, {'nation': words_result.nation.words}, 'nation')
            this.$refs.formIndex.setFieldsValue(changeDate(words_result.birthDay.words), {'birthday': changeDate(words_result.birthDay.words)}, 'birthday')
            this.$refs.formIndex.setFieldsValue(words_result.address.words, {'location': words_result.address.words}, 'location')
          } else if (type === 'back') {
            // 裁剪的身份证反面
            this.$refs.formIndex.setFieldsValue('backPath', {'backPath': res.data.data.idCardJson.filePath}, 'backPath')
            this.$refs.formIndex.setFieldsValue(words_result.signAddr.words, {'signOrgan': words_result.signAddr.words}, 'signOrgan')
            let expiryDate = []
            expiryDate.push(changeDate(words_result.signDate.words))
            expiryDate.push(changeDate(words_result.loseDate.words == '长期' ? '20991231' : words_result.loseDate.words))
            this.$refs.formIndex.setFieldsValue(expiryDate, {'expiryDate': expiryDate}, 'expiryDate')
          }
          // 处理回显图片path
          let path = [{key: "facePath",type: 'front'}, {key:"backPath",type:'back'}];
            this.formData.forEach((item) => {
              path.forEach((items, index) => {
                if (item.key == items.key && items.type == type) {
                  item.defaultFileList = [
                    { url: res.data.data.idCardJson.filePath, uid: index, name: "image.png", key: item.key },
                  ];
                }
              });
            });
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 获取银行卡信息
    getBankInfo(formData) {
      getBankCrad(formData).then(res => {
        if (res.data.code == 0) {
          const bank_result = res.data.data.result
          getBank({name: bank_result.bank_name}).then(res => {
            if (res.data.code=== 0) {
              // 处理银行卡name值不匹配code的问题
              if (res.data.data.length == 0) {
                this.selectBranch.map(el => {
                  if (bank_result.bank_name.indexOf(el.name )!= -1) {
                    return (
                    this.$refs.formIndex1.setFieldsValue(bank_result.bank_name, {'bankName': bank_result.bank_name}, 'bankName')
                    // this.$refs.formIndex1.setFieldsValue(bank_result.bank_name, {'salaryBranch': el.code}, 'salaryBranch')
                )

                }
                })
              } else {
                res.data.data.forEach(el => {
                  if (el.name == bank_result.bank_name) {
                    this.$refs.formIndex1.setFieldsValue(bank_result.bank_name, {'bankName': bank_result.bank_name}, 'bankName')
                    // this.$refs.formIndex1.setFieldsValue(bank_result.bank_name, {'salaryBranch': el.code}, 'salaryBranch')
                  }
                })
            }
            }
          })
          // lodash-replace 去除银行卡卡号，多余的空格
          this.$refs.formIndex1.setFieldsValue(replace(bank_result.bank_card_number,/\s+/g, ''), {'bankCardNum': replace(bank_result.bank_card_number,/\s+/g, '')}, 'bankCardNum')
        }else {
          this.$message.error(res.data.msg)
        }
      }).catch((error) => {
           this.$message.error(error)
      })
    },
    //获取工种
    getWorkerType() {
      // getJobByWorkerType([]).then(res => {
      //   if(res.data.code == 0){
      //     this.formDataFn('title', res.data.data, 'carft', this.formDataMid)
      //   }
      // })
      // 根据条件获取工人工种
      getJobFindWorkerType({category: 3}).then(res => {
        if(res.data.code == 0){
          this.formDataFn('workTypeCode', res.data.data, 'carft', this.formDataMid)
        }
      })
    },
    //查询所有单位
    getOfficeListByProjectId(){
      getOfficeListByProjectId().then((res) => {
        if(res.data.code == 0){
          this.formDataFn('unitId', res.data.data, 'name', this.formDataMid)
        }
      })
    },
    // 对下拉框做数据处理
    formDataFn(key, data, itemName, form) {
      let arr = []
      data.forEach(item => {
          if (key == 'political') {
            arr.push({ 'name': item[itemName], 'value': item.value })
          }
          else if (key == 'workTypeCode')  {
            arr.push({ 'name': item[itemName], 'value': item.code })
          }
          else {
            arr.push({ 'name': item[itemName], 'value': item.id })
          }
      })
      form.forEach(item => {
          if (item.key == key) {
              item.selectlist = arr
          }
      })
      // 班组数据-有用
      if (key == 'teamId') {
        this.teamData = data
      }
      // 岗位/工种数据-有用
      if (key == 'workTypeCode') {
        this.workTypeData = data
      }
    },
    handlSelect(obj) {
      const { val, keys } = obj
      // console.log(val, keys)
      if (keys === 'unitId') {
        this.doQueryTeams({ officeId: val })
        //选择单位的同时，重置班组和班组长权限
        this.$refs.formIndex2.setFieldsValue('', {'teamId': ''}, '')
        this.$refs.formIndex2.setFieldsValue('', {'isCaptain': ['0']}, '')
      }
      if (keys === 'teamId') {
        //选择班组的同时，根据姓名判断是否是班组长
        this.$refs.formIndex2.setFieldsValue('', {'isCaptain': ['0']}, [])
        this.setTeamLeader(val,keys)
      }
      if (keys === 'builderStay') {
        const name = ''
        this.formData.forEach(item => {
          if (item.key === 'builderStay') {
            item.radiolist.forEach(i => {
              if (val.target.value === '1') {
                this.$refs.formIndex.setFieldsValue(i.name, {'residence': i.name}, 'residence')
              } else {
                this.$refs.formIndex.setFieldsValue('', {'residence': ''}, 'residence')
              }
            })
          }
        })
      }
      if (keys === 'salaryBranch') {
        this.formDataTop.forEach(item => {
          if (item.key === 'salaryBranch') {
            item.dataSource.forEach(i => {
              if (i.id == val) {
                this.$refs.formIndex1.setFieldsValue(i.title, {'salaryName': i.title}, 'salaryName')
              }
            })
          }
        })
      }
    },
    //查询所有班组
    doQueryTeams(params){
      getAllTeams(params).then(res => {
        if(res.data.code == 0){
          this.formDataFn('teamId', res.data.data, 'teamName', this.formDataMid)
        }
      })
    },
    setTeamLeader(val, keys) {
        let name = this.$refs["formIndex"].getFieldValue().name;
        let isDisabled = true
        this.formDataMid.map((item, index) => {
            if (item.key == keys) {
                this.teamData.map(i => {
                  // 姓名对比班组长名称
                  if (i.id == val && i.teamLeader == name) {
                      this.$refs.formIndex2.setFieldsValue('isCaptain', {'isCaptain': ['1']}, 'isCaptain')
                      isDisabled = false
                  }
                })
            }
            if (item.key == 'isCaptain') {
              item.disabled = isDisabled
            }
        })
    },
    handleSubmit:debounce(function(e) {
      this.formDataArr = []
      let arr = ['formIndex', 'formIndex1']
      arr.forEach(item => {
        this.formDataArr.push(this.$refs[item].combinationSubmit())
      })
      //处理数组第一项
      this.formDataArr[0].then(res => {
        this.paramsObj.staffVo.expiryStart = moment(res.expiryDate[0]).format('YYYY-MM-DD HH:mm:ss')
        this.paramsObj.staffVo.expiryEnd = this.paramsObj.staffVo.expiryEnd ? moment(res.expiryDate[1]).format('YYYY-MM-DD HH:mm:ss') : null
        this.paramsObj.province = res.nationality[0]
        this.paramsObj.city = res.nationality[1]
        this.paramsObj.counties = res.nationality[2]
        this.paramsObj.isBuyXnhsb = res.isBuyXnhsb
        this.paramsObj.certificateImg = res.certificateImg
        // 提交时-拟退休时间
        this.paramsObj.planExitDate = res.planExitDate

        for (let item in res) {
          if (item === 'birthday') {
            res[item] = moment(res[item]).format('YYYY-MM-DD HH:mm:ss')
          }
          this.paramsObj.staffVo[item] = res[item]
        }
        // params.staffLib = Object.assign({}, ...res)
        this.formDataArr.shift()//去除数组第一项
      })
      this.formDataArr[1].then(res=> {
        // this.paramsObj.staffLib[item] = res[item]
        for (let item in res) {
          this.paramsObj.staffVo[item] = res[item]
        }
        console.log(res,'res ===this.formDataArr[1]')
      })
      // this.formDataArr[2].then(res=> {
      //   for (let item in res) {
      //     this.paramsObj[item] = res[item]
      //   }
      // })
      Promise.all(this.formDataArr).then((data) => {
        delete this.paramsObj.staffVo.nationality
        delete this.paramsObj.staffVo.expiryDate
        delete this.paramsObj.staffVo.isBuyXnhsb
        delete this.paramsObj.staffVo.certificateImg
        delete this.paramsObj.staffVo.planExitDate
        // this.paramsObj.
        // console.log(this.setWorkType(this.personInfo.workTypeCode),'this.setWorkType(this.personInfo.workTypeCode)')
        this.paramsObj.projectPersonId = this.projectPersonId
        this.paramsObj.applyType = this.applyType
        // 日期字符串格式化为时间戳
        // valueFormatToTimestamp(this.paramsObj.staffVo, ['birthday', 'expiryStart','expiryEnd'])
          setApprovePersonInfo(this.paramsObj).then(obj => {
            if (obj.data.code == 0) {
                this.$message.success('修改成功！')
                this.$refreshPage(this.$route.path); // 刷新当前页面
                // 重新入场-关闭当前页面-进入在场工人列表
                // this.close()
          } else {
            this.$message.error(obj.data.msg)
          }
          })
      })
    },1000),
    // 校验是否开启拟退场
    enablePlanExit() {
      const params = {
        officeId: this.$route.query.officeId,
        projectId: this.user.projectId
      }
      enablePlanExit(params).then(res => {
        if (res.data.code == 0)
          this.formData.forEach(el => {
            if (el.key === 'planExitDate') {
              if (res.data.data === true) {
                el.decorator.forEach((items) => {
                  if (items instanceof Object) {
                    items.rules[0].required = true;
                  }
                })
              } else {
                el.decorator.forEach((items) => {
                  if (items instanceof Object) {
                    items.rules[0].required = false;
                  }
                })
              }
            }
          })
      })
    },
    onDateChange(date) {
      const idCard = this.$refs.formIndex.getFieldValue().idCard
      if (this.isIdCardNo(idCard)) {
        const planExitParam = {
          idCards:[idCard],
          projectId: this.user.projectId,
          planExitDate: date
        }
        checkPlanExitDate(planExitParam).then(res => {
          if (res.data.code == 0) {
            // console.log('成功')
          } else {
            this.$refs.formIndex.setFieldsValue('planExitDate', {'planExitDate': null}, 'planExitDate')
            this.$message.error(res.data.msg, 10)
          }
        })
      }
    }
}
};
</script>

<style lang="less" scoped>
.wapper {
  // width: 50%;
  flex: 1;
  // height: 100%;
  // height: 1184px !important;
}
.public {
  padding: 17px;
  background: #fff;
  border-radius: 10px;
}

.m_g {
  background: #ffffff;
  // border-radius: 10px;
  padding-bottom: 20px;
  .m_g_left {
    border: 1px solid rgb(218, 230, 255);
    margin-right: 10px;
    // margin: 10px;
  }

  .m_g_right_top {
    border: 1px solid rgb(218, 230, 255);
    margin-bottom: 10px;
  }

  .m_g_right_mid {
    margin-bottom: 10px;
    border: 1px solid rgb(218, 230, 255);
  }

  .m_g_right_bottom {
    border: 1px solid rgb(218, 230, 255);
  }

  .m_g_button {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 10px;
    margin-right: 10px;
  }
}
// ::v-deep .ant-form-item {
//   margin-bottom: 24px !important;
// }
</style>
