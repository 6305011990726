<template>
  <div>
    <a-row :gutter="16">
      <a-col :span="6">
        <vue-scroll :ops="ops" style="width:100%;height:855px">
        <a-card title="课程管理" size="small" :bordered="false" >
          <a-tree :load-data="onLoadData" :tree-data="treeData" show-icon default-expand-all @select="onSelect" >
            <img src="@/assets/img/type.png" style="width:20px;height:20px"  slot="typeIcon" />
            <img src="@/assets/img/course.png" style="width:20px;height:20px"  slot="courseIcon" />
            <template slot="title" slot-scope="{ title }">
                <span>{{ title }}</span>
            </template>
          </a-tree>
        </a-card>
        </vue-scroll>
      </a-col>
        <a-col :span="17" :offset="0">
            <a-card  :bordered="false" style="margin-right:-75px">
              <template slot="title" >
                <span>{{node.title}}题目清单</span>
                <span style="float:right">
                  <a v-if="currentTab==1" style="float:right;margin-top:3px;margin-left:10px;font-size:13px" href="https://sjlw-templ.oss-cn-shenzhen.aliyuncs.com/mb_contract/%E9%80%89%E6%8B%A9%E9%A2%98%E6%A8%A1%E6%9D%BF.xlsx?Expires=2009523319&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=CAUoKCFcxWPYsEevrgoLtc9lnBA%3D">模板下载</a>
                  <a v-if="currentTab==2" style="float:right;margin-top:3px;margin-left:10px;font-size:13px" href="https://sjlw-templ.oss-cn-shenzhen.aliyuncs.com/mb_contract/%E5%88%A4%E6%96%AD%E9%A2%98%E6%A8%A1%E6%9D%BF.xlsx?Expires=2009523375&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=Qz%2BywDHHdRTzryrQbEOi6w2InXA%3D">模板下载</a>
                  <a-upload
                        name="file"
                        :multiple="false"
                        :beforeUpload="beforeUpload"
                        accept=".xls,.xlsx"
                        :customRequest="resourceUpload"
                        :file-list="fileListFile"
                    >
                      <a-button :disabled="importLoading" style="margin-left:10px" >导入题目</a-button>
                    </a-upload>
                </span>
                </template>
              <div :class="advanced ? null : null">
                <a-form class="clearfix">
                  <a-col :span="5">
                    <a-form-item
                      label="题目名称"
                      :labelCol="{span: 8}"
                      :wrapperCol="{span: 14, offset: 1}"
                    >
                      <a-input placeholder="题目名称" v-model="queryParam.questCont"/>
                    </a-form-item>
                  </a-col>
                  <a-col v-if="currentTab==1" :span="5">
                    <a-form-item
                      label="单选/多选"
                      :labelCol="{span: 8}"
                      :wrapperCol="{span: 14, offset: 1}"
                    >
                      <a-select v-model="queryParam.isMany" placeholder="请选择">
                        <a-select-option :value="0">全部</a-select-option>
                        <a-select-option :value="1">单选</a-select-option>
                        <a-select-option :value="2">多选</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :span="5">
                    <a-form-item
                      label="难易程度"
                      :labelCol="{span: 8}"
                      :wrapperCol="{span: 14, offset: 1}"
                    >
                      <a-select v-model="queryParam.level" placeholder="请选择">
                        <a-select-option :value="0">全部</a-select-option>
                        <a-select-option :value="1">简单</a-select-option>
                        <a-select-option :value="2">一般</a-select-option>
                        <a-select-option :value="3">困难</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :span="3">
                    <a-space class="btnBox">
                      <a-button type="primary" @click="doQuery()">查询</a-button>
                    </a-space>
                  </a-col>
                </a-form>
              </div>
              <a-row >
                <a-col :md="24" :sm="24">
                  <a-tabs defaultActiveKey="1" @change="changeTab">
                    <a-tab-pane key="1" tab="选择题">
                      <standard-table
                        :columns="chooseColumns"
                        :dataSource="chooseDataSource"
                        :selectedRows.sync="selectedRows"
                        :pagination="pagination"
                        :loading="tableLoading"
                        :isAlert="false"
                        @clear="onClear"
                        @change="onPageChange"
                        :bordered="true"
                        :btnArr="tableBtnArr"
                      >
                        <div slot="action" slot-scope="{text, record}">
                          <a-icon style="color:blue" type="edit" @click="editorQuest(record)" title="编辑"></a-icon>
                          <a-divider type="vertical" />
                          <a-icon style="color:red" type="delete" @click="deleteQuest(record)" title="删除"></a-icon>
                        </div>
                        <template slot="statusTitle">
                          <a-icon @click.native="onStatusTitleClick" type="info-circle" />
                        </template>
                      </standard-table>
                    </a-tab-pane>
                    <a-tab-pane key="2" tab="判断题" force-render>
                      <standard-table
                        :columns="judgeColumns"
                        :dataSource="judgeDataSource"
                        :selectedRows.sync="selectedRows"
                        :pagination="pagination"
                        :loading="tableLoading"
                        :isAlert="false"
                        @clear="onClear"
                        @change="onPageChange"
                      >
                        <div slot="action" slot-scope="{text, record}">
                          <a-icon style="color:blue" type="edit" @click="editorQuest(record)" title="编辑"></a-icon>
                          <a-divider type="vertical" />
                          <a-icon style="color:red" type="delete" @click="deleteJudge(record)" title="删除"></a-icon>
                        </div>
                        <template slot="statusTitle">
                          <a-icon @click.native="onStatusTitleClick" type="info-circle" />
                        </template>
                      </standard-table>
                    </a-tab-pane>
                  </a-tabs>
                 </a-col>
              </a-row>
            </a-card>
        </a-col>
    </a-row>
    <editor-quest
      :question="question.obj"
      :visible="question.visible"
      :type="question.type"
      @questPageClose="editorPageColse"
      @submit="doSubmitQuest"
    ></editor-quest>
    <editor-judge
      :question="judge.obj"
      :visible="judge.visible"
      :type="judge.type"
      @questPageClose="editorJudgeColse"
      @submit="submitJudge"
    ></editor-judge>
  </div>
</template>


<script>
import {
  ONLINE_TYPE_QUERY,
  ONLINE_QUERY_COURSE_GETALL,
  ONLINE_QUESTION_CHOOSE_QUERY,
  ONLINE_QUESTION_JUDGE_QUERY,
  ONLINE_QUESTION_JUDGE_UPDATE,
  ONLINE_QUESTION_JUDGE_ADD,
  ONLINE_QUESTION_JUDGE_DELETE,
  ONLINE_QUESTION_CHOOSE_UPDATE,
  ONLINE_QUESTION_CHOOSE_ADD,
  ONLINE_QUESTION_CHOOSE_DELETE,
  ONLINE_QUESTION_CHOOSE_IMPORT,
  ONLINE_QUESTION_JUDGE_IMPORT,
  } from '@/services/api'

import {request} from '@/utils/request'
import StandardTable from '@/components/table/StandardTable'
import EditorQuest from '@/pages/onlineedu/editorquest'
import EditorJudge from '@/pages/onlineedu/editorjudge'
const chooseColumns = [
  { title: '题目', dataIndex: 'questCont',key:'1',ellipsis:true},
  { title: '单选/多选', dataIndex: 'isMany',key:'2', align: 'center',width:120 ,customRender: (text) => {
    if(text==1){
      return "单选";
    }else if(text==2){
      return "多选";
    }else{
      return "--";
    }}},
  { title: '分值', dataIndex: 'core',key:'3' ,width:90, align: 'center'},
  { title: '难易程度', dataIndex: 'level',key:'4' ,width:110, align: 'center',customRender: (text) => {
    if(text==1){
      return "简单";
    }else if(text==2){
      return "一般";
    }else{
      return "困难";
    }}},
  { id:4,title: '操作', dataIndex: '',width:120,key:'5', scopedSlots: { customRender: 'action' }, align: 'center' },
];

const judgeColumns = [
  { title: '题目', dataIndex: 'questCont',key:'1',ellipsis:true},
  { title: '分值', dataIndex: 'core',key:'3' , align: 'center',width:90},
  { title: '难易程度', dataIndex: 'level',key:'4' , align: 'center',width:110,customRender: (text) => {
    if(text==1){
      return "简单";
    }else if(text==2){
      return "一般";
    }else{
      return "困难";
    }}},
  { title: '是否正确', dataIndex: 'isRight',key:'2', align: 'center',width:110,customRender: (text) => {
    if(text==0){
      return "错误";
    }else{
      return "正确";
    } }},
  { id:4,title: '操作', dataIndex: '',key:'5',width:120, scopedSlots: { customRender: 'action' }, align: 'center' },
];

export default {
  name: 'QueryList',
  components: {StandardTable,EditorQuest,EditorJudge},
  // components: {StandardTable},
  data() {
    return {
      chooseColumns,
      judgeColumns,
      selectedRows: [],
      chooseDataSource:[],
      judgeDataSource:[],
      currentTab:1,
      tableLoading: false,
      advanced:false,
      importLoading:false,
      fileListFile:[],
      queryParam:{
        questCont:null,
        level:0,
        isMany:0,
        courseId:null,
        pageNumber:0,
      },
      autoExpandParent: true,
      // 树形结构
      expandedKeys: [],
      treeData: [],
      node:{},
      question:{
        obj:{},
        visible:false,
        type:'1'
      },
      judge:{
        obj:{},
        visible:false,
        type:'2'
      },
      pagination:{
        current:1,
        pageSize:10,
        total:0,
        showLessItems:true,
        showQuickJumper:true
      },
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: "#4A86EB",//滚动条颜色
          opacity: 0.8,//滚动条透明度
          "overflow-x": "hidden"
        }
      },
      tableBtnArr: [
        {
          name: '新增',
          attrs: {
            type: 'primary'
          },
          click: () => this.editorQuest({})
        }
      ]
    };
  },
  created() {
    this.queryTypeWork();
    this.doQuery();
  },
  methods: {
    editorPageColse(){
      this.question.visible=false;
      this.question.obj={}
    },
    editorJudgeColse(){
      this.judge.visible=false;
      this.judge.obj={}
    },
    editorQuest(recode){
      if(this.currentTab==1){
        if(recode&&recode.courseId){
          this.question.obj=recode
        }else{
          if(this.node&&this.node.nodeType==2){
            this.question.obj.courseId=this.node.id
          }else{
            this.question.obj={};
          }
        }
        this.question.visible=true;
      }else{
        if(recode&&recode.courseId){
          this.judge.obj=recode
        }else{
          if(this.node&&this.node.nodeType==2){
            this.judge.obj.courseId=this.node.id
          }else{
            this.judge.obj={};
          }
        }
        this.judge.visible=true;
      }


    },
    changeTab(key){
      this.currentTab = key;
      this.queryParam.questCont=''
      this.queryParam.level=0
      this.queryParam.isMany=0;
      if(this.node&&this.node.nodeType&&this.node.nodeType==2){
        this.queryParam.courseId = this.node.id
      }
      this.question.type = key+"";
      this.doQuery();
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange(value) {
      console.log(`selected ${value}`);
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    doReset(){
      this.queryParam={};
      this.doQuery();
    },

    /**
    * 上传之前
    */
    beforeUpload (file) {
      return new Promise((resolve, reject) => {
        const isFiveM= file.size / 1024 / 1024 < 5 //小于5M的图片上传
        if(!this.queryParam.courseId){
          this.$message.error("请选择左侧的课程导入题库！");
          return reject(false)
        }
        //判断文件是否符合正则表达式的规则
        if (!isFiveM) {
          this.$message.error('上传资源不大于5M！');
          return reject(false)
        }else{
          this.fileListFile=[];
          this.fileListFile.push(file)
          return resolve(true);
        }
      });
    },
    /**
    * 资源上传
    */
    resourceUpload(value){
      this.importLoading = true;
      const formData = new FormData();
      formData.append('file', value.file);
      formData.append('courseId',this.queryParam.courseId)
      let path = ONLINE_QUESTION_CHOOSE_IMPORT;
      if(this.currentTab==2){
        path = ONLINE_QUESTION_JUDGE_IMPORT;
      }
      request(path,"post",formData).then(result => {
        this.importLoading = false;
        if (result.data.code==0) {
          if( result.data.data!=null&& result.data.data.length>0){
            const h = this.$createElement;
            let msg = [];
            result.data.data.forEach(o=>{
                msg.push(h('div',o))
            })

            this.$warning({ title: '成功导入'+result.data.data.total+'个题目，以下题目导入异常：', content:  h('div', {}, msg) });
          }else{
            this.$message.success("成功导入成功！");
          }
          value.onSuccess(result, value.file);
          this.doQuery();
        }else{
          this.$message.error(result.message);
        }
      })
    },
    //分页查询
    onPageChange(e){
      this.queryParam.pageNumber = e.current;
      this.pagination.current=e.current;
      this.doQuery();
    },
    onLoadData(treeNode) {
      return new Promise(resolve => {
        setTimeout(() => {
          request(ONLINE_QUERY_COURSE_GETALL,"post",{typeId:treeNode.dataRef.id}).then(res => {
            if(res.data.code==0){
              treeNode.dataRef.children = [];
              res.data.data.list.forEach(obj=>{
                obj.isLeaf = true;
                obj.title = obj.name;
                obj.key = `${treeNode.eventKey}-${obj.id}`
                obj.nodeType = "2";
                obj.slots={icon: 'courseIcon'}
                treeNode.dataRef.children.push(obj);
              })
            }
            this.treeData = [...this.treeData];
            resolve();
          })
        }, 500);
      });
    },
    onSelect(selectedKeys,e){
      this.tableLoading = true;
      if(e.node&&e.node.dataRef){
        this.node = e.node.dataRef
      }
      if(this.node&&this.node.nodeType==2){
        this.queryParam.courseId = this.node.id
      }
      this.doQuery();
      this.tableLoading = false;
    },
    doQuery(){
      this.tableLoading = true;
      let path = ONLINE_QUESTION_CHOOSE_QUERY;
      if(this.currentTab==1){
        path = ONLINE_QUESTION_CHOOSE_QUERY;
      }else{
        path = ONLINE_QUESTION_JUDGE_QUERY;
      }
      request(path,"post",this.queryParam).then(res => {
        if(res.data.code==0){
          res.data.data.list.forEach(obj=>{
            obj.key = obj.id
          })
          if(this.currentTab==1){
            this.chooseDataSource = res.data.data.list;
          }else{
            this.judgeDataSource = res.data.data.list;
          }
          this.pagination.total = res.data.data.total;
        }
      })
      this.tableLoading = false;
    },
    doSubmitQuest($event){
      let path = $event.quest.id?ONLINE_QUESTION_CHOOSE_UPDATE:ONLINE_QUESTION_CHOOSE_ADD;
      $event.quest.optionsList.forEach(d=>{
        d.isRight = d.isRight?1:0
      })
      request(path,"post",$event.quest).then(res => {
        if(res.data.code==0){
          this.$message.success("保存成功");
          this.doQuery();
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    queryTypeWork(){
      request(ONLINE_TYPE_QUERY,"post",{}).then(res => {
        if(res.data.code==0){
          this.treeData = res.data.data.list;
          this.treeData.forEach(obj=>{
            obj.title = obj.name;
            obj.key = obj.id;
            obj.isLeaf = false;
            obj.nodeType = "1";
            obj.slots={icon: 'typeIcon'}
          })
        }
      })
    },
    deleteQuest(recode){
    var _that = this
    this.$confirm({
        title: '删除选择题',
        content: '您好，您确认删除该选择题吗？',
        okText: '仍旧删除',
        okType: 'danger',
        cancelText: '再想想',
        onOk() {
              request(ONLINE_QUESTION_CHOOSE_DELETE+recode.id, "delete", {}).then((res) => {
              if (res.data.code === 0) {
                _that.$message.success("删除成功！");
                _that.doQuery();
              }else{
                _that.$message.error("删除异常！");
              }
            });
        },
        onCancel() {
            console.log('Cancel');
        },
    });
  },

  deleteJudge(recode){
    var _that = this
    this.$confirm({
        title: '删除判断题',
        content: '您好，您确认删除该判断题吗？',
        okText: '仍旧删除',
        okType: 'danger',
        cancelText: '再想想',
        onOk() {
          request(ONLINE_QUESTION_JUDGE_DELETE+recode.id, "delete", {}).then((res) => {
            if (res.data.code === 0) {
              _that.$message.success("删除成功！");
              _that.doQuery();
            }else{
              _that.$message.error("删除异常！");
            }
          });
        },
        onCancel() {
            console.log('Cancel');
        },
    });
  },

  submitJudge($event){
    let path = $event.quest.id?ONLINE_QUESTION_JUDGE_UPDATE:ONLINE_QUESTION_JUDGE_ADD;
      $event.quest.isRight = $event.quest.isRight?1:0
      request(path,"post",$event.quest).then(res => {
        if(res.data.code==0){
          this.$message.success("保存成功");
          this.doQuery();
        }else{
          this.$message.error(res.data.msg);
        }
      })
  }
  },
}
</script>

<style lang="less" scoped>
  .search{
    margin-bottom: 54px;
  }
  .fold{
    width: calc(100% - 216px);
    display: inline-block
  }
  .operator{
    margin-bottom: 18px;
  }
  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
</style>
