import {GET_PROJECT_CONFIG, UPDATE_PROJECT_CONFIG,GET_PROJECT_ID_CONFIG,SAVE_PROJECT_ID_CONFIG} from '@/services/api'
import {request, METHOD, removeAuthorization} from '@/utils/request'
import {
    exportAttendSheet,
    exportFile, findNoAttach, findNoAttachPersons,
    getAttendSheet,
    getOfficeListByProjectId,
    getPayrollList, getTeamListByOfficeId,
    submitPayrollData
} from "./payroll";
const BASE_URL = process.env.VUE_APP_API_BASE_URL



/**
 *  获取项目配置
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getProjectConfig(params) {
    return request(GET_PROJECT_CONFIG, METHOD.GET, params)
}

/**
 *  更新项目配置
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function updateProjectConfig(params) {
    return request(UPDATE_PROJECT_CONFIG, METHOD.POST, params)
}

/**
 *  根据项目ID获取参数配置
 * @projectId 项目Id
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getProjectIdConfig(params) {
    return request(GET_PROJECT_ID_CONFIG + `/${params.projectId}`, METHOD.GET)
}

/**
 *  保存项目参数配置
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function saveProjectIdConfig(params) {
    return request(SAVE_PROJECT_ID_CONFIG, METHOD.POST, params)
}

/**
 *  执行指定日期
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function setAutoReportAtte(params) {
    return request(BASE_URL + `/labour/jwAtteRecord/autoReportAtte`, METHOD.GET, params)
}

export default {
    getProjectConfig,
    updateProjectConfig
}
