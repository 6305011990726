<template>
    <div>
        <a-card :bordered="false">

            <div id="components-form-demo-advanced-search">
                <a-form class="ant-advanced-search-form" :form="searchForm" >
                    <a-row :gutter="24">
                        <a-col :span="6" >
                            <a-form-item :label="`角色名称`">
                                <a-input v-model="searchForm.name" placeholder="请输入角色名称" />
                            </a-form-item>
                        </a-col>

                        <a-col :span="4" >
                            <a-form-item :label="`状态`">
                                <a-select
                                        v-model="searchForm.status"
                                >
                                    <a-select-option key="-1" value="-1">
                                        全部
                                    </a-select-option>
                                    <a-select-option key="0" value="0">
                                        正常
                                    </a-select-option>
                                    <a-select-option key="1" value="1">
                                        禁用
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>

                        <a-col :span="4" :style="{ textAlign: 'right', marginTop: '3px'}">
                            <a-button type="primary" icon="search" :loading="loading" @click="handleSearch">
                                查询
                            </a-button>
                        </a-col>
                    </a-row>

                    <a-row>
                        <a-button type="primary" @click="add()">
                            新增
                        </a-button>
                    </a-row>

                </a-form>
            </div>
            <a-table
                    :columns="columns"
                    :data-source="data"
            >

                <template slot="action" slot-scope="text, record">
                                <span>
                                  <a @click="showModal(record)">编辑</a>
                                  <a-divider type="vertical" />
                                  <a @click="del(record)">删除</a>
                                </span>
                </template>
            </a-table>
        </a-card>

        <div>
            <a-modal
                    :title="title"
                    :visible="visible"
                    :confirm-loading="confirmLoading"
                    @ok="modelOk"
                    @cancel="modelCancel"
                    width="560px"
            >
                <a-form-model :model="form"
                              :label-col="labelCol"
                              :wrapper-col="wrapperCol"
                              ref="ruleForm"
                              :rules="rules"
                              v-if="visible"
                >
                    <a-form-model-item label="角色名称" ref="roleName" prop="roleName"  required>
                        <a-input v-model="form.roleName" />
                    </a-form-model-item>
                    <a-form-model-item label="权限字符" ref="roleKey" prop="roleKey" required>
                        <a-input v-model="form.roleKey" />
                    </a-form-model-item>
                    <a-form-model-item label="角色级别" ref="roleSort" prop="roleSort"   required>
                        <a-input v-model="form.roleSort" />
                    </a-form-model-item>
                    <a-form-model-item label="状态">
                        <a-select
                                v-model="form.status"
                        >
                            <a-select-option key="0" value="0">
                                正常
                            </a-select-option>
                            <a-select-option key="1" value="1">
                                禁用
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>

                    <a-divider />

                    <a-form-item
                            :labelCol="labelCol"
                            :wrapperCol="wrapperCol"
                            label="拥有权限"
                    >

                        <a-tree
                                checkable
                                v-model="checkedKeys"
                                @check="onCheck"
                                :treeData="permissions"
                        >
                        </a-tree>
                    </a-form-item>

                </a-form-model>
            </a-modal>
        </div>
    </div>



</template>

<script>
    import {getRoleList, getMenuListByRoleList, getMenuListByRoleId, saveRole, delRole} from '@/services/sys'
    import pick from 'lodash.pick'

    const columns = [
        {
            dataIndex: 'roleId',
            key: 'roleId',
            title: '角色编号',
        },
        {
            title: '角色名称',
            dataIndex: 'roleName',
            key: 'roleName',
        },
        {
            title: '权限字符',
            dataIndex: 'roleKey',
            key: 'roleKey',
        },
        {
            title: '显示顺序',
            key: 'roleSort',
            dataIndex: 'roleSort',
        },
        {
            title: '操作',
            key: 'action',
            scopedSlots: { customRender: 'action' },
        },
    ];

    export default {
        data() {
            return {
                // 筛选
                searchForm: {
                    name: "",
                    status: "-1"
                },
                loading: false,

                // 数据表格
                data: [],
                columns,

                // 详细数据弹窗
                title: "编辑",
                visible: false,
                confirmLoading: false,
                labelCol: { span: 6 },
                wrapperCol: { span: 12 },
                form:{
                    status: "0",
                },
                rules: {
                    roleName: [
                        { required: true, message: '请输入角色名', trigger: 'blur' },
                    ],
                    roleKey: [{ required: true, message: '请输入权限字符', trigger: 'blur' }],
                    roleSort: [{ required: true, message: '请输入显示顺序', trigger: 'blur' }],
                },

                // 弹窗里的权限树
                autoExpandParent: true,
                checkedKeys: [],
                permissions: [],
                pidSet: [],

            };
        },

        created() {
            this.getRoleList()
        },
        methods: {
            getRoleList() {
                getRoleList(this.searchForm).then(res => {
                    console.log(res)
                    this.data = res.data.data
                })
            },
            handleSearch(){
                this.getRoleList();
            },
            showModal(record) {
                this.loadPermissions()
                if (record.roleId) {
                    getMenuListByRoleId({id: record.roleId}).then(res => {
                        console.log("nnnnnnnn:", res)

                        const pidSet = new Set(res.data.data.map(m => m.parentId).filter(id => id > 0))
                        this.pidSet = pidSet
                        // 因为antd 树插件勾选父节点会导致所有子节点选中,所以过滤所有父节点
                        this.checkedKeys = res.data.data.map(m => m.menuId).filter(id => !pidSet.has(id))
                    })
                }
                this.mdl = Object.assign({}, record)
                // 如果没有check过，半选节点是拿不到的，只能通过预先设置的pidSet获取
                this.treeCheck = false
                this.visible = true
                // this.$nextTick(() => {
                //     this.form.setFieldsValue(pick(this.mdl, 'roleId', 'roleName', 'status', 'roleSort', 'roleKey'))
                //     // this.form.setFieldsValue(...record)
                // })
                this.form = record;
            },
            modelCancel(e) {
                this.visible = false;
                this.form = {status:"0"}
            },
            add(){
                this.checkedKeys = []
                this.pidSet = []
                this.showModal(this.form)
            },
            modelOk(){
                console.log(this.form)
                if (this.submit()){
                    this.modelCancel()
                }
            },
            submit(){

                const _this = this
                // 如果没有check过，半选节点是拿不到的，只能通过预先设置的pidSet获取
                const checkedAll = this.treeCheck ? _this.checkedKeys.concat(_this.halfCheckedKeys) : _this.checkedKeys.concat(Array.from(_this.pidSet))
                if (!checkedAll.length > 0) {
                    _this.$message.warning('请至少选择一个权限')
                    return false;
                }

                // 触发表单验证
                this.$refs.ruleForm.validate(valid => {
                    if (valid) {
                        this.form.menuIds = checkedAll;
                        this.confirmLoading = true
                        console.log(this.form)
                        saveRole(this.form).then(res => {
                            this.$message.info(res.data.msg);
                            this.getRoleList()
                            this.visible = false;
                            this.confirmLoading = false;
                            return true;
                        })
                    } else {
                        this.$message.error("请检查必填信息");
                        this.confirmLoading = false;
                    }
                })

                return false;

            },


            // 权限树
            loadPermissions () {
                this.permissions = []
                getMenuListByRoleList().then(res => {
                    this.buildtree(res.data.data, this.permissions, 0)
                })
            },
            buildtree (list, permissions, parentId) {
                list.forEach(item => {
                    if (item.parentId === parentId) {
                        var child = {
                            key: item.menuId,
                            title: item.menuName,
                            children: []
                        }
                        this.buildtree(list, child.children, item.menuId)
                        permissions.push(child)
                    }
                })
            },
            onCheck (checkedKeys, info) {
                if (!this.treeCheck) this.treeCheck = true
                console.log('onCheck', info)
                this.checkedKeys = checkedKeys
                this.halfCheckedKeys = info.halfCheckedKeys
            },

            del(record){
                var _that = this
                this.$confirm({
                    title: '确认删除' + record.name + "?",
                    content: '要删除该角色吗？',
                    okText: '删除',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                        delRole({roleId: record.roleId}).then(res => {
                            _that.$message.info(res.data.msg);
                            _that.getRoleList()
                        })
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            },

        }
    };
</script>


<style scoped>
    .ant-advanced-search-form {
        padding: 24px;
        background: #fbfbfb;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        margin-bottom: 10px;
    }

    .ant-advanced-search-form .ant-form-item {
        display: flex;
    }

    .ant-advanced-search-form .ant-form-item-control-wrapper {
        flex: 1;
    }

    #components-form-demo-advanced-search .ant-form {
        max-width: none;
    }

    tr{
        background-color: #ffffff;
    }

</style>
