<template>
  <div class="a_Method">
    <div class="action">
      <a-button type="primary" @click="onContextMenuClick('0', '1')" v-if="showAddNewBtn">新增区域</a-button>
    </div>
    <a-tree :tree-data="treeDataSource" :expandedKeys.sync="expandedKeys" checkable :selected-keys="selectedKeys" @select="onSelect" @check="onCheck">
      <template #title="{ key: treeKey, title }">
        <a-dropdown :trigger="['contextmenu']">
          <div class="flex">
            <a-tooltip placement="topLeft">
            <template slot="title"
              >
              <span>{{ title }}</span>
              </template
            >
            <span style="width: 200px" class="ellipsis-1">{{ title }}</span>
          </a-tooltip>
            <!-- <span style="width: 200px" class="ellipsis-1">{{ title }}</span> -->
            <div class="sTables ml-5">
              <div @click="onContextMenuClick(treeKey, '1')">新增</div>
              <div class="ml-2" @click="onContextMenuClick(treeKey, '2')">编辑</div>
              <div class="ml-2" @click="onContextMenuClick(treeKey, '3')">删除</div>
            </div>
          </div>
          <template #overlay>
            <a-menu @click="({ key: menuKey }) => onContextMenuClick(treeKey, menuKey)">
              <a-menu-item key="1">新增</a-menu-item>
              <a-menu-item key="2">编辑</a-menu-item>
              <a-menu-item key="3">删除</a-menu-item>
            </a-menu> 
          </template>
        </a-dropdown>
      </template>
    </a-tree>
    <!-- 新增区域框 -->
    <a-modal v-model="addNewDialog" :title="modalTitle" :width="600" :body-style="{height:'200px', overflowY: 'auto'}" @ok="handleAddNewSubmit" @cancel="cancel">
      <form-index
        ref="addNewFormIndex"
        :formData="addNewFormData"
      >
      </form-index>
    </a-modal>
  </div>
</template>

<script>
import { getGroupList, addGroup, delGroup, updateGroup } from '@/services/dmgr'
import { treeData, mapTree } from '@/utils/treeutil'
import formIndex from "@/pages/components/form/index";
import { mapGetters } from 'vuex'
const addNewFormData = [
  {
    label: "区域名称",
    placeholder: "请填写",
    decorator: [
      "groupName",
      { rules: [{ required: false, message: "请填写" }] },
    ],
    type: "input",
    key: "groupName",
    col: 24,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
];
export default {
  components: {
    formIndex,
  },
  data() {
    return {
      modalTitle: '新增区域',
      treeDataSource: [],
      selectedKeys: [],
      checkedKeys: [],
      expandedKeys: [],
      originData: [],
      queryData:{
        projectId: "",
      },
      addNewDialog: false,
      addNewFormData: addNewFormData,
      tempId: '',
      showAddNewBtn: false,
      isUpdate: false //判断是编辑还是新增 false:新增 true:编辑
    };
  },

  created() {
    this.getGroupList()
  },
  computed: {
    ...mapGetters('account', ['user']),
  },
  methods: {
    getGroupList() {
      this.queryData.projectId = this.user.projectId
      getGroupList(this.queryData).then(res => {
        if (res.data.code === 0) {
          this.showAddNewBtn = res.data.data.length > 0 ? false : true
          this.originData = res.data.data
          let list = treeData(res.data.data, 'groupId', 'fatherGroupId', 'children', "0");
          this.treeDataSource = list.map(el => mapTree(el))
          // console.log(this.treeDataSource)
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    onContextMenuClick(treeKey, menuKey) {
      console.log(`treeKey: ${treeKey}, menuKey: ${menuKey}`);
      let _that = this
      if (menuKey === '1') {
        this.modalTitle = '新增区域'
        this.addNewDialog = true
        this.tempId = treeKey
        this.isUpdate = false
      } else if (menuKey === '2') {
        this.modalTitle = '编辑区域'
        this.addNewDialog = true
        this.isUpdate = true
        const obj = this.originData.find(el => el.groupId === treeKey)
        this.tempId = obj.id
        this.$nextTick(() => {
          this.$refs['addNewFormIndex'].queryObj(obj)
        })
      } else if (menuKey === '3') {
        const record = this.originData.find(el => el.groupId === treeKey)
        // console.log(record)
        this.$confirm({
          title: '确定删除区域吗？',
          onOk() {
            delGroup(record).then(res => {
              if (res.data.code === 0) {
                _that.$message.success('删除成功！')
                _that.getGroupList()
              } else {
                _that.$message.error(res.data.msg)
              }
            })
          }
        })
      }
    },
    handleAddNewSubmit() {
      this.$refs.addNewFormIndex.combinationSubmit().then(res => {
        if (this.isUpdate) {
          res.id = this.tempId
          updateGroup(res).then(el => {
            if (el.data.code === 0) {
              this.$message.success('编辑成功！')
              this.$refs.addNewFormIndex.resetFields()
              this.addNewDialog = false
              this.tempId = ""
              this.getGroupList()
            } else {
              this.$message.error(el.data.msg)
            }
          })
        } else {
          res.fatherGroupId = this.tempId
          addGroup(res).then(el => {
            if (el.data.code === 0) {
              this.$message.success('新增成功！')
              this.addNewDialog = false
              this.$refs.addNewFormIndex.resetFields()
              this.tempId = ""
              this.getGroupList()
            } else {
              this.$message.error(el.data.msg)
            }
          })
        }
      })
    },
    cancel() {
      this.$refs.addNewFormIndex.resetFields()
    },
    onSelect(selectedKeys, info) {
      console.log('onSelect', info);
      this.selectedKeys = selectedKeys;
    },
    onCheck(checkedKeys) {
      console.log('onCheck', checkedKeys);
      this.checkedKeys = checkedKeys;
    },
  },
};
</script>

<style lang="less" scoped>
.a_Method {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
}
.sTables {
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    width: 100%;
    text-align: center;
    cursor: pointer;
  }

  div:nth-child(1) {
    color: #307dfa;
  }

  div:nth-child(3) {
    color: #ff5c77;
  }

  div:nth-child(2) {
    color: #615e83;
  }
}
</style>