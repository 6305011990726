var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-card",
        { attrs: { title: "职工伤亡事故报表" } },
        [
          _c(
            "a-form-model",
            { attrs: { layout: "horizontal", model: _vm.params } },
            [
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "状态：",
                            labelCol: { span: 3 },
                            wrapperCol: { span: 5 },
                          },
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.statusName))])]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "本企业职工小计（人）：",
                            labelCol: { span: 3 },
                            wrapperCol: { span: 5 },
                          },
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              placeholder: "请输入本企业职工小计（人）",
                              allowClear: "",
                            },
                            model: {
                              value: _vm.params.employeeNums,
                              callback: function ($$v) {
                                _vm.$set(_vm.params, "employeeNums", $$v)
                              },
                              expression: "params.employeeNums",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "本企业临时工（人）：",
                            labelCol: { span: 3 },
                            wrapperCol: { span: 5 },
                          },
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              placeholder: "请输入本企业临时工（人）",
                              allowClear: "",
                            },
                            model: {
                              value: _vm.params.partTimeNums,
                              callback: function ($$v) {
                                _vm.$set(_vm.params, "partTimeNums", $$v)
                              },
                              expression: "params.partTimeNums",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { "wrapper-col": { offset: 12 } } },
                [
                  _c("a-button", { on: { click: _vm.close } }, [
                    _vm._v(" 关闭 "),
                  ]),
                  _c(
                    "a-button",
                    {
                      staticClass: "ml-3",
                      attrs: { type: "primary" },
                      on: { click: _vm.submit },
                    },
                    [_vm._v(" 提交 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }