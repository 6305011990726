var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-card",
        [
          _c(
            "a-spin",
            { attrs: { spinning: _vm.spinning, tip: _vm.loadingText } },
            [
              _c(
                "div",
                { class: _vm.advanced ? "search" : null },
                [
                  _c("a-form", { attrs: { layout: "horizontal" } }, [
                    _c(
                      "div",
                      { class: _vm.advanced ? null : "fold" },
                      [
                        _c(
                          "a-row",
                          [
                            _c(
                              "a-col",
                              { attrs: { md: 12, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "姓名",
                                      labelCol: { span: 5 },
                                      wrapperCol: { span: 18, offset: 1 },
                                    },
                                  },
                                  [
                                    _c("a-input", {
                                      attrs: { placeholder: "请输入" },
                                      model: {
                                        value: _vm.queryParams.userName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParams,
                                            "userName",
                                            $$v
                                          )
                                        },
                                        expression: "queryParams.userName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 12, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "身份证号",
                                      labelCol: { span: 5 },
                                      wrapperCol: { span: 18, offset: 1 },
                                    },
                                  },
                                  [
                                    _c("a-input", {
                                      attrs: { placeholder: "身份证号" },
                                      model: {
                                        value: _vm.queryParams.idCard,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParams,
                                            "idCard",
                                            $$v
                                          )
                                        },
                                        expression: "queryParams.idCard",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "span",
                      { staticStyle: { float: "right", "margin-top": "3px" } },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.doQuery()
                              },
                            },
                          },
                          [_vm._v("查询")]
                        ),
                        _c(
                          "a-button",
                          {
                            staticStyle: { "margin-left": "8px" },
                            on: {
                              click: function ($event) {
                                return _vm.doReset()
                              },
                            },
                          },
                          [_vm._v("重置")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "standard-table",
                    {
                      attrs: {
                        columns: _vm.columns,
                        dataSource: _vm.dataSource,
                        selectedRows: _vm.selectedRows,
                        pagination: _vm.pagination,
                        loading: _vm.tableLoading,
                      },
                      on: {
                        "update:selectedRows": function ($event) {
                          _vm.selectedRows = $event
                        },
                        "update:selected-rows": function ($event) {
                          _vm.selectedRows = $event
                        },
                        clear: _vm.onClear,
                        change: _vm.onPageChange,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "do-hzcs",
                          fn: function (ref) {
                            var text = ref.text
                            return _c("div", {}, [
                              text != null && text != ""
                                ? _c("div", [_vm._v(_vm._s(text) + "次")])
                                : _c(
                                    "div",
                                    {
                                      staticStyle: { color: "red" },
                                      attrs: { type: "close" },
                                    },
                                    [_vm._v("0次")]
                                  ),
                            ])
                          },
                        },
                        {
                          key: "do-status",
                          fn: function (ref) {
                            var text = ref.text
                            return _c("div", {}, [
                              text != null && text != "" && text == 1
                                ? _c("div", [_vm._v("在场")])
                                : _c(
                                    "div",
                                    {
                                      staticStyle: { color: "red" },
                                      attrs: { type: "close" },
                                    },
                                    [_vm._v("离场")]
                                  ),
                            ])
                          },
                        },
                        {
                          key: "do-teamNames",
                          fn: function (ref) {
                            var record = ref.record
                            return _c(
                              "div",
                              {},
                              [
                                record.teamNamesList.length > 0
                                  ? _c(
                                      "a-popover",
                                      {
                                        attrs: {
                                          title: "服务班组清单",
                                          trigger: "hover",
                                        },
                                      },
                                      [
                                        _c(
                                          "template",
                                          { slot: "content" },
                                          _vm._l(
                                            record.teamNamesList,
                                            function (item) {
                                              return _c(
                                                "div",
                                                {
                                                  key: item.id,
                                                  staticStyle: {
                                                    "margin-bottom": "5px",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.teamTagClick(
                                                        "" + item.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "a-tag",
                                                    {
                                                      staticStyle: {
                                                        padding: "5px 10px",
                                                      },
                                                      attrs: { closable: "" },
                                                      on: {
                                                        close: function (
                                                          $event
                                                        ) {
                                                          return _vm.removeTeam(
                                                            item,
                                                            "" + record.idCard
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.teamName)
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                        _c("a", [
                                          _vm._v(
                                            _vm._s(
                                              record.teamNamesList[0].teamName
                                            )
                                          ),
                                        ]),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          },
                        },
                        {
                          key: "do-projectNames",
                          fn: function (ref) {
                            var record = ref.record
                            return _c(
                              "div",
                              {},
                              [
                                record.projectNamesList.length > 0
                                  ? _c(
                                      "a-popover",
                                      {
                                        attrs: {
                                          title: "服务项目清单",
                                          trigger: "hover",
                                        },
                                      },
                                      [
                                        _c(
                                          "template",
                                          { slot: "content" },
                                          _vm._l(
                                            record.projectNamesList,
                                            function (item) {
                                              return _c(
                                                "div",
                                                {
                                                  key: item.id,
                                                  staticStyle: {
                                                    "margin-bottom": "5px",
                                                  },
                                                },
                                                [
                                                  _c("a-tag", [
                                                    _vm._v(
                                                      _vm._s(item.projectName)
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                        _c("a", [
                                          _vm._v(
                                            _vm._s(
                                              record.projectNamesList[0]
                                                .projectName
                                            )
                                          ),
                                        ]),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          },
                        },
                        {
                          key: "do-certNames",
                          fn: function (ref) {
                            var text = ref.text
                            return _c("div", {}, [
                              text != null && text != ""
                                ? _c("div", [_vm._v(_vm._s(text))])
                                : _c(
                                    "div",
                                    {
                                      staticStyle: { color: "red" },
                                      attrs: { type: "close" },
                                    },
                                    [_vm._v("暂无证书")]
                                  ),
                            ])
                          },
                        },
                        {
                          key: "action",
                          fn: function (ref) {
                            var text = ref.text
                            var record = ref.record
                            return _c(
                              "div",
                              {},
                              [
                                _c(
                                  "a",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeTeam(record)
                                      },
                                    },
                                  },
                                  [_vm._v("调班")]
                                ),
                                _c("a-divider", {
                                  attrs: { type: "vertical" },
                                }),
                                _c(
                                  "a",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.updatePerson(record)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        record.enterStatus == 1
                                          ? "离场"
                                          : "在场"
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "template",
                        { slot: "statusTitle" },
                        [
                          _c("a-icon", {
                            attrs: { type: "info-circle" },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.onStatusTitleClick($event)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c("Export-loading", {
                attrs: {
                  cPercent: _vm.cPercent,
                  isExporting: _vm.isExporting,
                  loadingText: _vm.loadingText,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("change-team", {
        attrs: {
          visible: _vm.changeTeamParams.visible,
          idCard: _vm.changeTeamParams.idCard,
          userName: _vm.changeTeamParams.userName,
        },
        on: {
          teamChangeClose: _vm.doCloseTeamChange,
          viewTeamPersons: function ($event) {
            return _vm.teamPersons($event)
          },
          submit: function ($event) {
            return _vm.doSureTeam($event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }