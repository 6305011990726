var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        centered: "",
        destroyOnClose: true,
        width: 1100,
        footer: null,
        maskClosable: false,
        keyboard: false,
      },
      on: { cancel: _vm.cancel },
      model: {
        value: _vm.myVisible,
        callback: function ($$v) {
          _vm.myVisible = $$v
        },
        expression: "myVisible",
      },
    },
    [
      _c("template", { slot: "title" }, [
        _c("h3", { staticClass: "flex a-center j-center font-bold" }, [
          _vm._v("制作工资单"),
        ]),
      ]),
      _c(
        "div",
        [
          _c("h3", { staticClass: "flex font-bold ml-1" }, [
            _vm._v(" " + _vm._s(_vm.planDetail.projectName)),
          ]),
          _c(
            "div",
            { staticClass: "flex a-center j-between m-1" },
            [
              _c(
                "a-steps",
                {
                  staticStyle: { width: "60%" },
                  attrs: { current: _vm.currentStep, size: "small" },
                },
                [
                  _c("a-step", { attrs: { title: "选择班组" } }),
                  _c("a-step", { attrs: { title: "上传考勤表" } }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "flex a-center j-center" },
                [
                  _c("span", { staticClass: "mr-2" }, [_vm._v("班组名称：")]),
                  _c(
                    "a-auto-complete",
                    {
                      staticStyle: { width: "200px" },
                      attrs: {
                        "data-source": _vm.inputDataSource,
                        placeholder: "请输入班组名称",
                      },
                      on: { select: _vm.onSelect, change: _vm.onChange },
                      model: {
                        value: _vm.teamNameInput,
                        callback: function ($$v) {
                          _vm.teamNameInput = $$v
                        },
                        expression: "teamNameInput",
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "dataSource" },
                        _vm._l(_vm.inputDataSource, function (items) {
                          return _c(
                            "a-select-option",
                            {
                              key: items.teamName,
                              attrs: { title: items.teamName },
                            },
                            [_vm._v(_vm._s(items.teamName))]
                          )
                        }),
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          [
            _c(
              "div",
              { staticClass: "content" },
              [
                _vm.currentStep == 0
                  ? [
                      _c("standard-table", {
                        attrs: {
                          columns: _vm.columns0,
                          dataSource: _vm.dataSource0,
                          rowKey: "id",
                          scroll: { x: 900, y: 350 },
                          pagination: _vm.pagination,
                          loading: _vm.tableLoading0,
                          isAlert: false,
                          bordered: true,
                          selectedRows: _vm.selectedRows,
                        },
                        on: {
                          change: _vm.onPageChange,
                          "update:selectedRows": [
                            function ($event) {
                              _vm.selectedRows = $event
                            },
                            function ($event) {
                              return _vm.updateSelectRows($event)
                            },
                          ],
                          "update:selected-rows": function ($event) {
                            _vm.selectedRows = $event
                          },
                          selectedRowChange: _vm.selectedRowChange,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "index",
                              fn: function (ref) {
                                var index = ref.index
                                return [
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          (_vm.pagination.current - 1) *
                                            _vm.pagination.pageSize +
                                            parseInt(index) +
                                            1
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          821120737
                        ),
                      }),
                    ]
                  : _vm._e(),
                _vm.currentStep == 1
                  ? [
                      _c("standard-table", {
                        attrs: {
                          columns: _vm.columns1,
                          dataSource: _vm.dataSource1,
                          rowKey: "id",
                          scroll: { x: 900, y: 350 },
                          pagination: _vm.pagination,
                          loading: _vm.tableLoading1,
                          isAlert: false,
                          bordered: true,
                        },
                        on: { change: _vm.onPageChange },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "index",
                              fn: function (ref) {
                                var index = ref.index
                                return [
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          (_vm.pagination.current - 1) *
                                            _vm.pagination.pageSize +
                                            parseInt(index) +
                                            1
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "attSource",
                              fn: function (ref) {
                                var record = ref.record
                                return [
                                  !record.filePath
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "a-upload",
                                            {
                                              attrs: {
                                                "file-list": record.filePath,
                                                customRequest:
                                                  _vm.resourceUpload,
                                                data: record,
                                              },
                                            },
                                            [
                                              _c(
                                                "a-button",
                                                {
                                                  attrs: {
                                                    type: "primary",
                                                    ghost: "",
                                                    loading:
                                                      record.uploadLoading,
                                                  },
                                                },
                                                [_vm._v("上传excel")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass: "flex a-center j-center",
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(record.teamName) + ".xlsx"
                                            ),
                                          ]),
                                          _c("a-icon", {
                                            staticClass: "ml-3",
                                            attrs: { type: "close" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.delAttSource(record)
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ]
                              },
                            },
                            {
                              key: "action",
                              fn: function (ref) {
                                var record = ref.record
                                return [
                                  _c("div", [
                                    _c(
                                      "a",
                                      {
                                        staticStyle: { color: "#ff5c77" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.del(record)
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1847306106
                        ),
                      }),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ],
          _c(
            "div",
            { staticClass: "btn-box" },
            [
              _vm.currentStep == 0
                ? _c(
                    "a-space",
                    { attrs: { size: 20 } },
                    [
                      _c("a-button", { on: { click: _vm.cancel } }, [
                        _vm._v("取消"),
                      ]),
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", ghost: "" },
                          on: { click: _vm.getAttendModal },
                        },
                        [_vm._v("根据考勤数据制作工资表")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.onImportAttList(false, 1)
                            },
                          },
                        },
                        [_vm._v("导入含应发金额的考勤表")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.currentStep == 1
                ? _c(
                    "a-space",
                    { attrs: { size: 20 } },
                    [
                      _c("a-button", { on: { click: _vm.onBackStep } }, [
                        _vm._v("返回"),
                      ]),
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.onConfirm(1)
                            },
                          },
                        },
                        [_vm._v("确定")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "a-modal",
        {
          attrs: {
            centered: "",
            destroyOnClose: true,
            footer: null,
            maskClosable: false,
            keyboard: false,
          },
          on: {
            cancel: function ($event) {
              _vm.importAttendModal = false
            },
          },
          model: {
            value: _vm.importAttendModal,
            callback: function ($$v) {
              _vm.importAttendModal = $$v
            },
            expression: "importAttendModal",
          },
        },
        [
          _c(
            "div",
            [
              _c("h3", { staticClass: "text-center" }, [
                _vm._v("请选择导入考勤表来源"),
              ]),
              _c("a-divider"),
              _c(
                "div",
                { staticClass: "flex a-center j-center mt-5 mb-5" },
                [
                  _c(
                    "a-button",
                    {
                      staticClass: "mr-5",
                      attrs: {
                        type: "primary",
                        ghost: "",
                        loading: _vm.btnLoading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.getSystemAttendData(2)
                        },
                      },
                    },
                    [_vm._v("从平台获取")]
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        ghost: "",
                        loading: _vm.btnLoading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.getSystemAttendData(3)
                        },
                      },
                    },
                    [_vm._v("从建委获取")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }