import {request, METHOD, removeAuthorization} from '@/utils/request'
import { ADMIN } from '@/services/api.js'

// const BASE_URL = process.env.VUE_APP_API_BASE_URL + '/safe'
const ADMIN_API = `${ADMIN}/safe`

/**
 *  安全巡检计划--查询列表
 */
export async function inspectionPlanList(params) {
    return request(ADMIN_API + `/labourInspectionPlan/getList`, METHOD.POST, params)
}

/**
 *  领导季度带班--查询列表
 */
export async function leaderCheckPlanList(params) {
    return request(ADMIN_API + `/leaderCheckPlan/getList`, METHOD.POST, params)
}

/**
 *  领导季度带班--新增
 */
export async function leaderCheckPlanInsert(params) {
    return request(ADMIN_API + `/leaderCheckPlan/insert`, METHOD.POST, params)
}

/**
 *  领导季度带班--修改
 */
export async function leaderCheckPlanUpdate(params) {
    return request(ADMIN_API + `/leaderCheckPlan/update`, METHOD.POST, params)
}

/**
 *  领导季度带班--删除
 */
export async function leaderCheckPlanDel(id) {
    return request(ADMIN_API + `/leaderCheckPlan/del/${id}`, METHOD.DELETE)
}

/**
 *  领导季度带班--查询详情
 */
export async function leaderCheckPlanDetail(id) {
    return request(ADMIN_API + `/leaderCheckPlan/detail/${id}`, METHOD.GET)
}

/**
 *  领导季度带班--导出
 */
export const leaderCheckPlanExport = ADMIN_API + '/leaderCheckPlan/checkPlanExport'


/**
 *  安全巡检计划--删除
 */
export async function delInspectionPlan(id) {
    return request(ADMIN_API + `/labourInspectionPlan/del/${id}`, METHOD.DELETE)
}

/**
 *  安全巡检计划--新增
 */
export async function saveOrUpdatePlan(params) {
    return request(ADMIN_API + `/labourInspectionPlan/saveOrUpdatePlan`, METHOD.POST, params)
}

/**
 *  安全巡检计划--查询详情
 */
export async function inspectionPlanDetail(id) {
    return request(ADMIN_API + `/labourInspectionPlan/detail/${id}`, METHOD.GET)
}

/**
 *  安全巡检计划--导出
 */
export const inspectionPlanExport = ADMIN_API + '/labourInspectionPlan/inspectionPlanExport'


/**
 *  
 */
export async function projectSourceDangerBack(params) {
    return request(ADMIN_API + `/projectSourceDanger/withdraw?id=${params}`, METHOD.POST)
}

/**
 *  
 */
export async function projectDangerStaticBack(params) {
    return request(ADMIN_API + `/projectSourceDangerStatic/withdraw?id=${params}`, METHOD.POST)
}

/**
 *  
 */
export async function projectSourceDangerDistributionStaticBack(params) {
    return request(ADMIN_API + `/projectSourceDangerDistributionStatic/withdraw?id=${params}`, METHOD.POST)
}

/**
 *  月度项目检查一览表(分页)
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getMonthlyCheckList(params) {
    return request(ADMIN_API + '/aqxjProjects/getMonthlyCheckList', METHOD.POST, params)
}

/**
 *  危险源一览表-查询列表(分页)
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getDangerSourceReportList(params) {
    return request(ADMIN_API + '/projectSourceDanger/getList', METHOD.POST, params)
}

/**
 *  危险源分布查询详情
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getDangerSourceDetail(id) {
    return request(ADMIN_API + `/projectSourceDanger/detail/${id}`, METHOD.GET)
}

/**
 *  危险源一览表-修改
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function updateSourceDanger(params) {
    return request(ADMIN_API + '/projectSourceDanger/update', METHOD.POST, params)
}

/**
 *  安全隐患排查治理统计表(分页)
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getHiddenDangerStaticList(params) {
    return request(ADMIN_API + '/projectSourceDangerStatic/getList', METHOD.POST, params)
}

/**
 *  安全隐患排查治理统计表(分页)-总计部分
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getHiddenDangerStaticTotal(params) {
    return request(ADMIN_API + '/projectSourceDangerStatic/getTotalInfo', METHOD.POST, params)
}

/**
 *  安全隐患排查治理查询详情
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getHiddenDangerDetail(id) {
    return request(ADMIN_API + `/projectSourceDangerStatic/detail/${id}`, METHOD.GET)
}

/**
 *  安全隐患排查治理-修改
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function updateHiddenDanger(params) {
    return request(ADMIN_API + '/projectSourceDangerStatic/update', METHOD.POST, params)
}

/**
 *  危险性较大分部分项工程情况表(分页)
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getWdHiddenDangerStaticList(params) {
    return request(ADMIN_API + '/projectSourceDangerDistributionStatic/getList', METHOD.POST, params)
}

/**
 *  危险性较大分部分项工程情况表-总计部分
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getWdHiddenDangerStaticTotal(params) {
    return request(ADMIN_API + '/projectSourceDangerDistributionStatic/getTotalInfo', METHOD.POST, params)
}

/**
 *  危险性较大分部分项工程情况表查询详情
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getWdHiddenDangerDetail(id) {
    return request(ADMIN_API + `/projectSourceDangerDistributionStatic/detail/${id}`, METHOD.GET)
}

/**
 *  危险性较大分部分项工程情况表-修改
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function updateWdHiddenDanger(params) {
    return request(ADMIN_API + '/projectSourceDangerDistributionStatic/update', METHOD.POST, params)
}

/**
 *  安全隐患排查治理统计表(分页)
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getCasualtyAccidentInfo(params) {
    return request(ADMIN_API + '/casualtyAccidentInfo/getCasualtyAccidentInfo', METHOD.POST, params)
}

/**
 *  职工伤亡事故报表详情
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getCasualtyAccidentInfoDetail(id) {
    return request(ADMIN_API + `/casualtyAccidentInfo/detail/${id}`, METHOD.GET)
}

/**
 *  职工伤亡事故报表-修改
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function updateCasualtyAccidentInfo(params) {
    return request(ADMIN_API + '/casualtyAccidentInfo/update', METHOD.POST, params)
}

/**
 *  月度项目检查一览表-导出
 */
export const monthlyCheckExportUrl = ADMIN_API + '/aqxjProjects/monthlyCheckExport'

/**
 *  危险源一览表-导出
 */
export const dangerSourceExportUrl = ADMIN_API + '/projectSourceDanger/sourceDangerExport'

/**
 *  安全隐患排查治理统计表-导出
 */
export const hiddenDangerStaticExportUrl = ADMIN_API + '/projectSourceDangerStatic/projectSourceDangerStaticExport'

/**
 *  危险性较大分部分项工程情况表-导出
 */
export const wdHiddenDangerStaticExportUrl = ADMIN_API + '/projectSourceDangerDistributionStatic/distributionStaticExport'

/**
 *  伤亡事故信息-导出
 */
export const casualtyAccidentExportUrl = ADMIN_API + '/casualtyAccidentInfo/casualtyAccidentExport'

/**
 *  危险源一览表-分公司确认
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function updateSourceConfirm(params) {
    return request(ADMIN_API + `/projectSourceDanger/confirm`, METHOD.POST, params)
}

/**
 *  危险源一览表-分公司批量确认
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function updateSourceBatchConfirm(params) {
    return request(ADMIN_API + `/projectSourceDanger/confirm`, METHOD.POST, params)
}

/**
 *  月度汇总台账列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getMonthlyReportPage(params) {
    return request(ADMIN_API + `/projectSourceDanger/getMonthlyReportPage`, METHOD.POST, params)
}

/**
 *  危险性较大部分工程详情-分公司确认
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function updateSourceDangerApprove(params) {
    return request(ADMIN_API + `/projectSourceDangerDistributionStatic/approve`, METHOD.POST, params)
}

/**
 *  危险性较大部分工程详情-分公司批量确认
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function updateSourceDangerBatchApprove(params) {
    return request(ADMIN_API + `/projectSourceDangerDistributionStatic/approve`, METHOD.POST, params)
}

/**
 *  获得视频巡检分页
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getVideoCheckList(params) {
    return request(ADMIN_API + `/video-aqxj-plan/page`, METHOD.GET, params)
}

/**
 *  删除视频巡检
 */
export async function delVideoCheckPlan(id) {
    return request(ADMIN_API + `/video-aqxj-plan/delete?id=${id}`, METHOD.DELETE)
}

/**
 *  获得视频巡检
 */
export async function getVideoCheckDetail(id) {
    return request(ADMIN_API + `/video-aqxj-plan/get?id=${id}`, METHOD.GET)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getTreeList() {
    return request(ADMIN_API + `/aqxjProblemsBig/getTreeList`, METHOD.POST, {pageNumber: 1, pageSize: 100})
}

/**
 *  
 */
export async function getDetailByPlanId(id) {
    return request(ADMIN_API + `/video-aqxj-record/getDetailByPlanId?planId=${id}`, METHOD.GET)
}

/**
 *  创建视频巡检对应视频
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function createVideoRecord(params) {
    return request(ADMIN_API + `/video-aqxj-record/create`, METHOD.POST, params)
}

/**
 *  更新视频巡检对应视频
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function updateVideoRecord(params) {
    return request(ADMIN_API + `/video-aqxj-record/update`, METHOD.PUT, params)
}

/**
 *  删除视频巡检对应视频
 */
export async function delVideoRecord(id) {
    return request(ADMIN_API + `/video-aqxj-record/delete?id=${id}`, METHOD.DELETE)
}

/**
 *  删除视频
 */
export async function deleteAttachment(id) {
    return request(`${ADMIN}/labour/attachment/delete/${id}`, METHOD.DELETE)
}

/**
 *  更新视频巡检对应视频
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function updateVideoPlan(params) {
    return request(ADMIN_API + `/video-aqxj-plan/update`, METHOD.PUT, params)
}

/**
 *  创建巡检视频问题记录
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function createVideoProblem(params) {
    return request(ADMIN_API + `/video-aqxj-problem/create`, METHOD.POST, params)
}

/**
 *  根据视频文件id获得巡检视频问题记录
 */
export async function getVideoAqxjProblemByVideoId(id) {
    return request(ADMIN_API + `/video-aqxj-problem/getVideoAqxjProblemByVideoId?videoId=${id}`, METHOD.GET)
}

/**
 *  删除巡检视频问题记录
 */
export async function delVideoProblem(id) {
    return request(ADMIN_API + `/video-aqxj-problem/delete?id=${id}`, METHOD.DELETE)
}

/**
 *  视频巡检创建巡检单
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function createPlanCheck(params) {
    return request(ADMIN_API + `/video-aqxj-plan/createPlanCheck`, METHOD.POST, params)
}

/**
 *  危大工程类别字典
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getWDGC_GCLB() {
    return request(ADMIN + `/system/dict-data/type/WDGC_GCLB`)
}

/**
 *  风险源类型字典
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getRiskSourceType() {
    return request(ADMIN_API + `/thdj/getRiskSourceType`)
}