var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("form-index", {
        ref: "formindex",
        attrs: { formData: _vm.formData, flexSubmit: true },
        on: { handleSubmit: _vm.handleSubmit },
        scopedSlots: _vm._u([
          {
            key: "flexSubmit",
            fn: function () {
              return [
                _c(
                  "a-space",
                  {
                    staticStyle: { "margin-left": "55px", "margin-top": "5px" },
                  },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: {
                          icon: "search",
                          type: "primary",
                          "html-type": "submit",
                        },
                      },
                      [_vm._v(" 查询 ")]
                    ),
                    _c(
                      "a-space",
                      { staticClass: "operator" },
                      [
                        _c("a-button", { on: { click: _vm.close } }, [
                          _vm._v(" 重置 "),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "a_a_footer" },
        [
          _c("standard-table", {
            attrs: {
              columns: _vm.columns,
              dataSource: _vm.dataSource,
              rowKey: "id",
              pagination: _vm.pagination,
              loading: _vm.tableLoading,
              isAlert: false,
              bordered: true,
            },
            on: { change: _vm.onPageChange },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function (ref) {
                  var index = ref.index
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            (_vm.pagination.current - 1) *
                              _vm.pagination.pageSize +
                              parseInt(index) +
                              1
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "status",
                fn: function (ref) {
                  var text = ref.text
                  return [
                    text == 1
                      ? _c("a-tag", { attrs: { color: "green" } }, [
                          _vm._v("补报成功"),
                        ])
                      : _c("a-tag", { attrs: { color: "red" } }, [
                          _vm._v("未补报"),
                        ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }