
<template>
  <div>
    <div v-show="$route.meta.showFather" class="a_Approval">
      <div class="plug-Overview">安全巡检计划</div>
      <a-divider />
      <a-tabs :default-active-key="defaultActiveKey">
        <a-tab-pane key="1" tab="安全巡检计划" forceRender>
          <weekly-inspection-plan></weekly-inspection-plan>
        </a-tab-pane>
        <a-tab-pane key="2" tab="领导季度带班检查" forceRender>
          <leader-inspection-plan></leader-inspection-plan>
        </a-tab-pane>
      </a-tabs>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import LeaderInspectionPlan from './leaderInspectionPlan.vue';
import WeeklyInspectionPlan from './weeklyInspectionPlan.vue';
export default {
  components: {
    WeeklyInspectionPlan,
    LeaderInspectionPlan,
  },
  data() {
    return {
      defaultActiveKey: "1",
    };
  },
  activated() {
    this.defaultActiveKey = '1'
},
  methods: {
  },
};
</script>

<style lang="less" scoped>
.a_Approval {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
}
</style>