var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.modalTitle,
        destroyOnClose: true,
        width: 900,
        footer: null,
        maskClosable: false,
        keyboard: false,
      },
      on: { cancel: _vm.cancel },
      model: {
        value: _vm.myVisible,
        callback: function ($$v) {
          _vm.myVisible = $$v
        },
        expression: "myVisible",
      },
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.spinning } },
        [
          _c(
            "a-radio-group",
            {
              staticStyle: { margin: "8px" },
              attrs: { size: "large", disabled: "" },
              model: {
                value: _vm.tabPosition,
                callback: function ($$v) {
                  _vm.tabPosition = $$v
                },
                expression: "tabPosition",
              },
            },
            [
              _c("a-radio-button", { attrs: { value: 1 } }, [
                _vm._v(" 第一步 "),
              ]),
              _c("a-radio-button", { attrs: { value: 2 } }, [
                _vm._v(" 第二步 "),
              ]),
              _vm.hkAll > 0
                ? _c("a-radio-button", { attrs: { value: 3 } }, [
                    _vm._v(" 第三步 "),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c("h3", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
          _vm.yqAll > 0 && _vm.tabPosition === 3 && _vm.hkAll == 0
            ? _c("h3", { staticClass: "title" }, [
                _vm._v("云启设备无需等待该步骤，关闭即可"),
              ])
            : _vm._e(),
          _vm.tabPosition === 3
            ? _c("h3", { staticClass: "title" }, [
                _vm._v(
                  "若失败人数过多，请前去“系统设置”--“考勤设备设置”进行勾选对应设备重新下发。"
                ),
              ])
            : _vm._e(),
          _vm.tabPosition === 1 || _vm.tabPosition === 2
            ? [
                _vm.hkAll > 0
                  ? _c(
                      "div",
                      { staticClass: "content" },
                      [
                        _c("h4", { staticClass: "sub-title" }, [
                          _vm._v("海康："),
                        ]),
                        _c("a-space", { attrs: { size: 100 } }, [
                          _c("span", [_vm._v("共" + _vm._s(_vm.hkAll) + "人")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.isDel ? "删除" : "下发") + "成功"
                            ),
                            _c("span", { staticClass: "success" }, [
                              _vm._v(_vm._s(_vm.hkSuccess)),
                            ]),
                            _vm._v("人"),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.isDel ? "删除" : "下发") + "失败"
                            ),
                            _c("span", { staticClass: "fail" }, [
                              _vm._v(_vm._s(_vm.hkFail)),
                            ]),
                            _vm._v("人"),
                          ]),
                        ]),
                        _c("a-progress", {
                          attrs: { percent: _vm.hkProgress, strokeWidth: 15 },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.yqAll > 0
                  ? _c(
                      "div",
                      { staticClass: "content" },
                      [
                        _c("h4", { staticClass: "sub-title" }, [
                          _vm._v("云启："),
                        ]),
                        _c("a-space", { attrs: { size: 100 } }, [
                          _c("span", [_vm._v("共" + _vm._s(_vm.yqAll) + "人")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.isDel ? "删除" : "下发") + "成功"
                            ),
                            _c("span", { staticClass: "success" }, [
                              _vm._v(_vm._s(_vm.yqSuccess)),
                            ]),
                            _vm._v("人"),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.isDel ? "删除" : "下发") + "失败"
                            ),
                            _c("span", { staticClass: "fail" }, [
                              _vm._v(_vm._s(_vm.yqFail)),
                            ]),
                            _vm._v("人"),
                          ]),
                        ]),
                        _c("a-progress", {
                          staticClass: "progress",
                          attrs: { percent: _vm.yqProgress, strokeWidth: 15 },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : _vm.tabPosition === 3
            ? [
                _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _c("h4", { staticClass: "sub-title" }, [_vm._v("海康：")]),
                    _c("standard-table", {
                      attrs: {
                        columns: _vm.columns,
                        dataSource: _vm.dataSource,
                        rowKey: "id",
                        scroll: { x: 700 },
                        pagination: _vm.pagination,
                        loading: _vm.tableLoading,
                        isAlert: false,
                        bordered: true,
                      },
                      on: { change: _vm.onPageChange },
                      scopedSlots: _vm._u([
                        {
                          key: "index",
                          fn: function (ref) {
                            var index = ref.index
                            return [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      (_vm.pagination.current - 1) *
                                        _vm.pagination.pageSize +
                                        parseInt(index) +
                                        1
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "result",
                          fn: function (ref) {
                            var record = ref.record
                            return [
                              _c("div", [
                                _vm._v(
                                  "成功" +
                                    _vm._s(record.successedPersonCount) +
                                    "人"
                                ),
                              ]),
                              _c("div", [
                                _vm._v(
                                  "失败" +
                                    _vm._s(record.failedPersonCount) +
                                    "人"
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "downloadPercent",
                          fn: function (ref) {
                            var text = ref.text
                            return [
                              _c(
                                "div",
                                { staticClass: "flex a-center j-center" },
                                [
                                  _c("a-progress", {
                                    staticClass: "progress",
                                    attrs: { percent: text, strokeWidth: 15 },
                                  }),
                                  text != 100
                                    ? _c("a-spin", {
                                        staticStyle: { height: "20px" },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm.yqAll > 0
                      ? _c("p", { staticClass: "yq-tips" }, [
                          _vm._v(
                            "云启：已" +
                              _vm._s(_vm.isDel ? "删除" : "下发") +
                              _vm._s(_vm.yqAll) +
                              "人，成功" +
                              _vm._s(_vm.yqSuccess) +
                              "人，失败" +
                              _vm._s(_vm.yqFail) +
                              "人。"
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            : _vm.tabPosition === 4
            ? [
                _c("div", { staticClass: "content" }, [
                  _vm.hkAll > 0
                    ? _c("div", [
                        _c("h4", { staticClass: "sub-title" }, [
                          _vm._v("海康："),
                        ]),
                        _c("p", [
                          _vm._v(
                            "本次共" +
                              _vm._s(_vm.isDel ? "删除" : "下发") +
                              _vm._s(_vm.hkAll) +
                              "人，" +
                              _vm._s(_vm.isDel ? "删除" : "下发") +
                              "成功" +
                              _vm._s(_vm.hkSuccess) +
                              "人，" +
                              _vm._s(_vm.isDel ? "删除" : "下发") +
                              "失败" +
                              _vm._s(_vm.hkFail) +
                              "人。"
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.yqAll > 0
                    ? _c("div", [
                        _vm.yqAll > 0
                          ? _c("h4", { staticClass: "sub-title" }, [
                              _vm._v("云启："),
                            ])
                          : _vm._e(),
                        _c("p", [
                          _vm._v(
                            "本次共" +
                              _vm._s(_vm.isDel ? "删除" : "下发") +
                              _vm._s(_vm.yqAll) +
                              "人，" +
                              _vm._s(_vm.isDel ? "删除" : "下发") +
                              "成功" +
                              _vm._s(_vm.yqSuccess) +
                              "人，" +
                              _vm._s(_vm.isDel ? "删除" : "下发") +
                              "失败" +
                              _vm._s(_vm.yqFail) +
                              "人。"
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]
            : _vm._e(),
          _vm.hkerrList.length > 0
            ? [
                _c(
                  "div",
                  { staticClass: "err-box" },
                  [
                    _c("span", { staticClass: "font-18" }, [
                      _vm._v("失败原因："),
                    ]),
                    _vm._l(_vm.hkerrList, function (item, index) {
                      return _c("div", { key: index, staticClass: "mt-1" }, [
                        _vm._v(" " + _vm._s(item) + " "),
                      ])
                    }),
                  ],
                  2
                ),
              ]
            : _vm._e(),
          _vm.yqerrList.length > 0
            ? [
                _c(
                  "div",
                  { staticClass: "err-box" },
                  [
                    _c("span", { staticClass: "font-18" }, [
                      _vm._v("失败原因："),
                    ]),
                    _vm._l(_vm.yqerrList, function (item, index) {
                      return _c("div", { key: index, staticClass: "mt-1" }, [
                        _vm._v(" " + _vm._s(item) + " "),
                      ])
                    }),
                  ],
                  2
                ),
              ]
            : _vm._e(),
          _c(
            "div",
            { staticClass: "btn-box" },
            [
              _c(
                "a-space",
                { attrs: { size: 10 } },
                [
                  _vm.showCancelBtn
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: _vm.primary ? "primary" : "" },
                          on: {
                            click: function ($event) {
                              return _vm.cancel("cancelBtn")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.cancelBtnText))]
                      )
                    : _vm._e(),
                  _vm.showConfirmBtn
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: _vm.primary ? "" : "primary",
                            disabled: _vm.HKTask,
                          },
                          on: { click: _vm.nextStepBtn },
                        },
                        [_vm._v(_vm._s(_vm.confirmBtnText))]
                      )
                    : _vm._e(),
                  _vm.showOverBtn
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "primary", disabled: _vm.HKTask },
                          on: {
                            click: function ($event) {
                              return _vm.cancel("cancelBtn")
                            },
                          },
                        },
                        [_vm._v("完成")]
                      )
                    : _vm._e(),
                  _vm.showFailBtn
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.over("cancelBtn")
                            },
                          },
                        },
                        [_vm._v("结束")]
                      )
                    : _vm._e(),
                  _vm.tabPosition == 3 && !_vm.HKTask
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.batchSyncPersonAuthToDevice },
                        },
                        [_vm._v("重新执行该步骤")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }