var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.$route.meta.showFather,
              expression: "$route.meta.showFather",
            },
          ],
          staticClass: "a_Approval",
        },
        [
          _c("div", { staticClass: "plug-Overview" }, [
            _vm._v("项目安全组织架构"),
          ]),
          _c("a-divider"),
          _c(
            "a-form-model",
            {
              staticClass: "clearfix",
              attrs: { model: _vm.params },
              on: { submit: _vm.handleSubmit },
            },
            [
              _vm.user.isGcglb == 0
                ? _c(
                    "a-col",
                    { attrs: { span: 5 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "工程管理部",
                            labelCol: { span: 9 },
                            wrapperCol: { span: 14, offset: 1 },
                          },
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: { allowClear: "", placeholder: "请选择" },
                              model: {
                                value: _vm.params.officeId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.params, "officeId", $$v)
                                },
                                expression: "params.officeId",
                              },
                            },
                            _vm._l(_vm.gcglbList, function (item) {
                              return _c(
                                "a-select-option",
                                {
                                  key: item.value,
                                  attrs: {
                                    value: item.value,
                                    title: item.name,
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "项目名称",
                        labelCol: { span: 8 },
                        wrapperCol: { span: 14, offset: 1 },
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          placeholder: "请输入项目名称",
                          allowClear: "",
                        },
                        model: {
                          value: _vm.params.projectName,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "projectName", $$v)
                          },
                          expression: "params.projectName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "查询人员",
                        labelCol: { span: 8 },
                        wrapperCol: { span: 14, offset: 1 },
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            "show-search": "",
                            allowClear: "",
                            placeholder: "请输入查询人员",
                          },
                          model: {
                            value: _vm.params.userId,
                            callback: function ($$v) {
                              _vm.$set(_vm.params, "userId", $$v)
                            },
                            expression: "params.userId",
                          },
                        },
                        _vm._l(_vm.userSafeList, function (item) {
                          return _c(
                            "a-select-option",
                            {
                              key: item.id,
                              attrs: { value: item.id, title: item.name },
                            },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "a-space",
                    { staticClass: "btnBox" },
                    [
                      _c(
                        "a-button",
                        { attrs: { type: "primary", "html-type": "submit" } },
                        [_vm._v(" 查询 ")]
                      ),
                      _c("a-button", { on: { click: _vm.resetQuery } }, [
                        _vm._v(" 重置 "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("standard-table", {
            attrs: {
              columns: _vm.columns,
              dataSource: _vm.tableData,
              rowKey: "projectId",
              pagination: _vm.pagination,
              loading: _vm.tableLoading,
              isAlert: false,
              bordered: true,
              btnArr: _vm.tableBtnArr,
            },
            on: { change: _vm.handleChangePage },
            scopedSlots: _vm._u(
              [
                {
                  key: "index",
                  fn: function (ref) {
                    var index = ref.index
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            (_vm.pagination.current - 1) *
                              _vm.pagination.pageSize +
                              parseInt(index) +
                              1
                          )
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "manager",
                  fn: function (ref) {
                    var record = ref.record
                    return record.managerList
                      ? _vm._l(record.managerList, function (item) {
                          return _c(
                            "div",
                            { key: item.userId, staticClass: "post-border" },
                            [
                              _c("div", [
                                _vm._v(" " + _vm._s(item.userName) + " "),
                              ]),
                              _c("div", [
                                _vm._v(" " + _vm._s(item.phone) + " "),
                              ]),
                            ]
                          )
                        })
                      : undefined
                  },
                },
                {
                  key: "subManager",
                  fn: function (ref) {
                    var record = ref.record
                    return record.subManagerList
                      ? _vm._l(record.subManagerList, function (item) {
                          return _c(
                            "div",
                            { key: item.userId, staticClass: "post-border" },
                            [
                              _c("div", [
                                _vm._v(" " + _vm._s(item.userName) + " "),
                              ]),
                              _c("div", [
                                _vm._v(" " + _vm._s(item.phone) + " "),
                              ]),
                            ]
                          )
                        })
                      : undefined
                  },
                },
                {
                  key: "tecDuty",
                  fn: function (ref) {
                    var record = ref.record
                    return record.tecDuty
                      ? _vm._l(record.tecDuty, function (item) {
                          return _c(
                            "div",
                            { key: item.userId, staticClass: "post-border" },
                            [
                              _c("div", [
                                _vm._v(" " + _vm._s(item.userName) + " "),
                              ]),
                              _c("div", [
                                _vm._v(" " + _vm._s(item.phone) + " "),
                              ]),
                            ]
                          )
                        })
                      : undefined
                  },
                },
                {
                  key: "safe",
                  fn: function (ref) {
                    var record = ref.record
                    return record.safeList
                      ? _vm._l(record.safeList, function (item) {
                          return _c(
                            "div",
                            { key: item.userId, staticClass: "post-border" },
                            [
                              _c("div", [
                                _vm._v(" " + _vm._s(item.userName) + " "),
                              ]),
                              _c("div", [
                                _vm._v(" " + _vm._s(item.phone) + " "),
                              ]),
                            ]
                          )
                        })
                      : undefined
                  },
                },
                {
                  key: "safeNormalList",
                  fn: function (ref) {
                    var record = ref.record
                    return record.safeNormalList
                      ? _vm._l(record.safeNormalList, function (item) {
                          return _c(
                            "div",
                            { key: item.userId, staticClass: "post-border" },
                            [
                              _c("div", [
                                _vm._v(" " + _vm._s(item.userName) + " "),
                              ]),
                              _c("div", [
                                _vm._v(" " + _vm._s(item.phone) + " "),
                              ]),
                            ]
                          )
                        })
                      : undefined
                  },
                },
                {
                  key: "action",
                  fn: function (ref) {
                    var record = ref.record
                    return [
                      _c(
                        "a",
                        {
                          on: {
                            click: function () {
                              return _vm.handleCheck(record)
                            },
                          },
                        },
                        [_vm._v(" 查看 ")]
                      ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          }),
          _c("Export-loading", {
            attrs: {
              cPercent: _vm.cPercent,
              isExporting: _vm.isExporting,
              loadingText: _vm.loadingText,
            },
          }),
        ],
        1
      ),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }