<template>
  <div class="container">
    <a-card> 
      <div style="background-color:#FFF;padding-top:10px;padding:10px 10px">
        <div :class="advanced ? 'search' : null">
        <a-form layout="horizontal">
          <div :class="advanced ? null: 'fold'">
            <a-row >
              <a-col :md="6" :sm="24" >
                <a-form-item
                  label="班组"
                  :labelCol="{span: 3}"
                  :wrapperCol="{span: 18, offset: 1}"
                >
                  <a-select
                    show-search
                    placeholder="请输入或选择班组"
                    option-filter-prop="children"
                    :dropdownStyle="dropdownStyleSelect"
                    :filter-option="filterOption"
                    @change="handleChange"
                    v-model="queryParams.teamId"
                  >
                    <a-select-option :key="te.id" v-for="te in teamDatas" :value="te.id" :title="te.teamName">
                      {{te.teamName}}
                    </a-select-option>
                  </a-select>
                </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24" >
              <a-form-item
                label="姓名"
                :labelCol="{span: 3}"
                :wrapperCol="{span: 18, offset: 1}"
              >
                <a-input placeholder="请输入" v-model="queryParams.userName"/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24" >
              <a-form-item
                label="入场日期"
                :labelCol="{span: 6}"
                :wrapperCol="{span: 16, offset: 1}"
              >
                <!-- <a-range-picker  @change="onTimeChange" 
                :default-value="[moment(queryParams.enterDateStart, monthFormat), moment(queryParams.enterDateEnd, monthFormat)]"
                v-model="queryParams.inDate"/> -->
                <a-range-picker  @change="onTimeChange" 
                v-model="queryParams.inDate"/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24" >
                <a-form-item
                  label="分配状况"
                  :labelCol="{span: 6}"
                  :wrapperCol="{span: 16, offset: 1}"
                >
                  <a-select
                    v-model="queryParams.inStatus"
                  > 
                    <a-select-option value="99">
                      全部
                    </a-select-option>
                    <a-select-option value="0">
                      未分配
                    </a-select-option>
                    <a-select-option value="1">
                      已分配
                    </a-select-option>
                  </a-select>
                </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
            <a-form-item>
              <a-space :style="{ marginLeft: '5px',marginTop: '4px' }">
                <a-button type="primary" @click="doQuery()">查询</a-button>
                <a-button  @click="doReset()">重置</a-button>
                <a-button type="primary" @click="doAllotRoom()">批量分配宿舍</a-button>
              </a-space>
            </a-form-item>
            </a-col>
          </a-row>
          </div>
          <!-- <span style="float: right; margin-top: -59px;">
            <a-button type="primary" @click="doQuery()">查询</a-button>
            <a-button style="margin-left: 8px" @click="doReset()">重置</a-button>
          </span> -->
        </a-form>
      </div>
        <standard-table
          :columns="columns"
          :dataSource="dataSource"
          :pagination="pagination"
          :loading="tableLoading"
          rowKey="idCard"
          :isAlert="false"
          @clear="onClear"
          @change="onPageChange"
        >
          <div slot="action" slot-scope="{text, record}">
            <a type="setting" v-if='record.isInRoom<=0' @click="roomChange(record)">房间分配</a>
            <a-divider v-if='record.isInRoom<=0' type="vertical" />
            <a style="color:blue" v-if='record.isInRoom>0' @click="bakRoom(record)">退房</a>
            <a-divider v-if='record.isInRoom>0' type="vertical" />
            <a style="color:red" v-if='record.isInRoom>0' @click="roomChange(record)">换房</a>
          </div>
        </standard-table>
      </div>
      <person-allot :visible="allotVisible" @modelCancel="modelCancel" @doAllotRoom="doAllotRoomSubmit"></person-allot>
      <a-modal
        :title="Title"
        :visible="visible"
        @cancel="roomChangeColse"
        :footer="null"
      >
        <p>
            <a-form layout="horizontal">
              <a-row >
                <a-col :md="24" :sm="24" >
                <a-form-item
                  label="宿舍楼"
                  :labelCol="{span: 5}"
                  :wrapperCol="{span: 18, offset: 1}"
                >
                  <a-select
                    show-search
                    placeholder="请输入或选择宿舍楼"
                    option-filter-prop="children"
                    style="width: 200px"
                    :defaultValue="this.queryParams2.buildId"
                    :defaultActiveFirstOption="true"
                    :dropdownStyle="dropdownStyleSelect"
                    :filter-option="filterOption"
                    @change="handleChange2"
                  >
                    <a-select-option :key="te.id" v-for="te in buildData" :value="te.id">
                      {{te.name}}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
          <standard-table
            :columns="columnsRoom"
            :dataSource="dataSource2"
            :pagination="pagination2"
            :loading="tableLoading2"
            rowKey="id"
            :isAlert="false"
            tableSize="small"
            @change="onPageChange2"
          >
            <div slot="action" slot-scope="{text, record}">
                <a-button v-bind:disabled="dis(record)"  type="primary" @click="modelOk(record)">
                  分配
                </a-button>
            </div>
          </standard-table>

        </p>
      </a-modal>
    </a-card>
  </div>
</template>

<script>
import {
  FIND_TEAMS_BY_USER,
  PERSON_QUERY_LIST,
  PERSON_ALLOT_SAVE,
  BUILD_ROOM_QUERYPAGE,
  PERSON_ALLOT_REMOVE,
  BUILD_SIMPLE_QUERYALL
  } from '@/services/api'
import StandardTable from '@/components/table/StandardTable'
import PersonAllot from '@/pages/staffbuildroom/personallotroom'
import {request} from '@/utils/request'
import moment from 'moment';
const columns = [
  {
    title: '姓名',
    dataIndex: 'name',
    align: 'center',
    scopedSlots: { customRender: 'name' }
  },
  {
    title: '身份证号',
    dataIndex: 'idCard',
    align: 'center',
    scopedSlots: { customRender: 'address' },
  },
  {
    title: '所在房间号',
    dataIndex: 'buildRoomName',
    align: 'center',
    scopedSlots: { customRender: 'buildRoomName' },
    customRender: (text) => {
      if(text==""||text=='undefined'||text==undefined||text==null){
        return "未分配";
      }else{
        return text;
      }
    }
  },
  {
    title: '所属班组',
    dataIndex: 'teamName',
    align: 'center',
   scopedSlots: { customRender: 'totalRooms' },
  },
  {
    title: '所属公司',
    dataIndex: 'officeName',
    align: 'center',
   scopedSlots: { customRender: 'officeName' }
  },
  {
    title: '是否已入住',
    dataIndex: 'isInRoom',
    align: 'center',
   scopedSlots: { customRender: 'isInRoom' },
   customRender: (text) => {
     console.log(text)
     if(text=="1"){
       return "是";
     }else{
       return "否";
     }
    }
  },
  {
    title: '操作',
    dataIndex: 'action',
    align: 'center',
   scopedSlots: { customRender: 'action' },
  }
]

const columnsRoom = [
  {
    title: '房间号',
    dataIndex: 'roomNo',
    align: 'center',
    scopedSlots: { customRender: 'roomNo' }
  },
  {
    title: '空余床位',
    dataIndex: 'noPersonNums',
    align: 'center',
   scopedSlots: { customRender: 'noPersonNums' },
   customRender: (text) => {
     if(text){
       return text+"个";
     }else{
       return "0个";
     }
    }
  },
  {
    title: '操作',
    align: 'center',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'QueryList',
  components: {StandardTable,PersonAllot},
  data () {
    return {
      dropdownStyle: {
        zIndex: 999999,
      },
      dropdownStyleSelect:{
        zIndex: 999999,
      },
      columnsRoom,
      dataSource:[],
      dataSource2:[],
      buildData:[],
      teamDatas:[],
      monthFormat: 'YYYY-MM-DD',
      nowMonthDay:null,
      Title:'退房',
      visible:false,
      advanced: false,
      columns: columns,
      selectedRows: [],
      selectedAllRows:[],
      tableLoading: false,
      tableLoading2:false,
      allotVisible:false,
      spinning:false,
      loadingText:"数据加载中...",
      loadKeys:[],
      userInfo:{},
      queryParams:{
        userName:null,
        idCard:null,
        teamId:undefined,
        pageNumber:1,
        enterDateStart:null,
        enterDateEnd:null,
        inDate:null,
        inStatus:'99'
      },
      pagination:{
        current:1,
        pageSize:10,
        total:0,
        showLessItems:true,
        showQuickJumper:true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      queryParams2:{
        buildId:null,
        pageNumber:1,
        isFullPerson:"0"
      },
      pagination2:{
        current:1,
        pageSize:20,
        total:0,
        showLessItems:true,
        showQuickJumper:true
      }
    }
  },
  activated() {
    this.doQueryTeams();
    // this.queryParams.enterDateStart = moment().subtract('days', 6).format('YYYY-MM-DD');
    // this.queryParams.enterDateEnd = moment(new Date()).format('YYYY-MM-DD');
    // this.nowMonthDay = moment(new Date()).format('YYYY-MM-DD');
    this.doQuery();
    this.queryBuilds();
  },
  computed: {
    selectedCurrntRowKeys() {
      return this.selectedRows.map(record => {
        return (typeof this.rowKey === 'function') ? this.rowKey(record) : record["idCard"]
      })
    },
  },
  authorize: {
    deleteRecord: 'delete'
  },
  methods: {
    moment,
    /**
     * 获取所有人员的签名记录信息
     */
    onClear(){

    },
    dis(recode){
      if(recode.noPersonNums==0||recode.noPersonNums=='undefined'){
        return true;
      }else{
        return false;
      }
    },
     deleteRecord(key) {
      this.dataSource = this.dataSource.filter(item => item.key !== key)
      this.selectedRows = this.selectedRows.filter(item => item.key !== key)
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    remove () {
      this.dataSource = this.dataSource.filter(item => this.selectedRows.findIndex(row => row.key === item.key) === -1)
      this.selectedRows = []
    },
    onPageChange(e){
      this.queryParams.pageNumber = e.current;
      this.pagination.current=e.current;
      this.doQuery();
    },
    handleChange() {
      this.doQuery();
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    doQueryTeams(){
      request(FIND_TEAMS_BY_USER,"post",{}).then(res => {
          if(res.data.code==0){
            this.teamDatas = res.data.data;
          }
        })
    },

    doQuery(){
      this.tableLoading = true;
        request(PERSON_QUERY_LIST,"post",this.queryParams).then(res => {
          if(res.data.code==0){
            const reg = /^(.{6})(?:\d+)(.{4})$/    // 匹配身份证号前6位和后4位的正则表达式
            res.data.data.list.forEach(el => {
              if (el.idCard) el.idCard = el.idCard.replace(reg, '$1******$2')
            })
            this.dataSource = res.data.data.list;
            this.pagination.total = res.data.data.total;
          }
          this.tableLoading = false;
        })
    },
    onTimeChange(date, dateString){
      this.queryParams.enterDateStart = dateString[0]
      this.queryParams.enterDateEnd = dateString[1]
    },
    doAllotRoom(){
      this.allotVisible = true;
    },
    modelCancel(){
      this.allotVisible = false;
    },
    doAllotRoomSubmit($event){
      let datas = [];
      $event.userList.forEach(element => {
        let obj = {
          idCard:element.idCard,
          userName:element.name,
          roomId:$event.room.id,
          buildId:$event.room.buildId,
          projectId:$event.room.projectId,
          officeId:$event.room.officeId,
        }
        datas.push(obj)
      });
      request(PERSON_ALLOT_SAVE,"post",datas).then(res => {
        if(res.data.code==0){
         this.$message.success("宿舍分配成功")
         this.doQuery();
         this.modelCancel();
        }else{
          this.$message.error("宿舍分配失败")
        }
      })
      
    },
    modelOk(recode){
      let datas = [];
      let obj = {
        idCard:this.userInfo.idCard,
        userName:this.userInfo.name,
        roomId:recode.id,
        buildId:recode.buildId,
        projectId:recode.projectId,
        officeId:recode.officeId,
      }
      datas.push(obj)
      request(PERSON_ALLOT_SAVE,"post",datas).then(res => {
        if(res.data.code==0){
         this.$message.success("宿舍分配成功")
         this.doQuery();
         this.roomChangeColse();
        }else{
          this.$message.error("宿舍分配失败")
        }
      })
    },
    handleCancel(){
      
    },
    onPageChange2(e){
      this.queryParams2.pageNumber = e.current;
      this.pagination2.current=e.current;
      this.doQueryRooms();
    },
    handleChange2(value){
      this.queryParams2.buildId = value;
      this.doQueryRooms();
    },
    doQueryRooms(){
       this.tableLoading2 = true;
        request(BUILD_ROOM_QUERYPAGE,"post",this.queryParams2).then(res => {
        if(res.data.code==0){
          this.dataSource2 = res.data.data.list;
          this.pagination2.total = res.data.data.total
        }
        this.tableLoading2 = false;
      })
    },
    roomChange(recode){
      this.visible=true;
      this.Title = '房间分配'
      this.userInfo = recode;
      this.doQueryRooms();
    },
    roomChangeColse(){
      this.userInfo = {};
      this.visible=false;
    },
    bakRoom(recode){
      var _that = this;
      let obj = []
      obj.push(recode.idCard);
      this.$confirm({
          title: '人员退房',
          content: '您好，您即将把'+recode.name+'退出房间，是否继续？',
          okText: '确认',
          okType: 'primary',
          cancelText: '取消',
          onOk() {
            request(PERSON_ALLOT_REMOVE,"post",obj).then(res => {
              if(res.data.code==0){
              _that.$message.success("人员退房成功")
              _that.doQuery();
              }else{
                _that.$message.error("人员退房失败")
              }
            })
          },
          onCancel() {
              console.log('Cancel');
          },
      });
    },
    queryBuilds(){
      request(BUILD_SIMPLE_QUERYALL,"post",{}).then(res => {
          if(res.data.code==0){
            this.buildData = res.data.data;
            if(this.buildData!=null&&this.buildData.length>0){
              this.queryParams2.buildId = this.buildData[0].id;
              this.doQueryRooms();
            }
          }
        })
    },
    doReset() {
     this.queryParams={
        userName:null,
        idCard:null,
        teamId:undefined,
        pageNumber:1,
        enterDateStart:null,
        enterDateEnd:null,
        inDate:null,
        inStatus:'99'
      }
      this.pagination.current = 1
      this.doQuery();
    }
  }
}
</script>

<style scoped>
  .gutter-example >>> .ant-row > div {
    background: transparent;
    border: 0;
    margin-top:5px;
  }
  .gutter-box {
    /* background: #00a0e9; */
    padding: 5px 0;
  }
  .ant-calendar-picker-container{
    z-index: 999999 !important;
  }
  
</style>

<style lang="less" scoped>
  /deep/.ant-form-item {
    margin-bottom: 10px;
  }
</style>