var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home_container" },
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c("a-col", { attrs: { span: 16 } }, [
            _c(
              "div",
              { staticClass: "public", staticStyle: { "padding-top": "24px" } },
              [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c("div", { staticClass: "relative" }, [
                      _c("img", {
                        staticClass: "wran-img",
                        attrs: {
                          src: require("../../assets/img/icon-jw-warning.png"),
                          alt: "",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "warning-content white" },
                        [
                          _c("p", { staticClass: "font-bold-700" }, [
                            _vm._v("建委预警"),
                          ]),
                          _c("p", { staticClass: "font-bold-700 font-30" }, [
                            _vm._v(
                              " " + _vm._s(_vm.noCloseWarnList.length) + " "
                            ),
                          ]),
                          _c(
                            "router-link",
                            {
                              staticClass: "white pointer",
                              attrs: { to: { name: "预警列表" } },
                            },
                            [
                              _vm._v(" 查看详情"),
                              _c("a-icon", { attrs: { type: "right" } }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "vue-scroll",
                      {
                        staticClass: "ml-2",
                        staticStyle: { height: "122px" },
                        attrs: { ops: _vm.ops },
                      },
                      [
                        _c("standard-table", {
                          staticClass: "close-table",
                          attrs: {
                            columns: _vm.columns,
                            dataSource: _vm.noCloseWarnList,
                            rowKey: "id",
                            pagination: false,
                            isAlert: false,
                            bordered: false,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "index",
                              fn: function (ref) {
                                var index = ref.index
                                return [
                                  _c("span", [
                                    _vm._v(
                                      " " + _vm._s(parseInt(index) + 1) + " "
                                    ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "bizType",
                              fn: function (ref) {
                                var text = ref.text
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        text == 1 ? "实名制" : "工资分账"
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "eventName",
                              fn: function (ref) {
                                var text = ref.text
                                return [_vm._v(" " + _vm._s(text) + " ")]
                              },
                            },
                            {
                              key: "warmCode",
                              fn: function (ref) {
                                var text = ref.text
                                var record = ref.record
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "statusDrop flex a-center overtext-1",
                                      class: _vm.textStyle(record),
                                    },
                                    [_vm._v(" " + _vm._s(text) + " ")]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "status",
                              fn: function (ref) {
                                var text = ref.text
                                var record = ref.record
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "statusDrop flex a-center overtext-1",
                                      class: _vm.textStyle(record),
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm._f("statusStr")(text)) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "overtext",
                              fn: function (ref) {
                                var text = ref.text
                                return [
                                  _c("span", { staticClass: "overtext-1" }, [
                                    _vm._v(_vm._s(text)),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c("a-col", { attrs: { span: 8 } }, [
            _c(
              "div",
              [
                _c(
                  "a-row",
                  { attrs: { gutter: [0, 16] } },
                  [
                    _c("a-col", [
                      _c("div", { staticClass: "public" }, [
                        _c("div", { staticClass: "pl-1" }, [
                          _c(
                            "div",
                            {
                              staticClass: "black font-16 font-bold overtext-1",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.projectData.fullName
                                      ? _vm.projectData.fullName
                                      : _vm.projectData.projectName
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "flex",
                              staticStyle: {
                                "padding-top": "17px",
                                "padding-bottom": "6px",
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "home-project",
                                attrs: {
                                  src: require("@/assets/img/home-project.png"),
                                  alt: "",
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "flex-1 pl-2",
                                  staticStyle: {
                                    "line-height": "20px",
                                    "font-size": "14px",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex overtext-1" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "black mr-2" },
                                        [_vm._v("项目编码")]
                                      ),
                                      _c("span", { staticClass: "lightgray" }, [
                                        _vm._v(
                                          _vm._s(_vm.projectData.projectNum)
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c("div", { staticClass: "flex py-1" }, [
                                    _c("span", { staticClass: "black mr-2" }, [
                                      _vm._v("负责人"),
                                    ]),
                                    _c("span", { staticClass: "lightgray" }, [
                                      _vm._v(_vm._s(_vm.projectData.userName)),
                                    ]),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "flex" },
                                    [
                                      _c("div", { staticClass: "flex-1" }, [
                                        _c(
                                          "span",
                                          { staticClass: "black mr-2" },
                                          [_vm._v("联系电话")]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "lightgray" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.projectData.userPhone)
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "pointer",
                                          staticStyle: { color: "#0874f0" },
                                          attrs: { to: { name: "项目概况" } },
                                        },
                                        [
                                          _vm._v("查看详情"),
                                          _c("a-icon", {
                                            attrs: { type: "right" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 16 } },
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 0] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "a-card",
                        {
                          attrs: { bordered: false },
                          scopedSlots: _vm._u([
                            {
                              key: "title",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "flex font-16" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "flex-1 black" },
                                        [_vm._v("管理人员数据")]
                                      ),
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "pointer",
                                          staticStyle: { color: "#0874f0" },
                                          attrs: {
                                            to: { name: "管理人员列表" },
                                          },
                                        },
                                        [_vm._v("更多")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _c(
                            "a-row",
                            {
                              attrs: { type: "flex", justify: "space-between" },
                            },
                            [
                              _c("a-col", { staticClass: "ml-35" }, [
                                _c("h4", { staticClass: "black-three" }, [
                                  _vm._v("登记人员"),
                                ]),
                                _c("p", { staticClass: "font-24 black" }, [
                                  _vm._v(
                                    _vm._s(_vm.mangerData.registerNum || 0)
                                  ),
                                ]),
                                _c("div", { staticClass: "py-1" }, [
                                  _c("h4", { staticClass: "black-three" }, [
                                    _vm._v("今日出勤人数"),
                                  ]),
                                  _c("div", { staticClass: "flex" }, [
                                    _c(
                                      "span",
                                      { staticClass: "font-24 black" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.mangerData.todayAtteNum || 0
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c("a-col", { staticClass: "mr-3" }, [
                                _c(
                                  "div",
                                  { staticClass: "flex flex-column a-center" },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/img/icon-line-chart.png"),
                                        alt: "",
                                      },
                                    }),
                                    _c("div", { staticClass: "pt-2" }, [
                                      _c("h4", { staticClass: "black-three" }, [
                                        _vm._v("出勤率"),
                                      ]),
                                      _c(
                                        "p",
                                        { staticClass: "font-24 black" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.mangerData.atteRate
                                                  ? _vm.mangerData.atteRate >
                                                    100
                                                    ? 100
                                                    : _vm.mangerData.atteRate
                                                  : 0
                                              ) +
                                              "% "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "a-card",
                        {
                          attrs: { bordered: false },
                          scopedSlots: _vm._u([
                            {
                              key: "title",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "flex font-16" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "flex-1 black" },
                                        [_vm._v("工人数据")]
                                      ),
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "pointer",
                                          staticStyle: { color: "#0874f0" },
                                          attrs: {
                                            to: { name: "在场工人列表" },
                                          },
                                        },
                                        [_vm._v("更多")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _c(
                            "a-row",
                            {
                              attrs: { type: "flex", justify: "space-between" },
                            },
                            [
                              _c("a-col", { staticClass: "ml-35" }, [
                                _c("h4", { staticClass: "black-three" }, [
                                  _vm._v("累计登记人数"),
                                ]),
                                _c("p", { staticClass: "font-24 black" }, [
                                  _vm._v(
                                    _vm._s(_vm.staffData.registerNum || 0)
                                  ),
                                ]),
                                _c("div", { staticClass: "py-1" }, [
                                  _c("h4", { staticClass: "black-three" }, [
                                    _vm._v("今日出勤人数"),
                                  ]),
                                  _c("div", { staticClass: "flex" }, [
                                    _c(
                                      "span",
                                      { staticClass: "font-24 black" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.staffData.todayAtteNum || 0
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c("a-col", { staticClass: "mr-3" }, [
                                _c("h4", { staticClass: "black-three" }, [
                                  _vm._v("在场人数"),
                                ]),
                                _c("p", { staticClass: "font-24 black" }, [
                                  _vm._v(_vm._s(_vm.staffData.enterNum || 0)),
                                ]),
                                _c("div", { staticClass: "py-1" }, [
                                  _c("h4", { staticClass: "black-three" }, [
                                    _vm._v("出勤率"),
                                  ]),
                                  _c("p", { staticClass: "font-24 black" }, [
                                    _vm._v(
                                      _vm._s(_vm.staffData.atteRate || 0) + "%"
                                    ),
                                  ]),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "a-card",
                        {
                          attrs: { bordered: false },
                          scopedSlots: _vm._u([
                            {
                              key: "title",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "flex font-16" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "flex-1 black" },
                                        [_vm._v("班组数据")]
                                      ),
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "pointer",
                                          staticStyle: { color: "#0874f0" },
                                          attrs: { to: { name: "参建班组" } },
                                        },
                                        [_vm._v("更多")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _c(
                            "a-row",
                            {
                              attrs: { type: "flex", justify: "space-between" },
                            },
                            [
                              _c("a-col", { staticClass: "ml-35" }, [
                                _c("h4", { staticClass: "black-three" }, [
                                  _vm._v("累计登记班组"),
                                ]),
                                _c("p", { staticClass: "font-24 black" }, [
                                  _vm._v(
                                    _vm._s(_vm.teamDataKq.totalTeams || 0)
                                  ),
                                ]),
                                _c("div", { staticClass: "py-1" }, [
                                  _c("h4", { staticClass: "black-three" }, [
                                    _vm._v("今日出勤班组"),
                                  ]),
                                  _c("div", { staticClass: "flex" }, [
                                    _c(
                                      "span",
                                      { staticClass: "font-24 black" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.teamDataKq.todayTeams || 0)
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c("a-col", { staticClass: "mr-3" }, [
                                _c("h4", { staticClass: "black-three" }, [
                                  _vm._v("在场班组"),
                                ]),
                                _c("p", { staticClass: "font-24 black" }, [
                                  _vm._v(
                                    _vm._s(_vm.teamDataKq.onlineTeams || 0)
                                  ),
                                ]),
                                _c("div", { staticClass: "py-1" }, [
                                  _c("h4", { staticClass: "black-three" }, [
                                    _vm._v("出勤率"),
                                  ]),
                                  _c("p", { staticClass: "font-24 black" }, [
                                    _vm._v(_vm._s(_vm.teamDataKq.teamCql || 0)),
                                  ]),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 8 } },
            [
              _c(
                "a-card",
                {
                  attrs: { bordered: false },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "flex font-16" },
                            [
                              _c("span", { staticClass: "flex-1 black" }, [
                                _vm._v("考勤设备"),
                              ]),
                              _c(
                                "router-link",
                                {
                                  staticClass: "pointer",
                                  staticStyle: { color: "#0874f0" },
                                  attrs: { to: { name: "考勤设备设置" } },
                                },
                                [_vm._v("更多")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("div", { staticClass: "w-100" }, [
                    _c(
                      "div",
                      { staticClass: "flex j-around" },
                      [
                        _c("div", { staticClass: "ml-1 mr-5" }, [
                          _c("div", { staticClass: "flex flex-column" }, [
                            _c("h4", { staticClass: "black-three" }, [
                              _vm._v("考勤设备数"),
                            ]),
                            _c("p", { staticClass: "black font-24" }, [
                              _vm._v(_vm._s(_vm.deviceData.deviceNum || 0)),
                            ]),
                          ]),
                          _c("div", { staticClass: "flex flex-column pt-1" }, [
                            _c("h4", { staticClass: "black-three" }, [
                              _vm._v("离线设备"),
                            ]),
                            _c("p", { staticClass: "black font-24" }, [
                              _vm._v(
                                _vm._s(_vm.deviceData.offlineDeviceNum || 0)
                              ),
                            ]),
                          ]),
                        ]),
                        _c(
                          "vue-scroll",
                          {
                            staticClass: "flex-1",
                            staticStyle: { height: "152px" },
                            attrs: { ops: _vm.ops },
                          },
                          [
                            _c("standard-table", {
                              staticClass: "close-table",
                              attrs: {
                                columns: _vm.deviceColumns,
                                dataSource:
                                  _vm.deviceData.attendanceDeviceVoList,
                                rowKey: "id",
                                pagination: false,
                                isAlert: false,
                                bordered: false,
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "index",
                                  fn: function (ref) {
                                    var index = ref.index
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(parseInt(index) + 1) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                                {
                                  key: "online",
                                  fn: function (ref) {
                                    var text = ref.text
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(text ? text : "在线") +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 16 } },
            [
              _c(
                "a-card",
                {
                  attrs: { bordered: false },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "pointer font-16",
                              class:
                                _vm.activedKey === 2 ? "black" : "black-three",
                              on: {
                                click: function ($event) {
                                  return _vm.handleChangeTitle(2)
                                },
                              },
                            },
                            [_vm._v("近7日出勤数据")]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("div", {
                    ref: "sevenDaysChart",
                    staticStyle: { width: "100%", height: "263px" },
                    attrs: { id: "main" },
                  }),
                ]
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 8 } },
            [
              _c(
                "a-card",
                {
                  attrs: { bordered: false },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("span", { staticClass: "flex-1 black font-16" }, [
                            _vm._v("班组考勤情况"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _vm.teamRankList && _vm.teamRankList.length > 0
                    ? _c(
                        "vue-scroll",
                        {
                          staticClass: "tb-box-2",
                          staticStyle: { height: "220px" },
                          attrs: { ops: _vm.ops },
                        },
                        _vm._l(_vm.teamRankList, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "mt-2 px-1" },
                            [
                              _c("div", { staticClass: "flex j-between" }, [
                                _c("span", [_vm._v(_vm._s(item.teamName))]),
                                _c("span", [_vm._v(_vm._s(item.cql) + "%")]),
                              ]),
                              _c("a-progress", {
                                attrs: {
                                  percent: item.cql,
                                  "show-info": false,
                                  strokeWidth: 12,
                                  strokeColor: _vm.getProgressColor(item.cql),
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : [
                        _c("a-empty", {
                          staticClass: "flex j-center flex-column",
                          staticStyle: { height: "220px" },
                        }),
                      ],
                  _c("div", { staticClass: "mt-2 flex j-between px-2" }, [
                    _c("span", { staticStyle: { color: "#615e83" } }, [
                      _vm._v("0"),
                    ]),
                    _c("span", { staticStyle: { color: "#e5eafc" } }, [
                      _vm._v("25%"),
                    ]),
                    _c("span", { staticStyle: { color: "#a9e9fe" } }, [
                      _vm._v("50%"),
                    ]),
                    _c("span", { staticStyle: { color: "#3aa5ff" } }, [
                      _vm._v("75%"),
                    ]),
                    _c("span", { staticStyle: { color: "#4a3aff" } }, [
                      _vm._v("100%"),
                    ]),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            [
              _c(
                "a-card",
                {
                  attrs: { bordered: false },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "flex font-16" },
                            [
                              _c("span", { staticClass: "flex-1 black" }, [
                                _vm._v("工资发放数据"),
                              ]),
                              _c(
                                "router-link",
                                {
                                  staticClass: "pointer",
                                  staticStyle: { color: "#0874f0" },
                                  attrs: { to: { name: "项目工资申请" } },
                                },
                                [_vm._v("明细查询")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "a-row",
                    {
                      staticClass: "mt-2 mb-1",
                      attrs: { type: "flex", justify: "space-around" },
                    },
                    _vm._l(_vm.payrollData, function (item, index) {
                      return _c(
                        "a-col",
                        { key: index, staticClass: "payroll-card" },
                        [
                          _c(
                            "div",
                            { staticClass: "flex j-around a-center h-100" },
                            [
                              _c("img", {
                                staticStyle: { width: "42px", height: "42px" },
                                attrs: { src: item.img, alt: "" },
                              }),
                              _c("div", { staticClass: "mt-2" }, [
                                _c("h4", { staticClass: "black-three" }, [
                                  _vm._v(_vm._s(item.title)),
                                ]),
                                _c(
                                  "h4",
                                  { staticClass: "black-three font-24" },
                                  [_vm._v(_vm._s(item.value))]
                                ),
                              ]),
                            ]
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }