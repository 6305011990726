var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-container" },
    [
      _c("form-index", {
        ref: "formindex",
        attrs: {
          formData: _vm.formData,
          Overview: "参建班组详情",
          bottomSubmit: true,
        },
        on: { handleSubmit: _vm.handleSubmit, handlSelect: _vm.handlSelect },
        scopedSlots: _vm._u([
          {
            key: "bottomSubmit",
            fn: function () {
              return [
                _c(
                  "a-space",
                  [
                    _c(
                      "a-button",
                      {
                        attrs: {
                          disabled: Boolean(_vm.query.look),
                          type: "primary",
                          "html-type": "submit",
                        },
                      },
                      [_vm._v(" 保存 ")]
                    ),
                    _c("a-button", { on: { click: _vm.close } }, [
                      _vm._v(" 关闭 "),
                    ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }