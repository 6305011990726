var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "constructiontTable_index" }, [
      _c("div", { staticClass: "flex" }, [
        _c(
          "div",
          { staticClass: "header" },
          [
            [
              _c(
                "div",
                { staticClass: "flex a-center" },
                _vm._l(_vm.headerSelectList, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "flex a-center j-center" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex a-center j-center pointer item-box",
                          class: item.activated ? "activated" : "",
                          on: {
                            click: function ($event) {
                              return _vm.changeStatus(index)
                            },
                          },
                        },
                        [
                          _c("span", [_vm._v(_vm._s(item.name))]),
                          _c("span", [_vm._v("（" + _vm._s(item.num) + "）")]),
                        ]
                      ),
                      _vm.shouldShowDivider(index)
                        ? _c("div", { staticClass: "divider" })
                        : _vm._e(),
                    ]
                  )
                }),
                0
              ),
            ],
          ],
          2
        ),
      ]),
      _c(
        "div",
        [_c("salary-list", { attrs: { salaryStatus: _vm.salaryStatus } })],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }