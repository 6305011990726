<template>
  <a-card>
    <form-index
        ref="formindex"
        :formData="formData"
        :flexSubmit="true"
        @handleSubmit="handleSubmit"
      >
        <!-- 下部分按钮插槽 -->
        <template #flexSubmit>
          <a-space style="margin-top: 3px">
            <a-button icon="search" type="primary" html-type="submit"> 查询 </a-button>
            <a-space class="operator">
              <a-button  @click="close"> 重置 </a-button>
            </a-space>
          </a-space>
        </template>
    </form-index>

    <standard-table
        :columns="columns"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="tableLoading"
        @change="onPageChange"
        :bordered="true"
        :isAlert="false"
      >
        <template slot="index" slot-scope="{ index }">
          <span>
            {{
              (pagination.current - 1) * pagination.pageSize +
              parseInt(index) +
              1
            }}
          </span>
        </template>
        <template slot="reason" slot-scope="{ text }">
          <span>{{ text.indexOf('户籍') != -1 ? '自定义风控规则' : text }}</span>
        </template>
      </standard-table>
  </a-card>
</template>

<script>
import { useRender } from '@/hooks/useRender.js'
const formData = [
  {
    label: "姓名",
    placeholder: "请输入姓名",
    decorator: [
      "userName",
      { rules: [{ required: false, message: "请输入姓名" }] },
    ],
    type: "input",
    key: "userName",
    // dataSource: [],
    col: 4,
    labelCol: 4,
    wrapperCol: 18,
  },
  {
    label: "身份证号",
    placeholder: "请输入身份证号",
    decorator: [
      "idCard",
      { rules: [{ required: false, message: "请输入身份证号" }] },
    ],
    type: "input",
    key: "idCard",
    col: 5,
    labelCol: 6,
    wrapperCol: 16,
  }
];
const columns = [
{
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    align: "center",
  },
  {
    title: '姓名',
    dataIndex: 'userName',
    align: 'center',
  },
  {
    title: '身份证号',
    dataIndex: 'idCard',
    align: 'center',
  },
  {
    title: '项目名称',
    dataIndex: 'projectNum',
    align: 'center',
    scopedSlots: { customRender: 'projectNum' }
  },
  // {
  //   title: '所属公司',
  //   dataIndex: 'officeName',
  //   align: 'center',
  //   scopedSlots: { customRender: 'officeName' }
  // },
  {
    title: '拦截日期',
    dataIndex: 'filterTime',
    align: 'center',
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: '拦截原因',
    dataIndex: 'reason',
    align: 'center',
    scopedSlots: {customRender: 'reason'}
  }
]
import StandardTable from '@/components/table/StandardTable'
import formIndex from "@/pages/components/form/index";
import {
  getStaffFkList,
} from "@/services/participationUnit";
export default {
  name: 'SuijianClientRiskPersonnel',
  components: {
    StandardTable,
    formIndex
  },
  data() {
    return {
      columns,
      formData,
      dataSource:[],
      tableLoading: false,
      pagination:{
        current:1,
        pageSize:10,
        total:0,
        showTotal: (total, range) => `共 ${total} 条`
      },
      prams: {
        pageNumber: 1,
        pageSize: 10,
      },
    };
  },

  mounted() {
    this.aqxjlist(this.prams);
  },

  methods: {
    // 提交表单数据
    handleSubmit(e) {
      this.prams.pageNumber = 1 // 点击查询按钮 从第一页开始
      this.pagination.current = 1
      let obj = Object.assign({}, e, this.prams);
      this.aqxjlist(obj);
    },
     // 重置
     close() {
      this.prams = {
        pageNumber: 1,
        pageSize: 10,
      };
      this.pagination.current = 1
      this.aqxjlist(this.prams);
      this.$refs["formindex"].resetFields();
    },
    aqxjlist(prams) {
      this.tableLoading = true;
      getStaffFkList(prams).then((res) => {
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
        }
        this.tableLoading = false;
      });
    },
     //表格 - 分页查询
     onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      this.prams.pageNumber = e.current;
      this.pagination.current = e.current;
      this.aqxjlist(Object.assign({}, this.prams,obj));
    },
  },
};
</script>

<style lang="less" scoped>
.plug-form .ant-form .ant-btn {
  // 处理表单组件强制margin-right问题
  margin-right: 0 !important;
}
</style>
