import echarts from "echarts"
export function sevenKqChart (name,num) {
    // 基于准备好的dom，初始化echarts实例
    let sevenKqChart = echarts.init(document.getElementById("sevenKqChart"));
    // 指定图表的配置项和数据
    let option = {
        title: {
            text: '最近工人7天出勤曲线图',
            textStyle:{
                color:'#333',
                fontStyle:'normal',
                //字体大小
                fontSize:16
            }
        },
        tooltip: {
            trigger: 'axis'
        },
        color: ['#4982EA','#5CB5BF'],
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: name
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                name: '考勤数',
                type: 'line',
                stack: 'Total',
                lineStyle: {
                    color: '#4982EA'
                },
                data: num
            }
        ]
    }
    // 使用刚指定的配置项和数据显示图表。
    sevenKqChart.setOption(option)
}
/**
 * 安全管理扇形图
 * */
export function safeChart (obj) {
    let safeChart = echarts.init(document.getElementById("safeChart"))
    let option = {
        title: {
            text: '本月安全管理统计汇总',
            left: 'left',
            textStyle:{
                color:'#333',
                fontStyle:'normal',
                fontSize:16
            }
        },
        tooltip: {
            trigger: 'item'
        },
        color:['#68BBC4', '#5087EC'],
        legend: {
            orient: 'vertical',
            left: 'right'
        },
        series: [
            {
                name: '本月安全管理',
                type: 'pie',
                radius: '70%',
                center: ["35%", "58%"],
                data: [
                    { value: obj.wzg, name: '待整改' },
                    { value: obj.yzg, name: '已整改' },
                ],
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    }
    safeChart.setOption(option)
}

export function dangerTypeChart(name,num) {
    let dangerTypeChart = echarts.init(document.getElementById("dangerTypeChart"))
    let option = {
        title: {
            text: '隐患分类汇总',
            left: 'left',
            textStyle:{
                color:'#333',
                fontStyle:'normal',
                fontSize:16
            }
        },
        color:['#5087EC'],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        xAxis: {
            type: 'category',
            data: name,
            axisLabel: {
                //  让x轴文字方向为竖向
                interval: 0,
                rotate:40 ,
                //坐标轴刻度标签的相关设置。
                formatter : function(params){
                    let newParamsName = ""// 最终拼接成的字符串
                    let paramsNameNumber = params.length// 实际标签的个数
                    let provideNumber = 4;// 每行能显示的字的个数
                    let rowNumber = Math.ceil(paramsNameNumber / provideNumber);// 换行的话，需要显示几行，向上取整
                    /**
                     * 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
                     */
                    // 条件等同于rowNumber>1
                    if (paramsNameNumber > provideNumber) {
                        /** 循环每一行,p表示行 */
                        for (let p = 0; p < rowNumber; p++) {
                            let tempStr = "";// 表示每一次截取的字符串
                            let start = p * provideNumber;// 开始截取的位置
                            let end = start + provideNumber;// 结束截取的位置
                            // 此处特殊处理最后一行的索引值
                            if (p == rowNumber - 1) {
                                // 最后一次不换行
                                tempStr = params.substring(start, paramsNameNumber);
                            } else {
                                // 每一次拼接字符串并换行
                                tempStr = params.substring(start, end) + "\n";
                            }
                            newParamsName += tempStr;// 最终拼成的字符串
                        }

                    } else {
                        // 将旧标签的值赋给新标签
                        newParamsName = params;
                    }
                    //将最终的字符串返回
                    return newParamsName
                }
            }
        },
        yAxis: {
            type: 'value'
        },
        grid: {
            left: '8%',
            right: '4%',
            bottom: '20%'
        },
        series: [
            {
                data: num,
                barWidth: 20,
                type: 'bar'
            }
        ]
    }
    dangerTypeChart.setOption(option)
}
export function ageTypeChart(arr) {
    let ageTypeChart = echarts.init(document.getElementById("ageTypeChart"))
    let option = {
        title: {
            text: '劳务人员工种结构',
            left: 'left',
            textStyle:{
                color:'#333',
                fontStyle:'normal',
                fontSize:16
            }
        },
        tooltip: {
            trigger: 'item'
        },
        legend: {
            bottom: 10,
            left: 'center',
        },
        color: ['#B6A2DE','#8D98B3','#5AB1EF','#FFB980','#D87A80','#2EC7C9'],
        series: [
            {
                name: '劳工年龄结构',
                type: 'pie',
                radius: '60%',
                center: ["45%", "48%"],
                data: arr,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    }
    ageTypeChart.setOption(option)
}
export function workTypeChart(arr) {
    let workTypeChart = echarts.init(document.getElementById("workTypeChart"))
    let option = {
        title: {
            text: '劳务人员工种结构',
            left: 'left',
            textStyle:{
                color:'#333',
                fontStyle:'normal',
                fontSize:16
            }
        },
        tooltip: {
            trigger: 'item'
        },
        legend: {
            bottom: 10,
            left: 'center',
        },
        color: ['#2EC7C9','#B6A2DE','#5AB1EF','#FFB980','#D87A80','#8D98B3'],
        series: [
            {
                name: '劳务人员工种结构',
                type: 'pie',
                radius: '50%',
                center: ["45%", "42%"],
                data: arr,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    }
    workTypeChart.setOption(option)
}
