<template>
  <div class="a_Method">
    <div v-show="$route.meta.showFather" class="a_m_header">
      <a-tabs default-active-key="1" @change="callback">
        <a-tab-pane key="1" tab="实名制通道考勤">
          <passageway-attendance></passageway-attendance>
        </a-tab-pane>
        <a-tab-pane v-if="user.loginType == 0" key="2" tab="小程序移动考勤" force-render >
          <attendanceSetting></attendanceSetting>
        </a-tab-pane>
      </a-tabs>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import PassagewayAttendance from "./passagewayAttendance";
import attendanceSetting from '@/pages/attendance/setting'
import {mapGetters} from 'vuex'
export default {
  data() {
    return {};
  },
  components: {
    PassagewayAttendance,
    attendanceSetting
  },
  computed: {
      ...mapGetters('account', ['user']),
    },
  methods: {
		callback(key) {
      console.log(key);
    },
	},
};
</script>

<style lang="less" scoped>
.a_Method {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
}
</style>
