<template>
  <div class="a_Approval">
    <div class="plug-Overview">安全技术交底配置</div>
    <a-divider />
    <a-spin :spinning="spinning" :tip="loadingText">
    <!-- <label v-if="obj.isZongLaowu==0">温馨提示：技术交底参数设置暂只允许项目总包劳务员才能设置，如需更改设置，请联系总包劳务员更改设置，非常感谢！</label> -->
      <a-form :form="form" layout="horizontal" @submit="doSave">

        <a-card title="通用技术交底模板&课程设置：">
          <a-row :gutter="24">
            <a-col :span="4" >
              <a-form-item
                label="技术交底手动发起"
                :labelCol="{span: 17}"
                :wrapperCol="{span: 3, offset: 1}"
              >
                <a-switch  v-model="obj.params.jsjd"  />
              </a-form-item>
            </a-col>
            <a-col :span="5">
              <a-form-item
                label="启用技术交底视频学习"
                :labelCol="{span: 17}"
                :wrapperCol="{span: 3}"
              >
                <a-switch v-model="obj.params.isOpenVideo"  />
              </a-form-item>
            </a-col>
            <a-col :span="6" >
              <a-form-item
              label="交底模板"
              :labelCol="{span: 6}"
              :wrapperCol="{span: 16, offset: 1}"
              >
                <a-select
                  show-search
                  placeholder="请选择或输入模板"
                  option-filter-prop="children"
                  style="width: 200px"
                  :filter-option="filterOption"
                  @change="handleChange"
                  v-model="obj.params.commonSet.templId"
                >
                  <a-select-option :key="item.id" v-for="item in jsjdTemplData" :value="item.id">
                    {{item.title}}
                  </a-select-option>
                </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6" >
            <a-form-item
              label="教育课程"
              :labelCol="{span: 6}"
              :wrapperCol="{span: 16, offset: 1}"
            >
              <a-select
                show-search
                placeholder="请输入或选择课程"
                option-filter-prop="children"
                style="width: 200px"
                :filter-option="filterOption"
                @change="handleChange"
                v-model="obj.params.commonSet.courseId"
              >
                <a-select-option :key="item.id" v-for="item in courseData" :value="item.id">
                  {{item.name}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        </a-card>
        <a-card title="工种技术交底模板&视频课程设置：">
          <a-row v-for="(courseSet,index) in obj.params.jobTitleSet" :key="index" :gutter="24">
            <a-col :span="5" >
              <a-form-item
              label="工种"
              :labelCol="{span: 6}"
              :wrapperCol="{span: 16, offset: 1}"
              >
                <a-select
                mode="multiple"
                  show-search
                  placeholder="请输入或选择工种"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  @change="handleChange"
                  v-model="courseSet.titleId"
                >
                  <a-select-option :key="item.id" v-for="item in jobTitles" :value="item.id">
                    {{item.carft}}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="5" >
              <a-form-item
              label="交底模板"
              :labelCol="{span: 7}"
              :wrapperCol="{span: 16, offset: 1}"
              >
                <a-select
                  show-search
                  placeholder="请输入或选择模板"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  @change="handleChange"
                  v-model="courseSet.templId"
                >
                  <a-select-option :key="item.id" v-for="item in jsjdTemplData" :value="item.id">
                    {{item.title}}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="5" >
              <a-form-item
              label="教育课程"
              :labelCol="{span: 7}"
              :wrapperCol="{span: 16, offset: 1}"
              >
                <a-select
                  show-search
                  placeholder="请输入或选择课程"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  @change="handleChange"
                  v-model="courseSet.courseId"
                >
                  <a-select-option :key="item.id" v-for="item in courseData" :value="item.id">
                    {{item.name}}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="4">
              <a-form-item>
                <a-icon class="imdexStyle" type="plus-circle" @click="addTitleSet"/>
                <a-icon class="imdexStyle" type="minus-circle" @click="removeTitleSet(index)"/>
              </a-form-item>
            </a-col>
          </a-row>
        </a-card>

      <a-form-item>
          <a-button class="mt-2" icon="save" type="primary" :disabled="obj.isZongLaowu==0&&!isYYZY" html-type="submit">
              确认设置
          </a-button>
      </a-form-item>
      </a-form>

    </a-spin>
  </div>
</template>

<script>
import {
  PROJECT_JSJD_SETTING_FIND,
  PROJECT_JSJD_SAVE,
  ONLINE_COURSE_QUERY_ALL,
  QUERY_JOBTITLE_ALL,
  MYJSJD_WORDLIST,
  } from '@/services/api'
  import {mapGetters,mapMutations} from 'vuex'
import {request} from '@/utils/request'
// import axios from '@/utils/axios'

export default {
  name: 'jsjd_setting',
  components: {},
  data () {
    return {
      jobTitles:[],
      obj:{
        id:null,
        isZongLaowu:0,
        params:{
          isOpenVideo:false,
          jsjd:false,  //0：手动发起，1：自动发起
          commonSet:{
            templId:null,
            courseId:null
          },
          jobTitleSet:[{titleId:[],templId:null,courseId:null}],
        },
      },
      courseData:[],
      jsjdTemplData:[],
      spinning:false,
      isYYZY:false,
      loadingText:"数据加载中..."
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'validate_other' });
  },
  computed: {
    ...mapGetters('account', ['user']),
  },
  created() {
    this.doQuery();
    this.doQueryCourse();
    this.doQueryJobTitles();
    this.doQueryJsjdTemp();
    // console.log(this.user);
    let nowRoleKey = this.user.nowRoleKey;
    if(nowRoleKey&&nowRoleKey.length>0&&(nowRoleKey.indexOf('PLATFORM_YYZY')>-1||nowRoleKey.indexOf('OFFICE_MGR'))>-1){
      this.isYYZY = true
    }else{
      this.isYYZY = false
    }
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange(){

    },
    doQueryJsjdTemp(){
      request(MYJSJD_WORDLIST,"get",{}).then(res => {
          if(res.data.code==0){
            this.jsjdTemplData = res.data.data;
          }
        })
    },
    doQueryCourse(){
      request(ONLINE_COURSE_QUERY_ALL,"post",{}).then(res => {
          if(res.data.code==0){
            this.courseData = res.data.data;
          }
        })
    },
    doQueryJobTitles(){
      request(QUERY_JOBTITLE_ALL,"get",{}).then(res => {
          if(res.data.code==0){
            this.jobTitles = res.data.data;
          }
        })
    },
    /**
     * 获取项目技术交底主表配置信息
     */
    doQuery(){
        request(PROJECT_JSJD_SETTING_FIND,"post",{}).then(res => {
          if(res.data.code==0&&res.data.data.length>0){
            let objs = res.data.data;
            this.obj.isZongLaowu = objs[0].isZongLaowu;
            let jsonParams = JSON.parse(objs[0].params);
            if(objs[0].id){
              this.obj.id = objs[0].id
            }
            Object.assign(this.obj.params,jsonParams);
            if(!this.obj.params){
              let autoObj = {
                jsjd:false,
                commonSet:{
                  templId:null,
                  courseId:null
                }
              }
              let obj = {
                titleId:[],
                templId:null,
                courseId:null
              }
              autoObj.jobTitleSet.push(obj);
              this.obj.params = autoObj;
            }

          }
        })
    },
    /**
     * 保存表单信息
     */
    doSave(e){
        e.preventDefault();
        let cloneObj = JSON.parse(JSON.stringify(this.obj))
        var paramsStr = JSON.stringify(cloneObj.params)//转换成字符串
        cloneObj.params = paramsStr;
        request(PROJECT_JSJD_SAVE,"post",cloneObj).then(res => {
          if(res.data.code==0&&res.data.data.id){
            this.obj = res.data.data;
            if(this.obj.params){
              this.obj.params = JSON.parse(this.obj.params)
              this.$message.success('设置成功');
            }

          }
        })
    },
    handleMenuClick (e) {
      if (e.key === 'delete') {
        this.remove()
      }
    },
    addTitleSet(){
      let obj = {
        titleId:[],
        templId:null,
        courseId:null
      }
      this.obj.params.jobTitleSet.push(obj);
    },
    removeTitleSet(index){
      if(this.obj.params.jobTitleSet.length==1){
        this.$message.error("已经是最后一个了，不能再删除了");
        return false;
      }
      this.obj.params.jobTitleSet.splice(index,1)
    }
  }
}
</script>

<style lang="less" scoped>
  .search{
    margin-bottom: 54px;
  }
  .fold{
    width: calc(100% - 216px);
    display: inline-block
  }
  .operator{
    margin-bottom: 18px;
  }
  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
  .imdexStyle{
    font-size: 20px;
    margin-left: 15px;
  }
</style>
