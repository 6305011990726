<template>
    <div class="container">
      <form-index
        ref="formindex"
        :formData="formData"
        @handleSubmit="handleSubmit"
        :projectList="projectList"
        :flexSubmit="true"
        @handlSelect="handlSelect"
        @handleChange="handleChange"
      >
        <!-- 下部分按钮插槽 -->
        <template #flexSubmit>
          <a-space  style="margin-left: 90px; margin-top: 5px">
            <a-button icon="search" type="primary" html-type="submit"> 查询 </a-button>
            <a-space class="operator">
  
              <a-button @click="close"> 重置 </a-button>
            </a-space>
          </a-space>
        </template>
      </form-index>
      <div class="a_a_footer">
        <!-- <div class="mb-1 mt-2">
          <a-space class="operator">
            <a-button @click="batchEntrance()"><a-icon type="login" />批量重新入场</a-button>
          </a-space>
          <a-space class="operator">
            <a-button @click="uploadJw" v-if="canJwOperate"><a-icon type="file-add" />申请导入建委</a-button>
          </a-space>
          <a-space class="operator">
            <a-button @click="delAccess()"><a-icon type="user-delete" />批量删除考勤权限</a-button>
          </a-space>
          <a-space class="operator">
            <a-button @click="exportStaff()"><a-icon type="download" />导出全部信息</a-button>
          </a-space>
          <a-space class="operator">
            <a-button @click="batchExit" ghost style="background: #fd9e06 !important; color: #fff; border-color:#fd9e06"><a-icon type="logout" />批量退场入口</a-button>
          </a-space>
          <a-space class="operator">
            <a-button @click="preExit" ghost style="background: #fd9e06 !important; color: #fff; border-color:#fd9e06"><a-icon type="logout" />通知工人离场</a-button>
          </a-space>
        </div> -->
        <!-- 表格封装 -->
        <standard-table
          class=""
          :columns="columns"
          :dataSource="dataSource"
          :selectedRows.sync="selectedRows"
          :rowKey="record => record.hqTeamVo.id"
          :pagination="pagination"
          :loading="tableLoading"
          :scroll="{ x: 1300 }"
          @change="onPageChange"
          :isAlert="false"
          :bordered="true"
          @update:selectedRows="updateSelectRows($event)"
        >
          <template slot="index" slot-scope="{ index }">
            <span>
              {{
                (pagination.current - 1) * pagination.pageSize +
                  parseInt(index) +
                  1
              }}
            </span>
          </template>
          <!-- <template slot="workName" slot-scope="{ record }">
            <router-link :to="'../advance_retreat/electronic_resume/' + record.projWorkNum + `?teamId=${record.teamId}&workNum=${record.workNum}`">
              {{ record.workName }}
            </router-link>
          </template> -->
          <template slot="id" slot-scope="{ record }">
            {{ record.hqTeamVo.id }}
          </template>
          <template slot="teamName" slot-scope="{ record }">
            {{ record.hqTeamVo.teamName }}
          </template>
          <template slot="curator" slot-scope="{ record }">
            {{ record.hqTeamVo.curator }}
          </template>
          <template slot="curatorPhone" slot-scope="{ record }">
            {{ record.hqTeamVo.curatorPhone }}
          </template>
          <template slot="teamType" slot-scope="{ record }">
            <span>{{ record.hqTeamVo.teamType == 1 ? '企业班组' : record.hqTeamVo.teamType == 2 ? '班组合伙人' : '专业班组' }}</span>
          </template>
          <template slot="workType" slot-scope="{ record }">
            <div v-for="item in record.hqTeamWorkContentVoList" :key="item.id"> {{ item.workTypeSelected }}</div>
          </template>
          <template slot="reference" slot-scope="{ record }">
            <span :title="record.hqTeamVo.reference ? record.hqTeamVo.reference : ''">{{ record.hqTeamVo.reference ? record.hqTeamVo.reference : '/' }}</span>
          </template>
          <template slot="referenceType" slot-scope="{ record }">
            <span>{{ record.hqTeamVo.referenceType == 1 ? '工人推广' : record.hqTeamVo.referenceType == 2 ? '管理人员推荐' : '自主申请' }}</span>
          </template>
          <template slot="applyTime" slot-scope="{ record }">
            <span>{{ moment(record.hqTeamVo.applyTime).format('YYYY-MM-DD') }}</span>
          </template>
          <template slot="status" slot-scope="{ record }">
            <span v-if="record.hqTeamVo.status == 0">待提交申请</span>
            <span v-else-if="record.hqTeamVo.status == 1">待审批</span>
            <span v-else-if="record.hqTeamVo.status == 2">审批通过</span>
            <span v-else-if="record.hqTeamVo.status == 3">审批不通过</span>
          </template>
          <div slot="action" slot-scope="{ record }">
            <span @click="visit(record.hqTeamVo)" style="color: #615e83;cursor: pointer;">查看</span>
            <a-divider type="vertical" v-show="record.hqTeamVo.status == 1"/>
            <span @click="passOrReject(record.hqTeamVo, 2)" v-show="record.hqTeamVo.status == 1" style="color: #307dfa;cursor: pointer;">通过</span>
            <a-divider type="vertical" v-show="record.hqTeamVo.status == 1"/>
            <span @click="passOrReject(record.hqTeamVo, 3)" v-show="record.hqTeamVo.status == 1" style="color: #ff5c77;cursor: pointer;">驳回</span>
          </div>
        </standard-table>
      </div>
    </div>
  </template>
  
  <script>
  import formIndex from "@/pages/components/form/index";
  import StandardTable from "@/components/table/StandardTable";
  import { getProAllOfficeList, getAllTeams } from "@/services/participationUnit";
  import { hgTeamList, approveTeamList, hgTeamWorkType } from "@/services/hgTeam"
  import { mapGetters } from "vuex";
  import moment from "moment";

  const formData = [
    {
      label: "班组编号",
      placeholder: "请输入班组编号",
      decorator: [
        "id",
        { rules: [{ required: false, message: "请输入班组编号" }] },
      ],
      type: "input",
      key: "id",
      dataSource: [],
      col: 5,
      labelCol: 8,
      wrapperCol: 14,
    },
    {
      label: "姓名",
      placeholder: "请填写",
      decorator: [
        "curator",
        { rules: [{ required: false, message: "请填写" }] },
      ],
      type: "input",
      key: "curator",
      col: 5,
      labelCol: 8,
      wrapperCol: 14,
      display: true,
    },
    {
      label: "班组名称",
      placeholder: "请输入班组名称",
      decorator: [
        "teamName",
        { rules: [{ required: false, message: "请输入班组名称" }] },
      ],
      type: "input",
      key: "teamName",
      dataSource: [],
      col: 5,
      labelCol: 8,
      wrapperCol: 14,
    },
    {
        label: "申请时间",
        placeholder: "",
        decorator: ["applyTimeArr", { rules: [{ required: false, message: "" }] }],
        type: "rangePicker",
        key: "applyTimeArr",
        dataSource: [],
        col: 6,
        labelCol: 8,
        wrapperCol: 14,
        display: true,
        format: "YYYY-MM-DD",
    },
    {
      label: "审核状态",
      placeholder: "请选择",
      decorator: [
        "status",
        { rules: [{ required: false, message: "请选择" }] },
      ],
      type: "select",
      key: "status",
      selectlist: [{
        value: 1,
        name: '待审批'
      },{
        value: 3,
        name: '审批不通过'
      }],
      col: 5,
      labelCol: 8,
      wrapperCol: 14,
      display: true,
      showSearch: true
    },
  ];
  const columns = [
    {
      title: "序号",
      width: 65,
      scopedSlots: { customRender: "index" },
      // customRender: (text, record, index) => `${index + 1}`,
      align: "center",
    },
    {
      title: "班组编号",
      dataIndex: "id",
      align: "center",
      width: 110,
      scopedSlots: { customRender: "id" },
    },
    {
      title: "班组名称",
      dataIndex: "teamName",
      align: "center",
      width: 220,
      scopedSlots: { customRender: "teamName" },
    },
    {
      title: "姓名",
      dataIndex: "curator",
      align: "center",
      width: 110,
      scopedSlots: { customRender: "curator" },
    },
    {
      title: "联系方式",
      dataIndex: "curatorPhone",
      align: "center",
      width: 140,
      scopedSlots: { customRender: "curatorPhone" },
    },
    {
      title: "班组类型",
      dataIndex: "teamType",
      align: "center",
      width: 120,
      scopedSlots: { customRender: "teamType" },
    },
    {
      title: "承接内容",
      align: "center",
      dataIndex: "workType",
      width: 220,
      scopedSlots: { customRender: "workType" },
    },
    {
      title: "推荐人",
      dataIndex: "reference",
      align: "center",
      ellipsis: true,
      width: 120,
      scopedSlots: { customRender: "reference" },
    },
    {
      title: "申请来源",
      dataIndex: "referenceType",
      align: "center",
      width: 130,
      scopedSlots: { customRender: "referenceType" },
    },
    {
      title: "申请时间",
      dataIndex: "applyTime",
      align: "center",
      width: 120,
      scopedSlots: { customRender: "applyTime" },
    },
    {
      title: "审核状态",
      dataIndex: "status",
      align: "center",
      width: 120,
      scopedSlots: { customRender: "status" },
    },
    {
      title: "操作",
      align: "center",
      width: 180,
      scopedSlots: { customRender: "action" },
    },
  ];
  export default {
    components: {
      formIndex,
      StandardTable,
    },
    data() {
      return {
        projectArr: [],
        projectList: [], //获取当前项目数据
        formData: formData,
        columns: columns,
        dataSource: [], //table数组
        selectedRows: [], //选中的数据
        pagination: {
          //分页数据
          current: 1,
          pageSize: 10,
          total: 0,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
          showTotal: (total, range) => `共 ${total} 条`
        },
        params: { //测试数据
          pageNumber: 1,
          pageSize: 10,
        },
        tableLoading: false, //table加载中
        selectedRecord: null,
        workTypeList: [],
      };
    },
    computed: {
      ...mapGetters("account", ["user"]),
    },
    activated() {
      this.getWorkType()
      
      // this.getZdata(getProAllOfficeList, {value: '', item: {key: 'unitId'}}, "officeName");
    },
    methods: {
      moment,
      getWorkType() {
        hgTeamWorkType().then((res) => {
          if (res.data.code === 0) {
            this.workTypeList = res.data.data.map(item => {
              let transformedItem = {
                text: item.name,
                id: item.code
              }
              if (item.child) {
                transformedItem.children = item.child.map(childItem => {
                  return {
                    text: childItem.name,
                    id: childItem.code
                  };
                });
              }
              return transformedItem;
            })
            this.getData(this.params)
          } else {
            this.$message.error(res.data.msg)
          }
        });
      },
      getData(params) {
        this.tableLoading = true;
        hgTeamList(params).then((res) => {
          this.tableLoading = false;
          if (res.data.code === 0) {
            this.dataSource = res.data.data.list;
            this.pagination.total = res.data.data.total;
            this.dataSource.forEach((item) => {
              // item.hqTeamVo.applyTime = item.hqTeamVo.applyTime ? moment(item.hqTeamVo.applyTime).format("YYYY-MM-DD") : '';
              item.hqTeamWorkContentVoList.forEach(el => {
                //设置工作内容
                let result = JSON.parse(el.typeCode).reduce((acc, activeId) => {
                  let parent = this.workTypeList.find(item =>  {
                    return item.children.some(child => {
                      return child.id === activeId;
                    });
                  });
                  let child = parent.children.find(item => {
                    return item.id === activeId;
                  });
                  let existingItem = acc.find(item => {
                    return item.parentText === parent.text;
                  });
                  if (existingItem) {
                    existingItem.childText += '，' + child.text;
                  } else {
                    acc.push({
                      parentText: parent.text,
                      childText: child.text
                    });
                  }
                  return acc;
                }, []);
                let workTypeSelected = ''
                result.forEach(el => {
                  if (result.length > 1) {
                    workTypeSelected += `${el.parentText}/${el.childText}；`
                  } else {
                    workTypeSelected += `${el.parentText}/${el.childText}`
                  }
                })
                el.workTypeSelected = workTypeSelected
              })
            });
          } else {
            this.$message.error(res.data.msg)
          }
        });
      },
      handleSubmit(e) {
        let obj = { ...e, ...this.params }
        if (!obj.applyTimeArr) obj.applyTimeArr = []
        obj.pageNumber = 1
        this.pagination.current = 1;
        this.getData(obj);
      },
      //查询所有班组
      doQueryTeams(params){
        getAllTeams(params).then(res => {
          if(res.data.code === 0){
            this.formDataFn('teamId', res.data.data, 'teamName')
          }
        })
      },
      handleChange(e) {
        if (e.item.key == 'teamId') {
          this.doQueryTeams({ teamName: e.value })
        } else if (e.item.key == 'unitId') {
          this.projectArr = [];
          if (!e.value) {
            this.$refs["formindex"].setFieldsValue('', {}, "unitId");
          }
          this.getZdata(getProAllOfficeList, e, "officeName");
        }
      },
      // 接口封装
      /**
       * msg 接收接口名称
       * e 数据
       * name 参数名
       * */
       getZdata(msg, e, name) {
        let data = {
          [name]: e.value,
          projectId: this.user.projectId
        };
  
        msg(data).then((res) => {
          if (res.status == 200) {
            const data = res.data.data.list ? res.data.data.list : res.data.data
            data.forEach((item) => {
              this.projectArr.push({
                title: item[name],
                id: item.officeId,
              });
            });
            this.projectList = data;
            // 获取当前匹配key，赋值相对于数组
            this.formData.forEach((item) => {
              if (e.item.key === item.key) {
                item.dataSource = this.projectArr;
              }
            });
          }
        });
      },
      // 对下拉框做数据处理
      formDataFn(key, data, itemName) {
        let arrSelect = []
        let arrSource = []
          data.forEach(item => {
            // 班组模糊匹配dataSource
            arrSource.push({ 'title': item[itemName], 'id': item.id })
            // seletetList选项
            arrSelect.push({ 'name': item[itemName], 'value': item.id })
          })
          this.formData.forEach(item => {
              if (item.key == key) {
                item.dataSource = arrSource
                item.selectlist = arrSelect
              }
          })
      },
      // 特殊数据处理
      handlSelect({ val, keys }) {
        this.projectList.forEach((item) => {
          if (keys == "unitId" && item.officeId == val) {
            this.$refs["formindex"].setFieldsValue(item.officeId, {}, "unitId");
            this.doQueryTeams({ officeId: val })
          }
        });
      },
      getStaffWorkType (arr) {
        if (arr && arr.length > 0) {
            const tempWorkList = [
                {carft: '焊工', code: '027'}, {carft: '杂工', code: 'Z10'}, {carft: '木工', code: '240'}, {carft: '钢筋工', code: '020'}, {carft: '砌筑工', code: '010'}, {carft: '混凝土工', code: '040'},
                {carft: '管道工', code: '110'}, {carft: '油漆工', code: '193'}, {carft: '门窗幕墙安装工', code: '210'}, {carft: '镶贴工', code: '192'}, {carft: '装饰装修工', code: '190'},
                {carft: '建筑起重机械司机', code: 'Z30'}, {carft: '建筑架子工', code: 'Z40'}, {carft: '着升降脚手架安装拆卸工', code: '031'}, {carft: '高处作业吊篮安装拆卸工', code: '033'},
                {carft: '起重工', code: '080'}, {carft: '电工', code: '130'}, {carft: '起重信号工', code: '170'}, {carft: '建筑起重机械安装拆卸工', code: '180'},{carft: '其他', code: 'Z20'}
            ]
            let temp = []
            arr.forEach((item) => {
                let obj = tempWorkList.filter(i => i.code == item)[0]
                temp.push(obj.carft)
            })
            return temp.join()
        } else {
            return ''
        }
      },
      //表格 - 分页查询
      onPageChange(e) {
        let obj = this.$refs["formindex"].getFieldValue();
        if (!obj.applyTimeArr) obj.applyTimeArr = []
        this.params.pageSize = e.pageSize
        this.pagination.pageSize = e.pageSize;
        this.params.pageNumber = e.current;
        this.pagination.current = e.current;
        this.getData(Object.assign({}, this.params, obj));
      },
      updateSelectRows(e) {
        console.log(e)
      },
      //查看
      visit(record) {
        this.$router.push({
          name:'班组详情',
          params: {
            id: record.id
          },
        })
      },
      passOrReject(record, status) {
        const _that = this
        let title = status == 2 ? '确认通过审批？' : '确认驳回审批？'
        this.$confirm({
        title,
        onOk() {
            approveTeamList({id: record.id, status}).then(res => {
                if (res.data.code === 0) {
                    _that.$message.success('操作成功');
                    _that.getData(_that.params)
                } else {
                    _that.$message.error(res.data.msg);
                }
            })
        }
      })
      },
      // 重置
      close() {
        this.params = {
          pageNumber: 1,
          pageSize: 10,
        };
        this.pagination.current = 1;
        this.getData(this.params);
        this.$refs["formindex"].resetFields();
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  .a_a_footer {
    padding: 0 0px;
    background: #ffffff;
  
    .operator {
      margin-right: 8px;
  
      .ant-btn {
        color: #307dfa;
      }
    }
  
    .sTables {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      div {
        width: 100%;
        text-align: center;
        cursor: pointer;
      }
  
      div:nth-child(2) {
        color: #307dfa;
      }
  
      div:nth-child(3) {
        color: #ff5c77;
      }
  
      div:nth-child(1) {
        color: #615e83;
      }
    }
  
  }
  .content_div {
    padding: 5px 0;
    cursor: pointer;
  }
  .content_div:hover {
    color: #307dfa;
  }
  .jwTag {
    border-radius: 2px;
    width: 65px;
    height: 30px;
    line-height: 28px;
    margin: auto;
    cursor: pointer;
    font-size: 14px;
  }
  .tagRed {
    background: #FFE8EC;
    color: #FF5D78;
  }
  .tagGray {
    background: #EAEBEB;
    color: #989999;
  }
  .tagGreen {
    background: #DEFCF1;
    color: #00CE86;
  }
  .tagBlue {
    background: #0874F01A;
    color: #0874F0;
  }
  </style>
  