<template>
  <div>
    <a-drawer
      :width="800"
      :visible="visible"
      title="编辑课时信息"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose"
    > 
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }" layout="horizontal">
       <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="所属课程：">
              <a-select
                :allowClear="true"
                style="width: 100%"
                placeholder="请选择课程："
                @change="onChange"
                v-decorator="['courseId', { rules: [{ required: true, message: '请选择归属课程：' }]}]">
                <a-select-option v-for="t in courseList" :key="t.id" :value="t.id">{{t.name}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="课时标题：">
              <a-input
                v-decorator="['name', { rules: [{ required: true, message: '请输课时标题：' }] }]"
               placeholder="请输入课时标题："/>
            </a-form-item>
          </a-col>
        </a-row>
        <!-- <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="课时编码：">
              <a-input
                v-decorator="['code', { rules: [{ required: true, message: '请输入课时编码' }] }]"
               placeholder="请输入课时编码"/>
            </a-form-item>
          </a-col>
        </a-row> -->
        <a-row :gutter="12">
          <a-col :span="24">
            <a-form-item label="课时视频：">
              <a-upload
                name="file"
                :multiple="true"
                :action="uploadUrl"
                accept=".mp4,.MP4,.flv,.FLV"
                :headers="headers"
                :beforeUpload="beforeUpload"
                @change="handleChange"
              >
                <a-button> 
                  <a-icon v-if="uploadStatus=='done'||(uploadStatus=='upload'&&(videoPath!=''&&videoPath!=null))" style="color:#4a86e8" type="check-circle" theme="filled" /> 
                  <a-icon v-else type="upload" /> 
                  上传视频 </a-button>
              </a-upload>
              <a :href="`${videoPath}`" target="_blank" v-if="videoPath!=''&&videoPath!=null" style="margin-top:20px">已上传视频，点击预览</a>
            </a-form-item>
          </a-col>
        </a-row>
        <!-- <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="课时序号">
               <a-input-number
                   v-decorator="['indexNo']"
                  placeholder="请输入课时序号" 
                />
            </a-form-item>
          </a-col>
        </a-row> -->
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="最低学习时长">
               <a-input-number style="width:120px"
                   v-decorator="['viewTimes']"
                   :min='0'
                   :step="1"
                  placeholder="最低学习时长" 
                />(秒)
            </a-form-item>
          </a-col>
        </a-row>
        <!-- <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item v-if="isShowThree" label="三级安全教育类型">
               <a-radio-group v-model="threeEduType" @change="changeThreeEduType">
                 <a-radio :value="0">
                  非三级安全教育
                </a-radio>
                <a-radio :value="1">
                  一级教育
                </a-radio>
                <a-radio :value="2">
                  二级教育
                </a-radio>
                <a-radio :value="3">
                  三级教育
                </a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row> -->
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="课时内容">
              <keep-alive>
                <tinymce v-if="visible" ref="tinymceEditor" :value="obj.remark"  @input="contentInput"></tinymce>
              </keep-alive>
            </a-form-item>
          </a-col>
        </a-row>
    </a-form>
    <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginLeft: '8px' }" type="primary" @click="doSubmit">
          确定
        </a-button>
        <a-button :style="{ marginLeft: '8px' }" @click="onClose">
          关闭
        </a-button>
      </div>
    </a-drawer>
    
  </div>
</template>
<script>
import { request } from "@/utils/request";
import { ATTACHMENT_UPLOAD,ONLINE_QUERY_COURSE_GETALL,ONLINE_QUERY_COURSE_DETAIL,ONLINE_CLASS_HOUR_CHECKEDUTYPE} from "@/services/api";
import Tinymce from "@/components/tinymce"
import { session } from '@/utils/storage'
import { xsrfHeaderName } from '@/utils/request.js'

export default {
  name: "classHourEditor",
  components: {Tinymce},
  data() {
    return {
      form: this.$form.createForm(this),
      isPublic:0,
      fileList:[],
      defaultFileList:[],
      filePath:null,
      loading:false,
      videoPath:null,
      courseList:[],
      isShowThree:false,
      threeEduType:0,
      obj:{},
      uploadUrl:ATTACHMENT_UPLOAD,
      cPercent:0,
      uploadStatus:'upload',
      headers: {
        Authorization: session.get(xsrfHeaderName),
        'Tenant-Id': session.get('Tenant-Id') ? session.get('Tenant-Id') : ''
      },
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    classHour:{
      type: Object,
      require:true
    },
    // courseList:{
    //   type:Array,
    //   require:true
    // }
  },
  created(){
    
  },
  mounted(){
    
  },
  watch:{
    cPercent:{
      handler(newName, oldName) {
        this.cPercent=newName;
      },
      immediate: true,
    },
    visible:{
      handler(val, oldName) {
        this.queryCoure();
        console.log("当前课时",this.classHour)
        if(val&&this.classHour.id){
          this.obj = this.classHour;
          setTimeout(()=>{
            this.form.setFieldsValue({
              'name': this.obj.name,
              'code': this.obj.code,
              'indexNo': this.obj.indexNo,
              'remark': this.obj.remark,
              'viewTimes':this.obj.viewTimes,
              'courseId': this.obj.courseId+""
            })
            if (this.obj.courseId) this.doDetail(this.obj.courseId);
            this.videoPath = this.obj.videoPath;
            this.threeEduType = this.obj.threeEduType;
            // if(this.videoPath){
            //   var fileObj = {
            //     uid: '1',
            //     name: "课时视频.MP4",
            //     url: this.videoPath,
            //   }
            //   this.fileList.push(fileObj)
            // }else{
            //   this.fileList=null;
            // }
          })
        }else{
          this.threeEduType = 0;
          this.$set(this.obj,"remark","");
          this.filePath=null;
          this.fileList = [];
          this.videoPath = null;
          setTimeout(()=>{
            this.form.setFieldsValue({
              'courseId': this.classHour.courseId+""
            })
            if (this.obj.courseId) this.doDetail(this.obj.courseId);
            // this.doDetail(this.classHour.courseId);
          })
          this.form.resetFields();
          
        }
      },
      immediate: true,
    }
  },
  methods: {
    queryCoure(){
      request(ONLINE_QUERY_COURSE_GETALL,"post",{isGetAll:"1"}).then(res => {
        if(res.data.code==0){
          this.courseList = res.data.data.list;
        }
      })
    },
    onChange(value){
      this.doDetail(value);
    },
    changeThreeEduType(e){
      let value = e.target.value;
      if(value!=0){
        request(ONLINE_CLASS_HOUR_CHECKEDUTYPE+value,"get",{}).then(res => {
          if(res.data.code==0&&res.data.data){
            this.$message.warning("公司已经设置了【"+res.data.msg+"】课时为"+value+"级安全教育.如需修改，请先取消再设置.");
            this.threeEduType = 0;
            return false;
          }
        })
      }
       
    },
    doDetail(courseId){
      request(ONLINE_QUERY_COURSE_DETAIL+courseId,"get",{}).then(res => {
        if(res.data.code==0){
          if(res.data.data.isThreeEdu&&res.data.data.isThreeEdu==1){
             this.isShowThree= true;
          }else{
             this.isShowThree= false;
          }
        }else{
          this.isShowThree= false;
        }
      })
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },
    onClose(){
      this.$emit('classHourPageClose');
    },
    contentInput(e){
      this.obj.remark = e;
    },
    doSubmit(){
       //数据校验
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(this.classHour.id)
          if(this.classHour.id){
            values.id = this.classHour.id
          }
          if(!values.courseId){
            values.courseId=this.classHour.courseId;
          }
          values.videoPath=this.videoPath;
          // values.videoPath=this.videoPath;
          if(this.isShowThree==true){
            values.threeEduType = this.threeEduType;
          }else{
            values.threeEduType = 0;
          }
          if(this.obj.remark){
            values.remark = this.obj.remark
          }
          this.fileList = [];
          this.$emit('classHourSubmit',{classHour:values})
        }
      })
    },
    /** 
    * 上传之前
    */
    beforeUpload (file) {
      return new Promise((resolve, reject) => {
        // const isFiveM= file.size / 1024 / 1024 < 50 //小于10M的图片上传
        // //判断文件是否符合正则表达式的规则
        // if (!isFiveM) {
        //   this.$message.error('上传资源不大于50M！');
        //   return reject(false)
        // }else{
          return resolve(true);
        // }
      });
    },

    handleChange(info) {
      console.log(info)
      if (info.file.status !== 'uploading') {
        console.log("bbbbbb",info.file, info.fileList);
        this.uploadStatus = 'uploading';
      }
      if (info.file.status === 'done') {
        this.uploadStatus = 'done';
        this.$message.success(`视频文件${info.file.name} 上传成功……`);
        this.videoPath = info.file.response.data.path;
        var fileObj = {
          uid: '1',
          name: "课时视频.MP4",
          url: this.videoPath,
        }
        this.fileList.push(fileObj)
      } else if (info.file.status === 'error') {
        this.uploadStatus = 'error';
        this.$message.error(`视频文件${info.file.name}  上传失败……`);
      }
    },

    /**
    * 资源上传
    */
    resourceUpload(value){
      this.loading = true;
      const formData = new FormData();
      formData.append('file', value.file);
      // console.log(value.file.size)
      // this.uploadFun(ATTACHMENT_UPLOAD,formData);
      
      
      request(ATTACHMENT_UPLOAD,"post",formData).then(result => {
          if (result.data.code==0) {
            // var fileObj = {
            //   uid: '1',
            //   name: result.data.data.name,
            //   url: result.data.data.path,
            // }
            this.videoPath = result.data.data.path;
            // this.fileList = [];
            // this.fileList.push(fileObj);
            value.onProgress(value,value.file);
            value.onSuccess(result, value.file);
          }else{
            this.$message.warning(result.message);
          }
          this.loading = false;
      })
      console.log(value);
      let progress = { percent: 1 };
      console.log(value.file.size)
      let speed = 100 / (value.file.size / (value.file.size/100));
      const intervalId = setInterval(() => {
        if (progress.percent < 99 && progress.percent+speed<100) {
          progress.percent += speed;
          value.onProgress(progress);
        } else {
          clearInterval(intervalId);
        }
      }, 10);
    },
  }
};
</script>
<style>
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>