var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-card",
        {
          staticClass: "a_Approval",
          attrs: { title: _vm.title, bordered: false },
        },
        [
          _c(
            "a-form-model",
            { ref: "ruleForm", attrs: { model: _vm.params, rules: _vm.rules } },
            [
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "上级文件名称",
                            "label-col": { span: 6 },
                            "wrapper-col": { span: 14 },
                            prop: "higherfilename",
                          },
                        },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入上级文件名称" },
                            model: {
                              value: _vm.params.higherfilename,
                              callback: function ($$v) {
                                _vm.$set(_vm.params, "higherfilename", $$v)
                              },
                              expression: "params.higherfilename",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "编号",
                            "label-col": { span: 6 },
                            "wrapper-col": { span: 14 },
                            prop: "no",
                          },
                        },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入编号" },
                            model: {
                              value: _vm.params.no,
                              callback: function ($$v) {
                                _vm.$set(_vm.params, "no", $$v)
                              },
                              expression: "params.no",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "发布部门",
                            "label-col": { span: 6 },
                            "wrapper-col": { span: 14 },
                            prop: "no",
                          },
                        },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入发布部门" },
                            model: {
                              value: _vm.params.pubdeptname,
                              callback: function ($$v) {
                                _vm.$set(_vm.params, "pubdeptname", $$v)
                              },
                              expression: "params.pubdeptname",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "发布日期",
                            "label-col": { span: 6 },
                            "wrapper-col": { span: 14 },
                            prop: "pubdate",
                          },
                        },
                        [
                          _c("a-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              allowClear: "",
                              mode: "date",
                              format: "YYYY-MM-DD",
                              valueFormat: "YYYY-MM-DD",
                            },
                            model: {
                              value: _vm.params.pubdate,
                              callback: function ($$v) {
                                _vm.$set(_vm.params, "pubdate", $$v)
                              },
                              expression: "params.pubdate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "分类",
                            "label-col": { span: 6 },
                            "wrapper-col": { span: 14 },
                            prop: "remark",
                          },
                        },
                        [
                          _c("a-select", {
                            ref: "select",
                            staticStyle: { width: "220px" },
                            attrs: { options: _vm.options, allowClear: "" },
                            model: {
                              value: _vm.params.categorizationid,
                              callback: function ($$v) {
                                _vm.$set(_vm.params, "categorizationid", $$v)
                              },
                              expression: "params.categorizationid",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "上传附件",
                            "label-col": { span: 6 },
                            "wrapper-col": { span: 14 },
                            prop: "year",
                          },
                        },
                        [
                          _c(
                            "a-upload",
                            {
                              staticClass: "avatar-uploader",
                              attrs: {
                                "list-type": "picture-card",
                                accept: _vm.accept,
                                "file-list": _vm.filePath,
                                customRequest: _vm.resourceUpload,
                                beforeUpload: _vm.beforeUpload,
                                multiple: "",
                              },
                              on: {
                                change: _vm.handleUploadChange,
                                preview: _vm.handlePreview,
                              },
                            },
                            [
                              _vm.filePath.length < 10
                                ? _c(
                                    "div",
                                    [
                                      _c("a-icon", {
                                        attrs: {
                                          type: _vm.uploading
                                            ? "loading"
                                            : "plus",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "ant-upload-text" },
                                        [_vm._v("上传附件")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-row",
            [
              _c("a-col", { attrs: { span: 12 } }),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        "label-col": { span: 4 },
                        "wrapper-col": { span: 14 },
                        prop: "year",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex flex-row-reverse" },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.handleSubmit },
                            },
                            [_vm._v("保存")]
                          ),
                          _c(
                            "a-button",
                            { staticClass: "mr-2", on: { click: _vm.close } },
                            [_vm._v("返回")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }