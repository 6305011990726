var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.$route.meta.showFather,
              expression: "$route.meta.showFather",
            },
          ],
          staticClass: "a_Approval",
        },
        [
          _c("div", { staticClass: "plug-Overview" }, [_vm._v("专家信息列表")]),
          _c("a-divider"),
          _c(
            "div",
            { staticClass: "a_a_footer" },
            [
              _c(
                "div",
                { staticClass: "mb-1" },
                [
                  _c(
                    "a-space",
                    { staticClass: "operator" },
                    [
                      _vm.user.nowRoleKey.includes("OFFICE_AQMGLY")
                        ? _c(
                            "a-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.addNewRecord },
                            },
                            [_vm._v("新增")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("standard-table", {
                attrs: {
                  columns: _vm.columns,
                  dataSource: _vm.dataSource,
                  rowKey: "id",
                  pagination: _vm.pagination,
                  loading: _vm.tableLoading,
                  isAlert: false,
                  bordered: true,
                },
                on: { change: _vm.onPageChange },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "index",
                      fn: function (ref) {
                        var index = ref.index
                        return [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  (_vm.pagination.current - 1) *
                                    _vm.pagination.pageSize +
                                    parseInt(index) +
                                    1
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "checkDirection",
                      fn: function (ref) {
                        var record = ref.record
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.getCheckDir(record.checkDirection))
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "action",
                      fn: function (ref) {
                        var record = ref.record
                        return _vm.user.nowRoleKey.includes("OFFICE_AQMGLY")
                          ? [
                              _c("div", { staticClass: "sTables" }, [
                                _c(
                                  "div",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.visit(record)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _c(
                                  "div",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.del(record)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ]),
                            ]
                          : undefined
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }