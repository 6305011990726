import {request, METHOD} from '@/utils/request'
import { ADMIN, GET_POST_LIST, INSERT_POST, DEL_POST, INSERT_PEOPLE, LIST_WITH_PROJECT, DEL_PEOPLE, PEOPLE_DETAIL, PEOPLE_UPDATE, GET_COMPANY_STRUCTURE_LIST,
  GET_APPROVE_LIST, APPROVE_STRUCT } from '@/services/api'

// const BASE_URL = process.env.VUE_APP_API_BASE_URL + '/safe'
const ADMIN_API = `${ADMIN}/safe`

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getPostList(params) {
  return request(GET_POST_LIST, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function insertPost(params) {
  return request(INSERT_POST, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function delPost(params) {
  return request(DEL_POST + `/${params}`, METHOD.DELETE)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function insertPeople(params) {
  return request(INSERT_PEOPLE, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function listWithProject(params) {
  return request(LIST_WITH_PROJECT, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function delPeople(params) {
  return request(DEL_PEOPLE + `/${params}`, METHOD.DELETE)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function peopleDetail(params) {
  return request(PEOPLE_DETAIL + `/${params}`, METHOD.GET)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function peopleUpdate(params) {
  return request(PEOPLE_UPDATE, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getCompanyStructureList(params) {
  return request(GET_COMPANY_STRUCTURE_LIST, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getApproveList(params) {
  return request(GET_APPROVE_LIST, METHOD.POST, params)
}

/**
 *  
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function approveStruct(params) {
  return request(APPROVE_STRUCT, METHOD.POST, params)
}

/**
 *  导出
 */
export const exportCompanyStructureList = ADMIN_API + '/projectDutyStructure/structListWithCompanyExport'