<template>
  <div class="container">
    <div class="a_m_header">
      <a-tabs :default-active-key="defaultActiveKey">
        <a-tab-pane key="1" tab="基本信息">
          <base-info></base-info>
        </a-tab-pane>
        <a-tab-pane key="2" tab="考勤记录">
          <attend-record></attend-record>
        </a-tab-pane>
        <a-tab-pane key="3" tab="项目履历">
          <project-resume></project-resume>
        </a-tab-pane>
        <!-- <a-tab-pane key="3" tab="健康信息">
          <health-info></health-info>
        </a-tab-pane> -->
        <!-- <a-tab-pane key="4" tab="奖惩记录">
          <rewards-punishment-record></rewards-punishment-record>
        </a-tab-pane> -->
        <!-- <a-tab-pane key="5" tab="学习记录">
          <learning-record></learning-record>
        </a-tab-pane> -->
        <a-tab-pane key="6" tab="证书">
          <certificate></certificate>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import AttendRecord from './attendRecord.vue';
import BaseInfo from './baseInfo.vue';
import Certificate from './certificate.vue';
import HealthInfo from './healthInfo.vue';
import LearningRecord from './learningRecord.vue';
import ProjectResume from './projectResume.vue';
import RewardsPunishmentRecord from './rewardsPunishmentRecord.vue';
export default {
  components: { 
    BaseInfo, 
    // HealthInfo, 
    ProjectResume, 
    // RewardsPunishmentRecord, 
    // LearningRecord, 
    Certificate,
    AttendRecord
  },
  data() {
    return {
      defaultActiveKey: "1",
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>

<style lang="less" scoped>

</style>