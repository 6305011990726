/* 系统相关-枚举文件 */

/**
 * @description 系统类型-值枚举
 */
export const SYSTEM_TYPE_ENUM = Object.freeze({
  // 劳务管理
  LAOWU: 'laowu',
  // 机施安全
  SAFE: 'safe',
  // 建安易
  JIANANYI: 'jiananyi',
  // 市政集团
  SHIZHENG: 'shizheng'
})
/**
 * @description 系统类型-名称映射
 */
export const SYSTEM_TYPE_NAME_MAP = Object.freeze({
  [SYSTEM_TYPE_ENUM.LAOWU]: "劳务管理",
  [SYSTEM_TYPE_ENUM.SAFE]: "机施安全",
  [SYSTEM_TYPE_ENUM.JIANANYI]: "建安易",
  [SYSTEM_TYPE_ENUM.SHIZHENG]: "市政集团",
})
/**
 * @description 系统类型-全称映射
 */
export const SYSTEM_TYPE_FULLNAME_MAP = Object.freeze({
  [SYSTEM_TYPE_ENUM.LAOWU]: "建工一号·劳务管理",
  [SYSTEM_TYPE_ENUM.SAFE]: "机施集团安全管理系统",
  [SYSTEM_TYPE_ENUM.JIANANYI]: "建安易·安全生产管理平台",
  [SYSTEM_TYPE_ENUM.SHIZHENG]: "市政集团安全管理系统",
})
/**
 * @description 系统类型-侧边栏名称映射
 */
export const SYSTEM_TYPE_SIDE_NAME_MAP = Object.freeze({
  [SYSTEM_TYPE_ENUM.LAOWU]: "建工一号·劳务管理",
  [SYSTEM_TYPE_ENUM.SAFE]: "机施集团安全管理系统",
  [SYSTEM_TYPE_ENUM.JIANANYI]: "建安易·安全生产管理平台",
  [SYSTEM_TYPE_ENUM.SHIZHENG]: "| 安全管理系统",
})
/**
 * @description 系统类型-测试环境 host 映射
 */
export const SYSTEM_TYPE_TEST_HOST_MAP = Object.freeze({
  // 劳务管理
  [SYSTEM_TYPE_ENUM.LAOWU]: 'test.gz-suijian.com',
  // 机施安全
  [SYSTEM_TYPE_ENUM.SAFE]: 'testsafe.gz-suijian.com',
  // 建安易
  [SYSTEM_TYPE_ENUM.JIANANYI]: 'jantest.gz-suijian.com',
  // TODO: 【市政】待补充
  [SYSTEM_TYPE_ENUM.SHIZHENG]: "",
})
/**
 * @description 系统类型-生产环境 host 映射
 */
export const SYSTEM_TYPE_PROD_HOST_MAP = Object.freeze({
  // 劳务管理
  [SYSTEM_TYPE_ENUM.LAOWU]: 'adm.gz-suijian.com',
  // 机施安全
  [SYSTEM_TYPE_ENUM.SAFE]: 'safe.gz-suijian.com',
  // 建安易
  [SYSTEM_TYPE_ENUM.JIANANYI]: 'jan01.gz-suijian.com',
  // TODO: 【市政】待补充
  [SYSTEM_TYPE_ENUM.SHIZHENG]: "",
})

/**
 * @description 系统类型-Options
 */
export const SYSTEM_OPTIONS = Object.freeze(
  Object.entries(SYSTEM_TYPE_NAME_MAP).map(([value, label]) => ({
    label,
    value: Number(value),
  }))
)

/**
 * @description 版本更新类型-值枚举
 */
export const VERSION_TYPE_ENUMS = Object.freeze({
  // 版本更新通告
  PRE_RELEASE: 0,
  // 版本更新内容
  RELEASE: 1,
})
/**
 * @description 版本更新类型-文本映射
 */
export const VERSION_TYPE_ZHCN_MAP = Object.freeze({
  [VERSION_TYPE_ENUMS.PRE_RELEASE]: "版本更新通告",
  [VERSION_TYPE_ENUMS.RELEASE]: "版本更新内容",
})
