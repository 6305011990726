var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "constructiontTable_index" },
    [
      _c(
        "a-row",
        { attrs: { gutter: 24 } },
        [
          _c(
            "a-col",
            { attrs: { span: 6 } },
            [
              _c(
                "a-card",
                {
                  staticClass: "calc_height",
                  staticStyle: { overflow: "auto" },
                  attrs: { bordered: true },
                },
                [
                  _c("a-tree", {
                    attrs: {
                      showSearch: true,
                      "tree-data": _vm.officeTreeData,
                      "auto-expand-parent": _vm.autoExpandParent,
                      defaultExpandAll: "",
                      replaceFields: {
                        children: "children",
                        title: "name",
                        key: "id",
                      },
                    },
                    on: { select: _vm.onSelect },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 18 } },
            [
              _c("form-index", {
                ref: "formindex",
                attrs: {
                  formData: _vm.formData,
                  baiduMapBoolen: false,
                  flexAction: true,
                },
                on: { handleSubmit: _vm.handleSubmit },
                scopedSlots: _vm._u([
                  {
                    key: "flexAction",
                    fn: function () {
                      return [
                        _c(
                          "a-space",
                          { style: { marginTop: "4px" } },
                          [
                            _c(
                              "a-button",
                              {
                                attrs: {
                                  icon: "search",
                                  type: "primary",
                                  "html-type": "submit",
                                },
                              },
                              [_vm._v(" 查询 ")]
                            ),
                            _c(
                              "a-space",
                              { staticClass: "operator" },
                              [
                                _c("a-button", { on: { click: _vm.close } }, [
                                  _vm._v(" 重置 "),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "a-button",
                              {
                                attrs: { icon: "plus", type: "primary" },
                                on: { click: _vm.createUser },
                              },
                              [_vm._v(" 新增用户 ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("standard-table", {
                attrs: {
                  columns: _vm.columns,
                  dataSource: _vm.dataSource,
                  rowKey: "id",
                  pagination: _vm.pagination,
                  loading: _vm.tableLoading,
                  isAlert: false,
                  bordered: true,
                },
                on: { change: _vm.onPageChange },
                scopedSlots: _vm._u([
                  {
                    key: "index",
                    fn: function (ref) {
                      var index = ref.index
                      return [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                (_vm.pagination.current - 1) *
                                  _vm.pagination.pageSize +
                                  parseInt(index) +
                                  1
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "status",
                    fn: function (ref) {
                      var text = ref.text
                      return [
                        text === 0
                          ? _c("a-tag", { attrs: { color: "blue" } }, [
                              _vm._v("开启"),
                            ])
                          : _c("a-tag", [_vm._v("关闭")]),
                      ]
                    },
                  },
                  {
                    key: "action",
                    fn: function (ref) {
                      var record = ref.record
                      return [
                        _c(
                          "div",
                          { staticClass: "sTables" },
                          [
                            _c(
                              "div",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.updateUser(record)
                                  },
                                },
                              },
                              [_vm._v("修改")]
                            ),
                            _c(
                              "a-popover",
                              {
                                attrs: {
                                  placement: "bottomRight",
                                  trigger: "click",
                                },
                              },
                              [
                                _c("template", { slot: "content" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "content_div",
                                      on: {
                                        click: function ($event) {
                                          return _vm.setRole(record)
                                        },
                                      },
                                    },
                                    [
                                      _c("a-icon", {
                                        staticStyle: { "margin-right": "5px" },
                                        attrs: { type: "edit" },
                                      }),
                                      _vm._v("分配角色 "),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "content_div",
                                      on: {
                                        click: function ($event) {
                                          return _vm.resetPassUser(record)
                                        },
                                      },
                                    },
                                    [
                                      _c("a-icon", {
                                        staticStyle: { "margin-right": "5px" },
                                        attrs: { type: "edit" },
                                      }),
                                      _vm._v("重置密码"),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "content_div",
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteUser(record)
                                        },
                                      },
                                    },
                                    [
                                      _c("a-icon", {
                                        staticStyle: { "margin-right": "5px" },
                                        attrs: { type: "delete" },
                                      }),
                                      _vm._v("删除"),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("template", { slot: "title" }, [
                                  _c("span", [_vm._v("操作")]),
                                ]),
                                _c("div", [_vm._v("更多>")]),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("systemUserModal", {
        ref: "systemUserModal",
        attrs: {
          visible: _vm.visibleExit,
          visibleTitle: _vm.visibleTitle,
          userId: _vm.userId,
        },
        on: { handleCancel: _vm.handleCancel },
      }),
      _c("resetPasswordModal", {
        ref: "resetPasswordModal",
        attrs: { visible: _vm.resetPasswordVisible, userId: _vm.userId },
        on: { handleCancel: _vm.handleCancel },
      }),
      _c("setUserRoleModal", {
        ref: "resetPasswordModal",
        attrs: { visible: _vm.setRoleVisible, userId: _vm.userId },
        on: { handleCancel: _vm.handleCancel },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }