var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "team-box" },
    [
      _c("form-index", {
        ref: "formindex",
        attrs: {
          projectList: _vm.projectList,
          formData: _vm.formData,
          flexAction: true,
          baiduMapBoolen: false,
        },
        on: {
          handleSubmit: _vm.handleSubmit,
          handleChange: _vm.handleChange,
          handlSelect: _vm.handlSelect,
        },
        scopedSlots: _vm._u([
          {
            key: "flexAction",
            fn: function () {
              return [
                _c(
                  "a-space",
                  { style: { marginTop: "4px" } },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: {
                          icon: "search",
                          type: "primary",
                          "html-type": "submit",
                        },
                      },
                      [_vm._v(" 查询 ")]
                    ),
                    _c(
                      "a-space",
                      { staticClass: "operator" },
                      [
                        _c("a-button", { on: { click: _vm.close } }, [
                          _vm._v(" 重置 "),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("standard-table", {
        attrs: {
          columns: _vm.columns,
          dataSource: _vm.dataSource,
          "row-key": "id",
          pagination: _vm.pagination,
          loading: _vm.tableLoading,
          bordered: true,
          tableSize: "middle",
        },
        on: { change: _vm.onPageChange },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function (ref) {
              var index = ref.index
              return [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        (_vm.pagination.current - 1) * _vm.pagination.pageSize +
                          parseInt(index) +
                          1
                      ) +
                      " "
                  ),
                ]),
              ]
            },
          },
          {
            key: "teamName",
            fn: function (ref) {
              var text = ref.text
              var record = ref.record
              return [
                _c(
                  "a-tooltip",
                  { attrs: { placement: "topLeft" } },
                  [
                    _c("template", { slot: "title" }, [
                      _c("span", [_vm._v(_vm._s(text))]),
                    ]),
                    _c(
                      "span",
                      {
                        staticClass: "ant-btn-text ellipsis",
                        on: {
                          click: function ($event) {
                            return _vm.viewEditing(record)
                          },
                        },
                      },
                      [_vm._v(_vm._s(text))]
                    ),
                  ],
                  2
                ),
              ]
            },
          },
          {
            key: "phone",
            fn: function (ref) {
              var text = ref.text
              return _c("div", {}, [
                _c("span", [_vm._v(_vm._s(text ? text : "/"))]),
              ])
            },
          },
          {
            key: "action",
            fn: function (ref) {
              var record = ref.record
              return _c(
                "div",
                {},
                [
                  _c(
                    "a-dropdown",
                    {
                      staticClass: "sTables",
                      attrs: { placement: "bottomCenter" },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("span", [_vm._v("操作")]),
                          _c("a-icon", {
                            staticStyle: {
                              "margin-left": "2px",
                              "font-size": "12px",
                            },
                            attrs: { type: "caret-down" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-menu",
                        {
                          class: ["avatar-menu"],
                          attrs: { slot: "overlay" },
                          slot: "overlay",
                        },
                        [
                          _c(
                            "a-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.viewEditing(record, "edit")
                                },
                              },
                            },
                            [
                              _c("a-icon", { attrs: { type: "edit" } }),
                              _c("span", [_vm._v("修改")]),
                            ],
                            1
                          ),
                          _c(
                            "a-menu-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.recorDelete(record)
                                },
                              },
                            },
                            [
                              _c("a-icon", { attrs: { type: "delete" } }),
                              _c("span", [_vm._v("删除")]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }