<template>
    <a-card>
        <div id="components-form-demo-advanced-search">
            <a-form class="ant-advanced-search-form" :form="queryData" >
                <a-row :gutter="24">
                    <a-col :span="2" :style="{ textAlign: 'right'}">
                        <a-button type="primary" icon="plus" @click="addModel('', '0')">
                            新增设备
                        </a-button>
                    </a-col>
<!--                    <a-col :span="2" :style="{ textAlign: 'right'}">-->
<!--                        <a-button type="primary" icon="plus" @click="addModel('', '0')">-->
<!--                            分配分组-->
<!--                        </a-button>-->
<!--                    </a-col>-->
                </a-row>

            </a-form>
        </div>
        <a-table :columns="columns" :data-source="data"
                 :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                :rowKey="'deviceCode'">

            <template slot="action" slot-scope="text, record">
                                <span>
                                  <a @click="addGroup(record)">添加到分组</a>
                                  <a-divider type="vertical" />
                                  <a @click="delDevice(record)">删除</a>
                                </span>
            </template>
        </a-table>
        <div>
            <a-modal
                    :title="title"
                    :visible="visible"
                    :confirm-loading="confirmLoading"
                    @ok="modelOk"
                    @cancel="modelCancel"
                    width="560px"
            >
                <a-form-model :model="form"
                              :label-col="labelCol"
                              :wrapper-col="wrapperCol"
                              v-if="visible"
                >
                    <a-form-model-item label="设备名称" ref="deviceName" prop="deviceName"  required>
                        <a-input v-model="form.deviceName" />
                    </a-form-model-item>

                    <a-form-model-item label="设备编号" ref="deviceCode" prop="deviceCode"  required>
                        <a-input v-model="form.deviceCode" />
                    </a-form-model-item>

                    <a-form-model-item label="设备ip" ref="deviceIp" prop="deviceIp"  required>
                        <a-input v-model="form.deviceIp" />
                    </a-form-model-item>

                    <a-form-model-item label="厂商编号" ref="deviceManufactor" prop="deviceManufactor" >
                        <a-input v-model="form.deviceManufactor" />
                    </a-form-model-item>

                    <a-form-model-item label="通道名称" ref="checkChannel" prop="checkChannel"  required>
                        <a-input v-model="form.checkChannel" />
                    </a-form-model-item>

                    <a-form-model-item label="是否区分进出" ref="distinguishInOut" prop="distinguishInOut"  required>
                        <a-select v-model="form.distinguishInOut">
                            <a-select-option value="0" key="0">不区分</a-select-option>
                            <a-select-option value="1" key="1">区分</a-select-option>
                        </a-select>
                    </a-form-model-item>

                    <a-form-model-item label="进出标记" ref="inOutFlag" prop="inOutFlag"  required>
                        <a-select v-model="form.inOutFlag" >
                            <a-select-option value="1" key="1">进入</a-select-option>
                            <a-select-option value="2" key="2">出去</a-select-option>
                        </a-select>
                    </a-form-model-item>

                    <a-form-model-item label="是否考勤机" ref="isAttend" prop="isAttend"  required>
                        <a-select v-model="form.isAttend" >
                            <a-select-option value="1" key="1">是</a-select-option>
                            <a-select-option value="2" key="2">否</a-select-option>
                        </a-select>
                    </a-form-model-item>

                    <a-form-model-item label="所属厂商" ref="manufacturerKey" prop="manufacturerKey"  required>
                        <a-select v-model="form.manufacturerKey" >
                            <a-select-option v-for="item in manufacturerList" :value="item.manufacturerKey" :key="item.manufacturerKey">{{item.manufacturerName}}</a-select-option>
                        </a-select>
                    </a-form-model-item>


                </a-form-model>
            </a-modal>
        </div>
        <div>
            <a-modal
                    :title="selectGroupModel.title"
                    :visible="selectGroupModel.visible"
                    :confirm-loading="selectGroupModel.confirmLoading"
                    @ok="selectGroupModelOk"
                    @cancel="selectGroupModelCancel"
                    width="560px"
            >
                <a-select style="width: 200px" @change="selectGroupChange">
                    <a-select-option v-for="group in groupList" :key="group.groupId">
                        {{ group.groupName }}
                    </a-select-option>
                </a-select>
            </a-modal>
        </div>
    </a-card>
</template>



<script>
    import {allManufacturerList, getDeviceList, addDevice, delDevice, getGroupList, deviceAddGroup} from '@/services/dmgr'
    import {mapGetters} from 'vuex'
    import { Modal } from 'ant-design-vue';

    const columns = [
        {
            title: '设备名称',
            dataIndex: 'deviceName',
            key: 'deviceName',
        },
        {
            title: '设备编号',
            dataIndex: 'deviceCode',
            key: 'deviceCode',
        },
        {
            title: '设备ip',
            dataIndex: 'deviceIp',
            key: 'deviceIp',
        },
        {
            title: '是否在线',
            dataIndex: 'online',
            key: 'online',
            customRender: (text) => {
                return text? "在线" : "离线"
            },
        },
        {
            title: '厂商编号',
            dataIndex: 'deviceManufactor',
            key: 'deviceManufactor',
        },
        {
            title: '通道名称',
            key: 'checkChannel',
            dataIndex: 'checkChannel',
        },
        {
            title: '是否区分进出',
            key: 'distinguishInOut',
            dataIndex: 'distinguishInOut',
            customRender: (text) => {
                return text === 1? "是" : "否"
            },
        },
        {
            title: '进出标记',
            key: 'inOutFlag',
            dataIndex: 'inOutFlag',
            customRender: (text) => {
                return text === 1? "进" : "出"
            },
        },
        {
            title: '是否为考勤机',
            key: 'isAttend',
            dataIndex: 'isAttend',
            customRender: (text) => {
                return text === 1? "是" : "否"
            },
        },
        {
            title: '所属分组id',
            key: 'groupId',
            dataIndex: 'groupId',
        },
        {
            title: '所属厂商',
            key: 'manufacturerKey',
            dataIndex: 'manufacturerKey',
        },
        {
            title: '操作',
            key: 'action',
            scopedSlots: { customRender: 'action' },
        },
    ];



    export default {
        data() {
            return {
                selectedRowKeys: [],

                manufacturerList:[
                    {
                        manufacturerKey: 'yunqi',
                        manufacturerName: '云启'
                    }
                ],
                queryData:{
                    projectId: "",
                },
                data: [],
                columns,

                title: "编辑",
                visible: false,
                confirmLoading: false,
                labelCol: { span: 6 },
                wrapperCol: { span: 12 },
                form:{
                },

                selectGroupModel:{
                    title: "编辑",
                    visible: false,
                    confirmLoading: false,
                    selectGroupId: "",
                    selectDevice:{},

                },
                groupList: [],
            };
        },

        computed: {
            ...mapGetters('account', ['user']),
        },
        filters: {
        },

        created() {
            this.queryData.projectId = this.user.projectId
            this.getDeviceList()

        },
        methods: {
            getDeviceList() {
                getDeviceList(this.queryData).then(res => {
                    this.data = res.data.data;
                });
            },
            getGroupList(){
                getGroupList({projectId: this.queryData.projectId}).then(res => {
                    this.groupList = res.data.data;
                });
            },
            addModel() {

                this.form = {
                    deviceName: "" ,
                    deviceCode: "",
                    deviceIp: "",
                    deviceManufactor: "",
                    checkChannel: "",
                    distinguishInOut: "",
                    inOutFlag: "",
                    manufacturerKey: "",
                    isAttend: "",
                };
                this.visible = true;
            },
            modelOk(){
                this.confirmLoading = true;
                this.form.projectId = this.user.projectId
                addDevice(this.form).then(res => {
                    this.$message.success(res.data.msg);
                }).finally(() => {
                    this.modelCancel();
                })
            },
            modelCancel(){
                this.visible = false;
                this.confirmLoading = false;
                this.getDeviceList();
            },
            delDevice(record){
                let _that = this
                Modal.confirm({
                    title: () => '确定删除当前设备吗?',
                    // icon: () => createVNode(ExclamationCircleOutlined),
                    content: () => '删除后将无法恢复',

                    onOk() {
                        delDevice(record).then(res => {
                            _that.$message.success(res.data.msg);
                        }).finally(() => {
                            _that.getDeviceList();
                        })
                    },

                    onCancel() {},
                });

            },
            addGroup(record){
                this.selectGroupModel.selectDevice = record;
                this.getGroupList();
                this.selectGroupModel.visible = true

            },
            selectGroupChange(value){
                this.selectGroupModel.selectGroupId = value;
            },
            selectGroupModelOk(){
                this.selectGroupModel.selectDevice.groupId = this.selectGroupModel.selectGroupId
                this.deviceAddGroup();
                this.selectGroupModelCancel();
            },
            selectGroupModelCancel(){
                this.selectGroupModel.visible = false

            },
            onSelectChange(selectedRowKeys) {
                console.log('selectedRowKeys changed: ', selectedRowKeys);
                this.selectedRowKeys = selectedRowKeys;
            },
            deviceAddGroup(){
                console.log(this.selectGroupModel.selectDevice)
                deviceAddGroup(this.selectGroupModel.selectDevice).then(res => {
                    this.$message.success(res.data.msg);
                })
            },
        }
    };
</script>
<style scoped>

    .ant-advanced-search-form {
        padding: 24px;
        background: #fbfbfb;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        margin-bottom: 10px;
    }

    .ant-advanced-search-form .ant-form-item {
        display: flex;
    }

    .ant-advanced-search-form .ant-form-item-control-wrapper {
        flex: 1;
    }

    #components-form-demo-advanced-search .ant-form {
        max-width: none;
    }

    tr{
        background-color: #ffffff;
    }
</style>
