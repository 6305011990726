import { QUERY_OFFICE_BY_USER } from "@/services/api";
import { request } from "@/utils/request";
import { mapGetters } from "vuex";
import { ATTACHMENT_UPLOAD } from "@/services/api";

let options = [
  {label: "是",value: "1",},
  {label: "否",value: "0",},
];
// 基坑工程；高边坡工程；模板工程及支撑体系；脚手架工程；暗挖工程；拆除工程；有限空间；人防工程；天面工程；幕墙工程；钢结构工程；水下作业；水上平台；钢便桥；临时码头；装配式安装工程；人工挖孔桩；其他
const options1 = [
    { label: "基坑工程",value: "1"},
    { label: "高边坡工程",value: "2"},
    { label: "模板工程及支撑体系",value: "3"},
    { label: "脚手架工程",value: "4"},
    { label: "暗挖工程",value: "5"},
    { label: "拆除工程",value: "6"},
    { label: "有限空间",value: "7"},
    { label: "人防工程",value: "8"},
    { label: "天面工程",value: "9"},
    { label: "幕墙工程",value: "10"},
    { label: "钢结构工程",value: "11"},
    { label: "水下作业",value: "12"},
    { label: "水上平台",value: "13"},
    { label: "钢便桥",value: "14"},
    { label: "临时码头",value: "15"},
    { label: "装配式安装工程",value: "16"},
    { label: "人工挖孔桩",value: "17"},
    { label: "其他",value: "18"},
  
];
// 临边防护不到位、设备机具防护不到位、未按方案搭设、未进行安全技术交底、未使用或正确使用防护用品、基坑超挖、电箱接线不规范，其他
const options2 = [
    { label: "临边防护不到位",value: "1"},
    { label: "设备机具防护不到位",value: "2"},
    { label: "未按方案搭设",value: "3"},
    { label: "未进行安全技术交底",value: "4"},
    { label: "未使用或正确使用防护用品",value: "5"},
    { label: "基坑超挖",value: "6"},
    { label: "电箱接线不规范",value: "7"},
    { label: "其他",value: "8"},
]
// 物体打击、车辆伤害、机械伤害、起重伤害、淹溺、触电、灼烫、火灾、高处坠落、坍塌、冒顶片帮、透水、放炮、火药爆炸、瓦斯爆炸、锅炉爆炸、容器爆炸、其他爆炸、中毒和窒息、其他伤害
const options3 = [
    { label: "物体打击",value: "1"},
    { label: "车辆伤害",value: "2"},
    { label: "机械伤害",value: "3"},
    { label: "起重伤害",value: "4"},
    { label: "淹溺",value: "5"},
    { label: "触电",value: "6"},
    { label: "灼烫",value: "7"},
    { label: "火灾",value: "8"},
    { label: "高处坠落",value: "9"},
    { label: "坍塌",value: "10"},
    { label: "冒顶片帮",value: "11"},
    { label: "透水",value: "12"},
    { label: "放炮",value: "13"},
    { label: "火药爆炸",value: "14"},
    { label: "瓦斯爆炸",value: "15"},
    { label: "锅炉爆炸",value: "16"},
    { label: "容器爆炸",value: "17"},
    { label: "其他爆炸",value: "18"},
    { label: "中毒和窒息",value: "19"},
    { label: "其他伤害",value: "20"},
]
// 0.1；0.2；0.5；1；3；6；10
const options4 = [
    { label: "0.1",value: "0.1"},
    { label: "0.2",value: "0.2"},
    { label: "0.5",value: "0.5"},
    { label: "1",value: "1"},
    { label: "3",value: "3"},
    { label: "6",value: "6"},
    { label: "10",value: "10"},
]
// 0.5；1；2；3；6；10
const options5 = [
    { label: "0.5",value: "0.5"},
    { label: "1",value: "1"},
    { label: "2",value: "2"},
    { label: "3",value: "3"},
    { label: "6",value: "6"},
    { label: "10",value: "10"},
]
// 1；3；7；15；40；400
const options6 = [
    { label: "1",value: "1"},
    { label: "3",value: "3"},
    { label: "7",value: "7"},
    { label: "15",value: "15"},
    { label: "40",value: "40"},
    { label: "400",value: "400"},
]
const options7 = [
    { label: "一级",value: "1"},
    { label: "二级",value: "2"},
    { label: "三级",value: "3"},
    { label: "四级",value: "4"},
]
const calcAttach = (item,key) => {
  let attachList = [];
  if (item[key]) {
      attachList = item[key].split(',');
  } 
  attachList = attachList.map((i) => {
      let type = "img";
      // if i 为图片
      if (i.indexOf(".jpg") > -1 || i.indexOf(".png") > -1 || i.indexOf(".jpeg") > -1 || i.indexOf(".gif") > -1 ) {
          type = "img";
      } else if (i.indexOf(".pdf") > -1) {
          type = "pdf";
      } else {
          type = "file";
      }


      return {
          url: i,
          type: type,
      };
  });
  return attachList;
}
const columns = [
  {
      title: "序号",
      align: "center",
      scopedSlots: { customRender: "index" },
      width: 65,
  },
  {
      title: "公司名称",
      align: "center",
      dataIndex: "companyName",
      ellipsis: true,
      tooltip: true,
      width: 200,
  },
  {
      title: "分公司名称",
      align: "center",
      dataIndex: "branchOfficeName",
      ellipsis: true,
      tooltip: true,
      width: 200,
  },
  {
      title: "填报时间",
      align: "center",
      dataIndex: "reportTime",
      width: 120,
  },
  {
      title: "作业活动/项目名称",
      align: "center",
      dataIndex: "workName",
      ellipsis: true,
      tooltip: true,
      width: 200,
  },
  {
      title: "危险源辨识",
      
      align: "center",
    children: [
        {
            title: "危险源所在部位",
            dataIndex: "riskResourse",
            width: 150,
            align: "center",
        },
        {
            title: "风险特征描述",
            dataIndex: "riskAttr",
            width: 150,
            align: "center",
        },
        {
            title: "可能导致的事故类型",
            dataIndex: "accident",
            width: 180,
            align: "center",
        },
    ]
  },
  {
      title: "风险分析",
      
      align: "center",
    children: [
        {
            title: "L",
            dataIndex: "riskL",
            width: 120,
            align: "center",
        },
        {
            title: "E",
            dataIndex: "riskE",
            width: 120,
            align: "center",
        },
        {
            title: "C",
            dataIndex: "riskC",
            width: 120,
            align: "center",
        },
        {
            title: "D",
            dataIndex: "riskD",
            width: 120,
            align: "center",
        },
    ]
  },
  {
      title: "风险等级",
      dataIndex: "riskRank",
      width: 120,
      align: "center",
      sorter: (a, b) => a.riskRankType - b.riskRankType,
      defaultSortOrder: 'descend',
  },
  {
      title: "风险控制措施",
      children: [
        {
            title: "工程技术",
            dataIndex: "engineeringTech",
            width: 120,
            align: "center",
        },
        {
            title: "安全管理",
            dataIndex: "safetyManage",
            width: 120,
            align: "center",
        },
        {
            title: "培训内容",
            dataIndex: "cultivateContent",
            width: 120,
            align: "center",
        },
        {
            title: "个体防护",
            dataIndex: "personProtect",
            width: 120,
            align: "center",
        },
        {
            title: "应急处置",
            dataIndex: "emergencyResponse",
            width: 120,
            align: "center",
        },
    ]

  },
  {
      title: "风险控制责任人",
      children: [
        {
            title: "项目级指定负责人",
            dataIndex: "projectAppointHead",
            width: 170,
            align: "center",
            scopedSlots: { customRender: "projectAppointHead" },
        },
        {
            title: "项目级确认责任人",
            dataIndex: "projectConfirmHead",
            width: 170,
            align: "center",
            scopedSlots: { customRender: "projectConfirmHead" },

        },
        {
            title: "公司级指定负责人",
            dataIndex: "companyAppointHead",
            width: 170,
            align: "center",
            scopedSlots: { customRender: "companyAppointHead" },

        },
        {
            title: "公司级确认责任人",
            dataIndex: "companyConfirmHead",
            width: 170,
            align: "center",
            scopedSlots: { customRender: "companyConfirmHead" },

        },
    
    ]

  },

  {
      title: "风险覆盖状态",
      dataIndex: "riskCoverState",
      scopedSlots: { customRender: "riskCoverState" },

      align: "center",
      width: 120,
  },
  {
      title: "风险控制措施落实情况",
      dataIndex: "riskControlState",
      align: "center",
      width: 120,
  },
  
  {
      title: "操作",
      align: "center",
      fixed: "right",
      width: 160,
      scopedSlots: { customRender: "action" },
  },
];

export default {
  data() {
    return {
        gcglbList: [],
        onlyGcglbList: [],
        options,
        options1,
        options2,
        options3,
        options4,
        options5,
        options6,
        options7,

        columns,

        filePath1: [],
        uploading1: false,
        accept1: "",
    };
  },
  computed: {
    // ...mapGetters("account", ["user"]),
    // isBranchOffice() { //分公司
    //     return this.user.isGcglb == 1 && this.user.loginType == 1
    // },
    // isProject() { //项目部
    //     return this.user.loginType == 0
    // },
    // isSafe() { //公司
    //     return this.user.isGcglb == 0 && this.user.loginType == 1
    // },
    // isProjectLeader(){
    //     // user.nowRoleKey 包含 'project_leader'
    //     return this.user.nowRoleKey.includes('project_leader')
    // },
    // isProjectSafer() {
    //     // user.nowRoleKey 包含 'PROJECT_AQY_ZB_FZR'
    //     return this.user.nowRoleKey.includes('PROJECT_AQY_ZB_FZR')
    // },
    // isJiTuan() {
    //     // CLIGUE_AQMGLY 集团
    //       return this.user.nowRoleKey.includes('CLIGUE_AQMGLY')
    // },
    riskD() {
        if(this.params.riskC && this.params.riskL && this.params.riskE) {
            return this.params.riskC * this.params.riskL * this.params.riskE
        } else {
            return ''
        }
    }
  },
  created() {
    request(QUERY_OFFICE_BY_USER, "get").then((res) => {
      let data = res.data.data;
      const arr1 = []
      data.forEach((item) => {
       
        arr1.push({
          label: item.master,
          value: item.id,
        });
      });

      this.onlyGcglbList = arr1;
    });
  },
  methods: {
    calcCount(list,item,index, paramKey) {
      
      let reCount;
      if (index > 0 && item[paramKey] === list[index - 1][paramKey]) {
          reCount = 0;
      } else {
          let count = 1;
          for (let i = index + 1; i < list.length; i++) {
              if (item[paramKey] === list[i][paramKey]) {
                  count++;
              } else {
                  break;
              }
          }
          reCount = count;
      }
      return reCount;
    
    },
    calcAttach,
    handlePreview(file) {
        window.open(file.url);
    },
    beforeUpload1(file) {
        // const accept = '.' + file.name.substring(file.name.lastIndexOf(".") + 1);
        // if (this.accept1.split(',').indexOf(accept) == -1) {
        //   this.$message.error("只能上传PDF文件");
        //   return false
        // }
      },
      handleUploadChange1(info) {
        if (info.file.status === "uploading") {
          this.uploading1 = true;
          return;
        }
        if (info.file.status === "done") {
          this.uploading1 = false;
        }
        if (info.file.status === "removed") {
          this.filePath1 = info.fileList
        }
      },
      resourceUpload1(value) {
        const formData = new FormData();
        formData.append("file", value.file);
        request(ATTACHMENT_UPLOAD, "post", formData).then((result) => {
          if (result.data.code === 0) {
            this.$message.success("上传成功!");
            this.filePath1.push({
              uid: value.file.uid,
              url: result.data.data.path,
              name: value.file.name,
              status: 'done',
            });
            value.onSuccess(result.data.data.path, value.file);
          } else {
            this.$message.warning(result.data.message);
          }
        });
      },
  },
};
