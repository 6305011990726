var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a_Approval" },
    [
      _c(
        "a-form-model",
        {
          staticClass: "clearfix",
          attrs: { model: _vm.params },
          on: { submit: _vm.handleSubmit },
        },
        [
          _c(
            "a-col",
            { attrs: { span: 5 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "统计方式",
                    labelCol: { span: 8 },
                    wrapperCol: { span: 14, offset: 1 },
                  },
                },
                [
                  _c(
                    "a-radio-group",
                    {
                      model: {
                        value: _vm.dateType,
                        callback: function ($$v) {
                          _vm.dateType = $$v
                        },
                        expression: "dateType",
                      },
                    },
                    [
                      _c("a-radio-button", { attrs: { value: "a" } }, [
                        _vm._v(" 月份 "),
                      ]),
                      _c("a-radio-button", { attrs: { value: "b" } }, [
                        _vm._v(" 年份 "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 4 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.dateType == "a" ? "月份" : "年份",
                    labelCol: { span: 4 },
                    wrapperCol: { span: 10, offset: 0 },
                  },
                },
                [
                  _vm.dateType == "a"
                    ? _c("a-month-picker", {
                        staticStyle: { width: "140px" },
                        model: {
                          value: _vm.params.startDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "startDate", $$v)
                          },
                          expression: "params.startDate",
                        },
                      })
                    : _c("a-date-picker", {
                        staticStyle: { width: "140px" },
                        attrs: {
                          mode: "year",
                          placeholder: "请输入年份",
                          format: "YYYY",
                          open: _vm.isOpen,
                        },
                        on: {
                          openChange: _vm.monthOpenChangeOne,
                          panelChange: _vm.monthPanelChangeOne,
                        },
                        model: {
                          value: _vm.params.startDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "startDate", $$v)
                          },
                          expression: "params.startDate",
                        },
                      }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 4 } },
            [
              _c(
                "a-space",
                { staticClass: "btnBox" },
                [
                  _c(
                    "a-button",
                    { attrs: { type: "primary", "html-type": "submit" } },
                    [_vm._v(" 查询 ")]
                  ),
                  _c("a-button", { on: { click: _vm.resetQuery } }, [
                    _vm._v(" 重置 "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mb-2", staticStyle: { display: "flex" } },
        [
          _vm.isSafe
            ? _c(
                "a-button",
                {
                  staticClass: "mr-2",
                  attrs: { type: "primary" },
                  on: { click: _vm.addNewComplaint },
                },
                [_vm._v(" 新增 ")]
              )
            : _vm._e(),
          _c(
            "a-button",
            {
              staticClass: "mr-2",
              attrs: { type: "primary" },
              on: { click: _vm.handleDownload },
            },
            [_vm._v(" 导出 ")]
          ),
          _c(
            "a-button",
            {
              staticClass: "mr-2",
              attrs: { type: "primary" },
              on: { click: _vm.bathDownload },
            },
            [_vm._v(" 批量下载 ")]
          ),
        ],
        1
      ),
      _c(
        "a-tabs",
        {
          attrs: { "default-active-key": "1" },
          on: { change: _vm.changeTab },
          model: {
            value: _vm.params.type,
            callback: function ($$v) {
              _vm.$set(_vm.params, "type", $$v)
            },
            expression: "params.type",
          },
        },
        [
          _c("a-tab-pane", { key: "1", attrs: { tab: "公司" } }),
          _c("a-tab-pane", { key: "2", attrs: { tab: "集团" } }),
        ],
        1
      ),
      _c("standard-table", {
        attrs: {
          columns: _vm.columns,
          dataSource: _vm.tableData,
          rowKey: "noticeid",
          pagination: _vm.pagination,
          loading: _vm.tableLoading,
          isAlert: false,
          bordered: true,
          selectedRows: _vm.selectedRows,
        },
        on: {
          change: _vm.handleChangePage,
          "update:selectedRows": function ($event) {
            _vm.selectedRows = $event
          },
          "update:selected-rows": function ($event) {
            _vm.selectedRows = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function (ref) {
              var index = ref.index
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      (_vm.pagination.current - 1) * _vm.pagination.pageSize +
                        parseInt(index) +
                        1
                    )
                  ),
                ]),
              ]
            },
          },
          {
            key: "attach",
            fn: function (ref) {
              var record = ref.record
              return [
                _c(
                  "div",
                  { staticClass: "flex j-center" },
                  _vm._l(record.attachList, function (item, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        item.type == "img"
                          ? _c("img", {
                              staticStyle: { width: "32px" },
                              attrs: { alt: "", src: item.url },
                              on: {
                                click: function ($event) {
                                  return _vm.handlePreview(item)
                                },
                              },
                            })
                          : item.type == "pdf"
                          ? _c("a-icon", {
                              style: { fontSize: "32px" },
                              attrs: { type: "file-pdf" },
                              on: {
                                click: function ($event) {
                                  return _vm.handlePreview(item)
                                },
                              },
                            })
                          : _c("a-icon", {
                              style: { fontSize: "32px" },
                              attrs: { type: "file-text" },
                              on: {
                                click: function ($event) {
                                  return _vm.handlePreview(item)
                                },
                              },
                            }),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]
            },
          },
          {
            key: "action",
            fn: function (ref) {
              var record = ref.record
              return [
                _c("div", { staticClass: "sTables" }, [
                  _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.handleEdit(record)
                        },
                      },
                    },
                    [_vm._v("编辑")]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.$route.meta.isBreak,
                          expression: "!$route.meta.isBreak",
                        },
                      ],
                      on: {
                        click: function ($event) {
                          return _vm.handleDel(record)
                        },
                      },
                    },
                    [_vm._v(" 删除 ")]
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }