<template>
  <div class="standard-table">
    <div class="header">
      <!-- 表格按钮 -->
      <a-space>
        <template v-for="(btn) in btnArr">
          <a-button v-if="!btn.hidden" :key="btn.name" v-bind="btn.attrs" @click="btn.click">{{ btn.name }}</a-button>
        </template>
        <!-- 跟在按钮组后面的 -->
        <slot name="btnAfter"></slot>
      </a-space>
      <!-- 表格标题 -->
      <div>
        <slot name="tableTitle"></slot>
      </div>
    </div>

    <div class="alert" v-if="isAlert">
      <a-alert type="info" :show-icon="true" v-if="selectedRows">
        <div class="message" slot="message">
          已选择&nbsp;<a>{{selectedRows.length}}</a>&nbsp;项 <a class="clear" @click="onClear">清空</a>
        </div>
      </a-alert>
    </div>
    <div v-if="totalBoolean" class="total" >
      共{{ pagination.total }}个项目
    </div>

    <a-table
      style="margin-top: 12px;"
      :rowClassName="rowClassName"
      :bordered="bordered"
      :loading="loading"
      :columns="insideColumns"
      :dataSource="dataSource"
      :rowKey="rowKey"
      :pagination="pagination"
      :expandedRowKeys="expandedRowKeys"
      :expandedRowRender="expandedRowRender"
      :checkBoxDisable="checkBoxDisable"
      :size="tableSize"
      :scroll="scroll"
      @change="onChange"
      :rowSelection="selectedRows ? {selectedRowKeys: selectedRowKeys, onChange: updateSelect,getCheckboxProps:getCheckboxProps,onSelect:onSelect} : undefined"
    >
      <template slot-scope="text, record, index" :slot="slot" v-for="slot in Object.keys($scopedSlots).filter(key => key !== 'expandedRowRender') ">
        <slot :name="slot" v-bind="{text, record, index}"></slot>
      </template>
      <template :slot="slot" v-for="slot in Object.keys($slots)">
        <slot :name="slot"></slot>
      </template>
      <template slot-scope="record, index, indent, expanded" :slot="$scopedSlots.expandedRowRender ? 'expandedRowRender' : ''">
        <slot v-bind="{record, index, indent, expanded}" :name="$scopedSlots.expandedRowRender ? 'expandedRowRender' : ''"></slot>
      </template>
    </a-table>
  </div>
</template>

<script>
export default {
  name: 'StandardTable',
  props: {
    totalBoolean:Boolean, //项目总数显示
    bordered: Boolean,
    loading: [Boolean, Object],
    columns: Array,
    dataSource: Array,
    rowKey: {
      type: [String, Function],
      default: 'key'
    },
    tableSize:{
      type:String,
      default:'default'
    },
    scroll:{
      type:Object,
      require:false
    },
    pagination: {
      type: [Object, Boolean],
      default: true
    },
    checkBoxDisable:{
      type:Object,
      require:false
    },
    isAlert:{
      type:Boolean,
      default:true
    },
    rowColor: { // 隔行变色
      type:Boolean,
      default:true
    },
    selectedRows: Array,
    expandedRowKeys: Array,
    expandedRowRender: Function,
    // 表格按钮组
    btnArr: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      needTotalList: [],
      insideColumns: []
    }
  },
  methods: {
    // 处理columns
    prepareColumns() {
      this.insideColumns = this.columns.map((col) => {
        // 开启tooltip
        if (col.tooltip && !col.scopedSlots && !col.customRender) {
          col.customRender = this.insideCustomRender
        }
        return col
      })
    },
    // 内部的column渲染
    insideCustomRender(text) {
      return {
        children: (
          <a-tooltip placement="topLeft" title={text}>
            <div class="overtext-1">
              {text}
            </div>
          </a-tooltip>
        )
      }
    },
    updateSelect (selectedRowKeys, selectedRows) {
      this.$emit('update:selectedRows', selectedRows)
      this.$emit('selectedRowChange', selectedRowKeys, selectedRows)
    },
    getCheckboxProps(recode){
      if(typeof this.checkBoxDisable === 'undefined'){
        return {}
      }else{
        return ({
          props:{
            disabled: recode[this.checkBoxDisable.key]==this.checkBoxDisable.value||(typeof recode[this.checkBoxDisable.key] === 'undefined')
          }
        })
      }
      
    },
    initTotalList (columns) {
      const totalList = columns.filter(item => item.needTotal)
        .map(item => {
          return {
            ...item,
            total: 0
          }
        })
      return totalList
    },
    onClear() {
      this.updateSelect([], [])
      this.$emit('clear')
    },
    onChange(pagination, filters, sorter, {currentDataSource}) {
      this.$emit('change', pagination, filters, sorter, {currentDataSource})
    },
    onSelect(record,selected,selectedRows){
      this.$emit('rowSelect',record,selected,selectedRows)
    },
    // 隔行变色
    rowClassName(record, index) {
      if (this.rowColor) {
      let className = 'light';
      if (index % 2 === 0) className = '';
      return className;
     }
    }
  },
  created () {
    this.prepareColumns()
    this.needTotalList = this.initTotalList(this.insideColumns)
  },
  watch: {
    selectedRows (selectedRows) {
      this.needTotalList = this.needTotalList.map(item => {
        return {
          ...item,
          total: selectedRows.reduce((sum, val) => {
            let v
            try{
              v = val[item.dataIndex] ? val[item.dataIndex] : eval(`val.${item.dataIndex}`);
            }catch(_){
              v = val[item.dataIndex];
            }
            v = !isNaN(parseFloat(v)) ? parseFloat(v) : 0;
            return sum + v
          }, 0)
        }
      })
    },
    columns () {
      this.prepareColumns()
    }
  },
  computed: {
    selectedRowKeys() {
      return this.selectedRows.map(record => {
        return (typeof this.rowKey === 'function') ? this.rowKey(record) : record[this.rowKey]
      })
    },
  }
}
</script>

<style scoped lang="less">
.standard-table{
  position: relative;
  /deep/.ant-table-thead > tr > th{
    text-align: center;
  }
  
  .total{
    position: absolute;
    top: -40px;
    left: 27px;
    font-size: 14px;
    color: black;
  }
  .alert{
    margin-bottom: 16px;
    .message{
      a{
        font-weight: 600;
      }
    }
    .clear{
      float: right;
    }
  }
  /deep/ .ant-table .light{
      background-color: #FAFCFF;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }
}
</style>
