var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "api" },
    [
      _vm._m(0),
      _c("api-table", { attrs: { "api-source": _vm.apiSource } }),
      _c("api-table", {
        attrs: { type: "event", title: "事件", "api-source": _vm.events },
      }),
      _c("api-table", {
        attrs: { title: "Column", "api-source": _vm.columnApi },
      }),
      _c("api-table", {
        attrs: { title: "Search", "api-source": _vm.searchApi },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "introduce" }, [
      _c("h2", { staticClass: "title" }, [_vm._v("说明")]),
      _c("p", { staticClass: "content" }, [
        _vm._v(
          " AdvanceTable 是基于 Ant Design Vue Table 组件封装，支持其所有 API。"
        ),
        _c("br"),
        _vm._v(" 主要添加了"),
        _c("em", [_vm._v("列设置")]),
        _vm._v("及"),
        _c("em", [_vm._v("搜索控件配置")]),
        _vm._v(
          "的功能，可用于一些需要动态配置表格展示、动态配置搜索条件的场景。"
        ),
        _c("br"),
        _vm._v(" 使用方式 与 antd table 基本无异。添加了部分API，如下： "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }