var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: "1400px", title: _vm.title, footer: null },
      on: { cancel: _vm.close },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "a-form-model",
        { ref: "ruleForm", attrs: { model: _vm.params, rules: _vm.rules } },
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "公司",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: { disabled: "", placeholder: "" },
                        model: {
                          value: _vm.params.companyName,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "companyName", $$v)
                          },
                          expression: "params.companyName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "分公司",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: { disabled: "", placeholder: "" },
                        model: {
                          value: _vm.params.branchOfficeName,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "branchOfficeName", $$v)
                          },
                          expression: "params.branchOfficeName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "项目名称",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: { disabled: "", placeholder: "" },
                        model: {
                          value: _vm.params.projectName,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "projectName", $$v)
                          },
                          expression: "params.projectName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "危大工程类别",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                        prop: "gclbId",
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "240px" },
                          attrs: { allowClear: "", placeholder: "请选择 " },
                          model: {
                            value: _vm.params.gclbId,
                            callback: function ($$v) {
                              _vm.$set(_vm.params, "gclbId", $$v)
                            },
                            expression: "params.gclbId",
                          },
                        },
                        _vm._l(_vm.options1, function (item) {
                          return _c(
                            "a-select-option",
                            {
                              key: item.value,
                              attrs: { value: item.value, title: item.label },
                            },
                            [_vm._v(" " + _vm._s(item.label) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "危大工程名称",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入 " },
                        model: {
                          value: _vm.params.sgfaName,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "sgfaName", $$v)
                          },
                          expression: "params.sgfaName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "是否超一定规模",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                        prop: "overRange",
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "240px" },
                          attrs: { allowClear: "", placeholder: "请选择 " },
                          model: {
                            value: _vm.params.overRange,
                            callback: function ($$v) {
                              _vm.$set(_vm.params, "overRange", $$v)
                            },
                            expression: "params.overRange",
                          },
                        },
                        _vm._l(_vm.options, function (item) {
                          return _c(
                            "a-select-option",
                            {
                              key: item.value,
                              attrs: { value: item.value, title: item.label },
                            },
                            [_vm._v(" " + _vm._s(item.label) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "危大工程进度",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                      },
                    },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.params.gcproessCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.params, "gcproessCode", $$v)
                            },
                            expression: "params.gcproessCode",
                          },
                        },
                        [
                          _c("a-radio", { attrs: { value: 1 } }, [
                            _vm._v("未开始"),
                          ]),
                          _c("a-radio", { attrs: { value: 2 } }, [
                            _vm._v("持续中"),
                          ]),
                          _c("a-radio", { attrs: { value: 3 } }, [
                            _vm._v("已完成"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "计划实施时间",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                      },
                    },
                    [
                      _c("a-date-picker", {
                        attrs: { valueFormat: "YYYY-MM-DD" },
                        model: {
                          value: _vm.params.planTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "planTime", $$v)
                          },
                          expression: "params.planTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "专项施工方案",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                      },
                    },
                    [
                      _c(
                        "a-radio-group",
                        {
                          model: {
                            value: _vm.params.zxsgfa,
                            callback: function ($$v) {
                              _vm.$set(_vm.params, "zxsgfa", $$v)
                            },
                            expression: "params.zxsgfa",
                          },
                        },
                        [
                          _c("a-radio", { attrs: { value: "1" } }, [
                            _vm._v("是"),
                          ]),
                          _c("a-radio", { attrs: { value: "0" } }, [
                            _vm._v("否"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "填报时间",
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 14 },
                      },
                    },
                    [
                      _c("a-date-picker", {
                        attrs: { valueFormat: "YYYY-MM-DD" },
                        model: {
                          value: _vm.params.tbTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "tbTime", $$v)
                          },
                          expression: "params.tbTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { position: "relative" } },
        [
          _vm.isEdit != 2
            ? _c(
                "a-button",
                {
                  staticClass: "y-ayh",
                  attrs: { type: "primary" },
                  on: { click: _vm.add },
                },
                [_vm._v(" 添加施工过程 ")]
              )
            : _vm._e(),
          _c(
            "a-tabs",
            {
              attrs: { type: "editable-card", "hide-add": "" },
              on: { edit: _vm.onEdit },
              model: {
                value: _vm.activeKey,
                callback: function ($$v) {
                  _vm.activeKey = $$v
                },
                expression: "activeKey",
              },
            },
            _vm._l(_vm.panes, function (pane, index) {
              return _c(
                "a-tab-pane",
                {
                  key: index,
                  attrs: {
                    tab: "危大工程" + (index + 1),
                    closable: pane.closable,
                  },
                },
                [
                  _c(
                    "a-form-model",
                    { attrs: { model: pane, rules: _vm.rulesPane } },
                    [
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: "危大工程内容",
                                    "label-col": { span: 6 },
                                    "wrapper-col": { span: 14 },
                                  },
                                },
                                [
                                  _c("a-input", {
                                    attrs: { placeholder: "" },
                                    model: {
                                      value: pane.conetent,
                                      callback: function ($$v) {
                                        _vm.$set(pane, "conetent", $$v)
                                      },
                                      expression: "pane.conetent",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: "实施状态",
                                    "label-col": { span: 6 },
                                    "wrapper-col": { span: 14 },
                                  },
                                },
                                [
                                  _c(
                                    "a-radio-group",
                                    {
                                      model: {
                                        value: pane.ipmlCode,
                                        callback: function ($$v) {
                                          _vm.$set(pane, "ipmlCode", $$v)
                                        },
                                        expression: "pane.ipmlCode",
                                      },
                                    },
                                    [
                                      _c("a-radio", { attrs: { value: 1 } }, [
                                        _vm._v("未开始"),
                                      ]),
                                      _c("a-radio", { attrs: { value: 2 } }, [
                                        _vm._v("持续中"),
                                      ]),
                                      _c("a-radio", { attrs: { value: 3 } }, [
                                        _vm._v("已完成"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: "填报时间",
                                    "label-col": { span: 6 },
                                    "wrapper-col": { span: 14 },
                                  },
                                },
                                [
                                  _c("a-date-picker", {
                                    attrs: { valueFormat: "YYYY-MM-DD" },
                                    model: {
                                      value: pane.tbTime,
                                      callback: function ($$v) {
                                        _vm.$set(pane, "tbTime", $$v)
                                      },
                                      expression: "pane.tbTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: "验收情况",
                                    "label-col": { span: 6 },
                                    "wrapper-col": { span: 14 },
                                  },
                                },
                                [
                                  _c(
                                    "a-radio-group",
                                    {
                                      model: {
                                        value: pane.ysstatus,
                                        callback: function ($$v) {
                                          _vm.$set(pane, "ysstatus", $$v)
                                        },
                                        expression: "pane.ysstatus",
                                      },
                                    },
                                    [
                                      _c("a-radio", { attrs: { value: "1" } }, [
                                        _vm._v("已验收"),
                                      ]),
                                      _c("a-radio", { attrs: { value: "2" } }, [
                                        _vm._v(" 未验收 "),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: "负责人",
                                    "label-col": { span: 6 },
                                    "wrapper-col": { span: 14 },
                                  },
                                },
                                [
                                  _c("a-input", {
                                    attrs: { placeholder: "请输入 " },
                                    model: {
                                      value: pane.managename,
                                      callback: function ($$v) {
                                        _vm.$set(pane, "managename", $$v)
                                      },
                                      expression: "pane.managename",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: "负责人电话",
                                    "label-col": { span: 6 },
                                    "wrapper-col": { span: 14 },
                                    prop: "managephone",
                                  },
                                },
                                [
                                  _c("a-input", {
                                    attrs: {
                                      type: "number",
                                      placeholder: "请输入 ",
                                    },
                                    model: {
                                      value: pane.managephone,
                                      callback: function ($$v) {
                                        _vm.$set(pane, "managephone", $$v)
                                      },
                                      expression: "pane.managephone",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 20 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: "验收表",
                                    "label-col": { span: 2 },
                                    "wrapper-col": { span: 14 },
                                    prop: "gclbId",
                                  },
                                },
                                [
                                  _c(
                                    "a-upload",
                                    {
                                      staticClass: "avatar-uploader",
                                      attrs: {
                                        "list-type": "picture-card",
                                        accept: _vm.accept1,
                                        "file-list": pane.fileList,
                                        customRequest:
                                          _vm.resourceUpload1(pane),
                                        beforeUpload: _vm.beforeUpload1,
                                        remove: _vm.handleRemove1(pane),
                                      },
                                      on: {
                                        change: _vm.handleUploadChange1,
                                        preview: _vm.handlePreview,
                                      },
                                    },
                                    [
                                      pane.fileList.length < 1
                                        ? _c(
                                            "div",
                                            [
                                              _c("a-icon", {
                                                attrs: {
                                                  type: _vm.uploading1
                                                    ? "loading"
                                                    : "plus",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm.isEdit != 2
        ? _c(
            "a-row",
            [
              _c("a-col", { attrs: { span: 12 } }),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        "label-col": { span: 4 },
                        "wrapper-col": { span: 14 },
                        prop: "year",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex flex-row-reverse" },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.sbloading,
                              },
                              on: { click: _vm.handleSubmit },
                            },
                            [_vm._v("保存")]
                          ),
                          _c(
                            "a-button",
                            { staticClass: "mr-2", on: { click: _vm.close } },
                            [_vm._v("返回")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }