import { ADMIN } from '@/services/api'
import {request, METHOD, removeAuthorization} from '@/utils/request'

// const BASE_URL = process.env.VUE_APP_API_BASE_URL + '/safe'
const ADMIN_API = `${ADMIN}/safe`

/**
 *  获取创优列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getExcellentList(params) {
    return request(ADMIN_API + '/labourProjectExcellent/getExcellentList', METHOD.POST, params)
}

/**
 * 查询项目分类-地图
 * @param {*} 
 * @returns
 */
export async function getProjectCatalogMapStat(params) {
    return request(ADMIN_API + `/aqxjProjects/getProjectCatalogMapStat`, METHOD.GET, params)
}

/**
 * 查询项目分类统计
 * @param {*} 
 * @returns
 */
export async function getProjectCatalogStat(params) {
    return request(ADMIN_API + `/aqxjProjects/getProjectCatalogStat`, METHOD.GET, params)
}

/**
 * 查询项目分布情况
 * @param {*} 
 * @returns
 */
export async function getProjectAreaStat(params) {
    return request(ADMIN_API + `/aqxjProjects/getProjectAreaStat`, METHOD.GET, params)
}

/**
 * 查询项目检查情况统计
 * @param {*} 
 * @returns
 */
export async function getProjectCheckStat(params) {
    return request(ADMIN_API + `/aqxjProjects/getProjectCheckStat`, METHOD.GET, params)
}

/**
 * 危大工程情况
 * @param {*} 
 * @returns
 */
export async function getwdStatusData(params) {
    return request(ADMIN_API + `/thdj/stat`, METHOD.POST, params)
}

/**
 * 预警信息
 * @param {*} 
 * @returns
 */
export async function getEarlyWarnData(params) {
    return request(ADMIN_API + `/thdj/getEarlyWarnData`, METHOD.POST, params)
}

/**
 * 隐患总数-柱状图数据
 * @param {*} 
 * @returns
 */
export async function getBarChartData(params) {
    return request(ADMIN_API + `/aqxjPlan/findProbTotal/stat`, METHOD.POST, params)
}


/**
 * 获取创优详情
 * @param {*} projectId
 * @returns
 */
export async function getExcellentDetail(projectId) {
    return request(ADMIN_API + `/labourProjectExcellent/project/detail/${projectId}`, METHOD.GET)
}
export async function getExcellentDetailByexcellentId(projectId) {
    return request(ADMIN_API + `/labourProjectExcellent/detail/${projectId}`, METHOD.GET)
}
/**
 * 新增或编辑创优
 * @param {*} data
 * @returns
 */
export async function saveOrUpdateExcellent(data) {
    return request(ADMIN_API + '/labourProjectExcellent/saveOrUpdateExcellent', METHOD.POST, data)
}
export async function updateExcellent(data) {
    return request(ADMIN_API + '/labourProjectExcellent/updateExcellent', METHOD.POST, data)
}


/**
 *  获取创优获奖台账列表
 * 
 */
export async function getExcellentLedgerList(data) {
    return request(ADMIN_API + '/labourProjectExcellent/getExcellentLedgerList', METHOD.POST, data)
}

/**
 * 获取创优证书字典
 */
export async function getExcellentCerDic(data) {
    return request(ADMIN_API + '/projectExcellentCertificateDic/getExcellentCerDic', METHOD.POST, data)
}

/**
 * 获取创优证书字典
 */
export async function insertExcellentCerDic(data) {
    return request(ADMIN_API + '/projectExcellentCertificateDic/insert', METHOD.POST, data)
}

export const LabourProjectExcellentExportUrl = ADMIN_API + '/labourProjectExcellent/getExcellentExport'
export const ExcellentExportLedgerUrl = ADMIN_API + '/labourProjectExcellent/getExcellentLedgerExport'

/**
 * 上传证书
 */
export async function excellentLedgerImport(data) {
    return request(ADMIN_API + '/labourProjectExcellent/excellentLedgerImport', METHOD.POST, data)
}


/**
 * 首页统计信息
 */
export async function getAwardList(data) {
    return request(ADMIN_API + '/labourProjectExcellent/excellentStatic', METHOD.POST, data)
}

/**
 * 首页统计信息-检评
 */
export async function getAwardMonList(data) {
    return request(ADMIN_API + '/labourProjectExcellent/excellentStaticCheck', METHOD.POST, data)
}

/**
 * 项目创优首页统计-按证书统计
 */
export async function getAwardMonStatic(data) {
    return request(ADMIN_API + '/labourProjectExcellent/excellentCertificateStatic', METHOD.POST, data)
}

/**
 * 市政项目首页信息
 */
export async function getProjectDetail() {
    return request(ADMIN_API + '/aqxjProjects/project/detail', METHOD.GET)
}

/**
 * 市政项目首页-隐患排查情况
 */
export async function getProblemStat() {
    return request(ADMIN_API + '/aqxjProblem/stat', METHOD.GET)
}

/**
 * 市政项目首页-风险分级管控
 */
export async function getRiskControlStat() {
    return request(ADMIN_API + '/thdj/getRiskControlStat', METHOD.GET)
}

/**
 * 市政项目首页-查询危大工程情况
 */
export async function getWdgcThdjStat(data) {
    return request(ADMIN_API + '/thdj/stat', METHOD.POST, data)
}

/**
 * 市政项目首页-特种机械设备
 */
export async function getFacilityStatusStatic(data) {
    return request(ADMIN_API + '/facilityInfo/getFacilityStatusStatic', METHOD.POST, data)
}