var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "constructiontTable_index" },
    [
      _c(
        "a-row",
        { attrs: { gutter: 16 } },
        [
          _c(
            "a-col",
            [
              _c(
                "a-card",
                {
                  staticStyle: { "min-height": "900px" },
                  style: "overflow:auto",
                  attrs: { bordered: false },
                },
                [
                  _c(
                    "a-tabs",
                    {
                      attrs: { forceRender: "" },
                      on: { change: _vm.callback },
                      model: {
                        value: _vm.activeKey,
                        callback: function ($$v) {
                          _vm.activeKey = $$v
                        },
                        expression: "activeKey",
                      },
                    },
                    [
                      _c(
                        "a-tab-pane",
                        { key: "1", attrs: { tab: "基本信息" } },
                        [
                          _c("projectBasic", {
                            attrs: { projectId: _vm.projectId },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-tab-pane",
                        { key: "2", attrs: { tab: "参建单位" } },
                        [
                          _c("unitTabel", {
                            attrs: { projectId: _vm.projectId },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-tab-pane",
                        { key: "3", attrs: { tab: "管理人员" } },
                        [
                          _c("managementList", {
                            attrs: {
                              projectId: _vm.projectId,
                              officeId: _vm.officeId,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-tab-pane",
                        { key: "4", attrs: { tab: "场地分区" } },
                        [
                          _c("accessControl", {
                            attrs: {
                              projectId: _vm.projectId,
                              officeId: _vm.officeId,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-tab-pane",
                        { key: "5", attrs: { tab: "风控规则" } },
                        [
                          _c("riskControlRules", {
                            attrs: { projectId: _vm.projectId },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-tab-pane",
                        { key: "6", attrs: { tab: "项目参数设置" } },
                        [
                          _vm.activeKey === "6"
                            ? _c("projectConfig", {
                                attrs: { projectId: _vm.projectId },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.user.nowRoleKey.includes("OFFICE_JSFWZY")
                        ? _c(
                            "a-tab-pane",
                            { key: "8", attrs: { tab: "考勤数据上报" } },
                            [
                              _vm.activeKey === "8"
                                ? _c("attendnceSettings", {
                                    attrs: { projectId: _vm.projectId },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }