var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-card",
        {
          staticClass: "card",
          attrs: { title: _vm.$t("repository"), bordered: false },
        },
        [
          _c("repository-form", {
            ref: "repository",
            attrs: { showSubmit: false },
          }),
        ],
        1
      ),
      _c(
        "a-card",
        {
          staticClass: "card",
          attrs: { title: _vm.$t("task"), bordered: false },
        },
        [_c("task-form", { ref: "task", attrs: { showSubmit: false } })],
        1
      ),
      _c(
        "a-card",
        { attrs: { title: _vm.$t("user"), bordered: false } },
        [_c("user-form")],
        1
      ),
      _c(
        "footer-tool-bar",
        [
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.validate },
            },
            [_vm._v(_vm._s(_vm.$t("submit")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }