var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "plug-Overview" }, [_vm._v("风控管理")]),
      _c(
        "a-card",
        {
          staticClass: "risk_container",
          attrs: { title: "工人年龄限制", bordered: false },
        },
        [
          _c("div", { staticClass: "risk-rules" }, [
            _c(
              "div",
              [
                _c(
                  "a-checkbox",
                  { attrs: { checked: _vm.form.age.tg ? true : false } },
                  [
                    _c("span", { staticClass: "text-color" }, [
                      _vm._v("童工年龄限制，童工指未满 "),
                    ]),
                  ]
                ),
                _c(
                  "a-input",
                  {
                    staticStyle: { width: "120px" },
                    attrs: { placeholder: "18" },
                    model: {
                      value: _vm.form.age.tg,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.age, "tg", $$v)
                      },
                      expression: "form.age.tg",
                    },
                  },
                  [
                    _c(
                      "a-tooltip",
                      { attrs: { slot: "suffix" }, slot: "suffix" },
                      [
                        _c("span", { staticClass: "text-color" }, [
                          _vm._v("周岁"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "a-checkbox",
                  { attrs: { checked: _vm.form.age.man ? true : false } },
                  [
                    _c("span", { staticClass: "text-color" }, [
                      _vm._v("男性退休年龄限制，退休年龄为 "),
                    ]),
                  ]
                ),
                _c(
                  "a-input",
                  {
                    staticStyle: { width: "120px" },
                    attrs: { placeholder: "60" },
                    model: {
                      value: _vm.form.age.man,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.age, "man", $$v)
                      },
                      expression: "form.age.man",
                    },
                  },
                  [
                    _c(
                      "a-tooltip",
                      { attrs: { slot: "suffix" }, slot: "suffix" },
                      [
                        _c("span", { staticClass: "text-color" }, [
                          _vm._v("周岁"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "a-checkbox",
                  { attrs: { checked: _vm.form.age.women ? true : false } },
                  [
                    _c("span", { staticClass: "text-color" }, [
                      _vm._v("女性退休年龄限制，退休年龄为 "),
                    ]),
                  ]
                ),
                _c(
                  "a-input",
                  {
                    staticStyle: { width: "120px" },
                    attrs: { placeholder: "50" },
                    model: {
                      value: _vm.form.age.women,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.age, "women", $$v)
                      },
                      expression: "form.age.women",
                    },
                  },
                  [
                    _c(
                      "a-tooltip",
                      { attrs: { slot: "suffix" }, slot: "suffix" },
                      [
                        _c("span", { staticClass: "text-color" }, [
                          _vm._v("周岁"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "a-card",
        {
          staticClass: "risk_container",
          attrs: { title: "民族/地区限制", bordered: false },
        },
        [
          _c("div", { staticClass: "risk-rules" }, [
            _c(
              "div",
              [
                _c(
                  "a-checkbox",
                  {
                    attrs: { checked: _vm.form.areation.nation ? true : false },
                  },
                  [
                    _c("span", { staticClass: "text-color" }, [
                      _vm._v("民族限制 "),
                    ]),
                  ]
                ),
                _c(
                  "a-select",
                  {
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "请选择民族", allowClear: "" },
                    on: {
                      change: function ($event) {
                        return _vm.handlSelect($event)
                      },
                    },
                    model: {
                      value: _vm.form.areation.nation,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.areation, "nation", $$v)
                      },
                      expression: "form.areation.nation",
                    },
                  },
                  [
                    _c("a-select-option", { attrs: { value: "" } }, [
                      _vm._v("请选择民族"),
                    ]),
                    _vm._l(_vm.nation, function (items) {
                      return _c(
                        "a-select-option",
                        { key: items, attrs: { value: items } },
                        [_vm._v(" " + _vm._s(items) + " ")]
                      )
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "a-checkbox",
                  { attrs: { checked: _vm.form.areation.area ? true : false } },
                  [
                    _c("span", { staticClass: "text-color" }, [
                      _vm._v("地区限制 "),
                    ]),
                  ]
                ),
                _c(
                  "a-select",
                  {
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "请选择省份", allowClear: "" },
                    on: {
                      change: function ($event) {
                        return _vm.handlSelect($event)
                      },
                    },
                    model: {
                      value: _vm.form.areation.area,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.areation, "area", $$v)
                      },
                      expression: "form.areation.area",
                    },
                  },
                  [
                    _c("a-select-option", { attrs: { value: "" } }, [
                      _vm._v("请选择省份"),
                    ]),
                    _vm._l(_vm.area, function (items) {
                      return _c(
                        "a-select-option",
                        { key: items.name, attrs: { value: items.name } },
                        [_vm._v(" " + _vm._s(items.name) + " ")]
                      )
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "a-card",
        {
          staticClass: "risk_container",
          attrs: { title: "长期未考勤提醒规则", bordered: false },
        },
        [
          _c("div", { staticClass: "risk-rules" }, [
            _c(
              "div",
              [
                _c(
                  "a-checkbox",
                  { attrs: { checked: _vm.form.kq.day ? true : false } },
                  [_c("span", { staticClass: "text-color" }, [_vm._v("超过 ")])]
                ),
                _c("a-input", {
                  staticStyle: { width: "100px" },
                  attrs: { placeholder: "7" },
                  model: {
                    value: _vm.form.kq.day,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.kq, "day", $$v)
                    },
                    expression: "form.kq.day",
                  },
                }),
                _c(
                  "span",
                  {
                    staticClass: "text-color",
                    staticStyle: { "margin-left": "5px" },
                  },
                  [_vm._v("天未考勤，系统进行提示")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "a-card",
        {
          staticClass: "risk_container",
          attrs: { title: "教育提醒规则", bordered: false },
        },
        [
          _c("div", { staticClass: "risk-rules" }, [
            _c(
              "div",
              [
                _c(
                  "a-checkbox",
                  { attrs: { checked: _vm.form.edu.day ? true : false } },
                  [_c("span", { staticClass: "text-color" }, [_vm._v("超过 ")])]
                ),
                _c("a-input", {
                  staticStyle: { width: "100px" },
                  attrs: { placeholder: "1" },
                  model: {
                    value: _vm.form.edu.day,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.edu, "day", $$v)
                    },
                    expression: "form.edu.day",
                  },
                }),
                _c(
                  "span",
                  {
                    staticClass: "text-color",
                    staticStyle: { "margin-left": "5px" },
                  },
                  [_vm._v("天未接受三级安全教育培训，系统进行提示")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "a-button",
        {
          attrs: { type: "primary", size: "large" },
          on: { click: _vm.saveRules },
        },
        [_vm._v("保存")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }