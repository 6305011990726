var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap-cantainer ant-card ant-card-body" },
    [
      _c(
        "a-row",
        { attrs: { gutter: 16 } },
        [
          _c("a-col", { attrs: { span: 4 } }, [
            _c("div", { staticClass: "laowu-data-box blue" }, [
              _c("h5", { staticClass: "p-1" }, [_vm._v("建委预警")]),
              _c("div", { staticClass: "px-1" }, [
                _c("div", { staticClass: "flex mb-1" }, [
                  _c("label", { staticClass: "flex-1 font-bold-700 font-30" }, [
                    _vm._v(_vm._s(_vm.warningTotal)),
                  ]),
                ]),
                _c("div", { staticClass: "flex mb-1" }, [
                  _c(
                    "label",
                    { staticClass: "flex-1", on: { click: _vm.toWarning } },
                    [
                      _vm._v("去处理"),
                      _c("a-icon", { attrs: { type: "right" } }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("a-col", { attrs: { span: 10 } }, [
            _c("div", { staticClass: "laowu-data-box orange" }, [
              _c("h5", { staticClass: "p-1" }, [_vm._v("工人")]),
              _c("div", { staticClass: "px-1" }, [
                _c("div", { staticClass: "flex mb-1" }, [
                  _c("label", { staticClass: "flex-1" }, [_vm._v("今日")]),
                  _c("div", { staticClass: "flex-1 text-right" }, [
                    _vm._v(_vm._s(_vm.workData.today)),
                  ]),
                ]),
                _c("div", { staticClass: "flex mb-1" }, [
                  _c("label", { staticClass: "flex-1" }, [_vm._v("昨日")]),
                  _c("div", { staticClass: "flex-1 text-right" }, [
                    _vm._v(_vm._s(_vm.workData.yesterday)),
                  ]),
                ]),
                _c("div", { staticClass: "flex" }, [
                  _c("label", { staticClass: "flex-1" }, [_vm._v("已离场")]),
                  _c("div", { staticClass: "flex-1 text-right" }, [
                    _vm._v(_vm._s(_vm.workData.leave)),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("a-col", { attrs: { span: 10 } }, [
            _c("div", { staticClass: "laowu-data-box blue" }, [
              _c("h5", { staticClass: "p-1" }, [_vm._v("管理人员")]),
              _c("div", { staticClass: "px-1" }, [
                _c("div", { staticClass: "flex mb-1" }, [
                  _c("label", { staticClass: "flex-1" }, [_vm._v("建设单位")]),
                  _c("div", { staticClass: "flex-1 text-right" }, [
                    _vm._v(_vm._s(_vm.ManagCWA["2"])),
                  ]),
                ]),
                _c("div", { staticClass: "flex mb-1" }, [
                  _c("label", { staticClass: "flex-1" }, [_vm._v("施工单位")]),
                  _c("div", { staticClass: "flex-1 text-right" }, [
                    _vm._v(_vm._s(_vm.ManagCWA["1"])),
                  ]),
                ]),
                _c("div", { staticClass: "flex" }, [
                  _c("label", { staticClass: "flex-1" }, [_vm._v("监理单位")]),
                  _c("div", { staticClass: "flex-1 text-right" }, [
                    _vm._v(_vm._s(_vm.ManagCWA["3"])),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c(
            "a-row",
            { attrs: { gutter: 15 } },
            [
              _c("a-col", { attrs: { span: 12 } }, [
                _c("div", {
                  staticStyle: { width: "100%", height: "300px" },
                  attrs: { id: "ageTypeChart" },
                }),
              ]),
              _c("a-col", { attrs: { span: 12 } }, [
                _c("div", {
                  staticStyle: { width: "100%", height: "300px" },
                  attrs: { id: "workTypeChart" },
                }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mt-2" }, [
      _c("div", {
        staticStyle: { width: "100%", height: "300px" },
        attrs: { id: "sevenKqChart" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }