<template>
  <div>
    <a-card title="新增专家信息" class="a_Approval" :bordered="false">
      <form-index
        ref="formindex"
        :formData="formData"
        @handleSubmit="submit"
        :bottomSubmit="true"
      >
        <!-- 下部分按钮插槽 -->
        <template #bottomSubmit>
          <a-button class="ml-2" type="primary" html-type="submit"> 保存 </a-button>
          <a-button @click="close"> 返回 </a-button>
        </template>
      </form-index>
    </a-card>
  </div>
</template>

<script>
const formData = [
  {
    label: '姓名',
    placeholder: '请输入姓名',
    decorator: ['name', { rules: [{ required: true, message: '请输入姓名' }] }],
    type: 'input',
    key: 'name',
    col: 8,
    labelCol: 6,
    wrapperCol: 12,
    disabled: false
  },
  {
    label: "联系方式",
    placeholder: "请输入联系方式",
    decorator: [
      "phone",
      { rules: [{ required: false, message: "请输入联系方式" }] },
    ],
    type: "input",
    key: "phone",
    col: 12,
    labelCol: 6,
    wrapperCol: 8,
    display: true,  
    disabled: false
  },
  {
    label: "工作单位",
    placeholder: "请输入工作单位",
    decorator: [
      "workUnit",
      { rules: [{ required: false, message: "请输入工作单位" }] },
    ],
    type: "input",
    key: "workUnit",
    col: 8,
    labelCol: 6,
    wrapperCol: 12,
    display: true,
    disabled: false
  },
  {
    label: '职务',
    placeholder: '请输入职务',
    decorator: ['post', { rules: [{ required: false, message: '请输入职务' }] }],
    type: 'input',
    key: 'post',
    col: 12,
    labelCol: 6,
    wrapperCol: 8,
    disabled: false
  },
  {
    label: '检查方向',
    placeholder: '请选择检查方向',
    decorator: ['checkDirection', { rules: [{ required: false, message: '请选择检查方向' }] }],
    type: "select",
    key: "checkDirection",
    selectlist: [
      {name: '文施', value: '0'},
      {name: '土建', value: '1'},
      {name: '机电', value: '2'},
    ],
    mode: 'multiple',
    col: 8,
    labelCol: 6,
    wrapperCol: 12,
    disabled: false
  },
  {
    label: "信息收集",
    placeholder: "请输入信息收集",
    decorator: [
      "information",
      { rules: [{ required: false, message: "请输入信息收集" }] },
    ],
    type: "textarea",
    key: "information",
    col: 12,
    labelCol: 6,
    wrapperCol: 8,
    display: true,
    disabled: false
  },
];
import formIndex from "@/pages/components/form/index";
import { mapGetters } from 'vuex'
import { insertExcellentMaster, getExcellentMasterDetail, updateExcellentMaster } from "@/services/civilizedExcellent";
export default {
  components: {
    formIndex,
  },
  inject: ["fatherMethod"],
  computed: {
    ...mapGetters('account', ['user']),
  },
  data() {
    return {
      isEdit: 0,
      detailObj: null, //保存详情的对象
      formData: formData,
      params: {
        id: '',
      },
      ptype: 1, // 1:房间，2：市政
    };
  },
  activated() {
    this.isEdit = Number(this.$route.query.isEdit);
    if (this.isEdit === 1) {
      this.params.id = this.$route.query.id;
      this.getDetail()
    }
  },

  methods: {
    getDetail() {
      getExcellentMasterDetail(this.params.id).then(res => {
        if (res.data.code === 0) {
          if (res.data.data.checkDirection) {
            res.data.data.checkDirection = res.data.data.checkDirection.split(',');
          } else {
            res.data.data.checkDirection = []
          }
          this.detailObj = res.data.data
          this.$refs.formindex.queryObj(res.data.data)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    submit(e) {
      console.log(e)
      if (this.isEdit === 0) {
        this.params = Object.assign(this.params, e)
        this.params.created = this.user.id
        if (this.params.checkDirection && this.params.checkDirection.length > 0) {
          this.params.checkDirection = this.params.checkDirection.join(',')
        } else {
          this.params.checkDirection = ''
        }
        console.log(this.params)
        insertExcellentMaster(this.params).then((res) => {
          let d = res.data;
          if (d.code === 0) {
            this.$message.success("新增成功");
            this.close();
          } else {
            this.$message.error(res.data.msg);
          }
        });
      } else {
        this.params = Object.assign(this.detailObj, Object.assign(this.params, e))
        this.params.updated = this.user.id
        if (this.params.checkDirection.length > 0) {
          this.params.checkDirection = this.params.checkDirection.join(',')
        } else {
          this.params.checkDirection = ''
        }
        updateExcellentMaster(this.params).then((res) => {
          let d = res.data;
          if (d.code === 0) {
            this.$message.success("修改成功");
            this.close();
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
    },
    close() {
      let path = this.$route.path;
      this.fatherMethod(`${path}`);
      this.$router.push({
        name: "专家信息列表",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.a_Approval {
  background: #fff;
  border-radius: 10px;
}
</style>
