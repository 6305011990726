var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    [
      _c("form-index", {
        ref: "formindex",
        attrs: {
          chageT: true,
          formData: _vm.formData,
          Overview: _vm.query.id ? "编辑子产品" : "新增子产品",
          topSubmit: true,
          defaultFileList: _vm.defaultFileList,
        },
        on: { handleSubmit: _vm.handleSubmit, handlSelect: _vm.handlSelect },
        scopedSlots: _vm._u([
          {
            key: "topSubmit",
            fn: function () {
              return [
                _c(
                  "a-space",
                  { style: { marginTop: "4px" } },
                  [
                    _c(
                      "a-button",
                      { attrs: { type: "primary", "html-type": "submit" } },
                      [_vm._v(" 提交 ")]
                    ),
                    _c("a-button", { on: { click: _vm.close } }, [
                      _vm._v(" 关闭 "),
                    ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }