<template>
  <div class="a_Approval">
    <div class="plug-Overview">停工/处罚/约谈管理台账</div>
    <a-divider />
    <a-spin :spinning="spinning" :tip="loadingText">
      <div :class="advanced ? 'search' : null">
        <a-form class="clearfix">
          <a-col :span="5">
            <a-form-item label="统计方式" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
              <a-radio-group v-model="queryParams.queryType">
                <a-radio-button :value="1"> 月份 </a-radio-button>
                <a-radio-button :value="2"> 年份 </a-radio-button>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :span="5">
            <a-form-item label="月份/年份" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
              <a-month-picker
                placeholder="请选择月份"
                :default-value="moment(monthDay, monthFormat)"
                :format="monthFormat"
                v-if="queryParams.queryType == 1"
                v-model="queryParams.plan_date"
              >
                <a-icon slot="suffixIcon" type="smile" />
              </a-month-picker>
  
              <a-date-picker
                v-if="queryParams.queryType == 2"
                mode="year"
                v-model="queryParams.plan_date"
                placeholder="请输入年份"
                :default-value="moment(nowYear, yearFormat)"
                :format="yearFormat"
                style="width: 200px"
                :open="yearShowOne"
                @openChange="openChangeOne"
                @panelChange="panelChangeOne"
              />
            </a-form-item>
          </a-col>

          <a-col v-if="panelKey != 3" :span="5">
            <a-form-model-item :label="panelKey == 2 ? '巡检单位' : '分公司'" :labelCol="{ span: 9 }" :wrapperCol="{ span: 14, offset: 1 }">
              <a-select
                v-model="queryParams.gcglbOfficeId"
                allowClear
                placeholder="请选择"
                style="width: 200px"
              >
                <a-select-option value="">全部</a-select-option>
                <a-select-option
                  v-for="item in gcglbList"
                  :key="item.value"
                  :value="item.value"
                  :title="item.name"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>

          <a-col v-if="gcglbtype" :span="5">
            <a-form-item label="下发部门" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
              <a-select v-model="queryParams.qtType">
                <a-select-option :value="1">安全部</a-select-option>
                <a-select-option :value="2">工程管理部</a-select-option>
                <!-- <a-select-option :value="3">带班检查</a-select-option>
                    <a-select-option :value="4">停工单</a-select-option> -->
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="5">
            <a-space style="height: 40px; line-height: 40px;" class="ml-4">
              <a-button type="primary" @click="doBtnQuery()">查询</a-button>
              <a-button
                v-if="!gcglbtype && panelKey == 3"
                type="primary"
                @click="addyt()"
                >新增约谈记录</a-button
              >
              <a-button
                v-if="!gcglbtype && panelKey == 3"
                type="primary"
                @click="doDowload()"
                >导出约谈记录</a-button
              >
            </a-space>
          </a-col>
        </a-form>
      </div>
      <!-- <a-button type="primary" @click="doFileBatchDowload()">批量导出带班资料</a-button> -->
      <a-tabs default-active-key="2" @change="callback">
        <a-tab-pane key="2" tab="停工" force-render>
          <standard-table
            :bordered="true"
            :columns="columns2"
            :dataSource="dataSource"
            :selectedRows.sync="selectedRows"
            :isAlert="false"
            :pagination="pagination"
            @clear="onClear"
            @change="onPageChange"
          >
            <template slot="index" slot-scope="{ index }">
              <span>{{
                (pagination.current - 1) * pagination.pageSize +
                parseInt(index) +
                1
              }}</span>
            </template>
            <template slot="officeName" slot-scope="{ record }">
              <div v-for="(item, index) in record.officeName.split(',')" :key="index">
                <span>{{ item }}</span>
              </div>
            </template>
            <div slot="do-status" slot-scope="{ text }">
              <label v-if="text == 2"
                ><a-icon style="font-size: 15px; color: green" type="check"
              /></label>
              <label v-else
                ><a-icon style="font-size: 15px; color: red" type="close"
              /></label>
            </div>
            <div slot="action" slot-scope="{ record }">
              <a @click="doFileDowload2(record)">下载资料</a>
            </div>
            <template slot="statusTitle">
              <a-icon @click.native="onStatusTitleClick" type="info-circle" />
            </template>
          </standard-table>
        </a-tab-pane>
        <a-tab-pane key="1" tab="处罚">
          <standard-table
            :bordered="true"
            :columns="columns"
            :dataSource="dataSource"
            :selectedRows.sync="selectedRows"
            :isAlert="false"
            :pagination="pagination"
            :loading="tableLoading"
            @clear="onClear"
            @change="onPageChange"
          >
            <template slot="index" slot-scope="{ index }">
              <span>{{
                (pagination.current - 1) * pagination.pageSize +
                parseInt(index) +
                1
              }}</span>
            </template>
            <div slot="action" slot-scope="{ record }">
              <a :href="`${record.filePath}`">下载文件</a>
            </div>
            <div slot="do-status" slot-scope="{ text }">
              <label v-if="text == 2"
                ><a-icon style="font-size: 15px; color: green" type="check"
              /></label>
              <label v-else
                ><a-icon style="font-size: 15px; color: red" type="close"
              /></label>
            </div>
            <template slot="statusTitle">
              <a-icon @click.native="onStatusTitleClick" type="info-circle" />
            </template>
          </standard-table>
        </a-tab-pane>
        <a-tab-pane key="3" tab="约谈">
          <a-row justify="end" type="flex"> </a-row>
          <standard-table
            :bordered="true"
            :columns="columns3"
            :dataSource="dataSource"
            :selectedRows.sync="selectedRows"
            :isAlert="false"
            :scroll="{ x: 1300 }"
            :pagination="pagination"
            :loading="tableLoading"
            @clear="onClear"
            @change="onPageChange"
          >
            <template slot="index" slot-scope="{ index }">
              <span>{{
                (pagination.current - 1) * pagination.pageSize +
                parseInt(index) +
                1
              }}</span>
            </template>
            <div slot="action" slot-scope="{ record }">
              <a v-if="!gcglbtype" @click="doEdit(record)">修改</a>
              <a-divider v-if="!gcglbtype" type="vertical" />
              <a @click="doLook(record)">查看</a>
              <a-divider v-if="!gcglbtype" type="vertical" />
              <a v-if="!gcglbtype" @click="doDelete(record)">删除</a>
            </div>
          </standard-table>
        </a-tab-pane>
      </a-tabs>
      <Export-loading
        :cPercent="cPercent"
        :isExporting="isExporting"
        :loadingText="loadingText"
      ></Export-loading>
    </a-spin>
    <yt-modify
      :visible="yttable.visble"
      :ytlist="yttable.ytlist"
      :bytlist.sync="yttable.bytlist"
      :action="yttable.action"
      @ytClose="ytClose"
    >
    </yt-modify>
  </div>
</template>

<script>
import StandardTable from "@/components/table/StandardTable";
import YtModify from "@/pages/safeview/ytmodify";
import {
  DISPOSITION_NOTICE_LIST,
  DB_CHECK_DOWNLOAD,
  OFFICE_STAND_BOOK,
  OFFICE_STAND_BOOK_EXPORT,
  SAFE_VIEW_DOWNLOAD,
  PROJECT_OFFICE_yt,
  GET_JDJC_QUERY,
  DELETE_JDJC,
  EXPORT_JDJC,
  PROJECT_OFFICE_CHILD,
} from "@/services/api";

import { exportProgress } from "@/pages/aqjyzl/export"; // 导出方法
import ExportLoading from "@/components/ExportLoading/ExportLoading"; // progress组件
import { request } from "@/utils/request";
import moment from "moment";
import { useRender } from '@/hooks/useRender.js'
import { QUERY_OFFICE_BY_USER } from '@/services/api'

const columns = [
  {
    title: "序号",
    align: "center",
    scopedSlots: { customRender: "index" },
    width: 65,
  },
  {
    title: "公司",
    align: "center",
    dataIndex: "parentOffice",
  },
  {
    title: "分公司",
    align: "center",
    dataIndex: "childOffice",
  },
  {
    title: "项目名称",
    dataIndex: "projectName",
    width: 280,
    ellipsis: true,
    tooltip: true,
  },
  {
    title: "检查日期",
    dataIndex: "checkDate",
    align: "center",
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "处罚金额(元)",
    dataIndex: "moneys",
    align: "center",
    scopedSlots: { customRender: "moneys" },
  },
  {
    title: "处罚内容",
    dataIndex: "content",
    align: "center",
    ellipsis: true,
    tooltip: true,
  },
  // {
  //   title: '备注',
  //   dataIndex: 'remark',
  //   align: 'center',
  //   customRender: (text) => {
  //     return "";
  //   }
  // },
  {
    title: "操作",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
const columns3 = [
  {
    title: "序号",
    align: "center",
    scopedSlots: { customRender: "index" },
    width: 65,
  },
  {
    title: "公司",
    align: "center",
    dataIndex: "parentOffice",
    width: 120,
  },
  {
    title: "分公司",
    align: "center",
    dataIndex: "bytUnitName",
    width: 120,
  },
  {
    title: "工程名称",
    dataIndex: "projectName",
    width: 200,
    ellipsis: true,
    tooltip: true,
  },
  {
    title: "约谈单位/部门",
    dataIndex: "ytUnit",
    width: 130,
    align: "center",
    scopedSlots: { customRender: "ytUnit" },
  },
  {
    title: "被约谈单位/部门",
    dataIndex: "bytUnitName",
    align: "center",
    width: 160,
    ellipsis: true,
    tooltip: true,
  },
  {
    title: "约谈人",
    dataIndex: "ytrName",
    width: 100,
    align: "center",
    scopedSlots: { customRender: "ytrName" },
  },
  {
    title: "被约谈人",
    dataIndex: "bytrName",
    width: 100,
    align: "center",
    scopedSlots: { customRender: "bytrName" },
  },
  {
    title: "开展时间",
    width: 120,
    dataIndex: "checkTime",
    align: "center",
    customRender: (text) => useRender.renderDate(text, 'YYYY-MM-DD')
  },
  {
    title: "约谈内容",
    dataIndex: "ytContent",
    width: 160,
    align: "center",
    ellipsis: true,
    tooltip: true,
  },
  {
    title: "发现问题",
    dataIndex: "question",
    width: 160,
    align: "center",
    ellipsis: true,
    tooltip: true,
  },
  {
    title: "整改措施及情况",
    dataIndex: "zgcs",
    width: 170,
    align: "center",
    ellipsis: true,
    tooltip: true,
  },
  {
    title: "操作",
    align: "center",
    width: 160,
    scopedSlots: { customRender: "action" },
  },
];

const columns2 = [
  {
    title: "序号",
    align: "center",
    scopedSlots: { customRender: "index" },
    width: 65,
  },
  {
    title: '巡检单位',
    dataIndex: 'officeName',
    align: 'center',
    scopedSlots: { customRender: 'officeName' },
    width:120,
  },
  {
    title: "项目名称",
    dataIndex: "projectName",
    width: 220,
    ellipsis: true,
    tooltip: true,
  },
  {
    title: "检查时间",
    dataIndex: "checkDateStr",
    align: "center",
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "限期整改时间",
    dataIndex: "zgqxStr",
    align: "center",
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "停工通知书编号",
    dataIndex: "charCode",
    align: "center",
    scopedSlots: { customRender: "charCode" },
  },
  {
    title: "复工申请时间",
    dataIndex: "pmTimeStr",
    align: "center",
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "复检时间",
    dataIndex: "fhsjStr",
    align: "center",
    customRender: (text) => useRender.renderDate(text)
  },
  // {
  //   title: '整改到期时间',
  //   dataIndex: 'zgqxStr',
  //   align: 'center',
  //   scopedSlots: {customRender: 'zgqxStr'}
  // },
  {
    title: "是否闭环",
    dataIndex: "status",
    align: "center",
    scopedSlots: { customRender: "do-status" },
  },
  {
    title: "检查类别",
    dataIndex: "remark",
    align: "center",
    scopedSlots: { customRender: "remark" },
  },
  // {
  //   title: '带班/常规',
  //   dataIndex: 'planType',
  //   align: 'center',
  //   scopedSlots: {customRender: 'do-status'}
  // }
  {
    title: "操作",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  mixins: [exportProgress],
  name: "QueryList",
  components: { StandardTable, ExportLoading, YtModify },
  data() {
    return {
      dataSource: [],
      advanced: false,
      columns: columns,
      columns2,
      columns3,
      yttable: {
        visble: false,
        ytlist: {
          checkTime: "", // 约谈时间
          ytUnit: "", // 约谈单位/部门
          bytUnit: "", // 被约谈单位/部门
          bytUnitName: "", // 被约谈单位/部门名称
          ytrName: "", // 约谈人
          bytrName: "", // 被约谈人
          ytContent: "", // 约谈内容
          question: "", // 发现问题
        },
        bytlist: [],
        action: "add",
      },
      yearShowOne: false,
      selectedRows: [],
      tableLoading: false,
      spinning: false,
      loadingText: "数据加载中...",
      loadKeys: [],
      visible: false,
      monthFormat: "YYYY-MM",
      yearFormat: "YYYY",
      overDownIndex: 0,
      monthDay: null,
      nowYear: null,
      panelKey: 2,
      gcglbtype: false,
      gcglbList: [],
      queryParams: {
        xjTimeStr: null,
        queryType: 1,
        projectName: null,
        plan_date: null,
        pageNumber: 1,
        type: 2,
        date: null,
        zjType: 4,
        qtType: 1,
        gcglbOfficeId: null,
      },
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showLessItems: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`,
      },
      cPercent: 0,
      isExporting: false,
    };
  },
  watch: {
    cPercent: {
      handler(newName, oldName) {
        this.cPercent = newName;
      },
      immediate: true,
    },
    loadingText: {
      handler(newName, oldName) {
        this.loadingText = newName;
      },
      immediate: true,
    },
  },
  created() {
    this.getgcglbList(QUERY_OFFICE_BY_USER)
    this.monthDay = moment(new Date()).format("YYYY-MM");
    this.yttable.ytlist.checkTime = moment(new Date()).format("YYYY-MM-DD");
    this.nowYear = moment(new Date()).format("YYYY");
    this.getgcglb();
    let type = this.$store.getters["account/user"].isGcglb;
    if (type === 1) {
      this.gcglbtype = true;
    }
    this.doQuery1();
  },
  authorize: {
    deleteRecord: "delete",
  },
  methods: {
    moment,
    // 获取工程管理部数据
    getgcglbList(path) {
      request(path, "get").then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data.filter(item => item.id != "3da0f5533f87425c9a9e8a11a7923300")
          const arr = [];
          data.forEach((item) => {
            arr.push({
              name: item.master,
              value: item.id,
            });
          });
          this.gcglbList = arr;
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    callback(key) {
      this.dataSource = [];
      this.selectedRows = [];
      this.pagination.current = 1;
      this.pagination.total = 0;
      this.panelKey = key;
      if (key == 1) {
        this.doQuery();
      } else if (key == 2) {
        this.doQuery1();
      } else if (key == 3) {
        this.doQuery3();
      }
    },
    doBtnQuery() {
      this.dataSource = [];
      this.selectedRows = [];
      this.pagination.current = 1;
      this.pagination.total = 0;
      if (this.panelKey == 1) {
        this.doQuery();
      } else if (this.panelKey == 2) {
        this.doQuery1();
      } else if (this.panelKey == 3) {
        this.doQuery3();
      }
    },
    deleteRecord(key) {
      this.dataSource = this.dataSource.filter((item) => item.key !== key);
      this.selectedRows = this.selectedRows.filter((item) => item.key !== key);
    },
    toggleAdvanced() {
      this.advanced = !this.advanced;
    },
    remove() {
      this.dataSource = this.dataSource.filter(
        (item) =>
          this.selectedRows.findIndex((row) => row.key === item.key) === -1
      );
      this.selectedRows = [];
    },
    changeDate(e) {
      if (e == null || e.length <= 0) {
        this.queryParams.startDate = null;
        this.queryParams.endDate = null;
      }
    },

    /**
     * 获取时间段的台账信息
     */
    doQuery() {
      this.tableLoading = true;
      if (this.queryParams.queryType == 1) {
        if (this.queryParams.plan_date == null) {
          this.queryParams.xjTimeStr = this.monthDay;
        } else {
          this.queryParams.xjTimeStr =
            this.queryParams.plan_date.format("YYYY-MM");
        }
      }
      if (this.queryParams.queryType == 2) {
        if (this.queryParams.plan_date == null) {
          this.queryParams.xjTimeStr = this.nowYear;
        } else {
          this.queryParams.xjTimeStr =
            this.queryParams.plan_date.format("YYYY");
        }
      }
      request(DISPOSITION_NOTICE_LIST, "post", this.queryParams).then((res) => {
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
          this.dataSource.forEach((obj) => {
            obj.key = obj.id;
          });
        }
        this.tableLoading = false;
      });
    },
    doReset() {
      this.queryParams = {};
      this.doQuery();
    },
    //分页查询
    onPageChange(e) {
      this.queryParams.pageNumber = e.current;
      this.pagination.current = e.current;
      if (this.panelKey == 1) {
        this.doQuery();
      } else if (this.panelKey == 2) {
        this.doQuery1();
      } else if (this.panelKey == 3) {
        this.doQuery3();
      }
    },
    handleMenuClick(e) {
      if (e.key === "delete") {
        this.remove();
      }
    },
    doSureModal() {
      this.visible = true;
    },
    onChange(dataStr) {
      this.queryParams.plan_date = null;
    },
    openChangeOne(status) {
      if (status) {
        this.yearShowOne = true;
      }
    },
    // 得到年份选择器的值
    panelChangeOne(value) {
      this.yearShowOne = false;
      this.queryParams.plan_date = value;
    },
    doFileDowload(recode) {
      let selectedRows = this.dataSource.filter(
        (item) => item.key == recode.id
      );
      this.loadingText = "正在准备下载文件，请稍候 ····";
      this.loadKeys = [];
      this.loadKeys.push(recode.id);
      this.isExporting = true;
      this.cPercent = 0;
      let projectName = recode.projectName;
      if (recode.projectName.length > 15) {
        projectName = recode.projectName.slice(0, 15) + "……";
      }
      let fileName =
        projectName +
        "-" +
        moment(recode.xjTime).format("YYYY-MM-DD") +
        "-带班检查资料下载.zip";
      this.exportFun(DB_CHECK_DOWNLOAD, this.loadKeys, fileName, selectedRows);
    },
    doFileBatchDowload() {
      if (this.selectedRows && this.selectedRows.length > 0) {
        this.isExporting = true;
        this.loadingText = "正在下载资料...";
        this.selectedRows.map((item) => {
          this.loadKeys = [];
          this.loadKeys.push(item.key);
          let fileName = "";
          let projectName = item.projectName;
          if (item.projectName.length > 15) {
            projectName = item.projectName.slice(0, 15) + "……";
          }
          fileName =
            projectName +
            "-" +
            moment(item.xjTime).format("YYYY-MM-DD") +
            "-带班检查资料下载.zip";
          this.exportFun(DB_CHECK_DOWNLOAD, this.loadKeys, fileName, item);
        });
      } else {
        this.$message.info("请选择记录下载");
        return;
      }
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },
    changeDate1(e) {
      if (e == null || e.length <= 0) {
        this.queryParams.startDate = null;
        this.queryParams.endDate = null;
      }
    },

    /**
     * 获取时间段的台账信息
     */
    doQuery1() {
      this.queryParams.type = this.queryParams.queryType;
      this.tableLoading = true;
      if (this.queryParams.type == 1) {
        if (this.queryParams.plan_date == null) {
          this.queryParams.date = this.monthDay;
        } else {
          this.queryParams.date = this.queryParams.plan_date.format("YYYY-MM");
        }
      }
      if (this.queryParams.type == 2) {
        if (this.queryParams.plan_date == null) {
          this.queryParams.date = this.nowYear;
        } else {
          this.queryParams.date = this.queryParams.plan_date.format("YYYY");
        }
      }
      request(OFFICE_STAND_BOOK, "post", this.queryParams).then((res) => {
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
          this.dataSource.forEach((obj) => {
            obj.key = obj.id;
          });
        }
        this.tableLoading = false;
      });
    },
    /**
     * 获取时间段的台账信息
     */
    doQuery3() {
      this.tableLoading = true;
      if (this.queryParams.queryType == 1) {
        if (this.queryParams.plan_date == null) {
          this.queryParams.date = this.monthDay;
        } else {
          this.queryParams.date = this.queryParams.plan_date.format("YYYY-MM");
        }
      }
      if (this.queryParams.queryType == 2) {
        if (this.queryParams.plan_date == null) {
          this.queryParams.date = this.nowYear;
        } else {
          this.queryParams.date = this.queryParams.plan_date.format("YYYY");
        }
      }
      request(GET_JDJC_QUERY, "post", this.queryParams).then((res) => {
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
          this.dataSource.forEach((obj) => {
            obj.key = obj.id;
          });
        }
        this.tableLoading = false;
      });
    },
    //分页查询
    // onPageChange(e){
    //   this.queryParams.pageNumber = e.current;
    //   this.pagination.current=e.current;
    //   this.doQuery();
    // },
    //下载巡检资料文件
    doFileDowload2(recode) {
      let selectedRows = this.dataSource.filter(
        (item) => item.key == recode.key
      );
      this.loadingText = "正在下载文件，请稍候 ····";
      this.loadKeys = [];
      this.loadKeys.push(recode.key);
      this.isExporting = true;
      this.cPercent = 0;
      let fileName = "";
      if (recode.title) {
        fileName = recode.title;
      }
      let typeName = "公司常规检查";
      if (recode.planType == 4) {
        typeName = "停工处罚";
      } else if (recode.planType == 3) {
        typeName = "带班检查";
      } else {
        typeName = "公司常规检查";
      }
      let projectName = recode.projectName;
      if (recode.projectName.length > 15) {
        projectName = recode.projectName.slice(0, 15) + "……";
      }
      fileName =
        projectName +
        "-" +
        moment(recode.xjTime).format("YYYY-MM-DD") +
        "-" +
        typeName +
        ".zip";
      this.exportFun(SAFE_VIEW_DOWNLOAD, this.loadKeys, fileName, selectedRows);
    },
    addyt() {
      this.yttable.visble = true;
    },
    ytClose() {
      this.yttable.visble = false;
      this.yttable.ytlist = {};
      this.yttable.ytlist.checkTime = moment(new Date()).format("YYYY-MM-DD");
      this.yttable.action = "add";
      this.doBtnQuery();
    },
    getgcglb() {
      request(PROJECT_OFFICE_yt, "post").then((res) => {
        if (res.data.code == 0) {
          this.yttable.bytlist = []
          res.data.data.forEach(el => {
            this.yttable.bytlist.push({
              id: el.value,
              name: el.label,
            })
          })
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    //查看详情
    doEdit(row) {
      this.yttable.ytlist = row;
      this.yttable.action = "edit";
      this.yttable.visble = true;
    },
    //查看详情
    doLook(row) {
      this.yttable.visble = true;
      this.yttable.ytlist = row;
      this.yttable.action = "look";
      // this.$set(this.yttable.ytlist)
    },
    // 删除
    doDelete(row) {
      var _that = this;
      this.$confirm({
        title: "警告！删除约谈！",
        content: "您好，请您确认是否将该约谈删除，删除之后，该约谈将不可见！",
        okText: "仍旧删除",
        okType: "danger",
        cancelText: "再想想",
        onOk() {
          request(DELETE_JDJC + row.id, "delete", {}).then((res) => {
            if (res.data.code === 0) {
              _that.$message.success("删除成功！");
              _that.doQuery();
            } else {
              _that.$message.error("删除异常！");
            }
          });
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    // 导出约谈
    doDowload() {
      this.loadingText = "正在导出EXCEL文件，请稍候 ····";
      let name = "";
      if (this.queryParams.queryType == 1) {
        if (this.queryParams.plan_date == null) {
          this.queryParams.xjTimeStr = this.monthDay;
        } else {
          this.queryParams.xjTimeStr =
            this.queryParams.plan_date.format("YYYY-MM");
        }
        name = name + this.queryParams.xjTimeStr + "月";
      }
      if (this.queryParams.queryType == 2) {
        if (this.queryParams.plan_date == null) {
          this.queryParams.xjTimeStr = this.nowYear;
        } else {
          this.queryParams.xjTimeStr =
            this.queryParams.plan_date.format("YYYY");
        }
        name = name + this.queryParams.xjTimeStr + "年";
      }
      this.isExporting = true;
      this.cPercent = 0;
      let fileName = name + "-约谈记录台账.xlsx";
      this.exportFunNomal2(
        EXPORT_JDJC,
        this.queryParams,
        fileName,
        "application/vnd.ms-excel"
      );
    },
  },
};
</script>

<style lang="less" scoped>
.search {
  margin-bottom: 54px;
}
.fold {
  width: calc(100% - 216px);
  display: inline-block;
}
.operator {
  margin-bottom: 18px;
}
@media screen and (max-width: 900px) {
  .fold {
    width: 100%;
  }
}
</style>
