var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-card",
        {
          staticClass: "a_Approval",
          attrs: { title: "新增动态扣分", bordered: false },
        },
        [
          _c(
            "a-form-model",
            {
              ref: "ruleForm",
              attrs: { model: _vm.params.projectSubPoint, rules: _vm.rules },
            },
            [
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _vm.user.isGcglb == 0 && _vm.user.loginType == 1
                        ? _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: "工程管理部",
                                "label-col": { span: 6 },
                                "wrapper-col": { span: 14 },
                                prop: "gcglbId",
                              },
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  attrs: {
                                    placeholder: "请选择",
                                    allowClear: "",
                                    "filter-option": _vm.filterOption,
                                    disabled: _vm.isEdit == 1,
                                  },
                                  on: { change: _vm.handlSelect },
                                  model: {
                                    value: _vm.params.projectSubPoint.gcglbId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.params.projectSubPoint,
                                        "gcglbId",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "params.projectSubPoint.gcglbId",
                                  },
                                },
                                _vm._l(_vm.selectlist, function (items) {
                                  return _c(
                                    "a-select-option",
                                    {
                                      key: items.name,
                                      attrs: {
                                        value: items.value,
                                        title: items.name,
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(items.name) + " ")]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: "工程管理部",
                                "label-col": { span: 6 },
                                "wrapper-col": { span: 14 },
                                prop: "gcglbName",
                              },
                            },
                            [
                              _c("a-input", {
                                attrs: {
                                  placeholder: "请输入",
                                  disabled: true,
                                },
                                model: {
                                  value: _vm.params.projectSubPoint.gcglbName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.params.projectSubPoint,
                                      "gcglbName",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "params.projectSubPoint.gcglbName",
                                },
                              }),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _vm.user.isGcglb == 0 && _vm.user.loginType == 0
                        ? _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: "工程名称",
                                "label-col": { span: 4 },
                                "wrapper-col": { span: 14 },
                                prop: "projectName",
                              },
                            },
                            [
                              _c("a-input", {
                                attrs: {
                                  "data-source": _vm.dataSource,
                                  placeholder: "请输入",
                                  disabled: true,
                                },
                                model: {
                                  value: _vm.params.projectSubPoint.projectName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.params.projectSubPoint,
                                      "projectName",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "params.projectSubPoint.projectName",
                                },
                              }),
                            ],
                            1
                          )
                        : _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: "工程名称",
                                "label-col": { span: 4 },
                                "wrapper-col": { span: 14 },
                                prop: "projectName",
                              },
                            },
                            [
                              _c(
                                "a-auto-complete",
                                {
                                  attrs: {
                                    disabled: _vm.isEdit == 1,
                                    placeholder: "请输入",
                                    allowClear: "",
                                    "option-label-prop": "value",
                                  },
                                  on: {
                                    search: _vm.handleChange,
                                    select: _vm.handlProjectNameSelect,
                                  },
                                  model: {
                                    value:
                                      _vm.params.projectSubPoint.projectName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.params.projectSubPoint,
                                        "projectName",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "params.projectSubPoint.projectName",
                                  },
                                },
                                [
                                  _c(
                                    "template",
                                    { slot: "dataSource" },
                                    _vm._l(_vm.dataSource, function (items) {
                                      return _c(
                                        "a-select-option",
                                        { key: items.title + "" },
                                        [
                                          _c(
                                            "span",
                                            { attrs: { title: items.title } },
                                            [_vm._v(_vm._s(items.title))]
                                          ),
                                        ]
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "扣分年份",
                            "label-col": { span: 6 },
                            "wrapper-col": { span: 14 },
                            prop: "year",
                          },
                        },
                        [
                          _c("a-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              allowClear: "",
                              disabled: _vm.isEdit == 1,
                              mode: "year",
                              format: "YYYY",
                            },
                            on: { panelChange: _vm.changeYear },
                            model: {
                              value: _vm.params.projectSubPoint.year,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.params.projectSubPoint,
                                  "year",
                                  $$v
                                )
                              },
                              expression: "params.projectSubPoint.year",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      !_vm.params.projectSubPoint.id
                        ? _c(
                            "a-form-model-item",
                            {
                              staticClass: "ml-3",
                              attrs: {
                                "label-col": { span: 4 },
                                "wrapper-col": { span: 14 },
                              },
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.getDeductPoint },
                                },
                                [_vm._v("填写扣分表")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.params.projectSubPoint.id
        ? [
            _c(
              "div",
              { staticClass: "mt-2" },
              [
                _c(
                  "a-row",
                  { attrs: { gutter: 16 } },
                  [
                    _c(
                      "a-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "a-card",
                          {
                            staticClass: "a_Approval pl-3 pr-3",
                            attrs: { bordered: false },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "deduct_point_box-1" },
                              [
                                _c(
                                  "a-form-model",
                                  {
                                    ref: "companyForm",
                                    attrs: { model: _vm.params },
                                  },
                                  [
                                    _c(
                                      "a-form-model-item",
                                      {
                                        attrs: {
                                          "wrapper-col": { span: 24 },
                                          prop: "projectSubPoint.company",
                                          rules: {
                                            required: false,
                                            message: "请输入分数",
                                            trigger: "blur",
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "flex a-center" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "flex-1" },
                                              [_vm._v("施工企业扣分情况")]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "ml-2" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    "共扣" +
                                                      _vm.params.projectSubPoint
                                                        .company +
                                                      "分"
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._l(
                                      _vm.params.details.filter(function (el) {
                                        return el.subType == 1
                                      }),
                                      function (domain, index) {
                                        return _c(
                                          "a-form-model-item",
                                          {
                                            key: index,
                                            attrs: {
                                              rules: {
                                                comment: [
                                                  {
                                                    required: false,
                                                    message: "请输入扣分项",
                                                    trigger: "blur",
                                                  },
                                                ],
                                                score: [
                                                  {
                                                    required: false,
                                                    message: "请输入扣分数",
                                                    trigger: "blur",
                                                  },
                                                ],
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "a-row",
                                              { attrs: { gutter: 16 } },
                                              [
                                                _c(
                                                  "a-col",
                                                  { attrs: { span: 16 } },
                                                  [
                                                    _c(
                                                      "a-form-model-item",
                                                      {
                                                        attrs: {
                                                          prop:
                                                            "details." +
                                                            index +
                                                            ".comment",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "a-select",
                                                          {
                                                            staticStyle: {
                                                              width: "100%",
                                                            },
                                                            attrs: {
                                                              placeholder:
                                                                "请选择扣分项",
                                                            },
                                                            on: {
                                                              change:
                                                                _vm.handleItemChange,
                                                            },
                                                            model: {
                                                              value:
                                                                domain.comment,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    domain,
                                                                    "comment",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "domain.comment",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.selectItemList,
                                                            function (item) {
                                                              return _c(
                                                                "a-select-option",
                                                                {
                                                                  key: item.id,
                                                                  attrs: {
                                                                    value:
                                                                      item.comment,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        item.pointCode +
                                                                          " " +
                                                                          item.comment
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "a-col",
                                                  { attrs: { span: 8 } },
                                                  [
                                                    _c(
                                                      "a-form-model-item",
                                                      {
                                                        attrs: {
                                                          prop:
                                                            "details." +
                                                            index +
                                                            ".score",
                                                        },
                                                      },
                                                      [
                                                        _c("a-input", {
                                                          staticClass:
                                                            "text-center",
                                                          staticStyle: {
                                                            width: "70%",
                                                          },
                                                          attrs: {
                                                            placeholder:
                                                              "扣分数",
                                                            addonAfter: "分",
                                                            disabled: true,
                                                          },
                                                          model: {
                                                            value: domain.score,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                domain,
                                                                "score",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "domain.score",
                                                          },
                                                        }),
                                                        _c("a-icon", {
                                                          staticClass:
                                                            "dynamic-delete-button ml-2",
                                                          attrs: {
                                                            type: "minus-circle-o",
                                                            disabled:
                                                              _vm.params.details
                                                                .length === 1,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.removeDomain(
                                                                domain
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    _c(
                                      "a-form-model-item",
                                      [
                                        _c(
                                          "a-button",
                                          {
                                            staticStyle: { width: "95%" },
                                            attrs: { type: "dashed" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addDomain(1)
                                              },
                                            },
                                          },
                                          [
                                            _c("a-icon", {
                                              attrs: { type: "plus" },
                                            }),
                                            _vm._v(" 添加扣分项 "),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "a-card",
                          {
                            staticClass: "a_Approval pl-3 pr-3",
                            attrs: { bordered: false },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "deduct_point_box-2" },
                              [
                                _c(
                                  "a-form-model",
                                  {
                                    ref: "majorPeopleForm",
                                    attrs: { model: _vm.params },
                                  },
                                  [
                                    _c(
                                      "a-form-model-item",
                                      {
                                        attrs: {
                                          "wrapper-col": { span: 24 },
                                          prop: "projectSubPoint.majorPeople",
                                          rules: {
                                            required: false,
                                            message: "请输入分数",
                                            trigger: "blur",
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "flex a-center" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "flex-1" },
                                              [
                                                _vm._v(
                                                  "施工企业主要负责人累计扣分情况"
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "ml-2" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    "共扣" +
                                                      _vm.params.projectSubPoint
                                                        .majorPeople +
                                                      "分"
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._l(
                                      _vm.params.details.filter(function (el) {
                                        return el.subType == 2
                                      }),
                                      function (domain, index) {
                                        return _c(
                                          "a-form-model-item",
                                          {
                                            key: index,
                                            attrs: {
                                              rules: {
                                                comment: [
                                                  {
                                                    required: false,
                                                    message: "请输入扣分项",
                                                    trigger: "blur",
                                                  },
                                                ],
                                                score: [
                                                  {
                                                    required: false,
                                                    message: "请输入扣分数",
                                                    trigger: "blur",
                                                  },
                                                ],
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "a-row",
                                              { attrs: { gutter: 16 } },
                                              [
                                                _c(
                                                  "a-col",
                                                  { attrs: { span: 16 } },
                                                  [
                                                    _c(
                                                      "a-form-model-item",
                                                      {
                                                        attrs: {
                                                          prop:
                                                            "details." +
                                                            index +
                                                            ".comment",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "a-select",
                                                          {
                                                            staticStyle: {
                                                              width: "100%",
                                                            },
                                                            attrs: {
                                                              placeholder:
                                                                "请选择扣分项",
                                                            },
                                                            on: {
                                                              change:
                                                                _vm.handleItemChange,
                                                            },
                                                            model: {
                                                              value:
                                                                domain.comment,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    domain,
                                                                    "comment",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "domain.comment",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.selectItemList,
                                                            function (item) {
                                                              return _c(
                                                                "a-select-option",
                                                                {
                                                                  key: item.id,
                                                                  attrs: {
                                                                    value:
                                                                      item.comment,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        item.pointCode +
                                                                          " " +
                                                                          item.comment
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "a-col",
                                                  { attrs: { span: 8 } },
                                                  [
                                                    _c(
                                                      "a-form-model-item",
                                                      {
                                                        attrs: {
                                                          prop:
                                                            "details." +
                                                            index +
                                                            ".score",
                                                        },
                                                      },
                                                      [
                                                        _c("a-input", {
                                                          staticClass:
                                                            "text-center",
                                                          staticStyle: {
                                                            width: "70%",
                                                          },
                                                          attrs: {
                                                            placeholder:
                                                              "扣分数",
                                                            addonAfter: "分",
                                                            disabled: true,
                                                          },
                                                          model: {
                                                            value: domain.score,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                domain,
                                                                "score",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "domain.score",
                                                          },
                                                        }),
                                                        _c("a-icon", {
                                                          staticClass:
                                                            "dynamic-delete-button ml-2",
                                                          attrs: {
                                                            type: "minus-circle-o",
                                                            disabled:
                                                              _vm.params.details
                                                                .length === 1,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.removeDomain(
                                                                domain
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    _c(
                                      "a-form-model-item",
                                      [
                                        _c(
                                          "a-button",
                                          {
                                            staticStyle: { width: "95%" },
                                            attrs: { type: "dashed" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addDomain(2)
                                              },
                                            },
                                          },
                                          [
                                            _c("a-icon", {
                                              attrs: { type: "plus" },
                                            }),
                                            _vm._v(" 添加扣分项 "),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "flex flex-row-reverse btn_group mt-2" },
                  [
                    _c(
                      "a-button",
                      {
                        staticClass: "ml-3",
                        attrs: { type: "primary" },
                        on: { click: _vm.submit },
                      },
                      [_vm._v(" 确定 ")]
                    ),
                    _c("a-button", { on: { click: _vm.close } }, [
                      _vm._v(" 关闭 "),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }