var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "team card" },
    [
      _vm._m(0),
      _c("a-divider"),
      _c("div", { staticStyle: { display: "flex" } }, [
        _c(
          "div",
          {
            staticStyle: {
              width: "50%",
              display: "flex",
              "flex-direction": "column",
              "justify-content": "space-between",
              "align-items": "center",
              "margin-right": "20px",
              background: "#EBF2FF",
              padding: "10px 0",
              "border-radius": "10px",
            },
          },
          [
            _c("span", { staticClass: "font-28 font-bold" }, [
              _vm._v(_vm._s(_vm.teamDataKq.totalTeams || 109)),
            ]),
            _c("span", [_vm._v("累计登记")]),
          ]
        ),
        _c(
          "div",
          {
            staticStyle: {
              width: "50%",
              display: "flex",
              "flex-direction": "column",
              "justify-content": "space-between",
              "align-items": "center",
              background: "#E9F7FF",
              padding: "10px 0",
              "border-radius": "10px",
            },
          },
          [
            _c("span", { staticClass: "font-28 font-bold" }, [
              _vm._v(_vm._s(_vm.teamDataKq.onlineTeams || 37)),
            ]),
            _c("span", [_vm._v("在场班组")]),
          ]
        ),
      ]),
      _c("div", { staticClass: "mt-1", staticStyle: { display: "flex" } }, [
        _c(
          "div",
          {
            staticStyle: {
              width: "50%",
              display: "flex",
              "flex-direction": "column",
              "justify-content": "space-between",
              "align-items": "center",
              "margin-right": "20px",
              background: "#EBF2FF",
              padding: "10px 0",
              "border-radius": "10px",
            },
          },
          [
            _c("span", { staticClass: "font-28 font-bold" }, [
              _vm._v(_vm._s(_vm.teamDataKq.todayTeams || 29)),
            ]),
            _c("span", [_vm._v("今日出勤")]),
          ]
        ),
        _c(
          "div",
          {
            staticStyle: {
              width: "50%",
              display: "flex",
              "flex-direction": "column",
              "justify-content": "space-between",
              "align-items": "center",
              background: "#E9F7FF",
              padding: "10px 0",
              "border-radius": "10px",
            },
          },
          [
            _c("span", { staticClass: "font-28 font-bold" }, [
              _vm._v(_vm._s(_vm.teamDataKq.teamCql || "78%")),
            ]),
            _c("span", [_vm._v("出勤率")]),
          ]
        ),
      ]),
      _c(
        "vue-scroll",
        {
          staticClass: "flex-1",
          staticStyle: { height: "264px" },
          attrs: { ops: _vm.ops },
        },
        [
          _c("standard-table", {
            staticClass: "close-table2 scroll-tabox",
            attrs: {
              columns: _vm.teamColumns,
              dataSource: _vm.teamRankList,
              rowKey: "id",
              pagination: false,
              isAlert: false,
              bordered: false,
            },
            scopedSlots: _vm._u([
              {
                key: "cql",
                fn: function (ref) {
                  var text = ref.text
                  return [_c("span", [_vm._v(" " + _vm._s(text) + "% ")])]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "black font-16 font-bold overtext-1 flex",
        staticStyle: { "margin-bottom": "15px" },
      },
      [
        _c("img", {
          staticStyle: { width: "25px", height: "25px" },
          attrs: { src: require("@/assets/img/payroll-card1.png"), alt: "" },
        }),
        _c("span", { staticClass: "ml-05" }, [_vm._v("班组情况概览")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }