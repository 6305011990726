<template>
  <div>
    <a-row :gutter="16">
      <a-col :span="15">
        <!-- 预警处理情况 -->
        <warning-status></warning-status>
        <!-- 项目数统计、项目分布情况、地图 -->
        <project-map style="margin-top: 15px;" :officeList="officeList"></project-map>
      </a-col>
      <a-col :span="9">
        <!-- 危大工程情况 -->
        <dangerous-project :officeList="officeList"></dangerous-project>
        <!-- 项目检查情况 -->
        <project-inspection style="margin-top: 15px;" :officeList="officeList"></project-inspection>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import WarningStatus from './components/WarningStatus.vue';
import ProjectMap from './components/ProjectMap.vue';
import DangerousProject from './components/DangerousProject.vue';
import ProjectInspection from './components/ProjectInspection.vue';
import { getOfficeListByParentOffice2 } from "@/services/statistics";
import { mapGetters, mapMutations } from "vuex";
import { getIsGcglb, getDeptDepth } from '@/services/user'
import { session } from '@/utils/storage'
export default {
  components: { WarningStatus, ProjectMap, DangerousProject, ProjectInspection },
  data() {
    return {
      officeList: []
    };
  },

  computed: {
    ...mapGetters("account", ["user"]),
  },

  created() {
    this.getOfficeList()
    //获取isGcglb
    getIsGcglb().then(res => {
      if (res.data.code == 0) {
        let user = this.user
        user.isGcglb = res.data.data
        this.setUser(user)
      } else {
        this.$message.error(res.data.msg)
      }
    })

    //获取公司层级
    const tenantId = session.get('Tenant-Id') ? session.get('Tenant-Id') : '1'
    if (tenantId != 1) {
      getDeptDepth().then(res => {
        if (res.data.code == 0) {
          let user = this.user
          user.checkLevel = res.data.data
          this.setUser(user)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    }
  },

  methods: {
    ...mapMutations("account", ["setUser"]),
    getOfficeList() {
      // 获取账号下的单位
      const id = this.user.officeId;
      getOfficeListByParentOffice2({ parentOfficeId: id }).then((res) => {
        if (res.data.code == 0) {
          this.officeList = res.data.data;
          // 数据过滤成当前账号登录的信息
          // let items = this.officeList.filter((item) => {
          //   return item.id === id;
          // });
          // this.defaultUnit = items[0].name;
        } else {
          this.error = res.data.msg;
          this.$message.error(res.data.msg, 3);
        }
      })
    }
  },
};
</script>

<style lang="less" scoped>

</style>