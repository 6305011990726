<template>
  <div>
    <div class="constructiontTable_index">
      <a-tabs :default-active-key="defaultActiveKey" @change="callback" forceRender>
        <a-tab-pane key="1" tab="在场管理人员"> </a-tab-pane>
        <a-tab-pane key="0" tab="离场管理人员"> </a-tab-pane>
      </a-tabs>
      <form-index
        ref="formindex"
        :formData="formData"
        :flexAction="true"
        :baiduMapBoolen="false"
        @handleSubmit="handleSubmit"
        @handleChange="handleChange"
        @handlSelect="handlSelect"
      >
        <!-- 下部分按钮插槽 -->
        <template #flexAction>
          <a-space :style="{ marginTop: '4px' }">
            <a-button icon="search" type="primary" html-type="submit">查询</a-button>
            <a-button @click="close"> 重置 </a-button>
          </a-space>
        </template>
      </form-index>
      <a-button style="margin-right: 8px;" icon="plus" type="primary" @click="addPerson">新 增</a-button>
      <a-space class="operator">
        <!-- <a-button v-if="canOperate && (user.projectId != '90cb52bef5dc41cdb4a3b3336d243fb6' && user.projectId != 'd1e394d934ff4db096c3cd681637e432')" type="primary" icon="export" ghost @click="distribute"> 下发考勤设备 </a-button> -->
        <a-button
        v-if="canOperate && defaultActiveKey == 1"
        @click="distribute2(false)"
        ghost
        type="primary"
        icon="cloud-download"
        >分步下发考勤设备</a-button>
        <a-button
        v-if="canOperate && defaultActiveKey == 0"
        @click="distribute2(true)"
        ghost
        type="primary"
        icon="cloud-download"
        >批量删除考勤权限</a-button>
        <!-- <a-button @click="delAccess()" type="primary" ghost><a-icon type="user-delete" />批量删除考勤权限</a-button> -->
        <a-button icon="history" ghost @click="failRecord" style="background: #fd9e06 !important; color: #fff; border-color:#fd9e06" > 下发失败记录 </a-button>
        <!-- <span class="task-tips" v-if="taskObj.taskCount && taskObj.taskCount > 0" @click="handleTaskTips">共有{{taskObj.taskCount || 0}}个下发任务，已结束{{taskObj.endTaskCount || 0}}个下发任务</span> -->
      </a-space>
      <!-- 表格封装 -->
      <div style="margin-top: 10px;">
        <standard-table
          :columns="columns"
          :dataSource="dataSource"
          rowKey="id"
          :pagination="pagination"
          :loading="tableLoading"
          @change="onPageChange"
          :isAlert="false"
          :bordered="true"
          :selectedRows.sync="selectedRows"
          @update:selectedRows="updateSelectRows($event)"
          tableSize="middle"
        >
          <template slot="index" slot-scope="{ index }">
            <span>
              {{
                (pagination.current - 1) * pagination.pageSize +
                parseInt(index) +
                1
              }}
            </span>
          </template>
          <template slot="roleName" slot-scope="{ text }">
            <template v-if="text && text.length > 0">
               <div v-for="(item,index) in text" :key="index">
                {{item}}
              </div>
            </template>
          </template>
          <template slot="manPhone" slot-scope="{ text, record }">
            <div class="flex flex-column a-center mt-1">
              <a-avatar shape="square" :size="64" :src="text" icon="user"/>
              <div class="flex a-center j-center" style="margin-top: 5px">
                <div v-if="record.hkFace != '' && record.hkFace != null">
                  {{record.hkFace}}
                </div>
              </div>
            </div>
          </template>
          <template slot="hkFace" slot-scope="{ text }">
          <div class="flex a-center j-center">
            <div v-if="text != '' && text != null">
              {{text}}
            </div>
            <a-spin :spinning="!text"></a-spin>
          </div>
        </template>
          <template slot="isLogin" slot-scope="{ text }">
            <span v-if="text == 0"><i class="iconfont duigouxiao" style="color:#00CE86"></i></span>
            <span v-else><i class="iconfont path" style="color:#FF5C77"></i></span>
            <!-- <span>{{ text == 0 ? '启用' : '禁用' }}</span> -->
            <!-- <a-switch :checked="record.isLogin == 0 ? true : false" checked-children="启用" un-checked-children="禁用" /> -->
          </template>
          <template slot="isReportAtte" slot-scope="{ text }">
            <!-- <span>{{ text == 0 ? '否' : '是' }}</span> -->
            <span v-if="text == 1"><i class="iconfont duigouxiao" style="color:#00CE86"></i></span>
            <span v-else><i class="iconfont path" style="color:#FF5C77"></i></span>
          </template>
          <div slot="action" slot-scope="{ record }">
            <div>
              <a @click="viewEditing(record, false)">修改</a>
              <a-divider v-if="record.status == 1" type="vertical" />
              <a v-if="record.status == 1" style="color: red;" @click="setLeave(record)">离场</a>
              <!-- <a-divider type="vertical" />
              <a style="color: red;" @click="recorDelete(record)">删除</a> -->
            </div>
          </div>
        </standard-table>
      </div>
    </div>
    <a-modal v-model="modalVisible" :title="modalTitle" :destroyOnClose="true" :width="modalWidth" :footer="null" @cancel="modalCancel">
      <span v-if="modalTitle === '下发任务'">该列表有效期为3小时,结束后请前去“下发失败记录”中查看是否下发失败</span>
      <!-- <div v-if="modalTitle === '下发失败记录'" class="mb-1">
        <a-button type="primary" @click="clearFailRecord">清理失败人员</a-button>
        <span class="ml-2">注：该清理将会清理掉人员所有考勤机的打卡权限，例如：1号设备下发失败，清理后，2号设备也将无法打卡。</span>
      </div> -->
      <span class="mt-1" v-if="errMsg">{{errMsg}}</span>
      <standard-table
        class="mt-1"
        :columns="modalColumns"
        :dataSource="modalDataSource"
        rowKey="id"
        :pagination="modalPagination"
        :loading="modalTableLoading"
        @change="onModalPageChange"
        :isAlert="false"
        :bordered="true"
        tableSize="middle"
      >
        <template slot="index" slot-scope="{ index }">
          <span>
            {{
              (modalPagination.current - 1) * modalPagination.pageSize +
              parseInt(index) +
              1
            }}
          </span>
        </template>
        <!-- <template slot="name" slot-scope="{ record }">
          <span class="task-tips" @click="viewEditing(record, true)">
            {{ record.name }}
          </span>
        </template> -->
      </standard-table>
    </a-modal>
    <distribute-dialog
      :visible="distributeVisible"
      @cancel="distributeVisible = false"
      :isDel="isDel"
      :modalTitle="isDel ? '删除考勤权限' : '下发考勤设备'"
      :workNumList="userIdList"/>
  </div>
</template>

<script>
import StandardTable from "@/components/table/StandardTable";
import formIndex from "@/pages/components/form/index";
import {
  getProAllOfficeList,
  getCheckOfficeList,
  getDetailOffice,
  getMageUserDetail,
  getSysProOffice,
  delProOffUser,
  batchSyncPersonToPlatform,
  batchDelPersonToPlatform,
  getDistributePersonErrList,
  getManagerTask,
  getSysProManegeList,
  getMangeHkFace,
  setMangeUserLeave,
  clearFailRecord
} from "@/services/participationUnit";
import { mapGetters} from "vuex";
import { getRoleListBySysUserList } from "@/services/sys";
import DistributeDialog from '@/pages/participationUnit/management/dialogs/distributeDialog';
import { set } from 'date-fns';
import { useRender } from '@/hooks/useRender.js'

const failColumns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
    // fixed: "left",
  },
  {
    title: "人员ID",
    dataIndex: "personId",
    align: "center",
    width: 110,
    scopedSlots: { customRender: "personId" },
    // fixed: "left",
  },
  {
    title: "姓名",
    dataIndex: "name",
    align: "center",
    width: 110,
    scopedSlots: { customRender: "name" },
    // fixed: "left",
  },
  {
    title: "人员类型",
    dataIndex: "builderType",
    width: 110,
    align: "center",
    // ellipsis: true,
    scopedSlots: { customRender: "builderType" },
    // fixed: "left",
  },
  {
    title: "设备名称",
    align: "center",
    width: 100,
    dataIndex: "deviceName",
    scopedSlots: { customRender: "deviceName" },
  },
  {
    title: "下发时间",
    align: "center",
    width: 150,
    dataIndex: "createTime",
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "错误信息",
    align: "center",
    width: 180,
    dataIndex: "errInfo",
    scopedSlots: { customRender: "errInfo" },
  },
];
const taskColumns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
    // fixed: "left",
  },
  {
    title: "姓名",
    dataIndex: "name",
    align: "center",
    width: 110,
    scopedSlots: { customRender: "name" },
    // fixed: "left",
  },
  {
    title: "状态",
    dataIndex: "step",
    align: "center",
    // ellipsis: true,
    scopedSlots: { customRender: "step" },
    // fixed: "left",
  },
];
const formData = [
  {
    label: "姓名",
    placeholder: "请填写姓名",
    decorator: [
      "userName",
      { rules: [{ required: false, message: "请填写姓名" }] },
    ],
    type: "input",
    key: "userName",
    col: 5,
    labelCol: 5,
    wrapperCol: 16,
    display: true,
  },
  {
    label: "手机号",
    placeholder: "请输入手机号",
    decorator: [
      "phone",
      { rules: [{ required: false, message: "请输入手机号" }] },
    ],
    type: "input",
    key: "phone",
    col: 5,
    labelCol: 6,
    wrapperCol: 16,
    display: true,
  },
  {
    label: "所属单位",
    placeholder: "请输入所属单位",
    decorator: [
      "officeId",
      { rules: [{ required: false, message: "请选择所属单位" }] },
    ],
    type: "inputGroup",
    key: "officeId",
    dataSource: [],
    col: 5,
    labelCol: 6,
    wrapperCol: 16,
  },
  {
    type: "action",
    col: 6,
    display: true,
  },

];
export default {
  components: {
    StandardTable, //表格封装
    formIndex,
    DistributeDialog
  },
  data() {
    const columns = [
      {
        title: "序号",
        width: 65,
        scopedSlots: { customRender: "index" },
        // customRender: (text, record, index) => `${index + 1}`,
        align: "center",
        // fixed: "left",
      },
      {
        title: "姓名",
        dataIndex: "userName",
        align: "center",
        width: 110,
        scopedSlots: { customRender: "userName" },
        // fixed: "left",
      },
      {
        title: <a-tooltip>
          <template slot="title">人脸评分越高对于下发设备成功的概率越高，建议评分大于80分</template>
          头像评分<a-icon style="margin-left:5px" type="question-circle" theme="twoTone" />
        </a-tooltip>,
        dataIndex: "manPhone",
        align: "center",
        width: 120,
        scopedSlots: { customRender: "manPhone" },
      },
      {
        title: "所属公司",
        dataIndex: "officeName",
        align: "center",
        width: 150,
        ellipsis: true,
      },
      {
        title: "身份证号",
        dataIndex: "idCard",
        width: 150,
        align: "center",
        // ellipsis: true,
        // fixed: "left",
      },
      {
        title: "联系电话",
        dataIndex: "phone",
        align: "center",
        width: 100,
        scopedSlots: { customRender: "phone" },
      },
      {
        title: "职务",
        align: "center",
        width: 130,
        dataIndex: "roleName",
        scopedSlots: { customRender: "roleName" },
      },
      {
        title: "是否在场",
        dataIndex: "status",
        width: 80,
        ellipsis: true,
        align: "center",
        scopedSlots: { customRender: "isReportAtte" },
      },
      {
        title: "上报建委",
        dataIndex: "isReportAtte",
        width: 80,
        align: "center",
        scopedSlots: { customRender: "isReportAtte" },
      },
      {
        title: "启用账号",
        dataIndex: "isLogin",
        width: 80,
        ellipsis: true,
        align: "center",
        scopedSlots: { customRender: "isLogin" },
      },
      {
        title: "登记时间",
        dataIndex: "createTime",
        width: 80,
        align: "center",
        customRender: (text) => useRender.renderDate(text)
      },
      {
        title: "操作",
        align: "center",
        width: 80,
        // fixed: "right",
        scopedSlots: { customRender: "action" },
      },
    ];
    return {
      projectArr: [],
      columns: columns, //表格数据
      dataSource: [],
      pagination: {
        showSizeChanger:true,
        pageSizeOptions: ["10", "50", "100", "150"],
        current: 1,
        pageSize: 50,
        total: 0,
        showLessItems: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      tableLoading: false,
      formData: formData,
      prams: {
        pageNumber: 1,
        pageSize: 50,
        status: 1
      },
      loadingText: "数据加载中...",
      cPercent: 0,
      isExporting: false,
      projectId: "",
      query: this.$route.query,
      selectedRows: [], //选中的数据
      modalTitle: '',
      modalWidth: 950,
      modalVisible: false,
      modalColumns: null,
      modalDataSource: [],
      modalPagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      modalTableLoading: false,
      modalParams: {
        pageNumber: 1,
        pageSize: 10,
        builderType: 1
      },
      taskObj: {
        taskCount: 0,
        endTaskCount: 0
      },
      arrRoles:['zong_bao_lao_wu',], // 下发设备权限
      staffHkFace: [],
      distributeVisible: false,
      userIdList:[],
      isDel: false, // true=删除考勤权限
      defaultActiveKey: '1',
      errMsg: '',
      newRole: [], // 角色数组
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
    canOperate() {
      return this.user.nowRoleKey.some(role => this.arrRoles.includes(role));
    },
  },
  watch: {
    loadingText: {
      handler(newName, oldName) {
        this.loadingText = newName;
      },
      immediate: true,
    },
  },
  mounted() {
    this.getRoleList()
    this.projectId = this.user.projectId;
    this.prams= {
        pageNumber: 1,
        pageSize: 50,
        projectId:this.user.projectId,
        status: 1
      },
      this.aqxjlist(this.prams);
    this.getZdata(getProAllOfficeList, {value: '', item: {key: 'officeId'}}, "officeName");
    this.getManagerTask(this.modalParams)
  },
  methods: {
    // 管理人员离场
    setLeave(record) {
      let params = {
        userId: record.id,
        projectId: this.user.projectId,
      }
      this.confirm("离场", `是否确认该管理人员离场`).then(() => {
        setMangeUserLeave(params).then(res => {
        if (res.data.code === 0) {
          if (res.data.data == true) {
            this.$message.success("离场操作成功", 5);
          }
        }
      })
      });

    },
    // 封装弹窗确定按钮
    confirm(title, content) {
      return new Promise((resolve, reject) => {
        this.$confirm({
          title: title,
          content: content,
          onOk() {
            resolve();
          },
        });
      });
    },
    // 删除接口
    recorDelete(val, text) {
      let params = {
        projectId:this.projectId,
        userId: val.id
      }
      this.confirm("删除", `是否删除该用户`).then(() => {
        delProOffUser(params).then((res) => {
          if (res.data.code === 0) {
            this.$message.success("删除成功", 5);
            this.aqxjlist(this.prams);
          } else {
            this.$message.error(res.data.msg, 5);
          }
        });
      });
    },
    // 查看编辑
    viewEditing(e, isFail) {
      if (isFail) {
        e = this.dataSource.find(el => e.userId == el.id)
      }
      if (e) {
        this.modalVisible = false
        this.$router.push({
          name: "管理员详情",
          query: {
            userId: e.id,
            projectId: this.projectId
          },
        });
      } else {
        this.$message.warning('没有此管理人员！')
      }
    },
    aqxjlist(prams) {
      this.tableLoading = true;
      getSysProManegeList(prams).then((res) => {
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
          const sysUserIdList = this.dataSource.map(item => item.id)
          getRoleListBySysUserList({}).then((result1) => {
            // 筛选数组=项目角色
            if (result1.data.code === 0) {
              let data = result1.data.data.filter((obj) => obj.roleType == 2)
              const arr = [];
              data.forEach((item) => {
                arr.push({
                  name: item.name,
                  value: item.id,
                });
              });
              this.dataSource.map(item => {
                // 处理角色权限-roleIds字段 ",14,15,16,"
                if (item.roleIds) {
                  let roleIds = item.roleIds.split(",").filter(item => item != '').map(roles => {
                    const match = arr.find((items) => items.value == roles);
                    return match ? match.name : undefined;
                  })
                  item.roleName = roleIds
                }
              })
              this.$nextTick(() => {
                this.dataSource = [...this.dataSource]
              });
            }
          });         
          getMangeHkFace(sysUserIdList).then(result => {
            // 获取人脸评分
            if (result.data.code === 0) {
              // 海康人脸评分
              this.staffHkFace = result.data.data
              this.dataSource.map(item => {
                this.staffHkFace.map(key => {
                  if (item.id == key.sysUserId) {
                    item.hkFace = key.scoreContent
                  }
                })
              })
              this.dataSource = [...this.dataSource]
            } else {
              // 处理海康接口报错
              this.dataSource.map(item => {
                    item.hkFace = '暂无'
              })
              this.dataSource = [...this.dataSource]
            }
          })
        } else {
          this.$message.error(res.data.msg)
        }
        this.tableLoading = false;
      });
    },
    handleChange(e) {
      this.projectArr = [];
      if (e.item.key == 'officeId') {
        if (!e.value) {
          this.$refs["formindex"].setFieldsValue('', {}, "officeId");
        }
        this.getZdata(getProAllOfficeList, e, "officeName");
      } else {
        this.getZdata(getCheckOfficeList, e, "name");
        // 获取当前匹配key，赋值相对于数组
        this.formData.forEach((item) => {
          if (e.item.key === item.key) {
            item.dataSource = this.projectArr;
          }
        });
      }
    },
    // 接口封装
    /**
     * msg 接收接口名称
     * e 数据
     * name 参数名
     * */
     getZdata(msg, e, name) {
      let data = {
        [name]: e.value,
        projectId: this.user.projectId,
      };

      msg(data).then((res) => {
        if (res.status == 200) {
          const data = res.data.data.list ? res.data.data.list : res.data.data
          data.forEach((item) => {
            this.projectArr.push({
              title: item[name],
              id: item[name],
            });
          });
          this.projectList = data;
          // 获取当前匹配key，赋值相对于数组
          this.formData.forEach((item) => {
            if (e.item.key === item.key) {
              item.dataSource = this.projectArr;
            }
          });
        }
      });
    },
    // 根据条件查询公司
    // aqxCheckList()
    // 对下拉框做数据处理
    formDataFn(key, data) {
      this.formData.forEach((item) => {
        if (item.key == key) {
          item.selectlist = data;
        }
      });
    },
    // 提交表单数据
    handleSubmit(e) {
      let obj = Object.assign({}, e, this.prams);
      obj.pageNumber = 1
      this.pagination.current = 1;
      this.selectedRows = []
      this.aqxjlist(obj);
      this.getManagerTask(this.modalParams)
    },
    // 特殊数据处理
    handlSelect(obj) {
      const { val, keys } = obj;
      if (keys === "officeId") {
        this.projectList.forEach((item) => {
          if (item.officeName == val) {
            this.$refs["formindex"].setFieldsValue(
              item.officeId,
              {},
              "officeId"
            );
          }
        });
      }
    },
    // 重置
    close() {
      this.prams.pageNumber = 1
      this.prams.pageSize = 50
      this.pagination.current = 1;
      this.pagination.pageSize = 50;
      this.aqxjlist(this.prams);
      this.$refs["formindex"].resetFields();
    },
    //表格 - 分页查询
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      this.prams.pageNumber = e.current;
      this.prams.pageSize = e.pageSize;
      this.pagination.current = e.current;
      this.pagination.pageSize = e.pageSize;
      this.aqxjlist(Object.assign(obj, this.prams));
    },
    getProAllOfficeList() {
      getProAllOfficeList({projectId:this.user.projectId}).then(res => {
        const arr = [];
        res.data.data.forEach((item) => {
          arr.push({
            name: item.officeName,
            value: item.officeId,
          });
        });
        this.formDataFn("officeId", arr);
      })
    },
    //下发考勤设备
    distribute() {
      const _that = this
      if (this.selectedRows.length > 0) {
      this.$confirm({
        title: '确定下发管理人员到考勤设备？',
        onOk() {
            let userIdList = []
            _that.selectedRows.forEach(el => {
              userIdList.push(el.id)
            })
            batchSyncPersonToPlatform({ threadId: new Date().getTime(), userIdList }).then(res => {
              if (res.data.code === 0) {
                _that.$message.success(res.data.msg)
                _that.getManagerTask({pageNumber: 1,pageSize: 10,})
              } else {
                _that.$message.error(res.data.msg)
              }
            })
          }
        })
      } else {
        this.$message.error('请选择人员！')
      }
    },
    //下发考勤设备
    distribute2(isDel) {
      if (this.selectedRows.length > 0) {
        this.userIdList = []//先初始化这个list
        this.selectedRows.forEach(el => {
          this.userIdList.push(el.id)
        })
        this.isDel = isDel
        this.distributeVisible = true

      } else {
        this.$message.error('请选择人员！')
      }
    },
    delAccess() {
      const _that = this
      if (this.selectedRows.length > 0) {
      this.$confirm({
        title: '确定批量删除管理人员权限？',
        onOk() {
            let userIdList = []
            _that.selectedRows.forEach(el => {
              userIdList.push(el.id)
            })
            batchDelPersonToPlatform({ threadId: new Date().getTime(), userIdList }).then(res => {
              if (res.data.code === 0) {
                _that.$message.success(res.data.msg)
                _that.getManagerTask({pageNumber: 1,pageSize: 10,})
              } else {
                _that.$message.error(res.data.msg)
              }
            })
          }
        })
      } else {
        this.$message.error('请选择人员！')
      }
    },
    updateSelectRows(e) {
      console.log(e)
    },
    onModalPageChange(e) {
      // let obj = this.$refs["formindex"].getFieldValue();
      this.modalParams.pageNumber = e.current;
      this.modalPagination.current = e.current;
      if (this.modalTitle === '下发失败记录') {
        this.getDistributePersonErrList(Object.assign({}, this.modalParams))
      } else if (this.modalTitle === '下发任务') {
        this.getManagerTask(Object.assign({}, this.modalParams))
      }
    },
    failRecord() {
      this.modalTitle = '下发失败记录'
      this.modalVisible = true
      this.modalTableLoading = true
      this.modalColumns = failColumns
      this.modalParams.pageNumber = 1
      this.modalWidth = 950
      this.getDistributePersonErrList(this.modalParams)
    },
    getDistributePersonErrList(params) {
      getDistributePersonErrList(params).then(res => {
        this.modalTableLoading = false
        if (res.data.code === 0) {
          this.modalDataSource = res.data.data;
          this.modalPagination.total = res.data.data.length;
          // this.modalDataSource.forEach(el => {
          //   if (el.createTime) {
          //     el.createTime = moment(el.createTime).format("YYYY-MM-DD");
          //   }
          // })
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    clearFailRecord() {
      clearFailRecord().then(res => {
        if (res.data.code === 0) {
          this.errMsg = res.data.msg
          this.$message.warning(res.data.msg)
          this.getDistributePersonErrList(this.modalParams)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    handleTaskTips() {
      this.modalTitle = '下发任务'
      this.modalVisible = true
      this.modalColumns = taskColumns
      this.modalParams.pageNumber = 1
      this.modalWidth = 700
      this.getManagerTask(this.modalParams)
    },
    getManagerTask(params) {
      getManagerTask(params).then(res => {
        if (res.data.code === 0) {
          if (res.data.data) {
            this.taskObj = res.data.data
            this.modalDataSource = this.taskObj.taskDetailList
            this.modalPagination.total = this.taskObj.taskDetailList.length;
          }
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    modalCancel() {
      this.modalPagination = {
        current: 1,
        pageSize: 10,
        total: 0,
      }
    },
    // 新增人员
    addPerson() {
      this.$router.push({
            name: "分配管理人员",
            query: {
              // data: this.query.data,
              officeId: this.query.officeId ? this.query.officeId: this.user.officeId,
              projectId: this.query.projectId ? this.query.projectId : this.user.projectId,
            },
          });
    },
    callback(key) {
      if (key == 1) {
        this.prams.status = 1;
      } else {
        this.prams.status = 0;
      }
      this.defaultActiveKey = key
      this.close();
    },
    // 获取劳务角色数组
    getRoleList() {
      getRoleListBySysUserList({}).then((res) => {
        // 筛选数组=项目角色
        let data = res.data.data.filter((obj) => obj.roleType == 2)
        const arr = [];
        data.forEach((item) => {
          arr.push({
            name: item.name,
            value: item.id,
          });
        });
        this.newRole = arr
      });
    },
  },
};
</script>

<style lang="less" scoped>

.constructiontTable_index {
  // .operator  {
  //   // margin-left: 20px;
  // }
  .plug-Overview {
    font-size: 16px;
    color: #101010;
    font-weight: bold;
  }

  padding: 24px;
  background: #fff;
  border-radius: 6px;
  margin-bottom: 10px;

  .c_i_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 25px;

    .c_i_h_center {
      padding: 16px;
      border-radius: 4px;
      width: 180px;
      height: 130px;
      margin-right: 50px;

      div:nth-child(1) {
        font-size: 16px;
      }

      div:nth-child(2) {
        margin-top: 26px;
        font-size: 24px;
      }
    }
  }
}

.sTables {
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    // width: 100%;
    padding: 0 10px;
    text-align: center;
    cursor: pointer;
  }
  div:nth-child(1) {
    color: #307dfa;
  }
  div:nth-child(2) {
    color: #ff5c77;
  }
  div:nth-child(3) {
    color: #615e83;
  }
}
.content_div {
  padding: 5px 0;
  cursor: pointer;
}
.content_div:hover {
  color: #307dfa;
}
.task-tips {
  color: #4377cc;
  text-decoration: underline;
  cursor: pointer;
}
/deep/.ant-table-selection-col{
  width: 30px !important;
}
/deep/ .ant-form-item{
  margin-bottom: 10px ;
}
.plug-form .ant-form .ant-btn {
  // 处理表单组件强制margin-right问题
  margin-right: 0
}
</style>
