var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-dropdown",
        [
          _c(
            "div",
            {
              staticClass: "header-avatar overtext-1",
              staticStyle: { cursor: "pointer" },
            },
            [
              _c("a-avatar", {
                staticClass: "avatar",
                attrs: { size: "small", shape: "circle", src: _vm.user.avatar },
              }),
              _c(
                "span",
                {
                  staticClass: "name overtext-1",
                  staticStyle: { "max-width": "150px" },
                  attrs: { title: _vm.user.name },
                },
                [_vm._v(_vm._s(_vm.user.name))]
              ),
            ],
            1
          ),
          _c(
            "a-menu",
            {
              class: ["avatar-menu"],
              attrs: { slot: "overlay" },
              slot: "overlay",
            },
            [
              _c(
                "a-menu-item",
                { on: { click: _vm.changePassword } },
                [
                  _c("a-icon", { attrs: { type: "setting" } }),
                  _c("span", [_vm._v("修改密码")]),
                ],
                1
              ),
              _c("a-menu-divider"),
              _c(
                "a-menu-item",
                { on: { click: _vm.logout } },
                [
                  _c("a-icon", {
                    staticStyle: { "margin-right": "8px" },
                    attrs: { type: "poweroff" },
                  }),
                  _c("span", [_vm._v("退出登录")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            visible: _vm.updatePwdModal,
            footer: null,
            destroyOnClose: true,
          },
          on: { cancel: _vm.handleCancel },
        },
        [_c("update-password", { on: { cancel: _vm.handleCancel } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }