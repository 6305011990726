var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "drawer", staticClass: "modi-drawer" },
    [
      _c(
        "a-drawer",
        {
          attrs: {
            width: 900,
            visible: _vm.visible,
            title: "编辑安全责任管理",
            "body-style": { paddingBottom: "80px" },
            getContainer: function () {
              return _vm.$refs.drawer
            },
            headerStyle: { textAlign: "center" },
          },
          on: { close: _vm.onClose },
        },
        [
          _c(
            "a-form",
            {
              attrs: {
                form: _vm.form,
                "label-col": { span: 6 },
                "wrapper-col": { span: 16 },
                layout: "horizontal",
              },
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: "项目名称",
                            labelCol: { span: 2 },
                            "wrapper-col": { span: 22 },
                          },
                        },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "projectName",
                                  { rules: [{ required: false, message: "" }] },
                                ],
                                expression:
                                  "[\n                'projectName',\n                { rules: [{ required: false, message: '' }] },\n              ]",
                              },
                            ],
                            attrs: { disabled: true, placeholder: "项目名称" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: "所属单位",
                            labelCol: { span: 2 },
                            "wrapper-col": { span: 22 },
                          },
                        },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "childOfficeName",
                                  { rules: [{ required: false, message: "" }] },
                                ],
                                expression:
                                  "[\n                'childOfficeName',\n                { rules: [{ required: false, message: '' }] },\n              ]",
                              },
                            ],
                            attrs: {
                              disabled: true,
                              placeholder: "工程管理部",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-collapse",
                {
                  attrs: { "expand-icon-position": "right", accordion: "" },
                  model: {
                    value: _vm.activeKey,
                    callback: function ($$v) {
                      _vm.activeKey = $$v
                    },
                    expression: "activeKey",
                  },
                },
                [
                  _vm.user.loginType == 1 ||
                  (_vm.user.id == _vm.obj.userId && !_vm.canOperate)
                    ? _c(
                        "a-collapse-panel",
                        { key: "1", attrs: { header: "项目经理" } },
                        [
                          !_vm.obj.xmleaderName
                            ? _c(
                                "a-button",
                                {
                                  staticStyle: { "margin-top": "-4px" },
                                  attrs: { slot: "extra" },
                                  on: { click: _vm.addXmLeader },
                                  slot: "extra",
                                },
                                [_vm._v("添加经理")]
                              )
                            : _vm._e(),
                          _vm.xmModalUser
                            ? [
                                _c(
                                  "a-row",
                                  [
                                    _c(
                                      "a-col",
                                      { attrs: { span: 24 } },
                                      [
                                        _c(
                                          "a-form-item",
                                          {
                                            attrs: {
                                              label: "请选择人员",
                                              labelCol: { span: 6 },
                                              "wrapper-col": { span: 6 },
                                            },
                                          },
                                          [
                                            _c(
                                              "a-select",
                                              {
                                                staticStyle: { width: "200px" },
                                                attrs: {
                                                  "show-search": "",
                                                  allowClear: "",
                                                  placeholder: "请输入或选择",
                                                  "option-filter-prop":
                                                    "children",
                                                  "filter-option":
                                                    _vm.filterOption,
                                                },
                                                on: {
                                                  change: _vm.handleUserChange,
                                                },
                                                model: {
                                                  value:
                                                    _vm.changeUserVo.userId,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.changeUserVo,
                                                      "userId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "changeUserVo.userId",
                                                },
                                              },
                                              _vm._l(
                                                _vm.userList,
                                                function (item) {
                                                  return _c(
                                                    "a-select-option",
                                                    {
                                                      key: item.id,
                                                      attrs: {
                                                        value: item.id,
                                                        title: item.name,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(item.name) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-col",
                                      { attrs: { span: 24 } },
                                      [
                                        _c(
                                          "a-form-item",
                                          {
                                            attrs: {
                                              label: "姓名",
                                              labelCol: { span: 6 },
                                              "wrapper-col": { span: 6 },
                                            },
                                          },
                                          [
                                            _c("a-input", {
                                              attrs: {
                                                disabled: true,
                                                placeholder: "经理姓名",
                                              },
                                              model: {
                                                value:
                                                  _vm.changeUserVo.userName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.changeUserVo,
                                                    "userName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "changeUserVo.userName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-col",
                                      { attrs: { span: 24 } },
                                      [
                                        _c(
                                          "a-form-item",
                                          {
                                            attrs: {
                                              label: "电话",
                                              labelCol: { span: 6 },
                                              "wrapper-col": { span: 6 },
                                            },
                                          },
                                          [
                                            _c("a-input", {
                                              attrs: {
                                                disabled: true,
                                                placeholder: "经理电话",
                                              },
                                              model: {
                                                value: _vm.changeUserVo.phone,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.changeUserVo,
                                                    "phone",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "changeUserVo.phone",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-col",
                                      { attrs: { span: 24 } },
                                      [
                                        _c(
                                          "a-form-item",
                                          {
                                            attrs: {
                                              allowClear: false,
                                              label: "项目经理任命时间",
                                              labelCol: { span: 6 },
                                              "wrapper-col": { span: 16 },
                                            },
                                          },
                                          [
                                            _c("a-date-picker", {
                                              attrs: {
                                                placeholder: "项目经理任命时间",
                                                defaultValue: _vm.addAppointDate
                                                  ? _vm.moment(
                                                      _vm.addAppointDate,
                                                      "YYYY-MM-DD"
                                                    )
                                                  : "",
                                              },
                                              on: { change: _vm.onChangeAdd },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("a-col", { attrs: { span: 6 } }),
                                    _c(
                                      "a-col",
                                      { attrs: { span: 18 } },
                                      [
                                        _c(
                                          "a-button",
                                          {
                                            staticStyle: {
                                              "margin-right": "10px",
                                              "margin-bottom": "10px",
                                            },
                                            attrs: { type: "primary" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleUserOk(1)
                                              },
                                            },
                                          },
                                          [_vm._v("确定添加")]
                                        ),
                                        _c(
                                          "a-button",
                                          { on: { click: _vm.userCancel } },
                                          [_vm._v("关 闭")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                          !_vm.xmModalUser &&
                          _vm.obj.xmleaderName &&
                          _vm.obj.xmleaderPhone
                            ? [
                                _c(
                                  "a-row",
                                  { attrs: { gutter: 16 } },
                                  [
                                    _c(
                                      "a-col",
                                      { attrs: { span: 24 } },
                                      [
                                        _c(
                                          "a-form-item",
                                          {
                                            attrs: {
                                              allowClear: false,
                                              label: "姓名",
                                              labelCol: { span: 6 },
                                              "wrapper-col": { span: 6 },
                                            },
                                          },
                                          [
                                            _c("a-input", {
                                              attrs: {
                                                placeholder: "项目经理姓名",
                                                disabled: true,
                                              },
                                              model: {
                                                value: _vm.obj.xmleaderName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.obj,
                                                    "xmleaderName",
                                                    $$v
                                                  )
                                                },
                                                expression: "obj.xmleaderName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-col",
                                      { attrs: { span: 24 } },
                                      [
                                        _c(
                                          "a-form-item",
                                          {
                                            attrs: {
                                              allowClear: false,
                                              label: "电话",
                                              labelCol: { span: 6 },
                                              "wrapper-col": { span: 6 },
                                            },
                                          },
                                          [
                                            _c("a-input", {
                                              attrs: {
                                                placeholder: "项目经理电话",
                                                disabled: true,
                                              },
                                              model: {
                                                value: _vm.obj.xmleaderPhone,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.obj,
                                                    "xmleaderPhone",
                                                    $$v
                                                  )
                                                },
                                                expression: "obj.xmleaderPhone",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.obj.xmleaderPhone
                                      ? _c(
                                          "a-col",
                                          { attrs: { span: 24 } },
                                          [
                                            _c(
                                              "a-form-item",
                                              {
                                                attrs: {
                                                  allowClear: false,
                                                  label: "项目经理任命时间",
                                                  labelCol: { span: 6 },
                                                  "wrapper-col": { span: 16 },
                                                },
                                              },
                                              [
                                                _c("a-date-picker", {
                                                  attrs: {
                                                    placeholder:
                                                      "项目经理任命时间",
                                                    defaultValue: _vm.duty
                                                      .appointDate
                                                      ? _vm.moment(
                                                          _vm.duty.appointDate,
                                                          "YYYY-MM-DD"
                                                        )
                                                      : "",
                                                  },
                                                  on: {
                                                    change: _vm.onChangeXMJL,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.obj.isVice ||
                                    (_vm.obj.xmleaderPhone &&
                                      _vm.user.loginType == 1)
                                      ? _c(
                                          "a-col",
                                          { attrs: { span: 24 } },
                                          [
                                            _c(
                                              "a-form-item",
                                              {
                                                attrs: {
                                                  allowClear: false,
                                                  label: "任命为项目副经理",
                                                  labelCol: { span: 6 },
                                                  "wrapper-col": { span: 16 },
                                                },
                                              },
                                              [
                                                _c(
                                                  "a-checkbox",
                                                  {
                                                    attrs: {
                                                      checked: Boolean(
                                                        _vm.obj.isVice
                                                      ),
                                                    },
                                                    on: {
                                                      change: _vm.onAppoint,
                                                    },
                                                  },
                                                  [_vm._v(" 副经理 ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "a-col",
                                      { attrs: { span: 24 } },
                                      [
                                        _c(
                                          "a-form-item",
                                          {
                                            attrs: {
                                              label: "安全生产管理目标责任书",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                },
                                              },
                                              [
                                                _vm.obj.safeManagerDuty
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ant-upload-list-item",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "ant-upload-list-item-info",
                                                          },
                                                          [
                                                            _vm.icludesPdf(
                                                              _vm.obj.safeRms,
                                                              "pdf"
                                                            ) == -1
                                                              ? [
                                                                  _c("img", {
                                                                    staticClass:
                                                                      "image-width",
                                                                    attrs: {
                                                                      src: _vm
                                                                        .obj
                                                                        .safeManagerDuty,
                                                                    },
                                                                  }),
                                                                ]
                                                              : [
                                                                  _c("a-icon", {
                                                                    style: {
                                                                      fontSize:
                                                                        "45px",
                                                                    },
                                                                    attrs: {
                                                                      type: "file-pdf",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.showPdf(
                                                                            _vm.text
                                                                          )
                                                                        },
                                                                    },
                                                                  }),
                                                                ],
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "ant-upload-list-item-actions",
                                                              },
                                                              [
                                                                _c("a-icon", {
                                                                  staticClass:
                                                                    "anticon-eye-o",
                                                                  attrs: {
                                                                    type: "eye",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.onPreview(
                                                                          "safeManagerDuty"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                                _c("a-icon", {
                                                                  staticClass:
                                                                    "anticon-delete",
                                                                  attrs: {
                                                                    type: "delete",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.onDelete(
                                                                          "safeManagerDuty"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "a-upload",
                                                      {
                                                        attrs: {
                                                          name: "file",
                                                          multiple: false,
                                                          beforeUpload:
                                                            _vm.beforeUpload,
                                                          accept: ".pdf",
                                                          customRequest:
                                                            _vm.resourceUpload,
                                                          "file-list":
                                                            _vm.fileList,
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.handleChange,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "a-button",
                                                          {
                                                            attrs: {
                                                              type: "primary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.getName(
                                                                  "safeManagerDuty"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "上传安全生产管理目标责任书"
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-col",
                                      { attrs: { span: 24 } },
                                      [
                                        _c(
                                          "a-form-item",
                                          {
                                            attrs: {
                                              label: "安全生产第一责任人任命书",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                },
                                              },
                                              [
                                                _vm.obj.safeRms
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ant-upload-list-item",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "ant-upload-list-item-info",
                                                          },
                                                          [
                                                            _vm.icludesPdf(
                                                              _vm.obj.safeRms,
                                                              "pdf"
                                                            ) == -1
                                                              ? [
                                                                  _c("img", {
                                                                    staticClass:
                                                                      "image-width",
                                                                    attrs: {
                                                                      src: _vm
                                                                        .obj
                                                                        .safeRms,
                                                                    },
                                                                  }),
                                                                ]
                                                              : [
                                                                  _c("a-icon", {
                                                                    style: {
                                                                      fontSize:
                                                                        "45px",
                                                                    },
                                                                    attrs: {
                                                                      type: "file-pdf",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.showPdf(
                                                                            _vm.text
                                                                          )
                                                                        },
                                                                    },
                                                                  }),
                                                                ],
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "ant-upload-list-item-actions",
                                                              },
                                                              [
                                                                _c("a-icon", {
                                                                  staticClass:
                                                                    "anticon-eye-o",
                                                                  attrs: {
                                                                    type: "eye",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.onPreview(
                                                                          "safeRms"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                                _c("a-icon", {
                                                                  staticClass:
                                                                    "anticon-delete",
                                                                  attrs: {
                                                                    type: "delete",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.onDelete(
                                                                          "safeRms"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "a-upload",
                                                      {
                                                        attrs: {
                                                          name: "file",
                                                          multiple: false,
                                                          beforeUpload:
                                                            _vm.beforeUpload,
                                                          accept: ".pdf",
                                                          customRequest:
                                                            _vm.resourceUpload,
                                                          "file-list":
                                                            _vm.fileList,
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.handleChange,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "a-button",
                                                          {
                                                            attrs: {
                                                              type: "primary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.getName(
                                                                  "safeRms"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "上传安全生产第一责任人任命书（A4）"
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-col",
                                      { attrs: { span: 24 } },
                                      [
                                        _c(
                                          "a-form-item",
                                          {
                                            attrs: {
                                              label: "安全生产第一责任人任命状",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                },
                                              },
                                              [
                                                _vm.obj.safeRmz
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ant-upload-list-item",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "ant-upload-list-item-info",
                                                          },
                                                          [
                                                            _vm.icludesPdf(
                                                              _vm.obj.safeRmz,
                                                              "pdf"
                                                            ) == -1
                                                              ? [
                                                                  _c("img", {
                                                                    staticClass:
                                                                      "image-width",
                                                                    attrs: {
                                                                      src: _vm
                                                                        .obj
                                                                        .safeRmz,
                                                                    },
                                                                  }),
                                                                ]
                                                              : [
                                                                  _c("a-icon", {
                                                                    style: {
                                                                      fontSize:
                                                                        "45px",
                                                                    },
                                                                    attrs: {
                                                                      type: "file-pdf",
                                                                    },
                                                                  }),
                                                                ],
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "ant-upload-list-item-actions",
                                                              },
                                                              [
                                                                _c("a-icon", {
                                                                  staticClass:
                                                                    "anticon-eye-o",
                                                                  attrs: {
                                                                    type: "eye",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.onPreview(
                                                                          "safeRmz"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                                _c("a-icon", {
                                                                  staticClass:
                                                                    "anticon-delete",
                                                                  attrs: {
                                                                    type: "delete",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.onDelete(
                                                                          "safeRmz"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "a-upload",
                                                      {
                                                        attrs: {
                                                          name: "file",
                                                          multiple: false,
                                                          beforeUpload:
                                                            _vm.beforeUpload,
                                                          accept: ".pdf",
                                                          customRequest:
                                                            _vm.resourceUpload,
                                                          "file-list":
                                                            _vm.fileList,
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.handleChange,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "a-button",
                                                          {
                                                            attrs: {
                                                              type: "primary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.getName(
                                                                  "safeRmz"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "上传安全生产第一责任人任命状（A3）"
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-col",
                                      { attrs: { span: 24 } },
                                      [
                                        _c(
                                          "a-form-item",
                                          {
                                            attrs: {
                                              label: "消防安全责任人任命书",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                },
                                              },
                                              [
                                                _vm.obj.xfRms
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ant-upload-list-item",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "ant-upload-list-item-info",
                                                          },
                                                          [
                                                            _vm.icludesPdf(
                                                              _vm.obj.xfRms,
                                                              "pdf"
                                                            ) == -1
                                                              ? [
                                                                  _c("img", {
                                                                    staticClass:
                                                                      "image-width",
                                                                    attrs: {
                                                                      src: _vm
                                                                        .obj
                                                                        .xfRms,
                                                                    },
                                                                  }),
                                                                ]
                                                              : [
                                                                  _c("a-icon", {
                                                                    style: {
                                                                      fontSize:
                                                                        "45px",
                                                                    },
                                                                    attrs: {
                                                                      type: "file-pdf",
                                                                    },
                                                                  }),
                                                                ],
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "ant-upload-list-item-actions",
                                                              },
                                                              [
                                                                _c("a-icon", {
                                                                  staticClass:
                                                                    "anticon-eye-o",
                                                                  attrs: {
                                                                    type: "eye",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.onPreview(
                                                                          "xfRms"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                                _c("a-icon", {
                                                                  staticClass:
                                                                    "anticon-delete",
                                                                  attrs: {
                                                                    type: "delete",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.onDelete(
                                                                          "xfRms"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "a-upload",
                                                      {
                                                        attrs: {
                                                          name: "file",
                                                          multiple: false,
                                                          beforeUpload:
                                                            _vm.beforeUpload,
                                                          accept: ".pdf",
                                                          customRequest:
                                                            _vm.resourceUpload,
                                                          "file-list":
                                                            _vm.fileList,
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.handleChange,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "a-button",
                                                          {
                                                            attrs: {
                                                              type: "primary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.getName(
                                                                  "xfRms"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "上传消防安全责任人任命书（A4）"
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-col",
                                      { attrs: { span: 24 } },
                                      [
                                        _c(
                                          "a-form-item",
                                          {
                                            attrs: {
                                              label: "消防安全责任人任命状",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                },
                                              },
                                              [
                                                _vm.obj.xfRmz
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ant-upload-list-item",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "ant-upload-list-item-info",
                                                          },
                                                          [
                                                            _vm.icludesPdf(
                                                              _vm.obj.xfRmz,
                                                              "pdf"
                                                            ) == -1
                                                              ? [
                                                                  _c("img", {
                                                                    staticClass:
                                                                      "image-width",
                                                                    attrs: {
                                                                      src: _vm
                                                                        .obj
                                                                        .xfRmz,
                                                                    },
                                                                  }),
                                                                ]
                                                              : [
                                                                  _c("a-icon", {
                                                                    style: {
                                                                      fontSize:
                                                                        "45px",
                                                                    },
                                                                    attrs: {
                                                                      type: "file-pdf",
                                                                    },
                                                                  }),
                                                                ],
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "ant-upload-list-item-actions",
                                                              },
                                                              [
                                                                _c("a-icon", {
                                                                  staticClass:
                                                                    "anticon-eye-o",
                                                                  attrs: {
                                                                    type: "eye",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.onPreview(
                                                                          "xfRmz"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                                _c("a-icon", {
                                                                  staticClass:
                                                                    "anticon-delete",
                                                                  attrs: {
                                                                    type: "delete",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.onDelete(
                                                                          "xfRmz"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "a-upload",
                                                      {
                                                        attrs: {
                                                          name: "file",
                                                          multiple: false,
                                                          beforeUpload:
                                                            _vm.beforeUpload,
                                                          accept: ".pdf",
                                                          customRequest:
                                                            _vm.resourceUpload,
                                                          "file-list":
                                                            _vm.fileList,
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.handleChange,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "a-button",
                                                          {
                                                            attrs: {
                                                              type: "primary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.getName(
                                                                  "xfRmz"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "上传消防安全责任人任命状（A3）"
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-col",
                                      { attrs: { span: 24 } },
                                      [
                                        _c(
                                          "a-form-item",
                                          {
                                            attrs: { label: "安全生产承诺书" },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                },
                                              },
                                              [
                                                _vm.obj.scCns
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ant-upload-list-item",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "ant-upload-list-item-info",
                                                          },
                                                          [
                                                            _vm.icludesPdf(
                                                              _vm.obj.scCns,
                                                              "pdf"
                                                            ) == -1
                                                              ? [
                                                                  _c("img", {
                                                                    staticClass:
                                                                      "image-width",
                                                                    attrs: {
                                                                      src: _vm
                                                                        .obj
                                                                        .scCns,
                                                                    },
                                                                  }),
                                                                ]
                                                              : [
                                                                  _c("a-icon", {
                                                                    style: {
                                                                      fontSize:
                                                                        "45px",
                                                                    },
                                                                    attrs: {
                                                                      type: "file-pdf",
                                                                    },
                                                                  }),
                                                                ],
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "ant-upload-list-item-actions",
                                                              },
                                                              [
                                                                _c("a-icon", {
                                                                  staticClass:
                                                                    "anticon-eye-o",
                                                                  attrs: {
                                                                    type: "eye",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.onPreview(
                                                                          "scCns"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                                _c("a-icon", {
                                                                  staticClass:
                                                                    "anticon-delete",
                                                                  attrs: {
                                                                    type: "delete",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.onDelete(
                                                                          "scCns"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "a-upload",
                                                      {
                                                        attrs: {
                                                          name: "file",
                                                          multiple: false,
                                                          beforeUpload:
                                                            _vm.beforeUpload,
                                                          accept: ".pdf",
                                                          customRequest:
                                                            _vm.resourceUpload,
                                                          "file-list":
                                                            _vm.fileList,
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.handleChange,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "a-button",
                                                          {
                                                            attrs: {
                                                              type: "primary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.getName(
                                                                  "scCns"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "上传安全生产承诺书"
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-col",
                                      { attrs: { span: 24 } },
                                      [
                                        _c(
                                          "a-form-item",
                                          {
                                            attrs: { label: "安全生产责任书" },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                },
                                              },
                                              [
                                                _vm.obj.scZrs
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ant-upload-list-item",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "ant-upload-list-item-info",
                                                          },
                                                          [
                                                            _vm.icludesPdf(
                                                              _vm.obj.scZrs,
                                                              "pdf"
                                                            ) == -1
                                                              ? [
                                                                  _c("img", {
                                                                    staticClass:
                                                                      "image-width",
                                                                    attrs: {
                                                                      src: _vm
                                                                        .obj
                                                                        .scZrs,
                                                                    },
                                                                  }),
                                                                ]
                                                              : [
                                                                  _c("a-icon", {
                                                                    style: {
                                                                      fontSize:
                                                                        "45px",
                                                                    },
                                                                    attrs: {
                                                                      type: "file-pdf",
                                                                    },
                                                                  }),
                                                                ],
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "ant-upload-list-item-actions",
                                                              },
                                                              [
                                                                _c("a-icon", {
                                                                  staticClass:
                                                                    "anticon-eye-o",
                                                                  attrs: {
                                                                    type: "eye",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.onPreview(
                                                                          "scZrs"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                                _c("a-icon", {
                                                                  staticClass:
                                                                    "anticon-delete",
                                                                  attrs: {
                                                                    type: "delete",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.onDelete(
                                                                          "scZrs"
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "a-upload",
                                                      {
                                                        attrs: {
                                                          name: "file",
                                                          multiple: false,
                                                          beforeUpload:
                                                            _vm.beforeUpload,
                                                          accept: ".pdf",
                                                          customRequest:
                                                            _vm.resourceUpload,
                                                          "file-list":
                                                            _vm.fileList,
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.handleChange,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "a-button",
                                                          {
                                                            attrs: {
                                                              type: "primary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.getName(
                                                                  "scZrs"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "上传安全生产责任书"
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("a-col", { attrs: { span: 21 } }),
                                    _c(
                                      "a-col",
                                      { attrs: { span: 3 } },
                                      [
                                        _vm.user.loginType == 1
                                          ? _c(
                                              "a-button",
                                              {
                                                attrs: { type: "danger" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.delAqy(
                                                      _vm.obj,
                                                      1
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("删除经理")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.user.loginType == 1 || _vm.canOperate
                    ? _c(
                        "a-collapse-panel",
                        { key: "2", attrs: { header: "专职安全员" } },
                        [
                          _vm.obj.detailList.length < 3 &&
                          _vm.user.loginType == 1
                            ? _c(
                                "a-button",
                                {
                                  staticStyle: { "margin-top": "-4px" },
                                  attrs: { slot: "extra" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addAqyMotal(3)
                                    },
                                  },
                                  slot: "extra",
                                },
                                [_vm._v("添加安全员")]
                              )
                            : _vm._e(),
                          _vm._l(_vm.obj.detailList, function (item, index) {
                            return _c(
                              "a-row",
                              {
                                key: index + 2 + "",
                                staticStyle: {
                                  "border-bottom": "1px solid #d9d9d9",
                                  padding: "0px 10px 10px 10px",
                                  margin: "20px 10px 10px 10px",
                                },
                              },
                              [
                                _vm.modalUser && index == 0
                                  ? [
                                      _c(
                                        "a-col",
                                        { attrs: { span: 24 } },
                                        [
                                          _c(
                                            "a-form-item",
                                            {
                                              attrs: {
                                                label: "请选择人员",
                                                labelCol: { span: 6 },
                                                "wrapper-col": { span: 6 },
                                              },
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  staticStyle: {
                                                    width: "200px",
                                                  },
                                                  attrs: {
                                                    "show-search": "",
                                                    allowClear: "",
                                                    placeholder: "请输入或选择",
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.handleUserChange,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.changeUserVo.userId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.changeUserVo,
                                                        "userId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "changeUserVo.userId",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.userList,
                                                  function (item) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: item.id,
                                                        attrs: {
                                                          value: item.id,
                                                          title: item.name,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(item.name) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-col",
                                        { attrs: { span: 24 } },
                                        [
                                          _c(
                                            "a-form-item",
                                            {
                                              attrs: {
                                                label: "姓名",
                                                labelCol: { span: 6 },
                                                "wrapper-col": { span: 6 },
                                              },
                                            },
                                            [
                                              _c("a-input", {
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "安全员姓名",
                                                },
                                                model: {
                                                  value:
                                                    _vm.changeUserVo.userName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.changeUserVo,
                                                      "userName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "changeUserVo.userName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-col",
                                        { attrs: { span: 24 } },
                                        [
                                          _c(
                                            "a-form-item",
                                            {
                                              attrs: {
                                                label: "电话",
                                                labelCol: { span: 6 },
                                                "wrapper-col": { span: 6 },
                                              },
                                            },
                                            [
                                              _c("a-input", {
                                                attrs: {
                                                  disabled: true,
                                                  placeholder: "电话",
                                                },
                                                model: {
                                                  value: _vm.changeUserVo.phone,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.changeUserVo,
                                                      "phone",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "changeUserVo.phone",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-col",
                                        { attrs: { span: 24 } },
                                        [
                                          _c(
                                            "a-form-item",
                                            {
                                              attrs: {
                                                allowClear: false,
                                                label: "安全员任命时间",
                                                labelCol: { span: 6 },
                                                "wrapper-col": { span: 16 },
                                              },
                                            },
                                            [
                                              _c("a-date-picker", {
                                                attrs: {
                                                  placeholder: "安全员任命时间",
                                                  defaultValue:
                                                    _vm.addAppointDate
                                                      ? _vm.moment(
                                                          _vm.addAppointDate,
                                                          "YYYY-MM-DD"
                                                        )
                                                      : "",
                                                },
                                                on: { change: _vm.onChangeAdd },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("a-col", { attrs: { span: 6 } }),
                                      _c(
                                        "a-col",
                                        { attrs: { span: 18 } },
                                        [
                                          _c(
                                            "a-button",
                                            {
                                              staticStyle: {
                                                "margin-right": "10px",
                                                "margin-bottom": "10px",
                                              },
                                              attrs: { type: "primary" },
                                              on: { click: _vm.handleUserOk },
                                            },
                                            [_vm._v("确定添加")]
                                          ),
                                          _c(
                                            "a-button",
                                            { on: { click: _vm.userCancel } },
                                            [_vm._v("关 闭")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                                !_vm.modalUser || item.userName
                                  ? [
                                      _vm.user.loginType == 1 ||
                                      _vm.user.id == item.userId
                                        ? [
                                            _c(
                                              "a-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "a-form-item",
                                                  {
                                                    attrs: {
                                                      label: "",
                                                      labelCol: { span: 0 },
                                                      "wrapper-col": {
                                                        span: 12,
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "text-align":
                                                            "center",
                                                          "font-size": "18px",
                                                          "font-weight": "bold",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " 专项安全员" +
                                                            _vm._s(index + 1) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "a-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "a-form-item",
                                                  {
                                                    attrs: {
                                                      allowClear: false,
                                                      label: "姓名",
                                                      labelCol: { span: 6 },
                                                      "wrapper-col": {
                                                        span: 6,
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("a-input", {
                                                      attrs: {
                                                        disabled: true,
                                                        value: item.userName,
                                                        placeholder:
                                                          "安全员姓名",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "a-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "a-form-item",
                                                  {
                                                    attrs: {
                                                      allowClear: false,
                                                      label: "电话",
                                                      labelCol: { span: 6 },
                                                      "wrapper-col": {
                                                        span: 6,
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("a-input", {
                                                      attrs: {
                                                        disabled: true,
                                                        value: item.userPhone,
                                                        placeholder:
                                                          "安全员电话",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "a-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "a-form-item",
                                                  {
                                                    attrs: {
                                                      label: "任命时间",
                                                      labelCol: { span: 6 },
                                                      "wrapper-col": {
                                                        span: 16,
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("a-date-picker", {
                                                      attrs: {
                                                        allowClear: false,
                                                        placeholder:
                                                          "请选择任命时间",
                                                        defaultValue:
                                                          item.appointDate
                                                            ? _vm.moment(
                                                                item.appointDate,
                                                                "YYYY-MM-DD"
                                                              )
                                                            : "",
                                                      },
                                                      on: {
                                                        change: _vm.onChange,
                                                        openChange: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleStartOpenChange(
                                                            index
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "a-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "a-form-item",
                                                  {
                                                    attrs: {
                                                      label: "专职安全员任命书",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                        },
                                                      },
                                                      [
                                                        item.zzrmsSafe
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "ant-upload-list-item",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "ant-upload-list-item-info",
                                                                  },
                                                                  [
                                                                    _vm.icludesPdf(
                                                                      item.zzrmsSafe,
                                                                      "pdf"
                                                                    ) == -1
                                                                      ? [
                                                                          _c(
                                                                            "img",
                                                                            {
                                                                              staticClass:
                                                                                "image-width",
                                                                              attrs:
                                                                                {
                                                                                  src: item.zzrmsSafe,
                                                                                },
                                                                            }
                                                                          ),
                                                                        ]
                                                                      : [
                                                                          _c(
                                                                            "a-icon",
                                                                            {
                                                                              style:
                                                                                {
                                                                                  fontSize:
                                                                                    "45px",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  type: "file-pdf",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "ant-upload-list-item-actions",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "a-icon",
                                                                          {
                                                                            staticClass:
                                                                              "anticon-eye-o",
                                                                            attrs:
                                                                              {
                                                                                type: "eye",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.onPreview1(
                                                                                    "zzrmsSafe",
                                                                                    index
                                                                                  )
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "a-icon",
                                                                          {
                                                                            staticClass:
                                                                              "anticon-delete",
                                                                            attrs:
                                                                              {
                                                                                type: "delete",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.onDelete1(
                                                                                    "zzrmsSafe",
                                                                                    index,
                                                                                    "aqzrsSafe"
                                                                                  )
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  2
                                                                ),
                                                              ]
                                                            )
                                                          : _c(
                                                              "a-upload",
                                                              {
                                                                attrs: {
                                                                  name: "file",
                                                                  multiple: false,
                                                                  beforeUpload:
                                                                    _vm.beforeUpload,
                                                                  accept:
                                                                    ".pdf",
                                                                  customRequest:
                                                                    _vm.resourceUpload1,
                                                                  "file-list":
                                                                    _vm.fileList,
                                                                },
                                                                on: {
                                                                  change:
                                                                    _vm.handleChange,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "a-button",
                                                                  {
                                                                    attrs: {
                                                                      type: "primary",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.getName1(
                                                                            "zzrmsSafe",
                                                                            index,
                                                                            "aqzrsSafe"
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "上传专职安全员任命书"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "a-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "a-form-item",
                                                  {
                                                    attrs: {
                                                      label: "安全责任书",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                        },
                                                      },
                                                      [
                                                        item.aqzrsSafe
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "ant-upload-list-item",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "ant-upload-list-item-info",
                                                                  },
                                                                  [
                                                                    _vm.icludesPdf(
                                                                      item.aqzrsSafe,
                                                                      "pdf"
                                                                    ) == -1
                                                                      ? [
                                                                          _c(
                                                                            "img",
                                                                            {
                                                                              staticClass:
                                                                                "image-width",
                                                                              attrs:
                                                                                {
                                                                                  src: item.aqzrsSafe,
                                                                                },
                                                                            }
                                                                          ),
                                                                        ]
                                                                      : [
                                                                          _c(
                                                                            "a-icon",
                                                                            {
                                                                              style:
                                                                                {
                                                                                  fontSize:
                                                                                    "45px",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  type: "file-pdf",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "ant-upload-list-item-actions",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "a-icon",
                                                                          {
                                                                            staticClass:
                                                                              "anticon-eye-o",
                                                                            attrs:
                                                                              {
                                                                                type: "eye",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.onPreview1(
                                                                                    "aqzrsSafe",
                                                                                    index
                                                                                  )
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "a-icon",
                                                                          {
                                                                            staticClass:
                                                                              "anticon-delete",
                                                                            attrs:
                                                                              {
                                                                                type: "delete",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.onDelete1(
                                                                                    "aqzrsSafe",
                                                                                    index,
                                                                                    "zzrmsSafe"
                                                                                  )
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  2
                                                                ),
                                                              ]
                                                            )
                                                          : _c(
                                                              "a-upload",
                                                              {
                                                                attrs: {
                                                                  name: "file",
                                                                  multiple: false,
                                                                  beforeUpload:
                                                                    _vm.beforeUpload,
                                                                  accept:
                                                                    ".pdf",
                                                                  customRequest:
                                                                    _vm.resourceUpload1,
                                                                  "file-list":
                                                                    _vm.fileList,
                                                                },
                                                                on: {
                                                                  change:
                                                                    _vm.handleChange,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "a-button",
                                                                  {
                                                                    attrs: {
                                                                      type: "primary",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.getName1(
                                                                            "aqzrsSafe",
                                                                            index,
                                                                            "zzrmsSafe"
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "上传安全责任书"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c("a-col", {
                                              attrs: { span: 21 },
                                            }),
                                          ]
                                        : _vm._e(),
                                      _c(
                                        "a-col",
                                        { attrs: { span: 3 } },
                                        [
                                          _vm.obj.detailList &&
                                          _vm.user.loginType == 1
                                            ? _c(
                                                "a-button",
                                                {
                                                  attrs: { type: "danger" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.delAqy(item)
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除安全员")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            )
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              style: {
                position: "absolute",
                right: 0,
                bottom: 0,
                width: "100%",
                borderTop: "1px solid #e9e9e9",
                padding: "10px 16px",
                background: "#fff",
                textAlign: "right",
                zIndex: 1,
              },
            },
            [
              _c(
                "a-button",
                { style: { marginLeft: "8px" }, on: { click: _vm.onClose } },
                [_vm._v(" 关闭 ")]
              ),
            ],
            1
          ),
          _c(
            "a-modal",
            {
              attrs: { visible: _vm.modalVisible, footer: null },
              on: { cancel: _vm.handleCancel },
            },
            [
              _c("img", {
                staticStyle: { width: "100%" },
                attrs: { alt: "example", src: _vm.previewImage },
              }),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }