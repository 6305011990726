<template>
  <div>
    <a-card>
      <div slot="title">
        <div class="flex j-between a-center">
          <div class="ellipsis-1">
            <span class="font-bold">{{ projectObj.projectName || '国道G324线云浮市腰古至茶洞段改线工程第二合同段' }}</span>
            <a-tag class="ml-2 font-14 white" color="#0A63F1">{{ getStatusText(projectObj.status) }}</a-tag>
            <a-tag class="font-14" color="#E6EFFF" style="color: #0a63f1">{{getPtypeStatusText(projectObj.ptype)}}</a-tag>
          </div>
          <!-- <div class="flex a-center">
            <img style="width: 17px; height: 17px; margin-right: 5px"
              src="https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20241121/1732175375563.png?Expires=2047535375&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=AUhXY8AGTEizviR1KAgtxEM244k%3D"
              alt="" />
            <span class="font-14" style="color: #0a63f1">查看监控视频</span>
          </div> -->
        </div>
      </div>
      <div>
        <div class="flex a-center pb-3">
          <div class="flex a-center j-center" style="
              width: 22px;
              height: 22px;
              background: #eff6fe;
              border-radius: 4px;
            ">
            <img style="width: 17px; height: 17px"
              src="https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20241125/1732496689728.png?Expires=2047856689&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=tXuND2DYnt8jwc6qTonNwOJJN0k%3D"
              alt="" />
          </div>
          <span class="ml-1 mr-4" style="color: #212121">所属公司 | {{ projectObj.companyName || '工程总承包分公司' }}</span>
          <div class="flex a-center j-center" style="
              width: 22px;
              height: 22px;
              background: #eff6fe;
              border-radius: 4px;
            ">
            <img style="width: 17px; height: 17px"
              src="https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20241125/1732496689728.png?Expires=2047856689&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=tXuND2DYnt8jwc6qTonNwOJJN0k%3D"
              alt="" />
          </div>
          <span class="ml-1" style="color: #212121">所属分公司 | {{ projectObj.gcglbName || '第二事业部' }}</span>
        </div>
        <a-row :gutter="[16, 8]">
          <a-col :span="13">
            <div style="
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
              ">
              <div class="flex black font-16 mb-1 ellipsis-1" v-for="(item, index) in constructionList" :key="index"
                :class="item.class" :style="item.style">
                <span class="black-three text-center mr-3">{{
                  item.text
                }}</span>
                <div style="width: 60%;" class="ellipsis-1">{{ item.value
                  }}<span v-if="item.unit && item.value != '暂无'">{{ item.unit }}</span></div>
              </div>
            </div>

            <div class=" mt-1 pl-2 pr-1 py-1 bg-gradient">
              <div class="flex a-center">
                <img style="width: 46px; height: 46px; border-radius: 25px"
                  src="https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20241125/1732499127654.png?Expires=2047859127&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=0ZGPPXm7vFTyD5Yq6yijIO%2BQGOc%3D"
                  alt="" />
                <div class="ml-1 flex w-100">
                  <div class="flex-1">
                    <div class="flex a-center">
                      <div class="font-16 ellipsis-1" style="color: #212121;max-width: 50px">{{ projectObj.xmjl || '暂无'
                        }}</div>
                      <div class="ml-1 project-manager">项目经理</div>
                    </div>
                    <div style="color: #4D4D4D;margin-top: 5px;">{{ projectObj.xmjlPhone || '暂无' }}</div>
                  </div>
                  <div class="flex-1 font-14">
                    <div class="ellipsis-1" :style="multiPage ? 'max-width: 225px' : 'max-width: 225px'">
                      <span>技术负责人: </span>
                      <template v-if="jsfzrList">
                        <span>{{ projectObj.jsfzrList.length > 0 ? projectObj.jsfzrList[0].userName : '暂无' }}</span>
                        <a-tooltip placement="top"
                          :title="`技术负责人: ${projectObj.jsfzrList.length > 0 ? projectObj.jsfzrList[0].userName : ''} ${projectObj.jsfzrList.length > 0 ? projectObj.jsfzrList[0].phone : ''}`">
                          <span>{{ projectObj.jsfzrList.length > 0 ? projectObj.jsfzrList[0].phone : '' }}</span>
                        </a-tooltip>
                      </template>
                      <template v-else>暂无</template>
                    </div>
                    <div class="ellipsis-1" style="margin-top: 8px;"
                      :style="multiPage ? 'max-width: 225px' : 'max-width: 225px'">
                      <span>专职安全员: </span>
                      <template v-if="aqyList">
                        <a-tooltip placement="top"
                          :title="`专职安全员: ${projectObj.aqyList.length > 0 ? projectObj.aqyList[0].userName : ''} ${projectObj.aqyList.length > 0 ? projectObj.aqyList[0].phone : ''}`">
                          <span>
                            {{ projectObj.aqyList[0].userName }} {{ projectObj.aqyList[0].phone }}
                          </span>
                        </a-tooltip>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="border: 1px solid rgb(230, 239, 255);" class="my-2"></div>
            <div class="flex black font-16 mb-1" v-for="(item, index) in unitList" :key="index" :class="item.class"
              :style="item.style">
              <span class="black-three text-center mr-3">{{
                item.text
              }}</span>
              <span>{{ item.value
                }}<span v-if="item.unit">{{ item.unit }}</span></span>
            </div>
          </a-col>
          <a-col :span="11">
            <baidu-map class="bmView" :scroll-wheel-zoom="true" :center="location" :zoom="zoom">
              <bm-view class="bmMap"></bm-view>
              <div v-for="(items, index) in persons" :key="index">
                <bm-marker :position="{ lng: items.lng, lat: items.lat }" :icon="{
                  url: items.show ? localActive : items.icon,
                  size: { width: 28, height: 28 },
                }" :dragging="false" >
                </bm-marker>
              </div>
            </baidu-map>
          </a-col>
        </a-row>
      </div>
    </a-card>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { getProjectDetail, } from "@/services/labourProjectExcellent";

export default {
  data() {
    return {
      // ['projBudget', 'projAddress','startDate','endDate']
      constructionList: [
        { value: "暂无", text: "工程造价", class: "black", unit: "万元", key: 'projBudget' },
        { value: "暂无", text: "开工时间", class: "black", key: 'startDate' },
        { value: "暂无", text: "工程地点", class: "black", key: 'projAddress' },
        { value: "暂无", text: "竣工时间", class: "black", key: 'endDate' },
      ],
      unitList: [
        { value: "暂无", text: "建设单位", class: "black", key: 'buildOfficeName' },
        { value: "暂无", text: "施工企业", class: "black", key: 'sgList' },
        { value: "暂无", text: "监理企业", class: "black", key: 'effcOfficeName' },
        { value: "暂无", text: "专业分包", class: "black", key: 'zyfbList' },
        { value: "暂无", text: "劳务分包", class: "black", key: 'lwfbList' },
      ],
      local0: require("@/assets/img/home/local-1.png"),
      location: {
        lng: 113.274,
        lat: 23.123,
      },
      zoom: 11.5,
      persons: [],
      addressKeyword: "",
      projectObj: { xmjlPhone: '' },
      aqyList: false,
      jsfzrList: false
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
    ...mapState('setting', ['multiPage']),
    canOperate() {
      return this.user.nowRoleKey.some((role) => this.arrRoles.includes(role));
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    filterPersons(data) {
      // 遍历地图覆盖物状态
      let arr = [];
      let newData = data;

      newData.forEach((item) => {
        // 组成百度地图适配的数据格式
        arr.push({
          id: item.projectId,
          lat: item.lat,
          lng: item.lng,
          icon: this.local0,
          show: false,
          // status: item.status,
        });
      });
      this.persons = arr;
    },
    getStatusText(status) {
      switch (status) {
        case 0:
          return '在建';
        case 1:
          return '完工';
        case 2:
          return '停工';
        case 3:
          return '未开工';
        default:
          return '未知状态';
      }
    },
    getPtypeStatusText(ptype) {
      switch (ptype) {
        case 1:
          return '房建';
        case 2:
          return '市政工程';
        case 3:
          return '装饰装修';
        default:
          return '未知状态';
      }
    },
    getData() {
      getProjectDetail().then(res => {
        if (res.data.code === 0) {
          this.projectObj = res.data.data
          if (this.projectObj?.aqyList && this.projectObj?.aqyList.length > 0) {
            this.aqyList = true
          } else {
            this.aqyList = false
          }
          if (this.projectObj?.jsfzrList && this.projectObj?.jsfzrList.length > 0) {
            this.jsfzrList = true
          } else {
            this.jsfzrList = false
          }
          this.constructionList = this.constructionList.map(item => {
            if (this.projectObj[item.key]) item.value = this.projectObj[item.key];
            return item;
          })
          const getKeyValue = ['sgList', 'zyfbList', 'lwfbList'];
          this.unitList = this.unitList.map(item => {
            if (getKeyValue.includes(item.key) && res.data.data[item.key] && res.data.data[item.key].length > 0) {
              item.value = res.data.data[item.key][0].officeName;
            } else if (getKeyValue.includes(item.key)) {
              item.value = '暂无';
            } else {
              if (res.data.data[item.key]) item.value = res.data.data[item.key];
            }
            return item
          })
          this.location = {
            lng: res.data.data.longitude || 113.274,
            lat: res.data.data.latitude || 23.123,
          }
          this.filterPersons([{
            lng: res.data.data.longitude || 113.274,
            lat: res.data.data.latitude || 23.123,
            projectId: res.data.data.projectId,
          }])
        } else {
          this.$message.error(this.res.msg)
        }
      })
    },
  }
};
</script>
<style lang="less" scoped>
/deep/.ant-card {
  border-radius: 10px;
  border: none;

  .ant-card-body {
    padding: 20px 36px 5px 40px;
  }

  .ant-card-head {
    padding: 0 36px 0 40px;
    border-bottom: 1px solid #e6efff;
  }

  .ant-card-head-title {
    padding: 20px 0;
  }
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.bg-gradient {
  border-radius: 10px;
  background: linear-gradient(90deg, rgb(239, 246, 254), rgb(255, 255, 255) 100%);
}

// 百度地图自定义样式
/deep/ .bmView {
  .BMap_bottom {
    display: none;
  }

  .BMap_shadow {
    display: none;
  }

  .BMap_pop {

    >div,
    >img:nth-child(10) {
      display: none;
      overflow: unset;
    }

    >div:nth-child(9) {
      display: block;
      overflow: unset;
      width: 309px !important;
    }

    >div:nth-child(8) {
      /*display: block;*/
    }

    .BMap_top {
      display: none;
    }

    .BMap_center {
      background: transparent;
      border: none;
      position: sticky !important;
    }
  }

  .BMap_bubble_content {
    background: rgba(33, 42, 63, 0.8);
    border-radius: 6px;
    padding: 14px 17px 0 19px;
    max-height: 152px !important;

    // height: 136px !important;
    .info-window {
      font-size: 14px;
      font-family: Inter;
      width: 100%;
      // height: 100%;
      color: #e5e5ef;
      height: 138px !important;
      // height: 109px !important;
      position: relative;

      .onInfo {
        position: absolute;
        right: 5px;
        bottom: 14px;
        // margin: -10px 5px 0 auto;
        text-align: center;
        background: rgb(10, 99, 241);
        border-radius: 15px;
        width: 74px;
        height: 26px;
        line-height: 26px;
        font-size: 12px;
      }
    }
  }
}

.bmMap {
  width: 100%;
  height: 354px;
  flex: 1;
  border-radius: 10px;
}

.project-manager {
  padding: 0px 7px;
  border: 1px solid rgb(99, 159, 255);
  border-radius: 3px;
  color: rgb(10, 99, 241);
}
</style>
