<template>
  <div>
    <div class="plug-Overview">风控管理</div>
    <a-card class="risk_container" title="工人年龄限制" :bordered="false">
      <div class="risk-rules">
        <div>
          <a-checkbox :checked="form.age.tg ? true : false"
            ><span class="text-color"
              >童工年龄限制，童工指未满
            </span></a-checkbox
          >
          <a-input placeholder="18" v-model="form.age.tg" style="width: 120px">
            <a-tooltip slot="suffix">
              <span class="text-color">周岁</span>
            </a-tooltip>
          </a-input>
        </div>
        <div>
          <a-checkbox :checked="form.age.man ? true : false"
            ><span class="text-color"
              >男性退休年龄限制，退休年龄为
            </span></a-checkbox
          >
          <a-input placeholder="60" v-model="form.age.man" style="width: 120px">
            <a-tooltip slot="suffix">
              <span class="text-color">周岁</span>
            </a-tooltip>
          </a-input>
        </div>
        <div>
          <a-checkbox :checked="form.age.women ? true : false"
            ><span class="text-color"
              >女性退休年龄限制，退休年龄为
            </span></a-checkbox
          >
          <a-input placeholder="50" v-model="form.age.women" style="width: 120px">
            <a-tooltip slot="suffix">
              <span class="text-color">周岁</span>
            </a-tooltip>
          </a-input>
        </div>
      </div>
    </a-card>
    <a-card class="risk_container" title="民族/地区限制" :bordered="false">
      <div class="risk-rules">
        <div>
          <a-checkbox :checked="form.areation.nation ? true : false"
            ><span class="text-color">民族限制 </span></a-checkbox
          >
          <a-select
            v-model="form.areation.nation"
            placeholder="请选择民族"
            style="width: 200px"
            @change="handlSelect($event)"
            allowClear
          >
            <a-select-option value="">请选择民族</a-select-option>
            <a-select-option
              v-for="items of nation"
              :key="items"
              :value="items"
            >
              {{ items }}
            </a-select-option>
          </a-select>
        </div>
        <div>
          <a-checkbox :checked="form.areation.area ? true : false"
            ><span class="text-color">地区限制 </span></a-checkbox
          >
          <a-select
            v-model="form.areation.area"
            placeholder="请选择省份"
            style="width: 200px"
            @change="handlSelect($event)"
            allowClear
          >
            <a-select-option value="">请选择省份</a-select-option>
            <a-select-option
              v-for="items of area"
              :key="items.name"
              :value="items.name"
            >
              {{ items.name }}
            </a-select-option>
          </a-select>
        </div>
      </div>
    </a-card>
    <a-card class="risk_container" title="长期未考勤提醒规则" :bordered="false">
      <div class="risk-rules">
        <div>
          <a-checkbox :checked="form.kq.day ? true : false"
            ><span class="text-color">超过 </span></a-checkbox
          >
          <a-input placeholder="7" v-model="form.kq.day" style="width: 100px"> </a-input>
          <span class="text-color" style="margin-left: 5px"
            >天未考勤，系统进行提示</span
          >
        </div>
      </div>
    </a-card>
    <a-card class="risk_container" title="教育提醒规则" :bordered="false">
      <div class="risk-rules">
        <div>
          <a-checkbox :checked="form.edu.day ? true : false"
            ><span class="text-color">超过 </span></a-checkbox
          >
          <a-input placeholder="1" v-model="form.edu.day" style="width: 100px"> </a-input>
          <span class="text-color" style="margin-left: 5px"
            >天未接受三级安全教育培训，系统进行提示</span
          >
        </div>
      </div>
    </a-card>
    <a-button type="primary" size="large" @click="saveRules">保存</a-button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getFksetDetail,
  saveFkset,
  editFkset,
} from "@/services/projectManagement";
import nation from "./nation.json";
import area from "./area.json";
export default {
  data() {
    return {
      nation,
      area,
      form: {
        age: { man: "", women: "", tg: "" },
        areation: { nation: "", area: "" },
        kq: { day: "" },
        edu: { day: "" },
      },
      detail: {},
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  created() {
    this.getFksetDetail();
  },
  methods: {
    handlSelect(e) {
      console.log(e, "e===》》》");
    },
    saveRules() {
      if (this.detail.id) {
        // 修改
        const params = {
          id: this.detail.id,
          projectId: this.detail.projectId,
          settings: JSON.stringify(this.form),
        };
        editFkset(params).then((res) => {
          if (res.data.code === 0) {
            this.$message.success("修改风控规则成功", 10);
            this.getFksetDetail();
          }
        });
      } else {
        // 新增
        const params = {
          projectId: this.user.projectId,
          settings: JSON.stringify(this.form),
        };
        saveFkset(params).then((res) => {
          if (res.data.code === 0) {
            this.$message.success("保存风控规则成功", 10);
            this.getFksetDetail();
          }
        });
      }
    },
    // 获取风控详情
    getFksetDetail() {
      getFksetDetail({ projectId: this.user.projectId }).then((res) => {
        if (res.data.code === 0) {
          this.detail = res.data.data;
          this.form = JSON.parse(res.data.data.settings);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.plug-Overview {
  font-size: 16px;
  color: #101010;
  font-weight: bold;
  margin-bottom: 15px;
}
.risk_container {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-bottom: 10px;
  /deep/ .ant-card-head {
    border-bottom: none;
  }
  /deep/ .ant-card-body {
    padding: 0 24px 24px 24px;
  }
  .risk-rules {
    color: rgb(118, 131, 143);
    font-size: 15px;
    > div {
      display: flex;
      width: 100%;
      align-items: center;
      margin-bottom: 5px;
    }
  }
}

.text-color {
  color: rgb(118, 131, 143);
  font-size: 15px;
}
</style>
