var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "a-card",
        [
          _c(
            "div",
            [
              _c(
                "a-row",
                { staticStyle: { "margin-left": "5%" } },
                [
                  _c(
                    "a-col",
                    { attrs: { md: 8, sm: 24 } },
                    [
                      _c("a-statistic", {
                        attrs: {
                          title: "总房间数量",
                          value: _vm.roomStaticss.totalRooms,
                          suffix: "(间)",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { md: 8, sm: 24 } },
                    [
                      _c("a-statistic", {
                        attrs: {
                          title: "普通房",
                          value: _vm.roomStaticss.defaultTypeRooms,
                          "value-style": { color: "blue" },
                          suffix: "(间)",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { md: 8, sm: 24 } },
                    [
                      _c("a-statistic", {
                        attrs: {
                          title: "夫妻房",
                          value: _vm.roomStaticss.doubleRooms,
                          "value-style": { color: "blue" },
                          suffix: "(间)",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { md: 8, sm: 24 } },
                    [
                      _c("a-statistic", {
                        attrs: {
                          title: "可住宿人数",
                          value: _vm.roomStaticss.totalPersons,
                          suffix: "(人)",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { md: 8, sm: 24 } },
                    [
                      _c("a-statistic", {
                        attrs: {
                          title: "已入住人数",
                          value: _vm.roomStaticss.overInroomNums,
                          "value-style": { color: "#cf1322" },
                          suffix: "(人)",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { md: 8, sm: 24 } },
                    [
                      _c("a-statistic", {
                        attrs: {
                          title: "空余床位",
                          value: _vm.roomStaticss.noInroomNums,
                          "value-style": { color: "#3f8600" },
                          suffix: "(个)",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("a-divider"),
          _c(
            "div",
            {
              staticStyle: {
                "background-color": "#FFF",
                "padding-top": "10px",
                padding: "10px 10px",
              },
            },
            [
              _c(
                "div",
                { class: _vm.advanced ? "search" : null },
                [
                  _c("a-form", { attrs: { layout: "horizontal" } }, [
                    _c(
                      "div",
                      { class: _vm.advanced ? null : "fold" },
                      [
                        _c(
                          "a-row",
                          [
                            _c(
                              "a-col",
                              { attrs: { md: 8, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "宿舍名称",
                                      labelCol: { span: 5 },
                                      wrapperCol: { span: 16, offset: 1 },
                                    },
                                  },
                                  [
                                    _c("a-input", {
                                      attrs: { placeholder: "请输入" },
                                      model: {
                                        value: _vm.queryParams.name,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.queryParams, "name", $$v)
                                        },
                                        expression: "queryParams.name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { md: 8, sm: 24 } },
                              [
                                _c(
                                  "a-space",
                                  { style: { marginTop: "4px" } },
                                  [
                                    _c(
                                      "a-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.doQuery()
                                          },
                                        },
                                      },
                                      [_vm._v("查询")]
                                    ),
                                    _c(
                                      "a-button",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.doReset()
                                          },
                                        },
                                      },
                                      [_vm._v("重置")]
                                    ),
                                    _c(
                                      "a-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: { click: _vm.addBuild },
                                      },
                                      [_vm._v("新增宿舍")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c("standard-table", {
                attrs: {
                  columns: _vm.columns,
                  dataSource: _vm.dataSource,
                  selectedRows: _vm.selectedRows,
                  pagination: _vm.pagination,
                  loading: _vm.tableLoading,
                  rowKey: "id",
                  isAlert: false,
                },
                on: {
                  "update:selectedRows": function ($event) {
                    _vm.selectedRows = $event
                  },
                  "update:selected-rows": function ($event) {
                    _vm.selectedRows = $event
                  },
                  clear: _vm.onClear,
                  change: _vm.onPageChange,
                },
                scopedSlots: _vm._u([
                  {
                    key: "name",
                    fn: function (ref) {
                      var text = ref.text
                      var record = ref.record
                      return _c("div", {}, [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.toRoomList(record)
                              },
                            },
                          },
                          [_vm._v(_vm._s(text))]
                        ),
                      ])
                    },
                  },
                  {
                    key: "action",
                    fn: function (ref) {
                      var text = ref.text
                      var record = ref.record
                      return _c(
                        "div",
                        {},
                        [
                          _c("a-icon", {
                            staticStyle: { color: "#FC5531" },
                            attrs: { type: "setting", title: "房间管理" },
                            on: {
                              click: function ($event) {
                                return _vm.toRoomList(record)
                              },
                            },
                          }),
                          _c("a-divider", { attrs: { type: "vertical" } }),
                          _c("a-icon", {
                            staticStyle: { color: "blue" },
                            attrs: { type: "edit", title: "编辑宿舍" },
                            on: {
                              click: function ($event) {
                                return _vm.editBuild(record)
                              },
                            },
                          }),
                          _c("a-divider", { attrs: { type: "vertical" } }),
                          _c("a-icon", {
                            staticStyle: { color: "red" },
                            attrs: { type: "delete", title: "删除宿舍" },
                            on: {
                              click: function ($event) {
                                return _vm.buildDelete(record)
                              },
                            },
                          }),
                        ],
                        1
                      )
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("modify-build", {
            attrs: { visible: _vm.build.visible, build: _vm.build.build },
            on: {
              buildPageClose: _vm.buildPageClose,
              buildSubmit: _vm.buildSubmit,
            },
          }),
          _c("modify-room", {
            attrs: { visible: _vm.room.visible, build: _vm.room.room },
            on: { roomEditorClose: _vm.roomClose, roomSubmit: _vm.roomSubmit },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }