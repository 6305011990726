/*
* 该 JavaScript 文件用于格式化表单对象的值
*/
import moment from 'moment'

/**
* @description 将对象的键值格式化为时间戳
* @param {Object} forModel 格式化的对象
* @param {String|Array} keys 要格式化的键名 
* @return {void} 
* @example valueFormatToTimestamp(formModel, 'createDate')
* @example valueFormatToTimestamp(formModel, ['startDate', 'endDate'])
*/
export function valueFormatToTimestamp(forModel, keys) {
  if (!Array.isArray(keys)) {
    keys = [keys]
  }

  keys.forEach((key) => {
    forModel[key] = moment(forModel[key]).valueOf()
  })
}