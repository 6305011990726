var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "constructiontTable_index" },
      [
        _c(
          "a-form",
          [
            _c("div", { staticClass: "plug-Overview" }, [_vm._v("管理人员")]),
            _c("a-divider"),
            _c(
              "a-button",
              { staticClass: "mb-2", on: { click: _vm.addPerson } },
              [_vm._v("新 增")]
            ),
          ],
          1
        ),
        _c("standard-table", {
          attrs: {
            columns: _vm.columns,
            dataSource: _vm.dataSource,
            rowKey: "id",
            pagination: _vm.pagination,
            loading: _vm.tableLoading,
            isAlert: false,
            bordered: true,
          },
          on: { change: _vm.onPageChange },
          scopedSlots: _vm._u([
            {
              key: "index",
              fn: function (ref) {
                var index = ref.index
                return [
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          (_vm.pagination.current - 1) *
                            _vm.pagination.pageSize +
                            parseInt(index) +
                            1
                        ) +
                        " "
                    ),
                  ]),
                ]
              },
            },
            {
              key: "isLogin",
              fn: function (ref) {
                var text = ref.text
                return [
                  text == 0
                    ? _c("span", [
                        _c("i", {
                          staticClass: "iconfont duigouxiao",
                          staticStyle: { color: "#00CE86" },
                        }),
                      ])
                    : _c("span", [
                        _c("i", {
                          staticClass: "iconfont path",
                          staticStyle: { color: "#FF5C77" },
                        }),
                      ]),
                ]
              },
            },
            {
              key: "isReportAtte",
              fn: function (ref) {
                var text = ref.text
                return [
                  text == 1
                    ? _c("span", [
                        _c("i", {
                          staticClass: "iconfont duigouxiao",
                          staticStyle: { color: "#00CE86" },
                        }),
                      ])
                    : _c("span", [
                        _c("i", {
                          staticClass: "iconfont path",
                          staticStyle: { color: "#FF5C77" },
                        }),
                      ]),
                ]
              },
            },
            {
              key: "action",
              fn: function (ref) {
                var record = ref.record
                return _c("div", {}, [
                  _c("div", { staticClass: "sTables" }, [
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.viewEditing(record)
                          },
                        },
                      },
                      [_vm._v("修改")]
                    ),
                  ]),
                ])
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }