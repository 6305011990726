var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-spin",
        { attrs: { tip: "加载中...", spinning: _vm.spinning } },
        [
          _c(
            "a-form-model",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-col": _vm.labelCol,
                "wrapper-col": _vm.wrapperCol,
              },
            },
            [
              _c(
                "a-form-model-item",
                { ref: "title", attrs: { label: "标题", prop: "title" } },
                [
                  _c("a-input", {
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { ref: "wordCode", attrs: { label: "编号", prop: "wordCode" } },
                [
                  _c("a-input", {
                    model: {
                      value: _vm.form.wordCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "wordCode", $$v)
                      },
                      expression: "form.wordCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { ref: "showDoc", attrs: { label: "预览", prop: "showDoc" } },
                [
                  _c("a", { on: { click: _vm.showDrawer } }, [
                    _vm._v("点击预览模板"),
                  ]),
                ]
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "内容", prop: "content" } },
                [
                  _c(
                    "keep-alive",
                    [
                      _c("tinymce", {
                        ref: "tinymceEditor",
                        attrs: {
                          value: _vm.form.content,
                          plugins: _vm.plugins,
                        },
                        on: { input: _vm.contentInput },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-drawer",
        {
          attrs: {
            title: "文档模板预览",
            placement: "right",
            closable: true,
            visible: _vm.drawerVisible,
            keyboard: true,
            maskClosable: true,
            destroyOnClose: true,
            width: "780px",
            bodyStyle: _vm.bodyStyle,
          },
          on: { close: _vm.closeDrawer },
        },
        [
          _c("iframe", {
            staticClass: "fddPdf",
            staticStyle: { width: "100%" },
            attrs: { src: _vm.base64List },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }