<template>
  <a-card>
    <div :class="advanced ? 'search' : null">
      <a-form layout="horizontal">
        <div :class="advanced ? null: 'fold'">
          <a-row :gutter="24">
            <a-col :span="6">
              <a-form-item
                label="姓名"
                :labelCol="{span: 5}"
                :wrapperCol="{span: 16, offset: 1}"
              >
                <a-input placeholder="请输入" v-model="queryParams.userName"/>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item
                label="身份证号"
                :labelCol="{span: 5}"
                :wrapperCol="{span: 16, offset: 1}"
              >
                <a-input placeholder="身份证号" v-model="queryParams.idCard"/>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item
                label="进场日期"
                :labelCol="{span: 5}"
                :wrapperCol="{span: 16, offset: 1}"
              >
                <!-- <a-range-picker v-model="queryParams.inDate" /> -->
                <a-range-picker @change="onTimeChange"  v-model="queryParams.inDate"/>
                <!-- <a-date-picker :default-value="moment(nowMonthDay, monthFormat)" :format="monthFormat" v-model="queryParams.enterDateStr"/> -->
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item
                label="退场日期"
                :labelCol="{span: 5}"
                :wrapperCol="{span: 16, offset: 1}"
              >
                <a-range-picker @change="onQuitTimeChange"  v-model="queryParams.quitDate"/>
              </a-form-item>
            </a-col>
            <!-- <a-col :span="6">
              <a-form-item
                label="在场状态"
                :labelCol="{span: 5}"
                :wrapperCol="{span: 16, offset: 1}"
              >
              <a-select
                  show-search
                  placeholder="请选择在场状态"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  v-model="queryParams.enterStatus"
                >
                  <a-select-option :key="0" :value="0">
                    退场
                  </a-select-option>
                  <a-select-option :key="1" :value="1">
                    在场
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col> -->
            <a-col  v-if="user.loginType == 1" :span="6">
              <a-form-item
                  :labelCol="{span: 5}"
                  :wrapperCol="{span: 16, offset: 1}"
                  label="项目名称">
                    <a-auto-complete :data-source="inputDataSource" v-model="queryParams.projectId"
                        placeholder="请输入项目名称" @search="handleInputSearch($event)" allowClear
                        @select="handlInputSelect($event)">
                        <template slot="dataSource">
                            <a-select-option v-for="items in inputDataSource" :key="items.id + ''">{{ items.title }}</a-select-option>
                        </template>
                    </a-auto-complete>
                </a-form-item>

            </a-col>
            <a-col :span="6" >
              <a-form-item
                label="班组名称"
                :labelCol="{span: 5}"
                :wrapperCol="{span: 16, offset: 1}"
              >
                <a-select
                  show-search
                  placeholder="请输入或选择班组"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  @change="handleChange"
                  v-model="queryParams.teamId"
                >
                  <a-select-option :key="item.id" v-for="item in teamDatas" :value="item.id" :title="item.teamName">
                    {{item.teamName}}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-space style="margin-top: 4px">
              <a-button icon="search" type="primary" @click="doQueryReset()">查询</a-button>
              <a-space class="operator">
                <a-button @click="doReset()">重置</a-button>
                <a-button icon="download" @click="exportBatchStaff()">导出花名册</a-button>
              </a-space>
            </a-space>
          </a-row>
        </div>
        <!-- <span style="float: right; margin-top: 3px;">
          <a-button type="primary" @click="doQuery()">查询</a-button>
          <a-button style="margin-left: 8px" @click="doReset()">重置</a-button>
        </span> -->
      </a-form>
    </div>
    <div>
      <span>

      </span>
      <standard-table
        :columns="columns"
        :dataSource="dataSource"
        :selectedRows.sync="selectedRows"
        rowKey="id"
        :pagination="pagination"
        :loading="tableLoading"
        @clear="onClear"
        @change="onPageChange"
        :bordered="true"
      >
        <template slot="quitDate" slot-scope="{ text }">
          <span>{{ text ? text : '在场' }}</span>
        </template>
      </standard-table>
    </div>
    <Export-loading :cPercent="cPercent" :isExporting="isExporting" :loadingText="loadingText"></Export-loading>
  </a-card>
</template>
<script>
import StandardTable from '@/components/table/StandardTable'
import {
  FIND_PROJECT_STAFF_HMC,
  FIND_TEAMS_BY_USER,
  EXPORT_PROJECT_STAFF_HMC,
  } from '@/services/api'
import { exportProgress } from '@/pages/aqjyzl/export' // 导出方法
import ExportLoading from '@/components/ExportLoading/ExportLoading' // progress组件
import {request} from '@/utils/request'
import moment from 'moment';
import {mapGetters} from 'vuex'
import { getOfficeProject } from "@/services/projectManagement";
import { useRender } from '@/hooks/useRender.js'

import {formatTime} from "@/utils/formatter"
const columns = [
  { title: '姓名', dataIndex: 'name',align: 'center', width:100, key: 'name' },
  { title: '性别', dataIndex: 'gender',align: 'center', width:75, key: 'gender' },
  { title: '身份证号', dataIndex: 'idCard',align: 'center', key: 'idCard' },
  { title: '手机号', dataIndex: 'phoneNumber',align: 'center', key: 'phoneNumber' },
  { title: '所属项目', dataIndex: 'projectName', key: 'projectName',align: 'center' },
  { title: '所属班组', dataIndex: 'teamName', key: 'teamName',align: 'center' },
  { title: '入场日期', dataIndex: 'enterDate', key: 'enterDate',align: 'center', customRender: (text) => useRender.renderDate(text) },
  { title: '退场日期', dataIndex: 'quitDate', key: 'quitDate',align: 'center', customRender: (text) => useRender.renderDate(text,'YYYY-MM-DD HH:mm:ss', '在场') },
  { title: '合同编号', dataIndex: 'contactNumber', key: 'contactNumber',align: 'center' },
  { title: '备注', dataIndex: 'remark', key: 'remark',align: 'center' },
]

export default {
  mixins: [exportProgress],
  name: 'Query11List',
  components: {ExportLoading,StandardTable},
  created() {
    this.nowMonthDay = moment(new Date()).format('YYYY-MM-DD');
    this.doQuery()
    this.doQueryTeams();
    if (this.user.loginType == 0) {
      // 0 是项目人员 1是公司人员
      let keyToDelete = 'projectName'
      this.columns = this.columns.filter(item => item.key !== keyToDelete)
    }
  },
  data() {
    return {
      inputDataSource: [],
      dataSource:[],
      columns: columns,
      monthFormat: 'YYYY-MM-DD',
      nowMonthDay:null,
      defaultExpandAllRows:false,
      tableLoading:false,
      selectedRows: [],
      expandedRowKeys:[],
      teamDatas:[],
      loadKeys:[],
      advanced: false,
      loadingText:"请稍后.",
      queryParams:{
        teamId:undefined,
        idCard:null,
        userName:null,
        enterDateStart:null,
        enterDateEnd:null,
        inDate:null,
        projectId: null,
        pageSize: 50,
        enterStatus: undefined
      },
      pagination:{
        showSizeChanger:true,
        pageSizeOptions: ['50', '100', '300', '800', '1000'],
        current:1,
        pageSize:50,
        total:0,
        showLessItems:true,
        showQuickJumper:true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      cPercent:0,
      isExporting:false,
      fileName:'班组人员列表文件',
    }
  },
  authorize: {
    deleteRecord: 'delete'
  },
  watch: {
    cPercent:{
      handler(newName, oldName) {
        this.cPercent=newName;
      },
      immediate: true,
    },
    loadingText:{
      handler(newName, oldName) {
        this.loadingText=newName;
      },
      immediate: true,
    }
  },
  computed: {
    ...mapGetters('account', ['user']),
  },
  methods: {
    moment,
    handlInputSelect(e) {
      this.doQueryTeams()
    },
    handleInputSearch(value) {
      this.inputDataSource = []
      if(!value) {
          return
      }
      let params = {
          officeId: this.user.officeId,
          projectName: value
      }
      getOfficeProject(params).then((res) => {
          if (res.data.code === 0) {
            res.data.data.list.forEach(el => {
                this.inputDataSource.push({
                  title: el.fullName,
                  id: el.id,
                })
            })
          }
      });
    },
    doQueryReset(){
      this.pagination.current=this.queryParams.pageNumber = 1;
      this.pagination.pageSize = 50
      this.doQuery();
    },
    doReset(){
      this.queryParams = {
        teamId:undefined,
        idCard:null,
        userName:null,
        enterDateStart:null,
        enterDateEnd:null,
        quitDateStart:null,
        quitDateEnd:null,
        inDate:null,
        quitDate:null,
        projectId: null,
        pageSize:50
      };
      this.inputDataSource = []
      if (this.user.loginType == 1) { // 只有是公司账号-才需要清空班组
        this.teamDatas = []
      }
      this.selectedRows = [],
      this.doQueryReset();

    },
    deleteRecord(key) {
      this.dataSource = this.dataSource.filter(item => item.key !== key)
      this.selectedRows = this.selectedRows.filter(item => item.key !== key)
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    remove () {
      this.dataSource = this.dataSource.filter(item => this.selectedRows.findIndex(row => row.key === item.key) === -1)
      this.selectedRows = []
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },
    //分页查询
    onPageChange(e){
      this.queryParams.pageNumber = e.current;
      this.queryParams.pageSize = e.pageSize;
      this.pagination.pageSize = e.pageSize;
      this.pagination.current=e.current;
      this.doQuery();
    },
    handleBlur() {
      console.log('blur');
    },
    handleFocus() {
      console.log('focus');
    },
    handleChange(value) {
      this.queryParams.teamId = value;
      this.doQueryReset();
    },
    // deleteRecord(key) {
    //   this.dataSource = this.dataSource.filter(item => item.key !== key)
    //   this.selectedRows = this.selectedRows.filter(item => item.key !== key)
    // },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    doQueryTeams(){
      request(FIND_TEAMS_BY_USER,"post",{projectId: this.queryParams.projectId}).then(res => {
        // if(res.data.code==0){
        //   this.teamDatas = res.data.data;
        // }
        if(res.data.code==0){
          this.teamDatas = res.data.data;
        }
      })
    },
    /**
     * 获取所有人员的签名记录信息
     */
    doQuery(){
      this.tableLoading = true;
      request(FIND_PROJECT_STAFF_HMC,"post",this.queryParams).then(res => {
        if(res.data.code==0){
          // const reg = /^(.{6})(?:\d+)(.{4})$/
          // const phoneReg = /^(.{3})(?:\d+)(.{4})$/
          this.dataSource = res.data.data.list;
          this.dataSource.forEach(item => {
            // if (item.idCard) item.idCard = item.idCard.replace(reg, '$1******$2')
            // if (item.phoneNumber) item.phoneNumber = item.phoneNumber.replace(phoneReg, '$1****$2')
            item.enterDate = item.enterDate ? formatTime(item.enterDate, 'YYYY-MM-DD HH:mm:ss') : undefined
            item.quitDate = item.quitDate ?  formatTime(item.quitDate, 'YYYY-MM-DD HH:mm:ss')  : undefined
          })
          this.pagination.total=res.data.data.total;
        }
        this.tableLoading = false;
      })
    },
    exportBatchStaff(){
      if (this.user.loginType == 1 && !this.queryParams.projectId) {
        // 企业版需要选择项目导出
        return this.$message.info('请选择项目导出');
      }
      this.loadingText = "正在导出花名册，请稍候 ····";
      let loadKeys = [];
      this.selectedRows.map(record => {
        loadKeys.push(record["idCard"]);
      })
      let obj = {
        idCardList:loadKeys,
        projectId:this.queryParams.projectId,
        teamId:this.queryParams.teamId,
        enterDateStart: this.queryParams.enterDateStart,
        enterDateEnd: this.queryParams.enterDateEnd,
        quitDateStart: this.queryParams.quitDateStart,
        quitDateEnd: this.queryParams.quitDateEnd
      }
      this.isExporting=true;
      this.cPercent=0;
      let fileName = ""
      fileName="花名册.xlsx";
      this.exportFunNomal(EXPORT_PROJECT_STAFF_HMC,obj,fileName,this.selectedRows)
    },
    onTimeChange(date, dateString){
      this.queryParams.enterDateStart = dateString[0]
      this.queryParams.enterDateEnd = dateString[1]
    },
    onQuitTimeChange(date, dateString){
      this.queryParams.quitDateStart = dateString[0]
      this.queryParams.quitDateEnd = dateString[1]
    }
  }
};
</script>
<style lang="less" scoped>
.operator {
  margin-right: 8px;

  .ant-btn {
    color: #307dfa;
    border-color: #307dfa;
  }
}
</style>
