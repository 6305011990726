<template>
  <div class="pay">
    <div class="black font-16 font-bold overtext-1 flex">
      <img src="@/assets/img/payroll-card1.png" style="width: 25px; height: 25px;" alt="">
      <span class="ml-05">工资发放情况（万元）</span>
    </div>
    <a-divider />

    <div style="display: flex;">
      <a-skeleton v-if="loading" :paragraph="{ rows: 4 }" active style="width: 68%; height: 230px; margin-right: 30px;" />
      <!-- 折线图 -->
      <div v-show="!loading" id="chart" style="width: 70%; height: 230px"></div>
      <!-- 数据概览 -->
      <div class="flex" style="flex-wrap: wrap;">
        <div
          v-for="(item, index) in payrollData"
          :key="index"
          class="item flex a-center"
          :style="{
            width: '49%',
            backgroundColor: item.background,
            borderRadius: '6px',
          }"
        >
          <div class="flex a-center">
            <img
              style="width: 42px; height: 42px; margin-left: 20px"
              :src="item.img"
            />
            <a-divider
              class="mx-2"
              type="vertical"
              style="height: 61px; background: #dae6ff"
            />
          </div>
          <div class="mt-2">
            <h4 class="black-three">{{ item.title }}</h4>
            <h4 class="black-three font-24">
              <span v-if="item.value && item.value != '0'">{{ item.value }}</span>
              <a-spin v-else />
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from "echarts";
import { debounce, throttle } from "lodash";
import { getSummaryMonthOffice } from "@/services/statistics";
import { addCommas } from '@/utils/number.js'
export default {
  props: {
    payrollData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chart: null,
      loading: false
    };
  },
  methods: {
    async getSummaryMonthOffice() {
      this.loading = true
      const res = await getSummaryMonthOffice()

      const currentYear = new Date().getFullYear().toString();
      const months = [];
      const values = [];

      res.data.data.forEach(item => {
        const year = item.month.substring(0, 4);
        const month = parseInt(item.month.substring(4));

        let formattedYear = '';
        // if (year !== currentYear) {
        //   formattedYear = (parseInt(year.substring(2)) + 1).toString() + '年';
        // }

        months.push(`${formattedYear}${month}月`);
        // 元 转 万元
        values.push(parseFloat((item.payroll / 10000).toFixed(2)));
      });
      this.loading = false

      this.initChart(months, values)
    },
    async initChart(months, payData) {
      console.log(
        `%c 🚀 %c payData %c`,
        'background:#35495e ; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff',
        'background:#41b883 ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff',
        'background:transparent',
        payData
      )
      const chartDom = document.getElementById("chart");
      this.chart = echarts.init(chartDom);
      this.chart.setOption({
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: months,
          axisLine: {
            lineStyle: {
              color: "#E5E5EF", // X 轴颜色
            },
          },
          axisLabel: {
            color: "#333", // X 轴标签颜色
          },
          axisTick: {
            show: false, // 隐藏刻度线
          },
        },
        yAxis: [
          {
            type: "value",
            name: "万元", // 在 Y 轴底部添加单位
            axisLine: {
              show: false
            },
            axisLabel: {
              color: "#333", // Y 轴标签颜色
            },
            axisTick: {
              show: false, // 隐藏刻度线
            },
            splitLine: {
              lineStyle: {
                color: "#eee", // 分隔线颜色
              },
            },
          },
          // {
          //   type: "value",
          //   name: "发放人次", // 在右侧 Y 轴底部添加单位
          //   axisLine: {
          //     show: false
          //   },
          //   axisTick: {
          //     show: false, // 隐藏刻度线
          //   },
          //   axisLabel: {
          //     color: "#333", // 右侧 Y 轴标签颜色
          //   },
          //   splitLine: {
          //     show: false, // 右侧 Y 轴分隔线隐藏
          //   },
          // },
        ],
        tooltip: {
          trigger: "axis",
          formatter: throttle(function(params) {
            let tooltipContent = '<div>';
            tooltipContent += `<div>${params[0].axisValueLabel}</div>`
            params.forEach((p, i) => {
              const color = i === 0 ? 'rgb(74, 58, 255)' : '#07BAF5'
              // 色块
              const colorDiv = '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' + color + '"></span>'; // 颜色块
              // 单位
              const unit = i === 0 ? '万元' : '人次'
              // 万元加千分位
              const data = i === 0 ? addCommas(p.data) : p.data
              tooltipContent += `<div>${colorDiv}${data}${unit}</div>`
            })
            tooltipContent += '</div>';
            return tooltipContent;
          }, 100)
        },
        series: [
          {
            data: payData,
            type: "line",
            yAxisIndex: 0, // 指定使用左侧的 Y 轴
            smooth: true, // 使折线平滑
            lineStyle: {
              color: "rgb(74, 58, 255)", // 设置折线颜色
              width: 2, // 设置折线宽度
            },
            itemStyle: {
              opacity: 0,
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  { offset: 0, color: "#3A58FF" }, // 起始颜色
                  { offset: 0.5, color: "#BBD1FF" }, // 中间颜色
                  { offset: 1, color: "#FFFFFF" }, // 结束颜色
                ],
                global: false, // 缺省为 false
              },
            },
          },
          // {
          //   data: [
          //     1320, 820, 932, 901, 934, 1290, 820, 932, 901, 934, 1290, 1330,
          //   ], // 添加发放人次的数据
          //   type: "line",
          //   yAxisIndex: 1, // 指定使用右侧的 Y 轴
          //   smooth: true, // 使折线平滑
          //   lineStyle: {
          //     color: "#07BAF5", // 设置折线颜色
          //     width: 2, // 设置折线宽度
          //   },
          //   itemStyle: {
          //     opacity: 0,
          //   },
          //   symbol: "circle", // 设置标记点形状为圆形
          //   symbolSize: 5, // 设置标记点大小
          //   areaStyle: {
          //     color: {
          //       type: "linear",
          //       x: 0,
          //       y: 0,
          //       x2: 0,
          //       y2: 1,
          //       colorStops: [
          //         { offset: 0, color: "#07BAF5" }, // 起始颜色
          //         { offset: 0.5, color: "#BBD1FF" }, // 中间颜色
          //         { offset: 1, color: "#FFFFFF" }, // 结束颜色
          //       ],
          //       global: false, // 缺省为 false
          //     },
          //   },
          // },
        ],
      });

      this.handleResize()
    },
    handleResize: debounce(function () {
      // 调用 ECharts 实例的 resize 方法
      this.chart.resize();
    }, 300),
  },
  mounted() {
    this.getSummaryMonthOffice()
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="less" scoped>
.pay {
  height: 290px;
  border-radius: 10px;
  margin-top: 10px;
  padding: 17px;
  background-color: #fff;
  .item:nth-child(odd) {
    margin-right: 10px;
  }
  .item {
    margin-bottom: 10px;
  }
}
</style>
