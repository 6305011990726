var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-card",
        {
          staticStyle: { width: "300px" },
          attrs: { title: "同步旧劳务管理账号", bordered: false },
        },
        [
          _c(
            "a-form",
            {
              attrs: {
                form: _vm.form,
                "label-col": { span: 5 },
                "wrapper-col": { span: 12 },
              },
              on: { submit: _vm.handleSubmit },
            },
            [
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "项目id",
                    "wrapper-col": { span: 18, offset: 0 },
                  },
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入需要迁移的项目id" },
                    model: {
                      value: _vm.transferOfficeData.projectId,
                      callback: function ($$v) {
                        _vm.$set(_vm.transferOfficeData, "projectId", $$v)
                      },
                      expression: "transferOfficeData.projectId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "新公司id",
                    "wrapper-col": { span: 18, offset: 0 },
                  },
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入新的公司id" },
                    model: {
                      value: _vm.transferOfficeData.newOfficeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.transferOfficeData, "newOfficeId", $$v)
                      },
                      expression: "transferOfficeData.newOfficeId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.transferOfficeDataSubmit },
                },
                [_vm._v(" 将数据同步到该公司 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }