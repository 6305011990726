var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-card",
        { attrs: { title: _vm.title } },
        [
          _c("form-index", {
            ref: "formindex",
            attrs: { formData: _vm.formData, bottomSubmit: true },
            on: { handleSubmit: _vm.submit },
            scopedSlots: _vm._u([
              {
                key: "bottomSubmit",
                fn: function () {
                  return [
                    _c(
                      "a-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.$route.meta.isBreak,
                            expression: "!$route.meta.isBreak",
                          },
                        ],
                        attrs: { type: "primary", "html-type": "submit" },
                      },
                      [_vm._v(" 确定 ")]
                    ),
                    _c("a-button", { on: { click: _vm.close } }, [
                      _vm._v(" 关闭 "),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }