import { ADMIN } from '@/services/api'
import {request, METHOD, removeAuthorization} from '@/utils/request'

// const BASE_URL = process.env.VUE_APP_API_BASE_URL + '/score'
const ADMIN_API = `${ADMIN}/score`

export async function safesystemAdd(data) {
    return request(ADMIN_API + '/safesystem/add', METHOD.POST, data)
}
export async function safesystemUpdate(data) {
    return request(ADMIN_API + `/safesystem/update`, METHOD.POST, data)
}
export async function safesystemList(data) {
    return request(ADMIN_API + `/safesystem/list`, METHOD.POST, data)
}
export async function safesystemByID(params) {
    return request(ADMIN_API + `/safesystem/view`, METHOD.GET, params)
}
export async function safesystemDelete(params) {
    return request(ADMIN_API + `/safesystem/delete`, METHOD.GET, params)
}

export const safesystemBatchDownload = ADMIN_API + '/safesystem/batchDownload'
export const safesystemExport = ADMIN_API + '/safesystem/exportExcel'

export async function safegworkguideAdd(data) {
    return request(ADMIN_API + '/safegworkguide/add', METHOD.POST, data)
}
export async function safegworkguideUpdate(data) {
    return request(ADMIN_API + `/safegworkguide/update`, METHOD.POST, data)
}
export async function safegworkguideList(data) {
    return request(ADMIN_API + `/safegworkguide/list`, METHOD.POST, data)
}
export async function safegworkguideByID(params) {
    return request(ADMIN_API + `/safegworkguide/view`, METHOD.GET, params)
}
export async function safegworkguideDelete(params) {
    return request(ADMIN_API + `/safegworkguide/delete`, METHOD.GET, params)
}

export const safegworkguideBatchDownload = ADMIN_API + '/safegworkguide/batchDownload'