var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("search-form"),
      _c(
        "a-card",
        { attrs: { bordered: false } },
        [
          _c(
            "a-list",
            { attrs: { itemLayout: "vertical" } },
            _vm._l(10, function (n) {
              return _c(
                "a-list-item",
                { key: n },
                [
                  _c("a-list-item-meta", { attrs: { title: "Alipay" } }, [
                    _c(
                      "div",
                      { attrs: { slot: "description" }, slot: "description" },
                      [
                        _c("a-tag", [_vm._v("Ant Design")]),
                        _c("a-tag", [_vm._v("设计语言")]),
                        _c("a-tag", [_vm._v("蚂蚁金服")]),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "content" }, [
                    _c("div", { staticClass: "detail" }, [
                      _vm._v(
                        " 段落示意：蚂蚁金服设计平台 ant.design，用最小的工作量，无缝接入蚂蚁金服生态，提供跨越设计与开发的体验解决方案。蚂蚁金服设计平台 ant.design，用最小的工作量，无缝接入蚂蚁金服生态，提供跨越设计与开发的体验解决方案。 "
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "author" },
                      [
                        _c("a-avatar", {
                          attrs: {
                            size: "small",
                            src: "https://gw.alipayobjects.com/zos/rmsportal/WdGqmHpayyMjiEhcKoVE.png",
                          },
                        }),
                        _c("a", [_vm._v("ICZER")]),
                        _vm._v("发布在 "),
                        _c(
                          "a",
                          { attrs: { href: "https://github.com/iczer" } },
                          [_vm._v("https://github.com/iczer")]
                        ),
                        _c("em", [_vm._v("2018-08-05 22:23")]),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "span",
                    { attrs: { slot: "actions" }, slot: "actions" },
                    [
                      _c("a-icon", {
                        staticStyle: { "margin-right": "8px" },
                        attrs: { type: "star-o" },
                      }),
                      _vm._v("156"),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    { attrs: { slot: "actions" }, slot: "actions" },
                    [
                      _c("a-icon", {
                        staticStyle: { "margin-right": "8px" },
                        attrs: { type: "like-o" },
                      }),
                      _vm._v("1435"),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    { attrs: { slot: "actions" }, slot: "actions" },
                    [
                      _c("a-icon", {
                        staticStyle: { "margin-right": "8px" },
                        attrs: { type: "message" },
                      }),
                      _vm._v("4"),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }