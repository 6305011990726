<template>
  <a-card>
  <div style="background-color:#FFF;padding-top:10px;padding:10px 10px">
      <div :class="advanced ? 'search' : null">
        <a-form layout="horizontal">
          <div :class="advanced ? null: 'fold'">
            <a-row >
              <a-col :md="6" :sm="24" >
                <a-form-item
                  label="房间号"
                  :labelCol="{span: 5}"
                  :wrapperCol="{span: 18, offset: 1}"
                >
                  <a-input placeholder="请输入" v-model="queryParams.roomNo"/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24" >
                <a-form-item
                  label="房间类型"
                  :labelCol="{span: 5}"
                  :wrapperCol="{span: 18, offset: 1}"
                >
                  <a-select placeholder="请选择" v-model="queryParams.type">
                    <a-select-option :value="0">普通房间</a-select-option>
                    <a-select-option :value="1">夫妻房</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24" >
              <a-form-item
                  label="楼层"
                  :labelCol="{span: 5}"
                  :wrapperCol="{span: 18, offset: 1}"
                >
                  <a-input placeholder="请输入" v-model="queryParams.floor"/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24" :style="{ marginTop: '4px' }">
                <a-space>
                  <a-button type="primary" @click="doQuery()">查询</a-button>
                  <a-button @click="doReset">重置</a-button>
                  <a-button @click="doBackBuild()">返回宿舍</a-button>
                  <a-button type="primary" @click="addRoom">新增房间</a-button>
                </a-space>
              </a-col>
            </a-row>
          </div>
        </a-form>
      </div>
      <!-- <div style="margin-bottom:10px"><a-button @click="addbatchRoom">批量新增房间</a-button></div> -->
        <standard-table
          :columns="columns"
          :dataSource="dataSource"
          :selectedRows.sync="selectedRows"
          :pagination="pagination"
          :loading="tableLoading"
          rowKey="id"
          @clear="onClear"
          @change="onPageChange"
        >
          <div slot="action" slot-scope="{text, record}">
            <!-- <a-icon style="color:#FC5531" type="setting" @click="doPersonPage(record)" title="人员入住"></a-icon>
            <a-divider type="vertical" /> -->
            <a-icon style="color:blue" type="edit" @click="editRoom(record)" title="编辑房间"></a-icon>
            <a-divider type="vertical" />
            <a-icon style="color:red" type="delete" @click="roomDelete(record)" title="删除房间"></a-icon>
          </div>
        </standard-table>
    </div>
    <modify-room :visible="room.visible"
        :room="room.room"
        @roomEditorClose="roomClose"
        @roomSubmit="roomSubmit"
      >
    </modify-room>
  </a-card>
</template>

<script>
import {
  BUILD_ROOM_QUERYPAGE,
  BUILD_ROOM_DELETE,
  BUILD_ROOM_UPDATE,
  BUILD_ROOM_INSERT,
  } from '@/services/api'
import ModifyRoom from '@/pages/staffbuildroom/addroom'
import StandardTable from '@/components/table/StandardTable'
import {request} from '@/utils/request'
const columns = [
  {
    title: '房间号',
    dataIndex: 'roomNo',
    align: 'center',
    scopedSlots: { customRender: 'roomNo' }
  },
  {
    title: '所在楼层',
    dataIndex: 'floor',
    align: 'center',
    scopedSlots: { customRender: 'floor' },
  },
  {
    title: '房间类型',
    dataIndex: 'type',
    align: 'center',
   scopedSlots: { customRender: 'type' },
   customRender: (text) => {
     if(text==1){
       return "夫妻房";
     }else{
       return "普通房";
     }
    }
  },
  {
    title: '可容纳人数',
    dataIndex: 'personsNo',
    align: 'center',
   scopedSlots: { customRender: 'personsNo' },
   customRender: (text) => {
     if(text){
       return text+"人";
     }else{
       return "0人";
     }
    }
  },
  {
    title: '已入住人数',
    dataIndex: 'overPersonNums',
    align: 'center',
    scopedSlots: { customRender: 'overPersonNums' },
    customRender: (text) => {
     if(text){
       return text+"人";
     }else{
       return "0人";
     }
    }
  },
  {
    title: '空余床位',
    dataIndex: 'noPersonNums',
    align: 'center',
   scopedSlots: { customRender: 'noPersonNums' },
   customRender: (text) => {
     if(text){
       return text+"个";
     }else{
       return "0个";
     }
    }
  },
  // {
  //   title: '宿舍编号',
  //   dataIndex: 'code',
  //   align: 'center',
  //  scopedSlots: { customRender: 'code' }
  // },
  {
    title: '备注',
    dataIndex: 'remark',
    align: 'center',
    scopedSlots: {customRender: 'remark'}
  },
  // {
  //   title: '创建日期',
  //   dataIndex: 'createdTime',
  //   align: 'center',
  //   scopedSlots: {customRender: 'createdTime'}
  // },
  {
    title: '操作',
    align: 'center',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'QueryList',
  components: {StandardTable,ModifyRoom},
  inject: ["fatherMethod"],
  data () {
    return {
      dataSource:[],
      advanced: false,
      columns: columns,
      selectedRows: [],
      tableLoading: false,
      spinning:false,
      loadingText:"数据加载中...",
      loadKeys:[],
      visible:false,
      avatar: require('@/assets/img/dddd.jpg'),
      queryParams:{
        pageNumber:1,
        buildId:null,
      },
      pagination:{
        current:1,
        pageSize:10,
        total:0,
        showLessItems:true,
        showQuickJumper:true
      },
      room:{
        visible:false,
        room:{
          buildId:null,
        }
      },
      personPage:{
        visible:false,
        room:{},
      }
    }
  },
  activated() {
    console.log(this.$route.query.buildId, 99999999999)
    this.queryParams.buildId = this.$route.query.buildId;
    window.localStorage.setItem("buildId",this.queryParams.buildId)
    this.doQuery();
  },
  authorize: {
    deleteRecord: 'delete'
  },
  methods: {
    /**
     * 获取所有人员的签名记录信息
     */
    addbatchRoom(){

    },
    onClear(){

    },
    onPageChange(e){
      this.queryParams.pageNumber = e.current;
      this.pagination.current=e.current;
      this.doQuery();
    },
    doQuery(){
      this.tableLoading = true;
        request( BUILD_ROOM_QUERYPAGE,"post",this.queryParams).then(res => {
          if(res.data.code==0){
            this.dataSource = res.data.data.list;
            this.pagination.total = res.data.data.total;
          }
          this.tableLoading = false;
        })
    },
    roomClose(){
      this.room.visible=false;
      this.room.room={};
      this.$set(this.room.room,"buildId",this.queryParams.buildId);
    },
    roomDelete(recode){
      var _that = this
      this.$confirm({
          title: '警告！删除房间',
          content: '您好，请您确认该房间的人都全部搬出，否则数据将不保存，您确认删除房间吗？',
          okText: '仍旧删除',
          okType: 'danger',
          cancelText: '再想想',
          onOk() {
                request(BUILD_ROOM_DELETE+recode.id, "delete", {}).then((res) => {
                if (res.data.code === 0) {
                  _that.$message.success("删除成功！");
                  _that.doQuery();
                }else{
                  _that.$message.error("删除异常！");
                }
              });
          },
          onCancel() {
              console.log('Cancel');
          },
      });
    },
    roomSubmit($event){
      let methd = $event.room.id?BUILD_ROOM_UPDATE:BUILD_ROOM_INSERT;
      this.$set($event.room,"buildId",this.queryParams.buildId);
      request(methd,"post",$event.room).then(res => {
        if(res.data.code==0){
          this.doQuery();
          this.roomClose();

        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    doBackBuild(){
      this.fatherMethod({name: '工人宿舍房间管理'});
      this.$router.push({name:'宿舍信息一览表'})
    },
    addRoom(){
      this.room.room={};
      this.$set(this.room.room,"buildId",this.queryParams.buildId);
      this.room.visible=true;
    },
    editRoom(recode){
      this.room.room = recode;
      this.room.visible=true;
    },
    doReset() {
      this.queryParams.roomNo = ''
      this.queryParams.floor = ''
      this.queryParams.type = ''
      this.queryParams.pageNumber = 1
      this.doQuery()
    },
  }
}
</script>

<style scoped>
  .gutter-example >>> .ant-row > div {
    background: transparent;
    border: 0;
    margin-top:5px;
  }
  .gutter-box {
    /* background: #00a0e9; */
    padding: 5px 0;
}
</style>
