var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        { attrs: { gutter: 16 } },
        [
          _c(
            "a-col",
            { attrs: { span: 6 } },
            [
              _c(
                "vue-scroll",
                {
                  staticStyle: { width: "100%", height: "855px" },
                  attrs: { ops: _vm.ops },
                },
                [
                  _c(
                    "a-card",
                    {
                      attrs: { size: "small", bordered: false },
                      on: { click: _vm.clearMenu },
                    },
                    [
                      _c("template", { slot: "title" }, [
                        _c("strong", [_vm._v("课程管理")]),
                      ]),
                      _c(
                        "a-tree",
                        {
                          attrs: {
                            "load-data": _vm.onLoadData,
                            "tree-data": _vm.treeData,
                            "show-icon": "",
                            "default-expand-all": "",
                          },
                          on: { select: _vm.doNodeClick },
                          scopedSlots: _vm._u([
                            {
                              key: "title",
                              fn: function (item) {
                                return [
                                  _c(
                                    "span",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.treeNodeClick(item)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c("img", {
                            staticStyle: { width: "20px", height: "20px" },
                            attrs: {
                              slot: "typeIcon",
                              src: require("@/assets/img/type.png"),
                            },
                            slot: "typeIcon",
                          }),
                          _c("img", {
                            staticStyle: { width: "20px", height: "20px" },
                            attrs: {
                              slot: "courseIcon",
                              src: require("@/assets/img/course.png"),
                            },
                            slot: "courseIcon",
                          }),
                          _c("img", {
                            staticStyle: { width: "20px", height: "20px" },
                            attrs: {
                              slot: "classIcon",
                              src: require("@/assets/img/class.png"),
                            },
                            slot: "classIcon",
                          }),
                        ]
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("a-col", { attrs: { span: 18 } }, [
            _vm.node.nodeType === "1" || !_vm.node.nodeType
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      "background-color": "#FFF",
                      "padding-top": "10px",
                      padding: "10px 10px",
                    },
                  },
                  [
                    _c(
                      "a-form",
                      { staticClass: "clearfix" },
                      [
                        _c(
                          "a-col",
                          { attrs: { span: 5 } },
                          [
                            _c(
                              "a-form-item",
                              {
                                attrs: {
                                  label: "课程名称",
                                  labelCol: { span: 8 },
                                  wrapperCol: { span: 14, offset: 1 },
                                },
                              },
                              [
                                _c("a-input", {
                                  attrs: { placeholder: "请输入" },
                                  model: {
                                    value: _vm.queryParams.name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryParams, "name", $$v)
                                    },
                                    expression: "queryParams.name",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { span: 5 } },
                          [
                            _c(
                              "a-form-item",
                              {
                                attrs: {
                                  label: "是否公开",
                                  labelCol: { span: 8 },
                                  wrapperCol: { span: 14, offset: 1 },
                                },
                              },
                              [
                                _c(
                                  "a-select",
                                  {
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: _vm.queryParams.isPublic,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryParams,
                                          "isPublic",
                                          $$v
                                        )
                                      },
                                      expression: "queryParams.isPublic",
                                    },
                                  },
                                  [
                                    _c(
                                      "a-select-option",
                                      { attrs: { value: " " } },
                                      [_vm._v("全部")]
                                    ),
                                    _c(
                                      "a-select-option",
                                      { attrs: { value: "1" } },
                                      [_vm._v("公开课程")]
                                    ),
                                    _c(
                                      "a-select-option",
                                      { attrs: { value: "0" } },
                                      [_vm._v("内部课程")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { span: 4 } },
                          [
                            _c(
                              "a-space",
                              { staticClass: "btnBox" },
                              [
                                _c(
                                  "a-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.doQuery()
                                      },
                                    },
                                  },
                                  [_vm._v("查询")]
                                ),
                                _c(
                                  "a-button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.doReset()
                                      },
                                    },
                                  },
                                  [_vm._v("重置")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("standard-table", {
                      attrs: {
                        columns: _vm.courseColumns,
                        dataSource: _vm.dataSource,
                        selectedRows: _vm.selectedRows,
                        pagination: _vm.pagination,
                        loading: _vm.tableLoading,
                        rowKey: "id",
                        bordered: true,
                        btnArr: _vm.tableBtnArr,
                      },
                      on: {
                        "update:selectedRows": function ($event) {
                          _vm.selectedRows = $event
                        },
                        "update:selected-rows": function ($event) {
                          _vm.selectedRows = $event
                        },
                        clear: _vm.onClear,
                        change: _vm.onPageChange,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "courseName",
                            fn: function (ref) {
                              var text = ref.text
                              var record = ref.record
                              return _c("div", {}, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#4A86E8",
                                      cursor: "pointer",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewCourseHour(record)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(text))]
                                ),
                              ])
                            },
                          },
                          {
                            key: "action",
                            fn: function (ref) {
                              var text = ref.text
                              var record = ref.record
                              return _c(
                                "div",
                                {},
                                [
                                  _c("a-icon", {
                                    staticStyle: { color: "#FC5531" },
                                    attrs: {
                                      type: "unordered-list",
                                      title: "查看课时",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewCourseHour(record)
                                      },
                                    },
                                  }),
                                  _c("a-divider", {
                                    attrs: { type: "vertical" },
                                  }),
                                  _c("a-icon", {
                                    staticStyle: { color: "blue" },
                                    attrs: { type: "edit", title: "编辑课程" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.editCourse(record)
                                      },
                                    },
                                  }),
                                  _c("a-divider", {
                                    attrs: { type: "vertical" },
                                  }),
                                  _c("a-icon", {
                                    staticStyle: { color: "red" },
                                    attrs: {
                                      type: "delete",
                                      title: "删除课程",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.doDeleteCourse(
                                          "" + record.id
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            },
                          },
                        ],
                        null,
                        false,
                        997548338
                      ),
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.node.nodeType === "2" ||
            (!_vm.editor && _vm.node.nodeType === "3")
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      "background-color": "#FFF",
                      "padding-top": "10px",
                      padding: "10px 10px",
                    },
                  },
                  [
                    _c(
                      "div",
                      { class: _vm.advanced ? "search" : null },
                      [
                        _c("a-form", { attrs: { layout: "horizontal" } }, [
                          _c(
                            "div",
                            { class: _vm.advanced ? null : "fold" },
                            [
                              _c(
                                "a-row",
                                [
                                  _c(
                                    "a-col",
                                    { attrs: { md: 12, sm: 24 } },
                                    [
                                      _c(
                                        "a-form-item",
                                        {
                                          attrs: {
                                            label: "课时名称",
                                            labelCol: { span: 5 },
                                            wrapperCol: { span: 18, offset: 1 },
                                          },
                                        },
                                        [
                                          _c("a-input", {
                                            attrs: { placeholder: "请输入" },
                                            model: {
                                              value: _vm.queryParams.name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.queryParams,
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression: "queryParams.name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    { attrs: { md: 8, sm: 24 } },
                                    [
                                      _c(
                                        "a-button",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                            "margin-top": "3px",
                                          },
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.doQuery()
                                            },
                                          },
                                        },
                                        [_vm._v("查询")]
                                      ),
                                      _c(
                                        "a-button",
                                        {
                                          staticStyle: { "margin-left": "8px" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.doReset()
                                            },
                                          },
                                        },
                                        [_vm._v("重置")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                float: "right",
                                "margin-top": "3px",
                                "margin-right": "10px",
                              },
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addCourseHour()
                                    },
                                  },
                                },
                                [_vm._v("新增课时")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c("a-table", {
                      attrs: {
                        columns: _vm.courHourcolumns,
                        dataSource: _vm.dataSource,
                        pagination: _vm.pagination,
                        loading: _vm.tableLoading,
                        rowKey: "id",
                        customRow: _vm.customRow,
                        bordered: true,
                      },
                      on: { clear: _vm.onClear, change: _vm.onPageChange },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "courseHourName",
                            fn: function (text) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#4A86E8",
                                      cursor: "pointer",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.setCourseHourTask(text)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(text.name))]
                                ),
                              ]
                            },
                          },
                          {
                            key: "action",
                            fn: function (text) {
                              return [
                                _c("a-icon", {
                                  staticStyle: { color: "#FC5531" },
                                  attrs: {
                                    type: "setting",
                                    title: "习题任务设置",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setCourseHourTask(text)
                                    },
                                  },
                                }),
                                _c("a-divider", {
                                  attrs: { type: "vertical" },
                                }),
                                _c("a-icon", {
                                  staticStyle: { color: "blue" },
                                  attrs: { type: "edit", title: "编辑课时" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editCourseHour(text)
                                    },
                                  },
                                }),
                                _c("a-divider", {
                                  attrs: { type: "vertical" },
                                }),
                                _c("a-icon", {
                                  staticStyle: { color: "red" },
                                  attrs: { type: "delete", title: "删除课时" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.doDeleteCourseHour(
                                        "" + text.id
                                      )
                                    },
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1808154111
                      ),
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.editor && _vm.node.nodeType === "3"
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      "background-color": "#FFF",
                      "padding-top": "10px",
                      padding: "10px 10px",
                    },
                  },
                  [
                    _c(
                      "a-card",
                      { attrs: { title: "课时考试任务" } },
                      [
                        _c(
                          "a-form",
                          { attrs: { form: _vm.form, layout: "horizontal" } },
                          [
                            _c(
                              "a-row",
                              { attrs: { gutter: 16 } },
                              [
                                _c(
                                  "a-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c(
                                      "a-form-item",
                                      {
                                        attrs: {
                                          label: "试卷选择",
                                          labelCol: { span: 5 },
                                          wrapperCol: { span: 14, offset: 1 },
                                        },
                                      },
                                      [
                                        _c(
                                          "a-select",
                                          {
                                            directives: [
                                              {
                                                name: "decorator",
                                                rawName: "v-decorator",
                                                value: [
                                                  "task.paper.paperId",
                                                  {
                                                    rules: [
                                                      {
                                                        required: true,
                                                        message: "请选择试卷",
                                                      },
                                                    ],
                                                  },
                                                ],
                                                expression:
                                                  "['task.paper.paperId', { rules: [{ required: true, message: '请选择试卷' }] }]",
                                              },
                                            ],
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              allowClear: true,
                                              placeholder: "请选择试卷",
                                            },
                                          },
                                          _vm._l(_vm.paperList, function (t) {
                                            return _c(
                                              "a-select-option",
                                              {
                                                key: t.id,
                                                attrs: { value: t.id },
                                              },
                                              [_vm._v(_vm._s(t.title))]
                                            )
                                          }),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-row",
                              { attrs: { gutter: 16 } },
                              [
                                _c(
                                  "a-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c(
                                      "a-form-item",
                                      {
                                        attrs: {
                                          label: "达标分数",
                                          labelCol: { span: 5 },
                                          wrapperCol: { span: 14, offset: 1 },
                                        },
                                      },
                                      [
                                        _c("a-input-number", {
                                          directives: [
                                            {
                                              name: "decorator",
                                              rawName: "v-decorator",
                                              value: [
                                                "task.paper.paperPip",
                                                {
                                                  rules: [
                                                    {
                                                      required: true,
                                                      message: "达标分数",
                                                    },
                                                  ],
                                                },
                                              ],
                                              expression:
                                                "['task.paper.paperPip', { rules: [{ required: true, message: '达标分数' }] }]",
                                            },
                                          ],
                                          attrs: { placeholder: "请输入" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-row",
                              { attrs: { gutter: 16 } },
                              [
                                _c(
                                  "a-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c(
                                      "a-form-item",
                                      {
                                        attrs: {
                                          label: "考试时长",
                                          labelCol: { span: 5 },
                                          wrapperCol: { span: 14, offset: 1 },
                                        },
                                      },
                                      [
                                        _c("a-input-number", {
                                          directives: [
                                            {
                                              name: "decorator",
                                              rawName: "v-decorator",
                                              value: [
                                                "task.paper.paperTime",
                                                {
                                                  rules: [
                                                    {
                                                      required: true,
                                                      message: "答题时长",
                                                    },
                                                  ],
                                                },
                                              ],
                                              expression:
                                                "['task.paper.paperTime', { rules: [{ required: true, message: '答题时长' }] }]",
                                            },
                                          ],
                                          attrs: { placeholder: "请输入" },
                                        }),
                                        _vm._v("（秒） "),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-row",
                              { attrs: { gutter: 16 } },
                              [
                                _c(
                                  "a-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c(
                                      "a-form-item",
                                      {
                                        attrs: {
                                          label: "重考次数",
                                          labelCol: { span: 5 },
                                          wrapperCol: { span: 14, offset: 1 },
                                        },
                                      },
                                      [
                                        _c("a-input-number", {
                                          directives: [
                                            {
                                              name: "decorator",
                                              rawName: "v-decorator",
                                              value: ["task.paper.paperCt"],
                                              expression:
                                                "['task.paper.paperCt']",
                                            },
                                          ],
                                          attrs: { placeholder: "请输入" },
                                        }),
                                        _vm._v("（不填则可无限重考！） "),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-row",
                              { attrs: { gutter: 16 } },
                              [
                                _c(
                                  "a-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c(
                                      "a-form-item",
                                      {
                                        attrs: {
                                          label: "是否启用：",
                                          labelCol: { span: 5 },
                                          wrapperCol: { span: 14, offset: 1 },
                                        },
                                      },
                                      [
                                        _c(
                                          "a-radio-group",
                                          {
                                            directives: [
                                              {
                                                name: "decorator",
                                                rawName: "v-decorator",
                                                value: ["task.paper.status"],
                                                expression:
                                                  "['task.paper.status']",
                                              },
                                            ],
                                          },
                                          [
                                            _c(
                                              "a-radio",
                                              { attrs: { value: 0 } },
                                              [_vm._v(" 不启用 ")]
                                            ),
                                            _c(
                                              "a-radio",
                                              { attrs: { value: 1 } },
                                              [_vm._v(" 启用 ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-row",
                              { attrs: { gutter: 16 } },
                              [
                                _c(
                                  "a-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c(
                                      "a-form-item",
                                      {
                                        attrs: {
                                          label: "考试提示语：",
                                          labelCol: { span: 5 },
                                          wrapperCol: { span: 14, offset: 1 },
                                        },
                                      },
                                      [
                                        _c("a-input", {
                                          directives: [
                                            {
                                              name: "decorator",
                                              rawName: "v-decorator",
                                              value: ["task.paper.taskTip"],
                                              expression:
                                                "['task.paper.taskTip']",
                                            },
                                          ],
                                          attrs: { placeholder: "考试提示语" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "98%",
                              "text-align": "center",
                            },
                          },
                          [
                            _c(
                              "a-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.taskSubmit()
                                  },
                                },
                              },
                              [_vm._v(" 确认保存 ")]
                            ),
                            _c(
                              "a-button",
                              {
                                staticStyle: { "margin-left": "20px" },
                                on: {
                                  click: function ($event) {
                                    return _vm.taskBack()
                                  },
                                },
                              },
                              [_vm._v(" 返回 ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c("modify-course", {
        attrs: {
          visible: _vm.course.visble,
          course: _vm.course.course,
          typeCourse: _vm.course.typeCourse,
        },
        on: {
          coursePageClose: _vm.courseClose,
          courseSubmit: _vm.courseSubmit,
        },
      }),
      _c("modify-classhour", {
        attrs: {
          visible: _vm.courseHour.visible,
          classHour: _vm.courseHour.courseHour,
        },
        on: {
          classHourPageClose: _vm.courseHourClose,
          classHourSubmit: _vm.classHourSubmit,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }