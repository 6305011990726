var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    [
      _c(
        "div",
        { class: _vm.advanced ? "search" : null },
        [
          _c("a-form", { attrs: { layout: "horizontal" } }, [
            _c(
              "div",
              { class: _vm.advanced ? null : "fold" },
              [
                _c(
                  "a-row",
                  [
                    _c(
                      "a-col",
                      { attrs: { md: 8, sm: 24 } },
                      [
                        _c(
                          "a-form-item",
                          {
                            attrs: {
                              labelCol: { span: 5 },
                              wrapperCol: { span: 18, offset: 1 },
                              label: "所属公司",
                            },
                          },
                          [
                            _c("a-tree-select", {
                              staticStyle: { width: "80%" },
                              attrs: {
                                "dropdown-style": {
                                  maxHeight: "400px",
                                  overflow: "auto",
                                },
                                "tree-data": _vm.officeTreeData,
                                placeholder: "请选择公司",
                                "tree-default-expand-all": "",
                                replaceFields: {
                                  children: "children",
                                  title: "name",
                                  key: "id",
                                  value: "id",
                                },
                                treeDefaultExpandAll: false,
                                allowClear: true,
                              },
                              on: { select: _vm.currentOfficeId },
                              model: {
                                value: _vm.queryParams.projectOfficeId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryParams,
                                    "projectOfficeId",
                                    $$v
                                  )
                                },
                                expression: "queryParams.projectOfficeId",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a-col",
                      { attrs: { md: 8, sm: 24 } },
                      [
                        _c(
                          "a-form-item",
                          {
                            attrs: {
                              label: "检查时间",
                              labelCol: { span: 5 },
                              wrapperCol: { span: 18, offset: 1 },
                            },
                          },
                          [
                            _c("a-range-picker", {
                              attrs: {
                                "default-value": [
                                  _vm.moment(_vm.firstDay, _vm.dateFormat),
                                  _vm.moment(_vm.nowDay, _vm.dateFormat),
                                ],
                                format: _vm.dateFormat,
                              },
                              on: { change: _vm.changeDate },
                              model: {
                                value: _vm.queryParams.plan_date,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryParams, "plan_date", $$v)
                                },
                                expression: "queryParams.plan_date",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "span",
              { staticStyle: { float: "right", "margin-top": "3px" } },
              [
                _c(
                  "a-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.doQuery()
                      },
                    },
                  },
                  [_vm._v("查询")]
                ),
                _c(
                  "a-button",
                  {
                    staticStyle: { "margin-left": "8px" },
                    on: {
                      click: function ($event) {
                        return _vm.doReset()
                      },
                    },
                  },
                  [_vm._v("重置")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "standard-table",
        {
          attrs: {
            columns: _vm.columns,
            dataSource: _vm.dataSource,
            selectedRows: _vm.selectedRows,
            pagination: _vm.pagination,
            loading: _vm.tableLoading,
            isAlert: false,
          },
          on: {
            "update:selectedRows": function ($event) {
              _vm.selectedRows = $event
            },
            "update:selected-rows": function ($event) {
              _vm.selectedRows = $event
            },
            clear: _vm.onClear,
            change: _vm.onPageChange,
          },
          scopedSlots: _vm._u([
            {
              key: "safediscloseFileName",
              fn: function (ref) {
                var text = ref.text
                return _c(
                  "div",
                  {},
                  [
                    text != null && text != ""
                      ? _c("div", [_vm._v(_vm._s(text))])
                      : _c("a-icon", {
                          staticStyle: { color: "red" },
                          attrs: { type: "close" },
                        }),
                  ],
                  1
                )
              },
            },
            {
              key: "do-status",
              fn: function (ref) {
                var text = ref.text
                return _c(
                  "div",
                  {},
                  [
                    text == 1
                      ? _c("a-icon", {
                          staticStyle: { color: "green" },
                          attrs: { type: "check" },
                        })
                      : _c("a-icon", {
                          staticStyle: { color: "red" },
                          attrs: { type: "close" },
                        }),
                  ],
                  1
                )
              },
            },
            {
              key: "action",
              fn: function (ref) {
                var record = ref.record
                return _c("div", {}, [
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.doDetail(
                            "" + record.key,
                            "" + record.officeId,
                            "" + record.officeName,
                            "" + record.projectName
                          )
                        },
                      },
                    },
                    [_vm._v("查看详情")]
                  ),
                ])
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "statusTitle" },
            [
              _c("a-icon", {
                attrs: { type: "info-circle" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onStatusTitleClick($event)
                  },
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _c("detail", {
        attrs: {
          visible: _vm.detail.visible,
          projectId: _vm.detail.projectId,
          officeName: _vm.detail.officeName,
          officeId: _vm.detail.officeId,
          projectName: _vm.detail.projectName,
          times: _vm.detail.times,
        },
        on: { close: _vm.doClose },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }