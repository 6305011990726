<template>
  <a-row class="card" style="margin-top: 3px; padding-top: 20px; flex: 1;">
    <a-col style="height: 100%;" :span="fatherType === 'office' ? 24 : 16">
      <div style="height: 100%;" class="relative">
        <baidu-map
          class="bmView"
          :scroll-wheel-zoom="true"
          :center="location"
          :zoom="zoom"
        >
          <bm-view class="bmMap"></bm-view>
          <bm-local-search
            :keyword="addressKeyword"
            :auto-viewport="true"
            style="display: none"
          ></bm-local-search>
          <div v-for="(items, index) in persons" :key="index">
            <bm-marker
              :position="{ lng: items.lng, lat: items.lat }"
              :icon="{
                url: items.show ? localActive : items.icon,
                size: { width: 28, height: 28 },
              }"
              :dragging="false"
              @click="infoWindowOpen(index, items)"
            >
              <bm-info-window v-if="fatherType === 'office'" :show="items.show" @close="infoWindowClose(index)">
                <div class="info-window">
                  <a-popover>
                    <div slot="content">{{ items.name }}</div>
                    <div class="ellipsis-2">项目名称： {{ items.name }}</div>
                  </a-popover>
                  <div style="line-height: 30px">
                    项目经理：{{ staffObj.xmjl ? staffObj.xmjl : 0 }}
                  </div>
                  <div>
                    联系电话：{{ staffObj.xmjlPhone ? staffObj.xmjlPhone : 0 }}
                  </div>
                  <div
                    v-if="canOperate"
                    class="onInfo"
                    @click="onInfo(items, staffObj)"
                  >
                    查看详情
                  </div>
                </div>
              </bm-info-window>
            </bm-marker>
          </div>
        </baidu-map>
        <div v-if="fatherType === 'office'" class="pro-box">
          <a-row type="flex" justify="space-around">
            <a-col
              :class="proActive == index ? 'pro-active' : ''"
              class="tab-box"
              @click="onProAction(index, item)"
              v-for="(item, index) in proList"
              :key="item.key + index"
            >
              <div style="line-height: 35px" class="font-24">{{ item.value }}</div>
              <div style="line-height: 20px">{{ item.title }}</div>
            </a-col>
          </a-row>
        </div>
        <div v-if="fatherType === 'office'" class="unit-box">
          <label class="unit-label">单位：</label>
          <a-select
            :default-value="defaultUnit"
            v-model="defaultUnit"
            style="width: 260px"
            @change="onHandleUnitChange"
          >
            <a-select-option
              v-for="(items, id) in unitList"
              :key="id"
              :value="items.id"
            >
              {{ items.name }}
            </a-select-option>
          </a-select>
        </div>
      </div>
    </a-col>
    <a-col v-if="fatherType === 'project'" style="height: 100%;" :span="8">
      <div style="height: 100%; padding: 0 5px 0 20px">
        <!-- 详情 -->
        <div class="info-window relative">
          <div class="pb-2 "></div>
          <div class="ellipsis-2 black font-16 font-bold">{{ staffObj.projectName }}</div>
          <a-tag class="mt-1" :color="getTagStatus(staffObj.status,'color')">{{getTagStatus(staffObj.status)}}</a-tag>
          <a-tag v-if="getTagName(staffObj.ptype)" color="#F7A22C">{{ getTagName(staffObj.ptype) }}</a-tag>
          <div style="line-height: 30px; color: #212121; font-size: 14px;" class="ellipsis-1 font-bold mt-2 mb-2">
            <div>工程造价：{{ staffObj.projBudget || '暂无' }} <span v-if="staffObj.projBudget">万元</span></div>
            <div>是否报建：{{ staffObj.isReport ? '是' : '否' }}</div>
            <div>项目经理：{{ staffObj.xmjl || '暂无' }}</div>
            <div>联系电话：{{ staffObj.xmjlPhone || '暂无' }}</div>
          </div>
          <a-divider/>
          <a-tooltip>
            <div class="black font-bold ellipsis-2" style="font-size: 14px;-webkit-line-clamp: 5;">项目概况：{{ staffObj.projDesc || '暂无' }}</div>
          </a-tooltip>
        </div>
      </div>
    </a-col>
  </a-row>
</template>

<script>
const proList = [
{
    title: "项目总数",
    key: "status",
    value: 0,
    status: null
  },
  {
    title: "在建项目数",
    key: "onlineNums",
    value: 0,
    status: 0,
  },
  {
    title: "完工项目数",
    key: "offNums",
    value: 0,
    status: 1,
  },
  {
    title: "停工项目数",
    key: "stopNums",
    value: 0,
    status: 2,
  },
  {
    title: "未开工项目数",
    key: "createNums",
    value: 0,
    status: 3,
  },
];

const statusList = [
  {
    title: "在建",
    status: 0,
    color: '#33D89E'
  },
  {
    title: "完工",
    status: 1,
    color: '#9DAABE'
  },
  {
    title: "停工",
    status: 2,
    color: '#F6AD3A'
  },
  {
    title: "未开工",
    status: 3,
    color: '#12C5EC'
  },
  {
    title: "完工待调整",
    status: 4,
    color: '#F16C45'
  },
  {
    title: "竣工",
    status: 5,
    color: '#7568E8'
  },
]

const tagList =[
      {
        value: 1,
        name: "房建"
      },
      {
        value: 2,
        name: "市政"
      },
      {
        value: 3,
        name: "装修工程"
      },
      {
        value: 91,
        name: "土建"
      },
      {
        value: 92,
        name: "房屋建筑工程"
      },
      {
        value: 93,
        name: "冶炼工程"
      },
      {
        value: 4,
        name: "矿山工程"
      },
      {
        value: 5,
        name: "化工石油工程"
      },
      {
        value: 6,
        name: "水利水电工程"
      },
      {
        value: 7,
        name: "电力工程"
      },
      {
        value: 8,
        name: "农林工程"
      },
      {
        value: 9,
        name: "铁路工程"
      },
      {
        value: 10,
        name: "公路工程"
      },
      {
        value: 11,
        name: "港口与航道工程"
      },
      {
        value: 12,
        name: "航天航空工程"
      },
      {
        value: 13,
        name: "通信工程"
      },
    ]

import {
  getOfficeListByParentOffice2,
  getProjectByOffice,
  getProjectsByOfficeSafe,
} from "@/services/statistics";
import { getSafeDetail } from '@/services/projectManagement.js'
import {
  getExcellentDetail,
} from "@/services/labourProjectExcellent";
import { mapGetters, mapMutations } from "vuex";
import { aqxjprojectsdetail } from "@/services/projectManagement";
export default {
  name: "newSafeHomePage",
  props: {
    fatherType: {
      type: String,
      default: 'office'
    }
  },
  data() {
    return {
      proList,
      proActive: 0,
      staffObj: {},
      arrRoles: [
        "admin",
        "OFFICE_LEADER",
        "OFFICE_AQMGLY",
        "OFFICE_AQ_LEADER",
        "OFFICE_GC_LEADER",
      ], // 查看详情权限
      location: {
        lng: 113.274,
        lat: 23.123,
      },
      zoom: 11.5,
      addressKeyword: "",
      unitList: [], //单位列表
      projectList: [],
      defaultProject: "全部",
      defaultUnit: "全部",
      persons: [], // 地图上的覆盖物
      local0: require("@/assets/img/home/local-1.png"),
      local2: require("@/assets/img/home/local-2.png"),
      local1: require("@/assets/img/home/local-3.png"),
      localActive: require("@/assets/img/home/activepath.png"),
      local3: require("@/assets/img/home/path.png"),
      projectData: [], // table表格数据
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
    canOperate() {
      return this.user.nowRoleKey.some((role) => this.arrRoles.includes(role));
    },
  },
  filters: {
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 80) {
        return value.slice(0, 80) + "...";
      }
      return value;
    },
  },
  async created() {
    // 获取账号下的单位
    const id = this.user.officeId;
    await getOfficeListByParentOffice2({ parentOfficeId: id }).then((res) => {
      if (res.data.code == 0) {
        this.unitList = res.data.data;
        // 数据过滤成当前账号登录的信息
        let items = this.unitList.filter((item) => {
          return item.id === id;
        });
        this.defaultUnit = items[0].name;
        this.getProjectsByOffice(items[0].id);
        this.getProjectStatissKqS(items[0].id);
      } else {
        this.error = res.data.msg;
        this.$message.error(res.data.msg, 3);
      }
    });
  },
  methods: {
    ...mapMutations("account", ["setUser"]),
    /**
     * 获取所有项目，以及在建，停工，完工的数量
     * */
    getProjectsByOffice(id) {
      // 获取所有项目，以及在建，停工，完工的数量
      getProjectsByOfficeSafe({ parentOfficeId: id }).then((res) => {
        if (res.data.code == 0) {
          let obj = res.data.data;
          this.projectList = obj.projects;
          // 统计项目数
          const objData = {
            status: obj.projects.length,
            onlineNums: obj.zaijian,
            offNums: obj.wangong,
            stopNums: obj.tinggong,
            createNums: obj.wait,
          };
          this.proList.forEach((el) => {
            Object.keys(objData).forEach((item) => {
              if (el.key == item) {
                el.value = objData[item];
              }
            });
          });
          this.filterPersons(this.projectList);
        } else {
          this.error = res.data.msg;
          this.$message.error(res.data.msg, 3);
        }
      });
    },
    filterPersons(data, status) {
      // 遍历地图覆盖物状态
      let arr = [];
      let newData = data;
      if (status || status == "0") {
        newData = data.filter((item) => item.status == status);
      }

      newData.forEach((item) => {
        // 组成百度地图适配的数据格式
        let icon = this.getIconStatusStyle(item.status);
        arr.push({
          name: item.fullName,
          id: item.projId || item.id,
          lat: item.latitude,
          lng: item.longitude,
          icon: icon,
          show: false,
          status: item.status,
        });
      });

      if (this.fatherType === 'office') {
        this.persons = arr;
      } else {
        const projectItem = arr.find((item) => item.id === this.user.projectId)
        projectItem.icon = this.localActive
        this.persons = [projectItem];
        this.location.lng = projectItem.lng
        this.location.lat = projectItem.lat
        this.infoWindowOpen(0, projectItem)
      }
    },
    getTagStatus(status,color) {
      const tag = statusList.find(tag => tag.status == status);
      return tag ? (color ? tag.color : tag.title) : null;
    },
    getTagName(value) {
      const tag = tagList.find(tag => tag.value == value);
      return tag ? tag.name : null;
    },
    getIconStatusStyle(status) {
      switch (status) {
        case 1:
          return this.local1;
        case 2:
          return this.local2;
        case 3:
          return this.local3;
        default:
          return this.local0;
      }
    },
    infoWindowClose(index) {
      this.persons[index].show = false;
    },
    async infoWindowOpen(index, item) {
      if (this.fatherType === 'office') {
        getProjectByOffice({ projectId: item.id }).then((res) => {
          if (res.data.code == 0) {
            this.persons[index].show = true;
            this.staffObj = res.data.data[0];
          }
        });
      } else {
        const res = await getSafeDetail({ projectId: item.id })
        this.staffObj = res.data.data
      }
    },
    // 监听选择单位
    onHandleUnitChange(e) {
      this.proActive = 0; // 选择单位时统计总数恢复默认值
      // e 单位id
      this.getProjectsByOffice(e);
      this.getProjectStatissKqS(e);
    },
    getProjectStatissKqS(id) {
      getProjectByOffice({ parentOfficeId: id }).then((res) => {
        if (res.data.code == 0) {
          this.defaultProject = res.data.data[0].projectName;
          this.projectData = res.data.data;
        } else {
          this.error = res.data.msg;
          this.$message.error(res.data.msg, 3);
        }
      });
    },
    onProAction(e, item) {
      // 高亮效果
      console.log(item,'高亮')
      this.proActive = e;
      this.filterPersons(this.projectList, item.status);
    },
    onInfo(item, data) {
      // 点击详情
      aqxjprojectsdetail(data.id).then((res) => {
        if (res.data.code == 0) {
          if (item.status == 1 || item.status == 5) {
            this.$router.push({
              name: "项目详情",
              query: {
                data: res.data.data,
                type: "list",
                random: Math.random(),
              },
            });
          } else {
            getExcellentDetail(res.data.data.projectId).then((resEx) => {
              if (resEx.data.stat == 1) {
                if (resEx.data.data) {
                  // 赋值  cylevel cyyq
                  let p = res.data.data;
                  p.cylevel = resEx.data.data.excellentInfo.cylevel;
                  p.cyyq = resEx.data.data.excellentInfo.cyyq;
                  this.$router.push({
                    name: "项目详情",
                    query: {
                      data: res.data.data,
                      type: "table",
                      exData: resEx.data.data,
                      random: Math.random(),
                    },
                  });
                } else {
                  this.$router.push({
                    name: "项目详情",
                    query: {
                      data: res.data.data,
                      type: "table",
                      exData: 0,
                      random: Math.random(),
                    },
                  });
                }
              }
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.card {
  border-radius: 10px;
  padding: 17px;
  background-color: #fff;
}

.ant-card-body {
  padding: 10px;
  background: #f5f8fe;
  border-radius: 10px;
  border: none;
}
.relative {
  position: relative;
}
.unit-box {
  position: absolute;
  top: 97px;
  left: 20px;
  .unit-label {
    position: absolute;
    left: 10px;
    z-index: 1;
    line-height: 32px;
    color: #000;
  }
  /deep/ .ant-select .ant-select-selection-selected-value {
    padding-left: 40px;
  }
}
.ant-select-selection-selected-value {
  margin-left: 30px;
}
</style>

<style lang="less" scoped>
// 百度地图自定义样式
/deep/ .bmView {
  .BMap_bottom {
    display: none;
  }
  .BMap_shadow {
    display: none;
  }
  .BMap_pop {
    > div,
    > img:nth-child(10) {
      display: none;
      overflow: unset;
    }
    > div:nth-child(9) {
      display: block;
      overflow: unset;
      width: 309px !important;
    }
    > div:nth-child(8) {
      /*display: block;*/
    }
    .BMap_top {
      display: none;
    }
    .BMap_center {
      background: transparent;
      border: none;
      position: sticky !important;
    }
  }
  .BMap_bubble_content {
    background: rgba(33, 42, 63, 0.8);
    border-radius: 6px;
    padding: 14px 17px 0 19px;
    max-height: 152px !important;
    // height: 136px !important;
    .info-window {
      font-size: 14px;
      font-family: Inter;
      width: 100%;
      // height: 100%;
      color: #e5e5ef;
      height: 138px !important;
      // height: 109px !important;
      position: relative;
      .onInfo {
        position: absolute;
        right: 5px;
        bottom: 14px;
        // margin: -10px 5px 0 auto;
        text-align: center;
        background: rgb(10, 99, 241);
        border-radius: 15px;
        width: 74px;
        height: 26px;
        line-height: 26px;
        font-size: 12px;
      }
    }
  }
}
.bmMap {
  width: 100%;
  height: 461px;
  flex: 1;
  border-radius: 10px;
}
.pro-box {
  position: absolute;
  top: 24px;
  left: 4px;
  right: 0;
  .tab-box {
    width: 16%;
    height: 66px;
    background: rgba(34, 42, 63, 0.8);
    border-radius: 6px;
    color: #fff;
    text-align: center;
  }
  .pro-active {
    background: #2d5aff;
  }
}
</style>
