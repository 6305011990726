<template>

    <div >
        <a-card title="修改密码" :bordered="false" >
            <a-form :form="changePwdForm" @submit="handleSubmit" :label-col="{ span: 2 }" :wrapper-col="{ span: 2 }" >
                <a-form-item label="原密码" :wrapper-col="{span: 6, offset: 0}">
                    <a-input-password placeholder="请输入原密码" v-decorator="['oldPwd',
                    { rules: [
                        { 
                            required: true, 
                            validator: validateOldPassword,
                        },
                    ],
                    validateTrigger: 'blur'},
                    ]" />
                </a-form-item>
                <a-form-item label="新密码" :wrapper-col="{span: 6, offset: 0}">
                    <a-input-password placeholder="新密码必须包含数字、大小写字母、特殊字符，且长度至少8位" v-decorator="['newPwd',
                    { rules: [{ 
                        required: true, 
                        message: '密码必须包含数字、小写字母、大写字母、特殊字符，且长度至少8位',
                        pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[~!@#$%^&*.)(_+}{|:?><]).{8,16}$/
                    },
                  {validator: validatePassword}] },
                    ]" 
                    :disabled="!isOldPasswordValid"
                    />
                </a-form-item>
                <a-form-item label="确认新密码" :wrapper-col="{span: 6, offset: 0}">
                    <a-input-password placeholder="确认新密码" v-decorator="['confirmPwd', 
                    { rules: [{ required: true,message: '请输入确认新密码' },{validator: validateUserPassword}] },
                    ]" 
                    :disabled="!isOldPasswordValid"
                    />
                </a-form-item>
                <a-form-item :wrapper-col="{ span: 6, offset: 1 }">
                    <a-button type="primary" html-type="submit"> 提交 </a-button>
                </a-form-item>
            </a-form>
        </a-card>
    </div>

</template>
<script>

    import { changePwd, logout, loginOut, checkUserPwd } from '@/services/user'
    import { mapGetters } from 'vuex'
    import { Decrypt, Encrypt } from "@/config/crypto-js";
    export default {
        data() {
            return {
                changePwdForm: this.$form.createForm(this, { name: 'coordinated' }),
                isOldPasswordValid: false
            };
        },
        computed: {
            ...mapGetters('account', ['user']),
        },
        methods: {
            handleSubmit(e) {
                e.preventDefault();
                this.changePwdForm.validateFields((err, values) => {
                    if (!err) {
                        changePwd({newPwd: values.newPwd}).then(res => {
                            if (res.data.code === 0) {
                                this.$message.success(res.data.msg + ',请重新登录');
                                setTimeout(() => {
                                    loginOut({userId: this.user.id}).then(res => {
                                        logout()
                                        this.$router.push('/login')
                                    })
                                },2000)
                            } else {
                                this.$message.error(res.data.msg);
                            }
                        })
                    } else {
                        this.$message.error('请确认密码必须包含数字、小写字母、大写字母、特殊字符，且长度至少8位');
                    }
                })
            },
            validateUserPassword(rule, value, callback) {
                // 自定义校验规则
                let newPwd = this.changePwdForm.getFieldValue('newPwd')
                let oldPwd = this.changePwdForm.getFieldValue('oldPwd')
                if (value && value != newPwd) {
                    callback(new Error('确认密码不一致'));
                } else if (value && value == oldPwd) {
                    callback(new Error('新密码不能和旧密码一致'));
                } else {
                    callback();
                }
            },
            validateOldPassword(rule, value, callback) {
                // 这里可以调用接口或其他方式验证原密码
                if (value) {
                    const oldPwd = Encrypt(value);
                    checkUserPwd({oldPwd: oldPwd}).then(res => {
                        if (res.data.code === 0) {
                            this.isOldPasswordValid = true;
                            callback();
                        } else {
                            this.isOldPasswordValid = false;
                            callback(new Error('原密码不正确'));
                        }
                    })
                }
              
            },
            validatePassword(rule, value, callback) {
              if (value && /(\d)\1\1/.test(value)) {
                 callback(new Error('密码不能包含三个连续的相同数字'));
              }
              else if (value && !this.isConsecutiveNumbers(value)) {
                callback(new Error('不能连续数字（如1234）连续4位或4位以上'));
              }
              else {
                // 密码符合要求
                callback();
              }
            },
            // 判断是否包含连续数字 (如1234)
            isConsecutiveNumbers(str) {
                let arr = str.split('')
                let flag = true
                for (let i = 1; i < arr.length - 2; i++) {
                let firstIndex = arr[i - 1].charCodeAt()
                let secondIndex = arr[i].charCodeAt()
                let thirdIndex = arr[i + 1].charCodeAt()
                let fourIndex = arr[i + 2].charCodeAt()
                // 是否都为数值
                const allNumber = [
                firstIndex,
                secondIndex,
                thirdIndex,
                fourIndex
                ].every((i) => this.isNumeric(i))

                if (allNumber && (fourIndex - thirdIndex == 1) && (thirdIndex - secondIndex == 1) && (secondIndex - firstIndex == 1)) {
                    console.log('为弱口令密码')
                    flag = false
                    break; // 如果已经发现连续数字，提前结束循环
                }
                }
                return flag
            },
            // 根据Unicode 编码判断是否为数字
            isNumeric(charCode) {
                return charCode >= 48 && charCode <= 57
            }
        },
    };


</script>