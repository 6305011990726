var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: "人员宿舍分配",
        visible: _vm.visible,
        footer: null,
        width: "1020px",
      },
      on: { ok: _vm.modelOk, cancel: _vm.modelCancel },
    },
    [
      _c(
        "a-card",
        { staticStyle: { "background-color": "#ececec" } },
        [
          _c(
            "a-row",
            { attrs: { gutter: 16 } },
            [
              _c(
                "a-col",
                {
                  staticStyle: {
                    "background-color": "#fff",
                    padding: "5px",
                    "max-height": "500px",
                  },
                  attrs: { md: 11, sm: 24 },
                },
                [
                  _c("a-form", { attrs: { layout: "horizontal" } }, [
                    _c(
                      "div",
                      { class: _vm.advanced ? null : "fold" },
                      [
                        _c(
                          "a-row",
                          [
                            _c(
                              "a-col",
                              { attrs: { md: 24, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "班组",
                                      labelCol: { span: 5 },
                                      wrapperCol: { span: 18, offset: 1 },
                                    },
                                  },
                                  [
                                    _c(
                                      "a-select",
                                      {
                                        staticStyle: { width: "200px" },
                                        attrs: {
                                          "show-search": "",
                                          placeholder: "请输入或选择班组",
                                          "option-filter-prop": "children",
                                          dropdownStyle:
                                            _vm.dropdownStyleSelect,
                                          "filter-option": _vm.filterOption,
                                        },
                                        on: { change: _vm.handleChange },
                                      },
                                      _vm._l(_vm.teamDatas, function (te) {
                                        return _c(
                                          "a-select-option",
                                          {
                                            key: te.id,
                                            attrs: { value: te.id },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(te.teamName) + " "
                                            ),
                                          ]
                                        )
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("standard-table", {
                    attrs: {
                      columns: _vm.columns,
                      dataSource: _vm.dataSource,
                      selectedRows: _vm.selectedRows,
                      pagination: _vm.pagination,
                      loading: _vm.tableLoading,
                      rowKey: "idCard",
                      tableSize: "small",
                      scroll: _vm.scroll,
                      isAlert: false,
                    },
                    on: {
                      "update:selectedRows": function ($event) {
                        _vm.selectedRows = $event
                      },
                      "update:selected-rows": function ($event) {
                        _vm.selectedRows = $event
                      },
                      clear: _vm.onClear,
                      change: _vm.onPageChange,
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-col",
                {
                  staticStyle: {
                    "background-color": "#fff",
                    "margin-left": "50px",
                    padding: "5px",
                    "max-height": "500px",
                  },
                  attrs: { md: 11, sm: 24 },
                },
                [
                  _c("a-form", { attrs: { layout: "horizontal" } }, [
                    _c(
                      "div",
                      { class: _vm.advanced ? null : "fold" },
                      [
                        _c(
                          "a-row",
                          [
                            _c(
                              "a-col",
                              { attrs: { md: 24, sm: 24 } },
                              [
                                _c(
                                  "a-form-item",
                                  {
                                    attrs: {
                                      label: "宿舍楼",
                                      labelCol: { span: 5 },
                                      wrapperCol: { span: 18, offset: 1 },
                                    },
                                  },
                                  [
                                    _c(
                                      "a-select",
                                      {
                                        staticStyle: { width: "200px" },
                                        attrs: {
                                          "show-search": "",
                                          placeholder: "请输入或选择宿舍楼",
                                          "option-filter-prop": "children",
                                          defaultValue:
                                            this.queryParams2.buildId,
                                          defaultActiveFirstOption: true,
                                          dropdownStyle:
                                            _vm.dropdownStyleSelect,
                                          "filter-option": _vm.filterOption,
                                        },
                                        on: { change: _vm.handleChange2 },
                                      },
                                      _vm._l(_vm.buildData, function (te) {
                                        return _c(
                                          "a-select-option",
                                          {
                                            key: te.id,
                                            attrs: { value: te.id },
                                          },
                                          [_vm._v(" " + _vm._s(te.name) + " ")]
                                        )
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("standard-table", {
                    attrs: {
                      columns: _vm.columnsRoom,
                      dataSource: _vm.dataSource2,
                      pagination: _vm.pagination2,
                      loading: _vm.tableLoading2,
                      rowKey: "id",
                      isAlert: false,
                      tableSize: "small",
                      scroll: _vm.scroll,
                    },
                    on: { clear: _vm.onClear, change: _vm.onPageChange2 },
                    scopedSlots: _vm._u([
                      {
                        key: "action",
                        fn: function (ref) {
                          var text = ref.text
                          var record = ref.record
                          return _c(
                            "div",
                            {},
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    disabled: _vm.dis(record),
                                    type: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.modelOk(record)
                                    },
                                  },
                                },
                                [_vm._v(" 分配 ")]
                              ),
                            ],
                            1
                          )
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }