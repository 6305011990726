var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { bordered: false } },
    [
      _c(
        "div",
        { attrs: { id: "components-form-demo-advanced-search" } },
        [
          _c(
            "a-form",
            {
              staticClass: "ant-advanced-search-form",
              attrs: { form: _vm.searchForm },
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "公司名称" } },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入公司名称" },
                            model: {
                              value: _vm.searchForm.officeName,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "officeName", $$v)
                              },
                              expression: "searchForm.officeName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "考勤时间" } },
                        [
                          _c("a-range-picker", {
                            on: { change: _vm.onChange },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    {
                      style: { textAlign: "right", marginTop: "3px" },
                      attrs: { span: 4 },
                    },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            icon: "search",
                            loading: _vm.loading,
                          },
                          on: { click: _vm.search },
                        },
                        [_vm._v(" 查询 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.showDetail },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.detailsShow ? "汇总" : "详情") + " "
                      ),
                    ]
                  ),
                  _vm.detailsShow
                    ? _c(
                        "a-button",
                        {
                          staticStyle: { "margin-left": "15px" },
                          attrs: { type: "primary" },
                          on: { click: _vm.exportExcel },
                        },
                        [_vm._v(" 导出详情 ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.detailsShow
        ? _c("a-table", {
            attrs: {
              columns: _vm.columns,
              "data-source": _vm.data,
              pagination: _vm.pagination,
              loading: _vm.tableLoading,
            },
            on: { change: _vm.onPageChange },
            scopedSlots: _vm._u(
              [
                {
                  key: "action",
                  fn: function (text, record) {
                    return [
                      _c(
                        "span",
                        [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.showModal(record)
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          ),
                          _c("a-divider", { attrs: { type: "vertical" } }),
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.del(record)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              2822658125
            ),
          })
        : _vm._e(),
      _vm.detailsShow
        ? _c("a-table", {
            attrs: {
              columns: _vm.detailsColumns,
              "data-source": _vm.detailsData,
              loading: _vm.tableLoading,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "action",
                  fn: function (text, record) {
                    return [
                      _c(
                        "span",
                        [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.showModal(record)
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          ),
                          _c("a-divider", { attrs: { type: "vertical" } }),
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.del(record)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              2822658125
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }