var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.$route.meta.showFather,
              expression: "$route.meta.showFather",
            },
          ],
          staticClass: "a_Approval",
        },
        [
          _c("div", { staticClass: "plug-Overview" }, [
            _vm._v("安全考核一览表"),
          ]),
          _c("a-divider"),
          _c(
            "a-form-model",
            {
              staticClass: "clearfix",
              attrs: { model: _vm.params },
              on: { submit: _vm.handleSubmit },
            },
            [
              _c(
                "a-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "选择年份",
                        labelCol: { span: 8 },
                        wrapperCol: { span: 14, offset: 1 },
                      },
                    },
                    [
                      _c("a-date-picker", {
                        attrs: {
                          mode: "year",
                          placeholder: "请选择年份",
                          open: _vm.yearShowOne,
                          format: "YYYY",
                        },
                        on: {
                          openChange: _vm.openChangeOne,
                          panelChange: _vm.panelChangeOne,
                        },
                        model: {
                          value: _vm.params.year,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "year", $$v)
                          },
                          expression: "params.year",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "a-space",
                    { staticClass: "btnBox" },
                    [
                      _c(
                        "a-button",
                        { attrs: { type: "primary", "html-type": "submit" } },
                        [_vm._v(" 查询 ")]
                      ),
                      _c("a-button", { on: { click: _vm.resetQuery } }, [
                        _vm._v(" 重置 "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "standard-table",
            {
              attrs: {
                columns: _vm.columns,
                dataSource: _vm.tableData,
                rowKey: "projectId",
                pagination: _vm.pagination,
                loading: _vm.tableLoading,
                isAlert: false,
                bordered: true,
                btnArr: _vm.tableBtnArr,
              },
              on: { change: _vm.handleChangePage },
              scopedSlots: _vm._u([
                {
                  key: "index",
                  fn: function (ref) {
                    var index = ref.index
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            (_vm.pagination.current - 1) *
                              _vm.pagination.pageSize +
                              parseInt(index) +
                              1
                          )
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "action",
                  fn: function (ref) {
                    var record = ref.record
                    return [
                      _vm.isJSOffice
                        ? _c(
                            "a",
                            {
                              on: {
                                click: function () {
                                  return _vm.handleCheck(record, 1)
                                },
                              },
                            },
                            [_vm._v(" 编辑 ")]
                          )
                        : _vm._e(),
                      _vm.isBranchOffice
                        ? _c(
                            "a",
                            {
                              on: {
                                click: function () {
                                  return _vm.handleCheck(record, 0)
                                },
                              },
                            },
                            [_vm._v(" 查看 ")]
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [
              _c("template", { slot: "title" }, [
                _c("div", { staticClass: "table-title" }, [
                  _c("div", [_vm._v("广州机施建设集团有限公司")]),
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.titleYear) + "年工程管理部安全指标考核表"
                    ),
                  ]),
                ]),
              ]),
            ],
            2
          ),
          _c("Export-loading", {
            attrs: {
              cPercent: _vm.cPercent,
              isExporting: _vm.isExporting,
              loadingText: _vm.loadingText,
            },
          }),
        ],
        1
      ),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }