var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { bordered: false } },
    [
      _c("a-table", {
        attrs: {
          columns: _vm.columns,
          "data-source": _vm.data,
          pagination: _vm.pagination,
          loading: _vm.tableLoading,
        },
        on: { change: _vm.onPageChange },
        scopedSlots: _vm._u([
          {
            key: "organizationName",
            fn: function (text, record, index) {
              return [
                _c(
                  "div",
                  { key: index },
                  [
                    record.editable
                      ? _c("a-input", {
                          staticStyle: { margin: "-5px 0" },
                          attrs: { value: text },
                          on: {
                            change: function (e) {
                              return _vm.handleChange(
                                e.target.value,
                                record.id,
                                _vm.col
                              )
                            },
                          },
                        })
                      : [_vm._v(" " + _vm._s(text) + " ")],
                  ],
                  2
                ),
              ]
            },
          },
          {
            key: "organizationCode",
            fn: function (text, record, index) {
              return [
                _c(
                  "div",
                  { key: index },
                  [
                    record.editable
                      ? _c("a-input", {
                          staticStyle: { margin: "-5px 0" },
                          attrs: { value: text },
                          on: {
                            change: function (e) {
                              return _vm.handleChange(
                                e.target.value,
                                record.id,
                                "organizationCode"
                              )
                            },
                          },
                        })
                      : [_vm._v(" " + _vm._s(text) + " ")],
                  ],
                  2
                ),
              ]
            },
          },
          {
            key: "organizationLicense",
            fn: function (text, record, index) {
              return [
                _c(
                  "div",
                  { key: index },
                  [
                    text
                      ? _c("img", {
                          staticStyle: { width: "120px", height: "80px" },
                          attrs: { src: text },
                          on: {
                            click: function ($event) {
                              return _vm.showImg(record)
                            },
                          },
                        })
                      : _vm._e(),
                    record.editable
                      ? _c(
                          "a-upload",
                          {
                            attrs: {
                              name: "file",
                              action:
                                _vm.uploadOrganizationLicenseImg +
                                "?id=" +
                                record.id,
                              headers: _vm.headers,
                              showUploadList: false,
                            },
                            on: {
                              change: _vm.handleChangeOrganizationLicenseImg,
                            },
                          },
                          [
                            _c("a-divider", { attrs: { type: "vertical" } }),
                            _c(
                              "a-button",
                              [
                                _c("a-icon", { attrs: { type: "upload" } }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      record.organizationLicense == ""
                                        ? "上传"
                                        : "重新上传"
                                    ) +
                                    " "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "userName",
            fn: function (text, record, index) {
              return [
                _c(
                  "div",
                  { key: index },
                  [
                    record.editable
                      ? _c("a-input", {
                          staticStyle: { margin: "-5px 0" },
                          attrs: { value: text },
                          on: {
                            change: function (e) {
                              return _vm.handleChange(
                                e.target.value,
                                record.id,
                                "userName"
                              )
                            },
                          },
                        })
                      : [_vm._v(" " + _vm._s(text) + " ")],
                  ],
                  2
                ),
              ]
            },
          },
          {
            key: "userPhone",
            fn: function (text, record, index) {
              return [
                _c(
                  "div",
                  { key: index },
                  [
                    record.editable
                      ? _c("a-input", {
                          staticStyle: { margin: "-5px 0" },
                          attrs: { value: text },
                          on: {
                            change: function (e) {
                              return _vm.handleChange(
                                e.target.value,
                                record.id,
                                "userPhone"
                              )
                            },
                          },
                        })
                      : [_vm._v(" " + _vm._s(text) + " ")],
                  ],
                  2
                ),
              ]
            },
          },
          {
            key: "userIdCard",
            fn: function (text, record, index) {
              return [
                _c(
                  "div",
                  { key: index },
                  [
                    record.editable
                      ? _c("a-input", {
                          staticStyle: { margin: "-5px 0" },
                          attrs: { value: text },
                          on: {
                            change: function (e) {
                              return _vm.handleChange(
                                e.target.value,
                                record.id,
                                "userIdCard"
                              )
                            },
                          },
                        })
                      : [_vm._v(" " + _vm._s(text) + " ")],
                  ],
                  2
                ),
              ]
            },
          },
          {
            key: "authorization",
            fn: function (authorization, record) {
              return [
                !_vm.user.nowRoleKey.includes("admin")
                  ? _c(
                      "a",
                      {
                        attrs: {
                          href: "https://res.ess.myqcloud.com/web-sign/docs/%E3%80%90%E8%85%BE%E8%AE%AF%E7%94%B5%E5%AD%90%E7%AD%BE%E3%80%91%E8%B6%85%E7%BA%A7%E7%AE%A1%E7%90%86%E5%91%98%E6%8E%88%E6%9D%83%E4%B9%A6%E6%A8%A1%E6%9D%BF.docx",
                        },
                      },
                      [
                        _vm._v("下载模板 "),
                        _c("a-divider", { attrs: { type: "vertical" } }),
                      ],
                      1
                    )
                  : _vm._e(),
                authorization != ""
                  ? _c("a", { attrs: { href: authorization } }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            authorization == "" ? "" : "授权书.xls(点击下载)"
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                record.editable
                  ? _c(
                      "a-upload",
                      {
                        attrs: {
                          name: "file",
                          action:
                            _vm.uploadAuthorizationFileUrl + "?id=" + record.id,
                          headers: _vm.headers,
                          showUploadList: false,
                        },
                        on: { change: _vm.authorizationChange },
                      },
                      [
                        _c("a-divider", { attrs: { type: "vertical" } }),
                        _c(
                          "a-button",
                          [
                            _c("a-icon", { attrs: { type: "upload" } }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  authorization == "" ? "上传" : "重新上传"
                                ) +
                                " "
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "operation",
            fn: function (text, record) {
              return [
                _c("div", { staticClass: "editable-row-operations" }, [
                  record.editable
                    ? _c(
                        "span",
                        [
                          _c(
                            "a",
                            {
                              on: {
                                click: function () {
                                  return _vm.save(record.id)
                                },
                              },
                            },
                            [_vm._v("保存")]
                          ),
                          _c("a-divider", { attrs: { type: "vertical" } }),
                          _c(
                            "a-popconfirm",
                            {
                              attrs: { title: "是否取消保存?" },
                              on: {
                                confirm: function () {
                                  return _vm.cancel(record.id)
                                },
                              },
                            },
                            [_c("a", [_vm._v("取消")])]
                          ),
                        ],
                        1
                      )
                    : _c("span", [
                        _c(
                          "a",
                          {
                            attrs: { disabled: _vm.editingKey !== "" },
                            on: {
                              click: function () {
                                return _vm.edit(record.id)
                              },
                            },
                          },
                          [_vm._v("修改")]
                        ),
                        record.status != 1 && record.status != 2
                          ? _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.applyEss(record)
                                  },
                                },
                              },
                              [
                                _c("a-divider", {
                                  attrs: { type: "vertical" },
                                }),
                                _vm._v(" 申请开通 "),
                              ],
                              1
                            )
                          : _vm._e(),
                        record.status == 2 ||
                        (record.status >= 1 &&
                          _vm.user.nowRoleKey.includes("admin"))
                          ? _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.createConsoleLoginUrl(record)
                                  },
                                },
                              },
                              [
                                _c("a-divider", {
                                  attrs: { type: "vertical" },
                                }),
                                _vm._v(" 进入控制台 "),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                ]),
              ]
            },
          },
        ]),
      }),
      _c(
        "a-modal",
        {
          attrs: { visible: _vm.previewVisible, footer: null },
          on: {
            cancel: function ($event) {
              return _vm.handleCancelOrganizationLicenseImg()
            },
          },
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { alt: "example", src: _vm.previewVisibleImg },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }