<template>
  <a-card>
    <a-spin :spinning="spinning" :tip="loadingText">
    <div :class="advanced ? 'search' : null">
      <a-form layout="horizontal">
        <div :class="advanced ? null: 'fold'">
          <a-row >
          <a-col :md="8" :sm="24" >
            <a-form-item
              label="姓名"
              :labelCol="{span: 5}"
              :wrapperCol="{span: 18, offset: 1}"
            >
              <a-input placeholder="请输入" v-model="queryParams.userName"/>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24" >
            <a-form-item
              label="身份证号"
              :labelCol="{span: 5}"
              :wrapperCol="{span: 18, offset: 1}"
            >
              <a-input placeholder="身份证号" v-model="queryParams.idCard"/>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24" >
            <a-form-item
              label="班组名称"
              :labelCol="{span: 5}"
              :wrapperCol="{span: 18, offset: 1}"
            >
              <a-select
                show-search
                placeholder="请输入或选择班组"
                option-filter-prop="children"
                style="width: 200px"
                :filter-option="filterOption"
                @focus="handleFocus"
                @blur="handleBlur"
                @change="handleChange"
              >
                <a-select-option :key="item.id" v-for="item in teamDatas" :value="item.id">
                  {{item.teamName}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        </div>
        <span style="float: right; margin-top: 3px;">
          <a-button type="primary" @click="doQuery()">查询</a-button>
          <a-button style="margin-left: 8px" @click="doReset()">重置</a-button>
        </span>
      </a-form>
    </div>
    <div>
      <a-space class="operator">
        <a-button @click="doDowloadBatch()">导出名册</a-button>
      </a-space>
      <standard-table
        :columns="columns"
        :dataSource="dataSource"
        :selectedRows.sync="selectedRows"
        :pagination="pagination"
        :loading="tableLoading"
        @clear="onClear"
        @change="onPageChange"
      >
        <!-- <div slot="safediscloseFileName" slot-scope="{text}">
          <div v-if="text!=null&&text!=''">{{text}}</div>
          <a-icon v-else style="color:red" type="close" />
        </div>
        <div slot="do-status" slot-scope="{text}">
          <a-icon v-if="text==1" style="color:green" type="check" />
          <a-icon v-else style="color:red" type="close" />
        </div> -->
        <!-- <div slot="action" slot-scope="{text, record}"> -->
          <!--<router-link :to="`/list/query/detail/${record.key}`" >打包下载资料</router-link> -->
          <!-- <a @click="doDowload(`${record.key}`,`${record.userName}`)">打包下载</a> -->
          <!--<a-divider type="vertical" />
          <a @click="doRestartContract(`${record.key}`)">重新生成合同</a>
           <a-divider type="vertical" />
          <a @click="doRestartRcxz(`${record.key}`)">重新生成入场须知</a>  
          <a-divider type="vertical" />
          <a @click="doRestartEssContract(`${record.key}`)">重新生成电子签</a>  -->
        <!-- </div> -->
        <!-- <template slot="statusTitle">
          <a-icon @click.native="onStatusTitleClick" type="info-circle" />
        </template> -->
      </standard-table>
    </div>
    <Export-loading :cPercent="cPercent" :isExporting="isExporting" :loadingText="loadingText"></Export-loading>
    </a-spin>
  </a-card>
</template>

<script>
import StandardTable from '@/components/table/StandardTable'
import {
  QUERY_NO_CONTRACTS, 
  EXPORT_NO_CONTRACTS,
  FIND_TEAMS_BY_USER
  } from '@/services/api'
  
import { exportProgress } from '@/pages/aqjyzl/export' // 导出方法
import ExportLoading from '@/components/ExportLoading/ExportLoading' // progress组件
import {request} from '@/utils/request'
import axios from 'axios'
import { te } from 'date-fns/locale'
// import axios from '@/utils/axios'
import { useRender } from '@/hooks/useRender.js'

const columns = [
  {
    title: '姓名',
    dataIndex: 'userName',
    align: 'center',
    scopedSlots: { customRender: 'userName' }
  },
  {
    title: '身份证号',
    dataIndex: 'idCard',
    align: 'center',
    scopedSlots: { customRender: 'idCard' },
  },
  {
    title: '性别',
    dataIndex: 'gender',
    align: 'center',
    scopedSlots: { customRender: 'gender' },
  },
  {
    title: '联系方式',
    dataIndex: 'phone',
    align: 'center',
    scopedSlots: { customRender: 'phone' },
  },
  {
    title: '所属班组',
    dataIndex: 'teamName',
    align: 'center',
   scopedSlots: { customRender: 'teamName' }
  },
  {
    title: '所属公司',
    dataIndex: 'officeName',
    align: 'center',
    scopedSlots: {customRender: 'officeName'}
  },
  {
    title: '无合同原因',
    dataIndex: 'reason',
    align: 'center',
    scopedSlots: {customRender: 'reason'}
  },
  {
    title: '记录时间',
    dataIndex: 'addTime',
    align: 'center',
    width:160,
    customRender: (text) => useRender.renderDate(text)
  }
]

export default {
  mixins: [exportProgress],
  name: 'QueryList',
  components: {StandardTable,ExportLoading},
  data () {
    return {
      dataSource:[],
      advanced: false,
      columns: columns,
      selectedRows: [],
      teamDatas:[],
      tableLoading: false,
      spinning:false,
      loadingText:"数据加载中...",
      loadKeys:[],
      visible:false,
      overDownIndex:0,
      queryParams:{
        userName:null,
        idCard:null,
        teamId:null,
        pageNumber:1,
      },
      pagination:{
        showSizeChanger:true,
        pageSizeOptions: ['10', '20', '30', '50'],
        current:1,
        pageSize:10,
        total:0,
        showLessItems:true,
        showQuickJumper:true
      },
      cPercent:0,
      isExporting:false,
      fileName:'入场资料文件',
    }
  },
  watch: {
    cPercent:{
      handler(newName, oldName) {
        this.cPercent=newName;
      },
      immediate: true,
    },
    loadingText:{
      handler(newName, oldName) {
        this.loadingText=newName;
      },
      immediate: true,
    }
  },
  created() {
    this.doQuery();
    this.doQueryTeams();
  },
  authorize: {
    deleteRecord: 'delete'
  },
  methods: {
    handleChange(value) {
      this.queryParams.teamId = value;
      this.doQuery();
    },
    handleBlur() {
      console.log('blur');
    },
    handleFocus() {
      console.log('focus');
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    doQueryTeams(){
      request(FIND_TEAMS_BY_USER,"post",{}).then(res => {
          if(res.data.code==0){
            this.teamDatas = res.data.data;
          }
        })
    },

    deleteRecord(key) {
      this.dataSource = this.dataSource.filter(item => item.key !== key)
      this.selectedRows = this.selectedRows.filter(item => item.key !== key)
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    remove () {
      this.dataSource = this.dataSource.filter(item => this.selectedRows.findIndex(row => row.key === item.key) === -1)
      this.selectedRows = []
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },

    /**
     * 获取所有人员的签名记录信息
     */
    doQuery(){
      this.tableLoading = true;
        request(QUERY_NO_CONTRACTS,"post",this.queryParams).then(res => {
          if(res.data.code==0){
            this.dataSource = res.data.data.list;
            this.pagination.total = res.data.data.total;
            this.dataSource.forEach(obj=>{
              obj.key = obj.id;
            })
          }
          this.tableLoading = false;
        })
    },
    doReset(){
      this.queryParams={};
      this.doQuery();
    },
    //分页查询
    onPageChange(e){
      this.queryParams.pageNumber = e.current;
      this.queryParams.pageSize = e.pageSize;
      this.pagination.pageSize = e.pageSize;
      this.pagination.current=e.current;
      this.doQuery();
    },
    /**
     * 批量下载
     */
    doDowloadBatch(){
      this.loadKeys = [];
      let userName = '';
      this.cPercent = 0;
      if(this.selectedRows&&this.selectedRows.length>0){
        this.isExporting=true
        this.loadingText = "正在导出...";
        let items = [];
        let idList = [];
        this.selectedRows.map((item) => {
            items.push(item);
            idList.push(item.id);
        })
        let params = {
            idList:idList
        }
        let fileName = ""
        fileName="无效合同人员名单.xlsx";
        // this.exportFunNomal(EXPORT_NO_CONTRACTS,params,fileName,items)
        this.exportFunNomal2(
          EXPORT_NO_CONTRACTS,
          params,
          fileName,
        "application/vnd.ms-excel"
      );
      }else{ 
        this.$message.info('请选中无电子合同人员名册导出');
        return;
      }

        // if(this.selectedRows.length==1){
        //   userName = this.selectedRows[0].userName
        // }
        // this.isExporting=true;
        // this.cPercent=0;
        // let fileName = ""
        // fileName=userName+"入场资料下载.zip";
        // this.exportFun(SAFEMEANS_DOWNLOAD,this.loadKeys,fileName,this.selectedRows)
      //   request(SAFEMEANS_DOWNLOAD,"post",this.loadKeys,
      //     {
      //       responseType:"blob",
      //       headers:{ 'Content-Type': 'application/json; application/octet-stream'}
      //     }
      //     ).then(res => {
      //     this.spinning = false; 
      //     const content = res.data;
      //     console.log(typeof content);
      //     const reader = new FileReader()
      //     var blob = new Blob([content], {type: "application/zip"})
      //     reader.readAsDataURL(blob)
      //     reader.onload = (e) => {
      //     const a = document.createElement('a')
      //       a.download = userName+"入场资料下载.zip";
      //       a.href = e.target.result
      //       document.body.appendChild(a)
      //       a.click()
      //       document.body.removeChild(a)
      //     }
      // })
      
    },
    
    handleMenuClick (e) {
      if (e.key === 'delete') {
        this.remove()
      }
    },
    
    doSureModal(){
      this.visible = true;
    }
  }
}
</script>

<style lang="less" scoped>
  .search{
    margin-bottom: 54px;
  }
  .fold{
    width: calc(100% - 216px);
    display: inline-block
  }
  .operator{
    margin-bottom: 18px;
  }
  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
</style>
