<template>
  <a-card>
    <a-spin :spinning="spinning" :tip="loadingText">
    <label v-if="obj.isZongLaowu==0">温馨提示：项目参数设置暂只允许项目总包劳务员才能设置，如需更改设置，请联系总包劳务员更改设置，非常感谢！</label>
        <!-- <a-form-model :model="projectConfig"  :labelCol="{span: 5}"
                      :wrapperCol="{span: 18, offset: 1}">
            <a-form-model-item label="入场模式">
                <a-radio-group v-model="projectConfig.rcMode">
                    <a-radio value="1">
                        详细模式
                    </a-radio>
                    <a-radio value="2">
                        简洁模式
                    </a-radio>
                </a-radio-group>
            </a-form-model-item>
        </a-form-model> -->
      <a-form  :form="form" layout="vertical" @submit="doSave">
        <a-form-item
          label="三级安全教育手动发起"
          :labelCol="{span: 3}"
          :wrapperCol="{span: 3, offset: 1}"
          v-if="!isYYZY"
        >
          <a-switch :disabled="obj.isZongLaowu==0"  v-model="obj.params.sjaqjy"  />
        </a-form-item>
        <a-form-item style="margin-top: -20px;"
          label="是否启用三级安全教育视频学习"
          :labelCol="{span: 4}"
          :wrapperCol="{span: 4, offset: 1}"
          v-if="!isYYZY"
        >
          <a-switch :disabled="obj.isZongLaowu==0"  v-model="obj.params.isOpenVideo"  />
        </a-form-item>
        <a-card title="通用三级安全教育课程设置：">
          <a-row :gutter="24">
            <a-col :span="7" >
              <a-form-item
              label="一级教育课程"
              :labelCol="{span: 7}"
              :wrapperCol="{span: 12, offset: 1}"
              >
                <a-select
                  show-search
                  placeholder="请输入或选择课程"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  @change="handleChange"
                  v-model="obj.params.eduSet.oneEduCourseId"
                >
                  <a-select-option :key="item.id" v-for="item in courseData" :value="item.id">
                    {{item.name}}
                  </a-select-option>
                </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="7" >
            <a-form-item
              label="二级教育课程"
              :labelCol="{span: 7}"
              :wrapperCol="{span: 12, offset: 1}"
            >
              <a-select
                show-search
                placeholder="请输入或选择课程"
                option-filter-prop="children"
                :filter-option="filterOption"
                @change="handleChange"
                v-model="obj.params.eduSet.twoEduCourseId"
              >
                <a-select-option :key="item.id" v-for="item in courseData" :value="item.id">
                  {{item.name}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="7" >
            <a-form-item
              label="三级教育课程"
              :labelCol="{span: 7}"
              :wrapperCol="{span: 12, offset: 1}"
            >
              <a-select
                show-search
                placeholder="请输入或选择课程"
                option-filter-prop="children"
                :filter-option="filterOption"
                @change="handleChange"
                v-model="obj.params.eduSet.threeEduCourseId"
              >
                <a-select-option :key="item.id" v-for="item in courseData" :value="item.id">
                  {{item.name}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        </a-card>
        <a-card title="工种三级安全教育课程设置：">
          <a-row v-for="(courseSet,index) in obj.params.jobTitleSet" :key="index" :gutter="24">
            <a-col :span="5" >
              <a-form-item
              label="工种"
              :labelCol="{span: 4}"
              :wrapperCol="{span: 14, offset: 1}"
              >
                <a-select
                mode="multiple"
                  show-search
                  placeholder="请输入或选择工种"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  @change="handleChange"
                  v-model="courseSet.titleId"
                >
                  <a-select-option :key="item.id" v-for="item in jobTitles" :value="item.id"  :title="item.carft">
                    {{item.carft}}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="5" >
              <a-form-item
              label="一级教育课程"
              :labelCol="{span: 8}"
              :wrapperCol="{span: 15, offset: 1}"
              >
                <a-select
                  show-search
                  placeholder="请输入或选择课程"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  @change="handleChange"
                  v-model="courseSet.oneEduCourseId"
                >
                  <a-select-option :key="item.id" v-for="item in courseData" :value="item.id" :title="item.name">
                    {{item.name}}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="5" >
              <a-form-item
              label="二级教育课程"
              :labelCol="{span: 8}"
              :wrapperCol="{span: 15, offset: 1}"
              >
                <a-select
                  show-search
                  placeholder="请输入或选择课程"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  @change="handleChange"
                  v-model="courseSet.twoEduCourseId"
                >
                  <a-select-option :key="item.id" v-for="item in courseData" :value="item.id" :title="item.name">
                    {{item.name}}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="5" >
              <a-form-item
              label="三级教育课程"
              :labelCol="{span: 8}"
              :wrapperCol="{span: 15, offset: 1}"
              >
                <a-select
                  show-search
                  placeholder="请输入或选择课程"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  @change="handleChange"
                  v-model="courseSet.threeEduCourseId"
                >
                  <a-select-option :key="item.id" v-for="item in courseData" :value="item.id" :title="item.name">
                    {{item.name}}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="4">
              <a-form-item>
                <a-icon class="imdexStyle" type="plus-circle" @click="addTitleSet"/>
                <a-icon class="imdexStyle" type="minus-circle" @click="removeTitleSet(index)"/>
              </a-form-item>
            </a-col>
          </a-row>
        </a-card>

      <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
          <a-button icon="save" type="primary" :disabled="obj.isZongLaowu==0&&!isYYZY" html-type="submit">
              确认设置
          </a-button>
      </a-form-item>
      </a-form>

    </a-spin>
  </a-card>
</template>

<script>
import {
  PROJECT_PARAM_SETTING_FIND,
  PROJECT_PARAM_SAVE,
  ONLINE_COURSE_QUERY_ALL,
  QUERY_JOBTITLE_ALL,
  } from '@/services/api'
  import {mapGetters,mapMutations} from 'vuex'
import {changeAccount} from '@/services/user'
import { getProjectConfig, updateProjectConfig } from '@/services/projectConfig';
import {request} from '@/utils/request'
// import axios from '@/utils/axios'

export default {
  name: 'mobile_setting',
  components: {},
  data () {
    return {
      jobTitles:[],
      obj:{
        id:null,
        isZongLaowu:0,
        params:{
          isOpenVideo:false,
          sjaqjy:false,  //0：手动发起，1：自动发起
          eduSet:{
            oneEduCourseId:null,
            twoEduCourseId:null,
            threeEduCourseId:null
          },
          jobTitleSet:[{titleId:[],oneEduCourseId:null,twoEduCourseId:null,threeEduCourseId:null}],
        },
      },
      courseData:[],
      spinning:false,
      isYYZY:false,
      projectConfig:{},
      loadingText:"数据加载中..."
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'validate_other' });
  },
  computed: {
    ...mapGetters('account', ['user']),
  },
  created() {
      this.getProjectConfig()
    this.doQuery();
    this.doQueryCourse();
    this.doQueryJobTitles();
    // console.log(this.user);
    let nowRoleKey = this.user.nowRoleKey;
    if(nowRoleKey&&nowRoleKey.length>0&&nowRoleKey.indexOf('PLATFORM_YYZY')>-1){
      this.isYYZY = true
    }else{
      this.isYYZY = false
    }
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange(){

    },
    doQueryCourse(){
      request(ONLINE_COURSE_QUERY_ALL,"post",{}).then(res => {
          if(res.data.code==0){
            this.courseData = res.data.data;
          }
        })
    },
    doQueryJobTitles(){
      request(QUERY_JOBTITLE_ALL,"get",{}).then(res => {
          if(res.data.code==0){
            this.jobTitles = res.data.data;
          }
        })
    },
      getProjectConfig(){
        getProjectConfig().then(res => {
          this.projectConfig = res.data.data;
            this.projectConfig.rcMode = this.projectConfig.rcMode+""
        })
      },
    /**
     * 获取项目设置的参数配置信息
     */
    doQuery(){
        request(PROJECT_PARAM_SETTING_FIND,"post",{}).then(res => {
          if(res.data.code==0&&res.data.data.length>0){
            let objs = res.data.data;
            this.obj.isZongLaowu = objs[0].isZongLaowu;
            let jsonParams = JSON.parse(objs[0].params);
            if(objs[0].id){
              this.obj.id = objs[0].id
            }
            Object.assign(this.obj.params,jsonParams);
            console.log(this.obj)
            if(!this.obj.params){
              let autoObj = {
                sjaqjy:false,
                eduSet:{
                  oneEduCourseId:null,
                  twoEduCourseId:null,
                  threeEduCourseId:null
                }
              }
              let obj = {
                titleId:[],
                oneEduCourseId:null,
                twoEduCourseId:null,
                threeEduCourseId:null
              }
              autoObj.jobTitleSet.push(obj);
              this.obj.params = autoObj;
            }

          }
        })
    },
    /**
     * 保存表单信息
     */
    doSave(e){
        e.preventDefault();
        let cloneObj = JSON.parse(JSON.stringify(this.obj))
        var paramsStr = JSON.stringify(cloneObj.params)//转换成字符串
        cloneObj.params = paramsStr;
        request(PROJECT_PARAM_SAVE,"post",cloneObj).then(res => {
          if(res.data.code==0&&res.data.data.id){
            this.obj = res.data.data;
            if(this.obj.params){
              this.obj.params = JSON.parse(this.obj.params)
              this.$message.success('设置成功');
            }

          }
        })

        updateProjectConfig(this.projectConfig).then(res => {
          if(res.data.code==0){
            // this.$message.success('设置成功');
            console.log('设置成功')
          }
        })
    },
    handleMenuClick (e) {
      if (e.key === 'delete') {
        this.remove()
      }
    },
    addTitleSet(){
      let obj = {
        titleId:null,
        oneEduCourseId:null,
        twoEduCourseId:null,
        threeEduCourseId:null
      }
      this.obj.params.jobTitleSet.push(obj);
    },
    removeTitleSet(index){
      if(this.obj.params.jobTitleSet.length==1){
        this.$message.error("已经是最后一个了，不能再删除了");
        return false;
      }
      this.obj.params.jobTitleSet.splice(index,1)
    }
  }
}
</script>

<style lang="less" scoped>
  .search{
    margin-bottom: 54px;
  }
  .fold{
    width: calc(100% - 216px);
    display: inline-block
  }
  .operator{
    margin-bottom: 18px;
  }
  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
  .imdexStyle{
    font-size: 20px;
    margin-left: 10px;
  }
</style>
