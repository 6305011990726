<template>
  <div>
    <a-row :gutter="16">
      <a-col :span="6">
        <vue-scroll :ops="ops" style="width:100%;height:855px">
        <a-card title="工种" size="small" :bordered="false" >
          <a-tree :load-data="onLoadData" :tree-data="treeData" @select="onSelect" >
            <template slot="title" slot-scope="{ title }">
                <span>{{ title }}</span>
            </template>
          </a-tree>
        </a-card>
        </vue-scroll>
      </a-col>
        <a-col :span="17" :offset="0">
            <a-card  :bordered="false">
              <template slot="title" >
                <span>{{node.title}}班组清单</span>
                <span style="float:right">
                  <a style="float:right;margin-top:3px;margin-left:10px;font-size:13px" href="https://sjlw-templ.oss-cn-shenzhen.aliyuncs.com/enterprise-persons/import?Expires=1954460648&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=uwdRAtJbqH5fXT7xkQByjj%2BCPoE%3D">模板下载</a>
                  <a-upload
                        name="file"
                        :multiple="false"
                        :beforeUpload="beforeUpload"
                        accept=".xls,.xlsx"
                        :customRequest="resourceUpload"
                        :file-list="fileListFile"
                    >
                      <a-button :disabled="importLoading" style="margin-left:10px" >导入名册</a-button>
                    </a-upload>
                </span>
                
                </template>
              <div :class="advanced ? 'search' : null">
                <a-form layout="horizontal">
                  <div :class="advanced ? null: 'fold'">
                    <a-row >
                      <a-col :md="8" :sm="24" >
                        <a-form-item
                          label="班组名称"
                          :labelCol="{span: 5}"
                          :wrapperCol="{span: 18, offset: 1}"
                        >
                          <a-input placeholder="请输入班组名称" v-model="queryParam.teamName"/>
                        </a-form-item>
                      </a-col>
                      <a-col :md="8" :sm="24" >
                        <a-form-item
                          label="负责人"
                          :labelCol="{span: 5}"
                          :wrapperCol="{span: 18, offset: 1}"
                        >
                          <a-input placeholder="请负责人姓名" v-model="queryParam.fzrUserName"/>
                        </a-form-item>
                      </a-col>
                      <a-col :md="8" :sm="24" >
                        <a-form-item
                          label="手机"
                          :labelCol="{span: 5}"
                          :wrapperCol="{span: 18, offset: 1}"
                        >
                          <a-input placeholder="负责人手机" v-model="queryParam.fzrPhone"/>
                        </a-form-item>
                      </a-col>
                    </a-row >
                  </div>
                  <span style="float:right">
                    <a-button type="primary" @click="doQuery()">查询</a-button>
                    <a-button style="margin-left:8px;" type="primary" @click="doAddTeam()">新增班组</a-button>
                  </span>
                </a-form>
              </div>
              <a-row >
                <a-col :md="24" :sm="24" >
                  <standard-table
                    :columns="columns"
                    :dataSource="teams_data"
                    :selectedRows.sync="selectedRows"
                    :pagination="pagination"
                    :loading="tableLoading"
                    :isAlert="false"
                    @clear="onClear"
                    @change="onPageChange"
                  >
                    <div slot="action" slot-scope="{text, record}">
                      <a-icon style="color:#FC5531" type="unordered-list" @click="viewPersons(record)" title="查看人员列表"></a-icon>
                      <a-divider type="vertical" />
                      <a-icon style="color:blue" type="edit" @click="doOpenTeam(record)" title="编辑班组"></a-icon>
                      <a-divider type="vertical" />
                      <a-icon style="color:red" type="delete" @click="doDeleteTeam(`${record.key}`)" title="删除班组"></a-icon>
                    </div>
                    <template slot="statusTitle">
                      <a-icon @click.native="onStatusTitleClick" type="info-circle" />
                    </template>
                  </standard-table>
                 </a-col>
              </a-row>
            </a-card>
        </a-col>
    </a-row>
    <team-editor
      :visible="teamEditor.visible"
      :teamInfo="teamEditor.teamInfo"
      :typeWorkDataSource="teamEditor.typeWorkDataSource"
      @teamPageClose="doCloseTeamEditor"
      @submit="doSubmitTeamInfo($event)"
    ></team-editor>
    <team-persons
      :visible="teamPersons.visible"
      :teamId="teamPersons.teamId"
      :teamName="teamPersons.teamName"
      @doCloseTeamPersons="doCloseTeamPersonsList"
      @doOutWork="doOutWorks"
    ></team-persons>
    <a-modal v-model="outwork.visible" title="请选择项目" @ok="handleOk" @cancel="cancelWork">
        <a-select
            show-search
            placeholder="请选择一个项目生成派工单"
            option-filter-prop="children"
            style="width: 450px;z-index:999999999"
            :filter-option="filterOption"
            @change="handleChange"
            v-model="projectId"
          >
            <a-select-option style="z-index:999999999"  v-for="item in outwork.projectList" :key="item.projId" :value="item.projId">
              {{item.fullName}}
            </a-select-option>
          </a-select>
      </a-modal>
  </div>
</template>


<script>
import {
  JOB_TYPE_WORK_QUERY, 
  JOB_JOBTITLES_QUERY,
  TEAM_JOBTITLES_QUERY,
  TEAM_INFO_TAG_INSERT,
  TEAM_INFO_TAG_UPDATE,
  TEAM_INFO_TAG_DELETE,
  PERSON_ENTERPRISE_IMPORT,
  FIND_PROJECT_OFFICE,
  OUTWORK_ORDER_CREARTE
  } from '@/services/api'
  
import {request} from '@/utils/request'
import StandardTable from '@/components/table/StandardTable'
import TeamEditor from '@/pages/enterprisepool/teameditor'
import TeamPersons from '@/pages/enterprisepool/teampersons'
import {mapGetters} from 'vuex'
const columns = [
  { title: '班组名称', dataIndex: 'teamName',key:'1', align: 'center'},
  { title: '负责人', dataIndex: 'fzrUserName',key:'2', align: 'center' },
  { title: '负责人电话', dataIndex: 'fzrPhone',key:'3' , align: 'center'},
  { title: '在场/总人数', dataIndex: 'inTotal',key:'4' , align: 'center'},
  { id:4,title: '操作', dataIndex: '',key:'5', scopedSlots: { customRender: 'action' }, align: 'center' },
];

export default {
  name: 'QueryList',
  components: {StandardTable,TeamEditor,TeamPersons},
  // components: {StandardTable},
  data() {
    return {
      teams_data:[],
      columns,
      selectedRows: [],
      tableLoading: false,
      advanced:false,
      fileListFile:[],
      importLoading:false,
      chooseIdCards:[],
      chooseTeamId:null,
      projectId:null,
      queryParam:{
        phone:null,
        userName:null,
        pageNumber:0,
      },
      outwork:{
        visible:false,
        projectList:[]
      },
      autoExpandParent: true,
      // 树形结构
      expandedKeys: [],
      treeData: [],
      node:{},
      pagination:{
        current:1,
        pageSize:10,
        total:0,
        showLessItems:true,
        showQuickJumper:true
      },
      teamEditor:{
        visible:false,
        teamInfo:{},
        typeWorkDataSource:[]
      },
      teamPersons:{
        visible:false,
        teamId:null
      },
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: "#4A86EB",//滚动条颜色
          opacity: 0.8,//滚动条透明度
          "overflow-x": "hidden"
        }
      }
    };
  },
  created() {
    this.queryTypeWork();
    this.doQuery();
    this.queryProject();
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange(value) {
      console.log(`selected ${value}`);
    },
    
    handleOk(){
      if(!this.projectId){
        this.$message.error("请选择一个项目进行派工单！");
        return false;
      }
      if(!this.chooseTeamId){
        this.$message.error("班组信息丢失！");
        return false;
      }
      let projectsArr = this.outwork.projectList.filter(item => item.projId == this.projectId );
      if(projectsArr.length>0){
          let obj = {
            projectId:this.projectId,
            projectName:projectsArr[0].fullName,
            idCards:this.chooseIdCards,
            teamId:this.chooseTeamId
          }
          request(OUTWORK_ORDER_CREARTE, "post", obj).then((res) => {
          if (res.data.code === 0) {
            this.projectId = null;
            this.chooseTeamId = null;
            this.chooseIdCards = [];
            this.outwork.visible = false;
            // this.$router.push('/enterprise-pool/out-work-order')
          }else{
            this.$message.error("生成异常！");
          }
        });
      }else{
          this.$message.error("项目名称获取失败");
        return false;
      }
    },
    queryProject(){
      request(FIND_PROJECT_OFFICE,"get",{}).then(res => {
        if(res.data.code==0){
          this.outwork.projectList = res.data.data;
        }
      })
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    doReset(){
      this.queryParam={};
      this.doQuery();
    },

    /** 
    * 上传之前
    */
    beforeUpload (file) {
      return new Promise((resolve, reject) => {
        const fileType = file.type;
        const isExcel = fileType === 'application/vnd.ms-excel' || fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        const isFiveM= file.size / 1024 / 1024 < 5 //小于5M的图片上传
        //判断文件是否符合正则表达式的规则
        if (!isFiveM) {
          this.$message.error('上传资源不大于5M！');
          return reject(false)
        } else if (!isExcel) {
          this.$message.error('只能上传.xls和.xlsx文件');
          return reject(false)
        } else{
          this.fileListFile=[];
          this.fileListFile.push(file)
          return resolve(true);
        }
      });
    },
    /**
    * 资源上传
    */
    resourceUpload(value){
        this.importLoading = true;
        const formData = new FormData();
        formData.append('file', value.file);
        request(PERSON_ENTERPRISE_IMPORT,"post",formData).then(result => {
          this.importLoading = false;
          if (result.data.code==0) {
            if( result.data.data!=null&& result.data.data.length>0){
              const h = this.$createElement;
              let msg = [];
              result.data.data.forEach(o=>{
                  msg.push(h('div',o.userName))
              })

              this.$warning({ title: '以下人员导入异常：', content:  h('div', {}, msg) });
            }else{
              this.$message.success("导入成功");
            }
            value.onSuccess(result, value.file);
          }else{
            this.$message.warning(result.message);
          }
        })
    },
    //分页查询
    onPageChange(e){
      this.queryParam.pageNumber = e.current;
      this.pagination.current=e.current;
      this.doQuery();
    },
    onLoadData(treeNode) {
      return new Promise(resolve => {
        setTimeout(() => {
          request(JOB_JOBTITLES_QUERY,"post",{typeWorkId:treeNode.dataRef.id}).then(res => {
            if(res.data.code==0){
              treeNode.dataRef.children = [];
              res.data.data.forEach(obj=>{
                obj.isLeaf = true;
                obj.title = obj.name;
                obj.key = `${treeNode.eventKey}-${obj.id}`
                obj.nodeType = "2";
                treeNode.dataRef.children.push(obj);
              })
            }
            this.treeData = [...this.treeData];
            resolve();
          })
        }, 500);
      });
    },
    onSelect(selectedKeys,e){
      this.tableLoading = true;
      if(e.node&&e.node.dataRef){
        this.node = e.node.dataRef
      }
      let typeWorkId = null;
      if(this.node&&this.node.nodeType==1){
        typeWorkId = this.node.id
      }
      let totitleId = null;
      if( this.node&&this.node.nodeType==2){
        totitleId = this.node.id
      }
      let obj = {
        typeWorkId:typeWorkId,
        jobtitleId:totitleId
      }
      request(TEAM_JOBTITLES_QUERY,"post",Object.assign(obj, this.queryParam)).then(res => {
          if(res.data.code==0){
            res.data.data.forEach(obj=>{
              obj.key = obj.id
            })
            this.pagination.total = res.data.data.total;
            this.teams_data = res.data.data.list;
          }
       })
      this.tableLoading = false;
    },
    doQuery(){
      this.tableLoading = true;
      let typeWorkId = null;
      if( this.node&&this.node.nodeType==1){
        typeWorkId = this.node.id
      }
      let totitleId = null;
      if( this.node&&this.node.nodeType==2){
        totitleId = this.node.id
      }
      let obj = {
        typeWorkId:typeWorkId,
        jobtitleId:totitleId
      }
      request(TEAM_JOBTITLES_QUERY,"post",Object.assign(obj, this.queryParam)).then(res => {
            if(res.data.code==0){
              res.data.data.forEach(obj=>{
                obj.key = obj.id
              })
              this.teams_data = res.data.data.list;
              this.pagination.total = res.data.data.total;
            }
       })
       this.tableLoading = false;
    },
    onExpand(){

    },
    queryTypeWork(){
      request(JOB_TYPE_WORK_QUERY,"post",{}).then(res => {
        if(res.data.code==0){
          this.treeData = res.data.data;
          this.teamEditor.typeWorkDataSource=res.data.data;
          this.treeData.forEach(obj=>{
            obj.title = obj.name;
            obj.key = obj.id;
            obj.isLeaf = false;
            obj.nodeType = "1"
          })
        }
      })
    },
    /**班组操作start */
    doDeleteTeam($id){
      var _that = this
      this.$confirm({
          title: '确认删除班组',
          content: '您好，删除班组会删除该班组下面的人员，请确定所有人员都清除或移动到别的班组！',
          okText: '仍旧删除',
          okType: 'danger',
          cancelText: '暂时取消',
          onOk() {
                request(TEAM_INFO_TAG_DELETE+$id, "delete", {}).then((res) => {
                if (res.data.code === 0) {
                  _that.$message.success("删除成功！");
                  _that.doQuery();
                }else{
                  _that.$message.error("删除异常！");
                }
              });
          },
          onCancel() {
              console.log('Cancel');
          },
      });
    },
    doOpenTeam(team){
      this.teamEditor.teamInfo = team;
      this.teamEditor.visible=true
    },

    doAddTeam(){
      this.teamEditor.teamInfo = {};
      this.teamEditor.visible=true
    },

    doCloseTeamEditor(){
      this.teamEditor.visible=false
    },
    doSubmitTeamInfo($event){
      var url = $event.teamInfo.id?TEAM_INFO_TAG_UPDATE:TEAM_INFO_TAG_INSERT
      request(url, "post", $event.teamInfo).then((res) => {
        if (res.data.code === 0) {
          this.teamEditor.visible = false;
          this.$message.success("保存成功！");
          this.doQuery();
        }else{
          this.$message.error("保存异常！");
        }
      });
    },
    viewPersons($team){
      this.teamPersons.visible = true;
      this.teamPersons.teamId = $team.id;
      this.teamPersons.teamName = $team.teamName;
    },
    doCloseTeamPersonsList(){
      this.teamPersons.visible = false;
      this.teamPersons.teamId = null;

    },
    /**班组操作end */
    doOutWorks($event){
      this.teamPersons.visible = false;
      this.chooseIdCards = $event.idCards;
      this.chooseTeamId = $event.teamId;
      this.outwork.visible = true;
      // if(!this.projectId){
      //   this.$message.error("请选择一个项目进行派工单！");
      //   return false;
      // }
      // let projectsArr = this.outwork.projectList.filter(item => item.projId == this.projectId );
      // if(projectsArr.length>0){
      //     let obj = {
      //       projectId:this.projectId,
      //       projectName:projectsArr[0].fullName,
      //       idCards:this.chooseIdCards,
      //       teamId:this.chooseTeamId
      //     }
      //     request(OUTWORK_ORDER_CREARTE, "post", obj).then((res) => {
      //     if (res.data.code === 0) {
      //       this.projectId = null;
      //       this.chooseTeamId = null;
      //       this.chooseIdCards = [];
      //       this.outwork.visible = false;
      //     }else{
      //       this.$message.error("生成异常！");
      //     }
      //   });
      // }else{
      //     this.$message.error("项目名称获取失败");
      //   return false;
      // }
    },
    cancelWork(){
      var _this = this;
      this.$confirm({
        title: '放弃生成派工单',
        content: h => <div style="color:red;">您确认放弃此次派工单了吗？</div>,
        okText: '不，继续生成',
        cancelText: '是的',
        onOk() {
          //生成派工单保存
          if(!_this.projectId){
            _this.$message.error("请选择一个项目进行派工单！");
            return false;
          }
          if(!_this.chooseTeamId){
            _this.$message.error("班组选择异常！");
            return false;
          }
          let projectsArr = this.outwork.projectList.filter(item => item.projId == _this.projectId );
          if(projectsArr.length>0){
              // console.log(projectsArr[0].fullName);
              // console.log(_this.projectId);
              // console.log(_this.chooseTeamId);
            let obj = {
              projectId:_this.projectId,
              projectName:projectsArr[0].fullName,
              idCards:_this.chooseIdCards,
              teamId:_this.chooseTeamId
            }
            request(OUTWORK_ORDER_CREARTE, "post", obj).then((res) => {
              if (res.data.code === 0) {
                _this.projectId = null;
                _this.chooseTeamId = null;
                _this.chooseIdCards = [];
                _this.outwork.visible = false;
              }else{
                this.$message.error("生成派工单异常！");
              }
            })
          }else{
             _this.$message.error("项目名称获取失败");
            return false;
          }
          
        },
        onCancel() {
          _this.projectId = null;
          _this.chooseTeamId = null;
          _this.chooseIdCards = [];
          _this.outwork.visible = false;
        },
        class: 'test',
      });
    }
  },
  
}
</script>

<style lang="less" scoped>
  .search{
    margin-bottom: 54px;
  }
  .fold{
    width: calc(100% - 216px);
    display: inline-block
  }
  .operator{
    margin-bottom: 18px;
  }
  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
</style>
