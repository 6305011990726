var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "create_index" },
    [
      _c(
        "form-index",
        {
          key: _vm.random,
          ref: "formindex",
          attrs: {
            formData: _vm.formData,
            Overview: "项目概况",
            options: _vm.address,
            baiduMapBoolen: true,
            topSubmit: true,
            projectList: _vm.projectList,
            defaultFileList: _vm.defaultFileList,
          },
          on: {
            handleChange: _vm.handleChange,
            handleSubmit: _vm.handleSubmit,
            handlSelect: _vm.handlSelect,
            handleSearch: _vm.handleSearch,
            resourceUpload: _vm.resourceUpload,
          },
          scopedSlots: _vm._u([
            {
              key: "topSubmit",
              fn: function () {
                return [
                  _c(
                    "a-button",
                    { attrs: { type: "primary", "html-type": "submit" } },
                    [_vm._v(" 提交 ")]
                  ),
                  _c("a-button", { on: { click: _vm.close } }, [
                    _vm._v(" 关闭 "),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._l(_vm.officeList, function (item, index) {
            return _c(
              "template",
              { slot: item.officeType },
              [
                _c("a-input", {
                  key: index,
                  attrs: { placeholder: "请输入" },
                  model: {
                    value: item.officeName,
                    callback: function ($$v) {
                      _vm.$set(item, "officeName", $$v)
                    },
                    expression: "item.officeName",
                  },
                }),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }