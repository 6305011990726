<template>
  <a-card title="报价单列表">
    <a-space class="operator">
      <a-button type="primary" @click="createItem"> 新增 </a-button>
      <a-button type="primary"> 导出 </a-button>
    </a-space>
    <standard-table
      :columns="columns"
      :dataSource="dataSource"
      row-key="id"
      :pagination="pagination"
      :loading="tableLoading"
      @change="onPageChange"
      :bordered="true"
    >
      <template slot="index" slot-scope="{ index }">
        <span>
          {{
            (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1
          }}
        </span>
      </template>
      <!-- <template slot="name" slot-scope="{ text, record }">
        <a-tooltip placement="topLeft">
          <template slot="title"
            ><span>{{ text }}</span></template
          >
          <span class="ant-btn-text ellipsis" @click="toUnits(record)">{{
            text
          }}</span>
        </a-tooltip>
      </template> -->
      <div slot="action" slot-scope="{ record }">
        <a-dropdown v-if="isAdmin" placement="bottomCenter" class="sTables">
          <div>
            <span>操作</span>
            <a-icon
              style="margin-left: 2px; font-size: 12px"
              type="caret-down"
            />
          </div>
          <a-menu :class="['avatar-menu']" slot="overlay">
            <a-menu-item @click="viewEditing(record, 'edit')">
              <a-icon type="edit" />
              <span>修改</span>
            </a-menu-item>
            <a-menu-item @click="recorDelete(record)">
              <a-icon type="delete" />
              <span>删除</span>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <span v-else>/</span>
      </div>
    </standard-table>
  </a-card>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import { useRender } from '@/hooks/useRender.js'

const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    align: "center",
  },
  {
    title: "标题",
    align: "center",
    dataIndex: "title",
    width: 80,
  },
  {
    title: "报价单号",
    align: "center",
    dataIndex: "orderNo",
    width: 100,
  },
  {
    title: "价格",
    dataIndex: "totalMoney",
    width: 80,
    align: "center",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "创建时间",
    dataIndex: "createTime",
    width: 80,
    align: "center",
    customRender: (text) => useRender.renderDate(text)
  },

  {
    title: "操作",
    align: "center",
    width: 80,
    scopedSlots: { customRender: "action" },
  },
];
const formData = [
  {
    label: "产品名称",
    placeholder: "请输入产品名称",
    decorator: [
      "name",
      { rules: [{ required: false, message: "请输入产品名称" }] },
    ],
    type: "inputGroup",
    key: "name",
    dataSource: [],
    col: 4,
    labelCol: 6,
    wrapperCol: 14,
  },
];
import { getQuotationList,delQuotation } from "@/services/product";
import { mapGetters } from "vuex";
export default {
  name: "SuijianClientProjectListIndex",
  components: {
    // formIndex, //form表单封装
    StandardTable, //getProductList表格封装
  },
  data() {
    return {
      columns, //表格数据
      dataSource: [],
      formData: formData,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      prams: {
        pageNumber: 1,
        pageSize: 10,
      },
      tableLoading: false,
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
    isAdmin() {
      return this.user.nowRoleKey.includes("admin");
    },
  },

  mounted() {
    this.aqxjlist(this.prams);
  },

  methods: {
    //表格 - 分页查询
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      this.prams.pageNumber = e.current;
      this.pagination.current = e.current;
      this.aqxjlist(Object.assign({}, this.prams, obj));
    },
    // 重置
    close() {
      this.prams = {
        pageNumber: 1,
        pageSize: 10,
      };
      this.aqxjlist(this.prams);
      this.$refs["formindex"].resetFields();
    },
    // 新增报价单
    createItem(e) {
      this.$router.push({
        name: "报价单详情",
      });
    },
    // 获取产品列表
    aqxjlist(prams) {
      this.tableLoading = true;
      getQuotationList(prams).then((res) => {
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
        }
        this.tableLoading = false;
      });
    },
    // 封装弹窗确定按钮
    confirm(title, content) {
      return new Promise((resolve, reject) => {
        this.$confirm({
          title: title,
          content: content,
          onOk() {
            resolve();
          },
        });
      });
    },
    // 编辑详情
    viewEditing(e) {
      this.$router.push({
        name: "报价单详情",
        query: {
          id: e.id,
        },
      });
    },
    // 删除子产品
    recorDelete(e) {
      let params = {
        id: e.id,
      };
      this.confirm("删除", "是否删除").then(() => {
        delQuotation(params).then((res) => {
          if (res.data.code === 0) {
            this.$message.success("删除成功", 10);
            this.aqxjlist(this.prams);
          } else {
            this.$message.error(res.data.msg, 10);
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 显示行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-btn-text {
  color: #4a86e8;
  background-color: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ant-btn-text:hover {
  cursor: pointer;
  color: #76abf5;
  background-color: transparent;
  border-color: #76abf5;
}

</style>