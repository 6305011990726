<template>
    <a-card :bordered="false">

        <div>
            <a-form :form="searchForm" >
                <a-row :gutter="24">
                    <!-- <a-col :span="6" >
                        <a-form-item :label="`公司名称`" :labelCol="{span: 6}" :wrapperCol="{span: 16, offset: 1}">
                            <a-input v-model="searchForm.officeName" placeholder="请输入公司名称" />
                        </a-form-item>
                    </a-col> -->
                    <a-col :span="6" >
                        <a-form-item :label="`项目名称`" :labelCol="{span: 6}" :wrapperCol="{span: 16, offset: 1}">
                            <a-input v-model="searchForm.projectName" placeholder="请输入公司名称" />
                        </a-form-item>
                    </a-col>

                    <a-col :span="4" :style="{ marginTop: '3px'}">
                        <a-space>
                            <a-button type="primary" icon="search" :loading="loading" @click="search">
                            查询
                            </a-button>
                            <a-button class="ant-btn-reset" @click="doReset()">
                                    重置
                            </a-button>
                        </a-space>
                    </a-col>
                </a-row>


            </a-form>
        </div>
        <a-table
                :columns="columns"
                :data-source="data"
                :pagination="pagination"
                @change="onPageChange"
                :loading="tableLoading"
                :bordered="true"
                    size="middle"
        >

            <template slot="action" slot-scope="text, record">
                                <span>
                                  <a @click="showModal(record)">编辑</a>
                                  <a-divider type="vertical" />
                                  <a @click="del(record)">删除</a>
                                </span>
            </template>
        </a-table>
    </a-card>


</template>
<script>
    import {getCrossProjectList} from '@/services/aqjyzl'
    import {request} from '@/utils/request'

    const columns = [
        {
            title: '工人姓名',
            dataIndex: 'workerName',
            align:'center',
            key: 'workerName',
        },
        {
            title: '工人身份证号',
            dataIndex: 'workerIdCard',
            align:'center',
            key: 'workerIdCard',
        },
        // {
        //     title: '工人电话',
        //     dataIndex: 'phone',
        //     align:'center',
        //     key: 'phone',
        // },
        {
            title: '已在场项目',
            align:'center',
            dataIndex: 'projectName',
            key: 'projectName',
        },
        {
            title: '班组',
            dataIndex: 'teamName',
            align:'center',
            key: 'teamName',
        },
        {
            title: '班组长电话',
            align:'center',
            dataIndex: 'teamLeaderPhone',
            key: 'teamLeaderPhone',
        },
    ];


    export default {
        data() {
            return {

                // 筛选
                searchForm: {
                    officeName: "",
                    projectName: "",
                    pageNumber:1,
                    pageSize:10,
                },
                loading: false,


                // 数据表格
                columns,
                data: [],
                pagination:{
                    current:1,
                    pageSize:10,
                    total:0,
                    showTotal: (total, range) => `共 ${total} 条`
                },
                tableLoading: false,


            };
        },
        created() {
            this.initData()
        },
        methods: {
            initData() {
                this.searchForm.pageNumber = 1
                this.pagination.current = 1
                this.getCrossProjectList()
            },
            search() {
                this.initData()
            },
            getCrossProjectList(){
                this.data = []
                this.detailsData = []
                this.loading = true
                this.tableLoading = true
                getCrossProjectList(this.searchForm).then(res=>{
                    this.data = res.data.data.list
                    this.pagination.total = res.data.data.total
                }).finally(()=>{
                    this.loading = false
                    this.tableLoading = false
                })
            },
            onPageChange(e){
                this.searchForm.pageNumber = e.current;
                this.pagination.current=e.current;
                this.getCrossProjectList();
            },
            doReset() {
            this.searchForm= {
                officeName: "",
                projectName: "",
                pageNumber:1,
                pageSize:10,
            },
            this.pagination.current=1;
            this.getCrossProjectList();
        },


        },
    };

</script>
<style lang="less" scoped>
    .ant-advanced-search-form {
        padding: 24px;
        background: #fbfbfb;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        margin-bottom: 10px;
    }

    .ant-advanced-search-form .ant-form-item {
        display: flex;
    }

    .ant-advanced-search-form .ant-form-item-control-wrapper {
        flex: 1;
    }

    #components-form-demo-advanced-search .ant-form {
        max-width: none;
    }

    tr{
        background-color: #ffffff;
    }
        .ant-btn-reset {
        color: #307dfa;
        border-color: #307dfa;
        }

</style>
