var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a_Approval" },
    [
      _c("div", { staticClass: "plug-Overview" }, [
        _vm._v("月度项目检查情况一览表"),
      ]),
      _c("a-divider"),
      _c(
        "a-form-model",
        {
          staticClass: "mb-8 query-form",
          attrs: { layout: "inline", model: _vm.params },
          on: { submit: _vm.handleSubmit },
        },
        [
          _vm.user.loginType != 0
            ? _c(
                "a-form-model-item",
                { attrs: { label: "工程管理部" } },
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "220px" },
                      attrs: { allowClear: "", placeholder: "请选择" },
                      model: {
                        value: _vm.params.gcglbId,
                        callback: function ($$v) {
                          _vm.$set(_vm.params, "gcglbId", $$v)
                        },
                        expression: "params.gcglbId",
                      },
                    },
                    _vm._l(_vm.gcglbList, function (item) {
                      return _c(
                        "a-select-option",
                        {
                          key: item.value,
                          attrs: { value: item.value, title: item.name },
                        },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            { attrs: { label: "月度" } },
            [
              _c(
                "a-month-picker",
                {
                  staticStyle: { width: "220px" },
                  attrs: { placeholder: "请选择月份", format: "YYYY-MM" },
                  model: {
                    value: _vm.monthPickerVal,
                    callback: function ($$v) {
                      _vm.monthPickerVal = $$v
                    },
                    expression: "monthPickerVal",
                  },
                },
                [
                  _c("a-icon", {
                    attrs: { slot: "suffixIcon", type: "smile" },
                    slot: "suffixIcon",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.user.loginType != 0
            ? _c(
                "a-form-model-item",
                { attrs: { label: "项目名称" } },
                [
                  _c("a-input", {
                    staticStyle: { width: "220px" },
                    attrs: { placeholder: "请输入项目名称", allowClear: "" },
                    model: {
                      value: _vm.params.projectName,
                      callback: function ($$v) {
                        _vm.$set(_vm.params, "projectName", $$v)
                      },
                      expression: "params.projectName",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "a-space",
        [
          _c(
            "a-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
            [_vm._v(" 查询 ")]
          ),
          _c("a-button", { on: { click: _vm.resetQuery } }, [_vm._v(" 重置 ")]),
          _vm.user.isGcglb == 0 &&
          _vm.user.loginType == 1 &&
          _vm.user.nowRoleKey.includes("JS_LJJ")
            ? _c(
                "a-button",
                { attrs: { type: "primary" }, on: { click: _vm.doDowload } },
                [_vm._v(" 导出 ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("standard-table", {
        attrs: {
          columns: _vm.columns,
          dataSource: _vm.tableData,
          rowKey: "index",
          pagination: _vm.pagination,
          loading: _vm.tableLoading,
          isAlert: false,
          bordered: true,
        },
        on: { change: _vm.handleChangePage },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function (ref) {
              var index = ref.index
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      (_vm.pagination.current - 1) * _vm.pagination.pageSize +
                        parseInt(index) +
                        1
                    )
                  ),
                ]),
              ]
            },
          },
          {
            key: "problems",
            fn: function (ref) {
              var index = ref.index
              var record = ref.record
              return [
                _c(
                  "a-tooltip",
                  { attrs: { placement: "topLeft" } },
                  [
                    _c(
                      "template",
                      { slot: "title" },
                      [
                        _vm._l(record.problems, function (problem) {
                          return [_vm._v(_vm._s(problem))]
                        }),
                      ],
                      2
                    ),
                    _c(
                      "div",
                      { staticClass: "overtext-1" },
                      [
                        _vm._l(record.problems, function (problem) {
                          return [_vm._v(_vm._s(problem))]
                        }),
                      ],
                      2
                    ),
                  ],
                  2
                ),
              ]
            },
          },
        ]),
      }),
      _c("Export-loading", {
        attrs: {
          cPercent: _vm.cPercent,
          isExporting: _vm.isExporting,
          loadingText: _vm.loadingText,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }