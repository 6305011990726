<template>
  <div class="a_Approval">
    <div class="plug-Overview">安全责任管理</div>
    <a-divider />
    <a-spin :spinning="spinning" :tip="loadingText">
      <div :class="advanced ? 'search' : null">
        <a-form class="clearfix" v-if="user.loginType == 1">
          <div :class="advanced ? null : 'fold'">
            <a-col :span="5">
              <a-form-item label="所属单位" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
                <a-select
                  allowClear
                  placeholder="请选择单位"
                  v-model="queryParams.officeId"
                  @change="handlSelect($event)"
                >
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option
                    v-for="item in options"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.master || item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="5">
              <a-form-item label="项目名称" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
                <a-select
                  allowClear
                  v-model="queryParams.projectId"
                  show-search
                  placeholder="请输入或选择项目"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  @change="handleChange"
                  @search="handleSearch"
                  @focus="handleFocus"
                  @blur="handleBlur"
                >
                  <a-select-option
                    :key="item.projectId"
                    v-for="item in projectDatas"
                    :value="item.projectId"
                    :title="item.projectName"
                  >
                    {{ item.projectName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="5">
              <a-form-item label="查询人员" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
                <a-select
                  v-model="queryParams.userId"
                  show-search
                  allowClear
                  placeholder="请输入或选择"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  @change="handleUserChange"
                  @focus="handleFocus"
                  @blur="handleBlur"
                >
                  <a-select-option
                    :key="item.id"
                    v-for="item in userSafeList"
                    :value="item.id"
                    :title="item.name"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="5">
              <a-space class="btnBox">
                <a-checkbox @change="onMulti" :checked="multiOpen">
                  多项目履职人员
                </a-checkbox>
                <a-checkbox
                  @change="onExceed"
                  :checked="exceedOpen"
                >
                  未上传项目
                </a-checkbox>
              </a-space>
            </a-col>
            <a-col :span="4">
              <a-space class="btnBox">
                <a-button type="primary" @click="Query()">查询</a-button>
                <a-button style="margin-left: 8px" @click="doReset()"
                  >重置</a-button
                >
              </a-space>
            </a-col>
          </div>
        </a-form>
      </div>
      <div>
        <standard-table
          class="headerTablestyle"
          :bordered="true"
          :columns="columns"
          :dataSource="dataSource"
          :pagination="pagination"
          :loading="tableLoading"
          @change="onPageChange($event)"
          @clear="onClear"
        >
          <template slot="index" slot-scope="{ index }">
            <span>
              {{
                (pagination.current - 1) * pagination.pageSize +
                parseInt(index) +
                1
              }}
            </span>
          </template>
          <div slot="gcName" slot-scope="{ text }">
            <div v-if="text != null && text != ''">
              {{ text || fruitLoader }} 
            </div>
            <span v-else></span>
          </div>
          <!-- 项目名称 -->
          <template slot='Name' slot-scope='{ text, record }'>
            <a-tooltip placement='topLeft'>
              <template slot='title'>
                <span
                  :title="text"
                  :class="record.status == 4 || record.status == 1 ? 'colorRed' : ''"
                  v-if="text != null && text != ''"
                >
                  {{ text }}
                </span>
              </template>
              <div class='overtext-1'>
                <span
                  :title="text"
                  :class="record.status == 4 || record.status == 1 ? 'colorRed' : ''"
                  v-if="text != null && text != ''"
                >
                  {{ text }}
                </span>
              </div>
            </a-tooltip>
          </template>

          <div slot="do-status" slot-scope="{ text }">
            <div v-if="text != '' && text != null">
              <template v-if="icludesPdf(text, str) == -1">
                <img
                  v-if="text != '' && text != null"
                  :src="text"
                  style="width: 40px; height: 40px; cursor: pointer"
                  @click="showImg(text)"
                />
              </template>
              <template v-else-if="icludesPdf(text, str) != -1">
                <!-- style="color:red" -->
                <a-icon
                  type="file-pdf"
                  :style="{ fontSize: '35px' }"
                  @click="showPdf(text)"
                />
                <!-- 有bug慎用存在前端跨域问题 只能使用window.open(this.url) -->
                <!-- <pdf-view :visible.sync="visiblePdf" :url="pdfUrl" @closeView="closeView" title="PDF预览"/> -->
              </template>
            </div>
          </div>

          <div slot="do-userList" slot-scope="{ text, record }">
            <a-popover placement="bottom">
              <template slot="content">
                <p
                  v-for="(item, key) in record.userNames.split(',')"
                  :key="key"
                >
                  {{ item }}
                </p>
              </template>
              <span>{{ text | subText }}</span>
            </a-popover>
          </div>

          <div slot="xmleader" slot-scope="{ record }">
            <div
              v-for="(item, index) in record.xmList"
              :key="index"
              :class="[active == item.cnt ? '' : 'colorRes']"
              class="aqyBorder"
            >
              <div>{{ item.userName }}</div>
              <span v-if="item.phone">({{ item.phone }})</span>
            </div>
          </div>
          <div slot="appointDate-aqy" slot-scope="{ record }">
            <!-- :class="setClass(item)" -->
            <div
              v-for="(item, index) in record.detailList"
              :key="index"
              :class="setClass(item)"
            >
              <div style="height: 42px">
                <template v-if="item.userPhone != 13924219200">
                  <div
                    style="height: 42px; line-height: 42px"
                    v-if="item.appointDate"
                  >
                    {{ useRender.renderDate(item.appointDate) }}
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div slot="xmaqy" slot-scope="{ record }">
            <!--  :class="[active == item.cnt ? '' : 'colorRes']"
              class="aqyBorder" -->
            <div
              v-for="(item, index) in record.detailList"
              :key="index"
              :class="setClass(item, record.zzaqList)"
            >
              <div v-if="item.userPhone != 13924219200">
                <div>{{ item.userName }}</div>
                <span v-if="item.userPhone">({{ item.userPhone }})</span>
              </div>
            </div>
          </div>
          <!-- 安全员数组 zzrmsSafe -->
          <div slot="do-aqyList" slot-scope="{ record }">
            <div
              v-for="(item, index) in record.detailList"
              :key="index"
              :class="setClass(item)"
            >
              <div style="height: 42px">
                <div v-if="item.zzrmsSafe != '' && item.zzrmsSafe != null">
                  <template v-if="icludesPdf(item.zzrmsSafe, str) == -1">
                    <img
                      v-if="item.zzrmsSafe != '' && item.zzrmsSafe != null"
                      :src="item.zzrmsSafe"
                      style="width: 40px; height: 40px; cursor: pointer"
                      @click="showImg(item.zzrmsSafe)"
                    />
                  </template>
                  <template v-else-if="icludesPdf(item.zzrmsSafe, str) != -1">
                    <!-- style="color:red" -->
                    <a-icon
                      type="file-pdf"
                      :style="{ fontSize: '35px' }"
                      @click="showPdf(item.zzrmsSafe)"
                    />
                  </template>
                </div>
              </div>
            </div>
          </div>
          <!-- 安全数组展示 aqzrs -->
          <div slot="do-aqyList-aqzrs" slot-scope="{ record }">
            <div
              v-for="(item, index) in record.detailList"
              :key="index"
              :class="setClass(item)"
            >
              <div style="height: 42px">
                <div v-if="item.aqzrsSafe != '' && item.aqzrsSafe != null">
                  <template v-if="icludesPdf(item.aqzrsSafe, str) == -1">
                    <img
                      v-if="item.aqzrsSafe != '' && item.aqzrsSafe != null"
                      :src="item.aqzrsSafe"
                      style="width: 40px; height: 40px; cursor: pointer"
                      @click="showImg(item.aqzrsSafe)"
                    />
                  </template>
                  <template v-else-if="icludesPdf(item.aqzrsSafe, str) != -1">
                    <a-icon
                      type="file-pdf"
                      :style="{ fontSize: '35px' }"
                      @click="showPdf(item.aqzrsSafe)"
                    />
                  </template>
                </div>
              </div>
            </div>
          </div>
          <template slot="action" slot-scope="{ record }">
            <a @click="doEdit(record)">
              编辑
              <!-- <a-icon
                type="edit"
                title="编辑责任"
                :style="{ fontSize: '20px' }"
              /> -->
            </a>
          </template>
          <template slot="statusTitle" slot-scope="{ text, record }">
            <a @click="showPersons(record)">{{ text }}</a>
          </template>
        </standard-table>
      </div>
    </a-spin>
    <modify-duty
      :visible="duty.visble"
      :duty.sync="duty.duty"
      :userList="userList"
      @coursePageClose="dutyClose"
      @dutySubmit="dutySubmit"
      @doEdit="doEdit"
    />
    <a-modal
      :destroyOnClose="true"
      :visible="modalVisible"
      :footer="null"
      @cancel="handleCancel"
    >
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import StandardTable from "@/components/table/StandardTable";
import ModifyDuty from "@/pages/safeview/modifyduty";
import {
  GET_SAFEDUTY_LIST,
  QUERY_OFFICE_BY_USER,
  GET_STFE_FIND_USER,
  GET_ALL_USER,
} from "@/services/api";
import { getOfficeIdProject, getSafeDutyByProjId } from "@/services/aqjyzl";
import { projectMsgGetList } from "@/services/projectManagement";
import { request } from "@/utils/request";
import moment from "moment";
import { useRender } from '@/hooks/useRender.js'
import { getProjectByOffice } from "@/services/statistics";
const columns = [
  {
    title: "序号",
    align: "center",
    width: 60,
    scopedSlots: { customRender: "index" },
  },
  {
    title: "项目名称",
    dataIndex: "projectName",
    ellipsis: true,
    width: 120,
    scopedSlots: { customRender: "Name" },
  },
  {
    title: "工程管理部",
    dataIndex: "childOfficeName",
    align: "center",
    width: 60,
    // sorter: (a, b) => a.sort - b.sort,
    // sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
    // defaultSortOrder: "ascend",
    scopedSlots: { customRender: "gcName" },
  },
  {
    title: "项目经理",
    align: "center",
    className: "datestyle",
    scopedSlots: { customRender: "xmjl" },
    children: [
      {
        title: "姓名电话",
        align: "center",
        dataIndex: "xmleaderName",
        width: 70,
        scopedSlots: { customRender: "xmleader" },
      },
      {
        title: "任命时间",
        align: "center",
        dataIndex: "appointDate",
        width: 60,
        customRender: (text) => useRender.renderDate(text)
      },
      {
        title: "安全生产管理目标责任书",
        align: "center",
        width: 60,
        dataIndex: "safeManagerDuty",
        scopedSlots: { customRender: "do-status" },
      },
      {
        title: "安全生产第一责任人",
        align: "center",
        children: [
          {
            title: "任命书",
            dataIndex: "safeRms",
            align: "center",
            width: 35,
            scopedSlots: { customRender: "do-status" },
          },
          {
            title: "任命状",
            dataIndex: "safeRmz",
            align: "center",
            width: 35,
            scopedSlots: { customRender: "do-status" },
          },
          {
            title: "承诺书",
            dataIndex: "scCns",
            align: "center",
            width: 35,
            scopedSlots: { customRender: "do-status" },
          },
          {
            title: "责任书",
            dataIndex: "scZrs",
            align: "center",
            width: 35,
            scopedSlots: { customRender: "do-status" },
          },
        ],
      },

      {
        title: "消防安全责任人",
        align: "center",

        children: [
          {
            title: "任命书",
            dataIndex: "xfRms",
            align: "center",
            width: 35,
            scopedSlots: { customRender: "do-status" },
          },
          {
            title: "任命状",
            dataIndex: "xfRmz",
            align: "center",
            width: 35,
            scopedSlots: { customRender: "do-status" },
          },
        ],
      },
    ],
  },
  {
    title: "专职安全员",
    align: "center",
    className: "datestyle",
    children: [
      {
        title: "姓名电话",
        align: "center",
        dataIndex: "zzaqList",
        width: 70,
        scopedSlots: { customRender: "xmaqy" },
      },
      {
        title: "任命时间",
        align: "center",
        dataIndex: "appointDate-aqy",
        width: 70,
        scopedSlots: { customRender: "appointDate-aqy" },
      },
      {
        title: "任命书",
        dataIndex: "zzrmsSafe",
        align: "center",
        width: 35,
        scopedSlots: { customRender: "do-aqyList" },
      },
      {
        title: "责任书",
        dataIndex: "aqzrsSafe",
        align: "center",
        width: 35,
        scopedSlots: { customRender: "do-aqyList-aqzrs" },
      },
    ],
  },
  {
    title: "操作",
    align: "center",
    width: 40,
    key: 'action',
    scopedSlots: { customRender: "action" },
  },
];
import { session } from '@/utils/storage'
import { xsrfHeaderName } from '@/utils/request.js'
import { mapGetters } from "vuex";
export default {
  name: "SuijianClientSafeDuty",
  components: { StandardTable, ModifyDuty },
  data() {
    return {
      headers: {
        Authorization: session.get(xsrfHeaderName),
        'Tenant-Id': session.get('Tenant-Id') ? session.get('Tenant-Id') : ''
      },
      duty: {
        visble: false,
        duty: {},
        typeDuty: [],
      },
      sortedInfo: null,
      size: "small",
      projectDatas: [],
      dataSource: [],
      selectedRows: [],
      spinning: false,
      advanced: false,
      columns: columns,
      queryParams: {
        projectId: undefined,
        pageNumber: 1,
        pageSize: 10,
        officeId: undefined,
        userId: undefined,
        isRed: 0,
        isNullFile: undefined,
      },
      userList: [],
      userSafeList: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      loadingText: "数据加载中...",
      tableLoading: false,
      str: "pdf",
      modalVisible: false,
      previewImage: "",
      pdfUrl: "",
      visiblePdf: false,
      isleader: false,
      officeId: undefined,
      gcglbtype: false,
      options: [],
      active: 1,
      exceedList: [],
      multiOpen: false,
      exceedOpen: false,
      value: '',
      setSafeRoles: ['project_leader','PROJECT_AQY_ZB_FZR'], // 项目安全责任编辑权限
      useRender
    };
  },

  activated() {
    this.getStafFindUser();
    this.doQuery();
    this.officeId = this.$store.getters["account/user"].officeId;
    this.isleader = this.$store.getters["account/user"].nowRoleKey.includes([
      "project_leader",
    ]);
    this.getgcglbList(QUERY_OFFICE_BY_USER);
  },
  filters: {
    fruitLoader(v) {
      // let reg = new RegExp("第");
      let reg1 = new RegExp("工程管理");
      // v = v.replace(reg, "");
      v = v.replace(reg1, "");
      return v;
    },
  },
  computed: {
    ...mapGetters("account", ["user"]),
    canOperate() { // 劳务员确认信息权限
      return this.user.nowRoleKey.some(role => this.setSafeRoles.includes(role));
    },
  },
  mounted() {
      if ((this.user.isGcglb == 1 && this.loginType == 1) || (this.user.loginType == 0 && !this.canOperate)) {
        //（分公司账号）或者 (项目部-没有权限)去除编辑操作
        let keyToDelete = 'action'
        this.columns = this.columns.filter(item => item.key !== keyToDelete)
      }
      // 非机施安全
      if (!this.$store.state.setting.realmType) {
        // 找到 title 为 工程管理部 的对象
        const column = this.columns.find(column => column.title === '工程管理部')
        // 修改属性值
        if(column) this.$set(column, 'title', '管理部门')
      }
      this.doQueryTeams('','')
  },
  methods: {
    moment,
    Query(pageSize) {
      this.queryParams.pageNumber = 1;
      this.queryParams.pageSize = pageSize;
      this.doQuery();
    },
    // pdf预览
    showPdf(imgPath) {
      window.open(imgPath);
    },
    closeView() {
      this.visiblePdf = false;
    },
    // 图片预览
    showImg(imgPath) {
      this.modalVisible = true;
      this.previewImage = imgPath;
    },
    handleCancel() {
      this.modalVisible = false;
      this.previewImage = "";
    },
    // 判断是pdf还是图片
    icludesPdf(url, str) {
      if (url) {
        return url.toLowerCase().indexOf(str);
      }
    },
    confirmImgChange(info) {},
    handleChange(value) {
      this.queryParams.projectId = value;
      this.doQuery();
    },
    handleUserChange(value) {
      this.queryParams.userId = value;
      this.doQuery();
    },
    handleBlur() {
      console.log("blur");
    },
    handleFocus() {
      console.log("focus");
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handlSelect(e) {
      this.doQueryTeams('',e)
    },
    doQueryTeams(value, e) {
      getProjectByOffice({projectName: value,gcglbId:e}).then((res) => {
        if (res.data.code === 0) {
          this.projectDatas = res.data.data;
        }
      });
    },
    handleSearch(value) {
      // this.doQueryTeams(value,this.queryParams.officeId)
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },
    /**
     * 获取所有项目的安全责任记录
     */
    doQuery() {
      // this.exceedOpen = false;
      this.tableLoading = true;
      if (this.user.loginType == 0) {
        this.queryParams.projectId = this.user.projectId
      }
      request(GET_SAFEDUTY_LIST, "post", this.queryParams).then((res) => {
        console.log(res.data.data,'所属单位')
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
          this.dataSource.forEach((obj) => {
            obj.key = obj.projectId;
          });
        }
        this.tableLoading = false;
      });
    },
    doReset() {
      this.queryParams = {};
      this.exceedOpen = false;
      this.multiOpen = false;
      this.pagination.current = 1
      this.projectDatas = []
      this.doQueryTeams('','')
      this.doQuery();
    },
    //分页查询
    onPageChange(e) {
      this.queryParams.pageNumber = e.current;
      this.pagination.current = e.current;
      this.doQuery();
    },
    // 编辑
    doEdit(recode, del) {
      if (del) {
        this.duty.visble = false;
        this.duty.duty = {};
      }
      getSafeDutyByProjId(recode.projectId).then((res) => {
        res.data.data.childOfficeName = recode.childOfficeName;
        res.data.data.projectName = recode.projectName;
        this.duty.duty = res.data.data;
        this.duty.visble = true;
      });
    },
    // 关闭侧边栏
    dutyClose() {
      this.duty.visble = false;
      this.duty.duty = {};
      this.doQuery();
    },
    dutySubmit($event) {
      this.dutyClose();
    },
    // 工程管理部数组
    getgcglbList(path) {
      request(path, "get").then((res) => {
        this.options = res.data.data;
      });
    },
    // 安全责任筛选责任人数组user
    getStafFindUser() {
      request(GET_ALL_USER, "post", {}).then((res) => {
        this.userList = res.data.data;
      });
      request(GET_STFE_FIND_USER, "post", {}).then((res) => {
        res.data.data.forEach(el => {
          el.name = el.nickname
        })
        this.userSafeList = res.data.data;
      });
    },

    // 多项目开关
    multi(e) {
      if (e == true) {
        this.queryParams.isRed = 1;
      } else {
        this.queryParams.isRed = 0;
      }
      this.Query();
    },
    onMulti(e) {
      this.multiOpen = e.target.checked;
      if (this.multiOpen) {
        this.queryParams.isRed = 1;
      } else {
        this.queryParams.isRed = 0;
      }
      this.Query();
    },
    // 超期5天未履职
    onExceed(e) {
      let now = moment().format("YYYY-MM-DD"); // 当前时间
      this.exceedOpen = e.target.checked;
      if (this.exceedOpen) {
        this.queryParams.isNullFile = 1;
      } else {
        this.queryParams.isNullFile = undefined;
      }
      this.Query();
    },
    // 判断项目经理的任命书是否存在
    isXmLeaderPath(item) {
      if (
        item.safeRms &&
        item.safeRmz &&
        item.xfRms &&
        item.xfRmz &&
        item.scCns &&
        item.scZrs
      ) {
        return true;
      } else {
        return false;
      }
    },
    setClass(item, zzaqList) {
      let v = [];
      if (zzaqList) {
        zzaqList.map((aqy) => {
          if (aqy.phone == item.userPhone && this.active != aqy.cnt) {
            v.push("colorRes");
          }
        });
      }
      if (item.userPhone != 18676535625 && item.userPhone != 13924219200) {
        v.push("aqyBorder");
      }
      return v;
    },
  },
};
</script>

<style lang="less" scoped>
.search {
  margin-bottom: 54px;
}
/deep/ .ant-checkbox + span {
  padding-left: 0 !important;
}
.fold {
  width: 100%;
  display: inline-block;
}
.operator {
  margin-left: 15px;
  margin-bottom: 18px;
}
@media screen and (max-width: 900px) {
  .fold {
    width: 100%;
  }
}
.img-small {
  border-radius: 50%;
  overflow: hidden;
}

.headerTablestyle {
  & /deep/ .ant-table-thead > tr > th {
    font-weight: bold;
  }
}
.colorRed {
  color: red;
}
.colorRes {
  // color: rgb(255, 115, 0);
  color: blue;
}

.aqyBorder {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 10px;
  // padding-top: 10px;
  // height: 40px;
}

.aqyBorder:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-top: 10px;
}
</style>
