<template>
  <div>
    <standard-table
      class=""
      :columns="columns"
      :dataSource="dataSource"
      rowKey="id"
      :pagination="pagination"
      :loading="tableLoading"
      :isAlert="false"
      :bordered="true"
      @change="onPageChange"
    >
      <template slot="index" slot-scope="{ index }">
        <span>
          {{
            (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1
          }}
        </span>
      </template>
      <template slot="attachment" slot-scope="{ text }">
          <a-avatar shape="square" :size="64" :src="text" icon="user"/>
        </template>
        <div slot="action" slot-scope="{ record }">
            <div class="sTables">
              <div @click="doDowloadBatch(record)">下载证书</div>
            </div>
          </div>
    </standard-table>
  </div>
</template>

<script>
import StandardTable from "@/components/table/StandardTable";
import moment from "moment";
import {
  getCertificateTypeList,
  getstaffCertMyList,
} from "@/services/participationUnit";
import { useRender } from '@/hooks/useRender.js'

const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
  },
  // {
  //   title: "证书类型",
  //   dataIndex: "certType",
  //   align: "center",
  //   scopedSlots: { customRender: "certType" },
  // },
  {
    title: "证书名称",
    dataIndex: "certName",
    align: "center",
    scopedSlots: { customRender: "certName" },
  },
  {
    title: "证书编号",
    dataIndex: "certNum",
    align: "center",
    scopedSlots: { customRender: "certNum" },
  },
  {
    title: "附件",
    align: "center",
    dataIndex: "attachment",
    scopedSlots: { customRender: "attachment" },
  },
  {
    title: "持证人",
    dataIndex: "workName",
    align: "center",
    ellipsis: true,
    scopedSlots: { customRender: "workName" },
  },
  {
    title: "联系电话",
    dataIndex: "phone",
    align: "center",
    scopedSlots: { customRender: "phone" },
  },
  {
    title: "初领时间",
    dataIndex: "effectiveStart",
    align: "center",
    customRender: (text) => useRender.renderDate(text, 'YYYY-MM-DD')
  },
  {
    title: "有效期限",
    dataIndex: "effectiveEnd",
    align: "center",
    customRender: (text) => useRender.renderDate(text, 'YYYY-MM-DD')
  },
  {
    title: "是否有效",
    dataIndex: "isValid",
    align: "center",
    scopedSlots: { customRender: "isValid" },
  },
  {
    title: "操作",
    align: "center",
    fixed: "right",
    width: 100,
    scopedSlots: { customRender: "action" },
  },
];
export default {
  components: {
    StandardTable,
  },
  data() {
    return {
      columns: columns, //table数据key对应
      dataSource: [], //table数组
      pagination: {
        //分页数据
        current: 1,
        pageSize: 10,
        total: 0,
      },
      params: {
        //永远都是第一页
        pageNumber: 1,
        pageSize: 10,
        enterStatus: "1",
        workNum: '',
      },
      tableLoading: false, //table加载中
      selectedRows: [], //选中的数据
      certTypeList: []//证书类型列表
    };
  },
  
  mounted() {
    this.params.workNum = this.$route.query.workNum
    this.getCertificateTypeList();
    this.getstaffCertMyList();
  },
  methods: {
    getCertificateTypeList() {
      getCertificateTypeList({ isPage: "false" }).then((res) => {
        if (res.data.code === 0) {
          this.certTypeList = res.data.data.list
        }
      });
    },
    getstaffCertMyList() {
      getstaffCertMyList(this.params).then((res) => {
        this.tableLoading = false;
        if (res.data.code === 0) {
          this.dataSource = res.data.data;
          this.pagination.total = res.data.total;
          this.dataSource.forEach((el) => {
            // el.certType = this.certTypeList.find((i) => i.value === el.certType).typeName;
            el.effectiveStart = moment(el.effectiveStart).format("YYYY-MM-DD");
            el.effectiveEnd = moment(el.effectiveEnd).format("YYYY-MM-DD");
            el.isValid = el.isValid ? "有效" : "无效";
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    doDowloadBatch(record) {
      window.open(record.attachment)
    },
    //表格 - 分页查询
    onPageChange(e) {
      this.params.pageNumber = e.current;
      this.pagination.current = e.current;
      this.params.pageSize = e.pageSize;
      this.pagination.pageSize = e.pageSize;
      this.getData();
    },
  },
};
</script>

<style lang="less" scoped>
  .sTables {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      width: 100%;
      text-align: center;
      cursor: pointer;
    }

    div:nth-child(1) {
      color: #307dfa;
    }

    div:nth-child(2) {
      color: #ff5c77;
    }

    div:nth-child(3) {
      color: #615e83;
    }
  }
</style>
