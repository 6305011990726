var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    [
      _c(
        "div",
        { class: _vm.advanced ? "search" : null },
        [
          _c("a-form", { attrs: { layout: "horizontal" } }, [
            _c(
              "div",
              { class: _vm.advanced ? null : "fold" },
              [
                _c(
                  "a-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "a-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "a-form-item",
                          {
                            attrs: {
                              label: "姓名",
                              labelCol: { span: 5 },
                              wrapperCol: { span: 16, offset: 1 },
                            },
                          },
                          [
                            _c("a-input", {
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.queryParams.userName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryParams, "userName", $$v)
                                },
                                expression: "queryParams.userName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "a-form-item",
                          {
                            attrs: {
                              label: "身份证号",
                              labelCol: { span: 5 },
                              wrapperCol: { span: 16, offset: 1 },
                            },
                          },
                          [
                            _c("a-input", {
                              attrs: { placeholder: "身份证号" },
                              model: {
                                value: _vm.queryParams.idCard,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryParams, "idCard", $$v)
                                },
                                expression: "queryParams.idCard",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "a-form-item",
                          {
                            attrs: {
                              label: "进场日期",
                              labelCol: { span: 5 },
                              wrapperCol: { span: 16, offset: 1 },
                            },
                          },
                          [
                            _c("a-range-picker", {
                              on: { change: _vm.onTimeChange },
                              model: {
                                value: _vm.queryParams.inDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryParams, "inDate", $$v)
                                },
                                expression: "queryParams.inDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "a-form-item",
                          {
                            attrs: {
                              label: "退场日期",
                              labelCol: { span: 5 },
                              wrapperCol: { span: 16, offset: 1 },
                            },
                          },
                          [
                            _c("a-range-picker", {
                              on: { change: _vm.onQuitTimeChange },
                              model: {
                                value: _vm.queryParams.quitDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryParams, "quitDate", $$v)
                                },
                                expression: "queryParams.quitDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.user.loginType == 1
                      ? _c(
                          "a-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "a-form-item",
                              {
                                attrs: {
                                  labelCol: { span: 5 },
                                  wrapperCol: { span: 16, offset: 1 },
                                  label: "项目名称",
                                },
                              },
                              [
                                _c(
                                  "a-auto-complete",
                                  {
                                    attrs: {
                                      "data-source": _vm.inputDataSource,
                                      placeholder: "请输入项目名称",
                                      allowClear: "",
                                    },
                                    on: {
                                      search: function ($event) {
                                        return _vm.handleInputSearch($event)
                                      },
                                      select: function ($event) {
                                        return _vm.handlInputSelect($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.queryParams.projectId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryParams,
                                          "projectId",
                                          $$v
                                        )
                                      },
                                      expression: "queryParams.projectId",
                                    },
                                  },
                                  [
                                    _c(
                                      "template",
                                      { slot: "dataSource" },
                                      _vm._l(
                                        _vm.inputDataSource,
                                        function (items) {
                                          return _c(
                                            "a-select-option",
                                            { key: items.id + "" },
                                            [_vm._v(_vm._s(items.title))]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "a-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "a-form-item",
                          {
                            attrs: {
                              label: "班组名称",
                              labelCol: { span: 5 },
                              wrapperCol: { span: 16, offset: 1 },
                            },
                          },
                          [
                            _c(
                              "a-select",
                              {
                                attrs: {
                                  "show-search": "",
                                  placeholder: "请输入或选择班组",
                                  "option-filter-prop": "children",
                                  "filter-option": _vm.filterOption,
                                },
                                on: { change: _vm.handleChange },
                                model: {
                                  value: _vm.queryParams.teamId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryParams, "teamId", $$v)
                                  },
                                  expression: "queryParams.teamId",
                                },
                              },
                              _vm._l(_vm.teamDatas, function (item) {
                                return _c(
                                  "a-select-option",
                                  {
                                    key: item.id,
                                    attrs: {
                                      value: item.id,
                                      title: item.teamName,
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(item.teamName) + " ")]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a-space",
                      { staticStyle: { "margin-top": "4px" } },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: { icon: "search", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.doQueryReset()
                              },
                            },
                          },
                          [_vm._v("查询")]
                        ),
                        _c(
                          "a-space",
                          { staticClass: "operator" },
                          [
                            _c(
                              "a-button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.doReset()
                                  },
                                },
                              },
                              [_vm._v("重置")]
                            ),
                            _c(
                              "a-button",
                              {
                                attrs: { icon: "download" },
                                on: {
                                  click: function ($event) {
                                    return _vm.exportBatchStaff()
                                  },
                                },
                              },
                              [_vm._v("导出花名册")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("span"),
          _c("standard-table", {
            attrs: {
              columns: _vm.columns,
              dataSource: _vm.dataSource,
              selectedRows: _vm.selectedRows,
              rowKey: "id",
              pagination: _vm.pagination,
              loading: _vm.tableLoading,
              bordered: true,
            },
            on: {
              "update:selectedRows": function ($event) {
                _vm.selectedRows = $event
              },
              "update:selected-rows": function ($event) {
                _vm.selectedRows = $event
              },
              clear: _vm.onClear,
              change: _vm.onPageChange,
            },
            scopedSlots: _vm._u([
              {
                key: "quitDate",
                fn: function (ref) {
                  var text = ref.text
                  return [_c("span", [_vm._v(_vm._s(text ? text : "在场"))])]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("Export-loading", {
        attrs: {
          cPercent: _vm.cPercent,
          isExporting: _vm.isExporting,
          loadingText: _vm.loadingText,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }