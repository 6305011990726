var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "constructiontTable_index" },
      [
        _c(
          "a-tabs",
          {
            attrs: {
              "default-active-key": _vm.defaultActiveKey,
              forceRender: "",
            },
            on: { change: _vm.callback },
          },
          [
            _c("a-tab-pane", { key: "1", attrs: { tab: "在场人员班组" } }),
            _c("a-tab-pane", { key: "2", attrs: { tab: "无在场人员班组" } }),
          ],
          1
        ),
        _c("form-index", {
          ref: "formindex",
          attrs: {
            projectList: _vm.projectList,
            formData: _vm.formData,
            flexAction: true,
            topSubmit: true,
            baiduMapBoolen: false,
          },
          on: {
            handleSubmit: _vm.handleSubmit,
            handleChange: _vm.handleChange,
            handlSelect: _vm.handlSelect,
          },
          scopedSlots: _vm._u([
            {
              key: "flexAction",
              fn: function () {
                return [
                  _c(
                    "a-space",
                    { style: { marginTop: "4px" } },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            icon: "search",
                            type: "primary",
                            "html-type": "submit",
                          },
                        },
                        [_vm._v(" 查询 ")]
                      ),
                      _c(
                        "a-space",
                        { staticClass: "operator" },
                        [
                          _c("a-button", { on: { click: _vm.close } }, [
                            _vm._v(" 重置 "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: { icon: "plus", type: "primary" },
                          on: { click: _vm.createItem },
                        },
                        [_vm._v(" 新增 ")]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        }),
        _c("standard-table", {
          attrs: {
            columns: _vm.columns,
            dataSource: _vm.dataSource,
            rowKey: "id",
            pagination: _vm.pagination,
            loading: _vm.tableLoading,
            isAlert: false,
            bordered: true,
            tableSize: "middle",
          },
          on: { change: _vm.onPageChange },
          scopedSlots: _vm._u([
            {
              key: "index",
              fn: function (ref) {
                var index = ref.index
                return [
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          (_vm.pagination.current - 1) *
                            _vm.pagination.pageSize +
                            parseInt(index) +
                            1
                        ) +
                        " "
                    ),
                  ]),
                ]
              },
            },
            {
              key: "projectName",
              fn: function (ref) {
                var record = ref.record
                return [
                  _c("div", { style: _vm.textStyle(record) }, [
                    _vm._v(" " + _vm._s(record.projectName) + " "),
                  ]),
                ]
              },
            },
            {
              key: "teamName",
              fn: function (ref) {
                var record = ref.record
                var text = ref.text
                return [
                  record.teamType == 1
                    ? _c("span", [_c("a-tag", [_vm._v("甲指乙管")])], 1)
                    : _vm._e(),
                  _vm._v(" " + _vm._s(text) + " "),
                ]
              },
            },
            {
              key: "teamQr",
              fn: function (ref) {
                var text = ref.text
                var record = ref.record
                return _c("div", {}, [
                  _c(
                    "div",
                    {
                      staticClass: "flex a-center j-center",
                      staticStyle: { height: "40px" },
                    },
                    [
                      text != "" && text != null
                        ? _c("div", [
                            _c("img", {
                              staticStyle: {
                                width: "40px",
                                height: "40px",
                                cursor: "pointer",
                              },
                              attrs: { src: text },
                              on: {
                                click: function ($event) {
                                  return _vm.showImg(text, record)
                                },
                              },
                            }),
                          ])
                        : _vm._e(),
                      _c("a-spin", { attrs: { spinning: !text } }),
                    ],
                    1
                  ),
                ])
              },
            },
            {
              key: "action",
              fn: function (ref) {
                var record = ref.record
                var text = ref.text
                return _c("div", {}, [
                  _c(
                    "div",
                    [
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.viewEditing(record, true)
                            },
                          },
                        },
                        [_vm._v("查看")]
                      ),
                      _c("a-divider", { attrs: { type: "vertical" } }),
                      _c(
                        "a",
                        {
                          staticClass: "content_div",
                          staticStyle: { color: "#ff5c77" },
                          on: {
                            click: function ($event) {
                              return _vm.viewEditing(record)
                            },
                          },
                        },
                        [_vm._v("修改")]
                      ),
                      _c(
                        "a-popover",
                        {
                          attrs: { placement: "bottomRight", trigger: "click" },
                        },
                        [
                          _c("template", { slot: "content" }, [
                            _vm.defaultActiveKey == 2
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "content_div",
                                    staticStyle: { color: "#ff5c77" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.recorDelete(record, text)
                                      },
                                    },
                                  },
                                  [_vm._v(" 删除 ")]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass: "content_div",
                                on: {
                                  click: function ($event) {
                                    return _vm.recordView(record, 5)
                                  },
                                },
                              },
                              [_vm._v(" 设置门禁通道 ")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "content_div",
                                on: {
                                  click: function ($event) {
                                    return _vm.recordView(record, "evaluate")
                                  },
                                },
                              },
                              [_vm._v(" 评价 ")]
                            ),
                          ]),
                          _c("template", { slot: "title" }, [
                            _c("span", [_vm._v("操作")]),
                          ]),
                          _c(
                            "a-button",
                            {
                              staticStyle: { color: "#615e83" },
                              attrs: { type: "link" },
                            },
                            [_vm._v("更多>")]
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ])
              },
            },
          ]),
        }),
        _c("Export-loading", {
          attrs: {
            cPercent: _vm.cPercent,
            isExporting: _vm.isExporting,
            loadingText: _vm.loadingText,
          },
        }),
        _c(
          "a-modal",
          {
            attrs: {
              destroyOnClose: true,
              visible: _vm.modalVisible,
              "ok-text": "下载",
              "cancel-text": "关闭",
            },
            on: { cancel: _vm.handleCancel, ok: _vm.downQrCode },
          },
          [
            _c("img", {
              staticStyle: { width: "100%" },
              attrs: { alt: "example", src: _vm.previewImage },
            }),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }