<template>
  <div class="create_index">
    <!-- 表单封装 
            projectObj：下拉弹出框第一项对象
            Overview：项目名称
            baiduMapBoolen：控制地图显示
            topSubmit：控制上部分按钮显示
            bottomSubmit：控制下部分按钮显示
            handleSubmit：表单提交
            handleChange：输入出发
            handlSelect：输入下拉选择触发
            defaultFileList：上传图片回显链接
            query：路由传过来的数据
        -->
    <form-index
      ref="formindex"
      :chageT="true"
      :formData="formData"
      Overview="项目概况"
      @handleChange="handleChange"
      :baiduMapBoolen="true"
      :topSubmit="true"
      :projectList="projectList"
      @handleSubmit="handleSubmit"
      @handlSelect="handlSelect"
      :defaultFileList="defaultFileList"
      @handleSearch="handleSearch"
    >
      <!-- 上部分按钮插槽 -->
      <template #topSubmit>
        <a-button type="primary" html-type="submit"> 提交 </a-button>
        <a-button @click="close"> 关闭 </a-button>
      </template>
    </form-index>
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import {
  projectMsgGetList,
  getTreeOffice,
  addLwProjects,
} from "@/services/projectManagement";
import { request } from "@/utils/request";
import { PROJECT_OFFICE_CHILD } from "@/services/api";
import { sysUserGetList } from "@/services/message";
const formData = [
  {
    label: "项目名称",
    placeholder: "请输入项目名称",
    decorator: [
      "fullName",
      { rules: [{ required: true, message: "请输入项目名称" }] },
    ],
    type: "inputGroup",
    key: "fullName",
    dataSource: [],
    labelCol: 3,
    wrapperCol: 19,
    col: 12,
    display: true,
  },
  {
    label: "项目代码",
    placeholder: "请输入项目代码",
    decorator: [
      "projectNum",
      { rules: [{ required: true, message: "请输入项目代码" }] },
    ],
    type: "input",
    key: "projectNum",
    col: 12,
    labelCol: 3,
    wrapperCol: 19,
    display: true,
  },
  {
    label: "所属公司",
    placeholder: "请选择所属公司",
    decorator: [
      "officeId",
      { rules: [{ required: false, message: "请选择所属公司" }] },
    ],
    type: "treeSelect",
    key: "officeId",
    selectlist: [],
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "工程管理部",
    placeholder: "请选择工程管理部",
    decorator: [
      "gcglbId",
      { rules: [{ required: false, message: "请选择工程管理部" }] },
    ],
    type: "select",
    key: "gcglbId",
    selectlist: [],
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "工程状态",
    placeholder: "请选择工程状态",
    decorator: [
      "status",
      { initialValue: 0,rules: [{ required: true, message: "请选择工程状态" }] },
    ],
    type: "select",
    key: "status",
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
    selectlist: [
      {
        value: 3,
        name: "未开工",
      },
      {
        value: 0,
        name: "在建",
      },
      {
        value: 1,
        name: "完工",
      },
      {
        value: 2,
        name: "停缓建",
      },
    ],
  },
  {
    label: "工程类别",
    placeholder: "请选择工程类别",
    decorator: [
      "ptype",
      { rules: [{ required: true, message: "请选择工程类别" }] },
    ],
    type: "select",
    key: "ptype",
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
    selectlist: [
    {
        value: 1,
        name: "房建"
      },
      {
        value: 2,
        name: "市政"
      },
      {
        value: 3,
        name: "装修工程"
      },
      {
        value: 91,
        name: "土建"
      },
      {
        value: 92,
        name: "房屋建筑工程"
      },
      {
        value: 93,
        name: "冶炼工程"
      },
      {
        value: 4,
        name: "矿山工程"
      },
      {
        value: 5,
        name: "化工石油工程"
      },
      {
        value: 6,
        name: "水利水电工程"
      },
      {
        value: 7,
        name: "电力工程"
      },
      {
        value: 8,
        name: "农林工程"
      },
      {
        value: 9,
        name: "铁路工程"
      },
      {
        value: 10,
        name: "公路工程"
      },
      {
        value: 11,
        name: "港口与航道工程"
      },
      {
        value: 12,
        name: "航天航空工程"
      },
      {
        value: 13,
        name: "通信工程"
      },
    ],
  },

  {
    label: "项目经理",
    placeholder: "请输入项目经理",
    decorator: [
      "userName",
      { rules: [{ required: true, message: "请输入项目经理" }] },
    ],
    type: "inputGroup",
    key: "userName",
    dataSource: [],
    col: 12,
    labelCol: 3,
    wrapperCol: 19,
    display: true,
  },
  {
    label: "项目经理电话",
    placeholder: "请输入项目经理电话",
    decorator: [
      "userPhone",
      { rules: [{ required: true, message: "请输入项目经理电话" }] },
    ],
    type: "input",
    key: "userPhone",
    col: 12,
    labelCol: 3,
    wrapperCol: 19,
    display: true,
  },
  {
    label: "工程概况",
    placeholder: "请输入工程概况",
    decorator: [
      "projDesc",
      { rules: [{ required: false, message: "请输入工程概况" }] },
    ],
    type: "textarea",
    key: "projDesc",
    labelCol: 3,
    wrapperCol: 19,
    col: 12,
    display: true,
  },
  {
    label: "工程造价(万元)",
    placeholder: "请输入工程造价",
    decorator: [
      "projBudget",
      { rules: [{ required: false, message: "请输入工程造价" }] },
    ],
    type: "input",
    key: "projBudget",
    col: 12,
    labelCol: 3,
    wrapperCol: 19,
    display: true,
  },
  {
    label: "工程地址",
    placeholder: "请輸入工程地址",
    decorator: [
      "projAddress",
      { rules: [{ required: false, message: "请輸入工程地址" }] },
    ],
    type: "input",
    key: "projAddress",
    col: 12,
    labelCol: 3,
    wrapperCol: 19,
    display: true,
  },
  {
    label: "工程经纬度",
    placeholder: "113.274 , 23.123",
    decorator: [
      "lanAndLat",
      { rules: [{ required: false, message: "请输入经纬度或从地图选择" }] },
    ],
    type: "input",
    key: "lanAndLat",
    disabled: true,
    col: 12,
    labelCol: 3,
    wrapperCol: 19,
    display: true,
  },
];
export default {
  data() {
    return {
      query: this.$route.query,
      formData: formData,
      // form: this.$form.createForm(this, { name: 'coordinated' }),
      projectArr: [], //项目名称
      projectList: [], //获取当前项目数据
      defaultFileList: [], //图片数组
      isReport: null, // 记录是否报建变量
      status: null,
      parentOfficeId: "",
      userId: undefined,
      projType: undefined,
    };
  },
  components: {
    formIndex,
  },
  inject: ["fatherMethod"],
  methods: {
    // 下拉特殊数据处理
    handleSearch(e) {
      // if (e.key == "buildOfficeName" || e.key == "effcOfficeName") {
      //   this.getZdata(getofficelist, e, "name", e.key);
      // }
    },
    // 关闭当前页面
    close() {
      let path = this.$route.path;
      this.fatherMethod(`${path}`);
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      this.formData.forEach((item) => {
        if (item.key == key) {
          item.selectlist = data;
        }
      });
    },
    // 获取工程管理部数据
    getgcglbList(path) {
      request(path, "get").then((res) => {
        if (res.data.code === 0) {
          const data = res.data.data.filter(
            (item) => item.id != "3da0f5533f87425c9a9e8a11a7923300"
          );
          const arr = [];
          data.forEach((item) => {
            arr.push({
              name: item.itemValue,
              value: item.itemText,
            });
          });
          this.formDataFn("gcglbId", arr);
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    update(e, type) {
      addLwProjects(e).then((resdata) => {
        if (resdata.data.code === 0) {
          type == "handleSubmit"
            ? this.$message.success("更新成功", 10)
            : this.$message.success("成功", 10);
          this.$router.push({
            name: "项目工程一览表",
            query: {
              pageNumber: this.query.pageNumber,
            },
          });
        }
      });
    },
    // 提交表单数据
    handleSubmit(e) {
      delete e.lanAndLat;
      e.id = this.query.data.id;
      e.projectId = this.query.data.projectId;
      e.userId = this.userId ? this.userId : this.query.data.userId;
      e.projType = this.projType ? this.projType : this.query.data.statusw;
      console.log(e, "提交的表单数据");
      this.update(e, "handleSubmit");
      this.close();
    },
    // 下拉接口的处理
    getZdata(msg, e, name, key) {
      let data = {
        [name]: e.value,
        parentOfficeId: name == "name" ? this.parentOfficeId : undefined,
      };
      msg(data).then((res) => {
        if (res.status == 200) {
          const data = res.data.data.list ? res.data.data.list : res.data.data
          const arr = [];
          if (name == 'nickName') {
            name = 'nickname'
          }
          data.forEach((item) => {
            this.projectArr.push({
              title: item[name],
              id: item.id,
            });
            arr.push({
              name: item[name],
              value: item.id,
            });
          });
          this.projectList = data;
          if (key) {
            this.formDataFn(key, arr);
          }
        }
      });
    },
    // 输入选择框触发
    handleChange(e) {
      this.projectArr = [];
      const name = {
        fullName: () => {
          this.getZdata(projectMsgGetList, e, "projectName");
        },
        userName: () => {
          this.getZdata(sysUserGetList, e, "nickName");
        },
      }[e.item.key](); //匹配对应调用对应接口
      const arr = ["projectId", "userId"];
      arr.forEach((item) => {
        this.$refs["formindex"].setFieldsValue("", {}, item);
      });
      // // 获取当前匹配key，赋值相对于数组
      this.formData.forEach((item) => {
        if (e.item.key === item.key) {
          item.dataSource = this.projectArr;
        }
      });
    },
    // 特殊数据处理
    handlSelect({ val, keys }) {
      console.log(val, keys, "val,keys");
      if (keys == "officeId") {
        this.parentOfficeId = val;
      }
      if (keys == "ptype") {
        this.ptype = val;
      }
      console.log(this.ptype, "this.ptype");
      this.projectList.forEach((item) => {
        if (keys == "userName" && item.id == val) {
          console.log(item, "item");
          this.$refs["formindex"].setFieldsValue(val, {
            userName: item.nickname,
          });
          this.$refs["formindex"].setFieldsValue(val, {
            userPhone: item.mobile,
          });
          this.$refs["formindex"].setFieldsValue(
            val,
            { userId: item.id },
            "userId"
          );
          this.userId = item.id;
        } else if (keys == "fullName" && item.id == val) {
          this.$refs["formindex"].setFieldsValue(val, {}, "projectId");
          this.$refs["formindex"].setFieldsValue("", {
            lanAndLat: `${item.latitude},${item.longitude}`,
          });
          this.$refs["formindex"].setFieldsValue("", {
            projectNum: item.projectNum || item?.projectMsg?.projectNum,
          });
          this.$refs["formindex"].setFieldsValue("", {
            userName: item.userName || item?.projectMsg?.userName,
          });
          this.$refs["formindex"].setFieldsValue(
            item.userId,
            {
              userId: item.userId || item?.projectMsg?.userId,
            },
            "userId"
          );
        }
      });
    },
    initTree() {
      getTreeOffice({}).then((res) => {
        this.formDataFn("officeId", res.data.data);
      });
    },
  },
  created() {
    console.log(this.query.data, "this.query.data");
    // this.isReport = this.query.data.isReport;
    // this.statsu = this.query.data.status;
    // this.formData.forEach((item) => {
    //   if (item.type == "inputGroup") {
    //     item.dataSource = [];
    //   }
    //   // 报建=项目代码 不保健=工程编码
    //   if (item.key == "projectNum") {
    //     if (this.query.data.isReport == 1) {
    //       item.label = "项目代码";
    //     } else {
    //       item.label = "工程编码";
    //     }
    //   }
    // });

    this.initTree();
    this.getgcglbList(PROJECT_OFFICE_CHILD + "/OFFICE_CHILD");
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.create_index {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-bottom: 10px;
}
</style>
