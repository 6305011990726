<template>
    <div class="a_Approval">
        <a-form-model :model="params" class="clearfix" @submit="handleSubmit">
            <a-col :span="5">
                <a-form-model-item label="选择日期" :labelCol="{ span: 6 }" :wrapperCol="{ span: 14, offset: 0 }">

                    <a-date-picker
                        mode="year"
                        v-model="params.startDate"
                        placeholder="请输入年份"
                        :format="'YYYY'"
                        style="width: 140px"
                        :open="isOpen"
                        @openChange="monthOpenChangeOne"
                        @panelChange="monthPanelChangeOne"/>
                </a-form-model-item>
            </a-col>
            <a-col :span="5">
                <a-form-model-item label="上级文件名称" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 0 }">

                    <a-input v-model="params.higherfilename" allow-clear/>
                </a-form-model-item>
            </a-col>
            <a-col :span="5">
                <a-form-model-item label="分类" :labelCol="{ span: 4 }" :wrapperCol="{ span: 14, offset: 0 }">

                    <a-select
                        ref="select"
                        v-model="params.categorizationid"
                        style="width: 220px"
                        :options="options"
                        allowClear
                        >
                        </a-select>
                </a-form-model-item>
            </a-col>


            <a-col :span="4">
                <a-space class="btnBox">
                    <a-button type="primary" html-type="submit"> 查询 </a-button>
                    <a-button @click="resetQuery"> 重置 </a-button>
                </a-space>
            </a-col>
        </a-form-model>
        <standard-table :columns="columns" :dataSource="tableData" rowKey="gdfid" :pagination="pagination"
            :loading="tableLoading" @change="handleChangePage" :isAlert="false" :bordered="true" :btnArr="tableBtnArr"
            :selectedRows.sync="selectedRows"
            >
            <template slot="index" slot-scope="{ index }">
                <span>{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}</span>
            </template>
            <template slot="attach" slot-scope="{ record }">
                <!-- {{ record }} -->
                <div class="flex j-center">
                    <div v-for="(item, index) in record.attachList" :key="index">

                        <img alt="" style="width: 32px" v-if="item.type == 'img'" :src="item.url"
                            @click="handlePreview(item)" />

                        <a-icon type="file-pdf" :style="{ fontSize: '32px' }" v-else-if="item.type == 'pdf'"
                            @click="handlePreview(item)" />
                        <a-icon type="file-text" :style="{ fontSize: '32px' }" v-else
                            @click="handlePreview(item)" />
                    </div>
                </div>
            </template>
            <template slot="action" slot-scope="{ record }">
                <div class="sTables">
                    <div @click="handleEdit(record)">编辑</div>
                    <div @click="handleDel(record)" v-show="!$route.meta.isBreak">删除</div>
                </div>
            </template>
        </standard-table>
    </div>
</template>

<script>
import {
    safeStandardList, safeStandardkDel
} from "@/services/safeExamine";
import {
    govdeptfileList, govdeptfileDelete, govdeptfileBatchDownload
} from "@/services/specificatioNotification";
import { mapGetters } from "vuex";
import StandardTable from "@/components/table/StandardTable";
import moment from "moment";

const options = [
    {
        value: "1",
        label: "安全生产费用管理",
    },
    {
        value: "2",
        label: "危大工程管理",
    },
    {
        value: "3",
        label: "企业负责人、项目负责人安全生产管理",
    },
    {
        value: "4",
        label: "特种作业人员管理",
    },
    {
        value: "5",
        label: "文明施工管理",
    },
    {
        value: "6",
        label: "消防管理",
    },
    {
        value: "7",
        label: "生产安全事故管理",
    },
    {
        value: "8",
        label: "建筑起重机械设备管理",
    },
]
const columns = [
    {
        title: "序号",
        align: "center",
        scopedSlots: { customRender: "index" },
        width: 65,
    },
    {
        title: "文件名称",
        // align: "center",
        dataIndex: "higherfilename",
        // ellipsis: true,
        // tooltip: true,
        // width: 250,
    },

    {
        title: "编号",
        dataIndex: "no",
        width: 240,
        align: "center",
    },
    {
        title: "分类",
        dataIndex: "typeName",
        width: 210,
        align: "center",
    },
    {
        title: "发布部门",
        dataIndex: "pubdeptname",
        width: 180,
        align: "center",
    },
    {
        title: "发布日期",
        dataIndex: "pubdate",
        width: 150,
        align: "center",
    },
    {
        title: "附件",
        dataIndex: "attach",
        scopedSlots: { customRender: "attach" },
        align: "center",
        width: 100,
    },
    {
        title: "操作",
        align: "center",
        width: 120,
        scopedSlots: { customRender: "action" },
    },
];

export default {
    components: {
        StandardTable
    },
    data() {
        return {
            params: {
                "higherfilename": "", //上级文件名称
                "startDate": "", //开始日期 yyyy-MM-dd
                "endDate": "", //结束日期yyyy-MM-dd
                "categorizationid": "", 
                "page": 1,
                "limit": 10,
                 time: [],
                },
            pagination: {
                //分页数据
                current: 1,
                pageSize: 10,
                total: 0,
                showTotal: (total, range) => `共 ${total} 条`,
            },
            total: 0,
            columns: columns,
            tableData: [],
            tableLoading: false, //table加载中
            selectedRows: [],
            options: options,

            isOpen: false,
        };
    },
    created() {
        if (!this.isSafe) {
            this.columns = this.columns.filter(el => el.title != '操作')
        }
        this.handleQuery();
    },
    activated() {
        this.handleQuery();
    },
    computed: {
        ...mapGetters("account", ["user"]),
        isBranchOffice() { //分公司
            return this.user.isGcglb == 1 && this.user.loginType == 1
        },
        isProject() { //项目部
            return this.user.loginType == 0
        },
        isSafe() { //安全部
            return this.user.isGcglb == 0 && this.user.loginType == 1
        },
        tableBtnArr() {
            return [
                {
                    name: '新增',
                    attrs: {
                        type: 'primary'
                    },
                    hidden: !this.isSafe,
                    click: this.addNewComplaint
                },
                {
                    name: '批量下载',
                    attrs: {
                        type: 'primary'
                    },
                    // hidden: !this.isJSOffice,
                    click: this.bathDownload
                }
            ]
        }
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault();
            this.params.pageNumber = 1
            this.pagination.current = 1
            this.handleQuery();
        },
        handleQuery(e) {
            this.tableData = []
            this.tableLoading = true
            let startDate = "";
            if (this.params.startDate) {
                
                 startDate = moment(this.params.startDate).format("YYYY");
            }
            let d = {
                higherfilename: this.params.higherfilename,
                page: this.params.page,
                limit: this.params.limit,
                categorizationid: this.params.categorizationid,
                startDate
            }
            govdeptfileList(d).then((res) => {
                this.tableLoading = false
                if (res.data.code === 200) {
                    let list = res.data.data.list;

                    this.tableData = list.map(item => {
                        let attachList = item.attach.split(',');
                        if(item.attach == "") {
                            attachList = []
                        }
                        attachList = attachList.map((i) => {
                            let type = "img";
                            // if i 为图片
                            if (i.indexOf(".jpg") > -1 || i.indexOf(".png") > -1 || i.indexOf(".jpeg") > -1 || i.indexOf(".gif") > -1 ) {
                                type = "img";
                            } else if (i.indexOf(".pdf") > -1) {
                                type = "pdf";
                            } else {
                                type = "file";
                            }


                            return {
                                url: i,
                                type: type,
                            };
                        }
                        );
                        let typeName = ''
                        let i = options.find(item1 => item1.value == item.categorizationid)
                        if(i) {
                            typeName = i.label
                        }
                        return {
                            ...item,
                            key: item.gdfid,
                            attachList: attachList,
                            typeName
                        };


                    })
                    this.pagination.total = res.data.data.totalCount;
                } else {
                    this.$message.error(res.data.msg)
                }
            });
        },
        handleChangePage(e) {
            this.params.page = e.current;
            this.pagination.current = e.current;
            this.handleQuery();
        },
        addNewComplaint() {
            this.$router.push({
                name: `政府文件详情`,
                query: {
                    isEdit: 0
                },
            });
        },
        handleEdit(e) {
            this.$router.push({
                name: `政府文件详情`,
                query: {
                    id: e.gdfid,
                    isEdit: 1
                },
            });
        },
        handleDel(record) {
            const _that = this
            this.$confirm({
                title: '是否确定删除？',
                cancelText: '否',
                okText: '是',
                onOk: () => {
                    govdeptfileDelete({ id: record.gdfid }).then(res => {
                        if (res.data.code === 200) {
                            _that.$message.success('删除成功')
                            this.handleQuery(this.params);
                        } else {
                            _that.$message.error(res.data.msg)
                        }
                    })
                },
            })
        },
        resetQuery() {
            this.pagination.pageNumber = 1
            this.params = {
                "higherfilename": "", 
                "startDate": "", 
                "endDate": "", 
                "categorizationid": "", 
                "page": 1,
                "limit": 10,
                 time: [],
                };
            this.handleQuery();
        },
        bathDownload() {
            if (this.selectedRows.length == 0) {
                this.$message.warning('请选择文件！')
            } else {
                let arr = []
                this.selectedRows.forEach(el => {
                    arr.push(el.gdfid)
                })
                let url = govdeptfileBatchDownload + `?ids=${arr.join(',')}`
                window.open(url)
            }
        },
        handlePreview(file) {
            window.open(file.url);
        },
        monthOpenChangeOne(status) {
            if (status) {
            this.isOpen = true;
            }
        },
        monthPanelChangeOne(value) {
            this.isOpen = false;
            this.params.startDate = value;
            
        }
    },
};
</script>

<style lang="less" scoped>
.query-form {
    display: flex;
    // justify-content: space-between;
}
.sTables {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
        width: 100%;
        text-align: center;
        cursor: pointer;
    }

    div:nth-child(1) {
        color: #307dfa;
    }

    div:nth-child(2) {
        color: #ff5c77;
    }

    div:nth-child(3) {
        color: #615e83;
    }
}

</style>