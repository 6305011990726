var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "a-descriptions",
        { attrs: { bordered: "", column: 3 } },
        [
          _c("a-descriptions-item", { attrs: { label: "班组编号" } }, [
            _vm._v(_vm._s(_vm.hqTeamVo.id)),
          ]),
          _c("a-descriptions-item", { attrs: { label: "姓名" } }, [
            _vm._v(_vm._s(_vm.hqTeamVo.curator)),
          ]),
          _c("a-descriptions-item", { attrs: { label: "联系电话" } }, [
            _vm._v(_vm._s(_vm.hqTeamVo.curatorPhone)),
          ]),
          _c("a-descriptions-item", { attrs: { label: "班组名称" } }, [
            _vm._v(_vm._s(_vm.hqTeamVo.teamName)),
          ]),
          _c("a-descriptions-item", { attrs: { label: "班组类别" } }, [
            _vm._v(
              _vm._s(
                _vm.hqTeamVo.teamType == 1
                  ? "企业班组"
                  : _vm.hqTeamVo.teamType == 2
                  ? "班组合伙人"
                  : "专业班组"
              )
            ),
          ]),
          _c("a-descriptions-item", { attrs: { label: "承接内容" } }, [
            _vm.hqTeamWorkContentVoList.length > 0
              ? _c(
                  "span",
                  { staticClass: "check", on: { click: _vm.workContentCheck } },
                  [_vm._v("查看")]
                )
              : _vm._e(),
          ]),
          _c("a-descriptions-item", { attrs: { label: "规模（人数）" } }, [
            _vm._v(_vm._s(_vm.hqTeamVo.scale)),
          ]),
          _c("a-descriptions-item", { attrs: { label: "审核状态" } }, [
            _vm._v(_vm._s(_vm.status)),
          ]),
          _c("a-descriptions-item", { attrs: { label: "可承接区域" } }, [
            _vm._v(_vm._s(_vm.hqTeamVo.scaleArea)),
          ]),
          _c("a-descriptions-item", { attrs: { label: "持证人数" } }, [
            _vm._v(_vm._s(_vm.hqTeamVo.certPersonNum)),
          ]),
          _c("a-descriptions-item", { attrs: { label: "项目业绩" } }, [
            _vm.hqTeamPerformanceVoList.length > 0
              ? _c(
                  "span",
                  { staticClass: "check", on: { click: _vm.projModalCheck } },
                  [_vm._v("查看")]
                )
              : _c("span", [_vm._v("暂无数据")]),
          ]),
          _vm.hqTeamVo.teamType && _vm.hqTeamVo.teamType == 1
            ? _c("a-descriptions-item", { attrs: { label: "营业执照" } }, [
                _vm.officeAuth.businessLicense
                  ? _c(
                      "span",
                      {
                        staticClass: "check",
                        on: {
                          click: function ($event) {
                            return _vm.modalCheck(
                              "营业执照",
                              "",
                              "",
                              _vm.officeAuth.businessLicense
                            )
                          },
                        },
                      },
                      [_vm._v("查看")]
                    )
                  : _c("span", [_vm._v("暂无数据")]),
              ])
            : _vm._e(),
          _c("a-descriptions-item", { attrs: { label: "个人身份证" } }, [
            _vm.staffVo.facePath || _vm.staffVo.backPath
              ? _c(
                  "span",
                  {
                    staticClass: "check",
                    on: {
                      click: function ($event) {
                        return _vm.modalCheck("个人身份证", "", "", [
                          _vm.staffVo.facePath,
                          _vm.staffVo.backPath,
                        ])
                      },
                    },
                  },
                  [_vm._v("查看")]
                )
              : _c("span", [_vm._v("暂无数据")]),
          ]),
          _vm.hqTeamVo.teamType && _vm.hqTeamVo.teamType == 1
            ? _c("a-descriptions-item", { attrs: { label: "法人身份证" } }, [
                _vm.officeAuth.corporateIdCardFront ||
                _vm.officeAuth.corporateIdCardBack
                  ? _c(
                      "span",
                      {
                        staticClass: "check",
                        on: {
                          click: function ($event) {
                            return _vm.modalCheck("法人身份证", "", "", [
                              _vm.officeAuth.corporateIdCardFront,
                              _vm.officeAuth.corporateIdCardBack,
                            ])
                          },
                        },
                      },
                      [_vm._v("查看")]
                    )
                  : _c("span", [_vm._v("暂无数据")]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "班组成员",
            destroyOnClose: true,
            width: 800,
            footer: null,
          },
          on: { cancel: _vm.staffModalCancel },
          model: {
            value: _vm.staffModalVisible,
            callback: function ($$v) {
              _vm.staffModalVisible = $$v
            },
            expression: "staffModalVisible",
          },
        },
        [
          _c(
            "a-descriptions",
            { attrs: { bordered: "", column: 1 } },
            [
              _c("a-descriptions-item", { attrs: { label: "联系人" } }, [
                _vm._v(" " + _vm._s(_vm.staffObj.name) + " "),
              ]),
              _c("a-descriptions-item", { attrs: { label: "联系方式" } }, [
                _vm._v(" " + _vm._s(_vm.staffObj.phone) + " "),
              ]),
              _c("a-descriptions-item", { attrs: { label: "成员角色" } }, [
                _vm._v(
                  " " + _vm._s(_vm.staffObj.role == 1 ? "组长" : "组员") + " "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "承接内容",
            destroyOnClose: true,
            width: 600,
            footer: null,
          },
          on: { cancel: _vm.projModalCancel },
          model: {
            value: _vm.workContentVisible,
            callback: function ($$v) {
              _vm.workContentVisible = $$v
            },
            expression: "workContentVisible",
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "a-collapse",
                {
                  model: {
                    value: _vm.activeKey,
                    callback: function ($$v) {
                      _vm.activeKey = $$v
                    },
                    expression: "activeKey",
                  },
                },
                _vm._l(_vm.hqTeamWorkContentVoList, function (item) {
                  return _c(
                    "a-collapse-panel",
                    {
                      key: item.id + "",
                      attrs: { header: item.workTypeSelected },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "a-descriptions",
                            { attrs: { bordered: "", column: 1 } },
                            [
                              _c(
                                "a-descriptions-item",
                                { attrs: { label: "工作内容" } },
                                [
                                  _vm._v(
                                    " " + _vm._s(item.workTypeSelected) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "a-descriptions-item",
                                { attrs: { label: "结算方式" } },
                                [
                                  _vm._v(
                                    " " + _vm._s(item.settlementName) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "a-descriptions-item",
                                { attrs: { label: "单价" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(item.minPrice) +
                                      "元/" +
                                      _vm._s(item.settleUnit) +
                                      " - " +
                                      _vm._s(item.maxPrice) +
                                      "元/" +
                                      _vm._s(item.settleUnit) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "项目业绩",
            destroyOnClose: true,
            width: 800,
            footer: null,
          },
          on: { cancel: _vm.projModalCancel },
          model: {
            value: _vm.projModalVisible,
            callback: function ($$v) {
              _vm.projModalVisible = $$v
            },
            expression: "projModalVisible",
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "a-steps",
                {
                  attrs: {
                    "progress-dot": "",
                    current: _vm.hqTeamPerformanceVoList.length,
                    direction: "vertical",
                  },
                },
                _vm._l(_vm.hqTeamPerformanceVoList, function (item) {
                  return _c("a-step", { key: item.id }, [
                    _c(
                      "div",
                      {
                        staticClass: "step-time",
                        attrs: { slot: "title" },
                        slot: "title",
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.moment(item.startDate).format("YYYY-MM-DD")
                          ) +
                            " - " +
                            _vm._s(
                              _vm.moment(item.endDate).format("YYYY-MM-DD")
                            )
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { attrs: { slot: "description" }, slot: "description" },
                      [
                        _c(
                          "div",
                          { staticClass: "step-team-name flex a-center" },
                          [
                            _c(
                              "a-tag",
                              {
                                attrs: {
                                  color:
                                    item.status == 0 ? "#108ee9" : "#CCCCCC",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(item.status == 0 ? "在建" : "完工")
                                ),
                              ]
                            ),
                            _c("span", [
                              _vm._v("项目名称：" + _vm._s(item.projectName)),
                            ]),
                          ],
                          1
                        ),
                        _c("div", [
                          _vm._v("班组人数：" + _vm._s(item.workerNum)),
                        ]),
                        _c("div", [
                          _vm._v("工作内容：" + _vm._s(item.workTypeSelected)),
                        ]),
                        _c(
                          "div",
                          _vm._l(item.photoList, function (url, index) {
                            return _c("img", {
                              key: index,
                              staticClass: "mr-2 pointer",
                              staticStyle: { width: "100px" },
                              attrs: { src: url, alt: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.handlePreview(url)
                                },
                              },
                            })
                          }),
                          0
                        ),
                      ]
                    ),
                  ])
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.modalTitle,
            destroyOnClose: true,
            width: 800,
            footer: null,
          },
          on: { cancel: _vm.modalCancel },
          model: {
            value: _vm.modalVisible,
            callback: function ($$v) {
              _vm.modalVisible = $$v
            },
            expression: "modalVisible",
          },
        },
        [
          _c(
            "a-descriptions",
            { attrs: { bordered: "", column: 1 } },
            [
              _vm.modalSubTitle
                ? _c(
                    "a-descriptions-item",
                    { attrs: { label: _vm.modalSubTitle } },
                    [_vm._v(" " + _vm._s(_vm.modalContent) + " ")]
                  )
                : _vm._e(),
              _c("a-descriptions-item", { attrs: { label: "附件" } }, [
                _vm.attachmentUrl_2
                  ? _c("img", {
                      staticClass: "attachment-img",
                      attrs: { src: _vm.attachmentUrl_2, alt: "" },
                    })
                  : _vm._e(),
                _vm.attachmentUrl
                  ? _c("span", [
                      _vm.attachmentUrl.indexOf("contractPath") == -1 &&
                      _vm.attachmentUrl.indexOf("CONTRACT") == -1 &&
                      _vm.attachmentUrl.indexOf("THREE_AQJY_TEPL_ALL") == -1
                        ? _c("img", {
                            staticClass: "attachment-img",
                            attrs: { src: _vm.attachmentUrl, alt: "" },
                          })
                        : _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.attachmentUrl,
                                target: "_blank",
                              },
                            },
                            [_vm._v("查看附件")]
                          ),
                    ])
                  : _c("span", [_vm._v("暂未上传")]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "班组简介", "ok-text": "确认", footer: null },
          on: {
            ok: function () {
              return (_vm.teamIntroVisible = false)
            },
          },
          model: {
            value: _vm.teamIntroVisible,
            callback: function ($$v) {
              _vm.teamIntroVisible = $$v
            },
            expression: "teamIntroVisible",
          },
        },
        [_c("p", [_vm._v(_vm._s(_vm.hqTeamVo.teamIntro))])]
      ),
      _c(
        "a-modal",
        {
          attrs: { visible: _vm.previewVisible, footer: null, width: 1000 },
          on: { cancel: _vm.closeImgShow },
        },
        [
          _c("img", {
            attrs: { alt: "example", width: "950", src: _vm.imgPath },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }