<template>
  <a-card>
    <a-spin :spinning="spinning" :tip="loadingText">
      <div :class="advanced ? 'search' : null">
        <a-form layout="horizontal">
          <div :class="advanced ? null : 'fold'">
            <a-row>
              <a-col :md="6" :sm="24">
                <a-form-item
                  label="模板名称"
                  :labelCol="{ span: 5 }"
                  :wrapperCol="{ span: 18, offset: 1 }"
                >
                  <a-input
                    placeholder="请输入"
                    v-model="queryParams.title"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item
                  label="模板编号"
                  :labelCol="{ span: 5 }"
                  :wrapperCol="{ span: 18, offset: 1 }"
                >
                  <a-input
                    placeholder="请输入"
                    v-model="queryParams.fileType"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <div style="margin-top: 4px;margin-left: 10px;">
                  <a-button icon="search" type="primary" @click="doQuery()">查询</a-button>
                  <a-space class="operator">
                    <a-button style="margin-left: 8px" @click="doReset()">重置</a-button>
                  </a-space>
                  <a-button icon="plus" style="margin-left: 8px" type="primary" @click="doAddTempl()">新增模板</a-button>
                </div>
              </a-col>
            </a-row>
          </div>
        </a-form>
      </div>
      <div>
        <standard-table
          :columns="columns"
          :dataSource="dataSource"
          :selectedRows.sync="selectedRows"
          :pagination="pagination"
          :loading="tableLoading"
          @clear="onClear"
          @change="onPageChange"
          :bordered="true"
          :isAlert="false"
        >
          <div slot="do-status" slot-scope="{ text }">
            <!-- <a-icon v-if="text == 1" style="color: green" type="check" /> -->
            <!-- <a-icon v-else-if="text == 0" type="check" /> -->
            <!-- <a-icon v-else style="color: red" type="close" /> -->
            <span v-if="text == 1"><i class="iconfont duigouxiao" style="color:#00CE86"></i></span>
            <span v-else><i class="iconfont path" style="color:#FF5C77"></i></span>
          </div>
          <div slot="action" slot-scope="{record }">
            <!-- <a href="`${record.key}`" @click="doDownFile(`${record.key}`)">文件下载</a> -->
            <!-- <a-divider type="vertical" /> -->
            <a href="#" @click="doTemplSet(`${record.key}`)">基础设置</a>
            <!-- <a-divider v-if="`${record.status==1}`" type="vertical" />
            <a href="#" v-if="`${record.status==1}`" @click="doStatus(`${record.id}`,0)">置为无效</a>
            <a href="#" v-else @click="doStatus(`${record.id}`,1)">置为有效</a> -->
          </div>
        </standard-table>
      </div>
    </a-spin>
    <temp-setting
      :visible="tempFile.visible"
      :templFile="tempFile.file"
      @close="doCloseSet"
      @submit="doSubmit($event)"
    ></temp-setting>
  </a-card>
</template>

<script>
import StandardTable from "@/components/table/StandardTable";
import tempSetting from "@/pages/filetempl/setting";
import { FILE_TEMPL_FIND_PAGE,FILE_TEMPL_DETAIL,FILE_TEMPL_INSERT,FILE_TEMPL_UPDATE,FIND_ALL_OFFICE_LIST } from "@/services/api";
import { request } from "@/utils/request";
import { te } from "date-fns/locale";
import { useRender } from '@/hooks/useRender.js'
// import axios from '@/utils/axios'
const columns = [
  {
    title: "模板名称",
    dataIndex: "title",
    align: "center",
    scopedSlots: { customRender: "title" },
  },
  {
    title: "模板编号",
    dataIndex: "fileType",
    align: "center",
    scopedSlots: { customRender: "fileType" },
    // customRender: (text) => {
    //   return text.substring(0,6)+"************"+text.substring(text.length-4,text.length)
    // }
  },
  {
    title: "有效状态",
    dataIndex: "status",
    align: "center",
    scopedSlots: { customRender: "do-status" },
  },
  {
    title: "创建时间",
    dataIndex: "createdTime",
    align: "center",
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "描述说明",
    dataIndex: "remark",
    align: "center",
    scopedSlots: { customRender: "remark" },
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  name: "QueryList",
  components: { StandardTable, tempSetting },
  data() {
    return {
      dataSource: [],
      advanced: false,
      columns: columns,
      selectedRows: [],
      tableLoading: false,
      spinning: false,
      loadingText: "数据加载中...",
      loadKeys: [],
      queryParams: {
        title: null,
        fileType: null,
        pageNumber: 1,
      },
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showLessItems: true,
        showQuickJumper: true,
      },
      tempFile: {
        visible: false,
        file: {},
      },
    };
  },
  created() {
    this.doQuery();
  },
  authorize: {
    deleteRecord: "delete",
  },
  methods: {
    deleteRecord(key) {
      this.dataSource = this.dataSource.filter((item) => item.key !== key);
      this.selectedRows = this.selectedRows.filter((item) => item.key !== key);
    },
    toggleAdvanced() {
      this.advanced = !this.advanced;
    },
    remove() {
      this.dataSource = this.dataSource.filter(
        (item) =>
          this.selectedRows.findIndex((row) => row.key === item.key) === -1
      );
      this.selectedRows = [];
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },

    /**
     * 获取所有人员的签名记录信息
     */
    doQuery() {
      this.tableLoading = true;
      request(FILE_TEMPL_FIND_PAGE, "post", this.queryParams).then((res) => {
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
          this.dataSource.forEach((obj) => {
            obj.key = obj.id;
          });
        }
        this.tableLoading = false;
      });
    },

    // findOffice(){
    //   request(FIND_ALL_OFFICE_LIST,"get",null).then(result => {
    //       if (result.data.code==0) {
    //         this.officeDataSource = result.data.data;
    //         this.officeDataSource.forEach((obj) => {
    //           obj.key = obj.id;
    //         });
    //       }else{
    //         this.$message.warning(result.message);
    //       }
    //   })
    // },
    doReset() {
      this.queryParams = {};
      this.doQuery();
    },
    //分页查询
    onPageChange(e) {
      this.queryParams.pageNumber = e.current;
      this.pagination.current = e.current;
      this.doQuery();
    },
    handleMenuClick(e) {
      if (e.key === "delete") {
        this.remove();
      }
    },
    doCloseSet() {
      this.tempFile.visible = false;
    },
    doTemplSet($id){
      //查询该直播的最新数据
      this.doDetail($id);
    },
    doAddTempl(){
      this.tempFile.file = {};
      this.tempFile.file.statusBoolean = true;
      this.tempFile.visible = true;
    },
    doDetail(id){
      request(FILE_TEMPL_DETAIL+id, "get", {}).then((res) => {
        if (res.data.code === 0) {
          this.tempFile.file = res.data.data;
          this.tempFile.visible = true;
        }else{
          this.$message.error("暂未查询到模板信息！")
        }
      });
    },
    doSubmit($event){
      $event.templFile.status = $event.templFile.statusBoolean?1:0;
      $event.templFile.childIds = $event.templFile.childIds?$event.templFile.childIds.join(","):null
      var url = $event.templFile.id?FILE_TEMPL_UPDATE:FILE_TEMPL_INSERT
      console.log("templFile",$event.templFile);
      request(url, "post", $event.templFile).then((res) => {
        if (res.data.code === 0) {
          this.tempFile.visible = false;
          this.$message.success("保存成功！");
          this.doQuery();
        }else{
          this.$message.error("保存异常！");
        }
      });
    },
    doStatus($id,status){
      let updateObj = {
        id:$id,
        status:status
      }
      request(FILE_TEMPL_UPDATE, "post", updateObj).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("修改状态成功！");
          this.doQuery();
        }else{
          this.$message.error("修改状态异常！");
        }
      });
    }
  },
};
</script>

<style lang="less" scoped>
.operator {
  margin-right: 8px;

  .ant-btn {
    color: #307dfa;
    border-color: #307dfa;
  }
}

@media screen and (max-width: 900px) {
  .fold {
    width: 100%;
  }
}
</style>
