<template>
  <div class="relative">
    <div class="mt-2">
      <div class="text-center font-bold">为提升您账号的安全程度，请更新您的绑定手机号</div>
      <a-form class="mt-2" @submit="submit" :form="form">
        <a-form-item>
          <a-input
            :maxLength="11"
            size="large"
            placeholder="请输入手机号"
            class=""
            name="mobile"
            v-decorator="[
              'mobile',
              {
                rules: [
                  { required: true, message: '请输入手机号' },
                  { pattern: /^1[3-9]\d{9}$/, message: '请输入有效的手机号码' }
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="mobile" />
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-input
            size="large"
            placeholder="请输入验证码"
            class=""
            name="smsCode"
            v-decorator="[
              'smsCode',
              {
                rules: [{ required: true, message: '请输入验证码' }],
              },
            ]"
          >
            <a-icon slot="prefix" type="lock" />
            <a-button slot="addonAfter" type="link" @click="getCode" :disabled="countdown > 0"> {{ countdown > 0 ? `${countdown}秒后重新获取` : '获取验证码' }} </a-button>
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-button
            :loading="btnLoading"
            style="width: 100%; margin-top: 10px"
            size="large"
            htmlType="submit"
            type="primary"
            > 确定 </a-button
          >
        </a-form-item>
      </a-form>
    </div>
      <!-- 图形验证码 -->
    <Verify ref="verify" :captcha-type="'blockPuzzle'" :img-size="{width:'330px',height:'180px'}"
            @success="handleSuccessVerify" />
  </div>
</template>

<script>
import Verify from '../../components/Verifition/Verify.vue';
import { sendSmsCode, checkSmsCode, firstSaveBindPhone } from "@/services/user";
import { SYSTEM_TYPE_ENUM } from '@/enums/system.js'
import {logout,loginOut} from '@/services/user'
import {mapGetters} from 'vuex'
export default {
  components: { Verify },
  data() {
    return {
      form: this.$form.createForm(this),
      btnLoading: false,
      countdown: 0, // 倒计时时间
      intervalId: null, // 计时器ID
      mobile: '',
    };
  },
  props: {
    account: {
      type: String,
      default: '',
      required: true
    },
  },
  computed: {
    ...mapGetters('account', ['user']),
    systemType() {
      return this.$store.getters['setting/systemType'];
    },
  },
  mounted() {
    window.addEventListener('beforeunload', this.preventRefresh);
    window.addEventListener('popstate', this.preventBack);
    window.history.pushState(null, null, window.location.href);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventRefresh);
    window.removeEventListener('popstate', this.preventBack);
  },

  methods: {
     preventBack(event) {
      window.history.pushState(null, null, window.location.href);
    },
    preventRefresh(event) {
      logout()
      event.preventDefault();
      event.returnValue = ''; // 现代浏览器需要 returnValue 设置为一个空字符串来显示确认对话框
    },
    submit(e) {
      e.preventDefault();
      this.form.validateFields(["mobile", "smsCode"], (err) => {
        if (!err) {
          this.btnLoading = true
          const mobile = this.form.getFieldValue("mobile")
          const smsCode = this.form.getFieldValue("smsCode")
          firstSaveBindPhone({phone: mobile, smsCode, account: this.account, scene: 6}).then(res => {
            this.btnLoading = false
            if (res.data.code == 0) {
              logout()
              window.removeEventListener('beforeunload', this.preventRefresh);
              window.removeEventListener('popstate', this.preventBack);
              location.reload()
            } else {
              this.$message.error(res.data.msg)
            }
          })
        }
      });
    },
    handleSuccessVerify(e) {
      const mobile = this.form.getFieldValue("mobile")
      sendSmsCode({mobile, scene: 6}).then(res => {
        if (res.data.code == 0) {
          if (res.data.data) {
            this.$message.success('发送成功')
          }
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    getCode() {
      this.form.validateFields(["mobile"], (err) => {
        if (!err) {
          if (this.intervalId) {
            clearInterval(this.intervalId)
          }
          this.countdown = 60;
          // 设置定时器更新倒计时
          this.intervalId = setInterval(() => {
            if (this.countdown > 0) {
              this.countdown -= 1;
            } else {
              clearInterval(this.intervalId);
            }
          }, 1000);
          this.$refs.verify.show()
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-size: 24px;
  color: @title-color;
  font-family: "Myriad Pro", "Helvetica Neue", Arial, Helvetica,
    sans-serif;
  font-weight: 600;
}
.verifybox {
  position: absolute !important;
  top: 45%;
  left: 50%;
  z-index: 1001;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #e4e7eb;
  border-radius: 5px;
  box-shadow: 0 0 10px rgb(0 0 0 / 30%);
  transform: translate(-50%, -25%);
  padding: 8px;
  margin-top: 5px;
}
</style>