<template>
   <div>
    <div class="constructiontTable_index">
      <form-index
        ref="formindex"
        :formData="formData"
        :flexAction="true"
        :baiduMapBoolen="false"
        @handleSubmit="handleSubmit"
        @handleChange="handleChange"
        @handlSelect="handlSelect"
      >
        <!-- 下部分按钮插槽 -->
        <template #flexAction>
          <a-space :style="{ marginTop: '4px' }">
            <a-button icon="search" type="primary" html-type="submit">查询</a-button>
            <a-button @click="close"> 重置 </a-button>
          </a-space>
        </template>
      </form-index>
      <!-- <a-button style="margin-right: 8px;" icon="plus" type="primary">新 增</a-button> -->
      <!-- 表格封装 -->
      <div style="margin-top: 10px;">
        <standard-table
          class="table"
          :columns="columns"
          :dataSource="dataSource"
          rowKey="id"
          :pagination="pagination"
          :loading="tableLoading"
          @change="onPageChange"
          :isAlert="false"
          :bordered="true"
          tableSize="middle"
        >
          <template slot="index" slot-scope="{ index }">
            <span>
              {{
                (pagination.current - 1) * pagination.pageSize +
                parseInt(index) +
                1
              }}
            </span>
          </template>
          <template slot="projectName" slot-scope="{ text }">
              <a-tooltip placement="topLeft">
                <template slot="title"
                  ><span>{{ text }}</span></template
                >
                <span class="ant-btn-text ellipsis-2">{{ text  }}</span>
              </a-tooltip>
            </template>
            <template slot="status" slot-scope="{text}">
                <span v-if="text == 0"><i class="iconfont duigouxiao" style="color:#00CE86"></i></span>
                <span v-else><i class="iconfont path" style="color:#FF5C77"></i></span>
            </template>
          <!-- <div slot="action" slot-scope="{ record }">
            <div>
              <a @click="viewEditing(record, false)">查看</a>
            </div>
          </div> -->
        </standard-table>
      </div>
    </div>
  </div>
</template>

<script>
import { useRender } from '@/hooks/useRender.js'
const columns = [
      {
        title: "序号",
        width: 65,
        scopedSlots: { customRender: "index" },
        align: "center",
      },
      {
        title: "用户",
        dataIndex: "userNickname",
        align: "center",
      },
      // {
      //   title: "所属公司",
      //   dataIndex: "deptOfficeName",
      //   align: "center",
      // },
      {
        title: "操作模块",
        dataIndex: "module",
        align: "center",
        ellipsis: true,
      },
      {
        title: "操作名",
        dataIndex: "name",
        align: "center",
        ellipsis: true,
      },
      {
        title: "操作IP",
        dataIndex: "userIp",
        align: "center",
        ellipsis: true,
      },
      {
        title: "操作时间",
        dataIndex: "startTime",
        align: "center",
        customRender: (text) => useRender.renderDate(text)
      },
      {
        title: "操作接口",
        dataIndex: "requestUrl",
        align: "center",
        ellipsis: true,
      },
      {
        title: "执行时长",
        dataIndex: "duration",
        align: "center",
        customRender: (text) => useRender.renderText(text, 'ms')
      },
      {
        title: "执行结果",
        dataIndex: "resultCode",
        align: "center",
        scopedSlots: { customRender: "status" },
      },
      // {
      //   title: "操作内容",
      //   dataIndex: "title",
      //   align: "center",
      // },
      // {
      //   title: "操作",
      //   align: "center",
      //   width: 100,
      //   // fixed: "right",
      //   scopedSlots: { customRender: "action" },
      // },
    ]
const formData = [
  {
    label: "操作时间",
    placeholder: "操作时间",
    decorator: [
      "startTime",
      { rules: [{ required: false, message: "操作时间" }] },
    ],
    type: "rangePicker",
    key: "startTime",
    col: 5,
    labelCol: 5,
    wrapperCol: 16,
    display: true,
    format: "YYYY-MM-DD HH:mm:ss",
  },
  {
    label: "用户",
    placeholder: "请输入用户查询",
    decorator: [
      "operName",
      { rules: [{ required: false, message: "请输入用户查询" }] },
    ],
    type: "input",
    key: "operName",
    col: 5,
    labelCol: 6,
    wrapperCol: 16,
    display: true,
  },
  {
    type: "action",
    col: 6,
    display: true,
  },
]   
import StandardTable from "@/components/table/StandardTable";
import formIndex from "@/pages/components/form/index";
import { getLogGingList } from '@/services/sys'
export default {
  name: 'SuijianClientLogGingIndex',
  components: {
    StandardTable, //表格封装
    formIndex,
  },
  data() {
    return {
      formData,
      columns, //表格数据
      dataSource: [],
      pagination: {
        showSizeChanger:true,
        pageSizeOptions: ["10", "50", "100", "150"],
        current: 1,
        pageSize: 50,
        total: 0,
        showLessItems: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      tableLoading: false,
      params: {
        pageNumber: 1,
        pageSize: 50,
      },
      selectedRows:[]
    };
  },

  mounted() {
    this.aqxjlist(this.params)
  },

  methods: {
    aqxjlist(params, query) {
      this.tableLoading = true;
      getLogGingList(params, query).then(res => {
        if (res.data.code === 0) {
        this.dataSource = res.data.data.list
        this.pagination.total = res.data.data.total;
        } else {
        this.$message.warning('获取数据失败')
        }
        this.tableLoading = false;
      })
    },
    // 提交表单数据
    handleSubmit(e) {
      let obj = Object.assign({}, e, this.params);
      let query = '';
      if (obj.startTime) {
        for (const idx of Object.keys(obj.startTime)) {
          const paramss = `startTime[${idx}]`
          const subPart = `${encodeURIComponent(paramss)}=`
          query += `${subPart + encodeURIComponent(obj.startTime[idx])}&`
        }
      }
      delete obj.startTime
      obj.pageNumber = 1
      this.pagination.current = 1;
      this.selectedRows = []
      this.aqxjlist(obj, query);
    },
    // 特殊数据处理
    handlSelect(obj) {
      const { val, keys } = obj;
      
    },
    //表格 - 分页查询
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      let query = '';
      if (obj.startTime) {
        for (const idx of Object.keys(obj.startTime)) {
          const paramss = `startTime[${idx}]`
          const subPart = `${encodeURIComponent(paramss)}=`
          query += `${subPart + encodeURIComponent(obj.startTime[idx])}&`
        }
      }
      delete obj.startTime
      this.params.pageNumber = e.current;
      this.params.pageSize = e.pageSize;
      this.pagination.current = e.current;
      this.pagination.pageSize = e.pageSize;
      this.aqxjlist(Object.assign(obj, this.params),query);
    },
    close() {
      this.params = {
        pageNumber: 1,
        pageSize: 50,
        // projectId:this.user.projectId,
      };
      this.pagination.current = 1;
      this.pagination.pageSize = 50;
      this.aqxjlist(this.params);
      this.$refs["formindex"].resetFields();
    },
    updateSelectRows(e) {
      console.log(e)
    },
    handleChange(e) {
      // this.projectArr = [];
      // this.projectList = []
      // this.getZdata(getOfficeProject, e, "projectName",'fullName');
    },
  },
};
</script>

<style lang="less" scoped>
.constructiontTable_index {
  padding: 24px;
  background: #fff;
  border-radius: 6px;
  margin-bottom: 10px;
  min-height: 560px;
}
/deep/ .ant-modal-footer {
    text-align: center;
    border-top: none;
    padding-bottom: 24px;
}
/deep/ .ant-modal-body {
  padding-bottom: unset;
}
</style>