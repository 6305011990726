var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "company card w-100" }, [
    _c(
      "div",
      {
        staticClass: "black font-16 font-bold overtext-1",
        staticStyle: { "margin-bottom": "10px" },
      },
      [
        _vm._v(
          " " +
            _vm._s(
              _vm.isProject ? _vm.projectData.projectName : _vm.officeData.name
            ) +
            " "
        ),
      ]
    ),
    _c("div", { staticClass: "flex a-center" }, [
      _c("img", {
        staticStyle: { width: "100px", height: "82px" },
        attrs: { src: require("@/assets/img/home-project.png") },
      }),
      _c("div", { staticClass: "ml-1" }, [
        _vm.isProject
          ? _c(
              "div",
              { staticClass: "overtext-1", staticStyle: { width: "200px" } },
              [
                _c(
                  "span",
                  { staticClass: "font-bold", staticStyle: { width: "60px" } },
                  [_vm._v("参建单位")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "ml-1",
                    attrs: { title: _vm.projectData.officeName },
                  },
                  [_vm._v(_vm._s(_vm.projectData.officeName))]
                ),
              ]
            )
          : _vm._e(),
        _c("div", { staticClass: "overtext-1 mt-1 flex" }, [
          _c(
            "span",
            {
              staticClass: "font-bold text-right",
              staticStyle: { width: "70px" },
            },
            [_vm._v("负责人:")]
          ),
          _c(
            "span",
            {
              staticClass: "ml-1",
              attrs: {
                title: _vm.isProject
                  ? _vm.projectData.userName
                  : _vm.officeData.master,
              },
            },
            [
              _vm._v(
                _vm._s(
                  _vm.isProject
                    ? _vm.projectData.userName
                    : _vm.officeData.master || "/"
                )
              ),
            ]
          ),
        ]),
        _c("div", { staticClass: "overtext-1 mt-1 flex" }, [
          _c(
            "span",
            {
              staticClass: "font-bold text-right",
              staticStyle: { width: "70px" },
            },
            [_vm._v("联系电话:")]
          ),
          _c(
            "span",
            {
              staticClass: "ml-1",
              attrs: {
                title: _vm.isProject
                  ? _vm.projectData.userPhone
                  : _vm.officeData.phone,
              },
            },
            [
              _vm._v(
                _vm._s(
                  _vm.isProject
                    ? _vm.projectData.userPhone
                    : _vm.officeData.phone || "/"
                )
              ),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }