<template>
  <div class="a_Approval">
    <div class="plug-Overview">项目定期自检记录</div>
    <a-divider />
    <div :class="advanced ? 'search' : null">
        <a-form class="clearfix">
          <a-col :span="6">
            <a-form-item label="检查时间" :labelCol="{ span: 7 }" :wrapperCol="{ span: 16, offset: 1 }">
              <a-range-picker  v-model="plan_date"  :format="dateFormat"  @change="changeDate"/>
            </a-form-item>
          </a-col>

          <a-col :span="4">
            <a-space class="btnBox">
              <a-button type="primary" @click="handleDearch">查询</a-button>
              <a-button @click="doReset()">重置</a-button>
            </a-space>
          </a-col>
        </a-form>
      </div>
      <standard-table
          :columns="columns"
          :dataSource="dataSource"
          :selectedRows.sync="selectedRows"
          :pagination="pagination"
          :loading="tableLoading"
          :isAlert="false"
          @clear="onClear"
          @change="onPageChange"
        >
          <div slot="do-status" slot-scope="{text}">
            <label v-if="text==0">检查中</label>
            <label v-if="text==2">整改不合格</label>
            <label v-if="text==1">整改达标</label>
          </div>
          <div slot="xjTime" slot-scope="{text}">
            <div>{{text|formatDate}}</div>
          </div>
          <div slot="action" slot-scope="{record}">
            <a @click="dowLoadFile(record)">下载文件</a>
          </div>
      </standard-table>
      <Export-loading :cPercent="cPercent" :isExporting="isExporting" :loadingText="loadingText"/>
  </div>
</template>

<script>
import StandardTable from '@/components/table/StandardTable'
import {PROJECT_CHECK_AQXJ_QUERY,PROJECT_CHECK_AQXJ_EXPORT} from '@/services/api'
import { exportProgress } from '@/pages/aqjyzl/export' // 导出方法
import ExportLoading from '@/components/ExportLoading/ExportLoading' // progress组件
import {request} from '@/utils/request'
import {formatDate} from '@/filters/formatDate.js'
import {mapGetters} from 'vuex'
import moment from 'moment';
import { useRender } from '@/hooks/useRender.js'

  const columns = [
  {
    title: '检查时间',
    dataIndex: 'checkDate',
    align: 'center',
    customRender: (text) => useRender.renderDate(text)
  },{
    title: '检查人员',
    dataIndex: 'userNames',
    align: 'left',
    width:220,
    ellipsis:false,
    scopedSlots: { customRender: 'userNames' }
  },
  {
    title: '项目名称',
    dataIndex: 'projectName',
    align: 'left',
    scopedSlots: { customRender: 'projectName' }
  },
  // ,
  {
    title: '整改责任部门/班组',
    dataIndex: 'teamNames',
    align: 'center',
    scopedSlots: { customRender: 'teamNames' }
  },
  {
    title: '整改状态',
    dataIndex: 'fhjg',
    align: 'center',
    scopedSlots: { customRender: 'do-status' }
  },
    {
    title: '检查标题',
    dataIndex: 'title',
    align: 'center',
    scopedSlots: { customRender: 'title' }
  },{
    title: '操作',
    align: 'center',
    scopedSlots: { customRender: 'action' }
  }
  ];

export default {
   mixins: [exportProgress],
  name: 'QueryList',
  components: {StandardTable,ExportLoading},
  data () {
    return {
      firstDay:null,
      nowDay:null,
      dataSource:[],
      loadingText:'正在下载文件...',
      loadKeys:[],
      advanced: false,
      columns: columns,
      tableLoading:false,
      selectedRows: [],
      dateFormat: 'YYYY-MM-DD',
      plan_date:[moment(new Date()).format('YYYY-MM')+"-01", moment(new Date()).format('YYYY-MM-DD')],
      queryParams:{
        pageNumber:1,
        pageSize:10,
        checkDateStart:'',
        checkDateEnd:'',
      },
      pagination:{
        current:1,
        pageSize:10,
        total:0,
        showLessItems:true,
        showQuickJumper:true
      },
      cPercent:0,
      isExporting:false,
      fileName:'检查记录文件',
    }
  },
  computed: {
    ...mapGetters('account', ['user']),
  },
  filters:{
    formatDate(time){
      let date = new Date(time);
      return formatDate(date,'yyyy-MM-dd')
    }
  },
  created() {
    this.firstDay = moment(new Date()).format('YYYY-MM')+"-01";
    this.nowDay = moment(new Date()).format('YYYY-MM-DD');
    this.doQuery();

  },
  watch: {
    cPercent:{
      handler(newName, oldName) {
        this.cPercent=newName;
      },
      immediate: true,
    },
    loadingText:{
      handler(newName, oldName) {
        this.loadingText=newName;
      },
      immediate: true,
    }
  },
  methods: {
    moment,
    deleteRecord(key) {
      this.dataSource = this.dataSource.filter(item => item.key !== key)
      this.selectedRows = this.selectedRows.filter(item => item.key !== key)
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    remove () {
      this.dataSource = this.dataSource.filter(item => this.selectedRows.findIndex(row => row.key === item.key) === -1)
      this.selectedRows = []
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },
     /**
     * 获取所有人员的签名记录信息
     */
    doQuery(){
      this.tableLoading = true;
      console.log(this.plan_date, 'plan_date')
      this.queryParams.checkDateStart = this.plan_date[0]
      this.queryParams.checkDateEnd = this.plan_date[1]
      console.log(this.queryParams)
      request(PROJECT_CHECK_AQXJ_QUERY,"post",this.queryParams).then(res => {
        if(res.data.code==0){
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
          this.dataSource.forEach((obj,index)=>{
            obj.key = obj.id;
          })
        }
        this.tableLoading = false;
      })
    },
    handleDearch() {
      this.queryParams.pageNumber = 1
      this.pagination.current = 1
      this.doQuery()
    },
    doReset(){
      this.queryParams={queryType:1};
      this.doQuery();
    },
    //分页查询
    onPageChange(e){
      this.queryParams.pageNumber = e.current;
      this.pagination.current=e.current;
      this.doQuery();
    },
    changeDate(e, i){
        this.plan_date[0] = i[0];
        this.plan_date[1] = i[1]
    },
    //下载巡检资料文件
    dowLoadFile(recode){
      let selectedRows = this.dataSource.filter(item => item.key == recode.key);
      this.loadingText = "正在下载文件，请稍候 ····";
      this.loadKeys = [];
      this.loadKeys.push(recode.key);
      this.isExporting=true;
      this.cPercent=0;
      let fileName = ""
      if(recode.projectName){
        fileName=recode.projectName;
      }
      fileName=fileName+"-"+moment(recode.checkDate).format('YYYY-MM-DD')+"-项目自检文件.zip";
      this.exportFun(PROJECT_CHECK_AQXJ_EXPORT,this.loadKeys,fileName,selectedRows)
    },
  }
}
</script>

<style lang="less" scoped>
  .search{
    margin-bottom: 54px;
  }
  .fold{
    width: calc(100% - 216px);
    display: inline-block
  }
  .operator{
    margin-bottom: 18px;
  }
  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
</style>
