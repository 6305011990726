<template>
  <div class="create_index">
    <!-- 表单封装
            projectObj：下拉弹出框第一项对象
            Overview：项目名称
            baiduMapBoolen：控制地图显示
            topSubmit：控制上部分按钮显示
            bottomSubmit：控制下部分按钮显示
            handleSubmit：表单提交
            handleChange：输入出发
            handlSelect：输入下拉选择触发
            defaultFileList：上传图片回显链接
            query：路由传过来的数据
        -->
    <form-index

      ref="formindex"
      :options="options"
      :formData="formData"
      Overview="项目概况"
      @handleChange="handleChange"
      :baiduMapBoolen="true"
      :topSubmit="true"
      :projectList="projectList"
      @handleSubmit="handleSubmit"
      @handlSelect="handlSelect"
      :defaultFileList="defaultFileList"
    >
      <!-- 上部分按钮插槽 -->
      <template #topSubmit>
        <a-space>
          <a-button  icon="save" type="primary" html-type="submit"> 提交 </a-button>
          <a-button @click="close"> 关闭 </a-button>
        </a-space>
      </template>
    </form-index>
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import {
  getLwProjectAll,
  addLwProjects,
  getTreeOffice,
  projectMsgGetList
} from "@/services/projectManagement";
import { request } from "@/utils/request";
import { PROJECT_OFFICE_CHILD } from "@/services/api";
import { sysUserGetList } from "@/services/message";
import { mapGetters } from "vuex";
import { getOfficeTypeList } from "@/services/sys";
import address from "@/pages/components/form/address.json";
import { debounce } from "lodash";
const formData = [
  {
    label: "项目名称",
    placeholder: "请输入项目名称",
    decorator: [
      "fullName",
      { rules: [{ required: true, message: "请输入项目名称" }] },
    ],
    type: "inputGroup",
    key: "fullName",
    dataSource: [],
    labelCol: 4,
    wrapperCol: 19,
    col: 12,
    display: true,
  },
  {
    label: "项目代码",
    placeholder: "请输入项目代码",
    decorator: [
      "projectNum",
      { rules: [{ required: true, message: "请输入项目代码" }] },
    ],
    type: "input",
    key: "projectNum",
    col: 12,
    labelCol: 4,
    wrapperCol: 19,
    display: true,
  },
  {
    label: "参建单位",
    placeholder: "请选择参建单位",
    decorator: [
      "officeId",
      { rules: [{ required: true, message: "请选择参建单位" }] },
    ],
    type: "treeSelect",
    key: "officeId",
    selectlist: [],
    col: 6,
    labelCol: 8,
    wrapperCol: 14,
    display: true,
    showSearch: true
  },
  // 施工总承包
  {
    label: "参建类型",
    placeholder: "请选择参建类型",
    decorator: [
      "roleType",
      { rules: [{ required: false, message: "请选择参建类型" }] },
    ],
    type: "select",
    key: "roleType",
    selectlist: [],
    col: 6,
    labelCol: 8,
    wrapperCol: 14,
    display: true,
  },
  // {
  //   label: "工程管理部",
  //   placeholder: "请选择工程管理部",
  //   decorator: [
  //     "gcglbId",
  //     { rules: [{ required: false, message: "请选择工程管理部" }] },
  //   ],
  //   type: "select",
  //   key: "gcglbId",
  //   selectlist: [],
  //   col: 6,
  //   labelCol: 6,
  //   wrapperCol: 14,
  //   display: true,
  //   disabled: false,
  // },
  {
    label: "工程状态",
    placeholder: "请选择工程状态",
    decorator: [
      "status",
      { initialValue: 0, rules: [{ required: true, message: "请选择工程状态" }] },
    ],
    type: "select",
    key: "status",
    col: 6,
    labelCol: 8,
    wrapperCol: 14,
    display: true,
    selectlist: [
      {
        value: 3,
        name: "未开工",
      },
      {
        value: 0,
        name: "在建",
      },
      {
        value: 1,
        name: "完工",
      },
      {
        value: 2,
        name: "停缓建",
      },
    ],
  },
  {
    label: "工程类别",
    placeholder: "请选择工程类别",
    decorator: [
      "ptype",
      { rules: [{ required: true, message: "请选择工程类别" }] },
    ],
    type: "select",
    key: "ptype",
    col: 6,
    labelCol: 8,
    wrapperCol: 14,
    display: true,
    selectlist: [
      {
        value: 1,
        name: "房建"
      },
      {
        value: 2,
        name: "市政"
      },
      {
        value: 3,
        name: "装修工程"
      },
      {
        value: 91,
        name: "土建"
      },
      {
        value: 92,
        name: "房屋建筑工程"
      },
      {
        value: 93,
        name: "冶炼工程"
      },
      {
        value: 4,
        name: "矿山工程"
      },
      {
        value: 5,
        name: "化工石油工程"
      },
      {
        value: 6,
        name: "水利水电工程"
      },
      {
        value: 7,
        name: "电力工程"
      },
      {
        value: 8,
        name: "农林工程"
      },
      {
        value: 9,
        name: "铁路工程"
      },
      {
        value: 10,
        name: "公路工程"
      },
      {
        value: 11,
        name: "港口与航道工程"
      },
      {
        value: 12,
        name: "航天航空工程"
      },
      {
        value: 13,
        name: "通信工程"
      },
    ]
  },

  {
    label: "参建负责人",
    placeholder: "请选择或输入参建负责人",
    decorator: [
      "userName",
      { rules: [{ required: true, message: "请选择或输入参建负责人" }] },
    ],
    type: "inputGroup",
    key: "userName",
    dataSource: [],
    col: 6,
    labelCol: 8,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "负责人电话",
    placeholder: "请输入参建负责人电话",
    decorator: [
      "userPhone",
      { rules: [{ required: true, message: "请输入参建负责人电话"},{message: "请输入正确手机号",pattern: new RegExp(/^1\d{10}$/),}] },
    ],
    type: "input",
    key: "userPhone",
    col: 6,
    labelCol: 8,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "运维到期时间",
    placeholder: "请选择运维到期时间",
    decorator: [
      "dueTime",
      { rules: [{ required: true, message: "请选择运维到期时间" }] },
    ],
    type: "datePicker",
    key: "dueTime",
    col: 6,
    labelCol: 8,
    wrapperCol: 14,
    format: "yyyy-MM-DD",
    styles: "width: 100%",
    display: true,
    // disabled: true,
  },
  {
    label: "是否报建",
    placeholder: "请选择是否报建",
    decorator: [
      "isReport",
      { initialValue: 1, rules: [{ required: false, message: "请选择是否报建" }] },
    ],
    type: "select",
    key: "isReport",
    col: 6,
    labelCol: 8,
    wrapperCol: 14,
    selectlist: [
      {
        value: 0,
        name: "否",
      },
      {
        value: 1,
        name: "是",
      },
    ],
    display: true,
  },
  {
    label: "工程概况",
    placeholder: "请输入工程概况",
    decorator: [
      "projDesc",
      { rules: [{ required: false, message: "请输入工程概况" }] },
    ],
    type: "textarea",
    key: "projDesc",
    labelCol: 4,
    wrapperCol: 19,
    col: 12,
    display: true,
  },

  {
    label: "造价(万元)",
    placeholder: "请输入工程造价",
    decorator: [
      "projBudget",
      { rules: [{ required: false, message: "请输入工程造价" }] },
    ],
    type: "input",
    key: "projBudget",
    col: 12,
    labelCol: 4,
    wrapperCol: 19,
    display: true,
  },
  {
    label: "项目地区",
    placeholder: "请选择省市区",
    decorator: [
      "nationality",
      { rules: [{ required: false, message: "请选择省市区" }] },
    ],
    type: "cascader",
    key: "nationality",
    labelCol: 8,
    wrapperCol: 14,
    col: 6,
    display: true,
  },
  {
    label: "工程地址",
    placeholder: "请輸入工程地址",
    decorator: [
      "projAddress",
      { rules: [{ required: false, message: "请輸入工程地址" }] },
    ],
    type: "input",
    key: "projAddress",
    col: 6,
    labelCol: 8,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "工程经纬度",
    placeholder: "113.274 , 23.123",
    decorator: [
      "lanAndLat",
      { rules: [{ required: false, message: "请输入经纬度或从地图选择" }] },
    ],
    type: "input",
    key: "lanAndLat",
    disabled: true,
    col: 12,
    labelCol: 4,
    wrapperCol: 19,
    display: true,
  },
  {
    label: "项目ID",
    placeholder: "请輸入项目ID",
    decorator: [
      "projectId",
      { rules: [{ required: false, message: "请輸入项目ID" }] },
    ],
    type: "input",
    key: "projectId",
    col: 12,
    labelCol: 0,
    wrapperCol: 0,
    display: true,
  },
  {
    label: "userID",
    placeholder: "请輸入userID",
    decorator: [
      "userId",
      { rules: [{ required: false, message: "请輸入userID" }] },
    ],
    type: "input",
    key: "userId",
    col: 12,
    labelCol: 0,
    wrapperCol: 0,
    display: true,
  },
];
export default {
  data() {
    return {
      formData: formData,
      // form: this.$form.createForm(this, { name: 'coordinated' }),
      projectArr: [], //项目名称
      projectList: [], //获取当前项目数据
      defaultFileList: [], //图片数组
      parentOfficeId: "",
      options: address,
    };
  },
  components: {
    formIndex,
  },
  inject: ["fatherMethod"],
  computed: {
    ...mapGetters("account", ["user"]),
  },
  methods: {
    // 关闭当前页面
    close() {
      let path = this.$route.path;
      this.fatherMethod(`${path}`);
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      this.formData.forEach((item) => {
        if (item.key == key) {
          item.selectlist = data;
        }
      });
    },
    // parentofficeId
    initTree() {
      getTreeOffice({pageSize: 2000}).then((res) => {
        if (res.data.code === 0) {
          this.formDataFn("officeId", res.data.data.list);
        }
      });
    },
    // 获取工程管理部数据
    getgcglbList(path) {
      request(path, "get").then((res) => {
        if (res.data.code === 0) {
          const data = res.data.data.filter(
            (item) => item.id != "3da0f5533f87425c9a9e8a11a7923300"
          );
          const arr = [];
          data.forEach((item) => {
            arr.push({
              name: item.itemValue,
              value: item.itemText,
            });
          });
          this.formDataFn("gcglbId", arr);
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    getOfficeTypeList() {
      getOfficeTypeList().then((res) => {
        const data = res.data.data.companyTypeList;
        const arr = [];
        data.forEach((item) => {
          arr.push({
            name: item.label,
            value: item.value,
          });
        });
        this.roleTypeList = arr;
        this.formDataFn("roleType", arr);
      });
    },
    // 提交表单数据
    handleSubmit:debounce(function (e) {
      // 省市区联动
      if (e.nationality) {
        e.province = e.nationality[0] ? e.nationality[0] : undefined;
        e.city = e.nationality[1] ? e.nationality[1] : undefined;
        e.county = e.nationality[2] ? e.nationality[2] : undefined;
      }
      e.projType = e.ptype; // projType项目类型
      delete e.nationality;
      delete e.lanAndLat;
      if (e.userId == '') e.userId = undefined
      addLwProjects(e).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("操作成功", 10);
          this.$refs["formindex"].resetFields();
          this.close();
          this.$router.push({
            name: '项目库'
        });
        } else {
          this.$message.error(res.data.msg, 10);
        }
      });
      },1000),
    // 下拉接口的处理
    getZdata(msg, e, name, key) {
      let data = {
        [name]: e.value,
        // parentOfficeId: name == "name" ? this.parentOfficeId : undefined
      };
      msg(data).then((res) => {
        if (res.status == 200) {
          const data = res.data.data.list ? res.data.data.list : res.data.data
          const arr = [];
          data.forEach((item) => {
            if (name == 'nickName') {
             name = 'nickname'
            }
            this.projectArr.push({
              title: item[name],
              id: item.id,
            });
            arr.push({
              name: item.name,
              value: item.id,
            });
          });
          this.projectList = data;
          if (key) {
            this.formDataFn(key, arr);
          }
        }
      });
    },
    // 输入选择框触发
    handleChange(e) {
      this.projectArr = [];
      const name = {
        fullName: () => {
          this.getZdata(projectMsgGetList, e, "fullName");
        },
        userName: () => {
          this.getZdata(sysUserGetList, e, "nickName");
        },
      }[e.item.key](); //匹配对应调用对应接口
      // 获取当前匹配key，赋值相对于数组
      this.formData.forEach((item) => {
        if (e.item.key === item.key) {
          item.dataSource = this.projectArr;
        }
      });
    },
    // 特殊数据处理
    handlSelect({ val, keys }) {
      this.projectList.forEach((item) => {
        if (keys == "userName" && item.id == val) {
            this.$refs["formindex"].setFieldsValue(item.mobile, { userPhone: item.mobile },"userPhone");
            this.$refs["formindex"].setFieldsValue(item.id,{ userId: item.id },"userId");
            this.$refs["formindex"].setFieldsValue(item.nickname,{ userName: item.nickname },"userName"
          );
        } else if (keys == "fullName" && item.id == val) {
          this.$refs["formindex"].setFieldsValue(
            item.fullName,
            { fullName: item.fullName },
            "fullName"
          );
          this.$refs["formindex"].setFieldsValue(
            item.id,
            { projectId: item.id },
            "projectId"
          );
          this.$refs["formindex"].setFieldsValue("", {
            projectNum: item.projectNum || item?.projectMsg?.projectNum,
          });
        }
      });
    },
  },
  created() {
    this.getgcglbList(PROJECT_OFFICE_CHILD + "/OFFICE_CHILD");
    this.initTree();
    this.getOfficeTypeList();
    // this.$refs["formindex"].setFieldsValue(item.longitude, {longitude: item.longitude}, "longitude")
  },
  mounted() {
    if (!this.user.nowRoleKey.includes("admin")) {
      this.$refs["formindex"].queryObj({ officeId: this.user.officeId });
    }
  },
};
</script>
<style lang="less" scoped>
.create_index {
  padding: 24px;
  background: #fbfbfb;
  // border: 1px solid #d9d9d9;
  // border-radius: 6px;
  margin-bottom: 10px;
}
</style>
