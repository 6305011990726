var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-card",
        { attrs: { bordered: false } },
        [
          _c(
            "div",
            { attrs: { id: "components-form-demo-advanced-search" } },
            [
              _c(
                "a-form",
                {
                  staticClass: "ant-advanced-search-form",
                  attrs: { form: _vm.searchForm },
                },
                [
                  _c(
                    "a-row",
                    { attrs: { gutter: 24 } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "模板id" } },
                            [
                              _c("a-input", {
                                attrs: { placeholder: "请输入模板id" },
                                model: {
                                  value: _vm.searchForm.essTemplateId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.searchForm,
                                      "essTemplateId",
                                      $$v
                                    )
                                  },
                                  expression: "searchForm.essTemplateId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "模板类型" } },
                            [
                              _c(
                                "a-select",
                                {
                                  model: {
                                    value: _vm.searchForm.templateType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "templateType",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.templateType",
                                  },
                                },
                                [
                                  _c(
                                    "a-select-option",
                                    { key: "", attrs: { value: "" } },
                                    [_vm._v(" 全部 ")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { key: "0", attrs: { value: "0" } },
                                    [_vm._v(" 其他 ")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { key: "1", attrs: { value: "1" } },
                                    [_vm._v(" 入场合同 ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "状态" } },
                            [
                              _c(
                                "a-select",
                                {
                                  model: {
                                    value: _vm.searchForm.status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "status", $$v)
                                    },
                                    expression: "searchForm.status",
                                  },
                                },
                                [
                                  _c(
                                    "a-select-option",
                                    { key: "", attrs: { value: "" } },
                                    [_vm._v(" 全部 ")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { key: "0", attrs: { value: "0" } },
                                    [_vm._v(" 未启用 ")]
                                  ),
                                  _c(
                                    "a-select-option",
                                    { key: "1", attrs: { value: "1" } },
                                    [_vm._v(" 已启用 ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        {
                          style: { textAlign: "right", marginTop: "3px" },
                          attrs: { span: 4 },
                        },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "search",
                                loading: _vm.loading,
                              },
                              on: { click: _vm.handleSearch },
                            },
                            [_vm._v(" 查询 ")]
                          ),
                          _c(
                            "a-button",
                            {
                              staticStyle: { "margin-left": "10px" },
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.add()
                                },
                              },
                            },
                            [_vm._v(" 新增 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("a-row"),
                ],
                1
              ),
            ],
            1
          ),
          _c("a-table", {
            attrs: {
              columns: _vm.columns,
              "data-source": _vm.data,
              bordered: "",
            },
            scopedSlots: _vm._u([
              {
                key: "templateId",
                fn: function (text, record, index) {
                  return [
                    _c(
                      "div",
                      { key: index },
                      [
                        record.editable
                          ? _c("a-input", {
                              staticStyle: { margin: "-5px 0" },
                              attrs: { value: text },
                              on: {
                                change: function (e) {
                                  return _vm.handleChange(
                                    e.target.value,
                                    record.id,
                                    "templateId"
                                  )
                                },
                              },
                            })
                          : [_vm._v(" " + _vm._s(text) + " ")],
                      ],
                      2
                    ),
                  ]
                },
              },
              {
                key: "templateType",
                fn: function (text, record, index) {
                  return [
                    _c(
                      "div",
                      { key: index },
                      [
                        record.editable
                          ? _c(
                              "a-select",
                              {
                                on: {
                                  change: function (e) {
                                    return _vm.handleChange(
                                      e.target.value,
                                      record.id,
                                      "templateType"
                                    )
                                  },
                                },
                                model: {
                                  value: record.templateType,
                                  callback: function ($$v) {
                                    _vm.$set(record, "templateType", $$v)
                                  },
                                  expression: "record.templateType",
                                },
                              },
                              [
                                _c(
                                  "a-select-option",
                                  { key: "0", attrs: { value: "0" } },
                                  [_vm._v(" 其他 ")]
                                ),
                                _c(
                                  "a-select-option",
                                  { key: "1", attrs: { value: "1" } },
                                  [_vm._v(" 入场合同 ")]
                                ),
                              ],
                              1
                            )
                          : [
                              _vm._v(
                                " " +
                                  _vm._s(text == 0 ? "其他" : "入场合同") +
                                  " "
                              ),
                            ],
                      ],
                      2
                    ),
                  ]
                },
              },
              {
                key: "fromType",
                fn: function (text, record, index) {
                  return [
                    _c(
                      "div",
                      { key: index },
                      [
                        record.editable
                          ? _c(
                              "a-select",
                              {
                                on: {
                                  change: function (e) {
                                    return _vm.handleChange(
                                      e.target.value,
                                      record.id,
                                      "fromType"
                                    )
                                  },
                                },
                                model: {
                                  value: record.fromType,
                                  callback: function ($$v) {
                                    _vm.$set(record, "fromType", $$v)
                                  },
                                  expression: "record.fromType",
                                },
                              },
                              [
                                _c(
                                  "a-select-option",
                                  { key: "1", attrs: { value: 1 } },
                                  [_vm._v(" 腾讯电子签 ")]
                                ),
                                _c(
                                  "a-select-option",
                                  { key: "2", attrs: { value: 2 } },
                                  [_vm._v(" 法大大电子签 ")]
                                ),
                              ],
                              1
                            )
                          : [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    text == 1 ? "腾讯电子签" : "法大大电子签"
                                  ) +
                                  " "
                              ),
                            ],
                      ],
                      2
                    ),
                  ]
                },
              },
              {
                key: "customTemplateName",
                fn: function (text, record, index) {
                  return [
                    _c(
                      "div",
                      { key: index },
                      [
                        record.editable
                          ? _c(
                              "a-select",
                              {
                                on: {
                                  change: function (e) {
                                    return _vm.handleChange(
                                      e.target.value,
                                      record.id,
                                      "customTemplateId"
                                    )
                                  },
                                },
                                model: {
                                  value: record.customTemplateId,
                                  callback: function ($$v) {
                                    _vm.$set(record, "customTemplateId", $$v)
                                  },
                                  expression: "record.customTemplateId",
                                },
                              },
                              _vm._l(
                                _vm.customTemplateList,
                                function (item, index) {
                                  return _c(
                                    "a-select-option",
                                    { key: index, attrs: { value: item.id } },
                                    [_vm._v(" " + _vm._s(item.title) + " ")]
                                  )
                                }
                              ),
                              1
                            )
                          : [_vm._v(" " + _vm._s(text) + " ")],
                      ],
                      2
                    ),
                  ]
                },
              },
              {
                key: "customTemplateNameOthers",
                fn: function (text, record, index) {
                  return [
                    _c(
                      "div",
                      { key: index },
                      [
                        record.editable
                          ? _c(
                              "a-select",
                              {
                                attrs: { mode: "multiple" },
                                on: {
                                  change: function (e) {
                                    return _vm.handleChange(
                                      e.target.value,
                                      record.id,
                                      "customTemplateIds"
                                    )
                                  },
                                },
                                model: {
                                  value: record.customTemplateIds,
                                  callback: function ($$v) {
                                    _vm.$set(record, "customTemplateIds", $$v)
                                  },
                                  expression: "record.customTemplateIds",
                                },
                              },
                              _vm._l(
                                _vm.customTemplateList,
                                function (item, index) {
                                  return _c(
                                    "a-select-option",
                                    { key: index, attrs: { value: item.id } },
                                    [_vm._v(" " + _vm._s(item.title) + " ")]
                                  )
                                }
                              ),
                              1
                            )
                          : [_vm._v(" " + _vm._s(text) + " ")],
                      ],
                      2
                    ),
                  ]
                },
              },
              {
                key: "status",
                fn: function (text, record, index) {
                  return [
                    _c(
                      "div",
                      { key: index },
                      [
                        record.editable
                          ? _c(
                              "a-select",
                              {
                                on: {
                                  change: function (e) {
                                    return _vm.handleChange(
                                      e.target.value,
                                      record.id,
                                      "status"
                                    )
                                  },
                                },
                                model: {
                                  value: record.status,
                                  callback: function ($$v) {
                                    _vm.$set(record, "status", $$v)
                                  },
                                  expression: "record.status",
                                },
                              },
                              [
                                _c(
                                  "a-select-option",
                                  { key: "0", attrs: { value: "0" } },
                                  [_vm._v(" 未启用 ")]
                                ),
                                _c(
                                  "a-select-option",
                                  { key: "1", attrs: { value: "1" } },
                                  [_vm._v(" 已启用 ")]
                                ),
                              ],
                              1
                            )
                          : [
                              _vm._v(
                                " " +
                                  _vm._s(text == 0 ? "未启用" : "已启用") +
                                  " "
                              ),
                            ],
                      ],
                      2
                    ),
                  ]
                },
              },
              {
                key: "action",
                fn: function (text, record) {
                  return [
                    record.editable
                      ? _c(
                          "span",
                          [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function () {
                                    return _vm.editSave(record.id)
                                  },
                                },
                              },
                              [_vm._v("保存")]
                            ),
                            _c("a-divider", { attrs: { type: "vertical" } }),
                            _c(
                              "a-popconfirm",
                              {
                                attrs: { title: "是否取消保存?" },
                                on: {
                                  confirm: function () {
                                    return _vm.editCancel(record.id)
                                  },
                                },
                              },
                              [_c("a", [_vm._v("取消")])]
                            ),
                          ],
                          1
                        )
                      : _c(
                          "span",
                          [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function () {
                                    return _vm.edit(record.id)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _c("a-divider", { attrs: { type: "vertical" } }),
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.del(record)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                            _c("a-divider", { attrs: { type: "vertical" } }),
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.setting(record)
                                  },
                                },
                              },
                              [_vm._v("设置")]
                            ),
                            _c("a-divider", { attrs: { type: "vertical" } }),
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.downTempl(record)
                                  },
                                },
                              },
                              [_vm._v("模板下载")]
                            ),
                          ],
                          1
                        ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "a-modal",
            {
              attrs: {
                title: _vm.title,
                visible: _vm.visible,
                "confirm-loading": _vm.confirmLoading,
                width: "560px",
              },
              on: { ok: _vm.modelOk, cancel: _vm.modelCancel },
            },
            [
              _vm.visible
                ? _c(
                    "a-form-model",
                    {
                      ref: "ruleForm",
                      attrs: {
                        model: _vm.form,
                        "label-col": _vm.labelCol,
                        "wrapper-col": _vm.wrapperCol,
                        rules: _vm.rules,
                      },
                    },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          ref: "essTemplateId",
                          attrs: {
                            label: "模板id",
                            prop: "essTemplateId",
                            required: "",
                          },
                        },
                        [
                          _c("a-input", {
                            model: {
                              value: _vm.form.essTemplateId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "essTemplateId", $$v)
                              },
                              expression: "form.essTemplateId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "模板类型" } },
                        [
                          _c(
                            "a-select",
                            {
                              model: {
                                value: _vm.form.templateType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "templateType", $$v)
                                },
                                expression: "form.templateType",
                              },
                            },
                            [
                              _c(
                                "a-select-option",
                                { key: "0", attrs: { value: "0" } },
                                [_vm._v(" 其他 ")]
                              ),
                              _c(
                                "a-select-option",
                                { key: "1", attrs: { value: "1" } },
                                [_vm._v(" 入场合同 ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "电子签合作厂商" } },
                        [
                          _c(
                            "a-select",
                            {
                              model: {
                                value: _vm.form.fromType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "fromType", $$v)
                                },
                                expression: "form.fromType",
                              },
                            },
                            [
                              _c(
                                "a-select-option",
                                { key: "1", attrs: { value: 1 } },
                                [_vm._v(" 腾飞电子签 ")]
                              ),
                              _c(
                                "a-select-option",
                                { key: "2", attrs: { value: 2 } },
                                [_vm._v(" 法大大电子签 ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "状态" } },
                        [
                          _c(
                            "a-select",
                            {
                              model: {
                                value: _vm.form.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "status", $$v)
                                },
                                expression: "form.status",
                              },
                            },
                            [
                              _c(
                                "a-select-option",
                                { key: "0", attrs: { value: "0" } },
                                [_vm._v(" 未启用 ")]
                              ),
                              _c(
                                "a-select-option",
                                { key: "1", attrs: { value: "1" } },
                                [_vm._v(" 已启用 ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "自定义模板" } },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: { placeholder: "请选择关联的自定义模板" },
                              model: {
                                value: _vm.form.customTemplateId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "customTemplateId", $$v)
                                },
                                expression: "form.customTemplateId",
                              },
                            },
                            _vm._l(
                              _vm.customTemplateList,
                              function (item, index) {
                                return _c(
                                  "a-select-option",
                                  { key: index, attrs: { value: item.id } },
                                  [_vm._v(" " + _vm._s(item.title) + " ")]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "关联的其他模板" } },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                mode: "multiple",
                                placeholder: "请选择",
                              },
                              model: {
                                value: _vm.form.customTemplateIds,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "customTemplateIds", $$v)
                                },
                                expression: "form.customTemplateIds",
                              },
                            },
                            _vm._l(
                              _vm.customTemplateList,
                              function (item, index) {
                                return _c(
                                  "a-select-option",
                                  { key: index, attrs: { value: item.id } },
                                  [_vm._v(" " + _vm._s(item.title) + " ")]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.form.fromType == 2
                        ? _c(
                            "a-form-model-item",
                            { attrs: { label: "电子签模板" } },
                            [
                              _c(
                                "a-upload",
                                {
                                  attrs: {
                                    name: "file",
                                    multiple: false,
                                    beforeUpload: _vm.beforeUpload,
                                    accept: _vm.accept,
                                    customRequest: _vm.resourceUpload,
                                    "file-list": _vm.defaultFileList,
                                  },
                                },
                                [
                                  _c(
                                    "a-button",
                                    {
                                      staticStyle: { "margin-left": "10px" },
                                      attrs: { type: "primary" },
                                    },
                                    [_vm._v("上传模板文件")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("sigle-location", {
        attrs: {
          essTempl: _vm.sigleLocation.obj,
          visible: _vm.sigleLocation.visible,
        },
        on: { close: _vm.sigleLocationColse, submit: _vm.submitSigleLocation },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }