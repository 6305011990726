<template>
  <a-layout-sider :theme="sideTheme" :class="['side-menu', 'beauty-scroll', isMobile ? null : 'shadow']" width="256px" :collapsible="collapsible" v-model="collapsed" :trigger="null">
    <div :class="['logo', theme]">
      <router-link :to="user.loginType == 1 ? '/staff_lib_home/homePage' : '/staff_lib_home/homePage1'">
        <img v-if="isSafe" src="@/assets/img/logo3.png">
        <img v-if="isLaowu" src="@/assets/img/logo.png">
        <!-- 市政 logo -->
        <img v-if="isShiZheng" :style="{ width: collapsed ? '35px' : '90px' }" :src="collapsed ? shizhengLogo : shizhengLogoFull">
        <h1 v-if="!collapsed">{{ systemSideName }}</h1>
      </router-link>

    </div>
    <i-menu :theme="theme" :collapsed="collapsed" :options="menuData" @select="onSelect" class="menu"/>
  </a-layout-sider>
</template>

<script>
import IMenu from './menu'
import {mapState, mapGetters} from 'vuex'
import { isLaowu, isSafe, isShiZheng } from '@/utils/system.js'
import shizhengLogo from '@/assets/img/logo-shizheng.png'
import shizhengLogoFull from '@/assets/img/logo-shizheng-full.png'

export default {
  name: 'SideMenu',
  components: {IMenu},
  props: {
    collapsible: {
      type: Boolean,
      required: false,
      default: false
    },
    collapsed: {
      type: Boolean,
      required: false,
      default: false
    },
    menuData: {
      type: Array,
      required: true
    },
    theme: {
      type: String,
      required: false,
      default: 'dark'
    }
  },
  computed: {
    sideTheme() {
      return this.theme == 'light' ? this.theme : 'dark'
    },
    logo(){
      return require('@/assets/img/logo.png')
    },
    logo3(){
      return require('@/assets/img/logo3.png')
    },
    ...mapState('setting', ['isMobile', 'systemType', 'systemSideName']),
    ...mapGetters('account', ['user']),
  },
  data() {
    return {
      isLaowu: isLaowu(),
      isSafe: isSafe(),
      isShiZheng: isShiZheng(),
      shizhengLogo,
      shizhengLogoFull
    }
  },
  methods: {
    onSelect (obj) {
      this.$emit('menuSelect', obj)
    }
  }
}
</script>

<style lang="less" scoped>
@import "index";
</style>
