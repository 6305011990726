<template>
  <div ref="drawer" class="modi-drawer">
    <a-drawer
      :width="900"
      :visible="visible"
      title="编辑安全责任管理"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose"
      :getContainer="() => $refs.drawer"
      :headerStyle="{ textAlign: 'center' }"
    >
      <a-form
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
        layout="horizontal"
      >
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item
              label="项目名称"
              :labelCol="{ span: 2 }"
              :wrapper-col="{ span: 22 }"
            >
              <a-input
                :disabled="true"
                v-decorator="[
                  'projectName',
                  { rules: [{ required: false, message: '' }] },
                ]"
                placeholder="项目名称"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item
              label="所属单位"
              :labelCol="{ span: 2 }"
              :wrapper-col="{ span: 22 }"
            >
              <a-input
                :disabled="true"
                v-decorator="[
                  'childOfficeName',
                  { rules: [{ required: false, message: '' }] },
                ]"
                placeholder="工程管理部"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-collapse v-model="activeKey" expand-icon-position="right" accordion>
          <a-collapse-panel key="1" header="项目经理" v-if="user.loginType == 1 || (user.id == obj.userId && !canOperate)">
            <a-button
              v-if="!obj.xmleaderName"
              slot="extra"
              style="margin-top: -4px"
              @click="addXmLeader"
              >添加经理</a-button
            >
            <!-- <span style="margin-left: 15px"
                >姓名: {{ obj.xmleaderName }}</span
              >
              <span style="margin-left: 15px"
                >电话: {{ obj.xmleaderPhone }}</span
              >
              <span v-if="obj.appointDate" style="margin-left: 15px"
                >任命时间: {{ obj.appointDate }}</span
              > -->
            <template v-if="xmModalUser">
              <a-row>
                <a-col :span="24">
                  <a-form-item
                    label="请选择人员"
                    :labelCol="{ span: 6 }"
                    :wrapper-col="{ span: 6 }"
                  >
                    <a-select
                      v-model="changeUserVo.userId"
                      show-search
                      allowClear
                      placeholder="请输入或选择"
                      style="width: 200px"
                      option-filter-prop="children"
                      :filter-option="filterOption"
                      @change="handleUserChange"
                    >
                      <a-select-option
                        :key="item.id"
                        v-for="item in userList"
                        :value="item.id"
                        :title="item.name"
                      >
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="24">
                  <a-form-item
                    label="姓名"
                    :labelCol="{ span: 6 }"
                    :wrapper-col="{ span: 6 }"
                  >
                    <a-input
                      :disabled="true"
                      v-model="changeUserVo.userName"
                      placeholder="经理姓名"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="24">
                  <a-form-item
                    label="电话"
                    :labelCol="{ span: 6 }"
                    :wrapper-col="{ span: 6 }"
                  >
                    <a-input
                      :disabled="true"
                      v-model="changeUserVo.phone"
                      placeholder="经理电话"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="24">
                  <a-form-item
                    :allowClear="false"
                    label="项目经理任命时间"
                    :labelCol="{ span: 6 }"
                    :wrapper-col="{ span: 16 }"
                  >
                    <a-date-picker
                      @change="onChangeAdd"
                      placeholder="项目经理任命时间"
                      :defaultValue="
                        addAppointDate
                          ? moment(addAppointDate, 'YYYY-MM-DD')
                          : ''
                      "
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="6"></a-col>
                <a-col :span="18">
                  <a-button
                    @click="handleUserOk(1)"
                    type="primary"
                    style="margin-right: 10px; margin-bottom: 10px"
                    >确定添加</a-button
                  >
                  <a-button @click="userCancel">关 闭</a-button>
                </a-col>
              </a-row>
            </template>
            <template
              v-if="!xmModalUser && obj.xmleaderName && obj.xmleaderPhone"
            >
              <a-row :gutter="16">
                <a-col :span="24">
                  <a-form-item
                    :allowClear="false"
                    label="姓名"
                    :labelCol="{ span: 6 }"
                    :wrapper-col="{ span: 6 }"
                  >
                    <a-input
                      placeholder="项目经理姓名"
                      :disabled="true"
                      v-model="obj.xmleaderName"
                    />
                  </a-form-item>
                </a-col>

                <a-col :span="24">
                  <a-form-item
                    :allowClear="false"
                    label="电话"
                    :labelCol="{ span: 6 }"
                    :wrapper-col="{ span: 6 }"
                  >
                    <a-input
                      placeholder="项目经理电话"
                      :disabled="true"
                      v-model="obj.xmleaderPhone"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="24" v-if="obj.xmleaderPhone">
                  <a-form-item
                    :allowClear="false"
                    label="项目经理任命时间"
                    :labelCol="{ span: 6 }"
                    :wrapper-col="{ span: 16 }"
                  >
                    <a-date-picker
                      @change="onChangeXMJL"
                      placeholder="项目经理任命时间"
                      :defaultValue="
                        duty.appointDate
                          ? moment(duty.appointDate, 'YYYY-MM-DD')
                          : ''
                      "
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="24" v-if="obj.isVice || obj.xmleaderPhone && user.loginType == 1">
                  <a-form-item
                    :allowClear="false"
                    label="任命为项目副经理"
                    :labelCol="{ span: 6 }"
                    :wrapper-col="{ span: 16 }"
                  >
                    <a-checkbox
                      @change="onAppoint"
                      :checked="Boolean(obj.isVice)"
                    >
                      副经理
                    </a-checkbox>
                  </a-form-item>
                </a-col>
                <a-col :span="24">
                  <a-form-item label="安全生产管理目标责任书">
                    <div style="display: flex">
                      <div v-if="obj.safeManagerDuty" class="ant-upload-list-item">
                        <div class="ant-upload-list-item-info">
                          <template v-if="icludesPdf(obj.safeRms, 'pdf') == -1">
                            <img class="image-width" :src="obj.safeManagerDuty" />
                          </template>
                          <template v-else>
                            <a-icon
                              type="file-pdf"
                              :style="{ fontSize: '45px' }"
                              @click="showPdf(text)"
                            />
                          </template>
                          <div class="ant-upload-list-item-actions">
                            <a-icon
                              @click="onPreview('safeManagerDuty')"
                              class="anticon-eye-o"
                              type="eye"
                            />
                            <a-icon
                              @click="onDelete('safeManagerDuty')"
                              class="anticon-delete"
                              type="delete"
                            />
                          </div>
                        </div>
                      </div>
                      <a-upload
                        v-else
                        name="file"
                        :multiple="false"
                        :beforeUpload="beforeUpload"
                        accept=".pdf"
                        :customRequest="resourceUpload"
                        :file-list="fileList"
                        @change="handleChange"
                      >
                        <a-button @click="getName('safeManagerDuty')" type="primary"
                          >上传安全生产管理目标责任书</a-button
                        >
                      </a-upload>
                    </div>
                  </a-form-item>
                </a-col>
                <a-col :span="24">
                  <a-form-item label="安全生产第一责任人任命书">
                    <div style="display: flex">
                      <div v-if="obj.safeRms" class="ant-upload-list-item">
                        <div class="ant-upload-list-item-info">
                          <template v-if="icludesPdf(obj.safeRms, 'pdf') == -1">
                            <img class="image-width" :src="obj.safeRms" />
                          </template>
                          <template v-else>
                            <a-icon
                              type="file-pdf"
                              :style="{ fontSize: '45px' }"
                              @click="showPdf(text)"
                            />
                          </template>
                          <div class="ant-upload-list-item-actions">
                            <a-icon
                              @click="onPreview('safeRms')"
                              class="anticon-eye-o"
                              type="eye"
                            />
                            <a-icon
                              @click="onDelete('safeRms')"
                              class="anticon-delete"
                              type="delete"
                            />
                          </div>
                        </div>
                      </div>
                      <a-upload
                        v-else
                        name="file"
                        :multiple="false"
                        :beforeUpload="beforeUpload"
                        accept=".pdf"
                        :customRequest="resourceUpload"
                        :file-list="fileList"
                        @change="handleChange"
                      >
                        <a-button @click="getName('safeRms')" type="primary"
                          >上传安全生产第一责任人任命书（A4）</a-button
                        >
                      </a-upload>
                    </div>
                  </a-form-item>
                </a-col>
                <a-col :span="24">
                  <a-form-item label="安全生产第一责任人任命状">
                    <div style="display: flex">
                      <div v-if="obj.safeRmz" class="ant-upload-list-item">
                        <div class="ant-upload-list-item-info">
                          <template v-if="icludesPdf(obj.safeRmz, 'pdf') == -1">
                            <img class="image-width" :src="obj.safeRmz" />
                          </template>
                          <template v-else>
                            <a-icon
                              type="file-pdf"
                              :style="{ fontSize: '45px' }"
                            />
                          </template>
                          <div class="ant-upload-list-item-actions">
                            <a-icon
                              @click="onPreview('safeRmz')"
                              class="anticon-eye-o"
                              type="eye"
                            />
                            <a-icon
                              @click="onDelete('safeRmz')"
                              class="anticon-delete"
                              type="delete"
                            />
                          </div>
                        </div>
                      </div>
                      <a-upload
                        v-else
                        name="file"
                        :multiple="false"
                        :beforeUpload="beforeUpload"
                        accept=".pdf"
                        :customRequest="resourceUpload"
                        :file-list="fileList"
                        @change="handleChange"
                      >
                        <a-button @click="getName('safeRmz')" type="primary"
                          >上传安全生产第一责任人任命状（A3）</a-button
                        >
                      </a-upload>
                    </div>
                  </a-form-item>
                </a-col>
                <a-col :span="24">
                  <a-form-item label="消防安全责任人任命书">
                    <div style="display: flex">
                      <div v-if="obj.xfRms" class="ant-upload-list-item">
                        <div class="ant-upload-list-item-info">
                          <template v-if="icludesPdf(obj.xfRms, 'pdf') == -1">
                            <img class="image-width" :src="obj.xfRms" />
                          </template>
                          <template v-else>
                            <a-icon
                              type="file-pdf"
                              :style="{ fontSize: '45px' }"
                            />
                          </template>
                          <div class="ant-upload-list-item-actions">
                            <a-icon
                              @click="onPreview('xfRms')"
                              class="anticon-eye-o"
                              type="eye"
                            />
                            <a-icon
                              @click="onDelete('xfRms')"
                              class="anticon-delete"
                              type="delete"
                            />
                          </div>
                        </div>
                      </div>
                      <a-upload
                        v-else
                        name="file"
                        :multiple="false"
                        :beforeUpload="beforeUpload"
                        accept=".pdf"
                        :customRequest="resourceUpload"
                        :file-list="fileList"
                        @change="handleChange"
                      >
                        <a-button @click="getName('xfRms')" type="primary"
                          >上传消防安全责任人任命书（A4）</a-button
                        >
                      </a-upload>
                    </div>
                  </a-form-item>
                </a-col>
                <a-col :span="24">
                  <a-form-item label="消防安全责任人任命状">
                    <div style="display: flex">
                      <div v-if="obj.xfRmz" class="ant-upload-list-item">
                        <div class="ant-upload-list-item-info">
                          <template v-if="icludesPdf(obj.xfRmz, 'pdf') == -1">
                            <img class="image-width" :src="obj.xfRmz" />
                          </template>
                          <template v-else>
                            <a-icon
                              type="file-pdf"
                              :style="{ fontSize: '45px' }"
                            />
                          </template>
                          <div class="ant-upload-list-item-actions">
                            <a-icon
                              @click="onPreview('xfRmz')"
                              class="anticon-eye-o"
                              type="eye"
                            />
                            <a-icon
                              @click="onDelete('xfRmz')"
                              class="anticon-delete"
                              type="delete"
                            />
                          </div>
                        </div>
                      </div>
                      <a-upload
                        v-else
                        name="file"
                        :multiple="false"
                        :beforeUpload="beforeUpload"
                        accept=".pdf"
                        :customRequest="resourceUpload"
                        :file-list="fileList"
                        @change="handleChange"
                      >
                        <a-button @click="getName('xfRmz')" type="primary"
                          >上传消防安全责任人任命状（A3）</a-button
                        >
                      </a-upload>
                    </div>
                  </a-form-item>
                </a-col>
                <a-col :span="24">
                  <a-form-item label="安全生产承诺书">
                    <div style="display: flex">
                      <div v-if="obj.scCns" class="ant-upload-list-item">
                        <div class="ant-upload-list-item-info">
                          <template v-if="icludesPdf(obj.scCns, 'pdf') == -1">
                            <img class="image-width" :src="obj.scCns" />
                          </template>
                          <template v-else>
                            <a-icon
                              type="file-pdf"
                              :style="{ fontSize: '45px' }"
                            />
                          </template>
                          <div class="ant-upload-list-item-actions">
                            <a-icon
                              @click="onPreview('scCns')"
                              class="anticon-eye-o"
                              type="eye"
                            />
                            <a-icon
                              @click="onDelete('scCns')"
                              class="anticon-delete"
                              type="delete"
                            />
                          </div>
                        </div>
                      </div>
                      <a-upload
                        v-else
                        name="file"
                        :multiple="false"
                        :beforeUpload="beforeUpload"
                        accept=".pdf"
                        :customRequest="resourceUpload"
                        :file-list="fileList"
                        @change="handleChange"
                      >
                        <a-button @click="getName('scCns')" type="primary"
                          >上传安全生产承诺书</a-button
                        >
                      </a-upload>
                    </div>
                  </a-form-item>
                </a-col>
                <a-col :span="24">
                  <a-form-item label="安全生产责任书">
                    <div style="display: flex">
                      <div v-if="obj.scZrs" class="ant-upload-list-item">
                        <div class="ant-upload-list-item-info">
                          <template v-if="icludesPdf(obj.scZrs, 'pdf') == -1">
                            <img class="image-width" :src="obj.scZrs" />
                          </template>
                          <template v-else>
                            <a-icon
                              type="file-pdf"
                              :style="{ fontSize: '45px' }"
                            />
                          </template>
                          <div class="ant-upload-list-item-actions">
                            <a-icon
                              @click="onPreview('scZrs')"
                              class="anticon-eye-o"
                              type="eye"
                            />
                            <a-icon
                              @click="onDelete('scZrs')"
                              class="anticon-delete"
                              type="delete"
                            />
                          </div>
                        </div>
                      </div>
                      <a-upload
                        v-else
                        name="file"
                        :multiple="false"
                        :beforeUpload="beforeUpload"
                        accept=".pdf"
                        :customRequest="resourceUpload"
                        :file-list="fileList"
                        @change="handleChange"
                      >
                        <a-button @click="getName('scZrs')" type="primary"
                          >上传安全生产责任书</a-button
                        >
                      </a-upload>
                    </div>
                  </a-form-item>
                </a-col>
                <a-col :span="21"></a-col>
                <a-col :span="3">
                  <a-button type="danger" v-if="user.loginType == 1" @click="delAqy(obj, 1)"
                    >删除经理</a-button
                  >
                </a-col>
              </a-row>
            </template>
          </a-collapse-panel>
          <!-- :header="'专职安全员' + `${index + 1}`" -->
          <!-- v-for="(item, index) in obj.detailList"
            :key="index + 2 + ''" -->
          <a-collapse-panel key="2" :header="'专职安全员'" v-if="user.loginType == 1 || canOperate">
            <!--  v-if="obj?.detailList.length < 3" -->
            <a-button
              v-if="obj.detailList.length < 3 && user.loginType == 1"
              slot="extra"
              style="margin-top: -4px"
              @click="addAqyMotal(3)"
              >添加安全员</a-button
            >

            <!-- <a-button
              type="danger"
              v-if="obj.detailList.length"
              slot="extra"
              style="margin-top: -4px;margin-left: 10px;"
              @click="delAqy(item)"
              >删除安全员</a-button
            > -->
            <!-- <div slot="header" style="text-align: center">
              <h3 style="font-size: 18px; font-weight: bold">
                专职安全员
              </h3>
              <span style="margin-left: 15px">姓名: {{ item.userName }}</span>
              <span style="margin-left: 15px">电话: {{ item.userPhone }}</span>
              <span v-if="item.appointDate" style="margin-left: 15px"
                >任命时间: {{ item.appointDate }}</span
              >
            </div> -->
            <!-- 专项安全员 -->
            <a-row
              style="
                border-bottom: 1px solid #d9d9d9;
                padding: 0px 10px 10px 10px;
                margin: 20px 10px 10px 10px;
              "
              v-for="(item, index) in obj.detailList"
              :key="index + 2 + ''"
            >
              <template v-if="modalUser && index == 0">
                <a-col :span="24">
                  <a-form-item
                    label="请选择人员"
                    :labelCol="{ span: 6 }"
                    :wrapper-col="{ span: 6 }"
                  >
                    <a-select
                      v-model="changeUserVo.userId"
                      show-search
                      allowClear
                      placeholder="请输入或选择"
                      style="width: 200px"
                      option-filter-prop="children"
                      :filter-option="filterOption"
                      @change="handleUserChange"
                    >
                      <a-select-option
                        :key="item.id"
                        v-for="item in userList"
                        :value="item.id"
                        :title="item.name"
                      >
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="24">
                  <a-form-item
                    label="姓名"
                    :labelCol="{ span: 6 }"
                    :wrapper-col="{ span: 6 }"
                  >
                    <a-input
                      :disabled="true"
                      v-model="changeUserVo.userName"
                      placeholder="安全员姓名"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="24">
                  <a-form-item
                    label="电话"
                    :labelCol="{ span: 6 }"
                    :wrapper-col="{ span: 6 }"
                  >
                    <a-input
                      :disabled="true"
                      v-model="changeUserVo.phone"
                      placeholder="电话"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="24">
                  <a-form-item
                    :allowClear="false"
                    label="安全员任命时间"
                    :labelCol="{ span: 6 }"
                    :wrapper-col="{ span: 16 }"
                  >
                    <a-date-picker
                      @change="onChangeAdd"
                      placeholder="安全员任命时间"
                      :defaultValue="
                        addAppointDate
                          ? moment(addAppointDate, 'YYYY-MM-DD')
                          : ''
                      "
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="6"></a-col>
                <a-col :span="18">
                  <a-button
                    @click="handleUserOk"
                    type="primary"
                    style="margin-right: 10px; margin-bottom: 10px"
                    >确定添加</a-button
                  >
                  <a-button @click="userCancel">关 闭</a-button>
                </a-col>
              </template>
              <template v-if="!modalUser || item.userName">
                <template v-if="user.loginType == 1 || user.id == item.userId">
                
                <a-col :span="24">
                  <a-form-item
                    label=""
                    :labelCol="{ span: 0 }"
                    :wrapper-col="{ span: 12 }"
                  >
                    <div
                      style="
                        text-align: center;
                        font-size: 18px;
                        font-weight: bold;
                      "
                    >
                      专项安全员{{ index + 1 }}
                    </div>
                  </a-form-item>
                </a-col>
                <a-col :span="24">
                  <a-form-item
                    :allowClear="false"
                    label="姓名"
                    :labelCol="{ span: 6 }"
                    :wrapper-col="{ span: 6 }"
                  >
                    <a-input
                      :disabled="true"
                      :value="item.userName"
                      placeholder="安全员姓名"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="24">
                  <a-form-item
                    :allowClear="false"
                    label="电话"
                    :labelCol="{ span: 6 }"
                    :wrapper-col="{ span: 6 }"
                  >
                    <a-input
                      :disabled="true"
                      :value="item.userPhone"
                      placeholder="安全员电话"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="24">
                  <a-form-item
                    label="任命时间"
                    :labelCol="{ span: 6 }"
                    :wrapper-col="{ span: 16 }"
                  >
                    <a-date-picker
                      :allowClear="false"
                      @change="onChange"
                      @openChange="handleStartOpenChange(index)"
                      placeholder="请选择任命时间"
                      :defaultValue="
                        item.appointDate
                          ? moment(item.appointDate, 'YYYY-MM-DD')
                          : ''
                      "
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="24">
                  <a-form-item label="专职安全员任命书">
                    <div style="display: flex">
                      <div v-if="item.zzrmsSafe" class="ant-upload-list-item">
                        <div class="ant-upload-list-item-info">
                          <template
                            v-if="icludesPdf(item.zzrmsSafe, 'pdf') == -1"
                          >
                            <img class="image-width" :src="item.zzrmsSafe" />
                          </template>
                          <template v-else>
                            <a-icon
                              type="file-pdf"
                              :style="{ fontSize: '45px' }"
                            />
                          </template>
                          <div class="ant-upload-list-item-actions">
                            <a-icon
                              @click="onPreview1('zzrmsSafe', index)"
                              class="anticon-eye-o"
                              type="eye"
                            />
                            <a-icon
                              @click="
                                onDelete1('zzrmsSafe', index, 'aqzrsSafe')
                              "
                              class="anticon-delete"
                              type="delete"
                            />
                          </div>
                        </div>
                      </div>
                      <a-upload
                        v-else
                        name="file"
                        :multiple="false"
                        :beforeUpload="beforeUpload"
                        accept=".pdf"
                        :customRequest="resourceUpload1"
                        :file-list="fileList"
                        @change="handleChange"
                      >
                        <a-button
                          @click="getName1('zzrmsSafe', index, 'aqzrsSafe')"
                          type="primary"
                          >上传专职安全员任命书</a-button
                        >
                      </a-upload>
                    </div>
                  </a-form-item>
                </a-col>
                <a-col :span="24">
                  <a-form-item label="安全责任书">
                    <div style="display: flex">
                      <div v-if="item.aqzrsSafe" class="ant-upload-list-item">
                        <div class="ant-upload-list-item-info">
                          <template
                            v-if="icludesPdf(item.aqzrsSafe, 'pdf') == -1"
                          >
                            <img class="image-width" :src="item.aqzrsSafe" />
                          </template>
                          <template v-else>
                            <a-icon
                              type="file-pdf"
                              :style="{ fontSize: '45px' }"
                            />
                          </template>
                          <div class="ant-upload-list-item-actions">
                            <a-icon
                              @click="onPreview1('aqzrsSafe', index)"
                              class="anticon-eye-o"
                              type="eye"
                            />
                            <a-icon
                              @click="
                                onDelete1('aqzrsSafe', index, 'zzrmsSafe')
                              "
                              class="anticon-delete"
                              type="delete"
                            />
                          </div>
                        </div>
                      </div>
                      <a-upload
                        v-else
                        name="file"
                        :multiple="false"
                        :beforeUpload="beforeUpload"
                        accept=".pdf"
                        :customRequest="resourceUpload1"
                        :file-list="fileList"
                        @change="handleChange"
                      >
                        <a-button
                          @click="getName1('aqzrsSafe', index, 'zzrmsSafe')"
                          type="primary"
                          >上传安全责任书</a-button
                        >
                      </a-upload>
                    </div>
                  </a-form-item>
                </a-col>
                <!-- v-if="obj.detailList.length" -->
                <a-col :span="21"></a-col>
              </template>
                <a-col :span="3">
                  <a-button
                    v-if="obj.detailList && user.loginType == 1"
                    type="danger"
                    @click="delAqy(item)"
                    >删除安全员</a-button
                  >
                </a-col>
              </template>
            </a-row>
          </a-collapse-panel>
        </a-collapse>
        <!-- <a-modal :visible="modalUser" @ok="handleUserOk" @cancel="userCancel">
          <a-form-item label="请选择人员">
            <a-select
              v-model="userId"
              show-search
              allowClear
              placeholder="请输入或选择"
              style="width: 200px"
              option-filter-prop="children"
              :filter-option="filterOption"
              @change="handleUserChange"
            >
              <a-select-option
                :key="item.id"
                v-for="item in userList"
                :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="姓名">
            <a-input
              :disabled="true"
              v-model="changeUserVo.userName"
              placeholder="安全员姓名"
            />
          </a-form-item>
          <a-form-item label="电话">
            <a-input
              :disabled="true"
              v-model="changeUserVo.phone"
              placeholder="电话"
            />
          </a-form-item>
        </a-modal> -->
      </a-form>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <!-- <a-button
          :style="{ marginLeft: '8px' }"
          type="primary"
          @click="doSubmit"
        >
          确定
        </a-button> -->
        <a-button :style="{ marginLeft: '8px' }" @click="onClose">
          关闭
        </a-button>
      </div>
      <a-modal :visible="modalVisible" :footer="null" @cancel="handleCancel">
        <img alt="example" style="width: 100%" :src="previewImage" />
      </a-modal>
    </a-drawer>
  </div>
</template>

<script>
import { request } from "@/utils/request";
import { formatDate } from "@/filters/formatDate.js";
import {
  ATTACHMENT_UPLOAD,
  UPDATE_SAFEDUTY,
  UPDATE_PROJECT_SAFEDUTY_DETAIL,
  ADD_SAFE_AQYUSER,
  SAFE_PROJECTS_DEL,
  GET_STFE_FIND_USER,
  ADD_SAFE_DUTY_XMJL,
  DEL_SAFE_DUTY_XMJL,
} from "@/services/api";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "SuijianClientModifyduty",

  data() {
    return {
      form: this.$form.createForm(this),
      fileList: [],
      iconLoading: false,
      isPublic: 0,
      isThreeEdu: 0,
      filePath: null,
      fileList1: [],
      filePath1: null,
      iconLoading1: false,
      obj: {
        detailList: [],
      },
      name: null,
      modalVisible: false,
      previewImage: "",
      aqyIndex: 0,
      nameAqy: null, // 更新时，需要另一外一个安全责任书的名字
      size: "default",
      activeKey: ["1"],
      dateFormat: "YYYY-MM-DD",
      setAqyTimeIndex: 0, // 设置专职安全员的任命时间index
      isxmLeade: false,
      isaqy: false,
      appointOpen: false, // 副项目经理
      modalUser: false,
      changeUserVo: {
        userId: undefined,
        userName: undefined,
        phone: undefined,
        roleType: 3, // 3是新增安全员
      },
      addAppointDate:'', // 新增任命时间
      aqyIndexList: 0,
      xmModalUser: false, // 添加项目经理选项
      setSafeRoles: ['PROJECT_AQY_ZB_FZR'], // 项目安全责任编辑权限
      accept: '.pdf,.PDF'
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    duty: {
      type: Object,
      require: true,
    },
    userList: {
      type: Array,
      require: false,
    },
  },
  created() {},
  mounted() {},
  watch: {
    visible: {
      handler(val, oldName) {
        if (val || this.duty.id) {
          this.$nextTick(() => {
            this.obj = this.duty;
            console.log(this.duty, "this.duty");
            this.obj.detailList = this.duty.detailList;
            this.form.setFieldsValue({
              projectName: this.obj.projectName,
              childOfficeName: this.obj.childOfficeName,
            });
          });
        } else {
          this.form.resetFields();
          this.obj = {
            detailList: [],
          };
        }
      },
    },
    // activeKey(key) {
    //   if (key == 2) {
    //     this.setAqyTimeIndex = 0;
    //   } else if (key == 3) {
    //     this.setAqyTimeIndex = 1;
    //   }
    // },
  },
  computed: {
    setTitle: function () {
      return (
        "项目: " +
        this.obj.projectName +
        "所属单位: " +
        this.obj.childOfficeName
      );
    },
    ...mapGetters("account", ["user"]),
    canOperate() { // 安全员权限
      return this.user.nowRoleKey.some(role => this.setSafeRoles.includes(role));
    },
  },
  methods: {
    moment,
    handleChange(info) {},
    onClose() {
      this.$emit("coursePageClose");
      this.activeKey = ["1"];
      this.obj.detailList = [];
      this.modalUser = false;
      this.xmModalUser = false;
      this.addAppointDate = ''
    },
    /**
     * 上传之前
     */
    beforeUpload(file) {
      // const isFiveM= file.size / 1024 / 1024 < 10 //小于5M的图片上传
      // //判断文件是否符合正则表达式的规则
      // if (!isFiveM) {
      //   this.$message.error('上传资源不大于10M！');
      //   return reject(false)
      // }else{
      // 限制只能传图片
      // let img = file.name.substring(file.name.lastIndexOf(".") + 1);
      // const suffix = img === "jpg";
      // const suffix2 = img === "png";
      // const suffix3 = img === "jpeg";
      // if (!suffix && !suffix2 && !suffix3) {
      //   this.$message.error("只能上传图片！");
      //   return false;
      // }
      // return suffix || suffix2 || suffix3;
      // }
      // 限制只能上传pdf
      // let pdf = file.name.substring(file.name.lastIndexOf(".") + 1);
      // // const suffix = pdf === ("pdf" || "PDF");
      // const suffix = this. 
      // if (!suffix) {
      //   this.$message.error("限制只能上传PDF文件！");
      //   return false;
      // }
      // return suffix;
      // 限制只能上传pdf
      const accept = '.' + file.name.substring(file.name.lastIndexOf(".") + 1);
        if (this.accept.split(',').indexOf(accept) == -1) {
            this.$message.error("只能上传PDF文件");
            return false
        }
    },
    /**
     * 资源上传
     */
    resourceUpload(value) {
      this.iconLoading = true;
      const formData = new FormData();
      formData.append("file", value.file);
      request(ATTACHMENT_UPLOAD, "post", formData).then((result) => {
        if (result.data.code === 0) {
          let time = new Date().getTime();
          let date = formatDate(time, "yyyy-MM-dd");
          let params = {
            ...this.obj,
            [this.name]: result.data.data.path,
            // appointDate: date,
          };
          value.onSuccess(result, value.file);
          request(UPDATE_SAFEDUTY, "post", params).then((res) => {
            this.$message.success("上传修改成功");
            this.name = null;
            this.obj = res.data.data;
          });
        } else {
          this.$message.warning(result.message);
        }
        this.iconLoading = false;
      });
    },
    resourceUpload1(value) {
      this.iconLoading = true;
      const formData = new FormData();
      formData.append("file", value.file);
      request(ATTACHMENT_UPLOAD, "post", formData).then((result) => {
        if (result.data.code === 0) {
          let params = {
            id: this.obj.detailList[this.aqyIndex].id,
            [this.name]: result.data.data.path,
            [this.nameAqy]: this.obj.detailList[this.aqyIndex][this.nameAqy],
          };
          value.onSuccess(result, value.file);
          request(UPDATE_PROJECT_SAFEDUTY_DETAIL, "post", params).then(
            (res) => {
              this.$message.success("上传修改成功");
              this.obj.detailList[this.aqyIndex][this.name] =
                res.data.data[this.name];
              // 设置修改时间
              this.name = null;
            }
          );
        } else {
          this.$message.warning(result.message);
        }
        this.iconLoading = false;
      });
    },
    doSubmit() {
      //数据校验
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$emit("dutySubmit");
          this.activeKey = ["1"];
        }
      });
    },
    getName(name) {
      this.name = name;
    },
    getName1(name, index, nameAqy) {
      this.name = name;
      this.nameAqy = nameAqy;
      this.aqyIndex = index;
    },
    onDelete(val, index) {
      let _that = this;
      this.$confirm({
        title: "提醒",
        content: "确认删除吗?",
        okText: "确认",
        okType: "danger",
        cancelText: "再想想",
        onOk() {
          let params = {
            ..._that.obj,
            [val]: null,
          };
          request(UPDATE_SAFEDUTY, "post", params).then((res) => {
            if (res.data.code === 0) {
              _that.$message.success("删除成功！");
              _that.obj = params;
            } else {
              _that.$message.error("删除异常！");
            }
          });
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    onDelete1(val, index, nameAqy) {
      let _that = this;
      this.$confirm({
        title: "提醒",
        content: "确认删除吗?",
        okText: "确认",
        okType: "danger",
        cancelText: "再想想",
        onOk() {
          let params = {
            id: _that.obj.detailList[index].id,
            [val]: null,
            [nameAqy]: _that.obj.detailList[index][nameAqy],
          };
          request(UPDATE_PROJECT_SAFEDUTY_DETAIL, "post", params).then(
            (res) => {
              if (res.data.code === 0) {
                _that.$message.success("删除成功！");
                _that.obj.detailList[index][val] = res[val];
              } else {
                _that.$message.error("删除异常！");
              }
            }
          );
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    onChangeXMJL(date, dateString) {
      // 设置项目经理任命时间的方法
      let params = {
        ...this.obj,
        appointDate: dateString,
      };
      request(UPDATE_SAFEDUTY, "post", params).then((res) => {
        this.$message.success("修改项目经理任命时间成功");
        this.obj = res.data.data;
        this.duty.appointDate = dateString;
      });
    },
    handleStartOpenChange(index) {
      // 获取打开该折叠面板的数据
      this.setAqyTimeIndex = index;
    },
    onChangeAdd(date, dateString) {
      this.addAppointDate = dateString // 设置任命时间
    },
    onChange(date, dateString) {
      // 设置专职安全员任命时间的方法
      let _that = this;
      let index = this.setAqyTimeIndex;
      let params = {
        id: _that.obj.detailList[index].id,
        aqzrsSafe: _that.obj.detailList[index].aqzrsSafe,
        zzrmsSafe: _that.obj.detailList[index].zzrmsSafe,
        appointDate: dateString, // 设置任命时间
      };
      request(UPDATE_PROJECT_SAFEDUTY_DETAIL, "post", params).then((res) => {
        if (res.data.code === 0) {
          _that.$message.success("修改任命时间成功！");
          _that.obj.detailList[index].appointDate = res.data.data.appointDate;
          _that.setAqyTimeIndex = 0; // 恢复index默认值
        } else {
          _that.$message.error("删除异常！");
        }
      });
    },

    onPreview(val) {
      let url = this.obj[val];
      let isimg = this.icludesPdf(url, "pdf") == -1;
      if (isimg) {
        // 打开图片
        this.showImg(url);
      } else {
        // 打开pdf
        window.open(url);
      }
    },
    onPreview1(val, index) {
      let url = this.obj.detailList[index][val];
      let isimg = this.icludesPdf(url, "pdf") == -1;
      if (isimg) {
        // 打开图片
        this.showImg(url);
      } else {
        // 打开pdf
        window.open(url);
      }
    },
    // 判断是pdf还是图片
    icludesPdf(url, str) {
      if (url) {
        return url.toLowerCase().indexOf(str);
      }
    },
    // 图片预览
    showImg(imgPath) {
      this.modalVisible = true;
      this.previewImage = imgPath;
    },
    handleCancel() {
      this.modalVisible = false;
    },
    // 是否任命副经理
    onAppoint(e) {
      this.appointOpen = Number(e.target.checked);
      // 设置项目经理任命时间的方法
      let params = {
        ...this.obj,
        isVice: this.appointOpen,
      };
      request(UPDATE_SAFEDUTY, "post", params).then((res) => {
        this.$message.success("修改成功");
        this.obj = res.data.data;
        // this.duty.appointDate = dateString;
      });
    },
    // 新增安全员
    handleUserOk(xmType) {
      let _that = this;
      // 判断安全员是否已在该项目履职
      let isAddOk = this.duty.detailList.some(
        (item) => item.userId == this.changeUserVo.userId
      );
      let now = moment().format("YYYY-MM-DD"); // 当前时间
      // 新增项目经理
      if (xmType == 1) {
        let params = {
          xmleaderName: this.changeUserVo.userName,
          xmleaderPhone: this.changeUserVo.phone,
          appointDate: this.addAppointDate, // 任命时间
          id: _that.obj.id,
          projectId: _that.obj.projectId,
          projectName: _that.obj.projectName,
          xmjlUserId: this.changeUserVo.userId,
          // userId: _that.obj.userId,
          userId: this.changeUserVo.userId,
        };
        request(ADD_SAFE_DUTY_XMJL, "post", params).then((res) => {
          if (res.data.code === 0) {
            _that.$message.success("新增项目经理成功！");
            _that.$emit("doEdit", _that.obj, true);
            _that.activeKey = ["2"];
          }
        });
      } else if (this.changeUserVo) {
        // 新增专项安全员
        request(ADD_SAFE_AQYUSER, "post", this.changeUserVo).then((res) => {
          if (res.data.code === 0) {
            if (isAddOk) {
              return (
                _that.$message.error(res.data.msg),
                _that.$emit("doEdit", _that.obj, true)
              );
            }
            _that.$message.success("新增安全员成功！");
            _that.$emit("doEdit", _that.obj, true);
            _that.activeKey = ["2"];
            let id = res.data.data.id;
            // 新增安全员时 增加任命时间
            setTimeout(() => {
              if (this.duty.detailList) {
                this.duty.detailList.map((item, index) => {
                  if (item.id == id) {
                    this.setAqyTimeIndex = index;
                    let params = {
                      id: item.id,
                       // appointDate: now, // 设置任命时间
                       appointDate: this.addAppointDate, // 任命时间
                    };
                    request(
                      UPDATE_PROJECT_SAFEDUTY_DETAIL,
                      "post",
                      params
                    ).then((res) => {
                      if (res.data.code === 0) {
                        this.obj.detailList[index].appointDate =
                          res.data.data.appointDate;
                        _that.setAqyTimeIndex = 0; // 恢复index默认值
                      } else {
                        _that.$message.error("任命安全员时间失败！");
                      }
                    });
                  }
                });
              }
              this.$forceUpdate();
            }, 500);
          } else {
            _that.$message.error(res.data.msg);
          }
        });
      }
      // 成功与否  都关闭选择人员模态框 清除数据
      this.changeUserVo = {
        userId: undefined,
        userName: undefined,
        phone: undefined,
        roleType: 3, // 3是新增安全员
      };
      this.modalUser = false;
      this.xmModalUser = false;
    },
    // 添加项目经理
    addXmLeader() {
      this.xmModalUser = true;
      this.activeKey = ["2"];
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },

    addAqyMotal(roleType) {
      this.modalUser = true;
      this.changeUserVo.roleType = roleType;
      let item = {
        userName: undefined,
        phone: undefined,
      };
      this.obj.detailList.unshift(item);
      this.activeKey = ["1"];
    },
    userCancel() {
      // 关闭选择人员模态框
      this.changeUserVo = {
        userId: undefined,
        userName: undefined,
        phone: undefined,
        roleType: 3, // 3是新增安全员
      };
      this.addAppointDate = ''
      this.modalUser = false;
      this.xmModalUser = false;
      this.$emit("doEdit", this.obj, true);
    },
    handleUserChange(value) {
      let objUser = this.userList.filter((item) => item.id == value);
      if (objUser) {
        let changeUserVo = {
          userId: objUser[0].id,
          userName: objUser[0].name,
          phone: objUser[0].phone,
          roleType: this.changeUserVo.roleType, // 3是新增安全员
          projectId: this.obj.projectId,
          // oldUserId: this.obj.
        };
        this.changeUserVo = changeUserVo;
      }
    },
    // 删除安全员
    delAqy(item, index) {
      let _that = this;
      if (index == 1) {
        let params = {
          id: _that.obj.id,
          projectId: _that.obj.projectId,
          userId: item.userId,
        };
        this.$confirm({
          title: "提醒",
          content: `确认删除 ${_that.obj.xmleaderName} 项目经理履职吗?`,
          okText: "确认",
          okType: "danger",
          cancelText: "关闭",
          onOk() {
            request(DEL_SAFE_DUTY_XMJL, "post", params).then((res) => {
              if (res.data.code === 0) {
                _that.$message.success("删除成功！");
                // _that.obj.detailList[index][val] = res[val];
                _that.$emit("doEdit", _that.obj, true);
              } else {
                _that.$message.error("删除异常！");
              }
            });
          },
          onCancel() {
            console.log("Cancel");
          },
        });
      } else {
        this.$confirm({
          title: "提醒",
          content: `确认删除 ${item.userName} 专项安全员履职吗?`,
          okText: "确认",
          okType: "danger",
          cancelText: "关闭",
          onOk() {
            request(SAFE_PROJECTS_DEL + item.id, "delete", {}).then((res) => {
              if (res.data.code === 0) {
                _that.$message.success("删除成功！");
                // _that.obj.detailList[index][val] = res[val];
                _that.$emit("doEdit", _that.obj, true);
              } else {
                _that.$message.error("删除异常！");
              }
            });
          },
          onCancel() {
            console.log("Cancel");
          },
        });
      }
    },
    // 安全责任筛选责任人数组user
    getStafFindUser() {
      request(GET_STFE_FIND_USER, "post", {}).then((res) => {
        this.userList = res.data.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.ant-upload-list-item-actions {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  white-space: nowrap;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s;
  .anticon-delete,
  .anticon-eye-o {
    z-index: 10;
    width: 16px;
    margin: 0 4px;
    color: hsla(0, 0%, 100%, 0.85);
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s;
  }
  .anticon-delete:hover,
  .anticon-eye-o:hover {
    color: #fff;
  }
}

.modi-drawer /deep/ .ant-collapse-header {
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  position: relative;
  .ant-collapse-extra {
    font-size: 14px;
    position: absolute;
    right: 50px;
    top: 10px;
  }
}
.ant-upload-list-item {
  position: relative;
  height: 100%;
  margin-top: 0px;
  font-size: 14px;
}
.ant-upload-list-item-actions:hover {
  opacity: 1;
}
.ant-upload-list-item:hover .ant-upload-list-item-info:before {
  opacity: 1;
}

.ant-upload-list-item-info {
  position: relative;
  height: 100%;
  overflow: hidden;
  padding: 0;
  transition: background-color 0.3s;
}
.ant-upload-list-item-info:before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.3s;
  content: " ";
}
.image-width {
  width: 80px;
  height: 80px;
}
</style>
