<template>
  <a-card class="risk_container" title="风控管理" :bordered="false">
    <a-descriptions bordered :column="1" class="e-descriptions">
      <a-descriptions-item>
        <template v-slot:label>
          <div>年龄限制规则：</div>
        </template>
        <div class="risk-flex">
          <div class="risk-rules">
            <div>
              <a-checkbox
                ><span class="text-color"
                  >童工年龄限制，童工指未满
                </span></a-checkbox
              >
              <a-input default-value="18" style="width: 30%">
                <a-tooltip slot="suffix">
                  <span class="text-color">周岁</span>
                </a-tooltip>
              </a-input>
            </div>
            <div>
              <a-checkbox
                ><span class="text-color"
                  >男性退休年龄限制，退休年龄为
                </span></a-checkbox
              >
              <a-input default-value="65" style="width: 30%">
                <a-tooltip slot="suffix">
                  <span class="text-color">周岁</span>
                </a-tooltip>
              </a-input>
            </div>
            <div>
              <a-checkbox
                ><span class="text-color"
                  >女性退休年龄限制，退休年龄为
                </span></a-checkbox
              >
              <a-input default-value="60" style="width: 30%">
                <a-tooltip slot="suffix">
                  <span class="text-color">周岁</span>
                </a-tooltip>
              </a-input>
            </div>
          </div>
          <div class="ant-descriptions-item-label risk-switch">
            <div>
              <a-switch
                checked-children="开启"
                un-checked-children="关闭"
                default-checked
              />
            </div>
          </div>
        </div>
      </a-descriptions-item>
      <a-descriptions-item>
        <template v-slot:label>
          <div>民族限制规则：</div>
        </template>
        <div class="risk-flex">
          <div class="risk-rules">
            <div>
              <a-select
              placeholder="请选择民族"
                style="width:200px"
                @change="handlSelect($event)"
                allowClear
              >
                <a-select-option value="">请选择民族</a-select-option>
                <a-select-option
                  v-for="items of nation"
                  :key="items"
                  :value="items"
                >
                  {{ items }}
                </a-select-option>
              </a-select>
            </div>
          </div>
          <div class="ant-descriptions-item-label risk-switch">
            <div>
              <a-switch
                checked-children="开启"
                un-checked-children="关闭"
                default-checked
              />
            </div>
          </div>
        </div>
      </a-descriptions-item>
      <a-descriptions-item>
        <template v-slot:label>
          <div>地区限制规则：</div>
        </template>
        <div class="risk-flex">
          <div class="risk-rules">
            <div>
              <a-select
              placeholder="请选择省份"
                style="width:200px"
                @change="handlSelect($event)"
                allowClear
              >
                <a-select-option value="">请选择省份</a-select-option>
                <a-select-option
                  v-for="items of selectlist"
                  :key="items.name"
                  :value="items.value"
                >
                  {{ items.name }}
                </a-select-option>
              </a-select>
            </div>
          </div>
          <div class="ant-descriptions-item-label risk-switch">
            <div>
              <a-switch
                checked-children="开启"
                un-checked-children="关闭"
                default-checked
              />
            </div>
          </div>
        </div>
      </a-descriptions-item>
    
      <a-descriptions-item>
        <template v-slot:label>
          <div>长期未考勤提醒规则：</div>
        </template>
        <div class="risk-flex">
          <div class="risk-rules">
            <div>
              <a-checkbox
                ><span class="text-color"
                  >超过
                </span></a-checkbox
              >
              <a-input default-value="" style="width: 100px">
              </a-input>
              <span class="text-color" style="margin-left:5px">天未考勤，系统进行提示</span>

            </div>
          </div>
          <div class="ant-descriptions-item-label risk-switch">
            <div>
              <a-switch
                checked-children="开启"
                un-checked-children="关闭"
                default-checked
              />
            </div>
          </div>
        </div>
      </a-descriptions-item>
      <a-descriptions-item>
        <template v-slot:label>
          <div>教育提醒规则：</div>
        </template>
        <div class="risk-flex">
          <div class="risk-rules">
            <div>
              <a-checkbox
                ><span class="text-color"
                  >超过
                </span></a-checkbox
              >
              <a-input default-value="" style="width: 100px">
              </a-input>
              <span class="text-color" style="margin-left:5px">天未接受三级安全教育培训，系统进行提示</span>

            </div>
          </div>
          <div class="ant-descriptions-item-label risk-switch">
            <div>
              <a-switch
                checked-children="开启"
                un-checked-children="关闭"
                default-checked
              />
            </div>
          </div>
        </div>
      </a-descriptions-item>
    </a-descriptions>
  </a-card>
</template>

<script>
import nation from "./nation.json";
export default {
  data() {
    return {
      nation: nation,
      selectlist: []
    };
  },
  created() {},

  methods: {
    handlSelect(e) {
      console.log(e,'e===》》》')
    }
  },
};
</script>

<style lang="less" scoped>
.risk_container {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-bottom: 10px;
}

.content {
  display: flex;
}
.img-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
  img {
    margin-bottom: 10px;
  }
}

.e-descriptions {
  width: 1100px;
  .risk-flex {
    display: flex;
    justify-content: space-between;
  }
  .risk-switch {
    // display: flex;
    // align-content: center;
    text-align: center !important;
    border-right: 1px solid #f0f0f0;
    border-left: 1px solid #f0f0f0;
    background: #fff !important;
    width: 150px !important;
    position: relative;
    > div {
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
    }
  }
  .risk-rules {
    padding: 10px 24px;
    color: rgb(118, 131, 143);
    font-size: 15px;
    > div {
      display: flex;
      width: 100%;
      align-items: center;
      margin-bottom: 5px;
    }
  }
}
.text-color {
  color: rgb(118, 131, 143);
  font-size: 15px;
}
.e-descriptions /deep/ .ant-descriptions-item-label {
  width: 180px;
  // padding: 6px 7px;
  text-align: right;
  background: #f3f7f9;
  color: rgb(118, 131, 143);
  font-size: 15px;
}
.e-descriptions /deep/ .ant-descriptions-item-content {
  // padding: 6px 7px;
  padding: 0;
}
</style>
