<template>
    <div class="a_Approval">
        <a-form-model :model="params" class="clearfix" @submit="handleSubmit">
            <a-col :span="4" v-if="!isProject && !isBranchOffice">
                <a-form-model-item label="分公司" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">

                    <a-select v-model="params.branchOfficeId" allowClear placeholder="">
                        <!-- <a-select-option value="">全部</a-select-option> -->
                        <a-select-option v-for="item in onlyGcglbList" :key="item.value" :value="item.value"
                            :title="item.label">
                            {{ item.label }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
            </a-col>
            <a-col :span="5" v-if="!isProject">
                <a-form-model-item label="项目" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
                    <a-input v-model="params.projectName" allow-clear />
                </a-form-model-item>
            </a-col>
            <a-col :span="4">
                <a-form-model-item label="年份" :labelCol="{ span: 6 }" :wrapperCol="{ span: 14, offset: 1 }">
                    <a-date-picker mode="year" v-model="params.reportTime" placeholder="请输入年份" :format="'YYYY'"
                        style="width: 140px" :open="isOpen" @openChange="monthOpenChangeOne"
                        @panelChange="monthPanelChangeOne" />
                </a-form-model-item>
            </a-col>


            <a-col :span="3">
                <a-space class="btnBox">
                    <a-button type="primary" html-type="submit"> 查询 </a-button>
                    <a-button type="primary" v-if="isProject && isProjectSafer" @click="addNewComplaint"> 新建清单 </a-button>
                </a-space>
            </a-col>
        </a-form-model>
        <standard-table :columns="columns" :dataSource="tableData" rowKey="id" :pagination="pagination"
            :loading="tableLoading" @change="handleChangePage" :isAlert="false" :bordered="true" :btnArr="tableBtnArr"
            :selectedRows.sync="selectedRows" :scroll="{ x: 1400 }">
            <!-- @selectedRowChange="handleSelectedRowsChange" :totalBoolean="true" :rowColor="false" -->
            <template slot="index" slot-scope="{ index }">
                <span>{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}</span>
            </template>
            <template slot="safetyOfficerStatus" slot-scope="{ record }">
                <a-tag color="#108ee9" v-if="record.safetyOfficerStatus == 1">已提交</a-tag>
                <a-tag color="#999" v-if="record.safetyOfficerStatus == 0">未提交</a-tag>
            </template>
            <template slot="projectManagerStatus" slot-scope="{ record }">
                <a-tag color="#108ee9" v-if="record.projectManagerStatus == 1">已提交</a-tag>
                <a-tag color="#999" v-if="record.projectManagerStatus == 0">未提交</a-tag>
                <a-tag color="#f00" v-if="record.projectManagerStatus == 2">驳回</a-tag>
            </template>
            <template slot="branchOfficeStatus" slot-scope="{ record }">
                <a-tag color="#108ee9" v-if="record.branchOfficeStatus == 1">已提交</a-tag>
                <a-tag color="#999" v-if="record.branchOfficeStatus == 0">未提交</a-tag>
                <a-tag color="#f00" v-if="record.branchOfficeStatus == 2">驳回</a-tag>
            </template>
            <template slot="companyStatus" slot-scope="{ record }">
                <a-tag color="#108ee9" v-if="record.companyStatus == 1">已提交</a-tag>
                <a-tag color="#999" v-if="record.companyStatus == 0">未提交</a-tag>
                <a-tag color="#f00" v-if="record.companyStatus == 2">驳回</a-tag>
            </template>

            <template slot="action" slot-scope="{ record }">
                <div class="sTables">
                    <div class="y-edit" @click="handleGo(record)">详情</div>
                    <!-- <div class="y-del" @click="handleDel(record)">删除</div> -->
                </div>
            </template>
        </standard-table>
        <detail :visible.sync="visible" :isEdit="isEdit" :projectInfo="projectInfo" :id="id" @closeDialog="closeDialog" @refresh="handleQuery" />

    </div>
</template>

<script>

import {
    riskcontrolList, riskcontrolDelete, constructplanExport
} from "@/services/riskControll";

import { mapGetters } from "vuex";
import StandardTable from "@/components/table/StandardTable";
import moment from "moment";
import detail from "./components/smDetail.vue";
import { getProjectDetail } from "@/services/equipmentLedger";

import mixin from './components/mixin'
import mixinRole from './components/mixinRole'

export default {
    name: "list",
    components: {
        StandardTable,
        detail
    },
    mixins: [mixin, mixinRole],
    data() {
        return {
            params: {
                "pageNo": "1", //页码
                "pageSize": "10", //每页条数
                "companyId": "", //公司ID
                "companyName": "", //公司名称
                "branchOfficeId": "", //分公司ID
                "branchOfficeName": "", //分公司名称
                "projectId": "", //项目ID
                "projectName": "", //项目名称
                "safetyOfficerId": "", //安全员工号
                "reportTime": "", //清单日期
                "reserved1": "" //哪个角色查询 （1 安全员  2 项目经理  3 分公司  4公司 5集团)
            },
            pagination: {
                //分页数据
                current: 1,
                pageSize: 10,
                total: 0,
                showTotal: (total, range) => `共 ${total} 条`,
            },
            total: 0,
            tableData: [],
            tableLoading: false, //table加载中
            selectedRows: [],
            tmpSelectedRows: [],

            visible: false,
            isEdit: 0,
            id: "",

            projectInfo: {
                parentOffice: '',
                parentOfficeId: '',
                gcglbId: '',
                gcglbName: '',
                projectId: '',
                projectName: ''
            }

        };
    },
    activated() {
        this.params.reserved1 = this.calcRole()
        if(this.params.reserved1 == 1) {
            this.params.safetyOfficerId = this.user.id
        }
        if(this.isProject) {
            let d = this.user.loginLaoWuUser.projectId
            getProjectDetail(d).then(res => {

                if (res.data.code === 0) {
                    
                    this.projectInfo.parentOfficeId = res.data.data.parentOfficeId;
                    this.projectInfo.parentOffice = res.data.data.parentOffice;
                    this.projectInfo.gcglbId = res.data.data.gcglbId;
                    this.projectInfo.gcglbName = res.data.data.gcglbName;
                    this.projectInfo.projectId = res.data.data.projectId;
                    this.projectInfo.projectName = res.data.data.projectName;
                    
                    this.params.projectId = this.projectInfo.projectId;
                    this.params.companyId = this.projectInfo.parentOfficeId;
                    this.params.branchOfficeId = this.projectInfo.gcglbId;
                } 

                this.handleQuery();
            })
        } else {
            // this.columns = this.columns.filter(el => el.title != '操作')
            this.handleQuery();
        }
    },
    computed: {
        tableBtnArr() {
            return [
                // {
                //     name: '新增',
                //     attrs: {
                //         type: 'primary'
                //     },
                //     // hidden: !this.isSafe,
                //     click: this.addNewComplaint
                // },
                // {
                //     name: '导出',
                //     attrs: {
                //         type: 'primary'
                //     },
                //     // hidden: !this.isJSOffice,
                //     click: this.bathDownload
                // }
            ]
        }
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault();
            this.params.pageNo = 1
            this.pagination.current = 1
            this.handleQuery();
        },
        handleQuery(e) {
            this.tableData = []
            this.tableLoading = true
            let d = this.params;
            d.reportTime = d.reportTime ? moment(d.reportTime).format('YYYY'): ''

            riskcontrolList(d).then((res) => {
                this.tableLoading = false
                if (res.data.code == 0) {
                    let list = res.data.data.list;

                    this.tableData = list.map((item, index) => {
                        
                        return {
                            ...item,
                        };
                    })
                    this.pagination.total = res.data.data.total;
                } else {
                    this.$message.error(res.data.msg)
                }
            });
        },
        handleChangePage(e) {
            this.params.pageNo = e.current;
            this.params.pageSize = e.pageSize;
            this.pagination.current = e.current;
            this.handleQuery();
        },
        addNewComplaint() {
            this.isEdit = 0
            this.id = ''
            this.visible = true
        },
        handleEdit(e) {

            this.isEdit = 1
            this.id = e.id
            this.visible = true
        },
        handleDel(record) {
            const _that = this
            this.$confirm({
                title: '是否确定删除？',
                cancelText: '否',
                okText: '是',
                onOk: () => {
                    riskcontrolDelete({ id: record.id }).then(res => {
                        if (res.data.code == 0) {
                            _that.$message.success('删除成功')
                            this.handleQuery(this.params);
                        } else {
                            _that.$message.error(res.data.msg)
                        }
                    })
                },
            })
        },
        resetQuery() {
            this.pagination.current = 1
            this.params = {
                "pageNo": "1", //页码
                "pageSize": "10", //每页条数
                "companyId": "", //公司ID
                "companyName": "", //公司名称
                "branchOfficeId": "", //分公司ID
                "branchOfficeName": "", //分公司名称
                "projectId": "", //项目ID
                "projectName": "", //项目名称
                "safetyOfficerId": "", //安全员工号
                "reportTime": "" //清单日期
            };
            this.params.safetyOfficerId = this.user.id
            this.params.reportTime = moment(new Date()).format('YYYY')
            this.params.reserved1 = this.calcRole()
            if(this.isProject) {
                this.params.projectId = this.projectInfo.projectId;
                this.params.companyId = this.projectInfo.parentOfficeId;
                this.params.branchOfficeId = this.projectInfo.gcglbId;
            } else {
                // 公司  分公司
            }
            this.handleQuery();
        },
        bathDownload() {
            // 取查询参数导出 companyId=&branchOfficeId=&projectId=&sgfaName=&overRange=&proveTime=&aproveTime=&makeTime=
            // let url = constructplanExport + '?'+ `companyId=${this.params.companyId}&branchOfficeId=${this.params.branchOfficeId}&projectId=${this.params.projectId}&sgfaName=${this.params.sgfaName}&overRange=${this.params.overRange}&proveTime=${this.params.proveTime}&aproveTime=${this.params.aproveTime}&makeTime=${this.params.makeTime}`
            // window.open(url)
        },
        closeDialog() {
            this.visible = false
        },
        handleGo(record) {
            // 路由跳转
            this.$router.push({name: '安全生产风险排查管控清单', query: {id: record.id,date: record.reportTime}})
        }

        

    },
};
</script>

<style lang="less" scoped>
.query-form {
    display: flex;
    // justify-content: space-between;
}

.post-border {
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 5px;
    padding-top: 5px;
    // padding-top: 10px;
    // height: 40px;
}

.post-border:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

.sTables {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.y-edit {
    color: #307dfa;
    cursor: pointer;
}
.y-del {
    color: #ff5c77;
    cursor: pointer;
}
.y-other {
    color: #615e83;
    cursor: pointer;
}
</style>