<template>
  <div>
    <div class="constructiontTable_index">
      <form-index
        class="form"
        ref="formindex"
        :projectList="projectList"
        :formData="formData"
        :baiduMapBoolen="false"
        @handleSubmit="handleSubmit"
        @handleChange="handleChange"
        @handlSelect="handlSelect"
        :flexAction="true"
      >
        <!-- 下部分按钮插槽 -->
        <template #flexAction>
          <a-space :style="{ marginTop: '4px' }">
            <a-button icon="search" type="primary" html-type="submit"> 查询 </a-button>
            <!-- <a-button @click="close"> 重置 </a-button> -->
            <a-space class="operator">
              <a-button @click="close"> 重置 </a-button>
            </a-space>
            <a-button icon="plus" type="primary" @click="createItem"> 新建单位 </a-button>
          </a-space>
        </template>
      </form-index>

      <standard-table
        :columns="columns"
        :dataSource="dataSource"
        row-key="id"
        :pagination="pagination"
        :loading="tableLoading"
        @change="onPageChange"
        :bordered="true"
        tableSize="middle"
      >
        <template slot="name" slot-scope="{ text, record }">
          <a-button type="link" @click="toUnits(record)">{{ text }}</a-button>
        </template>
        <div slot="phone" slot-scope="{ text }">
          <span>{{ text ? text : "/" }}</span>
        </div>
        <template slot="mainBusiness" slot-scope="{ text }">
          <span v-if="text">
            <span v-for="(item, index) in text" :key="index">
              <a-tag class="mt-1">{{ item }}</a-tag>
            </span>
          </span>
          <span v-else>/</span>
        </template>
        <div slot="userName" slot-scope="{ record }">
          <div v-if="record.userName">
            <div>{{ record.userName }}</div>
            <span v-if="record.userPhone">({{ record.userPhone }})</span>
          </div>
        </div>
        <div slot="action" slot-scope="{ record }">
          <!-- <div class="sTables">
            <a-popover placement="bottomLeft" overlayClassName="overlay-class"> -->
              <!-- <template slot="content"> -->
                <!-- <div style="display: flex; flex-direction: column"> -->
                  <a-button
                    class="btn-default"
                    style="color:rgb(85, 85, 204)"
                    type="link"
                    @click="viewEditing(record, 'edit')"
                    >修改</a-button
                  >
                  <a-divider v-if="record.children == null" type="vertical" />
                  <a-button
                    v-if="record.children == null"
                    class="btn-default"
                    type="link"
                    @click="recorDelete(record)"
                    >移除</a-button
                  >
                  <a-divider type="vertical" />
                  <a-button
                    class="btn-default"
                    type="link"
                    style="color:rgb(255, 92, 119)"
                    @click="viewEditing(record, 'add')"
                    >添加下级</a-button
                  >
                <!-- </div> -->
              <!-- </template> -->
              <!-- <a-button type="link" class="btn-default"
                >操作<a-icon type="double-right"
              /></a-button>
            </a-popover>
          </div> -->
        </div>
      </standard-table>
      <Export-loading
        :cPercent="cPercent"
        :isExporting="isExporting"
        :loadingText="loadingText"
      ></Export-loading>

      <a-modal
        :title="title"
        :visible.sync="visible"
        @ok="modelOk"
        @cancel="modelCancel"
        width="900px"
      >
        <form-index
          ref="formModal"
          :projectList="projectList"
          :formData="formModal"
          :baiduMapBoolen="false"
          @handleChange="handleChange"
          @handlSelect="handlSelect"
          @resourceUpload="resourceUpload"
          @upRemove="upRemove"
        >
        </form-index>
      </a-modal>
    </div>
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import {
  aqxjProjectsupdate,
  delOfficeMaster,
  getOfficeMaster,
  getTreeOffice,
  addOfficeMaster,
  getOfficesList,
  deleteDept
} from "@/services/projectManagement";
import { exportProgress } from "@/pages/aqjyzl/export"; // 导出方法
import ExportLoading from "@/components/ExportLoading/ExportLoading"; // progress组件
import { mapGetters } from "vuex";
import { sysUserGetList } from "@/services/message";
import { getOfficeTypeList,getOfficesSelfPage,getOfficeTree,insertOffice,getOfficeDetail,setOfficeUpdate } from "@/services/sys";
import { useRender } from '@/hooks/useRender.js'

const columns = [
  {
    title: "单位名称",
    dataIndex: "name",
    width: 370,
    scopedSlots: { customRender: "name" },
    // fixed: "left",
  },
  {
    title: "社会信用代码",
    dataIndex: "code",
    align: "center",
    width: 110,
  },
  {
    title: "负责人",
    dataIndex: "master",
    align: "center",
    width: 65,
    key: "master",
  },
  {
    title: "联系电话",
    dataIndex: "phone",
    align: "center",
    width: 80,
    scopedSlots: { customRender: "phone" },
  },
  {
    title: "成立日期",
    dataIndex: "startDate",
    align: "center",
    width: 80,
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "注册资金(万)",
    dataIndex: "capital",
    align: "center",
    width: 80,
  },
  {
    title: "主营业务",
    dataIndex: "mainBusinessNames",
    align: "center",
    width: 120,
    ellipsis: true,
    scopedSlots: { customRender: "mainBusinessNames" },
  },
  {
    title: "地址",
    align: "center",
    width: 100,
    dataIndex: "address",
    scopedSlots: { customRender: "address" },
  },
  {
    title: "操作",
    align: "center",
    width: 75,
    // fixed: "right",
    scopedSlots: { customRender: "action" },
  },
];
const formData = [
  {
    label: "公司名称",
    placeholder: "请输入公司名称",
    decorator: [
      "name",
      { rules: [{ required: false, message: "请输入公司名称" }] },
    ],
    type: "input",
    key: "name",
    selectlist: [],
    qb: true,
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
  },
  {
    label: "社会信用代码",
    placeholder: "请输入社会信用代码",
    decorator: [
      "code",
      { rules: [{ required: false, message: "请输入社会信用代码" }] },
    ],
    type: "input",
    key: "code",
    dataSource: [],
    col: 6,
    labelCol: 8,
    wrapperCol: 14,
  },
  {
    type: "action",
    col: 6,
    display: true,
  }
];
const formModal = [
  // {
  //   label: "上级单位",
  //   placeholder: "请选择上级单位",
  //   decorator: [
  //     "id",
  //     { rules: [{ required: false, message: "请选择上级单位" }] },
  //   ],
  //   type: "treeSelect",
  //   key: "id",
  //   selectlist: [],
  //   col: 12,
  //   labelCol: 6,
  //   wrapperCol: 14,
  //   display: true,
  // },
  {
    label: "上级单位",
    placeholder: "请选择上级单位",
    decorator: [
      "parentId",
      { rules: [{ required: true, message: "请选择上级单位" }] },
    ],
    type: "treeSelect",
    key: "parentId",
    selectlist: [],
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
    disabled: false,
  },
  {
    label: "社会信用代码",
    placeholder: "请输入统一社会信用代码",
    decorator: [
      "code",
      { rules: [{ required: true, message: "请输入统一社会信用代码" }] },
    ],
    type: "input",
    key: "code",
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
  },
  {
    label: "公司名称",
    placeholder: "请输入公司名称",
    decorator: [
      "name",
      { rules: [{ required: true, message: "请输入公司名称" }] },
    ],
    type: "inputGroup",
    dataSource: [],
    key: "name",
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
  },
  // {
  //   label: "单位类型",
  //   placeholder: "请选择单位类型",
  //   decorator: [
  //     "type",
  //     { rules: [{ required: false, message: "请选择单位类型" }] },
  //   ],
  //   type: "select",
  //   key: "type",
  //   selectlist: [],
  //   col: 12,
  //   labelCol: 6,
  //   wrapperCol: 14,
  //   display: true,
  // },
  {
    label: "主营业务",
    placeholder: "请选择主营业务(可多选)",
    decorator: [
      "mainBusiness",
      { rules: [{ required: false, message: "请选择主营业务" }] },
    ],
    type: "select",
    key: "mainBusiness",
    mode: "multiple",
    selectlist: [
      {
        value: "1",
        name: "总包",
      },
      {
        value: "2",
        name: "监理",
      },
      {
        value: "3",
        name: "劳务分包",
      },
      {
        value: "4",
        name: "专业分包",
      },
      {
        value: "5",
        name: "老包",
      },
    ],
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  // {
  //   label: "管理员",
  //   placeholder: "请输入公司管理员姓名",
  //   decorator: [
  //     "master",
  //     { rules: [{ required: true, message: "请输入公司管理员姓名" }] },
  //   ],
  //   type: "input",
  //   key: "master",
  //   col: 12,
  //   labelCol: 6,
  //   wrapperCol: 14,
  // },
  {
    label: "法人代表",
    placeholder: "请输入法人代表",
    decorator: [
      "master",
      { rules: [{ required: true, message: "请输入法人代表" }] },
    ],
    type: "input",
    key: "master",
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "联系电话",
    placeholder: "请输入联系电话",
    decorator: [
      "phone",
      { rules: [{ required: false, message: "请输入联系电话" }] },
    ],
    type: "input",
    key: "phone",
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
  },
  {
    label: "运维到期时间",
    placeholder: "请选择运维到期时间",
    decorator: [
      "dutTime",
      { rules: [{ required: false, message: "请选择运维到期时间" }] },
    ],
    type: "datePicker",
    key: "dutTime",
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
    format: "yyyy-MM-DD",
    styles: "width: 100%",
    display: true,
  },
  {
    label: "注册资金(万)",
    placeholder: "请输入注册资金(万)元",
    decorator: [
      "capital",
      { rules: [{ required: false, message: "请输入注册资金(万)元" }] },
    ],
    type: "input",
    key: "capital",
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "成立时间",
    placeholder: "请选择成立时间",
    decorator: [
      "startDate",
      { rules: [{ required: false, message: "请选择成立时间" }] },
    ],
    type: "datePicker",
    key: "startDate",
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
    format: "yyyy-MM-DD",
    styles: "width: 100%",
  },
  {
    label: "单位地址",
    placeholder: "请输入单位地址",
    decorator: [
      "address",
      { rules: [{ required: false, message: "请输入单位地址" }] },
    ],
    type: "input",
    key: "address",
    col: 24,
    labelCol: 3,
    wrapperCol: 19,
  },
  {
    label: "营业执照",
    title: "上传营业执照",
    placeholder: "请上传营业执照",
    decorator: [
      "licence",
      { rules: [{ required: false, message: "请上传营业执照" }] },
    ],
    type: "tsUpload",
    key: "licence",
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
    defaultFileList: [],
  },
  {
    label: "公司资质附件",
    title: "上传公司资质",
    placeholder: "请上传公司资质",
    decorator: [
      "credential",
      { rules: [{ required: false, message: "请上传公司资质" }] },
    ],
    type: "tsUpload",
    key: "credential",
    col: 12,
    labelCol: 6,
    wrapperCol: 14,
    defaultFileList: [],
  },
  {
    label: "业绩",
    placeholder: "请输入业绩",
    decorator: [
      "achievement",
      { rules: [{ required: false, message: "请输入业绩" }] },
    ],
    type: "textarea",
    key: "achievement",
    col: 24,
    labelCol: 3,
    wrapperCol: 19,
    display: true,
  },
  {
    label: "获得荣誉",
    placeholder: "请输入获得荣誉",
    decorator: [
      "honor",
      { rules: [{ required: false, message: "请输入获得荣誉" }] },
    ],
    type: "textarea",
    key: "honor",
    col: 24,
    labelCol: 3,
    wrapperCol: 19,
    display: true,
  },
  {
    label: "经营范围",
    placeholder: "请输入经营范围",
    decorator: [
      "scope",
      { rules: [{ required: false, message: "请输入经营范围" }] },
    ],
    type: "textarea",
    key: "scope",
    col: 24,
    labelCol: 3,
    wrapperCol: 19,
    display: true,
  },
  // {
  //   label: "管理员密码",
  //   placeholder: "修改管理员密码",
  //   decorator: [
  //     "userPwd",
  //     {
  //       rules: [
  //         {
  //           required: false,
  //           message: "字母、数字及特殊字符两种以上6-22位",
  //           pattern: new RegExp(
  //             /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)^\S{6,22}$/
  //           ),
  //         },
  //       ],
  //     },
  //   ],
  //   type: "input",
  //   key: "userPwd",
  //   col: 12,
  //   labelCol: 6,
  //   wrapperCol: 14,
  //   display: true,
  // },
  // {
  //   label: "企业项目管理员用户姓名",
  //   placeholder: "请输入企业项目管理员用户姓名",
  //   decorator: [
  //     "userName",
  //     { rules: [{ required: false, message: "请输入企业项目管理员用户姓名" }] },
  //   ],
  //   type: "input",
  //   key: "userName",
  //   col: 12,
  //   labelCol: 0,
  //   wrapperCol: 0,
  //   display: false,
  // },
  // {
  //   label: "企业项目管理员用户姓名",
  //   placeholder: "请输入企业项目管理员用户姓名",
  //   decorator: [
  //     "userId",
  //     { rules: [{ required: false, message: "请输入企业项目管理员用户姓名" }] },
  //   ],
  //   type: "input",
  //   key: "userId",
  //   col: 12,
  //   labelCol: 0,
  //   wrapperCol: 0,
  //   display: false,
  // },

  // //
];
import { debounce } from "lodash";
export default {
  mixins: [exportProgress], //导出方法注入
  components: {
    formIndex, //form表单封装
    StandardTable, //表格封装
    ExportLoading, //导出数据加载封装
  },
  data() {
    return {
      expandedRowKeys: [],
      selectedRows: [],
      columns: columns, //表格数据
      dataSource: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total, range) => `共 ${total} 条`
      },
      tableLoading: false,
      formData: formData,
      formModal: formModal,
      prams: {
        pageNo: 1,
        pageSize: 10,
        // id: this.user.officeId
      },
      projectList: [], //获取当前项目数据
      loadingText: "数据加载中...",
      cPercent: 0,
      isExporting: false,
      expandedKeys: [],
      officeTreeData: [],
      // 弹出层标题
      title: "修改公司机构",
      // 是否显示弹出层
      visible: false,
      // 新增或者修改
      type: "edit",
      // 单位详情
      detail: {},
      isEditOfficeId: false // 是否是修改当前公司
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  activated() {
    // console.log("user", this.user);
    if (this.$route.query.pageNo) {
      this.prams.pageNo = this.$route.query.pageNo;
    }
    // console.log(this.isAdmin(), "是否是admin");
    if (this.isAdmin()) {
      this.prams.officeId = this.user.officeId;
    }
    this.aqxjlist(this.prams);
    // this.getOfficeTypeList();
  },
  methods: {
    getOfficeTypeList() {
      // 获取单位类型数组
      getOfficeTypeList().then((res) => {
        const data = res.data.data.companyTypeList;
        const arr = [];
        data.forEach((item) => {
          arr.push({
            name: item.label,
            value: item.value,
          });
        });
        this.roleTypeList = arr;
        this.formDataFn("type", arr);
      });
    },
    // 判断是不是admin 账户
    isAdmin() {
      return this.user.officeLoginData.labourRoleKeyList.some((item) => {
        return item != "admin";
      });
    },
    // 封装弹窗确定按钮
    confirm(title, content) {
      return new Promise((resolve, reject) => {
        this.$confirm({
          title: title,
          content: content,
          onOk() {
            resolve();
          },
        });
      });
    },
    resourceUpload(obj) {
      this.formModal.forEach((item) => {
        if (item.key == obj.key) {
          item.defaultFileList = [obj];
          // if (item.key === "facePath") {
          //   this.getIdCardInfo(obj.formData, "front");
          // } else if (item.key === "backPath") {
          //   this.getIdCardInfo(obj.formData, "back");
          // }
        }
      });
    },
    upRemove(file) {
      this.formModal.forEach((item) => {
        if (item.key == file.key) {
          item.defaultFileList = [];
        }
      });
    },
    // 删除接口
    recorDelete(val) {
      let params = {
        id: val.id,
      };
      this.confirm("删除", `是否确认删除 ${val.name} 公司`).then(() => {
        deleteDept(params).then((res) => {
          if (res.data.code === 0) {
            this.$message.success("删除成功", 10);
            this.aqxjlist(this.prams);
          } else {
            this.$message.error(res.data.msg, 10);
          }
        });
      });
    },
    // 更新接口
    update(e) {
      aqxjProjectsupdate(e).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("成功", 10);
        } else {
          this.$message.error(res.data.msg, 10);
        }
      });
    },
    handleChange(e) {
      this.projectArr = [];
      const name = {
        master: () => {
          this.getZdata(sysUserGetList, e, "nickName");
        },
        name: () => {
          this.getZdata(getOfficesList, e, "name");
        },
      }[e.item.key]();
      const arr = ["userId"];
      arr.forEach((item) => {
        this.$refs["formindex"].setFieldsValue("", {}, item);
      });
      // 获取当前匹配key，赋值相对于数组
      this.formModal.forEach((item) => {
        if (e.item.key === item.key) {
          item.dataSource = this.projectArr;
        }
      });
    },
    // 接口封装
    /**
     * msg 接收接口名称
     * e 数据
     * name 参数名
     * */
    getZdata(msg, e, name) {
      let data = {
        [name]: e.value,
      };
      msg(data).then((res) => {
        if (res.status == 200) {
          const data = res.data.data.list ? res.data.data.list : res.data.data
          if (name == 'nickName') {
            name = 'nickname'
          }
          data.forEach((item) => {
            this.projectArr.push({
              title: item[name],
              id: item.id,
            });
          });
          this.projectList = data;
        }
      });
    },

    // 特殊数据处理
    handlSelect({ val, keys }) {
      const arr = ["parentId"];
      arr.forEach((item) => {
        this.$refs["formModal"].setFieldsValue("", {}, item);
      });
      if (keys == "id") {
        this.officeTreeData.map((item) => {
          if (item.id == val) {
            this.$refs["formModal"].setFieldsValue("", { parentId: item.id });
          }
        });
      }
      this.projectList.forEach((item) => {
        // 选择企业项目管理员
        if (keys == "master" && item.id == val) {
          this.$refs["formModal"].setFieldsValue("", { userId: item.id });
          this.$refs["formModal"].setFieldsValue("", { master: item.nickname });
        }
      });
    },
    aqxjlist(prams) {
      this.tableLoading = true;
      getOfficeTree(prams).then((res) => {
        if (res.data.code === 0) {
          this.dataSource = res.data.data;
          this.officeTreeData = res.data.data;
          // this.pagination.total = res.data.data.total
          // this.formDataFn("id", res.data.data);
          this.formDataFn("parentId", res.data.data);
        }
        this.tableLoading = false;
      });
      // getTreeOffice(prams).then((res) => {
      //   if (res.data.code === 0) {
      //     this.dataSource = res.data.data.list;
      //     this.officeTreeData = res.data.data.list;
      //     this.pagination.total = res.data.data.total
      //     // this.formDataFn("id", res.data.data);
      //     this.formDataFn("parentId", res.data.data.list);
      //   }
      //   this.tableLoading = false;
      // });
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      this.formModal.forEach((item) => {
        if (item.key == key) {
          item.selectlist = data;
        }
      });
    },
    // 提交表单数据
    handleSubmit:debounce(function(e) {
      let obj = Object.assign({}, e, this.prams);
      this.aqxjlist(obj);
    },500),
    // 重置
    close() {
      this.prams = {
        pageNo: 1,
        pageSize: 10,
      };
      if (this.isAdmin()) {
        this.prams.officeId = this.user.officeId;
      }
      this.aqxjlist(this.prams);
      // this.$refs["formindex"].setFieldsValue("", {}, "projectName");
      this.$refs["formindex"].resetFields();
    },
    //表格 - 分页查询
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      this.prams.pageNo = e.current;
      this.pagination.current = e.current;
      this.aqxjlist(Object.assign({}, this.prams, obj));
    },
    updateSelectRows(e) {
      console.log(e);
    },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    onSelect(selectedKeys, info) {
      console.log(selectedKeys, "selectedKeys", info, "info");
      // this.queryParam.parentOfficeId = selectedKeys[0];
      // this.queryOfficeId(this.queryParam.parentOfficeId, null, null);
      // this.parentData();
    },
    // 查看编辑
    viewEditing(e, type) {
      if (type == "edit") {
        getOfficeDetail({ id: e.id }).then((res) => {
          if (res.data.code === 0) {
            this.detail = res.data.data
            const data = res.data.data
            let path = ["licence", "credential"];
            this.formModal.forEach((item, index) => {
              // 处理回显图片path
              path.forEach((key, index) => {
                if (item.key == key && data[key]) {
                  item.defaultFileList = [
                    {
                      url: data[key],
                      uid: index,
                      name: "image.png",
                    },
                  ];
                }
              });

              if (item.key == "parentId") {
                // item.selectlist = data;
                if (item.selectlist[0].parentId == e.parentId) {
                  item.disabled = true;
                  item.label = "最上级单位";
                  item.display = false;
                  item.col = 0;
                  item.decorator.forEach((items) => {
                    if (items instanceof Object) {
                      items.rules[0].required = false;
                    }
                  });
                  this.formModal[index + 1].col = 24;
                  this.formModal[index + 1].labelCol = 3;
                  this.formModal[index + 1].wrapperCol = 19;
                  this.isEditOfficeId = true
                } else {
                  item.disabled = false;
                  item.label = "上级单位";
                  item.display = true;
                  item.col = 12;
                  this.formModal[index + 1].col = 12;
                  this.formModal[index + 1].labelCol = 6;
                  this.formModal[index + 1].wrapperCol = 14;
                  this.isEditOfficeId = false
                }
              }
            });
            // 单位类型
            // res.data.data.type = res.data.data.type
            //   ? String(res.data.data.type)
            //   : res.data.data.type;
            data.mainBusiness = data.mainBusiness
              ? data.mainBusiness.split(",")
              : undefined;
            this.type = "edit";
            this.title = "修改机构";
            this.$nextTick(() => {
              this.$refs["formModal"].queryObj(data);
            });
          }
        });
      } else if (type == "add") {
        this.formModal.forEach((item, index) => {
          if (item.key == "parentId") {
            item.disabled = false;
            item.label = "上级单位";
            item.display = true;
            item.col = 12;
            this.formModal[index + 1].col = 12;
            this.formModal[index + 1].labelCol = 6;
            this.formModal[index + 1].wrapperCol = 14;
          }
        });
        this.type = "add";
        this.title = "新增机构";
        e = {
          // id: e.id,
          parentId: e.id,
        };
        this.$nextTick(() => {
          this.$refs["formModal"].queryObj(e);
        });
      }
      this.visible = true;
    },
    // 新建项目
    createItem(e) {
      this.title = "新增公司机构";
      this.visible = true;
      this.formModal.forEach((item, index) => {
        if (item.key == "parentId") {
          item.disabled = false;
          item.label = "上级单位";
          item.display = true;
          item.col = 12;
          this.formModal[index + 1].col = 12;
          this.formModal[index + 1].labelCol = 6;
          this.formModal[index + 1].wrapperCol = 14;
          item.decorator.forEach((items) => {
            if (items instanceof Object) {
              items.rules[0].required = true;
            }
          });
        }
      });
      this.type = "add";
    },
    // 确认弹窗
    modelOk(e) {
      this.$refs["formModal"].combinationSubmit().then((res) => {
        if (res.userPwd == null) {
          // 不修改密码删除字段
          delete res.userPwd;
        }
        if (this.type == "add") {
          // 新增删除掉id
          res.id = undefined;
          res.userName = res.master;
          res.userPhone = res.phone;
        }
        const params = {
          ...res,
          mainBusiness: res.mainBusiness
            ? res.mainBusiness.join()
            : res.mainBusiness,

        };
        if (this.type == 'add') {
          insertOffice(params).then((result) => {
          if (result.data.code === 0) {
              this.$message.success("新增单位成功", 10);
          } else {
            this.$message.error(result.data.msg, 10);
          }
          this.modelCancel();
          })
        } else {
          // 如果是修改自身，删除parentId
          // if (this.isEditOfficeId) delete params.parentId
          params.id = this.detail.id
          setOfficeUpdate(params).then((result) => {
          if (result.data.code === 0) {
              this.$message.success("修改单位成功", 10);
          } else {
            this.$message.error(result.data.msg, 10);
          }
          this.modelCancel();
          })
        }
        // addOfficeMaster(params).then((result) => {
        //   if (result.data.code === 0) {
        //     if (this.type == "add") {
        //       this.$message.success("新增单位成功", 10);
        //     } else {
        //       this.$message.success("修改单位成功", 10);
        //     }
        //   } else {
        //     this.$message.error(result.data.msg, 10);
        //   }
        //   this.modelCancel();
        // })
      });
    },
    // 关闭弹窗
    modelCancel(e) {
      this.visible = false;
      this.detail = null
      this.aqxjlist(this.prams);
      this.isEditOfficeId = false
      this.$refs["formModal"].resetFields();
    },
    // 跳转公司详情
    toUnits(e) {
      this.$router.push({
        name: "企业概况",
        query: {
          id: e.id,
          // data: res.data.data,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.constructiontTable_index {
  padding: 24px;
  background: #fff;
  border-radius: 6px;
  margin-bottom: 10px;


  .c_i_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 25px;

    .c_i_h_center {
      padding: 16px;
      border-radius: 4px;
      width: 180px;
      height: 130px;
      margin-right: 50px;

      div:nth-child(1) {
        font-size: 16px;
      }

      div:nth-child(2) {
        margin-top: 26px;
        font-size: 24px;
      }
    }
  }
}

.sTable {
  display: flex;
  justify-content: space-between;
  align-items: center;

  // div {
  //     width: 100%;
  //     color: blue;
  //     text-align: center;
  //     cursor: pointer;
  // }
}

.sTables {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;

  div {
    // width: 100%;
    padding: 0 10px;
    text-align: center;
    cursor: pointer;
  }
}

.ant-btn-link {
  // color: rgb(118, 131, 143);
  text-align: left;
  padding: 0;
}

.btn-default {
  color: #76838f;
}

.btn-default:hover {
  color: red;
}
.operator {
    margin-right: 8px;

    .ant-btn {
      color: #307dfa;
      border-color: #307dfa;
    }
  }
</style>
