var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "a_m_header" },
      [
        _c(
          "a-tabs",
          { attrs: { "default-active-key": _vm.defaultActiveKey } },
          [
            _c(
              "a-tab-pane",
              { key: "1", attrs: { tab: "在场人员" } },
              [
                _c("presence-list", {
                  ref: "presenceList",
                  on: { refreshExitList: _vm.refreshExitList },
                }),
              ],
              1
            ),
            _c(
              "a-tab-pane",
              { key: "2", attrs: { tab: "退场人员", forceRender: "" } },
              [
                _c("exit-list", {
                  ref: "exitList",
                  on: { refreshPresenceList: _vm.refreshPresenceList },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }