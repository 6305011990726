<template>
  <div>
    <p>query: {{$route.query}}</p>
    <p>params: {{$route.params}}</p>
  </div>
</template>

<script>
  export default {
    name: 'Demo'
  }
</script>

<style scoped>

</style>
