var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-layout",
    { attrs: { avatar: _vm.currUser.avatar } },
    [
      _c("div", { attrs: { slot: "headerContent" }, slot: "headerContent" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v(
            _vm._s(_vm.welcome.timeFix) + "，" + _vm._s(_vm.currUser.name)
          ),
        ]),
      ]),
      _c(
        "template",
        { slot: "extra" },
        [
          _c("head-info", {
            staticClass: "split-right",
            attrs: { title: _vm.$t("ranking"), content: "56" },
          }),
          _c("head-info", {
            staticClass: "split-right",
            attrs: { title: _vm.$t("ranking"), content: "8/24" },
          }),
          _c("head-info", {
            staticClass: "split-right",
            attrs: { title: _vm.$t("visit"), content: "2,223" },
          }),
        ],
        1
      ),
      [
        _c(
          "a-row",
          { staticStyle: { margin: "0 -12px" } },
          [
            _c(
              "a-col",
              {
                staticStyle: { padding: "0 12px" },
                attrs: { xl: 16, lg: 24, md: 24, sm: 24, xs: 24 },
              },
              [
                _c(
                  "a-card",
                  {
                    staticClass: "project-list",
                    staticStyle: { "margin-bottom": "24px" },
                    attrs: {
                      loading: _vm.loading,
                      bordered: false,
                      title: _vm.$t("progress"),
                      "body-style": { padding: 0 },
                    },
                  },
                  [
                    _c("a", { attrs: { slot: "extra" }, slot: "extra" }, [
                      _vm._v(_vm._s(_vm.$t("all"))),
                    ]),
                    _c(
                      "div",
                      _vm._l(_vm.projects, function (item, i) {
                        return _c(
                          "a-card-grid",
                          { key: i },
                          [
                            _c(
                              "a-card",
                              {
                                attrs: {
                                  bordered: false,
                                  "body-style": { padding: 0 },
                                },
                              },
                              [
                                _c(
                                  "a-card-meta",
                                  { attrs: { description: item.desc } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "card-title",
                                        attrs: { slot: "title" },
                                        slot: "title",
                                      },
                                      [
                                        _c("a-avatar", {
                                          attrs: {
                                            size: "small",
                                            src: item.logo,
                                          },
                                        }),
                                        _c("span", [_vm._v("Alipay")]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c("div", { staticClass: "project-item" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "group",
                                      attrs: { href: "/#/" },
                                    },
                                    [_vm._v("科学搬砖组")]
                                  ),
                                  _c("span", { staticClass: "datetime" }, [
                                    _vm._v("9小时前"),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ]
                ),
                _c(
                  "a-card",
                  {
                    attrs: {
                      loading: _vm.loading,
                      title: _vm.$t("dynamic"),
                      bordered: false,
                    },
                  },
                  [
                    _c(
                      "a-list",
                      _vm._l(_vm.activities, function (item, index) {
                        return _c(
                          "a-list-item",
                          { key: index },
                          [
                            _c(
                              "a-list-item-meta",
                              [
                                _c("a-avatar", {
                                  attrs: {
                                    slot: "avatar",
                                    src: item.user.avatar,
                                  },
                                  slot: "avatar",
                                }),
                                _c("div", {
                                  attrs: { slot: "title" },
                                  domProps: {
                                    innerHTML: _vm._s(item.template),
                                  },
                                  slot: "title",
                                }),
                                _c(
                                  "div",
                                  {
                                    attrs: { slot: "description" },
                                    slot: "description",
                                  },
                                  [_vm._v("9小时前")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "a-col",
              {
                staticStyle: { padding: "0 12px" },
                attrs: { xl: 8, lg: 24, md: 24, sm: 24, xs: 24 },
              },
              [
                _c(
                  "a-card",
                  {
                    staticStyle: { "margin-bottom": "24px" },
                    attrs: {
                      title: _vm.$t("access"),
                      bordered: false,
                      "body-style": { padding: 0 },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "item-group" },
                      [
                        _c("a", [_vm._v("操作一")]),
                        _c("a", [_vm._v("操作二")]),
                        _c("a", [_vm._v("操作三")]),
                        _c("a", [_vm._v("操作四")]),
                        _c("a", [_vm._v("操作五")]),
                        _c("a", [_vm._v("操作六")]),
                        _c(
                          "a-button",
                          {
                            attrs: {
                              size: "small",
                              type: "primary",
                              ghost: "",
                              icon: "plus",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("add")))]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "a-card",
                  {
                    attrs: {
                      loading: _vm.loading,
                      title: _vm.$t("team"),
                      bordered: false,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "members" },
                      [
                        _c(
                          "a-row",
                          _vm._l(_vm.teams, function (item, index) {
                            return _c(
                              "a-col",
                              { key: index, attrs: { span: 12 } },
                              [
                                _c(
                                  "a",
                                  [
                                    _c("a-avatar", {
                                      attrs: {
                                        size: "small",
                                        src: item.avatar,
                                      },
                                    }),
                                    _c("span", { staticClass: "member" }, [
                                      _vm._v(_vm._s(item.name)),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }