import moment from 'moment'
import { Modal } from 'ant-design-vue';
import { getVersionHistroyLast } from '@/services/sys'
import { VERSION_TYPE_ENUMS, VERSION_TYPE_ZHCN_MAP } from '@/enums/system'
import { checkAuthorization } from '@/utils/request'

let intervalId
// 是否已进行过提示
let isPrompted = false

async function checkUpdate() {
  if (!isLoggedIn()) {
    return
  }

  try {
    // 查询[版本更新通知]数据
    const res = await getVersionHistroyLast({ type: VERSION_TYPE_ENUMS.PRE_RELEASE })
    const data = res.data.data

    if (!validator(data)) return
    // 终止轮询
    stopPolling()
    // 提示即将更新
    isPrompted = true
    Modal.info({
      title: `#更新通知 ${data.vcode ? data.vcode : ''}`,
      content: function(h) {
        return h('div', {
          style: {
            maxHeight: '600px',
            overflow: 'auto'
          },
          domProps: {
            innerHTML: data.vcontent
          }
        })
      },
      width: 1150,
      closable: true,
      onOk() {}
    });
  } catch(e) {
    // 接口或token有问题，终止轮询
    console.error(e)
    stopPolling()
  }
}

function startPolling() {
  // 轮询间隔一分钟
  const interval = 1 * 60 * 1000

  // 手动调用一次
  isLoggedIn() && checkUpdate()
  // 开始轮询
  intervalId = setInterval(checkUpdate, interval)
}

function stopPolling() {
  clearInterval(intervalId);
  intervalId = null;
}

document.addEventListener('visibilitychange', function () {
  if (document.visibilityState === 'visible') {
    if (isPrompted) return
    startPolling()
  } else {
    stopPolling()
  }
});

/**
* @description 是否已登录
*/
function isLoggedIn() {
  if (!checkAuthorization()) return false
  if (location.hash === '#/login') return false

  return true
}

/**
* @description 验证器
* @param {Object} data getLastVersion接口数据
* @return {Boolean} 
*/
function validator(data) {
  // 1.是否为“版本更新通告”
  if (data?.type !== VERSION_TYPE_ENUMS.PRE_RELEASE) {
    console.warn(`版本更新检测：当前通知类型不为${VERSION_TYPE_ZHCN_MAP[VERSION_TYPE_ENUMS.PRE_RELEASE]}，不做弹窗提示`)
    return false
  }
  // 2.是否仍在有效截止时间内
  const currentTimeStamp = Date.now()
  const isValid = moment(currentTimeStamp).isBefore(moment(data.endTime))
  if (!isValid) {
    console.warn(`版本更新检测：当前通知已过有效期，不做弹窗提示`)
    return false
  }

  // 验证通过
  return true
}

export default startPolling;
