<template>
  <div class="container">
    <form-index
      ref="formindex"
      :formData="formData"
      @handleSubmit="handleSubmit"
      :projectList="projectList"
      :flexSubmit="true"
      @handlSelect="handlSelect"
      @handleChange="handleChange"
    >
      <!-- 下部分按钮插槽 -->
      <template #flexSubmit>
        <a-space  style="margin-left: 90px; margin-top: 5px">
          <a-button icon="search" type="primary" html-type="submit"> 查询 </a-button>
          <a-space class="operator">

            <a-button @click="close"> 重置 </a-button>
          </a-space>
        </a-space>
      </template>
    </form-index>
    <div style="margin-top:-20px" class="a_a_footer">
      <div class="mb-1 mt-2">
        <a-space class="operator">
          <a-button @click="batchEntrance(1)"><a-icon type="login" />批量重新入场</a-button>
        </a-space>
        <a-space class="operator">
          <a-button @click="uploadJw" v-if="canJwOperate"><a-icon type="file-add" />申请导入建委</a-button>
        </a-space>
        <!-- <a-space class="operator">
          <a-button @click="doDowloadBatch(3)">导出</a-button>
        </a-space> -->
        <a-space class="operator">
          <a-button @click="delAccess()"><a-icon type="user-delete" />批量删除考勤权限</a-button>
        </a-space>
        <a-space class="operator">
          <a-button @click="exportStaff()"><a-icon type="download" />导出全部信息</a-button>
        </a-space>
        <a-space class="operator">
          <a-button @click="batchExit" ghost style="background: #fd9e06 !important; color: #fff; border-color:#fd9e06"><a-icon type="logout" />批量退场入口</a-button>
        </a-space>
        <a-space class="operator">
          <a-button @click="preExit" ghost style="background: #fd9e06 !important; color: #fff; border-color:#fd9e06"><a-icon type="logout" />通知工人离场</a-button>
        </a-space>
        <!-- <a-space class="operator">
          <a-button @click="doDowloadBatch(4)">删除出入权限失败记录</a-button>
        </a-space> -->
      </div>
      <!-- 表格封装 -->
      <standard-table
        class=""
        :columns="columns"
        :dataSource="dataSource"
        :selectedRows.sync="selectedRows"
        rowKey="id"
        :scroll="{ x: 1300 }"
        :pagination="pagination"
        :loading="tableLoading"
        @change="onPageChange"
        :isAlert="false"
        :bordered="true"
        @update:selectedRows="updateSelectRows($event)"
      >
        <template slot="index" slot-scope="{ index }">
          <span>
            {{
              (pagination.current - 1) * pagination.pageSize +
                parseInt(index) +
                1
            }}
          </span>
        </template>
        <template slot="workName" slot-scope="{ record }">
          <router-link :to="{ name: '电子履历', params: { projWorkNum: record.projWorkNum}, query: { teamId: record.teamId,workNum: record.workNum } }">
            {{ record.workName }}
          </router-link>
          <div v-if="record.isCaptain == 1" style="color: #FF5C77">(班组长)</div>
        </template>
        <template slot="leaveType" slot-scope="{ text }">
          <span v-if="text == 2">通知退场</span>
          <span v-else>劳务员退场</span>
        </template>
        <template slot="workerFace" slot-scope="{ text }">
          <a-avatar shape="square" :size="64" :src="text" icon="user"/>
        </template>
        <template slot="isreport" slot-scope="{ text }">
          <span v-if="text == 1"><i class="iconfont duigouxiao" style="color:#00CE86"></i></span>
          <span v-else><i class="iconfont path" style="color:#FF5C77"></i></span>
        </template>
        <template slot="syncJwStatus" slot-scope="{ text, record }">
          <div class="jwTag" :class="getJwStatusStyle(text)" @click="errToast(record)">
            <span>{{ getJwStatusText(text) }}</span>
          </div>
        </template>
        <div slot="action" slot-scope="{ record }">
          <div class="sTables">
            <!-- <div @click="visit(record)">查看</div> -->
            <!-- <div @click="visitEdit(record)">修改</div> -->
            <a-popover placement="bottomRight" trigger="click">
              <template slot="content">
                <div class="content_div" @click="batchEntrance(3,record)">重新进场</div>
                <div class="content_div" @click="doDowload(record)">下载退场凭证</div>
                <!-- <div class="content_div">下载一人一档</div> -->
                <div class="content_div" @click="eventRecord(record)">事件记录</div>
                <!-- <div class="content_div">重新上传退场凭证</div> -->
                <!-- <div class="content_div" @click="delAccess(record)">删除人员权限</div> -->
                <div class="content_div" @click="openPreExitMotal(record)">查看退场记录</div>
              </template>
              <template slot="title">
                <span>操作</span>
              </template>
              <div>更多></div>
            </a-popover>
          </div>
        </div>
      </standard-table>
    </div>
    <distribute-dialog
      :visible="distributeVisible"
      @cancel="distributeVisible = false"
      :isDel="true"
      :modalTitle="'删除人员权限'"
      :workNumList="workNumList"/>
    <persence-list-modal
      ref="persenceModal"
      :visible="visibleExit"
      :visibleTitle="visibleTitle"
      @handleCancel="handleCancel"
      @handleOk="handleOk"/>
      <Export-loading :cPercent="cPercent" :isExporting="isExporting" :loadingText="loadingText"></Export-loading>
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import { getProjectStaffList, enterStaff, reportJwExitStaff, getProAllOfficeList } from "@/services/participationUnit";
import { getAllTeams, getJobByWorkerType } from "@/services/participationUnit"
// import { getOfficeListByProjectId } from '@/services/payroll'
import DistributeDialog from './dialogs/distributeDialog.vue';
import PersenceListModal from './dialogs/presenceListModal.vue';
import { mapGetters } from "vuex";
import moment from "moment";
import { EXPORT_STAFF_ALL_MSG} from '@/services/api'
import { exportProgress } from "@/pages/aqjyzl/export"; // 导出方法
import ExportLoading from '@/components/ExportLoading/ExportLoading' // progress组件
import { useRender } from '@/hooks/useRender.js'

const formData = [
  {
    label: "姓名",
    placeholder: "请填写",
    decorator: [
      "workerName",
      { rules: [{ required: false, message: "请填写" }] },
    ],
    type: "input",
    key: "workerName",
    col: 6,
    labelCol: 8,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "单位",
    placeholder: "请输入所属单位",
    decorator: [
      "unitId",
      { rules: [{ required: false, message: "请选择所属单位" }] },
    ],
    type: "inputGroup",
    key: "unitId",
    dataSource: [],
    col: 6,
    labelCol: 8,
    wrapperCol: 14,
  },
  {
    label: "班组",
    placeholder: "请输入选择所属班组",
    decorator: ["teamId", { rules: [{ required: false, message: "请输入选择所属班组" }] }],
    type: "inputGroup",
    key: "teamId",
    selectlist: [],
    dataSource: [],
    col: 6,
    labelCol: 8,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "工种",
    placeholder: "请选择",
    decorator: [
      "title",
      { rules: [{ required: false, message: "请选择" }] },
    ],
    type: "select",
    key: "title",
    selectlist: [],
    col: 6,
    labelCol: 8,
    wrapperCol: 14,
    display: true,
    showSearch: true
  },
  {
    label: "上报建委",
    placeholder: "请选择",
    decorator: [
      "isReportInfo",
      { rules: [{ required: false, message: "请选择" }] },
    ],
    type: "select",
    selectlist: [{
      value: 1,
      name: '是'
    },{
      value: 0,
      name: '否'
    }],
    key: "isReportInfo",
    col: 6,
    labelCol: 8,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "导入建委状态",
    placeholder: "请选择导入建委状态",
    decorator: [
      "syncJwStatus",
      { rules: [{ required: false, message: "请选择" }] },
    ],
    type: "select",
    selectlist: [{
      value: 0,
      name: '未导入'
    }, {
      value: 2,
      name: '处理中'
    }, {
      value: -1,
      name: '失败'
    }, {
      value: 1,
      name: '完成'
    }],
    key: "syncJwStatus",
    col: 6,
    labelCol: 8,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "进场日期",
    placeholder: "",
    decorator: ["enterDate", { rules: [{ required: false, message: "" }] }],
    type: "rangePicker",
    key: "enterDate",
    dataSource: [],
    col: 6,
    labelCol: 8,
    wrapperCol: 14,
    display: true,
    format: "YYYY-MM-DD",
  },
];
const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
  },
  {
    title: "姓名",
    dataIndex: "workName",
    align: "center",
    width: 120,
    scopedSlots: { customRender: "workName" },
  },
  {
    title: "头像",
    dataIndex: "workerFace",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "workerFace" },
  },
  {
    title: "所属单位",
    dataIndex: "officeName",
    align: "center",
    scopedSlots: { customRender: "officeName" },
  },
  {
    title: "所属班组",
    align: "center",
    dataIndex: "teamName",
    scopedSlots: { customRender: "teamName" },
  },
  {
    title: "工种",
    dataIndex: "workTypeName",
    align: "center",
    ellipsis: true,
    width: 120,
    scopedSlots: { customRender: "workTypeName" },
  },
  {
    title: "进场时间",
    dataIndex: "enterDate",
    align: "center",
    width: 120,
    customRender: (text) => useRender.renderDate(text, 'YYYY-MM-DD')
  },
  {
    title: "退场时间",
    dataIndex: "quitDate",
    align: "center",
    width: 120,
    customRender: (text) => useRender.renderDate(text, 'YYYY-MM-DD')
  },
  {
    title: "是否上报建委",
    dataIndex: "isreport",
    align: "center",
    width: 130,
    scopedSlots: { customRender: "isreport" },
  },
  {
    title: "导入建委状态",
    dataIndex: "syncJwStatus",
    align: "center",
    width: 130,
    scopedSlots: { customRender: "syncJwStatus" },
  },
  {
    title: "退场类型",
    dataIndex: "leaveType",
    align: "center",
    width: 120,
    scopedSlots: { customRender: "leaveType" },
  },
  // {
  //   title: "入场资料完成度",
  //   dataIndex: "infoPercent",
  //   align: "center",
  //   scopedSlots: { customRender: "infoPercent" },
  // },
  {
    title: "操作",
    align: "center",
    fixed: "right",
    width: 140,
    scopedSlots: { customRender: "action" },
  },
];
export default {
  mixins: [exportProgress], //导出方法注入
  components: {
    formIndex,
    StandardTable,
    DistributeDialog,
    PersenceListModal,
    ExportLoading
  },
  data() {
    return {
      projectArr: [],
      projectList: [], //获取当前项目数据
      visibleExit: false,
      visibleTitle: '批量退场',
      formData: formData,
      columns: columns,
      dataSource: [], //table数组
      selectedRows: [], //选中的数据
      pagination: {
        //分页数据
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
        showTotal: (total, range) => `共 ${total} 条`
      },
      params: { //测试数据
        pageNumber: 1,
        pageSize: 10,
        enterStatus: '0'
      },
      tableLoading: false, //table加载中
      selectedRecord: null,
      distributeVisible: false,
      workNumList: [], //选择的工号list
      arrJwOperate: ['67f43348a8fd450f9cac17a9215ba609'], // 有操作权限的项目ID
      cPercent: 0,
      loadingText:"请稍后.",
      isExporting: false,
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
    canJwOperate() {
      return this.arrJwOperate.some(role => role == this.user.projectId)
    }
  },
  mounted() {
    this.getData(this.params)
    this.getZdata(getProAllOfficeList, {value: '', item: {key: 'unitId'}}, "officeName");
    this.getWorkerType()
    // 没有导入建委状态权限的操作
    if (!this.canJwOperate) {
      let keyToDelete = 'syncJwStatus'
      this.columns = this.columns.filter(item => item.dataIndex !== keyToDelete)
      this.formData = this.formData.filter(item => item.key !== keyToDelete)
    }
  },
  methods: {
    //批量退场
    batchExit() {
      this.visibleTitle = '批量退场'
      this.visibleExit = true
    },
    handleCancel() {
      this.visibleExit = false
    },
    handleOk() {
      this.visibleExit = false
      this.getData(this.params)
    },
    //事件记录
    eventRecord(record) {
      this.$router.push({
        name:'诚信行为登记',
        query:{
          id: record.id,
          name: record.workName,
          unitId: record.unitId,
          teamId: record.teamId
        }
      })
    },
    doDowload(record) {
      if (record.quitPhoto) {
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = record.quitPhoto;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        this.$message.warning('暂无退场凭证！')
      }
    },
    getData(params) {
      this.tableLoading = true;
      getProjectStaffList(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
          this.dataSource.forEach((item) => {
            item.riskTips = item.riskTips ? item.riskTips : '/'
            item.officeName = item.officeName ? item.officeName : '/'
            item.teamName = item.teamName ? item.teamName : '待分配'
            // if (item.isreport && item.isreport == '1') {
            //   item.isreport = '是'
            // } else if (item.isreport && item.isreport == '0') {
            //   item.isreport = '否'
            // } else {
            //   item.isreport = '/'
            // }
            item.enterDate = moment(item.enterDate).format("YYYY-MM-DD");
            item.quitDate = moment(item.quitDate).format("YYYY-MM-DD");
          });
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    getJwStatusStyle(text) {
      switch(text) {
        case -1:
          return 'tagRed'
        case 0:
          return 'tagGray'
        case 1:
          return 'tagGreen'
        case 2:
        case 3:
        case 4:
        case 5:
          return 'tagBlue'
        default:
          return 'tagGray'
      }
    },
    getJwStatusText(text) {
      switch(text) {
        case -1:
          return '失败'
        case 0:
          return '未导入'
        case 1:
          return '完成'
        case 2:
        case 3:
        case 4:
        case 5:
          return '处理中'
        default:
          return '未导入'
      }
    },
    errToast(record) {
      if (record.syncJwErrInfo) {
        const h = this.$createElement;
        this.$info({
          content: h('div', {}, [
            h('p', record.syncJwErrInfo),
          ]),
          onOk() {},
        });
      }
    },
    uploadJw() {
      if (this.selectedRows.length > 0) {
        const _that = this
        let recordIdList = []
        this.selectedRows.forEach(el => {
          recordIdList.push(el.recordId)
        })
        this.$confirm({
          title: '是否申请导入建委？',
          cancelText: '否',
          okText: '是',
          onOk: () => {
            reportJwExitStaff(recordIdList).then(res => {
              if (res.data.code === 0) {
                _that.$message.success('设置成功')
                this.getData(this.params)
              } else {
                _that.$message.error(res.data.msg)
              }
            })
          },
        })
      } else {
        this.$message.error('请选择人员！')
      }
    },
    handleSubmit(e) {
      let obj = { ...e, ...this.params }
      obj.pageNumber = 1
      this.pagination.current = 1;
      obj.enterDate = obj.enterDate === '' ? [] : obj.enterDate
      this.getData(obj);
    },
    //查询所有班组
    doQueryTeams(params){
      getAllTeams(params).then(res => {
        if(res.data.code === 0){
          this.formDataFn('teamId', res.data.data, 'teamName')
        }
      })
    },
    handleChange(e) {
      if (e.item.key == 'teamId') {
        this.doQueryTeams({ teamName: e.value })
      } else if (e.item.key == 'unitId') {
        this.projectArr = [];
        if (!e.value) {
          this.$refs["formindex"].setFieldsValue('', {}, "unitId");
        }
        this.getZdata(getProAllOfficeList, e, "officeName");
      }
    },
    // 接口封装
    /**
     * msg 接收接口名称
     * e 数据
     * name 参数名
     * */
     getZdata(msg, e, name) {
      let data = {
        [name]: e.value,
        projectId: this.user.projectId
      };

      msg(data).then((res) => {
        if (res.status == 200) {
          const data = res.data.data.list ? res.data.data.list : res.data.data
          data.forEach((item) => {
            this.projectArr.push({
              title: item[name],
              id: item.officeId,
            });
          });
          this.projectList = data;
          // 获取当前匹配key，赋值相对于数组
          this.formData.forEach((item) => {
            if (e.item.key === item.key) {
              item.dataSource = this.projectArr;
            }
          });
        }
      });
    },
    // 对下拉框做数据处理
    formDataFn(key, data, itemName) {
      let arrSelect = []
      let arrSource = []
        data.forEach(item => {
          // 班组模糊匹配dataSource
          arrSource.push({ 'title': item[itemName], 'id': item.id })
          // seletetList选项
          arrSelect.push({ 'name': item[itemName], 'value': item.id })
        })
        this.formData.forEach(item => {
            if (item.key == key) {
              item.dataSource = arrSource
              item.selectlist = arrSelect
            }
        })
    },
    // 特殊数据处理
    handlSelect({ val, keys }) {
      this.projectList.forEach((item) => {
        if (keys == "unitId" && item.officeId == val) {
          this.$refs["formindex"].setFieldsValue(item.officeId, {}, "unitId");
          this.doQueryTeams({ officeId: val })
        }
      });
    },
    //获取工种
    getWorkerType() {
      getJobByWorkerType([]).then(res => {
        console.log(res)
        if(res.data.code === 0){
          this.formDataFn('title', res.data.data, 'carft')
        }
      })
    },
    batchEntrance(num,record) {
      let params = []
      if (record) {
        params.push(record)
      } else {
        if (this.selectedRows.length > 0) {
          params = this.selectedRows
        } else {
          this.$message.error('请选择人员')
          return
        }
      }
      const _that = this
      this.$confirm({
        title: '是否重新进场？',
        onOk() {
          // num=3 重新进场编辑
          if (num == 3) {
            _that.$router.push({
            name:'进场登记',
            query:{
              projWorkNum: record.projWorkNum,
              teamId: record.teamId,
              workNum: record.workNum,
              operateType: 3 // 重新进场编辑
            }
            })
          } 
          else {
            // 否则批量进场
            enterStaff(params).then(res => {
            if (res.data.code === 0) {
              _that.selectedRecord = null
              _that.$message.success('重新进场成功！')
              if (_that.params.pageNumber > 1) {
                _that.params.pageNumber = 1
              }
              _that.getData(_that.params)
              _that.$emit('refreshPresenceList')
            } else {
              _that.$message.error(res.data.msg)
            }
          })
          }
        }
      })
    },
    //表格 - 分页查询
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      if (obj.status == "") {
        obj.status = 0;
      }
      if (obj.enterDate == "") {
        obj.enterDate = [];
      }
      this.params.pageSize = e.pageSize
      this.pagination.pageSize = e.pageSize;
      this.params.pageNumber = e.current;
      this.pagination.current = e.current;
      this.getData(Object.assign({}, this.params, obj));
    },
    updateSelectRows(e) {
      console.log(e)
    },
    //删除人员权限
    delAccess() {
      if (this.selectedRows.length > 0) {
        this.workNumList = []//先初始化这个list
        this.selectedRows.forEach(el => {
          this.workNumList.push(el.workNum)
        })
        this.distributeVisible = true
      } else {
        this.$message.error('请选择人员！')
      }
    },
    //查看
    visit(record) {
      this.$router.push({
        name:'电子履历',
        params: {
          projWorkNum: record.projWorkNum
        },
        query:{
          teamId: record.teamId,
          workNum: record.workNum
        }
      })
    },
    // 重置
    close() {
      this.params = {
        pageNumber: 1,
        pageSize: 10,
        enterStatus: '0'
      };
      this.pagination.current = 1;
      this.getData(this.params);
      this.$refs["formindex"].resetFields();
    },
    // 导出退场花名册
    exportStaff() {
      let formObj = this.$refs["formindex"].getFieldValue()
      this.loadingText = "正在导出全部信息，请稍候 ····";
      let recordIdList = []
      if (this.selectedRows.length > 0) {
        this.selectedRows.forEach(el => {
          recordIdList.push(el.workNum)
        })
      }
      let fileName = '退场全部信息.xls'
      let obj = {
        officeId: formObj.unitId ? formObj.unitId : undefined,
        projectId: this.user.projectId,
        teamId: formObj.teamId ? formObj.teamId : undefined,
        workNums: recordIdList.length ? recordIdList : undefined,
        enterStatus: 0, // 退场人员
        enterDateStart: formObj.enterDate[0] ? formObj.enterDate[0] : undefined,
        enterDateEnd: formObj.enterDate[1] ? formObj.enterDate[1] : undefined
      }
      this.isExporting=true;
      this.cPercent=0;
      this.exportFunNomal2(EXPORT_STAFF_ALL_MSG,obj,fileName)
      this.selectedRows = []
    },
    // 发起预退场
    preExit() {
      this.visibleTitle = '批量通知工人离场'
      this.visibleExit = true
    },
    openPreExitMotal(record) {
      console.log(record,'record')
      this.$nextTick(() => {
        this.$refs.persenceModal.visitPre(record)
      });
    }
  },
};
</script>

<style lang="less" scoped>
.a_a_footer {
  padding: 0 0px;
  background: #ffffff;

  .operator {
    margin-right: 8px;

    .ant-btn {
      color: #307dfa;
    }
  }

  .sTables {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      width: 100%;
      text-align: center;
      cursor: pointer;
    }

    div:nth-child(1) {
      color: #307dfa;
    }

    // div:nth-child(2) {
    //   color: #ff5c77;
    // }

    div:nth-child(2) {
      color: #615e83;
    }
  }

}
.content_div {
  padding: 5px 0;
  cursor: pointer;
}
.content_div:hover {
  color: #307dfa;
}
.jwTag {
  border-radius: 2px;
  width: 65px;
  height: 30px;
  line-height: 28px;
  margin: auto;
  cursor: pointer;
  font-size: 14px;
}
.tagRed {
  background: #FFE8EC;
  color: #FF5D78;
}
.tagGray {
  background: #EAEBEB;
  color: #989999;
}
.tagGreen {
  background: #DEFCF1;
  color: #00CE86;
}
.tagBlue {
  background: #0874F01A;
  color: #0874F0;
}
</style>
