<template>
  <a-card>
    <div :class="advanced ? 'search' : null">
      <a-form layout="horizontal">
        <div :class="advanced ? null: 'fold'">
          <a-row >
          <a-col :md="6" :sm="24" >
            <a-form-item
              label="姓名"
              :labelCol="{span: 5}"
              :wrapperCol="{span: 18, offset: 1}"
            >
              <a-input placeholder="请输入" v-model="queryParams.userName"/>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24" >
            <a-form-item
              label="身份证号"
              :labelCol="{span: 5}"
              :wrapperCol="{span: 18, offset: 1}"
            >
              <a-input placeholder="身份证号" v-model="queryParams.idCard"/>
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="24" >
            <a-form-item
              label="进场日期"
              :labelCol="{span: 5}"
              :wrapperCol="{span: 18, offset: 1}"
            > 
              <!-- <a-range-picker v-model="queryParams.inDate" /> -->
              <a-range-picker @change="onTimeChange" :default-value="[moment(nowMonthDay, monthFormat), moment(nowMonthDay, monthFormat)]" v-model="queryParams.inDate"/>
              <!-- <a-date-picker :default-value="moment(nowMonthDay, monthFormat)" :format="monthFormat" v-model="queryParams.enterDateStr"/> -->
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="24" >
            <a-form-item
              label="班组名称"
              :labelCol="{span: 5}"
              :wrapperCol="{span: 18, offset: 1}"
            >
              <a-select
                show-search
                placeholder="请输入或选择班组"
                option-filter-prop="children"
                style="width: 200px"
                :filter-option="filterOption"
                @change="handleChange"
              >
                <a-select-option :key="item.id" v-for="item in teamDatas" :value="item.id">
                  {{item.teamName}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        </div>
        <span style="float: right; margin-top: 3px;">
          <a-button type="primary" @click="doQuery()">查询</a-button>
          <a-button style="margin-left: 8px" @click="doReset()">重置</a-button>
        </span>
      </a-form>
    </div>
    <div>
      <span>
        <a-button type="primary" @click="exportBatchStaff()">批量导出</a-button>
      </span>
      <a-table
        :columns="columns"
        bordered
        :data-source="dataSource"
        :defaultExpandAllRows="defaultExpandAllRows"
        class="components-table-demo-nested"
        @expandedRowsChange="expandedRowsChange"
        :rowSelection="{selectedRowKeys: selectedRowKeys,onSelectAll:onSelectAll, onSelect:onSelect}"
        style="margin:20px"
      >
        <a slot="operation" slot-scope="row" @click="exportStaff(row)">导出人员</a>
        <a-table
          slot="expandedRowRender"
          slot-scope="inner"
          :columns="innerColumns"
          :data-source="inner.innerData"
          :pagination="false"
        >
          <template slot="operation" slot-scope="record2" class="table-operation">
            <a @click="dormitory(record2)">宿舍安排</a>
          </template>
        </a-table>
      </a-table>
    </div>
    <Export-loading :cPercent="cPercent" :isExporting="isExporting" :loadingText="loadingText"></Export-loading>
  </a-card>
</template>
<script>

import {
  FIND_TEAMS_AND_STAFFS,
  FIND_TEAMS_BY_USER,
  FIND_TEAMS_AND_STAFFS_EXPORT,
  } from '@/services/api'
import { exportProgress } from '@/pages/aqjyzl/export' // 导出方法
import ExportLoading from '@/components/ExportLoading/ExportLoading' // progress组件
import {request} from '@/utils/request'
import moment from 'moment';
import { useRender } from '@/hooks/useRender.js'

const columns = [
  {title: '班组名称',dataIndex: 'teamName',align: 'center',width:220,scopedSlots: { customRender: 'teamName' }},
  {title: '班组负责人',dataIndex: 'teamLeader',align: 'center',scopedSlots: { customRender: 'teamLeader' }},
  {title: '班组联系电话',dataIndex: 'phoneNumber',align: 'center',scopedSlots: { customRender: 'phoneNumber' }},
  {title: '操作',align: 'center',key: 'operation', scopedSlots: { customRender: 'operation' } }
]

const innerColumns = [
  { title: '姓名', dataIndex: 'name',align: 'center', key: 'name' },
  { title: '性别', dataIndex: 'gender',align: 'center', key: 'gender' },
  { title: '身份证号', dataIndex: 'idCard',align: 'center', key: 'idCard' },
  { title: '手机号', dataIndex: 'phone',align: 'center', key: 'phone' },
  { title: '入场日期', dataIndex: 'enterDate', key: 'enterDate',align: 'center', customRender: (text) => useRender.renderDate(text) },
  { title: '操作', dataIndex: 'operation', scopedSlots: { customRender: 'operation' }, align: 'center' },
];

export default {
   mixins: [exportProgress],
  name: 'QueryList',
  components: {ExportLoading},
  created() {
    this.queryParams.enterDateStart = moment(new Date()).format('YYYY-MM-DD');
    this.queryParams.enterDateEnd = moment(new Date()).format('YYYY-MM-DD');
    this.nowMonthDay = moment(new Date()).format('YYYY-MM-DD');
    this.doQuery()
    this.doQueryTeams();
  },
  data() {
    return {
      dataSource:[],
      columns: columns,
      innerColumns,
      innerData:[],
      monthFormat: 'YYYY-MM-DD',
      nowMonthDay:null,
      defaultExpandAllRows:false,
      tableLoading:false,
      selectedRows: [],
      expandedRowKeys:[],
      teamDatas:[],
      loadKeys:[],
      advanced: false,
      loadingText:"请稍后.",
      queryParams:{
        teamId:null,
        idCard:null,
        userName:null,
        enterDateStart:null,
        enterDateEnd:null,
        inDate:null,
      },
      cPercent:0,
      isExporting:false,
      fileName:'班组人员列表文件',
    }
  },
  computed: {
    selectedRowKeys() {
      return this.selectedRows.map(record => {
        return (typeof this.rowKey === 'function') ? this.rowKey(record) : record["id"]
      })
    },
  },
  authorize: {
    deleteRecord: 'delete'
  },
  watch: {
    cPercent:{
      handler(newName, oldName) {
        this.cPercent=newName;
      },
      immediate: true,
    },
    loadingText:{
      handler(newName, oldName) {
        this.loadingText=newName;
      },
      immediate: true,
    }
  },
  methods: {
    moment,
    expandedRowsChange(expandedRowKeys) {
      this.expandedRowKeys = expandedRowKeys
    },
    handleChange(value) {
      this.queryParams.teamId = value;
      this.doQuery();
    },
    onSelectAll(selected,selectedRows,changeRows){
      if(selected){
        this.selectedRows = this.dataSource;
      }else{
        this.selectedRows = [];
      }
    },
    deleteRecord(key) {
      this.dataSource = this.dataSource.filter(item => item.key !== key)
      this.selectedRows = this.selectedRows.filter(item => item.key !== key)
    },
    onSelect($event){
      let isHas = this.selectedRows.filter(item => item.key == $event.key)
      if(isHas&&isHas.length>0){
        this.selectedRows = this.selectedRows.filter(item => item.key !== $event.key)
      }else{
        this.selectedRows.push($event)
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    doQueryTeams(){
      request(FIND_TEAMS_BY_USER,"post",{}).then(res => {
        if(res.data.code==0){
          this.teamDatas = res.data.data;
        }
      })
    },
    /**
     * 获取所有人员的签名记录信息
     */
    doQuery(){
      this.tableLoading = true;
      request(FIND_TEAMS_AND_STAFFS,"post",this.queryParams).then(res => {
        if(res.data.code==0){
          this.dataSource = [];
           res.data.data.forEach(obj=>{
            let obj1 = {
              key:obj.teamInfo.id,
              id:obj.teamInfo.id,
              teamName:obj.teamInfo.teamName,
              teamLeader:obj.teamInfo.teamLeader,
              phoneNumber:obj.teamInfo.phoneNumber,
              innerData:[],
              staffLibVoList:obj.staffLibVoList
            }
            obj.staffLibVoList.forEach(obj=>{
              let objNodes = {
                  key:obj.staffLib.workNum,
                  id:obj.staffLib.workNum,
                  name:obj.staffLib.name,
                  phone:obj.staffLib.phoneNumber,
                  idCard:obj.staffLib.idCard,
                  gender:obj.staffLib.gender,
                  enterDate:obj.teamStaffRelation.enterDate
                }
                obj1.innerData.push(objNodes);
            })
            this.dataSource.push(obj1);
          })
        }
        this.tableLoading = false;
        this.defaultExpandAllRows = false;
      })
    },
    // getInnerData(expanded, record){
    //   if(expanded){
    //     let rows = this.dataSource.filter(o=>o.key==record.key);
    //     rows[0].innerData=[];
    //     rows[0].staffLibVoList.forEach(obj=>{
    //       let objNodes = {
    //           key:obj.staffLib.workNum,
    //           id:obj.staffLib.workNum,
    //           name:obj.staffLib.name,
    //           phone:obj.staffLib.phoneNumber,
    //           idCard:obj.staffLib.idCard,
    //           gender:obj.staffLib.gender,
    //           enterDate:obj.teamStaffRelation.enterDate
    //         }
    //         rows[0].innerData.push(objNodes);
    //     })
    //   }else{
        
    //   }
      
    // },
    exportBatchStaff(){
      this.loadKeys = [];
      this.cPercent = 0;
      if(this.selectedRows&&this.selectedRows.length>0){
        this.selectedRows.map((item) => {
          this.loadKeys = [];
          this.loadKeys.push(item.key);
          let obj = {
            teamIds:this.loadKeys,
            enterDateStart:this.queryParams.enterDateStart,
            enterDateEnd:this.queryParams.enterDateEnd
          }
          this.cPercent=0;
          let fileName = ""
          fileName=item.teamName+"人员列表.zip";
          this.exportFunNomal(FIND_TEAMS_AND_STAFFS_EXPORT,obj,fileName,this.selectedRows)
        })
      }else{ 
        this.$message.info('请选中班组进行下载');
        return;
      }

    },
    exportStaff(recode){
      let selectedRows = this.dataSource.filter(item => item.key == recode.key);
      this.loadingText = "正在导出文件，请稍候 ····";
      this.loadKeys = [];
      this.loadKeys.push(recode.key);
      let obj = {
        teamIds:this.loadKeys,
        enterDateStart:this.queryParams.enterDateStart,
        enterDateEnd:this.queryParams.enterDateEnd
      }
      this.isExporting=true;
      this.cPercent=0;
      let fileName = ""
      fileName=recode.teamName+"人员列表.zip";
      this.exportFunNomal(FIND_TEAMS_AND_STAFFS_EXPORT,obj,fileName,selectedRows)
    },
    dormitory(recode){

    },
    onTimeChange(date, dateString){
      this.queryParams.enterDateStart = dateString[0]
      this.queryParams.enterDateEnd = dateString[1]
    }
  }
};
</script>
