var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "create_index" },
    [
      _c("form-index", {
        ref: "formindex",
        attrs: {
          options: _vm.options,
          formData: _vm.formData,
          Overview: "项目概况",
          baiduMapBoolen: true,
          topSubmit: true,
          topFlexSubmit: true,
          projectList: _vm.projectList,
          defaultFileList: _vm.defaultFileList,
        },
        on: {
          handleChange: _vm.handleChange,
          handleSubmit: _vm.handleSubmit,
          handlSelect: _vm.handlSelect,
          handleSearch: _vm.handleSearch,
        },
        scopedSlots: _vm._u([
          {
            key: "topSubmit",
            fn: function () {
              return [
                _c(
                  "a-space",
                  [
                    _vm.canOperate
                      ? _c(
                          "a-button",
                          {
                            attrs: {
                              type: "primary",
                              icon: "save",
                              "html-type": "submit",
                            },
                          },
                          [_vm._v(" 保存 ")]
                        )
                      : _vm._e(),
                    _vm.canOperate
                      ? _c(
                          "a-button",
                          {
                            attrs: { type: "danger", icon: "sync" },
                            on: { click: _vm.syncProject },
                          },
                          [_vm._v(" 同步海康平台 ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }