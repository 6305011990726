<!-- eslint-disable vue/no-template-key -->
<template>
  <div class="a_a_top">
    <form-index
      ref="formindex"
      :formData="formData"
      :projectList="projectList"
      @handleSubmit="handleSubmit"
      :flexAction="true"
      @handlSelect="handlSelect"
      @handleChange="handleChange"
    >
      <!-- 下部分按钮插槽 -->
      <template #flexAction>
        <a-space :style="{ marginTop: '4px' }">
          <a-button type="primary" html-type="submit"> 查询 </a-button>
          <a-space class="operator">
            <a-button @click="close"> 重置 </a-button>
          </a-space>
          <a-button @click="exportExcelStaff">导出</a-button>
        </a-space>
      </template>
    </form-index>
    <div class="a_a_footer">
      <div>
        <!-- <a-space class="operator">
          <a-button @click="exportExcel">导出</a-button>
        </a-space> -->
        <!-- <a-space class="operator">
          <a-button @click="doDowloadBatch(3)">打印</a-button>
        </a-space> -->
      </div>
      <!-- 表格封装 -->
      <standard-table
        class=""
        :columns="columns"
        :dataSource="dataSource"
        rowKey="index"
        :scroll="{ x: 1800, y: 'calc(100vh - 450px)' }"
        :pagination="pagination"
        :loading="tableLoading"
        @change="onPageChange"
        :isAlert="false"
        :bordered="true"
      >
        <template slot="title">
          <h3 class="table-title" style="margin-bottom: 0">{{ tableTitle }}</h3>
          <div>
            <a-icon class="red mr-1" type="close-circle" />全天无考勤
            <a-icon class="yellow ml-2 mr-1" type="exclamation-circle" />缺少考勤
            <a-icon class="green ml-2 mr-1" type="check-circle" />考勤完整
          </div>
          <div class="table-desc">
            <span>项目名称（盖章）：{{user.loginType == 0 ? user.loginLaoWuUser.projectName : ''}}</span>
            <div>
              <span v-show="params.officeName">单位：{{params.officeName ? params.officeName : '全部'}}</span>
              <span style="margin-left: 50px;" v-show="params.teamName">班组：{{params.teamName ? params.teamName : '全部'}}</span>
            </div>
          </div>
        </template>
        <template slot="index" slot-scope="{ index }">
          <span>{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}</span>
        </template>
        <template v-for="(item,index) in columns" :slot="item.dataIndex" slot-scope="{ record}">
            <div :key="index">
              <span v-if="item.dataIndex === 'index'">{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}</span>
              <span v-else-if="item.dataIndex === 'name'">{{ record.name }}</span>
              <span v-else-if="item.dataIndex === 'total'">{{ record.total }}</span>
              <span v-else class="table-content" :class="getStatusClass(record[item.dataIndex])">
                <a-icon v-if="record[item.dataIndex] == 0" type="close-circle" />
                <a-icon v-else-if="record[item.dataIndex] == 1" type="check-circle" />
                <a-icon v-else-if="record[item.dataIndex] == 2" type="exclamation-circle" />
              </span>
            </div>
          </template>
      </standard-table>
    </div>
    <Export-loading :cPercent="cPercent" :isExporting="isExporting" :loadingText="loadingText"></Export-loading>
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import { getStatistics } from "@/services/dmgr";
import { getAllTeams, getProAllOfficeList } from "@/services/participationUnit";
import { getOfficeListByProjectId } from "@/services/payroll";
import moment from "moment";
import {mapGetters} from 'vuex'
import {EXPORT_ATTE_STATISTICS} from '@/services/api'
import {request} from '@/utils/request'
import { exportProgress } from '@/pages/aqjyzl/export' // 导出方法
import ExportLoading from '@/components/ExportLoading/ExportLoading' // progress组件
const formData = [
  {
    label: "月份",
    placeholder: "请选择",
    decorator: [
      "month",
      {
        initialValue: moment(moment()).format("YYYYMM"),
        rules: [{ required: false, message: "请选择" }],
      },
    ],
    type: "monthPicker",
    key: "month",
    col: 4,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
    format: "YYYYMM",
  },
  {
    label: "姓名",
    placeholder: "请输入姓名",
    decorator: ["name", { rules: [{ required: false, message: "请输入姓名" }] }],
    type: "input",
    key: "name",
    col: 4,
    labelCol: 6,
    wrapperCol: 16,
    display: true,
  },
  {
    label: "所属单位",
    placeholder: "请输入所属单位",
    decorator: [
      "officeId",
      { rules: [{ required: false, message: "请选择" }] },
    ],
    type: "inputGroup",
    key: "officeId",
    dataSource: [],
    col: 4,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "班组名称",
    placeholder: "请输入选择所属班组",
    decorator: ["teamId", { rules: [{ required: false, message: "请输入选择所属班组" }] }],
    type: "inputGroup",
    key: "teamId",
    selectlist: [],
    dataSource: [],
    col: 4,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  // {
  //   label: "状态",
  //   placeholder: "请选择",
  //   decorator: [
  //     "status",
  //     { initialValue: 0, rules: [{ required: false, message: "请选择" }] },
  //   ],
  //   type: "select",
  //   key: "status",
  //   selectlist: [
  //     {
  //       name: "全部",
  //       value: 0,
  //     },
  //     {
  //       name: "当月进场",
  //       value: 1,
  //     },
  //     {
  //       name: "当月退场",
  //       value: 2,
  //     },
  //   ],
  //   col: 4,
  //   labelCol: 6,
  //   wrapperCol: 14,
  //   display: true,
  // },
  {
    type: 'action',
    col: 4,
    display: true,
  }
];
const columns = [
  {
    title: "序号",
    dataIndex: "index",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
    fixed: 'left'
  },
  {
    title: "姓名",
    dataIndex: "name",
    width: 85,
    align: "center",
    scopedSlots: { customRender: "name" },
    fixed: 'left'
  },
  {
    title: "合计",
    dataIndex: "total",
    align: "center",
    width: 65,
    scopedSlots: { customRender: "total" },
    fixed: 'left'
  },
];
export default {
  mixins: [exportProgress],
  components: {
    formIndex,
    StandardTable,
    ExportLoading
  },
  data() {
    return {
      projectArr: [],
      projectList: [], //获取当前项目数据
      tableTitle: "",
      formData: formData,
      columns: columns,
      dataSource: [], //table数组
      selectedRows: [], //选中的数据
      scrollY: 540,
      pagination: {
        //分页数据
        showSizeChanger:true,
        pageSizeOptions: ['10', '50', '100', '150'],
        current:1,
        pageSize:50,
        total:0,
        showLessItems:true,
        showQuickJumper:true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      params: {
        pageNumber: 1,
        pageSize: 50,
        isPage: "true",
        status: 0,
        month: moment(moment()).format("YYYYMM"),
        officeId: '',
        teamId: '',
        teamName: '',
        officeName: ''
      },
      tableLoading: false, //table加载中
      cPercent:0,
      isExporting:false,
      loadingText:"请稍后.",
    };
  },
  watch: {
    "params.month": {
      handler(newVal, oldVal) {
        if (oldVal) {
          const oldDays = moment(oldVal, "YYYY-MM").daysInMonth();
          for (let i = 0; i < oldDays; i++) {
            this.columns.pop();
          }
        }
        this.columns = [
          {
            title: "序号",
            width: 65,
            scopedSlots: { customRender: "index" },
            // customRender: (text, record, index) => `${index + 1}`,
            align: "center",
            fixed: 'left'
          },
          {
            title: "姓名",
            dataIndex: "name",
            width: 85,
            align: "center",
            scopedSlots: { customRender: "name" },
            fixed: 'left'
          },
          {
            title: "合计",
            dataIndex: "total",
            align: "center",
            width: 65,
            scopedSlots: { customRender: "total" },
            fixed: 'left'
          },
        ]
        const newDays = moment(newVal, "YYYY-MM").daysInMonth();
        this.tableTitle = moment(newVal).format("YYYY年MM月") + "工人考勤统计表";
        for (let i = 1; i <= newDays; i++) {
          let day = i < 10 ? "0" + i : i + "";
          this.columns.push({
            title: i,
            dataIndex: newVal + day,
            align: "center",
            width: 55,
            scopedSlots: { customRender: newVal + day },
          });
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.getData(this.params);
    this.getZdata(getProAllOfficeList, {value: '', item: {key: 'officeId'}}, "officeName");
  },
  computed: {
    ...mapGetters('account', ['user']),
  },
  methods: {
    getStatusClass(status) {
      switch(status) {
        case 0:
          return 'red'
        case 1:
          return 'green'
        case 2:
          return 'yellow'
        default:
          return ''
      }
    },
    getData(params) {
      this.tableLoading = true;
      getStatistics(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code === 0) {
          this.$nextTick(() => {
            this.pagination.total = res.data.data.total
            this.dataSource = res.data.data.list;
            this.dataSource.forEach((el) => {
              const dayAtte = el.dayAtte;
              dayAtte.forEach((i) => {
                // console.log(i)
                el[i["day"]] = i["status"]
              });
            });
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //查询所有单位
    getOfficeListByProjectId() {
      getOfficeListByProjectId().then((res) => {
        if (res.data.code === 0) {
          this.formDataFn("officeId", res.data.data, "name");
        }
      });
    },
    //查询所有班组
    doQueryTeams(params) {
      if (params) {
        getAllTeams(params).then((res) => {
          if (res.data.code === 0) {
            this.formDataFn("teamId", res.data.data, "teamName");
          }
        });
      } else {
        this.formDataFn("teamId", [], "teamName");
      }
    },
    handleChange(e) {
      if (e.item.key == 'teamId') {
        this.doQueryTeams({ teamName: e.value })
      } else if (e.item.key == 'officeId') {
        this.projectArr = [];
        if (!e.value) {
          this.$refs["formindex"].setFieldsValue('', {}, "officeId");
        }
        this.getZdata(getProAllOfficeList, e, "officeName");
      }
    },
    // 接口封装
    /**
     * msg 接收接口名称
     * e 数据
     * name 参数名
     * */
     getZdata(msg, e, name) {
      let data = {
        [name]: e.value,
        projectId: this.user.projectId
      };

      msg(data).then((res) => {
        if (res.status == 200) {
          const data = res.data.data.list ? res.data.data.list : res.data.data
          data.forEach((item) => {
            this.projectArr.push({
              title: item[name],
              id: item.officeId,
            });
          });
          this.projectList = data;
          // 获取当前匹配key，赋值相对于数组
          this.formData.forEach((item) => {
            if (e.item.key === item.key) {
              item.dataSource = this.projectArr;
            }
          });
        }
      });
    },
    // 对下拉框做数据处理
    formDataFn(key, data, itemName) {
      let arrSelect = []
      let arrSource = []
      data.forEach((item) => {
        // 班组模糊匹配dataSource
        arrSource.push({ 'title': item[itemName], 'id': item.id })
        // seletetList选项
        arrSelect.push({ 'name': item[itemName], 'value': item.id })
      });
      this.formData.forEach((item) => {
        if (item.key == key) {
          item.dataSource = arrSource
          item.selectlist = arrSelect
        }
      });
    },
    handleSubmit(e) {
      this.params.pageNumber = 1
      this.params = { ...this.params, ...e };
      this.pagination.current = 1;
      if (e.officeId) {
        this.formData.forEach(el => {
          if (el.key == 'officeId') {
            this.$set(this.params, 'officeName', this.projectList.find(i => e.officeId === i.officeId).officeName)
          }
        })
      } else {
        this.$set(this.params, 'officeName', '')
      }
      if (e.teamId) {
        this.formData.forEach(el => {
          if (el.key == 'teamId') {
            this.$set(this.params, 'teamName', el.selectlist.find(i => e.teamId === i.value).name)
          }
        })
      } else {
        this.$set(this.params, 'teamName', '')
      }
      this.getData(this.params);
    },
    //表格 - 分页查询
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      this.params.pageNumber = e.current;
      this.pagination.current = e.current;
      this.params.pageSize = e.pageSize;
      this.pagination.pageSize = e.pageSize;
      this.getData(Object.assign({}, this.params, obj));
    },
    handlSelect(obj) {
      const { val, keys } = obj;
      if (keys === "officeId") {
        this.doQueryTeams({ officeId: val });
        this.projectList.forEach((item) => {
          if (val == item.id) {
            this.$refs["formindex"].setFieldsValue(
              item.officeId,
              {},
              "officeId"
            );
          } 
        });
      }
    },
    exportExcel() {
      let obj = Object.assign(this.params, {
        exportType: 1 //"导出类型 1考勤统计 2考勤明细"
      })
      request(EXPORT_ATTE_STATISTICS, "post", obj, {
          responseType:"blob",
          headers:{ 'Content-Type': 'application/json; application/octet-stream'}
      }).then(res => {
          const content = res.data;
          const reader = new FileReader()
          var blob = new Blob([content], {type: "application/vnd.ms-excel"})
          reader.readAsDataURL(blob)
          reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = "工人考勤统计表.xlsx";
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    exportExcelStaff() {
      let obj = Object.assign(this.params, {
        exportType: 1 //"导出类型 1考勤统计 2考勤明细"
      })
      this.loadingText = "正在导出，请稍候 ····";
      this.isExporting=true;
      this.cPercent=0;
      let fileName="工人考勤统计表.xlsx";
      this.exportFunNomal(EXPORT_ATTE_STATISTICS,obj,fileName)
    },
    // 重置
    close() {
      this.params = {
        pageNumber: 1,
        pageSize: 50,
        isPage: "true",
        month: moment(moment()).format("YYYYMM"),
        officeId: '',
        teamId: '',
        teamName: '',
        officeName: '',
        name: ''
      };
      this.pagination.current = 1;
      this.pagination.pageSize = 50;
      this.selectedRows = []
      this.getData(this.params);
      this.$refs["formindex"].resetFields();
    },
  },
};
</script>

<style lang="less" scoped>
.a_a_top {
  padding: 20px 20px 0 20px;
  background: #ffffff;
}
.a_a_footer {
  // margin-top: 10px;
  padding: 20px 20px 0 20px;
  background: #ffffff;

  .operator {
    margin-right: 8px;

    .ant-btn {
      color: #307dfa;
    }
  }

  .table-title {
    text-align: center;
    font-size: 25px;
  }

  .table-desc {
    display: flex;
    justify-content: space-between;
  }
}

.table-content {
  font-weight: bold;
  font-size: 16px;
}
.red {
  color: red;
}
.yellow {
  color: rgb(230, 187, 0);
}
.green {
  color: #00CE86;
}
/deep/ .ant-form-item {
  margin-bottom: 0;
}
</style>
