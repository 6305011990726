<template>
  <a-card title="新增人员" class="a_Approval" :bordered="false">
    <a-form-model ref="ruleForm" :model="params" :rules="rules">
      <a-row>
        <a-col :span="24">
          <a-form-model-item label="岗位" :label-col="{ span: 2 }" :wrapper-col="{ span: 6 }" prop="postId">
            <a-select 
              v-model="params.postId"
              show-search
              placeholder="请输入或选择"
              option-filter-prop="children"
              option-label-prop="label"
              @change="handlePostChange"
              @search="remotePostSearch($event)"
              @focus="handlePostFocus"
            >
              <a-select-opt-group label="现有岗位">
                <a-select-option
                  :key="item.id"
                  v-for="item in postList"
                  :value="item.id"
                  :label="item.postName"
                >
                  <div class="flex a-center j-center">
                    <span class="flex-1">{{ item.postName }}</span>
                    <a-icon v-if="user.id == item.creatUser" @click="delPost(item)" type="close" />
                  </div>
                </a-select-option>
              </a-select-opt-group>
              <a-select-opt-group label="新增岗位" >
                <a-select-option
                  v-for="(d, index) in postListAdd"
                  :key="index"
                  :value="d.postName"
                >
                  {{ d.postName }}
                </a-select-option>
              </a-select-opt-group>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="姓名" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" prop="userName">
            <a-select
              v-model="params.userName"
              show-search
              placeholder="请输入或选择"
              option-filter-prop="children"
              @change="handleUserChange"
              @search="remoteSearch($event)"
              @focus="handleUserFocus"
            >
              <a-select-opt-group label="现有姓名">
                <a-select-option
                  :key="item.id"
                  v-for="item in userSafeList"
                  :value="item.name"
                  :title="item.name"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select-opt-group>
              <a-select-opt-group label="新增姓名" >
                <a-select-option
                  v-for="(d, index) in userSafeListAdd"
                  :key="index"
                  :value="d.name"
                >
                  {{ d.name }}
                </a-select-option>
              </a-select-opt-group>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="联系方式" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" prop="phone">
            <a-input placeholder="请输入联系方式" v-model="params.phone" allowClear :disabled="phoneDisabled" :maxLength="11"/>
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <a-form-model-item label="备注" :label-col="{ span: 2 }" :wrapper-col="{ span: 14 }" prop="remark">
            <a-input placeholder="请输入" v-model="params.remark" allowClear/>
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <a-form-model-item label="上传证件" :label-col="{ span: 2 }" :wrapper-col="{ span: 14 }" prop="cerFilePath">
            <a-upload
              list-type="picture-card"
              accept="image/png,image/jpg,image/jpeg"
              :customRequest="resourceUpload"
              :file-list="fileList"
              @preview="handlePreview"
              @change="handleChange"
            >
              <div v-if="fileList.length < 1">
                <a-icon type="plus" />
              </div>
            </a-upload>
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <a-form-model-item class="ml-3" :wrapper-col="{ span: 16 }">
            <div class="flex flex-row-reverse">
              <a-button type="primary" @click="submit"> 提交 </a-button>
              <a-button class="mr-2" @click="close"> 关闭 </a-button>
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>

    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </a-card>
</template>

<script>
import { GET_ALL_USER, ATTACHMENT_UPLOAD } from "@/services/api";
import { request } from "@/utils/request";
import { getFileExtension } from '@/utils/fileUtil.js'
import { mapGetters } from 'vuex'
import { getPostList, insertPost, delPost, insertPeople, peopleDetail, peopleUpdate } from "@/services/projectDutyStructure";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export default {
  inject: ["fatherMethod"],
  computed: {
    ...mapGetters('account', ['user']),
  },
  data() {
    return {
      isEdit: 0,
      phoneDisabled: false,
      rules: {
        postId: [{ required: true, message: '请输入或选择岗位', trigger: 'change' }],
        userName: [{ required: true, message: '请输入或选择姓名', trigger: 'change' }],
        phone: [{ required: true, message: '请输入联系方式', trigger: 'change' }],
      },
      orgUserSafeList: [],
      userSafeList: [],
      userSafeListAdd: [],
      orgPostList: [],
      postList: [],
      postListAdd: [],
      dataSource: [],
      params: {
        postCode: '',
        postId: '',
        userId: '',
        userName: '',
        projectId: '',
        phone: '',
        remark: '',
        creator: '',
        updator: '',
        cerFilePath: '',
      },
      fileList: [],
      previewVisible: false,
      previewImage: '',
    };
  },
  activated() {
    this.getPostList()
    this.getStafFindUser();
    this.isEdit = Number(this.$route.query.isEdit);
    if (this.isEdit === 0) {
      this.params.creator = this.user.id
    } else {
      this.params.updator = this.user.id
      this.params.userId = this.$route.query.id;
      this.getDetail()
    }
  },
  methods: {
    delPost(item) {
      delPost(item.id).then(res => {
        if (res.data.code === 0) {
          this.$message.success('删除成功！')
          this.orgPostList.splice(this.orgPostList.indexOf(item), 1)
          this.postList = this.orgPostList
          this.params.postId = ''
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    getDetail() {
      peopleDetail(this.params.userId).then(res => {
        if (res.data.code === 0) {
          console.log(res)
          for (const key in res.data.data) {
            if (Object.hasOwnProperty.call(res.data.data, key)) {
              const el = res.data.data[key];
              if (el) {
                this.$set(this.params, key, String(el))
              }
            }
          }
          this.fileList = this.params.cerFilePath ? [{url: this.params.cerFilePath, uid: 0}] : []
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 安全责任筛选责任人数组user
    getStafFindUser() {
      request(GET_ALL_USER, "post", {}).then((res) => {
        if (res.data.code === 0) {
          this.userSafeList = res.data.data;
          this.orgUserSafeList = res.data.data;
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    getPostList() {
      getPostList({pageSize: 999}).then(res => {
        if (res.data.code === 0) {
          this.postList = res.data.data.list;
          this.orgPostList = res.data.data.list;
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    remoteSearch(query) {
      console.log(query)
      if (query) {
        this.userSafeList = this.orgUserSafeList.filter(el => el.name.indexOf(query) != -1)
        this.userSafeListAdd = [{name: query}]
      } else {
        this.userSafeList = this.orgUserSafeList;
      }
    },
    remotePostSearch(query) {
      if (query) {
        this.postList = this.orgPostList.filter(el => el.postName.indexOf(query) != -1)
        this.postListAdd = [{postName: query}]
      } else {
        this.postList = this.orgPostList;
      }
    },
    handlePostChange(value) {
      let obj = this.postList.find(el => el.id == value)
      if (obj) {
        this.params.postId = value
        this.params.postCode = obj.postCode
      } else {
        insertPost({postName: value, creatUser: this.user.id}).then(res => {
          if (res.data.code === 0) {
            this.postListAdd = []
            this.orgPostList.push(res.data.data)
            this.postList = this.orgPostList;
            this.params.postId = res.data.data.id
            this.params.postCode = res.data.data.postCode
          } else {
            this.$message.error(res.data.msg)
          }
        })
      }
    },
    handleUserChange(value) {
      let obj = this.userSafeList.find(el => el.name == value)
      if (obj) {
        this.params.userId = obj.id
        this.params.phone = obj.phone
        this.phoneDisabled = true
      } else {
        this.params.userId = ''
        this.params.phone = ''
        this.phoneDisabled = false
      }
    },
    handlePostFocus() {
      this.postList = this.orgPostList;
    },
    handleUserFocus() {
      this.userSafeList = this.orgUserSafeList;
    },
    // 上传证件
    resourceUpload(value) {
      const extension = getFileExtension(value.file.name)
      if (extension !== 'jpg' && extension !== 'png' && extension !== 'jpeg') {
        this.$message.error('只能上传图片！')
        return
      }
      const formData = new FormData();
      formData.append('file', value.file);
      // 图片上传
      this.attachmentUpload(formData)
    },
        /*
    * 图片请求接口
    * type 上传组件的类型
    */
    attachmentUpload(formData) {
      // ATTACHMENT_UPLOAD图片上传接口
      request(ATTACHMENT_UPLOAD, "post", formData).then(res => {
        if (res.data.code === 0) {
          const fileObj = {
            uid: res.data.data.id, //图片id
            name: res.data.data.name, //图片名称
            url: res.data.data.path, //图片地址
          }
          this.fileList.push(fileObj);
          this.params.cerFilePath = res.data.data.path
        } else {
          this.$message.warning(res.message);
          this.params.cerFilePath = ''
        }
      })
    },
    handleChange({ fileList }) {
      if (fileList.length === 0) {
        this.params.cerFilePath = ''
        this.fileList = [];
      }
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let obj = {...this.params}
          if (this.isEdit == 0) {
            obj.projectId = this.user.projectId
            insertPeople(obj).then(res => {
              if (res.data.code === 0) {
                this.$message.success('操作成功！')
                this.close()
              } else {
                this.$message.error(res.data.msg)
              }
            })
          } else {
            peopleUpdate(obj).then(res => {
              if (res.data.code === 0) {
                this.$message.success('操作成功！')
                this.close()
              } else {
                this.$message.error(res.data.msg)
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    close() {
      let path = this.$route.path;
      this.fatherMethod(`${path}`);
      this.$router.push({
        path: `/org_structure_project`
      });
    },
  },
};
</script>

<style lang="less" scoped>
.a_Approval {
  background: #fff;
  border-radius: 10px;
}
.btn_group {
  padding-right: 190px;
}
.ant-form-item {
  margin-bottom: 16px;
}
</style>
