var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { bordered: false } },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.loading } },
        [
          _c(
            "a-form-model",
            {
              attrs: {
                model: _vm.form,
                "label-col": _vm.labelCol,
                "wrapper-col": _vm.wrapperCol,
              },
            },
            [
              _c(
                "a-form-model-item",
                { attrs: { "wrapper-col": { span: 24 } } },
                [
                  _c(
                    "a-card",
                    {
                      attrs: { bordered: false },
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function () {
                            return [
                              _c("div", { staticClass: "ml-2 title" }, [
                                _vm._v("紧急联系人信息"),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c(
                        "a-col",
                        { staticClass: "ml-1 my-2" },
                        [
                          _c("a-switch", {
                            model: {
                              value: _vm.form.enterSet.emergency.isOpen,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.enterSet.emergency,
                                  "isOpen",
                                  $$v
                                )
                              },
                              expression: "form.enterSet.emergency.isOpen",
                            },
                          }),
                          _c("span", { staticClass: "text-color ml-1" }, [
                            _vm._v("设为必填项"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { staticClass: "ml-5", attrs: { span: 3 } },
                        [
                          _c(
                            "a-checkbox",
                            {
                              attrs: {
                                disabled: !_vm.form.enterSet.emergency.isOpen,
                              },
                              model: {
                                value:
                                  _vm.form.enterSet.emergency.emergencyUserName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.enterSet.emergency,
                                    "emergencyUserName",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.enterSet.emergency.emergencyUserName",
                              },
                            },
                            [
                              _c("span", { staticClass: "text-color" }, [
                                _vm._v("联系人姓名 "),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 3 } },
                        [
                          _c(
                            "a-checkbox",
                            {
                              attrs: {
                                disabled: !_vm.form.enterSet.emergency.isOpen,
                              },
                              model: {
                                value:
                                  _vm.form.enterSet.emergency
                                    .emergencyUserPhone,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.enterSet.emergency,
                                    "emergencyUserPhone",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.enterSet.emergency.emergencyUserPhone",
                              },
                            },
                            [
                              _c("span", { staticClass: "text-color" }, [
                                _vm._v("联系人电话 "),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 3 } },
                        [
                          _c(
                            "a-checkbox",
                            {
                              attrs: {
                                disabled: !_vm.form.enterSet.emergency.isOpen,
                              },
                              model: {
                                value:
                                  _vm.form.enterSet.emergency.emergencyAddress,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.enterSet.emergency,
                                    "emergencyAddress",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.enterSet.emergency.emergencyAddress",
                              },
                            },
                            [
                              _c("span", { staticClass: "text-color" }, [
                                _vm._v("联系地址 "),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 3 } },
                        [
                          _c(
                            "a-checkbox",
                            {
                              attrs: {
                                disabled: !_vm.form.enterSet.emergency.isOpen,
                              },
                              model: {
                                value:
                                  _vm.form.enterSet.emergency.emergencyRelation,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.enterSet.emergency,
                                    "emergencyRelation",
                                    $$v
                                  )
                                },
                                expression:
                                  "form.enterSet.emergency.emergencyRelation",
                              },
                            },
                            [
                              _c("span", { staticClass: "text-color" }, [
                                _vm._v("与劳动者关系 "),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { "wrapper-col": { span: 24 } } },
                [
                  _c(
                    "a-card",
                    {
                      attrs: { bordered: false },
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function () {
                            return [
                              _c("div", { staticClass: "ml-2 title" }, [
                                _vm._v("拟退场时间"),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c(
                        "a-col",
                        { staticClass: "mt-2 ml-1" },
                        [
                          _c("a-switch", {
                            model: {
                              value: _vm.form.enterSet.exit,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.enterSet, "exit", $$v)
                              },
                              expression: "form.enterSet.exit",
                            },
                          }),
                          _c("span", { staticClass: "text-color ml-1" }, [
                            _vm._v("启用“拟退场时间”"),
                          ]),
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _c("p", [
                                  _vm._v(
                                    " “拟退场时间” 是指预计某工人将完成工作并从项目中退场的日期，启用后将: "
                                  ),
                                ]),
                                _c("p", [
                                  _vm._v(
                                    "1.开启进场校验，工人信息中须填写“拟退场时间”后方可入场"
                                  ),
                                ]),
                                _c("p", [
                                  _vm._v(
                                    "2.导出工人花名册时增加一列展示此项信息"
                                  ),
                                ]),
                                _c("p", [
                                  _vm._v(
                                    ' 3.工人临近"拟退场时间", 平台将会自动预警, 以加强项目用工管理的水平 '
                                  ),
                                ]),
                                _c("p", [
                                  _vm._v(
                                    "4.电子签合同增值服务中，可在电子合同中定制化增加此项信息"
                                  ),
                                ]),
                              ]),
                              _c("a-icon", {
                                staticStyle: { "margin-left": "5px" },
                                attrs: {
                                  type: "question-circle",
                                  theme: "twoTone",
                                },
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-button",
                {
                  staticClass: "ml-3",
                  attrs: { type: "primary", icon: "save" },
                  on: { click: _vm.saveRules },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }