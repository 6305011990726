<template>
    <div>
        <a-card :bordered="false">

            <div id="components-form-demo-advanced-search">
                <a-form class="ant-advanced-search-form" :form="searchForm" >
                    <a-row :gutter="24">
                        <a-col :span="6" >
                            <a-form-item :label="`模板id`">
                                <a-input v-model="searchForm.essTemplateId" placeholder="请输入模板id" />
                            </a-form-item>
                        </a-col>
                        <a-col :span="4" >
                            <a-form-item :label="`模板类型`">
                                <a-select
                                        v-model="searchForm.templateType"
                                >
                                    <a-select-option key="" value="">
                                        全部
                                    </a-select-option>
                                    <a-select-option key="0" value="0">
                                        其他
                                    </a-select-option>
                                    <a-select-option key="1" value="1">
                                        入场合同
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>

                        <!-- <a-col :span="4" >
                            <a-form-item :label="`电子合同厂商`">
                                <a-select
                                        v-model="searchForm.fromType"
                                >
                                    <a-select-option key="1" :value="1">
                                        腾讯电子签
                                    </a-select-option>
                                    <a-select-option key="2" :value="2">
                                        法大大电子签
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col> -->

                        <a-col :span="4" >
                            <a-form-item :label="`状态`">
                                <a-select
                                        v-model="searchForm.status"
                                >
                                    <a-select-option key="" value="">
                                        全部
                                    </a-select-option>
                                    <a-select-option key="0" value="0">
                                        未启用
                                    </a-select-option>
                                    <a-select-option key="1" value="1">
                                        已启用
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>

                        <a-col :span="4" :style="{ textAlign: 'right', marginTop: '3px'}">
                            <a-button type="primary" icon="search" :loading="loading" @click="handleSearch">
                                查询
                            </a-button>
                            <a-button style="margin-left: 10px;" type="primary" @click="add()">
                                新增
                            </a-button>
                        </a-col>
                    </a-row>

                    <a-row>

                    </a-row>

                </a-form>
            </div>
            <a-table
                    :columns="columns"
                    :data-source="data"
                    bordered
            >

                <template slot="templateId" slot-scope="text, record, index">
                    <div :key="index">
                        <a-input
                                v-if="record.editable"
                                style="margin: -5px 0"
                                :value="text"
                                @change="e => handleChange(e.target.value, record.id, 'templateId')"
                        />
                        <template v-else>
                            {{ text }}
                        </template>
                    </div>
                </template>
                <template slot="templateType" slot-scope="text, record, index">
                    <div :key="index">

                        <a-select
                                v-if="record.editable"
                                v-model="record.templateType"
                                @change="e => handleChange(e.target.value, record.id, 'templateType')"
                        >
                            <a-select-option key="0" value="0">
                                其他
                            </a-select-option>
                            <a-select-option key="1" value="1">
                                入场合同
                            </a-select-option>
                        </a-select>
                        <template v-else>
                            {{ text == 0 ? "其他" : "入场合同" }}
                        </template>
                    </div>
                </template>

                <template slot="fromType" slot-scope="text, record, index">
                    <div :key="index">

                        <a-select
                                v-if="record.editable"
                                v-model="record.fromType"
                                @change="e => handleChange(e.target.value, record.id, 'fromType')"
                        >
                            <a-select-option key="1" :value="1">
                                腾讯电子签
                            </a-select-option>
                            <a-select-option key="2" :value="2">
                                法大大电子签
                            </a-select-option>
                        </a-select>
                        <template v-else>
                            {{ text == 1 ? "腾讯电子签" : "法大大电子签" }}
                        </template>
                    </div>
                </template>

                <template slot="customTemplateName" slot-scope="text, record, index">
                    <div :key="index">

                        <a-select
                                v-if="record.editable"
                                v-model="record.customTemplateId"
                                @change="e => handleChange(e.target.value, record.id, 'customTemplateId')"
                        >
                            <a-select-option v-for="(item, index) in customTemplateList" :key="index" :value="item.id">
                                {{item.title}}
                            </a-select-option>
                        </a-select>
                        <template v-else>
                            {{ text }}
                        </template>
                    </div>
                </template>


                <template slot="customTemplateNameOthers" slot-scope="text, record, index">
                    <div :key="index">

                        <a-select
                                v-if="record.editable"
                                mode="multiple"
                                v-model="record.customTemplateIds"
                                @change="e => handleChange(e.target.value, record.id, 'customTemplateIds')"
                        >
                            <a-select-option v-for="(item, index) in customTemplateList" :key="index" :value="item.id">
                                {{item.title}}
                            </a-select-option>
                        </a-select>
                        <template v-else>
                            {{ text }}
                        </template>
                    </div>
                </template>

                <template slot="status" slot-scope="text, record, index">
                    <div :key="index">

                        <a-select
                                v-if="record.editable"
                                v-model="record.status"
                                @change="e => handleChange(e.target.value, record.id, 'status')"
                        >
                            <a-select-option key="0" value="0">
                                未启用
                            </a-select-option>
                            <a-select-option key="1" value="1">
                                已启用
                            </a-select-option>
                        </a-select>
                        <template v-else>
                            {{ text == 0 ? "未启用" : "已启用" }}
                        </template>
                    </div>
                </template>


                <template slot="action" slot-scope="text, record"><span v-if="record.editable">
                      <a @click="() => editSave(record.id)">保存</a>
                      <a-divider type="vertical" />
                      <a-popconfirm title="是否取消保存?" @confirm="() => editCancel(record.id)">
                        <a>取消</a>
                      </a-popconfirm>
                    </span>
                    <span v-else>
                      <a @click="() => edit(record.id)">编辑</a>
                      <a-divider type="vertical" />
                      <a @click="del(record)">删除</a>
                      <a-divider type="vertical" />
                      <a @click="setting(record)">设置</a>
                      <a-divider type="vertical" />
                      <a @click="downTempl(record)">模板下载</a>
                    </span>
                </template>
            </a-table>
        </a-card>

        <div>
            <a-modal
                    :title="title"
                    :visible="visible"
                    :confirm-loading="confirmLoading"
                    @ok="modelOk"
                    @cancel="modelCancel"
                    width="560px"
            >
                <a-form-model :model="form"
                              :label-col="labelCol"
                              :wrapper-col="wrapperCol"
                              ref="ruleForm"
                              :rules="rules"
                              v-if="visible"
                >
                    <a-form-model-item label="模板id" ref="essTemplateId" prop="essTemplateId"  required>
                        <a-input v-model="form.essTemplateId" />
                    </a-form-model-item>
                    <a-form-model-item label="模板类型">
                        <a-select
                                v-model="form.templateType"
                        >
                            <a-select-option key="0" value="0">
                                其他
                            </a-select-option>
                            <a-select-option key="1" value="1">
                                入场合同
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>

                    <a-form-model-item label="电子签合作厂商">
                        <a-select
                                v-model="form.fromType"
                        >
                            <a-select-option key="1" :value="1">
                                腾飞电子签
                            </a-select-option>
                            <a-select-option key="2" :value="2">
                                法大大电子签
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>

                    <a-form-model-item label="状态">
                        <a-select v-model="form.status" >
                            <a-select-option key="0" value="0">
                                未启用
                            </a-select-option>
                            <a-select-option key="1" value="1">
                                已启用
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>

                    <a-form-model-item label="自定义模板">
                        <a-select v-model="form.customTemplateId"  placeholder="请选择关联的自定义模板">
                            <a-select-option v-for="(item, index) in customTemplateList" :key="index" :value="item.id">
                                {{item.title}}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>

                     <a-form-model-item label="关联的其他模板">
                        <a-select
                        mode="multiple"
                        v-model="form.customTemplateIds"
                        placeholder="请选择">
                            <a-select-option v-for="(item, index) in customTemplateList" :key="index" :value="item.id">
                                {{item.title}}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item v-if="form.fromType==2" label="电子签模板">
                        <a-upload
                            name="file"
                            :multiple="false"
                            :beforeUpload="beforeUpload"
                            :accept="accept"
                            :customRequest="resourceUpload"
                            :file-list="defaultFileList"
                        >
                            <a-button style="margin-left:10px" type="primary" >上传模板文件</a-button>
                        </a-upload>
                    </a-form-model-item>


                </a-form-model>




            </a-modal>
        </div>
        <sigle-location
            :essTempl="sigleLocation.obj"
            :visible="sigleLocation.visible"
            @close="sigleLocationColse"
            @submit="submitSigleLocation"
        ></sigle-location>
    </div>



</template>

<script>
    import { getEssTemplateList, essTemplateUpdate, essTemplateInsert, essTemplateDel, getAccountCustomTemplateList} from '@/services/ess'
    import {ESS_TEMPL_RECIPIENT_SAVE,ATTACHMENT_UPLOAD} from '@/services/api'
    import {request} from '@/utils/request'
    import pick from 'lodash.pick'
    import {mapGetters} from 'vuex'
    import SigleLocation from '@/pages/essbasic/siglelocation'
    import { useRender } from '@/hooks/useRender.js'

    const columns = [
        {
            dataIndex: 'essTemplateId',
            key: 'essTemplateId',
            title: '模板id',
            align:'center',
            slots: { title: 'essTemplateId' },
            scopedSlots: { customRender: 'essTemplateId' },
        },
        {
            title: '模板类型',
            dataIndex: 'templateType',
            key: 'templateType',
            align:'center',
            slots: { title: 'templateType' },
            scopedSlots: { customRender: 'templateType' },
        },
        {
            title: '电子签合作厂商',
            dataIndex: 'fromType',
            key: 'fromType',
            align:'center',
            slots: { title: 'fromType' },
            scopedSlots: { customRender: 'fromType' },
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            align:'center',
            slots: { title: 'status' },
            scopedSlots: { customRender: 'status' },
        },
        {
            title: '关联自定义模板',
            dataIndex: 'customTemplateName',
            key: 'customTemplateName',
            align:'center',
            slots: { title: 'customTemplateName' },
            scopedSlots: { customRender: 'customTemplateName' },
        },
        {
            title: '关联其他模板',
            dataIndex: 'customTemplateNameOthers',
            key: 'customTemplateNameOthers',
            align:'center',
            slots: { title: 'customTemplateNameOthers' },
            scopedSlots: { customRender: 'customTemplateNameOthers' },
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            align:'center',
            key: 'createTime',
            customRender: (text) => useRender.renderDate(text)
        },
        {
            title: '更新时间',
            key: 'updateTime',
            align:'center',
            dataIndex: 'updateTime',
            customRender: (text) => useRender.renderDate(text)
        },
        {
            title: '操作',
            key: 'action',
            align:'center',
            scopedSlots: { customRender: 'action' },
        },
    ];

    export default {
        components: {SigleLocation},
        data() {
            return {
                defaultFileList:[],
                sigleLocation:{
                    obj:{},
                    visible:false
                },
                obj:{},
                // 筛选
                searchForm: {
                    essTemplateId: "",
                    status: "",
                    templateType: "",
                    fromType:null
                },
                loading: false,

                // 数据表格
                data: [],
                cacheData: [],
                columns,
                editingKey: '',

                // 详细数据弹窗
                title: "编辑",
                visible: true,
                confirmLoading: false,
                labelCol: { span: 6 },
                wrapperCol: { span: 12 },
                form:{
                    id:null,
                    status: "0",
                    fromType:0,
                    essTemplateId:null,
                    templateType:1,
                    customTemplateId:null,
                    customTemplateIds:null,
                    templPath:null,
                },
                rules: {
                    essTemplateId: [
                        { required: true, message: '请输入模板id', trigger: 'blur' },
                    ],
                    templateType: [{ required: true, message: '请选择模板类型', trigger: 'blur' }],
                    status: [{ required: true, message: '请选择状态', trigger: 'blur' }],
                },
                customTemplateList: [],
                accept:'.doc,.docx',//图片上传限制

            };
        },
        computed: {
            ...mapGetters('account', ['user']),
        },
        created() {
            this.getEssTemplateList()
            this.getAccountCustomTemplateList()
        },
        methods: {
            getEssTemplateList() {
                getEssTemplateList(this.searchForm).then(res => {
                    this.data = res.data.data
                    this.data.forEach(function (value, index, arr){
                        arr[index].status = arr[index].status + "";
                        arr[index].templateType = arr[index].templateType + "";
                        if(arr[index].customTemplateIds){
                            arr[index].customTemplateIds = arr[index].customTemplateIds.split(',');
                        }

                    })
                    this.cacheData = this.data
                    console.log(this.cacheData);
                })
            },
            handleSearch(){
                this.getEssTemplateList();
            },
            showModal(record) {
                this.visible = true
                this.form = record;
            },
            add(){
                this.showModal({status:"0", templateType: "0"})
            },
            modelOk(){
                const _this = this
                // 触发表单验证
                this.$refs.ruleForm.validate(valid => {
                    if (valid) {
                        this.confirmLoading = true
                        if(this.form.customTemplateIds){
                            this.form.customTemplateIds = this.form.customTemplateIds.join(",");
                        }
                        console.log(this.form);
                        if(this.form.id){
                            essTemplateUpdate(this.form).then(res=>{
                                this.getEssTemplateList()
                                this.visible = false;
                                this.confirmLoading = false;
                                this.form={};
                                return true;
                            })
                        }else{
                            essTemplateInsert(this.form).then(res => {
                                this.getEssTemplateList()
                                this.visible = false;
                                this.confirmLoading = false;
                                this.form={};
                                return true;
                            })
                        }

                    } else {
                        this.$message.error("请检查必填信息");
                        this.confirmLoading = false;
                    }
                })
            },
            modelCancel(e) {
                this.visible = false;
            },
            edit(key) {
                const newData = [...this.data];
                const target = newData.filter(item => key === item.id)[0];
                this.visible = true;
                this.obj = target;
                this.form = this.obj;
                if(target.templPath){
                    var fileObj = {
                        uid: '1',
                        name: "电子签模板文件",
                        url: target.templPath,
                    }
                    this.defaultFileList = [];
                    this.defaultFileList.push(fileObj);
                }

                // this.editingKey = key;
                // if (target) {
                //     target.editable = true;
                //     this.data = newData;
                // }
            },
            editSave(key) {
                console.log(this.form);
                // const newData = [...this.data];
                // const newCacheData = [...this.cacheData];
                // const target = newData.filter(item => key === item.id)[0];
                // const targetCache = newCacheData.filter(item => key === item.id)[0];
                // if (target && targetCache) {

                //     this.loading = true
                //     this.tableLoading = true
                //     if(target.customTemplateIds){
                //         target.customTemplateIds = target.customTemplateIds.join(",");
                //     }
                //     console.log("更新对象2",target);
                //     essTemplateUpdate(target).then(res=>{
                //         delete target.editable;
                //         this.data = newData;
                //         Object.assign(targetCache, target);
                //         this.cacheData = newCacheData;
                //     }).finally(()=>{
                //         this.loading = false
                //         this.tableLoading = false
                //         this.getEssTemplateList()
                //     })
                // }
                this.editingKey = '';
            },
            editCancel(key) {
                const newData = [...this.data];
                const target = newData.filter(item => key === item.id)[0];
                this.editingKey = '';
                if (target) {
                    Object.assign(target, this.cacheData.filter(item => key === item.id)[0]);
                    delete target.editable;
                    this.data = newData;
                }
            },
            del(record){
                var _that = this
                this.$confirm({
                    title: '确认删除' + record.name + "?",
                    content: '要删除该角色吗？',
                    okText: '删除',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                        essTemplateDel({id: record.id}).then(res => {
                            _that.$message.info(res.data.msg);
                            _that.getEssTemplateList()
                        })
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            },
            getAccountCustomTemplateList(){
                getAccountCustomTemplateList().then(res => {
                    this.customTemplateList = res.data.data
                })
            },
            setting(recode){
                console.log(recode);
                this.sigleLocation.visible = true;
                this.sigleLocation.obj = recode;
            },
            sigleLocationColse(){
                this.sigleLocation.visible = false;
                this.sigleLocation.obj = {};
            },
            submitSigleLocation($event){
                console.log($event);
                let datas = [];
                if($event.setings&&$event.setings.length>0){
                    $event.setings.forEach(obj=>{
                        if(obj.recipientId){
                            obj.customTemplatId = $event.customTemplateId;
                            obj.templId = $event.templId;
                            datas.push(obj);
                        }
                    })
                }else{
                    this.$message.error("签署配置为空！");
                    return false;
                }

                if(datas.length<=0){
                    this.$message.error("签署配置为空！");
                    return false;
                }
                request(ESS_TEMPL_RECIPIENT_SAVE,"post",datas).then(res => {
                    if(res.data.code==0){
                        this.$message.success("签署配置为空！");
                        this.sigleLocationColse();
                    }else{
                        this.$message.error(res.data.msg+"，保存异常！");
                        return false;
                    }
                })
            },
            /**
    * 上传之前
    */
    beforeUpload (file) {
      return new Promise((resolve, reject) => {
        const isFiveM= file.size / 1024 / 1024 < 200 //小于5M的图片上传
        //判断文件是否符合正则表达式的规则
        const accept = '.' + file.name.substring(file.name.lastIndexOf(".") + 1);
        if (this.accept.split(',').indexOf(accept) == -1) {
          this.$message.error("只能上传Word文档");
          return reject(false)
        }
        if (!isFiveM) {
          this.$message.error('上传资源不大于200M！');
          return reject(false)
        }
        let cont = "模板";
        if(this.obj.id){
          this.$confirm({
            title: '',
            content: h => <div style="color:red;">重新上传会将原有的{cont}覆盖，是否继续上传?</div>,
            onOk() {
              return resolve(true);
            },
            onCancel() {
              return reject(false);
            },
            class: 'test',
          });
        }else{
          return resolve(true);
        }

      });
    },
    /**
    * 资源上传
    */
    resourceUpload(value){
      const formData = new FormData();
      formData.append('file', value.file);
      request(ATTACHMENT_UPLOAD,"post",formData).then(result => {
          if (result.data.code==0) {
            var fileObj = {
              uid: '1',
              name: result.data.data.name,
              url: result.data.data.path,
            }
            this.defaultFileList = [];
            this.defaultFileList.push(fileObj);
            this.form.templPath = result.data.data.path;
            value.onSuccess(result, value.file);
          }else{
            this.$message.warning(result.message);
          }
      })
    },
    downTempl(record){
        if(record.templPath){
            window.open(record.templPath);
        }
    }


        }
    };
</script>


<style scoped>
    .ant-advanced-search-form {
        padding: 24px;
        /* background: #fbfbfb;
        border: 1px solid #d9d9d9; */
        border-radius: 6px;
        margin-bottom: 10px;
    }

    .ant-advanced-search-form .ant-form-item {
        display: flex;
    }

    .ant-advanced-search-form .ant-form-item-control-wrapper {
        flex: 1;
    }

    #components-form-demo-advanced-search .ant-form {
        max-width: none;
    }

    tr{
        background-color: #ffffff;
    }

</style>
