<template>
    <a-card :bordered="false">
        <div id="components-form-demo-advanced-search">
            <a-form class="ant-advanced-search-form" :form="searchForm" >
                <a-row :gutter="24">
                    <a-col :span="8" >
                        <a-form-item :label="`上报时间`">
                            <a-range-picker @change="onChange" />
                        </a-form-item>
                    </a-col>

                    <a-col :span="4" :style="{ textAlign: 'right', marginTop: '3px'}">
                        <a-button type="primary" icon="search" :loading="loading" @click="search">
                            查询
                        </a-button>
                    </a-col>
                </a-row>

                <a-row>
                    <a-button style="margin-left:15px" type="primary" v-if="detailsShow" @click="exportExcel">
                        导出详情
                    </a-button>
                </a-row>

            </a-form>
        </div>
        <a-table
                :columns="columns"
                :data-source="data"
                :pagination="pagination"
                @change="onPageChange"
                :loading="tableLoading"
        >
        </a-table>


    </a-card>


</template>

<script>
    import {getEpidemicListByProject, dailyReportStatistics, abnormalReportStatistics} from '@/services/epidemic'
    import {request} from '@/utils/request'

    const columns = [
        {
            title: '姓名',
            dataIndex: 'userName',
            key: 'userName',
        },
        {
            title: '身份证号',
            dataIndex: 'idCard',
            key: 'idCard',
        },
        {
            title: '电话',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: '体温',
            dataIndex: 'dayTemp',
            key: 'dayTemp',
        },
        {
            title: '穗康码',
            dataIndex: 'image',
            key: 'image',
        },
        {
            title: '核酸结果',
            dataIndex: 'checkLastStatus',
            key: 'checkLastStatus',
        },
        {
            title: '14天高风险区旅居',
            dataIndex: 'isHighPlace',
            key: 'isHighPlace',
        },
    ];

    export default {
        data() {
            return {

                // 筛选
                searchForm: {

                },
                loading: false,


                // 数据表格
                columns,
                data: [],
                pagination:{
                    current:1,
                    pageSize:10,
                    total:0,
                },
                tableLoading: false,

            };
        },
        created() {
            this.initData()
        },
        methods: {
            initData() {
                this.searchForm.pageNumber = 1
                this.pagination.pageNumber = 1
                this.findNoAttach()
            },
            onChange(date, dateString) {
                this.searchForm.startTime = dateString[0] + " 00:00:00"
                this.searchForm.endTime = dateString[1] + " 23:59:59"
                console.log(date, dateString);
            },
            search() {
                this.initData()
            },
            onPageChange(e){
                this.searchForm.pageNumber = e.current;
                this.pagination.current=e.current;
                this.findNoAttach();
            },
            exportExcel(){
                // var searchForm = {
                //     officeName: this.searchForm.officeName,
                //     startTime: this.searchForm.startTime,
                //     endTime: this.searchForm.endTime
                // }
                // request(EXPORT_NOATTACH_EXCEL,"post",searchForm,
                //     {
                //         responseType:"blob",
                //         headers:{ 'Content-Type': 'application/json; application/octet-stream'}
                //     }).then(res => {
                //     const content = res.data;
                //     const reader = new FileReader()
                //     var blob = new Blob([content], {type: "application/vnd.ms-excel"})
                //     reader.readAsDataURL(blob)
                //     reader.onload = (e) => {
                //         const a = document.createElement('a')
                //         a.download = "未考勤人员统计.xlsx";
                //         a.href = e.target.result
                //         document.body.appendChild(a)
                //         a.click()
                //         document.body.removeChild(a)
                //     }
                // })
            }


        },
    };


</script>

<style scoped>
    .ant-advanced-search-form {
        padding: 24px;
        background: #fbfbfb;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        margin-bottom: 10px;
    }

    .ant-advanced-search-form .ant-form-item {
        display: flex;
    }

    .ant-advanced-search-form .ant-form-item-control-wrapper {
        flex: 1;
    }

    #components-form-demo-advanced-search .ant-form {
        max-width: none;
    }

    tr{
        background-color: #ffffff;
    }

</style>
