<template>
  <div>
    <a-form-model :model="params" class="clearfix" @submit="handleSubmit">
      <a-col :span="5">
        <a-form-model-item label="选择年份" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
          <a-date-picker
            mode="year"
            v-model="params.year"
            placeholder="请选择年份"
            :open="yearShowOne"
            format="YYYY"
            @openChange="openChangeOne"
            @panelChange="panelChangeOne"/>
        </a-form-model-item>
      </a-col>

      <a-col :span="5" v-if="isJSOffice">
        <a-form-model-item label="工程管理部" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
          <a-select
            v-model="params.gcglbId"
            allowClear
            placeholder="请选择"
          >
            <a-select-option
              v-for="item in gcglbList"
              :key="item.value"
              :value="item.value"
              :title="item.name"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>

      <a-col :span="5">
        <a-form-model-item label="项目名称" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
          <a-input placeholder="请输入项目名称" v-model="params.projectName" allowClear/>
        </a-form-model-item>
      </a-col>

      <a-col :span="4">
        <a-space class="btnBox">
          <a-button type="primary" html-type="submit"> 查询 </a-button>
          <a-button @click="resetQuery"> 重置 </a-button>
        </a-space>
      </a-col>
    </a-form-model>
    <standard-table
      class=""
      :columns="columns"
      :dataSource="tableData"
      rowKey="projectId"
      :pagination="pagination"
      :loading="tableLoading"
      @change="handleChangePage"
      :isAlert="false"
      :bordered="true"
    >
      <template slot="index" slot-scope="{ index }">
        <span>{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}</span>
      </template>
      <template slot="complainType" slot-scope="{ text }">
        <span v-if="text == 0">投诉到集团公司</span>
        <span v-else-if="text == 1">投诉到政府相关部门</span>
        <span v-else-if="text == 2">投诉到省、市领导</span>
      </template>
      <template slot="filePath" slot-scope="{ text }">
        <div class="flex j-center pointer">
          <a-icon type="file-pdf" :style="{ fontSize: '32px' }" v-if="text && text.indexOf('.pdf') > -1" @click="handlePreview(text)" />
          <img
            alt=""
            style="width: 32px"
            v-else
            :src="text"
            @click="handlePreview(text)"
          />
        </div>
      </template>
    </standard-table>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>
  
<script>
import {
  safeExamineComplainList
} from "@/services/safeExamine";
import { mapGetters } from "vuex";
import StandardTable from "@/components/table/StandardTable";
import { request } from "@/utils/request";
import { QUERY_OFFICE_BY_USER } from "@/services/api";
import moment from "moment";



export default {
  components: {
    StandardTable
  },
  data() {
    const columns = [
      {
        title: "序号",
        align: "center",
        scopedSlots: { customRender: "index" },
        width: 65,
      },
      {
        title: "工程管理部",
        align: "center",
        dataIndex: "gcglbName",
        width: 250,
      },
      {
        title: "项目名称",
        dataIndex: "projectName",
        ellipsis: true,
        tooltip: true
      },
      {
        title: "投诉类型",
        dataIndex: "complainType",
        width: 300,
        scopedSlots: { customRender: "complainType" },
        align: "center",
      },
      {
        title: "被投诉扣分",
        dataIndex: "subScore",
        width: 300,
        scopedSlots: { customRender: "subScore" },
        align: "center",
      },
      {
        title: "附件",
        dataIndex: "filePath",
        width: 200,
        scopedSlots: { customRender: "filePath" },
        align: "center",
      },
      {
        title: '合计',
        dataIndex: "totalCount",
        width: 200,
        scopedSlots: { customRender: "totalCount" },
        align: "center",
      },
    ];
    return {
      params: {
        pageNumber: 1,
        pageSize: 10,
        year: moment(new Date()).format('YYYY'),
        gcglbId: '',
        projectName: ''
      },
      pagination: {
        //分页数据
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total, range) => `共 ${total} 条`,
      },
      gcglbList: [],
      yearShowOne:false,
      total: 0,
      columns: columns,
      tableData: [],
      tableLoading: false, //table加载中
      previewVisible: false,
      previewImage: "",
    };
  },
  created() {
    this.getgcglbList(QUERY_OFFICE_BY_USER);
    this.handleQuery();
  },
  activated() {
    this.handleQuery();
  },
  computed: {
    ...mapGetters("account", ["user"]),
    isBranchOffice() { //分公司
      return this.user.isGcglb == 1
    },
    isJSOffice() { //机施
      return this.user.isGcglb == 0
    },
  },
  methods: {
    handlePreview(item) {
      console.log(item)
      if (item.indexOf('.pdf') > -1) {
        // 打开新页面
        window.open(item);
      } else {
        // 打开预览
        this.previewImage = item;
        this.previewVisible = true;
      }
    },
    handleCancel() {
      this.previewVisible = false;
    },
    // 获取工程管理部数据
    getgcglbList(path) {
      request(path, "get").then((res) => {
        let data = res.data.data.filter(item => item.id != "3da0f5533f87425c9a9e8a11a7923300")
        const arr = [];
        data.forEach((item) => {
          arr.push({
            name: item.master,
            value: item.id,
          });
        });
        this.gcglbList = arr;
      });
    },
    openChangeOne(status) {
      if (status) {
        this.yearShowOne = true;
      }
    },
    // 得到年份选择器的值
    panelChangeOne(value) {
      this.yearShowOne = false;
      this.params.year = value.format('YYYY');
    },
    handleSubmit(e) {
      e.preventDefault();
      this.params.pageNumber = 1
      this.pagination.current = 1
      this.tableData = []
      this.handleQuery();
    },
    handleQuery(e) {
      this.tableData = []
      this.tableLoading = true
      safeExamineComplainList(this.params).then((res) => {
        this.tableLoading = false
        let d = res.data.data;
        if (res.data.code === 0) {
          let totalCountArr = d.list.reduce(function(acc, obj) {
            let gcglbName = obj.gcglbName;
            let subScore = obj.subScore;
            if (acc[gcglbName]) {
              acc[gcglbName] += subScore;
            } else {
              acc[gcglbName] = subScore;
            }

            return acc;
          }, {});
          let totalCountArrKeys = Object.keys(totalCountArr)
          d.list.forEach(el => {
            totalCountArrKeys.forEach(key => {
              if (el.gcglbName == key) {
                el.totalCount = totalCountArr[key]
              }
            })
          });
          let j = 0//根据gcglbName保存有多少项相同
          let k = 0//根据gcglbName保存相同项的起始下标，因为其他项有可能不同gcglbName但还是相同的
          this.columns = this.columns.map(item => {
            if (item.dataIndex === "gcglbName") {
              let tempColIndex = 0
              item.customRender = (value, row, index) => {
                const obj = {
                  children: value,
                  attrs: {},
                };
                j = d.list.filter(obj => obj.gcglbName === value)
                k = d.list.findIndex(obj => obj.gcglbName === value)
                let l = k//临时保存该相同项的起始下标
                if (j.length > 1) {
                  //若相同项大于1则合并第一行
                  if (index === l) {
                    obj.attrs.rowSpan = j.length;
                    tempColIndex = l + 1//临时保存需要设为0的下标
                  }
                  //其他相同的要设为0
                  if (index === tempColIndex) {
                    obj.attrs.rowSpan = 0;
                    tempColIndex = tempColIndex + 1
                  }
                }
                return obj
              }
            }
            if (item.dataIndex === "totalCount") {
              let tempColIndex = 0
              item.customRender = (value, row, index) => {
                console.log(value, row, index)
                const obj = {
                  children: value,
                  attrs: {},
                };
                let l = k//临时保存该相同项的起始下标
                if (j.length > 1) {
                  //若相同项大于1则合并第一行
                  if (index === l) {
                    obj.attrs.rowSpan = j.length;
                    tempColIndex = l + 1//临时保存需要设为0的下标
                  }
                  //其他相同的要设为0
                  if (index === tempColIndex) {
                    obj.attrs.rowSpan = 0;
                    tempColIndex = tempColIndex + 1
                  }
                }
                return obj
              }
            }
            return item
          })
          this.tableData = d.list;
          this.pagination.total = d.total;
        } else {
          this.$message.error(d.msg)
        }
      });
    },
    handleChangePage(e) {
      this.params.pageNumber = e.current;
      this.pagination.current = e.current;
      this.handleQuery();
    },
    resetQuery() {
      this.pagination.pageNumber = 1
      this.params = {
        gcglbId: '',
        year: moment(new Date()).format('YYYY'),
        projectName: '',
        pageNumber: 1,
        pageSize: 10,
      };
      this.handleQuery();
    },
  },
};
</script>
  
<style lang="less" scoped>
@import "@/theme/common.less";
.query-form {
  display: flex;
  // justify-content: space-between;
}
.post-border {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 5px;
  padding-top: 5px;
  // padding-top: 10px;
  // height: 40px;
}

.post-border:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.sTables {
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    width: 100%;
    text-align: center;
    cursor: pointer;
  }

  div:nth-child(1) {
    color: #307dfa;
  }

  div:nth-child(2) {
    color: #ff5c77;
  }

  div:nth-child(3) {
    color: #615e83;
  }
}
</style>
  