import {ADMIN, GET_JSJD_WORD_LIST, SUBMIT_JSJD, SHOW_DOC} from '@/services/api'
import {request, METHOD} from '@/utils/request'

/**
 * 获取文档列表
 * @returns {Promise<AxiosResponse<T>>}
 */
 export async function getJsjdWordList() {
    return request(GET_JSJD_WORD_LIST, METHOD.GET)
 }

 /**
 * 提交保存自定义word配置
 * @returns {Promise<AxiosResponse<T>>}
 */
  export async function submitJsjd(param) {
    return request(SUBMIT_JSJD, METHOD.POST, param)
 }

  /**
 * 提交保存自定义word配置
 * @returns {Promise<AxiosResponse<T>>}
 */
   export async function initJsjdWord(param) {
      return request(SUBMIT_JSJD, METHOD.POST, param)
   }


/**
 * 预览文档模板
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function showDoc(param) {
    return request(SHOW_DOC, METHOD.POST, param)
}

/**
 *  AI交底资料事件-分页查询
 */
export async function getReportAiList(params, query) {
   if (query) {
      return request(ADMIN + `/edu/third/data/report/ai/page?${query}`, METHOD.GET, params)
  }
   return request(ADMIN + `/edu/third/data/report/ai/page`, METHOD.GET, params)
}

/**
 *  AI交底资料事件-详情查询
 */
export async function getReportAiDetail(params) {
   return request(ADMIN + `/edu/third/data/report/ai/detail?eventId=${params}`, METHOD.GET)
}

/**
 *  AI交底资料事件-音视频列表查询
 */
export async function getReportAiMedia(params) {
   return request(ADMIN + `/edu/third/data/report/ai/media`, METHOD.GET, params)
}

/**
 *  AI识别违规记录-获取当前用户是属于哪个层级
 */
export async function cameraWaringCheckEvel(params) {
   return request(ADMIN + `/safe/camera-waring/checkEvel`, METHOD.GET, params)
}

/**
 *  AI识别违规记录-获得国云科技-AI解析结果分页
 */
export async function cameraWaringPage(params) {
   return request(ADMIN + `/safe/camera-waring/page`, METHOD.POST, params)
}

export default {
    getJsjdWordList,
    submitJsjd,
    initJsjdWord,
    showDoc,
}