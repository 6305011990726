<template>
  <div>
      <a-card>
        <a-spin :spinning="spinning" :tip="loadingText">
        <div :class="advanced ? 'search' : null">
          <a-form layout="horizontal">
            <div :class="advanced ? null: 'fold'">
              <a-row >
              <a-col :md="12" :sm="24" >
                <a-form-item
                  label="姓名"
                  :labelCol="{span: 5}"
                  :wrapperCol="{span: 18, offset: 1}"
                >
                  <a-input placeholder="请输入" v-model="queryParams.userName"/>
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24" >
                <a-form-item
                  label="身份证号"
                  :labelCol="{span: 5}"
                  :wrapperCol="{span: 18, offset: 1}"
                >
                  <a-input placeholder="身份证号" v-model="queryParams.idCard"/>
                </a-form-item>
              </a-col>
            </a-row>
            </div>
            <span style="float: right; margin-top: 3px;">
              <a-button type="primary" @click="doQuery()">查询</a-button>
              <a-button style="margin-left: 8px" @click="doReset()">重置</a-button>
            </span>
          </a-form>
        </div>
        <div>
          <standard-table
            :columns="columns"
            :dataSource="dataSource"
            :selectedRows.sync="selectedRows"
            :pagination="pagination"
            :loading="tableLoading"
            @clear="onClear"
            @change="onPageChange"
          >
            <div slot="do-hzcs" slot-scope="{text}">
              <div v-if="text!=null&&text!=''">{{text}}次</div>
              <div v-else style="color:red" type="close" >0次</div>
            </div>
            <div slot="do-status" slot-scope="{text}">
              <div v-if="text!=null&&text!=''&&text==1">在场</div>
              <div v-else style="color:red" type="close" >离场</div>
            </div>
            <div slot="do-teamNames" slot-scope="{record}">
              <a-popover v-if="record.teamNamesList.length>0" title="服务班组清单" trigger="hover">
                <template slot="content" >
                  <div v-for="item in record.teamNamesList" :key="item.id" style="margin-bottom:5px" @click="teamTagClick(`${item.id}`)">
                    <a-tag style="padding:5px 10px" closable @close="removeTeam(item,`${record.idCard}`)">{{item.teamName}}</a-tag>
                  </div>
                </template>
                <a>{{record.teamNamesList[0].teamName}}</a>
              </a-popover>
              
              
            </div>
            <div slot="do-projectNames" slot-scope="{record}">
              <a-popover v-if="record.projectNamesList.length>0" title="服务项目清单" trigger="hover">
                <template slot="content" >
                  <div v-for="item in record.projectNamesList" :key="item.id" style="margin-bottom:5px"><a-tag>{{item.projectName}}</a-tag></div>
                </template>
                <a>{{record.projectNamesList[0].projectName}}</a>
              </a-popover>
            </div>
            <div slot="do-certNames" slot-scope="{text}">
              <div v-if="text!=null&&text!=''">{{text}}</div>
              <div v-else style="color:red" type="close" >暂无证书</div>
            </div>
            <div slot="action" slot-scope="{text, record}">
              <a @click="changeTeam(record)">调班</a>
              <a-divider type="vertical" />
              <a @click="updatePerson(record)">{{record.enterStatus==1?'离场':'在场'}}</a>
            </div>
            <template slot="statusTitle">
              <a-icon @click.native="onStatusTitleClick" type="info-circle" />
            </template>
          </standard-table>
        </div>
        <Export-loading :cPercent="cPercent" :isExporting="isExporting" :loadingText="loadingText"></Export-loading>
        </a-spin>
      </a-card>
      <change-team
        :visible="changeTeamParams.visible"
        :idCard="changeTeamParams.idCard"
        :userName="changeTeamParams.userName"
        @teamChangeClose="doCloseTeamChange"
        @viewTeamPersons="teamPersons($event)"
        @submit="doSureTeam($event)"
      >

      </change-team>
  </div>
</template>

<script>
import StandardTable from '@/components/table/StandardTable'
import ChangeTeam from '@/pages/enterprisepool/teamChange'
import {
  PERSON_ENTERPRISE_QUERY,
  PERSON_ENTERPRISE_UPDATE,
  PERSON_TEAM_CHANGE,
  PERSON_ENTERPRISE_REMOVE
  } from '@/services/api'
  
import { exportProgress } from '@/pages/aqjyzl/export' // 导出方法
import ExportLoading from '@/components/ExportLoading/ExportLoading' // progress组件
import {request} from '@/utils/request'
import axios from 'axios'
import { te } from 'date-fns/locale'
// import axios from '@/utils/axios'
const columns = [
  {
    title: '姓名',
    dataIndex: 'userName',
    width:120,
    align: 'center',
    scopedSlots: { customRender: 'userName' }
  },
  {
    title: '身份证号',
    dataIndex: 'idCard',
    align: 'center',
    width:195,
    scopedSlots: { customRender: 'description' },
    customRender: (text) => {
      return text.substring(0,6)+"************"+text.substring(text.length-4,text.length)
    }
  },
  {
    title: '服务班组',
    dataIndex: 'teamName',
    align: 'center',
    width:210,
    ellipsis: true,
   scopedSlots: { customRender: 'do-teamNames' },
  },
  {
    title: '服务项目',
    dataIndex: 'projectNames',
    align: 'center',
    ellipsis: true,
   scopedSlots: { customRender: 'do-projectNames' },
  },
  {
    title: '联系电话',
    dataIndex: 'phone',
    align: 'center',
    width:120,
    scopedSlots: {customRender: 'phone'}
  },
  {
    title: '在场状态',
    dataIndex: 'enterStatus',
    align: 'center',
    width:60,
    scopedSlots: {customRender: 'do-status'}
  },
  {
    title: '合作次数',
    dataIndex: 'hzcs',
    align: 'center',
    width:60,
    scopedSlots: {customRender: 'do-hzcs'}
  },
  {
    title: '证书情况',
    dataIndex: 'certNames',
    align: 'center',
    ellipsis: true,
    width:100,
    scopedSlots: {customRender: 'do-certNames'}
  },
  {
    title: '操作',
    align: 'center',
    width:110,
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  mixins: [exportProgress],
  name: 'QueryList',
  components: {StandardTable,ExportLoading,ChangeTeam},
  data () {
    return {
      dataSource:[],
      advanced: false,
      columns: columns,
      selectedRows: [],
      tableLoading: false,
      spinning:false,
      loadingText:"数据加载中...",
      loadKeys:[],
      overDownIndex:0,
      queryParams:{
        userName:null,
        idCard:null,
        teamName:null,
        teamId:null,
        pageNumber:1,
      },
      changeTeamParams:{
        visible:false,
        idCard:null,
        userName:null,
      },
      pagination:{
        current:1,
        pageSize:10,
        total:0,
        showLessItems:true,
        showQuickJumper:true
      },
      cPercent:0,
      isExporting:false,
      fileName:'企业人员名单',
    }
  },
  watch: {
    cPercent:{
      handler(newName, oldName) {
        this.cPercent=newName;
      },
      immediate: true,
    },
    loadingText:{
      handler(newName, oldName) {
        this.loadingText=newName;
      },
      immediate: true,
    }
  },
  mounted(){
  },
  created() {
    this.doQuery();
  },
  authorize: {
    deleteRecord: 'delete'
  },
  methods: {
    deleteRecord(key) {
      this.dataSource = this.dataSource.filter(item => item.key !== key)
      this.selectedRows = this.selectedRows.filter(item => item.key !== key)
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    remove () {
      this.dataSource = this.dataSource.filter(item => this.selectedRows.findIndex(row => row.key === item.key) === -1)
      this.selectedRows = []
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },

    doCloseTeamChange(){
      this.changeTeamParams.idCard = null;
      this.changeTeamParams.visible = false;
    },

    doSureTeam($event){
      let obj = {
        teamTagIds:$event.teamIds,
        idCard:$event.idCard
      }
      request(PERSON_TEAM_CHANGE,"post",obj).then(res => {
        if(res.data.code==0){
          this.changeTeamParams.idCard = null;
          this.changeTeamParams.userName = null;
          this.changeTeamParams.visible = false;
          this.doQuery();
          this.$message.success("调班成功");
        }else{
          this.$message.error("调班异常");
        }
        this.tableLoading = false;
      })
    },
    //查看班组成员列表
    teamPersons($event){
      let teamId = $event.team.id;
      this.tableLoading = true;
      let queryPersons = {
        teamId:teamId
      }
      request(PERSON_ENTERPRISE_QUERY,"post",queryPersons).then(res => {
        if(res.data.code==0){
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
          this.dataSource.forEach(obj=>{
            obj.key = obj.uuid;
          })
          this.changeTeamParams.idCard = null;
          this.changeTeamParams.userName = null;
          this.changeTeamParams.visible = false;
        }
        this.tableLoading = false;
      })
    },

    /**
     * 获取所有人员的签名记录信息
     */
    doQuery(){
      this.tableLoading = true;
      request(PERSON_ENTERPRISE_QUERY,"post",this.queryParams).then(res => {
        if(res.data.code==0){
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
          this.dataSource.forEach(obj=>{
            obj.key = obj.uuid;
          })
        }
        this.tableLoading = false;
      })
    },
    changeTeam($user){
      this.changeTeamParams.idCard = $user.idCard;
      this.changeTeamParams.userName = $user.userName;
      this.changeTeamParams.visible = true;
    },
    doReset(){
      this.queryParams={};
      this.queryParams.teamId = this.teamId;
      this.doQuery();
    },
    //分页查询
    onPageChange(e){
      this.queryParams.pageNumber = e.current;
      this.pagination.current=e.current;
      this.doQuery();
    },
    //移除班组
    removeTeam($team,idCard){
      var _that = this
      this.$confirm({
          title: '确认移除班组人员',
          content: '您好，您确认将该人员的服务班组【'+$team.teamName+'】移除吗？',
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk() {
             let obj = {
                idCard:idCard,
                teamTagId:$team.id
              }
              _that.tableLoading = true;
              request(PERSON_ENTERPRISE_REMOVE,"post",obj).then(res => {
                if(res.data.code==0){
                  _that.doQuery();
                  _that.$message.success("移除班组成功！")
                }else{
                  _that.$message.error("移除班组失败")
                }
                _that.tableLoading = false;
              })
          },
          onCancel() {
              console.log('Cancel');
          },
      });
    },
    /**
     * 导出
     */
    doDowload(key,userName){
      let selectedRows = this.dataSource.filter(item => item.key == key);
      this.loadingText = "正在打包资料文件，请稍候 ····";
      this.loadKeys = [];
      this.loadKeys.push(key);
      this.isExporting=true;
      this.cPercent=0;
      let fileName = ""
      fileName=userName+"入场资料下载.zip";
      // this.exportFun(SAFEMEANS_DOWNLOAD,this.loadKeys,fileName,selectedRows)
    },
    updatePerson($obj){
      var _that = this
      let text = "离场";
      if($obj.enterStatus&&$obj.enterStatus==1){
          text = "离场";
      }else{
        text = "在场";
      }
      this.$confirm({
          title: '确认切换工作状态',
          content: '您好，您确认当前工人为'+text+'状态？',
          okText: '确定',
          okType: 'danger',
          cancelText: '取消',
          onOk() {
             let obj = {
                idCard:$obj.idCard,
                enterStatus:$obj.enterStatus==1?0:1
              }
              _that.tableLoading = true;
              request(PERSON_ENTERPRISE_UPDATE,"post",obj).then(res => {
                if(res.data.code==0){
                  _that.doQuery();
                  _that.$message.success("切换成功！")
                }else{
                  _that.$message.error("修改失败")
                }
                _that.tableLoading = false;
              })
          },
          onCancel() {
              console.log('Cancel');
          },
      });
    },
    teamTagClick(teamId){
      this.queryParams={};
      this.queryParams.teamId = teamId;
      this.doQuery();
    }
  }
}
</script>

<style lang="less" scoped>
  .search{
    margin-bottom: 54px;
  }
  .fold{
    width: calc(100% - 216px);
    display: inline-block
  }
  .operator{
    margin-bottom: 18px;
  }
  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
</style>
