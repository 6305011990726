var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { "body-style": { padding: "24px 32px" }, bordered: false } },
    [
      _c(
        "a-form",
        [
          _c(
            "a-form-item",
            {
              attrs: {
                label: _vm.$t("title"),
                labelCol: { span: 7 },
                wrapperCol: { span: 10 },
              },
            },
            [_c("a-input", { attrs: { placeholder: _vm.$t("titleInput") } })],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                label: _vm.$t("date"),
                labelCol: { span: 7 },
                wrapperCol: { span: 10 },
              },
            },
            [_c("a-range-picker", { staticStyle: { width: "100%" } })],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                label: _vm.$t("describe"),
                labelCol: { span: 7 },
                wrapperCol: { span: 10 },
              },
            },
            [
              _c("a-textarea", {
                attrs: { rows: "4", placeholder: _vm.$t("describeInput") },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                label: _vm.$t("metrics"),
                labelCol: { span: 7 },
                wrapperCol: { span: 10 },
              },
            },
            [
              _c("a-textarea", {
                attrs: { rows: "4", placeholder: _vm.$t("metricsInput") },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                label: _vm.$t("customer"),
                labelCol: { span: 7 },
                wrapperCol: { span: 10 },
                required: false,
              },
            },
            [
              _c("a-input", {
                attrs: { placeholder: _vm.$t("customerInput") },
              }),
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                label: _vm.$t("critics"),
                labelCol: { span: 7 },
                wrapperCol: { span: 10 },
                required: false,
              },
            },
            [_c("a-input", { attrs: { placeholder: _vm.$t("criticsInput") } })],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                label: _vm.$t("weight"),
                labelCol: { span: 7 },
                wrapperCol: { span: 10 },
                required: false,
              },
            },
            [
              _c("a-input-number", { attrs: { min: 0, max: 100 } }),
              _c("span", [_vm._v("%")]),
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                label: _vm.$t("disclosure"),
                labelCol: { span: 7 },
                wrapperCol: { span: 10 },
                required: false,
                help: _vm.$t("disclosureDesc"),
              },
            },
            [
              _c(
                "a-radio-group",
                {
                  model: {
                    value: _vm.value,
                    callback: function ($$v) {
                      _vm.value = $$v
                    },
                    expression: "value",
                  },
                },
                [
                  _c("a-radio", { attrs: { value: 1 } }, [
                    _vm._v(_vm._s(_vm.$t("public"))),
                  ]),
                  _c("a-radio", { attrs: { value: 2 } }, [
                    _vm._v(_vm._s(_vm.$t("partially"))),
                  ]),
                  _c("a-radio", { attrs: { value: 3 } }, [
                    _vm._v(_vm._s(_vm.$t("private"))),
                  ]),
                ],
                1
              ),
              _vm.value === 2
                ? _c(
                    "a-select",
                    { attrs: { mode: "multiple" } },
                    [
                      _c("a-select-option", { attrs: { value: "4" } }, [
                        _vm._v(_vm._s(_vm.$t("colleague1"))),
                      ]),
                      _c("a-select-option", { attrs: { value: "5" } }, [
                        _vm._v(_vm._s(_vm.$t("colleague2"))),
                      ]),
                      _c("a-select-option", { attrs: { value: "6" } }, [
                        _vm._v(_vm._s(_vm.$t("colleague3"))),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              staticStyle: { "margin-top": "24px" },
              attrs: { wrapperCol: { span: 10, offset: 7 } },
            },
            [
              _c("a-button", { attrs: { type: "primary" } }, [
                _vm._v(_vm._s(_vm.$t("submit"))),
              ]),
              _c("a-button", { staticStyle: { "margin-left": "8px" } }, [
                _vm._v(_vm._s(_vm.$t("save"))),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }