var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a_Approval" },
    [
      _c("div", { staticClass: "plug-Overview" }, [
        _vm._v("安全生产隐患排查治理情况统计表"),
      ]),
      _c("a-divider"),
      _c(
        "a-form-model",
        {
          staticClass: "mb-8 query-form",
          attrs: { layout: "inline", model: _vm.params },
          on: { submit: _vm.handleSubmit },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "工程管理部" } },
            [
              _c(
                "a-select",
                {
                  staticStyle: { width: "220px" },
                  attrs: { allowClear: "", placeholder: "请选择" },
                  model: {
                    value: _vm.params.gcglbId,
                    callback: function ($$v) {
                      _vm.$set(_vm.params, "gcglbId", $$v)
                    },
                    expression: "params.gcglbId",
                  },
                },
                _vm._l(_vm.gcglbList, function (item) {
                  return _c(
                    "a-select-option",
                    {
                      key: item.value,
                      attrs: { value: item.value, title: item.name },
                    },
                    [_vm._v(" " + _vm._s(item.name) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "项目名称" } },
            [
              _c("a-input", {
                staticStyle: { width: "220px" },
                attrs: { placeholder: "请输入项目名称", allowClear: "" },
                model: {
                  value: _vm.params.projectName,
                  callback: function ($$v) {
                    _vm.$set(_vm.params, "projectName", $$v)
                  },
                  expression: "params.projectName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    { attrs: { type: "primary", "html-type": "submit" } },
                    [_vm._v(" 查询 ")]
                  ),
                  _c("a-button", { on: { click: _vm.resetQuery } }, [
                    _vm._v(" 重置 "),
                  ]),
                  _vm.user.isGcglb == 0 &&
                  _vm.user.loginType == 1 &&
                  _vm.user.nowRoleKey.includes("JS_LJJ")
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.doDowload },
                        },
                        [_vm._v(" 导出 ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("standard-table", {
        attrs: {
          columns: _vm.columns,
          dataSource: _vm.tableData,
          rowKey: "id",
          scroll: { x: 1300 },
          pagination: _vm.pagination,
          loading: _vm.tableLoading,
          isAlert: false,
          bordered: true,
        },
        on: { change: _vm.handleChangePage },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function (ref) {
              var index = ref.index
              return [
                index < _vm.tableData.length - 1
                  ? _c("span", [_vm._v(_vm._s(_vm.itemNo(index)))])
                  : _c("span", [_vm._v("合计")]),
              ]
            },
          },
          {
            key: "problems",
            fn: function (ref) {
              var index = ref.index
              var record = ref.record
              return _vm._l(record.problems, function (problem, index) {
                return _c("div", { key: index }, [_vm._v(_vm._s(problem))])
              })
            },
          },
          {
            key: "action",
            fn: function (ref) {
              var index = ref.index
              var record = ref.record
              return _c("div", {}, [
                _c(
                  "a",
                  {
                    on: {
                      click: function () {
                        return _vm.handleEdit(record)
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          index !== _vm.tableData.length - 1 ? "查看" : ""
                        ) +
                        " "
                    ),
                  ]
                ),
                record.status == 1
                  ? _c(
                      "a",
                      {
                        staticStyle: { color: "red" },
                        on: {
                          click: function () {
                            return _vm.handleBack(record)
                          },
                        },
                      },
                      [_vm._v(" 退回 ")]
                    )
                  : _vm._e(),
              ])
            },
          },
        ]),
      }),
      _c("Export-loading", {
        attrs: {
          cPercent: _vm.cPercent,
          isExporting: _vm.isExporting,
          loadingText: _vm.loadingText,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }