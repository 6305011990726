<template>
  <exception-page :home-route="user.loginType == 1 ? '公司首页' : '项目首页'" :style="`min-height: ${minHeight}`" type="403" />
</template>

<script>
import ExceptionPage from '@/components/exception/ExceptionPage'
import {mapState, mapGetters} from 'vuex'
export default {
  name: 'Exp403',
  components: {ExceptionPage},
  computed: {
    ...mapState('setting', ['pageMinHeight']),
    ...mapGetters('account', ['user']),
    minHeight() {
      return this.pageMinHeight ? this.pageMinHeight + 'px' : '100vh'
    }
  }
}
</script>

<style scoped lang="less">
</style>
