// fileUtil.js

/**
 * a 标签下载文件，指定文件名
 * @param {string} url - 文件的 URL 地址
 * @param {string} fileName - 保存的文件名
 */
export function downloadFile(url, fileName) {
  // 创建隐藏的 a 标签
  const link = document.createElement("a");
  link.style.display = "none";

  // 不需要指定文件名的，直接原链接下载
  if (!fileName) {
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    // 通过 fetch 获取文件并处理为 Blob
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.blob();
      })
      .then(blob => {
        const objectURL = URL.createObjectURL(blob); // 创建 Blob 对象的 URL
        link.href = objectURL;
        link.download = fileName; // 指定下载文件名
        document.body.appendChild(link);
        link.click(); // 触发下载
        document.body.removeChild(link);
        URL.revokeObjectURL(objectURL); // 释放创建的 URL
      })
      .catch(error => {
        console.error("文件下载失败:", error);
        alert("下载失败，请稍后再试！");
      });
  }
}

/**
 * 下载二进制数据为文件
 * @param {ArrayBuffer | Blob} binaryData - 二进制数据
 * @param {string} fileName - 保存的文件名
 * @param {string} mimeType - 文件的 MIME 类型（如：'application/pdf'、'image/jpeg'）
 */
export function downloadBinaryData(binaryData, fileName = 'file', mimeType = 'application/octet-stream') {
  const blob = new Blob([binaryData], { type: mimeType });
  const link = document.createElement('a');
  const objectURL = URL.createObjectURL(blob);
  
  link.href = objectURL;
  link.download = fileName;
  
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  
  URL.revokeObjectURL(objectURL); // 释放创建的 URL
}

/**
 * 下载 Base64 字符串为文件
 * @param {string} base64Data - Base64 编码的数据
 * @param {string} fileName - 保存的文件名
 * @param {string} mimeType - 文件的 MIME 类型
 */
export function downloadBase64(base64Data, fileName = 'file', mimeType = 'application/octet-stream') {
  const binaryData = atob(base64Data.split(',')[1]); // 去掉头部的 data URL 部分
  const length = binaryData.length;
  const arrayBuffer = new ArrayBuffer(length);
  const uint8Array = new Uint8Array(arrayBuffer);
  
  for (let i = 0; i < length; i++) {
    uint8Array[i] = binaryData.charCodeAt(i);
  }

  downloadBinaryData(arrayBuffer, fileName, mimeType);
}

/**
 * 根据文件名获取文件的扩展名
 * @param {string} fileName - 文件名
 * @returns {string} - 扩展名（包括点，如 `.jpg`）
 */
export function getFileExtension(fileName) {
  return fileName.split('.').pop();
}

/**
 * 判断文件是否为图片
 * @param {string} mimeType - 文件的 MIME 类型
 * @returns {boolean} - 如果是图片返回 true，否则返回 false
 */
export function isImageFile(mimeType) {
  return mimeType.startsWith('image/');
}

/**
 * 读取文件内容
 * @param {File} file - 文件对象
 * @param {Function} callback - 回调函数，接收读取到的内容
 */
export function readFileContent(file, callback) {
  const reader = new FileReader();
  
  reader.onload = (event) => {
    callback(event.target.result); // 读取结果
  };

  reader.onerror = (error) => {
    console.error('文件读取失败', error);
  };

  // 根据文件类型选择不同的读取方法
  if (file.type.startsWith('image')) {
    reader.readAsDataURL(file); // 读取图片为 DataURL
  } else if (file.type === 'application/pdf') {
    reader.readAsArrayBuffer(file); // 读取 PDF 文件为二进制流
  } else {
    reader.readAsText(file); // 读取文本文件
  }
}

/**
 * 将文件转为 DataURL
 * @param {File} file - 文件对象
 * @param {Function} callback - 回调函数，接收 DataURL
 */
export function fileToDataURL(file, callback) {
  const reader = new FileReader();
  
  reader.onload = (event) => {
    callback(event.target.result); // 返回 DataURL
  };
  
  reader.onerror = (error) => {
    console.error('文件转 DataURL 失败', error);
  };
  
  reader.readAsDataURL(file);
}

/**
 * 判断文件类型
 * @param {string} mimeType - 文件的 MIME 类型
 * @returns {string} - 返回文件类型的字符串（'image', 'pdf', 'video'等）
 */
export function getFileTypeByMime(mimeType) {
  if (mimeType.startsWith('image')) {
    return 'image';
  } else if (mimeType === 'application/pdf') {
    return 'pdf';
  } else if (mimeType.startsWith('video')) {
    return 'video';
  } else if (mimeType.startsWith('audio')) {
    return 'audio';
  } else {
    return 'other';
  }
}

/**
 * 将文件大小从字节转换为易读格式
 * @param {number} bytes - 文件大小（字节）
 * @returns {string} - 格式化后的文件大小（如 "1.5 MB"）
 */
export function formatFileSize(bytes) {
  if (bytes === 0) return '0 B';
  const k = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i];
}

