var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "m_g" },
    [
      _c("div", { staticClass: "flex p-1" }, [
        _c("div", { staticClass: "wapper" }, [
          _c(
            "div",
            { staticClass: "m_g_left public" },
            [
              _c("form-index", {
                ref: "formIndex",
                attrs: {
                  formData: _vm.formData,
                  Overview: "基本信息",
                  options: _vm.options,
                },
                on: {
                  onDateChange: _vm.onDateChange,
                  resourceUpload: _vm.resourceUpload,
                  upRemove: _vm.upRemove,
                  handlSelect: _vm.handlSelect,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "m_g_left mt-1 public" },
            [
              _c("form-index", {
                ref: "formIndexInfo",
                attrs: {
                  formData: _vm.formDataInfo,
                  Overview: "紧急联系人信息",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "wapper" }, [
          _c(
            "div",
            { staticClass: "m_g_right_top public" },
            [
              _c("form-index", {
                ref: "formIndex1",
                attrs: {
                  options: _vm.options,
                  formData: _vm.formDataTop,
                  Overview: "工卡/工资卡信息",
                },
                on: {
                  upRemove: _vm.upRemove,
                  resourceUpload: _vm.resourceUpload,
                  handleChange: _vm.handleChange,
                  handlSelect: _vm.handlSelect,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "m_g_right_mid public" },
            [
              _c("form-index", {
                ref: "formIndex2",
                attrs: { formData: _vm.formDataMid, Overview: "工种/职务信息" },
                on: {
                  upRemove: _vm.upRemove,
                  handlSelect: _vm.handlSelect,
                  resourceUpload: _vm.resourceUpload,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "m_g_right_bottom public" },
            [
              _c("form-index", {
                ref: "formIndex3",
                attrs: { formData: _vm.formDataBottom, Overview: "证件信息" },
                on: {
                  upRemove: _vm.upRemove,
                  resourceUpload: _vm.resourceUpload,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      [
        _c(
          "div",
          { staticClass: "m_g_button" },
          [
            _c(
              "a-button",
              { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
              [_vm._v(" 提交 ")]
            ),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }