var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        { attrs: { gutter: 16 } },
        [
          _c(
            "a-col",
            { attrs: { span: 13 } },
            [
              _c(
                "a-card",
                [
                  _c("div", { attrs: { slot: "title" }, slot: "title" }, [
                    _c("div", { staticClass: "flex a-center" }, [
                      _c("img", {
                        staticStyle: {
                          width: "21px",
                          height: "21px",
                          "margin-right": "5px",
                        },
                        attrs: {
                          src: "https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20241120/1732091350443.png?Expires=2047451350&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=59AEqlAw%2B%2BGyQ%2F%2B4QYdFWxJZ7rs%3D",
                          alt: "",
                        },
                      }),
                      _c("span", { staticClass: "ml-1 font-bold" }, [
                        _vm._v("人员及培训"),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "flex j-around a-center text-center" },
                    _vm._l(_vm.staffArr, function (item, index) {
                      return _c("div", { key: index, staticClass: "flex-1" }, [
                        _c(
                          "div",
                          { staticClass: "flex a-center flex-column" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "black",
                                staticStyle: { "font-size": "26px" },
                              },
                              [_vm._v(_vm._s(item.value))]
                            ),
                            _c(
                              "div",
                              { staticClass: "font-14 black-three mt-1" },
                              [_vm._v(_vm._s(item.text))]
                            ),
                          ]
                        ),
                      ])
                    }),
                    0
                  ),
                  _c(
                    "vue-scroll",
                    {
                      staticClass: "mt-2",
                      staticStyle: { height: "143px" },
                      attrs: { ops: _vm.ops },
                    },
                    [
                      _c("standard-table", {
                        staticClass: "close-table",
                        attrs: {
                          columns: _vm.staffColumns,
                          dataSource: _vm.staffList,
                          rowKey: "id",
                          pagination: false,
                          isAlert: false,
                          bordered: false,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "do-status",
                            fn: function (ref) {
                              var text = ref.text
                              return [
                                text == 1
                                  ? _c("span", [
                                      _c("i", {
                                        staticClass: "iconfont duigouxiao",
                                        staticStyle: { color: "#00CE86" },
                                      }),
                                    ])
                                  : _c("span", [
                                      _c("i", {
                                        staticClass: "iconfont path",
                                        staticStyle: { color: "#FF5C77" },
                                      }),
                                    ]),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 11 } },
            [
              _c("a-card", { staticClass: "pitfall-card" }, [
                _c(
                  "div",
                  {
                    staticClass: "font-bold black",
                    staticStyle: { "font-size": "17px" },
                  },
                  [_vm._v("应急管理情况")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "mt-1 py-2",
                    staticStyle: {
                      "border-radius": "10px",
                      background: "rgb(255, 251, 245)",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex j-around a-center text-center" },
                      [
                        _c("div", { staticClass: "flex-1" }, [
                          _c(
                            "div",
                            { staticClass: "flex a-center flex-column" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "black",
                                  staticStyle: { "font-size": "26px" },
                                },
                                [_vm._v("5")]
                              ),
                              _c(
                                "div",
                                { staticClass: "font-14 black-three" },
                                [_vm._v("年度应急计划次数")]
                              ),
                            ]
                          ),
                        ]),
                        _c("div", {
                          staticStyle: {
                            border: "1px solid rgb(255, 223, 180)",
                            transform: "rotate(180.00deg)",
                            height: "58px",
                          },
                        }),
                        _c("div", { staticClass: "flex-1" }, [
                          _c(
                            "div",
                            { staticClass: "flex a-center flex-column" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "black",
                                  staticStyle: { "font-size": "26px" },
                                },
                                [_vm._v("5")]
                              ),
                              _c(
                                "div",
                                { staticClass: "font-14 black-three" },
                                [_vm._v("年度应急演练次数")]
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "font-bold mt-2",
                    staticStyle: { "font-size": "17px", color: "#000" },
                  },
                  [_vm._v("费用使用情况")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "mt-2 py-2",
                    staticStyle: {
                      "border-radius": "10px",
                      background: "rgb(247, 253, 255)",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex j-around a-center text-center" },
                      [
                        _c("div", { staticClass: "flex-1" }, [
                          _c(
                            "div",
                            { staticClass: "flex a-center flex-column" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "black",
                                  staticStyle: { "font-size": "22px" },
                                },
                                [
                                  _vm._v("5548.20 "),
                                  _c(
                                    "span",
                                    { staticClass: "font-14 black-three" },
                                    [_vm._v("万元")]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "font-14 black-three" },
                                [_vm._v("十项费用内容计划资金")]
                              ),
                            ]
                          ),
                        ]),
                        _c("div", {
                          staticStyle: {
                            border: "1px solid rgb(217, 231, 255)",
                            transform: "rotate(180.00deg)",
                            height: "58px",
                          },
                        }),
                        _c("div", { staticClass: "flex-1" }, [
                          _c(
                            "div",
                            { staticClass: "flex a-center flex-column" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "black",
                                  staticStyle: { "font-size": "22px" },
                                },
                                [
                                  _vm._v("470.30 "),
                                  _c(
                                    "span",
                                    { staticClass: "font-14  black-three" },
                                    [_vm._v("万元")]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "font-14 black-three" },
                                [_vm._v("累计实际使用资金")]
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }