import {MESSAGE_NOTIFY_GET_LIST, SYS_USER_GET_LIST, GET_LIST_BY_SYS_MESSAGE,
    MESSAGE_NOTIFY_INSERT, GET_MY_SEND_MESSAGE_LIST_BY_SYS, GET_PROJECT_LIST_BY_PARENT_OFFICE_ID_AND_PROJECT_NAME,
    GET_MESSAGE_NOTIFY_BY_ID, UPDATE_MESSAGE_NOTIFY_BY_ID, GET_SYS_USER_LIST_BY_SYS_MSG, GET_TEAM_LIST_BY_SYS_MSG} from '@/services/api'
import {request, METHOD} from '@/utils/request'

/**
 * 获取消息通知列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function messageNotifyGetList(params) {
    return request(MESSAGE_NOTIFY_GET_LIST, METHOD.GET, params)
}

/**
 *
 */
/**
 * 获取消息通知列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getMySendMessageListBySys(params) {
    return request(GET_MY_SEND_MESSAGE_LIST_BY_SYS, METHOD.POST, params)
}


/**
 * 获取B端用户列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function sysUserGetList(params) {
    return request(SYS_USER_GET_LIST, METHOD.GET, params)
}

/**
 * 获取C端用户列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getListBySysMessage(params) {
    return request(GET_LIST_BY_SYS_MESSAGE, METHOD.POST, params)
}

/**
 * 添加消息通知
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function messageNotifyInsert(params) {
    return request(MESSAGE_NOTIFY_INSERT, METHOD.POST, params)
}

/**
 * 获取项目列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getProjectListByParentOfficeIdAndProjectName(params) {
    return request(GET_PROJECT_LIST_BY_PARENT_OFFICE_ID_AND_PROJECT_NAME, METHOD.GET, params)
}


/**
 * 获取一个消息的详情
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getMessageNotifyById(params) {
    return request(GET_MESSAGE_NOTIFY_BY_ID, METHOD.GET, params)
}

/**
 * 更新一个消息
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function updateMessageNotifyById(params) {
    return request(UPDATE_MESSAGE_NOTIFY_BY_ID, METHOD.POST, params)
}

/**
 * 获取sys用户列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getSysUserListBySysMsg(params) {
    return request(GET_SYS_USER_LIST_BY_SYS_MSG, METHOD.POST, params)
}


/**
 * 获取sys班组列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getTeamListBySysMsg(params) {
    return request(GET_TEAM_LIST_BY_SYS_MSG, METHOD.GET, params)
}





export default {
    messageNotifyGetList,
    sysUserGetList,
    getListBySysMessage,
    messageNotifyInsert,
    getMySendMessageListBySys,
    getProjectListByParentOfficeIdAndProjectName,
    getMessageNotifyById,
    updateMessageNotifyById,
    getSysUserListBySysMsg,
    getTeamListBySysMsg
}