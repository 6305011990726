import {GET_GROUP_LIST, ADD_GROUP, DEL_GROUP, UPDATE_GROUP, GET_DEVICE_LIST,
    ADD_DEVICE, DEL_DEVICE, DEVICE_ADD_GROUP, GET_PERSON_FACE_RECORD_LIST, UPDATE_DEVICE,
    ISSUE_GROUP_FACE, RE_ISSUE_GROUP_FACE, DEL_GROUP_PERSON, BATCH_ISSUE_GROUP_FACE,
    GET_MANAGER_PERSON_LIST, JW_BATCH_APPLY_DEL, JW_BATCH_APPLY_LIST,DEL_LEAVE_PERSON, GET_STATISTICS, GET_ATTE_FAIL_RECORD_LIST,
    GET_MANAGER_ATTE_STATISTICS,REPORT_ATTE_FAIL, JW_BATCH_APPLY_ADD_MANAGER,DEL_BACK_ATTE_RE_RECORD} from '@/services/api'
import {request, METHOD, removeAuthorization} from '@/utils/request'


/**
 * 管理人员考勤提交接口
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function batchApplyAddManager(param) {
    return request(JW_BATCH_APPLY_ADD_MANAGER, METHOD.POST, param)
}

/**
 * 获取分组列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getGroupList(param) {
    return request(GET_GROUP_LIST, METHOD.POST, param)
}

/**
 * 查询非设备直连考勤补报列表(分页)
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getAtteFailRecordList(param) {
    return request(GET_ATTE_FAIL_RECORD_LIST, METHOD.POST, param)
}

/**
 * 考勤统计
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getStatistics(param) {
    return request(GET_STATISTICS, METHOD.POST, param)
}

/**
 * 管理人员考勤统计
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getManagerAtteStatistics(param) {
    return request(GET_MANAGER_ATTE_STATISTICS, METHOD.POST, param)
}

/**
 * 添加分组
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function addGroup(param) {
    return request(ADD_GROUP, METHOD.POST, param)
}

/**
 * 修改分组
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function updateGroup(param) {
    return request(UPDATE_GROUP, METHOD.POST, param)
}

/**
 * 删除分组
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function delGroup(param) {
    return request(DEL_GROUP, METHOD.POST, param)
}

/**
 * 获取设备列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getDeviceList(param) {
    return request(GET_DEVICE_LIST, METHOD.POST, param)
}

/**
 * 添加设备
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function addDevice(param) {
    return request(ADD_DEVICE, METHOD.POST, param)
}

/**
 * 删除设备
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function delDevice(param) {
    return request(DEL_DEVICE, METHOD.POST, param)
}

/**
 * 修改设备
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function updateDevice(param) {
    return request(UPDATE_DEVICE, METHOD.POST, param)
}

/**
 * 设备添加到分组
 *  @returns {Promise<AxiosResponse<T>>}
 */
export async function deviceAddGroup(param){
    return request(DEVICE_ADD_GROUP, METHOD.POST, param)
}



/**
 * 获取人员列表
 *  @returns {Promise<AxiosResponse<T>>}
 */
export async function getPersonFaceRecordList(param){
    return request(GET_PERSON_FACE_RECORD_LIST, METHOD.POST, param)
}

/**
 * 人员下发到组
 *  @returns {Promise<AxiosResponse<T>>}
 */
export async function issueGroupFace(param){
    return request(ISSUE_GROUP_FACE, METHOD.POST, param)
}

/**
 * 重新人员下发到组
 *  @returns {Promise<AxiosResponse<T>>}
 */
export async function reIssueGroupFace(param){
    return request(RE_ISSUE_GROUP_FACE, METHOD.POST, param)
}

/**
 * 删除分组中人员
 *  @returns {Promise<AxiosResponse<T>>}
 */
export async function delGroupPerson(param){
    return request(DEL_GROUP_PERSON, METHOD.POST, param)
}

/**
 * 批量下发分组人员
 *  @returns {Promise<AxiosResponse<T>>}
 */
export async function batchIssueGroupFace(param){
    return request(BATCH_ISSUE_GROUP_FACE, METHOD.POST, param)
}

/**
 * 获取管理员人员列表
 */
export async function getManagerPersonList(param){
    return request(GET_MANAGER_PERSON_LIST, METHOD.POST, param)
}

/**
 * 设备直连获取补报文件列表
 */
export async function jwBatchApplyList(param){
    return request(JW_BATCH_APPLY_LIST, METHOD.POST, param)
}

/**
 * 设备直连删除补报文件
 */
export async function jwBatchApplyDel(param){
    let url = JW_BATCH_APPLY_DEL + param.id
    return request(url, METHOD.DELETE, param)
}

/**
 * 删除设备离场人员
 */
export async function delLeavePerson(param){
    return request(DEL_LEAVE_PERSON, METHOD.GET, param)
}

/**
 *  获取近七日出勤数据
 *
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function atteReport(params) {
    return request(REPORT_ATTE_FAIL , METHOD.GET,params)
}

/**
 * 设备直连删除补报文件
 */
export async function delBackAtteRecord(params){
    return request(DEL_BACK_ATTE_RE_RECORD + `/${params.id}`, METHOD.DELETE,params)
}




export default {
    addGroup,
    getGroupList,
    delGroup,
    addDevice,
    delDevice,
    deviceAddGroup,
    getPersonFaceRecordList,
    issueGroupFace,
    reIssueGroupFace,
    delGroupPerson,
    batchIssueGroupFace,
    getManagerPersonList,
    jwBatchApplyList,
    jwBatchApplyDel,
    delLeavePerson,
    atteReport
}
