<template>
  <div style="display: flex; flex-direction: column;">
    <!-- 项目概览，建委预警显示的话，此处隐藏 -->
    <div v-if="!openWaring" class="pro-box">
      <a-row type="flex" justify="space-between">
        <a-col
          :class="proActive == index ? 'pro-active' : ''"
          class="tab-box"
          :style="item.color"
          @click="onProAction(index, item)"
          v-for="(item, index) in proList"
          :key="item.key + index"
        >
          <div style="line-height: 45px">{{ item.title }}</div>
          <div style="line-height: 20px" class="font-24">
            {{ item.value }}
          </div>
        </a-col>
      </a-row>
    </div>
    <a-row class="card mt-1" style="padding-top: 20px; flex: 1;">
      <a-col style="height: 100%;" :span="16">
        <div style="height: 100%;" class="relative">
          <baidu-map
            style="height: 100%;"
            class="baidu-map"
            :scroll-wheel-zoom="true"
            :center="location"
            :zoom="zoom"
          >
            <bm-view class="bmMap"></bm-view>
            <!-- 手动放大缩小到省市街道 位置：右下方-->
            <!-- <bm-navigation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" /> -->
            <!-- <bm-local-search :keyword="addressKeyword" :auto-viewport="true" style="display: none"></bm-local-search> -->
            <div v-for="(items, index) in persons" :key="index">
              <bm-marker
                :position="{ lng: items.lng, lat: items.lat }"
                :icon="{
                  url: items.show ? localActive : items.icon,
                  size: { width: 28, height: 28 },
                }"
                :dragging="false"
                @click="handleMarkerClick(items)"
              >
              </bm-marker>
            </div>
          </baidu-map>
        </div>
      </a-col>
      <a-col style="height: 100%;" :span="8">
        <div style="height: 100%;" class="per-project">
          <template v-if="type === 'office'" v-show="isOver">
            <!-- 总览 -->
            <div id="yhzsPieChart" :style="{display: (isOver ? 'block' : 'none')}" style="width: 98%; height: 400px"></div>
          </template>

          <template v-if="!isOver">
            <!-- 详情 -->
            <div class="info-window relative">
              <div v-if="type === 'office'" @click="isOver = true" style="color: #615e83; font-size: 14px;position: absolute;right: 0; top: -10px;">返回总览></div>
              <div class="pb-2 "></div>
              <div class="ellipsis-2 black font-16 font-bold">{{ staffObj.projectMsg.fullName || staffObj.projectName }}</div>
              <a-tag class="mt-1" :color="getTagStatus(staffObj.status,'color')">{{getTagStatus(staffObj.status)}}</a-tag>
              <a-tag v-if="getTagName(staffObj.ptype)" color="#F7A22C">{{ getTagName(staffObj.ptype) }}</a-tag>
              <div style="line-height: 30px; color: #212121; font-size: 14px;" class="ellipsis-1 font-bold mt-2 mb-2">
                <div>工程造价：{{ staffObj.projectMsg.projBudget || '暂无' }} <span v-if="staffObj.projectMsg.projBudget">万元</span></div>
                <div>参建单位：{{ staffObj.officeName || '暂无' }}</div>
                <div>参建负责人：{{ staffObj.userName || '暂无' }}</div>
                <div>联系电话：{{ staffObj.userPhone || '暂无' }}</div>
              </div>
              <a-divider/>
              <a-tooltip>
                <template slot="title">
                  {{ staffObj.projectMsg.projDesc || '暂无' }}
                </template>
                <div class="black font-bold ellipsis-2" style="font-size: 14px;-webkit-line-clamp: 5;">项目概况：{{ staffObj.projectMsg.projDesc || '暂无' }}</div>
              </a-tooltip>
            </div>
          </template>
        </div>
      </a-col>
    </a-row>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import { debounce } from "lodash";
import {
  getHomeOfficeProjeStatus,
  getHomeOfficeProject,
  getHomeOfficeProjeTotals,
} from "@/services/statistics";
import { getLwDetail } from "@/services/projectManagement";
import echarts from "echarts";
import { addCommas } from '@/utils/number.js'
const proList = [
  {
    title: "项目总数",
    key: "status",
    value: 0,
    status: 99,
    color: 'background:#0874F0'
  },
  {
    title: "在建项目数",
    key: "onlineNums",
    value: 6,
    status: 0,
    color: 'background:#33D89E'
  },
  {
    title: "未开工项目数",
    key: "createNums",
    value: 75,
    status: 3,
    color: 'background:#12C5EC'
  },
  {
    title: "停工项目数",
    key: "stopNums",
    value: 3,
    status: 2,
    color: 'background:#F6AD3A'
  },
  {
    title: "完工项目数",
    key: "offNums",
    value: 95,
    status: 1,
    color: 'background:#9DAABE'
  },
];
const proChart = [
  {
    name: "在建项目",
    key: "onlineNums",
    value: 6,
    status: 0,
    color: '#33D89E'
  },
  {
    name: "未开工项目",
    key: "createNums",
    value: 75,
    status: 3,
    color: '#12C5EC'
  },
  {
    name: "停工项目",
    key: "stopNums",
    value: 3,
    status: 2,
    color: '#F6AD3A'
  },
  {
    name: "完工项目",
    key: "offNums",
    value: 95,
    status: 1,
    color: '#9DAABE'
  },
];
const tagList =[
      {
        value: 1,
        name: "房建"
      },
      {
        value: 2,
        name: "市政"
      },
      {
        value: 3,
        name: "装修工程"
      },
      {
        value: 91,
        name: "土建"
      },
      {
        value: 92,
        name: "房屋建筑工程"
      },
      {
        value: 93,
        name: "冶炼工程"
      },
      {
        value: 4,
        name: "矿山工程"
      },
      {
        value: 5,
        name: "化工石油工程"
      },
      {
        value: 6,
        name: "水利水电工程"
      },
      {
        value: 7,
        name: "电力工程"
      },
      {
        value: 8,
        name: "农林工程"
      },
      {
        value: 9,
        name: "铁路工程"
      },
      {
        value: 10,
        name: "公路工程"
      },
      {
        value: 11,
        name: "港口与航道工程"
      },
      {
        value: 12,
        name: "航天航空工程"
      },
      {
        value: 13,
        name: "通信工程"
      },
    ]
export default {
  props: {
    type: String,
    // 是否显示建委预警功能
    openWaring: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      persons: [], // 地图上的覆盖物
      local0: require("@/assets/img/home/local-1.png"),
      local2: require("@/assets/img/home/local-2.png"),
      local1: require("@/assets/img/home/local-3.png"),
      localActive: require("@/assets/img/home/activepath.png"),
      local3: require("@/assets/img/home/path.png"),
      proList,
      proChart,
      proActive: 0,
      staffObj: {},
      mapPieChart: null,
      location: {
        lng: 113.274,
        lat: 23.123,
      },
      zoom: 11.5,
      arrRoles: ["OFFICE_MGR", "admin", "OFFICE_JSFWZY"], // 查看详情权限
      isOver: true,
      tagList
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
    canOperate() {
      return this.user.nowRoleKey.some((role) => this.arrRoles.includes(role));
    },
  },
  mounted() {
    this.getHomeOfficeProjeStatus(99); // 初始化项目总数
    this.getHomeOfficeProject();
    this.initYhzsPieChart();
    // 添加窗口 resize 事件监听
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    // 根据传入的 value 值获取标签名称
    getTagName(value) {
        const tag = this.tagList.find(tag => tag.value == value);
        return tag ? tag.name : null;
    },
    getTagStatus(status,color) {
        const tag = this.proChart.find(tag => tag.status == status);
        return tag ? (color ? tag.color : tag.name) : null;
    },
    // 处理窗口 resize 事件
    handleResize: debounce(function () {
      // 调用 ECharts 实例的 resize 方法
      this.mapPieChart.resize();
    }, 300),
    array2obj(array,key) {
      var resObj = {};
      for(var i=0;i<array.length;i++){
        resObj[array[i][key]] = array[i];
      }
      return resObj;
    },
    initYhzsPieChart(data) {
      // 基于准备好的dom，初始化echarts实例
      const chartDom = document.getElementById("yhzsPieChart");
      this.mapPieChart = echarts.init(chartDom);
      // 指定图表的配置项和数据
      // let objData = this.array2obj(data, "name");
      const setData = data
      this.mapPieChart.setOption({
        title: {
          text: '项目总览',
          top: '150', // 标题垂直居中
          left: 'center', // 标题水平居中
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          bottom: "50",
          left: "center",
          itemGap: 13,
          textStyle: {
            fontSize: 14,
            color: '#333', // 图例文本颜色
          },
          data,
          formatter: function(name) {
            // 在这里返回自定义的 legend 文本，第一行显示 value，第二行显示 label
            if (!setData) return
            const dataItem = setData.find(item => item.name === name);
            return `${name}:${addCommas(dataItem.value)}`;
          }
        },
        color: ["#307DFA", "#33D89E", "#A9E9FE", "#C6D2FD"],
        series: [
          {
            type: "pie",
            radius: ["40%", "60%"],
            center: ["50%", "40%"],
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            // adjust the start and end angle
            // startAngle: 180,
            // endAngle: 360,
            label: {
              show: false,
              position: "center",
            },
            data: data || [
              { value: 1048, name: "在建" },
              { value: 735, name: "未开工" },
              { value: 580, name: "停缓建" },
              { value: 484, name: "完工" },
            ],
          },
        ],
      });
    },
    getHomeOfficeProjeStatus(status) {
      // 查詢各項目狀態的项目清单
      getHomeOfficeProjeStatus({ status: status }).then((res) => {
        console.log(
          `%c 🚀 %c res %c`,
          'background:#35495e ; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff',
          'background:#41b883 ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff',
          'background:transparent',
          res
        )
        if (res.data.code === 0) {
          let obj = res.data.data;
          this.filterPersons(obj);
        } else {
          this.error = res.data.msg;
          this.$message.error(res.data.msg, 3);
        }
      });
    },
    filterPersons(data, status) {
      // 遍历地图覆盖物状态
      let arr = [];
      let newData = data;
      if (status || status === "0")
        newData = data.filter((item) => item.status == status);
      newData.forEach((item) => {
        // 组成百度地图适配的数据格式
        let icon = this.getIconStatusStyle(item.status);
        arr.push({
          name: item.projectName,
          id: item.projectId || item.id,
          lat: item.latitude,
          lng: item.longitude,
          icon: icon,
          show: false,
          status: item.status,
        });
      });

      if (this.type === 'office') {
        this.persons = arr;
      } else {
        const projectItem = arr.find((item) => item.id === this.user.projectId)
        if (projectItem) {
          projectItem.icon = this.localActive
          this.persons = [projectItem];
          this.handleMarkerClick(projectItem)
        } else {
          this.handleMarkerClick({id: this.user.projectId}).then(res => {
            // 组装数据
            const data = this.staffObj
            this.persons = [{
              name: data.projectMsg.fullName || data.projectName,
              id: data.projectId,
              lat: data.projectMsg.latitude,
              lng: data.projectMsg.longitude,
              icon: this.localActive,
              show: false,
              status: data.status,
            }];
          })
        }
      }
    },
    getIconStatusStyle(status) {
      switch (status) {
        case 1:
          return this.local1;
        case 2:
          return this.local2;
        case 3:
          return this.local3;
        default:
          return this.local0;
      }
    },
    onInfo(item) {
      getLwDetail({ projectId: item.id }).then((res) => {
        this.$router.push({
          name: "项目详情",
          query: {
            id: item.id,
            officeId: res.data.officeId ? res.data.officeId : undefined,
          },
        });
      });
    },
   async handleMarkerClick(item) {
    await getLwDetail({ projectId: item.id }).then((res) => {
        if (res.data) {
          this.isOver = false
          this.staffObj = res.data
          // 定位地图中心点
          this.location = {lng: res.data.projectMsg.longitude,lat: res.data.projectMsg.latitude}
        }
      });
    },
    onProAction(e, item) {
      // 高亮效果
      this.proActive = e;
      // 查詢各項目狀態的项目清单
      this.getHomeOfficeProjeStatus(item.status);
    },
    // 获取项目总数
    getHomeOfficeProject() {
      getHomeOfficeProject().then((res) => {
        if (res.data.code === 0) {
          // 项目总数每次刷新先清空
          this.proList[0].value = 0;
          const data = res.data.data;
          this.proList.forEach((el) => {
            Object.keys(data).forEach((item) => {
              if (el.key == "status") {
                // 处理项目总数
                el.value += data[item];
              } else if (el.key == item) {
                el.value = data[item];
              }
            });
          });
          this.proChart.forEach((el) => {
            Object.keys(data).forEach((item) => {
              if (el.key == item) {
                el.value = data[item];
              }
            });
          });
          this.initYhzsPieChart(this.proChart);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.card {
  border-radius: 10px;
  padding: 17px;
  background-color: #fff;
}
// 百度地图自定义样式
/deep/ .baidu-map {
  .BMap_bottom {
    display: none;
  }
  .BMap_shadow {
    display: none;
  }
  .BMap_pop {
    > div,
    > img:nth-child(10) {
      display: none;
      overflow: unset;
    }
    > div:nth-child(9) {
      display: block;
      overflow: unset;
      width: 340px !important;
    }
    > div:nth-child(8) {
      /*display: block;*/
    }
    .BMap_top {
      display: none;
    }
    .BMap_center {
      background: transparent;
      border: none;
      position: sticky !important;
    }
  }
  .BMap_bubble_content {
    background: rgba(33, 42, 63, 0.8);
    border-radius: 6px;
    padding: 14px 17px 0 19px;
    max-height: 152px !important;
    height: 152px !important;
    display: none;
    .info-window {
      font-size: 14px;
      font-family: Inter;
      width: 100%;
      // height: 100%;
      color: #e5e5ef;
      height: 138px !important;
      position: relative;
      .onInfo {
        position: absolute;
        right: 5px;
        bottom: 14px;
        // margin: -10px 5px 0 auto;
        text-align: center;
        background: rgb(10, 99, 241);
        border-radius: 15px;
        width: 74px;
        height: 26px;
        line-height: 26px;
        font-size: 12px;
      }
    }
  }
}

.bmMap {
  width: 100%;
  height: 100%;
  flex: 1;
  border-radius: 10px;
}
.pro-box {
  width: 100%;
  .tab-box {
    width: 18%;
    height: 80px;
    background: rgba(34, 42, 63, 0.8);
    border-radius: 6px;
    color: #fff;
    text-align: center;
  }
  .pro-active {
    background: #2d5aff;
  }
}
.per-project {
    // background: pink;
    // padding-left: 5px;
    // padding-right: 5px;
    // height: 278px;
    padding: 0 5px 0 20px;
  }
</style>
