<template>
  <a-card>
    <div style="background-color:#FFF;padding-top:10px;padding:10px 10px">
      <div :class="advanced ? 'search' : null">
        <a-form layout="horizontal">
          <div :class="advanced ? null: 'fold'">
            <a-row >
              <a-col :md="8" :sm="24" >
                <a-form-item
                  label="任务名称"
                  :labelCol="{span: 5}"
                  :wrapperCol="{span: 18, offset: 1}"
                >
                  <a-input placeholder="请输入" v-model="queryParams.name"/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24" >
                <a-button style="margin-left:10px;margin-top:3px" type="primary" @click="doQuery()">查询</a-button>
                <a-button style="margin-left: 8px" @click="doReset()">重置</a-button>
              </a-col>
            </a-row>
          </div>
          <span style="float: right; margin-top:-50px;margin-right: 10px;">
            <a-button type="primary" @click="addTask">新增任务</a-button>
          </span>
        </a-form>
      </div>
      <standard-table
        :columns="columns"
        :dataSource="dataSource"
        :selectedRows.sync="selectedRows"
        :pagination="pagination"
        :loading="tableLoading"
        rowKey="id"
        :isAlert="false"
        @clear="onClear"
        @change="onPageChange"
      >
        <div  slot="name" slot-scope="{text,record}">
          <a @click="viewTask(record)">{{text}}</a>
        </div>
        <div slot="action" slot-scope="{text, record}">
          <a-icon style="color:#FC5531" type="setting" title="查看任务" @click="viewTask(record)"></a-icon>
          <a-divider type="vertical" />
          <a-icon style="color:blue" type="edit" @click="editTask(record)" title="编辑任务"></a-icon>
          <a-divider type="vertical" />
          <a-icon style="color:red" type="delete" @click="TaskDelete(record)" title="删除任务"></a-icon>
        </div>
      </standard-table>
    </div>
  </a-card>
</template>

<script>
import {
  ONLINE_QUERY_TASK_LIST, 
  ONLINE_TASK_ADD,
  ONLINE_TASK_UPDATE,
  ONLINE_TASK_DELETE,
  } from '@/services/api'
import StandardTable from '@/components/table/StandardTable'
import {request} from '@/utils/request'
import { useRender } from '@/hooks/useRender.js'
const columns = [
  {
    title: '任务名称',
    dataIndex: 'name',
    align: 'center',
    scopedSlots: { customRender: 'name' }
  },
  {
    title: '发布时间',
    dataIndex: 'publishTime',
    align: 'center',
    customRender: (text) => useRender.renderDate(text)
  },{
    title: '任务类型',
    dataIndex: 'type',
    align: 'center',
   scopedSlots: { customRender: 'type' },
  },
  {
    title: '课程名称',
    dataIndex: 'courseName',
    align: 'center',
   scopedSlots: { customRender: 'courseName' },
  },
  {
    title: '课程所属公司',
    dataIndex: 'courseOfficeName',
    align: 'center',
   scopedSlots: { customRender: 'courseOfficeName' },
  },
  {
    title: '创建时间',
    dataIndex: 'createdTime',
    align: 'center',
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: '选择人数',
    dataIndex: 'totalPersonNums',
    align: 'center',
   scopedSlots: { customRender: 'totalPersonNums' },
  },
  {
    title: '备注',
    dataIndex: 'remark',
    align: 'center',
    scopedSlots: {customRender: 'remark'}
  },
  {
    title: '操作',
    align: 'center',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'QueryList',
  inject: ["fatherMethod"],
  components: {StandardTable},
  data () {
    return {
      dataSource:[],
      advanced: false,
      columns: columns,
      selectedRows: [],
      tableLoading: false,
      spinning:false,
      loadingText:"数据加载中...",
      loadKeys:[],
      visible:false,
      queryParams:{
        userName:null,
        idCard:null,
        teamId:null,
        pageNumber:1,
      },
      pagination:{
        current:1,
        pageSize:10,
        total:0,
        showLessItems:true,
        showQuickJumper:true
      },
      task:{
        visible:false,
        task:{

        }
      },
    }
  },
  created() {
    this.doQuery();
  },
  authorize: {
    deleteRecord: 'delete'
  },
  methods: {

    /**
     * 获取所有人员的签名记录信息
     */
    onClear(){

    },
    onPageChange(e){
      this.queryParams.pageNumber = e.current;
      this.pagination.current=e.current;
      this.doQuery();
    },
    doQuery(){
      this.tableLoading = true;
        request(ONLINE_QUERY_TASK_LIST,"post",this.queryParams).then(res => {
          if(res.data.code==0){
            this.dataSource = res.data.data.list;
            this.pagination.total = res.data.data.total;
          }
          this.tableLoading = false;
        })
    },
    taskClose(){
      this.task.visible=false;
      this.task.task={};
    },
    taskDelete(recode){
      var _that = this
      this.$confirm({
          title: '警告！删除学习任务',
          content: '您好，请您确认是否将该学习任务删除，删除之后，学习任务将失效！',
          okText: '仍旧删除',
          okType: 'danger',
          cancelText: '再想想',
          onOk() {
                request(ONLINE_TASK_DELETE+recode.id, "delete", {}).then((res) => {
                if (res.data.code === 0) {
                  _that.$message.success("删除成功！");
                  _that.doQuery();
                }else{
                  _that.$message.error("删除异常！");
                }
              });
          },
          onCancel() {
              console.log('Cancel');
          },
      });
    },
    taskSubmit($event){
      let methd = $event.task.id?ONLINE_TASK_UPDATE:ONLINE_TASK_ADD;
      request(methd,"post",$event.task).then(res => {
        if(res.data.code==0){
          this.doQuery();
          this.taskClose()
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    addTask(){
      this.task = {};
      this.task.visible=true;
    },
    editTask(recode){
      this.task.task = recode;
      this.task.visible=true;
    },
    toRoomList(recode){
      this.fatherMethod("/build-room");
      this.$router.push({path:'/build-room',query: {recode:recode}})
    }
  }
}
</script>

<style scoped>
  .gutter-example >>> .ant-row > div {
    background: transparent;
    border: 0;
    margin-top:5px;
  }
  .gutter-box {
    /* background: #00a0e9; */
    padding: 5px 0;
}
</style>
