<template>
    <a-card :bordered="false">
        <a-form :form="form">
            <a-form-item
                    label="疫情上报开关"
                    :labelCol="{span: 5}"
                    :wrapperCol="{span: 18, offset: 1}">
                <a-switch v-model="form.yqsbOnOff"  />
            </a-form-item>
            <a-form-item
                    label="疫情上报频率"
                    :labelCol="{span: 5}"
                    :wrapperCol="{span: 18, offset: 1}">
                <a-select v-model="form.yqsbFrequency">
                    <a-select-option value="1">每天</a-select-option>
                    <a-select-option value="2">每周</a-select-option>
                    <a-select-option value="3">每月</a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item
                    label="疫情上报提醒"
                    :labelCol="{span: 5}"
                    :wrapperCol="{span: 18, offset: 1}">
                <a-switch v-model="form.yqsbRemind"  />
            </a-form-item>
            <a-form-item label="提醒时间"
                         :labelCol="{span: 5}"
                         :wrapperCol="{span: 18, offset: 1}">
                <a-time-picker format="HH:mm" :minute-step="30" :input-read-only="true" valueFormat="HH:mm" v-model="form.yqsbTime" />
            </a-form-item>
            <a-form-item label="申报模式"
                         :labelCol="{span: 5}"
                         :wrapperCol="{span: 18, offset: 1}">
                <a-select v-model="form.yqsbModel">
                    <a-select-option value="1">详细版</a-select-option>
                    <a-select-option value="2">简洁版</a-select-option>
                </a-select>
            </a-form-item>
        </a-form>
        <a-button type="primary"  @click="save">
            保存
        </a-button>


    </a-card>
</template>
<script>
    import { getProjectConfig, updateProjectConfig } from '@/services/projectConfig';

    export default {
        data() {
            return {

                // 筛选
                form: {
                    yqsbOnOff: false,
                    yqsbFrequency: '1',
                    yqsbRemind: false,
                    yqsbTime: '',
                    yqsbModel: '1',
                },

            };
        },
        created() {
            this.initData();
        },
        methods: {
            initData() {
                getProjectConfig().then(res => {
                    // this.form = res.data.data;
                    this.form.yqsbOnOff =res.data.data.yqsbOnOff===1;
                    this.form.yqsbFrequency =res.data.data.yqsbFrequency + "";
                    this.form.yqsbRemind =res.data.data.yqsbRemind===1 ;
                    this.form.yqsbTime =res.data.data.yqsbTime + "";
                    this.form.yqsbModel =res.data.data.yqsbModel + "";
                    this.form.id = res.data.data.id;
                });
            },
            save(){
                let data = {}
                data.yqsbOnOff = this.form.yqsbOnOff?1:0;
                data.yqsbFrequency = this.form.yqsbFrequency;
                data.yqsbRemind = this.form.yqsbRemind?1:0;
                data.yqsbTime = this.form.yqsbTime;
                data.yqsbModel = this.form.yqsbModel;
                data.id = this.form.id;

                updateProjectConfig(data).then(res => {
                    if (res.data.code === 0) {
                        this.$message.success('保存成功');
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
            }


        },
    };


</script>