import {request, METHOD, removeAuthorization} from '@/utils/request'
import { ADMIN } from '@/services/api.js'

// const BASE_URL = process.env.VUE_APP_API_BASE_URL + '/safe'
const ADMIN_API = `${ADMIN}/safe`

/**
 *  安全考核被投诉详情
 */
export async function safeExamineComplainDetail(id) {
    return request(ADMIN_API + `/safeExamineComplain/detail/${id}`, METHOD.GET)
}

/**
 *  安全考核被投诉列表
 */
export async function safeExamineComplainList(params) {
    return request(ADMIN_API + `/safeExamineComplain/getComplainPage`, METHOD.POST, params)
}

/**
 *  安全考核被投诉新增
 */
export async function safeExamineComplainInsert(params) {
    return request(ADMIN_API + `/safeExamineComplain/insert`, METHOD.POST, params)
}

/**
 *  安全考核被投诉修改
 */
export async function safeExamineComplainUpdate(params) {
    return request(ADMIN_API + `/safeExamineComplain/update`, METHOD.POST, params)
}

/**
 *  安全考核被投诉--删除
 */
export async function safeExamineComplainDel(id) {
    return request(ADMIN_API + `/safeExamineComplain/del/${id}`, METHOD.DELETE)
}




/**
 *  安全考核被约谈详情
 */
export async function safeExamineTalkDetail(id) {
    return request(ADMIN_API + `/safeExamineTalk/detail/${id}`, METHOD.GET)
}

/**
 *  安全考核被约谈列表
 */
export async function safeExamineTalkList(params) {
    return request(ADMIN_API + `/safeExamineTalk/getTalkPage`, METHOD.POST, params)
}

/**
 *  安全考核被约谈新增
 */
export async function safeExamineTalkInsert(params) {
    return request(ADMIN_API + `/safeExamineTalk/insert`, METHOD.POST, params)
}

/**
 *  安全考核被约谈修改
 */
export async function safeExamineTalkUpdate(params) {
    return request(ADMIN_API + `/safeExamineTalk/update`, METHOD.POST, params)
}

/**
 *  安全考核被约谈--删除
 */
export async function safeExamineTalkDel(id) {
    return request(ADMIN_API + `/safeExamineTalk/del/${id}`, METHOD.DELETE)
}





/**
 *  集团标准化检评情况详情
 */
export async function safeStandardDetail(id) {
    return request(ADMIN_API + `/safeExamineStandard/detail/${id}`, METHOD.GET)
}

/**
 *  集团标准化检评情况列表
 */
export async function safeStandardList(params) {
    return request(ADMIN_API + `/safeExamineStandard/getStandardPage`, METHOD.POST, params)
}

/**
 *  集团标准化检评情况新增
 */
export async function safeStandardInsert(params) {
    return request(ADMIN_API + `/safeExamineStandard/insert`, METHOD.POST, params)
}

/**
 *  集团标准化检评情况修改
 */
export async function safeStandardkUpdate(params) {
    return request(ADMIN_API + `/safeExamineStandard/update`, METHOD.POST, params)
}

/**
 *  集团标准化检评情况--删除
 */
export async function safeStandardkDel(id) {
    return request(ADMIN_API + `/safeExamineStandard/del/${id}`, METHOD.DELETE)
}




/**
 *  安全考核一览表详情
 */
export async function safeExamineYearDetail(id) {
    return request(ADMIN_API + `/safeExamineYear/detail/${id}`, METHOD.GET)
}

/**
 *  安全考核一览表列表
 */
export async function safeExamineYearList(params) {
    return request(ADMIN_API + `/safeExamineYear/getExaminePage`, METHOD.POST, params)
}

/**
 *  安全考核一览表生成
 */
export async function safeExamineYearGenerate(params) {
    return request(ADMIN_API + `/safeExamineYear/generateSafeExamine`, METHOD.POST, params)
}

/**
 *  安全考核一览表新增
 */
export async function safeExamineYearInsert(params) {
    return request(ADMIN_API + `/safeExamineYear/insert`, METHOD.POST, params)
}

/**
 *  安全考核一览表修改
 */
export async function safeExamineYearkUpdate(params) {
    return request(ADMIN_API + `/safeExamineYear/update`, METHOD.POST, params)
}

/**
 *  安全考核一览表--删除
 */
export async function safeExamineYearkDel(id) {
    return request(ADMIN_API + `/safeExamineYear/del/${id}`, METHOD.DELETE)
}


/**
 *  获取安全考核创优项目
 */
export async function examineExcellent(params) {
    return request(ADMIN_API + `/labourProjectExcellent/getExamineExcellent`, METHOD.POST, params)
}

/**
 *  月度项目检查一览表-导出
 */
export const monthlyCheckExport = ADMIN_API + '/safeExamineYear/exportExaminePage'


/**
 *  集团总分
 */
export async function examineSeasonTotalList(params) {
    return request(ADMIN_API + `/safeExamineSeason/getExamineSeasonTotalPage`, METHOD.POST, params)
}

/**
 *  集团季度总分
 */
export async function examineSeasonList(params) {
    return request(ADMIN_API + `/safeExamineSeason/getExamineSeasonPage`, METHOD.POST, params)
}

/**
 *  集团季度评分表-导入
 */
export const examineSeasonImport = ADMIN_API + '/safeExamineSeason/examineSeasonImport'