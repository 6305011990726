var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.user.loginType != 0
        ? _c(
            "a-form-model",
            {
              staticClass: "clearfix",
              attrs: { model: _vm.params },
              on: { submit: _vm.handleSubmit },
            },
            [
              _c(
                "a-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "工程管理部",
                        labelCol: { span: 8 },
                        wrapperCol: { span: 14, offset: 1 },
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: { allowClear: "", placeholder: "请选择" },
                          model: {
                            value: _vm.params.gcglbId,
                            callback: function ($$v) {
                              _vm.$set(_vm.params, "gcglbId", $$v)
                            },
                            expression: "params.gcglbId",
                          },
                        },
                        _vm._l(_vm.gcglbList, function (item) {
                          return _c(
                            "a-select-option",
                            {
                              key: item.value,
                              attrs: { value: item.value, title: item.name },
                            },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "项目名称",
                        labelCol: { span: 8 },
                        wrapperCol: { span: 14, offset: 1 },
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          placeholder: "请输入项目名称",
                          allowClear: "",
                        },
                        model: {
                          value: _vm.params.projectName,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "projectName", $$v)
                          },
                          expression: "params.projectName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "形象进度",
                        labelCol: { span: 8 },
                        wrapperCol: { span: 14, offset: 1 },
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          placeholder: "请输入形象进度",
                          allowClear: "",
                        },
                        model: {
                          value: _vm.params.imageProcess,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "imageProcess", $$v)
                          },
                          expression: "params.imageProcess",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "a-space",
                    { staticClass: "btnBox" },
                    [
                      _c(
                        "a-button",
                        { attrs: { type: "primary", "html-type": "submit" } },
                        [_vm._v(" 查询 ")]
                      ),
                      _c("a-button", { on: { click: _vm.resetQuery } }, [
                        _vm._v(" 重置 "),
                      ]),
                      _vm.user.isGcglb == 0 &&
                      _vm.user.loginType == 1 &&
                      _vm.user.nowRoleKey.includes("JS_LJJ")
                        ? _c(
                            "a-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.doDowload },
                            },
                            [_vm._v(" 导出 ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.user.isGcglb == 1
        ? _c(
            "a-button",
            {
              attrs: { type: "primary", disabled: !_vm.canConfirm },
              on: { click: _vm.batchConfirm },
            },
            [_vm._v("批量确认")]
          )
        : _vm._e(),
      _c("standard-table", {
        attrs: {
          columns: _vm.columns,
          dataSource: _vm.tableData,
          rowKey: "index",
          pagination: _vm.pagination,
          loading: _vm.tableLoading,
          isAlert: false,
          bordered: true,
        },
        on: { change: _vm.handleChangePage },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function (ref) {
              var index = ref.index
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      (_vm.pagination.current - 1) * _vm.pagination.pageSize +
                        parseInt(index) +
                        1
                    )
                  ),
                ]),
              ]
            },
          },
          {
            key: "dangerAndProcess",
            fn: function (ref) {
              var index = ref.index
              var record = ref.record
              return [
                record.dangerContent
                  ? _c(
                      "a-tooltip",
                      { attrs: { placement: "topLeft" } },
                      [
                        _c("template", { slot: "title" }, [
                          _c(
                            "div",
                            {
                              staticClass: "ellipsis-2",
                              staticStyle: { "white-space": "pre-wrap" },
                            },
                            [
                              _vm._v(
                                "危大内容：" + _vm._s(record.dangerContent)
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "ellipsis-2",
                            staticStyle: { "white-space": "pre-wrap" },
                          },
                          [_vm._v("危大内容：" + _vm._s(record.dangerContent))]
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
                record.imageProcess
                  ? _c(
                      "a-tooltip",
                      { attrs: { placement: "topLeft" } },
                      [
                        _c("template", { slot: "title" }, [
                          _c("div", { staticClass: "mt-1 ellipsis-2" }, [
                            _vm._v("形象进度：" + _vm._s(record.imageProcess)),
                          ]),
                        ]),
                        _c("div", { staticClass: "mt-1 ellipsis-2" }, [
                          _vm._v("形象进度：" + _vm._s(record.imageProcess)),
                        ]),
                      ],
                      2
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "status",
            fn: function (ref) {
              var text = ref.text
              return [_c("span", [_vm._v(_vm._s(_vm._f("statusName")(text)))])]
            },
          },
          {
            key: "action",
            fn: function (ref) {
              var text = ref.text
              var record = ref.record
              return _c("div", {}, [
                _c(
                  "a",
                  {
                    on: {
                      click: function () {
                        return _vm.handleEdit(record)
                      },
                    },
                  },
                  [
                    _vm.user.nowRoleKey.includes("OFFICE_AQ_LEADER")
                      ? _c("span", [_vm._v("查看")])
                      : _c("span", [_vm._v("编辑")]),
                  ]
                ),
                record.status == 0 &&
                _vm.user.isGcglb == 1 &&
                _vm.user.loginType == 1 &&
                _vm.canConfirm
                  ? _c(
                      "a",
                      {
                        staticStyle: { color: "red" },
                        on: {
                          click: function () {
                            return _vm.handleConfirm(record)
                          },
                        },
                      },
                      [_vm._v(" 确认 ")]
                    )
                  : _vm._e(),
                record.status == 0 &&
                _vm.user.isGcglb == 1 &&
                _vm.user.loginType == 1 &&
                !_vm.canConfirm
                  ? _c("div", { staticStyle: { color: "#999" } }, [
                      _c(
                        "span",
                        { attrs: { title: "开放时间：本月22至24日" } },
                        [_vm._v("确认")]
                      ),
                    ])
                  : _vm._e(),
              ])
            },
          },
        ]),
      }),
      _c("Export-loading", {
        attrs: {
          cPercent: _vm.cPercent,
          isExporting: _vm.isExporting,
          loadingText: _vm.loadingText,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }