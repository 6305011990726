/**
 * @description: Layout TabsView
 */
export const LAYOUT_TABS_VIEW = () => import('@/layouts/tabs/TabsView.vue')

/**
 * @description: Layout BlankView
 */
export const LAYOUT_BLANK_VIEW = () => import('@/layouts/BlankView.vue')

/**
 * @description: 404 NOT FOUND
 */
export const NOT_FOUND = () => import('@/pages/exception/404')

export const PARENT_LAYOUT_NAME = 'ParentLayout'
/**
 * @description: parent-layout
 */
export function getParentLayout(_name) {
  return () =>
    new Promise((resolve) => {
      resolve({
        name: _name || PARENT_LAYOUT_NAME,
      })
    })
}

/**
 * @description: 基础的 routes
 */
export const BASE_ROUTES = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: '登录页',
    component: () => import( /* webpackChunkName: "Login" */ '@/pages/login')
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/pages/exception/404'),
  },
  {
    path: '/403',
    name: '403',
    component: () => import('@/pages/exception/403'),
  }
]