<template>
  <div class="team-box">
    <form-index
        ref="formindex"
        :formData="formData"
        :flexAction="true"
        :baiduMapBoolen="false"
        @handleSubmit="handleSubmit"
        @handleChange="handleChange"
        @handlSelect="handlSelect"
      >
        <!-- 下部分按钮插槽 -->
        <template #flexAction>
          <a-space :style="{ marginTop: '4px' }">
            <a-button icon="search" type="primary" html-type="submit"> 查询 </a-button>
            <a-space class="operator">
                        <!-- <a-button @click="reset()"> 重置 </a-button> -->
              <a-button @click="close"> 重置 </a-button>
            </a-space>

          </a-space>
        </template>
    </form-index>


    <standard-table
      :columns="columns"
      :dataSource="dataSource"
      row-key="id"
      :pagination="pagination"
      :loading="tableLoading"
      @change="onPageChange"
      :bordered="true"
    >
      <template slot="index" slot-scope="{ index }">
        <span>
          {{
            (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1
          }}
        </span>
      </template>
      <template slot="fullName" slot-scope="{ text, record }">
        <a-tooltip placement="topLeft">
            <template slot="title"
              ><span>{{ text }}</span></template
            >
            <span class="ant-btn-text ellipsis" @click="toUnits(record)">{{
              text
            }}</span>
          </a-tooltip>
        <!-- <a-button type="link" @click="toUnits(record)">{{ text }}</a-button> -->
      </template>
      <div slot="phone" slot-scope="{ text }">
        <span>{{ text ? text : "/" }}</span>
      </div>
      <div slot="action" slot-scope="{ record }">
        <a-dropdown placement="bottomCenter" class="sTables">
          <div>
            <span>操作</span>
            <a-icon
              style="margin-left: 2px; font-size: 12px"
              type="caret-down"
            />
          </div>
          <a-menu :class="['avatar-menu']" slot="overlay">
            <a-menu-item @click="viewEditing(record, 'edit')">
              <a-icon type="edit" />
              <span>修改</span>
            </a-menu-item>
            <a-menu-item @click="recorDelete(record)">
              <a-icon type="delete" />
              <span>删除</span>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </standard-table>
  </div>
</template>

<script>
import { useRender } from '@/hooks/useRender.js'

const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    align: "center",
  },
  {
    title: "项目名称",
    dataIndex: "fullName",
    width: 245,
    scopedSlots: { customRender: "fullName" },
  },
  {
    title: "工程编码",
    dataIndex: "projectNum",
    width: 180,
    scopedSlots: { customRender: "projectNum" },
  },
  {
    title: "负责人",
    dataIndex: "userName",
    align: "center",
    key: "teamLeader",
    width: 80,
    scopedSlots: { customRender: "phone" },
  },
  {
    title: "联系电话",
    dataIndex: "phone",
    align: "center",
    width: 80,
    scopedSlots: { customRender: "phone" },
  },
  {
    title: "开工日期",
    dataIndex: "startDate",
    align: "center",
    width: 80,
   customRender: (text) => useRender.renderDate(text,'YYYY-MM-DD', true),
  },
  // {
  //   title: "派遣班组",
  //   dataIndex: "leaderPhoneNumber",
  //   align: "center",
  //   width: 80,
  // },
  {
    title: "工程造价(万)",
    dataIndex: "projBudget",
    align: "center",
    width: 80,
    scopedSlots: { customRender: "phone" },
  },
  {
    title: "状态",
    dataIndex: "status",
    align: "center",
    width: 80,
  },
  {
    title: "备注",
    dataIndex: "remarks",
    align: "center",
    width: 80,
    scopedSlots: { customRender: "phone" },
  },
];
const formData = [
  {
    label: "项目名称",
    placeholder: "请输入项目名称",
    decorator: [
      "projectName",
      { rules: [{ required: false, message: "请输入项目名称" }] },
    ],
    type: "input",
    key: "projectName",
    dataSource: [],
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
  },
  {
    label: "工程编码",
    placeholder: "请输入工程编码",
    decorator: [
      "projectNum",
      { rules: [{ required: false, message: "请输入工程编码" }] },
    ],
    type: "inputGroup",
    key: "projectNum",
    col: 6,
    labelCol: 6,
    wrapperCol: 14

  },
  {
    label: "项目状态",
    placeholder: "请选择项目状态",
    decorator: [
      "status",
      { rules: [{ required: false, message: "请选择项目状态" }] },
    ],
    type: "select",
    key: "status",
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    selectlist: [
      {
        value: 3,
        name: "未开工",
      },
      {
        value: 0,
        name: "在建",
      },
      {
        value: 2,
        name: "停缓建",
      },
      {
        value: 4,
        name: "完工",
      },
    ],
  },
  {
    type: "action",
    col: 6,
  }
];
import { getOfficeProject } from "@/services/projectManagement";
import StandardTable from "@/components/table/StandardTable";
import formIndex from "@/pages/components/form/index";
export default {
  name: "SuijianClientCompanyProject",
  components: {
    StandardTable, //表格封装
    formIndex
  },
  props: {
    officeId: {
      type: [String, Number],
      require: false,
    },
  },
  data() {
    return {
      formData,
      columns, //表格数据
      dataSource: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total, range) => `共 ${total} 条`
      },
      tableLoading: false,
      prams: {
        pageNumber: 1,
        pageSize: 10,
      },
    };
  },
  watch: {
    officeId(newV, oldV) {
      if (newV) {
        this.aqxjlist();
      }
    },
  },
  mounted() {
    this.aqxjlist();
  },
  // activated() {
  //   this.aqxjlist();
  // },
  methods: {

    aqxjlist(param) {
      this.prams.officeId = this.officeId
        ? this.officeId
        : this.$route.query.id;
        let obj = Object.assign({}, param, this.prams);
      this.tableLoading = true;
      getOfficeProject(obj).then((res) => {
        if (res.data.code === 0) {
          this.tableLoading = false;
          this.dataSource = res.data.data.list ? res.data.data.list : res.data.data;
          this.pagination.total = res.data.data.total;
          this.dataSource.forEach(item => {
            item.status = this.ptypeState("status", item.status);
          })
        }
      });
    },
    //表格 - 分页查询
    onPageChange(e) {
      this.prams.pageNumber = e.current;
      this.pagination.current = e.current;
      this.aqxjlist(Object.assign({}, this.prams));
    },
    // 跳转到项目详情
    toUnits(e) {
      this.$router.push({
        name: "劳务项目详情",
        // path: "../labor/projectLw_detail",
        query: {
          id: e.id,
          officeId: this.$route.query.id
          // data: res.data.data,
        },
      });
    },
    // 项目数据格式化
    ptypeState(type, status) {
      if (type == "status") {
        const obj = {
          0: "在建",
          1: "完工",
          2: "停缓建",
          3: "未开工",
          4: "完工",
        };
        let str = null;
        Object.keys(obj).forEach((key) => {
          if (key == status) {
            str = obj[key];
          }
        });
        return str;
      }
    },
    handleSubmit(e) {
      let obj = Object.assign({}, e, this.prams);
      obj.pageNumber = 1
      this.pagination.current = 1
      this.aqxjlist(obj);
    },
    handlSelect({ val, keys }) {
      console.log(val, keys,'val, keys')
    },
    handleChange(e) {
      console.log(e,'点击===========')
    },
    close() {
      this.prams = {
        pageNumber: 1,
        pageSize: 10,
      };
      this.$refs["formindex"].resetFields();
      this.pagination.current = 1
      this.aqxjlist(this.prams);
    },
  },
};
</script>

<style lang="less" scoped>
.team-box {
  padding: 0 24px 24px 24px;
}

.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 1; /* 显示行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-btn-text {
  color: #4a86e8;
  background-color: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ant-btn-text:hover {
  cursor: pointer;
  color: #76abf5;
  background-color: transparent;
  border-color: #76abf5;
}
.operator {
    margin-right: 8px;

    .ant-btn {
      color: #307dfa;
      border-color: #307dfa;
    }
  }
</style>
