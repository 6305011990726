<template>
  <a-card>
    <form-index
      class="form"
      ref="formindex"
      :formData="formData"
      Overview="产品列表"
      :baiduMapBoolen="false"
      :flexSubmit="true"
    >
      <!-- 下部分按钮插槽 -->
      <template #flexSubmit>
        <a-space :style="{ marginTop: '4px' }">
          <a-button type="primary" html-type="submit">
          查询
        </a-button>
        <a-button @click="close"> 重置 </a-button>
        </a-space>
      </template>
    </form-index>
    <!-- <a-space class="operator">
      <a-button type="primary" @click="createItem"> 新增 </a-button>
    </a-space> -->
    <standard-table
      :columns="columns"
      :dataSource="dataSource"
      row-key="id"
      :scroll="{ x: 1300 }"
      :pagination="pagination"
      :loading="tableLoading"
      @change="onPageChange"
      :bordered="true"
    >
      <template slot="index" slot-scope="{ index }">
        <span>
          {{
            (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1
          }}
        </span>
      </template>
      <template slot="name" slot-scope="{ text, record }">
        <a-tooltip placement="topLeft">
          <template slot="title"
            ><span>{{ text }}</span></template
          >
          <span class="ant-btn-text ellipsis" @click="viewEditing(record)">{{
            text
          }}</span>
        </a-tooltip>
      </template>
      <div slot="dictId" slot-scope="{ text }">
        <span v-if="text">{{ text| statusDictId }}</span>
        <span v-else>/</span>
      </div>
      <template slot="startDate" slot-scope="{ text, record }">
        {{ text ? text : record.createTime | formatDate }}
      </template>
      <template slot="mainBusiness" slot-scope="{ text }">
        <span v-if="text">
          <span v-for="(item, index) in text" :key="index">
            <a-tag class="mt-1">{{ item }}</a-tag>
          </span>
        </span>
        <span v-else>/</span>
      </template>
      <div slot="userName" slot-scope="{ record }">
        <div v-if="record.userName">
          <div>{{ record.userName }}</div>
          <span v-if="record.userPhone">({{ record.userPhone }})</span>
        </div>
      </div>
      <div slot="action" slot-scope="{ record }">
        <a-dropdown v-if="isAdmin" placement="bottomCenter" class="sTables">
          <div>
            <span>操作</span>
            <a-icon
              style="margin-left: 2px; font-size: 12px"
              type="caret-down"
            />
          </div>
          <a-menu :class="['avatar-menu']" slot="overlay">
            <a-menu-item @click="viewEditing(record, 'edit')">
              <a-icon type="edit" />
              <span>编辑</span>
            </a-menu-item>
            <a-menu-item @click="recorDelete(record)">
              <a-icon type="delete" />
              <span>删除</span>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <span v-else>/</span>
      </div>
    </standard-table>
  </a-card>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    align: "center",
  },
  {
    title: "产品品牌",
    align: "center",
    dataIndex: "pdMp",
    width: 80,
  },
  {
    title: "产品",
    align: "center",
    dataIndex: "typeName",
    width: 100,
  },
  {
    title: "子产品",
    dataIndex: "pdName",
    width: 80,
    scopedSlots: { customRender: "name" },
  },
  {
    title: "产品类别",
    dataIndex: "dictId",
    width: 80,
    align: "center",
    scopedSlots: { customRender: "dictId" },
  },
  {
    title: "产品详情",
    dataIndex: "ggxh",
    width: 200,
    scopedSlots: { customRender: "name" },
  },
  {
    title: "产品单位",
    dataIndex: "unit",
    width: 60,
    align: "center",
  },
  {
    title: "产品价格(不含税)",
    dataIndex: "price",
    width: 100,
    align: "center",
  },
  {
    title: "标准税率",
    dataIndex: "tax",
    width: 80,
    align: "center",
  },
  {
    title: "含税单价",
    dataIndex: "lastPrice",
    width: 80,
    align: "center",
  },
  {
    title: "备注",
    dataIndex: "pdRemark",
    width: 80,
    scopedSlots: { customRender: "name" },
  },
  {
    title: "操作",
    align: "center",
    width: 80,
    scopedSlots: { customRender: "action" },
  },
];
const formData = [
  {
    label: "产品名称",
    placeholder: "请输入产品名称",
    decorator: [
      "name",
      { rules: [{ required: false, message: "请输入产品名称" }] },
    ],
    type: "inputGroup",
    key: "name",
    dataSource: [],
    col: 4,
    labelCol: 6,
    wrapperCol: 14,
  },
];
import { getProductDetailList,delProductDetail } from "@/services/product";
import { mapGetters } from "vuex";
export default {
  name: "SuijianClientProjectListIndex",
  components: {
    formIndex, //form表单封装
    StandardTable, //getProductList表格封装
  },
  data() {
    return {
      columns, //表格数据
      dataSource: [],
      formData: formData,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      prams: {
        pageNumber: 1,
        pageSize: 10,
      },
      tableLoading: false,
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
    isAdmin() {
      return this.user.nowRoleKey.includes("admin");
    },
  },
  filters: {
    statusDictId(status) {
      const dictMap = {
        '1': '软件产品',
        '2': '增值服务',
        '3': '硬件设备',
      }
      return  dictMap[status]
    }
  },

  mounted() {
    this.aqxjlist(this.prams);
  },

  methods: {
    //表格 - 分页查询
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      this.prams.pageNumber = e.current;
      this.pagination.current = e.current;
      this.aqxjlist(Object.assign({}, this.prams, obj));
    },
    // 重置
    close() {
      this.prams = {
        pageNumber: 1,
        pageSize: 10,
      };
      this.aqxjlist(this.prams);
      this.$refs["formindex"].resetFields();
    },
    // 新建项目
    createItem(e) {
      // this.title = "新增产品";
      // this.visible = true;
      // this.type = "add";
      this.$router.push({
        name: "子产品",
      });
    },
    // 获取产品列表
    aqxjlist(prams) {
      this.tableLoading = true;
      getProductDetailList(prams).then((res) => {
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
        }
        this.tableLoading = false;
      });
    },
    // 封装弹窗确定按钮
    confirm(title, content) {
      return new Promise((resolve, reject) => {
        this.$confirm({
          title: title,
          content: content,
          onOk() {
            resolve();
          },
        });
      });
    },
    // 编辑详情
    viewEditing(e) {
      this.$router.push({
        name: "子产品",
        query: {
          id: e.id,
        },
      });
    },
    // 删除子产品
    recorDelete(e) {
      let params = {
        id: e.id,
      };
      this.confirm("删除", "是否删除").then(() => {
        delProductDetail(params).then((res) => {
          if (res.data.code === 0) {
            this.$message.success("删除成功", 10);
            this.aqxjlist(this.prams);
          } else {
            this.$message.error(res.data.msg, 10);
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 显示行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-btn-text {
  color: #4a86e8;
  background-color: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ant-btn-text:hover {
  cursor: pointer;
  color: #76abf5;
  background-color: transparent;
  border-color: #76abf5;
}
</style>
