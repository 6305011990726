import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './theme/index.less'
import './assets/styles/global.less'
import Antd from 'ant-design-vue'
import Viser from 'viser-vue'
import '@/mock'
import store from './store'
import 'animate.css/source/animate.css'
import Plugins from '@/plugins'
import {initI18n} from '@/utils/i18n'
import bootstrap from '@/bootstrap'
import 'moment/locale/zh-cn'
import vuescroll from "vuescroll";//引入vuescroll
import "vuescroll/dist/vuescroll.css";//引入vuescroll样式
import BaiduMap from 'vue-baidu-map'
import echarts from 'echarts'
import '@/assets/iconfont/iconfont.css' // 引入阿里自定义图标库
// import SlideVerify from 'vue-monoplasty-slide-verify'; // 引入滑动验证



Vue.prototype.$echarts = echarts
const i18n = initI18n('CN', 'US')

Vue.use(vuescroll);//使用
Vue.use(Antd)
Vue.config.productionTip = false
Vue.use(Viser)
Vue.use(Plugins)
Vue.use(BaiduMap,{ak:'AybiKm2RzshFzseoQVzWkbM1lKUruypo'})
// Vue.use(SlideVerify);

bootstrap({router, store, i18n, message: Vue.prototype.$message, warning: Vue.prototype.$warning})


new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
