var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: "报价单列表" } },
    [
      _c(
        "a-space",
        { staticClass: "operator" },
        [
          _c(
            "a-button",
            { attrs: { type: "primary" }, on: { click: _vm.createItem } },
            [_vm._v(" 新增 ")]
          ),
          _c("a-button", { attrs: { type: "primary" } }, [_vm._v(" 导出 ")]),
        ],
        1
      ),
      _c("standard-table", {
        attrs: {
          columns: _vm.columns,
          dataSource: _vm.dataSource,
          "row-key": "id",
          pagination: _vm.pagination,
          loading: _vm.tableLoading,
          bordered: true,
        },
        on: { change: _vm.onPageChange },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function (ref) {
              var index = ref.index
              return [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        (_vm.pagination.current - 1) * _vm.pagination.pageSize +
                          parseInt(index) +
                          1
                      ) +
                      " "
                  ),
                ]),
              ]
            },
          },
          {
            key: "action",
            fn: function (ref) {
              var record = ref.record
              return _c(
                "div",
                {},
                [
                  _vm.isAdmin
                    ? _c(
                        "a-dropdown",
                        {
                          staticClass: "sTables",
                          attrs: { placement: "bottomCenter" },
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("span", [_vm._v("操作")]),
                              _c("a-icon", {
                                staticStyle: {
                                  "margin-left": "2px",
                                  "font-size": "12px",
                                },
                                attrs: { type: "caret-down" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "a-menu",
                            {
                              class: ["avatar-menu"],
                              attrs: { slot: "overlay" },
                              slot: "overlay",
                            },
                            [
                              _c(
                                "a-menu-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.viewEditing(record, "edit")
                                    },
                                  },
                                },
                                [
                                  _c("a-icon", { attrs: { type: "edit" } }),
                                  _c("span", [_vm._v("修改")]),
                                ],
                                1
                              ),
                              _c(
                                "a-menu-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.recorDelete(record)
                                    },
                                  },
                                },
                                [
                                  _c("a-icon", { attrs: { type: "delete" } }),
                                  _c("span", [_vm._v("删除")]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c("span", [_vm._v("/")]),
                ],
                1
              )
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }