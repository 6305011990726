<template>
  <div>
    <div class="constructiontTable_index">
      <form-index
        ref="formindex"
        :formData="formData"
        :flexAction="true"
        :baiduMapBoolen="false"
        @handleSubmit="handleSubmit"
        @handleChange="handleChange"
        @handlSelect="handlSelect"
        @changeMonth="changeMonth"
      >
        <!-- 下部分按钮插槽 -->
        <template #flexAction>
          <a-space :style="{ marginTop: '4px' }">
            <a-button icon="search" type="primary" html-type="submit">查询</a-button>
            <a-button @click="close"> 重置 </a-button>
          </a-space>
        </template>
      </form-index>
      <!-- 表格封装 -->
      <div>
        <standard-table
          class="table"
          :columns="columns"
          :dataSource="dataSource"
          rowKey="projectId"
          :pagination="pagination"
          :loading="tableLoading"
          @change="onPageChange"
          :isAlert="false"
          :bordered="true"
          tableSize="middle"
        >
          <template slot="index" slot-scope="{ index }">
            <span>
              {{
                (pagination.current - 1) * pagination.pageSize +
                parseInt(index) +
                1
              }}
            </span>
          </template>
          <template slot="projectName" slot-scope="{ text }">
              <a-tooltip placement="topLeft">
                <template slot="title"
                  ><span>{{ text }}</span></template
                >
                <span class="ant-btn-text ellipsis-2">{{ text  }}</span>
              </a-tooltip>
            </template>
        </standard-table>
      </div>
    </div>
  </div>
</template>

<script>
import StandardTable from "@/components/table/StandardTable";
import formIndex from "@/pages/components/form/index";
import { mapGetters} from "vuex";
import { getOfficeProject } from "@/services/projectManagement";
import { getProSumList } from '@/services/payroll'
import moment from 'moment';

const formData = [
  {
    label: "项目名称",
    placeholder: "请输入项目名称",
    decorator: [
      "projectId",
      { rules: [{ required: false, message: "请输入项目名称" }] },
    ],
    type: "inputGroup",
    key: "projectId",
    dataSource: [],
    col: 5,
    labelCol: 6,
    wrapperCol: 16,
    display: true,
  },
  {
    type: "action",
    col: 6,
    display: true,
  },

];
export default {
  components: {
    StandardTable, //表格封装
    formIndex,
  },
  data() {
    const columns = [
      {
        title: "序号",
        width: 65,
        scopedSlots: { customRender: "index" },
        align: "center",
      },
      {
        title: "计划编码",
        dataIndex: "projectName",
        align: "center",
        scopedSlots: { customRender: "projectName" },
      },
      {
        title: "项目名称",
        dataIndex: "projectName",
        align: "center",
        width: 350,
        scopedSlots: { customRender: "projectName" },
      },
      {
        title: "合同编号",
        dataIndex: "payableMoney",
        align: "center",
        ellipsis: true,
      },
      {
        title: "制表日期",
        dataIndex: "taxMoney",
        align: "center",
        // ellipsis: true,
        scopedSlots: { customRender: "taxMoney" },
        // fixed: "left",
      },
      {
        title: "不含税应发总额",
        dataIndex: "actualMoney",
        align: "center",
        scopedSlots: { customRender: "actualMoney" },
      },
      {
        title: "含税应发总额",
        align: "center",
        dataIndex: "teamCount",
        scopedSlots: { customRender: "teamCount" },
      },
      {
        title: "状态",
        dataIndex: "peopleCount",
        width: 80,
        align: "center",
        scopedSlots: { customRender: "peopleCount" },
      }
    ];
    return {
      projectArr: [],
      columns: columns, //表格数据
      dataSource: [],
      pagination: {
        showSizeChanger:true,
        pageSizeOptions: ["10", "50", "100", "150"],
        current: 1,
        pageSize: 50,
        total: 0,
        showLessItems: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      tableLoading: false,
      formData: formData,
      params: {
        pageNumber: 1,
        pageSize: 50,
      },
      loadingText: "数据加载中...",
      projectId: "",
      query: this.$route.query,
      selectedRows: [], //选中的数据
      taskObj: {
        taskCount: 0,
        endTaskCount: 0
      },
      arrRoles:['zong_bao_lao_wu',], // 下发设备权限
      staffHkFace: [],
      showThirdMotal:false,
      distributeVisible: false,
      workNumList: null,
      showMotalType: null
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
    canOperate() {
      return this.user.nowRoleKey.some(role => this.arrRoles.includes(role));
    },
  },
  watch: {
    loadingText: {
      handler(newName, oldName) {
        this.loadingText = newName;
      },
      immediate: true,
    },
  },
  activated() {
    this.params= {
        pageNumber: 1,
        pageSize: 50,
        // officeId: this.query.officeId ? this.query.officeId: this.unitOfficeId
      },
    this.aqxjlist(this.params);
  },
  methods: {
    changeMonth(e) {
      const { val, key } = e;
      if (key === "searchMonthArr") {
        this.$refs["formindex"].setFieldsValue('', { [key] : [moment(val[0]).format('YYYYMM'),moment(val[1]).format('YYYYMM')] },'');
      }
    },
    aqxjlist(params) {
      this.tableLoading = true;
      getProSumList(params).then(res => {
        if (res.data.code == 0) {
        this.dataSource = res.data.data
        this.pagination.total = res.data.total;
        } else {
        this.$message.warning('获取数据失败')
        }
        this.tableLoading = false;
      })
    },
    handleChange(e) {
      this.projectArr = [];
      this.projectList = []
      this.getZdata(getOfficeProject, e, "projectName",'fullName');
    },
    // 接口封装
    /**
     * msg 接收接口名称
     * e 数据
     * name 参数名
     * */
     getZdata(msg, e, name,title) {
      let data = {
        [name]: e.value,
        officeId: this.user.officeId,
      };
      msg(data).then((res) => {
        if (res.status == 200) {
          res.data.data.list.forEach((item) => {
            this.projectArr.push({
              title: item[title],
              id: item.id,
            });
          });
          this.projectList = res.data.data;
          // 获取当前匹配key，赋值相对于数组
          this.formData.forEach((item) => {
            if (e.item.key === item.key) {
              item.dataSource = this.projectArr;
            }
          });
        }
      });
    },
    // 根据条件查询公司
    // aqxCheckList()
    // 对下拉框做数据处理
    formDataFn(key, data) {
      this.formData.forEach((item) => {
        if (item.key == key) {
          item.selectlist = data;
        }
      });
    },
    // 提交表单数据
    handleSubmit(e) {
      let obj = Object.assign({}, e, this.params);
      // 处理查询默认格式
      if (obj.searchMonthArr == '') obj.searchMonthArr = []
      if (e.projectId) {
        this.formData.forEach(el => {
          if (el.key == 'projectId') {
            this.$set(obj, 'projectName', this.projectList.find(i => e.projectId === i.id).fullName)
          }
        })
      } else {
        this.$set(obj, 'projectName', '')
      }
      obj.pageNumber = 1
      this.pagination.current = 1;
      this.selectedRows = []
      this.aqxjlist(obj);
    },
    // 特殊数据处理
    handlSelect(obj) {
      const { val, keys } = obj;
      if (keys === "officeId") {
        this.projectList.forEach((item) => {
          if (item.officeName == val) {
            this.$refs["formindex"].setFieldsValue(
              item.officeId,
              {},
              "officeId"
            );
          }
        });
      }
    },
    // 重置
    close() {
      this.params = {
        pageNumber: 1,
        pageSize: 50,
        // projectId:this.user.projectId,
      };
      this.pagination.current = 1;
      this.pagination.pageSize = 50;
      this.aqxjlist(this.params);
      this.$refs["formindex"].resetFields();
    },
    //表格 - 分页查询
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      // 处理查询默认格式
      if (obj.searchMonthArr == '') obj.searchMonthArr = []
      this.params.pageNumber = e.current;
      this.params.pageSize = e.pageSize;
      this.pagination.current = e.current;
      this.pagination.pageSize = e.pageSize;
      this.aqxjlist(Object.assign(obj, this.params));
    },
  },
};
</script>

<style lang="less" scoped>
.constructiontTable_index {
  padding: 24px;
  background: #fff;
  border-radius: 6px;
  margin-bottom: 10px;
  min-height: 560px;
  /deep/ .light-select {
    border-color:#fd9e06;
    width: 155px;
    .ant-select-selection__placeholder {
      color: #fff
    }
    .ant-select-selection {
      background: #fd9e06;
      border-color:#fd9e06;
      color: #fff;
      .ant-select-arrow {
      color: #fff;
      }
    }

   }
}
/deep/ .ant-modal-footer {
    text-align: center;
    border-top: none;
    padding-bottom: 24px;
}
/deep/ .ant-modal-body {
  padding-bottom: unset;
}
</style>
