<template>
  <div class="a_Approval">
    <div class="plug-Overview">安全生产隐患排查治理情况统计表</div>
    <a-divider />
    <a-form-model layout="inline" :model="params" class="mb-8 query-form" @submit="handleSubmit">
      <a-form-model-item label="工程管理部">
        <a-select
          v-model="params.gcglbId"
          allowClear
          placeholder="请选择"
          style="width: 220px"
        >
          <a-select-option
            v-for="item in gcglbList"
            :key="item.value"
            :value="item.value"
            :title="item.name"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <!-- <a-form-model-item label="月度">
        <a-month-picker style="width: 220px" placeholder="请选择月份" format="YYYY-MM" v-model="monthPickerVal">
          <a-icon slot="suffixIcon" type="smile" />
        </a-month-picker>
      </a-form-model-item> -->
      <a-form-model-item label="项目名称">
        <a-input style="width: 220px" placeholder="请输入项目名称" v-model="params.projectName" allowClear/>
      </a-form-model-item>
      <a-form-model-item>
        <a-space>
          <a-button type="primary" html-type="submit"> 查询 </a-button>
          <a-button @click="resetQuery"> 重置 </a-button>
          <a-button type="primary" @click="doDowload" v-if="user.isGcglb == 0 && user.loginType == 1 && user.nowRoleKey.includes('JS_LJJ')"> 导出 </a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
    <!-- <div style="float: right; margin-top: -30px; margin-bottom: 16px">
      <a-button class="mr-9" type="primary" @click="handleQuery"> 查询 </a-button>
      <a-button class="mr-9 ml-4" @click="resetQuery"> 重置 </a-button>
      <a-button class="mr-9" type="primary" @click="doDowload" v-if="user.isGcglb == 0 && user.loginType == 1"> 导出 </a-button>
    </div> -->
    <standard-table
      :columns="columns"
      :dataSource="tableData"
      rowKey="id"
      :scroll="{ x: 1300 }"
      :pagination="pagination"
      :loading="tableLoading"
      @change="handleChangePage"
      :isAlert="false"
      :bordered="true"
    >
      <template slot="index" slot-scope="{ index }">
        <span v-if="index < tableData.length - 1">{{ itemNo(index) }}</span>
        <span v-else>合计</span>
      </template>
      <template slot="problems" slot-scope="{ index, record }">
        <div v-for="(problem, index) in record.problems" :key="index">{{ problem }}</div>
      </template>
      <div slot="action" slot-scope="{ index, record }">
        <a @click="() => handleEdit(record)" >
          {{index !== tableData.length - 1 ? '查看' : ''}}
        </a>
        <a
          v-if="record.status == 1"
          @click="() => handleBack(record)"
          style="color: red;"
        >
          退回
        </a>
      </div>
    </standard-table>

    <Export-loading
      :cPercent="cPercent"
      :isExporting="isExporting"
      :loadingText="loadingText"
    ></Export-loading>
  </div>
</template>

<script>
import {
  getHiddenDangerStaticList, getHiddenDangerStaticTotal,
  hiddenDangerStaticExportUrl, projectDangerStaticBack
} from "@/services/safeMonthlyReport";
import { request } from "@/utils/request";
import { QUERY_OFFICE_BY_USER } from "@/services/api";
import ExportLoading from "@/components/ExportLoading/ExportLoading"; // progress组件
import { exportProgress } from "@/pages/aqjyzl/export"; // 导出方法
import { mapGetters } from "vuex";
import StandardTable from "@/components/table/StandardTable";
import moment from "moment";

const columns = [
  {
    title: "序号",
    align: "center",
    scopedSlots: { customRender: "index" },
    width: 65,
  },
  {
    title: "工程管理部",
    align: "center",
    dataIndex: "gcglbName",
    width: 110,
  },
  {
    title: "项目或场所",
    dataIndex: "projectName",
    width: 280,
    ellipsis: true,
    tooltip: true
  },
  {
    title: "排查治理隐患总量（项）",
    dataIndex: "problemNums",
    align: "center",
    width: 80,
  },
  {
    title: "一般隐患",
    align: "center",
    children: [
      {
        title: "排查一般隐患（项）",
        dataIndex: "problemNumsNormal",
        align: "center",
        width: 80,
      },
      {
        title: "已整改（项）",
        dataIndex: "problemNumsNormalDone",
        align: "center",
        width: 80,
      },
      {
        title: "整改率（%）",
        dataIndex: "problemNumsNormalRateString",
        align: "center",
        width: 80,
      },
    ],
  },
  {
    title: "重大隐患",
    align: "center",
    children: [
      {
        title: "排查重大隐患（项）",
        dataIndex: "problemNumsSerious",
        align: "center",
        width: 80,
      },
      {
        title: "已整改（项）",
        dataIndex: "problemNumsSeriousDone",
        align: "center",
        width: 80,
      },
      {
        title: "整改率（%）",
        dataIndex: "problemNumsSeriousRateString",
        align: "center",
        width: 80,
      },
    ],
  },
  {
    title: "隐患分布情况",
    align: "center",
    children: [
      {
        title: "安全管理（项）",
        dataIndex: "safeManager",
        align: "center",
        width: 80,
      },
      {
        title: "文明施工（项）",
        dataIndex: "civilizedConstruction",
        align: "center",
        width: 80,
      },
      {
        title: "脚手架（项）",
        dataIndex: "scaffold",
        align: "center",
        width: 80,
      },
      {
        title: "基坑与模板（项）",
        dataIndex: "baseModel",
        align: "center",
        width: 80,
      },
      {
        title: "三宝四口临边（项）",
        dataIndex: "threeFour",
        align: "center",
        width: 80,
      },
      {
        title: "施工用电（项）",
        dataIndex: "constructionElectricity",
        align: "center",
        width: 80,
      },
      {
        title: "井架与电梯（项）",
        dataIndex: "derrickElevator",
        align: "center",
        width: 80,
      },
      {
        title: "塔吊（项）",
        dataIndex: "towerCrane",
        align: "center",
        width: 80,
      },
      {
        title: " 起重吊装（项）",
        dataIndex: "hoisting",
        align: "center",
        width: 80,
      },
      {
        title: " 施工机具（项）",
        dataIndex: "constructionEquipment",
        align: "center",
        width: 80,
      },
      {
        title: " 其他（项）",
        dataIndex: "others",
        align: "center",
        width: 80,
      },
    ],
  },
  {
    title: "备注",
    align: "center",
    children: [
      {
        title: "违法违规情况（项）",
        dataIndex: "illegal",
        align: "center",
        width: 80,
      },
    ],
  },
  {
    title: "操作",
    align: "center",
    fixed: "right",
    width: 65,
    scopedSlots: { customRender: "action" },
  },
];

export default {
  mixins: [exportProgress], //导出方法注入
  components: {
    ExportLoading,
    StandardTable
  },
  data() {
    return {
      monthPickerVal: moment(new Date(), 'YYYY-MM'),
      params: {
        month: '',
        gcglbId: '',
        projectName: '',
        year: '',
        pageNumber: 1,
        pageSize: 10,
      },
      pagination: {
        //分页数据
        current: 1,
        pageSize: 11,
        total: 0,
        showTotal: (total, range) => `共 ${total} 条`,
      },
      gcglbList: [],
      total: 0,
      columns: columns,
      tableData: [],
      tableLoading: false, //table加载中

      loadingText: "数据加载中...",
      cPercent: 0,
      isExporting: false,
    };
  },
  created() {
    this.getgcglbList(QUERY_OFFICE_BY_USER);
  },
  activated() {
    this.handleQuery();
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.params.pageNumber = 1
      this.pagination.current = 1
      this.tableData = []
      this.handleQuery();
    },
    //计算序号
    itemNo(index) {
      let no = 0
      if (this.pagination.current === 1) {
        no = (this.pagination.current - 1) * this.pagination.pageSize + parseInt(index) + 1
      } else {
        no = ((this.pagination.current - 1) * this.pagination.pageSize + parseInt(index) + 1) - (this.pagination.current - 1)
      }
      return no
    },
    handleQuery(e) {
      this.tableLoading = true
      const now = moment() // 当前时间
      const targetDate = moment().date(25).hour(3).minute(0).second(0) // 设置目标日期为当月的25号03:00
      if (now.isBefore(targetDate)) {
        // console.log('当前时间早于当月25号03:00');
        this.params.year = moment(now).format('YYYY-MM').split('-')[0]
        this.params.month = moment(now).format('YYYY-MM').split('-')[1]
      } else if (now.isAfter(targetDate)) {
        // console.log('当前时间晚于当月25号03:00');
        this.params.year = moment(now).add(1,'months').format('YYYY-MM').split('-')[0]
        this.params.month = moment(now).add(1,'months').format('YYYY-MM').split('-')[1]
      }
      getHiddenDangerStaticList(this.params).then((res) => {
        this.tableLoading = false
        let d = res.data;
        if (d.code === 0) {
          this.tableData = d.data.list
          this.pagination.total = d.data.total + 1;
          this.total = d.data.total;
          this.getTotal()
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    getTotal() {
      getHiddenDangerStaticTotal(this.params).then(res => {
        let d = res.data;
        if (d.code === 0) {
          d.data.id = new Date().getTime() + ""
          this.tableData.push(d.data)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    handleChangePage(e) {
      this.params.pageNumber = e.current;
      this.pagination.current = e.current;
      this.handleQuery();
    },
    // 获取工程管理部数据
    getgcglbList(path) {
      request(path, "get").then((res) => {
        let data = res.data.data.filter(item => item.id != "3da0f5533f87425c9a9e8a11a7923300")
        const arr = [];
        data.forEach((item) => {
          arr.push({
            name: item.master,
            value: item.id,
          });
        });
        this.gcglbList = arr;
      });
    },
    resetQuery() {
      this.monthPickerVal = null
      this.pagination.pageNumber = 1
      this.params = {
        month: '',
        gcglbId: '',
        projectName: '',
        year: '',
        pageNumber: 1,
        pageSize: 10,
      };
      this.handleQuery();
    },
    handleEdit(row) {
      console.log(row)
      this.$router.push({
        path: `/hidden_danger_static_edit`,
        query: {
          id: row.id,
        },
      });
    },
    handleBack(e) {
      const _that = this
      this.$confirm({
          title: '确认退回？',
          onOk() {
            projectDangerStaticBack(e.id).then(res => {
              let d = res.data;
              if (d.code === 0) {
                _that.$message.success('退回成功')
                _that.handleQuery()
              } else {
                _that.$message.error(res.data.msg)
              }
            })
          }
        })
    },
    doDowload() {
      const _that = this
      this.$confirm({
        title: '导出上报后内容不可编辑，确认导出？',
        onOk() {
          let obj = _that.params;
          delete obj.pageNumber
          delete obj.pageSize
          _that.loadingText = "正在导出EXCEL文件，请稍候 ····";
          _that.isExporting = true;
          _that.cPercent = 0;
          let fileName = "安全生产隐患排查治理情况统计表.xlsx";
          _that.exportFunNomal2(
            hiddenDangerStaticExportUrl,
            obj,
            fileName,
            "application/vnd.ms-excel"
          );
        }
      })
    },
  },
};
</script>

<style lang="less" scoped>
@import "@/theme/common.less";
.query-form {
  display: flex;
  // justify-content: space-between;
}
.ant-table td { white-space: nowrap; }
</style>
