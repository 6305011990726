<template>
  <div class="m_g">
      <div class="flex p-1">
        <div class="wapper">
          <div class="m_g_left public">
            <form-index ref="formIndex" @onDateChange="onDateChange" :formData="formData" Overview="基本信息" :options="options"
            @resourceUpload="resourceUpload" @upRemove="upRemove" @handlSelect="handlSelect"></form-index>
          </div>
          <div class="m_g_left mt-1 public">
            <form-index ref="formIndexInfo" :formData="formDataInfo" Overview="紧急联系人信息"/>
          </div>
        </div>
        <div class="wapper">
          <div class="m_g_right_top public">
            <form-index ref="formIndex1" :options="options" @upRemove="upRemove" :formData="formDataTop" Overview="工卡/工资卡信息" @resourceUpload="resourceUpload" @handleChange="handleChange" @handlSelect="handlSelect"></form-index>
          </div>
          <div class="m_g_right_mid public">
            <form-index ref="formIndex2" :formData="formDataMid" Overview="工种/职务信息" @upRemove="upRemove" @handlSelect="handlSelect" @resourceUpload="resourceUpload"></form-index>
          </div>
          <div class="m_g_right_bottom public">
            <form-index ref="formIndex3" :formData="formDataBottom" Overview="证件信息" @upRemove="upRemove" @resourceUpload="resourceUpload"></form-index>
          </div>
        </div>
      </div>
    <template>
      <div class="m_g_button">
        <a-button type="primary" @click="handleSubmit">
          提交
        </a-button>
      </div>
    </template>
  </div>
</template>

<script>
// let obj =  this.$refs['formindex'].getFieldValue()
let _that = null
const formDataInfo = [
  {
    label: "姓名",
    placeholder: "请输入姓名",
    decorator: [
      "emergencyUserName",
      { rules: [{ required: false, message: "请输入姓名" },{ required: false, message: "最长不超过12位汉字" ,pattern:/(^[\u4e00-\u9fa5]{1,12}$)/}] },
    ],
    type: "input",
    key: "emergencyUserName",
    labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
    maxLength: 13
  },
  {
    label: "电话",
    placeholder: "请输入紧急联系人电话",
    decorator: [
      "emergencyUserPhone",
      { rules: [{ required: false, message: "请正确输入紧急联系人电话",pattern: new RegExp(/^1\d{10}$/),}] },
    ],
    type: "input",
    key: "emergencyUserPhone",
     labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
    maxLength: 11,
  },
  {
    label: "地址",
    placeholder: "请输入地址",
    decorator: [
      "emergencyAddress",
      { rules: [{ required: false, message: "请输入地址" }] },
    ],
    type: "input",
    key: "emergencyAddress",
    labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
    maxLength: 13
  },
  {
    label: "与劳动者关系",
    placeholder: "请选择",
    decorator: [
      "emergencyRelation",
      { rules: [{ required: false, message: "请选择" }] },
    ],
    showSearch: true,//暂时注释
    type: "select",
    selectlist: [
      {
        name: "父子",
        value: "父子",
      },
      {
        name: "母子",
        value: "母子",
      },
      {
        name: '父女',
        value: "父女",
      },
      {
        name: '母女',
        value: "母女",
      },
      {
        name: '夫妻',
        value: "夫妻",
      },
      {
        name: '兄弟姐妹',
        value: "兄弟姐妹",
      },
      {
        name: '其他',
        value: "其他",
      }
    ],
    key: "emergencyRelation",
    labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
  },
  ]
const formData = [
  {
    type: "place",
    labelCol: 6,
    wrapperCol: 16,
    col: 2,
  },
  {
    label: "",
    title: "上传正面照",
    placeholder: "请上传正面照",
    decorator: [
      "facePath",
      { rules: [{ required: true, message: "请上传正面照" }] },
    ],
    type: "tsUpload",
    key: "facePath",
    labelCol: 6,
    wrapperCol: 16,
    defaultImg: require('@/assets/img/front.png'),
    col: 8,
    defaultFileList: [],
    display: true,
  },
   {
    label: "",
    title: "上传反面照",
    placeholder: "请上传反面照",
    defaultImg: require('@/assets/img/opposite.png'),
    defaultFileList: [],
    previewImage: '',
    decorator: [
      "backPath",
      { rules: [{ required: true, message: "请上传反面照" }] },
    ],
    type: "tsUpload",
    key: "backPath",
    labelCol: 8,
    wrapperCol: 14,
    col: 8,
    display: true,
  },
  {
    label: "",
    title: "上传头像",
    placeholder: "请上传头像",
    defaultImg: require('@/assets/img/headsculpture.png'),
    defaultFileList: [],
    previewImage: '',
    decorator: [
      "appPhotoPath",
      { rules: [{ required: true, message: "请上传头像" }] },
    ],
    type: "tsCropperUpload",
    key: "appPhotoPath",
    labelCol: 6,
    wrapperCol: 16,
    col: 6,
    display: true,
    compress: true,
    cropperTitle: '编辑头像',
    showCroImg: true
  },
  {
    label: "姓名",
    placeholder: "请输入姓名",
    decorator: [
      "name",
      { rules: [{ required: true, message: "请输入姓名" },{ required: true, message: "最长不超过12位汉字" ,pattern:/(^[\u4e00-\u9fa5]{1,12}$)/}] },
    ],
    type: "input",
    key: "name",
    dataSource: [],
    labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
    maxLength: 13
  },
  {
    label: "性别",
    placeholder: "请选择性别",
    decorator: [
      "gender",
      {
        rules: [{ required: true, message: "请选择性别" }],
      },
    ],
    type: "radio",
    class: "radio",
    key: "gender",
    radiolist: [
      {
        name: "男",
        value: "男",
      },
      {
        name: "女",
        value: "女",
      },
    ],
     labelCol: 8,
    wrapperCol: 12,
    col: 12,
    display: true,
  },
  {
    label: "身份证号",
    placeholder: "请输入身份证号",
    decorator: [
      "idCard",
      { rules: [{ required: true, message: "请正确输入身份证号", pattern:/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,}] },
    ],
    type: "input",
    key: "idCard",
    dataSource: [],
     labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
    inputChange: (e) => {
      _that.$nextTick(() => {
        const idCard = _that.$refs.formIndex.getFieldValue().idCard
        if (_that.isIdCardNo(idCard)) {
          selectStaffInfoByIdCard({idCard}).then(res => {
            if (res.data.code != 0) {
              _that.$message.warning(res.data.msg)
            } else if (res.data.code === 0) {
              res.data.data.expiryDate = [moment(res.data.data.expiryStart), moment(res.data.data.expiryEnd)]
              res.data.data.nationality = [res.data.data.nativePlace, res.data.data.nativeCity, res.data.data.nativeArea]
              res.data.data.birthday = moment(res.data.data.birthday)
              _that.$refs.formIndex.queryObj(res.data.data)
              // 紧急联系人
              if (res.data.data.staffLibExtRespVO) {
                _that.$refs.formIndexInfo.queryObj(res.data.data.staffLibExtRespVO)
              }
              let path = ["facePath", "backPath", "appPhotoPath"];
              _that.formData.forEach((item) => {
                path.forEach((key, index) => {
                  if (item.key == key && res.data.data[key]) {
                    item.defaultFileList = [
                      { url: res.data.data[key], uid: index, name: "image.png", key },
                    ];
                  }
                });
              });
            }
          })
        }
      })
    }
  },
  {
    label: "民族",
    placeholder: "请输入民族",
    decorator: [
      "nation",
      { rules: [{ required: true, message: "请输入民族" },{ required: false, message: "最长不超过8位汉字" ,pattern:/(^[\u4e00-\u9fa5]{1,8}$)/}] },
    ],
    type: "input",
    key: "nation",
    dataSource: [],
     labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
    maxLength: 9,
  },
  {
    label: "出生日期",
    placeholder: "请输入出生日期",
    decorator: [
      "birthday",
      { rules: [{ required: true, message: "请输入出生日期" }] },
    ],
    type: "datePicker",
    format: "yyyy-MM-DD",
    key: "birthday",
    dataSource: [],
     labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
    styles: {width: '100%'}
  },
  {
    label: "签发机关",
    placeholder: "请输入签发机关",
    decorator: [
      "signOrgan",
      { rules: [{ required: true, message: "请输入签发机关" }, { required: true, message: "最长不超过20位" ,pattern:/^[\u4e00-\u9fa5|\d]{1,20}$/}] },
    ],
    type: "input",
    key: "signOrgan",
    dataSource: [],
     labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
    maxLength: 21
  },
  {
    label: "有效期限",
    placeholder: "请选择有效期限",
    decorator: [
      "expiryDate",
      { rules: [{ required: true, message: "请选择有效期限" }] },
    ],
    type: "rangePicker",
    key: "expiryDate",
    dataSource: [],
     labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
    format: "YYYY-MM-DD HH:mm:ss",
  },
  {
    label: "手机号码",
    placeholder: "请输入手机号码",
    decorator: [
      "phoneNumber",
      { rules: [{ required: true, message: "请正确输入手机号码",pattern: new RegExp(/^1\d{10}$/),}] },
    ],
    type: "input",
    key: "phoneNumber",
    dataSource: [],
     labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
    maxLength: 11,
  },
  {
    label: "籍贯",
    placeholder: "请选择省市区",
    decorator: [
      "nationality",
      { rules: [{ required: true, message: "请选择省市区" }] },
    ],
    type: "cascader",
    key: "nationality",
    labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
  },
  {
    label: "住址",
    placeholder: "请输入住址",
    decorator: [
      "location",
      { rules: [{ required: true, message: "请输入住址" }] },
    ],
    type: "input",
    key: "location",
    dataSource: [],
    labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
    maxLength: 201,
  },
  {
    label: "婚姻状况",
    placeholder: "请输入婚姻状况",
    decorator: [
      "maritalStatus",
      {
        initialValue: "1",
        rules: [{ required: true, message: "请选择婚姻状况" }]
      },
    ],
    type: "select",
    selectlist: [
      {
        name: "未婚",
        value: "0",
      },
      {
        name: "已婚",
        value: "1",
      },
      {
        name: "离异",
        value: "2",
      },
      {
        name: "丧偶",
        value: "3",
      },
    ],
    key: "maritalStatus",
    dataSource: [],
     labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
  },
  {
    label: "文化程度",
    placeholder: "请选择文化程度",
    decorator: [
      "education",
      {
        initialValue: "初中及以下",
        rules: [{ required: true, message: "请选择文化程度" }]
      },
    ],
    selectlist: [{
      value: '初中及以下',
      name: '初中及以下'
    }, {
      value: '高中',
      name: '高中'
    }, {
      value: '中专',
      name: '中专'
    }, {
      value: '大专',
      name: '大专'
    }, {
      value: '本科',
      name: '本科'
    }, {
      value: '本科及以上',
      name: '本科及以上'
    }],
    type: "select",
    key: "education",
    dataSource: [],
     labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
  },
  {
    label: "政治面貌",
    placeholder: "请输入政治面貌",
    decorator: [
      "political",
      { initialValue: "2", rules: [{ required: true, message: "请输入政治面貌" }] },
    ],
    type: "select",
    selectlist: [],
    key: "political",
    dataSource: [],
     labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
  },
  {
    label: "社保号码",
    placeholder: "请输入社保号码",
    decorator: [
      "securityCode",
      { initialValue: "无", rules: [{ required: true, message: "请输入社保号码" }] },
    ],
    type: "input",
    key: "securityCode",
    dataSource: [],
     labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
    maxLength: 18,
  },

  {
    label: "户口分类",
    placeholder: "请输入户口分类",
    decorator: [
      "householdType",
      {
        initialValue: "2",
        rules: [{ required: true, message: "请输入户口分类" }],
      },
    ],
    type: "radio",
    class: "radio",
    key: "householdType",
    radiolist: [
      {
        name: "城镇户口",
        value: "1",
      },
      {
        name: "农村户口",
        value: "2",
      },
    ],
    labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
  },

  {
    label: "工人宿舍",
    decorator: [
      "builderStay",
      {
        initialValue: "1",
        rules: [{ required: false, message: "请选择工人宿舍" }],
      },
    ],
    type: "radio",
    class: "radio",
    key: "builderStay",
    radiolist: [
      {
        name: "场内住宿",
        value: "1",
      },
      {
        name: "场外住宿",
        value: "0",
      },
    ],
    labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
  },

  {
    label: "拟退场时间",
    placeholder: "请选择拟退场时间",
    decorator: [
      "planExitDate",
      { rules: [{ required: false, message: "请选择拟退场时间" }] },
    ],
    defaultPickerValue: moment().add(30, 'days'),
    type: "datePicker",
    format: "yyyy-MM-DD",
    key: "planExitDate",
    dataSource: [],
     labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
    styles: {width: '100%'},
    disabled: false,
    disabledDate(date) {
      // 1. 拟退场时间必须晚于入场时间，入场时间之前（含入场当日）不可选
      let entranceTime = new Date();
      if (_that.params.teamStaffRelation.enterDate) entranceTime = new Date(_that.params.teamStaffRelation.enterDate); // 转换成日期对象
      entranceTime.setDate(entranceTime.getDate() + 29);
      return date <= entranceTime;
    },
  },
  {
    label: "技能水平",
    placeholder: "请选择技能水平",
    decorator: [
      "techlevel",
      { rules: [{ required: false, message: "请选择技能水平" }] },
    ],
    showSearch: true,//暂时注释
    type: "select",
    selectlist: [{
      value: 1,
      name: '无(普通)'
    }, {
      value: 2,
      name: '初级工'
    }, {
      value: 3,
      name: '中级工'
    }, {
      value: 4,
      name: '高级工'
    }, {
      value: 5,
      name: '技师'
    }, {
      value: 6,
      name: '高级技术'
    }],
    key: "techlevel",
     labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
  },

  {
    label: "现住地址",
    placeholder: "",
    decorator: [
      "residence",
      { initialValue: "工地宿舍", rules: [{ required: false, message: "请输入现住地址" }] },
    ],
    type: "input",
    key: "residence",
    labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
  },
  {
    label: "是否购买工伤保险",
    placeholder: "请选择工伤保险",
    decorator: [
      "hasBuyinjuryInsurance",
      {
        initialValue: "false",
        rules: [{ required: false, message: "请选择工伤保险" }],
      },
    ],
    type: "radio",
    class: "radio",
    key: "hasBuyinjuryInsurance",
    radiolist: [
      {
        name: "是",
        value: 'true',
      },
      {
        name: "否",
        value: 'false',
      },
    ],
    labelCol: 10,
    wrapperCol: 12,
    col: 12,
    display: true,
  },

  {
    label: "是否加入公会",
    placeholder: "请选择公会",
    decorator: [
      "isLabourUnion",
      {
        initialValue: "false",
        rules: [{ required: false, message: "请选择公会" }],
      },
    ],
    type: "radio",
    class: "radio",
    key: "isLabourUnion",
    radiolist: [
      {
        name: "是",
        value: "true",
      },
      {
        name: "否",
        value: "false",
      },
    ],
    labelCol: 10,
    wrapperCol: 12,
    col: 12,
    display: true,
  }, {
    label: "是否退役军人",
    decorator: [
      "soldier",
      {
        initialValue: "否",
        rules: [{ required: false, message: "请选择退役军人" }],
      },
    ],
    type: "radio",
    class: "radio",
    key: "soldier",
    radiolist: [
      {
        name: "是",
        value: "是",
      },
      {
        name: "否",
        value: "否",
      },
    ],
    labelCol: 10,
    wrapperCol: 12,
    col: 12,
    display: true,
  }, {
    label: "安全教育",
    decorator: [
      "securityEducatedFlag",
      {
        initialValue: ['1'],
        rules: [{ required: false, message: "请选择安全教育" }],
      },
    ],
    type: "checkbox",
    key: "securityEducatedFlag",
    checkboxlist: [
      {
        name: "已接受",
        value: "1",
      },
    ],
    labelCol: 8,
    wrapperCol: 12,
    col: 12,
    display: true,
    styles: {width: '100%'}
  },
  // {
  //   label: "应急号码",
  //   placeholder: "请输入应急号码",
  //   decorator: [
  //     "emergencyTel",
  //     { rules: [{ required: false, message: "请输入应急号码" }] },
  //   ],
  //   type: "input",
  //   key: "emergencyTel",
  //   dataSource: [],
  //    labelCol: 8,
  //   wrapperCol: 14,
  //   col: 12,
  //   display: true,
  // },

  {
    label: "教育附件",
    decorator: [
      "securityEducatedAttachment",
      { rules: [{ required: false, message: "上传教育附件" }] },
    ],
    type: "tsUpload",
    listType: 'picture-card',
    key: "securityEducatedAttachment",
    defaultFileList: [],
    labelCol: 8,
    wrapperCol: 12,
    col: 12,
    display: true,
    class: "Upload",
    showUploadList: true
  },
  {
    label: "社保证明",
    placeholder: "上传社保证明",
    decorator: [
      "socialSecurity",
      { rules: [{ required: false, message: "上传社保证明" }] },
    ],
    type: "tsUpload",
    key: "socialSecurity",
    listType: 'picture-card',
    defaultFileList: [],
    labelCol: 8,
    wrapperCol: 14,
    col: 12,
    display: true,
    class: "Upload",
    showUploadList: true
  },
];

const formDataMid = [{
  label: "所属单位",
  placeholder: "请选择所属单位",
  decorator: [
    "unitId",
    { rules: [{ required: true, message: "请选择所属单位" }] },
  ],
  type: "select",
  key: "unitId",
  selectlist: [],
  dataSource: [],
  labelCol: 8,
  wrapperCol: 14,
  col: 12,
  display: true,
  showSearch: true
}, {
  label: "类别",
  placeholder: "请输入类别",
  decorator: [
    "category",
    { initialValue: "3", rules: [{ required: true, message: "请输入类别" }] },
  ],
  selectlist: [
    {
      name: "项目负责人",
      value: "1",
    },
    {
      name: "现场管理人员",
      value: "2",
    },
    {
      name: "作业工人",
      value: "3",
    },
  ],
  type: "select",
  disabled: true,
  key: "category",
  dataSource: [],
  labelCol: 8,
  wrapperCol: 14,
  col: 12,
  display: true,
}, {
  label: "班组",
  placeholder: "请选择班组",
  decorator: [
    "teamId",
    { rules: [{ required: true, message: "请选择班组" }] },
  ],
  type: "select",
  key: "teamId",
  selectlist: [],
  dataSource: [],
  labelCol: 8,
  wrapperCol: 14,
  col: 12,
  display: true,
}, {
  label: "",
  decorator: [
    "isCaptain",
    { rules: [{ required: false, message: "" }] },
  ],
  type: "checkbox",
  key: "isCaptain",
  checkboxlist: [
    {
      name: "设为班组长",
      value: "1",
    },
  ],
  labelCol: 6,
  wrapperCol: 10,
  col: 12,
  display: true,
  disabled: true,
  styles: {width: '100%'}
}, {
  label: "岗位/工种",
  placeholder: "请选择岗位/工种",
  decorator: [
    "title",
    { rules: [{ required: true, message: "请选择岗位/工种" }] },
  ],
  type: "select",
  selectlist: [],
  key: "title",
  dataSource: [],
  labelCol: 8,
  wrapperCol: 14,
  col: 12,
  display: true,
  showSearch: true
}, {
  label: "用工形式",
  decorator: [
    "contractType",
    {
      initialValue: "4",
      rules: [{ required: true, message: "请选择用工形式" }],
    },
  ],
  type: "radio",
  key: "contractType",
  radiolist: [
    {
      name: "自聘",
      value: "0",
    },
    {
      name: "外聘",
      value: "1",
    }, {
      name: "包工",
      value: "4",
    }, {
      name: "点工",
      value: "2",
    }, {
      name: "合同工",
      value: "5",
    }, {
      name: "临时工",
      value: "3",
    }
  ],
  labelCol: 4,
  wrapperCol: 20,
  col: 24,
  display: true,
}, {
  label: "项目工号",
  placeholder: "请输入工号",
  decorator: [
    "projWorkNum",
    { rules: [{ required: false, message: "请输入工号" }] },
  ],
  type: "input",
  key: "projWorkNum",
  dataSource: [],
  labelCol: 8,
  wrapperCol: 14,
  col: 12,
  display: true,
  disabled: true
}, {
  label: "IC卡号",
  placeholder: "请输入IC卡号",
  decorator: [
    "icCardNumber",
    { rules: [{ required: false, message: "请输入IC卡号" }] },
  ],
  type: "input",
  key: "icCardNumber",
  dataSource: [],
  labelCol: 8,
  wrapperCol: 14,
  col: 12,
  display: true,
  maxLength:30
}, {
  label: "合同编号",
  placeholder: "请输入合同编号",
  decorator: [
    "contactNumber",
    { rules: [{ required: true, message: "请输入合同编号" }] },
  ],
  type: "input",
  key: "contactNumber",
  dataSource: [],
  labelCol: 8,
  wrapperCol: 14,
  col: 12,
  display: true,
}, {
  label: "合同附件",
  decorator: [
    "contactAttachment",
    { rules: [{ required: false, message: "" }] },
  ],
  type: "tsUpload",
  defaultFileList: [],
  listType: 'picture-card',
  key: "contactAttachment",
  labelCol: 8,
  wrapperCol: 14,
  col: 12,
  display: true,
  class: "Upload",
  showUploadList: true

}, {
  label: "结算方式",
  decorator: [
    "settlement",
    {
      initialValue: "1",
      rules: [{ required: false, message: "请选择结算方式" }],
    },
  ],
  type: "radio",
  key: "settlement",
  radiolist: [
    {
      name: "计时",
      value: "0",
    },
    {
      name: "计天",
      value: "1",
    }, {
      name: "计量",
      value: "2",
    }, {
      name: "包月",
      value: "3",
    }, {
      name: "定额",
      value: "4",
    }, {
      name: "其他",
      value: "5",
    }
  ],
  labelCol: 3,
  wrapperCol: 20,
  col: 24,
  display: true,
}, {
  label: "计量单位",
  decorator: [
    "measureUnit",
    {
      initialValue: "1",
      rules: [{ required: false, message: "请选择计量单位" }],
    },
  ],
  type: "radio",
  key: "measureUnit",
  radiolist: [
    {
      name: "米",
      value: "0",
    },
    {
      name: "平方米",
      value: "1",
    }, {
      name: "立方米",
      value: "2",
    }
  ],
  labelCol: 3,
  wrapperCol: 20,
  col: 24,
  display: true,
}]
const formDataBottom = [{
  label: "证书类型",
  placeholder: "请选择证书类型",
  decorator: ["certType",
    { rules: [{ required: false, message: "请选择证书类型" }] },
  ],
  type: "select",
  selectlist: [],
  key: "certType",
  dataSource: [],
  labelCol: 6,
  wrapperCol: 14,
  col: 12,
  display: true,
}, {
  label: "证书名称",
  placeholder: "请输入证书名称",
  decorator: ["certName",
    { rules: [{ required: false, message: "请输入证书名称" }] },
  ],
  type: "input",
  key: "certName",
  dataSource: [],
  labelCol: 6,
  wrapperCol: 14,
  col: 12,
  display: true,
}, {
  label: "证书编号",
  placeholder: "请输入证书编号",
  decorator: ["certNum",
    { rules: [{ required: false, message: "请输入证书编号" }] },
  ],
  type: "input",
  key: "certNum",
  dataSource: [],
  labelCol: 6,
  wrapperCol: 14,
  col: 12,
  display: true,
  maxLength:30
}, {
  label: "初领时间",
  placeholder: "请选择初领时间",
  decorator: ["effectiveStart",
    { rules: [{ required: false, message: "请选择初领时间" }] },
  ],
  type: "datePicker",
  key: "effectiveStart",
  dataSource: [],
  labelCol: 6,
  wrapperCol: 14,
  col: 12,
  display: true,
  styles: {width: '100%'},
  format: "yyyy-MM-DD",
}, {
    label: "到期时间",
    placeholder: "请选择到期时间",
    decorator: [
      "effectiveEnd",
      { rules: [{ required: false, message: "请选择到期时间" }] },
    ],
    type: "datePicker",
    key: "effectiveEnd",
    dataSource: [],
    labelCol: 6,
    wrapperCol: 14,
    col: 12,
    display: true,
    format: "yyyy-MM-DD",
    styles: {width: '100%'}
  }, {
    label: "证书",
    placeholder: "上传证书",
    decorator: [
      "attachment",
      { rules: [{ required: false, message: "上传证书" }] },
    ],
    type: "tsUpload",
    key: "attachment",
    listType: 'picture-card',
    defaultFileList: [],
    labelCol: 6,
    wrapperCol: 14,
    col: 12,
    display: true,
    class: "Upload",
    showUploadList: true
  }]

import formIndex from "@/pages/components/form/index";
import address from "@/pages/components/form/address.json";
import { saveStaffInfo, getIdCardInfo, getJobByWorkerType, getCertificateTypeList, staffInfo, getBank, getPoliticalOutlook,
  selectStaffInfoByIdCard,getBankCrad,getJobFindWorkerType,enablePlanExit,checkPlanExitDate,getAllTeams, getSetParamsByPidOid} from "@/services/participationUnit"
import { changeDate } from '@/utils/util.js'
import { getOfficeListByProjectId } from '@/services/payroll'
import { mapGetters } from 'vuex'
import moment from "moment";
import { replace, debounce } from "lodash";
import { storage } from '@/utils/storage';

export default {
  components: {
    formIndex,
  },
  data() {
    const formDataTop = [
  {
    type: "place",
    labelCol: 6,
    wrapperCol: 16,
    col: 3,
  },
  {
  label: "",
  title: "上传工资卡",
  placeholder: "请上传工资卡",
  decorator: [
    "salaryCardAttachment",
    { rules: [{ required: false, message: "请上传工资卡" }] },
  ],
  type: "tsCropperUpload",
  key: "salaryCardAttachment",
  labelCol: 6,
  wrapperCol: 16,
  col: 7,
  defaultImg: require('@/assets/img/wageCard.png'),
  defaultFileList: [],
  display: true,
  cropperTitle: '编辑工资卡',
}, {
  label: "开户银行",
  placeholder: "请输入开户银行",
  decorator: [
    "salaryBranch",
    { rules: [{ required: true, message: "请输入开户银行" }] },
  ],
  type: "inputGroup",
  key: "salaryBranch",
  dataSource: [],
  labelCol: 8,
  wrapperCol: 14,
  col: 12,
  display: true,
}, {
  label: "开户账号",
  placeholder: "请输入开户账号",
  decorator: [
    "salaryAccount",
    { rules: [{ required: true, message: "请正确填写银行卡号",pattern:/^([1-9]{1})(\d{15}|\d{18})$/,}] },
  ],
  type: "input",
  key: "salaryAccount",
  dataSource: [],
  labelCol: 8,
  wrapperCol: 14,
  col: 12,
  display: true,
  maxLength:30
}
,{
  label: "开户所属地",
  placeholder: "请输入开户所属地",
  decorator: [
    "branchAddress",
    { rules: [{ required: false, message: "请输入开户所属地" }] },
  ],
  type: "cascader",
  key: "branchAddress",
  labelCol: 8,
  wrapperCol: 14,
  col: 12,
  display: true,
},
{
  label: "银行名称",
  placeholder: "请输入银行名称",
  decorator: [
    "salaryName",
    { rules: [{ required: false, message: "请输入银行名称" }] },
  ],
  type: "input",
  key: "salaryName",
  labelCol: 0,
  wrapperCol: 0,
  col: 0,
  display: false,
},
]
    return {
      formData: formData,
      options: address,
      formDataTop: formDataTop,
      formDataMid: formDataMid,
      formDataBottom: formDataBottom,
      formDataInfo:formDataInfo,
      formDataArr: [],
      allSubmitobj: {},
      projWorkNum: '',
      teamId: '',
      workNum: '',
      operateType: 1,
      params: {
        operateType: this.operateType,
        staffLib: {},
        teamStaffRelation: {},
        staffCertication: {}
      },
      selectBranch: [
        {
          name: '工商',
          code: '0102'
        },
        {
          name: '农业',
          code: '0103'
        },
        {
          name: '建设',
          code: '0105'
        },
        {
          name: '广西农村信用社联合社',
          code: '1443'
        },
        {
          name: '浦东发展银行',
          code: '0310'
        },
      ], // 上传银行卡-匹配不到的name值自定义code
      teamData: [],
      isEnablePlanExit: false,
      dateFormat: 'YYYY-MM-DD',
    };
  },
  inject: ["fatherMethod"],
  created() {
    _that = this
    this.getBankList({key: 'salaryBranch', value: ''})
  },
  activated() {
    const formDataArr = [this.formData, this.formDataTop, this.formDataMid, this.formDataBottom]
    formDataArr.forEach(i => {
      i.forEach(item => {
        if (item.type === 'tsUpload' || item.type === 'tsCropperUpload') {
          item.defaultFileList = []
        }
      })
    })
    this.getOfficeListByProjectId()
    this.getWorkerType()
    this.getCertificateTypeList()
    this.getPoliticalOutlook()
    this.projWorkNum = this.$route.query.projWorkNum
    this.teamId = this.$route.query.teamId
    this.workNum = this.$route.query.workNum
    if (this.$route.query.operateType) {
      this.operateType = this.$route.query.operateType
    }
    if (this.projWorkNum && this.teamId && this.workNum) {
      this.operateType == 3 ? this.operateType : this.operateType = 2
      this.getStaffInfo()
    } else {
      this.getSetParamsByPidOid()
    }
  },
  computed: {
    ...mapGetters('account', ['user']),
  },
  methods: {
    // 获取工人进场设置
    getSetParamsByPidOid(officeId) {
      getSetParamsByPidOid({officeId: officeId}).then(res => {
        const enterSet = JSON.parse(res.data.data.enterSet)
        if (enterSet.emergency.isOpen) {
          if (enterSet.emergency.fields && enterSet.emergency.fields.length > 0) {
            this.formDataInfo.forEach(el => {
              if (enterSet.emergency.fields.includes(el.key)) {
                el.decorator.forEach((items) => {
                  if (items instanceof Object) {
                    items.rules[0].required = true;
                  }
                })
              } else {
                el.decorator.forEach((items) => {
                  if (items instanceof Object) {
                    items.rules[0].required = false;
                  }
                })
              }
          })
          }
        } else {
          this.formDataInfo.forEach(el => {
            el.decorator.forEach((items) => {
              if (items instanceof Object) {
                items.rules[0].required = false;
              }
            })
          })
        }
      })
    },
    // 关闭当前页面
    close() {
      let path = this.$route.path;
      this.fatherMethod(`${path}`);
      this.$router.push({name: "在场工人列表"});
    },
    // 银行卡号校验函数
    // checkCardNum(rule, value, callback) {
    //   var reg = /^([1-9]{1})(\d{14}|\d{18})$/;
    //   if (reg.test(value)) {
    //     callback();
    //   } else {
    //     callback(new Error('银行卡号格式不正确'));
    //   }
    // },
    isIdCardNo(num) {
      num = num.toUpperCase();
      //身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X。
      if ( !(/(^\d{15}$)|(^\d{17}([0-9]|X)$)/.test(num)) ) {
        console.log('身份证错误')
        return false;
      } else {
        console.log('身份证正确')
        return true;
      }
    },
    //修改时获取回显信息接口
    getStaffInfo() {
      staffInfo({
        projWorkNum: this.projWorkNum,
        teamId: this.teamId,
        workNum: this.workNum
      }).then(res => {
        if (res.data.code === 0) {
          let { staffLib, teamStaffRelation, staffCertication,staffLibExtDTO} = res.data.data
          this.params.staffLib = staffLib
          this.params.teamStaffRelation = teamStaffRelation
          this.params.staffCertication = staffCertication
          this.params.staffLibExtDTO = staffLibExtDTO
          if (staffLibExtDTO) {
            this.$refs.formIndexInfo.queryObj(staffLibExtDTO)
          } else {
            this.$refs.formIndexInfo.resetFields()
          }
          if (staffLib) {
            // 处理时间戳 1.出生日期 2.有效期限
            staffLib.birthday = moment(staffLib.birthday).format('YYYY-MM-DD')
            const expiryStart = moment(staffLib.expiryStart).format('YYYY-MM-DD')
            const expiryEnd = moment(staffLib.expiryEnd).format('YYYY-MM-DD')
            staffLib.expiryDate = [expiryStart,expiryEnd]
            // staffLib.expiryDate = [staffLib.expiryStart,staffLib.expiryEnd]
            staffLib.nationality = [staffLib.nativePlace, staffLib.nativeCity, staffLib.nativeArea]
            if (teamStaffRelation?.techlevel) {
              // 单独处理技能水平techlevel
              staffLib.techlevel = Number(teamStaffRelation.techlevel)
            }
            if (teamStaffRelation?.securityEducatedFlag) {
              // 单独处理-安全教育字段-注释
              staffLib.securityEducatedFlag = [teamStaffRelation.securityEducatedFlag]
            }
            // 为防止数据覆盖-回显拟退场时间
            if (this.params.teamStaffRelation.planExitDate) staffLib.planExitDate = this.params.teamStaffRelation.planExitDate
            this.$refs.formIndex.queryObj(staffLib)
            this.$refs.formIndex2.queryObj(staffLib)
            // 处理回显图片path
            let path = ["facePath", "backPath", "appPhotoPath", "socialSecurity"];
            this.formData.forEach((item) => {
              path.forEach((key, index) => {
                if (item.key == key && staffLib[key]) {
                  item.defaultFileList = [
                    { url: staffLib[key], uid: index, name: "image.png", key: item.key },
                  ];
                }
              });
            });
            // 处理回显教育附件
            this.formData.forEach((item) => {
              if (item.key == 'securityEducatedAttachment' && teamStaffRelation['securityEducatedAttachment']) {
                item.defaultFileList = [
                  { url: teamStaffRelation['securityEducatedAttachment'], uid: 0, name: "image.pdf", key: item.key },
                ];
              }
            });
          }
          if (teamStaffRelation) {
            // 校验是否必填拟退场
            this.checkExitDate(teamStaffRelation.unitId)
            // 校验是否必填紧急联系人
            this.getSetParamsByPidOid(teamStaffRelation.unitId)
            // 电子合同状态-是否可编辑拟退场
            if (teamStaffRelation.contactStatus != null) {
              this.setExitContactStatus(teamStaffRelation.contactStatus)
            }

            if (teamStaffRelation.branchAddress) {
              teamStaffRelation.branchAddress = teamStaffRelation.branchAddress.split(',')
            }
            this.doQueryTeams({ officeId: teamStaffRelation.unitId })
            teamStaffRelation.isCaptain = [teamStaffRelation.isCaptain]
            // teamStaffRelation.securityEducatedFlag = [teamStaffRelation.securityEducatedFlag]
            this.$refs.formIndex1.queryObj(teamStaffRelation)
            if (teamStaffRelation?.teamId) {
              setTimeout(() => {  // 回显班组长
                this.setTeamLeader(teamStaffRelation.teamId,'teamId')
              },500)
            }
            // 处理回显图片path
            this.formDataTop.forEach((item) => {
              if (item.key == 'salaryCardAttachment' && teamStaffRelation['salaryCardAttachment']) {
                item.defaultFileList = [
                  { url: teamStaffRelation['salaryCardAttachment'], uid: 0, name: "image.png", key: item.key },
                ];
              }
            });
            this.$refs.formIndex2.queryObj(teamStaffRelation)
            if (teamStaffRelation['contactAttachment']) {
              // 处理合同附件，回显失败问题
              this.$refs.formIndex2.setFieldsValue('contactAttachment', {'contactAttachment': teamStaffRelation['contactAttachment']}, 'contactAttachment')
              // 处理回显图片path
              this.formDataMid.forEach((item) => {
                if (item.key == 'contactAttachment' && teamStaffRelation['contactAttachment']) {
                  item.defaultFileList = [
                    { url: teamStaffRelation['contactAttachment'], uid: 0, name: "image.pdf", key: item.key },
                  ];
                }
              });
            }
          }
          if (staffCertication) {
            this.$refs.formIndex3.queryObj(staffCertication)
            // 处理回显图片path
            this.formDataBottom.forEach((item) => {
              if (item.key == 'attachment' && staffCertication['attachment']) {
                item.defaultFileList = [
                  { url: staffCertication['attachment'], uid: 0, name: "image.png", key: item.key },
                ];
              }
            });
          }
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 输入选择框触发
    handleChange(e) {
      // // 获取当前匹配key，赋值相对于数组
      this.getBankList({key: e.item.key, value: e.value})
    },
    getBankList(e) {
      getBank({name: e.value}).then(res => {
        if (res.data.code === 0) {
          this.formDataTop.forEach((item) => {
            if (e.key === item.key) {
              let arr = []
              res.data.data.forEach(el => {
                arr.push({
                  title: el.name,
                  id: el.code
                })
              })
              item.dataSource = arr
            }
          });
        }
      })
    },
    getCertificateTypeList() {
      getCertificateTypeList({isPage: "false"}).then(res => {
        if(res.data.code === 0){
          this.formDataFn('certType', res.data.data.list, 'typeName', this.formDataBottom)
        }
      })
    },
    getPoliticalOutlook() {
      getPoliticalOutlook({type:'political'}).then(res => {
        if(res.data.code === 0){
          this.formDataFn('political', res.data.data, 'label', this.formData)
        }
      })
    },
    //上传文件回调
    resourceUpload(obj) {
      const formDataArr = [this.formData, this.formDataTop, this.formDataMid, this.formDataBottom]
      formDataArr.forEach(i => {
        i.forEach(item => {
          if (item.key === obj.key) {
            item.defaultFileList = [obj]
            if (item.key === 'facePath') {
              this.getIdCardInfo(obj.formData, 'front')
            } else if (item.key === 'backPath') {
              this.getIdCardInfo(obj.formData, 'back')
            } else if (item.key === 'salaryCardAttachment') {
              // 上传工资卡-并回显银行卡号、银行名称
              this.getBankInfo(obj.formData)
            }
          }
        })
      })
    },
    //删除上传附件
    upRemove(file) {
      const formDataArr = [this.formData, this.formDataTop, this.formDataMid, this.formDataBottom]
      formDataArr.forEach(i => {
        i.forEach(item => {
          if (item.key === file.key) {
            item.defaultFileList = []
          }
        })
      })
    },
    //获取身份证信息
    getIdCardInfo(formData, type) {
      getIdCardInfo(formData, type).then(res => {
        if (res.data.code === 0) {
          const words_result = res.data.data.idCardJson.words_result
          if (type === 'front') {
            // 裁剪的身份证正面
            this.$refs.formIndex.setFieldsValue('facePath', {'facePath': res.data.data.idCardJson.filePath}, 'facePath')
            this.$refs.formIndex.setFieldsValue(words_result.name.words, {'name': words_result.name.words}, 'name')
            this.$refs.formIndex.setFieldsValue(words_result.sex.words, {'gender': words_result.sex.words}, 'gender')
            this.$refs.formIndex.setFieldsValue(words_result.idCard.words, {'idCard': words_result.idCard.words}, 'idCard')
            this.$refs.formIndex.setFieldsValue(words_result.nation.words, {'nation': words_result.nation.words}, 'nation')
            this.$refs.formIndex.setFieldsValue(changeDate(words_result.birthDay.words), {'birthday': changeDate(words_result.birthDay.words)}, 'birthday')
            this.$refs.formIndex.setFieldsValue(words_result.address.words, {'location': words_result.address.words}, 'location')
          } else if (type === 'back') {
            // 裁剪的身份证反面
            this.$refs.formIndex.setFieldsValue('backPath', {'backPath': res.data.data.idCardJson.filePath}, 'backPath')
            this.$refs.formIndex.setFieldsValue(words_result.signAddr.words, {'signOrgan': words_result.signAddr.words}, 'signOrgan')
            let expiryDate = []
            expiryDate.push(changeDate(words_result.signDate.words))
            expiryDate.push(changeDate(words_result.loseDate.words == '长期' ? '20991231' : words_result.loseDate.words))
            this.$refs.formIndex.setFieldsValue(expiryDate, {'expiryDate': expiryDate}, 'expiryDate')
          }
          // 处理回显图片path
          let path = [{key: "facePath",type: 'front'}, {key:"backPath",type:'back'}];
            this.formData.forEach((item) => {
              path.forEach((items, index) => {
                if (item.key == items.key && items.type == type) {
                  item.defaultFileList = [
                    { url: res.data.data.idCardJson.filePath, uid: index, name: "image.png", key: item.key },
                  ];
                }
              });
            });
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 获取银行卡信息
    getBankInfo(formData) {
      getBankCrad(formData).then(res => {
        if (res.data.code === 0) {
          const bank_result = res.data.data.result
          getBank({name: bank_result.bank_name}).then(res => {
            if (res.data.code === 0) {
              // 处理银行卡name值不匹配code的问题
              if (res.data.data.length == 0) {
                this.selectBranch.map(el => {
                  if (bank_result.bank_name.indexOf(el.name )!= -1) {
                    return (
                    this.$refs.formIndex1.setFieldsValue(bank_result.bank_name, {'salaryName': bank_result.bank_name}, 'salaryName'),
                    this.$refs.formIndex1.setFieldsValue(bank_result.bank_name, {'salaryBranch': el.code}, 'salaryBranch')
                )

                }
                })
              } else {
                res.data.data.forEach(el => {
                  if (el.name == bank_result.bank_name) {
                    this.$refs.formIndex1.setFieldsValue(bank_result.bank_name, {'salaryName': bank_result.bank_name}, 'salaryName'),
                    this.$refs.formIndex1.setFieldsValue(bank_result.bank_name, {'salaryBranch': el.code}, 'salaryBranch')
                  }
                })
            }
            }
          })
          // lodash-replace 去除银行卡卡号，多余的空格
          this.$refs.formIndex1.setFieldsValue(replace(bank_result.bank_card_number,/\s+/g, ''), {'salaryAccount': replace(bank_result.bank_card_number,/\s+/g, '')}, 'salaryAccount')
        }else {
          this.$message.error(res.data.msg)
        }
      }).catch((error) => {
           this.$message.error(error)
      })
    },
    //获取工种
    getWorkerType() {
      // getJobByWorkerType([]).then(res => {
      //   if(res.data.code === 0){
      //     this.formDataFn('title', res.data.data, 'carft', this.formDataMid)
      //   }
      // })
      // 根据条件获取工人工种
      getJobFindWorkerType({category: 3}).then(res => {
        if(res.data.code === 0){
          this.formDataFn('title', res.data.data, 'carft', this.formDataMid)
        }
      })
    },
    //查询所有单位
    getOfficeListByProjectId(){
      getOfficeListByProjectId().then((res) => {
        if(res.data.code === 0){
          this.formDataFn('unitId', res.data.data, 'name', this.formDataMid)
        }
      })
    },
    // 对下拉框做数据处理
    formDataFn(key, data, itemName, form) {
      let arr = []
      data.forEach(item => {
          if (key == 'political') {
            arr.push({ 'name': item[itemName], 'value': item.value })
          } else {
            arr.push({ 'name': item[itemName], 'value': item.id })
          }
      })
      form.forEach(item => {
          if (item.key == key) {
              item.selectlist = arr
          }
      })
      // 班组数据-有用
      if (key == 'teamId') {
        this.teamData = data
      }
    },
    handlSelect(obj) {
      const { val, keys } = obj
      // console.log(val, keys)
      if (keys === 'unitId') {
        this.doQueryTeams({ officeId: val })
        //选择单位的同时，重置班组和班组长权限
        this.$refs.formIndex2.setFieldsValue('', {'teamId': ''}, '')
        this.$refs.formIndex2.setFieldsValue('', {'isCaptain': ['0']}, '')
        // 校验是否必填拟退场
        this.checkExitDate(val)
        // 校验是否必填紧急联系人
        this.getSetParamsByPidOid(val)
      }
      if (keys === 'teamId') {
        //选择班组的同时，根据姓名判断是否是班组长
        this.$refs.formIndex2.setFieldsValue('', {'isCaptain': ['0']}, [])
        this.setTeamLeader(val,keys)
      }
      if (keys === 'builderStay') {
        const name = ''
        this.formData.forEach(item => {
          if (item.key === 'builderStay') {
            item.radiolist.forEach(i => {
              if (val.target.value === '1') {
                this.$refs.formIndex.setFieldsValue(i.name, {'residence': i.name}, 'residence')
              } else {
                this.$refs.formIndex.setFieldsValue('', {'residence': ''}, 'residence')
              }
            })
          }
        })
      }
      if (keys === 'salaryBranch') {
        this.formDataTop.forEach(item => {
          if (item.key === 'salaryBranch') {
            item.dataSource.forEach(i => {
              if (i.id == val) {
                this.$refs.formIndex1.setFieldsValue(i.title, {'salaryName': i.title}, 'salaryName')
              }
            })
          }
        })
      }
    },
    //查询所有班组
    doQueryTeams(params){
      getAllTeams(params).then(res => {
        if(res.data.code === 0){
          this.formDataFn('teamId', res.data.data, 'teamName', this.formDataMid)
        }
      })
    },
    setTeamLeader(val, keys) {
        let name = this.$refs["formIndex"].getFieldValue().name;
        let isDisabled = true
        this.formDataMid.map((item, index) => {
            if (item.key == keys) {
                this.teamData.map(i => {
                  // 姓名对比班组长名称
                  if (i.id == val && i.teamLeader == name) {
                      this.$refs.formIndex2.setFieldsValue('isCaptain', {'isCaptain': ['1']}, 'isCaptain')
                      isDisabled = false
                  }
                })
            }
            if (item.key == 'isCaptain') {
              item.disabled = isDisabled
            }
        })
        return isDisabled
    },
    handleSubmit:debounce(function(e) {
      this.formDataArr = []
      let arr = ['formIndex', 'formIndex1', 'formIndex2', 'formIndexInfo', 'formIndex3']
      arr.forEach(item => {
        this.formDataArr.push(this.$refs[item].combinationSubmit())
      })
      this.params.operateType = this.operateType
      //处理数组第一项
      this.formDataArr[0].then(res => {
        this.params.teamStaffRelation.techlevel = res.techlevel //单独处理技能水平
        delete res.techlevel
        let redirect = ['securityEducatedAttachment','socialSecurity']
        redirect.forEach(items => {
          for (let item in res) {
            // 处理删除图片后，字段格式错误
            if (items == item && res[item]?.file?.status == 'removed') {
              res[item] = ''
            }
          }
        })
        this.params.teamStaffRelation.securityEducatedAttachment = res.securityEducatedAttachment //单独处理安全教育凭证
        delete res.securityEducatedAttachment
        const targetDate = moment('2099-12-31', 'YYYY-MM-DD') // 比较是否是长期身份证
        const yourDate = moment(res.expiryDate[1]); // 要比较的日期
        this.params.staffLib.expiryStart = moment(res.expiryDate[0]).valueOf()
        this.params.staffLib.expiryEnd = this.params.staffLib.expiryEnd ? moment(res.expiryDate[1]).valueOf() : yourDate.isSame(targetDate,'day') ? null : moment(res.expiryDate[1]).valueOf()
        // 单独处理安全教育字段
        if (res.securityEducatedFlag && res.securityEducatedFlag.length > 0) {
          this.params.teamStaffRelation.securityEducatedFlag = res.securityEducatedFlag[0]
          delete res.securityEducatedFlag
        }
        if (res.isOverAqjy && res.isOverAqjy.length > 0) {
          this.params.staffLib.isOverAqjy = res.isOverAqjy[0]
          delete res.isOverAqjy
        }
        this.params.staffLib.nativePlace = res.nationality[0]
        this.params.staffLib.nativeCity = res.nationality[1]
        this.params.staffLib.nativeArea = res.nationality[2]
        res.nationality = res.nationality.join('')
        for (let item in res) {
          if (item === 'birthday') {
            res[item] = moment(res[item]).valueOf()
          }
          this.params.staffLib[item] = res[item]
        }
        delete res.expiryDate
        // params.staffLib = Object.assign({}, ...res)
        this.formDataArr.shift()//去除数组第一项
      })
      this.formDataArr[1].then(res=> {
        // 修复报错警告问题
       this.params.staffLib.branchAddress = res.branchAddress.join(',')
       this.params.teamStaffRelation.branchAddress = res.branchAddress.join(',')
      })

      //处理数组最后一项
      this.formDataArr[this.formDataArr.length - 1].then(res => {
        if (res.effectiveStart) res.effectiveStart = moment(res.effectiveStart).format('YYYY-MM-DD HH:mm:ss')
        if (res.effectiveEnd) res.effectiveEnd = moment(res.effectiveEnd).format('YYYY-MM-DD HH:mm:ss')
        for (let item in res) {
          this.params.staffCertication[item] = res[item]
        }
        // params.staffLib = Object.assign({}, ...res)
        this.formDataArr.pop()//去除数组第一项
      })
      Promise.all(this.formDataArr).then((data) => {
        // console.log(data, 'data')
        data.forEach(obj => {
          for (let e in obj) {
            //设为班组长选择出来是数组，要处理一下
            if (e === 'isCaptain' || e === 'securityEducatedFlag') {
              if (obj[e] && obj[e].length > 0) {
                obj[e] = obj[e][0]
              } else {
                obj[e] = '0'
              }
            }

            if (e !== 'branchAddress') {
              this.params.teamStaffRelation[e] = obj[e]
            }
            if (obj['settlement']) this.params.staffLib['settlement'] = obj['settlement']
            if (obj['measureUnit']) this.params.staffLib['measureUnit'] = obj['measureUnit']
            if (obj['contractType']) this.params.staffLib['contractType'] = obj['contractType']
          }
        })
        // 校验班组长
        if (this.params.teamStaffRelation.isCaptain == '1' && this.params.teamStaffRelation?.teamId) {
           const isTeamLeader = this.setTeamLeader(this.params.teamStaffRelation.teamId,'teamId')
          //  isTeamLeader ? this.params.teamStaffRelation.isCaptain = '0' : this.params.teamStaffRelation.isCaptain = '1'
          if (isTeamLeader) return this.$message.error('您当前姓名与班组长名称不匹配，无法修改')
        }
        if (this.params.teamStaffRelation.unitId) {
          // 校验是否必填拟退场
          const isRequired = this.checkExitDate(this.params.teamStaffRelation.unitId)
          if (isRequired && !this.params.teamStaffRelation.planExitDate) return this.$message.error('请填写拟退场时间')
        }
        // 组装紧急联系人字段
        this.params.staffLibExtDTO = {
          idCard: this.params.teamStaffRelation.idCard,
          emergencyUserName: this.params.teamStaffRelation.emergencyUserName || undefined,
          emergencyUserPhone: this.params.teamStaffRelation.emergencyUserPhone || undefined,
          emergencyRelation: this.params.teamStaffRelation.emergencyRelation || undefined,
          emergencyAddress: this.params.teamStaffRelation.emergencyAddress || undefined,
        }
        saveStaffInfo(this.params).then(obj => {
          if (obj.data.code === 0) {
            this.operateType == 2 ? this.$message.success('修改成功！') :this.$message.success('登记成功！')
            if (this.operateType == 1) {
              //新增之后重新页面
              let arr = ['formIndex', 'formIndex1', 'formIndex2','formIndexInfo', 'formIndex3']
              arr.forEach(item => {
                this.formDataArr.push(this.$refs[item].resetFields())
              })
              const formDataArr = [this.formData, this.formDataTop, this.formDataMid, this.formDataBottom]
              formDataArr.forEach(i => {
                i.forEach(item => {
                  if (item.type === 'tsUpload'|| item.type === 'tsCropperUpload') {
                    item.defaultFileList = []
                  }
                })
              })
              this.$refreshPage(this.$route.path); // 刷新当前页面
            } else {
              // 重新入场-关闭当前页面-进入在场工人列表
              this.close()
              storage.set('presenceList-submit', true)
            }
          } else {
            this.$message.error(obj.data.msg)
          }
        })
        // params.teamStaffRelation = Object.assign({}, ...data)
      })
    },1000),
    // 校验是否开启拟退场
    checkExitDate(officeId) {
      const params = {
        officeId: officeId,
        projectId: this.user.projectId
      }
      enablePlanExit(params).then(res => {
        if (res.data.code == 0)
          this.formData.forEach(el => {
            if (el.key === 'planExitDate') {
              if (res.data.data === true) {
                el.decorator.forEach((items) => {
                  if (items instanceof Object) {
                    items.rules[0].required = true;
                  }
                })
              } else {
                el.decorator.forEach((items) => {
                  if (items instanceof Object) {
                    items.rules[0].required = false;
                  }
                })
              }
            }
          })
          return res.data.data
      })
    },
    // 校验拟退场-电子合同状态
    setExitContactStatus(status) {
      // 签署状态 0：待工人签署  1：合同已归档  2：公司驳回 3:待公司签署 4:已经线下签署 99:已作废
      const contactStatus = [0,1,3,4,99]
      const isInfo = contactStatus.includes(status)
      this.formData.forEach(el => {
        if (el.key === 'planExitDate') {
          if (isInfo === true) {
            el.disabled = true
          } else {
            el.disabled = false
          }
        }
      })
    },
    onDateChange(date) {
      const idCard = this.$refs.formIndex.getFieldValue().idCard
      if (this.isIdCardNo(idCard)) {
        const planExitParam = {
          idCards:[idCard],
          projectId: this.user.projectId,
          planExitDate: date
        }
        checkPlanExitDate(planExitParam).then(res => {
          if (res.data.code == 0) {
            // this.batchExitDate = false
          } else {
            // this.batchExitDate = true
            this.$refs.formIndex.setFieldsValue('planExitDate', {'planExitDate': null}, 'planExitDate')
            this.$message.error(res.data.msg, 10)
          }
        })
      }
    }
  }
};
</script>

<style lang="less" scoped>
.wapper {
  // width: 50%;
  flex: 1;
  // height: 100%;
  // height: 1184px !important;
}
.public {
  padding: 17px;
  background: #fff;
  border-radius: 10px;
}

.m_g {
  background: #ffffff;
  // border-radius: 10px;
  padding-bottom: 20px;
  .m_g_left {
    border: 1px solid rgb(218, 230, 255);
    margin-right: 10px;
    // margin: 10px;
  }

  .m_g_right_top {
    border: 1px solid rgb(218, 230, 255);
    margin-bottom: 10px;
  }

  .m_g_right_mid {
    margin-bottom: 10px;
    border: 1px solid rgb(218, 230, 255);
  }

  .m_g_right_bottom {
    border: 1px solid rgb(218, 230, 255);
  }

  .m_g_button {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 10px;
    margin-right: 10px;
  }
}
// ::v-deep .ant-form-item {
//   margin-bottom: 24px !important;
// }
</style>
