<template>
  <div class="container">
    <div class="content">
      <div class="img-box">
        <img :src="staffLib.appPhotoPath" alt="">
        <p>{{ staffLib.name }}</p>
        <p>{{ staffLib.gender }}</p>
        <p>{{ staffLib.nation }}</p>
        <p>{{ workAge }}岁</p>
      </div>
      <div class="calender-box">
        <a-calendar :header-render="headerRender">
          <template slot="dateCellRender" slot-scope="value">
            <div v-for="(item, index) in getListData(value)" :key="index">
              <div class="flex a-center" :key="index2" v-for="(obj, index2) in item.atteTime">
                <span>{{ formatDate(obj.atteTime) }}</span>
                <span v-if="obj['checkType'] == 1" class="ml-05 font-12 in-span">进</span>
                <span v-else class="ml-05 font-12 out-span">出</span>
              </div>
            </div>
          </template>
        </a-calendar>
      </div>
    </div>
  </div>
</template>

<script>
import { staffInfo } from "@/services/participationUnit";
import { getStatistics } from "@/services/dmgr";
import moment from 'moment'
export default {
  data() {
    return {
      projWorkNum: '',
      teamId: '',
      workNum: '',
      staffLib: {},
      workAge: 24,
      params: {
        //测试数据
        pageNumber: 1,
        pageSize: 50,
        isPage: "true",
        month: moment(moment()).format("YYYYMM"),
        workNum: '',
        name: ''
      },
      dayAtteList: []
    };
  },
  mounted() {
    this.projWorkNum = this.$route.params.projWorkNum
    this.teamId = this.$route.query.teamId
    this.workNum = this.params.workNum = this.$route.query.workNum
    this.getStaffInfo()
  },
  methods: {
    getStaffInfo() {
      staffInfo({
        projWorkNum: this.projWorkNum,
        teamId: this.teamId,
        workNum: this.workNum
      }).then(res => {
        if (res.data.code === 0) {
          if (res.data.data.staffLib) this.staffLib = res.data.data.staffLib
          if (res.data.data.staffLib.birthday) this.getAge(res.data.data.staffLib.birthday)
          this.params.name = res.data.data.staffLib.name
          this.getData(this.params)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    getData(params) {
      this.tableLoading = true;
      getStatistics(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code === 0) {
          if (res.data.data.list.length > 0) {
            this.dayAtteList = res.data.data.list[0].dayAtte
          } else {
            this.$message.warning('暂无数据！')
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    getListData(value) {
      let listData;
      const dateStr = value.format('yyyyMMDD')
      listData = this.dayAtteList.filter(el => {
        if (dateStr == el.day && el.atteTime && el.atteTime.length > 0) {
          return el
        }
      })
      // console.log(dateStr)
      return listData || [];
    },
    formatDate(e) {
      let timeArr = e.substring(8, 12).split('')
      return timeArr[0] + timeArr[1] + ':' + timeArr[2] + timeArr[3]
    },
    // 根据出生日期获取年龄
    getAge(vl) {
      if (vl) {
        const birthdayTimestamp = vl / 1000; // 将生日时间戳转换为秒
        this.workAge = this.calculateAge(birthdayTimestamp)
      }
    },
    calculateAge(birthdayTimestamp) {
      // 当前时间戳
      var currentTimestamp = Math.floor(Date.now() / 1000);
      // 将生日时间戳转换为毫秒
      var birthdayMilliseconds = birthdayTimestamp * 1000;
      // 计算年龄
      var ageDate = new Date(currentTimestamp - birthdayMilliseconds);
      var age = Math.abs(ageDate.getUTCFullYear() - 1970);
      return age;
    },
    //日历自定义头部
    headerRender ({ value, type, onChange, onTypeChange }) {
      const start = 0
      const end = 12
      const monthOptions = []

      const current = value.clone()
      const localeData = value.localeData()
      const months = []
      for (let i = 0; i < 12; i++) {
        current.month(i)
        months.push(localeData.monthsShort(current))
      }

      for (let index = start; index < end; index++) {
        monthOptions.push(
          <a-select-option class="month-item" key={`${index}`}>
          {months[index]}
          </a-select-option>
        )
      }
      const month = value.month()

      const year = value.year()
      const options = []
      for (let i = year - 10; i < year + 10; i += 1) {
        options.push(
          <a-select-option key={i} value={i} class="year-item">
            {i + '年'}
          </a-select-option>
        )
      }
      /* 上个月 */
      const prevMonth = () => {
        let newMonth = moment(value).subtract(1, 'months');
        onChange(newMonth);
        this.params.month = newMonth.format("YYYYMM"),
        this.getData(this.params)
      };
      /* 下个月 */
      const nextMonth = () => {
        let newMonth = moment(value).add(1, 'months');
        onChange(newMonth);
        this.params.month = newMonth.format("YYYYMM"),
        this.getData(this.params)
      };
      // 返回今天
      const showTotay = () => {
        const today = moment(new Date())
        onChange(today)
        this.params.month = today.format("YYYYMM"),
        this.getData(this.params)
      }
      return (
        <div style={{ padding: ' 15px 15px 40px 15px', textAlign: 'center', position: 'relative'}}>
          <div style={{display: 'inline-block', textAlign: 'center', fontSize: '26px'}}>
            <span>{Number(year)}</span>年
            <span>{String(month + 1)}</span>月
            {/* <span class="header-title">XXX标题</span> */}
          </div>
          <div style={{position: 'absolute',right: '15px',top: '20px'}}>
            <a-button-group>
              <a-button type="primary" onClick={() => prevMonth()}><a-icon type="left" />上一月</a-button>
              <a-button type="primary" onClick={() => showTotay()}>返回今日</a-button>
              <a-button type="primary" onClick={() => nextMonth()}>下一月<a-icon type="right" /></a-button>
            </a-button-group>
          </div>
        </div>
      )
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  display: flex;
}
.img-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
  img {
    width: 150px;
    margin-bottom: 10px;
  }
}
.check {
  color: #89bceb;
  cursor: pointer;
}
.attachment-img {
  width: 550px;
}
.in-span {
  color: #fff;
  background: #00CE86;
  border-radius: 2px;
  padding: 0.5px 4px;
  text-align: center;
}
.out-span {
  color: #fff;
  background: #FF5D78;
  border-radius: 2px;
  padding: 0.5px 4px;
  text-align: center;
}
.calender-box {
  border: 1px solid rgba(0, 0, 0, 0.25);
}
</style>
