<template>
  <div class="a_Approval">
    <div class="plug-Overview">视频监控</div>
    <a-divider />
    <div v-if="videoList.length > 0" class="flex a-center j-center flex-column">
      <h2>{{gcglbName}}——{{projectName}}</h2>
      <div class="mt-2" style="width: 70%;">
        <a-row>
          <a-col v-for="(item, index) in videoList" :key="index" :span="8">
            <div class="flex a-center j-center p relative">
              <video 
                style="width: 100%;"
                id="video"
                :ref="`video${index}`"
                crossorigin="anonymous"
                autoplay
                ></video>
                <a-icon class="fullscreen-icon" type="fullscreen" @click="onClickVideo(item, index)"/>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <div v-else>
      <a-empty />
    </div>
    <a-modal v-model="modalVisible" :destroyOnClose="true" :footer="null" :width="1100" @cancel="closeModal"
      :maskClosable="false">
      <video 
        style="width: 100%;"
        id="video"
        ref="videoModal"
        crossorigin="anonymous"
        autoplay
        controls
        ></video>
    </a-modal>
  </div>
</template>

<script>
import Hls from 'hls.js';
import { getVideoView } from '@/services/sys'
import { mapGetters } from "vuex";
import { QUERY_OFFICE_BY_USER } from '@/services/api'
import { request } from '@/utils/request'

export default {
  data() {
    return {
      params: {
        // projectId: '360a2b25f4e5626033cea7203b618ebf'
      },
      modalVisible: false,
      videoList: [],
      projectName: '',
      officeList: [],
      gcglbName: '',
      hlsModal: null,
      videoElement: null,
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  activated() {
    this.getgcglbList('/admin-api/system/dept/getOfficesByUser')
  },
  methods: {
    closeModal() {
      this.modalVisible = false
      if (this.hlsModal) {
        this.hlsModal.destroy()
        this.hlsModal = null
      }
      if (this.videoElement) {
        this.videoElement.play()
        this.videoElement = null
      }
    },
    // 获取工程管理部数据
    getgcglbList(path) {
      request(path, 'get').then(res => {
        this.officeList = res.data.data.filter(item => item.id != "3da0f5533f87425c9a9e8a11a7923300")
        this.getData()
      })
    },
    getData() {
      getVideoView(this.params).then((res) => {
        if (res.data.code === 0) {
          const gcglb = this.officeList.find(el => el.id == res.data.data.list[0].gcglbId)
          this.gcglbName = gcglb ? gcglb.name : ''
          this.projectName = res.data.data.list[0].projectName
          this.videoList = res.data.data.list[0].proejctVideoViewDOList
          this.videoList.forEach((el, index) => {
            this.$nextTick(() => {
              el.videoElement = this.$refs[`video${index}`][0];
              if (Hls.isSupported()) {
                el.hls = new Hls();
                el.hls.loadSource(el.url);
                el.hls.attachMedia(el.videoElement);
                el.hls.on(Hls.Events.MANIFEST_PARSED, () => {
                  el.videoElement.play();
                });
              } else if (el.videoElement.canPlayType('application/vnd.apple.mpegURL')) {
                el.videoElement.src = el.url;
                el.videoElement.addEventListener('loadedmetadata', () => {
                  el.videoElement.play();
                });
              }
            })
          })
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    onClickVideo(item, index) {
      this.modalVisible = true
      this.videoElement = item.videoElement
      this.videoElement.pause()
      this.$nextTick(() => {
        const video = this.$refs.videoModal
        if (Hls.isSupported()) {
          this.hlsModal = new Hls();
          this.hlsModal.loadSource(item.url);
          this.hlsModal.attachMedia(video);
          this.hlsModal.on(Hls.Events.MANIFEST_PARSED, () => {
            video.play();
          });
        } else if (video.canPlayType('application/vnd.apple.mpegURL')) {
          video.src = item.url;
          video.addEventListener('loadedmetadata', () => {
            video.play();
          });
        }
      })
    },
  },
};
</script>

<style lang="less" scoped>
.fullscreen-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  color: #fff;
  background: #9a9a9a;
}
</style>
