var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "analysis" },
    [
      _c(
        "a-row",
        { staticStyle: { "margin-top": "0" }, attrs: { gutter: [24, 24] } },
        [
          _c(
            "a-col",
            { attrs: { sm: 24, md: 12, xl: 6 } },
            [
              _c(
                "chart-card",
                {
                  attrs: {
                    loading: _vm.loading,
                    title: _vm.$t("totalSales"),
                    total: "￥ 189,345",
                  },
                },
                [
                  _c(
                    "a-tooltip",
                    {
                      attrs: { slot: "action", title: _vm.$t("introduce") },
                      slot: "action",
                    },
                    [_c("a-icon", { attrs: { type: "info-circle-o" } })],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("trend", {
                        staticStyle: { "margin-right": "16px" },
                        attrs: {
                          term: _vm.$t("wow"),
                          percent: 12,
                          "is-increase": true,
                          scale: 0,
                        },
                      }),
                      _c("trend", {
                        attrs: {
                          term: _vm.$t("dod"),
                          target: 100,
                          value: 89,
                          scale: 0,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
                    _vm._v(_vm._s(_vm.$ta("daily|sales", "p"))),
                    _c("span", [_vm._v(" ￥234.56")]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 24, md: 12, xl: 6 } },
            [
              _c(
                "chart-card",
                {
                  attrs: {
                    loading: _vm.loading,
                    title: _vm.$t("visits"),
                    total: "￥ 189,345",
                  },
                },
                [
                  _c(
                    "a-tooltip",
                    {
                      attrs: { slot: "action", title: _vm.$t("introduce") },
                      slot: "action",
                    },
                    [_c("a-icon", { attrs: { type: "info-circle-o" } })],
                    1
                  ),
                  _c("div", [_c("mini-area")], 1),
                  _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
                    _vm._v(_vm._s(_vm.$ta("daily|visits", "p"))),
                    _c("span", [_vm._v(" 123,4")]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 24, md: 12, xl: 6 } },
            [
              _c(
                "chart-card",
                {
                  attrs: {
                    loading: _vm.loading,
                    title: _vm.$t("payments"),
                    total: "￥ 189,345",
                  },
                },
                [
                  _c(
                    "a-tooltip",
                    {
                      attrs: { slot: "action", title: _vm.$t("introduce") },
                      slot: "action",
                    },
                    [_c("a-icon", { attrs: { type: "info-circle-o" } })],
                    1
                  ),
                  _c("div", [_c("mini-bar")], 1),
                  _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
                    _vm._v(_vm._s(_vm.$t("conversion")) + " "),
                    _c("span", [_vm._v("60%")]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 24, md: 12, xl: 6 } },
            [
              _c(
                "chart-card",
                {
                  attrs: {
                    loading: _vm.loading,
                    title: _vm.$t("operating"),
                    total: "73%",
                  },
                },
                [
                  _c(
                    "a-tooltip",
                    {
                      attrs: { slot: "action", title: _vm.$t("introduce") },
                      slot: "action",
                    },
                    [_c("a-icon", { attrs: { type: "info-circle-o" } })],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("mini-progress", {
                        attrs: {
                          target: "90",
                          percent: "78",
                          color: "#13C2C2",
                          height: "8px",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "white-space": "nowrap",
                        overflow: "hidden",
                      },
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c("trend", {
                        staticStyle: { "margin-right": "16px" },
                        attrs: {
                          term: _vm.$t("wow"),
                          percent: 12,
                          "is-increase": true,
                          scale: 0,
                        },
                      }),
                      _c("trend", {
                        attrs: {
                          term: _vm.$t("dod"),
                          target: 100,
                          value: 89,
                          scale: 0,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-card",
        {
          staticStyle: { "margin-top": "24px" },
          attrs: {
            loading: _vm.loading,
            bordered: false,
            "body-style": { padding: "24px" },
          },
        },
        [
          _c(
            "div",
            { staticClass: "salesCard" },
            [
              _c(
                "a-tabs",
                {
                  attrs: {
                    "default-active-key": "1",
                    size: "large",
                    "tab-bar-style": {
                      marginBottom: "24px",
                      paddingLeft: "16px",
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "extra-wrap",
                      attrs: { slot: "tabBarExtraContent" },
                      slot: "tabBarExtraContent",
                    },
                    [
                      _c("div", { staticClass: "extra-item" }, [
                        _c("a", [_vm._v(_vm._s(_vm.$t("day")))]),
                        _c("a", [_vm._v(_vm._s(_vm.$t("week")))]),
                        _c("a", [_vm._v(_vm._s(_vm.$t("month")))]),
                        _c("a", [_vm._v(_vm._s(_vm.$t("year")))]),
                      ]),
                      _c("a-range-picker", { style: { width: "256px" } }),
                    ],
                    1
                  ),
                  _c(
                    "a-tab-pane",
                    {
                      key: "1",
                      attrs: { loading: "true", tab: _vm.$t("sales") },
                    },
                    [
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 16, lg: 12, md: 12, sm: 24, xs: 24 },
                            },
                            [
                              _c("bar", {
                                attrs: {
                                  title: _vm.$ta("stores|sales|trend", "p"),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 8, lg: 12, md: 12, sm: 24, xs: 24 },
                            },
                            [
                              _c("ranking-list", {
                                attrs: {
                                  title: _vm.$ta("stores|sales|ranking", "p"),
                                  list: _vm.rankList,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-tab-pane",
                    { key: "2", attrs: { tab: _vm.$t("visits") } },
                    [
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 16, lg: 12, md: 12, sm: 24, xs: 24 },
                            },
                            [
                              _c("bar", {
                                attrs: { title: _vm.$ta("visits|trend", "p") },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 8, lg: 12, md: 12, sm: 24, xs: 24 },
                            },
                            [
                              _c("ranking-list", {
                                attrs: {
                                  title: _vm.$ta("stores|visits|ranking", "p"),
                                  list: _vm.rankList,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "a-row",
        { staticStyle: { margin: "0 -12px" } },
        [
          _c(
            "a-col",
            {
              staticStyle: { padding: "0 12px" },
              attrs: { xl: 12, lg: 24, md: 24, sm: 24, xs: 24 },
            },
            [
              _c(
                "a-card",
                {
                  staticStyle: { "margin-top": "24px" },
                  attrs: {
                    loading: _vm.loading,
                    bordered: false,
                    title: _vm.$t("search"),
                  },
                },
                [_c("hot-search")],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            {
              staticStyle: { padding: "0 12px" },
              attrs: { xl: 12, lg: 24, md: 24, sm: 24, xs: 24 },
            },
            [
              _c(
                "a-card",
                {
                  staticStyle: { "margin-top": "24px" },
                  attrs: {
                    loading: _vm.loading,
                    bordered: false,
                    title: _vm.$t("proportion"),
                  },
                },
                [
                  _c("sales-data"),
                  _c(
                    "a-radio-group",
                    {
                      staticStyle: { margin: "-12px 0" },
                      attrs: { slot: "extra" },
                      slot: "extra",
                    },
                    [
                      _c("a-radio-button", { attrs: { value: "a" } }, [
                        _vm._v(_vm._s(_vm.$t("all"))),
                      ]),
                      _c("a-radio-button", { attrs: { value: "b" } }, [
                        _vm._v(_vm._s(_vm.$t("online"))),
                      ]),
                      _c("a-radio-button", { attrs: { value: "c" } }, [
                        _vm._v(_vm._s(_vm.$t("stores"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }