var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a_Approval" },
    [
      _c(
        "a-tabs",
        { attrs: { "default-active-key": "1" } },
        [
          _c(
            "a-tab-pane",
            { key: "1", attrs: { tab: "带班检查记录" } },
            [
              _c(
                "a-spin",
                { attrs: { spinning: _vm.spinning, tip: _vm.loadingText } },
                [
                  _c(
                    "a-form",
                    { staticClass: "clearfix" },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 5 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: "统计方式",
                                labelCol: { span: 8 },
                                wrapperCol: { span: 14, offset: 1 },
                              },
                            },
                            [
                              _c(
                                "a-radio-group",
                                {
                                  model: {
                                    value: _vm.queryParams.queryType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryParams,
                                        "queryType",
                                        $$v
                                      )
                                    },
                                    expression: "queryParams.queryType",
                                  },
                                },
                                [
                                  _c(
                                    "a-radio-button",
                                    { attrs: { value: 1 } },
                                    [_vm._v(" 月份 ")]
                                  ),
                                  _c(
                                    "a-radio-button",
                                    { attrs: { value: 2 } },
                                    [_vm._v(" 年份 ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 5 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: "月份/年份",
                                labelCol: { span: 8 },
                                wrapperCol: { span: 14, offset: 1 },
                              },
                            },
                            [
                              _vm.queryParams.queryType == 1
                                ? _c(
                                    "a-month-picker",
                                    {
                                      attrs: {
                                        placeholder: "请选择月份",
                                        "default-value": _vm.moment(
                                          _vm.monthDay,
                                          _vm.monthFormat
                                        ),
                                        format: _vm.monthFormat,
                                      },
                                      model: {
                                        value: _vm.queryParams.plan_date,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParams,
                                            "plan_date",
                                            $$v
                                          )
                                        },
                                        expression: "queryParams.plan_date",
                                      },
                                    },
                                    [
                                      _c("a-icon", {
                                        attrs: {
                                          slot: "suffixIcon",
                                          type: "smile",
                                        },
                                        slot: "suffixIcon",
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.queryParams.queryType == 2
                                ? _c("a-date-picker", {
                                    staticStyle: { width: "200px" },
                                    attrs: {
                                      mode: "year",
                                      placeholder: "请输入年份",
                                      "default-value": _vm.moment(
                                        _vm.nowYear,
                                        _vm.yearFormat
                                      ),
                                      format: _vm.yearFormat,
                                      open: _vm.yearShowOne,
                                    },
                                    on: {
                                      openChange: _vm.openChangeOne,
                                      panelChange: _vm.panelChangeOne,
                                    },
                                    model: {
                                      value: _vm.queryParams.plan_date,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryParams,
                                          "plan_date",
                                          $$v
                                        )
                                      },
                                      expression: "queryParams.plan_date",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "a-space",
                            { staticClass: "btnBox" },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.doQuery()
                                    },
                                  },
                                },
                                [_vm._v("查询")]
                              ),
                              _c(
                                "a-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.doReset()
                                    },
                                  },
                                },
                                [_vm._v("重置")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "standard-table",
                        {
                          attrs: {
                            bordered: true,
                            columns: _vm.columns,
                            dataSource: _vm.dataSource,
                            selectedRows: _vm.selectedRows,
                            isAlert: false,
                            pagination: _vm.pagination,
                            loading: _vm.tableLoading,
                            btnArr: _vm.tableBtnArr,
                          },
                          on: {
                            "update:selectedRows": function ($event) {
                              _vm.selectedRows = $event
                            },
                            "update:selected-rows": function ($event) {
                              _vm.selectedRows = $event
                            },
                            clear: _vm.onClear,
                            change: _vm.onPageChange,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "index",
                              fn: function (ref) {
                                var index = ref.index
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        (_vm.pagination.current - 1) *
                                          _vm.pagination.pageSize +
                                          parseInt(index) +
                                          1
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "action",
                              fn: function (ref) {
                                var record = ref.record
                                return _c("div", {}, [
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.doFileDowload(record)
                                        },
                                      },
                                    },
                                    [_vm._v("下载资料")]
                                  ),
                                ])
                              },
                            },
                            {
                              key: "do-status",
                              fn: function (ref) {
                                var text = ref.text
                                return _c("div", {}, [
                                  text == 2
                                    ? _c(
                                        "label",
                                        [
                                          _c("a-icon", {
                                            staticStyle: {
                                              "font-size": "15px",
                                              color: "green",
                                            },
                                            attrs: { type: "check" },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "label",
                                        [
                                          _c("a-icon", {
                                            staticStyle: {
                                              "font-size": "15px",
                                              color: "red",
                                            },
                                            attrs: { type: "close" },
                                          }),
                                        ],
                                        1
                                      ),
                                ])
                              },
                            },
                          ]),
                        },
                        [
                          _c(
                            "template",
                            { slot: "statusTitle" },
                            [
                              _c("a-icon", {
                                attrs: { type: "info-circle" },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.onStatusTitleClick($event)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c("Export-loading", {
                    attrs: {
                      cPercent: _vm.cPercent,
                      isExporting: _vm.isExporting,
                      loadingText: _vm.loadingText,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isGcglb != 1
            ? _c(
                "a-tab-pane",
                {
                  key: "2",
                  attrs: { tab: "领导带班详情", "force-render": "" },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { "max-width": "65%" } },
                    [
                      _vm._v(" 年份： "),
                      _c("a-date-picker", {
                        staticStyle: { width: "100px" },
                        attrs: {
                          mode: "year",
                          placeholder: "请输入年份",
                          "default-value": _vm.moment(
                            _vm.thisYear,
                            _vm.yearFormat
                          ),
                          format: _vm.yearFormat,
                          open: _vm.officeParams.yearShowOne,
                        },
                        on: {
                          openChange: _vm.gcglbOpenChangeOne,
                          panelChange: _vm.gcglbPanelChangeOne,
                        },
                        model: {
                          value: _vm.officeParams.plan_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.officeParams, "plan_date", $$v)
                          },
                          expression: "officeParams.plan_date",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticStyle: { "margin-top": "15px" } }),
                  _c("a-table", {
                    attrs: {
                      bordered: true,
                      columns: _vm.columns2,
                      dataSource: _vm.dataSource2,
                      pagination: false,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }