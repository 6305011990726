<template>
  <div>
    <a-card title="项目危险源情况">
      <a-form-model layout="horizontal" :model="params">
        <a-row>
          <a-col :span="8">
            <a-form-model-item
              label="状态："
              :labelCol="{ span: 3 }"
              :wrapperCol="{ span: 15 }"
            >
              <span>{{statusName}}</span>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-model-item
              label="项目："
              :labelCol="{ span: 3 }"
              :wrapperCol="{ span: 15 }"
            >
              <a-input
                placeholder="请输入项目"
                v-model="params.projectName"
                disabled
                allowClear
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              label="工程类别："
              :labelCol="{ span: 3 }"
              :wrapperCol="{ span: 11 }"
            >
              <a-input
                v-model="projectType"
                allowClear
                disabled
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item
              label="自评得分："
              :labelCol="{ span: 3 }"
              :wrapperCol="{ span: 15 }"
            >
              <a-input
                class="custom-input-number"
                placeholder="请输入自评得分"
                v-model="params.score"
                allowClear
                :type="'number'"
                @input="validateInteger"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              label="形象进度："
              :labelCol="{ span: 3 }"
              :wrapperCol="{ span: 11 }"
            >
              <a-select v-if="params.ptype == 1" v-model="params.imageProcess" placeholder="请输入形象进度">
                <a-select-option value="基础阶段">
                  基础阶段
                </a-select-option>
                <a-select-option value="主体结构阶段">
                  主体结构阶段
                </a-select-option>
                <a-select-option value="装饰装修阶段">
                  装饰装修阶段
                </a-select-option>
              </a-select>
              <a-input
                v-else
                placeholder="请输入形象进度"
                v-model="params.imageProcess"
                allowClear
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="24"
            ><a-form-model-item
              label="危大内容："
              :labelCol="{ span: 1 }"
              :wrapperCol="{ span: 14 }"
            >
            <a-radio-group class="ml-3" v-model="radio" @change="radioChange">
              <a-radio :value="true">
                是
              </a-radio>
              <a-radio :value="false">
                否
              </a-radio>
            </a-radio-group>
              <a-textarea
                placeholder="请输入危大内容"
                v-model="params.dangerContent"
                :disabled="!radio"
                :autoSize="{minRows: 10, maxRows: 20}"
              />
           </a-form-model-item>
          </a-col>
        </a-row>
        <a-form-item :wrapper-col="{ offset: 11 }">
          <a-button @click="close">
            关闭
          </a-button>
          <!-- <a-button class="ml-3" type="primary" @click="submit(0)" v-if="params.status == 0 || params.status == 2">
            暂存
          </a-button> -->
          <a-button class="ml-3" type="primary" @click="submit(1)" v-if="params.status != 3 && canOperate">
            保存
          </a-button>
        </a-form-item>
      </a-form-model>
    </a-card>
  </div>
</template>

<script>
import {
  getDangerSourceDetail, updateSourceDanger
} from "@/services/safeMonthlyReport";
import { mapGetters } from "vuex";
export default {
  inject: ["fatherMethod"],
  data() {
    return {
      radio: true,
      params: {
        score: "",
        projectName: "",
        dangerContent: "",
        imageProcess: "",
        id: '',
        ptype: null,
        status: null
      },
      projectType: "",
      arrRoles:['PROJECT_JSFZR','PROJECT_AQY_ZB_FZR', 'project_leader', 'PROJECT_DEPUTY_LEADER'], // 保存权限按钮
    };
  },

  activated() {
    this.params.id = this.$route.query.id
    this.handleQuery()
  },
  computed: {
    statusName() {
      switch (this.params.status) {
        case 0:
          return '待确认'
        case 1:
          return '已确认'
        case 2:
          return '退回'
        case 3:
          return '已上报'
        default:
          return ''
      }
    },
    ...mapGetters("account", ["user"]),
    canOperate() {
      return this.user.nowRoleKey.some(role => this.arrRoles.includes(role));
    },
  },
  methods: {
    validateInteger(e) {
      let value = e.target.value;
      if (value.includes('.')) {
        // 如果包含小数点，提示警告信息
        this.$message.warning("请输入整数，不允许包含小数点！");
      }
    },
    handleQuery() {
      getDangerSourceDetail(this.params.id).then((res) => {
        let d = res.data;
        if (d.code === 0) {
          if (d.data.dangerContent == '此项目无危大危险源') this.radio = false
          this.params = d.data
          if (this.params.ptype == 1) {
            this.projectType = '房建'
          } else {
            this.projectType = '市政'
          }
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    submit(e) {
      const _that = this
      if (_that.params.score.toString().includes('.')) {
        // 如果包含小数点，提示警告信息
        this.$message.warning("请输入整数，不允许包含小数点！");
        return
      }
      if (e == 1) {
        this.$confirm({
          title: '是否确认提交？',
          onOk() {
            updateSourceDanger(_that.params).then(res => {
              let d = res.data;
              if (d.code === 0) {
                _that.$message.success('保存成功')
                _that.handleQuery()
              } else {
                _that.$message.error(res.data.msg)
              }
            })
          }
        })
      } else {
        this.params.status = e
        updateSourceDanger(this.params).then(res => {
          let d = res.data;
          if (d.code === 0) {
            this.$message.success('暂存成功')
            this.handleQuery()
          } else {
            this.$message.error(res.data.msg)
          }
        })
      }
    },
    close() {
      let path = this.$route.path
      this.fatherMethod(`${path}`)
    },
    radioChange() {
      if (this.radio) {
        this.params.dangerContent = ''
      } else {
        this.params.dangerContent = '此项目无危大危险源'
      }
    }
  },
};
</script>

<style lang="less" scoped>
// inputNumber 去掉右侧加减箭头
.custom-input-number /deep/ input::-webkit-outer-spin-button,
.custom-input-number /deep/ input::-webkit-inner-spin-button {
    appearance: none;
}
</style>
