<template>
  <div>
    <a-card :title="title">
      <form-index
        ref="formindex"
        :formData="formData"
        @handleSubmit="submit"
        :bottomSubmit="true"
      >
        <!-- 下部分按钮插槽 -->
        <template #bottomSubmit>
          <a-button type="primary" html-type="submit" v-show="!$route.meta.isBreak"> 确定 </a-button>
          <a-button @click="close"> 关闭 </a-button>
        </template>
      </form-index>
    </a-card>
  </div>
</template>

<script>
const formData = [
  {
    label: "项目名称",
    placeholder: "请输入项目名称",
    decorator: [
      "projectName",
      { rules: [{ required: true, message: "请输入项目名称" }] },
    ],
    type: "inputGroup",
    key: "projectName",
    dataSource: [],
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
    disabled: true
  },
  {
    label: '工程管理部',
    placeholder: '请选择工程管理部',
    decorator: ['gcglbId', { rules: [{ required: true, message: '请选择工程管理部' }] }],
    type: 'select',
    key: 'gcglbId',
    selectlist: [],
    qb:true,
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    disabled: true
  },
  {
    label: "自编号",
    placeholder: "请填写",
    decorator: [
      "selfNum",
      { rules: [{ required: true, message: "请填写" }] },
    ],
    type: "input",
    key: "selfNum",
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "设备名称",
    placeholder: "请填写",
    decorator: [
      "facilityTypeName",
      { rules: [{ required: false, message: "请填写" }] },
    ],
    disabled: true,
    type: "input",
    key: "facilityTypeName",
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "设备型号",
    placeholder: "请填写",
    decorator: [
      "facilityNum",
      { rules: [{ required: true, message: "请填写" }] },
    ],
    type: "input",
    key: "facilityNum",
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "检测单位名称",
    placeholder: "请填写",
    decorator: [
      "checkUnit",
      { rules: [{ required: false, message: "请填写" }] },
    ],
    type: "input",
    disabled: true,
    key: "checkUnit",
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "设备检验报告编号",
    placeholder: "请填写",
    decorator: [
      "checkReportNum",
      { rules: [{ required: false, message: "请填写" }] },
    ],
    type: "input",
    disabled: true,
    key: "checkReportNum",
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "最新检验日期",
    placeholder: "请填写",
    decorator: [
      "checkDate",
      { rules: [{ required: false, message: "请填写" }] },
    ],
    type: "input",
    disabled: true,
    key: "checkDate",
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "公司验收日期",
    placeholder: "请选择",
    decorator: [
      "acceptDate",
      { rules: [{ required: false, message: "请选择" }] },
    ],
    type: "datePicker",
    format: "yyyy-MM-DD",
    key: "acceptDate",
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
    styles: {width: '100%'}
  },
  {
    label: "状态",
    placeholder: "请选择状态",
    decorator: [
      "status",
      { rules: [{ required: true, message: "请选择状态" }] },
    ],
    type: "select",
    key: "status",
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    selectlist: [
      {
        value: 0,
        name: "使用中",
      },
      {
        value: 1,
        name: "取证中",
      },
      {
        value: 2,
        name: "停用",
      },
    ],
  },
  {
    label: "使用登记证编号",
    placeholder: "请填写",
    decorator: [
      "usingNum",
      { rules: [{ required: false, message: "请填写" }] },
    ],
    type: "input",
    disabled: true,
    key: "usingNum",
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "有效日期",
    placeholder: "请填写",
    decorator: [
      "usingEndTime",
      { rules: [{ required: false, message: "请填写" }] },
    ],
    type: "input",
    disabled: true,
    key: "usingEndTime",
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "制造单位名称",
    placeholder: "请填写",
    decorator: [
      "makeUnitName",
      { rules: [{ required: true, message: "请填写" }] },
    ],
    type: "input",
    key: "makeUnitName",
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "设备出厂编号",
    placeholder: "请填写",
    decorator: [
      "factoryNum",
      { rules: [{ required: true, message: "请填写" }] },
    ],
    type: "input",
    key: "factoryNum",
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "设备出厂日期",
    placeholder: "请选择",
    decorator: [
      "factoryDate",
      { rules: [{ required: true, message: "请选择" }] },
    ],
    type: "datePicker",
    format: "yyyy-MM-DD",
    key: "factoryDate",
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
    styles: {width: '100%'}
  },
  {
    label: "设备产权单位名称",
    placeholder: "请填写",
    decorator: [
      "equityUnit",
      { rules: [{ required: true, message: "请填写" }] },
    ],
    type: "input",
    key: "equityUnit",
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "产权登记编号",
    placeholder: "请填写",
    decorator: [
      "equityNum",
      { rules: [{ required: true, message: "请填写" }] },
    ],
    type: "input",
    key: "equityNum",
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "租赁合同编号",
    placeholder: "请填写",
    decorator: [
      "rentContractNum",
      { rules: [{ required: true, message: "请填写" }] },
    ],
    type: "input",
    key: "rentContractNum",
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "安装单位名称",
    placeholder: "请填写",
    decorator: [
      "installUnit",
      { rules: [{ required: true, message: "请填写" }] },
    ],
    type: "input",
    key: "installUnit",
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "安装资质等级",
    placeholder: "请选择",
    decorator: [
      "installQualityLevel",
      { rules: [{ required: true, message: "请选择" }] },
    ],
    type: 'select',
    key: 'installQualityLevel',
    selectlist: [
      { name: '壹级', value: '壹级' },
      { name: '贰级', value: '贰级' },
    ],
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
  },
  {
    label: "安装合同编号",
    placeholder: "请填写",
    decorator: [
      "installContractNum",
      { rules: [{ required: false, message: "请填写" }] },
    ],
    type: "input",
    key: "installContractNum",
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
  // {
  //   label: "拆卸合同编号",
  //   placeholder: "请填写",
  //   decorator: [
  //     "splitContractNum",
  //     { rules: [{ required: false, message: "请填写" }] },
  //   ],
  //   type: "input",
  //   key: "splitContractNum",
  //   col: 8,
  //   labelCol: 8,
  //   wrapperCol: 12,
  //   display: true,
  // },
  {
    label: "方案审批情况",
    placeholder: "请选择",
    decorator: [
      "approveStatus",
      { rules: [{ required: true, message: "请选择" }] },
    ],
    type: "select",
    key: "approveStatus",
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    selectlist: [
      {
        value: '0',
        name: "未审批",
      },
      {
        value: '1',
        name: "已审批",
      },
      {
        value: '2',
        name: "审批中",
      },
    ],
  },
  {
    label: "安装完成日期",
    placeholder: "请选择",
    decorator: [
      "finishDate",
      { rules: [{ required: false, message: "请选择" }] },
    ],
    type: "datePicker",
    format: "yyyy-MM-DD",
    key: "finishDate",
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
    styles: {width: '100%'}
  },
  {
    label: "安装告知编号",
    placeholder: "请填写",
    decorator: [
      "installNum",
      { rules: [{ required: false, message: "请填写" }] },
    ],
    type: "input",
    key: "installNum",
    col: 8,
    labelCol: 8,
    wrapperCol: 12,
    display: true,
  },
];
import formIndex from "@/pages/components/form/index";
import { projectMsgGetList } from "@/services/projectManagement";
import { getFacilityDetail, updateFacility } from "@/services/equipmentLedger";
import { mapMutations } from "vuex";
export default {
  components: {
    formIndex,
  },
  inject: ["fatherMethod"],
  data() {
    return {
      title: '',
      formData: formData,
      params: {
        id: '',
        facilityType: null,
      },
      detailObj: null, //保存详情的对象
    };
  },
  props: {
    gcglbList: {
      type: Array,
      request: true,
      default: () => []
    }
  },
  watch: {
    gcglbList: {
      handler(newVal) {
        this.formDataFn("gcglbId", newVal);
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.init()
  },
  methods: {
    ...mapMutations("equipment", ["setTowerCrane", "setConstructionHoist", "setMaterialHois", 'setPortalCrane', 'setBridgeCrane']),
    init() {
      this.params.id = this.$route.query.id;
      this.params.facilityType = Number(this.$route.query.facilityType);
      switch(this.params.facilityType) {
        case 1: 
          this.title = '塔式起重机'
          break
        case 2: 
          this.title = '施工升降机'
          break
        case 3: 
          this.title = '物料提升机'
          break
        case 4: 
          this.title = '门式起重机'
          break
        case 5: 
          this.title = '桥式起重机'
          break
      }
      switch(this.params.facilityType) {
        case 1: 
        case 3: 
        case 4: 
        case 5: 
          if (this.formData.some(item => item.key === 'instaliSafeProtocal')) {
            this.formData.splice(this.formData.findIndex(item => item.key === 'instaliSafeProtocal'), 1)
          }
          if (this.formData.some(item => item.key === 'splitSafeProtocal')) {
            this.formData.splice(this.formData.findIndex(item => item.key === 'splitSafeProtocal'), 1)
          }
          break
        case 2: 
          if (!this.formData.some(item => item.key === 'instaliSafeProtocal')) {
            this.formData.splice(this.formData.length - 2, 0, {
              label: "安装安全管理协议",
              placeholder: "请填写",
              decorator: [
                "instaliSafeProtocal",
                { rules: [{ required: false, message: "请填写" }] },
              ],
              type: "input",
              key: "instaliSafeProtocal",
              col: 8,
              labelCol: 8,
              wrapperCol: 12,
              display: true,
            })
          }
          if (!this.formData.some(item => item.key === 'splitSafeProtocal') && this.$route.meta.isBreak) {
            this.formData.splice(this.formData.length - 2, 0, {
              label: "拆卸安全管理协议",
              placeholder: "请填写",
              decorator: [
                "splitSafeProtocal",
                { rules: [{ required: false, message: "请填写" }] },
              ],
              type: "input",
              key: "splitSafeProtocal",
              col: 8,
              labelCol: 8,
              wrapperCol: 12,
              display: true,
            })
          }
          break
      }
      this.getDetail()
    },
    getDetail() {
      getFacilityDetail(this.params.id).then(res => {
        if (res.data.code === 0) {
          res.data.data.checkUnit = res.data.data.checkRecord?.checkUnit
          res.data.data.checkReportNum = res.data.data.checkRecord?.checkReportNum
          res.data.data.checkDate = res.data.data.checkRecord?.checkDate
          res.data.data.usingNum = res.data.data.usingRecord?.usingNum
          res.data.data.usingEndTime = res.data.data.usingRecord?.usingEndTime
          this.detailObj = res.data.data
          switch(this.params.facilityType) {
            case 1: 
              this.setTowerCrane(this.detailObj)
              break
            case 2: 
              this.setConstructionHoist(this.detailObj)
              break
            case 3: 
              this.setMaterialHois(this.detailObj)
              break
            case 4: 
              this.setPortalCrane(this.detailObj)
              break
            case 5: 
              this.setBridgeCrane(this.detailObj)
              break
          }
          this.$refs.formindex.queryObj(res.data.data)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      let arr = [];
      data.forEach((item) => {
        arr.push({ name: item.master, value: item.id });
      });
      this.formData.forEach((item) => {
        if (item.key == key) {
          item.selectlist = arr;
        }
      });
    },
    submit(e) {
      this.params = Object.assign(this.detailObj, Object.assign(this.params, e))
      updateFacility(this.params).then((res) => {
        let d = res.data;
        if (d.code === 0) {
          this.$message.success("修改成功");
          this.close()
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    close() {
      let path = this.$route.path;
      this.fatherMethod(`${path}`);
    },
  },
};
</script>

<style lang="less" scoped></style>
