<template>
  <div>
    <a-drawer
      :width="800"
      :visible="visible"
      title="判断题题目信息"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose"
    > 
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }" layout="horizontal">
      <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="所属课程">
              <a-select
                :allowClear="true"
                style="width: 100%"
                placeholder="请选择课程"
                @change="changeCourse"
                v-decorator="['courseId', { rules: [{ required: true, message: '请选择课程' }] }]">
                <a-select-option v-for="t in courseDataSource" :key="t.id" :value="t.id">{{t.name}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="题目：">
              <a-textarea
                v-decorator="['questCont', { rules: [{  message: '请输入题目内容' }] }]"
               placeholder="题目内容"
               :auto-size="{ minRows: 3, maxRows: 5 }"
               />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="是否正确：">
              <a-switch v-decorator="['isRight', { valuePropName: 'checked' }]" checked-children="正确" un-checked-children="错误"/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="难易程度">
              <a-radio-group v-decorator="['level']">
                <a-radio-button :value="1">
                  简单
                </a-radio-button>
                <a-radio-button :value="2">
                  一般
                </a-radio-button>
                <a-radio-button :value="3">
                  困难
                </a-radio-button>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="分值：">
              <a-input-number
                v-decorator="['core',{rules: [{ required: true, message: '请输入题目分值' }]}]"
                placeholder="题目分值"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item label="课时标签">
              <a-select
                mode="tags"
                placeholder="请您选择课时标签"
                v-model="tagsArray"
                @change="handleChange"
              >
                <a-select-option v-for="item in hourDataSource" :key="item.id">
                  {{item.name}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="题目解析：">
              <a-textarea
                v-decorator="['analysis', { rules: [{  message: '请输入题目解析' }] }]"
               placeholder="请输入题目解析"
               :auto-size="{ minRows: 3, maxRows: 5 }"
               />
            </a-form-item>
          </a-col>
        </a-row>
        
    </a-form>
    <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginLeft: '8px' }" type="primary" @click="doSubmit">
          确定
        </a-button>
        <a-button :style="{ marginLeft: '8px' }" @click="onClose">
          关闭
        </a-button>
      </div>
    </a-drawer>
    
  </div>
</template>
<script>
import { request } from "@/utils/request";
import {ONLINE_QUERY_COURSE_GETALL,ONLINE_CLASS_HOUR_GETALL} from "@/services/api";
export default {
  name: "teamEditor",
  components: {},
  data() {
    return {
      form: this.$form.createForm(this),
      courseDataSource:[],
      obj:{},
      data:[],
      hourDataSource:[],
      hourTags:'',
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    question:{
      type: Object,
      require:true
    },
    type:{
      type: String,
      default: '2'
    },
  },
  created(){
    this.doQueryCourse();
  },
  mounted(){
    
  },
  computed:{
    tagsArray:{
      get(){
        if(this.hourTags!=null&&this.hourTags!=''){
          return this.hourTags.split(',');
        }else{
          return [];
        }
      },
      set(v) {
        this.hourTags = v
      }
      
    }
  },
  watch:{
    visible(val){
      if(val){
        this.obj = this.question;
        if(this.obj.courseId){
          this.doQueryCourseHours(this.obj.courseId)
        }
        setTimeout(()=>{
          this.form.setFieldsValue({
            'isMany': this.obj.isMany?this.obj.isMany:1,
            'level': this.obj.level?this.obj.level:1,
            'questCont': this.obj.questCont,
            'core': this.obj.core?this.obj.level:1,
            'analysis': this.obj.analysis,
            'isRight': this.obj.isRight&&this.obj.isRight==1?true:false,
            'courseId': this.obj.courseId?this.obj.courseId+"":null
          })
          this.hourTags = this.obj.hourTags;
        })
      }
    }
  },
  methods: {
    doQueryCourse(){
      request(ONLINE_QUERY_COURSE_GETALL,"post",{}).then(res => {
          if(res.data.code==0){
           this.courseDataSource = res.data.data.list;
          }
       })
    },
    handleChange(values){
      this.hourTags = values.join(",");
    },
    doQueryCourseHours(courseId){
      request(ONLINE_CLASS_HOUR_GETALL,"post",{courseId:courseId}).then(res => {
          if(res.data.code==0){
           this.hourDataSource = res.data.data;
          }
       })
    },
    changeCourse(value){
      this.hourTags = '';
      this.doQueryCourseHours(value);
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },
    onClose(){
      this.$emit('questPageClose');
    },
    doSubmit(){
       //数据校验
      this.form.validateFields((err, values) => {
        if (!err) {
          if(this.obj&&this.obj.id){
            values.id = this.obj.id
          }
          this.$set(values,"type",this.type);
          this.$set(values,"hourTags",this.hourTags);
          this.$emit('submit',{quest:values})
          this.hourTags=''
          this.form.resetFields(["questCont","analysis"]);
        }
      })
    },

  }
};
</script>
<style>
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
/deep/ .ant-upload-list-picture-card .ant-upload-list-item{
  width: 127px;
  height: 127px;
}
</style>