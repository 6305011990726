var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: 600, footer: null, maskClosable: false },
      on: { cancel: _vm.handleCancel },
      model: {
        value: _vm.internalVisible,
        callback: function ($$v) {
          _vm.internalVisible = $$v
        },
        expression: "internalVisible",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "relative",
          staticStyle: { padding: "30px", height: "550px" },
        },
        [
          _c("div", { staticClass: "flex a-center j-center flex-column" }, [
            _c("h2", { staticStyle: { "font-weight": "bold" } }, [
              _vm._v("欢迎来到" + _vm._s(_vm.systemFullname)),
            ]),
            _c("p", { staticClass: "mt-1" }, [
              _vm._v("请选择以下任意一种方式进入系统"),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "flex a-center j-center flex-column mt-2" },
            [
              _c("div", { staticClass: "flex" }, [
                !_vm.isPtzy
                  ? _c(
                      "div",
                      {
                        staticClass: "choose-box choose-box-right",
                        class:
                          _vm.selectType == 1
                            ? "choose-box-selected"
                            : "choose-box-unselected",
                        on: {
                          click: function ($event) {
                            return _vm.handleSelect(1)
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "iconfont gongsi",
                          staticStyle: { "font-size": "30px" },
                        }),
                        _c("span", [_vm._v("公司管理")]),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "choose-box",
                    class:
                      _vm.selectType == 2
                        ? "choose-box-selected"
                        : "choose-box-unselected",
                    on: {
                      click: function ($event) {
                        return _vm.handleSelect(2)
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "iconfont xiangmukongjian",
                      staticStyle: { "font-size": "30px" },
                    }),
                    _c("span", [_vm._v("项目管理")]),
                  ]
                ),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "radio-box mt-3" },
            [
              _c("img", { attrs: { src: _vm.radioBg, alt: "" } }),
              _vm.selectType == 1
                ? _c(
                    "a-radio-group",
                    {
                      staticStyle: {
                        width: "85%",
                        margin: "15px 35px 10px 35px",
                      },
                      attrs: { name: "radioGroup" },
                      on: { change: _vm.onChange },
                      model: {
                        value: _vm.officeKey,
                        callback: function ($$v) {
                          _vm.officeKey = $$v
                        },
                        expression: "officeKey",
                      },
                    },
                    [
                      _c(
                        "a-radio",
                        {
                          style: _vm.radioStyle,
                          attrs: { value: _vm.officeData.key },
                        },
                        [
                          _c(
                            "span",
                            { attrs: { title: _vm.officeData.name } },
                            [
                              _vm._v(
                                _vm._s(_vm._f("ellipsis")(_vm.officeData.name))
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              staticStyle: { float: "right" },
                              attrs: { title: _vm.officeData.roles },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("ellipsisRole")(_vm.officeData.roles)
                                )
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectType == 2 && _vm.projectLists.length > 0
                ? _c(
                    "a-radio-group",
                    {
                      staticStyle: {
                        width: "85%",
                        margin: "15px 35px 10px 35px",
                      },
                      attrs: { name: "radioGroup" },
                      on: { change: _vm.onChange2 },
                      model: {
                        value: _vm.value,
                        callback: function ($$v) {
                          _vm.value = $$v
                        },
                        expression: "value",
                      },
                    },
                    _vm._l(_vm.projectLists, function (item, index) {
                      return _c(
                        "a-radio",
                        {
                          key: index,
                          style: _vm.radioStyle,
                          attrs: { value: item.key },
                        },
                        [
                          _c("span", { attrs: { title: item.name } }, [
                            _vm._v(_vm._s(_vm._f("ellipsisRole")(item.name))),
                          ]),
                          _c(
                            "span",
                            {
                              staticStyle: { float: "right" },
                              attrs: { title: item.roles },
                            },
                            [_vm._v(_vm._s(_vm._f("ellipsisRole")(item.roles)))]
                          ),
                        ]
                      )
                    }),
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "btn-box flex j-center mt-3" },
            [
              _c(
                "a-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.doSureChoice()
                    },
                  },
                },
                [_vm._v(" 确认选择 ")]
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }