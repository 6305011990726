var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "a_Approval" },
        [
          _vm.tenantId == 1 || _vm.isOrg || _vm.isOffice
            ? _c("form-index", {
                ref: "formindex",
                attrs: {
                  projectList: _vm.projectList,
                  formData: _vm.formData,
                  Overview: "项目管理/在建工程一览表",
                  flexSubmit: "",
                  flexAction: true,
                  baiduMapBoolen: false,
                },
                on: {
                  handleSubmit: _vm.handleSubmit,
                  handleChange: _vm.handleChange,
                  handlSelect: _vm.handlSelect,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "flexAction",
                      fn: function () {
                        return [
                          _c(
                            "a-space",
                            { style: { marginTop: "4px" } },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    "html-type": "submit",
                                  },
                                },
                                [_vm._v(" 查询 ")]
                              ),
                              _c("a-button", { on: { click: _vm.close } }, [
                                _vm._v(" 重置 "),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2434333794
                ),
              })
            : _vm._e(),
          _c("standard-table", {
            attrs: {
              columns: _vm.columns,
              dataSource: _vm.dataSource,
              rowKey: "id",
              scroll: { x: 1300 },
              pagination: _vm.pagination,
              loading: _vm.tableLoading,
              isAlert: false,
              bordered: true,
              btnArr: _vm.tableBtnArr,
            },
            on: { change: _vm.onPageChange },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function (ref) {
                  var index = ref.index
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            (_vm.pagination.current - 1) *
                              _vm.pagination.pageSize +
                              parseInt(index) +
                              1
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "projectName",
                fn: function (ref) {
                  var record = ref.record
                  return [
                    _c(
                      "a-tooltip",
                      { attrs: { placement: "topLeft" } },
                      [
                        _c("template", { slot: "title" }, [
                          _vm._v(" " + _vm._s(record.projectName) + " "),
                        ]),
                        _c("span", { style: _vm.textStyle(record) }, [
                          _vm._v(" " + _vm._s(record.projectName) + " "),
                        ]),
                      ],
                      2
                    ),
                  ]
                },
              },
              {
                key: "isAttached",
                fn: function (ref) {
                  var text = ref.text
                  return [_c("span", [_vm._v(_vm._s(text == 1 ? "是" : "否"))])]
                },
              },
              {
                key: "monitorDisable",
                fn: function (ref) {
                  var text = ref.text
                  return [
                    text == 1
                      ? _c("a-tag", { attrs: { color: "red" } }, [
                          _vm._v(" 是 "),
                        ])
                      : _c("a-tag", { attrs: { color: "blue" } }, [
                          _vm._v(" 否 "),
                        ]),
                  ]
                },
              },
              {
                key: "action",
                fn: function (ref) {
                  var record = ref.record
                  return _c("div", {}, [
                    _c("div", { staticClass: "sTables" }, [
                      !_vm.isOrg
                        ? _c(
                            "div",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.viewEditing(record)
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          )
                        : _vm._e(),
                      _vm.isOffice && !_vm.isOrg
                        ? _c(
                            "span",
                            {
                              staticClass: "ml-1 mr-1",
                              staticStyle: { color: "#dfdfdf" },
                            },
                            [_vm._v("|")]
                          )
                        : _vm._e(),
                      _vm.isOffice && !_vm.isOrg
                        ? _c(
                            "div",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.tobeFinished(record)
                                },
                              },
                            },
                            [_vm._v("完工")]
                          )
                        : _vm._e(),
                    ]),
                  ])
                },
              },
            ]),
          }),
          _c("Export-loading", {
            attrs: {
              cPercent: _vm.cPercent,
              isExporting: _vm.isExporting,
              loadingText: _vm.loadingText,
            },
          }),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: "是否确认完工", width: 1200, destroyOnClose: true },
          on: { ok: _vm.handlefinish, cancel: _vm.handleCancel },
          model: {
            value: _vm.finishDialogVisible,
            callback: function ($$v) {
              _vm.finishDialogVisible = $$v
            },
            expression: "finishDialogVisible",
          },
        },
        [
          _c(
            "div",
            [
              _c("form-index", {
                ref: "finishFormindex",
                attrs: {
                  formData: _vm.finishFormData,
                  defaultFileList: _vm.defaultFileList,
                },
                on: { resourceUpload: _vm.resourceUpload },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }