var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "a_m_header" },
      [
        _vm.isDirect
          ? [
              _c(
                "a-tabs",
                {
                  attrs: { "default-active-key": "1" },
                  on: { change: _vm.callback },
                },
                [
                  _c(
                    "a-tab-pane",
                    { key: "1", attrs: { tab: "当天补报" } },
                    [_c("daily-replenish", { attrs: { isDaily: true } })],
                    1
                  ),
                  _c(
                    "a-tab-pane",
                    {
                      key: "2",
                      attrs: { tab: "批量补报（非当天）", "force-render": "" },
                    },
                    [_c("daily-replenish", { attrs: { isDaily: false } })],
                    1
                  ),
                ],
                1
              ),
            ]
          : [_c("div", [_vm._v("33333")])],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }