<template>
  <div>
    <a-drawer
      :width="1024"
      :visible="visible"
      title="自动生成试卷的相关配置"
      :footer="null"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose"
    > 
    <a-steps v-model="current" type="navigation" size="small" >
      <a-step
        title="第一步"
        sub-title="配置生成规则"
      >
      </a-step>
      <a-step
        title="第二步"
        sub-title="试题编辑"
      >
      </a-step>
    </a-steps>
    <div v-show="current==0">
      <a-form :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 19 }" layout="horizontal">
      <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="所属课程">
              <a-select
                :allowClear="true"
                style="width: 100%"
                placeholder="请选择课程"
                @change="changeCourse"
                v-decorator="['courseId', { rules: [{ required: true, message: '请选择课程' }] }]">
                <a-select-option v-for="t in courseDataSource" :key="t.id" :value="t.id">{{t.name}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="试卷标题：">
              <a-input
                v-decorator="['title', { rules: [{  required: true, message: '请输入试卷标题' }] }]"
               placeholder="试卷标题"
               />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="课时标签">
              <a-select
                mode="tags"
                placeholder="请您选择课时标签"
                v-model="tagsArray"
                @change="handleChange"
              >
                <a-select-option v-for="item in hourDataSource" :key="item.id">
                  {{item.name}}
                </a-select-option>
              </a-select>
              <span style="color:#999">备注：选择了课时标签，只会在选中的标签中抓取题目，不填则从整个课程中抓取题目</span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="试卷说明：">
              <a-textarea
                v-decorator="['pExplain', { rules: [{  message: '请输入试卷说明' }] }]"
               placeholder="试卷说明"
               :auto-size="{ minRows: 3, maxRows: 5 }"
               />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="生成方式：">
              <a-radio-group @change="changeDoType" v-decorator="['doType']">
                <a-radio-button :value="2">
                  配置生成
                </a-radio-button>
                <a-radio-button :value="1">
                  随机生成
                </a-radio-button>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row v-if="doType==1" :gutter="16">
          <a-col :span="24">
            <a-form-item :label="`单选题(${sigleCntMax})：`">
              题目数量：<a-input-number
                v-decorator="['singleCnt',{ initialValue: 0 }]" :min="0" @change="changeQuests($event,'singleCnt')" :max="sigleCntMax"/>
              每题分值：<a-input-number 
                v-decorator="['singleCores1',{ initialValue: 2 }]" :min="1" :max="20" @change="changeQuests($event,'singleCores1')"/>
              题型占比：{{sigleP1}}%
            </a-form-item>
          </a-col>
        </a-row>
        <a-row  v-if="doType==1" :gutter="16">
          <a-col :span="24">
            <a-form-item :label="`多选题(${multipleCntMax})：`">
              题目数量：<a-input-number
                v-decorator="['multipleCnt',{ initialValue: 0 }]" :min="0" @change="changeQuests($event,'multipleCnt')" :max="multipleCntMax"/>
              每题分值：<a-input-number 
                v-decorator="['multipleCores1',{ initialValue: 2 }]" :min="1" :max="20"  @change="changeQuests($event,'multipleCores1')"/>
              题型占比：{{multipleP1}}%
            </a-form-item>
          </a-col>
        </a-row>
        <a-row  v-if="doType==1" :gutter="16">
          <a-col :span="24">
            <a-form-item :label="`判断题(${judgeCntMax})：`">
              题目数量：<a-input-number
                v-decorator="['judgeCnt',{ initialValue: 0 }]"  @change="changeQuests($event,'judgeCnt')" :min="0" :max="judgeCntMax"/>
              每题分值：<a-input-number 
                v-decorator="['judgeCores1',{ initialValue: 2 }]" :min="1" :max="20"  @change="changeQuests($event,'judgeCores1')"/>
              题型占比：{{judgeP1}}%
            </a-form-item>
          </a-col>
        </a-row>
        <a-row v-if="doType==2" :gutter="16">
          <a-col :span="24">
            <a-form-item label="单选题：">
              容易({{sigleQ.easyMax}})：<a-input-number
                v-decorator="['sigleSet.easy']" :min="0" @change="changeQuests($event,'sigleSetEasy')" :max="sigleQ.easyMax"/>
              一般({{sigleQ.commonMax}})：<a-input-number
                v-decorator="['sigleSet.common']" :min="0" @change="changeQuests($event,'sigleSetCommon')" :max="sigleQ.commonMax"/>
              困难({{sigleQ.difficultyMax}})：<a-input-number 
                v-decorator="['sigleSet.difficulty']"  @change="changeQuests($event,'sigleSetDifficulty')" :min="0" :max="sigleQ.difficultyMax"/>
              每题分值：<a-input-number 
                v-decorator="['singleCores']" :min="1" :max="20" @change="changeQuests($event,'singleCores')"/>
              占比：{{sigleP}}%
            </a-form-item>
          </a-col>
        </a-row>
        <a-row  v-if="doType==2" :gutter="16">
          <a-col :span="24">
            <a-form-item label="多选题：">
              容易({{multipleQ.easyMax}})：<a-input-number
                v-decorator="['multipleSet.easy']" :min="0" @change="changeQuests($event,'multipleSetEasy')" :max="multipleQ.easyMax"/>
              一般({{multipleQ.commonMax}})：<a-input-number
                v-decorator="['multipleSet.common']" @change="changeQuests($event,'multipleSetCommon')" :min="0" :max="multipleQ.commonMax"/>
              困难({{multipleQ.difficultyMax}})：<a-input-number 
                v-decorator="['multipleSet.difficulty']" @change="changeQuests($event,'multipleSetDifficulty')" :min="0" :max="multipleQ.difficultyMax"/>
              每题分值：<a-input-number 
                v-decorator="['multipleCores']" :min="1" :max="20"  @change="changeQuests($event,'multipleCores')"/>
              占比：{{multipleP}}%
            </a-form-item>
          </a-col>
        </a-row>
        <a-row  v-if="doType==2" :gutter="16">
          <a-col :span="24">
            <a-form-item label="判断题：">
              容易({{judgeQ.easyMax}})：<a-input-number
                v-decorator="['judgeSet.easy']"  @change="changeQuests($event,'judgeSetEasy')" :min="0" :max="judgeQ.easyMax"/>
              一般({{judgeQ.commonMax}})：<a-input-number
                v-decorator="['judgeSet.common']" @change="changeQuests($event,'judgeSetCommon')" :min="0" :max="judgeQ.commonMax"/>
              困难({{judgeQ.difficultyMax}})：<a-input-number 
                v-decorator="['judgeSet.difficulty']"   @change="changeQuests($event,'judgeSetDifficulty')" :min="0" :max="judgeQ.difficultyMax"/>
              每题分值：<a-input-number 
                v-decorator="['judgeCores']" :min="1" :max="20"  @change="changeQuests($event,'judgeCores')"/>
              占比：{{judgeP}}%
            </a-form-item>
          </a-col>
        </a-row>
        <div  v-if="doType==2" style="margin-left:20%;width:90%"><span>总题目数：{{totalQuest2}}题</span><span style="margin-left:200px">总分数：{{totalCores2}}分</span></div>
        <div  v-if="doType==1" style="margin-left:20%;width:90%"><span>总题目数：{{totalQuest1}}题</span><span style="margin-left:200px">总分数：{{totalCores1}}分</span></div>
      
      </a-form>
      <div style="width:100%;text-align: center; margin-top: 35px;">
        <a-button :style="{ marginLeft: '8px' }" type="primary" @click="nextStep">
          保存，下一步
        </a-button>
      </div>
    </div>
    <div v-show="current==1">
      <div>
        <!-- <a-divider>试卷题目<a @click="changeQuests" style="margin-left:10px;font-size:13px;color:blue">题目编辑</a></a-divider> -->
        <a-divider>试卷题目</a-divider>
          <div style="width:98%;">
            <a-tabs :activeKey="currentTab" @change="callback">
              <a-tab-pane :key="1">
                <template slot="tab">单选题（{{sigleQuests.length}}题/{{sigleCore}}分）</template>
                <vue-scroll :ops="ops" style="width:100%;height:520px">
                <a-list size="small" :data-source="sigleQuests">
                  <a-list-item slot="renderItem" slot-scope="item, index">
                    {{index+1}}、【{{item.level==1?'简单':item.level==2?"一般":"困难"}}】{{ item.questCont }}({{item.newcore}}分)
                    <a slot="actions" v-if="index !== 0" @click="upClick(1,index)"><a-icon type="arrow-up" title="上移" /></a>
                    <a slot="actions" v-if="index !== sigleQuests.length - 1" @click="downClick(1,index)"><a-icon type="arrow-down" title="下移"/></a>
                    <!-- <a slot="actions" v-if="index !== 0" @click="topClick(1,index)"><a-icon type="vertical-align-top" title="置顶" /></a>
                    <a slot="actions" v-if="index !== sigleQuests.length - 1"  @click="bottomClick(1,index)"><a-icon type="vertical-align-bottom" title="置底"/></a> -->
                  </a-list-item>
                </a-list>
                </vue-scroll>
              </a-tab-pane>
              <a-tab-pane :key="2" force-render>
                <template slot="tab">多选题（{{multipleQuests.length}}题/{{multipleCore}}分）</template>
                <vue-scroll :ops="ops" style="width:100%;height:520px">
                <a-list size="small" :data-source="multipleQuests">
                  <a-list-item slot="renderItem" slot-scope="item, index">
                    {{index+1}}、【{{item.level==1?'简单':item.level==2?"一般":"困难"}}】{{ item.questCont }}({{item.newcore}}分)
                    <a slot="actions" v-if="index !== 0" @click="upClick(2,index)"><a-icon type="arrow-up" title="上移" /></a>
                    <a slot="actions" v-if="index !== multipleQuests.length - 1" @click="downClick(2,index)"><a-icon type="arrow-down" title="下移"/></a>
                    <!-- <a slot="actions" v-if="index !== 0" @click="topClick(2,index)"><a-icon type="vertical-align-top" title="置顶" /></a>
                    <a slot="actions" v-if="index !== multipleQuests.length - 1"  @click="bottomClick(2,index)"><a-icon type="vertical-align-bottom" title="置底"/></a> -->
                  </a-list-item>
                </a-list>
                </vue-scroll>
              </a-tab-pane>
              <a-tab-pane :key="3">
                <template slot="tab">判断题（{{judgeQuests.length}}题/{{judgeCore}}分）</template>
                <vue-scroll :ops="ops" style="width:100%;height:520px">
                <a-list size="small" :data-source="judgeQuests">
                  <a-list-item slot="renderItem" slot-scope="item, index">
                    {{index+1}}、【{{item.level==1?'简单':item.level==2?"一般":"困难"}}】{{ item.questCont }}({{item.newcore}}分)
                    <a slot="actions" v-if="index !== 0" @click="upClick(3,index)"><a-icon type="arrow-up" title="上移" /></a>
                    <a slot="actions" v-if="index !== judgeQuests.length - 1" @click="downClick(3,index)"><a-icon type="arrow-down" title="下移"/></a>
                    <!-- <a slot="actions" v-if="index !== 0" @click="topClick(3,index)"><a-icon type="vertical-align-top" title="置顶" /></a>
                    <a slot="actions" v-if="index !== judgeQuests.length - 1"  @click="bottomClick(3,index)"><a-icon type="vertical-align-bottom" title="置底"/></a> -->
                  </a-list-item>
                </a-list>
                </vue-scroll>
              </a-tab-pane>
            </a-tabs>
        </div>
      </div>
      <div style="width:100%;text-align: center; margin-top: 35px;">
        <a-button :style="{ marginLeft: '8px' }" type="primary" @click="doSubmit">
          好的，生成试卷
        </a-button>
      </div>
    </div>
    </a-drawer>
  </div>
</template>
<script>
import { request } from "@/utils/request";
import {ONLINE_QUEST_LEVEL_QUERY,ONLINE_QUERY_COURSE_GETALL,ONLINE_AUTO_SETTING_ADD,ONLINE_AUTO_SETTING_UPDATE,ONLINE_AUTO_FEACH_QUEST,ONLINE_CLASS_HOUR_GETALL} from "@/services/api";
export default {
  name: "auroPaper",
  components: {},
  data() {
    return {
      form: this.$form.createForm(this),
      current:0,
      sigleSetEasy:0,
      sigleSetCommon:0,
      sigleSetDifficulty:0,
      multipleSetEasy:0,
      multipleSetCommon:0,
      multipleSetDifficulty:0,
      judgeSetEasy:0,
      judgeSetCommon:0,
      judgeSetDifficulty:0,
      singleCores:2,
      multipleCores:2,
      judgeCores:2,
      singleCnt:0,
      multipleCnt:0,
      judgeCnt:0,
      hourTags:null,
      singleCores1:2,
      multipleCores1:2,
      judgeCores1:2,
      courseDataSource:[],
      currentTab:1,
      sigleQuests:[],
      multipleQuests:[],
      judgeQuests:[],
      hourDataSource:[],
      sigleQ:{
        easyMax:0,
        commonMax:0,
        difficultyMax:0
      },
      autoObj:{},
      doType:2,
      multipleQ:{
        easyMax:0,
        commonMax:0,
        difficultyMax:0
      },
      judgeQ:{
        easyMax:0,
        commonMax:0,
        difficultyMax:0
      },
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: "#3ec13e",//滚动条颜色
          opacity: 0.8,//滚动条透明度
          "overflow-x": "hidden"
        }
      }
    };
  },
  watch:{
    visible(val){
      if(val){
        setTimeout(()=>{
          this.form.setFieldsValue({
            'sigleSet.easy': 0,
            'sigleSet.common': 0,
            'sigleSet.difficulty':0,
            'singleCores': 2,
            'multipleSet.easy': 0,
            'multipleSet.common': 0,
            'multipleSet.difficulty':0,
            'multipleCores': 2,
            'judgeSet.easy': 0,
            'judgeSet.common': 0,
            'judgeSet.difficulty':0,
            'judgeCores': 2,
            'doType': 2,
            'singleCnt':0,
            'multipleCnt':0,
            'judgeCnt':0,
            'courseId': this.oldCourseId?this.oldCourseId+"":null
          })
        })
        if(this.oldCourseId){
          this.doQueryMax(this.oldCourseId);
        }
      }
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    oldCourseId:{
      type:String
    }
  },
  computed:{
    totalQuest2() {
      let s = this.sigleSetEasy+this.sigleSetCommon+this.sigleSetDifficulty;  //单选题题目总数
      let m = this.multipleSetEasy+this.multipleSetCommon+this.multipleSetDifficulty;  //多选题题目总数
      let j = this.judgeSetEasy+this.judgeSetCommon+this.judgeSetDifficulty;  //判断题题目总数
      return s+m+j
    },
    totalCores2() {
      let s = this.sigleSetEasy+this.sigleSetCommon+this.sigleSetDifficulty;  //单选题题目总数
      let m = this.multipleSetEasy+this.multipleSetCommon+this.multipleSetDifficulty;  //多选题题目总数
      let j = this.judgeSetEasy+this.judgeSetCommon+this.judgeSetDifficulty;  //判断题题目总数 
      return s*this.singleCores+m*this.multipleCores+j*this.judgeCores
    },
    totalQuest1() {
      let s = this.singleCnt+this.multipleCnt+this.judgeCnt;
      return s
    },
    totalCores1() {
      let s = this.singleCnt*this.singleCores1+this.multipleCnt*this.multipleCores1+this.judgeCnt*this.judgeCores1; 
      return s
    },
    sigleP(){
      let s = this.sigleSetEasy+this.sigleSetCommon+this.sigleSetDifficulty;  //单选题题目总数
      if(s&&s>0){
        return (s/this.totalQuest2*100).toFixed(2);
      }else{
        return 0;
      }
    },
    multipleP(){
      let m = this.multipleSetEasy+this.multipleSetCommon+this.multipleSetDifficulty;  //多选题题目总数
      if(m&&m>0){
        return (m/this.totalQuest2*100).toFixed(2);
      }else{
        return 0;
      }
    },
    judgeP(){
      let j = this.judgeSetEasy+this.judgeSetCommon+this.judgeSetDifficulty;  //判断题题目总数
      if(j&&j>0){
        return (j/this.totalQuest2*100).toFixed(2);
      }else{
        return 0;
      }
    },
    sigleP1(){
      let s = this.singleCnt+this.multipleCnt+this.judgeCnt;
      if(this.singleCnt&&this.singleCnt>0){
        return (this.singleCnt/s*100).toFixed(2);
      }else{
        return 0;
      }
    },
    multipleP1(){
      let s = this.singleCnt+this.multipleCnt+this.judgeCnt;
      if(this.multipleCnt&&this.multipleCnt>0){
        return (this.multipleCnt/s*100).toFixed(2);
      }else{
        return 0;
      }
    },
    judgeP1(){
      let s = this.singleCnt+this.multipleCnt+this.judgeCnt;
      if(this.judgeCnt&&this.judgeCnt>0){
        return (this.judgeCnt/s*100).toFixed(2);
      }else{
        return 0;
      }
    },
    sigleCore(){
      if(this.doType==1){
        return this.singleCnt*this.singleCores1;
      }else{
        let s = this.sigleSetEasy+this.sigleSetCommon+this.sigleSetDifficulty;  //单选题题目总数
        return this.singleCores*s;
      }
      
    },
    multipleCore(){
      if(this.doType==1){
        return this.multipleCnt*this.multipleCores1
      }else{
        let m = this.multipleSetEasy+this.multipleSetCommon+this.multipleSetDifficulty;  //多选题题目总数
        return this.multipleCores*m;
      }
    },
    judgeCore(){
      if(this.doType==1){
        return this.judgeCnt*this.judgeCores1;
      }else{
        let j = this.judgeSetEasy+this.judgeSetCommon+this.judgeSetDifficulty;  //判断题题目总数 
        return this.judgeCores*j;
      }
    },
    sigleCntMax(){
      return this.sigleQ.easyMax+this.sigleQ.commonMax+this.sigleQ.difficultyMax
    },
    multipleCntMax(){
      return this.multipleQ.easyMax+this.multipleQ.commonMax+this.multipleQ.difficultyMax;
    },
    judgeCntMax(){
      return this.judgeQ.easyMax+this.judgeQ.commonMax+this.judgeQ.difficultyMax;
    },
    tagsArray:{
      get(){
        if(this.hourTags!=null&&this.hourTags!=''){
          return this.hourTags.split(',');
        }else{
          return [];
        }
      },
      set(v) {
        this.hourTags = v
      }
    }

  },
  created(){
    this.doQueryCourse();
  },
  mounted(){
     this.doQueryTypeLevelMax();
  },
  methods: {
    doQueryTypeLevelMax(){
      var course
      if(this.oldCourseId){
        course = this.oldCourseId
      }else{
        course = this.form.getFieldValue('courseId');
      }
      this.doQueryMax(course);
    },
    handleChange(values){
      this.hourTags = values.join(",");
      var course
      if(this.oldCourseId){
        course = this.oldCourseId
      }else{
        course = this.form.getFieldValue('courseId');
      }
      this.doQueryMax(course)
    },
    doQueryCourseHours(courseId){
      request(ONLINE_CLASS_HOUR_GETALL,"post",{courseId:courseId}).then(res => {
          if(res.data.code==0){
           this.hourDataSource = res.data.data;
          }
       })
    },
    doQueryMax(course){
      if(course){
        let obj = {
          courseId:course,
          hourTags:this.tagsArray
        }
        request(ONLINE_QUEST_LEVEL_QUERY,"post",obj).then(res => {
          if(res.data.code==0){
            this.sigleQ.easyMax=0;
            this.sigleQ.commonMax=0;
            this.sigleQ.difficultyMax=0;
            this.multipleQ.easyMax=0;
            this.multipleQ.commonMax=0;
            this.multipleQ.difficultyMax=0;
            this.judgeQ.easyMax=0;
            this.judgeQ.commonMax=0;
            this.judgeQ.difficultyMax=0;
            res.data.data.forEach(obj=>{
              if(obj.isMany==1&&obj.level==1){
                this.sigleQ.easyMax = obj.cnt;
              }
              if(obj.isMany==1&&obj.level==2){
                this.sigleQ.commonMax = obj.cnt;
              }
              if(obj.isMany==1&&obj.level==3){
                this.sigleQ.difficultyMax = obj.cnt;
              }
              if(obj.isMany==2&&obj.level==1){
                this.multipleQ.easyMax = obj.cnt;
              }
              if(obj.isMany==2&&obj.level==2){
                this.multipleQ.commonMax = obj.cnt;
              }
              if(obj.isMany==2&&obj.level==3){
                this.multipleQ.difficultyMax = obj.cnt;
              }
              if(obj.isMany==3&&obj.level==1){
                this.judgeQ.easyMax = obj.cnt;
              }
              if(obj.isMany==3&&obj.level==2){
                this.judgeQ.commonMax = obj.cnt;
              }
              if(obj.isMany==3&&obj.level==3){
                this.judgeQ.difficultyMax = obj.cnt;
              }
            })
          }
       })
      }else{
        this.sigleQ.easyMax=0;
        this.sigleQ.commonMax=0;
        this.sigleQ.difficultyMax=0;
        this.multipleQ.easyMax=0;
        this.multipleQ.commonMax=0;
        this.multipleQ.difficultyMax=0;
        this.judgeQ.easyMax=0;
        this.judgeQ.commonMax=0;
        this.judgeQ.difficultyMax=0;
      }
    },
    doQueryCourse(){
      request(ONLINE_QUERY_COURSE_GETALL,"post",{}).then(res => {
          if(res.data.code==0){
           this.courseDataSource = res.data.data.list;
          }
       })
    },
    changeCourse(value){
      this.hourTags = '';
      this.doQueryMax(value);
      this.doQueryCourseHours(value);
      this.sigleQ.easyMax=0;
      this.sigleQ.commonMax=0;
      this.sigleQ.difficultyMax=0;
      this.multipleQ.easyMax=0;
      this.multipleQ.commonMax=0;
      this.multipleQ.difficultyMax=0;
      this.judgeQ.easyMax=0;
      this.judgeQ.commonMax=0;
      this.judgeQ.difficultyMax=0;
    },
    nextStep(){
       //数据校验
      this.form.validateFields((err, values) => {
        if (!err) {
          if(this.doType==2&&this.totalQuest2<=0){
            this.$message.error("请您填写需要生成试卷的题目个数")
            return false;
          }
          if(this.doType==1&&this.totalQuest1<=0){
            this.$message.error("请您填写需要生成试卷的题目个数")
            return false;
          }
          if(this.doType==2){
             //保存数据配置记录信息
            if(values.judgeSet){
              values.judgeSet = JSON.stringify(values.judgeSet);
            }
            if(values.multipleSet){
              values.multipleSet = JSON.stringify(values.multipleSet);
            }
            if(values.sigleSet){
              values.sigleSet = JSON.stringify(values.sigleSet);
            }
          }

          if(this.doType==1){
             //保存数据配置记录信息
            if(values.judgeSet){
              values.judgeSet = JSON.stringify(values.judgeSet);
            }
            if(values.multipleSet){
              values.multipleSet = JSON.stringify(values.multipleSet);
            }
            if(values.sigleSet){
              values.sigleSet = JSON.stringify(values.sigleSet);
            }
          }
         
          let path = ONLINE_AUTO_SETTING_ADD
          if(this.autoObj&&this.autoObj.id){
            values.id=this.autoObj.id
            path = ONLINE_AUTO_SETTING_UPDATE;
          }
          
          this.$set(values,"hourTags",this.hourTags);
          request(path,"post",values).then(res => {
            if(res.data.code==0){
              this.autoObj = res.data.data;
              this.doFeachQuests();
              this.current=1;
            }
          })
        }else{
          return false;
        }
      })
    },
    doFeachQuests(){
      if(this.autoObj&&this.autoObj.id){
        request(ONLINE_AUTO_FEACH_QUEST,"post",this.autoObj).then(res => {
          if(res.data.code==0){
            this.judgeQuests = res.data.data.judgeQuestsInfo;
            this.judgeQuests.forEach(o=>{
              if(this.autoObj.doType==1){
                this.$set(o,'newcore',this.judgeCores1)
              }else{
                this.$set(o,'newcore',this.judgeCores)
              }
              
            })
            this.multipleQuests = res.data.data.multipleQuests;
            this.multipleQuests.forEach(o=>{
              if(this.autoObj.doType==1){
                this.$set(o,'newcore',this.multipleCores1)
              }else{
                this.$set(o,'newcore',this.multipleCores)
              }
            })
            this.sigleQuests = res.data.data.sigleQuests;
            this.sigleQuests.forEach(o=>{
              if(this.autoObj.doType==1){
                this.$set(o,'newcore',this.singleCores1)
              }else{
                this.$set(o,'newcore',this.singleCores)
              }
            })
          }
        })
      }
    },
    preBack(){

    },
    onClose(){
      this.$emit("closeAutoSetting");
    },
    doSubmit(){
      //数据校验
      let autoPaper = {};
      if(this.sigleQuests.length<=0&&this.multipleQuests.length<=0&&this.judgeQuests.length<=0){
        this.$message.error("题目数量为空，请在对应课程中的题库中新增题目并正确配置生成规则！")
        return ;
      }
      if(this.sigleQuests.length>0){
        this.sigleQuests.forEach((obj,index)=>{
          this.$set(obj,"qtIndex",(index+1))
          this.$set(obj,"qtType",1);
          this.$set(obj,"questId",obj.id);
          obj.core = obj.newcore?obj.newcore:obj.core
        })
        autoPaper.sigleQuests = this.sigleQuests;
      }
      if(this.multipleQuests.length>0){
        this.multipleQuests.forEach((obj,index)=>{
          this.$set(obj,"qtIndex",(index+1))
          this.$set(obj,"qtType",2)
          this.$set(obj,"questId",obj.id);
          obj.core = obj.newcore?obj.newcore:obj.core
        })
        autoPaper.multipleQuests = this.multipleQuests;
      }
      if(this.judgeQuests.length>0){
        this.judgeQuests.forEach((obj,index)=>{
          this.$set(obj,"qtIndex",(index+1))
          this.$set(obj,"qtType",3)
          this.$set(obj,"questId",obj.id);
          obj.core = obj.newcore?obj.newcore:obj.core
        })
        autoPaper.judgeQuests = this.judgeQuests;
      }
      autoPaper.autoObj = this.autoObj;
      this.$emit("submit",autoPaper);
    },
    changeQuests(e,ct){
      this[ct] = e;
    },
    changeDoType(e){
      this.doType = e.target.value;
    },
    callback(key){
      this.currentTab = key;
    },
    // 上移
    upClick(type,index) {
      if(type==1){
        let newArr = this.swapItems(this.sigleQuests, index, index - 1)
        this.sigleQuests = newArr
      }else if(type==2){
        let newArr = this.swapItems(this.multipleQuests, index, index - 1)
        this.multipleQuests = newArr
      }else if(type==3){
        let newArr = this.swapItems(this.judgeQuests, index, index - 1)
        this.judgeQuests = newArr
      }
    },
    // 下移
    downClick(type,index) {
     if(type==1){
        let newArr = this.swapItems(this.sigleQuests, index, index + 1)
        this.sigleQuests = newArr
      }else if(type==2){
        let newArr = this.swapItems(this.multipleQuests, index, index + 1)
        this.multipleQuests = newArr
      }else if(type==3){
        let newArr = this.swapItems(this.judgeQuests, index, index + 1)
        this.judgeQuests = newArr
      }
    },
    topClick(type,index){
      if(type==1){
        let newArr = this.swapItems(this.sigleQuests, index, 0)
        this.sigleQuests = newArr
      }else if(type==2){
        let newArr = this.swapItems(this.multipleQuests, index, 0)
        this.multipleQuests = newArr
      }else if(type==3){
        let newArr = this.swapItems(this.judgeQuests, index, 0)
        this.judgeQuests = newArr
      }
    },
    bottomClick(type,index){
      if(type==1){
        let newArr = this.swapItems(this.sigleQuests, index, this.sigleQuests.length-1)
        this.sigleQuests = newArr
      }else if(type==2){
        let newArr = this.swapItems(this.multipleQuests, index, this.multipleQuests.length-1)
        this.multipleQuests = newArr
      }else if(type==3){
        let newArr = this.swapItems(this.judgeQuests, index, this.judgeQuests.length-1)
        this.judgeQuests = newArr
      }
    },
    swapItems(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      return arr;
    },
  }
};
</script>