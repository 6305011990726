var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "docTable" },
    [
      _c("form-index", {
        ref: "formindex",
        attrs: {
          formData: _vm.formData,
          flexAction: true,
          baiduMapBoolen: false,
        },
        on: { handleSubmit: _vm.handleSubmit },
        scopedSlots: _vm._u([
          {
            key: "flexAction",
            fn: function () {
              return [
                _c(
                  "a-space",
                  { style: { marginTop: "4px" } },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: {
                          icon: "search",
                          type: "primary",
                          "html-type": "submit",
                        },
                      },
                      [_vm._v("查询")]
                    ),
                    _c("a-button", { on: { click: _vm.close } }, [
                      _vm._v(" 重置 "),
                    ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.canOperate
        ? _c(
            "a-button",
            {
              staticStyle: { "margin-right": "8px" },
              attrs: { icon: "plus", type: "primary" },
              on: { click: _vm.addVersion },
            },
            [_vm._v("新 增")]
          )
        : _vm._e(),
      _c("standard-table", {
        staticClass: "mt-1",
        attrs: {
          columns: _vm.columns,
          dataSource: _vm.dataSource,
          rowKey: "id",
          pagination: _vm.pagination,
          loading: _vm.tableLoading,
          isAlert: false,
          bordered: true,
        },
        on: { change: _vm.onPageChange },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function (ref) {
              var index = ref.index
              return [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        (_vm.pagination.current - 1) * _vm.pagination.pageSize +
                          parseInt(index) +
                          1
                      ) +
                      " "
                  ),
                ]),
              ]
            },
          },
          {
            key: "type",
            fn: function (ref) {
              var text = ref.text
              return [_vm._v(_vm._s(_vm.VERSION_TYPE_ZHCN_MAP[text]))]
            },
          },
          {
            key: "action",
            fn: function (ref) {
              var record = ref.record
              return _c("div", {}, [
                _c(
                  "div",
                  [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.showVersion(record)
                          },
                        },
                      },
                      [_vm._v("查看")]
                    ),
                    _vm.canOperate
                      ? _c("a-divider", { attrs: { type: "vertical" } })
                      : _vm._e(),
                    _vm.canOperate
                      ? _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.editVersion(record)
                              },
                            },
                          },
                          [_vm._v("修改")]
                        )
                      : _vm._e(),
                    _vm.canOperate
                      ? _c("a-divider", { attrs: { type: "vertical" } })
                      : _vm._e(),
                    _vm.canOperate
                      ? _c(
                          "a",
                          {
                            staticStyle: { color: "red" },
                            on: {
                              click: function ($event) {
                                return _vm.delVersion(record)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            },
          },
        ]),
      }),
      _c(
        "a-modal",
        {
          attrs: {
            mask: true,
            centered: "",
            width: 1050,
            title: "#更新通知 " + (_vm.version.vcode ? _vm.version.vcode : ""),
            footer: null,
          },
          on: { cancel: _vm.closeVersion },
          model: {
            value: _vm.versionMotal,
            callback: function ($$v) {
              _vm.versionMotal = $$v
            },
            expression: "versionMotal",
          },
        },
        [
          _c("p", [
            _vm._v(
              _vm._s(
                _vm.moment(_vm.version.vtime).format("YYYY-MM-DD hh:mm:ss")
              )
            ),
          ]),
          _c("span", { domProps: { innerHTML: _vm._s(_vm.version.vcontent) } }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }