var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-card",
        { attrs: { title: "项目安全生产隐患排查治理情况" } },
        [
          _c(
            "a-form-model",
            { attrs: { layout: "horizontal", model: _vm.params } },
            [
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "状态：",
                            labelCol: { span: 2 },
                            wrapperCol: { span: 5 },
                          },
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.statusName))])]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "项目：",
                            labelCol: { span: 2 },
                            wrapperCol: { span: 5 },
                          },
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              placeholder: "请输入项目",
                              disabled: "",
                              allowClear: "",
                            },
                            model: {
                              value: _vm.params.projectName,
                              callback: function ($$v) {
                                _vm.$set(_vm.params, "projectName", $$v)
                              },
                              expression: "params.projectName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "一般隐患数：",
                            labelCol: { span: 6 },
                            wrapperCol: { span: 15 },
                          },
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              placeholder: "暂无",
                              disabled: "",
                              allowClear: "",
                            },
                            model: {
                              value: _vm.params.problemNumsNormal,
                              callback: function ($$v) {
                                _vm.$set(_vm.params, "problemNumsNormal", $$v)
                              },
                              expression: "params.problemNumsNormal",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "一般隐患已整改数：",
                            labelCol: { span: 6 },
                            wrapperCol: { span: 11 },
                          },
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              placeholder: "暂无",
                              allowClear: "",
                              disabled: "",
                            },
                            model: {
                              value: _vm.params.problemNumsNormalDone,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.params,
                                  "problemNumsNormalDone",
                                  $$v
                                )
                              },
                              expression: "params.problemNumsNormalDone",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "重大隐患数：",
                            labelCol: { span: 6 },
                            wrapperCol: { span: 15 },
                          },
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              placeholder: "暂无",
                              disabled: "",
                              allowClear: "",
                            },
                            model: {
                              value: _vm.params.problemNumsSerious,
                              callback: function ($$v) {
                                _vm.$set(_vm.params, "problemNumsSerious", $$v)
                              },
                              expression: "params.problemNumsSerious",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "重大隐患已整改数：",
                            labelCol: { span: 6 },
                            wrapperCol: { span: 11 },
                          },
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              placeholder: "暂无",
                              allowClear: "",
                              disabled: "",
                            },
                            model: {
                              value: _vm.params.problemNumsSeriousDone,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.params,
                                  "problemNumsSeriousDone",
                                  $$v
                                )
                              },
                              expression: "params.problemNumsSeriousDone",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { "wrapper-col": { offset: 11 } } },
                [
                  _c("a-button", { on: { click: _vm.close } }, [
                    _vm._v(" 关闭 "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }