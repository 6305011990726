<template>
  <a-modal
  forceRender
  centered
  :maskClosable="false"
  v-model="visibleExit"
  :title="visibleTitle"
  @cancel="handleCancel"
  @ok="modelOk"
  :width="700"
  >
    <form-index
      ref="formindex"
      :formData="formData"
    >
    </form-index>
  </a-modal>
</template>

<script>

import formIndex from "@/pages/components/form/index";
import { mapGetters } from "vuex";
import {
  getOfficesSelfPage
} from "@/services/sys";
import {
  createUser,
  updateUser,
  getUser
} from "@/services/sys";
export default {
  name: 'SuijianClientSystemUserModal',
  components: {
    formIndex
  },
  props: {
    visible:{
      type:Boolean,
      default:false
    },
    visibleTitle:{
      type:String,
      default: '新增用户'
    },
    userId: {
      type: [String,Number],
    }
  },
  watch: {
    visible: {
      handler(val, oldName) {
        this.visibleExit = val
        this.getOfficeTree()
        if (this.visibleTitle == '新增用户') {
          this.$nextTick(() => {
            this.formData.forEach(el => {
              if (el.key === 'password' || el.key === 'confimPassword') {
                el.decorator.forEach((items) => {
                  if (items instanceof Object) {
                    items.rules[0].required = true;
                  }
                })
              }
            })
            this.$refs["formindex"].resetFields()
          })
        } else if (this.userId) {
          this.getUser(this.userId)
        }
      }
    }
  },
  data() {
    const formData = [
      {
        label: "所属部门",
        placeholder: "请选择所属部门",
        decorator: [
          "deptId",
          { rules: [{ required: true, message: "请选择所属部门" }] },
        ],
        type: "treeSelect",
        key: "deptId",
        selectlist: [],
        col: 24,
        labelCol: 6,
        wrapperCol: 14,
        display: true,
        showSearch: true
      },
      {
        label: "姓名",
        placeholder: "请输入姓名",
        decorator: [
          "nickname",
          { rules: [{ required: true, message: "请输入姓名" }] },
        ],
        type: "input",
        key: "nickname",
        col: 24,
        labelCol: 6,
        wrapperCol: 14,
      },
      {
        label: "账号",
        placeholder: "请输入手机账号",
        decorator: [
          "username",
          { rules: [{ required: true, message: "请输入手机账号" }] },
        ],
        type: "input",
        inputType: "number",
        key: "username",
        col: 24,
        labelCol: 6,
        wrapperCol: 14,
        maxLength: 11
      },
      {
        label: "状态",
        placeholder: "请选择状态",
        decorator: [
          "status",
          { rules: [{ required: true, message: "请选择状态" }] },
        ],
        type: "select",
        selectlist: [
          {
            name: '开启',
            value: 0
          },
          {
            name: '关闭',
            value: 1
          }
        ],
        key: "status",
        col: 24,
        labelCol: 6,
        wrapperCol: 14,
      },
      {
        label: "密码",
        placeholder: "密码必须包含数字、大小写字母、特殊字符，且长度至少8位",
        decorator: [
          "password",
          { rules: [
            {
              required: true,
              message: '密码必须包含数字、小写字母、大写字母、特殊字符，且长度至少8位',
              pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[~!@#$%^&*.)(_+}{|:?><]).{8,16}$/
            },
            {validator: this.validatePassword}
            ]
          },
        ],
        type: "inputPassWord",
        key: "password",
        col: 24,
        labelCol: 6,
        wrapperCol: 14,
      },
      {
        label: "确认密码",
        placeholder: "请输入确认密码",
        decorator: [
          "confimPassword",
          { rules: [{ required: true,message: '请输入确认密码' },{validator: this.validateUserPassword}] },
        ],
        type: "inputPassWord",
        key: "confimPassword",
        col: 24,
        labelCol: 6,
        wrapperCol: 14,
      },
      {
        label: "备注",
        placeholder: "请输入备注",
        decorator: [
          "remark",
          { rules: [{ required: false, message: "请输入备注" }] },
        ],
        type: "input",
        key: "remark",
        col: 24,
        labelCol: 6,
        wrapperCol: 14,
      },
    ]
    return {
      formData:formData,
      visibleExit: this.visible,
      // projectList:[]
    };
  },

  mounted() {
    this.getOfficeTree()
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },

  methods: {
    handleCancel() {
      this.$emit("handleCancel")
    },
    getUser(userId) {
      getUser({id: userId}).then(res => {
        console.log(res,'用户信息')
        if (res.data.code == 0) {
          this.$nextTick(() => {
            this.formData.forEach(el => {
              if (el.key === 'password' || el.key === 'confimPassword') {
                el.decorator.forEach((items) => {
                  if (items instanceof Object) {
                    items.rules[0].required = false;
                  }
                })
              }
            })
            this.$refs.formindex.queryObj(res.data.data)
          })
        }
      })
    },
    // 确认弹窗
    modelOk(e) {
      this.$refs["formindex"].combinationSubmit().then((res) => {
        const params = {
          ...res,
        };
        // 手机号 取值 账号
        params.mobile = params.username

        if (this.visibleTitle == '新增用户') {
          createUser(params).then(result => {
            if (result.data.code === 0) {
              this.$message.success('新增成功，请分配角色', 10);
              this.handleCancel();
            }
          })
        } else {
          params.id = this.userId
          updateUser(params).then(result => {
            if (result.data.code === 0) {
              this.$message.success('更新成功', 10);
              this.handleCancel();
            }
          })
        }
      });
    },
    getOfficeTree() {
      getOfficesSelfPage({officeId: this.user.officeId,pageSize: 9999}).then((res) => {
        this.formDataFn("deptId", res.data.data.list);
      });
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      this.formData.forEach((item) => {
        if (item.key == key) {
          item.selectlist = data;
        }
      });
    },
    validatePassword(rule, value, callback) {
      if (value && /(\d)\1\1/.test(value)) {
          callback(new Error('密码不能包含三个连续的相同数字'));
      }
      else if (value && !this.isConsecutiveNumbers(value)) {
        callback(new Error('不能连续数字（如1234）连续4位或4位以上'));
      }
      else {
        // 密码符合要求
        callback();
      }
    },
    validateUserPassword(rule, value, callback) {
        // 自定义校验规则
        let obj = this.$refs["formindex"].getFieldValue();
        if (value && value != obj.password) {
            callback(new Error('确认密码不一致'));
        }
        // else if (value && value == oldPwd) {
        //     callback(new Error('新密码不能和旧密码一致'));
        // }
        else {
            callback();
        }
    },
    // 判断是否包含连续数字 (如1234)
    isConsecutiveNumbers(str) {
        let arr = str.split('')
        let flag = true
        for (let i = 1; i < arr.length - 2; i++) {
        let firstIndex = arr[i - 1].charCodeAt()
        let secondIndex = arr[i].charCodeAt()
        let thirdIndex = arr[i + 1].charCodeAt()
        let fourIndex = arr[i + 2].charCodeAt()
        // 是否都为数值
        const allNumber = [
        firstIndex,
        secondIndex,
        thirdIndex,
        fourIndex
        ].every((i) => this.isNumeric(i))

        if (allNumber && (fourIndex - thirdIndex == 1) && (thirdIndex - secondIndex == 1) && (secondIndex - firstIndex == 1)) {
            console.log('为弱口令密码')
            flag = false
            break; // 如果已经发现连续数字，提前结束循环
        }
        }
        return flag
    },
    // 根据Unicode 编码判断是否为数字
    isNumeric(charCode) {
        return charCode >= 48 && charCode <= 57
    }
  },
};
</script>
