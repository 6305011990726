var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    [_c("companyTeams", { attrs: { officeId: _vm.user.officeId } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }