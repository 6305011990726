var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("form-index", {
        ref: "formindex",
        attrs: {
          formData: _vm.formData,
          projectList: _vm.projectList,
          flexSubmit: true,
        },
        on: {
          handleSubmit: _vm.handleSubmit,
          handlSelect: _vm.handlSelect,
          handleChange: _vm.handleChange,
        },
        scopedSlots: _vm._u([
          {
            key: "flexSubmit",
            fn: function () {
              return [
                _c(
                  "a-space",
                  {
                    staticStyle: { "margin-left": "90px", "margin-top": "5px" },
                  },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: {
                          icon: "search",
                          type: "primary",
                          "html-type": "submit",
                        },
                      },
                      [_vm._v(" 查询 ")]
                    ),
                    _c(
                      "a-space",
                      { staticClass: "operator" },
                      [
                        _c("a-button", { on: { click: _vm.close } }, [
                          _vm._v(" 重置 "),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "a_a_footer", staticStyle: { "margin-top": "-20px" } },
        [
          _c(
            "div",
            { staticClass: "mb-1 mt-2" },
            [
              _c(
                "a-space",
                { staticClass: "operator" },
                [
                  _c(
                    "a-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.batchEntrance(1)
                        },
                      },
                    },
                    [
                      _c("a-icon", { attrs: { type: "login" } }),
                      _vm._v("批量重新入场"),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-space",
                { staticClass: "operator" },
                [
                  _vm.canJwOperate
                    ? _c(
                        "a-button",
                        { on: { click: _vm.uploadJw } },
                        [
                          _c("a-icon", { attrs: { type: "file-add" } }),
                          _vm._v("申请导入建委"),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "a-space",
                { staticClass: "operator" },
                [
                  _c(
                    "a-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.delAccess()
                        },
                      },
                    },
                    [
                      _c("a-icon", { attrs: { type: "user-delete" } }),
                      _vm._v("批量删除考勤权限"),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-space",
                { staticClass: "operator" },
                [
                  _c(
                    "a-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.exportStaff()
                        },
                      },
                    },
                    [
                      _c("a-icon", { attrs: { type: "download" } }),
                      _vm._v("导出全部信息"),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-space",
                { staticClass: "operator" },
                [
                  _c(
                    "a-button",
                    {
                      staticStyle: {
                        background: "#fd9e06 !important",
                        color: "#fff",
                        "border-color": "#fd9e06",
                      },
                      attrs: { ghost: "" },
                      on: { click: _vm.batchExit },
                    },
                    [
                      _c("a-icon", { attrs: { type: "logout" } }),
                      _vm._v("批量退场入口"),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-space",
                { staticClass: "operator" },
                [
                  _c(
                    "a-button",
                    {
                      staticStyle: {
                        background: "#fd9e06 !important",
                        color: "#fff",
                        "border-color": "#fd9e06",
                      },
                      attrs: { ghost: "" },
                      on: { click: _vm.preExit },
                    },
                    [
                      _c("a-icon", { attrs: { type: "logout" } }),
                      _vm._v("通知工人离场"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("standard-table", {
            attrs: {
              columns: _vm.columns,
              dataSource: _vm.dataSource,
              selectedRows: _vm.selectedRows,
              rowKey: "id",
              scroll: { x: 1300 },
              pagination: _vm.pagination,
              loading: _vm.tableLoading,
              isAlert: false,
              bordered: true,
            },
            on: {
              "update:selectedRows": [
                function ($event) {
                  _vm.selectedRows = $event
                },
                function ($event) {
                  return _vm.updateSelectRows($event)
                },
              ],
              "update:selected-rows": function ($event) {
                _vm.selectedRows = $event
              },
              change: _vm.onPageChange,
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function (ref) {
                  var index = ref.index
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            (_vm.pagination.current - 1) *
                              _vm.pagination.pageSize +
                              parseInt(index) +
                              1
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "workName",
                fn: function (ref) {
                  var record = ref.record
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "电子履历",
                            params: { projWorkNum: record.projWorkNum },
                            query: {
                              teamId: record.teamId,
                              workNum: record.workNum,
                            },
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(record.workName) + " ")]
                    ),
                    record.isCaptain == 1
                      ? _c("div", { staticStyle: { color: "#FF5C77" } }, [
                          _vm._v("(班组长)"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "leaveType",
                fn: function (ref) {
                  var text = ref.text
                  return [
                    text == 2
                      ? _c("span", [_vm._v("通知退场")])
                      : _c("span", [_vm._v("劳务员退场")]),
                  ]
                },
              },
              {
                key: "workerFace",
                fn: function (ref) {
                  var text = ref.text
                  return [
                    _c("a-avatar", {
                      attrs: {
                        shape: "square",
                        size: 64,
                        src: text,
                        icon: "user",
                      },
                    }),
                  ]
                },
              },
              {
                key: "isreport",
                fn: function (ref) {
                  var text = ref.text
                  return [
                    text == 1
                      ? _c("span", [
                          _c("i", {
                            staticClass: "iconfont duigouxiao",
                            staticStyle: { color: "#00CE86" },
                          }),
                        ])
                      : _c("span", [
                          _c("i", {
                            staticClass: "iconfont path",
                            staticStyle: { color: "#FF5C77" },
                          }),
                        ]),
                  ]
                },
              },
              {
                key: "syncJwStatus",
                fn: function (ref) {
                  var text = ref.text
                  var record = ref.record
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "jwTag",
                        class: _vm.getJwStatusStyle(text),
                        on: {
                          click: function ($event) {
                            return _vm.errToast(record)
                          },
                        },
                      },
                      [_c("span", [_vm._v(_vm._s(_vm.getJwStatusText(text)))])]
                    ),
                  ]
                },
              },
              {
                key: "action",
                fn: function (ref) {
                  var record = ref.record
                  return _c("div", {}, [
                    _c(
                      "div",
                      { staticClass: "sTables" },
                      [
                        _c(
                          "a-popover",
                          {
                            attrs: {
                              placement: "bottomRight",
                              trigger: "click",
                            },
                          },
                          [
                            _c("template", { slot: "content" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "content_div",
                                  on: {
                                    click: function ($event) {
                                      return _vm.batchEntrance(3, record)
                                    },
                                  },
                                },
                                [_vm._v("重新进场")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "content_div",
                                  on: {
                                    click: function ($event) {
                                      return _vm.doDowload(record)
                                    },
                                  },
                                },
                                [_vm._v("下载退场凭证")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "content_div",
                                  on: {
                                    click: function ($event) {
                                      return _vm.eventRecord(record)
                                    },
                                  },
                                },
                                [_vm._v("事件记录")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "content_div",
                                  on: {
                                    click: function ($event) {
                                      return _vm.openPreExitMotal(record)
                                    },
                                  },
                                },
                                [_vm._v("查看退场记录")]
                              ),
                            ]),
                            _c("template", { slot: "title" }, [
                              _c("span", [_vm._v("操作")]),
                            ]),
                            _c("div", [_vm._v("更多>")]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ])
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("distribute-dialog", {
        attrs: {
          visible: _vm.distributeVisible,
          isDel: true,
          modalTitle: "删除人员权限",
          workNumList: _vm.workNumList,
        },
        on: {
          cancel: function ($event) {
            _vm.distributeVisible = false
          },
        },
      }),
      _c("persence-list-modal", {
        ref: "persenceModal",
        attrs: { visible: _vm.visibleExit, visibleTitle: _vm.visibleTitle },
        on: { handleCancel: _vm.handleCancel, handleOk: _vm.handleOk },
      }),
      _c("Export-loading", {
        attrs: {
          cPercent: _vm.cPercent,
          isExporting: _vm.isExporting,
          loadingText: _vm.loadingText,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }