var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("standard-table", {
        attrs: {
          columns: _vm.columns,
          dataSource: _vm.dataSource,
          rowKey: "id",
          pagination: _vm.pagination,
          loading: _vm.tableLoading,
          isAlert: false,
          bordered: true,
        },
        on: { change: _vm.onPageChange },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function (ref) {
              var index = ref.index
              return [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        (_vm.pagination.current - 1) * _vm.pagination.pageSize +
                          parseInt(index) +
                          1
                      ) +
                      " "
                  ),
                ]),
              ]
            },
          },
          {
            key: "attachment",
            fn: function (ref) {
              var text = ref.text
              return [
                _c("a-avatar", {
                  attrs: { shape: "square", size: 64, src: text, icon: "user" },
                }),
              ]
            },
          },
          {
            key: "action",
            fn: function (ref) {
              var record = ref.record
              return _c("div", {}, [
                _c("div", { staticClass: "sTables" }, [
                  _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.doDowloadBatch(record)
                        },
                      },
                    },
                    [_vm._v("下载证书")]
                  ),
                ]),
              ])
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }