<template>
  <a-card>
    <form-index
      :chageT="true"
      ref="formindex"
      :formData="formData"
      :Overview="query.id ? '编辑子产品' : '新增子产品'"
      :topSubmit="true"
      @handleSubmit="handleSubmit"
      @handlSelect="handlSelect"
      :defaultFileList="defaultFileList"
    >
      <!-- 上部分按钮插槽 -->
      <template #topSubmit>
        <a-space :style="{ marginTop: '4px' }">
          <a-button type="primary" html-type="submit"> 提交 </a-button>
          <a-button @click="close"> 关闭 </a-button>
        </a-space>
      </template>
    </form-index>
    <!-- <a-space class="operator">
      <a-button type="primary" html-type="submit"> 提交 </a-button>
        <a-button @click="close"> 关闭 </a-button>
      </a-space> -->
  </a-card>
</template>

<script>
const formData = [
  {
    label: "所属产品",
    placeholder: "请选择所属产品",
    decorator: [
      "typeId",
      { rules: [{ required: true, message: "请选择所属产品" }] },
    ],
    type: "select",
    key: "typeId",
    selectlist: [],
    labelCol: 4,
    wrapperCol: 12,
    col: 8,
    display: true,
  },
  {
    label: "所属产品名称",
    placeholder: "请选择所属产品名称",
    decorator: [
      "typeName",
      { rules: [{ required: false, message: "请选择所属产品名称" }] },
    ],
    type: "input",
    key: "typeName",
    labelCol: 0,
    wrapperCol: 0,
    col: 0,
    display: true,
  },
  {
    label: "子产品名称",
    placeholder: "请输入子产品名称",
    decorator: [
      "pdName",
      { rules: [{ required: true, message: "请输入子产品名称" }] },
    ],
    type: "input",
    key: "pdName",
    col: 8,
    labelCol: 4,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "产品类别",
    placeholder: "请选择产品类别",
    decorator: [
      "dictId",
      { rules: [{ required: true, message: "请选择产品类别" }] },
    ],
    type: "select",
    key: "dictId",
    selectlist: [
      {
        name: "软件产品",
        value: '1',
      },
      {
        name: "增值服务",
        value: '2',
      },
      {
        name: "硬件设备",
        value: '3',
      },
    ],
    col: 8,
    labelCol: 4,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "产品单位",
    placeholder: "请选择产品单位",
    decorator: [
      "unit",
      { rules: [{ required: false, message: "请选择产品单位" }] },
    ],
    type: "select",
    key: "unit",
    selectlist: [
      {
        name: "项",
        value: "项",
      },
      {
        name: "台",
        value: "台",
      },
      {
        name: "年",
        value: "年",
      },
      {
        name: "件",
        value: "件",
      },
      {
        name: "套",
        value: "套",
      },
    ],
    col: 8,
    labelCol: 4,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "产品价格",
    placeholder: "请输入产品价格(不含税)",
    decorator: [
      "price",
      { rules: [{ required: true, message: "请输入产品价格(不含税)" }] },
    ],
    type: "input",
    key: "price",
    col: 8,
    labelCol: 4,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "税率",
    placeholder: "请填写税率",
    decorator: ["tax", { rules: [{ required: true, message: "请填写税率" }] }],
    type: "input",
    key: "tax",
    col: 8,
    labelCol: 4,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "产品品牌",
    placeholder: "请输入品牌",
    decorator: ["pdMp", { rules: [{ required: true, message: "请输入品牌" }] }],
    type: "input",
    key: "pdMp",
    col: 8,
    labelCol: 4,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "产品备注",
    placeholder: "请输入产品备注",
    decorator: [
      "remark",
      { rules: [{ required: true, message: "请输入产品备注" }] },
    ],
    type: "input",
    key: "remark",
    col: 8,
    labelCol: 4,
    wrapperCol: 12,
    display: true,
  },
  {
    label: "产品详情",
    placeholder: "请输入产品详情(型号规格)",
    decorator: [
      "ggxh",
      { rules: [{ required: true, message: "请输入产品详情(型号规格)" }] },
    ],
    type: "textarea",
    key: "ggxh",
    col: 17,
    labelCol: 2,
    wrapperCol: 17,
    display: true,
  },
  {
    label: "产品附件",
    placeholder: "上传产品附件",
    decorator: [
      "annex",
      { rules: [{ required: false, message: "请上传产品附件" }] },
    ],
    type: "Upload",
    key: "annex",
    class: "Upload",
    col: 8,
    labelCol: 4,
    wrapperCol: 12,
    display: true,
  },
];
import formIndex from "@/pages/components/form/index";
import { mapGetters } from "vuex";
import {
  getProductList,
  addProduct,
  getProductDetail,
  addProductDetail,
  editProductDetail,
} from "@/services/product";
export default {
  data() {
    return {
      formData: formData,
      defaultFileList: [], //图片数组
      roleTypeList: [], // 所属产品数组
      query: this.$route.query,
    };
  },
  components: {
    formIndex,
  },
  inject: ["fatherMethod"],
  computed: {
    ...mapGetters("account", ["user"]),
  },
  created() {},
  mounted() {
    this.getProductList();
    // 有id获取详情
    if (this.query.id) {
      this.getProductDetail();
    }
  },
  methods: {
    // 编辑初始化数据
    getProductDetail() {
      getProductDetail({ id: this.query.id }).then((res) => {
        this.$refs["formindex"].queryObj(res.data.data);
      });
    },
    // 关闭当前页面
    close() {
      let path = this.$route.path;
      this.fatherMethod(`${path}`);
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      this.formData.forEach((item) => {
        if (item.key == key) {
          item.selectlist = data;
        }
      });
    },
    getProductList() {
      getProductList({}).then((res) => {
        const data = res.data.data;
        const arr = [];
        data.forEach((item) => {
          arr.push({
            name: item.pdName,
            value: Number(item.id),
          });
        });
        this.roleTypeList = arr;
        this.formDataFn("typeId", arr);
      });
    },
    // 提交表单数据
    handleSubmit(e) {
      console.log(e, "ee");
      if (this.query.id) {
        e.id = this.query.id;
        editProductDetail(e).then((res) => {
          if (res.data.code === 0) {
            this.$message.success("修改成功", 10);
            // this.$refs["formindex"].resetFields();
          } else {
            this.$message.error(res.data.msg, 10);
          }
        });
      } else {
        addProductDetail(e).then((res) => {
          if (res.data.code === 0) {
            this.$message.success("新增成功", 10);
            // this.$refs["formindex"].resetFields();
          } else {
            this.$message.error(res.data.msg, 10);
          }
        });
      }
      this.close();
    },
    // 特殊数据处理
    handlSelect({ val, keys }) {
      console.log(val, keys, "val", "keys");
      if (keys == "typeId") {
        this.roleTypeList.forEach((item) => {
          if (item.value == val) {
            this.$refs["formindex"].setFieldsValue(
              item.name,
              { typeName: item.name },
              "typeName"
            );
          }
        });
      }
    },
  },
};
</script>
<style lang="less" scoped></style>
