import {OFFICE_ESS_LIST, OFFICE_ESS_UPDATE, APPLY_ESS, CREATE_CONSOLE_LOGIN_URL,
    GET_ESS_TEMPLATE_LIST, ESS_TEMPLATE_UPDATE, ESS_TEMPLATE_DEL, ESS_TEMPLATE_INSERT,
    GET_ACCOUNT_CUSTOM_TEMPLATE_LIST, GET_ESS_CONTRACT_RECORD_LIST, GET_CONSOLE_CONTRACT_RECORD_URL,GET_CONTRACT_FILE_URL,APPLY_ESS_OFFICE,APPLY_ESS_AUTHSIGN,QUERY_DETAIL_OFFICE_STATUS} from '@/services/api'
import {request, METHOD} from '@/utils/request'



/**
 * 获取当前公司认证信息
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getOfficeEssList(param) {
    return request(OFFICE_ESS_LIST, METHOD.POST, param)
}

/**
 * 更新认证信息
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function officeEssUpdate(param) {
    return request(OFFICE_ESS_UPDATE, METHOD.POST, param)
}

/**
 * 申请开通电子签
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function applyEss(param) {
    return request(APPLY_ESS, METHOD.GET, param)
}

/**
 * 获取控制台入口链接
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function createConsoleLoginUrl(param) {
    return request(CREATE_CONSOLE_LOGIN_URL, METHOD.GET, param)
}

/**
 * 获取模板列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getEssTemplateList(param) {
    return request(GET_ESS_TEMPLATE_LIST, METHOD.POST, param)
}

/**
 * 更新模板
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function essTemplateUpdate(param) {
    return request(ESS_TEMPLATE_UPDATE, METHOD.POST, param)
}

/**
 * 更新模板
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function essTemplateDel(param) {
    return request(ESS_TEMPLATE_DEL, METHOD.GET, param)
}

/**
 * 新增模板
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function essTemplateInsert(param) {
    return request(ESS_TEMPLATE_INSERT, METHOD.POST, param)
}

/**
 * 获取我的自定义模板列表
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getAccountCustomTemplateList() {
    return request(GET_ACCOUNT_CUSTOM_TEMPLATE_LIST, METHOD.GET)
}

/**
 * 获取电子合同记录
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getEssContractRecordList(param) {
    return request(GET_ESS_CONTRACT_RECORD_LIST, METHOD.POST, param )
}

/**
 * 获取电子合同记录预览链接
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getConsoleContractRecordUrl(param) {
    return request(GET_CONSOLE_CONTRACT_RECORD_URL, METHOD.GET, param )
}
/**
 * 获取电子合同记录预览链接
 * @returns {Promise<AxiosResponse<T>>}
 */
 export async function getContractFileUrl(param) {
    return request(GET_CONTRACT_FILE_URL, METHOD.POST, param )
}

/**
 *  申请开通法大大电子签
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function applyEssOffice(params) {
    return request(APPLY_ESS_OFFICE, METHOD.POST,params)
}

/**
 *  获取自动授权签署链接
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function applyEssAuthSign(params) {
    return request(APPLY_ESS_AUTHSIGN, METHOD.POST,params)
}

/**
 *  查询公司实名详情状态 
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getOfficeAuthStatus() {
    return request(QUERY_DETAIL_OFFICE_STATUS, METHOD.get)
}




export default {
    getOfficeEssList,
    officeEssUpdate,
    applyEss,
    createConsoleLoginUrl,
    getEssTemplateList,
    essTemplateUpdate,
    essTemplateInsert,
    essTemplateDel,
    getAccountCustomTemplateList,
    getEssContractRecordList,
    getConsoleContractRecordUrl,
    getContractFileUrl
}