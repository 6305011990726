var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("search-form"),
      _c(
        "a-list",
        {
          staticStyle: { margin: "0 -8px" },
          attrs: { grid: { gutter: 24, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 } },
        },
        _vm._l(8, function (n) {
          return _c(
            "a-list-item",
            { key: n, staticStyle: { padding: "0 8px" } },
            [
              _c(
                "a-card",
                [
                  _c("img", {
                    attrs: {
                      slot: "cover",
                      src: "https://gw.alipayobjects.com/zos/rmsportal/iZBVOIhGJiAnhplqjvZW.png",
                      height: "154",
                    },
                    slot: "cover",
                  }),
                  _c("a-card-meta", { attrs: { title: "Ant Design" } }, [
                    _c(
                      "div",
                      { attrs: { slot: "description" }, slot: "description" },
                      [_vm._v(" 城镇中有那么多的酒馆，她却偏偏走进了我的酒馆 ")]
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _c("span", [_vm._v("4小时前")]),
                      _c(
                        "avatar-list",
                        [
                          _c("avatar-list-item", {
                            attrs: {
                              size: "small",
                              tips: "曲丽丽",
                              src: "https://gw.alipayobjects.com/zos/rmsportal/ZiESqWwCXBRQoaPONSJe.png",
                            },
                          }),
                          _c("avatar-list-item", {
                            attrs: {
                              size: "small",
                              tips: "周星星",
                              src: "https://gw.alipayobjects.com/zos/rmsportal/tBOxZPlITHqwlGjsJWaF.png",
                            },
                          }),
                          _c("avatar-list-item", {
                            attrs: {
                              size: "small",
                              tips: "董娜娜",
                              src: "https://gw.alipayobjects.com/zos/rmsportal/sBxjgqiuHMGRkIjqlQCd.png",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }