<template>
  <div>
    <div class="a_a_footer">
      <p>本调查表共5项，4分为“很满意”，3分为“满意”，2分为“比较满意”，1分为“不满意”</p>
      <div class="table-container">
        <div class="table">
          <span class="flex-1">维保人员佩戴安全防护用品</span>
          <a-radio-group v-model="params.protection">
            <a-radio :value="4">
              4
            </a-radio>
            <a-radio :value="3">
              3
            </a-radio>
            <a-radio :value="2">
              2
            </a-radio>
            <a-radio :value="1">
              1
            </a-radio>
          </a-radio-group>
        </div>
        <div class="table" style="background: #fff">
          <span class="flex-1">维保人员与项目部的协调沟通</span>
          <a-radio-group v-model="params.communication">
            <a-radio :value="4">
              4
            </a-radio>
            <a-radio :value="3">
              3
            </a-radio>
            <a-radio :value="2">
              2
            </a-radio>
            <a-radio :value="1">
              1
            </a-radio>
          </a-radio-group>
        </div>
        <div class="table">
          <span class="flex-1">维保资料、相片提供及时性</span>
          <a-radio-group v-model="params.inTime">
            <a-radio :value="4">
              4
            </a-radio>
            <a-radio :value="3">
              3
            </a-radio>
            <a-radio :value="2">
              2
            </a-radio>
            <a-radio :value="1">
              1
            </a-radio>
          </a-radio-group>
        </div>
        <div class="table" style="background: #fff">
          <span class="flex-1">维保人员遵守现场纪律情况、服务态度，责任心</span>
          <a-radio-group v-model="params.responsibility">
            <a-radio :value="4">
              4
            </a-radio>
            <a-radio :value="3">
              3
            </a-radio>
            <a-radio :value="2">
              2
            </a-radio>
            <a-radio :value="1">
              1
            </a-radio>
          </a-radio-group>
        </div>
        <div class="table">
          <span class="flex-1">维保质量</span>
          <a-radio-group v-model="params.quality">
            <a-radio :value="4">
              4
            </a-radio>
            <a-radio :value="3">
              3
            </a-radio>
            <a-radio :value="2">
              2
            </a-radio>
            <a-radio :value="1">
              1
            </a-radio>
          </a-radio-group>
        </div>
        <p class="mt-2">综合得分：{{ params.protection + params.communication + params.inTime + params.responsibility + params.quality }}</p>
        <a-textarea placeholder="" v-model="params.content" :rows="4" />
        <a-button class="mt-2 sumbit-btn" type="primary" @click="sumbit" v-show="(!this.params.evaluateUserId || this.user.id == this.params.evaluateUserId) && !$route.meta.isBreak"> 保存 </a-button>
      </div>
    </div>
    <div class="a_a_footer mt-3">
      <!-- 表格封装 -->
      <standard-table
        class=""
        :columns="columns"
        :dataSource="dataSource"
        rowKey="id"
        :pagination="pagination"
        :loading="tableLoading"
        @change="onPageChange"
        :isAlert="false"
        :bordered="true"
      >
        <template slot="index" slot-scope="{ index }">
          <span>
            {{
              (pagination.current - 1) * pagination.pageSize +
              parseInt(index) +
              1
            }}
          </span>
        </template>
        <template slot="maintenancePath" slot-scope="{ text }">
          <a-avatar shape="square" :size="64" :src="text" icon="user" />
        </template>
        <div slot="action" slot-scope="{ record }">
          <div class="sTables">
            <div @click="visit(record)">查看</div>
            <div @click="del(record)" v-show="record.evaluateUserId == user.id && !$route.meta.isBreak">删除</div>
          </div>
        </div>
      </standard-table>
    </div>
  </div>
</template>

<script>
import StandardTable from "@/components/table/StandardTable";
import { getMaintenanceEvaluateList, getFacilityMaintenanceEvaluateSelfDetail, getFacilityMaintenanceEvaluateDetail, delFacilityMaintenanceEvaluate,
  insertMaintenanceEvaluatengRecord, updateFacilityMaintenanceEvaluate } from "@/services/equipmentLedger";
import moment from "moment";
import {mapGetters} from 'vuex'
import { useRender } from '@/hooks/useRender.js'

const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
  },
  {
    title: "维保单位",
    dataIndex: "maintenanceUnit",
    width: 205,
    align: "center",
    // ellipsis: true,
    scopedSlots: { customRender: "maintenanceUnit" },
  },

  {
    title: "评价人",
    dataIndex: "evaluateUserName",
    align: "center",
    width: 130,
    scopedSlots: { customRender: "evaluateUserName" },
  },
  {
    title: "评价日期",
    dataIndex: "evaluateDate",
    width: 130,
    align: "center",
    // ellipsis: true,
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "综合得分",
    dataIndex: "total",
    align: "center",
    width: 130,
    scopedSlots: { customRender: "total" },
  },
  {
    title: "操作",
    align: "center",
    width: 130,
    scopedSlots: { customRender: "action" },
  },
];
export default {
  components: {
    StandardTable,
  },
  data() {
    return {
      columns: columns,
      dataSource: [], //table数组
      pagination: {
        //分页数据
        current: 1,
        pageSize: 10,
        total: 0,
      },
      isUpdata: false,
      params: {
        //测试数据
        pageNumber: 1,
        pageSize: 10,
        facilityId: '',
        recordId: '',
        facilityType: null,
        protection: 0,
        communication: 0,
        inTime: 0,
        responsibility: 0,
        quality: 0,
        content: ''
      },
      tableLoading: false, //table加载中
    };
  },
  computed: {
    ...mapGetters('account', ['user']),
  },
  activated() {
    this.params.recordId = this.$route.query.recordId;
    this.params.facilityId = this.$route.query.facilityId;
    this.params.facilityType = Number(this.$route.query.facilityType);
    this.aqxjlist(this.params);
    this.getEvaluateDetail(this.params);
  },
  methods: {
    sumbit() {
      if (this.isUpdata) {
        updateFacilityMaintenanceEvaluate(this.params).then(res => {
          if (res.data.code === 0) {
            this.$message.success("保存成功!");
            this.aqxjlist(this.params);
          } else {
            this.$message.warning(res.data.message);
          }
        })
      } else {
        let obj = Object.assign(this.params, {evaluateUserId: this.user.id, evaluateUserName: this.user.name})
        insertMaintenanceEvaluatengRecord(obj).then(res => {
          if (res.data.code === 0) {
            this.$message.success("保存成功!");
            this.aqxjlist(this.params);
            this.getEvaluateDetail(this.params);
          } else {
            this.$message.warning(res.data.message);
          }
        })
      }
    },
    getEvaluateDetail(params) {
      getFacilityMaintenanceEvaluateSelfDetail(params.recordId).then((res) => {
        if (res.data.code === 0) {
          if (res.data.data) {
            this.isUpdata = true
            this.params = res.data.data;
          } else {
            this.isUpdata = false
          }
        }
      });
    },
    aqxjlist(params) {
      this.tableLoading = true;
      getMaintenanceEvaluateList(params).then((res) => {
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
        }
        this.tableLoading = false;
      });
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      let arr = [];
      data.forEach((item) => {
        arr.push({ name: item.master, value: item.id });
      });
      this.formData.forEach((item) => {
        if (item.key == key) {
          item.selectlist = arr;
        }
      });
    },
    handlSelect(obj) {
      const { val, keys } = obj;
    },
    //表格 - 分页查询
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      if (obj.status == "") {
        obj.status = 0;
      }
      this.params.pageNumber = e.current;
      this.pagination.current = e.current;
      this.getData(Object.assign({}, this.params, obj));
    },
    //查看
    visit(record) {
      this.params = Object.assign(this.params, record)
    },
    del(record) {
      const _that = this
      this.$confirm({
          title: '是否确定删除？',
          cancelText: '否',
          okText: '是',
          onOk: () => {
            delFacilityMaintenanceEvaluate(record.id).then(res => {
              if (res.data.code === 0) {
                _that.$message.success('删除成功')
                this.aqxjlist(this.params);
                this.getEvaluateDetail(this.params);
              } else {
                _that.$message.error(res.data.msg)
              }
            })
          },
        })
    },
  },
};
</script>

<style lang="less" scoped>
.a_a_footer {
  padding: 20px;
  background: #ffffff;

  .operator {
    margin-right: 8px;
  }

  .sTables {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      width: 100%;
      text-align: center;
      cursor: pointer;
    }

    div:nth-child(1) {
      color: #307dfa;
    }

    div:nth-child(2) {
      color: #ff5c77;
    }

    div:nth-child(3) {
      color: #615e83;
    }
  }
}
.content_div {
  padding: 5px 0;
  cursor: pointer;
}
.content_div:hover {
  color: #307dfa;
}
.table-container {
  width: 800px;
  position: relative;
  margin-bottom: 50px;
  .table {
    width: 750px;
    display: flex;
    padding: 10px 20px;
    background: #F5F9FF;  
  }
  .sumbit-btn {
    position: absolute;
    right: 0;
    bottom: -50px;
  }
}
</style>
