var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a_Method" },
    [
      _c(
        "a-row",
        { attrs: { gutter: 16 } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-card",
                {
                  staticStyle: { height: "900px" },
                  style: "overflow:auto",
                  attrs: { title: "参建单位绑定考勤设备", bordered: false },
                },
                [
                  _c(
                    "a-checkbox-group",
                    {
                      on: { change: _vm.onChange },
                      model: {
                        value: _vm.selectedDevices,
                        callback: function ($$v) {
                          _vm.selectedDevices = $$v
                        },
                        expression: "selectedDevices",
                      },
                    },
                    [
                      _c(
                        "a-row",
                        _vm._l(_vm.deviceList, function (device) {
                          return _c(
                            "a-col",
                            {
                              key: device.value,
                              staticClass: "mt-1",
                              attrs: { span: 24 },
                            },
                            [
                              _c(
                                "a-checkbox",
                                { attrs: { value: device.value } },
                                [_vm._v(" " + _vm._s(device.label) + " ")]
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }