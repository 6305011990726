var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("form-index", {
        ref: "formindex",
        attrs: { formData: _vm.formData, flexSubmit: true },
        on: { handleSubmit: _vm.handleSubmit, handlSelect: _vm.handlSelect },
        scopedSlots: _vm._u([
          {
            key: "flexSubmit",
            fn: function () {
              return [
                _c(
                  "a-button",
                  { attrs: { type: "primary", "html-type": "submit" } },
                  [_vm._v(" 查询 ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "a_a_footer" },
        [
          _c(
            "div",
            { staticClass: "mb-1" },
            [
              _c(
                "a-space",
                { staticClass: "operator" },
                [
                  _c("a-button", { on: { click: _vm.doReports } }, [
                    _vm._v("一键上报"),
                  ]),
                ],
                1
              ),
              _c(
                "a-space",
                { staticClass: "operator" },
                [
                  _c("a-button", { on: { click: _vm.Refresh } }, [
                    _vm._v("刷新"),
                  ]),
                  _vm.canOperate
                    ? _c(
                        "a-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.handleUpload(1)
                            },
                          },
                        },
                        [
                          _c("a-icon", { attrs: { type: "upload" } }),
                          _vm._v(" 导入工人考勤 "),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.canOperate
                    ? _c(
                        "a-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.handleUpload(2)
                            },
                          },
                        },
                        [
                          _c("a-icon", { attrs: { type: "upload" } }),
                          _vm._v(" 导入管理人员考勤 "),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("standard-table", {
            attrs: {
              columns: _vm.columns,
              dataSource: _vm.dataSource,
              rowKey: "id",
              pagination: _vm.pagination,
              loading: _vm.tableLoading,
              isAlert: false,
              bordered: true,
            },
            on: { change: _vm.onPageChange },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function (ref) {
                  var index = ref.index
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            (_vm.pagination.current - 1) *
                              _vm.pagination.pageSize +
                              parseInt(index) +
                              1
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { visible: _vm.uploadWorkerModal, footer: null },
          on: { cancel: _vm.cancelUploadWorkerModal },
        },
        [
          _c(
            "div",
            { staticClass: "flex flex-column a-center" },
            [
              _c("h4", { staticClass: "mb-2" }, [
                _vm._v(
                  "只能导入3天内的" +
                    _vm._s(_vm.uploadType == 1 ? "工人" : "管理人员") +
                    "考勤数据"
                ),
              ]),
              _c(
                "a-upload",
                {
                  attrs: {
                    action:
                      _vm.uploadType == 1
                        ? _vm.staffWorkBatchApplyUrl
                        : _vm.staffManageBatchApplyUrl,
                    headers: _vm.headers,
                    "file-list": _vm.fileList,
                    accept: ".xls,.xlsx",
                    beforeUpload: _vm.beforeUpload,
                  },
                  on: { change: _vm.handleChange },
                },
                [
                  _c(
                    "div",
                    [
                      _c("a-button", { attrs: { type: "primary" } }, [
                        _vm._v(" 确认导入 "),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }