<template>
  <a-card>
    <a-spin :spinning="spinning" :tip="loadingText">
      <div :class="advanced ? 'search' : null">
        <a-form layout="horizontal">
          <div :class="advanced ? null : 'fold'">
            <a-row>
              <a-col :md="8" :sm="24">
                <a-form-item
                  label="印章标题"
                  :labelCol="{ span: 5 }"
                  :wrapperCol="{ span: 18, offset: 1 }"
                >
                  <a-input
                    placeholder="请输入"
                    v-model="queryParams.title"
                  />
                </a-form-item>
              </a-col>
            </a-row>
          </div>
          <span style="float: right; margin-top: 3px">
            <a-button type="primary" @click="doQuery()">查询</a-button>
            <a-button style="margin-left: 8px" @click="doReset()">重置</a-button
            >
          </span>
        </a-form>
      </div>
      <div class="operator">
        <a-button type="primary" @click="doAddTempl()">新增印章</a-button>
      </div>
      <div>
        <standard-table
          :columns="columns"
          :dataSource="dataSource"
          :selectedRows.sync="selectedRows"
          :pagination="pagination"
          :loading="tableLoading"
          @clear="onClear"
          @change="onPageChange"
        >
          <div slot="do-Type" slot-scope="{ text }">
            <label v-if="text == 1" >公司安全部印章</label>
            <label v-if="text == 2" >项目印章</label>
          </div>
          <div slot="do-status" slot-scope="{ text }">
            <label v-if="text == 1" >普通章</label>
            <label v-if="text == 2" >其他</label>
          </div>
          <div slot="action" slot-scope="{record }">
            <!-- <a href="`${record.key}`" @click="viewSeal(`${record.key}`)">查看印章</a>
            <a-divider type="vertical" /> -->
            <a href="#" @click="editorSeal(`${record.key}`)">编辑印章</a>
            <a-divider type="vertical" />
            <a href="#" @click="deleteSeal(`${record.key}`)">删除</a>
          </div>
        </standard-table>
      </div>
    </a-spin>
    <seal-editor
      :visible="seal.visible"
      :seal="seal.file"
      @close="doCloseSet"
      @submit="doSubmit($event)"
    ></seal-editor>
  </a-card>
</template>

<script>
import StandardTable from "@/components/table/StandardTable";
import SealEditor from "@/pages/essbasic/SealEditor";
import { SEAL_MANAGE_QUERY,SEAL_MANAGE_DETAIL,SEAL_MANAGE_INSERT,SEAL_MANAGE_UPDATE,SEAL_MANAGE_DELETE } from "@/services/api";
import { request } from "@/utils/request";
import { te } from "date-fns/locale";
// import axios from '@/utils/axios'
const columns = [
  {
    title: "印章名称",
    dataIndex: "title",
    align: "center",
    scopedSlots: { customRender: "title" },
  },
  {
    title: "公司/项目",
    dataIndex: "bussType",
    align: "center",
    scopedSlots: { customRender: "do-Type" },
    // customRender: (text) => {
    //   return text.substring(0,6)+"************"+text.substring(text.length-4,text.length)
    // }
  },
  {
    title: "印章类型",
    dataIndex: "type",
    align: "center",
    scopedSlots: { customRender: "do-status" },
  },
  // {
  //   title: "图章链接",
  //   dataIndex: "path",
  //   align: "left"
  // },
  {
    title: "描述说明",
    dataIndex: "remark",
    align: "center",
    scopedSlots: { customRender: "remark" },
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  name: "QueryList",
  components: { StandardTable, SealEditor },
  data() {
    return {
      dataSource: [],
      advanced: false,
      columns: columns,
      selectedRows: [],
      tableLoading: false,
      spinning: false,
      loadingText: "数据加载中...",
      loadKeys: [],
      queryParams: {
        title: null,
        fileType: null,
        pageNumber: 1,
      },
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showLessItems: true,
        showQuickJumper: true,
      },
      seal: {
        visible: false,
        file: {},
      },
    };
  },
  created() {
    this.doQuery();
  },
  authorize: {
    deleteRecord: "delete",
  },
  methods: {
    deleteRecord(key) {
      this.dataSource = this.dataSource.filter((item) => item.key !== key);
      this.selectedRows = this.selectedRows.filter((item) => item.key !== key);
    },
    toggleAdvanced() {
      this.advanced = !this.advanced;
    },
    remove() {
      this.dataSource = this.dataSource.filter(
        (item) =>
          this.selectedRows.findIndex((row) => row.key === item.key) === -1
      );
      this.selectedRows = [];
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },

    /**
     * 获取所有人员的签名记录信息
     */
    doQuery() {
      this.tableLoading = true;
      request(SEAL_MANAGE_QUERY, "post", this.queryParams).then((res) => {
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list;
          this.pagination.total = Number(res.data.data.total);
          this.dataSource.forEach((obj) => {
            obj.key = obj.id;
          });
        }
        this.tableLoading = false;
      });
    },
    doReset() {
      this.queryParams = {};
      this.doQuery();
    },
    //分页查询
    onPageChange(e) {
      this.queryParams.pageNumber = e.current;
      this.pagination.current = e.current;
      this.doQuery();
    },
    handleMenuClick(e) {
      if (e.key === "delete") {
        this.remove();
      }
    },
    doCloseSet() {
      this.seal.visible = false;
    },
    editorSeal($id){
      //查询该直播的最新数据
      this.doDetail($id);
    },
    doAddTempl(){
      this.seal.file = {};
      this.seal.visible = true;
    },
    doDetail(id){
      request(SEAL_MANAGE_DETAIL+id, "get", {}).then((res) => {
        if (res.data.code === 0) {
          this.seal.file = res.data.data;
          this.seal.visible = true;
        }else{
          this.$message.error("暂未查询到图章信息！")
        }
      });
    },
    doSubmit($event){
      var url = $event.seal.id?SEAL_MANAGE_UPDATE:SEAL_MANAGE_INSERT;
      var obj = $event.seal;
      console.log("保存前参数",obj);
      if(obj.bussType==1){
        obj.bussId = obj.officeId;
      }else if(obj.bussType==2){
        obj.bussId = obj.projectId;
      }
      console.log("保存参数",obj);
      request(url, "post", obj).then((res) => {
        if (res.data.code === 0) {
          this.seal.visible = false;
          this.$message.success("保存成功！");
          this.doQuery();
        }else{
          this.$message.error("保存异常！");
        }
      });
    },
    deleteSeal($id){
        var _that = this
        this.$confirm({
            title: '删除图章',
            content: '确认删除该图章吗?',
            okText: '删除',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                 request(SEAL_MANAGE_DELETE+$id, "delete", {}).then((res) => {
                   console.log(res)
                  if (res.data.code === 0) {
                    _that.$message.success("删除成功！");
                    _that.doQuery();
                  }else{
                    _that.$message.error("删除异常！");
                  }
                });
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    },
  },
};
</script>

<style lang="less" scoped>
.search {
  margin-bottom: 54px;
}
.fold {
  width: calc(100% - 216px);
  display: inline-block;
}
.operator {
  margin-bottom: 18px;
}

@media screen and (max-width: 900px) {
  .fold {
    width: 100%;
  }
}
</style>
