var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    [
      _c("form-index", {
        ref: "formindex",
        attrs: {
          chageT: true,
          formData: _vm.formData,
          Overview: _vm.query.id ? "报价单详情" : "新增报价单",
          topSubmit: true,
          defaultFileList: _vm.defaultFileList,
        },
        on: { handleSubmit: _vm.handleSubmit, handlSelect: _vm.handlSelect },
        scopedSlots: _vm._u([
          {
            key: "topSubmit",
            fn: function () {
              return [
                _c(
                  "a-space",
                  { style: { marginTop: "4px" } },
                  [
                    _c(
                      "a-button",
                      { attrs: { type: "primary", "html-type": "submit" } },
                      [_vm._v(" 提交 ")]
                    ),
                    _c("a-button", { on: { click: _vm.close } }, [
                      _vm._v(" 关闭 "),
                    ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      !_vm.query.id
        ? _c(
            "a-space",
            { staticClass: "operator mb-2" },
            [
              _c(
                "a-button",
                { attrs: { type: "primary", "html-type": "submit" } },
                [_vm._v(" 提交 ")]
              ),
              _c("a-button", { on: { click: _vm.close } }, [_vm._v(" 关闭 ")]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "table-container" },
        [
          _c("standard-table", {
            staticClass: "table-container",
            attrs: {
              columns: _vm.columns,
              dataSource: _vm.dataSource,
              "row-key": "id",
              loading: _vm.tableLoading,
              bordered: true,
              pagination: false,
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function (ref) {
                  var index = ref.index
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            (_vm.pagination.current - 1) *
                              _vm.pagination.pageSize +
                              parseInt(index) +
                              1
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "name",
                fn: function (ref) {
                  var text = ref.text
                  var record = ref.record
                  return [
                    _c(
                      "a-tooltip",
                      { attrs: { placement: "topLeft" } },
                      [
                        _c("template", { slot: "title" }, [
                          _c("span", [_vm._v(_vm._s(text))]),
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "ant-btn-text ellipsis",
                            on: {
                              click: function ($event) {
                                return _vm.toUnits(record)
                              },
                            },
                          },
                          [_vm._v(_vm._s(text))]
                        ),
                      ],
                      2
                    ),
                  ]
                },
              },
              {
                key: "userName",
                fn: function (ref) {
                  var record = ref.record
                  return _c("div", {}, [
                    record.userName
                      ? _c("div", [
                          _c("div", [_vm._v(_vm._s(record.userName))]),
                          record.userPhone
                            ? _c("span", [
                                _vm._v("(" + _vm._s(record.userPhone) + ")"),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ])
                },
              },
              {
                key: "action",
                fn: function (ref) {
                  var record = ref.record
                  return _c(
                    "div",
                    {},
                    [
                      _vm.isAdmin
                        ? _c(
                            "a-dropdown",
                            {
                              staticClass: "sTables",
                              attrs: { placement: "bottomCenter" },
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c("span", [_vm._v("操作")]),
                                  _c("a-icon", {
                                    staticStyle: {
                                      "margin-left": "2px",
                                      "font-size": "12px",
                                    },
                                    attrs: { type: "caret-down" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "a-menu",
                                {
                                  class: ["avatar-menu"],
                                  attrs: { slot: "overlay" },
                                  slot: "overlay",
                                },
                                [
                                  _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.viewEditing(record, "edit")
                                        },
                                      },
                                    },
                                    [
                                      _c("a-icon", { attrs: { type: "edit" } }),
                                      _c("span", [_vm._v("修改")]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-menu-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.recorDelete(record)
                                        },
                                      },
                                    },
                                    [
                                      _c("a-icon", {
                                        attrs: { type: "delete" },
                                      }),
                                      _c("span", [_vm._v("删除")]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c("span", [_vm._v("/")]),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "a-row",
        { staticClass: "mt-2", attrs: { type: "flex", justify: "end" } },
        [
          _c("a-col", { staticStyle: { "font-size": "16px" } }, [
            _c("div", { staticClass: "flex j-end" }, [
              _c("span", [_vm._v("共" + _vm._s(_vm.dataSource.length) + "项")]),
              _c("span", { staticClass: "ml-1" }, [
                _vm._v("总计: ￥" + _vm._s(_vm.originData.totalMoney)),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "my-2 flex j-end a-center" },
              [
                _vm._v(" 折扣"),
                _c("a-input", {
                  staticClass: "ml-1",
                  staticStyle: { width: "150px" },
                  attrs: { prefix: "￥", suffix: "RMB" },
                  model: {
                    value: _vm.discount,
                    callback: function ($$v) {
                      _vm.discount = $$v
                    },
                    expression: "discount",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "flex j-end" }, [
              _c("span", [_vm._v("实付")]),
              _c("span", { staticClass: "ml-1" }, [
                _vm._v("￥" + _vm._s(_vm.setDiscount)),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            width: 1300,
            centered: true,
            title: _vm.query.id ? "查看产品" : "添加产品",
          },
          model: {
            value: _vm.productVisible,
            callback: function ($$v) {
              _vm.productVisible = $$v
            },
            expression: "productVisible",
          },
        },
        [
          _c("standard-table", {
            attrs: {
              columns: _vm.productColumns,
              dataSource: _vm.productSource,
              "row-key": "id",
              loading: _vm.tableLoading,
              bordered: "",
              pagination: false,
              selectedRows: _vm.selectedRows,
            },
            on: {
              "update:selectedRows": function ($event) {
                _vm.selectedRows = $event
              },
              "update:selected-rows": function ($event) {
                _vm.selectedRows = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function (ref) {
                  var index = ref.index
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            (_vm.pagination.current - 1) *
                              _vm.pagination.pageSize +
                              parseInt(index) +
                              1
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "name",
                fn: function (ref) {
                  var text = ref.text
                  return [
                    _c(
                      "a-tooltip",
                      { attrs: { placement: "topLeft" } },
                      [
                        _c("template", { slot: "title" }, [
                          _c("span", [_vm._v(_vm._s(text))]),
                        ]),
                        _c("span", { staticClass: "ant-btn-text ellipsis" }, [
                          _vm._v(_vm._s(text)),
                        ]),
                      ],
                      2
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }