<template>

    <a-card>

        <div id="components-form-demo-advanced-search">
            <a-form class="ant-advanced-search-form" :form="queryData" >
                <a-row :gutter="24">
                    <a-col :span="6" >
                        <a-form-item label="姓名">
                            <a-input v-model="queryData.personName" placeholder="请输入工人姓名" />
                        </a-form-item>
                    </a-col>
                    <a-col :span="6" >
                        <a-form-item label="班组名">
                            <a-input v-model="queryData.teamName" placeholder="请输入班组名称" />
                        </a-form-item>
                    </a-col>
                    <a-col :span="6" >
                        <a-form-item label="下发状态">
                            <a-select
                                    v-model = "queryData.status"
                                    placeholder="请选择下发状态"

                            >
                                <a-select-option value="0">
                                    全部
                                </a-select-option>
                                <a-select-option value="1">
                                    已下发
                                </a-select-option>
                                <a-select-option value="2">
                                    未下发
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :span="4" :style="{ textAlign: 'right', marginTop: '3px'}">
                        <a-button type="primary" icon="search" @click="search">
                            查询
                        </a-button>
                    </a-col>
                </a-row>
                <a-row :gutter="24">

                    <a-col :span="2" :style="{ textAlign: 'right', marginTop: '3px'}">
                        <a-button type="primary" icon="search" @click="showBatchIssueModel">
                            批量下发
                        </a-button>
                    </a-col>
                    <a-col :span="2" :style="{ textAlign: 'right', marginTop: '3px'}">
                        <a-button type="primary" icon="search" @click="delLeavePerson">
                            批量移除设备离场人员
                        </a-button>
                    </a-col>
                </a-row>

            </a-form>
        </div>
        <a-tabs default-active-key="1" @change="tabsChange">
            <a-tab-pane key="1" tab="工人">
                <a-table :columns="columns" :data-source="data"  :pagination="pagination"
                         :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                         :rowKey="'idCard'">
                    <template slot="action" slot-scope="text, record">
                <span>
                  <a @click="issueGroupModel(record)">下发人脸</a>
                </span>
                    </template>
                    <a-table
                            slot="expandedRowRender"
                            slot-scope="inner"
                            :columns="groupFaceColumns"
                            :data-source="inner.personFaceRecordVoList"
                            :pagination="false"
                    >
                <span slot="operation" slot-scope="text, record" class="table-operation">
                    <span>
                      <a @click="reIssueGroup(inner, record)">重新下发</a>
                      <a-divider type="vertical" />
                      <a @click="delGroupPerson(inner, record)">删除</a>
                    </span>
              </span>
                    </a-table>

                </a-table>
            </a-tab-pane>
            <a-tab-pane key="2" tab="管理人员" force-render>
                <a-table :columns="columns" :data-source="data"  :pagination="pagination"
                         :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                         :rowKey="'idCard'">
                    <template slot="action" slot-scope="text, record">
                <span>
                  <a @click="issueGroupModel(record)">下发人脸</a>
                </span>
                    </template>
                    <a-table
                            slot="expandedRowRender"
                            slot-scope="inner"
                            :columns="groupFaceColumns"
                            :data-source="inner.personFaceRecordVoList"
                            :pagination="false"
                    >
                <span slot="operation" slot-scope="text, record" class="table-operation">
                    <span>
                      <a @click="reIssueGroup(inner, record)">重新下发</a>
                      <a-divider type="vertical" />
                      <a @click="delGroupPerson(inner, record)">删除</a>
                    </span>
              </span>
                    </a-table>

                </a-table>
            </a-tab-pane>
        </a-tabs>
        <div>
            <a-modal
                    :title="selectGroupModel.title"
                    :visible="selectGroupModel.visible"
                    :confirm-loading="selectGroupModel.confirmLoading"
                    @ok="selectGroupModelOk"
                    @cancel="selectGroupModelCancel"
            >

                <a-select
                        mode="tags"
                        placeholder="请选择下发分组"
                        :value="selectGroupModel.selectGroupIdList"
                        style="width: 200px"
                        @change="selectGroupChange"
                >
                    <a-select-option v-for="group in groupList" :key="group.groupId">
                        {{ group.groupName }}
                    </a-select-option>
                </a-select>
                <!--                <a-select style="width: 200px" @change="selectGroupChange">-->
                <!--                    <a-select-option v-for="group in groupList" :key="group.groupId">-->
                <!--                        {{ group.groupName }}-->
                <!--                    </a-select-option>-->
                <!--                </a-select>-->
            </a-modal>
        </div>

    </a-card>
</template>

<script>
    import {getPersonFaceRecordList, getGroupList, issueGroupFace, reIssueGroupFace, delGroupPerson, batchIssueGroupFace, getManagerPersonList, delLeavePerson} from '@/services/dmgr'
    import {mapGetters} from 'vuex'
    import { Modal } from 'ant-design-vue';

    const columns = [
        {
            title: '姓名',
            dataIndex: 'personName',
            key: 'personName',
        },
        {
            title: '工号',
            dataIndex: 'workNum',
            key: 'workNum',
        },
        {
            title: '项目工号',
            dataIndex: 'projectWorkNum',
            key: 'projectWorkNum',
        },
        {
            title: '身份证号',
            dataIndex: 'idCard',
            key: 'idCard',
        },
        {
            title: '班组',
            dataIndex: 'teamName',
            key: 'teamName',
        },
        // {
        //     title: '照片',
        //     dataIndex: 'facePhoto',
        //     key: 'facePhoto',
        // },
        {
            title: '操作',
            key: 'action',
            scopedSlots: { customRender: 'action' },
        },
    ];

    const groupFaceColumns = [
        {
            title: '分组名',
            dataIndex: 'groupName',
            key: 'groupName',
        },
        {
            title: '分组Id',
            dataIndex: 'groupId',
            key: 'groupId',
        },
        {
            title: '人员ID',
            dataIndex: 'devicePersonId',
            key: 'devicePersonId',
        },
        {
            title: '下发状态',
            dataIndex: 'status',
            key: 'status',
            customRender: (text) => {
                return text === 1? "下发成功" : "下发失败"
            },
        },
        {
            title: '失败原因',
            dataIndex: 'remarks',
            key: 'remarks',
            // width: 80,
            ellipsis: true,
        },
        {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            scopedSlots: { customRender: 'operation' },
        },
    ];



    export default {
        data() {
            return {
                selectedRowKeys: [],
                tabsKey: '1',
                manufacturerList:[
                    {
                        manufacturerKey: 'yunqi',
                        manufacturerName: '云启'
                    }
                ],
                queryData:{
                    projectId: "",
                    status: "0",
                },
                pagination:{
                    pageSize: 20,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    showSizeChanger: true,
                },
                data: [],
                columns,
                groupFaceColumns,

                title: "编辑",
                visible: false,
                confirmLoading: false,
                labelCol: { span: 6 },
                wrapperCol: { span: 12 },
                form:{
                },

                selectGroupModel:{
                    title: "编辑",
                    visible: false,
                    confirmLoading: false,
                    selectGroupIdList: [],
                    selectPerson:{},
                    batch: false

                },
                groupList: [],
            };
        },

        computed: {
            ...mapGetters('account', ['user']),
        },
        filters: {
        },

        created() {
            this.queryData.projectId = this.user.projectId
            this.getPersonFaceRecordList()

        },
        methods: {
            getPersonFaceRecordList() {
                this.selectedRowKeys = []
                if (this.tabsKey == 1){
                    getPersonFaceRecordList(this.queryData).then(res => {
                        this.data = res.data.data;
                    });
                }
                if (this.tabsKey == 2){
                    getManagerPersonList(this.queryData).then(res=>{
                        this.data = res.data.data;
                    })
                }

            },
            search(){
              this.getPersonFaceRecordList();
            },
            getGroupList(){
                getGroupList({projectId: this.queryData.projectId}).then(res => {
                    this.groupList = res.data.data;
                });
            },
            issueGroupModel(record){
                let groupIdList = record.personFaceRecordVoList.map(personFaceRecord=> {return personFaceRecord.groupId});
                this.selectGroupModel.selectPerson = record;
                this.selectGroupModel.selectPerson.groupIdList = groupIdList;
                this.selectGroupModel.selectGroupIdList = groupIdList;
                this.getGroupList();
                this.selectGroupModel.visible = true
            },
            selectGroupChange(value){
                this.selectGroupModel.selectGroupIdList = value;
            },
            selectGroupModelOk(){
                if (this.selectGroupModel.batch){
                    this.batchIssueGroupFace()
                }else {
                    this.selectGroupModel.selectPerson.groupIdList = this.selectGroupModel.selectGroupIdList
                    this.personIssueGroup();
                }
                this.selectGroupModelCancel();

            },
            selectGroupModelCancel(){
                this.selectGroupModel.selectGroupIdList = [];
                this.selectGroupModel.visible = false
                this.selectGroupModel.batch = false
            },
            personIssueGroup(){
                // this.selectGroupModel.selectPerson.facePhoto = "http://qiniu.gz-suijian.com/1655113537604imageMogr2.jpg?e=5741415573&token=lJuyBQoBwDdN0NT-7A9NEqydz6r7siqKmYXaMMrD:B4Cxo4-e51bnWXxJkHtTCdJqAjk="
                issueGroupFace(this.selectGroupModel.selectPerson).then(res => {
                    this.$message.success(res.data.msg);
                    this.getPersonFaceRecordList();

                })
            },
            reIssueGroup(person, faceRecord){
                let tempFaceRecord =JSON.parse(JSON.stringify(faceRecord));
                tempFaceRecord.personName = person.personName
                tempFaceRecord.facePhoto = person.facePhoto
                // tempFaceRecord.gender = person.gender

                // tempFaceRecord.mobile = person.
                // personFaceRecord.groupId = faceRecord.groupId;
                // personFaceRecord.devicePersonId = faceRecord.devicePersonId
                reIssueGroupFace(tempFaceRecord).then(res=>{
                    this.$message.success(res.data.msg);
                    this.getPersonFaceRecordList();
                })
            },
            delGroupPerson(person, faceRecord){
                let personFaceRecord =JSON.parse(JSON.stringify(person));
                personFaceRecord.groupId = faceRecord.groupId;
                personFaceRecord.devicePersonId = faceRecord.devicePersonId
                personFaceRecord.id = faceRecord.id
                delGroupPerson(personFaceRecord).then(res=>{
                    this.$message.success(res.data.msg);
                    this.getPersonFaceRecordList();
                })
            },
            onSelectChange(selectedRowKeys) {
                console.log('selectedRowKeys changed: ', selectedRowKeys);
                this.selectedRowKeys = selectedRowKeys;
            },
            showBatchIssueModel(){
                this.selectGroupModel.batch = true
                this.getGroupList();
                this.selectGroupModel.visible = true
            },
            batchIssueGroupFace(){
                console.log(this.selectedRowKeys)
                let personList = this.data.filter(person=> this.selectedRowKeys.includes(person.idCard));
                console.log(personList)
                for (let person of personList){
                    console.log(person)
                    let groupIdList = person.personFaceRecordVoList.map(personFaceRecord=> {return personFaceRecord.groupId});
                    groupIdList = groupIdList.concat(this.selectGroupModel.selectGroupIdList)
                    person.groupIdList = groupIdList;
                }
                batchIssueGroupFace(personList).then(res=>{
                    this.$message.success(res.data.msg);
                    this.getPersonFaceRecordList();
                })
            },
            tabsChange(key){
                this.tabsKey = key;
                this.selectedRowKeys = []
                this.getPersonFaceRecordList();
            },
            delLeavePerson(){
                delLeavePerson().then(res=>{
                    this.$message.success(res.data.msg);
                })
            }
        }
    };
</script>
<style scoped>

    .ant-advanced-search-form {
        padding: 24px;
        background: #fbfbfb;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        margin-bottom: 10px;
    }

    .ant-advanced-search-form .ant-form-item {
        display: flex;
    }

    .ant-advanced-search-form .ant-form-item-control-wrapper {
        flex: 1;
    }

    #components-form-demo-advanced-search .ant-form {
        max-width: none;
    }

    tr{
        background-color: #ffffff;
    }
</style>
