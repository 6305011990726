import { QUERY_OFFICE_BY_USER } from "@/services/api";
import { request } from "@/utils/request";
import { mapGetters } from "vuex";
import { ATTACHMENT_UPLOAD } from "@/services/api";



const calcAttach = (item,key) => {
  let attachList = [];
  if (item[key]) {
      attachList = item[key].split(',');
  } 
  attachList = attachList.map((i) => {
      let type = "img";
      // if i 为图片
      if (i.indexOf(".jpg") > -1 || i.indexOf(".png") > -1 || i.indexOf(".jpeg") > -1 || i.indexOf(".gif") > -1 ) {
          type = "img";
      } else if (i.indexOf(".pdf") > -1) {
          type = "pdf";
      } else {
          type = "file";
      }


      return {
          url: i,
          type: type,
      };
  });
  return attachList;
}
const columns = [
  {
      title: "序号",
      align: "center",
      scopedSlots: { customRender: "index" },
      width: 65,
  },
//   {
//       title: "公司",
//       align: "center",
//       dataIndex: "companyName",
//       ellipsis: true,
//       tooltip: true,
//       width: 200,
//   },
//   {
//       title: "分公司",
//       align: "center",
//       dataIndex: "branchOfficeName",
//       ellipsis: true,
//       tooltip: true,
//       width: 200,
//   },
//   {
//       title: "项目名称",
//       dataIndex: "projectName",
//       // width: 200,
//       ellipsis: true,
//       tooltip: true,
//       align: "center",
//   },
  {
      title: "年月",
      dataIndex: "reportTime",
      width: 200,
      align: "center",
  },
  {
      title: "标注后的项目平面图",
      dataIndex: "planarGraph",
      scopedSlots: { customRender: "attach1" },


  },

  {
      title: "操作",
      align: "center",
      fixed: "right",
      width: 260,
      scopedSlots: { customRender: "action" },
  },
];

export default {
  data() {
    return {
        gcglbList: [],
        onlyGcglbList: [],
        isOpen: false,
        columns,

        filePath1: [],
        uploading1: false,
        accept1: "",

        previewVisible: false,
        previewImage: '',
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
    // isBranchOffice() { //分公司
    //     return this.user.isGcglb == 1 && this.user.loginType == 1
    // },
    // isProject() { //项目部
    //     return this.user.loginType == 0
    // },
    // isSafe() { //安全部
    //     return this.user.isGcglb == 0 && this.user.loginType == 1
    // },
    // isProjectLeader(){
    //     // user.nowRoleKey 包含 'project_leader'
    //     return this.user.nowRoleKey.includes('project_leader')
    // },
    // isProjectSafer() {
    //     // user.nowRoleKey 包含 'PROJECT_AQY_ZB_FZR'
    //     return this.user.nowRoleKey.includes('PROJECT_AQY_ZB_FZR')
    // }
  },
  created() {
    request(QUERY_OFFICE_BY_USER, "get").then((res) => {
      let data = res.data.data;
      const arr1 = []
      data.forEach((item) => {
       
        arr1.push({
          label: item.master,
          value: item.id,
        });
      });

      this.onlyGcglbList = arr1;
    });
  },
  methods: {
    calcCount(list,item,index, paramKey) {
      
      let reCount;
      if (index > 0 && item[paramKey] === list[index - 1][paramKey]) {
          reCount = 0;
      } else {
          let count = 1;
          for (let i = index + 1; i < list.length; i++) {
              if (item[paramKey] === list[i][paramKey]) {
                  count++;
              } else {
                  break;
              }
          }
          reCount = count;
      }
      return reCount;
    
    },
    calcAttach,
    handlePreview(file) {
        if(file.type === 'img') {
            this.previewImage = file.url;
            this.previewVisible = true;
          } else {
    
            window.open(file.url);
          }
    },
    beforeUpload1(file) {
        // const accept = '.' + file.name.substring(file.name.lastIndexOf(".") + 1);
        // if (this.accept1.split(',').indexOf(accept) == -1) {
        //   this.$message.error("只能上传PDF文件");
        //   return false
        // }
      },
      handleUploadChange1(info) {
        if (info.file.status === "uploading") {
          this.uploading1 = true;
          return;
        }
        if (info.file.status === "done") {
          this.uploading1 = false;
        }
        if (info.file.status === "removed") {
          this.filePath1 = info.fileList
        }
      },
      resourceUpload1(value) {
        const formData = new FormData();
        formData.append("file", value.file);
        request(ATTACHMENT_UPLOAD, "post", formData).then((result) => {
          if (result.data.code === 0) {
            this.$message.success("上传成功!");
            this.filePath1.push({
              uid: value.file.uid,
              url: result.data.data.path,
              name: value.file.name,
              status: 'done',
            });
            value.onSuccess(result.data.data.path, value.file);
          } else {
            this.$message.warning(result.data.message);
          }
        });
      },
      monthOpenChangeOne(status) {
        if (status) {
            this.isOpen = true;
        }
      },
      monthPanelChangeOne(value) {
          this.isOpen = false;
          this.params.reportTime = value;
  
      },
  },
};
