var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "basic-box" },
    [
      _c(
        "a-descriptions",
        { staticClass: "e-descriptions", attrs: { bordered: "", column: 2 } },
        _vm._l(_vm.descList, function (desc, index) {
          return _c(
            "a-descriptions-item",
            { key: index, attrs: { label: desc.menuText, span: desc.span } },
            [
              _c(
                "div",
                {
                  staticClass: "risk-rules",
                  style: desc.span ? "width: 100%" : "",
                },
                [
                  desc.image
                    ? [
                        desc.value
                          ? _c(
                              "span",
                              {
                                staticClass: "check",
                                on: {
                                  click: function ($event) {
                                    return _vm.modalCheck(
                                      desc.menuText,
                                      "",
                                      "",
                                      desc.value
                                    )
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            )
                          : _c("span", [_vm._v("暂无数据")]),
                      ]
                    : [_vm._v(" " + _vm._s(desc.value) + " ")],
                ],
                2
              ),
            ]
          )
        }),
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.modalTitle,
            destroyOnClose: true,
            width: 800,
            footer: null,
          },
          on: { cancel: _vm.modalCancel },
          model: {
            value: _vm.modalVisible,
            callback: function ($$v) {
              _vm.modalVisible = $$v
            },
            expression: "modalVisible",
          },
        },
        [
          _c(
            "a-descriptions",
            { attrs: { bordered: "", column: 1 } },
            [
              _vm.modalSubTitle
                ? _c(
                    "a-descriptions-item",
                    { attrs: { label: _vm.modalSubTitle } },
                    [_vm._v(" " + _vm._s(_vm.modalContent) + " ")]
                  )
                : _vm._e(),
              _c("a-descriptions-item", { attrs: { label: "附件" } }, [
                _vm.attachmentUrl_2
                  ? _c("img", {
                      staticClass: "attachment-img",
                      attrs: { src: _vm.attachmentUrl_2, alt: "" },
                    })
                  : _vm._e(),
                _vm.attachmentUrl
                  ? _c("img", {
                      staticClass: "attachment-img",
                      attrs: { src: _vm.attachmentUrl, alt: "" },
                    })
                  : _c("span", [_vm._v("暂未上传")]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }