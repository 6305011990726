var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    [
      _c(
        "div",
        [
          _c(
            "a-collapse",
            {
              staticStyle: { "margin-bottom": "20px" },
              model: {
                value: _vm.activeKey,
                callback: function ($$v) {
                  _vm.activeKey = $$v
                },
                expression: "activeKey",
              },
            },
            [
              _c(
                "a-collapse-panel",
                { key: "1", attrs: { header: "进场资料完成情况" } },
                [
                  _c("div", { staticClass: "flex w-100 mb-1" }, [
                    _c(
                      "div",
                      { staticStyle: { width: "50%", height: "240px" } },
                      [
                        _c("div", {
                          staticStyle: { width: "100%", height: "240px" },
                          attrs: { id: "chart" },
                        }),
                      ]
                    ),
                    _c("div", { staticStyle: { "margin-top": "60px" } }, [
                      _c("h2", [_vm._v("情况分析")]),
                      _c("h3", [
                        _vm._v(
                          "当前进场工人资料完成率" +
                            _vm._s(_vm.finishRate) +
                            "%"
                        ),
                      ]),
                      _c("h3", [
                        _vm._v(
                          "资料待完善的工人最久的是" +
                            _vm._s(_vm.farTime) +
                            "；进场至今已" +
                            _vm._s(_vm.farDays) +
                            "天，请及时处理!"
                        ),
                      ]),
                      _c("h3", [
                        _vm._v(
                          "超七天未完善进场资料的工人已达" +
                            _vm._s(_vm.unFinishNums) +
                            "人"
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c("a-form", { attrs: { layout: "horizontal" } }, [
            _c(
              "div",
              [
                _c(
                  "a-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "a-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "a-form-item",
                          {
                            attrs: {
                              label: "姓名",
                              labelCol: { span: 5 },
                              wrapperCol: { span: 16, offset: 1 },
                            },
                          },
                          [
                            _c("a-input", {
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.queryParams.userName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryParams, "userName", $$v)
                                },
                                expression: "queryParams.userName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "a-form-item",
                          {
                            attrs: {
                              label: "身份证号",
                              labelCol: { span: 5 },
                              wrapperCol: { span: 16, offset: 1 },
                            },
                          },
                          [
                            _c("a-input", {
                              attrs: { placeholder: "身份证号" },
                              model: {
                                value: _vm.queryParams.idCard,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryParams, "idCard", $$v)
                                },
                                expression: "queryParams.idCard",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.user.loginType == 1
                      ? _c(
                          "a-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "a-form-item",
                              {
                                attrs: {
                                  labelCol: { span: 5 },
                                  wrapperCol: { span: 16, offset: 1 },
                                  label: "项目名称",
                                },
                              },
                              [
                                _c(
                                  "a-auto-complete",
                                  {
                                    attrs: {
                                      "data-source": _vm.inputDataSource,
                                      placeholder: "请输入项目名称",
                                      allowClear: "",
                                    },
                                    on: {
                                      search: function ($event) {
                                        return _vm.handleInputSearch($event)
                                      },
                                      select: function ($event) {
                                        return _vm.handlInputSelect($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.queryParams.projectId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryParams,
                                          "projectId",
                                          $$v
                                        )
                                      },
                                      expression: "queryParams.projectId",
                                    },
                                  },
                                  [
                                    _c(
                                      "template",
                                      { slot: "dataSource" },
                                      _vm._l(
                                        _vm.inputDataSource,
                                        function (items) {
                                          return _c(
                                            "a-select-option",
                                            { key: items.id + "" },
                                            [_vm._v(_vm._s(items.title))]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "a-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "a-form-item",
                          {
                            attrs: {
                              label: "班组名称",
                              labelCol: { span: 5 },
                              wrapperCol: { span: 16, offset: 1 },
                            },
                          },
                          [
                            _c(
                              "a-select",
                              {
                                attrs: {
                                  "show-search": "",
                                  placeholder: "请输入或选择班组",
                                  "option-filter-prop": "children",
                                  "filter-option": _vm.filterOption,
                                },
                                on: {
                                  focus: _vm.handleFocus,
                                  blur: _vm.handleBlur,
                                  change: _vm.handleChange,
                                },
                                model: {
                                  value: _vm.queryParams.teamId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryParams, "teamId", $$v)
                                  },
                                  expression: "queryParams.teamId",
                                },
                              },
                              _vm._l(_vm.teamDatas, function (item) {
                                return _c(
                                  "a-select-option",
                                  {
                                    key: item.id,
                                    attrs: {
                                      title: item.teamName,
                                      value: item.id,
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(item.teamName) + " ")]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a-space",
                      {
                        staticClass: "ml-1 mb-3",
                        staticStyle: { "margin-top": "4px" },
                      },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: { icon: "search", type: "primary" },
                            on: { click: _vm.handleSubmit },
                          },
                          [_vm._v("查询")]
                        ),
                        _c(
                          "a-space",
                          { staticClass: "operator" },
                          [
                            _c(
                              "a-button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.doReset()
                                  },
                                },
                              },
                              [_vm._v("重置")]
                            ),
                            _c(
                              "a-button",
                              {
                                attrs: { icon: "download" },
                                on: {
                                  click: function ($event) {
                                    return _vm.doDowloadBatch()
                                  },
                                },
                              },
                              [_vm._v("批量下载")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "standard-table",
        {
          attrs: {
            columns: _vm.columns,
            dataSource: _vm.dataSource,
            selectedRows: _vm.selectedRows,
            pagination: _vm.pagination,
            loading: _vm.tableLoading,
            bordered: true,
          },
          on: {
            "update:selectedRows": function ($event) {
              _vm.selectedRows = $event
            },
            "update:selected-rows": function ($event) {
              _vm.selectedRows = $event
            },
            clear: _vm.onClear,
            change: _vm.onPageChange,
          },
          scopedSlots: _vm._u([
            {
              key: "safediscloseFileName",
              fn: function (ref) {
                var text = ref.text
                return _c(
                  "div",
                  {},
                  [
                    text != null && text != ""
                      ? _c("div", [_vm._v(_vm._s(text))])
                      : _c("a-icon", {
                          staticStyle: { color: "red" },
                          attrs: { type: "close" },
                        }),
                  ],
                  1
                )
              },
            },
            {
              key: "do-status",
              fn: function (ref) {
                var text = ref.text
                return _c("div", {}, [
                  text == 1
                    ? _c("span", [
                        _c("i", {
                          staticClass: "iconfont duigouxiao",
                          staticStyle: { color: "#00CE86" },
                        }),
                      ])
                    : _c("span", [
                        _c("i", {
                          staticClass: "iconfont path",
                          staticStyle: { color: "#FF5C77" },
                        }),
                      ]),
                ])
              },
            },
            {
              key: "action",
              fn: function (ref) {
                var record = ref.record
                return _c("div", {}, [
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.doDowload(
                            "" + record.key,
                            "" + record.userName
                          )
                        },
                      },
                    },
                    [_vm._v("下载资料")]
                  ),
                ])
              },
            },
            {
              key: "projectName",
              fn: function (ref) {
                var text = ref.text
                return [
                  _c(
                    "a-tooltip",
                    { attrs: { placement: "topLeft" } },
                    [
                      _c("template", { slot: "title" }, [
                        _c("span", [_vm._v(_vm._s(text))]),
                      ]),
                      _c("span", { staticClass: "ellipsis-2" }, [
                        _vm._v(_vm._s(text)),
                      ]),
                    ],
                    2
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "statusTitle" },
            [
              _c("a-icon", {
                attrs: { type: "info-circle" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onStatusTitleClick($event)
                  },
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _c("Export-loading", {
        attrs: {
          cPercent: _vm.cPercent,
          isExporting: _vm.isExporting,
          loadingText: _vm.loadingText,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }