<template>
  <a-card title="项目详情" class="labour-y">
    <div slot="extra">
      <a-button type="primary" class="mr-4" @click="onSubmit"> 保存 </a-button>
      <a-button type="text" @click="close"> 关闭 </a-button>
    </div>
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      layout="inline"
      style="width: 84%; margin: 0 auto"
    >
      <a-row>
        <a-col :span="8" class="mb-5">
          <a-form-model-item label="项目名称" prop="projectName" >
            <a-input
              v-model="form.projectName"
              style="width: 280px"
              disabled
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="8" class="mb-5">
          <a-form-model-item label="申报情况">
            <a-select
              v-model="form.applyState"
              placeholder=""
              style="width: 280px"
              :disabled="pageType == 2"
            >
              <a-select-option
                v-for="item in applyStateOption"
                :key="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8" class="mb-5">
          <a-form-model-item label="安全文明创优要求" prop="cyyq">
            <a-select
              v-model="form.cyyq"
              placeholder=""
              style="width: 280px"
              :disabled="pageType == 2"
            >
              <a-select-option value="1"> 合同要求 </a-select-option>
              <a-select-option value="2"> 滚动计划 </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8" class="mb-5" v-if="cylevel != 3">
          <a-form-model-item label="项目类别">
            <a-select
              v-model="form.ptype"
              placeholder=""
              style="width: 280px"
              disabled
            >
              <a-select-option :value="1"> 房建 </a-select-option>
              <a-select-option :value="2"> 市政 </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8" class="mb-5" v-if="form.cyyq == 2 && (cylevel == 2 || cylevel == 3)">
          <a-form-model-item label="立项情况">
            <a-select
              v-model="form.projectStatus"
              placeholder=""
              style="width: 280px"
              :disabled="pageType == 2"
            >
              <a-select-option value="已立项"> 已立项 </a-select-option>
              <a-select-option value="未立项"> 未立项 </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>

        <a-col :span="8" class="mb-5" v-if="$route.query.isBook == 1 && (form.cyyq == 2 && (cylevel == 2 || cylevel == 3))">
          <a-form-model-item label="立项完成时间">
            <a-date-picker
              v-model="form.projectDate"
              valueFormat="YYYY-MM-DD"
              placeholder=""
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="8" class="mb-5">
          <a-form-model-item label="授奖单位">
            <a-select
              v-model="awardUnitSelect"
              @change="handleAwardUnitChange"
              placeholder=""
              style="width: 280px"
            >
              <a-select-option
                v-for="item in awardUnitOption"
                :key="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8" class="mb-5" v-if="showOtherAwardUnit">
          <a-form-model-item label="">
            <a-input
              placeholder="请输入授奖单位"
              v-model="form.awardUnit"
              style="width: 280px"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="8" class="mb-5">
          <a-form-model-item label="备注">
            <a-input
              v-model="form.comment"
              style="width: 280px"
            />
          </a-form-model-item>
        </a-col>

        <a-col :span="8" class="mb-5" v-if="cylevel == 1">
          <a-form-model-item label="是否发给协会">
            <a-select
              v-model="form.isSend"
              placeholder=""
              style="width: 280px"
            >
              <a-select-option :value="1"> 是 </a-select-option>
              <a-select-option :value="0"> 否 </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>

        <a-col :span="8" class="mb-5" v-if="form.cyyq == 2 && (cylevel == 2 || cylevel == 3)">
          <a-form-model-item label="上传立项表">
            <a-upload
            
              class="avatar-uploader"
              list-type="picture-card"
              accept=".pdf"
              :show-upload-list="false"
              :customRequest="resourceLXUpload"
            >
              <img
                v-if="form.projectFile && form.projectFile.indexOf('pdf') == -1"
                :src="form.projectFile"
                alt="avatar"
              />
              <a-icon v-else-if="form.projectFile && form.projectFile.indexOf('pdf') > -1" type="file-pdf" :style="{ fontSize: '64px' }" />

              <div v-else>
                <a-icon :type="'plus'" />
                <div class="ant-upload-text">上传立项表</div>
              </div>
            </a-upload>
            <div
              style="color: #bfbfbf;font-size: 14px;margin-top: -28px;"   
            >
              pdf格式
              <div style="margin-top: -20px;">
                <a-button
                  v-if="form.projectFile"
                  size="small"
                  type="link"
                  @click="handlePreview(form.projectFile)"
                >
                  预览
                </a-button>
                
                <a-button
                  v-if="form.projectFile"
                  type="link"
                  @click="handleLXClear"
                >
                  清除
                </a-button>
              </div>
            </div>
          </a-form-model-item>
        </a-col>

        <a-col :span="24" v-if="cylevel != 3">
          <div
            class="p-2 mb-4"
            style="border-bottom: 1px solid #bbbbbb; color: #272727"
          >
            {{ timeTitle }}
          </div>
        </a-col>

        <!-- 市优 -->
        <template v-if="cylevel == 1 || cylevel == 4">
          <a-col :span="8" class="mb-5">
            <a-form-model-item label="基础" prop="cityDateBase">
              <a-radio-group v-model="form.cityDateBaseHave" >
                <a-radio :value="true">
                  有
                </a-radio>
                <a-radio :value="false">
                  无
                </a-radio>
                
              </a-radio-group>
              <div>

                <a-date-picker
                v-if="form.cityDateBaseHave"
                v-model="form.cityDateBase"
                valueFormat="YYYY-MM-DD"
                placeholder=""
                @change="onDateBaseChange"
                :disabled="pageType == 2"
                />
              </div>
            </a-form-model-item>
          </a-col>
          <a-col :span="8" class="mb-5">
            <a-form-model-item label="主体" prop="cityDateMajor">
              <a-radio-group v-model="form.cityDateMajorHave" >
                <a-radio :value="true">
                  有
                </a-radio>
                <a-radio :value="false">
                  无
                </a-radio>
                
              </a-radio-group>
              <div>
                
                <a-date-picker
                v-if="form.cityDateMajorHave"
                v-model="form.cityDateMajor"
                valueFormat="YYYY-MM-DD"
                placeholder=""
                @change="onDateMajorChange"
                :disabled="pageType == 2"
                />
              </div>
            </a-form-model-item>
          </a-col>
          <a-col :span="8" class="mb-5">
            <a-form-model-item label="装饰装修" prop="cityDateDecorate">
              <a-radio-group v-model="form.cityDateDecorateHave" >
                <a-radio :value="true">
                  有
                </a-radio>
                <a-radio :value="false">
                  无
                </a-radio>
                
              </a-radio-group>
              <div>

                <a-date-picker
                v-if="form.cityDateDecorateHave"
                v-model="form.cityDateDecorate"
                valueFormat="YYYY-MM-DD"
                placeholder=""
                @change="onDateDecorateChange"
                :disabled="pageType == 2"
                />
              </div>
            </a-form-model-item>
          </a-col>
        </template>

        <!-- 省优 -->
        <template v-if="cylevel == 2">
          <a-col :span="8" class="mb-5">
            <a-form-model-item label="初评" prop="provinceDateFirst">
              <a-radio-group v-model="form.provinceDateFirstHave" >
                <a-radio :value="true">
                  有
                </a-radio>
                <a-radio :value="false">
                  无
                </a-radio>
                
              </a-radio-group>
              <div>

                <a-date-picker
                v-if="form.provinceDateFirstHave"
                v-model="form.provinceDateFirst"
                valueFormat="YYYY-MM-DD"
                placeholder=""
                :disabled="pageType == 2"
                />
              </div>
            </a-form-model-item>
          </a-col>
          <a-col :span="8" class="mb-5">
            <a-form-model-item label="复评" prop="provinceDateSecond">
              <a-radio-group v-model="form.provinceDateSecondHave" >
                <a-radio :value="true">
                  有
                </a-radio>
                <a-radio :value="false">
                  无
                </a-radio>
                
              </a-radio-group>
              <div>

                <a-date-picker
                v-if="form.provinceDateSecondHave"
                v-model="form.provinceDateSecond"
                valueFormat="YYYY-MM-DD"
                placeholder=""
                :disabled="pageType == 2"
                />
              </div>
            </a-form-model-item>
          </a-col>
        </template>

        <!-- 国优 -->
        <template v-if="cylevel == 3">
          <a-col :span="24" class="mb-5">
            <a-form-model-item
              label="国家级安全生产标准化简评时间"
              prop="countryDateStandard"
            >
            <a-radio-group v-model="form.countryDateStandardHave" :disabled="pageType == 2">
                <a-radio :value="true">
                  有
                </a-radio>
                <a-radio :value="false">
                  无
                </a-radio>
                
              </a-radio-group>
              <div>

                <a-date-picker
                v-if="form.countryDateStandardHave"
                v-model="form.countryDateStandard"
                valueFormat="YYYY-MM-DD"
                placeholder=""
                :disabled="pageType == 2"
                />
              </div>
            </a-form-model-item>
          </a-col>
        </template>

        <a-col :span="24">
          <a-row :gutter="48">
            <a-col
              :span="12"
              v-for="(item, index) in certificates"
              :key="index"
              class="mb-3"
            >
              <a-card size="small" hoverable>
                <div slot="title" v-if="cylevel != 4">
                  {{ item.certificateName }}
                </div>
                <div v-if="cylevel == 4" class="mb-3">
                  <a-form-model-item label="奖项名称">
                    <a-select
                      
                      show-search
                      v-model="item.certificateName"
                      placeholder="输入关键字搜索"
                      style="width: 280px"
                      :default-active-first-option="true"
                      :show-arrow="false"
                      :filter-option="false"
                      @search="remoteSearch($event, index)"
                    >
                      <a-select-opt-group
                        label="现有证书"
                       
                      >
                        <a-select-option
                          v-for="d in item.options"
                          :key="d.certificateName"
                        >
                          {{ d.certificateName }}
                        </a-select-option>
                      </a-select-opt-group>
                      <a-select-opt-group
                        label="新增证书"
                       
                      >
                        <a-select-option
                          v-for="d in item.optionsAdd"
                          :key="d.certificateName"
                        >
                          {{ d.certificateName }}
                        </a-select-option>
                      </a-select-opt-group>
                    </a-select>
                  </a-form-model-item>
                </div>
                <div class="mb-3">
                  <a-form-model-item label="获奖时间">
                    <a-month-picker
                      
                      v-model="item.certificateDate"
                      valueFormat="YYYY-MM"
                      placeholder=""
                    />
                  </a-form-model-item>
                </div>
                <div class="mb-3">
                  <a-form-model-item label="项目证书编号">
                    <a-input
                   
                      v-model="item.certificateNum"
                      style="width: 280px"
                    />
  
                    <div v-if="cylevel == 1">
                      <span v-for="t in codeDes" :key="t" class="mr-2"
                        @click="handleCopyCode(item, t)"
                        style="border: 1px solid rgba(187, 187, 187, 1);border-radius: 20px;padding: 4px;"
                      >{{ t }}</span>
                    </div>
                  </a-form-model-item>
                </div>
                <div class="mb-3">
                  <a-row :gutter="48">
                    <a-col :span="12">
                      <a-form-model-item label="项目证书">
                        <a-upload
                        
                          class="avatar-uploader"
                          list-type="picture-card"
                          accept=".png, .jpg, .jpeg,.pdf"
                          :show-upload-list="false"
                          @change="handleChange($event, index)"
                          :customRequest="item.customRequest"
                        >
                          <img
                            v-if="item.certificatePaths && item.certificatePaths.indexOf('pdf') == -1"
                            :src="item.certificatePaths"
                            alt="avatar"
                          />
                          <a-icon v-else-if="item.certificatePaths && item.certificatePaths.indexOf('pdf') > -1" type="file-pdf" :style="{ fontSize: '64px' }" />

                          <div v-else>
                            <a-icon :type="item.loading ? 'loading' : 'plus'" />
                            <div class="ant-upload-text">上传证书</div>
                          </div>
                        </a-upload>
                        <div
                          style="color: #bfbfbf;font-size: 14px;margin-top: -28px;"   
                        >
                          图片或pdf格式
                          <div style="margin-top: -20px;">
                            <a-button
                              v-if="item.certificatePaths"
                              size="small"
                              type="link"
                              @click="handlePreview(item.certificatePaths)"
                            >
                              预览
                            </a-button>
                            
                            <a-button
                              v-if="item.certificatePaths"
                              type="link"
                              @click="handleClear(item, index, 1)"
                            >
                              清除
                            </a-button>
                          </div>
                        </div>
                      </a-form-model-item>
                    </a-col>
                    <a-col :span="12"  v-if="(cylevel == 2 && form.ptype == 1) || cylevel == 1">
                      <a-form-model-item label="个人证书">
                        <a-upload
                        
                          class="avatar-uploader"
                          list-type="picture-card"
                          accept=".png, .jpg, .jpeg,.pdf"
                          :show-upload-list="false"
                          @change="handleChangePrivate($event, index)"
                          :customRequest="item.privatecustomRequest"
                        >
                          <img
                            v-if="item.certificatePathsPrivate && item.certificatePathsPrivate.indexOf('pdf') == -1"
                            :src="item.certificatePathsPrivate"
                            alt="avatar"
                          />
                          <a-icon v-else-if="item.certificatePathsPrivate && item.certificatePathsPrivate.indexOf('pdf') > -1" type="file-pdf" :style="{ fontSize: '64px' }" />
                          <div v-else>
                            <a-icon :type="item.privateLoading ? 'loading' : 'plus'" />
                            <div class="ant-upload-text">上传证书</div>
                          </div>
                        </a-upload>
                        <div
                          style="color: #bfbfbf;font-size: 14px;margin-top: -28px;"   
                        >
                          图片或pdf格式
                          <div style="margin-top: -20px;">
                            <a-button
                              v-if="item.certificatePathsPrivate"
                              size="small"
                              type="link"
                              @click="handlePreview(item.certificatePathsPrivate)"
                            >
                              预览
                            </a-button>
                            
                            <a-button
                              v-if="item.certificatePathsPrivate"
                              type="link"
                              @click="handleClear(item, index, 2)"
                            >
                              清除
                            </a-button>
                          </div>
                        </div>
                      </a-form-model-item>
                    </a-col>
                  </a-row>
                  
                  <!-- <div style="float: right; margin-top: 70px">
                    <a-button
                      type="primary"
                      v-if="item.ledger === 0 && item.excellentId"
                      @click="changeLedgerStatus(item)"
                    >
                      流转到台账
                    </a-button>
                  </div> -->
                </div>
              </a-card>
            </a-col>
            <a-col :span="24">
              <div style="display: flex;justify-content: center;" class="p-4" v-if="certificates[0].ledger != 1 && certificates[0].excellentId">
                <a-button
                  class="mr-3"
                  @click="changeLedgerStatus(1)"
                  >
                  未通过创优
                </a-button>
                <a-button
                  type="primary"
                  @click="changeLedgerStatus(0)"
                  >
                  流转到台账
                </a-button>
              </div>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </a-form-model>
  </a-card>
</template>

<script>
import {
  getExcellentDetailByexcellentId,
  updateExcellent,
  getExcellentCerDic,
  insertExcellentCerDic,
} from "@/services/labourProjectExcellent";
import { ATTACHMENT_UPLOAD } from "@/services/api";
import { request } from "@/utils/request";
import { mapGetters } from "vuex";

/**
 * @description 项目创优详情
 * 四种类别，在表单上有所区别；
 */
export default {
  name: "LabourProjectExcellentDetail",
  inject: ["fatherMethod"],
  data() {
    return {
      cylevel: "",
      labelCol: { span: 5 },
      wrapperCol: { span: 14 },
      other: "",
      loading: false,
      imageUrl: "",
      applyStateOption: [
        { label: "未申报", value: 1 },
        { label: "已申报", value: 2 },
      ],
      awardUnitOption: [
        { label: "广州市建筑业联合会", value: '广州市建筑业联合会' },
        { label: "广州市市政公路协会", value: '广州市市政公路协会' },
        { label: "广东省建筑安全协会", value: '广东省建筑安全协会' },
        { label: "广东省市政行业协会", value: '广东省市政行业协会' },
        { label: "其他", value: '其他' },
      ],
      form: {
        projectName: "",
        applyState: undefined,
        awardUnit: '',
        cyyq: "",
        ptype: "",
        projectStatus: "",
        comment: "",

        cityDateBase: "",
        cityDateDecorate: "",
        cityDateMajor: "",

        provinceDateFirst: "",
        provinceDateSecond: "",

        countryDateStandard: "",

        certificateDate: "",
        certificateName: "",
        certificateNum: "",
        certificatePaths: "",
        projectFile: "",
        projectDate: "",
        isSend: 0
      },
      certificates: [
        {
          certificateDate: null,
          certificateName: "",
          certificateNum: "",
          certificatePaths: "",
          certificateCode: "",
          excellentId: "",
          ledger: 0,
          options: [],
          optionsAdd: [],
        },
      ],
      rules: {
        cyyq: [{ required: true, message: '请选择安全文明创优要求', trigger: 'change' }],
      },
      options: [],
      optionsAdd: [],
      needAddCert: false,

      codeDes: ['穗建安文绿证字：','穗建安文绿示范：'],
      showOtherAwardUnit: false,
      awardUnitSelect: ''
    };
  },

  created() {
    this.init();
  },
  mounted() {},
  watch: {
    cylevel: {
      handler(val) {
        if (val == 1) {
          this.awardUnitOption = [
            { label: "广州市建筑业联合会", value: '广州市建筑业联合会' },
            { label: "广州市市政公路协会", value: '广州市市政公路协会' },
            { label: "其他", value: '其他' },
          ]
        } else if (val == 2) {
          this.awardUnitOption = [
            { label: "广东省建筑安全协会", value: '广东省建筑安全协会' },
            { label: "广东省市政行业协会", value: '广东省市政行业协会' },
            { label: "其他", value: '其他' },
          ]
        } else if (val == 3) {
          this.awardUnitOption = [
            { label: "其他", value: '其他' },
          ]
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters("account", ["user"]),
    timeTitle() {
      let title = "";
      if (this.cylevel === "1") title = "市双优检评时间";
      if (this.cylevel === "2") title = "省优检评时间";
      if (this.cylevel === "3") title = "国家级安全生产标准化简评时间";
      if (this.cylevel === "4") title = "创优检评时间";
      return title;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // if query 发生改变的情况下，需要重新获取数据
      vm.init();
    });
  },
  methods: {
    init() {
      this.cylevel = this.$route.query.cylevel;
      this.pageType = this.$route.query.pageType;
      this.queryDetail();
    },
    queryDetail() {
      getExcellentDetailByexcellentId(this.$route.query.id).then((res) => {
        if (res.data.code === 0) {
          this.form = this.handleCreateForm(res.data.data.excellentInfo);
          if (this.form.awardUnit && this.awardUnitOption.some(el => el.value == this.form.awardUnit)) {
            this.awardUnitSelect = this.form.awardUnit
            this.showOtherAwardUnit = false
          } else {
            this.awardUnitSelect = '其他'
            this.showOtherAwardUnit = true
          }

          let cert = res.data.data.certificates;
          if (cert && cert.length > 0) {
            // 其他情况下，需要对 remote options 处理
            if (this.cylevel != "4") {
              this.certificates = cert.map((item, index) => {
                return {
                  ...item,
                  certID: item.id,
                  loading: false,
                  beforeUpload: (file) => this.beforeUpload(file, index),
                  handleChange: (file) => this.handleChange(file, index),
                  customRequest: (file) => this.resourceUpload(file, index),

                  privateLoading: false,
                  privateBeforeUpload: (file) => this.privateBeforeUpload(file, index),
                  privatecustomRequest: (file) => this.resourceUploadPrivate(file, index),


                };
              });
            } else {
              this.certificates = cert.map((item, index) => {
                // 搜索
                let options = [{certificateName: item.certificateName, certificateCode: item.certificateCode, }]
                return {
                  ...item,
                  certID: item.id,
                  loading: false,
                  beforeUpload: (file) => this.beforeUpload(file, index),
                  handleChange: (file) => this.handleChange(file, index),
                  customRequest: (file) => this.resourceUpload(file, index),
                  options: options,
                  optionsAdd: [],
                  remoteSearch: (value) => this.remoteSearch(value, index),

                  privateLoading: false,
                  privateBeforeUpload: (file) => this.privateBeforeUpload(file, index),
                  privatecustomRequest: (file) => this.resourceUploadPrivate(file, index),
                };
              });
            }
            console.log(this.certificates);
          } else {
            this.handleCertView();
          }
        }
      });
    },
    onSubmit(item) {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          // 根据 cylevel 的不同提交的参数也不同
          let params = await this.createParams();
          if (params) {
            if (item == 1) params.excellentInfo.isFailed = 1//是否通过创优，0.是，1.否
            updateExcellent(params).then((res) => {
              if (res.data.code === 0) {
                this.$message.success("保存成功");

                let path;
                // 根据类型跳转 cylevel pageType
                if (this.pageType == 1) {
                  // 创优列表
                  path = '/project_list'
                } else {
                  // 台账 cylevel 1 市级 2 省级 3 国家级 4 市级其他
                  if (this.cylevel == 1) {
                    path = '/book_list_1'
                  } else if (this.cylevel == 2) {
                    path = '/book_list_2'
                  } else if (this.cylevel == 3) {
                    path = '/book_list_3'
                  } else if (this.cylevel == 4) {
                    path = '/book_list_1'
                  }
                  

                }
                this.close();
                this.$router.push({
                  path: path,
                })
              } else {
                this.$message.error(res.data.msg);
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    async createParams() {
      // cylevel 1 市级 2 省级 3 国家级 4 市级其他
      let { projectName, applyState, awardUnit, cyyq, projectId, cylevel, id, ledger, projectFile, projectDate, comment } =
        this.form;

      // 公共参数 项目名称 申报状态 创优要求
      let params = {
        projectName,
        applyState,
        awardUnit,
        cyyq,
        projectId,
        cylevel,
        id,
        ledger,
        projectFile,
        projectDate,
        comment
      };

      // 市级
      if (this.cylevel === "1" || this.cylevel === "4") {
        let { cityDateBase, cityDateDecorate, cityDateMajor, ptype,
          cityDateBaseHave, cityDateDecorateHave, cityDateMajorHave, isSend
        } =
          this.form;
          // todo 时间解析
        if (!cityDateBaseHave) {
          cityDateBase = "/";
        }
        if (!cityDateDecorateHave) {
          cityDateDecorate = "/";
        }
        if (!cityDateMajorHave) {
          cityDateMajor = "/";
        }

        params = {
          ...params,
          cityDateBase,
          cityDateDecorate,
          cityDateMajor,
          ptype,
          isSend
        };
      }

      // 省级
      if (this.cylevel === "2") {
        let { provinceDateFirst, provinceDateSecond, ptype,
        provinceDateFirstHave, provinceDateSecondHave,
        projectStatus
        } = this.form;
        if (!provinceDateFirstHave) {
          provinceDateFirst = "/";
        }
        if (!provinceDateSecondHave) {
          provinceDateSecond = "/";
        }

        // projectStatus
        if (cyyq != 2) {
          
          projectStatus = '/'
        }
        params = {
          ...params,
          provinceDateFirst,
          provinceDateSecond,
          ptype,
          projectStatus
        };
      }

      // 国家级
      if (this.cylevel === "3") {
        let { countryDateStandard, countryDateStandardHave, projectStatus, comment } = this.form;
        if (!countryDateStandardHave) {
          countryDateStandard = "/";
        }

        if (cyyq != 2) {
          
          projectStatus = '/'
        }
        params = {
          ...params,
          countryDateStandard,
          projectStatus
        };
      }

      // 证书
      let certificates;

      if (this.cylevel != 4) {
        certificates = this.certificates.map((item) => {
          let {
            certificateCode,
            certificateDate,
            certificateName,
            certificateNum,
            certificatePaths,
            certificatePathsPrivate,
            certID,
            ledger,
          } = item;
          // 不同级别取值有差别
          return {
            certificateCode,
            certificateDate,
            certificateName,
            certificateNum,
            certificatePaths,
            certificatePathsPrivate,
            created: this.user.id,
            excellentId: id,
            id: certID,
            ledger
          };
        });
      }

      if (this.cylevel === "4") {
        let c1 = null
        let c2 = null
        if (this.certificates.length == 2) {
          c1 = await this.cartCertForOther(0);
          c2 = await this.cartCertForOther(1)
        } else {
          c1 = await this.cartCertForOther(0);
        }
        certificates = [];
        if (c1) {
          certificates.push(c1);
        }
        if (c2) {
          certificates.push(c2);
        }
        if (certificates.length === 0) {
          this.$message.error('请输入奖项名称');
          return
        }
      }

      return {
        certificates,
        excellentInfo: params,
      };
    },
    async cartCertForOther(index) {
      // 市优其他
      let {  id } = this.form;
      let certificates = {};
      let n = this.certificates[index].certificateName;
        // options 中查看是否存在
        let isExist = this.certificates[index].options.some((item) => {
          return item.certificateName === n;
        });
        let cert = this.certificates[index].options.find((item) => {
          return item.certificateName === n;
        });
        let certForm = this.certificates[index];

        if (isExist) {
          let { certificateCode } = cert;
          let { certificateName, certificateDate, certificatePaths, certID, ledger, certificatePathsPrivate } = certForm;

          certificates = 
            {
              certificateCode,
              certificateName,
              certificateDate,
              certificatePaths,
              certificatePathsPrivate,
              created: this.user.id,
              excellentId: id,
              id: certID,
              ledger
            }
          
        } else {
          // 先新增证书
          let params = {
            certificateName: n,
            certificateLevel: 4,
            certificateType: this.form.ptype,
            isOther: 1,
          };
          if (params.certificateName) {
            let req = await insertExcellentCerDic(params);
  
            if (req.data.code === 0) {
              let { certificateCode } = req.data.data;
  
              let { certificateName, certificateDate, certificatePaths, certID, certificatePathsPrivate } =
                certForm;
  
              certificates = 
                {
                  certificateCode,
                  certificateName,
                  certificateDate,
                  certificatePaths,
                  certificatePathsPrivate,
                  created: this.user.id,
                  excellentId: id,
                  id: certID,
                  ledger: 0
                }
              
            } else {
              this.$message.error(req.data.msg);
              return false;
            }
          } else {
            return false;
          }
        }
        return certificates
    },
    handleCreateForm(d) {
      // cylevel 1 市级 2 省级 3 国家级 4 市级其他
      // 时间 cityDateBase cityDateDecorate cityDateMajor；provinceDateFirst provinceDateSecond；countryDateStandard; 
      // 存在以下值情况  null, / ， Date
      // 生成对应的是否为 / 字段
      if (this.cylevel === "1" || this.cylevel === "4") {
        let { cityDateBase, cityDateDecorate, cityDateMajor } = d;
        if (cityDateBase == '/') {
          d.cityDateBaseHave = false;
          d.cityDateBase = null;
        } else {
          d.cityDateBaseHave = true;
        }

        if (cityDateDecorate == '/') {
          d.cityDateDecorateHave = false;
          d.cityDateDecorate = null;
        } else {
          d.cityDateDecorateHave = true;
        }

        if (cityDateMajor == '/') {
          d.cityDateMajorHave = false;
          d.cityDateMajor = null;
        } else {
          d.cityDateMajorHave = true;
        }
      }

      if (this.cylevel === "2") {
        let { provinceDateFirst, provinceDateSecond } = d;
        if (provinceDateFirst == '/') {
          d.provinceDateFirstHave = false;
          d.provinceDateFirst = null;

        } else {
          d.provinceDateFirstHave = true;
        }

        if (provinceDateSecond == '/') {
          d.provinceDateSecondHave = false;
          d.provinceDateSecond = null;
        } else {
          d.provinceDateSecondHave = true;
        }
      }

      if (this.cylevel === "3") {
        let { countryDateStandard } = d;
        if (countryDateStandard == '/') {
          d.countryDateStandardHave = false;
          d.countryDateStandard = null;
        } else {
          d.countryDateStandardHave = true;
        }
      }

      // projectStatus 状态的解析
      

      return d
    },
    handleCertView() {
      let params = new FormData();
      params.append("level", this.cylevel);

      if (this.cylevel != 3) {
        params.append("pType", this.form.ptype);
      }

      if (this.cylevel === "4") {
        params.append("isOther", 1);
      } else {
        params.append("isOther", 0);
      }

      if (this.cylevel != "4") {
        getExcellentCerDic(params).then((res) => {
          if (res.data.code === 0) {
            if (res.data.data) {
              this.certificates = res.data.data.map((item, index) => {
                return {
                  ...item,
                  certID: null,
                  certificateDate: null,
                  certificateNum: "",
                  certificatePaths: "",
                  ledger: 0,
                  loading: false,
                  beforeUpload: (file) => this.beforeUpload(file, index),
                  handleChange: (file) => this.handleChange(file, index),
                  customRequest: (file) => this.resourceUpload(file, index),

                  privateLoading: false,
                  privateBeforeUpload: (file) => this.privateBeforeUpload(file, index),
                  privatecustomRequest: (file) => this.resourceUploadPrivate(file, index),
                };
              });
            }
          }
        });
      } else {
        // 市级其他
        this.certificates = [];
        for (let index = 0; index < 2; index++) {
          this.certificates.push({
            certificateName: "",
            certificateDate: null,
            certificateNum: "",
            certificatePaths: "",
            ledger: 0,
            loading: false,
            beforeUpload: (file) => this.beforeUpload(file, index),
            handleChange: (file) => this.handleChange(file, index),
            customRequest: (file) => this.resourceUpload(file, index),
            options: [],
            optionsAdd: [],
            remoteSearch: (value) => this.remoteSearch(value, index),

            privateLoading: false,
            privateBeforeUpload: (file) => this.privateBeforeUpload(file, index),
            privatecustomRequest: (file) => this.resourceUploadPrivate(file, index),
          });
        }
        // this.certificates = this.certificates.map((item, index) => {
        //   return {
        //     ...item,

        //     loading: false,
        //     beforeUpload: (file) => this.beforeUpload(file, index),
        //     handleChange: (file) => this.handleChange(file, index),
        //     customRequest: (file) => this.resourceUpload(file, index),
        //   };
        // });
      }
    },
    handleChange(info, index) {
      if (info.file.status === "uploading") {
        this.certificates[index].loading = true;
        return;
      }
      if (info.file.status === "done") {
        this.certificates[index].loading = false;
      }
    },
    handleChangePrivate(info, index) {
      if (info.file.status === "uploading") {
        this.certificates[index].privateLoading = true;
        return;
      }
      if (info.file.status === "done") {
        this.certificates[index].privateLoading = false;
      }
    },
    beforeUpload(file, index) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("You can only upload JPG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("Image must smaller than 2MB!");
      }
      return isJpgOrPng && isLt2M;
    },
    resourceUpload(value, index) {
      const formData = new FormData();
      formData.append("file", value.file);

      request(ATTACHMENT_UPLOAD, "post", formData).then((result) => {
        if (result.data.code === 0) {
          this.$message.success("上传成功!");
          this.certificates[index].certificatePaths = result.data.data.path;
          value.onSuccess(result.data.data.path, value.file);
        } else {
          this.$message.warning(result.data.message);
        }
      });
    },
    resourceLXUpload(value) {
      const formData = new FormData();
      formData.append("file", value.file);

      request(ATTACHMENT_UPLOAD, "post", formData).then((result) => {
        if (result.data.code === 0) {
          this.$message.success("上传成功!");
          this.form.projectFile = result.data.data.path;
          value.onSuccess(result.data.data.path, value.file);
        } else {
          this.$message.warning(result.data.message);
        }
      });
    },
    resourceUploadPrivate(value, index) {
      const formData = new FormData();
      formData.append("file", value.file);

      request(ATTACHMENT_UPLOAD, "post", formData).then((result) => {
        if (result.data.code === 0) {
          this.$message.success("上传成功!");
          this.certificates[index].certificatePathsPrivate = result.data.data.path;
          value.onSuccess(result.data.data.path, value.file);
        } else {
          this.$message.warning(result.data.message);
        }
      });
    },
    close() {
      let path = this.$route.path;
      this.fatherMethod(`${path}`);
    },
    remoteSearch(query, index) {
      if (query !== "") {
        this.loading = true;
        let params = new FormData();
        // params.append("level", this.cylevel);
        // params.append("pType", this.form.ptype);
        // params.append("isOther", 1);
        params.append("keyword", query);

        getExcellentCerDic(params).then((res) => {
          if (res.data.code === 0) {
            if (res.data.data) {
              this.certificates[index].options = res.data.data.map((item) => {
                return {
                  ...item,
                };
              });
            }
            this.certificates[index].optionsAdd = [
              {
                certificateName: query,
              },
            ];
          }
        });
      } else {
        this.options = [];
      }
    },
    changeLedgerStatus(item) {
      // certificates 中查找 item,并设置 item中的 ledger
      // let index = this.certificates.findIndex((i) => i.id === item.id);
      // this.certificates[index].ledger = 1;
      // console.log( this.certificates[index].ledger)
      // this.onSubmit();
      
      // set all of certificates ledger equal 1
      if (item == 1) {
        const _that = this
        this.$confirm({
          title: '是否确定未通过创优？',
          onOk() {
            _that.onSubmit(item);
          }
        })
      } else {
        this.certificates.forEach((item) => {
          item.ledger = 1;
        });
        this.onSubmit(item);
      }
      
    },
    handleCopyCode(item, t) {
      item.certificateNum = t;
    },
    handlePreview(url) {
     
        window.open(url);
      
    },
    handleClear(item,index, t) {
      if (t == 1) {
        this.certificates[index].certificatePaths = "";
      } else {
        this.certificates[index].certificatePathsPrivate = "";
      }
    },
    handleLXClear() {
      this.form.projectFile = "";
    },
    onDateMajorChange(date, dateString) {
      this.form.cityDateMajor = dateString
    },
    onDateBaseChange(date, dateString) {
      this.form.cityDateBase = dateString
    },
    onDateDecorateChange(date, dateString) {
      this.form.cityDateDecorate = dateString
    },
    handleAwardUnitChange(value) {
      console.log(value)
      if (value == '其他') {
        this.showOtherAwardUnit = true
        this.form.awardUnit = ''
      } else {
        this.showOtherAwardUnit = false
        this.form.awardUnit = value
      }
    }
  },
};
</script>

<style lang="less" scoped>
@import "@/theme/common.less";

.labour-y {
  .avatar-uploader > .ant-upload {
    img {
      width: 86px;
      height: 86px;
    }
  }
  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }

  .ant-form-item-with-help {
    margin-bottom: 0;
  }
}
.star-comment {
  :nth-child(2) {
    vertical-align: middle;
  }
}

</style>
