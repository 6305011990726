var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form",
        { staticStyle: { "max-width": "500px", margin: "40px auto 0" } },
        [
          _c("a-alert", {
            staticStyle: { "margin-bottom": "24px" },
            attrs: { closable: true, message: _vm.$t("note") },
          }),
          _c(
            "a-form-item",
            {
              staticClass: "stepFormText",
              attrs: {
                label: _vm.$t("payment"),
                labelCol: { span: 7 },
                wrapperCol: { span: 17 },
              },
            },
            [_vm._v(" ant-design@alipay.com ")]
          ),
          _c(
            "a-form-item",
            {
              staticClass: "stepFormText",
              attrs: {
                label: _vm.$t("collection"),
                labelCol: { span: 7 },
                wrapperCol: { span: 17 },
              },
            },
            [_vm._v(" test@example.com ")]
          ),
          _c(
            "a-form-item",
            {
              staticClass: "stepFormText",
              attrs: {
                label: _vm.$t("collectionName"),
                labelCol: { span: 7 },
                wrapperCol: { span: 17 },
              },
            },
            [_vm._v(" Alex ")]
          ),
          _c(
            "a-form-item",
            {
              staticClass: "stepFormText",
              attrs: {
                label: _vm.$t("transferAmount"),
                labelCol: { span: 7 },
                wrapperCol: { span: 17 },
              },
            },
            [_vm._v(" ￥ 5,000.00 ")]
          ),
          _c(
            "a-form-item",
            { attrs: { wrapperCol: { span: 17, offset: 7 } } },
            [
              _c(
                "a-button",
                {
                  attrs: { loading: _vm.loading, type: "primary" },
                  on: { click: _vm.nextStep },
                },
                [_vm._v(_vm._s(_vm.$t("submit")))]
              ),
              _c(
                "a-button",
                {
                  staticStyle: { "margin-left": "8px" },
                  on: { click: _vm.prevStep },
                },
                [_vm._v(_vm._s(_vm.$t("preStep")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }