var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "a-card",
        { attrs: { "body-style": { padding: "0px" }, bordered: false } },
        [
          _c(
            "div",
            { attrs: { id: "components-form-demo-advanced-search" } },
            [
              _c(
                "a-form",
                { staticClass: "ant-advanced-search-form" },
                [
                  _c(
                    "a-row",
                    { attrs: { gutter: 24 } },
                    [
                      _vm.canOperate
                        ? _c(
                            "a-col",
                            { attrs: { span: 3 } },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.handleUpload },
                                },
                                [
                                  _c("a-icon", { attrs: { type: "upload" } }),
                                  _vm._v(" 导入工人考勤 "),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.canOperate
                        ? _c(
                            "a-col",
                            { attrs: { span: 3 } },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.managerAtt },
                                },
                                [_vm._v(" 填写管理人员考勤 ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "a-col",
                        { attrs: { span: 5 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "姓名" } },
                            [
                              _c("a-input", {
                                attrs: { placeholder: "请输入查询姓名" },
                                model: {
                                  value: _vm.query.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.query, "name", $$v)
                                  },
                                  expression: "query.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "search",
                                loading: _vm.loading,
                              },
                              on: { click: _vm.handleSearch },
                            },
                            [_vm._v(" 查询 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("standard-table", {
            attrs: {
              columns: _vm.columns,
              "data-source": _vm.data,
              pagination: _vm.pagination,
              rowKey: "id",
              loading: _vm.loading,
              isAlert: false,
              bordered: false,
            },
            on: { change: _vm.onPageChange },
            scopedSlots: _vm._u([
              {
                key: "titleWithTooltip",
                fn: function () {
                  return [
                    _c(
                      "a-tooltip",
                      [
                        _c("template", { slot: "title" }, [
                          _vm._v(
                            "设备直联补报，遇到重名报错，可手动填写建委staffId"
                          ),
                        ]),
                        _vm._v(" 建委staffId"),
                        _c("a-icon", {
                          staticStyle: { "margin-left": "5px" },
                          attrs: { type: "question-circle", theme: "twoTone" },
                        }),
                      ],
                      2
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "index",
                fn: function (ref) {
                  var index = ref.index
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            (_vm.pagination.current - 1) *
                              _vm.pagination.pageSize +
                              parseInt(index) +
                              1
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "action",
                fn: function (ref) {
                  var text = ref.text
                  var record = ref.record
                  return [
                    _c("div", { staticClass: "sTables" }, [
                      _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.edit(record)
                            },
                          },
                        },
                        [_vm._v("填写建委staffId")]
                      ),
                      _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.del(record)
                            },
                          },
                        },
                        [_vm._v("删除")]
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "checkType",
                fn: function (ref) {
                  var text = ref.text
                  return [
                    _vm._v(" " + _vm._s(text == 1 ? "进场" : "出场") + " "),
                  ]
                },
              },
              {
                key: "personType",
                fn: function (ref) {
                  var text = ref.text
                  return [
                    _vm._v(" " + _vm._s(text == 1 ? "工人" : "管理人员") + " "),
                  ]
                },
              },
              {
                key: "atteImg",
                fn: function (ref) {
                  var text = ref.text
                  return [
                    _c("a-avatar", {
                      attrs: {
                        shape: "square",
                        size: 64,
                        src: text,
                        icon: "user",
                      },
                    }),
                  ]
                },
              },
              {
                key: "pullStatus",
                fn: function (ref) {
                  var text = ref.text
                  return [
                    text == 1
                      ? _c("span", [
                          _c("i", {
                            staticClass: "iconfont duigouxiao",
                            staticStyle: { color: "#00CE86" },
                          }),
                        ])
                      : _c("span", [
                          _c("i", {
                            staticClass: "iconfont path",
                            staticStyle: { color: "#FF5C77" },
                          }),
                        ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { visible: _vm.uploadWorkerModal, footer: null },
          on: { cancel: _vm.cancelUploadWorkerModal },
        },
        [
          _c(
            "div",
            { staticClass: "flex flex-column a-center" },
            [
              _c("h4", { staticClass: "mb-2" }, [
                _vm._v("只能导入工人考勤数据，并且3天内的"),
              ]),
              _c(
                "a-upload",
                {
                  attrs: {
                    action: _vm.jwBatchApplyAddUrl,
                    headers: _vm.headers,
                    "file-list": _vm.fileList,
                    accept: ".xls,.xlsx",
                    beforeUpload: _vm.beforeUpload,
                  },
                  on: { change: _vm.handleChange },
                },
                [
                  _c(
                    "div",
                    [
                      _c("a-button", { attrs: { type: "primary" } }, [
                        _vm._v(" 确认导入 "),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: { visible: _vm.managerModal, width: 680 },
          on: { ok: _vm.managerOk, cancel: _vm.managerCancel },
        },
        [
          _c("form-index", {
            ref: "formindex",
            attrs: { formData: _vm.formData },
            on: { upRemove: _vm.upRemove, resourceUpload: _vm.resourceUpload },
          }),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { visible: _vm.editModal, width: 680 },
          on: { ok: _vm.editOk, cancel: _vm.editCancel },
        },
        [
          _c("form-index", {
            ref: "editForm",
            attrs: { formData: _vm.editFormData },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }