<template>
  <a-card :bordered="false">
    <a-spin :spinning="loading">
    <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item :wrapper-col="{ span: 24 }">
        <a-card :bordered="false">
          <template #title>
            <div class="ml-2 title">紧急联系人信息</div>
          </template>
          <a-col class="ml-1 my-2">
            <a-switch v-model="form.enterSet.emergency.isOpen" />
            <span class="text-color ml-1">设为必填项</span>
          </a-col>
          <a-col :span="3" class="ml-5">
            <a-checkbox v-model="form.enterSet.emergency.emergencyUserName" :disabled="!form.enterSet.emergency.isOpen"><span
                class="text-color">联系人姓名 </span></a-checkbox>
          </a-col>
          <a-col :span="3">
            <a-checkbox v-model="form.enterSet.emergency.emergencyUserPhone" :disabled="!form.enterSet.emergency.isOpen"><span
                class="text-color">联系人电话 </span></a-checkbox>
          </a-col>
          <a-col :span="3">
            <a-checkbox v-model="form.enterSet.emergency.emergencyAddress" :disabled="!form.enterSet.emergency.isOpen"><span
                class="text-color">联系地址 </span></a-checkbox>
          </a-col>
          <a-col :span="3">
            <a-checkbox v-model="form.enterSet.emergency.emergencyRelation" :disabled="!form.enterSet.emergency.isOpen"><span
                class="text-color">与劳动者关系 </span></a-checkbox>
          </a-col>
        </a-card>
      </a-form-model-item>

      <a-form-model-item :wrapper-col="{ span: 24 }">
        <a-card :bordered="false">
          <template #title>
            <div class="ml-2 title">拟退场时间</div>
          </template>
          <a-col class="mt-2 ml-1">
            <a-switch v-model="form.enterSet.exit" />
            <span class="text-color ml-1">启用“拟退场时间”</span>
            <a-tooltip>
              <template slot="title">
                <p>
                  “拟退场时间”
                  是指预计某工人将完成工作并从项目中退场的日期，启用后将:
                </p>
                <p>1.开启进场校验，工人信息中须填写“拟退场时间”后方可入场</p>
                <p>2.导出工人花名册时增加一列展示此项信息</p>
                <p>
                  3.工人临近"拟退场时间", 平台将会自动预警,
                  以加强项目用工管理的水平
                </p>
                <p>4.电子签合同增值服务中，可在电子合同中定制化增加此项信息</p>
              </template>
              <a-icon style="margin-left: 5px" type="question-circle" theme="twoTone" />
            </a-tooltip>
          </a-col>
        </a-card>
      </a-form-model-item>
      <a-button type="primary" icon="save" class="ml-3" @click="saveRules">保存</a-button>
    </a-form-model>
  </a-spin>
  </a-card>
</template>

<script>
import { getSetParamsByCond, setParamsByCond } from "@/services/participationUnit";
import { mapGetters } from "vuex";
export default {
  name: "SuijianClientEntrySettings",
  props: {
    projectId: {
      type: [String, Number],
      require: true,
    },
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  data() {
    return {
      form: {
        enterSet: {
          emergency: {
            isOpen: false,
            emergencyUserName: false,
            emergencyUserPhone: false,
            emergencyAddress: false,
            emergencyRelation: false,
          },
          exit: false,
        },
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      detail: {},
      loading: false
    };
  },
  mounted() {
    this.getConfig();
  },

  methods: {
    setEnterSet(data) {
      const emergency = {
        fields: []
      }
      Object.keys(data).forEach(key => {
        if (key === 'isOpen') {
          emergency.isOpen = this.form.enterSet.emergency[key]
        } else if
          (this.form.enterSet.emergency[key]) {
          emergency.fields.push(key)
        }
      })
      if (emergency.fields.length === 0) {
        delete emergency.fields
      }
      return emergency
    },
    saveRules() {
      // 处理保存数据
      const emergency = this.setEnterSet(this.form.enterSet.emergency)
      const enterSet = { emergency, exit: this.form.enterSet.exit }
      const params = {
        // 企业管理员
        officeId: this.user.nowRoleKey.includes("OFFICE_MGR") ? this.user.officeId : undefined, 
        id: this.detail.id,
        enterSet: JSON.stringify(enterSet),
      };
      setParamsByCond(params).then(res => {
        if (res.data.code === 0) {
          this.getConfig()
        }
        this.$message.success(res.data.msg, 5);
      })
    },
    getConfig() {
      this.loading = true
      const params = {
        officeId: this.user.nowRoleKey.includes("OFFICE_MGR") ? this.user.officeId : undefined, 
        // projectId: this.user.nowRoleKey.includes("OFFICE_MGR") ? this.user.projectId : undefined, 
      }
      getSetParamsByCond(params).then((res) => {
        this.loading = false
        if (res.data.code === 0) {
          if (res.data.data.id) {
            this.detail = res.data.data;
            // 组装数据
            const enterSet = JSON.parse(this.detail.enterSet)
            if (enterSet.emergency.fields && enterSet.emergency.fields.length > 0) {
              Object.keys(this.form.enterSet.emergency).forEach(key => {
                enterSet.emergency.fields.forEach(field => {
                  if (key === field) {
                    this.form.enterSet.emergency[key] = true
                  }
                })
              })
            }
            if (enterSet.emergency.isOpen) this.form.enterSet.emergency.isOpen = enterSet.emergency.isOpen
            if (enterSet.exit) this.form.enterSet.exit = enterSet.exit
          } else {
            this.detail = {}
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.risk-rules {
  color: rgb(118, 131, 143);
  font-size: 15px;

  >div {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 5px;
  }
}

.text-color {
  color: #101010;
  font-size: 15px;
}

/deep/ .ant-card-head {
  border-bottom: none;
}

/deep/ .ant-card-body {
  padding: 0 24px 24px 24px;
}

.title {
  position: relative;
}

.title:after {
  position: absolute;
  top: 4px;
  left: -12px;
  width: 5px;
  height: 19px;
  content: "";
  background-color: #307dfa;
  border-radius: 3.5px;
}
</style>