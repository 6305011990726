<template>

    <div >
        <a-card title="同步旧劳务管理账号" :bordered="false" style="width: 300px">

<!--            <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">-->
<!--                <a-form-item label="账号" :wrapper-col="{span: 18, offset: 0}">-->
<!--                    <a-input placeholder="请输入需要同步的账号" v-model="syncLoginName" />-->
<!--                </a-form-item>-->
<!--                <a-button type="primary" @click="syncUserName">-->
<!--                    同步该账号-->
<!--                </a-button>-->

<!--                <a-button type="primary" style="margin-left: 15px" @click="syncAllUser">-->
<!--                    同步整个系统-->
<!--                </a-button>-->

<!--            </a-form>-->


            <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">
                <a-form-item label="项目id" :wrapper-col="{span: 18, offset: 0}">
                    <a-input placeholder="请输入需要迁移的项目id" v-model="transferOfficeData.projectId" />
                </a-form-item>
                <a-form-item label="新公司id" :wrapper-col="{span: 18, offset: 0}">
                    <a-input placeholder="请输入新的公司id" v-model="transferOfficeData.newOfficeId" />
                </a-form-item>
                <a-button type="primary" @click="transferOfficeDataSubmit">
                    将数据同步到该公司
                </a-button>
            </a-form>
        </a-card>
    </div>

</template>
<script>
import {syncSysUser, modifyJiShiByLabour, modifyJiShiByLaoWu} from '@/services/sys'
export default {
    data() {
        return {
            syncLoginName: "",

            transferOfficeData:{
                projectId: "",
                newOfficeId: "",
            }
        };
    },

    created() {

    },
    methods: {
        syncUserName(){
            if (this.syncLoginName == "" || this.syncLoginName==undefined){
                this.$message.error("请输入单个需要同步的账号");
                return
            }

            var _that = this
            this.$confirm({
                title: '提醒',
                content: '确认同步' + _that.syncLoginName + "?",
                okText: '确认',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    syncSysUser({loginName: this.syncLoginName}).then(res => {
                        _that.$message.info(res.data.msg);
                    })
                },
                onCancel() {
                    console.log('Cancel');
                },
            });

        },
        syncAllUser(){
            var _that = this
            this.$confirm({
                title: '提醒',
                content: '确认同步所有旧劳务系统到新系统?',
                okText: '确认',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    syncSysUser({loginName: ""}).then(res => {
                        _that.$message.info(res.data.msg);
                    })
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        },
        transferOfficeDataSubmit(){
            this.modifyJiShiByLaoWu();
            this.modifyJiShiByLabour();
        },
        modifyJiShiByLabour(){
            modifyJiShiByLabour({
                projectId: this.transferOfficeData.projectId,
                newOfficeId: this.transferOfficeData.newOfficeId
            }).then(res=>{
                console.log("modifyJiShiByLabour:", res)
            })
        },
        modifyJiShiByLaoWu(){
            modifyJiShiByLaoWu({
                projectId: this.transferOfficeData.projectId,
                newOfficeId: this.transferOfficeData.newOfficeId
            }).then(res=>{
                console.log("modifyJiShiByLaoWu:", res)
            })
        },

    },
};


</script>