<template>
  <div class="home_container">
    <a-row :gutter="[16, 8]">
      <a-col :span="16">
        <div class="public" style="padding-top: 24px">
          <div class="flex">
            <div class="relative">
              <img
                class="wran-img"
                src="@/assets/img/icon-jw-warning.png"
                alt=""
              />
              <div class="warning-content white">
                <p class="font-bold-700">建委预警</p>
                <p class="font-bold-700 font-30">
                  {{ noCloseWarnList.length }}
                </p>
                <div class="white pointer"
                >
                  查看详情<a-icon type="right" />
                </div>
              </div>
            </div>
            <vue-scroll :ops="ops" style="height:122px" class="ml-2">
              <standard-table
                class="close-table"
                :columns="columns"
                :dataSource="noCloseWarnList"
                rowKey="id"
                :pagination="false"
                :isAlert="false"
                :bordered="false"
              >
                <template slot="index" slot-scope="{ index }">
                  <span>
                    {{ parseInt(index) + 1 }}
                  </span>
                </template>
                <template slot="bizType" slot-scope="{ text }">
                  {{ text == 1 ? "实名制" : "工资分账" }}
                </template>
                <template slot="eventName" slot-scope="{ text }">
                  {{ text }}
                </template>
                <template slot="warmCode" slot-scope="{ text, record }">
                  <div
                    class="statusDrop flex a-center overtext-1"
                    :class="textStyle(record)"
                  >
                    {{ text }}
                  </div>
                </template>
                <template slot="status" slot-scope="{ text, record }">
                  <div
                    class="statusDrop flex a-center overtext-1"
                    :class="textStyle(record)"
                  >
                    {{ text | statusStr }}
                  </div>
                </template>
                <!-- ellipsis-1 -->
                <template slot="overtext" slot-scope="{ text }">
                  <span class="overtext-1">{{ text }}</span>
                </template>
                <!-- <div slot="action" slot-scope="{ record }">
                  <a-button type="link" @click="toHandle(record)"
                    >去处理</a-button
                  >
                </div> -->
              </standard-table>
            </vue-scroll>
          </div>
        </div>
      </a-col>
      <a-col :span="8">
        <div>
          <a-row :gutter="[0, 16]">
            <a-col>
              <div class="public">
                <div class="pl-1">
                  <div class="black font-16 font-bold overtext-1">
                    {{
                      officeData.name
                    }}
                  </div>
                  <div
                    class="flex"
                    style="padding-top: 17px; padding-bottom: 6px"
                  >
                    <img
                      class="home-project"
                      src="@/assets/img/home-project.png"
                      alt=""
                    />
                    <div
                      class="flex-1 pl-2"
                      style="line-height: 20px; font-size: 14px"
                    >
                      <div class="flex overtext-1">
                        <span class="black mr-2">公司编码</span>
                        <span class="lightgray">{{
                          officeData.code
                        }}</span>
                      </div>
                      <div class="flex py-1">
                        <span class="black mr-2">负责人</span>
                        <span class="lightgray">{{
                          officeData.master
                        }}</span>
                      </div>
                      <div class="flex">
                        <div class="flex-1">
                          <span class="black mr-2">联系电话</span>
                          <span class="lightgray">{{
                            officeData.userPhone
                          }}</span>
                        </div>
                        <router-link
                          class="pointer"
                          style="color: #0874f0"
                          :to="{ name: '企业概况' }"
                          >查看详情<a-icon type="right"
                        /></router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a-col>
          </a-row>
        </div>
      </a-col>
    </a-row>
    <a-row :gutter="[16, 16]">
      <a-col>
        <a-card :bordered="false" class="project-card">
          <template #title>
            <span class="font-16  black">项目数据</span>
          </template>
          <div class="relative">
            <baidu-map
              class="baidu-map"
              :scroll-wheel-zoom="false"
              :center="location"
              :zoom="zoom">
              <bm-view class="bmMap"></bm-view>
              <!-- 手动放大缩小到省市街道 位置：右下方-->
             <bm-navigation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" />
              <!-- <bm-local-search :keyword="addressKeyword" :auto-viewport="true" style="display: none"></bm-local-search> -->
              <div v-for="(items,index) in persons" :key="index">
                  <bm-marker :position="{lng: items.lng, lat: items.lat}" :icon="{url: items.show ? local2 : items.icon, size: {width: 28, height: 28}}" :dragging="false" @click="infoWindowOpen(index,items)">
                    <bm-info-window :show="items.show" @close="infoWindowClose(index)">
                      <div class="info-window">
                        <div class="ellipsis-2" >项目名称： {{ items.name }}</div>
                        <div style="line-height: 45px;">工人总数：{{staffObj.persons ? staffObj.persons : 0}}</div>
                        <div>班组总数：{{staffObj.teams ? staffObj.teams : 0}}</div>
                        <div v-if="canOperate" class="onInfo" @click="onInfo(items)" >查看详情</div>
                      </div>
                    </bm-info-window>
                  </bm-marker>
              </div>
            </baidu-map>
            <div class="pro-box">
              <a-row type="flex" justify="space-between">
                <a-col :class="proActive == index ? 'pro-active' : '' " class="tab-box" @click="onProAction(index,item)" v-for="(item,index) in proList" :key="item.key + index">
                  <div style="line-height: 45px;">{{ item.title }}</div>
                  <div style="line-height: 20px;" class="font-24">{{ item.value }}</div>
                </a-col>
              </a-row>
            </div>
          </div>
        </a-card>
      </a-col>
    </a-row>
    <!-- <a-row :gutter="[16, 16]" class="pt-1 pb-1">
      <a-col>
        <div class="flex">
          <div class="font-20 mx-2 black">劳务数据</div>
              <a-select default-value="lucy" style="width: 234px" @change="handleChange">
            <a-select-option value="jack">
              Jack
            </a-select-option>
            <a-select-option value="lucy">
              Lucy
            </a-select-option>
          </a-select>
        </div>
      </a-col>
    </a-row> -->
    <a-row :gutter="[16, 16]">
      <a-col :span="16">
        <a-row :gutter="[16, 0]">
          <a-col :span="8">
            <a-card :bordered="false">
              <template #title>
                <div class="flex font-16">
                  <span class="flex-1 black">管理人员数据</span>
                  <!-- <div
                    class="pointer"
                    style="color: #0874f0"
                    >更多</div
                  > -->
                </div>
              </template>
              <a-row type="flex" justify="space-between">
                <a-col class="ml-35">
                  <h4 class="black-three">登记人员</h4>
                  <p class="font-24 black">{{ mangerData.totalTeams || 5 }}</p>
                  <div class="py-1">
                    <h4 class="black-three">今日出勤人数</h4>
                  <div class="flex">
                    <span class="font-24 black">{{
                      mangerData.todayTeams
                    }}</span>
                    <!-- <div class="card-match ml-05">
                      <span class="green">+1.502%</span>
                      <img src="@/assets/img/arrow-up.png" alt="" />
                    </div> -->
                  </div>
                  </div>
                </a-col>
                <a-col class="mr-3">
                  <div class="flex flex-column a-center">
                  <img src="@/assets/img/icon-line-chart.png" alt="" />
                  <div class="pt-2">
                    <h4 class="black-three">出勤率</h4>
                    <p class="font-24 black">
                      {{ mangerData.teamCql ? mangerData.teamCql : 100 }}%
                    </p>
                  </div>
                </div>
                </a-col>
              </a-row>
            </a-card>
          </a-col>
          <a-col :span="8">
            <a-card :bordered="false">
              <template #title>
                <div class="flex font-16">
                  <span class="flex-1 black">工人数据</span>
                  <!-- <div
                    class="pointer"
                    style="color: #0874f0"
                    >更多</div
                  > -->
                </div>
              </template>
              <a-row type="flex" justify="space-between">
                <a-col class="ml-35">
                  <h4 class="black-three">累计登记人数</h4>
                  <p class="font-24 black">{{ staffData.onlineTeams || 5972}}</p>
                  <div class="py-1">
                    <h4 class="black-three">今日出勤人数</h4>
                  <div class="flex">
                    <span class="font-24 black">{{
                      staffData.todayTeams || 423
                    }}</span>
                    <!-- <div class="card-match ml-05">
                      <span class="green"
                        >{{ staffData.compareYesterdayRate }}%</span
                      >
                      <img src="@/assets/img/arrow-up.png" alt="" />
                    </div> -->
                  </div>
                  </div>
                </a-col>
                <a-col class="mr-3">
                  <h4 class="black-three">在场人数</h4>
                  <p class="font-24 black">{{ staffData.totalTeams || 879}}</p>
                  <div class="py-1">
                    <h4 class="black-three">出勤率</h4>
                    <p class="font-24 black">{{ staffData.teamCql || 48.00}}%</p>
                  </div>
                </a-col>
              </a-row>
            </a-card>
          </a-col>
          <a-col :span="8">
            <a-card :bordered="false">
              <template #title>
                <div class="flex font-16">
                  <span class="flex-1 black">班组数据</span>
                  <!-- <div
                    class="pointer"
                    style="color: #0874f0"
                    >更多</div
                  > -->
                </div>
              </template>
              <a-row type="flex" justify="space-between">
                <a-col class="ml-35">
                  <h4 class="black-three">累计登记班组</h4>
                  <p class="font-24 black">{{ teamDataKq.totalTeams || 109}}</p>
                  <div class="py-1">
                  <h4 class="black-three">今日出勤班组</h4>
                  <div class="flex">
                    <span class="font-24 black">{{
                      teamDataKq.todayTeams || 29
                    }}</span>
                    <!-- <div class="card-match ml-05">
                      <span class="red">-21.01%</span>
                      <img src="@/assets/img/arrow-down.png" alt="" />
                    </div> -->
                  </div>
                </div>
                </a-col>
                <a-col class="mr-3">
                  <h4 class="black-three">在场班组</h4>
                  <p class="font-24 black">{{ teamDataKq.onlineTeams || 37}}</p>
                  <div class="py-1">
                    <h4 class="black-three">出勤率</h4>
                    <p class="font-24 black">{{ teamDataKq.teamCql || '78%'}}</p>
                  </div>
                </a-col>
              </a-row>
            </a-card>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="8">
        <a-card :bordered="false">
          <template #title>
            <div class="flex font-16">
              <span class="flex-1 black">考勤设备</span>
              <router-link
                v-auth:role="`device`"
                :to="{ name: '考勤设备设置' }"
                class="pointer"
                >更多</router-link
              >
            </div>
          </template>
          <div class="w-100">
            <div class="flex j-around">
              <div class="ml-1 mr-5">
                <div class="flex flex-column">
                  <h4 class="black-three">考勤设备数</h4>
                  <p class="black font-24">{{ deviceData.deviceNum || 0}}</p>
                </div>
                <div class="flex flex-column pt-1">
                  <h4 class="black-three">离线设备</h4>
                  <p class="black font-24">{{ deviceData.offlineDeviceNum || 0}}</p>
                </div>
              </div>
              <vue-scroll
                :ops="ops"
                style="height: 152px"
                class="flex-1"
              >
                <standard-table
                  class="close-table"
                  :columns="deviceColumns"
                  :dataSource="deviceData.attendanceDeviceVoList"
                  rowKey="id"
                  :pagination="false"
                  :isAlert="false"
                  :bordered="false"
                >
                  <template slot="index" slot-scope="{ index }">
                    <span>
                      {{ parseInt(index) + 1 }}
                    </span>
                  </template>
                  <template slot="online" slot-scope="{ text }">
                    <span>
                      {{ text ? text : "在线" }}
                    </span>
                  </template>
                </standard-table>
              </vue-scroll>
            </div>
          </div>
        </a-card>
      </a-col>
    </a-row>
    <a-row :gutter="[24, 16]">
      <a-col :span="11" style="padding-right: 30px;">
        <a-card :bordered="false">
          <template #title>
            <span class="flex-1 black font-16">班组考勤情况</span>
          </template>
          <vue-scroll :ops="ops" style="height: 264px">

          <standard-table
                  class="close-table2 scroll-tabox"
                  :columns="teamColumns"
                  :dataSource="teamRankList"
                  rowKey="id"
                  :pagination="false"
                  :isAlert="false"
                  :bordered="false"
                >
                  <template slot="cql" slot-scope="{ text }">
                    <span>
                      {{ text }}%
                    </span>
                  </template>
          </standard-table>
        </vue-scroll>

        </a-card>
      </a-col>
      <a-col :span="13" style="margin-left: -13px;padding-right: 0;padding-left: 0;">
        <a-card :bordered="false">
          <template #title>
            <div class="flex font-16">
              <span class="flex-1 black">工资发放数据</span>
              <router-link
                v-auth:role="`salary`"
                :to="{ name: '汇总工资申请单' }"
                class="pointer"
                >明细查询</router-link
              >
            </div>
          </template>
          <a-row class="mt-2 mb-2" style="padding-bottom: 8px;" type="flex" justify="space-around">
            <a-col
              class="payroll-card"
              :class="item.class ? item.class : ''"
              v-for="(item, index) in payrollData"
              :key="index"
            >
              <div class="flex j-center a-center h-100">
                <div class="flex a-center">
                  <img  style="width: 42px; height: 42px" :src="item.img" alt="" />
                  <a-divider class="mx-3" type="vertical" style="height: 61px;background:#DAE6FF;"/>
                </div>
                <div class="mt-2">
                  <h4 class="black-three">{{ item.title }}</h4>
                  <h4 class="black-three font-24">{{ item.value }}</h4>
                </div>
              </div>
            </a-col>
          </a-row>
        </a-card>
      </a-col>

    </a-row>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  sevenDaysChart,
} from "@/pages/homePage/newHome/echarts";
import {
  getProWarnList,
  getProTeamKq,
  getProMangerData,
  getProStaffData,
  getProPayrollData,
  getProDeviceData,
  getLwDetail,
  getProSevenDays,
} from "@/services/projectManagement";
import StandardTable from "@/components/table/StandardTable";
import { getOfficeDetail } from "@/services/projectManagement";
import {
  getHomeOfficeProject,
  getHomeOfficeProjeStatus,
  getHomeOfficeProjeTotals,
  getHomeOfficeManage,
  getHomeOfficeStaff,
  getHomeOfficeTeamKq,
  getHomeOfficeTeamKqData
} from "@/services/statistics";
const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    align: "center",
  },
  {
    title: "预警单号",
    dataIndex: "jwWarnOddNum",
    align: "center",
    scopedSlots: { customRender: "overtext" },
  },
  {
    title: "预警(编号)名称",
    dataIndex: "name",
    align: "center",
    scopedSlots: { customRender: "overtext" },
  },
  {
    title: "生成时间",
    dataIndex: "warmDate",
    align: "center",
    scopedSlots: { customRender: "overtext" },
  },
  {
    title: "处理状态",
    dataIndex: "status",
    align: "center",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "处理时限",
    dataIndex: "timeoutTime",
    align: "center",
    scopedSlots: { customRender: "warmCode" },
  },
];
const teamColumns = [
  {
    title: "班组名称",
    dataIndex: "teamName",
    scopedSlots: { customRender: "teamName" },
    align: "left",
  },
  {
    title: "班组总人数",
    dataIndex: "numbers",
    align: "center",
  },
  {
    title: "班组今日考勤人数",
    dataIndex: "cqrs",
    align: "center",
  },
  {
    title: "考勤率",
    dataIndex: "cql",
    align: "center",
    scopedSlots: { customRender: "cql" },
  },
]
const deviceColumns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    align: "center",
  },
  {
    title: "品牌",
    dataIndex: "manufacturerKey",
    align: "center",
  },
  {
    title: "设备名称",
    dataIndex: "deviceName",
    align: "center",
  },
  {
    title: "状态",
    dataIndex: "online",
    align: "center",
    scopedSlots: { customRender: "online" },
  },
];
const payrollData = [
  {
    title: "累计发放工人工资金额总数(元)",
    key: "accumulatedTotal",
    value: "0",
    img: require("@/assets/img/payroll-card1.png"),
  },
  {
    title: "累计班组发放总数(人)",
    key: "teamNum",
    value: "0",
    img: require("@/assets/img/payroll-card3.png"),
  },
  {
    title: "本月发放工人工资金额总数(元)",
    key: "currMonthAccumulatedTotal",
    value: "0",
    img: require("@/assets/img/payroll-card2.png"),
    class: 'mt-3'
  },
  {
    title: "本月发放工人总数(人)",
    key: "staffNum",
    value: "0",
    img: require("@/assets/img/payroll-card-4.png"),
    class: 'mt-3'
  },
];
const proList = [
  {
    title: '项目总数',
    key: "status",
    value: 0,
    status: 99

  },
  {
    title: '在建项目数',
    key: "onlineNums",
    value: 6,
    status: 0
  },
  {
    title: '未开工项目数',
    key: "createNums",
    value: 75,
    status: 3
  },
  {
    title: '停工项目数',
    key: "stopNums",
    value: 3,
    status: 2
  },
  {
    title: '完工项目数',
    key: "offNums",
    value: 95,
    status: 1
  },
]

export default {
  name: "officeNewhomePage",
  components: {
    StandardTable,
  },
  data() {
    return {
      activedKey: 2,
      peopleChart: null,
      environmentChart: null,
      safeChart: null,
      environmentChartCopy: null,
      sevenDaysChart: null,
      // currentGk: {}, // 当月安全管理统计汇总
      // workData: {}, // 劳务数据汇总
      // projectList: [],
      // defaultProject: "全部",
      // gcObj: {}, // 工程类型守护
      // ManagCWA: {}, // 管理人员数据
      // sevenKq: [],
      noCloseWarnList: [], // 建委预警列表
      columns,
      deviceColumns,
      pagination: {
        //分页数据
        current: 1,
        pageSize: 10,
        total: 0,
      },
      teamRankList: [], // 班组排名情况
      teamDataKq: {}, // 班组数据
      deviceData: {}, // 设备数组
      staffData: {}, // 工人数据
      payrollData, // 工资数据
      mangerData: {}, // 管理人员数据
      projectData: {}, // 项目数据
      sevenKq: {}, // 项目七日考勤情况
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true,
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: "#3ec13e", //滚动条颜色
          opacity: 0.8, //滚动条透明度
          "overflow-x": "hidden",
        },
      },
      deviceNum: 0,
      officeData: {}, // 公司概况详情
      location: {
        lng: 113.274,
        lat: 23.123
      },
      zoom: 9.5,
      projectList: [], //公司项目总数
      gcObj: {}, // 工程类型
      persons:[], // 地图上的覆盖物
      local1: require("../icon/path.png"),
      local2: require("../icon/activepath.png"),
      local3: require("../icon/local-3.png"),
      proList,
      proActive: 0,
      staffObj: {},
      arrRoles:['OFFICE_MGR', 'admin', 'OFFICE_JSFWZY'], // 查看详情权限
      teamColumns
    };
  },
  filters: {
    statusStr(val) {
      switch (val) {
        case 0:
          return "待处理";
        case 1:
          return "处理中";
        case 2:
          return "已处理";
        case 3:
          return "已超时";
      }
    },
  },
  computed: {
    ...mapGetters("account", ["user"]),
    canOperate() {
      return this.user.nowRoleKey.some(role => this.arrRoles.includes(role));
    },
    getProgressColor() {
      return function (successPercent) {
        let color = "";
        if (successPercent < 25) {
          color = "#615e83";
        } else if (successPercent <= 50) {
          color = "#e5eafc";
        } else if (successPercent <= 75) {
          color = "#a9e9fe";
        } else if (successPercent <= 90) {
          color = "#3aa5ff";
        } else {
          color = "#4a3aff";
        }
        return color;
      };
    },
    textStyle() {
      return function (val) {
        if (val.status == 0) {
          return "";
        } else if (val.status == 1) {
          return "before-second";
        } else if (val.status == 2) {
          return "before-third";
        } else if (val.status == 3) {
          return "before-fourth";
        }
      };
    },
  },
  mounted() {
    this.init(); // 初始化数据
    this.getProWarnList(); // 建委预警列表
    this.getHomeOfficeProjeStatus(99) // 初始化项目总数

  },
  beforeDestroy() {
    /* 页面组件销毁的时候，别忘了移除绑定的监听resize事件，否则的话，多渲染几次
    容易导致内存泄漏和额外CPU或GPU占用哦*/
    // window.removeEventListener("resize", () => {
    //   this.sevenDaysChart.resize();
    // });
  },
  methods: {
    getHomeOfficeProjeStatus(status) {
      // 查詢各項目狀態的项目清单
      getHomeOfficeProjeStatus({status: status}).then(res => {
        if (res.data.code === 0) {
          let obj = res.data.data
          let arr = []
          obj.forEach(item => {
             // 组成百度地图适配的数据格式
             arr.push({
              name: item.projectName,
              icon: this.local1,
              id: item.projectId,
              lat: item.latitude,
              lng: item.longitude,
              show: false
             })
          })
          this.persons = arr
        } else {
          this.error = res.data.msg
          this.$message.error(res.data.msg, 3)
        }
      })
    },
    onProAction(e,item) {
      // 高亮效果
      this.proActive = e
      // 查詢各項目狀態的项目清单
      this.getHomeOfficeProjeStatus(item.status)
    },
    onInfo(item) {
        getLwDetail({projectId: item.id}).then(res => {
          this.$router.push({
          name: "项目详情",
          query: {
            id: item.id,
            officeId: res.data.officeId ? res.data.officeId : undefined
          }
          });
        })
    },
    infoWindowClose (index) {
      this.persons[index].show = false
    },
    infoWindowOpen (index,item) {
      getHomeOfficeProjeTotals({projectId: item.id}).then(res => {
        if (res.data.code === 0) {
          this.persons[index].show = true
          this.staffObj = {
            persons: res.data.data.persons,
            teams: res.data.data.teams,
          }
        }
      })
    },
    handleChangeTitle(e) {
      this.activedKey = e;
    },
    // 建委预警列表
    getProWarnList() {
      const params = {
        officeId: this.user.officeId,
        pageNumber: 1,
        pageSize: 50,
      };
      getProWarnList(params)
        .then((res) => {
          if (res.data.code === 0) {
            // this.noCloseWarnList = res.data.data
            this.noCloseWarnList = res.data.data.filter(
              (item) => item.status != 2
            );
          }
        })
        .catch((e) => {
          this.$message.error("获取预警列表失败", 10);
        });
    },
    init() {
      getOfficeDetail({
        id: this.user.officeId
      }).then(res=> {
        if (res.data.code === 0) {
          this.officeData = res.data.data
        }
      })
      const params = {
        officeId: this.user.officeId,
      };
      // 获取项目总数
      getHomeOfficeProject().then(res => {
        if (res.data.code === 0) {
          // 项目总数每次刷新先清空
          this.proList[0].value = 0
          const data = res.data.data
          this.proList.forEach(el => {
            Object.keys(data).forEach(item => {
              if (el.key == 'status') {
                // 处理项目总数
                el.value += data[item]
              } else if (el.key == item) {
                el.value = data[item]
              }
            })
          })
        }
      })

      // 首页获取管理人员数据
      getHomeOfficeManage().then((res) => {
        if (res.data.code === 0) {
          this.mangerData = res.data.data;
        }
      });
      // 工人公司出勤数据统计
      getHomeOfficeStaff().then((res) => {
        if (res.data.code === 0) {
          this.staffData = res.data.data;
        }
      });
      // 查询班组考勤情况
      getHomeOfficeTeamKq({}).then(res => {
          if (res.data.code === 0) {
            this.teamRankList = res.data.data
           }
      })
      // 班组考勤数组
      getHomeOfficeTeamKqData({}).then(res => {
        if (res.data.code === 0) {
            this.teamDataKq = res.data.data;
          }
      })
      // 获取工资数据
      getProPayrollData().then((res) => {
        if (res.data.code === 0) {
          let data = res.data.data;
          this.payrollData.forEach((item) => {
            Object.keys(data).forEach((key) => {
              if (item.key == key) {
                item.value = data[key];
              }
            });
          });
        }
      });
      // 获取设备数据
      getProDeviceData().then((res) => {
        if (res.data.code === 0) {
          this.deviceData = res.data.data;
          if (this.deviceData.attendanceDeviceVoList) {
            this.deviceData.attendanceDeviceVoList.forEach((el) => {
              el.online = Number(el.online)
                ? "在线"
                : el.manufacturerKey === "HK"
                ? "/"
                : "不在线";
              if (el.manufacturerKey === "yunqi") {
                el.manufacturerKey = "云启";
              } else if (el.manufacturerKey === "HK") {
                el.manufacturerKey = "海康";
              }
            });
          }

        }
      });
    },
    toDetail() {
      this.$router.push({ name: "项目详情" });
    },
    handleChange(value) {
      console.log(`selected ${value}`);
    },
  },
};
</script>
<style lang="less" scoped>
p {
  margin: 0;
}
.public {
  padding: 17px;
  background: #fff;
  border-radius: 10px;
}
.warning-content {
  position: absolute;
  top: 10px;
  left: 18px;
}
.green {
  color: rgb(4, 206, 0);
}
.red {
  color: #ff718b;
}
.lightgray {
  color: #4c4c4c;
}
.tb-box-2 {
  height: 155px;
  overflow: hidden;
  overflow-y: auto;
}
.home_container {
  .bmMap {
    width: 100%;
    height:500px;
    flex: 1;
    border-radius: 10px;
  }
  .pro-box {
    position: absolute;
    top: 25px;
    left: 40px;
    right:38px;
    .tab-box {
      width: 18%;
      height: 80px;
      background: rgba(34, 42, 63, 0.8);
      border-radius:6px;
      color: #fff;
      text-align: center;
    }
    .pro-active {
      background: #2D5AFF;
    }
  }
  /deep/ .ant-card {
    border-radius: 10px;
  }
  /deep/ .ant-card-head-title {
    padding: 0;
  }
  /deep/ .ant-card-head {
    // min-height: 45px;
    line-height: 48px;
    border-bottom: 1px solid rgb(218, 230, 255);
  }
  /deep/ .ant-card-body {
    padding: 17px;
  }
  .project-card /deep/ .ant-card-body {
    padding: 24px;
  }
  .card-match {
    line-height: 27px;
    margin-top: auto;
  }
  .home-project {
    width: 126px;
    height: 82px;
  }

  .payroll-card {
    width: 350px;
    height: 93px;
    background: #f4f6ff;
    border-radius: 10px;
  }
  .wran-img {
    width: 210px;
    height: 120px;
  }
}
.scroll-tabox{
	position: relative;
	/deep/ .ant-table-thead{
		position:sticky;
		top : 0px;
		z-index:9999;
    background: #fff;
	}
}
/deep/ .close-table2 {
  .ant-table-thead > tr > th {
    background: #f4f6ff;
    padding: 4px 16px;
    font-weight: 400;
    font-size: 14px;
    font-family: 微软雅黑;
    color: rgb(0, 0, 0);
  }
  .ant-table-tbody > tr > td {
    color: #4d4d4d;
    // color: #666;
    font-family: 微软雅黑;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0px;
    padding: 8px 8px;
    border-bottom: unset;
  }
  .ant-table-placeholder {
    // padding: 8px 0;
  }
  .ant-empty-normal {
    // margin: 0;
  }
}
/deep/ .close-table {
  .ant-table-thead > tr > th {
    background: #f4f6ff;
    padding: 4px 16px;
    font-weight: 400;
    font-size: 14px;
    font-family: 微软雅黑;
    color: rgb(0, 0, 0);
  }
  .ant-table-tbody > tr > td {
    color: #4d4d4d;
    // color: #666;
    font-family: 微软雅黑;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0px;
    padding: 8px 8px;
    border-bottom: unset;
  }
  .ant-table-placeholder {
    padding: 8px 0;
  }
  .ant-empty-normal {
    margin: 0;
  }
  // 预警状态
  .statusDrop::before {
    width: 8px;
    height: 8px;
    background: #ee6945;
    content: "";
    display: inline-block;
    border-radius: 50%;
    margin-left: 15px;
    margin-right: 3px;
  }
  .before-second::before {
    background: #e5a858;
  }
  .before-third::before {
    background: #6ccc51;
  }
  .before-fourth::before {
    background: #656262;
  }
}
// 百度地图自定义样式
/deep/ .baidu-map {

.BMap_bottom {
    display: none;
}
.BMap_shadow {
    display: none;
}
.BMap_pop {
    > div,
    > img:nth-child(10) {
        display: none;
        overflow: unset;
    }
    > div:nth-child(9) {
        display: block;
        overflow: unset;
        width: 340px !important;
    }
    > div:nth-child(8){
        /*display: block;*/
    }
    .BMap_top {
        display: none;
    }
    .BMap_center {
        background: transparent;
        border: none;
        position: sticky !important;
    }
}
.BMap_bubble_content{
    background: rgba(33, 42, 63, 0.8);
    border-radius: 6px;
    padding: 14px 17px 0 19px;
    max-height: 152px !important;
    height: 152px !important;
    .info-window{
        font-size: 14px;
        font-family: Inter;
        width: 100%;
        // height: 100%;
        color: #E5E5EF;
        height: 138px !important;
        position: relative;
        .onInfo {
            position: absolute;
            right: 5px;
            bottom: 14px;
            // margin: -10px 5px 0 auto;
            text-align: center;
            background: rgb(10, 99, 241);
            border-radius:15px;
            width: 74px;
            height: 26px;
            line-height: 26px;
            font-size: 12px;
        }
    }
}

}
</style>
