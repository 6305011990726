import { render, staticRenderFns } from "./BasicForm.vue?vue&type=template&id=325514ec&scoped=true&"
import script from "./BasicForm.vue?vue&type=script&lang=js&"
export * from "./BasicForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "325514ec",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\admin\\Desktop\\SJ\\【劳务机施】suijian_client\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('325514ec')) {
      api.createRecord('325514ec', component.options)
    } else {
      api.reload('325514ec', component.options)
    }
    module.hot.accept("./BasicForm.vue?vue&type=template&id=325514ec&scoped=true&", function () {
      api.rerender('325514ec', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/form/basic/BasicForm.vue"
export default component.exports