<template>
  <div class="tinymce-editor">
    <editor id="tinymce" :key="tinymceFlag" v-model="tinymceHtml" :init="init"></editor>
  </div>
</template>

<script>
import tinymce from 'tinymce/tinymce'
import Editor from '@tinymce/tinymce-vue'
import { request } from "@/utils/request";
import { ATTACHMENT_UPLOAD } from "@/services/api";
import 'tinymce/themes/silver/theme'
import 'tinymce/icons/default/icons'
import 'tinymce/plugins/image'
import 'tinymce/plugins/link'
import 'tinymce/plugins/code'
import 'tinymce/plugins/table'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/contextmenu'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/colorpicker'
import 'tinymce/plugins/textcolor'

export default {
  name: "Tinymce",
  components: {Editor},
  data() {
    return {
      tinymceHtml:this.value,
       tinymceFlag: 1,
      init: {
        selector:'tinymce',
        language_url: '/static/tinymce/zh_CN.js',
        language: 'zh_CN',
        skin_url: '/static/tinymce/skins/ui/oxide',
        height: 300,
        plugins: this.plugins,
        toolbar: this.toolbar,
        branding: false,
        images_upload_handler:(blobInfo, success, failure, progress)=>{
          // tinymce api文档查阅可知   直接获取blob原始数据
          var blob = blobInfo.blob();
          if(blob.size/1024/1024>5){
            failure("上传失败，图片大小请控制在5M以内")
          }
          // 原生上传
          var fd = new FormData();
          fd.append("file", blob);
          request(this.uploadUrl,"post",fd).then(result => {
              if (result.data.code==0) {
                success(result.data.data.path);
              }
          })
        }
      }
    };
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    baseUrl: {
      type: String,
      default: ''
    },
    disable:{
      type: Boolean,
      default:false
    },
    plugins:{
      type:[String,Array],
      default:'link lists image code table colorpicker textcolor contextmenu'
    },
    toolbar:{
      type:[String,Array],
      default:'alignleft aligncenter alignright alignjustify | bold italic underline | fontsizeselect | forecolor backcolor | bullist numlist | outdent indent blockquote | undo redo | link unlink image code | removeformat'
    },
    uploadUrl: {
      type: String,
      default:ATTACHMENT_UPLOAD
    }
  },
  created(){
  },
  mounted(){
    tinymce.init({})
  },
  activated() {
    this.tinymceFlag++
  },
  watch:{
    value(newValue){
      this.tinymceHtml = newValue;
    },
    tinymceHtml(newValue){
      this.$emit('input',newValue);
    }
  },
  beforeDestroy () {
    // 销毁tinymce
    // tinymce.remove()
    // console.log('是否被销毁')
  },
  methods: {
    onClick(e){
      this.$emit('tinymceClick',e,tinymce)
    },
    clear(){
      this.tinymceHtml = '';
    },
    destory(){
      console.log('是否被销毁')
      tinymce.remove()
    },
    initTinymce(){
      tinymce.init({ })
    }
  },
};
</script>
<style scoped> 
@import '/static/tinymce/skins/ui/oxide/content.min.css';
@import '/static/tinymce/skins/ui/oxide/content.css';
</style>