<template>
  <div class="container">
    <a-card>
      <div>
        <a-row style="margin-left:5%">
          <a-col :md="8" :sm="24" >
            <a-statistic title="总房间数量" :value="roomStaticss.totalRooms" suffix="(间)"/>
          </a-col>
          <a-col :md="8" :sm="24" >
            <a-statistic title="普通房" :value="roomStaticss.defaultTypeRooms" :value-style="{ color: 'blue' }" suffix="(间)"/>
          </a-col>
          <a-col :md="8" :sm="24" >
            <a-statistic title="夫妻房" :value="roomStaticss.doubleRooms" :value-style="{ color: 'blue' }" suffix="(间)"/>
          </a-col>
          <a-col :md="8" :sm="24" >
            <a-statistic title="可住宿人数" :value="roomStaticss.totalPersons" suffix="(人)"/>
          </a-col>
          <a-col :md="8" :sm="24" >
            <a-statistic title="已入住人数" :value="roomStaticss.overInroomNums" :value-style="{ color: '#cf1322' }" suffix="(人)"/>
          </a-col>
          <a-col :md="8" :sm="24" >
            <a-statistic title="空余床位" :value="roomStaticss.noInroomNums"  :value-style="{ color: '#3f8600' }" suffix="(个)"/>
          </a-col>
        </a-row>
      </div>
      <a-divider />
      <div style="background-color:#FFF;padding-top:10px;padding:10px 10px">
        <div :class="advanced ? 'search' : null">
          <a-form layout="horizontal">
            <div :class="advanced ? null: 'fold'">
              <a-row >
                <a-col :md="8" :sm="24" >
                  <a-form-item
                    label="宿舍名称"
                    :labelCol="{span: 5}"
                    :wrapperCol="{span: 16, offset: 1}"
                  >
                    <a-input placeholder="请输入" v-model="queryParams.name"/>
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24" >
                  <a-space :style="{ marginTop: '4px' }">
                    <a-button type="primary" @click="doQuery()">查询</a-button>
                    <a-button @click="doReset()">重置</a-button>
                    <a-button type="primary" @click="addBuild">新增宿舍</a-button>
                  </a-space>
                </a-col>
              </a-row>
            </div>
            <!-- <span style="float: right; margin-top:-50px;margin-right: 10px;">
              <a-button type="primary" @click="addBuild">新增宿舍</a-button>
            </span> -->
          </a-form>
        </div>
        <standard-table
          :columns="columns"
          :dataSource="dataSource"
          :selectedRows.sync="selectedRows"
          :pagination="pagination"
          :loading="tableLoading"
          rowKey="id"
          :isAlert="false"
          @clear="onClear"
          @change="onPageChange"
        >
          <div  slot="name" slot-scope="{text,record}">
            <a @click="toRoomList(record)">{{text}}</a>
          </div>
          <div slot="action" slot-scope="{text, record}">
            <a-icon style="color:#FC5531" type="setting" title="房间管理" @click="toRoomList(record)"></a-icon>
            <a-divider type="vertical" />
            <a-icon style="color:blue" type="edit" @click="editBuild(record)" title="编辑宿舍"></a-icon>
            <a-divider type="vertical" />
            <a-icon style="color:red" type="delete" @click="buildDelete(record)" title="删除宿舍"></a-icon>
          </div>
        </standard-table>
      </div>
      <modify-build :visible="build.visible"
          :build="build.build"
          @buildPageClose="buildPageClose"
          @buildSubmit="buildSubmit"
        >
      </modify-build>
      <modify-room :visible="room.visible"
          :build="room.room"
          @roomEditorClose="roomClose"
          @roomSubmit="roomSubmit"
        >
      </modify-room>
    </a-card>
  </div>
</template>

<script>
import {
  SAFEWORK_BUILD_QUERYPAGE, 
  SAFEWORK_BUILD_INSERT,
  SAFEWORK_BUILD_UPDATE,
  SAFEWORK_BUILD_DELETE,
  BUILD_ROOM_UPDATE,
  BUILD_ROOM_INSERT,
  BUILD_ROOM_STATICSS,
  } from '@/services/api'
import ModifyBuild from '@/pages/staffbuildroom/addbuild'
import ModifyRoom from '@/pages/staffbuildroom/addroom'
import StandardTable from '@/components/table/StandardTable'
import {request} from '@/utils/request'
const columns = [
  {
    title: '宿舍楼名称',
    dataIndex: 'name',
    align: 'center',
    scopedSlots: { customRender: 'name' }
  },
  {
    title: '宿舍楼地址',
    dataIndex: 'address',
    align: 'center',
    scopedSlots: { customRender: 'address' },
  },
  {
    title: '房间数',
    dataIndex: 'totalRooms',
    align: 'center',
   scopedSlots: { customRender: 'totalRooms' },
   customRender: (text) => {
     if(text){
       return text+"间";
     }else{
       return "0间";
     }
    }
  },
  {
    title: '普通房间',
    dataIndex: 'defaultTypeRooms',
    align: 'center',
   scopedSlots: { customRender: 'defaultTypeRooms' },
   customRender: (text) => {
     if(text){
       return text+"间";
     }else{
       return "0间";
     }
    }
  },
  {
    title: '夫妻房',
    dataIndex: 'doubleRooms',
    align: 'center',
   scopedSlots: { customRender: 'doubleRooms' },
   customRender: (text) => {
     if(text){
       return text+"间";
     }else{
       return "0间";
     }
    }
  },
  {
    title: '可容纳人数',
    dataIndex: 'totalPersonNums',
    align: 'center',
   scopedSlots: { customRender: 'totalPersonNums' },
   customRender: (text) => {
     if(text){
       return text+"人";
     }else{
       return "0人";
     }
    }
  },
  {
    title: '已入住人数',
    dataIndex: 'overPersonNums',
    align: 'center',
    scopedSlots: { customRender: 'overPersonNums' },
    customRender: (text) => {
     if(text){
       return text+"人";
     }else{
       return "0人";
     }
    }
  },
  {
    title: '空余床位',
    dataIndex: 'noPersonNums',
    align: 'center',
   scopedSlots: { customRender: 'noPersonNums' },
   customRender: (text) => {
     if(text){
       return text+"个";
     }else{
       return "0个";
     }
    }
  },
  // {
  //   title: '宿舍编号',
  //   dataIndex: 'code',
  //   align: 'center',
  //  scopedSlots: { customRender: 'code' }
  // },
  {
    title: '备注',
    dataIndex: 'remark',
    align: 'center',
    scopedSlots: {customRender: 'remark'}
  },
  // {
  //   title: '创建日期',
  //   dataIndex: 'createdTime',
  //   align: 'center',
  //   scopedSlots: {customRender: 'createdTime'}
  // },
  {
    title: '操作',
    align: 'center',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'QueryList',
  inject: ["fatherMethod"],
  components: {StandardTable,ModifyBuild,ModifyRoom},
  data () {
    return {
      dataSource:[],
      advanced: false,
      columns: columns,
      selectedRows: [],
      tableLoading: false,
      spinning:false,
      loadingText:"数据加载中...",
      loadKeys:[],
      visible:false,
      roomStaticss:{},
      avatar: require('@/assets/img/dddd.jpg'),
      queryParams:{
        userName:null,
        idCard:null,
        teamId:null,
        pageNumber:1,
      },
      pagination:{
        current:1,
        pageSize:10,
        total:0,
        showLessItems:true,
        showQuickJumper:true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      build:{
        visible:false,
        build:{

        }
      },
      room:{
        visible:false,
        room:{

        }
      }
    }
  },
  activated() {
    this.doQuery();
    this.doQueryStaticss();
  },
  authorize: {
    deleteRecord: 'delete'
  },
  methods: {

    /**
     * 获取所有人员的签名记录信息
     */
    onClear(){

    },
    doQueryStaticss(){
      request(BUILD_ROOM_STATICSS,"get",{}).then(res => {
          if(res.data.code==0){
            this.roomStaticss = res.data.data;
          }
        })
    },
    onPageChange(e){
      this.queryParams.pageNumber = e.current;
      this.pagination.current=e.current;
      this.doQuery();
    },
    doQuery(){
      this.tableLoading = true;
        request(SAFEWORK_BUILD_QUERYPAGE,"post",this.queryParams).then(res => {
          if(res.data.code==0){
            this.dataSource = res.data.data.list;
            this.pagination.total = res.data.data.total;
          }
          this.tableLoading = false;
        })
    },
    buildPageClose(){
      this.build.visible=false;
      this.build.build={};
    },
    roomClose(){
      this.room.visible=false;
      this.room.room={};
    },
    buildDelete(recode){
      var _that = this
      this.$confirm({
          title: '警告！删除宿舍楼',
          content: '您好，请您确认该宿舍楼下的人都全部移出，否则数据将不保存，您确认删除宿舍楼吗？',
          okText: '仍旧删除',
          okType: 'danger',
          cancelText: '再想想',
          onOk() {
                request(SAFEWORK_BUILD_DELETE+recode.id, "delete", {}).then((res) => {
                if (res.data.code === 0) {
                  _that.$message.success("删除成功！");
                  _that.doQuery();
                }else{
                  _that.$message.error("删除异常！");
                }
              });
          },
          onCancel() {
              console.log('Cancel');
          },
      });
    },
    buildSubmit($event){
      let methd = $event.build.id?SAFEWORK_BUILD_UPDATE:SAFEWORK_BUILD_INSERT;
      request(methd,"post",$event.build).then(res => {
        if(res.data.code==0){
          this.doQuery();
          this.buildPageClose()
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    roomSubmit($event){
      let methd = $event.room.id?BUILD_ROOM_UPDATE:BUILD_ROOM_INSERT;
      request(methd,"post",$event.room).then(res => {
        if(res.data.code==0){
          this.doQuery();
          this.roomClose();
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    addBuild(){
      this.build.build = {};
      this.build.visible=true;
    },
    editBuild(recode){
      this.build.build = recode;
      this.build.visible=true;
    },
    toRoomList(recode){
      // 修改调整3.0系统路由跳转问题
      // this.fatherMethod("/build-room");
      this.$router.push({path:'/build-room',query: {buildId:recode.id}})
    },
    doReset() {
      this.queryParams = {
        userName:null,
        idCard:null,
        teamId:null,
        pageNumber:1,
      }
      this.pagination.current = 1
      this.doQuery();
    }
  }
}
</script>

<style scoped>
  .gutter-example >>> .ant-row > div {
    background: transparent;
    border: 0;
    margin-top:5px;
  }
  .gutter-box {
    /* background: #00a0e9; */
    padding: 5px 0;
}
</style>
