var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "80%" } },
    [
      _c(
        "a-row",
        [
          _c(
            "a-col",
            {
              staticStyle: {
                "background-color": "#ececec",
                "padding-right": "5px",
              },
              attrs: { span: 12 },
            },
            [
              _c(
                "a-card",
                { attrs: { title: "自动补报设置", bordered: false } },
                [
                  _c(
                    "a-form-model",
                    {
                      attrs: {
                        model: _vm.jwAutoAtteContent,
                        "label-col": _vm.labelCol,
                        "wrapper-col": _vm.wrapperCol,
                      },
                    },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "是否启用" } },
                        [
                          _c("a-switch", {
                            attrs: {
                              "checked-children": "启用",
                              "un-checked-children": "停用",
                            },
                            model: {
                              value: _vm.jwAutoAtte,
                              callback: function ($$v) {
                                _vm.jwAutoAtte = $$v
                              },
                              expression: "jwAutoAtte",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "自动补报时间" } },
                        [
                          _c("a-time-picker", {
                            attrs: {
                              "minute-step": 15,
                              format: "HH:mm",
                              valueFormat: "HH:mm",
                              placeholder: "开启后设置",
                              disabled: !_vm.jwAutoAtte,
                            },
                            model: {
                              value: _vm.jwAutoAtteContent.atteReportTime,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.jwAutoAtteContent,
                                  "atteReportTime",
                                  $$v
                                )
                              },
                              expression: "jwAutoAtteContent.atteReportTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "企微机器人链接" } },
                        [
                          _c("a-textarea", {
                            attrs: {
                              disabled: !_vm.jwAutoAtte,
                              placeholder: "请开启后输入链接",
                              rows: 3,
                            },
                            model: {
                              value: _vm.jwAutoAtteContent.wxRootHook,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.jwAutoAtteContent,
                                  "wxRootHook",
                                  $$v
                                )
                              },
                              expression: "jwAutoAtteContent.wxRootHook",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-button",
                    {
                      staticClass: "mt-2 ml-5",
                      attrs: { icon: "save", type: "primary" },
                      on: { click: _vm.saveProjectConfig },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c(
                "a-card",
                { attrs: { title: "指定日期补报", bordered: false } },
                [
                  _c(
                    "a-form-model",
                    {
                      attrs: {
                        model: _vm.jwAutoAtteContent,
                        "label-col": _vm.labelCol,
                        "wrapper-col": _vm.wrapperCol,
                      },
                    },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: "请选择日期" } },
                        [
                          _c("a-date-picker", {
                            attrs: {
                              valueFormat: "YYYY-MM-DD",
                              disabledDate: _vm.disabledDate,
                            },
                            model: {
                              value: _vm.reportAtteDate,
                              callback: function ($$v) {
                                _vm.reportAtteDate = $$v
                              },
                              expression: "reportAtteDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-button",
                    {
                      staticClass: "ml-5",
                      attrs: {
                        loading: _vm.setAutoLoding,
                        icon: "save",
                        type: "primary",
                      },
                      on: { click: _vm.setAutoReportAtte },
                    },
                    [_vm._v("执行")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }