var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        { attrs: { gutter: 16 } },
        [
          _c(
            "a-col",
            { attrs: { span: 6 } },
            [
              _c(
                "vue-scroll",
                {
                  staticStyle: { width: "100%", height: "855px" },
                  attrs: { ops: _vm.ops },
                },
                [
                  _c(
                    "a-card",
                    {
                      attrs: { title: "工种", size: "small", bordered: false },
                    },
                    [
                      _c("a-tree", {
                        attrs: {
                          "load-data": _vm.onLoadData,
                          "tree-data": _vm.treeData,
                        },
                        on: { select: _vm.onSelect },
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function (ref) {
                              var title = ref.title
                              return [_c("span", [_vm._v(_vm._s(title))])]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 17, offset: 0 } },
            [
              _c(
                "a-card",
                { attrs: { bordered: false } },
                [
                  _c("template", { slot: "title" }, [
                    _c("span", [_vm._v(_vm._s(_vm.node.title) + "班组清单")]),
                    _c(
                      "span",
                      { staticStyle: { float: "right" } },
                      [
                        _c(
                          "a",
                          {
                            staticStyle: {
                              float: "right",
                              "margin-top": "3px",
                              "margin-left": "10px",
                              "font-size": "13px",
                            },
                            attrs: {
                              href: "https://sjlw-templ.oss-cn-shenzhen.aliyuncs.com/enterprise-persons/import?Expires=1954460648&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=uwdRAtJbqH5fXT7xkQByjj%2BCPoE%3D",
                            },
                          },
                          [_vm._v("模板下载")]
                        ),
                        _c(
                          "a-upload",
                          {
                            attrs: {
                              name: "file",
                              multiple: false,
                              beforeUpload: _vm.beforeUpload,
                              accept: ".xls,.xlsx",
                              customRequest: _vm.resourceUpload,
                              "file-list": _vm.fileListFile,
                            },
                          },
                          [
                            _c(
                              "a-button",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { disabled: _vm.importLoading },
                              },
                              [_vm._v("导入名册")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { class: _vm.advanced ? "search" : null },
                    [
                      _c("a-form", { attrs: { layout: "horizontal" } }, [
                        _c(
                          "div",
                          { class: _vm.advanced ? null : "fold" },
                          [
                            _c(
                              "a-row",
                              [
                                _c(
                                  "a-col",
                                  { attrs: { md: 8, sm: 24 } },
                                  [
                                    _c(
                                      "a-form-item",
                                      {
                                        attrs: {
                                          label: "班组名称",
                                          labelCol: { span: 5 },
                                          wrapperCol: { span: 18, offset: 1 },
                                        },
                                      },
                                      [
                                        _c("a-input", {
                                          attrs: {
                                            placeholder: "请输入班组名称",
                                          },
                                          model: {
                                            value: _vm.queryParam.teamName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.queryParam,
                                                "teamName",
                                                $$v
                                              )
                                            },
                                            expression: "queryParam.teamName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "a-col",
                                  { attrs: { md: 8, sm: 24 } },
                                  [
                                    _c(
                                      "a-form-item",
                                      {
                                        attrs: {
                                          label: "负责人",
                                          labelCol: { span: 5 },
                                          wrapperCol: { span: 18, offset: 1 },
                                        },
                                      },
                                      [
                                        _c("a-input", {
                                          attrs: {
                                            placeholder: "请负责人姓名",
                                          },
                                          model: {
                                            value: _vm.queryParam.fzrUserName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.queryParam,
                                                "fzrUserName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "queryParam.fzrUserName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "a-col",
                                  { attrs: { md: 8, sm: 24 } },
                                  [
                                    _c(
                                      "a-form-item",
                                      {
                                        attrs: {
                                          label: "手机",
                                          labelCol: { span: 5 },
                                          wrapperCol: { span: 18, offset: 1 },
                                        },
                                      },
                                      [
                                        _c("a-input", {
                                          attrs: { placeholder: "负责人手机" },
                                          model: {
                                            value: _vm.queryParam.fzrPhone,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.queryParam,
                                                "fzrPhone",
                                                $$v
                                              )
                                            },
                                            expression: "queryParam.fzrPhone",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "span",
                          { staticStyle: { float: "right" } },
                          [
                            _c(
                              "a-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.doQuery()
                                  },
                                },
                              },
                              [_vm._v("查询")]
                            ),
                            _c(
                              "a-button",
                              {
                                staticStyle: { "margin-left": "8px" },
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.doAddTeam()
                                  },
                                },
                              },
                              [_vm._v("新增班组")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { attrs: { md: 24, sm: 24 } },
                        [
                          _c(
                            "standard-table",
                            {
                              attrs: {
                                columns: _vm.columns,
                                dataSource: _vm.teams_data,
                                selectedRows: _vm.selectedRows,
                                pagination: _vm.pagination,
                                loading: _vm.tableLoading,
                                isAlert: false,
                              },
                              on: {
                                "update:selectedRows": function ($event) {
                                  _vm.selectedRows = $event
                                },
                                "update:selected-rows": function ($event) {
                                  _vm.selectedRows = $event
                                },
                                clear: _vm.onClear,
                                change: _vm.onPageChange,
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "action",
                                  fn: function (ref) {
                                    var text = ref.text
                                    var record = ref.record
                                    return _c(
                                      "div",
                                      {},
                                      [
                                        _c("a-icon", {
                                          staticStyle: { color: "#FC5531" },
                                          attrs: {
                                            type: "unordered-list",
                                            title: "查看人员列表",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.viewPersons(record)
                                            },
                                          },
                                        }),
                                        _c("a-divider", {
                                          attrs: { type: "vertical" },
                                        }),
                                        _c("a-icon", {
                                          staticStyle: { color: "blue" },
                                          attrs: {
                                            type: "edit",
                                            title: "编辑班组",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.doOpenTeam(record)
                                            },
                                          },
                                        }),
                                        _c("a-divider", {
                                          attrs: { type: "vertical" },
                                        }),
                                        _c("a-icon", {
                                          staticStyle: { color: "red" },
                                          attrs: {
                                            type: "delete",
                                            title: "删除班组",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.doDeleteTeam(
                                                "" + record.key
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  },
                                },
                              ]),
                            },
                            [
                              _c(
                                "template",
                                { slot: "statusTitle" },
                                [
                                  _c("a-icon", {
                                    attrs: { type: "info-circle" },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.onStatusTitleClick($event)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("team-editor", {
        attrs: {
          visible: _vm.teamEditor.visible,
          teamInfo: _vm.teamEditor.teamInfo,
          typeWorkDataSource: _vm.teamEditor.typeWorkDataSource,
        },
        on: {
          teamPageClose: _vm.doCloseTeamEditor,
          submit: function ($event) {
            return _vm.doSubmitTeamInfo($event)
          },
        },
      }),
      _c("team-persons", {
        attrs: {
          visible: _vm.teamPersons.visible,
          teamId: _vm.teamPersons.teamId,
          teamName: _vm.teamPersons.teamName,
        },
        on: {
          doCloseTeamPersons: _vm.doCloseTeamPersonsList,
          doOutWork: _vm.doOutWorks,
        },
      }),
      _c(
        "a-modal",
        {
          attrs: { title: "请选择项目" },
          on: { ok: _vm.handleOk, cancel: _vm.cancelWork },
          model: {
            value: _vm.outwork.visible,
            callback: function ($$v) {
              _vm.$set(_vm.outwork, "visible", $$v)
            },
            expression: "outwork.visible",
          },
        },
        [
          _c(
            "a-select",
            {
              staticStyle: { width: "450px", "z-index": "999999999" },
              attrs: {
                "show-search": "",
                placeholder: "请选择一个项目生成派工单",
                "option-filter-prop": "children",
                "filter-option": _vm.filterOption,
              },
              on: { change: _vm.handleChange },
              model: {
                value: _vm.projectId,
                callback: function ($$v) {
                  _vm.projectId = $$v
                },
                expression: "projectId",
              },
            },
            _vm._l(_vm.outwork.projectList, function (item) {
              return _c(
                "a-select-option",
                {
                  key: item.projId,
                  staticStyle: { "z-index": "999999999" },
                  attrs: { value: item.projId },
                },
                [_vm._v(" " + _vm._s(item.fullName) + " ")]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }