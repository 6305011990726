var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a_Approval" },
    [
      _c(
        "div",
        {
          staticClass: "plug-Overview",
          staticStyle: { display: "flex", "justify-content": "space-between" },
        },
        [
          _vm._v(
            " " +
              _vm._s(_vm.DetailPageModeNameMap[_vm.pageMode]) +
              "标准化考评 "
          ),
          _c(
            "a-space",
            [
              _c("a-button", { on: { click: _vm.onClose } }, [_vm._v("关闭")]),
              _vm.isEdit || _vm.isAdd
                ? _c(
                    "a-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSave } },
                    [_vm._v("保存")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("a-divider"),
      _c("form-index", {
        ref: "formindexRef",
        attrs: { formData: _vm.formConfig },
        on: {
          changeYear: _vm.changeYear,
          handleChange: _vm.handleChange,
          handlSelect: _vm.handlSelect,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }