<template>
  <div class="container">
    <a-tabs :default-active-key="defaultActiveKey" @change="callback" forceRender>
        <a-tab-pane key="1" tab="在场工人"> </a-tab-pane>
        <a-tab-pane key="0" tab="离场工人"> </a-tab-pane>
      </a-tabs>
    <form-index
      ref="formindex"
      :formData="formData"
      @handleSubmit="handleSubmit"
      :projectList="projectList"
      :flexSubmit="true"
      @handlSelect="handlSelect"
      @handleChange="handleChange"
    >
      <!-- 下部分按钮插槽 -->
      <template #flexSubmit>
        <a-space  style="margin-left: 55px; margin-top: 5px">
          <a-button icon="search" type="primary" html-type="submit"> 查询 </a-button>
          <a-space class="operator">
            <a-button @click="close(true)"> 重置 </a-button>
          </a-space>
        </a-space>
      </template>
    </form-index>
    <div style="margin-top: -20px;" class="a_a_footer">
      <div class="mb-1 mt-2">
        <a-space class="operator">
          <a-button @click="exportStaff()"><a-icon type="download" />导出全部信息</a-button>
        </a-space>
        <a-space class="operator">
          <a-button v-if="defaultActiveKey != 1" @click="downloadBatch()"><a-icon type="download" />批量下载凭证</a-button>
        </a-space>
      </div>
      <!-- 表格封装 -->
      <standard-table
        class=""
        :columns="columns"
        :dataSource="dataSource"
        :selectedRows.sync="selectedRows"
        rowKey="id"
        :scroll="{ x: 1300 }"
        :pagination="pagination"
        :loading="tableLoading"
        @change="onPageChange"
        :isAlert="false"
        :bordered="true"
        @update:selectedRows="updateSelectRows($event)"
      >
        <template slot="index" slot-scope="{ index }">
          <span>
            {{
              (pagination.current - 1) * pagination.pageSize +
                parseInt(index) +
                1
            }}
          </span>
        </template>
        <template slot="hkFace" slot-scope="{ text }">
          <div class="flex a-center j-center">
            <div v-if="text != '' && text != null">
              {{text}}
            </div>
            <a-spin :spinning="!text"></a-spin>
          </div>
        </template>
        <template slot="workName" slot-scope="{ record }">
          <router-link :to="'/electronic_resume_worker/' + record.projWorkNum + `?teamId=${record.teamId}&workNum=${record.workNum}`">
            {{ record.workName }}
          </router-link>
          <div v-if="record.leaveStatus == 0" style="color: #FF5C77">(退场中)</div>
          <a-popover v-if="record.projectNames && showNames(record.projectNames).length > 1">
              <template slot="content">
                <div v-for="(item,index) in showNames(record.projectNames)" :key="index">{{deleNames(item)}}</div>
              </template>
              <template slot="title">
                <span style="color: #FF5D78">已在场项目</span>
              </template>
              <div style="color: #FF5D78">(多项目)</div>
          </a-popover>
        </template>
        <template slot="workerFace" slot-scope="{ text }">
          <a-avatar shape="square" :size="64" :src="text" icon="user"/>
        </template>
        <template slot="isreport" slot-scope="{ text }">
          <span v-if="text == 1"><i class="iconfont duigouxiao" style="color:#00CE86"></i></span>
          <span v-else><i class="iconfont path" style="color:#FF5C77"></i></span>
        </template>


        <div slot="action" slot-scope="{ record }">
          <div class="sTables">
            <div @click="visit(record)">查看</div>
            <!-- <div @click="visitEdit(record)">修改</div> -->
          </div>
        </div>
      </standard-table>
    </div>
    <Export-loading :cPercent="cPercent" :isExporting="isExporting" :loadingText="loadingText"></Export-loading>
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import {getAllTeams, getJobByWorkerType, setJwReportStatus, reportJwStaff, batchSyncStaffToPlatform,
  getProAllOfficeList,getStaffHkFace, getOfficeStaffs} from "@/services/participationUnit"
import moment from "moment";
import { mapGetters } from "vuex";
import { exportProgress } from "@/pages/aqjyzl/export"; // 导出方法
import ExportLoading from '@/components/ExportLoading/ExportLoading' // progress组件
import { getOfficeProject} from "@/services/projectManagement";
import {EXPORT_STAFF_ALL_MSG,DOWN_BATCH_WORK_RECORD} from '@/services/api'
import { useRender } from '@/hooks/useRender.js'

const formData = [
  {
    label: "姓名",
    placeholder: "请填写",
    decorator: [
      "workerName",
      { rules: [{ required: false, message: "请填写" }] },
    ],
    type: "input",
    key: "workerName",
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
  },
  {
    label: "项目名称",
    placeholder: "请输入选择项目名称",
    decorator: [
      "projectId",
      { rules: [{ required: false, message: "请输入选择项目名称" }] },
    ],
    type: "inputGroup",
    key: "projectId",
    dataSource: [],
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
  },
  {
    label: "班组",
    placeholder: "请输入选择班组",
    decorator: ["teamId", { rules: [{ required: false, message: "请输入选择班组" }] }],
    type: "select",
    key: "teamId",
    selectlist: [],
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
    showSearch: true
  },
  {
    label: "工种",
    placeholder: "请选择",
    decorator: [
      "title",
      { rules: [{ required: false, message: "请选择" }] },
    ],
    type: "select",
    key: "title",
    selectlist: [],
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
    showSearch: true
  },
  {
    label: "进场日期",
    placeholder: "",
    decorator: ["enterDate", { rules: [{ required: false, message: "" }] }],
    type: "rangePicker",
    key: "enterDate",
    dataSource: [],
    col: 6,
    labelCol: 6,
    wrapperCol: 14,
    display: true,
    format: "YYYY-MM-DD",
  },
];

const taskColumns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
    // fixed: "left",
  },
  {
    title: "姓名",
    dataIndex: "name",
    align: "center",
    width: 110,
    scopedSlots: { customRender: "name" },
    // fixed: "left",
  },
  {
    title: "状态",
    dataIndex: "step",
    align: "center",
    // ellipsis: true,
    scopedSlots: { customRender: "step" },
    // fixed: "left",
  },
];
const columns = [
      {
        title: "序号",
        width: 65,
        scopedSlots: { customRender: "index" },
        // customRender: (text, record, index) => `${index + 1}`,
        align: "center",
      },
      {
        title: "姓名",
        dataIndex: "workName",
        align: "center",
        width: 120,
        scopedSlots: { customRender: "workName" },
      },
      {
        title: "头像",
        dataIndex: "workerFace",
        align: "center",
        width: 100,
        scopedSlots: { customRender: "workerFace" },
      },
      {
        title: "所属项目",
        dataIndex: "projectName",
        align: "center",
        scopedSlots: { customRender: "projectName" },
      },
      {
        title: "所属班组",
        align: "center",
        dataIndex: "teamName",
        scopedSlots: { customRender: "teamName" },
      },
      {
        title: "工种",
        dataIndex: "workTypeName",
        align: "center",
        width: 120,
        ellipsis: true,
        scopedSlots: { customRender: "workTypeName" },
      },
      {
        title: "进场时间",
        dataIndex: "enterDate",
        align: "center",
        width: 120,
        customRender: (text) => useRender.renderDate(text)
      },
      {
        title: "退场时间",
        dataIndex: "quitDate",
        align: "center",
        width: 120,
        customRender: (text) => useRender.renderDate(text)
      },
      {
        title: "操作",
        align: "center",
        fixed: "right",
        width: 160,
        scopedSlots: { customRender: "action" },
      },
    ];
export default {
  name: 'SuijianClientWorkerList',
  mixins: [exportProgress], //导出方法注入
  components: {
    formIndex,
    StandardTable,
    ExportLoading,
  },
  data() {

    return {
      projectArr: [],
      projectList: [], //获取当前项目数据
      formItemLayout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 },
      },
      defaultFileList: [],
      formData: formData,
      columns,
      dataSource: [], //table数组
      selectedRows: [], //选中的数据
      pagination: {
        //分页数据
        showSizeChanger:true,
        pageSizeOptions: ['10', '50', '100', '150'],
        current:1,
        pageSize:50,
        total:0,
        showLessItems:true,
        showQuickJumper:true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      params: { //测试数据
        pageNumber: 1,
        pageSize: 50,
        enterStatus: '1'
      },
      tableLoading: false, //table加载中
      distributeVisible: false,
      workNumList: [], //选择的工号list
      exitDialog: false,
      selectedRecord: null,
      modalTitle: '',
      modalWidth: 1000,
      modalVisible: false,
      modalDataSource: [],
      modalPagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      modalTableLoading: false,
      modalParams: {
        pageNumber: 1,
        pageSize: 10,
      },
      taskObj: {
        taskCount: 0,
        endTaskCount: 0
      },
      exitForm: this.$form.createForm(this, { name: 'coordinated' }),
      loadingText:"请稍后.",
      isExporting: false,
      cPercent: 0,
      arrRoles:['zong_bao_lao_wu',], // 下发设备权限
      arrJwOperate: ['67f43348a8fd450f9cac17a9215ba609'], // 有操作权限的项目ID
      staffHkFace: [],
      defaultActiveKey: '1',
    };
  },
  watch: {
    defaultFileList: {
      handler(newVal) {
        console.log(newVal)
      }
    },
    defaultActiveKey: {
      handler(newVal) {
        if (newVal == 1) {
          let keyToDelete = 'quitDate'
          this.columns = this.columns.filter(item => item.dataIndex !== keyToDelete)
        } else {
          this.columns = [
            {
              title: "序号",
              width: 65,
              scopedSlots: { customRender: "index" },
              // customRender: (text, record, index) => `${index + 1}`,
              align: "center",
            },
            {
              title: "姓名",
              dataIndex: "workName",
              align: "center",
              width: 120,
              scopedSlots: { customRender: "workName" },
            },
            {
              title: "头像",
              dataIndex: "workerFace",
              align: "center",
              width: 100,
              scopedSlots: { customRender: "workerFace" },
            },
            {
              title: "所属项目",
              dataIndex: "projectName",
              align: "center",
              scopedSlots: { customRender: "projectName" },
            },
            {
              title: "所属班组",
              align: "center",
              dataIndex: "teamName",
              scopedSlots: { customRender: "teamName" },
            },
            {
              title: "工种",
              dataIndex: "workTypeName",
              align: "center",
              width: 120,
              ellipsis: true,
              scopedSlots: { customRender: "workTypeName" },
            },
            {
              title: "进场时间",
              dataIndex: "enterDate",
              align: "center",
              width: 120,
              customRender: (text) => useRender.renderDate(text)
            },
            {
              title: "退场时间",
              dataIndex: "quitDate",
              align: "center",
              width: 120,
              customRender: (text) => useRender.renderDate(text)
            },
            {
              title: "操作",
              align: "center",
              fixed: "right",
              width: 160,
              scopedSlots: { customRender: "action" },
            },
          ];
        }
      },
      immediate: true,
    }
  },
  computed: {
    ...mapGetters("account", ["user"]),
    canOperate() {
      return this.user.nowRoleKey.some(role => this.arrRoles.includes(role));
    },
    canJwOperate() {
      return this.arrJwOperate.some(role => role == this.user.projectId)
    }
  },
  mounted() {
    this.getData(this.params)
    this.getWorkerType()
    // let keyToDelete = 'quitDate'
    // this.columns = this.columns.filter(item => item.dataIndex !== keyToDelete)
    // this.getZdata(getProAllOfficeList, {value: '', item: {key: 'unitId'}}, "officeName");
    // 没有导入建委状态权限的操作
  },
  methods: {
    getData(params) {
      this.tableLoading = true;
      getOfficeStaffs(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
          // this.dataSource.forEach((item) => {
          //   item.riskTips = item.riskTips ? item.riskTips : '/'
          //   item.officeName = item.officeName ? item.officeName : '/'
          //   item.teamName = item.teamName ? item.teamName : '待分配'
          //   // if (item.isreport && item.isreport == '1') {
          //   //   item.isreport = '是'
          //   // } else if (item.isreport && item.isreport == '0') {
          //   //   item.isreport = '否'
          //   // } else {
          //   //   item.isreport = '/'
          //   // }
          //   // getStaffHkFace
          //   item.enterDate = moment(item.enterDate).format("YYYY-MM-DD");
          // })
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    errToast(record) {
      if (record.syncJwErrInfo) {
        const h = this.$createElement;
        this.$info({
          content: h('div', {}, [
            h('p', record.syncJwErrInfo),
          ]),
          onOk() {},
        });
      }
    },
    handleSubmit(e) {
      let obj = { ...e, ...this.params }
      console.log(obj,'obj ===')
      obj.pageNumber = 1
      this.pagination.current = 1;
      this.selectedRows = []
      // obj.enterDate = obj.enterDate === '' ? [] : obj.enterDate
      if (obj.enterDate && obj.enterDate.length) {
        obj.enterDateStart = obj.enterDate[0]
        obj.enterDateEnd = obj.enterDate[1]
      }
      delete obj.enterDate
      this.getData(obj);
    },
    //查询所有班组
    doQueryTeams(params){
      getAllTeams(params).then(res => {
        if(res.data.code === 0){
          this.formDataFn('teamId', res.data.data, 'teamName')
        }
      })
    },
    // 对下拉框做数据处理
    formDataFn(key, data, itemName) {
        let arrSelect = []
        let arrSource = []
        data.forEach(item => {
            // 班组模糊匹配dataSource
            arrSource.push({ 'title': item[itemName], 'id': item.id })
            // seletetList选项
            arrSelect.push({ 'name': item[itemName], 'value': item.id })
        })
        this.formData.forEach(item => {
             if (item.key == key) {
                item.dataSource = arrSource
                item.selectlist = arrSelect
            }

        })
    },
    handleChange(e) {
      if (e.item.key == 'teamId') {
        this.doQueryTeams({ teamName: e.value })
      }
      else if (e.item.key == 'projectId') {
        this.projectArr = []
        this.projectList = []
        this.getZdata(getOfficeProject, e, "projectName",'fullName');
      }
    },
    // 接口封装
    /**
     * msg 接收接口名称
     * e 数据
     * name 参数名
     * */
     getZdata(msg, e, name,title) {
      let data = {
        [name]: e.value,
        officeId: this.user.officeId
      };

      msg(data).then((res) => {
        if (res.status == 200) {
          const data = res.data.data.list ? res.data.data.list : res.data.data
          data.forEach((item) => {
            this.projectArr.push({
              title: item[title],
              id: item.id,
            });
          });
          this.projectList = data;
          // 获取当前匹配key，赋值相对于数组
          this.formData.forEach((item) => {
            if (e.item.key === item.key) {
              item.dataSource = this.projectArr;
            }
          });
        }
      });
    },
    // 特殊数据处理
    handlSelect({ val, keys }) {
      this.projectList.forEach((item) => {
        if (keys == "projectId" && item.id == val) {
          // this.$refs["formindex"].setFieldsValue(item.officeId, {}, "unitId");
          this.doQueryTeams({ projectId: val })
        }
      });
    },
    //获取工种
    getWorkerType() {
      getJobByWorkerType([]).then(res => {
        console.log(res)
        if(res.data.code === 0){
          this.formDataFn('title', res.data.data, 'carft')
        }
      })
    },
    //表格 - 分页查询
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      if (obj.status == "") {
        obj.status = 0;
      }
      if (obj.enterDate == "") {
        obj.enterDate = [];
      }
      this.params.pageNumber = e.current;
      this.pagination.current = e.current;
      this.params.pageSize = e.pageSize;
      this.pagination.pageSize = e.pageSize;
      this.getData(Object.assign({}, this.params, obj));
    },
    //查看
    visit(record) {
      this.$router.push({
        name:'工人电子履历',
        params: {
          projWorkNum: record.projWorkNum
        },
        query:{
          teamId: record.teamId,
          workNum: record.workNum
        }
      })
    },
    //修改
    visitEdit(record) {
      this.$router.push({
        name:'进场登记',
        query:{
          projWorkNum: record.projWorkNum,
          teamId: record.teamId,
          workNum: record.workNum
        }
      })
    },
    updateSelectRows(e) {
      console.log(e)
    },
    // 重置
    close(isResetFileds) {
      this.params.pageNumber = 1
      this.params.pageSize = 50
      this.pagination.current = 1;
      this.pagination.pageSize = 50;
      this.selectedRows = []
      if (isResetFileds) {
        this.$refs["formindex"].resetFields();
        this.getData(this.params);
      } else {
        // 切换tabs保留筛选参数
        let obj = this.$refs["formindex"].getFieldValue();
        if (obj.enterDate != '') {
        obj.enterDateStart = obj.enterDate[0]
        obj.enterDateEnd = obj.enterDate[1]
        }
        delete obj.enterDate
        this.getData(Object.assign({}, this.params, obj));
      }
    },
    callback(key) {
      this.params.enterStatus = key;
      this.defaultActiveKey = key
      this.close();
    },
    // 导出人员信息
    exportStaff() {
      let formObj = this.$refs["formindex"].getFieldValue()
      this.loadingText = "正在导出全部信息，请稍候 ····";
      let recordIdList = []
      if (this.selectedRows.length > 0) {
        this.selectedRows.forEach(el => {
          recordIdList.push(el.workNum)
        })
      }
      if (formObj.projectId == '') return this.$message.error('请先选择查询项目，再导出人员信息')
      let obj = {
        officeId: formObj.unitId ? formObj.unitId : undefined,
        projectId: formObj.projectId,
        teamId: formObj.teamId ? formObj.teamId : undefined,
        workNums: recordIdList.length ? recordIdList : undefined,
        enterStatus: Number(this.params.enterStatus), // 在场或者离场人员
        enterDateStart: formObj.enterDate[0] ? formObj.enterDate[0] : undefined,
        enterDateEnd: formObj.enterDate[1] ? formObj.enterDate[1] : undefined
      }
      let fileName = `${obj.enterStatus == 1 ? '在场人员' : '离场人员'}全部信息.xls`
      this.isExporting=true;
      this.cPercent=0;
      this.exportFunNomal2(EXPORT_STAFF_ALL_MSG,obj,fileName)
      this.selectedRows = []
    },
    // 批量下载凭证
    downloadBatch(){
        this.loadKeys = [];
        this.cPercent = 0;
        const time = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        if(this.selectedRows&&this.selectedRows.length>0){
            this.isExporting=true
            this.loadingText = "正在下载离场凭证...";
            let relationIds = []
            this.selectedRows.map((item) => {
              relationIds.push(item.id)
            })
            let params = {
              relationIds
            }
            let fileName="离场凭证("+time+").zip";
            this.exportFunNomal(DOWN_BATCH_WORK_RECORD,params,fileName)
            this.selectedRows = []
        }else{
            this.$message.info('请选中离场人员进行下载');
            return;
        }
    },
    showNames(projects){
      return projects.split(',')
    },
    deleNames(projects) {
      // 去除 @
      return projects.replace(/@/g, "")
    }
  },
};
</script>

<style lang="less" scoped>
.a_a_footer {
  padding: -10px 0px;
  background: #ffffff;

  .operator {
    margin-right: 8px;

    .ant-btn {
      color: #307dfa;
    }
  }

  .sTables {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      width: 100%;
      text-align: center;
      cursor: pointer;
    }

    div:nth-child(1) {
      color: #307dfa;
    }

    div:nth-child(2) {
      color: #ff5c77;
    }

    div:nth-child(3) {
      color: #615e83;
    }
  }

}
.content_div {
  padding: 5px 0;
  cursor: pointer;
}
.content_div:hover {
  color: #307dfa;
}
.task-tips {
  color: #4377cc;
  text-decoration: underline;
  cursor: pointer;
}
.jwTag {
  border-radius: 2px;
  width: 65px;
  height: 30px;
  line-height: 28px;
  margin: auto;
  cursor: pointer;
  font-size: 14px;
}
.tagRed {
  background: #FFE8EC;
  color: #FF5D78;
}
.tagGray {
  background: #EAEBEB;
  color: #989999;
}
.tagGreen {
  background: #DEFCF1;
  color: #00CE86;
}
.tagBlue {
  background: #0874F01A;
  color: #0874F0;
}
</style>
