var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "standard-table",
        {
          attrs: {
            columns: _vm.columns,
            dataSource: _vm.tableData,
            rowKey: "projectId",
            pagination: _vm.pagination,
            loading: _vm.tableLoading,
            isAlert: false,
            bordered: true,
          },
          on: { change: _vm.handleChangePage },
          scopedSlots: _vm._u([
            {
              key: "index",
              fn: function (ref) {
                var index = ref.index
                return [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        (_vm.pagination.current - 1) * _vm.pagination.pageSize +
                          parseInt(index) +
                          1
                      )
                    ),
                  ]),
                ]
              },
            },
            {
              key: "companyName",
              fn: function (ref) {
                var text = ref.text
                return [
                  _c(
                    "span",
                    { style: { color: text == "机施集团" ? "#FF5D78" : "" } },
                    [_vm._v(_vm._s(text))]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "title" }, [
            _c("div", { staticClass: "table-title" }, [
              _vm._v(
                _vm._s(_vm.year) + "年企业项目标准化管理年度检查考核评分汇总表"
              ),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }