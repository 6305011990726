var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "standard-table" },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c(
            "a-space",
            [
              _vm._l(_vm.btnArr, function (btn) {
                return [
                  !btn.hidden
                    ? _c(
                        "a-button",
                        _vm._b(
                          { key: btn.name, on: { click: btn.click } },
                          "a-button",
                          btn.attrs,
                          false
                        ),
                        [_vm._v(_vm._s(btn.name))]
                      )
                    : _vm._e(),
                ]
              }),
              _vm._t("btnAfter"),
            ],
            2
          ),
          _c("div", [_vm._t("tableTitle")], 2),
        ],
        1
      ),
      _vm.isAlert
        ? _c(
            "div",
            { staticClass: "alert" },
            [
              _vm.selectedRows
                ? _c(
                    "a-alert",
                    { attrs: { type: "info", "show-icon": true } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "message",
                          attrs: { slot: "message" },
                          slot: "message",
                        },
                        [
                          _vm._v(" 已选择 "),
                          _c("a", [_vm._v(_vm._s(_vm.selectedRows.length))]),
                          _vm._v(" 项 "),
                          _c(
                            "a",
                            {
                              staticClass: "clear",
                              on: { click: _vm.onClear },
                            },
                            [_vm._v("清空")]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.totalBoolean
        ? _c("div", { staticClass: "total" }, [
            _vm._v(" 共" + _vm._s(_vm.pagination.total) + "个项目 "),
          ])
        : _vm._e(),
      _c(
        "a-table",
        {
          staticStyle: { "margin-top": "12px" },
          attrs: {
            rowClassName: _vm.rowClassName,
            bordered: _vm.bordered,
            loading: _vm.loading,
            columns: _vm.insideColumns,
            dataSource: _vm.dataSource,
            rowKey: _vm.rowKey,
            pagination: _vm.pagination,
            expandedRowKeys: _vm.expandedRowKeys,
            expandedRowRender: _vm.expandedRowRender,
            checkBoxDisable: _vm.checkBoxDisable,
            size: _vm.tableSize,
            scroll: _vm.scroll,
            rowSelection: _vm.selectedRows
              ? {
                  selectedRowKeys: _vm.selectedRowKeys,
                  onChange: _vm.updateSelect,
                  getCheckboxProps: _vm.getCheckboxProps,
                  onSelect: _vm.onSelect,
                }
              : undefined,
          },
          on: { change: _vm.onChange },
          scopedSlots: _vm._u(
            [
              _vm._l(
                Object.keys(_vm.$scopedSlots).filter(function (key) {
                  return key !== "expandedRowRender"
                }),
                function (slot) {
                  return {
                    key: slot,
                    fn: function (text, record, index) {
                      return [
                        _vm._t(slot, null, null, {
                          text: text,
                          record: record,
                          index: index,
                        }),
                      ]
                    },
                  }
                }
              ),
              {
                key: _vm.$scopedSlots.expandedRowRender
                  ? "expandedRowRender"
                  : "",
                fn: function (record, index, indent, expanded) {
                  return [
                    _vm._t(
                      _vm.$scopedSlots.expandedRowRender
                        ? "expandedRowRender"
                        : "",
                      null,
                      null,
                      {
                        record: record,
                        index: index,
                        indent: indent,
                        expanded: expanded,
                      }
                    ),
                  ]
                },
              },
            ],
            null,
            true
          ),
        },
        [
          _vm._l(Object.keys(_vm.$slots), function (slot) {
            return _c("template", { slot: slot }, [_vm._t(slot)], 2)
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }