var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.newLogin
    ? _c("div", { staticClass: "footer" }, [
        _c(
          "div",
          { staticClass: "links" },
          _vm._l(_vm.linkList, function (item, index) {
            return _c(
              "a",
              {
                key: index,
                attrs: {
                  target: "_blank",
                  href: item.link ? item.link : "javascript: void(0)",
                },
              },
              [
                item.icon
                  ? _c("a-icon", { attrs: { type: item.icon } })
                  : _vm._e(),
                _vm._v(_vm._s(item.name) + " "),
              ],
              1
            )
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "copyright" },
          [
            _vm._v(" Copyright"),
            _c("a-icon", { attrs: { type: "copyright" } }),
            _vm._v(_vm._s(_vm.copyright) + " "),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }