<template>
  <div class="a_Approval">
    <div class="plug-Overview">危大工程情况表</div>
    <a-divider />
    <a-form-model :model="params" class="clearfix" @submit="handleSubmit" v-if="user.loginType != 0">
      <a-col :span="5">
        <a-form-model-item label="工程管理部" :labelCol="{ span: 9 }" :wrapperCol="{ span: 14, offset: 1 }">
          <a-select
            v-model="params.gcglbId"
            allowClear
            placeholder="请选择"
          >
            <a-select-option
              v-for="item in gcglbList"
              :key="item.value"
              :value="item.value"
              :title="item.name"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <!-- <a-form-model-item label="月度">
        <a-month-picker style="width: 220px" placeholder="请选择月份" format="YYYY-MM" v-model="monthPickerVal">
          <a-icon slot="suffixIcon" type="smile" />
        </a-month-picker>
      </a-form-model-item> -->

      <a-col :span="5">
        <a-form-model-item label="项目名称" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
          <a-input placeholder="请输入项目名称" v-model="params.projectName" allowClear/>
        </a-form-model-item>
      </a-col>

      <a-col :span="4">
        <a-space class="btnBox">
          <a-button type="primary" html-type="submit"> 查询 </a-button>
          <a-button @click="resetQuery"> 重置 </a-button>
          <a-button type="primary" @click="doDowload" v-if="user.isGcglb == 0 && user.loginType == 1 && user.nowRoleKey.includes('JS_LJJ')"> 导出 </a-button>
        </a-space>
      </a-col>
    </a-form-model>

    <a-button @click="batchConfirm" type="primary" v-if="user.isGcglb == 1">批量确认</a-button>
    <standard-table
      :columns="columns"
      :dataSource="tableData"
      rowKey="id"
      :scroll="{ x: 1300 }"
      :pagination="pagination"
      :loading="tableLoading"
      :selectedRows.sync="selectedRows"
      @change="handleChangePage"
      :isAlert="false"
      :bordered="true"
      @update:selectedRows="updateSelectRows($event)"
    >
      <template slot="index" slot-scope="{ index }">
        <span v-if="index < tableData.length - 1">{{ itemNo(index) }}</span>
        <span v-else>合计</span>
      </template>
      <template slot="status" slot-scope="{ text }">
        <span >{{ text | statusName }}</span>
      </template>
      <template slot="problems" slot-scope="{ index, record }">
        <div v-for="(problem, index) in record.problems" :key="index">{{ problem }}</div>
      </template>
      <div slot="action"
        slot-scope="{ index, record }">
        <a
          @click="() => handleEdit(record)"
        >
          <span v-if="user.nowRoleKey.includes('PROJECT_AQY_ZB_FZR')">{{index !== tableData.length - 1 ? '编辑' : ''}}</span>
          <span v-else>{{index !== tableData.length - 1 ? '查看' : ''}}</span>
        </a>
        <a
          v-if="record.status == 0 && user.isGcglb == 1 && user.loginType == 1"
          @click="() => handleConfirm(record)"
          style="color: red;"
        >
           确认
        </a>
        <!-- <div 
          v-if="record.status == 0 && user.isGcglb == 1 && user.loginType == 1 && !canConfirm" 
          style="color: #999;"><span title="开放时间：本月22至24日">确认</span>
        </div> -->
      </div>
    </standard-table>

    <Export-loading
      :cPercent="cPercent"
      :isExporting="isExporting"
      :loadingText="loadingText"
    ></Export-loading>
  </div>
</template>

<script>
import {
  getWdHiddenDangerStaticList, getWdHiddenDangerStaticTotal,
  wdHiddenDangerStaticExportUrl, projectSourceDangerDistributionStaticBack,
  updateSourceDangerApprove, updateSourceDangerBatchApprove
} from "@/services/safeMonthlyReport";
import { request } from "@/utils/request";
import { QUERY_OFFICE_BY_USER } from "@/services/api";
import ExportLoading from "@/components/ExportLoading/ExportLoading"; // progress组件
import { exportProgress } from "@/pages/aqjyzl/export"; // 导出方法
import { mapGetters } from "vuex";
import StandardTable from "@/components/table/StandardTable";
import moment from "moment";

export default {
  mixins: [exportProgress], //导出方法注入
  components: {
    ExportLoading,
    StandardTable
  },
  data() {
    return {
      monthPickerVal: moment(new Date(), 'YYYY-MM'),
      params: {
        month: '',
        gcglbId: '',
        projectName: '',
        year: '',
        pageNumber: 1,
        pageSize: 10,
      },
      selectedRows: [], //选中的数据
      pagination: {
        //分页数据
        current: 1,
        pageSize: 11,
        total: 0,
        showTotal: (total, range) => `共 ${total} 条`,
      },
      gcglbList: [],
      total: 0,
      columns: [
        {
          title: "序号",
          align: "center",
          scopedSlots: { customRender: "index" },
          width: 60,
        },
        {
          title: "工程管理部",
          align: "center",
          dataIndex: "gcglbName",
          width: 110,
        },
        {
          title: "项目名称",
          dataIndex: "projectName",
          width: 280,
          ellipsis: true,
          tooltip: true
        },
        {
          title: "危险性较大分部分项工程",
          align: "center",
          children: [
            {
              title: "基坑工程",
              align: "center",
              children: [
                {
                  title: "",
                  align: "center",
                  dataIndex: "baseHole",
                  width: 60
                },
                {
                  title: "超",
                  align: "center",
                  dataIndex: "baseHoleOut",
                  width: 60
                },
              ]
            },
            {
              title: "模板及支撑体系",
              align: "center",
              children: [
                {
                  title: "",
                  align: "center",
                  dataIndex: "modelSupport",
                  width: 60
                },
                {
                  title: "超",
                  align: "center",
                  dataIndex: "modelSupportOut",
                  width: 60
                },
              ]
            },
            {
              title: "起重吊装及起重机械安装拆卸",
              align: "center",
              children: [
                {
                  title: <a-tooltip><template slot="title">塔式起重机</template>塔</a-tooltip>,
                  align: "center",
                  dataIndex: "hoistingTower",
                  width: 60,
                },
                {
                  title: <a-tooltip><template slot="title">施工升降机</template>梯</a-tooltip>,
                  align: "center",
                  dataIndex: "hoistingTowerEle",
                  width: 60
                },
                {
                  title: <a-tooltip><template slot="title">物料提升机</template>提</a-tooltip>,
                  align: "center",
                  dataIndex: "goodsUpDown",
                  width: 60
                },
                {
                  title: "吊",
                  align: "center",
                  dataIndex: "hoistingHang",
                  width: 60
                },
                {
                  title: "超",
                  align: "center",
                  dataIndex: "hoistingOut",
                  width: 60
                },
              ]
            },
            {
              title: "脚手架",
              align: "center",
              children: [
                {
                  title: "",
                  align: "center",
                  dataIndex: "scaffold",
                  width: 60
                },
                {
                  title: "超",
                  align: "center",
                  dataIndex: "scaffoldOut",
                  width: 60
                },
              ]
            },
            {
              title: "拆除工程",
              align: "center",
              children: [
                {
                  title: "",
                  align: "center",
                  dataIndex: "splitWork",
                  width: 60
                },
                {
                  title: "超",
                  align: "center",
                  dataIndex: "splitWorkOut",
                  width: 60
                },
              ]
            },
            {
              title: "幕墙",
              align: "center",
              children: [
                {
                  title: "",
                  align: "center",
                  dataIndex: "curtainWall",
                  width: 60
                },
                {
                  title: "超",
                  align: "center",
                  dataIndex: "curtainWallOut",
                  width: 60
                },
              ]
            },
            {
              title: "钢构网架",
              align: "center",
              children: [
                {
                  title: "",
                  align: "center",
                  dataIndex: "steelFrame",
                  width: 60
                },
                {
                  title: "超",
                  align: "center",
                  dataIndex: "steelFrameOut",
                  width: 60
                },
              ]
            },
            {
              title: "人工挖孔",
              align: "center",
              children: [
                {
                  title: "",
                  align: "center",
                  dataIndex: "handDigging",
                  width: 60
                },
                {
                  title: "超",
                  align: "center",
                  dataIndex: "handDiggingOut",
                  width: 60
                },
              ]
            },
            {
              title: "暗挖工程",
              align: "center",
              children: [
                {
                  title: "",
                  align: "center",
                  dataIndex: "darkWork",
                  width: 60
                },
                {
                  title: "超",
                  align: "center",
                  dataIndex: "darkWorkOut",
                  width: 60
                },
              ]
            },
            {
              title: "新四无标",
              align: "center",
              children: [
                {
                  title: "",
                  align: "center",
                  dataIndex: "fourNew",
                  width: 60
                },
                {
                  title: "超",
                  align: "center",
                  dataIndex: "fourNewOut",
                  width: 60
                },
              ]
            },
            {
              title: "装配式安装",
              align: "center",
              children: [
                {
                  title: "",
                  align: "center",
                  dataIndex: "fitment",
                  width: 60
                },
                {
                  title: "超",
                  align: "center",
                  dataIndex: "fitmentOut",
                  width: 60
                },
              ]
            },
            {
              title: "水下作业",
              align: "center",
              dataIndex: "waterMan",
              width: 60
            },
          ],
        },
        {
          title: "重大危险因素重大危险源",
          children: [
            {
              title: "宿舍人数",
              align: "center",
              dataIndex: "roomMan",
              width: 80
            },
            {
              title: "人",
              align: "center",
              dataIndex: "otherMan",
              width: 80
            },
            {
              title: "其他",
              align: "center",
              dataIndex: "other",
              width: 80
            },
          ]
          // align: "center",
        },
        {
          title: "状态",
          align: "center",
          dataIndex: "status",
          fixed: "right",
          width: 80,
          scopedSlots: { customRender: "status" },
        },
        {
          title: "操作",
          align: "center",
          fixed: "right",
          width: 65,
          scopedSlots: { customRender: "action" },
        },
      ],
      tableData: [],
      tableLoading: false, //table加载中

      loadingText: "数据加载中...",
      cPercent: 0,
      isExporting: false,
    };
  },
  created() {
    this.getgcglbList(QUERY_OFFICE_BY_USER);
  },
  activated() {
    this.handleQuery();
  },
  filters: {
    statusName(status) {
      switch (status) {
        case 0:
          return '待确认'
        case 1:
          return '已确认'
        case 2:
          return '退回'
        case 3:
          return '已上报'
        default:
          return ''
      }
    },
  },
  computed: {
    ...mapGetters("account", ["user"]),
    canConfirm() {
      const today = moment(); // 获取当前日期
      const dayOfMonth = today.date(); // 获取当前月份的日期
      if (dayOfMonth >= 22 && dayOfMonth <= 24) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.params.pageNumber = 1
      this.pagination.current = 1
      this.tableData = []
      this.handleQuery();
    },
    batchConfirm() {
      if (this.selectedRows.length == 0) {
        this.$message.warning('请选择项目！')
      } else {
        let arr = []
        this.selectedRows.forEach(el => {
          arr.push(el.id)
        })
        const _that = this
        this.$confirm({
          title: '是否确认？',
          onOk() {
            updateSourceDangerBatchApprove({ids: arr}).then(res => {
              if (res.data.code === 0) {
                _that.$message.success(res.data.msg)
                _that.handleQuery()
              } else {
                _that.$message.error(res.data.msg)
              }
            })
          }
        })
      }
    },
    updateSelectRows(e) {
      console.log(e)
    },
    //计算序号
    itemNo(index) {
      let no = 0
      if (this.pagination.current === 1) {
        no = (this.pagination.current - 1) * this.pagination.pageSize + parseInt(index) + 1
      } else {
        no = ((this.pagination.current - 1) * this.pagination.pageSize + parseInt(index) + 1) - (this.pagination.current - 1)
      }
      return no
    },
    handleQuery(e) {
      this.tableLoading = true
      const now = moment() // 当前时间
      const targetDate = moment().date(25).hour(3).minute(0).second(0) // 设置目标日期为当月的25号03:00
      if (now.isBefore(targetDate)) {
        // console.log('当前时间早于当月25号03:00');
        this.params.year = moment(now).format('YYYY-MM').split('-')[0]
        this.params.month = moment(now).format('YYYY-MM').split('-')[1]
      } else if (now.isAfter(targetDate)) {
        // console.log('当前时间晚于当月25号03:00');
        this.params.year = moment(now).add(1,'months').format('YYYY-MM').split('-')[0]
        this.params.month = moment(now).add(1,'months').format('YYYY-MM').split('-')[1]
      }
      getWdHiddenDangerStaticList(this.params).then((res) => {
        this.tableLoading = false
        let d = res.data;
        if (d.code === 0) {
          this.tableData = d.data.list
          this.pagination.total = d.data.total + 1;
          this.total = d.data.total;
          this.getTotal()
        } else {
          this.$message.error(res.data.msg)
        }
      });
    },
    getTotal() {
      getWdHiddenDangerStaticTotal(this.params).then(res => {
        let d = res.data;
        if (d.code === 0) {
          d.data.id = new Date().getTime() + ""
          this.tableData.push(d.data)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    handleChangePage(e) {
      this.params.pageNumber = e.current;
      this.pagination.current = e.current;
      this.handleQuery();
    },
    // 获取工程管理部数据
    getgcglbList(path) {
      request(path, "get").then((res) => {
        let data = res.data.data.filter(item => item.id != "3da0f5533f87425c9a9e8a11a7923300")
        const arr = [];
        data.forEach((item) => {
          arr.push({
            name: item.master,
            value: item.id,
          });
        });
        this.gcglbList = arr;
      });
    },
    resetQuery() {
      this.monthPickerVal = null
      this.pagination.pageNumber = 1
      this.params = {
        month: '',
        gcglbId: '',
        projectName: '',
        year: '',
        pageNumber: 1,
        pageSize: 10,
      };
      this.handleQuery();
    },
    handleEdit(row) {
      console.log(row)
      this.$router.push({
        path: `/wd_hidden_danger_static_edit`,
        query: {
          id: row.id,
        },
      });
    },
    handleBack(e) {
      const _that = this
      this.$confirm({
          title: '确认退回？',
          onOk() {
            projectSourceDangerDistributionStaticBack(e.id).then(res => {
              let d = res.data;
              if (d.code === 0) {
                _that.$message.success('退回成功')
                _that.handleQuery()
              } else {
                _that.$message.error(res.data.msg)
              }
            })
          }
        })
    },
    handleConfirm(e) {
      // 分公司-确认状态
      const _that = this
        this.$confirm({
          title: '是否确认？',
          onOk() {
            updateSourceDangerApprove({ids: [e.id]}).then(res => {
              let d = res.data;
              if (d.code === 0) {
                _that.$message.success('确认成功')
                _that.handleQuery()
              } else {
                _that.$message.error(res.data.msg)
              }
            })
          }
        })
    },
    doDowload() {
      const _that = this
      this.$confirm({
        title: '导出上报后内容不可编辑，确认导出？',
        onOk() {
          let obj = _that.params;
          delete obj.pageNumber
          delete obj.pageSize
          _that.loadingText = "正在导出EXCEL文件，请稍候 ····";
          _that.isExporting = true;
          _that.cPercent = 0;
          let fileName = "危险性较大分部分项工程情况表.xlsx";
          _that.exportFunNomal2(
            wdHiddenDangerStaticExportUrl,
            obj,
            fileName,
            "application/vnd.ms-excel"
          );
        }
      })
    },
  },
};
</script>

<style lang="less" scoped>
@import "@/theme/common.less";
.query-form {
  display: flex;
  // justify-content: space-between;
}
.ant-table td { white-space: nowrap; }
</style>
