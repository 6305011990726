/**
 * 将数字转为千分位格式的字符串
 * @param {number} num - 要转换的数字
 * @returns {string} 转换后的字符串
 */
export function addCommas(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/**
 * 将千分位格式的字符串恢复为普通数字格式
 * @param {string} str - 要恢复的字符串
 * @returns {string} 恢复后的字符串
 */
export function removeCommas(str) {
  return str.replace(/,/g, "");
}

/**
 * 格式化金额为指定小数位数的字符串（四舍五入）
 * @param {number} amount - 要格式化的金额
 * @param {number} [decimal=2] - 小数位数，默认为2
 * @returns {string} 格式化后的字符串
 */
export function formatCurrency(amount, decimal = 2) {
  return parseFloat(amount).toFixed(decimal);
}

/**
 * 将金额转为大写人民币形式
 * @param {number} n - 要转换的金额
 * @returns {string} 转换后的大写人民币形式
 */
export function digitUppercase(n) {
  const fraction = ['角', '分'];
  const digit = [
      '零', '壹', '贰', '叁', '肆',
      '伍', '陆', '柒', '捌', '玖'
  ];
  const unit = [
      ['元', '万', '亿'],
      ['', '拾', '佰', '仟']
  ];
  let num = Math.abs(n);
  let s = '';
  for (let i = 0; i < fraction.length; i++) {
      s += (digit[Math.floor(num * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '');
  }
  s = s || '整';
  num = Math.floor(num);
  for (let i = 0; i < unit[0].length && num > 0; i++) {
      let p = '';
      for (let j = 0; j < unit[1].length && num > 0; j++) {
          p = digit[num % 10] + unit[1][j] + p;
          num = Math.floor(num / 10);
      }
      s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
  }
  return n < 0 ? `负${s}` : s;
}
