<template>
  <a-modal v-model="myVisible" centered :destroyOnClose="true" :width="1100" :footer="null" :maskClosable="false"
    :keyboard="false" @cancel="cancel">
    <template slot="title">
      <h3 class="flex a-center j-center font-bold">制作工资单</h3>
    </template>
    <div>
      <h3 class="flex font-bold ml-1"> {{ planDetail.projectName }}</h3>
      <div class="flex a-center j-between m-1">
        <a-steps :current="currentStep" size="small" style="width: 60%;">
          <a-step title="选择班组" />
          <a-step title="上传考勤表" />
        </a-steps>
        <div class="flex a-center j-center">
          <span class="mr-2">班组名称：</span>
          <a-auto-complete
            v-model="teamNameInput"
            :data-source="inputDataSource"
            style="width: 200px"
            placeholder="请输入班组名称"
            @select="onSelect"
            @change="onChange"
          >
          <template slot="dataSource">
            <a-select-option v-for="items in inputDataSource" :key="items.teamName" :title="items.teamName">{{items.teamName}}</a-select-option>
          </template>
          </a-auto-complete>
        </div>
        <!-- <a-button type="link">导入其他班组考勤</a-button> -->
      </div>
      <template>
        <div class="content">
          <template v-if="currentStep == 0">
            <standard-table class="" :columns="columns0" :dataSource="dataSource0" rowKey="id" :scroll="{ x: 900, y: 350 }"
              :pagination="pagination" @change="onPageChange" :loading="tableLoading0" :isAlert="false" :bordered="true"
              :selectedRows.sync="selectedRows" @update:selectedRows="updateSelectRows($event)" @selectedRowChange="selectedRowChange">
              <template slot="index" slot-scope="{ index }">
                <span> {{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }} </span>
              </template>
            </standard-table>
          </template>
          <template v-if="currentStep == 1">
            <standard-table class="" :columns="columns1" :dataSource="dataSource1" rowKey="id" :scroll="{ x: 900, y: 350 }"
              :pagination="pagination" @change="onPageChange" :loading="tableLoading1" :isAlert="false"
              :bordered="true">
              <template slot="index" slot-scope="{ index }">
                <span> {{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }} </span>
              </template>
              <template slot="attSource" slot-scope="{ record }">
                <div v-if="!record.filePath">
                  <a-upload :file-list="record.filePath" :customRequest="resourceUpload" :data="record">
                    <a-button type="primary" ghost :loading="record.uploadLoading">上传excel</a-button>
                  </a-upload>
                </div>
                <div v-else class="flex a-center j-center">
                  <span>{{ record.teamName }}.xlsx</span>
                  <a-icon class="ml-3" @click="delAttSource(record)" type="close" />
                </div>

              </template>
              <template slot="action" slot-scope="{ record }">
                <div>
                  <a style="color: #ff5c77;" @click="del(record)">删除</a>
                </div>
              </template>
            </standard-table>
          </template>
        </div>
      </template>
      <div class="btn-box">
        <a-space :size="20" v-if="currentStep == 0">
          <a-button @click="cancel">取消</a-button>
          <a-button type="primary" ghost @click="getAttendModal">根据考勤数据制作工资表</a-button>
          <a-button @click="onImportAttList(false, 1)" type="primary">导入含应发金额的考勤表</a-button>
        </a-space>
        <a-space :size="20" v-if="currentStep == 1">
          <a-button @click="onBackStep">返回</a-button>
          <a-button @click="onConfirm(1)" type="primary">确定</a-button>
        </a-space>
      </div>
    </div>
    <a-modal v-model="importAttendModal" centered :destroyOnClose="true" :footer="null" :maskClosable="false"
      :keyboard="false" @cancel="importAttendModal = false">
      <div>
        <h3 class="text-center">请选择导入考勤表来源</h3>
        <a-divider/>
        <div class="flex a-center j-center mt-5 mb-5"> 
          <a-button type="primary" ghost class="mr-5" @click="getSystemAttendData(2)" :loading="btnLoading">从平台获取</a-button> 
          <a-button type="primary" ghost @click="getSystemAttendData(3)" :loading="btnLoading">从建委获取</a-button>
        </div>
      </div>
    </a-modal>
  </a-modal>
</template>

<script>
import StandardTable from "@/components/table/StandardTable";
import { saveBatch, getSalaryTeam, delSalaryTeam, getSystemAttendData, getJwWorkAttend, chooseType } from '@/services/payroll'
import { request } from '@/utils/request'
import { IMPORT_TEAM_KQ_EXCEL } from '@/services/api'
import moment from 'moment';
import formIndex from "@/pages/components/form/index";
import { getTeamsByOfficeAndProject } from "@/services/participationUnit";
const columns0 = [
  {
    title: "序号",
    width: 100,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
  },
  {
    title: "班组名称",
    dataIndex: "teamName",
    align: "center",
    scopedSlots: { customRender: "teamName" },
  },
];
const columns1 = [
  {
    title: "序号",
    width: 80,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
  },
  {
    title: "班组名称",
    dataIndex: "teamName",
    align: "center",
    scopedSlots: { customRender: "teamName" },
  },
  {
    title: "工资月份",
    dataIndex: "doMonth",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "doMonth" },
  },
  {
    title: "考勤表来源",
    dataIndex: "attSource",
    align: "center",
    scopedSlots: { customRender: "attSource" },
  },
  {
    title: "应发总额(不含税)",
    dataIndex: "payable",
    align: "center",
    scopedSlots: { customRender: "payable" },
  },
  // {
  //   title: "附件",
  //   dataIndex: "attachment",
  //   align: "center",
  //   width: 80,
  //   scopedSlots: { customRender: "attachment" },
  // },
  {
    title: "操作",
    align: "center",
    width: 80,
    // fixed: "right",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  components: {
    StandardTable,
    // formIndex
  },
  props: {
    planDetail: {
      type: Object,
      require: true,
      default: () => { }
    },
    visible: {
      type: Boolean,
      default: false,
      require: true
    },
  },
  data() {
    return {
      btnLoading: false, 
      importAttendModal: false,
      currentStep: 0,
      myVisible: false,
      selectedRows: [],
      columns0: columns0,
      columns1: columns1,
      orgDataSource0: [],
      dataSource0: [],
      dataSource1: [],
      tableLoading0: false, //table加载中
      tableLoading1: false, //table加载中
      pagination: {
        //分页数据
        current: 1,
        pageSize: 10,
        total: 0,
      },
      showFailBtn: false,
      params: {
        pageNumber: 1,
        pageSize: 10,
      },
      selectTeamList: [],
      teamNameInput: '',
      inputDataSource: []
    };
  },
  mounted() {

  },
  watch: {
    visible: {
      handler(val) {
        this.myVisible = val
        if (val) {
          this.getTeamsByOfficeAndProject()
        }
      }
    },
    selectedRows: {
      handler(val) {
        console.log(val, 'selectedRows')
      }
    },
  },
  methods: {
    onSelect(value) {
      this.dataSource0 = this.orgDataSource0.filter(el => el.teamName.indexOf(value) != -1)
    },
    onChange(value) {
      this.inputDataSource = []
      if (value) {
        this.inputDataSource = this.orgDataSource0.filter(el => el.teamName.indexOf(value) != -1)
      } else {
        this.dataSource0 = this.orgDataSource0
      }
    },
    // 上传资源
    resourceUpload(value) {
      console.log(value)
      this.$set(value.data, 'uploadLoading', true)
      value.data.uploadLoad = true
      const formData = new FormData();
      formData.append('file', value.file);
      formData.append('relatioId', value.data.id);
      // // 图片上传
      this.attachmentUpload(formData, value)
    },
    /*
        * 图片请求接口
        * type 上传组件的类型
        */
    attachmentUpload(formData, value) {
      // ATTACHMENT_UPLOAD图片上传接口
      request(IMPORT_TEAM_KQ_EXCEL, "post", formData).then(res => {
        console.log(res)
        this.$set(value.data, 'uploadLoading', false)
        if (res.data.code === 0) {
          // const fileObj = {
          //   uid: value.data.id, //图片id
          //   name: value.data.teamName, //图片名称
          //   url: res.data.data, //图片地址
          //   key: value.data.id, //获取对应的上传key名 ，后续移除图片用
          // }
          this.dataSource1.forEach(item => {
            if (item.id === value.data.id) {
              item.fileName = value.file.name
              item.filePath = res.data.data.filePath
              item.payable = res.data.data.payable
            }
          })
        } else {
          this.$message.error(res.data.message);
        }
      })
    },
    updateSelectRows(e) {
      console.log(e)
      this.dataSource1 = e
    },
    getTeamsByOfficeAndProject() {
      this.tableLoading0 = true //table加载中
      getTeamsByOfficeAndProject({ projectId: this.planDetail.projectId, officeId: this.planDetail.officeId }).then(res => {
        if (res.data.code == 0) {
          this.dataSource0 = res.data.data
          this.orgDataSource0 = res.data.data //保存一个原始的数组，用于筛选
          if (this.selectTeamList.length > 0) {
            this.dataSource0.forEach(el => {
              this.selectTeamList.forEach(item => {
                if (el.id == item.teamId) {
                  this.selectedRows.push(el)
                }
              })
            })
          }
        } else {
          this.$message.error(res.data.msg)
        }
        this.tableLoading0 = false;
      })
    },
    //表格 - 分页查询
    onPageChange(e) {
      this.pagination.pageSize = e.pageSize;
      this.pagination.current = e.current;
    },
    // reset() {
    //   this.dataSource = []
    //   this.tabPosition = 1
    //   this.cancelBtnText = '返回修改'
    //   this.confirmBtnText = '下一步'
    //   this.showCancelBtn = true
    //   this.showConfirmBtn = true
    //   this.showOverBtn = false
    //   this.primary = false
    //   this.showFailBtn = false
    //   this.HKTask = false
    // },
    cancel() {
      this.selectedRows = []
      this.currentStep = 0
      this.$emit('cancel', false)
    },
    // over() {
    //   this.$emit('cancel', false);
    //   this.reset()
    // },
    // 查看编辑
    // viewEditing(e) {
    //   this.$router.push({
    //     name: '工人工资汇总',
    //     query: {
    //       searchMonth: e.summaryMonth,
    //       projectId: e.projectId,
    //       fullName: e.projectName
    //     }
    //   })
    //   this.over()
    // },
    onImportAttList(importAttend = false, getType) {
      this.btnLoading = true
      if (this.selectedRows.length == 0) {
        this.$message.error('请选择班组！');
        return
      }
      let arr = []
      this.selectedRows.forEach(el => {
        arr.push({
          planId: this.planDetail.id,
          projectId: this.planDetail.projectId,
          officeId: this.planDetail.officeId,
          teamId: el.id,
          teamName: el.teamName,
        })
      });
      saveBatch(arr).then(res => {
        if (res.data.code == 0) {
          if (importAttend) {
            let path = null
            getType === 2 ? path = getSystemAttendData : path = getJwWorkAttend
            path({planId: this.planDetail.id}).then(res => {
              this.btnLoading = false
              if (res.data.code == 0) {
                this.importAttendModal = false
                this.onConfirm(2)
              } else {
                this.$message.error(res.data.msg)
              }
            })
          } else {
            this.currentStep = 1
            this.getSalaryTeam()
          }
          let params = {
            id: this.planDetail.id,
            type: getType
          }
          chooseType(params).then(item => {
            if (item.data.code != 0) {
              this.$message.error(item.data.msg)
            }
          })
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    getSalaryTeam() {
      this.tableLoading1 = true
      getSalaryTeam({
        planId: this.planDetail.id,
      }).then(res => {
        this.btnLoading = false
        this.tableLoading1 = false
        if (res.data.code == 0) {
          this.dataSource1 = res.data.data.list
          this.dataSource1.forEach(el => {
            el.doMonth = this.planDetail.doMonth
          })
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    del(record) {
      const _that = this
      this.$confirm({
        title: '确认删除？',
        onOk() {
          delSalaryTeam({ id: record.id }).then(res => {
            if (res.data.code == 0) {
              _that.getSalaryTeam()
              _that.$message.success(res.data.msg)
            } else {
              _that.$message.error(res.data.msg)
            }
          })
        }
      })
    },
    onBackStep() {
      this.currentStep = 0
    },
    /**
     * ways 1为路径1，2为路径2
     */
    onConfirm(ways) {
      this.currentStep = 0
      this.selectedRows = []
      this.$emit('confirm', ways)
    },
    delAttSource(record) {
      this.dataSource1.forEach(el => {
        if (el.id == record.id) {
          el.filePath = ''
        }
      })
    },
    getSystemAttendData(e) {
      this.onImportAttList(true, e)
    },
    getAttendModal() {
      if (this.selectedRows.length == 0) {
        this.$message.error('请选择班组！');
      } else {
        this.importAttendModal = true
      }
    },
    //供外部调用的方法
    setSelectedRows(e) {
      if (e && e.length > 0) {
        this.selectTeamList = e
      }
    },
    selectedRowChange(selectedRowKeys) {
      this.selectedRows = []
      selectedRowKeys.forEach(el => {
        this.orgDataSource0.forEach(item => {
          if (el == item.id) {
            this.selectedRows.push(item)
          }
        })
      })
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  padding: 0 10px 0 10px;
}

.content {
  padding: 0 10px;

  .sub-title {
    padding-top: 10px;
  }

  .progress {
    padding-top: 5px;
  }
}

.btn-box {
  margin-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row-reverse;
}

.yq-tips {
  margin-top: 10px;
}

.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  z-index: 1;
  color: #4a86e8;
  background: #fff;
  border-color: #4a86e8;
}

.ant-radio-button-wrapper-disabled {
  background: #fff;
  color: rgba(0, 0, 0, 0.65);
  cursor: default;
}

.err-box {
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;
  padding: 10px 20px;
}
</style>