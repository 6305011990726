<template>
  <div>
    <div class="constructiontTable_index">
      <!-- 表单封装 
                projectObj：下拉弹出框第一项对象
                Overview：项目名称
                baiduMapBoolen：控制地图显示
                topSubmit：控制上部分按钮显示
                bottomSubmit：控制下部分按钮显示
                handleSubmit：表单提交
                handleChange：输入出发
                handlSelect：输入下拉选择触发
            -->
      <form-index
        ref="formindex"
        :projectList="projectList"
        :formData="formData"
        Overview="访客登记"
        :baiduMapBoolen="false"
        @handleSubmit="handleSubmit"
        @handleChange="handleChange"
        @handlSelect="handlSelect"
        :flexSubmit="true"
      >
        <!-- 下部分按钮插槽 -->
        <template #flexSubmit>
          <a-button type="primary" html-type="submit"> 查询 </a-button>
          <a-button @click="close"> 重置 </a-button>
        </template>
      </form-index>
      <div>
        <a-space class="operator">
          <a-button>下发</a-button>
        </a-space>
      </div>
      <!-- 表格封装 -->
      <standard-table
        class=""
        :columns="columns"
        :dataSource="dataSource"
        rowKey="id"
        :scroll="{ x: 1300 }"
        :pagination="pagination"
        :loading="tableLoading"
        @change="onPageChange"
        :isAlert="false"
        :bordered="true"
      >
        <template slot="index" slot-scope="{ index }">
          <span>
            {{
              (pagination.current - 1) * pagination.pageSize +
              parseInt(index) +
              1
            }}
          </span>
        </template>
        <template slot="projectName" slot-scope="{ record }">
          <!-- :style="record.pjsPath == null || record.pjsPath == ''? '' : 'color:red'" -->
          <div :style="textStyle(record)">
            {{ record.projectName }}
          </div>
        </template>
        
        <div slot="action" slot-scope="{ record }">
          <div class="sTable">
            <a-icon @click="viewEditing(record)" type="edit" theme="twoTone" />
            <a-icon
              @click="tobeFinished(record)"
              type="check-circle"
              theme="twoTone"
              two-tone-color="#52c41a"
            />
            <a-icon
              @click="recorDelete(record)"
              type="delete"
              theme="twoTone"
              two-tone-color="#eb2f96"
            />
          </div>
        </div>
      </standard-table>
      <Export-loading
        :cPercent="cPercent"
        :isExporting="isExporting"
        :loadingText="loadingText"
      ></Export-loading>
    </div>
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import {
  aqxjprojectsgetlist,
  aqxjprojectsdetail,
  aqxjProjectsupdate,
  aqxjprojectsdel,
  getLwProjectList,
} from "@/services/projectManagement";
import { request } from "@/utils/request";
import { exportProgress } from "@/pages/aqjyzl/export"; // 导出方法
import ExportLoading from "@/components/ExportLoading/ExportLoading"; // progress组件
import {
  PROJECT_OFFICE_CHILD,
  QUERY_OFFICE_BY_USER,
  AQXJ_PROJECTS_EXPORT_ZAIJIAN,
} from "@/services/api";
import { formatNumber } from "@/utils/whole";
import { projectMsgGetList,getLwDetail } from "@/services/projectManagement";
import { mapGetters } from "vuex";
import { useRender } from '@/hooks/useRender.js'
const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
    // fixed: "left",
  },
  {
    title: "姓名",
    dataIndex: "createName",
    key: "createName",
    // ellipsis: true,
    // scopedSlots: { customRender: "projectName" },
    // fixed: "left",
  },
  {
    title: "头像",
    dataIndex: "headPath",
    align: "center",
    key: "headPath",
    scopedSlots: { customRender: "headPath" },
  },
  {
    title: "来访单位",
    dataIndex: "officeName",
    align: "center",
    scopedSlots: { customRender: "officeName" },
  },
  {
    title: "访问事由",
    align: "center",
    dataIndex: "subject",
    scopedSlots: { customRender: "subject" },
  },
  // {
  //   title: "所属单位",
  //   dataIndex: "officeId",
  //   align: "center",
  //   width: 110,
  //   scopedSlots: { customRender: "officeId" },
  // },

  {
    title: "被访部门/人员",
    dataIndex: "bfbm",
    align: "center",
    key: "bfbm",
  },
  {
    title: "车牌号",
    align: "center",
    dataIndex: "carId",
    key: "carId",
    scopedSlots: { customRender: "carId" },
  },
  {
    title: "携带物品",
    align: "center",
    dataIndex: "xdwp",
    key: "xdwp",
    scopedSlots: { customRender: "xdwp" },
  },
  {
    title: "来访时间",
    align: "center",
    dataIndex: "visitTime",
    key: "visitTime",
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "门禁授权起始时间",
    align: "center",
    dataIndex: "startTime",
    key: "startTime",
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "门禁授权起始时间",
    align: "center",
    dataIndex: "endTime",
    key: "endTime",
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "操作",
    align: "center",
    width: 100,
    // fixed: "right",
    scopedSlots: { customRender: "action" },
  },
];

const formData = [
  {
    label: "选择月份",
    placeholder: "请选择月份",
    decorator: [
      "officeId",
      { rules: [{ required: false, message: "请选择请选择月份" }] },
    ],
    type: "monthPicker",
    key: "officeId",
    col: 8,
    labelCol: 3,
    wrapperCol: 12,
    format: "yyyy-MM",
    styles: "width: 262px;",
  },
  {
    label: "访客姓名",
    placeholder: "请输入访客姓名",
    decorator: [
      "projectName",
      { rules: [{ required: false, message: "请输入访客姓名" }] },
    ],
    type: "inputGroup",
    key: "projectName",
    dataSource: [],
    col: 8,
    labelCol: 3,
    wrapperCol: 12,
  },
  {
    label: "身份证号",
    placeholder: "请输入身份证号",
    decorator: [
      "idCard",
      { rules: [{ required: false, message: "请输入身份证号" }] },
    ],
    type: "input",
    key: "idCard",
    col: 8,
    labelCol: 3,
    wrapperCol: 12,
  },
  {
    label: "来访时间",
    placeholder: "请选择来访时间",
    decorator: [
      "ptype",
      { rules: [{ required: false, message: "请选择来访时间" }] },
    ],
    type: "rangePicker",
    key: "ptype",
    col: 8,
    labelCol: 3,
    wrapperCol: 12,
    format: "yyyy-MM-DD",
  },
];
export default {
  mixins: [exportProgress], //导出方法注入
  components: {
    formIndex, //form表单封装
    StandardTable, //表格封装
    ExportLoading, //导出数据加载封装
  },
  data() {
    return {
      columns: columns, //表格数据
      dataSource: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      tableLoading: false,
      formData: formData,
      prams: {
        pageNumber: 1,
        pageSize: 10,
      },
      projectList: [], //获取当前项目数据
      loadingText: "数据加载中...",
      cPercent: 0,
      isExporting: false,
    };
  },
  computed: {
    textStyle() {
      return function (val) {
        if (val.statusw == 4 && (val.pjsPath != null || val.pjsPath != "")) {
          return "color:red";
        }
      };
    },
    ...mapGetters("account", ["user"]),
  },
  watch: {
    loadingText: {
      handler(newName, oldName) {
        this.loadingText = newName;
      },
      immediate: true,
    },
  },
  created() {
    // this.projectId = this.user.projectId;
    // this.prams.projectId = this.user.projectId
    // this.prams.officeId = this.user.officeId
    console.log(this.user,'this.user')
    if (this.$route.query.pageNumber) {
      this.prams.pageNumber = this.$route.query.pageNumber;
    }
    // this.getgcglbList(PROJECT_OFFICE_CHILD + "/OFFICE_CHILD")
    this.getgcglbList(QUERY_OFFICE_BY_USER);
    this.aqxjlist(this.prams);
  },
  methods: {
    // 封装弹窗确定按钮
    confirm(title, content) {
      return new Promise((resolve, reject) => {
        this.$confirm({
          title: title,
          content: content,
          onOk() {
            resolve();
          },
        });
      });
    },
    // 删除接口
    recorDelete(val) {
      this.confirm("删除", "是否删除").then(() => {
        aqxjprojectsdel(val.id).then((res) => {
          if (res.data.code === 0) {
            this.$message.success("删除成功", 10);
            this.aqxjlist(this.prams);
          } else {
            this.$message.error(res.data.msg, 10);
          }
        });
      });
    },
    // 更新接口
    update(e) {
      aqxjProjectsupdate(e).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("成功", 10);
        } else {
          this.$message.error(res.data.msg, 10);
        }
      });
    },
    /**
     * 导出excel
     */
    doDowload() {
      let obj = this.$refs["formindex"].getFieldValue();
      obj.queryType = this.prams.queryType;
      this.loadingText = "正在导出EXCEL文件，请稍候 ····";
      this.isExporting = true;
      this.cPercent = 0;
      let fileName = "在建工程一览表.xlsx";
      this.exportFunNomal2(
        AQXJ_PROJECTS_EXPORT_ZAIJIAN,
        obj,
        fileName,
        "application/vnd.ms-excel"
      );
    },
    // 查看编辑
    viewEditing(e) {
      let params = {
        projectId: e.projectId
      }
      console.log(e,'查看编辑数据====================')
      // getLwDetail(params).then((res) => {
      //     this.$router.push({
      //       path: "../project_construction_management/details_lw_index",
      //       query: {
      //         data: res.data.data,
      //         type: "table",
      //         pageNumber: this.prams.pageNumber,
      //       },
      //     });
      // });
    },
    handleChange(e) {
      this.projectArr = [];
      this.getZdata(projectMsgGetList, e, "fullName");
      // 获取当前匹配key，赋值相对于数组
      this.formData.forEach((item) => {
        if (e.item.key === item.key) {
          item.dataSource = this.projectArr;
        }
      });
    },
    // 接口封装
    /**
     * msg 接收接口名称
     * e 数据
     * name 参数名
     * */
    getZdata(msg, e, name) {
      let data = {
        [name]: e.value,
      };
      msg(data).then((res) => {
        if (res.status == 200) {
          const data = res.data.data.list ? res.data.data.list : res.data.data
          data.forEach((item) => {
            this.projectArr.push({
              title: item[name],
              id: item.id,
            });
          });
          this.projectList = data;
        }
      });
    },
    // 新建项目
    createItem() {
      this.$router.push({ name:"新增项目"});
    },
    // 特殊数据处理
    handlSelect({ val, keys }) {
      this.projectList.forEach((item) => {
        if (keys == "projectName" && item.id == val) {
          this.$refs["formindex"].setFieldsValue(
            item.fullName,
            {},
            "projectName"
          );
        }
      });
    },
    aqxjlist(prams) {
      // this.tableLoading = true;
      // getLwProjectList(prams).then((res) => {
      //   if (res.data.code === 0) {
      //     this.dataSource = res.data.data;
      //     this.pagination.total = res.data.data.total;
      //   }
      //   this.tableLoading = false;
      // });
    },
    // 对下拉框做数据处理
    formDataFn(key, data) {
      console.log(data);
      let arr = [];
      data.forEach((item) => {
        arr.push({ name: item.master || item.name, value: item.id });
      });
      this.formData.forEach((item) => {
        if (item.key == key) {
          item.selectlist = arr;
        }
      });
    },
    // 获取工程管理部数据
    getgcglbList(path) {
      request(path, "get").then((res) => {
        let data = res.data.data.filter(
          (item) => item.id != "3da0f5533f87425c9a9e8a11a7923300"
        );
        this.formDataFn("officeId", data);
      });
    },
    // 提交表单数据
    handleSubmit(e) {
      let obj = Object.assign({}, e, this.prams);
      this.aqxjlist(obj);
    },
    // 重置
    close() {
      this.prams = {
        pageNumber: 1,
        pageSize: 10,
      };
      this.aqxjlist(this.prams);
      this.$refs["formindex"].setFieldsValue("", {}, "projectName");
      this.$refs["formindex"].resetFields();
    },
    //表格 - 分页查询
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      this.prams.pageNumber = e.current;
      this.pagination.current = e.current;
      this.aqxjlist(Object.assign({}, this.prams, obj));
    },
  },
};
</script>

<style lang="less" scoped>
.constructiontTable_index {
  padding: 24px;
  background: #fff;
  border-radius: 6px;
  margin-bottom: 10px;
  .operator {
    margin-right: 8px;

    .ant-btn {
      color: #307dfa;
    }
  }
  .c_i_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 25px;

    .c_i_h_center {
      padding: 16px;
      border-radius: 4px;
      width: 180px;
      height: 130px;
      margin-right: 50px;

      div:nth-child(1) {
        font-size: 16px;
      }

      div:nth-child(2) {
        margin-top: 26px;
        font-size: 24px;
      }
    }
  }
}

.sTable {
  display: flex;
  justify-content: space-between;
  align-items: center;

  // div {
  //     width: 100%;
  //     color: blue;
  //     text-align: center;
  //     cursor: pointer;
  // }
}
</style>