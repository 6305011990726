<template>
    <div>
        <a-card :bordered="false">
            <div id="components-form-demo-advanced-search">
                <a-form  :form="searchForm" >
                    <a-row :gutter="24">
                        <!-- <a-col :span="5" >
                            <a-form-item :labelCol="{span: 5}"
              :wrapperCol="{span: 18, offset: 1}" label="模板id">
                                <a-input v-model="searchForm.essTemplateId" placeholder="请输入模板id" />
                            </a-form-item>
                        </a-col> -->
                        <!-- <a-col :span="4" >
                            <a-form-item :label="`模板类型`" :labelCol="{span: 5}"
              :wrapperCol="{span: 18, offset: 1}">
                                <a-select
                                        v-model="searchForm.templateType"
                                >
                                    <a-select-option key="" value="">
                                        全部
                                    </a-select-option>
                                    <a-select-option key="0" value="0">
                                        其他
                                    </a-select-option>
                                    <a-select-option key="1" value="1">
                                        入场合同
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col> -->
                        <a-col :span="6" >
                            <a-form-item :labelCol="{span: 5}"
                                :wrapperCol="{span: 16, offset: 1}" label="姓名">
                                <a-input v-model="searchForm.userName" placeholder="请输入姓名" />
                            </a-form-item>
                        </a-col>

                        <a-col :span="6" >
                            <a-form-item label="生成时间" :labelCol="{span: 5}" :wrapperCol="{span: 16, offset: 1}">
                                <!-- <a-date-picker  :format="monthFormat" v-model="searchForm.enterDateStr"  :allowClear="false"/> -->
                                <a-range-picker @change="onTimeChange" :format="monthFormat" v-model="searchForm.enterDateStr" :allowClear="true"/>
                            </a-form-item>
                        </a-col>
                        <a-col :span="6" >
                            <a-form-item :labelCol="{span: 5}"
                                :wrapperCol="{span: 16, offset: 1}" label="名册编号">
                                <a-input v-model="searchForm.codes" placeholder="名册编号" />
                            </a-form-item>
                        </a-col>

                        <a-col :span="6" >
                            <a-form-item :label="`状态`" :labelCol="{span: 5}"
                            :wrapperCol="{span: 16, offset: 1}">
                                <a-select
                                        v-model="searchForm.status"
                                >
                                    <a-select-option key="" value="">
                                        全部
                                    </a-select-option>
                                    <a-select-option key="0" value="0">
                                        待工人签署
                                    </a-select-option>
                                    <a-select-option key="1" value="1">
                                        已签署
                                    </a-select-option>
                                    <a-select-option key="2" value="2">
                                        已驳回
                                    </a-select-option>
                                    <a-select-option key="3" value="3">
                                        待公司签署
                                    </a-select-option>
                                    <a-select-option key="4" value="4">
                                        已线下签署
                                    </a-select-option>
                                    <a-select-option key="99" value="99">
                                        已作废
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>


                    </a-row>
                    <a-row :gutter="24">
                        <a-col v-if="user.loginType == 1" :span="6" >
                            <a-form-item :labelCol="{span: 5}"
                                :wrapperCol="{span: 16, offset: 1}" label="项目名称">
                                <!-- <a-input v-model="searchForm.contractName" placeholder="请输入项目名称" /> -->
                                <a-auto-complete :data-source="inputDataSource" v-model="searchForm.projectId"
                                    placeholder="请输入项目名称" @search="handleInputSearch($event)" allowClear
                                    @select="handlInputSelect($event)">
                                    <template slot="dataSource">
                                        <a-select-option v-for="items in inputDataSource" :key="items.id + ''">{{ items.title }}</a-select-option>
                                    </template>
                                </a-auto-complete>
                            </a-form-item>
                        </a-col>
                        <a-col :span="6" >
                            <a-form-item label="所属班组" :labelCol="{span: 5}" :wrapperCol="{span: 16, offset: 1}">
                                <a-select
                                    v-model="searchForm.teamId"
                                    show-search
                                    placeholder="请输入或选择班组"
                                    option-filter-prop="children"
                                    :filter-option="filterOption"
                                    @focus="handleFocus"
                                    @blur="handleBlur"
                                    @change="handleChange"
                                >
                                    <a-select-option :key="item.id" v-for="item in teamDatas" :value="item.id">
                                    {{item.teamName}}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :span="6" :style="{ textAlign: 'left', marginTop: '4px'}">
                            <a-space>
                                <a-button type="primary" icon="search"  :loading="loading" @click="handleSearch">
                                    查询
                                </a-button>
                                <a-button  v-if="signOperate" type="danger" @click="doSealOffice" >
                                    公司签章
                                </a-button>
                            <a-space class="operator">
                                <a-button @click="doReset()">
                                    重置
                                </a-button>
                                <a-button v-if="rejectOperate" @click="doRejectContract(true)">批量驳回</a-button>
                                <a-button v-if="canOperate" @click="setEssProSure(true)">批量信息已修正</a-button>
                                <a-button icon="download"  @click="() => downloadBatch(1)">批量下载合同</a-button>
                                <a-button icon="download" v-if="isSclw" @click="() => downloadBatch(2)">批量下载三级安全教育</a-button>
                                <a-button icon="download" v-if="!isSclw&&!signOperate" @click="() => exportStaffHmc()">导出班组花名册</a-button>
                                <a-button icon="download" v-if="rejectOperate || signOperate" @click="() => exportStaffAllHmc()">导出人员花名册</a-button>
                            </a-space>

                        </a-space>
                        </a-col>
                    </a-row>

                </a-form>
            </div>
            <!-- <div>
                <a-button @click="() => downloadBatch(1)">批量下载合同</a-button>
                <a-button v-if="isSclw" style="margin-left: 3px;" @click="() => downloadBatch(2)">批量下载三级安全教育</a-button>
                <a-button style="margin-left: 3px;" v-if="!isSclw" @click="() => exportStaffHmc()">导出花名册</a-button>
            </div> -->
            <!-- <a-table
                    :columns="columns"
                    :data-source="data"
                    :pagination="pagination"
                    @change="onPageChange"
                    :loading="tableLoading"
            >
                <template slot="action" slot-scope="text, record">
                    <span >
                      <a @click="() => showRecord(record.id)">下载合同</a>
                    </span>
                </template>

            </a-table> -->
            <standard-table
                :columns="columns"
                :dataSource="data"
                :selectedRows.sync="selectedRows"
                :pagination="pagination"
                :loading="tableLoading"
                bordered
                rowKey="id"
                @clear="onClear"
                @change="onPageChange"
                @update:selectedRows="updateSelectRows($event)"
                >
                <template slot="projectName" slot-scope="{ text }">
                    <a-tooltip placement="topLeft">
                        <template slot="title"
                        ><span>{{ text }}</span></template
                        >
                        <span class="ellipsis-2">{{
                        text
                        }}</span>
                    </a-tooltip>
                </template>
                <template slot="paperName" slot-scope="{ record }">
                    <a @click="() => viewOpenUrl(record)">{{record.contractName}}</a>
                </template>
                <template slot="riskNames" slot-scope="{ text, record }">
                    <div>{{text}}</div>
                    <template v-if="record.riskNames && record.riskNames.length > 0">
                        <div style="color: #ff5c77" v-for="(item,index) in record.riskNames" :key="index">({{item}})</div> 
                    </template>
                </template>
                <template slot="action" slot-scope="{record}">
                    <span>
                      <!-- <a @click="() => showRecord(record)">下载合同</a> -->
                      <!-- 穗建合同查看权限 -->
                      <a @click="() => viewOpenUrl(record)" v-if="(record.officeId != '1f6bd3af5c434991a44fc50fcb424f38' || user.loginType == 1)">查看</a>
                      <a-divider v-if="canOperate && record.status == 2" type="vertical" />
                      <a v-if="canOperate && record.status == 2 && !sureId.includes(record.id)" @click="setEssProSure(false, record)">信息已修正</a>
                      <a-spin v-else-if="canOperate && record.status == 2" :spinning="sureId.includes(record.id)">正在重新发起电子签</a-spin>
                      <a-divider v-if="record.status == 0 && offSignOperate" type="vertical" />
                      <a v-if="record.status == 0 && offSignOperate" style="color: red" @click="showEssOffSign(record)">线下签署</a>
                    </span>
                </template>
            </standard-table>
        </a-card>
        <Export-loading :cPercent="cPercent" :isExporting="isExporting" :loadingText="loadingText"></Export-loading>
        <a-modal :visible="visible" :width="1350" @cancel="closeView" :footer="false">
            <template slot="title">
                <div class="flex j-between a-center">
                    <span style="flex: 0 0 auto;">合同预览</span>
                    <div class="flex-1 text-center">
                        <a-space>
                            <a-button v-if="signOperate && essContract.status == 3" type="danger" @click="doSignOffice">
                            签章
                            </a-button>
                            <a-button v-if="rejectOperate && (essContract.status == 3 || essContract.status == 0)" type="primary" @click="doRejectContract(false)">
                            驳回
                            </a-button>
                            <a-button v-if="rejectOperate && essContract.status == 0" type="danger" @click="offValiDialog = true">
                            作废
                            </a-button>
                        </a-space>
                    </div>
                </div>
            </template>
            <div class="flex">
                <div id="watermarkContainer" :style="{width: (essDoLog.length > 0 ? '75%' : '100%' ), position: 'relative'}">
                    <iframe :src="essContract.fddViewUrl" class="fddPdf" style="width: 100%"></iframe>
                </div>
                <div style="width: 25%;margin-top: 54px" v-if="essDoLog.length > 0">
                    <div class="ml-2 pb-2 font-18 font-bold text-center">操作记录</div>
                    <a-timeline class="ml-2">
                        <a-timeline-item v-for="(item,index) in essDoLog" :key="index">
                            <div class="black"><span class="black-three">发起人: </span>{{item.loginName ? item.loginName : item.loginAcc}}</div>
                            <div class="black" style="margin-top: 5px"><span class="black-three">时间: </span> {{item.doTime}}</div>
                            <div class="black" style="margin-top: 5px"><span class="black-three">内容: </span> {{item.doContent}} </div>
                        </a-timeline-item>
                    </a-timeline>
                </div>
            </div>
        </a-modal>
        <!-- 合同驳回弹窗 -->
        <a-modal
            v-model="rejectDialog"
            title="驳回信息"
            :destroyOnClose="true"
            @ok="rejectSubmit"
            :width="950"
            >
            <form-index
                ref="formReject"
                :formData="formRejectData"
            >
            </form-index>
        </a-modal>
        <!-- 线下签署弹窗 -->
        <a-modal
            v-model="offSignDialog"
            title="线下签署凭证"
            :destroyOnClose="true"
            @ok="setEssOffSign"
            :width="950"
            @cancel="closeEssOffSign"
            >
            <form-index
                ref="formOffSign"
                :formData="formOffSignData"
            >
            </form-index>
        </a-modal>
        <!-- 线下签署弹窗 -->
        <a-modal
            v-model="offValiDialog"
            title="电子合同作废"
            :destroyOnClose="true"
            @ok="setEssDoInvalidSign"
            :width="600"
            >
            <a-alert type="error" message="电子签作废之后，无法再次恢复电子签，请慎重使用，如需作废，请详细填写作废具体原因！" banner />
            <form-index
                class="mt-2"
                ref="formVali"
                :formData="formValiData"
            >
            </form-index>
        </a-modal>
    </div>
</template>

<script>
import {  getEssContractRecordList,getContractFileUrl} from '@/services/ess'
import {DOWN_BATCH_ESS_RECORD,FIND_TEAMS_BY_USER,DO_OFFICE_SEAL_ESS,EXPORT_PROJECT_STAFF_HMC,DO_STAFF_CODE_ESS,EXPORT_PROJECT_STAFF_ALL_HMC} from '@/services/api'
import pick from 'lodash.pick'
import {mapGetters} from 'vuex'
import StandardTable from "@/components/table/StandardTable";
import { exportProgress } from '@/pages/aqjyzl/export' // 导出方法
import ExportLoading from '@/components/ExportLoading/ExportLoading' // progress组件
import moment from 'moment'
import {request} from '@/utils/request'
import { getOfficeProject, setEssOfficeReject, setEssProSure,getEsscContractDoLog,setEssDoOffSign,setEssDoInvalid } from "@/services/projectManagement";
import formIndex from "@/pages/components/form/index";
import { useRender } from '@/hooks/useRender.js'
import { storage } from '@/utils/storage.js'

const columns = [
    {
        title: '公司名称',
        dataIndex: 'officeName',
        key: 'officeName',
        width:210,
        align:'center',
        ellipsis: true
    },
    {
        title: '项目名称',
        dataIndex: 'projectName',
        key: 'projectName',
        width: 140,
        scopedSlots: { customRender: 'projectName' }
    },
    {
        title: '合同名称',
        dataIndex: 'contractName',
        align:'center',
        key: 'contractName',
        width: 230,
        scopedSlots: { customRender: 'paperName' }
    },
    // {
    //     title: '合同id',
    //     dataIndex: 'contractId',
    //     key: 'contractId',
    // },
    {
        title: '签署人',
        dataIndex: 'name',
        width:100,
        align:'center',
        key: 'name',
        scopedSlots: { customRender: "riskNames" },
    },
    // {
    //     title: '签署人身份证',
    //     dataIndex: 'idCard',
    //     key: 'idCard',
    // },
    {
        title: '签署人手机号',
        key: 'phone',
        width:130,
        align:'center',
        dataIndex: 'phone',
    },
    // {
    //     title: '签署人类型',
    //     key: 'userTypeName',
    //     dataIndex: 'userTypeName',
    // },
    // {
    //     title: '签署合同类型',
    //     key: 'contractTypeName',
    //     dataIndex: 'contractTypeName',
    // },
    {
        title: '签署状态',
        key: 'statusName',
        align:'center',
        width:100,
        dataIndex: 'statusName',
    },
    {
        title: '生成时间',
        key: 'createTime',
        align:'center',
        width:120,
        dataIndex: 'createTime',
        customRender: (text) => useRender.renderDate(text)
    },
    {
        title: '签署时间',
        key: 'updateTime',
        align:'center',
        width:120,
        dataIndex: 'updateTime',
        customRender: (text) => useRender.renderDate(text)
    },
    {
        title: '名册编号',
        key: 'codes',
        align:'center',
        width:150,
        dataIndex: 'codes',
    },
    {
        title: '操作',
        key: 'action',
        align:'center',
        width:130,
        scopedSlots: { customRender: 'action' },
    },
];
    const formRejectData = [
    {
        label: "驳回信息",
        placeholder: "请填写驳回信息",
        decorator: [
        "appOpinion",
        { rules: [{ required: true, message: "请填写驳回信息" }] },
        ],
        type: "textarea",
        key: "appOpinion",
        col: 24,
        labelCol: 6,
        wrapperCol: 14,
        display: true,
    },
    ]
    const formValiData = [
    {
        label: "作废具体原因",
        placeholder: "请填写作废具体原因",
        decorator: [
        "remark",
        { rules: [{ required: true, message: "请填写作废具体原因" }] },
        ],
        type: "textarea",
        key: "remark",
        col: 24,
        labelCol: 6,
        wrapperCol: 14,
        display: true,
    },
    ]
    const formOffSignData = [
        {
            label: "凭证信息",
            placeholder: "请填写凭证信息",
            decorator: [
            "remark",
            { rules: [{ required: true, message: "请填写凭证信息" }] },
            ],
            type: "textarea",
            key: "remark",
            col: 24,
            labelCol: 6,
            wrapperCol: 14,
            display: true,
        },
        {
            label: "凭证",
            placeholder: "请上传凭证",
            decorator: [
            "annex",
            { rules: [{ required: true, message: "请上传凭证" }] },
            ],
            type: "Upload",
            key: "annex",
            col: 24,
            labelCol: 6,
            wrapperCol: 14,
            display: true,
            uploadLength: 1,
        },
    ]

export default {
    mixins: [exportProgress],
    components: {StandardTable,ExportLoading,formIndex },
    data() {
        return {
            inputDataSource: [],
            visible:false,
            selectedRows: [],
            essContract:{},
            teamDatas:[],
            monthFormat: 'YYYY-MM-DD',
            // 筛选
            searchForm: {
                pageNumber:1,
                pageSize:50,
                essTemplateId: "",
                teamId:undefined,
                status: "",
                templateType: "",
                userName:"",
                enterDateStr:null,
                enterDateStart:null,
                enterDateEnd:null,
                contractName:null,
                codes:null,
                projectId: ''
            },
            loading: false,
            // 数据表格
            data: [],
            cacheData: [],
            columns,
            editingKey: '',
            // pagination:{
            //     current:1,
            //     pageSize:10,
            //     total:0,
            // },
            pagination: {
                showSizeChanger:true,
                current: 1,
                pageSize: 50,
                total: 0,
                pageSizeOptions: ['50', '100', '300', '800', '1000'],
                showLessItems: true,
                showQuickJumper: true,
                showTotal: (total, range) => `共 ${total} 条`
            },
            tableLoading: false,
            cPercent:0,
            isExporting:false,
            fileName:'入场资料文件',
            isSclw:false,
            exportProjectId: undefined, // 导出的项目Id
            essContractRoles: ['OFFICE_ESS_CONTRACT','SJKJ_CONTRACT_ESS'], // 电子合同专员-电子签章权限
            essRejectRoles: ['OFFICE_ESS_APPROVE'], // 合同驳回权限-电子审批专员
            essConfirmRoles: ['zong_bao_lao_wu','fen_bao_lao_wu'], // 劳务员确认驳回信息权限
            offSignRoles: ['fen_bao_lao_wu'], // 劳务员确认驳回信息权限
            rejectDialog: false, // 驳回信息弹窗
            formRejectData,
            essDoLog:[], // 签署进度
            sureId: [],
            offSignDialog: false, // 线下签署弹窗
            formOffSignData,
            formValiData,
            offValiDialog: false, // 作废弹窗
            isBatchReject: false, // 是否批量驳回
        };
    },
    computed: {
        ...mapGetters('account', ['user']),
        signOperate() { // 签章权限
            return this.user.nowRoleKey.some(role => this.essContractRoles.includes(role));
        },
        rejectOperate() { // 驳回权限和电子签作废权限
            return this.user.nowRoleKey.some(role => this.essRejectRoles.includes(role));
        },
        canOperate() { // 劳务员确认信息权限
            return this.user.nowRoleKey.some(role => this.essConfirmRoles.includes(role));
        },
        offSignOperate() { // 线下签署权限
            return this.user.nowRoleKey.some(role => this.offSignRoles.includes(role));
        }
    },

    mounted() {
        this.doQueryTeams();
        this.getEssContractRecordList()
        if (this.user.loginType == 1) {  //公司账号
            this.columns.map(item => { // 兼容125%表格显示
                if (item.key == 'contractName') {
                    delete item.width
                }
            })
        }else{
            this.columns = this.columns.filter(item => item.key != 'projectName')
        }
        // 个别公司权限
        if(this.user.officeId=='ac35537583724611b1220ed01a746173'||this.user.officeId=='6189aad8cd1c4cb08ef4f87c37e81772'||this.user.officeId=='373ae3d99d624303ad19e46f8075e24c') {
            this.isSclw = true;
        }
    },
    watch: {
        cPercent:{
            handler(newName, oldName) {
                this.cPercent=newName;
            },
        immediate: true,
        },
        loadingText:{
            handler(newName, oldName) {
                this.loadingText=newName;
            },
            immediate: true,
        }
    },
    methods: {
        addWatermark() {
            const container = document.getElementById('watermarkContainer');
            if (!container) {
                console.error('水印容器不存在');
                return;
            }
            // 拿到当前用户的名字号码
            const userInfo = storage.get(process.env.VUE_APP_USER_KEY)
            const { name = '', phone = '' } = userInfo.officeLoginData

            const pageSize = 150; // 水印元素的尺寸
            const containerWidth = container.offsetWidth; // 容器宽度
            const containerHeight = container.offsetHeight; // 容器高度
            const rows = Math.ceil(containerHeight / pageSize); // 计算行数
            const cols = Math.ceil(containerWidth / pageSize); // 计算列数
            const offsetX = (containerWidth % pageSize) / 2; // 计算水平方向的偏移量
            const offsetY = (containerHeight % pageSize) / 2 + 20; // 计算垂直方向的偏移量，加上固定的偏移量 20px

            for (let i = 0; i < rows; i++) {
                for (let j = 0; j < cols; j++) {
                const left = j * pageSize + offsetX;
                const top = i * pageSize + offsetY;
                const right = left + pageSize;
                const bottom = top + pageSize;

                // 检查水印是否超出容器范围
                if (right <= containerWidth && bottom <= containerHeight) {
                    const watermark = document.createElement('div');
                    watermark.style.position = 'absolute';
                    watermark.style.top = `${top}px`;
                    watermark.style.left = `${left}px`;
                    watermark.style.opacity = '0.5';
                    watermark.style.pointerEvents = 'none';
                    watermark.style.fontSize = '18px';
                    watermark.style.color = '#ccc';
                    watermark.style.textAlign = 'center'; // 文本居中显示
                    watermark.style.transform = 'rotate(-30deg)';
                    watermark.innerHTML = `${name}<br>${phone}`;

                    container.appendChild(watermark);
                }
                }
            }
        },
        updateSelectRows(e) {
            console.log(e, 'updateSelectRows')
        },
        handlInputSelect(e) {
            this.doQueryTeams()
        },
        handleInputSearch(value) {
            this.inputDataSource = []
            if(!value) {
                return
            }
            let params = {
                officeId: this.user.officeId,
                projectName: value
            }
            getOfficeProject(params).then((res) => {
                if (res.data.code === 0) {
                    res.data.data.list.forEach(el => {
                        this.inputDataSource.push({
                            title: el.fullName,
                            id: el.id,
                        })
                    })

                }
            });
        },
        handleChange(value) {
            this.searchForm.teamId = value;
            this.searchForm.pageNumber = this.pagination.pageNumber = 1
            this.getEssContractRecordList();
        },
        handleBlur() {
            console.log('blur');
        },
        handleFocus() {
            console.log('focus');
        },
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
            },
        doQueryTeams(){
            request(FIND_TEAMS_BY_USER,"post",{projectId: this.searchForm.projectId}).then(res => {
                if(res.data.code==0){
                    this.teamDatas = res.data.data;
                }
                })
            },
        deleteRecord(key) {
            this.data = this.data.filter((item) => item.key !== key);
            this.selectedRows = this.selectedRows.filter((item) => item.key !== key);
        },
        toggleAdvanced() {
            this.advanced = !this.advanced;
        },
        remove() {
            this.data = this.data.filter(
                (item) =>
                this.selectedRows.findIndex((row) => row.key === item.key) === -1
            );
            this.selectedRows = [];
        },
        onClear() {
            // this.$message.info('您清空了勾选的所有行')
        },
        // init() {
        //     this.searchForm.pageNumber = this.pagination.pageNumber = 1
        // },
        getEssContractRecordList() {
            this.loading = true
            this.tableLoading = true

            getEssContractRecordList(this.searchForm).then(res => {
                this.data = res.data.data.list
                this.data.forEach(item => {
                    item.statusName = this.statusName('statusName', item.status)
                    item.templateType = item.templateType + "";
                })


                this.cacheData = this.data.list
                this.pagination.total = res.data.data.total
            }).finally(()=>{
                this.loading = false
                this.tableLoading = false
            })
        },
        handleSearch(){
            this.searchForm.pageNumber = 1;
            this.pagination.pageNumber = 1;
            this.pagination.current=1;
            // this.pagination.pageSize = 50
            this.getEssContractRecordList();
        },
        // 3.0系统增加-重置按钮
        doReset() {
            this.searchForm = {
                pageNumber:1,
                pageSize:50,
                essTemplateId: "",
                teamId:undefined,
                status: "",
                templateType: "",
                userName:"",
                enterDateStr:null,
                enterDateStart:null,
                enterDateEnd:null,
                contractName:null,
                codes:null
            },
            this.selectedRows = []
            this.pagination.pageNumber = 1;
            this.pagination.current=1;
            this.getEssContractRecordList();
        },
        showRecord(record){
            // getConsoleContractRecordUrl({contractId: contractId}).then(res=>{
            //     window.open(res.data.msg);
            // })
            // console.log(record);
            if(record.fromType!=null&&record.fromType=='2'){
                window.open(record.fddDownUrl);
            }else{
                console.log(record);
                window.open(record.ossPath);
                // let arr = [];
                // let contractId = record.id
                // arr.push(contractId);
                // getContractFileUrl(arr).then(res=>{
                //     if(res.data.code==0){
                //         this.essContract = res.data.msg
                //         window.open(this.essContract);
                //         // this.visible = true;
                //     }else{
                //         this.$message.error("合同预览失败，请联系管理员");
                //     }
                // })
            }

        },
        onPageChange(e){
            this.searchForm.pageNumber = e.current;
            this.searchForm.pageSize = e.pageSize;
            this.pagination.current = e.current;
            this.pagination.pageSize = e.pageSize;
            this.getEssContractRecordList();
        },
        downloadBatch(type){
            this.loadKeys = [];
            this.cPercent = 0;
            const time = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
            if(this.selectedRows&&this.selectedRows.length>0){
                this.isExporting=true
                this.loadingText = "正在下载电子合同...";
                let items = [];
                let itemsKeys = [];
                this.selectedRows.map((item) => {
                    items.push(item);
                    itemsKeys.push(item.id);
                })
                let params = {
                    idList:itemsKeys,
                    type:type
                }
                let fileName = ""
                fileName="入场电子合同("+time+").zip";
                this.exportFunNomal(DOWN_BATCH_ESS_RECORD,params,fileName,items)
            }else{
                this.$message.info('请选中劳工人员进行下载');
                return;
            }
        },
        exportStaffHmc(){
            this.loadKeys = [];
            this.cPercent = 0;
            const time = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
            if(this.selectedRows&&this.selectedRows.length>0){
                this.isExporting=true
                this.loadingText = "正在导出花名册...";
                let items = [];
                let itemsKeys = [];
                let idList = [];
                this.selectedRows.map((item) => {
                    items.push(item);
                    itemsKeys.push(item.idCard);
                    idList.push(item.id);
                })
                let params = {
                    idCardList:itemsKeys,
                    idList: idList,
                    bussIds:idList,
                    projectId:this.searchForm.projectId ? this.searchForm.projectId : undefined,
                    type: 2 // 1: 进出名册导出，2: 电子名册导出
                }
                request(DO_STAFF_CODE_ESS,"post",params).then(res => {
                    if(res.data.code==0){
                        this.$message.success("导出成功");
                        let fileName = ""
                        fileName="花名册名单("+res.data.data+").xlsx";
                        this.exportFunNomal(EXPORT_PROJECT_STAFF_HMC,params,fileName,items)
                        //生成人员批次号
                        // this.doStaffCode(params);
                        this.selectedRows = []
                        this.handleSearch();
                    }else{
                        this.$message.error("导出名册编号生成失败！");
                    }
                });

            }else{
                this.$message.info('请选中劳工人员进行下载');
                return;
            }
        },
        // 导出全部人员花名册
        exportStaffAllHmc() {
            if(this.selectedRows&&this.selectedRows.length>0){
                if (this.user.loginType == 1 && !this.searchForm.projectId) {
                    // 企业版需要选择项目导出
                    return this.$message.info('请选择项目导出');
                }
                this.isExporting=true
                this.loadingText = "正在导出花名册...";
                let items = []
                let itemsKeys = []
                let idList = []
                this.selectedRows.map((item) => {
                    items.push(item);
                    itemsKeys.push(item.idCard);
                    idList.push(item.id);
                })
                let params = {
                    idCardList:itemsKeys,
                    idList: idList,
                    bussIds:idList,
                    projectId:this.searchForm.projectId ? this.searchForm.projectId : undefined,
                    type: 2 // 1: 进出名册导出，2: 电子名册导出
                }
                let fileName = ""
                fileName="导出人员花名册（组合）名单.xlsx";
                this.exportFunNomal(EXPORT_PROJECT_STAFF_ALL_HMC,params,fileName)
                this.handleSearch();
            }else{
                return this.$message.info('请选中劳工人员进行下载');
            }
        },
        // doStaffCode(data){ 
        //     request(DO_STAFF_CODE_ESS,"post",data).then(res => {
        //         if(res.data.code==0){
        //             this.$message.success("导出花名册成功，编号是："+res.data.msg);
        //         }else{
        //             this.$message.error("导出名册编号生成失败！");
        //         }
        //     });
        // },

        onTimeChange(date, dateString){
            this.searchForm.enterDateStart = dateString[0]
            this.searchForm.enterDateEnd = dateString[1]
        },
        doSealOffice(){
            this.loading = true
            let itemsKeys = [];
            if(this.selectedRows&&this.selectedRows.length>0){
                this.selectedRows.map((item) => {
                    itemsKeys.push(item.id);
                })
            }else{
                this.loading = false;
                return this.$message.warn("对不起，请选择待公司签署的合同落章")
            }
            let obj ={
                idLists:itemsKeys
            }
            request(DO_OFFICE_SEAL_ESS,"post",obj).then(res => {
                if(res.data.code==0){
                    // console.log(res.data)
                    this.loading = false;
                    this.$message.success("签署成功！总计："+res.data.data+"人，成功："+res.data.msg+"人");
                    this.handleSearch();
                }else{
                    this.loading = false;
                    this.$message.error("签署失败！"+res.data.msg)
                }
            })
        },
        doSignOffice() {
            // 签章合同公司签章
            let obj ={
                idLists:[this.essContract.id]
            }
            this.confirm("电子签公司签章",`是否确认给 ${this.essContract.name} ${this.essContract.contractTypeName}盖公司签章`).then(() => {
                    request(DO_OFFICE_SEAL_ESS,"post",obj).then(res => {
                        if(res.data.code==0){
                            this.$message.success("签署成功！总计："+res.data.data+"人，成功："+res.data.msg+"人");
                            this.handleSearch();
                        }else{
                            this.$message.error("签署失败！"+res.data.msg)
                        }
                        this.closeView()
                })
            })
        },
        doRejectContract(type) {
            // 驳回合同
            this.isBatchReject = type
            if (this.isBatchReject) {
                // 批量驳回
                const allCanReject = this.selectedRows.every(el => el.status === 0)
                if (allCanReject) {
                    this.rejectDialog = true
                } else {
                    this.$message.error('仅支持选择“待工人签署”状态的合同，请重新选择！')
                }
            } else {
                // 单个驳回
                this.rejectDialog = true
            }
        },
        setEssProSure(isBatch, record) {
            this.confirm('劳务员更正信息确认',`项目已确认${isBatch ? '' : ' ' + record.name + ' '}信息完全正确, 重新发起合同！`).then(() => {
                let idLists = []
                if (isBatch) {
                    const allCanSure = this.selectedRows.every(el => el.status == 2)
                    if (allCanSure) {
                        this.selectedRows.forEach(el => {
                            idLists.push(el.id)
                            this.sureId.push(el.id)
                        })
                    } else {
                        this.$message.error('仅支持选择“已驳回”状态的合同，请重新选择！')
                        return
                    }
                } else {
                    idLists = [record.id]
                    this.sureId.push(record.id)
                }
                // 劳务员确认意见
                setEssProSure({idLists}).then((result) => {
                        if (result.data.code === 0) {
                            this.sureId = []
                            this.handleSearch();
                            this.$message.success("合同已重新发起，请通知工人及时签署(有效期七天)", 5);
                        } else {
                            this.$message.error(result.data.msg);
                            this.sureId = []
                        }
                })
            })
        },
        rejectSubmit() {
            let idLists = []
            if (this.isBatchReject) {
                // 批量驳回
                const allCanReject = this.selectedRows.every(el => el.status === 0)
                if (allCanReject) {
                    this.selectedRows.forEach(el => {
                        idLists.push(el.id)
                    })
                } else {
                    this.$message.error('仅支持选择“待工人签署”状态的合同，请重新选择！')
                    return
                }
            } else {
                // 单个驳回
                idLists = [this.essContract.id]
            }
            this.$refs.formReject.combinationSubmit().then((res) => {
                if (this.rejectOperate) {
                    const params = {
                    ...res,
                    idLists
                    };
                    // 公司驳回合同盖章
                    setEssOfficeReject(params).then((result) => {
                        if (result.data.code === 0) {
                            this.$message.success("驳回操作成功，请联系劳务员确认驳回信息", 5);
                        } else {
                            this.$message.error(result.data.msg);
                        }
                        this.handleSearch();
                    });
                }
                // 清空表单和弹窗
                this.rejectDialog = false;
                this.$refs.formReject.resetFields()
                this.closeView()
            });

        },
        viewOpenUrl(record){
            // 穗建合同查看权限
            if (record.officeId != '1f6bd3af5c434991a44fc50fcb424f38' || this.user.loginType == 1) {
                this.$nextTick(() => {
                    this.essContract = record
                    this.visible = true;
                    this.getEsscContractDoLog(record.id)
                    setTimeout(this.addWatermark)
                })
            }
        },
        closeView() {
            this.essContract = {}
            this.essDoLog = []
            this.visible = false
        },
        // 封装弹窗确定按钮
        confirm(title, content, isLoading) {
            return new Promise((resolve, reject) => {
                this.$confirm({
                title: title,
                content: content,
                onOk() {
                    resolve();
                },
                });
            });
        },
        getEsscContractDoLog(params) {
            // 获取合同签署进度详情
            getEsscContractDoLog({essId: params}).then(res => {
                if (res.data.code === 0) {
                    let data = res.data.data
                    data.map(item => {
                        item.doTime = moment(item.doTime).format("YYYY-MM-DD");
                    })
                    this.essDoLog = data
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
        statusName(type,status) {
            if (type == 'statusName') {
                const obj = {
                    0: '待工人签署',
                    1: '签署完成',
                    2: '已驳回',
                    3: '待公司签署',
                    4: '已线下签署',
                    99: '已作废',
                }
                let str = null;
                Object.keys(obj).forEach(key =>{
                    if(key == status){
                        str =  obj[key]
                    }
                })
                return str
            }
        },
        showEssOffSign(record) {
            this.essContract = record
            this.offSignDialog = true
        },
        closeEssOffSign() {
            this.essContract = {}
            this.$refs.formOffSign.resetFields()
            this.offSignDialog = false
        },
        setEssOffSign() {
            // 线下签署接口
            this.$refs.formOffSign.combinationSubmit().then((res) => {
                const params = {
                    ...res,
                    id:this.essContract.id
                };
                setEssDoOffSign(params).then((result) => {
                    if (result.data.code === 0) {
                        this.$message.success("操作成功");
                    } else {
                        this.$message.error(result.data.msg);
                    }
                    this.handleSearch();
                });
                this.closeEssOffSign()
            });
        },
        setEssDoInvalidSign() {
            this.$refs.formVali.combinationSubmit().then((res) => {
                if (this.rejectOperate) {
                    const params = {
                    ...res,
                    id:this.essContract.id
                    };
                    // 公司驳回合同盖章
                    setEssDoInvalid(params).then((result) => {
                        if (result.data.code === 0) {
                            this.$message.success("操作成功", 5);
                        } else {
                            this.$message.error(result.data.msg);
                        }
                        this.handleSearch();
                    });
                }
                // 清空表单和弹窗
                this.offValiDialog = false
                this.$refs.formVali.resetFields()
                this.closeView()
            });
        }
    }
};
</script>


<style lang="less" scoped>
    .ant-advanced-search-form {
        padding: 24px;
        background: #fbfbfb;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        margin-bottom: 10px;
    }

    .ant-advanced-search-form .ant-form-item {
        display: flex;
    }

    .ant-advanced-search-form .ant-form-item-control-wrapper {
        flex: 1;
    }

    #components-form-demo-advanced-search .ant-form {
        max-width: none;
    }

    tr{
        background-color: #ffffff;
    }
    .operator {
        margin-right: 8px;

        .ant-btn {
        color: #307dfa;
        border-color: #307dfa;
        }
    }
    .fddPdf {
        height:70vh;
        border:none;
    }
    .riskTips {
        background: rgb(255, 232, 236);
        border-radius: 2px;
        color: #FF5D78;
        font-size: 14px;
        padding: 5px 9px;
    }

</style>
