import { cloneDeep, omit } from 'lodash'
import { LAYOUT_TABS_VIEW, LAYOUT_BLANK_VIEW, NOT_FOUND } from './constant'
import { message } from 'ant-design-vue'
import { getSystemType } from '@/utils/system.js'
// import VueRouter from 'vue-router'
// import { treeMap } from '@/utils/helper/treeHelper.js'
// import { isHttpUrl } from '@/utils/is.js'

const LayoutMap = new Map()
LayoutMap.set('TabsView', LAYOUT_TABS_VIEW)
LayoutMap.set('BlankView', LAYOUT_BLANK_VIEW)

// src/pages下的所有页面模块映射表，键名：组件路径，键值：引入组件的函数
let pagesMap
// src/pages下的所有页面模块的组件路径数组
let pagesImportPathArr
/**
* @description 创建所有页面模块映射表
*/
function createpagesMap() {
  const pagesContext = require.context("../pages", true, /\.vue$|\.tsx$/);
  // 使用 keys() 方法获取所有匹配的文件的相对路径
  const res = pagesContext.keys().reduce((map, item) => {
    const prefix = '../pages'
    const path = item.slice(1, item.length)
    const key = prefix + path
    
    // map[key] = () => import(`../pages${path}`)
    const comp = pagesContext(item)
    map[key] = comp.default
    return map
  }, {});

  return res
}

/**
* @description 将 menus 菜单转换为路由列表
*/
export function transformMenuToRoute(menus) {
  pagesMap = createpagesMap()
  pagesImportPathArr = Object.keys(pagesMap)

  const result = cloneDeep(menus)

  try {
    result.forEach((route) => {
      // 【根目录】用 Layout-TabsView
      if (route.children && route.children.length) {
        route.component = LayoutMap.get('TabsView')
      } else {
        // 处理【根页面】路由，没有用 Layout 组件，表示这就是一个菜单页面
        route.children = [cloneDeep(route)]
        route.component = LayoutMap.get('BlankView')
      }

      // 处理 route 属性
      handleProps(route)
      // 处理 子目录/菜单 的组件引入
      route.children && asyncImportRoute(route.children)
    })
  } catch (error) {
    console.error(error)
  }
  return result
}

/**
* @description 处理 子目录/菜单 的组件引入
*/
function asyncImportRoute(routes) {
  if (!routes) return

  routes.forEach((item) => {
    const { component, children, path } = item

    // 处理 route 属性
    handleProps(item)
    
    // 如果是 Layout 布局组件的字符串，转成对应的 import path (配菜单的时候可能规范没统一，填了字符串，实际可以不填的)
    if (component && LayoutMap.has(component)) {
      item.component = LayoutMap.get(component)
    }
    // 子目录，使用 BlankView 的布局组件
    // 子目录的判断依据是：children 不为空，且不是根路由，且 showFather 不为 true (有children的页面，就会配 meta.showFater 为 true，其余要么不配，要么为 false)
    else if (children?.length && path !== '/' && item?.meta?.showFather !== true) {
      item.component = LayoutMap.get('BlankView')
    }
    // 菜单页面，动态引入页面组件
    else {
      item.component = dynamicImport(item)
    }

    // 配置菜单时，没有填写组件路径会走到这里；配置目录时，没有 children 会走到这里
    if (item.component === '') {
      message.error({
        content: `“${item.name}”的组件路径为空，请检查！`,
        duration: 3
      })
      console.error(`“${item.name}”的组件路径为空，请检查！该路由类型如果是目录，请检查 children 是否为空？ 该路由类型如果是菜单，请检查 component 是否漏填`, cloneDeep(item))
      // 随便给着一个布局组件吧，否则vue-router报错，页面都进不去
      item.component = LayoutMap.get('BlankView')
    }

    children?.length && asyncImportRoute(children)
  })
}

/**
* @description 处理 route 属性
*/
function handleProps(route) {
  /* 处理 meta */
  // extendProps目前已有的扩展项：系统类型-systemType、元信息-meta
  const extendProps = route.extendProps && JSON.parse(route.extendProps) || {}
  const meta = extendProps.meta && JSON.parse(extendProps.meta) || {}
  meta.title = route.name
  meta.icon = route.icon
  meta.ignoreKeepAlive = !route.keepAlive
  // 是否隐藏菜单（仍可通过路由访问）
  meta.invisible = !route.visible
  // 所属系统（用于过滤路由）
  meta.systemType = extendProps.systemType || []
  route.meta = meta

  /* 过滤多余字段 */
  const routeIgnoreKeys = ['icon', 'alwaysShow', 'componentName', 'keepAlive', 'visible', 'extendProps']
  route = omit(route, routeIgnoreKeys)
}

/**
* @description 动态引入页面组件
* @param {String} route 路由
* @return {Function} 引入组件的函数 
*/
function dynamicImport(route) {
  const { component } = route
  if (!component) return ''

  const matchKeys = pagesImportPathArr.filter((key) => {
    const k = key.replace('../', '/')
    const startFlag = component.startsWith('/')
    const endFlag = component.endsWith('.vue') || component.endsWith('.tsx')
    const startIndex = startFlag ? 0 : 1
    const lastIndex = endFlag ? k.length : k.lastIndexOf('.')
    return k.substring(startIndex, lastIndex) === component
  })
  if (matchKeys && matchKeys.length === 1) {
    const matchKey = matchKeys[0]
    return pagesMap[matchKey]
  }
  else if (matchKeys && matchKeys.length > 1) {
    console.warn(
      '请不要在views文件夹下的同一层次目录中创建具有相同文件名的`.vue`和`.TSX`文件。 这会导致动态引入失败!',
    )
  }
  else {
    console.warn(`在src/下找不到\`${component}, 当前路由完整信息：`, route)
    return NOT_FOUND
  }
}

/**
 * 过滤路由列表
 */
export function filterRouteList(routeList) {
  return routeList.filter((route) => {
    const { meta } = route
    // TODO: 【市政】有域名之后解开注释的代码
    // const currentSystemType = getSystemType()
    const currentSystemType = getSystemType() === 'shizheng' ? 'jiananyi' : getSystemType()

    // tips：meta.systemType(该路由的系统类型) 实际是一个数组
    if (!meta.systemType.includes(currentSystemType)) {
      return false
    }

    if (route.children?.length) {
      route.children = filterRouteList(route.children)
    }
    return true
  })
}

/* 以下代码暂不需要 */
// /**
// * @description 将路由转换成菜单
// * @param {} 
// * @param {} 
// * @return {Array} 
// */
// export function transformRouteToMenu(routeModList) {
//   // 深拷贝
//   const cloneRouteModList = cloneDeep(routeModList)
//   const routeList = []

//   // 对路由项进行修改
//   cloneRouteModList.forEach((item) => {
//     if (item.meta?.single) {
//       const realItem = item?.children?.[0]
//       realItem && routeList.push(realItem)
//     }
//     else {
//       routeList.push(item)
//     }
//   })
//   // 提取树指定结构
//   const list = treeMap(routeList, {
//     conversion: (node) => {
//       const { meta: { title, hideMenu = false } = {} } = node

//       return {
//         ...(node.meta || {}),
//         meta: node.meta,
//         name: title,
//         hideMenu,
//         path: node.path,
//         ...(node.redirect ? { redirect: node.redirect } : {}),
//       }
//     },
//   })
//   // 路径处理
//   joinParentPath(list)
//   return cloneDeep(list)
// }

// // 路径处理
// function joinParentPath(menus, parentPath = '') {
//   for (let index = 0; index < menus.length; index++) {
//     const menu = menus[index]
//     // https://next.router.vuejs.org/guide/essentials/nested-routes.html
//     // Note that nested paths that start with / will be treated as a root path.
//     // 请注意，以 / 开头的嵌套路径将被视为根路径。
//     // This allows you to leverage the component nesting without having to use a nested URL.
//     // 这允许你利用组件嵌套，而无需使用嵌套 URL。
//     if (!(menu.path.startsWith('/') || isHttpUrl(menu.path))) {
//       // path doesn't start with /, nor is it a url, join parent path
//       // 路径不以 / 开头，也不是 url，加入父路径
//       menu.path = `${parentPath}/${menu.path}`
//     }
//     if (menu?.children?.length)
//       joinParentPath(menu.children, menu.meta?.hidePathForChildren ? parentPath : menu.path)
//   }
// }

// /**
//  * Convert multi-level routing to level 2 routing
//  * 将多级路由转换为 2 级路由
//  */
// export function flatMultiLevelRoutes(routeModules) {
//   const modules = cloneDeep(routeModules)

//   for (let index = 0; index < modules.length; index++) {
//     const routeModule = modules[index]
//     // 判断级别是否 多级 路由
//     if (!isMultipleRoute(routeModule)) {
//       // 声明终止当前循环， 即跳过此次循环，进行下一轮
//       continue
//     }
//     // 路由等级提升
//     promoteRouteLevel(routeModule)
//   }
//   return modules
// }

// // 判断级别是否超过2级
// function isMultipleRoute(routeModule) {
//   // Reflect.has 与 in 操作符 相同, 用于检查一个对象(包括它原型链上)是否拥有某个属性
//   if (!routeModule || !Reflect.has(routeModule, 'children') || !routeModule.children?.length)
//     return false

//   const children = routeModule.children

//   let flag = false
//   for (let index = 0; index < children.length; index++) {
//     const child = children[index]
//     if (child.children?.length) {
//       flag = true
//       break
//     }
//   }
//   return flag
// }

// // 路由等级提升
// function promoteRouteLevel(routeModule) {
//   let router = new VueRouter({
//     routes: [routeModule]
//   })
//   // getRoutes： 获取所有 路由记录的完整列表。
//   const routes = router.getRoutes()
//   // 将所有子路由添加到二级路由
//   addToChildren(routes, routeModule.children || [], routeModule)
//   router = null

//   // omit lodash的函数 对传入的item对象的children进行删除
//   routeModule.children = routeModule.children?.map(item => omit(item, 'children'))
// }

// // 将所有子路由添加到二级路由
// function addToChildren(routes, children, routeModule) {
//   for (let index = 0; index < children.length; index++) {
//     const child = children[index]
//     const route = routes.find(item => item.name === child.name)
//     if (!route)
//       continue

//     routeModule.children = routeModule.children || []
//     if (!routeModule.children.find(item => item.name === route.name))
//       routeModule.children?.push(route)

//     if (child.children?.length)
//       addToChildren(routes, child.children, routeModule)
//   }
// }