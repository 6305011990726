<template>


    <div>
        <div class="my-table">
            <a-card :bordered="false">
                <div id="components-form-demo-advanced-search">
                    <a-form class="ant-advanced-search-form" :form="searchForm" >
                        <a-row :gutter="24">
                            <a-col :span="6" >
                                <a-form-item :label="`项目名`">
                                    <a-input v-model="searchForm.fullName" placeholder="请输入项目名称" />
                                </a-form-item>
                            </a-col>

                            <a-col :span="4" :style="{ textAlign: 'right', marginTop: '3px'}">
                                <a-button type="primary" icon="search" :loading="loading" @click="handleSearch">
                                    查询
                                </a-button>
                            </a-col>
                        </a-row>

<!--                        <a-row>-->
<!--                            <a-button type="primary" @click="add()">-->
<!--                                新增项目-->
<!--                            </a-button>-->
<!--                        </a-row>-->

                    </a-form>
                </div>
                <!-- <a-table
                        :columns="columns"
                        :data-source="data"
                        :pagination="pagination"
                > -->
                <standard-table
                    :columns="columns"
                    :dataSource="data"
                    :pagination="pagination1"
                    @change="onPageChange"
                >

                    <template slot="action" slot-scope="{record}">
                                <span>
<!--                                  <a @click="showEditModel(record.projId)">编辑</a>-->
<!--                                  <a-divider type="vertical" />-->
                                  <!-- <a @click="$refs.modal.relationOfficeOpen(record.id)">公司关联</a>
                                  <a-divider type="vertical" /> -->
                                  <a @click="$refs.acctModal.relationAcctOpen(record.id)">账号管理</a>
                                 <a-divider type="vertical" />
                                 <a @click="bindFOffice(record.id)">分公司绑定</a>
                                </span>
                    </template>
                <!-- </a-table> -->
                </standard-table>
            </a-card>
        </div>

        <div class="my-edit">
            <a-modal
                    :title="title"
                    :visible="visible"
                    :confirm-loading="confirmLoading"
                    @ok="editSubmit"
                    @cancel="closeEditModel"
                    width="860px"
            >
                <a-form-model :model="form"
                              :label-col="labelCol"
                              :wrapper-col="wrapperCol"
                              ref="ruleForm"
                              :rules="rules"
                              v-if="visible"
                >
                    <a-form-model-item label="项目名称（全称）" ref="fullName" prop="fullName"  required>
                        <a-input v-model="form.fullName" />
                    </a-form-model-item>
                    <a-form-model-item label="工程编码" ref="projectNum" prop="projectNum" required>
                        <a-input v-model="form.projectNum" />
                    </a-form-model-item>
                    <a-form-model-item label="运维服务到期时间" ref="dueTime" prop="dueTime" >
                        <a-date-picker v-model="form.dueTime" @change="dueTimeChange"/>
                    </a-form-model-item>
                    <a-form-model-item label="所属公司" required>
                        <a-tree-select

                                :disabled="form.projId != ''"
                                style="width: 100%"
                                v-model="form.officeId"
                                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                :tree-data="officeTreeData"
                                placeholder="请选择直属上级单位"
                                tree-default-expand-all
                                :replaceFields="{children:'children', title:'name', key:'id', value:'id' }"
                                :treeDefaultExpandAll="false"
                                @select="officeSelect"
                        >
                        </a-tree-select>
                    </a-form-model-item>
                    <a-form-model-item label="项目负责人" ref="directorUserId" prop="directorUserId" required>

                        <a-select
                                v-model="form.directorUserId"
                                :disabled="form.projId != ''"
                        >
                            <a-select-option v-for="(item, index) in directorUserList" :key="index" :value="item.id">
                                {{item.name}}
                            </a-select-option>
                        </a-select>

                    </a-form-model-item>
                    <a-form-model-item label="项目类别" required>
                        <a-select
                                v-model="form.projType"
                        >
                            <a-select-option v-for="(item, index) in projectTypeDictList" :key="index" :value="item.value">
                                {{ item.label }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="所在经纬度" ref="lanAndLat" prop="lanAndLat">
                        <a-input v-model="form.lanAndLat" disabled/>
                    </a-form-model-item>


                    <a-form-model-item label="地图">
                        <a-card>
                            <a-select
                                    show-search
                                    :value="mapLocation.address"
                                    placeholder="input search text"
                                    style="width: 600px"
                                    :default-active-first-option="false"
                                    :show-arrow="false"
                                    :filter-option="false"
                                    :not-found-content="null"
                                    @search="querySearch"
                                    @change="handleSelect"
                            >
                                <a-select-option v-for="(map, index) in mapData" :key="index" :value="index">
                                    {{ map.value }}
                                </a-select-option>
                            </a-select>
                            <baidu-map
                                    id="allmap"
                                    :center="mapCenter"
                                    :zoom="mapZoom"
                                    :scroll-wheel-zoom="true"
                                    @ready="handlerBMap">
                            </baidu-map>
                        </a-card>
                    </a-form-model-item>
                </a-form-model>
            </a-modal>
        </div>

        <relation-office-model ref="modal"/>
        <relation-acct-model ref="acctModal"/>

        <a-modal v-model="officeChoiceShow" title="项目分公司绑定" @ok="handleOk" @cancel="cancel">
            <a-form :form="formOffice" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }" layout="horizontal">
                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-item label="公司：">
                        <a-select
                        show-search
                            :allowClear="true"
                            :filterOption="filterOption"
                            style="width: 100%"
                            @search="officeSearch"
                            v-model="formOffice.officeId">
                            <a-select-option v-for="t in officeDataSource" :key="t.id" :value="t.id">{{t.name}}</a-select-option>
                        </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-item label="在建/完工：">
                        <a-select
                            :allowClear="true"
                            style="width: 100%"
                            v-model="formOffice.isOver">
                            <a-select-option  :value="0">在建</a-select-option>
                            <a-select-option  :value="1">完工</a-select-option>
                            <a-select-option  :value="2">停工</a-select-option>
                        </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-item label="公司：">
                        <a-select
                            :allowClear="true"
                            style="width: 100%"
                            v-model="formOffice.type">
                            <a-select-option :value="0">房建项目</a-select-option>
                            <a-select-option :value="1">市政项目</a-select-option>
                        </a-select>
                        </a-form-item>
                    </a-col>
                </a-row>
            </a-form>
        </a-modal>
    </div>



</template>
<script>
import StandardTable from '@/components/table/StandardTable'
    import {getProjectList, getOfficeTree, saveProject, getDirectorUserList, getProjectInfo, getProjectDictTypeList} from '@/services/sys'
    import {FIND_ALL_OFFICE_LIST,PROJECT_OFFICE_CHILDRELATION,PROJECT_OFFICE_CHILDRELATION_SAVE} from "@/services/api";
    import { request } from "@/utils/request";
    import {mapGetters} from 'vuex'
    import RelationOfficeModel from './modules/RelationOfficeModel.vue'
    import RelationAcctModel from './modules/RelationAcctModel.vue'
    import { useRender } from '@/hooks/useRender.js'


    const columns = [
        {
            title: '项目名称',
            dataIndex: 'fullName',
            key: 'fullName',
            width: 400
        },
        {
            title: '项目简称',
            dataIndex: 'shortName',
            key: 'shortName',
        },
        {
            title: '地址',
            dataIndex: 'addressRelated',
            key: 'addressRelated',
        },
        {
            title: '创建时间',
            key: 'createDate',
            dataIndex: 'createDate',
            customRender: (text) => useRender.renderDate(text)
        },
        {
            title: '操作',
            key: 'action',
            scopedSlots: { customRender: 'action' },
        },
    ];

    export default {
        components: {
            RelationOfficeModel,
            RelationAcctModel,
            StandardTable
        },
        data() {
            return {
                officeChoiceShow:false,
                officeDataSource:[],
                formOffice:{
                    officeId:"",
                    projectId:"",
                    isOver:0,
                    type:0
                },
                // 筛选
                searchForm: {
                    fullName: "",
                    isPage:"true",
                    pageNumber:1,
                    pageSize:10
                },
                loading: false,

                // 数据表格
                data: [],
                columns,

                // 详细信息弹窗
                // 详细数据弹窗
                title: "编辑",
                visible: false,
                confirmLoading: false,
                labelCol: { span: 4 },
                wrapperCol: { span: 19 },
                rules: {
                    fullName: [
                        { required: true, message: '请输入项目名称', trigger: 'blur' },
                    ],
                    projectNum: [{ required: true, message: '请输入工程编号', trigger: 'blur' }],
                    officeId: [{ required: true, message: '请选择所属公司', trigger: 'blur' }],
                    directorUserId: [{ required: true, message: '请选择项目负责人', trigger: 'blur' }],
                    projType: [{ required: true, message: '请选择项目类型', trigger: 'blur' }],
                },
                pagination1:{
                    showSizeChanger:true,
                    pageSizeOptions: ['10', '20', '30', '50'],
                    current:1,
                    pageSize:10,
                    total:0,
                    showLessItems:true,
                    showQuickJumper:true
                },
                form: {
                    fullName: '',
                    projectNum: '',
                    dueTime:'',
                    officeId:'',
                    directorUserId: '',
                    directorUserName: '',
                    projType: '',
                    longitude: '',
                    latitude: '',


                    lanAndLat: '',// 经纬度拼接  做显示用
                },
                officeTreeData: [],
                directorUserList: [],
                projectTypeDictList: [],
                companyTypeList: [],


                // 地图
                mapZoom: 15,
                mapCenter: { lng: 0, lat: 0},
                mapLocation: {
                    address: "",
                    coordinate: ""
                },
                mapData:[],


            };
        },
        computed: {
            ...mapGetters('account', ['user']),
        },
        created() {
            this.init()
        },
        methods: {
            init(){
                this.getProjectList();
                getProjectDictTypeList().then(res=>{
                    this.projectTypeDictList = res.data.data.projectTypeList
                    this.companyTypeList = res.data.data.companyTypeList
                    console.log(this.projectTypeDictList)
                })
            },
            handlerBMap({ BMap, map }) {
                this.BMap = BMap
                this.map = map
                if (this.mapLocation.coordinate && this.mapLocation.coordinate.lng) {
                    this.mapCenter.lng = this.mapLocation.coordinate.lng
                    this.mapCenter.lat = this.mapLocation.coordinate.lat
                    this.mapZoom = 15
                    map.addOverlay(new this.BMap.Marker(this.mapLocation.coordinate))
                } else {
                    this.mapCenter.lng = 113.271429
                    this.mapCenter.lat = 23.135336
                    this.mapZoom = 10
                }
                this.form.longitude =  this.mapCenter.lng
                this.form.latitude =  this.mapCenter.lat
                this.form.lanAndLat = this.form.longitude + ", " + this.form.latitude;
            },
            querySearch(queryString) {
                queryString = queryString == null || queryString == undefined ? "" : queryString;
                var that = this
                var myGeo = new this.BMap.Geocoder()
                myGeo.getPoint(queryString, function(point) {
                    if (point) {
                        that.mapLocation.coordinate = point
                        that.makerCenter(point)
                    } else {
                        that.mapLocation.coordinate = null
                    }
                }, this.locationCity)
                var options = {
                    onSearchComplete: function(results) {
                        that.mapData.length = 0;
                        if (local.getStatus() === 0) {
                            // 判断状态是否正确
                            var s = []
                            for (var i = 0; i < results.getCurrentNumPois(); i++) {
                                var x = results.getPoi(i)
                                var item = { value: x.address + x.title, point: x.point }
                                s.push(item)
                                // cb(s)
                            }
                            that.mapData = s;

                        } else {
                            that.mapData.length = 0;
                            // cb()
                        }
                    }
                }
                var local = new this.BMap.LocalSearch(this.map, options)
                local.search(queryString)
            },
            handleSelect(index) {
                const item = this.mapData[index]
                const { point } = item
                this.mapLocation.coordinate = point
                this.makerCenter(point)
                this.mapLocation.address = item.value
            },
            makerCenter(point) {
                if (this.map) {
                    this.map.clearOverlays()
                    this.map.addOverlay(new this.BMap.Marker(point))
                    this.mapCenter.lng = point.lng
                    this.mapCenter.lat = point.lat
                    this.mapZoom = 15
                }

                this.form.longitude =  this.mapCenter.lng
                this.form.latitude =  this.mapCenter.lat
                this.form.lanAndLat = this.form.longitude + ", " + this.form.latitude;
                console.log("asdasdad", this.lanAndLat)
            },
            getProjectList(){
                console.log(this.searchForm);
                getProjectList(this.searchForm).then(res => {
                    this.data = res.data.data.list
                    this.pagination1.total = res.data.data.total
                    console.log(this.data)
                })
            },
            handleSearch(){
                this.getProjectList()
            },
            add(){
                this.showEditModel()
            },
            showEditModel(projectId){
                this.visible = true

                if (this.user.nowRoleKey.includes("admin")){
                    this.searchForm.parentOfficeId = undefined
                }else{
                    this.searchForm.parentOfficeId = this.user.officeId
                }

                getOfficeTree({parentOfficeId: this.searchForm.parentOfficeId}).then(res => {
                    this.officeTreeData = res.data.data
                })

                if (projectId!=undefined && projectId!=""){
                    getProjectInfo({projectId: projectId}).then(res => {
                        var data = res.data.data;
                        this.form = data
                        this.form.lanAndLat = this.form.longitude + "," + this.form.latitude
                        this.officeSelect(this.form.officeId, null, null)
                    })
                }else{
                    this.form = {
                        projId:'',
                        fullName: '',
                        projectNum: '',
                        dueTime:'',
                        officeId:'',
                        directorUserId: '',
                        directorUserName: '',
                        projType: '',
                        longitude: '',
                        latitude: '',
                        lanAndLat: '',// 经纬度拼接  做显示用
                    }
                }


            },
            closeEditModel(){
                this.visible = false
            },
            editSubmit(){
                this.$refs.ruleForm.validate(valid => {
                    if (valid) {
                        saveProject(this.form).then(res =>{
                            const stat = res.data.code
                            this.$message.info(res.data.msg);
                            this.confirmLoading = false;
                            this.closeEditModel()
                        })

                    } else {
                        this.$message.error("请检查必填信息");
                        this.confirmLoading = false;
                        return false;
                    }
                });
            },
            officeSelect(value, node, extra){
                this.form.officeId = value
                getDirectorUserList({parentOfficeId: this.form.officeId}).then(res => {
                    this.directorUserList = res.data.data;
                })
            },
            dueTimeChange(time, timeString) {
                this.form.dueTime = timeString + " 00:00:00"
            },
            bindFOffice(projectId){
                this.officeChoiceShow = true;
                this.formOffice.projectId = projectId
                let obj = {
                    projectId:projectId
                }
                request(PROJECT_OFFICE_CHILDRELATION,"post",obj).then(result => {
                    if (result.data.code==0&&result.data.data!=null&&result.data.data.length>0) {
                        console.log(result.data.data)
                        this.formOffice.type = (result.data.data)[0].type;
                        this.formOffice.isOver = (result.data.data)[0].isOver;
                        this.querOfficeList((result.data.data)[0].officeId);
                    }
                })
            },
            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.indexOf(input) >= 0
                );
            },
            officeSearch(value){
                if(value){
                    let obj ={
                        name:value
                    }
                    request(FIND_ALL_OFFICE_LIST,"post",obj).then(result => {
                        if (result.data.code==0) {
                            this.officeDataSource = result.data.data;
                            this.officeDataSource.forEach((obj) => {
                                obj.key = obj.id;
                            });
                        }else{
                            this.$message.warning(result.message);
                        }
                    })
                }

            },
            onPageChange(e){
                this.searchForm.pageNumber = e.current;
                this.searchForm.pageSize = e.pageSize;
                this.pagination1.pageSize = e.pageSize;
                this.pagination1.current=e.current;
                this.getProjectList();

            },
            cancel(){
                this.officeDataSource = [];
                this.formOffice.officeId=null;
                this.formOffice.projectId = null;
                this.officeChoiceShow = false;
            },
            querOfficeList(officeId){
                let objx ={
                    id:officeId
                }
                request(FIND_ALL_OFFICE_LIST,"post",objx).then(result => {
                    if (result.data.code==0) {
                        result.data.data.forEach((o) => {
                            o.key = o.id;
                            this.formOffice.officeId=o.id
                            this.officeDataSource.push(o)
                        });
                    }
                })
            },
            handleOk(){
                const projectId = this.formOffice.projectId;
                const officeId = this.formOffice.officeId;
                let obj = {
                    projectId:projectId,
                    officeId:officeId,
                    type:this.formOffice.type,
                    isOver:this.formOffice.isOver
                }
                request(PROJECT_OFFICE_CHILDRELATION_SAVE,"post",obj).then(result => {
                    if (result.data.code==0) {
                        this.$message.success("绑定成功")
                    }
                })
            }

        }
    };
</script>

<style scoped>
    .ant-advanced-search-form {
        padding: 24px;
        background: #fbfbfb;
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        margin-bottom: 10px;
    }

    .ant-advanced-search-form .ant-form-item {
        display: flex;
    }

    .ant-advanced-search-form .ant-form-item-control-wrapper {
        flex: 1;
    }

    #components-form-demo-advanced-search .ant-form {
        max-width: none;
    }

    tr{
        background-color: #ffffff;
    }

    #allmap{
        height: 350px;
        width: 600px;
        margin: 0 auto;
        position: relative;
    }
</style>
