var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-drawer",
        {
          attrs: {
            width: 800,
            visible: _vm.visible,
            title: "编辑课时信息",
            "body-style": { paddingBottom: "80px" },
          },
          on: { close: _vm.onClose },
        },
        [
          _c(
            "a-form",
            {
              attrs: {
                form: _vm.form,
                "label-col": { span: 5 },
                "wrapper-col": { span: 18 },
                layout: "horizontal",
              },
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "所属课程：" } },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: [
                                    "courseId",
                                    {
                                      rules: [
                                        {
                                          required: true,
                                          message: "请选择归属课程：",
                                        },
                                      ],
                                    },
                                  ],
                                  expression:
                                    "['courseId', { rules: [{ required: true, message: '请选择归属课程：' }]}]",
                                },
                              ],
                              staticStyle: { width: "100%" },
                              attrs: {
                                allowClear: true,
                                placeholder: "请选择课程：",
                              },
                              on: { change: _vm.onChange },
                            },
                            _vm._l(_vm.courseList, function (t) {
                              return _c(
                                "a-select-option",
                                { key: t.id, attrs: { value: t.id } },
                                [_vm._v(_vm._s(t.name))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "课时标题：" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "name",
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        message: "请输课时标题：",
                                      },
                                    ],
                                  },
                                ],
                                expression:
                                  "['name', { rules: [{ required: true, message: '请输课时标题：' }] }]",
                              },
                            ],
                            attrs: { placeholder: "请输入课时标题：" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 12 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "课时视频：" } },
                        [
                          _c(
                            "a-upload",
                            {
                              attrs: {
                                name: "file",
                                multiple: true,
                                action: _vm.uploadUrl,
                                accept: ".mp4,.MP4,.flv,.FLV",
                                headers: _vm.headers,
                                beforeUpload: _vm.beforeUpload,
                              },
                              on: { change: _vm.handleChange },
                            },
                            [
                              _c(
                                "a-button",
                                [
                                  _vm.uploadStatus == "done" ||
                                  (_vm.uploadStatus == "upload" &&
                                    _vm.videoPath != "" &&
                                    _vm.videoPath != null)
                                    ? _c("a-icon", {
                                        staticStyle: { color: "#4a86e8" },
                                        attrs: {
                                          type: "check-circle",
                                          theme: "filled",
                                        },
                                      })
                                    : _c("a-icon", {
                                        attrs: { type: "upload" },
                                      }),
                                  _vm._v(" 上传视频 "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.videoPath != "" && _vm.videoPath != null
                            ? _c(
                                "a",
                                {
                                  staticStyle: { "margin-top": "20px" },
                                  attrs: {
                                    href: "" + _vm.videoPath,
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("已上传视频，点击预览")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "最低学习时长" } },
                        [
                          _c("a-input-number", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: ["viewTimes"],
                                expression: "['viewTimes']",
                              },
                            ],
                            staticStyle: { width: "120px" },
                            attrs: {
                              min: 0,
                              step: 1,
                              placeholder: "最低学习时长",
                            },
                          }),
                          _vm._v("(秒) "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "课时内容" } },
                        [
                          _c(
                            "keep-alive",
                            [
                              _vm.visible
                                ? _c("tinymce", {
                                    ref: "tinymceEditor",
                                    attrs: { value: _vm.obj.remark },
                                    on: { input: _vm.contentInput },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              style: {
                position: "absolute",
                right: 0,
                bottom: 0,
                width: "100%",
                borderTop: "1px solid #e9e9e9",
                padding: "10px 16px",
                background: "#fff",
                textAlign: "right",
                zIndex: 1,
              },
            },
            [
              _c(
                "a-button",
                {
                  style: { marginLeft: "8px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.doSubmit },
                },
                [_vm._v(" 确定 ")]
              ),
              _c(
                "a-button",
                { style: { marginLeft: "8px" }, on: { click: _vm.onClose } },
                [_vm._v(" 关闭 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }