<template>
    <div class="a_Approval">
        <a-form-model :model="params" class="clearfix" @submit="handleSubmit">
            <a-col :span="4" v-if="!isProject && !isBranchOffice">
                <a-form-model-item label="分公司" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">

                    <a-select v-model="params.branchOfficeId" allowClear placeholder="">
                        <!-- <a-select-option value="">全部</a-select-option> -->
                        <a-select-option v-for="item in onlyGcglbList" :key="item.value" :value="item.value"
                            :title="item.label">
                            {{ item.label }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
            </a-col>
            <a-col :span="5" v-if="!isProject">
                <a-form-model-item label="项目" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14, offset: 1 }">
                    <a-input v-model="params.projectName" allow-clear />
                </a-form-model-item>
            </a-col>

            <a-col :span="4">
                <a-space class="btnBox">
                    <a-button type="primary" html-type="submit"> 查询 </a-button>
                    <a-button type="primary" @click="addNewComplaint" v-if="isProject"> 新增 </a-button>
                </a-space>
            </a-col>
        </a-form-model>
        <standard-table :columns="columns" :dataSource="tableData" rowKey="id" :pagination="pagination"
            :loading="tableLoading" @change="handleChangePage" :isAlert="false" :bordered="true" :btnArr="tableBtnArr"
            :selectedRows.sync="selectedRows" :scroll="{ x: 1400 }">
            <!-- @selectedRowChange="handleSelectedRowsChange" :totalBoolean="true" :rowColor="false" -->
            <template slot="index" slot-scope="{ index }">
                <span>{{ (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1 }}</span>
            </template>
            <template slot="attach1" slot-scope="{ record }">
                <!-- {{ record }} -->
                <div class="flex j-center" style="flex-wrap: wrap;">
                    <div v-for="(item, index) in record.attachList1" :key="index">

                        <img alt="" style="width: 32px" v-if="item.type == 'img'" :src="item.url"
                            @click="handlePreview(item)" />

                        <a-icon type="file-pdf" :style="{ fontSize: '32px' }" v-else-if="item.type == 'pdf'"
                            @click="handlePreview(item)" />
                        <a-icon type="file-text" :style="{ fontSize: '32px' }" v-else
                            @click="handlePreview(item)" />
                    </div>
                </div>
            </template>
            <template slot="action" slot-scope="{ record }">
                <div class="sTables">
                    <div class="y-edit" @click="handleEdit(record)" v-if="isProject">编辑</div>
                    <div class="y-other" @click="handleDownload(record)">下载</div>
                    <div class="y-del" @click="handleDel(record)" v-if="isProject">删除</div>
                    <div class="y-other" @click="handleGo(record)">风险地图绘制情况详情</div>
                </div>
            </template>
        </standard-table>
        <detail :visible.sync="visible" :isEdit="isEdit" :projectInfo="projectInfo" :id="id" @closeDialog="closeDialog" @refresh="handleQuery" />

        <!-- 预览图片 -->
        <a-modal :visible="previewVisible" :footer="false" title="预览" @cancel="previewVisible= false">
            <img :src="previewImage" style="width: 100%" />
        </a-modal>

    </div>
</template>

<script>

import {
    riskmapList, riskmapDelete
} from "@/services/riskControll";

import { mapGetters } from "vuex";
import StandardTable from "@/components/table/StandardTable";
import moment from "moment";
import detail from "./components/gmDetail.vue";
import { getProjectDetail } from "@/services/equipmentLedger";

import mixin from './components/mixinGm'
import mixinRole from './components/mixinRole'

export default {
    name: "emerTeam",
    components: {
        StandardTable,
        detail
    },
    mixins: [mixin, mixinRole],
    data() {
        return {
            params: {
                "companyName": "", //公司名称
                "companyId": "",          //公司ID
                "projectName": "", //项目名称
                "projectId":"", //项目ID
                "branchOfficeId": "", //分公司ID
                "branchOfficeName": "", //分公司名称
                "pageNo": "1", //页码
                "pageSize": "10" //每页条数
            },
            pagination: {
                //分页数据
                current: 1,
                pageSize: 10,
                total: 0,
                showTotal: (total, range) => `共 ${total} 条`,
            },
            total: 0,
            tableData: [],
            tableLoading: false, //table加载中
            selectedRows: [],
            tmpSelectedRows: [],

            visible: false,
            isEdit: 0,
            id: "",

            projectInfo: {
                parentOffice: '',
                parentOfficeId: '',
                gcglbId: '',
                gcglbName: '',
                projectId: '',
                projectName: ''
            }

        };
    },
    created() {
        if(this.isProject) {
            let d = this.user.loginLaoWuUser.projectId
            getProjectDetail(d).then(res => {

                if (res.data.code === 0) {
                    
                    this.projectInfo.parentOfficeId = res.data.data.parentOfficeId;
                    this.projectInfo.parentOffice = res.data.data.parentOffice;
                    this.projectInfo.gcglbId = res.data.data.gcglbId;
                    this.projectInfo.gcglbName = res.data.data.gcglbName;
                    this.projectInfo.projectId = res.data.data.projectId;
                    this.projectInfo.projectName = res.data.data.projectName;
                    
                    this.params.companyName = this.projectInfo.parentOffice;
                    this.params.projectName = this.projectInfo.projectName;
                    this.params.branchOfficeName = this.projectInfo.gcglbName;
                    this.params.companyId = this.projectInfo.parentOfficeId;
                    this.params.branchOfficeId = this.projectInfo.gcglbId;
                    this.params.projectId = this.projectInfo.projectId;
                } 

                this.handleQuery();
            })
        } else {
            // this.columns = this.columns.filter(el => el.title != '操作')
            this.handleQuery();
        }
    },
    computed: {
        tableBtnArr() {
            return [
              
            ]
        }
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault();
            this.params.pageNo = 1
            this.pagination.current = 1
            this.handleQuery();
        },
        handleQuery(e) {
            this.tableData = []
            this.tableLoading = true
            let d = this.params;

            riskmapList(d).then((res) => {
                this.tableLoading = false
                if (res.data.code == 0) {
                    let list = res.data.data.list;
                    
                    this.tableData = list.map((item, index) => {
                        let attachList1 = this.calcAttach(item,'projectMap')
                        
                        return {
                            ...item,
                            attachList1
                        };
                    })
                    this.pagination.total = res.data.data.total;
                } else {
                    this.$message.error(res.data.msg)
                }
            });
        },
        handleChangePage(e) {
            this.params.pageNo = e.current;
            this.params.pageSize = e.pageSize;
            this.pagination.current = e.current;
            this.handleQuery();
        },
        addNewComplaint() {
            this.isEdit = 0
            this.id = ''
            this.visible = true
        },
        handleEdit(e) {

            this.isEdit = 1
            this.id = e.id
            this.visible = true
        },
        handleDel(record) {
            const _that = this
            this.$confirm({
                title: '是否确定删除？',
                cancelText: '否',
                okText: '是',
                onOk: () => {
                    riskmapDelete({ id: record.id }).then(res => {
                        if (res.data.code == 0) {
                            _that.$message.success('删除成功')
                            this.handleQuery(this.params);
                        } else {
                            _that.$message.error(res.data.msg)
                        }
                    })
                },
            })
        },
        resetQuery() {
            this.pagination.current = 1
            this.params = {
                "companyName": "", //公司名称
                "companyId": "",          //公司ID
                "projectName": "", //项目名称
                "projectId":"", //项目ID
                "branchOfficeId": "", //分公司ID
                "branchOfficeName": "", //分公司名称
                "pageNo": "1", //页码
                "pageSize": "10" //每页条数
            };
            if(this.isProject) {
                this.params.companyName = this.projectInfo.parentOffice;
                this.params.projectName = this.projectInfo.projectName;
                this.params.branchOfficeName = this.projectInfo.gcglbName;
                this.params.companyId = this.projectInfo.parentOfficeId;
                this.params.branchOfficeId = this.projectInfo.gcglbId;
                this.params.projectId = this.projectInfo.projectId;
            } else {
                // 公司  分公司
            }
            this.handleQuery();
        },
        closeDialog() {
            this.visible = false
        },
        handleDownload(record) {
            if(record.projectMap){
                window.open(record.projectMap)
            }else{
                this.$message.error('暂无文件')
            }
        },
        handleGo(record) {
            this.$router.push({name: '风险分级地图绘制情况', query: {id: record.id}})
        },
        handleCancel() {
            this.previewVisible = false;
        },

        

    },
};
</script>

<style lang="less" scoped>
.query-form {
    display: flex;
    // justify-content: space-between;
}

.post-border {
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 5px;
    padding-top: 5px;
    // padding-top: 10px;
    // height: 40px;
}

.post-border:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

.sTables {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.y-edit {
    color: #307dfa;
    cursor: pointer;
}
.y-del {
    color: #ff5c77;
    cursor: pointer;
}
.y-other {
    color: #615e83;
    cursor: pointer;
}
</style>