<template>
  <div class="committee">
    <div class="a_a_header mt-1">
      <form-index
        ref="formindex"
        :formData="formData"
        @handleSubmit="handleSubmit"
        :flexAction="true"
        @handlSelect="handlSelect"
      >
        <!-- 下部分按钮插槽 -->
        <template #flexAction>
          <a-space :style="{ marginTop: '4px' }">
            <a-button type="primary" icon="search" html-type="submit">
            查询
            </a-button>
            <a-space class="operator">
              <a-button @click="close"> 重置 </a-button>
            </a-space>
            <span style="color: red">注：处理预警请去“建工管理小程序”</span>
          </a-space>
        </template>
      </form-index>
    </div>
    <div class="flex a-center">
      <div>处理时限说明:</div>
      <div class="status-box flex">
        <div class="statusDrop">剩余1天</div>
        <div class="statusDrop before-second">剩余2-3天</div>
        <div class="statusDrop before-third">剩余>3天</div>
        <div class="statusDrop before-fourth">已超时</div>
      </div>
    </div>
    <!-- 表格封装 -->
    <standard-table
      class=""
      :columns="columns"
      :dataSource="dataSource"
      rowKey="id"
      :pagination="pagination"
      :loading="tableLoading"
      @change="onPageChange"
      :isAlert="false"
      :bordered="false"
      tableSize="middle"
    >
      <template slot="index" slot-scope="{ index }">
        <span>
          {{
            (pagination.current - 1) * pagination.pageSize + parseInt(index) + 1
          }}
        </span>
      </template>
      <template slot="bizType" slot-scope="{ text }">
        {{ text == 1 ? "实名制" : "工资分账" }}
      </template>
      <template slot="eventName" slot-scope="{ text }">
        {{ text }}
      </template>
      <template slot="warmCode" slot-scope="{ text, record }">
        <div class="statusDrop flex a-center" :class="textStyle(record)">
          {{ text }}
        </div>
      </template>
      <template slot="status" slot-scope="{ text }">
        {{ text | statusStr }}
      </template>
      <div slot="action" slot-scope="{ record }">
        <a-button type="link" @click="toHandle(record)">去处理</a-button>
      </div>
    </standard-table>
  </div>
</template>

<script>
import { useRender } from '@/hooks/useRender.js'
const formData = [
  {
    label: "预警时间",
    placeholder: "请选择时间段",
    decorator: [
      "applyDate",
      { rules: [{ required: false, message: "请选择时间段" }] },
    ],
    type: "rangePicker",
    key: "applyDate",
    col: 6,
    labelCol: 5,
    wrapperCol: 18,
    display: true,
    format: "yyyy-MM-DD",
  },
  {
    type: "action",
    col: 12,
    display: true,
  },
];
const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
  },
  {
    title: "预警单号",
    dataIndex: "warmCode",
    align: "center",
    width: 200,
    scopedSlots: { customRender: "warmCode" },
  },
  {
    title: "预警(编号)名称",
    dataIndex: "eventName",
    align: "center",
    scopedSlots: { customRender: "eventName" },
  },
  {
    title: "处理类别",
    dataIndex: "bizType",
    align: "center",
    scopedSlots: { customRender: "bizType" },
  },
  // {
  //   title: "责任企业",
  //   dataIndex: "enterpriseName",
  //   align: "center",
  //   scopedSlots: { customRender: "enterpriseName" },
  // },
  {
    title: "建委项目编码",
    dataIndex: "projectCode",
    align: "center",
    width: 300,
  },
  {
    title: "处理状态",
    dataIndex: "status",
    align: "center",
    ellipsis: true,
    scopedSlots: { customRender: "status" },
  },
  {
    title: "监理剩余天数",
    dataIndex: "lamjl",
    align: "center",
    key: "lamjl",
  },
  {
    title: "管辖区域",
    dataIndex: "enterDate1",
    align: "center",
    key: "enterDate1",
  },
  {
    title: "生成时间",
    dataIndex: "createDate",
    align: "center",
    key: "createDate",
    customRender: (text) => useRender.renderDate(text)
  },
  {
    title: "操作",
    align: "center",
    width: 120,
    scopedSlots: { customRender: "action" },
  },
];
import { mapGetters } from "vuex";
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import { getNoCloseWarnList, getProWarnList } from "@/services/projectManagement";
export default {
  components: {
    formIndex,
    StandardTable,
  },
  data() {
    return {
      formData: formData, //form表单数据
      columns: columns, //table数据key对应
      dataSource: [], //table数组
      pagination: {
        //分页数据
        current: 1,
        pageSize: 10,
        total: 0,
      },
      tableLoading: false,
      // 建委的预警参数
      prams: {
        type: 2,
        status: 4,
        pageNo: 1,
        pageSize: 10,
      },
    };
  },
  filters: {
    statusStr(val) {
      switch (val) {
        case "0":
          return "已关闭";
        case "1":
          return "黄灯";
        case "2":
          return "红灯";
        case "3":
          return "已超时";
        case "4":
          return "处理中";
      }
    },
  },
  computed: {
    ...mapGetters("account", ["user"]),
    textStyle() {
      return function (val) {
        if (val.lamzb == 0) {
          return "";
        } else if (val.lamzb == 1) {
          return "before-second";
        } else if (val.lamzb == 2) {
          return "before-third";
        } else if (val.lamzb == 3) {
          return "before-fourth";
        }
      };
    },
  },
  created() {
    this.aqxjlist(this.prams);
  },

  methods: {
    updateSelectRows(e) {
      console.log(e);
    },
    toHandle(e) {
      console.log(e);
      this.$router.push({
        path: "/alert_solve",
        query: {
          data: e
        },
      });
    },
    handlSelect(obj) {
      // const { val, keys } = obj
      // if (keys === 'officeId') {
      //   this.doQueryTeams({ officeId: val })
      // }
    },
    aqxjlist(prams) {
      this.tableLoading = true;
      getNoCloseWarnList(prams)
        .then((res) => {
          if (res.data.code === 0) {
            this.dataSource = res.data.data.list;
            this.pagination.total = res.data.data.total;
          }
          this.tableLoading = false;
        })
        .catch((e) => {
          this.tableLoading = false;
          this.$message.error("获取预警列表失败",2);
        });  
    },
    // 提交表单数据 查询
    handleSubmit(e) {
      let obj = { ...e, ...this.prams };
      // obj.applyDate = obj.applyDate === '' ? [] : obj.applyDate
      this.aqxjlist(obj);
    },
    //表格 - 分页查询
    onPageChange(e) {
      // let obj = this.$refs["formindex"].getFieldValue();
      // if (obj.status == "") {
      //   obj.status = 0;
      // }
      // this.prams.pageNumber = e.current;
      // this.pagination.current = e.current;
      // this.aqxjlist(Object.assign({}, this.prams, obj));
    },
    close() {
      this.prams = {
        type: 2,
        status: 4,
        pageNo: 1,
        pageSize: 10,
      };
      this.pagination.current = 1
      this.aqxjlist(this.prams);
      this.$refs["formindex"].resetFields();
    },
  },
};
</script>

<style lang="less" scoped>
.committee {
  color: #000000;
  font-family: Microsoft YaHei;
  font-size: 14px;
  .status-box {
    color: #4d4d4d;
  }
  // 预警状态
  .statusDrop::before {
    width: 8px;
    height: 8px;
    background: #ee6945;
    content: "";
    display: inline-block;
    border-radius: 50%;
    margin-left: 15px;
    margin-right: 3px;
  }
  .before-second::before {
    background: #e5a858;
  }
  .before-third::before {
    background: #6ccc51;
  }
  .before-fourth::before {
    background: #656262;
  }
}

.plug-form .ant-form .ant-btn {
  // 处理表单组件强制margin-right问题
  margin-right: 0
}
</style>
