<template>
  <div>
    <a-row :gutter="16">
      <a-col :span="6">
        <vue-scroll :ops="ops" style="width:100%;height:855px">
        <a-card title="课程管理" size="small" :bordered="false" >
          <a-tree :load-data="onLoadData" :tree-data="treeData" show-icon default-expand-all @select="onSelect" >
            <img src="@/assets/img/type.png" style="width:20px;height:20px"  slot="typeIcon" />
            <img src="@/assets/img/course.png" style="width:20px;height:20px"  slot="courseIcon" />
            <template slot="title" slot-scope="{ title }">
                <span>{{ title }}</span>
            </template>
          </a-tree>
        </a-card>
        </vue-scroll>
      </a-col>
      <a-col :span="17" :offset="0">
          <a-card  :bordered="false" style="margin-right:-75px">
            <template slot="title" >
              <span>{{node.title}}试卷清单</span>
              <!-- <span style="float:right">
                <a style="float:right;margin-top:3px;margin-left:10px;font-size:13px" href="https://sjlw-templ.oss-cn-shenzhen.aliyuncs.com/enterprise-persons/import?Expires=1954460648&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=uwdRAtJbqH5fXT7xkQByjj%2BCPoE%3D">模板下载</a>
                <a-upload
                      name="file"
                      :multiple="false"
                      :beforeUpload="beforeUpload"
                      accept=".xls,.xlsx"
                      :customRequest="resourceUpload"
                      :file-list="fileListFile"
                  >
                    <a-button :disabled="importLoading" style="margin-left:10px" >导入试卷</a-button>
                  </a-upload>
              </span> -->
              </template>
            <a-form class="clearfix">
              <a-col :span="5">
                <a-form-item
                  label="试卷标题"
                  :labelCol="{span: 8}"
                  :wrapperCol="{span: 14, offset: 1}"
                >
                  <a-input placeholder="试卷标题" v-model="queryParam.title"/>
                </a-form-item>
              </a-col>
              <a-col :span="5">
                <a-form-item
                  label="发布状态"
                  :labelCol="{span: 8}"
                  :wrapperCol="{span: 14, offset: 1}"
                >
                  <a-select v-model="queryParam.status" placeholder="请选择">
                    <a-select-option value="">全部</a-select-option>
                    <a-select-option :value="0">未发布</a-select-option>
                    <a-select-option :value="1">已发布</a-select-option>
                    <a-select-option :value="2">已关闭</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="2">
                <a-space class="btnBox">
                  <a-button type="primary" @click="doQuery()">查询</a-button>
                </a-space>
              </a-col>
            </a-form>
            <a-row >
              <a-col :md="24" :sm="24">
                <standard-table
                  :columns="columns"
                  :dataSource="dataSource"
                  :selectedRows.sync="selectedRows"
                  :pagination="pagination"
                  :loading="tableLoading"
                  :isAlert="false"
                  @clear="onClear"
                  @change="onPageChange"
                  :bordered="true"
                  :btnArr="tableBtnArr"
                >
                  <div slot="action" slot-scope="{record}">
                    <a-icon style="color:blue" type="edit" @click="editorPaper(record)" title="编辑"></a-icon>
                     <a-divider type="vertical" />
                    <a-icon style="color:green" type="eye" @click="viewPaperFn(record)" title="试卷预览"></a-icon>
                    <a-divider type="vertical" />
                   <img src="@/assets/img/publish.png" v-if="record.status==0"  @click="modifyPaper(record,1)" title="发布"/>
                    <a-divider v-if="record.status==0" type="vertical" />
                   <img src="@/assets/img/cancel.png" v-if="record.status==1" @click="modifyPaper(record,0)" title="取消发布"/>
                    <a-divider v-if="record.status==1" type="vertical" />
                    <a-icon style="color:red" type="delete" @click="deletePaper(record)" title="删除"></a-icon>
                  </div>
                  <template slot="statusTitle">
                    <a-icon @click.native="onStatusTitleClick" type="info-circle" />
                  </template>
                </standard-table>
                </a-col>
            </a-row>
          </a-card>
      </a-col>
    </a-row>
    <editor-paper
      :testPaper="testPaper.obj"
      :visible="testPaper.visible"
      :type="testPaper.type"
      @questPageClose="editorPageColse"
      @submit="doSubmitPaper"
    ></editor-paper>
    <auto-paper
      :visible="auto.visible"
      :oldCourseId="auto.oldCourseId"
      @closeAutoSetting="closeAutoSetting"
      @submit="autoSubmit"
    ></auto-paper>
    <view-paper
      :visible="viewPaper.visible"
      :sigleQuests="viewPaper.sigleQuests"
      :multipleQuests="viewPaper.multipleQuests"
      :judgeQuests="viewPaper.judgeQuests"
      :paperTitle="viewPaper.paperTitle"
      :paperId="viewPaper.paperId"
      @doCloseViewPaper="doCloseViewPaper"
      @submit="autoSubmit"
    ></view-paper>
  </div>
</template>


<script>
import {
  ONLINE_TYPE_QUERY,
  ONLINE_QUERY_COURSE_GETALL,
  ONLINE_TEST_PAPER_QUERY,
  ONLINE_TEST_PAPER_UPDATE,
  ONLINE_TEST_PAPER_ADD,
  ONLINE_TEST_PAPER_DELETE,
  ONLINE_TEST_PAPER_MODIFY,
  ONLINE_PAPER_QUEST_QUERY,
  ONLINE_QUESTION_OPTIONS,
  } from '@/services/api'

import {request} from '@/utils/request'
import StandardTable from '@/components/table/StandardTable'
import editorPaper from '@/pages/onlineedu/editorPaper'
import AutoPaper from '@/pages/onlineedu/autopaper'
import ViewPaper from '@/pages/onlineedu/viewpaper'
import { useRender } from '@/hooks/useRender.js'

const columns = [
  { title: '试卷标题', dataIndex: 'title',key:'1',ellipsis:true},
  { title: '试卷状态', dataIndex: 'status',key:'2', align: 'center' ,customRender: (text) => {
    if(text==0){
      return "未发布";
    }else if(text==1){
      return "已发布";
    }else if(text==2){
      return "已关闭";
    }else{
      return "--";
    }}},
  { title: '题目/分数', dataIndex: 'cnts',key:'3' , align: 'center',customRender: (text,recode) => {
      return text+"题/"+recode.cores+"分";}},
  { title: '更新时间', dataIndex: 'createTime',key:'4' , align: 'center', customRender: (text) => useRender.renderDate(text) },
  { id:4,title: '操作', dataIndex: '',key:'5', scopedSlots: { customRender: 'action' }, align: 'center' },
];

export default {
  name: 'QueryList',
  components: {StandardTable,editorPaper,AutoPaper,ViewPaper},
  // components: {StandardTable},
  data() {
    return {
      columns,
      selectedRows: [],
      dataSource:[],
      tableLoading: false,
      advanced:false,
      importLoading:false,
      fileListFile:[],
      queryParam:{
        title:null,
        status:null,
        courseId:null,
        pageNumber:0,
      },
      autoExpandParent: true,
      // 树形结构
      expandedKeys: [],
      treeData: [],
      node:{},
      testPaper:{
        obj:{},
        visible:false,
        type:'1'
      },
      auto:{
        oldCourseId:null,
        visible:false,
      },
      viewPaper:{
        visible:false,
        sigleQuests:[],
        multipleQuests:[],
        judgeQuests:[],
        paperTitle:"试卷预览",
        paperId:null
      },
      pagination:{
        current:1,
        pageSize:10,
        total:0,
        showLessItems:true,
        showQuickJumper:true
      },
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: "#4A86EB",//滚动条颜色
          opacity: 0.8,//滚动条透明度
          "overflow-x": "hidden"
        }
      },
      tableBtnArr: [
        {
          name: '手动组卷',
          attrs: {
            type: 'primary'
          },
          click: () => this.editorPaper({})
        },
        {
          name: '自动组卷',
          attrs: {
            type: 'primary'
          },
          click: this.autoPaper
        }
      ]
    };
  },
  created() {
    this.queryTypeWork();
    this.doQuery();
  },
  methods: {
    autoPaper(){
      this.auto.visible=true;
    },
    editorPageColse(){
      this.testPaper.visible=false;
      this.testPaper.obj={}
    },
    editorPaper(recode){
      if(recode&&recode.courseId){
        this.testPaper.obj=recode
      }else{
        if(this.node&&this.node.nodeType==2){
          this.testPaper.obj.courseId=this.node.id
        }else{
          this.testPaper.obj={};
        }
      }
      this.testPaper.type = '0';
      this.testPaper.visible=true;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    handleChange(value) {
      console.log(`selected ${value}`);
    },
    onClear() {
      // this.$message.info('您清空了勾选的所有行')
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    doReset(){
      this.queryParam={};
      this.doQuery();
    },

    /**
    * 上传之前
    */
    beforeUpload (file) {
      return new Promise((resolve, reject) => {
        const isFiveM= file.size / 1024 / 1024 < 5 //小于5M的图片上传
        //判断文件是否符合正则表达式的规则
        if (!isFiveM) {
          this.$message.error('上传资源不大于5M！');
          return reject(false)
        }else{
          this.fileListFile=[];
          this.fileListFile.push(file)
          return resolve(true);
        }
      });
    },
    /**
    * 资源上传
    */
    resourceUpload(value){
        this.importLoading = true;
        const formData = new FormData();
        formData.append('file', value.file);
        // request(PERSON_ENTERPRISE_IMPORT,"post",formData).then(result => {
        //   this.importLoading = false;
        //   if (result.data.code==0) {
        //     if( result.data.data!=null&& result.data.data.length>0){
        //       const h = this.$createElement;
        //       let msg = [];
        //       result.data.data.forEach(o=>{
        //           msg.push(h('div',o.userName))
        //       })

        //       this.$warning({ title: '以下人员导入异常：', content:  h('div', {}, msg) });
        //     }else{
        //       this.$message.success("导入成功");
        //     }
        //     value.onSuccess(result, value.file);
        //   }else{
        //     this.$message.warning(result.message);
        //   }
        // })
    },
    //分页查询
    onPageChange(e){
      this.queryParam.pageNumber = e.current;
      this.pagination.current=e.current;
      this.doQuery();
    },
    onLoadData(treeNode) {
      return new Promise(resolve => {
        setTimeout(() => {
          request(ONLINE_QUERY_COURSE_GETALL,"post",{typeId:treeNode.dataRef.id}).then(res => {
            if(res.data.code==0){
              treeNode.dataRef.children = [];
              res.data.data.list.forEach(obj=>{
                obj.isLeaf = true;
                obj.title = obj.name;
                obj.key = `${treeNode.eventKey}-${obj.id}`
                obj.nodeType = "2";
                obj.slots={icon: 'courseIcon'}
                treeNode.dataRef.children.push(obj);
              })
            }
            this.treeData = [...this.treeData];
            resolve();
          })
        }, 500);
      });
    },
    onSelect(selectedKeys,e){
      this.tableLoading = true;
      if(e.node&&e.node.dataRef){
        this.node = e.node.dataRef
      }
      if(this.node&&this.node.nodeType==2){
        this.queryParam.courseId = this.node.id
        this.auto.oldCourseId = this.node.id
        this.doQuery();
      }
      this.tableLoading = false;
    },
    doQuery(){
      request(ONLINE_TEST_PAPER_QUERY,"post",this.queryParam).then(res => {
        if(res.data.code==0){
          res.data.data.list.forEach(obj=>{
            obj.key = obj.id
          })
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
        }
      })
      this.tableLoading = false;
    },
    doSubmitPaper($event){
      let path = $event.id?ONLINE_TEST_PAPER_UPDATE:ONLINE_TEST_PAPER_ADD;
      request(path,"post",$event).then(res => {
        if(res.data.code==0){
          this.doQuery();
          this.testPaper.visible = false;
          this.testPaper.obj = {};
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    queryTypeWork(){
      request(ONLINE_TYPE_QUERY,"post",{}).then(res => {
        if(res.data.code==0){
          this.treeData = res.data.data.list;
          this.treeData.forEach(obj=>{
            obj.title = obj.name;
            obj.key = obj.id;
            obj.isLeaf = false;
            obj.nodeType = "1";
            obj.slots={icon: 'typeIcon'}
          })
        }
      })
    },
    modifyPaper(recode,type){
      let obj = {
        id:recode.id,
        status:type
      }
      request(ONLINE_TEST_PAPER_MODIFY,"post",obj).then(res => {
        if(res.data.code==0){
          this.doQuery();
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    deletePaper(recode){
      var _that = this
      this.$confirm({
          title: '删除选择题',
          content: '您好，您确认删除该选择题吗？',
          okText: '仍旧删除',
          cancelText: '再想想',
          onOk() {
                request(ONLINE_TEST_PAPER_DELETE+recode.id, "delete", {}).then((res) => {
                if (res.data.code === 0) {
                  _that.$message.success("删除成功！");
                  _that.doQuery();
                }else{
                  _that.$message.error("删除异常！");
                }
              });
          },
          onCancel() {
              console.log('Cancel');
          },
      });
    },
    closeAutoSetting(){
      this.auto.visible=false;
    },
    autoSubmit($event){
      let objParams = {
        title:$event.autoObj.title,
        pExplain:$event.autoObj.pexplain,
        isAuto:1,
        autoId:$event.autoObj.id,
        courseId:$event.autoObj.courseId,
        judgeQuests:$event.judgeQuests,
        multipleQuests:$event.multipleQuests,
        sigleQuests:$event.sigleQuests,
      }
      request(ONLINE_TEST_PAPER_ADD,"post",objParams).then(res => {
        if(res.data.code==0){
          this.doQuery();
          this.auto.visible=false;
        }else{
          this.$message.error(res.data.msg);
        }
      })

    },
    doCloseViewPaper(){
      this.viewPaper.sigleQuests = [];
      this.viewPaper.multipleQuests = [];
      this.viewPaper.judgeQuests = [];
      this.viewPaper.paperTitle = "试卷预览";
      this.viewPaper.paperId='';
      this.viewPaper.visible = false;
    },
    viewPaperFn(recode){
      this.viewPaper.paperTitle = recode.title;
      this.viewPaper.paperId=recode.id;
      request(ONLINE_PAPER_QUEST_QUERY+recode.id,"get",{}).then(res => {
        if(res.data.code==0){
          this.viewPaper.judgeQuests = res.data.data.judgeQuests;
          this.viewPaper.sigleQuests = res.data.data.sigleQuests
          this.viewPaper.multipleQuests = res.data.data.multipleQuests
          let chooseIdsArr = [];
          this.viewPaper.sigleQuests.forEach(o=>{
            chooseIdsArr.push(o.id);
          })
          this.viewPaper.multipleQuests.forEach(o=>{
            chooseIdsArr.push(o.id);
          })
          request(ONLINE_QUESTION_OPTIONS,"post",chooseIdsArr).then(res2 => {
            if(res.data.code==0){
              this.viewPaper.sigleQuests.forEach(obj=>{
                obj.optionsList = res2.data.data[obj.id];
              })
              this.viewPaper.multipleQuests.forEach(obj=>{
                obj.optionsList = res2.data.data[obj.id];
              })
            }
          })
          this.viewPaper.visible = true;
        }else{
          this.$message.error("获取题目信息异常,请检查试卷是否有题目或者联系管理员");
          return;
        }
      })
    }
  },
}
</script>

<style lang="less" scoped>
  .search{
    margin-bottom: 54px;
  }
  .fold{
    width: calc(100% - 85px);
    display: inline-block
  }
  .operator{
    margin-bottom: 18px;
  }
  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
</style>
