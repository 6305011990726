var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.qrImg != ""
    ? _c(
        "a-col",
        { attrs: { span: 4 } },
        [
          _vm._v(" 特殊激活小程序码（项目慎用）： "),
          _c("img", {
            staticStyle: { width: "160px" },
            attrs: { src: _vm.qrImg },
            on: {
              click: function ($event) {
                return _vm.showImg(_vm.qrImg)
              },
            },
          }),
          _c(
            "a-modal",
            {
              attrs: { visible: _vm.previewVisible, footer: null },
              on: { cancel: _vm.closeImgShow },
            },
            [
              _c("img", {
                staticStyle: { width: "100%" },
                attrs: { alt: "example", src: _vm.qrImg },
              }),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }