var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "create_index" },
    [
      _c("form-index", {
        ref: "formindex",
        attrs: {
          chageT: true,
          formData: _vm.formData,
          projectList: _vm.projectList,
          Overview: "新增参建单位",
          bottomSubmit: true,
        },
        on: {
          handleSubmit: _vm.handleSubmit,
          handlSelect: _vm.handlSelect,
          handleChange: _vm.handleChange,
        },
        scopedSlots: _vm._u([
          {
            key: "bottomSubmit",
            fn: function () {
              return [
                _c(
                  "a-button",
                  {
                    attrs: {
                      icon: "save",
                      type: "primary",
                      "html-type": "submit",
                    },
                  },
                  [_vm._v(" 确定 ")]
                ),
                _c(
                  "a-space",
                  { staticClass: "operator" },
                  [
                    _c(
                      "a-button",
                      { attrs: { icon: "close" }, on: { click: _vm.close } },
                      [_vm._v(" 关闭 ")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }