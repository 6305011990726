<template>
  <div style="background: #ECECEC; padding: 30px">
    <a-row :gutter="16" >
        <a-col :span="12">
          <a-card
            style="width:100%;height:280px"
            :tab-list="tabListNoTitle"
            :active-tab-key="noTitleKey"
            @tabChange="key => onTabChange(key, 'noTitleKey')"
          >
            <p v-if="noTitleKey === 'office'">
              <a-row :gutter="16" style="padding-left:5%">
                <a-col :span="6">
                  <a-statistic
                    title="检查项目总数"
                    :value="gkObj.checks"

                    suffix="个"
                    :value-style="{ color: 'blue' }"
                  />
                </a-col>
                <a-col :span="6">
                  <a-statistic
                    title="一般隐患数"
                    :value="`${yhzs-gkObj.zdyhs}`"
                    suffix="个"
                    :value-style="{ color: '#cf1322' }"
                  />
                </a-col>
                <a-col :span="6">
                  <a-statistic
                      title="重大隐患数"
                      suffix="个"
                      :value="gkObj.zdyhs"
                      :value-style="{ color: '#cf1322' }"
                  />
                </a-col>
                <a-col :span="6">
                  <a-statistic
                    title="隐患整改率"
                    :value="gkObj.zgl"
                  />
                </a-col>

<!--                <a-col :span="6">-->
<!--                  <a-statistic-->
<!--                    title="超时未整改数"-->
<!--                    :value="`${gkObj.cswzgs|0}`"-->
<!--                    suffix="个"-->
<!--                    :value-style="{ color: '#cf1322' }"-->
<!--                  />-->
<!--                </a-col>-->

              </a-row>
              <a-row :gutter="16" style="padding-left:5%">
                <a-col :span="6">
                  <a-statistic
                    title="检查总数"
                    :value="totalChecks"
                    suffix="次"
                    :value-style="{ color: 'green' }"
                  />
                </a-col>
                <a-col :span="6">
                  <a-statistic
                    title="日常检查总数"
                    :value="`${gkObj.checks-gkObj.zxjc}`"
                    suffix="次"
                    :value-style="{ color: 'green' }"
                  />
                </a-col>
                <a-col :span="6">
                  <a-statistic
                    title="专项检查总数"
                    :value="`${gkObj.zxjc|0}`"
                    suffix="次"
                    :value-style="{ color: 'purple' }"
                  />
                </a-col>
                <a-col :span="6">
                  <a-statistic
                    title="带班检查总数"
                    :value="`${gkObj.dbCnt|0}`"
                    suffix="次"
                    :value-style="{ color: '#000' }"
                  />
                </a-col>
              </a-row>
              <a-row :gutter="16" style="padding-left:5%">
                <a-col :span="6">
                  <a-statistic
                      title="安全验收总数"
                      :value="`${gkObj.aqyscnt|0}`"
                      suffix="份"
                      :value-style="{ color: 'green' }"
                  />
                </a-col>
                <a-col :span="6">
                  <a-statistic
                      title="停工通知书总数"
                      :value="`${gkObj.tgdcnt|0}`"
                      suffix="份"
                      :value-style="{ color: 'green' }"
                  />
                </a-col>
                <a-col :span="6">
                  <a-statistic
                      title="处罚通知书总数"
                      :value="`${gkObj.cfdcnt|0}`"
                      suffix="份"
                      :value-style="{ color: 'green' }"
                  />
                </a-col>
                <a-col :span="6">
                  <a-statistic
                      title="安全生产约谈总数"
                      :value="`${gkObj.ytcnt|0}`"
                      suffix="次"
                      :value-style="{ color: 'purple' }"
                  />
                </a-col>

              </a-row>
            </p>
            <p v-else-if="noTitleKey === 'gcglb'">
              <a-row :gutter="16" style="padding-left:5%">
                <a-col :span="6">
                  <a-statistic
                    title="检查项目总数"
                    :value="gcglbObj.projects"

                    suffix="个"
                    :value-style="{ color: 'blue' }"
                  />
                </a-col>
                <!-- <a-col :span="6">
                  <a-statistic
                    title="隐患总数"
                    :value="gcglbYhzs"
                    suffix="个"
                    :value-style="{ color: '#cf1322' }"
                  />
                </a-col> -->
                <a-col :span="6">
                  <a-statistic
                    title="一般隐患"
                    :value="`${gcglbYhzs-gcglbObj.zdyhs}`"
                    suffix="个"
                    :value-style="{ color: '#cf1322' }"
                  />
                </a-col>
                <a-col :span="6">
                  <a-statistic
                    title="重大隐患"
                    :value="`${gcglbObj.zdyh|0}`"
                    suffix="个"
                    :value-style="{ color: '#cf1322' }"
                  />
                </a-col>
                <a-col :span="6">
                  <a-statistic
                    title="隐患整改率"
                    :value="gcglbObj.zgl"
                  />
                </a-col>

                <!-- <a-col :span="6">
                  <a-statistic
                    title="超时未整改数"
                    :value="`${gcglbObj.cswzgs|0}`"
                    suffix="个"
                    :value-style="{ color: '#cf1322' }"
                  />
                </a-col> -->

              </a-row>
              <a-row :gutter="16" style="padding-left:5%">
                <a-col :span="6">
                  <a-statistic
                      title="安全验收总数"
                      :value="`${gkObj.aqyscnt|0}`"
                      suffix="份"
                      :value-style="{ color: 'green' }"
                  />
                </a-col>
                <a-col :span="6">
                  <a-statistic
                    title="检查总数"
                    :value="gcglbObj.checks"
                    suffix="次"
                    :value-style="{ color: 'green' }"
                  />
                </a-col>
                <a-col :span="6">
                  <a-statistic
                    title="日常检查总数"
                    :value="`${isNaN(gcglbObj.checks-gcglbObj.zxjc)?0:gcglbObj.checks-gcglbObj.zxjc}`"
                    suffix="次"
                    :value-style="{ color: 'green' }"
                  />
                </a-col>
                <a-col :span="6">
                  <a-statistic
                    title="专项检查总数"
                    :value="`${gcglbObj.zxjc|0}`"
                    suffix="次"
                    :value-style="{ color: 'purple' }"
                  />
                </a-col>
              </a-row>
            </p>
            <div slot="tabBarExtraContent" style="float:right;max-width:350px">
                <div style="max-width: 60%;float:right">
                <a-range-picker v-if="monthQueryParams.queryType==3"  v-model="monthQueryParams.plan_date" :default-value="[moment(firstDay, dateFormat), moment(nowDay, dateFormat)]"
                      :format="dateFormat"  @change="mtChangeDate"/>
                <a-month-picker style="width: 100px" v-if="monthQueryParams.queryType==2"  @change="mtChangeDate" placeholder="请选择月份" :default-value="moment(nowMonth, monthFormat)" :format="monthFormat"  v-model="monthQueryParams.plan_date">
                  <a-icon slot="suffixIcon" type="smile"/>
                </a-month-picker>
                <a-date-picker
                  v-if="monthQueryParams.queryType==1"
                  mode="year"
                  v-model="monthQueryParams.plan_date"
                  placeholder="请输入年份"
                  :default-value="moment(nowYear, yearFormat)" :format="yearFormat"
                  style="width: 100px"
                  :open="monthQueryParams.yearShowOne"
                  @openChange="monthOpenChangeOne"
                  @panelChange="monthPanelChangeOne"/>
              </div>
              <a-select  v-model="monthQueryParams.queryType" style="width: 80px;float:right;top:12px" @change="mtChangeTypeDate">
                <a-select-option :key="1"> 年 </a-select-option>
                <a-select-option :key="2"> 月 </a-select-option>
                <a-select-option :key="3">其他</a-select-option>
              </a-select>
            </div>
          </a-card>
          <!-- <a-card title="公司巡检概况" style="height:250px">
            <div style="float:right" slot="extra">
              <div style="max-width: 60%;float:right">
                <a-range-picker v-if="monthQueryParams.queryType==3"  v-model="monthQueryParams.plan_date" :default-value="[moment(firstDay, dateFormat), moment(nowDay, dateFormat)]"
                      :format="dateFormat"  @change="mtChangeDate"/>
                <a-month-picker style="width: 100px" v-if="monthQueryParams.queryType==2"  @change="mtChangeDate" placeholder="请选择月份" :default-value="moment(nowMonth, monthFormat)" :format="monthFormat"  v-model="monthQueryParams.plan_date">
                  <a-icon slot="suffixIcon" type="smile"/>
                </a-month-picker>
                <a-date-picker
                  v-if="monthQueryParams.queryType==1"
                  mode="year"
                  v-model="monthQueryParams.plan_date"
                  placeholder="请输入年份"
                  :default-value="moment(nowYear, yearFormat)" :format="yearFormat"
                  style="width: 100px"
                  :open="monthQueryParams.yearShowOne"
                  @openChange="monthOpenChangeOne"
                  @panelChange="monthPanelChangeOne"/>
              </div>
              <a-select  v-model="monthQueryParams.queryType" style="width: 80px;float:right" @change="mtChangeTypeDate">
                <a-select-option :key="1"> 年 </a-select-option>
                <a-select-option :key="2"> 月 </a-select-option>
                <a-select-option :key="3">其他</a-select-option>
              </a-select>

            </div>

          </a-card> -->
        </a-col>
        <!-- <a-col :span="12">
          <a-card title="本月隐患总数排行（TOP10）" style="height:250px">
              <div style="float:right" slot="extra">
                <a-month-picker :default-value="moment(nowMonth, monthFormat)" :format="monthFormat" @change="queryYhzsTop10" :allowClear="false"/>
              </div>
              <vue-scroll :ops="ops" style="width:100%;height:137px">
                <a-list bordered :data-source="dataSourceYhs" item-layout="horizontal">
                  <a-list-item slot="renderItem" slot-scope="item,index">
                    <a-list-item-meta>
                      <template slot="description"><strong>NO.{{index+1}}</strong>:{{item.projectName | ellipsis}}<strong style="float:right;color:#cf1322">{{item.cnt}}个</strong></template>
                    </a-list-item-meta>
                  </a-list-item>
                </a-list>
            </vue-scroll>
          </a-card>
        </a-col> -->
        <a-col :span="12">
          <a-card title='项目月度自检情况' style="height:280px">
            <div style="float:right" slot="extra">
              <!-- <div style="max-width: 60%;float:right"> -->
                <!-- <a-range-picker v-if="proQueryParams.queryType==3"  v-model="proQueryParams.plan_date" :default-value="[moment(firstDay, dateFormat), moment(nowDay, dateFormat)]"
                      :format="dateFormat"  @change="proChangeDate"/> -->
                <a-month-picker style="width: 100px"  @change="proChangeDate" placeholder="请选择月份" :default-value="moment(nowMonth, monthFormat)" :format="monthFormat"  v-model="proQueryParams.plan_date">
                  <a-icon slot="suffixIcon" type="smile"/>
                </a-month-picker>
                <!-- <a-date-picker
                  v-if="proQueryParams.queryType==1"
                  mode="year"
                  v-model="proQueryParams.plan_date"
                  placeholder="请输入年份"
                  :default-value="moment(nowYear, yearFormat)" :format="yearFormat"
                  style="width: 100px"
                  :open="proQueryParams.yearShowOne"
                  @openChange="proOpenChangeOne"
                  @panelChange="proPanelChangeOne"/> -->
              <!-- </div> -->
              <!-- <a-select  v-model="proQueryParams.queryType" style="width: 80px;float:right" @change="proTop10Change">
                <a-select-option :key="1"> 年 </a-select-option>
                <a-select-option :key="2"> 月 </a-select-option>
                <a-select-option :key="3">其他</a-select-option>
              </a-select> -->

            </div>
            <vue-scroll :ops="ops" style="width:100%;height:160px">
              <a-list bordered :data-source="dataSource" item-layout="horizontal" >
                <a-list-item slot="renderItem" slot-scope="item,index">
                  <a-list-item-meta>
                    <template slot="description">{{index+1}}.{{item.projectName}}</template>

                  </a-list-item-meta>
                  <a>{{item.cnt}}次</a>
                </a-list-item>
              </a-list>
            </vue-scroll>
          </a-card>
        </a-col>
      </a-row>
      <a-row :gutter="16" style="margin-top:5px">
        <a-col :span="12">
          <a-row :gutter="16">
            <a-col :span="24">
              <a-card>
                <span slot="title">{{gcglbtitle||'公司' }}检查次数统计（总计：{{checkTimeTotal}}次）</span>
                <div style="float:right" slot="extra">
                  <div style="max-width: 60%;float:right">
                    <a-range-picker v-if="xjTimesQueryParams.queryType==3"  v-model="xjTimesQueryParams.plan_date" :default-value="[moment(firstDay, dateFormat), moment(nowDay, dateFormat)]"
                          :format="dateFormat"  @change="xjTimesChangeDate"/>
                    <a-month-picker style="width: 100px" v-if="xjTimesQueryParams.queryType==2"  @change="xjTimesChangeDate" placeholder="请选择月份" :default-value="moment(nowMonth, monthFormat)" :format="monthFormat"  v-model="xjTimesQueryParams.plan_date">
                      <a-icon slot="suffixIcon" type="smile"/>
                    </a-month-picker>
                    <a-date-picker
                      v-if="xjTimesQueryParams.queryType==1"
                      mode="year"
                      v-model="xjTimesQueryParams.plan_date"
                      placeholder="请输入年份"
                      :default-value="moment(nowYear, yearFormat)" :format="yearFormat"
                      style="width: 100px"
                      :open="xjTimesQueryParams.yearShowOne"
                      @openChange="xjTimesOpenChangeOne"
                      @panelChange="xjTimesPanelChangeOne"/>
                  </div>
                  <a-select  v-model="xjTimesQueryParams.queryType" style="width: 80px;float:right" @change="checkTimeTypeChange">
                    <a-select-option :key="1"> 年 </a-select-option>
                    <a-select-option :key="2"> 月 </a-select-option>
                    <a-select-option :key="3">其他</a-select-option>
                  </a-select>

                </div>
                <!-- <div style="float:right" slot="extra">
                  <a-select v-model="checkTimeType" style="width: 100px" @change="checkTimeChange">
                    <a-select-option :key="1"> 当月每天 </a-select-option>
                    <a-select-option :key="2"> 当年每月 </a-select-option>
                  </a-select>
                </div> -->
                <div id="checkTimesChart" style="width:98%;height:300px"></div>
              </a-card>
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :span="24">
                <a-card>
                  <div style="float:right" slot="extra">
                      <a-date-picker
                        mode="year"
                        v-model="zglParams.plan_date"
                        placeholder="请输入年份"
                        :default-value="moment(nowYear, yearFormat)" :format="yearFormat"
                        style="width: 100px"
                        :open="zglParams.yearShowOne"
                        @openChange="zglOpenChangeOne"
                        @panelChange="zglPanelChangeOne"/>
                  </div>
                  <span slot="title">{{gcglbtitle||'公司' }}每月隐患整改率统计情况  （整改率：{{yhzglTotal||0}}）</span>
                  <div id="yhzglChart" style="width:98%;height:300px"></div>
                </a-card>
            </a-col>
          </a-row>

        </a-col>
        <a-col :span="12">
          <a-card>
            <span slot="title">{{gcglbtitle||'公司' }}检查隐患类型统计  （总计：{{yhzsTotal}}个）</span>
            <div style="float:right" slot="extra">
              <div style="max-width: 60%;float:right">
                <a-range-picker v-if="queryParams.type==3"  v-model="queryParams.plan_date" :default-value="[moment(firstDay, dateFormat), moment(nowDay, dateFormat)]"
                      :format="dateFormat"  @change="changeDate"/>
                <a-month-picker style="width: 100px" v-if="queryParams.type==2"  @change="changeDate" placeholder="请选择月份" :default-value="moment(nowMonth, monthFormat)" :format="monthFormat"  v-model="queryParams.plan_date">
                  <a-icon slot="suffixIcon" type="smile"/>
                </a-month-picker>
                <a-date-picker
                  v-if="queryParams.type==1"
                  mode="year"
                  v-model="queryParams.plan_date"
                  placeholder="请输入年份"
                  :default-value="moment(nowYear, yearFormat)" :format="yearFormat"
                  style="width: 100px"
                  :open="yearShowOne"
                  @openChange="openChangeOne"
                  @panelChange="panelChangeOne"/>
              </div>
              <a-select  v-model="queryParams.type" style="width: 80px;float:right" @change="yhzsTypeChange">
                <a-select-option :key="1"> 年 </a-select-option>
                <a-select-option :key="2"> 月 </a-select-option>
                <a-select-option :key="3">其他</a-select-option>
              </a-select>

            </div>
            <!-- <div id="yhzsChart" style="width:98%;height:398px"></div> -->
            <div id="yhzsPieChart" style="width:98%;height:398px"></div>
            <a-divider>隐患分布情况</a-divider>
            <div style="width:98%;height:260px">
              <a-table
                :bordered="true"
                :columns="columns"
                :dataSource="dataSourceTypeCount"
                :pagination="false"
              >
              </a-table>

            </div>
          </a-card>
        </a-col>
      </a-row>
      <a-row :gutter="16">
            <a-col :span="24">
                <a-card>
                      <a-card :tab-list="tabNoTitle"
                              :active-tab-key="TitleKey"
                              @tabChange="key => TabChange(key, 'TitleKey')" >
                        <div slot="tabBarExtraContent" style="float:right;max-width:350px">
                          <div style="float:right;max-width: 60%;">
                            <a-range-picker v-if="gcglbQueryParams.queryType==3"  v-model="gcglbQueryParams.plan_date" :default-value="[moment(firstDay, dateFormat), moment(nowDay, dateFormat)]"
                                            :format="dateFormat"  @change="gcglbChangeDate"/>
                            <a-month-picker style="width: 100px" v-if="gcglbQueryParams.queryType==2"  @change="gcglbChangeDate" placeholder="请选择月份" :default-value="moment(nowMonth, monthFormat)" :format="monthFormat"  v-model="gcglbQueryParams.plan_date">
                              <a-icon slot="suffixIcon" type="smile"/>
                            </a-month-picker>
                            <a-date-picker
                                v-if="gcglbQueryParams.queryType==1"
                                mode="year"
                                v-model="gcglbQueryParams.plan_date"
                                placeholder="请输入年份"
                                :default-value="moment(nowYear, yearFormat)" :format="yearFormat"
                                style="width: 100px"
                                :open="gcglbQueryParams.yearShowOne"
                                @openChange="gcglbOpenChangeOne"
                                @panelChange="gcglbPanelChangeOne"/>
                          </div>
                          <a-select  v-model="gcglbQueryParams.queryType" style="width: 100px;float:right;top:12px" @change="gcglbTimeChange">
                            <a-select-option :key="1"> 年 </a-select-option>
                            <a-select-option :key="2"> 月 </a-select-option>
                            <a-select-option :key="3">其他</a-select-option>
                          </a-select>
                        </div>
                      </a-card>
                  <p v-if="TitleKey==='office'">
                  <a-table
                      :bordered="true"
                      :columns="columns1"
                      :dataSource="gcglbDatas"
                      :pagination="false"
                  >

                  </a-table>
                  </p>
                  <p v-else-if="TitleKey==='gcglb'">
                    <a-table
                        :bordered="true"
                        :columns="columns2"
                        :dataSource="gcglbDatas2"
                        :pagination="false"
                    >
                    </a-table>
                  </p>
                  <p v-else-if="TitleKey==='tgcfd'">
                    <a-table
                        :bordered="true"
                        :columns="columns3"
                        :dataSource="tgcfDatas"
                        :pagination="false"
                    >
                    </a-table>
                  </p>
                </a-card>
            </a-col>
          </a-row>
  </div>
</template>


<script>
const columns = [
  {
    title: '隐患总数',
    dataIndex: 'total',
    align: 'center',
   scopedSlots: { customRender: 'total' }
  },
  {
    title: '安全管理',
    dataIndex: 'pb1',
    align: 'center',
   scopedSlots: { customRender: 'pb1' },
   className:"aqglclass"
  },
  {
    title: '文明施工',
    dataIndex: 'pb2',
    align: 'center',
    scopedSlots: {customRender: 'pb2'},
   className:"wmsgclass"
  },
  {
    title: '脚手架',
    dataIndex: 'pb3',
    align: 'center',
    scopedSlots: {customRender: 'pb3'},
   className:"jsjclass"
  },
  {
    title: '基坑与模板',
    dataIndex: 'pb4',
    align: 'center',
    scopedSlots: {customRender: 'pb4'},
   className:"jkymbclass"
  },
  {
    title: '高处作业',
    dataIndex: 'pb6',
    align: 'center',
    scopedSlots: {customRender: 'pb6'},
   className:"gczyclass"
  },
  {
    title: '施工用电',
    dataIndex: 'pb7',
    align: 'center',
    scopedSlots: {customRender: 'pb7'},
   className:"sgydclass"
  },
  {
    title: '大型设备',
    dataIndex: 'pb8',
    align: 'center',
    scopedSlots: {customRender: 'pb8'}
    ,
   className:"dxsbclass"
  },
  {
    title: '起重吊装',
    dataIndex: 'pb54',
    align: 'center',
    scopedSlots: {customRender: 'pb54'},
   className:"qzdzclass"
  },
  {
    title: '施工机具',
    dataIndex: 'pb11',
    align: 'center',
    scopedSlots: { customRender: 'pb11' },
   className:"sgjjclass"
  }
]
const columns1=[
  {
    title: '工程管理部',
    dataIndex: 'officeName',
    align: 'center',
   scopedSlots: { customRender: 'officeName' }
  },
  {
    title: '在建项目',
    dataIndex: 'totalProjs',
    align: 'center',
   scopedSlots: { customRender: 'totalProjs' }
  },
  {
    title: '排查项目数量',
    dataIndex: 'checkProjs',
    align: 'center',
   scopedSlots: { customRender: 'checkProjs' }
  },
  {
    title: '安全隐患检查次数',
    dataIndex: 'checkTimes',
    align: 'center',
   scopedSlots: { customRender: 'checkTimes' }
  },
  {
    title: '检查覆盖率',
    dataIndex: 'checkProjFgl',
    align: 'center',
   scopedSlots: { customRender: 'checkProjFgl' }
  },
  {
    title: '隐患总数',
    dataIndex: 'yhzs',
    align: 'center',
   scopedSlots: { customRender: 'yhzs' }
  },
  {
    title: '隐患整改率',
    dataIndex: 'yhzgl',
    align: 'center',
   scopedSlots: { customRender: 'yhzgl' }
  },
]
const columns2=[
  {
    title: '项目名称',
    dataIndex: 'projectName',
    align: 'left',
    scopedSlots: { customRender: 'projectName' }
  },
  {
    title: '安全隐患检查次数',
    dataIndex: 'checkCnt',
    align: 'center',
    scopedSlots: { customRender: 'checkCnt' }
  },
  {
    title: '隐患总数',
    dataIndex: 'wtzs',
    align: 'center',
    scopedSlots: { customRender: 'wtzs' }
  },
  {
    title: '检查覆盖率',
    dataIndex: 'yhzgl',
    align: 'center',
    scopedSlots: { customRender: 'yhzgl' }
  },
]
const columns3=[
  {
    title: '工程管理部',
    dataIndex: 'officeName',
    align: 'left',
    scopedSlots: { customRender: 'officeName' }
  },
  {
    title: '停工通知书总数',
    dataIndex: 'stops',
    align: 'center',
    scopedSlots: { customRender: 'stops' }
  },
  {
    title: '处罚通知书总数',
    dataIndex: 'cfds',
    align: 'center',
    scopedSlots: { customRender: 'cfds' }
  },
  {
    title: '安全生产约谈总数',
    dataIndex: 'yts',
    align: 'center',
    scopedSlots: { customRender: 'yts' }
  },
]
import { QUERY_PROJECT_GK,
 GCGLB_AQXJ_DETAILS,
 QUERY_AQXJ_YHZGLTOP10,
 CURRENT_CHECKTIMES_CHART,
 CURRENT_YHZSL_CHART,
 CURRENT_CSWZGS_CHART,
 OFFICE_TYPE_COUNT_QUERY,
  PROJECT_AQXJ_GCGLB_EXPORT,
 PROBLEMS_BY_TOTAL_QUERY,
 PROJECT_CHECK_TOP10,
 PROJECT_CHECK_NO8,
  PROJECT_getStopCfdCnts,
 CURRENT_YHZGL_CHART} from '@/services/api'
import {request} from '@/utils/request'
import {mapGetters} from 'vuex'
import moment from 'moment';

export default {
  name: 'QueryList',
  components: {},
  data () {
    return {
      columns,
      columns1,
      columns2,
      columns3,
      yhzs:0,
      gcglbYhzs:0,
      gkObj:{},
      gcglbObj:{},
      gcglbDatas:[],
      gcglbDatas2:[],
      tgcfDatas:[],
      dataSourceTypeCount:[],
      dataSource:[],
      dataSourceYhs:[],
      dataSourceYhzgl:[],
      checkTimeType:1,
      checkTimeText:"当月",
      checkTimeTotal:0,
      yhzsType:1,
      yhzsText:"当月",
      yhzsTotal:0,
      yhzglType:2,
      yhzglText:"当年",
      yhzglTotal:0,
      cswzgType:1,
      cswzgText:"当月",
      cswzgsTotal:0,
      monthFormat: 'YYYY-MM',
      nowMonth:null,
      dateFormat: 'YYYY-MM-DD',
      nowYear:null,
      yearFormat:'YYYY',
      firstDay:null,
      nowDay:null,
      yearShowOne:false,
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: "#3ec13e",//滚动条颜色
          opacity: 0.8,//滚动条透明度
          "overflow-x": "hidden"
        }
      },
      queryParams:{
        type:2,
        plan_date:null,
        xjTimeStart:moment(new Date()).format('YYYY-MM')+"-01",
        xjTimeEnd:moment(new Date()).format('YYYY-MM-DD'),
        xjTimeStr:moment(new Date()).format('YYYY-MM'),
      },
      proQueryParams:{
        queryType:2,
        plan_date:null,
        yearShowOne:false,
        startDate:moment(new Date()).format('YYYY-MM')+"-01",
        endDate:moment(new Date()).format('YYYY-MM-DD'),
        month:moment(new Date()).format('YYYY-MM'),
      },
      xjTimesQueryParams:{
        queryType:1,
        plan_date:null,
        startDate:moment(new Date()).format('YYYY-MM')+"-01",
        endDate:moment(new Date()).format('YYYY-MM-DD'),
        month:moment(new Date()).format('YYYY'),
        yearShowOne:false,
      },
      zglParams:{
        yearShowOne:false,
        plan_date:null,
        month:moment(new Date()).format('YYYY'),
      },
      monthQueryParams:{
        isGcglb:0,
        queryType:2,
        plan_date:null,
        startDate:moment(new Date()).format('YYYY-MM')+"-01",
        endDate:moment(new Date()).format('YYYY-MM-DD'),
        month:moment(new Date()).format('YYYY-MM'),
        yearShowOne:false,
      },
      gcglbtype:false,
      gcglbQueryParams:{
        queryType:2,
        plan_date:null,
        startDate:moment(new Date()).format('YYYY-MM')+"-01",
        endDate:moment(new Date()).format('YYYY-MM-DD'),
        month:moment(new Date()).format('YYYY-MM'),
        yearShowOne:false,
      },
      tabListNoTitle: [
        {
          key: 'office',
          tab: '公司检查概况',
        },
        {
          key: 'gcglb',
          tab: '工程管理部检查概况',
        },
      ],
      tabNoTitle:[
        {
          key: 'office',
          tab: `工程管理部检查情况`,

        },
        {
          key: 'tgcfd',
          tab: '停工/处罚/约谈情况',
        }
      ],
      TitleKey:'office',
      key: 'office',
      isleader:false,
      noTitleKey: 'office',
      gcglbtitle:''
    }
  },
  computed: {
    ...mapGetters('account', ['user']),
    totalChecks(){
      let total = 0;
      if(this.gkObj.checks&&this.gkObj.checks!=null){
        total = total+this.gkObj.checks;
      }
      if(this.gkObj.dbCnt&&this.gkObj.dbCnt!=null){
        total = total+this.gkObj.dbCnt;
      }
      return total
    },
    gcglbObjChecks(){
      let total = 0;
      if(this.gcglbObj.checks&&this.gcglbObj.checks!=null){
        total = total+this.gcglbObj.checks;
      }
      if(this.gcglbObj.zxjc&&this.gcglbObj.zxjc!=null){
        total = total+this.gcglbObj.zxjc;
      }
      return total;
    },
  },

  filters: {
    ellipsis (value) {
      if (!value) return ''
      if (value.length > 80) {
        return value.slice(0,80) + '...'
      }
      return value
    }
  },
  created() {
    this.nowMonth = moment(new Date()).format('YYYY-MM');
    this.firstDay = moment(new Date()).format('YYYY-MM')+"-01";
    this.nowDay = moment(new Date()).format('YYYY-MM-DD');
    this.nowYear = moment(new Date()).format('YYYY');
    let type=this.$store.getters["account/user"].isGcglb
    if(type===1) {
      this.gcglbtype=true
      this.isleader=this.$store.getters["account/user"].nowRoleKey.includes('OFFCIE_DZBSJ')
      if(this.isleader){
        this.tabNoTitle=[{key: 'office', tab: `工程管理部检查情况`},{key: 'gcglb', tab: `项目部检查情况`},]
      }else{
        this.TitleKey='gcglb'
        this.tabNoTitle=[{key: 'gcglb', tab: `项目部检查情况`}]
      }
      this.tabListNoTitle = [{key: 'office',tab: '工程管理部检查概况'}];
      this.key = 'office';
      this.noTitleKey = 'office';
      this.gcglbtitle='工程管理部'
    }
    this.queryXjGk();
    this.doProCheckQuery();
    // this.queryYhzsTop10();
    // this.queryYhzglTop10();
    this.gcglbAqxjQuery();

    this.$nextTick(function() {
      this.checkTimeChange();
      // this.yhzsChange();
      // this.cswzgChange(1);
      this.yhzglChange();
      this.doQuery();
    })

  },
  methods: {
    onTabChange(key, type) {
      this[type] = key;
      if(key=='gcglb'){
        this.monthQueryParams.isGcglb=1;
      }else{
        this.monthQueryParams.isGcglb=0;
      }
      this.queryXjGk();
    },
    TabChange(key,type){
      this[type] = key;
    },
    /**
     * 获取项目自查排名前十
     */
    doProCheckQuery(){
      if(this.proQueryParams.queryType==1&&this.proQueryParams.plan_date!=null){
        this.proQueryParams.month = this.proQueryParams.plan_date.format('YYYY')
      }
      if(this.proQueryParams.queryType==2&&this.proQueryParams.plan_date!=null){
        this.proQueryParams.month = this.proQueryParams.plan_date.format('YYYY-MM')
      }
      if(this.proQueryParams.queryType==3&&this.proQueryParams.plan_date&&this.proQueryParams.plan_date.length>0){
        this.proQueryParams.startDate = this.proQueryParams.plan_date[0].format('YYYY-MM-DD')
        this.proQueryParams.endDate = this.proQueryParams.plan_date[1].format('YYYY-MM-DD')
      }
      request(PROJECT_CHECK_NO8,"post",this.proQueryParams).then(res => {
        if(res.data.code==0){
          this.dataSource = res.data.data;
        }
      })
    },

    gcglbAqxjQuery(){
      if(this.gcglbQueryParams.queryType==1&&this.gcglbQueryParams.plan_date!=null){
        this.gcglbQueryParams.month = this.gcglbQueryParams.plan_date.format('YYYY')
      }
      if(this.gcglbQueryParams.queryType==2&&this.gcglbQueryParams.plan_date!=null){
        this.gcglbQueryParams.month = this.gcglbQueryParams.plan_date.format('YYYY-MM')
      }
      if(this.gcglbQueryParams.queryType==3&&this.gcglbQueryParams.plan_date&&this.gcglbQueryParams.plan_date.length>0){
        this.gcglbQueryParams.startDate = this.gcglbQueryParams.plan_date[0].format('YYYY-MM-DD')
        this.gcglbQueryParams.endDate = this.gcglbQueryParams.plan_date[1].format('YYYY-MM-DD')
      }
      if(this.gcglbtype){
        request(PROJECT_AQXJ_GCGLB_EXPORT,"post",this.gcglbQueryParams).then(res => {
          if(res.data.code==0) {
            res.data.data.forEach(e=>{
              if(this.gcglbQueryParams.queryType==1){
                 e.yhzgl=Number(e.checkCnt/96*100).toFixed(2)>=100?'100%':Number(e.checkCnt/96*100).toFixed(2)+'%'
              }else if(this.gcglbQueryParams.queryType==2){
                e.yhzgl=Number(e.checkCnt/8*100).toFixed(2)>=100?'100%':Number(e.checkCnt/8*100).toFixed(2)+'%'
              }else{
                e.yhzgl=' '
              }
            })
            this.gcglbDatas2 = res.data.data;
          }
        })
      }else{
        request(PROJECT_getStopCfdCnts,"post",this.gcglbQueryParams).then(res => {
          if(res.data.code==0){
            this.tgcfDatas = res.data.data;
          }
        })
      }
      if(this.isleader||!this.gcglbtype){
        request(GCGLB_AQXJ_DETAILS, "post", this.gcglbQueryParams).then(res => {
          if (res.data.code === 0) {
            this.gcglbDatas = res.data.data;
          }
        })
      }
    },


    /**
     * 获取时间段的台账信息
     */
    doQuery(){
      this.tableLoading = true;
      if(this.queryParams.type==1&&this.queryParams.plan_date!=null){
        this.queryParams.xjTimeStr = this.queryParams.plan_date.format('YYYY')
      }
      if(this.queryParams.type==2&&this.queryParams.plan_date!=null){
        this.queryParams.xjTimeStr = this.queryParams.plan_date.format('YYYY-MM')
      }
      if(this.queryParams.type==3&&this.queryParams.plan_date&&this.queryParams.plan_date.length>0){
        this.queryParams.xjTimeStart = this.queryParams.plan_date[0].format('YYYY-MM-DD')
        this.queryParams.xjTimeEnd = this.queryParams.plan_date[1].format('YYYY-MM-DD')
      }
      request(PROBLEMS_BY_TOTAL_QUERY,"post",this.queryParams).then(res => {
        let obj = [];
        let pieData = [];
        this.columns.forEach(c=>{
          if(c.dataIndex!='total'){
            obj.push(c.title);
          }
        })
        let obj2 = [];
        if(res.data.code==0){
          this.dataSourceTypeCount = res.data.data;
          this.dataSourceTypeCount.forEach(obj=>{
            this.yhzsTotal = obj.total;
            obj.key = obj.id;
            this.columns.forEach(c=>{
              if(c.dataIndex!='total'){
                obj2.push(obj[c.dataIndex]);
                let pie = {
                  name:c.title,
                  value:obj[c.dataIndex],
                }
                pieData.push(pie);
              }
            })

          })
        }
        // this.initYhzsChart(obj,obj2);
        this.initYhzsPieChart(pieData);
      })
    },


    /**
     * //查询概况中的隐患总数
     */
    doGkYhzsQuery(){
      let obj ={
        type:this.monthQueryParams.queryType,
        xjTimeStr:this.monthQueryParams.month,
        xjTimeStart:this.monthQueryParams.startDate,
        xjTimeEnd:this.monthQueryParams.endDate
      }
      request(PROBLEMS_BY_TOTAL_QUERY,"post",obj).then(res => {
        if(res.data.code==0){
          res.data.data.forEach(obj=>{
            this.yhzs = obj.total;
          })
        }
      })
    },
    getGcglbYhzs(){
      let obj ={
        type:this.monthQueryParams.queryType,
        month:this.monthQueryParams.month,
        startDate:this.monthQueryParams.startDate,
        endDate:this.monthQueryParams.endDate
      }
      request(GCGLB_AQXJ_DETAILS,"post",obj).then(res => {
        let total = 0;
        if(res.data.code==0){
          res.data.data.forEach(o=>{
              total += o.yhzs
          });
        }
        this.gcglbYhzs = total;
      })
    },

    moment,
    initCheckTimesChart(data1,data2){
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("checkTimesChart"));
      // 指定图表的配置项和数据
      let option = {
        color:['#9BCA63'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: data1,
              axisTick: {
                alignWithLabel: true
              },
              axisLabel: { interval: 0, rotate: 60,formatter: '{value}'}
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: '检查次数',
              type: 'bar',
              barWidth: '60%',
              data: data2,
              label: {
                show: true,
                position: 'outside'
              },
            }
          ]
        };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    initYhzsChart(data1,data2){
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("yhzsChart"));
      // 指定图表的配置项和数据
      let option = {
        color:['#6087c7'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: data1,
              axisTick: {
                alignWithLabel: true
              },
              axisLabel: { interval: 0, rotate: 60,formatter: '{value}'}
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: '隐患数',
              type: 'bar',
              barWidth: '60%',
              data: data2,
              label: {
                show: true,
                position: 'outside'
              },
            }
          ]
        };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    initCswzgsChart(data1,data2,type){
      let text = "";
      if(type==2){
        text = "月";
      }
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("cswzgsChart"));
      // 指定图表的配置项和数据
      let option = {
        color:['#8c493c'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: data1,
              axisTick: {
                alignWithLabel: true
              },
              axisLabel: { interval: 0, rotate: 60,formatter: '{value}'+text}
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: '超时未整改数',
              type: 'bar',
              barWidth: '60%',
              data: data2,
              label: {
                show: true,
                position: 'outside'
              },
            }
          ]
        };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    initYhzglChart(data1,data2,type){
      let text = "";
      if(type==2){
        text = "月";
      }
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("yhzglChart"));
      // 指定图表的配置项和数据
      let option = {
        color:['#bcd749'],
          tooltip: {
            trigger: 'axis',
            formatter: "{a} <br/>{b}: {c}%",
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: data1,
              axisTick: {
                alignWithLabel: true
              },
              axisLabel: { interval: 0, rotate: 60,formatter: '{value}'+text}
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: '整改率',
              type: 'bar',
              barWidth: '60%',
              data: data2,
              label: {
                show: true,
                position: 'outside',
                formatter: '{c}%'
              },
            }
          ]
        };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    initYhzsPieChart(data){
       // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("yhzsPieChart"));
      // 指定图表的配置项和数据
      let option = {
        title: {
          text: '',
          subtext: '',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          show:false,
          orient: 'vertical',
          left: 'left'
        },
        color: ['#fc8251', '#5470c6', '#91cd77', '#75bedc', '#f9c956', '#ef6567','#45C2E0', '#C1EBDD', '#FFC851'],
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '55%',
            label:{
              normal: {
                show: true,
                // formatter: '{b} : {c} ({d}%)' //带当前图例名 + 百分比
                formatter: '{b} ({d}%)' //带当前图例名 + 百分比
              }
            },
            labelLine :{show:true} ,
            data: data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              },
            }
          }
        ]
      }
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    checkTimeChange(){
      if(this.xjTimesQueryParams.queryType==1&&this.xjTimesQueryParams.plan_date!=null){
        this.xjTimesQueryParams.month = this.xjTimesQueryParams.plan_date.format('YYYY')
      }
      if(this.xjTimesQueryParams.queryType==2&&this.xjTimesQueryParams.plan_date!=null){
        this.xjTimesQueryParams.month = this.xjTimesQueryParams.plan_date.format('YYYY-MM')
      }
      if(this.xjTimesQueryParams.queryType==3&&this.xjTimesQueryParams.plan_date&&this.xjTimesQueryParams.plan_date.length>0){
        this.xjTimesQueryParams.startDate = this.xjTimesQueryParams.plan_date[0].format('YYYY-MM-DD')
        this.xjTimesQueryParams.endDate = this.xjTimesQueryParams.plan_date[1].format('YYYY-MM-DD')
      }
      this.checkTimeTotal = 0;
      request(CURRENT_CHECKTIMES_CHART,"post",this.xjTimesQueryParams).then(res => {
        if(res.data.code==0){
          let obj = [];
          let obj2 = [];
          res.data.data.forEach(o=>{
            obj.push(o.timeDate);
            obj2.push(o.cnt)
            this.checkTimeTotal = this.checkTimeTotal+Number(o.cnt)
          })
          this.initCheckTimesChart(obj,obj2);
        }
      })
    },

    yhzglChange(){
      if(this.zglParams.plan_date!=null){
        this.zglParams.month = this.zglParams.plan_date.format('YYYY')
      }
      request(CURRENT_YHZGL_CHART,"post",this.zglParams).then(res => {
        if(res.data.code==0){
            this.yhzglTotal = res.data.msg+"%";
          let obj = [];
          let obj2 = [];
          res.data.data.forEach(o=>{
            obj.push(o.timeDate);
            obj2.push(o.cnt);
          })
          this.initYhzglChart(obj,obj2);
        }else{
          this.yhzglTotal = "0";
        }
      })
    },
    queryXjGk(){
      if(this.monthQueryParams.queryType==1&&this.monthQueryParams.plan_date!=null){
        this.monthQueryParams.month = this.monthQueryParams.plan_date.format('YYYY')
      }
      if(this.monthQueryParams.queryType==2&&this.monthQueryParams.plan_date!=null){
        this.monthQueryParams.month = this.monthQueryParams.plan_date.format('YYYY-MM')
      }
      if(this.monthQueryParams.queryType==3&&this.monthQueryParams.plan_date&&this.monthQueryParams.plan_date.length>0){
        this.monthQueryParams.startDate = this.monthQueryParams.plan_date[0].format('YYYY-MM-DD')
        this.monthQueryParams.endDate = this.monthQueryParams.plan_date[1].format('YYYY-MM-DD')
      }
      request(QUERY_PROJECT_GK,"post",this.monthQueryParams).then(res => {
        if(res.data.code==0){
          if(this.monthQueryParams.isGcglb==0){
            this.gkObj = res.data.data;
          }else{
            this.gcglbObj = res.data.data;
          }

        }
      })
      this.doGkYhzsQuery();
      this.getGcglbYhzs();

    },
    openChangeOne(status) {
      if (status) {
        this.yearShowOne = true;
      }
    },
    proOpenChangeOne(status){
      if (status) {
        this.proQueryParams.yearShowOne = true;
      }
    },
    zglOpenChangeOne(status){
      if (status) {
        this.zglParams.yearShowOne = true;
      }
    },
    xjTimesOpenChangeOne(status){
      if (status) {
        this.xjTimesQueryParams.yearShowOne = true;
      }
    },
    monthOpenChangeOne(status){
      if (status) {
        this.monthQueryParams.yearShowOne = true;
      }
    },
    gcglbOpenChangeOne(status){
      if (status) {
        this.gcglbQueryParams.yearShowOne = true;
      }
    },
    gcglbPanelChangeOne(value){
      this.gcglbQueryParams.yearShowOne = false;
      this.gcglbQueryParams.plan_date = value;
      this.$nextTick(function() {
        this.gcglbAqxjQuery();
      })
    },
    monthPanelChangeOne(value){
      this.monthQueryParams.yearShowOne = false;
      this.monthQueryParams.plan_date = value;
      this.$nextTick(function() {
        this.queryXjGk();
      })
    },
    // 得到年份选择器的值
    proPanelChangeOne(value) {
      this.proQueryParams.yearShowOne = false;
      this.proQueryParams.plan_date = value;
      this.$nextTick(function() {
        this.doProCheckQuery();
      })
    },
    zglPanelChangeOne(value){
      this.zglParams.yearShowOne = false;
      this.zglParams.plan_date = value;
      this.$nextTick(function() {
        this.yhzglChange();
      })
    },
    // 得到年份选择器的值
    xjTimesPanelChangeOne(value) {
      this.xjTimesQueryParams.yearShowOne = false;
      this.xjTimesQueryParams.plan_date = value;
      this.$nextTick(function() {
        this.checkTimeChange();
      })
    },
    // 得到年份选择器的值
    panelChangeOne(value) {
      this.yearShowOne = false;
      this.queryParams.plan_date = value;
      this.$nextTick(function() {
        this.doQuery();
      })
    },
    proChangeDate(value){
      this.proQueryParams.plan_date = value;
      this.$nextTick(function() {
        this.doProCheckQuery();
      })

    },
    changeDate(value){
      this.queryParams.plan_date = value;
      this.$nextTick(function() {
        this.doQuery();
      })
    },
    xjTimesChangeDate(value){
      this.xjTimesQueryParams.plan_date = value;
      this.$nextTick(function() {
        this.checkTimeChange();
      })
    },
    yhzsTypeChange(){
      if(this.queryParams.type==1||this.queryParams.type==2){
        this.queryParams.plan_date=moment(new Date());
      }else{
        this.queryParams.plan_date=null;
      }
      this.$nextTick(function() {
        this.doQuery();
      })
    },
    proTop10Change(){
      if(this.proQueryParams.queryType==1||this.proQueryParams.queryType==2){
        this.proQueryParams.plan_date=moment(new Date());
      }else{
        this.proQueryParams.plan_date=null;
      }
      this.doProCheckQuery();
    },
    checkTimeTypeChange(){
      if(this.xjTimesQueryParams.queryType==1||this.xjTimesQueryParams.queryType==2){
        this.xjTimesQueryParams.plan_date=moment(new Date());
      }else{
        this.xjTimesQueryParams.plan_date=null;
      }
      if(this.xjTimesQueryParams.queryType==1){
        this.xjTimesQueryParams.month = moment(new Date()).format('YYYY')
      }
      if(this.xjTimesQueryParams.queryType==2){
        this.xjTimesQueryParams.month = moment(new Date()).format('YYYY-MM')
      }
      this.checkTimeChange();
    },
    gcglbTimeChange(){
      if(this.gcglbQueryParams.queryType==1||this.gcglbQueryParams.queryType==2){
        this.gcglbQueryParams.plan_date=moment(new Date());
      }else{
        this.gcglbQueryParams.plan_date=null;
      }
      if(this.gcglbQueryParams.queryType==1){
        this.gcglbQueryParams.month = moment(new Date()).format('YYYY')
      }
      if(this.gcglbQueryParams.queryType==2){
        this.gcglbQueryParams.month = moment(new Date()).format('YYYY-MM')
      }
      this.gcglbAqxjQuery();
    },
    mtChangeTypeDate(){
      if(this.monthQueryParams.queryType==1||this.monthQueryParams.queryType==2){
        this.monthQueryParams.plan_date=moment(new Date());
      }else{
        this.monthQueryParams.plan_date=null;
      }
      if(this.monthQueryParams.queryType==1){
        this.monthQueryParams.month = moment(new Date()).format('YYYY')
      }
      if(this.monthQueryParams.queryType==2){
        this.monthQueryParams.month = moment(new Date()).format('YYYY-MM')
      }
      this.queryXjGk();
    },
    mtChangeDate(value){
      this.monthQueryParams.plan_date = value;
      this.queryXjGk();
    },
    gcglbChangeDate(value){
      this.gcglbQueryParams.plan_date = value;
      this.gcglbAqxjQuery();
    }
  }
}
</script>

<style lang="less" scoped>
  .search{
    margin-bottom: 54px;
  }
  .fold{
    width: calc(100% - 216px);
    display: inline-block
  }
  .operator{
    margin-bottom: 18px;
  }
  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
  // 滚动条位置
/deep/.__bar-is-vertical {
  right: -1px !important;
}
// 隐藏横向滚动条
/deep/.__bar-is-horizontal {
  display: none !important;
}
/deep/ th.aqglclass{
    background-color: #fc8251 !important;
  }
/deep/ th.wmsgclass{
  background-color: #5470c6 !important;
}
/deep/ th.jsjclass{
    background-color: #91cd77 !important;
  }
/deep/ th.jkymbclass{
  background-color:  #75bedc !important;
}
/deep/ th.gczyclass{
    background-color: #f9c956 !important;
  }
/deep/ th.sgydclass{
  background-color: #ef6567 !important;
}
/deep/ th.dxsbclass{
    background-color: #45C2E0 !important;
  }
/deep/ th.qzdzclass{
  background-color: #C1EBDD !important;
}
/deep/ th.sgjjclass{
    background-color: #FFC851 !important;
  }
</style>
