<template>
  <div class="container">
    <form-index
      ref="formindex"
      :formData="formData"
      @handleSubmit="handleSubmit"
      :flexAction="true"
      @handlSelect="handlSelect"
      @handleChange="handleChange"
    >
      <!-- 下部分按钮插槽 -->
      <template #flexAction>
        <a-space :style="{ marginTop: '4px' }">
          <a-button icon="search" type="primary" html-type="submit"> 查询 </a-button>
          <a-space class="operator">
            <a-button @click="close"> 重置 </a-button>
          </a-space>
          <a-button icon="plus" type="primary" @click="addNewRecord" v-if="user.loginType == 0 && params.staffId">新增</a-button>
        </a-space>
      </template>
    </form-index>
    <div class="a_a_footer">
      <standard-table
        class=""
        :columns="columns"
        :dataSource="dataSource"
        :selectedRows.sync="selectedRows"
        rowKey="id"
        :scroll="{ x: 1300 }"
        :pagination="pagination"
        :loading="tableLoading"
        @change="onPageChange"
        :isAlert="false"
        :bordered="true"
        @update:selectedRows="updateSelectRows($event)"
      >
        <template slot="index" slot-scope="{ index }">
          <span>
            {{
              (pagination.current - 1) * pagination.pageSize +
              parseInt(index) +
              1
            }}
          </span>
        </template>
        <template slot="annex" slot-scope="{ text }">
          <img :src="text" style="width: 100px;" />
        </template>
        <div slot="action" slot-scope="{ record }">
            <a @click="edit(record)">修改</a>
            <a-divider type="vertical" />
            <a @click="delRecord(record)" style="color: #FF5D78">删除</a>
        </div>
      </standard-table>
    </div>
  </div>
</template>

<script>
import formIndex from "@/pages/components/form/index";
import StandardTable from "@/components/table/StandardTable";
import { getOfficeProject } from "@/services/projectManagement";
import moment from "moment";
import {mapGetters} from 'vuex'
import eventType from '@/pages/advanceRetreat/eventType.json'
import { getEventRecordList, delEventRecord } from "@/services/participationUnit";
import { useRender } from '@/hooks/useRender.js'

const formData = [
  {
    label: "所属项目",
    placeholder: "请输入所属项目",
    decorator: [
      "projectId",
      { rules: [{ required: false, message: "请输入所属项目" }] },
    ],
    type: "inputGroup",
    key: "projectId",
    dataSource: [],
    labelCol: 8,
    wrapperCol: 14,
    col: 4,
    display: true,
  },
  {
    label: "事件类型",
    placeholder: "请选择",
    decorator: [
      "eventType",
      { rules: [{ required: false, message: "请选择" }] },
    ],
    type: "select",
    selectlist: eventType,
    key: "eventType",
    dataSource: [],
    labelCol: 8,
    wrapperCol: 14,
    col: 4,
    display: true,
  },
  {
    label: "发生时间",
    placeholder: "请选择",
    decorator: [
      "eventTime",
      { rules: [{ required: false, message: "请选择" }] },
    ],
    type: "datePicker",
    key: "eventTime",
    col: 4,
    labelCol: 8,
    wrapperCol: 14,
    display: true,
    format: 'YYYY-MM-DD'
  },
  // {
  //   label: "审核状态",
  //   placeholder: "请选择",
  //   decorator: ["auditStatus", { rules: [{ required: false, message: "请选择" }] }],
  //   type: "select",
  //   key: "auditStatus",
  //   selectlist: [
  //   {
  //     value: 0,
  //     name: '待审核'
  //   },{
  //     value: 1,
  //     name: '已审核'
  //   }
  //   ],
  //   col: 4,
  //   labelCol: 6,
  //   wrapperCol: 14,
  //   display: true,
  // },

  {
    type: "action",
    col: 5,
    display: true,
  },
];
const columns = [
  {
    title: "序号",
    width: 65,
    scopedSlots: { customRender: "index" },
    // customRender: (text, record, index) => `${index + 1}`,
    align: "center",
  },
  {
    title: "姓名",
    dataIndex: "name",
    align: "center",
    scopedSlots: { customRender: "name" },
  },
  {
    title: '所属项目',
    dataIndex: 'projectName',
    key: 'projectName',
    scopedSlots: { customRender: 'projectName' }
  },
  {
    title: "事件类型",
    dataIndex: "eventType",
    align: "center",
    scopedSlots: { customRender: "eventType" },
  },
  {
    title: "事件描述",
    dataIndex: "eventDescribe",
    align: "center",
    ellipsis: true,
    scopedSlots: { customRender: "eventDescribe" },
  },
  {
    title: "发生时间",
    align: "center",
    dataIndex: "eventTime",
    customRender: (text) => useRender.renderDate(text, 'YYYY-MM-DD')
  },
  {
    title: "处理措施",
    dataIndex: "measures",
    align: "center",
    ellipsis: true,
    scopedSlots: { customRender: "measures" },
  },
  {
    title: "记录时效",
    dataIndex: "termTime",
    align: "center",
  },
  {
    title: "附件",
    dataIndex: "annex",
    align: "center",
    scopedSlots: { customRender: "annex" },
  },
  // {
  //   title: "审核状态",
  //   dataIndex: "auditStatus",
  //   align: "center",
  //   scopedSlots: { customRender: "auditStatus" },
  // },
  {
    title: "操作",
    align: "center",
    width: 120,
    scopedSlots: { customRender: "action" },
  },
];
export default {
  components: {
    formIndex,
    StandardTable,
  },
  data() {
    return {
      formData: formData,
      columns: columns,
      dataSource: [], //table数组
      selectedRows: [], //选中的数据
      pagination: {
        //分页数据
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total, range) => `共 ${total} 条`
      },
      params: {
        //永远都是第一页
        pageNumber: 1,
        pageSize: 10,
        staffId: ''
      },
      tableLoading: false, //table加载中
    };
  },
  mounted() {
    if (this.user.loginType == 0) {  //项目账号
      this.columns = this.columns.filter(item => item.key != 'projectName')
      this.formData = this.formData.filter(item => item.key != 'projectId')
    }
  },
  activated() {
    if (this.$route.query.id) {
      this.params.staffId = this.$route.query.id
    }
    this.getEventRecordList()
  },
  computed: {
    ...mapGetters('account', ['user']),
  },
  methods: {
    handleChange(e) {
      this.projectArr = [];
      this.getZdata(getOfficeProject, e, "projectName",'fullName');
      // 获取当前匹配key，赋值相对于数组
      this.formData.forEach((item) => {
        if (e.item.key === item.key) {
          item.dataSource = this.projectArr;
        }
      });
    },
    /**
     * msg 接收接口名称
     * e 数据
     * name 参数名
     * */
     getZdata(msg, e, name,title) {
      let data = {
        [name]: e.value,
        officeId: this.user.officeId
      };
      msg(data).then((res) => {
        if (res.status == 200) {
          const data = res.data.data.list ? res.data.data.list : res.data.data
          data.forEach((item) => {
            this.projectArr.push({
              title: item[title],
              id: item.id,
            });
          });
          this.projectList = data;
        }
      });
    },
    getEventRecordList(obj) {
      this.tableLoading = true;
      getEventRecordList(obj ? obj : this.params).then((res) => {
        this.tableLoading = false;
        if (res.data.code === 0) {
          this.dataSource = res.data.data.list
          this.dataSource.forEach(el => {
            el.eventTime = moment(el.eventTime).format('YYYY-MM-DD')
            el.startTermTime = moment(el.startTermTime).format('YYYY-MM-DD')
            el.endTermTime = moment(el.endTermTime).format('YYYY-MM-DD')
            el.termTime = `${el.startTermTime} 至 ${el.endTermTime}`
            el.eventType = eventType.find(i => i.value === el.eventType).name
            el.auditStatus = el.auditStatus === 0 ? '未审核' : '已审核'
          })
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 特殊数据处理
    handlSelect({ val, keys }) {
      this.projectList.forEach((item) => {
        if (keys == "officeName" && item.id == val) {
          this.$refs["formindex"].setFieldsValue(
            item.name,
            {},
            "officeName"
          );
        }
      });
    },
    // 对下拉框做数据处理
    formDataFn(key, data, itemName, form) {
      let arr = [];
      data.forEach((item) => {
        arr.push({ name: item[itemName], value: item.id });
      });
      form.forEach((item) => {
        if (item.key == key) {
          item.selectlist = arr;
        }
      });
    },
    handleSubmit(e) {
      let obj = { ...e, ...this.params };
      obj.eventTime = obj.eventTime ? moment(obj.eventTime).valueOf() : '';
      // this.getCertificateList(obj);
      this.getEventRecordList(obj)
    },
    addNewRecord() {
      this.$router.push({
        name: "事件记录详情",
        query: {
          staffId: this.params.staffId || '',
          name: this.$route.query.name,
          unitId: this.$route.query.unitId,
          teamId: this.$route.query.teamId,
        }
      });
    },
    edit(record) {
      record['termTime'] = [record.startTermTime, record.endTermTime]
      this.$router.push({
        name: "事件记录详情",
        query: {
          id: record.id,
          staffId: record.staffId,
          isEdit: true,
          unitId: record.officeId,
          teamId: record.teamId,
          name: record.name,
        }
      });
    },
    delRecord(record) {
      const _that = this
      this.$confirm({
        title: '是否删除记录？',
        onOk() {
          delEventRecord(record.id).then(res => {
            if (res.data.code === 0) {
              _that.$message.success('删除成功！');
              _that.params.pageNumber = 1
              _that.getEventRecordList()
            } else {
              _that.$message.error(res.data.msg);
            }
          })
        }
      })
    },
    //表格 - 分页查询
    onPageChange(e) {
      let obj = this.$refs["formindex"].getFieldValue();
      if (obj.status == "") {
        obj.status = 0;
      }
      this.params.pageNumber = e.current;
      this.pagination.current = e.current;
      this.getEventRecordList(Object.assign({}, this.params, obj));
    },
    close() {
      this.params = {
        pageNumber: 1,
        pageSize: 10,
      };
      this.getEventRecordList(this.params);
      this.pagination.current = 1;
      this.$refs["formindex"].resetFields();
    },
  },
};
</script>

<style lang="less" scoped>
.a_a_footer {
  // padding: 20px;
  background: #ffffff;

  .operator {
    margin-right: 8px;

    .ant-btn {
      color: #307dfa;
    }
  }

  .sTables {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      width: 100%;
      text-align: center;
      cursor: pointer;
    }

    div:nth-child(1) {
      color: #307dfa;
    }

    div:nth-child(2) {
      color: #ff5c77;
    }

    div:nth-child(3) {
      color: #615e83;
    }
  }
}
.content_div {
  padding: 5px 0;
  cursor: pointer;
}
.content_div:hover {
  color: #307dfa;
}

.plug-form .ant-form .ant-btn {
  // 处理表单组件强制margin-right问题
  margin-right: 0
}

.operator {
  .ant-btn {
  color: #307dfa;
  border-color: #307dfa;
  }
}
</style>
