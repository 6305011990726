<template>
    <a-modal
      title="人员宿舍分配"
      :visible="visible"
      @ok="modelOk"
      @cancel="modelCancel"
      :footer="null"
      width="1020px"
    >
      <a-card style="background-color: #ececec;"> 
        <a-row :gutter="16">
          <a-col :md="11" :sm="24" style="background-color: #fff;padding:5px;max-height:500px">
            <a-form layout="horizontal">
              <div :class="advanced ? null: 'fold'">
                <a-row >
                  <a-col :md="24" :sm="24" >
                  <a-form-item
                    label="班组"
                    :labelCol="{span: 5}"
                    :wrapperCol="{span: 18, offset: 1}"
                  >
                    <a-select
                      show-search
                      placeholder="请输入或选择班组"
                      option-filter-prop="children"
                      style="width: 200px"
                      :dropdownStyle="dropdownStyleSelect"
                      :filter-option="filterOption"
                      @change="handleChange"
                    >
                      <a-select-option :key="te.id" v-for="te in teamDatas" :value="te.id">
                        {{te.teamName}}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
              </a-row>
              </div>
            </a-form>
            <standard-table
              :columns="columns"
              :dataSource="dataSource"
              :selectedRows.sync="selectedRows"
              :pagination="pagination"
              :loading="tableLoading"
              rowKey="idCard"
              tableSize="small"
              :scroll="scroll"
              :isAlert="false"
              @clear="onClear"
              @change="onPageChange"
            >
            </standard-table>
          </a-col>
          <a-col :md="11" :sm="24" style="background-color: #fff;margin-left:50px;padding:5px;max-height:500px">
            <a-form layout="horizontal">
              <div :class="advanced ? null: 'fold'">
                <a-row >
                  <a-col :md="24" :sm="24" >
                  <a-form-item
                    label="宿舍楼"
                    :labelCol="{span: 5}"
                    :wrapperCol="{span: 18, offset: 1}"
                  >
                    <a-select
                      show-search
                      placeholder="请输入或选择宿舍楼"
                      option-filter-prop="children"
                      style="width: 200px"
                      :defaultValue="this.queryParams2.buildId"
                      :defaultActiveFirstOption="true"
                      :dropdownStyle="dropdownStyleSelect"
                      :filter-option="filterOption"
                      @change="handleChange2"
                    >
                      <a-select-option :key="te.id" v-for="te in buildData" :value="te.id">
                        {{te.name}}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
              </a-row>
              </div>
            </a-form>
            <standard-table
              :columns="columnsRoom"
              :dataSource="dataSource2"
              :pagination="pagination2"
              :loading="tableLoading2"
              rowKey="id"
              :isAlert="false"
              tableSize="small"
              :scroll="scroll"
              @clear="onClear"
              @change="onPageChange2"
            >
              <div slot="action" slot-scope="{text, record}">
                  <a-button v-bind:disabled="dis(record)"  type="primary" @click="modelOk(record)">
                    分配
                  </a-button>
              </div>
            </standard-table>
          </a-col>
        </a-row>
      </a-card>
    </a-modal>
</template>

<script>
import {
  FIND_TEAMS_BY_USER,
  PERSON_QUERY_LIST,
  BUILD_SIMPLE_QUERYALL,
  BUILD_ROOM_QUERYPAGE,
  } from '@/services/api'
import StandardTable from '@/components/table/StandardTable'
import {request} from '@/utils/request'
import moment from 'moment';
const columns = [
  {
    title: '姓名',
    dataIndex: 'name',
    width:80,
    align: 'center',
    scopedSlots: { customRender: 'name' }
  },
  {
    title: '所属班组',
    dataIndex: 'teamName',
    align: 'center',
   scopedSlots: { customRender: 'totalRooms' },
  }
]

const columnsRoom = [
  {
    title: '房间号',
    dataIndex: 'roomNo',
    align: 'center',
    scopedSlots: { customRender: 'roomNo' }
  },
  {
    title: '空余床位',
    dataIndex: 'noPersonNums',
    align: 'center',
   scopedSlots: { customRender: 'noPersonNums' },
   customRender: (text) => {
     if(text){
       return text+"个";
     }else{
       return "0个";
     }
    }
  },
  {
    title: '操作',
    align: 'center',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'QueryList',
  components: {StandardTable},
  data () {
    return {
      dropdownStyle: {
        zIndex: 999999,
      },
      dropdownStyleSelect:{
        zIndex: 999999,
      },
      scroll:{
         y: false
      },
      columnsRoom,
      dataSource:[],
      dataSource2:[],
      teamDatas:[],
      buildData:[],
      monthFormat: 'YYYY-MM-DD',
      nowMonthDay:null,
      advanced: false,
      columns: columns,
      checkBoxDisable:{key:'noPersonNums',value:0},
      selectedRows: [],
      selectedRow2:[],
      selectedAllRows:[],
      tableLoading: false,
      tableLoading2:false,
      spinning:false,
      loadingText:"数据加载中...",
      loadKeys:[],
      queryParams:{
        userName:null,
        idCard:null,
        teamId:null,
        pageNumber:1,
        enterDateStart:null,
        enterDateEnd:null,
        inDate:null,
        inStatus:"0"
      },
      queryParams2:{
        buildId:null,
        pageNumber:1,
        isFullPerson:"0"
      },
      pagination:{
        current:1,
        pageSize:20,
        total:0,
        showLessItems:true,
        showQuickJumper:true
      },
      pagination2:{
        current:1,
        pageSize:20,
        total:0,
        showLessItems:true,
        showQuickJumper:true
      },
    }
  },
  created() {
    // this.doQueryTeams();
    // this.queryParams.enterDateStart = moment(new Date()).format('YYYY-MM-DD');
    // this.queryParams.enterDateEnd = moment(new Date()).format('YYYY-MM-DD');
    // this.nowMonthDay = moment(new Date()).format('YYYY-MM-DD');
    // this.doQuery();
    // this.queryBuilds();
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },

  watch:{
    visible:{
       handler(val, oldName) {
          if(val){
            console.log(val,'val===')
            this.doQueryTeams();
            this.doQuery();
            this.queryBuilds();
          }
       }
    }
  },
  authorize: {
    deleteRecord: 'delete'
  },
  methods: {
    moment,
    /**
     * 获取所有人员的签名记录信息
     */
    onClear(){

    },
    dis(recode){
      if(recode.noPersonNums==0||recode.noPersonNums=='undefined'){
        return true;
      }else{
        return false;
      }
    },
    queryBuilds(){
      request(BUILD_SIMPLE_QUERYALL,"post",{}).then(res => {
          if(res.data.code==0){
            this.buildData = res.data.data;
            if(this.buildData!=null&&this.buildData.length>0){
              this.queryParams2.buildId = this.buildData[0].id;
              this.doQueryRooms();
            }
          }
        })
    },
    doQueryRooms(){
       this.tableLoading2 = true;
        request(BUILD_ROOM_QUERYPAGE,"post",this.queryParams2).then(res => {
        if(res.data.code==0){
          this.dataSource2 = res.data.data.list;
        }
        this.tableLoading2 = false;
      })
    },
    deleteRecord(key) {
      this.dataSource = this.dataSource.filter(item => item.key !== key)
      this.selectedRows = this.selectedRows.filter(item => item.key !== key)
      this.selectedRow2 = this.selectedRow2.filter(item => item.key !== key)
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    remove () {
      this.dataSource = this.dataSource.filter(item => this.selectedRows.findIndex(row => row.key === item.key) === -1)
      this.selectedRows = []
    },
    onPageChange2(e){
       this.queryParams2.pageNumber = e.current;
      this.pagination2.current=e.current;
      this.doQueryRooms();
    },
    onPageChange(e){
      this.queryParams.pageNumber = e.current;
      this.pagination.current=e.current;
      this.doQuery();
    },
    handleChange(value) {
      this.queryParams.teamId = value;
      this.doQuery();
    },
    handleChange2(value){
      this.queryParams2.buildId = value;
      this.doQueryRooms();
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    doQueryTeams(){

      request(FIND_TEAMS_BY_USER,"post",{}).then(res => {
          if(res.data.code==0){
            this.teamDatas = res.data.data;
          }
        })
    },

    doQuery(){
      this.tableLoading = true;
      request(PERSON_QUERY_LIST,"post",this.queryParams).then(res => {
        console.log(res,'res**************')

        if(res.data.code==0){
          this.dataSource = res.data.data.list;
          this.pagination.total = res.data.data.total;
        }
        this.tableLoading = false;
      })
    },
    onTimeChange(date, dateString){
      this.queryParams.enterDateStart = dateString[0]
      this.queryParams.enterDateEnd = dateString[1]
    },
    modelOk(recode){
      if(this.selectedRows.length<=0){
        this.$message.error("请选择人员分配宿舍");
        return;
      }
      if(recode&&recode.noPersonNums<=0){
        this.$message.error("非常抱歉，该房间已经满员，请分配其他房间");
        return;
      }
      if(this.selectedRows.length>recode.noPersonNums){
        this.$message.error("非常抱歉，该房间的空余床位不足以满足您分配的人数。");
        return;
      }
      var _that = this
      this.$confirm({
          title: '分配人员宿舍',
          content: '您好，您即将分配'+_that.selectedRows.length+'人到'+recode.roomNo+"房间，是否正确？",
          okText: '确认',
          okType: 'danger',
          cancelText: '重新选择',
          onOk() {
            _that.$emit("doAllotRoom",{userList:_that.selectedRows,room:recode})
          },
          onCancel() {
              console.log('Cancel');
          },
      });
      
    },
    modelCancel(){
      this.$emit("modelCancel");
    }
  }
}
</script>

<style scoped>
  .gutter-example >>> .ant-row > div {
    background: transparent;
    border: 0;
    margin-top:5px;
  }
  .gutter-box {
    /* background: #00a0e9; */
    padding: 5px 0;
  }
  .ant-calendar-picker-container{
    z-index: 999999 !important;
  }
</style>
