var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "a_Approval" },
    [
      _c(
        "a-form-model",
        {
          staticClass: "clearfix",
          attrs: { model: _vm.params },
          on: { submit: _vm.handleSubmit },
        },
        [
          !_vm.isProject && !_vm.isBranchOffice
            ? _c(
                "a-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "分公司",
                        labelCol: { span: 8 },
                        wrapperCol: { span: 14, offset: 1 },
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: { allowClear: "", placeholder: "" },
                          model: {
                            value: _vm.params.branchOfficeId,
                            callback: function ($$v) {
                              _vm.$set(_vm.params, "branchOfficeId", $$v)
                            },
                            expression: "params.branchOfficeId",
                          },
                        },
                        _vm._l(_vm.onlyGcglbList, function (item) {
                          return _c(
                            "a-select-option",
                            {
                              key: item.value,
                              attrs: { value: item.value, title: item.label },
                            },
                            [_vm._v(" " + _vm._s(item.label) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.isProject
            ? _c(
                "a-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "项目",
                        labelCol: { span: 8 },
                        wrapperCol: { span: 14, offset: 1 },
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: { "allow-clear": "" },
                        model: {
                          value: _vm.params.projectName,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "projectName", $$v)
                          },
                          expression: "params.projectName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-col",
            { attrs: { span: 5 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "危大工程类别",
                    labelCol: { span: 8 },
                    wrapperCol: { span: 14, offset: 1 },
                  },
                },
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "180px" },
                      attrs: { allowClear: "", placeholder: "" },
                      model: {
                        value: _vm.params.gclbId,
                        callback: function ($$v) {
                          _vm.$set(_vm.params, "gclbId", $$v)
                        },
                        expression: "params.gclbId",
                      },
                    },
                    _vm._l(_vm.options1, function (item) {
                      return _c(
                        "a-select-option",
                        {
                          key: item.label,
                          attrs: { value: item.value, title: item.label },
                        },
                        [_vm._v(" " + _vm._s(item.label) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 4 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "工程名称",
                    labelCol: { span: 8 },
                    wrapperCol: { span: 14, offset: 0 },
                  },
                },
                [
                  _c("a-input", {
                    attrs: { "allow-clear": "" },
                    model: {
                      value: _vm.params.sgfaName,
                      callback: function ($$v) {
                        _vm.$set(_vm.params, "sgfaName", $$v)
                      },
                      expression: "params.sgfaName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 4 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "是否超规模",
                    labelCol: { span: 8 },
                    wrapperCol: { span: 14, offset: 0 },
                  },
                },
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { allowClear: "", placeholder: "" },
                      model: {
                        value: _vm.params.overRange,
                        callback: function ($$v) {
                          _vm.$set(_vm.params, "overRange", $$v)
                        },
                        expression: "params.overRange",
                      },
                    },
                    _vm._l(_vm.options, function (item) {
                      return _c(
                        "a-select-option",
                        {
                          key: item.label,
                          attrs: { value: item.value, title: item.label },
                        },
                        [_vm._v(" " + _vm._s(item.label) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 4 } },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "计划实施时间",
                    labelCol: { span: 9 },
                    wrapperCol: { span: 14, offset: 0 },
                  },
                },
                [
                  _c("a-date-picker", {
                    staticStyle: { width: "140px" },
                    attrs: { valueFormat: "YYYY-MM-DD", placeholder: "" },
                    model: {
                      value: _vm.params.planTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.params, "planTime", $$v)
                      },
                      expression: "params.planTime",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 4 } },
            [
              _c(
                "a-space",
                { staticClass: "btnBox" },
                [
                  _c(
                    "a-button",
                    { attrs: { type: "primary", "html-type": "submit" } },
                    [_vm._v(" 查询 ")]
                  ),
                  _c("a-button", { on: { click: _vm.resetQuery } }, [
                    _vm._v(" 重置 "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("standard-table", {
        attrs: {
          columns: _vm.columns,
          dataSource: _vm.tableData,
          rowKey: "id",
          pagination: false,
          loading: _vm.tableLoading,
          isAlert: false,
          bordered: true,
          btnArr: _vm.tableBtnArr,
          scroll: { x: 1400 },
        },
        on: { change: _vm.handleChangePage },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function (ref) {
              var index = ref.index
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      (_vm.pagination.current - 1) * _vm.pagination.pageSize +
                        parseInt(index) +
                        1
                    )
                  ),
                ]),
              ]
            },
          },
          {
            key: "overRange",
            fn: function (ref) {
              var record = ref.record
              return [
                record.overRange == 1
                  ? _c("a-tag", { attrs: { color: "#108ee9" } }, [_vm._v("是")])
                  : _vm._e(),
                record.overRange == 0
                  ? _c("a-tag", { attrs: { color: "#f50" } }, [_vm._v("否")])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "zxsgfa",
            fn: function (ref) {
              var record = ref.record
              return [
                record.overRange == 1
                  ? _c("a-tag", { attrs: { color: "#108ee9" } }, [_vm._v("是")])
                  : _vm._e(),
                record.overRange == 0
                  ? _c("a-tag", { attrs: { color: "#f50" } }, [_vm._v("否")])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "ipmlCode",
            fn: function (ref) {
              var record = ref.record
              return [
                record.ipmlCode == 1
                  ? _c("a-tag", { attrs: { color: "#108ee9" } }, [
                      _vm._v("未开始"),
                    ])
                  : _vm._e(),
                record.ipmlCode == 2
                  ? _c("a-tag", { attrs: { color: "#108ee9" } }, [
                      _vm._v("持续中"),
                    ])
                  : _vm._e(),
                record.ipmlCode == 3
                  ? _c("a-tag", { attrs: { color: "#108ee9" } }, [
                      _vm._v("已完成"),
                    ])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "gcproessCode",
            fn: function (ref) {
              var record = ref.record
              return [
                _c(
                  "div",
                  { staticClass: "sTables" },
                  [
                    record.gcproessCode == 1
                      ? _c("a-tag", { attrs: { color: "#108ee9" } }, [
                          _vm._v("未开工"),
                        ])
                      : _vm._e(),
                    record.gcproessCode == 2
                      ? _c("a-tag", { attrs: { color: "#108ee9" } }, [
                          _vm._v("持续中"),
                        ])
                      : _vm._e(),
                    record.gcproessCode == 3
                      ? _c("a-tag", { attrs: { color: "#108ee9" } }, [
                          _vm._v("已完成"),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "ysurl",
            fn: function (ref) {
              var record = ref.record
              return [
                _c("div", { staticClass: "sTables" }, [
                  _vm._v(" " + _vm._s(record.gcproessCode) + " "),
                ]),
              ]
            },
          },
          {
            key: "attach1",
            fn: function (ref) {
              var record = ref.record
              return [
                _c(
                  "div",
                  {
                    staticClass: "flex j-center",
                    staticStyle: { "flex-wrap": "wrap" },
                  },
                  _vm._l(record.attachList1, function (item, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        item.type == "img"
                          ? _c("img", {
                              staticStyle: { width: "32px", height: "22.5px" },
                              attrs: { alt: "", src: item.url },
                              on: {
                                click: function ($event) {
                                  return _vm.handlePreview(item)
                                },
                              },
                            })
                          : item.type == "pdf"
                          ? _c("a-icon", {
                              style: { fontSize: "32px" },
                              attrs: { type: "file-pdf" },
                              on: {
                                click: function ($event) {
                                  return _vm.handlePreview(item)
                                },
                              },
                            })
                          : _c("a-icon", {
                              style: { fontSize: "32px" },
                              attrs: { type: "file-text" },
                              on: {
                                click: function ($event) {
                                  return _vm.handlePreview(item)
                                },
                              },
                            }),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]
            },
          },
          {
            key: "managename",
            fn: function (ref) {
              var record = ref.record
              return [
                _c("div", [
                  _c("div", [_vm._v(" " + _vm._s(record.managename) + " ")]),
                  _c("div", [_vm._v(" " + _vm._s(record.managephone) + " ")]),
                ]),
              ]
            },
          },
          {
            key: "action",
            fn: function (ref) {
              var record = ref.record
              return [
                _c("div", { staticClass: "sTables" }, [
                  _c(
                    "div",
                    {
                      staticClass: "y-edit",
                      on: {
                        click: function ($event) {
                          return _vm.handleEdit(record)
                        },
                      },
                    },
                    [_vm._v("编辑")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "y-del",
                      on: {
                        click: function ($event) {
                          return _vm.handleDel(record)
                        },
                      },
                    },
                    [_vm._v("删除")]
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
      _c("detail", {
        attrs: {
          visible: _vm.visible,
          isEdit: _vm.isEdit,
          projectInfo: _vm.projectInfo,
          id: _vm.id,
        },
        on: {
          "update:visible": function ($event) {
            _vm.visible = $event
          },
          closeDialog: _vm.closeDialog,
          refresh: _vm.handleQuery,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }