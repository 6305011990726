var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-drawer",
        {
          attrs: {
            width: 800,
            visible: _vm.visible,
            title: "企业库班组信息",
            "body-style": { paddingBottom: "80px" },
          },
          on: { close: _vm.onClose },
        },
        [
          _c(
            "a-form",
            {
              attrs: {
                form: _vm.form,
                "label-col": { span: 5 },
                "wrapper-col": { span: 18 },
                layout: "horizontal",
              },
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "班组名称：" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "teamName",
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        message: "请输入班组名称",
                                      },
                                    ],
                                  },
                                ],
                                expression:
                                  "['teamName', { rules: [{ required: true, message: '请输入班组名称' }] }]",
                              },
                            ],
                            attrs: { placeholder: "请输入班组名称" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "工种类型" } },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: [
                                    "typeWorkId",
                                    {
                                      rules: [
                                        {
                                          required: true,
                                          message: "请选择工种类型",
                                        },
                                      ],
                                    },
                                  ],
                                  expression:
                                    "['typeWorkId', { rules: [{ required: true, message: '请选择工种类型' }] }]",
                                },
                              ],
                              staticStyle: { width: "100%" },
                              attrs: {
                                allowClear: true,
                                placeholder: "请选择工种类型",
                              },
                              on: { change: _vm.changeTypeWork },
                            },
                            _vm._l(_vm.typeWorkDataSource, function (t) {
                              return _c(
                                "a-select-option",
                                { key: t.id, attrs: { value: t.id } },
                                [_vm._v(_vm._s(t.name))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "工  种" } },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: [
                                    "jobtitleId",
                                    {
                                      rules: [
                                        {
                                          required: true,
                                          message: "请选择工种",
                                        },
                                      ],
                                    },
                                  ],
                                  expression:
                                    "['jobtitleId', { rules: [{ required: true, message: '请选择工种' }] }]",
                                },
                              ],
                              staticStyle: { width: "100%" },
                              attrs: {
                                allowClear: true,
                                placeholder: "请选择工种",
                              },
                            },
                            _vm._l(_vm.JobtitleDataSource, function (l) {
                              return _c(
                                "a-select-option",
                                { key: l.id, attrs: { value: l.id } },
                                [_vm._v(_vm._s(l.name))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "负责人：" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "fzrUserName",
                                  { rules: [{ message: "请输入班组负责人" }] },
                                ],
                                expression:
                                  "['fzrUserName', { rules: [{ message: '请输入班组负责人' }] }]",
                              },
                            ],
                            attrs: { placeholder: "请输入班组负责人" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "负责人手机号：" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "fzrPhone",
                                  {
                                    rules: [{ message: "请输入负责人手机号" }],
                                  },
                                ],
                                expression:
                                  "['fzrPhone', { rules: [{  message: '请输入负责人手机号' }] }]",
                              },
                            ],
                            attrs: { placeholder: "请输入负责人手机号" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              style: {
                position: "absolute",
                right: 0,
                bottom: 0,
                width: "100%",
                borderTop: "1px solid #e9e9e9",
                padding: "10px 16px",
                background: "#fff",
                textAlign: "right",
                zIndex: 1,
              },
            },
            [
              _c(
                "a-button",
                {
                  style: { marginLeft: "8px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.doSubmit },
                },
                [_vm._v(" 确定 ")]
              ),
              _c(
                "a-button",
                { style: { marginLeft: "8px" }, on: { click: _vm.onClose } },
                [_vm._v(" 关闭 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }