var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-card", { staticClass: "pitfall-card" }, [
        _c("div", { attrs: { slot: "title" }, slot: "title" }, [
          _c("div", { staticClass: "flex a-center" }, [
            _c("img", {
              staticStyle: {
                width: "21px",
                height: "21px",
                "margin-right": "5px",
              },
              attrs: {
                src: "https://sjlw.oss-cn-shenzhen.aliyuncs.com/attachmentDO/20241120/1732091350443.png?Expires=2047451350&OSSAccessKeyId=LTAI4G3bZuu23QiabXFe8vVE&Signature=59AEqlAw%2B%2BGyQ%2F%2B4QYdFWxJZ7rs%3D",
                alt: "",
              },
            }),
            _c("span", { staticClass: "ml-1 font-bold" }, [
              _vm._v("危大工程情况"),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticStyle: { "padding-left": "13px", "padding-right": "13px" } },
          [
            _c(
              "a-row",
              { attrs: { gutter: [16, 20] } },
              _vm._l(_vm.projectContent, function (item, index) {
                return _c("a-col", { key: index, attrs: { span: 8 } }, [
                  _c(
                    "div",
                    {
                      staticClass: "flex j-center a-center content-box black",
                      style: item.style,
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "black-three text-center font-14" },
                        [_vm._v(_vm._s(item.label))]
                      ),
                      _c(
                        "span",
                        { staticClass: "ml-1 font-20 font-bold black" },
                        [_vm._v(_vm._s(item.value || 0))]
                      ),
                    ]
                  ),
                ])
              }),
              1
            ),
            _c("div", {
              staticClass: "mt-2",
              staticStyle: {
                width: "98%",
                height: "331px",
                "margin-bottom": "15px",
                "margin-top": "21px",
              },
              attrs: { id: "checkTimesCateGoryChart" },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }